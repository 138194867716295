export const rings = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 240,
  w: 360,
  h: 360,
  nm: 'L - Rings',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'RIngs Loop',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape Layer 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [-45],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [587, 494, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape Layer 15',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [-30],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [581, 500, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape Layer 14',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [-15],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [575, 506, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Shape Layer 13',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [0],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [569, 512, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape Layer 12',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [15],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [563, 518, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Shape Layer 11',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [30],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [557, 524, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Shape Layer 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [45],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [551, 530, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Shape Layer 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [60],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [545, 536, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Shape Layer 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [75],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [539, 542, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Shape Layer 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [90],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [533, 548, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Shape Layer 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [105],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [527, 554, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'Shape Layer 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 120,
                  s: [-45],
                },
                {
                  i: { x: [0.05], y: [1] },
                  o: { x: [0.5], y: [0] },
                  t: 180,
                  s: [120],
                },
                { t: 240, s: [135] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [542, 539, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.664, y: 1 },
                  o: { x: 0.505, y: 0 },
                  t: 61,
                  s: [521, 560, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 120, s: [542, 539, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [160, 320], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 1800,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'RIngs Loop',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [178, 181, 0], ix: 2, l: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1080,
      h: 1080,
      ip: 0,
      op: 1800,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
}

import Module from './module'
import { LottieAnimate } from '../animations'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { footer } from '../lottie'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Footer extends Module {
  constructor(obj) {
    super(obj)

    // ! Try to setup the SVG animation instead of lottie
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      bg: qs('.footer-bg', el),
      title: qsa('.footer-title', el),
      asset: qs('.footer-asset', el),
      logo: qs('.footer-logo', el),
      menu: qsa('.menu .menu-item', el),
      foot: qsa('.footnote-item', el),
    })

    this.lottie = {
      footer: {
        data: footer,
        el: this.dom.asset,
      },
    }
  }

  setAnimation(splitText) {
    const { bg, logo, asset, menu, foot } = this.dom
    const { footer } = this.lottie
    const title = splitText[0].lines

    this.tl = gsap.timeline({ paused: true })
    this.tl.from(bg, { duration: 0.8, scale: 0 })
    this.tl.from(logo, { duration: 0.8, y: '200%' }, '-=0.6')
    this.tl.from(asset, { duration: 0.5, autoAlpha: 0 }, '-=0.4')
    this.tl.from(title, { y: 60, autoAlpha: 0, stagger: 0.05 }, '-=0.5')

    this.tl.from(
      menu,
      {
        duration: 0.6,
        y: 60,
        autoAlpha: 0,
        stagger: 0.05,
        ease: 'power2.out',
      },
      '-=0.3',
    )
    foot.forEach((foot) => {
      const lines = qsa('.line-0', foot)
      this.tl.from(
        lines,
        {
          duration: 0.6,
          y: 30,
          autoAlpha: 0,
          stagger: 0.05,
          ease: 'power2.inOut',
        },
        '-=0.6',
      )
    })

    // * Setup Lottie Animation

    footer.anim = new LottieAnimate({
      container: footer.el,
      data: footer.data,
      loop: true,
      autoplay: false,
    })

    footer.anim.load()
  }

  setTrigger() {
    const { el } = this.dom
    const { footer } = this.lottie
    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=30%',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=100%',
      end: 'bottom bottom',
      onEnter: () => {
        footer.anim.play()
      },
      onLeave: () => {
        footer.anim.pause()
      },
      onEnterBack: () => {
        footer.anim.play()
      },
      onLeaveBack: () => {
        footer.anim.pause()
      },
    })
  }
}

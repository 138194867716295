import Module from './module'
import { LottieAnimate } from '../animations'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { box, crystal } from '../lottie'
import { qs } from '../utils'
import gsap from 'gsap'

export default class Split extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    this.lottie = {
      box: {
        data: box,
        el: qs('.split-art .art-2'),
      },
      crystal: {
        data: crystal,
        el: qs('.split-art .art-1'),
      },
    }
  }

  setAnimation(splitText) {
    const { crystal, box } = this.lottie
    const description = splitText[0].words

    this.tl = gsap.timeline({ paused: true })
    this.tl.from(description, {
      duration: 0.5,
      y: 40,
      autoAlpha: 0,
      stagger: 0.025,
    })

    // * Setup Lottie Animation

    crystal.anim = new LottieAnimate({
      container: crystal.el,
      data: crystal.data,
      loop: false,
      autoplay: false,
    })

    crystal.anim.load()

    box.anim = new LottieAnimate({
      container: box.el,
      data: box.data,
      loop: true,
      autoplay: false,
    })

    box.anim.load()
  }

  setTrigger() {
    const { el } = this.dom
    const { crystal, box } = this.lottie

    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'top top-=10%',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=80%',
      end: 'bottom center',
      once: true,
      onEnter: () => {
        crystal.anim.play()
        box.anim.play()
      },
      onLeave: () => {
        box.anim.pause()
      },
      onEnterBack: () => {
        box.anim.play()
      },
      onLeaveBack: () => {
        box.anim.pause()
      },
    })
  }
}

import Module from './module'
import { LottieAnimate } from '../animations'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { rings, waves } from '../lottie'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Company extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    this.lottie = {
      rings: {
        data: rings,
        el: qs('.company-art .art-content'),
      },
      waves: {
        data: waves,
        el: qs('.company-icon.icon-3'),
      },
    }
  }

  setAnimation() {
    const { el } = this.dom
    const titles = qsa('.-t4', el)
    const footnotes = qsa('.footnote', el)

    // * Setup Scroll Animation

    this.tl = gsap.timeline({ paused: true })

    titles.forEach((title, i) => {
      const lines = qsa('.line-0', title)
      lines.forEach((line) => {
        const chars = qsa('.char-1', line)
        this.tl.from(
          chars,
          {
            duration: 0.8,
            y: '101%',
            stagger: 0.035,
            ease: 'power2.inOut',
          },
          i * 0.2,
        )
      })
    })

    footnotes.forEach((foot) => {
      const lines = qsa('.line-0', foot)
      this.tl.from(
        lines,
        {
          duration: 0.6,
          y: 30,
          autoAlpha: 0,
          stagger: 0.05,
          ease: 'power2.inOut',
        },
        '-=0.8',
      )
    })

    // * Setup Lottie Animation

    for (const item in this.lottie) {
      const container = this.lottie[item].el
      const data = this.lottie[item].data

      this.lottie[item].anim = new LottieAnimate({
        container: container,
        data: data,
        loop: true,
        autoplay: false,
      })

      this.lottie[item].anim.load()
    }
  }

  setTrigger() {
    const { el } = this.dom
    const { rings, waves } = this.lottie
    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=50%',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=100%',
      end: 'bottom bottom-=100%',
      onEnter: () => {
        rings.anim.play()
        waves.anim.play()
      },
      onLeave: () => {
        rings.anim.pause()
        waves.anim.pause()
      },
      onEnterBack: () => {
        rings.anim.play()
        waves.anim.play()
      },
      onLeaveBack: () => {
        rings.anim.pause()
        waves.anim.pause()
      },
    })
  }
}

export const blob = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 240,
  w: 640,
  h: 640,
  nm: 'L - Blob',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'Blob Container',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'circle',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1179.857, 359.864, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-298, 406, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [20, 20], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-298, 406], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 240,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 5,
          nm: '37 85543 641',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1168.857, 260.723, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 18,
                    f: 'Berkeley Mono',
                    t: '37\r85543\r641',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 24,
                    ls: 0,
                    fc: [0.216, 0.216, 0.216],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 240,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 0,
          nm: 'sliding graph vertical',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -90, ix: 10 },
            p: { a: 0, k: [-226, -100, 0], ix: 2, l: 2 },
            a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 1440,
          h: 880,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 0,
          nm: 'wobbly alt inside',
          refId: 'comp_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 50, ix: 10 },
            p: { a: 0, k: [540, 580, 0], ix: 2, l: 2 },
            a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 1080,
          h: 1080,
          ip: 0,
          op: 240,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      nm: 'sliding graph vertical',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'lines Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [243.527, 755.837, 0], ix: 2, l: 2 },
            a: { a: 0, k: [99.527, 7.838, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0.5, 0.5],
                        [0.5, 15.175],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [10.403, 0.5],
                        [10.403, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [20.305, 0.5],
                        [20.305, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [30.208, 0.5],
                        [30.208, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [40.111, 0.5],
                        [40.111, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [50.014, 0.5],
                        [50.014, 15.175],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [59.916, 0.5],
                        [59.916, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [69.819, 0.5],
                        [69.819, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [79.722, 0.5],
                        [79.722, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [89.624, 0.5],
                        [89.624, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [99.527, 0.5],
                        [99.527, 15.175],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 11',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [109.43, 0.5],
                        [109.43, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [119.333, 0.5],
                        [119.333, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [129.235, 0.5],
                        [129.235, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 14',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [139.138, 0.5],
                        [139.138, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 15',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 15,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [149.041, 0.5],
                        [149.041, 15.175],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 16',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 16,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [158.943, 0.5],
                        [158.943, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 17',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 17,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [168.846, 0.5],
                        [168.846, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 18',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 18,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [178.749, 0.5],
                        [178.749, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 19',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 19,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [188.652, 0.5],
                        [188.652, 7.837],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 20',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 20,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [198.554, 0.5],
                        [198.554, 15.175],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 21',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 21,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 244,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'fixed dots Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [170.951, 782.728, 0], ix: 2, l: 2 },
            a: { a: 0, k: [30.77, 4.32, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [13.954, 4.07],
                        [-13.954, 4.07],
                        [-13.954, -4.07],
                        [13.954, -4.07],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [43.267, 4.321], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.248],
                        [2.248, 0],
                        [0, 2.248],
                        [-2.247, 0],
                      ],
                      o: [
                        [0, 2.248],
                        [-2.247, 0],
                        [0, -2.248],
                        [2.248, 0],
                      ],
                      v: [
                        [4.07, 0],
                        [-0.001, 4.07],
                        [-4.07, 0],
                        [-0.001, -4.07],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [57.221, 4.32], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.248],
                        [2.248, 0],
                        [0, 2.248],
                        [-2.247, 0],
                      ],
                      o: [
                        [0, 2.248],
                        [-2.247, 0],
                        [0, -2.248],
                        [2.248, 0],
                      ],
                      v: [
                        [4.07, 0],
                        [-0.001, 4.07],
                        [-4.07, 0],
                        [-0.001, -4.07],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [29.314, 4.32], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.248],
                        [2.248, 0],
                        [0, 2.248],
                        [-2.247, 0],
                      ],
                      o: [
                        [0, 2.248],
                        [-2.247, 0],
                        [0, -2.248],
                        [2.248, 0],
                      ],
                      v: [
                        [4.07, 0],
                        [-0.001, 4.07],
                        [-4.07, 0],
                        [-0.001, -4.07],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [4.32, 4.32], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 244,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'dot num Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.333, y: 0.333 },
                  t: 0,
                  s: [262.79, 782.728, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.8, y: 0 },
                  t: 15,
                  s: [262.79, 782.728, 0],
                  to: [6, 0, 0],
                  ti: [-6, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.8, y: 0.8 },
                  t: 30,
                  s: [298.79, 782.728, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.8, y: 0 },
                  t: 45,
                  s: [298.79, 782.728, 0],
                  to: [-11.833, 0, 0],
                  ti: [11.833, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.8, y: 0.8 },
                  t: 60,
                  s: [227.79, 782.728, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.8, y: 0 },
                  t: 75,
                  s: [227.79, 782.728, 0],
                  to: [17.333, 0, 0],
                  ti: [-17.333, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.8, y: 0.8 },
                  t: 90,
                  s: [331.79, 782.728, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.8, y: 0 },
                  t: 105,
                  s: [331.79, 782.728, 0],
                  to: [-11.5, 0, 0],
                  ti: [11.5, 0, 0],
                },
                { t: 120, s: [262.79, 782.728, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [4.32, 4.32, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.248],
                        [2.248, 0],
                        [0, 2.248],
                        [-2.247, 0],
                      ],
                      o: [
                        [0, 2.248],
                        [-2.247, 0],
                        [0, -2.248],
                        [2.248, 0],
                      ],
                      v: [
                        [4.07, 0],
                        [-0.001, 4.07],
                        [-4.07, 0],
                        [-0.001, -4.07],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.21568627451, 0.21568627451, 0.21568627451, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [4.32, 4.32], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 244,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 5,
          nm: 'I slider number 2',
          parent: 3,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 90, ix: 10 },
            p: { a: 0, k: [0.262, 36.168, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 12,
                    f: 'Berkeley Mono',
                    t: 'FSF',
                    ca: 1,
                    j: 2,
                    tr: 0,
                    lh: 66.9562225341797,
                    ls: 0,
                    fc: [0.71, 0.71, 0.71],
                  },
                  t: 0,
                },
              ],
              x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 23900);\n$bm_rt = n = Math.round(n);',
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 251.5,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      nm: 'wobbly alt inside',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 19,
          ty: 4,
          nm: 'SHAPES BLEND 1 - Blends',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540, 540, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [6.437, -252.938],
                                  [-184.937, -268.875],
                                  [-244.5, -60.187],
                                  [-249.25, 152.188],
                                  [-205.937, 234.688],
                                  [125.438, 339],
                                  [324.5, 250.75],
                                  [295.437, 119.625],
                                  [109.25, -118.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [7.187, -254.063],
                                  [-184.625, -268.312],
                                  [-244.625, -59.937],
                                  [-249.75, 151.563],
                                  [-205.875, 235.625],
                                  [125.063, 338.438],
                                  [324.375, 251.125],
                                  [295.75, 119.437],
                                  [108.75, -118.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [8, -255.25],
                                  [-184.25, -267.812],
                                  [-244.813, -59.687],
                                  [-250.187, 150.938],
                                  [-205.75, 236.5],
                                  [124.688, 337.875],
                                  [324.312, 251.562],
                                  [296.062, 119.25],
                                  [108.25, -119.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [8.812, -256.5],
                                  [-183.875, -267.25],
                                  [-244.938, -59.5],
                                  [-250.625, 150.312],
                                  [-205.5, 237.375],
                                  [124.25, 337.25],
                                  [324.312, 252],
                                  [296.312, 119],
                                  [107.75, -119.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [9.625, -257.75],
                                  [-183.5, -266.625],
                                  [-245.125, -59.375],
                                  [-251.062, 149.75],
                                  [-205.25, 238.25],
                                  [123.813, 336.625],
                                  [324.312, 252.5],
                                  [296.625, 118.687],
                                  [107.313, -120.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [10.375, -259],
                                  [-183.125, -266.062],
                                  [-245.25, -59.25],
                                  [-251.437, 149.188],
                                  [-204.937, 239.125],
                                  [123.375, 336],
                                  [324.375, 253],
                                  [296.875, 118.375],
                                  [106.938, -120.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [11.125, -260.125],
                                  [-182.75, -265.437],
                                  [-245.375, -59.125],
                                  [-251.812, 148.625],
                                  [-204.562, 239.938],
                                  [122.875, 335.313],
                                  [324.437, 253.5],
                                  [297.125, 118],
                                  [106.5, -120.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [11.812, -261.25],
                                  [-182.375, -264.812],
                                  [-245.5, -59.125],
                                  [-252.187, 148.063],
                                  [-204.125, 240.75],
                                  [122.375, 334.625],
                                  [324.562, 254.062],
                                  [297.312, 117.562],
                                  [106.188, -121],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [12.437, -262.25],
                                  [-181.938, -264.187],
                                  [-245.625, -59.062],
                                  [-252.5, 147.5],
                                  [-203.625, 241.5],
                                  [121.813, 333.938],
                                  [324.687, 254.625],
                                  [297.562, 117.125],
                                  [105.813, -121.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [13, -263.25],
                                  [-181.562, -263.5],
                                  [-245.75, -59.062],
                                  [-252.75, 147],
                                  [-203.125, 242.25],
                                  [121.25, 333.25],
                                  [324.937, 255.187],
                                  [297.75, 116.687],
                                  [105.5, -121.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [13.562, -264.125],
                                  [-181.125, -262.812],
                                  [-245.875, -59.125],
                                  [-253.062, 146.5],
                                  [-202.625, 242.938],
                                  [120.688, 332.5],
                                  [325.125, 255.75],
                                  [297.937, 116.187],
                                  [105.188, -121.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [14.062, -265],
                                  [-180.75, -262.125],
                                  [-245.938, -59.187],
                                  [-253.312, 146.063],
                                  [-202, 243.688],
                                  [120.125, 331.75],
                                  [325.375, 256.375],
                                  [298.062, 115.625],
                                  [104.938, -121.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [14.562, -265.75],
                                  [-180.312, -261.437],
                                  [-246.063, -59.25],
                                  [-253.562, 145.625],
                                  [-201.437, 244.313],
                                  [119.5, 331.063],
                                  [325.687, 256.937],
                                  [298.25, 115.062],
                                  [104.688, -121.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [15, -266.438],
                                  [-179.875, -260.687],
                                  [-246.188, -59.375],
                                  [-253.75, 145.188],
                                  [-200.75, 244.938],
                                  [118.875, 330.313],
                                  [326, 257.562],
                                  [298.312, 114.437],
                                  [104.5, -121.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [15.375, -267.125],
                                  [-179.438, -260],
                                  [-246.25, -59.5],
                                  [-253.937, 144.75],
                                  [-200.125, 245.563],
                                  [118.25, 329.563],
                                  [326.375, 258.187],
                                  [298.437, 113.812],
                                  [104.25, -121.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [15.75, -267.688],
                                  [-179, -259.25],
                                  [-246.375, -59.687],
                                  [-254.125, 144.375],
                                  [-199.437, 246.188],
                                  [117.625, 328.813],
                                  [326.75, 258.75],
                                  [298.5, 113.187],
                                  [104.063, -121.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [16.062, -268.25],
                                  [-178.562, -258.5],
                                  [-246.438, -59.812],
                                  [-254.25, 144],
                                  [-198.75, 246.688],
                                  [116.938, 328],
                                  [327.125, 259.375],
                                  [298.562, 112.5],
                                  [103.938, -121],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [16.375, -268.688],
                                  [-178.062, -257.75],
                                  [-246.5, -60.062],
                                  [-254.437, 143.625],
                                  [-198, 247.25],
                                  [116.312, 327.25],
                                  [327.562, 260],
                                  [298.625, 111.812],
                                  [103.75, -120.75],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [16.687, -269.125],
                                  [-177.625, -257],
                                  [-246.625, -60.25],
                                  [-254.562, 143.313],
                                  [-197.312, 247.75],
                                  [115.625, 326.5],
                                  [328.062, 260.562],
                                  [298.625, 111.062],
                                  [103.625, -120.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [16.937, -269.5],
                                  [-177.125, -256.25],
                                  [-246.688, -60.5],
                                  [-254.625, 143],
                                  [-196.562, 248.188],
                                  [114.938, 325.813],
                                  [328.5, 261.125],
                                  [298.625, 110.312],
                                  [103.5, -120.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [17.187, -269.875],
                                  [-176.625, -255.5],
                                  [-246.75, -60.75],
                                  [-254.75, 142.688],
                                  [-195.812, 248.625],
                                  [114.25, 325.063],
                                  [329, 261.75],
                                  [298.562, 109.562],
                                  [103.438, -119.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [17.437, -270.125],
                                  [-176.125, -254.75],
                                  [-246.812, -61],
                                  [-254.812, 142.438],
                                  [-195.062, 249],
                                  [113.562, 324.313],
                                  [329.562, 262.312],
                                  [298.5, 108.812],
                                  [103.375, -119.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [17.625, -270.375],
                                  [-175.563, -254],
                                  [-246.875, -61.312],
                                  [-254.875, 142.188],
                                  [-194.312, 249.375],
                                  [112.812, 323.625],
                                  [330.125, 262.875],
                                  [298.437, 108],
                                  [103.313, -119.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [17.812, -270.563],
                                  [-175.063, -253.25],
                                  [-247, -61.625],
                                  [-254.937, 141.938],
                                  [-193.562, 249.688],
                                  [112.125, 322.938],
                                  [330.687, 263.375],
                                  [298.312, 107.187],
                                  [103.25, -118.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [18, -270.75],
                                  [-174.5, -252.5],
                                  [-247.062, -61.937],
                                  [-255, 141.75],
                                  [-192.812, 250],
                                  [111.438, 322.25],
                                  [331.25, 263.937],
                                  [298.187, 106.375],
                                  [103.188, -118.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [18.125, -270.813],
                                  [-174, -251.75],
                                  [-247.125, -62.25],
                                  [-255, 141.563],
                                  [-192.062, 250.25],
                                  [110.75, 321.625],
                                  [331.875, 264.437],
                                  [298.062, 105.5],
                                  [103.188, -117.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [18.25, -270.938],
                                  [-173.438, -251],
                                  [-247.188, -62.562],
                                  [-255.062, 141.438],
                                  [-191.312, 250.5],
                                  [110, 320.938],
                                  [332.5, 264.937],
                                  [297.875, 104.687],
                                  [103.188, -117],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [18.437, -270.938],
                                  [-172.875, -250.25],
                                  [-247.25, -62.937],
                                  [-255.062, 141.313],
                                  [-190.625, 250.688],
                                  [109.313, 320.375],
                                  [333.125, 265.375],
                                  [297.687, 103.875],
                                  [103.188, -116.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [18.5, -270.938],
                                  [-172.25, -249.5],
                                  [-247.312, -63.312],
                                  [-255.062, 141.188],
                                  [-189.875, 250.813],
                                  [108.625, 319.75],
                                  [333.812, 265.875],
                                  [297.437, 103],
                                  [103.188, -115.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [18.625, -270.875],
                                  [-171.688, -248.812],
                                  [-247.375, -63.687],
                                  [-255.062, 141.063],
                                  [-189.187, 250.938],
                                  [107.938, 319.188],
                                  [334.437, 266.25],
                                  [297.187, 102.125],
                                  [103.188, -115.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [18.75, -270.813],
                                  [-171.063, -248.125],
                                  [-247.438, -64.062],
                                  [-255.062, 141],
                                  [-188.5, 251],
                                  [107.25, 318.688],
                                  [335.125, 266.687],
                                  [296.937, 101.312],
                                  [103.25, -114.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [18.812, -270.688],
                                  [-170.438, -247.437],
                                  [-247.5, -64.437],
                                  [-255, 141],
                                  [-187.812, 251.063],
                                  [106.625, 318.188],
                                  [335.812, 267.062],
                                  [296.625, 100.437],
                                  [103.25, -113.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [18.937, -270.563],
                                  [-169.813, -246.75],
                                  [-247.562, -64.812],
                                  [-255, 140.938],
                                  [-187.187, 251.063],
                                  [105.938, 317.75],
                                  [336.562, 267.437],
                                  [296.312, 99.625],
                                  [103.313, -113.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19, -270.438],
                                  [-169.188, -246.062],
                                  [-247.625, -65.25],
                                  [-255, 140.938],
                                  [-186.562, 251],
                                  [105.312, 317.313],
                                  [337.25, 267.75],
                                  [295.937, 98.75],
                                  [103.375, -112.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.062, -270.188],
                                  [-168.563, -245.437],
                                  [-247.688, -65.687],
                                  [-254.937, 141],
                                  [-185.937, 250.938],
                                  [104.688, 316.938],
                                  [338, 268],
                                  [295.562, 97.937],
                                  [103.438, -111.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.125, -270],
                                  [-167.875, -244.812],
                                  [-247.75, -66.062],
                                  [-254.937, 141],
                                  [-185.375, 250.875],
                                  [104.062, 316.563],
                                  [338.75, 268.312],
                                  [295.187, 97.125],
                                  [103.563, -110.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.187, -269.75],
                                  [-167.25, -244.25],
                                  [-247.812, -66.5],
                                  [-254.875, 141.063],
                                  [-184.812, 250.688],
                                  [103.438, 316.25],
                                  [339.5, 268.5],
                                  [294.75, 96.312],
                                  [103.625, -110.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.25, -269.5],
                                  [-166.563, -243.687],
                                  [-247.875, -66.937],
                                  [-254.875, 141.188],
                                  [-184.312, 250.5],
                                  [102.875, 316],
                                  [340.25, 268.687],
                                  [294.312, 95.5],
                                  [103.75, -109.25],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.312, -269.188],
                                  [-165.875, -243.125],
                                  [-248, -67.375],
                                  [-254.875, 141.25],
                                  [-183.812, 250.313],
                                  [102.312, 315.813],
                                  [341, 268.875],
                                  [293.812, 94.75],
                                  [103.813, -108.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.312, -268.875],
                                  [-165.125, -242.562],
                                  [-248.062, -67.812],
                                  [-254.812, 141.375],
                                  [-183.312, 250.063],
                                  [101.75, 315.688],
                                  [341.75, 269],
                                  [293.312, 94],
                                  [103.938, -107.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.375, -268.5],
                                  [-164.438, -242.125],
                                  [-248.125, -68.25],
                                  [-254.812, 141.563],
                                  [-182.875, 249.75],
                                  [101.25, 315.563],
                                  [342.437, 269.062],
                                  [292.812, 93.25],
                                  [104.063, -106.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.375, -268.188],
                                  [-163.688, -241.625],
                                  [-248.187, -68.687],
                                  [-254.812, 141.688],
                                  [-182.437, 249.438],
                                  [100.75, 315.5],
                                  [343.187, 269.125],
                                  [292.25, 92.562],
                                  [104.25, -105.938],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.437, -267.813],
                                  [-163, -241.187],
                                  [-248.25, -69.187],
                                  [-254.75, 141.875],
                                  [-182.062, 249.063],
                                  [100.312, 315.5],
                                  [343.937, 269.187],
                                  [291.687, 91.875],
                                  [104.375, -105.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.437, -267.375],
                                  [-162.25, -240.812],
                                  [-248.375, -69.625],
                                  [-254.75, 142.125],
                                  [-181.625, 248.688],
                                  [99.875, 315.563],
                                  [344.625, 269.125],
                                  [291.125, 91.187],
                                  [104.5, -104.25],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.437, -267],
                                  [-161.5, -240.437],
                                  [-248.437, -70.062],
                                  [-254.812, 142.313],
                                  [-181.25, 248.25],
                                  [99.5, 315.688],
                                  [345.312, 269.062],
                                  [290.5, 90.562],
                                  [104.688, -103.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.5, -266.563],
                                  [-160.75, -240.125],
                                  [-248.562, -70.5],
                                  [-254.812, 142.563],
                                  [-180.875, 247.813],
                                  [99.125, 315.875],
                                  [346, 269],
                                  [289.875, 89.937],
                                  [104.875, -102.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.5, -266.125],
                                  [-159.938, -239.812],
                                  [-248.687, -70.937],
                                  [-254.812, 142.875],
                                  [-180.5, 247.375],
                                  [98.75, 316.125],
                                  [346.687, 268.875],
                                  [289.25, 89.375],
                                  [105.063, -101.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.5, -265.625],
                                  [-159.188, -239.562],
                                  [-248.812, -71.375],
                                  [-254.875, 143.125],
                                  [-180.187, 246.875],
                                  [98.438, 316.438],
                                  [347.312, 268.687],
                                  [288.625, 88.812],
                                  [105.25, -100.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.562, -265.125],
                                  [-158.375, -239.375],
                                  [-249, -71.75],
                                  [-254.938, 143.438],
                                  [-179.812, 246.375],
                                  [98.188, 316.813],
                                  [347.937, 268.5],
                                  [288, 88.312],
                                  [105.438, -100],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.625, -264.625],
                                  [-157.563, -239.187],
                                  [-249.187, -72.187],
                                  [-255, 143.75],
                                  [-179.5, 245.875],
                                  [97.938, 317.25],
                                  [348.562, 268.25],
                                  [287.437, 87.812],
                                  [105.625, -99.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.625, -264.125],
                                  [-156.75, -239.062],
                                  [-249.312, -72.625],
                                  [-255.062, 144.062],
                                  [-179.187, 245.313],
                                  [97.75, 317.688],
                                  [349.187, 268],
                                  [286.875, 87.312],
                                  [105.813, -98.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.687, -263.625],
                                  [-155.938, -239],
                                  [-249.562, -73],
                                  [-255.125, 144.438],
                                  [-178.937, 244.75],
                                  [97.562, 318.188],
                                  [349.75, 267.687],
                                  [286.312, 86.875],
                                  [106, -97.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.75, -263.063],
                                  [-155.125, -239],
                                  [-249.75, -73.437],
                                  [-255.125, 144.812],
                                  [-178.625, 244.188],
                                  [97.437, 318.75],
                                  [350.312, 267.375],
                                  [285.75, 86.437],
                                  [106.188, -96.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.875, -262.5],
                                  [-154.313, -239],
                                  [-249.937, -73.812],
                                  [-255.062, 145.125],
                                  [-178.375, 243.563],
                                  [97.312, 319.313],
                                  [350.875, 267],
                                  [285.187, 86.062],
                                  [106.438, -95.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.937, -261.938],
                                  [-153.438, -239.062],
                                  [-250.187, -74.187],
                                  [-255, 145.5],
                                  [-178.125, 242.938],
                                  [97.187, 319.875],
                                  [351.375, 266.562],
                                  [284.687, 85.687],
                                  [106.625, -95],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [20.062, -261.313],
                                  [-152.563, -239.062],
                                  [-250.437, -74.562],
                                  [-254.812, 145.812],
                                  [-177.937, 242.313],
                                  [97.125, 320.5],
                                  [351.875, 266.125],
                                  [284.187, 85.312],
                                  [106.813, -94.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [20.125, -260.75],
                                  [-151.75, -239.125],
                                  [-250.687, -74.937],
                                  [-254.562, 146.125],
                                  [-177.687, 241.688],
                                  [97.063, 321.188],
                                  [352.375, 265.687],
                                  [283.687, 85],
                                  [107.063, -93.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [20.25, -260.125],
                                  [-150.875, -239.25],
                                  [-251, -75.312],
                                  [-254.312, 146.5],
                                  [-177.5, 241.063],
                                  [97.062, 321.875],
                                  [352.812, 265.188],
                                  [283.187, 84.75],
                                  [107.25, -92.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [20.375, -259.563],
                                  [-150, -239.312],
                                  [-251.25, -75.687],
                                  [-254, 146.812],
                                  [-177.312, 240.438],
                                  [97.062, 322.563],
                                  [353.25, 264.688],
                                  [282.75, 84.5],
                                  [107.438, -91.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [20.562, -258.938],
                                  [-149.125, -239.375],
                                  [-251.562, -76.062],
                                  [-253.688, 147.125],
                                  [-177.187, 239.75],
                                  [97.062, 323.25],
                                  [353.625, 264.125],
                                  [282.312, 84.25],
                                  [107.688, -91.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [20.687, -258.313],
                                  [-148.313, -239.5],
                                  [-251.875, -76.375],
                                  [-253.312, 147.438],
                                  [-177, 239.063],
                                  [97.125, 324],
                                  [354.062, 263.562],
                                  [281.875, 84.062],
                                  [107.875, -90.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [20.875, -257.75],
                                  [-147.438, -239.625],
                                  [-252.187, -76.75],
                                  [-252.875, 147.688],
                                  [-176.875, 238.438],
                                  [97.187, 324.75],
                                  [354.375, 262.938],
                                  [281.437, 83.937],
                                  [108.063, -89.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [21.062, -257.125],
                                  [-146.563, -239.75],
                                  [-252.5, -77.062],
                                  [-252.438, 148],
                                  [-176.75, 237.75],
                                  [97.312, 325.5],
                                  [354.687, 262.312],
                                  [281.062, 83.812],
                                  [108.25, -89],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [21.25, -256.5],
                                  [-145.75, -239.875],
                                  [-252.875, -77.375],
                                  [-251.938, 148.25],
                                  [-176.625, 237.125],
                                  [97.437, 326.313],
                                  [355, 261.688],
                                  [280.625, 83.687],
                                  [108.438, -88.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [21.437, -255.938],
                                  [-144.938, -240],
                                  [-253.25, -77.687],
                                  [-251.438, 148.5],
                                  [-176.562, 236.438],
                                  [97.562, 327.063],
                                  [355.25, 261],
                                  [280.312, 83.625],
                                  [108.625, -87.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [21.687, -255.313],
                                  [-144.125, -240.125],
                                  [-253.625, -78],
                                  [-250.938, 148.75],
                                  [-176.437, 235.813],
                                  [97.75, 327.875],
                                  [355.5, 260.312],
                                  [279.937, 83.625],
                                  [108.813, -87.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [21.937, -254.688],
                                  [-143.313, -240.312],
                                  [-254, -78.312],
                                  [-250.375, 148.938],
                                  [-176.375, 235.125],
                                  [97.937, 328.688],
                                  [355.687, 259.625],
                                  [279.562, 83.625],
                                  [109, -86.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [22.187, -254.125],
                                  [-142.5, -240.5],
                                  [-254.375, -78.562],
                                  [-249.812, 149.188],
                                  [-176.312, 234.5],
                                  [98.125, 329.5],
                                  [355.875, 258.875],
                                  [279.25, 83.625],
                                  [109.125, -85.938],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [22.437, -253.563],
                                  [-141.75, -240.625],
                                  [-254.812, -78.875],
                                  [-249.25, 149.375],
                                  [-176.312, 233.875],
                                  [98.375, 330.313],
                                  [356.062, 258.125],
                                  [278.937, 83.687],
                                  [109.313, -85.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [22.687, -253],
                                  [-141, -240.812],
                                  [-255.187, -79.125],
                                  [-248.625, 149.5],
                                  [-176.25, 233.25],
                                  [98.625, 331.063],
                                  [356.187, 257.375],
                                  [278.687, 83.812],
                                  [109.438, -84.938],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [23, -252.438],
                                  [-140.25, -241],
                                  [-255.625, -79.437],
                                  [-248.062, 149.688],
                                  [-176.25, 232.625],
                                  [98.875, 331.875],
                                  [356.25, 256.625],
                                  [278.437, 83.937],
                                  [109.625, -84.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [23.312, -251.875],
                                  [-139.563, -241.187],
                                  [-256.062, -79.687],
                                  [-247.438, 149.813],
                                  [-176.25, 232.063],
                                  [99.125, 332.688],
                                  [356.312, 255.875],
                                  [278.187, 84.062],
                                  [109.75, -84],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [23.625, -251.313],
                                  [-138.813, -241.375],
                                  [-256.5, -79.937],
                                  [-246.812, 149.938],
                                  [-176.25, 231.5],
                                  [99.437, 333.5],
                                  [356.375, 255.063],
                                  [277.937, 84.25],
                                  [109.875, -83.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [23.937, -250.813],
                                  [-138.125, -241.625],
                                  [-256.937, -80.187],
                                  [-246.188, 150],
                                  [-176.312, 230.938],
                                  [99.75, 334.25],
                                  [356.375, 254.25],
                                  [277.75, 84.437],
                                  [109.938, -83.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [24.312, -250.313],
                                  [-137.5, -241.812],
                                  [-257.375, -80.437],
                                  [-245.625, 150.062],
                                  [-176.312, 230.375],
                                  [100.125, 335.063],
                                  [356.312, 253.438],
                                  [277.562, 84.687],
                                  [110.063, -82.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [24.687, -249.813],
                                  [-136.875, -242],
                                  [-257.875, -80.625],
                                  [-245, 150.125],
                                  [-176.375, 229.875],
                                  [100.5, 335.813],
                                  [356.25, 252.625],
                                  [277.375, 84.937],
                                  [110.125, -82.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [25.062, -249.375],
                                  [-136.25, -242.25],
                                  [-258.312, -80.875],
                                  [-244.375, 150.188],
                                  [-176.437, 229.375],
                                  [100.812, 336.563],
                                  [356.187, 251.875],
                                  [277.25, 85.25],
                                  [110.188, -82.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [25.437, -248.938],
                                  [-135.688, -242.5],
                                  [-258.812, -81.062],
                                  [-243.812, 150.188],
                                  [-176.5, 228.938],
                                  [101.25, 337.313],
                                  [356.062, 251.063],
                                  [277.125, 85.562],
                                  [110.25, -82.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [25.812, -248.5],
                                  [-135.063, -242.75],
                                  [-259.25, -81.312],
                                  [-243.188, 150.188],
                                  [-176.625, 228.5],
                                  [101.625, 338],
                                  [355.875, 250.25],
                                  [277, 85.875],
                                  [110.313, -81.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [26.25, -248.125],
                                  [-134.563, -243],
                                  [-259.75, -81.5],
                                  [-242.625, 150.125],
                                  [-176.687, 228.063],
                                  [102.062, 338.688],
                                  [355.687, 249.438],
                                  [276.937, 86.187],
                                  [110.375, -81.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [26.625, -247.75],
                                  [-134.063, -243.25],
                                  [-260.25, -81.687],
                                  [-242.062, 150.125],
                                  [-176.812, 227.688],
                                  [102.5, 339.375],
                                  [355.5, 248.625],
                                  [276.875, 86.562],
                                  [110.375, -81.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [27.062, -247.375],
                                  [-133.563, -243.5],
                                  [-260.75, -81.875],
                                  [-241.5, 150.062],
                                  [-176.875, 227.313],
                                  [102.938, 340],
                                  [355.25, 247.813],
                                  [276.812, 86.937],
                                  [110.375, -81.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [27.562, -247.063],
                                  [-133.063, -243.75],
                                  [-261.25, -82.062],
                                  [-241, 149.938],
                                  [-177, 227],
                                  [103.375, 340.625],
                                  [355, 247],
                                  [276.812, 87.375],
                                  [110.375, -81.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [28, -246.813],
                                  [-132.625, -244.062],
                                  [-261.75, -82.25],
                                  [-240.5, 149.813],
                                  [-177.125, 226.75],
                                  [103.875, 341.25],
                                  [354.687, 246.25],
                                  [276.812, 87.75],
                                  [110.313, -81.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [28.5, -246.563],
                                  [-132.25, -244.312],
                                  [-262.25, -82.437],
                                  [-240, 149.688],
                                  [-177.312, 226.5],
                                  [104.375, 341.813],
                                  [354.375, 245.5],
                                  [276.875, 88.187],
                                  [110.313, -81.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [28.937, -246.313],
                                  [-131.813, -244.625],
                                  [-262.75, -82.625],
                                  [-239.5, 149.562],
                                  [-177.437, 226.25],
                                  [104.812, 342.313],
                                  [354, 244.688],
                                  [276.937, 88.625],
                                  [110.25, -81.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [29.437, -246.125],
                                  [-131.5, -244.937],
                                  [-263.25, -82.75],
                                  [-239.125, 149.375],
                                  [-177.562, 226.063],
                                  [105.375, 342.813],
                                  [353.625, 243.938],
                                  [277.062, 89.062],
                                  [110.188, -81.75],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [30, -245.938],
                                  [-131.125, -245.187],
                                  [-263.75, -82.937],
                                  [-238.688, 149.188],
                                  [-177.75, 225.938],
                                  [105.875, 343.25],
                                  [353.187, 243.25],
                                  [277.187, 89.5],
                                  [110.063, -81.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [30.5, -245.813],
                                  [-130.813, -245.5],
                                  [-264.25, -83.125],
                                  [-238.312, 149],
                                  [-177.937, 225.875],
                                  [106.375, 343.688],
                                  [352.75, 242.5],
                                  [277.312, 89.937],
                                  [109.938, -82.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [31, -245.75],
                                  [-130.563, -245.875],
                                  [-264.75, -83.25],
                                  [-237.938, 148.75],
                                  [-178.062, 225.813],
                                  [106.937, 344.063],
                                  [352.25, 241.813],
                                  [277.5, 90.437],
                                  [109.813, -82.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [31.562, -245.688],
                                  [-130.313, -246.187],
                                  [-265.25, -83.375],
                                  [-237.688, 148.5],
                                  [-178.25, 225.813],
                                  [107.437, 344.375],
                                  [351.75, 241.188],
                                  [277.687, 90.875],
                                  [109.688, -82.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [32.125, -245.625],
                                  [-130.063, -246.5],
                                  [-265.75, -83.562],
                                  [-237.375, 148.25],
                                  [-178.437, 225.813],
                                  [107.937, 344.688],
                                  [351.25, 240.5],
                                  [277.875, 91.375],
                                  [109.5, -83.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [32.687, -245.688],
                                  [-129.875, -246.875],
                                  [-266.25, -83.687],
                                  [-237.063, 148],
                                  [-178.625, 225.938],
                                  [108.437, 344.938],
                                  [350.687, 239.938],
                                  [278.062, 91.875],
                                  [109.313, -83.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [33.25, -245.75],
                                  [-129.688, -247.25],
                                  [-266.75, -83.812],
                                  [-236.75, 147.688],
                                  [-178.812, 226.063],
                                  [108.875, 345.063],
                                  [350.125, 239.313],
                                  [278.312, 92.312],
                                  [109.125, -83.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [33.812, -245.813],
                                  [-129.563, -247.562],
                                  [-267.25, -83.937],
                                  [-236.375, 147.375],
                                  [-179, 226.25],
                                  [109.375, 345.25],
                                  [349.5, 238.75],
                                  [278.562, 92.812],
                                  [108.938, -84.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [34.437, -246],
                                  [-129.5, -247.875],
                                  [-267.75, -84.062],
                                  [-236.063, 147.063],
                                  [-179.187, 226.438],
                                  [109.812, 345.313],
                                  [348.875, 238.25],
                                  [278.812, 93.312],
                                  [108.688, -84.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [35, -246.188],
                                  [-129.438, -248.187],
                                  [-268.25, -84.187],
                                  [-235.688, 146.75],
                                  [-179.375, 226.688],
                                  [110.25, 345.375],
                                  [348.25, 237.75],
                                  [279.062, 93.812],
                                  [108.5, -85.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [35.625, -246.375],
                                  [-129.5, -248.5],
                                  [-268.75, -84.312],
                                  [-235.313, 146.375],
                                  [-179.562, 227.063],
                                  [110.687, 345.375],
                                  [347.562, 237.25],
                                  [279.375, 94.312],
                                  [108.25, -85.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [36.25, -246.688],
                                  [-129.625, -248.75],
                                  [-269.187, -84.437],
                                  [-234.938, 146.063],
                                  [-179.75, 227.438],
                                  [111.125, 345.313],
                                  [346.937, 236.75],
                                  [279.687, 94.812],
                                  [108.063, -86.25],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [36.875, -247],
                                  [-129.75, -249],
                                  [-269.687, -84.562],
                                  [-234.563, 145.688],
                                  [-179.937, 227.875],
                                  [111.5, 345.25],
                                  [346.312, 236.313],
                                  [280, 95.312],
                                  [107.875, -86.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [37.5, -247.375],
                                  [-130, -249.25],
                                  [-270.125, -84.625],
                                  [-234.125, 145.313],
                                  [-180.187, 228.313],
                                  [111.875, 345.063],
                                  [345.687, 235.813],
                                  [280.312, 95.75],
                                  [107.688, -87.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [38.187, -247.75],
                                  [-130.25, -249.437],
                                  [-270.625, -84.75],
                                  [-233.75, 144.938],
                                  [-180.375, 228.813],
                                  [112.25, 344.938],
                                  [345.125, 235.375],
                                  [280.625, 96.25],
                                  [107.563, -87.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [38.812, -248.25],
                                  [-130.563, -249.687],
                                  [-271.062, -84.875],
                                  [-233.313, 144.563],
                                  [-180.562, 229.375],
                                  [112.562, 344.688],
                                  [344.562, 234.875],
                                  [280.937, 96.75],
                                  [107.375, -87.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [39.437, -248.75],
                                  [-130.938, -249.812],
                                  [-271.5, -84.937],
                                  [-232.875, 144.125],
                                  [-180.75, 229.938],
                                  [112.937, 344.438],
                                  [344, 234.438],
                                  [281.312, 97.187],
                                  [107.25, -88.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [40.125, -249.313],
                                  [-131.375, -250],
                                  [-271.875, -85.062],
                                  [-232.438, 143.75],
                                  [-181, 230.5],
                                  [113.25, 344.125],
                                  [343.437, 234],
                                  [281.625, 97.687],
                                  [107.125, -88.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [40.812, -249.875],
                                  [-131.813, -250.125],
                                  [-272.25, -85.187],
                                  [-232, 143.375],
                                  [-181.187, 231.125],
                                  [113.562, 343.813],
                                  [342.937, 233.563],
                                  [282, 98.125],
                                  [107, -88.75],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [41.437, -250.438],
                                  [-132.375, -250.312],
                                  [-272.625, -85.312],
                                  [-231.563, 143],
                                  [-181.437, 231.813],
                                  [113.812, 343.438],
                                  [342.5, 233.188],
                                  [282.375, 98.562],
                                  [106.875, -89.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [42.062, -251],
                                  [-132.938, -250.437],
                                  [-273, -85.437],
                                  [-231.125, 142.563],
                                  [-181.625, 232.5],
                                  [114.062, 343],
                                  [342, 232.75],
                                  [282.687, 99],
                                  [106.813, -89.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [42.687, -251.563],
                                  [-133.563, -250.5],
                                  [-273.312, -85.562],
                                  [-230.688, 142.188],
                                  [-181.875, 233.188],
                                  [114.312, 342.563],
                                  [341.562, 232.375],
                                  [283.062, 99.437],
                                  [106.688, -89.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [43.312, -252.188],
                                  [-134.25, -250.625],
                                  [-273.625, -85.75],
                                  [-230.25, 141.813],
                                  [-182.062, 233.875],
                                  [114.562, 342.063],
                                  [341.125, 232.063],
                                  [283.437, 99.875],
                                  [106.625, -89.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [43.937, -252.75],
                                  [-134.938, -250.687],
                                  [-273.875, -85.937],
                                  [-229.813, 141.375],
                                  [-182.312, 234.625],
                                  [114.75, 341.563],
                                  [340.75, 231.688],
                                  [283.812, 100.25],
                                  [106.625, -90.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [44.5, -253.313],
                                  [-135.688, -250.75],
                                  [-274.125, -86.062],
                                  [-229.375, 141],
                                  [-182.5, 235.438],
                                  [114.937, 341],
                                  [340.375, 231.375],
                                  [284.187, 100.687],
                                  [106.563, -90.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [45.062, -253.875],
                                  [-136.438, -250.812],
                                  [-274.375, -86.25],
                                  [-228.875, 140.625],
                                  [-182.75, 236.188],
                                  [115.125, 340.438],
                                  [340, 231.063],
                                  [284.562, 101.062],
                                  [106.563, -90.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [45.625, -254.5],
                                  [-137.313, -250.812],
                                  [-274.625, -86.5],
                                  [-228.438, 140.25],
                                  [-182.937, 237],
                                  [115.25, 339.813],
                                  [339.687, 230.813],
                                  [284.937, 101.375],
                                  [106.563, -90.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [46.187, -255.063],
                                  [-138.188, -250.875],
                                  [-274.812, -86.687],
                                  [-228, 139.875],
                                  [-183.187, 237.813],
                                  [115.375, 339.188],
                                  [339.375, 230.563],
                                  [285.25, 101.75],
                                  [106.563, -90.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [46.687, -255.625],
                                  [-139.063, -250.875],
                                  [-275.062, -86.937],
                                  [-227.563, 139.563],
                                  [-183.437, 238.625],
                                  [115.5, 338.5],
                                  [339.125, 230.375],
                                  [285.625, 102.062],
                                  [106.563, -91],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [47.187, -256.188],
                                  [-140, -250.875],
                                  [-275.187, -87.125],
                                  [-227.125, 139.188],
                                  [-183.625, 239.438],
                                  [115.562, 337.813],
                                  [338.875, 230.188],
                                  [286, 102.375],
                                  [106.625, -91.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [47.687, -256.813],
                                  [-141, -250.875],
                                  [-275.375, -87.375],
                                  [-226.625, 138.875],
                                  [-183.875, 240.313],
                                  [115.625, 337.125],
                                  [338.625, 230.063],
                                  [286.312, 102.687],
                                  [106.688, -91.25],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [48.125, -257.375],
                                  [-142, -250.812],
                                  [-275.562, -87.625],
                                  [-226.188, 138.563],
                                  [-184.062, 241.125],
                                  [115.687, 336.375],
                                  [338.437, 229.938],
                                  [286.687, 102.937],
                                  [106.75, -91.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [48.562, -258],
                                  [-143, -250.812],
                                  [-275.687, -87.937],
                                  [-225.75, 138.25],
                                  [-184.312, 242],
                                  [115.75, 335.625],
                                  [338.25, 229.813],
                                  [287, 103.187],
                                  [106.875, -91.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [48.937, -258.563],
                                  [-144.063, -250.75],
                                  [-275.812, -88.187],
                                  [-225.375, 137.938],
                                  [-184.5, 242.813],
                                  [115.75, 334.813],
                                  [338.125, 229.75],
                                  [287.312, 103.437],
                                  [107, -91.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [49.375, -259.188],
                                  [-145.125, -250.75],
                                  [-275.937, -88.5],
                                  [-224.938, 137.688],
                                  [-184.75, 243.688],
                                  [115.687, 334.063],
                                  [338, 229.75],
                                  [287.625, 103.625],
                                  [107.125, -91.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [49.75, -259.75],
                                  [-146.25, -250.687],
                                  [-276.062, -88.812],
                                  [-224.5, 137.375],
                                  [-185, 244.563],
                                  [115.687, 333.25],
                                  [337.875, 229.75],
                                  [287.937, 103.812],
                                  [107.25, -91.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [50.062, -260.375],
                                  [-147.375, -250.625],
                                  [-276.125, -89.187],
                                  [-224.063, 137.188],
                                  [-185.187, 245.375],
                                  [115.625, 332.438],
                                  [337.875, 229.75],
                                  [288.25, 104],
                                  [107.438, -91.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [50.375, -260.938],
                                  [-148.563, -250.562],
                                  [-276.25, -89.5],
                                  [-223.688, 136.938],
                                  [-185.437, 246.25],
                                  [115.562, 331.563],
                                  [337.812, 229.875],
                                  [288.562, 104.125],
                                  [107.625, -91.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [50.687, -261.563],
                                  [-149.75, -250.5],
                                  [-276.312, -89.812],
                                  [-223.25, 136.75],
                                  [-185.687, 247.063],
                                  [115.438, 330.75],
                                  [337.812, 229.938],
                                  [288.812, 104.25],
                                  [107.813, -91.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [50.937, -262.125],
                                  [-150.875, -250.437],
                                  [-276.375, -90.187],
                                  [-222.875, 136.563],
                                  [-185.875, 247.938],
                                  [115.312, 329.875],
                                  [337.875, 230.063],
                                  [289.125, 104.375],
                                  [108.063, -91.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [51.25, -262.75],
                                  [-152.125, -250.375],
                                  [-276.437, -90.562],
                                  [-222.438, 136.375],
                                  [-186.125, 248.75],
                                  [115.188, 329.063],
                                  [337.937, 230.25],
                                  [289.375, 104.437],
                                  [108.313, -91.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [51.437, -263.375],
                                  [-153.313, -250.312],
                                  [-276.5, -90.937],
                                  [-222.063, 136.25],
                                  [-186.312, 249.5],
                                  [115.062, 328.188],
                                  [338.062, 230.438],
                                  [289.625, 104.5],
                                  [108.563, -91.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [51.687, -264],
                                  [-154.563, -250.187],
                                  [-276.5, -91.312],
                                  [-221.688, 136.125],
                                  [-186.562, 250.313],
                                  [114.875, 327.313],
                                  [338.187, 230.688],
                                  [289.812, 104.5],
                                  [108.875, -91.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [51.875, -264.625],
                                  [-155.812, -250.125],
                                  [-276.562, -91.75],
                                  [-221.313, 136.063],
                                  [-186.812, 251.063],
                                  [114.688, 326.438],
                                  [338.375, 231],
                                  [290.062, 104.5],
                                  [109.188, -91.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52, -265.25],
                                  [-157, -250.062],
                                  [-276.562, -92.125],
                                  [-221, 136],
                                  [-187, 251.813],
                                  [114.438, 325.563],
                                  [338.625, 231.25],
                                  [290.25, 104.437],
                                  [109.5, -91.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.187, -265.875],
                                  [-158.25, -250],
                                  [-276.625, -92.5],
                                  [-220.625, 135.938],
                                  [-187.25, 252.563],
                                  [114.25, 324.688],
                                  [338.875, 231.625],
                                  [290.437, 104.437],
                                  [109.875, -90.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.312, -266.5],
                                  [-159.5, -249.875],
                                  [-276.625, -92.937],
                                  [-220.313, 135.938],
                                  [-187.437, 253.25],
                                  [114, 323.813],
                                  [339.187, 232],
                                  [290.562, 104.312],
                                  [110.25, -90.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.437, -267.125],
                                  [-160.75, -249.812],
                                  [-276.625, -93.375],
                                  [-219.938, 135.938],
                                  [-187.687, 253.875],
                                  [113.688, 322.938],
                                  [339.5, 232.375],
                                  [290.75, 104.187],
                                  [110.688, -90.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.5, -267.75],
                                  [-162, -249.75],
                                  [-276.687, -93.75],
                                  [-219.625, 136],
                                  [-187.875, 254.5],
                                  [113.438, 322.125],
                                  [339.937, 232.813],
                                  [290.875, 104.062],
                                  [111.125, -90.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.562, -268.375],
                                  [-163.25, -249.687],
                                  [-276.687, -94.187],
                                  [-219.313, 136.063],
                                  [-188.125, 255.125],
                                  [113.125, 321.25],
                                  [340.375, 233.25],
                                  [291, 103.875],
                                  [111.563, -89.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.625, -269],
                                  [-164.5, -249.625],
                                  [-276.687, -94.625],
                                  [-219, 136.125],
                                  [-188.312, 255.688],
                                  [112.75, 320.438],
                                  [340.812, 233.75],
                                  [291.062, 103.687],
                                  [112.063, -89.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.687, -269.625],
                                  [-165.75, -249.562],
                                  [-276.687, -95],
                                  [-218.75, 136.25],
                                  [-188.562, 256.188],
                                  [112.438, 319.625],
                                  [341.312, 234.25],
                                  [291.187, 103.5],
                                  [112.563, -89.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.75, -270.313],
                                  [-167, -249.5],
                                  [-276.687, -95.437],
                                  [-218.438, 136.438],
                                  [-188.75, 256.688],
                                  [112.062, 318.813],
                                  [341.875, 234.75],
                                  [291.25, 103.25],
                                  [113.125, -88.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.75, -270.938],
                                  [-168.188, -249.437],
                                  [-276.687, -95.812],
                                  [-218.188, 136.625],
                                  [-188.937, 257.063],
                                  [111.688, 318],
                                  [342.375, 235.313],
                                  [291.25, 102.937],
                                  [113.625, -88.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.75, -271.563],
                                  [-169.438, -249.375],
                                  [-276.75, -96.25],
                                  [-217.938, 136.875],
                                  [-189.187, 257.438],
                                  [111.312, 317.25],
                                  [342.937, 235.813],
                                  [291.312, 102.625],
                                  [114.188, -88.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.75, -272.25],
                                  [-170.625, -249.312],
                                  [-276.75, -96.625],
                                  [-217.688, 137.125],
                                  [-189.437, 257.813],
                                  [110.875, 316.5],
                                  [343.437, 236.375],
                                  [291.312, 102.312],
                                  [114.813, -87.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.75, -272.875],
                                  [-171.75, -249.312],
                                  [-276.75, -97],
                                  [-217.438, 137.375],
                                  [-189.687, 258.063],
                                  [110.438, 315.75],
                                  [344, 236.938],
                                  [291.312, 101.937],
                                  [115.375, -87.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.75, -273.563],
                                  [-172.938, -249.25],
                                  [-276.687, -97.437],
                                  [-217.25, 137.688],
                                  [-189.937, 258.313],
                                  [110, 315.063],
                                  [344.562, 237.5],
                                  [291.25, 101.562],
                                  [116, -86.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.687, -274.188],
                                  [-174.062, -249.25],
                                  [-276.625, -97.812],
                                  [-217, 138.062],
                                  [-190.187, 258.5],
                                  [109.563, 314.375],
                                  [345.125, 238.063],
                                  [291.187, 101.125],
                                  [116.625, -86.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.562, -274.75],
                                  [-175.188, -249.25],
                                  [-276.562, -98.25],
                                  [-216.813, 138.438],
                                  [-190.5, 258.625],
                                  [109.063, 313.75],
                                  [345.687, 238.625],
                                  [291.125, 100.75],
                                  [117.25, -86],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.375, -275.25],
                                  [-176.312, -249.25],
                                  [-276.5, -98.625],
                                  [-216.625, 138.875],
                                  [-190.75, 258.75],
                                  [108.563, 313.125],
                                  [346.25, 239.188],
                                  [291.062, 100.312],
                                  [117.875, -85.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [52.125, -275.688],
                                  [-177.375, -249.25],
                                  [-276.375, -99.062],
                                  [-216.438, 139.312],
                                  [-191.062, 258.813],
                                  [108.063, 312.5],
                                  [346.812, 239.75],
                                  [291, 99.875],
                                  [118.563, -85.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [51.875, -276.125],
                                  [-178.375, -249.312],
                                  [-276.25, -99.5],
                                  [-216.313, 139.812],
                                  [-191.375, 258.813],
                                  [107.625, 311.938],
                                  [347.437, 240.313],
                                  [290.875, 99.5],
                                  [119.25, -84.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [51.5, -276.438],
                                  [-179.437, -249.312],
                                  [-276.062, -99.875],
                                  [-216.125, 140.312],
                                  [-191.687, 258.813],
                                  [107.125, 311.313],
                                  [348, 240.938],
                                  [290.812, 99.125],
                                  [119.875, -84.25],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [51.125, -276.75],
                                  [-180.375, -249.375],
                                  [-275.937, -100.312],
                                  [-216, 140.875],
                                  [-192, 258.75],
                                  [106.688, 310.75],
                                  [348.562, 241.5],
                                  [290.687, 98.812],
                                  [120.563, -83.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [50.687, -277.063],
                                  [-181.375, -249.437],
                                  [-275.75, -100.687],
                                  [-215.875, 141.438],
                                  [-192.312, 258.625],
                                  [106.25, 310.188],
                                  [349.125, 242.063],
                                  [290.562, 98.437],
                                  [121.25, -83.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [50.187, -277.25],
                                  [-182.312, -249.5],
                                  [-275.5, -101.125],
                                  [-215.813, 142],
                                  [-192.625, 258.5],
                                  [105.875, 309.563],
                                  [349.687, 242.625],
                                  [290.5, 98.125],
                                  [121.938, -82.938],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [49.625, -277.438],
                                  [-183.187, -249.625],
                                  [-275.312, -101.5],
                                  [-215.75, 142.625],
                                  [-193, 258.313],
                                  [105.5, 309.063],
                                  [350.187, 243.25],
                                  [290.375, 97.812],
                                  [122.625, -82.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [49, -277.563],
                                  [-184.062, -249.687],
                                  [-275.062, -101.875],
                                  [-215.688, 143.25],
                                  [-193.312, 258.125],
                                  [105.125, 308.5],
                                  [350.75, 243.813],
                                  [290.25, 97.5],
                                  [123.313, -82.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [48.375, -277.625],
                                  [-185, -249.812],
                                  [-274.812, -102.25],
                                  [-215.688, 143.938],
                                  [-193.687, 257.875],
                                  [104.812, 308],
                                  [351.312, 244.375],
                                  [290.125, 97.187],
                                  [123.938, -81.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [47.687, -277.625],
                                  [-185.875, -249.937],
                                  [-274.562, -102.625],
                                  [-215.688, 144.562],
                                  [-194, 257.563],
                                  [104.5, 307.5],
                                  [351.812, 244.938],
                                  [290, 96.875],
                                  [124.625, -81.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [47, -277.625],
                                  [-186.687, -250.125],
                                  [-274.312, -103],
                                  [-215.688, 145.312],
                                  [-194.375, 257.25],
                                  [104.188, 307],
                                  [352.312, 245.5],
                                  [289.812, 96.625],
                                  [125.313, -80.75],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [46.25, -277.625],
                                  [-187.562, -250.25],
                                  [-274, -103.312],
                                  [-215.75, 146],
                                  [-194.75, 256.938],
                                  [103.938, 306.563],
                                  [352.812, 246],
                                  [289.687, 96.375],
                                  [126, -80.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [45.437, -277.5],
                                  [-188.375, -250.437],
                                  [-273.687, -103.687],
                                  [-215.813, 146.75],
                                  [-195.125, 256.563],
                                  [103.688, 306.125],
                                  [353.312, 246.563],
                                  [289.562, 96.125],
                                  [126.625, -80],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [44.625, -277.375],
                                  [-189.187, -250.687],
                                  [-273.375, -104],
                                  [-215.875, 147.5],
                                  [-195.5, 256.125],
                                  [103.438, 305.75],
                                  [353.75, 247.063],
                                  [289.375, 95.875],
                                  [127.313, -79.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [43.75, -277.188],
                                  [-189.937, -250.875],
                                  [-273.062, -104.312],
                                  [-215.938, 148.25],
                                  [-195.875, 255.688],
                                  [103.25, 305.375],
                                  [354.187, 247.625],
                                  [289.25, 95.625],
                                  [127.938, -79.25],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [42.812, -277],
                                  [-190.687, -251.125],
                                  [-272.75, -104.562],
                                  [-216.063, 149.062],
                                  [-196.25, 255.25],
                                  [103.063, 305],
                                  [354.625, 248.125],
                                  [289.062, 95.437],
                                  [128.563, -78.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [41.937, -276.75],
                                  [-191.437, -251.375],
                                  [-272.375, -104.875],
                                  [-216.188, 149.812],
                                  [-196.625, 254.75],
                                  [102.875, 304.75],
                                  [355.062, 248.625],
                                  [288.937, 95.25],
                                  [129.188, -78.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [41, -276.5],
                                  [-192.187, -251.687],
                                  [-272, -105.125],
                                  [-216.375, 150.625],
                                  [-197, 254.188],
                                  [102.75, 304.5],
                                  [355.437, 249.063],
                                  [288.75, 95.062],
                                  [129.813, -78.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [40, -276.188],
                                  [-192.875, -251.937],
                                  [-271.687, -105.312],
                                  [-216.563, 151.438],
                                  [-197.375, 253.688],
                                  [102.625, 304.25],
                                  [355.812, 249.563],
                                  [288.562, 94.937],
                                  [130.375, -77.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [39, -275.875],
                                  [-193.5, -252.25],
                                  [-271.312, -105.562],
                                  [-216.75, 152.312],
                                  [-197.75, 253.063],
                                  [102.563, 304.063],
                                  [356.187, 250],
                                  [288.437, 94.75],
                                  [130.938, -77.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [38, -275.5],
                                  [-194.125, -252.625],
                                  [-270.875, -105.75],
                                  [-216.938, 153.125],
                                  [-198.125, 252.5],
                                  [102.5, 303.938],
                                  [356.5, 250.438],
                                  [288.25, 94.625],
                                  [131.5, -77.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [36.937, -275.125],
                                  [-194.75, -252.937],
                                  [-270.5, -105.937],
                                  [-217.188, 153.938],
                                  [-198.5, 251.875],
                                  [102.438, 303.813],
                                  [356.812, 250.813],
                                  [288.062, 94.562],
                                  [132.063, -77.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [35.937, -274.688],
                                  [-195.312, -253.312],
                                  [-270.125, -106.062],
                                  [-217.438, 154.813],
                                  [-198.875, 251.25],
                                  [102.438, 303.75],
                                  [357.125, 251.25],
                                  [287.875, 94.437],
                                  [132.563, -76.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [34.812, -274.25],
                                  [-195.875, -253.75],
                                  [-269.687, -106.187],
                                  [-217.688, 155.625],
                                  [-199.312, 250.625],
                                  [102.438, 303.75],
                                  [357.375, 251.625],
                                  [287.75, 94.375],
                                  [133.063, -76.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [33.75, -273.75],
                                  [-196.375, -254.125],
                                  [-269.25, -106.312],
                                  [-218, 156.438],
                                  [-199.687, 249.938],
                                  [102.5, 303.813],
                                  [357.625, 252],
                                  [287.562, 94.312],
                                  [133.563, -76.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [32.687, -273.25],
                                  [-196.875, -254.562],
                                  [-268.875, -106.375],
                                  [-218.25, 157.312],
                                  [-200.062, 249.25],
                                  [102.563, 303.875],
                                  [357.812, 252.313],
                                  [287.375, 94.312],
                                  [134, -76.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [31.562, -272.75],
                                  [-197.312, -255],
                                  [-268.437, -106.437],
                                  [-218.563, 158.125],
                                  [-200.437, 248.563],
                                  [102.625, 304.063],
                                  [358, 252.688],
                                  [287.25, 94.312],
                                  [134.438, -76.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [30.5, -272.188],
                                  [-197.75, -255.5],
                                  [-268, -106.437],
                                  [-218.938, 158.937],
                                  [-200.812, 247.875],
                                  [102.75, 304.25],
                                  [358.125, 252.938],
                                  [287.062, 94.312],
                                  [134.875, -76.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [29.375, -271.625],
                                  [-198.125, -255.937],
                                  [-267.562, -106.437],
                                  [-219.25, 159.75],
                                  [-201.25, 247.125],
                                  [102.875, 304.438],
                                  [358.25, 253.25],
                                  [286.937, 94.375],
                                  [135.25, -76],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [28.25, -271.063],
                                  [-198.5, -256.437],
                                  [-267.062, -106.437],
                                  [-219.625, 160.562],
                                  [-201.625, 246.438],
                                  [103, 304.75],
                                  [358.375, 253.5],
                                  [286.75, 94.437],
                                  [135.563, -75.938],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [27.187, -270.438],
                                  [-198.812, -257],
                                  [-266.625, -106.375],
                                  [-220, 161.312],
                                  [-202, 245.688],
                                  [103.188, 305.125],
                                  [358.437, 253.75],
                                  [286.625, 94.562],
                                  [135.938, -75.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [26.062, -269.813],
                                  [-199.062, -257.5],
                                  [-266.187, -106.25],
                                  [-220.375, 162.062],
                                  [-202.375, 244.938],
                                  [103.438, 305.5],
                                  [358.5, 253.938],
                                  [286.5, 94.687],
                                  [136.25, -75.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [24.937, -269.188],
                                  [-199.312, -258.062],
                                  [-265.687, -106.187],
                                  [-220.813, 162.812],
                                  [-202.75, 244.188],
                                  [103.688, 305.938],
                                  [358.5, 254.188],
                                  [286.375, 94.875],
                                  [136.5, -75.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [23.875, -268.563],
                                  [-199.5, -258.625],
                                  [-265.25, -106],
                                  [-221.188, 163.562],
                                  [-203.125, 243.438],
                                  [103.938, 306.438],
                                  [358.5, 254.313],
                                  [286.25, 95.062],
                                  [136.75, -75.938],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [22.75, -267.938],
                                  [-199.625, -259.25],
                                  [-264.812, -105.812],
                                  [-221.625, 164.25],
                                  [-203.5, 242.688],
                                  [104.25, 307],
                                  [358.437, 254.5],
                                  [286.125, 95.312],
                                  [136.938, -76.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [21.687, -267.25],
                                  [-199.75, -259.812],
                                  [-264.312, -105.625],
                                  [-222.063, 164.875],
                                  [-203.875, 241.938],
                                  [104.563, 307.625],
                                  [358.312, 254.625],
                                  [286.062, 95.562],
                                  [137.125, -76.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [20.625, -266.563],
                                  [-199.812, -260.437],
                                  [-263.812, -105.375],
                                  [-222.5, 165.562],
                                  [-204.25, 241.188],
                                  [104.875, 308.313],
                                  [358.25, 254.688],
                                  [286, 95.875],
                                  [137.313, -76.25],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [19.562, -265.875],
                                  [-199.875, -261.062],
                                  [-263.375, -105.125],
                                  [-223, 166.125],
                                  [-204.625, 240.438],
                                  [105.25, 309.063],
                                  [358.062, 254.75],
                                  [285.937, 96.25],
                                  [137.438, -76.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [18.5, -265.25],
                                  [-199.875, -261.75],
                                  [-262.875, -104.812],
                                  [-223.438, 166.687],
                                  [-204.937, 239.688],
                                  [105.688, 309.813],
                                  [357.937, 254.813],
                                  [285.875, 96.625],
                                  [137.5, -76.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [17.5, -264.5],
                                  [-199.812, -262.375],
                                  [-262.438, -104.5],
                                  [-223.938, 167.187],
                                  [-205.312, 238.938],
                                  [106.063, 310.688],
                                  [357.687, 254.875],
                                  [285.875, 97.062],
                                  [137.563, -76.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [16.5, -263.813],
                                  [-199.75, -263.062],
                                  [-261.938, -104.125],
                                  [-224.438, 167.687],
                                  [-205.625, 238.188],
                                  [106.563, 311.563],
                                  [357.437, 254.875],
                                  [285.875, 97.562],
                                  [137.563, -77.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [15.5, -263.125],
                                  [-199.562, -263.75],
                                  [-261.438, -103.687],
                                  [-224.875, 168.125],
                                  [-206, 237.438],
                                  [107, 312.5],
                                  [357.187, 254.813],
                                  [285.875, 98],
                                  [137.563, -77.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [14.5, -262.438],
                                  [-199.437, -264.437],
                                  [-261, -103.25],
                                  [-225.375, 168.437],
                                  [-206.312, 236.75],
                                  [107.563, 313.438],
                                  [356.875, 254.813],
                                  [285.937, 98.562],
                                  [137.5, -77.75],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [13.562, -261.75],
                                  [-199.187, -265.125],
                                  [-260.5, -102.812],
                                  [-225.875, 168.812],
                                  [-206.687, 236],
                                  [108.063, 314.313],
                                  [356.562, 254.75],
                                  [286, 99.062],
                                  [137.438, -78.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [12.625, -261.063],
                                  [-198.937, -265.812],
                                  [-260, -102.312],
                                  [-226.438, 169.062],
                                  [-207, 235.313],
                                  [108.625, 315.25],
                                  [356.187, 254.625],
                                  [286.062, 99.625],
                                  [137.313, -78.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [11.75, -260.375],
                                  [-198.625, -266.562],
                                  [-259.562, -101.75],
                                  [-226.938, 169.312],
                                  [-207.312, 234.625],
                                  [109.188, 316.188],
                                  [355.812, 254.5],
                                  [286.187, 100.187],
                                  [137.188, -78.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [10.875, -259.688],
                                  [-198.312, -267.25],
                                  [-259.062, -101.187],
                                  [-227.5, 169.562],
                                  [-207.625, 233.938],
                                  [109.75, 317.125],
                                  [355.375, 254.375],
                                  [286.25, 100.812],
                                  [137, -79.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [10, -259],
                                  [-198.062, -267.937],
                                  [-258.625, -100.625],
                                  [-228, 169.75],
                                  [-207.937, 233.25],
                                  [110.375, 318],
                                  [354.937, 254.25],
                                  [286.375, 101.375],
                                  [136.813, -79.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [9.187, -258.375],
                                  [-197.75, -268.562],
                                  [-258.125, -100],
                                  [-228.563, 169.875],
                                  [-208.187, 232.625],
                                  [111, 318.938],
                                  [354.437, 254.063],
                                  [286.5, 102],
                                  [136.563, -80.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [8.437, -257.688],
                                  [-197.437, -269.25],
                                  [-257.625, -99.312],
                                  [-229.125, 170],
                                  [-208.5, 231.938],
                                  [111.625, 319.813],
                                  [353.937, 253.875],
                                  [286.687, 102.687],
                                  [136.375, -80.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [7.687, -257.063],
                                  [-197.187, -269.875],
                                  [-257.188, -98.625],
                                  [-229.75, 170.062],
                                  [-208.75, 231.375],
                                  [112.25, 320.75],
                                  [353.437, 253.625],
                                  [286.812, 103.312],
                                  [136.125, -81.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [6.937, -256.438],
                                  [-196.937, -270.437],
                                  [-256.75, -97.875],
                                  [-230.313, 170.125],
                                  [-209.062, 230.75],
                                  [112.875, 321.625],
                                  [352.875, 253.438],
                                  [287, 103.937],
                                  [135.875, -82.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [6.25, -255.813],
                                  [-196.625, -271.062],
                                  [-256.25, -97.125],
                                  [-230.875, 170.188],
                                  [-209.312, 230.188],
                                  [113.563, 322.5],
                                  [352.312, 253.188],
                                  [287.187, 104.625],
                                  [135.563, -82.75],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [5.625, -255.188],
                                  [-196.375, -271.562],
                                  [-255.812, -96.375],
                                  [-231.5, 170.125],
                                  [-209.5, 229.625],
                                  [114.188, 323.375],
                                  [351.687, 253],
                                  [287.375, 105.25],
                                  [135.313, -83.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [5, -254.563],
                                  [-196.125, -272.125],
                                  [-255.375, -95.562],
                                  [-232.063, 170.125],
                                  [-209.687, 229.063],
                                  [114.875, 324.188],
                                  [351.062, 252.75],
                                  [287.625, 105.937],
                                  [135, -84.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [4.437, -254],
                                  [-195.875, -272.625],
                                  [-254.938, -94.75],
                                  [-232.625, 170.062],
                                  [-209.875, 228.5],
                                  [115.5, 325.063],
                                  [350.437, 252.5],
                                  [287.812, 106.625],
                                  [134.688, -84.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [3.875, -253.438],
                                  [-195.625, -273.125],
                                  [-254.5, -93.875],
                                  [-233.25, 169.937],
                                  [-210.062, 228],
                                  [116.125, 325.875],
                                  [349.812, 252.25],
                                  [288, 107.25],
                                  [134.313, -85.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [3.375, -252.875],
                                  [-195.375, -273.562],
                                  [-254.125, -93],
                                  [-233.813, 169.812],
                                  [-210.187, 227.5],
                                  [116.813, 326.688],
                                  [349.125, 252],
                                  [288.25, 107.937],
                                  [134, -86.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [2.875, -252.375],
                                  [-195.125, -274],
                                  [-253.75, -92.062],
                                  [-234.438, 169.688],
                                  [-210.312, 227.063],
                                  [117.438, 327.5],
                                  [348.437, 251.75],
                                  [288.5, 108.562],
                                  [133.563, -87.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [2.375, -251.875],
                                  [-194.875, -274.375],
                                  [-253.375, -91.125],
                                  [-235, 169.5],
                                  [-210.375, 226.625],
                                  [118.062, 328.313],
                                  [347.75, 251.5],
                                  [288.75, 109.25],
                                  [133.188, -88],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [1.937, -251.375],
                                  [-194.625, -274.75],
                                  [-253, -90.187],
                                  [-235.625, 169.25],
                                  [-210.437, 226.188],
                                  [118.688, 329.063],
                                  [347, 251.25],
                                  [289, 109.875],
                                  [132.75, -88.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [1.5, -250.875],
                                  [-194.375, -275.062],
                                  [-252.625, -89.25],
                                  [-236.188, 169],
                                  [-210.5, 225.813],
                                  [119.312, 329.813],
                                  [346.25, 251],
                                  [289.187, 110.5],
                                  [132.313, -89.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [1.125, -250.438],
                                  [-194.125, -275.375],
                                  [-252.313, -88.25],
                                  [-236.813, 168.75],
                                  [-210.5, 225.438],
                                  [119.875, 330.5],
                                  [345.5, 250.75],
                                  [289.437, 111.125],
                                  [131.875, -90.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [0.75, -250],
                                  [-193.875, -275.687],
                                  [-252, -87.25],
                                  [-237.375, 168.5],
                                  [-210.5, 225.125],
                                  [120.5, 331.188],
                                  [344.75, 250.437],
                                  [289.687, 111.75],
                                  [131.375, -91.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [0.437, -249.563],
                                  [-193.625, -275.937],
                                  [-251.688, -86.25],
                                  [-237.938, 168.187],
                                  [-210.5, 224.875],
                                  [121.062, 331.875],
                                  [344, 250.187],
                                  [289.937, 112.375],
                                  [130.875, -92.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [0.125, -249.188],
                                  [-193.375, -276.125],
                                  [-251.375, -85.187],
                                  [-238.5, 167.812],
                                  [-210.437, 224.563],
                                  [121.625, 332.563],
                                  [343.187, 249.937],
                                  [290.25, 112.937],
                                  [130.313, -93.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-0.125, -248.813],
                                  [-193.125, -276.312],
                                  [-251.063, -84.125],
                                  [-239.063, 167.437],
                                  [-210.437, 224.375],
                                  [122.125, 333.188],
                                  [342.437, 249.687],
                                  [290.5, 113.5],
                                  [129.813, -94.25],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-0.375, -248.438],
                                  [-192.875, -276.437],
                                  [-250.813, -83.125],
                                  [-239.625, 167.062],
                                  [-210.312, 224.188],
                                  [122.625, 333.813],
                                  [341.625, 249.437],
                                  [290.75, 114.062],
                                  [129.188, -95.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-0.563, -248.125],
                                  [-192.625, -276.562],
                                  [-250.5, -82.062],
                                  [-240.188, 166.687],
                                  [-210.25, 224.063],
                                  [123.125, 334.375],
                                  [340.812, 249.187],
                                  [291, 114.625],
                                  [128.625, -96.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-0.75, -247.875],
                                  [-192.312, -276.625],
                                  [-250.25, -80.937],
                                  [-240.75, 166.25],
                                  [-210.125, 223.938],
                                  [123.625, 334.938],
                                  [340, 249],
                                  [291.25, 115.125],
                                  [128, -97.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-0.875, -247.625],
                                  [-192.062, -276.687],
                                  [-250, -79.875],
                                  [-241.25, 165.812],
                                  [-210, 223.875],
                                  [124.062, 335.438],
                                  [339.187, 248.75],
                                  [291.5, 115.625],
                                  [127.375, -98.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-1, -247.375],
                                  [-191.812, -276.687],
                                  [-249.75, -78.812],
                                  [-241.75, 165.312],
                                  [-209.875, 223.813],
                                  [124.437, 335.938],
                                  [338.375, 248.562],
                                  [291.75, 116.125],
                                  [126.688, -99.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-1.063, -247.25],
                                  [-191.562, -276.687],
                                  [-249.438, -77.75],
                                  [-242.25, 164.812],
                                  [-209.75, 223.875],
                                  [124.875, 336.375],
                                  [337.562, 248.375],
                                  [291.937, 116.562],
                                  [126, -100.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-1.063, -247.063],
                                  [-191.25, -276.625],
                                  [-249.188, -76.687],
                                  [-242.75, 164.312],
                                  [-209.562, 223.938],
                                  [125.188, 336.813],
                                  [336.75, 248.188],
                                  [292.187, 117],
                                  [125.313, -101.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-1.063, -246.938],
                                  [-191, -276.562],
                                  [-249, -75.562],
                                  [-243.25, 163.812],
                                  [-209.375, 224.063],
                                  [125.562, 337.25],
                                  [335.937, 248],
                                  [292.437, 117.375],
                                  [124.625, -102.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-1, -246.875],
                                  [-190.75, -276.437],
                                  [-248.75, -74.5],
                                  [-243.75, 163.25],
                                  [-209.187, 224.188],
                                  [125.812, 337.625],
                                  [335.187, 247.875],
                                  [292.687, 117.812],
                                  [123.875, -103.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-0.875, -246.875],
                                  [-190.437, -276.25],
                                  [-248.5, -73.437],
                                  [-244.188, 162.687],
                                  [-209, 224.375],
                                  [126.125, 337.938],
                                  [334.375, 247.75],
                                  [292.875, 118.125],
                                  [123.125, -104],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-0.75, -246.875],
                                  [-190.125, -276.062],
                                  [-248.25, -72.437],
                                  [-244.625, 162.125],
                                  [-208.812, 224.625],
                                  [126.313, 338.25],
                                  [333.562, 247.688],
                                  [293.125, 118.437],
                                  [122.313, -105],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-0.563, -246.938],
                                  [-189.875, -275.812],
                                  [-248, -71.375],
                                  [-245.062, 161.5],
                                  [-208.625, 224.938],
                                  [126.562, 338.563],
                                  [332.812, 247.625],
                                  [293.312, 118.75],
                                  [121.5, -106],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-0.313, -247],
                                  [-189.562, -275.562],
                                  [-247.813, -70.375],
                                  [-245.5, 160.937],
                                  [-208.375, 225.313],
                                  [126.688, 338.813],
                                  [332.062, 247.562],
                                  [293.5, 119],
                                  [120.688, -107],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [-0.063, -247.188],
                                  [-189.25, -275.25],
                                  [-247.563, -69.375],
                                  [-245.875, 160.312],
                                  [-208.187, 225.688],
                                  [126.813, 339],
                                  [331.312, 247.562],
                                  [293.687, 119.25],
                                  [119.875, -108],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [0.25, -247.375],
                                  [-188.937, -274.875],
                                  [-247.313, -68.437],
                                  [-246.312, 159.688],
                                  [-207.937, 226.125],
                                  [126.938, 339.188],
                                  [330.625, 247.625],
                                  [293.937, 119.5],
                                  [119, -108.938],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [0.625, -247.563],
                                  [-188.625, -274.5],
                                  [-247.063, -67.437],
                                  [-246.625, 159],
                                  [-207.75, 226.625],
                                  [127, 339.313],
                                  [329.875, 247.688],
                                  [294.062, 119.625],
                                  [118.125, -109.938],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [1, -247.875],
                                  [-188.312, -274.125],
                                  [-246.813, -66.562],
                                  [-247, 158.375],
                                  [-207.562, 227.188],
                                  [127, 339.438],
                                  [329.187, 247.75],
                                  [294.25, 119.812],
                                  [117.25, -110.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [1.437, -248.25],
                                  [-187.937, -273.687],
                                  [-246.625, -65.687],
                                  [-247.312, 157.688],
                                  [-207.312, 227.813],
                                  [127, 339.563],
                                  [328.562, 247.938],
                                  [294.437, 119.937],
                                  [116.313, -111.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [1.937, -248.625],
                                  [-187.625, -273.187],
                                  [-246.375, -64.812],
                                  [-247.625, 157.062],
                                  [-207.125, 228.5],
                                  [126.938, 339.563],
                                  [327.937, 248.125],
                                  [294.562, 120],
                                  [115.375, -112.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [2.437, -249.063],
                                  [-187.25, -272.687],
                                  [-246.125, -64],
                                  [-247.937, 156.375],
                                  [-206.937, 229.188],
                                  [126.813, 339.625],
                                  [327.312, 248.312],
                                  [294.75, 120.062],
                                  [114.438, -113.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [3, -249.563],
                                  [-186.875, -272.125],
                                  [-245.875, -63.25],
                                  [-248.187, 155.687],
                                  [-206.75, 230],
                                  [126.688, 339.563],
                                  [326.812, 248.625],
                                  [294.875, 120.062],
                                  [113.5, -114.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [3.625, -250.125],
                                  [-186.5, -271.5],
                                  [-245.563, -62.5],
                                  [-248.437, 155],
                                  [-206.562, 230.813],
                                  [126.5, 339.563],
                                  [326.25, 248.938],
                                  [295, 120.062],
                                  [112.625, -115.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [4.25, -250.75],
                                  [-186.125, -270.875],
                                  [-245.313, -61.875],
                                  [-248.687, 154.25],
                                  [-206.375, 231.688],
                                  [126.313, 339.438],
                                  [325.75, 249.375],
                                  [295.125, 120],
                                  [111.75, -116.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [4.937, -251.438],
                                  [-185.75, -270.25],
                                  [-245.063, -61.25],
                                  [-248.937, 153.562],
                                  [-206.25, 232.625],
                                  [126.063, 339.313],
                                  [325.312, 249.812],
                                  [295.25, 119.875],
                                  [110.875, -116.938],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [140, 28],
                                  [60.5, -26.75],
                                  [-8, -103],
                                  [-16.75, -46.75],
                                  [-13, -16],
                                  [-204, 44],
                                  [-19.75, 43.75],
                                  [19, 17],
                                  [-26, 92],
                                ],
                                o: [
                                  [-70, -14],
                                  [-60.5, 26.75],
                                  [8, 103],
                                  [16.75, 46.75],
                                  [26, 32],
                                  [102, -22],
                                  [19.75, -43.75],
                                  [-38, -34],
                                  [26, -92],
                                ],
                                v: [
                                  [5.687, -252.188],
                                  [-185.375, -269.562],
                                  [-244.75, -60.687],
                                  [-249.125, 152.875],
                                  [-206.125, 233.688],
                                  [125.75, 339.188],
                                  [324.875, 250.25],
                                  [295.312, 119.75],
                                  [110.063, -117.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [37.625, 35.062],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.312, 35.062],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, 35.031],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.844, 35],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.625, 35],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.312, 34.938],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.188, 34.906],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.094, 34.875],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.094, 34.875],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.031, 34.188],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.031, 33.375],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.062, 32.656],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.125, 31.938],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.219, 31.219],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.312, 30.562],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.438, 29.875],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 29.281],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 28.688],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.938, 28.156],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.125, 27.594],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.375, 27.094],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.625, 26.625],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.125, 25.75],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.438, 25.406],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.719, 25],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.031, 24.719],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.375, 24.406],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.688, 24.156],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 23.938],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.406, 23.75],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.781, 23.594],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.125, 23.438],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.531, 23.375],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.906, 23.281],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.312, 23.25],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.688, 23.25],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.062, 23.312],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.469, 23.406],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.812, 23.531],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.188, 23.656],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.594, 23.844],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.938, 24.094],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.25, 24.344],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.594, 24.656],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.938, 25],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.219, 25.406],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.5, 25.844],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.062, 26.781],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.312, 27.281],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.594, 27.844],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.906, 28.406],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.188, 28.969],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.531, 29.594],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.906, 30.219],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.25, 30.875],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.625, 31.5],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.969, 32.156],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.375, 32.844],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.75, 33.5],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.094, 34.188],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.062, 34.906],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51, 35.562],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.938, 36.281],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.844, 37],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.75, 37.688],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.625, 38.375],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.5, 39.031],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.938, 41.094],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.719, 41.719],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.469, 42.375],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.188, 43],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.938, 43.594],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.625, 44.188],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.312, 44.75],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.625, 45.812],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.25, 46.312],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.875, 46.781],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.469, 47.219],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.062, 47.625],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.625, 48],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.188, 48.344],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.719, 48.656],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.25, 48.938],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.75, 49.094],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.75, 49.094],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.219, 49.031],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.688, 48.906],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.125, 48.844],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40, 48.594],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.406, 48.438],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.875, 48.281],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.312, 48.125],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 47.906],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.25, 47.75],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 47.5],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.25, 47.312],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.781, 47.062],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.344, 46.844],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.938, 46.5],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.5, 46],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.125, 45.5],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.75, 44.938],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.438, 44.406],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.812, 43.281],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.531, 42.656],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.281, 42.062],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.031, 41.438],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, 40.812],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.625, 40.156],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.438, 39.5],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.281, 38.812],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, 36.75],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.875, 36.031],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 35.312],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 34.594],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 33.156],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 32.406],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, 30.906],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.031, 30.156],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.125, 29.406],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.438, 27.906],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.625, 27.188],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, 26.438],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.062, 25.719],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.312, 25],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.594, 24.25],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.844, 23.531],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.094, 22.844],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.344, 22.125],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.625, 21.438],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.938, 20.75],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.25, 20.094],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.562, 19.5],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.875, 18.938],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.219, 18.406],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.594, 17.906],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.969, 17.438],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.312, 17],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.688, 16.562],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.094, 16.156],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.438, 15.812],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, 15.469],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.25, 15.188],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39, 14.688],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.406, 14.469],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 14.312],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.188, 14.188],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.938, 14],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.344, 14],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.719, 14],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.062, 14.031],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.438, 14.094],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.812, 14.219],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.156, 14.344],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.5, 14.531],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.844, 14.75],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.188, 15.031],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.469, 15.312],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.781, 15.656],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.344, 16.406],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.656, 16.844],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.156, 17.844],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.406, 18.375],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.625, 18.938],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.812, 19.531],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47, 20.188],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.219, 20.875],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.344, 21.594],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.531, 22.281],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.625, 23.094],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.75, 23.875],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.875, 24.375],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.938, 24.5],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.031, 24.594],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.094, 24.719],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.125, 24.812],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.156, 24.938],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.156, 25.031],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.156, 25.188],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.156, 25.281],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.125, 25.438],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.062, 25.594],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.031, 25.719],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.938, 25.906],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.844, 26.031],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.75, 26.219],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.656, 26.375],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.344, 26.75],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.188, 26.969],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47, 27.156],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.812, 27.375],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.375, 27.75],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.156, 27.969],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.906, 28.219],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.688, 28.438],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.406, 28.688],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.156, 28.906],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.875, 29.156],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.594, 29.375],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.312, 29.625],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.062, 29.844],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.781, 30.094],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.469, 30.344],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.219, 30.594],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.656, 31.094],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.406, 31.375],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.125, 31.625],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.875, 31.875],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.656, 32.156],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.406, 32.406],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.094, 32.656],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.625, 32.938],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.156, 33.188],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.688, 33.469],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.312, 33.719],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.906, 34.031],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.531, 34.281],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.188, 34.531],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.875, 34.812],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [37.625, 35.062],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.312, 35.062],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, 35.031],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.844, 35],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.625, 35],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.312, 34.938],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.188, 34.906],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.094, 34.875],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.094, 34.875],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.031, 34.188],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.031, 33.375],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.062, 32.656],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.125, 31.938],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.219, 31.219],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.312, 30.562],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.438, 29.875],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 29.281],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 28.688],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.938, 28.156],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.125, 27.594],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.375, 27.094],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.625, 26.625],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.125, 25.75],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.438, 25.406],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.719, 25],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.031, 24.719],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.375, 24.406],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.688, 24.156],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 23.938],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.406, 23.75],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.781, 23.594],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.125, 23.438],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.531, 23.375],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.906, 23.281],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.312, 23.25],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.688, 23.25],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.062, 23.312],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.469, 23.406],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.812, 23.531],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.188, 23.656],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.594, 23.844],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.938, 24.094],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.25, 24.344],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.594, 24.656],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.938, 25],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.219, 25.406],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.5, 25.844],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.062, 26.781],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.312, 27.281],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.594, 27.844],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.906, 28.406],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.188, 28.969],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.531, 29.594],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.906, 30.219],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.25, 30.875],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.625, 31.5],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.969, 32.156],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.375, 32.844],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.75, 33.5],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.094, 34.188],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51.062, 34.906],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [51, 35.562],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.938, 36.281],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.844, 37],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.75, 37.688],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.625, 38.375],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [50.5, 39.031],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.938, 41.094],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.719, 41.719],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.469, 42.375],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [49.188, 43],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.938, 43.594],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.625, 44.188],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.312, 44.75],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.625, 45.812],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.25, 46.312],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.875, 46.781],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.469, 47.219],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.062, 47.625],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.625, 48],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.188, 48.344],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.719, 48.656],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.25, 48.938],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.75, 49.094],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.75, 49.094],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.219, 49.031],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.688, 48.906],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.125, 48.844],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40, 48.594],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.406, 48.438],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.875, 48.281],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.312, 48.125],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 47.906],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.25, 47.75],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 47.5],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.25, 47.312],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.781, 47.062],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.344, 46.844],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.938, 46.5],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.5, 46],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.125, 45.5],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.75, 44.938],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.438, 44.406],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.812, 43.281],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.531, 42.656],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.281, 42.062],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.031, 41.438],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, 40.812],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.625, 40.156],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.438, 39.5],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.281, 38.812],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, 36.75],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.875, 36.031],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 35.312],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 34.594],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 33.156],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 32.406],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, 30.906],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.031, 30.156],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.125, 29.406],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.438, 27.906],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.625, 27.188],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, 26.438],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.062, 25.719],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.312, 25],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.594, 24.25],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.844, 23.531],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.094, 22.844],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.344, 22.125],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.625, 21.438],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.938, 20.75],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.25, 20.094],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.562, 19.5],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.875, 18.938],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.219, 18.406],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.594, 17.906],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.969, 17.438],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.312, 17],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.688, 16.562],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.094, 16.156],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.438, 15.812],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, 15.469],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.25, 15.188],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39, 14.688],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.406, 14.469],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 14.312],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.188, 14.188],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.938, 14],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.344, 14],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.719, 14],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.062, 14.031],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.438, 14.094],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.812, 14.219],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.156, 14.344],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.5, 14.531],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.844, 14.75],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.188, 15.031],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.469, 15.312],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.781, 15.656],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.344, 16.406],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.656, 16.844],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.156, 17.844],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.406, 18.375],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.625, 18.938],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.812, 19.531],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47, 20.188],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.219, 20.875],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.344, 21.594],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.531, 22.281],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.625, 23.094],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.75, 23.875],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.875, 24.375],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.938, 24.5],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.031, 24.594],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.094, 24.719],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.125, 24.812],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.156, 24.938],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.156, 25.031],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.156, 25.188],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.156, 25.281],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.125, 25.438],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.062, 25.594],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [48.031, 25.719],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.938, 25.906],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.844, 26.031],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.75, 26.219],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.656, 26.375],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.344, 26.75],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47.188, 26.969],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [47, 27.156],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.812, 27.375],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.375, 27.75],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.156, 27.969],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.906, 28.219],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.688, 28.438],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.406, 28.688],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.156, 28.906],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.875, 29.156],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.594, 29.375],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.312, 29.625],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.062, 29.844],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.781, 30.094],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.469, 30.344],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.219, 30.594],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.656, 31.094],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.406, 31.375],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.125, 31.625],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.875, 31.875],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.656, 32.156],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.406, 32.406],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.094, 32.656],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.625, 32.938],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.156, 33.188],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.688, 33.469],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.312, 33.719],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.906, 34.031],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.531, 34.281],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.188, 34.531],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.875, 34.812],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 1',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [137.144, 22.287],
                                  [53.609, -34.551],
                                  [-3.144, -98.216],
                                  [-25.408, -45.84],
                                  [-13.357, -19.999],
                                  [-197.43, 41.001],
                                  [-8.057, 46.624],
                                  [14.073, 26.926],
                                  [-24.715, 92],
                                ],
                                o: [
                                  [-72.142, -9.287],
                                  [-53.609, 34.551],
                                  [3.144, 98.216],
                                  [25.408, 45.84],
                                  [25.429, 34.856],
                                  [102.714, -20.572],
                                  [8.057, -46.624],
                                  [-31.716, -42.712],
                                  [24.715, -92],
                                ],
                                v: [
                                  [-3.917, -264.742],
                                  [-183.674, -261.489],
                                  [-246.798, -54.957],
                                  [-248.21, 151.884],
                                  [-190.299, 240.931],
                                  [127.178, 343.891],
                                  [320.015, 248.617],
                                  [273.064, 98.211],
                                  [122.028, -126.092],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [137.066, 22.133],
                                  [53.422, -34.763],
                                  [-3.013, -98.086],
                                  [-25.643, -45.815],
                                  [-13.367, -20.107],
                                  [-197.252, 40.92],
                                  [-7.74, 46.702],
                                  [13.939, 27.195],
                                  [-24.68, 92],
                                ],
                                o: [
                                  [-72.2, -9.159],
                                  [-53.422, 34.763],
                                  [3.013, 98.086],
                                  [25.643, 45.815],
                                  [25.413, 34.934],
                                  [102.733, -20.533],
                                  [7.74, -46.702],
                                  [-31.546, -42.948],
                                  [24.68, -92],
                                ],
                                v: [
                                  [-3.466, -266.017],
                                  [-183.433, -260.772],
                                  [-246.912, -54.579],
                                  [-248.641, 151.384],
                                  [-189.795, 241.96],
                                  [126.836, 343.443],
                                  [319.686, 248.883],
                                  [272.803, 97.545],
                                  [121.966, -126.691],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.989, 21.978],
                                  [53.236, -34.974],
                                  [-2.881, -97.956],
                                  [-25.877, -45.79],
                                  [-13.376, -20.216],
                                  [-197.074, 40.838],
                                  [-7.423, 46.78],
                                  [13.806, 27.464],
                                  [-24.645, 92],
                                ],
                                o: [
                                  [-72.258, -9.032],
                                  [-53.236, 34.974],
                                  [2.881, 97.956],
                                  [25.877, 45.79],
                                  [25.398, 35.011],
                                  [102.753, -20.494],
                                  [7.423, -46.78],
                                  [-31.376, -43.184],
                                  [24.645, -92],
                                ],
                                v: [
                                  [-2.958, -267.341],
                                  [-183.135, -260.12],
                                  [-247.076, -54.202],
                                  [-249.011, 150.891],
                                  [-189.227, 242.927],
                                  [126.485, 342.994],
                                  [319.41, 249.205],
                                  [272.538, 96.883],
                                  [121.908, -127.289],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.912, 21.823],
                                  [53.049, -35.185],
                                  [-2.75, -97.827],
                                  [-26.112, -45.766],
                                  [-13.386, -20.324],
                                  [-196.896, 40.757],
                                  [-7.107, 46.858],
                                  [13.672, 27.732],
                                  [-24.61, 92],
                                ],
                                o: [
                                  [-72.316, -8.904],
                                  [-53.049, 35.185],
                                  [2.75, 97.827],
                                  [26.112, 45.766],
                                  [25.382, 35.089],
                                  [102.772, -20.456],
                                  [7.107, -46.858],
                                  [-31.205, -43.42],
                                  [24.61, -92],
                                ],
                                v: [
                                  [-2.456, -268.714],
                                  [-182.847, -259.413],
                                  [-247.181, -53.873],
                                  [-249.385, 150.404],
                                  [-188.542, 243.885],
                                  [126.079, 342.486],
                                  [319.192, 249.524],
                                  [272.222, 96.169],
                                  [121.856, -127.818],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.834, 21.668],
                                  [52.862, -35.397],
                                  [-2.618, -97.697],
                                  [-26.347, -45.741],
                                  [-13.396, -20.432],
                                  [-196.719, 40.676],
                                  [-6.79, 46.936],
                                  [13.539, 28.001],
                                  [-24.575, 92],
                                ],
                                o: [
                                  [-72.374, -8.776],
                                  [-52.862, 35.397],
                                  [2.618, 97.697],
                                  [26.347, 45.741],
                                  [25.367, 35.166],
                                  [102.791, -20.417],
                                  [6.79, -46.936],
                                  [-31.035, -43.656],
                                  [24.575, -92],
                                ],
                                v: [
                                  [-1.955, -270.082],
                                  [-182.565, -258.651],
                                  [-247.331, -53.602],
                                  [-249.757, 149.978],
                                  [-187.858, 244.839],
                                  [125.663, 341.977],
                                  [318.975, 249.903],
                                  [271.966, 95.408],
                                  [121.861, -128.285],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.757, 21.514],
                                  [52.676, -35.608],
                                  [-2.486, -97.568],
                                  [-26.581, -45.716],
                                  [-13.405, -20.541],
                                  [-196.541, 40.595],
                                  [-6.473, 47.013],
                                  [13.405, 28.27],
                                  [-24.541, 92],
                                ],
                                o: [
                                  [-72.432, -8.649],
                                  [-52.676, 35.608],
                                  [2.486, 97.568],
                                  [26.581, 45.716],
                                  [25.351, 35.243],
                                  [102.811, -20.378],
                                  [6.473, -47.014],
                                  [-30.865, -43.892],
                                  [24.541, -92],
                                ],
                                v: [
                                  [-1.508, -271.436],
                                  [-182.284, -257.954],
                                  [-247.419, -53.326],
                                  [-250.074, 149.557],
                                  [-187.115, 245.784],
                                  [125.24, 341.468],
                                  [318.811, 250.284],
                                  [271.654, 94.649],
                                  [121.932, -128.694],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.679, 21.359],
                                  [52.489, -35.819],
                                  [-2.355, -97.438],
                                  [-26.816, -45.692],
                                  [-13.415, -20.649],
                                  [-196.363, 40.513],
                                  [-6.156, 47.091],
                                  [13.272, 28.539],
                                  [-24.506, 92],
                                ],
                                o: [
                                  [-72.49, -8.521],
                                  [-52.489, 35.819],
                                  [2.355, 97.438],
                                  [26.816, 45.692],
                                  [25.336, 35.321],
                                  [102.83, -20.34],
                                  [6.156, -47.091],
                                  [-30.695, -44.128],
                                  [24.506, -92],
                                ],
                                v: [
                                  [-1.073, -272.666],
                                  [-182.013, -257.203],
                                  [-247.502, -53.049],
                                  [-250.391, 149.139],
                                  [-186.309, 246.672],
                                  [124.753, 340.895],
                                  [318.642, 250.667],
                                  [271.343, 93.842],
                                  [121.951, -129.093],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.602, 21.204],
                                  [52.302, -36.031],
                                  [-2.223, -97.308],
                                  [-27.05, -45.667],
                                  [-13.425, -20.757],
                                  [-196.185, 40.432],
                                  [-5.839, 47.169],
                                  [13.138, 28.808],
                                  [-24.471, 92],
                                ],
                                o: [
                                  [-72.549, -8.393],
                                  [-52.302, 36.031],
                                  [2.223, 97.308],
                                  [27.05, 45.667],
                                  [25.32, 35.398],
                                  [102.85, -20.301],
                                  [5.839, -47.169],
                                  [-30.524, -44.364],
                                  [24.471, -92],
                                ],
                                v: [
                                  [-0.691, -273.886],
                                  [-181.743, -256.456],
                                  [-247.576, -52.881],
                                  [-250.711, 148.721],
                                  [-185.452, 247.551],
                                  [124.265, 340.327],
                                  [318.536, 251.105],
                                  [270.978, 92.98],
                                  [122.089, -129.378],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.525, 21.049],
                                  [52.116, -36.242],
                                  [-2.092, -97.179],
                                  [-27.285, -45.642],
                                  [-13.434, -20.866],
                                  [-196.007, 40.351],
                                  [-5.523, 47.247],
                                  [13.005, 29.077],
                                  [-24.436, 92],
                                ],
                                o: [
                                  [-72.607, -8.266],
                                  [-52.116, 36.242],
                                  [2.092, 97.179],
                                  [27.285, 45.642],
                                  [25.305, 35.475],
                                  [102.869, -20.262],
                                  [5.523, -47.247],
                                  [-30.354, -44.6],
                                  [24.436, -92],
                                ],
                                v: [
                                  [-0.373, -274.984],
                                  [-181.422, -255.716],
                                  [-247.645, -52.649],
                                  [-250.974, 148.309],
                                  [-184.538, 248.369],
                                  [123.713, 339.753],
                                  [318.421, 251.541],
                                  [270.677, 92.129],
                                  [122.169, -129.653],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.447, 20.894],
                                  [51.929, -36.454],
                                  [-1.96, -97.049],
                                  [-27.519, -45.618],
                                  [-13.444, -20.974],
                                  [-195.829, 40.27],
                                  [-5.206, 47.325],
                                  [12.871, 29.346],
                                  [-24.401, 92],
                                ],
                                o: [
                                  [-72.665, -8.138],
                                  [-51.929, 36.454],
                                  [1.96, 97.049],
                                  [27.519, 45.618],
                                  [25.289, 35.553],
                                  [102.888, -20.224],
                                  [5.206, -47.325],
                                  [-30.184, -44.836],
                                  [24.401, -92],
                                ],
                                v: [
                                  [-0.112, -276.079],
                                  [-181.163, -254.918],
                                  [-247.71, -52.468],
                                  [-251.185, 147.96],
                                  [-183.624, 249.183],
                                  [123.154, 339.184],
                                  [318.426, 251.979],
                                  [270.316, 91.28],
                                  [122.315, -129.929],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.37, 20.74],
                                  [51.742, -36.665],
                                  [-1.829, -96.919],
                                  [-27.754, -45.593],
                                  [-13.454, -21.082],
                                  [-195.651, 40.188],
                                  [-4.889, 47.403],
                                  [12.738, 29.615],
                                  [-24.366, 92],
                                ],
                                o: [
                                  [-72.723, -8.01],
                                  [-51.742, 36.665],
                                  [1.829, 96.92],
                                  [27.754, 45.593],
                                  [25.274, 35.63],
                                  [102.908, -20.185],
                                  [4.889, -47.403],
                                  [-30.014, -45.072],
                                  [24.366, -92],
                                ],
                                v: [
                                  [0.148, -277.046],
                                  [-180.853, -254.128],
                                  [-247.764, -52.335],
                                  [-251.452, 147.606],
                                  [-182.71, 249.931],
                                  [122.593, 338.552],
                                  [318.364, 252.42],
                                  [269.957, 90.385],
                                  [122.465, -130.087],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.292, 20.585],
                                  [51.556, -36.876],
                                  [-1.697, -96.79],
                                  [-27.988, -45.568],
                                  [-13.463, -21.191],
                                  [-195.473, 40.107],
                                  [-4.572, 47.481],
                                  [12.604, 29.884],
                                  [-24.332, 92],
                                ],
                                o: [
                                  [-72.781, -7.883],
                                  [-51.556, 36.876],
                                  [1.697, 96.79],
                                  [27.988, 45.568],
                                  [25.258, 35.708],
                                  [102.927, -20.146],
                                  [4.572, -47.481],
                                  [-29.843, -45.308],
                                  [24.332, -92],
                                ],
                                v: [
                                  [0.35, -278.011],
                                  [-180.599, -253.343],
                                  [-247.762, -52.201],
                                  [-251.661, 147.32],
                                  [-181.684, 250.738],
                                  [122.025, 337.92],
                                  [318.365, 252.917],
                                  [269.549, 89.441],
                                  [122.67, -130.236],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.215, 20.43],
                                  [51.369, -37.088],
                                  [-1.566, -96.66],
                                  [-28.223, -45.544],
                                  [-13.473, -21.299],
                                  [-195.295, 40.026],
                                  [-4.255, 47.559],
                                  [12.471, 30.153],
                                  [-24.297, 92],
                                ],
                                o: [
                                  [-72.839, -7.755],
                                  [-51.369, 37.088],
                                  [1.566, 96.66],
                                  [28.223, 45.544],
                                  [25.243, 35.785],
                                  [102.946, -20.108],
                                  [4.255, -47.559],
                                  [-29.673, -45.544],
                                  [24.297, -92],
                                ],
                                v: [
                                  [0.546, -278.849],
                                  [-180.295, -252.561],
                                  [-247.807, -52.059],
                                  [-251.877, 147.027],
                                  [-180.709, 251.421],
                                  [121.392, 337.349],
                                  [318.419, 253.36],
                                  [269.195, 88.503],
                                  [122.885, -130.329],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.138, 20.275],
                                  [51.182, -37.299],
                                  [-1.434, -96.531],
                                  [-28.458, -45.519],
                                  [-13.483, -21.407],
                                  [-195.117, 39.945],
                                  [-3.939, 47.636],
                                  [12.338, 30.421],
                                  [-24.262, 92],
                                ],
                                o: [
                                  [-72.897, -7.627],
                                  [-51.182, 37.299],
                                  [1.434, 96.531],
                                  [28.458, 45.519],
                                  [25.228, 35.862],
                                  [102.966, -20.069],
                                  [3.939, -47.636],
                                  [-29.503, -45.78],
                                  [24.262, -92],
                                ],
                                v: [
                                  [0.685, -279.63],
                                  [-179.99, -251.726],
                                  [-247.841, -51.97],
                                  [-252.036, 146.738],
                                  [-179.628, 252.107],
                                  [120.758, 336.715],
                                  [318.475, 253.863],
                                  [268.736, 87.51],
                                  [123.154, -130.363],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.06, 20.121],
                                  [50.996, -37.51],
                                  [-1.303, -96.401],
                                  [-28.692, -45.494],
                                  [-13.492, -21.516],
                                  [-194.939, 39.863],
                                  [-3.622, 47.714],
                                  [12.204, 30.69],
                                  [-24.227, 92],
                                ],
                                o: [
                                  [-72.955, -7.5],
                                  [-50.996, 37.51],
                                  [1.303, 96.401],
                                  [28.692, 45.494],
                                  [25.212, 35.94],
                                  [102.985, -20.03],
                                  [3.622, -47.714],
                                  [-29.333, -46.016],
                                  [24.227, -92],
                                ],
                                v: [
                                  [0.767, -280.409],
                                  [-179.696, -250.951],
                                  [-247.82, -51.873],
                                  [-252.195, 146.455],
                                  [-178.605, 252.783],
                                  [120.115, 336.088],
                                  [318.588, 254.365],
                                  [268.33, 86.53],
                                  [123.37, -130.343],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.983, 19.966],
                                  [50.809, -37.722],
                                  [-1.171, -96.271],
                                  [-28.927, -45.47],
                                  [-13.502, -21.624],
                                  [-194.761, 39.782],
                                  [-3.305, 47.792],
                                  [12.071, 30.959],
                                  [-24.192, 92],
                                ],
                                o: [
                                  [-73.013, -7.372],
                                  [-50.809, 37.722],
                                  [1.171, 96.271],
                                  [28.927, 45.47],
                                  [25.197, 36.017],
                                  [103.004, -19.991],
                                  [3.305, -47.792],
                                  [-29.162, -46.252],
                                  [24.192, -92],
                                ],
                                v: [
                                  [0.843, -281.063],
                                  [-179.395, -250.124],
                                  [-247.85, -51.823],
                                  [-252.361, 146.227],
                                  [-177.526, 253.456],
                                  [119.47, 335.453],
                                  [318.697, 254.808],
                                  [267.87, 85.551],
                                  [123.646, -130.314],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.906, 19.811],
                                  [50.622, -37.933],
                                  [-1.039, -96.142],
                                  [-29.161, -45.445],
                                  [-13.512, -21.732],
                                  [-194.583, 39.701],
                                  [-2.988, 47.87],
                                  [11.937, 31.228],
                                  [-24.158, 92],
                                ],
                                o: [
                                  [-73.071, -7.244],
                                  [-50.622, 37.933],
                                  [1.039, 96.142],
                                  [29.161, 45.445],
                                  [25.181, 36.094],
                                  [103.024, -19.953],
                                  [2.988, -47.87],
                                  [-28.992, -46.488],
                                  [24.158, -92],
                                ],
                                v: [
                                  [0.862, -281.717],
                                  [-179.106, -249.3],
                                  [-247.813, -51.713],
                                  [-252.471, 145.996],
                                  [-176.442, 254.013],
                                  [118.761, 334.769],
                                  [318.808, 255.313],
                                  [267.413, 84.53],
                                  [123.981, -130.225],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.828, 19.656],
                                  [50.435, -38.144],
                                  [-0.908, -96.012],
                                  [-29.396, -45.42],
                                  [-13.521, -21.841],
                                  [-194.405, 39.62],
                                  [-2.672, 47.948],
                                  [11.804, 31.497],
                                  [-24.123, 92],
                                ],
                                o: [
                                  [-73.129, -7.116],
                                  [-50.435, 38.144],
                                  [0.908, 96.012],
                                  [29.396, 45.42],
                                  [25.166, 36.172],
                                  [103.043, -19.914],
                                  [2.672, -47.948],
                                  [-28.822, -46.724],
                                  [24.123, -92],
                                ],
                                v: [
                                  [0.881, -282.252],
                                  [-178.753, -248.481],
                                  [-247.778, -51.706],
                                  [-252.638, 145.763],
                                  [-175.309, 254.622],
                                  [118.105, 334.14],
                                  [318.978, 255.815],
                                  [266.965, 83.509],
                                  [124.264, -130.084],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.751, 19.502],
                                  [50.249, -38.356],
                                  [-0.776, -95.883],
                                  [-29.63, -45.396],
                                  [-13.531, -21.949],
                                  [-194.227, 39.538],
                                  [-2.355, 48.026],
                                  [11.67, 31.766],
                                  [-24.088, 92],
                                ],
                                o: [
                                  [-73.187, -6.989],
                                  [-50.249, 38.356],
                                  [0.776, 95.883],
                                  [29.63, 45.396],
                                  [25.15, 36.249],
                                  [103.062, -19.875],
                                  [2.355, -48.026],
                                  [-28.652, -46.96],
                                  [24.088, -92],
                                ],
                                v: [
                                  [0.892, -282.789],
                                  [-178.468, -247.665],
                                  [-247.787, -51.632],
                                  [-252.75, 145.59],
                                  [-174.234, 255.179],
                                  [117.384, 333.518],
                                  [319.206, 256.267],
                                  [266.457, 82.447],
                                  [124.605, -129.941],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.673, 19.347],
                                  [50.062, -38.567],
                                  [-0.645, -95.753],
                                  [-29.865, -45.371],
                                  [-13.541, -22.057],
                                  [-194.049, 39.457],
                                  [-2.038, 48.104],
                                  [11.537, 32.035],
                                  [-24.053, 92],
                                ],
                                o: [
                                  [-73.245, -6.861],
                                  [-50.062, 38.567],
                                  [0.645, 95.753],
                                  [29.865, 45.371],
                                  [25.135, 36.327],
                                  [103.082, -19.837],
                                  [2.038, -48.104],
                                  [-28.482, -47.196],
                                  [24.053, -92],
                                ],
                                v: [
                                  [0.848, -283.264],
                                  [-178.119, -246.853],
                                  [-247.742, -51.61],
                                  [-252.813, 145.42],
                                  [-173.104, 255.671],
                                  [116.661, 332.945],
                                  [319.374, 256.724],
                                  [265.953, 81.385],
                                  [124.944, -129.74],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.596, 19.192],
                                  [49.875, -38.778],
                                  [-0.513, -95.623],
                                  [-30.1, -45.346],
                                  [-13.55, -22.166],
                                  [-193.871, 39.376],
                                  [-1.721, 48.181],
                                  [11.403, 32.304],
                                  [-24.018, 92],
                                ],
                                o: [
                                  [-73.303, -6.733],
                                  [-49.875, 38.778],
                                  [0.513, 95.623],
                                  [30.1, 45.346],
                                  [25.119, 36.404],
                                  [103.101, -19.798],
                                  [1.721, -48.182],
                                  [-28.311, -47.432],
                                  [24.018, -92],
                                ],
                                v: [
                                  [0.797, -283.734],
                                  [-177.781, -246.045],
                                  [-247.693, -51.577],
                                  [-252.933, 145.24],
                                  [-171.969, 256.16],
                                  [115.936, 332.322],
                                  [319.6, 257.236],
                                  [265.395, 80.331],
                                  [125.347, -129.536],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.519, 19.037],
                                  [49.689, -38.99],
                                  [-0.382, -95.494],
                                  [-30.334, -45.322],
                                  [-13.56, -22.274],
                                  [-193.693, 39.295],
                                  [-1.404, 48.259],
                                  [11.27, 32.573],
                                  [-23.983, 92],
                                ],
                                o: [
                                  [-73.361, -6.606],
                                  [-49.689, 38.99],
                                  [0.382, 95.494],
                                  [30.334, 45.322],
                                  [25.104, 36.481],
                                  [103.12, -19.759],
                                  [1.404, -48.259],
                                  [-28.141, -47.668],
                                  [23.983, -92],
                                ],
                                v: [
                                  [0.745, -284.094],
                                  [-177.434, -245.234],
                                  [-247.639, -51.54],
                                  [-252.992, 145.126],
                                  [-170.842, 256.59],
                                  [115.201, 331.693],
                                  [319.886, 257.691],
                                  [264.841, 79.285],
                                  [125.747, -129.219],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.441, 18.883],
                                  [49.502, -39.201],
                                  [-0.25, -95.364],
                                  [-30.569, -45.297],
                                  [-13.57, -22.382],
                                  [-193.515, 39.213],
                                  [-1.088, 48.337],
                                  [11.136, 32.842],
                                  [-23.949, 92],
                                ],
                                o: [
                                  [-73.419, -6.478],
                                  [-49.502, 39.201],
                                  [0.25, 95.364],
                                  [30.569, 45.297],
                                  [25.088, 36.559],
                                  [103.14, -19.721],
                                  [1.088, -48.337],
                                  [-27.971, -47.904],
                                  [23.949, -92],
                                ],
                                v: [
                                  [0.637, -284.457],
                                  [-177.037, -244.434],
                                  [-247.58, -51.547],
                                  [-253.059, 145.001],
                                  [-169.717, 257.018],
                                  [114.408, 331.133],
                                  [320.174, 258.152],
                                  [264.283, 78.19],
                                  [126.149, -128.963],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.364, 18.728],
                                  [49.315, -39.412],
                                  [-0.119, -95.235],
                                  [-30.803, -45.272],
                                  [-13.58, -22.491],
                                  [-193.337, 39.132],
                                  [-0.771, 48.415],
                                  [11.003, 33.11],
                                  [-23.914, 92],
                                ],
                                o: [
                                  [-73.477, -6.35],
                                  [-49.315, 39.412],
                                  [0.119, 95.235],
                                  [30.803, 45.272],
                                  [25.073, 36.636],
                                  [103.159, -19.682],
                                  [0.771, -48.415],
                                  [-27.801, -48.14],
                                  [23.914, -92],
                                ],
                                v: [
                                  [0.521, -284.753],
                                  [-176.7, -243.63],
                                  [-247.572, -51.549],
                                  [-253.127, 144.879],
                                  [-168.593, 257.395],
                                  [113.661, 330.565],
                                  [320.466, 258.558],
                                  [263.679, 77.104],
                                  [126.554, -128.595],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.286, 18.573],
                                  [49.129, -39.624],
                                  [0.013, -95.105],
                                  [-31.038, -45.248],
                                  [-13.589, -22.599],
                                  [-193.159, 39.051],
                                  [-0.454, 48.493],
                                  [10.869, 33.379],
                                  [-23.879, 92],
                                ],
                                o: [
                                  [-73.535, -6.223],
                                  [-49.129, 39.624],
                                  [-0.013, 95.105],
                                  [31.038, 45.248],
                                  [25.057, 36.714],
                                  [103.178, -19.643],
                                  [0.454, -48.493],
                                  [-27.63, -48.376],
                                  [23.879, -92],
                                ],
                                v: [
                                  [0.405, -285.06],
                                  [-176.297, -242.837],
                                  [-247.512, -51.538],
                                  [-253.203, 144.806],
                                  [-167.47, 257.763],
                                  [112.918, 329.998],
                                  [320.755, 259.018],
                                  [263.072, 76.017],
                                  [126.954, -128.226],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.209, 18.418],
                                  [48.942, -39.835],
                                  [0.144, -94.975],
                                  [-31.272, -45.223],
                                  [-13.599, -22.707],
                                  [-192.981, 38.97],
                                  [-0.137, 48.571],
                                  [10.736, 33.648],
                                  [-23.844, 92],
                                ],
                                o: [
                                  [-73.593, -6.095],
                                  [-48.942, 39.835],
                                  [-0.144, 94.975],
                                  [31.272, 45.223],
                                  [25.042, 36.791],
                                  [103.198, -19.605],
                                  [0.137, -48.571],
                                  [-27.46, -48.612],
                                  [23.844, -92],
                                ],
                                v: [
                                  [0.227, -285.252],
                                  [-175.954, -242.041],
                                  [-247.439, -51.523],
                                  [-253.218, 144.736],
                                  [-166.349, 258.08],
                                  [112.165, 329.492],
                                  [321.111, 259.43],
                                  [262.468, 74.891],
                                  [127.419, -127.855],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.132, 18.264],
                                  [48.755, -40.046],
                                  [0.276, -94.846],
                                  [-31.507, -45.198],
                                  [-13.609, -22.816],
                                  [-192.803, 38.888],
                                  [0.179, 48.649],
                                  [10.602, 33.917],
                                  [-23.809, 92],
                                ],
                                o: [
                                  [-73.651, -5.967],
                                  [-48.755, 40.046],
                                  [-0.276, 94.846],
                                  [31.507, 45.198],
                                  [25.026, 36.868],
                                  [103.217, -19.566],
                                  [-0.179, -48.649],
                                  [-27.29, -48.848],
                                  [23.809, -92],
                                ],
                                v: [
                                  [0.047, -285.497],
                                  [-175.56, -241.241],
                                  [-247.378, -51.502],
                                  [-253.29, 144.716],
                                  [-165.229, 258.396],
                                  [111.354, 328.932],
                                  [321.463, 259.841],
                                  [261.82, 73.82],
                                  [127.879, -127.436],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.054, 18.109],
                                  [48.569, -40.258],
                                  [0.408, -94.716],
                                  [-31.741, -45.174],
                                  [-13.618, -22.924],
                                  [-192.625, 38.807],
                                  [0.496, 48.727],
                                  [10.469, 34.186],
                                  [-23.774, 92],
                                ],
                                o: [
                                  [-73.709, -5.84],
                                  [-48.569, 40.258],
                                  [-0.408, 94.716],
                                  [31.741, 45.174],
                                  [25.011, 36.946],
                                  [103.236, -19.527],
                                  [-0.496, -48.727],
                                  [-27.12, -49.084],
                                  [23.774, -92],
                                ],
                                v: [
                                  [-0.085, -285.635],
                                  [-175.162, -240.451],
                                  [-247.304, -51.524],
                                  [-253.308, 144.689],
                                  [-164.166, 258.655],
                                  [110.588, 328.489],
                                  [321.827, 260.205],
                                  [261.167, 72.764],
                                  [128.341, -127.017],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.977, 17.954],
                                  [48.382, -40.469],
                                  [0.539, -94.586],
                                  [-31.976, -45.149],
                                  [-13.628, -23.032],
                                  [-192.447, 38.726],
                                  [0.813, 48.804],
                                  [10.335, 34.455],
                                  [-23.74, 92],
                                ],
                                o: [
                                  [-73.767, -5.712],
                                  [-48.382, 40.469],
                                  [-0.539, 94.586],
                                  [31.976, 45.149],
                                  [24.995, 37.023],
                                  [103.256, -19.488],
                                  [-0.813, -48.804],
                                  [-26.949, -49.32],
                                  [23.74, -92],
                                ],
                                v: [
                                  [-0.328, -285.779],
                                  [-174.707, -239.666],
                                  [-247.234, -51.54],
                                  [-253.328, 144.664],
                                  [-163.049, 258.857],
                                  [109.826, 327.991],
                                  [322.244, 260.624],
                                  [260.456, 71.653],
                                  [128.797, -126.541],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.9, 17.799],
                                  [48.195, -40.68],
                                  [0.671, -94.457],
                                  [-32.211, -45.124],
                                  [-13.638, -23.141],
                                  [-192.269, 38.645],
                                  [1.13, 48.882],
                                  [10.202, 34.724],
                                  [-23.705, 92],
                                ],
                                o: [
                                  [-73.825, -5.584],
                                  [-48.195, 40.68],
                                  [-0.671, 94.457],
                                  [32.211, 45.124],
                                  [24.98, 37.1],
                                  [103.275, -19.45],
                                  [-1.13, -48.882],
                                  [-26.779, -49.556],
                                  [23.705, -92],
                                ],
                                v: [
                                  [-0.525, -285.873],
                                  [-174.301, -238.93],
                                  [-247.168, -51.542],
                                  [-253.349, 144.641],
                                  [-161.988, 259.058],
                                  [109.053, 327.547],
                                  [322.611, 260.934],
                                  [259.755, 70.55],
                                  [129.263, -126.011],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.822, 17.644],
                                  [48.009, -40.892],
                                  [0.802, -94.327],
                                  [-32.445, -45.1],
                                  [-13.647, -23.249],
                                  [-192.091, 38.563],
                                  [1.446, 48.96],
                                  [10.068, 34.993],
                                  [-23.67, 92],
                                ],
                                o: [
                                  [-73.883, -5.457],
                                  [-48.009, 40.892],
                                  [-0.802, 94.327],
                                  [32.445, 45.1],
                                  [24.964, 37.178],
                                  [103.294, -19.411],
                                  [-1.447, -48.96],
                                  [-26.609, -49.792],
                                  [23.67, -92],
                                ],
                                v: [
                                  [-0.723, -285.966],
                                  [-173.846, -238.198],
                                  [-247.098, -51.539],
                                  [-253.38, 144.665],
                                  [-160.928, 259.212],
                                  [108.286, 327.158],
                                  [323.038, 261.307],
                                  [259.051, 69.51],
                                  [129.778, -125.543],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.745, 17.49],
                                  [47.822, -41.103],
                                  [0.934, -94.198],
                                  [-32.68, -45.075],
                                  [-13.657, -23.357],
                                  [-191.913, 38.482],
                                  [1.763, 49.038],
                                  [9.935, 35.262],
                                  [-23.635, 92],
                                ],
                                o: [
                                  [-73.941, -5.329],
                                  [-47.822, 41.103],
                                  [-0.934, 94.198],
                                  [32.68, 45.075],
                                  [24.949, 37.255],
                                  [103.314, -19.372],
                                  [-1.763, -49.038],
                                  [-26.439, -50.028],
                                  [23.635, -92],
                                ],
                                v: [
                                  [-0.985, -286.01],
                                  [-173.385, -237.461],
                                  [-247.032, -51.53],
                                  [-253.35, 144.736],
                                  [-159.87, 259.364],
                                  [107.561, 326.776],
                                  [323.479, 261.618],
                                  [258.295, 68.422],
                                  [130.24, -125.021],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.667, 17.335],
                                  [47.635, -41.314],
                                  [1.065, -94.068],
                                  [-32.914, -45.05],
                                  [-13.667, -23.466],
                                  [-191.735, 38.401],
                                  [2.08, 49.116],
                                  [9.801, 35.531],
                                  [-23.6, 92],
                                ],
                                o: [
                                  [-73.999, -5.201],
                                  [-47.635, 41.314],
                                  [-1.065, 94.068],
                                  [32.914, 45.05],
                                  [24.933, 37.333],
                                  [103.333, -19.334],
                                  [-2.08, -49.116],
                                  [-26.268, -50.264],
                                  [23.6, -92],
                                ],
                                v: [
                                  [-1.193, -286.06],
                                  [-172.92, -236.735],
                                  [-246.963, -51.514],
                                  [-253.375, 144.754],
                                  [-158.875, 259.461],
                                  [106.779, 326.449],
                                  [323.973, 261.938],
                                  [257.543, 67.397],
                                  [130.75, -124.452],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.59, 17.18],
                                  [47.449, -41.526],
                                  [1.197, -93.938],
                                  [-33.149, -45.026],
                                  [-13.676, -23.574],
                                  [-191.557, 38.32],
                                  [2.397, 49.194],
                                  [9.668, 35.799],
                                  [-23.566, 92],
                                ],
                                o: [
                                  [-74.057, -5.074],
                                  [-47.449, 41.526],
                                  [-1.197, 93.938],
                                  [33.149, 45.026],
                                  [24.918, 37.41],
                                  [103.353, -19.295],
                                  [-2.397, -49.194],
                                  [-26.098, -50.5],
                                  [23.566, -92],
                                ],
                                v: [
                                  [-1.465, -286.109],
                                  [-172.459, -236.003],
                                  [-246.898, -51.539],
                                  [-253.394, 144.817],
                                  [-157.873, 259.504],
                                  [106.048, 326.12],
                                  [324.418, 262.205],
                                  [256.741, 66.324],
                                  [131.27, -123.885],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.513, 17.025],
                                  [47.262, -41.737],
                                  [1.328, -93.809],
                                  [-33.383, -45.001],
                                  [-13.686, -23.682],
                                  [-191.379, 38.238],
                                  [2.714, 49.272],
                                  [9.534, 36.068],
                                  [-23.531, 92],
                                ],
                                o: [
                                  [-74.115, -4.946],
                                  [-47.262, 41.737],
                                  [-1.328, 93.809],
                                  [33.383, 45.001],
                                  [24.903, 37.487],
                                  [103.372, -19.256],
                                  [-2.714, -49.272],
                                  [-25.928, -50.736],
                                  [23.531, -92],
                                ],
                                v: [
                                  [-1.738, -286.066],
                                  [-171.992, -235.32],
                                  [-246.839, -51.558],
                                  [-253.368, 144.935],
                                  [-156.881, 259.554],
                                  [105.313, 325.854],
                                  [324.925, 262.427],
                                  [255.943, 65.305],
                                  [131.783, -123.326],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.435, 16.871],
                                  [47.075, -41.949],
                                  [1.46, -93.679],
                                  [-33.618, -44.976],
                                  [-13.696, -23.791],
                                  [-191.201, 38.157],
                                  [3.03, 49.349],
                                  [9.401, 36.337],
                                  [-23.496, 92],
                                ],
                                o: [
                                  [-74.173, -4.818],
                                  [-47.075, 41.949],
                                  [-1.46, 93.679],
                                  [33.618, 44.976],
                                  [24.887, 37.565],
                                  [103.391, -19.218],
                                  [-3.03, -49.349],
                                  [-25.758, -50.972],
                                  [23.496, -92],
                                ],
                                v: [
                                  [-2.021, -286.077],
                                  [-171.466, -234.648],
                                  [-246.785, -51.516],
                                  [-253.399, 144.991],
                                  [-155.934, 259.605],
                                  [104.576, 325.588],
                                  [325.447, 262.696],
                                  [255.139, 64.302],
                                  [132.351, -122.768],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.358, 16.716],
                                  [46.889, -42.16],
                                  [1.591, -93.55],
                                  [-33.852, -44.952],
                                  [-13.705, -23.899],
                                  [-191.023, 38.076],
                                  [3.347, 49.427],
                                  [9.267, 36.606],
                                  [-23.461, 92],
                                ],
                                o: [
                                  [-74.232, -4.691],
                                  [-46.889, 42.16],
                                  [-1.591, 93.55],
                                  [33.852, 44.952],
                                  [24.872, 37.642],
                                  [103.411, -19.179],
                                  [-3.347, -49.427],
                                  [-25.587, -51.208],
                                  [23.461, -92],
                                ],
                                v: [
                                  [-2.305, -286.041],
                                  [-170.988, -234.024],
                                  [-246.728, -51.522],
                                  [-253.368, 145.091],
                                  [-154.998, 259.547],
                                  [103.834, 325.383],
                                  [325.968, 262.867],
                                  [254.286, 63.298],
                                  [132.876, -122.219],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.281, 16.561],
                                  [46.702, -42.371],
                                  [1.723, -93.42],
                                  [-34.087, -44.927],
                                  [-13.715, -24.007],
                                  [-190.845, 37.995],
                                  [3.664, 49.505],
                                  [9.134, 36.875],
                                  [-23.426, 92],
                                ],
                                o: [
                                  [-74.29, -4.563],
                                  [-46.702, 42.371],
                                  [-1.723, 93.42],
                                  [34.087, 44.927],
                                  [24.856, 37.719],
                                  [103.43, -19.14],
                                  [-3.664, -49.505],
                                  [-25.417, -51.444],
                                  [23.426, -92],
                                ],
                                v: [
                                  [-2.6, -286.015],
                                  [-170.459, -233.393],
                                  [-246.677, -51.522],
                                  [-253.4, 145.245],
                                  [-154.116, 259.499],
                                  [103.143, 325.223],
                                  [326.497, 263.049],
                                  [253.436, 62.3],
                                  [133.455, -121.627],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.203, 16.406],
                                  [46.515, -42.583],
                                  [1.855, -93.29],
                                  [-34.322, -44.902],
                                  [-13.725, -24.115],
                                  [-190.667, 37.913],
                                  [3.981, 49.583],
                                  [9, 37.144],
                                  [-23.391, 92],
                                ],
                                o: [
                                  [-74.348, -4.435],
                                  [-46.515, 42.583],
                                  [-1.855, 93.29],
                                  [34.322, 44.902],
                                  [24.841, 37.797],
                                  [103.449, -19.102],
                                  [-3.981, -49.583],
                                  [-25.247, -51.68],
                                  [23.391, -92],
                                ],
                                v: [
                                  [-2.906, -285.944],
                                  [-169.924, -232.763],
                                  [-246.687, -51.515],
                                  [-253.426, 145.344],
                                  [-153.234, 259.452],
                                  [102.448, 325.133],
                                  [327.033, 263.241],
                                  [252.537, 61.373],
                                  [133.983, -121.046],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.126, 16.252],
                                  [46.329, -42.794],
                                  [1.986, -93.161],
                                  [-34.556, -44.878],
                                  [-13.734, -24.224],
                                  [-190.489, 37.832],
                                  [4.297, 49.661],
                                  [8.867, 37.413],
                                  [-23.357, 92],
                                ],
                                o: [
                                  [-74.406, -4.308],
                                  [-46.328, 42.794],
                                  [-1.986, 93.161],
                                  [34.556, 44.878],
                                  [24.825, 37.874],
                                  [103.469, -19.063],
                                  [-4.298, -49.661],
                                  [-25.077, -51.916],
                                  [23.357, -92],
                                ],
                                v: [
                                  [-3.266, -285.873],
                                  [-169.329, -232.136],
                                  [-246.649, -51.493],
                                  [-253.399, 145.487],
                                  [-152.363, 259.351],
                                  [101.759, 325.086],
                                  [327.569, 263.383],
                                  [251.633, 60.444],
                                  [134.566, -120.466],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.048, 16.097],
                                  [46.142, -43.005],
                                  [2.118, -93.031],
                                  [-34.791, -44.853],
                                  [-13.744, -24.332],
                                  [-190.311, 37.751],
                                  [4.614, 49.739],
                                  [8.734, 37.682],
                                  [-23.322, 92],
                                ],
                                o: [
                                  [-74.464, -4.18],
                                  [-46.142, 43.005],
                                  [-2.118, 93.031],
                                  [34.791, 44.853],
                                  [24.81, 37.952],
                                  [103.488, -19.024],
                                  [-4.614, -49.739],
                                  [-24.906, -52.152],
                                  [23.322, -92],
                                ],
                                v: [
                                  [-3.585, -285.769],
                                  [-168.79, -231.607],
                                  [-246.618, -51.474],
                                  [-253.427, 145.673],
                                  [-151.555, 259.189],
                                  [101.111, 325.048],
                                  [328.061, 263.492],
                                  [250.733, 59.512],
                                  [135.16, -119.897],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.971, 15.942],
                                  [45.955, -43.217],
                                  [2.249, -92.901],
                                  [-35.025, -44.828],
                                  [-13.754, -24.441],
                                  [-190.133, 37.67],
                                  [4.931, 49.817],
                                  [8.6, 37.951],
                                  [-23.287, 92],
                                ],
                                o: [
                                  [-74.522, -4.052],
                                  [-45.955, 43.217],
                                  [-2.249, 92.902],
                                  [35.025, 44.828],
                                  [24.794, 38.029],
                                  [103.507, -18.986],
                                  [-4.931, -49.817],
                                  [-24.736, -52.388],
                                  [23.287, -92],
                                ],
                                v: [
                                  [-3.959, -285.728],
                                  [-168.19, -231.027],
                                  [-246.595, -51.448],
                                  [-253.447, 145.804],
                                  [-150.757, 259.027],
                                  [100.467, 325.062],
                                  [328.605, 263.605],
                                  [249.784, 58.65],
                                  [135.798, -119.286],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.894, 15.787],
                                  [45.768, -43.428],
                                  [2.381, -92.772],
                                  [-35.26, -44.804],
                                  [-13.763, -24.549],
                                  [-189.955, 37.588],
                                  [5.248, 49.894],
                                  [8.467, 38.22],
                                  [-23.252, 92],
                                ],
                                o: [
                                  [-74.58, -3.925],
                                  [-45.768, 43.428],
                                  [-2.381, 92.772],
                                  [35.26, 44.804],
                                  [24.779, 38.106],
                                  [103.527, -18.947],
                                  [-5.248, -49.895],
                                  [-24.566, -52.624],
                                  [23.252, -92],
                                ],
                                v: [
                                  [-4.291, -285.645],
                                  [-167.637, -230.501],
                                  [-246.571, -51.47],
                                  [-253.424, 145.987],
                                  [-150.023, 258.804],
                                  [99.874, 325.137],
                                  [329.168, 263.73],
                                  [248.838, 57.794],
                                  [136.395, -118.74],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.816, 15.633],
                                  [45.582, -43.639],
                                  [2.512, -92.642],
                                  [-35.494, -44.779],
                                  [-13.773, -24.657],
                                  [-189.777, 37.507],
                                  [5.565, 49.972],
                                  [8.333, 38.488],
                                  [-23.217, 92],
                                ],
                                o: [
                                  [-74.638, -3.797],
                                  [-45.582, 43.639],
                                  [-2.512, 92.642],
                                  [35.494, 44.779],
                                  [24.763, 38.184],
                                  [103.546, -18.908],
                                  [-5.565, -49.972],
                                  [-24.396, -52.86],
                                  [23.217, -92],
                                ],
                                v: [
                                  [-4.679, -285.511],
                                  [-167.023, -230.02],
                                  [-246.597, -51.431],
                                  [-253.446, 146.212],
                                  [-149.247, 258.572],
                                  [99.276, 325.263],
                                  [329.668, 263.763],
                                  [247.897, 56.935],
                                  [136.994, -118.144],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.739, 15.478],
                                  [45.395, -43.851],
                                  [2.644, -92.513],
                                  [-35.729, -44.754],
                                  [-13.783, -24.766],
                                  [-189.599, 37.426],
                                  [5.881, 50.05],
                                  [8.2, 38.757],
                                  [-23.182, 92],
                                ],
                                o: [
                                  [-74.696, -3.669],
                                  [-45.395, 43.851],
                                  [-2.644, 92.513],
                                  [35.729, 44.754],
                                  [24.748, 38.261],
                                  [103.565, -18.87],
                                  [-5.881, -50.05],
                                  [-24.226, -53.096],
                                  [23.182, -92],
                                ],
                                v: [
                                  [-5.079, -285.441],
                                  [-166.411, -229.539],
                                  [-246.579, -51.397],
                                  [-253.521, 146.382],
                                  [-148.546, 258.287],
                                  [98.727, 325.451],
                                  [330.178, 263.799],
                                  [246.897, 56.146],
                                  [137.637, -117.603],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.661, 15.323],
                                  [45.208, -44.062],
                                  [2.775, -92.383],
                                  [-35.964, -44.73],
                                  [-13.792, -24.874],
                                  [-189.421, 37.345],
                                  [6.198, 50.128],
                                  [8.066, 39.026],
                                  [-23.148, 92],
                                ],
                                o: [
                                  [-74.754, -3.542],
                                  [-45.208, 44.062],
                                  [-2.775, 92.383],
                                  [35.964, 44.73],
                                  [24.732, 38.338],
                                  [103.585, -18.831],
                                  [-6.198, -50.128],
                                  [-24.055, -53.332],
                                  [23.148, -92],
                                ],
                                v: [
                                  [-5.428, -285.32],
                                  [-165.791, -229.112],
                                  [-246.621, -51.356],
                                  [-253.545, 146.593],
                                  [-147.846, 257.994],
                                  [98.184, 325.69],
                                  [330.698, 263.84],
                                  [245.902, 55.353],
                                  [138.291, -117.032],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.584, 15.168],
                                  [45.022, -44.273],
                                  [2.907, -92.253],
                                  [-36.198, -44.705],
                                  [-13.802, -24.982],
                                  [-189.243, 37.263],
                                  [6.515, 50.206],
                                  [7.933, 39.295],
                                  [-23.113, 92],
                                ],
                                o: [
                                  [-74.812, -3.414],
                                  [-45.022, 44.273],
                                  [-2.907, 92.253],
                                  [36.198, 44.705],
                                  [24.717, 38.416],
                                  [103.604, -18.792],
                                  [-6.515, -50.206],
                                  [-23.885, -53.568],
                                  [23.113, -92],
                                ],
                                v: [
                                  [-5.833, -285.202],
                                  [-165.12, -228.675],
                                  [-246.652, -51.319],
                                  [-253.569, 146.855],
                                  [-147.158, 257.711],
                                  [97.637, 325.979],
                                  [331.209, 263.853],
                                  [244.91, 54.619],
                                  [138.946, -116.454],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.507, 15.014],
                                  [44.835, -44.485],
                                  [3.039, -92.124],
                                  [-36.433, -44.68],
                                  [-13.812, -25.091],
                                  [-189.066, 37.182],
                                  [6.832, 50.284],
                                  [7.799, 39.564],
                                  [-23.078, 92],
                                ],
                                o: [
                                  [-74.87, -3.286],
                                  [-44.835, 44.485],
                                  [-3.039, 92.124],
                                  [36.433, 44.68],
                                  [24.701, 38.493],
                                  [103.623, -18.753],
                                  [-6.832, -50.284],
                                  [-23.715, -53.804],
                                  [23.078, -92],
                                ],
                                v: [
                                  [-6.24, -285.034],
                                  [-164.494, -228.291],
                                  [-246.702, -51.286],
                                  [-253.647, 147.062],
                                  [-146.534, 257.355],
                                  [97.139, 326.33],
                                  [331.688, 263.807],
                                  [243.913, 53.891],
                                  [139.613, -115.952],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.429, 14.859],
                                  [44.648, -44.696],
                                  [3.17, -91.994],
                                  [-36.667, -44.656],
                                  [-13.821, -25.199],
                                  [-188.887, 37.101],
                                  [7.148, 50.362],
                                  [7.666, 39.833],
                                  [-23.043, 92],
                                ],
                                o: [
                                  [-74.928, -3.158],
                                  [-44.648, 44.696],
                                  [-3.17, 91.994],
                                  [36.667, 44.656],
                                  [24.686, 38.571],
                                  [103.643, -18.715],
                                  [-7.148, -50.362],
                                  [-23.545, -54.04],
                                  [23.043, -92],
                                ],
                                v: [
                                  [-6.597, -284.857],
                                  [-163.806, -227.948],
                                  [-246.793, -51.196],
                                  [-253.716, 147.308],
                                  [-145.881, 257.011],
                                  [96.689, 326.73],
                                  [332.158, 263.777],
                                  [242.93, 53.221],
                                  [140.272, -115.4],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.352, 14.704],
                                  [44.462, -44.907],
                                  [3.302, -91.865],
                                  [-36.902, -44.631],
                                  [-13.831, -25.307],
                                  [-188.71, 37.02],
                                  [7.465, 50.44],
                                  [7.532, 40.102],
                                  [-23.008, 92],
                                ],
                                o: [
                                  [-74.986, -3.031],
                                  [-44.462, 44.907],
                                  [-3.302, 91.865],
                                  [36.902, 44.631],
                                  [24.67, 38.648],
                                  [103.662, -18.676],
                                  [-7.465, -50.44],
                                  [-23.374, -54.276],
                                  [23.008, -92],
                                ],
                                v: [
                                  [-6.967, -284.694],
                                  [-163.12, -227.616],
                                  [-246.892, -51.153],
                                  [-253.785, 147.552],
                                  [-145.284, 256.657],
                                  [96.244, 327.191],
                                  [332.638, 263.7],
                                  [241.992, 52.557],
                                  [140.932, -114.852],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.275, 14.549],
                                  [44.275, -45.119],
                                  [3.433, -91.735],
                                  [-37.136, -44.606],
                                  [-13.841, -25.416],
                                  [-188.532, 36.938],
                                  [7.782, 50.517],
                                  [7.399, 40.371],
                                  [-22.974, 92],
                                ],
                                o: [
                                  [-75.044, -2.903],
                                  [-44.275, 45.119],
                                  [-3.433, 91.735],
                                  [37.136, 44.606],
                                  [24.655, 38.725],
                                  [103.681, -18.637],
                                  [-7.782, -50.517],
                                  [-23.204, -54.512],
                                  [22.974, -92],
                                ],
                                v: [
                                  [-7.382, -284.522],
                                  [-162.425, -227.325],
                                  [-246.948, -51.125],
                                  [-253.854, 147.793],
                                  [-144.699, 256.241],
                                  [95.858, 327.65],
                                  [333.12, 263.639],
                                  [241.048, 51.889],
                                  [141.594, -114.369],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.197, 14.394],
                                  [44.088, -45.33],
                                  [3.565, -91.605],
                                  [-37.371, -44.582],
                                  [-13.85, -25.524],
                                  [-188.354, 36.857],
                                  [8.099, 50.595],
                                  [7.265, 40.64],
                                  [-22.939, 92],
                                ],
                                o: [
                                  [-75.102, -2.775],
                                  [-44.088, 45.33],
                                  [-3.565, 91.605],
                                  [37.371, 44.582],
                                  [24.639, 38.803],
                                  [103.701, -18.599],
                                  [-8.099, -50.595],
                                  [-23.034, -54.748],
                                  [22.939, -92],
                                ],
                                v: [
                                  [-7.747, -284.363],
                                  [-161.73, -227.074],
                                  [-247.107, -51.04],
                                  [-253.913, 148.083],
                                  [-144.18, 255.836],
                                  [95.458, 328.158],
                                  [333.562, 263.531],
                                  [240.117, 51.277],
                                  [142.267, -113.849],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.12, 14.24],
                                  [43.902, -45.541],
                                  [3.696, -91.476],
                                  [-37.605, -44.557],
                                  [-13.86, -25.632],
                                  [-188.176, 36.776],
                                  [8.416, 50.673],
                                  [7.132, 40.909],
                                  [-22.904, 92],
                                ],
                                o: [
                                  [-75.16, -2.648],
                                  [-43.902, 45.541],
                                  [-3.696, 91.476],
                                  [37.605, 44.557],
                                  [24.624, 38.88],
                                  [103.72, -18.56],
                                  [-8.416, -50.673],
                                  [-22.864, -54.984],
                                  [22.904, -92],
                                ],
                                v: [
                                  [-8.115, -284.144],
                                  [-161.027, -226.874],
                                  [-247.213, -51.002],
                                  [-253.932, 148.36],
                                  [-143.611, 255.422],
                                  [95.126, 328.726],
                                  [333.994, 263.43],
                                  [239.18, 50.682],
                                  [142.932, -113.385],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.042, 14.085],
                                  [43.715, -45.753],
                                  [3.828, -91.346],
                                  [-37.84, -44.532],
                                  [-13.87, -25.741],
                                  [-187.998, 36.695],
                                  [8.732, 50.751],
                                  [6.998, 41.177],
                                  [-22.869, 92],
                                ],
                                o: [
                                  [-75.218, -2.52],
                                  [-43.715, 45.753],
                                  [-3.828, 91.346],
                                  [37.84, 44.532],
                                  [24.608, 38.958],
                                  [103.739, -18.521],
                                  [-8.732, -50.751],
                                  [-22.693, -55.22],
                                  [22.869, -92],
                                ],
                                v: [
                                  [-8.433, -283.927],
                                  [-160.324, -226.672],
                                  [-247.328, -50.918],
                                  [-253.888, 148.582],
                                  [-143.12, 254.955],
                                  [94.78, 329.292],
                                  [334.438, 263.282],
                                  [238.246, 50.133],
                                  [143.66, -112.924],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.965, 13.93],
                                  [43.528, -45.964],
                                  [3.959, -91.217],
                                  [-38.075, -44.508],
                                  [-13.879, -25.849],
                                  [-187.82, 36.613],
                                  [9.049, 50.829],
                                  [6.865, 41.446],
                                  [-22.834, 92],
                                ],
                                o: [
                                  [-75.276, -2.392],
                                  [-43.528, 45.964],
                                  [-3.959, 91.217],
                                  [38.075, 44.508],
                                  [24.593, 39.035],
                                  [103.759, -18.483],
                                  [-9.049, -50.829],
                                  [-22.523, -55.456],
                                  [22.834, -92],
                                ],
                                v: [
                                  [-8.807, -283.713],
                                  [-159.571, -226.521],
                                  [-247.494, -50.84],
                                  [-253.846, 148.853],
                                  [-142.632, 254.49],
                                  [94.439, 329.867],
                                  [334.832, 263.089],
                                  [237.367, 49.579],
                                  [144.328, -112.477],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.888, 13.775],
                                  [43.342, -46.175],
                                  [4.091, -91.087],
                                  [-38.309, -44.483],
                                  [-13.889, -25.957],
                                  [-187.642, 36.532],
                                  [9.366, 50.907],
                                  [6.731, 41.715],
                                  [-22.799, 92],
                                ],
                                o: [
                                  [-75.334, -2.265],
                                  [-43.342, 46.175],
                                  [-4.091, 91.087],
                                  [38.309, 44.483],
                                  [24.578, 39.112],
                                  [103.778, -18.444],
                                  [-9.366, -50.907],
                                  [-22.353, -55.693],
                                  [22.799, -92],
                                ],
                                v: [
                                  [-9.12, -283.45],
                                  [-158.797, -226.316],
                                  [-247.67, -50.759],
                                  [-253.701, 149.069],
                                  [-142.209, 254.026],
                                  [94.158, 330.491],
                                  [335.228, 262.902],
                                  [236.491, 49.04],
                                  [145.008, -112.046],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.81, 13.621],
                                  [43.155, -46.387],
                                  [4.222, -90.957],
                                  [-38.544, -44.458],
                                  [-13.899, -26.066],
                                  [-187.464, 36.451],
                                  [9.683, 50.985],
                                  [6.598, 41.984],
                                  [-22.765, 92],
                                ],
                                o: [
                                  [-75.392, -2.137],
                                  [-43.155, 46.387],
                                  [-4.222, 90.957],
                                  [38.544, 44.458],
                                  [24.562, 39.19],
                                  [103.797, -18.405],
                                  [-9.683, -50.985],
                                  [-22.183, -55.929],
                                  [22.765, -92],
                                ],
                                v: [
                                  [-9.499, -283.24],
                                  [-158.086, -226.15],
                                  [-247.845, -50.695],
                                  [-253.495, 149.282],
                                  [-141.75, 253.55],
                                  [93.872, 331.163],
                                  [335.625, 262.733],
                                  [235.607, 48.557],
                                  [145.741, -111.619],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.733, 13.466],
                                  [42.968, -46.598],
                                  [4.354, -90.828],
                                  [-38.778, -44.434],
                                  [-13.908, -26.174],
                                  [-187.286, 36.37],
                                  [10, 51.062],
                                  [6.464, 42.253],
                                  [-22.73, 92],
                                ],
                                o: [
                                  [-75.45, -2.009],
                                  [-42.968, 46.598],
                                  [-4.354, 90.828],
                                  [38.778, 44.434],
                                  [24.547, 39.267],
                                  [103.817, -18.367],
                                  [-10, -51.062],
                                  [-22.013, -56.165],
                                  [22.73, -92],
                                ],
                                v: [
                                  [-9.817, -282.982],
                                  [-157.313, -226.033],
                                  [-248.07, -50.616],
                                  [-253.291, 149.532],
                                  [-141.358, 253.087],
                                  [93.645, 331.845],
                                  [335.985, 262.508],
                                  [234.737, 48.119],
                                  [146.413, -111.247],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.656, 13.311],
                                  [42.782, -46.809],
                                  [4.486, -90.698],
                                  [-39.013, -44.409],
                                  [-13.918, -26.282],
                                  [-187.108, 36.288],
                                  [10.316, 51.14],
                                  [6.331, 42.522],
                                  [-22.695, 92],
                                ],
                                o: [
                                  [-75.508, -1.882],
                                  [-42.782, 46.809],
                                  [-4.486, 90.698],
                                  [39.013, 44.409],
                                  [24.531, 39.344],
                                  [103.836, -18.328],
                                  [-10.316, -51.14],
                                  [-21.842, -56.401],
                                  [22.695, -92],
                                ],
                                v: [
                                  [-10.139, -282.778],
                                  [-156.541, -225.863],
                                  [-248.255, -50.556],
                                  [-253.036, 149.739],
                                  [-140.969, 252.613],
                                  [93.413, 332.523],
                                  [336.335, 262.289],
                                  [233.91, 47.686],
                                  [147.097, -110.89],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.578, 13.156],
                                  [42.595, -47.021],
                                  [4.617, -90.568],
                                  [-39.247, -44.384],
                                  [-13.928, -26.391],
                                  [-186.93, 36.207],
                                  [10.633, 51.218],
                                  [6.197, 42.791],
                                  [-22.66, 92],
                                ],
                                o: [
                                  [-75.566, -1.754],
                                  [-42.595, 47.021],
                                  [-4.617, 90.568],
                                  [39.247, 44.384],
                                  [24.516, 39.422],
                                  [103.855, -18.289],
                                  [-10.633, -51.218],
                                  [-21.672, -56.637],
                                  [22.66, -92],
                                ],
                                v: [
                                  [-10.4, -282.525],
                                  [-155.758, -225.691],
                                  [-248.489, -50.492],
                                  [-252.783, 149.931],
                                  [-140.647, 252.1],
                                  [93.189, 333.212],
                                  [336.648, 262.026],
                                  [233.073, 47.257],
                                  [147.823, -110.538],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.501, 13.002],
                                  [42.408, -47.232],
                                  [4.749, -90.439],
                                  [-39.482, -44.36],
                                  [-13.937, -26.499],
                                  [-186.752, 36.126],
                                  [10.95, 51.296],
                                  [6.064, 43.06],
                                  [-22.625, 92],
                                ],
                                o: [
                                  [-75.624, -1.626],
                                  [-42.408, 47.232],
                                  [-4.749, 90.439],
                                  [39.482, 44.36],
                                  [24.5, 39.499],
                                  [103.875, -18.25],
                                  [-10.95, -51.296],
                                  [-21.502, -56.873],
                                  [22.625, -92],
                                ],
                                v: [
                                  [-10.727, -282.263],
                                  [-155.027, -225.556],
                                  [-248.735, -50.386],
                                  [-252.481, 150.133],
                                  [-140.277, 251.577],
                                  [93.024, 333.948],
                                  [337.002, 261.77],
                                  [232.251, 46.883],
                                  [148.511, -110.201],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.423, 12.847],
                                  [42.221, -47.444],
                                  [4.88, -90.309],
                                  [-39.717, -44.335],
                                  [-13.947, -26.607],
                                  [-186.574, 36.045],
                                  [11.267, 51.374],
                                  [5.93, 43.329],
                                  [-22.591, 92],
                                ],
                                o: [
                                  [-75.682, -1.499],
                                  [-42.221, 47.444],
                                  [-4.88, 90.309],
                                  [39.717, 44.335],
                                  [24.485, 39.577],
                                  [103.894, -18.212],
                                  [-11.267, -51.374],
                                  [-21.332, -57.109],
                                  [22.59, -92],
                                ],
                                v: [
                                  [-10.994, -282.066],
                                  [-154.245, -225.431],
                                  [-248.979, -50.339],
                                  [-252.117, 150.268],
                                  [-139.987, 251.105],
                                  [92.855, 334.682],
                                  [337.268, 261.47],
                                  [231.42, 46.564],
                                  [149.201, -109.919],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.346, 12.692],
                                  [42.035, -47.655],
                                  [5.012, -90.18],
                                  [-39.951, -44.31],
                                  [-13.957, -26.716],
                                  [-186.396, 35.963],
                                  [11.583, 51.452],
                                  [5.797, 43.598],
                                  [-22.556, 92],
                                ],
                                o: [
                                  [-75.74, -1.371],
                                  [-42.035, 47.655],
                                  [-5.012, 90.18],
                                  [39.951, 44.31],
                                  [24.469, 39.654],
                                  [103.913, -18.173],
                                  [-11.583, -51.452],
                                  [-21.161, -57.345],
                                  [22.556, -92],
                                ],
                                v: [
                                  [-11.252, -281.809],
                                  [-153.463, -225.291],
                                  [-249.235, -50.238],
                                  [-251.768, 150.464],
                                  [-139.688, 250.582],
                                  [92.744, 335.426],
                                  [337.526, 261.176],
                                  [230.654, 46.236],
                                  [149.88, -109.654],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.269, 12.537],
                                  [41.848, -47.866],
                                  [5.143, -90.05],
                                  [-40.186, -44.286],
                                  [-13.966, -26.824],
                                  [-186.218, 35.882],
                                  [11.9, 51.53],
                                  [5.663, 43.866],
                                  [-22.521, 92],
                                ],
                                o: [
                                  [-75.798, -1.243],
                                  [-41.848, 47.866],
                                  [-5.143, 90.05],
                                  [40.186, 44.286],
                                  [24.454, 39.731],
                                  [103.933, -18.134],
                                  [-11.9, -51.53],
                                  [-20.991, -57.581],
                                  [22.521, -92],
                                ],
                                v: [
                                  [-11.524, -281.554],
                                  [-152.72, -225.149],
                                  [-249.529, -50.146],
                                  [-251.346, 150.594],
                                  [-139.418, 250.123],
                                  [92.642, 336.218],
                                  [337.786, 260.878],
                                  [229.828, 45.913],
                                  [150.573, -109.443],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.191, 12.383],
                                  [41.661, -48.078],
                                  [5.275, -89.92],
                                  [-40.42, -44.261],
                                  [-13.976, -26.932],
                                  [-186.04, 35.801],
                                  [12.217, 51.608],
                                  [5.53, 44.135],
                                  [-22.486, 92],
                                ],
                                o: [
                                  [-75.857, -1.116],
                                  [-41.661, 48.078],
                                  [-5.275, 89.92],
                                  [40.42, 44.261],
                                  [24.438, 39.809],
                                  [103.952, -18.096],
                                  [-12.217, -51.608],
                                  [-20.821, -57.817],
                                  [22.486, -92],
                                ],
                                v: [
                                  [-11.774, -281.352],
                                  [-151.978, -225.005],
                                  [-249.846, -50.064],
                                  [-250.937, 150.733],
                                  [-139.19, 249.603],
                                  [92.536, 336.958],
                                  [338.01, 260.536],
                                  [229.092, 45.643],
                                  [151.268, -109.198],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.114, 12.228],
                                  [41.475, -48.289],
                                  [5.406, -89.791],
                                  [-40.655, -44.236],
                                  [-13.986, -27.04],
                                  [-185.862, 35.72],
                                  [12.534, 51.685],
                                  [5.396, 44.404],
                                  [-22.451, 92],
                                ],
                                o: [
                                  [-75.915, -0.988],
                                  [-41.475, 48.289],
                                  [-5.406, 89.791],
                                  [40.655, 44.236],
                                  [24.423, 39.886],
                                  [103.972, -18.057],
                                  [-12.534, -51.685],
                                  [-20.651, -58.053],
                                  [22.451, -92],
                                ],
                                v: [
                                  [-11.989, -281.103],
                                  [-151.235, -224.858],
                                  [-250.15, -49.98],
                                  [-250.531, 150.869],
                                  [-138.942, 249.133],
                                  [92.488, 337.757],
                                  [338.224, 260.202],
                                  [228.32, 45.427],
                                  [151.952, -109.058],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.037, 12.073],
                                  [41.288, -48.5],
                                  [5.538, -89.661],
                                  [-40.889, -44.212],
                                  [-13.995, -27.149],
                                  [-185.684, 35.638],
                                  [12.85, 51.763],
                                  [5.263, 44.673],
                                  [-22.416, 92],
                                ],
                                o: [
                                  [-75.973, -0.86],
                                  [-41.288, 48.5],
                                  [-5.538, 89.661],
                                  [40.889, 44.212],
                                  [24.407, 39.964],
                                  [103.991, -18.018],
                                  [-12.851, -51.763],
                                  [-20.48, -58.289],
                                  [22.416, -92],
                                ],
                                v: [
                                  [-12.194, -280.855],
                                  [-150.492, -224.759],
                                  [-250.466, -49.905],
                                  [-250.064, 150.953],
                                  [-138.735, 248.613],
                                  [92.45, 338.555],
                                  [338.392, 259.874],
                                  [227.551, 45.214],
                                  [152.65, -108.885],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.959, 11.918],
                                  [41.101, -48.712],
                                  [5.669, -89.532],
                                  [-41.124, -44.187],
                                  [-14.005, -27.257],
                                  [-185.506, 35.557],
                                  [13.167, 51.841],
                                  [5.13, 44.942],
                                  [-22.382, 92],
                                ],
                                o: [
                                  [-76.031, -0.733],
                                  [-41.101, 48.712],
                                  [-5.669, 89.532],
                                  [41.124, 44.187],
                                  [24.392, 40.041],
                                  [104.01, -17.98],
                                  [-13.167, -51.841],
                                  [-20.31, -58.525],
                                  [22.382, -92],
                                ],
                                v: [
                                  [-12.401, -280.66],
                                  [-149.749, -224.657],
                                  [-250.794, -49.779],
                                  [-249.599, 151.085],
                                  [-138.558, 248.157],
                                  [92.408, 339.363],
                                  [338.575, 259.491],
                                  [226.821, 44.991],
                                  [153.299, -108.766],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.882, 11.764],
                                  [40.915, -48.923],
                                  [5.801, -89.402],
                                  [-41.358, -44.162],
                                  [-14.015, -27.366],
                                  [-185.328, 35.476],
                                  [13.484, 51.919],
                                  [4.996, 45.211],
                                  [-22.347, 92],
                                ],
                                o: [
                                  [-76.089, -0.605],
                                  [-40.915, 48.923],
                                  [-5.801, 89.402],
                                  [41.358, 44.162],
                                  [24.376, 40.118],
                                  [104.03, -17.941],
                                  [-13.484, -51.919],
                                  [-20.14, -58.761],
                                  [22.347, -92],
                                ],
                                v: [
                                  [-12.598, -280.467],
                                  [-149.056, -224.503],
                                  [-251.159, -49.713],
                                  [-249.136, 151.164],
                                  [-138.423, 247.689],
                                  [92.439, 340.169],
                                  [338.748, 259.114],
                                  [226.093, 44.821],
                                  [153.988, -108.701],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.804, 11.609],
                                  [40.728, -49.134],
                                  [5.933, -89.272],
                                  [-41.593, -44.138],
                                  [-14.024, -27.474],
                                  [-185.15, 35.395],
                                  [13.801, 51.997],
                                  [4.863, 45.48],
                                  [-22.312, 92],
                                ],
                                o: [
                                  [-76.147, -0.477],
                                  [-40.728, 49.134],
                                  [-5.933, 89.272],
                                  [41.593, 44.138],
                                  [24.361, 40.196],
                                  [104.049, -17.902],
                                  [-13.801, -51.997],
                                  [-19.97, -58.997],
                                  [22.312, -92],
                                ],
                                v: [
                                  [-12.784, -280.263],
                                  [-148.363, -224.396],
                                  [-251.487, -49.608],
                                  [-248.625, 151.203],
                                  [-138.256, 247.221],
                                  [92.465, 340.923],
                                  [338.874, 258.732],
                                  [225.416, 44.704],
                                  [154.642, -108.666],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.727, 11.454],
                                  [40.541, -49.346],
                                  [6.064, -89.143],
                                  [-41.828, -44.113],
                                  [-14.034, -27.582],
                                  [-184.972, 35.313],
                                  [14.118, 52.075],
                                  [4.729, 45.749],
                                  [-22.277, 92],
                                ],
                                o: [
                                  [-76.205, -0.35],
                                  [-40.541, 49.346],
                                  [-6.064, 89.143],
                                  [41.828, 44.113],
                                  [24.345, 40.273],
                                  [104.068, -17.864],
                                  [-14.118, -52.075],
                                  [-19.799, -59.233],
                                  [22.277, -92],
                                ],
                                v: [
                                  [-12.936, -280.075],
                                  [-147.657, -224.286],
                                  [-251.876, -49.551],
                                  [-248.166, 151.277],
                                  [-138.155, 246.767],
                                  [92.502, 341.725],
                                  [338.941, 258.357],
                                  [224.74, 44.589],
                                  [155.346, -108.623],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.65, 11.299],
                                  [40.355, -49.557],
                                  [6.196, -89.013],
                                  [-42.062, -44.088],
                                  [-14.044, -27.691],
                                  [-184.794, 35.232],
                                  [14.434, 52.153],
                                  [4.596, 46.018],
                                  [-22.242, 92],
                                ],
                                o: [
                                  [-76.263, -0.222],
                                  [-40.355, 49.557],
                                  [-6.196, 89.013],
                                  [42.062, 44.088],
                                  [24.33, 40.35],
                                  [104.088, -17.825],
                                  [-14.434, -52.153],
                                  [-19.629, -59.469],
                                  [22.242, -92],
                                ],
                                v: [
                                  [-13.064, -279.875],
                                  [-147.013, -224.161],
                                  [-252.253, -49.456],
                                  [-247.646, 151.326],
                                  [-138.047, 246.35],
                                  [92.549, 342.538],
                                  [339.025, 257.989],
                                  [224.067, 44.476],
                                  [155.99, -108.647],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.572, 11.144],
                                  [40.168, -49.768],
                                  [6.327, -88.883],
                                  [-42.297, -44.064],
                                  [-14.053, -27.799],
                                  [-184.616, 35.151],
                                  [14.751, 52.23],
                                  [4.462, 46.286],
                                  [-22.207, 92],
                                ],
                                o: [
                                  [-76.321, -0.094],
                                  [-40.168, 49.768],
                                  [-6.327, 88.883],
                                  [42.297, 44.064],
                                  [24.314, 40.428],
                                  [104.107, -17.786],
                                  [-14.751, -52.23],
                                  [-19.459, -59.705],
                                  [22.208, -92],
                                ],
                                v: [
                                  [-13.194, -279.677],
                                  [-146.318, -224.059],
                                  [-252.642, -49.36],
                                  [-247.129, 151.36],
                                  [-137.956, 245.946],
                                  [92.643, 343.35],
                                  [339.111, 257.564],
                                  [223.381, 44.402],
                                  [156.649, -108.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.495, 10.99],
                                  [39.981, -49.98],
                                  [6.459, -88.754],
                                  [-42.531, -44.039],
                                  [-14.063, -27.907],
                                  [-184.438, 35.07],
                                  [15.068, 52.308],
                                  [4.329, 46.555],
                                  [-22.173, 92],
                                ],
                                o: [
                                  [-76.379, 0.034],
                                  [-39.981, 49.98],
                                  [-6.459, 88.754],
                                  [42.531, 44.039],
                                  [24.299, 40.505],
                                  [104.126, -17.747],
                                  [-15.068, -52.308],
                                  [-19.289, -59.941],
                                  [22.173, -92],
                                ],
                                v: [
                                  [-13.326, -279.531],
                                  [-145.673, -223.99],
                                  [-253.03, -49.277],
                                  [-246.626, 151.355],
                                  [-137.933, 245.529],
                                  [92.76, 344.124],
                                  [339.139, 257.134],
                                  [222.759, 44.33],
                                  [157.247, -108.796],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.417, 10.835],
                                  [39.795, -50.191],
                                  [6.59, -88.624],
                                  [-42.766, -44.014],
                                  [-14.073, -28.016],
                                  [-184.26, 34.988],
                                  [15.385, 52.386],
                                  [4.195, 46.824],
                                  [-22.138, 92],
                                ],
                                o: [
                                  [-76.437, 0.161],
                                  [-39.795, 50.191],
                                  [-6.59, 88.624],
                                  [42.766, 44.014],
                                  [24.283, 40.583],
                                  [104.146, -17.709],
                                  [-15.385, -52.386],
                                  [-19.118, -60.177],
                                  [22.138, -92],
                                ],
                                v: [
                                  [-13.397, -279.386],
                                  [-145.077, -223.87],
                                  [-253.432, -49.192],
                                  [-246.161, 151.35],
                                  [-137.852, 245.126],
                                  [92.924, 344.932],
                                  [339.121, 256.723],
                                  [222.125, 44.309],
                                  [157.91, -108.944],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.34, 10.68],
                                  [39.608, -50.402],
                                  [6.722, -88.495],
                                  [-43, -43.99],
                                  [-14.082, -28.124],
                                  [-184.082, 34.907],
                                  [15.702, 52.464],
                                  [4.062, 47.093],
                                  [-22.103, 92],
                                ],
                                o: [
                                  [-76.495, 0.289],
                                  [-39.608, 50.402],
                                  [-6.722, 88.495],
                                  [43, 43.99],
                                  [24.268, 40.66],
                                  [104.165, -17.67],
                                  [-15.702, -52.464],
                                  [-18.948, -60.413],
                                  [22.103, -92],
                                ],
                                v: [
                                  [-13.47, -279.229],
                                  [-144.493, -223.747],
                                  [-253.87, -49.07],
                                  [-245.636, 151.343],
                                  [-137.838, 244.759],
                                  [93.112, 345.704],
                                  [339.106, 256.292],
                                  [221.491, 44.276],
                                  [158.512, -109.111],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.263, 10.525],
                                  [39.421, -50.614],
                                  [6.853, -88.365],
                                  [-43.235, -43.965],
                                  [-14.092, -28.232],
                                  [-183.904, 34.826],
                                  [16.018, 52.542],
                                  [3.928, 47.362],
                                  [-22.068, 92],
                                ],
                                o: [
                                  [-76.553, 0.417],
                                  [-39.421, 50.614],
                                  [-6.853, 88.365],
                                  [43.235, 43.965],
                                  [24.253, 40.737],
                                  [104.184, -17.631],
                                  [-16.018, -52.542],
                                  [-18.778, -60.649],
                                  [22.068, -92],
                                ],
                                v: [
                                  [-13.532, -279.136],
                                  [-143.895, -223.683],
                                  [-254.271, -48.984],
                                  [-245.126, 151.348],
                                  [-137.83, 244.406],
                                  [93.249, 346.474],
                                  [339.095, 255.916],
                                  [220.908, 44.294],
                                  [159.116, -109.344],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.185, 10.371],
                                  [39.235, -50.825],
                                  [6.985, -88.235],
                                  [-43.469, -43.94],
                                  [-14.102, -28.341],
                                  [-183.726, 34.745],
                                  [16.335, 52.62],
                                  [3.795, 47.631],
                                  [-22.033, 92],
                                ],
                                o: [
                                  [-76.611, 0.544],
                                  [-39.235, 50.825],
                                  [-6.985, 88.235],
                                  [43.469, 43.94],
                                  [24.237, 40.815],
                                  [104.204, -17.593],
                                  [-16.335, -52.62],
                                  [-18.608, -60.885],
                                  [22.033, -92],
                                ],
                                v: [
                                  [-13.581, -279.031],
                                  [-143.359, -223.604],
                                  [-254.722, -48.875],
                                  [-244.666, 151.303],
                                  [-137.826, 244.101],
                                  [93.496, 347.257],
                                  [339.025, 255.497],
                                  [220.325, 44.313],
                                  [159.709, -109.581],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.108, 10.216],
                                  [39.048, -51.036],
                                  [7.117, -88.106],
                                  [-43.704, -43.916],
                                  [-14.112, -28.449],
                                  [-183.548, 34.663],
                                  [16.652, 52.698],
                                  [3.661, 47.9],
                                  [-21.999, 92],
                                ],
                                o: [
                                  [-76.669, 0.672],
                                  [-39.048, 51.036],
                                  [-7.116, 88.106],
                                  [43.704, 43.916],
                                  [24.222, 40.892],
                                  [104.223, -17.554],
                                  [-16.652, -52.698],
                                  [-18.437, -61.121],
                                  [21.999, -92],
                                ],
                                v: [
                                  [-13.632, -278.928],
                                  [-142.774, -223.535],
                                  [-255.124, -48.801],
                                  [-244.146, 151.271],
                                  [-137.889, 243.797],
                                  [93.706, 347.976],
                                  [338.911, 255.085],
                                  [219.743, 44.332],
                                  [160.303, -109.871],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.031, 10.061],
                                  [38.861, -51.248],
                                  [7.248, -87.976],
                                  [-43.939, -43.891],
                                  [-14.121, -28.557],
                                  [-183.37, 34.582],
                                  [16.969, 52.776],
                                  [3.528, 48.169],
                                  [-21.964, 92],
                                ],
                                o: [
                                  [-76.727, 0.8],
                                  [-38.861, 51.248],
                                  [-7.248, 87.976],
                                  [43.939, 43.891],
                                  [24.206, 40.969],
                                  [104.242, -17.515],
                                  [-16.969, -52.775],
                                  [-18.267, -61.357],
                                  [21.964, -92],
                                ],
                                v: [
                                  [-13.622, -278.873],
                                  [-142.299, -223.463],
                                  [-255.574, -48.691],
                                  [-243.676, 151.19],
                                  [-137.895, 243.479],
                                  [93.99, 348.708],
                                  [338.8, 254.665],
                                  [219.211, 44.353],
                                  [160.898, -110.165],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.953, 9.906],
                                  [38.675, -51.459],
                                  [7.38, -87.847],
                                  [-44.173, -43.866],
                                  [-14.131, -28.666],
                                  [-183.192, 34.501],
                                  [17.285, 52.853],
                                  [3.394, 48.438],
                                  [-21.929, 92],
                                ],
                                o: [
                                  [-76.785, 0.927],
                                  [-38.675, 51.459],
                                  [-7.38, 87.847],
                                  [44.173, 43.866],
                                  [24.191, 41.047],
                                  [104.262, -17.477],
                                  [-17.285, -52.853],
                                  [-18.097, -61.593],
                                  [21.929, -92],
                                ],
                                v: [
                                  [-13.662, -278.806],
                                  [-141.823, -223.389],
                                  [-256.038, -48.568],
                                  [-243.222, 151.171],
                                  [-137.968, 243.223],
                                  [94.273, 349.44],
                                  [338.679, 254.251],
                                  [218.678, 44.41],
                                  [161.433, -110.526],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.876, 9.752],
                                  [38.488, -51.67],
                                  [7.511, -87.717],
                                  [-44.408, -43.842],
                                  [-14.141, -28.774],
                                  [-183.014, 34.42],
                                  [17.602, 52.931],
                                  [3.261, 48.707],
                                  [-21.894, 92],
                                ],
                                o: [
                                  [-76.843, 1.055],
                                  [-38.488, 51.67],
                                  [-7.511, 87.717],
                                  [44.408, 43.842],
                                  [24.175, 41.124],
                                  [104.281, -17.438],
                                  [-17.602, -52.931],
                                  [-17.927, -61.829],
                                  [21.894, -92],
                                ],
                                v: [
                                  [-13.64, -278.754],
                                  [-141.347, -223.327],
                                  [-256.487, -48.458],
                                  [-242.755, 151.103],
                                  [-137.983, 242.966],
                                  [94.569, 350.122],
                                  [338.513, 253.843],
                                  [218.147, 44.467],
                                  [161.969, -110.94],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.798, 9.597],
                                  [38.301, -51.882],
                                  [7.643, -87.587],
                                  [-44.642, -43.817],
                                  [-14.15, -28.882],
                                  [-182.836, 34.338],
                                  [17.919, 53.009],
                                  [3.127, 48.975],
                                  [-21.859, 92],
                                ],
                                o: [
                                  [-76.901, 1.183],
                                  [-38.301, 51.882],
                                  [-7.643, 87.587],
                                  [44.642, 43.817],
                                  [24.16, 41.202],
                                  [104.3, -17.399],
                                  [-17.919, -53.009],
                                  [-17.757, -62.065],
                                  [21.859, -92],
                                ],
                                v: [
                                  [-13.557, -278.736],
                                  [-140.869, -223.248],
                                  [-256.951, -48.362],
                                  [-242.337, 150.987],
                                  [-138.066, 242.758],
                                  [94.878, 350.819],
                                  [338.351, 253.427],
                                  [217.664, 44.559],
                                  [162.508, -111.357],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.721, 9.442],
                                  [38.115, -52.093],
                                  [7.774, -87.458],
                                  [-44.877, -43.792],
                                  [-14.16, -28.991],
                                  [-182.658, 34.257],
                                  [18.236, 53.087],
                                  [2.994, 49.244],
                                  [-21.824, 92],
                                ],
                                o: [
                                  [-76.959, 1.31],
                                  [-38.115, 52.093],
                                  [-7.774, 87.458],
                                  [44.877, 43.792],
                                  [24.144, 41.279],
                                  [104.32, -17.361],
                                  [-18.236, -53.087],
                                  [-17.586, -62.301],
                                  [21.824, -92],
                                ],
                                v: [
                                  [-13.523, -278.767],
                                  [-140.454, -223.228],
                                  [-257.4, -48.28],
                                  [-241.921, 150.9],
                                  [-138.153, 242.582],
                                  [95.248, 351.5],
                                  [338.13, 253.064],
                                  [217.181, 44.603],
                                  [162.985, -111.826],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.644, 9.287],
                                  [37.928, -52.305],
                                  [7.906, -87.328],
                                  [-45.111, -43.768],
                                  [-14.17, -29.099],
                                  [-182.48, 34.176],
                                  [18.553, 53.165],
                                  [2.86, 49.513],
                                  [-21.79, 92],
                                ],
                                o: [
                                  [-77.017, 1.438],
                                  [-37.928, 52.305],
                                  [-7.906, 87.328],
                                  [45.111, 43.768],
                                  [24.129, 41.356],
                                  [104.339, -17.322],
                                  [-18.552, -53.165],
                                  [-17.416, -62.537],
                                  [21.79, -92],
                                ],
                                v: [
                                  [-13.428, -278.798],
                                  [-140.101, -223.173],
                                  [-257.85, -48.199],
                                  [-241.491, 150.799],
                                  [-138.308, 242.42],
                                  [95.633, 352.148],
                                  [337.914, 252.707],
                                  [216.731, 44.695],
                                  [163.512, -112.361],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.566, 9.133],
                                  [37.741, -52.516],
                                  [8.037, -87.199],
                                  [-45.346, -43.743],
                                  [-14.179, -29.207],
                                  [-182.302, 34.095],
                                  [18.869, 53.243],
                                  [2.727, 49.782],
                                  [-21.755, 92],
                                ],
                                o: [
                                  [-77.075, 1.566],
                                  [-37.741, 52.516],
                                  [-8.037, 87.199],
                                  [45.346, 43.743],
                                  [24.113, 41.434],
                                  [104.358, -17.283],
                                  [-18.869, -53.243],
                                  [-17.246, -62.773],
                                  [21.755, -92],
                                ],
                                v: [
                                  [-13.382, -278.815],
                                  [-139.699, -223.149],
                                  [-258.298, -48.133],
                                  [-241.077, 150.727],
                                  [-138.405, 242.258],
                                  [95.983, 352.749],
                                  [337.653, 252.293],
                                  [216.296, 44.788],
                                  [163.978, -112.933],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.489, 8.978],
                                  [37.554, -52.727],
                                  [8.169, -87.069],
                                  [-45.581, -43.718],
                                  [-14.189, -29.316],
                                  [-182.124, 34.013],
                                  [19.186, 53.321],
                                  [2.593, 50.051],
                                  [-21.72, 92],
                                ],
                                o: [
                                  [-77.133, 1.693],
                                  [-37.554, 52.727],
                                  [-8.169, 87.069],
                                  [45.581, 43.718],
                                  [24.098, 41.511],
                                  [104.378, -17.244],
                                  [-19.186, -53.321],
                                  [-17.076, -63.009],
                                  [21.72, -92],
                                ],
                                v: [
                                  [-13.273, -278.879],
                                  [-139.406, -223.136],
                                  [-258.747, -48.02],
                                  [-240.76, 150.609],
                                  [-138.508, 242.142],
                                  [96.443, 353.349],
                                  [337.382, 251.933],
                                  [215.909, 44.866],
                                  [164.445, -113.523],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.411, 8.823],
                                  [37.368, -52.939],
                                  [8.3, -86.939],
                                  [-45.815, -43.694],
                                  [-14.199, -29.424],
                                  [-181.946, 33.932],
                                  [19.503, 53.398],
                                  [2.46, 50.32],
                                  [-21.685, 92],
                                ],
                                o: [
                                  [-77.191, 1.821],
                                  [-37.368, 52.939],
                                  [-8.3, 86.939],
                                  [45.815, 43.694],
                                  [24.082, 41.589],
                                  [104.397, -17.206],
                                  [-19.503, -53.398],
                                  [-16.905, -63.245],
                                  [21.685, -92],
                                ],
                                v: [
                                  [-13.103, -278.958],
                                  [-139.065, -223.074],
                                  [-259.195, -47.969],
                                  [-240.395, 150.506],
                                  [-138.662, 242.073],
                                  [96.886, 353.902],
                                  [337.067, 251.625],
                                  [215.522, 44.944],
                                  [164.867, -114.116],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.334, 8.668],
                                  [37.181, -53.15],
                                  [8.432, -86.81],
                                  [-46.05, -43.669],
                                  [-14.208, -29.532],
                                  [-181.768, 33.851],
                                  [19.82, 53.476],
                                  [2.326, 50.589],
                                  [-21.65, 92],
                                ],
                                o: [
                                  [-77.249, 1.949],
                                  [-37.181, 53.15],
                                  [-8.432, 86.81],
                                  [46.05, 43.669],
                                  [24.067, 41.666],
                                  [104.416, -17.167],
                                  [-19.82, -53.476],
                                  [-16.735, -63.481],
                                  [21.65, -92],
                                ],
                                v: [
                                  [-12.981, -279.069],
                                  [-138.787, -223.072],
                                  [-259.644, -47.935],
                                  [-240.064, 150.405],
                                  [-138.821, 242.05],
                                  [97.328, 354.456],
                                  [336.741, 251.275],
                                  [215.149, 45.021],
                                  [165.275, -114.808],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.257, 8.514],
                                  [36.994, -53.361],
                                  [8.564, -86.68],
                                  [-46.284, -43.644],
                                  [-14.218, -29.641],
                                  [-181.591, 33.77],
                                  [20.136, 53.554],
                                  [2.193, 50.858],
                                  [-21.616, 92],
                                ],
                                o: [
                                  [-77.307, 2.076],
                                  [-36.994, 53.361],
                                  [-8.564, 86.68],
                                  [46.284, 43.644],
                                  [24.051, 41.743],
                                  [104.436, -17.128],
                                  [-20.136, -53.554],
                                  [-16.565, -63.717],
                                  [21.616, -92],
                                ],
                                v: [
                                  [-12.845, -279.212],
                                  [-138.57, -223.115],
                                  [-260.076, -47.855],
                                  [-239.749, 150.272],
                                  [-138.937, 242.026],
                                  [97.818, 354.993],
                                  [336.372, 250.962],
                                  [214.808, 45.131],
                                  [165.684, -115.517],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.179, 8.359],
                                  [36.808, -53.573],
                                  [8.695, -86.55],
                                  [-46.519, -43.62],
                                  [-14.228, -29.749],
                                  [-181.413, 33.688],
                                  [20.453, 53.632],
                                  [2.059, 51.127],
                                  [-21.581, 92],
                                ],
                                o: [
                                  [-77.365, 2.204],
                                  [-36.808, 53.573],
                                  [-8.695, 86.55],
                                  [46.519, 43.62],
                                  [24.036, 41.821],
                                  [104.455, -17.09],
                                  [-20.453, -53.632],
                                  [-16.395, -63.953],
                                  [21.581, -92],
                                ],
                                v: [
                                  [-12.646, -279.369],
                                  [-138.353, -223.109],
                                  [-260.508, -47.791],
                                  [-239.529, 150.142],
                                  [-139.106, 242.047],
                                  [98.292, 355.5],
                                  [336.006, 250.701],
                                  [214.483, 45.194],
                                  [166.079, -116.262],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.102, 8.204],
                                  [36.621, -53.784],
                                  [8.827, -86.421],
                                  [-46.753, -43.595],
                                  [-14.237, -29.857],
                                  [-181.235, 33.607],
                                  [20.77, 53.71],
                                  [1.926, 51.396],
                                  [-21.546, 92],
                                ],
                                o: [
                                  [-77.424, 2.332],
                                  [-36.621, 53.784],
                                  [-8.827, 86.421],
                                  [46.753, 43.595],
                                  [24.02, 41.898],
                                  [104.475, -17.051],
                                  [-20.77, -53.71],
                                  [-16.224, -64.189],
                                  [21.546, -92],
                                ],
                                v: [
                                  [-12.462, -279.51],
                                  [-138.151, -223.1],
                                  [-260.94, -47.775],
                                  [-239.262, 150.013],
                                  [-139.279, 242.067],
                                  [98.766, 356.008],
                                  [335.63, 250.383],
                                  [214.157, 45.303],
                                  [166.429, -117.056],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.025, 8.049],
                                  [36.434, -53.995],
                                  [8.958, -86.291],
                                  [-46.988, -43.57],
                                  [-14.247, -29.966],
                                  [-181.057, 33.526],
                                  [21.087, 53.788],
                                  [1.792, 51.664],
                                  [-21.511, 92],
                                ],
                                o: [
                                  [-77.482, 2.459],
                                  [-36.434, 53.995],
                                  [-8.958, 86.291],
                                  [46.988, 43.57],
                                  [24.005, 41.975],
                                  [104.494, -17.012],
                                  [-21.087, -53.788],
                                  [-16.054, -64.425],
                                  [21.511, -92],
                                ],
                                v: [
                                  [-12.249, -279.76],
                                  [-137.996, -223.152],
                                  [-261.371, -47.729],
                                  [-239.011, 149.886],
                                  [-139.44, 242.194],
                                  [99.257, 356.487],
                                  [335.21, 250.178],
                                  [213.83, 45.396],
                                  [166.78, -117.868],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.947, 7.894],
                                  [36.248, -54.207],
                                  [9.09, -86.162],
                                  [-47.222, -43.546],
                                  [-14.257, -30.074],
                                  [-180.879, 33.445],
                                  [21.404, 53.866],
                                  [1.659, 51.933],
                                  [-21.476, 92],
                                ],
                                o: [
                                  [-77.54, 2.587],
                                  [-36.248, 54.207],
                                  [-9.09, 86.162],
                                  [47.222, 43.546],
                                  [23.989, 42.053],
                                  [104.513, -16.974],
                                  [-21.404, -53.866],
                                  [-15.884, -64.661],
                                  [21.476, -92],
                                ],
                                v: [
                                  [-12.035, -279.992],
                                  [-137.855, -223.218],
                                  [-261.802, -47.685],
                                  [-238.776, 149.714],
                                  [-139.607, 242.304],
                                  [99.702, 356.89],
                                  [334.779, 249.915],
                                  [213.566, 45.457],
                                  [167.117, -118.714],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.87, 7.74],
                                  [36.061, -54.418],
                                  [9.221, -86.032],
                                  [-47.457, -43.521],
                                  [-14.266, -30.182],
                                  [-180.701, 33.363],
                                  [21.72, 53.943],
                                  [1.525, 52.202],
                                  [-21.441, 92],
                                ],
                                o: [
                                  [-77.598, 2.715],
                                  [-36.061, 54.418],
                                  [-9.221, 86.032],
                                  [47.457, 43.521],
                                  [23.974, 42.13],
                                  [104.533, -16.935],
                                  [-21.72, -53.943],
                                  [-15.714, -64.897],
                                  [21.441, -92],
                                ],
                                v: [
                                  [-11.821, -280.223],
                                  [-137.762, -223.234],
                                  [-262.217, -47.643],
                                  [-238.496, 149.544],
                                  [-139.793, 242.474],
                                  [100.21, 357.343],
                                  [334.305, 249.687],
                                  [213.286, 45.549],
                                  [167.439, -119.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.792, 7.585],
                                  [35.874, -54.629],
                                  [9.353, -85.902],
                                  [-47.692, -43.496],
                                  [-14.276, -30.291],
                                  [-180.523, 33.282],
                                  [22.037, 54.021],
                                  [1.392, 52.471],
                                  [-21.407, 92],
                                ],
                                o: [
                                  [-77.656, 2.842],
                                  [-35.874, 54.629],
                                  [-9.353, 85.902],
                                  [47.692, 43.496],
                                  [23.958, 42.208],
                                  [104.552, -16.896],
                                  [-22.037, -54.021],
                                  [-15.543, -65.133],
                                  [21.407, -92],
                                ],
                                v: [
                                  [-11.544, -280.562],
                                  [-137.698, -223.265],
                                  [-262.63, -47.618],
                                  [-238.279, 149.375],
                                  [-139.952, 242.642],
                                  [100.69, 357.705],
                                  [333.835, 249.51],
                                  [213.022, 45.64],
                                  [167.732, -120.522],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.715, 7.43],
                                  [35.688, -54.841],
                                  [9.484, -85.773],
                                  [-47.926, -43.472],
                                  [-14.286, -30.399],
                                  [-180.345, 33.201],
                                  [22.354, 54.099],
                                  [1.259, 52.74],
                                  [-21.372, 92],
                                ],
                                o: [
                                  [-77.714, 2.97],
                                  [-35.688, 54.841],
                                  [-9.484, 85.773],
                                  [47.926, 43.472],
                                  [23.943, 42.285],
                                  [104.571, -16.858],
                                  [-22.354, -54.099],
                                  [-15.373, -65.369],
                                  [21.372, -92],
                                ],
                                v: [
                                  [-11.314, -280.883],
                                  [-137.649, -223.295],
                                  [-263.043, -47.612],
                                  [-238.034, 149.193],
                                  [-140.132, 242.854],
                                  [101.17, 358.103],
                                  [333.353, 249.337],
                                  [212.757, 45.714],
                                  [168.04, -121.436],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.638, 7.275],
                                  [35.501, -55.052],
                                  [9.616, -85.643],
                                  [-48.161, -43.447],
                                  [-14.295, -30.507],
                                  [-180.167, 33.12],
                                  [22.671, 54.177],
                                  [1.125, 53.009],
                                  [-21.337, 92],
                                ],
                                o: [
                                  [-77.772, 3.098],
                                  [-35.501, 55.052],
                                  [-9.616, 85.643],
                                  [48.161, 43.447],
                                  [23.928, 42.362],
                                  [104.591, -16.819],
                                  [-22.671, -54.177],
                                  [-15.203, -65.605],
                                  [21.337, -92],
                                ],
                                v: [
                                  [-11.022, -281.202],
                                  [-137.676, -223.339],
                                  [-263.456, -47.591],
                                  [-237.79, 148.982],
                                  [-140.299, 243.156],
                                  [101.653, 358.441],
                                  [332.829, 249.167],
                                  [212.538, 45.788],
                                  [168.319, -122.291],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.56, 7.121],
                                  [35.314, -55.263],
                                  [9.747, -85.514],
                                  [-48.395, -43.422],
                                  [-14.305, -30.616],
                                  [-179.989, 33.038],
                                  [22.987, 54.255],
                                  [0.992, 53.278],
                                  [-21.302, 92],
                                ],
                                o: [
                                  [-77.83, 3.225],
                                  [-35.314, 55.263],
                                  [-9.747, 85.514],
                                  [48.395, 43.422],
                                  [23.912, 42.44],
                                  [104.61, -16.78],
                                  [-22.987, -54.255],
                                  [-15.033, -65.841],
                                  [21.302, -92],
                                ],
                                v: [
                                  [-10.729, -281.611],
                                  [-137.765, -223.336],
                                  [-263.821, -47.589],
                                  [-237.564, 148.803],
                                  [-140.471, 243.456],
                                  [102.153, 358.754],
                                  [332.338, 248.984],
                                  [212.335, 45.86],
                                  [168.629, -123.148],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.483, 6.966],
                                  [35.128, -55.475],
                                  [9.879, -85.384],
                                  [-48.63, -43.398],
                                  [-14.315, -30.724],
                                  [-179.811, 32.957],
                                  [23.304, 54.333],
                                  [0.858, 53.547],
                                  [-21.267, 92],
                                ],
                                o: [
                                  [-77.888, 3.353],
                                  [-35.128, 55.475],
                                  [-9.879, 85.384],
                                  [48.63, 43.398],
                                  [23.897, 42.517],
                                  [104.629, -16.742],
                                  [-23.304, -54.333],
                                  [-14.862, -66.077],
                                  [21.267, -92],
                                ],
                                v: [
                                  [-10.435, -282.019],
                                  [-137.835, -223.348],
                                  [-264.215, -47.605],
                                  [-237.34, 148.596],
                                  [-140.63, 243.798],
                                  [102.61, 359.07],
                                  [331.852, 248.851],
                                  [212.116, 45.932],
                                  [168.923, -124.023],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.406, 6.811],
                                  [34.941, -55.686],
                                  [10.011, -85.254],
                                  [-48.864, -43.373],
                                  [-14.324, -30.832],
                                  [-179.633, 32.876],
                                  [23.621, 54.411],
                                  [0.725, 53.816],
                                  [-21.233, 92],
                                ],
                                o: [
                                  [-77.946, 3.481],
                                  [-34.941, 55.686],
                                  [-10.011, 85.254],
                                  [48.864, 43.373],
                                  [23.881, 42.594],
                                  [104.649, -16.703],
                                  [-23.621, -54.411],
                                  [-14.692, -66.313],
                                  [21.232, -92],
                                ],
                                v: [
                                  [-10.125, -282.469],
                                  [-138.012, -223.376],
                                  [-264.563, -47.561],
                                  [-237.088, 148.375],
                                  [-140.839, 244.155],
                                  [103.085, 359.315],
                                  [331.368, 248.675],
                                  [211.912, 45.94],
                                  [169.218, -124.838],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.328, 6.656],
                                  [34.754, -55.897],
                                  [10.142, -85.125],
                                  [-49.099, -43.348],
                                  [-14.334, -30.941],
                                  [-179.455, 32.795],
                                  [23.938, 54.489],
                                  [0.591, 54.085],
                                  [-21.198, 92],
                                ],
                                o: [
                                  [-78.004, 3.608],
                                  [-34.754, 55.897],
                                  [-10.142, 85.125],
                                  [49.099, 43.348],
                                  [23.866, 42.672],
                                  [104.668, -16.664],
                                  [-23.938, -54.489],
                                  [-14.522, -66.549],
                                  [21.198, -92],
                                ],
                                v: [
                                  [-9.769, -282.917],
                                  [-138.187, -223.358],
                                  [-264.956, -47.582],
                                  [-236.885, 148.172],
                                  [-141.006, 244.553],
                                  [103.562, 359.611],
                                  [330.918, 248.531],
                                  [211.708, 45.992],
                                  [169.559, -125.656],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.251, 6.502],
                                  [34.568, -56.109],
                                  [10.274, -84.995],
                                  [-49.334, -43.324],
                                  [-14.344, -31.049],
                                  [-179.277, 32.713],
                                  [24.255, 54.566],
                                  [0.458, 54.353],
                                  [-21.163, 92],
                                ],
                                o: [
                                  [-78.062, 3.736],
                                  [-34.568, 56.109],
                                  [-10.274, 84.995],
                                  [49.334, 43.324],
                                  [23.85, 42.749],
                                  [104.687, -16.625],
                                  [-24.255, -54.566],
                                  [-14.352, -66.785],
                                  [21.163, -92],
                                ],
                                v: [
                                  [-9.458, -283.454],
                                  [-138.406, -223.386],
                                  [-265.302, -47.606],
                                  [-236.655, 147.955],
                                  [-141.16, 244.995],
                                  [104.014, 359.82],
                                  [330.471, 248.345],
                                  [211.521, 46.044],
                                  [169.838, -126.476],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.173, 6.347],
                                  [34.381, -56.32],
                                  [10.405, -84.865],
                                  [-49.568, -43.299],
                                  [-14.353, -31.157],
                                  [-179.099, 32.632],
                                  [24.571, 54.644],
                                  [0.324, 54.622],
                                  [-21.128, 92],
                                ],
                                o: [
                                  [-78.12, 3.864],
                                  [-34.381, 56.32],
                                  [-10.405, 84.866],
                                  [49.568, 43.299],
                                  [23.835, 42.827],
                                  [104.707, -16.587],
                                  [-24.571, -54.644],
                                  [-14.182, -67.021],
                                  [21.128, -92],
                                ],
                                v: [
                                  [-9.147, -284.004],
                                  [-138.685, -223.338],
                                  [-265.63, -47.586],
                                  [-236.444, 147.711],
                                  [-141.301, 245.433],
                                  [104.496, 360.035],
                                  [330.027, 248.208],
                                  [211.363, 46.032],
                                  [170.163, -127.236],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.096, 6.192],
                                  [34.194, -56.531],
                                  [10.537, -84.736],
                                  [-49.803, -43.274],
                                  [-14.363, -31.266],
                                  [-178.921, 32.551],
                                  [24.888, 54.722],
                                  [0.191, 54.891],
                                  [-21.093, 92],
                                ],
                                o: [
                                  [-78.178, 3.992],
                                  [-34.194, 56.531],
                                  [-10.537, 84.736],
                                  [49.803, 43.274],
                                  [23.819, 42.904],
                                  [104.726, -16.548],
                                  [-24.888, -54.722],
                                  [-14.011, -67.257],
                                  [21.093, -92],
                                ],
                                v: [
                                  [-8.772, -284.58],
                                  [-139.008, -223.353],
                                  [-265.912, -47.614],
                                  [-236.237, 147.498],
                                  [-141.49, 245.868],
                                  [104.953, 360.208],
                                  [329.569, 248.056],
                                  [211.175, 46.081],
                                  [170.47, -128.015],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.019, 6.037],
                                  [34.008, -56.743],
                                  [10.668, -84.606],
                                  [-50.037, -43.25],
                                  [-14.373, -31.374],
                                  [-178.743, 32.47],
                                  [25.205, 54.8],
                                  [0.057, 55.16],
                                  [-21.058, 92],
                                ],
                                o: [
                                  [-78.236, 4.119],
                                  [-34.008, 56.743],
                                  [-10.668, 84.606],
                                  [50.037, 43.25],
                                  [23.804, 42.981],
                                  [104.745, -16.509],
                                  [-25.205, -54.8],
                                  [-13.841, -67.493],
                                  [21.058, -92],
                                ],
                                v: [
                                  [-8.398, -285.153],
                                  [-139.328, -223.324],
                                  [-266.193, -47.662],
                                  [-236.049, 147.304],
                                  [-141.638, 246.362],
                                  [105.412, 360.387],
                                  [329.159, 247.907],
                                  [211.033, 46.049],
                                  [170.778, -128.78],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.941, 5.883],
                                  [33.821, -56.954],
                                  [10.8, -84.477],
                                  [-50.272, -43.225],
                                  [-14.382, -31.482],
                                  [-178.565, 32.388],
                                  [25.522, 54.878],
                                  [-0.076, 55.429],
                                  [-21.024, 92],
                                ],
                                o: [
                                  [-78.294, 4.247],
                                  [-33.821, 56.954],
                                  [-10.8, 84.477],
                                  [50.272, 43.225],
                                  [23.788, 43.059],
                                  [104.765, -16.471],
                                  [-25.522, -54.878],
                                  [-13.671, -67.729],
                                  [21.024, -92],
                                ],
                                v: [
                                  [-8.068, -285.739],
                                  [-139.736, -223.357],
                                  [-266.456, -47.696],
                                  [-235.865, 147.112],
                                  [-141.816, 246.88],
                                  [105.829, 360.527],
                                  [328.798, 247.806],
                                  [210.891, 46.034],
                                  [171.067, -129.548],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.864, 5.728],
                                  [33.634, -57.165],
                                  [10.931, -84.347],
                                  [-50.506, -43.2],
                                  [-14.392, -31.591],
                                  [-178.387, 32.307],
                                  [25.838, 54.956],
                                  [-0.21, 55.698],
                                  [-20.989, 92],
                                ],
                                o: [
                                  [-78.352, 4.375],
                                  [-33.634, 57.165],
                                  [-10.931, 84.347],
                                  [50.506, 43.2],
                                  [23.773, 43.136],
                                  [104.784, -16.432],
                                  [-25.838, -54.956],
                                  [-13.501, -67.965],
                                  [20.989, -92],
                                ],
                                v: [
                                  [-7.719, -286.322],
                                  [-140.159, -223.345],
                                  [-266.736, -47.731],
                                  [-235.701, 146.86],
                                  [-141.935, 247.412],
                                  [106.267, 360.626],
                                  [328.376, 247.662],
                                  [210.72, 45.999],
                                  [171.419, -130.318],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.786, 5.573],
                                  [33.447, -57.377],
                                  [11.063, -84.217],
                                  [-50.741, -43.176],
                                  [-14.402, -31.699],
                                  [-178.209, 32.226],
                                  [26.155, 55.034],
                                  [-0.343, 55.967],
                                  [-20.954, 92],
                                ],
                                o: [
                                  [-78.41, 4.502],
                                  [-33.447, 57.377],
                                  [-11.063, 84.217],
                                  [50.741, 43.176],
                                  [23.757, 43.214],
                                  [104.803, -16.393],
                                  [-26.155, -55.034],
                                  [-13.33, -68.201],
                                  [20.954, -92],
                                ],
                                v: [
                                  [-7.388, -286.885],
                                  [-140.623, -223.307],
                                  [-266.952, -47.787],
                                  [-235.541, 146.673],
                                  [-142.085, 247.94],
                                  [106.708, 360.732],
                                  [328.001, 247.548],
                                  [210.595, 45.963],
                                  [171.691, -131.045],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.709, 5.418],
                                  [33.261, -57.588],
                                  [11.194, -84.088],
                                  [-50.975, -43.151],
                                  [-14.411, -31.807],
                                  [-178.031, 32.145],
                                  [26.472, 55.111],
                                  [-0.477, 56.236],
                                  [-20.919, 92],
                                ],
                                o: [
                                  [-78.468, 4.63],
                                  [-33.261, 57.588],
                                  [-11.194, 84.088],
                                  [50.975, 43.151],
                                  [23.742, 43.291],
                                  [104.823, -16.355],
                                  [-26.472, -55.111],
                                  [-13.16, -68.437],
                                  [20.919, -92],
                                ],
                                v: [
                                  [-7.038, -287.507],
                                  [-141.13, -223.315],
                                  [-267.15, -47.873],
                                  [-235.401, 146.488],
                                  [-142.192, 248.483],
                                  [107.135, 360.798],
                                  [327.629, 247.482],
                                  [210.47, 45.926],
                                  [172.006, -131.775],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.632, 5.264],
                                  [33.074, -57.8],
                                  [11.326, -83.958],
                                  [-51.21, -43.126],
                                  [-14.421, -31.916],
                                  [-177.853, 32.063],
                                  [26.789, 55.189],
                                  [-0.61, 56.505],
                                  [-20.884, 92],
                                ],
                                o: [
                                  [-78.526, 4.758],
                                  [-33.074, 57.8],
                                  [-11.326, 83.958],
                                  [51.21, 43.126],
                                  [23.726, 43.368],
                                  [104.842, -16.316],
                                  [-26.789, -55.189],
                                  [-12.99, -68.673],
                                  [20.884, -92],
                                ],
                                v: [
                                  [-6.704, -288.098],
                                  [-141.652, -223.279],
                                  [-267.32, -47.96],
                                  [-235.266, 146.242],
                                  [-142.346, 249.048],
                                  [107.538, 360.853],
                                  [327.286, 247.372],
                                  [210.345, 45.825],
                                  [172.365, -132.445],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.554, 5.109],
                                  [32.887, -58.011],
                                  [11.458, -83.829],
                                  [-51.445, -43.102],
                                  [-14.431, -32.024],
                                  [-177.675, 31.982],
                                  [27.106, 55.267],
                                  [-0.744, 56.774],
                                  [-20.849, 92],
                                ],
                                o: [
                                  [-78.584, 4.885],
                                  [-32.887, 58.011],
                                  [-11.458, 83.829],
                                  [51.445, 43.102],
                                  [23.711, 43.446],
                                  [104.861, -16.277],
                                  [-27.106, -55.267],
                                  [-12.82, -68.909],
                                  [20.849, -92],
                                ],
                                v: [
                                  [-6.397, -288.669],
                                  [-142.215, -223.263],
                                  [-267.472, -48.006],
                                  [-235.134, 146.061],
                                  [-142.422, 249.673],
                                  [107.945, 360.887],
                                  [326.944, 247.292],
                                  [210.22, 45.748],
                                  [172.679, -133.162],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.477, 4.954],
                                  [32.701, -58.222],
                                  [11.589, -83.699],
                                  [-51.679, -43.077],
                                  [-14.44, -32.132],
                                  [-177.497, 31.901],
                                  [27.422, 55.345],
                                  [-0.877, 57.042],
                                  [-20.815, 92],
                                ],
                                o: [
                                  [-78.642, 5.013],
                                  [-32.701, 58.222],
                                  [-11.589, 83.699],
                                  [51.679, 43.077],
                                  [23.695, 43.523],
                                  [104.881, -16.239],
                                  [-27.422, -55.345],
                                  [-12.649, -69.145],
                                  [20.815, -92],
                                ],
                                v: [
                                  [-6.089, -289.254],
                                  [-142.775, -223.247],
                                  [-267.623, -48.116],
                                  [-234.997, 145.882],
                                  [-142.546, 250.249],
                                  [108.355, 360.909],
                                  [326.604, 247.213],
                                  [210.113, 45.644],
                                  [173.018, -133.819],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.4, 4.799],
                                  [32.514, -58.434],
                                  [11.721, -83.569],
                                  [-51.914, -43.052],
                                  [-14.45, -32.241],
                                  [-177.319, 31.82],
                                  [27.739, 55.423],
                                  [-1.011, 57.311],
                                  [-20.78, 92],
                                ],
                                o: [
                                  [-78.7, 5.141],
                                  [-32.514, 58.434],
                                  [-11.721, 83.569],
                                  [51.914, 43.052],
                                  [23.68, 43.6],
                                  [104.9, -16.2],
                                  [-27.739, -55.423],
                                  [-12.479, -69.381],
                                  [20.78, -92],
                                ],
                                v: [
                                  [-5.797, -289.863],
                                  [-143.439, -223.207],
                                  [-267.774, -48.237],
                                  [-234.89, 145.706],
                                  [-142.608, 250.866],
                                  [108.725, 360.893],
                                  [326.293, 247.18],
                                  [210.006, 45.476],
                                  [173.337, -134.497],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.322, 4.644],
                                  [32.327, -58.645],
                                  [11.852, -83.44],
                                  [-52.148, -43.028],
                                  [-14.46, -32.349],
                                  [-177.141, 31.738],
                                  [28.056, 55.501],
                                  [-1.144, 57.58],
                                  [-20.745, 92],
                                ],
                                o: [
                                  [-78.758, 5.268],
                                  [-32.327, 58.645],
                                  [-11.852, 83.44],
                                  [52.148, 43.028],
                                  [23.664, 43.678],
                                  [104.919, -16.161],
                                  [-28.056, -55.501],
                                  [-12.309, -69.617],
                                  [20.745, -92],
                                ],
                                v: [
                                  [-5.487, -290.443],
                                  [-144.118, -223.213],
                                  [-267.861, -48.333],
                                  [-234.806, 145.513],
                                  [-142.717, 251.497],
                                  [109.098, 360.864],
                                  [326, 247.149],
                                  [209.855, 45.332],
                                  [173.673, -135.159],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.245, 4.49],
                                  [32.141, -58.856],
                                  [11.984, -83.31],
                                  [-52.383, -43.003],
                                  [-14.469, -32.457],
                                  [-176.963, 31.657],
                                  [28.373, 55.579],
                                  [-1.278, 57.849],
                                  [-20.71, 92],
                                ],
                                o: [
                                  [-78.816, 5.396],
                                  [-32.141, 58.856],
                                  [-11.984, 83.31],
                                  [52.383, 43.003],
                                  [23.649, 43.755],
                                  [104.939, -16.122],
                                  [-28.373, -55.579],
                                  [-12.139, -69.853],
                                  [20.71, -92],
                                ],
                                v: [
                                  [-5.237, -291.037],
                                  [-144.793, -223.177],
                                  [-267.991, -48.476],
                                  [-234.744, 145.385],
                                  [-142.809, 252.125],
                                  [109.475, 360.816],
                                  [325.735, 247.163],
                                  [209.749, 45.142],
                                  [173.971, -135.78],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.167, 4.335],
                                  [31.954, -59.068],
                                  [12.115, -83.18],
                                  [-52.617, -42.978],
                                  [-14.479, -32.566],
                                  [-176.785, 31.576],
                                  [28.689, 55.656],
                                  [-1.411, 58.118],
                                  [-20.675, 92],
                                ],
                                o: [
                                  [-78.874, 5.524],
                                  [-31.954, 59.068],
                                  [-12.115, 83.181],
                                  [52.617, 42.978],
                                  [23.633, 43.833],
                                  [104.958, -16.084],
                                  [-28.689, -55.657],
                                  [-11.968, -70.089],
                                  [20.675, -92],
                                ],
                                v: [
                                  [-4.968, -291.611],
                                  [-145.51, -223.161],
                                  [-268.033, -48.558],
                                  [-234.687, 145.215],
                                  [-142.821, 252.749],
                                  [109.813, 360.757],
                                  [325.453, 247.16],
                                  [209.662, 44.95],
                                  [174.329, -136.384],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.09, 4.18],
                                  [31.767, -59.279],
                                  [12.247, -83.051],
                                  [-52.852, -42.954],
                                  [-14.489, -32.674],
                                  [-176.607, 31.495],
                                  [29.006, 55.734],
                                  [-1.545, 58.387],
                                  [-20.641, 92],
                                ],
                                o: [
                                  [-78.932, 5.651],
                                  [-31.767, 59.279],
                                  [-12.247, 83.051],
                                  [52.852, 42.954],
                                  [23.618, 43.91],
                                  [104.977, -16.045],
                                  [-29.006, -55.734],
                                  [-11.798, -70.325],
                                  [20.641, -92],
                                ],
                                v: [
                                  [-4.716, -292.245],
                                  [-146.285, -223.147],
                                  [-268.117, -48.686],
                                  [-234.59, 145.091],
                                  [-142.879, 253.413],
                                  [110.135, 360.684],
                                  [325.189, 247.202],
                                  [209.531, 44.738],
                                  [174.667, -136.992],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.013, 4.025],
                                  [31.581, -59.49],
                                  [12.378, -82.921],
                                  [-53.086, -42.929],
                                  [-14.498, -32.782],
                                  [-176.429, 31.413],
                                  [29.323, 55.812],
                                  [-1.678, 58.656],
                                  [-20.606, 92],
                                ],
                                o: [
                                  [-78.99, 5.779],
                                  [-31.581, 59.49],
                                  [-12.378, 82.921],
                                  [53.086, 42.929],
                                  [23.603, 43.987],
                                  [104.997, -16.006],
                                  [-29.323, -55.812],
                                  [-11.628, -70.561],
                                  [20.606, -92],
                                ],
                                v: [
                                  [-4.507, -292.85],
                                  [-147.057, -223.092],
                                  [-268.183, -48.797],
                                  [-234.56, 144.949],
                                  [-142.894, 254.049],
                                  [110.481, 360.574],
                                  [324.952, 247.244],
                                  [209.444, 44.481],
                                  [175.003, -137.582],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.935, 3.871],
                                  [31.394, -59.702],
                                  [12.51, -82.792],
                                  [-53.321, -42.904],
                                  [-14.508, -32.891],
                                  [-176.251, 31.332],
                                  [29.64, 55.89],
                                  [-1.812, 58.925],
                                  [-20.571, 92],
                                ],
                                o: [
                                  [-79.048, 5.907],
                                  [-31.394, 59.702],
                                  [-12.51, 82.792],
                                  [53.321, 42.904],
                                  [23.587, 44.065],
                                  [105.016, -15.968],
                                  [-29.64, -55.89],
                                  [-11.458, -70.797],
                                  [20.571, -92],
                                ],
                                v: [
                                  [-4.296, -293.478],
                                  [-147.845, -223.083],
                                  [-268.204, -48.972],
                                  [-234.535, 144.829],
                                  [-142.916, 254.706],
                                  [110.83, 360.452],
                                  [324.715, 247.287],
                                  [209.334, 44.203],
                                  [175.361, -138.194],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.858, 3.716],
                                  [31.207, -59.913],
                                  [12.641, -82.662],
                                  [-53.556, -42.88],
                                  [-14.518, -32.999],
                                  [-176.073, 31.251],
                                  [29.957, 55.968],
                                  [-1.945, 59.194],
                                  [-20.536, 92],
                                ],
                                o: [
                                  [-79.107, 6.034],
                                  [-31.207, 59.913],
                                  [-12.642, 82.662],
                                  [53.556, 42.88],
                                  [23.572, 44.142],
                                  [105.036, -15.929],
                                  [-29.957, -55.968],
                                  [-11.287, -71.033],
                                  [20.536, -92],
                                ],
                                v: [
                                  [-4.127, -294.078],
                                  [-148.673, -223.051],
                                  [-268.224, -49.086],
                                  [-234.557, 144.692],
                                  [-142.876, 255.334],
                                  [111.121, 360.292],
                                  [324.522, 247.356],
                                  [209.205, 43.922],
                                  [175.716, -138.727],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.935, 3.871],
                                  [31.394, -59.702],
                                  [12.51, -82.792],
                                  [-53.321, -42.904],
                                  [-14.508, -32.891],
                                  [-176.251, 31.332],
                                  [29.64, 55.89],
                                  [-1.812, 58.925],
                                  [-20.571, 92],
                                ],
                                o: [
                                  [-79.049, 5.907],
                                  [-31.394, 59.702],
                                  [-12.51, 82.792],
                                  [53.321, 42.904],
                                  [23.587, 44.065],
                                  [105.016, -15.968],
                                  [-29.64, -55.89],
                                  [-11.458, -70.797],
                                  [20.571, -92],
                                ],
                                v: [
                                  [-3.257, -294.269],
                                  [-149.461, -223.397],
                                  [-268.322, -49.723],
                                  [-234.438, 144.53],
                                  [-143.41, 255.846],
                                  [111.427, 359.832],
                                  [324.503, 247.244],
                                  [210.091, 44.338],
                                  [175.196, -138.677],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.013, 4.025],
                                  [31.581, -59.49],
                                  [12.378, -82.921],
                                  [-53.086, -42.929],
                                  [-14.498, -32.782],
                                  [-176.429, 31.413],
                                  [29.323, 55.812],
                                  [-1.678, 58.656],
                                  [-20.606, 92],
                                ],
                                o: [
                                  [-78.99, 5.779],
                                  [-31.581, 59.49],
                                  [-12.378, 82.921],
                                  [53.086, 42.929],
                                  [23.603, 43.987],
                                  [104.997, -16.006],
                                  [-29.323, -55.812],
                                  [-11.628, -70.561],
                                  [20.606, -92],
                                ],
                                v: [
                                  [-2.432, -294.438],
                                  [-150.296, -223.716],
                                  [-268.439, -50.359],
                                  [-234.315, 144.305],
                                  [-143.925, 256.381],
                                  [111.773, 359.322],
                                  [324.483, 247.15],
                                  [210.975, 44.738],
                                  [174.66, -138.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.09, 4.18],
                                  [31.767, -59.279],
                                  [12.247, -83.051],
                                  [-52.852, -42.954],
                                  [-14.489, -32.674],
                                  [-176.607, 31.495],
                                  [29.006, 55.734],
                                  [-1.545, 58.387],
                                  [-20.641, 92],
                                ],
                                o: [
                                  [-78.932, 5.651],
                                  [-31.767, 59.279],
                                  [-12.247, 83.051],
                                  [52.852, 42.954],
                                  [23.618, 43.91],
                                  [104.977, -16.045],
                                  [-29.006, -55.734],
                                  [-11.798, -70.325],
                                  [20.641, -92],
                                ],
                                v: [
                                  [-1.653, -294.653],
                                  [-151.134, -224.014],
                                  [-268.477, -51.039],
                                  [-234.186, 144.166],
                                  [-144.359, 256.876],
                                  [112.071, 358.788],
                                  [324.532, 247.038],
                                  [211.878, 45.139],
                                  [174.17, -138.471],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.167, 4.335],
                                  [31.954, -59.068],
                                  [12.115, -83.181],
                                  [-52.617, -42.978],
                                  [-14.479, -32.566],
                                  [-176.785, 31.576],
                                  [28.689, 55.656],
                                  [-1.411, 58.118],
                                  [-20.675, 92],
                                ],
                                o: [
                                  [-78.874, 5.524],
                                  [-31.954, 59.068],
                                  [-12.115, 83.18],
                                  [52.617, 42.978],
                                  [23.633, 43.833],
                                  [104.958, -16.084],
                                  [-28.689, -55.657],
                                  [-11.968, -70.089],
                                  [20.675, -92],
                                ],
                                v: [
                                  [-0.875, -294.827],
                                  [-152.057, -224.328],
                                  [-268.577, -51.654],
                                  [-234.115, 143.982],
                                  [-144.837, 257.417],
                                  [112.346, 358.186],
                                  [324.538, 246.995],
                                  [212.779, 45.48],
                                  [173.684, -138.334],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.245, 4.49],
                                  [32.141, -58.856],
                                  [11.984, -83.31],
                                  [-52.383, -43.003],
                                  [-14.469, -32.457],
                                  [-176.963, 31.657],
                                  [28.373, 55.579],
                                  [-1.278, 57.849],
                                  [-20.71, 92],
                                ],
                                o: [
                                  [-78.816, 5.396],
                                  [-32.141, 58.856],
                                  [-11.984, 83.31],
                                  [52.383, 43.003],
                                  [23.649, 43.755],
                                  [104.939, -16.122],
                                  [-28.373, -55.579],
                                  [-12.139, -69.853],
                                  [20.71, -92],
                                ],
                                v: [
                                  [-0.117, -295.065],
                                  [-152.964, -224.639],
                                  [-268.635, -52.269],
                                  [-233.995, 143.84],
                                  [-145.298, 257.918],
                                  [112.591, 357.622],
                                  [324.588, 246.927],
                                  [213.635, 45.823],
                                  [173.182, -138.15],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.322, 4.644],
                                  [32.327, -58.645],
                                  [11.852, -83.44],
                                  [-52.148, -43.028],
                                  [-14.46, -32.349],
                                  [-177.141, 31.738],
                                  [28.056, 55.501],
                                  [-1.144, 57.58],
                                  [-20.745, 92],
                                ],
                                o: [
                                  [-78.758, 5.268],
                                  [-32.327, 58.645],
                                  [-11.852, 83.44],
                                  [52.148, 43.028],
                                  [23.664, 43.678],
                                  [104.919, -16.161],
                                  [-28.056, -55.501],
                                  [-12.309, -69.617],
                                  [20.745, -92],
                                ],
                                v: [
                                  [0.595, -295.261],
                                  [-153.849, -224.929],
                                  [-268.675, -52.91],
                                  [-233.932, 143.679],
                                  [-145.696, 258.466],
                                  [112.813, 356.989],
                                  [324.683, 246.904],
                                  [214.515, 46.132],
                                  [172.746, -137.989],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.4, 4.799],
                                  [32.514, -58.434],
                                  [11.721, -83.569],
                                  [-51.914, -43.052],
                                  [-14.45, -32.241],
                                  [-177.319, 31.82],
                                  [27.739, 55.423],
                                  [-1.011, 57.311],
                                  [-20.78, 92],
                                ],
                                o: [
                                  [-78.7, 5.141],
                                  [-32.514, 58.434],
                                  [-11.721, 83.569],
                                  [51.914, 43.052],
                                  [23.68, 43.6],
                                  [104.9, -16.2],
                                  [-27.739, -55.423],
                                  [-12.479, -69.381],
                                  [20.78, -92],
                                ],
                                v: [
                                  [1.332, -295.521],
                                  [-154.826, -225.217],
                                  [-268.716, -53.55],
                                  [-233.802, 143.516],
                                  [-146.122, 258.991],
                                  [113.012, 356.378],
                                  [324.778, 246.907],
                                  [215.35, 46.417],
                                  [172.278, -137.783],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.477, 4.954],
                                  [32.701, -58.222],
                                  [11.589, -83.699],
                                  [-51.679, -43.077],
                                  [-14.44, -32.132],
                                  [-177.497, 31.901],
                                  [27.422, 55.345],
                                  [-0.877, 57.042],
                                  [-20.815, 92],
                                ],
                                o: [
                                  [-78.642, 5.013],
                                  [-32.701, 58.222],
                                  [-11.589, 83.699],
                                  [51.679, 43.077],
                                  [23.695, 43.523],
                                  [104.881, -16.238],
                                  [-27.422, -55.345],
                                  [-12.649, -69.145],
                                  [20.815, -92],
                                ],
                                v: [
                                  [1.979, -295.783],
                                  [-155.779, -225.502],
                                  [-268.74, -54.172],
                                  [-233.73, 143.398],
                                  [-146.486, 259.475],
                                  [113.226, 355.717],
                                  [324.919, 246.912],
                                  [216.184, 46.669],
                                  [171.831, -137.512],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.554, 5.109],
                                  [32.887, -58.011],
                                  [11.458, -83.829],
                                  [-51.445, -43.102],
                                  [-14.431, -32.024],
                                  [-177.675, 31.982],
                                  [27.106, 55.267],
                                  [-0.744, 56.774],
                                  [-20.849, 92],
                                ],
                                o: [
                                  [-78.584, 4.885],
                                  [-32.887, 58.011],
                                  [-11.458, 83.829],
                                  [51.445, 43.102],
                                  [23.711, 43.446],
                                  [104.861, -16.277],
                                  [-27.106, -55.267],
                                  [-12.82, -68.909],
                                  [20.85, -92],
                                ],
                                v: [
                                  [2.65, -296.048],
                                  [-156.798, -225.74],
                                  [-268.738, -54.794],
                                  [-233.652, 143.279],
                                  [-146.896, 260.006],
                                  [113.355, 355.032],
                                  [325.061, 246.98],
                                  [216.954, 46.878],
                                  [171.415, -137.238],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.632, 5.264],
                                  [33.074, -57.8],
                                  [11.326, -83.958],
                                  [-51.21, -43.126],
                                  [-14.421, -31.916],
                                  [-177.853, 32.063],
                                  [26.789, 55.189],
                                  [-0.61, 56.505],
                                  [-20.884, 92],
                                ],
                                o: [
                                  [-78.526, 4.758],
                                  [-33.074, 57.8],
                                  [-11.326, 83.958],
                                  [51.21, 43.126],
                                  [23.726, 43.368],
                                  [104.842, -16.316],
                                  [-26.789, -55.189],
                                  [-12.99, -68.673],
                                  [20.884, -92],
                                ],
                                v: [
                                  [3.276, -296.332],
                                  [-157.802, -226.003],
                                  [-268.765, -55.425],
                                  [-233.569, 143.185],
                                  [-147.272, 260.512],
                                  [113.497, 354.361],
                                  [325.248, 247.075],
                                  [217.75, 47.09],
                                  [171.002, -136.945],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.709, 5.418],
                                  [33.261, -57.588],
                                  [11.194, -84.088],
                                  [-50.975, -43.151],
                                  [-14.411, -31.807],
                                  [-178.031, 32.145],
                                  [26.472, 55.111],
                                  [-0.477, 56.236],
                                  [-20.919, 92],
                                ],
                                o: [
                                  [-78.468, 4.63],
                                  [-33.261, 57.588],
                                  [-11.194, 84.088],
                                  [50.975, 43.151],
                                  [23.742, 43.291],
                                  [104.823, -16.354],
                                  [-26.472, -55.111],
                                  [-13.16, -68.437],
                                  [20.919, -92],
                                ],
                                v: [
                                  [3.855, -296.617],
                                  [-158.782, -226.263],
                                  [-268.747, -56.03],
                                  [-233.543, 143.092],
                                  [-147.588, 261.022],
                                  [113.573, 353.666],
                                  [325.464, 247.128],
                                  [218.518, 47.225],
                                  [170.594, -136.649],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.787, 5.573],
                                  [33.447, -57.377],
                                  [11.063, -84.217],
                                  [-50.741, -43.176],
                                  [-14.402, -31.699],
                                  [-178.209, 32.226],
                                  [26.155, 55.034],
                                  [-0.343, 55.967],
                                  [-20.954, 92],
                                ],
                                o: [
                                  [-78.41, 4.502],
                                  [-33.447, 57.377],
                                  [-11.063, 84.217],
                                  [50.741, 43.176],
                                  [23.757, 43.213],
                                  [104.803, -16.393],
                                  [-26.155, -55.034],
                                  [-13.33, -68.201],
                                  [20.954, -92],
                                ],
                                v: [
                                  [4.46, -296.922],
                                  [-159.827, -226.522],
                                  [-268.776, -56.599],
                                  [-233.45, 142.981],
                                  [-147.933, 261.533],
                                  [113.689, 352.949],
                                  [325.699, 247.271],
                                  [219.249, 47.424],
                                  [170.235, -136.29],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.864, 5.728],
                                  [33.634, -57.165],
                                  [10.931, -84.347],
                                  [-50.506, -43.2],
                                  [-14.392, -31.591],
                                  [-178.387, 32.307],
                                  [25.838, 54.956],
                                  [-0.21, 55.698],
                                  [-20.989, 92],
                                ],
                                o: [
                                  [-78.352, 4.375],
                                  [-33.634, 57.165],
                                  [-10.931, 84.347],
                                  [50.506, 43.2],
                                  [23.773, 43.136],
                                  [104.784, -16.432],
                                  [-25.838, -54.956],
                                  [-13.501, -67.965],
                                  [20.989, -92],
                                ],
                                v: [
                                  [5.001, -297.229],
                                  [-160.857, -226.715],
                                  [-268.743, -57.215],
                                  [-233.415, 142.898],
                                  [-148.235, 262.002],
                                  [113.756, 352.244],
                                  [325.981, 247.399],
                                  [219.953, 47.501],
                                  [169.863, -135.865],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.941, 5.883],
                                  [33.821, -56.954],
                                  [10.8, -84.477],
                                  [-50.272, -43.225],
                                  [-14.382, -31.482],
                                  [-178.565, 32.388],
                                  [25.522, 54.878],
                                  [-0.076, 55.429],
                                  [-21.024, 92],
                                ],
                                o: [
                                  [-78.294, 4.247],
                                  [-33.821, 56.954],
                                  [-10.8, 84.477],
                                  [50.272, 43.225],
                                  [23.788, 43.059],
                                  [104.765, -16.471],
                                  [-25.522, -54.878],
                                  [-13.671, -67.729],
                                  [21.024, -92],
                                ],
                                v: [
                                  [5.542, -297.554],
                                  [-161.89, -226.969],
                                  [-268.728, -57.832],
                                  [-233.329, 142.815],
                                  [-148.55, 262.428],
                                  [113.757, 351.517],
                                  [326.247, 247.546],
                                  [220.683, 47.581],
                                  [169.541, -135.457],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.019, 6.037],
                                  [34.008, -56.743],
                                  [10.668, -84.606],
                                  [-50.037, -43.25],
                                  [-14.373, -31.374],
                                  [-178.743, 32.47],
                                  [25.205, 54.8],
                                  [0.057, 55.16],
                                  [-21.058, 92],
                                ],
                                o: [
                                  [-78.236, 4.119],
                                  [-34.008, 56.743],
                                  [-10.668, 84.606],
                                  [50.037, 43.25],
                                  [23.804, 42.981],
                                  [104.745, -16.509],
                                  [-25.205, -54.8],
                                  [-13.841, -67.493],
                                  [21.058, -92],
                                ],
                                v: [
                                  [6.018, -297.88],
                                  [-162.927, -227.238],
                                  [-268.743, -58.369],
                                  [-233.283, 142.778],
                                  [-148.805, 262.873],
                                  [113.798, 350.831],
                                  [326.623, 247.74],
                                  [221.349, 47.663],
                                  [169.223, -135.028],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.096, 6.192],
                                  [34.194, -56.531],
                                  [10.537, -84.736],
                                  [-49.803, -43.274],
                                  [-14.363, -31.266],
                                  [-178.921, 32.551],
                                  [24.888, 54.722],
                                  [0.191, 54.891],
                                  [-21.093, 92],
                                ],
                                o: [
                                  [-78.178, 3.992],
                                  [-34.194, 56.531],
                                  [-10.537, 84.736],
                                  [49.803, 43.274],
                                  [23.819, 42.904],
                                  [104.726, -16.548],
                                  [-24.888, -54.722],
                                  [-14.011, -67.257],
                                  [21.093, -92],
                                ],
                                v: [
                                  [6.476, -298.225],
                                  [-163.966, -227.488],
                                  [-268.714, -58.954],
                                  [-233.215, 142.724],
                                  [-149.109, 263.32],
                                  [113.789, 350.094],
                                  [327.001, 247.919],
                                  [222.015, 47.686],
                                  [168.928, -134.57],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.173, 6.347],
                                  [34.381, -56.32],
                                  [10.405, -84.865],
                                  [-49.568, -43.299],
                                  [-14.353, -31.157],
                                  [-179.099, 32.632],
                                  [24.571, 54.644],
                                  [0.324, 54.622],
                                  [-21.128, 92],
                                ],
                                o: [
                                  [-78.12, 3.864],
                                  [-34.381, 56.32],
                                  [-10.405, 84.865],
                                  [49.568, 43.299],
                                  [23.835, 42.827],
                                  [104.707, -16.587],
                                  [-24.571, -54.644],
                                  [-14.181, -67.021],
                                  [21.128, -92],
                                ],
                                v: [
                                  [6.933, -298.57],
                                  [-164.991, -227.752],
                                  [-268.686, -59.523],
                                  [-233.159, 142.655],
                                  [-149.337, 263.723],
                                  [113.714, 349.382],
                                  [327.381, 248.163],
                                  [222.618, 47.694],
                                  [168.665, -134.092],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.251, 6.502],
                                  [34.568, -56.109],
                                  [10.274, -84.995],
                                  [-49.334, -43.324],
                                  [-14.344, -31.049],
                                  [-179.277, 32.713],
                                  [24.255, 54.566],
                                  [0.458, 54.353],
                                  [-21.163, 92],
                                ],
                                o: [
                                  [-78.062, 3.736],
                                  [-34.568, 56.109],
                                  [-10.274, 84.995],
                                  [49.334, 43.324],
                                  [23.85, 42.749],
                                  [104.687, -16.625],
                                  [-24.255, -54.566],
                                  [-14.352, -66.785],
                                  [21.163, -92],
                                ],
                                v: [
                                  [7.373, -298.917],
                                  [-166.019, -228.014],
                                  [-268.642, -60.048],
                                  [-233.144, 142.632],
                                  [-149.613, 264.081],
                                  [113.68, 348.681],
                                  [327.809, 248.409],
                                  [223.266, 47.705],
                                  [168.408, -133.629],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.328, 6.656],
                                  [34.754, -55.897],
                                  [10.142, -85.125],
                                  [-49.099, -43.348],
                                  [-14.334, -30.941],
                                  [-179.455, 32.795],
                                  [23.938, 54.489],
                                  [0.591, 54.085],
                                  [-21.198, 92],
                                ],
                                o: [
                                  [-78.004, 3.608],
                                  [-34.754, 55.897],
                                  [-10.142, 85.125],
                                  [49.099, 43.348],
                                  [23.866, 42.672],
                                  [104.668, -16.664],
                                  [-23.938, -54.489],
                                  [-14.522, -66.549],
                                  [21.198, -92],
                                ],
                                v: [
                                  [7.795, -299.327],
                                  [-167.05, -228.273],
                                  [-268.617, -60.604],
                                  [-233.061, 142.657],
                                  [-149.815, 264.458],
                                  [113.58, 347.943],
                                  [328.285, 248.64],
                                  [223.867, 47.673],
                                  [168.202, -133.101],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.406, 6.811],
                                  [34.941, -55.686],
                                  [10.011, -85.254],
                                  [-48.864, -43.373],
                                  [-14.324, -30.832],
                                  [-179.633, 32.876],
                                  [23.621, 54.411],
                                  [0.725, 53.816],
                                  [-21.232, 92],
                                ],
                                o: [
                                  [-77.946, 3.481],
                                  [-34.941, 55.686],
                                  [-10.011, 85.254],
                                  [48.864, 43.373],
                                  [23.881, 42.594],
                                  [104.649, -16.703],
                                  [-23.621, -54.411],
                                  [-14.692, -66.313],
                                  [21.232, -92],
                                ],
                                v: [
                                  [8.154, -299.708],
                                  [-168.039, -228.53],
                                  [-268.576, -61.099],
                                  [-233.036, 142.667],
                                  [-150.02, 264.76],
                                  [113.475, 347.217],
                                  [328.718, 248.936],
                                  [224.389, 47.582],
                                  [167.971, -132.587],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.483, 6.966],
                                  [35.128, -55.475],
                                  [9.879, -85.384],
                                  [-48.63, -43.398],
                                  [-14.315, -30.724],
                                  [-179.811, 32.957],
                                  [23.304, 54.333],
                                  [0.858, 53.547],
                                  [-21.267, 92],
                                ],
                                o: [
                                  [-77.888, 3.353],
                                  [-35.128, 55.475],
                                  [-9.879, 85.384],
                                  [48.63, 43.398],
                                  [23.897, 42.517],
                                  [104.629, -16.742],
                                  [-23.304, -54.333],
                                  [-14.862, -66.077],
                                  [21.267, -92],
                                ],
                                v: [
                                  [8.513, -300.09],
                                  [-169.076, -228.785],
                                  [-268.583, -61.626],
                                  [-232.99, 142.725],
                                  [-150.274, 265.046],
                                  [113.367, 346.517],
                                  [329.216, 249.172],
                                  [224.973, 47.493],
                                  [167.775, -132.053],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.56, 7.121],
                                  [35.314, -55.263],
                                  [9.747, -85.514],
                                  [-48.395, -43.422],
                                  [-14.305, -30.616],
                                  [-179.989, 33.038],
                                  [22.987, 54.255],
                                  [0.992, 53.278],
                                  [-21.302, 92],
                                ],
                                o: [
                                  [-77.83, 3.225],
                                  [-35.314, 55.263],
                                  [-9.747, 85.514],
                                  [48.395, 43.422],
                                  [23.912, 42.44],
                                  [104.61, -16.78],
                                  [-22.987, -54.255],
                                  [-15.033, -65.841],
                                  [21.302, -92],
                                ],
                                v: [
                                  [8.854, -300.535],
                                  [-170.054, -229.053],
                                  [-268.545, -62.109],
                                  [-232.939, 142.785],
                                  [-150.517, 265.365],
                                  [113.208, 345.813],
                                  [329.654, 249.474],
                                  [225.494, 47.39],
                                  [167.631, -131.518],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.638, 7.275],
                                  [35.501, -55.052],
                                  [9.616, -85.643],
                                  [-48.161, -43.447],
                                  [-14.295, -30.507],
                                  [-180.167, 33.12],
                                  [22.671, 54.177],
                                  [1.125, 53.009],
                                  [-21.337, 92],
                                ],
                                o: [
                                  [-77.772, 3.098],
                                  [-35.501, 55.052],
                                  [-9.616, 85.643],
                                  [48.161, 43.447],
                                  [23.928, 42.362],
                                  [104.591, -16.819],
                                  [-22.671, -54.177],
                                  [-15.203, -65.605],
                                  [21.337, -92],
                                ],
                                v: [
                                  [9.18, -300.934],
                                  [-170.989, -229.349],
                                  [-268.525, -62.594],
                                  [-232.884, 142.831],
                                  [-150.748, 265.591],
                                  [113.028, 345.104],
                                  [330.157, 249.761],
                                  [225.998, 47.244],
                                  [167.446, -130.996],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.715, 7.43],
                                  [35.688, -54.841],
                                  [9.484, -85.773],
                                  [-47.926, -43.472],
                                  [-14.286, -30.399],
                                  [-180.345, 33.201],
                                  [22.354, 54.099],
                                  [1.259, 52.74],
                                  [-21.372, 92],
                                ],
                                o: [
                                  [-77.714, 2.97],
                                  [-35.688, 54.841],
                                  [-9.484, 85.773],
                                  [47.926, 43.472],
                                  [23.943, 42.285],
                                  [104.571, -16.858],
                                  [-22.354, -54.099],
                                  [-15.373, -65.369],
                                  [21.372, -92],
                                ],
                                v: [
                                  [9.505, -301.396],
                                  [-171.973, -229.612],
                                  [-268.444, -63.128],
                                  [-232.87, 142.926],
                                  [-150.983, 265.817],
                                  [112.828, 344.423],
                                  [330.678, 250.067],
                                  [226.455, 47.101],
                                  [167.312, -130.409],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.792, 7.585],
                                  [35.874, -54.629],
                                  [9.353, -85.902],
                                  [-47.692, -43.496],
                                  [-14.276, -30.291],
                                  [-180.523, 33.282],
                                  [22.037, 54.021],
                                  [1.392, 52.471],
                                  [-21.407, 92],
                                ],
                                o: [
                                  [-77.656, 2.842],
                                  [-35.874, 54.629],
                                  [-9.353, 85.902],
                                  [47.692, 43.496],
                                  [23.958, 42.208],
                                  [104.552, -16.896],
                                  [-22.037, -54.021],
                                  [-15.543, -65.133],
                                  [21.407, -92],
                                ],
                                v: [
                                  [9.784, -301.812],
                                  [-172.898, -229.904],
                                  [-268.379, -63.601],
                                  [-232.806, 143.055],
                                  [-151.207, 266.012],
                                  [112.641, 343.738],
                                  [331.185, 250.359],
                                  [226.896, 46.897],
                                  [167.2, -129.82],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.87, 7.74],
                                  [36.061, -54.418],
                                  [9.221, -86.032],
                                  [-47.457, -43.521],
                                  [-14.266, -30.182],
                                  [-180.701, 33.363],
                                  [21.72, 53.943],
                                  [1.525, 52.202],
                                  [-21.441, 92],
                                ],
                                o: [
                                  [-77.598, 2.715],
                                  [-36.061, 54.418],
                                  [-9.221, 86.032],
                                  [47.457, 43.521],
                                  [23.974, 42.13],
                                  [104.533, -16.935],
                                  [-21.72, -53.943],
                                  [-15.714, -64.897],
                                  [21.441, -92],
                                ],
                                v: [
                                  [10.016, -302.196],
                                  [-173.826, -230.208],
                                  [-268.3, -64.124],
                                  [-232.767, 143.186],
                                  [-151.483, 266.159],
                                  [112.386, 343.096],
                                  [331.695, 250.655],
                                  [227.337, 46.744],
                                  [167.078, -129.227],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.947, 7.894],
                                  [36.248, -54.207],
                                  [9.09, -86.162],
                                  [-47.222, -43.546],
                                  [-14.257, -30.074],
                                  [-180.879, 33.445],
                                  [21.404, 53.866],
                                  [1.659, 51.933],
                                  [-21.476, 92],
                                ],
                                o: [
                                  [-77.54, 2.587],
                                  [-36.248, 54.207],
                                  [-9.09, 86.162],
                                  [47.222, 43.546],
                                  [23.989, 42.053],
                                  [104.513, -16.974],
                                  [-21.404, -53.866],
                                  [-15.884, -64.661],
                                  [21.476, -92],
                                ],
                                v: [
                                  [10.2, -302.534],
                                  [-174.757, -230.495],
                                  [-268.238, -64.603],
                                  [-232.725, 143.367],
                                  [-151.717, 266.305],
                                  [112.112, 342.435],
                                  [332.223, 250.968],
                                  [227.761, 46.546],
                                  [166.961, -128.648],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.025, 8.049],
                                  [36.434, -53.995],
                                  [8.958, -86.291],
                                  [-46.988, -43.57],
                                  [-14.247, -29.966],
                                  [-181.057, 33.526],
                                  [21.087, 53.788],
                                  [1.792, 51.664],
                                  [-21.511, 92],
                                ],
                                o: [
                                  [-77.482, 2.459],
                                  [-36.434, 53.995],
                                  [-8.958, 86.291],
                                  [46.988, 43.57],
                                  [24.005, 41.975],
                                  [104.494, -17.012],
                                  [-21.087, -53.788],
                                  [-16.054, -64.425],
                                  [21.511, -92],
                                ],
                                v: [
                                  [10.337, -302.839],
                                  [-175.645, -230.796],
                                  [-268.13, -65.131],
                                  [-232.663, 143.552],
                                  [-151.987, 266.419],
                                  [111.85, 341.772],
                                  [332.753, 251.268],
                                  [228.171, 46.335],
                                  [166.896, -128.05],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.102, 8.204],
                                  [36.621, -53.784],
                                  [8.827, -86.421],
                                  [-46.753, -43.595],
                                  [-14.237, -29.857],
                                  [-181.235, 33.607],
                                  [20.77, 53.71],
                                  [1.926, 51.396],
                                  [-21.546, 92],
                                ],
                                o: [
                                  [-77.423, 2.332],
                                  [-36.621, 53.784],
                                  [-8.827, 86.421],
                                  [46.753, 43.595],
                                  [24.02, 41.898],
                                  [104.475, -17.051],
                                  [-20.77, -53.71],
                                  [-16.224, -64.189],
                                  [21.546, -92],
                                ],
                                v: [
                                  [10.458, -303.159],
                                  [-176.488, -231.125],
                                  [-268.038, -65.646],
                                  [-232.644, 143.772],
                                  [-152.262, 266.483],
                                  [111.615, 341.152],
                                  [333.348, 251.571],
                                  [228.548, 46.174],
                                  [166.838, -127.45],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.179, 8.359],
                                  [36.808, -53.573],
                                  [8.695, -86.55],
                                  [-46.519, -43.62],
                                  [-14.228, -29.749],
                                  [-181.413, 33.688],
                                  [20.453, 53.632],
                                  [2.059, 51.127],
                                  [-21.581, 92],
                                ],
                                o: [
                                  [-77.365, 2.204],
                                  [-36.808, 53.573],
                                  [-8.695, 86.55],
                                  [46.519, 43.62],
                                  [24.036, 41.821],
                                  [104.455, -17.09],
                                  [-20.453, -53.632],
                                  [-16.395, -63.953],
                                  [21.581, -92],
                                ],
                                v: [
                                  [10.484, -303.383],
                                  [-177.366, -231.421],
                                  [-267.884, -66.117],
                                  [-232.575, 143.995],
                                  [-152.528, 266.546],
                                  [111.314, 340.483],
                                  [333.883, 251.924],
                                  [228.943, 46.017],
                                  [166.753, -126.832],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.257, 8.514],
                                  [36.994, -53.361],
                                  [8.564, -86.68],
                                  [-46.284, -43.644],
                                  [-14.218, -29.641],
                                  [-181.591, 33.77],
                                  [20.136, 53.554],
                                  [2.193, 50.858],
                                  [-21.616, 92],
                                ],
                                o: [
                                  [-77.307, 2.076],
                                  [-36.994, 53.361],
                                  [-8.564, 86.68],
                                  [46.284, 43.644],
                                  [24.051, 41.743],
                                  [104.436, -17.128],
                                  [-20.136, -53.554],
                                  [-16.565, -63.717],
                                  [21.616, -92],
                                ],
                                v: [
                                  [10.508, -303.605],
                                  [-178.152, -231.746],
                                  [-267.793, -66.637],
                                  [-232.533, 144.27],
                                  [-152.799, 266.575],
                                  [111.072, 339.843],
                                  [334.435, 252.248],
                                  [229.29, 45.91],
                                  [166.706, -126.226],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.334, 8.668],
                                  [37.181, -53.15],
                                  [8.432, -86.81],
                                  [-46.05, -43.669],
                                  [-14.208, -29.532],
                                  [-181.768, 33.851],
                                  [19.82, 53.476],
                                  [2.326, 50.589],
                                  [-21.65, 92],
                                ],
                                o: [
                                  [-77.249, 1.949],
                                  [-37.181, 53.15],
                                  [-8.432, 86.81],
                                  [46.05, 43.669],
                                  [24.067, 41.666],
                                  [104.416, -17.167],
                                  [-19.82, -53.476],
                                  [-16.735, -63.481],
                                  [21.65, -92],
                                ],
                                v: [
                                  [10.484, -303.84],
                                  [-178.974, -232.069],
                                  [-267.655, -67.114],
                                  [-232.488, 144.534],
                                  [-153.075, 266.539],
                                  [110.811, 339.2],
                                  [334.989, 252.559],
                                  [229.622, 45.743],
                                  [166.664, -125.618],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.411, 8.823],
                                  [37.368, -52.939],
                                  [8.3, -86.939],
                                  [-45.815, -43.694],
                                  [-14.199, -29.424],
                                  [-181.946, 33.932],
                                  [19.503, 53.398],
                                  [2.46, 50.32],
                                  [-21.685, 92],
                                ],
                                o: [
                                  [-77.191, 1.821],
                                  [-37.368, 52.939],
                                  [-8.3, 86.939],
                                  [45.815, 43.694],
                                  [24.082, 41.589],
                                  [104.397, -17.206],
                                  [-19.503, -53.398],
                                  [-16.905, -63.245],
                                  [21.685, -92],
                                ],
                                v: [
                                  [10.395, -303.993],
                                  [-179.766, -232.391],
                                  [-267.47, -67.625],
                                  [-232.472, 144.817],
                                  [-153.357, 266.515],
                                  [110.594, 338.508],
                                  [335.559, 252.873],
                                  [230.003, 45.628],
                                  [166.644, -125.007],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.489, 8.978],
                                  [37.554, -52.727],
                                  [8.169, -87.069],
                                  [-45.581, -43.718],
                                  [-14.189, -29.316],
                                  [-182.124, 34.013],
                                  [19.186, 53.321],
                                  [2.593, 50.051],
                                  [-21.72, 92],
                                ],
                                o: [
                                  [-77.133, 1.693],
                                  [-37.554, 52.727],
                                  [-8.169, 87.069],
                                  [45.581, 43.718],
                                  [24.098, 41.511],
                                  [104.378, -17.244],
                                  [-19.186, -53.321],
                                  [-17.076, -63.009],
                                  [21.72, -92],
                                ],
                                v: [
                                  [10.258, -304.143],
                                  [-180.498, -232.758],
                                  [-267.347, -68.092],
                                  [-232.454, 145.136],
                                  [-153.678, 266.442],
                                  [110.36, 337.908],
                                  [336.069, 253.222],
                                  [230.336, 45.516],
                                  [166.614, -124.379],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.566, 9.133],
                                  [37.741, -52.516],
                                  [8.037, -87.199],
                                  [-45.346, -43.743],
                                  [-14.179, -29.207],
                                  [-182.302, 34.095],
                                  [18.869, 53.243],
                                  [2.727, 49.782],
                                  [-21.755, 92],
                                ],
                                o: [
                                  [-77.075, 1.566],
                                  [-37.741, 52.516],
                                  [-8.037, 87.199],
                                  [45.346, 43.743],
                                  [24.113, 41.434],
                                  [104.358, -17.283],
                                  [-18.869, -53.243],
                                  [-17.246, -62.773],
                                  [21.755, -92],
                                ],
                                v: [
                                  [10.071, -304.242],
                                  [-181.218, -233.075],
                                  [-267.176, -68.562],
                                  [-232.418, 145.461],
                                  [-153.956, 266.365],
                                  [110.122, 337.243],
                                  [336.644, 253.541],
                                  [230.641, 45.408],
                                  [166.604, -123.763],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.644, 9.287],
                                  [37.928, -52.305],
                                  [7.906, -87.328],
                                  [-45.111, -43.768],
                                  [-14.17, -29.099],
                                  [-182.48, 34.176],
                                  [18.553, 53.165],
                                  [2.86, 49.513],
                                  [-21.79, 92],
                                ],
                                o: [
                                  [-77.017, 1.438],
                                  [-37.928, 52.305],
                                  [-7.906, 87.328],
                                  [45.111, 43.768],
                                  [24.129, 41.356],
                                  [104.339, -17.322],
                                  [-18.552, -53.165],
                                  [-17.416, -62.537],
                                  [21.79, -92],
                                ],
                                v: [
                                  [9.868, -304.291],
                                  [-182.003, -233.424],
                                  [-267.006, -69.035],
                                  [-232.427, 145.823],
                                  [-154.303, 266.237],
                                  [109.929, 336.625],
                                  [337.234, 253.863],
                                  [230.961, 45.304],
                                  [166.553, -123.129],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.721, 9.442],
                                  [38.115, -52.093],
                                  [7.774, -87.458],
                                  [-44.877, -43.792],
                                  [-14.16, -28.991],
                                  [-182.658, 34.257],
                                  [18.236, 53.087],
                                  [2.994, 49.244],
                                  [-21.824, 92],
                                ],
                                o: [
                                  [-76.959, 1.31],
                                  [-38.115, 52.093],
                                  [-7.774, 87.458],
                                  [44.877, 43.792],
                                  [24.144, 41.279],
                                  [104.32, -17.36],
                                  [-18.236, -53.087],
                                  [-17.586, -62.301],
                                  [21.824, -92],
                                ],
                                v: [
                                  [9.615, -304.302],
                                  [-182.729, -233.786],
                                  [-266.849, -69.496],
                                  [-232.419, 146.157],
                                  [-154.593, 266.073],
                                  [109.719, 336.004],
                                  [337.764, 254.188],
                                  [231.253, 45.188],
                                  [166.54, -122.493],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.798, 9.597],
                                  [38.301, -51.882],
                                  [7.643, -87.587],
                                  [-44.642, -43.817],
                                  [-14.15, -28.882],
                                  [-182.836, 34.338],
                                  [17.919, 53.009],
                                  [3.127, 48.975],
                                  [-21.859, 92],
                                ],
                                o: [
                                  [-76.901, 1.183],
                                  [-38.301, 51.882],
                                  [-7.643, 87.587],
                                  [44.642, 43.817],
                                  [24.16, 41.202],
                                  [104.3, -17.399],
                                  [-17.919, -53.009],
                                  [-17.756, -62.065],
                                  [21.859, -92],
                                ],
                                v: [
                                  [9.36, -304.31],
                                  [-183.395, -234.18],
                                  [-266.692, -69.975],
                                  [-232.408, 146.592],
                                  [-154.938, 265.891],
                                  [109.522, 335.367],
                                  [338.309, 254.5],
                                  [231.512, 45.139],
                                  [166.547, -121.855],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.876, 9.752],
                                  [38.488, -51.67],
                                  [7.511, -87.717],
                                  [-44.408, -43.842],
                                  [-14.141, -28.774],
                                  [-183.014, 34.42],
                                  [17.602, 52.931],
                                  [3.261, 48.707],
                                  [-21.894, 92],
                                ],
                                o: [
                                  [-76.843, 1.055],
                                  [-38.488, 51.67],
                                  [-7.511, 87.717],
                                  [44.408, 43.842],
                                  [24.175, 41.124],
                                  [104.281, -17.438],
                                  [-17.602, -52.931],
                                  [-17.927, -61.829],
                                  [21.894, -92],
                                ],
                                v: [
                                  [9.04, -304.328],
                                  [-184.127, -234.525],
                                  [-266.487, -70.394],
                                  [-232.43, 146.969],
                                  [-155.274, 265.72],
                                  [109.355, 334.776],
                                  [338.855, 254.782],
                                  [231.792, 45.08],
                                  [166.56, -121.263],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.953, 9.906],
                                  [38.675, -51.459],
                                  [7.38, -87.847],
                                  [-44.173, -43.866],
                                  [-14.131, -28.666],
                                  [-183.192, 34.501],
                                  [17.285, 52.853],
                                  [3.394, 48.438],
                                  [-21.929, 92],
                                ],
                                o: [
                                  [-76.785, 0.927],
                                  [-38.675, 51.459],
                                  [-7.38, 87.847],
                                  [44.173, 43.866],
                                  [24.191, 41.047],
                                  [104.262, -17.477],
                                  [-17.285, -52.853],
                                  [-18.097, -61.593],
                                  [21.929, -92],
                                ],
                                v: [
                                  [8.671, -304.231],
                                  [-184.799, -234.902],
                                  [-266.295, -70.865],
                                  [-232.436, 147.4],
                                  [-155.63, 265.482],
                                  [109.186, 334.184],
                                  [339.417, 255.1],
                                  [232.073, 45.039],
                                  [166.544, -120.668],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.031, 10.061],
                                  [38.861, -51.248],
                                  [7.248, -87.976],
                                  [-43.939, -43.891],
                                  [-14.121, -28.557],
                                  [-183.37, 34.582],
                                  [16.969, 52.775],
                                  [3.528, 48.169],
                                  [-21.964, 92],
                                ],
                                o: [
                                  [-76.727, 0.8],
                                  [-38.861, 51.248],
                                  [-7.248, 87.976],
                                  [43.939, 43.891],
                                  [24.206, 40.969],
                                  [104.242, -17.515],
                                  [-16.969, -52.776],
                                  [-18.267, -61.357],
                                  [21.964, -92],
                                ],
                                v: [
                                  [8.285, -304.143],
                                  [-185.474, -235.327],
                                  [-266.101, -71.276],
                                  [-232.44, 147.836],
                                  [-155.992, 265.207],
                                  [109.001, 333.639],
                                  [339.931, 255.373],
                                  [232.307, 44.987],
                                  [166.568, -120.023],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.108, 10.216],
                                  [39.048, -51.036],
                                  [7.116, -88.106],
                                  [-43.704, -43.916],
                                  [-14.112, -28.449],
                                  [-183.548, 34.663],
                                  [16.652, 52.698],
                                  [3.661, 47.9],
                                  [-21.999, 92],
                                ],
                                o: [
                                  [-76.669, 0.672],
                                  [-39.048, 51.036],
                                  [-7.116, 88.106],
                                  [43.704, 43.916],
                                  [24.222, 40.892],
                                  [104.223, -17.554],
                                  [-16.652, -52.698],
                                  [-18.437, -61.121],
                                  [21.999, -92],
                                ],
                                v: [
                                  [7.848, -304.003],
                                  [-186.089, -235.703],
                                  [-265.907, -71.704],
                                  [-232.429, 148.278],
                                  [-156.375, 264.913],
                                  [108.863, 333.093],
                                  [340.447, 255.697],
                                  [232.577, 44.94],
                                  [166.562, -119.473],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.185, 10.371],
                                  [39.235, -50.825],
                                  [6.985, -88.235],
                                  [-43.469, -43.94],
                                  [-14.102, -28.341],
                                  [-183.726, 34.745],
                                  [16.335, 52.62],
                                  [3.795, 47.631],
                                  [-22.033, 92],
                                ],
                                o: [
                                  [-76.611, 0.544],
                                  [-39.235, 50.825],
                                  [-6.985, 88.235],
                                  [43.469, 43.94],
                                  [24.237, 40.815],
                                  [104.204, -17.593],
                                  [-16.335, -52.62],
                                  [-18.608, -60.885],
                                  [22.033, -92],
                                ],
                                v: [
                                  [7.347, -303.857],
                                  [-186.707, -236.126],
                                  [-265.726, -72.086],
                                  [-232.452, 148.787],
                                  [-156.749, 264.629],
                                  [108.723, 332.532],
                                  [340.962, 255.976],
                                  [232.786, 44.946],
                                  [166.548, -118.858],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.263, 10.525],
                                  [39.421, -50.614],
                                  [6.853, -88.365],
                                  [-43.235, -43.965],
                                  [-14.092, -28.232],
                                  [-183.904, 34.826],
                                  [16.018, 52.542],
                                  [3.928, 47.362],
                                  [-22.068, 92],
                                ],
                                o: [
                                  [-76.553, 0.417],
                                  [-39.421, 50.614],
                                  [-6.853, 88.365],
                                  [43.235, 43.965],
                                  [24.253, 40.737],
                                  [104.184, -17.631],
                                  [-16.018, -52.542],
                                  [-18.778, -60.649],
                                  [22.068, -92],
                                ],
                                v: [
                                  [6.893, -303.658],
                                  [-187.328, -236.535],
                                  [-265.508, -72.506],
                                  [-232.474, 149.239],
                                  [-157.13, 264.279],
                                  [108.582, 332.068],
                                  [341.492, 256.256],
                                  [233.046, 44.956],
                                  [166.553, -118.241],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.34, 10.68],
                                  [39.608, -50.402],
                                  [6.722, -88.495],
                                  [-43, -43.99],
                                  [-14.082, -28.124],
                                  [-184.082, 34.907],
                                  [15.702, 52.464],
                                  [4.062, 47.093],
                                  [-22.103, 92],
                                ],
                                o: [
                                  [-76.495, 0.289],
                                  [-39.608, 50.402],
                                  [-6.722, 88.495],
                                  [43, 43.99],
                                  [24.268, 40.66],
                                  [104.165, -17.67],
                                  [-15.702, -52.464],
                                  [-18.948, -60.413],
                                  [22.103, -92],
                                ],
                                v: [
                                  [6.387, -303.468],
                                  [-187.939, -236.993],
                                  [-265.275, -72.88],
                                  [-232.531, 149.732],
                                  [-157.53, 263.889],
                                  [108.487, 331.603],
                                  [341.974, 256.491],
                                  [233.259, 44.984],
                                  [166.577, -117.685],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.417, 10.835],
                                  [39.795, -50.191],
                                  [6.59, -88.624],
                                  [-42.766, -44.014],
                                  [-14.073, -28.016],
                                  [-184.26, 34.988],
                                  [15.385, 52.386],
                                  [4.195, 46.824],
                                  [-22.138, 92],
                                ],
                                o: [
                                  [-76.437, 0.161],
                                  [-39.795, 50.191],
                                  [-6.59, 88.624],
                                  [42.766, 44.014],
                                  [24.283, 40.583],
                                  [104.146, -17.709],
                                  [-15.385, -52.386],
                                  [-19.118, -60.177],
                                  [22.138, -92],
                                ],
                                v: [
                                  [5.817, -303.209],
                                  [-188.503, -237.401],
                                  [-265.116, -73.222],
                                  [-232.574, 150.244],
                                  [-157.936, 263.531],
                                  [108.378, 331.137],
                                  [342.469, 256.777],
                                  [233.46, 45.052],
                                  [166.542, -117.113],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.495, 10.99],
                                  [39.981, -49.98],
                                  [6.459, -88.754],
                                  [-42.531, -44.039],
                                  [-14.063, -27.907],
                                  [-184.438, 35.07],
                                  [15.068, 52.308],
                                  [4.329, 46.555],
                                  [-22.173, 92],
                                ],
                                o: [
                                  [-76.379, 0.034],
                                  [-39.981, 49.98],
                                  [-6.459, 88.754],
                                  [42.531, 44.039],
                                  [24.299, 40.505],
                                  [104.126, -17.747],
                                  [-15.068, -52.308],
                                  [-19.289, -59.941],
                                  [22.173, -92],
                                ],
                                v: [
                                  [5.232, -302.945],
                                  [-189.021, -237.844],
                                  [-264.907, -73.602],
                                  [-232.604, 150.811],
                                  [-158.347, 263.069],
                                  [108.317, 330.721],
                                  [342.965, 257.003],
                                  [233.699, 45.075],
                                  [166.526, -116.589],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.572, 11.144],
                                  [40.168, -49.768],
                                  [6.327, -88.883],
                                  [-42.297, -44.064],
                                  [-14.053, -27.799],
                                  [-184.616, 35.151],
                                  [14.751, 52.23],
                                  [4.462, 46.286],
                                  [-22.208, 92],
                                ],
                                o: [
                                  [-76.321, -0.094],
                                  [-40.168, 49.768],
                                  [-6.327, 88.883],
                                  [42.297, 44.064],
                                  [24.314, 40.428],
                                  [104.107, -17.786],
                                  [-14.751, -52.23],
                                  [-19.459, -59.705],
                                  [22.207, -92],
                                ],
                                v: [
                                  [4.658, -302.64],
                                  [-189.542, -238.324],
                                  [-264.646, -73.935],
                                  [-232.634, 151.334],
                                  [-158.778, 262.653],
                                  [108.255, 330.34],
                                  [343.411, 257.232],
                                  [233.891, 45.151],
                                  [166.515, -116.001],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.65, 11.299],
                                  [40.355, -49.557],
                                  [6.196, -89.013],
                                  [-42.062, -44.088],
                                  [-14.044, -27.691],
                                  [-184.794, 35.232],
                                  [14.434, 52.153],
                                  [4.596, 46.018],
                                  [-22.242, 92],
                                ],
                                o: [
                                  [-76.263, -0.222],
                                  [-40.355, 49.557],
                                  [-6.196, 89.013],
                                  [42.062, 44.088],
                                  [24.33, 40.35],
                                  [104.088, -17.825],
                                  [-14.434, -52.153],
                                  [-19.629, -59.469],
                                  [22.242, -92],
                                ],
                                v: [
                                  [4.006, -302.316],
                                  [-190.053, -238.768],
                                  [-264.446, -74.284],
                                  [-232.701, 151.863],
                                  [-159.214, 262.183],
                                  [108.191, 329.973],
                                  [343.869, 257.428],
                                  [234.085, 45.295],
                                  [166.521, -115.474],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.727, 11.454],
                                  [40.541, -49.346],
                                  [6.064, -89.143],
                                  [-41.828, -44.113],
                                  [-14.034, -27.582],
                                  [-184.972, 35.313],
                                  [14.118, 52.075],
                                  [4.729, 45.749],
                                  [-22.277, 92],
                                ],
                                o: [
                                  [-76.205, -0.35],
                                  [-40.541, 49.346],
                                  [-6.064, 89.143],
                                  [41.828, 44.113],
                                  [24.345, 40.273],
                                  [104.068, -17.864],
                                  [-14.118, -52.075],
                                  [-19.799, -59.233],
                                  [22.277, -92],
                                ],
                                v: [
                                  [3.401, -301.937],
                                  [-190.504, -239.248],
                                  [-264.243, -74.586],
                                  [-232.755, 152.447],
                                  [-159.656, 261.708],
                                  [108.177, 329.642],
                                  [344.328, 257.662],
                                  [234.268, 45.381],
                                  [166.482, -114.995],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.804, 11.609],
                                  [40.728, -49.134],
                                  [5.933, -89.272],
                                  [-41.593, -44.138],
                                  [-14.024, -27.474],
                                  [-185.15, 35.395],
                                  [13.801, 51.997],
                                  [4.863, 45.48],
                                  [-22.312, 92],
                                ],
                                o: [
                                  [-76.147, -0.477],
                                  [-40.728, 49.134],
                                  [-5.933, 89.272],
                                  [41.593, 44.138],
                                  [24.361, 40.196],
                                  [104.049, -17.902],
                                  [-13.801, -51.997],
                                  [-19.97, -58.997],
                                  [22.312, -92],
                                ],
                                v: [
                                  [2.696, -301.552],
                                  [-190.97, -239.766],
                                  [-264.002, -74.878],
                                  [-232.798, 152.987],
                                  [-160.166, 261.228],
                                  [108.149, 329.361],
                                  [344.736, 257.836],
                                  [234.491, 45.534],
                                  [166.447, -114.503],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.882, 11.764],
                                  [40.915, -48.923],
                                  [5.801, -89.402],
                                  [-41.358, -44.162],
                                  [-14.015, -27.366],
                                  [-185.328, 35.476],
                                  [13.484, 51.919],
                                  [4.996, 45.211],
                                  [-22.347, 92],
                                ],
                                o: [
                                  [-76.089, -0.605],
                                  [-40.915, 48.923],
                                  [-5.801, 89.402],
                                  [41.358, 44.162],
                                  [24.376, 40.118],
                                  [104.03, -17.941],
                                  [-13.484, -51.919],
                                  [-20.14, -58.761],
                                  [22.347, -92],
                                ],
                                v: [
                                  [2.025, -301.111],
                                  [-191.377, -240.235],
                                  [-263.77, -75.184],
                                  [-232.892, 153.545],
                                  [-160.631, 260.694],
                                  [108.17, 329.131],
                                  [345.156, 258.025],
                                  [234.68, 45.692],
                                  [166.429, -114.009],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.959, 11.918],
                                  [41.101, -48.712],
                                  [5.669, -89.532],
                                  [-41.124, -44.187],
                                  [-14.005, -27.257],
                                  [-185.506, 35.557],
                                  [13.167, 51.841],
                                  [5.13, 44.942],
                                  [-22.382, 92],
                                ],
                                o: [
                                  [-76.031, -0.733],
                                  [-41.101, 48.712],
                                  [-5.669, 89.532],
                                  [41.124, 44.187],
                                  [24.392, 40.041],
                                  [104.01, -17.979],
                                  [-13.167, -51.841],
                                  [-20.31, -58.525],
                                  [22.382, -92],
                                ],
                                v: [
                                  [1.353, -300.664],
                                  [-191.774, -240.755],
                                  [-263.586, -75.443],
                                  [-232.925, 154.146],
                                  [-161.115, 260.143],
                                  [108.204, 328.902],
                                  [345.525, 258.167],
                                  [234.846, 45.904],
                                  [166.365, -113.514],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.037, 12.073],
                                  [41.288, -48.5],
                                  [5.538, -89.661],
                                  [-40.889, -44.212],
                                  [-13.995, -27.149],
                                  [-185.684, 35.638],
                                  [12.85, 51.763],
                                  [5.263, 44.673],
                                  [-22.416, 92],
                                ],
                                o: [
                                  [-75.973, -0.86],
                                  [-41.288, 48.5],
                                  [-5.538, 89.661],
                                  [40.889, 44.212],
                                  [24.407, 39.963],
                                  [103.991, -18.018],
                                  [-12.851, -51.763],
                                  [-20.48, -58.289],
                                  [22.416, -92],
                                ],
                                v: [
                                  [0.617, -300.212],
                                  [-192.136, -241.263],
                                  [-263.361, -75.716],
                                  [-232.996, 154.703],
                                  [-161.616, 259.587],
                                  [108.224, 328.762],
                                  [345.905, 258.349],
                                  [235.077, 46.121],
                                  [166.304, -113.068],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.114, 12.228],
                                  [41.475, -48.289],
                                  [5.406, -89.791],
                                  [-40.655, -44.236],
                                  [-13.986, -27.041],
                                  [-185.862, 35.72],
                                  [12.534, 51.685],
                                  [5.396, 44.404],
                                  [-22.451, 92],
                                ],
                                o: [
                                  [-75.915, -0.988],
                                  [-41.475, 48.289],
                                  [-5.406, 89.791],
                                  [40.655, 44.236],
                                  [24.423, 39.886],
                                  [103.972, -18.057],
                                  [-12.534, -51.685],
                                  [-20.651, -58.053],
                                  [22.451, -92],
                                ],
                                v: [
                                  [-0.071, -299.69],
                                  [-192.488, -241.823],
                                  [-263.133, -75.94],
                                  [-233.108, 155.278],
                                  [-162.121, 259.026],
                                  [108.295, 328.635],
                                  [346.234, 258.433],
                                  [235.248, 46.343],
                                  [166.247, -112.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.191, 12.383],
                                  [41.661, -48.078],
                                  [5.275, -89.92],
                                  [-40.42, -44.261],
                                  [-13.976, -26.932],
                                  [-186.04, 35.801],
                                  [12.217, 51.608],
                                  [5.53, 44.135],
                                  [-22.486, 92],
                                ],
                                o: [
                                  [-75.857, -1.116],
                                  [-41.661, 48.078],
                                  [-5.275, 89.92],
                                  [40.42, 44.261],
                                  [24.438, 39.809],
                                  [103.952, -18.096],
                                  [-12.217, -51.608],
                                  [-20.821, -57.817],
                                  [22.486, -92],
                                ],
                                v: [
                                  [-0.823, -299.163],
                                  [-192.793, -242.321],
                                  [-262.914, -76.167],
                                  [-233.159, 155.846],
                                  [-162.694, 258.411],
                                  [108.366, 328.498],
                                  [346.561, 258.57],
                                  [235.461, 46.62],
                                  [166.143, -112.225],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.269, 12.537],
                                  [41.848, -47.866],
                                  [5.143, -90.05],
                                  [-40.186, -44.286],
                                  [-13.966, -26.824],
                                  [-186.218, 35.882],
                                  [11.9, 51.53],
                                  [5.663, 43.866],
                                  [-22.521, 92],
                                ],
                                o: [
                                  [-75.798, -1.243],
                                  [-41.848, 47.866],
                                  [-5.143, 90.05],
                                  [40.186, 44.286],
                                  [24.454, 39.731],
                                  [103.933, -18.134],
                                  [-11.9, -51.53],
                                  [-20.991, -57.581],
                                  [22.521, -92],
                                ],
                                v: [
                                  [-1.576, -298.617],
                                  [-193.088, -242.871],
                                  [-262.653, -76.394],
                                  [-233.251, 156.431],
                                  [-163.234, 257.829],
                                  [108.436, 328.476],
                                  [346.887, 258.646],
                                  [235.626, 46.914],
                                  [165.993, -111.828],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.346, 12.692],
                                  [42.035, -47.655],
                                  [5.012, -90.18],
                                  [-39.951, -44.31],
                                  [-13.957, -26.716],
                                  [-186.396, 35.963],
                                  [11.583, 51.452],
                                  [5.797, 43.598],
                                  [-22.556, 92],
                                ],
                                o: [
                                  [-75.74, -1.371],
                                  [-42.035, 47.655],
                                  [-5.012, 90.18],
                                  [39.951, 44.31],
                                  [24.469, 39.654],
                                  [103.913, -18.173],
                                  [-11.583, -51.452],
                                  [-21.161, -57.345],
                                  [22.556, -92],
                                ],
                                v: [
                                  [-2.292, -298.028],
                                  [-193.335, -243.462],
                                  [-262.439, -76.584],
                                  [-233.347, 156.971],
                                  [-163.79, 257.192],
                                  [108.545, 328.493],
                                  [347.148, 258.713],
                                  [235.846, 47.263],
                                  [165.908, -111.43],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.423, 12.847],
                                  [42.221, -47.444],
                                  [4.88, -90.309],
                                  [-39.717, -44.335],
                                  [-13.947, -26.607],
                                  [-186.574, 36.045],
                                  [11.267, 51.374],
                                  [5.93, 43.329],
                                  [-22.591, 92],
                                ],
                                o: [
                                  [-75.682, -1.499],
                                  [-42.221, 47.444],
                                  [-4.88, 90.309],
                                  [39.717, 44.335],
                                  [24.485, 39.577],
                                  [103.894, -18.212],
                                  [-11.267, -51.374],
                                  [-21.332, -57.109],
                                  [22.591, -92],
                                ],
                                v: [
                                  [-3.06, -297.408],
                                  [-193.534, -244.004],
                                  [-262.233, -76.737],
                                  [-233.433, 157.517],
                                  [-164.35, 256.551],
                                  [108.717, 328.514],
                                  [347.407, 258.732],
                                  [236.068, 47.618],
                                  [165.763, -111.071],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.501, 13.002],
                                  [42.408, -47.232],
                                  [4.749, -90.439],
                                  [-39.482, -44.36],
                                  [-13.937, -26.499],
                                  [-186.752, 36.126],
                                  [10.95, 51.296],
                                  [6.064, 43.06],
                                  [-22.625, 92],
                                ],
                                o: [
                                  [-75.624, -1.626],
                                  [-42.408, 47.232],
                                  [-4.749, 90.439],
                                  [39.482, 44.36],
                                  [24.5, 39.499],
                                  [103.875, -18.25],
                                  [-10.95, -51.296],
                                  [-21.502, -56.873],
                                  [22.625, -92],
                                ],
                                v: [
                                  [-3.829, -296.794],
                                  [-193.723, -244.587],
                                  [-261.985, -76.941],
                                  [-233.561, 158.067],
                                  [-164.926, 255.905],
                                  [108.89, 328.599],
                                  [347.603, 258.792],
                                  [236.283, 48.028],
                                  [165.571, -110.723],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.578, 13.156],
                                  [42.595, -47.021],
                                  [4.617, -90.568],
                                  [-39.247, -44.384],
                                  [-13.928, -26.391],
                                  [-186.93, 36.207],
                                  [10.633, 51.218],
                                  [6.197, 42.791],
                                  [-22.66, 92],
                                ],
                                o: [
                                  [-75.566, -1.754],
                                  [-42.595, 47.021],
                                  [-4.617, 90.568],
                                  [39.247, 44.384],
                                  [24.516, 39.422],
                                  [103.855, -18.289],
                                  [-10.633, -51.218],
                                  [-21.672, -56.637],
                                  [22.66, -92],
                                ],
                                v: [
                                  [-4.56, -296.151],
                                  [-193.876, -245.173],
                                  [-261.783, -77.055],
                                  [-233.631, 158.634],
                                  [-165.506, 255.254],
                                  [109.052, 328.726],
                                  [347.785, 258.742],
                                  [236.489, 48.456],
                                  [165.382, -110.414],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.656, 13.311],
                                  [42.782, -46.809],
                                  [4.486, -90.698],
                                  [-39.013, -44.409],
                                  [-13.918, -26.282],
                                  [-187.108, 36.288],
                                  [10.316, 51.14],
                                  [6.331, 42.522],
                                  [-22.695, 92],
                                ],
                                o: [
                                  [-75.508, -1.882],
                                  [-42.782, 46.809],
                                  [-4.486, 90.698],
                                  [39.013, 44.409],
                                  [24.531, 39.344],
                                  [103.836, -18.328],
                                  [-10.316, -51.14],
                                  [-21.842, -56.401],
                                  [22.695, -92],
                                ],
                                v: [
                                  [-5.342, -295.502],
                                  [-193.967, -245.812],
                                  [-261.588, -77.181],
                                  [-233.743, 159.143],
                                  [-166.089, 254.599],
                                  [109.276, 328.907],
                                  [347.914, 258.746],
                                  [236.711, 48.928],
                                  [165.156, -110.157],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.733, 13.466],
                                  [42.968, -46.598],
                                  [4.354, -90.828],
                                  [-38.778, -44.434],
                                  [-13.908, -26.174],
                                  [-187.286, 36.37],
                                  [10, 51.062],
                                  [6.464, 42.253],
                                  [-22.73, 92],
                                ],
                                o: [
                                  [-75.45, -2.009],
                                  [-42.968, 46.598],
                                  [-4.354, 90.828],
                                  [38.778, 44.434],
                                  [24.547, 39.267],
                                  [103.817, -18.366],
                                  [-10, -51.062],
                                  [-22.012, -56.165],
                                  [22.73, -92],
                                ],
                                v: [
                                  [-6.086, -294.797],
                                  [-194.05, -246.38],
                                  [-261.338, -77.318],
                                  [-233.86, 159.618],
                                  [-166.688, 253.939],
                                  [109.49, 329.142],
                                  [347.991, 258.69],
                                  [236.976, 49.428],
                                  [164.922, -109.849],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.81, 13.621],
                                  [43.155, -46.387],
                                  [4.222, -90.957],
                                  [-38.544, -44.458],
                                  [-13.899, -26.066],
                                  [-187.464, 36.451],
                                  [9.683, 50.985],
                                  [6.598, 41.984],
                                  [-22.765, 92],
                                ],
                                o: [
                                  [-75.392, -2.137],
                                  [-43.155, 46.387],
                                  [-4.222, 90.957],
                                  [38.544, 44.458],
                                  [24.562, 39.19],
                                  [103.797, -18.405],
                                  [-9.683, -50.985],
                                  [-22.183, -55.929],
                                  [22.765, -92],
                                ],
                                v: [
                                  [-6.819, -294.074],
                                  [-194.094, -247.013],
                                  [-261.094, -77.403],
                                  [-233.97, 160.148],
                                  [-167.291, 253.275],
                                  [109.717, 329.432],
                                  [348.095, 258.574],
                                  [237.245, 49.973],
                                  [164.701, -109.592],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.888, 13.775],
                                  [43.342, -46.175],
                                  [4.091, -91.087],
                                  [-38.309, -44.483],
                                  [-13.889, -25.957],
                                  [-187.642, 36.532],
                                  [9.366, 50.907],
                                  [6.731, 41.715],
                                  [-22.799, 92],
                                ],
                                o: [
                                  [-75.334, -2.265],
                                  [-43.342, 46.175],
                                  [-4.091, 91.087],
                                  [38.309, 44.483],
                                  [24.578, 39.112],
                                  [103.778, -18.444],
                                  [-9.366, -50.907],
                                  [-22.353, -55.693],
                                  [22.799, -92],
                                ],
                                v: [
                                  [-7.553, -293.346],
                                  [-194.13, -247.638],
                                  [-260.897, -77.498],
                                  [-234.124, 160.591],
                                  [-167.908, 252.606],
                                  [109.984, 329.777],
                                  [348.105, 258.462],
                                  [237.518, 50.587],
                                  [164.42, -109.376],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.965, 13.93],
                                  [43.528, -45.964],
                                  [3.959, -91.217],
                                  [-38.075, -44.508],
                                  [-13.879, -25.849],
                                  [-187.82, 36.613],
                                  [9.049, 50.829],
                                  [6.865, 41.446],
                                  [-22.834, 92],
                                ],
                                o: [
                                  [-75.276, -2.392],
                                  [-43.528, 45.964],
                                  [-3.959, 91.217],
                                  [38.075, 44.508],
                                  [24.593, 39.035],
                                  [103.759, -18.483],
                                  [-9.049, -50.829],
                                  [-22.523, -55.457],
                                  [22.834, -92],
                                ],
                                v: [
                                  [-8.277, -292.653],
                                  [-194.115, -248.307],
                                  [-260.655, -77.552],
                                  [-234.221, 161.027],
                                  [-168.477, 251.933],
                                  [110.315, 330.126],
                                  [348.144, 258.352],
                                  [237.796, 51.206],
                                  [164.101, -109.172],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.042, 14.085],
                                  [43.715, -45.753],
                                  [3.828, -91.346],
                                  [-37.84, -44.532],
                                  [-13.87, -25.741],
                                  [-187.998, 36.695],
                                  [8.732, 50.751],
                                  [6.998, 41.177],
                                  [-22.869, 92],
                                ],
                                o: [
                                  [-75.218, -2.52],
                                  [-43.715, 45.753],
                                  [-3.828, 91.346],
                                  [37.84, 44.532],
                                  [24.608, 38.958],
                                  [103.739, -18.521],
                                  [-8.732, -50.751],
                                  [-22.693, -55.22],
                                  [22.869, -92],
                                ],
                                v: [
                                  [-8.96, -291.852],
                                  [-194.04, -248.927],
                                  [-260.448, -77.616],
                                  [-234.374, 161.426],
                                  [-169.101, 251.244],
                                  [110.596, 330.582],
                                  [348.077, 258.234],
                                  [238.129, 51.882],
                                  [163.784, -109.01],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.12, 14.24],
                                  [43.902, -45.541],
                                  [3.696, -91.476],
                                  [-37.605, -44.557],
                                  [-13.86, -25.632],
                                  [-188.176, 36.776],
                                  [8.416, 50.673],
                                  [7.132, 40.909],
                                  [-22.904, 92],
                                ],
                                o: [
                                  [-75.16, -2.648],
                                  [-43.902, 45.541],
                                  [-3.696, 91.476],
                                  [37.605, 44.557],
                                  [24.624, 38.88],
                                  [103.72, -18.56],
                                  [-8.416, -50.673],
                                  [-22.864, -54.984],
                                  [22.904, -92],
                                ],
                                v: [
                                  [-9.623, -291.086],
                                  [-193.977, -249.592],
                                  [-260.196, -77.636],
                                  [-234.521, 161.829],
                                  [-169.676, 250.561],
                                  [110.981, 331.042],
                                  [347.999, 258.057],
                                  [238.456, 52.616],
                                  [163.417, -108.849],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.197, 14.394],
                                  [44.088, -45.33],
                                  [3.565, -91.605],
                                  [-37.371, -44.582],
                                  [-13.85, -25.524],
                                  [-188.354, 36.857],
                                  [8.099, 50.595],
                                  [7.265, 40.64],
                                  [-22.939, 92],
                                ],
                                o: [
                                  [-75.102, -2.775],
                                  [-44.088, 45.33],
                                  [-3.565, 91.605],
                                  [37.371, 44.582],
                                  [24.639, 38.803],
                                  [103.701, -18.599],
                                  [-8.099, -50.595],
                                  [-23.034, -54.748],
                                  [22.939, -92],
                                ],
                                v: [
                                  [-10.287, -290.325],
                                  [-193.801, -250.261],
                                  [-259.949, -77.603],
                                  [-234.611, 162.194],
                                  [-170.317, 249.874],
                                  [111.315, 331.558],
                                  [347.919, 257.831],
                                  [238.798, 53.314],
                                  [163.052, -108.73],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.275, 14.549],
                                  [44.275, -45.119],
                                  [3.433, -91.735],
                                  [-37.136, -44.606],
                                  [-13.841, -25.416],
                                  [-188.532, 36.938],
                                  [7.782, 50.517],
                                  [7.399, 40.371],
                                  [-22.974, 92],
                                ],
                                o: [
                                  [-75.044, -2.903],
                                  [-44.275, 45.119],
                                  [-3.433, 91.735],
                                  [37.136, 44.606],
                                  [24.655, 38.725],
                                  [103.681, -18.637],
                                  [-7.782, -50.517],
                                  [-23.204, -54.512],
                                  [22.974, -92],
                                ],
                                v: [
                                  [-10.951, -289.539],
                                  [-193.679, -250.934],
                                  [-259.75, -77.578],
                                  [-234.749, 162.448],
                                  [-170.92, 249.223],
                                  [111.755, 332.079],
                                  [347.775, 257.65],
                                  [239.196, 54.122],
                                  [162.636, -108.613],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.352, 14.704],
                                  [44.462, -44.907],
                                  [3.302, -91.865],
                                  [-36.902, -44.631],
                                  [-13.831, -25.307],
                                  [-188.71, 37.02],
                                  [7.465, 50.44],
                                  [7.532, 40.102],
                                  [-23.008, 92],
                                ],
                                o: [
                                  [-74.986, -3.031],
                                  [-44.462, 44.907],
                                  [-3.302, 91.865],
                                  [36.902, 44.631],
                                  [24.67, 38.648],
                                  [103.662, -18.676],
                                  [-7.465, -50.44],
                                  [-23.374, -54.276],
                                  [23.008, -92],
                                ],
                                v: [
                                  [-11.555, -288.747],
                                  [-193.454, -251.6],
                                  [-259.482, -77.56],
                                  [-234.891, 162.777],
                                  [-171.567, 248.517],
                                  [112.145, 332.553],
                                  [347.629, 257.42],
                                  [239.599, 54.884],
                                  [162.232, -108.55],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.429, 14.859],
                                  [44.648, -44.696],
                                  [3.17, -91.994],
                                  [-36.667, -44.656],
                                  [-13.821, -25.199],
                                  [-188.888, 37.101],
                                  [7.148, 50.362],
                                  [7.666, 39.833],
                                  [-23.043, 92],
                                ],
                                o: [
                                  [-74.928, -3.158],
                                  [-44.648, 44.696],
                                  [-3.17, 91.994],
                                  [36.667, 44.656],
                                  [24.686, 38.571],
                                  [103.643, -18.715],
                                  [-7.149, -50.362],
                                  [-23.545, -54.04],
                                  [23.043, -92],
                                ],
                                v: [
                                  [-12.159, -287.951],
                                  [-193.219, -252.261],
                                  [-259.23, -77.488],
                                  [-235.082, 162.995],
                                  [-172.176, 247.869],
                                  [112.598, 333.083],
                                  [347.42, 257.14],
                                  [240.007, 55.715],
                                  [161.768, -108.489],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.507, 15.014],
                                  [44.835, -44.485],
                                  [3.039, -92.124],
                                  [-36.433, -44.68],
                                  [-13.812, -25.091],
                                  [-189.066, 37.182],
                                  [6.832, 50.284],
                                  [7.799, 39.564],
                                  [-23.078, 92],
                                ],
                                o: [
                                  [-74.87, -3.286],
                                  [-44.835, 44.485],
                                  [-3.039, 92.124],
                                  [36.433, 44.68],
                                  [24.701, 38.493],
                                  [103.623, -18.753],
                                  [-6.832, -50.284],
                                  [-23.715, -53.804],
                                  [23.078, -92],
                                ],
                                v: [
                                  [-12.711, -287.15],
                                  [-192.943, -252.988],
                                  [-259.015, -77.37],
                                  [-235.227, 163.225],
                                  [-172.797, 247.206],
                                  [113.053, 333.618],
                                  [347.199, 256.854],
                                  [240.471, 56.551],
                                  [161.314, -108.429],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.584, 15.168],
                                  [45.022, -44.273],
                                  [2.907, -92.253],
                                  [-36.198, -44.705],
                                  [-13.802, -24.982],
                                  [-189.244, 37.263],
                                  [6.515, 50.206],
                                  [7.933, 39.295],
                                  [-23.113, 92],
                                ],
                                o: [
                                  [-74.812, -3.414],
                                  [-45.022, 44.273],
                                  [-2.907, 92.253],
                                  [36.198, 44.705],
                                  [24.717, 38.416],
                                  [103.604, -18.792],
                                  [-6.515, -50.206],
                                  [-23.885, -53.568],
                                  [23.113, -92],
                                ],
                                v: [
                                  [-13.255, -286.323],
                                  [-192.669, -253.656],
                                  [-258.742, -77.248],
                                  [-235.43, 163.467],
                                  [-173.41, 246.539],
                                  [113.509, 334.157],
                                  [346.914, 256.56],
                                  [240.888, 57.445],
                                  [160.809, -108.467],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.662, 15.323],
                                  [45.208, -44.062],
                                  [2.775, -92.383],
                                  [-35.964, -44.73],
                                  [-13.792, -24.874],
                                  [-189.422, 37.345],
                                  [6.198, 50.128],
                                  [8.066, 39.026],
                                  [-23.148, 92],
                                ],
                                o: [
                                  [-74.754, -3.542],
                                  [-45.208, 44.062],
                                  [-2.775, 92.383],
                                  [35.964, 44.73],
                                  [24.732, 38.339],
                                  [103.585, -18.831],
                                  [-6.198, -50.128],
                                  [-24.055, -53.332],
                                  [23.148, -92],
                                ],
                                v: [
                                  [-13.789, -285.503],
                                  [-192.437, -254.32],
                                  [-258.516, -77.133],
                                  [-235.576, 163.659],
                                  [-174.036, 245.868],
                                  [114.02, 334.638],
                                  [346.628, 256.28],
                                  [241.362, 58.303],
                                  [160.305, -108.445],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.739, 15.478],
                                  [45.395, -43.851],
                                  [2.644, -92.513],
                                  [-35.729, -44.754],
                                  [-13.783, -24.766],
                                  [-189.599, 37.426],
                                  [5.881, 50.05],
                                  [8.2, 38.757],
                                  [-23.182, 92],
                                ],
                                o: [
                                  [-74.696, -3.669],
                                  [-45.395, 43.851],
                                  [-2.644, 92.513],
                                  [35.729, 44.754],
                                  [24.748, 38.261],
                                  [103.565, -18.869],
                                  [-5.881, -50.05],
                                  [-24.226, -53.096],
                                  [23.182, -92],
                                ],
                                v: [
                                  [-14.272, -284.721],
                                  [-192.164, -254.945],
                                  [-258.242, -76.971],
                                  [-235.773, 163.8],
                                  [-174.612, 245.235],
                                  [114.541, 335.187],
                                  [346.288, 255.941],
                                  [241.841, 59.219],
                                  [159.758, -108.487],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.816, 15.633],
                                  [45.582, -43.639],
                                  [2.512, -92.642],
                                  [-35.494, -44.779],
                                  [-13.773, -24.657],
                                  [-189.777, 37.507],
                                  [5.565, 49.972],
                                  [8.333, 38.488],
                                  [-23.217, 92],
                                ],
                                o: [
                                  [-74.638, -3.797],
                                  [-45.582, 43.639],
                                  [-2.512, 92.642],
                                  [35.494, 44.779],
                                  [24.763, 38.184],
                                  [103.546, -18.908],
                                  [-5.565, -49.972],
                                  [-24.396, -52.86],
                                  [23.217, -92],
                                ],
                                v: [
                                  [-14.703, -283.872],
                                  [-191.882, -255.617],
                                  [-257.954, -76.752],
                                  [-235.985, 163.952],
                                  [-175.253, 244.556],
                                  [115.055, 335.687],
                                  [345.928, 255.595],
                                  [242.387, 60.194],
                                  [159.255, -108.576],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.894, 15.787],
                                  [45.768, -43.428],
                                  [2.381, -92.772],
                                  [-35.26, -44.804],
                                  [-13.763, -24.549],
                                  [-189.955, 37.588],
                                  [5.248, 49.895],
                                  [8.467, 38.22],
                                  [-23.252, 92],
                                ],
                                o: [
                                  [-74.58, -3.925],
                                  [-45.768, 43.428],
                                  [-2.381, 92.772],
                                  [35.26, 44.804],
                                  [24.779, 38.106],
                                  [103.527, -18.947],
                                  [-5.248, -49.894],
                                  [-24.566, -52.624],
                                  [23.252, -92],
                                ],
                                v: [
                                  [-15.125, -283.072],
                                  [-191.663, -256.241],
                                  [-257.722, -76.537],
                                  [-236.238, 164.052],
                                  [-175.833, 243.979],
                                  [115.58, 336.245],
                                  [345.566, 255.209],
                                  [242.875, 61.131],
                                  [158.709, -108.721],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.971, 15.942],
                                  [45.955, -43.217],
                                  [2.249, -92.901],
                                  [-35.025, -44.828],
                                  [-13.754, -24.441],
                                  [-190.133, 37.67],
                                  [4.931, 49.817],
                                  [8.6, 37.951],
                                  [-23.287, 92],
                                ],
                                o: [
                                  [-74.522, -4.052],
                                  [-45.955, 43.217],
                                  [-2.249, 92.902],
                                  [35.025, 44.828],
                                  [24.794, 38.029],
                                  [103.507, -18.986],
                                  [-4.931, -49.817],
                                  [-24.736, -52.388],
                                  [23.287, -92],
                                ],
                                v: [
                                  [-15.549, -282.268],
                                  [-191.445, -256.816],
                                  [-257.475, -76.265],
                                  [-236.454, 164.153],
                                  [-176.478, 243.335],
                                  [116.116, 336.745],
                                  [345.15, 254.869],
                                  [243.431, 62.074],
                                  [158.163, -108.807],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.048, 16.097],
                                  [46.142, -43.005],
                                  [2.118, -93.031],
                                  [-34.791, -44.853],
                                  [-13.744, -24.332],
                                  [-190.311, 37.751],
                                  [4.614, 49.739],
                                  [8.734, 37.682],
                                  [-23.322, 92],
                                ],
                                o: [
                                  [-74.464, -4.18],
                                  [-46.142, 43.005],
                                  [-2.118, 93.031],
                                  [34.791, 44.853],
                                  [24.81, 37.952],
                                  [103.488, -19.024],
                                  [-4.614, -49.739],
                                  [-24.907, -52.152],
                                  [23.322, -92],
                                ],
                                v: [
                                  [-15.91, -281.451],
                                  [-191.176, -257.439],
                                  [-257.171, -75.988],
                                  [-236.668, 164.264],
                                  [-177.072, 242.751],
                                  [116.696, 337.258],
                                  [344.724, 254.48],
                                  [243.992, 63.076],
                                  [157.574, -109.002],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.126, 16.252],
                                  [46.328, -42.794],
                                  [1.986, -93.161],
                                  [-34.556, -44.878],
                                  [-13.734, -24.224],
                                  [-190.489, 37.832],
                                  [4.298, 49.661],
                                  [8.867, 37.413],
                                  [-23.357, 92],
                                ],
                                o: [
                                  [-74.406, -4.308],
                                  [-46.328, 42.794],
                                  [-1.986, 93.161],
                                  [34.556, 44.878],
                                  [24.825, 37.874],
                                  [103.469, -19.063],
                                  [-4.298, -49.661],
                                  [-25.077, -51.916],
                                  [23.357, -92],
                                ],
                                v: [
                                  [-16.22, -280.63],
                                  [-190.96, -257.96],
                                  [-256.923, -75.714],
                                  [-236.943, 164.269],
                                  [-177.623, 242.154],
                                  [117.235, 337.767],
                                  [344.235, 254.138],
                                  [244.558, 64.039],
                                  [157.029, -109.201],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.203, 16.406],
                                  [46.515, -42.583],
                                  [1.855, -93.29],
                                  [-34.322, -44.902],
                                  [-13.725, -24.116],
                                  [-190.667, 37.913],
                                  [3.981, 49.583],
                                  [9, 37.144],
                                  [-23.391, 92],
                                ],
                                o: [
                                  [-74.348, -4.435],
                                  [-46.515, 42.583],
                                  [-1.855, 93.29],
                                  [34.322, 44.902],
                                  [24.841, 37.797],
                                  [103.449, -19.102],
                                  [-3.981, -49.583],
                                  [-25.247, -51.68],
                                  [23.391, -92],
                                ],
                                v: [
                                  [-16.521, -279.797],
                                  [-190.745, -258.539],
                                  [-256.661, -75.391],
                                  [-237.171, 164.337],
                                  [-178.167, 241.553],
                                  [117.819, 338.236],
                                  [343.735, 253.755],
                                  [245.19, 65.061],
                                  [156.439, -109.396],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.281, 16.561],
                                  [46.702, -42.371],
                                  [1.723, -93.42],
                                  [-34.087, -44.927],
                                  [-13.715, -24.007],
                                  [-190.845, 37.995],
                                  [3.664, 49.505],
                                  [9.134, 36.875],
                                  [-23.426, 92],
                                ],
                                o: [
                                  [-74.29, -4.563],
                                  [-46.702, 42.371],
                                  [-1.723, 93.42],
                                  [34.087, 44.927],
                                  [24.856, 37.719],
                                  [103.43, -19.14],
                                  [-3.664, -49.505],
                                  [-25.417, -51.444],
                                  [23.426, -92],
                                ],
                                v: [
                                  [-16.769, -279.005],
                                  [-190.54, -259.068],
                                  [-256.394, -75.063],
                                  [-237.397, 164.352],
                                  [-178.722, 240.949],
                                  [118.36, 338.753],
                                  [343.235, 253.367],
                                  [245.775, 66.097],
                                  [155.849, -109.594],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.358, 16.716],
                                  [46.889, -42.16],
                                  [1.591, -93.55],
                                  [-33.852, -44.952],
                                  [-13.705, -23.899],
                                  [-191.023, 38.076],
                                  [3.347, 49.427],
                                  [9.267, 36.606],
                                  [-23.461, 92],
                                ],
                                o: [
                                  [-74.232, -4.691],
                                  [-46.889, 42.16],
                                  [-1.591, 93.55],
                                  [33.852, 44.952],
                                  [24.872, 37.642],
                                  [103.41, -19.179],
                                  [-3.347, -49.427],
                                  [-25.587, -51.208],
                                  [23.461, -92],
                                ],
                                v: [
                                  [-17.009, -278.218],
                                  [-190.327, -259.602],
                                  [-256.113, -74.674],
                                  [-237.693, 164.322],
                                  [-179.278, 240.395],
                                  [118.911, 339.231],
                                  [342.733, 252.982],
                                  [246.363, 67.086],
                                  [155.215, -109.85],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.435, 16.871],
                                  [47.075, -41.949],
                                  [1.46, -93.679],
                                  [-33.618, -44.976],
                                  [-13.696, -23.791],
                                  [-191.201, 38.157],
                                  [3.03, 49.349],
                                  [9.401, 36.337],
                                  [-23.496, 92],
                                ],
                                o: [
                                  [-74.174, -4.818],
                                  [-47.075, 41.949],
                                  [-1.46, 93.679],
                                  [33.618, 44.976],
                                  [24.887, 37.565],
                                  [103.391, -19.218],
                                  [-3.03, -49.349],
                                  [-25.758, -50.972],
                                  [23.496, -92],
                                ],
                                v: [
                                  [-17.197, -277.412],
                                  [-190.123, -260.077],
                                  [-255.89, -74.28],
                                  [-237.934, 164.291],
                                  [-179.782, 239.838],
                                  [119.508, 339.704],
                                  [342.16, 252.6],
                                  [247.01, 68.141],
                                  [154.633, -110.155],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.513, 17.025],
                                  [47.262, -41.737],
                                  [1.328, -93.809],
                                  [-33.383, -45.001],
                                  [-13.686, -23.682],
                                  [-191.379, 38.238],
                                  [2.714, 49.272],
                                  [9.534, 36.068],
                                  [-23.531, 92],
                                ],
                                o: [
                                  [-74.115, -4.946],
                                  [-47.262, 41.737],
                                  [-1.328, 93.809],
                                  [33.383, 45.001],
                                  [24.903, 37.487],
                                  [103.372, -19.256],
                                  [-2.714, -49.272],
                                  [-25.928, -50.736],
                                  [23.531, -92],
                                ],
                                v: [
                                  [-17.377, -276.656],
                                  [-189.929, -260.565],
                                  [-255.653, -73.834],
                                  [-238.236, 164.26],
                                  [-180.295, 239.332],
                                  [120.061, 340.189],
                                  [341.587, 252.222],
                                  [247.671, 69.148],
                                  [153.943, -110.457],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.59, 17.18],
                                  [47.449, -41.526],
                                  [1.197, -93.938],
                                  [-33.149, -45.026],
                                  [-13.676, -23.574],
                                  [-191.557, 38.32],
                                  [2.397, 49.194],
                                  [9.668, 35.799],
                                  [-23.566, 92],
                                ],
                                o: [
                                  [-74.057, -5.074],
                                  [-47.449, 41.526],
                                  [-1.197, 93.938],
                                  [33.149, 45.026],
                                  [24.918, 37.41],
                                  [103.352, -19.295],
                                  [-2.397, -49.194],
                                  [-26.098, -50.5],
                                  [23.566, -92],
                                ],
                                v: [
                                  [-17.557, -275.898],
                                  [-189.727, -260.994],
                                  [-255.412, -73.391],
                                  [-238.491, 164.183],
                                  [-180.747, 238.814],
                                  [120.615, 340.671],
                                  [341.013, 251.838],
                                  [248.336, 70.214],
                                  [153.306, -110.772],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.667, 17.335],
                                  [47.635, -41.314],
                                  [1.065, -94.068],
                                  [-32.914, -45.05],
                                  [-13.667, -23.466],
                                  [-191.735, 38.401],
                                  [2.08, 49.116],
                                  [9.801, 35.531],
                                  [-23.6, 92],
                                ],
                                o: [
                                  [-73.999, -5.201],
                                  [-47.635, 41.314],
                                  [-1.065, 94.068],
                                  [32.914, 45.05],
                                  [24.933, 37.333],
                                  [103.333, -19.334],
                                  [-2.08, -49.116],
                                  [-26.268, -50.264],
                                  [23.6, -92],
                                ],
                                v: [
                                  [-17.685, -275.13],
                                  [-189.534, -261.427],
                                  [-255.158, -72.932],
                                  [-238.808, 164.051],
                                  [-181.209, 238.302],
                                  [121.17, 341.111],
                                  [340.368, 251.467],
                                  [249.014, 71.239],
                                  [152.622, -111.076],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.745, 17.49],
                                  [47.822, -41.103],
                                  [0.934, -94.198],
                                  [-32.68, -45.075],
                                  [-13.657, -23.357],
                                  [-191.913, 38.482],
                                  [1.763, 49.038],
                                  [9.935, 35.262],
                                  [-23.635, 92],
                                ],
                                o: [
                                  [-73.941, -5.329],
                                  [-47.822, 41.103],
                                  [-0.934, 94.198],
                                  [32.68, 45.075],
                                  [24.949, 37.255],
                                  [103.314, -19.372],
                                  [-1.763, -49.038],
                                  [-26.439, -50.028],
                                  [23.635, -92],
                                ],
                                v: [
                                  [-17.804, -274.359],
                                  [-189.35, -261.81],
                                  [-254.908, -72.475],
                                  [-239.086, 163.925],
                                  [-181.671, 237.834],
                                  [121.727, 341.546],
                                  [339.73, 251.099],
                                  [249.642, 72.269],
                                  [151.937, -111.447],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.822, 17.644],
                                  [48.009, -40.892],
                                  [0.802, -94.327],
                                  [-32.445, -45.1],
                                  [-13.647, -23.249],
                                  [-192.091, 38.563],
                                  [1.446, 48.96],
                                  [10.068, 34.993],
                                  [-23.67, 92],
                                ],
                                o: [
                                  [-73.883, -5.457],
                                  [-48.009, 40.892],
                                  [-0.802, 94.327],
                                  [32.445, 45.1],
                                  [24.964, 37.178],
                                  [103.294, -19.411],
                                  [-1.446, -48.96],
                                  [-26.609, -49.792],
                                  [23.67, -92],
                                ],
                                v: [
                                  [-17.863, -273.64],
                                  [-189.166, -262.196],
                                  [-254.699, -71.956],
                                  [-239.418, 163.799],
                                  [-182.079, 237.363],
                                  [122.237, 341.932],
                                  [339.076, 250.726],
                                  [250.337, 73.311],
                                  [151.259, -111.816],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.9, 17.799],
                                  [48.195, -40.68],
                                  [0.671, -94.457],
                                  [-32.211, -45.124],
                                  [-13.638, -23.141],
                                  [-192.269, 38.645],
                                  [1.13, 48.882],
                                  [10.202, 34.724],
                                  [-23.705, 92],
                                ],
                                o: [
                                  [-73.825, -5.584],
                                  [-48.195, 40.68],
                                  [-0.671, 94.457],
                                  [32.211, 45.124],
                                  [24.98, 37.1],
                                  [103.275, -19.45],
                                  [-1.13, -48.882],
                                  [-26.779, -49.556],
                                  [23.705, -92],
                                ],
                                v: [
                                  [-17.922, -272.904],
                                  [-188.982, -262.586],
                                  [-254.478, -71.431],
                                  [-239.702, 163.671],
                                  [-182.488, 236.944],
                                  [122.795, 342.329],
                                  [338.422, 250.31],
                                  [251.036, 74.366],
                                  [150.517, -112.19],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.977, 17.954],
                                  [48.382, -40.469],
                                  [0.539, -94.586],
                                  [-31.976, -45.149],
                                  [-13.628, -23.032],
                                  [-192.447, 38.726],
                                  [0.813, 48.804],
                                  [10.335, 34.455],
                                  [-23.74, 92],
                                ],
                                o: [
                                  [-73.767, -5.712],
                                  [-48.382, 40.469],
                                  [-0.539, 94.586],
                                  [31.976, 45.149],
                                  [24.995, 37.023],
                                  [103.256, -19.489],
                                  [-0.813, -48.804],
                                  [-26.949, -49.32],
                                  [23.74, -92],
                                ],
                                v: [
                                  [-17.928, -272.174],
                                  [-188.806, -262.933],
                                  [-254.254, -70.898],
                                  [-240.002, 163.494],
                                  [-182.897, 236.577],
                                  [123.307, 342.722],
                                  [337.768, 249.944],
                                  [251.747, 75.417],
                                  [149.782, -112.616],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.054, 18.109],
                                  [48.569, -40.258],
                                  [0.408, -94.716],
                                  [-31.741, -45.174],
                                  [-13.618, -22.924],
                                  [-192.625, 38.807],
                                  [0.496, 48.727],
                                  [10.469, 34.186],
                                  [-23.774, 92],
                                ],
                                o: [
                                  [-73.709, -5.84],
                                  [-48.569, 40.258],
                                  [-0.408, 94.716],
                                  [31.741, 45.174],
                                  [25.011, 36.946],
                                  [103.236, -19.527],
                                  [-0.496, -48.727],
                                  [-27.12, -49.084],
                                  [23.774, -92],
                                ],
                                v: [
                                  [-17.927, -271.489],
                                  [-188.63, -263.22],
                                  [-254.026, -70.312],
                                  [-240.301, 163.269],
                                  [-183.252, 236.154],
                                  [123.82, 343.118],
                                  [337.052, 249.582],
                                  [252.516, 76.433],
                                  [148.998, -113.041],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.132, 18.264],
                                  [48.755, -40.046],
                                  [0.276, -94.846],
                                  [-31.507, -45.198],
                                  [-13.609, -22.816],
                                  [-192.803, 38.888],
                                  [0.179, 48.649],
                                  [10.602, 33.917],
                                  [-23.809, 92],
                                ],
                                o: [
                                  [-73.651, -5.967],
                                  [-48.755, 40.046],
                                  [-0.276, 94.846],
                                  [31.507, 45.198],
                                  [25.026, 36.868],
                                  [103.217, -19.566],
                                  [-0.179, -48.649],
                                  [-27.29, -48.848],
                                  [23.809, -92],
                                ],
                                v: [
                                  [-17.864, -270.796],
                                  [-188.462, -263.511],
                                  [-253.786, -69.718],
                                  [-240.614, 163.041],
                                  [-183.67, 235.838],
                                  [124.285, 343.472],
                                  [336.383, 249.231],
                                  [253.243, 77.452],
                                  [148.266, -113.48],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.209, 18.418],
                                  [48.942, -39.835],
                                  [0.144, -94.975],
                                  [-31.272, -45.223],
                                  [-13.599, -22.707],
                                  [-192.981, 38.97],
                                  [-0.137, 48.571],
                                  [10.736, 33.648],
                                  [-23.844, 92],
                                ],
                                o: [
                                  [-73.593, -6.095],
                                  [-48.942, 39.835],
                                  [-0.144, 94.975],
                                  [31.272, 45.223],
                                  [25.042, 36.791],
                                  [103.198, -19.605],
                                  [0.137, -48.571],
                                  [-27.46, -48.612],
                                  [23.844, -92],
                                ],
                                v: [
                                  [-17.802, -270.101],
                                  [-188.301, -263.749],
                                  [-253.597, -69.172],
                                  [-240.942, 162.811],
                                  [-183.97, 235.521],
                                  [124.751, 343.821],
                                  [335.659, 248.884],
                                  [253.98, 78.475],
                                  [147.423, -113.917],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.287, 18.573],
                                  [49.129, -39.624],
                                  [0.013, -95.105],
                                  [-31.038, -45.248],
                                  [-13.589, -22.599],
                                  [-193.159, 39.051],
                                  [-0.454, 48.493],
                                  [10.869, 33.379],
                                  [-23.879, 92],
                                ],
                                o: [
                                  [-73.535, -6.223],
                                  [-49.129, 39.624],
                                  [-0.013, 95.105],
                                  [31.038, 45.248],
                                  [25.057, 36.714],
                                  [103.178, -19.643],
                                  [0.454, -48.493],
                                  [-27.63, -48.376],
                                  [23.879, -92],
                                ],
                                v: [
                                  [-17.686, -269.461],
                                  [-188.14, -263.998],
                                  [-253.343, -68.563],
                                  [-241.277, 162.585],
                                  [-184.333, 235.25],
                                  [125.209, 344.126],
                                  [334.928, 248.532],
                                  [254.721, 79.51],
                                  [146.632, -114.353],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.364, 18.728],
                                  [49.315, -39.412],
                                  [-0.119, -95.235],
                                  [-30.803, -45.272],
                                  [-13.58, -22.491],
                                  [-193.337, 39.132],
                                  [-0.771, 48.415],
                                  [11.003, 33.11],
                                  [-23.914, 92],
                                ],
                                o: [
                                  [-73.477, -6.35],
                                  [-49.315, 39.412],
                                  [0.119, 95.235],
                                  [30.803, 45.272],
                                  [25.073, 36.636],
                                  [103.159, -19.682],
                                  [0.771, -48.415],
                                  [-27.801, -48.14],
                                  [23.914, -92],
                                ],
                                v: [
                                  [-17.563, -268.868],
                                  [-187.93, -264.194],
                                  [-253.14, -67.891],
                                  [-241.619, 162.309],
                                  [-184.634, 234.977],
                                  [125.682, 344.427],
                                  [334.198, 248.247],
                                  [255.472, 80.492],
                                  [145.791, -114.851],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.441, 18.883],
                                  [49.502, -39.201],
                                  [-0.25, -95.364],
                                  [-30.569, -45.297],
                                  [-13.57, -22.382],
                                  [-193.515, 39.213],
                                  [-1.088, 48.337],
                                  [11.136, 32.842],
                                  [-23.949, 92],
                                ],
                                o: [
                                  [-73.419, -6.478],
                                  [-49.502, 39.201],
                                  [0.25, 95.364],
                                  [30.569, 45.297],
                                  [25.088, 36.559],
                                  [103.14, -19.721],
                                  [1.088, -48.337],
                                  [-27.971, -47.904],
                                  [23.949, -92],
                                ],
                                v: [
                                  [-17.386, -268.267],
                                  [-187.774, -264.393],
                                  [-252.928, -67.267],
                                  [-241.912, 162.03],
                                  [-184.941, 234.766],
                                  [126.093, 344.683],
                                  [333.461, 247.909],
                                  [256.234, 81.484],
                                  [144.947, -115.3],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.519, 19.037],
                                  [49.689, -38.99],
                                  [-0.382, -95.494],
                                  [-30.334, -45.322],
                                  [-13.56, -22.274],
                                  [-193.693, 39.295],
                                  [-1.405, 48.259],
                                  [11.27, 32.573],
                                  [-23.983, 92],
                                ],
                                o: [
                                  [-73.361, -6.606],
                                  [-49.689, 38.99],
                                  [0.382, 95.494],
                                  [30.334, 45.322],
                                  [25.104, 36.481],
                                  [103.12, -19.759],
                                  [1.404, -48.259],
                                  [-28.141, -47.668],
                                  [23.983, -92],
                                ],
                                v: [
                                  [-17.203, -267.674],
                                  [-187.625, -264.539],
                                  [-252.712, -66.636],
                                  [-242.226, 161.692],
                                  [-185.242, 234.547],
                                  [126.447, 344.935],
                                  [332.724, 247.631],
                                  [257.006, 82.48],
                                  [144.053, -115.805],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.596, 19.192],
                                  [49.875, -38.778],
                                  [-0.513, -95.623],
                                  [-30.1, -45.346],
                                  [-13.55, -22.166],
                                  [-193.871, 39.376],
                                  [-1.721, 48.182],
                                  [11.403, 32.304],
                                  [-24.018, 92],
                                ],
                                o: [
                                  [-73.303, -6.733],
                                  [-49.875, 38.778],
                                  [0.513, 95.623],
                                  [30.1, 45.346],
                                  [25.119, 36.404],
                                  [103.101, -19.798],
                                  [1.721, -48.181],
                                  [-28.311, -47.432],
                                  [24.018, -92],
                                ],
                                v: [
                                  [-16.965, -267.178],
                                  [-187.482, -264.687],
                                  [-252.431, -65.997],
                                  [-242.539, 161.358],
                                  [-185.549, 234.438],
                                  [126.864, 345.142],
                                  [331.982, 247.357],
                                  [257.731, 83.43],
                                  [143.148, -116.316],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.673, 19.347],
                                  [50.062, -38.567],
                                  [-0.645, -95.753],
                                  [-29.865, -45.371],
                                  [-13.541, -22.057],
                                  [-194.049, 39.457],
                                  [-2.038, 48.104],
                                  [11.537, 32.035],
                                  [-24.053, 92],
                                ],
                                o: [
                                  [-73.245, -6.861],
                                  [-50.062, 38.567],
                                  [0.645, 95.753],
                                  [29.865, 45.371],
                                  [25.135, 36.327],
                                  [103.082, -19.837],
                                  [2.038, -48.104],
                                  [-28.482, -47.196],
                                  [24.053, -92],
                                ],
                                v: [
                                  [-16.672, -266.634],
                                  [-187.288, -264.788],
                                  [-252.203, -65.351],
                                  [-242.872, 161.027],
                                  [-185.793, 234.328],
                                  [127.168, 345.351],
                                  [331.24, 247.092],
                                  [258.515, 84.388],
                                  [142.254, -116.827],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.751, 19.502],
                                  [50.249, -38.356],
                                  [-0.776, -95.883],
                                  [-29.63, -45.396],
                                  [-13.531, -21.949],
                                  [-194.227, 39.538],
                                  [-2.355, 48.026],
                                  [11.67, 31.766],
                                  [-24.088, 92],
                                ],
                                o: [
                                  [-73.187, -6.989],
                                  [-50.249, 38.356],
                                  [0.776, 95.883],
                                  [29.63, 45.396],
                                  [25.15, 36.249],
                                  [103.062, -19.875],
                                  [2.355, -48.026],
                                  [-28.652, -46.96],
                                  [24.088, -92],
                                ],
                                v: [
                                  [-16.373, -266.139],
                                  [-187.149, -264.897],
                                  [-252.014, -64.641],
                                  [-243.21, 160.692],
                                  [-186.044, 234.274],
                                  [127.528, 345.562],
                                  [330.5, 246.825],
                                  [259.314, 85.294],
                                  [141.35, -117.346],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.828, 19.656],
                                  [50.435, -38.144],
                                  [-0.908, -96.012],
                                  [-29.396, -45.42],
                                  [-13.521, -21.841],
                                  [-194.405, 39.62],
                                  [-2.672, 47.948],
                                  [11.804, 31.497],
                                  [-24.123, 92],
                                ],
                                o: [
                                  [-73.129, -7.116],
                                  [-50.435, 38.144],
                                  [0.908, 96.012],
                                  [29.396, 45.42],
                                  [25.166, 36.172],
                                  [103.043, -19.914],
                                  [2.672, -47.948],
                                  [-28.822, -46.724],
                                  [24.123, -92],
                                ],
                                v: [
                                  [-16.012, -265.694],
                                  [-187.015, -264.952],
                                  [-251.775, -63.979],
                                  [-243.561, 160.304],
                                  [-186.288, 234.219],
                                  [127.781, 345.721],
                                  [329.803, 246.623],
                                  [260.115, 86.257],
                                  [140.393, -117.866],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.906, 19.811],
                                  [50.622, -37.933],
                                  [-1.039, -96.142],
                                  [-29.161, -45.445],
                                  [-13.512, -21.732],
                                  [-194.583, 39.701],
                                  [-2.988, 47.87],
                                  [11.937, 31.228],
                                  [-24.158, 92],
                                ],
                                o: [
                                  [-73.071, -7.244],
                                  [-50.622, 37.933],
                                  [1.039, 96.142],
                                  [29.161, 45.445],
                                  [25.181, 36.094],
                                  [103.024, -19.953],
                                  [2.988, -47.87],
                                  [-28.992, -46.488],
                                  [24.158, -92],
                                ],
                                v: [
                                  [-15.602, -265.313],
                                  [-186.836, -264.952],
                                  [-251.52, -63.31],
                                  [-243.868, 159.911],
                                  [-186.538, 234.214],
                                  [128.083, 345.826],
                                  [329.059, 246.426],
                                  [260.874, 87.116],
                                  [139.432, -118.337],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [135.983, 19.966],
                                  [50.809, -37.722],
                                  [-1.171, -96.271],
                                  [-28.927, -45.47],
                                  [-13.502, -21.624],
                                  [-194.761, 39.782],
                                  [-3.305, 47.792],
                                  [12.071, 30.959],
                                  [-24.192, 92],
                                ],
                                o: [
                                  [-73.013, -7.372],
                                  [-50.809, 37.722],
                                  [1.171, 96.271],
                                  [28.927, 45.47],
                                  [25.197, 36.017],
                                  [103.004, -19.991],
                                  [3.305, -47.792],
                                  [-29.163, -46.252],
                                  [24.192, -92],
                                ],
                                v: [
                                  [-15.186, -264.92],
                                  [-186.654, -264.959],
                                  [-251.263, -62.69],
                                  [-244.186, 159.514],
                                  [-186.781, 234.272],
                                  [128.277, 345.933],
                                  [328.309, 246.288],
                                  [261.698, 87.983],
                                  [138.418, -118.871],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.06, 20.121],
                                  [50.996, -37.51],
                                  [-1.303, -96.401],
                                  [-28.692, -45.494],
                                  [-13.492, -21.516],
                                  [-194.939, 39.863],
                                  [-3.622, 47.714],
                                  [12.204, 30.69],
                                  [-24.227, 92],
                                ],
                                o: [
                                  [-72.955, -7.5],
                                  [-50.996, 37.51],
                                  [1.303, 96.401],
                                  [28.692, 45.494],
                                  [25.212, 35.94],
                                  [102.985, -20.03],
                                  [3.622, -47.714],
                                  [-29.333, -46.016],
                                  [24.227, -92],
                                ],
                                v: [
                                  [-14.708, -264.592],
                                  [-186.532, -264.917],
                                  [-250.998, -62.006],
                                  [-244.515, 159.068],
                                  [-187.024, 234.38],
                                  [128.526, 346.048],
                                  [327.617, 246.16],
                                  [262.466, 88.852],
                                  [137.4, -119.413],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.138, 20.275],
                                  [51.182, -37.299],
                                  [-1.434, -96.531],
                                  [-28.458, -45.519],
                                  [-13.483, -21.407],
                                  [-195.117, 39.945],
                                  [-3.939, 47.636],
                                  [12.338, 30.421],
                                  [-24.262, 92],
                                ],
                                o: [
                                  [-72.897, -7.627],
                                  [-51.182, 37.299],
                                  [1.434, 96.531],
                                  [28.458, 45.519],
                                  [25.228, 35.862],
                                  [102.966, -20.069],
                                  [3.939, -47.636],
                                  [-29.503, -45.78],
                                  [24.262, -92],
                                ],
                                v: [
                                  [-14.175, -264.26],
                                  [-186.352, -264.875],
                                  [-250.788, -61.371],
                                  [-244.854, 158.674],
                                  [-187.217, 234.552],
                                  [128.667, 346.103],
                                  [326.921, 246.036],
                                  [263.235, 89.671],
                                  [136.378, -119.957],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.215, 20.43],
                                  [51.369, -37.088],
                                  [-1.566, -96.66],
                                  [-28.223, -45.544],
                                  [-13.473, -21.299],
                                  [-195.295, 40.026],
                                  [-4.255, 47.559],
                                  [12.471, 30.153],
                                  [-24.297, 92],
                                ],
                                o: [
                                  [-72.839, -7.755],
                                  [-51.369, 37.088],
                                  [1.566, 96.66],
                                  [28.223, 45.544],
                                  [25.243, 35.785],
                                  [102.946, -20.108],
                                  [4.255, -47.559],
                                  [-29.673, -45.544],
                                  [24.297, -92],
                                ],
                                v: [
                                  [-13.641, -264.036],
                                  [-186.181, -264.782],
                                  [-250.514, -60.723],
                                  [-245.148, 158.219],
                                  [-187.459, 234.718],
                                  [128.794, 346.103],
                                  [326.232, 245.972],
                                  [264.011, 90.491],
                                  [135.358, -120.502],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.292, 20.585],
                                  [51.556, -36.876],
                                  [-1.697, -96.79],
                                  [-27.988, -45.568],
                                  [-13.463, -21.191],
                                  [-195.473, 40.107],
                                  [-4.572, 47.481],
                                  [12.604, 29.884],
                                  [-24.332, 92],
                                ],
                                o: [
                                  [-72.781, -7.883],
                                  [-51.556, 36.876],
                                  [1.697, 96.79],
                                  [27.988, 45.568],
                                  [25.258, 35.708],
                                  [102.927, -20.146],
                                  [4.572, -47.481],
                                  [-29.843, -45.308],
                                  [24.332, -92],
                                ],
                                v: [
                                  [-13.051, -263.821],
                                  [-186.012, -264.639],
                                  [-250.232, -60.13],
                                  [-245.513, 157.764],
                                  [-187.645, 234.942],
                                  [128.913, 346.099],
                                  [325.591, 245.974],
                                  [264.851, 91.323],
                                  [134.27, -121.004],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.37, 20.74],
                                  [51.742, -36.665],
                                  [-1.829, -96.919],
                                  [-27.754, -45.593],
                                  [-13.454, -21.082],
                                  [-195.651, 40.188],
                                  [-4.889, 47.403],
                                  [12.738, 29.615],
                                  [-24.366, 92],
                                ],
                                o: [
                                  [-72.723, -8.01],
                                  [-51.742, 36.665],
                                  [1.829, 96.92],
                                  [27.754, 45.593],
                                  [25.274, 35.63],
                                  [102.908, -20.185],
                                  [4.889, -47.403],
                                  [-30.014, -45.072],
                                  [24.366, -92],
                                ],
                                v: [
                                  [-12.398, -263.603],
                                  [-185.846, -264.5],
                                  [-249.95, -59.468],
                                  [-245.769, 157.247],
                                  [-187.886, 235.224],
                                  [128.974, 346.034],
                                  [324.901, 245.98],
                                  [265.571, 92.042],
                                  [133.19, -121.565],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.447, 20.894],
                                  [51.929, -36.453],
                                  [-1.96, -97.049],
                                  [-27.519, -45.618],
                                  [-13.444, -20.974],
                                  [-195.829, 40.27],
                                  [-5.206, 47.325],
                                  [12.871, 29.346],
                                  [-24.401, 92],
                                ],
                                o: [
                                  [-72.665, -8.138],
                                  [-51.929, 36.454],
                                  [1.96, 97.049],
                                  [27.519, 45.618],
                                  [25.289, 35.553],
                                  [102.888, -20.224],
                                  [5.206, -47.325],
                                  [-30.184, -44.836],
                                  [24.401, -92],
                                ],
                                v: [
                                  [-11.746, -263.496],
                                  [-185.681, -264.366],
                                  [-249.655, -58.913],
                                  [-246.09, 156.787],
                                  [-188.122, 235.564],
                                  [128.965, 345.971],
                                  [324.264, 245.99],
                                  [266.361, 92.817],
                                  [132.099, -122.072],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.525, 21.049],
                                  [52.116, -36.242],
                                  [-2.092, -97.179],
                                  [-27.285, -45.642],
                                  [-13.434, -20.866],
                                  [-196.007, 40.351],
                                  [-5.523, 47.247],
                                  [13.005, 29.077],
                                  [-24.436, 92],
                                ],
                                o: [
                                  [-72.607, -8.266],
                                  [-52.116, 36.242],
                                  [2.092, 97.179],
                                  [27.285, 45.642],
                                  [25.305, 35.475],
                                  [102.869, -20.262],
                                  [5.523, -47.247],
                                  [-30.354, -44.6],
                                  [24.436, -92],
                                ],
                                v: [
                                  [-11.036, -263.455],
                                  [-185.461, -264.174],
                                  [-249.422, -58.351],
                                  [-246.362, 156.265],
                                  [-188.306, 235.958],
                                  [128.949, 345.9],
                                  [323.686, 246.124],
                                  [267.15, 93.546],
                                  [130.953, -122.592],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.602, 21.204],
                                  [52.302, -36.031],
                                  [-2.223, -97.308],
                                  [-27.05, -45.667],
                                  [-13.425, -20.757],
                                  [-196.185, 40.432],
                                  [-5.839, 47.169],
                                  [13.138, 28.808],
                                  [-24.471, 92],
                                ],
                                o: [
                                  [-72.548, -8.393],
                                  [-52.302, 36.031],
                                  [2.223, 97.308],
                                  [27.05, 45.667],
                                  [25.32, 35.398],
                                  [102.849, -20.301],
                                  [5.839, -47.169],
                                  [-30.524, -44.364],
                                  [24.471, -92],
                                ],
                                v: [
                                  [-10.263, -263.412],
                                  [-185.299, -263.933],
                                  [-249.12, -57.778],
                                  [-246.637, 155.794],
                                  [-188.542, 236.416],
                                  [128.875, 345.716],
                                  [323.111, 246.261],
                                  [267.883, 94.218],
                                  [129.801, -123.057],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.679, 21.359],
                                  [52.489, -35.819],
                                  [-2.355, -97.438],
                                  [-26.816, -45.692],
                                  [-13.415, -20.649],
                                  [-196.363, 40.513],
                                  [-6.156, 47.091],
                                  [13.272, 28.539],
                                  [-24.506, 92],
                                ],
                                o: [
                                  [-72.49, -8.521],
                                  [-52.489, 35.819],
                                  [2.355, 97.438],
                                  [26.816, 45.692],
                                  [25.336, 35.321],
                                  [102.83, -20.34],
                                  [6.156, -47.091],
                                  [-30.695, -44.128],
                                  [24.506, -92],
                                ],
                                v: [
                                  [-9.491, -263.424],
                                  [-185.081, -263.691],
                                  [-248.813, -57.255],
                                  [-246.921, 155.259],
                                  [-188.783, 236.872],
                                  [128.732, 345.592],
                                  [322.539, 246.403],
                                  [268.673, 94.888],
                                  [128.644, -123.582],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.757, 21.514],
                                  [52.676, -35.608],
                                  [-2.486, -97.568],
                                  [-26.581, -45.716],
                                  [-13.405, -20.541],
                                  [-196.541, 40.595],
                                  [-6.473, 47.013],
                                  [13.405, 28.27],
                                  [-24.54, 92],
                                ],
                                o: [
                                  [-72.432, -8.649],
                                  [-52.676, 35.608],
                                  [2.486, 97.568],
                                  [26.581, 45.716],
                                  [25.351, 35.243],
                                  [102.811, -20.378],
                                  [6.473, -47.014],
                                  [-30.865, -43.892],
                                  [24.541, -92],
                                ],
                                v: [
                                  [-8.66, -263.503],
                                  [-184.863, -263.394],
                                  [-248.505, -56.784],
                                  [-247.154, 154.72],
                                  [-189.019, 237.449],
                                  [128.583, 345.35],
                                  [322.079, 246.669],
                                  [269.41, 95.51],
                                  [127.486, -124.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.834, 21.668],
                                  [52.862, -35.397],
                                  [-2.618, -97.697],
                                  [-26.347, -45.741],
                                  [-13.396, -20.432],
                                  [-196.719, 40.676],
                                  [-6.79, 46.936],
                                  [13.539, 28.001],
                                  [-24.575, 92],
                                ],
                                o: [
                                  [-72.374, -8.776],
                                  [-52.862, 35.397],
                                  [2.618, 97.697],
                                  [26.347, 45.741],
                                  [25.367, 35.166],
                                  [102.792, -20.417],
                                  [6.79, -46.936],
                                  [-31.035, -43.656],
                                  [24.575, -92],
                                ],
                                v: [
                                  [-7.772, -263.639],
                                  [-184.64, -263.036],
                                  [-248.14, -56.307],
                                  [-247.394, 154.174],
                                  [-189.254, 238.025],
                                  [128.37, 345.162],
                                  [321.57, 246.944],
                                  [270.153, 96.131],
                                  [126.377, -124.489],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.911, 21.823],
                                  [53.049, -35.185],
                                  [-2.75, -97.827],
                                  [-26.112, -45.766],
                                  [-13.386, -20.324],
                                  [-196.896, 40.757],
                                  [-7.107, 46.858],
                                  [13.672, 27.732],
                                  [-24.61, 92],
                                ],
                                o: [
                                  [-72.316, -8.904],
                                  [-53.049, 35.185],
                                  [2.75, 97.827],
                                  [26.112, 45.766],
                                  [25.382, 35.089],
                                  [102.772, -20.456],
                                  [7.107, -46.858],
                                  [-31.205, -43.42],
                                  [24.61, -92],
                                ],
                                v: [
                                  [-6.883, -263.837],
                                  [-184.417, -262.676],
                                  [-247.822, -55.934],
                                  [-247.635, 153.565],
                                  [-189.49, 238.661],
                                  [128.151, 344.857],
                                  [321.117, 247.339],
                                  [270.895, 96.692],
                                  [125.267, -124.922],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [136.989, 21.978],
                                  [53.236, -34.974],
                                  [-2.881, -97.956],
                                  [-25.877, -45.79],
                                  [-13.376, -20.216],
                                  [-197.074, 40.838],
                                  [-7.423, 46.78],
                                  [13.806, 27.464],
                                  [-24.645, 92],
                                ],
                                o: [
                                  [-72.258, -9.032],
                                  [-53.236, 34.974],
                                  [2.881, 97.956],
                                  [25.877, 45.79],
                                  [25.398, 35.011],
                                  [102.753, -20.494],
                                  [7.423, -46.78],
                                  [-31.376, -43.184],
                                  [24.645, -92],
                                ],
                                v: [
                                  [-5.935, -264.094],
                                  [-184.193, -262.322],
                                  [-247.504, -55.557],
                                  [-247.879, 153.012],
                                  [-189.778, 239.358],
                                  [127.874, 344.554],
                                  [320.725, 247.738],
                                  [271.636, 97.198],
                                  [124.152, -125.364],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [137.066, 22.133],
                                  [53.422, -34.763],
                                  [-3.013, -98.086],
                                  [-25.643, -45.815],
                                  [-13.367, -20.107],
                                  [-197.252, 40.92],
                                  [-7.74, 46.702],
                                  [13.939, 27.195],
                                  [-24.68, 92],
                                ],
                                o: [
                                  [-72.2, -9.159],
                                  [-53.422, 34.763],
                                  [3.013, 98.086],
                                  [25.643, 45.815],
                                  [25.413, 34.934],
                                  [102.733, -20.533],
                                  [7.74, -46.702],
                                  [-31.546, -42.948],
                                  [24.68, -92],
                                ],
                                v: [
                                  [-4.924, -264.417],
                                  [-183.963, -261.907],
                                  [-247.124, -55.233],
                                  [-248.071, 152.449],
                                  [-190.067, 240.174],
                                  [127.529, 344.253],
                                  [320.337, 248.146],
                                  [272.319, 97.706],
                                  [123.095, -125.755],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [35.906, 39.562],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, 38.719],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.219, 37.844],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.906, 36.906],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.625, 35.969],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.375, 35],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.125, 34.094],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.938, 33.219],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.719, 32.375],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.625, 31.562],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.469, 30.75],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.344, 29.969],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 28.531],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 27.812],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 27.188],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 26.531],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 25.344],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 24.844],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.344, 24.281],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.438, 23.781],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.562, 23.344],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.656, 22.906],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.781, 22.469],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.969, 22.125],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.062, 21.719],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.25, 21.438],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.469, 21.125],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.625, 20.844],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.844, 20.625],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.062, 20.375],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.312, 20.188],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, 20],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.781, 19.906],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.031, 19.75],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.281, 19.656],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 19.625],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.812, 19.594],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.094, 19.594],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.312, 19.656],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.594, 19.656],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.875, 19.75],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.125, 19.875],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.344, 20],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.562, 20.188],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.812, 20.406],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.031, 20.625],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.25, 20.938],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.406, 21.25],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.625, 21.562],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 21.906],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 22.312],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.281, 22.688],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.469, 23.094],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.781, 23.531],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.062, 23.969],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.344, 24.438],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.625, 24.875],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.906, 25.344],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.25, 25.844],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.562, 26.312],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.875, 26.812],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.219, 27.312],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.531, 27.781],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.875, 28.312],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.969, 28.844],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.875, 29.344],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.781, 29.875],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.688, 30.344],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.375, 31.344],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.25, 31.844],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.062, 32.312],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.844, 32.781],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.625, 33.219],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.438, 33.688],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.125, 34.094],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.906, 34.531],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.625, 34.906],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.312, 35.312],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41, 35.688],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.719, 36.031],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, 36.375],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 36.656],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.656, 36.969],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.312, 37.25],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.562, 37.688],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.156, 37.906],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.75, 38.062],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.344, 38.25],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.906, 38.375],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.469, 38.438],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.062, 38.531],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.594, 38.562],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.156, 38.625],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.688, 38.625],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.25, 38.562],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.844, 38.531],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.406, 38.406],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33, 38.344],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.188, 38.031],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.812, 37.812],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.5, 37.625],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.188, 37.375],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.812, 37.156],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.531, 36.938],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.25, 36.656],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30, 36.375],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.719, 36.094],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.5, 35.844],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.281, 35.5],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.062, 35.219],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.875, 34.875],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.688, 34.562],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.531, 34.219],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.375, 33.844],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.25, 33.469],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.125, 33.125],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.094, 32.781],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.031, 32.438],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.031, 32.094],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, 31.281],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28, 30.875],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.031, 30.438],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.094, 29.938],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.156, 29.5],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.25, 29.031],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.344, 28.531],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 28],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.625, 27.5],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.75, 26.969],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.938, 26.469],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.156, 25.938],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.344, 25.406],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.594, 24.875],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.844, 24.312],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.062, 23.75],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.312, 23.219],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.531, 22.625],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.844, 22.094],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.406, 20.969],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.688, 20.469],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, 19.938],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.312, 19.469],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.656, 18.969],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33, 18.562],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.312, 18.094],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.688, 17.688],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.031, 17.25],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.344, 16.906],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.719, 16.5],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.125, 16.156],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.438, 15.844],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.188, 15.219],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 14.969],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.25, 14.531],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.594, 14.344],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38, 14.188],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.375, 14.094],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.688, 13.969],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39, 13.906],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.406, 13.844],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 13.844],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, 13.906],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.688, 14],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.969, 14.094],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.25, 14.281],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.562, 14.469],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.812, 14.656],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.125, 14.938],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.344, 15.25],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.594, 15.531],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.812, 15.906],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.188, 16.719],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.344, 17.156],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.5, 17.688],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.625, 18.188],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.75, 18.75],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.812, 19.344],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.906, 20],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44, 20.625],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44, 21.25],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.062, 21.906],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.094, 22.562],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.094, 23.938],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.062, 24.562],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.031, 25.219],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44, 25.906],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.906, 26.594],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.812, 27.25],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.781, 27.906],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.531, 29.219],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.438, 29.875],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.312, 30.531],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.156, 31.125],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.969, 31.75],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.781, 32.406],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.406, 33.594],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.75, 35.281],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.531, 35.812],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.281, 36.344],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.031, 36.844],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.812, 37.344],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.25, 38.219],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 38.625],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.781, 38.969],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.531, 39.375],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.25, 39.719],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.031, 40.031],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.781, 40.25],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.531, 40.5],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.312, 40.562],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.062, 40.625],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.875, 40.656],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.656, 40.75],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.469, 40.781],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.312, 40.812],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.125, 40.844],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37, 40.875],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.875, 40.938],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.781, 40.938],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.719, 40.781],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.656, 40.531],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.438, 40.25],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.125, 39.906],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [35.906, 39.562],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, 38.719],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.219, 37.844],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.906, 36.906],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.625, 35.969],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.375, 35],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.125, 34.094],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.938, 33.219],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.719, 32.375],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.625, 31.562],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.469, 30.75],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.344, 29.969],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 28.531],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 27.812],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 27.188],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 26.531],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 25.344],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 24.844],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.344, 24.281],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.438, 23.781],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.562, 23.344],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.656, 22.906],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.781, 22.469],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.969, 22.125],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.062, 21.719],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.25, 21.438],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.469, 21.125],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.625, 20.844],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.844, 20.625],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.062, 20.375],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.312, 20.188],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, 20],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.781, 19.906],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.031, 19.75],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.281, 19.656],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 19.625],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.812, 19.594],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.094, 19.594],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.312, 19.656],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.594, 19.656],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.875, 19.75],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.125, 19.875],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.344, 20],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.562, 20.188],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.812, 20.406],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.031, 20.625],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.25, 20.938],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.406, 21.25],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.625, 21.562],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 21.906],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 22.312],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.281, 22.688],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.469, 23.094],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.781, 23.531],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.062, 23.969],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.344, 24.438],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.625, 24.875],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.906, 25.344],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.25, 25.844],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.562, 26.312],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.875, 26.812],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.219, 27.312],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.531, 27.781],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.875, 28.312],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.969, 28.844],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.875, 29.344],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.781, 29.875],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.688, 30.344],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.375, 31.344],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.25, 31.844],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.062, 32.312],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.844, 32.781],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.625, 33.219],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.438, 33.688],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.125, 34.094],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.906, 34.531],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.625, 34.906],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.312, 35.312],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41, 35.688],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.719, 36.031],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, 36.375],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 36.656],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.656, 36.969],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.312, 37.25],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.562, 37.688],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.156, 37.906],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.75, 38.062],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.344, 38.25],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.906, 38.375],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.469, 38.438],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.062, 38.531],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.594, 38.562],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.156, 38.625],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.688, 38.625],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.25, 38.562],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.844, 38.531],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.406, 38.406],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33, 38.344],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.188, 38.031],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.812, 37.812],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.5, 37.625],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.188, 37.375],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.812, 37.156],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.531, 36.938],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.25, 36.656],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30, 36.375],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.719, 36.094],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.5, 35.844],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.281, 35.5],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.062, 35.219],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.875, 34.875],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.688, 34.562],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.531, 34.219],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.375, 33.844],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.25, 33.469],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.125, 33.125],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.094, 32.781],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.031, 32.438],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.031, 32.094],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, 31.281],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28, 30.875],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.031, 30.438],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.094, 29.938],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.156, 29.5],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.25, 29.031],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.344, 28.531],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 28],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.625, 27.5],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.75, 26.969],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.938, 26.469],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.156, 25.938],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.344, 25.406],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.594, 24.875],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.844, 24.312],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.062, 23.75],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.312, 23.219],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.531, 22.625],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.844, 22.094],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.406, 20.969],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.688, 20.469],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, 19.938],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.312, 19.469],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.656, 18.969],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33, 18.562],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.312, 18.094],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.688, 17.688],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.031, 17.25],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.344, 16.906],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.719, 16.5],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.125, 16.156],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.438, 15.844],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.188, 15.219],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 14.969],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.25, 14.531],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.594, 14.344],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38, 14.188],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.375, 14.094],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.688, 13.969],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39, 13.906],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.406, 13.844],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 13.844],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, 13.906],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.688, 14],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.969, 14.094],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.25, 14.281],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.562, 14.469],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.812, 14.656],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.125, 14.938],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.344, 15.25],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.594, 15.531],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.812, 15.906],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.188, 16.719],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.344, 17.156],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.5, 17.688],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.625, 18.188],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.75, 18.75],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.812, 19.344],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.906, 20],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44, 20.625],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44, 21.25],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.062, 21.906],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.094, 22.562],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.094, 23.938],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.062, 24.562],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.031, 25.219],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44, 25.906],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.906, 26.594],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.812, 27.25],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.781, 27.906],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.531, 29.219],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.438, 29.875],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.312, 30.531],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.156, 31.125],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.969, 31.75],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.781, 32.406],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.406, 33.594],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.75, 35.281],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.531, 35.812],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.281, 36.344],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.031, 36.844],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.812, 37.344],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.25, 38.219],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 38.625],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.781, 38.969],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.531, 39.375],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.25, 39.719],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.031, 40.031],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.781, 40.25],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.531, 40.5],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.312, 40.562],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.062, 40.625],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.875, 40.656],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.656, 40.75],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.469, 40.781],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.312, 40.812],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.125, 40.844],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37, 40.875],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.875, 40.938],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.781, 40.938],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.719, 40.781],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.656, 40.531],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.438, 40.25],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.125, 39.906],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 2',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [134.287, 16.573],
                                  [46.716, -42.355],
                                  [1.713, -93.43],
                                  [-34.069, -44.929],
                                  [-13.714, -23.999],
                                  [-190.859, 38.001],
                                  [3.64, 49.499],
                                  [9.144, 36.854],
                                  [-23.429, 92],
                                ],
                                o: [
                                  [-74.285, -4.573],
                                  [-46.716, 42.355],
                                  [-1.713, 93.43],
                                  [34.069, 44.929],
                                  [24.857, 37.714],
                                  [103.428, -19.143],
                                  [-3.64, -49.499],
                                  [-25.43, -51.426],
                                  [23.429, -92],
                                ],
                                v: [
                                  [-14.274, -276.55],
                                  [-182.411, -254.1],
                                  [-249.098, -49.725],
                                  [-247.17, 151.58],
                                  [-174.656, 247.177],
                                  [128.919, 348.784],
                                  [315.528, 246.483],
                                  [250.685, 76.792],
                                  [134.809, -133.81],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.144, 16.288],
                                  [46.372, -42.745],
                                  [1.956, -93.191],
                                  [-34.502, -44.883],
                                  [-13.732, -24.199],
                                  [-190.531, 37.851],
                                  [4.224, 49.643],
                                  [8.898, 37.35],
                                  [-23.365, 92],
                                ],
                                o: [
                                  [-74.392, -4.337],
                                  [-46.372, 42.745],
                                  [-1.956, 93.191],
                                  [34.502, 44.883],
                                  [24.829, 37.856],
                                  [103.464, -19.072],
                                  [-4.224, -49.643],
                                  [-25.116, -51.861],
                                  [23.365, -92],
                                ],
                                v: [
                                  [-14.078, -277.926],
                                  [-182.246, -253.26],
                                  [-249.191, -49.241],
                                  [-247.536, 151.206],
                                  [-173.776, 248.271],
                                  [128.604, 348.43],
                                  [315.014, 246.65],
                                  [249.944, 75.736],
                                  [135.13, -134.476],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.001, 16.003],
                                  [46.028, -43.134],
                                  [2.198, -92.952],
                                  [-34.933, -44.838],
                                  [-13.75, -24.398],
                                  [-190.203, 37.701],
                                  [4.807, 49.786],
                                  [8.652, 37.845],
                                  [-23.301, 92],
                                ],
                                o: [
                                  [-74.499, -4.102],
                                  [-46.028, 43.134],
                                  [-2.198, 92.952],
                                  [34.933, 44.838],
                                  [24.8, 37.999],
                                  [103.5, -19.001],
                                  [-4.807, -49.786],
                                  [-24.803, -52.296],
                                  [23.301, -92],
                                ],
                                v: [
                                  [-13.829, -279.338],
                                  [-182.029, -252.488],
                                  [-249.321, -48.759],
                                  [-247.844, 150.844],
                                  [-172.833, 249.303],
                                  [128.268, 348.073],
                                  [314.546, 246.867],
                                  [249.198, 74.692],
                                  [135.459, -135.14],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.859, 15.719],
                                  [45.686, -43.522],
                                  [2.439, -92.714],
                                  [-35.364, -44.793],
                                  [-13.768, -24.597],
                                  [-189.876, 37.552],
                                  [5.389, 49.929],
                                  [8.407, 38.339],
                                  [-23.237, 92],
                                ],
                                o: [
                                  [-74.606, -3.868],
                                  [-45.686, 43.522],
                                  [-2.439, 92.714],
                                  [35.364, 44.793],
                                  [24.772, 38.141],
                                  [103.535, -18.93],
                                  [-5.389, -49.929],
                                  [-24.49, -52.729],
                                  [23.237, -92],
                                ],
                                v: [
                                  [-13.592, -280.785],
                                  [-181.831, -251.668],
                                  [-249.399, -48.312],
                                  [-248.159, 150.495],
                                  [-171.784, 250.318],
                                  [127.886, 347.66],
                                  [314.132, 247.078],
                                  [248.415, 73.607],
                                  [135.796, -135.73],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.717, 15.435],
                                  [45.343, -43.909],
                                  [2.68, -92.477],
                                  [-35.794, -44.747],
                                  [-13.785, -24.796],
                                  [-189.55, 37.403],
                                  [5.969, 50.072],
                                  [8.163, 38.832],
                                  [-23.173, 92],
                                ],
                                o: [
                                  [-74.712, -3.634],
                                  [-45.343, 43.909],
                                  [-2.68, 92.477],
                                  [35.794, 44.747],
                                  [24.743, 38.283],
                                  [103.571, -18.859],
                                  [-5.969, -50.072],
                                  [-24.178, -53.162],
                                  [23.173, -92],
                                ],
                                v: [
                                  [-13.355, -282.222],
                                  [-181.645, -250.801],
                                  [-249.503, -47.919],
                                  [-248.471, 150.202],
                                  [-170.735, 251.326],
                                  [127.484, 347.246],
                                  [313.721, 247.346],
                                  [247.69, 72.491],
                                  [136.183, -136.257],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.576, 15.152],
                                  [45.002, -44.296],
                                  [2.921, -92.24],
                                  [-36.223, -44.702],
                                  [-13.803, -24.994],
                                  [-189.225, 37.255],
                                  [6.548, 50.214],
                                  [7.918, 39.324],
                                  [-23.109, 92],
                                ],
                                o: [
                                  [-74.818, -3.4],
                                  [-45.002, 44.296],
                                  [-2.921, 92.24],
                                  [36.223, 44.702],
                                  [24.715, 38.424],
                                  [103.606, -18.788],
                                  [-6.548, -50.214],
                                  [-23.867, -53.593],
                                  [23.109, -92],
                                ],
                                v: [
                                  [-13.163, -283.632],
                                  [-181.459, -250.002],
                                  [-249.546, -47.516],
                                  [-248.737, 149.92],
                                  [-169.635, 252.314],
                                  [127.069, 346.831],
                                  [313.354, 247.62],
                                  [246.918, 71.379],
                                  [136.639, -136.731],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.435, 14.869],
                                  [44.661, -44.681],
                                  [3.161, -92.003],
                                  [-36.651, -44.657],
                                  [-13.821, -25.191],
                                  [-188.9, 37.106],
                                  [7.127, 50.356],
                                  [7.675, 39.814],
                                  [-23.046, 92],
                                ],
                                o: [
                                  [-74.924, -3.167],
                                  [-44.661, 44.681],
                                  [-3.161, 92.003],
                                  [36.651, 44.657],
                                  [24.687, 38.565],
                                  [103.641, -18.717],
                                  [-7.127, -50.356],
                                  [-23.556, -54.024],
                                  [23.046, -92],
                                ],
                                v: [
                                  [-12.992, -284.919],
                                  [-181.293, -249.158],
                                  [-249.581, -47.113],
                                  [-249.002, 149.641],
                                  [-168.474, 253.253],
                                  [126.588, 346.35],
                                  [312.979, 247.899],
                                  [246.152, 70.238],
                                  [137.049, -137.185],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.294, 14.588],
                                  [44.321, -45.066],
                                  [3.401, -91.767],
                                  [-37.078, -44.612],
                                  [-13.838, -25.389],
                                  [-188.576, 36.958],
                                  [7.703, 50.498],
                                  [7.432, 40.304],
                                  [-22.982, 92],
                                ],
                                o: [
                                  [-75.03, -2.935],
                                  [-44.321, 45.066],
                                  [-3.401, 91.767],
                                  [37.078, 44.612],
                                  [24.659, 38.706],
                                  [103.677, -18.647],
                                  [-7.703, -50.498],
                                  [-23.246, -54.454],
                                  [22.982, -92],
                                ],
                                v: [
                                  [-12.864, -286.189],
                                  [-181.128, -248.319],
                                  [-249.597, -46.802],
                                  [-249.274, 149.362],
                                  [-167.272, 254.173],
                                  [126.105, 345.879],
                                  [312.669, 248.226],
                                  [245.34, 69.047],
                                  [137.57, -137.535],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.153, 14.306],
                                  [43.982, -45.45],
                                  [3.64, -91.532],
                                  [-37.504, -44.568],
                                  [-13.856, -25.586],
                                  [-188.252, 36.811],
                                  [8.279, 50.64],
                                  [7.189, 40.793],
                                  [-22.919, 92],
                                ],
                                o: [
                                  [-75.135, -2.703],
                                  [-43.982, 45.45],
                                  [-3.64, 91.532],
                                  [37.504, 44.568],
                                  [24.631, 38.847],
                                  [103.712, -18.577],
                                  [-8.279, -50.64],
                                  [-22.937, -54.883],
                                  [22.919, -92],
                                ],
                                v: [
                                  [-12.799, -287.337],
                                  [-180.921, -247.498],
                                  [-249.605, -46.428],
                                  [-249.494, 149.094],
                                  [-166.021, 255.033],
                                  [125.557, 345.395],
                                  [312.342, 248.548],
                                  [244.596, 67.881],
                                  [138.035, -137.866],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.013, 14.026],
                                  [43.644, -45.834],
                                  [3.878, -91.297],
                                  [-37.93, -44.523],
                                  [-13.873, -25.782],
                                  [-187.93, 36.664],
                                  [8.854, 50.781],
                                  [6.947, 41.28],
                                  [-22.856, 92],
                                ],
                                o: [
                                  [-75.24, -2.471],
                                  [-43.644, 45.834],
                                  [-3.878, 91.297],
                                  [37.93, 44.523],
                                  [24.603, 38.987],
                                  [103.747, -18.506],
                                  [-8.854, -50.781],
                                  [-22.628, -55.311],
                                  [22.856, -92],
                                ],
                                v: [
                                  [-12.787, -288.48],
                                  [-180.776, -246.622],
                                  [-249.604, -46.093],
                                  [-249.671, 148.889],
                                  [-164.772, 255.886],
                                  [124.995, 344.921],
                                  [312.131, 248.877],
                                  [243.796, 66.718],
                                  [138.569, -138.199],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.873, 13.746],
                                  [43.306, -46.216],
                                  [4.116, -91.062],
                                  [-38.354, -44.478],
                                  [-13.891, -25.978],
                                  [-187.607, 36.516],
                                  [9.427, 50.922],
                                  [6.706, 41.767],
                                  [-22.793, 92],
                                ],
                                o: [
                                  [-75.345, -2.24],
                                  [-43.306, 46.216],
                                  [-4.116, 91.062],
                                  [38.354, 44.478],
                                  [24.575, 39.127],
                                  [103.782, -18.436],
                                  [-9.427, -50.922],
                                  [-22.32, -55.738],
                                  [22.793, -92],
                                ],
                                v: [
                                  [-12.775, -289.493],
                                  [-180.59, -245.763],
                                  [-249.583, -45.795],
                                  [-249.9, 148.672],
                                  [-163.526, 256.668],
                                  [124.429, 344.382],
                                  [311.851, 249.213],
                                  [243.003, 65.529],
                                  [139.108, -138.42],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.733, 13.466],
                                  [42.969, -46.598],
                                  [4.354, -90.828],
                                  [-38.778, -44.434],
                                  [-13.908, -26.174],
                                  [-187.286, 36.37],
                                  [9.999, 51.062],
                                  [6.465, 42.252],
                                  [-22.73, 92],
                                ],
                                o: [
                                  [-75.45, -2.01],
                                  [-42.969, 46.598],
                                  [-4.354, 90.828],
                                  [38.778, 44.434],
                                  [24.547, 39.267],
                                  [103.817, -18.367],
                                  [-9.999, -51.062],
                                  [-22.013, -56.164],
                                  [22.73, -92],
                                ],
                                v: [
                                  [-12.814, -290.502],
                                  [-180.455, -244.912],
                                  [-249.514, -45.494],
                                  [-250.076, 148.526],
                                  [-162.18, 257.506],
                                  [123.849, 343.843],
                                  [311.636, 249.596],
                                  [242.175, 64.302],
                                  [139.694, -138.623],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.594, 13.187],
                                  [42.632, -46.979],
                                  [4.591, -90.594],
                                  [-39.2, -44.389],
                                  [-13.926, -26.369],
                                  [-186.965, 36.223],
                                  [10.57, 51.203],
                                  [6.224, 42.737],
                                  [-22.667, 92],
                                ],
                                o: [
                                  [-75.555, -1.78],
                                  [-42.632, 46.979],
                                  [-4.591, 90.594],
                                  [39.2, 44.389],
                                  [24.519, 39.406],
                                  [103.852, -18.297],
                                  [-10.57, -51.203],
                                  [-21.706, -56.589],
                                  [22.667, -92],
                                ],
                                v: [
                                  [-12.864, -291.383],
                                  [-180.278, -244.067],
                                  [-249.476, -45.178],
                                  [-250.264, 148.368],
                                  [-160.876, 258.223],
                                  [123.203, 343.364],
                                  [311.465, 249.936],
                                  [241.395, 63.091],
                                  [140.296, -138.778],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.454, 12.909],
                                  [42.297, -47.359],
                                  [4.827, -90.361],
                                  [-39.622, -44.345],
                                  [-13.943, -26.564],
                                  [-186.645, 36.077],
                                  [11.139, 51.343],
                                  [5.984, 43.221],
                                  [-22.605, 92],
                                ],
                                o: [
                                  [-75.659, -1.55],
                                  [-42.297, 47.359],
                                  [-4.827, 90.361],
                                  [39.622, 44.345],
                                  [24.491, 39.546],
                                  [103.886, -18.227],
                                  [-11.139, -51.343],
                                  [-21.4, -57.014],
                                  [22.605, -92],
                                ],
                                v: [
                                  [-12.965, -292.21],
                                  [-180.099, -243.18],
                                  [-249.418, -44.909],
                                  [-250.402, 148.217],
                                  [-159.486, 258.944],
                                  [122.553, 342.822],
                                  [311.298, 250.335],
                                  [240.531, 61.831],
                                  [140.942, -138.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.316, 12.631],
                                  [41.962, -47.738],
                                  [5.063, -90.129],
                                  [-40.043, -44.301],
                                  [-13.961, -26.758],
                                  [-186.326, 35.931],
                                  [11.708, 51.482],
                                  [5.745, 43.703],
                                  [-22.542, 92],
                                ],
                                o: [
                                  [-75.763, -1.321],
                                  [-41.962, 47.738],
                                  [-5.063, 90.129],
                                  [40.043, 44.301],
                                  [24.463, 39.684],
                                  [103.921, -18.158],
                                  [-11.708, -51.482],
                                  [-21.094, -57.437],
                                  [22.542, -92],
                                ],
                                v: [
                                  [-13.117, -293.036],
                                  [-179.942, -242.35],
                                  [-249.312, -44.624],
                                  [-250.54, 148.076],
                                  [-158.149, 259.646],
                                  [121.888, 342.29],
                                  [311.186, 250.731],
                                  [239.712, 60.599],
                                  [141.543, -138.926],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.177, 12.354],
                                  [41.627, -48.116],
                                  [5.299, -89.897],
                                  [-40.463, -44.256],
                                  [-13.978, -26.952],
                                  [-186.007, 35.786],
                                  [12.275, 51.622],
                                  [5.506, 44.184],
                                  [-22.48, 92],
                                ],
                                o: [
                                  [-75.867, -1.092],
                                  [-41.627, 48.116],
                                  [-5.299, 89.897],
                                  [40.463, 44.256],
                                  [24.435, 39.823],
                                  [103.956, -18.089],
                                  [-12.275, -51.622],
                                  [-20.79, -57.86],
                                  [22.48, -92],
                                ],
                                v: [
                                  [-13.28, -293.735],
                                  [-179.77, -241.477],
                                  [-249.247, -44.372],
                                  [-250.69, 147.981],
                                  [-156.766, 260.342],
                                  [121.219, 341.745],
                                  [311.068, 251.074],
                                  [238.851, 59.369],
                                  [142.199, -138.96],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.039, 12.078],
                                  [41.294, -48.494],
                                  [5.534, -89.665],
                                  [-40.882, -44.212],
                                  [-13.995, -27.146],
                                  [-185.689, 35.641],
                                  [12.841, 51.761],
                                  [5.267, 44.665],
                                  [-22.418, 92],
                                ],
                                o: [
                                  [-75.971, -0.864],
                                  [-41.294, 48.494],
                                  [-5.534, 89.665],
                                  [40.882, 44.212],
                                  [24.408, 39.961],
                                  [103.99, -18.019],
                                  [-12.841, -51.761],
                                  [-20.486, -58.281],
                                  [22.417, -92],
                                ],
                                v: [
                                  [-13.493, -294.435],
                                  [-179.62, -240.612],
                                  [-249.112, -44.064],
                                  [-250.792, 147.881],
                                  [-155.374, 260.93],
                                  [120.483, 341.161],
                                  [310.954, 251.476],
                                  [237.996, 58.116],
                                  [142.91, -138.937],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.901, 11.802],
                                  [40.961, -48.871],
                                  [5.768, -89.434],
                                  [-41.3, -44.168],
                                  [-14.012, -27.339],
                                  [-185.372, 35.496],
                                  [13.405, 51.9],
                                  [5.029, 45.144],
                                  [-22.355, 92],
                                ],
                                o: [
                                  [-76.074, -0.637],
                                  [-40.961, 48.871],
                                  [-5.768, 89.434],
                                  [41.3, 44.168],
                                  [24.38, 40.099],
                                  [104.025, -17.951],
                                  [-13.406, -51.9],
                                  [-20.182, -58.702],
                                  [22.355, -92],
                                ],
                                v: [
                                  [-13.705, -295.022],
                                  [-179.404, -239.755],
                                  [-248.98, -43.839],
                                  [-250.945, 147.776],
                                  [-153.949, 261.563],
                                  [119.793, 340.626],
                                  [310.896, 251.876],
                                  [237.161, 56.866],
                                  [143.575, -138.872],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.763, 11.527],
                                  [40.629, -49.246],
                                  [6.002, -89.204],
                                  [-41.718, -44.125],
                                  [-14.03, -27.531],
                                  [-185.056, 35.351],
                                  [13.969, 52.038],
                                  [4.792, 45.623],
                                  [-22.293, 92],
                                ],
                                o: [
                                  [-76.178, -0.409],
                                  [-40.629, 49.246],
                                  [-6.002, 89.204],
                                  [41.717, 44.125],
                                  [24.353, 40.237],
                                  [104.059, -17.882],
                                  [-13.969, -52.038],
                                  [-19.879, -59.122],
                                  [22.294, -92],
                                ],
                                v: [
                                  [-13.93, -295.611],
                                  [-179.259, -238.905],
                                  [-248.877, -43.545],
                                  [-251.049, 147.727],
                                  [-152.577, 262.151],
                                  [119.036, 340.103],
                                  [310.894, 252.236],
                                  [236.271, 55.593],
                                  [144.294, -138.801],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.626, 11.252],
                                  [40.298, -49.622],
                                  [6.236, -88.973],
                                  [-42.134, -44.081],
                                  [-14.047, -27.724],
                                  [-184.74, 35.207],
                                  [14.531, 52.176],
                                  [4.555, 46.1],
                                  [-22.232, 92],
                                ],
                                o: [
                                  [-76.281, -0.183],
                                  [-40.298, 49.622],
                                  [-6.236, 88.973],
                                  [42.134, 44.081],
                                  [24.325, 40.374],
                                  [104.094, -17.813],
                                  [-14.531, -52.176],
                                  [-19.577, -59.541],
                                  [22.232, -92],
                                ],
                                v: [
                                  [-14.203, -296.14],
                                  [-179.048, -238.063],
                                  [-248.729, -43.294],
                                  [-251.118, 147.684],
                                  [-151.159, 262.672],
                                  [118.274, 339.617],
                                  [310.836, 252.607],
                                  [235.388, 54.324],
                                  [145.004, -138.676],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.489, 10.978],
                                  [39.967, -49.996],
                                  [6.469, -88.744],
                                  [-42.549, -44.037],
                                  [-14.064, -27.916],
                                  [-184.424, 35.063],
                                  [15.092, 52.314],
                                  [4.318, 46.576],
                                  [-22.17, 92],
                                ],
                                o: [
                                  [-76.383, 0.043],
                                  [-39.967, 49.996],
                                  [-6.469, 88.744],
                                  [42.549, 44.037],
                                  [24.298, 40.511],
                                  [104.128, -17.744],
                                  [-15.092, -52.314],
                                  [-19.276, -59.959],
                                  [22.17, -92],
                                ],
                                v: [
                                  [-14.49, -296.658],
                                  [-178.859, -237.228],
                                  [-248.572, -43.023],
                                  [-251.239, 147.621],
                                  [-149.733, 263.187],
                                  [117.508, 339.093],
                                  [310.834, 253.026],
                                  [234.463, 53.07],
                                  [145.78, -138.546],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.352, 10.704],
                                  [39.637, -50.369],
                                  [6.701, -88.515],
                                  [-42.964, -43.993],
                                  [-14.081, -28.107],
                                  [-184.11, 34.92],
                                  [15.652, 52.452],
                                  [4.082, 47.051],
                                  [-22.108, 92],
                                ],
                                o: [
                                  [-76.486, 0.269],
                                  [-39.637, 50.369],
                                  [-6.701, 88.515],
                                  [42.964, 43.993],
                                  [24.27, 40.648],
                                  [104.162, -17.676],
                                  [-15.652, -52.452],
                                  [-18.975, -60.376],
                                  [22.108, -92],
                                ],
                                v: [
                                  [-14.776, -297.082],
                                  [-178.652, -236.387],
                                  [-248.407, -42.745],
                                  [-251.299, 147.626],
                                  [-148.324, 263.647],
                                  [116.724, 338.554],
                                  [310.888, 253.394],
                                  [233.546, 51.831],
                                  [146.547, -138.314],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.216, 10.431],
                                  [39.308, -50.742],
                                  [6.933, -88.286],
                                  [-43.377, -43.95],
                                  [-14.098, -28.298],
                                  [-183.796, 34.776],
                                  [16.211, 52.589],
                                  [3.847, 47.526],
                                  [-22.047, 92],
                                ],
                                o: [
                                  [-76.588, 0.494],
                                  [-39.308, 50.742],
                                  [-6.933, 88.286],
                                  [43.377, 43.95],
                                  [24.243, 40.784],
                                  [104.196, -17.608],
                                  [-16.211, -52.589],
                                  [-18.675, -60.792],
                                  [22.047, -92],
                                ],
                                v: [
                                  [-15.11, -297.51],
                                  [-178.404, -235.567],
                                  [-248.234, -42.495],
                                  [-251.375, 147.609],
                                  [-146.918, 264.102],
                                  [115.887, 338.092],
                                  [310.95, 253.775],
                                  [232.624, 50.559],
                                  [147.316, -138.141],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.08, 10.159],
                                  [38.979, -51.114],
                                  [7.165, -88.058],
                                  [-43.79, -43.907],
                                  [-14.115, -28.489],
                                  [-183.483, 34.633],
                                  [16.768, 52.726],
                                  [3.612, 47.999],
                                  [-21.986, 92],
                                ],
                                o: [
                                  [-76.69, 0.719],
                                  [-38.979, 51.114],
                                  [-7.165, 88.058],
                                  [43.79, 43.907],
                                  [24.216, 40.92],
                                  [104.23, -17.54],
                                  [-16.768, -52.726],
                                  [-18.375, -61.207],
                                  [21.986, -92],
                                ],
                                v: [
                                  [-15.458, -297.868],
                                  [-178.213, -234.74],
                                  [-248.101, -42.237],
                                  [-251.453, 147.596],
                                  [-145.517, 264.518],
                                  [115.08, 337.614],
                                  [311.021, 254.106],
                                  [231.674, 49.302],
                                  [148.089, -137.867],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.944, 9.887],
                                  [38.652, -51.485],
                                  [7.396, -87.831],
                                  [-44.202, -43.863],
                                  [-14.132, -28.679],
                                  [-183.17, 34.491],
                                  [17.324, 52.863],
                                  [3.378, 48.471],
                                  [-21.925, 92],
                                ],
                                o: [
                                  [-76.792, 0.943],
                                  [-38.652, 51.485],
                                  [-7.396, 87.831],
                                  [44.202, 43.863],
                                  [24.189, 41.056],
                                  [104.264, -17.472],
                                  [-17.324, -52.863],
                                  [-18.076, -61.622],
                                  [21.925, -92],
                                ],
                                v: [
                                  [-15.806, -298.244],
                                  [-177.953, -233.934],
                                  [-247.926, -41.957],
                                  [-251.547, 147.622],
                                  [-144.121, 264.915],
                                  [114.282, 337.136],
                                  [311.085, 254.485],
                                  [230.718, 48.046],
                                  [148.851, -137.59],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.808, 9.616],
                                  [38.325, -51.855],
                                  [7.626, -87.604],
                                  [-44.613, -43.82],
                                  [-14.149, -28.869],
                                  [-182.859, 34.349],
                                  [17.879, 52.999],
                                  [3.144, 48.942],
                                  [-21.864, 92],
                                ],
                                o: [
                                  [-76.894, 1.167],
                                  [-38.325, 51.855],
                                  [-7.626, 87.604],
                                  [44.613, 43.82],
                                  [24.162, 41.192],
                                  [104.298, -17.404],
                                  [-17.879, -52.999],
                                  [-17.778, -62.035],
                                  [21.864, -92],
                                ],
                                v: [
                                  [-16.215, -298.517],
                                  [-177.749, -233.122],
                                  [-247.728, -41.669],
                                  [-251.582, 147.652],
                                  [-142.728, 265.273],
                                  [113.464, 336.72],
                                  [311.222, 254.829],
                                  [229.77, 46.772],
                                  [149.678, -137.311],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.673, 9.346],
                                  [37.998, -52.225],
                                  [7.856, -87.377],
                                  [-45.023, -43.777],
                                  [-14.166, -29.058],
                                  [-182.547, 34.206],
                                  [18.433, 53.135],
                                  [2.911, 49.412],
                                  [-21.803, 92],
                                ],
                                o: [
                                  [-76.995, 1.39],
                                  [-37.998, 52.225],
                                  [-7.856, 87.377],
                                  [45.023, 43.777],
                                  [24.135, 41.327],
                                  [104.332, -17.336],
                                  [-18.433, -53.135],
                                  [-17.48, -62.448],
                                  [21.803, -92],
                                ],
                                v: [
                                  [-16.625, -298.832],
                                  [-177.504, -232.302],
                                  [-247.552, -41.372],
                                  [-251.667, 147.719],
                                  [-141.339, 265.628],
                                  [112.594, 336.254],
                                  [311.354, 255.173],
                                  [228.796, 45.547],
                                  [150.494, -136.995],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.538, 9.076],
                                  [37.673, -52.593],
                                  [8.086, -87.151],
                                  [-45.432, -43.734],
                                  [-14.183, -29.247],
                                  [-182.237, 34.065],
                                  [18.986, 53.271],
                                  [2.678, 49.881],
                                  [-21.742, 92],
                                ],
                                o: [
                                  [-77.097, 1.613],
                                  [-37.673, 52.593],
                                  [-8.086, 87.151],
                                  [45.432, 43.734],
                                  [24.108, 41.462],
                                  [104.366, -17.269],
                                  [-18.986, -53.271],
                                  [-17.183, -62.86],
                                  [21.742, -92],
                                ],
                                v: [
                                  [-17.001, -299.058],
                                  [-177.251, -231.503],
                                  [-247.353, -41.101],
                                  [-251.706, 147.773],
                                  [-140.003, 265.93],
                                  [111.752, 335.899],
                                  [311.51, 255.484],
                                  [227.815, 44.352],
                                  [151.311, -136.678],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.403, 8.806],
                                  [37.348, -52.961],
                                  [8.315, -86.925],
                                  [-45.84, -43.691],
                                  [-14.2, -29.436],
                                  [-181.927, 33.923],
                                  [19.537, 53.407],
                                  [2.445, 50.349],
                                  [-21.681, 92],
                                ],
                                o: [
                                  [-77.198, 1.835],
                                  [-37.348, 52.961],
                                  [-8.315, 86.925],
                                  [45.84, 43.691],
                                  [24.081, 41.597],
                                  [104.399, -17.202],
                                  [-19.537, -53.407],
                                  [-16.887, -63.27],
                                  [21.681, -92],
                                ],
                                v: [
                                  [-17.473, -299.293],
                                  [-176.943, -230.711],
                                  [-247.163, -40.82],
                                  [-251.749, 147.83],
                                  [-138.622, 266.182],
                                  [110.919, 335.495],
                                  [311.71, 255.843],
                                  [226.781, 43.11],
                                  [152.116, -136.311],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.269, 8.538],
                                  [37.023, -53.328],
                                  [8.543, -86.7],
                                  [-46.248, -43.648],
                                  [-14.216, -29.624],
                                  [-181.618, 33.782],
                                  [20.087, 53.542],
                                  [2.214, 50.816],
                                  [-21.621, 92],
                                ],
                                o: [
                                  [-77.298, 2.056],
                                  [-37.023, 53.328],
                                  [-8.543, 86.7],
                                  [46.248, 43.648],
                                  [24.054, 41.731],
                                  [104.433, -17.134],
                                  [-20.087, -53.542],
                                  [-16.591, -63.68],
                                  [21.621, -92],
                                ],
                                v: [
                                  [-17.913, -299.491],
                                  [-176.675, -229.958],
                                  [-246.98, -40.515],
                                  [-251.793, 147.89],
                                  [-137.293, 266.431],
                                  [110.066, 335.138],
                                  [311.873, 256.108],
                                  [225.77, 41.883],
                                  [152.938, -135.895],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.135, 8.269],
                                  [36.7, -53.695],
                                  [8.771, -86.476],
                                  [-46.654, -43.605],
                                  [-14.233, -29.811],
                                  [-181.31, 33.641],
                                  [20.636, 53.677],
                                  [1.982, 51.282],
                                  [-21.561, 92],
                                ],
                                o: [
                                  [-77.399, 2.278],
                                  [-36.7, 53.695],
                                  [-8.771, 86.476],
                                  [46.654, 43.605],
                                  [24.027, 41.865],
                                  [104.466, -17.067],
                                  [-20.636, -53.677],
                                  [-16.296, -64.089],
                                  [21.561, -92],
                                ],
                                v: [
                                  [-18.353, -299.684],
                                  [-176.365, -229.211],
                                  [-246.79, -40.201],
                                  [-251.856, 147.983],
                                  [-135.967, 266.646],
                                  [109.223, 334.826],
                                  [312.096, 256.437],
                                  [224.751, 40.718],
                                  [153.794, -135.541],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.001, 8.002],
                                  [36.377, -54.06],
                                  [8.998, -86.252],
                                  [-47.06, -43.563],
                                  [-14.25, -29.999],
                                  [-181.002, 33.501],
                                  [21.184, 53.812],
                                  [1.752, 51.747],
                                  [-21.5, 92],
                                ],
                                o: [
                                  [-77.499, 2.498],
                                  [-36.377, 54.06],
                                  [-8.998, 86.252],
                                  [47.06, 43.563],
                                  [24, 41.999],
                                  [104.5, -17],
                                  [-21.184, -53.812],
                                  [-16.002, -64.497],
                                  [21.5, -92],
                                ],
                                v: [
                                  [-18.856, -299.841],
                                  [-176.046, -228.455],
                                  [-246.609, -39.877],
                                  [-251.86, 148.109],
                                  [-134.646, 266.858],
                                  [108.406, 334.53],
                                  [312.346, 256.704],
                                  [223.694, 39.521],
                                  [154.604, -135.139],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.867, 7.735],
                                  [36.055, -54.424],
                                  [9.225, -86.028],
                                  [-47.464, -43.52],
                                  [-14.267, -30.186],
                                  [-180.695, 33.361],
                                  [21.73, 53.946],
                                  [1.521, 52.211],
                                  [-21.44, 92],
                                ],
                                o: [
                                  [-77.599, 2.719],
                                  [-36.055, 54.424],
                                  [-9.225, 86.028],
                                  [47.464, 43.52],
                                  [23.974, 42.133],
                                  [104.533, -16.934],
                                  [-21.73, -53.946],
                                  [-15.708, -64.904],
                                  [21.44, -92],
                                ],
                                v: [
                                  [-19.313, -300.01],
                                  [-175.718, -227.72],
                                  [-246.423, -39.544],
                                  [-251.913, 148.189],
                                  [-133.39, 267.021],
                                  [107.536, 334.279],
                                  [312.64, 256.988],
                                  [222.646, 38.387],
                                  [155.448, -134.705],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.734, 7.469],
                                  [35.734, -54.788],
                                  [9.452, -85.805],
                                  [-47.868, -43.478],
                                  [-14.283, -30.372],
                                  [-180.389, 33.221],
                                  [22.275, 54.08],
                                  [1.292, 52.673],
                                  [-21.38, 92],
                                ],
                                o: [
                                  [-77.699, 2.938],
                                  [-35.734, 54.788],
                                  [-9.452, 85.805],
                                  [47.868, 43.478],
                                  [23.947, 42.266],
                                  [104.566, -16.867],
                                  [-22.275, -54.08],
                                  [-15.415, -65.31],
                                  [21.38, -92],
                                ],
                                v: [
                                  [-19.833, -300.176],
                                  [-175.395, -226.975],
                                  [-246.246, -39.233],
                                  [-251.952, 148.3],
                                  [-132.122, 267.136],
                                  [106.708, 334.026],
                                  [312.901, 257.228],
                                  [221.559, 37.22],
                                  [156.308, -134.272],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.601, 7.203],
                                  [35.413, -55.151],
                                  [9.678, -85.582],
                                  [-48.271, -43.435],
                                  [-14.3, -30.558],
                                  [-180.083, 33.081],
                                  [22.819, 54.214],
                                  [1.062, 53.135],
                                  [-21.321, 92],
                                ],
                                o: [
                                  [-77.799, 3.158],
                                  [-35.413, 55.151],
                                  [-9.678, 85.582],
                                  [48.271, 43.435],
                                  [23.92, 42.399],
                                  [104.6, -16.801],
                                  [-22.819, -54.214],
                                  [-15.123, -65.716],
                                  [21.321, -92],
                                ],
                                v: [
                                  [-20.355, -300.278],
                                  [-175.062, -226.265],
                                  [-246.079, -38.911],
                                  [-251.964, 148.458],
                                  [-130.874, 267.267],
                                  [105.874, 333.835],
                                  [313.222, 257.439],
                                  [220.481, 36.098],
                                  [157.153, -133.855],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.469, 6.938],
                                  [35.093, -55.513],
                                  [9.903, -85.36],
                                  [-48.673, -43.393],
                                  [-14.316, -30.744],
                                  [-179.778, 32.942],
                                  [23.362, 54.347],
                                  [0.834, 53.596],
                                  [-21.261, 92],
                                ],
                                o: [
                                  [-77.898, 3.376],
                                  [-35.093, 55.513],
                                  [-9.903, 85.36],
                                  [48.673, 43.393],
                                  [23.894, 42.531],
                                  [104.633, -16.734],
                                  [-23.362, -54.347],
                                  [-14.831, -66.12],
                                  [21.261, -92],
                                ],
                                v: [
                                  [-20.894, -300.425],
                                  [-174.671, -225.577],
                                  [-245.923, -38.533],
                                  [-252.025, 148.553],
                                  [-129.658, 267.396],
                                  [105.033, 333.643],
                                  [313.574, 257.683],
                                  [219.396, 35.007],
                                  [158.045, -133.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.337, 6.673],
                                  [34.774, -55.875],
                                  [10.128, -85.139],
                                  [-49.074, -43.351],
                                  [-14.333, -30.929],
                                  [-179.474, 32.803],
                                  [23.904, 54.48],
                                  [0.605, 54.056],
                                  [-21.201, 92],
                                ],
                                o: [
                                  [-77.998, 3.595],
                                  [-34.774, 55.875],
                                  [-10.128, 85.139],
                                  [49.074, 43.351],
                                  [23.867, 42.664],
                                  [104.666, -16.668],
                                  [-23.904, -54.48],
                                  [-14.54, -66.524],
                                  [21.201, -92],
                                ],
                                v: [
                                  [-21.434, -300.541],
                                  [-174.315, -224.922],
                                  [-245.763, -38.192],
                                  [-252.026, 148.677],
                                  [-128.463, 267.432],
                                  [104.187, 333.512],
                                  [313.924, 257.853],
                                  [218.273, 33.914],
                                  [158.909, -133.039],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.204, 6.409],
                                  [34.456, -56.235],
                                  [10.352, -84.918],
                                  [-49.474, -43.309],
                                  [-14.349, -31.114],
                                  [-179.17, 32.665],
                                  [24.444, 54.613],
                                  [0.378, 54.514],
                                  [-21.142, 92],
                                ],
                                o: [
                                  [-78.097, 3.813],
                                  [-34.456, 56.235],
                                  [-10.352, 84.918],
                                  [49.474, 43.309],
                                  [23.841, 42.795],
                                  [104.699, -16.602],
                                  [-24.444, -54.613],
                                  [-14.25, -66.926],
                                  [21.142, -92],
                                ],
                                v: [
                                  [-21.992, -300.672],
                                  [-173.917, -224.256],
                                  [-245.615, -37.84],
                                  [-252.092, 148.846],
                                  [-127.316, 267.486],
                                  [103.381, 333.408],
                                  [314.29, 258.044],
                                  [217.159, 32.836],
                                  [159.819, -132.612],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.073, 6.146],
                                  [34.138, -56.595],
                                  [10.576, -84.697],
                                  [-49.873, -43.267],
                                  [-14.366, -31.298],
                                  [-178.867, 32.526],
                                  [24.983, 54.745],
                                  [0.151, 54.972],
                                  [-21.083, 92],
                                ],
                                o: [
                                  [-78.195, 4.03],
                                  [-34.138, 56.595],
                                  [-10.576, 84.697],
                                  [49.873, 43.267],
                                  [23.815, 42.927],
                                  [104.732, -16.536],
                                  [-24.983, -54.745],
                                  [-13.96, -67.328],
                                  [21.083, -92],
                                ],
                                v: [
                                  [-22.569, -300.774],
                                  [-173.507, -223.593],
                                  [-245.524, -37.479],
                                  [-252.143, 148.967],
                                  [-126.172, 267.54],
                                  [102.569, 333.381],
                                  [314.672, 258.255],
                                  [216.008, 31.833],
                                  [160.685, -132.204],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.941, 5.883],
                                  [33.821, -56.954],
                                  [10.8, -84.477],
                                  [-50.271, -43.225],
                                  [-14.382, -31.482],
                                  [-178.565, 32.389],
                                  [25.521, 54.878],
                                  [-0.076, 55.429],
                                  [-21.024, 92],
                                ],
                                o: [
                                  [-78.294, 4.247],
                                  [-33.821, 56.954],
                                  [-10.8, 84.477],
                                  [50.271, 43.225],
                                  [23.788, 43.059],
                                  [104.765, -16.471],
                                  [-25.521, -54.878],
                                  [-13.671, -67.729],
                                  [21.024, -92],
                                ],
                                v: [
                                  [-23.194, -300.876],
                                  [-173.039, -222.934],
                                  [-245.402, -37.09],
                                  [-252.152, 149.116],
                                  [-125.048, 267.549],
                                  [101.767, 333.381],
                                  [315.054, 258.425],
                                  [214.848, 30.828],
                                  [161.598, -131.798],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.81, 5.621],
                                  [33.505, -57.312],
                                  [11.022, -84.257],
                                  [-50.669, -43.183],
                                  [-14.399, -31.666],
                                  [-178.264, 32.251],
                                  [26.058, 55.01],
                                  [-0.302, 55.884],
                                  [-20.965, 92],
                                ],
                                o: [
                                  [-78.392, 4.463],
                                  [-33.505, 57.312],
                                  [-11.022, 84.257],
                                  [50.669, 43.183],
                                  [23.762, 43.19],
                                  [104.797, -16.405],
                                  [-26.058, -55.01],
                                  [-13.383, -68.129],
                                  [20.965, -92],
                                ],
                                v: [
                                  [-23.793, -300.968],
                                  [-172.62, -222.351],
                                  [-245.293, -36.709],
                                  [-252.207, 149.29],
                                  [-123.989, 267.496],
                                  [100.988, 333.396],
                                  [315.407, 258.59],
                                  [213.698, 29.819],
                                  [162.529, -131.412],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.68, 5.359],
                                  [33.189, -57.669],
                                  [11.245, -84.038],
                                  [-51.065, -43.142],
                                  [-14.415, -31.849],
                                  [-177.963, 32.113],
                                  [26.593, 55.141],
                                  [-0.528, 56.339],
                                  [-20.906, 92],
                                ],
                                o: [
                                  [-78.49, 4.679],
                                  [-33.189, 57.669],
                                  [-11.245, 84.038],
                                  [51.065, 43.142],
                                  [23.736, 43.321],
                                  [104.83, -16.34],
                                  [-26.593, -55.141],
                                  [-13.095, -68.527],
                                  [20.906, -92],
                                ],
                                v: [
                                  [-24.439, -301.123],
                                  [-172.142, -221.726],
                                  [-245.198, -36.318],
                                  [-252.248, 149.417],
                                  [-122.952, 267.444],
                                  [100.219, 333.454],
                                  [315.806, 258.76],
                                  [212.511, 28.885],
                                  [163.488, -131.002],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.549, 5.098],
                                  [32.874, -58.025],
                                  [11.467, -83.82],
                                  [-51.461, -43.1],
                                  [-14.431, -32.031],
                                  [-177.663, 31.977],
                                  [27.127, 55.273],
                                  [-0.753, 56.792],
                                  [-20.847, 92],
                                ],
                                o: [
                                  [-78.588, 4.894],
                                  [-32.874, 58.025],
                                  [-11.467, 83.82],
                                  [51.461, 43.1],
                                  [23.71, 43.451],
                                  [104.863, -16.274],
                                  [-27.128, -55.273],
                                  [-12.808, -68.926],
                                  [20.847, -92],
                                ],
                                v: [
                                  [-25.06, -301.253],
                                  [-171.696, -221.148],
                                  [-245.101, -35.962],
                                  [-252.263, 149.587],
                                  [-121.981, 267.33],
                                  [99.489, 333.571],
                                  [316.24, 258.953],
                                  [211.334, 27.964],
                                  [164.421, -130.657],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.419, 4.838],
                                  [32.56, -58.381],
                                  [11.688, -83.602],
                                  [-51.855, -43.059],
                                  [-14.448, -32.214],
                                  [-177.363, 31.84],
                                  [27.661, 55.404],
                                  [-0.978, 57.245],
                                  [-20.788, 92],
                                ],
                                o: [
                                  [-78.686, 5.109],
                                  [-32.56, 58.381],
                                  [-11.688, 83.602],
                                  [51.855, 43.058],
                                  [23.684, 43.581],
                                  [104.895, -16.209],
                                  [-27.661, -55.404],
                                  [-12.521, -69.323],
                                  [20.788, -92],
                                ],
                                v: [
                                  [-25.729, -301.34],
                                  [-171.189, -220.6],
                                  [-245.045, -35.551],
                                  [-252.307, 149.779],
                                  [-120.987, 267.199],
                                  [98.753, 333.73],
                                  [316.613, 259.082],
                                  [210.165, 27.039],
                                  [165.356, -130.272],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.289, 4.578],
                                  [32.247, -58.736],
                                  [11.909, -83.384],
                                  [-52.249, -43.017],
                                  [-14.464, -32.396],
                                  [-177.064, 31.703],
                                  [28.192, 55.534],
                                  [-1.202, 57.696],
                                  [-20.73, 92],
                                ],
                                o: [
                                  [-78.783, 5.323],
                                  [-32.247, 58.736],
                                  [-11.909, 83.384],
                                  [52.249, 43.017],
                                  [23.658, 43.711],
                                  [104.928, -16.144],
                                  [-28.192, -55.534],
                                  [-12.236, -69.719],
                                  [20.73, -92],
                                ],
                                v: [
                                  [-26.419, -301.493],
                                  [-170.686, -220.053],
                                  [-244.961, -35.149],
                                  [-252.397, 149.925],
                                  [-120.078, 267.024],
                                  [98.054, 333.95],
                                  [317.005, 259.218],
                                  [208.943, 26.188],
                                  [166.317, -129.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.159, 4.319],
                                  [31.934, -59.09],
                                  [12.129, -83.167],
                                  [-52.642, -42.976],
                                  [-14.48, -32.577],
                                  [-176.766, 31.567],
                                  [28.723, 55.665],
                                  [-1.425, 58.147],
                                  [-20.672, 92],
                                ],
                                o: [
                                  [-78.881, 5.537],
                                  [-31.934, 59.09],
                                  [-12.129, 83.167],
                                  [52.642, 42.976],
                                  [23.632, 43.841],
                                  [104.96, -16.08],
                                  [-28.723, -55.665],
                                  [-11.95, -70.114],
                                  [20.672, -92],
                                ],
                                v: [
                                  [-27.067, -301.604],
                                  [-170.167, -219.552],
                                  [-244.936, -34.737],
                                  [-252.444, 150.092],
                                  [-119.174, 266.832],
                                  [97.367, 334.21],
                                  [317.416, 259.361],
                                  [207.73, 25.332],
                                  [167.298, -129.593],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.03, 4.06],
                                  [31.622, -59.443],
                                  [12.349, -82.95],
                                  [-53.034, -42.935],
                                  [-14.496, -32.758],
                                  [-176.469, 31.431],
                                  [29.252, 55.795],
                                  [-1.648, 58.596],
                                  [-20.613, 92],
                                ],
                                o: [
                                  [-78.978, 5.751],
                                  [-31.622, 59.443],
                                  [-12.349, 82.95],
                                  [53.034, 42.935],
                                  [23.606, 43.97],
                                  [104.992, -16.015],
                                  [-29.252, -55.795],
                                  [-11.666, -70.509],
                                  [20.613, -92],
                                ],
                                v: [
                                  [-27.763, -301.717],
                                  [-169.607, -219.033],
                                  [-244.891, -34.335],
                                  [-252.493, 150.3],
                                  [-118.293, 266.658],
                                  [96.674, 334.51],
                                  [317.81, 259.505],
                                  [206.526, 24.531],
                                  [168.279, -129.237],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.901, 3.802],
                                  [31.311, -59.796],
                                  [12.568, -82.734],
                                  [-53.425, -42.893],
                                  [-14.512, -32.939],
                                  [-176.172, 31.296],
                                  [29.781, 55.925],
                                  [-1.871, 59.044],
                                  [-20.555, 92],
                                ],
                                o: [
                                  [-79.074, 5.963],
                                  [-31.311, 59.796],
                                  [-12.568, 82.734],
                                  [53.425, 42.893],
                                  [23.58, 44.099],
                                  [105.025, -15.951],
                                  [-29.781, -55.925],
                                  [-11.382, -70.902],
                                  [20.555, -92],
                                ],
                                v: [
                                  [-28.461, -301.79],
                                  [-169.075, -218.559],
                                  [-244.88, -33.944],
                                  [-252.588, 150.46],
                                  [-117.48, 266.404],
                                  [96.018, 334.87],
                                  [318.199, 259.594],
                                  [205.312, 23.742],
                                  [169.28, -128.967],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.772, 3.544],
                                  [31.001, -60.147],
                                  [12.787, -82.518],
                                  [-53.815, -42.852],
                                  [-14.528, -33.119],
                                  [-175.876, 31.161],
                                  [30.307, 56.054],
                                  [-2.093, 59.492],
                                  [-20.497, 92],
                                ],
                                o: [
                                  [-79.171, 6.176],
                                  [-31.001, 60.147],
                                  [-12.787, 82.518],
                                  [53.815, 42.852],
                                  [23.554, 44.228],
                                  [105.057, -15.886],
                                  [-30.307, -56.054],
                                  [-11.099, -71.295],
                                  [20.497, -92],
                                ],
                                v: [
                                  [-29.119, -301.847],
                                  [-168.482, -218.11],
                                  [-244.892, -33.5],
                                  [-252.664, 150.64],
                                  [-116.668, 266.169],
                                  [95.398, 335.269],
                                  [318.572, 259.711],
                                  [204.125, 23.009],
                                  [170.264, -128.659],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.644, 3.288],
                                  [30.691, -60.498],
                                  [13.006, -82.303],
                                  [-54.205, -42.811],
                                  [-14.545, -33.299],
                                  [-175.581, 31.026],
                                  [30.833, 56.183],
                                  [-2.314, 59.938],
                                  [-20.44, 92],
                                ],
                                o: [
                                  [-79.267, 6.388],
                                  [-30.691, 60.498],
                                  [-13.006, 82.303],
                                  [54.205, 42.811],
                                  [23.529, 44.356],
                                  [105.089, -15.822],
                                  [-30.833, -56.183],
                                  [-10.816, -71.686],
                                  [20.44, -92],
                                ],
                                v: [
                                  [-29.8, -301.925],
                                  [-167.892, -217.68],
                                  [-244.921, -33.092],
                                  [-252.741, 150.816],
                                  [-115.904, 265.915],
                                  [94.791, 335.726],
                                  [318.966, 259.794],
                                  [202.971, 22.286],
                                  [171.248, -128.355],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.516, 3.031],
                                  [30.382, -60.848],
                                  [13.223, -82.089],
                                  [-54.593, -42.771],
                                  [-14.561, -33.478],
                                  [-175.286, 30.891],
                                  [31.358, 56.312],
                                  [-2.535, 60.383],
                                  [-20.382, 92],
                                ],
                                o: [
                                  [-79.363, 6.599],
                                  [-30.382, 60.848],
                                  [-13.223, 82.089],
                                  [54.593, 42.771],
                                  [23.503, 44.484],
                                  [105.121, -15.758],
                                  [-31.358, -56.312],
                                  [-10.534, -72.077],
                                  [20.382, -92],
                                ],
                                v: [
                                  [-30.507, -301.988],
                                  [-167.284, -217.273],
                                  [-244.923, -32.714],
                                  [-252.819, 150.987],
                                  [-115.166, 265.6],
                                  [94.239, 336.18],
                                  [319.362, 259.905],
                                  [201.806, 21.555],
                                  [172.233, -128.119],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.388, 2.776],
                                  [30.073, -61.197],
                                  [13.441, -81.875],
                                  [-54.981, -42.73],
                                  [-14.577, -33.657],
                                  [-174.992, 30.757],
                                  [31.881, 56.441],
                                  [-2.756, 60.827],
                                  [-20.325, 92],
                                ],
                                o: [
                                  [-79.459, 6.81],
                                  [-30.073, 61.197],
                                  [-13.441, 81.875],
                                  [54.981, 42.73],
                                  [23.478, 44.612],
                                  [105.153, -15.694],
                                  [-31.881, -56.441],
                                  [-10.253, -72.467],
                                  [20.325, -92],
                                ],
                                v: [
                                  [-31.175, -302.072],
                                  [-166.678, -216.889],
                                  [-245.01, -32.286],
                                  [-252.878, 151.197],
                                  [-114.497, 265.304],
                                  [93.661, 336.672],
                                  [319.737, 259.982],
                                  [200.668, 20.878],
                                  [173.238, -127.864],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.26, 2.521],
                                  [29.766, -61.545],
                                  [13.657, -81.661],
                                  [-55.367, -42.689],
                                  [-14.592, -33.836],
                                  [-174.699, 30.623],
                                  [32.403, 56.569],
                                  [-2.976, 61.27],
                                  [-20.267, 92],
                                ],
                                o: [
                                  [-79.555, 7.02],
                                  [-29.766, 61.545],
                                  [-13.657, 81.661],
                                  [55.367, 42.689],
                                  [23.452, 44.74],
                                  [105.185, -15.63],
                                  [-32.403, -56.569],
                                  [-9.973, -72.856],
                                  [20.267, -92],
                                ],
                                v: [
                                  [-31.846, -302.098],
                                  [-166.053, -216.546],
                                  [-245.052, -31.894],
                                  [-252.915, 151.381],
                                  [-113.792, 264.989],
                                  [93.158, 337.223],
                                  [320.096, 260.07],
                                  [199.518, 20.231],
                                  [174.225, -127.658],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.133, 2.266],
                                  [29.459, -61.893],
                                  [13.874, -81.448],
                                  [-55.753, -42.649],
                                  [-14.608, -34.014],
                                  [-174.406, 30.49],
                                  [32.924, 56.697],
                                  [-3.195, 61.712],
                                  [-20.21, 92],
                                ],
                                o: [
                                  [-79.65, 7.23],
                                  [-29.459, 61.893],
                                  [-13.874, 81.448],
                                  [55.753, 42.649],
                                  [23.427, 44.867],
                                  [105.217, -15.567],
                                  [-32.924, -56.697],
                                  [-9.693, -73.244],
                                  [20.21, -92],
                                ],
                                v: [
                                  [-32.477, -302.126],
                                  [-165.43, -216.201],
                                  [-245.112, -31.472],
                                  [-252.891, 151.518],
                                  [-113.176, 264.632],
                                  [92.628, 337.768],
                                  [320.477, 260.124],
                                  [198.376, 19.617],
                                  [175.276, -127.457],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.006, 2.012],
                                  [29.152, -62.239],
                                  [14.09, -81.235],
                                  [-56.138, -42.608],
                                  [-14.624, -34.191],
                                  [-174.114, 30.357],
                                  [33.443, 56.825],
                                  [-3.414, 62.154],
                                  [-20.153, 92],
                                ],
                                o: [
                                  [-79.745, 7.44],
                                  [-29.152, 62.239],
                                  [-14.09, 81.235],
                                  [56.138, 42.608],
                                  [23.401, 44.994],
                                  [105.248, -15.503],
                                  [-33.444, -56.825],
                                  [-9.414, -73.631],
                                  [20.153, -92],
                                ],
                                v: [
                                  [-33.155, -302.158],
                                  [-164.767, -215.897],
                                  [-245.213, -31.064],
                                  [-252.868, 151.692],
                                  [-112.567, 264.276],
                                  [92.112, 338.33],
                                  [320.819, 260.147],
                                  [197.284, 18.993],
                                  [176.264, -127.282],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.88, 1.759],
                                  [28.847, -62.585],
                                  [14.305, -81.023],
                                  [-56.521, -42.568],
                                  [-14.64, -34.369],
                                  [-173.823, 30.224],
                                  [33.962, 56.952],
                                  [-3.633, 62.594],
                                  [-20.096, 92],
                                ],
                                o: [
                                  [-79.84, 7.649],
                                  [-28.847, 62.585],
                                  [-14.305, 81.023],
                                  [56.521, 42.568],
                                  [23.376, 45.12],
                                  [105.28, -15.44],
                                  [-33.962, -56.953],
                                  [-9.135, -74.017],
                                  [20.096, -92],
                                ],
                                v: [
                                  [-33.772, -302.15],
                                  [-164.063, -215.548],
                                  [-245.333, -30.65],
                                  [-252.762, 151.819],
                                  [-112.028, 263.92],
                                  [91.651, 338.93],
                                  [321.165, 260.18],
                                  [196.198, 18.398],
                                  [177.274, -127.132],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.753, 1.506],
                                  [28.542, -62.93],
                                  [14.52, -80.812],
                                  [-56.904, -42.528],
                                  [-14.656, -34.546],
                                  [-173.532, 30.091],
                                  [34.479, 57.08],
                                  [-3.851, 63.033],
                                  [-20.039, 92],
                                ],
                                o: [
                                  [-79.935, 7.857],
                                  [-28.542, 62.93],
                                  [-14.52, 80.812],
                                  [56.904, 42.528],
                                  [23.351, 45.247],
                                  [105.312, -15.377],
                                  [-34.479, -57.08],
                                  [-8.857, -74.403],
                                  [20.039, -92],
                                ],
                                v: [
                                  [-34.456, -302.188],
                                  [-163.424, -215.219],
                                  [-245.451, -30.271],
                                  [-252.596, 151.941],
                                  [-111.474, 263.545],
                                  [91.184, 339.567],
                                  [321.514, 260.244],
                                  [195.099, 17.855],
                                  [178.326, -126.989],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.627, 1.254],
                                  [28.238, -63.275],
                                  [14.734, -80.6],
                                  [-57.286, -42.487],
                                  [-14.672, -34.722],
                                  [-173.242, 29.958],
                                  [34.995, 57.206],
                                  [-4.068, 63.471],
                                  [-19.982, 92],
                                ],
                                o: [
                                  [-80.03, 8.065],
                                  [-28.238, 63.275],
                                  [-14.734, 80.6],
                                  [57.286, 42.487],
                                  [23.325, 45.373],
                                  [105.343, -15.314],
                                  [-34.995, -57.206],
                                  [-8.58, -74.787],
                                  [19.982, -92],
                                ],
                                v: [
                                  [-35.08, -302.187],
                                  [-162.723, -214.928],
                                  [-245.609, -29.866],
                                  [-252.432, 152.079],
                                  [-110.991, 263.19],
                                  [90.773, 340.221],
                                  [321.846, 260.256],
                                  [194.028, 17.342],
                                  [179.317, -126.893],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.501, 1.003],
                                  [27.935, -63.618],
                                  [14.948, -80.39],
                                  [-57.668, -42.447],
                                  [-14.687, -34.898],
                                  [-172.953, 29.827],
                                  [35.51, 57.333],
                                  [-4.285, 63.908],
                                  [-19.926, 92],
                                ],
                                o: [
                                  [-80.124, 8.273],
                                  [-27.935, 63.618],
                                  [-14.948, 80.39],
                                  [57.668, 42.447],
                                  [23.3, 45.499],
                                  [105.375, -15.251],
                                  [-35.51, -57.333],
                                  [-8.303, -75.171],
                                  [19.926, -92],
                                ],
                                v: [
                                  [-35.707, -302.231],
                                  [-162.022, -214.593],
                                  [-245.745, -29.497],
                                  [-252.228, 152.191],
                                  [-110.516, 262.816],
                                  [90.355, 340.87],
                                  [322.161, 260.279],
                                  [192.984, 16.838],
                                  [180.33, -126.824],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.376, 0.752],
                                  [27.632, -63.961],
                                  [15.161, -80.18],
                                  [-58.048, -42.407],
                                  [-14.703, -35.074],
                                  [-172.665, 29.695],
                                  [36.023, 57.459],
                                  [-4.502, 64.344],
                                  [-19.869, 92],
                                ],
                                o: [
                                  [-80.218, 8.48],
                                  [-27.632, 63.961],
                                  [-15.161, 80.18],
                                  [58.048, 42.407],
                                  [23.275, 45.624],
                                  [105.406, -15.188],
                                  [-36.023, -57.459],
                                  [-8.027, -75.553],
                                  [19.869, -92],
                                ],
                                v: [
                                  [-36.275, -302.236],
                                  [-161.301, -214.256],
                                  [-245.921, -29.123],
                                  [-252.027, 152.277],
                                  [-110.11, 262.421],
                                  [89.952, 341.536],
                                  [322.46, 260.272],
                                  [191.925, 16.343],
                                  [181.364, -126.76],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.251, 0.502],
                                  [27.33, -64.302],
                                  [15.374, -79.97],
                                  [-58.427, -42.367],
                                  [-14.719, -35.249],
                                  [-172.377, 29.563],
                                  [36.536, 57.585],
                                  [-4.717, 64.778],
                                  [-19.813, 92],
                                ],
                                o: [
                                  [-80.312, 8.686],
                                  [-27.33, 64.303],
                                  [-15.374, 79.97],
                                  [58.427, 42.367],
                                  [23.25, 45.749],
                                  [105.437, -15.125],
                                  [-36.536, -57.585],
                                  [-7.752, -75.935],
                                  [19.813, -92],
                                ],
                                v: [
                                  [-36.909, -302.224],
                                  [-160.622, -213.935],
                                  [-246.118, -28.725],
                                  [-251.787, 152.379],
                                  [-109.672, 262.006],
                                  [89.606, 342.239],
                                  [322.783, 260.276],
                                  [190.894, 15.897],
                                  [182.378, -126.724],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.126, 0.252],
                                  [27.029, -64.644],
                                  [15.586, -79.761],
                                  [-58.806, -42.328],
                                  [-14.734, -35.424],
                                  [-172.09, 29.432],
                                  [37.047, 57.711],
                                  [-4.933, 65.212],
                                  [-19.757, 92],
                                ],
                                o: [
                                  [-80.406, 8.892],
                                  [-27.029, 64.644],
                                  [-15.586, 79.761],
                                  [58.806, 42.328],
                                  [23.225, 45.874],
                                  [105.469, -15.063],
                                  [-37.047, -57.711],
                                  [-7.477, -76.316],
                                  [19.757, -92],
                                ],
                                v: [
                                  [-37.483, -302.277],
                                  [-159.902, -213.633],
                                  [-246.313, -28.386],
                                  [-251.488, 152.413],
                                  [-109.326, 261.633],
                                  [89.253, 342.938],
                                  [323.05, 260.249],
                                  [189.847, 15.499],
                                  [183.394, -126.736],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.001, 0.003],
                                  [26.728, -64.984],
                                  [15.798, -79.552],
                                  [-59.183, -42.288],
                                  [-14.75, -35.598],
                                  [-171.803, 29.302],
                                  [37.557, 57.836],
                                  [-5.148, 65.645],
                                  [-19.701, 92],
                                ],
                                o: [
                                  [-80.499, 9.098],
                                  [-26.728, 64.984],
                                  [-15.798, 79.552],
                                  [59.183, 42.288],
                                  [23.2, 45.999],
                                  [105.5, -15.001],
                                  [-37.557, -57.836],
                                  [-7.203, -76.696],
                                  [19.701, -92],
                                ],
                                v: [
                                  [-38.038, -302.271],
                                  [-159.183, -213.306],
                                  [-246.529, -28.002],
                                  [-251.213, 152.506],
                                  [-108.966, 261.22],
                                  [88.957, 343.654],
                                  [323.3, 260.235],
                                  [188.869, 15.088],
                                  [184.389, -126.774],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.877, -0.246],
                                  [26.429, -65.323],
                                  [16.009, -79.344],
                                  [-59.56, -42.248],
                                  [-14.765, -35.772],
                                  [-171.517, 29.171],
                                  [38.065, 57.961],
                                  [-5.362, 66.077],
                                  [-19.645, 92],
                                ],
                                o: [
                                  [-80.592, 9.303],
                                  [-26.429, 65.323],
                                  [-16.009, 79.344],
                                  [59.56, 42.248],
                                  [23.175, 46.123],
                                  [105.531, -14.939],
                                  [-38.065, -57.961],
                                  [-6.93, -77.075],
                                  [19.645, -92],
                                ],
                                v: [
                                  [-38.618, -302.267],
                                  [-158.483, -212.975],
                                  [-246.762, -27.636],
                                  [-250.856, 152.531],
                                  [-108.659, 260.869],
                                  [88.677, 344.407],
                                  [323.555, 260.209],
                                  [187.833, 14.684],
                                  [185.407, -126.86],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.753, -0.494],
                                  [26.13, -65.662],
                                  [16.22, -79.137],
                                  [-59.936, -42.209],
                                  [-14.781, -35.945],
                                  [-171.232, 29.041],
                                  [38.573, 58.086],
                                  [-5.576, 66.508],
                                  [-19.589, 92],
                                ],
                                o: [
                                  [-80.685, 9.507],
                                  [-26.13, 65.662],
                                  [-16.22, 79.137],
                                  [59.936, 42.209],
                                  [23.151, 46.247],
                                  [105.562, -14.877],
                                  [-38.573, -58.086],
                                  [-6.657, -77.453],
                                  [19.589, -92],
                                ],
                                v: [
                                  [-39.156, -302.306],
                                  [-157.782, -212.642],
                                  [-247.039, -27.289],
                                  [-250.525, 152.574],
                                  [-108.378, 260.457],
                                  [88.391, 345.116],
                                  [323.796, 260.154],
                                  [186.862, 14.328],
                                  [186.426, -126.933],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.629, -0.741],
                                  [25.831, -65.999],
                                  [16.43, -78.929],
                                  [-60.311, -42.169],
                                  [-14.796, -36.119],
                                  [-170.948, 28.911],
                                  [39.079, 58.21],
                                  [-5.789, 66.937],
                                  [-19.533, 92],
                                ],
                                o: [
                                  [-80.778, 9.711],
                                  [-25.831, 65.999],
                                  [-16.43, 78.929],
                                  [60.311, 42.169],
                                  [23.126, 46.37],
                                  [105.593, -14.815],
                                  [-39.079, -58.21],
                                  [-6.385, -77.83],
                                  [19.533, -92],
                                ],
                                v: [
                                  [-39.679, -302.308],
                                  [-157.081, -212.305],
                                  [-247.293, -26.94],
                                  [-250.197, 152.612],
                                  [-108.11, 260.085],
                                  [88.162, 345.883],
                                  [324.02, 260.11],
                                  [185.878, 14.018],
                                  [187.424, -127.093],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.506, -0.988],
                                  [25.534, -66.336],
                                  [16.64, -78.723],
                                  [-60.684, -42.13],
                                  [-14.812, -36.291],
                                  [-170.664, 28.781],
                                  [39.584, 58.334],
                                  [-6.002, 67.366],
                                  [-19.478, 92],
                                ],
                                o: [
                                  [-80.87, 9.915],
                                  [-25.534, 66.336],
                                  [-16.64, 78.723],
                                  [60.684, 42.13],
                                  [23.101, 46.494],
                                  [105.623, -14.753],
                                  [-39.584, -58.334],
                                  [-6.114, -78.206],
                                  [19.478, -92],
                                ],
                                v: [
                                  [-40.182, -302.313],
                                  [-156.38, -212.004],
                                  [-247.568, -26.61],
                                  [-249.809, 152.606],
                                  [-107.869, 259.674],
                                  [87.95, 346.646],
                                  [324.209, 260.078],
                                  [184.9, 13.715],
                                  [188.444, -127.241],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.383, -1.234],
                                  [25.237, -66.672],
                                  [16.849, -78.517],
                                  [-61.058, -42.091],
                                  [-14.827, -36.464],
                                  [-170.381, 28.652],
                                  [40.088, 58.458],
                                  [-6.214, 67.794],
                                  [-19.422, 92],
                                ],
                                o: [
                                  [-80.963, 10.118],
                                  [-25.237, 66.672],
                                  [-16.849, 78.517],
                                  [61.058, 42.091],
                                  [23.077, 46.617],
                                  [105.654, -14.692],
                                  [-40.088, -58.458],
                                  [-5.843, -78.582],
                                  [19.422, -92],
                                ],
                                v: [
                                  [-40.688, -302.361],
                                  [-155.678, -211.7],
                                  [-247.866, -26.239],
                                  [-249.424, 152.636],
                                  [-107.682, 259.326],
                                  [87.733, 347.429],
                                  [324.426, 259.994],
                                  [183.943, 13.395],
                                  [189.427, -127.436],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.26, -1.479],
                                  [24.941, -67.008],
                                  [17.057, -78.311],
                                  [-61.43, -42.052],
                                  [-14.842, -36.636],
                                  [-170.099, 28.523],
                                  [40.591, 58.582],
                                  [-6.426, 68.22],
                                  [-19.367, 92],
                                ],
                                o: [
                                  [-81.055, 10.32],
                                  [-24.941, 67.008],
                                  [-17.057, 78.311],
                                  [61.43, 42.052],
                                  [23.052, 46.74],
                                  [105.685, -14.63],
                                  [-40.591, -58.582],
                                  [-5.573, -78.956],
                                  [19.367, -92],
                                ],
                                v: [
                                  [-41.173, -302.411],
                                  [-155.016, -211.353],
                                  [-248.18, -25.927],
                                  [-249.043, 152.622],
                                  [-107.52, 258.955],
                                  [87.597, 348.207],
                                  [324.626, 259.921],
                                  [182.991, 13.121],
                                  [190.427, -127.676],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.138, -1.724],
                                  [24.645, -67.342],
                                  [17.266, -78.106],
                                  [-61.801, -42.013],
                                  [-14.858, -36.807],
                                  [-169.817, 28.395],
                                  [41.092, 58.705],
                                  [-6.637, 68.646],
                                  [-19.312, 92],
                                ],
                                o: [
                                  [-81.147, 10.523],
                                  [-24.645, 67.342],
                                  [-17.266, 78.106],
                                  [61.801, 42.013],
                                  [23.028, 46.862],
                                  [105.716, -14.569],
                                  [-41.092, -58.705],
                                  [-5.303, -79.33],
                                  [19.312, -92],
                                ],
                                v: [
                                  [-41.638, -302.44],
                                  [-154.353, -211.042],
                                  [-248.476, -25.598],
                                  [-248.625, 152.589],
                                  [-107.35, 258.585],
                                  [87.455, 348.944],
                                  [324.791, 259.837],
                                  [182.084, 12.892],
                                  [191.412, -127.968],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.016, -1.969],
                                  [24.35, -67.676],
                                  [17.473, -77.901],
                                  [-62.171, -41.974],
                                  [-14.873, -36.978],
                                  [-169.536, 28.266],
                                  [41.592, 58.828],
                                  [-6.848, 69.071],
                                  [-19.257, 92],
                                ],
                                o: [
                                  [-81.238, 10.724],
                                  [-24.35, 67.676],
                                  [-17.473, 77.901],
                                  [62.171, 41.974],
                                  [23.003, 46.984],
                                  [105.746, -14.508],
                                  [-41.592, -58.828],
                                  [-5.034, -79.702],
                                  [19.257, -92],
                                ],
                                v: [
                                  [-42.088, -302.494],
                                  [-153.666, -210.727],
                                  [-248.835, -25.307],
                                  [-248.25, 152.567],
                                  [-107.252, 258.239],
                                  [87.332, 349.716],
                                  [324.9, 259.762],
                                  [181.18, 12.668],
                                  [192.437, -128.243],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.894, -2.212],
                                  [24.056, -68.009],
                                  [17.681, -77.697],
                                  [-62.541, -41.935],
                                  [-14.888, -37.149],
                                  [-169.256, 28.139],
                                  [42.091, 58.951],
                                  [-7.058, 69.494],
                                  [-19.202, 92],
                                ],
                                o: [
                                  [-81.33, 10.925],
                                  [-24.056, 68.009],
                                  [-17.681, 77.697],
                                  [62.541, 41.935],
                                  [22.979, 47.106],
                                  [105.777, -14.447],
                                  [-42.091, -58.951],
                                  [-4.766, -80.074],
                                  [19.202, -92],
                                ],
                                v: [
                                  [-42.494, -302.528],
                                  [-153.04, -210.385],
                                  [-249.17, -24.998],
                                  [-247.815, 152.55],
                                  [-107.139, 257.908],
                                  [87.229, 350.508],
                                  [325.038, 259.699],
                                  [180.28, 12.45],
                                  [193.401, -128.587],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.772, -2.456],
                                  [23.763, -68.341],
                                  [17.887, -77.493],
                                  [-62.909, -41.896],
                                  [-14.903, -37.319],
                                  [-168.976, 28.011],
                                  [42.589, 59.073],
                                  [-7.268, 69.917],
                                  [-19.147, 92],
                                ],
                                o: [
                                  [-81.421, 11.126],
                                  [-23.763, 68.341],
                                  [-17.887, 77.493],
                                  [62.909, 41.896],
                                  [22.954, 47.228],
                                  [105.807, -14.386],
                                  [-42.589, -59.073],
                                  [-4.499, -80.445],
                                  [19.147, -92],
                                ],
                                v: [
                                  [-42.901, -302.563],
                                  [-152.375, -210.087],
                                  [-249.529, -24.689],
                                  [-247.384, 152.507],
                                  [-107.059, 257.601],
                                  [87.16, 351.297],
                                  [325.182, 259.583],
                                  [179.361, 12.251],
                                  [194.389, -128.96],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.651, -2.698],
                                  [23.47, -68.672],
                                  [18.093, -77.29],
                                  [-63.277, -41.857],
                                  [-14.919, -37.489],
                                  [-168.697, 27.884],
                                  [43.085, 59.195],
                                  [-7.477, 70.338],
                                  [-19.093, 92],
                                ],
                                o: [
                                  [-81.512, 11.326],
                                  [-23.47, 68.672],
                                  [-18.093, 77.29],
                                  [63.277, 41.857],
                                  [22.93, 47.349],
                                  [105.837, -14.325],
                                  [-43.085, -59.195],
                                  [-4.232, -80.815],
                                  [19.093, -92],
                                ],
                                v: [
                                  [-43.311, -302.64],
                                  [-151.747, -209.8],
                                  [-249.887, -24.403],
                                  [-246.979, 152.446],
                                  [-107.05, 257.271],
                                  [87.135, 352.069],
                                  [325.269, 259.454],
                                  [178.509, 12.057],
                                  [195.317, -129.403],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.53, -2.94],
                                  [23.179, -69.003],
                                  [18.299, -77.088],
                                  [-63.644, -41.819],
                                  [-14.934, -37.658],
                                  [-168.419, 27.756],
                                  [43.58, 59.317],
                                  [-7.686, 70.758],
                                  [-19.038, 92],
                                ],
                                o: [
                                  [-81.603, 11.526],
                                  [-23.179, 69.003],
                                  [-18.299, 77.088],
                                  [63.644, 41.819],
                                  [22.906, 47.47],
                                  [105.867, -14.265],
                                  [-43.58, -59.317],
                                  [-3.966, -81.184],
                                  [19.038, -92],
                                ],
                                v: [
                                  [-43.659, -302.717],
                                  [-151.157, -209.47],
                                  [-250.268, -24.117],
                                  [-246.592, 152.383],
                                  [-106.987, 256.964],
                                  [87.145, 352.853],
                                  [325.324, 259.36],
                                  [177.636, 11.906],
                                  [196.308, -129.865],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.409, -3.181],
                                  [22.887, -69.332],
                                  [18.504, -76.886],
                                  [-64.009, -41.78],
                                  [-14.949, -37.827],
                                  [-168.141, 27.63],
                                  [44.074, 59.438],
                                  [-7.894, 71.178],
                                  [-18.984, 92],
                                ],
                                o: [
                                  [-81.693, 11.725],
                                  [-22.887, 69.332],
                                  [-18.504, 76.886],
                                  [64.009, 41.78],
                                  [22.882, 47.591],
                                  [105.898, -14.205],
                                  [-44.074, -59.438],
                                  [-3.7, -81.552],
                                  [18.984, -92],
                                ],
                                v: [
                                  [-44.009, -302.772],
                                  [-150.59, -209.138],
                                  [-250.664, -23.816],
                                  [-246.145, 152.317],
                                  [-106.996, 256.672],
                                  [87.199, 353.62],
                                  [325.385, 259.227],
                                  [176.766, 11.734],
                                  [197.238, -130.358],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.289, -3.422],
                                  [22.597, -69.661],
                                  [18.709, -76.684],
                                  [-64.374, -41.742],
                                  [-14.964, -37.996],
                                  [-167.865, 27.503],
                                  [44.567, 59.559],
                                  [-8.102, 71.596],
                                  [-18.93, 92],
                                ],
                                o: [
                                  [-81.783, 11.923],
                                  [-22.597, 69.661],
                                  [-18.709, 76.684],
                                  [64.374, 41.742],
                                  [22.858, 47.711],
                                  [105.928, -14.144],
                                  [-44.567, -59.559],
                                  [-3.436, -81.919],
                                  [18.93, -92],
                                ],
                                v: [
                                  [-44.336, -302.891],
                                  [-149.997, -208.864],
                                  [-251.046, -23.529],
                                  [-245.725, 152.274],
                                  [-107.014, 256.403],
                                  [87.213, 354.385],
                                  [325.453, 259.141],
                                  [175.938, 11.604],
                                  [198.17, -130.919],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.169, -3.662],
                                  [22.307, -69.989],
                                  [18.913, -76.483],
                                  [-64.738, -41.704],
                                  [-14.979, -38.164],
                                  [-167.588, 27.377],
                                  [45.059, 59.68],
                                  [-8.309, 72.013],
                                  [-18.876, 92],
                                ],
                                o: [
                                  [-81.873, 12.121],
                                  [-22.307, 69.989],
                                  [-18.913, 76.483],
                                  [64.738, 41.704],
                                  [22.834, 47.831],
                                  [105.958, -14.084],
                                  [-45.059, -59.68],
                                  [-3.171, -82.285],
                                  [18.876, -92],
                                ],
                                v: [
                                  [-44.64, -302.986],
                                  [-149.466, -208.562],
                                  [-251.466, -23.254],
                                  [-245.346, 152.191],
                                  [-107.041, 256.172],
                                  [87.323, 355.172],
                                  [325.464, 259.028],
                                  [175.113, 11.477],
                                  [199.078, -131.486],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.049, -3.902],
                                  [22.018, -70.316],
                                  [19.117, -76.282],
                                  [-65.101, -41.666],
                                  [-14.994, -38.331],
                                  [-167.313, 27.251],
                                  [45.549, 59.801],
                                  [-8.515, 72.43],
                                  [-18.822, 92],
                                ],
                                o: [
                                  [-81.963, 12.319],
                                  [-22.018, 70.316],
                                  [-19.117, 76.282],
                                  [65.101, 41.666],
                                  [22.81, 47.951],
                                  [105.988, -14.025],
                                  [-45.549, -59.801],
                                  [-2.908, -82.651],
                                  [18.822, -92],
                                ],
                                v: [
                                  [-44.945, -303.082],
                                  [-148.895, -208.281],
                                  [-251.848, -22.992],
                                  [-244.907, 152.132],
                                  [-107.139, 255.941],
                                  [87.419, 355.895],
                                  [325.444, 258.923],
                                  [174.291, 11.354],
                                  [199.987, -132.096],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.93, -4.141],
                                  [21.73, -70.642],
                                  [19.32, -76.082],
                                  [-65.464, -41.627],
                                  [-15.009, -38.499],
                                  [-167.038, 27.126],
                                  [46.038, 59.921],
                                  [-8.722, 72.845],
                                  [-18.768, 92],
                                ],
                                o: [
                                  [-82.053, 12.516],
                                  [-21.73, 70.642],
                                  [-19.32, 76.082],
                                  [65.464, 41.628],
                                  [22.786, 48.07],
                                  [106.018, -13.965],
                                  [-46.039, -59.921],
                                  [-2.645, -83.015],
                                  [18.768, -92],
                                ],
                                v: [
                                  [-45.188, -303.217],
                                  [-148.423, -207.996],
                                  [-252.267, -22.717],
                                  [-244.508, 152.033],
                                  [-107.183, 255.684],
                                  [87.599, 356.641],
                                  [325.43, 258.804],
                                  [173.509, 11.234],
                                  [200.897, -132.711],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.81, -4.379],
                                  [21.442, -70.968],
                                  [19.522, -75.882],
                                  [-65.825, -41.59],
                                  [-15.024, -38.666],
                                  [-166.764, 27.001],
                                  [46.526, 60.041],
                                  [-8.927, 73.259],
                                  [-18.715, 92],
                                ],
                                o: [
                                  [-82.142, 12.713],
                                  [-21.442, 70.968],
                                  [-19.522, 75.882],
                                  [65.825, 41.59],
                                  [22.762, 48.19],
                                  [106.047, -13.905],
                                  [-46.526, -60.041],
                                  [-2.383, -83.379],
                                  [18.715, -92],
                                ],
                                v: [
                                  [-45.47, -303.326],
                                  [-147.95, -207.709],
                                  [-252.712, -22.418],
                                  [-244.137, 151.997],
                                  [-107.298, 255.488],
                                  [87.777, 357.386],
                                  [325.397, 258.693],
                                  [172.73, 11.129],
                                  [201.745, -133.395],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.691, -4.617],
                                  [21.156, -71.293],
                                  [19.725, -75.683],
                                  [-66.185, -41.552],
                                  [-15.039, -38.832],
                                  [-166.49, 26.876],
                                  [47.013, 60.161],
                                  [-9.132, 73.672],
                                  [-18.661, 92],
                                ],
                                o: [
                                  [-82.231, 12.909],
                                  [-21.156, 71.293],
                                  [-19.725, 75.683],
                                  [66.185, 41.552],
                                  [22.738, 48.309],
                                  [106.077, -13.846],
                                  [-47.013, -60.16],
                                  [-2.121, -83.741],
                                  [18.661, -92],
                                ],
                                v: [
                                  [-45.689, -303.461],
                                  [-147.476, -207.443],
                                  [-253.131, -22.145],
                                  [-243.742, 151.923],
                                  [-107.36, 255.292],
                                  [87.979, 358.092],
                                  [325.334, 258.591],
                                  [171.953, 11.026],
                                  [202.595, -134.121],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.573, -4.854],
                                  [20.869, -71.617],
                                  [19.926, -75.484],
                                  [-66.545, -41.514],
                                  [-15.053, -38.998],
                                  [-166.217, 26.751],
                                  [47.499, 60.28],
                                  [-9.337, 74.084],
                                  [-18.608, 92],
                                ],
                                o: [
                                  [-82.32, 13.105],
                                  [-20.869, 71.617],
                                  [-19.926, 75.484],
                                  [66.545, 41.514],
                                  [22.715, 48.427],
                                  [106.107, -13.786],
                                  [-47.499, -60.28],
                                  [-1.86, -84.103],
                                  [18.608, -92],
                                ],
                                v: [
                                  [-45.846, -303.608],
                                  [-147, -207.148],
                                  [-253.575, -21.898],
                                  [-243.387, 151.811],
                                  [-107.493, 255.132],
                                  [88.206, 358.823],
                                  [325.277, 258.473],
                                  [171.217, 10.937],
                                  [203.445, -134.851],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.455, -5.091],
                                  [20.584, -71.94],
                                  [20.127, -75.286],
                                  [-66.903, -41.476],
                                  [-15.068, -39.164],
                                  [-165.945, 26.627],
                                  [47.983, 60.399],
                                  [-9.541, 74.496],
                                  [-18.555, 92],
                                ],
                                o: [
                                  [-82.409, 13.3],
                                  [-20.584, 71.94],
                                  [-20.127, 75.286],
                                  [66.903, 41.476],
                                  [22.691, 48.546],
                                  [106.136, -13.727],
                                  [-47.983, -60.399],
                                  [-1.6, -84.464],
                                  [18.555, -92],
                                ],
                                v: [
                                  [-46.041, -303.791],
                                  [-146.585, -206.913],
                                  [-253.994, -21.678],
                                  [-243.034, 151.751],
                                  [-107.634, 254.981],
                                  [88.493, 359.528],
                                  [325.164, 258.401],
                                  [170.482, 10.814],
                                  [204.234, -135.624],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.337, -5.327],
                                  [20.299, -72.262],
                                  [20.328, -75.089],
                                  [-67.261, -41.439],
                                  [-15.083, -39.329],
                                  [-165.674, 26.503],
                                  [48.466, 60.518],
                                  [-9.745, 74.906],
                                  [-18.501, 92],
                                ],
                                o: [
                                  [-82.498, 13.495],
                                  [-20.299, 72.262],
                                  [-20.328, 75.089],
                                  [67.261, 41.439],
                                  [22.667, 48.664],
                                  [106.166, -13.668],
                                  [-48.466, -60.518],
                                  [-1.34, -84.824],
                                  [18.501, -92],
                                ],
                                v: [
                                  [-46.173, -303.973],
                                  [-146.232, -206.663],
                                  [-254.413, -21.46],
                                  [-242.656, 151.666],
                                  [-107.847, 254.854],
                                  [88.805, 360.22],
                                  [325.058, 258.336],
                                  [169.76, 10.728],
                                  [205.06, -136.463],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.219, -5.562],
                                  [20.015, -72.584],
                                  [20.528, -74.891],
                                  [-67.618, -41.401],
                                  [-15.098, -39.494],
                                  [-165.403, 26.38],
                                  [48.948, 60.636],
                                  [-9.948, 75.315],
                                  [-18.448, 92],
                                ],
                                o: [
                                  [-82.586, 13.689],
                                  [-20.015, 72.584],
                                  [-20.528, 74.892],
                                  [67.618, 41.401],
                                  [22.644, 48.781],
                                  [106.195, -13.609],
                                  [-48.948, -60.636],
                                  [-1.081, -85.183],
                                  [18.448, -92],
                                ],
                                v: [
                                  [-46.342, -304.129],
                                  [-145.841, -206.422],
                                  [-254.831, -21.269],
                                  [-242.306, 151.634],
                                  [-108.005, 254.726],
                                  [89.106, 360.877],
                                  [324.921, 258.217],
                                  [169.066, 10.645],
                                  [205.824, -137.318],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.101, -5.797],
                                  [19.732, -72.904],
                                  [20.728, -74.695],
                                  [-67.974, -41.364],
                                  [-15.112, -39.658],
                                  [-165.133, 26.256],
                                  [49.429, 60.754],
                                  [-10.15, 75.723],
                                  [-18.396, 92],
                                ],
                                o: [
                                  [-82.674, 13.883],
                                  [-19.732, 72.904],
                                  [-20.728, 74.695],
                                  [67.974, 41.364],
                                  [22.62, 48.899],
                                  [106.225, -13.551],
                                  [-49.429, -60.754],
                                  [-0.823, -85.541],
                                  [18.396, -92],
                                ],
                                v: [
                                  [-46.448, -304.32],
                                  [-145.547, -206.203],
                                  [-255.25, -21.044],
                                  [-242.029, 151.567],
                                  [-108.173, 254.632],
                                  [89.506, 361.533],
                                  [324.765, 258.143],
                                  [168.41, 10.537],
                                  [206.589, -138.202],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.984, -6.031],
                                  [19.45, -73.224],
                                  [20.927, -74.499],
                                  [-68.329, -41.326],
                                  [-15.127, -39.822],
                                  [-164.864, 26.133],
                                  [49.908, 60.872],
                                  [-10.352, 76.13],
                                  [-18.343, 92],
                                ],
                                o: [
                                  [-82.762, 14.076],
                                  [-19.45, 73.224],
                                  [-20.927, 74.499],
                                  [68.329, 41.326],
                                  [22.597, 49.016],
                                  [106.254, -13.492],
                                  [-49.908, -60.872],
                                  [-0.565, -85.898],
                                  [18.343, -92],
                                ],
                                v: [
                                  [-46.491, -304.535],
                                  [-145.216, -205.945],
                                  [-255.668, -20.883],
                                  [-241.718, 151.527],
                                  [-108.384, 254.572],
                                  [89.923, 362.153],
                                  [324.58, 258.112],
                                  [167.755, 10.43],
                                  [207.318, -139.09],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.867, -6.265],
                                  [19.168, -73.543],
                                  [21.125, -74.303],
                                  [-68.683, -41.289],
                                  [-15.142, -39.986],
                                  [-164.595, 26.011],
                                  [50.386, 60.99],
                                  [-10.554, 76.536],
                                  [-18.29, 92],
                                ],
                                o: [
                                  [-82.849, 14.269],
                                  [-19.168, 73.543],
                                  [-21.125, 74.303],
                                  [68.683, 41.289],
                                  [22.573, 49.133],
                                  [106.283, -13.434],
                                  [-50.386, -60.99],
                                  [-0.308, -86.254],
                                  [18.29, -92],
                                ],
                                v: [
                                  [-46.57, -304.759],
                                  [-144.947, -205.747],
                                  [-256.085, -20.752],
                                  [-241.418, 151.49],
                                  [-108.604, 254.545],
                                  [90.34, 362.774],
                                  [324.374, 258.053],
                                  [167.129, 10.325],
                                  [208.021, -140.054],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.751, -6.498],
                                  [18.887, -73.861],
                                  [21.323, -74.108],
                                  [-69.036, -41.252],
                                  [-15.156, -40.149],
                                  [-164.327, 25.888],
                                  [50.863, 61.107],
                                  [-10.755, 76.94],
                                  [-18.238, 92],
                                ],
                                o: [
                                  [-82.937, 14.461],
                                  [-18.887, 73.861],
                                  [-21.323, 74.108],
                                  [69.036, 41.252],
                                  [22.55, 49.249],
                                  [106.312, -13.375],
                                  [-50.863, -61.107],
                                  [-0.052, -86.61],
                                  [18.238, -92],
                                ],
                                v: [
                                  [-46.621, -304.99],
                                  [-144.739, -205.582],
                                  [-256.476, -20.587],
                                  [-241.145, 151.445],
                                  [-108.797, 254.516],
                                  [90.793, 363.414],
                                  [324.139, 258.01],
                                  [166.513, 10.229],
                                  [208.725, -141.049],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.635, -6.731],
                                  [18.606, -74.179],
                                  [21.521, -73.913],
                                  [-69.388, -41.215],
                                  [-15.171, -40.311],
                                  [-164.06, 25.766],
                                  [51.339, 61.224],
                                  [-10.955, 77.344],
                                  [-18.186, 92],
                                ],
                                o: [
                                  [-83.024, 14.653],
                                  [-18.606, 74.179],
                                  [-21.521, 73.913],
                                  [69.388, 41.215],
                                  [22.527, 49.365],
                                  [106.341, -13.317],
                                  [-51.339, -61.224],
                                  [0.204, -86.964],
                                  [18.186, -92],
                                ],
                                v: [
                                  [-46.608, -305.245],
                                  [-144.53, -205.379],
                                  [-256.866, -20.453],
                                  [-240.943, 151.403],
                                  [-109.033, 254.52],
                                  [91.266, 364.047],
                                  [323.911, 258.01],
                                  [165.926, 10.099],
                                  [209.402, -142.055],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.519, -6.962],
                                  [18.327, -74.496],
                                  [21.718, -73.719],
                                  [-69.74, -41.178],
                                  [-15.185, -40.474],
                                  [-163.793, 25.645],
                                  [51.814, 61.34],
                                  [-11.155, 77.747],
                                  [-18.133, 92],
                                ],
                                o: [
                                  [-83.111, 14.844],
                                  [-18.327, 74.496],
                                  [-21.718, 73.719],
                                  [69.74, 41.178],
                                  [22.504, 49.481],
                                  [106.37, -13.259],
                                  [-51.814, -61.34],
                                  [0.459, -87.318],
                                  [18.133, -92],
                                ],
                                v: [
                                  [-46.622, -305.471],
                                  [-144.348, -205.173],
                                  [-257.255, -20.357],
                                  [-240.707, 151.364],
                                  [-109.278, 254.52],
                                  [91.74, 364.682],
                                  [323.662, 257.954],
                                  [165.34, 10.005],
                                  [210.044, -143.099],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.403, -7.194],
                                  [18.048, -74.811],
                                  [21.915, -73.525],
                                  [-70.09, -41.141],
                                  [-15.2, -40.636],
                                  [-163.527, 25.523],
                                  [52.287, 61.457],
                                  [-11.355, 78.149],
                                  [-18.081, 92],
                                ],
                                o: [
                                  [-83.198, 15.035],
                                  [-18.048, 74.811],
                                  [-21.915, 73.525],
                                  [70.09, 41.141],
                                  [22.481, 49.597],
                                  [106.399, -13.202],
                                  [-52.287, -61.457],
                                  [0.713, -87.67],
                                  [18.081, -92],
                                ],
                                v: [
                                  [-46.581, -305.792],
                                  [-144.2, -205.028],
                                  [-257.644, -20.256],
                                  [-240.499, 151.327],
                                  [-109.503, 254.615],
                                  [92.243, 365.311],
                                  [323.385, 258.002],
                                  [164.755, 9.885],
                                  [210.687, -144.174],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.288, -7.424],
                                  [17.769, -75.126],
                                  [22.111, -73.332],
                                  [-70.44, -41.104],
                                  [-15.214, -40.797],
                                  [-163.262, 25.402],
                                  [52.759, 61.573],
                                  [-11.553, 78.55],
                                  [-18.03, 92],
                                ],
                                o: [
                                  [-83.284, 15.225],
                                  [-17.769, 75.126],
                                  [-22.111, 73.332],
                                  [70.44, 41.104],
                                  [22.458, 49.712],
                                  [106.428, -13.144],
                                  [-52.759, -61.573],
                                  [0.967, -88.022],
                                  [18.03, -92],
                                ],
                                v: [
                                  [-46.538, -306.082],
                                  [-144.079, -204.907],
                                  [-258.032, -20.159],
                                  [-240.32, 151.258],
                                  [-109.735, 254.679],
                                  [92.713, 365.902],
                                  [323.086, 257.993],
                                  [164.235, 9.757],
                                  [211.302, -145.259],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.173, -7.654],
                                  [17.492, -75.44],
                                  [22.306, -73.139],
                                  [-70.789, -41.068],
                                  [-15.228, -40.958],
                                  [-162.997, 25.281],
                                  [53.23, 61.689],
                                  [-11.752, 78.949],
                                  [-17.978, 92],
                                ],
                                o: [
                                  [-83.37, 15.415],
                                  [-17.492, 75.44],
                                  [-22.306, 73.139],
                                  [70.789, 41.068],
                                  [22.435, 49.827],
                                  [106.457, -13.086],
                                  [-53.23, -61.689],
                                  [1.22, -88.373],
                                  [17.978, -92],
                                ],
                                v: [
                                  [-46.493, -306.369],
                                  [-143.991, -204.749],
                                  [-258.392, -20.066],
                                  [-240.108, 151.191],
                                  [-110.003, 254.801],
                                  [93.247, 366.531],
                                  [322.759, 257.998],
                                  [163.689, 9.638],
                                  [211.889, -146.408],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.058, -7.884],
                                  [17.215, -75.754],
                                  [22.501, -72.947],
                                  [-71.136, -41.031],
                                  [-15.243, -41.119],
                                  [-162.734, 25.161],
                                  [53.7, 61.804],
                                  [-11.95, 79.348],
                                  [-17.926, 92],
                                ],
                                o: [
                                  [-83.456, 15.604],
                                  [-17.215, 75.754],
                                  [-22.501, 72.947],
                                  [71.136, 41.031],
                                  [22.412, 49.942],
                                  [106.486, -13.029],
                                  [-53.7, -61.804],
                                  [1.472, -88.723],
                                  [17.926, -92],
                                ],
                                v: [
                                  [-46.385, -306.75],
                                  [-143.91, -204.618],
                                  [-258.751, -20.004],
                                  [-239.959, 151.127],
                                  [-110.223, 254.92],
                                  [93.777, 367.095],
                                  [322.439, 258.042],
                                  [163.171, 9.518],
                                  [212.47, -147.532],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.944, -8.113],
                                  [16.939, -76.066],
                                  [22.696, -72.756],
                                  [-71.483, -40.995],
                                  [-15.257, -41.279],
                                  [-162.47, 25.041],
                                  [54.168, 61.919],
                                  [-12.147, 79.746],
                                  [-17.875, 92],
                                ],
                                o: [
                                  [-83.542, 15.793],
                                  [-16.939, 76.066],
                                  [-22.696, 72.756],
                                  [71.483, 40.995],
                                  [22.389, 50.056],
                                  [106.514, -12.972],
                                  [-54.168, -61.919],
                                  [1.724, -89.072],
                                  [17.875, -92],
                                ],
                                v: [
                                  [-46.31, -307.099],
                                  [-143.854, -204.486],
                                  [-259.109, -19.975],
                                  [-239.807, 151.038],
                                  [-110.479, 255.07],
                                  [94.31, 367.72],
                                  [322.097, 258.092],
                                  [162.655, 9.371],
                                  [213.029, -148.685],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.83, -8.341],
                                  [16.664, -76.378],
                                  [22.89, -72.564],
                                  [-71.829, -40.958],
                                  [-15.271, -41.439],
                                  [-162.208, 24.921],
                                  [54.635, 62.034],
                                  [-12.344, 80.142],
                                  [-17.823, 92],
                                ],
                                o: [
                                  [-83.628, 15.981],
                                  [-16.664, 76.378],
                                  [-22.89, 72.564],
                                  [71.829, 40.958],
                                  [22.366, 50.171],
                                  [106.543, -12.915],
                                  [-54.635, -62.034],
                                  [1.975, -89.42],
                                  [17.823, -92],
                                ],
                                v: [
                                  [-46.171, -307.445],
                                  [-143.838, -204.38],
                                  [-259.466, -19.921],
                                  [-239.656, 150.946],
                                  [-110.713, 255.284],
                                  [94.845, 368.287],
                                  [321.726, 258.146],
                                  [162.175, 9.224],
                                  [213.582, -149.778],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.716, -8.569],
                                  [16.389, -76.689],
                                  [23.083, -72.374],
                                  [-72.174, -40.922],
                                  [-15.286, -41.598],
                                  [-161.946, 24.801],
                                  [55.101, 62.149],
                                  [-12.54, 80.538],
                                  [-17.772, 92],
                                ],
                                o: [
                                  [-83.713, 16.169],
                                  [-16.389, 76.689],
                                  [-23.083, 72.374],
                                  [72.174, 40.922],
                                  [22.343, 50.284],
                                  [106.571, -12.858],
                                  [-55.101, -62.149],
                                  [2.225, -89.767],
                                  [17.772, -92],
                                ],
                                v: [
                                  [-46.029, -307.854],
                                  [-143.881, -204.239],
                                  [-259.788, -19.9],
                                  [-239.537, 150.862],
                                  [-110.955, 255.493],
                                  [95.412, 368.854],
                                  [321.368, 258.177],
                                  [161.725, 9.076],
                                  [214.14, -150.874],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.602, -8.796],
                                  [16.115, -76.999],
                                  [23.276, -72.184],
                                  [-72.519, -40.886],
                                  [-15.3, -41.757],
                                  [-161.685, 24.682],
                                  [55.566, 62.263],
                                  [-12.736, 80.933],
                                  [-17.721, 92],
                                ],
                                o: [
                                  [-83.798, 16.356],
                                  [-16.115, 76.999],
                                  [-23.276, 72.184],
                                  [72.519, 40.886],
                                  [22.32, 50.398],
                                  [106.599, -12.801],
                                  [-55.566, -62.263],
                                  [2.475, -90.113],
                                  [17.721, -92],
                                ],
                                v: [
                                  [-45.887, -308.259],
                                  [-143.893, -204.126],
                                  [-260.115, -19.911],
                                  [-239.421, 150.776],
                                  [-111.175, 255.73],
                                  [95.948, 369.426],
                                  [321.015, 258.246],
                                  [161.248, 8.929],
                                  [214.669, -152],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.489, -9.022],
                                  [15.842, -77.308],
                                  [23.469, -71.994],
                                  [-72.862, -40.85],
                                  [-15.314, -41.916],
                                  [-161.424, 24.563],
                                  [56.03, 62.377],
                                  [-12.932, 81.326],
                                  [-17.67, 92],
                                ],
                                o: [
                                  [-83.883, 16.543],
                                  [-15.842, 77.308],
                                  [-23.469, 71.994],
                                  [72.862, 40.85],
                                  [22.298, 50.511],
                                  [106.628, -12.744],
                                  [-56.03, -62.377],
                                  [2.724, -90.459],
                                  [17.67, -92],
                                ],
                                v: [
                                  [-45.713, -308.693],
                                  [-143.999, -204.042],
                                  [-260.407, -19.865],
                                  [-239.302, 150.663],
                                  [-111.436, 255.992],
                                  [96.517, 369.966],
                                  [320.669, 258.286],
                                  [160.8, 8.719],
                                  [215.197, -153.066],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.376, -9.248],
                                  [15.569, -77.617],
                                  [23.661, -71.805],
                                  [-73.204, -40.814],
                                  [-15.328, -42.074],
                                  [-161.165, 24.445],
                                  [56.492, 62.49],
                                  [-13.126, 81.719],
                                  [-17.619, 92],
                                ],
                                o: [
                                  [-83.968, 16.73],
                                  [-15.569, 77.617],
                                  [-23.661, 71.805],
                                  [73.204, 40.814],
                                  [22.275, 50.624],
                                  [106.656, -12.688],
                                  [-56.492, -62.49],
                                  [2.973, -90.803],
                                  [17.619, -92],
                                ],
                                v: [
                                  [-45.504, -309.122],
                                  [-144.102, -203.925],
                                  [-260.731, -19.886],
                                  [-239.221, 150.583],
                                  [-111.67, 256.283],
                                  [97.089, 370.546],
                                  [320.332, 258.335],
                                  [160.354, 8.542],
                                  [215.757, -154.135],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.263, -9.473],
                                  [15.298, -77.925],
                                  [23.852, -71.616],
                                  [-73.546, -40.778],
                                  [-15.342, -42.231],
                                  [-160.906, 24.326],
                                  [56.953, 62.604],
                                  [-13.321, 82.11],
                                  [-17.568, 92],
                                ],
                                o: [
                                  [-84.053, 16.916],
                                  [-15.298, 77.925],
                                  [-23.852, 71.616],
                                  [73.546, 40.778],
                                  [22.253, 50.737],
                                  [106.684, -12.632],
                                  [-56.953, -62.604],
                                  [3.221, -91.147],
                                  [17.568, -92],
                                ],
                                v: [
                                  [-45.327, -309.613],
                                  [-144.234, -203.841],
                                  [-261.021, -19.911],
                                  [-239.138, 150.476],
                                  [-111.881, 256.602],
                                  [97.661, 371.065],
                                  [320, 258.354],
                                  [159.939, 8.365],
                                  [216.253, -155.206],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.151, -9.698],
                                  [15.027, -78.231],
                                  [24.043, -71.428],
                                  [-73.886, -40.742],
                                  [-15.356, -42.389],
                                  [-160.647, 24.208],
                                  [57.413, 62.717],
                                  [-13.515, 82.5],
                                  [-17.518, 92],
                                ],
                                o: [
                                  [-84.137, 17.101],
                                  [-15.027, 78.231],
                                  [-24.043, 71.428],
                                  [73.886, 40.742],
                                  [22.23, 50.849],
                                  [106.712, -12.575],
                                  [-57.413, -62.717],
                                  [3.468, -91.49],
                                  [17.518, -92],
                                ],
                                v: [
                                  [-45.148, -310.127],
                                  [-144.426, -203.721],
                                  [-261.28, -19.908],
                                  [-239.089, 150.369],
                                  [-112.069, 256.915],
                                  [98.241, 371.592],
                                  [319.673, 258.411],
                                  [159.53, 8.126],
                                  [216.781, -156.217],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.039, -9.922],
                                  [14.756, -78.538],
                                  [24.234, -71.24],
                                  [-74.226, -40.706],
                                  [-15.37, -42.546],
                                  [-160.389, 24.091],
                                  [57.872, 62.83],
                                  [-13.708, 82.89],
                                  [-17.467, 92],
                                ],
                                o: [
                                  [-84.221, 17.286],
                                  [-14.756, 78.538],
                                  [-24.234, 71.24],
                                  [74.226, 40.706],
                                  [22.208, 50.961],
                                  [106.74, -12.519],
                                  [-57.872, -62.83],
                                  [3.714, -91.831],
                                  [17.468, -92],
                                ],
                                v: [
                                  [-44.904, -310.64],
                                  [-144.648, -203.664],
                                  [-261.506, -19.943],
                                  [-239.044, 150.268],
                                  [-112.296, 257.223],
                                  [98.822, 372.093],
                                  [319.321, 258.442],
                                  [159.118, 7.948],
                                  [217.277, -157.26],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.927, -10.146],
                                  [14.487, -78.843],
                                  [24.424, -71.053],
                                  [-74.565, -40.671],
                                  [-15.384, -42.702],
                                  [-160.132, 23.974],
                                  [58.33, 62.942],
                                  [-13.901, 83.278],
                                  [-17.417, 92],
                                ],
                                o: [
                                  [-84.305, 17.47],
                                  [-14.487, 78.843],
                                  [-24.424, 71.053],
                                  [74.565, 40.671],
                                  [22.185, 51.073],
                                  [106.768, -12.464],
                                  [-58.33, -62.942],
                                  [3.96, -92.172],
                                  [17.417, -92],
                                ],
                                v: [
                                  [-44.658, -311.147],
                                  [-144.865, -203.575],
                                  [-261.73, -20.012],
                                  [-239.033, 150.199],
                                  [-112.495, 257.589],
                                  [99.407, 372.601],
                                  [319.007, 258.476],
                                  [158.742, 7.678],
                                  [217.772, -158.277],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.816, -10.369],
                                  [14.218, -79.147],
                                  [24.613, -70.866],
                                  [-74.902, -40.635],
                                  [-15.398, -42.858],
                                  [-159.876, 23.856],
                                  [58.786, 63.054],
                                  [-14.093, 83.666],
                                  [-17.367, 92],
                                ],
                                o: [
                                  [-84.388, 17.654],
                                  [-14.218, 79.147],
                                  [-24.613, 70.866],
                                  [74.902, 40.635],
                                  [22.163, 51.184],
                                  [106.796, -12.408],
                                  [-58.786, -63.054],
                                  [4.206, -92.512],
                                  [17.367, -92],
                                ],
                                v: [
                                  [-44.442, -311.677],
                                  [-145.145, -203.551],
                                  [-261.924, -20.056],
                                  [-239.026, 150.134],
                                  [-112.704, 257.951],
                                  [99.964, 373.083],
                                  [318.729, 258.547],
                                  [158.366, 7.437],
                                  [218.234, -159.295],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.705, -10.591],
                                  [13.95, -79.451],
                                  [24.802, -70.68],
                                  [-75.239, -40.6],
                                  [-15.412, -43.014],
                                  [-159.62, 23.74],
                                  [59.241, 63.166],
                                  [-14.285, 84.052],
                                  [-17.317, 92],
                                ],
                                o: [
                                  [-84.472, 17.838],
                                  [-13.95, 79.451],
                                  [-24.802, 70.68],
                                  [75.239, 40.6],
                                  [22.141, 51.295],
                                  [106.824, -12.352],
                                  [-59.241, -63.166],
                                  [4.45, -92.851],
                                  [17.317, -92],
                                ],
                                v: [
                                  [-44.194, -312.203],
                                  [-145.449, -203.495],
                                  [-262.146, -20.104],
                                  [-239.054, 150.009],
                                  [-112.855, 258.338],
                                  [100.556, 373.541],
                                  [318.393, 258.588],
                                  [157.99, 7.166],
                                  [218.756, -160.316],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.594, -10.813],
                                  [13.682, -79.754],
                                  [24.991, -70.494],
                                  [-75.575, -40.564],
                                  [-15.426, -43.169],
                                  [-159.365, 23.623],
                                  [59.695, 63.278],
                                  [-14.476, 84.437],
                                  [-17.267, 92],
                                ],
                                o: [
                                  [-84.555, 18.02],
                                  [-13.682, 79.754],
                                  [-24.991, 70.494],
                                  [75.575, 40.564],
                                  [22.119, 51.406],
                                  [106.852, -12.297],
                                  [-59.695, -63.278],
                                  [4.694, -93.189],
                                  [17.267, -92],
                                ],
                                v: [
                                  [-43.974, -312.692],
                                  [-145.782, -203.44],
                                  [-262.305, -20.187],
                                  [-239.087, 149.95],
                                  [-113.013, 258.719],
                                  [101.153, 374.007],
                                  [318.093, 258.634],
                                  [157.649, 6.893],
                                  [219.184, -161.307],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.483, -11.034],
                                  [13.415, -80.056],
                                  [25.179, -70.309],
                                  [-75.911, -40.529],
                                  [-15.44, -43.324],
                                  [-159.111, 23.507],
                                  [60.147, 63.389],
                                  [-14.667, 84.821],
                                  [-17.217, 92],
                                ],
                                o: [
                                  [-84.638, 18.203],
                                  [-13.415, 80.056],
                                  [-25.179, 70.309],
                                  [75.911, 40.529],
                                  [22.097, 51.517],
                                  [106.879, -12.242],
                                  [-60.147, -63.389],
                                  [4.937, -93.527],
                                  [17.217, -92],
                                ],
                                v: [
                                  [-43.721, -313.239],
                                  [-146.143, -203.418],
                                  [-262.433, -20.277],
                                  [-239.155, 149.894],
                                  [-113.143, 259.125],
                                  [101.724, 374.448],
                                  [317.796, 258.715],
                                  [157.309, 6.621],
                                  [219.64, -162.301],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.373, -11.254],
                                  [13.149, -80.357],
                                  [25.366, -70.124],
                                  [-76.245, -40.494],
                                  [-15.453, -43.478],
                                  [-158.857, 23.391],
                                  [60.599, 63.5],
                                  [-14.857, 85.204],
                                  [-17.168, 92],
                                ],
                                o: [
                                  [-84.72, 18.385],
                                  [-13.149, 80.357],
                                  [-25.366, 70.124],
                                  [76.245, 40.494],
                                  [22.075, 51.627],
                                  [106.907, -12.186],
                                  [-60.599, -63.5],
                                  [5.18, -93.863],
                                  [17.168, -92],
                                ],
                                v: [
                                  [-43.495, -313.778],
                                  [-146.53, -203.368],
                                  [-262.559, -20.37],
                                  [-239.227, 149.778],
                                  [-113.311, 259.527],
                                  [102.299, 374.867],
                                  [317.504, 258.767],
                                  [156.972, 6.285],
                                  [220.125, -163.235],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.263, -11.474],
                                  [12.884, -80.657],
                                  [25.553, -69.94],
                                  [-76.578, -40.459],
                                  [-15.467, -43.632],
                                  [-158.605, 23.276],
                                  [61.049, 63.611],
                                  [-15.047, 85.587],
                                  [-17.118, 92],
                                ],
                                o: [
                                  [-84.803, 18.566],
                                  [-12.884, 80.657],
                                  [-25.553, 69.94],
                                  [76.578, 40.459],
                                  [22.053, 51.737],
                                  [106.934, -12.131],
                                  [-61.049, -63.61],
                                  [5.422, -94.198],
                                  [17.118, -92],
                                ],
                                v: [
                                  [-43.269, -314.282],
                                  [-146.944, -203.35],
                                  [-262.653, -20.436],
                                  [-239.305, 149.728],
                                  [-113.389, 259.986],
                                  [102.879, 375.293],
                                  [317.215, 258.822],
                                  [156.636, 5.949],
                                  [220.575, -164.203],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.153, -11.694],
                                  [12.62, -80.957],
                                  [25.74, -69.757],
                                  [-76.911, -40.424],
                                  [-15.481, -43.786],
                                  [-158.352, 23.161],
                                  [61.498, 63.721],
                                  [-15.236, 85.968],
                                  [-17.069, 92],
                                ],
                                o: [
                                  [-84.885, 18.747],
                                  [-12.62, 80.957],
                                  [-25.74, 69.757],
                                  [76.911, 40.424],
                                  [22.031, 51.847],
                                  [106.962, -12.077],
                                  [-61.498, -63.721],
                                  [5.663, -94.533],
                                  [17.069, -92],
                                ],
                                v: [
                                  [-43.039, -314.811],
                                  [-147.353, -203.335],
                                  [-262.746, -20.569],
                                  [-239.419, 149.68],
                                  [-113.503, 260.407],
                                  [103.465, 375.697],
                                  [316.928, 258.879],
                                  [156.333, 5.612],
                                  [221.023, -165.112],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.044, -11.912],
                                  [12.356, -81.255],
                                  [25.926, -69.573],
                                  [-77.242, -40.389],
                                  [-15.495, -43.939],
                                  [-158.101, 23.046],
                                  [61.946, 63.831],
                                  [-15.424, 86.348],
                                  [-17.02, 92],
                                ],
                                o: [
                                  [-84.967, 18.928],
                                  [-12.356, 81.255],
                                  [-25.926, 69.573],
                                  [77.242, 40.389],
                                  [22.009, 51.956],
                                  [106.989, -12.022],
                                  [-61.946, -63.831],
                                  [5.904, -94.866],
                                  [17.02, -92],
                                ],
                                v: [
                                  [-42.837, -315.335],
                                  [-147.852, -203.323],
                                  [-262.838, -20.676],
                                  [-239.538, 149.636],
                                  [-113.559, 260.854],
                                  [104.025, 376.077],
                                  [316.644, 258.969],
                                  [156.033, 5.211],
                                  [221.435, -166.053],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.935, -12.131],
                                  [12.093, -81.553],
                                  [26.111, -69.391],
                                  [-77.573, -40.354],
                                  [-15.508, -44.091],
                                  [-157.85, 22.931],
                                  [62.392, 63.941],
                                  [-15.613, 86.727],
                                  [-16.971, 92],
                                ],
                                o: [
                                  [-85.049, 19.108],
                                  [-12.093, 81.553],
                                  [-26.111, 69.391],
                                  [77.573, 40.354],
                                  [21.987, 52.065],
                                  [107.016, -11.967],
                                  [-62.392, -63.941],
                                  [6.144, -95.199],
                                  [16.971, -92],
                                ],
                                v: [
                                  [-42.601, -315.854],
                                  [-148.377, -203.345],
                                  [-262.867, -20.786],
                                  [-239.694, 149.563],
                                  [-113.649, 261.326],
                                  [104.59, 376.434],
                                  [316.394, 259.061],
                                  [155.704, 4.81],
                                  [221.875, -166.966],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.826, -12.348],
                                  [11.83, -81.85],
                                  [26.296, -69.208],
                                  [-77.903, -40.32],
                                  [-15.522, -44.244],
                                  [-157.6, 22.817],
                                  [62.838, 64.05],
                                  [-15.8, 87.105],
                                  [-16.922, 92],
                                ],
                                o: [
                                  [-85.131, 19.287],
                                  [-11.83, 81.85],
                                  [-26.296, 69.208],
                                  [77.903, 40.32],
                                  [21.965, 52.174],
                                  [107.044, -11.913],
                                  [-62.838, -64.05],
                                  [6.383, -95.531],
                                  [16.922, -92],
                                ],
                                v: [
                                  [-42.425, -316.399],
                                  [-148.897, -203.34],
                                  [-262.927, -20.931],
                                  [-239.888, 149.555],
                                  [-113.711, 261.794],
                                  [105.161, 376.799],
                                  [316.145, 259.186],
                                  [155.408, 4.376],
                                  [222.248, -167.851],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.717, -12.565],
                                  [11.568, -82.147],
                                  [26.48, -69.027],
                                  [-78.231, -40.285],
                                  [-15.535, -44.396],
                                  [-157.35, 22.703],
                                  [63.282, 64.159],
                                  [-15.987, 87.482],
                                  [-16.873, 92],
                                ],
                                o: [
                                  [-85.212, 19.466],
                                  [-11.568, 82.147],
                                  [-26.48, 69.027],
                                  [78.231, 40.285],
                                  [21.943, 52.282],
                                  [107.071, -11.859],
                                  [-63.282, -64.159],
                                  [6.622, -95.862],
                                  [16.873, -92],
                                ],
                                v: [
                                  [-42.214, -316.908],
                                  [-149.444, -203.37],
                                  [-262.923, -21.017],
                                  [-240.087, 149.519],
                                  [-113.682, 262.255],
                                  [105.706, 377.142],
                                  [315.867, 259.28],
                                  [155.146, 3.941],
                                  [222.679, -168.706],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.609, -12.781],
                                  [11.307, -82.442],
                                  [26.664, -68.846],
                                  [-78.559, -40.25],
                                  [-15.549, -44.547],
                                  [-157.101, 22.59],
                                  [63.724, 64.268],
                                  [-16.174, 87.858],
                                  [-16.824, 92],
                                ],
                                o: [
                                  [-85.293, 19.645],
                                  [-11.307, 82.442],
                                  [-26.664, 68.846],
                                  [78.559, 40.25],
                                  [21.922, 52.391],
                                  [107.098, -11.805],
                                  [-63.724, -64.268],
                                  [6.86, -96.192],
                                  [16.824, -92],
                                ],
                                v: [
                                  [-42.032, -317.475],
                                  [-150.048, -203.403],
                                  [-262.949, -21.137],
                                  [-240.261, 149.516],
                                  [-113.686, 262.742],
                                  [106.226, 377.46],
                                  [315.622, 259.406],
                                  [154.856, 3.474],
                                  [223.074, -169.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.501, -12.997],
                                  [11.047, -82.737],
                                  [26.848, -68.665],
                                  [-78.886, -40.216],
                                  [-15.562, -44.698],
                                  [-156.853, 22.476],
                                  [64.166, 64.377],
                                  [-16.36, 88.233],
                                  [-16.776, 92],
                                ],
                                o: [
                                  [-85.374, 19.823],
                                  [-11.047, 82.737],
                                  [-26.848, 68.665],
                                  [78.886, 40.216],
                                  [21.9, 52.499],
                                  [107.125, -11.751],
                                  [-64.166, -64.377],
                                  [7.097, -96.521],
                                  [16.776, -92],
                                ],
                                v: [
                                  [-41.877, -318.038],
                                  [-150.648, -203.409],
                                  [-262.943, -21.229],
                                  [-240.505, 149.484],
                                  [-113.663, 263.225],
                                  [106.783, 377.757],
                                  [315.377, 259.532],
                                  [154.6, 2.975],
                                  [223.464, -170.391],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.394, -13.212],
                                  [10.788, -83.031],
                                  [27.031, -68.485],
                                  [-79.213, -40.182],
                                  [-15.576, -44.849],
                                  [-156.606, 22.364],
                                  [64.607, 64.485],
                                  [-16.546, 88.607],
                                  [-16.727, 92],
                                ],
                                o: [
                                  [-85.455, 20],
                                  [-10.788, 83.031],
                                  [-27.031, 68.485],
                                  [79.213, 40.182],
                                  [21.879, 52.606],
                                  [107.152, -11.697],
                                  [-64.607, -64.485],
                                  [7.334, -96.849],
                                  [16.727, -92],
                                ],
                                v: [
                                  [-41.718, -318.595],
                                  [-151.275, -203.45],
                                  [-262.905, -21.386],
                                  [-240.754, 149.487],
                                  [-113.607, 263.701],
                                  [107.347, 378.029],
                                  [315.132, 259.659],
                                  [154.348, 2.443],
                                  [223.847, -171.252],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.287, -13.427],
                                  [10.529, -83.324],
                                  [27.213, -68.305],
                                  [-79.538, -40.148],
                                  [-15.589, -44.999],
                                  [-156.359, 22.251],
                                  [65.046, 64.593],
                                  [-16.731, 88.979],
                                  [-16.679, 92],
                                ],
                                o: [
                                  [-85.535, 20.177],
                                  [-10.529, 83.324],
                                  [-27.213, 68.305],
                                  [79.538, 40.148],
                                  [21.857, 52.714],
                                  [107.178, -11.643],
                                  [-65.046, -64.593],
                                  [7.57, -97.176],
                                  [16.679, -92],
                                ],
                                v: [
                                  [-41.587, -319.149],
                                  [-151.927, -203.497],
                                  [-262.867, -21.484],
                                  [-241.04, 149.459],
                                  [-113.492, 264.173],
                                  [107.853, 378.278],
                                  [314.919, 259.785],
                                  [154.066, 1.909],
                                  [224.225, -172.021],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.394, -13.212],
                                  [10.788, -83.031],
                                  [27.031, -68.485],
                                  [-79.213, -40.182],
                                  [-15.576, -44.849],
                                  [-156.606, 22.364],
                                  [64.607, 64.485],
                                  [-16.546, 88.607],
                                  [-16.727, 92],
                                ],
                                o: [
                                  [-85.455, 20],
                                  [-10.788, 83.031],
                                  [-27.031, 68.485],
                                  [79.213, 40.182],
                                  [21.879, 52.606],
                                  [107.152, -11.697],
                                  [-64.607, -64.485],
                                  [7.334, -96.849],
                                  [16.727, -92],
                                ],
                                v: [
                                  [-40.52, -319.106],
                                  [-152.53, -204.032],
                                  [-262.93, -22.27],
                                  [-241.165, 149.375],
                                  [-114.142, 264.455],
                                  [108.411, 378.076],
                                  [314.947, 259.629],
                                  [155.198, 2.365],
                                  [223.389, -171.989],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.501, -12.997],
                                  [11.047, -82.737],
                                  [26.848, -68.665],
                                  [-78.886, -40.216],
                                  [-15.562, -44.698],
                                  [-156.853, 22.476],
                                  [64.166, 64.377],
                                  [-16.36, 88.233],
                                  [-16.776, 92],
                                ],
                                o: [
                                  [-85.374, 19.823],
                                  [-11.047, 82.737],
                                  [-26.848, 68.665],
                                  [78.886, 40.216],
                                  [21.9, 52.499],
                                  [107.125, -11.751],
                                  [-64.166, -64.377],
                                  [7.097, -96.521],
                                  [16.776, -92],
                                ],
                                v: [
                                  [-39.483, -319.068],
                                  [-153.168, -204.566],
                                  [-263.027, -23.057],
                                  [-241.283, 149.226],
                                  [-114.76, 264.773],
                                  [108.993, 377.834],
                                  [314.974, 259.505],
                                  [156.33, 2.793],
                                  [222.523, -171.89],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.609, -12.781],
                                  [11.307, -82.442],
                                  [26.664, -68.846],
                                  [-78.559, -40.25],
                                  [-15.549, -44.547],
                                  [-157.101, 22.59],
                                  [63.725, 64.268],
                                  [-16.174, 87.858],
                                  [-16.824, 92],
                                ],
                                o: [
                                  [-85.293, 19.645],
                                  [-11.307, 82.442],
                                  [-26.664, 68.846],
                                  [78.559, 40.25],
                                  [21.922, 52.391],
                                  [107.098, -11.805],
                                  [-63.724, -64.268],
                                  [6.86, -96.192],
                                  [16.824, -92],
                                ],
                                v: [
                                  [-38.478, -319.062],
                                  [-153.811, -205.064],
                                  [-263.03, -23.873],
                                  [-241.395, 149.135],
                                  [-115.286, 265.065],
                                  [109.54, 377.552],
                                  [315.031, 259.348],
                                  [157.495, 3.225],
                                  [221.689, -171.785],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.717, -12.565],
                                  [11.568, -82.147],
                                  [26.48, -69.027],
                                  [-78.231, -40.285],
                                  [-15.535, -44.396],
                                  [-157.35, 22.703],
                                  [63.282, 64.159],
                                  [-15.987, 87.482],
                                  [-16.873, 92],
                                ],
                                o: [
                                  [-85.212, 19.466],
                                  [-11.568, 82.147],
                                  [-26.48, 69.027],
                                  [78.231, 40.285],
                                  [21.943, 52.283],
                                  [107.071, -11.859],
                                  [-63.282, -64.159],
                                  [6.622, -95.862],
                                  [16.873, -92],
                                ],
                                v: [
                                  [-37.474, -319.028],
                                  [-154.552, -205.592],
                                  [-263.098, -24.628],
                                  [-241.561, 149.012],
                                  [-115.843, 265.392],
                                  [110.048, 377.198],
                                  [315.058, 259.221],
                                  [158.659, 3.6],
                                  [220.859, -171.645],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.826, -12.348],
                                  [11.83, -81.85],
                                  [26.296, -69.208],
                                  [-77.903, -40.32],
                                  [-15.522, -44.244],
                                  [-157.6, 22.817],
                                  [62.838, 64.05],
                                  [-15.8, 87.105],
                                  [-16.922, 92],
                                ],
                                o: [
                                  [-85.131, 19.287],
                                  [-11.83, 81.85],
                                  [-26.296, 69.208],
                                  [77.903, 40.32],
                                  [21.965, 52.174],
                                  [107.044, -11.913],
                                  [-62.838, -64.05],
                                  [6.383, -95.531],
                                  [16.922, -92],
                                ],
                                v: [
                                  [-36.502, -319.059],
                                  [-155.266, -206.117],
                                  [-263.136, -25.382],
                                  [-241.69, 148.917],
                                  [-116.371, 265.692],
                                  [110.552, 376.867],
                                  [315.117, 259.095],
                                  [159.793, 3.979],
                                  [219.999, -171.47],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.935, -12.131],
                                  [12.093, -81.553],
                                  [26.111, -69.391],
                                  [-77.573, -40.354],
                                  [-15.508, -44.091],
                                  [-157.85, 22.932],
                                  [62.392, 63.941],
                                  [-15.613, 86.727],
                                  [-16.971, 92],
                                ],
                                o: [
                                  [-85.049, 19.108],
                                  [-12.093, 81.553],
                                  [-26.111, 69.391],
                                  [77.573, 40.354],
                                  [21.987, 52.065],
                                  [107.016, -11.967],
                                  [-62.392, -63.941],
                                  [6.144, -95.199],
                                  [16.971, -92],
                                ],
                                v: [
                                  [-35.563, -319.06],
                                  [-155.985, -206.607],
                                  [-263.144, -26.135],
                                  [-241.874, 148.79],
                                  [-116.838, 266.028],
                                  [111.017, 376.464],
                                  [315.208, 259],
                                  [160.927, 4.299],
                                  [219.205, -171.29],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.044, -11.912],
                                  [12.356, -81.255],
                                  [25.926, -69.573],
                                  [-77.242, -40.389],
                                  [-15.495, -43.939],
                                  [-158.101, 23.046],
                                  [61.946, 63.831],
                                  [-15.424, 86.348],
                                  [-17.02, 92],
                                ],
                                o: [
                                  [-84.967, 18.928],
                                  [-12.356, 81.255],
                                  [-25.926, 69.573],
                                  [77.242, 40.389],
                                  [22.009, 51.956],
                                  [106.989, -12.022],
                                  [-61.946, -63.831],
                                  [5.904, -94.866],
                                  [17.02, -92],
                                ],
                                v: [
                                  [-34.624, -319.126],
                                  [-156.771, -207.095],
                                  [-263.154, -26.89],
                                  [-241.988, 148.661],
                                  [-117.307, 266.368],
                                  [111.446, 376.053],
                                  [315.3, 258.906],
                                  [162.03, 4.625],
                                  [218.352, -171.075],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.153, -11.694],
                                  [12.62, -80.957],
                                  [25.74, -69.757],
                                  [-76.911, -40.424],
                                  [-15.481, -43.786],
                                  [-158.352, 23.161],
                                  [61.498, 63.721],
                                  [-15.236, 85.968],
                                  [-17.069, 92],
                                ],
                                o: [
                                  [-84.885, 18.747],
                                  [-12.62, 80.957],
                                  [-25.74, 69.757],
                                  [76.911, 40.424],
                                  [22.031, 51.847],
                                  [106.962, -12.077],
                                  [-61.498, -63.721],
                                  [5.663, -94.533],
                                  [17.069, -92],
                                ],
                                v: [
                                  [-33.749, -319.194],
                                  [-157.561, -207.58],
                                  [-263.134, -27.614],
                                  [-242.157, 148.561],
                                  [-117.717, 266.68],
                                  [111.899, 375.603],
                                  [315.425, 258.812],
                                  [163.133, 4.894],
                                  [217.534, -170.794],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.263, -11.474],
                                  [12.884, -80.657],
                                  [25.553, -69.94],
                                  [-76.578, -40.459],
                                  [-15.467, -43.632],
                                  [-158.605, 23.276],
                                  [61.049, 63.611],
                                  [-15.047, 85.587],
                                  [-17.118, 92],
                                ],
                                o: [
                                  [-84.803, 18.566],
                                  [-12.884, 80.657],
                                  [-25.553, 69.94],
                                  [76.578, 40.459],
                                  [22.053, 51.737],
                                  [106.934, -12.131],
                                  [-61.049, -63.61],
                                  [5.422, -94.198],
                                  [17.118, -92],
                                ],
                                v: [
                                  [-32.874, -319.264],
                                  [-158.417, -208.03],
                                  [-263.116, -28.339],
                                  [-242.319, 148.461],
                                  [-118.161, 267.027],
                                  [112.254, 375.113],
                                  [315.551, 258.782],
                                  [164.173, 5.136],
                                  [216.72, -170.508],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.373, -11.254],
                                  [13.149, -80.357],
                                  [25.366, -70.124],
                                  [-76.245, -40.494],
                                  [-15.453, -43.478],
                                  [-158.857, 23.391],
                                  [60.599, 63.5],
                                  [-14.857, 85.204],
                                  [-17.168, 92],
                                ],
                                o: [
                                  [-84.72, 18.385],
                                  [-13.149, 80.357],
                                  [-25.366, 70.124],
                                  [76.245, 40.494],
                                  [22.075, 51.627],
                                  [106.907, -12.186],
                                  [-60.599, -63.5],
                                  [5.18, -93.863],
                                  [17.168, -92],
                                ],
                                v: [
                                  [-32.031, -319.366],
                                  [-159.247, -208.479],
                                  [-263.099, -29.035],
                                  [-242.473, 148.36],
                                  [-118.547, 267.378],
                                  [112.633, 374.647],
                                  [315.712, 258.754],
                                  [165.215, 5.383],
                                  [215.91, -170.187],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.483, -11.034],
                                  [13.415, -80.056],
                                  [25.179, -70.309],
                                  [-75.911, -40.529],
                                  [-15.44, -43.324],
                                  [-159.111, 23.507],
                                  [60.147, 63.389],
                                  [-14.667, 84.821],
                                  [-17.217, 92],
                                ],
                                o: [
                                  [-84.638, 18.203],
                                  [-13.415, 80.056],
                                  [-25.179, 70.309],
                                  [75.911, 40.529],
                                  [22.097, 51.517],
                                  [106.879, -12.242],
                                  [-60.147, -63.389],
                                  [4.937, -93.527],
                                  [17.217, -92],
                                ],
                                v: [
                                  [-31.221, -319.47],
                                  [-160.08, -208.924],
                                  [-263.053, -29.732],
                                  [-242.682, 148.259],
                                  [-118.874, 267.731],
                                  [112.943, 374.141],
                                  [315.876, 258.696],
                                  [166.257, 5.542],
                                  [215.105, -169.862],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.594, -10.813],
                                  [13.682, -79.754],
                                  [24.991, -70.494],
                                  [-75.575, -40.564],
                                  [-15.426, -43.169],
                                  [-159.365, 23.623],
                                  [59.695, 63.278],
                                  [-14.476, 84.437],
                                  [-17.267, 92],
                                ],
                                o: [
                                  [-84.555, 18.02],
                                  [-13.682, 79.754],
                                  [-24.991, 70.494],
                                  [75.575, 40.564],
                                  [22.119, 51.406],
                                  [106.852, -12.297],
                                  [-59.695, -63.278],
                                  [4.694, -93.189],
                                  [17.267, -92],
                                ],
                                v: [
                                  [-30.411, -319.606],
                                  [-160.979, -209.368],
                                  [-263.041, -30.369],
                                  [-242.821, 148.127],
                                  [-119.207, 268.088],
                                  [113.28, 373.598],
                                  [316.073, 258.703],
                                  [167.238, 5.768],
                                  [214.336, -169.471],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.705, -10.591],
                                  [13.95, -79.451],
                                  [24.802, -70.68],
                                  [-75.239, -40.6],
                                  [-15.412, -43.014],
                                  [-159.62, 23.74],
                                  [59.241, 63.166],
                                  [-14.285, 84.052],
                                  [-17.317, 92],
                                ],
                                o: [
                                  [-84.472, 17.838],
                                  [-13.95, 79.451],
                                  [-24.802, 70.68],
                                  [75.239, 40.6],
                                  [22.141, 51.295],
                                  [106.824, -12.352],
                                  [-59.241, -63.166],
                                  [4.45, -92.851],
                                  [17.317, -92],
                                ],
                                v: [
                                  [-29.663, -319.743],
                                  [-161.852, -209.746],
                                  [-262.967, -31.041],
                                  [-243.015, 147.997],
                                  [-119.511, 268.415],
                                  [113.578, 373.076],
                                  [316.304, 258.682],
                                  [168.217, 5.875],
                                  [213.541, -169.013],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.816, -10.369],
                                  [14.218, -79.147],
                                  [24.613, -70.866],
                                  [-74.902, -40.635],
                                  [-15.398, -42.858],
                                  [-159.876, 23.856],
                                  [58.786, 63.054],
                                  [-14.093, 83.666],
                                  [-17.367, 92],
                                ],
                                o: [
                                  [-84.388, 17.654],
                                  [-14.218, 79.147],
                                  [-24.613, 70.866],
                                  [74.902, 40.635],
                                  [22.163, 51.184],
                                  [106.796, -12.408],
                                  [-58.786, -63.054],
                                  [4.205, -92.512],
                                  [17.367, -92],
                                ],
                                v: [
                                  [-28.916, -319.912],
                                  [-162.728, -210.185],
                                  [-262.926, -31.715],
                                  [-243.169, 147.868],
                                  [-119.793, 268.713],
                                  [113.807, 372.517],
                                  [316.509, 258.693],
                                  [169.199, 5.988],
                                  [212.784, -168.581],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.927, -10.146],
                                  [14.487, -78.843],
                                  [24.424, -71.053],
                                  [-74.565, -40.67],
                                  [-15.384, -42.702],
                                  [-160.132, 23.973],
                                  [58.33, 62.942],
                                  [-13.901, 83.278],
                                  [-17.417, 92],
                                ],
                                o: [
                                  [-84.305, 17.47],
                                  [-14.487, 78.843],
                                  [-24.424, 71.053],
                                  [74.565, 40.671],
                                  [22.185, 51.073],
                                  [106.768, -12.464],
                                  [-58.33, -62.942],
                                  [3.96, -92.172],
                                  [17.417, -92],
                                ],
                                v: [
                                  [-28.232, -320.081],
                                  [-163.609, -210.651],
                                  [-262.889, -32.3],
                                  [-243.347, 147.772],
                                  [-120.017, 269.043],
                                  [114.063, 371.983],
                                  [316.812, 258.74],
                                  [170.12, 6.105],
                                  [212.032, -168.115],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.039, -9.922],
                                  [14.756, -78.538],
                                  [24.234, -71.24],
                                  [-74.226, -40.706],
                                  [-15.37, -42.546],
                                  [-160.389, 24.091],
                                  [57.872, 62.83],
                                  [-13.708, 82.89],
                                  [-17.467, 92],
                                ],
                                o: [
                                  [-84.221, 17.286],
                                  [-14.756, 78.538],
                                  [-24.234, 71.24],
                                  [74.226, 40.706],
                                  [22.208, 50.961],
                                  [106.74, -12.519],
                                  [-57.872, -62.83],
                                  [3.714, -91.831],
                                  [17.468, -92],
                                ],
                                v: [
                                  [-27.578, -320.281],
                                  [-164.494, -211.084],
                                  [-262.822, -32.919],
                                  [-243.488, 147.647],
                                  [-120.28, 269.375],
                                  [114.28, 371.408],
                                  [317.118, 258.759],
                                  [171.041, 6.166],
                                  [211.316, -167.642],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.151, -9.698],
                                  [15.027, -78.231],
                                  [24.043, -71.428],
                                  [-73.886, -40.742],
                                  [-15.356, -42.389],
                                  [-160.647, 24.208],
                                  [57.413, 62.717],
                                  [-13.515, 82.5],
                                  [-17.518, 92],
                                ],
                                o: [
                                  [-84.137, 17.101],
                                  [-15.027, 78.231],
                                  [-24.043, 71.428],
                                  [73.886, 40.742],
                                  [22.23, 50.849],
                                  [106.712, -12.575],
                                  [-57.413, -62.717],
                                  [3.468, -91.49],
                                  [17.518, -92],
                                ],
                                v: [
                                  [-26.924, -320.481],
                                  [-165.354, -211.544],
                                  [-262.757, -33.513],
                                  [-243.651, 147.493],
                                  [-120.456, 269.677],
                                  [114.429, 370.829],
                                  [317.428, 258.843],
                                  [171.901, 6.203],
                                  [210.608, -167.135],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.263, -9.473],
                                  [15.298, -77.925],
                                  [23.852, -71.616],
                                  [-73.546, -40.778],
                                  [-15.342, -42.231],
                                  [-160.906, 24.326],
                                  [56.953, 62.604],
                                  [-13.321, 82.11],
                                  [-17.568, 92],
                                ],
                                o: [
                                  [-84.053, 16.916],
                                  [-15.298, 77.925],
                                  [-23.852, 71.616],
                                  [73.546, 40.778],
                                  [22.253, 50.737],
                                  [106.684, -12.632],
                                  [-56.953, -62.604],
                                  [3.221, -91.147],
                                  [17.568, -92],
                                ],
                                v: [
                                  [-26.299, -320.682],
                                  [-166.218, -212.001],
                                  [-262.664, -34.076],
                                  [-243.84, 147.375],
                                  [-120.671, 269.947],
                                  [114.604, 370.273],
                                  [317.775, 258.93],
                                  [172.795, 6.245],
                                  [209.906, -166.652],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.376, -9.248],
                                  [15.569, -77.617],
                                  [23.661, -71.805],
                                  [-73.204, -40.814],
                                  [-15.328, -42.074],
                                  [-161.165, 24.445],
                                  [56.492, 62.49],
                                  [-13.126, 81.719],
                                  [-17.619, 92],
                                ],
                                o: [
                                  [-83.968, 16.73],
                                  [-15.569, 77.617],
                                  [-23.661, 71.805],
                                  [73.204, 40.814],
                                  [22.275, 50.624],
                                  [106.656, -12.688],
                                  [-56.492, -62.49],
                                  [2.973, -90.803],
                                  [17.619, -92],
                                ],
                                v: [
                                  [-25.704, -320.947],
                                  [-167.087, -212.456],
                                  [-262.603, -34.647],
                                  [-243.958, 147.292],
                                  [-120.801, 270.248],
                                  [114.711, 369.65],
                                  [318.158, 258.99],
                                  [173.657, 6.259],
                                  [209.243, -166.102],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.489, -9.022],
                                  [15.842, -77.308],
                                  [23.469, -71.994],
                                  [-72.862, -40.85],
                                  [-15.314, -41.916],
                                  [-161.424, 24.563],
                                  [56.03, 62.377],
                                  [-12.932, 81.326],
                                  [-17.67, 92],
                                ],
                                o: [
                                  [-83.883, 16.543],
                                  [-15.842, 77.308],
                                  [-23.469, 71.994],
                                  [72.862, 40.85],
                                  [22.298, 50.511],
                                  [106.628, -12.744],
                                  [-56.03, -62.377],
                                  [2.724, -90.459],
                                  [17.67, -92],
                                ],
                                v: [
                                  [-25.17, -321.207],
                                  [-167.927, -212.907],
                                  [-262.515, -35.16],
                                  [-244.132, 147.182],
                                  [-120.938, 270.512],
                                  [114.811, 369.05],
                                  [318.513, 259.117],
                                  [174.428, 6.217],
                                  [208.581, -165.577],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.602, -8.796],
                                  [16.115, -76.999],
                                  [23.276, -72.184],
                                  [-72.519, -40.886],
                                  [-15.3, -41.757],
                                  [-161.685, 24.682],
                                  [55.566, 62.263],
                                  [-12.736, 80.933],
                                  [-17.721, 92],
                                ],
                                o: [
                                  [-83.798, 16.356],
                                  [-16.115, 76.999],
                                  [-23.276, 72.184],
                                  [72.519, 40.886],
                                  [22.32, 50.398],
                                  [106.6, -12.801],
                                  [-55.566, -62.263],
                                  [2.475, -90.113],
                                  [17.721, -92],
                                ],
                                v: [
                                  [-24.636, -321.467],
                                  [-168.805, -213.355],
                                  [-262.463, -35.681],
                                  [-244.269, 147.109],
                                  [-121.115, 270.747],
                                  [114.906, 368.448],
                                  [318.934, 259.184],
                                  [175.262, 6.18],
                                  [207.93, -165.017],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.716, -8.569],
                                  [16.389, -76.689],
                                  [23.083, -72.374],
                                  [-72.174, -40.922],
                                  [-15.286, -41.598],
                                  [-161.946, 24.801],
                                  [55.101, 62.149],
                                  [-12.54, 80.538],
                                  [-17.772, 92],
                                ],
                                o: [
                                  [-83.713, 16.169],
                                  [-16.389, 76.689],
                                  [-23.083, 72.374],
                                  [72.174, 40.922],
                                  [22.343, 50.284],
                                  [106.571, -12.858],
                                  [-55.101, -62.149],
                                  [2.225, -89.767],
                                  [17.772, -92],
                                ],
                                v: [
                                  [-24.13, -321.789],
                                  [-169.625, -213.829],
                                  [-262.38, -36.172],
                                  [-244.4, 147.039],
                                  [-121.271, 271.04],
                                  [114.96, 367.839],
                                  [319.297, 259.316],
                                  [176.036, 6.12],
                                  [207.32, -164.453],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.829, -8.341],
                                  [16.664, -76.378],
                                  [22.89, -72.564],
                                  [-71.829, -40.958],
                                  [-15.271, -41.439],
                                  [-162.208, 24.921],
                                  [54.635, 62.034],
                                  [-12.344, 80.142],
                                  [-17.823, 92],
                                ],
                                o: [
                                  [-83.628, 15.981],
                                  [-16.664, 76.378],
                                  [-22.89, 72.564],
                                  [71.829, 40.958],
                                  [22.366, 50.17],
                                  [106.543, -12.915],
                                  [-54.635, -62.034],
                                  [1.975, -89.42],
                                  [17.823, -92],
                                ],
                                v: [
                                  [-23.651, -322.077],
                                  [-170.416, -214.306],
                                  [-262.327, -36.668],
                                  [-244.523, 146.943],
                                  [-121.406, 271.265],
                                  [114.98, 367.224],
                                  [319.725, 259.423],
                                  [176.782, 6.031],
                                  [206.682, -163.912],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.944, -8.113],
                                  [16.939, -76.066],
                                  [22.696, -72.756],
                                  [-71.483, -40.995],
                                  [-15.257, -41.279],
                                  [-162.47, 25.041],
                                  [54.168, 61.919],
                                  [-12.147, 79.746],
                                  [-17.875, 92],
                                ],
                                o: [
                                  [-83.542, 15.793],
                                  [-16.939, 76.066],
                                  [-22.696, 72.756],
                                  [71.483, 40.995],
                                  [22.389, 50.056],
                                  [106.514, -12.972],
                                  [-54.168, -61.919],
                                  [1.724, -89.072],
                                  [17.875, -92],
                                ],
                                v: [
                                  [-23.171, -322.427],
                                  [-171.245, -214.774],
                                  [-262.214, -37.203],
                                  [-244.673, 146.885],
                                  [-121.549, 271.488],
                                  [114.965, 366.608],
                                  [320.186, 259.563],
                                  [177.495, 5.949],
                                  [206.084, -163.304],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.058, -7.884],
                                  [17.215, -75.754],
                                  [22.501, -72.947],
                                  [-71.136, -41.031],
                                  [-15.243, -41.119],
                                  [-162.734, 25.161],
                                  [53.7, 61.804],
                                  [-11.95, 79.348],
                                  [-17.926, 92],
                                ],
                                o: [
                                  [-83.456, 15.604],
                                  [-17.215, 75.754],
                                  [-22.501, 72.947],
                                  [71.136, 41.031],
                                  [22.412, 49.942],
                                  [106.486, -13.029],
                                  [-53.7, -61.804],
                                  [1.472, -88.723],
                                  [17.926, -92],
                                ],
                                v: [
                                  [-22.725, -322.743],
                                  [-172.016, -215.244],
                                  [-262.131, -37.679],
                                  [-244.782, 146.837],
                                  [-121.672, 271.704],
                                  [114.973, 365.986],
                                  [320.623, 259.677],
                                  [178.182, 5.809],
                                  [205.522, -162.691],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.173, -7.654],
                                  [17.492, -75.44],
                                  [22.306, -73.139],
                                  [-70.789, -41.068],
                                  [-15.228, -40.958],
                                  [-162.997, 25.281],
                                  [53.23, 61.689],
                                  [-11.752, 78.949],
                                  [-17.978, 92],
                                ],
                                o: [
                                  [-83.37, 15.415],
                                  [-17.492, 75.44],
                                  [-22.306, 73.139],
                                  [70.789, 41.068],
                                  [22.435, 49.827],
                                  [106.457, -13.086],
                                  [-53.23, -61.689],
                                  [1.22, -88.373],
                                  [17.978, -92],
                                ],
                                v: [
                                  [-22.312, -323.051],
                                  [-172.792, -215.74],
                                  [-262.022, -38.195],
                                  [-244.89, 146.794],
                                  [-121.838, 271.884],
                                  [114.912, 365.393],
                                  [321.064, 259.795],
                                  [178.87, 5.709],
                                  [204.937, -162.072],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.288, -7.424],
                                  [17.769, -75.126],
                                  [22.111, -73.332],
                                  [-70.44, -41.104],
                                  [-15.214, -40.797],
                                  [-163.262, 25.402],
                                  [52.759, 61.573],
                                  [-11.553, 78.55],
                                  [-18.03, 92],
                                ],
                                o: [
                                  [-83.284, 15.225],
                                  [-17.769, 75.126],
                                  [-22.111, 73.332],
                                  [70.44, 41.104],
                                  [22.458, 49.712],
                                  [106.428, -13.144],
                                  [-52.759, -61.573],
                                  [0.967, -88.022],
                                  [18.029, -92],
                                ],
                                v: [
                                  [-21.933, -323.322],
                                  [-173.573, -216.206],
                                  [-261.943, -38.68],
                                  [-244.992, 146.79],
                                  [-121.977, 272.062],
                                  [114.817, 364.767],
                                  [321.536, 259.944],
                                  [179.531, 5.58],
                                  [204.36, -161.476],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.403, -7.194],
                                  [18.048, -74.811],
                                  [21.915, -73.525],
                                  [-70.09, -41.141],
                                  [-15.2, -40.636],
                                  [-163.527, 25.523],
                                  [52.287, 61.457],
                                  [-11.354, 78.149],
                                  [-18.081, 92],
                                ],
                                o: [
                                  [-83.198, 15.035],
                                  [-18.048, 74.811],
                                  [-21.915, 73.525],
                                  [70.09, 41.141],
                                  [22.481, 49.597],
                                  [106.399, -13.202],
                                  [-52.287, -61.457],
                                  [0.713, -87.67],
                                  [18.081, -92],
                                ],
                                v: [
                                  [-21.589, -323.584],
                                  [-174.323, -216.696],
                                  [-261.83, -39.206],
                                  [-245.06, 146.791],
                                  [-122.132, 272.23],
                                  [114.744, 364.136],
                                  [322.012, 260.069],
                                  [180.168, 5.43],
                                  [203.825, -160.846],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.519, -6.962],
                                  [18.327, -74.496],
                                  [21.718, -73.719],
                                  [-69.74, -41.178],
                                  [-15.185, -40.474],
                                  [-163.793, 25.645],
                                  [51.814, 61.34],
                                  [-11.155, 77.747],
                                  [-18.133, 92],
                                ],
                                o: [
                                  [-83.111, 14.844],
                                  [-18.327, 74.496],
                                  [-21.718, 73.719],
                                  [69.74, 41.178],
                                  [22.504, 49.481],
                                  [106.37, -13.259],
                                  [-51.814, -61.34],
                                  [0.459, -87.318],
                                  [18.133, -92],
                                ],
                                v: [
                                  [-21.273, -323.871],
                                  [-175.043, -217.191],
                                  [-261.746, -39.709],
                                  [-245.156, 146.805],
                                  [-122.297, 272.36],
                                  [114.672, 363.534],
                                  [322.554, 260.197],
                                  [180.798, 5.32],
                                  [203.296, -160.212],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.635, -6.731],
                                  [18.606, -74.179],
                                  [21.521, -73.913],
                                  [-69.388, -41.215],
                                  [-15.171, -40.311],
                                  [-164.06, 25.766],
                                  [51.339, 61.224],
                                  [-10.955, 77.344],
                                  [-18.186, 92],
                                ],
                                o: [
                                  [-83.024, 14.653],
                                  [-18.606, 74.179],
                                  [-21.521, 73.913],
                                  [69.388, 41.215],
                                  [22.527, 49.365],
                                  [106.341, -13.317],
                                  [-51.339, -61.224],
                                  [0.204, -86.964],
                                  [18.186, -92],
                                ],
                                v: [
                                  [-21.027, -324.083],
                                  [-175.775, -217.675],
                                  [-261.6, -40.182],
                                  [-245.212, 146.824],
                                  [-122.443, 272.488],
                                  [114.532, 362.893],
                                  [323.037, 260.365],
                                  [181.411, 5.217],
                                  [202.767, -159.545],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.751, -6.498],
                                  [18.887, -73.861],
                                  [21.323, -74.108],
                                  [-69.036, -41.252],
                                  [-15.156, -40.149],
                                  [-164.327, 25.888],
                                  [50.863, 61.107],
                                  [-10.755, 76.94],
                                  [-18.238, 92],
                                ],
                                o: [
                                  [-82.937, 14.461],
                                  [-18.887, 73.861],
                                  [-21.323, 74.108],
                                  [69.036, 41.252],
                                  [22.55, 49.249],
                                  [106.312, -13.375],
                                  [-50.863, -61.107],
                                  [-0.052, -86.61],
                                  [18.238, -92],
                                ],
                                v: [
                                  [-20.782, -324.293],
                                  [-176.44, -218.165],
                                  [-261.518, -40.695],
                                  [-245.269, 146.885],
                                  [-122.6, 272.603],
                                  [114.45, 362.255],
                                  [323.551, 260.528],
                                  [181.992, 5.156],
                                  [202.253, -158.9],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.867, -6.265],
                                  [19.168, -73.543],
                                  [21.125, -74.303],
                                  [-68.683, -41.289],
                                  [-15.142, -39.986],
                                  [-164.595, 26.011],
                                  [50.386, 60.99],
                                  [-10.554, 76.536],
                                  [-18.29, 92],
                                ],
                                o: [
                                  [-82.849, 14.269],
                                  [-19.168, 73.543],
                                  [-21.125, 74.303],
                                  [68.683, 41.289],
                                  [22.574, 49.133],
                                  [106.283, -13.434],
                                  [-50.386, -60.99],
                                  [-0.308, -86.254],
                                  [18.29, -92],
                                ],
                                v: [
                                  [-20.573, -324.525],
                                  [-177.119, -218.652],
                                  [-261.401, -41.179],
                                  [-245.322, 146.925],
                                  [-122.765, 272.652],
                                  [114.334, 361.612],
                                  [324.069, 260.667],
                                  [182.547, 5.038],
                                  [201.746, -158.25],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.984, -6.031],
                                  [19.45, -73.224],
                                  [20.927, -74.499],
                                  [-68.329, -41.326],
                                  [-15.127, -39.822],
                                  [-164.864, 26.133],
                                  [49.908, 60.872],
                                  [-10.352, 76.13],
                                  [-18.343, 92],
                                ],
                                o: [
                                  [-82.762, 14.076],
                                  [-19.45, 73.224],
                                  [-20.927, 74.499],
                                  [68.329, 41.326],
                                  [22.597, 49.016],
                                  [106.254, -13.492],
                                  [-49.908, -60.872],
                                  [-0.565, -85.898],
                                  [18.343, -92],
                                ],
                                v: [
                                  [-20.428, -324.708],
                                  [-177.793, -219.138],
                                  [-261.249, -41.676],
                                  [-245.377, 146.998],
                                  [-122.94, 272.724],
                                  [114.25, 360.929],
                                  [324.616, 260.811],
                                  [183.141, 4.964],
                                  [201.273, -157.594],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.101, -5.797],
                                  [19.732, -72.904],
                                  [20.728, -74.695],
                                  [-67.974, -41.364],
                                  [-15.112, -39.658],
                                  [-165.133, 26.256],
                                  [49.429, 60.754],
                                  [-10.15, 75.723],
                                  [-18.396, 92],
                                ],
                                o: [
                                  [-82.674, 13.883],
                                  [-19.732, 72.904],
                                  [-20.728, 74.695],
                                  [67.974, 41.364],
                                  [22.62, 48.899],
                                  [106.225, -13.551],
                                  [-49.429, -60.754],
                                  [-0.823, -85.541],
                                  [18.396, -92],
                                ],
                                v: [
                                  [-20.319, -324.886],
                                  [-178.408, -219.656],
                                  [-261.16, -42.144],
                                  [-245.428, 147.087],
                                  [-123.135, 272.756],
                                  [114.134, 360.313],
                                  [325.104, 260.967],
                                  [183.703, 4.897],
                                  [200.781, -156.907],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.219, -5.562],
                                  [20.015, -72.584],
                                  [20.528, -74.891],
                                  [-67.618, -41.401],
                                  [-15.098, -39.494],
                                  [-165.403, 26.38],
                                  [48.948, 60.636],
                                  [-9.948, 75.315],
                                  [-18.448, 92],
                                ],
                                o: [
                                  [-82.586, 13.689],
                                  [-20.015, 72.584],
                                  [-20.528, 74.892],
                                  [67.618, 41.401],
                                  [22.644, 48.781],
                                  [106.195, -13.609],
                                  [-48.948, -60.636],
                                  [-1.081, -85.183],
                                  [18.448, -92],
                                ],
                                v: [
                                  [-20.249, -325.022],
                                  [-179.002, -220.137],
                                  [-261.034, -42.616],
                                  [-245.448, 147.183],
                                  [-123.303, 272.782],
                                  [114.014, 359.63],
                                  [325.657, 261.118],
                                  [184.214, 4.836],
                                  [200.322, -156.241],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.337, -5.327],
                                  [20.299, -72.262],
                                  [20.328, -75.089],
                                  [-67.261, -41.439],
                                  [-15.083, -39.329],
                                  [-165.674, 26.503],
                                  [48.466, 60.518],
                                  [-9.745, 74.906],
                                  [-18.501, 92],
                                ],
                                o: [
                                  [-82.498, 13.495],
                                  [-20.299, 72.262],
                                  [-20.328, 75.089],
                                  [67.261, 41.438],
                                  [22.667, 48.663],
                                  [106.166, -13.668],
                                  [-48.466, -60.518],
                                  [-1.34, -84.824],
                                  [18.501, -92],
                                ],
                                v: [
                                  [-20.206, -325.116],
                                  [-179.662, -220.625],
                                  [-260.909, -43.095],
                                  [-245.5, 147.296],
                                  [-123.545, 272.768],
                                  [113.925, 358.98],
                                  [326.239, 261.273],
                                  [184.754, 4.783],
                                  [199.835, -155.543],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.455, -5.091],
                                  [20.584, -71.94],
                                  [20.127, -75.286],
                                  [-66.903, -41.476],
                                  [-15.068, -39.164],
                                  [-165.945, 26.627],
                                  [47.983, 60.399],
                                  [-9.541, 74.496],
                                  [-18.555, 92],
                                ],
                                o: [
                                  [-82.409, 13.3],
                                  [-20.584, 71.94],
                                  [-20.127, 75.286],
                                  [66.903, 41.476],
                                  [22.691, 48.545],
                                  [106.136, -13.727],
                                  [-47.983, -60.399],
                                  [-1.6, -84.464],
                                  [18.555, -92],
                                ],
                                v: [
                                  [-20.201, -325.193],
                                  [-180.265, -221.138],
                                  [-260.809, -43.552],
                                  [-245.521, 147.406],
                                  [-123.733, 272.738],
                                  [113.807, 358.326],
                                  [326.762, 261.431],
                                  [185.246, 4.712],
                                  [199.364, -154.841],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.573, -4.854],
                                  [20.869, -71.617],
                                  [19.926, -75.484],
                                  [-66.545, -41.514],
                                  [-15.053, -38.998],
                                  [-166.218, 26.751],
                                  [47.499, 60.28],
                                  [-9.337, 74.084],
                                  [-18.608, 92],
                                ],
                                o: [
                                  [-82.32, 13.105],
                                  [-20.869, 71.617],
                                  [-19.926, 75.484],
                                  [66.545, 41.514],
                                  [22.715, 48.427],
                                  [106.107, -13.786],
                                  [-47.499, -60.28],
                                  [-1.86, -84.103],
                                  [18.608, -92],
                                ],
                                v: [
                                  [-20.197, -325.264],
                                  [-180.81, -221.66],
                                  [-260.709, -44.042],
                                  [-245.539, 147.597],
                                  [-123.969, 272.676],
                                  [113.71, 357.642],
                                  [327.312, 261.568],
                                  [185.731, 4.71],
                                  [198.927, -154.133],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.691, -4.617],
                                  [21.156, -71.293],
                                  [19.725, -75.683],
                                  [-66.185, -41.552],
                                  [-15.039, -38.832],
                                  [-166.49, 26.876],
                                  [47.013, 60.16],
                                  [-9.132, 73.672],
                                  [-18.661, 92],
                                ],
                                o: [
                                  [-82.231, 12.909],
                                  [-21.156, 71.293],
                                  [-19.725, 75.683],
                                  [66.185, 41.552],
                                  [22.738, 48.309],
                                  [106.077, -13.846],
                                  [-47.013, -60.161],
                                  [-2.121, -83.741],
                                  [18.661, -92],
                                ],
                                v: [
                                  [-20.258, -325.353],
                                  [-181.421, -222.143],
                                  [-260.571, -44.474],
                                  [-245.564, 147.733],
                                  [-124.19, 272.635],
                                  [113.621, 356.992],
                                  [327.866, 261.697],
                                  [186.204, 4.69],
                                  [198.497, -153.458],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.81, -4.379],
                                  [21.442, -70.968],
                                  [19.522, -75.882],
                                  [-65.825, -41.59],
                                  [-15.024, -38.666],
                                  [-166.764, 27.001],
                                  [46.526, 60.041],
                                  [-8.927, 73.259],
                                  [-18.715, 92],
                                ],
                                o: [
                                  [-82.142, 12.713],
                                  [-21.442, 70.968],
                                  [-19.522, 75.882],
                                  [65.825, 41.59],
                                  [22.762, 48.19],
                                  [106.047, -13.905],
                                  [-46.526, -60.041],
                                  [-2.383, -83.379],
                                  [18.715, -92],
                                ],
                                v: [
                                  [-20.358, -325.335],
                                  [-181.975, -222.637],
                                  [-260.457, -44.949],
                                  [-245.561, 147.914],
                                  [-124.447, 272.524],
                                  [113.528, 356.338],
                                  [328.446, 261.841],
                                  [186.682, 4.704],
                                  [198.062, -152.778],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.93, -4.141],
                                  [21.73, -70.642],
                                  [19.32, -76.082],
                                  [-65.464, -41.628],
                                  [-15.009, -38.499],
                                  [-167.038, 27.126],
                                  [46.038, 59.921],
                                  [-8.722, 72.845],
                                  [-18.768, 92],
                                ],
                                o: [
                                  [-82.053, 12.516],
                                  [-21.73, 70.642],
                                  [-19.32, 76.082],
                                  [65.464, 41.628],
                                  [22.786, 48.07],
                                  [106.018, -13.965],
                                  [-46.039, -59.921],
                                  [-2.645, -83.015],
                                  [18.768, -92],
                                ],
                                v: [
                                  [-20.486, -325.335],
                                  [-182.533, -223.167],
                                  [-260.343, -45.368],
                                  [-245.555, 148.103],
                                  [-124.715, 272.396],
                                  [113.406, 355.719],
                                  [328.991, 261.953],
                                  [187.127, 4.7],
                                  [197.646, -152.055],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.049, -3.902],
                                  [22.018, -70.316],
                                  [19.117, -76.282],
                                  [-65.101, -41.666],
                                  [-14.994, -38.331],
                                  [-167.313, 27.251],
                                  [45.549, 59.801],
                                  [-8.515, 72.43],
                                  [-18.822, 92],
                                ],
                                o: [
                                  [-81.963, 12.319],
                                  [-22.018, 70.316],
                                  [-19.117, 76.282],
                                  [65.101, 41.666],
                                  [22.81, 47.951],
                                  [105.988, -14.024],
                                  [-45.549, -59.801],
                                  [-2.908, -82.65],
                                  [18.822, -92],
                                ],
                                v: [
                                  [-20.652, -325.29],
                                  [-183.034, -223.659],
                                  [-260.227, -45.817],
                                  [-245.522, 148.3],
                                  [-125.018, 272.237],
                                  [113.319, 355.097],
                                  [329.538, 262.105],
                                  [187.587, 4.704],
                                  [197.225, -151.403],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.169, -3.662],
                                  [22.307, -69.989],
                                  [18.913, -76.483],
                                  [-64.738, -41.704],
                                  [-14.979, -38.164],
                                  [-167.588, 27.377],
                                  [45.059, 59.68],
                                  [-8.309, 72.013],
                                  [-18.876, 92],
                                ],
                                o: [
                                  [-81.873, 12.121],
                                  [-22.307, 69.989],
                                  [-18.913, 76.483],
                                  [64.738, 41.704],
                                  [22.834, 47.831],
                                  [105.958, -14.084],
                                  [-45.059, -59.68],
                                  [-3.171, -82.285],
                                  [18.876, -92],
                                ],
                                v: [
                                  [-20.883, -325.236],
                                  [-183.539, -224.187],
                                  [-260.135, -46.234],
                                  [-245.499, 148.568],
                                  [-125.306, 272.095],
                                  [113.229, 354.448],
                                  [330.087, 262.225],
                                  [187.99, 4.754],
                                  [196.785, -150.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.289, -3.422],
                                  [22.597, -69.661],
                                  [18.709, -76.684],
                                  [-64.374, -41.742],
                                  [-14.964, -37.995],
                                  [-167.864, 27.503],
                                  [44.567, 59.559],
                                  [-8.102, 71.596],
                                  [-18.93, 92],
                                ],
                                o: [
                                  [-81.783, 11.923],
                                  [-22.597, 69.661],
                                  [-18.709, 76.684],
                                  [64.374, 41.742],
                                  [22.858, 47.711],
                                  [105.928, -14.144],
                                  [-44.567, -59.559],
                                  [-3.436, -81.919],
                                  [18.93, -92],
                                ],
                                v: [
                                  [-21.079, -325.135],
                                  [-184.048, -224.691],
                                  [-260.027, -46.67],
                                  [-245.474, 148.781],
                                  [-125.606, 271.885],
                                  [113.136, 353.872],
                                  [330.661, 262.348],
                                  [188.436, 4.813],
                                  [196.377, -149.961],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.409, -3.181],
                                  [22.887, -69.332],
                                  [18.504, -76.886],
                                  [-64.009, -41.78],
                                  [-14.949, -37.827],
                                  [-168.141, 27.63],
                                  [44.075, 59.438],
                                  [-7.894, 71.178],
                                  [-18.984, 92],
                                ],
                                o: [
                                  [-81.693, 11.725],
                                  [-22.887, 69.332],
                                  [-18.504, 76.886],
                                  [64.009, 41.78],
                                  [22.882, 47.591],
                                  [105.898, -14.205],
                                  [-44.074, -59.438],
                                  [-3.7, -81.552],
                                  [18.984, -92],
                                ],
                                v: [
                                  [-21.314, -325.05],
                                  [-184.538, -225.232],
                                  [-259.893, -47.074],
                                  [-245.461, 149.017],
                                  [-125.94, 271.654],
                                  [113.079, 353.294],
                                  [331.199, 262.436],
                                  [188.848, 4.904],
                                  [196, -149.296],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.53, -2.94],
                                  [23.179, -69.003],
                                  [18.299, -77.088],
                                  [-63.644, -41.819],
                                  [-14.934, -37.658],
                                  [-168.419, 27.756],
                                  [43.58, 59.317],
                                  [-7.686, 70.758],
                                  [-19.039, 92],
                                ],
                                o: [
                                  [-81.603, 11.526],
                                  [-23.179, 69.003],
                                  [-18.299, 77.088],
                                  [63.644, 41.819],
                                  [22.906, 47.47],
                                  [105.867, -14.265],
                                  [-43.58, -59.317],
                                  [-3.966, -81.184],
                                  [19.038, -92],
                                ],
                                v: [
                                  [-21.614, -324.894],
                                  [-184.995, -225.735],
                                  [-259.843, -47.469],
                                  [-245.424, 149.286],
                                  [-126.285, 271.43],
                                  [112.995, 352.715],
                                  [331.761, 262.567],
                                  [189.24, 5.018],
                                  [195.567, -148.602],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.651, -2.698],
                                  [23.47, -68.672],
                                  [18.093, -77.29],
                                  [-63.277, -41.858],
                                  [-14.919, -37.489],
                                  [-168.697, 27.884],
                                  [43.085, 59.195],
                                  [-7.477, 70.338],
                                  [-19.093, 92],
                                ],
                                o: [
                                  [-81.512, 11.326],
                                  [-23.47, 68.672],
                                  [-18.093, 77.29],
                                  [63.277, 41.857],
                                  [22.93, 47.349],
                                  [105.837, -14.325],
                                  [-43.085, -59.195],
                                  [-4.232, -80.815],
                                  [19.093, -92],
                                ],
                                v: [
                                  [-21.941, -324.728],
                                  [-185.418, -226.253],
                                  [-259.753, -47.884],
                                  [-245.362, 149.602],
                                  [-126.641, 271.122],
                                  [112.947, 352.172],
                                  [332.324, 262.639],
                                  [189.652, 5.102],
                                  [195.164, -147.943],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.772, -2.456],
                                  [23.763, -68.341],
                                  [17.887, -77.493],
                                  [-62.909, -41.896],
                                  [-14.903, -37.319],
                                  [-168.976, 28.011],
                                  [42.589, 59.073],
                                  [-7.268, 69.917],
                                  [-19.148, 92],
                                ],
                                o: [
                                  [-81.421, 11.126],
                                  [-23.763, 68.341],
                                  [-17.887, 77.493],
                                  [62.909, 41.896],
                                  [22.954, 47.228],
                                  [105.807, -14.386],
                                  [-42.589, -59.073],
                                  [-4.499, -80.445],
                                  [19.148, -92],
                                ],
                                v: [
                                  [-22.245, -324.538],
                                  [-185.845, -226.785],
                                  [-259.621, -48.265],
                                  [-245.299, 149.889],
                                  [-127.03, 270.845],
                                  [112.898, 351.643],
                                  [332.849, 262.715],
                                  [190.031, 5.233],
                                  [194.767, -147.217],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.894, -2.212],
                                  [24.056, -68.009],
                                  [17.681, -77.697],
                                  [-62.541, -41.935],
                                  [-14.888, -37.149],
                                  [-169.256, 28.138],
                                  [42.091, 58.951],
                                  [-7.058, 69.494],
                                  [-19.202, 92],
                                ],
                                o: [
                                  [-81.33, 10.925],
                                  [-24.056, 68.009],
                                  [-17.681, 77.697],
                                  [62.541, 41.935],
                                  [22.979, 47.106],
                                  [105.777, -14.447],
                                  [-42.091, -58.951],
                                  [-4.766, -80.074],
                                  [19.202, -92],
                                ],
                                v: [
                                  [-22.638, -324.315],
                                  [-186.253, -227.304],
                                  [-259.548, -48.676],
                                  [-245.252, 150.185],
                                  [-127.43, 270.522],
                                  [112.847, 351.137],
                                  [333.398, 262.779],
                                  [190.415, 5.436],
                                  [194.399, -146.551],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.016, -1.969],
                                  [24.35, -67.676],
                                  [17.473, -77.901],
                                  [-62.171, -41.974],
                                  [-14.873, -36.978],
                                  [-169.536, 28.266],
                                  [41.592, 58.828],
                                  [-6.848, 69.071],
                                  [-19.257, 92],
                                ],
                                o: [
                                  [-81.238, 10.724],
                                  [-24.35, 67.676],
                                  [-17.473, 77.901],
                                  [62.171, 41.974],
                                  [23.003, 46.984],
                                  [105.746, -14.508],
                                  [-41.592, -58.828],
                                  [-5.034, -79.702],
                                  [19.257, -92],
                                ],
                                v: [
                                  [-22.993, -324.042],
                                  [-186.603, -227.839],
                                  [-259.472, -49.052],
                                  [-245.182, 150.528],
                                  [-127.84, 270.191],
                                  [112.833, 350.646],
                                  [333.946, 262.863],
                                  [190.781, 5.585],
                                  [193.998, -145.92],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.138, -1.724],
                                  [24.645, -67.342],
                                  [17.266, -78.106],
                                  [-61.801, -42.013],
                                  [-14.858, -36.807],
                                  [-169.817, 28.395],
                                  [41.092, 58.705],
                                  [-6.637, 68.646],
                                  [-19.312, 92],
                                ],
                                o: [
                                  [-81.147, 10.523],
                                  [-24.645, 67.342],
                                  [-17.266, 78.106],
                                  [61.801, 42.013],
                                  [23.028, 46.862],
                                  [105.716, -14.569],
                                  [-41.092, -58.705],
                                  [-5.303, -79.33],
                                  [19.312, -92],
                                ],
                                v: [
                                  [-23.429, -323.76],
                                  [-186.981, -228.391],
                                  [-259.377, -49.409],
                                  [-245.09, 150.841],
                                  [-128.323, 269.853],
                                  [112.795, 350.194],
                                  [334.455, 262.888],
                                  [191.169, 5.806],
                                  [193.603, -145.262],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.26, -1.479],
                                  [24.941, -67.008],
                                  [17.057, -78.311],
                                  [-61.43, -42.052],
                                  [-14.842, -36.636],
                                  [-170.099, 28.523],
                                  [40.591, 58.582],
                                  [-6.426, 68.22],
                                  [-19.367, 92],
                                ],
                                o: [
                                  [-81.055, 10.32],
                                  [-24.941, 67.008],
                                  [-17.057, 78.311],
                                  [61.43, 42.052],
                                  [23.052, 46.74],
                                  [105.685, -14.63],
                                  [-40.591, -58.582],
                                  [-5.573, -78.956],
                                  [19.367, -92],
                                ],
                                v: [
                                  [-23.85, -323.427],
                                  [-187.301, -228.906],
                                  [-259.301, -49.795],
                                  [-245.038, 151.186],
                                  [-128.776, 269.468],
                                  [112.795, 349.782],
                                  [334.986, 262.94],
                                  [191.547, 6.036],
                                  [193.235, -144.602],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.383, -1.234],
                                  [25.237, -66.672],
                                  [16.849, -78.517],
                                  [-61.058, -42.091],
                                  [-14.827, -36.464],
                                  [-170.381, 28.652],
                                  [40.088, 58.458],
                                  [-6.214, 67.794],
                                  [-19.422, 92],
                                ],
                                o: [
                                  [-80.963, 10.118],
                                  [-25.237, 66.672],
                                  [-16.849, 78.517],
                                  [61.058, 42.091],
                                  [23.077, 46.617],
                                  [105.654, -14.692],
                                  [-40.088, -58.458],
                                  [-5.843, -78.582],
                                  [19.422, -92],
                                ],
                                v: [
                                  [-24.273, -323.085],
                                  [-187.602, -229.463],
                                  [-259.26, -50.146],
                                  [-244.926, 151.557],
                                  [-129.262, 269.051],
                                  [112.817, 349.37],
                                  [335.476, 262.955],
                                  [191.885, 6.314],
                                  [192.833, -143.939],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.506, -0.988],
                                  [25.534, -66.336],
                                  [16.64, -78.723],
                                  [-60.684, -42.13],
                                  [-14.812, -36.291],
                                  [-170.664, 28.781],
                                  [39.584, 58.334],
                                  [-6.002, 67.366],
                                  [-19.478, 92],
                                ],
                                o: [
                                  [-80.87, 9.915],
                                  [-25.534, 66.336],
                                  [-16.64, 78.723],
                                  [60.684, 42.13],
                                  [23.101, 46.494],
                                  [105.624, -14.753],
                                  [-39.584, -58.334],
                                  [-6.114, -78.206],
                                  [19.478, -92],
                                ],
                                v: [
                                  [-24.76, -322.731],
                                  [-187.892, -229.998],
                                  [-259.198, -50.523],
                                  [-244.833, 151.897],
                                  [-129.78, 268.627],
                                  [112.816, 349.016],
                                  [335.987, 262.992],
                                  [192.293, 6.602],
                                  [192.436, -143.312],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.63, -0.741],
                                  [25.831, -65.999],
                                  [16.43, -78.929],
                                  [-60.311, -42.169],
                                  [-14.796, -36.119],
                                  [-170.948, 28.911],
                                  [39.079, 58.21],
                                  [-5.789, 66.937],
                                  [-19.533, 92],
                                ],
                                o: [
                                  [-80.778, 9.711],
                                  [-25.831, 65.999],
                                  [-16.43, 78.929],
                                  [60.311, 42.169],
                                  [23.126, 46.37],
                                  [105.593, -14.815],
                                  [-39.079, -58.21],
                                  [-6.385, -77.83],
                                  [19.533, -92],
                                ],
                                v: [
                                  [-25.208, -322.305],
                                  [-188.162, -230.576],
                                  [-259.131, -50.864],
                                  [-244.759, 152.269],
                                  [-130.307, 268.196],
                                  [112.854, 348.686],
                                  [336.457, 262.952],
                                  [192.644, 6.9],
                                  [192.043, -142.683],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.753, -0.494],
                                  [26.13, -65.662],
                                  [16.22, -79.137],
                                  [-59.936, -42.209],
                                  [-14.781, -35.945],
                                  [-171.232, 29.041],
                                  [38.573, 58.086],
                                  [-5.576, 66.508],
                                  [-19.589, 92],
                                ],
                                o: [
                                  [-80.685, 9.507],
                                  [-26.13, 65.662],
                                  [-16.22, 79.137],
                                  [59.936, 42.209],
                                  [23.151, 46.247],
                                  [105.562, -14.877],
                                  [-38.573, -58.086],
                                  [-6.657, -77.453],
                                  [19.589, -92],
                                ],
                                v: [
                                  [-25.72, -321.867],
                                  [-188.397, -231.095],
                                  [-259.081, -51.209],
                                  [-244.627, 152.627],
                                  [-130.906, 267.716],
                                  [112.892, 348.336],
                                  [336.924, 262.956],
                                  [193.02, 7.247],
                                  [191.614, -142.092],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.877, -0.246],
                                  [26.429, -65.323],
                                  [16.009, -79.344],
                                  [-59.56, -42.248],
                                  [-14.765, -35.772],
                                  [-171.517, 29.171],
                                  [38.065, 57.961],
                                  [-5.362, 66.077],
                                  [-19.645, 92],
                                ],
                                o: [
                                  [-80.592, 9.303],
                                  [-26.429, 65.323],
                                  [-16.009, 79.344],
                                  [59.56, 42.248],
                                  [23.175, 46.123],
                                  [105.531, -14.939],
                                  [-38.065, -57.961],
                                  [-6.93, -77.075],
                                  [19.645, -92],
                                ],
                                v: [
                                  [-26.233, -321.397],
                                  [-188.614, -231.656],
                                  [-259.008, -51.557],
                                  [-244.517, 153.016],
                                  [-131.496, 267.247],
                                  [112.93, 348.09],
                                  [337.389, 262.901],
                                  [193.363, 7.626],
                                  [191.149, -141.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.001, 0.003],
                                  [26.728, -64.984],
                                  [15.798, -79.552],
                                  [-59.183, -42.288],
                                  [-14.75, -35.598],
                                  [-171.803, 29.302],
                                  [37.557, 57.836],
                                  [-5.148, 65.645],
                                  [-19.701, 92],
                                ],
                                o: [
                                  [-80.499, 9.098],
                                  [-26.728, 64.984],
                                  [-15.798, 79.552],
                                  [59.183, 42.288],
                                  [23.2, 45.999],
                                  [105.5, -15.001],
                                  [-37.557, -57.836],
                                  [-7.203, -76.696],
                                  [19.701, -92],
                                ],
                                v: [
                                  [-26.729, -320.898],
                                  [-188.795, -232.24],
                                  [-258.97, -51.89],
                                  [-244.41, 153.373],
                                  [-132.116, 266.729],
                                  [112.987, 347.864],
                                  [337.79, 262.827],
                                  [193.753, 8.056],
                                  [190.751, -140.903],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.126, 0.252],
                                  [27.029, -64.643],
                                  [15.586, -79.761],
                                  [-58.806, -42.328],
                                  [-14.734, -35.424],
                                  [-172.09, 29.432],
                                  [37.047, 57.711],
                                  [-4.933, 65.212],
                                  [-19.757, 92],
                                ],
                                o: [
                                  [-80.406, 8.892],
                                  [-27.029, 64.644],
                                  [-15.586, 79.761],
                                  [58.806, 42.328],
                                  [23.225, 45.874],
                                  [105.469, -15.063],
                                  [-37.047, -57.711],
                                  [-7.477, -76.316],
                                  [19.757, -92],
                                ],
                                v: [
                                  [-27.266, -320.344],
                                  [-188.939, -232.787],
                                  [-258.947, -52.206],
                                  [-244.286, 153.738],
                                  [-132.745, 266.204],
                                  [113.106, 347.642],
                                  [338.188, 262.717],
                                  [194.151, 8.495],
                                  [190.294, -140.324],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.251, 0.502],
                                  [27.33, -64.303],
                                  [15.374, -79.97],
                                  [-58.427, -42.367],
                                  [-14.719, -35.249],
                                  [-172.377, 29.563],
                                  [36.536, 57.585],
                                  [-4.717, 64.779],
                                  [-19.813, 92],
                                ],
                                o: [
                                  [-80.312, 8.686],
                                  [-27.33, 64.303],
                                  [-15.374, 79.97],
                                  [58.427, 42.367],
                                  [23.25, 45.749],
                                  [105.437, -15.125],
                                  [-36.536, -57.585],
                                  [-7.752, -75.935],
                                  [19.813, -92],
                                ],
                                v: [
                                  [-27.804, -319.802],
                                  [-189.065, -233.357],
                                  [-258.899, -52.566],
                                  [-244.186, 154.112],
                                  [-133.402, 265.671],
                                  [113.226, 347.486],
                                  [338.521, 262.63],
                                  [194.535, 8.985],
                                  [189.8, -139.766],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.376, 0.752],
                                  [27.632, -63.961],
                                  [15.161, -80.18],
                                  [-58.048, -42.407],
                                  [-14.703, -35.074],
                                  [-172.665, 29.695],
                                  [36.023, 57.459],
                                  [-4.501, 64.344],
                                  [-19.869, 92],
                                ],
                                o: [
                                  [-80.218, 8.48],
                                  [-27.632, 63.961],
                                  [-15.161, 80.18],
                                  [58.048, 42.407],
                                  [23.275, 45.624],
                                  [105.406, -15.188],
                                  [-36.023, -57.459],
                                  [-8.027, -75.553],
                                  [19.869, -92],
                                ],
                                v: [
                                  [-28.322, -319.206],
                                  [-189.176, -233.931],
                                  [-258.885, -52.866],
                                  [-244.029, 154.515],
                                  [-134.068, 265.13],
                                  [113.325, 347.352],
                                  [338.83, 262.444],
                                  [194.905, 9.508],
                                  [189.309, -139.225],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.501, 1.003],
                                  [27.935, -63.618],
                                  [14.948, -80.39],
                                  [-57.668, -42.447],
                                  [-14.687, -34.898],
                                  [-172.953, 29.826],
                                  [35.51, 57.333],
                                  [-4.285, 63.908],
                                  [-19.926, 92],
                                ],
                                o: [
                                  [-80.124, 8.273],
                                  [-27.935, 63.618],
                                  [-14.948, 80.39],
                                  [57.668, 42.447],
                                  [23.3, 45.499],
                                  [105.375, -15.251],
                                  [-35.51, -57.333],
                                  [-8.303, -75.171],
                                  [19.926, -92],
                                ],
                                v: [
                                  [-28.882, -318.599],
                                  [-189.227, -234.552],
                                  [-258.886, -53.189],
                                  [-243.898, 154.864],
                                  [-134.741, 264.581],
                                  [113.488, 347.264],
                                  [339.097, 262.304],
                                  [195.305, 10.06],
                                  [188.802, -138.726],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.627, 1.254],
                                  [28.238, -63.275],
                                  [14.734, -80.6],
                                  [-57.286, -42.487],
                                  [-14.672, -34.722],
                                  [-173.242, 29.958],
                                  [34.995, 57.206],
                                  [-4.068, 63.471],
                                  [-19.982, 92],
                                ],
                                o: [
                                  [-80.03, 8.065],
                                  [-28.238, 63.275],
                                  [-14.734, 80.6],
                                  [57.286, 42.487],
                                  [23.325, 45.373],
                                  [105.343, -15.314],
                                  [-34.995, -57.206],
                                  [-8.58, -74.787],
                                  [19.982, -92],
                                ],
                                v: [
                                  [-29.422, -317.941],
                                  [-189.261, -235.094],
                                  [-258.838, -53.534],
                                  [-243.773, 155.201],
                                  [-135.444, 264.024],
                                  [113.631, 347.221],
                                  [339.319, 262.105],
                                  [195.733, 10.666],
                                  [188.276, -138.183],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.753, 1.506],
                                  [28.542, -62.93],
                                  [14.52, -80.812],
                                  [-56.904, -42.528],
                                  [-14.656, -34.546],
                                  [-173.532, 30.091],
                                  [34.479, 57.08],
                                  [-3.851, 63.033],
                                  [-20.039, 92],
                                ],
                                o: [
                                  [-79.935, 7.857],
                                  [-28.542, 62.93],
                                  [-14.52, 80.812],
                                  [56.904, 42.528],
                                  [23.351, 45.247],
                                  [105.312, -15.377],
                                  [-34.479, -57.08],
                                  [-8.857, -74.403],
                                  [20.039, -92],
                                ],
                                v: [
                                  [-29.941, -317.252],
                                  [-189.277, -235.703],
                                  [-258.804, -53.837],
                                  [-243.633, 155.586],
                                  [-136.153, 263.459],
                                  [113.796, 347.225],
                                  [339.539, 261.849],
                                  [196.17, 11.302],
                                  [187.775, -137.682],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.88, 1.759],
                                  [28.847, -62.585],
                                  [14.305, -81.023],
                                  [-56.521, -42.568],
                                  [-14.64, -34.369],
                                  [-173.823, 30.224],
                                  [33.962, 56.952],
                                  [-3.633, 62.594],
                                  [-20.096, 92],
                                ],
                                o: [
                                  [-79.84, 7.649],
                                  [-28.847, 62.585],
                                  [-14.305, 81.023],
                                  [56.521, 42.568],
                                  [23.376, 45.12],
                                  [105.28, -15.44],
                                  [-33.962, -56.953],
                                  [-9.135, -74.017],
                                  [20.096, -92],
                                ],
                                v: [
                                  [-30.459, -316.553],
                                  [-189.276, -236.298],
                                  [-258.803, -54.16],
                                  [-243.521, 155.916],
                                  [-136.891, 262.886],
                                  [113.983, 347.276],
                                  [339.694, 261.597],
                                  [196.616, 12.012],
                                  [187.213, -137.202],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.006, 2.012],
                                  [29.152, -62.239],
                                  [14.09, -81.235],
                                  [-56.138, -42.608],
                                  [-14.624, -34.191],
                                  [-174.114, 30.357],
                                  [33.444, 56.825],
                                  [-3.414, 62.154],
                                  [-20.153, 92],
                                ],
                                o: [
                                  [-79.745, 7.44],
                                  [-29.152, 62.239],
                                  [-14.09, 81.235],
                                  [56.138, 42.608],
                                  [23.401, 44.994],
                                  [105.248, -15.503],
                                  [-33.444, -56.825],
                                  [-9.414, -73.631],
                                  [20.153, -92],
                                ],
                                v: [
                                  [-30.958, -315.865],
                                  [-189.236, -236.92],
                                  [-258.774, -54.461],
                                  [-243.355, 156.232],
                                  [-137.593, 262.305],
                                  [114.235, 347.332],
                                  [339.848, 261.35],
                                  [197.07, 12.733],
                                  [186.633, -136.742],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.133, 2.266],
                                  [29.459, -61.893],
                                  [13.874, -81.448],
                                  [-55.753, -42.649],
                                  [-14.608, -34.014],
                                  [-174.406, 30.49],
                                  [32.924, 56.697],
                                  [-3.195, 61.712],
                                  [-20.21, 92],
                                ],
                                o: [
                                  [-79.65, 7.23],
                                  [-29.459, 61.893],
                                  [-13.874, 81.448],
                                  [55.753, 42.649],
                                  [23.427, 44.867],
                                  [105.217, -15.567],
                                  [-32.924, -56.697],
                                  [-9.693, -73.244],
                                  [20.21, -92],
                                ],
                                v: [
                                  [-31.434, -315.083],
                                  [-189.137, -237.506],
                                  [-258.758, -54.781],
                                  [-243.238, 156.532],
                                  [-138.344, 261.696],
                                  [114.446, 347.479],
                                  [339.915, 261.087],
                                  [197.576, 13.508],
                                  [186.055, -136.304],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.26, 2.521],
                                  [29.766, -61.545],
                                  [13.657, -81.661],
                                  [-55.367, -42.689],
                                  [-14.592, -33.836],
                                  [-174.699, 30.623],
                                  [32.403, 56.569],
                                  [-2.976, 61.27],
                                  [-20.267, 92],
                                ],
                                o: [
                                  [-79.555, 7.02],
                                  [-29.766, 61.545],
                                  [-13.657, 81.661],
                                  [55.367, 42.689],
                                  [23.452, 44.74],
                                  [105.185, -15.63],
                                  [-32.403, -56.569],
                                  [-9.973, -72.856],
                                  [20.267, -92],
                                ],
                                v: [
                                  [-31.871, -314.313],
                                  [-189.061, -238.121],
                                  [-258.713, -55.077],
                                  [-243.109, 156.839],
                                  [-139.06, 261.099],
                                  [114.744, 347.632],
                                  [339.96, 260.767],
                                  [198.071, 14.337],
                                  [185.436, -135.867],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.388, 2.776],
                                  [30.073, -61.197],
                                  [13.441, -81.875],
                                  [-54.981, -42.73],
                                  [-14.577, -33.657],
                                  [-174.992, 30.757],
                                  [31.881, 56.441],
                                  [-2.756, 60.827],
                                  [-20.325, 92],
                                ],
                                o: [
                                  [-79.459, 6.81],
                                  [-30.073, 61.197],
                                  [-13.441, 81.875],
                                  [54.981, 42.73],
                                  [23.478, 44.612],
                                  [105.153, -15.694],
                                  [-31.881, -56.441],
                                  [-10.253, -72.467],
                                  [20.325, -92],
                                ],
                                v: [
                                  [-32.308, -313.554],
                                  [-188.882, -238.742],
                                  [-258.679, -55.328],
                                  [-242.926, 157.129],
                                  [-139.845, 260.494],
                                  [115.001, 347.834],
                                  [340.003, 260.409],
                                  [198.595, 15.154],
                                  [184.819, -135.453],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.516, 3.031],
                                  [30.382, -60.848],
                                  [13.223, -82.089],
                                  [-54.593, -42.771],
                                  [-14.561, -33.478],
                                  [-175.286, 30.891],
                                  [31.358, 56.312],
                                  [-2.535, 60.383],
                                  [-20.382, 92],
                                ],
                                o: [
                                  [-79.363, 6.599],
                                  [-30.382, 60.848],
                                  [-13.223, 82.089],
                                  [54.593, 42.771],
                                  [23.503, 44.484],
                                  [105.121, -15.758],
                                  [-31.358, -56.312],
                                  [-10.535, -72.077],
                                  [20.382, -92],
                                ],
                                v: [
                                  [-32.745, -312.745],
                                  [-188.748, -239.371],
                                  [-258.679, -55.595],
                                  [-242.775, 157.319],
                                  [-140.614, 259.905],
                                  [115.346, 348.042],
                                  [339.982, 260.079],
                                  [199.171, 16.068],
                                  [184.16, -135.041],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.644, 3.288],
                                  [30.691, -60.498],
                                  [13.006, -82.303],
                                  [-54.205, -42.811],
                                  [-14.545, -33.299],
                                  [-175.581, 31.026],
                                  [30.833, 56.183],
                                  [-2.314, 59.938],
                                  [-20.44, 92],
                                ],
                                o: [
                                  [-79.267, 6.388],
                                  [-30.691, 60.498],
                                  [-13.006, 82.303],
                                  [54.205, 42.811],
                                  [23.529, 44.356],
                                  [105.089, -15.822],
                                  [-30.833, -56.183],
                                  [-10.816, -71.686],
                                  [20.44, -92],
                                ],
                                v: [
                                  [-33.121, -311.928],
                                  [-188.53, -239.988],
                                  [-258.608, -55.878],
                                  [-242.633, 157.595],
                                  [-141.412, 259.264],
                                  [115.65, 348.215],
                                  [339.959, 259.712],
                                  [199.758, 16.952],
                                  [183.522, -134.674],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.772, 3.544],
                                  [31.001, -60.147],
                                  [12.787, -82.518],
                                  [-53.815, -42.852],
                                  [-14.528, -33.119],
                                  [-175.876, 31.161],
                                  [30.307, 56.054],
                                  [-2.093, 59.491],
                                  [-20.497, 92],
                                ],
                                o: [
                                  [-79.171, 6.176],
                                  [-31, 60.147],
                                  [-12.787, 82.518],
                                  [53.815, 42.852],
                                  [23.554, 44.228],
                                  [105.057, -15.886],
                                  [-30.307, -56.054],
                                  [-11.099, -71.295],
                                  [20.497, -92],
                                ],
                                v: [
                                  [-33.497, -311.101],
                                  [-188.296, -240.593],
                                  [-258.567, -56.114],
                                  [-242.525, 157.771],
                                  [-142.193, 258.679],
                                  [116.019, 348.437],
                                  [339.871, 259.306],
                                  [200.353, 17.909],
                                  [182.823, -134.308],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.901, 3.802],
                                  [31.311, -59.796],
                                  [12.568, -82.734],
                                  [-53.425, -42.893],
                                  [-14.512, -32.939],
                                  [-176.172, 31.296],
                                  [29.78, 55.925],
                                  [-1.871, 59.044],
                                  [-20.555, 92],
                                ],
                                o: [
                                  [-79.074, 5.963],
                                  [-31.311, 59.796],
                                  [-12.568, 82.734],
                                  [53.425, 42.893],
                                  [23.58, 44.099],
                                  [105.025, -15.95],
                                  [-29.781, -55.925],
                                  [-11.382, -70.902],
                                  [20.555, -92],
                                ],
                                v: [
                                  [-33.829, -310.265],
                                  [-188.038, -241.268],
                                  [-258.542, -56.322],
                                  [-242.383, 157.97],
                                  [-142.998, 258.067],
                                  [116.39, 348.666],
                                  [339.762, 258.886],
                                  [201.003, 18.878],
                                  [182.143, -133.945],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.03, 4.06],
                                  [31.622, -59.443],
                                  [12.349, -82.95],
                                  [-53.034, -42.935],
                                  [-14.496, -32.758],
                                  [-176.469, 31.431],
                                  [29.252, 55.795],
                                  [-1.648, 58.596],
                                  [-20.613, 92],
                                ],
                                o: [
                                  [-78.978, 5.751],
                                  [-31.622, 59.443],
                                  [-12.349, 82.95],
                                  [53.034, 42.935],
                                  [23.606, 43.97],
                                  [104.993, -16.015],
                                  [-29.252, -55.795],
                                  [-11.666, -70.509],
                                  [20.613, -92],
                                ],
                                v: [
                                  [-34.144, -309.382],
                                  [-187.783, -241.888],
                                  [-258.464, -56.524],
                                  [-242.294, 158.191],
                                  [-143.791, 257.447],
                                  [116.764, 348.902],
                                  [339.589, 258.452],
                                  [201.618, 19.903],
                                  [181.42, -133.652],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.159, 4.319],
                                  [31.934, -59.09],
                                  [12.129, -83.167],
                                  [-52.642, -42.976],
                                  [-14.48, -32.577],
                                  [-176.766, 31.567],
                                  [28.723, 55.665],
                                  [-1.425, 58.146],
                                  [-20.672, 92],
                                ],
                                o: [
                                  [-78.881, 5.537],
                                  [-31.934, 59.09],
                                  [-12.129, 83.167],
                                  [52.642, 42.976],
                                  [23.632, 43.841],
                                  [104.96, -16.08],
                                  [-28.723, -55.665],
                                  [-11.95, -70.114],
                                  [20.672, -92],
                                ],
                                v: [
                                  [-34.44, -308.509],
                                  [-187.554, -242.498],
                                  [-258.421, -56.74],
                                  [-242.153, 158.372],
                                  [-144.608, 256.82],
                                  [117.183, 349.082],
                                  [339.415, 258.043],
                                  [202.287, 20.914],
                                  [180.697, -133.299],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.289, 4.578],
                                  [32.247, -58.736],
                                  [11.909, -83.384],
                                  [-52.249, -43.017],
                                  [-14.464, -32.396],
                                  [-177.064, 31.703],
                                  [28.192, 55.534],
                                  [-1.202, 57.696],
                                  [-20.73, 92],
                                ],
                                o: [
                                  [-78.783, 5.323],
                                  [-32.247, 58.736],
                                  [-11.909, 83.384],
                                  [52.249, 43.017],
                                  [23.658, 43.711],
                                  [104.928, -16.144],
                                  [-28.192, -55.534],
                                  [-12.236, -69.719],
                                  [20.73, -92],
                                ],
                                v: [
                                  [-34.693, -307.653],
                                  [-187.301, -243.091],
                                  [-258.345, -56.925],
                                  [-242.049, 158.512],
                                  [-145.387, 256.212],
                                  [117.624, 349.331],
                                  [339.195, 257.576],
                                  [202.967, 21.98],
                                  [179.948, -133.012],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.419, 4.838],
                                  [32.56, -58.381],
                                  [11.688, -83.602],
                                  [-51.855, -43.058],
                                  [-14.448, -32.214],
                                  [-177.363, 31.84],
                                  [27.661, 55.404],
                                  [-0.978, 57.245],
                                  [-20.788, 92],
                                ],
                                o: [
                                  [-78.686, 5.109],
                                  [-32.56, 58.381],
                                  [-11.688, 83.602],
                                  [51.855, 43.059],
                                  [23.684, 43.581],
                                  [104.895, -16.209],
                                  [-27.661, -55.404],
                                  [-12.521, -69.323],
                                  [20.788, -92],
                                ],
                                v: [
                                  [-34.901, -306.727],
                                  [-187.033, -243.717],
                                  [-258.24, -57.059],
                                  [-241.973, 158.672],
                                  [-146.233, 255.57],
                                  [118.049, 349.544],
                                  [338.936, 257.096],
                                  [203.719, 23.103],
                                  [179.225, -132.755],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.549, 5.098],
                                  [32.874, -58.026],
                                  [11.467, -83.82],
                                  [-51.461, -43.1],
                                  [-14.431, -32.031],
                                  [-177.663, 31.976],
                                  [27.127, 55.273],
                                  [-0.753, 56.792],
                                  [-20.847, 92],
                                ],
                                o: [
                                  [-78.588, 4.894],
                                  [-32.874, 58.026],
                                  [-11.467, 83.82],
                                  [51.461, 43.1],
                                  [23.71, 43.451],
                                  [104.863, -16.275],
                                  [-27.128, -55.273],
                                  [-12.808, -68.926],
                                  [20.847, -92],
                                ],
                                v: [
                                  [-35.093, -305.837],
                                  [-186.828, -244.307],
                                  [-258.189, -57.205],
                                  [-241.917, 158.79],
                                  [-147.022, 255.011],
                                  [118.494, 349.807],
                                  [338.676, 256.595],
                                  [204.419, 24.211],
                                  [178.476, -132.546],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.68, 5.359],
                                  [33.189, -57.669],
                                  [11.245, -84.038],
                                  [-51.065, -43.142],
                                  [-14.415, -31.849],
                                  [-177.963, 32.113],
                                  [26.593, 55.141],
                                  [-0.528, 56.339],
                                  [-20.906, 92],
                                ],
                                o: [
                                  [-78.49, 4.679],
                                  [-33.189, 57.669],
                                  [-11.245, 84.038],
                                  [51.065, 43.142],
                                  [23.736, 43.32],
                                  [104.83, -16.34],
                                  [-26.593, -55.141],
                                  [-13.095, -68.527],
                                  [20.906, -92],
                                ],
                                v: [
                                  [-35.284, -304.939],
                                  [-186.625, -244.86],
                                  [-258.112, -57.298],
                                  [-241.845, 158.911],
                                  [-147.879, 254.382],
                                  [118.96, 350.015],
                                  [338.371, 256.126],
                                  [205.192, 25.331],
                                  [177.725, -132.279],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.81, 5.621],
                                  [33.505, -57.312],
                                  [11.022, -84.257],
                                  [-50.669, -43.183],
                                  [-14.399, -31.666],
                                  [-178.264, 32.251],
                                  [26.058, 55.01],
                                  [-0.302, 55.884],
                                  [-20.965, 92],
                                ],
                                o: [
                                  [-78.392, 4.463],
                                  [-33.505, 57.312],
                                  [-11.022, 84.257],
                                  [50.669, 43.183],
                                  [23.762, 43.19],
                                  [104.797, -16.405],
                                  [-26.058, -55.01],
                                  [-13.383, -68.129],
                                  [20.965, -92],
                                ],
                                v: [
                                  [-35.414, -304.016],
                                  [-186.379, -245.449],
                                  [-257.981, -57.384],
                                  [-241.767, 159.05],
                                  [-148.696, 253.808],
                                  [119.455, 350.247],
                                  [338.046, 255.618],
                                  [205.975, 26.507],
                                  [176.947, -132.107],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.941, 5.883],
                                  [33.821, -56.954],
                                  [10.8, -84.477],
                                  [-50.271, -43.225],
                                  [-14.382, -31.482],
                                  [-178.565, 32.388],
                                  [25.521, 54.878],
                                  [-0.076, 55.429],
                                  [-21.024, 92],
                                ],
                                o: [
                                  [-78.294, 4.247],
                                  [-33.821, 56.954],
                                  [-10.8, 84.477],
                                  [50.271, 43.225],
                                  [23.788, 43.059],
                                  [104.765, -16.471],
                                  [-25.521, -54.878],
                                  [-13.671, -67.729],
                                  [21.024, -92],
                                ],
                                v: [
                                  [-35.499, -303.085],
                                  [-186.18, -245.955],
                                  [-257.903, -57.48],
                                  [-241.746, 159.101],
                                  [-149.49, 253.211],
                                  [119.924, 350.469],
                                  [337.657, 255.143],
                                  [206.768, 27.667],
                                  [176.195, -131.94],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.073, 6.146],
                                  [34.138, -56.595],
                                  [10.576, -84.697],
                                  [-49.873, -43.267],
                                  [-14.366, -31.298],
                                  [-178.867, 32.526],
                                  [24.983, 54.745],
                                  [0.151, 54.972],
                                  [-21.083, 92],
                                ],
                                o: [
                                  [-78.195, 4.03],
                                  [-34.138, 56.595],
                                  [-10.576, 84.697],
                                  [49.873, 43.267],
                                  [23.815, 42.927],
                                  [104.732, -16.536],
                                  [-24.983, -54.745],
                                  [-13.96, -67.328],
                                  [21.083, -92],
                                ],
                                v: [
                                  [-35.567, -302.13],
                                  [-185.983, -246.514],
                                  [-257.799, -57.539],
                                  [-241.692, 159.215],
                                  [-150.271, 252.607],
                                  [120.424, 350.669],
                                  [337.25, 254.645],
                                  [207.635, 28.884],
                                  [175.413, -131.761],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.204, 6.409],
                                  [34.456, -56.235],
                                  [10.352, -84.918],
                                  [-49.474, -43.309],
                                  [-14.349, -31.114],
                                  [-179.17, 32.665],
                                  [24.444, 54.613],
                                  [0.378, 54.514],
                                  [-21.142, 92],
                                ],
                                o: [
                                  [-78.097, 3.813],
                                  [-34.456, 56.235],
                                  [-10.352, 84.918],
                                  [49.474, 43.309],
                                  [23.841, 42.795],
                                  [104.699, -16.602],
                                  [-24.444, -54.613],
                                  [-14.25, -66.926],
                                  [21.142, -92],
                                ],
                                v: [
                                  [-35.59, -301.197],
                                  [-185.805, -247.036],
                                  [-257.687, -57.59],
                                  [-241.632, 159.284],
                                  [-151.073, 251.997],
                                  [120.898, 350.904],
                                  [336.842, 254.136],
                                  [208.466, 30.129],
                                  [174.631, -131.587],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.337, 6.673],
                                  [34.774, -55.875],
                                  [10.128, -85.139],
                                  [-49.074, -43.351],
                                  [-14.333, -30.929],
                                  [-179.474, 32.803],
                                  [23.904, 54.48],
                                  [0.605, 54.056],
                                  [-21.201, 92],
                                ],
                                o: [
                                  [-77.998, 3.595],
                                  [-34.774, 55.875],
                                  [-10.128, 85.139],
                                  [49.074, 43.351],
                                  [23.867, 42.664],
                                  [104.666, -16.668],
                                  [-23.904, -54.48],
                                  [-14.54, -66.524],
                                  [21.201, -92],
                                ],
                                v: [
                                  [-35.597, -300.274],
                                  [-185.611, -247.566],
                                  [-257.549, -57.586],
                                  [-241.647, 159.324],
                                  [-151.88, 251.426],
                                  [121.39, 351.117],
                                  [336.433, 253.633],
                                  [209.307, 31.339],
                                  [173.817, -131.465],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.469, 6.938],
                                  [35.093, -55.513],
                                  [9.903, -85.36],
                                  [-48.673, -43.393],
                                  [-14.316, -30.744],
                                  [-179.778, 32.942],
                                  [23.362, 54.347],
                                  [0.834, 53.596],
                                  [-21.261, 92],
                                ],
                                o: [
                                  [-77.898, 3.376],
                                  [-35.093, 55.513],
                                  [-9.903, 85.36],
                                  [48.673, 43.393],
                                  [23.894, 42.531],
                                  [104.633, -16.734],
                                  [-23.362, -54.347],
                                  [-14.831, -66.12],
                                  [21.261, -92],
                                ],
                                v: [
                                  [-35.558, -299.311],
                                  [-185.436, -248.041],
                                  [-257.465, -57.572],
                                  [-241.612, 159.364],
                                  [-152.644, 250.85],
                                  [121.914, 351.321],
                                  [335.945, 253.135],
                                  [210.204, 32.623],
                                  [173.048, -131.379],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.601, 7.203],
                                  [35.413, -55.151],
                                  [9.678, -85.582],
                                  [-48.271, -43.435],
                                  [-14.3, -30.558],
                                  [-180.083, 33.081],
                                  [22.819, 54.214],
                                  [1.062, 53.135],
                                  [-21.321, 92],
                                ],
                                o: [
                                  [-77.799, 3.158],
                                  [-35.413, 55.151],
                                  [-9.678, 85.582],
                                  [48.271, 43.435],
                                  [23.92, 42.399],
                                  [104.6, -16.801],
                                  [-22.819, -54.214],
                                  [-15.123, -65.716],
                                  [21.321, -92],
                                ],
                                v: [
                                  [-35.502, -298.389],
                                  [-185.279, -248.54],
                                  [-257.357, -57.52],
                                  [-241.635, 159.403],
                                  [-153.429, 250.313],
                                  [122.409, 351.547],
                                  [335.456, 252.644],
                                  [211.128, 33.871],
                                  [172.184, -131.284],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.734, 7.469],
                                  [35.734, -54.788],
                                  [9.452, -85.805],
                                  [-47.868, -43.478],
                                  [-14.283, -30.372],
                                  [-180.389, 33.221],
                                  [22.275, 54.08],
                                  [1.292, 52.673],
                                  [-21.38, 92],
                                ],
                                o: [
                                  [-77.699, 2.938],
                                  [-35.734, 54.788],
                                  [-9.452, 85.805],
                                  [47.868, 43.478],
                                  [23.947, 42.266],
                                  [104.566, -16.867],
                                  [-22.275, -54.08],
                                  [-15.415, -65.31],
                                  [21.38, -92],
                                ],
                                v: [
                                  [-35.448, -297.461],
                                  [-185.107, -248.983],
                                  [-257.241, -57.473],
                                  [-241.625, 159.411],
                                  [-154.154, 249.755],
                                  [122.907, 351.763],
                                  [334.966, 252.142],
                                  [212.062, 35.176],
                                  [171.363, -131.212],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.867, 7.735],
                                  [36.055, -54.424],
                                  [9.225, -86.028],
                                  [-47.464, -43.52],
                                  [-14.267, -30.186],
                                  [-180.695, 33.361],
                                  [21.73, 53.946],
                                  [1.521, 52.211],
                                  [-21.44, 92],
                                ],
                                o: [
                                  [-77.599, 2.719],
                                  [-36.055, 54.424],
                                  [-9.225, 86.028],
                                  [47.464, 43.52],
                                  [23.973, 42.133],
                                  [104.533, -16.934],
                                  [-21.73, -53.946],
                                  [-15.708, -64.904],
                                  [21.44, -92],
                                ],
                                v: [
                                  [-35.347, -296.512],
                                  [-184.952, -249.434],
                                  [-257.1, -57.399],
                                  [-241.673, 159.371],
                                  [-154.899, 249.207],
                                  [123.405, 351.956],
                                  [334.398, 251.662],
                                  [213.022, 36.462],
                                  [170.51, -131.116],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.001, 8.002],
                                  [36.377, -54.06],
                                  [8.998, -86.252],
                                  [-47.06, -43.563],
                                  [-14.25, -29.999],
                                  [-181.002, 33.501],
                                  [21.184, 53.812],
                                  [1.752, 51.747],
                                  [-21.5, 92],
                                ],
                                o: [
                                  [-77.499, 2.498],
                                  [-36.377, 54.06],
                                  [-8.998, 86.252],
                                  [47.06, 43.563],
                                  [24, 41.999],
                                  [104.5, -17],
                                  [-21.184, -53.812],
                                  [-16.002, -64.497],
                                  [21.5, -92],
                                ],
                                v: [
                                  [-35.231, -295.558],
                                  [-184.813, -249.846],
                                  [-256.968, -57.331],
                                  [-241.703, 159.345],
                                  [-155.646, 248.685],
                                  [123.906, 352.138],
                                  [333.845, 251.188],
                                  [213.945, 37.757],
                                  [169.653, -131.09],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.135, 8.269],
                                  [36.7, -53.695],
                                  [8.771, -86.476],
                                  [-46.654, -43.605],
                                  [-14.233, -29.811],
                                  [-181.31, 33.641],
                                  [20.636, 53.677],
                                  [1.982, 51.282],
                                  [-21.561, 92],
                                ],
                                o: [
                                  [-77.399, 2.278],
                                  [-36.7, 53.695],
                                  [-8.771, 86.476],
                                  [46.654, 43.605],
                                  [24.027, 41.865],
                                  [104.466, -17.067],
                                  [-20.636, -53.677],
                                  [-16.296, -64.089],
                                  [21.561, -92],
                                ],
                                v: [
                                  [-35.053, -294.646],
                                  [-184.676, -250.265],
                                  [-256.86, -57.205],
                                  [-241.776, 159.316],
                                  [-156.349, 248.158],
                                  [124.376, 352.281],
                                  [333.261, 250.704],
                                  [214.939, 39.078],
                                  [168.808, -131.056],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.269, 8.538],
                                  [37.023, -53.328],
                                  [8.543, -86.7],
                                  [-46.248, -43.648],
                                  [-14.216, -29.624],
                                  [-181.618, 33.782],
                                  [20.087, 53.542],
                                  [2.214, 50.816],
                                  [-21.621, 92],
                                ],
                                o: [
                                  [-77.298, 2.056],
                                  [-37.023, 53.328],
                                  [-8.543, 86.7],
                                  [46.248, 43.648],
                                  [24.054, 41.731],
                                  [104.433, -17.134],
                                  [-20.087, -53.542],
                                  [-16.591, -63.68],
                                  [21.621, -92],
                                ],
                                v: [
                                  [-34.875, -293.699],
                                  [-184.539, -250.69],
                                  [-256.729, -57.068],
                                  [-241.815, 159.286],
                                  [-157.054, 247.674],
                                  [124.879, 352.444],
                                  [332.677, 250.194],
                                  [215.943, 40.423],
                                  [167.897, -131.031],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.403, 8.806],
                                  [37.348, -52.961],
                                  [8.315, -86.925],
                                  [-45.84, -43.691],
                                  [-14.2, -29.436],
                                  [-181.927, 33.923],
                                  [19.537, 53.407],
                                  [2.445, 50.349],
                                  [-21.681, 92],
                                ],
                                o: [
                                  [-77.198, 1.835],
                                  [-37.348, 52.961],
                                  [-8.315, 86.925],
                                  [45.84, 43.691],
                                  [24.081, 41.597],
                                  [104.399, -17.202],
                                  [-19.537, -53.407],
                                  [-16.887, -63.27],
                                  [21.681, -92],
                                ],
                                v: [
                                  [-34.651, -292.763],
                                  [-184.418, -251.091],
                                  [-256.591, -56.92],
                                  [-241.881, 159.22],
                                  [-157.762, 247.233],
                                  [125.351, 352.598],
                                  [332.094, 249.723],
                                  [216.972, 41.763],
                                  [166.998, -131.047],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.538, 9.076],
                                  [37.673, -52.593],
                                  [8.086, -87.151],
                                  [-45.432, -43.734],
                                  [-14.183, -29.247],
                                  [-182.237, 34.065],
                                  [18.986, 53.271],
                                  [2.678, 49.881],
                                  [-21.742, 92],
                                ],
                                o: [
                                  [-77.097, 1.613],
                                  [-37.673, 52.593],
                                  [-8.086, 87.151],
                                  [45.432, 43.734],
                                  [24.108, 41.462],
                                  [104.366, -17.269],
                                  [-18.986, -53.271],
                                  [-17.183, -62.86],
                                  [21.742, -92],
                                ],
                                v: [
                                  [-34.412, -291.856],
                                  [-184.297, -251.435],
                                  [-256.446, -56.727],
                                  [-241.945, 159.119],
                                  [-158.425, 246.739],
                                  [125.824, 352.758],
                                  [331.448, 249.257],
                                  [218.057, 43.095],
                                  [166.062, -131.058],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.673, 9.346],
                                  [37.998, -52.225],
                                  [7.856, -87.377],
                                  [-45.023, -43.777],
                                  [-14.166, -29.058],
                                  [-182.547, 34.206],
                                  [18.433, 53.135],
                                  [2.911, 49.412],
                                  [-21.803, 92],
                                ],
                                o: [
                                  [-76.995, 1.39],
                                  [-37.998, 52.225],
                                  [-7.856, 87.377],
                                  [45.023, 43.777],
                                  [24.135, 41.327],
                                  [104.332, -17.336],
                                  [-18.433, -53.135],
                                  [-17.48, -62.448],
                                  [21.803, -92],
                                ],
                                v: [
                                  [-34.111, -290.931],
                                  [-184.191, -251.785],
                                  [-256.28, -56.522],
                                  [-242.036, 159.014],
                                  [-159.153, 246.338],
                                  [126.264, 352.891],
                                  [330.837, 248.813],
                                  [219.118, 44.435],
                                  [165.168, -131.092],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.808, 9.616],
                                  [38.325, -51.855],
                                  [7.626, -87.604],
                                  [-44.613, -43.82],
                                  [-14.149, -28.869],
                                  [-182.859, 34.348],
                                  [17.879, 52.999],
                                  [3.144, 48.942],
                                  [-21.864, 92],
                                ],
                                o: [
                                  [-76.894, 1.167],
                                  [-38.325, 51.855],
                                  [-7.626, 87.604],
                                  [44.613, 43.82],
                                  [24.162, 41.192],
                                  [104.298, -17.404],
                                  [-17.879, -52.999],
                                  [-17.778, -62.035],
                                  [21.864, -92],
                                ],
                                v: [
                                  [-33.811, -290.002],
                                  [-184.1, -252.093],
                                  [-256.155, -56.354],
                                  [-242.153, 158.905],
                                  [-159.771, 245.932],
                                  [126.704, 353.015],
                                  [330.178, 248.375],
                                  [220.202, 45.784],
                                  [164.174, -131.122],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.944, 9.887],
                                  [38.652, -51.485],
                                  [7.396, -87.831],
                                  [-44.202, -43.863],
                                  [-14.132, -28.679],
                                  [-183.17, 34.491],
                                  [17.324, 52.863],
                                  [3.378, 48.471],
                                  [-21.925, 92],
                                ],
                                o: [
                                  [-76.792, 0.943],
                                  [-38.652, 51.485],
                                  [-7.396, 87.831],
                                  [44.202, 43.863],
                                  [24.189, 41.056],
                                  [104.264, -17.472],
                                  [-17.324, -52.863],
                                  [-18.076, -61.622],
                                  [21.925, -92],
                                ],
                                v: [
                                  [-33.462, -289.119],
                                  [-184.007, -252.422],
                                  [-255.962, -56.125],
                                  [-242.282, 158.806],
                                  [-160.454, 245.557],
                                  [127.13, 353.11],
                                  [329.506, 247.928],
                                  [221.294, 47.155],
                                  [163.223, -131.148],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.08, 10.159],
                                  [38.979, -51.114],
                                  [7.165, -88.058],
                                  [-43.79, -43.907],
                                  [-14.115, -28.489],
                                  [-183.483, 34.633],
                                  [16.768, 52.726],
                                  [3.612, 47.999],
                                  [-21.986, 92],
                                ],
                                o: [
                                  [-76.69, 0.719],
                                  [-38.979, 51.114],
                                  [-7.165, 88.058],
                                  [43.79, 43.907],
                                  [24.216, 40.92],
                                  [104.23, -17.54],
                                  [-16.768, -52.726],
                                  [-18.375, -61.207],
                                  [21.986, -92],
                                ],
                                v: [
                                  [-33.101, -288.268],
                                  [-183.88, -252.707],
                                  [-255.811, -55.835],
                                  [-242.423, 158.668],
                                  [-161.076, 245.179],
                                  [127.583, 353.197],
                                  [328.835, 247.55],
                                  [222.409, 48.486],
                                  [162.232, -131.232],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.216, 10.431],
                                  [39.308, -50.742],
                                  [6.933, -88.286],
                                  [-43.377, -43.95],
                                  [-14.098, -28.298],
                                  [-183.796, 34.776],
                                  [16.211, 52.589],
                                  [3.847, 47.526],
                                  [-22.047, 92],
                                ],
                                o: [
                                  [-76.588, 0.494],
                                  [-39.308, 50.742],
                                  [-6.933, 88.286],
                                  [43.377, 43.95],
                                  [24.243, 40.784],
                                  [104.196, -17.608],
                                  [-16.211, -52.589],
                                  [-18.675, -60.792],
                                  [22.047, -92],
                                ],
                                v: [
                                  [-32.691, -287.401],
                                  [-183.8, -252.997],
                                  [-255.641, -55.581],
                                  [-242.526, 158.524],
                                  [-161.714, 244.861],
                                  [127.974, 353.253],
                                  [328.152, 247.13],
                                  [223.545, 49.839],
                                  [161.236, -131.278],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.352, 10.704],
                                  [39.637, -50.369],
                                  [6.701, -88.515],
                                  [-42.964, -43.994],
                                  [-14.081, -28.107],
                                  [-184.11, 34.92],
                                  [15.652, 52.452],
                                  [4.082, 47.051],
                                  [-22.108, 92],
                                ],
                                o: [
                                  [-76.486, 0.269],
                                  [-39.637, 50.369],
                                  [-6.701, 88.515],
                                  [42.964, 43.993],
                                  [24.27, 40.648],
                                  [104.162, -17.676],
                                  [-15.652, -52.452],
                                  [-18.975, -60.376],
                                  [22.108, -92],
                                ],
                                v: [
                                  [-32.267, -286.547],
                                  [-183.732, -253.244],
                                  [-255.466, -55.315],
                                  [-242.669, 158.327],
                                  [-162.339, 244.527],
                                  [128.316, 353.301],
                                  [327.471, 246.765],
                                  [224.702, 51.198],
                                  [160.198, -131.37],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.489, 10.978],
                                  [39.967, -49.996],
                                  [6.469, -88.744],
                                  [-42.549, -44.037],
                                  [-14.064, -27.916],
                                  [-184.424, 35.063],
                                  [15.092, 52.314],
                                  [4.318, 46.576],
                                  [-22.17, 92],
                                ],
                                o: [
                                  [-76.383, 0.043],
                                  [-39.967, 49.996],
                                  [-6.469, 88.744],
                                  [42.549, 44.037],
                                  [24.298, 40.511],
                                  [104.128, -17.744],
                                  [-15.092, -52.314],
                                  [-19.276, -59.959],
                                  [22.17, -92],
                                ],
                                v: [
                                  [-31.796, -285.763],
                                  [-183.676, -253.495],
                                  [-255.222, -55.036],
                                  [-242.809, 158.137],
                                  [-162.979, 244.288],
                                  [128.718, 353.317],
                                  [326.777, 246.407],
                                  [225.83, 52.53],
                                  [159.14, -131.474],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.626, 11.252],
                                  [40.298, -49.622],
                                  [6.236, -88.973],
                                  [-42.134, -44.081],
                                  [-14.047, -27.724],
                                  [-184.74, 35.207],
                                  [14.531, 52.176],
                                  [4.555, 46.1],
                                  [-22.232, 92],
                                ],
                                o: [
                                  [-76.281, -0.183],
                                  [-40.298, 49.622],
                                  [-6.236, 88.973],
                                  [42.134, 44.081],
                                  [24.325, 40.374],
                                  [104.094, -17.813],
                                  [-14.531, -52.176],
                                  [-19.577, -59.541],
                                  [22.232, -92],
                                ],
                                v: [
                                  [-31.275, -284.942],
                                  [-183.583, -253.714],
                                  [-255.023, -54.745],
                                  [-242.986, 157.953],
                                  [-163.558, 244.048],
                                  [129.021, 353.338],
                                  [326.086, 246.068],
                                  [227.014, 53.881],
                                  [158.102, -131.575],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.763, 11.527],
                                  [40.629, -49.246],
                                  [6.002, -89.204],
                                  [-41.717, -44.125],
                                  [-14.03, -27.531],
                                  [-185.056, 35.351],
                                  [13.969, 52.038],
                                  [4.792, 45.623],
                                  [-22.293, 92],
                                ],
                                o: [
                                  [-76.178, -0.409],
                                  [-40.629, 49.246],
                                  [-6.002, 89.204],
                                  [41.717, 44.125],
                                  [24.353, 40.237],
                                  [104.059, -17.882],
                                  [-13.969, -52.038],
                                  [-19.879, -59.122],
                                  [22.294, -92],
                                ],
                                v: [
                                  [-30.74, -284.157],
                                  [-183.535, -253.95],
                                  [-254.843, -54.392],
                                  [-243.173, 157.764],
                                  [-164.15, 243.856],
                                  [129.372, 353.363],
                                  [325.397, 245.722],
                                  [228.23, 55.188],
                                  [157.044, -131.689],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.901, 11.802],
                                  [40.961, -48.871],
                                  [5.768, -89.434],
                                  [-41.3, -44.168],
                                  [-14.012, -27.339],
                                  [-185.372, 35.496],
                                  [13.405, 51.9],
                                  [5.029, 45.144],
                                  [-22.355, 92],
                                ],
                                o: [
                                  [-76.074, -0.637],
                                  [-40.961, 48.871],
                                  [-5.768, 89.434],
                                  [41.3, 44.168],
                                  [24.38, 40.099],
                                  [104.025, -17.95],
                                  [-13.405, -51.9],
                                  [-20.182, -58.702],
                                  [22.355, -92],
                                ],
                                v: [
                                  [-30.144, -283.409],
                                  [-183.499, -254.14],
                                  [-254.622, -54.075],
                                  [-243.383, 157.53],
                                  [-164.731, 243.663],
                                  [129.634, 353.342],
                                  [324.735, 245.445],
                                  [229.452, 56.551],
                                  [155.942, -131.801],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.039, 12.078],
                                  [41.294, -48.494],
                                  [5.534, -89.665],
                                  [-40.882, -44.212],
                                  [-13.995, -27.146],
                                  [-185.689, 35.641],
                                  [12.841, 51.761],
                                  [5.267, 44.665],
                                  [-22.417, 92],
                                ],
                                o: [
                                  [-75.971, -0.864],
                                  [-41.294, 48.494],
                                  [-5.534, 89.665],
                                  [40.882, 44.212],
                                  [24.408, 39.961],
                                  [103.99, -18.019],
                                  [-12.841, -51.761],
                                  [-20.486, -58.281],
                                  [22.418, -92],
                                ],
                                v: [
                                  [-29.51, -282.725],
                                  [-183.434, -254.282],
                                  [-254.371, -53.746],
                                  [-243.566, 157.289],
                                  [-165.326, 243.507],
                                  [129.931, 353.275],
                                  [324.038, 245.175],
                                  [230.654, 57.832],
                                  [154.833, -131.876],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.177, 12.354],
                                  [41.627, -48.116],
                                  [5.299, -89.897],
                                  [-40.463, -44.256],
                                  [-13.978, -26.952],
                                  [-186.007, 35.786],
                                  [12.275, 51.622],
                                  [5.506, 44.184],
                                  [-22.48, 92],
                                ],
                                o: [
                                  [-75.867, -1.092],
                                  [-41.627, 48.116],
                                  [-5.299, 89.897],
                                  [40.463, 44.256],
                                  [24.435, 39.823],
                                  [103.956, -18.089],
                                  [-12.275, -51.622],
                                  [-20.79, -57.86],
                                  [22.48, -92],
                                ],
                                v: [
                                  [-28.863, -282.017],
                                  [-183.366, -254.44],
                                  [-254.117, -53.455],
                                  [-243.769, 157.04],
                                  [-165.909, 243.412],
                                  [130.138, 353.211],
                                  [323.332, 244.962],
                                  [231.923, 59.13],
                                  [153.677, -132.013],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.316, 12.631],
                                  [41.962, -47.738],
                                  [5.063, -90.129],
                                  [-40.043, -44.301],
                                  [-13.961, -26.758],
                                  [-186.326, 35.932],
                                  [11.708, 51.482],
                                  [5.745, 43.703],
                                  [-22.542, 92],
                                ],
                                o: [
                                  [-75.763, -1.321],
                                  [-41.962, 47.738],
                                  [-5.063, 90.129],
                                  [40.043, 44.301],
                                  [24.463, 39.684],
                                  [103.921, -18.158],
                                  [-11.708, -51.482],
                                  [-21.094, -57.437],
                                  [22.542, -92],
                                ],
                                v: [
                                  [-28.154, -281.373],
                                  [-183.355, -254.561],
                                  [-253.847, -53.101],
                                  [-243.994, 156.757],
                                  [-166.493, 243.356],
                                  [130.393, 353.163],
                                  [322.679, 244.767],
                                  [233.147, 60.433],
                                  [152.513, -132.163],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.455, 12.909],
                                  [42.297, -47.359],
                                  [4.827, -90.361],
                                  [-39.622, -44.345],
                                  [-13.943, -26.564],
                                  [-186.645, 36.077],
                                  [11.139, 51.343],
                                  [5.984, 43.221],
                                  [-22.604, 92],
                                ],
                                o: [
                                  [-75.659, -1.55],
                                  [-42.297, 47.359],
                                  [-4.827, 90.361],
                                  [39.622, 44.345],
                                  [24.491, 39.545],
                                  [103.886, -18.227],
                                  [-11.139, -51.343],
                                  [-21.4, -57.014],
                                  [22.605, -92],
                                ],
                                v: [
                                  [-27.394, -280.719],
                                  [-183.29, -254.683],
                                  [-253.625, -52.785],
                                  [-244.238, 156.516],
                                  [-167.04, 243.363],
                                  [130.555, 353.055],
                                  [322.018, 244.58],
                                  [234.374, 61.701],
                                  [151.341, -132.313],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.594, 13.187],
                                  [42.632, -46.979],
                                  [4.591, -90.594],
                                  [-39.2, -44.389],
                                  [-13.926, -26.369],
                                  [-186.965, 36.223],
                                  [10.57, 51.203],
                                  [6.224, 42.737],
                                  [-22.667, 92],
                                ],
                                o: [
                                  [-75.555, -1.78],
                                  [-42.632, 46.979],
                                  [-4.591, 90.594],
                                  [39.2, 44.389],
                                  [24.519, 39.406],
                                  [103.852, -18.297],
                                  [-10.57, -51.203],
                                  [-21.706, -56.589],
                                  [22.667, -92],
                                ],
                                v: [
                                  [-26.633, -280.157],
                                  [-183.244, -254.767],
                                  [-253.337, -52.445],
                                  [-244.452, 156.216],
                                  [-167.626, 243.359],
                                  [130.689, 352.898],
                                  [321.372, 244.45],
                                  [235.617, 62.973],
                                  [150.172, -132.464],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.733, 13.466],
                                  [42.969, -46.598],
                                  [4.354, -90.828],
                                  [-38.778, -44.434],
                                  [-13.908, -26.174],
                                  [-187.286, 36.37],
                                  [9.999, 51.062],
                                  [6.465, 42.253],
                                  [-22.73, 92],
                                ],
                                o: [
                                  [-75.45, -2.01],
                                  [-42.969, 46.598],
                                  [-4.354, 90.828],
                                  [38.778, 44.434],
                                  [24.547, 39.267],
                                  [103.817, -18.367],
                                  [-9.999, -51.062],
                                  [-22.013, -56.164],
                                  [22.73, -92],
                                ],
                                v: [
                                  [-25.82, -279.611],
                                  [-183.203, -254.812],
                                  [-253.035, -52.155],
                                  [-244.746, 155.918],
                                  [-168.163, 243.407],
                                  [130.809, 352.733],
                                  [320.758, 244.389],
                                  [236.926, 64.272],
                                  [148.931, -132.589],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [132.873, 13.746],
                                  [43.306, -46.216],
                                  [4.116, -91.062],
                                  [-38.354, -44.478],
                                  [-13.891, -25.978],
                                  [-187.607, 36.516],
                                  [9.427, 50.922],
                                  [6.706, 41.767],
                                  [-22.793, 92],
                                ],
                                o: [
                                  [-75.345, -2.24],
                                  [-43.306, 46.216],
                                  [-4.116, 91.062],
                                  [38.354, 44.478],
                                  [24.575, 39.127],
                                  [103.782, -18.436],
                                  [-9.427, -50.922],
                                  [-22.32, -55.738],
                                  [22.793, -92],
                                ],
                                v: [
                                  [-24.944, -279.056],
                                  [-183.168, -254.867],
                                  [-252.731, -51.791],
                                  [-244.943, 155.559],
                                  [-168.751, 243.508],
                                  [130.875, 352.509],
                                  [320.109, 244.336],
                                  [238.125, 65.47],
                                  [147.703, -132.767],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.013, 14.026],
                                  [43.644, -45.834],
                                  [3.878, -91.297],
                                  [-37.93, -44.523],
                                  [-13.873, -25.782],
                                  [-187.93, 36.664],
                                  [8.854, 50.781],
                                  [6.947, 41.28],
                                  [-22.856, 92],
                                ],
                                o: [
                                  [-75.24, -2.471],
                                  [-43.644, 45.834],
                                  [-3.878, 91.297],
                                  [37.93, 44.523],
                                  [24.603, 38.987],
                                  [103.747, -18.506],
                                  [-8.854, -50.781],
                                  [-22.628, -55.311],
                                  [22.856, -92],
                                ],
                                v: [
                                  [-24.066, -278.597],
                                  [-183.138, -254.932],
                                  [-252.402, -51.518],
                                  [-245.21, 155.253],
                                  [-169.33, 243.662],
                                  [130.865, 352.287],
                                  [319.504, 244.289],
                                  [239.401, 66.721],
                                  [146.454, -132.895],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.153, 14.306],
                                  [43.982, -45.45],
                                  [3.64, -91.532],
                                  [-37.504, -44.568],
                                  [-13.856, -25.586],
                                  [-188.252, 36.811],
                                  [8.279, 50.64],
                                  [7.189, 40.793],
                                  [-22.919, 92],
                                ],
                                o: [
                                  [-75.135, -2.703],
                                  [-43.982, 45.45],
                                  [-3.64, 91.532],
                                  [37.504, 44.568],
                                  [24.631, 38.847],
                                  [103.712, -18.577],
                                  [-8.279, -50.64],
                                  [-22.937, -54.883],
                                  [22.919, -92],
                                ],
                                v: [
                                  [-23.136, -278.205],
                                  [-183.059, -254.944],
                                  [-252.135, -51.234],
                                  [-245.44, 154.885],
                                  [-169.869, 243.86],
                                  [130.841, 352.047],
                                  [318.956, 244.364],
                                  [240.679, 67.944],
                                  [145.155, -133.048],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.294, 14.588],
                                  [44.321, -45.066],
                                  [3.401, -91.767],
                                  [-37.078, -44.612],
                                  [-13.838, -25.389],
                                  [-188.576, 36.958],
                                  [7.703, 50.498],
                                  [7.432, 40.304],
                                  [-22.982, 92],
                                ],
                                o: [
                                  [-75.03, -2.935],
                                  [-44.321, 45.066],
                                  [-3.401, 91.767],
                                  [37.078, 44.612],
                                  [24.659, 38.706],
                                  [103.677, -18.647],
                                  [-7.703, -50.498],
                                  [-23.246, -54.454],
                                  [22.982, -92],
                                ],
                                v: [
                                  [-22.142, -277.807],
                                  [-183.035, -254.924],
                                  [-251.792, -50.929],
                                  [-245.676, 154.558],
                                  [-170.451, 244.123],
                                  [130.762, 351.707],
                                  [318.413, 244.447],
                                  [241.908, 69.117],
                                  [143.845, -133.152],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.435, 14.869],
                                  [44.661, -44.681],
                                  [3.161, -92.003],
                                  [-36.651, -44.657],
                                  [-13.821, -25.191],
                                  [-188.9, 37.107],
                                  [7.127, 50.356],
                                  [7.675, 39.814],
                                  [-23.046, 92],
                                ],
                                o: [
                                  [-74.924, -3.167],
                                  [-44.661, 44.681],
                                  [-3.161, 92.003],
                                  [36.651, 44.657],
                                  [24.687, 38.565],
                                  [103.641, -18.717],
                                  [-7.127, -50.356],
                                  [-23.556, -54.024],
                                  [23.046, -92],
                                ],
                                v: [
                                  [-21.146, -277.457],
                                  [-182.962, -254.9],
                                  [-251.439, -50.664],
                                  [-245.927, 154.17],
                                  [-171.044, 244.38],
                                  [130.608, 351.422],
                                  [317.875, 244.537],
                                  [243.192, 70.29],
                                  [142.525, -133.311],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.576, 15.152],
                                  [45.002, -44.296],
                                  [2.921, -92.24],
                                  [-36.223, -44.702],
                                  [-13.803, -24.994],
                                  [-189.225, 37.255],
                                  [6.548, 50.214],
                                  [7.919, 39.324],
                                  [-23.109, 92],
                                ],
                                o: [
                                  [-74.818, -3.4],
                                  [-45.002, 44.296],
                                  [-2.921, 92.24],
                                  [36.223, 44.702],
                                  [24.715, 38.424],
                                  [103.606, -18.788],
                                  [-6.548, -50.214],
                                  [-23.867, -53.593],
                                  [23.109, -92],
                                ],
                                v: [
                                  [-20.096, -277.176],
                                  [-182.89, -254.83],
                                  [-251.085, -50.442],
                                  [-246.14, 153.77],
                                  [-171.629, 244.755],
                                  [130.442, 351.025],
                                  [317.437, 244.751],
                                  [244.436, 71.43],
                                  [141.204, -133.441],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.717, 15.435],
                                  [45.343, -43.909],
                                  [2.68, -92.477],
                                  [-35.794, -44.747],
                                  [-13.785, -24.796],
                                  [-189.55, 37.403],
                                  [5.969, 50.072],
                                  [8.163, 38.832],
                                  [-23.173, 92],
                                ],
                                o: [
                                  [-74.712, -3.634],
                                  [-45.343, 43.909],
                                  [-2.68, 92.477],
                                  [35.794, 44.747],
                                  [24.744, 38.283],
                                  [103.571, -18.859],
                                  [-5.969, -50.072],
                                  [-24.178, -53.162],
                                  [23.173, -92],
                                ],
                                v: [
                                  [-18.992, -276.944],
                                  [-182.809, -254.704],
                                  [-250.677, -50.21],
                                  [-246.366, 153.361],
                                  [-172.215, 245.125],
                                  [130.211, 350.675],
                                  [316.964, 244.981],
                                  [245.692, 72.57],
                                  [139.915, -133.522],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [133.859, 15.719],
                                  [45.686, -43.522],
                                  [2.439, -92.714],
                                  [-35.364, -44.793],
                                  [-13.768, -24.597],
                                  [-189.876, 37.552],
                                  [5.389, 49.929],
                                  [8.407, 38.339],
                                  [-23.237, 92],
                                ],
                                o: [
                                  [-74.606, -3.868],
                                  [-45.686, 43.522],
                                  [-2.439, 92.714],
                                  [35.364, 44.793],
                                  [24.772, 38.141],
                                  [103.535, -18.93],
                                  [-5.389, -49.929],
                                  [-24.49, -52.729],
                                  [23.237, -92],
                                ],
                                v: [
                                  [-17.885, -276.771],
                                  [-182.728, -254.573],
                                  [-250.302, -50.064],
                                  [-246.596, 152.888],
                                  [-172.803, 245.552],
                                  [129.968, 350.213],
                                  [316.539, 245.326],
                                  [246.949, 73.657],
                                  [138.625, -133.616],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.001, 16.003],
                                  [46.028, -43.134],
                                  [2.198, -92.952],
                                  [-34.933, -44.838],
                                  [-13.75, -24.398],
                                  [-190.203, 37.701],
                                  [4.807, 49.786],
                                  [8.652, 37.845],
                                  [-23.301, 92],
                                ],
                                o: [
                                  [-74.499, -4.102],
                                  [-46.028, 43.134],
                                  [-2.198, 92.952],
                                  [34.933, 44.838],
                                  [24.8, 37.999],
                                  [103.5, -19.001],
                                  [-4.807, -49.786],
                                  [-24.803, -52.296],
                                  [23.301, -92],
                                ],
                                v: [
                                  [-16.723, -276.65],
                                  [-182.648, -254.457],
                                  [-249.927, -49.909],
                                  [-246.829, 152.466],
                                  [-173.436, 246.038],
                                  [129.671, 349.754],
                                  [316.174, 245.679],
                                  [248.208, 74.698],
                                  [137.325, -133.724],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [134.144, 16.288],
                                  [46.372, -42.745],
                                  [1.956, -93.191],
                                  [-34.502, -44.883],
                                  [-13.732, -24.199],
                                  [-190.531, 37.851],
                                  [4.224, 49.643],
                                  [8.898, 37.35],
                                  [-23.365, 92],
                                ],
                                o: [
                                  [-74.392, -4.337],
                                  [-46.372, 42.745],
                                  [-1.956, 93.191],
                                  [34.502, 44.883],
                                  [24.829, 37.856],
                                  [103.464, -19.072],
                                  [-4.224, -49.643],
                                  [-25.116, -51.861],
                                  [23.365, -92],
                                ],
                                v: [
                                  [-15.495, -276.6],
                                  [-182.557, -254.281],
                                  [-249.49, -49.799],
                                  [-247.02, 152.024],
                                  [-174.071, 246.635],
                                  [129.3, 349.299],
                                  [315.816, 246.05],
                                  [249.415, 75.746],
                                  [136.077, -133.792],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [33.188, 36.125],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.625, 34.375],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.125, 32.5],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.906, 31.594],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.531, 29.844],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.344, 29.031],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.219, 28.219],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 26.656],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.594, 26],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.469, 25.312],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.312, 24.625],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.062, 23.375],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.969, 22.812],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.906, 22.25],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.844, 21.75],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 21.188],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 20.75],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 20.281],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 19.875],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.75, 19.438],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.844, 19.125],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.844, 18.719],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.969, 18.094],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.031, 17.812],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.125, 17.562],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.25, 17.344],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.344, 17.125],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.469, 16.906],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.656, 16.781],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 16.594],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.969, 16.469],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.125, 16.375],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.281, 16.312],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.469, 16.25],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.625, 16.219],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.781, 16.156],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, 16.156],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.156, 16.219],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.312, 16.219],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.5, 16.281],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.812, 16.531],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.938, 16.688],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.094, 16.906],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 17.094],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.438, 17.312],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.594, 17.562],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.812, 17.812],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.969, 18.062],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.219, 18.406],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.438, 18.688],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.719, 19.031],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.969, 19.312],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.219, 19.656],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.062, 20.688],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.344, 21.094],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.656, 21.406],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.906, 21.781],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.188, 22.156],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.5, 22.531],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 22.875],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.094, 23.25],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.031, 23.594],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.938, 24],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, 24.375],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.688, 24.719],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.531, 25.094],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.344, 25.438],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.188, 25.75],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37, 26.094],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.781, 26.406],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.594, 26.719],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.344, 27.031],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.844, 27.594],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.594, 27.844],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.312, 28.125],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.062, 28.375],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.75, 28.625],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.438, 28.781],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.156, 29],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.812, 29.219],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.531, 29.406],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 29.594],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.875, 29.75],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.5, 29.906],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.188, 30.062],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.156, 30.438],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 30.5],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.438, 30.594],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.125, 30.625],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 30.719],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.219, 30.719],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.906, 30.719],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.625, 30.75],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.406, 30.688],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.125, 30.688],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.875, 30.656],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.688, 30.594],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.469, 30.562],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.906, 30.375],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.75, 30.281],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 30.219],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.219, 29.844],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.125, 29.688],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 29.562],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, 29.469],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, 29.375],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, 29.25],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.969, 29.094],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, 28.906],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 28.688],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 28.469],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.156, 28.219],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.219, 27.938],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.281, 27.625],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.406, 27.312],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.5, 27],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 26.656],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.781, 26.281],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.969, 25.969],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.156, 25.594],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.344, 25.156],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.531, 24.781],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.781, 24.344],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28, 23.938],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.25, 23.5],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 23],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.719, 22.594],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29, 22.094],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.25, 21.625],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.531, 21.156],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.094, 20.281],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.031, 18.969],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.344, 18.562],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.688, 18.125],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.969, 17.719],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.312, 17.312],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.656, 16.938],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.969, 16.562],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.656, 15.812],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34, 15.5],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.344, 15.219],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.969, 14.594],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.344, 14.375],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.656, 14.125],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.969, 13.906],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.281, 13.719],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.625, 13.531],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.906, 13.406],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.219, 13.281],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.531, 13.219],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, 13.188],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.406, 13.125],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.656, 13.188],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.938, 13.219],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.188, 13.344],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.406, 13.5],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.625, 13.625],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 13.844],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.969, 14.094],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.125, 14.312],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, 15],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.438, 15.344],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.562, 15.719],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.594, 16.219],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.625, 16.656],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.656, 17.125],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.656, 17.656],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.656, 18.125],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.656, 18.656],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.594, 19.219],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.562, 19.75],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.5, 20.281],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.406, 20.844],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.344, 21.406],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.25, 22],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.125, 22.531],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 23.125],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.906, 23.688],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.719, 24.281],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.562, 24.844],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.438, 25.438],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.25, 26],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.031, 26.594],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.844, 27.156],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.625, 27.719],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.469, 28.281],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.188, 28.812],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.969, 29.375],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 29.938],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.5, 30.438],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.281, 30.969],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 31.5],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.781, 32],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.5, 32.469],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.25, 32.938],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.031, 33.375],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.75, 33.781],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, 34.188],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.281, 34.594],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.062, 34.938],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.844, 35.25],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.594, 35.594],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.406, 35.906],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.188, 36.156],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.031, 36.344],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.844, 36.562],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.688, 36.719],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.562, 36.844],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.406, 36.938],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.312, 36.938],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 37],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 36.906],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 36.875],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 36.562],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 36.344],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [33.188, 36.125],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.625, 34.375],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.125, 32.5],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.906, 31.594],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.531, 29.844],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.344, 29.031],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.219, 28.219],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 26.656],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.594, 26],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.469, 25.312],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.312, 24.625],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.062, 23.375],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.969, 22.812],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.906, 22.25],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.844, 21.75],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 21.188],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 20.75],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 20.281],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 19.875],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.75, 19.438],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.844, 19.125],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.844, 18.719],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.969, 18.094],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.031, 17.812],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.125, 17.562],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.25, 17.344],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.344, 17.125],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.469, 16.906],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.656, 16.781],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 16.594],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.969, 16.469],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.125, 16.375],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.281, 16.312],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.469, 16.25],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.625, 16.219],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.781, 16.156],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, 16.156],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.156, 16.219],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.312, 16.219],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.5, 16.281],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.812, 16.531],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.938, 16.688],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.094, 16.906],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 17.094],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.438, 17.312],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.594, 17.562],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.812, 17.812],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.969, 18.062],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.219, 18.406],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.438, 18.688],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.719, 19.031],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.969, 19.312],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.219, 19.656],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.062, 20.688],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.344, 21.094],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.656, 21.406],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.906, 21.781],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.188, 22.156],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.5, 22.531],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 22.875],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.094, 23.25],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.031, 23.594],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.938, 24],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, 24.375],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.688, 24.719],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.531, 25.094],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.344, 25.438],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.188, 25.75],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37, 26.094],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.781, 26.406],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.594, 26.719],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.344, 27.031],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.844, 27.594],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.594, 27.844],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.312, 28.125],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.062, 28.375],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.75, 28.625],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.438, 28.781],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.156, 29],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.812, 29.219],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.531, 29.406],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 29.594],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.875, 29.75],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.5, 29.906],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.188, 30.062],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.156, 30.438],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 30.5],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.438, 30.594],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.125, 30.625],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 30.719],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.219, 30.719],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.906, 30.719],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.625, 30.75],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.406, 30.688],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.125, 30.688],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.875, 30.656],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.688, 30.594],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.469, 30.562],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.906, 30.375],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.75, 30.281],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 30.219],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.219, 29.844],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.125, 29.688],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 29.562],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, 29.469],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, 29.375],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, 29.25],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.969, 29.094],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, 28.906],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 28.688],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 28.469],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.156, 28.219],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.219, 27.938],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.281, 27.625],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.406, 27.312],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.5, 27],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 26.656],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.781, 26.281],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.969, 25.969],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.156, 25.594],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.344, 25.156],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.531, 24.781],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.781, 24.344],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28, 23.938],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.25, 23.5],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 23],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.719, 22.594],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29, 22.094],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.25, 21.625],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.531, 21.156],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.094, 20.281],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.031, 18.969],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.344, 18.562],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.688, 18.125],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.969, 17.719],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.312, 17.312],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.656, 16.938],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.969, 16.562],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.656, 15.812],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34, 15.5],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.344, 15.219],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.969, 14.594],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.344, 14.375],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.656, 14.125],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.969, 13.906],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.281, 13.719],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.625, 13.531],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.906, 13.406],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.219, 13.281],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.531, 13.219],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, 13.188],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.406, 13.125],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.656, 13.188],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.938, 13.219],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.188, 13.344],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.406, 13.5],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.625, 13.625],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 13.844],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.969, 14.094],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.125, 14.312],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, 15],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.438, 15.344],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.562, 15.719],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.594, 16.219],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.625, 16.656],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.656, 17.125],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.656, 17.656],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.656, 18.125],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.656, 18.656],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.594, 19.219],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.562, 19.75],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.5, 20.281],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.406, 20.844],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.344, 21.406],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.25, 22],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.125, 22.531],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, 23.125],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.906, 23.688],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.719, 24.281],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.562, 24.844],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.438, 25.438],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.25, 26],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.031, 26.594],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.844, 27.156],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.625, 27.719],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.469, 28.281],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.188, 28.812],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.969, 29.375],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 29.938],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.5, 30.438],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.281, 30.969],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 31.5],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.781, 32],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.5, 32.469],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.25, 32.938],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.031, 33.375],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.75, 33.781],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, 34.188],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.281, 34.594],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.062, 34.938],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.844, 35.25],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.594, 35.594],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.406, 35.906],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.188, 36.156],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.031, 36.344],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.844, 36.562],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.688, 36.719],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.562, 36.844],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.406, 36.938],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.312, 36.938],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 37],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 36.906],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 36.875],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 36.562],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 36.344],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 3',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [131.429, 10.859],
                                  [39.823, -50.159],
                                  [6.57, -88.644],
                                  [-42.73, -44.018],
                                  [-14.071, -27.999],
                                  [-184.288, 35.001],
                                  [15.336, 52.374],
                                  [4.216, 46.783],
                                  [-22.143, 92],
                                ],
                                o: [
                                  [-76.428, 0.142],
                                  [-39.823, 50.159],
                                  [-6.57, 88.644],
                                  [42.73, 44.018],
                                  [24.286, 40.571],
                                  [104.143, -17.715],
                                  [-15.336, -52.374],
                                  [-19.145, -60.14],
                                  [22.143, -92],
                                ],
                                v: [
                                  [-24.631, -288.358],
                                  [-181.148, -246.712],
                                  [-251.397, -44.493],
                                  [-246.13, 151.277],
                                  [-159.013, 253.422],
                                  [130.66, 353.677],
                                  [311.041, 244.349],
                                  [228.305, 55.372],
                                  [147.59, -141.529],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.233, 10.467],
                                  [39.35, -50.694],
                                  [6.903, -88.316],
                                  [-43.324, -43.956],
                                  [-14.096, -28.273],
                                  [-183.837, 34.795],
                                  [16.138, 52.571],
                                  [3.878, 47.464],
                                  [-22.055, 92],
                                ],
                                o: [
                                  [-76.575, 0.465],
                                  [-39.35, 50.694],
                                  [-6.903, 88.316],
                                  [43.324, 43.956],
                                  [24.247, 40.767],
                                  [104.192, -17.617],
                                  [-16.138, -52.571],
                                  [-18.713, -60.738],
                                  [22.055, -92],
                                ],
                                v: [
                                  [-24.646, -289.787],
                                  [-181.064, -245.779],
                                  [-251.46, -43.925],
                                  [-246.435, 151.028],
                                  [-157.823, 254.555],
                                  [130.364, 353.396],
                                  [310.362, 244.427],
                                  [227.178, 54.016],
                                  [148.241, -142.23],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.038, 10.076],
                                  [38.88, -51.227],
                                  [7.235, -87.989],
                                  [-43.916, -43.893],
                                  [-14.12, -28.547],
                                  [-183.388, 34.59],
                                  [16.938, 52.768],
                                  [3.541, 48.142],
                                  [-21.967, 92],
                                ],
                                o: [
                                  [-76.721, 0.787],
                                  [-38.88, 51.227],
                                  [-7.235, 87.989],
                                  [43.916, 43.893],
                                  [24.208, 40.962],
                                  [104.24, -17.519],
                                  [-16.938, -52.768],
                                  [-18.284, -61.334],
                                  [21.967, -92],
                                ],
                                v: [
                                  [-24.613, -291.237],
                                  [-180.931, -244.918],
                                  [-251.548, -43.36],
                                  [-246.687, 150.797],
                                  [-156.572, 255.628],
                                  [130.037, 353.11],
                                  [309.721, 244.547],
                                  [226.048, 52.68],
                                  [148.9, -142.928],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.844, 9.688],
                                  [38.411, -51.758],
                                  [7.566, -87.663],
                                  [-44.505, -43.831],
                                  [-14.145, -28.819],
                                  [-182.941, 34.386],
                                  [17.733, 52.964],
                                  [3.206, 48.818],
                                  [-21.88, 92],
                                ],
                                o: [
                                  [-76.867, 1.108],
                                  [-38.411, 51.758],
                                  [-7.566, 87.663],
                                  [44.505, 43.831],
                                  [24.169, 41.156],
                                  [104.289, -17.422],
                                  [-17.733, -52.963],
                                  [-17.856, -61.927],
                                  [21.88, -92],
                                ],
                                v: [
                                  [-24.593, -292.71],
                                  [-180.828, -244.016],
                                  [-251.59, -42.818],
                                  [-246.948, 150.584],
                                  [-155.227, 256.675],
                                  [129.672, 352.773],
                                  [309.133, 244.661],
                                  [224.894, 51.315],
                                  [149.568, -143.547],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.65, 9.3],
                                  [37.944, -52.287],
                                  [7.895, -87.339],
                                  [-45.092, -43.77],
                                  [-14.169, -29.09],
                                  [-182.495, 34.183],
                                  [18.526, 53.158],
                                  [2.872, 49.491],
                                  [-21.793, 92],
                                ],
                                o: [
                                  [-77.012, 1.427],
                                  [-37.944, 52.287],
                                  [-7.895, 87.339],
                                  [45.092, 43.77],
                                  [24.13, 41.35],
                                  [104.337, -17.325],
                                  [-18.526, -53.158],
                                  [-17.43, -62.517],
                                  [21.793, -92],
                                ],
                                v: [
                                  [-24.575, -294.17],
                                  [-180.739, -243.075],
                                  [-251.641, -42.326],
                                  [-247.206, 150.422],
                                  [-153.884, 257.709],
                                  [129.276, 352.432],
                                  [308.549, 244.83],
                                  [223.799, 49.937],
                                  [150.278, -144.103],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.457, 8.915],
                                  [37.479, -52.813],
                                  [8.222, -87.016],
                                  [-45.676, -43.708],
                                  [-14.193, -29.36],
                                  [-182.052, 33.98],
                                  [19.315, 53.352],
                                  [2.539, 50.16],
                                  [-21.706, 92],
                                ],
                                o: [
                                  [-77.157, 1.745],
                                  [-37.479, 52.813],
                                  [-8.222, 87.016],
                                  [45.676, 43.708],
                                  [24.091, 41.543],
                                  [104.386, -17.229],
                                  [-19.315, -53.352],
                                  [-17.006, -63.105],
                                  [21.706, -92],
                                ],
                                v: [
                                  [-24.59, -295.59],
                                  [-180.65, -242.206],
                                  [-251.632, -41.82],
                                  [-247.427, 150.276],
                                  [-152.498, 258.717],
                                  [128.862, 352.088],
                                  [308.004, 245.008],
                                  [222.667, 48.565],
                                  [151.057, -144.61],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.265, 8.531],
                                  [37.015, -53.337],
                                  [8.549, -86.695],
                                  [-46.258, -43.647],
                                  [-14.217, -29.628],
                                  [-181.611, 33.779],
                                  [20.101, 53.545],
                                  [2.208, 50.828],
                                  [-21.62, 92],
                                ],
                                o: [
                                  [-77.301, 2.062],
                                  [-37.015, 53.337],
                                  [-8.549, 86.695],
                                  [46.258, 43.647],
                                  [24.053, 41.735],
                                  [104.434, -17.133],
                                  [-20.101, -53.545],
                                  [-16.584, -63.69],
                                  [21.619, -92],
                                ],
                                v: [
                                  [-24.634, -296.888],
                                  [-180.59, -241.299],
                                  [-251.611, -41.314],
                                  [-247.645, 150.131],
                                  [-151.053, 259.68],
                                  [128.381, 351.679],
                                  [307.448, 245.195],
                                  [221.546, 47.181],
                                  [151.796, -145.088],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.074, 8.149],
                                  [36.554, -53.859],
                                  [8.874, -86.375],
                                  [-46.837, -43.586],
                                  [-14.241, -29.896],
                                  [-181.171, 33.578],
                                  [20.883, 53.738],
                                  [1.878, 51.492],
                                  [-21.533, 92],
                                ],
                                o: [
                                  [-77.444, 2.377],
                                  [-36.554, 53.859],
                                  [-8.874, 86.375],
                                  [46.837, 43.586],
                                  [24.015, 41.926],
                                  [104.481, -17.037],
                                  [-20.883, -53.738],
                                  [-16.164, -64.273],
                                  [21.533, -92],
                                ],
                                v: [
                                  [-24.711, -298.161],
                                  [-180.529, -240.402],
                                  [-251.564, -40.887],
                                  [-247.876, 149.986],
                                  [-149.58, 260.617],
                                  [127.896, 351.282],
                                  [306.96, 245.424],
                                  [220.389, 45.756],
                                  [152.637, -145.473],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.884, 7.768],
                                  [36.095, -54.379],
                                  [9.197, -86.056],
                                  [-47.414, -43.526],
                                  [-14.264, -30.162],
                                  [-180.733, 33.378],
                                  [21.662, 53.929],
                                  [1.55, 52.153],
                                  [-21.448, 92],
                                ],
                                o: [
                                  [-77.587, 2.691],
                                  [-36.095, 54.379],
                                  [-9.197, 86.056],
                                  [47.414, 43.526],
                                  [23.977, 42.116],
                                  [104.529, -16.942],
                                  [-21.662, -53.929],
                                  [-15.745, -64.854],
                                  [21.448, -92],
                                ],
                                v: [
                                  [-24.849, -299.316],
                                  [-180.436, -239.53],
                                  [-251.505, -40.395],
                                  [-248.058, 149.855],
                                  [-148.066, 261.495],
                                  [127.345, 350.866],
                                  [306.447, 245.647],
                                  [219.306, 44.369],
                                  [153.421, -145.829],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.694, 7.389],
                                  [35.638, -54.897],
                                  [9.519, -85.738],
                                  [-47.989, -43.465],
                                  [-14.288, -30.428],
                                  [-180.297, 33.179],
                                  [22.438, 54.12],
                                  [1.223, 52.812],
                                  [-21.363, 92],
                                ],
                                o: [
                                  [-77.729, 3.004],
                                  [-35.638, 54.897],
                                  [-9.519, 85.738],
                                  [47.989, 43.465],
                                  [23.939, 42.306],
                                  [104.576, -16.847],
                                  [-22.438, -54.12],
                                  [-15.328, -65.432],
                                  [21.362, -92],
                                ],
                                v: [
                                  [-25.034, -300.463],
                                  [-180.403, -238.606],
                                  [-251.434, -39.933],
                                  [-248.209, 149.786],
                                  [-146.557, 262.362],
                                  [126.773, 350.463],
                                  [306.049, 245.88],
                                  [218.172, 42.987],
                                  [154.274, -146.189],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.506, 7.011],
                                  [35.183, -55.413],
                                  [9.84, -85.422],
                                  [-48.561, -43.405],
                                  [-14.312, -30.692],
                                  [-179.863, 32.981],
                                  [23.211, 54.31],
                                  [0.897, 53.468],
                                  [-21.278, 92],
                                ],
                                o: [
                                  [-77.871, 3.316],
                                  [-35.183, 55.413],
                                  [-9.84, 85.422],
                                  [48.561, 43.405],
                                  [23.901, 42.494],
                                  [104.624, -16.753],
                                  [-23.211, -54.31],
                                  [-14.913, -66.008],
                                  [21.278, -92],
                                ],
                                v: [
                                  [-25.217, -301.478],
                                  [-180.338, -237.708],
                                  [-251.335, -39.497],
                                  [-248.406, 149.697],
                                  [-145.054, 263.155],
                                  [126.197, 349.996],
                                  [305.579, 246.125],
                                  [217.05, 41.596],
                                  [155.134, -146.443],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.318, 6.636],
                                  [34.729, -55.926],
                                  [10.16, -85.107],
                                  [-49.13, -43.345],
                                  [-14.335, -30.955],
                                  [-179.431, 32.784],
                                  [23.98, 54.499],
                                  [0.573, 54.121],
                                  [-21.193, 92],
                                ],
                                o: [
                                  [-78.012, 3.626],
                                  [-34.729, 55.926],
                                  [-10.16, 85.107],
                                  [49.13, 43.345],
                                  [23.864, 42.682],
                                  [104.671, -16.659],
                                  [-23.98, -54.499],
                                  [-14.499, -66.581],
                                  [21.193, -92],
                                ],
                                v: [
                                  [-25.445, -302.488],
                                  [-180.316, -236.822],
                                  [-251.195, -39.058],
                                  [-248.556, 149.684],
                                  [-143.465, 264],
                                  [125.6, 349.526],
                                  [305.179, 246.411],
                                  [215.91, 40.182],
                                  [156.029, -146.67],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.131, 6.261],
                                  [34.278, -56.437],
                                  [10.478, -84.794],
                                  [-49.698, -43.285],
                                  [-14.359, -31.217],
                                  [-179.001, 32.587],
                                  [24.746, 54.687],
                                  [0.25, 54.771],
                                  [-21.109, 92],
                                ],
                                o: [
                                  [-78.152, 3.934],
                                  [-34.278, 56.437],
                                  [-10.478, 84.794],
                                  [49.698, 43.285],
                                  [23.826, 42.869],
                                  [104.717, -16.565],
                                  [-24.746, -54.687],
                                  [-14.087, -67.151],
                                  [21.109, -92],
                                ],
                                v: [
                                  [-25.688, -303.368],
                                  [-180.262, -235.946],
                                  [-251.073, -38.598],
                                  [-248.722, 149.65],
                                  [-141.911, 264.726],
                                  [124.935, 349.116],
                                  [304.815, 246.663],
                                  [214.811, 38.79],
                                  [156.945, -146.856],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.944, 5.889],
                                  [33.828, -56.946],
                                  [10.795, -84.482],
                                  [-50.262, -43.226],
                                  [-14.382, -31.478],
                                  [-178.572, 32.392],
                                  [25.509, 54.875],
                                  [-0.071, 55.418],
                                  [-21.025, 92],
                                ],
                                o: [
                                  [-78.292, 4.242],
                                  [-33.828, 56.946],
                                  [-10.795, 84.482],
                                  [50.262, 43.226],
                                  [23.789, 43.056],
                                  [104.764, -16.472],
                                  [-25.509, -54.875],
                                  [-13.678, -67.72],
                                  [21.025, -92],
                                ],
                                v: [
                                  [-25.975, -304.199],
                                  [-180.203, -235.035],
                                  [-250.921, -38.179],
                                  [-248.844, 149.627],
                                  [-140.289, 265.46],
                                  [124.265, 348.641],
                                  [304.459, 246.973],
                                  [213.65, 37.359],
                                  [157.895, -146.986],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.759, 5.518],
                                  [33.381, -57.453],
                                  [11.11, -84.171],
                                  [-50.825, -43.167],
                                  [-14.405, -31.738],
                                  [-178.145, 32.197],
                                  [26.269, 55.061],
                                  [-0.391, 56.063],
                                  [-20.941, 92],
                                ],
                                o: [
                                  [-78.431, 4.548],
                                  [-33.381, 57.453],
                                  [-11.11, 84.171],
                                  [50.825, 43.167],
                                  [23.752, 43.241],
                                  [104.81, -16.379],
                                  [-26.269, -55.061],
                                  [-13.269, -68.286],
                                  [20.941, -92],
                                ],
                                v: [
                                  [-26.305, -305.029],
                                  [-180.175, -234.18],
                                  [-250.729, -37.738],
                                  [-248.967, 149.615],
                                  [-138.72, 266.166],
                                  [123.572, 348.181],
                                  [304.156, 247.28],
                                  [212.53, 35.967],
                                  [158.805, -147.079],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.574, 5.148],
                                  [32.935, -57.957],
                                  [11.424, -83.862],
                                  [-51.385, -43.108],
                                  [-14.428, -31.996],
                                  [-177.72, 32.003],
                                  [27.025, 55.247],
                                  [-0.71, 56.705],
                                  [-20.858, 92],
                                ],
                                o: [
                                  [-78.569, 4.853],
                                  [-32.935, 57.957],
                                  [-11.424, 83.862],
                                  [51.385, 43.108],
                                  [23.715, 43.426],
                                  [104.857, -16.287],
                                  [-27.025, -55.247],
                                  [-12.863, -68.849],
                                  [20.858, -92],
                                ],
                                v: [
                                  [-26.651, -305.732],
                                  [-180.125, -233.292],
                                  [-250.57, -37.318],
                                  [-249.108, 149.642],
                                  [-137.113, 266.861],
                                  [122.874, 347.701],
                                  [303.845, 247.538],
                                  [211.377, 34.581],
                                  [159.764, -147.146],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.39, 4.78],
                                  [32.491, -58.46],
                                  [11.737, -83.553],
                                  [-51.942, -43.049],
                                  [-14.451, -32.254],
                                  [-177.297, 31.81],
                                  [27.778, 55.432],
                                  [-1.027, 57.344],
                                  [-20.776, 92],
                                ],
                                o: [
                                  [-78.707, 5.156],
                                  [-32.491, 58.46],
                                  [-11.737, 83.553],
                                  [51.942, 43.049],
                                  [23.678, 43.61],
                                  [104.902, -16.195],
                                  [-27.778, -55.432],
                                  [-12.458, -69.41],
                                  [20.776, -92],
                                ],
                                v: [
                                  [-27.039, -306.436],
                                  [-180.104, -232.414],
                                  [-250.338, -36.847],
                                  [-249.207, 149.66],
                                  [-135.494, 267.458],
                                  [122.108, 347.193],
                                  [303.542, 247.856],
                                  [210.237, 33.19],
                                  [160.772, -147.159],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.207, 4.414],
                                  [32.049, -58.96],
                                  [12.048, -83.247],
                                  [-52.498, -42.991],
                                  [-14.474, -32.51],
                                  [-176.876, 31.617],
                                  [28.528, 55.617],
                                  [-1.343, 57.981],
                                  [-20.693, 92],
                                ],
                                o: [
                                  [-78.845, 5.459],
                                  [-32.049, 58.96],
                                  [-12.048, 83.247],
                                  [52.498, 42.991],
                                  [23.641, 43.793],
                                  [104.948, -16.103],
                                  [-28.528, -55.617],
                                  [-12.055, -69.969],
                                  [20.693, -92],
                                ],
                                v: [
                                  [-27.425, -307.033],
                                  [-180.016, -231.547],
                                  [-250.112, -36.44],
                                  [-249.352, 149.669],
                                  [-133.857, 268.091],
                                  [121.38, 346.727],
                                  [303.295, 248.17],
                                  [209.127, 31.804],
                                  [161.739, -147.137],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.025, 4.049],
                                  [31.609, -59.458],
                                  [12.358, -82.941],
                                  [-53.05, -42.933],
                                  [-14.497, -32.766],
                                  [-176.457, 31.426],
                                  [29.274, 55.8],
                                  [-1.658, 58.615],
                                  [-20.611, 92],
                                ],
                                o: [
                                  [-78.982, 5.759],
                                  [-31.609, 59.458],
                                  [-12.358, 82.941],
                                  [53.05, 42.933],
                                  [23.605, 43.975],
                                  [104.994, -16.012],
                                  [-29.274, -55.8],
                                  [-11.654, -70.525],
                                  [20.611, -92],
                                ],
                                v: [
                                  [-27.827, -307.633],
                                  [-180.001, -230.692],
                                  [-249.9, -35.962],
                                  [-249.454, 149.731],
                                  [-132.271, 268.688],
                                  [120.584, 346.278],
                                  [303.101, 248.456],
                                  [207.967, 30.416],
                                  [162.754, -147.108],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.843, 3.686],
                                  [31.171, -59.954],
                                  [12.667, -82.637],
                                  [-53.601, -42.875],
                                  [-14.52, -33.02],
                                  [-176.039, 31.235],
                                  [30.018, 55.983],
                                  [-1.971, 59.246],
                                  [-20.529, 92],
                                ],
                                o: [
                                  [-79.118, 6.059],
                                  [-31.171, 59.954],
                                  [-12.667, 82.637],
                                  [53.601, 42.875],
                                  [23.569, 44.157],
                                  [105.039, -15.922],
                                  [-30.018, -55.983],
                                  [-11.255, -71.079],
                                  [20.529, -92],
                                ],
                                v: [
                                  [-28.271, -308.174],
                                  [-179.917, -229.847],
                                  [-249.651, -35.521],
                                  [-249.534, 149.8],
                                  [-130.649, 269.215],
                                  [119.781, 345.853],
                                  [302.856, 248.759],
                                  [206.821, 29.032],
                                  [163.753, -147.028],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.662, 3.324],
                                  [30.735, -60.448],
                                  [12.974, -82.334],
                                  [-54.149, -42.817],
                                  [-14.542, -33.273],
                                  [-175.623, 31.045],
                                  [30.758, 56.165],
                                  [-2.283, 59.874],
                                  [-20.448, 92],
                                ],
                                o: [
                                  [-79.253, 6.357],
                                  [-30.735, 60.448],
                                  [-12.974, 82.334],
                                  [54.149, 42.817],
                                  [23.532, 44.338],
                                  [105.084, -15.831],
                                  [-30.758, -56.165],
                                  [-10.857, -71.63],
                                  [20.448, -92],
                                ],
                                v: [
                                  [-28.732, -308.7],
                                  [-179.864, -229.012],
                                  [-249.391, -35.053],
                                  [-249.661, 149.84],
                                  [-129.015, 269.734],
                                  [118.973, 345.401],
                                  [302.667, 249.104],
                                  [205.644, 27.67],
                                  [164.818, -146.941],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.482, 2.964],
                                  [30.301, -60.939],
                                  [13.28, -82.033],
                                  [-54.695, -42.76],
                                  [-14.565, -33.525],
                                  [-175.209, 30.856],
                                  [31.495, 56.346],
                                  [-2.593, 60.499],
                                  [-20.367, 92],
                                ],
                                o: [
                                  [-79.388, 6.654],
                                  [-30.301, 60.939],
                                  [-13.28, 82.033],
                                  [54.695, 42.76],
                                  [23.496, 44.518],
                                  [105.129, -15.741],
                                  [-31.495, -56.346],
                                  [-10.461, -72.179],
                                  [20.367, -92],
                                ],
                                v: [
                                  [-29.191, -309.145],
                                  [-179.783, -228.169],
                                  [-249.12, -34.576],
                                  [-249.727, 149.948],
                                  [-127.408, 270.202],
                                  [118.139, 344.928],
                                  [302.532, 249.404],
                                  [204.48, 26.332],
                                  [165.866, -146.761],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.303, 2.606],
                                  [29.868, -61.429],
                                  [13.585, -81.733],
                                  [-55.238, -42.703],
                                  [-14.587, -33.776],
                                  [-174.797, 30.668],
                                  [32.228, 56.526],
                                  [-2.902, 61.122],
                                  [-20.286, 92],
                                ],
                                o: [
                                  [-79.523, 6.95],
                                  [-29.868, 61.429],
                                  [-13.585, 81.732],
                                  [55.238, 42.703],
                                  [23.461, 44.697],
                                  [105.174, -15.651],
                                  [-32.228, -56.526],
                                  [-10.066, -72.726],
                                  [20.286, -92],
                                ],
                                v: [
                                  [-29.691, -309.597],
                                  [-179.669, -227.356],
                                  [-248.839, -34.113],
                                  [-249.816, 150.024],
                                  [-125.808, 270.662],
                                  [117.256, 344.535],
                                  [302.41, 249.722],
                                  [203.309, 24.973],
                                  [166.917, -146.639],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.125, 2.249],
                                  [29.438, -61.916],
                                  [13.888, -81.434],
                                  [-55.779, -42.646],
                                  [-14.609, -34.026],
                                  [-174.386, 30.481],
                                  [32.959, 56.706],
                                  [-3.21, 61.742],
                                  [-20.206, 92],
                                ],
                                o: [
                                  [-79.657, 7.245],
                                  [-29.438, 61.916],
                                  [-13.888, 81.434],
                                  [55.779, 42.646],
                                  [23.425, 44.875],
                                  [105.219, -15.562],
                                  [-32.959, -56.706],
                                  [-9.674, -73.27],
                                  [20.206, -92],
                                ],
                                v: [
                                  [-30.209, -309.973],
                                  [-179.609, -226.533],
                                  [-248.589, -33.641],
                                  [-249.908, 150.105],
                                  [-124.216, 271.093],
                                  [116.39, 344.122],
                                  [302.301, 249.997],
                                  [202.129, 23.638],
                                  [167.969, -146.426],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.947, 1.894],
                                  [29.009, -62.401],
                                  [14.19, -81.136],
                                  [-56.317, -42.589],
                                  [-14.632, -34.274],
                                  [-173.978, 30.294],
                                  [33.686, 56.885],
                                  [-3.517, 62.36],
                                  [-20.126, 92],
                                ],
                                o: [
                                  [-79.79, 7.538],
                                  [-29.009, 62.401],
                                  [-14.19, 81.136],
                                  [56.317, 42.589],
                                  [23.389, 45.053],
                                  [105.263, -15.474],
                                  [-33.686, -56.885],
                                  [-9.283, -73.812],
                                  [20.126, -92],
                                ],
                                v: [
                                  [-30.725, -310.378],
                                  [-179.477, -225.741],
                                  [-248.307, -33.139],
                                  [-250.023, 150.214],
                                  [-122.632, 271.497],
                                  [115.537, 343.706],
                                  [302.186, 250.313],
                                  [200.942, 22.305],
                                  [169.004, -146.208],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.77, 1.54],
                                  [28.583, -62.884],
                                  [14.491, -80.84],
                                  [-56.854, -42.533],
                                  [-14.654, -34.522],
                                  [-173.571, 30.108],
                                  [34.41, 57.063],
                                  [-3.822, 62.974],
                                  [-20.046, 92],
                                ],
                                o: [
                                  [-79.923, 7.83],
                                  [-28.583, 62.884],
                                  [-14.491, 80.84],
                                  [56.853, 42.533],
                                  [23.354, 45.23],
                                  [105.307, -15.385],
                                  [-34.41, -57.063],
                                  [-8.894, -74.352],
                                  [20.046, -92],
                                ],
                                v: [
                                  [-31.302, -310.689],
                                  [-179.395, -224.939],
                                  [-247.993, -32.627],
                                  [-250.08, 150.327],
                                  [-121.055, 271.873],
                                  [114.657, 343.351],
                                  [302.149, 250.608],
                                  [199.768, 20.973],
                                  [170.102, -145.986],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.594, 1.188],
                                  [28.158, -63.365],
                                  [14.79, -80.545],
                                  [-57.387, -42.477],
                                  [-14.676, -34.769],
                                  [-173.166, 29.924],
                                  [35.131, 57.24],
                                  [-4.126, 63.586],
                                  [-19.967, 92],
                                ],
                                o: [
                                  [-80.055, 8.12],
                                  [-28.158, 63.366],
                                  [-14.79, 80.545],
                                  [57.387, 42.477],
                                  [23.319, 45.406],
                                  [105.352, -15.297],
                                  [-35.131, -57.24],
                                  [-8.507, -74.889],
                                  [19.967, -92],
                                ],
                                v: [
                                  [-31.876, -311.03],
                                  [-179.282, -224.125],
                                  [-247.711, -32.105],
                                  [-250.182, 150.466],
                                  [-119.485, 272.243],
                                  [113.729, 342.953],
                                  [302.106, 250.903],
                                  [198.586, 19.684],
                                  [171.182, -145.739],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.419, 0.837],
                                  [27.735, -63.844],
                                  [15.089, -80.251],
                                  [-57.919, -42.421],
                                  [-14.698, -35.014],
                                  [-172.763, 29.74],
                                  [35.849, 57.416],
                                  [-4.428, 64.196],
                                  [-19.888, 92],
                                ],
                                o: [
                                  [-80.186, 8.409],
                                  [-27.735, 63.844],
                                  [-15.088, 80.251],
                                  [57.919, 42.421],
                                  [23.284, 45.581],
                                  [105.395, -15.209],
                                  [-35.849, -57.416],
                                  [-8.121, -75.423],
                                  [19.888, -92],
                                ],
                                v: [
                                  [-32.429, -311.3],
                                  [-179.156, -223.342],
                                  [-247.399, -31.594],
                                  [-250.245, 150.586],
                                  [-117.964, 272.566],
                                  [112.814, 342.657],
                                  [302.1, 251.178],
                                  [197.397, 18.439],
                                  [172.262, -145.489],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.244, 0.488],
                                  [27.314, -64.321],
                                  [15.385, -79.959],
                                  [-58.448, -42.365],
                                  [-14.719, -35.258],
                                  [-172.361, 29.556],
                                  [36.564, 57.592],
                                  [-4.729, 64.802],
                                  [-19.81, 92],
                                ],
                                o: [
                                  [-80.317, 8.697],
                                  [-27.314, 64.321],
                                  [-15.385, 79.959],
                                  [58.448, 42.365],
                                  [23.249, 45.756],
                                  [105.439, -15.122],
                                  [-36.564, -57.592],
                                  [-7.737, -75.956],
                                  [19.81, -92],
                                ],
                                v: [
                                  [-33.064, -311.582],
                                  [-178.978, -222.568],
                                  [-247.098, -31.072],
                                  [-250.312, 150.709],
                                  [-116.409, 272.844],
                                  [111.914, 342.318],
                                  [302.131, 251.496],
                                  [196.159, 17.154],
                                  [173.322, -145.195],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.07, 0.14],
                                  [26.894, -64.796],
                                  [15.681, -79.668],
                                  [-58.975, -42.31],
                                  [-14.741, -35.502],
                                  [-171.961, 29.374],
                                  [37.275, 57.767],
                                  [-5.029, 65.406],
                                  [-19.732, 92],
                                ],
                                o: [
                                  [-80.447, 8.984],
                                  [-26.894, 64.796],
                                  [-15.681, 79.668],
                                  [58.975, 42.31],
                                  [23.214, 45.93],
                                  [105.482, -15.035],
                                  [-37.275, -57.767],
                                  [-7.354, -76.486],
                                  [19.732, -92],
                                ],
                                v: [
                                  [-33.677, -311.837],
                                  [-178.827, -221.823],
                                  [-246.809, -30.517],
                                  [-250.383, 150.835],
                                  [-114.902, 273.116],
                                  [110.985, 342.019],
                                  [302.138, 251.733],
                                  [194.956, 15.891],
                                  [174.404, -144.856],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.897, -0.206],
                                  [26.477, -65.268],
                                  [15.975, -79.378],
                                  [-59.499, -42.255],
                                  [-14.763, -35.744],
                                  [-171.563, 29.192],
                                  [37.984, 57.941],
                                  [-5.327, 66.007],
                                  [-19.654, 92],
                                ],
                                o: [
                                  [-80.577, 9.27],
                                  [-26.477, 65.268],
                                  [-15.975, 79.378],
                                  [59.499, 42.255],
                                  [23.179, 46.103],
                                  [105.526, -14.949],
                                  [-37.984, -57.941],
                                  [-6.974, -77.014],
                                  [19.654, -92],
                                ],
                                v: [
                                  [-34.29, -312.085],
                                  [-178.643, -221.087],
                                  [-246.512, -29.951],
                                  [-250.479, 150.982],
                                  [-113.402, 273.366],
                                  [110.071, 341.759],
                                  [302.204, 252.034],
                                  [193.744, 14.69],
                                  [175.505, -144.579],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.725, -0.55],
                                  [26.061, -65.739],
                                  [16.268, -79.089],
                                  [-60.021, -42.2],
                                  [-14.784, -35.985],
                                  [-171.167, 29.011],
                                  [38.689, 58.114],
                                  [-5.625, 66.606],
                                  [-19.576, 92],
                                ],
                                o: [
                                  [-80.706, 9.554],
                                  [-26.061, 65.739],
                                  [-16.268, 79.089],
                                  [60.021, 42.2],
                                  [23.145, 46.275],
                                  [105.569, -14.862],
                                  [-38.689, -58.114],
                                  [-6.595, -77.539],
                                  [19.576, -92],
                                ],
                                v: [
                                  [-34.965, -312.308],
                                  [-178.445, -220.337],
                                  [-246.229, -29.374],
                                  [-250.517, 151.149],
                                  [-111.909, 273.613],
                                  [109.168, 341.518],
                                  [302.311, 252.274],
                                  [192.506, 13.471],
                                  [176.566, -144.259],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.553, -0.893],
                                  [25.648, -66.207],
                                  [16.559, -78.802],
                                  [-60.541, -42.145],
                                  [-14.806, -36.225],
                                  [-170.773, 28.831],
                                  [39.391, 58.287],
                                  [-5.92, 67.202],
                                  [-19.499, 92],
                                ],
                                o: [
                                  [-80.835, 9.837],
                                  [-25.648, 66.207],
                                  [-16.559, 78.802],
                                  [60.541, 42.145],
                                  [23.111, 46.447],
                                  [105.612, -14.777],
                                  [-39.391, -58.287],
                                  [-6.217, -78.062],
                                  [19.499, -92],
                                ],
                                v: [
                                  [-35.598, -312.548],
                                  [-178.232, -219.617],
                                  [-245.937, -28.786],
                                  [-250.598, 151.276],
                                  [-110.485, 273.816],
                                  [108.217, 341.316],
                                  [302.455, 252.539],
                                  [191.281, 12.313],
                                  [177.645, -143.92],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.383, -1.235],
                                  [25.236, -66.674],
                                  [16.849, -78.516],
                                  [-61.059, -42.091],
                                  [-14.827, -36.464],
                                  [-170.38, 28.652],
                                  [40.09, 58.459],
                                  [-6.215, 67.795],
                                  [-19.422, 92],
                                ],
                                o: [
                                  [-80.963, 10.119],
                                  [-25.236, 66.674],
                                  [-16.849, 78.516],
                                  [61.059, 42.091],
                                  [23.077, 46.617],
                                  [105.654, -14.691],
                                  [-40.09, -58.459],
                                  [-5.842, -78.583],
                                  [19.422, -92],
                                ],
                                v: [
                                  [-36.295, -312.782],
                                  [-178.026, -218.883],
                                  [-245.661, -28.204],
                                  [-250.66, 151.421],
                                  [-109.045, 273.977],
                                  [107.299, 341.111],
                                  [302.579, 252.767],
                                  [190.03, 11.137],
                                  [178.746, -143.58],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.213, -1.575],
                                  [24.826, -67.138],
                                  [17.138, -78.231],
                                  [-61.574, -42.037],
                                  [-14.848, -36.702],
                                  [-169.989, 28.473],
                                  [40.786, 58.63],
                                  [-6.508, 68.386],
                                  [-19.346, 92],
                                ],
                                o: [
                                  [-81.09, 10.399],
                                  [-24.826, 67.138],
                                  [-17.138, 78.231],
                                  [61.574, 42.037],
                                  [23.043, 46.787],
                                  [105.697, -14.606],
                                  [-40.786, -58.63],
                                  [-5.468, -79.101],
                                  [19.346, -92],
                                ],
                                v: [
                                  [-36.991, -312.978],
                                  [-177.805, -218.173],
                                  [-245.4, -27.61],
                                  [-250.709, 151.605],
                                  [-107.634, 274.158],
                                  [106.374, 340.967],
                                  [302.765, 252.982],
                                  [188.794, 9.999],
                                  [179.823, -143.263],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.043, -1.913],
                                  [24.417, -67.6],
                                  [17.426, -77.948],
                                  [-62.087, -41.983],
                                  [-14.87, -36.939],
                                  [-169.6, 28.296],
                                  [41.478, 58.8],
                                  [-6.8, 68.974],
                                  [-19.27, 92],
                                ],
                                o: [
                                  [-81.217, 10.678],
                                  [-24.417, 67.6],
                                  [-17.426, 77.948],
                                  [62.087, 41.983],
                                  [23.009, 46.957],
                                  [105.739, -14.522],
                                  [-41.478, -58.8],
                                  [-5.096, -79.617],
                                  [19.27, -92],
                                ],
                                v: [
                                  [-37.71, -313.21],
                                  [-177.527, -217.493],
                                  [-245.156, -26.965],
                                  [-250.801, 151.727],
                                  [-106.246, 274.338],
                                  [105.441, 340.82],
                                  [302.995, 253.216],
                                  [187.547, 8.905],
                                  [180.939, -142.946],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.875, -2.25],
                                  [24.011, -68.06],
                                  [17.712, -77.666],
                                  [-62.598, -41.929],
                                  [-14.891, -37.175],
                                  [-169.213, 28.119],
                                  [42.168, 58.969],
                                  [-7.091, 69.559],
                                  [-19.194, 92],
                                ],
                                o: [
                                  [-81.344, 10.956],
                                  [-24.011, 68.06],
                                  [-17.712, 77.666],
                                  [62.598, 41.929],
                                  [22.975, 47.125],
                                  [105.781, -14.438],
                                  [-42.168, -58.97],
                                  [-4.725, -80.131],
                                  [19.194, -92],
                                ],
                                v: [
                                  [-38.429, -313.423],
                                  [-177.27, -216.836],
                                  [-244.906, -26.348],
                                  [-250.834, 151.863],
                                  [-104.887, 274.438],
                                  [104.501, 340.734],
                                  [303.224, 253.399],
                                  [186.276, 7.808],
                                  [182.038, -142.652],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.707, -2.585],
                                  [23.606, -68.518],
                                  [17.997, -77.385],
                                  [-63.106, -41.875],
                                  [-14.912, -37.41],
                                  [-168.827, 27.943],
                                  [42.854, 59.138],
                                  [-7.38, 70.142],
                                  [-19.118, 92],
                                ],
                                o: [
                                  [-81.469, 11.233],
                                  [-23.606, 68.518],
                                  [-17.997, 77.385],
                                  [63.106, 41.875],
                                  [22.941, 47.293],
                                  [105.823, -14.354],
                                  [-42.854, -59.138],
                                  [-4.356, -80.643],
                                  [19.118, -92],
                                ],
                                v: [
                                  [-39.173, -313.658],
                                  [-176.981, -216.161],
                                  [-244.673, -25.719],
                                  [-250.932, 152.036],
                                  [-103.572, 274.562],
                                  [103.592, 340.659],
                                  [303.476, 253.61],
                                  [185.018, 6.731],
                                  [183.176, -142.344],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.54, -2.919],
                                  [23.204, -68.974],
                                  [18.281, -77.105],
                                  [-63.612, -41.822],
                                  [-14.932, -37.643],
                                  [-168.443, 27.767],
                                  [43.538, 59.306],
                                  [-7.668, 70.722],
                                  [-19.043, 92],
                                ],
                                o: [
                                  [-81.595, 11.508],
                                  [-23.204, 68.974],
                                  [-18.281, 77.105],
                                  [63.612, 41.822],
                                  [22.908, 47.46],
                                  [105.865, -14.27],
                                  [-43.538, -59.306],
                                  [-3.989, -81.152],
                                  [19.043, -92],
                                ],
                                v: [
                                  [-39.941, -313.875],
                                  [-176.673, -215.491],
                                  [-244.497, -25.079],
                                  [-251.01, 152.168],
                                  [-102.263, 274.686],
                                  [102.675, 340.669],
                                  [303.752, 253.85],
                                  [183.735, 5.737],
                                  [184.275, -142.062],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.374, -3.252],
                                  [22.803, -69.428],
                                  [18.564, -76.827],
                                  [-64.116, -41.769],
                                  [-14.953, -37.876],
                                  [-168.061, 27.593],
                                  [44.218, 59.473],
                                  [-7.954, 71.3],
                                  [-18.968, 92],
                                ],
                                o: [
                                  [-81.719, 11.783],
                                  [-22.803, 69.428],
                                  [-18.564, 76.827],
                                  [64.116, 41.769],
                                  [22.875, 47.626],
                                  [105.906, -14.187],
                                  [-44.218, -59.473],
                                  [-3.623, -81.659],
                                  [18.968, -92],
                                ],
                                v: [
                                  [-40.749, -314.092],
                                  [-176.307, -214.827],
                                  [-244.303, -24.402],
                                  [-251.053, 152.313],
                                  [-100.984, 274.771],
                                  [101.774, 340.689],
                                  [304.028, 254.058],
                                  [182.443, 4.738],
                                  [185.411, -141.782],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.209, -3.582],
                                  [22.404, -69.88],
                                  [18.845, -76.55],
                                  [-64.617, -41.717],
                                  [-14.974, -38.108],
                                  [-167.68, 27.419],
                                  [44.895, 59.64],
                                  [-8.24, 71.874],
                                  [-18.894, 92],
                                ],
                                o: [
                                  [-81.843, 12.055],
                                  [-22.404, 69.88],
                                  [-18.845, 76.55],
                                  [64.617, 41.717],
                                  [22.842, 47.791],
                                  [105.948, -14.104],
                                  [-44.895, -59.64],
                                  [-3.259, -82.163],
                                  [18.894, -92],
                                ],
                                v: [
                                  [-41.544, -314.319],
                                  [-175.985, -214.219],
                                  [-244.128, -23.739],
                                  [-251.136, 152.468],
                                  [-99.774, 274.794],
                                  [100.88, 340.73],
                                  [304.292, 254.283],
                                  [181.164, 3.734],
                                  [186.572, -141.528],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.044, -3.912],
                                  [22.006, -70.329],
                                  [19.125, -76.274],
                                  [-65.116, -41.664],
                                  [-14.994, -38.338],
                                  [-167.301, 27.246],
                                  [45.569, 59.806],
                                  [-8.524, 72.447],
                                  [-18.82, 92],
                                ],
                                o: [
                                  [-81.967, 12.327],
                                  [-22.006, 70.33],
                                  [-19.125, 76.274],
                                  [65.116, 41.664],
                                  [22.809, 47.956],
                                  [105.989, -14.022],
                                  [-45.569, -59.806],
                                  [-2.897, -82.665],
                                  [18.82, -92],
                                ],
                                v: [
                                  [-42.379, -314.609],
                                  [-175.605, -213.578],
                                  [-243.974, -23.064],
                                  [-251.197, 152.582],
                                  [-98.594, 274.817],
                                  [100.002, 340.805],
                                  [304.594, 254.515],
                                  [179.861, 2.811],
                                  [187.744, -141.265],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.88, -4.24],
                                  [21.611, -70.777],
                                  [19.404, -75.999],
                                  [-65.613, -41.612],
                                  [-15.015, -38.568],
                                  [-166.924, 27.074],
                                  [46.24, 59.971],
                                  [-8.807, 73.016],
                                  [-18.746, 92],
                                ],
                                o: [
                                  [-82.09, 12.598],
                                  [-21.611, 70.777],
                                  [-19.404, 75.999],
                                  [65.613, 41.612],
                                  [22.776, 48.12],
                                  [106.03, -13.94],
                                  [-46.24, -59.971],
                                  [-2.536, -83.165],
                                  [18.746, -92],
                                ],
                                v: [
                                  [-43.203, -314.887],
                                  [-175.241, -212.978],
                                  [-243.817, -22.415],
                                  [-251.249, 152.731],
                                  [-97.484, 274.779],
                                  [99.154, 340.939],
                                  [304.948, 254.78],
                                  [178.572, 1.906],
                                  [188.903, -141.067],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.717, -4.566],
                                  [21.217, -71.223],
                                  [19.681, -75.726],
                                  [-66.108, -41.56],
                                  [-15.035, -38.796],
                                  [-166.549, 26.903],
                                  [46.909, 60.135],
                                  [-9.088, 73.583],
                                  [-18.673, 92],
                                ],
                                o: [
                                  [-82.212, 12.867],
                                  [-21.217, 71.223],
                                  [-19.681, 75.726],
                                  [66.108, 41.56],
                                  [22.743, 48.283],
                                  [106.071, -13.858],
                                  [-46.909, -60.135],
                                  [-2.177, -83.663],
                                  [18.673, -92],
                                ],
                                v: [
                                  [-44.066, -315.13],
                                  [-174.818, -212.394],
                                  [-243.694, -21.717],
                                  [-251.315, 152.887],
                                  [-96.368, 274.715],
                                  [98.298, 341.106],
                                  [305.241, 255.005],
                                  [177.297, 0.995],
                                  [190.062, -140.836],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.555, -4.891],
                                  [20.825, -71.667],
                                  [19.957, -75.454],
                                  [-66.6, -41.508],
                                  [-15.056, -39.024],
                                  [-166.175, 26.732],
                                  [47.573, 60.298],
                                  [-9.368, 74.148],
                                  [-18.6, 92],
                                ],
                                o: [
                                  [-82.334, 13.135],
                                  [-20.825, 71.667],
                                  [-19.957, 75.454],
                                  [66.6, 41.508],
                                  [22.711, 48.445],
                                  [106.111, -13.777],
                                  [-47.573, -60.298],
                                  [-1.82, -84.159],
                                  [18.6, -92],
                                ],
                                v: [
                                  [-44.957, -315.437],
                                  [-174.399, -211.812],
                                  [-243.555, -21.035],
                                  [-251.421, 153.002],
                                  [-95.348, 274.614],
                                  [97.47, 341.332],
                                  [305.561, 255.238],
                                  [175.973, 0.164],
                                  [191.232, -140.645],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.393, -5.214],
                                  [20.435, -72.108],
                                  [20.232, -75.183],
                                  [-67.09, -41.456],
                                  [-15.076, -39.25],
                                  [-165.804, 26.562],
                                  [48.235, 60.461],
                                  [-9.647, 74.71],
                                  [-18.527, 92],
                                ],
                                o: [
                                  [-82.455, 13.402],
                                  [-20.435, 72.108],
                                  [-20.232, 75.183],
                                  [67.09, 41.456],
                                  [22.679, 48.607],
                                  [106.152, -13.696],
                                  [-48.235, -60.461],
                                  [-1.464, -84.652],
                                  [18.527, -92],
                                ],
                                v: [
                                  [-45.813, -315.709],
                                  [-173.958, -211.27],
                                  [-243.477, -20.341],
                                  [-251.491, 153.124],
                                  [-94.336, 274.488],
                                  [96.66, 341.59],
                                  [305.909, 255.481],
                                  [174.663, -0.675],
                                  [192.426, -140.473],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.232, -5.536],
                                  [20.047, -72.548],
                                  [20.506, -74.913],
                                  [-67.578, -41.405],
                                  [-15.096, -39.475],
                                  [-165.433, 26.393],
                                  [48.894, 60.623],
                                  [-9.925, 75.269],
                                  [-18.454, 92],
                                ],
                                o: [
                                  [-82.576, 13.667],
                                  [-20.047, 72.548],
                                  [-20.506, 74.913],
                                  [67.578, 41.405],
                                  [22.646, 48.768],
                                  [106.192, -13.616],
                                  [-48.894, -60.623],
                                  [-1.11, -85.143],
                                  [18.454, -92],
                                ],
                                v: [
                                  [-46.708, -315.984],
                                  [-173.483, -210.704],
                                  [-243.369, -19.664],
                                  [-251.564, 153.276],
                                  [-93.358, 274.387],
                                  [95.842, 341.88],
                                  [306.235, 255.749],
                                  [173.367, -1.461],
                                  [193.619, -140.28],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.072, -5.857],
                                  [19.66, -72.985],
                                  [20.778, -74.645],
                                  [-68.064, -41.354],
                                  [-15.116, -39.7],
                                  [-165.065, 26.225],
                                  [49.55, 60.784],
                                  [-10.201, 75.826],
                                  [-18.382, 92],
                                ],
                                o: [
                                  [-82.696, 13.932],
                                  [-19.66, 72.985],
                                  [-20.778, 74.645],
                                  [68.064, 41.354],
                                  [22.614, 48.928],
                                  [106.232, -13.536],
                                  [-49.55, -60.784],
                                  [-0.758, -85.631],
                                  [18.382, -92],
                                ],
                                v: [
                                  [-47.605, -316.225],
                                  [-173.021, -210.176],
                                  [-243.311, -19.003],
                                  [-251.674, 153.388],
                                  [-92.451, 274.198],
                                  [95.052, 342.227],
                                  [306.579, 255.965],
                                  [172.058, -2.23],
                                  [194.838, -140.18],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.912, -6.176],
                                  [19.276, -73.421],
                                  [21.049, -74.378],
                                  [-68.547, -41.303],
                                  [-15.136, -39.923],
                                  [-164.698, 26.058],
                                  [50.203, 60.945],
                                  [-10.476, 76.38],
                                  [-18.311, 92],
                                ],
                                o: [
                                  [-82.816, 14.195],
                                  [-19.276, 73.421],
                                  [-21.049, 74.378],
                                  [68.547, 41.303],
                                  [22.582, 49.088],
                                  [106.272, -13.456],
                                  [-50.203, -60.945],
                                  [-0.407, -86.118],
                                  [18.311, -92],
                                ],
                                v: [
                                  [-48.468, -316.442],
                                  [-172.499, -209.658],
                                  [-243.26, -18.297],
                                  [-251.76, 153.503],
                                  [-91.57, 274.036],
                                  [94.289, 342.604],
                                  [306.9, 256.218],
                                  [170.789, -2.947],
                                  [196.029, -140.049],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.754, -6.493],
                                  [18.893, -73.855],
                                  [21.319, -74.112],
                                  [-69.028, -41.253],
                                  [-15.156, -40.145],
                                  [-164.333, 25.891],
                                  [50.853, 61.104],
                                  [-10.75, 76.932],
                                  [-18.239, 92],
                                ],
                                o: [
                                  [-82.935, 14.457],
                                  [-18.893, 73.855],
                                  [-21.319, 74.112],
                                  [69.028, 41.253],
                                  [22.551, 49.247],
                                  [106.312, -13.377],
                                  [-50.853, -61.104],
                                  [-0.058, -86.602],
                                  [18.239, -92],
                                ],
                                v: [
                                  [-49.361, -316.688],
                                  [-171.98, -209.168],
                                  [-243.232, -17.618],
                                  [-251.847, 153.613],
                                  [-90.734, 273.847],
                                  [93.545, 343.039],
                                  [307.252, 256.447],
                                  [169.541, -3.648],
                                  [197.22, -139.924],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.595, -6.809],
                                  [18.512, -74.286],
                                  [21.588, -73.847],
                                  [-69.507, -41.202],
                                  [-15.176, -40.366],
                                  [-163.97, 25.725],
                                  [51.5, 61.263],
                                  [-11.023, 77.481],
                                  [-18.168, 92],
                                ],
                                o: [
                                  [-83.053, 14.717],
                                  [-18.512, 74.286],
                                  [-21.588, 73.847],
                                  [69.507, 41.202],
                                  [22.519, 49.405],
                                  [106.351, -13.298],
                                  [-51.5, -61.263],
                                  [0.29, -87.084],
                                  [18.168, -92],
                                ],
                                v: [
                                  [-50.265, -316.91],
                                  [-171.435, -208.686],
                                  [-243.194, -16.985],
                                  [-251.935, 153.717],
                                  [-89.935, 273.595],
                                  [92.855, 343.468],
                                  [307.607, 256.714],
                                  [168.28, -4.36],
                                  [198.41, -139.866],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.438, -7.124],
                                  [18.132, -74.716],
                                  [21.855, -73.584],
                                  [-69.984, -41.152],
                                  [-15.195, -40.586],
                                  [-163.608, 25.56],
                                  [52.144, 61.422],
                                  [-11.294, 78.027],
                                  [-18.097, 92],
                                ],
                                o: [
                                  [-83.171, 14.977],
                                  [-18.132, 74.716],
                                  [-21.855, 73.584],
                                  [69.984, 41.152],
                                  [22.488, 49.562],
                                  [106.39, -13.219],
                                  [-52.144, -61.422],
                                  [0.636, -87.563],
                                  [18.097, -92],
                                ],
                                v: [
                                  [-51.136, -317.161],
                                  [-170.892, -208.212],
                                  [-243.224, -16.308],
                                  [-251.997, 153.85],
                                  [-89.208, 273.37],
                                  [92.129, 343.926],
                                  [307.958, 256.958],
                                  [167.057, -5.023],
                                  [199.626, -139.805],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.282, -7.437],
                                  [17.755, -75.143],
                                  [22.121, -73.322],
                                  [-70.459, -41.102],
                                  [-15.215, -40.806],
                                  [-163.248, 25.396],
                                  [52.784, 61.579],
                                  [-11.564, 78.571],
                                  [-18.027, 92],
                                ],
                                o: [
                                  [-83.289, 15.235],
                                  [-17.755, 75.143],
                                  [-22.121, 73.322],
                                  [70.459, 41.102],
                                  [22.456, 49.718],
                                  [106.43, -13.141],
                                  [-52.784, -61.579],
                                  [0.98, -88.041],
                                  [18.027, -92],
                                ],
                                v: [
                                  [-52.009, -317.353],
                                  [-170.324, -207.771],
                                  [-243.216, -15.659],
                                  [-252.052, 153.949],
                                  [-88.455, 273.118],
                                  [91.485, 344.442],
                                  [308.287, 257.215],
                                  [165.819, -5.642],
                                  [200.814, -139.786],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.126, -7.748],
                                  [17.379, -75.569],
                                  [22.386, -73.061],
                                  [-70.931, -41.053],
                                  [-15.234, -41.024],
                                  [-162.89, 25.232],
                                  [53.422, 61.736],
                                  [-11.833, 79.113],
                                  [-17.957, 92],
                                ],
                                o: [
                                  [-83.406, 15.492],
                                  [-17.379, 75.569],
                                  [-22.386, 73.061],
                                  [70.931, 41.053],
                                  [22.425, 49.874],
                                  [106.469, -13.063],
                                  [-53.422, -61.736],
                                  [1.323, -88.516],
                                  [17.957, -92],
                                ],
                                v: [
                                  [-52.85, -317.547],
                                  [-169.757, -207.329],
                                  [-243.235, -14.995],
                                  [-252.046, 154.006],
                                  [-87.803, 272.831],
                                  [90.805, 344.951],
                                  [308.647, 257.449],
                                  [164.593, -6.241],
                                  [202.064, -139.772],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.971, -8.058],
                                  [17.005, -75.992],
                                  [22.65, -72.801],
                                  [-71.401, -41.003],
                                  [-15.254, -41.241],
                                  [-162.533, 25.069],
                                  [54.057, 61.892],
                                  [-12.1, 79.652],
                                  [-17.887, 92],
                                ],
                                o: [
                                  [-83.522, 15.748],
                                  [-17.005, 75.992],
                                  [-22.65, 72.801],
                                  [71.401, 41.003],
                                  [22.394, 50.029],
                                  [106.507, -12.985],
                                  [-54.057, -61.892],
                                  [1.664, -88.989],
                                  [17.887, -92],
                                ],
                                v: [
                                  [-53.729, -317.744],
                                  [-169.157, -206.92],
                                  [-243.286, -14.353],
                                  [-252.042, 154.092],
                                  [-87.162, 272.545],
                                  [90.145, 345.482],
                                  [308.978, 257.661],
                                  [163.413, -6.853],
                                  [203.25, -139.791],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.816, -8.367],
                                  [16.632, -76.414],
                                  [22.912, -72.543],
                                  [-71.869, -40.954],
                                  [-15.273, -41.457],
                                  [-162.178, 24.907],
                                  [54.689, 62.047],
                                  [-12.367, 80.188],
                                  [-17.817, 92],
                                ],
                                o: [
                                  [-83.638, 16.003],
                                  [-16.632, 76.414],
                                  [-22.912, 72.543],
                                  [71.869, 40.954],
                                  [22.363, 50.184],
                                  [106.546, -12.908],
                                  [-54.689, -62.047],
                                  [2.004, -89.46],
                                  [17.817, -92],
                                ],
                                v: [
                                  [-54.547, -317.909],
                                  [-168.502, -206.474],
                                  [-243.363, -13.704],
                                  [-251.971, 154.137],
                                  [-86.593, 272.259],
                                  [89.539, 346.042],
                                  [309.314, 257.886],
                                  [162.244, -7.424],
                                  [204.464, -139.845],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.663, -8.674],
                                  [16.262, -76.833],
                                  [23.173, -72.285],
                                  [-72.335, -40.905],
                                  [-15.292, -41.672],
                                  [-161.824, 24.746],
                                  [55.318, 62.202],
                                  [-12.632, 80.722],
                                  [-17.748, 92],
                                ],
                                o: [
                                  [-83.753, 16.256],
                                  [-16.262, 76.833],
                                  [-23.173, 72.285],
                                  [72.335, 40.905],
                                  [22.333, 50.337],
                                  [106.584, -12.831],
                                  [-55.318, -62.202],
                                  [2.342, -89.928],
                                  [17.748, -92],
                                ],
                                v: [
                                  [-55.43, -318.111],
                                  [-167.91, -206.032],
                                  [-243.439, -13.107],
                                  [-251.841, 154.176],
                                  [-86.03, 271.944],
                                  [88.925, 346.63],
                                  [309.655, 258.152],
                                  [161.057, -7.947],
                                  [205.711, -139.906],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.51, -8.98],
                                  [15.893, -77.251],
                                  [23.433, -72.029],
                                  [-72.798, -40.856],
                                  [-15.311, -41.886],
                                  [-161.473, 24.585],
                                  [55.944, 62.356],
                                  [-12.895, 81.253],
                                  [-17.68, 92],
                                ],
                                o: [
                                  [-83.868, 16.509],
                                  [-15.893, 77.251],
                                  [-23.433, 72.029],
                                  [72.798, 40.856],
                                  [22.302, 50.49],
                                  [106.623, -12.755],
                                  [-55.944, -62.356],
                                  [2.678, -90.395],
                                  [17.679, -92],
                                ],
                                v: [
                                  [-56.253, -318.282],
                                  [-167.256, -205.621],
                                  [-243.546, -12.475],
                                  [-251.712, 154.214],
                                  [-85.541, 271.658],
                                  [88.366, 347.241],
                                  [309.996, 258.369],
                                  [159.91, -8.452],
                                  [206.894, -140.006],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.358, -9.285],
                                  [15.525, -77.667],
                                  [23.692, -71.774],
                                  [-73.259, -40.808],
                                  [-15.33, -42.099],
                                  [-161.123, 24.426],
                                  [56.567, 62.509],
                                  [-13.158, 81.782],
                                  [-17.611, 92],
                                ],
                                o: [
                                  [-83.982, 16.76],
                                  [-15.525, 77.667],
                                  [-23.692, 71.774],
                                  [73.259, 40.808],
                                  [22.272, 50.642],
                                  [106.661, -12.679],
                                  [-56.567, -62.509],
                                  [3.013, -90.859],
                                  [17.611, -92],
                                ],
                                v: [
                                  [-57.078, -318.49],
                                  [-166.603, -205.174],
                                  [-243.647, -11.896],
                                  [-251.553, 154.24],
                                  [-85.062, 271.343],
                                  [87.8, 347.846],
                                  [310.314, 258.6],
                                  [158.779, -8.945],
                                  [208.106, -140.141],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.206, -9.588],
                                  [15.16, -78.08],
                                  [23.949, -71.52],
                                  [-73.719, -40.759],
                                  [-15.349, -42.311],
                                  [-160.774, 24.266],
                                  [57.187, 62.661],
                                  [-13.419, 82.308],
                                  [-17.543, 92],
                                ],
                                o: [
                                  [-84.095, 17.01],
                                  [-15.16, 78.08],
                                  [-23.949, 71.52],
                                  [73.719, 40.76],
                                  [22.241, 50.794],
                                  [106.698, -12.603],
                                  [-57.187, -62.661],
                                  [3.346, -91.321],
                                  [17.543, -92],
                                ],
                                v: [
                                  [-57.843, -318.666],
                                  [-165.922, -204.724],
                                  [-243.781, -11.312],
                                  [-251.397, 154.231],
                                  [-84.658, 271.024],
                                  [87.255, 348.475],
                                  [310.634, 258.81],
                                  [157.628, -9.425],
                                  [209.321, -140.282],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.055, -9.889],
                                  [14.796, -78.492],
                                  [24.206, -71.268],
                                  [-74.176, -40.711],
                                  [-15.368, -42.522],
                                  [-160.428, 24.108],
                                  [57.804, 62.813],
                                  [-13.679, 82.832],
                                  [-17.475, 92],
                                ],
                                o: [
                                  [-84.208, 17.258],
                                  [-14.796, 78.492],
                                  [-24.206, 71.268],
                                  [74.176, 40.711],
                                  [22.211, 50.945],
                                  [106.736, -12.528],
                                  [-57.804, -62.813],
                                  [3.678, -91.781],
                                  [17.475, -92],
                                ],
                                v: [
                                  [-58.672, -318.817],
                                  [-165.274, -204.275],
                                  [-243.942, -10.72],
                                  [-251.211, 154.246],
                                  [-84.231, 270.676],
                                  [86.765, 349.131],
                                  [310.964, 259.034],
                                  [156.516, -9.861],
                                  [210.531, -140.46],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.905, -10.189],
                                  [14.434, -78.902],
                                  [24.461, -71.017],
                                  [-74.63, -40.664],
                                  [-15.387, -42.732],
                                  [-160.083, 23.951],
                                  [58.418, 62.964],
                                  [-13.938, 83.354],
                                  [-17.407, 92],
                                ],
                                o: [
                                  [-84.321, 17.506],
                                  [-14.434, 78.902],
                                  [-24.461, 71.017],
                                  [74.63, 40.664],
                                  [22.181, 51.095],
                                  [106.774, -12.453],
                                  [-58.418, -62.964],
                                  [4.008, -92.238],
                                  [17.407, -92],
                                ],
                                v: [
                                  [-59.441, -319.032],
                                  [-164.593, -203.853],
                                  [-244.102, -10.188],
                                  [-250.966, 154.192],
                                  [-83.908, 270.361],
                                  [86.268, 349.782],
                                  [311.263, 259.238],
                                  [155.384, -10.252],
                                  [211.74, -140.676],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.756, -10.488],
                                  [14.074, -79.31],
                                  [24.715, -70.767],
                                  [-75.083, -40.616],
                                  [-15.406, -42.941],
                                  [-159.739, 23.794],
                                  [59.03, 63.114],
                                  [-14.196, 83.872],
                                  [-17.34, 92],
                                ],
                                o: [
                                  [-84.433, 17.752],
                                  [-14.074, 79.31],
                                  [-24.715, 70.767],
                                  [75.083, 40.616],
                                  [22.151, 51.244],
                                  [106.811, -12.378],
                                  [-59.03, -63.114],
                                  [4.337, -92.694],
                                  [17.34, -92],
                                ],
                                v: [
                                  [-60.183, -319.186],
                                  [-163.912, -203.397],
                                  [-244.291, -9.619],
                                  [-250.754, 154.194],
                                  [-83.568, 270.014],
                                  [85.826, 350.457],
                                  [311.539, 259.456],
                                  [154.324, -10.663],
                                  [212.919, -140.928],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.608, -10.785],
                                  [13.716, -79.716],
                                  [24.967, -70.518],
                                  [-75.533, -40.569],
                                  [-15.424, -43.149],
                                  [-159.397, 23.638],
                                  [59.638, 63.264],
                                  [-14.452, 84.389],
                                  [-17.273, 92],
                                ],
                                o: [
                                  [-84.544, 17.998],
                                  [-13.716, 79.716],
                                  [-24.967, 70.518],
                                  [75.533, 40.569],
                                  [22.121, 51.392],
                                  [106.848, -12.304],
                                  [-59.638, -63.264],
                                  [4.663, -93.147],
                                  [17.273, -92],
                                ],
                                v: [
                                  [-60.956, -319.344],
                                  [-163.234, -202.938],
                                  [-244.482, -9.077],
                                  [-250.453, 154.128],
                                  [-83.299, 269.729],
                                  [85.408, 351.159],
                                  [311.821, 259.657],
                                  [153.21, -11.063],
                                  [214.126, -141.219],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.46, -11.081],
                                  [13.359, -80.12],
                                  [25.219, -70.27],
                                  [-75.982, -40.521],
                                  [-15.443, -43.356],
                                  [-159.057, 23.483],
                                  [60.243, 63.412],
                                  [-14.707, 84.903],
                                  [-17.207, 92],
                                ],
                                o: [
                                  [-84.655, 18.242],
                                  [-13.359, 80.12],
                                  [-25.219, 70.27],
                                  [75.982, 40.522],
                                  [22.092, 51.54],
                                  [106.885, -12.23],
                                  [-60.243, -63.413],
                                  [4.989, -93.598],
                                  [17.207, -92],
                                ],
                                v: [
                                  [-61.67, -319.535],
                                  [-162.554, -202.476],
                                  [-244.732, -8.563],
                                  [-250.186, 154.087],
                                  [-83.044, 269.381],
                                  [84.983, 351.824],
                                  [312.109, 259.84],
                                  [152.14, -11.421],
                                  [215.334, -141.515],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.312, -11.375],
                                  [13.004, -80.522],
                                  [25.469, -70.023],
                                  [-76.428, -40.475],
                                  [-15.461, -43.563],
                                  [-158.719, 23.328],
                                  [60.846, 63.561],
                                  [-14.961, 85.414],
                                  [-17.141, 92],
                                ],
                                o: [
                                  [-84.766, 18.484],
                                  [-13.004, 80.522],
                                  [-25.469, 70.023],
                                  [76.428, 40.475],
                                  [22.062, 51.688],
                                  [106.922, -12.156],
                                  [-60.846, -63.561],
                                  [5.313, -94.047],
                                  [17.141, -92],
                                ],
                                v: [
                                  [-62.384, -319.697],
                                  [-161.875, -202.011],
                                  [-244.95, -8.048],
                                  [-249.922, 154.041],
                                  [-82.829, 269.066],
                                  [84.615, 352.546],
                                  [312.372, 260.036],
                                  [151.076, -11.737],
                                  [216.51, -141.881],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.166, -11.668],
                                  [12.65, -80.922],
                                  [25.718, -69.778],
                                  [-76.872, -40.428],
                                  [-15.479, -43.768],
                                  [-158.382, 23.174],
                                  [61.446, 63.708],
                                  [-15.214, 85.923],
                                  [-17.075, 92],
                                ],
                                o: [
                                  [-84.876, 18.726],
                                  [-12.65, 80.922],
                                  [-25.718, 69.778],
                                  [76.872, 40.428],
                                  [22.033, 51.834],
                                  [106.959, -12.083],
                                  [-61.446, -63.708],
                                  [5.635, -94.494],
                                  [17.075, -92],
                                ],
                                v: [
                                  [-63.07, -319.861],
                                  [-161.194, -201.574],
                                  [-245.199, -7.561],
                                  [-249.6, 153.958],
                                  [-82.628, 268.719],
                                  [84.271, 353.263],
                                  [312.611, 260.245],
                                  [150.022, -12.043],
                                  [217.715, -142.252],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.02, -11.96],
                                  [12.299, -81.32],
                                  [25.966, -69.534],
                                  [-77.314, -40.381],
                                  [-15.497, -43.972],
                                  [-158.046, 23.021],
                                  [62.042, 63.855],
                                  [-15.465, 86.43],
                                  [-17.009, 92],
                                ],
                                o: [
                                  [-84.985, 18.967],
                                  [-12.299, 81.32],
                                  [-25.966, 69.534],
                                  [77.314, 40.381],
                                  [22.004, 51.98],
                                  [106.995, -12.01],
                                  [-62.042, -63.855],
                                  [5.956, -94.938],
                                  [17.009, -92],
                                ],
                                v: [
                                  [-63.757, -320.058],
                                  [-160.513, -201.134],
                                  [-245.478, -7.041],
                                  [-249.282, 153.901],
                                  [-82.501, 268.434],
                                  [83.921, 354.006],
                                  [312.887, 260.405],
                                  [148.975, -12.372],
                                  [218.889, -142.661],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.875, -12.25],
                                  [11.949, -81.716],
                                  [26.212, -69.291],
                                  [-77.753, -40.335],
                                  [-15.516, -44.175],
                                  [-157.713, 22.869],
                                  [62.636, 64.001],
                                  [-15.715, 86.934],
                                  [-16.944, 92],
                                ],
                                o: [
                                  [-85.094, 19.206],
                                  [-11.949, 81.716],
                                  [-26.212, 69.291],
                                  [77.753, 40.335],
                                  [21.975, 52.125],
                                  [107.031, -11.938],
                                  [-62.636, -64.001],
                                  [6.275, -95.381],
                                  [16.944, -92],
                                ],
                                v: [
                                  [-64.414, -320.258],
                                  [-159.862, -200.658],
                                  [-245.756, -6.583],
                                  [-248.967, 153.809],
                                  [-82.386, 268.119],
                                  [83.659, 354.745],
                                  [313.14, 260.578],
                                  [147.937, -12.66],
                                  [220.062, -143.108],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.731, -12.538],
                                  [11.6, -82.11],
                                  [26.458, -69.049],
                                  [-78.191, -40.289],
                                  [-15.534, -44.377],
                                  [-157.381, 22.717],
                                  [63.227, 64.146],
                                  [-15.964, 87.436],
                                  [-16.879, 92],
                                ],
                                o: [
                                  [-85.202, 19.444],
                                  [-11.6, 82.11],
                                  [-26.458, 69.049],
                                  [78.191, 40.289],
                                  [21.946, 52.269],
                                  [107.067, -11.865],
                                  [-63.227, -64.146],
                                  [6.592, -95.821],
                                  [16.879, -92],
                                ],
                                v: [
                                  [-65.04, -320.427],
                                  [-159.211, -200.211],
                                  [-246.035, -6.124],
                                  [-248.625, 153.712],
                                  [-82.283, 267.803],
                                  [83.391, 355.449],
                                  [313.369, 260.732],
                                  [146.938, -12.91],
                                  [221.235, -143.623],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.587, -12.826],
                                  [11.254, -82.503],
                                  [26.702, -68.808],
                                  [-78.627, -40.243],
                                  [-15.552, -44.578],
                                  [-157.05, 22.566],
                                  [63.815, 64.29],
                                  [-16.212, 87.935],
                                  [-16.814, 92],
                                ],
                                o: [
                                  [-85.31, 19.681],
                                  [-11.254, 82.503],
                                  [-26.702, 68.808],
                                  [78.627, 40.243],
                                  [21.917, 52.413],
                                  [107.103, -11.794],
                                  [-63.815, -64.291],
                                  [6.908, -96.259],
                                  [16.814, -92],
                                ],
                                v: [
                                  [-65.669, -320.629],
                                  [-158.526, -199.76],
                                  [-246.375, -5.696],
                                  [-248.318, 153.611],
                                  [-82.255, 267.518],
                                  [83.151, 356.179],
                                  [313.542, 260.899],
                                  [145.945, -13.151],
                                  [222.438, -144.113],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.444, -13.112],
                                  [10.909, -82.893],
                                  [26.945, -68.569],
                                  [-79.06, -40.198],
                                  [-15.569, -44.778],
                                  [-156.722, 22.416],
                                  [64.401, 64.434],
                                  [-16.459, 88.432],
                                  [-16.75, 92],
                                ],
                                o: [
                                  [-85.417, 19.917],
                                  [-10.909, 82.893],
                                  [-26.945, 68.569],
                                  [79.06, 40.198],
                                  [21.889, 52.556],
                                  [107.139, -11.722],
                                  [-64.401, -64.434],
                                  [7.223, -96.695],
                                  [16.75, -92],
                                ],
                                v: [
                                  [-66.234, -320.801],
                                  [-157.902, -199.273],
                                  [-246.684, -5.269],
                                  [-247.951, 153.538],
                                  [-82.207, 267.232],
                                  [82.937, 356.937],
                                  [313.755, 261.078],
                                  [144.96, -13.385],
                                  [223.578, -144.672],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.302, -13.396],
                                  [10.566, -83.282],
                                  [27.187, -68.331],
                                  [-79.491, -40.152],
                                  [-15.587, -44.977],
                                  [-156.394, 22.267],
                                  [64.983, 64.578],
                                  [-16.704, 88.926],
                                  [-16.686, 92],
                                ],
                                o: [
                                  [-85.524, 20.152],
                                  [-10.566, 83.282],
                                  [-27.187, 68.331],
                                  [79.491, 40.152],
                                  [21.86, 52.698],
                                  [107.174, -11.651],
                                  [-64.983, -64.577],
                                  [7.536, -97.129],
                                  [16.686, -92],
                                ],
                                v: [
                                  [-66.8, -320.975],
                                  [-157.247, -198.847],
                                  [-247.024, -4.842],
                                  [-247.589, 153.43],
                                  [-82.203, 266.978],
                                  [82.75, 357.691],
                                  [313.976, 261.207],
                                  [143.949, -13.613],
                                  [224.75, -145.269],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.16, -13.679],
                                  [10.224, -83.668],
                                  [27.427, -68.094],
                                  [-79.92, -40.107],
                                  [-15.605, -45.176],
                                  [-156.069, 22.118],
                                  [65.562, 64.72],
                                  [-16.949, 89.418],
                                  [-16.622, 92],
                                ],
                                o: [
                                  [-85.63, 20.385],
                                  [-10.224, 83.668],
                                  [-27.427, 68.094],
                                  [79.92, 40.107],
                                  [21.832, 52.84],
                                  [107.21, -11.58],
                                  [-65.562, -64.72],
                                  [7.847, -97.561],
                                  [16.622, -92],
                                ],
                                v: [
                                  [-67.366, -321.179],
                                  [-156.62, -198.415],
                                  [-247.364, -4.448],
                                  [-247.262, 153.321],
                                  [-82.273, 266.691],
                                  [82.622, 358.444],
                                  [314.142, 261.316],
                                  [143.008, -13.835],
                                  [225.858, -145.935],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.019, -13.961],
                                  [9.884, -84.053],
                                  [27.667, -67.858],
                                  [-80.347, -40.062],
                                  [-15.623, -45.373],
                                  [-155.745, 21.97],
                                  [66.139, 64.862],
                                  [-17.191, 89.908],
                                  [-16.559, 92],
                                ],
                                o: [
                                  [-85.735, 20.618],
                                  [-9.884, 84.053],
                                  [-27.667, 67.858],
                                  [80.347, 40.062],
                                  [21.804, 52.981],
                                  [107.245, -11.51],
                                  [-66.139, -64.862],
                                  [8.157, -97.991],
                                  [16.559, -92],
                                ],
                                v: [
                                  [-67.871, -321.384],
                                  [-156.022, -197.95],
                                  [-247.737, -4.055],
                                  [-246.936, 153.21],
                                  [-82.293, 266.435],
                                  [82.521, 359.19],
                                  [314.286, 261.469],
                                  [142.041, -14.02],
                                  [227.029, -146.604],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.879, -14.242],
                                  [9.546, -84.436],
                                  [27.905, -67.623],
                                  [-80.772, -40.018],
                                  [-15.64, -45.569],
                                  [-155.422, 21.823],
                                  [66.713, 65.003],
                                  [-17.433, 90.395],
                                  [-16.496, 92],
                                ],
                                o: [
                                  [-85.841, 20.849],
                                  [-9.546, 84.436],
                                  [-27.905, 67.623],
                                  [80.772, 40.018],
                                  [21.776, 53.121],
                                  [107.28, -11.44],
                                  [-66.713, -65.003],
                                  [8.466, -98.418],
                                  [16.496, -92],
                                ],
                                v: [
                                  [-68.376, -321.557],
                                  [-155.455, -197.481],
                                  [-248.107, -3.666],
                                  [-246.551, 153.095],
                                  [-82.388, 266.176],
                                  [82.481, 359.936],
                                  [314.438, 261.568],
                                  [141.08, -14.231],
                                  [228.137, -147.311],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.74, -14.521],
                                  [9.21, -84.817],
                                  [28.142, -67.389],
                                  [-81.195, -39.973],
                                  [-15.658, -45.764],
                                  [-155.101, 21.677],
                                  [67.284, 65.143],
                                  [-17.674, 90.879],
                                  [-16.433, 92],
                                ],
                                o: [
                                  [-85.945, 21.079],
                                  [-9.21, 84.817],
                                  [-28.142, 67.389],
                                  [81.195, 39.973],
                                  [21.748, 53.26],
                                  [107.315, -11.37],
                                  [-67.284, -65.143],
                                  [8.773, -98.844],
                                  [16.433, -92],
                                ],
                                v: [
                                  [-68.848, -321.793],
                                  [-154.853, -197.072],
                                  [-248.48, -3.275],
                                  [-246.202, 153.011],
                                  [-82.494, 265.949],
                                  [82.409, 360.68],
                                  [314.597, 261.708],
                                  [140.155, -14.407],
                                  [229.245, -148.086],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.601, -14.798],
                                  [8.875, -85.196],
                                  [28.378, -67.157],
                                  [-81.616, -39.929],
                                  [-15.675, -45.959],
                                  [-154.782, 21.531],
                                  [67.852, 65.283],
                                  [-17.913, 91.362],
                                  [-16.37, 92],
                                ],
                                o: [
                                  [-86.049, 21.308],
                                  [-8.875, 85.196],
                                  [-28.378, 67.157],
                                  [81.616, 39.929],
                                  [21.72, 53.399],
                                  [107.35, -11.301],
                                  [-67.852, -65.283],
                                  [9.078, -99.267],
                                  [16.37, -92],
                                ],
                                v: [
                                  [-69.287, -321.995],
                                  [-154.311, -196.626],
                                  [-248.882, -2.922],
                                  [-245.886, 152.896],
                                  [-82.612, 265.751],
                                  [82.425, 361.453],
                                  [314.702, 261.829],
                                  [139.235, -14.578],
                                  [230.319, -148.868],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.463, -15.074],
                                  [8.542, -85.573],
                                  [28.613, -66.925],
                                  [-82.035, -39.885],
                                  [-15.692, -46.152],
                                  [-154.464, 21.386],
                                  [68.418, 65.422],
                                  [-18.152, 91.842],
                                  [-16.308, 92],
                                ],
                                o: [
                                  [-86.153, 21.536],
                                  [-8.542, 85.573],
                                  [-28.613, 66.925],
                                  [82.035, 39.885],
                                  [21.693, 53.537],
                                  [107.384, -11.231],
                                  [-68.418, -65.422],
                                  [9.382, -99.688],
                                  [16.308, -92],
                                ],
                                v: [
                                  [-69.725, -322.198],
                                  [-153.739, -196.209],
                                  [-249.255, -2.567],
                                  [-245.509, 152.812],
                                  [-82.804, 265.551],
                                  [82.443, 362.162],
                                  [314.786, 261.961],
                                  [138.321, -14.744],
                                  [231.392, -149.683],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.325, -15.349],
                                  [8.21, -85.949],
                                  [28.847, -66.695],
                                  [-82.451, -39.841],
                                  [-15.709, -46.344],
                                  [-154.148, 21.242],
                                  [68.98, 65.56],
                                  [-18.389, 92.319],
                                  [-16.246, 92],
                                ],
                                o: [
                                  [-86.256, 21.763],
                                  [-8.21, 85.949],
                                  [-28.847, 66.695],
                                  [82.451, 39.841],
                                  [21.665, 53.675],
                                  [107.419, -11.163],
                                  [-68.98, -65.56],
                                  [9.684, -100.108],
                                  [16.246, -92],
                                ],
                                v: [
                                  [-70.1, -322.428],
                                  [-153.257, -195.79],
                                  [-249.658, -2.218],
                                  [-245.165, 152.699],
                                  [-82.945, 265.316],
                                  [82.555, 362.902],
                                  [314.878, 262.07],
                                  [137.441, -14.904],
                                  [232.464, -150.504],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.189, -15.623],
                                  [7.88, -86.322],
                                  [29.079, -66.466],
                                  [-82.866, -39.798],
                                  [-15.726, -46.536],
                                  [-153.834, 21.098],
                                  [69.54, 65.698],
                                  [-18.625, 92.795],
                                  [-16.185, 92],
                                ],
                                o: [
                                  [-86.359, 21.989],
                                  [-7.88, 86.322],
                                  [-29.079, 66.466],
                                  [82.866, 39.798],
                                  [21.638, 53.811],
                                  [107.453, -11.094],
                                  [-69.54, -65.698],
                                  [9.985, -100.525],
                                  [16.185, -92],
                                ],
                                v: [
                                  [-70.504, -322.624],
                                  [-152.773, -195.367],
                                  [-250.094, -1.837],
                                  [-244.857, 152.647],
                                  [-83.16, 265.142],
                                  [82.665, 363.64],
                                  [314.944, 262.189],
                                  [136.567, -15.065],
                                  [233.473, -151.393],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.053, -15.895],
                                  [7.552, -86.694],
                                  [29.311, -66.238],
                                  [-83.278, -39.754],
                                  [-15.743, -46.726],
                                  [-153.521, 20.955],
                                  [70.097, 65.835],
                                  [-18.859, 93.267],
                                  [-16.124, 92],
                                ],
                                o: [
                                  [-86.461, 22.213],
                                  [-7.552, 86.694],
                                  [-29.311, 66.238],
                                  [83.278, 39.754],
                                  [21.611, 53.947],
                                  [107.487, -11.026],
                                  [-70.097, -65.835],
                                  [10.284, -100.94],
                                  [16.124, -92],
                                ],
                                v: [
                                  [-70.844, -322.854],
                                  [-152.286, -194.976],
                                  [-250.496, -1.493],
                                  [-244.518, 152.566],
                                  [-83.324, 264.966],
                                  [82.808, 364.348],
                                  [314.99, 262.318],
                                  [135.697, -15.221],
                                  [234.481, -152.315],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.917, -16.166],
                                  [7.225, -87.064],
                                  [29.541, -66.011],
                                  [-83.689, -39.711],
                                  [-15.76, -46.916],
                                  [-153.21, 20.813],
                                  [70.652, 65.971],
                                  [-19.093, 93.738],
                                  [-16.063, 92],
                                ],
                                o: [
                                  [-86.562, 22.437],
                                  [-7.225, 87.064],
                                  [-29.541, 66.011],
                                  [83.689, 39.711],
                                  [21.583, 54.083],
                                  [107.521, -10.959],
                                  [-70.652, -65.971],
                                  [10.582, -101.353],
                                  [16.063, -92],
                                ],
                                v: [
                                  [-71.12, -323.076],
                                  [-151.798, -194.547],
                                  [-250.933, -1.184],
                                  [-244.209, 152.456],
                                  [-83.562, 264.817],
                                  [82.983, 365.089],
                                  [315.044, 262.423],
                                  [134.861, -15.379],
                                  [235.488, -153.241],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.782, -16.435],
                                  [6.9, -87.432],
                                  [29.77, -65.786],
                                  [-84.097, -39.668],
                                  [-15.777, -47.105],
                                  [-152.9, 20.672],
                                  [71.203, 66.106],
                                  [-19.325, 94.206],
                                  [-16.002, 92],
                                ],
                                o: [
                                  [-86.663, 22.659],
                                  [-6.9, 87.432],
                                  [-29.77, 65.786],
                                  [84.097, 39.668],
                                  [21.557, 54.218],
                                  [107.554, -10.891],
                                  [-71.203, -66.106],
                                  [10.879, -101.764],
                                  [16.002, -92],
                                ],
                                v: [
                                  [-71.423, -323.324],
                                  [-151.371, -194.177],
                                  [-251.336, -0.913],
                                  [-243.902, 152.416],
                                  [-83.811, 264.659],
                                  [83.22, 365.793],
                                  [315.043, 262.566],
                                  [134.03, -15.562],
                                  [236.432, -154.2],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.648, -16.703],
                                  [6.577, -87.798],
                                  [29.998, -65.561],
                                  [-84.503, -39.625],
                                  [-15.794, -47.292],
                                  [-152.591, 20.531],
                                  [71.752, 66.241],
                                  [-19.556, 94.672],
                                  [-15.942, 92],
                                ],
                                o: [
                                  [-86.764, 22.88],
                                  [-6.577, 87.798],
                                  [-29.998, 65.561],
                                  [84.503, 39.626],
                                  [21.53, 54.352],
                                  [107.588, -10.824],
                                  [-71.752, -66.241],
                                  [11.173, -102.172],
                                  [15.942, -92],
                                ],
                                v: [
                                  [-71.663, -323.571],
                                  [-151.004, -193.812],
                                  [-251.738, -0.645],
                                  [-243.562, 152.342],
                                  [-84.134, 264.533],
                                  [83.49, 366.504],
                                  [315.05, 262.718],
                                  [133.196, -15.712],
                                  [237.402, -155.225],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.515, -16.97],
                                  [6.255, -88.162],
                                  [30.224, -65.338],
                                  [-84.907, -39.583],
                                  [-15.811, -47.479],
                                  [-152.285, 20.391],
                                  [72.298, 66.375],
                                  [-19.787, 95.135],
                                  [-15.882, 92],
                                ],
                                o: [
                                  [-86.864, 23.1],
                                  [-6.255, 88.162],
                                  [-30.224, 65.338],
                                  [84.907, 39.583],
                                  [21.503, 54.485],
                                  [107.621, -10.758],
                                  [-72.298, -66.375],
                                  [11.467, -102.579],
                                  [15.882, -92],
                                ],
                                v: [
                                  [-71.928, -323.78],
                                  [-150.608, -193.437],
                                  [-252.14, -0.414],
                                  [-243.259, 152.338],
                                  [-84.406, 264.404],
                                  [83.768, 367.186],
                                  [315.038, 262.816],
                                  [132.402, -15.86],
                                  [238.309, -156.247],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.382, -17.235],
                                  [5.935, -88.524],
                                  [30.45, -65.116],
                                  [-85.31, -39.541],
                                  [-15.827, -47.665],
                                  [-151.98, 20.251],
                                  [72.841, 66.509],
                                  [-20.015, 95.596],
                                  [-15.822, 92],
                                ],
                                o: [
                                  [-86.963, 23.319],
                                  [-5.935, 88.524],
                                  [-30.45, 65.116],
                                  [85.31, 39.541],
                                  [21.477, 54.618],
                                  [107.654, -10.691],
                                  [-72.841, -66.509],
                                  [11.759, -102.984],
                                  [15.822, -92],
                                ],
                                v: [
                                  [-72.13, -324.014],
                                  [-150.301, -193.094],
                                  [-252.542, -0.16],
                                  [-243.012, 152.308],
                                  [-84.689, 264.3],
                                  [84.136, 367.869],
                                  [314.998, 262.95],
                                  [131.638, -16.039],
                                  [239.214, -157.307],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.25, -17.499],
                                  [5.617, -88.885],
                                  [30.674, -64.894],
                                  [-85.71, -39.499],
                                  [-15.844, -47.849],
                                  [-151.676, 20.113],
                                  [73.381, 66.642],
                                  [-20.243, 96.055],
                                  [-15.763, 92],
                                ],
                                o: [
                                  [-87.062, 23.537],
                                  [-5.617, 88.885],
                                  [-30.674, 64.894],
                                  [85.71, 39.499],
                                  [21.45, 54.75],
                                  [107.687, -10.625],
                                  [-73.381, -66.642],
                                  [12.049, -103.386],
                                  [15.763, -92],
                                ],
                                v: [
                                  [-72.267, -324.281],
                                  [-149.965, -192.721],
                                  [-252.944, 0.028],
                                  [-242.74, 152.316],
                                  [-85.01, 264.221],
                                  [84.547, 368.523],
                                  [314.94, 263.12],
                                  [130.879, -16.215],
                                  [240.091, -158.371],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.119, -17.762],
                                  [5.3, -89.244],
                                  [30.898, -64.674],
                                  [-86.108, -39.457],
                                  [-15.86, -48.033],
                                  [-151.374, 19.975],
                                  [73.919, 66.774],
                                  [-20.47, 96.511],
                                  [-15.704, 92],
                                ],
                                o: [
                                  [-87.161, 23.754],
                                  [-5.3, 89.244],
                                  [-30.898, 64.674],
                                  [86.108, 39.457],
                                  [21.424, 54.881],
                                  [107.72, -10.56],
                                  [-73.919, -66.774],
                                  [12.338, -103.787],
                                  [15.704, -92],
                                ],
                                v: [
                                  [-72.429, -324.537],
                                  [-149.689, -192.409],
                                  [-253.346, 0.176],
                                  [-242.46, 152.325],
                                  [-85.341, 264.165],
                                  [84.96, 369.178],
                                  [314.853, 263.272],
                                  [130.159, -16.387],
                                  [240.931, -159.491],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.988, -18.023],
                                  [4.984, -89.601],
                                  [31.12, -64.456],
                                  [-86.504, -39.415],
                                  [-15.876, -48.216],
                                  [-151.073, 19.838],
                                  [74.454, 66.905],
                                  [-20.695, 96.965],
                                  [-15.645, 92],
                                ],
                                o: [
                                  [-87.259, 23.969],
                                  [-4.984, 89.601],
                                  [-31.12, 64.456],
                                  [86.504, 39.415],
                                  [21.398, 55.012],
                                  [107.753, -10.494],
                                  [-74.454, -66.905],
                                  [12.626, -104.186],
                                  [15.645, -92],
                                ],
                                v: [
                                  [-72.552, -324.781],
                                  [-149.475, -192.121],
                                  [-253.712, 0.347],
                                  [-242.216, 152.346],
                                  [-85.656, 264.105],
                                  [85.4, 369.879],
                                  [314.748, 263.422],
                                  [129.435, -16.567],
                                  [241.77, -160.651],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.858, -18.283],
                                  [4.671, -89.956],
                                  [31.341, -64.238],
                                  [-86.898, -39.374],
                                  [-15.893, -48.398],
                                  [-150.774, 19.701],
                                  [74.986, 67.036],
                                  [-20.919, 97.417],
                                  [-15.586, 92],
                                ],
                                o: [
                                  [-87.356, 24.184],
                                  [-4.671, 89.956],
                                  [-31.341, 64.238],
                                  [86.898, 39.374],
                                  [21.372, 55.142],
                                  [107.785, -10.429],
                                  [-74.986, -67.036],
                                  [12.912, -104.582],
                                  [15.586, -92],
                                ],
                                v: [
                                  [-72.611, -325.056],
                                  [-149.26, -191.805],
                                  [-254.077, 0.477],
                                  [-242.027, 152.369],
                                  [-86.009, 264.068],
                                  [85.887, 370.59],
                                  [314.651, 263.606],
                                  [128.749, -16.77],
                                  [242.571, -161.803],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.729, -18.542],
                                  [4.359, -90.309],
                                  [31.561, -64.021],
                                  [-87.29, -39.332],
                                  [-15.909, -48.579],
                                  [-150.477, 19.565],
                                  [75.516, 67.166],
                                  [-21.142, 97.867],
                                  [-15.528, 92],
                                ],
                                o: [
                                  [-87.453, 24.397],
                                  [-4.359, 90.309],
                                  [-31.561, 64.021],
                                  [87.29, 39.332],
                                  [21.346, 55.271],
                                  [107.818, -10.365],
                                  [-75.516, -67.166],
                                  [13.196, -104.976],
                                  [15.528, -92],
                                ],
                                v: [
                                  [-72.703, -325.292],
                                  [-149.079, -191.486],
                                  [-254.442, 0.577],
                                  [-241.811, 152.395],
                                  [-86.372, 264.028],
                                  [86.375, 371.304],
                                  [314.526, 263.735],
                                  [128.068, -16.944],
                                  [243.344, -162.984],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.6, -18.799],
                                  [4.048, -90.66],
                                  [31.779, -63.806],
                                  [-87.68, -39.291],
                                  [-15.925, -48.76],
                                  [-150.181, 19.43],
                                  [76.042, 67.296],
                                  [-21.364, 98.314],
                                  [-15.47, 92],
                                ],
                                o: [
                                  [-87.55, 24.609],
                                  [-4.048, 90.66],
                                  [-31.779, 63.806],
                                  [87.68, 39.291],
                                  [21.32, 55.4],
                                  [107.85, -10.3],
                                  [-76.042, -67.296],
                                  [13.479, -105.369],
                                  [15.47, -92],
                                ],
                                v: [
                                  [-72.721, -325.612],
                                  [-148.924, -191.228],
                                  [-254.806, 0.661],
                                  [-241.633, 152.424],
                                  [-86.708, 264.072],
                                  [86.902, 372.03],
                                  [314.382, 263.959],
                                  [127.391, -17.153],
                                  [244.116, -164.203],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.472, -19.055],
                                  [3.739, -91.01],
                                  [31.997, -63.591],
                                  [-88.068, -39.251],
                                  [-15.941, -48.939],
                                  [-149.886, 19.296],
                                  [76.566, 67.425],
                                  [-21.585, 98.759],
                                  [-15.413, 92],
                                ],
                                o: [
                                  [-87.646, 24.821],
                                  [-3.739, 91.01],
                                  [-31.997, 63.591],
                                  [88.068, 39.251],
                                  [21.294, 55.528],
                                  [107.882, -10.236],
                                  [-76.566, -67.425],
                                  [13.761, -105.759],
                                  [15.413, -92],
                                ],
                                v: [
                                  [-72.735, -325.891],
                                  [-148.804, -191.004],
                                  [-255.17, 0.74],
                                  [-241.492, 152.43],
                                  [-87.055, 264.074],
                                  [87.406, 372.744],
                                  [314.208, 264.127],
                                  [126.78, -17.349],
                                  [244.85, -165.413],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.345, -19.31],
                                  [3.432, -91.358],
                                  [32.214, -63.378],
                                  [-88.454, -39.21],
                                  [-15.957, -49.117],
                                  [-149.593, 19.162],
                                  [77.088, 67.553],
                                  [-21.805, 99.201],
                                  [-15.355, 92],
                                ],
                                o: [
                                  [-87.741, 25.031],
                                  [-3.432, 91.358],
                                  [-32.214, 63.378],
                                  [88.454, 39.21],
                                  [21.269, 55.655],
                                  [107.914, -10.172],
                                  [-77.088, -67.553],
                                  [14.041, -106.148],
                                  [15.355, -92],
                                ],
                                v: [
                                  [-72.746, -326.166],
                                  [-148.708, -190.753],
                                  [-255.496, 0.814],
                                  [-241.328, 152.439],
                                  [-87.448, 264.135],
                                  [87.975, 373.488],
                                  [314.017, 264.29],
                                  [126.135, -17.553],
                                  [245.545, -166.687],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.218, -19.563],
                                  [3.127, -91.704],
                                  [32.429, -63.166],
                                  [-88.838, -39.17],
                                  [-15.973, -49.294],
                                  [-149.302, 19.029],
                                  [77.606, 67.68],
                                  [-22.023, 99.642],
                                  [-15.298, 92],
                                ],
                                o: [
                                  [-87.836, 25.24],
                                  [-3.127, 91.704],
                                  [-32.429, 63.166],
                                  [88.838, 39.17],
                                  [21.244, 55.782],
                                  [107.945, -10.109],
                                  [-77.606, -67.68],
                                  [14.32, -106.534],
                                  [15.298, -92],
                                ],
                                v: [
                                  [-72.692, -326.523],
                                  [-148.6, -190.539],
                                  [-255.822, 0.846],
                                  [-241.228, 152.451],
                                  [-87.776, 264.191],
                                  [88.558, 374.185],
                                  [313.834, 264.484],
                                  [125.532, -17.756],
                                  [246.25, -167.925],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.092, -19.816],
                                  [2.823, -92.048],
                                  [32.643, -62.954],
                                  [-89.22, -39.129],
                                  [-15.988, -49.471],
                                  [-149.012, 18.897],
                                  [78.122, 67.807],
                                  [-22.241, 100.079],
                                  [-15.242, 92],
                                ],
                                o: [
                                  [-87.931, 25.448],
                                  [-2.822, 92.048],
                                  [-32.643, 62.954],
                                  [89.22, 39.129],
                                  [21.218, 55.908],
                                  [107.977, -10.046],
                                  [-78.122, -67.807],
                                  [14.597, -106.919],
                                  [15.242, -92],
                                ],
                                v: [
                                  [-72.66, -326.839],
                                  [-148.526, -190.323],
                                  [-256.147, 0.835],
                                  [-241.141, 152.428],
                                  [-88.152, 264.268],
                                  [89.144, 374.961],
                                  [313.621, 264.684],
                                  [124.932, -17.994],
                                  [246.904, -169.202],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.967, -20.066],
                                  [2.52, -92.391],
                                  [32.856, -62.744],
                                  [-89.601, -39.089],
                                  [-16.004, -49.646],
                                  [-148.724, 18.765],
                                  [78.636, 67.933],
                                  [-22.457, 100.515],
                                  [-15.185, 92],
                                ],
                                o: [
                                  [-88.025, 25.655],
                                  [-2.52, 92.391],
                                  [-32.856, 62.744],
                                  [89.601, 39.089],
                                  [21.193, 56.033],
                                  [108.008, -9.983],
                                  [-78.636, -67.933],
                                  [14.873, -107.301],
                                  [15.185, -92],
                                ],
                                v: [
                                  [-72.562, -327.149],
                                  [-148.464, -190.144],
                                  [-256.471, 0.855],
                                  [-241.058, 152.421],
                                  [-88.499, 264.389],
                                  [89.734, 375.679],
                                  [313.39, 264.888],
                                  [124.361, -18.23],
                                  [247.567, -170.417],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.842, -20.316],
                                  [2.219, -92.731],
                                  [33.068, -62.536],
                                  [-89.979, -39.05],
                                  [-16.02, -49.821],
                                  [-148.437, 18.634],
                                  [79.146, 68.059],
                                  [-22.672, 100.949],
                                  [-15.129, 92],
                                ],
                                o: [
                                  [-88.118, 25.861],
                                  [-2.219, 92.731],
                                  [-33.068, 62.535],
                                  [89.979, 39.05],
                                  [21.168, 56.158],
                                  [108.039, -9.921],
                                  [-79.146, -68.059],
                                  [15.147, -107.682],
                                  [15.129, -92],
                                ],
                                v: [
                                  [-72.461, -327.503],
                                  [-148.461, -189.941],
                                  [-256.769, 0.832],
                                  [-241.015, 152.404],
                                  [-88.856, 264.505],
                                  [90.364, 376.416],
                                  [313.154, 265.06],
                                  [123.831, -18.465],
                                  [248.216, -171.633],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.718, -20.564],
                                  [1.92, -93.07],
                                  [33.279, -62.328],
                                  [-90.355, -39.01],
                                  [-16.035, -49.995],
                                  [-148.151, 18.504],
                                  [79.654, 68.184],
                                  [-22.886, 101.38],
                                  [-15.073, 92],
                                ],
                                o: [
                                  [-88.211, 26.065],
                                  [-1.92, 93.07],
                                  [-33.279, 62.328],
                                  [90.355, 39.01],
                                  [21.144, 56.282],
                                  [108.07, -9.859],
                                  [-79.654, -68.184],
                                  [15.42, -108.06],
                                  [15.073, -92],
                                ],
                                v: [
                                  [-72.356, -327.851],
                                  [-148.417, -189.775],
                                  [-257.053, 0.766],
                                  [-240.974, 152.403],
                                  [-89.184, 264.639],
                                  [90.974, 377.158],
                                  [312.925, 265.261],
                                  [123.267, -18.699],
                                  [248.824, -172.889],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.595, -20.811],
                                  [1.622, -93.407],
                                  [33.489, -62.121],
                                  [-90.729, -38.971],
                                  [-16.051, -50.168],
                                  [-147.868, 18.374],
                                  [80.16, 68.308],
                                  [-23.099, 101.809],
                                  [-15.018, 92],
                                ],
                                o: [
                                  [-88.304, 26.269],
                                  [-1.622, 93.407],
                                  [-33.489, 62.121],
                                  [90.729, 38.971],
                                  [21.119, 56.405],
                                  [108.101, -9.797],
                                  [-80.16, -68.308],
                                  [15.692, -108.436],
                                  [15.018, -92],
                                ],
                                v: [
                                  [-72.21, -328.218],
                                  [-148.457, -189.647],
                                  [-257.312, 0.756],
                                  [-240.951, 152.367],
                                  [-89.544, 264.806],
                                  [91.626, 377.895],
                                  [312.702, 265.442],
                                  [122.745, -18.994],
                                  [249.43, -174.083],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.472, -21.056],
                                  [1.326, -93.743],
                                  [33.698, -61.915],
                                  [-91.101, -38.932],
                                  [-16.066, -50.339],
                                  [-147.585, 18.245],
                                  [80.662, 68.431],
                                  [-23.311, 102.235],
                                  [-14.962, 92],
                                ],
                                o: [
                                  [-88.396, 26.472],
                                  [-1.326, 93.743],
                                  [-33.698, 61.915],
                                  [91.101, 38.932],
                                  [21.094, 56.528],
                                  [108.132, -9.736],
                                  [-80.662, -68.432],
                                  [15.962, -108.811],
                                  [14.962, -92],
                                ],
                                v: [
                                  [-72.036, -328.578],
                                  [-148.493, -189.497],
                                  [-257.594, 0.677],
                                  [-240.955, 152.373],
                                  [-89.889, 264.992],
                                  [92.283, 378.664],
                                  [312.472, 265.614],
                                  [122.227, -19.262],
                                  [250.056, -175.279],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.35, -21.301],
                                  [1.031, -94.076],
                                  [33.906, -61.711],
                                  [-91.471, -38.893],
                                  [-16.081, -50.51],
                                  [-147.304, 18.117],
                                  [81.162, 68.554],
                                  [-23.522, 102.66],
                                  [-14.907, 92],
                                ],
                                o: [
                                  [-88.488, 26.673],
                                  [-1.031, 94.076],
                                  [-33.906, 61.711],
                                  [91.471, 38.893],
                                  [21.07, 56.65],
                                  [108.163, -9.675],
                                  [-81.162, -68.554],
                                  [16.231, -109.184],
                                  [14.907, -92],
                                ],
                                v: [
                                  [-71.883, -328.98],
                                  [-148.55, -189.371],
                                  [-257.851, 0.592],
                                  [-240.977, 152.343],
                                  [-90.204, 265.195],
                                  [92.958, 379.391],
                                  [312.247, 265.765],
                                  [121.75, -19.53],
                                  [250.617, -176.477],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.228, -21.544],
                                  [0.738, -94.408],
                                  [34.112, -61.507],
                                  [-91.84, -38.854],
                                  [-16.096, -50.681],
                                  [-147.025, 17.99],
                                  [81.66, 68.677],
                                  [-23.731, 103.082],
                                  [-14.853, 92],
                                ],
                                o: [
                                  [-88.579, 26.874],
                                  [-0.738, 94.408],
                                  [-34.112, 61.507],
                                  [91.84, 38.854],
                                  [21.046, 56.772],
                                  [108.193, -9.614],
                                  [-81.66, -68.677],
                                  [16.498, -109.554],
                                  [14.853, -92],
                                ],
                                v: [
                                  [-71.726, -329.413],
                                  [-148.665, -189.238],
                                  [-258.069, 0.525],
                                  [-241.042, 152.331],
                                  [-90.488, 265.392],
                                  [93.623, 380.124],
                                  [312.029, 265.944],
                                  [121.263, -19.859],
                                  [251.198, -177.613],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.107, -21.785],
                                  [0.446, -94.738],
                                  [34.318, -61.305],
                                  [-92.206, -38.815],
                                  [-16.112, -50.85],
                                  [-146.747, 17.863],
                                  [82.154, 68.798],
                                  [-23.94, 103.502],
                                  [-14.798, 92],
                                ],
                                o: [
                                  [-88.669, 27.073],
                                  [-0.446, 94.738],
                                  [-34.318, 61.305],
                                  [92.206, 38.815],
                                  [21.021, 56.893],
                                  [108.223, -9.554],
                                  [-82.154, -68.798],
                                  [16.764, -109.923],
                                  [14.798, -92],
                                ],
                                v: [
                                  [-71.503, -329.825],
                                  [-148.8, -189.169],
                                  [-258.262, 0.428],
                                  [-241.111, 152.307],
                                  [-90.803, 265.583],
                                  [94.308, 380.842],
                                  [311.777, 266.088],
                                  [120.795, -20.125],
                                  [251.736, -178.79],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.987, -22.026],
                                  [0.156, -95.066],
                                  [34.522, -61.103],
                                  [-92.57, -38.777],
                                  [-16.127, -51.018],
                                  [-146.47, 17.736],
                                  [82.647, 68.919],
                                  [-24.147, 103.92],
                                  [-14.744, 92],
                                ],
                                o: [
                                  [-88.76, 27.271],
                                  [-0.156, 95.066],
                                  [-34.522, 61.103],
                                  [92.57, 38.777],
                                  [20.997, 57.013],
                                  [108.253, -9.494],
                                  [-82.647, -68.919],
                                  [17.028, -110.289],
                                  [14.744, -92],
                                ],
                                v: [
                                  [-71.277, -330.229],
                                  [-148.931, -189.078],
                                  [-258.454, 0.286],
                                  [-241.223, 152.325],
                                  [-91.102, 265.83],
                                  [94.998, 381.566],
                                  [311.554, 266.236],
                                  [120.354, -20.491],
                                  [252.27, -179.93],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.868, -22.265],
                                  [-0.132, -95.393],
                                  [34.725, -60.903],
                                  [-92.933, -38.739],
                                  [-16.142, -51.185],
                                  [-146.195, 17.611],
                                  [83.136, 69.04],
                                  [-24.353, 104.335],
                                  [-14.69, 92],
                                ],
                                o: [
                                  [-88.849, 27.469],
                                  [0.132, 95.393],
                                  [-34.725, 60.903],
                                  [92.933, 38.739],
                                  [20.974, 57.132],
                                  [108.283, -9.434],
                                  [-83.136, -69.04],
                                  [17.291, -110.654],
                                  [14.69, -92],
                                ],
                                v: [
                                  [-71.07, -330.665],
                                  [-149.104, -189.052],
                                  [-258.606, 0.177],
                                  [-241.341, 152.346],
                                  [-91.393, 266.056],
                                  [95.67, 382.275],
                                  [311.359, 266.41],
                                  [119.917, -20.817],
                                  [252.761, -181.071],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.749, -22.503],
                                  [-0.419, -95.718],
                                  [34.927, -60.704],
                                  [-93.293, -38.701],
                                  [-16.156, -51.352],
                                  [-145.922, 17.486],
                                  [83.623, 69.159],
                                  [-24.559, 104.749],
                                  [-14.637, 92],
                                ],
                                o: [
                                  [-88.939, 27.665],
                                  [0.419, 95.718],
                                  [-34.927, 60.704],
                                  [93.293, 38.701],
                                  [20.95, 57.251],
                                  [108.313, -9.374],
                                  [-83.623, -69.159],
                                  [17.553, -111.017],
                                  [14.637, -92],
                                ],
                                v: [
                                  [-70.819, -331.094],
                                  [-149.311, -189.006],
                                  [-258.796, 0.062],
                                  [-241.502, 152.308],
                                  [-91.627, 266.313],
                                  [96.387, 382.968],
                                  [311.107, 266.563],
                                  [119.5, -21.181],
                                  [253.31, -182.213],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.63, -22.739],
                                  [-0.704, -96.041],
                                  [35.128, -60.506],
                                  [-93.652, -38.663],
                                  [-16.171, -51.518],
                                  [-145.65, 17.362],
                                  [84.107, 69.278],
                                  [-24.763, 105.16],
                                  [-14.584, 92],
                                ],
                                o: [
                                  [-89.027, 27.86],
                                  [0.704, 96.041],
                                  [-35.128, 60.506],
                                  [93.652, 38.663],
                                  [20.926, 57.37],
                                  [108.342, -9.315],
                                  [-84.107, -69.278],
                                  [17.813, -111.378],
                                  [14.584, -92],
                                ],
                                v: [
                                  [-70.604, -331.477],
                                  [-149.537, -188.979],
                                  [-258.923, -0.098],
                                  [-241.668, 152.335],
                                  [-91.852, 266.564],
                                  [97.109, 383.669],
                                  [310.881, 266.703],
                                  [119.111, -21.544],
                                  [253.752, -183.334],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.513, -22.975],
                                  [-0.988, -96.362],
                                  [35.329, -60.309],
                                  [-94.009, -38.626],
                                  [-16.186, -51.682],
                                  [-145.379, 17.238],
                                  [84.589, 69.397],
                                  [-24.966, 105.569],
                                  [-14.531, 92],
                                ],
                                o: [
                                  [-89.116, 28.054],
                                  [0.988, 96.362],
                                  [-35.328, 60.309],
                                  [94.009, 38.626],
                                  [20.903, 57.487],
                                  [108.372, -9.256],
                                  [-84.589, -69.397],
                                  [18.072, -111.736],
                                  [14.531, -92],
                                ],
                                v: [
                                  [-70.345, -331.915],
                                  [-149.781, -188.977],
                                  [-259.01, -0.248],
                                  [-241.879, 152.366],
                                  [-92.06, 266.849],
                                  [97.797, 384.356],
                                  [310.66, 266.869],
                                  [118.725, -21.907],
                                  [254.213, -184.457],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.396, -23.209],
                                  [-1.271, -96.682],
                                  [35.527, -60.113],
                                  [-94.363, -38.589],
                                  [-16.201, -51.846],
                                  [-145.11, 17.115],
                                  [85.068, 69.514],
                                  [-25.168, 105.975],
                                  [-14.478, 92],
                                ],
                                o: [
                                  [-89.203, 28.247],
                                  [1.271, 96.682],
                                  [-35.527, 60.113],
                                  [94.363, 38.589],
                                  [20.879, 57.604],
                                  [108.401, -9.198],
                                  [-85.068, -69.514],
                                  [18.33, -112.093],
                                  [14.478, -92],
                                ],
                                v: [
                                  [-70.122, -332.364],
                                  [-150.06, -188.957],
                                  [-259.112, -0.403],
                                  [-242.095, 152.337],
                                  [-92.297, 267.111],
                                  [98.507, 385.01],
                                  [310.425, 267.014],
                                  [118.344, -22.331],
                                  [254.69, -185.519],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.279, -23.442],
                                  [-1.551, -97],
                                  [35.725, -59.918],
                                  [-94.716, -38.552],
                                  [-16.215, -52.009],
                                  [-144.842, 16.993],
                                  [85.544, 69.632],
                                  [-25.368, 106.38],
                                  [-14.426, 92],
                                ],
                                o: [
                                  [-89.291, 28.439],
                                  [1.551, 97],
                                  [-35.725, 59.918],
                                  [94.716, 38.552],
                                  [20.856, 57.721],
                                  [108.43, -9.14],
                                  [-85.544, -69.631],
                                  [18.586, -112.448],
                                  [14.426, -92],
                                ],
                                v: [
                                  [-69.877, -332.766],
                                  [-150.356, -188.98],
                                  [-259.175, -0.541],
                                  [-242.316, 152.373],
                                  [-92.437, 267.428],
                                  [99.224, 385.69],
                                  [310.193, 267.143],
                                  [117.967, -22.772],
                                  [255.14, -186.605],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.163, -23.673],
                                  [-1.831, -97.316],
                                  [35.922, -59.724],
                                  [-95.067, -38.515],
                                  [-16.23, -52.171],
                                  [-144.576, 16.872],
                                  [86.018, 69.748],
                                  [-25.568, 106.782],
                                  [-14.374, 92],
                                ],
                                o: [
                                  [-89.377, 28.63],
                                  [1.831, 97.316],
                                  [-35.922, 59.724],
                                  [95.067, 38.515],
                                  [20.833, 57.837],
                                  [108.459, -9.082],
                                  [-86.018, -69.748],
                                  [18.84, -112.801],
                                  [14.374, -92],
                                ],
                                v: [
                                  [-69.628, -333.201],
                                  [-150.648, -189.007],
                                  [-259.236, -0.747],
                                  [-242.601, 152.413],
                                  [-92.605, 267.716],
                                  [99.946, 386.338],
                                  [309.965, 267.274],
                                  [117.634, -23.195],
                                  [255.567, -187.629],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.048, -23.903],
                                  [-2.108, -97.631],
                                  [36.118, -59.531],
                                  [-95.416, -38.478],
                                  [-16.244, -52.332],
                                  [-144.311, 16.751],
                                  [86.49, 69.864],
                                  [-25.767, 107.182],
                                  [-14.322, 92],
                                ],
                                o: [
                                  [-89.464, 28.82],
                                  [2.109, 97.631],
                                  [-36.118, 59.531],
                                  [95.416, 38.478],
                                  [20.81, 57.952],
                                  [108.488, -9.024],
                                  [-86.49, -69.864],
                                  [19.094, -113.153],
                                  [14.322, -92],
                                ],
                                v: [
                                  [-69.414, -333.612],
                                  [-151.018, -189.056],
                                  [-259.297, -0.9],
                                  [-242.873, 152.455],
                                  [-92.715, 268.02],
                                  [100.652, 386.972],
                                  [309.721, 267.429],
                                  [117.306, -23.68],
                                  [255.947, -188.696],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.934, -24.132],
                                  [-2.385, -97.943],
                                  [36.313, -59.339],
                                  [-95.763, -38.441],
                                  [-16.258, -52.493],
                                  [-144.048, 16.63],
                                  [86.959, 69.979],
                                  [-25.964, 107.58],
                                  [-14.27, 92],
                                ],
                                o: [
                                  [-89.55, 29.009],
                                  [2.385, 97.943],
                                  [-36.313, 59.339],
                                  [95.763, 38.441],
                                  [20.787, 58.066],
                                  [108.517, -8.967],
                                  [-86.959, -69.979],
                                  [19.346, -113.502],
                                  [14.27, -92],
                                ],
                                v: [
                                  [-69.155, -334.035],
                                  [-151.424, -189.131],
                                  [-259.295, -1.076],
                                  [-243.192, 152.46],
                                  [-92.852, 268.359],
                                  [101.364, 387.573],
                                  [309.521, 267.585],
                                  [116.96, -24.182],
                                  [256.362, -189.723],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.82, -24.36],
                                  [-2.66, -98.254],
                                  [36.506, -59.148],
                                  [-96.108, -38.405],
                                  [-16.272, -52.652],
                                  [-143.786, 16.511],
                                  [87.425, 70.094],
                                  [-26.161, 107.976],
                                  [-14.219, 92],
                                ],
                                o: [
                                  [-89.635, 29.197],
                                  [2.66, 98.254],
                                  [-36.506, 59.148],
                                  [96.108, 38.405],
                                  [20.764, 58.18],
                                  [108.545, -8.91],
                                  [-87.425, -70.094],
                                  [19.596, -113.849],
                                  [14.219, -92],
                                ],
                                v: [
                                  [-68.955, -334.493],
                                  [-151.825, -189.188],
                                  [-259.314, -1.279],
                                  [-243.557, 152.53],
                                  [-92.953, 268.691],
                                  [102.083, 388.201],
                                  [309.304, 267.763],
                                  [116.641, -24.706],
                                  [256.689, -190.73],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.707, -24.587],
                                  [-2.933, -98.564],
                                  [36.699, -58.959],
                                  [-96.452, -38.369],
                                  [-16.287, -52.811],
                                  [-143.525, 16.392],
                                  [87.889, 70.208],
                                  [-26.356, 108.37],
                                  [-14.168, 92],
                                ],
                                o: [
                                  [-89.72, 29.384],
                                  [2.933, 98.564],
                                  [-36.699, 58.959],
                                  [96.452, 38.369],
                                  [20.741, 58.293],
                                  [108.573, -8.853],
                                  [-87.888, -70.208],
                                  [19.845, -114.195],
                                  [14.168, -92],
                                ],
                                v: [
                                  [-68.709, -334.903],
                                  [-152.243, -189.291],
                                  [-259.288, -1.425],
                                  [-243.928, 152.581],
                                  [-92.954, 269.018],
                                  [102.786, 388.797],
                                  [309.048, 267.902],
                                  [116.367, -25.23],
                                  [257.072, -191.697],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.594, -24.812],
                                  [-3.205, -98.871],
                                  [36.89, -58.77],
                                  [-96.793, -38.333],
                                  [-16.301, -52.969],
                                  [-143.266, 16.274],
                                  [88.35, 70.321],
                                  [-26.55, 108.761],
                                  [-14.117, 92],
                                ],
                                o: [
                                  [-89.805, 29.57],
                                  [3.205, 98.871],
                                  [-36.89, 58.77],
                                  [96.793, 38.333],
                                  [20.719, 58.406],
                                  [108.602, -8.797],
                                  [-88.35, -70.321],
                                  [20.093, -114.538],
                                  [14.117, -92],
                                ],
                                v: [
                                  [-68.499, -335.37],
                                  [-152.718, -189.398],
                                  [-259.284, -1.597],
                                  [-244.284, 152.656],
                                  [-92.98, 269.359],
                                  [103.453, 389.359],
                                  [308.836, 268.062],
                                  [116.076, -25.794],
                                  [257.408, -192.666],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.482, -25.036],
                                  [-3.475, -99.177],
                                  [37.081, -58.582],
                                  [-97.133, -38.297],
                                  [-16.315, -53.125],
                                  [-143.008, 16.156],
                                  [88.808, 70.434],
                                  [-26.744, 109.15],
                                  [-14.067, 92],
                                ],
                                o: [
                                  [-89.889, 29.755],
                                  [3.475, 99.177],
                                  [-37.081, 58.582],
                                  [97.133, 38.297],
                                  [20.696, 58.518],
                                  [108.63, -8.741],
                                  [-88.808, -70.434],
                                  [20.34, -114.88],
                                  [14.067, -92],
                                ],
                                v: [
                                  [-68.306, -335.852],
                                  [-153.187, -189.489],
                                  [-259.237, -1.731],
                                  [-244.709, 152.692],
                                  [-92.99, 269.715],
                                  [104.169, 389.908],
                                  [308.605, 268.223],
                                  [115.812, -26.379],
                                  [257.737, -193.594],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.371, -25.259],
                                  [-3.744, -99.482],
                                  [37.27, -58.396],
                                  [-97.471, -38.262],
                                  [-16.329, -53.281],
                                  [-142.752, 16.039],
                                  [89.264, 70.546],
                                  [-26.936, 109.537],
                                  [-14.017, 92],
                                ],
                                o: [
                                  [-89.972, 29.939],
                                  [3.744, 99.482],
                                  [-37.27, 58.396],
                                  [97.471, 38.262],
                                  [20.674, 58.63],
                                  [108.657, -8.685],
                                  [-89.264, -70.546],
                                  [20.585, -115.22],
                                  [14.017, -92],
                                ],
                                v: [
                                  [-68.108, -336.307],
                                  [-153.693, -189.606],
                                  [-259.169, -1.933],
                                  [-245.14, 152.771],
                                  [-92.94, 270.044],
                                  [104.89, 390.423],
                                  [308.375, 268.384],
                                  [115.573, -27.006],
                                  [258.039, -194.564],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.26, -25.481],
                                  [-4.011, -99.784],
                                  [37.458, -58.21],
                                  [-97.806, -38.227],
                                  [-16.343, -53.436],
                                  [-142.497, 15.923],
                                  [89.718, 70.657],
                                  [-27.127, 109.922],
                                  [-13.967, 92],
                                ],
                                o: [
                                  [-90.055, 30.121],
                                  [4.011, 99.784],
                                  [-37.458, 58.21],
                                  [97.806, 38.227],
                                  [20.652, 58.74],
                                  [108.685, -8.63],
                                  [-89.718, -70.657],
                                  [20.829, -115.558],
                                  [13.967, -92],
                                ],
                                v: [
                                  [-67.926, -336.778],
                                  [-154.215, -189.749],
                                  [-259.1, -2.075],
                                  [-245.597, 152.812],
                                  [-92.831, 270.388],
                                  [105.555, 390.925],
                                  [308.168, 268.523],
                                  [115.297, -27.632],
                                  [258.333, -195.432],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.371, -25.259],
                                  [-3.744, -99.482],
                                  [37.27, -58.396],
                                  [-97.471, -38.262],
                                  [-16.329, -53.281],
                                  [-142.752, 16.039],
                                  [89.264, 70.546],
                                  [-26.936, 109.537],
                                  [-14.017, 92],
                                ],
                                o: [
                                  [-89.972, 29.939],
                                  [3.744, 99.482],
                                  [-37.27, 58.396],
                                  [97.471, 38.262],
                                  [20.674, 58.629],
                                  [108.657, -8.685],
                                  [-89.264, -70.546],
                                  [20.585, -115.22],
                                  [14.017, -92],
                                ],
                                v: [
                                  [-66.796, -336.621],
                                  [-154.695, -190.376],
                                  [-259.128, -2.911],
                                  [-245.908, 152.791],
                                  [-93.502, 270.525],
                                  [106.284, 390.941],
                                  [308.208, 268.363],
                                  [116.489, -27.234],
                                  [257.373, -195.48],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.482, -25.036],
                                  [-3.475, -99.177],
                                  [37.081, -58.582],
                                  [-97.133, -38.297],
                                  [-16.315, -53.125],
                                  [-143.008, 16.156],
                                  [88.808, 70.434],
                                  [-26.744, 109.15],
                                  [-14.067, 92],
                                ],
                                o: [
                                  [-89.889, 29.755],
                                  [3.475, 99.177],
                                  [-37.081, 58.582],
                                  [97.133, 38.297],
                                  [20.696, 58.518],
                                  [108.63, -8.741],
                                  [-88.808, -70.434],
                                  [20.34, -114.88],
                                  [14.067, -92],
                                ],
                                v: [
                                  [-65.686, -336.487],
                                  [-155.2, -191.022],
                                  [-259.199, -3.748],
                                  [-246.212, 152.706],
                                  [-94.134, 270.708],
                                  [107.028, 390.927],
                                  [308.249, 268.243],
                                  [117.683, -26.872],
                                  [256.373, -195.46],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.594, -24.812],
                                  [-3.205, -98.871],
                                  [36.89, -58.77],
                                  [-96.793, -38.333],
                                  [-16.301, -52.968],
                                  [-143.266, 16.274],
                                  [88.35, 70.321],
                                  [-26.55, 108.761],
                                  [-14.117, 92],
                                ],
                                o: [
                                  [-89.805, 29.57],
                                  [3.205, 98.871],
                                  [-36.89, 58.77],
                                  [96.793, 38.333],
                                  [20.719, 58.406],
                                  [108.602, -8.797],
                                  [-88.35, -70.321],
                                  [20.093, -114.538],
                                  [14.117, -92],
                                ],
                                v: [
                                  [-64.597, -336.375],
                                  [-155.709, -191.624],
                                  [-259.167, -4.606],
                                  [-246.508, 152.66],
                                  [-94.664, 270.873],
                                  [107.744, 390.861],
                                  [308.292, 268.08],
                                  [118.921, -26.504],
                                  [255.394, -195.414],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.707, -24.587],
                                  [-2.933, -98.564],
                                  [36.699, -58.959],
                                  [-96.452, -38.369],
                                  [-16.287, -52.811],
                                  [-143.525, 16.392],
                                  [87.888, 70.208],
                                  [-26.356, 108.37],
                                  [-14.168, 92],
                                ],
                                o: [
                                  [-89.72, 29.384],
                                  [2.933, 98.564],
                                  [-36.699, 58.959],
                                  [96.452, 38.369],
                                  [20.741, 58.293],
                                  [108.573, -8.853],
                                  [-87.888, -70.208],
                                  [19.845, -114.195],
                                  [14.168, -92],
                                ],
                                v: [
                                  [-63.508, -336.243],
                                  [-156.327, -192.265],
                                  [-259.2, -5.402],
                                  [-246.859, 152.59],
                                  [-95.218, 271.064],
                                  [108.414, 390.723],
                                  [308.315, 267.918],
                                  [120.162, -26.192],
                                  [254.416, -195.341],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.82, -24.36],
                                  [-2.66, -98.254],
                                  [36.506, -59.148],
                                  [-96.108, -38.405],
                                  [-16.273, -52.652],
                                  [-143.786, 16.511],
                                  [87.425, 70.094],
                                  [-26.161, 107.976],
                                  [-14.219, 92],
                                ],
                                o: [
                                  [-89.635, 29.197],
                                  [2.66, 98.254],
                                  [-36.506, 59.148],
                                  [96.108, 38.405],
                                  [20.764, 58.18],
                                  [108.545, -8.91],
                                  [-87.425, -70.094],
                                  [19.596, -113.849],
                                  [14.219, -92],
                                ],
                                v: [
                                  [-62.46, -336.176],
                                  [-156.909, -192.904],
                                  [-259.214, -6.2],
                                  [-247.18, 152.54],
                                  [-95.734, 271.238],
                                  [109.097, 390.596],
                                  [308.36, 267.776],
                                  [121.383, -25.873],
                                  [253.398, -195.241],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.934, -24.132],
                                  [-2.385, -97.943],
                                  [36.313, -59.339],
                                  [-95.763, -38.441],
                                  [-16.258, -52.493],
                                  [-144.048, 16.63],
                                  [86.959, 69.979],
                                  [-25.964, 107.58],
                                  [-14.27, 92],
                                ],
                                o: [
                                  [-89.55, 29.009],
                                  [2.385, 97.943],
                                  [-36.313, 59.339],
                                  [95.763, 38.441],
                                  [20.787, 58.066],
                                  [108.517, -8.967],
                                  [-86.958, -69.979],
                                  [19.346, -113.502],
                                  [14.27, -92],
                                ],
                                v: [
                                  [-61.432, -336.088],
                                  [-157.514, -193.499],
                                  [-259.188, -6.979],
                                  [-247.556, 152.447],
                                  [-96.191, 271.438],
                                  [109.733, 390.397],
                                  [308.428, 267.655],
                                  [122.586, -25.63],
                                  [252.445, -195.116],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.048, -23.903],
                                  [-2.108, -97.631],
                                  [36.118, -59.531],
                                  [-95.416, -38.478],
                                  [-16.244, -52.332],
                                  [-144.311, 16.751],
                                  [86.49, 69.864],
                                  [-25.767, 107.182],
                                  [-14.322, 92],
                                ],
                                o: [
                                  [-89.464, 28.82],
                                  [2.108, 97.631],
                                  [-36.118, 59.531],
                                  [95.416, 38.478],
                                  [20.81, 57.952],
                                  [108.488, -9.024],
                                  [-86.49, -69.864],
                                  [19.094, -113.153],
                                  [14.322, -92],
                                ],
                                v: [
                                  [-60.423, -336.064],
                                  [-158.167, -194.092],
                                  [-259.163, -7.76],
                                  [-247.862, 152.352],
                                  [-96.632, 271.661],
                                  [110.322, 390.171],
                                  [308.499, 267.515],
                                  [123.771, -25.362],
                                  [251.412, -194.964],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.163, -23.673],
                                  [-1.831, -97.316],
                                  [35.922, -59.724],
                                  [-95.067, -38.515],
                                  [-16.23, -52.171],
                                  [-144.576, 16.872],
                                  [86.018, 69.748],
                                  [-25.568, 106.782],
                                  [-14.374, 92],
                                ],
                                o: [
                                  [-89.377, 28.63],
                                  [1.831, 97.316],
                                  [-35.922, 59.724],
                                  [95.067, 38.515],
                                  [20.833, 57.837],
                                  [108.459, -9.082],
                                  [-86.018, -69.748],
                                  [18.84, -112.801],
                                  [14.374, -92],
                                ],
                                v: [
                                  [-59.458, -336.04],
                                  [-158.843, -194.683],
                                  [-259.101, -8.503],
                                  [-248.222, 152.277],
                                  [-97.015, 271.865],
                                  [110.945, 389.913],
                                  [308.593, 267.376],
                                  [124.957, -25.167],
                                  [250.421, -194.743],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.279, -23.442],
                                  [-1.551, -97],
                                  [35.725, -59.918],
                                  [-94.716, -38.551],
                                  [-16.215, -52.009],
                                  [-144.842, 16.993],
                                  [85.544, 69.632],
                                  [-25.368, 106.38],
                                  [-14.426, 92],
                                ],
                                o: [
                                  [-89.291, 28.439],
                                  [1.551, 97],
                                  [-35.725, 59.918],
                                  [94.716, 38.552],
                                  [20.856, 57.721],
                                  [108.43, -9.14],
                                  [-85.544, -69.631],
                                  [18.586, -112.448],
                                  [14.426, -92],
                                ],
                                v: [
                                  [-58.51, -336.018],
                                  [-159.586, -195.25],
                                  [-259.058, -9.247],
                                  [-248.574, 152.2],
                                  [-97.424, 272.095],
                                  [111.459, 389.605],
                                  [308.689, 267.299],
                                  [126.084, -24.988],
                                  [249.415, -194.517],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.396, -23.209],
                                  [-1.271, -96.682],
                                  [35.527, -60.113],
                                  [-94.363, -38.589],
                                  [-16.201, -51.846],
                                  [-145.11, 17.115],
                                  [85.068, 69.514],
                                  [-25.168, 105.975],
                                  [-14.478, 92],
                                ],
                                o: [
                                  [-89.203, 28.247],
                                  [1.271, 96.682],
                                  [-35.527, 60.113],
                                  [94.363, 38.589],
                                  [20.879, 57.604],
                                  [108.401, -9.198],
                                  [-85.068, -69.514],
                                  [18.33, -112.093],
                                  [14.478, -92],
                                ],
                                v: [
                                  [-57.584, -336.037],
                                  [-160.293, -195.796],
                                  [-258.999, -9.936],
                                  [-248.917, 152.105],
                                  [-97.757, 272.346],
                                  [112.007, 389.328],
                                  [308.81, 267.206],
                                  [127.195, -24.802],
                                  [248.411, -194.245],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.513, -22.975],
                                  [-0.988, -96.362],
                                  [35.329, -60.309],
                                  [-94.009, -38.626],
                                  [-16.186, -51.682],
                                  [-145.379, 17.238],
                                  [84.589, 69.397],
                                  [-24.966, 105.569],
                                  [-14.531, 92],
                                ],
                                o: [
                                  [-89.116, 28.054],
                                  [0.988, 96.362],
                                  [-35.328, 60.309],
                                  [94.009, 38.626],
                                  [20.903, 57.487],
                                  [108.372, -9.256],
                                  [-84.589, -69.397],
                                  [18.072, -111.736],
                                  [14.531, -92],
                                ],
                                v: [
                                  [-56.68, -336.057],
                                  [-161.022, -196.34],
                                  [-258.92, -10.645],
                                  [-249.315, 152.009],
                                  [-98.033, 272.601],
                                  [112.486, 389.002],
                                  [308.916, 267.092],
                                  [128.326, -24.712],
                                  [247.41, -193.968],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.63, -22.739],
                                  [-0.704, -96.041],
                                  [35.128, -60.506],
                                  [-93.652, -38.663],
                                  [-16.171, -51.518],
                                  [-145.65, 17.362],
                                  [84.107, 69.278],
                                  [-24.763, 105.16],
                                  [-14.584, 92],
                                ],
                                o: [
                                  [-89.027, 27.86],
                                  [0.704, 96.041],
                                  [-35.128, 60.506],
                                  [93.652, 38.663],
                                  [20.926, 57.37],
                                  [108.342, -9.315],
                                  [-84.107, -69.278],
                                  [17.813, -111.378],
                                  [14.584, -92],
                                ],
                                v: [
                                  [-55.792, -336.117],
                                  [-161.818, -196.882],
                                  [-258.866, -11.277],
                                  [-249.642, 151.873],
                                  [-98.298, 272.858],
                                  [112.982, 388.627],
                                  [309.066, 267.024],
                                  [129.38, -24.552],
                                  [246.435, -193.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.749, -22.503],
                                  [-0.419, -95.718],
                                  [34.927, -60.704],
                                  [-93.293, -38.701],
                                  [-16.156, -51.352],
                                  [-145.922, 17.486],
                                  [83.623, 69.159],
                                  [-24.559, 104.749],
                                  [-14.637, 92],
                                ],
                                o: [
                                  [-88.939, 27.665],
                                  [0.419, 95.718],
                                  [-34.927, 60.704],
                                  [93.293, 38.701],
                                  [20.95, 57.251],
                                  [108.313, -9.374],
                                  [-83.623, -69.159],
                                  [17.553, -111.017],
                                  [14.637, -92],
                                ],
                                v: [
                                  [-54.966, -336.178],
                                  [-162.578, -197.36],
                                  [-258.752, -11.936],
                                  [-250.022, 151.72],
                                  [-98.545, 273.096],
                                  [113.448, 388.282],
                                  [309.241, 266.918],
                                  [130.453, -24.509],
                                  [245.424, -193.209],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.868, -22.265],
                                  [-0.132, -95.393],
                                  [34.725, -60.903],
                                  [-92.933, -38.739],
                                  [-16.142, -51.185],
                                  [-146.195, 17.611],
                                  [83.136, 69.039],
                                  [-24.353, 104.335],
                                  [-14.69, 92],
                                ],
                                o: [
                                  [-88.849, 27.469],
                                  [0.132, 95.393],
                                  [-34.725, 60.903],
                                  [92.933, 38.739],
                                  [20.974, 57.133],
                                  [108.283, -9.434],
                                  [-83.136, -69.04],
                                  [17.291, -110.654],
                                  [14.69, -92],
                                ],
                                v: [
                                  [-54.14, -336.279],
                                  [-163.342, -197.898],
                                  [-258.679, -12.598],
                                  [-250.371, 151.567],
                                  [-98.742, 273.313],
                                  [113.845, 387.889],
                                  [309.38, 266.854],
                                  [131.512, -24.459],
                                  [244.439, -192.829],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.987, -22.026],
                                  [0.156, -95.066],
                                  [34.522, -61.103],
                                  [-92.57, -38.777],
                                  [-16.127, -51.018],
                                  [-146.47, 17.736],
                                  [82.647, 68.919],
                                  [-24.147, 103.92],
                                  [-14.744, 92],
                                ],
                                o: [
                                  [-88.76, 27.271],
                                  [-0.156, 95.066],
                                  [-34.522, 61.103],
                                  [92.57, 38.777],
                                  [20.997, 57.013],
                                  [108.253, -9.494],
                                  [-82.647, -68.919],
                                  [17.028, -110.289],
                                  [14.744, -92],
                                ],
                                v: [
                                  [-53.375, -336.379],
                                  [-164.11, -198.474],
                                  [-258.592, -13.162],
                                  [-250.735, 151.438],
                                  [-98.884, 273.572],
                                  [114.258, 387.51],
                                  [309.609, 266.814],
                                  [132.512, -24.402],
                                  [243.459, -192.404],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.107, -21.785],
                                  [0.446, -94.738],
                                  [34.318, -61.305],
                                  [-92.206, -38.815],
                                  [-16.112, -50.85],
                                  [-146.747, 17.863],
                                  [82.154, 68.798],
                                  [-23.94, 103.502],
                                  [-14.798, 92],
                                ],
                                o: [
                                  [-88.67, 27.073],
                                  [-0.446, 94.738],
                                  [-34.318, 61.305],
                                  [92.206, 38.815],
                                  [21.021, 56.893],
                                  [108.223, -9.554],
                                  [-82.154, -68.798],
                                  [16.764, -109.923],
                                  [14.798, -92],
                                ],
                                v: [
                                  [-52.648, -336.519],
                                  [-164.884, -199.008],
                                  [-258.485, -13.753],
                                  [-251.051, 151.27],
                                  [-99.056, 273.833],
                                  [114.642, 387.099],
                                  [309.843, 266.738],
                                  [133.515, -24.4],
                                  [242.521, -191.989],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.228, -21.544],
                                  [0.738, -94.408],
                                  [34.112, -61.507],
                                  [-91.84, -38.854],
                                  [-16.096, -50.681],
                                  [-147.025, 17.99],
                                  [81.66, 68.677],
                                  [-23.731, 103.082],
                                  [-14.853, 92],
                                ],
                                o: [
                                  [-88.579, 26.874],
                                  [-0.738, 94.408],
                                  [-34.112, 61.507],
                                  [91.84, 38.854],
                                  [21.046, 56.772],
                                  [108.193, -9.614],
                                  [-81.66, -68.677],
                                  [16.498, -109.554],
                                  [14.853, -92],
                                ],
                                v: [
                                  [-51.92, -336.658],
                                  [-165.622, -199.578],
                                  [-258.38, -14.31],
                                  [-251.397, 151.065],
                                  [-99.134, 274.073],
                                  [114.956, 386.663],
                                  [310.08, 266.727],
                                  [134.458, -24.429],
                                  [241.573, -191.529],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.35, -21.301],
                                  [1.031, -94.076],
                                  [33.906, -61.711],
                                  [-91.471, -38.893],
                                  [-16.081, -50.51],
                                  [-147.304, 18.117],
                                  [81.162, 68.554],
                                  [-23.522, 102.66],
                                  [-14.907, 92],
                                ],
                                o: [
                                  [-88.488, 26.673],
                                  [-1.031, 94.076],
                                  [-33.906, 61.711],
                                  [91.471, 38.893],
                                  [21.07, 56.65],
                                  [108.163, -9.675],
                                  [-81.162, -68.554],
                                  [16.231, -109.184],
                                  [14.907, -92],
                                ],
                                v: [
                                  [-51.229, -336.797],
                                  [-166.366, -200.146],
                                  [-258.238, -14.848],
                                  [-251.758, 150.886],
                                  [-99.244, 274.29],
                                  [115.288, 386.258],
                                  [310.346, 266.719],
                                  [135.428, -24.451],
                                  [240.629, -191.101],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.472, -21.056],
                                  [1.326, -93.743],
                                  [33.698, -61.915],
                                  [-91.101, -38.932],
                                  [-16.066, -50.339],
                                  [-147.585, 18.245],
                                  [80.662, 68.431],
                                  [-23.311, 102.235],
                                  [-14.962, 92],
                                ],
                                o: [
                                  [-88.396, 26.472],
                                  [-1.326, 93.743],
                                  [-33.698, 61.915],
                                  [91.101, 38.932],
                                  [21.094, 56.528],
                                  [108.132, -9.736],
                                  [-80.662, -68.431],
                                  [15.962, -108.811],
                                  [14.962, -92],
                                ],
                                v: [
                                  [-50.575, -336.999],
                                  [-167.115, -200.712],
                                  [-258.138, -15.376],
                                  [-252.049, 150.733],
                                  [-99.26, 274.547],
                                  [115.55, 385.767],
                                  [310.64, 266.675],
                                  [136.377, -24.488],
                                  [239.714, -190.605],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.595, -20.811],
                                  [1.622, -93.407],
                                  [33.489, -62.121],
                                  [-90.729, -38.971],
                                  [-16.051, -50.168],
                                  [-147.868, 18.374],
                                  [80.16, 68.308],
                                  [-23.099, 101.809],
                                  [-15.018, 92],
                                ],
                                o: [
                                  [-88.304, 26.269],
                                  [-1.622, 93.407],
                                  [-33.489, 62.121],
                                  [90.729, 38.971],
                                  [21.119, 56.405],
                                  [108.101, -9.797],
                                  [-80.16, -68.308],
                                  [15.692, -108.437],
                                  [15.018, -92],
                                ],
                                v: [
                                  [-49.982, -337.213],
                                  [-167.844, -201.275],
                                  [-258.002, -15.847],
                                  [-252.393, 150.544],
                                  [-99.286, 274.795],
                                  [115.806, 385.306],
                                  [310.915, 266.696],
                                  [137.229, -24.581],
                                  [238.817, -190.139],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.718, -20.564],
                                  [1.92, -93.07],
                                  [33.279, -62.328],
                                  [-90.355, -39.01],
                                  [-16.035, -49.995],
                                  [-148.151, 18.504],
                                  [79.654, 68.184],
                                  [-22.886, 101.38],
                                  [-15.073, 92],
                                ],
                                o: [
                                  [-88.211, 26.065],
                                  [-1.92, 93.07],
                                  [-33.279, 62.328],
                                  [90.355, 39.01],
                                  [21.144, 56.282],
                                  [108.07, -9.859],
                                  [-79.654, -68.184],
                                  [15.42, -108.06],
                                  [15.073, -92],
                                ],
                                v: [
                                  [-49.386, -337.427],
                                  [-168.603, -201.835],
                                  [-257.894, -16.309],
                                  [-252.691, 150.382],
                                  [-99.344, 275.004],
                                  [116.055, 384.822],
                                  [311.257, 266.658],
                                  [138.146, -24.666],
                                  [237.912, -189.629],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.842, -20.316],
                                  [2.219, -92.731],
                                  [33.068, -62.536],
                                  [-89.979, -39.05],
                                  [-16.02, -49.821],
                                  [-148.437, 18.634],
                                  [79.146, 68.059],
                                  [-22.672, 100.949],
                                  [-15.129, 92],
                                ],
                                o: [
                                  [-88.118, 25.861],
                                  [-2.219, 92.731],
                                  [-33.068, 62.536],
                                  [89.979, 39.05],
                                  [21.168, 56.158],
                                  [108.039, -9.921],
                                  [-79.146, -68.059],
                                  [15.147, -107.682],
                                  [15.129, -92],
                                ],
                                v: [
                                  [-48.826, -337.703],
                                  [-169.304, -202.431],
                                  [-257.763, -16.753],
                                  [-252.981, 150.223],
                                  [-99.374, 275.289],
                                  [116.273, 384.331],
                                  [311.542, 266.686],
                                  [139.005, -24.781],
                                  [237.036, -189.113],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.967, -20.066],
                                  [2.52, -92.391],
                                  [32.856, -62.744],
                                  [-89.6, -39.089],
                                  [-16.004, -49.646],
                                  [-148.724, 18.765],
                                  [78.636, 67.933],
                                  [-22.457, 100.515],
                                  [-15.185, 92],
                                ],
                                o: [
                                  [-88.025, 25.655],
                                  [-2.52, 92.391],
                                  [-32.856, 62.744],
                                  [89.601, 39.089],
                                  [21.193, 56.033],
                                  [108.008, -9.983],
                                  [-78.636, -67.933],
                                  [14.873, -107.301],
                                  [15.185, -92],
                                ],
                                v: [
                                  [-48.302, -337.952],
                                  [-169.985, -203.011],
                                  [-257.674, -17.202],
                                  [-253.262, 150.03],
                                  [-99.375, 275.524],
                                  [116.446, 383.831],
                                  [311.893, 266.679],
                                  [139.828, -24.913],
                                  [236.142, -188.627],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.092, -19.816],
                                  [2.822, -92.048],
                                  [32.643, -62.954],
                                  [-89.22, -39.129],
                                  [-15.988, -49.471],
                                  [-149.012, 18.897],
                                  [78.122, 67.807],
                                  [-22.241, 100.08],
                                  [-15.242, 92],
                                ],
                                o: [
                                  [-87.931, 25.448],
                                  [-2.822, 92.048],
                                  [-32.643, 62.954],
                                  [89.22, 39.129],
                                  [21.218, 55.908],
                                  [107.977, -10.046],
                                  [-78.122, -67.807],
                                  [14.597, -106.919],
                                  [15.242, -92],
                                ],
                                v: [
                                  [-47.775, -338.263],
                                  [-170.696, -203.601],
                                  [-257.524, -17.683],
                                  [-253.56, 149.866],
                                  [-99.387, 275.758],
                                  [116.575, 383.312],
                                  [312.287, 266.712],
                                  [140.631, -25.037],
                                  [235.277, -188.072],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.218, -19.563],
                                  [3.127, -91.704],
                                  [32.429, -63.166],
                                  [-88.838, -39.17],
                                  [-15.973, -49.294],
                                  [-149.302, 19.029],
                                  [77.606, 67.68],
                                  [-22.023, 99.641],
                                  [-15.298, 92],
                                ],
                                o: [
                                  [-87.836, 25.24],
                                  [-3.127, 91.704],
                                  [-32.429, 63.166],
                                  [88.838, 39.17],
                                  [21.244, 55.782],
                                  [107.945, -10.109],
                                  [-77.606, -67.68],
                                  [14.32, -106.534],
                                  [15.298, -92],
                                ],
                                v: [
                                  [-47.27, -338.547],
                                  [-171.35, -204.176],
                                  [-257.414, -18.107],
                                  [-253.825, 149.693],
                                  [-99.371, 276.002],
                                  [116.734, 382.785],
                                  [312.648, 266.712],
                                  [141.4, -25.215],
                                  [234.456, -187.51],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.345, -19.31],
                                  [3.432, -91.358],
                                  [32.214, -63.378],
                                  [-88.454, -39.21],
                                  [-15.957, -49.117],
                                  [-149.593, 19.162],
                                  [77.088, 67.553],
                                  [-21.805, 99.201],
                                  [-15.355, 92],
                                ],
                                o: [
                                  [-87.741, 25.031],
                                  [-3.432, 91.358],
                                  [-32.214, 63.378],
                                  [88.454, 39.21],
                                  [21.269, 55.655],
                                  [107.914, -10.172],
                                  [-77.088, -67.553],
                                  [14.041, -106.148],
                                  [15.355, -92],
                                ],
                                v: [
                                  [-46.789, -338.84],
                                  [-172.009, -204.786],
                                  [-257.269, -18.562],
                                  [-254.069, 149.526],
                                  [-99.391, 276.218],
                                  [116.824, 382.276],
                                  [313.014, 266.715],
                                  [142.172, -25.361],
                                  [233.603, -186.94],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.472, -19.055],
                                  [3.739, -91.01],
                                  [31.997, -63.591],
                                  [-88.068, -39.251],
                                  [-15.941, -48.939],
                                  [-149.886, 19.296],
                                  [76.566, 67.425],
                                  [-21.585, 98.759],
                                  [-15.413, 92],
                                ],
                                o: [
                                  [-87.646, 24.821],
                                  [-3.739, 91.01],
                                  [-31.997, 63.591],
                                  [88.068, 39.251],
                                  [21.294, 55.528],
                                  [107.882, -10.236],
                                  [-76.566, -67.425],
                                  [13.761, -105.759],
                                  [15.413, -92],
                                ],
                                v: [
                                  [-46.331, -339.106],
                                  [-172.673, -205.356],
                                  [-257.163, -18.999],
                                  [-254.306, 149.389],
                                  [-99.396, 276.433],
                                  [116.871, 381.723],
                                  [313.422, 266.759],
                                  [142.912, -25.524],
                                  [232.756, -186.401],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.6, -18.799],
                                  [4.048, -90.66],
                                  [31.779, -63.806],
                                  [-87.68, -39.291],
                                  [-15.925, -48.76],
                                  [-150.181, 19.43],
                                  [76.042, 67.296],
                                  [-21.364, 98.314],
                                  [-15.47, 92],
                                ],
                                o: [
                                  [-87.55, 24.609],
                                  [-4.048, 90.66],
                                  [-31.779, 63.806],
                                  [87.68, 39.291],
                                  [21.32, 55.4],
                                  [107.85, -10.3],
                                  [-76.042, -67.296],
                                  [13.479, -105.369],
                                  [15.47, -92],
                                ],
                                v: [
                                  [-45.898, -339.378],
                                  [-173.317, -205.961],
                                  [-257.034, -19.467],
                                  [-254.499, 149.257],
                                  [-99.402, 276.655],
                                  [116.947, 381.163],
                                  [313.834, 266.769],
                                  [143.619, -25.715],
                                  [231.941, -185.817],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.729, -18.542],
                                  [4.359, -90.309],
                                  [31.561, -64.021],
                                  [-87.29, -39.332],
                                  [-15.909, -48.579],
                                  [-150.477, 19.565],
                                  [75.516, 67.166],
                                  [-21.142, 97.867],
                                  [-15.528, 92],
                                ],
                                o: [
                                  [-87.453, 24.397],
                                  [-4.359, 90.309],
                                  [-31.561, 64.021],
                                  [87.29, 39.332],
                                  [21.346, 55.271],
                                  [107.818, -10.365],
                                  [-75.516, -67.166],
                                  [13.196, -104.976],
                                  [15.528, -92],
                                ],
                                v: [
                                  [-45.5, -339.684],
                                  [-173.939, -206.552],
                                  [-256.942, -19.906],
                                  [-254.71, 149.121],
                                  [-99.418, 276.848],
                                  [117.006, 380.623],
                                  [314.313, 266.783],
                                  [144.341, -25.872],
                                  [231.133, -185.225],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.858, -18.283],
                                  [4.671, -89.956],
                                  [31.341, -64.238],
                                  [-86.898, -39.374],
                                  [-15.893, -48.398],
                                  [-150.774, 19.701],
                                  [74.986, 67.036],
                                  [-20.919, 97.417],
                                  [-15.586, 92],
                                ],
                                o: [
                                  [-87.356, 24.184],
                                  [-4.671, 89.956],
                                  [-31.341, 64.238],
                                  [86.898, 39.374],
                                  [21.372, 55.142],
                                  [107.785, -10.429],
                                  [-74.986, -67.036],
                                  [12.912, -104.582],
                                  [15.586, -92],
                                ],
                                v: [
                                  [-45.152, -339.932],
                                  [-174.556, -207.151],
                                  [-256.79, -20.326],
                                  [-254.887, 148.991],
                                  [-99.41, 277.037],
                                  [116.996, 380.05],
                                  [314.734, 266.827],
                                  [145.02, -26.021],
                                  [230.34, -184.591],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.988, -18.023],
                                  [4.984, -89.601],
                                  [31.12, -64.456],
                                  [-86.504, -39.415],
                                  [-15.876, -48.216],
                                  [-151.073, 19.838],
                                  [74.454, 66.906],
                                  [-20.695, 96.965],
                                  [-15.645, 92],
                                ],
                                o: [
                                  [-87.259, 23.969],
                                  [-4.984, 89.601],
                                  [-31.12, 64.456],
                                  [86.504, 39.415],
                                  [21.398, 55.012],
                                  [107.753, -10.494],
                                  [-74.454, -66.905],
                                  [12.626, -104.185],
                                  [15.645, -92],
                                ],
                                v: [
                                  [-44.805, -340.176],
                                  [-175.125, -207.738],
                                  [-256.701, -20.779],
                                  [-255.048, 148.893],
                                  [-99.414, 277.231],
                                  [117.043, 379.461],
                                  [315.196, 266.885],
                                  [145.678, -26.133],
                                  [229.544, -183.985],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.119, -17.762],
                                  [5.3, -89.244],
                                  [30.898, -64.674],
                                  [-86.108, -39.457],
                                  [-15.86, -48.033],
                                  [-151.374, 19.975],
                                  [73.919, 66.774],
                                  [-20.47, 96.511],
                                  [-15.704, 92],
                                ],
                                o: [
                                  [-87.161, 23.754],
                                  [-5.3, 89.244],
                                  [-30.898, 64.674],
                                  [86.108, 39.457],
                                  [21.424, 54.881],
                                  [107.72, -10.56],
                                  [-73.919, -66.774],
                                  [12.338, -103.787],
                                  [15.704, -92],
                                ],
                                v: [
                                  [-44.483, -340.45],
                                  [-175.691, -208.323],
                                  [-256.587, -21.212],
                                  [-255.202, 148.767],
                                  [-99.43, 277.359],
                                  [117.047, 378.866],
                                  [315.662, 266.91],
                                  [146.305, -26.299],
                                  [228.755, -183.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.25, -17.499],
                                  [5.617, -88.885],
                                  [30.674, -64.894],
                                  [-85.71, -39.499],
                                  [-15.844, -47.849],
                                  [-151.676, 20.113],
                                  [73.381, 66.642],
                                  [-20.243, 96.055],
                                  [-15.763, 92],
                                ],
                                o: [
                                  [-87.062, 23.537],
                                  [-5.617, 88.885],
                                  [-30.674, 64.894],
                                  [85.71, 39.499],
                                  [21.45, 54.75],
                                  [107.687, -10.625],
                                  [-73.381, -66.642],
                                  [12.049, -103.386],
                                  [15.763, -92],
                                ],
                                v: [
                                  [-44.223, -340.7],
                                  [-176.27, -208.906],
                                  [-256.447, -21.644],
                                  [-255.34, 148.683],
                                  [-99.458, 277.517],
                                  [117.072, 378.238],
                                  [316.167, 266.939],
                                  [146.964, -26.429],
                                  [228.008, -182.751],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.382, -17.235],
                                  [5.935, -88.524],
                                  [30.45, -65.116],
                                  [-85.31, -39.541],
                                  [-15.827, -47.665],
                                  [-151.98, 20.252],
                                  [72.841, 66.509],
                                  [-20.015, 95.596],
                                  [-15.822, 92],
                                ],
                                o: [
                                  [-86.963, 23.319],
                                  [-5.935, 88.524],
                                  [-30.45, 65.116],
                                  [85.31, 39.541],
                                  [21.476, 54.618],
                                  [107.654, -10.691],
                                  [-72.841, -66.509],
                                  [11.759, -102.984],
                                  [15.822, -92],
                                ],
                                v: [
                                  [-43.991, -340.944],
                                  [-176.791, -209.514],
                                  [-256.37, -22.056],
                                  [-255.472, 148.597],
                                  [-99.49, 277.643],
                                  [117.056, 377.657],
                                  [316.615, 266.963],
                                  [147.601, -26.549],
                                  [227.231, -182.088],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.515, -16.97],
                                  [6.255, -88.162],
                                  [30.224, -65.338],
                                  [-84.907, -39.583],
                                  [-15.811, -47.479],
                                  [-152.285, 20.391],
                                  [72.298, 66.375],
                                  [-19.786, 95.135],
                                  [-15.882, 92],
                                ],
                                o: [
                                  [-86.864, 23.1],
                                  [-6.255, 88.162],
                                  [-30.224, 65.338],
                                  [84.907, 39.583],
                                  [21.503, 54.485],
                                  [107.621, -10.758],
                                  [-72.298, -66.375],
                                  [11.467, -102.579],
                                  [15.882, -92],
                                ],
                                v: [
                                  [-43.785, -341.153],
                                  [-177.282, -210.093],
                                  [-256.267, -22.475],
                                  [-255.563, 148.52],
                                  [-99.508, 277.764],
                                  [117.035, 377.009],
                                  [317.128, 267],
                                  [148.173, -26.659],
                                  [226.497, -181.453],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.648, -16.703],
                                  [6.577, -87.798],
                                  [29.998, -65.561],
                                  [-84.503, -39.625],
                                  [-15.794, -47.292],
                                  [-152.591, 20.531],
                                  [71.752, 66.241],
                                  [-19.556, 94.672],
                                  [-15.942, 92],
                                ],
                                o: [
                                  [-86.764, 22.88],
                                  [-6.577, 87.798],
                                  [-29.998, 65.561],
                                  [84.503, 39.625],
                                  [21.53, 54.352],
                                  [107.588, -10.824],
                                  [-71.752, -66.241],
                                  [11.173, -102.172],
                                  [15.942, -92],
                                ],
                                v: [
                                  [-43.616, -341.327],
                                  [-177.841, -210.662],
                                  [-256.163, -22.902],
                                  [-255.676, 148.443],
                                  [-99.601, 277.852],
                                  [117.036, 376.382],
                                  [317.68, 267.041],
                                  [148.786, -26.759],
                                  [225.742, -180.775],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.782, -16.435],
                                  [6.9, -87.432],
                                  [29.77, -65.786],
                                  [-84.097, -39.668],
                                  [-15.777, -47.105],
                                  [-152.9, 20.672],
                                  [71.203, 66.106],
                                  [-19.325, 94.206],
                                  [-16.002, 92],
                                ],
                                o: [
                                  [-86.663, 22.659],
                                  [-6.9, 87.432],
                                  [-29.77, 65.786],
                                  [84.097, 39.668],
                                  [21.557, 54.218],
                                  [107.554, -10.891],
                                  [-71.203, -66.106],
                                  [10.879, -101.764],
                                  [16.002, -92],
                                ],
                                v: [
                                  [-43.474, -341.5],
                                  [-178.342, -211.265],
                                  [-256.094, -23.301],
                                  [-255.748, 148.381],
                                  [-99.645, 277.94],
                                  [116.997, 375.749],
                                  [318.174, 267.086],
                                  [149.335, -26.884],
                                  [224.986, -180.09],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.917, -16.166],
                                  [7.225, -87.064],
                                  [29.541, -66.011],
                                  [-83.689, -39.711],
                                  [-15.76, -46.916],
                                  [-153.21, 20.813],
                                  [70.652, 65.971],
                                  [-19.093, 93.738],
                                  [-16.063, 92],
                                ],
                                o: [
                                  [-86.562, 22.437],
                                  [-7.225, 87.064],
                                  [-29.541, 66.011],
                                  [83.689, 39.711],
                                  [21.583, 54.083],
                                  [107.521, -10.959],
                                  [-70.652, -65.971],
                                  [10.582, -101.353],
                                  [16.063, -92],
                                ],
                                v: [
                                  [-43.333, -341.665],
                                  [-178.786, -211.86],
                                  [-256.025, -23.743],
                                  [-255.816, 148.383],
                                  [-99.729, 277.987],
                                  [116.989, 375.077],
                                  [318.705, 267.1],
                                  [149.897, -26.935],
                                  [224.271, -179.398],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.053, -15.895],
                                  [7.552, -86.694],
                                  [29.311, -66.238],
                                  [-83.278, -39.754],
                                  [-15.743, -46.726],
                                  [-153.521, 20.955],
                                  [70.097, 65.835],
                                  [-18.859, 93.267],
                                  [-16.124, 92],
                                ],
                                o: [
                                  [-86.461, 22.213],
                                  [-7.552, 86.694],
                                  [-29.311, 66.238],
                                  [83.278, 39.754],
                                  [21.611, 53.947],
                                  [107.487, -11.026],
                                  [-70.097, -65.835],
                                  [10.284, -100.94],
                                  [16.124, -92],
                                ],
                                v: [
                                  [-43.254, -341.856],
                                  [-179.298, -212.425],
                                  [-255.928, -24.13],
                                  [-255.873, 148.332],
                                  [-99.793, 278.062],
                                  [116.969, 374.428],
                                  [319.24, 267.124],
                                  [150.424, -27.011],
                                  [223.563, -178.727],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.189, -15.623],
                                  [7.88, -86.322],
                                  [29.079, -66.466],
                                  [-82.866, -39.798],
                                  [-15.726, -46.536],
                                  [-153.834, 21.098],
                                  [69.54, 65.698],
                                  [-18.625, 92.795],
                                  [-16.185, 92],
                                ],
                                o: [
                                  [-86.359, 21.989],
                                  [-7.88, 86.322],
                                  [-29.079, 66.466],
                                  [82.866, 39.798],
                                  [21.638, 53.811],
                                  [107.453, -11.094],
                                  [-69.54, -65.698],
                                  [9.985, -100.525],
                                  [16.185, -92],
                                ],
                                v: [
                                  [-43.205, -341.946],
                                  [-179.753, -212.983],
                                  [-255.864, -24.553],
                                  [-255.891, 148.318],
                                  [-99.905, 278.067],
                                  [116.945, 373.774],
                                  [319.812, 267.147],
                                  [150.958, -27.041],
                                  [222.868, -178.049],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.325, -15.349],
                                  [8.21, -85.949],
                                  [28.847, -66.695],
                                  [-82.451, -39.841],
                                  [-15.709, -46.344],
                                  [-154.148, 21.242],
                                  [68.98, 65.56],
                                  [-18.389, 92.319],
                                  [-16.246, 92],
                                ],
                                o: [
                                  [-86.256, 21.763],
                                  [-8.21, 85.949],
                                  [-28.847, 66.695],
                                  [82.451, 39.841],
                                  [21.665, 53.675],
                                  [107.419, -11.163],
                                  [-68.98, -65.56],
                                  [9.684, -100.108],
                                  [16.246, -92],
                                ],
                                v: [
                                  [-43.191, -342.06],
                                  [-180.213, -213.57],
                                  [-255.798, -24.922],
                                  [-255.905, 148.313],
                                  [-100.03, 278.071],
                                  [116.883, 373.145],
                                  [320.358, 267.145],
                                  [151.47, -27.095],
                                  [222.173, -177.335],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.463, -15.074],
                                  [8.542, -85.573],
                                  [28.613, -66.925],
                                  [-82.035, -39.885],
                                  [-15.692, -46.152],
                                  [-154.464, 21.386],
                                  [68.418, 65.422],
                                  [-18.152, 91.842],
                                  [-16.308, 92],
                                ],
                                o: [
                                  [-86.153, 21.536],
                                  [-8.542, 85.573],
                                  [-28.613, 66.925],
                                  [82.035, 39.885],
                                  [21.693, 53.537],
                                  [107.384, -11.231],
                                  [-68.418, -65.422],
                                  [9.382, -99.688],
                                  [16.308, -92],
                                ],
                                v: [
                                  [-43.206, -342.136],
                                  [-180.616, -214.127],
                                  [-255.732, -25.332],
                                  [-255.882, 148.317],
                                  [-100.203, 278.032],
                                  [116.845, 372.51],
                                  [320.906, 267.177],
                                  [151.984, -27.137],
                                  [221.49, -176.672],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.601, -14.798],
                                  [8.875, -85.196],
                                  [28.378, -67.157],
                                  [-81.616, -39.929],
                                  [-15.675, -45.959],
                                  [-154.782, 21.531],
                                  [67.852, 65.283],
                                  [-17.913, 91.362],
                                  [-16.37, 92],
                                ],
                                o: [
                                  [-86.049, 21.308],
                                  [-8.875, 85.196],
                                  [-28.378, 67.157],
                                  [81.616, 39.929],
                                  [21.72, 53.399],
                                  [107.35, -11.3],
                                  [-67.852, -65.283],
                                  [9.078, -99.267],
                                  [16.37, -92],
                                ],
                                v: [
                                  [-43.285, -342.2],
                                  [-181.024, -214.713],
                                  [-255.698, -25.72],
                                  [-255.851, 148.394],
                                  [-100.355, 278.02],
                                  [116.805, 371.839],
                                  [321.456, 267.184],
                                  [152.443, -27.139],
                                  [220.779, -175.94],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.74, -14.521],
                                  [9.21, -84.817],
                                  [28.142, -67.389],
                                  [-81.195, -39.973],
                                  [-15.658, -45.764],
                                  [-155.101, 21.677],
                                  [67.284, 65.143],
                                  [-17.674, 90.879],
                                  [-16.433, 92],
                                ],
                                o: [
                                  [-85.945, 21.079],
                                  [-9.21, 84.817],
                                  [-28.142, 67.389],
                                  [81.195, 39.973],
                                  [21.748, 53.26],
                                  [107.315, -11.37],
                                  [-67.284, -65.143],
                                  [8.773, -98.844],
                                  [16.433, -92],
                                ],
                                v: [
                                  [-43.336, -342.225],
                                  [-181.439, -215.266],
                                  [-255.666, -26.112],
                                  [-255.818, 148.417],
                                  [-100.522, 277.937],
                                  [116.761, 371.222],
                                  [322.043, 267.194],
                                  [152.938, -27.129],
                                  [220.108, -175.201],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.879, -14.242],
                                  [9.546, -84.436],
                                  [27.905, -67.623],
                                  [-80.772, -40.018],
                                  [-15.64, -45.569],
                                  [-155.422, 21.823],
                                  [66.713, 65.003],
                                  [-17.433, 90.395],
                                  [-16.496, 92],
                                ],
                                o: [
                                  [-85.841, 20.849],
                                  [-9.546, 84.436],
                                  [-27.905, 67.623],
                                  [80.772, 40.018],
                                  [21.776, 53.121],
                                  [107.28, -11.44],
                                  [-66.713, -65.003],
                                  [8.466, -98.418],
                                  [16.496, -92],
                                ],
                                v: [
                                  [-43.417, -342.271],
                                  [-181.825, -215.848],
                                  [-255.598, -26.483],
                                  [-255.778, 148.447],
                                  [-100.736, 277.849],
                                  [116.743, 370.601],
                                  [322.601, 267.18],
                                  [153.412, -27.075],
                                  [219.477, -174.518],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.019, -13.961],
                                  [9.884, -84.053],
                                  [27.667, -67.858],
                                  [-80.347, -40.062],
                                  [-15.623, -45.373],
                                  [-155.745, 21.97],
                                  [66.139, 64.862],
                                  [-17.191, 89.908],
                                  [-16.559, 92],
                                ],
                                o: [
                                  [-85.735, 20.618],
                                  [-9.884, 84.053],
                                  [-27.667, 67.858],
                                  [80.347, 40.062],
                                  [21.804, 52.981],
                                  [107.245, -11.51],
                                  [-66.139, -64.862],
                                  [8.157, -97.991],
                                  [16.559, -92],
                                ],
                                v: [
                                  [-43.562, -342.244],
                                  [-182.188, -216.402],
                                  [-255.624, -26.865],
                                  [-255.704, 148.52],
                                  [-100.963, 277.75],
                                  [116.689, 369.978],
                                  [323.194, 267.199],
                                  [153.861, -27.012],
                                  [218.788, -173.796],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.16, -13.679],
                                  [10.224, -83.668],
                                  [27.427, -68.094],
                                  [-79.92, -40.107],
                                  [-15.605, -45.176],
                                  [-156.069, 22.118],
                                  [65.563, 64.72],
                                  [-16.948, 89.418],
                                  [-16.622, 92],
                                ],
                                o: [
                                  [-85.63, 20.385],
                                  [-10.224, 83.668],
                                  [-27.428, 68.094],
                                  [79.92, 40.107],
                                  [21.832, 52.84],
                                  [107.21, -11.58],
                                  [-65.563, -64.72],
                                  [7.847, -97.561],
                                  [16.622, -92],
                                ],
                                v: [
                                  [-43.74, -342.204],
                                  [-182.527, -216.953],
                                  [-255.618, -27.251],
                                  [-255.597, 148.633],
                                  [-101.204, 277.582],
                                  [116.662, 369.382],
                                  [323.788, 267.16],
                                  [154.314, -26.967],
                                  [218.139, -173.097],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.302, -13.396],
                                  [10.566, -83.282],
                                  [27.187, -68.331],
                                  [-79.491, -40.152],
                                  [-15.587, -44.977],
                                  [-156.394, 22.267],
                                  [64.983, 64.577],
                                  [-16.704, 88.926],
                                  [-16.686, 92],
                                ],
                                o: [
                                  [-85.524, 20.152],
                                  [-10.566, 83.282],
                                  [-27.187, 68.331],
                                  [79.491, 40.152],
                                  [21.86, 52.698],
                                  [107.175, -11.651],
                                  [-64.983, -64.577],
                                  [7.536, -97.129],
                                  [16.686, -92],
                                ],
                                v: [
                                  [-43.887, -342.154],
                                  [-182.87, -217.503],
                                  [-255.578, -27.615],
                                  [-255.488, 148.727],
                                  [-101.491, 277.435],
                                  [116.633, 368.781],
                                  [324.353, 267.125],
                                  [154.747, -26.879],
                                  [217.494, -172.33],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.444, -13.112],
                                  [10.909, -82.893],
                                  [26.945, -68.569],
                                  [-79.06, -40.198],
                                  [-15.569, -44.778],
                                  [-156.722, 22.416],
                                  [64.401, 64.434],
                                  [-16.459, 88.432],
                                  [-16.75, 92],
                                ],
                                o: [
                                  [-85.417, 19.917],
                                  [-10.909, 82.893],
                                  [-26.945, 68.569],
                                  [79.06, 40.198],
                                  [21.889, 52.556],
                                  [107.139, -11.722],
                                  [-64.401, -64.434],
                                  [7.223, -96.695],
                                  [16.75, -92],
                                ],
                                v: [
                                  [-44.13, -342.06],
                                  [-183.187, -218.057],
                                  [-255.597, -28.018],
                                  [-255.376, 148.831],
                                  [-101.791, 277.249],
                                  [116.602, 368.21],
                                  [324.951, 267.097],
                                  [155.188, -26.717],
                                  [216.888, -171.619],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.587, -12.826],
                                  [11.254, -82.503],
                                  [26.702, -68.808],
                                  [-78.627, -40.243],
                                  [-15.552, -44.578],
                                  [-157.05, 22.566],
                                  [63.815, 64.29],
                                  [-16.212, 87.935],
                                  [-16.814, 92],
                                ],
                                o: [
                                  [-85.31, 19.681],
                                  [-11.254, 82.503],
                                  [-26.702, 68.808],
                                  [78.627, 40.243],
                                  [21.917, 52.413],
                                  [107.103, -11.794],
                                  [-63.815, -64.29],
                                  [6.908, -96.259],
                                  [16.814, -92],
                                ],
                                v: [
                                  [-44.343, -341.922],
                                  [-183.448, -218.611],
                                  [-255.613, -28.398],
                                  [-255.233, 148.976],
                                  [-102.105, 277.053],
                                  [116.599, 367.636],
                                  [325.549, 267.07],
                                  [155.606, -26.605],
                                  [216.255, -170.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.731, -12.538],
                                  [11.6, -82.11],
                                  [26.458, -69.049],
                                  [-78.191, -40.289],
                                  [-15.534, -44.377],
                                  [-157.381, 22.717],
                                  [63.227, 64.146],
                                  [-15.964, 87.436],
                                  [-16.879, 92],
                                ],
                                o: [
                                  [-85.202, 19.444],
                                  [-11.6, 82.11],
                                  [-26.458, 69.049],
                                  [78.191, 40.289],
                                  [21.946, 52.269],
                                  [107.067, -11.865],
                                  [-63.227, -64.146],
                                  [6.592, -95.821],
                                  [16.879, -92],
                                ],
                                v: [
                                  [-44.618, -341.772],
                                  [-183.745, -219.166],
                                  [-255.626, -28.753],
                                  [-255.059, 149.101],
                                  [-102.495, 276.848],
                                  [116.563, 367.091],
                                  [326.116, 266.985],
                                  [156.032, -26.417],
                                  [215.628, -170.211],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.875, -12.25],
                                  [11.949, -81.716],
                                  [26.212, -69.291],
                                  [-77.753, -40.335],
                                  [-15.516, -44.175],
                                  [-157.713, 22.869],
                                  [62.636, 64.001],
                                  [-15.715, 86.934],
                                  [-16.944, 92],
                                ],
                                o: [
                                  [-85.094, 19.206],
                                  [-11.949, 81.716],
                                  [-26.212, 69.291],
                                  [77.753, 40.335],
                                  [21.975, 52.125],
                                  [107.031, -11.938],
                                  [-62.636, -64.001],
                                  [6.275, -95.381],
                                  [16.944, -92],
                                ],
                                v: [
                                  [-44.894, -341.577],
                                  [-183.986, -219.693],
                                  [-255.666, -29.146],
                                  [-254.917, 149.268],
                                  [-102.868, 276.603],
                                  [116.556, 366.577],
                                  [326.714, 266.936],
                                  [156.468, -26.217],
                                  [215.037, -169.483],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.02, -11.96],
                                  [12.299, -81.32],
                                  [25.966, -69.534],
                                  [-77.314, -40.382],
                                  [-15.497, -43.972],
                                  [-158.047, 23.021],
                                  [62.042, 63.855],
                                  [-15.465, 86.43],
                                  [-17.009, 92],
                                ],
                                o: [
                                  [-84.985, 18.967],
                                  [-12.299, 81.32],
                                  [-25.966, 69.534],
                                  [77.314, 40.381],
                                  [22.004, 51.98],
                                  [106.995, -12.01],
                                  [-62.042, -63.855],
                                  [5.956, -94.938],
                                  [17.009, -92],
                                ],
                                v: [
                                  [-45.171, -341.369],
                                  [-184.2, -220.253],
                                  [-255.732, -29.515],
                                  [-254.713, 149.445],
                                  [-103.285, 276.316],
                                  [116.58, 366.062],
                                  [327.281, 266.86],
                                  [156.851, -25.972],
                                  [214.419, -168.75],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.166, -11.668],
                                  [12.65, -80.922],
                                  [25.718, -69.778],
                                  [-76.872, -40.428],
                                  [-15.479, -43.768],
                                  [-158.382, 23.174],
                                  [61.446, 63.708],
                                  [-15.214, 85.923],
                                  [-17.075, 92],
                                ],
                                o: [
                                  [-84.876, 18.726],
                                  [-12.65, 80.922],
                                  [-25.718, 69.778],
                                  [76.872, 40.428],
                                  [22.033, 51.834],
                                  [106.958, -12.083],
                                  [-61.446, -63.708],
                                  [5.635, -94.494],
                                  [17.075, -92],
                                ],
                                v: [
                                  [-45.511, -341.146],
                                  [-184.42, -220.786],
                                  [-255.793, -29.922],
                                  [-254.512, 149.603],
                                  [-103.747, 276.02],
                                  [116.571, 365.579],
                                  [327.877, 266.788],
                                  [157.306, -25.714],
                                  [213.805, -168.044],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.312, -11.375],
                                  [13.004, -80.522],
                                  [25.469, -70.023],
                                  [-76.428, -40.475],
                                  [-15.461, -43.563],
                                  [-158.719, 23.328],
                                  [60.846, 63.561],
                                  [-14.961, 85.414],
                                  [-17.141, 92],
                                ],
                                o: [
                                  [-84.766, 18.484],
                                  [-13.004, 80.522],
                                  [-25.469, 70.023],
                                  [76.428, 40.475],
                                  [22.063, 51.688],
                                  [106.922, -12.156],
                                  [-60.846, -63.561],
                                  [5.313, -94.047],
                                  [17.141, -92],
                                ],
                                v: [
                                  [-45.82, -340.848],
                                  [-184.615, -221.354],
                                  [-255.849, -30.302],
                                  [-254.314, 149.801],
                                  [-104.22, 275.714],
                                  [116.593, 365.128],
                                  [328.44, 266.657],
                                  [157.709, -25.443],
                                  [213.195, -167.333],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.46, -11.081],
                                  [13.359, -80.12],
                                  [25.219, -70.27],
                                  [-75.982, -40.521],
                                  [-15.443, -43.357],
                                  [-159.057, 23.483],
                                  [60.243, 63.413],
                                  [-14.707, 84.903],
                                  [-17.207, 92],
                                ],
                                o: [
                                  [-84.655, 18.242],
                                  [-13.359, 80.12],
                                  [-25.219, 70.27],
                                  [75.982, 40.521],
                                  [22.092, 51.54],
                                  [106.885, -12.23],
                                  [-60.243, -63.412],
                                  [4.989, -93.598],
                                  [17.207, -92],
                                ],
                                v: [
                                  [-46.191, -340.535],
                                  [-184.783, -221.864],
                                  [-255.93, -30.688],
                                  [-254.056, 149.98],
                                  [-104.769, 275.367],
                                  [116.614, 364.646],
                                  [329.001, 266.562],
                                  [158.124, -25.127],
                                  [212.557, -166.649],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.608, -10.785],
                                  [13.716, -79.716],
                                  [24.967, -70.518],
                                  [-75.533, -40.569],
                                  [-15.424, -43.149],
                                  [-159.397, 23.638],
                                  [59.638, 63.264],
                                  [-14.452, 84.389],
                                  [-17.273, 92],
                                ],
                                o: [
                                  [-84.544, 17.998],
                                  [-13.716, 79.716],
                                  [-24.967, 70.518],
                                  [75.533, 40.569],
                                  [22.121, 51.392],
                                  [106.848, -12.304],
                                  [-59.638, -63.264],
                                  [4.663, -93.147],
                                  [17.273, -92],
                                ],
                                v: [
                                  [-46.562, -340.178],
                                  [-184.925, -222.41],
                                  [-256.003, -31.08],
                                  [-253.804, 150.2],
                                  [-105.329, 275.011],
                                  [116.635, 364.261],
                                  [329.559, 266.408],
                                  [158.517, -24.766],
                                  [211.89, -165.961],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.756, -10.488],
                                  [14.074, -79.31],
                                  [24.715, -70.767],
                                  [-75.083, -40.616],
                                  [-15.405, -42.941],
                                  [-159.739, 23.794],
                                  [59.03, 63.114],
                                  [-14.196, 83.872],
                                  [-17.34, 92],
                                ],
                                o: [
                                  [-84.433, 17.752],
                                  [-14.074, 79.31],
                                  [-24.715, 70.767],
                                  [75.083, 40.616],
                                  [22.151, 51.244],
                                  [106.811, -12.378],
                                  [-59.03, -63.114],
                                  [4.337, -92.694],
                                  [17.34, -92],
                                ],
                                v: [
                                  [-46.931, -339.806],
                                  [-185.041, -222.962],
                                  [-256.101, -31.474],
                                  [-253.556, 150.398],
                                  [-105.931, 274.613],
                                  [116.658, 363.879],
                                  [330.053, 266.228],
                                  [158.954, -24.359],
                                  [211.289, -165.269],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.905, -10.189],
                                  [14.434, -78.902],
                                  [24.461, -71.017],
                                  [-74.63, -40.664],
                                  [-15.387, -42.732],
                                  [-160.083, 23.951],
                                  [58.418, 62.964],
                                  [-13.938, 83.354],
                                  [-17.407, 92],
                                ],
                                o: [
                                  [-84.321, 17.506],
                                  [-14.434, 78.902],
                                  [-24.461, 71.017],
                                  [74.63, 40.664],
                                  [22.181, 51.094],
                                  [106.774, -12.453],
                                  [-58.418, -62.964],
                                  [4.008, -92.238],
                                  [17.407, -92],
                                ],
                                v: [
                                  [-47.332, -339.358],
                                  [-185.129, -223.488],
                                  [-256.223, -31.871],
                                  [-253.283, 150.606],
                                  [-106.544, 274.206],
                                  [116.744, 363.5],
                                  [330.544, 266.021],
                                  [159.402, -23.937],
                                  [210.629, -164.575],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.056, -9.889],
                                  [14.796, -78.492],
                                  [24.206, -71.268],
                                  [-74.176, -40.711],
                                  [-15.368, -42.522],
                                  [-160.428, 24.108],
                                  [57.804, 62.813],
                                  [-13.679, 82.832],
                                  [-17.475, 92],
                                ],
                                o: [
                                  [-84.208, 17.258],
                                  [-14.796, 78.492],
                                  [-24.206, 71.268],
                                  [74.176, 40.711],
                                  [22.211, 50.945],
                                  [106.736, -12.528],
                                  [-57.804, -62.813],
                                  [3.678, -91.781],
                                  [17.475, -92],
                                ],
                                v: [
                                  [-47.732, -338.927],
                                  [-185.193, -224.022],
                                  [-256.334, -32.304],
                                  [-253.018, 150.824],
                                  [-107.199, 273.788],
                                  [116.83, 363.185],
                                  [330.971, 265.821],
                                  [159.832, -23.469],
                                  [209.94, -163.908],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.206, -9.588],
                                  [15.16, -78.08],
                                  [23.949, -71.52],
                                  [-73.719, -40.759],
                                  [-15.349, -42.311],
                                  [-160.774, 24.267],
                                  [57.187, 62.661],
                                  [-13.419, 82.308],
                                  [-17.543, 92],
                                ],
                                o: [
                                  [-84.095, 17.01],
                                  [-15.16, 78.08],
                                  [-23.949, 71.52],
                                  [73.719, 40.76],
                                  [22.241, 50.794],
                                  [106.699, -12.603],
                                  [-57.187, -62.661],
                                  [3.346, -91.321],
                                  [17.543, -92],
                                ],
                                v: [
                                  [-48.129, -338.422],
                                  [-185.258, -224.561],
                                  [-256.47, -32.704],
                                  [-252.696, 151.082],
                                  [-107.864, 273.361],
                                  [116.888, 362.878],
                                  [331.367, 265.53],
                                  [160.244, -22.956],
                                  [209.253, -163.24],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.358, -9.285],
                                  [15.525, -77.667],
                                  [23.692, -71.774],
                                  [-73.259, -40.808],
                                  [-15.33, -42.099],
                                  [-161.123, 24.426],
                                  [56.567, 62.509],
                                  [-13.158, 81.782],
                                  [-17.611, 92],
                                ],
                                o: [
                                  [-83.982, 16.76],
                                  [-15.525, 77.667],
                                  [-23.692, 71.774],
                                  [73.259, 40.808],
                                  [22.272, 50.642],
                                  [106.661, -12.679],
                                  [-56.567, -62.509],
                                  [3.013, -90.859],
                                  [17.611, -92],
                                ],
                                v: [
                                  [-48.557, -337.904],
                                  [-185.265, -225.14],
                                  [-256.627, -33.137],
                                  [-252.385, 151.288],
                                  [-108.541, 272.923],
                                  [117.008, 362.606],
                                  [331.727, 265.278],
                                  [160.697, -22.425],
                                  [208.565, -162.604],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.51, -8.98],
                                  [15.893, -77.251],
                                  [23.433, -72.029],
                                  [-72.798, -40.856],
                                  [-15.311, -41.886],
                                  [-161.473, 24.585],
                                  [55.944, 62.356],
                                  [-12.895, 81.253],
                                  [-17.679, 92],
                                ],
                                o: [
                                  [-83.868, 16.509],
                                  [-15.893, 77.251],
                                  [-23.433, 72.029],
                                  [72.798, 40.856],
                                  [22.302, 50.49],
                                  [106.623, -12.755],
                                  [-55.944, -62.356],
                                  [2.678, -90.395],
                                  [17.679, -92],
                                ],
                                v: [
                                  [-48.979, -337.339],
                                  [-185.247, -225.635],
                                  [-256.743, -33.6],
                                  [-252.08, 151.499],
                                  [-109.258, 272.475],
                                  [117.101, 362.373],
                                  [332.051, 264.968],
                                  [161.168, -21.821],
                                  [207.85, -161.931],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.663, -8.674],
                                  [16.262, -76.833],
                                  [23.173, -72.285],
                                  [-72.335, -40.905],
                                  [-15.292, -41.672],
                                  [-161.824, 24.746],
                                  [55.318, 62.202],
                                  [-12.632, 80.722],
                                  [-17.748, 92],
                                ],
                                o: [
                                  [-83.753, 16.256],
                                  [-16.262, 76.833],
                                  [-23.173, 72.285],
                                  [72.335, 40.905],
                                  [22.333, 50.337],
                                  [106.584, -12.831],
                                  [-55.318, -62.202],
                                  [2.342, -89.928],
                                  [17.748, -92],
                                ],
                                v: [
                                  [-49.372, -336.731],
                                  [-185.229, -226.199],
                                  [-256.879, -34.032],
                                  [-251.754, 151.752],
                                  [-109.985, 272.017],
                                  [117.224, 362.178],
                                  [332.349, 264.601],
                                  [161.651, -21.197],
                                  [207.166, -161.289],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.816, -8.367],
                                  [16.632, -76.414],
                                  [22.912, -72.542],
                                  [-71.869, -40.954],
                                  [-15.273, -41.457],
                                  [-162.178, 24.907],
                                  [54.689, 62.047],
                                  [-12.367, 80.188],
                                  [-17.817, 92],
                                ],
                                o: [
                                  [-83.638, 16.003],
                                  [-16.632, 76.414],
                                  [-22.912, 72.543],
                                  [71.869, 40.954],
                                  [22.363, 50.184],
                                  [106.546, -12.908],
                                  [-54.689, -62.047],
                                  [2.004, -89.46],
                                  [17.817, -92],
                                ],
                                v: [
                                  [-49.762, -336.109],
                                  [-185.186, -226.741],
                                  [-257.039, -34.493],
                                  [-251.44, 151.976],
                                  [-110.752, 271.548],
                                  [117.353, 362.022],
                                  [332.605, 264.24],
                                  [162.147, -20.494],
                                  [206.421, -160.65],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.971, -8.058],
                                  [17.005, -75.992],
                                  [22.65, -72.801],
                                  [-71.401, -41.003],
                                  [-15.254, -41.241],
                                  [-162.533, 25.069],
                                  [54.057, 61.892],
                                  [-12.1, 79.652],
                                  [-17.887, 92],
                                ],
                                o: [
                                  [-83.522, 15.748],
                                  [-17.005, 75.992],
                                  [-22.65, 72.801],
                                  [71.401, 41.003],
                                  [22.394, 50.029],
                                  [106.507, -12.985],
                                  [-54.057, -61.892],
                                  [1.664, -88.989],
                                  [17.887, -92],
                                ],
                                v: [
                                  [-50.124, -335.479],
                                  [-185.114, -227.297],
                                  [-257.185, -34.949],
                                  [-251.073, 152.18],
                                  [-111.495, 271.07],
                                  [117.547, 361.872],
                                  [332.837, 263.883],
                                  [162.657, -19.776],
                                  [205.673, -160.038],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.126, -7.748],
                                  [17.379, -75.569],
                                  [22.386, -73.061],
                                  [-70.931, -41.053],
                                  [-15.234, -41.024],
                                  [-162.89, 25.232],
                                  [53.422, 61.736],
                                  [-11.833, 79.113],
                                  [-17.957, 92],
                                ],
                                o: [
                                  [-83.406, 15.492],
                                  [-17.379, 75.569],
                                  [-22.386, 73.061],
                                  [70.931, 41.053],
                                  [22.425, 49.874],
                                  [106.468, -13.063],
                                  [-53.422, -61.736],
                                  [1.323, -88.516],
                                  [17.957, -92],
                                ],
                                v: [
                                  [-50.478, -334.768],
                                  [-184.983, -227.828],
                                  [-257.327, -35.433],
                                  [-250.749, 152.385],
                                  [-112.283, 270.552],
                                  [117.709, 361.796],
                                  [332.999, 263.505],
                                  [163.214, -19.008],
                                  [204.926, -159.431],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.282, -7.437],
                                  [17.755, -75.143],
                                  [22.121, -73.322],
                                  [-70.459, -41.102],
                                  [-15.215, -40.806],
                                  [-163.248, 25.396],
                                  [52.784, 61.579],
                                  [-11.564, 78.571],
                                  [-18.027, 92],
                                ],
                                o: [
                                  [-83.289, 15.235],
                                  [-17.755, 75.143],
                                  [-22.121, 73.322],
                                  [70.459, 41.102],
                                  [22.456, 49.718],
                                  [106.43, -13.141],
                                  [-52.784, -61.579],
                                  [0.98, -88.041],
                                  [18.027, -92],
                                ],
                                v: [
                                  [-50.774, -334.049],
                                  [-184.883, -228.373],
                                  [-257.453, -35.909],
                                  [-250.406, 152.599],
                                  [-113.047, 270.053],
                                  [117.941, 361.728],
                                  [333.13, 263.07],
                                  [163.757, -18.188],
                                  [204.145, -158.823],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.438, -7.124],
                                  [18.132, -74.716],
                                  [21.855, -73.584],
                                  [-69.984, -41.152],
                                  [-15.195, -40.586],
                                  [-163.608, 25.56],
                                  [52.144, 61.422],
                                  [-11.294, 78.027],
                                  [-18.097, 92],
                                ],
                                o: [
                                  [-83.171, 14.977],
                                  [-18.132, 74.716],
                                  [-21.855, 73.584],
                                  [69.984, 41.152],
                                  [22.488, 49.562],
                                  [106.39, -13.219],
                                  [-52.144, -61.422],
                                  [0.636, -87.563],
                                  [18.097, -92],
                                ],
                                v: [
                                  [-51.07, -333.345],
                                  [-184.69, -228.928],
                                  [-257.596, -36.349],
                                  [-250.01, 152.813],
                                  [-113.883, 269.543],
                                  [118.141, 361.7],
                                  [333.26, 262.606],
                                  [164.342, -17.359],
                                  [203.364, -158.222],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.595, -6.809],
                                  [18.512, -74.286],
                                  [21.588, -73.847],
                                  [-69.507, -41.202],
                                  [-15.176, -40.366],
                                  [-163.97, 25.725],
                                  [51.5, 61.263],
                                  [-11.023, 77.481],
                                  [-18.168, 92],
                                ],
                                o: [
                                  [-83.053, 14.717],
                                  [-18.512, 74.286],
                                  [-21.588, 73.847],
                                  [69.507, 41.202],
                                  [22.519, 49.404],
                                  [106.351, -13.298],
                                  [-51.5, -61.263],
                                  [0.29, -87.084],
                                  [18.168, -92],
                                ],
                                v: [
                                  [-51.365, -332.572],
                                  [-184.535, -229.492],
                                  [-257.764, -36.814],
                                  [-249.633, 152.937],
                                  [-114.721, 269.03],
                                  [118.413, 361.681],
                                  [333.324, 262.155],
                                  [164.976, -16.443],
                                  [202.549, -157.62],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.753, -6.493],
                                  [18.893, -73.855],
                                  [21.319, -74.112],
                                  [-69.028, -41.253],
                                  [-15.156, -40.145],
                                  [-164.333, 25.891],
                                  [50.853, 61.104],
                                  [-10.75, 76.932],
                                  [-18.239, 92],
                                ],
                                o: [
                                  [-82.935, 14.457],
                                  [-18.893, 73.855],
                                  [-21.319, 74.112],
                                  [69.028, 41.253],
                                  [22.551, 49.247],
                                  [106.312, -13.377],
                                  [-50.853, -61.104],
                                  [-0.058, -86.602],
                                  [18.239, -92],
                                ],
                                v: [
                                  [-51.597, -331.787],
                                  [-184.312, -230.039],
                                  [-257.859, -37.303],
                                  [-249.266, 153.156],
                                  [-115.577, 268.472],
                                  [118.653, 361.633],
                                  [333.388, 261.676],
                                  [165.625, -15.546],
                                  [201.762, -157.055],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.912, -6.176],
                                  [19.276, -73.421],
                                  [21.049, -74.378],
                                  [-68.547, -41.303],
                                  [-15.136, -39.923],
                                  [-164.698, 26.058],
                                  [50.203, 60.945],
                                  [-10.476, 76.38],
                                  [-18.311, 92],
                                ],
                                o: [
                                  [-82.816, 14.195],
                                  [-19.276, 73.421],
                                  [-21.049, 74.378],
                                  [68.547, 41.303],
                                  [22.582, 49.088],
                                  [106.272, -13.456],
                                  [-50.203, -60.945],
                                  [-0.407, -86.118],
                                  [18.311, -92],
                                ],
                                v: [
                                  [-51.828, -330.989],
                                  [-184.066, -230.569],
                                  [-257.998, -37.753],
                                  [-248.919, 153.283],
                                  [-116.435, 267.966],
                                  [118.958, 361.627],
                                  [333.386, 261.166],
                                  [166.288, -14.569],
                                  [200.911, -156.49],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.072, -5.857],
                                  [19.66, -72.985],
                                  [20.778, -74.645],
                                  [-68.064, -41.354],
                                  [-15.116, -39.7],
                                  [-165.065, 26.225],
                                  [49.55, 60.784],
                                  [-10.201, 75.826],
                                  [-18.382, 92],
                                ],
                                o: [
                                  [-82.696, 13.932],
                                  [-19.66, 72.985],
                                  [-20.778, 74.645],
                                  [68.064, 41.354],
                                  [22.614, 48.928],
                                  [106.232, -13.536],
                                  [-49.55, -60.784],
                                  [-0.758, -85.631],
                                  [18.382, -92],
                                ],
                                v: [
                                  [-52.022, -330.178],
                                  [-183.813, -231.172],
                                  [-258.134, -38.19],
                                  [-248.548, 153.442],
                                  [-117.328, 267.424],
                                  [119.265, 361.629],
                                  [333.356, 260.637],
                                  [167.002, -13.576],
                                  [200.087, -155.925],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.232, -5.536],
                                  [20.047, -72.548],
                                  [20.506, -74.913],
                                  [-67.578, -41.405],
                                  [-15.096, -39.475],
                                  [-165.433, 26.393],
                                  [48.894, 60.623],
                                  [-9.925, 75.269],
                                  [-18.454, 92],
                                ],
                                o: [
                                  [-82.576, 13.667],
                                  [-20.047, 72.548],
                                  [-20.506, 74.913],
                                  [67.578, 41.405],
                                  [22.646, 48.768],
                                  [106.192, -13.616],
                                  [-48.894, -60.623],
                                  [-1.11, -85.143],
                                  [18.454, -92],
                                ],
                                v: [
                                  [-52.189, -329.301],
                                  [-183.562, -231.723],
                                  [-258.224, -38.622],
                                  [-248.224, 153.633],
                                  [-118.204, 266.871],
                                  [119.575, 361.639],
                                  [333.262, 260.087],
                                  [167.694, -12.529],
                                  [199.225, -155.408],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.393, -5.214],
                                  [20.435, -72.108],
                                  [20.232, -75.183],
                                  [-67.09, -41.456],
                                  [-15.076, -39.25],
                                  [-165.804, 26.563],
                                  [48.235, 60.461],
                                  [-9.647, 74.71],
                                  [-18.527, 92],
                                ],
                                o: [
                                  [-82.455, 13.402],
                                  [-20.435, 72.108],
                                  [-20.232, 75.183],
                                  [67.09, 41.456],
                                  [22.679, 48.607],
                                  [106.152, -13.696],
                                  [-48.235, -60.461],
                                  [-1.464, -84.652],
                                  [18.527, -92],
                                ],
                                v: [
                                  [-52.329, -328.439],
                                  [-183.324, -232.258],
                                  [-258.34, -39.075],
                                  [-247.851, 153.792],
                                  [-119.115, 266.309],
                                  [119.924, 361.594],
                                  [333.167, 259.569],
                                  [168.438, -11.476],
                                  [198.363, -154.83],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.555, -4.891],
                                  [20.825, -71.667],
                                  [19.957, -75.454],
                                  [-66.6, -41.508],
                                  [-15.056, -39.024],
                                  [-166.176, 26.732],
                                  [47.573, 60.298],
                                  [-9.368, 74.148],
                                  [-18.6, 92],
                                ],
                                o: [
                                  [-82.334, 13.135],
                                  [-20.825, 71.667],
                                  [-19.957, 75.454],
                                  [66.6, 41.508],
                                  [22.711, 48.445],
                                  [106.111, -13.777],
                                  [-47.573, -60.298],
                                  [-1.82, -84.159],
                                  [18.6, -92],
                                ],
                                v: [
                                  [-52.431, -327.574],
                                  [-183.078, -232.794],
                                  [-258.433, -39.512],
                                  [-247.5, 153.918],
                                  [-119.999, 265.747],
                                  [120.302, 361.619],
                                  [333.033, 258.996],
                                  [169.198, -10.369],
                                  [197.488, -154.317],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.717, -4.566],
                                  [21.217, -71.223],
                                  [19.681, -75.726],
                                  [-66.108, -41.56],
                                  [-15.035, -38.796],
                                  [-166.549, 26.903],
                                  [46.909, 60.135],
                                  [-9.088, 73.583],
                                  [-18.673, 92],
                                ],
                                o: [
                                  [-82.212, 12.867],
                                  [-21.217, 71.223],
                                  [-19.681, 75.726],
                                  [66.108, 41.56],
                                  [22.743, 48.283],
                                  [106.071, -13.859],
                                  [-46.909, -60.135],
                                  [-2.177, -83.663],
                                  [18.673, -92],
                                ],
                                v: [
                                  [-52.497, -326.636],
                                  [-182.808, -233.351],
                                  [-258.49, -39.905],
                                  [-247.189, 154.073],
                                  [-120.953, 265.165],
                                  [120.657, 361.614],
                                  [332.846, 258.404],
                                  [170.035, -9.207],
                                  [196.622, -153.818],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.88, -4.24],
                                  [21.611, -70.777],
                                  [19.404, -75.999],
                                  [-65.613, -41.612],
                                  [-15.015, -38.568],
                                  [-166.924, 27.074],
                                  [46.24, 59.971],
                                  [-8.807, 73.016],
                                  [-18.746, 92],
                                ],
                                o: [
                                  [-82.09, 12.598],
                                  [-21.611, 70.777],
                                  [-19.404, 75.999],
                                  [65.613, 41.612],
                                  [22.776, 48.12],
                                  [106.03, -13.94],
                                  [-46.24, -59.971],
                                  [-2.536, -83.165],
                                  [18.746, -92],
                                ],
                                v: [
                                  [-52.535, -325.723],
                                  [-182.604, -233.882],
                                  [-258.598, -40.317],
                                  [-246.877, 154.195],
                                  [-121.854, 264.647],
                                  [121.04, 361.654],
                                  [332.658, 257.806],
                                  [170.825, -8.04],
                                  [195.743, -153.359],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.044, -3.912],
                                  [22.006, -70.329],
                                  [19.125, -76.274],
                                  [-65.116, -41.664],
                                  [-14.994, -38.338],
                                  [-167.301, 27.246],
                                  [45.569, 59.806],
                                  [-8.524, 72.447],
                                  [-18.82, 92],
                                ],
                                o: [
                                  [-81.967, 12.327],
                                  [-22.006, 70.329],
                                  [-19.125, 76.274],
                                  [65.116, 41.664],
                                  [22.809, 47.956],
                                  [105.989, -14.022],
                                  [-45.569, -59.806],
                                  [-2.897, -82.665],
                                  [18.82, -92],
                                ],
                                v: [
                                  [-52.573, -324.798],
                                  [-182.403, -234.387],
                                  [-258.67, -40.683],
                                  [-246.568, 154.319],
                                  [-122.826, 264.058],
                                  [121.451, 361.639],
                                  [332.432, 257.227],
                                  [171.694, -6.856],
                                  [194.862, -152.842],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.209, -3.582],
                                  [22.404, -69.88],
                                  [18.845, -76.55],
                                  [-64.617, -41.717],
                                  [-14.974, -38.108],
                                  [-167.68, 27.419],
                                  [44.895, 59.64],
                                  [-8.24, 71.874],
                                  [-18.894, 92],
                                ],
                                o: [
                                  [-81.843, 12.055],
                                  [-22.404, 69.88],
                                  [-18.845, 76.55],
                                  [64.617, 41.717],
                                  [22.842, 47.791],
                                  [105.948, -14.104],
                                  [-44.895, -59.64],
                                  [-3.259, -82.163],
                                  [18.894, -92],
                                ],
                                v: [
                                  [-52.548, -323.838],
                                  [-182.166, -234.915],
                                  [-258.693, -41.042],
                                  [-246.247, 154.47],
                                  [-123.769, 263.522],
                                  [121.878, 361.657],
                                  [332.179, 256.617],
                                  [172.578, -5.617],
                                  [193.965, -152.404],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.374, -3.252],
                                  [22.803, -69.428],
                                  [18.564, -76.827],
                                  [-64.116, -41.769],
                                  [-14.953, -37.876],
                                  [-168.061, 27.593],
                                  [44.218, 59.473],
                                  [-7.954, 71.3],
                                  [-18.968, 92],
                                ],
                                o: [
                                  [-81.719, 11.783],
                                  [-22.803, 69.428],
                                  [-18.564, 76.827],
                                  [64.116, 41.769],
                                  [22.875, 47.626],
                                  [105.906, -14.187],
                                  [-44.218, -59.473],
                                  [-3.623, -81.659],
                                  [18.968, -92],
                                ],
                                v: [
                                  [-52.483, -322.867],
                                  [-181.97, -235.379],
                                  [-258.767, -41.416],
                                  [-245.978, 154.548],
                                  [-124.706, 262.952],
                                  [122.293, 361.658],
                                  [331.862, 256.027],
                                  [173.478, -4.375],
                                  [193.079, -151.971],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.54, -2.919],
                                  [23.204, -68.974],
                                  [18.281, -77.105],
                                  [-63.612, -41.822],
                                  [-14.932, -37.643],
                                  [-168.443, 27.767],
                                  [43.538, 59.306],
                                  [-7.668, 70.722],
                                  [-19.043, 92],
                                ],
                                o: [
                                  [-81.595, 11.508],
                                  [-23.204, 68.974],
                                  [-18.281, 77.105],
                                  [63.612, 41.822],
                                  [22.908, 47.46],
                                  [105.865, -14.27],
                                  [-43.538, -59.306],
                                  [-3.989, -81.152],
                                  [19.043, -92],
                                ],
                                v: [
                                  [-52.394, -321.86],
                                  [-181.777, -235.892],
                                  [-258.805, -41.768],
                                  [-245.686, 154.69],
                                  [-125.626, 262.373],
                                  [122.726, 361.653],
                                  [331.521, 255.431],
                                  [174.456, -3.077],
                                  [192.176, -151.519],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.707, -2.585],
                                  [23.606, -68.518],
                                  [17.997, -77.385],
                                  [-63.106, -41.875],
                                  [-14.912, -37.41],
                                  [-168.827, 27.943],
                                  [42.854, 59.138],
                                  [-7.38, 70.142],
                                  [-19.118, 92],
                                ],
                                o: [
                                  [-81.469, 11.233],
                                  [-23.606, 68.518],
                                  [-17.997, 77.385],
                                  [63.106, 41.875],
                                  [22.941, 47.293],
                                  [105.823, -14.354],
                                  [-42.854, -59.138],
                                  [-4.356, -80.643],
                                  [19.118, -92],
                                ],
                                v: [
                                  [-52.265, -320.858],
                                  [-181.61, -236.377],
                                  [-258.832, -42.11],
                                  [-245.385, 154.794],
                                  [-126.578, 261.785],
                                  [123.146, 361.669],
                                  [331.178, 254.818],
                                  [175.412, -1.738],
                                  [191.27, -151.073],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.875, -2.25],
                                  [24.011, -68.06],
                                  [17.712, -77.666],
                                  [-62.598, -41.929],
                                  [-14.891, -37.175],
                                  [-169.213, 28.119],
                                  [42.168, 58.969],
                                  [-7.091, 69.559],
                                  [-19.194, 92],
                                ],
                                o: [
                                  [-81.344, 10.956],
                                  [-24.011, 68.06],
                                  [-17.712, 77.666],
                                  [62.598, 41.929],
                                  [22.975, 47.125],
                                  [105.781, -14.438],
                                  [-42.168, -58.969],
                                  [-4.725, -80.131],
                                  [19.194, -92],
                                ],
                                v: [
                                  [-52.111, -319.869],
                                  [-181.422, -236.872],
                                  [-258.825, -42.403],
                                  [-245.161, 154.883],
                                  [-127.537, 261.228],
                                  [123.593, 361.679],
                                  [330.835, 254.212],
                                  [176.383, -0.421],
                                  [190.346, -150.671],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.043, -1.913],
                                  [24.417, -67.6],
                                  [17.426, -77.948],
                                  [-62.087, -41.983],
                                  [-14.87, -36.939],
                                  [-169.6, 28.296],
                                  [41.478, 58.8],
                                  [-6.8, 68.974],
                                  [-19.27, 92],
                                ],
                                o: [
                                  [-81.217, 10.678],
                                  [-24.417, 67.6],
                                  [-17.426, 77.948],
                                  [62.087, 41.983],
                                  [23.009, 46.956],
                                  [105.739, -14.522],
                                  [-41.478, -58.8],
                                  [-5.096, -79.617],
                                  [19.27, -92],
                                ],
                                v: [
                                  [-51.917, -318.824],
                                  [-181.26, -237.316],
                                  [-258.869, -42.685],
                                  [-244.89, 154.973],
                                  [-128.464, 260.661],
                                  [124.057, 361.672],
                                  [330.406, 253.612],
                                  [177.408, 0.975],
                                  [189.456, -150.291],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.213, -1.575],
                                  [24.826, -67.138],
                                  [17.138, -78.231],
                                  [-61.574, -42.037],
                                  [-14.848, -36.702],
                                  [-169.989, 28.473],
                                  [40.786, 58.63],
                                  [-6.508, 68.386],
                                  [-19.346, 92],
                                ],
                                o: [
                                  [-81.09, 10.399],
                                  [-24.826, 67.138],
                                  [-17.138, 78.231],
                                  [61.574, 42.037],
                                  [23.043, 46.787],
                                  [105.697, -14.606],
                                  [-40.786, -58.63],
                                  [-5.468, -79.101],
                                  [19.346, -92],
                                ],
                                v: [
                                  [-51.699, -317.809],
                                  [-181.123, -237.794],
                                  [-258.879, -42.941],
                                  [-244.673, 155.062],
                                  [-129.421, 260.126],
                                  [124.508, 361.696],
                                  [329.976, 253.021],
                                  [178.473, 2.348],
                                  [188.483, -149.895],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.383, -1.235],
                                  [25.236, -66.674],
                                  [16.849, -78.516],
                                  [-61.059, -42.091],
                                  [-14.827, -36.464],
                                  [-170.38, 28.652],
                                  [40.09, 58.459],
                                  [-6.215, 67.795],
                                  [-19.422, 92],
                                ],
                                o: [
                                  [-80.963, 10.119],
                                  [-25.236, 66.674],
                                  [-16.849, 78.516],
                                  [61.059, 42.091],
                                  [23.077, 46.617],
                                  [105.654, -14.691],
                                  [-40.09, -58.459],
                                  [-5.842, -78.583],
                                  [19.422, -92],
                                ],
                                v: [
                                  [-51.481, -316.785],
                                  [-180.966, -238.22],
                                  [-258.879, -43.208],
                                  [-244.433, 155.134],
                                  [-130.322, 259.56],
                                  [124.96, 361.704],
                                  [329.547, 252.414],
                                  [179.554, 3.777],
                                  [187.546, -149.53],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.553, -0.893],
                                  [25.648, -66.207],
                                  [16.559, -78.802],
                                  [-60.541, -42.145],
                                  [-14.806, -36.225],
                                  [-170.773, 28.831],
                                  [39.391, 58.287],
                                  [-5.92, 67.202],
                                  [-19.499, 92],
                                ],
                                o: [
                                  [-80.835, 9.837],
                                  [-25.648, 66.207],
                                  [-16.559, 78.802],
                                  [60.541, 42.145],
                                  [23.111, 46.447],
                                  [105.612, -14.777],
                                  [-39.391, -58.287],
                                  [-6.217, -78.062],
                                  [19.499, -92],
                                ],
                                v: [
                                  [-51.221, -315.73],
                                  [-180.833, -238.656],
                                  [-258.846, -43.439],
                                  [-244.248, 155.165],
                                  [-131.252, 259.008],
                                  [125.414, 361.703],
                                  [329.032, 251.837],
                                  [180.673, 5.205],
                                  [186.586, -149.127],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.725, -0.55],
                                  [26.061, -65.739],
                                  [16.268, -79.089],
                                  [-60.021, -42.2],
                                  [-14.784, -35.985],
                                  [-171.167, 29.011],
                                  [38.689, 58.114],
                                  [-5.625, 66.606],
                                  [-19.576, 92],
                                ],
                                o: [
                                  [-80.706, 9.554],
                                  [-26.061, 65.739],
                                  [-16.268, 79.089],
                                  [60.021, 42.2],
                                  [23.145, 46.275],
                                  [105.569, -14.862],
                                  [-38.689, -58.114],
                                  [-6.595, -77.539],
                                  [19.576, -92],
                                ],
                                v: [
                                  [-50.939, -314.667],
                                  [-180.724, -239.062],
                                  [-258.826, -43.681],
                                  [-244.061, 155.216],
                                  [-132.188, 258.467],
                                  [125.87, 361.686],
                                  [328.54, 251.268],
                                  [181.767, 6.649],
                                  [185.621, -148.795],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.897, -0.206],
                                  [26.477, -65.268],
                                  [15.975, -79.378],
                                  [-59.499, -42.255],
                                  [-14.763, -35.744],
                                  [-171.563, 29.192],
                                  [37.984, 57.941],
                                  [-5.327, 66.007],
                                  [-19.654, 92],
                                ],
                                o: [
                                  [-80.577, 9.27],
                                  [-26.477, 65.268],
                                  [-15.975, 79.378],
                                  [59.499, 42.255],
                                  [23.179, 46.103],
                                  [105.526, -14.949],
                                  [-37.984, -57.941],
                                  [-6.974, -77.014],
                                  [19.654, -92],
                                ],
                                v: [
                                  [-50.593, -313.636],
                                  [-180.617, -239.479],
                                  [-258.813, -43.87],
                                  [-243.908, 155.264],
                                  [-133.089, 257.918],
                                  [126.309, 361.637],
                                  [328.004, 250.684],
                                  [182.939, 8.13],
                                  [184.673, -148.45],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.07, 0.14],
                                  [26.894, -64.796],
                                  [15.681, -79.668],
                                  [-58.975, -42.31],
                                  [-14.741, -35.502],
                                  [-171.961, 29.374],
                                  [37.275, 57.767],
                                  [-5.029, 65.406],
                                  [-19.732, 92],
                                ],
                                o: [
                                  [-80.447, 8.984],
                                  [-26.894, 64.796],
                                  [-15.681, 79.668],
                                  [58.975, 42.31],
                                  [23.214, 45.93],
                                  [105.482, -15.035],
                                  [-37.275, -57.767],
                                  [-7.354, -76.486],
                                  [19.732, -92],
                                ],
                                v: [
                                  [-50.246, -312.554],
                                  [-180.511, -239.905],
                                  [-258.769, -44.046],
                                  [-243.73, 155.31],
                                  [-133.995, 257.403],
                                  [126.768, 361.615],
                                  [327.468, 250.089],
                                  [184.126, 9.649],
                                  [183.655, -148.114],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.244, 0.488],
                                  [27.314, -64.321],
                                  [15.385, -79.959],
                                  [-58.448, -42.365],
                                  [-14.719, -35.258],
                                  [-172.361, 29.556],
                                  [36.564, 57.592],
                                  [-4.729, 64.802],
                                  [-19.81, 92],
                                ],
                                o: [
                                  [-80.317, 8.697],
                                  [-27.314, 64.321],
                                  [-15.385, 79.959],
                                  [58.448, 42.365],
                                  [23.249, 45.756],
                                  [105.439, -15.122],
                                  [-36.564, -57.592],
                                  [-7.737, -75.956],
                                  [19.81, -92],
                                ],
                                v: [
                                  [-49.858, -311.486],
                                  [-180.428, -240.322],
                                  [-258.716, -44.208],
                                  [-243.59, 155.334],
                                  [-134.906, 256.922],
                                  [127.21, 361.579],
                                  [326.934, 249.521],
                                  [185.349, 11.161],
                                  [182.654, -147.808],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.419, 0.837],
                                  [27.735, -63.844],
                                  [15.089, -80.251],
                                  [-57.919, -42.421],
                                  [-14.698, -35.014],
                                  [-172.763, 29.739],
                                  [35.849, 57.416],
                                  [-4.428, 64.196],
                                  [-19.888, 92],
                                ],
                                o: [
                                  [-80.186, 8.409],
                                  [-27.735, 63.844],
                                  [-15.089, 80.251],
                                  [57.919, 42.421],
                                  [23.284, 45.581],
                                  [105.395, -15.209],
                                  [-35.849, -57.416],
                                  [-8.121, -75.423],
                                  [19.888, -92],
                                ],
                                v: [
                                  [-49.447, -310.431],
                                  [-180.345, -240.686],
                                  [-258.654, -44.337],
                                  [-243.445, 155.335],
                                  [-135.782, 256.394],
                                  [127.652, 361.55],
                                  [326.338, 248.961],
                                  [186.628, 12.689],
                                  [181.625, -147.49],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.594, 1.188],
                                  [28.158, -63.365],
                                  [14.79, -80.545],
                                  [-57.387, -42.477],
                                  [-14.676, -34.769],
                                  [-173.166, 29.924],
                                  [35.131, 57.24],
                                  [-4.126, 63.586],
                                  [-19.967, 92],
                                ],
                                o: [
                                  [-80.055, 8.12],
                                  [-28.158, 63.365],
                                  [-14.79, 80.545],
                                  [57.387, 42.477],
                                  [23.319, 45.406],
                                  [105.352, -15.297],
                                  [-35.131, -57.24],
                                  [-8.507, -74.889],
                                  [19.967, -92],
                                ],
                                v: [
                                  [-48.974, -309.35],
                                  [-180.284, -241.059],
                                  [-258.562, -44.451],
                                  [-243.336, 155.33],
                                  [-136.725, 255.942],
                                  [128.074, 361.508],
                                  [325.764, 248.431],
                                  [187.901, 14.232],
                                  [180.63, -147.204],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.77, 1.54],
                                  [28.583, -62.884],
                                  [14.491, -80.84],
                                  [-56.854, -42.533],
                                  [-14.654, -34.522],
                                  [-173.571, 30.108],
                                  [34.41, 57.063],
                                  [-3.822, 62.974],
                                  [-20.047, 92],
                                ],
                                o: [
                                  [-79.923, 7.83],
                                  [-28.583, 62.884],
                                  [-14.491, 80.84],
                                  [56.853, 42.533],
                                  [23.354, 45.23],
                                  [105.307, -15.385],
                                  [-34.41, -57.063],
                                  [-8.894, -74.352],
                                  [20.047, -92],
                                ],
                                v: [
                                  [-48.499, -308.262],
                                  [-180.244, -241.399],
                                  [-258.502, -44.593],
                                  [-243.263, 155.321],
                                  [-137.568, 255.485],
                                  [128.496, 361.451],
                                  [325.149, 247.908],
                                  [189.21, 15.788],
                                  [179.544, -146.909],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.947, 1.894],
                                  [29.009, -62.401],
                                  [14.19, -81.136],
                                  [-56.317, -42.589],
                                  [-14.632, -34.274],
                                  [-173.978, 30.294],
                                  [33.686, 56.885],
                                  [-3.517, 62.36],
                                  [-20.126, 92],
                                ],
                                o: [
                                  [-79.79, 7.538],
                                  [-29.009, 62.401],
                                  [-14.19, 81.136],
                                  [56.317, 42.589],
                                  [23.389, 45.053],
                                  [105.263, -15.473],
                                  [-33.686, -56.885],
                                  [-9.283, -73.812],
                                  [20.126, -92],
                                ],
                                v: [
                                  [-47.982, -307.211],
                                  [-180.204, -241.768],
                                  [-258.373, -44.678],
                                  [-243.206, 155.327],
                                  [-138.478, 255.043],
                                  [128.897, 361.379],
                                  [324.516, 247.372],
                                  [190.532, 17.379],
                                  [178.492, -146.604],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.125, 2.249],
                                  [29.438, -61.916],
                                  [13.888, -81.434],
                                  [-55.779, -42.646],
                                  [-14.609, -34.026],
                                  [-174.386, 30.481],
                                  [32.959, 56.706],
                                  [-3.21, 61.742],
                                  [-20.206, 92],
                                ],
                                o: [
                                  [-79.657, 7.245],
                                  [-29.438, 61.916],
                                  [-13.888, 81.434],
                                  [55.779, 42.646],
                                  [23.425, 44.875],
                                  [105.219, -15.562],
                                  [-32.959, -56.706],
                                  [-9.674, -73.27],
                                  [20.206, -92],
                                ],
                                v: [
                                  [-47.444, -306.177],
                                  [-180.141, -242.103],
                                  [-258.277, -44.705],
                                  [-243.164, 155.306],
                                  [-139.33, 254.597],
                                  [129.338, 361.292],
                                  [323.886, 246.908],
                                  [191.888, 18.941],
                                  [177.41, -146.354],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.303, 2.606],
                                  [29.868, -61.429],
                                  [13.585, -81.733],
                                  [-55.238, -42.703],
                                  [-14.587, -33.776],
                                  [-174.797, 30.668],
                                  [32.228, 56.526],
                                  [-2.902, 61.122],
                                  [-20.286, 92],
                                ],
                                o: [
                                  [-79.523, 6.95],
                                  [-29.868, 61.429],
                                  [-13.585, 81.732],
                                  [55.238, 42.703],
                                  [23.461, 44.697],
                                  [105.174, -15.651],
                                  [-32.228, -56.526],
                                  [-10.067, -72.726],
                                  [20.286, -92],
                                ],
                                v: [
                                  [-46.862, -305.119],
                                  [-180.119, -242.445],
                                  [-258.154, -44.76],
                                  [-243.095, 155.278],
                                  [-140.206, 254.209],
                                  [129.717, 361.189],
                                  [323.237, 246.409],
                                  [193.277, 20.536],
                                  [176.318, -146.073],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.482, 2.964],
                                  [30.301, -60.939],
                                  [13.28, -82.033],
                                  [-54.695, -42.76],
                                  [-14.565, -33.525],
                                  [-175.209, 30.856],
                                  [31.495, 56.346],
                                  [-2.593, 60.499],
                                  [-20.367, 92],
                                ],
                                o: [
                                  [-79.388, 6.654],
                                  [-30.301, 60.939],
                                  [-13.28, 82.033],
                                  [54.695, 42.76],
                                  [23.496, 44.518],
                                  [105.129, -15.741],
                                  [-31.495, -56.346],
                                  [-10.461, -72.179],
                                  [20.367, -92],
                                ],
                                v: [
                                  [-46.26, -304.077],
                                  [-180.116, -242.753],
                                  [-258.023, -44.799],
                                  [-243.08, 155.201],
                                  [-141.066, 253.797],
                                  [130.051, 361.071],
                                  [322.591, 245.961],
                                  [194.698, 22.144],
                                  [175.194, -145.828],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.662, 3.324],
                                  [30.735, -60.448],
                                  [12.974, -82.334],
                                  [-54.149, -42.817],
                                  [-14.542, -33.273],
                                  [-175.623, 31.045],
                                  [30.758, 56.165],
                                  [-2.283, 59.874],
                                  [-20.448, 92],
                                ],
                                o: [
                                  [-79.253, 6.357],
                                  [-30.735, 60.448],
                                  [-12.974, 82.334],
                                  [54.149, 42.817],
                                  [23.532, 44.338],
                                  [105.084, -15.831],
                                  [-30.758, -56.165],
                                  [-10.857, -71.63],
                                  [20.448, -92],
                                ],
                                v: [
                                  [-45.613, -303.078],
                                  [-180.131, -243.067],
                                  [-257.823, -44.824],
                                  [-243.06, 155.135],
                                  [-141.95, 253.466],
                                  [130.446, 360.935],
                                  [321.928, 245.522],
                                  [196.108, 23.74],
                                  [174.04, -145.597],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.843, 3.686],
                                  [31.171, -59.954],
                                  [12.667, -82.637],
                                  [-53.601, -42.875],
                                  [-14.52, -33.02],
                                  [-176.039, 31.235],
                                  [30.018, 55.983],
                                  [-1.971, 59.246],
                                  [-20.529, 92],
                                ],
                                o: [
                                  [-79.118, 6.059],
                                  [-31.171, 59.954],
                                  [-12.667, 82.637],
                                  [53.601, 42.875],
                                  [23.569, 44.157],
                                  [105.039, -15.922],
                                  [-30.018, -55.983],
                                  [-11.255, -71.079],
                                  [20.529, -92],
                                ],
                                v: [
                                  [-44.923, -302.054],
                                  [-180.119, -243.364],
                                  [-257.659, -44.832],
                                  [-243.092, 155.081],
                                  [-142.775, 253.133],
                                  [130.753, 360.804],
                                  [321.269, 245.11],
                                  [197.572, 25.367],
                                  [172.915, -145.36],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.025, 4.049],
                                  [31.609, -59.458],
                                  [12.358, -82.941],
                                  [-53.05, -42.933],
                                  [-14.497, -32.766],
                                  [-176.457, 31.426],
                                  [29.274, 55.8],
                                  [-1.658, 58.615],
                                  [-20.611, 92],
                                ],
                                o: [
                                  [-78.982, 5.759],
                                  [-31.609, 59.458],
                                  [-12.358, 82.941],
                                  [53.05, 42.933],
                                  [23.605, 43.975],
                                  [104.994, -16.012],
                                  [-29.274, -55.8],
                                  [-11.654, -70.525],
                                  [20.611, -92],
                                ],
                                v: [
                                  [-44.211, -301.051],
                                  [-180.147, -243.686],
                                  [-257.495, -44.782],
                                  [-243.138, 155.018],
                                  [-143.623, 252.841],
                                  [131.101, 360.677],
                                  [320.613, 244.688],
                                  [199.085, 26.961],
                                  [171.759, -145.137],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.207, 4.414],
                                  [32.049, -58.96],
                                  [12.048, -83.247],
                                  [-52.498, -42.991],
                                  [-14.474, -32.51],
                                  [-176.876, 31.617],
                                  [28.528, 55.617],
                                  [-1.343, 57.981],
                                  [-20.693, 92],
                                ],
                                o: [
                                  [-78.845, 5.459],
                                  [-32.049, 58.96],
                                  [-12.048, 83.247],
                                  [52.498, 42.991],
                                  [23.641, 43.793],
                                  [104.948, -16.104],
                                  [-28.528, -55.617],
                                  [-12.055, -69.969],
                                  [20.693, -92],
                                ],
                                v: [
                                  [-43.437, -300.073],
                                  [-180.192, -243.97],
                                  [-257.3, -44.759],
                                  [-243.216, 154.921],
                                  [-144.455, 252.546],
                                  [131.377, 360.511],
                                  [319.967, 244.337],
                                  [200.61, 28.609],
                                  [170.568, -144.909],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.39, 4.78],
                                  [32.491, -58.46],
                                  [11.737, -83.553],
                                  [-51.942, -43.049],
                                  [-14.451, -32.254],
                                  [-177.297, 31.81],
                                  [27.778, 55.432],
                                  [-1.027, 57.344],
                                  [-20.776, 92],
                                ],
                                o: [
                                  [-78.707, 5.156],
                                  [-32.491, 58.46],
                                  [-11.737, 83.553],
                                  [51.942, 43.049],
                                  [23.678, 43.61],
                                  [104.902, -16.195],
                                  [-27.778, -55.432],
                                  [-12.458, -69.41],
                                  [20.776, -92],
                                ],
                                v: [
                                  [-42.634, -299.156],
                                  [-180.224, -244.214],
                                  [-257.062, -44.721],
                                  [-243.28, 154.815],
                                  [-145.309, 252.275],
                                  [131.676, 360.305],
                                  [319.3, 243.995],
                                  [202.136, 30.198],
                                  [169.365, -144.653],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.574, 5.148],
                                  [32.935, -57.957],
                                  [11.424, -83.862],
                                  [-51.385, -43.108],
                                  [-14.428, -31.996],
                                  [-177.72, 32.003],
                                  [27.025, 55.247],
                                  [-0.71, 56.705],
                                  [-20.858, 92],
                                ],
                                o: [
                                  [-78.569, 4.853],
                                  [-32.935, 57.957],
                                  [-11.424, 83.862],
                                  [51.385, 43.108],
                                  [23.715, 43.426],
                                  [104.857, -16.287],
                                  [-27.025, -55.247],
                                  [-12.863, -68.849],
                                  [20.858, -92],
                                ],
                                v: [
                                  [-41.812, -298.202],
                                  [-180.252, -244.48],
                                  [-256.819, -44.712],
                                  [-243.375, 154.698],
                                  [-146.148, 252.065],
                                  [131.9, 360.102],
                                  [318.619, 243.706],
                                  [203.735, 31.815],
                                  [168.123, -144.455],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.759, 5.518],
                                  [33.381, -57.453],
                                  [11.11, -84.171],
                                  [-50.825, -43.167],
                                  [-14.405, -31.738],
                                  [-178.145, 32.197],
                                  [26.269, 55.062],
                                  [-0.391, 56.063],
                                  [-20.941, 92],
                                ],
                                o: [
                                  [-78.431, 4.548],
                                  [-33.381, 57.453],
                                  [-11.11, 84.171],
                                  [50.825, 43.167],
                                  [23.752, 43.241],
                                  [104.81, -16.379],
                                  [-26.269, -55.061],
                                  [-13.269, -68.286],
                                  [20.941, -92],
                                ],
                                v: [
                                  [-40.925, -297.312],
                                  [-180.338, -244.724],
                                  [-256.554, -44.642],
                                  [-243.499, 154.562],
                                  [-146.992, 251.881],
                                  [132.166, 359.921],
                                  [317.988, 243.445],
                                  [205.297, 33.44],
                                  [166.869, -144.273],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.944, 5.889],
                                  [33.828, -56.946],
                                  [10.795, -84.482],
                                  [-50.262, -43.226],
                                  [-14.382, -31.478],
                                  [-178.572, 32.392],
                                  [25.509, 54.875],
                                  [-0.071, 55.418],
                                  [-21.025, 92],
                                ],
                                o: [
                                  [-78.292, 4.242],
                                  [-33.828, 56.946],
                                  [-10.795, 84.482],
                                  [50.262, 43.226],
                                  [23.789, 43.056],
                                  [104.764, -16.472],
                                  [-25.509, -54.875],
                                  [-13.678, -67.72],
                                  [21.025, -92],
                                ],
                                v: [
                                  [-39.992, -296.405],
                                  [-180.372, -244.969],
                                  [-256.329, -44.601],
                                  [-243.652, 154.46],
                                  [-147.811, 251.76],
                                  [132.354, 359.68],
                                  [317.345, 243.192],
                                  [206.868, 35.046],
                                  [165.601, -144.088],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.131, 6.261],
                                  [34.278, -56.437],
                                  [10.478, -84.794],
                                  [-49.698, -43.285],
                                  [-14.359, -31.217],
                                  [-179.001, 32.587],
                                  [24.746, 54.687],
                                  [0.25, 54.771],
                                  [-21.109, 92],
                                ],
                                o: [
                                  [-78.152, 3.934],
                                  [-34.278, 56.437],
                                  [-10.478, 84.794],
                                  [49.698, 43.285],
                                  [23.826, 42.869],
                                  [104.717, -16.565],
                                  [-24.746, -54.687],
                                  [-14.087, -67.151],
                                  [21.109, -92],
                                ],
                                v: [
                                  [-39.056, -295.573],
                                  [-180.436, -245.189],
                                  [-256.037, -44.528],
                                  [-243.786, 154.3],
                                  [-148.661, 251.621],
                                  [132.502, 359.397],
                                  [316.724, 242.994],
                                  [208.465, 36.66],
                                  [164.337, -143.903],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.318, 6.636],
                                  [34.729, -55.926],
                                  [10.16, -85.107],
                                  [-49.13, -43.345],
                                  [-14.335, -30.955],
                                  [-179.431, 32.784],
                                  [23.98, 54.499],
                                  [0.573, 54.121],
                                  [-21.193, 92],
                                ],
                                o: [
                                  [-78.012, 3.626],
                                  [-34.729, 55.926],
                                  [-10.16, 85.107],
                                  [49.13, 43.345],
                                  [23.864, 42.682],
                                  [104.671, -16.659],
                                  [-23.98, -54.499],
                                  [-14.499, -66.581],
                                  [21.193, -92],
                                ],
                                v: [
                                  [-38.072, -294.761],
                                  [-180.509, -245.382],
                                  [-255.725, -44.503],
                                  [-244.009, 154.146],
                                  [-149.469, 251.529],
                                  [132.629, 359.1],
                                  [316.121, 242.868],
                                  [210.132, 38.315],
                                  [162.997, -143.705],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.506, 7.011],
                                  [35.183, -55.412],
                                  [9.84, -85.422],
                                  [-48.561, -43.405],
                                  [-14.312, -30.692],
                                  [-179.863, 32.981],
                                  [23.211, 54.31],
                                  [0.897, 53.468],
                                  [-21.278, 92],
                                ],
                                o: [
                                  [-77.871, 3.316],
                                  [-35.183, 55.412],
                                  [-9.84, 85.422],
                                  [48.561, 43.405],
                                  [23.901, 42.494],
                                  [104.624, -16.753],
                                  [-23.211, -54.31],
                                  [-14.913, -66.008],
                                  [21.278, -92],
                                ],
                                v: [
                                  [-37.023, -293.934],
                                  [-180.59, -245.592],
                                  [-255.409, -44.399],
                                  [-244.149, 153.933],
                                  [-150.327, 251.483],
                                  [132.706, 358.743],
                                  [315.495, 242.752],
                                  [211.699, 39.885],
                                  [161.676, -143.552],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.694, 7.389],
                                  [35.638, -54.897],
                                  [9.519, -85.738],
                                  [-47.989, -43.465],
                                  [-14.288, -30.428],
                                  [-180.297, 33.179],
                                  [22.438, 54.12],
                                  [1.223, 52.812],
                                  [-21.363, 92],
                                ],
                                o: [
                                  [-77.729, 3.004],
                                  [-35.638, 54.897],
                                  [-9.519, 85.738],
                                  [47.989, 43.465],
                                  [23.939, 42.306],
                                  [104.576, -16.847],
                                  [-22.438, -54.12],
                                  [-15.328, -65.432],
                                  [21.362, -92],
                                ],
                                v: [
                                  [-35.971, -293.187],
                                  [-180.68, -245.817],
                                  [-255.057, -44.373],
                                  [-244.359, 153.77],
                                  [-151.172, 251.486],
                                  [132.7, 358.39],
                                  [314.905, 242.646],
                                  [213.351, 41.506],
                                  [160.324, -143.354],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [129.884, 7.768],
                                  [36.095, -54.379],
                                  [9.197, -86.056],
                                  [-47.414, -43.526],
                                  [-14.264, -30.162],
                                  [-180.733, 33.378],
                                  [21.662, 53.929],
                                  [1.55, 52.153],
                                  [-21.448, 92],
                                ],
                                o: [
                                  [-77.587, 2.691],
                                  [-36.095, 54.379],
                                  [-9.197, 86.056],
                                  [47.414, 43.526],
                                  [23.977, 42.116],
                                  [104.529, -16.942],
                                  [-21.662, -53.929],
                                  [-15.745, -64.854],
                                  [21.448, -92],
                                ],
                                v: [
                                  [-34.87, -292.508],
                                  [-180.73, -245.995],
                                  [-254.765, -44.333],
                                  [-244.546, 153.546],
                                  [-151.991, 251.522],
                                  [132.674, 358.008],
                                  [314.368, 242.658],
                                  [215.011, 43.119],
                                  [158.926, -143.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.074, 8.149],
                                  [36.554, -53.859],
                                  [8.874, -86.375],
                                  [-46.837, -43.586],
                                  [-14.241, -29.896],
                                  [-181.171, 33.578],
                                  [20.883, 53.738],
                                  [1.878, 51.492],
                                  [-21.533, 92],
                                ],
                                o: [
                                  [-77.444, 2.377],
                                  [-36.554, 53.859],
                                  [-8.874, 86.374],
                                  [46.837, 43.586],
                                  [24.015, 41.926],
                                  [104.481, -17.037],
                                  [-20.883, -53.738],
                                  [-16.163, -64.273],
                                  [21.533, -92],
                                ],
                                v: [
                                  [-33.702, -291.817],
                                  [-180.832, -246.156],
                                  [-254.393, -44.263],
                                  [-244.74, 153.356],
                                  [-152.844, 251.624],
                                  [132.598, 357.537],
                                  [313.84, 242.681],
                                  [216.63, 44.689],
                                  [157.513, -142.976],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.265, 8.531],
                                  [37.015, -53.337],
                                  [8.549, -86.695],
                                  [-46.258, -43.647],
                                  [-14.217, -29.628],
                                  [-181.611, 33.779],
                                  [20.101, 53.545],
                                  [2.208, 50.827],
                                  [-21.619, 92],
                                ],
                                o: [
                                  [-77.301, 2.062],
                                  [-37.015, 53.337],
                                  [-8.549, 86.695],
                                  [46.258, 43.647],
                                  [24.053, 41.734],
                                  [104.434, -17.133],
                                  [-20.101, -53.545],
                                  [-16.584, -63.69],
                                  [21.619, -92],
                                ],
                                v: [
                                  [-32.531, -291.164],
                                  [-180.892, -246.313],
                                  [-254.004, -44.227],
                                  [-244.956, 153.105],
                                  [-153.717, 251.714],
                                  [132.44, 357.117],
                                  [313.319, 242.715],
                                  [218.304, 46.263],
                                  [156.083, -142.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.457, 8.915],
                                  [37.479, -52.813],
                                  [8.222, -87.016],
                                  [-45.676, -43.708],
                                  [-14.193, -29.36],
                                  [-182.052, 33.98],
                                  [19.315, 53.352],
                                  [2.539, 50.16],
                                  [-21.706, 92],
                                ],
                                o: [
                                  [-77.157, 1.745],
                                  [-37.479, 52.813],
                                  [-8.222, 87.016],
                                  [45.676, 43.708],
                                  [24.092, 41.543],
                                  [104.386, -17.229],
                                  [-19.315, -53.352],
                                  [-17.006, -63.105],
                                  [21.706, -92],
                                ],
                                v: [
                                  [-31.309, -290.581],
                                  [-180.956, -246.435],
                                  [-253.613, -44.224],
                                  [-245.146, 152.84],
                                  [-154.579, 251.918],
                                  [132.264, 356.59],
                                  [312.886, 242.87],
                                  [219.951, 47.822],
                                  [154.652, -142.636],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.65, 9.3],
                                  [37.944, -52.287],
                                  [7.895, -87.339],
                                  [-45.092, -43.77],
                                  [-14.169, -29.09],
                                  [-182.495, 34.183],
                                  [18.526, 53.158],
                                  [2.872, 49.491],
                                  [-21.793, 92],
                                ],
                                o: [
                                  [-77.012, 1.427],
                                  [-37.944, 52.287],
                                  [-7.895, 87.339],
                                  [45.092, 43.77],
                                  [24.13, 41.35],
                                  [104.337, -17.325],
                                  [-18.526, -53.158],
                                  [-17.43, -62.517],
                                  [21.793, -92],
                                ],
                                v: [
                                  [-30.034, -290.037],
                                  [-181.007, -246.504],
                                  [-253.174, -44.209],
                                  [-245.355, 152.56],
                                  [-155.445, 252.113],
                                  [132.022, 356.1],
                                  [312.43, 243.05],
                                  [221.619, 49.384],
                                  [153.238, -142.412],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [130.844, 9.688],
                                  [38.411, -51.758],
                                  [7.566, -87.663],
                                  [-44.505, -43.831],
                                  [-14.145, -28.819],
                                  [-182.941, 34.386],
                                  [17.733, 52.963],
                                  [3.206, 48.818],
                                  [-21.88, 92],
                                ],
                                o: [
                                  [-76.867, 1.108],
                                  [-38.411, 51.758],
                                  [-7.566, 87.663],
                                  [44.505, 43.831],
                                  [24.169, 41.156],
                                  [104.289, -17.422],
                                  [-17.733, -52.964],
                                  [-17.856, -61.927],
                                  [21.88, -92],
                                ],
                                v: [
                                  [-28.755, -289.55],
                                  [-181.06, -246.568],
                                  [-252.752, -44.264],
                                  [-245.569, 152.218],
                                  [-156.316, 252.361],
                                  [131.763, 355.504],
                                  [312.016, 243.338],
                                  [223.291, 50.899],
                                  [151.823, -142.206],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.038, 10.076],
                                  [38.88, -51.227],
                                  [7.235, -87.989],
                                  [-43.916, -43.893],
                                  [-14.12, -28.547],
                                  [-183.388, 34.59],
                                  [16.938, 52.768],
                                  [3.541, 48.142],
                                  [-21.967, 92],
                                ],
                                o: [
                                  [-76.721, 0.787],
                                  [-38.88, 51.227],
                                  [-7.235, 87.989],
                                  [43.916, 43.893],
                                  [24.208, 40.962],
                                  [104.24, -17.519],
                                  [-16.938, -52.768],
                                  [-18.284, -61.334],
                                  [21.967, -92],
                                ],
                                v: [
                                  [-27.423, -289.105],
                                  [-181.115, -246.655],
                                  [-252.33, -44.307],
                                  [-245.787, 151.924],
                                  [-157.226, 252.663],
                                  [131.454, 354.912],
                                  [311.66, 243.637],
                                  [224.969, 52.381],
                                  [150.391, -142.017],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [131.233, 10.467],
                                  [39.35, -50.694],
                                  [6.903, -88.316],
                                  [-43.324, -43.956],
                                  [-14.096, -28.273],
                                  [-183.837, 34.795],
                                  [16.138, 52.571],
                                  [3.878, 47.464],
                                  [-22.055, 92],
                                ],
                                o: [
                                  [-76.575, 0.465],
                                  [-39.35, 50.694],
                                  [-6.903, 88.316],
                                  [43.324, 43.956],
                                  [24.247, 40.767],
                                  [104.192, -17.617],
                                  [-16.138, -52.571],
                                  [-18.713, -60.738],
                                  [22.055, -92],
                                ],
                                v: [
                                  [-26.023, -288.733],
                                  [-181.156, -246.687],
                                  [-251.846, -44.387],
                                  [-245.974, 151.601],
                                  [-158.141, 253.07],
                                  [131.065, 354.324],
                                  [311.314, 243.963],
                                  [226.604, 53.877],
                                  [149.006, -141.796],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [29.844, 32.656],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.469, 31.781],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.094, 30.938],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 29.125],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.188, 28.25],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.906, 27.406],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.688, 26.562],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.469, 25.781],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.312, 25],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.125, 24.25],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27, 23.5],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.875, 22.875],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.75, 22.219],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 21.594],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.656, 20.969],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.625, 20.375],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 19.844],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.625, 19.312],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.625, 18.844],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.5, 18.344],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.312, 17.469],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, 17.062],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 16.656],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 16.344],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.969, 15.969],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.938, 15.688],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.906, 15.375],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.875, 15.094],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 14.844],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.906, 14.594],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.906, 14.375],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.938, 14.156],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 13.969],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 13.812],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.219, 13.656],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.281, 13.5],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.469, 13.312],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 13.219],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.719, 13.094],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.844, 13.031],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.969, 13],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, 12.938],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.219, 12.938],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.344, 12.938],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.438, 13],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.688, 13.188],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.844, 13.25],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, 13.375],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.281, 13.688],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.656, 14.062],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.906, 14.25],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.156, 14.469],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.375, 14.688],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.625, 14.906],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.875, 15.156],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.156, 15.375],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.406, 15.625],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.688, 15.938],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.969, 16.125],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.219, 16.438],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.5, 16.688],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.781, 16.969],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.094, 17.25],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.375, 17.5],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.625, 17.781],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.906, 18.062],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 18.344],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.375, 18.625],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 18.906],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 19.188],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.062, 19.438],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.906, 19.719],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.781, 20],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.594, 20.219],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.406, 20.5],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.25, 20.75],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.875, 21.25],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.656, 21.469],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.469, 21.719],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.219, 21.938],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31, 22.094],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 22.312],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.531, 22.562],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.281, 22.75],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.031, 23],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 23.188],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.5, 23.438],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.25, 23.656],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29, 23.844],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.75, 24.062],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.438, 24.281],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.688, 24.844],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.438, 25.062],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.188, 25.188],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.75, 25.5],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.562, 25.656],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.375, 25.781],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.156, 25.906],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.969, 26.094],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 26.219],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.656, 26.312],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.5, 26.469],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.344, 26.562],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.219, 26.688],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.094, 26.75],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25, 26.844],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.875, 26.969],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.75, 27],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.688, 27.031],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 27.062],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 27.094],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 27.219],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.562, 27.25],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.562, 27.25],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 27.219],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.625, 27.156],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.656, 27.062],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 26.938],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.812, 26.812],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.906, 26.625],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25, 26.469],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.094, 26.25],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.25, 26.062],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.344, 25.812],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.531, 25.562],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.656, 25.312],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 25],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.062, 24.719],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, 24.375],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.469, 24.062],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.906, 23.312],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.094, 22.938],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.406, 22.531],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.594, 22.125],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.875, 21.719],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.375, 20.906],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.688, 20.469],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.969, 20.062],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.25, 19.625],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.562, 19.219],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.875, 18.781],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.125, 18.375],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.438, 17.938],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 17.531],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.031, 17.125],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.344, 16.688],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.656, 16.281],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.969, 15.906],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.25, 15.531],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.5, 15.188],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.781, 14.812],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 14.5],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.75, 13.875],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.094, 13.594],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.406, 13.312],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.656, 13.062],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.969, 12.844],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.25, 12.656],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.75, 12.344],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.031, 12.219],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.531, 12.031],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.781, 12.031],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.969, 12.031],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.156, 12.062],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.344, 12.125],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.438, 12.219],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.562, 12.375],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.656, 12.531],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.75, 12.719],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 12.938],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.812, 13.188],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, 13.531],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, 13.844],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.812, 14.156],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 14.562],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.75, 14.906],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.625, 15.719],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.5, 16.156],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.438, 16.562],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.188, 17.5],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 17.938],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.875, 18.406],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 18.938],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 19.406],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.344, 19.875],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.188, 20.406],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36, 20.906],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.75, 21.438],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.562, 21.938],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.344, 22.438],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.094, 22.969],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.875, 23.5],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.594, 24],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.344, 24.531],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.125, 25.031],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.844, 25.562],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.594, 26.062],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.312, 26.594],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.062, 27.094],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.562, 28.031],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.281, 28.5],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.062, 28.938],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.781, 29.375],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.562, 29.812],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.312, 30.219],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.125, 30.594],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, 30.969],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.531, 31.656],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.344, 31.906],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.188, 32.188],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.031, 32.406],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.906, 32.594],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.812, 32.75],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.719, 32.875],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.656, 32.969],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.625, 33],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.625, 33.031],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.625, 32.969],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.688, 32.906],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.719, 32.781],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [29.844, 32.656],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.469, 31.781],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.094, 30.938],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 29.125],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.188, 28.25],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.906, 27.406],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.688, 26.562],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.469, 25.781],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.312, 25],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.125, 24.25],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27, 23.5],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.875, 22.875],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.75, 22.219],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 21.594],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.656, 20.969],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.625, 20.375],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 19.844],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.625, 19.312],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.625, 18.844],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.5, 18.344],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.312, 17.469],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, 17.062],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 16.656],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 16.344],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.969, 15.969],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.938, 15.688],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.906, 15.375],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.875, 15.094],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 14.844],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.906, 14.594],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.906, 14.375],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.938, 14.156],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 13.969],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 13.812],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.219, 13.656],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.281, 13.5],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.469, 13.312],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 13.219],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.719, 13.094],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.844, 13.031],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.969, 13],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, 12.938],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.219, 12.938],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.344, 12.938],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.438, 13],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.688, 13.188],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.844, 13.25],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, 13.375],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.281, 13.688],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.656, 14.062],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.906, 14.25],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.156, 14.469],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.375, 14.688],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.625, 14.906],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.875, 15.156],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.156, 15.375],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.406, 15.625],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.688, 15.938],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.969, 16.125],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.219, 16.438],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.5, 16.688],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.781, 16.969],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.094, 17.25],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.375, 17.5],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.625, 17.781],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.906, 18.062],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 18.344],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.375, 18.625],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 18.906],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 19.188],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.062, 19.438],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.906, 19.719],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.781, 20],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.594, 20.219],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.406, 20.5],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.25, 20.75],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.875, 21.25],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.656, 21.469],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.469, 21.719],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.219, 21.938],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31, 22.094],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 22.312],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.531, 22.562],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.281, 22.75],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.031, 23],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.781, 23.188],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.5, 23.438],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.25, 23.656],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29, 23.844],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.75, 24.062],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.438, 24.281],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.688, 24.844],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.438, 25.062],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.188, 25.188],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.75, 25.5],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.562, 25.656],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.375, 25.781],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.156, 25.906],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.969, 26.094],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 26.219],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.656, 26.312],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.5, 26.469],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.344, 26.562],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.219, 26.688],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.094, 26.75],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25, 26.844],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.875, 26.969],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.75, 27],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.688, 27.031],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 27.062],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 27.094],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 27.219],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.562, 27.25],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.562, 27.25],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 27.219],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.625, 27.156],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.656, 27.062],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 26.938],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.812, 26.812],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.906, 26.625],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25, 26.469],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.094, 26.25],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.25, 26.062],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.344, 25.812],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.531, 25.562],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.656, 25.312],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 25],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.062, 24.719],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, 24.375],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.469, 24.062],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.906, 23.312],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.094, 22.938],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.406, 22.531],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.594, 22.125],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.875, 21.719],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.375, 20.906],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.688, 20.469],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.969, 20.062],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.25, 19.625],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.562, 19.219],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.875, 18.781],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.125, 18.375],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.438, 17.938],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 17.531],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.031, 17.125],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.344, 16.688],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.656, 16.281],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.969, 15.906],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.25, 15.531],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.5, 15.188],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.781, 14.812],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 14.5],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.75, 13.875],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.094, 13.594],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.406, 13.312],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.656, 13.062],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.969, 12.844],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.25, 12.656],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.75, 12.344],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.031, 12.219],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.531, 12.031],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.781, 12.031],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.969, 12.031],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.156, 12.062],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.344, 12.125],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.438, 12.219],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.562, 12.375],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.656, 12.531],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.75, 12.719],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 12.938],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.812, 13.188],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, 13.531],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, 13.844],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.812, 14.156],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 14.562],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.75, 14.906],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.625, 15.719],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.5, 16.156],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.438, 16.562],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.188, 17.5],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 17.938],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.875, 18.406],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 18.938],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 19.406],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.344, 19.875],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.188, 20.406],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36, 20.906],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.75, 21.438],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.562, 21.938],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.344, 22.438],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.094, 22.969],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.875, 23.5],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.594, 24],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.344, 24.531],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.125, 25.031],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.844, 25.562],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.594, 26.062],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.312, 26.594],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.062, 27.094],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.562, 28.031],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.281, 28.5],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.062, 28.938],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.781, 29.375],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.562, 29.812],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.312, 30.219],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.125, 30.594],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, 30.969],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.531, 31.656],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.344, 31.906],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.188, 32.188],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.031, 32.406],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.906, 32.594],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.812, 32.75],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.719, 32.875],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.656, 32.969],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.625, 33],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.625, 33.031],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.625, 32.969],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.688, 32.906],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.719, 32.781],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 4',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [128.572, 5.144],
                                  [32.93, -57.962],
                                  [11.427, -83.858],
                                  [-51.39, -43.107],
                                  [-14.428, -31.999],
                                  [-177.716, 32.001],
                                  [27.032, 55.249],
                                  [-0.713, 56.712],
                                  [-20.858, 92],
                                ],
                                o: [
                                  [-78.571, 4.856],
                                  [-32.93, 57.962],
                                  [-11.427, 83.858],
                                  [51.39, 43.107],
                                  [23.714, 43.428],
                                  [104.857, -16.286],
                                  [-27.032, -55.249],
                                  [-12.859, -68.855],
                                  [20.857, -92],
                                ],
                                v: [
                                  [-34.988, -300.166],
                                  [-179.884, -239.324],
                                  [-253.696, -39.26],
                                  [-245.09, 150.973],
                                  [-143.37, 259.668],
                                  [132.401, 358.57],
                                  [306.555, 242.215],
                                  [205.926, 33.952],
                                  [160.371, -149.248],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.335, 4.67],
                                  [32.358, -58.61],
                                  [11.831, -83.461],
                                  [-52.11, -43.032],
                                  [-14.458, -32.331],
                                  [-177.17, 31.752],
                                  [28.004, 55.488],
                                  [-1.122, 57.536],
                                  [-20.751, 92],
                                ],
                                o: [
                                  [-78.749, 5.247],
                                  [-32.358, 58.61],
                                  [-11.831, 83.461],
                                  [52.11, 43.032],
                                  [23.667, 43.665],
                                  [104.916, -16.167],
                                  [-28.004, -55.488],
                                  [-12.337, -69.579],
                                  [20.751, -92],
                                ],
                                v: [
                                  [-35.171, -301.598],
                                  [-179.886, -238.329],
                                  [-253.72, -38.63],
                                  [-245.339, 150.852],
                                  [-141.936, 260.814],
                                  [132.116, 358.341],
                                  [305.729, 242.212],
                                  [204.507, 32.387],
                                  [161.298, -149.952],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.099, 4.198],
                                  [31.789, -59.255],
                                  [12.232, -83.066],
                                  [-52.825, -42.957],
                                  [-14.488, -32.661],
                                  [-176.628, 31.504],
                                  [28.97, 55.725],
                                  [-1.529, 58.356],
                                  [-20.645, 92],
                                ],
                                o: [
                                  [-78.926, 5.637],
                                  [-31.789, 59.255],
                                  [-12.232, 83.066],
                                  [52.825, 42.957],
                                  [23.62, 43.901],
                                  [104.975, -16.049],
                                  [-28.97, -55.725],
                                  [-11.818, -70.298],
                                  [20.645, -92],
                                ],
                                v: [
                                  [-35.308, -303.04],
                                  [-179.843, -237.409],
                                  [-253.757, -38.005],
                                  [-245.539, 150.751],
                                  [-140.443, 261.901],
                                  [131.791, 358.107],
                                  [304.936, 242.246],
                                  [203.086, 30.848],
                                  [162.232, -150.652],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.865, 3.729],
                                  [31.223, -59.895],
                                  [12.63, -82.673],
                                  [-53.535, -42.882],
                                  [-14.517, -32.99],
                                  [-176.089, 31.258],
                                  [29.929, 55.961],
                                  [-1.934, 59.17],
                                  [-20.539, 92],
                                ],
                                o: [
                                  [-79.102, 6.023],
                                  [-31.223, 59.895],
                                  [-12.63, 82.673],
                                  [53.535, 42.882],
                                  [23.573, 44.135],
                                  [105.034, -15.932],
                                  [-29.929, -55.961],
                                  [-11.302, -71.013],
                                  [20.539, -92],
                                ],
                                v: [
                                  [-35.463, -304.492],
                                  [-179.836, -236.456],
                                  [-253.755, -37.391],
                                  [-245.752, 150.673],
                                  [-138.869, 262.954],
                                  [131.436, 357.823],
                                  [304.194, 242.273],
                                  [201.656, 29.293],
                                  [163.175, -151.27],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.632, 3.263],
                                  [30.661, -60.531],
                                  [13.026, -82.283],
                                  [-54.241, -42.808],
                                  [-14.546, -33.316],
                                  [-175.553, 31.013],
                                  [30.883, 56.196],
                                  [-2.335, 59.98],
                                  [-20.434, 92],
                                ],
                                o: [
                                  [-79.276, 6.408],
                                  [-30.661, 60.531],
                                  [-13.026, 82.283],
                                  [54.241, 42.808],
                                  [23.526, 44.368],
                                  [105.092, -15.816],
                                  [-30.883, -56.196],
                                  [-10.79, -71.723],
                                  [20.434, -92],
                                ],
                                v: [
                                  [-35.616, -305.928],
                                  [-179.847, -235.472],
                                  [-253.744, -36.822],
                                  [-245.961, 150.639],
                                  [-137.302, 263.992],
                                  [131.04, 357.535],
                                  [303.46, 242.354],
                                  [200.287, 27.742],
                                  [164.149, -151.823],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.4, 2.8],
                                  [30.103, -61.163],
                                  [13.42, -81.895],
                                  [-54.943, -42.734],
                                  [-14.575, -33.64],
                                  [-175.021, 30.77],
                                  [31.83, 56.429],
                                  [-2.735, 60.784],
                                  [-20.33, 92],
                                ],
                                o: [
                                  [-79.45, 6.79],
                                  [-30.103, 61.163],
                                  [-13.42, 81.895],
                                  [54.943, 42.734],
                                  [23.48, 44.6],
                                  [105.15, -15.7],
                                  [-31.83, -56.429],
                                  [-10.28, -72.429],
                                  [20.33, -92],
                                ],
                                v: [
                                  [-35.791, -307.312],
                                  [-179.857, -234.563],
                                  [-253.676, -36.236],
                                  [-246.142, 150.625],
                                  [-135.698, 264.994],
                                  [130.62, 357.242],
                                  [302.759, 242.448],
                                  [198.892, 26.2],
                                  [165.192, -152.334],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.17, 2.34],
                                  [29.548, -61.791],
                                  [13.811, -81.51],
                                  [-55.64, -42.661],
                                  [-14.604, -33.962],
                                  [-174.491, 30.529],
                                  [32.772, 56.66],
                                  [-3.131, 61.583],
                                  [-20.227, 92],
                                ],
                                o: [
                                  [-79.622, 7.169],
                                  [-29.548, 61.791],
                                  [-13.811, 81.51],
                                  [55.64, 42.66],
                                  [23.434, 44.83],
                                  [105.207, -15.585],
                                  [-32.772, -56.66],
                                  [-9.774, -73.131],
                                  [20.227, -92],
                                ],
                                v: [
                                  [-36.004, -308.577],
                                  [-179.903, -233.624],
                                  [-253.594, -35.651],
                                  [-246.32, 150.61],
                                  [-134.039, 265.958],
                                  [130.132, 356.883],
                                  [302.046, 242.555],
                                  [197.514, 24.663],
                                  [166.199, -152.807],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.942, 1.883],
                                  [28.997, -62.416],
                                  [14.199, -81.127],
                                  [-56.333, -42.588],
                                  [-14.632, -34.282],
                                  [-173.966, 30.289],
                                  [33.708, 56.89],
                                  [-3.526, 62.378],
                                  [-20.124, 92],
                                ],
                                o: [
                                  [-79.794, 7.546],
                                  [-28.997, 62.416],
                                  [-14.199, 81.127],
                                  [56.333, 42.588],
                                  [23.388, 45.058],
                                  [105.265, -15.471],
                                  [-33.708, -56.89],
                                  [-9.272, -73.828],
                                  [20.124, -92],
                                ],
                                v: [
                                  [-36.238, -309.811],
                                  [-179.947, -232.697],
                                  [-253.478, -35.13],
                                  [-246.515, 150.593],
                                  [-132.365, 266.887],
                                  [129.639, 356.539],
                                  [301.404, 242.698],
                                  [196.11, 23.094],
                                  [167.296, -153.197],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.715, 1.429],
                                  [28.449, -63.036],
                                  [14.585, -80.747],
                                  [-57.021, -42.515],
                                  [-14.661, -34.6],
                                  [-173.443, 30.05],
                                  [34.637, 57.119],
                                  [-3.917, 63.167],
                                  [-20.022, 92],
                                ],
                                o: [
                                  [-79.964, 7.921],
                                  [-28.449, 63.036],
                                  [-14.585, 80.747],
                                  [57.021, 42.515],
                                  [23.343, 45.285],
                                  [105.321, -15.357],
                                  [-34.637, -57.118],
                                  [-8.772, -74.521],
                                  [20.022, -92],
                                ],
                                v: [
                                  [-36.532, -310.929],
                                  [-179.965, -231.804],
                                  [-253.347, -34.547],
                                  [-246.667, 150.593],
                                  [-130.659, 267.759],
                                  [129.078, 356.17],
                                  [300.732, 242.834],
                                  [194.787, 21.574],
                                  [168.337, -153.55],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.489, 0.978],
                                  [27.904, -63.652],
                                  [14.969, -80.369],
                                  [-57.706, -42.443],
                                  [-14.689, -34.916],
                                  [-172.924, 29.813],
                                  [35.561, 57.346],
                                  [-4.307, 63.951],
                                  [-19.92, 92],
                                ],
                                o: [
                                  [-80.133, 8.293],
                                  [-27.904, 63.652],
                                  [-14.969, 80.369],
                                  [57.706, 42.443],
                                  [23.298, 45.511],
                                  [105.378, -15.245],
                                  [-35.561, -57.346],
                                  [-8.276, -75.209],
                                  [19.92, -92],
                                ],
                                v: [
                                  [-36.864, -312.038],
                                  [-180.042, -230.862],
                                  [-253.202, -33.983],
                                  [-246.797, 150.652],
                                  [-128.962, 268.618],
                                  [128.491, 355.818],
                                  [300.174, 242.985],
                                  [193.419, 20.062],
                                  [169.446, -153.907],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.265, 0.529],
                                  [27.363, -64.265],
                                  [15.35, -79.993],
                                  [-58.385, -42.372],
                                  [-14.717, -35.229],
                                  [-172.409, 29.578],
                                  [36.479, 57.571],
                                  [-4.693, 64.73],
                                  [-19.819, 92],
                                ],
                                o: [
                                  [-80.301, 8.663],
                                  [-27.363, 64.265],
                                  [-15.35, 79.993],
                                  [58.385, 42.372],
                                  [23.253, 45.735],
                                  [105.434, -15.132],
                                  [-36.479, -57.571],
                                  [-7.782, -75.893],
                                  [19.819, -92],
                                ],
                                v: [
                                  [-37.194, -313.014],
                                  [-180.095, -229.955],
                                  [-253.022, -33.436],
                                  [-246.967, 150.685],
                                  [-127.274, 269.4],
                                  [127.899, 355.399],
                                  [299.543, 243.152],
                                  [192.068, 18.559],
                                  [170.559, -154.166],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.042, 0.084],
                                  [26.826, -64.873],
                                  [15.729, -79.62],
                                  [-59.061, -42.301],
                                  [-14.745, -35.541],
                                  [-171.896, 29.344],
                                  [37.391, 57.795],
                                  [-5.078, 65.505],
                                  [-19.719, 92],
                                ],
                                o: [
                                  [-80.469, 9.031],
                                  [-26.826, 64.873],
                                  [-15.729, 79.62],
                                  [59.061, 42.301],
                                  [23.208, 45.958],
                                  [105.49, -15.021],
                                  [-37.391, -57.795],
                                  [-7.292, -76.572],
                                  [19.719, -92],
                                ],
                                v: [
                                  [-37.561, -313.984],
                                  [-180.183, -229.062],
                                  [-252.808, -32.885],
                                  [-247.097, 150.795],
                                  [-125.514, 270.23],
                                  [127.279, 354.977],
                                  [298.985, 243.355],
                                  [190.716, 17.046],
                                  [171.697, -154.389],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.82, -0.359],
                                  [26.292, -65.478],
                                  [16.105, -79.249],
                                  [-59.732, -42.23],
                                  [-14.772, -35.851],
                                  [-171.387, 29.111],
                                  [38.298, 58.018],
                                  [-5.46, 66.274],
                                  [-19.619, 92],
                                ],
                                o: [
                                  [-80.635, 9.396],
                                  [-26.292, 65.478],
                                  [-16.105, 79.249],
                                  [59.732, 42.23],
                                  [23.164, 46.18],
                                  [105.545, -14.91],
                                  [-38.297, -58.018],
                                  [-6.805, -77.248],
                                  [19.619, -92],
                                ],
                                v: [
                                  [-37.946, -314.825],
                                  [-180.246, -228.182],
                                  [-252.598, -32.309],
                                  [-247.248, 150.876],
                                  [-123.782, 270.943],
                                  [126.59, 354.614],
                                  [298.458, 243.533],
                                  [189.4, 15.562],
                                  [172.86, -154.578],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.601, -0.799],
                                  [25.761, -66.079],
                                  [16.479, -78.881],
                                  [-60.399, -42.16],
                                  [-14.8, -36.159],
                                  [-170.881, 28.88],
                                  [39.198, 58.24],
                                  [-5.839, 67.038],
                                  [-19.52, 92],
                                ],
                                o: [
                                  [-80.8, 9.759],
                                  [-25.761, 66.079],
                                  [-16.479, 78.881],
                                  [60.399, 42.16],
                                  [23.12, 46.399],
                                  [105.6, -14.8],
                                  [-39.198, -58.24],
                                  [-6.321, -77.919],
                                  [19.52, -92],
                                ],
                                v: [
                                  [-38.368, -315.621],
                                  [-180.302, -227.276],
                                  [-252.352, -31.768],
                                  [-247.36, 150.97],
                                  [-122.003, 271.667],
                                  [125.895, 354.184],
                                  [297.943, 243.77],
                                  [188.044, 14.046],
                                  [174.045, -154.714],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.382, -1.236],
                                  [25.234, -66.676],
                                  [16.851, -78.515],
                                  [-61.061, -42.091],
                                  [-14.827, -36.465],
                                  [-170.378, 28.651],
                                  [40.093, 58.459],
                                  [-6.216, 67.798],
                                  [-19.422, 92],
                                ],
                                o: [
                                  [-80.964, 10.12],
                                  [-25.234, 66.676],
                                  [-16.851, 78.515],
                                  [61.061, 42.09],
                                  [23.076, 46.618],
                                  [105.655, -14.691],
                                  [-40.093, -58.459],
                                  [-5.84, -78.585],
                                  [19.422, -92],
                                ],
                                v: [
                                  [-38.826, -316.415],
                                  [-180.397, -226.424],
                                  [-252.074, -31.2],
                                  [-247.474, 151.077],
                                  [-120.274, 272.355],
                                  [125.171, 353.774],
                                  [297.481, 244.003],
                                  [186.723, 12.582],
                                  [175.193, -154.82],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.165, -1.671],
                                  [24.71, -67.269],
                                  [17.22, -78.151],
                                  [-61.719, -42.021],
                                  [-14.854, -36.769],
                                  [-169.879, 28.423],
                                  [40.982, 58.678],
                                  [-6.591, 68.552],
                                  [-19.324, 92],
                                ],
                                o: [
                                  [-81.126, 10.478],
                                  [-24.71, 67.269],
                                  [-17.22, 78.151],
                                  [61.719, 42.021],
                                  [23.033, 46.835],
                                  [105.709, -14.582],
                                  [-40.982, -58.678],
                                  [-5.362, -79.247],
                                  [19.324, -92],
                                ],
                                v: [
                                  [-39.303, -317.084],
                                  [-180.46, -225.546],
                                  [-251.822, -30.643],
                                  [-247.611, 151.213],
                                  [-118.516, 273.03],
                                  [124.44, 353.337],
                                  [297.01, 244.193],
                                  [185.381, 11.126],
                                  [176.384, -154.891],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.949, -2.102],
                                  [24.189, -67.858],
                                  [17.587, -77.79],
                                  [-62.373, -41.952],
                                  [-14.881, -37.071],
                                  [-169.383, 28.196],
                                  [41.865, 58.895],
                                  [-6.963, 69.302],
                                  [-19.227, 92],
                                ],
                                o: [
                                  [-81.288, 10.834],
                                  [-24.189, 67.858],
                                  [-17.587, 77.79],
                                  [62.373, 41.953],
                                  [22.99, 47.051],
                                  [105.763, -14.474],
                                  [-41.865, -58.895],
                                  [-4.888, -79.906],
                                  [19.227, -92],
                                ],
                                v: [
                                  [-39.814, -317.754],
                                  [-180.561, -224.682],
                                  [-251.494, -30.04],
                                  [-247.712, 151.337],
                                  [-116.745, 273.615],
                                  [123.64, 352.881],
                                  [296.553, 244.442],
                                  [184.057, 9.683],
                                  [177.617, -154.912],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.735, -2.531],
                                  [23.672, -68.444],
                                  [17.951, -77.43],
                                  [-63.023, -41.884],
                                  [-14.908, -37.372],
                                  [-168.89, 27.971],
                                  [42.743, 59.111],
                                  [-7.333, 70.047],
                                  [-19.131, 92],
                                ],
                                o: [
                                  [-81.449, 11.188],
                                  [-23.672, 68.444],
                                  [-17.951, 77.43],
                                  [63.023, 41.884],
                                  [22.947, 47.265],
                                  [105.816, -14.367],
                                  [-42.743, -59.111],
                                  [-4.416, -80.559],
                                  [19.131, -92],
                                ],
                                v: [
                                  [-40.322, -318.324],
                                  [-180.591, -223.832],
                                  [-251.175, -29.484],
                                  [-247.854, 151.449],
                                  [-114.971, 274.227],
                                  [122.872, 352.462],
                                  [296.149, 244.687],
                                  [182.775, 8.246],
                                  [178.813, -154.906],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.522, -2.957],
                                  [23.158, -69.025],
                                  [18.313, -77.074],
                                  [-63.669, -41.816],
                                  [-14.935, -37.67],
                                  [-168.4, 27.748],
                                  [43.615, 59.325],
                                  [-7.7, 70.787],
                                  [-19.035, 92],
                                ],
                                o: [
                                  [-81.609, 11.539],
                                  [-23.158, 69.025],
                                  [-18.313, 77.074],
                                  [63.669, 41.816],
                                  [22.904, 47.478],
                                  [105.87, -14.261],
                                  [-43.615, -59.325],
                                  [-3.948, -81.209],
                                  [19.035, -92],
                                ],
                                v: [
                                  [-40.849, -318.898],
                                  [-180.696, -222.996],
                                  [-250.857, -28.858],
                                  [-247.96, 151.608],
                                  [-113.246, 274.813],
                                  [122.034, 352.064],
                                  [295.8, 244.915],
                                  [181.449, 6.825],
                                  [180.05, -154.891],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.31, -3.38],
                                  [22.648, -69.603],
                                  [18.673, -76.719],
                                  [-64.31, -41.749],
                                  [-14.961, -37.966],
                                  [-167.913, 27.526],
                                  [44.481, 59.538],
                                  [-8.065, 71.523],
                                  [-18.94, 92],
                                ],
                                o: [
                                  [-81.768, 11.888],
                                  [-22.648, 69.603],
                                  [-18.673, 76.719],
                                  [64.31, 41.749],
                                  [22.862, 47.69],
                                  [105.922, -14.155],
                                  [-44.481, -59.538],
                                  [-3.482, -81.854],
                                  [18.94, -92],
                                ],
                                v: [
                                  [-41.41, -319.414],
                                  [-180.729, -222.173],
                                  [-250.512, -28.262],
                                  [-248.055, 151.777],
                                  [-111.493, 275.326],
                                  [121.189, 351.677],
                                  [295.404, 245.165],
                                  [180.141, 5.41],
                                  [181.264, -154.828],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.1, -3.8],
                                  [22.141, -70.178],
                                  [19.03, -76.367],
                                  [-64.948, -41.682],
                                  [-14.988, -38.26],
                                  [-167.429, 27.305],
                                  [45.342, 59.75],
                                  [-8.428, 72.253],
                                  [-18.845, 92],
                                ],
                                o: [
                                  [-81.925, 12.235],
                                  [-22.141, 70.178],
                                  [-19.03, 76.367],
                                  [64.948, 41.682],
                                  [22.82, 47.9],
                                  [105.975, -14.05],
                                  [-45.342, -59.75],
                                  [-3.019, -82.496],
                                  [18.845, -92],
                                ],
                                v: [
                                  [-41.991, -319.911],
                                  [-180.799, -221.364],
                                  [-250.154, -27.633],
                                  [-248.191, 151.905],
                                  [-109.728, 275.829],
                                  [120.337, 351.273],
                                  [295.063, 245.452],
                                  [178.814, 4.025],
                                  [182.541, -154.756],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.891, -4.218],
                                  [21.637, -70.748],
                                  [19.385, -76.017],
                                  [-65.581, -41.615],
                                  [-15.014, -38.553],
                                  [-166.949, 27.085],
                                  [46.197, 59.96],
                                  [-8.788, 72.979],
                                  [-18.751, 92],
                                ],
                                o: [
                                  [-82.082, 12.58],
                                  [-21.637, 70.748],
                                  [-19.385, 76.017],
                                  [65.581, 41.615],
                                  [22.778, 48.109],
                                  [106.027, -13.945],
                                  [-46.197, -59.96],
                                  [-2.56, -83.133],
                                  [18.751, -92],
                                ],
                                v: [
                                  [-42.569, -320.34],
                                  [-180.832, -220.543],
                                  [-249.783, -26.995],
                                  [-248.268, 152.103],
                                  [-107.998, 276.285],
                                  [119.452, 350.842],
                                  [294.777, 245.7],
                                  [177.505, 2.669],
                                  [183.795, -154.601],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.683, -4.633],
                                  [21.136, -71.315],
                                  [19.738, -75.67],
                                  [-66.21, -41.549],
                                  [-15.04, -38.843],
                                  [-166.472, 26.868],
                                  [47.046, 60.169],
                                  [-9.146, 73.7],
                                  [-18.658, 92],
                                ],
                                o: [
                                  [-82.237, 12.922],
                                  [-21.136, 71.315],
                                  [-19.738, 75.67],
                                  [66.21, 41.549],
                                  [22.737, 48.317],
                                  [106.079, -13.842],
                                  [-47.046, -60.169],
                                  [-2.103, -83.766],
                                  [18.658, -92],
                                ],
                                v: [
                                  [-43.18, -320.778],
                                  [-180.84, -219.761],
                                  [-249.399, -26.359],
                                  [-248.374, 152.258],
                                  [-106.279, 276.731],
                                  [118.523, 350.496],
                                  [294.509, 245.972],
                                  [176.19, 1.305],
                                  [185.049, -154.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.477, -5.046],
                                  [20.639, -71.878],
                                  [20.089, -75.324],
                                  [-66.835, -41.483],
                                  [-15.065, -39.132],
                                  [-165.998, 26.651],
                                  [47.89, 60.376],
                                  [-9.502, 74.417],
                                  [-18.565, 92],
                                ],
                                o: [
                                  [-82.392, 13.263],
                                  [-20.639, 71.878],
                                  [-20.089, 75.324],
                                  [66.835, 41.483],
                                  [22.695, 48.523],
                                  [106.131, -13.739],
                                  [-47.89, -60.376],
                                  [-1.65, -84.395],
                                  [18.565, -92],
                                ],
                                v: [
                                  [-43.812, -321.138],
                                  [-180.897, -218.965],
                                  [-249.04, -25.714],
                                  [-248.483, 152.419],
                                  [-104.572, 277.157],
                                  [117.598, 350.123],
                                  [294.26, 246.207],
                                  [174.882, -0.03],
                                  [186.303, -154.319],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.272, -5.455],
                                  [20.145, -72.437],
                                  [20.437, -74.981],
                                  [-67.456, -41.418],
                                  [-15.091, -39.419],
                                  [-165.527, 26.436],
                                  [48.729, 60.582],
                                  [-9.855, 75.128],
                                  [-18.473, 92],
                                ],
                                o: [
                                  [-82.546, 13.601],
                                  [-20.145, 72.437],
                                  [-20.437, 74.981],
                                  [67.456, 41.418],
                                  [22.654, 48.728],
                                  [106.182, -13.636],
                                  [-48.729, -60.582],
                                  [-1.199, -85.019],
                                  [18.473, -92],
                                ],
                                v: [
                                  [-44.441, -321.534],
                                  [-180.877, -218.208],
                                  [-248.657, -25.032],
                                  [-248.623, 152.597],
                                  [-102.876, 277.548],
                                  [116.691, 349.746],
                                  [294.005, 246.478],
                                  [173.567, -1.361],
                                  [187.531, -154.131],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.069, -5.862],
                                  [19.654, -72.993],
                                  [20.783, -74.64],
                                  [-68.072, -41.353],
                                  [-15.116, -39.703],
                                  [-165.059, 26.222],
                                  [49.562, 60.787],
                                  [-10.206, 75.835],
                                  [-18.381, 92],
                                ],
                                o: [
                                  [-82.698, 13.936],
                                  [-19.654, 72.993],
                                  [-20.783, 74.64],
                                  [68.072, 41.353],
                                  [22.614, 48.931],
                                  [106.233, -13.534],
                                  [-49.562, -60.787],
                                  [-0.752, -85.64],
                                  [18.381, -92],
                                ],
                                v: [
                                  [-45.128, -321.844],
                                  [-180.905, -217.438],
                                  [-248.236, -24.34],
                                  [-248.704, 152.779],
                                  [-101.19, 277.922],
                                  [115.75, 349.429],
                                  [293.833, 246.739],
                                  [172.27, -2.674],
                                  [188.822, -153.938],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.867, -6.266],
                                  [19.166, -73.545],
                                  [21.126, -74.302],
                                  [-68.685, -41.289],
                                  [-15.142, -39.986],
                                  [-164.594, 26.01],
                                  [50.389, 60.99],
                                  [-10.555, 76.538],
                                  [-18.29, 92],
                                ],
                                o: [
                                  [-82.85, 14.27],
                                  [-19.166, 73.545],
                                  [-21.126, 74.302],
                                  [68.685, 41.289],
                                  [22.573, 49.133],
                                  [106.283, -13.433],
                                  [-50.389, -60.99],
                                  [-0.307, -86.256],
                                  [18.29, -92],
                                ],
                                v: [
                                  [-45.812, -322.176],
                                  [-180.906, -216.653],
                                  [-247.857, -23.638],
                                  [-248.825, 152.976],
                                  [-99.516, 278.288],
                                  [114.765, 349.073],
                                  [293.656, 247.001],
                                  [170.982, -3.948],
                                  [190.085, -153.729],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.666, -6.668],
                                  [18.682, -74.093],
                                  [21.468, -73.966],
                                  [-69.294, -41.225],
                                  [-15.167, -40.268],
                                  [-164.132, 25.799],
                                  [51.211, 61.192],
                                  [-10.901, 77.236],
                                  [-18.2, 92],
                                ],
                                o: [
                                  [-83.001, 14.601],
                                  [-18.682, 74.093],
                                  [-21.468, 73.966],
                                  [69.294, 41.225],
                                  [22.533, 49.334],
                                  [106.333, -13.333],
                                  [-51.211, -61.192],
                                  [0.135, -86.869],
                                  [18.2, -92],
                                ],
                                v: [
                                  [-46.484, -322.452],
                                  [-180.892, -215.907],
                                  [-247.44, -22.934],
                                  [-248.914, 153.148],
                                  [-97.888, 278.611],
                                  [113.781, 348.814],
                                  [293.528, 247.256],
                                  [169.687, -5.167],
                                  [191.347, -153.516],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.467, -7.067],
                                  [18.201, -74.638],
                                  [21.807, -73.631],
                                  [-69.898, -41.161],
                                  [-15.192, -40.547],
                                  [-163.673, 25.59],
                                  [52.028, 61.393],
                                  [-11.245, 77.929],
                                  [-18.11, 92],
                                ],
                                o: [
                                  [-83.15, 14.93],
                                  [-18.201, 74.638],
                                  [-21.807, 73.631],
                                  [69.898, 41.161],
                                  [22.493, 49.533],
                                  [106.383, -13.233],
                                  [-52.028, -61.393],
                                  [0.574, -87.477],
                                  [18.11, -92],
                                ],
                                v: [
                                  [-47.223, -322.743],
                                  [-180.825, -215.173],
                                  [-247.039, -22.218],
                                  [-249.008, 153.324],
                                  [-96.235, 278.893],
                                  [112.817, 348.516],
                                  [293.431, 247.547],
                                  [168.347, -6.42],
                                  [192.581, -153.263],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.268, -7.463],
                                  [17.722, -75.179],
                                  [22.144, -73.3],
                                  [-70.499, -41.098],
                                  [-15.216, -40.824],
                                  [-163.217, 25.382],
                                  [52.839, 61.592],
                                  [-11.587, 78.617],
                                  [-18.021, 92],
                                ],
                                o: [
                                  [-83.299, 15.257],
                                  [-17.722, 75.179],
                                  [-22.144, 73.3],
                                  [70.499, 41.098],
                                  [22.454, 49.732],
                                  [106.433, -13.134],
                                  [-52.839, -61.592],
                                  [1.01, -88.081],
                                  [18.021, -92],
                                ],
                                v: [
                                  [-47.952, -323.017],
                                  [-180.775, -214.457],
                                  [-246.655, -21.464],
                                  [-249.106, 153.502],
                                  [-94.628, 279.17],
                                  [111.816, 348.251],
                                  [293.322, 247.77],
                                  [167.054, -7.645],
                                  [193.84, -152.971],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.072, -7.857],
                                  [17.248, -75.717],
                                  [22.478, -72.97],
                                  [-71.096, -41.035],
                                  [-15.241, -41.1],
                                  [-162.765, 25.175],
                                  [53.645, 61.79],
                                  [-11.927, 79.301],
                                  [-17.932, 92],
                                ],
                                o: [
                                  [-83.446, 15.582],
                                  [-17.248, 75.717],
                                  [-22.478, 72.97],
                                  [71.096, 41.035],
                                  [22.414, 49.928],
                                  [106.482, -13.036],
                                  [-53.645, -61.79],
                                  [1.443, -88.682],
                                  [17.932, -92],
                                ],
                                v: [
                                  [-48.678, -323.281],
                                  [-180.699, -213.753],
                                  [-246.261, -20.698],
                                  [-249.236, 153.69],
                                  [-93.031, 279.433],
                                  [110.836, 348.017],
                                  [293.273, 248.059],
                                  [165.752, -8.807],
                                  [195.105, -152.738],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.876, -8.248],
                                  [16.776, -76.251],
                                  [22.811, -72.642],
                                  [-71.688, -40.973],
                                  [-15.265, -41.374],
                                  [-162.315, 24.97],
                                  [54.445, 61.987],
                                  [-12.264, 79.981],
                                  [-17.844, 92],
                                ],
                                o: [
                                  [-83.593, 15.905],
                                  [-16.776, 76.251],
                                  [-22.811, 72.642],
                                  [71.688, 40.973],
                                  [22.375, 50.124],
                                  [106.531, -12.938],
                                  [-54.445, -61.987],
                                  [1.873, -89.278],
                                  [17.844, -92],
                                ],
                                v: [
                                  [-49.464, -323.53],
                                  [-180.604, -213.03],
                                  [-245.886, -19.92],
                                  [-249.308, 153.885],
                                  [-91.444, 279.693],
                                  [109.853, 347.809],
                                  [293.278, 248.287],
                                  [164.433, -9.977],
                                  [196.334, -152.468],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.682, -8.636],
                                  [16.307, -76.782],
                                  [23.141, -72.317],
                                  [-72.277, -40.911],
                                  [-15.29, -41.646],
                                  [-161.868, 24.766],
                                  [55.24, 62.183],
                                  [-12.599, 80.656],
                                  [-17.757, 92],
                                ],
                                o: [
                                  [-83.739, 16.225],
                                  [-16.307, 76.782],
                                  [-23.141, 72.317],
                                  [72.277, 40.911],
                                  [22.336, 50.318],
                                  [106.58, -12.841],
                                  [-55.24, -62.183],
                                  [2.3, -89.871],
                                  [17.757, -92],
                                ],
                                v: [
                                  [-50.214, -323.8],
                                  [-180.489, -212.346],
                                  [-245.502, -19.131],
                                  [-249.419, 154.046],
                                  [-89.929, 279.914],
                                  [108.828, 347.632],
                                  [293.314, 248.547],
                                  [163.133, -11.086],
                                  [197.565, -152.189],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.489, -9.022],
                                  [15.842, -77.309],
                                  [23.469, -71.994],
                                  [-72.862, -40.85],
                                  [-15.314, -41.916],
                                  [-161.424, 24.563],
                                  [56.03, 62.377],
                                  [-12.932, 81.326],
                                  [-17.67, 92],
                                ],
                                o: [
                                  [-83.883, 16.543],
                                  [-15.842, 77.309],
                                  [-23.469, 71.994],
                                  [72.862, 40.85],
                                  [22.298, 50.511],
                                  [106.628, -12.744],
                                  [-56.03, -62.377],
                                  [2.724, -90.459],
                                  [17.67, -92],
                                ],
                                v: [
                                  [-51.024, -324.062],
                                  [-180.381, -211.643],
                                  [-245.138, -18.336],
                                  [-249.505, 154.213],
                                  [-88.396, 280.097],
                                  [107.829, 347.451],
                                  [293.344, 248.776],
                                  [161.818, -12.201],
                                  [198.823, -151.909],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.297, -9.406],
                                  [15.38, -77.832],
                                  [23.795, -71.673],
                                  [-73.443, -40.789],
                                  [-15.338, -42.184],
                                  [-160.984, 24.362],
                                  [56.815, 62.57],
                                  [-13.262, 81.992],
                                  [-17.584, 92],
                                ],
                                o: [
                                  [-84.027, 16.86],
                                  [-15.38, 77.832],
                                  [-23.795, 71.673],
                                  [73.443, 40.788],
                                  [22.259, 50.703],
                                  [106.676, -12.649],
                                  [-56.815, -62.57],
                                  [3.146, -91.044],
                                  [17.584, -92],
                                ],
                                v: [
                                  [-51.833, -324.309],
                                  [-180.252, -210.954],
                                  [-244.794, -17.528],
                                  [-249.59, 154.413],
                                  [-86.9, 280.307],
                                  [106.821, 347.33],
                                  [293.435, 249.005],
                                  [160.523, -13.286],
                                  [200.049, -151.657],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.107, -9.786],
                                  [14.92, -78.352],
                                  [24.118, -71.354],
                                  [-74.02, -40.728],
                                  [-15.362, -42.45],
                                  [-160.546, 24.162],
                                  [57.594, 62.761],
                                  [-13.591, 82.654],
                                  [-17.498, 92],
                                ],
                                o: [
                                  [-84.17, 17.174],
                                  [-14.92, 78.352],
                                  [-24.118, 71.354],
                                  [74.02, 40.728],
                                  [22.221, 50.893],
                                  [106.723, -12.553],
                                  [-57.594, -62.761],
                                  [3.565, -91.624],
                                  [17.498, -92],
                                ],
                                v: [
                                  [-52.669, -324.583],
                                  [-180.067, -210.303],
                                  [-244.473, -16.675],
                                  [-249.712, 154.55],
                                  [-85.418, 280.514],
                                  [105.804, 347.205],
                                  [293.584, 249.242],
                                  [159.216, -14.315],
                                  [201.305, -151.403],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.918, -10.164],
                                  [14.464, -78.868],
                                  [24.44, -71.037],
                                  [-74.593, -40.668],
                                  [-15.385, -42.715],
                                  [-160.111, 23.964],
                                  [58.368, 62.951],
                                  [-13.917, 83.311],
                                  [-17.413, 92],
                                ],
                                o: [
                                  [-84.312, 17.486],
                                  [-14.464, 78.868],
                                  [-24.44, 71.037],
                                  [74.593, 40.668],
                                  [22.184, 51.082],
                                  [106.771, -12.459],
                                  [-58.368, -62.951],
                                  [3.981, -92.201],
                                  [17.413, -92],
                                ],
                                v: [
                                  [-53.504, -324.85],
                                  [-179.892, -209.663],
                                  [-244.145, -15.843],
                                  [-249.776, 154.688],
                                  [-83.975, 280.653],
                                  [104.779, 347.139],
                                  [293.732, 249.448],
                                  [157.895, -15.348],
                                  [202.554, -151.179],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.73, -10.54],
                                  [14.011, -79.381],
                                  [24.759, -70.723],
                                  [-75.162, -40.608],
                                  [-15.409, -42.978],
                                  [-159.679, 23.766],
                                  [59.137, 63.141],
                                  [-14.241, 83.963],
                                  [-17.328, 92],
                                ],
                                o: [
                                  [-84.453, 17.796],
                                  [-14.011, 79.381],
                                  [-24.759, 70.723],
                                  [75.162, 40.608],
                                  [22.146, 51.27],
                                  [106.818, -12.365],
                                  [-59.137, -63.14],
                                  [4.394, -92.774],
                                  [17.328, -92],
                                ],
                                v: [
                                  [-54.368, -325.142],
                                  [-179.69, -209.001],
                                  [-243.84, -14.999],
                                  [-249.907, 154.857],
                                  [-82.573, 280.82],
                                  [103.778, 347.073],
                                  [293.912, 249.688],
                                  [156.592, -16.357],
                                  [203.834, -150.952],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.543, -10.913],
                                  [13.561, -79.891],
                                  [25.076, -70.41],
                                  [-75.728, -40.548],
                                  [-15.432, -43.239],
                                  [-159.25, 23.571],
                                  [59.9, 63.328],
                                  [-14.563, 84.612],
                                  [-17.245, 92],
                                ],
                                o: [
                                  [-84.592, 18.103],
                                  [-13.561, 79.891],
                                  [-25.076, 70.41],
                                  [75.728, 40.548],
                                  [22.109, 51.457],
                                  [106.864, -12.272],
                                  [-59.9, -63.328],
                                  [4.804, -93.343],
                                  [17.245, -92],
                                ],
                                v: [
                                  [-55.261, -325.429],
                                  [-179.464, -208.346],
                                  [-243.592, -14.143],
                                  [-250.011, 154.991],
                                  [-81.179, 280.987],
                                  [102.769, 347.095],
                                  [294.122, 249.966],
                                  [155.276, -17.277],
                                  [205.078, -150.756],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.358, -11.284],
                                  [13.114, -80.397],
                                  [25.391, -70.1],
                                  [-76.289, -40.489],
                                  [-15.455, -43.499],
                                  [-158.824, 23.376],
                                  [60.659, 63.515],
                                  [-14.882, 85.255],
                                  [-17.161, 92],
                                ],
                                o: [
                                  [-84.731, 18.409],
                                  [-13.114, 80.397],
                                  [-25.391, 70.1],
                                  [76.289, 40.489],
                                  [22.072, 51.642],
                                  [106.91, -12.179],
                                  [-60.659, -63.515],
                                  [5.212, -93.908],
                                  [17.161, -92],
                                ],
                                v: [
                                  [-56.186, -325.714],
                                  [-179.181, -207.698],
                                  [-243.336, -13.245],
                                  [-250.086, 155.124],
                                  [-79.825, 281.121],
                                  [101.781, 347.114],
                                  [294.333, 250.217],
                                  [153.947, -18.204],
                                  [206.351, -150.561],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.174, -11.652],
                                  [12.67, -80.899],
                                  [25.704, -69.792],
                                  [-76.847, -40.431],
                                  [-15.478, -43.756],
                                  [-158.401, 23.183],
                                  [61.412, 63.7],
                                  [-15.2, 85.895],
                                  [-17.078, 92],
                                ],
                                o: [
                                  [-84.869, 18.713],
                                  [-12.67, 80.899],
                                  [-25.704, 69.792],
                                  [76.847, 40.43],
                                  [22.035, 51.826],
                                  [106.956, -12.087],
                                  [-61.412, -63.7],
                                  [5.617, -94.469],
                                  [17.078, -92],
                                ],
                                v: [
                                  [-57.109, -326.026],
                                  [-178.935, -207.089],
                                  [-243.107, -12.366],
                                  [-250.197, 155.255],
                                  [-78.541, 281.193],
                                  [100.785, 347.16],
                                  [294.546, 250.507],
                                  [152.637, -19.138],
                                  [207.653, -150.398],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.991, -12.017],
                                  [12.229, -81.399],
                                  [26.015, -69.486],
                                  [-77.401, -40.372],
                                  [-15.501, -44.012],
                                  [-157.98, 22.991],
                                  [62.16, 63.884],
                                  [-15.515, 86.53],
                                  [-16.996, 92],
                                ],
                                o: [
                                  [-85.006, 19.014],
                                  [-12.229, 81.399],
                                  [-26.015, 69.486],
                                  [77.401, 40.372],
                                  [21.998, 52.009],
                                  [107.002, -11.996],
                                  [-62.16, -63.884],
                                  [6.019, -95.026],
                                  [16.996, -92],
                                ],
                                v: [
                                  [-58.065, -326.4],
                                  [-178.631, -206.454],
                                  [-242.904, -11.475],
                                  [-250.279, 155.35],
                                  [-77.298, 281.264],
                                  [99.812, 347.232],
                                  [294.792, 250.805],
                                  [151.314, -19.986],
                                  [208.951, -150.238],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.81, -12.38],
                                  [11.791, -81.894],
                                  [26.323, -69.181],
                                  [-77.951, -40.314],
                                  [-15.524, -44.266],
                                  [-157.563, 22.8],
                                  [62.903, 64.066],
                                  [-15.828, 87.161],
                                  [-16.914, 92],
                                ],
                                o: [
                                  [-85.143, 19.314],
                                  [-11.791, 81.894],
                                  [-26.323, 69.181],
                                  [77.951, 40.314],
                                  [21.962, 52.19],
                                  [107.047, -11.905],
                                  [-62.904, -64.066],
                                  [6.418, -95.58],
                                  [16.914, -92],
                                ],
                                v: [
                                  [-59.02, -326.774],
                                  [-178.332, -205.855],
                                  [-242.698, -10.604],
                                  [-250.365, 155.472],
                                  [-76.127, 281.272],
                                  [98.861, 347.363],
                                  [295.103, 251.143],
                                  [150.009, -20.811],
                                  [210.247, -150.143],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.63, -12.741],
                                  [11.356, -82.387],
                                  [26.63, -68.879],
                                  [-78.498, -40.257],
                                  [-15.546, -44.519],
                                  [-157.148, 22.611],
                                  [63.642, 64.248],
                                  [-16.139, 87.787],
                                  [-16.833, 92],
                                ],
                                o: [
                                  [-85.278, 19.611],
                                  [-11.356, 82.387],
                                  [-26.63, 68.88],
                                  [78.498, 40.257],
                                  [21.926, 52.37],
                                  [107.093, -11.815],
                                  [-63.642, -64.248],
                                  [6.815, -96.13],
                                  [16.833, -92],
                                ],
                                v: [
                                  [-60.007, -327.118],
                                  [-177.973, -205.26],
                                  [-242.518, -9.691],
                                  [-250.453, 155.589],
                                  [-74.967, 281.248],
                                  [97.902, 347.519],
                                  [295.354, 251.46],
                                  [148.723, -21.646],
                                  [211.54, -150.02],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.451, -13.099],
                                  [10.924, -82.876],
                                  [26.934, -68.58],
                                  [-79.041, -40.2],
                                  [-15.569, -44.769],
                                  [-156.736, 22.423],
                                  [64.375, 64.428],
                                  [-16.448, 88.409],
                                  [-16.753, 92],
                                ],
                                o: [
                                  [-85.412, 19.907],
                                  [-10.924, 82.876],
                                  [-26.934, 68.58],
                                  [79.041, 40.2],
                                  [21.89, 52.549],
                                  [107.137, -11.725],
                                  [-64.375, -64.428],
                                  [7.209, -96.676],
                                  [16.753, -92],
                                ],
                                v: [
                                  [-61.027, -327.525],
                                  [-177.619, -204.669],
                                  [-242.337, -8.799],
                                  [-250.574, 155.67],
                                  [-73.911, 281.193],
                                  [96.963, 347.732],
                                  [295.641, 251.788],
                                  [147.392, -22.396],
                                  [212.829, -149.931],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.273, -13.455],
                                  [10.495, -83.361],
                                  [27.236, -68.282],
                                  [-79.58, -40.143],
                                  [-15.591, -45.018],
                                  [-156.327, 22.236],
                                  [65.102, 64.607],
                                  [-16.755, 89.027],
                                  [-16.673, 92],
                                ],
                                o: [
                                  [-85.546, 20.2],
                                  [-10.495, 83.361],
                                  [-27.236, 68.282],
                                  [79.58, 40.143],
                                  [21.855, 52.727],
                                  [107.182, -11.636],
                                  [-65.103, -64.607],
                                  [7.6, -97.218],
                                  [16.673, -92],
                                ],
                                v: [
                                  [-62.017, -327.902],
                                  [-177.235, -204.111],
                                  [-242.215, -7.897],
                                  [-250.667, 155.744],
                                  [-72.866, 281.106],
                                  [96.048, 347.97],
                                  [295.963, 252.127],
                                  [146.079, -23.156],
                                  [214.147, -149.879],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.096, -13.808],
                                  [10.069, -83.844],
                                  [27.537, -67.986],
                                  [-80.115, -40.087],
                                  [-15.613, -45.266],
                                  [-155.921, 22.051],
                                  [65.825, 64.785],
                                  [-17.059, 89.641],
                                  [-16.593, 92],
                                ],
                                o: [
                                  [-85.678, 20.491],
                                  [-10.069, 83.844],
                                  [-27.537, 67.986],
                                  [80.115, 40.087],
                                  [21.819, 52.904],
                                  [107.226, -11.548],
                                  [-65.825, -64.785],
                                  [7.989, -97.757],
                                  [16.593, -92],
                                ],
                                v: [
                                  [-63.038, -328.282],
                                  [-176.824, -203.525],
                                  [-242.057, -7.017],
                                  [-250.762, 155.842],
                                  [-71.865, 281.05],
                                  [95.124, 348.232],
                                  [296.257, 252.511],
                                  [144.784, -23.865],
                                  [215.461, -149.799],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.921, -14.159],
                                  [9.646, -84.323],
                                  [27.835, -67.692],
                                  [-80.647, -40.031],
                                  [-15.635, -45.511],
                                  [-155.518, 21.867],
                                  [66.543, 64.961],
                                  [-17.362, 90.251],
                                  [-16.514, 92],
                                ],
                                o: [
                                  [-85.809, 20.781],
                                  [-9.646, 84.323],
                                  [-27.835, 67.692],
                                  [80.647, 40.031],
                                  [21.784, 53.079],
                                  [107.27, -11.46],
                                  [-66.543, -64.961],
                                  [8.374, -98.292],
                                  [16.514, -92],
                                ],
                                v: [
                                  [-64.06, -328.632],
                                  [-176.413, -202.97],
                                  [-241.962, -6.161],
                                  [-250.89, 155.904],
                                  [-70.937, 280.898],
                                  [94.222, 348.551],
                                  [296.59, 252.845],
                                  [143.474, -24.555],
                                  [216.806, -149.819],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.747, -14.507],
                                  [9.226, -84.799],
                                  [28.131, -67.4],
                                  [-81.175, -39.975],
                                  [-15.657, -45.755],
                                  [-155.117, 21.684],
                                  [67.256, 65.136],
                                  [-17.662, 90.856],
                                  [-16.436, 92],
                                ],
                                o: [
                                  [-85.94, 21.068],
                                  [-9.226, 84.799],
                                  [-28.131, 67.4],
                                  [81.175, 39.975],
                                  [21.749, 53.253],
                                  [107.313, -11.373],
                                  [-67.256, -65.136],
                                  [8.758, -98.823],
                                  [16.436, -92],
                                ],
                                v: [
                                  [-65.053, -328.952],
                                  [-175.942, -202.414],
                                  [-241.86, -5.266],
                                  [-250.986, 155.957],
                                  [-70.058, 280.779],
                                  [93.339, 348.892],
                                  [296.896, 253.224],
                                  [142.214, -25.194],
                                  [218.114, -149.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.574, -14.853],
                                  [8.809, -85.271],
                                  [28.425, -67.111],
                                  [-81.699, -39.92],
                                  [-15.678, -45.997],
                                  [-154.719, 21.502],
                                  [67.965, 65.31],
                                  [-17.961, 91.457],
                                  [-16.358, 92],
                                ],
                                o: [
                                  [-86.07, 21.354],
                                  [-8.809, 85.271],
                                  [-28.425, 67.111],
                                  [81.699, 39.92],
                                  [21.715, 53.427],
                                  [107.357, -11.287],
                                  [-67.965, -65.31],
                                  [9.138, -99.351],
                                  [16.358, -92],
                                ],
                                v: [
                                  [-66.081, -329.306],
                                  [-175.474, -201.892],
                                  [-241.789, -4.393],
                                  [-251.083, 156.003],
                                  [-69.221, 280.626],
                                  [92.48, 349.289],
                                  [297.239, 253.586],
                                  [140.968, -25.815],
                                  [219.42, -149.812],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.402, -15.196],
                                  [8.394, -85.74],
                                  [28.717, -66.823],
                                  [-82.22, -39.866],
                                  [-15.7, -46.237],
                                  [-154.324, 21.322],
                                  [68.668, 65.483],
                                  [-18.257, 92.054],
                                  [-16.281, 92],
                                ],
                                o: [
                                  [-86.199, 21.637],
                                  [-8.394, 85.74],
                                  [-28.717, 66.823],
                                  [82.22, 39.866],
                                  [21.68, 53.598],
                                  [107.4, -11.201],
                                  [-68.668, -65.483],
                                  [9.516, -99.875],
                                  [16.281, -92],
                                ],
                                v: [
                                  [-67.105, -329.628],
                                  [-174.973, -201.367],
                                  [-241.719, -3.578],
                                  [-251.182, 156.043],
                                  [-68.429, 280.41],
                                  [91.676, 349.68],
                                  [297.588, 253.995],
                                  [139.703, -26.449],
                                  [220.722, -149.878],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.231, -15.538],
                                  [7.983, -86.206],
                                  [29.007, -66.537],
                                  [-82.737, -39.811],
                                  [-15.721, -46.476],
                                  [-153.932, 21.143],
                                  [69.366, 65.655],
                                  [-18.551, 92.647],
                                  [-16.204, 92],
                                ],
                                o: [
                                  [-86.327, 21.919],
                                  [-7.983, 86.206],
                                  [-29.007, 66.537],
                                  [82.737, 39.811],
                                  [21.646, 53.769],
                                  [107.442, -11.116],
                                  [-69.366, -65.655],
                                  [9.891, -100.395],
                                  [16.204, -92],
                                ],
                                v: [
                                  [-68.102, -329.986],
                                  [-174.475, -200.837],
                                  [-241.705, -2.728],
                                  [-251.247, 156.104],
                                  [-67.713, 280.226],
                                  [90.828, 350.092],
                                  [297.947, 254.388],
                                  [138.489, -27.037],
                                  [222.055, -149.955],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.062, -15.876],
                                  [7.574, -86.669],
                                  [29.295, -66.254],
                                  [-83.25, -39.757],
                                  [-15.742, -46.713],
                                  [-153.542, 20.965],
                                  [70.059, 65.825],
                                  [-18.843, 93.235],
                                  [-16.128, 92],
                                ],
                                o: [
                                  [-86.454, 22.198],
                                  [-7.574, 86.669],
                                  [-29.295, 66.254],
                                  [83.25, 39.757],
                                  [21.612, 53.938],
                                  [107.485, -11.031],
                                  [-70.059, -65.825],
                                  [10.264, -100.911],
                                  [16.128, -92],
                                ],
                                v: [
                                  [-69.1, -330.282],
                                  [-173.944, -200.334],
                                  [-241.66, -1.898],
                                  [-251.32, 156.124],
                                  [-66.98, 280.008],
                                  [90.068, 350.56],
                                  [298.278, 254.795],
                                  [137.256, -27.573],
                                  [223.351, -150.065],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.894, -16.213],
                                  [7.168, -87.128],
                                  [29.581, -65.972],
                                  [-83.76, -39.704],
                                  [-15.763, -46.949],
                                  [-153.155, 20.788],
                                  [70.748, 65.995],
                                  [-19.134, 93.82],
                                  [-16.052, 92],
                                ],
                                o: [
                                  [-86.58, 22.476],
                                  [-7.168, 87.128],
                                  [-29.581, 65.972],
                                  [83.76, 39.704],
                                  [21.579, 54.106],
                                  [107.527, -10.947],
                                  [-70.748, -65.995],
                                  [10.634, -101.424],
                                  [16.052, -92],
                                ],
                                v: [
                                  [-70.07, -330.581],
                                  [-173.414, -199.83],
                                  [-241.648, -1.069],
                                  [-251.332, 156.109],
                                  [-66.358, 279.762],
                                  [89.264, 351.021],
                                  [298.649, 255.187],
                                  [136.038, -28.096],
                                  [224.707, -150.18],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.727, -16.547],
                                  [6.765, -87.584],
                                  [29.865, -65.692],
                                  [-84.266, -39.65],
                                  [-15.784, -47.183],
                                  [-152.771, 20.613],
                                  [71.432, 66.163],
                                  [-19.422, 94.4],
                                  [-15.977, 92],
                                ],
                                o: [
                                  [-86.705, 22.751],
                                  [-6.765, 87.584],
                                  [-29.865, 65.692],
                                  [84.266, 39.65],
                                  [21.545, 54.273],
                                  [107.568, -10.863],
                                  [-71.432, -66.163],
                                  [11.002, -101.934],
                                  [15.977, -92],
                                ],
                                v: [
                                  [-71.07, -330.881],
                                  [-172.857, -199.353],
                                  [-241.661, -0.268],
                                  [-251.346, 156.115],
                                  [-65.749, 279.515],
                                  [88.487, 351.51],
                                  [298.998, 255.565],
                                  [134.864, -28.638],
                                  [225.997, -150.336],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.561, -16.879],
                                  [6.365, -88.037],
                                  [30.147, -65.414],
                                  [-84.769, -39.598],
                                  [-15.805, -47.415],
                                  [-152.39, 20.439],
                                  [72.111, 66.329],
                                  [-19.708, 94.976],
                                  [-15.902, 92],
                                ],
                                o: [
                                  [-86.829, 23.025],
                                  [-6.365, 88.037],
                                  [-30.147, 65.414],
                                  [84.769, 39.597],
                                  [21.512, 54.439],
                                  [107.61, -10.78],
                                  [-72.111, -66.33],
                                  [11.366, -102.44],
                                  [15.902, -92],
                                ],
                                v: [
                                  [-72.009, -331.155],
                                  [-172.232, -198.847],
                                  [-241.707, 0.539],
                                  [-251.306, 156.086],
                                  [-65.215, 279.267],
                                  [87.764, 352.02],
                                  [299.353, 255.957],
                                  [133.704, -29.128],
                                  [227.318, -150.531],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.396, -17.208],
                                  [5.968, -88.487],
                                  [30.427, -65.138],
                                  [-85.268, -39.545],
                                  [-15.826, -47.646],
                                  [-152.011, 20.266],
                                  [72.785, 66.495],
                                  [-19.992, 95.549],
                                  [-15.828, 92],
                                ],
                                o: [
                                  [-86.953, 23.297],
                                  [-5.968, 88.487],
                                  [-30.427, 65.138],
                                  [85.268, 39.545],
                                  [21.479, 54.604],
                                  [107.651, -10.698],
                                  [-72.785, -66.495],
                                  [11.729, -102.942],
                                  [15.828, -92],
                                ],
                                v: [
                                  [-73.01, -331.458],
                                  [-171.67, -198.332],
                                  [-241.752, 1.28],
                                  [-251.207, 156.05],
                                  [-64.703, 278.984],
                                  [87.032, 352.551],
                                  [299.715, 256.399],
                                  [132.523, -29.574],
                                  [228.665, -150.733],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.232, -17.535],
                                  [5.573, -88.934],
                                  [30.705, -64.864],
                                  [-85.764, -39.493],
                                  [-15.846, -47.874],
                                  [-151.635, 20.094],
                                  [73.454, 66.66],
                                  [-20.274, 96.117],
                                  [-15.755, 92],
                                ],
                                o: [
                                  [-87.076, 23.566],
                                  [-5.573, 88.934],
                                  [-30.705, 64.864],
                                  [85.764, 39.493],
                                  [21.447, 54.768],
                                  [107.692, -10.616],
                                  [-73.454, -66.66],
                                  [12.088, -103.441],
                                  [15.755, -92],
                                ],
                                v: [
                                  [-73.95, -331.736],
                                  [-171.046, -197.842],
                                  [-241.822, 2.061],
                                  [-251.111, 155.998],
                                  [-64.267, 278.736],
                                  [86.355, 353.109],
                                  [300.092, 256.792],
                                  [131.392, -30.012],
                                  [229.945, -150.967],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.07, -17.86],
                                  [5.182, -89.377],
                                  [30.981, -64.592],
                                  [-86.256, -39.441],
                                  [-15.866, -48.102],
                                  [-151.261, 19.924],
                                  [74.119, 66.823],
                                  [-20.554, 96.681],
                                  [-15.682, 92],
                                ],
                                o: [
                                  [-87.197, 23.834],
                                  [-5.182, 89.377],
                                  [-30.981, 64.593],
                                  [86.256, 39.441],
                                  [21.414, 54.93],
                                  [107.733, -10.535],
                                  [-74.119, -66.823],
                                  [12.446, -103.936],
                                  [15.682, -92],
                                ],
                                v: [
                                  [-74.891, -332.042],
                                  [-170.422, -197.322],
                                  [-241.899, 2.776],
                                  [-250.99, 155.949],
                                  [-63.846, 278.451],
                                  [85.669, 353.661],
                                  [300.44, 257.199],
                                  [130.266, -30.436],
                                  [231.259, -151.242],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.909, -18.182],
                                  [4.793, -89.818],
                                  [31.255, -64.322],
                                  [-86.745, -39.39],
                                  [-15.886, -48.328],
                                  [-150.891, 19.754],
                                  [74.779, 66.985],
                                  [-20.832, 97.242],
                                  [-15.609, 92],
                                ],
                                o: [
                                  [-87.318, 24.1],
                                  [-4.793, 89.818],
                                  [-31.255, 64.322],
                                  [86.745, 39.39],
                                  [21.382, 55.091],
                                  [107.773, -10.454],
                                  [-74.779, -66.985],
                                  [12.8, -104.428],
                                  [15.609, -92],
                                ],
                                v: [
                                  [-75.771, -332.324],
                                  [-169.763, -196.801],
                                  [-242.001, 3.493],
                                  [-250.873, 155.856],
                                  [-63.5, 278.175],
                                  [85.012, 354.243],
                                  [300.804, 257.595],
                                  [129.118, -30.845],
                                  [232.56, -151.523],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.749, -18.502],
                                  [4.407, -90.255],
                                  [31.527, -64.054],
                                  [-87.23, -39.339],
                                  [-15.906, -48.552],
                                  [-150.522, 19.586],
                                  [75.434, 67.146],
                                  [-21.108, 97.798],
                                  [-15.537, 92],
                                ],
                                o: [
                                  [-87.438, 24.364],
                                  [-4.407, 90.255],
                                  [-31.527, 64.054],
                                  [87.23, 39.339],
                                  [21.35, 55.251],
                                  [107.813, -10.374],
                                  [-75.434, -67.146],
                                  [13.152, -104.916],
                                  [15.537, -92],
                                ],
                                v: [
                                  [-76.713, -332.571],
                                  [-169.13, -196.267],
                                  [-242.139, 4.205],
                                  [-250.733, 155.793],
                                  [-63.142, 277.863],
                                  [84.41, 354.844],
                                  [301.166, 258.004],
                                  [128.018, -31.212],
                                  [233.867, -151.845],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.59, -18.82],
                                  [4.023, -90.689],
                                  [31.797, -63.788],
                                  [-87.712, -39.288],
                                  [-15.926, -48.774],
                                  [-150.157, 19.419],
                                  [76.085, 67.306],
                                  [-21.382, 98.35],
                                  [-15.465, 92],
                                ],
                                o: [
                                  [-87.558, 24.627],
                                  [-4.023, 90.689],
                                  [-31.797, 63.788],
                                  [87.712, 39.288],
                                  [21.318, 55.41],
                                  [107.852, -10.295],
                                  [-76.085, -67.306],
                                  [13.502, -105.401],
                                  [15.465, -92],
                                ],
                                v: [
                                  [-77.594, -332.882],
                                  [-168.47, -195.768],
                                  [-242.275, 4.856],
                                  [-250.534, 155.662],
                                  [-62.897, 277.576],
                                  [83.8, 355.439],
                                  [301.519, 258.402],
                                  [126.894, -31.54],
                                  [235.172, -152.2],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.432, -19.136],
                                  [3.642, -91.12],
                                  [32.065, -63.524],
                                  [-88.19, -39.238],
                                  [-15.946, -48.995],
                                  [-149.794, 19.254],
                                  [76.731, 67.465],
                                  [-21.655, 98.898],
                                  [-15.394, 92],
                                ],
                                o: [
                                  [-87.676, 24.887],
                                  [-3.642, 91.12],
                                  [-32.065, 63.524],
                                  [88.19, 39.238],
                                  [21.286, 55.568],
                                  [107.892, -10.216],
                                  [-76.731, -67.465],
                                  [13.849, -105.882],
                                  [15.394, -92],
                                ],
                                v: [
                                  [-78.438, -333.131],
                                  [-167.81, -195.229],
                                  [-242.447, 5.536],
                                  [-250.375, 155.586],
                                  [-62.63, 277.263],
                                  [83.245, 356.065],
                                  [301.844, 258.814],
                                  [125.846, -31.891],
                                  [236.437, -152.596],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.275, -19.449],
                                  [3.264, -91.548],
                                  [32.332, -63.261],
                                  [-88.665, -39.188],
                                  [-15.966, -49.214],
                                  [-149.434, 19.089],
                                  [77.372, 67.623],
                                  [-21.925, 99.443],
                                  [-15.324, 92],
                                ],
                                o: [
                                  [-87.793, 25.146],
                                  [-3.264, 91.548],
                                  [-32.332, 63.261],
                                  [88.665, 39.188],
                                  [21.255, 55.725],
                                  [107.931, -10.138],
                                  [-77.372, -67.623],
                                  [14.194, -106.36],
                                  [15.324, -92],
                                ],
                                v: [
                                  [-79.32, -333.382],
                                  [-167.139, -194.687],
                                  [-242.607, 6.18],
                                  [-250.121, 155.441],
                                  [-62.451, 277.012],
                                  [82.721, 356.71],
                                  [302.176, 259.204],
                                  [124.747, -32.229],
                                  [237.736, -153.024],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.12, -19.76],
                                  [2.889, -91.973],
                                  [32.596, -63.001],
                                  [-89.137, -39.138],
                                  [-15.985, -49.432],
                                  [-149.076, 18.926],
                                  [78.009, 67.779],
                                  [-22.193, 99.983],
                                  [-15.254, 92],
                                ],
                                o: [
                                  [-87.91, 25.402],
                                  [-2.889, 91.973],
                                  [-32.596, 63.001],
                                  [89.137, 39.138],
                                  [21.224, 55.88],
                                  [107.97, -10.06],
                                  [-78.009, -67.779],
                                  [14.536, -106.834],
                                  [15.254, -92],
                                ],
                                v: [
                                  [-80.128, -333.66],
                                  [-166.467, -194.143],
                                  [-242.84, 6.788],
                                  [-249.908, 155.328],
                                  [-62.274, 276.698],
                                  [82.19, 357.323],
                                  [302.527, 259.582],
                                  [123.674, -32.529],
                                  [239.033, -153.47],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.965, -20.069],
                                  [2.517, -92.394],
                                  [32.859, -62.742],
                                  [-89.605, -39.089],
                                  [-16.004, -49.648],
                                  [-148.721, 18.764],
                                  [78.641, 67.935],
                                  [-22.46, 100.52],
                                  [-15.184, 92],
                                ],
                                o: [
                                  [-88.026, 25.657],
                                  [-2.517, 92.394],
                                  [-32.859, 62.742],
                                  [89.605, 39.089],
                                  [21.193, 56.035],
                                  [108.009, -9.983],
                                  [-78.641, -67.935],
                                  [14.876, -107.305],
                                  [15.184, -92],
                                ],
                                v: [
                                  [-80.948, -333.913],
                                  [-165.794, -193.596],
                                  [-243.035, 7.398],
                                  [-249.698, 155.209],
                                  [-62.161, 276.408],
                                  [81.714, 357.993],
                                  [302.849, 259.975],
                                  [122.624, -32.792],
                                  [240.289, -153.971],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.812, -20.376],
                                  [2.147, -92.813],
                                  [33.119, -62.485],
                                  [-90.069, -39.04],
                                  [-16.023, -49.863],
                                  [-148.368, 18.603],
                                  [79.269, 68.089],
                                  [-22.724, 101.053],
                                  [-15.115, 92],
                                ],
                                o: [
                                  [-88.141, 25.91],
                                  [-2.147, 92.813],
                                  [-33.119, 62.485],
                                  [90.069, 39.04],
                                  [21.162, 56.188],
                                  [108.047, -9.906],
                                  [-79.269, -68.089],
                                  [15.213, -107.773],
                                  [15.115, -92],
                                ],
                                v: [
                                  [-81.73, -334.167],
                                  [-165.119, -193.071],
                                  [-243.267, 7.97],
                                  [-249.43, 155.06],
                                  [-62.049, 276.093],
                                  [81.271, 358.658],
                                  [303.154, 260.381],
                                  [121.585, -33.044],
                                  [241.58, -154.491],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.66, -20.68],
                                  [1.78, -93.229],
                                  [33.378, -62.23],
                                  [-90.531, -38.992],
                                  [-16.043, -50.076],
                                  [-148.018, 18.443],
                                  [79.892, 68.242],
                                  [-22.987, 101.582],
                                  [-15.047, 92],
                                ],
                                o: [
                                  [-88.255, 26.161],
                                  [-1.78, 93.229],
                                  [-33.378, 62.23],
                                  [90.531, 38.992],
                                  [21.132, 56.34],
                                  [108.085, -9.83],
                                  [-79.892, -68.242],
                                  [15.548, -108.237],
                                  [15.047, -92],
                                ],
                                v: [
                                  [-82.513, -334.447],
                                  [-164.444, -192.542],
                                  [-243.536, 8.567],
                                  [-249.166, 154.93],
                                  [-62.028, 275.84],
                                  [80.821, 359.355],
                                  [303.506, 260.739],
                                  [120.545, -33.322],
                                  [242.843, -155.041],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.509, -20.982],
                                  [1.415, -93.642],
                                  [33.635, -61.977],
                                  [-90.989, -38.943],
                                  [-16.061, -50.288],
                                  [-147.67, 18.284],
                                  [80.511, 68.394],
                                  [-23.247, 102.107],
                                  [-14.979, 92],
                                ],
                                o: [
                                  [-88.368, 26.41],
                                  [-1.415, 93.642],
                                  [-33.635, 61.977],
                                  [90.989, 38.943],
                                  [21.102, 56.491],
                                  [108.123, -9.754],
                                  [-80.511, -68.394],
                                  [15.881, -108.698],
                                  [14.979, -92],
                                ],
                                v: [
                                  [-83.257, -334.728],
                                  [-163.792, -191.987],
                                  [-243.791, 9.102],
                                  [-248.906, 154.771],
                                  [-62.008, 275.548],
                                  [80.466, 360.046],
                                  [303.827, 261.11],
                                  [119.515, -33.564],
                                  [244.091, -155.62],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.359, -21.282],
                                  [1.053, -94.051],
                                  [33.89, -61.726],
                                  [-91.444, -38.896],
                                  [-16.08, -50.498],
                                  [-147.325, 18.127],
                                  [81.125, 68.545],
                                  [-23.506, 102.628],
                                  [-14.911, 92],
                                ],
                                o: [
                                  [-88.481, 26.658],
                                  [-1.053, 94.051],
                                  [-33.89, 61.726],
                                  [91.444, 38.896],
                                  [21.072, 56.641],
                                  [108.16, -9.679],
                                  [-81.125, -68.545],
                                  [16.211, -109.156],
                                  [14.911, -92],
                                ],
                                v: [
                                  [-83.963, -334.971],
                                  [-163.139, -191.453],
                                  [-244.06, 9.622],
                                  [-248.625, 154.621],
                                  [-62.015, 275.256],
                                  [80.106, 360.709],
                                  [304.133, 261.456],
                                  [118.52, -33.772],
                                  [245.349, -156.282],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.21, -21.58],
                                  [0.694, -94.458],
                                  [34.143, -61.477],
                                  [-91.895, -38.848],
                                  [-16.099, -50.706],
                                  [-146.983, 17.97],
                                  [81.735, 68.695],
                                  [-23.763, 103.146],
                                  [-14.844, 92],
                                ],
                                o: [
                                  [-88.593, 26.904],
                                  [-0.694, 94.458],
                                  [-34.143, 61.477],
                                  [91.895, 38.848],
                                  [21.042, 56.79],
                                  [108.198, -9.605],
                                  [-81.735, -68.695],
                                  [16.538, -109.61],
                                  [14.844, -92],
                                ],
                                v: [
                                  [-84.682, -335.252],
                                  [-162.445, -190.916],
                                  [-244.392, 10.117],
                                  [-248.372, 154.452],
                                  [-62.099, 275.001],
                                  [79.779, 361.391],
                                  [304.384, 261.815],
                                  [117.534, -33.971],
                                  [246.629, -156.91],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.062, -21.876],
                                  [0.337, -94.862],
                                  [34.395, -61.229],
                                  [-92.343, -38.801],
                                  [-16.117, -50.913],
                                  [-146.643, 17.815],
                                  [82.34, 68.844],
                                  [-24.018, 103.659],
                                  [-14.778, 92],
                                ],
                                o: [
                                  [-88.703, 27.148],
                                  [-0.337, 94.862],
                                  [-34.395, 61.229],
                                  [92.343, 38.801],
                                  [21.012, 56.938],
                                  [108.235, -9.531],
                                  [-82.34, -68.844],
                                  [16.864, -110.061],
                                  [14.778, -92],
                                ],
                                v: [
                                  [-85.324, -335.495],
                                  [-161.812, -190.337],
                                  [-244.685, 10.596],
                                  [-248.061, 154.333],
                                  [-62.159, 274.73],
                                  [79.486, 362.107],
                                  [304.683, 262.187],
                                  [116.558, -34.159],
                                  [247.844, -157.606],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.915, -22.169],
                                  [-0.017, -95.263],
                                  [34.644, -60.983],
                                  [-92.788, -38.754],
                                  [-16.136, -51.119],
                                  [-146.305, 17.661],
                                  [82.941, 68.992],
                                  [-24.271, 104.169],
                                  [-14.712, 92],
                                ],
                                o: [
                                  [-88.814, 27.39],
                                  [0.017, 95.263],
                                  [-34.644, 60.983],
                                  [92.788, 38.754],
                                  [20.983, 57.085],
                                  [108.271, -9.458],
                                  [-82.941, -68.992],
                                  [17.186, -110.508],
                                  [14.712, -92],
                                ],
                                v: [
                                  [-85.964, -335.738],
                                  [-161.154, -189.834],
                                  [-245.016, 11.073],
                                  [-247.753, 154.171],
                                  [-62.271, 274.497],
                                  [79.213, 362.818],
                                  [304.99, 262.51],
                                  [115.553, -34.354],
                                  [249.095, -158.346],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.77, -22.461],
                                  [-0.368, -95.661],
                                  [34.892, -60.739],
                                  [-93.23, -38.708],
                                  [-16.154, -51.323],
                                  [-145.97, 17.508],
                                  [83.537, 69.138],
                                  [-24.522, 104.676],
                                  [-14.646, 92],
                                ],
                                o: [
                                  [-88.923, 27.63],
                                  [0.368, 95.661],
                                  [-34.892, 60.739],
                                  [93.23, 38.708],
                                  [20.954, 57.23],
                                  [108.308, -9.385],
                                  [-83.537, -69.138],
                                  [17.507, -110.953],
                                  [14.646, -92],
                                ],
                                v: [
                                  [-86.603, -336.005],
                                  [-160.517, -189.311],
                                  [-245.347, 11.509],
                                  [-247.488, 154.021],
                                  [-62.46, 274.223],
                                  [79.014, 363.541],
                                  [305.244, 262.805],
                                  [114.62, -34.54],
                                  [250.282, -159.155],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.625, -22.75],
                                  [-0.717, -96.056],
                                  [35.138, -60.497],
                                  [-93.668, -38.662],
                                  [-16.172, -51.525],
                                  [-145.637, 17.356],
                                  [84.129, 69.284],
                                  [-24.772, 105.178],
                                  [-14.581, 92],
                                ],
                                o: [
                                  [-89.031, 27.869],
                                  [0.717, 96.056],
                                  [-35.138, 60.497],
                                  [93.668, 38.662],
                                  [20.925, 57.375],
                                  [108.344, -9.312],
                                  [-84.129, -69.284],
                                  [17.825, -111.394],
                                  [14.581, -92],
                                ],
                                v: [
                                  [-87.179, -336.27],
                                  [-159.901, -188.763],
                                  [-245.718, 11.944],
                                  [-247.211, 153.869],
                                  [-62.601, 273.988],
                                  [78.834, 364.244],
                                  [305.483, 263.151],
                                  [113.656, -34.694],
                                  [251.528, -159.952],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.481, -23.037],
                                  [-1.064, -96.448],
                                  [35.382, -60.256],
                                  [-94.103, -38.616],
                                  [-16.19, -51.726],
                                  [-145.307, 17.206],
                                  [84.717, 69.428],
                                  [-25.02, 105.677],
                                  [-14.517, 92],
                                ],
                                o: [
                                  [-89.139, 28.106],
                                  [1.064, 96.448],
                                  [-35.382, 60.256],
                                  [94.103, 38.616],
                                  [20.896, 57.519],
                                  [108.38, -9.241],
                                  [-84.717, -69.428],
                                  [18.141, -111.832],
                                  [14.517, -92],
                                ],
                                v: [
                                  [-87.754, -336.496],
                                  [-159.323, -188.212],
                                  [-246.073, 12.358],
                                  [-246.874, 153.714],
                                  [-62.817, 273.735],
                                  [78.729, 364.96],
                                  [305.731, 263.431],
                                  [112.7, -34.88],
                                  [252.71, -160.793],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.339, -23.322],
                                  [-1.407, -96.837],
                                  [35.624, -60.018],
                                  [-94.535, -38.571],
                                  [-16.208, -51.926],
                                  [-144.98, 17.056],
                                  [85.3, 69.571],
                                  [-25.265, 106.172],
                                  [-14.453, 92],
                                ],
                                o: [
                                  [-89.246, 28.341],
                                  [1.407, 96.837],
                                  [-35.624, 60.018],
                                  [94.535, 38.57],
                                  [20.868, 57.661],
                                  [108.415, -9.169],
                                  [-85.3, -69.571],
                                  [18.454, -112.266],
                                  [14.453, -92],
                                ],
                                v: [
                                  [-88.287, -336.783],
                                  [-158.703, -187.72],
                                  [-246.444, 12.788],
                                  [-246.58, 153.596],
                                  [-63.048, 273.52],
                                  [78.6, 365.672],
                                  [305.988, 263.743],
                                  [111.776, -35.036],
                                  [253.89, -161.701],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.197, -23.605],
                                  [-1.749, -97.223],
                                  [35.864, -59.781],
                                  [-94.964, -38.525],
                                  [-16.225, -52.123],
                                  [-144.654, 16.907],
                                  [85.879, 69.714],
                                  [-25.509, 106.664],
                                  [-14.389, 92],
                                ],
                                o: [
                                  [-89.352, 28.574],
                                  [1.749, 97.223],
                                  [-35.864, 59.781],
                                  [94.964, 38.525],
                                  [20.84, 57.802],
                                  [108.451, -9.099],
                                  [-85.879, -69.714],
                                  [18.766, -112.698],
                                  [14.389, -92],
                                ],
                                v: [
                                  [-88.779, -337.029],
                                  [-158.144, -187.186],
                                  [-246.839, 13.157],
                                  [-246.312, 153.453],
                                  [-63.292, 273.326],
                                  [78.552, 366.421],
                                  [306.191, 264.044],
                                  [110.86, -35.185],
                                  [255.026, -162.615],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.057, -23.886],
                                  [-2.087, -97.607],
                                  [36.103, -59.546],
                                  [-95.389, -38.481],
                                  [-16.243, -52.32],
                                  [-144.331, 16.76],
                                  [86.454, 69.855],
                                  [-25.751, 107.152],
                                  [-14.326, 92],
                                ],
                                o: [
                                  [-89.457, 28.806],
                                  [2.087, 97.607],
                                  [-36.103, 59.546],
                                  [95.389, 38.481],
                                  [20.811, 57.943],
                                  [108.486, -9.029],
                                  [-86.454, -69.855],
                                  [19.074, -113.126],
                                  [14.326, -92],
                                ],
                                v: [
                                  [-89.269, -337.273],
                                  [-157.56, -186.689],
                                  [-247.211, 13.541],
                                  [-245.984, 153.349],
                                  [-63.611, 273.13],
                                  [78.52, 367.105],
                                  [306.381, 264.356],
                                  [109.952, -35.327],
                                  [256.16, -163.555],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.918, -24.164],
                                  [-2.423, -97.987],
                                  [36.34, -59.312],
                                  [-95.812, -38.436],
                                  [-16.26, -52.515],
                                  [-144.011, 16.614],
                                  [87.024, 69.995],
                                  [-25.992, 107.636],
                                  [-14.263, 92],
                                ],
                                o: [
                                  [-89.562, 29.036],
                                  [2.423, 97.987],
                                  [-36.34, 59.312],
                                  [95.812, 38.436],
                                  [20.784, 58.082],
                                  [108.521, -8.959],
                                  [-87.024, -69.995],
                                  [19.381, -113.551],
                                  [14.263, -92],
                                ],
                                v: [
                                  [-89.694, -337.538],
                                  [-157.058, -186.19],
                                  [-247.605, 13.904],
                                  [-245.682, 153.222],
                                  [-63.882, 272.891],
                                  [78.588, 367.826],
                                  [306.58, 264.639],
                                  [109.074, -35.462],
                                  [257.291, -164.499],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.779, -24.441],
                                  [-2.757, -98.365],
                                  [36.575, -59.081],
                                  [-96.231, -38.392],
                                  [-16.278, -52.709],
                                  [-143.693, 16.469],
                                  [87.59, 70.134],
                                  [-26.23, 108.116],
                                  [-14.201, 92],
                                ],
                                o: [
                                  [-89.665, 29.264],
                                  [2.757, 98.365],
                                  [-36.575, 59.081],
                                  [96.231, 38.392],
                                  [20.756, 58.221],
                                  [108.555, -8.89],
                                  [-87.59, -70.134],
                                  [19.685, -113.973],
                                  [14.201, -92],
                                ],
                                v: [
                                  [-90.138, -337.76],
                                  [-156.555, -185.688],
                                  [-248.041, 14.304],
                                  [-245.422, 153.157],
                                  [-64.228, 272.713],
                                  [78.656, 368.545],
                                  [306.746, 264.931],
                                  [108.203, -35.609],
                                  [258.357, -165.509],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.642, -24.716],
                                  [-3.088, -98.74],
                                  [36.808, -58.851],
                                  [-96.647, -38.348],
                                  [-16.295, -52.901],
                                  [-143.377, 16.324],
                                  [88.152, 70.273],
                                  [-26.467, 108.593],
                                  [-14.139, 92],
                                ],
                                o: [
                                  [-89.768, 29.49],
                                  [3.088, 98.74],
                                  [-36.808, 58.851],
                                  [96.647, 38.349],
                                  [20.728, 58.358],
                                  [108.589, -8.821],
                                  [-88.152, -70.273],
                                  [19.987, -114.391],
                                  [14.139, -92],
                                ],
                                v: [
                                  [-90.518, -338.021],
                                  [-156.049, -185.225],
                                  [-248.436, 14.66],
                                  [-245.124, 153.069],
                                  [-64.525, 272.533],
                                  [78.762, 369.241],
                                  [306.9, 265.233],
                                  [107.34, -35.75],
                                  [259.42, -166.545],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.506, -24.988],
                                  [-3.417, -99.112],
                                  [37.04, -58.622],
                                  [-97.06, -38.305],
                                  [-16.312, -53.092],
                                  [-143.064, 16.181],
                                  [88.71, 70.41],
                                  [-26.702, 109.067],
                                  [-14.078, 92],
                                ],
                                o: [
                                  [-89.871, 29.715],
                                  [3.417, 99.112],
                                  [-37.04, 58.622],
                                  [97.06, 38.305],
                                  [20.701, 58.494],
                                  [108.624, -8.753],
                                  [-88.71, -70.41],
                                  [20.287, -114.807],
                                  [14.078, -92],
                                ],
                                v: [
                                  [-90.833, -338.26],
                                  [-155.541, -184.718],
                                  [-248.872, 14.972],
                                  [-244.85, 152.959],
                                  [-64.897, 272.371],
                                  [78.909, 369.975],
                                  [307.062, 265.504],
                                  [106.505, -35.905],
                                  [260.48, -167.585],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.371, -25.259],
                                  [-3.743, -99.481],
                                  [37.27, -58.396],
                                  [-97.47, -38.262],
                                  [-16.329, -53.281],
                                  [-142.753, 16.039],
                                  [89.264, 70.546],
                                  [-26.935, 109.537],
                                  [-14.017, 92],
                                ],
                                o: [
                                  [-89.972, 29.938],
                                  [3.743, 99.481],
                                  [-37.27, 58.396],
                                  [97.47, 38.262],
                                  [20.674, 58.629],
                                  [108.657, -8.685],
                                  [-89.264, -70.546],
                                  [20.584, -115.219],
                                  [14.017, -92],
                                ],
                                v: [
                                  [-91.166, -338.517],
                                  [-155.093, -184.271],
                                  [-249.268, 15.238],
                                  [-244.578, 152.933],
                                  [-65.282, 272.186],
                                  [79.118, 370.667],
                                  [307.171, 265.806],
                                  [105.678, -36.076],
                                  [261.475, -168.648],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.237, -25.527],
                                  [-4.067, -99.848],
                                  [37.498, -58.171],
                                  [-97.877, -38.219],
                                  [-16.345, -53.469],
                                  [-142.444, 15.898],
                                  [89.813, 70.681],
                                  [-27.167, 110.003],
                                  [-13.956, 92],
                                ],
                                o: [
                                  [-90.073, 30.16],
                                  [4.067, 99.848],
                                  [-37.498, 58.171],
                                  [97.877, 38.219],
                                  [20.647, 58.763],
                                  [108.691, -8.618],
                                  [-89.813, -70.681],
                                  [20.88, -115.629],
                                  [13.956, -92],
                                ],
                                v: [
                                  [-91.434, -338.771],
                                  [-154.706, -183.844],
                                  [-249.663, 15.5],
                                  [-244.265, 152.866],
                                  [-65.742, 272.04],
                                  [79.368, 371.378],
                                  [307.288, 266.116],
                                  [104.836, -36.221],
                                  [262.488, -169.778],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.103, -25.793],
                                  [-4.388, -100.212],
                                  [37.724, -57.948],
                                  [-98.28, -38.177],
                                  [-16.362, -53.655],
                                  [-142.138, 15.758],
                                  [90.358, 70.815],
                                  [-27.397, 110.466],
                                  [-13.896, 92],
                                ],
                                o: [
                                  [-90.172, 30.379],
                                  [4.388, 100.212],
                                  [-37.724, 57.948],
                                  [98.281, 38.177],
                                  [20.621, 58.897],
                                  [108.724, -8.552],
                                  [-90.358, -70.815],
                                  [21.173, -116.035],
                                  [13.897, -92],
                                ],
                                v: [
                                  [-91.719, -338.98],
                                  [-154.296, -183.393],
                                  [-250.058, 15.716],
                                  [-243.997, 152.883],
                                  [-66.153, 271.89],
                                  [79.639, 372.067],
                                  [307.393, 266.373],
                                  [104.042, -36.36],
                                  [263.435, -170.888],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.971, -26.058],
                                  [-4.707, -100.573],
                                  [37.949, -57.727],
                                  [-98.681, -38.135],
                                  [-16.379, -53.84],
                                  [-141.834, 15.62],
                                  [90.899, 70.948],
                                  [-27.625, 110.925],
                                  [-13.837, 92],
                                ],
                                o: [
                                  [-90.272, 30.598],
                                  [4.707, 100.573],
                                  [-37.949, 57.727],
                                  [98.681, 38.135],
                                  [20.594, 59.029],
                                  [108.757, -8.486],
                                  [-90.899, -70.948],
                                  [21.463, -116.438],
                                  [13.837, -92],
                                ],
                                v: [
                                  [-91.939, -339.206],
                                  [-153.968, -182.982],
                                  [-250.454, 15.948],
                                  [-243.771, 152.88],
                                  [-66.575, 271.758],
                                  [79.993, 372.755],
                                  [307.465, 266.659],
                                  [103.275, -36.537],
                                  [264.379, -172.043],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.84, -26.32],
                                  [-5.024, -100.931],
                                  [38.172, -57.507],
                                  [-99.079, -38.093],
                                  [-16.395, -54.024],
                                  [-141.532, 15.482],
                                  [91.436, 71.08],
                                  [-27.851, 111.381],
                                  [-13.778, 92],
                                ],
                                o: [
                                  [-90.37, 30.814],
                                  [5.024, 100.931],
                                  [-38.172, 57.507],
                                  [99.079, 38.093],
                                  [20.568, 59.16],
                                  [108.79, -8.42],
                                  [-91.436, -71.08],
                                  [21.752, -116.838],
                                  [13.778, -92],
                                ],
                                v: [
                                  [-92.093, -339.47],
                                  [-153.617, -182.55],
                                  [-250.849, 16.112],
                                  [-243.525, 152.922],
                                  [-67.03, 271.643],
                                  [80.412, 373.423],
                                  [307.525, 266.973],
                                  [102.515, -36.709],
                                  [265.299, -173.201],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.71, -26.58],
                                  [-5.338, -101.286],
                                  [38.393, -57.289],
                                  [-99.473, -38.051],
                                  [-16.411, -54.206],
                                  [-141.233, 15.345],
                                  [91.969, 71.211],
                                  [-28.076, 111.833],
                                  [-13.719, 92],
                                ],
                                o: [
                                  [-90.468, 31.029],
                                  [5.338, 101.286],
                                  [-38.393, 57.289],
                                  [99.473, 38.051],
                                  [20.542, 59.29],
                                  [108.823, -8.355],
                                  [-91.969, -71.211],
                                  [22.038, -117.235],
                                  [13.719, -92],
                                ],
                                v: [
                                  [-92.263, -339.708],
                                  [-153.327, -182.178],
                                  [-251.245, 16.228],
                                  [-243.259, 152.966],
                                  [-67.497, 271.543],
                                  [80.833, 374.091],
                                  [307.551, 267.274],
                                  [101.802, -36.877],
                                  [266.174, -174.4],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.581, -26.839],
                                  [-5.649, -101.639],
                                  [38.613, -57.073],
                                  [-99.865, -38.01],
                                  [-16.427, -54.387],
                                  [-140.935, 15.21],
                                  [92.498, 71.341],
                                  [-28.298, 112.282],
                                  [-13.661, 92],
                                ],
                                o: [
                                  [-90.565, 31.242],
                                  [5.649, 101.639],
                                  [-38.613, 57.073],
                                  [99.865, 38.01],
                                  [20.516, 59.419],
                                  [108.855, -8.29],
                                  [-92.498, -71.341],
                                  [22.323, -117.629],
                                  [13.661, -92],
                                ],
                                v: [
                                  [-92.387, -339.918],
                                  [-153.098, -181.825],
                                  [-251.597, 16.359],
                                  [-243.036, 153.034],
                                  [-67.957, 271.44],
                                  [81.275, 374.824],
                                  [307.565, 267.561],
                                  [101.073, -37.063],
                                  [267.046, -175.644],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.452, -27.095],
                                  [-5.959, -101.989],
                                  [38.831, -56.858],
                                  [-100.254, -37.969],
                                  [-16.443, -54.567],
                                  [-140.641, 15.075],
                                  [93.023, 71.47],
                                  [-28.52, 112.728],
                                  [-13.604, 92],
                                ],
                                o: [
                                  [-90.661, 31.454],
                                  [5.959, 101.989],
                                  [-38.831, 56.858],
                                  [100.254, 37.969],
                                  [20.49, 59.548],
                                  [108.887, -8.226],
                                  [-93.023, -71.47],
                                  [22.605, -118.02],
                                  [13.604, -92],
                                ],
                                v: [
                                  [-92.445, -340.167],
                                  [-152.867, -181.451],
                                  [-251.95, 16.442],
                                  [-242.853, 153.106],
                                  [-68.447, 271.352],
                                  [81.784, 375.581],
                                  [307.588, 267.874],
                                  [100.393, -37.264],
                                  [267.871, -176.866],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.325, -27.35],
                                  [-6.265, -102.337],
                                  [39.047, -56.645],
                                  [-100.639, -37.929],
                                  [-16.459, -54.745],
                                  [-140.348, 14.941],
                                  [93.544, 71.598],
                                  [-28.739, 113.17],
                                  [-13.546, 92],
                                ],
                                o: [
                                  [-90.756, 31.663],
                                  [6.265, 102.337],
                                  [-39.047, 56.645],
                                  [100.639, 37.929],
                                  [20.465, 59.675],
                                  [108.919, -8.163],
                                  [-93.544, -71.598],
                                  [22.885, -118.408],
                                  [13.546, -92],
                                ],
                                v: [
                                  [-92.541, -340.368],
                                  [-152.678, -181.075],
                                  [-252.302, 16.499],
                                  [-242.651, 153.18],
                                  [-68.949, 271.26],
                                  [82.295, 376.341],
                                  [307.576, 268.132],
                                  [99.718, -37.443],
                                  [268.673, -178.108],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.199, -27.602],
                                  [-6.57, -102.682],
                                  [39.262, -56.433],
                                  [-101.022, -37.888],
                                  [-16.475, -54.921],
                                  [-140.058, 14.809],
                                  [94.061, 71.725],
                                  [-28.957, 113.609],
                                  [-13.49, 92],
                                ],
                                o: [
                                  [-90.851, 31.872],
                                  [6.57, 102.682],
                                  [-39.262, 56.433],
                                  [101.022, 37.888],
                                  [20.44, 59.801],
                                  [108.95, -8.099],
                                  [-94.061, -71.725],
                                  [23.162, -118.793],
                                  [13.49, -92],
                                ],
                                v: [
                                  [-92.548, -340.645],
                                  [-152.507, -180.761],
                                  [-252.654, 16.527],
                                  [-242.492, 153.256],
                                  [-69.419, 271.245],
                                  [82.852, 377.126],
                                  [307.552, 268.477],
                                  [99.05, -37.661],
                                  [269.473, -179.395],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.074, -27.853],
                                  [-6.872, -103.024],
                                  [39.475, -56.223],
                                  [-101.402, -37.849],
                                  [-16.491, -55.097],
                                  [-139.769, 14.677],
                                  [94.573, 71.851],
                                  [-29.173, 114.044],
                                  [-13.433, 92],
                                ],
                                o: [
                                  [-90.945, 32.078],
                                  [6.872, 103.024],
                                  [-39.475, 56.223],
                                  [101.402, 37.848],
                                  [20.415, 59.926],
                                  [108.982, -8.037],
                                  [-94.573, -71.851],
                                  [23.438, -119.175],
                                  [13.433, -92],
                                ],
                                v: [
                                  [-92.549, -340.874],
                                  [-152.378, -180.489],
                                  [-253.005, 16.548],
                                  [-242.379, 153.316],
                                  [-69.9, 271.181],
                                  [83.392, 377.92],
                                  [307.494, 268.766],
                                  [98.449, -37.85],
                                  [270.225, -180.657],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.949, -28.101],
                                  [-7.172, -103.363],
                                  [39.686, -56.015],
                                  [-101.778, -37.809],
                                  [-16.506, -55.271],
                                  [-139.484, 14.547],
                                  [95.082, 71.976],
                                  [-29.387, 114.476],
                                  [-13.377, 92],
                                ],
                                o: [
                                  [-91.038, 32.284],
                                  [7.172, 103.363],
                                  [-39.686, 56.015],
                                  [101.778, 37.809],
                                  [20.39, 60.051],
                                  [109.013, -7.975],
                                  [-95.082, -71.976],
                                  [23.711, -119.554],
                                  [13.377, -92],
                                ],
                                v: [
                                  [-92.547, -341.097],
                                  [-152.266, -180.197],
                                  [-253.312, 16.563],
                                  [-242.248, 153.38],
                                  [-70.435, 271.175],
                                  [83.998, 378.735],
                                  [307.424, 269.036],
                                  [97.811, -38.061],
                                  [270.93, -181.982],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.826, -28.348],
                                  [-7.47, -103.7],
                                  [39.896, -55.809],
                                  [-102.152, -37.77],
                                  [-16.522, -55.444],
                                  [-139.2, 14.417],
                                  [95.587, 72.1],
                                  [-29.6, 114.905],
                                  [-13.322, 92],
                                ],
                                o: [
                                  [-91.13, 32.487],
                                  [7.47, 103.7],
                                  [-39.896, 55.809],
                                  [102.152, 37.77],
                                  [20.365, 60.174],
                                  [109.044, -7.913],
                                  [-95.587, -72.1],
                                  [23.983, -119.931],
                                  [13.322, -92],
                                ],
                                v: [
                                  [-92.477, -341.395],
                                  [-152.127, -179.949],
                                  [-253.619, 16.527],
                                  [-242.182, 153.446],
                                  [-70.894, 271.164],
                                  [84.632, 379.517],
                                  [307.362, 269.329],
                                  [97.223, -38.269],
                                  [271.656, -183.264],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.704, -28.593],
                                  [-7.765, -104.034],
                                  [40.104, -55.604],
                                  [-102.523, -37.731],
                                  [-16.537, -55.615],
                                  [-138.918, 14.289],
                                  [96.088, 72.223],
                                  [-29.811, 115.33],
                                  [-13.267, 92],
                                ],
                                o: [
                                  [-91.222, 32.689],
                                  [7.765, 104.034],
                                  [-40.104, 55.604],
                                  [102.523, 37.731],
                                  [20.341, 60.296],
                                  [109.074, -7.852],
                                  [-96.088, -72.223],
                                  [24.252, -120.304],
                                  [13.267, -92],
                                ],
                                v: [
                                  [-92.422, -341.643],
                                  [-152.03, -179.702],
                                  [-253.925, 16.441],
                                  [-242.143, 153.47],
                                  [-71.407, 271.166],
                                  [85.27, 380.392],
                                  [307.265, 269.628],
                                  [96.64, -38.517],
                                  [272.309, -184.589],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.582, -28.836],
                                  [-8.058, -104.366],
                                  [40.31, -55.4],
                                  [-102.891, -37.692],
                                  [-16.552, -55.785],
                                  [-138.639, 14.161],
                                  [96.585, 72.345],
                                  [-30.021, 115.752],
                                  [-13.212, 92],
                                ],
                                o: [
                                  [-91.313, 32.889],
                                  [8.058, 104.366],
                                  [-40.31, 55.4],
                                  [102.891, 37.692],
                                  [20.316, 60.418],
                                  [109.104, -7.791],
                                  [-96.585, -72.345],
                                  [24.519, -120.674],
                                  [13.212, -92],
                                ],
                                v: [
                                  [-92.299, -341.885],
                                  [-151.923, -179.498],
                                  [-254.23, 16.393],
                                  [-242.106, 153.524],
                                  [-71.886, 271.199],
                                  [85.911, 381.208],
                                  [307.156, 269.93],
                                  [96.08, -38.763],
                                  [272.984, -185.852],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.462, -29.077],
                                  [-8.349, -104.695],
                                  [40.515, -55.198],
                                  [-103.257, -37.653],
                                  [-16.567, -55.954],
                                  [-138.362, 14.035],
                                  [97.078, 72.467],
                                  [-30.228, 116.17],
                                  [-13.158, 92],
                                ],
                                o: [
                                  [-91.404, 33.088],
                                  [8.349, 104.695],
                                  [-40.515, 55.198],
                                  [103.257, 37.653],
                                  [20.292, 60.538],
                                  [109.135, -7.731],
                                  [-97.078, -72.467],
                                  [24.784, -121.042],
                                  [13.158, -92],
                                ],
                                v: [
                                  [-92.172, -342.157],
                                  [-151.876, -179.277],
                                  [-254.517, 16.294],
                                  [-242.117, 153.554],
                                  [-72.375, 271.226],
                                  [86.6, 382.056],
                                  [307.029, 270.193],
                                  [95.571, -39.005],
                                  [273.629, -187.115],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.342, -29.316],
                                  [-8.637, -105.022],
                                  [40.718, -54.998],
                                  [-103.619, -37.615],
                                  [-16.582, -56.121],
                                  [-138.087, 13.909],
                                  [97.568, 72.587],
                                  [-30.435, 116.586],
                                  [-13.104, 92],
                                ],
                                o: [
                                  [-91.493, 33.285],
                                  [8.637, 105.022],
                                  [-40.718, 54.998],
                                  [103.619, 37.615],
                                  [20.268, 60.658],
                                  [109.164, -7.671],
                                  [-97.568, -72.587],
                                  [25.047, -121.406],
                                  [13.104, -92],
                                ],
                                v: [
                                  [-92.04, -342.422],
                                  [-151.781, -179.102],
                                  [-254.776, 16.143],
                                  [-242.13, 153.613],
                                  [-72.829, 271.265],
                                  [87.275, 382.908],
                                  [306.908, 270.478],
                                  [95.023, -39.245],
                                  [274.225, -188.423],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.224, -29.553],
                                  [-8.923, -105.346],
                                  [40.92, -54.8],
                                  [-103.978, -37.577],
                                  [-16.597, -56.287],
                                  [-137.814, 13.785],
                                  [98.053, 72.706],
                                  [-30.639, 116.998],
                                  [-13.051, 92],
                                ],
                                o: [
                                  [-91.582, 33.481],
                                  [8.923, 105.346],
                                  [-40.92, 54.8],
                                  [103.978, 37.578],
                                  [20.245, 60.776],
                                  [109.194, -7.612],
                                  [-98.053, -72.706],
                                  [25.308, -121.768],
                                  [13.051, -92],
                                ],
                                v: [
                                  [-91.859, -342.697],
                                  [-151.762, -178.973],
                                  [-255.017, 16.047],
                                  [-242.173, 153.631],
                                  [-73.31, 271.342],
                                  [88, 383.776],
                                  [306.794, 270.749],
                                  [94.525, -39.544],
                                  [274.815, -189.668],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.106, -29.788],
                                  [-9.207, -105.667],
                                  [41.12, -54.602],
                                  [-104.335, -37.54],
                                  [-16.612, -56.452],
                                  [-137.544, 13.661],
                                  [98.535, 72.825],
                                  [-30.842, 117.407],
                                  [-12.998, 92],
                                ],
                                o: [
                                  [-91.671, 33.675],
                                  [9.207, 105.667],
                                  [-41.12, 54.602],
                                  [104.335, 37.54],
                                  [20.221, 60.894],
                                  [109.223, -7.553],
                                  [-98.535, -72.825],
                                  [25.567, -122.127],
                                  [12.998, -92],
                                ],
                                v: [
                                  [-91.655, -342.965],
                                  [-151.74, -178.828],
                                  [-255.275, 15.883],
                                  [-242.238, 153.696],
                                  [-73.783, 271.431],
                                  [88.728, 384.667],
                                  [306.66, 270.996],
                                  [94.033, -39.823],
                                  [275.419, -190.914],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.989, -30.022],
                                  [-9.489, -105.986],
                                  [41.318, -54.407],
                                  [-104.689, -37.503],
                                  [-16.626, -56.615],
                                  [-137.275, 13.539],
                                  [99.013, 72.942],
                                  [-31.044, 117.813],
                                  [-12.945, 92],
                                ],
                                o: [
                                  [-91.758, 33.868],
                                  [9.489, 105.986],
                                  [-41.318, 54.407],
                                  [104.689, 37.503],
                                  [20.198, 61.011],
                                  [109.253, -7.495],
                                  [-99.013, -72.942],
                                  [25.824, -122.483],
                                  [12.945, -92],
                                ],
                                v: [
                                  [-91.464, -343.26],
                                  [-151.731, -178.702],
                                  [-255.515, 15.711],
                                  [-242.333, 153.719],
                                  [-74.22, 271.531],
                                  [89.49, 385.529],
                                  [306.531, 271.229],
                                  [93.591, -40.099],
                                  [275.955, -192.161],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.873, -30.253],
                                  [-9.768, -106.302],
                                  [41.515, -54.213],
                                  [-105.04, -37.466],
                                  [-16.641, -56.777],
                                  [-137.009, 13.417],
                                  [99.487, 73.059],
                                  [-31.243, 118.215],
                                  [-12.893, 92],
                                ],
                                o: [
                                  [-91.845, 34.059],
                                  [9.768, 106.302],
                                  [-41.515, 54.213],
                                  [105.04, 37.466],
                                  [20.175, 61.127],
                                  [109.282, -7.437],
                                  [-99.487, -73.059],
                                  [26.079, -122.836],
                                  [12.893, -92],
                                ],
                                v: [
                                  [-91.268, -343.594],
                                  [-151.781, -178.589],
                                  [-255.708, 15.549],
                                  [-242.478, 153.773],
                                  [-74.62, 271.625],
                                  [90.228, 386.398],
                                  [306.408, 271.483],
                                  [93.127, -40.436],
                                  [276.503, -193.345],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.758, -30.483],
                                  [-10.045, -106.616],
                                  [41.711, -54.02],
                                  [-105.388, -37.429],
                                  [-16.655, -56.938],
                                  [-136.745, 13.296],
                                  [99.958, 73.174],
                                  [-31.442, 118.614],
                                  [-12.841, 92],
                                ],
                                o: [
                                  [-91.931, 34.249],
                                  [10.045, 106.616],
                                  [-41.711, 54.02],
                                  [105.388, 37.429],
                                  [20.152, 61.241],
                                  [109.31, -7.379],
                                  [-99.957, -73.174],
                                  [26.331, -123.187],
                                  [12.841, -92],
                                ],
                                v: [
                                  [-91.005, -343.891],
                                  [-151.844, -178.541],
                                  [-255.884, 15.364],
                                  [-242.626, 153.802],
                                  [-75.045, 271.712],
                                  [90.999, 387.256],
                                  [306.246, 271.694],
                                  [92.697, -40.707],
                                  [277, -194.575],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.645, -30.711],
                                  [-10.32, -106.927],
                                  [41.904, -53.83],
                                  [-105.734, -37.393],
                                  [-16.669, -57.098],
                                  [-136.482, 13.177],
                                  [100.424, 73.289],
                                  [-31.638, 119.01],
                                  [-12.79, 92],
                                ],
                                o: [
                                  [-92.017, 34.437],
                                  [10.32, 106.927],
                                  [-41.904, 53.829],
                                  [105.734, 37.393],
                                  [20.129, 61.356],
                                  [109.339, -7.322],
                                  [-100.424, -73.289],
                                  [26.582, -123.534],
                                  [12.79, -92],
                                ],
                                v: [
                                  [-90.737, -344.18],
                                  [-151.903, -178.479],
                                  [-256.059, 15.126],
                                  [-242.825, 153.879],
                                  [-75.462, 271.856],
                                  [91.775, 388.121],
                                  [306.105, 271.908],
                                  [92.29, -41.084],
                                  [277.491, -195.76],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.531, -30.937],
                                  [-10.593, -107.236],
                                  [42.097, -53.64],
                                  [-106.077, -37.357],
                                  [-16.684, -57.256],
                                  [-136.222, 13.058],
                                  [100.887, 73.403],
                                  [-31.833, 119.403],
                                  [-12.739, 92],
                                ],
                                o: [
                                  [-92.101, 34.623],
                                  [10.593, 107.236],
                                  [-42.097, 53.64],
                                  [106.077, 37.357],
                                  [20.106, 61.469],
                                  [109.367, -7.266],
                                  [-100.887, -73.403],
                                  [26.831, -123.879],
                                  [12.739, -92],
                                ],
                                v: [
                                  [-90.48, -344.507],
                                  [-151.99, -178.483],
                                  [-256.187, 14.927],
                                  [-243.028, 153.959],
                                  [-75.857, 271.964],
                                  [92.54, 388.976],
                                  [305.987, 272.141],
                                  [91.888, -41.413],
                                  [277.931, -196.945],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.419, -31.162],
                                  [-10.864, -107.542],
                                  [42.287, -53.452],
                                  [-106.417, -37.321],
                                  [-16.698, -57.413],
                                  [-135.964, 12.94],
                                  [101.346, 73.516],
                                  [-32.027, 119.793],
                                  [-12.689, 92],
                                ],
                                o: [
                                  [-92.186, 34.808],
                                  [10.864, 107.542],
                                  [-42.287, 53.452],
                                  [106.417, 37.321],
                                  [20.084, 61.581],
                                  [109.395, -7.21],
                                  [-101.346, -73.516],
                                  [27.078, -124.221],
                                  [12.689, -92],
                                ],
                                v: [
                                  [-90.173, -344.827],
                                  [-152.119, -178.473],
                                  [-256.361, 14.721],
                                  [-243.281, 153.979],
                                  [-76.197, 272.11],
                                  [93.356, 389.821],
                                  [305.81, 272.36],
                                  [91.522, -41.786],
                                  [278.427, -198.131],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.308, -31.384],
                                  [-11.132, -107.846],
                                  [42.476, -53.266],
                                  [-106.754, -37.286],
                                  [-16.711, -57.569],
                                  [-135.708, 12.823],
                                  [101.802, 73.628],
                                  [-32.219, 120.18],
                                  [-12.639, 92],
                                ],
                                o: [
                                  [-92.269, 34.992],
                                  [11.132, 107.846],
                                  [-42.476, 53.266],
                                  [106.754, 37.286],
                                  [20.062, 61.692],
                                  [109.423, -7.154],
                                  [-101.802, -73.628],
                                  [27.323, -124.561],
                                  [12.639, -92],
                                ],
                                v: [
                                  [-89.906, -345.092],
                                  [-152.259, -178.497],
                                  [-256.472, 14.463],
                                  [-243.539, 154.064],
                                  [-76.515, 272.251],
                                  [94.178, 390.673],
                                  [305.654, 272.552],
                                  [91.178, -42.156],
                                  [278.809, -199.3],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.198, -31.605],
                                  [-11.398, -108.148],
                                  [42.664, -53.081],
                                  [-107.089, -37.25],
                                  [-16.725, -57.723],
                                  [-135.454, 12.707],
                                  [102.254, 73.739],
                                  [-32.409, 120.563],
                                  [-12.589, 92],
                                ],
                                o: [
                                  [-92.352, 35.174],
                                  [11.398, 108.148],
                                  [-42.664, 53.081],
                                  [107.089, 37.25],
                                  [20.04, 61.802],
                                  [109.451, -7.099],
                                  [-102.254, -73.739],
                                  [27.565, -124.897],
                                  [12.589, -92],
                                ],
                                v: [
                                  [-89.588, -345.413],
                                  [-152.411, -178.54],
                                  [-256.535, 14.228],
                                  [-243.847, 154.153],
                                  [-76.823, 272.432],
                                  [94.958, 391.516],
                                  [305.502, 272.761],
                                  [90.839, -42.525],
                                  [279.2, -200.47],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.088, -31.824],
                                  [-11.663, -108.447],
                                  [42.85, -52.897],
                                  [-107.421, -37.216],
                                  [-16.739, -57.877],
                                  [-135.203, 12.593],
                                  [102.702, 73.849],
                                  [-32.598, 120.944],
                                  [-12.54, 92],
                                ],
                                o: [
                                  [-92.434, 35.355],
                                  [11.663, 108.447],
                                  [-42.85, 52.898],
                                  [107.421, 37.216],
                                  [20.018, 61.912],
                                  [109.478, -7.044],
                                  [-102.702, -73.849],
                                  [27.806, -125.231],
                                  [12.54, -92],
                                ],
                                v: [
                                  [-89.311, -345.757],
                                  [-152.604, -178.572],
                                  [-256.629, 13.987],
                                  [-244.161, 154.181],
                                  [-77.153, 272.576],
                                  [95.775, 392.319],
                                  [305.323, 272.957],
                                  [90.507, -42.953],
                                  [279.6, -201.578],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.979, -32.041],
                                  [-11.925, -108.744],
                                  [43.035, -52.716],
                                  [-107.75, -37.181],
                                  [-16.753, -58.029],
                                  [-134.953, 12.478],
                                  [103.147, 73.958],
                                  [-32.785, 121.321],
                                  [-12.491, 92],
                                ],
                                o: [
                                  [-92.515, 35.534],
                                  [11.925, 108.744],
                                  [-43.035, 52.716],
                                  [107.75, 37.181],
                                  [19.996, 62.021],
                                  [109.505, -6.99],
                                  [-103.147, -73.958],
                                  [28.045, -125.563],
                                  [12.491, -92],
                                ],
                                v: [
                                  [-88.998, -346.048],
                                  [-152.809, -178.654],
                                  [-256.676, 13.756],
                                  [-244.479, 154.275],
                                  [-77.381, 272.776],
                                  [96.597, 393.161],
                                  [305.148, 273.122],
                                  [90.18, -43.411],
                                  [279.978, -202.702],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.872, -32.257],
                                  [-12.184, -109.038],
                                  [43.218, -52.535],
                                  [-108.076, -37.147],
                                  [-16.766, -58.18],
                                  [-134.705, 12.365],
                                  [103.588, 74.067],
                                  [-32.971, 121.696],
                                  [-12.442, 92],
                                ],
                                o: [
                                  [-92.596, 35.712],
                                  [12.184, 109.038],
                                  [-43.218, 52.535],
                                  [108.076, 37.147],
                                  [19.974, 62.128],
                                  [109.532, -6.936],
                                  [-103.588, -74.067],
                                  [28.282, -125.891],
                                  [12.442, -92],
                                ],
                                v: [
                                  [-88.679, -346.378],
                                  [-153.008, -178.74],
                                  [-256.722, 13.456],
                                  [-244.881, 154.371],
                                  [-77.631, 272.953],
                                  [97.425, 393.962],
                                  [304.976, 273.289],
                                  [89.906, -43.836],
                                  [280.318, -203.763],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.765, -32.47],
                                  [-12.442, -109.33],
                                  [43.4, -52.356],
                                  [-108.4, -37.113],
                                  [-16.779, -58.329],
                                  [-134.459, 12.253],
                                  [104.025, 74.174],
                                  [-33.156, 122.067],
                                  [-12.394, 92],
                                ],
                                o: [
                                  [-92.676, 35.888],
                                  [12.442, 109.33],
                                  [-43.4, 52.356],
                                  [108.4, 37.113],
                                  [19.953, 62.235],
                                  [109.559, -6.882],
                                  [-104.025, -74.174],
                                  [28.517, -126.217],
                                  [12.394, -92],
                                ],
                                v: [
                                  [-88.402, -346.67],
                                  [-153.28, -178.862],
                                  [-256.767, 13.229],
                                  [-245.256, 154.47],
                                  [-77.823, 273.14],
                                  [98.243, 394.755],
                                  [304.775, 273.472],
                                  [89.638, -44.32],
                                  [280.603, -204.872],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.659, -32.682],
                                  [-12.698, -109.619],
                                  [43.58, -52.179],
                                  [-108.721, -37.079],
                                  [-16.793, -58.478],
                                  [-134.215, 12.142],
                                  [104.459, 74.281],
                                  [-33.338, 122.435],
                                  [-12.347, 92],
                                ],
                                o: [
                                  [-92.756, 36.063],
                                  [12.698, 109.619],
                                  [-43.58, 52.179],
                                  [108.721, 37.079],
                                  [19.932, 62.341],
                                  [109.585, -6.829],
                                  [-104.459, -74.281],
                                  [28.75, -126.54],
                                  [12.346, -92],
                                ],
                                v: [
                                  [-88.072, -346.987],
                                  [-153.595, -179.005],
                                  [-256.75, 12.964],
                                  [-245.683, 154.524],
                                  [-78.037, 273.368],
                                  [99.067, 395.508],
                                  [304.625, 273.656],
                                  [89.36, -44.835],
                                  [280.929, -205.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.554, -32.892],
                                  [-12.952, -109.906],
                                  [43.759, -52.003],
                                  [-109.04, -37.045],
                                  [-16.806, -58.625],
                                  [-133.974, 12.031],
                                  [104.889, 74.386],
                                  [-33.52, 122.801],
                                  [-12.299, 92],
                                ],
                                o: [
                                  [-92.835, 36.236],
                                  [12.952, 109.906],
                                  [-43.759, 52.003],
                                  [109.04, 37.045],
                                  [19.911, 62.446],
                                  [109.612, -6.777],
                                  [-104.889, -74.387],
                                  [28.982, -126.861],
                                  [12.299, -92],
                                ],
                                v: [
                                  [-87.799, -347.344],
                                  [-153.905, -179.136],
                                  [-256.747, 12.679],
                                  [-246.163, 154.643],
                                  [-78.208, 273.591],
                                  [99.896, 396.3],
                                  [304.445, 273.856],
                                  [89.104, -45.364],
                                  [281.153, -206.981],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.45, -33.101],
                                  [-13.203, -110.191],
                                  [43.936, -51.828],
                                  [-109.356, -37.012],
                                  [-16.819, -58.771],
                                  [-133.734, 11.922],
                                  [105.316, 74.491],
                                  [-33.7, 123.163],
                                  [-12.252, 92],
                                ],
                                o: [
                                  [-92.913, 36.408],
                                  [13.203, 110.191],
                                  [-43.936, 51.828],
                                  [109.356, 37.012],
                                  [19.89, 62.551],
                                  [109.638, -6.725],
                                  [-105.316, -74.491],
                                  [29.211, -127.179],
                                  [12.252, -92],
                                ],
                                v: [
                                  [-87.473, -347.648],
                                  [-154.225, -179.32],
                                  [-256.714, 12.451],
                                  [-246.649, 154.749],
                                  [-78.274, 273.807],
                                  [100.717, 397.052],
                                  [304.219, 274.008],
                                  [88.902, -45.89],
                                  [281.431, -207.981],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.346, -33.308],
                                  [-13.453, -110.473],
                                  [44.112, -51.655],
                                  [-109.67, -36.979],
                                  [-16.832, -58.915],
                                  [-133.496, 11.813],
                                  [105.739, 74.596],
                                  [-33.878, 123.522],
                                  [-12.206, 92],
                                ],
                                o: [
                                  [-92.99, 36.579],
                                  [13.453, 110.473],
                                  [-44.112, 51.655],
                                  [109.67, 36.979],
                                  [19.869, 62.654],
                                  [109.664, -6.673],
                                  [-105.739, -74.596],
                                  [29.439, -127.494],
                                  [12.206, -92],
                                ],
                                v: [
                                  [-87.19, -348.008],
                                  [-154.603, -179.509],
                                  [-256.695, 12.202],
                                  [-247.125, 154.872],
                                  [-78.359, 274.032],
                                  [101.495, 397.762],
                                  [304.044, 274.175],
                                  [88.691, -46.463],
                                  [281.654, -208.98],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.244, -33.513],
                                  [-13.7, -110.754],
                                  [44.286, -51.483],
                                  [-109.98, -36.946],
                                  [-16.845, -59.059],
                                  [-133.26, 11.706],
                                  [106.159, 74.699],
                                  [-34.055, 123.879],
                                  [-12.16, 92],
                                ],
                                o: [
                                  [-93.067, 36.748],
                                  [13.7, 110.754],
                                  [-44.286, 51.483],
                                  [109.98, 36.946],
                                  [19.849, 62.756],
                                  [109.689, -6.622],
                                  [-106.159, -74.699],
                                  [29.664, -127.807],
                                  [12.16, -92],
                                ],
                                v: [
                                  [-86.915, -348.395],
                                  [-154.975, -179.688],
                                  [-256.628, 11.997],
                                  [-247.669, 154.95],
                                  [-78.433, 274.284],
                                  [102.328, 398.465],
                                  [303.837, 274.342],
                                  [88.501, -47.048],
                                  [281.87, -209.932],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.142, -33.716],
                                  [-13.945, -111.031],
                                  [44.459, -51.313],
                                  [-110.289, -36.914],
                                  [-16.857, -59.201],
                                  [-133.026, 11.599],
                                  [106.576, 74.801],
                                  [-34.23, 124.232],
                                  [-12.114, 92],
                                ],
                                o: [
                                  [-93.144, 36.916],
                                  [13.945, 111.031],
                                  [-44.459, 51.313],
                                  [110.289, 36.914],
                                  [19.828, 62.858],
                                  [109.715, -6.571],
                                  [-106.576, -74.801],
                                  [29.888, -128.117],
                                  [12.114, -92],
                                ],
                                v: [
                                  [-86.635, -348.742],
                                  [-155.391, -179.887],
                                  [-256.546, 11.725],
                                  [-248.219, 155.077],
                                  [-78.43, 274.497],
                                  [103.166, 399.125],
                                  [303.631, 274.509],
                                  [88.351, -47.68],
                                  [282.043, -210.931],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.041, -33.918],
                                  [-14.189, -111.307],
                                  [44.63, -51.144],
                                  [-110.595, -36.882],
                                  [-16.87, -59.343],
                                  [-132.794, 11.493],
                                  [106.989, 74.902],
                                  [-34.404, 124.583],
                                  [-12.068, 92],
                                ],
                                o: [
                                  [-93.219, 37.082],
                                  [14.189, 111.307],
                                  [-44.63, 51.144],
                                  [110.595, 36.882],
                                  [19.808, 62.959],
                                  [109.74, -6.52],
                                  [-106.988, -74.903],
                                  [30.11, -128.425],
                                  [12.068, -92],
                                ],
                                v: [
                                  [-86.363, -349.118],
                                  [-155.817, -180.125],
                                  [-256.463, 11.51],
                                  [-248.788, 155.158],
                                  [-78.369, 274.739],
                                  [103.947, 399.778],
                                  [303.441, 274.641],
                                  [88.159, -48.31],
                                  [282.209, -211.819],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.142, -33.716],
                                  [-13.945, -111.031],
                                  [44.459, -51.313],
                                  [-110.289, -36.914],
                                  [-16.857, -59.201],
                                  [-133.026, 11.599],
                                  [106.576, 74.801],
                                  [-34.23, 124.232],
                                  [-12.114, 92],
                                ],
                                o: [
                                  [-93.144, 36.916],
                                  [13.945, 111.031],
                                  [-44.459, 51.313],
                                  [110.289, 36.914],
                                  [19.828, 62.858],
                                  [109.715, -6.571],
                                  [-106.576, -74.801],
                                  [29.888, -128.117],
                                  [12.114, -92],
                                ],
                                v: [
                                  [-85.244, -348.917],
                                  [-156.215, -180.789],
                                  [-256.458, 10.68],
                                  [-249.238, 155.19],
                                  [-79.013, 274.787],
                                  [104.791, 399.974],
                                  [303.477, 274.494],
                                  [89.313, -48.014],
                                  [281.233, -211.972],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.244, -33.513],
                                  [-13.7, -110.754],
                                  [44.286, -51.483],
                                  [-109.98, -36.946],
                                  [-16.845, -59.059],
                                  [-133.26, 11.706],
                                  [106.159, 74.699],
                                  [-34.055, 123.879],
                                  [-12.16, 92],
                                ],
                                o: [
                                  [-93.067, 36.748],
                                  [13.7, 110.754],
                                  [-44.286, 51.483],
                                  [109.98, 36.946],
                                  [19.849, 62.756],
                                  [109.689, -6.622],
                                  [-106.159, -74.699],
                                  [29.664, -127.807],
                                  [12.16, -92],
                                ],
                                v: [
                                  [-84.137, -348.751],
                                  [-156.63, -181.485],
                                  [-256.503, 9.848],
                                  [-249.682, 155.156],
                                  [-79.611, 274.886],
                                  [105.644, 400.145],
                                  [303.514, 274.395],
                                  [90.472, -47.759],
                                  [280.207, -212.056],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.346, -33.308],
                                  [-13.452, -110.473],
                                  [44.112, -51.655],
                                  [-109.67, -36.979],
                                  [-16.832, -58.915],
                                  [-133.496, 11.813],
                                  [105.739, 74.596],
                                  [-33.878, 123.522],
                                  [-12.206, 92],
                                ],
                                o: [
                                  [-92.99, 36.579],
                                  [13.453, 110.473],
                                  [-44.112, 51.655],
                                  [109.67, 36.979],
                                  [19.869, 62.654],
                                  [109.663, -6.673],
                                  [-105.739, -74.595],
                                  [29.439, -127.494],
                                  [12.206, -92],
                                ],
                                v: [
                                  [-83.042, -348.601],
                                  [-157.05, -182.133],
                                  [-256.439, 9.001],
                                  [-250.118, 155.149],
                                  [-80.102, 274.975],
                                  [106.477, 400.259],
                                  [303.533, 274.246],
                                  [91.682, -47.497],
                                  [279.195, -212.1],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.449, -33.101],
                                  [-13.203, -110.191],
                                  [43.936, -51.828],
                                  [-109.356, -37.012],
                                  [-16.819, -58.771],
                                  [-133.734, 11.922],
                                  [105.316, 74.491],
                                  [-33.7, 123.163],
                                  [-12.252, 92],
                                ],
                                o: [
                                  [-92.913, 36.408],
                                  [13.203, 110.191],
                                  [-43.936, 51.828],
                                  [109.356, 37.012],
                                  [19.89, 62.55],
                                  [109.638, -6.725],
                                  [-105.316, -74.491],
                                  [29.211, -127.179],
                                  [12.252, -92],
                                ],
                                v: [
                                  [-81.947, -348.436],
                                  [-157.584, -182.826],
                                  [-256.44, 8.214],
                                  [-250.61, 155.125],
                                  [-80.611, 275.082],
                                  [107.257, 400.301],
                                  [303.539, 274.078],
                                  [92.896, -47.291],
                                  [278.183, -212.123],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.554, -32.892],
                                  [-12.952, -109.906],
                                  [43.759, -52.003],
                                  [-109.04, -37.045],
                                  [-16.806, -58.625],
                                  [-133.974, 12.031],
                                  [104.889, 74.387],
                                  [-33.52, 122.801],
                                  [-12.299, 92],
                                ],
                                o: [
                                  [-92.835, 36.236],
                                  [12.952, 109.906],
                                  [-43.759, 52.003],
                                  [109.04, 37.045],
                                  [19.911, 62.446],
                                  [109.612, -6.777],
                                  [-104.889, -74.387],
                                  [28.982, -126.861],
                                  [12.299, -92],
                                ],
                                v: [
                                  [-80.897, -348.334],
                                  [-158.075, -183.518],
                                  [-256.427, 7.425],
                                  [-251.08, 155.113],
                                  [-81.076, 275.178],
                                  [108.064, 400.348],
                                  [303.56, 273.942],
                                  [94.099, -47.077],
                                  [277.122, -212.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.659, -32.682],
                                  [-12.698, -109.619],
                                  [43.58, -52.179],
                                  [-108.721, -37.079],
                                  [-16.793, -58.478],
                                  [-134.215, 12.142],
                                  [104.459, 74.281],
                                  [-33.338, 122.435],
                                  [-12.346, 92],
                                ],
                                o: [
                                  [-92.756, 36.063],
                                  [12.698, 109.619],
                                  [-43.58, 52.179],
                                  [108.721, 37.079],
                                  [19.932, 62.341],
                                  [109.585, -6.829],
                                  [-104.459, -74.281],
                                  [28.75, -126.54],
                                  [12.347, -92],
                                ],
                                v: [
                                  [-79.861, -348.218],
                                  [-158.602, -184.161],
                                  [-256.369, 6.667],
                                  [-251.604, 155.052],
                                  [-81.483, 275.292],
                                  [108.818, 400.323],
                                  [303.599, 273.82],
                                  [95.274, -46.951],
                                  [276.124, -212.089],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.765, -32.47],
                                  [-12.442, -109.33],
                                  [43.4, -52.356],
                                  [-108.4, -37.113],
                                  [-16.779, -58.329],
                                  [-134.459, 12.253],
                                  [104.025, 74.174],
                                  [-33.156, 122.067],
                                  [-12.394, 92],
                                ],
                                o: [
                                  [-92.676, 35.888],
                                  [12.442, 109.33],
                                  [-43.4, 52.356],
                                  [108.4, 37.113],
                                  [19.953, 62.235],
                                  [109.559, -6.882],
                                  [-104.025, -74.174],
                                  [28.517, -126.217],
                                  [12.394, -92],
                                ],
                                v: [
                                  [-78.855, -348.164],
                                  [-159.164, -184.803],
                                  [-256.312, 5.906],
                                  [-252.058, 154.988],
                                  [-81.861, 275.442],
                                  [109.518, 400.257],
                                  [303.64, 273.666],
                                  [96.437, -46.785],
                                  [275.031, -212.031],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.872, -32.257],
                                  [-12.184, -109.038],
                                  [43.218, -52.535],
                                  [-108.076, -37.147],
                                  [-16.766, -58.18],
                                  [-134.705, 12.365],
                                  [103.588, 74.067],
                                  [-32.971, 121.696],
                                  [-12.442, 92],
                                ],
                                o: [
                                  [-92.596, 35.712],
                                  [12.184, 109.038],
                                  [-43.218, 52.535],
                                  [108.076, 37.147],
                                  [19.974, 62.128],
                                  [109.532, -6.936],
                                  [-103.588, -74.067],
                                  [28.282, -125.891],
                                  [12.442, -92],
                                ],
                                v: [
                                  [-77.879, -348.111],
                                  [-159.762, -185.443],
                                  [-256.211, 5.19],
                                  [-252.567, 154.939],
                                  [-82.182, 275.58],
                                  [110.261, 400.166],
                                  [303.697, 273.512],
                                  [97.604, -46.706],
                                  [273.985, -211.903],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.979, -32.041],
                                  [-11.925, -108.744],
                                  [43.035, -52.716],
                                  [-107.75, -37.181],
                                  [-16.753, -58.029],
                                  [-134.953, 12.478],
                                  [103.146, 73.958],
                                  [-32.785, 121.321],
                                  [-12.491, 92],
                                ],
                                o: [
                                  [-92.515, 35.534],
                                  [11.925, 108.744],
                                  [-43.035, 52.716],
                                  [107.75, 37.181],
                                  [19.996, 62.021],
                                  [109.505, -6.99],
                                  [-103.147, -73.958],
                                  [28.045, -125.563],
                                  [12.491, -92],
                                ],
                                v: [
                                  [-76.932, -348.057],
                                  [-160.426, -186.066],
                                  [-256.142, 4.472],
                                  [-253.068, 154.888],
                                  [-82.523, 275.736],
                                  [110.888, 400.018],
                                  [303.758, 273.42],
                                  [98.713, -46.635],
                                  [272.909, -211.77],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.088, -31.824],
                                  [-11.663, -108.447],
                                  [42.85, -52.898],
                                  [-107.421, -37.216],
                                  [-16.739, -57.877],
                                  [-135.203, 12.592],
                                  [102.702, 73.849],
                                  [-32.598, 120.944],
                                  [-12.54, 92],
                                ],
                                o: [
                                  [-92.434, 35.355],
                                  [11.663, 108.447],
                                  [-42.85, 52.898],
                                  [107.421, 37.216],
                                  [20.018, 61.912],
                                  [109.478, -7.044],
                                  [-102.702, -73.849],
                                  [27.806, -125.231],
                                  [12.54, -92],
                                ],
                                v: [
                                  [-75.998, -348.051],
                                  [-161.047, -186.656],
                                  [-256.045, 3.828],
                                  [-253.561, 154.804],
                                  [-82.775, 275.927],
                                  [111.556, 399.909],
                                  [303.836, 273.297],
                                  [99.795, -46.555],
                                  [271.834, -211.583],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.198, -31.605],
                                  [-11.398, -108.148],
                                  [42.664, -53.081],
                                  [-107.089, -37.25],
                                  [-16.725, -57.723],
                                  [-135.454, 12.707],
                                  [102.254, 73.739],
                                  [-32.409, 120.563],
                                  [-12.589, 92],
                                ],
                                o: [
                                  [-92.352, 35.174],
                                  [11.398, 108.148],
                                  [-42.664, 53.081],
                                  [107.089, 37.25],
                                  [20.039, 61.802],
                                  [109.451, -7.099],
                                  [-102.254, -73.739],
                                  [27.565, -124.897],
                                  [12.589, -92],
                                ],
                                v: [
                                  [-75.079, -348.045],
                                  [-161.703, -187.245],
                                  [-255.934, 3.15],
                                  [-254.109, 154.72],
                                  [-82.971, 276.12],
                                  [112.156, 399.743],
                                  [303.887, 273.16],
                                  [100.911, -46.577],
                                  [270.759, -211.39],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.308, -31.384],
                                  [-11.132, -107.846],
                                  [42.476, -53.266],
                                  [-106.754, -37.286],
                                  [-16.711, -57.569],
                                  [-135.708, 12.823],
                                  [101.802, 73.628],
                                  [-32.219, 120.18],
                                  [-12.639, 92],
                                ],
                                o: [
                                  [-92.269, 34.992],
                                  [11.132, 107.846],
                                  [-42.476, 53.266],
                                  [106.754, 37.286],
                                  [20.062, 61.692],
                                  [109.423, -7.154],
                                  [-101.802, -73.628],
                                  [27.323, -124.561],
                                  [12.639, -92],
                                ],
                                v: [
                                  [-74.189, -348.085],
                                  [-162.425, -187.832],
                                  [-255.841, 2.561],
                                  [-254.585, 154.588],
                                  [-83.142, 276.316],
                                  [112.765, 399.521],
                                  [303.987, 273.055],
                                  [101.939, -46.528],
                                  [269.702, -211.128],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.419, -31.162],
                                  [-10.864, -107.542],
                                  [42.287, -53.452],
                                  [-106.417, -37.321],
                                  [-16.698, -57.413],
                                  [-135.964, 12.94],
                                  [101.346, 73.516],
                                  [-32.027, 119.793],
                                  [-12.689, 92],
                                ],
                                o: [
                                  [-92.186, 34.808],
                                  [10.864, 107.542],
                                  [-42.287, 53.452],
                                  [106.417, 37.321],
                                  [20.084, 61.581],
                                  [109.395, -7.21],
                                  [-101.346, -73.516],
                                  [27.078, -124.221],
                                  [12.689, -92],
                                ],
                                v: [
                                  [-73.36, -348.124],
                                  [-163.105, -188.356],
                                  [-255.687, 1.952],
                                  [-255.116, 154.425],
                                  [-83.304, 276.499],
                                  [113.353, 399.336],
                                  [304.107, 272.905],
                                  [103.002, -46.596],
                                  [268.6, -210.797],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.531, -30.937],
                                  [-10.593, -107.236],
                                  [42.097, -53.64],
                                  [-106.077, -37.357],
                                  [-16.684, -57.256],
                                  [-136.222, 13.058],
                                  [100.887, 73.403],
                                  [-31.833, 119.403],
                                  [-12.739, 92],
                                ],
                                o: [
                                  [-92.101, 34.623],
                                  [10.593, 107.236],
                                  [-42.097, 53.64],
                                  [106.077, 37.357],
                                  [20.106, 61.469],
                                  [109.367, -7.266],
                                  [-100.887, -73.403],
                                  [26.831, -123.879],
                                  [12.739, -92],
                                ],
                                v: [
                                  [-72.528, -348.21],
                                  [-163.789, -188.941],
                                  [-255.583, 1.339],
                                  [-255.622, 154.263],
                                  [-83.396, 276.667],
                                  [113.872, 399.095],
                                  [304.184, 272.802],
                                  [104.039, -46.655],
                                  [267.516, -210.506],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.644, -30.711],
                                  [-10.32, -106.927],
                                  [41.904, -53.83],
                                  [-105.734, -37.393],
                                  [-16.669, -57.098],
                                  [-136.482, 13.177],
                                  [100.424, 73.289],
                                  [-31.638, 119.01],
                                  [-12.79, 92],
                                ],
                                o: [
                                  [-92.017, 34.437],
                                  [10.32, 106.927],
                                  [-41.904, 53.829],
                                  [105.734, 37.393],
                                  [20.129, 61.355],
                                  [109.339, -7.322],
                                  [-100.424, -73.289],
                                  [26.582, -123.534],
                                  [12.79, -92],
                                ],
                                v: [
                                  [-71.756, -348.294],
                                  [-164.477, -189.571],
                                  [-255.451, 0.83],
                                  [-256.136, 154.118],
                                  [-83.434, 276.884],
                                  [114.401, 398.862],
                                  [304.344, 272.718],
                                  [105.018, -46.706],
                                  [266.434, -210.162],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.758, -30.483],
                                  [-10.045, -106.616],
                                  [41.711, -54.02],
                                  [-105.388, -37.429],
                                  [-16.655, -56.938],
                                  [-136.745, 13.296],
                                  [99.957, 73.174],
                                  [-31.442, 118.614],
                                  [-12.841, 92],
                                ],
                                o: [
                                  [-91.931, 34.249],
                                  [10.045, 106.616],
                                  [-41.711, 54.02],
                                  [105.388, 37.429],
                                  [20.152, 61.242],
                                  [109.31, -7.379],
                                  [-99.958, -73.174],
                                  [26.331, -123.187],
                                  [12.841, -92],
                                ],
                                v: [
                                  [-71.029, -348.424],
                                  [-165.169, -190.154],
                                  [-255.305, 0.299],
                                  [-256.595, 153.927],
                                  [-83.495, 277.102],
                                  [114.907, 398.603],
                                  [304.508, 272.589],
                                  [106.002, -46.81],
                                  [265.4, -209.84],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.873, -30.253],
                                  [-9.768, -106.302],
                                  [41.515, -54.213],
                                  [-105.04, -37.466],
                                  [-16.641, -56.777],
                                  [-137.009, 13.417],
                                  [99.487, 73.059],
                                  [-31.243, 118.215],
                                  [-12.893, 92],
                                ],
                                o: [
                                  [-91.845, 34.059],
                                  [9.768, 106.302],
                                  [-41.515, 54.213],
                                  [105.04, 37.466],
                                  [20.175, 61.127],
                                  [109.282, -7.437],
                                  [-99.487, -73.059],
                                  [26.079, -122.836],
                                  [12.893, -92],
                                ],
                                v: [
                                  [-70.298, -348.552],
                                  [-165.82, -190.78],
                                  [-255.161, -0.191],
                                  [-257.092, 153.692],
                                  [-83.457, 277.305],
                                  [115.344, 398.306],
                                  [304.677, 272.525],
                                  [106.928, -46.952],
                                  [264.34, -209.465],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.989, -30.022],
                                  [-9.489, -105.986],
                                  [41.318, -54.407],
                                  [-104.689, -37.503],
                                  [-16.626, -56.615],
                                  [-137.275, 13.539],
                                  [99.013, 72.942],
                                  [-31.044, 117.813],
                                  [-12.945, 92],
                                ],
                                o: [
                                  [-91.758, 33.868],
                                  [9.489, 105.986],
                                  [-41.318, 54.407],
                                  [104.689, 37.503],
                                  [20.198, 61.011],
                                  [109.253, -7.495],
                                  [-99.013, -72.942],
                                  [25.824, -122.483],
                                  [12.945, -92],
                                ],
                                v: [
                                  [-69.611, -348.679],
                                  [-166.475, -191.405],
                                  [-254.975, -0.67],
                                  [-257.597, 153.475],
                                  [-83.444, 277.492],
                                  [115.792, 398.045],
                                  [304.868, 272.463],
                                  [107.875, -47.084],
                                  [263.283, -209.129],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.106, -29.788],
                                  [-9.207, -105.667],
                                  [41.12, -54.602],
                                  [-104.335, -37.54],
                                  [-16.612, -56.452],
                                  [-137.544, 13.661],
                                  [98.535, 72.825],
                                  [-30.842, 117.407],
                                  [-12.998, 92],
                                ],
                                o: [
                                  [-91.671, 33.675],
                                  [9.207, 105.667],
                                  [-41.12, 54.602],
                                  [104.335, 37.54],
                                  [20.221, 60.894],
                                  [109.224, -7.553],
                                  [-98.535, -72.825],
                                  [25.567, -122.127],
                                  [12.998, -92],
                                ],
                                v: [
                                  [-68.966, -348.868],
                                  [-167.135, -192.028],
                                  [-254.836, -1.125],
                                  [-258.031, 153.277],
                                  [-83.332, 277.726],
                                  [116.171, 397.684],
                                  [305.08, 272.357],
                                  [108.811, -47.225],
                                  [262.245, -208.723],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.224, -29.553],
                                  [-8.923, -105.346],
                                  [40.92, -54.8],
                                  [-103.978, -37.577],
                                  [-16.597, -56.287],
                                  [-137.814, 13.785],
                                  [98.053, 72.706],
                                  [-30.639, 116.998],
                                  [-13.051, 92],
                                ],
                                o: [
                                  [-91.582, 33.481],
                                  [8.923, 105.346],
                                  [-40.92, 54.8],
                                  [103.978, 37.578],
                                  [20.245, 60.776],
                                  [109.194, -7.612],
                                  [-98.053, -72.706],
                                  [25.308, -121.768],
                                  [13.051, -92],
                                ],
                                v: [
                                  [-68.381, -349.083],
                                  [-167.783, -192.649],
                                  [-254.656, -1.525],
                                  [-258.519, 153.037],
                                  [-83.229, 277.97],
                                  [116.544, 397.36],
                                  [305.28, 272.317],
                                  [109.644, -47.419],
                                  [261.239, -208.354],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.342, -29.316],
                                  [-8.637, -105.022],
                                  [40.718, -54.998],
                                  [-103.619, -37.615],
                                  [-16.582, -56.121],
                                  [-138.087, 13.909],
                                  [97.568, 72.587],
                                  [-30.435, 116.586],
                                  [-13.104, 92],
                                ],
                                o: [
                                  [-91.493, 33.285],
                                  [8.637, 105.022],
                                  [-40.718, 54.998],
                                  [103.619, 37.615],
                                  [20.268, 60.658],
                                  [109.164, -7.671],
                                  [-97.568, -72.587],
                                  [25.047, -121.406],
                                  [13.104, -92],
                                ],
                                v: [
                                  [-67.792, -349.297],
                                  [-168.452, -193.268],
                                  [-254.495, -1.903],
                                  [-258.954, 152.816],
                                  [-83.154, 278.17],
                                  [116.91, 396.999],
                                  [305.548, 272.217],
                                  [110.545, -47.605],
                                  [260.208, -207.933],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.462, -29.077],
                                  [-8.349, -104.695],
                                  [40.515, -55.198],
                                  [-103.257, -37.653],
                                  [-16.567, -55.954],
                                  [-138.362, 14.035],
                                  [97.078, 72.467],
                                  [-30.228, 116.17],
                                  [-13.158, 92],
                                ],
                                o: [
                                  [-91.404, 33.088],
                                  [8.349, 104.695],
                                  [-40.515, 55.198],
                                  [103.257, 37.653],
                                  [20.292, 60.538],
                                  [109.135, -7.731],
                                  [-97.078, -72.467],
                                  [24.784, -121.042],
                                  [13.158, -92],
                                ],
                                v: [
                                  [-67.244, -349.571],
                                  [-169.064, -193.93],
                                  [-254.321, -2.27],
                                  [-259.38, 152.598],
                                  [-83.043, 278.458],
                                  [117.252, 396.63],
                                  [305.758, 272.182],
                                  [111.388, -47.825],
                                  [259.198, -207.504],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.582, -28.836],
                                  [-8.058, -104.366],
                                  [40.31, -55.4],
                                  [-102.891, -37.692],
                                  [-16.552, -55.785],
                                  [-138.639, 14.161],
                                  [96.585, 72.345],
                                  [-30.021, 115.752],
                                  [-13.212, 92],
                                ],
                                o: [
                                  [-91.313, 32.889],
                                  [8.058, 104.366],
                                  [-40.31, 55.4],
                                  [102.891, 37.692],
                                  [20.316, 60.418],
                                  [109.104, -7.791],
                                  [-96.585, -72.345],
                                  [24.519, -120.674],
                                  [13.212, -92],
                                ],
                                v: [
                                  [-66.738, -349.825],
                                  [-169.663, -194.564],
                                  [-254.193, -2.644],
                                  [-259.798, 152.339],
                                  [-82.899, 278.71],
                                  [117.542, 396.252],
                                  [306.036, 272.104],
                                  [112.192, -48.055],
                                  [258.174, -207.111],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.704, -28.593],
                                  [-7.765, -104.034],
                                  [40.104, -55.604],
                                  [-102.523, -37.731],
                                  [-16.537, -55.615],
                                  [-138.918, 14.289],
                                  [96.088, 72.223],
                                  [-29.811, 115.33],
                                  [-13.267, 92],
                                ],
                                o: [
                                  [-91.222, 32.689],
                                  [7.765, 104.034],
                                  [-40.104, 55.604],
                                  [102.523, 37.731],
                                  [20.341, 60.296],
                                  [109.074, -7.852],
                                  [-96.088, -72.223],
                                  [24.252, -120.304],
                                  [13.267, -92],
                                ],
                                v: [
                                  [-66.227, -350.139],
                                  [-170.285, -195.222],
                                  [-254.006, -3.043],
                                  [-260.225, 152.101],
                                  [-82.765, 278.96],
                                  [117.781, 395.84],
                                  [306.362, 272.075],
                                  [112.983, -48.275],
                                  [257.172, -206.648],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.826, -28.348],
                                  [-7.47, -103.7],
                                  [39.896, -55.809],
                                  [-102.152, -37.77],
                                  [-16.522, -55.444],
                                  [-139.2, 14.417],
                                  [95.587, 72.1],
                                  [-29.6, 114.905],
                                  [-13.322, 92],
                                ],
                                o: [
                                  [-91.13, 32.487],
                                  [7.47, 103.7],
                                  [-39.896, 55.809],
                                  [102.152, 37.77],
                                  [20.365, 60.174],
                                  [109.043, -7.913],
                                  [-95.587, -72.1],
                                  [23.983, -119.931],
                                  [13.322, -92],
                                ],
                                v: [
                                  [-65.731, -350.433],
                                  [-170.849, -195.852],
                                  [-253.866, -3.386],
                                  [-260.626, 151.841],
                                  [-82.598, 279.234],
                                  [118.059, 395.419],
                                  [306.65, 272.003],
                                  [113.736, -48.549],
                                  [256.218, -206.177],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.949, -28.101],
                                  [-7.172, -103.363],
                                  [39.686, -56.015],
                                  [-101.778, -37.809],
                                  [-16.506, -55.271],
                                  [-139.484, 14.547],
                                  [95.082, 71.976],
                                  [-29.387, 114.476],
                                  [-13.377, 92],
                                ],
                                o: [
                                  [-91.038, 32.284],
                                  [7.172, 103.363],
                                  [-39.686, 56.015],
                                  [101.778, 37.809],
                                  [20.39, 60.051],
                                  [109.013, -7.975],
                                  [-95.082, -71.976],
                                  [23.711, -119.554],
                                  [13.377, -92],
                                ],
                                v: [
                                  [-65.25, -350.75],
                                  [-171.418, -196.524],
                                  [-253.684, -3.754],
                                  [-260.992, 151.586],
                                  [-82.461, 279.488],
                                  [118.267, 395.009],
                                  [306.943, 271.935],
                                  [114.494, -48.795],
                                  [255.224, -205.697],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.074, -27.853],
                                  [-6.872, -103.024],
                                  [39.475, -56.223],
                                  [-101.402, -37.848],
                                  [-16.491, -55.097],
                                  [-139.769, 14.677],
                                  [94.573, 71.851],
                                  [-29.173, 114.044],
                                  [-13.433, 92],
                                ],
                                o: [
                                  [-90.945, 32.078],
                                  [6.872, 103.024],
                                  [-39.475, 56.223],
                                  [101.402, 37.849],
                                  [20.415, 59.926],
                                  [108.982, -8.037],
                                  [-94.573, -71.851],
                                  [23.438, -119.175],
                                  [13.433, -92],
                                ],
                                v: [
                                  [-64.785, -351.044],
                                  [-171.993, -197.15],
                                  [-253.548, -4.112],
                                  [-261.351, 151.355],
                                  [-82.317, 279.738],
                                  [118.424, 394.548],
                                  [307.284, 271.914],
                                  [115.214, -49.05],
                                  [254.234, -205.253],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.199, -27.602],
                                  [-6.57, -102.682],
                                  [39.262, -56.433],
                                  [-101.022, -37.888],
                                  [-16.475, -54.922],
                                  [-140.058, 14.809],
                                  [94.061, 71.725],
                                  [-28.957, 113.609],
                                  [-13.49, 92],
                                ],
                                o: [
                                  [-90.851, 31.872],
                                  [6.57, 102.682],
                                  [-39.262, 56.433],
                                  [101.022, 37.888],
                                  [20.44, 59.801],
                                  [108.95, -8.099],
                                  [-94.061, -71.725],
                                  [23.162, -118.793],
                                  [13.49, -92],
                                ],
                                v: [
                                  [-64.335, -351.358],
                                  [-172.553, -197.818],
                                  [-253.396, -4.495],
                                  [-261.658, 151.128],
                                  [-82.161, 280.011],
                                  [118.618, 394.079],
                                  [307.63, 271.852],
                                  [115.897, -49.338],
                                  [253.267, -204.756],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.325, -27.35],
                                  [-6.265, -102.337],
                                  [39.047, -56.645],
                                  [-100.639, -37.929],
                                  [-16.459, -54.745],
                                  [-140.348, 14.941],
                                  [93.544, 71.598],
                                  [-28.739, 113.17],
                                  [-13.546, 92],
                                ],
                                o: [
                                  [-90.756, 31.663],
                                  [6.266, 102.337],
                                  [-39.047, 56.645],
                                  [100.639, 37.929],
                                  [20.465, 59.675],
                                  [108.919, -8.163],
                                  [-93.544, -71.598],
                                  [22.885, -118.408],
                                  [13.546, -92],
                                ],
                                v: [
                                  [-63.927, -351.712],
                                  [-173.099, -198.46],
                                  [-253.289, -4.844],
                                  [-261.976, 150.882],
                                  [-82.016, 280.26],
                                  [118.781, 393.621],
                                  [308.044, 271.793],
                                  [116.61, -49.598],
                                  [252.305, -204.251],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.452, -27.095],
                                  [-5.959, -101.989],
                                  [38.831, -56.858],
                                  [-100.254, -37.969],
                                  [-16.443, -54.567],
                                  [-140.641, 15.075],
                                  [93.023, 71.47],
                                  [-28.52, 112.728],
                                  [-13.604, 92],
                                ],
                                o: [
                                  [-90.661, 31.454],
                                  [5.959, 101.989],
                                  [-38.831, 56.858],
                                  [100.254, 37.969],
                                  [20.49, 59.548],
                                  [108.887, -8.226],
                                  [-93.023, -71.47],
                                  [22.605, -118.02],
                                  [13.604, -92],
                                ],
                                v: [
                                  [-63.554, -352.021],
                                  [-173.627, -199.124],
                                  [-253.121, -5.18],
                                  [-262.267, 150.643],
                                  [-81.841, 280.506],
                                  [118.876, 393.137],
                                  [308.4, 271.756],
                                  [117.263, -49.847],
                                  [251.372, -203.695],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.581, -26.839],
                                  [-5.649, -101.639],
                                  [38.613, -57.073],
                                  [-99.865, -38.01],
                                  [-16.427, -54.387],
                                  [-140.935, 15.21],
                                  [92.498, 71.341],
                                  [-28.299, 112.282],
                                  [-13.661, 92],
                                ],
                                o: [
                                  [-90.565, 31.242],
                                  [5.649, 101.639],
                                  [-38.613, 57.073],
                                  [99.865, 38.01],
                                  [20.516, 59.419],
                                  [108.855, -8.29],
                                  [-92.498, -71.341],
                                  [22.323, -117.629],
                                  [13.661, -92],
                                ],
                                v: [
                                  [-63.18, -352.325],
                                  [-174.12, -199.763],
                                  [-253.017, -5.544],
                                  [-262.527, 150.429],
                                  [-81.68, 280.771],
                                  [119.026, 392.623],
                                  [308.804, 271.747],
                                  [117.903, -50.066],
                                  [250.419, -203.173],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.71, -26.58],
                                  [-5.338, -101.286],
                                  [38.393, -57.289],
                                  [-99.473, -38.051],
                                  [-16.411, -54.206],
                                  [-141.233, 15.345],
                                  [91.969, 71.211],
                                  [-28.076, 111.833],
                                  [-13.719, 92],
                                ],
                                o: [
                                  [-90.468, 31.029],
                                  [5.338, 101.286],
                                  [-38.393, 57.289],
                                  [99.473, 38.051],
                                  [20.542, 59.29],
                                  [108.823, -8.355],
                                  [-91.969, -71.211],
                                  [22.038, -117.235],
                                  [13.719, -92],
                                ],
                                v: [
                                  [-62.823, -352.665],
                                  [-174.595, -200.4],
                                  [-252.894, -5.897],
                                  [-262.78, 150.179],
                                  [-81.531, 280.969],
                                  [119.128, 392.101],
                                  [309.213, 271.698],
                                  [118.506, -50.337],
                                  [249.471, -202.642],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.84, -26.32],
                                  [-5.024, -100.931],
                                  [38.172, -57.507],
                                  [-99.079, -38.093],
                                  [-16.395, -54.024],
                                  [-141.532, 15.482],
                                  [91.436, 71.08],
                                  [-27.851, 111.381],
                                  [-13.778, 92],
                                ],
                                o: [
                                  [-90.37, 30.814],
                                  [5.024, 100.931],
                                  [-38.172, 57.507],
                                  [99.079, 38.093],
                                  [20.568, 59.16],
                                  [108.79, -8.42],
                                  [-91.436, -71.08],
                                  [21.752, -116.838],
                                  [13.778, -92],
                                ],
                                v: [
                                  [-62.527, -353.001],
                                  [-175.098, -201.036],
                                  [-252.753, -6.235],
                                  [-263.003, 149.978],
                                  [-81.396, 281.204],
                                  [119.243, 391.552],
                                  [309.669, 271.652],
                                  [119.137, -50.576],
                                  [248.571, -202.102],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.971, -26.058],
                                  [-4.707, -100.573],
                                  [37.949, -57.727],
                                  [-98.681, -38.135],
                                  [-16.379, -53.84],
                                  [-141.834, 15.62],
                                  [90.899, 70.948],
                                  [-27.625, 110.925],
                                  [-13.837, 92],
                                ],
                                o: [
                                  [-90.272, 30.598],
                                  [4.707, 100.573],
                                  [-37.949, 57.727],
                                  [98.681, 38.135],
                                  [20.594, 59.029],
                                  [108.757, -8.486],
                                  [-90.899, -70.948],
                                  [21.464, -116.438],
                                  [13.837, -92],
                                ],
                                v: [
                                  [-62.249, -353.329],
                                  [-175.543, -201.691],
                                  [-252.676, -6.561],
                                  [-263.219, 149.762],
                                  [-81.253, 281.414],
                                  [119.31, 391.036],
                                  [310.067, 271.588],
                                  [119.755, -50.804],
                                  [247.633, -201.511],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.103, -25.793],
                                  [-4.388, -100.212],
                                  [37.724, -57.948],
                                  [-98.281, -38.177],
                                  [-16.362, -53.655],
                                  [-142.138, 15.758],
                                  [90.358, 70.815],
                                  [-27.397, 110.466],
                                  [-13.896, 92],
                                ],
                                o: [
                                  [-90.173, 30.379],
                                  [4.388, 100.212],
                                  [-37.724, 57.948],
                                  [98.281, 38.177],
                                  [20.621, 58.897],
                                  [108.724, -8.552],
                                  [-90.358, -70.815],
                                  [21.173, -116.035],
                                  [13.896, -92],
                                ],
                                v: [
                                  [-61.991, -353.63],
                                  [-175.951, -202.324],
                                  [-252.579, -6.896],
                                  [-263.387, 149.554],
                                  [-81.103, 281.618],
                                  [119.372, 390.451],
                                  [310.531, 271.55],
                                  [120.297, -51.02],
                                  [246.743, -200.954],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.237, -25.527],
                                  [-4.067, -99.848],
                                  [37.498, -58.171],
                                  [-97.877, -38.219],
                                  [-16.345, -53.469],
                                  [-142.444, 15.898],
                                  [89.813, 70.681],
                                  [-27.167, 110.003],
                                  [-13.956, 92],
                                ],
                                o: [
                                  [-90.073, 30.16],
                                  [4.067, 99.848],
                                  [-37.498, 58.171],
                                  [97.877, 38.219],
                                  [20.647, 58.763],
                                  [108.691, -8.618],
                                  [-89.813, -70.681],
                                  [20.88, -115.629],
                                  [13.956, -92],
                                ],
                                v: [
                                  [-61.773, -353.901],
                                  [-176.427, -202.935],
                                  [-252.482, -7.24],
                                  [-263.569, 149.332],
                                  [-81.03, 281.795],
                                  [119.449, 389.88],
                                  [311.042, 271.515],
                                  [120.888, -51.224],
                                  [245.837, -200.346],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.371, -25.259],
                                  [-3.743, -99.481],
                                  [37.27, -58.396],
                                  [-97.47, -38.262],
                                  [-16.329, -53.281],
                                  [-142.753, 16.039],
                                  [89.264, 70.546],
                                  [-26.936, 109.537],
                                  [-14.017, 92],
                                ],
                                o: [
                                  [-89.972, 29.938],
                                  [3.743, 99.481],
                                  [-37.27, 58.396],
                                  [97.47, 38.262],
                                  [20.674, 58.629],
                                  [108.657, -8.685],
                                  [-89.264, -70.546],
                                  [20.584, -115.219],
                                  [14.017, -92],
                                ],
                                v: [
                                  [-61.576, -354.184],
                                  [-176.846, -203.586],
                                  [-252.427, -7.55],
                                  [-263.703, 149.139],
                                  [-80.909, 281.987],
                                  [119.479, 389.302],
                                  [311.494, 271.484],
                                  [121.403, -51.458],
                                  [244.914, -199.729],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.506, -24.988],
                                  [-3.417, -99.112],
                                  [37.04, -58.622],
                                  [-97.06, -38.305],
                                  [-16.312, -53.092],
                                  [-143.064, 16.181],
                                  [88.71, 70.41],
                                  [-26.702, 109.067],
                                  [-14.078, 92],
                                ],
                                o: [
                                  [-89.871, 29.715],
                                  [3.417, 99.112],
                                  [-37.04, 58.622],
                                  [97.06, 38.305],
                                  [20.701, 58.494],
                                  [108.624, -8.753],
                                  [-88.71, -70.41],
                                  [20.287, -114.807],
                                  [14.078, -92],
                                ],
                                v: [
                                  [-61.377, -354.458],
                                  [-177.208, -204.216],
                                  [-252.372, -7.911],
                                  [-263.832, 148.997],
                                  [-80.823, 282.13],
                                  [119.546, 388.676],
                                  [311.992, 271.414],
                                  [121.948, -51.617],
                                  [244.039, -199.104],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.642, -24.716],
                                  [-3.088, -98.74],
                                  [36.808, -58.851],
                                  [-96.647, -38.348],
                                  [-16.295, -52.901],
                                  [-143.377, 16.324],
                                  [88.152, 70.273],
                                  [-26.467, 108.593],
                                  [-14.139, 92],
                                ],
                                o: [
                                  [-89.768, 29.49],
                                  [3.088, 98.74],
                                  [-36.808, 58.851],
                                  [96.647, 38.348],
                                  [20.728, 58.358],
                                  [108.589, -8.821],
                                  [-88.152, -70.273],
                                  [19.987, -114.391],
                                  [14.139, -92],
                                ],
                                v: [
                                  [-61.24, -354.763],
                                  [-177.637, -204.824],
                                  [-252.296, -8.218],
                                  [-263.935, 148.801],
                                  [-80.711, 282.307],
                                  [119.587, 388.066],
                                  [312.493, 271.369],
                                  [122.439, -51.805],
                                  [243.169, -198.491],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.779, -24.441],
                                  [-2.757, -98.365],
                                  [36.575, -59.081],
                                  [-96.231, -38.392],
                                  [-16.278, -52.709],
                                  [-143.693, 16.468],
                                  [87.59, 70.134],
                                  [-26.23, 108.116],
                                  [-14.201, 92],
                                ],
                                o: [
                                  [-89.665, 29.264],
                                  [2.757, 98.365],
                                  [-36.575, 59.081],
                                  [96.231, 38.392],
                                  [20.756, 58.221],
                                  [108.555, -8.89],
                                  [-87.59, -70.134],
                                  [19.685, -113.973],
                                  [14.201, -92],
                                ],
                                v: [
                                  [-61.124, -354.974],
                                  [-178.01, -205.412],
                                  [-252.26, -8.556],
                                  [-263.993, 148.635],
                                  [-80.656, 282.414],
                                  [119.625, 387.45],
                                  [313.039, 271.308],
                                  [122.939, -51.939],
                                  [242.323, -197.87],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.918, -24.164],
                                  [-2.423, -97.987],
                                  [36.34, -59.312],
                                  [-95.812, -38.436],
                                  [-16.26, -52.515],
                                  [-144.011, 16.614],
                                  [87.024, 69.995],
                                  [-25.992, 107.636],
                                  [-14.263, 92],
                                ],
                                o: [
                                  [-89.562, 29.036],
                                  [2.423, 97.987],
                                  [-36.34, 59.312],
                                  [95.812, 38.436],
                                  [20.784, 58.082],
                                  [108.521, -8.959],
                                  [-87.024, -69.995],
                                  [19.381, -113.551],
                                  [14.263, -92],
                                ],
                                v: [
                                  [-61.049, -355.214],
                                  [-178.388, -206.022],
                                  [-252.224, -8.841],
                                  [-264.046, 148.478],
                                  [-80.616, 282.533],
                                  [119.617, 386.849],
                                  [313.567, 271.229],
                                  [123.427, -52.101],
                                  [241.463, -197.218],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.057, -23.886],
                                  [-2.087, -97.607],
                                  [36.103, -59.546],
                                  [-95.389, -38.481],
                                  [-16.243, -52.32],
                                  [-144.331, 16.76],
                                  [86.454, 69.855],
                                  [-25.751, 107.152],
                                  [-14.326, 92],
                                ],
                                o: [
                                  [-89.457, 28.806],
                                  [2.087, 97.607],
                                  [-36.103, 59.546],
                                  [95.389, 38.481],
                                  [20.811, 57.943],
                                  [108.486, -9.029],
                                  [-86.454, -69.855],
                                  [19.074, -113.126],
                                  [14.326, -92],
                                ],
                                v: [
                                  [-60.994, -355.422],
                                  [-178.709, -206.61],
                                  [-252.187, -9.175],
                                  [-264.053, 148.331],
                                  [-80.632, 282.603],
                                  [119.626, 386.244],
                                  [314.098, 271.176],
                                  [123.905, -52.249],
                                  [240.627, -196.601],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.198, -23.605],
                                  [-1.749, -97.223],
                                  [35.864, -59.781],
                                  [-94.964, -38.525],
                                  [-16.225, -52.124],
                                  [-144.654, 16.907],
                                  [85.879, 69.714],
                                  [-25.509, 106.664],
                                  [-14.389, 92],
                                ],
                                o: [
                                  [-89.352, 28.574],
                                  [1.749, 97.223],
                                  [-35.864, 59.781],
                                  [94.964, 38.526],
                                  [20.839, 57.802],
                                  [108.451, -9.099],
                                  [-85.879, -69.714],
                                  [18.766, -112.698],
                                  [14.389, -92],
                                ],
                                v: [
                                  [-61.002, -355.617],
                                  [-179.036, -207.22],
                                  [-252.189, -9.496],
                                  [-264.039, 148.256],
                                  [-80.622, 282.705],
                                  [119.633, 385.593],
                                  [314.631, 271.105],
                                  [124.33, -52.362],
                                  [239.755, -195.914],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.339, -23.322],
                                  [-1.407, -96.837],
                                  [35.624, -60.018],
                                  [-94.535, -38.571],
                                  [-16.208, -51.925],
                                  [-144.98, 17.056],
                                  [85.3, 69.571],
                                  [-25.265, 106.172],
                                  [-14.453, 92],
                                ],
                                o: [
                                  [-89.246, 28.341],
                                  [1.407, 96.837],
                                  [-35.624, 60.018],
                                  [94.535, 38.571],
                                  [20.868, 57.661],
                                  [108.415, -9.169],
                                  [-85.3, -69.571],
                                  [18.454, -112.266],
                                  [14.453, -92],
                                ],
                                v: [
                                  [-60.987, -355.778],
                                  [-179.369, -207.791],
                                  [-252.207, -9.809],
                                  [-264.021, 148.128],
                                  [-80.629, 282.737],
                                  [119.635, 384.981],
                                  [315.208, 271.038],
                                  [124.787, -52.462],
                                  [238.929, -195.218],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.481, -23.037],
                                  [-1.064, -96.448],
                                  [35.382, -60.256],
                                  [-94.103, -38.616],
                                  [-16.19, -51.726],
                                  [-145.307, 17.206],
                                  [84.717, 69.428],
                                  [-25.02, 105.677],
                                  [-14.517, 92],
                                ],
                                o: [
                                  [-89.139, 28.106],
                                  [1.064, 96.448],
                                  [-35.382, 60.256],
                                  [94.103, 38.616],
                                  [20.896, 57.519],
                                  [108.38, -9.241],
                                  [-84.717, -69.428],
                                  [18.141, -111.832],
                                  [14.517, -92],
                                ],
                                v: [
                                  [-60.995, -355.966],
                                  [-179.667, -208.386],
                                  [-252.183, -10.108],
                                  [-263.983, 147.993],
                                  [-80.691, 282.776],
                                  [119.656, 384.365],
                                  [315.764, 270.953],
                                  [125.232, -52.507],
                                  [238.147, -194.576],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.625, -22.75],
                                  [-0.717, -96.056],
                                  [35.138, -60.497],
                                  [-93.668, -38.662],
                                  [-16.172, -51.525],
                                  [-145.637, 17.356],
                                  [84.129, 69.284],
                                  [-24.772, 105.178],
                                  [-14.581, 92],
                                ],
                                o: [
                                  [-89.031, 27.869],
                                  [0.717, 96.056],
                                  [-35.138, 60.497],
                                  [93.668, 38.662],
                                  [20.925, 57.375],
                                  [108.344, -9.312],
                                  [-84.129, -69.284],
                                  [17.825, -111.394],
                                  [14.581, -92],
                                ],
                                v: [
                                  [-61.064, -356.079],
                                  [-179.95, -208.959],
                                  [-252.26, -10.434],
                                  [-263.903, 147.909],
                                  [-80.769, 282.79],
                                  [119.634, 383.745],
                                  [316.362, 270.893],
                                  [125.647, -52.555],
                                  [237.307, -193.886],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.77, -22.461],
                                  [-0.368, -95.661],
                                  [34.892, -60.739],
                                  [-93.23, -38.708],
                                  [-16.154, -51.323],
                                  [-145.97, 17.508],
                                  [83.537, 69.138],
                                  [-24.522, 104.676],
                                  [-14.646, 92],
                                ],
                                o: [
                                  [-88.923, 27.63],
                                  [0.368, 95.661],
                                  [-34.892, 60.739],
                                  [93.23, 38.708],
                                  [20.954, 57.23],
                                  [108.308, -9.385],
                                  [-83.537, -69.138],
                                  [17.507, -110.953],
                                  [14.646, -92],
                                ],
                                v: [
                                  [-61.173, -356.179],
                                  [-180.215, -209.516],
                                  [-252.311, -10.751],
                                  [-263.781, 147.858],
                                  [-80.862, 282.748],
                                  [119.632, 383.144],
                                  [316.962, 270.776],
                                  [126.056, -52.611],
                                  [236.511, -193.212],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.915, -22.169],
                                  [-0.017, -95.263],
                                  [34.644, -60.983],
                                  [-92.788, -38.754],
                                  [-16.136, -51.119],
                                  [-146.305, 17.661],
                                  [82.941, 68.992],
                                  [-24.271, 104.169],
                                  [-14.712, 92],
                                ],
                                o: [
                                  [-88.814, 27.39],
                                  [0.017, 95.263],
                                  [-34.644, 60.983],
                                  [92.788, 38.754],
                                  [20.983, 57.085],
                                  [108.271, -9.458],
                                  [-82.941, -68.992],
                                  [17.186, -110.508],
                                  [14.712, -92],
                                ],
                                v: [
                                  [-61.241, -356.281],
                                  [-180.485, -210.059],
                                  [-252.336, -11.055],
                                  [-263.658, 147.794],
                                  [-81.011, 282.719],
                                  [119.628, 382.523],
                                  [317.54, 270.662],
                                  [126.453, -52.63],
                                  [235.719, -192.467],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.062, -21.876],
                                  [0.337, -94.862],
                                  [34.395, -61.229],
                                  [-92.343, -38.801],
                                  [-16.117, -50.913],
                                  [-146.643, 17.815],
                                  [82.34, 68.844],
                                  [-24.018, 103.659],
                                  [-14.778, 92],
                                ],
                                o: [
                                  [-88.703, 27.148],
                                  [-0.337, 94.862],
                                  [-34.395, 61.229],
                                  [92.343, 38.801],
                                  [21.012, 56.938],
                                  [108.234, -9.531],
                                  [-82.34, -68.844],
                                  [16.864, -110.061],
                                  [14.778, -92],
                                ],
                                v: [
                                  [-61.412, -356.329],
                                  [-180.722, -210.621],
                                  [-252.42, -11.407],
                                  [-263.518, 147.742],
                                  [-81.175, 282.658],
                                  [119.621, 381.939],
                                  [318.159, 270.568],
                                  [126.862, -52.572],
                                  [234.971, -191.777],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.21, -21.58],
                                  [0.694, -94.458],
                                  [34.143, -61.477],
                                  [-91.895, -38.848],
                                  [-16.099, -50.706],
                                  [-146.983, 17.97],
                                  [81.735, 68.695],
                                  [-23.763, 103.146],
                                  [-14.844, 92],
                                ],
                                o: [
                                  [-88.593, 26.904],
                                  [-0.694, 94.458],
                                  [-34.143, 61.477],
                                  [91.895, 38.848],
                                  [21.042, 56.79],
                                  [108.198, -9.605],
                                  [-81.735, -68.695],
                                  [16.538, -109.61],
                                  [14.844, -92],
                                ],
                                v: [
                                  [-61.557, -356.34],
                                  [-180.903, -211.169],
                                  [-252.501, -11.744],
                                  [-263.338, 147.724],
                                  [-81.354, 282.586],
                                  [119.636, 381.335],
                                  [318.778, 270.462],
                                  [127.243, -52.56],
                                  [234.202, -191.103],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.359, -21.282],
                                  [1.053, -94.051],
                                  [33.89, -61.726],
                                  [-91.444, -38.896],
                                  [-16.08, -50.498],
                                  [-147.325, 18.127],
                                  [81.125, 68.545],
                                  [-23.506, 102.628],
                                  [-14.911, 92],
                                ],
                                o: [
                                  [-88.481, 26.658],
                                  [-1.053, 94.051],
                                  [-33.89, 61.726],
                                  [91.444, 38.896],
                                  [21.072, 56.641],
                                  [108.16, -9.679],
                                  [-81.125, -68.545],
                                  [16.211, -109.156],
                                  [14.911, -92],
                                ],
                                v: [
                                  [-61.75, -356.336],
                                  [-181.129, -211.706],
                                  [-252.593, -12.05],
                                  [-263.12, 147.694],
                                  [-81.612, 282.505],
                                  [119.609, 380.754],
                                  [319.374, 270.298],
                                  [127.621, -52.471],
                                  [233.437, -190.384],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.509, -20.982],
                                  [1.415, -93.642],
                                  [33.635, -61.977],
                                  [-90.989, -38.943],
                                  [-16.061, -50.288],
                                  [-147.67, 18.284],
                                  [80.511, 68.394],
                                  [-23.247, 102.107],
                                  [-14.979, 92],
                                ],
                                o: [
                                  [-88.368, 26.41],
                                  [-1.415, 93.642],
                                  [-33.635, 61.977],
                                  [90.989, 38.943],
                                  [21.102, 56.491],
                                  [108.123, -9.754],
                                  [-80.511, -68.394],
                                  [15.881, -108.698],
                                  [14.979, -92],
                                ],
                                v: [
                                  [-61.957, -356.293],
                                  [-181.298, -212.222],
                                  [-252.718, -12.404],
                                  [-262.926, 147.713],
                                  [-81.862, 282.388],
                                  [119.606, 380.194],
                                  [320.008, 270.177],
                                  [128.025, -52.367],
                                  [232.714, -189.657],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.66, -20.68],
                                  [1.78, -93.229],
                                  [33.378, -62.23],
                                  [-90.531, -38.992],
                                  [-16.043, -50.076],
                                  [-148.018, 18.443],
                                  [79.892, 68.242],
                                  [-22.987, 101.582],
                                  [-15.047, 92],
                                ],
                                o: [
                                  [-88.255, 26.161],
                                  [-1.78, 93.229],
                                  [-33.378, 62.23],
                                  [90.531, 38.992],
                                  [21.132, 56.34],
                                  [108.085, -9.83],
                                  [-79.892, -68.242],
                                  [15.548, -108.237],
                                  [15.047, -92],
                                ],
                                v: [
                                  [-62.163, -356.234],
                                  [-181.434, -212.766],
                                  [-252.864, -12.742],
                                  [-262.671, 147.729],
                                  [-82.165, 282.223],
                                  [119.639, 379.634],
                                  [320.618, 270.035],
                                  [128.366, -52.222],
                                  [231.969, -188.923],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.812, -20.376],
                                  [2.147, -92.813],
                                  [33.119, -62.485],
                                  [-90.069, -39.04],
                                  [-16.023, -49.863],
                                  [-148.368, 18.603],
                                  [79.269, 68.089],
                                  [-22.724, 101.053],
                                  [-15.115, 92],
                                ],
                                o: [
                                  [-88.141, 25.91],
                                  [-2.147, 92.813],
                                  [-33.119, 62.485],
                                  [90.069, 39.04],
                                  [21.162, 56.188],
                                  [108.047, -9.906],
                                  [-79.269, -68.089],
                                  [15.213, -107.773],
                                  [15.115, -92],
                                ],
                                v: [
                                  [-62.43, -356.16],
                                  [-181.59, -213.276],
                                  [-253.018, -13.125],
                                  [-262.403, 147.732],
                                  [-82.522, 282.048],
                                  [119.632, 379.083],
                                  [321.265, 269.884],
                                  [128.782, -52.062],
                                  [231.227, -188.208],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.965, -20.069],
                                  [2.517, -92.394],
                                  [32.859, -62.742],
                                  [-89.605, -39.089],
                                  [-16.004, -49.648],
                                  [-148.721, 18.764],
                                  [78.641, 67.935],
                                  [-22.46, 100.52],
                                  [-15.184, 92],
                                ],
                                o: [
                                  [-88.026, 25.657],
                                  [-2.517, 92.394],
                                  [-32.859, 62.742],
                                  [89.605, 39.089],
                                  [21.193, 56.035],
                                  [108.009, -9.983],
                                  [-78.641, -67.935],
                                  [14.876, -107.305],
                                  [15.184, -92],
                                ],
                                v: [
                                  [-62.671, -356.008],
                                  [-181.714, -213.815],
                                  [-253.166, -13.491],
                                  [-262.125, 147.784],
                                  [-82.893, 281.861],
                                  [119.649, 378.569],
                                  [321.885, 269.687],
                                  [129.148, -51.885],
                                  [230.487, -187.485],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.12, -19.76],
                                  [2.889, -91.973],
                                  [32.596, -63.001],
                                  [-89.137, -39.138],
                                  [-15.985, -49.432],
                                  [-149.076, 18.926],
                                  [78.009, 67.779],
                                  [-22.193, 99.983],
                                  [-15.254, 92],
                                ],
                                o: [
                                  [-87.91, 25.402],
                                  [-2.889, 91.973],
                                  [-32.596, 63.001],
                                  [89.137, 39.138],
                                  [21.224, 55.88],
                                  [107.97, -10.06],
                                  [-78.009, -67.779],
                                  [14.536, -106.834],
                                  [15.254, -92],
                                ],
                                v: [
                                  [-62.974, -355.84],
                                  [-181.819, -214.297],
                                  [-253.346, -13.865],
                                  [-261.786, 147.81],
                                  [-83.342, 281.639],
                                  [119.666, 378.018],
                                  [322.504, 269.518],
                                  [129.515, -51.667],
                                  [229.726, -186.781],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.275, -19.449],
                                  [3.264, -91.548],
                                  [32.332, -63.261],
                                  [-88.665, -39.188],
                                  [-15.966, -49.214],
                                  [-149.434, 19.089],
                                  [77.372, 67.623],
                                  [-21.925, 99.443],
                                  [-15.324, 92],
                                ],
                                o: [
                                  [-87.793, 25.146],
                                  [-3.264, 91.548],
                                  [-32.332, 63.261],
                                  [88.665, 39.188],
                                  [21.255, 55.725],
                                  [107.931, -10.138],
                                  [-77.372, -67.623],
                                  [14.194, -106.36],
                                  [15.324, -92],
                                ],
                                v: [
                                  [-63.275, -355.618],
                                  [-181.893, -214.808],
                                  [-253.532, -14.245],
                                  [-261.44, 147.885],
                                  [-83.818, 281.394],
                                  [119.681, 377.555],
                                  [323.122, 269.292],
                                  [129.871, -51.394],
                                  [228.941, -186.071],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.432, -19.136],
                                  [3.642, -91.12],
                                  [32.065, -63.524],
                                  [-88.19, -39.238],
                                  [-15.946, -48.995],
                                  [-149.794, 19.254],
                                  [76.731, 67.465],
                                  [-21.655, 98.898],
                                  [-15.394, 92],
                                ],
                                o: [
                                  [-87.676, 24.887],
                                  [-3.642, 91.12],
                                  [-32.065, 63.524],
                                  [88.19, 39.238],
                                  [21.286, 55.568],
                                  [107.892, -10.216],
                                  [-76.731, -67.465],
                                  [13.849, -105.882],
                                  [15.394, -92],
                                ],
                                v: [
                                  [-63.586, -355.392],
                                  [-181.947, -215.314],
                                  [-253.736, -14.644],
                                  [-261.097, 147.945],
                                  [-84.345, 281.113],
                                  [119.685, 377.081],
                                  [323.675, 269.032],
                                  [130.267, -51.08],
                                  [228.221, -185.355],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.59, -18.82],
                                  [4.023, -90.689],
                                  [31.797, -63.788],
                                  [-87.712, -39.288],
                                  [-15.926, -48.774],
                                  [-150.157, 19.419],
                                  [76.085, 67.306],
                                  [-21.382, 98.35],
                                  [-15.465, 92],
                                ],
                                o: [
                                  [-87.558, 24.627],
                                  [-4.023, 90.689],
                                  [-31.797, 63.788],
                                  [87.712, 39.288],
                                  [21.318, 55.41],
                                  [107.853, -10.295],
                                  [-76.085, -67.306],
                                  [13.502, -105.401],
                                  [15.465, -92],
                                ],
                                v: [
                                  [-63.92, -355.076],
                                  [-181.98, -215.801],
                                  [-253.97, -15.06],
                                  [-260.721, 148.016],
                                  [-84.886, 280.821],
                                  [119.751, 376.608],
                                  [324.226, 268.752],
                                  [130.677, -50.748],
                                  [227.44, -184.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.749, -18.502],
                                  [4.407, -90.255],
                                  [31.527, -64.054],
                                  [-87.23, -39.339],
                                  [-15.906, -48.552],
                                  [-150.522, 19.586],
                                  [75.434, 67.146],
                                  [-21.108, 97.798],
                                  [-15.537, 92],
                                ],
                                o: [
                                  [-87.438, 24.364],
                                  [-4.407, 90.255],
                                  [-31.527, 64.054],
                                  [87.23, 39.339],
                                  [21.35, 55.251],
                                  [107.813, -10.374],
                                  [-75.434, -67.146],
                                  [13.153, -104.916],
                                  [15.537, -92],
                                ],
                                v: [
                                  [-64.252, -354.781],
                                  [-181.983, -216.283],
                                  [-254.207, -15.508],
                                  [-260.339, 148.099],
                                  [-85.477, 280.517],
                                  [119.818, 376.2],
                                  [324.713, 268.465],
                                  [131.065, -50.372],
                                  [226.635, -183.92],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.909, -18.182],
                                  [4.793, -89.817],
                                  [31.255, -64.322],
                                  [-86.745, -39.39],
                                  [-15.886, -48.328],
                                  [-150.891, 19.754],
                                  [74.779, 66.985],
                                  [-20.832, 97.241],
                                  [-15.609, 92],
                                ],
                                o: [
                                  [-87.318, 24.1],
                                  [-4.793, 89.818],
                                  [-31.255, 64.322],
                                  [86.745, 39.39],
                                  [21.382, 55.091],
                                  [107.773, -10.454],
                                  [-74.779, -66.985],
                                  [12.8, -104.428],
                                  [15.609, -92],
                                ],
                                v: [
                                  [-64.593, -354.396],
                                  [-182.001, -216.772],
                                  [-254.462, -15.945],
                                  [-259.901, 148.229],
                                  [-86.082, 280.203],
                                  [119.849, 375.783],
                                  [325.162, 268.095],
                                  [131.432, -49.942],
                                  [225.831, -183.203],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.07, -17.86],
                                  [5.182, -89.377],
                                  [30.981, -64.592],
                                  [-86.256, -39.441],
                                  [-15.866, -48.102],
                                  [-151.261, 19.924],
                                  [74.119, 66.823],
                                  [-20.554, 96.681],
                                  [-15.682, 92],
                                ],
                                o: [
                                  [-87.197, 23.834],
                                  [-5.182, 89.377],
                                  [-30.981, 64.592],
                                  [86.256, 39.441],
                                  [21.414, 54.93],
                                  [107.733, -10.535],
                                  [-74.119, -66.823],
                                  [12.446, -103.936],
                                  [15.682, -92],
                                ],
                                v: [
                                  [-64.957, -353.996],
                                  [-181.962, -217.296],
                                  [-254.745, -16.422],
                                  [-259.459, 148.307],
                                  [-86.701, 279.877],
                                  [119.943, 375.396],
                                  [325.583, 267.756],
                                  [131.85, -49.504],
                                  [225.038, -182.507],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.232, -17.535],
                                  [5.573, -88.934],
                                  [30.705, -64.864],
                                  [-85.764, -39.493],
                                  [-15.846, -47.875],
                                  [-151.635, 20.094],
                                  [73.454, 66.66],
                                  [-20.274, 96.117],
                                  [-15.755, 92],
                                ],
                                o: [
                                  [-87.076, 23.566],
                                  [-5.573, 88.934],
                                  [-30.705, 64.865],
                                  [85.764, 39.493],
                                  [21.447, 54.767],
                                  [107.692, -10.616],
                                  [-73.454, -66.66],
                                  [12.088, -103.441],
                                  [15.755, -92],
                                ],
                                v: [
                                  [-65.327, -353.553],
                                  [-181.892, -217.728],
                                  [-254.992, -16.939],
                                  [-259.024, 148.404],
                                  [-87.37, 279.539],
                                  [120.002, 375.038],
                                  [325.976, 267.361],
                                  [132.275, -48.975],
                                  [224.211, -181.78],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.396, -17.208],
                                  [5.968, -88.487],
                                  [30.427, -65.138],
                                  [-85.268, -39.545],
                                  [-15.826, -47.646],
                                  [-152.011, 20.266],
                                  [72.785, 66.495],
                                  [-19.992, 95.549],
                                  [-15.828, 92],
                                ],
                                o: [
                                  [-86.953, 23.297],
                                  [-5.968, 88.487],
                                  [-30.427, 65.138],
                                  [85.268, 39.545],
                                  [21.479, 54.604],
                                  [107.651, -10.698],
                                  [-72.785, -66.495],
                                  [11.729, -102.942],
                                  [15.828, -92],
                                ],
                                v: [
                                  [-65.659, -353.058],
                                  [-181.835, -218.232],
                                  [-255.265, -17.431],
                                  [-258.56, 148.539],
                                  [-88.052, 279.19],
                                  [120.097, 374.712],
                                  [326.322, 266.908],
                                  [132.717, -48.437],
                                  [223.419, -181.076],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.561, -16.879],
                                  [6.365, -88.037],
                                  [30.147, -65.414],
                                  [-84.769, -39.598],
                                  [-15.805, -47.415],
                                  [-152.39, 20.439],
                                  [72.111, 66.33],
                                  [-19.708, 94.976],
                                  [-15.902, 92],
                                ],
                                o: [
                                  [-86.829, 23.025],
                                  [-6.365, 88.037],
                                  [-30.147, 65.414],
                                  [84.769, 39.597],
                                  [21.512, 54.439],
                                  [107.61, -10.78],
                                  [-72.111, -66.33],
                                  [11.366, -102.44],
                                  [15.902, -92],
                                ],
                                v: [
                                  [-65.987, -352.547],
                                  [-181.748, -218.708],
                                  [-255.556, -17.962],
                                  [-258.096, 148.664],
                                  [-88.782, 278.83],
                                  [120.186, 374.417],
                                  [326.648, 266.461],
                                  [133.175, -47.817],
                                  [222.566, -180.359],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.727, -16.547],
                                  [6.765, -87.584],
                                  [29.865, -65.692],
                                  [-84.266, -39.65],
                                  [-15.784, -47.183],
                                  [-152.771, 20.613],
                                  [71.432, 66.163],
                                  [-19.422, 94.4],
                                  [-15.977, 92],
                                ],
                                o: [
                                  [-86.705, 22.751],
                                  [-6.765, 87.584],
                                  [-29.865, 65.692],
                                  [84.266, 39.65],
                                  [21.545, 54.273],
                                  [107.568, -10.863],
                                  [-71.432, -66.163],
                                  [11.002, -101.934],
                                  [15.977, -92],
                                ],
                                v: [
                                  [-66.278, -352.012],
                                  [-181.639, -219.187],
                                  [-255.845, -18.503],
                                  [-257.578, 148.764],
                                  [-89.499, 278.457],
                                  [120.339, 374.127],
                                  [326.929, 266.019],
                                  [133.65, -47.178],
                                  [221.721, -179.674],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.894, -16.213],
                                  [7.168, -87.128],
                                  [29.581, -65.972],
                                  [-83.76, -39.704],
                                  [-15.763, -46.949],
                                  [-153.155, 20.788],
                                  [70.748, 65.995],
                                  [-19.134, 93.82],
                                  [-16.052, 92],
                                ],
                                o: [
                                  [-86.58, 22.476],
                                  [-7.168, 87.128],
                                  [-29.581, 65.972],
                                  [83.76, 39.704],
                                  [21.579, 54.106],
                                  [107.527, -10.947],
                                  [-70.748, -65.995],
                                  [10.634, -101.425],
                                  [16.052, -92],
                                ],
                                v: [
                                  [-66.573, -351.406],
                                  [-181.471, -219.647],
                                  [-256.116, -19.08],
                                  [-257.097, 148.881],
                                  [-90.256, 278.038],
                                  [120.466, 373.898],
                                  [327.153, 265.548],
                                  [134.17, -46.491],
                                  [220.876, -178.979],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.062, -15.876],
                                  [7.574, -86.669],
                                  [29.295, -66.254],
                                  [-83.25, -39.757],
                                  [-15.742, -46.713],
                                  [-153.542, 20.965],
                                  [70.059, 65.825],
                                  [-18.843, 93.235],
                                  [-16.128, 92],
                                ],
                                o: [
                                  [-86.454, 22.198],
                                  [-7.574, 86.669],
                                  [-29.295, 66.254],
                                  [83.25, 39.757],
                                  [21.612, 53.938],
                                  [107.484, -11.031],
                                  [-70.059, -65.825],
                                  [10.264, -100.911],
                                  [16.128, -92],
                                ],
                                v: [
                                  [-66.797, -350.777],
                                  [-181.342, -220.112],
                                  [-256.384, -19.663],
                                  [-256.591, 149.006],
                                  [-90.998, 277.642],
                                  [120.651, 373.675],
                                  [327.341, 265.021],
                                  [134.673, -45.756],
                                  [220.002, -178.281],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.231, -15.538],
                                  [7.983, -86.206],
                                  [29.007, -66.537],
                                  [-82.737, -39.811],
                                  [-15.721, -46.476],
                                  [-153.932, 21.143],
                                  [69.366, 65.655],
                                  [-18.551, 92.647],
                                  [-16.204, 92],
                                ],
                                o: [
                                  [-86.327, 21.919],
                                  [-7.983, 86.206],
                                  [-29.007, 66.537],
                                  [82.737, 39.811],
                                  [21.646, 53.769],
                                  [107.442, -11.116],
                                  [-69.366, -65.655],
                                  [9.891, -100.395],
                                  [16.204, -92],
                                ],
                                v: [
                                  [-67.017, -350.166],
                                  [-181.127, -220.587],
                                  [-256.676, -20.218],
                                  [-256.032, 149.145],
                                  [-91.816, 277.234],
                                  [120.81, 373.487],
                                  [327.528, 264.472],
                                  [135.228, -44.994],
                                  [219.128, -177.574],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.402, -15.196],
                                  [8.394, -85.74],
                                  [28.717, -66.823],
                                  [-82.22, -39.866],
                                  [-15.7, -46.237],
                                  [-154.324, 21.322],
                                  [68.668, 65.483],
                                  [-18.257, 92.054],
                                  [-16.281, 92],
                                ],
                                o: [
                                  [-86.199, 21.637],
                                  [-8.394, 85.74],
                                  [-28.717, 66.823],
                                  [82.22, 39.866],
                                  [21.68, 53.598],
                                  [107.4, -11.201],
                                  [-68.668, -65.483],
                                  [9.516, -99.875],
                                  [16.281, -92],
                                ],
                                v: [
                                  [-67.236, -349.472],
                                  [-180.944, -221.072],
                                  [-256.984, -20.806],
                                  [-255.478, 149.202],
                                  [-92.652, 276.808],
                                  [121.028, 373.305],
                                  [327.65, 263.924],
                                  [135.83, -44.155],
                                  [218.223, -176.866],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.574, -14.853],
                                  [8.809, -85.271],
                                  [28.425, -67.111],
                                  [-81.699, -39.92],
                                  [-15.678, -45.997],
                                  [-154.719, 21.502],
                                  [67.964, 65.31],
                                  [-17.961, 91.457],
                                  [-16.358, 92],
                                ],
                                o: [
                                  [-86.07, 21.354],
                                  [-8.809, 85.271],
                                  [-28.425, 67.111],
                                  [81.699, 39.92],
                                  [21.715, 53.426],
                                  [107.357, -11.287],
                                  [-67.965, -65.31],
                                  [9.138, -99.351],
                                  [16.358, -92],
                                ],
                                v: [
                                  [-67.389, -348.762],
                                  [-180.707, -221.536],
                                  [-257.219, -21.425],
                                  [-254.935, 149.361],
                                  [-93.496, 276.342],
                                  [121.22, 373.101],
                                  [327.771, 263.354],
                                  [136.45, -43.323],
                                  [217.351, -176.185],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.746, -14.507],
                                  [9.226, -84.799],
                                  [28.131, -67.4],
                                  [-81.175, -39.975],
                                  [-15.657, -45.755],
                                  [-155.117, 21.684],
                                  [67.256, 65.136],
                                  [-17.662, 90.856],
                                  [-16.436, 92],
                                ],
                                o: [
                                  [-85.94, 21.068],
                                  [-9.226, 84.799],
                                  [-28.131, 67.4],
                                  [81.175, 39.975],
                                  [21.749, 53.254],
                                  [107.313, -11.373],
                                  [-67.256, -65.136],
                                  [8.758, -98.823],
                                  [16.436, -92],
                                ],
                                v: [
                                  [-67.54, -348.036],
                                  [-180.44, -221.977],
                                  [-257.509, -22.014],
                                  [-254.399, 149.436],
                                  [-94.357, 275.927],
                                  [121.477, 372.933],
                                  [327.827, 262.761],
                                  [137.089, -42.408],
                                  [216.415, -175.502],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.921, -14.159],
                                  [9.646, -84.323],
                                  [27.835, -67.692],
                                  [-80.647, -40.031],
                                  [-15.635, -45.511],
                                  [-155.518, 21.867],
                                  [66.543, 64.961],
                                  [-17.362, 90.251],
                                  [-16.514, 92],
                                ],
                                o: [
                                  [-85.809, 20.781],
                                  [-9.646, 84.323],
                                  [-27.835, 67.692],
                                  [80.647, 40.031],
                                  [21.784, 53.079],
                                  [107.27, -11.46],
                                  [-66.543, -64.961],
                                  [8.374, -98.292],
                                  [16.514, -92],
                                ],
                                v: [
                                  [-67.66, -347.294],
                                  [-180.181, -222.494],
                                  [-257.784, -22.604],
                                  [-253.847, 149.551],
                                  [-95.263, 275.466],
                                  [121.736, 372.772],
                                  [327.849, 262.141],
                                  [137.776, -41.472],
                                  [215.51, -174.819],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.096, -13.808],
                                  [10.069, -83.844],
                                  [27.537, -67.986],
                                  [-80.115, -40.087],
                                  [-15.613, -45.266],
                                  [-155.921, 22.051],
                                  [65.825, 64.785],
                                  [-17.059, 89.641],
                                  [-16.593, 92],
                                ],
                                o: [
                                  [-85.678, 20.491],
                                  [-10.069, 83.844],
                                  [-27.537, 67.986],
                                  [80.115, 40.087],
                                  [21.819, 52.904],
                                  [107.226, -11.548],
                                  [-65.825, -64.785],
                                  [7.989, -97.757],
                                  [16.593, -92],
                                ],
                                v: [
                                  [-67.743, -346.471],
                                  [-179.924, -222.96],
                                  [-258.017, -23.19],
                                  [-253.336, 149.704],
                                  [-96.148, 274.994],
                                  [121.998, 372.618],
                                  [327.808, 261.495],
                                  [138.453, -40.485],
                                  [214.573, -174.161],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.273, -13.455],
                                  [10.495, -83.361],
                                  [27.236, -68.282],
                                  [-79.58, -40.143],
                                  [-15.591, -45.018],
                                  [-156.327, 22.236],
                                  [65.103, 64.607],
                                  [-16.755, 89.027],
                                  [-16.673, 92],
                                ],
                                o: [
                                  [-85.545, 20.2],
                                  [-10.495, 83.362],
                                  [-27.236, 68.282],
                                  [79.58, 40.143],
                                  [21.855, 52.727],
                                  [107.182, -11.636],
                                  [-65.102, -64.607],
                                  [7.6, -97.218],
                                  [16.673, -92],
                                ],
                                v: [
                                  [-67.792, -345.666],
                                  [-179.667, -223.406],
                                  [-258.269, -23.804],
                                  [-252.776, 149.832],
                                  [-97.079, 274.51],
                                  [122.293, 372.409],
                                  [327.765, 260.889],
                                  [139.179, -39.474],
                                  [213.633, -173.442],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.451, -13.099],
                                  [10.924, -82.876],
                                  [26.934, -68.58],
                                  [-79.041, -40.2],
                                  [-15.569, -44.769],
                                  [-156.736, 22.423],
                                  [64.375, 64.428],
                                  [-16.448, 88.409],
                                  [-16.753, 92],
                                ],
                                o: [
                                  [-85.412, 19.907],
                                  [-10.924, 82.876],
                                  [-26.934, 68.58],
                                  [79.041, 40.2],
                                  [21.89, 52.549],
                                  [107.137, -11.725],
                                  [-64.375, -64.428],
                                  [7.209, -96.676],
                                  [16.753, -92],
                                ],
                                v: [
                                  [-67.809, -344.843],
                                  [-179.416, -223.867],
                                  [-258.51, -24.416],
                                  [-252.227, 149.936],
                                  [-97.991, 274.013],
                                  [122.623, 372.27],
                                  [327.691, 260.227],
                                  [139.924, -38.411],
                                  [212.692, -172.785],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.63, -12.741],
                                  [11.356, -82.387],
                                  [26.63, -68.879],
                                  [-78.498, -40.257],
                                  [-15.546, -44.519],
                                  [-157.148, 22.611],
                                  [63.641, 64.248],
                                  [-16.139, 87.787],
                                  [-16.833, 92],
                                ],
                                o: [
                                  [-85.278, 19.611],
                                  [-11.356, 82.387],
                                  [-26.63, 68.88],
                                  [78.498, 40.257],
                                  [21.926, 52.37],
                                  [107.093, -11.815],
                                  [-63.642, -64.248],
                                  [6.815, -96.13],
                                  [16.833, -92],
                                ],
                                v: [
                                  [-67.793, -343.944],
                                  [-179.136, -224.34],
                                  [-258.707, -24.992],
                                  [-251.723, 150.075],
                                  [-98.977, 273.506],
                                  [122.924, 372.107],
                                  [327.551, 259.541],
                                  [140.752, -37.296],
                                  [211.746, -172.128],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.81, -12.38],
                                  [11.791, -81.894],
                                  [26.323, -69.181],
                                  [-77.951, -40.314],
                                  [-15.524, -44.266],
                                  [-157.563, 22.8],
                                  [62.904, 64.066],
                                  [-15.828, 87.161],
                                  [-16.914, 92],
                                ],
                                o: [
                                  [-85.143, 19.314],
                                  [-11.791, 81.894],
                                  [-26.323, 69.181],
                                  [77.951, 40.314],
                                  [21.962, 52.19],
                                  [107.048, -11.905],
                                  [-62.903, -64.066],
                                  [6.418, -95.58],
                                  [16.914, -92],
                                ],
                                v: [
                                  [-67.742, -343.06],
                                  [-178.922, -224.794],
                                  [-258.954, -25.593],
                                  [-251.202, 150.188],
                                  [-99.912, 273.049],
                                  [123.26, 371.983],
                                  [327.411, 258.862],
                                  [141.538, -36.157],
                                  [210.797, -171.504],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.991, -12.017],
                                  [12.229, -81.399],
                                  [26.015, -69.486],
                                  [-77.401, -40.372],
                                  [-15.501, -44.012],
                                  [-157.98, 22.991],
                                  [62.16, 63.884],
                                  [-15.515, 86.53],
                                  [-16.996, 92],
                                ],
                                o: [
                                  [-85.006, 19.014],
                                  [-12.229, 81.399],
                                  [-26.015, 69.486],
                                  [77.401, 40.372],
                                  [21.998, 52.009],
                                  [107.002, -11.996],
                                  [-62.16, -63.884],
                                  [6.019, -95.026],
                                  [16.996, -92],
                                ],
                                v: [
                                  [-67.688, -342.162],
                                  [-178.711, -225.23],
                                  [-259.157, -26.157],
                                  [-250.696, 150.304],
                                  [-100.922, 272.518],
                                  [123.63, 371.803],
                                  [327.239, 258.19],
                                  [142.406, -34.997],
                                  [209.844, -170.82],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.174, -11.652],
                                  [12.67, -80.899],
                                  [25.704, -69.792],
                                  [-76.847, -40.431],
                                  [-15.478, -43.756],
                                  [-158.401, 23.183],
                                  [61.412, 63.7],
                                  [-15.2, 85.895],
                                  [-17.078, 92],
                                ],
                                o: [
                                  [-84.869, 18.713],
                                  [-12.67, 80.899],
                                  [-25.704, 69.792],
                                  [76.847, 40.43],
                                  [22.035, 51.826],
                                  [106.956, -12.087],
                                  [-61.412, -63.7],
                                  [5.617, -94.469],
                                  [17.078, -92],
                                ],
                                v: [
                                  [-67.57, -341.219],
                                  [-178.472, -225.68],
                                  [-259.317, -26.712],
                                  [-250.174, 150.455],
                                  [-101.912, 272.039],
                                  [124.002, 371.662],
                                  [327.035, 257.493],
                                  [143.295, -33.784],
                                  [208.887, -170.201],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.358, -11.284],
                                  [13.114, -80.397],
                                  [25.391, -70.1],
                                  [-76.289, -40.489],
                                  [-15.455, -43.499],
                                  [-158.824, 23.376],
                                  [60.659, 63.515],
                                  [-14.882, 85.255],
                                  [-17.161, 92],
                                ],
                                o: [
                                  [-84.731, 18.409],
                                  [-13.114, 80.397],
                                  [-25.391, 70.1],
                                  [76.289, 40.489],
                                  [22.072, 51.642],
                                  [106.91, -12.179],
                                  [-60.659, -63.515],
                                  [5.212, -93.908],
                                  [17.161, -92],
                                ],
                                v: [
                                  [-67.418, -340.261],
                                  [-178.268, -226.079],
                                  [-259.526, -27.291],
                                  [-249.699, 150.545],
                                  [-102.912, 271.517],
                                  [124.377, 371.498],
                                  [326.767, 256.806],
                                  [144.204, -32.55],
                                  [207.926, -169.585],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.543, -10.913],
                                  [13.561, -79.891],
                                  [25.076, -70.41],
                                  [-75.728, -40.548],
                                  [-15.432, -43.239],
                                  [-159.25, 23.571],
                                  [59.9, 63.328],
                                  [-14.563, 84.612],
                                  [-17.245, 92],
                                ],
                                o: [
                                  [-84.592, 18.103],
                                  [-13.561, 79.891],
                                  [-25.076, 70.41],
                                  [75.728, 40.548],
                                  [22.109, 51.457],
                                  [106.864, -12.272],
                                  [-59.9, -63.328],
                                  [4.804, -93.342],
                                  [17.245, -92],
                                ],
                                v: [
                                  [-67.233, -339.259],
                                  [-178.067, -226.524],
                                  [-259.692, -27.86],
                                  [-249.209, 150.699],
                                  [-103.892, 270.985],
                                  [124.755, 371.339],
                                  [326.468, 256.124],
                                  [145.197, -31.262],
                                  [206.959, -168.943],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.73, -10.54],
                                  [14.011, -79.381],
                                  [24.759, -70.723],
                                  [-75.162, -40.608],
                                  [-15.409, -42.978],
                                  [-159.679, 23.766],
                                  [59.137, 63.14],
                                  [-14.241, 83.963],
                                  [-17.328, 92],
                                ],
                                o: [
                                  [-84.453, 17.796],
                                  [-14.011, 79.381],
                                  [-24.759, 70.723],
                                  [75.162, 40.608],
                                  [22.146, 51.27],
                                  [106.818, -12.365],
                                  [-59.137, -63.141],
                                  [4.394, -92.774],
                                  [17.328, -92],
                                ],
                                v: [
                                  [-67.012, -338.246],
                                  [-177.9, -226.949],
                                  [-259.845, -28.419],
                                  [-248.703, 150.823],
                                  [-104.914, 270.442],
                                  [125.135, 371.19],
                                  [326.169, 255.421],
                                  [146.178, -29.921],
                                  [205.987, -168.306],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.918, -10.164],
                                  [14.464, -78.868],
                                  [24.44, -71.037],
                                  [-74.593, -40.668],
                                  [-15.385, -42.715],
                                  [-160.111, 23.964],
                                  [58.368, 62.951],
                                  [-13.917, 83.311],
                                  [-17.413, 92],
                                ],
                                o: [
                                  [-84.312, 17.486],
                                  [-14.464, 78.868],
                                  [-24.44, 71.037],
                                  [74.593, 40.668],
                                  [22.184, 51.082],
                                  [106.771, -12.459],
                                  [-58.368, -62.951],
                                  [3.981, -92.201],
                                  [17.413, -92],
                                ],
                                v: [
                                  [-66.759, -337.25],
                                  [-177.706, -227.387],
                                  [-259.956, -28.936],
                                  [-248.277, 150.945],
                                  [-105.945, 269.921],
                                  [125.547, 371.046],
                                  [325.87, 254.725],
                                  [147.179, -28.592],
                                  [205.006, -167.706],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.107, -9.786],
                                  [14.92, -78.352],
                                  [24.118, -71.354],
                                  [-74.02, -40.728],
                                  [-15.362, -42.45],
                                  [-160.546, 24.162],
                                  [57.594, 62.761],
                                  [-13.591, 82.654],
                                  [-17.498, 92],
                                ],
                                o: [
                                  [-84.17, 17.174],
                                  [-14.92, 78.352],
                                  [-24.118, 71.354],
                                  [74.02, 40.728],
                                  [22.221, 50.893],
                                  [106.723, -12.553],
                                  [-57.594, -62.761],
                                  [3.565, -91.624],
                                  [17.498, -92],
                                ],
                                v: [
                                  [-66.471, -336.182],
                                  [-177.545, -227.776],
                                  [-260.118, -29.442],
                                  [-247.805, 151.067],
                                  [-106.954, 269.39],
                                  [125.964, 370.88],
                                  [325.479, 254.037],
                                  [148.234, -27.178],
                                  [204.052, -167.114],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.297, -9.406],
                                  [15.38, -77.832],
                                  [23.795, -71.673],
                                  [-73.443, -40.788],
                                  [-15.338, -42.184],
                                  [-160.984, 24.362],
                                  [56.815, 62.57],
                                  [-13.262, 81.992],
                                  [-17.584, 92],
                                ],
                                o: [
                                  [-84.027, 16.86],
                                  [-15.38, 77.832],
                                  [-23.795, 71.673],
                                  [73.443, 40.789],
                                  [22.259, 50.703],
                                  [106.676, -12.649],
                                  [-56.815, -62.57],
                                  [3.146, -91.044],
                                  [17.584, -92],
                                ],
                                v: [
                                  [-66.15, -335.135],
                                  [-177.416, -228.207],
                                  [-260.238, -29.934],
                                  [-247.383, 151.189],
                                  [-108.002, 268.881],
                                  [126.38, 370.75],
                                  [325.088, 253.357],
                                  [149.34, -25.776],
                                  [203.026, -166.499],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.489, -9.022],
                                  [15.842, -77.309],
                                  [23.469, -71.994],
                                  [-72.862, -40.85],
                                  [-15.314, -41.916],
                                  [-161.424, 24.563],
                                  [56.03, 62.377],
                                  [-12.932, 81.326],
                                  [-17.67, 92],
                                ],
                                o: [
                                  [-83.883, 16.543],
                                  [-15.842, 77.309],
                                  [-23.469, 71.994],
                                  [72.862, 40.85],
                                  [22.298, 50.511],
                                  [106.628, -12.744],
                                  [-56.03, -62.377],
                                  [2.725, -90.459],
                                  [17.67, -92],
                                ],
                                v: [
                                  [-65.827, -334.076],
                                  [-177.26, -228.589],
                                  [-260.346, -30.443],
                                  [-246.945, 151.307],
                                  [-108.998, 268.333],
                                  [126.797, 370.599],
                                  [324.698, 252.657],
                                  [150.466, -24.319],
                                  [202.026, -165.92],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.682, -8.636],
                                  [16.307, -76.782],
                                  [23.141, -72.317],
                                  [-72.277, -40.911],
                                  [-15.29, -41.645],
                                  [-161.868, 24.766],
                                  [55.24, 62.183],
                                  [-12.599, 80.656],
                                  [-17.757, 92],
                                ],
                                o: [
                                  [-83.739, 16.225],
                                  [-16.307, 76.782],
                                  [-23.141, 72.317],
                                  [72.277, 40.911],
                                  [22.336, 50.318],
                                  [106.58, -12.841],
                                  [-55.24, -62.183],
                                  [2.3, -89.871],
                                  [17.757, -92],
                                ],
                                v: [
                                  [-65.468, -332.977],
                                  [-177.137, -228.983],
                                  [-260.413, -30.911],
                                  [-246.559, 151.39],
                                  [-110.031, 267.804],
                                  [127.217, 370.45],
                                  [324.217, 251.994],
                                  [151.642, -22.846],
                                  [201.014, -165.291],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.876, -8.248],
                                  [16.776, -76.251],
                                  [22.811, -72.642],
                                  [-71.688, -40.973],
                                  [-15.265, -41.374],
                                  [-162.315, 24.97],
                                  [54.445, 61.987],
                                  [-12.264, 79.981],
                                  [-17.844, 92],
                                ],
                                o: [
                                  [-83.593, 15.905],
                                  [-16.776, 76.251],
                                  [-22.811, 72.642],
                                  [71.688, 40.973],
                                  [22.375, 50.124],
                                  [106.531, -12.938],
                                  [-54.445, -61.987],
                                  [1.873, -89.278],
                                  [17.844, -92],
                                ],
                                v: [
                                  [-65.077, -331.867],
                                  [-177.044, -229.356],
                                  [-260.498, -31.395],
                                  [-246.184, 151.499],
                                  [-111.073, 267.271],
                                  [127.638, 370.28],
                                  [323.765, 251.34],
                                  [152.805, -21.352],
                                  [199.994, -164.732],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.072, -7.857],
                                  [17.248, -75.717],
                                  [22.478, -72.97],
                                  [-71.096, -41.035],
                                  [-15.241, -41.1],
                                  [-162.765, 25.175],
                                  [53.645, 61.791],
                                  [-11.927, 79.301],
                                  [-17.932, 92],
                                ],
                                o: [
                                  [-83.446, 15.582],
                                  [-17.248, 75.717],
                                  [-22.478, 72.97],
                                  [71.096, 41.035],
                                  [22.414, 49.928],
                                  [106.482, -13.036],
                                  [-53.645, -61.791],
                                  [1.443, -88.682],
                                  [17.932, -92],
                                ],
                                v: [
                                  [-64.622, -330.779],
                                  [-176.953, -229.741],
                                  [-260.576, -31.831],
                                  [-245.833, 151.606],
                                  [-112.09, 266.729],
                                  [128.055, 370.083],
                                  [323.258, 250.666],
                                  [154.05, -19.809],
                                  [198.995, -164.153],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.268, -7.463],
                                  [17.722, -75.179],
                                  [22.144, -73.3],
                                  [-70.499, -41.098],
                                  [-15.216, -40.824],
                                  [-163.217, 25.382],
                                  [52.839, 61.592],
                                  [-11.587, 78.617],
                                  [-18.021, 92],
                                ],
                                o: [
                                  [-83.299, 15.257],
                                  [-17.722, 75.179],
                                  [-22.144, 73.3],
                                  [70.499, 41.098],
                                  [22.454, 49.732],
                                  [106.433, -13.134],
                                  [-52.839, -61.592],
                                  [1.01, -88.081],
                                  [18.021, -92],
                                ],
                                v: [
                                  [-64.164, -329.626],
                                  [-176.864, -230.139],
                                  [-260.616, -32.254],
                                  [-245.465, 151.71],
                                  [-113.115, 266.212],
                                  [128.479, 369.92],
                                  [322.752, 249.994],
                                  [155.315, -18.217],
                                  [197.924, -163.583],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.467, -7.067],
                                  [18.201, -74.638],
                                  [21.807, -73.631],
                                  [-69.898, -41.161],
                                  [-15.192, -40.547],
                                  [-163.673, 25.59],
                                  [52.028, 61.393],
                                  [-11.245, 77.929],
                                  [-18.11, 92],
                                ],
                                o: [
                                  [-83.15, 14.93],
                                  [-18.201, 74.638],
                                  [-21.807, 73.631],
                                  [69.898, 41.161],
                                  [22.493, 49.533],
                                  [106.383, -13.233],
                                  [-52.028, -61.393],
                                  [0.574, -87.477],
                                  [18.11, -92],
                                ],
                                v: [
                                  [-63.669, -328.49],
                                  [-176.804, -230.542],
                                  [-260.646, -32.663],
                                  [-245.143, 151.805],
                                  [-114.148, 265.722],
                                  [128.898, 369.736],
                                  [322.248, 249.338],
                                  [156.628, -16.633],
                                  [196.872, -163.029],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.666, -6.668],
                                  [18.682, -74.093],
                                  [21.468, -73.966],
                                  [-69.294, -41.225],
                                  [-15.167, -40.268],
                                  [-164.132, 25.799],
                                  [51.211, 61.192],
                                  [-10.901, 77.236],
                                  [-18.2, 92],
                                ],
                                o: [
                                  [-83, 14.601],
                                  [-18.682, 74.093],
                                  [-21.468, 73.966],
                                  [69.294, 41.225],
                                  [22.533, 49.334],
                                  [106.333, -13.333],
                                  [-51.211, -61.192],
                                  [0.135, -86.869],
                                  [18.2, -92],
                                ],
                                v: [
                                  [-63.144, -327.353],
                                  [-176.745, -230.894],
                                  [-260.665, -33.05],
                                  [-244.811, 151.887],
                                  [-115.155, 265.189],
                                  [129.317, 369.56],
                                  [321.683, 248.692],
                                  [157.997, -15.009],
                                  [195.803, -162.459],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.867, -6.266],
                                  [19.166, -73.545],
                                  [21.126, -74.302],
                                  [-68.685, -41.289],
                                  [-15.142, -39.986],
                                  [-164.594, 26.01],
                                  [50.389, 60.99],
                                  [-10.555, 76.538],
                                  [-18.29, 92],
                                ],
                                o: [
                                  [-82.85, 14.27],
                                  [-19.166, 73.545],
                                  [-21.126, 74.302],
                                  [68.685, 41.289],
                                  [22.573, 49.133],
                                  [106.283, -13.433],
                                  [-50.389, -60.99],
                                  [-0.307, -86.256],
                                  [18.29, -92],
                                ],
                                v: [
                                  [-62.554, -326.18],
                                  [-176.715, -231.258],
                                  [-260.646, -33.421],
                                  [-244.524, 151.964],
                                  [-116.232, 264.718],
                                  [129.728, 369.381],
                                  [321.128, 248.081],
                                  [159.378, -13.366],
                                  [194.758, -161.926],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.069, -5.862],
                                  [19.654, -72.993],
                                  [20.783, -74.64],
                                  [-68.072, -41.353],
                                  [-15.116, -39.703],
                                  [-165.059, 26.222],
                                  [49.562, 60.787],
                                  [-10.206, 75.835],
                                  [-18.381, 92],
                                ],
                                o: [
                                  [-82.698, 13.936],
                                  [-19.654, 72.993],
                                  [-20.783, 74.64],
                                  [68.072, 41.353],
                                  [22.614, 48.931],
                                  [106.233, -13.534],
                                  [-49.562, -60.787],
                                  [-0.752, -85.64],
                                  [18.381, -92],
                                ],
                                v: [
                                  [-61.962, -324.998],
                                  [-176.711, -231.597],
                                  [-260.654, -33.813],
                                  [-244.281, 152.036],
                                  [-117.218, 264.24],
                                  [130.138, 369.183],
                                  [320.54, 247.48],
                                  [160.804, -11.704],
                                  [193.631, -161.377],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.272, -5.455],
                                  [20.145, -72.437],
                                  [20.437, -74.981],
                                  [-67.456, -41.418],
                                  [-15.091, -39.419],
                                  [-165.526, 26.436],
                                  [48.729, 60.582],
                                  [-9.855, 75.128],
                                  [-18.473, 92],
                                ],
                                o: [
                                  [-82.546, 13.601],
                                  [-20.145, 72.437],
                                  [-20.437, 74.981],
                                  [67.456, 41.418],
                                  [22.654, 48.728],
                                  [106.182, -13.636],
                                  [-48.729, -60.582],
                                  [-1.199, -85.019],
                                  [18.473, -92],
                                ],
                                v: [
                                  [-61.331, -323.844],
                                  [-176.708, -231.973],
                                  [-260.589, -34.154],
                                  [-244.056, 152.129],
                                  [-118.274, 263.764],
                                  [130.522, 368.98],
                                  [319.929, 246.861],
                                  [162.25, -9.996],
                                  [192.53, -160.814],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.477, -5.046],
                                  [20.639, -71.878],
                                  [20.089, -75.324],
                                  [-66.835, -41.483],
                                  [-15.065, -39.132],
                                  [-165.998, 26.651],
                                  [47.89, 60.376],
                                  [-9.502, 74.417],
                                  [-18.565, 92],
                                ],
                                o: [
                                  [-82.392, 13.263],
                                  [-20.639, 71.878],
                                  [-20.089, 75.324],
                                  [66.835, 41.483],
                                  [22.695, 48.523],
                                  [106.131, -13.739],
                                  [-47.89, -60.376],
                                  [-1.65, -84.395],
                                  [18.565, -92],
                                ],
                                v: [
                                  [-60.671, -322.692],
                                  [-176.693, -232.323],
                                  [-260.551, -34.441],
                                  [-243.848, 152.206],
                                  [-119.276, 263.282],
                                  [130.957, 368.758],
                                  [319.321, 246.315],
                                  [163.741, -8.305],
                                  [191.406, -160.299],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.683, -4.633],
                                  [21.136, -71.315],
                                  [19.738, -75.67],
                                  [-66.21, -41.549],
                                  [-15.04, -38.843],
                                  [-166.472, 26.868],
                                  [47.046, 60.169],
                                  [-9.146, 73.7],
                                  [-18.658, 92],
                                ],
                                o: [
                                  [-82.237, 12.922],
                                  [-21.136, 71.315],
                                  [-19.738, 75.67],
                                  [66.21, 41.549],
                                  [22.737, 48.317],
                                  [106.079, -13.842],
                                  [-47.046, -60.169],
                                  [-2.103, -83.766],
                                  [18.658, -92],
                                ],
                                v: [
                                  [-59.972, -321.509],
                                  [-176.715, -232.684],
                                  [-260.478, -34.749],
                                  [-243.621, 152.275],
                                  [-120.31, 262.856],
                                  [131.328, 368.53],
                                  [318.69, 245.742],
                                  [165.276, -6.571],
                                  [190.27, -159.76],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.891, -4.218],
                                  [21.637, -70.748],
                                  [19.385, -76.017],
                                  [-65.581, -41.615],
                                  [-15.014, -38.553],
                                  [-166.949, 27.085],
                                  [46.197, 59.96],
                                  [-8.788, 72.979],
                                  [-18.751, 92],
                                ],
                                o: [
                                  [-82.082, 12.58],
                                  [-21.637, 70.748],
                                  [-19.385, 76.017],
                                  [65.581, 41.615],
                                  [22.778, 48.109],
                                  [106.027, -13.945],
                                  [-46.197, -59.96],
                                  [-2.56, -83.133],
                                  [18.751, -92],
                                ],
                                v: [
                                  [-59.245, -320.344],
                                  [-176.761, -233.017],
                                  [-260.397, -35.041],
                                  [-243.462, 152.299],
                                  [-121.325, 262.399],
                                  [131.661, 368.282],
                                  [318.062, 245.215],
                                  [166.854, -4.819],
                                  [189.11, -159.245],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.1, -3.8],
                                  [22.141, -70.178],
                                  [19.03, -76.367],
                                  [-64.948, -41.682],
                                  [-14.988, -38.26],
                                  [-167.429, 27.305],
                                  [45.342, 59.75],
                                  [-8.428, 72.253],
                                  [-18.845, 92],
                                ],
                                o: [
                                  [-81.925, 12.235],
                                  [-22.141, 70.178],
                                  [-19.03, 76.367],
                                  [64.948, 41.682],
                                  [22.82, 47.9],
                                  [105.975, -14.05],
                                  [-45.342, -59.75],
                                  [-3.019, -82.496],
                                  [18.845, -92],
                                ],
                                v: [
                                  [-58.477, -319.199],
                                  [-176.83, -233.359],
                                  [-260.245, -35.316],
                                  [-243.293, 152.341],
                                  [-122.373, 262.011],
                                  [132.055, 368.026],
                                  [317.414, 244.698],
                                  [168.437, -3.062],
                                  [187.912, -158.744],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.31, -3.38],
                                  [22.648, -69.603],
                                  [18.673, -76.719],
                                  [-64.31, -41.749],
                                  [-14.961, -37.966],
                                  [-167.913, 27.526],
                                  [44.481, 59.538],
                                  [-8.065, 71.523],
                                  [-18.94, 92],
                                ],
                                o: [
                                  [-81.768, 11.888],
                                  [-22.648, 69.603],
                                  [-18.673, 76.719],
                                  [64.31, 41.749],
                                  [22.862, 47.69],
                                  [105.923, -14.155],
                                  [-44.481, -59.538],
                                  [-3.482, -81.854],
                                  [18.939, -92],
                                ],
                                v: [
                                  [-57.669, -318.035],
                                  [-176.884, -233.698],
                                  [-260.121, -35.575],
                                  [-243.191, 152.398],
                                  [-123.366, 261.618],
                                  [132.371, 367.776],
                                  [316.77, 244.216],
                                  [170.075, -1.263],
                                  [186.749, -158.233],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.522, -2.957],
                                  [23.158, -69.025],
                                  [18.313, -77.074],
                                  [-63.669, -41.816],
                                  [-14.935, -37.67],
                                  [-168.4, 27.748],
                                  [43.615, 59.325],
                                  [-7.7, 70.787],
                                  [-19.035, 92],
                                ],
                                o: [
                                  [-81.609, 11.539],
                                  [-23.158, 69.025],
                                  [-18.313, 77.074],
                                  [63.669, 41.816],
                                  [22.904, 47.478],
                                  [105.87, -14.261],
                                  [-43.615, -59.325],
                                  [-3.948, -81.209],
                                  [19.035, -92],
                                ],
                                v: [
                                  [-56.833, -316.88],
                                  [-176.973, -234.07],
                                  [-259.98, -35.778],
                                  [-243.105, 152.446],
                                  [-124.389, 261.259],
                                  [132.721, 367.53],
                                  [316.13, 243.719],
                                  [171.779, 0.513],
                                  [185.547, -157.736],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.735, -2.531],
                                  [23.672, -68.444],
                                  [17.951, -77.43],
                                  [-63.023, -41.884],
                                  [-14.908, -37.372],
                                  [-168.89, 27.971],
                                  [42.743, 59.111],
                                  [-7.333, 70.047],
                                  [-19.131, 92],
                                ],
                                o: [
                                  [-81.449, 11.188],
                                  [-23.672, 68.444],
                                  [-17.951, 77.43],
                                  [63.023, 41.884],
                                  [22.947, 47.265],
                                  [105.816, -14.367],
                                  [-42.743, -59.111],
                                  [-4.416, -80.559],
                                  [19.131, -92],
                                ],
                                v: [
                                  [-55.932, -315.736],
                                  [-177.083, -234.41],
                                  [-259.817, -36.003],
                                  [-243.058, 152.469],
                                  [-125.395, 260.896],
                                  [133.016, 367.249],
                                  [315.486, 243.295],
                                  [173.498, 2.344],
                                  [184.316, -157.231],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.949, -2.102],
                                  [24.189, -67.858],
                                  [17.587, -77.79],
                                  [-62.373, -41.953],
                                  [-14.881, -37.071],
                                  [-169.383, 28.196],
                                  [41.865, 58.895],
                                  [-6.963, 69.302],
                                  [-19.227, 92],
                                ],
                                o: [
                                  [-81.288, 10.834],
                                  [-24.189, 67.858],
                                  [-17.587, 77.79],
                                  [62.373, 41.952],
                                  [22.99, 47.051],
                                  [105.763, -14.474],
                                  [-41.865, -58.895],
                                  [-4.888, -79.906],
                                  [19.227, -92],
                                ],
                                v: [
                                  [-55.012, -314.652],
                                  [-177.197, -234.719],
                                  [-259.6, -36.21],
                                  [-243.012, 152.481],
                                  [-126.431, 260.544],
                                  [133.321, 366.934],
                                  [314.832, 242.882],
                                  [175.242, 4.137],
                                  [183.07, -156.702],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.165, -1.671],
                                  [24.71, -67.269],
                                  [17.22, -78.151],
                                  [-61.719, -42.021],
                                  [-14.854, -36.769],
                                  [-169.879, 28.423],
                                  [40.982, 58.678],
                                  [-6.591, 68.552],
                                  [-19.324, 92],
                                ],
                                o: [
                                  [-81.126, 10.478],
                                  [-24.71, 67.269],
                                  [-17.22, 78.151],
                                  [61.719, 42.021],
                                  [23.033, 46.835],
                                  [105.709, -14.582],
                                  [-40.982, -58.678],
                                  [-5.362, -79.248],
                                  [19.324, -92],
                                ],
                                v: [
                                  [-54.064, -313.518],
                                  [-177.306, -235.057],
                                  [-259.376, -36.439],
                                  [-243.002, 152.482],
                                  [-127.45, 260.253],
                                  [133.568, 366.622],
                                  [314.161, 242.518],
                                  [177.062, 5.968],
                                  [181.793, -156.228],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.382, -1.236],
                                  [25.234, -66.676],
                                  [16.851, -78.515],
                                  [-61.061, -42.09],
                                  [-14.827, -36.465],
                                  [-170.378, 28.651],
                                  [40.093, 58.459],
                                  [-6.216, 67.798],
                                  [-19.422, 92],
                                ],
                                o: [
                                  [-80.964, 10.12],
                                  [-25.234, 66.676],
                                  [-16.851, 78.515],
                                  [61.061, 42.09],
                                  [23.076, 46.618],
                                  [105.655, -14.691],
                                  [-40.093, -58.459],
                                  [-5.84, -78.585],
                                  [19.422, -92],
                                ],
                                v: [
                                  [-53.051, -312.445],
                                  [-177.472, -235.384],
                                  [-259.123, -36.611],
                                  [-243.03, 152.478],
                                  [-128.477, 259.975],
                                  [133.849, 366.337],
                                  [313.535, 242.189],
                                  [178.857, 7.812],
                                  [180.498, -155.77],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.6, -0.799],
                                  [25.761, -66.079],
                                  [16.479, -78.881],
                                  [-60.399, -42.16],
                                  [-14.8, -36.159],
                                  [-170.881, 28.88],
                                  [39.198, 58.24],
                                  [-5.839, 67.038],
                                  [-19.52, 92],
                                ],
                                o: [
                                  [-80.8, 9.759],
                                  [-25.761, 66.079],
                                  [-16.479, 78.881],
                                  [60.399, 42.16],
                                  [23.12, 46.4],
                                  [105.6, -14.8],
                                  [-39.198, -58.239],
                                  [-6.321, -77.919],
                                  [19.52, -92],
                                ],
                                v: [
                                  [-51.993, -311.348],
                                  [-177.593, -235.716],
                                  [-258.905, -36.806],
                                  [-243.093, 152.5],
                                  [-129.493, 259.759],
                                  [134.067, 365.992],
                                  [312.893, 241.87],
                                  [180.666, 9.654],
                                  [179.186, -155.306],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.82, -0.359],
                                  [26.292, -65.478],
                                  [16.105, -79.249],
                                  [-59.732, -42.23],
                                  [-14.772, -35.851],
                                  [-171.387, 29.112],
                                  [38.298, 58.018],
                                  [-5.46, 66.274],
                                  [-19.619, 92],
                                ],
                                o: [
                                  [-80.635, 9.396],
                                  [-26.292, 65.478],
                                  [-16.105, 79.249],
                                  [59.732, 42.23],
                                  [23.164, 46.18],
                                  [105.545, -14.91],
                                  [-38.297, -58.018],
                                  [-6.805, -77.248],
                                  [19.619, -92],
                                ],
                                v: [
                                  [-50.932, -310.309],
                                  [-177.751, -236.035],
                                  [-258.618, -36.961],
                                  [-243.15, 152.469],
                                  [-130.532, 259.519],
                                  [134.235, 365.609],
                                  [312.281, 241.603],
                                  [182.511, 11.508],
                                  [177.878, -154.837],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.042, 0.084],
                                  [26.826, -64.873],
                                  [15.729, -79.62],
                                  [-59.061, -42.301],
                                  [-14.745, -35.541],
                                  [-171.896, 29.344],
                                  [37.391, 57.795],
                                  [-5.078, 65.505],
                                  [-19.719, 92],
                                ],
                                o: [
                                  [-80.469, 9.031],
                                  [-26.826, 64.873],
                                  [-15.729, 79.62],
                                  [59.061, 42.301],
                                  [23.208, 45.958],
                                  [105.49, -15.021],
                                  [-37.391, -57.795],
                                  [-7.292, -76.572],
                                  [19.719, -92],
                                ],
                                v: [
                                  [-49.825, -309.293],
                                  [-177.924, -236.337],
                                  [-258.305, -37.163],
                                  [-243.303, 152.447],
                                  [-131.538, 259.319],
                                  [134.375, 365.206],
                                  [311.672, 241.409],
                                  [184.431, 13.418],
                                  [176.49, -154.367],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.265, 0.529],
                                  [27.363, -64.265],
                                  [15.35, -79.993],
                                  [-58.385, -42.372],
                                  [-14.717, -35.229],
                                  [-172.409, 29.578],
                                  [36.479, 57.571],
                                  [-4.693, 64.73],
                                  [-19.819, 92],
                                ],
                                o: [
                                  [-80.302, 8.663],
                                  [-27.363, 64.265],
                                  [-15.35, 79.993],
                                  [58.385, 42.372],
                                  [23.253, 45.735],
                                  [105.434, -15.132],
                                  [-36.479, -57.571],
                                  [-7.782, -75.893],
                                  [19.819, -92],
                                ],
                                v: [
                                  [-48.651, -308.256],
                                  [-178.109, -236.663],
                                  [-257.986, -37.284],
                                  [-243.384, 152.368],
                                  [-132.592, 259.161],
                                  [134.469, 364.744],
                                  [311.053, 241.228],
                                  [186.261, 15.258],
                                  [175.127, -153.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.489, 0.978],
                                  [27.904, -63.652],
                                  [14.969, -80.369],
                                  [-57.706, -42.443],
                                  [-14.689, -34.916],
                                  [-172.924, 29.813],
                                  [35.561, 57.346],
                                  [-4.307, 63.951],
                                  [-19.92, 92],
                                ],
                                o: [
                                  [-80.133, 8.293],
                                  [-27.904, 63.652],
                                  [-14.969, 80.369],
                                  [57.706, 42.443],
                                  [23.298, 45.511],
                                  [105.378, -15.244],
                                  [-35.561, -57.346],
                                  [-8.276, -75.209],
                                  [19.92, -92],
                                ],
                                v: [
                                  [-47.471, -307.282],
                                  [-178.306, -237.012],
                                  [-257.621, -37.471],
                                  [-243.538, 152.337],
                                  [-133.631, 259.044],
                                  [134.473, 364.285],
                                  [310.462, 241.058],
                                  [188.188, 17.149],
                                  [173.722, -153.456],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.715, 1.429],
                                  [28.449, -63.036],
                                  [14.585, -80.747],
                                  [-57.021, -42.515],
                                  [-14.661, -34.6],
                                  [-173.443, 30.05],
                                  [34.637, 57.118],
                                  [-3.917, 63.167],
                                  [-20.022, 92],
                                ],
                                o: [
                                  [-79.964, 7.921],
                                  [-28.449, 63.036],
                                  [-14.585, 80.747],
                                  [57.021, 42.515],
                                  [23.343, 45.285],
                                  [105.321, -15.357],
                                  [-34.637, -57.119],
                                  [-8.772, -74.521],
                                  [20.022, -92],
                                ],
                                v: [
                                  [-46.245, -306.374],
                                  [-178.472, -237.319],
                                  [-257.316, -37.643],
                                  [-243.68, 152.249],
                                  [-134.658, 258.95],
                                  [134.452, 363.788],
                                  [309.921, 241.004],
                                  [190.126, 19.051],
                                  [172.277, -153.018],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [126.942, 1.883],
                                  [28.997, -62.416],
                                  [14.199, -81.127],
                                  [-56.333, -42.588],
                                  [-14.632, -34.282],
                                  [-173.966, 30.289],
                                  [33.708, 56.89],
                                  [-3.526, 62.378],
                                  [-20.124, 92],
                                ],
                                o: [
                                  [-79.794, 7.546],
                                  [-28.997, 62.416],
                                  [-14.199, 81.127],
                                  [56.333, 42.588],
                                  [23.388, 45.058],
                                  [105.265, -15.471],
                                  [-33.708, -56.89],
                                  [-9.272, -73.828],
                                  [20.124, -92],
                                ],
                                v: [
                                  [-44.95, -305.449],
                                  [-178.688, -237.624],
                                  [-256.924, -37.778],
                                  [-243.83, 152.186],
                                  [-135.712, 258.922],
                                  [134.385, 363.21],
                                  [309.391, 240.963],
                                  [192.034, 20.92],
                                  [170.812, -152.536],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.17, 2.34],
                                  [29.548, -61.791],
                                  [13.811, -81.51],
                                  [-55.64, -42.66],
                                  [-14.604, -33.962],
                                  [-174.491, 30.529],
                                  [32.772, 56.66],
                                  [-3.131, 61.583],
                                  [-20.227, 92],
                                ],
                                o: [
                                  [-79.622, 7.169],
                                  [-29.548, 61.791],
                                  [-13.811, 81.51],
                                  [55.64, 42.66],
                                  [23.434, 44.83],
                                  [105.207, -15.585],
                                  [-32.772, -56.66],
                                  [-9.774, -73.131],
                                  [20.227, -92],
                                ],
                                v: [
                                  [-43.649, -304.551],
                                  [-178.871, -237.927],
                                  [-256.509, -37.94],
                                  [-244.008, 152.065],
                                  [-136.795, 258.876],
                                  [134.23, 362.678],
                                  [308.87, 240.935],
                                  [193.996, 22.797],
                                  [169.325, -152.094],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.4, 2.8],
                                  [30.103, -61.163],
                                  [13.42, -81.895],
                                  [-54.943, -42.734],
                                  [-14.575, -33.64],
                                  [-175.02, 30.77],
                                  [31.83, 56.429],
                                  [-2.735, 60.784],
                                  [-20.33, 92],
                                ],
                                o: [
                                  [-79.45, 6.79],
                                  [-30.103, 61.163],
                                  [-13.42, 81.895],
                                  [54.943, 42.734],
                                  [23.48, 44.6],
                                  [105.15, -15.7],
                                  [-31.83, -56.429],
                                  [-10.28, -72.429],
                                  [20.33, -92],
                                ],
                                v: [
                                  [-42.3, -303.722],
                                  [-179.059, -238.204],
                                  [-256.093, -38.129],
                                  [-244.171, 151.927],
                                  [-137.865, 258.94],
                                  [134.05, 362.045],
                                  [308.425, 241.026],
                                  [195.947, 24.678],
                                  [167.837, -151.65],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.632, 3.263],
                                  [30.661, -60.531],
                                  [13.026, -82.283],
                                  [-54.241, -42.808],
                                  [-14.546, -33.316],
                                  [-175.553, 31.013],
                                  [30.883, 56.196],
                                  [-2.335, 59.98],
                                  [-20.434, 92],
                                ],
                                o: [
                                  [-79.276, 6.408],
                                  [-30.661, 60.531],
                                  [-13.026, 82.283],
                                  [54.241, 42.808],
                                  [23.526, 44.368],
                                  [105.092, -15.816],
                                  [-30.883, -56.196],
                                  [-10.79, -71.723],
                                  [20.434, -92],
                                ],
                                v: [
                                  [-40.901, -302.922],
                                  [-179.234, -238.434],
                                  [-255.631, -38.304],
                                  [-244.36, 151.773],
                                  [-138.943, 258.989],
                                  [133.805, 361.439],
                                  [307.968, 241.149],
                                  [197.928, 26.566],
                                  [166.35, -151.161],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [127.865, 3.729],
                                  [31.223, -59.895],
                                  [12.63, -82.673],
                                  [-53.535, -42.882],
                                  [-14.517, -32.99],
                                  [-176.089, 31.258],
                                  [29.929, 55.961],
                                  [-1.934, 59.17],
                                  [-20.539, 92],
                                ],
                                o: [
                                  [-79.102, 6.023],
                                  [-31.223, 59.895],
                                  [-12.63, 82.673],
                                  [53.535, 42.882],
                                  [23.573, 44.135],
                                  [105.034, -15.932],
                                  [-29.929, -55.961],
                                  [-11.302, -71.013],
                                  [20.539, -92],
                                ],
                                v: [
                                  [-39.494, -302.174],
                                  [-179.413, -238.659],
                                  [-255.173, -38.533],
                                  [-244.554, 151.557],
                                  [-140.029, 259.087],
                                  [133.537, 360.731],
                                  [307.547, 241.373],
                                  [199.919, 28.416],
                                  [164.861, -150.692],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.099, 4.198],
                                  [31.789, -59.255],
                                  [12.232, -83.066],
                                  [-52.825, -42.957],
                                  [-14.488, -32.661],
                                  [-176.628, 31.504],
                                  [28.97, 55.725],
                                  [-1.529, 58.356],
                                  [-20.645, 92],
                                ],
                                o: [
                                  [-78.926, 5.637],
                                  [-31.789, 59.255],
                                  [-12.232, 83.066],
                                  [52.825, 42.957],
                                  [23.62, 43.901],
                                  [104.975, -16.05],
                                  [-28.97, -55.725],
                                  [-11.818, -70.298],
                                  [20.645, -92],
                                ],
                                v: [
                                  [-38.036, -301.459],
                                  [-179.595, -238.917],
                                  [-254.713, -38.75],
                                  [-244.754, 151.387],
                                  [-141.148, 259.235],
                                  [133.222, 360.028],
                                  [307.182, 241.612],
                                  [201.92, 30.246],
                                  [163.351, -150.242],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [128.335, 4.67],
                                  [32.358, -58.61],
                                  [11.831, -83.461],
                                  [-52.11, -43.032],
                                  [-14.458, -32.331],
                                  [-177.17, 31.752],
                                  [28.004, 55.488],
                                  [-1.122, 57.536],
                                  [-20.751, 92],
                                ],
                                o: [
                                  [-78.749, 5.247],
                                  [-32.358, 58.61],
                                  [-11.831, 83.461],
                                  [52.11, 43.032],
                                  [23.667, 43.665],
                                  [104.916, -16.167],
                                  [-28.004, -55.488],
                                  [-12.337, -69.579],
                                  [20.751, -92],
                                ],
                                v: [
                                  [-36.507, -300.816],
                                  [-179.761, -239.124],
                                  [-254.191, -38.998],
                                  [-244.933, 151.18],
                                  [-142.277, 259.478],
                                  [132.822, 359.328],
                                  [306.831, 241.884],
                                  [203.887, 32.098],
                                  [161.881, -149.767],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [26.438, 29.188],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, 28.344],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.594, 27.531],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.219, 26.656],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.844, 25.812],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 24.969],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.219, 24.156],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.938, 23.375],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.688, 22.625],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 21.875],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.281, 21.188],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.094, 20.5],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.906, 19.906],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 19.281],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.719, 18.656],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.594, 18.125],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.531, 17.562],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 17.062],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 16.594],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.438, 16.125],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 15.656],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 15.25],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.562, 14.875],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.594, 14.5],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.656, 14.094],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.562, 13.781],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.406, 13.438],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.281, 13.188],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.219, 12.875],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.094, 12.625],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22, 12.375],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.969, 12.156],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.938, 11.906],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.938, 11.688],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.938, 11.312],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22, 11.125],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22, 10.969],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.188, 10.594],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.281, 10.438],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 10.188],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.531, 10.125],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.625, 10.062],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.75, 9.969],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.938, 9.969],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.094, 10],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.188, 10.031],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 10.031],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.469, 10.125],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.656, 10.219],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.812, 10.312],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.469, 10.688],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 10.812],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 10.969],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.219, 11.156],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.469, 11.281],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 11.656],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.281, 11.812],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 12.031],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.844, 12.25],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.156, 12.469],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.469, 12.656],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.75, 12.844],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28, 13.062],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, 13.312],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.625, 13.531],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.875, 13.781],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.719, 14.438],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.688, 14.719],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.594, 14.938],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.469, 15.125],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.344, 15.406],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.219, 15.625],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.094, 15.875],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.969, 16.094],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.656, 16.531],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, 16.781],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, 16.969],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.156, 17.188],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, 17.438],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.812, 17.688],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.625, 17.969],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.469, 18.25],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.25, 18.531],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, 18.812],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.875, 19.062],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.656, 19.375],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.438, 19.656],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, 19.938],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 20.25],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.906, 20.531],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.719, 20.875],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.5, 21.125],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.375, 21.406],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.188, 21.688],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.031, 21.969],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.906, 22.25],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 22.5],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.562, 22.812],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.469, 23.031],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.344, 23.281],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.219, 23.562],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.125, 23.781],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24, 24.031],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.938, 24.25],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.844, 24.469],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.781, 24.719],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.594, 25.031],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.531, 25.188],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 25.312],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.531, 25.531],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 25.688],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, 25.812],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, 25.938],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, 26.031],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.625, 26.062],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.656, 26.031],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.75, 26.031],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.812, 25.969],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.875, 25.938],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.969, 25.844],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.219, 25.594],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.281, 25.469],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.125, 25.281],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.938, 25.094],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.812, 24.875],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.625, 24.688],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 24.406],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.375, 24.156],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.312, 23.844],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.188, 23.531],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.125, 23.219],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.062, 22.844],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23, 22.5],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.969, 22.125],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.969, 21.75],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.969, 21.344],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 20.969],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.062, 20.562],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.156, 20.156],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.219, 19.719],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 19.281],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.406, 18.875],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, 18.406],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.75, 18],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.906, 17.562],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.094, 17.125],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.281, 16.656],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 16.219],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.75, 15.844],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.031, 15.406],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.281, 14.969],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.594, 14.625],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.875, 14.219],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, 13.844],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 13.469],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.938, 13.125],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.344, 12.812],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.719, 12.5],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.531, 11.938],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.969, 11.688],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.438, 11.438],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.875, 11.281],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.344, 11.094],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.844, 10.969],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.281, 10.844],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.75, 10.781],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.188, 10.719],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.656, 10.719],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.062, 10.688],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.5, 10.75],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.875, 10.812],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.25, 10.938],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.688, 11.062],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35, 11.25],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.375, 11.469],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.406, 11.656],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.312, 11.906],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.25, 12.188],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.156, 12.438],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.031, 12.719],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.906, 13.062],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.75, 13.375],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.594, 13.719],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.438, 14.094],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.25, 14.469],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.031, 14.812],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.875, 15.25],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.625, 15.625],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.375, 16.031],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 16.469],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.969, 16.906],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.688, 17.344],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.406, 17.812],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.156, 18.281],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.875, 18.719],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.625, 19.188],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.344, 19.656],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.062, 20.156],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.812, 20.625],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.5, 21.094],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.25, 21.594],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.688, 22.594],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.375, 23.031],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.094, 23.5],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.844, 23.969],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.594, 24.406],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, 24.844],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.062, 25.312],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.844, 25.75],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.594, 26.156],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.406, 26.562],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.219, 26.938],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27, 27.312],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.812, 27.656],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 27.938],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.531, 28.25],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.406, 28.5],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.312, 28.719],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.219, 28.875],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, 29.062],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, 29.156],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.156, 29.25],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, 29.281],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, 29.281],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.312, 29.25],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [26.438, 29.188],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, 28.344],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.594, 27.531],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.219, 26.656],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.844, 25.812],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 24.969],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.219, 24.156],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.938, 23.375],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.688, 22.625],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 21.875],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.281, 21.188],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.094, 20.5],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.906, 19.906],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 19.281],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.719, 18.656],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.594, 18.125],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.531, 17.562],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 17.062],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 16.594],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.438, 16.125],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 15.656],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 15.25],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.562, 14.875],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.594, 14.5],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.656, 14.094],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.562, 13.781],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.406, 13.438],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.281, 13.188],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.219, 12.875],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.094, 12.625],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22, 12.375],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.969, 12.156],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.938, 11.906],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.938, 11.688],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.938, 11.312],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22, 11.125],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22, 10.969],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.188, 10.594],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.281, 10.438],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 10.188],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.531, 10.125],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.625, 10.062],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.75, 9.969],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.938, 9.969],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.094, 10],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.188, 10.031],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 10.031],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.469, 10.125],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.656, 10.219],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.812, 10.312],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.469, 10.688],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 10.812],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 10.969],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.219, 11.156],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.469, 11.281],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 11.656],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.281, 11.812],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 12.031],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.844, 12.25],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.156, 12.469],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.469, 12.656],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.75, 12.844],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28, 13.062],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, 13.312],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.625, 13.531],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.875, 13.781],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.719, 14.438],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.688, 14.719],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.594, 14.938],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.469, 15.125],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.344, 15.406],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.219, 15.625],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.094, 15.875],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.969, 16.094],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.656, 16.531],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, 16.781],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, 16.969],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.156, 17.188],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, 17.438],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.812, 17.688],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.625, 17.969],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.469, 18.25],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.25, 18.531],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, 18.812],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.875, 19.062],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.656, 19.375],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.438, 19.656],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, 19.938],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 20.25],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.906, 20.531],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.719, 20.875],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.5, 21.125],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.375, 21.406],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.188, 21.688],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.031, 21.969],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.906, 22.25],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 22.5],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.562, 22.812],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.469, 23.031],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.344, 23.281],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.219, 23.562],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.125, 23.781],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24, 24.031],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.938, 24.25],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.844, 24.469],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.781, 24.719],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.594, 25.031],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.531, 25.188],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 25.312],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.531, 25.531],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 25.688],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, 25.812],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, 25.938],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, 26.031],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.625, 26.062],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.656, 26.031],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.75, 26.031],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.812, 25.969],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.875, 25.938],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.969, 25.844],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.219, 25.594],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.281, 25.469],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.125, 25.281],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.938, 25.094],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.812, 24.875],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.625, 24.688],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 24.406],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.375, 24.156],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.312, 23.844],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.188, 23.531],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.125, 23.219],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.062, 22.844],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23, 22.5],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.969, 22.125],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.969, 21.75],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.969, 21.344],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 20.969],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.062, 20.562],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.156, 20.156],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.219, 19.719],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 19.281],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.406, 18.875],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, 18.406],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.75, 18],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.906, 17.562],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.094, 17.125],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.281, 16.656],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 16.219],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.75, 15.844],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.031, 15.406],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.281, 14.969],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.594, 14.625],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.875, 14.219],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, 13.844],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 13.469],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.938, 13.125],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.344, 12.812],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.719, 12.5],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.531, 11.938],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.969, 11.688],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.438, 11.438],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.875, 11.281],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.344, 11.094],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.844, 10.969],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.281, 10.844],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.75, 10.781],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.188, 10.719],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.656, 10.719],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.062, 10.688],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.5, 10.75],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.875, 10.812],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.25, 10.938],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.688, 11.062],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35, 11.25],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.375, 11.469],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.406, 11.656],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.312, 11.906],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.25, 12.188],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.156, 12.438],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.031, 12.719],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.906, 13.062],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.75, 13.375],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.594, 13.719],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.438, 14.094],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.25, 14.469],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.031, 14.812],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.875, 15.25],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.625, 15.625],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.375, 16.031],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 16.469],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.969, 16.906],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.688, 17.344],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.406, 17.812],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.156, 18.281],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.875, 18.719],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.625, 19.188],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.344, 19.656],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.062, 20.156],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.812, 20.625],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.5, 21.094],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.25, 21.594],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.688, 22.594],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.375, 23.031],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.094, 23.5],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.844, 23.969],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.594, 24.406],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, 24.844],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.062, 25.312],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.844, 25.75],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.594, 26.156],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.406, 26.562],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.219, 26.938],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27, 27.312],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.812, 27.656],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 27.938],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.531, 28.25],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.406, 28.5],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.312, 28.719],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.219, 28.875],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, 29.062],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, 29.156],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.156, 29.25],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, 29.281],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, 29.281],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.312, 29.25],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 5',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [125.715, -0.57],
                                  [26.038, -65.766],
                                  [16.284, -79.073],
                                  [-60.051, -42.197],
                                  [-14.786, -35.999],
                                  [-171.145, 29.001],
                                  [38.729, 58.124],
                                  [-5.642, 66.64],
                                  [-19.572, 92],
                                ],
                                o: [
                                  [-80.714, 9.57],
                                  [-26.038, 65.766],
                                  [-16.284, 79.073],
                                  [60.051, 42.197],
                                  [23.143, 46.285],
                                  [105.571, -14.858],
                                  [-38.729, -58.124],
                                  [-6.573, -77.569],
                                  [19.572, -92],
                                ],
                                v: [
                                  [-45.345, -311.974],
                                  [-178.621, -231.935],
                                  [-255.995, -34.028],
                                  [-244.049, 150.67],
                                  [-127.728, 265.913],
                                  [134.142, 363.463],
                                  [302.068, 240.081],
                                  [183.546, 12.533],
                                  [173.153, -156.967],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.448, -1.103],
                                  [25.394, -66.494],
                                  [16.738, -78.626],
                                  [-60.86, -42.112],
                                  [-14.819, -36.372],
                                  [-170.531, 28.721],
                                  [39.821, 58.393],
                                  [-6.102, 67.567],
                                  [-19.452, 92],
                                ],
                                o: [
                                  [-80.914, 10.01],
                                  [-25.394, 66.494],
                                  [-16.738, 78.626],
                                  [60.86, 42.112],
                                  [23.09, 46.552],
                                  [105.638, -14.724],
                                  [-39.821, -58.393],
                                  [-5.986, -78.383],
                                  [19.452, -92],
                                ],
                                v: [
                                  [-45.653, -313.361],
                                  [-178.713, -230.91],
                                  [-255.971, -33.358],
                                  [-244.248, 150.676],
                                  [-126.114, 267.047],
                                  [133.862, 363.266],
                                  [301.115, 240.007],
                                  [181.929, 10.846],
                                  [174.301, -157.642],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.184, -1.632],
                                  [24.756, -67.217],
                                  [17.188, -78.183],
                                  [-61.662, -42.027],
                                  [-14.852, -36.743],
                                  [-169.923, 28.443],
                                  [40.904, 58.659],
                                  [-6.558, 68.486],
                                  [-19.333, 92],
                                ],
                                o: [
                                  [-81.112, 10.447],
                                  [-24.756, 67.217],
                                  [-17.188, 78.183],
                                  [61.662, 42.027],
                                  [23.037, 46.816],
                                  [105.704, -14.592],
                                  [-40.904, -58.659],
                                  [-5.404, -79.189],
                                  [19.333, -92],
                                ],
                                v: [
                                  [-45.917, -314.746],
                                  [-178.763, -229.962],
                                  [-255.948, -32.694],
                                  [-244.4, 150.706],
                                  [-124.446, 268.123],
                                  [133.531, 363.062],
                                  [300.19, 239.964],
                                  [180.311, 9.194],
                                  [175.455, -158.314],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.921, -2.157],
                                  [24.123, -67.933],
                                  [17.633, -77.744],
                                  [-62.457, -41.944],
                                  [-14.885, -37.11],
                                  [-169.319, 28.168],
                                  [41.978, 58.923],
                                  [-7.01, 69.398],
                                  [-19.215, 92],
                                ],
                                o: [
                                  [-81.309, 10.88],
                                  [-24.123, 67.933],
                                  [-17.633, 77.743],
                                  [62.457, 41.944],
                                  [22.984, 47.078],
                                  [105.77, -14.461],
                                  [-41.978, -58.923],
                                  [-4.827, -79.989],
                                  [19.215, -92],
                                ],
                                v: [
                                  [-46.2, -316.131],
                                  [-178.857, -228.988],
                                  [-255.893, -32.029],
                                  [-244.57, 150.76],
                                  [-122.71, 269.158],
                                  [133.179, 362.813],
                                  [299.315, 239.914],
                                  [178.7, 7.537],
                                  [176.616, -158.899],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.661, -2.677],
                                  [23.496, -68.644],
                                  [18.076, -77.308],
                                  [-63.245, -41.861],
                                  [-14.917, -37.474],
                                  [-168.721, 27.894],
                                  [43.042, 59.185],
                                  [-7.459, 70.302],
                                  [-19.098, 92],
                                ],
                                o: [
                                  [-81.504, 11.309],
                                  [-23.496, 68.644],
                                  [-18.076, 77.308],
                                  [63.245, 41.861],
                                  [22.932, 47.339],
                                  [105.835, -14.331],
                                  [-43.043, -59.184],
                                  [-4.255, -80.783],
                                  [19.098, -92],
                                ],
                                v: [
                                  [-46.481, -317.499],
                                  [-178.97, -227.991],
                                  [-255.814, -31.406],
                                  [-244.735, 150.852],
                                  [-120.983, 270.174],
                                  [132.776, 362.557],
                                  [298.453, 239.918],
                                  [177.151, 5.901],
                                  [177.798, -159.421],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.403, -3.193],
                                  [22.873, -69.348],
                                  [18.514, -76.876],
                                  [-64.027, -41.779],
                                  [-14.95, -37.835],
                                  [-168.128, 27.624],
                                  [44.099, 59.444],
                                  [-7.904, 71.198],
                                  [-18.982, 92],
                                ],
                                o: [
                                  [-81.697, 11.734],
                                  [-22.873, 69.348],
                                  [-18.514, 76.876],
                                  [64.027, 41.779],
                                  [22.881, 47.597],
                                  [105.899, -14.202],
                                  [-44.099, -59.444],
                                  [-3.688, -81.57],
                                  [18.982, -92],
                                ],
                                v: [
                                  [-46.772, -318.803],
                                  [-179.08, -227.071],
                                  [-255.68, -30.762],
                                  [-244.882, 150.966],
                                  [-119.229, 271.147],
                                  [132.343, 362.295],
                                  [297.617, 239.938],
                                  [175.587, 4.276],
                                  [179.047, -159.906],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.148, -3.705],
                                  [22.256, -70.047],
                                  [18.949, -76.447],
                                  [-64.803, -41.697],
                                  [-14.982, -38.193],
                                  [-167.54, 27.355],
                                  [45.146, 59.701],
                                  [-8.345, 72.087],
                                  [-18.866, 92],
                                ],
                                o: [
                                  [-81.889, 12.156],
                                  [-22.256, 70.047],
                                  [-18.949, 76.447],
                                  [64.803, 41.697],
                                  [22.83, 47.852],
                                  [105.963, -14.074],
                                  [-45.146, -59.701],
                                  [-3.125, -82.35],
                                  [18.866, -92],
                                ],
                                v: [
                                  [-47.108, -319.992],
                                  [-179.233, -226.129],
                                  [-255.53, -30.12],
                                  [-245.026, 151.077],
                                  [-117.424, 272.088],
                                  [131.842, 361.964],
                                  [296.77, 239.976],
                                  [174.046, 2.674],
                                  [180.263, -160.344],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.894, -4.212],
                                  [21.644, -70.74],
                                  [19.38, -76.022],
                                  [-65.571, -41.616],
                                  [-15.013, -38.548],
                                  [-166.956, 27.089],
                                  [46.184, 59.957],
                                  [-8.783, 72.968],
                                  [-18.752, 92],
                                ],
                                o: [
                                  [-82.08, 12.575],
                                  [-21.644, 70.74],
                                  [-19.38, 76.022],
                                  [65.571, 41.616],
                                  [22.779, 48.106],
                                  [106.026, -13.947],
                                  [-46.184, -59.957],
                                  [-2.567, -83.123],
                                  [18.752, -92],
                                ],
                                v: [
                                  [-47.453, -321.144],
                                  [-179.38, -225.202],
                                  [-255.34, -29.53],
                                  [-245.191, 151.183],
                                  [-115.617, 272.987],
                                  [131.334, 361.653],
                                  [296, 240.045],
                                  [172.491, 1.046],
                                  [181.559, -160.711],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.642, -4.715],
                                  [21.037, -71.426],
                                  [19.808, -75.601],
                                  [-66.334, -41.536],
                                  [-15.045, -38.901],
                                  [-166.378, 26.825],
                                  [47.214, 60.21],
                                  [-9.217, 73.842],
                                  [-18.639, 92],
                                ],
                                o: [
                                  [-82.268, 12.99],
                                  [-21.037, 71.426],
                                  [-19.808, 75.601],
                                  [66.334, 41.536],
                                  [22.729, 48.358],
                                  [106.089, -13.821],
                                  [-47.214, -60.21],
                                  [-2.013, -83.89],
                                  [18.639, -92],
                                ],
                                v: [
                                  [-47.855, -322.185],
                                  [-179.509, -224.316],
                                  [-255.133, -28.878],
                                  [-245.318, 151.308],
                                  [-113.786, 273.832],
                                  [130.758, 361.311],
                                  [295.193, 240.108],
                                  [171.022, -0.521],
                                  [182.795, -161.033],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.393, -5.214],
                                  [20.436, -72.108],
                                  [20.232, -75.183],
                                  [-67.09, -41.457],
                                  [-15.076, -39.25],
                                  [-165.804, 26.563],
                                  [48.234, 60.461],
                                  [-9.647, 74.709],
                                  [-18.527, 92],
                                ],
                                o: [
                                  [-82.455, 13.401],
                                  [-20.436, 72.108],
                                  [-20.232, 75.183],
                                  [67.09, 41.457],
                                  [22.679, 48.607],
                                  [106.152, -13.697],
                                  [-48.235, -60.461],
                                  [-1.465, -84.651],
                                  [18.527, -92],
                                ],
                                v: [
                                  [-48.29, -323.216],
                                  [-179.694, -223.384],
                                  [-254.91, -28.236],
                                  [-245.433, 151.489],
                                  [-111.969, 274.659],
                                  [130.15, 360.989],
                                  [294.5, 240.189],
                                  [169.514, -2.078],
                                  [184.097, -161.361],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.146, -5.708],
                                  [19.839, -72.783],
                                  [20.652, -74.769],
                                  [-67.839, -41.378],
                                  [-15.107, -39.596],
                                  [-165.235, 26.303],
                                  [49.247, 60.71],
                                  [-10.073, 75.568],
                                  [-18.416, 92],
                                ],
                                o: [
                                  [-82.641, 13.809],
                                  [-19.839, 72.783],
                                  [-20.652, 74.769],
                                  [67.839, 41.378],
                                  [22.629, 48.854],
                                  [106.214, -13.573],
                                  [-49.247, -60.71],
                                  [-0.921, -85.405],
                                  [18.416, -92],
                                ],
                                v: [
                                  [-48.719, -324.115],
                                  [-179.861, -222.494],
                                  [-254.644, -27.602],
                                  [-245.583, 151.635],
                                  [-110.164, 275.408],
                                  [129.536, 360.599],
                                  [293.734, 240.292],
                                  [168.029, -3.609],
                                  [185.403, -161.598],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.901, -6.199],
                                  [19.248, -73.453],
                                  [21.069, -74.359],
                                  [-68.582, -41.3],
                                  [-15.137, -39.939],
                                  [-164.671, 26.046],
                                  [50.25, 60.956],
                                  [-10.496, 76.42],
                                  [-18.305, 92],
                                ],
                                o: [
                                  [-82.825, 14.214],
                                  [-19.248, 73.453],
                                  [-21.069, 74.359],
                                  [68.582, 41.3],
                                  [22.58, 49.099],
                                  [106.275, -13.45],
                                  [-50.25, -60.956],
                                  [-0.381, -86.153],
                                  [18.305, -92],
                                ],
                                v: [
                                  [-49.178, -325.008],
                                  [-180.055, -221.621],
                                  [-254.354, -26.966],
                                  [-245.698, 151.86],
                                  [-108.301, 276.203],
                                  [128.888, 360.204],
                                  [293.047, 240.424],
                                  [166.558, -5.139],
                                  [186.721, -161.79],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.658, -6.685],
                                  [18.661, -74.117],
                                  [21.482, -73.951],
                                  [-69.319, -41.222],
                                  [-15.168, -40.279],
                                  [-164.112, 25.79],
                                  [51.246, 61.201],
                                  [-10.916, 77.265],
                                  [-18.196, 92],
                                ],
                                o: [
                                  [-83.007, 14.615],
                                  [-18.661, 74.117],
                                  [-21.482, 73.951],
                                  [69.319, 41.222],
                                  [22.532, 49.342],
                                  [106.336, -13.329],
                                  [-51.246, -61.201],
                                  [0.153, -86.894],
                                  [18.196, -92],
                                ],
                                v: [
                                  [-49.659, -325.771],
                                  [-180.231, -220.764],
                                  [-254.056, -26.299],
                                  [-245.84, 152.047],
                                  [-106.46, 276.884],
                                  [128.171, 359.867],
                                  [292.385, 240.543],
                                  [165.12, -6.633],
                                  [188.067, -161.957],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.417, -7.167],
                                  [18.08, -74.775],
                                  [21.892, -73.548],
                                  [-70.05, -41.145],
                                  [-15.198, -40.617],
                                  [-163.558, 25.537],
                                  [52.232, 61.443],
                                  [-11.331, 78.103],
                                  [-18.087, 92],
                                ],
                                o: [
                                  [-83.188, 15.013],
                                  [-18.08, 74.775],
                                  [-21.892, 73.548],
                                  [70.05, 41.145],
                                  [22.483, 49.583],
                                  [106.396, -13.208],
                                  [-52.232, -61.443],
                                  [0.684, -87.63],
                                  [18.087, -92],
                                ],
                                v: [
                                  [-50.169, -326.496],
                                  [-180.397, -219.888],
                                  [-253.715, -25.664],
                                  [-245.947, 152.248],
                                  [-104.59, 277.577],
                                  [127.447, 359.463],
                                  [291.74, 240.721],
                                  [163.661, -8.152],
                                  [189.423, -162.072],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.178, -7.645],
                                  [17.503, -75.428],
                                  [22.298, -73.147],
                                  [-70.774, -41.069],
                                  [-15.228, -40.951],
                                  [-163.008, 25.286],
                                  [53.211, 61.684],
                                  [-11.744, 78.933],
                                  [-17.98, 92],
                                ],
                                o: [
                                  [-83.367, 15.407],
                                  [-17.503, 75.428],
                                  [-22.298, 73.147],
                                  [70.774, 41.069],
                                  [22.436, 49.822],
                                  [106.456, -13.089],
                                  [-53.211, -61.684],
                                  [1.209, -88.358],
                                  [17.98, -92],
                                ],
                                v: [
                                  [-50.707, -327.22],
                                  [-180.607, -219.064],
                                  [-253.351, -24.997],
                                  [-246.057, 152.464],
                                  [-102.77, 278.228],
                                  [126.688, 359.081],
                                  [291.148, 240.894],
                                  [162.235, -9.608],
                                  [190.745, -162.165],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.941, -8.119],
                                  [16.932, -76.075],
                                  [22.701, -72.751],
                                  [-71.492, -40.994],
                                  [-15.257, -41.283],
                                  [-162.463, 25.038],
                                  [54.181, 61.922],
                                  [-12.152, 79.756],
                                  [-17.873, 92],
                                ],
                                o: [
                                  [-83.545, 15.798],
                                  [-16.932, 76.075],
                                  [-22.701, 72.751],
                                  [71.492, 40.994],
                                  [22.388, 50.059],
                                  [106.515, -12.97],
                                  [-54.181, -61.922],
                                  [1.731, -89.081],
                                  [17.873, -92],
                                ],
                                v: [
                                  [-51.267, -327.82],
                                  [-180.778, -218.221],
                                  [-253.006, -24.331],
                                  [-246.196, 152.699],
                                  [-100.929, 278.864],
                                  [125.921, 358.667],
                                  [290.547, 241.029],
                                  [160.797, -11.055],
                                  [192.102, -162.216],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.706, -8.589],
                                  [16.365, -76.716],
                                  [23.1, -72.357],
                                  [-72.205, -40.919],
                                  [-15.287, -41.612],
                                  [-161.923, 24.791],
                                  [55.142, 62.159],
                                  [-12.558, 80.573],
                                  [-17.768, 92],
                                ],
                                o: [
                                  [-83.721, 16.186],
                                  [-16.365, 76.716],
                                  [-23.1, 72.357],
                                  [72.205, 40.919],
                                  [22.341, 50.294],
                                  [106.574, -12.853],
                                  [-55.142, -62.159],
                                  [2.247, -89.798],
                                  [17.768, -92],
                                ],
                                v: [
                                  [-51.855, -328.421],
                                  [-180.992, -217.395],
                                  [-252.583, -23.624],
                                  [-246.303, 152.918],
                                  [-99.075, 279.417],
                                  [125.084, 358.243],
                                  [289.965, 241.224],
                                  [159.383, -12.473],
                                  [193.494, -162.219],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.473, -9.054],
                                  [15.803, -77.352],
                                  [23.496, -71.967],
                                  [-72.91, -40.844],
                                  [-15.316, -41.938],
                                  [-161.388, 24.547],
                                  [56.096, 62.393],
                                  [-12.959, 81.382],
                                  [-17.663, 92],
                                ],
                                o: [
                                  [-83.895, 16.57],
                                  [-15.803, 77.352],
                                  [-23.496, 71.967],
                                  [72.91, 40.844],
                                  [22.295, 50.527],
                                  [106.632, -12.736],
                                  [-56.096, -62.393],
                                  [2.76, -90.508],
                                  [17.663, -92],
                                ],
                                v: [
                                  [-52.436, -328.93],
                                  [-181.131, -216.585],
                                  [-252.174, -22.95],
                                  [-246.448, 153.12],
                                  [-97.23, 279.991],
                                  [124.273, 357.849],
                                  [289.437, 241.415],
                                  [158.021, -13.883],
                                  [194.852, -162.205],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.242, -9.516],
                                  [15.246, -77.983],
                                  [23.889, -71.58],
                                  [-73.61, -40.771],
                                  [-15.345, -42.261],
                                  [-160.857, 24.304],
                                  [57.041, 62.625],
                                  [-13.358, 82.184],
                                  [-17.559, 92],
                                ],
                                o: [
                                  [-84.069, 16.951],
                                  [-15.246, 77.983],
                                  [-23.889, 71.58],
                                  [73.61, 40.771],
                                  [22.248, 50.758],
                                  [106.689, -12.621],
                                  [-57.041, -62.625],
                                  [3.268, -91.212],
                                  [17.559, -92],
                                ],
                                v: [
                                  [-53.04, -329.444],
                                  [-181.347, -215.791],
                                  [-251.754, -22.206],
                                  [-246.561, 153.366],
                                  [-95.433, 280.547],
                                  [123.392, 357.48],
                                  [288.964, 241.599],
                                  [156.621, -15.261],
                                  [196.243, -162.178],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.013, -9.974],
                                  [14.694, -78.608],
                                  [24.278, -71.197],
                                  [-74.304, -40.698],
                                  [-15.373, -42.582],
                                  [-160.33, 24.064],
                                  [57.978, 62.856],
                                  [-13.752, 82.98],
                                  [-17.456, 92],
                                ],
                                o: [
                                  [-84.24, 17.328],
                                  [-14.694, 78.608],
                                  [-24.278, 71.197],
                                  [74.304, 40.698],
                                  [22.203, 50.987],
                                  [106.747, -12.506],
                                  [-57.978, -62.856],
                                  [3.771, -91.91],
                                  [17.456, -92],
                                ],
                                v: [
                                  [-53.67, -329.902],
                                  [-181.486, -215.013],
                                  [-251.316, -21.488],
                                  [-246.674, 153.621],
                                  [-93.618, 281.029],
                                  [122.503, 357.111],
                                  [288.449, 241.812],
                                  [155.244, -16.633],
                                  [197.604, -162.107],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.786, -10.428],
                                  [14.147, -79.228],
                                  [24.664, -70.817],
                                  [-74.992, -40.626],
                                  [-15.402, -42.899],
                                  [-159.808, 23.825],
                                  [58.907, 63.084],
                                  [-14.144, 83.768],
                                  [-17.354, 92],
                                ],
                                o: [
                                  [-84.41, 17.703],
                                  [-14.147, 79.228],
                                  [-24.664, 70.817],
                                  [74.992, 40.626],
                                  [22.157, 51.214],
                                  [106.803, -12.393],
                                  [-58.907, -63.084],
                                  [4.27, -92.602],
                                  [17.354, -92],
                                ],
                                v: [
                                  [-54.324, -330.339],
                                  [-181.669, -214.25],
                                  [-250.863, -20.731],
                                  [-246.824, 153.826],
                                  [-91.788, 281.499],
                                  [121.605, 356.735],
                                  [287.99, 242.056],
                                  [153.858, -17.969],
                                  [199.026, -162.025],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.561, -10.878],
                                  [13.604, -79.842],
                                  [25.046, -70.44],
                                  [-75.674, -40.554],
                                  [-15.43, -43.214],
                                  [-159.291, 23.589],
                                  [59.828, 63.31],
                                  [-14.532, 84.55],
                                  [-17.253, 92],
                                ],
                                o: [
                                  [-84.579, 18.074],
                                  [-13.604, 79.842],
                                  [-25.046, 70.44],
                                  [75.674, 40.554],
                                  [22.112, 51.439],
                                  [106.86, -12.281],
                                  [-59.828, -63.31],
                                  [4.765, -93.288],
                                  [17.253, -92],
                                ],
                                v: [
                                  [-54.972, -330.719],
                                  [-181.805, -213.473],
                                  [-250.397, -19.966],
                                  [-246.915, 154.101],
                                  [-90.002, 281.924],
                                  [120.67, 356.326],
                                  [287.587, 242.266],
                                  [152.496, -19.271],
                                  [200.417, -161.869],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.338, -11.324],
                                  [13.066, -80.451],
                                  [25.425, -70.066],
                                  [-76.35, -40.483],
                                  [-15.458, -43.527],
                                  [-158.778, 23.355],
                                  [60.74, 63.535],
                                  [-14.917, 85.325],
                                  [-17.152, 92],
                                ],
                                o: [
                                  [-84.746, 18.442],
                                  [-13.066, 80.451],
                                  [-25.425, 70.066],
                                  [76.35, 40.483],
                                  [22.068, 51.662],
                                  [106.915, -12.169],
                                  [-60.74, -63.535],
                                  [5.256, -93.968],
                                  [17.152, -92],
                                ],
                                v: [
                                  [-55.646, -331.111],
                                  [-181.922, -212.741],
                                  [-249.916, -19.193],
                                  [-247.041, 154.323],
                                  [-88.231, 282.339],
                                  [119.694, 356.005],
                                  [287.207, 242.507],
                                  [151.128, -20.57],
                                  [201.806, -161.765],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.117, -11.766],
                                  [12.533, -81.055],
                                  [25.801, -69.696],
                                  [-77.02, -40.412],
                                  [-15.485, -43.836],
                                  [-158.269, 23.123],
                                  [61.645, 63.757],
                                  [-15.298, 86.093],
                                  [-17.053, 92],
                                ],
                                o: [
                                  [-84.912, 18.807],
                                  [-12.533, 81.055],
                                  [-25.801, 69.696],
                                  [77.02, 40.412],
                                  [22.023, 51.883],
                                  [106.971, -12.059],
                                  [-61.645, -63.757],
                                  [5.742, -94.643],
                                  [17.053, -92],
                                ],
                                v: [
                                  [-56.344, -331.423],
                                  [-182.084, -211.993],
                                  [-249.454, -18.411],
                                  [-247.171, 154.551],
                                  [-86.475, 282.743],
                                  [118.711, 355.651],
                                  [286.852, 242.715],
                                  [149.781, -21.834],
                                  [203.192, -161.59],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.898, -12.204],
                                  [12.004, -81.654],
                                  [26.173, -69.329],
                                  [-77.684, -40.342],
                                  [-15.513, -44.143],
                                  [-157.765, 22.893],
                                  [62.542, 63.978],
                                  [-15.676, 86.854],
                                  [-16.954, 92],
                                ],
                                o: [
                                  [-85.076, 19.168],
                                  [-12.004, 81.654],
                                  [-26.173, 69.329],
                                  [77.684, 40.342],
                                  [21.98, 52.102],
                                  [107.026, -11.949],
                                  [-62.542, -63.978],
                                  [6.224, -95.311],
                                  [16.954, -92],
                                ],
                                v: [
                                  [-57.037, -331.778],
                                  [-182.164, -211.291],
                                  [-248.978, -17.588],
                                  [-247.336, 154.785],
                                  [-84.733, 283.105],
                                  [117.751, 355.293],
                                  [286.492, 242.956],
                                  [148.428, -23.094],
                                  [204.545, -161.407],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.681, -12.638],
                                  [11.48, -82.247],
                                  [26.543, -68.965],
                                  [-78.343, -40.273],
                                  [-15.54, -44.447],
                                  [-157.266, 22.665],
                                  [63.432, 64.196],
                                  [-16.051, 87.609],
                                  [-16.856, 92],
                                ],
                                o: [
                                  [-85.239, 19.527],
                                  [-11.48, 82.247],
                                  [-26.543, 68.965],
                                  [78.343, 40.273],
                                  [21.936, 52.319],
                                  [107.08, -11.84],
                                  [-63.432, -64.196],
                                  [6.702, -95.974],
                                  [16.856, -92],
                                ],
                                v: [
                                  [-57.786, -332.056],
                                  [-182.286, -210.572],
                                  [-248.458, -16.754],
                                  [-247.444, 155.024],
                                  [-83.006, 283.459],
                                  [116.751, 354.993],
                                  [286.22, 243.198],
                                  [147.097, -24.321],
                                  [205.958, -161.217],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.466, -13.069],
                                  [10.96, -82.835],
                                  [26.909, -68.605],
                                  [-78.995, -40.205],
                                  [-15.567, -44.748],
                                  [-156.771, 22.439],
                                  [64.313, 64.413],
                                  [-16.422, 88.357],
                                  [-16.76, 92],
                                ],
                                o: [
                                  [-85.401, 19.882],
                                  [-10.96, 82.835],
                                  [-26.909, 68.605],
                                  [78.995, 40.205],
                                  [21.893, 52.535],
                                  [107.134, -11.733],
                                  [-64.313, -64.413],
                                  [7.176, -96.63],
                                  [16.759, -92],
                                ],
                                v: [
                                  [-58.53, -332.349],
                                  [-182.389, -209.835],
                                  [-247.99, -15.911],
                                  [-247.587, 155.266],
                                  [-81.292, 283.804],
                                  [115.711, 354.659],
                                  [285.944, 243.44],
                                  [145.791, -25.514],
                                  [207.336, -161.021],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.252, -13.496],
                                  [10.446, -83.418],
                                  [27.271, -68.247],
                                  [-79.642, -40.137],
                                  [-15.594, -45.047],
                                  [-156.28, 22.215],
                                  [65.187, 64.628],
                                  [-16.79, 89.099],
                                  [-16.663, 92],
                                ],
                                o: [
                                  [-85.561, 20.234],
                                  [-10.446, 83.418],
                                  [-27.271, 68.247],
                                  [79.642, 40.137],
                                  [21.85, 52.748],
                                  [107.187, -11.626],
                                  [-65.187, -64.628],
                                  [7.645, -97.281],
                                  [16.663, -92],
                                ],
                                v: [
                                  [-59.27, -332.598],
                                  [-182.471, -209.143],
                                  [-247.477, -15.055],
                                  [-247.703, 155.479],
                                  [-79.624, 284.111],
                                  [114.662, 354.415],
                                  [285.729, 243.687],
                                  [144.477, -26.642],
                                  [208.71, -160.819],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.041, -13.919],
                                  [9.935, -83.996],
                                  [27.631, -67.893],
                                  [-80.283, -40.069],
                                  [-15.62, -45.343],
                                  [-155.793, 21.993],
                                  [66.053, 64.841],
                                  [-17.155, 89.834],
                                  [-16.568, 92],
                                ],
                                o: [
                                  [-85.72, 20.583],
                                  [-9.935, 83.996],
                                  [-27.631, 67.893],
                                  [80.283, 40.069],
                                  [21.808, 52.959],
                                  [107.24, -11.52],
                                  [-66.053, -64.84],
                                  [8.111, -97.926],
                                  [16.568, -92],
                                ],
                                v: [
                                  [-60.065, -332.866],
                                  [-182.501, -208.465],
                                  [-246.985, -14.189],
                                  [-247.825, 155.695],
                                  [-77.938, 284.38],
                                  [113.636, 354.137],
                                  [285.54, 243.966],
                                  [143.123, -27.8],
                                  [210.048, -160.581],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.831, -14.338],
                                  [9.429, -84.568],
                                  [27.988, -67.542],
                                  [-80.919, -40.002],
                                  [-15.646, -45.637],
                                  [-155.311, 21.772],
                                  [66.911, 65.051],
                                  [-17.517, 90.563],
                                  [-16.474, 92],
                                ],
                                o: [
                                  [-85.877, 20.929],
                                  [-9.429, 84.568],
                                  [-27.988, 67.542],
                                  [80.919, 40.002],
                                  [21.766, 53.169],
                                  [107.292, -11.415],
                                  [-66.911, -65.051],
                                  [8.572, -98.566],
                                  [16.474, -92],
                                ],
                                v: [
                                  [-60.859, -333.126],
                                  [-182.537, -207.797],
                                  [-246.515, -13.278],
                                  [-247.951, 155.914],
                                  [-76.296, 284.643],
                                  [112.568, 353.885],
                                  [285.352, 244.188],
                                  [141.826, -28.925],
                                  [211.415, -160.308],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.623, -14.754],
                                  [8.928, -85.136],
                                  [28.341, -67.194],
                                  [-81.549, -39.936],
                                  [-15.672, -45.928],
                                  [-154.833, 21.554],
                                  [67.762, 65.261],
                                  [-17.875, 91.285],
                                  [-16.38, 92],
                                ],
                                o: [
                                  [-86.033, 21.272],
                                  [-8.928, 85.136],
                                  [-28.341, 67.194],
                                  [81.549, 39.936],
                                  [21.725, 53.377],
                                  [107.344, -11.312],
                                  [-67.762, -65.261],
                                  [9.029, -99.2],
                                  [16.38, -92],
                                ],
                                v: [
                                  [-61.648, -333.374],
                                  [-182.553, -207.141],
                                  [-246.035, -12.357],
                                  [-248.115, 156.131],
                                  [-74.667, 284.903],
                                  [111.525, 353.659],
                                  [285.223, 244.475],
                                  [140.518, -29.989],
                                  [212.774, -160.093],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.417, -15.166],
                                  [8.431, -85.699],
                                  [28.691, -66.848],
                                  [-82.173, -39.87],
                                  [-15.698, -46.216],
                                  [-154.359, 21.338],
                                  [68.605, 65.468],
                                  [-18.231, 92.001],
                                  [-16.288, 92],
                                ],
                                o: [
                                  [-86.187, 21.612],
                                  [-8.431, 85.699],
                                  [-28.691, 66.848],
                                  [82.173, 39.87],
                                  [21.683, 53.583],
                                  [107.396, -11.208],
                                  [-68.605, -65.468],
                                  [9.483, -99.828],
                                  [16.288, -92],
                                ],
                                v: [
                                  [-62.495, -333.615],
                                  [-182.544, -206.464],
                                  [-245.578, -11.424],
                                  [-248.222, 156.344],
                                  [-73.052, 285.157],
                                  [110.469, 353.462],
                                  [285.16, 244.704],
                                  [139.204, -31.05],
                                  [214.099, -159.845],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.213, -15.574],
                                  [7.938, -86.256],
                                  [29.038, -66.507],
                                  [-82.792, -39.805],
                                  [-15.723, -46.502],
                                  [-153.89, 21.124],
                                  [69.441, 65.673],
                                  [-18.583, 92.71],
                                  [-16.196, 92],
                                ],
                                o: [
                                  [-86.34, 21.949],
                                  [-7.938, 86.256],
                                  [-29.038, 66.506],
                                  [82.792, 39.805],
                                  [21.643, 53.787],
                                  [107.447, -11.106],
                                  [-69.441, -65.673],
                                  [9.932, -100.451],
                                  [16.196, -92],
                                ],
                                v: [
                                  [-63.309, -333.881],
                                  [-182.51, -205.831],
                                  [-245.111, -10.481],
                                  [-248.362, 156.529],
                                  [-71.511, 285.377],
                                  [109.376, 353.29],
                                  [285.124, 244.969],
                                  [137.913, -32.051],
                                  [215.414, -159.599],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.01, -15.979],
                                  [7.45, -86.809],
                                  [29.382, -66.168],
                                  [-83.406, -39.741],
                                  [-15.749, -46.785],
                                  [-153.424, 20.911],
                                  [70.27, 65.877],
                                  [-18.932, 93.414],
                                  [-16.105, 92],
                                ],
                                o: [
                                  [-86.492, 22.283],
                                  [-7.45, 86.809],
                                  [-29.382, 66.168],
                                  [83.406, 39.741],
                                  [21.602, 53.99],
                                  [107.497, -11.005],
                                  [-70.27, -65.877],
                                  [10.377, -101.068],
                                  [16.105, -92],
                                ],
                                v: [
                                  [-64.182, -334.138],
                                  [-182.484, -205.176],
                                  [-244.67, -9.52],
                                  [-248.472, 156.708],
                                  [-69.949, 285.565],
                                  [108.302, 353.114],
                                  [285.094, 245.211],
                                  [136.617, -33.05],
                                  [216.758, -159.349],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.81, -16.38],
                                  [6.966, -87.357],
                                  [29.723, -65.832],
                                  [-84.014, -39.677],
                                  [-15.774, -47.066],
                                  [-152.963, 20.7],
                                  [71.091, 66.079],
                                  [-19.278, 94.111],
                                  [-16.014, 92],
                                ],
                                o: [
                                  [-86.643, 22.614],
                                  [-6.966, 87.357],
                                  [-29.723, 65.832],
                                  [84.014, 39.677],
                                  [21.562, 54.19],
                                  [107.548, -10.905],
                                  [-71.091, -66.079],
                                  [10.818, -101.68],
                                  [16.014, -92],
                                ],
                                v: [
                                  [-65.052, -334.4],
                                  [-182.431, -204.525],
                                  [-244.255, -8.548],
                                  [-248.593, 156.914],
                                  [-68.434, 285.783],
                                  [107.219, 352.996],
                                  [285.125, 245.463],
                                  [135.343, -34.024],
                                  [218.063, -159.132],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.611, -16.778],
                                  [6.487, -87.899],
                                  [30.061, -65.499],
                                  [-84.616, -39.614],
                                  [-15.799, -47.344],
                                  [-152.506, 20.492],
                                  [71.904, 66.279],
                                  [-19.621, 94.801],
                                  [-15.925, 92],
                                ],
                                o: [
                                  [-86.792, 22.942],
                                  [-6.487, 87.9],
                                  [-30.061, 65.499],
                                  [84.616, 39.614],
                                  [21.522, 54.389],
                                  [107.597, -10.806],
                                  [-71.904, -66.279],
                                  [11.255, -102.286],
                                  [15.925, -92],
                                ],
                                v: [
                                  [-65.953, -334.683],
                                  [-182.323, -203.918],
                                  [-243.867, -7.537],
                                  [-248.746, 157.057],
                                  [-66.924, 285.997],
                                  [106.126, 352.874],
                                  [285.227, 245.714],
                                  [134.058, -34.934],
                                  [219.39, -158.914],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.414, -17.172],
                                  [6.012, -88.438],
                                  [30.396, -65.169],
                                  [-85.213, -39.551],
                                  [-15.823, -47.62],
                                  [-152.053, 20.285],
                                  [72.711, 66.477],
                                  [-19.961, 95.486],
                                  [-15.836, 92],
                                ],
                                o: [
                                  [-86.939, 23.267],
                                  [-6.012, 88.438],
                                  [-30.396, 65.169],
                                  [85.213, 39.551],
                                  [21.483, 54.586],
                                  [107.646, -10.707],
                                  [-72.711, -66.477],
                                  [11.689, -102.887],
                                  [15.836, -92],
                                ],
                                v: [
                                  [-66.85, -334.967],
                                  [-182.213, -203.313],
                                  [-243.472, -6.542],
                                  [-248.84, 157.19],
                                  [-65.461, 286.154],
                                  [105.026, 352.811],
                                  [285.329, 245.949],
                                  [132.767, -35.85],
                                  [220.718, -158.728],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.219, -17.562],
                                  [5.541, -88.971],
                                  [30.728, -64.842],
                                  [-85.805, -39.489],
                                  [-15.848, -47.893],
                                  [-151.604, 20.08],
                                  [73.51, 66.673],
                                  [-20.297, 96.164],
                                  [-15.749, 92],
                                ],
                                o: [
                                  [-87.086, 23.589],
                                  [-5.541, 88.971],
                                  [-30.728, 64.842],
                                  [85.805, 39.489],
                                  [21.444, 54.781],
                                  [107.695, -10.609],
                                  [-73.51, -66.673],
                                  [12.118, -103.482],
                                  [15.749, -92],
                                ],
                                v: [
                                  [-67.781, -335.28],
                                  [-182.082, -202.682],
                                  [-243.105, -5.535],
                                  [-249.002, 157.348],
                                  [-64.036, 286.345],
                                  [103.943, 352.734],
                                  [285.469, 246.227],
                                  [131.499, -36.737],
                                  [222.069, -158.55],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.025, -17.949],
                                  [5.074, -89.499],
                                  [31.057, -64.518],
                                  [-86.391, -39.427],
                                  [-15.872, -48.164],
                                  [-151.159, 19.877],
                                  [74.302, 66.868],
                                  [-20.631, 96.836],
                                  [-15.661, 92],
                                ],
                                o: [
                                  [-87.231, 23.908],
                                  [-5.074, 89.499],
                                  [-31.057, 64.518],
                                  [86.391, 39.427],
                                  [21.405, 54.974],
                                  [107.744, -10.513],
                                  [-74.302, -66.868],
                                  [12.544, -104.072],
                                  [15.661, -92],
                                ],
                                v: [
                                  [-68.744, -335.598],
                                  [-181.921, -202.058],
                                  [-242.795, -4.518],
                                  [-249.131, 157.476],
                                  [-62.622, 286.533],
                                  [102.851, 352.751],
                                  [285.646, 246.547],
                                  [130.227, -37.533],
                                  [223.388, -158.407],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.834, -18.332],
                                  [4.612, -90.023],
                                  [31.383, -64.197],
                                  [-86.973, -39.366],
                                  [-15.896, -48.433],
                                  [-150.718, 19.675],
                                  [75.087, 67.061],
                                  [-20.962, 97.503],
                                  [-15.575, 92],
                                ],
                                o: [
                                  [-87.375, 24.224],
                                  [-4.612, 90.023],
                                  [-31.383, 64.197],
                                  [86.973, 39.366],
                                  [21.367, 55.166],
                                  [107.792, -10.417],
                                  [-75.087, -67.061],
                                  [12.966, -104.657],
                                  [15.575, -92],
                                ],
                                v: [
                                  [-69.733, -335.912],
                                  [-181.703, -201.442],
                                  [-242.488, -3.454],
                                  [-249.238, 157.591],
                                  [-61.256, 286.694],
                                  [101.786, 352.753],
                                  [285.825, 246.847],
                                  [128.941, -38.337],
                                  [224.728, -158.265],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.644, -18.712],
                                  [4.153, -90.542],
                                  [31.706, -63.878],
                                  [-87.548, -39.305],
                                  [-15.92, -48.699],
                                  [-150.281, 19.476],
                                  [75.864, 67.252],
                                  [-21.289, 98.163],
                                  [-15.49, 92],
                                ],
                                o: [
                                  [-87.517, 24.538],
                                  [-4.153, 90.542],
                                  [-31.706, 63.878],
                                  [87.548, 39.305],
                                  [21.329, 55.356],
                                  [107.839, -10.322],
                                  [-75.865, -67.252],
                                  [13.384, -105.236],
                                  [15.49, -92],
                                ],
                                v: [
                                  [-70.729, -336.269],
                                  [-181.517, -200.85],
                                  [-242.213, -2.415],
                                  [-249.375, 157.693],
                                  [-59.963, 286.791],
                                  [100.703, 352.786],
                                  [286.018, 247.203],
                                  [127.677, -39.15],
                                  [226.099, -158.16],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.456, -19.089],
                                  [3.699, -91.056],
                                  [32.026, -63.563],
                                  [-88.119, -39.245],
                                  [-15.943, -48.962],
                                  [-149.848, 19.278],
                                  [76.635, 67.442],
                                  [-21.614, 98.817],
                                  [-15.405, 92],
                                ],
                                o: [
                                  [-87.658, 24.848],
                                  [-3.699, 91.056],
                                  [-32.026, 63.563],
                                  [88.119, 39.245],
                                  [21.291, 55.544],
                                  [107.886, -10.228],
                                  [-76.635, -67.442],
                                  [13.798, -105.811],
                                  [15.405, -92],
                                ],
                                v: [
                                  [-71.749, -336.687],
                                  [-181.272, -200.238],
                                  [-241.97, -1.365],
                                  [-249.478, 157.764],
                                  [-58.719, 286.888],
                                  [99.646, 352.84],
                                  [286.239, 247.567],
                                  [126.409, -39.875],
                                  [227.454, -158.066],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.269, -19.462],
                                  [3.249, -91.565],
                                  [32.343, -63.251],
                                  [-88.685, -39.186],
                                  [-15.966, -49.223],
                                  [-149.419, 19.082],
                                  [77.399, 67.629],
                                  [-21.936, 99.465],
                                  [-15.321, 92],
                                ],
                                o: [
                                  [-87.798, 25.156],
                                  [-3.249, 91.565],
                                  [-32.343, 63.251],
                                  [88.685, 39.186],
                                  [21.254, 55.731],
                                  [107.933, -10.135],
                                  [-77.399, -67.629],
                                  [14.208, -106.38],
                                  [15.321, -92],
                                ],
                                v: [
                                  [-72.78, -337.115],
                                  [-181.021, -199.658],
                                  [-241.724, -0.33],
                                  [-249.596, 157.856],
                                  [-57.549, 286.921],
                                  [98.607, 352.951],
                                  [286.538, 247.978],
                                  [125.162, -40.574],
                                  [228.815, -158.038],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.084, -19.832],
                                  [2.803, -92.07],
                                  [32.657, -62.941],
                                  [-89.245, -39.127],
                                  [-15.99, -49.482],
                                  [-148.993, 18.888],
                                  [78.156, 67.815],
                                  [-22.255, 100.108],
                                  [-15.238, 92],
                                ],
                                o: [
                                  [-87.937, 25.461],
                                  [-2.803, 92.07],
                                  [-32.657, 62.941],
                                  [89.245, 39.127],
                                  [21.217, 55.916],
                                  [107.979, -10.042],
                                  [-78.156, -67.815],
                                  [14.615, -106.943],
                                  [15.238, -92],
                                ],
                                v: [
                                  [-73.834, -337.516],
                                  [-180.71, -199.072],
                                  [-241.499, 0.74],
                                  [-249.705, 157.932],
                                  [-56.403, 286.916],
                                  [97.558, 353.081],
                                  [286.778, 248.385],
                                  [123.938, -41.284],
                                  [230.171, -157.986],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.901, -20.198],
                                  [2.361, -92.571],
                                  [32.968, -62.634],
                                  [-89.8, -39.068],
                                  [-16.012, -49.739],
                                  [-148.572, 18.696],
                                  [78.906, 68],
                                  [-22.571, 100.744],
                                  [-15.155, 92],
                                ],
                                o: [
                                  [-88.074, 25.763],
                                  [-2.361, 92.571],
                                  [-32.968, 62.634],
                                  [89.8, 39.068],
                                  [21.18, 56.099],
                                  [108.025, -9.951],
                                  [-78.906, -68],
                                  [15.018, -107.502],
                                  [15.155, -92],
                                ],
                                v: [
                                  [-74.925, -337.979],
                                  [-180.403, -198.49],
                                  [-241.283, 1.783],
                                  [-249.842, 157.977],
                                  [-55.37, 286.884],
                                  [96.525, 353.267],
                                  [287.061, 248.804],
                                  [122.673, -41.907],
                                  [231.509, -157.962],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.719, -20.561],
                                  [1.923, -93.066],
                                  [33.277, -62.33],
                                  [-90.35, -39.011],
                                  [-16.035, -49.993],
                                  [-148.155, 18.505],
                                  [79.649, 68.182],
                                  [-22.884, 101.375],
                                  [-15.074, 92],
                                ],
                                o: [
                                  [-88.21, 26.063],
                                  [-1.923, 93.066],
                                  [-33.277, 62.33],
                                  [90.35, 39.011],
                                  [21.144, 56.281],
                                  [108.07, -9.86],
                                  [-79.649, -68.182],
                                  [15.417, -108.056],
                                  [15.074, -92],
                                ],
                                v: [
                                  [-75.991, -338.418],
                                  [-180.061, -197.938],
                                  [-241.127, 2.835],
                                  [-249.956, 158.003],
                                  [-54.35, 286.813],
                                  [95.521, 353.472],
                                  [287.385, 249.234],
                                  [121.429, -42.543],
                                  [232.879, -157.99],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.54, -20.921],
                                  [1.489, -93.558],
                                  [33.583, -62.029],
                                  [-90.896, -38.953],
                                  [-16.058, -50.245],
                                  [-147.741, 18.317],
                                  [80.385, 68.363],
                                  [-23.194, 102],
                                  [-14.993, 92],
                                ],
                                o: [
                                  [-88.345, 26.36],
                                  [-1.489, 93.558],
                                  [-33.583, 62.029],
                                  [90.896, 38.953],
                                  [21.108, 56.46],
                                  [108.115, -9.77],
                                  [-80.385, -68.363],
                                  [15.813, -108.604],
                                  [14.993, -92],
                                ],
                                v: [
                                  [-77.08, -338.857],
                                  [-179.697, -197.351],
                                  [-240.929, 3.858],
                                  [-250.073, 158.048],
                                  [-53.382, 286.779],
                                  [94.508, 353.695],
                                  [287.677, 249.727],
                                  [120.206, -43.132],
                                  [234.244, -157.985],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.361, -21.277],
                                  [1.059, -94.044],
                                  [33.886, -61.73],
                                  [-91.436, -38.896],
                                  [-16.08, -50.494],
                                  [-147.331, 18.129],
                                  [81.114, 68.543],
                                  [-23.502, 102.619],
                                  [-14.913, 92],
                                ],
                                o: [
                                  [-88.479, 26.654],
                                  [-1.059, 94.044],
                                  [-33.886, 61.73],
                                  [91.436, 38.896],
                                  [21.072, 56.639],
                                  [108.16, -9.681],
                                  [-81.114, -68.543],
                                  [16.205, -109.148],
                                  [14.913, -92],
                                ],
                                v: [
                                  [-78.169, -339.271],
                                  [-179.322, -196.791],
                                  [-240.805, 4.851],
                                  [-250.217, 158.062],
                                  [-52.49, 286.643],
                                  [93.51, 353.973],
                                  [288.026, 250.17],
                                  [118.965, -43.697],
                                  [235.642, -158.083],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.185, -21.63],
                                  [0.633, -94.526],
                                  [34.186, -61.435],
                                  [-91.971, -38.84],
                                  [-16.102, -50.741],
                                  [-146.925, 17.944],
                                  [81.837, 68.72],
                                  [-23.806, 103.233],
                                  [-14.833, 92],
                                ],
                                o: [
                                  [-88.611, 26.945],
                                  [-0.633, 94.526],
                                  [-34.186, 61.435],
                                  [91.971, 38.84],
                                  [21.037, 56.815],
                                  [108.204, -9.592],
                                  [-81.837, -68.72],
                                  [16.593, -109.686],
                                  [14.833, -92],
                                ],
                                v: [
                                  [-79.233, -339.648],
                                  [-178.886, -196.22],
                                  [-240.664, 5.875],
                                  [-250.323, 158.055],
                                  [-51.665, 286.545],
                                  [92.527, 354.268],
                                  [288.342, 250.664],
                                  [117.783, -44.216],
                                  [236.997, -158.161],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.01, -21.98],
                                  [0.211, -95.004],
                                  [34.483, -61.142],
                                  [-92.501, -38.784],
                                  [-16.124, -50.986],
                                  [-146.523, 17.76],
                                  [82.553, 68.896],
                                  [-24.108, 103.841],
                                  [-14.754, 92],
                                ],
                                o: [
                                  [-88.743, 27.234],
                                  [-0.211, 95.004],
                                  [-34.483, 61.142],
                                  [92.501, 38.784],
                                  [21.002, 56.99],
                                  [108.248, -9.505],
                                  [-82.553, -68.896],
                                  [16.978, -110.22],
                                  [14.755, -92],
                                ],
                                v: [
                                  [-80.335, -340.064],
                                  [-178.453, -195.689],
                                  [-240.558, 6.883],
                                  [-250.432, 158.041],
                                  [-50.879, 286.406],
                                  [91.573, 354.618],
                                  [288.703, 251.147],
                                  [116.607, -44.713],
                                  [238.346, -158.242],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.837, -22.327],
                                  [-0.207, -95.478],
                                  [34.778, -60.851],
                                  [-93.027, -38.729],
                                  [-16.145, -51.229],
                                  [-146.124, 17.578],
                                  [83.263, 69.071],
                                  [-24.407, 104.443],
                                  [-14.677, 92],
                                ],
                                o: [
                                  [-88.873, 27.52],
                                  [0.207, 95.478],
                                  [-34.778, 60.851],
                                  [93.027, 38.729],
                                  [20.967, 57.163],
                                  [108.291, -9.418],
                                  [-83.263, -69.071],
                                  [17.359, -110.748],
                                  [14.676, -92],
                                ],
                                v: [
                                  [-81.422, -340.441],
                                  [-177.982, -195.144],
                                  [-240.466, 7.819],
                                  [-250.541, 158.02],
                                  [-50.146, 286.203],
                                  [90.673, 354.961],
                                  [289.07, 251.683],
                                  [115.41, -45.228],
                                  [239.69, -158.39],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.665, -22.67],
                                  [-0.621, -95.947],
                                  [35.07, -60.564],
                                  [-93.547, -38.674],
                                  [-16.167, -51.469],
                                  [-145.729, 17.398],
                                  [83.966, 69.243],
                                  [-24.703, 105.04],
                                  [-14.599, 92],
                                ],
                                o: [
                                  [-89.001, 27.803],
                                  [0.621, 95.947],
                                  [-35.07, 60.564],
                                  [93.547, 38.674],
                                  [20.933, 57.335],
                                  [108.334, -9.332],
                                  [-83.966, -69.243],
                                  [17.737, -111.272],
                                  [14.599, -92],
                                ],
                                v: [
                                  [-82.484, -340.858],
                                  [-177.512, -194.584],
                                  [-240.418, 8.785],
                                  [-250.612, 158.016],
                                  [-49.491, 286.038],
                                  [89.724, 355.319],
                                  [289.46, 252.209],
                                  [114.271, -45.699],
                                  [241.068, -158.558],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.495, -23.01],
                                  [-1.031, -96.411],
                                  [35.359, -60.279],
                                  [-94.063, -38.62],
                                  [-16.188, -51.707],
                                  [-145.338, 17.219],
                                  [84.662, 69.415],
                                  [-24.997, 105.631],
                                  [-14.523, 92],
                                ],
                                o: [
                                  [-89.129, 28.084],
                                  [1.031, 96.411],
                                  [-35.359, 60.279],
                                  [94.063, 38.62],
                                  [20.899, 57.505],
                                  [108.376, -9.247],
                                  [-84.662, -69.415],
                                  [18.111, -111.791],
                                  [14.523, -92],
                                ],
                                v: [
                                  [-83.546, -341.212],
                                  [-177.004, -194.047],
                                  [-240.345, 9.733],
                                  [-250.701, 157.964],
                                  [-48.827, 285.833],
                                  [88.869, 355.733],
                                  [289.817, 252.749],
                                  [113.111, -46.11],
                                  [242.401, -158.754],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.326, -23.348],
                                  [-1.438, -96.871],
                                  [35.645, -59.996],
                                  [-94.574, -38.566],
                                  [-16.209, -51.943],
                                  [-144.95, 17.043],
                                  [85.352, 69.584],
                                  [-25.287, 106.216],
                                  [-14.447, 92],
                                ],
                                o: [
                                  [-89.255, 28.362],
                                  [1.438, 96.871],
                                  [-35.645, 59.996],
                                  [94.574, 38.566],
                                  [20.865, 57.674],
                                  [108.418, -9.163],
                                  [-85.352, -69.584],
                                  [18.482, -112.305],
                                  [14.447, -92],
                                ],
                                v: [
                                  [-84.585, -341.567],
                                  [-176.496, -193.509],
                                  [-240.31, 10.67],
                                  [-250.73, 157.882],
                                  [-48.281, 285.603],
                                  [87.966, 356.138],
                                  [290.221, 253.281],
                                  [111.97, -46.518],
                                  [243.792, -158.954],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.159, -23.681],
                                  [-1.841, -97.327],
                                  [35.929, -59.717],
                                  [-95.08, -38.513],
                                  [-16.23, -52.177],
                                  [-144.566, 16.867],
                                  [86.036, 69.752],
                                  [-25.575, 106.797],
                                  [-14.372, 92],
                                ],
                                o: [
                                  [-89.381, 28.637],
                                  [1.841, 97.328],
                                  [-35.929, 59.717],
                                  [95.08, 38.513],
                                  [20.832, 57.841],
                                  [108.46, -9.08],
                                  [-86.035, -69.752],
                                  [18.85, -112.814],
                                  [14.372, -92],
                                ],
                                v: [
                                  [-85.646, -341.923],
                                  [-175.967, -192.993],
                                  [-240.295, 11.571],
                                  [-250.76, 157.815],
                                  [-47.75, 285.373],
                                  [87.093, 356.577],
                                  [290.609, 253.804],
                                  [110.868, -46.948],
                                  [245.115, -159.198],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.994, -24.012],
                                  [-2.24, -97.779],
                                  [36.21, -59.44],
                                  [-95.581, -38.461],
                                  [-16.251, -52.409],
                                  [-144.186, 16.694],
                                  [86.713, 69.919],
                                  [-25.861, 107.371],
                                  [-14.297, 92],
                                ],
                                o: [
                                  [-89.505, 28.91],
                                  [2.24, 97.779],
                                  [-36.21, 59.44],
                                  [95.581, 38.461],
                                  [20.799, 58.006],
                                  [108.502, -8.997],
                                  [-86.713, -69.919],
                                  [19.213, -113.319],
                                  [14.297, -92],
                                ],
                                v: [
                                  [-86.644, -342.257],
                                  [-175.359, -192.455],
                                  [-240.319, 12.477],
                                  [-250.749, 157.718],
                                  [-47.297, 285.141],
                                  [86.276, 357.03],
                                  [291.004, 254.341],
                                  [109.784, -47.319],
                                  [246.474, -159.487],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.83, -24.34],
                                  [-2.635, -98.227],
                                  [36.489, -59.165],
                                  [-96.078, -38.408],
                                  [-16.271, -52.638],
                                  [-143.809, 16.521],
                                  [87.383, 70.083],
                                  [-26.143, 107.941],
                                  [-14.224, 92],
                                ],
                                o: [
                                  [-89.627, 29.18],
                                  [2.635, 98.227],
                                  [-36.489, 59.165],
                                  [96.078, 38.408],
                                  [20.766, 58.17],
                                  [108.542, -8.915],
                                  [-87.383, -70.084],
                                  [19.574, -113.818],
                                  [14.224, -92],
                                ],
                                v: [
                                  [-87.704, -342.613],
                                  [-174.812, -191.896],
                                  [-240.342, 13.304],
                                  [-250.678, 157.615],
                                  [-46.878, 284.868],
                                  [85.45, 357.498],
                                  [291.407, 254.933],
                                  [108.676, -47.649],
                                  [247.849, -159.781],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.668, -24.665],
                                  [-3.027, -98.67],
                                  [36.765, -58.893],
                                  [-96.57, -38.357],
                                  [-16.292, -52.865],
                                  [-143.436, 16.351],
                                  [88.048, 70.247],
                                  [-26.423, 108.505],
                                  [-14.15, 92],
                                ],
                                o: [
                                  [-89.749, 29.448],
                                  [3.027, 98.67],
                                  [-36.765, 58.893],
                                  [96.57, 38.357],
                                  [20.734, 58.332],
                                  [108.583, -8.834],
                                  [-88.048, -70.247],
                                  [19.931, -114.313],
                                  [14.151, -92],
                                ],
                                v: [
                                  [-88.7, -342.948],
                                  [-174.204, -191.358],
                                  [-240.385, 14.176],
                                  [-250.61, 157.486],
                                  [-46.538, 284.634],
                                  [84.678, 358],
                                  [291.837, 255.477],
                                  [107.625, -47.981],
                                  [249.156, -160.102],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.507, -24.986],
                                  [-3.415, -99.109],
                                  [37.038, -58.624],
                                  [-97.057, -38.305],
                                  [-16.312, -53.09],
                                  [-143.066, 16.182],
                                  [88.706, 70.409],
                                  [-26.701, 109.063],
                                  [-14.078, 92],
                                ],
                                o: [
                                  [-89.87, 29.714],
                                  [3.415, 99.109],
                                  [-37.038, 58.624],
                                  [97.057, 38.305],
                                  [20.701, 58.493],
                                  [108.623, -8.753],
                                  [-88.706, -70.409],
                                  [20.285, -114.804],
                                  [14.078, -92],
                                ],
                                v: [
                                  [-89.695, -343.305],
                                  [-173.595, -190.797],
                                  [-240.446, 14.969],
                                  [-250.523, 157.368],
                                  [-46.213, 284.358],
                                  [83.899, 358.494],
                                  [292.233, 256.036],
                                  [106.571, -48.297],
                                  [250.5, -160.467],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.348, -25.305],
                                  [-3.799, -99.544],
                                  [37.309, -58.357],
                                  [-97.54, -38.255],
                                  [-16.332, -53.313],
                                  [-142.7, 16.015],
                                  [89.358, 70.569],
                                  [-26.975, 109.617],
                                  [-14.006, 92],
                                ],
                                o: [
                                  [-89.989, 29.976],
                                  [3.799, 99.544],
                                  [-37.309, 58.357],
                                  [97.54, 38.255],
                                  [20.67, 58.652],
                                  [108.663, -8.674],
                                  [-89.358, -70.569],
                                  [20.635, -115.29],
                                  [14.006, -92],
                                ],
                                v: [
                                  [-90.628, -343.642],
                                  [-172.946, -190.235],
                                  [-240.527, 15.763],
                                  [-250.439, 157.203],
                                  [-45.966, 284.101],
                                  [83.153, 359.022],
                                  [292.658, 256.587],
                                  [105.491, -48.595],
                                  [251.818, -160.838],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.19, -25.62],
                                  [-4.179, -99.975],
                                  [37.577, -58.093],
                                  [-98.018, -38.204],
                                  [-16.351, -53.534],
                                  [-142.337, 15.849],
                                  [90.004, 70.728],
                                  [-27.247, 110.165],
                                  [-13.935, 92],
                                ],
                                o: [
                                  [-90.108, 30.237],
                                  [4.179, 99.975],
                                  [-37.577, 58.093],
                                  [98.018, 38.204],
                                  [20.638, 58.81],
                                  [108.703, -8.595],
                                  [-90.004, -70.728],
                                  [20.982, -115.771],
                                  [13.935, -92],
                                ],
                                v: [
                                  [-91.622, -343.937],
                                  [-172.316, -189.65],
                                  [-240.648, 16.539],
                                  [-250.338, 157.072],
                                  [-45.714, 283.802],
                                  [82.463, 359.565],
                                  [293.069, 257.153],
                                  [104.467, -48.857],
                                  [253.152, -161.254],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.034, -25.933],
                                  [-4.556, -100.402],
                                  [37.843, -57.831],
                                  [-98.492, -38.154],
                                  [-16.371, -53.753],
                                  [-141.977, 15.685],
                                  [90.644, 70.885],
                                  [-27.517, 110.708],
                                  [-13.865, 92],
                                ],
                                o: [
                                  [-90.225, 30.495],
                                  [4.556, 100.402],
                                  [-37.843, 57.831],
                                  [98.492, 38.154],
                                  [20.607, 58.966],
                                  [108.742, -8.517],
                                  [-90.644, -70.885],
                                  [21.326, -116.247],
                                  [13.865, -92],
                                ],
                                v: [
                                  [-92.552, -344.295],
                                  [-171.665, -189.105],
                                  [-240.769, 17.253],
                                  [-250.178, 156.873],
                                  [-45.582, 283.522],
                                  [81.766, 360.101],
                                  [293.49, 257.713],
                                  [103.417, -49.083],
                                  [254.482, -161.696],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.879, -26.242],
                                  [-4.93, -100.825],
                                  [38.106, -57.572],
                                  [-98.961, -38.105],
                                  [-16.39, -53.97],
                                  [-141.621, 15.523],
                                  [91.277, 71.041],
                                  [-27.784, 111.246],
                                  [-13.795, 92],
                                ],
                                o: [
                                  [-90.341, 30.75],
                                  [4.93, 100.825],
                                  [-38.106, 57.572],
                                  [98.961, 38.105],
                                  [20.576, 59.121],
                                  [108.78, -8.439],
                                  [-91.277, -71.041],
                                  [21.667, -116.72],
                                  [13.795, -92],
                                ],
                                v: [
                                  [-93.44, -344.591],
                                  [-171.014, -188.516],
                                  [-240.931, 17.989],
                                  [-250.064, 156.73],
                                  [-45.424, 283.22],
                                  [81.125, 360.673],
                                  [293.877, 258.287],
                                  [102.444, -49.335],
                                  [255.764, -162.185],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.726, -26.549],
                                  [-5.3, -101.243],
                                  [38.367, -57.315],
                                  [-99.426, -38.056],
                                  [-16.409, -54.184],
                                  [-141.269, 15.362],
                                  [91.905, 71.195],
                                  [-28.048, 111.779],
                                  [-13.727, 92],
                                ],
                                o: [
                                  [-90.456, 31.003],
                                  [5.3, 101.243],
                                  [-38.367, 57.315],
                                  [99.426, 38.056],
                                  [20.545, 59.274],
                                  [108.819, -8.363],
                                  [-91.905, -71.195],
                                  [22.004, -117.187],
                                  [13.727, -92],
                                ],
                                v: [
                                  [-94.368, -344.886],
                                  [-170.34, -187.926],
                                  [-241.07, 18.682],
                                  [-249.849, 156.518],
                                  [-45.367, 282.98],
                                  [80.519, 361.258],
                                  [294.272, 258.832],
                                  [101.423, -49.574],
                                  [257.083, -162.698],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.574, -26.853],
                                  [-5.666, -101.658],
                                  [38.625, -57.061],
                                  [-99.886, -38.008],
                                  [-16.428, -54.397],
                                  [-140.919, 15.202],
                                  [92.526, 71.348],
                                  [-28.31, 112.306],
                                  [-13.658, 92],
                                ],
                                o: [
                                  [-90.57, 31.253],
                                  [5.666, 101.658],
                                  [-38.625, 57.061],
                                  [99.886, 38.008],
                                  [20.515, 59.426],
                                  [108.857, -8.287],
                                  [-92.526, -71.348],
                                  [22.338, -117.65],
                                  [13.658, -92],
                                ],
                                v: [
                                  [-95.21, -345.202],
                                  [-169.664, -187.333],
                                  [-241.294, 19.333],
                                  [-249.68, 156.342],
                                  [-45.302, 282.677],
                                  [79.907, 361.817],
                                  [294.698, 259.372],
                                  [100.413, -49.778],
                                  [258.398, -163.238],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.423, -27.153],
                                  [-6.029, -102.069],
                                  [38.88, -56.809],
                                  [-100.342, -37.96],
                                  [-16.447, -54.607],
                                  [-140.574, 15.044],
                                  [93.142, 71.499],
                                  [-28.57, 112.829],
                                  [-13.59, 92],
                                ],
                                o: [
                                  [-90.683, 31.502],
                                  [6.029, 102.069],
                                  [-38.88, 56.809],
                                  [100.342, 37.96],
                                  [20.485, 59.577],
                                  [108.894, -8.212],
                                  [-93.142, -71.499],
                                  [22.669, -118.109],
                                  [13.59, -92],
                                ],
                                v: [
                                  [-96.074, -345.497],
                                  [-168.987, -186.738],
                                  [-241.474, 19.983],
                                  [-249.516, 156.161],
                                  [-45.319, 282.391],
                                  [79.351, 362.432],
                                  [295.09, 259.925],
                                  [99.439, -49.95],
                                  [259.666, -163.823],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.274, -27.451],
                                  [-6.388, -102.476],
                                  [39.134, -56.56],
                                  [-100.793, -37.912],
                                  [-16.466, -54.816],
                                  [-140.231, 14.888],
                                  [93.752, 71.649],
                                  [-28.827, 113.347],
                                  [-13.523, 92],
                                ],
                                o: [
                                  [-90.794, 31.747],
                                  [6.388, 102.476],
                                  [-39.134, 56.56],
                                  [100.793, 37.912],
                                  [20.455, 59.726],
                                  [108.931, -8.137],
                                  [-93.752, -71.649],
                                  [22.996, -118.563],
                                  [13.523, -92],
                                ],
                                v: [
                                  [-96.893, -345.792],
                                  [-168.309, -186.161],
                                  [-241.697, 20.59],
                                  [-249.292, 155.956],
                                  [-45.328, 282.085],
                                  [78.833, 363.041],
                                  [295.471, 260.491],
                                  [98.479, -50.108],
                                  [260.971, -164.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.127, -27.746],
                                  [-6.744, -102.879],
                                  [39.384, -56.312],
                                  [-101.241, -37.865],
                                  [-16.484, -55.022],
                                  [-139.892, 14.733],
                                  [94.356, 71.797],
                                  [-29.081, 113.859],
                                  [-13.457, 92],
                                ],
                                o: [
                                  [-90.905, 31.991],
                                  [6.744, 102.879],
                                  [-39.384, 56.312],
                                  [101.241, 37.865],
                                  [20.425, 59.873],
                                  [108.968, -8.063],
                                  [-94.356, -71.797],
                                  [23.321, -119.013],
                                  [13.457, -92],
                                ],
                                v: [
                                  [-97.711, -346.106],
                                  [-167.629, -185.581],
                                  [-241.963, 21.215],
                                  [-249.072, 155.764],
                                  [-45.439, 281.841],
                                  [78.309, 363.688],
                                  [295.904, 261.009],
                                  [97.508, -50.297],
                                  [262.253, -165.071],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.981, -28.039],
                                  [-7.096, -103.278],
                                  [39.633, -56.068],
                                  [-101.683, -37.819],
                                  [-16.502, -55.227],
                                  [-139.556, 14.58],
                                  [94.954, 71.944],
                                  [-29.333, 114.367],
                                  [-13.391, 92],
                                ],
                                o: [
                                  [-91.014, 32.232],
                                  [7.096, 103.278],
                                  [-39.633, 56.068],
                                  [101.683, 37.819],
                                  [20.396, 60.019],
                                  [109.005, -7.99],
                                  [-94.954, -71.944],
                                  [23.642, -119.459],
                                  [13.391, -92],
                                ],
                                v: [
                                  [-98.483, -346.42],
                                  [-166.967, -184.98],
                                  [-242.204, 21.775],
                                  [-248.856, 155.548],
                                  [-45.541, 281.552],
                                  [77.886, 364.33],
                                  [296.303, 261.54],
                                  [96.55, -50.455],
                                  [263.506, -165.73],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.836, -28.328],
                                  [-7.446, -103.673],
                                  [39.879, -55.825],
                                  [-102.122, -37.773],
                                  [-16.521, -55.43],
                                  [-139.223, 14.428],
                                  [95.546, 72.09],
                                  [-29.583, 114.87],
                                  [-13.326, 92],
                                ],
                                o: [
                                  [-91.123, 32.471],
                                  [7.446, 103.673],
                                  [-39.879, 55.825],
                                  [102.122, 37.773],
                                  [20.367, 60.164],
                                  [109.041, -7.918],
                                  [-95.546, -72.09],
                                  [23.961, -119.9],
                                  [13.326, -92],
                                ],
                                v: [
                                  [-99.21, -346.689],
                                  [-166.304, -184.397],
                                  [-242.47, 22.309],
                                  [-248.625, 155.353],
                                  [-45.684, 281.261],
                                  [77.458, 364.947],
                                  [296.691, 262.04],
                                  [95.622, -50.582],
                                  [264.779, -166.481],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.693, -28.614],
                                  [-7.791, -104.064],
                                  [40.122, -55.585],
                                  [-102.556, -37.727],
                                  [-16.538, -55.63],
                                  [-138.893, 14.277],
                                  [96.133, 72.234],
                                  [-29.83, 115.368],
                                  [-13.262, 92],
                                ],
                                o: [
                                  [-91.23, 32.707],
                                  [7.791, 104.064],
                                  [-40.122, 55.585],
                                  [102.556, 37.727],
                                  [20.339, 60.307],
                                  [109.077, -7.846],
                                  [-96.133, -72.234],
                                  [24.276, -120.337],
                                  [13.262, -92],
                                ],
                                v: [
                                  [-99.96, -347.001],
                                  [-165.594, -183.811],
                                  [-242.798, 22.822],
                                  [-248.416, 155.128],
                                  [-45.904, 281.013],
                                  [77.07, 365.578],
                                  [297.026, 262.552],
                                  [94.706, -50.699],
                                  [266.067, -167.192],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.551, -28.898],
                                  [-8.134, -104.452],
                                  [40.364, -55.348],
                                  [-102.986, -37.682],
                                  [-16.556, -55.829],
                                  [-138.567, 14.128],
                                  [96.714, 72.377],
                                  [-30.075, 115.861],
                                  [-13.198, 92],
                                ],
                                o: [
                                  [-91.337, 32.941],
                                  [8.134, 104.452],
                                  [-40.364, 55.348],
                                  [102.986, 37.682],
                                  [20.31, 60.449],
                                  [109.112, -7.775],
                                  [-96.714, -72.377],
                                  [24.588, -120.77],
                                  [13.198, -92],
                                ],
                                v: [
                                  [-100.619, -347.268],
                                  [-164.944, -183.178],
                                  [-243.083, 23.307],
                                  [-248.149, 154.969],
                                  [-46.095, 280.737],
                                  [76.721, 366.249],
                                  [297.414, 263.076],
                                  [93.802, -50.804],
                                  [267.287, -167.969],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.41, -29.179],
                                  [-8.472, -104.835],
                                  [40.602, -55.112],
                                  [-103.412, -37.637],
                                  [-16.574, -56.025],
                                  [-138.244, 13.981],
                                  [97.289, 72.518],
                                  [-30.317, 116.349],
                                  [-13.135, 92],
                                ],
                                o: [
                                  [-91.442, 33.173],
                                  [8.472, 104.835],
                                  [-40.602, 55.112],
                                  [103.412, 37.637],
                                  [20.282, 60.59],
                                  [109.147, -7.705],
                                  [-97.289, -72.518],
                                  [24.897, -121.198],
                                  [13.135, -92],
                                ],
                                v: [
                                  [-101.276, -347.534],
                                  [-164.275, -182.632],
                                  [-243.411, 23.789],
                                  [-247.885, 154.762],
                                  [-46.346, 280.504],
                                  [76.387, 366.914],
                                  [297.811, 263.55],
                                  [92.864, -50.925],
                                  [268.547, -168.795],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.271, -29.458],
                                  [-8.808, -105.215],
                                  [40.839, -54.879],
                                  [-103.834, -37.593],
                                  [-16.591, -56.22],
                                  [-137.924, 13.835],
                                  [97.858, 72.658],
                                  [-30.557, 116.832],
                                  [-13.072, 92],
                                ],
                                o: [
                                  [-91.547, 33.402],
                                  [8.808, 105.215],
                                  [-40.839, 54.879],
                                  [103.834, 37.593],
                                  [20.254, 60.729],
                                  [109.182, -7.636],
                                  [-97.858, -72.658],
                                  [25.203, -121.623],
                                  [13.072, -92],
                                ],
                                v: [
                                  [-101.93, -347.817],
                                  [-163.622, -182.058],
                                  [-243.74, 24.224],
                                  [-247.669, 154.579],
                                  [-46.674, 280.225],
                                  [76.139, 367.602],
                                  [298.154, 263.991],
                                  [92.001, -51.037],
                                  [269.741, -169.688],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.133, -29.733],
                                  [-9.141, -105.592],
                                  [41.073, -54.649],
                                  [-104.252, -37.549],
                                  [-16.608, -56.413],
                                  [-137.607, 13.69],
                                  [98.422, 72.797],
                                  [-30.795, 117.311],
                                  [-13.01, 92],
                                ],
                                o: [
                                  [-91.65, 33.63],
                                  [9.141, 105.592],
                                  [-41.073, 54.648],
                                  [104.252, 37.549],
                                  [20.227, 60.867],
                                  [109.217, -7.567],
                                  [-98.422, -72.797],
                                  [25.506, -122.043],
                                  [13.01, -92],
                                ],
                                v: [
                                  [-102.52, -348.098],
                                  [-162.984, -181.463],
                                  [-244.114, 24.655],
                                  [-247.429, 154.392],
                                  [-46.954, 279.989],
                                  [75.904, 368.259],
                                  [298.489, 264.488],
                                  [91.103, -51.121],
                                  [270.994, -170.557],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.997, -30.006],
                                  [-9.47, -105.964],
                                  [41.305, -54.42],
                                  [-104.665, -37.505],
                                  [-16.625, -56.604],
                                  [-137.293, 13.547],
                                  [98.981, 72.934],
                                  [-31.03, 117.785],
                                  [-12.949, 92],
                                ],
                                o: [
                                  [-91.752, 33.855],
                                  [9.47, 105.964],
                                  [-41.305, 54.42],
                                  [104.665, 37.505],
                                  [20.199, 61.003],
                                  [109.251, -7.498],
                                  [-98.981, -72.934],
                                  [25.807, -122.459],
                                  [12.949, -92],
                                ],
                                v: [
                                  [-103.107, -348.332],
                                  [-162.389, -180.867],
                                  [-244.461, 25.055],
                                  [-247.13, 154.204],
                                  [-47.312, 279.724],
                                  [75.757, 368.939],
                                  [298.833, 264.906],
                                  [90.215, -51.241],
                                  [272.179, -171.475],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.862, -30.276],
                                  [-9.796, -106.333],
                                  [41.535, -54.194],
                                  [-105.075, -37.462],
                                  [-16.642, -56.793],
                                  [-136.982, 13.405],
                                  [99.534, 73.07],
                                  [-31.263, 118.255],
                                  [-12.888, 92],
                                ],
                                o: [
                                  [-91.854, 34.078],
                                  [9.796, 106.333],
                                  [-41.535, 54.194],
                                  [105.075, 37.462],
                                  [20.172, 61.138],
                                  [109.284, -7.431],
                                  [-99.534, -73.07],
                                  [26.104, -122.871],
                                  [12.888, -92],
                                ],
                                v: [
                                  [-103.646, -348.627],
                                  [-161.746, -180.332],
                                  [-244.836, 25.479],
                                  [-246.879, 154.057],
                                  [-47.684, 279.502],
                                  [75.59, 369.616],
                                  [299.186, 265.351],
                                  [89.355, -51.335],
                                  [273.361, -172.458],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.728, -30.544],
                                  [-10.118, -106.699],
                                  [41.762, -53.97],
                                  [-105.48, -37.42],
                                  [-16.659, -56.981],
                                  [-136.675, 13.265],
                                  [100.081, 73.205],
                                  [-31.494, 118.72],
                                  [-12.828, 92],
                                ],
                                o: [
                                  [-91.954, 34.298],
                                  [10.118, 106.699],
                                  [-41.762, 53.97],
                                  [105.48, 37.42],
                                  [20.146, 61.272],
                                  [109.318, -7.364],
                                  [-100.082, -73.205],
                                  [26.398, -123.279],
                                  [12.828, -92],
                                ],
                                v: [
                                  [-104.136, -348.873],
                                  [-161.163, -179.749],
                                  [-245.229, 25.825],
                                  [-246.648, 153.892],
                                  [-48.07, 279.294],
                                  [75.5, 370.335],
                                  [299.486, 265.79],
                                  [88.505, -51.421],
                                  [274.492, -173.445],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.596, -30.809],
                                  [-10.438, -107.06],
                                  [41.987, -53.748],
                                  [-105.882, -37.377],
                                  [-16.676, -57.166],
                                  [-136.37, 13.126],
                                  [100.624, 73.338],
                                  [-31.722, 119.18],
                                  [-12.768, 92],
                                ],
                                o: [
                                  [-92.053, 34.517],
                                  [10.438, 107.06],
                                  [-41.987, 53.748],
                                  [105.882, 37.377],
                                  [20.119, 61.404],
                                  [109.351, -7.298],
                                  [-100.624, -73.338],
                                  [26.689, -123.683],
                                  [12.768, -92],
                                ],
                                v: [
                                  [-104.623, -349.118],
                                  [-160.562, -179.209],
                                  [-245.605, 26.197],
                                  [-246.358, 153.771],
                                  [-48.533, 279.085],
                                  [75.437, 370.988],
                                  [299.777, 266.239],
                                  [87.664, -51.498],
                                  [275.62, -174.452],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.465, -31.071],
                                  [-10.754, -107.419],
                                  [42.21, -53.528],
                                  [-106.279, -37.336],
                                  [-16.692, -57.35],
                                  [-136.069, 12.988],
                                  [101.16, 73.47],
                                  [-31.949, 119.635],
                                  [-12.709, 92],
                                ],
                                o: [
                                  [-92.152, 34.733],
                                  [10.754, 107.419],
                                  [-42.21, 53.528],
                                  [106.279, 37.336],
                                  [20.093, 61.535],
                                  [109.384, -7.232],
                                  [-101.161, -73.47],
                                  [26.978, -124.083],
                                  [12.709, -92],
                                ],
                                v: [
                                  [-105.044, -349.376],
                                  [-160.037, -178.668],
                                  [-245.998, 26.535],
                                  [-246.086, 153.632],
                                  [-48.947, 278.826],
                                  [75.481, 371.684],
                                  [300.078, 266.651],
                                  [86.85, -51.567],
                                  [276.742, -175.462],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.335, -31.33],
                                  [-11.067, -107.773],
                                  [42.431, -53.311],
                                  [-106.673, -37.294],
                                  [-16.708, -57.531],
                                  [-135.77, 12.852],
                                  [101.692, 73.601],
                                  [-32.172, 120.087],
                                  [-12.651, 92],
                                ],
                                o: [
                                  [-92.249, 34.948],
                                  [11.067, 107.773],
                                  [-42.431, 53.311],
                                  [106.673, 37.294],
                                  [20.067, 61.665],
                                  [109.416, -7.167],
                                  [-101.692, -73.601],
                                  [27.263, -124.479],
                                  [12.651, -92],
                                ],
                                v: [
                                  [-105.478, -349.585],
                                  [-159.51, -178.126],
                                  [-246.437, 26.915],
                                  [-245.863, 153.555],
                                  [-49.438, 278.628],
                                  [75.523, 372.378],
                                  [300.341, 267.073],
                                  [86.044, -51.659],
                                  [277.798, -176.538],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.206, -31.587],
                                  [-11.377, -108.124],
                                  [42.649, -53.095],
                                  [-107.062, -37.253],
                                  [-16.724, -57.711],
                                  [-135.474, 12.717],
                                  [102.218, 73.73],
                                  [-32.394, 120.533],
                                  [-12.593, 92],
                                ],
                                o: [
                                  [-92.345, 35.16],
                                  [11.377, 108.124],
                                  [-42.649, 53.095],
                                  [107.062, 37.253],
                                  [20.041, 61.794],
                                  [109.448, -7.103],
                                  [-102.218, -73.73],
                                  [27.546, -124.871],
                                  [12.593, -92],
                                ],
                                v: [
                                  [-105.845, -349.838],
                                  [-158.98, -177.628],
                                  [-246.831, 27.245],
                                  [-245.597, 153.461],
                                  [-49.879, 278.427],
                                  [75.611, 373.052],
                                  [300.597, 267.504],
                                  [85.248, -51.743],
                                  [278.849, -177.631],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.079, -31.842],
                                  [-11.684, -108.472],
                                  [42.866, -52.882],
                                  [-107.448, -37.213],
                                  [-16.74, -57.889],
                                  [-135.182, 12.583],
                                  [102.739, 73.858],
                                  [-32.614, 120.975],
                                  [-12.536, 92],
                                ],
                                o: [
                                  [-92.441, 35.37],
                                  [11.684, 108.472],
                                  [-42.866, 52.882],
                                  [107.448, 37.213],
                                  [20.016, 61.921],
                                  [109.48, -7.039],
                                  [-102.739, -73.858],
                                  [27.826, -125.259],
                                  [12.536, -92],
                                ],
                                v: [
                                  [-106.147, -350.056],
                                  [-158.449, -177.082],
                                  [-247.271, 27.523],
                                  [-245.348, 153.35],
                                  [-50.397, 278.24],
                                  [75.745, 373.772],
                                  [300.862, 267.898],
                                  [84.477, -51.851],
                                  [279.896, -178.727],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.953, -32.094],
                                  [-11.988, -108.816],
                                  [43.08, -52.671],
                                  [-107.83, -37.173],
                                  [-16.756, -58.066],
                                  [-134.892, 12.451],
                                  [103.255, 73.985],
                                  [-32.831, 121.413],
                                  [-12.479, 92],
                                ],
                                o: [
                                  [-92.535, 35.577],
                                  [11.988, 108.816],
                                  [-43.08, 52.671],
                                  [107.83, 37.173],
                                  [19.991, 62.047],
                                  [109.512, -6.977],
                                  [-103.255, -73.985],
                                  [28.103, -125.643],
                                  [12.479, -92],
                                ],
                                v: [
                                  [-106.46, -350.287],
                                  [-157.977, -176.598],
                                  [-247.665, 27.75],
                                  [-245.101, 153.334],
                                  [-50.928, 278.018],
                                  [75.941, 374.442],
                                  [301.072, 268.316],
                                  [83.714, -51.968],
                                  [280.876, -179.841],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.828, -32.343],
                                  [-12.289, -109.156],
                                  [43.292, -52.462],
                                  [-108.208, -37.133],
                                  [-16.771, -58.24],
                                  [-134.605, 12.32],
                                  [103.765, 74.11],
                                  [-33.046, 121.847],
                                  [-12.423, 92],
                                ],
                                o: [
                                  [-92.629, 35.783],
                                  [12.289, 109.156],
                                  [-43.292, 52.462],
                                  [108.208, 37.133],
                                  [19.966, 62.172],
                                  [109.543, -6.914],
                                  [-103.765, -74.11],
                                  [28.378, -126.024],
                                  [12.423, -92],
                                ],
                                v: [
                                  [-106.707, -350.513],
                                  [-157.566, -176.143],
                                  [-248.06, 27.972],
                                  [-244.809, 153.27],
                                  [-51.534, 277.839],
                                  [76.183, 375.143],
                                  [301.292, 268.742],
                                  [82.928, -52.064],
                                  [281.867, -181.019],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.705, -32.59],
                                  [-12.587, -109.494],
                                  [43.502, -52.256],
                                  [-108.582, -37.093],
                                  [-16.787, -58.413],
                                  [-134.321, 12.19],
                                  [104.271, 74.234],
                                  [-33.259, 122.276],
                                  [-12.367, 92],
                                ],
                                o: [
                                  [-92.721, 35.987],
                                  [12.587, 109.494],
                                  [-43.502, 52.256],
                                  [108.582, 37.093],
                                  [19.941, 62.295],
                                  [109.574, -6.852],
                                  [-104.271, -74.234],
                                  [28.649, -126.4],
                                  [12.367, -92],
                                ],
                                v: [
                                  [-106.965, -350.689],
                                  [-157.137, -175.656],
                                  [-248.454, 28.142],
                                  [-244.565, 153.302],
                                  [-52.091, 277.657],
                                  [76.458, 375.827],
                                  [301.504, 269.113],
                                  [82.197, -52.153],
                                  [282.79, -182.167],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.583, -32.835],
                                  [-12.882, -109.827],
                                  [43.709, -52.051],
                                  [-108.953, -37.054],
                                  [-16.802, -58.584],
                                  [-134.04, 12.062],
                                  [104.771, 74.357],
                                  [-33.47, 122.7],
                                  [-12.312, 92],
                                ],
                                o: [
                                  [-92.813, 36.189],
                                  [12.882, 109.827],
                                  [-43.71, 52.051],
                                  [108.953, 37.055],
                                  [19.917, 62.417],
                                  [109.604, -6.791],
                                  [-104.771, -74.357],
                                  [28.918, -126.773],
                                  [12.312, -92],
                                ],
                                v: [
                                  [-107.155, -350.875],
                                  [-156.784, -175.215],
                                  [-248.849, 28.321],
                                  [-244.353, 153.32],
                                  [-52.661, 277.487],
                                  [76.811, 376.509],
                                  [301.678, 269.507],
                                  [81.488, -52.283],
                                  [283.709, -183.363],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.462, -33.077],
                                  [-13.174, -110.158],
                                  [43.915, -51.848],
                                  [-109.319, -37.016],
                                  [-16.817, -58.754],
                                  [-133.762, 11.935],
                                  [105.266, 74.479],
                                  [-33.679, 123.121],
                                  [-12.258, 92],
                                ],
                                o: [
                                  [-92.904, 36.388],
                                  [13.174, 110.158],
                                  [-43.915, 51.848],
                                  [109.319, 37.016],
                                  [19.892, 62.538],
                                  [109.635, -6.731],
                                  [-105.266, -74.479],
                                  [29.184, -127.142],
                                  [12.258, -92],
                                ],
                                v: [
                                  [-107.279, -351.104],
                                  [-156.415, -174.758],
                                  [-249.244, 28.432],
                                  [-244.127, 153.387],
                                  [-53.259, 277.328],
                                  [77.245, 377.176],
                                  [301.845, 269.923],
                                  [80.788, -52.408],
                                  [284.608, -184.56],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.342, -33.316],
                                  [-13.463, -110.485],
                                  [44.119, -51.648],
                                  [-109.682, -36.978],
                                  [-16.832, -58.921],
                                  [-133.486, 11.809],
                                  [105.756, 74.6],
                                  [-33.885, 123.537],
                                  [-12.204, 92],
                                ],
                                o: [
                                  [-92.994, 36.586],
                                  [13.463, 110.485],
                                  [-44.119, 51.648],
                                  [109.682, 36.978],
                                  [19.868, 62.658],
                                  [109.665, -6.671],
                                  [-105.757, -74.6],
                                  [29.448, -127.507],
                                  [12.204, -92],
                                ],
                                v: [
                                  [-107.413, -351.295],
                                  [-156.105, -174.364],
                                  [-249.64, 28.49],
                                  [-243.869, 153.455],
                                  [-53.868, 277.179],
                                  [77.681, 377.843],
                                  [301.973, 270.332],
                                  [80.143, -52.527],
                                  [285.455, -185.788],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.223, -33.553],
                                  [-13.749, -110.809],
                                  [44.32, -51.449],
                                  [-110.042, -36.94],
                                  [-16.847, -59.087],
                                  [-133.214, 11.685],
                                  [106.242, 74.719],
                                  [-34.09, 123.949],
                                  [-12.151, 92],
                                ],
                                o: [
                                  [-93.082, 36.781],
                                  [13.749, 110.809],
                                  [-44.32, 51.449],
                                  [110.042, 36.94],
                                  [19.845, 62.777],
                                  [109.694, -6.612],
                                  [-106.242, -74.719],
                                  [29.709, -127.869],
                                  [12.151, -92],
                                ],
                                v: [
                                  [-107.495, -351.448],
                                  [-155.857, -173.983],
                                  [-249.987, 28.556],
                                  [-243.66, 153.559],
                                  [-54.475, 277.027],
                                  [78.132, 378.59],
                                  [302.094, 270.714],
                                  [79.472, -52.674],
                                  [286.297, -187.064],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.106, -33.788],
                                  [-14.032, -111.129],
                                  [44.52, -51.253],
                                  [-110.397, -36.903],
                                  [-16.862, -59.252],
                                  [-132.944, 11.561],
                                  [106.722, 74.837],
                                  [-34.292, 124.357],
                                  [-12.098, 92],
                                ],
                                o: [
                                  [-93.17, 36.975],
                                  [14.032, 111.129],
                                  [-44.52, 51.253],
                                  [110.397, 36.903],
                                  [19.821, 62.894],
                                  [109.724, -6.553],
                                  [-106.722, -74.837],
                                  [29.967, -128.227],
                                  [12.098, -92],
                                ],
                                v: [
                                  [-107.509, -351.644],
                                  [-155.607, -173.587],
                                  [-250.334, 28.567],
                                  [-243.48, 153.665],
                                  [-55.109, 276.884],
                                  [78.667, 379.372],
                                  [302.223, 271.116],
                                  [78.856, -52.83],
                                  [287.086, -188.306],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.99, -34.02],
                                  [-14.312, -111.446],
                                  [44.717, -51.058],
                                  [-110.749, -36.866],
                                  [-16.876, -59.414],
                                  [-132.677, 11.439],
                                  [107.198, 74.954],
                                  [-34.492, 124.76],
                                  [-12.045, 92],
                                ],
                                o: [
                                  [-93.258, 37.167],
                                  [14.312, 111.446],
                                  [-44.717, 51.058],
                                  [110.749, 36.866],
                                  [19.798, 63.01],
                                  [109.753, -6.495],
                                  [-107.198, -74.954],
                                  [30.222, -128.581],
                                  [12.045, -92],
                                ],
                                v: [
                                  [-107.566, -351.786],
                                  [-155.404, -173.19],
                                  [-250.681, 28.558],
                                  [-243.286, 153.774],
                                  [-55.754, 276.737],
                                  [79.205, 380.155],
                                  [302.312, 271.462],
                                  [78.247, -52.967],
                                  [287.857, -189.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.875, -34.25],
                                  [-14.589, -111.76],
                                  [44.913, -50.866],
                                  [-111.098, -36.829],
                                  [-16.891, -59.575],
                                  [-132.412, 11.319],
                                  [107.668, 75.07],
                                  [-34.691, 125.16],
                                  [-11.994, 92],
                                ],
                                o: [
                                  [-93.344, 37.356],
                                  [14.589, 111.76],
                                  [-44.913, 50.866],
                                  [111.098, 36.829],
                                  [19.775, 63.125],
                                  [109.781, -6.437],
                                  [-107.668, -75.07],
                                  [30.475, -128.931],
                                  [11.994, -92],
                                ],
                                v: [
                                  [-107.521, -351.999],
                                  [-155.213, -172.856],
                                  [-251.028, 28.509],
                                  [-243.142, 153.885],
                                  [-56.362, 276.662],
                                  [79.793, 380.974],
                                  [302.395, 271.889],
                                  [77.646, -53.148],
                                  [288.622, -190.868],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.761, -34.478],
                                  [-14.864, -112.071],
                                  [45.106, -50.675],
                                  [-111.443, -36.793],
                                  [-16.905, -59.734],
                                  [-132.151, 11.199],
                                  [108.134, 75.184],
                                  [-34.887, 125.555],
                                  [-11.943, 92],
                                ],
                                o: [
                                  [-93.429, 37.544],
                                  [14.864, 112.071],
                                  [-45.106, 50.675],
                                  [111.443, 36.793],
                                  [19.752, 63.239],
                                  [109.81, -6.381],
                                  [-108.134, -75.184],
                                  [30.725, -129.278],
                                  [11.943, -92],
                                ],
                                v: [
                                  [-107.471, -352.157],
                                  [-155.069, -172.57],
                                  [-251.375, 28.452],
                                  [-243.047, 153.984],
                                  [-56.981, 276.533],
                                  [80.37, 381.817],
                                  [302.437, 272.26],
                                  [77.115, -53.29],
                                  [289.334, -192.137],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.649, -34.703],
                                  [-15.135, -112.379],
                                  [45.297, -50.486],
                                  [-111.784, -36.757],
                                  [-16.919, -59.892],
                                  [-131.892, 11.081],
                                  [108.595, 75.297],
                                  [-35.081, 125.946],
                                  [-11.892, 92],
                                ],
                                o: [
                                  [-93.514, 37.73],
                                  [15.135, 112.379],
                                  [-45.297, 50.486],
                                  [111.784, 36.757],
                                  [19.73, 63.351],
                                  [109.838, -6.324],
                                  [-108.595, -75.297],
                                  [30.973, -129.622],
                                  [11.892, -92],
                                ],
                                v: [
                                  [-107.416, -352.309],
                                  [-154.938, -172.27],
                                  [-251.672, 28.389],
                                  [-242.939, 154.086],
                                  [-57.66, 276.461],
                                  [81.012, 382.676],
                                  [302.473, 272.6],
                                  [76.542, -53.461],
                                  [289.992, -193.468],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.537, -34.926],
                                  [-15.404, -112.683],
                                  [45.487, -50.3],
                                  [-112.122, -36.721],
                                  [-16.933, -60.048],
                                  [-131.635, 10.964],
                                  [109.051, 75.409],
                                  [-35.273, 126.333],
                                  [-11.842, 92],
                                ],
                                o: [
                                  [-93.597, 37.914],
                                  [15.404, 112.683],
                                  [-45.487, 50.3],
                                  [112.122, 36.721],
                                  [19.707, 63.463],
                                  [109.866, -6.269],
                                  [-109.051, -75.409],
                                  [31.218, -129.962],
                                  [11.842, -92],
                                ],
                                v: [
                                  [-107.293, -352.531],
                                  [-154.769, -172.02],
                                  [-251.969, 28.27],
                                  [-242.896, 154.191],
                                  [-58.252, 276.385],
                                  [81.693, 383.51],
                                  [302.516, 272.957],
                                  [76.025, -53.629],
                                  [290.68, -194.749],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.427, -35.146],
                                  [-15.67, -112.984],
                                  [45.674, -50.115],
                                  [-112.456, -36.686],
                                  [-16.947, -60.202],
                                  [-131.382, 10.848],
                                  [109.503, 75.52],
                                  [-35.464, 126.717],
                                  [-11.792, 92],
                                ],
                                o: [
                                  [-93.68, 38.096],
                                  [15.67, 112.984],
                                  [-45.674, 50.115],
                                  [112.456, 36.686],
                                  [19.685, 63.573],
                                  [109.893, -6.213],
                                  [-109.503, -75.521],
                                  [31.461, -130.298],
                                  [11.792, -92],
                                ],
                                v: [
                                  [-107.178, -352.698],
                                  [-154.647, -171.771],
                                  [-252.266, 28.094],
                                  [-242.89, 154.249],
                                  [-58.904, 276.317],
                                  [82.377, 384.447],
                                  [302.518, 273.319],
                                  [75.514, -53.841],
                                  [291.279, -196.078],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.318, -35.365],
                                  [-15.934, -113.282],
                                  [45.86, -49.932],
                                  [-112.787, -36.651],
                                  [-16.96, -60.355],
                                  [-131.131, 10.734],
                                  [109.949, 75.63],
                                  [-35.652, 127.096],
                                  [-11.743, 92],
                                ],
                                o: [
                                  [-93.762, 38.276],
                                  [15.934, 113.282],
                                  [-45.86, 49.932],
                                  [112.787, 36.651],
                                  [19.664, 63.682],
                                  [109.921, -6.159],
                                  [-109.949, -75.63],
                                  [31.701, -130.631],
                                  [11.743, -92],
                                ],
                                v: [
                                  [-106.995, -352.857],
                                  [-154.499, -171.571],
                                  [-252.562, 27.962],
                                  [-242.887, 154.345],
                                  [-59.516, 276.269],
                                  [83.065, 385.324],
                                  [302.514, 273.685],
                                  [75.024, -54.05],
                                  [291.908, -197.344],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.21, -35.581],
                                  [-16.194, -113.577],
                                  [46.044, -49.751],
                                  [-113.114, -36.617],
                                  [-16.974, -60.506],
                                  [-130.882, 10.62],
                                  [110.392, 75.739],
                                  [-35.838, 127.471],
                                  [-11.694, 92],
                                ],
                                o: [
                                  [-93.843, 38.454],
                                  [16.194, 113.577],
                                  [-46.044, 49.751],
                                  [113.114, 36.617],
                                  [19.642, 63.79],
                                  [109.948, -6.105],
                                  [-110.392, -75.739],
                                  [31.939, -130.96],
                                  [11.694, -92],
                                ],
                                v: [
                                  [-106.806, -353.036],
                                  [-154.411, -171.36],
                                  [-252.845, 27.772],
                                  [-242.935, 154.407],
                                  [-60.139, 276.216],
                                  [83.805, 386.243],
                                  [302.481, 274.004],
                                  [74.59, -54.254],
                                  [292.496, -198.609],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.103, -35.794],
                                  [-16.452, -113.869],
                                  [46.225, -49.572],
                                  [-113.438, -36.583],
                                  [-16.987, -60.656],
                                  [-130.636, 10.508],
                                  [110.829, 75.847],
                                  [-36.023, 127.843],
                                  [-11.646, 92],
                                ],
                                o: [
                                  [-93.923, 38.63],
                                  [16.452, 113.869],
                                  [-46.225, 49.572],
                                  [113.438, 36.583],
                                  [19.621, 63.897],
                                  [109.974, -6.051],
                                  [-110.829, -75.846],
                                  [32.174, -131.286],
                                  [11.646, -92],
                                ],
                                v: [
                                  [-106.612, -353.208],
                                  [-154.271, -171.201],
                                  [-253.091, 27.526],
                                  [-242.985, 154.509],
                                  [-60.722, 276.169],
                                  [84.537, 387.165],
                                  [302.454, 274.34],
                                  [74.114, -54.455],
                                  [293.028, -199.923],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.997, -36.006],
                                  [-16.707, -114.158],
                                  [46.405, -49.395],
                                  [-113.759, -36.549],
                                  [-17, -60.804],
                                  [-130.393, 10.397],
                                  [111.262, 75.953],
                                  [-36.205, 128.21],
                                  [-11.599, 92],
                                ],
                                o: [
                                  [-94.002, 38.805],
                                  [16.707, 114.158],
                                  [-46.405, 49.395],
                                  [113.759, 36.549],
                                  [19.599, 64.003],
                                  [110.001, -5.999],
                                  [-111.262, -75.953],
                                  [32.406, -131.609],
                                  [11.599, -92],
                                ],
                                v: [
                                  [-106.363, -353.385],
                                  [-154.202, -171.093],
                                  [-253.323, 27.335],
                                  [-243.076, 154.563],
                                  [-61.327, 276.167],
                                  [85.323, 388.117],
                                  [302.434, 274.666],
                                  [73.693, -54.714],
                                  [293.555, -201.173],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.892, -36.215],
                                  [-16.96, -114.444],
                                  [46.583, -49.22],
                                  [-114.076, -36.516],
                                  [-17.013, -60.951],
                                  [-130.152, 10.287],
                                  [111.691, 76.058],
                                  [-36.386, 128.574],
                                  [-11.552, 92],
                                ],
                                o: [
                                  [-94.081, 38.978],
                                  [16.96, 114.444],
                                  [-46.583, 49.22],
                                  [114.076, 36.516],
                                  [19.578, 64.108],
                                  [110.027, -5.946],
                                  [-111.691, -76.058],
                                  [32.637, -131.928],
                                  [11.552, -92],
                                ],
                                v: [
                                  [-106.096, -353.555],
                                  [-154.13, -170.975],
                                  [-253.568, 27.075],
                                  [-243.182, 154.67],
                                  [-61.928, 276.171],
                                  [86.112, 389.086],
                                  [302.382, 274.958],
                                  [73.28, -54.957],
                                  [294.088, -202.423],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.789, -36.422],
                                  [-17.209, -114.727],
                                  [46.759, -49.046],
                                  [-114.39, -36.483],
                                  [-17.026, -61.096],
                                  [-129.914, 10.178],
                                  [112.115, 76.163],
                                  [-36.564, 128.934],
                                  [-11.505, 92],
                                ],
                                o: [
                                  [-94.158, 39.148],
                                  [17.209, 114.727],
                                  [-46.759, 49.046],
                                  [114.39, 36.483],
                                  [19.558, 64.211],
                                  [110.053, -5.894],
                                  [-112.115, -76.162],
                                  [32.865, -132.244],
                                  [11.505, -92],
                                ],
                                v: [
                                  [-105.836, -353.742],
                                  [-154.067, -170.871],
                                  [-253.799, 26.807],
                                  [-243.328, 154.729],
                                  [-62.488, 276.182],
                                  [86.945, 390.035],
                                  [302.336, 275.24],
                                  [72.924, -55.196],
                                  [294.552, -203.672],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.686, -36.627],
                                  [-17.457, -115.007],
                                  [46.933, -48.875],
                                  [-114.701, -36.45],
                                  [-17.039, -61.239],
                                  [-129.679, 10.071],
                                  [112.534, 76.266],
                                  [-36.741, 129.29],
                                  [-11.459, 92],
                                ],
                                o: [
                                  [-94.235, 39.318],
                                  [17.457, 115.007],
                                  [-46.933, 48.875],
                                  [114.701, 36.45],
                                  [19.537, 64.314],
                                  [110.078, -5.843],
                                  [-112.534, -76.266],
                                  [33.09, -132.557],
                                  [11.459, -92],
                                ],
                                v: [
                                  [-105.57, -353.971],
                                  [-154.062, -170.796],
                                  [-253.98, 26.545],
                                  [-243.528, 154.829],
                                  [-63.007, 276.186],
                                  [87.743, 390.989],
                                  [302.295, 275.536],
                                  [72.536, -55.494],
                                  [295.023, -204.858],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.585, -36.83],
                                  [-17.701, -115.284],
                                  [47.106, -48.705],
                                  [-115.008, -36.418],
                                  [-17.052, -61.381],
                                  [-129.445, 9.964],
                                  [112.949, 76.368],
                                  [-36.916, 129.642],
                                  [-11.413, 92],
                                ],
                                o: [
                                  [-94.311, 39.485],
                                  [17.701, 115.284],
                                  [-47.106, 48.705],
                                  [115.008, 36.418],
                                  [19.517, 64.415],
                                  [110.104, -5.792],
                                  [-112.949, -76.368],
                                  [33.313, -132.866],
                                  [11.413, -92],
                                ],
                                v: [
                                  [-105.236, -354.155],
                                  [-154.066, -170.786],
                                  [-254.148, 26.263],
                                  [-243.732, 154.893],
                                  [-63.546, 276.185],
                                  [88.584, 391.937],
                                  [302.21, 275.785],
                                  [72.193, -55.727],
                                  [295.436, -206.094],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.485, -37.031],
                                  [-17.943, -115.558],
                                  [47.276, -48.537],
                                  [-115.312, -36.386],
                                  [-17.064, -61.521],
                                  [-129.215, 9.859],
                                  [113.36, 76.469],
                                  [-37.089, 129.991],
                                  [-11.368, 92],
                                ],
                                o: [
                                  [-94.387, 39.65],
                                  [17.943, 115.558],
                                  [-47.276, 48.537],
                                  [115.312, 36.386],
                                  [19.497, 64.515],
                                  [110.129, -5.742],
                                  [-113.36, -76.469],
                                  [33.534, -133.172],
                                  [11.368, -92],
                                ],
                                v: [
                                  [-104.897, -354.331],
                                  [-154.065, -170.767],
                                  [-254.316, 25.924],
                                  [-243.99, 155.009],
                                  [-64.081, 276.24],
                                  [89.43, 392.89],
                                  [302.141, 276.036],
                                  [71.87, -56.069],
                                  [295.843, -207.279],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.385, -37.229],
                                  [-18.183, -115.829],
                                  [47.445, -48.371],
                                  [-115.613, -36.354],
                                  [-17.077, -61.66],
                                  [-128.986, 9.755],
                                  [113.766, 76.568],
                                  [-37.26, 130.336],
                                  [-11.323, 92],
                                ],
                                o: [
                                  [-94.461, 39.814],
                                  [18.183, 115.829],
                                  [-47.445, 48.37],
                                  [115.613, 36.354],
                                  [19.477, 64.615],
                                  [110.154, -5.693],
                                  [-113.766, -76.568],
                                  [33.752, -133.475],
                                  [11.323, -92],
                                ],
                                v: [
                                  [-104.564, -354.549],
                                  [-154.084, -170.814],
                                  [-254.432, 25.628],
                                  [-244.252, 155.129],
                                  [-64.585, 276.25],
                                  [90.269, 393.837],
                                  [302.088, 276.3],
                                  [71.552, -56.357],
                                  [296.193, -208.463],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.287, -37.426],
                                  [-18.42, -116.097],
                                  [47.612, -48.206],
                                  [-115.911, -36.323],
                                  [-17.089, -61.798],
                                  [-128.761, 9.651],
                                  [114.168, 76.667],
                                  [-37.43, 130.677],
                                  [-11.279, 92],
                                ],
                                o: [
                                  [-94.535, 39.976],
                                  [18.42, 116.097],
                                  [-47.612, 48.206],
                                  [115.911, 36.323],
                                  [19.457, 64.713],
                                  [110.178, -5.644],
                                  [-114.168, -76.667],
                                  [33.968, -133.774],
                                  [11.279, -92],
                                ],
                                v: [
                                  [-104.174, -354.761],
                                  [-154.15, -170.853],
                                  [-254.599, 25.326],
                                  [-244.568, 155.188],
                                  [-65.034, 276.305],
                                  [91.164, 394.778],
                                  [301.978, 276.555],
                                  [71.281, -56.693],
                                  [296.598, -209.646],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.19, -37.62],
                                  [-18.654, -116.362],
                                  [47.777, -48.043],
                                  [-116.205, -36.292],
                                  [-17.101, -61.934],
                                  [-128.537, 9.55],
                                  [114.566, 76.765],
                                  [-37.597, 131.015],
                                  [-11.236, 92],
                                ],
                                o: [
                                  [-94.607, 40.136],
                                  [18.654, 116.362],
                                  [-47.777, 48.043],
                                  [116.205, 36.292],
                                  [19.438, 64.81],
                                  [110.203, -5.595],
                                  [-114.566, -76.765],
                                  [34.182, -134.07],
                                  [11.236, -92],
                                ],
                                v: [
                                  [-103.829, -354.914],
                                  [-154.223, -170.936],
                                  [-254.703, 24.966],
                                  [-244.888, 155.311],
                                  [-65.451, 276.353],
                                  [92.063, 395.725],
                                  [301.883, 276.771],
                                  [71.028, -57.025],
                                  [296.883, -210.817],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.094, -37.812],
                                  [-18.886, -116.625],
                                  [47.94, -47.882],
                                  [-116.497, -36.261],
                                  [-17.113, -62.068],
                                  [-128.316, 9.449],
                                  [114.959, 76.862],
                                  [-37.763, 131.349],
                                  [-11.192, 92],
                                ],
                                o: [
                                  [-94.68, 40.295],
                                  [18.886, 116.625],
                                  [-47.94, 47.882],
                                  [116.497, 36.261],
                                  [19.419, 64.906],
                                  [110.227, -5.547],
                                  [-114.959, -76.862],
                                  [34.393, -134.364],
                                  [11.192, -92],
                                ],
                                v: [
                                  [-103.428, -355.122],
                                  [-154.302, -171.033],
                                  [-254.755, 24.64],
                                  [-245.263, 155.438],
                                  [-65.863, 276.447],
                                  [92.916, 396.666],
                                  [301.792, 277],
                                  [70.782, -57.354],
                                  [297.172, -211.987],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.999, -38.002],
                                  [-19.115, -116.884],
                                  [48.102, -47.723],
                                  [-116.785, -36.231],
                                  [-17.125, -62.202],
                                  [-128.097, 9.349],
                                  [115.348, 76.957],
                                  [-37.927, 131.679],
                                  [-11.149, 92],
                                ],
                                o: [
                                  [-94.751, 40.452],
                                  [19.115, 116.884],
                                  [-48.102, 47.723],
                                  [116.785, 36.231],
                                  [19.4, 65.001],
                                  [110.25, -5.499],
                                  [-115.348, -76.957],
                                  [34.603, -134.654],
                                  [11.149, -92],
                                ],
                                v: [
                                  [-103.072, -355.363],
                                  [-154.429, -171.124],
                                  [-254.848, 24.307],
                                  [-245.643, 155.504],
                                  [-66.293, 276.495],
                                  [93.815, 397.561],
                                  [301.664, 277.219],
                                  [70.543, -57.743],
                                  [297.464, -213.095],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.905, -38.19],
                                  [-19.342, -117.141],
                                  [48.262, -47.566],
                                  [-117.07, -36.201],
                                  [-17.137, -62.333],
                                  [-127.881, 9.25],
                                  [115.734, 77.052],
                                  [-38.089, 132.006],
                                  [-11.107, 92],
                                ],
                                o: [
                                  [-94.821, 40.607],
                                  [19.342, 117.141],
                                  [-48.262, 47.566],
                                  [117.07, 36.201],
                                  [19.381, 65.095],
                                  [110.274, -5.452],
                                  [-115.734, -77.052],
                                  [34.809, -134.94],
                                  [11.107, -92],
                                ],
                                v: [
                                  [-102.67, -355.546],
                                  [-154.562, -171.27],
                                  [-254.888, 23.979],
                                  [-246.026, 155.634],
                                  [-66.615, 276.6],
                                  [94.718, 398.503],
                                  [301.54, 277.398],
                                  [70.31, -58.17],
                                  [297.739, -214.213],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.812, -38.377],
                                  [-19.567, -117.395],
                                  [48.42, -47.41],
                                  [-117.352, -36.171],
                                  [-17.149, -62.464],
                                  [-127.667, 9.152],
                                  [116.114, 77.146],
                                  [-38.25, 132.329],
                                  [-11.065, 92],
                                ],
                                o: [
                                  [-94.891, 40.761],
                                  [19.567, 117.395],
                                  [-48.42, 47.41],
                                  [117.352, 36.171],
                                  [19.362, 65.188],
                                  [110.297, -5.406],
                                  [-116.114, -77.146],
                                  [35.014, -135.224],
                                  [11.065, -92],
                                ],
                                v: [
                                  [-102.262, -355.773],
                                  [-154.691, -171.42],
                                  [-254.929, 23.582],
                                  [-246.506, 155.767],
                                  [-66.954, 276.688],
                                  [95.627, 399.398],
                                  [301.419, 277.578],
                                  [70.135, -58.552],
                                  [297.965, -215.267],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.72, -38.561],
                                  [-19.789, -117.647],
                                  [48.577, -47.256],
                                  [-117.631, -36.142],
                                  [-17.16, -62.592],
                                  [-127.455, 9.056],
                                  [116.491, 77.238],
                                  [-38.408, 132.649],
                                  [-11.024, 92],
                                ],
                                o: [
                                  [-94.96, 40.912],
                                  [19.789, 117.647],
                                  [-48.576, 47.255],
                                  [117.631, 36.142],
                                  [19.344, 65.28],
                                  [110.32, -5.36],
                                  [-116.491, -77.238],
                                  [35.217, -135.505],
                                  [11.024, -92],
                                ],
                                v: [
                                  [-101.9, -355.953],
                                  [-154.889, -171.616],
                                  [-254.969, 23.273],
                                  [-246.95, 155.902],
                                  [-67.236, 276.78],
                                  [96.53, 400.289],
                                  [301.259, 277.769],
                                  [69.968, -58.994],
                                  [298.132, -216.373],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.629, -38.743],
                                  [-20.008, -117.895],
                                  [48.731, -47.103],
                                  [-117.907, -36.113],
                                  [-17.171, -62.72],
                                  [-127.246, 8.96],
                                  [116.864, 77.33],
                                  [-38.566, 132.965],
                                  [-10.983, 92],
                                ],
                                o: [
                                  [-95.029, 41.063],
                                  [20.008, 117.895],
                                  [-48.731, 47.103],
                                  [117.907, 36.113],
                                  [19.326, 65.371],
                                  [110.343, -5.314],
                                  [-116.864, -77.33],
                                  [35.417, -135.783],
                                  [10.983, -92],
                                ],
                                v: [
                                  [-101.481, -356.167],
                                  [-155.134, -171.827],
                                  [-254.946, 22.917],
                                  [-247.449, 155.987],
                                  [-67.535, 276.92],
                                  [97.438, 401.133],
                                  [301.154, 277.96],
                                  [69.796, -59.475],
                                  [298.343, -217.425],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.539, -38.923],
                                  [-20.226, -118.141],
                                  [48.884, -46.952],
                                  [-118.18, -36.084],
                                  [-17.183, -62.846],
                                  [-127.039, 8.866],
                                  [117.233, 77.421],
                                  [-38.721, 133.278],
                                  [-10.942, 92],
                                ],
                                o: [
                                  [-95.096, 41.211],
                                  [20.226, 118.141],
                                  [-48.884, 46.952],
                                  [118.18, 36.084],
                                  [19.308, 65.461],
                                  [110.365, -5.269],
                                  [-117.233, -77.42],
                                  [35.615, -136.057],
                                  [10.942, -92],
                                ],
                                v: [
                                  [-101.118, -356.427],
                                  [-155.375, -172.032],
                                  [-254.933, 22.544],
                                  [-248.005, 156.136],
                                  [-67.787, 277.053],
                                  [98.351, 402.024],
                                  [301.01, 278.162],
                                  [69.642, -59.964],
                                  [298.443, -218.467],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.45, -39.101],
                                  [-20.44, -118.385],
                                  [49.036, -46.803],
                                  [-118.45, -36.056],
                                  [-17.194, -62.971],
                                  [-126.834, 8.772],
                                  [117.597, 77.51],
                                  [-38.875, 133.588],
                                  [-10.902, 92],
                                ],
                                o: [
                                  [-95.163, 41.358],
                                  [20.44, 118.385],
                                  [-49.036, 46.803],
                                  [118.45, 36.056],
                                  [19.29, 65.55],
                                  [110.388, -5.225],
                                  [-117.597, -77.51],
                                  [35.811, -136.329],
                                  [10.902, -92],
                                ],
                                v: [
                                  [-100.697, -356.629],
                                  [-155.622, -172.294],
                                  [-254.9, 22.229],
                                  [-248.566, 156.277],
                                  [-67.928, 277.182],
                                  [99.259, 402.869],
                                  [300.816, 278.311],
                                  [69.547, -60.45],
                                  [298.597, -219.455],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.361, -39.277],
                                  [-20.653, -118.625],
                                  [49.186, -46.655],
                                  [-118.717, -36.028],
                                  [-17.205, -63.094],
                                  [-126.631, 8.68],
                                  [117.958, 77.599],
                                  [-39.026, 133.894],
                                  [-10.863, 92],
                                ],
                                o: [
                                  [-95.229, 41.504],
                                  [20.653, 118.625],
                                  [-49.186, 46.655],
                                  [118.717, 36.028],
                                  [19.272, 65.639],
                                  [110.41, -5.181],
                                  [-117.958, -77.599],
                                  [36.005, -136.598],
                                  [10.863, -92],
                                ],
                                v: [
                                  [-100.322, -356.887],
                                  [-155.927, -172.56],
                                  [-254.877, 21.898],
                                  [-249.121, 156.43],
                                  [-68.085, 277.315],
                                  [100.12, 403.666],
                                  [300.677, 278.47],
                                  [69.449, -60.985],
                                  [298.69, -220.443],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.274, -39.451],
                                  [-20.863, -118.863],
                                  [49.334, -46.509],
                                  [-118.981, -36],
                                  [-17.216, -63.216],
                                  [-126.431, 8.588],
                                  [118.315, 77.686],
                                  [-39.177, 134.197],
                                  [-10.823, 92],
                                ],
                                o: [
                                  [-95.294, 41.647],
                                  [20.863, 118.863],
                                  [-49.334, 46.509],
                                  [118.981, 36],
                                  [19.255, 65.726],
                                  [110.431, -5.137],
                                  [-118.315, -77.686],
                                  [36.197, -136.863],
                                  [10.823, -92],
                                ],
                                v: [
                                  [-99.952, -357.182],
                                  [-156.228, -172.822],
                                  [-254.8, 21.615],
                                  [-249.743, 156.532],
                                  [-68.236, 277.485],
                                  [101.038, 404.459],
                                  [300.496, 278.629],
                                  [69.368, -61.528],
                                  [298.776, -221.377],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.188, -39.624],
                                  [-21.071, -119.099],
                                  [49.48, -46.365],
                                  [-119.242, -35.972],
                                  [-17.226, -63.337],
                                  [-126.233, 8.498],
                                  [118.667, 77.773],
                                  [-39.325, 134.496],
                                  [-10.785, 92],
                                ],
                                o: [
                                  [-95.359, 41.79],
                                  [21.071, 119.099],
                                  [-49.48, 46.365],
                                  [119.242, 35.972],
                                  [19.238, 65.812],
                                  [110.453, -5.094],
                                  [-118.667, -77.773],
                                  [36.386, -137.126],
                                  [10.785, -92],
                                ],
                                v: [
                                  [-99.576, -357.428],
                                  [-156.578, -173.098],
                                  [-254.714, 21.264],
                                  [-250.369, 156.688],
                                  [-68.296, 277.608],
                                  [101.961, 405.203],
                                  [300.317, 278.787],
                                  [69.336, -62.121],
                                  [298.81, -222.362],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.103, -39.794],
                                  [-21.277, -119.331],
                                  [49.625, -46.222],
                                  [-119.501, -35.945],
                                  [-17.237, -63.456],
                                  [-126.037, 8.408],
                                  [119.016, 77.859],
                                  [-39.472, 134.792],
                                  [-10.746, 92],
                                ],
                                o: [
                                  [-95.423, 41.93],
                                  [21.277, 119.331],
                                  [-49.625, 46.222],
                                  [119.501, 35.945],
                                  [19.221, 65.897],
                                  [110.474, -5.051],
                                  [-119.016, -77.859],
                                  [36.574, -137.386],
                                  [10.746, -92],
                                ],
                                v: [
                                  [-99.203, -357.711],
                                  [-156.933, -173.422],
                                  [-254.627, 20.972],
                                  [-251.01, 156.793],
                                  [-68.297, 277.769],
                                  [102.827, 405.943],
                                  [300.15, 278.901],
                                  [69.259, -62.711],
                                  [298.836, -223.231],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.188, -39.624],
                                  [-21.071, -119.099],
                                  [49.48, -46.365],
                                  [-119.242, -35.972],
                                  [-17.226, -63.337],
                                  [-126.233, 8.498],
                                  [118.667, 77.773],
                                  [-39.325, 134.496],
                                  [-10.785, 92],
                                ],
                                o: [
                                  [-95.359, 41.79],
                                  [21.071, 119.099],
                                  [-49.48, 46.365],
                                  [119.242, 35.972],
                                  [19.238, 65.812],
                                  [110.453, -5.094],
                                  [-118.667, -77.773],
                                  [36.386, -137.126],
                                  [10.785, -92],
                                ],
                                v: [
                                  [-98.129, -357.506],
                                  [-157.276, -174.092],
                                  [-254.594, 20.173],
                                  [-251.564, 156.865],
                                  [-68.892, 277.764],
                                  [103.748, 406.282],
                                  [300.173, 278.777],
                                  [70.331, -62.529],
                                  [297.898, -223.491],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.274, -39.451],
                                  [-20.863, -118.863],
                                  [49.334, -46.509],
                                  [-118.981, -36],
                                  [-17.216, -63.216],
                                  [-126.431, 8.588],
                                  [118.315, 77.686],
                                  [-39.177, 134.197],
                                  [-10.823, 92],
                                ],
                                o: [
                                  [-95.294, 41.647],
                                  [20.863, 118.863],
                                  [-49.334, 46.509],
                                  [118.981, 36],
                                  [19.255, 65.726],
                                  [110.431, -5.137],
                                  [-118.315, -77.687],
                                  [36.196, -136.863],
                                  [10.823, -92],
                                ],
                                v: [
                                  [-97.062, -357.344],
                                  [-157.632, -174.805],
                                  [-254.615, 19.373],
                                  [-252.113, 156.873],
                                  [-69.437, 277.814],
                                  [104.674, 406.603],
                                  [300.197, 278.705],
                                  [71.408, -62.392],
                                  [296.904, -223.682],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.361, -39.277],
                                  [-20.653, -118.625],
                                  [49.186, -46.655],
                                  [-118.717, -36.028],
                                  [-17.205, -63.094],
                                  [-126.631, 8.68],
                                  [117.958, 77.599],
                                  [-39.026, 133.894],
                                  [-10.863, 92],
                                ],
                                o: [
                                  [-95.229, 41.504],
                                  [20.653, 118.625],
                                  [-49.186, 46.655],
                                  [118.717, 36.028],
                                  [19.272, 65.639],
                                  [110.41, -5.181],
                                  [-117.958, -77.599],
                                  [36.005, -136.598],
                                  [10.863, -92],
                                ],
                                v: [
                                  [-96.002, -357.191],
                                  [-157.992, -175.464],
                                  [-254.523, 18.561],
                                  [-252.655, 156.898],
                                  [-69.87, 277.857],
                                  [105.586, 406.863],
                                  [300.189, 278.578],
                                  [72.543, -62.248],
                                  [295.919, -223.824],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.45, -39.101],
                                  [-20.44, -118.385],
                                  [49.036, -46.803],
                                  [-118.45, -36.056],
                                  [-17.194, -62.971],
                                  [-126.834, 8.772],
                                  [117.597, 77.51],
                                  [-38.874, 133.588],
                                  [-10.902, 92],
                                ],
                                o: [
                                  [-95.163, 41.358],
                                  [20.44, 118.385],
                                  [-49.036, 46.803],
                                  [118.45, 36.056],
                                  [19.29, 65.55],
                                  [110.388, -5.225],
                                  [-117.597, -77.51],
                                  [35.811, -136.329],
                                  [10.902, -92],
                                ],
                                v: [
                                  [-94.94, -357.028],
                                  [-158.47, -176.175],
                                  [-254.495, 17.809],
                                  [-253.254, 156.911],
                                  [-70.318, 277.913],
                                  [106.441, 407.051],
                                  [300.173, 278.418],
                                  [73.682, -62.159],
                                  [294.931, -223.95],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.539, -38.923],
                                  [-20.226, -118.141],
                                  [48.884, -46.952],
                                  [-118.18, -36.084],
                                  [-17.183, -62.846],
                                  [-127.039, 8.866],
                                  [117.233, 77.42],
                                  [-38.721, 133.278],
                                  [-10.942, 92],
                                ],
                                o: [
                                  [-95.096, 41.211],
                                  [20.226, 118.141],
                                  [-48.884, 46.952],
                                  [118.18, 36.084],
                                  [19.308, 65.461],
                                  [110.365, -5.269],
                                  [-117.233, -77.421],
                                  [35.615, -136.057],
                                  [10.942, -92],
                                ],
                                v: [
                                  [-93.928, -356.927],
                                  [-158.9, -176.885],
                                  [-254.458, 17.055],
                                  [-253.836, 156.931],
                                  [-70.716, 277.962],
                                  [107.334, 407.241],
                                  [300.168, 278.3],
                                  [74.816, -62.064],
                                  [293.889, -224.059],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.629, -38.743],
                                  [-20.008, -117.895],
                                  [48.731, -47.103],
                                  [-117.907, -36.113],
                                  [-17.171, -62.72],
                                  [-127.246, 8.96],
                                  [116.864, 77.33],
                                  [-38.566, 132.965],
                                  [-10.983, 92],
                                ],
                                o: [
                                  [-95.029, 41.063],
                                  [20.008, 117.895],
                                  [-48.731, 47.103],
                                  [117.907, 36.113],
                                  [19.326, 65.371],
                                  [110.343, -5.314],
                                  [-116.864, -77.33],
                                  [35.417, -135.783],
                                  [10.983, -92],
                                ],
                                v: [
                                  [-92.924, -356.816],
                                  [-159.374, -177.542],
                                  [-254.371, 16.34],
                                  [-254.473, 156.898],
                                  [-71.057, 278.024],
                                  [108.169, 407.359],
                                  [300.176, 278.19],
                                  [75.913, -62.065],
                                  [292.908, -224.12],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.72, -38.561],
                                  [-19.789, -117.647],
                                  [48.577, -47.255],
                                  [-117.631, -36.142],
                                  [-17.16, -62.592],
                                  [-127.455, 9.056],
                                  [116.491, 77.238],
                                  [-38.409, 132.649],
                                  [-11.024, 92],
                                ],
                                o: [
                                  [-94.96, 40.912],
                                  [19.789, 117.647],
                                  [-48.577, 47.256],
                                  [117.631, 36.142],
                                  [19.344, 65.28],
                                  [110.32, -5.36],
                                  [-116.491, -77.238],
                                  [35.217, -135.505],
                                  [11.024, -92],
                                ],
                                v: [
                                  [-91.959, -356.767],
                                  [-159.873, -178.198],
                                  [-254.285, 15.622],
                                  [-255.042, 156.863],
                                  [-71.36, 278.13],
                                  [108.947, 407.427],
                                  [300.185, 278.038],
                                  [77.005, -62.016],
                                  [291.822, -224.164],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.812, -38.377],
                                  [-19.567, -117.395],
                                  [48.42, -47.41],
                                  [-117.352, -36.171],
                                  [-17.149, -62.464],
                                  [-127.667, 9.152],
                                  [116.114, 77.146],
                                  [-38.25, 132.329],
                                  [-11.065, 92],
                                ],
                                o: [
                                  [-94.891, 40.761],
                                  [19.567, 117.395],
                                  [-48.42, 47.41],
                                  [117.352, 36.171],
                                  [19.362, 65.188],
                                  [110.297, -5.406],
                                  [-116.114, -77.146],
                                  [35.014, -135.224],
                                  [11.065, -92],
                                ],
                                v: [
                                  [-91.013, -356.717],
                                  [-160.417, -178.854],
                                  [-254.15, 14.954],
                                  [-255.666, 156.837],
                                  [-71.606, 278.229],
                                  [109.773, 407.476],
                                  [300.207, 277.886],
                                  [78.102, -62.064],
                                  [290.787, -224.139],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.905, -38.19],
                                  [-19.342, -117.141],
                                  [48.262, -47.566],
                                  [-117.07, -36.201],
                                  [-17.137, -62.333],
                                  [-127.881, 9.25],
                                  [115.734, 77.052],
                                  [-38.089, 132.006],
                                  [-11.107, 92],
                                ],
                                o: [
                                  [-94.821, 40.607],
                                  [19.342, 117.141],
                                  [-48.262, 47.565],
                                  [117.07, 36.201],
                                  [19.381, 65.095],
                                  [110.274, -5.452],
                                  [-115.734, -77.052],
                                  [34.809, -134.94],
                                  [11.107, -92],
                                ],
                                v: [
                                  [-90.105, -356.667],
                                  [-161.026, -179.498],
                                  [-254.057, 14.282],
                                  [-256.282, 156.809],
                                  [-71.867, 278.34],
                                  [110.48, 407.465],
                                  [300.231, 277.796],
                                  [79.141, -62.114],
                                  [289.709, -224.107],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.999, -38.002],
                                  [-19.115, -116.884],
                                  [48.102, -47.723],
                                  [-116.785, -36.231],
                                  [-17.125, -62.202],
                                  [-128.097, 9.349],
                                  [115.348, 76.957],
                                  [-37.927, 131.679],
                                  [-11.149, 92],
                                ],
                                o: [
                                  [-94.751, 40.452],
                                  [19.115, 116.884],
                                  [-48.102, 47.723],
                                  [116.785, 36.231],
                                  [19.4, 65.001],
                                  [110.25, -5.499],
                                  [-115.348, -76.957],
                                  [34.603, -134.654],
                                  [11.15, -92],
                                ],
                                v: [
                                  [-89.205, -356.667],
                                  [-161.588, -180.101],
                                  [-253.926, 13.699],
                                  [-256.892, 156.74],
                                  [-72.03, 278.495],
                                  [111.233, 407.497],
                                  [300.269, 277.666],
                                  [80.144, -62.156],
                                  [288.631, -224.017],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.094, -37.812],
                                  [-18.886, -116.625],
                                  [47.94, -47.882],
                                  [-116.497, -36.261],
                                  [-17.113, -62.069],
                                  [-128.316, 9.449],
                                  [114.959, 76.862],
                                  [-37.763, 131.349],
                                  [-11.192, 92],
                                ],
                                o: [
                                  [-94.68, 40.295],
                                  [18.886, 116.625],
                                  [-47.94, 47.882],
                                  [116.497, 36.261],
                                  [19.419, 64.906],
                                  [110.227, -5.547],
                                  [-114.959, -76.862],
                                  [34.393, -134.364],
                                  [11.192, -92],
                                ],
                                v: [
                                  [-88.314, -356.667],
                                  [-162.193, -180.703],
                                  [-253.785, 13.072],
                                  [-257.557, 156.669],
                                  [-72.137, 278.652],
                                  [111.918, 407.468],
                                  [300.269, 277.524],
                                  [81.194, -62.304],
                                  [287.553, -223.921],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.19, -37.62],
                                  [-18.654, -116.362],
                                  [47.777, -48.043],
                                  [-116.205, -36.292],
                                  [-17.101, -61.934],
                                  [-128.537, 9.549],
                                  [114.566, 76.765],
                                  [-37.597, 131.015],
                                  [-11.235, 92],
                                ],
                                o: [
                                  [-94.607, 40.136],
                                  [18.654, 116.362],
                                  [-47.777, 48.043],
                                  [116.205, 36.292],
                                  [19.438, 64.81],
                                  [110.202, -5.595],
                                  [-114.566, -76.765],
                                  [34.182, -134.07],
                                  [11.236, -92],
                                ],
                                v: [
                                  [-87.46, -356.718],
                                  [-162.864, -181.304],
                                  [-253.658, 12.543],
                                  [-258.151, 156.546],
                                  [-72.21, 278.81],
                                  [112.61, 407.379],
                                  [300.323, 277.405],
                                  [82.146, -62.381],
                                  [286.485, -223.755],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.287, -37.426],
                                  [-18.42, -116.097],
                                  [47.612, -48.206],
                                  [-115.911, -36.323],
                                  [-17.089, -61.798],
                                  [-128.761, 9.652],
                                  [114.168, 76.667],
                                  [-37.43, 130.677],
                                  [-11.279, 92],
                                ],
                                o: [
                                  [-94.535, 39.976],
                                  [18.42, 116.097],
                                  [-47.612, 48.206],
                                  [115.911, 36.323],
                                  [19.457, 64.713],
                                  [110.178, -5.644],
                                  [-114.168, -76.667],
                                  [33.968, -133.774],
                                  [11.279, -92],
                                ],
                                v: [
                                  [-86.666, -356.767],
                                  [-163.487, -181.843],
                                  [-253.47, 11.999],
                                  [-258.801, 156.383],
                                  [-72.278, 278.96],
                                  [113.284, 407.332],
                                  [300.392, 277.236],
                                  [83.143, -62.574],
                                  [285.366, -223.521],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.385, -37.229],
                                  [-18.183, -115.829],
                                  [47.445, -48.371],
                                  [-115.613, -36.354],
                                  [-17.077, -61.66],
                                  [-128.986, 9.755],
                                  [113.766, 76.568],
                                  [-37.26, 130.336],
                                  [-11.323, 92],
                                ],
                                o: [
                                  [-94.461, 39.814],
                                  [18.183, 115.829],
                                  [-47.445, 48.37],
                                  [115.613, 36.354],
                                  [19.477, 64.615],
                                  [110.154, -5.693],
                                  [-113.766, -76.568],
                                  [33.752, -133.475],
                                  [11.323, -92],
                                ],
                                v: [
                                  [-85.869, -356.866],
                                  [-164.113, -182.443],
                                  [-253.336, 11.45],
                                  [-259.432, 156.22],
                                  [-72.262, 279.1],
                                  [113.891, 407.226],
                                  [300.413, 277.118],
                                  [84.106, -62.759],
                                  [284.258, -223.331],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.485, -37.031],
                                  [-17.943, -115.558],
                                  [47.276, -48.537],
                                  [-115.312, -36.386],
                                  [-17.064, -61.521],
                                  [-129.215, 9.859],
                                  [113.36, 76.469],
                                  [-37.089, 129.991],
                                  [-11.368, 92],
                                ],
                                o: [
                                  [-94.387, 39.65],
                                  [17.943, 115.558],
                                  [-47.276, 48.537],
                                  [115.312, 36.386],
                                  [19.497, 64.515],
                                  [110.129, -5.742],
                                  [-113.36, -76.469],
                                  [33.534, -133.172],
                                  [11.368, -92],
                                ],
                                v: [
                                  [-85.131, -356.964],
                                  [-164.743, -183.094],
                                  [-253.165, 11.01],
                                  [-260.066, 156.068],
                                  [-72.191, 279.293],
                                  [114.504, 407.122],
                                  [300.513, 277.013],
                                  [85.012, -62.934],
                                  [283.152, -223.082],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.585, -36.83],
                                  [-17.701, -115.284],
                                  [47.106, -48.705],
                                  [-115.008, -36.418],
                                  [-17.052, -61.381],
                                  [-129.445, 9.964],
                                  [112.949, 76.368],
                                  [-36.916, 129.642],
                                  [-11.413, 92],
                                ],
                                o: [
                                  [-94.311, 39.485],
                                  [17.701, 115.284],
                                  [-47.106, 48.705],
                                  [115.008, 36.418],
                                  [19.517, 64.415],
                                  [110.104, -5.792],
                                  [-112.949, -76.368],
                                  [33.313, -132.866],
                                  [11.413, -92],
                                ],
                                v: [
                                  [-84.442, -357.111],
                                  [-165.377, -183.693],
                                  [-252.984, 10.554],
                                  [-260.642, 155.866],
                                  [-72.14, 279.487],
                                  [115.1, 406.998],
                                  [300.616, 276.858],
                                  [85.924, -63.164],
                                  [282.096, -222.866],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.686, -36.627],
                                  [-17.457, -115.007],
                                  [46.933, -48.875],
                                  [-114.701, -36.45],
                                  [-17.039, -61.239],
                                  [-129.679, 10.071],
                                  [112.534, 76.266],
                                  [-36.741, 129.29],
                                  [-11.459, 92],
                                ],
                                o: [
                                  [-94.235, 39.318],
                                  [17.457, 115.007],
                                  [-46.933, 48.875],
                                  [114.701, 36.45],
                                  [19.537, 64.314],
                                  [110.078, -5.843],
                                  [-112.534, -76.266],
                                  [33.09, -132.557],
                                  [11.459, -92],
                                ],
                                v: [
                                  [-83.749, -357.257],
                                  [-165.964, -184.341],
                                  [-252.806, 10.142],
                                  [-261.26, 155.614],
                                  [-71.983, 279.67],
                                  [115.628, 406.826],
                                  [300.723, 276.768],
                                  [86.78, -63.435],
                                  [281.002, -222.592],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.789, -36.422],
                                  [-17.209, -114.727],
                                  [46.759, -49.046],
                                  [-114.39, -36.483],
                                  [-17.026, -61.096],
                                  [-129.914, 10.178],
                                  [112.115, 76.162],
                                  [-36.564, 128.934],
                                  [-11.505, 92],
                                ],
                                o: [
                                  [-94.158, 39.148],
                                  [17.209, 114.727],
                                  [-46.759, 49.046],
                                  [114.39, 36.483],
                                  [19.558, 64.211],
                                  [110.053, -5.894],
                                  [-112.115, -76.162],
                                  [32.865, -132.244],
                                  [11.505, -92],
                                ],
                                v: [
                                  [-83.103, -357.4],
                                  [-166.555, -184.989],
                                  [-252.579, 9.737],
                                  [-261.883, 155.375],
                                  [-71.847, 279.843],
                                  [116.162, 406.696],
                                  [300.847, 276.678],
                                  [87.653, -63.696],
                                  [279.91, -222.361],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.892, -36.215],
                                  [-16.96, -114.444],
                                  [46.583, -49.22],
                                  [-114.076, -36.516],
                                  [-17.013, -60.951],
                                  [-130.152, 10.287],
                                  [111.691, 76.058],
                                  [-36.386, 128.574],
                                  [-11.552, 92],
                                ],
                                o: [
                                  [-94.081, 38.978],
                                  [16.96, 114.444],
                                  [-46.583, 49.22],
                                  [114.076, 36.516],
                                  [19.578, 64.108],
                                  [110.027, -5.946],
                                  [-111.691, -76.058],
                                  [32.637, -131.928],
                                  [11.552, -92],
                                ],
                                v: [
                                  [-82.503, -357.605],
                                  [-167.15, -185.636],
                                  [-252.406, 9.364],
                                  [-262.435, 155.15],
                                  [-71.607, 280.066],
                                  [116.628, 406.456],
                                  [300.988, 276.54],
                                  [88.521, -63.96],
                                  [278.83, -222.059],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.997, -36.006],
                                  [-16.707, -114.158],
                                  [46.405, -49.395],
                                  [-113.759, -36.549],
                                  [-17, -60.804],
                                  [-130.393, 10.397],
                                  [111.262, 75.953],
                                  [-36.205, 128.21],
                                  [-11.599, 92],
                                ],
                                o: [
                                  [-94.002, 38.805],
                                  [16.707, 114.158],
                                  [-46.405, 49.395],
                                  [113.759, 36.549],
                                  [19.599, 64.003],
                                  [110.001, -5.999],
                                  [-111.262, -75.953],
                                  [32.407, -131.609],
                                  [11.599, -92],
                                ],
                                v: [
                                  [-81.962, -357.846],
                                  [-167.737, -186.282],
                                  [-252.185, 9.047],
                                  [-263.042, 154.877],
                                  [-71.377, 280.315],
                                  [117.088, 406.258],
                                  [301.121, 276.466],
                                  [89.281, -64.278],
                                  [277.79, -221.8],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.103, -35.794],
                                  [-16.452, -113.869],
                                  [46.225, -49.572],
                                  [-113.438, -36.583],
                                  [-16.987, -60.656],
                                  [-130.637, 10.508],
                                  [110.829, 75.847],
                                  [-36.023, 127.843],
                                  [-11.646, 92],
                                ],
                                o: [
                                  [-93.923, 38.63],
                                  [16.452, 113.869],
                                  [-46.225, 49.572],
                                  [113.438, 36.583],
                                  [19.621, 63.897],
                                  [109.974, -6.051],
                                  [-110.829, -75.846],
                                  [32.174, -131.286],
                                  [11.646, -92],
                                ],
                                v: [
                                  [-81.418, -358.084],
                                  [-168.341, -186.926],
                                  [-251.98, 8.762],
                                  [-263.59, 154.618],
                                  [-71.168, 280.513],
                                  [117.542, 406.014],
                                  [301.322, 276.332],
                                  [90.111, -64.586],
                                  [276.714, -221.482],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.21, -35.581],
                                  [-16.194, -113.577],
                                  [46.044, -49.751],
                                  [-113.114, -36.617],
                                  [-16.974, -60.506],
                                  [-130.882, 10.62],
                                  [110.392, 75.739],
                                  [-35.838, 127.471],
                                  [-11.694, 92],
                                ],
                                o: [
                                  [-93.843, 38.454],
                                  [16.194, 113.577],
                                  [-46.044, 49.751],
                                  [113.114, 36.617],
                                  [19.642, 63.79],
                                  [109.948, -6.105],
                                  [-110.391, -75.739],
                                  [31.939, -130.96],
                                  [11.694, -92],
                                ],
                                v: [
                                  [-80.918, -358.382],
                                  [-168.886, -187.619],
                                  [-251.765, 8.482],
                                  [-264.131, 154.361],
                                  [-70.919, 280.811],
                                  [117.978, 405.761],
                                  [301.464, 276.262],
                                  [90.885, -64.934],
                                  [275.652, -221.157],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.318, -35.365],
                                  [-15.934, -113.282],
                                  [45.86, -49.932],
                                  [-112.787, -36.651],
                                  [-16.96, -60.355],
                                  [-131.131, 10.734],
                                  [109.949, 75.63],
                                  [-35.652, 127.096],
                                  [-11.743, 92],
                                ],
                                o: [
                                  [-93.762, 38.276],
                                  [15.934, 113.282],
                                  [-45.86, 49.932],
                                  [112.787, 36.651],
                                  [19.664, 63.682],
                                  [109.921, -6.159],
                                  [-109.949, -75.63],
                                  [31.701, -130.631],
                                  [11.743, -92],
                                ],
                                v: [
                                  [-80.464, -358.665],
                                  [-169.423, -188.275],
                                  [-251.602, 8.195],
                                  [-264.664, 154.058],
                                  [-70.631, 281.082],
                                  [118.358, 405.5],
                                  [301.674, 276.144],
                                  [91.615, -65.286],
                                  [274.578, -220.873],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.427, -35.146],
                                  [-15.67, -112.984],
                                  [45.674, -50.115],
                                  [-112.456, -36.686],
                                  [-16.947, -60.202],
                                  [-131.382, 10.848],
                                  [109.503, 75.521],
                                  [-35.464, 126.717],
                                  [-11.792, 92],
                                ],
                                o: [
                                  [-93.68, 38.096],
                                  [15.67, 112.984],
                                  [-45.674, 50.115],
                                  [112.456, 36.686],
                                  [19.685, 63.573],
                                  [109.893, -6.213],
                                  [-109.503, -75.521],
                                  [31.461, -130.298],
                                  [11.792, -92],
                                ],
                                v: [
                                  [-80.005, -359.007],
                                  [-169.978, -188.965],
                                  [-251.38, 7.889],
                                  [-265.202, 153.77],
                                  [-70.354, 281.351],
                                  [118.683, 405.194],
                                  [301.939, 276.078],
                                  [92.339, -65.627],
                                  [273.52, -220.518],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.537, -34.926],
                                  [-15.404, -112.683],
                                  [45.487, -50.3],
                                  [-112.122, -36.721],
                                  [-16.933, -60.048],
                                  [-131.635, 10.964],
                                  [109.051, 75.41],
                                  [-35.273, 126.333],
                                  [-11.842, 92],
                                ],
                                o: [
                                  [-93.597, 37.914],
                                  [15.404, 112.683],
                                  [-45.487, 50.3],
                                  [112.122, 36.721],
                                  [19.707, 63.463],
                                  [109.866, -6.269],
                                  [-109.051, -75.409],
                                  [31.218, -129.962],
                                  [11.842, -92],
                                ],
                                v: [
                                  [-79.555, -359.334],
                                  [-170.474, -189.618],
                                  [-251.209, 7.637],
                                  [-265.718, 153.45],
                                  [-70.039, 281.655],
                                  [119.05, 404.88],
                                  [302.159, 275.965],
                                  [93.021, -66.021],
                                  [272.514, -220.155],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.649, -34.703],
                                  [-15.135, -112.379],
                                  [45.297, -50.486],
                                  [-111.784, -36.757],
                                  [-16.919, -59.892],
                                  [-131.892, 11.081],
                                  [108.595, 75.297],
                                  [-35.081, 125.946],
                                  [-11.892, 92],
                                ],
                                o: [
                                  [-93.514, 37.73],
                                  [15.135, 112.379],
                                  [-45.297, 50.486],
                                  [111.784, 36.757],
                                  [19.73, 63.351],
                                  [109.838, -6.324],
                                  [-108.595, -75.297],
                                  [30.973, -129.622],
                                  [11.892, -92],
                                ],
                                v: [
                                  [-79.113, -359.693],
                                  [-170.975, -190.319],
                                  [-250.992, 7.365],
                                  [-266.191, 153.133],
                                  [-69.748, 281.943],
                                  [119.35, 404.571],
                                  [302.383, 275.855],
                                  [93.709, -66.392],
                                  [271.46, -219.782],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.761, -34.478],
                                  [-14.864, -112.071],
                                  [45.106, -50.675],
                                  [-111.443, -36.793],
                                  [-16.905, -59.734],
                                  [-132.151, 11.199],
                                  [108.134, 75.184],
                                  [-34.887, 125.555],
                                  [-11.943, 92],
                                ],
                                o: [
                                  [-93.429, 37.544],
                                  [14.864, 112.071],
                                  [-45.106, 50.675],
                                  [111.443, 36.793],
                                  [19.752, 63.239],
                                  [109.81, -6.381],
                                  [-108.134, -75.184],
                                  [30.725, -129.278],
                                  [11.943, -92],
                                ],
                                v: [
                                  [-78.682, -360.034],
                                  [-171.48, -190.97],
                                  [-250.826, 7.099],
                                  [-266.656, 152.835],
                                  [-69.456, 282.228],
                                  [119.594, 404.206],
                                  [302.662, 275.796],
                                  [94.356, -66.766],
                                  [270.408, -219.45],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.875, -34.25],
                                  [-14.589, -111.76],
                                  [44.913, -50.866],
                                  [-111.098, -36.829],
                                  [-16.891, -59.575],
                                  [-132.412, 11.319],
                                  [107.668, 75.07],
                                  [-34.691, 125.16],
                                  [-11.994, 92],
                                ],
                                o: [
                                  [-93.344, 37.356],
                                  [14.589, 111.76],
                                  [-44.913, 50.866],
                                  [111.098, 36.829],
                                  [19.775, 63.125],
                                  [109.781, -6.438],
                                  [-107.668, -75.07],
                                  [30.475, -128.931],
                                  [11.994, -92],
                                ],
                                v: [
                                  [-78.26, -360.406],
                                  [-171.977, -191.669],
                                  [-250.648, 6.811],
                                  [-267.064, 152.541],
                                  [-69.14, 282.545],
                                  [119.88, 403.833],
                                  [302.946, 275.69],
                                  [94.961, -67.179],
                                  [269.373, -219.06],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.99, -34.02],
                                  [-14.312, -111.446],
                                  [44.717, -51.058],
                                  [-110.749, -36.865],
                                  [-16.876, -59.414],
                                  [-132.677, 11.439],
                                  [107.198, 74.954],
                                  [-34.492, 124.76],
                                  [-12.045, 92],
                                ],
                                o: [
                                  [-93.258, 37.167],
                                  [14.312, 111.446],
                                  [-44.717, 51.058],
                                  [110.749, 36.866],
                                  [19.798, 63.01],
                                  [109.753, -6.495],
                                  [-107.198, -74.954],
                                  [30.222, -128.581],
                                  [12.045, -92],
                                ],
                                v: [
                                  [-77.883, -360.821],
                                  [-172.464, -192.331],
                                  [-250.521, 6.564],
                                  [-267.479, 152.217],
                                  [-68.837, 282.845],
                                  [120.126, 403.466],
                                  [303.297, 275.586],
                                  [95.608, -67.567],
                                  [268.341, -218.661],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.106, -33.788],
                                  [-14.032, -111.129],
                                  [44.52, -51.253],
                                  [-110.397, -36.903],
                                  [-16.862, -59.252],
                                  [-132.944, 11.561],
                                  [106.722, 74.837],
                                  [-34.292, 124.357],
                                  [-12.098, 92],
                                ],
                                o: [
                                  [-93.17, 36.975],
                                  [14.032, 111.129],
                                  [-44.52, 51.253],
                                  [110.397, 36.903],
                                  [19.821, 62.894],
                                  [109.723, -6.553],
                                  [-106.722, -74.837],
                                  [29.967, -128.227],
                                  [12.098, -92],
                                ],
                                v: [
                                  [-77.531, -361.203],
                                  [-172.921, -193.028],
                                  [-250.334, 6.323],
                                  [-267.872, 151.898],
                                  [-68.498, 283.142],
                                  [120.303, 403.077],
                                  [303.59, 275.5],
                                  [96.181, -67.944],
                                  [267.345, -218.204],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.223, -33.553],
                                  [-13.749, -110.809],
                                  [44.32, -51.449],
                                  [-110.042, -36.94],
                                  [-16.847, -59.087],
                                  [-133.214, 11.685],
                                  [106.242, 74.719],
                                  [-34.09, 123.949],
                                  [-12.151, 92],
                                ],
                                o: [
                                  [-93.083, 36.781],
                                  [13.749, 110.809],
                                  [-44.32, 51.449],
                                  [110.042, 36.94],
                                  [19.845, 62.777],
                                  [109.694, -6.612],
                                  [-106.242, -74.719],
                                  [29.709, -127.869],
                                  [12.151, -92],
                                ],
                                v: [
                                  [-77.175, -361.578],
                                  [-173.354, -193.689],
                                  [-250.21, 6.059],
                                  [-268.224, 151.599],
                                  [-68.172, 283.467],
                                  [120.537, 402.647],
                                  [303.936, 275.45],
                                  [96.747, -68.295],
                                  [266.319, -217.787],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.342, -33.316],
                                  [-13.463, -110.485],
                                  [44.119, -51.648],
                                  [-109.682, -36.978],
                                  [-16.832, -58.921],
                                  [-133.486, 11.809],
                                  [105.757, 74.6],
                                  [-33.885, 123.537],
                                  [-12.204, 92],
                                ],
                                o: [
                                  [-92.994, 36.586],
                                  [13.463, 110.485],
                                  [-44.119, 51.648],
                                  [109.682, 36.978],
                                  [19.868, 62.658],
                                  [109.664, -6.671],
                                  [-105.757, -74.6],
                                  [29.448, -127.507],
                                  [12.204, -92],
                                ],
                                v: [
                                  [-76.831, -361.995],
                                  [-173.758, -194.349],
                                  [-250.074, 5.801],
                                  [-268.569, 151.258],
                                  [-67.86, 283.726],
                                  [120.717, 402.21],
                                  [304.288, 275.355],
                                  [97.273, -68.697],
                                  [265.295, -217.36],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.462, -33.077],
                                  [-13.174, -110.158],
                                  [43.915, -51.848],
                                  [-109.319, -37.016],
                                  [-16.817, -58.754],
                                  [-133.762, 11.935],
                                  [105.266, 74.479],
                                  [-33.679, 123.121],
                                  [-12.258, 92],
                                ],
                                o: [
                                  [-92.904, 36.388],
                                  [13.174, 110.158],
                                  [-43.915, 51.848],
                                  [109.319, 37.016],
                                  [19.892, 62.538],
                                  [109.635, -6.731],
                                  [-105.266, -74.479],
                                  [29.184, -127.142],
                                  [12.258, -92],
                                ],
                                v: [
                                  [-76.547, -362.423],
                                  [-174.201, -195.008],
                                  [-249.924, 5.568],
                                  [-268.873, 150.971],
                                  [-67.561, 284.028],
                                  [120.906, 401.75],
                                  [304.691, 275.263],
                                  [97.822, -69.072],
                                  [264.322, -216.924],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.583, -32.835],
                                  [-12.882, -109.827],
                                  [43.709, -52.051],
                                  [-108.953, -37.054],
                                  [-16.802, -58.584],
                                  [-134.04, 12.062],
                                  [104.771, 74.357],
                                  [-33.47, 122.7],
                                  [-12.312, 92],
                                ],
                                o: [
                                  [-92.813, 36.189],
                                  [12.882, 109.827],
                                  [-43.709, 52.051],
                                  [108.953, 37.055],
                                  [19.917, 62.417],
                                  [109.604, -6.791],
                                  [-104.771, -74.357],
                                  [28.918, -126.773],
                                  [12.312, -92],
                                ],
                                v: [
                                  [-76.274, -362.844],
                                  [-174.585, -195.682],
                                  [-249.838, 5.341],
                                  [-269.17, 150.657],
                                  [-67.243, 284.31],
                                  [121.041, 401.313],
                                  [305.037, 275.141],
                                  [98.365, -69.435],
                                  [263.305, -216.431],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.705, -32.59],
                                  [-12.587, -109.494],
                                  [43.502, -52.256],
                                  [-108.582, -37.093],
                                  [-16.787, -58.413],
                                  [-134.321, 12.19],
                                  [104.271, 74.234],
                                  [-33.259, 122.276],
                                  [-12.367, 92],
                                ],
                                o: [
                                  [-92.721, 35.987],
                                  [12.587, 109.494],
                                  [-43.502, 52.256],
                                  [108.582, 37.093],
                                  [19.941, 62.295],
                                  [109.574, -6.852],
                                  [-104.271, -74.234],
                                  [28.649, -126.4],
                                  [12.367, -92],
                                ],
                                v: [
                                  [-76.015, -363.241],
                                  [-174.927, -196.34],
                                  [-249.738, 5.105],
                                  [-269.414, 150.35],
                                  [-66.925, 284.586],
                                  [121.172, 400.806],
                                  [305.449, 275.054],
                                  [98.823, -69.786],
                                  [262.339, -215.976],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.828, -32.343],
                                  [-12.289, -109.156],
                                  [43.292, -52.462],
                                  [-108.208, -37.133],
                                  [-16.771, -58.24],
                                  [-134.605, 12.32],
                                  [103.765, 74.11],
                                  [-33.046, 121.847],
                                  [-12.423, 92],
                                ],
                                o: [
                                  [-92.629, 35.783],
                                  [12.289, 109.156],
                                  [-43.292, 52.462],
                                  [108.208, 37.133],
                                  [19.966, 62.172],
                                  [109.543, -6.914],
                                  [-103.765, -74.11],
                                  [28.378, -126.024],
                                  [12.423, -92],
                                ],
                                v: [
                                  [-75.8, -363.614],
                                  [-175.336, -196.965],
                                  [-249.638, 4.859],
                                  [-269.667, 150.019],
                                  [-66.684, 284.841],
                                  [121.313, 400.306],
                                  [305.914, 274.971],
                                  [99.336, -70.124],
                                  [261.36, -215.465],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.953, -32.094],
                                  [-11.988, -108.816],
                                  [43.08, -52.671],
                                  [-107.83, -37.173],
                                  [-16.756, -58.066],
                                  [-134.892, 12.451],
                                  [103.255, 73.985],
                                  [-32.831, 121.413],
                                  [-12.479, 92],
                                ],
                                o: [
                                  [-92.535, 35.577],
                                  [11.988, 108.816],
                                  [-43.08, 52.671],
                                  [107.83, 37.173],
                                  [19.991, 62.047],
                                  [109.512, -6.977],
                                  [-103.255, -73.985],
                                  [28.103, -125.643],
                                  [12.479, -92],
                                ],
                                v: [
                                  [-75.599, -364.01],
                                  [-175.687, -197.637],
                                  [-249.586, 4.652],
                                  [-269.866, 149.727],
                                  [-66.394, 285.121],
                                  [121.401, 399.8],
                                  [306.32, 274.891],
                                  [99.765, -70.496],
                                  [260.353, -214.943],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.079, -31.842],
                                  [-11.684, -108.472],
                                  [42.866, -52.882],
                                  [-107.448, -37.213],
                                  [-16.74, -57.889],
                                  [-135.182, 12.583],
                                  [102.739, 73.858],
                                  [-32.614, 120.975],
                                  [-12.536, 92],
                                ],
                                o: [
                                  [-92.441, 35.37],
                                  [11.684, 108.472],
                                  [-42.866, 52.882],
                                  [107.448, 37.213],
                                  [20.016, 61.921],
                                  [109.48, -7.04],
                                  [-102.739, -73.858],
                                  [27.826, -125.259],
                                  [12.536, -92],
                                ],
                                v: [
                                  [-75.396, -364.396],
                                  [-175.981, -198.278],
                                  [-249.534, 4.388],
                                  [-270.06, 149.473],
                                  [-66.136, 285.348],
                                  [121.532, 399.241],
                                  [306.776, 274.766],
                                  [100.235, -70.792],
                                  [259.396, -214.413],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.206, -31.587],
                                  [-11.377, -108.124],
                                  [42.649, -53.095],
                                  [-107.062, -37.253],
                                  [-16.724, -57.711],
                                  [-135.474, 12.717],
                                  [102.218, 73.73],
                                  [-32.394, 120.533],
                                  [-12.593, 92],
                                ],
                                o: [
                                  [-92.345, 35.16],
                                  [11.377, 108.124],
                                  [-42.649, 53.095],
                                  [107.062, 37.253],
                                  [20.041, 61.794],
                                  [109.448, -7.103],
                                  [-102.218, -73.73],
                                  [27.546, -124.871],
                                  [12.593, -92],
                                ],
                                v: [
                                  [-75.253, -364.818],
                                  [-176.343, -198.902],
                                  [-249.467, 4.178],
                                  [-270.217, 149.166],
                                  [-65.846, 285.614],
                                  [121.628, 398.69],
                                  [307.237, 274.676],
                                  [100.637, -71.122],
                                  [258.443, -213.888],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.335, -31.33],
                                  [-11.067, -107.773],
                                  [42.431, -53.311],
                                  [-106.673, -37.294],
                                  [-16.708, -57.531],
                                  [-135.77, 12.852],
                                  [101.692, 73.601],
                                  [-32.172, 120.087],
                                  [-12.651, 92],
                                ],
                                o: [
                                  [-92.249, 34.948],
                                  [11.067, 107.773],
                                  [-42.431, 53.311],
                                  [106.673, 37.294],
                                  [20.067, 61.665],
                                  [109.416, -7.167],
                                  [-101.692, -73.601],
                                  [27.263, -124.479],
                                  [12.651, -92],
                                ],
                                v: [
                                  [-75.124, -365.151],
                                  [-176.648, -199.497],
                                  [-249.445, 3.942],
                                  [-270.322, 148.882],
                                  [-65.618, 285.811],
                                  [121.718, 398.133],
                                  [307.749, 274.559],
                                  [101.049, -71.391],
                                  [257.523, -213.354],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.465, -31.071],
                                  [-10.754, -107.419],
                                  [42.21, -53.528],
                                  [-106.279, -37.336],
                                  [-16.692, -57.35],
                                  [-136.069, 12.988],
                                  [101.16, 73.47],
                                  [-31.949, 119.635],
                                  [-12.709, 92],
                                ],
                                o: [
                                  [-92.152, 34.733],
                                  [10.754, 107.419],
                                  [-42.21, 53.528],
                                  [106.279, 37.336],
                                  [20.093, 61.535],
                                  [109.384, -7.232],
                                  [-101.161, -73.47],
                                  [26.978, -124.083],
                                  [12.709, -92],
                                ],
                                v: [
                                  [-75.039, -365.52],
                                  [-176.958, -200.108],
                                  [-249.424, 3.758],
                                  [-270.423, 148.608],
                                  [-65.406, 286.03],
                                  [121.759, 397.587],
                                  [308.247, 274.429],
                                  [101.456, -71.692],
                                  [256.576, -212.795],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.596, -30.809],
                                  [-10.438, -107.06],
                                  [41.987, -53.748],
                                  [-105.882, -37.377],
                                  [-16.676, -57.166],
                                  [-136.37, 13.126],
                                  [100.624, 73.338],
                                  [-31.722, 119.18],
                                  [-12.768, 92],
                                ],
                                o: [
                                  [-92.053, 34.517],
                                  [10.438, 107.06],
                                  [-41.987, 53.748],
                                  [105.882, 37.377],
                                  [20.119, 61.404],
                                  [109.351, -7.298],
                                  [-100.624, -73.338],
                                  [26.689, -123.683],
                                  [12.768, -92],
                                ],
                                v: [
                                  [-74.97, -365.86],
                                  [-177.211, -200.704],
                                  [-249.401, 3.518],
                                  [-270.473, 148.342],
                                  [-65.256, 286.194],
                                  [121.811, 397.034],
                                  [308.749, 274.319],
                                  [101.844, -71.979],
                                  [255.662, -212.258],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.728, -30.544],
                                  [-10.118, -106.699],
                                  [41.762, -53.97],
                                  [-105.48, -37.42],
                                  [-16.659, -56.981],
                                  [-136.675, 13.265],
                                  [100.082, 73.205],
                                  [-31.494, 118.72],
                                  [-12.828, 92],
                                ],
                                o: [
                                  [-91.954, 34.298],
                                  [10.118, 106.699],
                                  [-41.762, 53.97],
                                  [105.48, 37.42],
                                  [20.146, 61.272],
                                  [109.318, -7.364],
                                  [-100.082, -73.205],
                                  [26.398, -123.279],
                                  [12.828, -92],
                                ],
                                v: [
                                  [-74.961, -366.188],
                                  [-177.469, -201.317],
                                  [-249.424, 3.286],
                                  [-270.49, 148.148],
                                  [-65.076, 286.397],
                                  [121.861, 396.429],
                                  [309.254, 274.195],
                                  [102.18, -72.235],
                                  [254.706, -211.65],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.862, -30.276],
                                  [-9.796, -106.333],
                                  [41.535, -54.194],
                                  [-105.075, -37.462],
                                  [-16.642, -56.793],
                                  [-136.982, 13.405],
                                  [99.534, 73.07],
                                  [-31.263, 118.255],
                                  [-12.888, 92],
                                ],
                                o: [
                                  [-91.854, 34.078],
                                  [9.796, 106.333],
                                  [-41.535, 54.194],
                                  [105.075, 37.462],
                                  [20.172, 61.138],
                                  [109.285, -7.431],
                                  [-99.534, -73.07],
                                  [26.104, -122.871],
                                  [12.888, -92],
                                ],
                                v: [
                                  [-74.933, -366.486],
                                  [-177.734, -201.886],
                                  [-249.474, 3.072],
                                  [-270.503, 147.9],
                                  [-64.912, 286.529],
                                  [121.906, 395.852],
                                  [309.807, 274.075],
                                  [102.545, -72.476],
                                  [253.798, -211.033],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.997, -30.006],
                                  [-9.47, -105.964],
                                  [41.305, -54.42],
                                  [-104.665, -37.505],
                                  [-16.625, -56.604],
                                  [-137.293, 13.547],
                                  [98.981, 72.934],
                                  [-31.03, 117.785],
                                  [-12.949, 92],
                                ],
                                o: [
                                  [-91.752, 33.855],
                                  [9.47, 105.964],
                                  [-41.305, 54.42],
                                  [104.665, 37.505],
                                  [20.199, 61.003],
                                  [109.251, -7.498],
                                  [-98.981, -72.934],
                                  [25.807, -122.459],
                                  [12.949, -92],
                                ],
                                v: [
                                  [-74.921, -366.817],
                                  [-177.958, -202.473],
                                  [-249.477, 2.866],
                                  [-270.484, 147.634],
                                  [-64.81, 286.68],
                                  [121.964, 395.27],
                                  [310.347, 273.942],
                                  [102.904, -72.656],
                                  [252.939, -210.468],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.133, -29.733],
                                  [-9.141, -105.592],
                                  [41.073, -54.649],
                                  [-104.252, -37.549],
                                  [-16.608, -56.413],
                                  [-137.607, 13.69],
                                  [98.422, 72.797],
                                  [-30.795, 117.311],
                                  [-13.01, 92],
                                ],
                                o: [
                                  [-91.65, 33.63],
                                  [9.141, 105.592],
                                  [-41.073, 54.649],
                                  [104.252, 37.549],
                                  [20.227, 60.867],
                                  [109.217, -7.567],
                                  [-98.422, -72.797],
                                  [25.506, -122.043],
                                  [13.01, -92],
                                ],
                                v: [
                                  [-74.97, -367.072],
                                  [-178.171, -203.045],
                                  [-249.587, 2.621],
                                  [-270.417, 147.423],
                                  [-64.724, 286.794],
                                  [121.975, 394.684],
                                  [310.934, 273.828],
                                  [103.23, -72.849],
                                  [252.02, -209.849],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.271, -29.458],
                                  [-8.808, -105.215],
                                  [40.839, -54.879],
                                  [-103.834, -37.593],
                                  [-16.591, -56.22],
                                  [-137.924, 13.835],
                                  [97.858, 72.658],
                                  [-30.557, 116.832],
                                  [-13.072, 92],
                                ],
                                o: [
                                  [-91.547, 33.402],
                                  [8.808, 105.215],
                                  [-40.839, 54.879],
                                  [103.834, 37.593],
                                  [20.254, 60.729],
                                  [109.182, -7.636],
                                  [-97.858, -72.658],
                                  [25.203, -121.623],
                                  [13.072, -92],
                                ],
                                v: [
                                  [-75.062, -367.313],
                                  [-178.372, -203.591],
                                  [-249.676, 2.395],
                                  [-270.302, 147.24],
                                  [-64.655, 286.864],
                                  [121.999, 394.109],
                                  [311.523, 273.656],
                                  [103.541, -73.044],
                                  [251.149, -209.239],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.41, -29.179],
                                  [-8.472, -104.835],
                                  [40.602, -55.112],
                                  [-103.412, -37.637],
                                  [-16.574, -56.025],
                                  [-138.244, 13.981],
                                  [97.289, 72.518],
                                  [-30.317, 116.349],
                                  [-13.135, 92],
                                ],
                                o: [
                                  [-91.442, 33.173],
                                  [8.472, 104.835],
                                  [-40.602, 55.112],
                                  [103.412, 37.637],
                                  [20.282, 60.59],
                                  [109.147, -7.705],
                                  [-97.289, -72.518],
                                  [24.897, -121.198],
                                  [13.135, -92],
                                ],
                                v: [
                                  [-75.108, -367.568],
                                  [-178.579, -204.112],
                                  [-249.745, 2.176],
                                  [-270.186, 147.05],
                                  [-64.647, 286.942],
                                  [122.021, 393.503],
                                  [312.097, 273.488],
                                  [103.847, -73.205],
                                  [250.281, -208.557],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.551, -28.898],
                                  [-8.134, -104.452],
                                  [40.364, -55.348],
                                  [-102.986, -37.682],
                                  [-16.556, -55.829],
                                  [-138.567, 14.128],
                                  [96.714, 72.377],
                                  [-30.075, 115.861],
                                  [-13.198, 92],
                                ],
                                o: [
                                  [-91.337, 32.941],
                                  [8.134, 104.452],
                                  [-40.364, 55.348],
                                  [102.986, 37.682],
                                  [20.31, 60.449],
                                  [109.112, -7.776],
                                  [-96.714, -72.377],
                                  [24.588, -120.77],
                                  [13.198, -92],
                                ],
                                v: [
                                  [-75.259, -367.762],
                                  [-178.747, -204.663],
                                  [-249.874, 1.902],
                                  [-270.041, 146.87],
                                  [-64.656, 286.992],
                                  [122.041, 392.938],
                                  [312.716, 273.35],
                                  [104.166, -73.287],
                                  [249.46, -207.928],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.693, -28.614],
                                  [-7.791, -104.064],
                                  [40.122, -55.585],
                                  [-102.556, -37.727],
                                  [-16.538, -55.63],
                                  [-138.893, 14.277],
                                  [96.133, 72.234],
                                  [-29.83, 115.368],
                                  [-13.262, 92],
                                ],
                                o: [
                                  [-91.23, 32.707],
                                  [7.791, 104.064],
                                  [-40.122, 55.585],
                                  [102.556, 37.727],
                                  [20.339, 60.307],
                                  [109.077, -7.846],
                                  [-96.133, -72.234],
                                  [24.276, -120.337],
                                  [13.262, -92],
                                ],
                                v: [
                                  [-75.39, -367.924],
                                  [-178.859, -205.19],
                                  [-250, 1.638],
                                  [-269.85, 146.718],
                                  [-64.681, 287.032],
                                  [122.076, 392.343],
                                  [313.337, 273.188],
                                  [104.453, -73.416],
                                  [248.622, -207.309],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.836, -28.328],
                                  [-7.446, -103.673],
                                  [39.879, -55.825],
                                  [-102.122, -37.773],
                                  [-16.52, -55.43],
                                  [-139.223, 14.428],
                                  [95.546, 72.09],
                                  [-29.583, 114.87],
                                  [-13.326, 92],
                                ],
                                o: [
                                  [-91.123, 32.471],
                                  [7.446, 103.673],
                                  [-39.879, 55.825],
                                  [102.122, 37.773],
                                  [20.367, 60.164],
                                  [109.041, -7.918],
                                  [-95.546, -72.09],
                                  [23.961, -119.9],
                                  [13.326, -92],
                                ],
                                v: [
                                  [-75.555, -368.071],
                                  [-179.021, -205.695],
                                  [-250.149, 1.408],
                                  [-269.615, 146.56],
                                  [-64.786, 287.062],
                                  [122.064, 391.762],
                                  [313.941, 272.968],
                                  [104.728, -73.465],
                                  [247.787, -206.638],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.981, -28.039],
                                  [-7.096, -103.278],
                                  [39.633, -56.068],
                                  [-101.683, -37.819],
                                  [-16.502, -55.227],
                                  [-139.556, 14.58],
                                  [94.954, 71.944],
                                  [-29.333, 114.367],
                                  [-13.391, 92],
                                ],
                                o: [
                                  [-91.014, 32.232],
                                  [7.096, 103.278],
                                  [-39.633, 56.068],
                                  [101.683, 37.819],
                                  [20.396, 60.019],
                                  [109.005, -7.99],
                                  [-94.954, -71.944],
                                  [23.642, -119.459],
                                  [13.391, -92],
                                ],
                                v: [
                                  [-75.744, -368.184],
                                  [-179.126, -206.186],
                                  [-250.337, 1.124],
                                  [-269.398, 146.456],
                                  [-64.889, 287.063],
                                  [122.07, 391.198],
                                  [314.589, 272.796],
                                  [105.043, -73.497],
                                  [246.997, -205.958],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.127, -27.746],
                                  [-6.744, -102.879],
                                  [39.384, -56.312],
                                  [-101.241, -37.865],
                                  [-16.484, -55.022],
                                  [-139.892, 14.733],
                                  [94.356, 71.797],
                                  [-29.081, 113.859],
                                  [-13.457, 92],
                                ],
                                o: [
                                  [-90.905, 31.991],
                                  [6.744, 102.879],
                                  [-39.384, 56.312],
                                  [101.241, 37.865],
                                  [20.425, 59.873],
                                  [108.968, -8.063],
                                  [-94.356, -71.797],
                                  [23.321, -119.013],
                                  [13.457, -92],
                                ],
                                v: [
                                  [-75.931, -368.28],
                                  [-179.193, -206.699],
                                  [-250.54, 0.85],
                                  [-269.119, 146.337],
                                  [-65.052, 287.009],
                                  [122.117, 390.63],
                                  [315.219, 272.608],
                                  [105.285, -73.493],
                                  [246.19, -205.269],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.274, -27.451],
                                  [-6.388, -102.476],
                                  [39.134, -56.56],
                                  [-100.793, -37.912],
                                  [-16.466, -54.816],
                                  [-140.231, 14.888],
                                  [93.752, 71.649],
                                  [-28.827, 113.347],
                                  [-13.523, 92],
                                ],
                                o: [
                                  [-90.794, 31.747],
                                  [6.388, 102.476],
                                  [-39.134, 56.559],
                                  [100.793, 37.912],
                                  [20.455, 59.726],
                                  [108.931, -8.137],
                                  [-93.752, -71.649],
                                  [22.996, -118.563],
                                  [13.523, -92],
                                ],
                                v: [
                                  [-76.178, -368.36],
                                  [-179.291, -207.173],
                                  [-250.762, 0.523],
                                  [-268.815, 146.212],
                                  [-65.275, 286.945],
                                  [122.12, 390.055],
                                  [315.892, 272.399],
                                  [105.604, -73.471],
                                  [245.383, -204.592],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.423, -27.153],
                                  [-6.029, -102.069],
                                  [38.88, -56.809],
                                  [-100.342, -37.96],
                                  [-16.447, -54.607],
                                  [-140.574, 15.044],
                                  [93.142, 71.499],
                                  [-28.57, 112.829],
                                  [-13.591, 92],
                                ],
                                o: [
                                  [-90.683, 31.502],
                                  [6.029, 102.069],
                                  [-38.88, 56.809],
                                  [100.342, 37.96],
                                  [20.485, 59.577],
                                  [108.894, -8.212],
                                  [-93.142, -71.499],
                                  [22.669, -118.109],
                                  [13.59, -92],
                                ],
                                v: [
                                  [-76.403, -368.361],
                                  [-179.351, -207.672],
                                  [-250.98, 0.207],
                                  [-268.489, 146.14],
                                  [-65.514, 286.87],
                                  [122.14, 389.522],
                                  [316.544, 272.155],
                                  [105.875, -73.431],
                                  [244.579, -203.906],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.574, -26.853],
                                  [-5.666, -101.658],
                                  [38.625, -57.061],
                                  [-99.886, -38.008],
                                  [-16.428, -54.397],
                                  [-140.919, 15.202],
                                  [92.526, 71.348],
                                  [-28.31, 112.306],
                                  [-13.658, 92],
                                ],
                                o: [
                                  [-90.57, 31.253],
                                  [5.666, 101.658],
                                  [-38.625, 57.061],
                                  [99.886, 38.008],
                                  [20.515, 59.426],
                                  [108.857, -8.287],
                                  [-92.526, -71.348],
                                  [22.338, -117.65],
                                  [13.658, -92],
                                ],
                                v: [
                                  [-76.688, -368.346],
                                  [-179.398, -208.113],
                                  [-251.235, -0.118],
                                  [-268.103, 146.037],
                                  [-65.833, 286.765],
                                  [122.159, 388.945],
                                  [317.196, 271.934],
                                  [106.138, -73.354],
                                  [243.755, -203.232],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.726, -26.549],
                                  [-5.3, -101.243],
                                  [38.367, -57.315],
                                  [-99.426, -38.056],
                                  [-16.409, -54.184],
                                  [-141.269, 15.362],
                                  [91.905, 71.195],
                                  [-28.048, 111.779],
                                  [-13.727, 92],
                                ],
                                o: [
                                  [-90.456, 31.003],
                                  [5.3, 101.243],
                                  [-38.367, 57.315],
                                  [99.426, 38.056],
                                  [20.545, 59.274],
                                  [108.819, -8.363],
                                  [-91.905, -71.195],
                                  [22.004, -117.187],
                                  [13.726, -92],
                                ],
                                v: [
                                  [-76.97, -368.27],
                                  [-179.408, -208.579],
                                  [-251.508, -0.451],
                                  [-267.696, 145.988],
                                  [-66.19, 286.625],
                                  [122.177, 388.449],
                                  [317.847, 271.655],
                                  [106.398, -73.215],
                                  [242.913, -202.551],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.879, -26.242],
                                  [-4.93, -100.825],
                                  [38.106, -57.572],
                                  [-98.961, -38.105],
                                  [-16.39, -53.97],
                                  [-141.621, 15.523],
                                  [91.277, 71.041],
                                  [-27.784, 111.246],
                                  [-13.795, 92],
                                ],
                                o: [
                                  [-90.341, 30.75],
                                  [4.93, 100.825],
                                  [-38.106, 57.572],
                                  [98.961, 38.105],
                                  [20.576, 59.121],
                                  [108.78, -8.439],
                                  [-91.277, -71.041],
                                  [21.667, -116.72],
                                  [13.795, -92],
                                ],
                                v: [
                                  [-77.271, -368.201],
                                  [-179.404, -209.029],
                                  [-251.793, -0.814],
                                  [-267.293, 145.93],
                                  [-66.606, 286.454],
                                  [122.172, 387.929],
                                  [318.434, 271.336],
                                  [106.693, -73.038],
                                  [242.134, -201.861],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.034, -25.933],
                                  [-4.556, -100.402],
                                  [37.843, -57.831],
                                  [-98.492, -38.154],
                                  [-16.371, -53.753],
                                  [-141.977, 15.685],
                                  [90.644, 70.885],
                                  [-27.517, 110.708],
                                  [-13.865, 92],
                                ],
                                o: [
                                  [-90.225, 30.495],
                                  [4.556, 100.402],
                                  [-37.843, 57.831],
                                  [98.492, 38.154],
                                  [20.607, 58.966],
                                  [108.742, -8.517],
                                  [-90.644, -70.885],
                                  [21.326, -116.247],
                                  [13.865, -92],
                                ],
                                v: [
                                  [-77.59, -368.029],
                                  [-179.385, -209.466],
                                  [-252.114, -1.208],
                                  [-266.85, 145.883],
                                  [-67.037, 286.272],
                                  [122.23, 387.41],
                                  [319.02, 271.002],
                                  [107.005, -72.841],
                                  [241.292, -201.142],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.19, -25.62],
                                  [-4.179, -99.975],
                                  [37.577, -58.093],
                                  [-98.018, -38.204],
                                  [-16.351, -53.534],
                                  [-142.337, 15.849],
                                  [90.004, 70.728],
                                  [-27.247, 110.165],
                                  [-13.935, 92],
                                ],
                                o: [
                                  [-90.108, 30.237],
                                  [4.179, 99.975],
                                  [-37.577, 58.093],
                                  [98.018, 38.204],
                                  [20.638, 58.81],
                                  [108.703, -8.595],
                                  [-90.004, -70.728],
                                  [20.982, -115.771],
                                  [13.935, -92],
                                ],
                                v: [
                                  [-77.904, -367.882],
                                  [-179.331, -209.888],
                                  [-252.45, -1.628],
                                  [-266.39, 145.847],
                                  [-67.526, 286.078],
                                  [122.287, 386.954],
                                  [319.542, 270.651],
                                  [107.292, -72.604],
                                  [240.431, -200.458],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.348, -25.305],
                                  [-3.799, -99.544],
                                  [37.309, -58.357],
                                  [-97.54, -38.255],
                                  [-16.332, -53.313],
                                  [-142.7, 16.015],
                                  [89.358, 70.569],
                                  [-26.975, 109.617],
                                  [-14.006, 92],
                                ],
                                o: [
                                  [-89.989, 29.976],
                                  [3.799, 99.544],
                                  [-37.309, 58.357],
                                  [97.54, 38.255],
                                  [20.67, 58.652],
                                  [108.663, -8.674],
                                  [-89.358, -70.569],
                                  [20.635, -115.29],
                                  [14.006, -92],
                                ],
                                v: [
                                  [-78.237, -367.634],
                                  [-179.303, -210.318],
                                  [-252.798, -2.056],
                                  [-265.872, 145.864],
                                  [-68.031, 285.873],
                                  [122.303, 386.478],
                                  [320.021, 270.221],
                                  [107.554, -72.306],
                                  [239.57, -199.746],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.507, -24.986],
                                  [-3.415, -99.109],
                                  [37.038, -58.624],
                                  [-97.057, -38.305],
                                  [-16.312, -53.09],
                                  [-143.066, 16.182],
                                  [88.706, 70.409],
                                  [-26.701, 109.063],
                                  [-14.078, 92],
                                ],
                                o: [
                                  [-89.87, 29.714],
                                  [3.415, 99.109],
                                  [-37.038, 58.624],
                                  [97.057, 38.305],
                                  [20.701, 58.493],
                                  [108.623, -8.753],
                                  [-88.706, -70.409],
                                  [20.285, -114.804],
                                  [14.078, -92],
                                ],
                                v: [
                                  [-78.586, -367.369],
                                  [-179.217, -210.776],
                                  [-253.18, -2.531],
                                  [-265.339, 145.829],
                                  [-68.551, 285.656],
                                  [122.381, 386.024],
                                  [320.478, 269.816],
                                  [107.876, -72.008],
                                  [238.729, -199.048],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.668, -24.665],
                                  [-3.027, -98.67],
                                  [36.765, -58.893],
                                  [-96.57, -38.357],
                                  [-16.292, -52.865],
                                  [-143.436, 16.351],
                                  [88.048, 70.247],
                                  [-26.423, 108.505],
                                  [-14.151, 92],
                                ],
                                o: [
                                  [-89.749, 29.448],
                                  [3.027, 98.67],
                                  [-36.765, 58.893],
                                  [96.57, 38.357],
                                  [20.734, 58.332],
                                  [108.583, -8.834],
                                  [-88.048, -70.247],
                                  [19.931, -114.314],
                                  [14.15, -92],
                                ],
                                v: [
                                  [-78.951, -367.066],
                                  [-179.096, -211.139],
                                  [-253.533, -3.052],
                                  [-264.811, 145.825],
                                  [-69.128, 285.427],
                                  [122.419, 385.593],
                                  [320.912, 269.355],
                                  [108.196, -71.606],
                                  [237.846, -198.323],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.83, -24.34],
                                  [-2.635, -98.227],
                                  [36.489, -59.165],
                                  [-96.078, -38.408],
                                  [-16.271, -52.638],
                                  [-143.809, 16.522],
                                  [87.383, 70.084],
                                  [-26.143, 107.941],
                                  [-14.224, 92],
                                ],
                                o: [
                                  [-89.628, 29.18],
                                  [2.635, 98.227],
                                  [-36.489, 59.165],
                                  [96.078, 38.408],
                                  [20.766, 58.17],
                                  [108.543, -8.915],
                                  [-87.383, -70.083],
                                  [19.574, -113.818],
                                  [14.224, -92],
                                ],
                                v: [
                                  [-79.271, -366.704],
                                  [-178.999, -211.573],
                                  [-253.917, -3.557],
                                  [-264.249, 145.853],
                                  [-69.72, 285.186],
                                  [122.499, 385.187],
                                  [321.285, 268.836],
                                  [108.535, -71.204],
                                  [237.004, -197.613],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.994, -24.012],
                                  [-2.24, -97.779],
                                  [36.21, -59.44],
                                  [-95.581, -38.461],
                                  [-16.251, -52.409],
                                  [-144.186, 16.694],
                                  [86.713, 69.919],
                                  [-25.861, 107.371],
                                  [-14.297, 92],
                                ],
                                o: [
                                  [-89.505, 28.91],
                                  [2.24, 97.779],
                                  [-36.21, 59.44],
                                  [95.581, 38.461],
                                  [20.799, 58.006],
                                  [108.501, -8.997],
                                  [-86.713, -69.919],
                                  [19.213, -113.319],
                                  [14.297, -92],
                                ],
                                v: [
                                  [-79.586, -366.324],
                                  [-178.867, -211.976],
                                  [-254.314, -4.107],
                                  [-263.675, 145.889],
                                  [-70.368, 284.932],
                                  [122.56, 384.805],
                                  [321.654, 268.322],
                                  [108.893, -70.717],
                                  [236.098, -196.878],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.159, -23.681],
                                  [-1.841, -97.327],
                                  [35.929, -59.717],
                                  [-95.08, -38.513],
                                  [-16.23, -52.177],
                                  [-144.566, 16.867],
                                  [86.035, 69.752],
                                  [-25.575, 106.797],
                                  [-14.372, 92],
                                ],
                                o: [
                                  [-89.381, 28.637],
                                  [1.841, 97.327],
                                  [-35.929, 59.717],
                                  [95.08, 38.513],
                                  [20.832, 57.841],
                                  [108.46, -9.08],
                                  [-86.035, -69.752],
                                  [18.85, -112.814],
                                  [14.372, -92],
                                ],
                                v: [
                                  [-79.856, -365.908],
                                  [-178.718, -212.37],
                                  [-254.719, -4.68],
                                  [-263.047, 145.894],
                                  [-71.01, 284.667],
                                  [122.686, 384.428],
                                  [321.962, 267.814],
                                  [109.27, -70.209],
                                  [235.21, -196.179],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.326, -23.348],
                                  [-1.438, -96.871],
                                  [35.645, -59.996],
                                  [-94.574, -38.566],
                                  [-16.209, -51.943],
                                  [-144.95, 17.043],
                                  [85.352, 69.584],
                                  [-25.287, 106.216],
                                  [-14.447, 92],
                                ],
                                o: [
                                  [-89.255, 28.362],
                                  [1.438, 96.871],
                                  [-35.645, 59.996],
                                  [94.574, 38.566],
                                  [20.865, 57.674],
                                  [108.418, -9.163],
                                  [-85.352, -69.584],
                                  [18.482, -112.305],
                                  [14.447, -92],
                                ],
                                v: [
                                  [-80.141, -365.43],
                                  [-178.511, -212.752],
                                  [-255.096, -5.295],
                                  [-262.448, 145.926],
                                  [-71.689, 284.348],
                                  [122.791, 384.098],
                                  [322.226, 267.271],
                                  [109.689, -69.657],
                                  [234.321, -195.456],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.495, -23.01],
                                  [-1.031, -96.411],
                                  [35.359, -60.279],
                                  [-94.063, -38.62],
                                  [-16.188, -51.707],
                                  [-145.338, 17.219],
                                  [84.662, 69.415],
                                  [-24.997, 105.631],
                                  [-14.523, 92],
                                ],
                                o: [
                                  [-89.129, 28.084],
                                  [1.031, 96.411],
                                  [-35.359, 60.279],
                                  [94.063, 38.62],
                                  [20.899, 57.505],
                                  [108.376, -9.247],
                                  [-84.662, -69.415],
                                  [18.112, -111.791],
                                  [14.523, -92],
                                ],
                                v: [
                                  [-80.34, -364.917],
                                  [-178.35, -213.128],
                                  [-255.481, -5.93],
                                  [-261.819, 145.968],
                                  [-72.36, 284.057],
                                  [122.942, 383.774],
                                  [322.448, 266.671],
                                  [110.088, -69.058],
                                  [233.406, -194.728],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.665, -22.67],
                                  [-0.621, -95.947],
                                  [35.07, -60.564],
                                  [-93.547, -38.674],
                                  [-16.167, -51.469],
                                  [-145.729, 17.398],
                                  [83.966, 69.243],
                                  [-24.703, 105.04],
                                  [-14.599, 92],
                                ],
                                o: [
                                  [-89.001, 27.803],
                                  [0.621, 95.947],
                                  [-35.07, 60.564],
                                  [93.547, 38.674],
                                  [20.933, 57.335],
                                  [108.334, -9.332],
                                  [-83.966, -69.243],
                                  [17.737, -111.272],
                                  [14.599, -92],
                                ],
                                v: [
                                  [-80.536, -364.426],
                                  [-178.107, -213.515],
                                  [-255.895, -6.543],
                                  [-261.136, 146.036],
                                  [-73.109, 283.753],
                                  [123.072, 383.478],
                                  [322.668, 266.055],
                                  [110.548, -68.42],
                                  [232.49, -193.979],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.837, -22.327],
                                  [-0.207, -95.478],
                                  [34.778, -60.851],
                                  [-93.027, -38.729],
                                  [-16.145, -51.229],
                                  [-146.124, 17.578],
                                  [83.263, 69.071],
                                  [-24.407, 104.443],
                                  [-14.677, 92],
                                ],
                                o: [
                                  [-88.873, 27.52],
                                  [0.207, 95.478],
                                  [-34.778, 60.851],
                                  [93.027, 38.729],
                                  [20.967, 57.163],
                                  [108.291, -9.418],
                                  [-83.263, -69.071],
                                  [17.359, -110.748],
                                  [14.676, -92],
                                ],
                                v: [
                                  [-80.728, -363.838],
                                  [-177.891, -213.914],
                                  [-256.321, -7.197],
                                  [-260.447, 146.026],
                                  [-73.89, 283.42],
                                  [123.25, 383.187],
                                  [322.826, 265.428],
                                  [111.052, -67.713],
                                  [231.548, -193.226],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.01, -21.98],
                                  [0.211, -95.004],
                                  [34.483, -61.142],
                                  [-92.501, -38.784],
                                  [-16.124, -50.986],
                                  [-146.523, 17.76],
                                  [82.553, 68.896],
                                  [-24.108, 103.841],
                                  [-14.755, 92],
                                ],
                                o: [
                                  [-88.743, 27.234],
                                  [-0.211, 95.004],
                                  [-34.483, 61.142],
                                  [92.501, 38.784],
                                  [21.002, 56.99],
                                  [108.247, -9.505],
                                  [-82.553, -68.896],
                                  [16.978, -110.22],
                                  [14.754, -92],
                                ],
                                v: [
                                  [-80.853, -363.233],
                                  [-177.634, -214.286],
                                  [-256.673, -7.889],
                                  [-259.768, 146.126],
                                  [-74.669, 283.052],
                                  [123.408, 382.879],
                                  [322.982, 264.785],
                                  [111.577, -67.005],
                                  [230.643, -192.494],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.185, -21.63],
                                  [0.633, -94.526],
                                  [34.186, -61.435],
                                  [-91.971, -38.84],
                                  [-16.102, -50.741],
                                  [-146.925, 17.944],
                                  [81.837, 68.72],
                                  [-23.806, 103.233],
                                  [-14.833, 92],
                                ],
                                o: [
                                  [-88.611, 26.945],
                                  [-0.633, 94.526],
                                  [-34.186, 61.435],
                                  [91.971, 38.84],
                                  [21.037, 56.815],
                                  [108.204, -9.592],
                                  [-81.837, -68.72],
                                  [16.593, -109.686],
                                  [14.833, -92],
                                ],
                                v: [
                                  [-80.975, -362.611],
                                  [-177.341, -214.631],
                                  [-257.092, -8.558],
                                  [-259.085, 146.147],
                                  [-75.48, 282.733],
                                  [123.63, 382.6],
                                  [323.075, 264.125],
                                  [112.124, -66.21],
                                  [229.671, -191.758],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.361, -21.277],
                                  [1.059, -94.044],
                                  [33.886, -61.73],
                                  [-91.436, -38.896],
                                  [-16.08, -50.494],
                                  [-147.331, 18.129],
                                  [81.114, 68.543],
                                  [-23.502, 102.619],
                                  [-14.913, 92],
                                ],
                                o: [
                                  [-88.479, 26.654],
                                  [-1.059, 94.044],
                                  [-33.886, 61.73],
                                  [91.436, 38.896],
                                  [21.072, 56.639],
                                  [108.16, -9.681],
                                  [-81.114, -68.543],
                                  [16.205, -109.148],
                                  [14.913, -92],
                                ],
                                v: [
                                  [-81.068, -361.971],
                                  [-177.066, -215.052],
                                  [-257.484, -9.24],
                                  [-258.39, 146.214],
                                  [-76.343, 282.362],
                                  [123.855, 382.327],
                                  [323.128, 263.431],
                                  [112.716, -65.392],
                                  [228.735, -191.019],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.54, -20.921],
                                  [1.489, -93.558],
                                  [33.583, -62.029],
                                  [-90.896, -38.953],
                                  [-16.058, -50.245],
                                  [-147.741, 18.317],
                                  [80.385, 68.363],
                                  [-23.194, 102],
                                  [-14.993, 92],
                                ],
                                o: [
                                  [-88.345, 26.36],
                                  [-1.489, 93.558],
                                  [-33.583, 62.029],
                                  [90.896, 38.953],
                                  [21.108, 56.46],
                                  [108.115, -9.77],
                                  [-80.385, -68.363],
                                  [15.813, -108.604],
                                  [14.993, -92],
                                ],
                                v: [
                                  [-81.119, -361.236],
                                  [-176.795, -215.424],
                                  [-257.839, -9.92],
                                  [-257.732, 146.325],
                                  [-77.182, 281.979],
                                  [124.082, 382.06],
                                  [323.118, 262.707],
                                  [113.307, -64.525],
                                  [227.771, -190.288],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.719, -20.561],
                                  [1.923, -93.066],
                                  [33.277, -62.33],
                                  [-90.35, -39.011],
                                  [-16.035, -49.993],
                                  [-148.155, 18.505],
                                  [79.649, 68.182],
                                  [-22.884, 101.375],
                                  [-15.074, 92],
                                ],
                                o: [
                                  [-88.21, 26.063],
                                  [-1.923, 93.066],
                                  [-33.277, 62.33],
                                  [90.35, 39.011],
                                  [21.144, 56.281],
                                  [108.07, -9.86],
                                  [-79.649, -68.182],
                                  [15.417, -108.056],
                                  [15.074, -92],
                                ],
                                v: [
                                  [-81.128, -360.523],
                                  [-176.513, -215.772],
                                  [-258.208, -10.635],
                                  [-257.023, 146.418],
                                  [-78.074, 281.584],
                                  [124.336, 381.736],
                                  [323.107, 262.027],
                                  [113.945, -63.619],
                                  [226.802, -189.492],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.901, -20.198],
                                  [2.361, -92.571],
                                  [32.968, -62.634],
                                  [-89.8, -39.068],
                                  [-16.012, -49.739],
                                  [-148.572, 18.696],
                                  [78.906, 68],
                                  [-22.571, 100.744],
                                  [-15.155, 92],
                                ],
                                o: [
                                  [-88.074, 25.763],
                                  [-2.361, 92.571],
                                  [-32.968, 62.634],
                                  [89.8, 39.068],
                                  [21.18, 56.099],
                                  [108.025, -9.95],
                                  [-78.906, -68],
                                  [15.018, -107.502],
                                  [15.155, -92],
                                ],
                                v: [
                                  [-81.109, -359.779],
                                  [-176.249, -216.147],
                                  [-258.577, -11.361],
                                  [-256.314, 146.491],
                                  [-78.956, 281.162],
                                  [124.631, 381.482],
                                  [323.071, 261.292],
                                  [114.606, -62.664],
                                  [225.842, -188.758],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.084, -19.832],
                                  [2.803, -92.07],
                                  [32.657, -62.941],
                                  [-89.245, -39.127],
                                  [-15.989, -49.482],
                                  [-148.993, 18.888],
                                  [78.156, 67.815],
                                  [-22.255, 100.108],
                                  [-15.238, 92],
                                ],
                                o: [
                                  [-87.937, 25.461],
                                  [-2.803, 92.07],
                                  [-32.657, 62.941],
                                  [89.245, 39.127],
                                  [21.217, 55.916],
                                  [107.979, -10.042],
                                  [-78.156, -67.815],
                                  [14.615, -106.943],
                                  [15.238, -92],
                                ],
                                v: [
                                  [-81.06, -358.956],
                                  [-175.951, -216.523],
                                  [-258.896, -12.057],
                                  [-255.657, 146.607],
                                  [-79.915, 280.741],
                                  [124.891, 381.209],
                                  [322.959, 260.527],
                                  [115.353, -61.66],
                                  [224.864, -188.011],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.269, -19.462],
                                  [3.249, -91.565],
                                  [32.343, -63.251],
                                  [-88.685, -39.186],
                                  [-15.966, -49.223],
                                  [-149.419, 19.082],
                                  [77.399, 67.629],
                                  [-21.936, 99.465],
                                  [-15.321, 92],
                                ],
                                o: [
                                  [-87.798, 25.156],
                                  [-3.249, 91.565],
                                  [-32.343, 63.251],
                                  [88.685, 39.186],
                                  [21.254, 55.731],
                                  [107.933, -10.134],
                                  [-77.399, -67.629],
                                  [14.208, -106.38],
                                  [15.321, -92],
                                ],
                                v: [
                                  [-80.97, -358.142],
                                  [-175.718, -216.888],
                                  [-259.264, -12.785],
                                  [-254.964, 146.702],
                                  [-80.825, 280.357],
                                  [125.191, 380.967],
                                  [322.847, 259.78],
                                  [116.06, -60.617],
                                  [223.893, -187.288],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.456, -19.089],
                                  [3.699, -91.056],
                                  [32.026, -63.563],
                                  [-88.119, -39.245],
                                  [-15.943, -48.962],
                                  [-149.848, 19.278],
                                  [76.635, 67.442],
                                  [-21.614, 98.817],
                                  [-15.405, 92],
                                ],
                                o: [
                                  [-87.658, 24.848],
                                  [-3.699, 91.056],
                                  [-32.026, 63.563],
                                  [88.119, 39.245],
                                  [21.291, 55.544],
                                  [107.886, -10.228],
                                  [-76.635, -67.442],
                                  [13.798, -105.811],
                                  [15.405, -92],
                                ],
                                v: [
                                  [-80.876, -357.311],
                                  [-175.49, -217.242],
                                  [-259.583, -13.483],
                                  [-254.298, 146.801],
                                  [-81.812, 279.899],
                                  [125.53, 380.67],
                                  [322.709, 259.029],
                                  [116.854, -59.549],
                                  [222.916, -186.504],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.644, -18.712],
                                  [4.153, -90.542],
                                  [31.706, -63.878],
                                  [-87.548, -39.305],
                                  [-15.92, -48.699],
                                  [-150.281, 19.476],
                                  [75.864, 67.252],
                                  [-21.289, 98.163],
                                  [-15.49, 92],
                                ],
                                o: [
                                  [-87.517, 24.538],
                                  [-4.153, 90.542],
                                  [-31.706, 63.878],
                                  [87.548, 39.305],
                                  [21.329, 55.356],
                                  [107.839, -10.322],
                                  [-75.864, -67.252],
                                  [13.384, -105.236],
                                  [15.49, -92],
                                ],
                                v: [
                                  [-80.715, -356.427],
                                  [-175.239, -217.599],
                                  [-259.863, -14.174],
                                  [-253.611, 146.941],
                                  [-82.788, 279.491],
                                  [125.861, 380.416],
                                  [322.533, 258.26],
                                  [117.673, -58.43],
                                  [221.944, -185.773],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.834, -18.332],
                                  [4.612, -90.023],
                                  [31.383, -64.197],
                                  [-86.973, -39.366],
                                  [-15.896, -48.433],
                                  [-150.718, 19.675],
                                  [75.087, 67.061],
                                  [-20.962, 97.503],
                                  [-15.575, 92],
                                ],
                                o: [
                                  [-87.375, 24.224],
                                  [-4.612, 90.023],
                                  [-31.383, 64.197],
                                  [86.973, 39.366],
                                  [21.367, 55.166],
                                  [107.792, -10.417],
                                  [-75.087, -67.061],
                                  [12.966, -104.657],
                                  [15.575, -92],
                                ],
                                v: [
                                  [-80.524, -355.526],
                                  [-175.019, -217.918],
                                  [-260.192, -14.895],
                                  [-252.965, 147.031],
                                  [-83.787, 279.034],
                                  [126.205, 380.132],
                                  [322.295, 257.488],
                                  [118.515, -57.275],
                                  [220.955, -185.043],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.025, -17.949],
                                  [5.074, -89.499],
                                  [31.057, -64.518],
                                  [-86.391, -39.427],
                                  [-15.872, -48.164],
                                  [-151.159, 19.877],
                                  [74.302, 66.868],
                                  [-20.631, 96.836],
                                  [-15.661, 92],
                                ],
                                o: [
                                  [-87.231, 23.908],
                                  [-5.074, 89.499],
                                  [-31.057, 64.518],
                                  [86.391, 39.427],
                                  [21.405, 54.974],
                                  [107.744, -10.513],
                                  [-74.302, -66.868],
                                  [12.544, -104.072],
                                  [15.661, -92],
                                ],
                                v: [
                                  [-80.293, -354.573],
                                  [-174.802, -218.279],
                                  [-260.472, -15.618],
                                  [-252.309, 147.186],
                                  [-84.764, 278.565],
                                  [126.542, 379.865],
                                  [322.021, 256.735],
                                  [119.445, -56.069],
                                  [219.97, -184.279],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.219, -17.562],
                                  [5.541, -88.971],
                                  [30.728, -64.842],
                                  [-85.805, -39.489],
                                  [-15.848, -47.893],
                                  [-151.604, 20.08],
                                  [73.51, 66.673],
                                  [-20.297, 96.164],
                                  [-15.749, 92],
                                ],
                                o: [
                                  [-87.086, 23.589],
                                  [-5.541, 88.971],
                                  [-30.728, 64.842],
                                  [85.805, 39.489],
                                  [21.444, 54.781],
                                  [107.695, -10.609],
                                  [-73.51, -66.673],
                                  [12.118, -103.482],
                                  [15.749, -92],
                                ],
                                v: [
                                  [-80.03, -353.596],
                                  [-174.625, -218.627],
                                  [-260.74, -16.333],
                                  [-251.633, 147.317],
                                  [-85.79, 278.084],
                                  [126.89, 379.595],
                                  [321.748, 255.953],
                                  [120.372, -54.8],
                                  [218.977, -183.518],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.414, -17.172],
                                  [6.012, -88.438],
                                  [30.396, -65.169],
                                  [-85.213, -39.551],
                                  [-15.823, -47.62],
                                  [-152.053, 20.285],
                                  [72.711, 66.477],
                                  [-19.961, 95.486],
                                  [-15.836, 92],
                                ],
                                o: [
                                  [-86.939, 23.267],
                                  [-6.012, 88.438],
                                  [-30.396, 65.169],
                                  [85.213, 39.551],
                                  [21.483, 54.586],
                                  [107.646, -10.707],
                                  [-72.711, -66.477],
                                  [11.689, -102.887],
                                  [15.836, -92],
                                ],
                                v: [
                                  [-79.728, -352.639],
                                  [-174.416, -218.99],
                                  [-260.958, -17.013],
                                  [-251.036, 147.458],
                                  [-86.829, 277.618],
                                  [127.277, 379.34],
                                  [321.474, 255.179],
                                  [121.324, -53.533],
                                  [217.986, -182.787],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.611, -16.778],
                                  [6.487, -87.899],
                                  [30.061, -65.499],
                                  [-84.616, -39.614],
                                  [-15.799, -47.344],
                                  [-152.506, 20.492],
                                  [71.904, 66.279],
                                  [-19.621, 94.801],
                                  [-15.925, 92],
                                ],
                                o: [
                                  [-86.792, 22.942],
                                  [-6.487, 87.9],
                                  [-30.061, 65.499],
                                  [84.616, 39.614],
                                  [21.522, 54.389],
                                  [107.597, -10.806],
                                  [-71.904, -66.279],
                                  [11.255, -102.286],
                                  [15.925, -92],
                                ],
                                v: [
                                  [-79.394, -351.596],
                                  [-174.246, -219.305],
                                  [-261.226, -17.683],
                                  [-250.394, 147.599],
                                  [-87.854, 277.14],
                                  [127.657, 379.056],
                                  [321.104, 254.414],
                                  [122.328, -52.178],
                                  [217.014, -182.052],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.81, -16.38],
                                  [6.966, -87.357],
                                  [29.723, -65.832],
                                  [-84.014, -39.677],
                                  [-15.774, -47.066],
                                  [-152.963, 20.7],
                                  [71.091, 66.079],
                                  [-19.278, 94.111],
                                  [-16.014, 92],
                                ],
                                o: [
                                  [-86.643, 22.614],
                                  [-6.966, 87.357],
                                  [-29.723, 65.832],
                                  [84.014, 39.677],
                                  [21.562, 54.19],
                                  [107.548, -10.905],
                                  [-71.091, -66.079],
                                  [10.818, -101.68],
                                  [16.014, -92],
                                ],
                                v: [
                                  [-79.02, -350.566],
                                  [-174.114, -219.669],
                                  [-261.447, -18.35],
                                  [-249.797, 147.74],
                                  [-88.926, 276.678],
                                  [128.047, 378.815],
                                  [320.735, 253.657],
                                  [123.392, -50.825],
                                  [215.978, -181.287],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.01, -15.979],
                                  [7.45, -86.809],
                                  [29.382, -66.168],
                                  [-83.406, -39.741],
                                  [-15.749, -46.785],
                                  [-153.424, 20.911],
                                  [70.27, 65.877],
                                  [-18.932, 93.414],
                                  [-16.105, 92],
                                ],
                                o: [
                                  [-86.492, 22.283],
                                  [-7.45, 86.809],
                                  [-29.382, 66.168],
                                  [83.406, 39.741],
                                  [21.602, 53.99],
                                  [107.497, -11.005],
                                  [-70.27, -65.877],
                                  [10.377, -101.068],
                                  [16.105, -92],
                                ],
                                v: [
                                  [-78.643, -349.522],
                                  [-173.951, -219.985],
                                  [-261.655, -19.041],
                                  [-249.19, 147.888],
                                  [-89.948, 276.17],
                                  [128.439, 378.545],
                                  [320.366, 252.874],
                                  [124.481, -49.418],
                                  [214.961, -180.562],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.213, -15.574],
                                  [7.938, -86.256],
                                  [29.038, -66.507],
                                  [-82.792, -39.805],
                                  [-15.723, -46.502],
                                  [-153.89, 21.124],
                                  [69.441, 65.673],
                                  [-18.583, 92.71],
                                  [-16.196, 92],
                                ],
                                o: [
                                  [-86.34, 21.949],
                                  [-7.938, 86.256],
                                  [-29.038, 66.507],
                                  [82.792, 39.805],
                                  [21.643, 53.787],
                                  [107.447, -11.106],
                                  [-69.441, -65.673],
                                  [9.932, -100.451],
                                  [16.196, -92],
                                ],
                                v: [
                                  [-78.232, -348.43],
                                  [-173.825, -220.316],
                                  [-261.817, -19.686],
                                  [-248.629, 148.007],
                                  [-91.017, 275.685],
                                  [128.832, 378.288],
                                  [319.902, 252.135],
                                  [125.63, -47.98],
                                  [213.941, -179.774],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.417, -15.166],
                                  [8.431, -85.699],
                                  [28.691, -66.848],
                                  [-82.173, -39.87],
                                  [-15.698, -46.216],
                                  [-154.359, 21.338],
                                  [68.605, 65.468],
                                  [-18.231, 92.001],
                                  [-16.288, 92],
                                ],
                                o: [
                                  [-86.187, 21.612],
                                  [-8.431, 85.699],
                                  [-28.691, 66.848],
                                  [82.173, 39.87],
                                  [21.683, 53.583],
                                  [107.396, -11.208],
                                  [-68.605, -65.468],
                                  [9.483, -99.828],
                                  [16.288, -92],
                                ],
                                v: [
                                  [-77.784, -347.324],
                                  [-173.736, -220.633],
                                  [-262, -20.353],
                                  [-248.092, 148.159],
                                  [-92.097, 275.184],
                                  [129.227, 378.003],
                                  [319.474, 251.405],
                                  [126.775, -46.516],
                                  [212.912, -179.055],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.623, -14.754],
                                  [8.928, -85.136],
                                  [28.341, -67.194],
                                  [-81.549, -39.936],
                                  [-15.672, -45.928],
                                  [-154.833, 21.554],
                                  [67.762, 65.261],
                                  [-17.875, 91.285],
                                  [-16.38, 92],
                                ],
                                o: [
                                  [-86.033, 21.272],
                                  [-8.928, 85.136],
                                  [-28.341, 67.194],
                                  [81.549, 39.936],
                                  [21.725, 53.377],
                                  [107.344, -11.311],
                                  [-67.762, -65.261],
                                  [9.029, -99.2],
                                  [16.38, -92],
                                ],
                                v: [
                                  [-77.268, -346.233],
                                  [-173.65, -220.964],
                                  [-262.166, -20.979],
                                  [-247.568, 148.308],
                                  [-93.16, 274.671],
                                  [129.628, 377.696],
                                  [318.979, 250.65],
                                  [128.008, -44.994],
                                  [211.905, -178.308],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.831, -14.338],
                                  [9.429, -84.568],
                                  [27.988, -67.542],
                                  [-80.919, -40.002],
                                  [-15.646, -45.637],
                                  [-155.311, 21.772],
                                  [66.911, 65.051],
                                  [-17.517, 90.563],
                                  [-16.474, 92],
                                ],
                                o: [
                                  [-85.877, 20.929],
                                  [-9.429, 84.568],
                                  [-27.988, 67.542],
                                  [80.919, 40.002],
                                  [21.766, 53.169],
                                  [107.292, -11.415],
                                  [-66.911, -65.052],
                                  [8.572, -98.566],
                                  [16.474, -92],
                                ],
                                v: [
                                  [-76.749, -345.063],
                                  [-173.566, -221.309],
                                  [-262.287, -21.592],
                                  [-247.033, 148.455],
                                  [-94.235, 274.178],
                                  [130.026, 377.429],
                                  [318.487, 249.908],
                                  [129.265, -43.413],
                                  [210.826, -177.57],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.04, -13.919],
                                  [9.935, -83.996],
                                  [27.631, -67.893],
                                  [-80.283, -40.069],
                                  [-15.62, -45.343],
                                  [-155.793, 21.993],
                                  [66.053, 64.841],
                                  [-17.155, 89.834],
                                  [-16.568, 92],
                                ],
                                o: [
                                  [-85.72, 20.583],
                                  [-9.935, 83.996],
                                  [-27.631, 67.893],
                                  [80.283, 40.069],
                                  [21.808, 52.959],
                                  [107.24, -11.52],
                                  [-66.053, -64.84],
                                  [8.111, -97.926],
                                  [16.568, -92],
                                ],
                                v: [
                                  [-76.196, -343.913],
                                  [-173.517, -221.672],
                                  [-262.396, -22.193],
                                  [-246.551, 148.604],
                                  [-95.321, 273.704],
                                  [130.429, 377.134],
                                  [317.997, 249.172],
                                  [130.58, -41.841],
                                  [209.768, -176.835],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.252, -13.496],
                                  [10.446, -83.418],
                                  [27.271, -68.247],
                                  [-79.642, -40.137],
                                  [-15.593, -45.047],
                                  [-156.28, 22.215],
                                  [65.187, 64.628],
                                  [-16.79, 89.099],
                                  [-16.663, 92],
                                ],
                                o: [
                                  [-85.561, 20.234],
                                  [-10.446, 83.418],
                                  [-27.271, 68.247],
                                  [79.642, 40.137],
                                  [21.85, 52.748],
                                  [107.187, -11.626],
                                  [-65.187, -64.628],
                                  [7.645, -97.281],
                                  [16.663, -92],
                                ],
                                v: [
                                  [-75.605, -342.748],
                                  [-173.47, -221.986],
                                  [-262.495, -22.782],
                                  [-246.054, 148.75],
                                  [-96.389, 273.19],
                                  [130.832, 376.846],
                                  [317.447, 248.446],
                                  [131.95, -40.208],
                                  [208.701, -176.077],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.465, -13.069],
                                  [10.96, -82.835],
                                  [26.909, -68.605],
                                  [-78.995, -40.205],
                                  [-15.567, -44.748],
                                  [-156.771, 22.439],
                                  [64.313, 64.413],
                                  [-16.422, 88.357],
                                  [-16.759, 92],
                                ],
                                o: [
                                  [-85.401, 19.882],
                                  [-10.96, 82.835],
                                  [-26.909, 68.605],
                                  [78.995, 40.205],
                                  [21.893, 52.534],
                                  [107.134, -11.733],
                                  [-64.313, -64.413],
                                  [7.176, -96.63],
                                  [16.759, -92],
                                ],
                                v: [
                                  [-74.948, -341.539],
                                  [-173.457, -222.313],
                                  [-262.549, -23.356],
                                  [-245.608, 148.892],
                                  [-97.53, 272.727],
                                  [131.237, 376.567],
                                  [316.898, 247.762],
                                  [133.347, -38.552],
                                  [207.651, -175.361],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.681, -12.638],
                                  [11.48, -82.247],
                                  [26.543, -68.965],
                                  [-78.343, -40.273],
                                  [-15.54, -44.447],
                                  [-157.266, 22.665],
                                  [63.432, 64.196],
                                  [-16.051, 87.609],
                                  [-16.856, 92],
                                ],
                                o: [
                                  [-85.239, 19.527],
                                  [-11.48, 82.247],
                                  [-26.543, 68.965],
                                  [78.343, 40.273],
                                  [21.936, 52.319],
                                  [107.08, -11.84],
                                  [-63.432, -64.196],
                                  [6.702, -95.974],
                                  [16.856, -92],
                                ],
                                v: [
                                  [-74.286, -340.318],
                                  [-173.477, -222.623],
                                  [-262.623, -23.945],
                                  [-245.213, 149.029],
                                  [-98.589, 272.255],
                                  [131.642, 376.261],
                                  [316.321, 247.088],
                                  [134.8, -36.871],
                                  [206.527, -174.623],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.898, -12.204],
                                  [12.004, -81.654],
                                  [26.173, -69.329],
                                  [-77.684, -40.342],
                                  [-15.513, -44.143],
                                  [-157.765, 22.893],
                                  [62.542, 63.978],
                                  [-15.676, 86.854],
                                  [-16.954, 92],
                                ],
                                o: [
                                  [-85.076, 19.168],
                                  [-12.004, 81.654],
                                  [-26.173, 69.329],
                                  [77.684, 40.342],
                                  [21.98, 52.102],
                                  [107.026, -11.949],
                                  [-62.542, -63.978],
                                  [6.224, -95.311],
                                  [16.954, -92],
                                ],
                                v: [
                                  [-73.589, -339.118],
                                  [-173.497, -222.978],
                                  [-262.624, -24.489],
                                  [-244.836, 149.193],
                                  [-99.721, 271.773],
                                  [132.014, 375.961],
                                  [315.716, 246.392],
                                  [136.278, -35.135],
                                  [205.421, -173.863],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.117, -11.766],
                                  [12.533, -81.055],
                                  [25.801, -69.696],
                                  [-77.02, -40.412],
                                  [-15.485, -43.836],
                                  [-158.269, 23.123],
                                  [61.645, 63.757],
                                  [-15.298, 86.093],
                                  [-17.053, 92],
                                ],
                                o: [
                                  [-84.912, 18.807],
                                  [-12.533, 81.055],
                                  [-25.801, 69.696],
                                  [77.02, 40.412],
                                  [22.023, 51.883],
                                  [106.971, -12.059],
                                  [-61.645, -63.757],
                                  [5.742, -94.643],
                                  [17.053, -92],
                                ],
                                v: [
                                  [-72.856, -337.907],
                                  [-173.517, -223.314],
                                  [-262.646, -24.986],
                                  [-244.478, 149.35],
                                  [-100.8, 271.284],
                                  [132.448, 375.635],
                                  [315.115, 245.769],
                                  [137.81, -33.406],
                                  [204.3, -173.146],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.338, -11.324],
                                  [13.066, -80.451],
                                  [25.425, -70.066],
                                  [-76.35, -40.483],
                                  [-15.458, -43.527],
                                  [-158.778, 23.355],
                                  [60.74, 63.535],
                                  [-14.917, 85.325],
                                  [-17.152, 92],
                                ],
                                o: [
                                  [-84.746, 18.442],
                                  [-13.066, 80.451],
                                  [-25.425, 70.066],
                                  [76.35, 40.483],
                                  [22.068, 51.662],
                                  [106.915, -12.169],
                                  [-60.74, -63.535],
                                  [5.256, -93.968],
                                  [17.152, -92],
                                ],
                                v: [
                                  [-72.088, -336.656],
                                  [-173.568, -223.662],
                                  [-262.627, -25.498],
                                  [-244.107, 149.499],
                                  [-101.922, 270.848],
                                  [132.818, 375.314],
                                  [314.487, 245.125],
                                  [139.398, -31.623],
                                  [203.165, -172.409],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.561, -10.878],
                                  [13.604, -79.842],
                                  [25.046, -70.44],
                                  [-75.674, -40.554],
                                  [-15.43, -43.214],
                                  [-159.291, 23.589],
                                  [59.828, 63.31],
                                  [-14.532, 84.55],
                                  [-17.253, 92],
                                ],
                                o: [
                                  [-84.579, 18.074],
                                  [-13.604, 79.842],
                                  [-25.046, 70.44],
                                  [75.674, 40.554],
                                  [22.112, 51.439],
                                  [106.86, -12.281],
                                  [-59.828, -63.31],
                                  [4.765, -93.288],
                                  [17.253, -92],
                                ],
                                v: [
                                  [-71.283, -335.427],
                                  [-173.649, -223.99],
                                  [-262.598, -25.994],
                                  [-243.815, 149.61],
                                  [-103.022, 270.374],
                                  [133.155, 374.967],
                                  [313.864, 244.523],
                                  [141.039, -29.818],
                                  [202.013, -171.685],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.786, -10.428],
                                  [14.147, -79.228],
                                  [24.664, -70.817],
                                  [-74.992, -40.626],
                                  [-15.402, -42.899],
                                  [-159.808, 23.825],
                                  [58.907, 63.084],
                                  [-14.144, 83.768],
                                  [-17.354, 92],
                                ],
                                o: [
                                  [-84.41, 17.703],
                                  [-14.147, 79.228],
                                  [-24.664, 70.817],
                                  [74.992, 40.626],
                                  [22.157, 51.214],
                                  [106.803, -12.393],
                                  [-58.907, -63.084],
                                  [4.27, -92.602],
                                  [17.354, -92],
                                ],
                                v: [
                                  [-70.443, -334.193],
                                  [-173.76, -224.33],
                                  [-262.497, -26.473],
                                  [-243.511, 149.742],
                                  [-104.164, 269.958],
                                  [133.551, 374.623],
                                  [313.215, 243.932],
                                  [142.7, -27.992],
                                  [200.814, -170.974],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.013, -9.974],
                                  [14.694, -78.608],
                                  [24.278, -71.197],
                                  [-74.304, -40.698],
                                  [-15.373, -42.582],
                                  [-160.33, 24.064],
                                  [57.978, 62.856],
                                  [-13.753, 82.98],
                                  [-17.456, 92],
                                ],
                                o: [
                                  [-84.24, 17.328],
                                  [-14.694, 78.608],
                                  [-24.278, 71.197],
                                  [74.304, 40.698],
                                  [22.203, 50.987],
                                  [106.747, -12.507],
                                  [-57.978, -62.856],
                                  [3.771, -91.91],
                                  [17.456, -92],
                                ],
                                v: [
                                  [-69.564, -332.949],
                                  [-173.865, -224.678],
                                  [-262.419, -26.936],
                                  [-243.284, 149.894],
                                  [-105.253, 269.536],
                                  [133.88, 374.282],
                                  [312.571, 243.381],
                                  [144.416, -26.114],
                                  [199.658, -170.246],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.242, -9.516],
                                  [15.246, -77.983],
                                  [23.889, -71.58],
                                  [-73.61, -40.771],
                                  [-15.345, -42.261],
                                  [-160.857, 24.304],
                                  [57.041, 62.625],
                                  [-13.358, 82.184],
                                  [-17.559, 92],
                                ],
                                o: [
                                  [-84.069, 16.951],
                                  [-15.246, 77.983],
                                  [-23.889, 71.58],
                                  [73.61, 40.771],
                                  [22.248, 50.758],
                                  [106.69, -12.621],
                                  [-57.041, -62.625],
                                  [3.268, -91.212],
                                  [17.559, -92],
                                ],
                                v: [
                                  [-68.65, -331.7],
                                  [-174.001, -225.066],
                                  [-262.306, -27.348],
                                  [-243.074, 150.037],
                                  [-106.382, 269.14],
                                  [134.238, 373.945],
                                  [311.933, 242.812],
                                  [146.213, -24.248],
                                  [198.456, -169.533],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.473, -9.054],
                                  [15.803, -77.352],
                                  [23.496, -71.967],
                                  [-72.91, -40.844],
                                  [-15.316, -41.938],
                                  [-161.388, 24.547],
                                  [56.096, 62.393],
                                  [-12.959, 81.382],
                                  [-17.663, 92],
                                ],
                                o: [
                                  [-83.895, 16.57],
                                  [-15.803, 77.352],
                                  [-23.496, 71.967],
                                  [72.91, 40.844],
                                  [22.295, 50.527],
                                  [106.632, -12.736],
                                  [-56.096, -62.393],
                                  [2.76, -90.508],
                                  [17.663, -92],
                                ],
                                v: [
                                  [-67.669, -330.45],
                                  [-174.162, -225.43],
                                  [-262.182, -27.777],
                                  [-242.91, 150.165],
                                  [-107.491, 268.74],
                                  [134.555, 373.579],
                                  [311.276, 242.317],
                                  [148.031, -22.328],
                                  [197.231, -168.805],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.706, -8.589],
                                  [16.365, -76.716],
                                  [23.1, -72.357],
                                  [-72.205, -40.919],
                                  [-15.287, -41.612],
                                  [-161.923, 24.791],
                                  [55.142, 62.159],
                                  [-12.558, 80.573],
                                  [-17.768, 92],
                                ],
                                o: [
                                  [-83.721, 16.186],
                                  [-16.365, 76.716],
                                  [-23.1, 72.357],
                                  [72.205, 40.919],
                                  [22.341, 50.294],
                                  [106.574, -12.853],
                                  [-55.142, -62.159],
                                  [2.247, -89.798],
                                  [17.768, -92],
                                ],
                                v: [
                                  [-66.677, -329.256],
                                  [-174.344, -225.769],
                                  [-261.992, -28.188],
                                  [-242.758, 150.282],
                                  [-108.639, 268.338],
                                  [134.872, 373.183],
                                  [310.621, 241.833],
                                  [149.894, -20.426],
                                  [195.988, -168.059],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.941, -8.119],
                                  [16.932, -76.075],
                                  [22.701, -72.751],
                                  [-71.492, -40.994],
                                  [-15.257, -41.283],
                                  [-162.463, 25.038],
                                  [54.181, 61.922],
                                  [-12.152, 79.756],
                                  [-17.873, 92],
                                ],
                                o: [
                                  [-83.545, 15.798],
                                  [-16.932, 76.075],
                                  [-22.701, 72.751],
                                  [71.492, 40.994],
                                  [22.388, 50.059],
                                  [106.515, -12.97],
                                  [-54.181, -61.922],
                                  [1.731, -89.081],
                                  [17.873, -92],
                                ],
                                v: [
                                  [-65.651, -328.001],
                                  [-174.521, -226.145],
                                  [-261.795, -28.615],
                                  [-242.65, 150.386],
                                  [-109.768, 267.996],
                                  [135.145, 372.789],
                                  [309.944, 241.395],
                                  [151.839, -18.475],
                                  [194.719, -167.361],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.178, -7.645],
                                  [17.503, -75.428],
                                  [22.298, -73.147],
                                  [-70.774, -41.069],
                                  [-15.228, -40.951],
                                  [-163.008, 25.286],
                                  [53.211, 61.684],
                                  [-11.744, 78.933],
                                  [-17.98, 92],
                                ],
                                o: [
                                  [-83.367, 15.407],
                                  [-17.503, 75.428],
                                  [-22.298, 73.147],
                                  [70.774, 41.069],
                                  [22.436, 49.822],
                                  [106.456, -13.089],
                                  [-53.211, -61.684],
                                  [1.209, -88.358],
                                  [17.98, -92],
                                ],
                                v: [
                                  [-64.556, -326.804],
                                  [-174.754, -226.522],
                                  [-261.562, -28.991],
                                  [-242.584, 150.5],
                                  [-110.908, 267.656],
                                  [135.446, 372.425],
                                  [309.309, 240.997],
                                  [153.768, -16.506],
                                  [193.43, -166.68],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.417, -7.167],
                                  [18.08, -74.775],
                                  [21.892, -73.548],
                                  [-70.05, -41.145],
                                  [-15.198, -40.617],
                                  [-163.558, 25.537],
                                  [52.232, 61.443],
                                  [-11.331, 78.103],
                                  [-18.087, 92],
                                ],
                                o: [
                                  [-83.188, 15.013],
                                  [-18.08, 74.775],
                                  [-21.892, 73.548],
                                  [70.05, 41.145],
                                  [22.483, 49.583],
                                  [106.396, -13.208],
                                  [-52.232, -61.443],
                                  [0.684, -87.63],
                                  [18.087, -92],
                                ],
                                v: [
                                  [-63.421, -325.576],
                                  [-174.946, -226.906],
                                  [-261.357, -29.384],
                                  [-242.56, 150.635],
                                  [-112.051, 267.376],
                                  [135.699, 372.001],
                                  [308.654, 240.612],
                                  [155.717, -14.525],
                                  [192.12, -165.987],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.658, -6.685],
                                  [18.661, -74.117],
                                  [21.482, -73.951],
                                  [-69.319, -41.222],
                                  [-15.168, -40.279],
                                  [-164.112, 25.79],
                                  [51.246, 61.201],
                                  [-10.916, 77.265],
                                  [-18.196, 92],
                                ],
                                o: [
                                  [-83.007, 14.615],
                                  [-18.661, 74.117],
                                  [-21.482, 73.951],
                                  [69.319, 41.222],
                                  [22.532, 49.342],
                                  [106.336, -13.329],
                                  [-51.246, -61.201],
                                  [0.153, -86.895],
                                  [18.196, -92],
                                ],
                                v: [
                                  [-62.279, -324.389],
                                  [-175.186, -227.287],
                                  [-261.084, -29.731],
                                  [-242.542, 150.72],
                                  [-113.21, 267.065],
                                  [135.89, 371.544],
                                  [308.036, 240.273],
                                  [157.712, -12.526],
                                  [190.816, -165.284],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.901, -6.199],
                                  [19.248, -73.453],
                                  [21.069, -74.359],
                                  [-68.582, -41.3],
                                  [-15.137, -39.939],
                                  [-164.671, 26.046],
                                  [50.25, 60.956],
                                  [-10.496, 76.42],
                                  [-18.305, 92],
                                ],
                                o: [
                                  [-82.825, 14.214],
                                  [-19.248, 73.453],
                                  [-21.069, 74.359],
                                  [68.582, 41.3],
                                  [22.58, 49.099],
                                  [106.275, -13.45],
                                  [-50.25, -60.956],
                                  [-0.381, -86.153],
                                  [18.305, -92],
                                ],
                                v: [
                                  [-61.094, -323.227],
                                  [-175.445, -227.665],
                                  [-260.778, -30.124],
                                  [-242.625, 150.817],
                                  [-114.345, 266.789],
                                  [136.048, 371.062],
                                  [307.407, 240.01],
                                  [159.788, -10.455],
                                  [189.428, -164.591],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.146, -5.708],
                                  [19.839, -72.783],
                                  [20.652, -74.769],
                                  [-67.839, -41.378],
                                  [-15.107, -39.596],
                                  [-165.235, 26.303],
                                  [49.247, 60.71],
                                  [-10.074, 75.568],
                                  [-18.416, 92],
                                ],
                                o: [
                                  [-82.641, 13.809],
                                  [-19.839, 72.783],
                                  [-20.652, 74.769],
                                  [67.839, 41.378],
                                  [22.629, 48.854],
                                  [106.214, -13.573],
                                  [-49.247, -60.71],
                                  [-0.921, -85.405],
                                  [18.416, -92],
                                ],
                                v: [
                                  [-59.839, -322.037],
                                  [-175.721, -228.072],
                                  [-260.467, -30.437],
                                  [-242.648, 150.863],
                                  [-115.526, 266.548],
                                  [136.164, 370.519],
                                  [306.78, 239.761],
                                  [161.784, -8.44],
                                  [188.07, -163.924],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.393, -5.214],
                                  [20.436, -72.108],
                                  [20.232, -75.183],
                                  [-67.09, -41.457],
                                  [-15.076, -39.25],
                                  [-165.804, 26.563],
                                  [48.235, 60.461],
                                  [-9.647, 74.709],
                                  [-18.527, 92],
                                ],
                                o: [
                                  [-82.455, 13.401],
                                  [-20.436, 72.108],
                                  [-20.232, 75.183],
                                  [67.09, 41.457],
                                  [22.679, 48.607],
                                  [106.152, -13.697],
                                  [-48.234, -60.461],
                                  [-1.465, -84.651],
                                  [18.527, -92],
                                ],
                                v: [
                                  [-58.577, -320.893],
                                  [-176.013, -228.508],
                                  [-260.098, -30.806],
                                  [-242.744, 150.954],
                                  [-116.692, 266.344],
                                  [136.186, 369.979],
                                  [306.171, 239.524],
                                  [163.886, -6.374],
                                  [186.662, -163.213],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.642, -4.715],
                                  [21.037, -71.426],
                                  [19.808, -75.601],
                                  [-66.334, -41.536],
                                  [-15.045, -38.901],
                                  [-166.378, 26.825],
                                  [47.214, 60.21],
                                  [-9.217, 73.842],
                                  [-18.639, 92],
                                ],
                                o: [
                                  [-82.268, 12.99],
                                  [-21.037, 71.426],
                                  [-19.808, 75.601],
                                  [66.334, 41.536],
                                  [22.729, 48.358],
                                  [106.089, -13.821],
                                  [-47.214, -60.21],
                                  [-2.013, -83.89],
                                  [18.639, -92],
                                ],
                                v: [
                                  [-57.271, -319.814],
                                  [-176.283, -228.909],
                                  [-259.788, -31.158],
                                  [-242.84, 150.991],
                                  [-117.857, 266.15],
                                  [136.176, 369.389],
                                  [305.611, 239.401],
                                  [166.005, -4.277],
                                  [185.219, -162.546],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [123.894, -4.212],
                                  [21.644, -70.74],
                                  [19.38, -76.022],
                                  [-65.571, -41.616],
                                  [-15.013, -38.548],
                                  [-166.956, 27.089],
                                  [46.184, 59.957],
                                  [-8.783, 72.968],
                                  [-18.752, 92],
                                ],
                                o: [
                                  [-82.08, 12.575],
                                  [-21.644, 70.74],
                                  [-19.38, 76.022],
                                  [65.571, 41.616],
                                  [22.779, 48.106],
                                  [106.026, -13.947],
                                  [-46.184, -59.957],
                                  [-2.567, -83.123],
                                  [18.752, -92],
                                ],
                                v: [
                                  [-55.893, -318.711],
                                  [-176.602, -229.324],
                                  [-259.386, -31.468],
                                  [-242.944, 151.048],
                                  [-119.045, 266.022],
                                  [136.123, 368.729],
                                  [305.062, 239.292],
                                  [168.105, -2.204],
                                  [183.75, -161.836],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.148, -3.705],
                                  [22.256, -70.047],
                                  [18.949, -76.447],
                                  [-64.803, -41.697],
                                  [-14.982, -38.193],
                                  [-167.539, 27.355],
                                  [45.146, 59.701],
                                  [-8.345, 72.087],
                                  [-18.866, 92],
                                ],
                                o: [
                                  [-81.889, 12.156],
                                  [-22.256, 70.047],
                                  [-18.949, 76.447],
                                  [64.803, 41.697],
                                  [22.83, 47.852],
                                  [105.963, -14.074],
                                  [-45.146, -59.701],
                                  [-3.125, -82.35],
                                  [18.866, -92],
                                ],
                                v: [
                                  [-54.507, -317.624],
                                  [-176.896, -229.737],
                                  [-258.956, -31.8],
                                  [-243.083, 151.05],
                                  [-120.27, 265.871],
                                  [135.977, 368.11],
                                  [304.525, 239.197],
                                  [170.259, -0.118],
                                  [182.256, -161.157],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.403, -3.193],
                                  [22.873, -69.348],
                                  [18.514, -76.876],
                                  [-64.027, -41.779],
                                  [-14.95, -37.835],
                                  [-168.128, 27.624],
                                  [44.099, 59.444],
                                  [-7.904, 71.198],
                                  [-18.982, 92],
                                ],
                                o: [
                                  [-81.697, 11.734],
                                  [-22.873, 69.348],
                                  [-18.514, 76.876],
                                  [64.027, 41.779],
                                  [22.881, 47.597],
                                  [105.899, -14.202],
                                  [-44.099, -59.444],
                                  [-3.688, -81.57],
                                  [18.982, -92],
                                ],
                                v: [
                                  [-53.075, -316.603],
                                  [-177.2, -230.136],
                                  [-258.523, -32.154],
                                  [-243.216, 151.033],
                                  [-121.481, 265.823],
                                  [135.802, 367.393],
                                  [304.051, 239.218],
                                  [172.417, 1.991],
                                  [180.76, -160.486],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.661, -2.677],
                                  [23.496, -68.644],
                                  [18.076, -77.308],
                                  [-63.245, -41.861],
                                  [-14.917, -37.474],
                                  [-168.721, 27.894],
                                  [43.042, 59.185],
                                  [-7.459, 70.302],
                                  [-19.098, 92],
                                ],
                                o: [
                                  [-81.504, 11.309],
                                  [-23.496, 68.644],
                                  [-18.076, 77.308],
                                  [63.245, 41.861],
                                  [22.932, 47.339],
                                  [105.835, -14.331],
                                  [-43.043, -59.185],
                                  [-4.255, -80.783],
                                  [19.098, -92],
                                ],
                                v: [
                                  [-51.594, -315.602],
                                  [-177.489, -230.493],
                                  [-258.049, -32.494],
                                  [-243.381, 150.998],
                                  [-122.703, 265.756],
                                  [135.559, 366.693],
                                  [303.578, 239.279],
                                  [174.616, 4.112],
                                  [179.252, -159.771],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [124.921, -2.157],
                                  [24.123, -67.933],
                                  [17.633, -77.743],
                                  [-62.457, -41.944],
                                  [-14.885, -37.11],
                                  [-169.319, 28.168],
                                  [41.978, 58.923],
                                  [-7.01, 69.398],
                                  [-19.215, 92],
                                ],
                                o: [
                                  [-81.309, 10.88],
                                  [-24.123, 67.933],
                                  [-17.633, 77.743],
                                  [62.457, 41.944],
                                  [22.984, 47.078],
                                  [105.77, -14.461],
                                  [-41.978, -58.923],
                                  [-4.827, -79.989],
                                  [19.215, -92],
                                ],
                                v: [
                                  [-50.103, -314.645],
                                  [-177.785, -230.846],
                                  [-257.564, -32.872],
                                  [-243.551, 150.904],
                                  [-123.938, 265.732],
                                  [135.289, 365.896],
                                  [303.132, 239.433],
                                  [176.829, 6.204],
                                  [177.742, -159.075],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.184, -1.632],
                                  [24.756, -67.217],
                                  [17.188, -78.183],
                                  [-61.662, -42.027],
                                  [-14.852, -36.743],
                                  [-169.923, 28.443],
                                  [40.904, 58.659],
                                  [-6.558, 68.486],
                                  [-19.333, 92],
                                ],
                                o: [
                                  [-81.112, 10.447],
                                  [-24.756, 67.217],
                                  [-17.188, 78.183],
                                  [61.662, 42.027],
                                  [23.037, 46.816],
                                  [105.704, -14.592],
                                  [-40.904, -58.659],
                                  [-5.404, -79.189],
                                  [19.333, -92],
                                ],
                                v: [
                                  [-48.563, -313.712],
                                  [-178.087, -231.242],
                                  [-257.077, -33.238],
                                  [-243.729, 150.854],
                                  [-125.201, 265.753],
                                  [134.975, 365.102],
                                  [302.741, 239.603],
                                  [179.059, 8.291],
                                  [176.205, -158.4],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [125.448, -1.103],
                                  [25.394, -66.494],
                                  [16.738, -78.626],
                                  [-60.86, -42.112],
                                  [-14.819, -36.372],
                                  [-170.531, 28.721],
                                  [39.821, 58.393],
                                  [-6.102, 67.567],
                                  [-19.452, 92],
                                ],
                                o: [
                                  [-80.914, 10.01],
                                  [-25.394, 66.494],
                                  [-16.738, 78.626],
                                  [60.86, 42.112],
                                  [23.09, 46.552],
                                  [105.638, -14.724],
                                  [-39.821, -58.393],
                                  [-5.986, -78.383],
                                  [19.452, -92],
                                ],
                                v: [
                                  [-46.949, -312.85],
                                  [-178.372, -231.592],
                                  [-256.528, -33.631],
                                  [-243.895, 150.76],
                                  [-126.477, 265.86],
                                  [134.572, 364.312],
                                  [302.365, 239.814],
                                  [181.264, 10.408],
                                  [174.704, -157.705],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [23.031, 25.719],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.562, 24.938],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.125, 24.156],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.312, 22.531],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.625, 20.969],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.344, 20.25],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.031, 19.562],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.781, 18.906],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.344, 17.594],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.156, 17.062],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.031, 16.469],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.875, 15.906],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.781, 15.438],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, 14.906],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.625, 14.469],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.594, 14.031],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.562, 13.625],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.562, 13.219],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.594, 12.781],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.625, 12.438],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.719, 12.094],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.75, 11.781],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.906, 11.469],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.969, 11.156],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.031, 10.906],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.875, 10.625],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.719, 10.375],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.562, 10.156],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.469, 9.906],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.375, 9.719],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.281, 9.5],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.281, 9.312],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.25, 9.094],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.25, 8.938],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.25, 8.562],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.281, 8.406],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.312, 8.281],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.375, 8.062],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.469, 7.906],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.531, 7.781],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.625, 7.625],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.719, 7.531],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.812, 7.406],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.906, 7.375],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19, 7.312],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.25, 7.25],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.406, 7.219],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.562, 7.281],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.75, 7.281],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.938, 7.312],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.125, 7.375],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.375, 7.438],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.594, 7.531],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.875, 7.594],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.125, 7.688],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.375, 7.812],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.656, 7.906],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.906, 8.062],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.188, 8.188],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.5, 8.312],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 8.469],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.719, 8.938],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.031, 9.125],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.281, 9.281],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.656, 9.5],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 9.688],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.219, 9.906],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.531, 10.062],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 10.312],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.156, 10.5],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.438, 10.719],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 10.938],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27, 11.156],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.938, 11.406],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.906, 11.625],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.812, 11.844],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 12.062],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.625, 12.312],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.531, 12.562],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.406, 12.812],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.312, 13.031],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, 13.25],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 13.562],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.969, 13.875],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.812, 14.156],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.688, 14.5],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.531, 14.812],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.406, 15.188],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.25, 15.469],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.125, 15.875],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 16.219],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.812, 16.594],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.688, 17],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.562, 17.375],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.406, 17.75],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.25, 18.156],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.156, 18.5],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.031, 18.906],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.906, 19.281],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.812, 19.625],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.688, 20],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.594, 20.406],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.531, 20.781],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.406, 21.094],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 21.469],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.25, 21.812],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.125, 22.188],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.094, 22.469],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 22.781],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.969, 23.125],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.906, 23.406],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 23.625],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.812, 23.875],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.75, 24.125],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 24.406],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 24.656],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 24.844],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 25.031],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.875, 25.156],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 25.281],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.562, 25.344],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.25, 25.406],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.969, 25.375],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.688, 25.406],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.344, 25.375],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.094, 25.344],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.781, 25.281],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.219, 25.094],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20, 24.938],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.75, 24.781],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.5, 24.656],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.281, 24.406],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.031, 24.188],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.875, 23.969],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.656, 23.688],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.5, 23.406],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.25, 22.781],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.094, 22.438],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.031, 22.062],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, 21.719],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.906, 21.312],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.844, 20.938],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.844, 20.531],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.906, 19.656],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, 19.25],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18, 18.781],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.125, 18.344],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.219, 17.906],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.344, 17.438],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.531, 16.938],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.719, 16.5],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.906, 16.031],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.125, 15.594],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.375, 15.125],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.656, 14.688],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.938, 14.219],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.25, 13.812],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.594, 13.406],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.969, 12.969],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.312, 12.594],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.719, 12.188],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.594, 11.5],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.062, 11.188],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.531, 10.844],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 10.281],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.094, 10.094],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.562, 9.875],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.062, 9.719],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 9.531],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, 9.438],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.594, 9.312],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.062, 9.25],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.531, 9.25],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.969, 9.25],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.438, 9.25],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.906, 9.344],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.312, 9.406],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 9.531],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.188, 9.688],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.625, 9.812],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, 10],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.375, 10.156],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.656, 10.406],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.469, 10.625],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.031, 11.125],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.812, 11.406],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.562, 11.688],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.344, 12],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.062, 12.312],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 12.656],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.5, 13],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.281, 13.344],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.938, 13.719],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.375, 14.531],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.031, 14.938],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.75, 15.344],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.406, 15.719],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.094, 16.188],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.812, 16.594],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.469, 17.062],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.156, 17.5],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.844, 17.969],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.531, 18.406],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, 18.844],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.938, 19.344],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.625, 19.75],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.062, 20.688],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.812, 21.125],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 21.562],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, 21.969],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.062, 22.406],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.875, 22.812],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.625, 23.219],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 23.594],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 23.906],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.156, 24.219],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 24.562],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.906, 24.781],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 25.031],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 25.25],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 25.375],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.75, 25.531],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 25.625],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 25.719],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.938, 25.719],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [23.031, 25.719],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.562, 24.938],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.125, 24.156],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.312, 22.531],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.625, 20.969],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.344, 20.25],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.031, 19.562],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.781, 18.906],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.344, 17.594],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.156, 17.062],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.031, 16.469],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.875, 15.906],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.781, 15.438],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, 14.906],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.625, 14.469],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.594, 14.031],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.562, 13.625],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.562, 13.219],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.594, 12.781],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.625, 12.438],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.719, 12.094],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.75, 11.781],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.906, 11.469],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.969, 11.156],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.031, 10.906],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.875, 10.625],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.719, 10.375],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.562, 10.156],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.469, 9.906],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.375, 9.719],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.281, 9.5],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.281, 9.312],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.25, 9.094],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.25, 8.938],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.25, 8.562],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.281, 8.406],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.312, 8.281],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.375, 8.062],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.469, 7.906],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.531, 7.781],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.625, 7.625],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.719, 7.531],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.812, 7.406],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.906, 7.375],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19, 7.312],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.25, 7.25],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.406, 7.219],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.562, 7.281],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.75, 7.281],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.938, 7.312],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.125, 7.375],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.375, 7.438],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.594, 7.531],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.875, 7.594],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.125, 7.688],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.375, 7.812],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.656, 7.906],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.906, 8.062],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.188, 8.188],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.5, 8.312],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 8.469],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.719, 8.938],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.031, 9.125],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.281, 9.281],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.656, 9.5],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 9.688],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.219, 9.906],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.531, 10.062],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 10.312],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.156, 10.5],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.438, 10.719],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 10.938],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27, 11.156],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.938, 11.406],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.906, 11.625],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.812, 11.844],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 12.062],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.625, 12.312],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.531, 12.562],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.406, 12.812],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.312, 13.031],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, 13.25],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 13.562],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.969, 13.875],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.812, 14.156],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.688, 14.5],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.531, 14.812],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.406, 15.188],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.25, 15.469],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.125, 15.875],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 16.219],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.812, 16.594],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.688, 17],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.562, 17.375],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.406, 17.75],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.25, 18.156],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.156, 18.5],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.031, 18.906],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.906, 19.281],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.812, 19.625],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.688, 20],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.594, 20.406],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.531, 20.781],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.406, 21.094],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 21.469],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.25, 21.812],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.125, 22.188],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.094, 22.469],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 22.781],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.969, 23.125],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.906, 23.406],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 23.625],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.812, 23.875],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.75, 24.125],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 24.406],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 24.656],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 24.844],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 25.031],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.875, 25.156],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 25.281],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.562, 25.344],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.25, 25.406],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.969, 25.375],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.688, 25.406],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.344, 25.375],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.094, 25.344],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.781, 25.281],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.219, 25.094],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20, 24.938],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.75, 24.781],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.5, 24.656],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.281, 24.406],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.031, 24.188],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.875, 23.969],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.656, 23.688],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.5, 23.406],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.25, 22.781],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.094, 22.438],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.031, 22.062],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, 21.719],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.906, 21.312],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.844, 20.938],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.844, 20.531],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.906, 19.656],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, 19.25],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18, 18.781],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.125, 18.344],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.219, 17.906],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.344, 17.438],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.531, 16.938],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.719, 16.5],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.906, 16.031],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.125, 15.594],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.375, 15.125],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.656, 14.688],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.938, 14.219],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.25, 13.812],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.594, 13.406],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.969, 12.969],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.312, 12.594],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.719, 12.188],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.594, 11.5],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.062, 11.188],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.531, 10.844],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 10.281],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.094, 10.094],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.562, 9.875],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.062, 9.719],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 9.531],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, 9.438],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.594, 9.312],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.062, 9.25],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.531, 9.25],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.969, 9.25],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.438, 9.25],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.906, 9.344],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.312, 9.406],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 9.531],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.188, 9.688],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.625, 9.812],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, 10],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.375, 10.156],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.656, 10.406],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.469, 10.625],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.031, 11.125],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.812, 11.406],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.562, 11.688],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.344, 12],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.062, 12.312],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 12.656],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.5, 13],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.281, 13.344],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.938, 13.719],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.375, 14.531],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.031, 14.938],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.75, 15.344],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.406, 15.719],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.094, 16.188],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.812, 16.594],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.469, 17.062],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.156, 17.5],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.844, 17.969],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.531, 18.406],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, 18.844],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.938, 19.344],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.625, 19.75],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.062, 20.688],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.812, 21.125],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.531, 21.562],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, 21.969],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.062, 22.406],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.875, 22.812],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.625, 23.219],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 23.594],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 23.906],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.156, 24.219],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 24.562],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.906, 24.781],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 25.031],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 25.25],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 25.375],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.75, 25.531],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 25.625],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.844, 25.719],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.938, 25.719],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 6',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 6,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [122.858, -6.284],
                                  [19.145, -73.569],
                                  [21.141, -74.287],
                                  [-68.712, -41.286],
                                  [-15.143, -39.999],
                                  [-164.573, 26.001],
                                  [50.425, 60.999],
                                  [-10.57, 76.569],
                                  [-18.286, 92],
                                ],
                                o: [
                                  [-82.857, 14.284],
                                  [-19.145, 73.569],
                                  [-21.142, 74.287],
                                  [68.712, 41.286],
                                  [22.572, 49.142],
                                  [106.286, -13.429],
                                  [-50.425, -60.999],
                                  [-0.287, -86.283],
                                  [18.286, -92],
                                ],
                                v: [
                                  [-55.702, -323.782],
                                  [-177.357, -224.547],
                                  [-258.294, -28.796],
                                  [-243.009, 150.366],
                                  [-112.085, 272.159],
                                  [135.883, 368.356],
                                  [297.582, 237.947],
                                  [161.167, -8.887],
                                  [185.934, -164.685],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.574, -6.853],
                                  [18.459, -74.346],
                                  [21.625, -73.811],
                                  [-69.573, -41.195],
                                  [-15.178, -40.397],
                                  [-163.92, 25.702],
                                  [51.589, 61.285],
                                  [-11.06, 77.556],
                                  [-18.158, 92],
                                ],
                                o: [
                                  [-83.07, 14.753],
                                  [-18.459, 74.346],
                                  [-21.625, 73.811],
                                  [69.573, 41.195],
                                  [22.515, 49.426],
                                  [106.357, -13.287],
                                  [-51.589, -61.285],
                                  [0.338, -87.15],
                                  [18.158, -92],
                                ],
                                v: [
                                  [-56.092, -325.075],
                                  [-177.546, -223.521],
                                  [-258.212, -28.107],
                                  [-243.161, 150.501],
                                  [-110.357, 273.255],
                                  [135.6, 368.171],
                                  [296.52, 237.81],
                                  [159.444, -10.606],
                                  [187.25, -165.3],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.292, -7.415],
                                  [17.781, -75.114],
                                  [22.103, -73.34],
                                  [-70.426, -41.106],
                                  [-15.213, -40.791],
                                  [-163.273, 25.407],
                                  [52.74, 61.568],
                                  [-11.546, 78.534],
                                  [-18.032, 92],
                                ],
                                o: [
                                  [-83.281, 15.217],
                                  [-17.781, 75.114],
                                  [-22.103, 73.34],
                                  [70.426, 41.106],
                                  [22.458, 49.708],
                                  [106.427, -13.146],
                                  [-52.74, -61.568],
                                  [0.957, -88.008],
                                  [18.032, -92],
                                ],
                                v: [
                                  [-56.439, -326.357],
                                  [-177.693, -222.575],
                                  [-258.121, -27.427],
                                  [-243.27, 150.661],
                                  [-108.58, 274.295],
                                  [135.257, 367.978],
                                  [295.482, 237.701],
                                  [157.722, -12.284],
                                  [188.57, -165.913],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.014, -7.972],
                                  [17.109, -75.874],
                                  [22.576, -72.874],
                                  [-71.27, -41.017],
                                  [-15.248, -41.18],
                                  [-162.632, 25.115],
                                  [53.88, 61.848],
                                  [-12.026, 79.501],
                                  [-17.906, 92],
                                ],
                                o: [
                                  [-83.489, 15.677],
                                  [-17.109, 75.874],
                                  [-22.576, 72.874],
                                  [71.27, 41.017],
                                  [22.403, 49.986],
                                  [106.496, -13.007],
                                  [-53.88, -61.848],
                                  [1.569, -88.857],
                                  [17.906, -92],
                                ],
                                v: [
                                  [-56.808, -327.629],
                                  [-177.889, -221.611],
                                  [-258.005, -26.732],
                                  [-243.403, 150.846],
                                  [-106.746, 275.286],
                                  [134.901, 367.742],
                                  [294.495, 237.583],
                                  [156.022, -13.955],
                                  [189.895, -166.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.739, -8.523],
                                  [16.444, -76.626],
                                  [23.044, -72.412],
                                  [-72.105, -40.929],
                                  [-15.283, -41.566],
                                  [-161.999, 24.826],
                                  [55.007, 62.125],
                                  [-12.501, 80.458],
                                  [-17.782, 92],
                                ],
                                o: [
                                  [-83.696, 16.131],
                                  [-16.444, 76.626],
                                  [-23.044, 72.412],
                                  [72.105, 40.929],
                                  [22.348, 50.261],
                                  [106.565, -12.869],
                                  [-55.007, -62.126],
                                  [2.175, -89.697],
                                  [17.782, -92],
                                ],
                                v: [
                                  [-57.172, -328.884],
                                  [-178.107, -220.629],
                                  [-257.851, -26.077],
                                  [-243.53, 151.063],
                                  [-104.927, 276.257],
                                  [134.484, 367.498],
                                  [293.525, 237.52],
                                  [154.386, -15.591],
                                  [191.229, -166.897],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.466, -9.068],
                                  [15.787, -77.371],
                                  [23.508, -71.956],
                                  [-72.931, -40.842],
                                  [-15.317, -41.948],
                                  [-161.372, 24.539],
                                  [56.123, 62.4],
                                  [-12.971, 81.405],
                                  [-17.66, 92],
                                ],
                                o: [
                                  [-83.9, 16.581],
                                  [-15.787, 77.371],
                                  [-23.508, 71.956],
                                  [72.931, 40.842],
                                  [22.293, 50.534],
                                  [106.633, -12.733],
                                  [-56.123, -62.4],
                                  [2.775, -90.529],
                                  [17.66, -92],
                                ],
                                v: [
                                  [-57.535, -330.066],
                                  [-178.318, -219.728],
                                  [-257.645, -25.397],
                                  [-243.647, 151.302],
                                  [-103.088, 277.177],
                                  [134.032, 367.247],
                                  [292.578, 237.478],
                                  [152.745, -17.212],
                                  [192.628, -167.327],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.196, -9.607],
                                  [15.136, -78.107],
                                  [23.966, -71.504],
                                  [-73.749, -40.756],
                                  [-15.35, -42.325],
                                  [-160.752, 24.256],
                                  [57.227, 62.671],
                                  [-13.436, 82.343],
                                  [-17.538, 92],
                                ],
                                o: [
                                  [-84.103, 17.026],
                                  [-15.136, 78.107],
                                  [-23.966, 71.504],
                                  [73.749, 40.756],
                                  [22.239, 50.804],
                                  [106.701, -12.598],
                                  [-57.227, -62.671],
                                  [3.368, -91.351],
                                  [17.538, -92],
                                ],
                                v: [
                                  [-57.949, -331.138],
                                  [-178.578, -218.81],
                                  [-257.421, -24.72],
                                  [-243.762, 151.534],
                                  [-101.201, 278.074],
                                  [133.511, 366.926],
                                  [291.619, 237.458],
                                  [151.132, -18.797],
                                  [193.996, -167.704],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.929, -10.141],
                                  [14.492, -78.836],
                                  [24.42, -71.057],
                                  [-74.558, -40.671],
                                  [-15.384, -42.699],
                                  [-160.138, 23.976],
                                  [58.32, 62.94],
                                  [-13.897, 83.27],
                                  [-17.418, 92],
                                ],
                                o: [
                                  [-84.303, 17.466],
                                  [-14.492, 78.836],
                                  [-24.42, 71.057],
                                  [74.558, 40.671],
                                  [22.186, 51.071],
                                  [106.768, -12.465],
                                  [-58.32, -62.94],
                                  [3.955, -92.165],
                                  [17.418, -92],
                                ],
                                v: [
                                  [-58.361, -332.169],
                                  [-178.829, -217.911],
                                  [-257.151, -24.083],
                                  [-243.904, 151.757],
                                  [-99.326, 278.921],
                                  [132.983, 366.628],
                                  [290.742, 237.465],
                                  [149.516, -20.401],
                                  [195.432, -168.021],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.665, -10.669],
                                  [13.855, -79.558],
                                  [24.869, -70.615],
                                  [-75.358, -40.587],
                                  [-15.417, -43.068],
                                  [-159.53, 23.699],
                                  [59.401, 63.205],
                                  [-14.352, 84.188],
                                  [-17.299, 92],
                                ],
                                o: [
                                  [-84.501, 17.902],
                                  [-13.855, 79.558],
                                  [-24.869, 70.614],
                                  [75.358, 40.587],
                                  [22.133, 51.335],
                                  [106.834, -12.333],
                                  [-59.401, -63.205],
                                  [4.536, -92.971],
                                  [17.299, -92],
                                ],
                                v: [
                                  [-58.829, -333.093],
                                  [-179.068, -217.059],
                                  [-256.863, -23.384],
                                  [-244.011, 152.001],
                                  [-97.436, 279.716],
                                  [132.386, 366.293],
                                  [289.825, 237.466],
                                  [147.991, -21.933],
                                  [196.806, -168.285],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.404, -11.192],
                                  [13.225, -80.271],
                                  [25.313, -70.177],
                                  [-76.15, -40.504],
                                  [-15.449, -43.434],
                                  [-158.929, 23.424],
                                  [60.471, 63.468],
                                  [-14.803, 85.096],
                                  [-17.182, 92],
                                ],
                                o: [
                                  [-84.697, 18.333],
                                  [-13.225, 80.271],
                                  [-25.313, 70.177],
                                  [76.15, 40.504],
                                  [22.081, 51.596],
                                  [106.899, -12.202],
                                  [-60.471, -63.468],
                                  [5.111, -93.768],
                                  [17.182, -92],
                                ],
                                v: [
                                  [-59.321, -334.01],
                                  [-179.358, -216.164],
                                  [-256.558, -22.688],
                                  [-244.116, 152.297],
                                  [-95.562, 280.493],
                                  [131.752, 365.981],
                                  [289.022, 237.49],
                                  [146.433, -23.454],
                                  [198.244, -168.558],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.146, -11.709],
                                  [12.601, -80.977],
                                  [25.753, -69.744],
                                  [-76.934, -40.421],
                                  [-15.482, -43.796],
                                  [-158.335, 23.153],
                                  [61.529, 63.729],
                                  [-15.249, 85.994],
                                  [-17.065, 92],
                                ],
                                o: [
                                  [-84.891, 18.76],
                                  [-12.601, 80.977],
                                  [-25.753, 69.744],
                                  [76.934, 40.421],
                                  [22.029, 51.854],
                                  [106.964, -12.073],
                                  [-61.529, -63.729],
                                  [5.68, -94.556],
                                  [17.065, -92],
                                ],
                                v: [
                                  [-59.806, -334.794],
                                  [-179.636, -215.317],
                                  [-256.206, -21.991],
                                  [-244.252, 152.549],
                                  [-93.705, 281.188],
                                  [131.111, 365.601],
                                  [288.146, 237.54],
                                  [144.903, -24.934],
                                  [199.682, -168.747],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.89, -12.221],
                                  [11.984, -81.676],
                                  [26.187, -69.315],
                                  [-77.709, -40.34],
                                  [-15.514, -44.154],
                                  [-157.746, 22.884],
                                  [62.576, 63.986],
                                  [-15.69, 86.883],
                                  [-16.95, 92],
                                ],
                                o: [
                                  [-85.083, 19.182],
                                  [-11.984, 81.676],
                                  [-26.187, 69.315],
                                  [77.709, 40.34],
                                  [21.978, 52.11],
                                  [107.028, -11.945],
                                  [-62.576, -63.986],
                                  [6.243, -95.336],
                                  [16.95, -92],
                                ],
                                v: [
                                  [-60.314, -335.574],
                                  [-179.933, -214.489],
                                  [-255.836, -21.292],
                                  [-244.357, 152.881],
                                  [-91.802, 281.928],
                                  [130.431, 365.215],
                                  [287.354, 237.616],
                                  [143.402, -26.404],
                                  [201.121, -168.885],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.637, -12.727],
                                  [11.373, -82.367],
                                  [26.618, -68.891],
                                  [-78.476, -40.259],
                                  [-15.545, -44.509],
                                  [-157.164, 22.618],
                                  [63.613, 64.241],
                                  [-16.127, 87.763],
                                  [-16.836, 92],
                                ],
                                o: [
                                  [-85.272, 19.6],
                                  [-11.373, 82.367],
                                  [-26.618, 68.891],
                                  [78.476, 40.259],
                                  [21.927, 52.363],
                                  [107.091, -11.818],
                                  [-63.612, -64.241],
                                  [6.799, -96.108],
                                  [16.836, -92],
                                ],
                                v: [
                                  [-60.846, -336.226],
                                  [-180.217, -213.679],
                                  [-255.449, -20.56],
                                  [-244.494, 153.166],
                                  [-89.916, 282.557],
                                  [129.682, 364.885],
                                  [286.583, 237.688],
                                  [141.929, -27.831],
                                  [202.591, -169.004],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.386, -13.228],
                                  [10.769, -83.051],
                                  [27.043, -68.472],
                                  [-79.235, -40.179],
                                  [-15.577, -44.859],
                                  [-156.588, 22.356],
                                  [64.638, 64.493],
                                  [-16.559, 88.633],
                                  [-16.724, 92],
                                ],
                                o: [
                                  [-85.46, 20.013],
                                  [-10.769, 83.051],
                                  [-27.043, 68.472],
                                  [79.235, 40.179],
                                  [21.877, 52.614],
                                  [107.153, -11.693],
                                  [-64.638, -64.493],
                                  [7.35, -96.872],
                                  [16.724, -92],
                                ],
                                v: [
                                  [-61.4, -336.846],
                                  [-180.487, -212.857],
                                  [-255.013, -19.855],
                                  [-244.603, 153.465],
                                  [-88.019, 283.202],
                                  [128.924, 364.486],
                                  [285.835, 237.818],
                                  [140.456, -29.279],
                                  [204.058, -169.075],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.139, -13.723],
                                  [10.172, -83.728],
                                  [27.465, -68.057],
                                  [-79.986, -40.1],
                                  [-15.608, -45.206],
                                  [-156.019, 22.095],
                                  [65.652, 64.742],
                                  [-16.986, 89.494],
                                  [-16.612, 92],
                                ],
                                o: [
                                  [-85.646, 20.421],
                                  [-10.172, 83.728],
                                  [-27.465, 68.057],
                                  [79.986, 40.1],
                                  [21.828, 52.862],
                                  [107.215, -11.569],
                                  [-65.652, -64.742],
                                  [7.895, -97.628],
                                  [16.612, -92],
                                ],
                                v: [
                                  [-61.975, -337.467],
                                  [-180.807, -212.084],
                                  [-254.562, -19.113],
                                  [-244.713, 153.779],
                                  [-86.169, 283.798],
                                  [128.127, 364.114],
                                  [285.141, 237.945],
                                  [139.01, -30.653],
                                  [205.493, -169.131],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.893, -14.213],
                                  [9.58, -84.397],
                                  [27.881, -67.646],
                                  [-80.729, -40.022],
                                  [-15.638, -45.549],
                                  [-155.455, 21.838],
                                  [66.655, 64.989],
                                  [-17.409, 90.345],
                                  [-16.502, 92],
                                ],
                                o: [
                                  [-85.83, 20.826],
                                  [-9.58, 84.397],
                                  [-27.881, 67.646],
                                  [80.729, 40.022],
                                  [21.779, 53.107],
                                  [107.277, -11.447],
                                  [-66.655, -64.988],
                                  [8.434, -98.375],
                                  [16.502, -92],
                                ],
                                v: [
                                  [-62.575, -337.966],
                                  [-181.078, -211.298],
                                  [-254.125, -18.365],
                                  [-244.858, 154.104],
                                  [-84.308, 284.377],
                                  [127.321, 363.704],
                                  [284.438, 238.039],
                                  [137.562, -32.019],
                                  [206.957, -169.139],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.651, -14.698],
                                  [8.995, -85.06],
                                  [28.293, -67.24],
                                  [-81.464, -39.945],
                                  [-15.669, -45.889],
                                  [-154.897, 21.583],
                                  [67.648, 65.232],
                                  [-17.827, 91.188],
                                  [-16.393, 92],
                                ],
                                o: [
                                  [-86.012, 21.226],
                                  [-8.995, 85.06],
                                  [-28.293, 67.24],
                                  [81.464, 39.945],
                                  [21.73, 53.349],
                                  [107.337, -11.326],
                                  [-67.648, -65.232],
                                  [8.968, -99.115],
                                  [16.393, -92],
                                ],
                                v: [
                                  [-63.196, -338.468],
                                  [-181.398, -210.531],
                                  [-253.609, -17.581],
                                  [-244.977, 154.408],
                                  [-82.431, 284.882],
                                  [126.444, 363.293],
                                  [283.76, 238.193],
                                  [136.143, -33.341],
                                  [208.448, -169.102],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.411, -15.178],
                                  [8.417, -85.715],
                                  [28.701, -66.839],
                                  [-82.191, -39.869],
                                  [-15.699, -46.224],
                                  [-154.345, 21.332],
                                  [68.63, 65.474],
                                  [-18.241, 92.021],
                                  [-16.285, 92],
                                ],
                                o: [
                                  [-86.192, 21.622],
                                  [-8.417, 85.715],
                                  [-28.701, 66.839],
                                  [82.191, 39.869],
                                  [21.682, 53.589],
                                  [107.397, -11.206],
                                  [-68.63, -65.474],
                                  [9.496, -99.846],
                                  [16.285, -92],
                                ],
                                v: [
                                  [-63.808, -338.885],
                                  [-181.638, -209.782],
                                  [-253.112, -16.817],
                                  [-245.127, 154.689],
                                  [-80.577, 285.402],
                                  [125.589, 362.905],
                                  [283.137, 238.344],
                                  [134.785, -34.655],
                                  [209.907, -169.055],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.174, -15.652],
                                  [7.844, -86.363],
                                  [29.105, -66.441],
                                  [-82.911, -39.793],
                                  [-15.728, -46.557],
                                  [-153.8, 21.082],
                                  [69.601, 65.713],
                                  [-18.65, 92.846],
                                  [-16.178, 92],
                                ],
                                o: [
                                  [-86.37, 22.013],
                                  [-7.844, 86.363],
                                  [-29.105, 66.441],
                                  [82.911, 39.793],
                                  [21.635, 53.826],
                                  [107.457, -11.087],
                                  [-69.601, -65.713],
                                  [10.018, -100.57],
                                  [16.178, -92],
                                ],
                                v: [
                                  [-64.445, -339.31],
                                  [-181.956, -209.051],
                                  [-252.593, -15.984],
                                  [-245.252, 155.01],
                                  [-78.769, 285.912],
                                  [124.662, 362.547],
                                  [282.568, 238.498],
                                  [133.394, -35.923],
                                  [211.392, -168.995],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.939, -16.122],
                                  [7.278, -87.004],
                                  [29.504, -66.048],
                                  [-83.622, -39.718],
                                  [-15.758, -46.885],
                                  [-153.26, 20.836],
                                  [70.562, 65.949],
                                  [-19.055, 93.662],
                                  [-16.073, 92],
                                ],
                                o: [
                                  [-86.546, 22.401],
                                  [-7.278, 87.004],
                                  [-29.504, 66.048],
                                  [83.622, 39.718],
                                  [21.588, 54.061],
                                  [107.515, -10.97],
                                  [-70.562, -65.949],
                                  [10.534, -101.286],
                                  [16.073, -92],
                                ],
                                v: [
                                  [-65.102, -339.681],
                                  [-182.192, -208.336],
                                  [-252.065, -15.172],
                                  [-245.387, 155.34],
                                  [-76.951, 286.346],
                                  [123.727, 362.179],
                                  [281.965, 238.685],
                                  [132.03, -37.186],
                                  [212.84, -168.894],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.707, -16.586],
                                  [6.718, -87.638],
                                  [29.898, -65.659],
                                  [-84.326, -39.644],
                                  [-15.787, -47.21],
                                  [-152.726, 20.592],
                                  [71.513, 66.182],
                                  [-19.456, 94.469],
                                  [-15.968, 92],
                                ],
                                o: [
                                  [-86.72, 22.784],
                                  [-6.718, 87.638],
                                  [-29.898, 65.659],
                                  [84.326, 39.644],
                                  [21.541, 54.293],
                                  [107.573, -10.853],
                                  [-71.513, -66.182],
                                  [11.045, -101.994],
                                  [15.968, -92],
                                ],
                                v: [
                                  [-65.785, -340.029],
                                  [-182.477, -207.639],
                                  [-251.522, -14.318],
                                  [-245.554, 155.611],
                                  [-75.116, 286.767],
                                  [122.784, 361.811],
                                  [281.418, 238.9],
                                  [130.667, -38.408],
                                  [214.346, -168.781],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.477, -17.046],
                                  [6.164, -88.266],
                                  [30.289, -65.274],
                                  [-85.022, -39.571],
                                  [-15.815, -47.532],
                                  [-152.197, 20.351],
                                  [72.453, 66.414],
                                  [-19.852, 95.267],
                                  [-15.865, 92],
                                ],
                                o: [
                                  [-86.892, 23.163],
                                  [-6.164, 88.266],
                                  [-30.289, 65.274],
                                  [85.022, 39.571],
                                  [21.495, 54.523],
                                  [107.631, -10.739],
                                  [-72.453, -66.414],
                                  [11.55, -102.695],
                                  [15.865, -92],
                                ],
                                v: [
                                  [-66.46, -340.333],
                                  [-182.706, -206.924],
                                  [-250.965, -13.456],
                                  [-245.663, 155.951],
                                  [-73.333, 287.148],
                                  [121.797, 361.405],
                                  [280.927, 239.086],
                                  [129.332, -39.592],
                                  [215.813, -168.601],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.25, -17.5],
                                  [5.615, -88.886],
                                  [30.675, -64.894],
                                  [-85.711, -39.498],
                                  [-15.844, -47.85],
                                  [-151.675, 20.112],
                                  [73.383, 66.642],
                                  [-20.244, 96.056],
                                  [-15.762, 92],
                                ],
                                o: [
                                  [-87.063, 23.538],
                                  [-5.615, 88.886],
                                  [-30.675, 64.894],
                                  [85.711, 39.498],
                                  [21.45, 54.75],
                                  [107.688, -10.625],
                                  [-73.383, -66.642],
                                  [12.05, -103.388],
                                  [15.762, -92],
                                ],
                                v: [
                                  [-67.155, -340.651],
                                  [-182.921, -206.26],
                                  [-250.394, -12.578],
                                  [-245.811, 156.229],
                                  [-71.569, 287.517],
                                  [120.775, 361.092],
                                  [280.466, 239.307],
                                  [127.99, -40.764],
                                  [217.276, -168.473],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.025, -17.95],
                                  [5.073, -89.5],
                                  [31.057, -64.517],
                                  [-86.392, -39.427],
                                  [-15.872, -48.165],
                                  [-151.158, 19.876],
                                  [74.303, 66.868],
                                  [-20.632, 96.837],
                                  [-15.661, 92],
                                ],
                                o: [
                                  [-87.231, 23.908],
                                  [-5.073, 89.5],
                                  [-31.057, 64.517],
                                  [86.392, 39.427],
                                  [21.405, 54.975],
                                  [107.744, -10.513],
                                  [-74.303, -66.868],
                                  [12.545, -104.073],
                                  [15.661, -92],
                                ],
                                v: [
                                  [-67.876, -340.887],
                                  [-183.175, -205.577],
                                  [-249.836, -11.691],
                                  [-245.963, 156.512],
                                  [-69.822, 287.883],
                                  [119.735, 360.738],
                                  [280.035, 239.503],
                                  [126.684, -41.897],
                                  [218.734, -168.281],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.803, -18.394],
                                  [4.537, -90.107],
                                  [31.435, -64.145],
                                  [-87.066, -39.356],
                                  [-15.9, -48.476],
                                  [-150.647, 19.643],
                                  [75.213, 67.092],
                                  [-21.015, 97.61],
                                  [-15.561, 92],
                                ],
                                o: [
                                  [-87.398, 24.275],
                                  [-4.537, 90.107],
                                  [-31.435, 64.145],
                                  [87.066, 39.356],
                                  [21.361, 55.197],
                                  [107.799, -10.401],
                                  [-75.213, -67.092],
                                  [13.034, -104.751],
                                  [15.561, -92],
                                ],
                                v: [
                                  [-68.59, -341.175],
                                  [-183.344, -204.946],
                                  [-249.273, -10.759],
                                  [-246.156, 156.792],
                                  [-68.092, 288.203],
                                  [118.723, 360.38],
                                  [279.6, 239.726],
                                  [125.369, -43.029],
                                  [220.152, -168.081],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.583, -18.834],
                                  [4.007, -90.708],
                                  [31.809, -63.777],
                                  [-87.733, -39.286],
                                  [-15.927, -48.784],
                                  [-150.141, 19.412],
                                  [76.113, 67.313],
                                  [-21.394, 98.374],
                                  [-15.462, 92],
                                ],
                                o: [
                                  [-87.563, 24.638],
                                  [-4.007, 90.708],
                                  [-31.809, 63.777],
                                  [87.733, 39.286],
                                  [21.317, 55.417],
                                  [107.854, -10.292],
                                  [-76.113, -67.313],
                                  [13.517, -105.422],
                                  [15.462, -92],
                                ],
                                v: [
                                  [-69.359, -341.393],
                                  [-183.55, -204.294],
                                  [-248.662, -9.818],
                                  [-246.292, 157.076],
                                  [-66.379, 288.522],
                                  [117.665, 360.08],
                                  [279.26, 239.959],
                                  [124.082, -44.112],
                                  [221.626, -167.872],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.366, -19.269],
                                  [3.482, -91.302],
                                  [32.179, -63.412],
                                  [-88.392, -39.216],
                                  [-15.954, -49.088],
                                  [-149.641, 19.184],
                                  [77.004, 67.532],
                                  [-21.769, 99.13],
                                  [-15.365, 92],
                                ],
                                o: [
                                  [-87.726, 24.997],
                                  [-3.482, 91.302],
                                  [-32.179, 63.412],
                                  [88.392, 39.217],
                                  [21.273, 55.634],
                                  [107.909, -10.183],
                                  [-77.003, -67.532],
                                  [13.996, -106.085],
                                  [15.365, -92],
                                ],
                                v: [
                                  [-70.121, -341.619],
                                  [-183.741, -203.62],
                                  [-248.111, -8.868],
                                  [-246.458, 157.354],
                                  [-64.684, 288.832],
                                  [116.573, 359.75],
                                  [278.916, 240.195],
                                  [122.832, -45.17],
                                  [223.058, -167.666],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.15, -19.699],
                                  [2.963, -91.889],
                                  [32.544, -63.052],
                                  [-89.044, -39.148],
                                  [-15.981, -49.389],
                                  [-149.146, 18.958],
                                  [77.884, 67.749],
                                  [-22.14, 99.877],
                                  [-15.268, 92],
                                ],
                                o: [
                                  [-87.887, 25.352],
                                  [-2.963, 91.889],
                                  [-32.544, 63.052],
                                  [89.044, 39.148],
                                  [21.23, 55.85],
                                  [107.962, -10.075],
                                  [-77.884, -67.749],
                                  [14.469, -106.741],
                                  [15.268, -92],
                                ],
                                v: [
                                  [-70.886, -341.815],
                                  [-183.905, -202.998],
                                  [-247.511, -7.897],
                                  [-246.603, 157.597],
                                  [-63.03, 289.108],
                                  [115.461, 359.503],
                                  [278.644, 240.445],
                                  [121.574, -46.153],
                                  [224.485, -167.453],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.938, -20.125],
                                  [2.45, -92.47],
                                  [32.906, -62.696],
                                  [-89.689, -39.08],
                                  [-16.008, -49.687],
                                  [-148.657, 18.735],
                                  [78.755, 67.963],
                                  [-22.507, 100.616],
                                  [-15.172, 92],
                                ],
                                o: [
                                  [-88.047, 25.703],
                                  [-2.45, 92.47],
                                  [-32.906, 62.696],
                                  [89.689, 39.08],
                                  [21.188, 56.062],
                                  [108.016, -9.969],
                                  [-78.755, -67.963],
                                  [14.937, -107.39],
                                  [15.172, -92],
                                ],
                                v: [
                                  [-71.696, -342.034],
                                  [-184.018, -202.391],
                                  [-246.937, -6.917],
                                  [-246.754, 157.843],
                                  [-61.368, 289.35],
                                  [114.377, 359.227],
                                  [278.394, 240.722],
                                  [120.279, -47.164],
                                  [225.867, -167.208],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.727, -20.545],
                                  [1.942, -93.045],
                                  [33.263, -62.343],
                                  [-90.326, -39.013],
                                  [-16.034, -49.982],
                                  [-148.173, 18.514],
                                  [79.616, 68.174],
                                  [-22.87, 101.347],
                                  [-15.077, 92],
                                ],
                                o: [
                                  [-88.204, 26.05],
                                  [-1.942, 93.045],
                                  [-33.263, 62.343],
                                  [90.326, 39.013],
                                  [21.145, 56.273],
                                  [108.068, -9.864],
                                  [-79.616, -68.174],
                                  [15.4, -108.032],
                                  [15.077, -92],
                                ],
                                v: [
                                  [-72.511, -342.252],
                                  [-184.127, -201.784],
                                  [-246.389, -5.888],
                                  [-246.908, 158.091],
                                  [-59.746, 289.584],
                                  [113.247, 358.972],
                                  [278.155, 240.953],
                                  [119.049, -48.138],
                                  [227.281, -166.932],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.519, -20.961],
                                  [1.44, -93.613],
                                  [33.617, -61.995],
                                  [-90.957, -38.947],
                                  [-16.06, -50.273],
                                  [-147.694, 18.295],
                                  [80.468, 68.384],
                                  [-23.229, 102.07],
                                  [-14.984, 92],
                                ],
                                o: [
                                  [-88.361, 26.393],
                                  [-1.44, 93.613],
                                  [-33.617, 61.995],
                                  [90.957, 38.947],
                                  [21.104, 56.481],
                                  [108.12, -9.76],
                                  [-80.468, -68.384],
                                  [15.858, -108.666],
                                  [14.984, -92],
                                ],
                                v: [
                                  [-73.32, -342.457],
                                  [-184.221, -201.191],
                                  [-245.831, -4.85],
                                  [-247.106, 158.328],
                                  [-58.14, 289.825],
                                  [112.146, 358.736],
                                  [277.978, 241.25],
                                  [117.808, -49.053],
                                  [228.675, -166.713],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.314, -21.373],
                                  [0.944, -94.175],
                                  [33.967, -61.65],
                                  [-91.581, -38.881],
                                  [-16.086, -50.561],
                                  [-147.221, 18.079],
                                  [81.31, 68.591],
                                  [-23.584, 102.785],
                                  [-14.891, 92],
                                ],
                                o: [
                                  [-88.515, 26.733],
                                  [-0.944, 94.175],
                                  [-33.967, 61.65],
                                  [91.581, 38.881],
                                  [21.063, 56.686],
                                  [108.172, -9.657],
                                  [-81.31, -68.591],
                                  [16.31, -109.294],
                                  [14.891, -92],
                                ],
                                v: [
                                  [-74.186, -342.664],
                                  [-184.285, -200.572],
                                  [-245.301, -3.801],
                                  [-247.247, 158.551],
                                  [-56.55, 290.059],
                                  [111.022, 358.534],
                                  [277.876, 241.489],
                                  [116.568, -49.957],
                                  [230.038, -166.464],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.11, -21.78],
                                  [0.453, -94.731],
                                  [34.313, -61.309],
                                  [-92.198, -38.816],
                                  [-16.111, -50.846],
                                  [-146.753, 17.865],
                                  [82.143, 68.796],
                                  [-23.935, 103.493],
                                  [-14.8, 92],
                                ],
                                o: [
                                  [-88.667, 27.068],
                                  [-0.453, 94.731],
                                  [-34.313, 61.309],
                                  [92.198, 38.816],
                                  [21.022, 56.89],
                                  [108.223, -9.555],
                                  [-82.143, -68.796],
                                  [16.758, -109.914],
                                  [14.799, -92],
                                ],
                                v: [
                                  [-75.022, -342.899],
                                  [-184.319, -200.004],
                                  [-244.762, -2.743],
                                  [-247.416, 158.749],
                                  [-55.037, 290.264],
                                  [109.865, 358.352],
                                  [277.799, 241.77],
                                  [115.355, -50.804],
                                  [231.379, -166.226],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.909, -22.182],
                                  [-0.033, -95.28],
                                  [34.655, -60.972],
                                  [-92.808, -38.752],
                                  [-16.136, -51.128],
                                  [-146.29, 17.654],
                                  [82.967, 68.998],
                                  [-24.282, 104.192],
                                  [-14.709, 92],
                                ],
                                o: [
                                  [-88.818, 27.4],
                                  [0.033, 95.28],
                                  [-34.655, 60.972],
                                  [92.808, 38.752],
                                  [20.982, 57.091],
                                  [108.273, -9.454],
                                  [-82.967, -68.998],
                                  [17.201, -110.528],
                                  [14.709, -92],
                                ],
                                v: [
                                  [-75.915, -343.124],
                                  [-184.36, -199.409],
                                  [-244.253, -1.66],
                                  [-247.551, 158.933],
                                  [-53.501, 290.44],
                                  [108.723, 358.164],
                                  [277.737, 242.031],
                                  [114.144, -51.64],
                                  [232.751, -165.984],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.71, -22.58],
                                  [-0.513, -95.824],
                                  [34.993, -60.639],
                                  [-93.411, -38.689],
                                  [-16.161, -51.406],
                                  [-145.833, 17.445],
                                  [83.782, 69.198],
                                  [-24.626, 104.883],
                                  [-14.619, 92],
                                ],
                                o: [
                                  [-88.968, 27.729],
                                  [0.513, 95.824],
                                  [-34.993, 60.639],
                                  [93.411, 38.689],
                                  [20.942, 57.29],
                                  [108.323, -9.355],
                                  [-83.782, -69.198],
                                  [17.638, -111.135],
                                  [14.619, -92],
                                ],
                                v: [
                                  [-76.803, -343.371],
                                  [-184.369, -198.809],
                                  [-243.775, -0.565],
                                  [-247.706, 159.138],
                                  [-52.018, 290.651],
                                  [107.572, 358.034],
                                  [277.738, 242.315],
                                  [112.959, -52.46],
                                  [234.077, -165.778],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.513, -22.974],
                                  [-0.987, -96.361],
                                  [35.328, -60.309],
                                  [-94.007, -38.626],
                                  [-16.186, -51.682],
                                  [-145.38, 17.239],
                                  [84.587, 69.396],
                                  [-24.965, 105.567],
                                  [-14.531, 92],
                                ],
                                o: [
                                  [-89.115, 28.053],
                                  [0.987, 96.361],
                                  [-35.328, 60.309],
                                  [94.007, 38.626],
                                  [20.903, 57.487],
                                  [108.372, -9.256],
                                  [-84.587, -69.396],
                                  [18.071, -111.735],
                                  [14.531, -92],
                                ],
                                v: [
                                  [-77.725, -343.633],
                                  [-184.322, -198.259],
                                  [-243.33, 0.562],
                                  [-247.889, 159.279],
                                  [-50.533, 290.858],
                                  [106.412, 357.899],
                                  [277.82, 242.587],
                                  [111.761, -53.208],
                                  [235.418, -165.57],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.318, -23.363],
                                  [-1.457, -96.893],
                                  [35.659, -59.983],
                                  [-94.597, -38.564],
                                  [-16.21, -51.954],
                                  [-144.932, 17.034],
                                  [85.384, 69.592],
                                  [-25.301, 106.243],
                                  [-14.443, 92],
                                ],
                                o: [
                                  [-89.261, 28.375],
                                  [1.457, 96.893],
                                  [-35.659, 59.983],
                                  [94.597, 38.564],
                                  [20.864, 57.682],
                                  [108.42, -9.159],
                                  [-85.384, -69.592],
                                  [18.499, -112.329],
                                  [14.443, -92],
                                ],
                                v: [
                                  [-78.643, -343.905],
                                  [-184.264, -197.702],
                                  [-242.877, 1.676],
                                  [-248.013, 159.401],
                                  [-49.102, 291.016],
                                  [105.243, 357.822],
                                  [277.904, 242.859],
                                  [110.565, -53.965],
                                  [236.768, -165.398],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.126, -23.748],
                                  [-1.921, -97.418],
                                  [35.986, -59.661],
                                  [-95.18, -38.503],
                                  [-16.234, -52.223],
                                  [-144.49, 16.832],
                                  [86.171, 69.786],
                                  [-25.632, 106.912],
                                  [-14.357, 92],
                                ],
                                o: [
                                  [-89.405, 28.692],
                                  [1.921, 97.418],
                                  [-35.986, 59.661],
                                  [95.18, 38.503],
                                  [20.825, 57.874],
                                  [108.469, -9.063],
                                  [-86.171, -69.786],
                                  [18.923, -112.915],
                                  [14.357, -92],
                                ],
                                v: [
                                  [-79.596, -344.211],
                                  [-184.189, -197.114],
                                  [-242.458, 2.801],
                                  [-248.204, 159.542],
                                  [-47.706, 291.211],
                                  [104.088, 357.722],
                                  [278.031, 243.177],
                                  [109.395, -54.69],
                                  [238.133, -165.243],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.936, -24.128],
                                  [-2.38, -97.938],
                                  [36.309, -59.342],
                                  [-95.757, -38.442],
                                  [-16.258, -52.49],
                                  [-144.052, 16.633],
                                  [86.95, 69.977],
                                  [-25.961, 107.573],
                                  [-14.271, 92],
                                ],
                                o: [
                                  [-89.548, 29.006],
                                  [2.38, 97.938],
                                  [-36.309, 59.343],
                                  [95.757, 38.442],
                                  [20.787, 58.064],
                                  [108.516, -8.968],
                                  [-86.95, -69.977],
                                  [19.341, -113.496],
                                  [14.271, -92],
                                ],
                                v: [
                                  [-80.587, -344.529],
                                  [-184.079, -196.535],
                                  [-242.095, 3.935],
                                  [-248.359, 159.658],
                                  [-46.324, 291.404],
                                  [102.923, 357.719],
                                  [278.202, 243.544],
                                  [108.228, -55.323],
                                  [239.468, -165.127],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.748, -24.505],
                                  [-2.834, -98.451],
                                  [36.629, -59.027],
                                  [-96.327, -38.382],
                                  [-16.282, -52.753],
                                  [-143.62, 16.435],
                                  [87.72, 70.166],
                                  [-26.285, 108.227],
                                  [-14.186, 92],
                                ],
                                o: [
                                  [-89.689, 29.316],
                                  [2.834, 98.451],
                                  [-36.629, 59.027],
                                  [96.327, 38.382],
                                  [20.75, 58.252],
                                  [108.563, -8.874],
                                  [-87.72, -70.166],
                                  [19.755, -114.069],
                                  [14.186, -92],
                                ],
                                v: [
                                  [-81.595, -344.842],
                                  [-183.912, -195.965],
                                  [-241.746, 5.12],
                                  [-248.496, 159.752],
                                  [-44.996, 291.574],
                                  [101.791, 357.691],
                                  [278.375, 243.896],
                                  [107.045, -55.966],
                                  [240.818, -165.011],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.562, -24.876],
                                  [-3.282, -98.959],
                                  [36.945, -58.716],
                                  [-96.891, -38.323],
                                  [-16.305, -53.014],
                                  [-143.192, 16.24],
                                  [88.482, 70.354],
                                  [-26.606, 108.873],
                                  [-14.103, 92],
                                ],
                                o: [
                                  [-89.829, 29.623],
                                  [3.282, 98.959],
                                  [-36.945, 58.716],
                                  [96.891, 38.323],
                                  [20.712, 58.438],
                                  [108.61, -8.781],
                                  [-88.482, -70.354],
                                  [20.164, -114.637],
                                  [14.103, -92],
                                ],
                                v: [
                                  [-82.619, -345.212],
                                  [-183.77, -195.404],
                                  [-241.433, 6.273],
                                  [-248.657, 159.821],
                                  [-43.744, 291.679],
                                  [100.63, 357.698],
                                  [278.573, 244.318],
                                  [105.886, -56.622],
                                  [242.202, -164.935],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.378, -25.244],
                                  [-3.726, -99.462],
                                  [37.258, -58.408],
                                  [-97.448, -38.264],
                                  [-16.328, -53.271],
                                  [-142.769, 16.047],
                                  [89.234, 70.539],
                                  [-26.923, 109.512],
                                  [-14.02, 92],
                                ],
                                o: [
                                  [-89.967, 29.926],
                                  [3.726, 99.462],
                                  [-37.258, 58.408],
                                  [97.448, 38.264],
                                  [20.676, 58.622],
                                  [108.656, -8.689],
                                  [-89.234, -70.539],
                                  [20.569, -115.197],
                                  [14.02, -92],
                                ],
                                v: [
                                  [-83.661, -345.641],
                                  [-183.571, -194.828],
                                  [-241.158, 7.435],
                                  [-248.781, 159.865],
                                  [-42.547, 291.783],
                                  [99.502, 357.721],
                                  [278.795, 244.749],
                                  [104.73, -57.187],
                                  [243.559, -164.881],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.196, -25.608],
                                  [-4.164, -99.958],
                                  [37.567, -58.103],
                                  [-97.999, -38.206],
                                  [-16.35, -53.525],
                                  [-142.351, 15.856],
                                  [89.978, 70.721],
                                  [-27.237, 110.144],
                                  [-13.938, 92],
                                ],
                                o: [
                                  [-90.103, 30.226],
                                  [4.164, 99.958],
                                  [-37.567, 58.103],
                                  [97.999, 38.206],
                                  [20.639, 58.804],
                                  [108.701, -8.598],
                                  [-89.979, -70.721],
                                  [20.969, -115.752],
                                  [13.938, -92],
                                ],
                                v: [
                                  [-84.721, -346.088],
                                  [-183.354, -194.281],
                                  [-240.879, 8.586],
                                  [-248.929, 159.926],
                                  [-41.426, 291.823],
                                  [98.386, 357.8],
                                  [279.106, 245.232],
                                  [103.599, -57.724],
                                  [244.928, -164.889],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.016, -25.967],
                                  [-4.598, -100.449],
                                  [37.872, -57.802],
                                  [-98.544, -38.149],
                                  [-16.373, -53.777],
                                  [-141.938, 15.667],
                                  [90.714, 70.902],
                                  [-27.547, 110.768],
                                  [-13.857, 92],
                                ],
                                o: [
                                  [-90.238, 30.523],
                                  [4.598, 100.449],
                                  [-37.872, 57.802],
                                  [98.544, 38.149],
                                  [20.603, 58.984],
                                  [108.746, -8.508],
                                  [-90.714, -70.902],
                                  [21.364, -116.3],
                                  [13.857, -92],
                                ],
                                v: [
                                  [-85.799, -346.513],
                                  [-183.078, -193.718],
                                  [-240.617, 9.766],
                                  [-249.058, 159.96],
                                  [-40.34, 291.819],
                                  [97.261, 357.893],
                                  [279.358, 245.725],
                                  [102.493, -58.277],
                                  [246.291, -164.879],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.839, -26.323],
                                  [-5.027, -100.934],
                                  [38.174, -57.505],
                                  [-99.083, -38.092],
                                  [-16.395, -54.026],
                                  [-141.529, 15.481],
                                  [91.442, 71.081],
                                  [-27.853, 111.386],
                                  [-13.777, 92],
                                ],
                                o: [
                                  [-90.371, 30.816],
                                  [5.027, 100.934],
                                  [-38.174, 57.505],
                                  [99.083, 38.092],
                                  [20.568, 59.161],
                                  [108.79, -8.419],
                                  [-91.442, -71.081],
                                  [21.755, -116.842],
                                  [13.777, -92],
                                ],
                                v: [
                                  [-86.916, -346.999],
                                  [-182.805, -193.16],
                                  [-240.374, 10.912],
                                  [-249.21, 159.967],
                                  [-39.375, 291.793],
                                  [96.147, 358.042],
                                  [279.659, 246.23],
                                  [101.347, -58.74],
                                  [247.624, -164.89],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.663, -26.674],
                                  [-5.45, -101.414],
                                  [38.473, -57.211],
                                  [-99.615, -38.036],
                                  [-16.417, -54.272],
                                  [-141.125, 15.296],
                                  [92.161, 71.258],
                                  [-28.156, 111.996],
                                  [-13.698, 92],
                                ],
                                o: [
                                  [-90.503, 31.106],
                                  [5.45, 101.414],
                                  [-38.473, 57.211],
                                  [99.615, 38.036],
                                  [20.533, 59.337],
                                  [108.834, -8.332],
                                  [-92.161, -71.258],
                                  [22.141, -117.378],
                                  [13.698, -92],
                                ],
                                v: [
                                  [-88.011, -347.463],
                                  [-182.491, -192.627],
                                  [-240.191, 12.066],
                                  [-249.345, 159.947],
                                  [-38.424, 291.723],
                                  [95.067, 358.205],
                                  [280.007, 246.746],
                                  [100.225, -59.22],
                                  [248.992, -164.968],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.489, -27.021],
                                  [-5.869, -101.888],
                                  [38.768, -56.92],
                                  [-100.142, -37.981],
                                  [-16.439, -54.515],
                                  [-140.726, 15.114],
                                  [92.872, 71.433],
                                  [-28.456, 112.599],
                                  [-13.62, 92],
                                ],
                                o: [
                                  [-90.633, 31.393],
                                  [5.869, 101.888],
                                  [-38.768, 56.92],
                                  [100.142, 37.981],
                                  [20.498, 59.511],
                                  [108.878, -8.245],
                                  [-92.872, -71.433],
                                  [22.523, -117.907],
                                  [13.62, -92],
                                ],
                                v: [
                                  [-89.124, -347.926],
                                  [-182.161, -192.056],
                                  [-239.961, 13.185],
                                  [-249.482, 159.94],
                                  [-37.531, 291.693],
                                  [93.979, 358.38],
                                  [280.318, 247.339],
                                  [99.126, -59.655],
                                  [250.353, -165.005],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.318, -27.365],
                                  [-6.284, -102.357],
                                  [39.06, -56.632],
                                  [-100.662, -37.926],
                                  [-16.46, -54.755],
                                  [-140.331, 14.934],
                                  [93.574, 71.605],
                                  [-28.752, 113.196],
                                  [-13.543, 92],
                                ],
                                o: [
                                  [-90.762, 31.676],
                                  [6.284, 102.357],
                                  [-39.06, 56.632],
                                  [100.662, 37.926],
                                  [20.464, 59.682],
                                  [108.921, -8.159],
                                  [-93.574, -71.605],
                                  [22.901, -118.431],
                                  [13.543, -92],
                                ],
                                v: [
                                  [-90.234, -348.369],
                                  [-181.809, -191.508],
                                  [-239.816, 14.267],
                                  [-249.641, 159.907],
                                  [-36.715, 291.556],
                                  [92.901, 358.61],
                                  [280.702, 247.883],
                                  [98.007, -60.066],
                                  [251.75, -165.15],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.148, -27.704],
                                  [-6.693, -102.821],
                                  [39.348, -56.348],
                                  [-101.176, -37.872],
                                  [-16.481, -54.993],
                                  [-139.94, 14.755],
                                  [94.269, 71.776],
                                  [-29.045, 113.786],
                                  [-13.467, 92],
                                ],
                                o: [
                                  [-90.889, 31.956],
                                  [6.693, 102.821],
                                  [-39.348, 56.348],
                                  [101.176, 37.872],
                                  [20.43, 59.852],
                                  [108.963, -8.074],
                                  [-94.269, -71.776],
                                  [23.274, -118.949],
                                  [13.467, -92],
                                ],
                                v: [
                                  [-91.323, -348.768],
                                  [-181.396, -190.939],
                                  [-239.643, 15.374],
                                  [-249.759, 159.844],
                                  [-35.983, 291.46],
                                  [91.834, 358.852],
                                  [281.049, 248.481],
                                  [96.953, -60.434],
                                  [253.097, -165.278],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.98, -28.039],
                                  [-7.098, -103.279],
                                  [39.634, -56.067],
                                  [-101.685, -37.819],
                                  [-16.502, -55.228],
                                  [-139.555, 14.579],
                                  [94.956, 71.945],
                                  [-29.334, 114.368],
                                  [-13.391, 92],
                                ],
                                o: [
                                  [-91.015, 32.233],
                                  [7.098, 103.279],
                                  [-39.633, 56.067],
                                  [101.685, 37.819],
                                  [20.396, 60.02],
                                  [109.005, -7.99],
                                  [-94.956, -71.945],
                                  [23.643, -119.46],
                                  [13.391, -92],
                                ],
                                v: [
                                  [-92.454, -349.21],
                                  [-180.985, -190.416],
                                  [-239.512, 16.469],
                                  [-249.878, 159.774],
                                  [-35.286, 291.319],
                                  [90.801, 359.148],
                                  [281.447, 249.074],
                                  [95.896, -60.78],
                                  [254.436, -165.409],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.814, -28.371],
                                  [-7.498, -103.732],
                                  [39.915, -55.789],
                                  [-102.187, -37.766],
                                  [-16.523, -55.46],
                                  [-139.173, 14.405],
                                  [95.635, 72.112],
                                  [-29.62, 114.945],
                                  [-13.317, 92],
                                ],
                                o: [
                                  [-91.139, 32.506],
                                  [7.498, 103.732],
                                  [-39.915, 55.789],
                                  [102.187, 37.766],
                                  [20.363, 60.185],
                                  [109.046, -7.907],
                                  [-95.634, -72.112],
                                  [24.008, -119.966],
                                  [13.316, -92],
                                ],
                                v: [
                                  [-93.557, -349.606],
                                  [-180.532, -189.87],
                                  [-239.404, 17.481],
                                  [-249.998, 159.697],
                                  [-34.649, 291.114],
                                  [89.823, 359.437],
                                  [281.85, 249.723],
                                  [94.817, -61.146],
                                  [255.768, -165.605],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.651, -28.699],
                                  [-7.893, -104.179],
                                  [40.194, -55.515],
                                  [-102.684, -37.714],
                                  [-16.544, -55.689],
                                  [-138.796, 14.233],
                                  [96.305, 72.277],
                                  [-29.903, 115.514],
                                  [-13.243, 92],
                                ],
                                o: [
                                  [-91.262, 32.777],
                                  [7.893, 104.179],
                                  [-40.194, 55.515],
                                  [102.684, 37.714],
                                  [20.33, 60.349],
                                  [109.087, -7.825],
                                  [-96.305, -72.277],
                                  [24.369, -120.466],
                                  [13.243, -92],
                                ],
                                v: [
                                  [-94.64, -350.046],
                                  [-180.079, -189.3],
                                  [-239.33, 18.515],
                                  [-250.075, 159.631],
                                  [-34.09, 290.951],
                                  [88.792, 359.737],
                                  [282.287, 250.368],
                                  [93.802, -61.472],
                                  [257.138, -165.83],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.489, -29.023],
                                  [-8.284, -104.622],
                                  [40.469, -55.243],
                                  [-103.175, -37.662],
                                  [-16.564, -55.916],
                                  [-138.424, 14.063],
                                  [96.969, 72.44],
                                  [-30.182, 116.077],
                                  [-13.17, 92],
                                ],
                                o: [
                                  [-91.384, 33.044],
                                  [8.284, 104.622],
                                  [-40.469, 55.243],
                                  [103.175, 37.662],
                                  [20.298, 60.511],
                                  [109.128, -7.744],
                                  [-96.969, -72.44],
                                  [24.725, -120.96],
                                  [13.17, -92],
                                ],
                                v: [
                                  [-95.721, -350.423],
                                  [-179.582, -188.749],
                                  [-239.236, 19.536],
                                  [-250.18, 159.514],
                                  [-33.529, 290.741],
                                  [87.859, 360.091],
                                  [282.687, 251.026],
                                  [92.764, -61.733],
                                  [258.456, -166.077],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.329, -29.343],
                                  [-8.67, -105.059],
                                  [40.742, -54.975],
                                  [-103.66, -37.611],
                                  [-16.584, -56.14],
                                  [-138.056, 13.895],
                                  [97.624, 72.601],
                                  [-30.458, 116.633],
                                  [-13.098, 92],
                                ],
                                o: [
                                  [-91.504, 33.308],
                                  [8.67, 105.059],
                                  [-40.742, 54.975],
                                  [103.66, 37.611],
                                  [20.266, 60.672],
                                  [109.168, -7.664],
                                  [-97.624, -72.601],
                                  [25.077, -121.448],
                                  [13.098, -92],
                                ],
                                v: [
                                  [-96.782, -350.799],
                                  [-179.086, -188.198],
                                  [-239.186, 20.535],
                                  [-250.224, 159.371],
                                  [-33.091, 290.512],
                                  [86.874, 360.438],
                                  [283.139, 251.679],
                                  [91.744, -61.999],
                                  [259.83, -166.326],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.17, -29.659],
                                  [-9.052, -105.491],
                                  [41.011, -54.71],
                                  [-104.14, -37.561],
                                  [-16.604, -56.362],
                                  [-137.692, 13.729],
                                  [98.272, 72.76],
                                  [-30.731, 117.183],
                                  [-13.027, 92],
                                ],
                                o: [
                                  [-91.622, 33.569],
                                  [9.052, 105.491],
                                  [-41.011, 54.71],
                                  [104.14, 37.56],
                                  [20.234, 60.83],
                                  [109.207, -7.585],
                                  [-98.272, -72.76],
                                  [25.425, -121.931],
                                  [13.027, -92],
                                ],
                                v: [
                                  [-97.859, -351.175],
                                  [-178.573, -187.664],
                                  [-239.151, 21.491],
                                  [-250.27, 159.239],
                                  [-32.67, 290.281],
                                  [85.926, 360.822],
                                  [283.58, 252.328],
                                  [90.762, -62.29],
                                  [261.134, -166.624],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.014, -29.972],
                                  [-9.429, -105.918],
                                  [41.276, -54.448],
                                  [-104.614, -37.511],
                                  [-16.623, -56.581],
                                  [-137.332, 13.565],
                                  [98.912, 72.917],
                                  [-31.001, 117.727],
                                  [-12.956, 92],
                                ],
                                o: [
                                  [-91.74, 33.827],
                                  [9.429, 105.918],
                                  [-41.276, 54.448],
                                  [104.614, 37.511],
                                  [20.203, 60.986],
                                  [109.247, -7.507],
                                  [-98.912, -72.917],
                                  [25.77, -122.408],
                                  [12.956, -92],
                                ],
                                v: [
                                  [-98.871, -351.532],
                                  [-177.971, -187.114],
                                  [-239.16, 22.45],
                                  [-250.283, 159.083],
                                  [-32.327, 290.049],
                                  [85.032, 361.216],
                                  [284.029, 252.991],
                                  [89.799, -62.517],
                                  [262.477, -166.97],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.859, -30.281],
                                  [-9.802, -106.341],
                                  [41.539, -54.189],
                                  [-105.083, -37.461],
                                  [-16.643, -56.797],
                                  [-136.976, 13.402],
                                  [99.545, 73.073],
                                  [-31.268, 118.264],
                                  [-12.887, 92],
                                ],
                                o: [
                                  [-91.856, 34.082],
                                  [9.802, 106.341],
                                  [-41.539, 54.189],
                                  [105.083, 37.461],
                                  [20.172, 61.141],
                                  [109.285, -7.43],
                                  [-99.545, -73.073],
                                  [26.11, -122.879],
                                  [12.887, -92],
                                ],
                                v: [
                                  [-99.944, -351.906],
                                  [-177.43, -186.535],
                                  [-239.168, 23.321],
                                  [-250.237, 158.92],
                                  [-32.03, 289.77],
                                  [84.131, 361.62],
                                  [284.486, 253.712],
                                  [88.809, -62.707],
                                  [263.83, -167.32],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.707, -30.587],
                                  [-10.17, -106.758],
                                  [41.799, -53.933],
                                  [-105.546, -37.413],
                                  [-16.662, -57.011],
                                  [-136.625, 13.242],
                                  [100.17, 73.227],
                                  [-31.531, 118.795],
                                  [-12.818, 92],
                                ],
                                o: [
                                  [-91.97, 34.334],
                                  [10.17, 106.758],
                                  [-41.799, 53.933],
                                  [105.546, 37.413],
                                  [20.141, 61.293],
                                  [109.323, -7.353],
                                  [-100.17, -73.227],
                                  [26.446, -123.345],
                                  [12.818, -92],
                                ],
                                v: [
                                  [-100.952, -352.262],
                                  [-176.827, -185.973],
                                  [-239.191, 24.239],
                                  [-250.193, 158.721],
                                  [-31.811, 289.534],
                                  [83.285, 362.062],
                                  [284.98, 254.386],
                                  [87.882, -62.907],
                                  [265.114, -167.689],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.556, -30.889],
                                  [-10.535, -107.17],
                                  [42.056, -53.681],
                                  [-106.003, -37.365],
                                  [-16.681, -57.222],
                                  [-136.278, 13.083],
                                  [100.788, 73.378],
                                  [-31.792, 119.319],
                                  [-12.75, 92],
                                ],
                                o: [
                                  [-92.083, 34.583],
                                  [10.535, 107.17],
                                  [-42.056, 53.681],
                                  [106.003, 37.365],
                                  [20.111, 61.444],
                                  [109.361, -7.278],
                                  [-100.788, -73.378],
                                  [26.778, -123.805],
                                  [12.75, -92],
                                ],
                                v: [
                                  [-101.957, -352.634],
                                  [-176.224, -185.392],
                                  [-239.242, 25.068],
                                  [-250.135, 158.544],
                                  [-31.609, 289.251],
                                  [82.432, 362.496],
                                  [285.436, 255.072],
                                  [86.945, -63.09],
                                  [266.437, -168.108],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.406, -31.187],
                                  [-10.895, -107.577],
                                  [42.309, -53.431],
                                  [-106.456, -37.317],
                                  [-16.699, -57.431],
                                  [-135.935, 12.927],
                                  [101.399, 73.529],
                                  [-32.049, 119.838],
                                  [-12.683, 92],
                                ],
                                o: [
                                  [-92.195, 34.829],
                                  [10.895, 107.578],
                                  [-42.309, 53.431],
                                  [106.456, 37.317],
                                  [20.081, 61.594],
                                  [109.398, -7.203],
                                  [-101.399, -73.529],
                                  [27.106, -124.26],
                                  [12.683, -92],
                                ],
                                v: [
                                  [-102.898, -352.99],
                                  [-175.574, -184.812],
                                  [-239.309, 25.897],
                                  [-250.081, 158.315],
                                  [-31.485, 288.996],
                                  [81.618, 362.97],
                                  [285.93, 255.756],
                                  [85.977, -63.256],
                                  [267.724, -168.531],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.259, -31.482],
                                  [-11.25, -107.98],
                                  [42.56, -53.184],
                                  [-106.902, -37.27],
                                  [-16.718, -57.637],
                                  [-135.596, 12.772],
                                  [102.002, 73.677],
                                  [-32.303, 120.35],
                                  [-12.617, 92],
                                ],
                                o: [
                                  [-92.306, 35.073],
                                  [11.25, 107.98],
                                  [-42.56, 53.184],
                                  [106.902, 37.27],
                                  [20.052, 61.741],
                                  [109.435, -7.13],
                                  [-102.002, -73.677],
                                  [27.43, -124.71],
                                  [12.617, -92],
                                ],
                                v: [
                                  [-103.9, -353.298],
                                  [-174.94, -184.2],
                                  [-239.421, 26.696],
                                  [-250.013, 158.126],
                                  [-31.362, 288.693],
                                  [80.86, 363.453],
                                  [286.402, 256.453],
                                  [85.073, -63.388],
                                  [269.034, -169.003],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.113, -31.773],
                                  [-11.602, -108.378],
                                  [42.807, -52.94],
                                  [-107.344, -37.224],
                                  [-16.736, -57.841],
                                  [-135.261, 12.619],
                                  [102.599, 73.823],
                                  [-32.555, 120.856],
                                  [-12.551, 92],
                                ],
                                o: [
                                  [-92.415, 35.313],
                                  [11.602, 108.378],
                                  [-42.807, 52.94],
                                  [107.344, 37.224],
                                  [20.023, 61.887],
                                  [109.472, -7.057],
                                  [-102.599, -73.823],
                                  [27.751, -125.154],
                                  [12.551, -92],
                                ],
                                v: [
                                  [-104.836, -353.668],
                                  [-174.289, -183.634],
                                  [-239.532, 27.433],
                                  [-249.886, 157.868],
                                  [-31.364, 288.404],
                                  [80.096, 363.93],
                                  [286.896, 257.147],
                                  [84.139, -63.488],
                                  [270.338, -169.494],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.969, -32.061],
                                  [-11.949, -108.771],
                                  [43.052, -52.699],
                                  [-107.78, -37.178],
                                  [-16.754, -58.043],
                                  [-134.93, 12.468],
                                  [103.188, 73.968],
                                  [-32.803, 121.357],
                                  [-12.486, 92],
                                ],
                                o: [
                                  [-92.523, 35.551],
                                  [11.949, 108.771],
                                  [-43.052, 52.699],
                                  [107.78, 37.178],
                                  [19.994, 62.031],
                                  [109.508, -6.985],
                                  [-103.188, -73.968],
                                  [28.067, -125.593],
                                  [12.486, -92],
                                ],
                                v: [
                                  [-105.723, -353.974],
                                  [-173.637, -183.02],
                                  [-239.69, 28.186],
                                  [-249.81, 157.666],
                                  [-31.336, 288.098],
                                  [79.388, 364.446],
                                  [287.353, 257.855],
                                  [83.283, -63.619],
                                  [271.589, -170.036],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.827, -32.346],
                                  [-12.292, -109.16],
                                  [43.294, -52.46],
                                  [-108.212, -37.132],
                                  [-16.772, -58.242],
                                  [-134.602, 12.318],
                                  [103.77, 74.111],
                                  [-33.048, 121.851],
                                  [-12.422, 92],
                                ],
                                o: [
                                  [-92.63, 35.785],
                                  [12.292, 109.16],
                                  [-43.294, 52.46],
                                  [108.212, 37.132],
                                  [19.965, 62.173],
                                  [109.543, -6.914],
                                  [-103.77, -74.112],
                                  [28.38, -126.027],
                                  [12.422, -92],
                                ],
                                v: [
                                  [-106.655, -354.279],
                                  [-172.953, -182.405],
                                  [-239.816, 28.89],
                                  [-249.627, 157.396],
                                  [-31.418, 287.853],
                                  [78.721, 364.971],
                                  [287.818, 258.529],
                                  [82.38, -63.735],
                                  [272.88, -170.596],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.687, -32.627],
                                  [-12.631, -109.544],
                                  [43.533, -52.225],
                                  [-108.638, -37.088],
                                  [-16.789, -58.439],
                                  [-134.279, 12.171],
                                  [104.346, 74.253],
                                  [-33.291, 122.339],
                                  [-12.359, 92],
                                ],
                                o: [
                                  [-92.735, 36.017],
                                  [12.631, 109.544],
                                  [-43.533, 52.225],
                                  [108.638, 37.088],
                                  [19.937, 62.313],
                                  [109.578, -6.843],
                                  [-104.346, -74.253],
                                  [28.69, -126.456],
                                  [12.359, -92],
                                ],
                                v: [
                                  [-107.489, -354.598],
                                  [-172.267, -181.789],
                                  [-240.035, 29.546],
                                  [-249.496, 157.168],
                                  [-31.484, 287.544],
                                  [78.048, 365.475],
                                  [288.324, 259.2],
                                  [81.475, -63.821],
                                  [274.165, -171.191],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.548, -32.905],
                                  [-12.966, -109.923],
                                  [43.769, -51.992],
                                  [-109.058, -37.043],
                                  [-16.807, -58.633],
                                  [-133.96, 12.025],
                                  [104.914, 74.393],
                                  [-33.53, 122.822],
                                  [-12.297, 92],
                                ],
                                o: [
                                  [-92.839, 36.246],
                                  [12.966, 109.923],
                                  [-43.769, 51.992],
                                  [109.058, 37.043],
                                  [19.91, 62.452],
                                  [109.613, -6.774],
                                  [-104.914, -74.393],
                                  [28.995, -126.879],
                                  [12.297, -92],
                                ],
                                v: [
                                  [-108.353, -354.901],
                                  [-171.58, -181.171],
                                  [-240.207, 30.201],
                                  [-249.367, 156.934],
                                  [-31.646, 287.248],
                                  [77.433, 366.036],
                                  [288.79, 259.884],
                                  [80.618, -63.878],
                                  [275.396, -171.82],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.411, -33.179],
                                  [-13.297, -110.298],
                                  [44.002, -51.763],
                                  [-109.474, -37],
                                  [-16.824, -58.825],
                                  [-133.644, 11.881],
                                  [105.476, 74.531],
                                  [-33.767, 123.298],
                                  [-12.235, 92],
                                ],
                                o: [
                                  [-92.942, 36.473],
                                  [13.297, 110.298],
                                  [-44.002, 51.763],
                                  [109.474, 37],
                                  [19.882, 62.589],
                                  [109.647, -6.705],
                                  [-105.476, -74.531],
                                  [29.297, -127.298],
                                  [12.235, -92],
                                ],
                                v: [
                                  [-109.167, -355.203],
                                  [-170.891, -180.567],
                                  [-240.426, 30.806],
                                  [-249.18, 156.68],
                                  [-31.792, 286.936],
                                  [76.859, 366.59],
                                  [289.251, 260.581],
                                  [79.775, -63.921],
                                  [276.669, -172.485],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.275, -33.45],
                                  [-13.624, -110.668],
                                  [44.233, -51.536],
                                  [-109.885, -36.956],
                                  [-16.841, -59.015],
                                  [-133.332, 11.739],
                                  [106.031, 74.667],
                                  [-34.001, 123.769],
                                  [-12.174, 92],
                                ],
                                o: [
                                  [-93.044, 36.696],
                                  [13.624, 110.668],
                                  [-44.233, 51.536],
                                  [109.885, 36.956],
                                  [19.855, 62.725],
                                  [109.681, -6.638],
                                  [-106.031, -74.667],
                                  [29.595, -127.711],
                                  [12.174, -92],
                                ],
                                v: [
                                  [-109.978, -355.518],
                                  [-170.201, -179.962],
                                  [-240.693, 31.424],
                                  [-248.996, 156.437],
                                  [-32.049, 286.685],
                                  [76.281, 367.186],
                                  [289.768, 261.227],
                                  [78.913, -63.999],
                                  [277.921, -173.168],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.141, -33.718],
                                  [-13.947, -111.034],
                                  [44.46, -51.311],
                                  [-110.291, -36.914],
                                  [-16.857, -59.203],
                                  [-133.024, 11.598],
                                  [106.579, 74.802],
                                  [-34.232, 124.235],
                                  [-12.113, 92],
                                ],
                                o: [
                                  [-93.144, 36.917],
                                  [13.947, 111.034],
                                  [-44.46, 51.311],
                                  [110.291, 36.914],
                                  [19.828, 62.859],
                                  [109.715, -6.57],
                                  [-106.579, -74.802],
                                  [29.89, -128.12],
                                  [12.113, -92],
                                ],
                                v: [
                                  [-110.738, -355.831],
                                  [-169.523, -179.341],
                                  [-240.926, 31.976],
                                  [-248.816, 156.174],
                                  [-32.288, 286.383],
                                  [75.809, 367.777],
                                  [290.246, 261.886],
                                  [78.065, -64.05],
                                  [279.133, -173.868],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.009, -33.983],
                                  [-14.266, -111.395],
                                  [44.685, -51.09],
                                  [-110.692, -36.871],
                                  [-16.874, -59.388],
                                  [-132.72, 11.459],
                                  [107.12, 74.935],
                                  [-34.46, 124.695],
                                  [-12.054, 92],
                                ],
                                o: [
                                  [-93.243, 37.136],
                                  [14.266, 111.395],
                                  [-44.685, 51.09],
                                  [110.692, 36.872],
                                  [19.802, 62.991],
                                  [109.748, -6.504],
                                  [-107.12, -74.935],
                                  [30.181, -128.523],
                                  [12.054, -92],
                                ],
                                v: [
                                  [-111.447, -356.094],
                                  [-168.844, -178.733],
                                  [-241.193, 32.492],
                                  [-248.625, 155.94],
                                  [-32.576, 286.08],
                                  [75.333, 368.349],
                                  [290.719, 262.508],
                                  [77.244, -64.074],
                                  [280.374, -174.667],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.878, -34.244],
                                  [-14.582, -111.752],
                                  [44.907, -50.871],
                                  [-111.088, -36.83],
                                  [-16.89, -59.571],
                                  [-132.419, 11.322],
                                  [107.656, 75.066],
                                  [-34.685, 125.149],
                                  [-11.995, 92],
                                ],
                                o: [
                                  [-93.341, 37.351],
                                  [14.582, 111.752],
                                  [-44.907, 50.871],
                                  [111.088, 36.83],
                                  [19.776, 63.122],
                                  [109.78, -6.439],
                                  [-107.656, -75.066],
                                  [30.468, -128.922],
                                  [11.995, -92],
                                ],
                                v: [
                                  [-112.186, -356.404],
                                  [-168.114, -178.124],
                                  [-241.523, 32.991],
                                  [-248.452, 155.669],
                                  [-32.943, 285.824],
                                  [74.901, 368.93],
                                  [291.137, 263.141],
                                  [76.436, -64.086],
                                  [281.622, -175.421],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.749, -34.502],
                                  [-14.893, -112.105],
                                  [45.127, -50.654],
                                  [-111.48, -36.789],
                                  [-16.906, -59.752],
                                  [-132.122, 11.186],
                                  [108.184, 75.196],
                                  [-34.908, 125.598],
                                  [-11.937, 92],
                                ],
                                o: [
                                  [-93.438, 37.564],
                                  [14.893, 112.105],
                                  [-45.127, 50.654],
                                  [111.48, 36.789],
                                  [19.75, 63.251],
                                  [109.813, -6.374],
                                  [-108.184, -75.196],
                                  [30.752, -129.316],
                                  [11.937, -92],
                                ],
                                v: [
                                  [-112.825, -356.664],
                                  [-167.444, -177.465],
                                  [-241.804, 33.451],
                                  [-248.219, 155.477],
                                  [-33.276, 285.531],
                                  [74.515, 369.554],
                                  [291.613, 263.785],
                                  [75.641, -64.087],
                                  [282.803, -176.24],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.621, -34.757],
                                  [-15.201, -112.453],
                                  [45.344, -50.441],
                                  [-111.867, -36.748],
                                  [-16.922, -59.93],
                                  [-131.829, 11.052],
                                  [108.706, 75.325],
                                  [-35.128, 126.041],
                                  [-11.88, 92],
                                ],
                                o: [
                                  [-93.534, 37.775],
                                  [15.201, 112.453],
                                  [-45.344, 50.441],
                                  [111.867, 36.748],
                                  [19.724, 63.379],
                                  [109.845, -6.311],
                                  [-108.706, -75.325],
                                  [31.033, -129.705],
                                  [11.88, -92],
                                ],
                                v: [
                                  [-113.46, -356.921],
                                  [-166.759, -176.902],
                                  [-242.134, 33.908],
                                  [-247.989, 155.233],
                                  [-33.673, 285.285],
                                  [74.138, 370.174],
                                  [292.098, 264.378],
                                  [74.81, -64.112],
                                  [284.026, -177.11],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.495, -35.009],
                                  [-15.505, -112.797],
                                  [45.558, -50.23],
                                  [-112.248, -36.708],
                                  [-16.938, -60.106],
                                  [-131.539, 10.92],
                                  [109.222, 75.452],
                                  [-35.345, 126.479],
                                  [-11.823, 92],
                                ],
                                o: [
                                  [-93.628, 37.983],
                                  [15.505, 112.797],
                                  [-45.558, 50.23],
                                  [112.248, 36.708],
                                  [19.699, 63.505],
                                  [109.876, -6.248],
                                  [-109.222, -75.452],
                                  [31.31, -130.089],
                                  [11.823, -92],
                                ],
                                v: [
                                  [-114.092, -357.19],
                                  [-166.085, -176.302],
                                  [-242.465, 34.312],
                                  [-247.812, 155.021],
                                  [-34.148, 284.988],
                                  [73.857, 370.825],
                                  [292.529, 264.932],
                                  [74.053, -64.127],
                                  [285.182, -178.046],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.371, -35.258],
                                  [-15.805, -113.137],
                                  [45.769, -50.021],
                                  [-112.626, -36.668],
                                  [-16.954, -60.281],
                                  [-131.253, 10.789],
                                  [109.732, 75.577],
                                  [-35.56, 126.911],
                                  [-11.767, 92],
                                ],
                                o: [
                                  [-93.722, 38.188],
                                  [15.805, 113.137],
                                  [-45.769, 50.021],
                                  [112.626, 36.668],
                                  [19.674, 63.629],
                                  [109.907, -6.185],
                                  [-109.732, -75.577],
                                  [31.584, -130.469],
                                  [11.767, -92],
                                ],
                                v: [
                                  [-114.658, -357.456],
                                  [-165.423, -175.688],
                                  [-242.845, 34.712],
                                  [-247.602, 154.807],
                                  [-34.575, 284.737],
                                  [73.586, 371.436],
                                  [292.955, 265.545],
                                  [73.258, -64.118],
                                  [286.395, -178.948],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.248, -35.504],
                                  [-16.102, -113.473],
                                  [45.978, -49.815],
                                  [-112.998, -36.629],
                                  [-16.969, -60.453],
                                  [-130.97, 10.66],
                                  [110.235, 75.701],
                                  [-35.772, 127.338],
                                  [-11.712, 92],
                                ],
                                o: [
                                  [-93.814, 38.391],
                                  [16.102, 113.473],
                                  [-45.978, 49.815],
                                  [112.998, 36.629],
                                  [19.65, 63.752],
                                  [109.938, -6.124],
                                  [-110.235, -75.701],
                                  [31.854, -130.844],
                                  [11.712, -92],
                                ],
                                v: [
                                  [-115.22, -357.67],
                                  [-164.807, -175.073],
                                  [-243.19, 35.072],
                                  [-247.332, 154.59],
                                  [-35.078, 284.449],
                                  [73.412, 372.079],
                                  [293.391, 266.07],
                                  [72.475, -64.148],
                                  [287.539, -179.902],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.127, -35.747],
                                  [-16.395, -113.804],
                                  [46.185, -49.612],
                                  [-113.366, -36.59],
                                  [-16.984, -60.623],
                                  [-130.691, 10.533],
                                  [110.732, 75.823],
                                  [-35.982, 127.76],
                                  [-11.657, 92],
                                ],
                                o: [
                                  [-93.905, 38.591],
                                  [16.395, 113.804],
                                  [-46.185, 49.612],
                                  [113.366, 36.59],
                                  [19.625, 63.874],
                                  [109.968, -6.063],
                                  [-110.732, -75.823],
                                  [32.122, -131.214],
                                  [11.657, -92],
                                ],
                                v: [
                                  [-115.729, -357.944],
                                  [-164.139, -174.519],
                                  [-243.571, 35.463],
                                  [-247.114, 154.421],
                                  [-35.597, 284.207],
                                  [73.222, 372.719],
                                  [293.835, 266.616],
                                  [71.716, -64.157],
                                  [288.679, -180.921],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.007, -35.987],
                                  [-16.684, -114.132],
                                  [46.389, -49.411],
                                  [-113.73, -36.552],
                                  [-16.999, -60.791],
                                  [-130.415, 10.407],
                                  [111.223, 75.943],
                                  [-36.189, 128.177],
                                  [-11.603, 92],
                                ],
                                o: [
                                  [-93.995, 38.789],
                                  [16.684, 114.132],
                                  [-46.389, 49.411],
                                  [113.73, 36.552],
                                  [19.601, 63.993],
                                  [109.998, -6.003],
                                  [-111.223, -75.943],
                                  [32.385, -131.58],
                                  [11.603, -92],
                                ],
                                v: [
                                  [-116.183, -358.165],
                                  [-163.532, -173.914],
                                  [-243.966, 35.763],
                                  [-246.912, 154.237],
                                  [-36.129, 283.976],
                                  [73.106, 373.405],
                                  [294.225, 267.159],
                                  [70.968, -64.157],
                                  [289.763, -181.942],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.888, -36.224],
                                  [-16.97, -114.455],
                                  [46.59, -49.213],
                                  [-114.089, -36.514],
                                  [-17.014, -60.957],
                                  [-130.143, 10.283],
                                  [111.708, 76.063],
                                  [-36.393, 128.589],
                                  [-11.55, 92],
                                ],
                                o: [
                                  [-94.084, 38.985],
                                  [16.97, 114.456],
                                  [-46.59, 49.213],
                                  [114.089, 36.514],
                                  [19.578, 64.112],
                                  [110.028, -5.944],
                                  [-111.708, -76.063],
                                  [32.646, -131.941],
                                  [11.55, -92],
                                ],
                                v: [
                                  [-116.634, -358.383],
                                  [-162.91, -173.358],
                                  [-244.348, 36.096],
                                  [-246.65, 154.101],
                                  [-36.738, 283.742],
                                  [73.025, 374.026],
                                  [294.612, 267.711],
                                  [70.23, -64.148],
                                  [290.841, -182.977],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.771, -36.458],
                                  [-17.252, -114.775],
                                  [46.789, -49.017],
                                  [-114.444, -36.477],
                                  [-17.029, -61.12],
                                  [-129.874, 10.16],
                                  [112.187, 76.18],
                                  [-36.595, 128.995],
                                  [-11.497, 92],
                                ],
                                o: [
                                  [-94.172, 39.178],
                                  [17.252, 114.775],
                                  [-46.789, 49.017],
                                  [114.444, 36.477],
                                  [19.554, 64.229],
                                  [110.057, -5.886],
                                  [-112.187, -76.18],
                                  [32.903, -132.298],
                                  [11.497, -92],
                                ],
                                v: [
                                  [-117.017, -358.61],
                                  [-162.36, -172.802],
                                  [-244.743, 36.387],
                                  [-246.402, 153.952],
                                  [-37.298, 283.456],
                                  [73.057, 374.693],
                                  [295.007, 268.221],
                                  [69.515, -64.13],
                                  [291.914, -184.014],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.656, -36.689],
                                  [-17.531, -115.091],
                                  [46.985, -48.823],
                                  [-114.794, -36.44],
                                  [-17.043, -61.282],
                                  [-129.608, 10.038],
                                  [112.66, 76.297],
                                  [-36.794, 129.397],
                                  [-11.445, 92],
                                ],
                                o: [
                                  [-94.258, 39.368],
                                  [17.531, 115.091],
                                  [-46.985, 48.823],
                                  [114.794, 36.44],
                                  [19.531, 64.344],
                                  [110.086, -5.828],
                                  [-112.66, -76.297],
                                  [33.158, -132.65],
                                  [11.445, -92],
                                ],
                                v: [
                                  [-117.409, -358.782],
                                  [-161.808, -172.244],
                                  [-245.19, 36.723],
                                  [-246.207, 153.865],
                                  [-37.934, 283.229],
                                  [73.087, 375.358],
                                  [295.36, 268.739],
                                  [68.81, -64.142],
                                  [292.919, -185.115],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.542, -36.917],
                                  [-17.806, -115.402],
                                  [47.179, -48.632],
                                  [-115.14, -36.404],
                                  [-17.057, -61.442],
                                  [-129.345, 9.919],
                                  [113.127, 76.411],
                                  [-36.991, 129.793],
                                  [-11.394, 92],
                                ],
                                o: [
                                  [-94.344, 39.557],
                                  [17.806, 115.402],
                                  [-47.179, 48.632],
                                  [115.14, 36.404],
                                  [19.508, 64.458],
                                  [110.115, -5.771],
                                  [-113.127, -76.411],
                                  [33.409, -132.998],
                                  [11.394, -92],
                                ],
                                v: [
                                  [-117.733, -359.002],
                                  [-161.254, -171.736],
                                  [-245.586, 37.004],
                                  [-245.963, 153.765],
                                  [-38.521, 282.999],
                                  [73.167, 376.009],
                                  [295.709, 269.266],
                                  [68.115, -64.147],
                                  [293.918, -186.229],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.429, -37.142],
                                  [-18.078, -115.71],
                                  [47.371, -48.443],
                                  [-115.481, -36.368],
                                  [-17.071, -61.6],
                                  [-129.086, 9.8],
                                  [113.588, 76.525],
                                  [-37.185, 130.185],
                                  [-11.343, 92],
                                ],
                                o: [
                                  [-94.428, 39.742],
                                  [18.078, 115.71],
                                  [-47.371, 48.443],
                                  [115.481, 36.368],
                                  [19.486, 64.571],
                                  [110.143, -5.714],
                                  [-113.588, -76.525],
                                  [33.657, -133.342],
                                  [11.343, -92],
                                ],
                                v: [
                                  [-117.99, -359.179],
                                  [-160.697, -171.177],
                                  [-246.033, 37.229],
                                  [-245.733, 153.653],
                                  [-39.183, 282.778],
                                  [73.298, 376.708],
                                  [296.066, 269.749],
                                  [67.441, -64.183],
                                  [294.911, -187.345],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.318, -37.365],
                                  [-18.346, -116.014],
                                  [47.56, -48.257],
                                  [-115.819, -36.332],
                                  [-17.085, -61.755],
                                  [-128.83, 9.683],
                                  [114.044, 76.637],
                                  [-37.377, 130.572],
                                  [-11.293, 92],
                                ],
                                o: [
                                  [-94.512, 39.926],
                                  [18.346, 116.014],
                                  [-47.56, 48.257],
                                  [115.819, 36.333],
                                  [19.464, 64.682],
                                  [110.171, -5.659],
                                  [-114.044, -76.637],
                                  [33.901, -133.681],
                                  [11.293, -92],
                                ],
                                v: [
                                  [-118.254, -359.363],
                                  [-160.201, -170.68],
                                  [-246.43, 37.398],
                                  [-245.505, 153.642],
                                  [-39.859, 282.514],
                                  [73.491, 377.354],
                                  [296.37, 270.251],
                                  [66.777, -64.223],
                                  [295.837, -188.473],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.208, -37.585],
                                  [-18.612, -116.314],
                                  [47.747, -48.073],
                                  [-116.152, -36.297],
                                  [-17.099, -61.909],
                                  [-128.578, 9.568],
                                  [114.494, 76.747],
                                  [-37.567, 130.953],
                                  [-11.243, 92],
                                ],
                                o: [
                                  [-94.594, 40.107],
                                  [18.612, 116.314],
                                  [-47.747, 48.073],
                                  [116.152, 36.297],
                                  [19.442, 64.792],
                                  [110.198, -5.604],
                                  [-114.494, -76.747],
                                  [34.143, -134.017],
                                  [11.243, -92],
                                ],
                                v: [
                                  [-118.451, -359.542],
                                  [-159.765, -170.222],
                                  [-246.827, 37.562],
                                  [-245.226, 153.582],
                                  [-40.609, 282.298],
                                  [73.735, 378.038],
                                  [296.681, 270.76],
                                  [66.082, -64.246],
                                  [296.768, -189.664],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.099, -37.802],
                                  [-18.873, -116.61],
                                  [47.931, -47.891],
                                  [-116.481, -36.263],
                                  [-17.113, -62.061],
                                  [-128.328, 9.454],
                                  [114.938, 76.856],
                                  [-37.754, 131.33],
                                  [-11.195, 92],
                                ],
                                o: [
                                  [-94.676, 40.286],
                                  [18.873, 116.61],
                                  [-47.931, 47.891],
                                  [116.481, 36.263],
                                  [19.42, 64.901],
                                  [110.225, -5.55],
                                  [-114.938, -76.856],
                                  [34.382, -134.348],
                                  [11.195, -92],
                                ],
                                v: [
                                  [-118.654, -359.666],
                                  [-159.316, -169.724],
                                  [-247.225, 37.669],
                                  [-245.001, 153.624],
                                  [-41.31, 282.079],
                                  [74.019, 378.709],
                                  [296.989, 271.214],
                                  [65.447, -64.261],
                                  [297.631, -190.815],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.992, -38.016],
                                  [-19.132, -116.903],
                                  [48.114, -47.712],
                                  [-116.806, -36.229],
                                  [-17.126, -62.211],
                                  [-128.082, 9.342],
                                  [115.376, 76.964],
                                  [-37.939, 131.703],
                                  [-11.146, 92],
                                ],
                                o: [
                                  [-94.756, 40.463],
                                  [19.132, 116.903],
                                  [-48.114, 47.712],
                                  [116.805, 36.229],
                                  [19.398, 65.008],
                                  [110.252, -5.496],
                                  [-115.376, -76.964],
                                  [34.618, -134.674],
                                  [11.146, -92],
                                ],
                                v: [
                                  [-118.789, -359.796],
                                  [-158.938, -169.277],
                                  [-247.623, 37.781],
                                  [-244.798, 153.656],
                                  [-42.023, 281.867],
                                  [74.378, 379.379],
                                  [297.254, 271.685],
                                  [64.831, -64.322],
                                  [298.488, -192.017],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.886, -38.228],
                                  [-19.387, -117.192],
                                  [48.293, -47.534],
                                  [-117.126, -36.195],
                                  [-17.139, -62.359],
                                  [-127.838, 9.231],
                                  [115.81, 77.071],
                                  [-38.121, 132.07],
                                  [-11.099, 92],
                                ],
                                o: [
                                  [-94.835, 40.638],
                                  [19.387, 117.192],
                                  [-48.293, 47.534],
                                  [117.126, 36.195],
                                  [19.377, 65.114],
                                  [110.278, -5.443],
                                  [-115.81, -77.071],
                                  [34.85, -134.997],
                                  [11.099, -92],
                                ],
                                v: [
                                  [-118.857, -359.973],
                                  [-158.547, -168.819],
                                  [-248.021, 37.824],
                                  [-244.586, 153.741],
                                  [-42.76, 281.662],
                                  [74.831, 380.037],
                                  [297.515, 272.173],
                                  [64.224, -64.376],
                                  [299.328, -193.22],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.782, -38.436],
                                  [-19.639, -117.477],
                                  [48.471, -47.359],
                                  [-117.443, -36.162],
                                  [-17.152, -62.505],
                                  [-127.598, 9.121],
                                  [116.237, 77.176],
                                  [-38.301, 132.433],
                                  [-11.052, 92],
                                ],
                                o: [
                                  [-94.914, 40.81],
                                  [19.639, 117.477],
                                  [-48.471, 47.359],
                                  [117.443, 36.162],
                                  [19.356, 65.218],
                                  [110.305, -5.391],
                                  [-116.237, -77.176],
                                  [35.08, -135.316],
                                  [11.052, -92],
                                ],
                                v: [
                                  [-118.93, -360.104],
                                  [-158.218, -168.423],
                                  [-248.419, 37.81],
                                  [-244.333, 153.827],
                                  [-43.507, 281.464],
                                  [75.285, 380.695],
                                  [297.732, 272.656],
                                  [63.678, -64.424],
                                  [300.112, -194.444],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.679, -38.643],
                                  [-19.888, -117.759],
                                  [48.646, -47.187],
                                  [-117.755, -36.129],
                                  [-17.165, -62.65],
                                  [-127.361, 9.013],
                                  [116.659, 77.28],
                                  [-38.479, 132.792],
                                  [-11.005, 92],
                                ],
                                o: [
                                  [-94.991, 40.98],
                                  [19.888, 117.759],
                                  [-48.646, 47.187],
                                  [117.755, 36.129],
                                  [19.336, 65.321],
                                  [110.33, -5.339],
                                  [-116.659, -77.28],
                                  [35.307, -135.63],
                                  [11.005, -92],
                                ],
                                v: [
                                  [-118.946, -360.188],
                                  [-157.949, -168.038],
                                  [-248.766, 37.8],
                                  [-244.133, 153.956],
                                  [-44.256, 281.261],
                                  [75.751, 381.445],
                                  [297.947, 273.103],
                                  [63.098, -64.507],
                                  [300.89, -195.72],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.577, -38.846],
                                  [-20.133, -118.037],
                                  [48.819, -47.016],
                                  [-118.064, -36.096],
                                  [-17.178, -62.792],
                                  [-127.127, 8.906],
                                  [117.076, 77.382],
                                  [-38.655, 133.146],
                                  [-10.96, 92],
                                ],
                                o: [
                                  [-95.067, 41.148],
                                  [20.133, 118.037],
                                  [-48.819, 47.016],
                                  [118.064, 36.096],
                                  [19.315, 65.423],
                                  [110.356, -5.288],
                                  [-117.076, -77.382],
                                  [35.531, -135.941],
                                  [10.96, -92],
                                ],
                                v: [
                                  [-118.894, -360.319],
                                  [-157.678, -167.643],
                                  [-249.113, 37.732],
                                  [-243.954, 154.088],
                                  [-45.027, 281.065],
                                  [76.312, 382.237],
                                  [298.168, 273.566],
                                  [62.578, -64.595],
                                  [301.61, -196.953],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.476, -39.047],
                                  [-20.376, -118.312],
                                  [48.99, -46.848],
                                  [-118.369, -36.064],
                                  [-17.19, -62.933],
                                  [-126.895, 8.8],
                                  [117.488, 77.483],
                                  [-38.828, 133.495],
                                  [-10.914, 92],
                                ],
                                o: [
                                  [-95.143, 41.314],
                                  [20.376, 118.312],
                                  [-48.99, 46.848],
                                  [118.369, 36.064],
                                  [19.295, 65.524],
                                  [110.381, -5.238],
                                  [-117.488, -77.483],
                                  [35.752, -136.247],
                                  [10.914, -92],
                                ],
                                v: [
                                  [-118.889, -360.391],
                                  [-157.458, -167.248],
                                  [-249.46, 37.647],
                                  [-243.765, 154.221],
                                  [-45.809, 280.865],
                                  [76.876, 383.03],
                                  [298.345, 273.971],
                                  [62.066, -64.667],
                                  [302.314, -198.195],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.377, -39.246],
                                  [-20.615, -118.583],
                                  [49.159, -46.682],
                                  [-118.67, -36.033],
                                  [-17.203, -63.072],
                                  [-126.667, 8.696],
                                  [117.894, 77.583],
                                  [-39, 133.84],
                                  [-10.87, 92],
                                ],
                                o: [
                                  [-95.217, 41.478],
                                  [20.615, 118.583],
                                  [-49.159, 46.682],
                                  [118.67, 36.033],
                                  [19.275, 65.623],
                                  [110.406, -5.189],
                                  [-117.894, -77.583],
                                  [35.97, -136.55],
                                  [10.87, -92],
                                ],
                                v: [
                                  [-118.773, -360.531],
                                  [-157.246, -166.915],
                                  [-249.806, 37.513],
                                  [-243.629, 154.357],
                                  [-46.55, 280.732],
                                  [77.494, 383.867],
                                  [298.519, 274.454],
                                  [61.562, -64.787],
                                  [303.012, -199.489],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.279, -39.442],
                                  [-20.852, -118.85],
                                  [49.326, -46.517],
                                  [-118.967, -36.001],
                                  [-17.215, -63.209],
                                  [-126.442, 8.593],
                                  [118.295, 77.682],
                                  [-39.169, 134.18],
                                  [-10.826, 92],
                                ],
                                o: [
                                  [-95.291, 41.64],
                                  [20.852, 118.85],
                                  [-49.326, 46.517],
                                  [118.967, 36.001],
                                  [19.256, 65.721],
                                  [110.43, -5.14],
                                  [-118.295, -77.682],
                                  [36.186, -136.849],
                                  [10.826, -92],
                                ],
                                v: [
                                  [-118.652, -360.612],
                                  [-157.086, -166.636],
                                  [-250.153, 37.372],
                                  [-243.547, 154.484],
                                  [-47.301, 280.543],
                                  [78.105, 384.737],
                                  [298.648, 274.878],
                                  [61.129, -64.859],
                                  [303.653, -200.739],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.182, -39.635],
                                  [-21.085, -119.114],
                                  [49.49, -46.355],
                                  [-119.26, -35.971],
                                  [-17.227, -63.345],
                                  [-126.219, 8.491],
                                  [118.691, 77.779],
                                  [-39.335, 134.516],
                                  [-10.782, 92],
                                ],
                                o: [
                                  [-95.363, 41.799],
                                  [21.085, 119.114],
                                  [-49.49, 46.355],
                                  [119.26, 35.971],
                                  [19.236, 65.818],
                                  [110.454, -5.091],
                                  [-118.691, -77.779],
                                  [36.399, -137.144],
                                  [10.782, -92],
                                ],
                                v: [
                                  [-118.525, -360.687],
                                  [-156.934, -166.347],
                                  [-250.447, 37.225],
                                  [-243.456, 154.614],
                                  [-48.115, 280.411],
                                  [78.781, 385.62],
                                  [298.774, 275.263],
                                  [60.65, -64.968],
                                  [304.234, -202.05],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.087, -39.826],
                                  [-21.315, -119.375],
                                  [49.652, -46.196],
                                  [-119.549, -35.94],
                                  [-17.239, -63.478],
                                  [-126, 8.391],
                                  [119.082, 77.875],
                                  [-39.5, 134.848],
                                  [-10.739, 92],
                                ],
                                o: [
                                  [-95.435, 41.957],
                                  [21.315, 119.375],
                                  [-49.652, 46.195],
                                  [119.549, 35.94],
                                  [19.217, 65.913],
                                  [110.478, -5.043],
                                  [-119.082, -77.875],
                                  [36.609, -137.435],
                                  [10.739, -92],
                                ],
                                v: [
                                  [-118.33, -360.828],
                                  [-156.736, -166.113],
                                  [-250.74, 37.018],
                                  [-243.428, 154.746],
                                  [-48.834, 280.275],
                                  [79.503, 386.485],
                                  [298.906, 275.66],
                                  [60.232, -65.072],
                                  [304.854, -203.306],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.993, -40.015],
                                  [-21.543, -119.633],
                                  [49.812, -46.038],
                                  [-119.835, -35.91],
                                  [-17.251, -63.61],
                                  [-125.783, 8.292],
                                  [119.468, 77.97],
                                  [-39.663, 135.176],
                                  [-10.697, 92],
                                ],
                                o: [
                                  [-95.505, 42.112],
                                  [21.543, 119.633],
                                  [-49.812, 46.038],
                                  [119.835, 35.91],
                                  [19.199, 66.007],
                                  [110.502, -4.996],
                                  [-119.468, -77.97],
                                  [36.816, -137.722],
                                  [10.697, -92],
                                ],
                                v: [
                                  [-118.139, -360.909],
                                  [-156.59, -165.879],
                                  [-251.034, 36.751],
                                  [-243.445, 154.827],
                                  [-49.616, 280.143],
                                  [80.228, 387.459],
                                  [298.992, 276.061],
                                  [59.821, -65.225],
                                  [305.371, -204.613],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.9, -40.201],
                                  [-21.767, -119.887],
                                  [49.971, -45.882],
                                  [-120.117, -35.88],
                                  [-17.263, -63.74],
                                  [-125.569, 8.195],
                                  [119.848, 78.063],
                                  [-39.823, 135.499],
                                  [-10.655, 92],
                                ],
                                o: [
                                  [-95.575, 42.266],
                                  [21.767, 119.887],
                                  [-49.971, 45.882],
                                  [120.117, 35.88],
                                  [19.18, 66.1],
                                  [110.525, -4.95],
                                  [-119.848, -78.063],
                                  [37.021, -138.006],
                                  [10.655, -92],
                                ],
                                v: [
                                  [-117.88, -360.984],
                                  [-156.407, -165.7],
                                  [-251.327, 36.531],
                                  [-243.465, 154.953],
                                  [-50.354, 280.025],
                                  [80.956, 388.373],
                                  [299.076, 276.465],
                                  [59.428, -65.373],
                                  [305.925, -205.856],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.808, -40.384],
                                  [-21.989, -120.137],
                                  [50.127, -45.728],
                                  [-120.395, -35.851],
                                  [-17.274, -63.869],
                                  [-125.358, 8.098],
                                  [120.224, 78.156],
                                  [-39.982, 135.818],
                                  [-10.614, 92],
                                ],
                                o: [
                                  [-95.644, 42.417],
                                  [21.989, 120.137],
                                  [-50.127, 45.728],
                                  [120.395, 35.851],
                                  [19.162, 66.192],
                                  [110.548, -4.904],
                                  [-120.224, -78.156],
                                  [37.223, -138.286],
                                  [10.614, -92],
                                ],
                                v: [
                                  [-117.615, -361.071],
                                  [-156.284, -165.513],
                                  [-251.611, 36.25],
                                  [-243.539, 155.038],
                                  [-51.102, 279.901],
                                  [81.741, 389.335],
                                  [299.122, 276.819],
                                  [59.095, -65.517],
                                  [306.43, -207.098],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.717, -40.566],
                                  [-22.207, -120.385],
                                  [50.281, -45.576],
                                  [-120.67, -35.822],
                                  [-17.285, -63.996],
                                  [-125.15, 8.003],
                                  [120.595, 78.247],
                                  [-40.138, 136.133],
                                  [-10.573, 92],
                                ],
                                o: [
                                  [-95.712, 42.567],
                                  [22.207, 120.385],
                                  [-50.281, 45.576],
                                  [120.67, 35.822],
                                  [19.143, 66.283],
                                  [110.571, -4.859],
                                  [-120.595, -78.247],
                                  [37.422, -138.562],
                                  [10.573, -92],
                                ],
                                v: [
                                  [-117.344, -361.151],
                                  [-156.105, -165.382],
                                  [-251.85, 35.909],
                                  [-243.615, 155.169],
                                  [-51.806, 279.781],
                                  [82.52, 390.3],
                                  [299.174, 277.184],
                                  [58.715, -65.656],
                                  [306.876, -208.391],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.628, -40.744],
                                  [-22.423, -120.629],
                                  [50.433, -45.427],
                                  [-120.941, -35.794],
                                  [-17.297, -64.121],
                                  [-124.944, 7.909],
                                  [120.961, 78.337],
                                  [-40.292, 136.443],
                                  [-10.533, 92],
                                ],
                                o: [
                                  [-95.779, 42.714],
                                  [22.423, 120.629],
                                  [-50.433, 45.427],
                                  [120.941, 35.794],
                                  [19.126, 66.372],
                                  [110.593, -4.814],
                                  [-120.961, -78.337],
                                  [37.619, -138.835],
                                  [10.532, -92],
                                ],
                                v: [
                                  [-117.014, -361.233],
                                  [-155.994, -165.307],
                                  [-252.08, 35.624],
                                  [-243.738, 155.248],
                                  [-52.527, 279.71],
                                  [83.357, 391.304],
                                  [299.231, 277.543],
                                  [58.397, -65.853],
                                  [307.315, -209.621],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.54, -40.921],
                                  [-22.636, -120.87],
                                  [50.583, -45.279],
                                  [-121.208, -35.766],
                                  [-17.308, -64.245],
                                  [-124.741, 7.816],
                                  [121.323, 78.426],
                                  [-40.444, 136.75],
                                  [-10.493, 92],
                                ],
                                o: [
                                  [-95.845, 42.86],
                                  [22.636, 120.87],
                                  [-50.583, 45.279],
                                  [121.208, 35.766],
                                  [19.108, 66.46],
                                  [110.615, -4.77],
                                  [-121.323, -78.426],
                                  [37.813, -139.104],
                                  [10.493, -92],
                                ],
                                v: [
                                  [-116.669, -361.308],
                                  [-155.88, -165.225],
                                  [-252.318, 35.269],
                                  [-243.873, 155.383],
                                  [-53.248, 279.642],
                                  [84.197, 392.321],
                                  [299.249, 277.858],
                                  [58.086, -66.037],
                                  [307.756, -210.849],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.452, -41.095],
                                  [-22.846, -121.108],
                                  [50.731, -45.133],
                                  [-121.472, -35.738],
                                  [-17.318, -64.367],
                                  [-124.541, 7.725],
                                  [121.679, 78.513],
                                  [-40.595, 137.053],
                                  [-10.454, 92],
                                ],
                                o: [
                                  [-95.911, 43.004],
                                  [22.846, 121.108],
                                  [-50.731, 45.133],
                                  [121.472, 35.738],
                                  [19.09, 66.548],
                                  [110.637, -4.726],
                                  [-121.679, -78.513],
                                  [38.005, -139.37],
                                  [10.454, -92],
                                ],
                                v: [
                                  [-116.328, -361.393],
                                  [-155.771, -165.154],
                                  [-252.547, 34.908],
                                  [-244.055, 155.467],
                                  [-53.924, 279.577],
                                  [85.086, 393.324],
                                  [299.273, 278.167],
                                  [57.836, -66.217],
                                  [308.129, -212.076],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.366, -41.267],
                                  [-23.053, -121.343],
                                  [50.877, -44.989],
                                  [-121.733, -35.711],
                                  [-17.329, -64.487],
                                  [-124.343, 7.635],
                                  [122.031, 78.6],
                                  [-40.743, 137.352],
                                  [-10.415, 92],
                                ],
                                o: [
                                  [-95.975, 43.145],
                                  [23.053, 121.343],
                                  [-50.877, 44.989],
                                  [121.733, 35.711],
                                  [19.073, 66.634],
                                  [110.658, -4.683],
                                  [-122.031, -78.6],
                                  [38.194, -139.632],
                                  [10.415, -92],
                                ],
                                v: [
                                  [-115.981, -361.525],
                                  [-155.722, -165.123],
                                  [-252.722, 34.548],
                                  [-244.293, 155.597],
                                  [-54.554, 279.507],
                                  [85.934, 394.331],
                                  [299.301, 278.487],
                                  [57.547, -66.456],
                                  [308.503, -213.239],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.282, -41.437],
                                  [-23.258, -121.575],
                                  [51.021, -44.847],
                                  [-121.99, -35.684],
                                  [-17.34, -64.606],
                                  [-124.148, 7.546],
                                  [122.378, 78.685],
                                  [-40.889, 137.646],
                                  [-10.377, 92],
                                ],
                                o: [
                                  [-96.039, 43.285],
                                  [23.258, 121.575],
                                  [-51.021, 44.847],
                                  [121.99, 35.684],
                                  [19.056, 66.718],
                                  [110.68, -4.641],
                                  [-122.378, -78.685],
                                  [38.38, -139.891],
                                  [10.377, -92],
                                ],
                                v: [
                                  [-115.565, -361.605],
                                  [-155.678, -165.157],
                                  [-252.888, 34.173],
                                  [-244.535, 155.684],
                                  [-55.2, 279.431],
                                  [86.831, 395.335],
                                  [299.28, 278.754],
                                  [57.312, -66.628],
                                  [308.817, -214.454],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.198, -41.604],
                                  [-23.46, -121.803],
                                  [51.164, -44.707],
                                  [-122.244, -35.657],
                                  [-17.35, -64.723],
                                  [-123.955, 7.458],
                                  [122.721, 78.77],
                                  [-41.034, 137.937],
                                  [-10.339, 92],
                                ],
                                o: [
                                  [-96.102, 43.423],
                                  [23.46, 121.803],
                                  [-51.164, 44.706],
                                  [122.244, 35.657],
                                  [19.04, 66.802],
                                  [110.701, -4.599],
                                  [-122.721, -78.77],
                                  [38.565, -140.146],
                                  [10.339, -92],
                                ],
                                v: [
                                  [-115.144, -361.677],
                                  [-155.63, -165.186],
                                  [-253.054, 33.738],
                                  [-244.833, 155.828],
                                  [-55.846, 279.413],
                                  [87.733, 396.342],
                                  [299.272, 279.023],
                                  [57.091, -66.913],
                                  [309.124, -215.614],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.115, -41.769],
                                  [-23.659, -122.029],
                                  [51.304, -44.568],
                                  [-122.494, -35.63],
                                  [-17.361, -64.839],
                                  [-123.765, 7.371],
                                  [123.059, 78.853],
                                  [-41.176, 138.224],
                                  [-10.302, 92],
                                ],
                                o: [
                                  [-96.163, 43.56],
                                  [23.659, 122.029],
                                  [-51.304, 44.568],
                                  [122.494, 35.63],
                                  [19.023, 66.885],
                                  [110.721, -4.558],
                                  [-123.059, -78.853],
                                  [38.746, -140.398],
                                  [10.302, -92],
                                ],
                                v: [
                                  [-114.726, -361.796],
                                  [-155.595, -165.281],
                                  [-253.166, 33.35],
                                  [-245.135, 155.973],
                                  [-56.452, 279.343],
                                  [88.63, 397.345],
                                  [299.276, 279.301],
                                  [56.878, -67.14],
                                  [309.37, -216.773],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.034, -41.932],
                                  [-23.856, -122.251],
                                  [51.443, -44.432],
                                  [-122.741, -35.604],
                                  [-17.371, -64.953],
                                  [-123.578, 7.286],
                                  [123.393, 78.935],
                                  [-41.317, 138.508],
                                  [-10.265, 92],
                                ],
                                o: [
                                  [-96.225, 43.694],
                                  [23.856, 122.251],
                                  [-51.443, 44.432],
                                  [122.741, 35.605],
                                  [19.007, 66.966],
                                  [110.742, -4.517],
                                  [-123.393, -78.935],
                                  [38.926, -140.647],
                                  [10.265, -92],
                                ],
                                v: [
                                  [-114.247, -361.908],
                                  [-155.611, -165.371],
                                  [-253.332, 32.956],
                                  [-245.494, 156.057],
                                  [-57.003, 279.322],
                                  [89.586, 398.345],
                                  [299.221, 279.572],
                                  [56.718, -67.417],
                                  [309.671, -217.93],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.953, -42.093],
                                  [-24.05, -122.471],
                                  [51.579, -44.297],
                                  [-122.985, -35.579],
                                  [-17.381, -65.065],
                                  [-123.393, 7.201],
                                  [123.722, 79.016],
                                  [-41.455, 138.787],
                                  [-10.229, 92],
                                ],
                                o: [
                                  [-96.285, 43.827],
                                  [24.05, 122.471],
                                  [-51.579, 44.297],
                                  [122.985, 35.579],
                                  [18.991, 67.047],
                                  [110.762, -4.477],
                                  [-123.722, -79.016],
                                  [39.103, -140.892],
                                  [10.229, -92],
                                ],
                                v: [
                                  [-113.818, -361.959],
                                  [-155.631, -165.512],
                                  [-253.434, 32.501],
                                  [-245.856, 156.206],
                                  [-57.514, 279.296],
                                  [90.547, 399.349],
                                  [299.178, 279.797],
                                  [56.574, -67.691],
                                  [309.848, -219.078],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.874, -42.252],
                                  [-24.241, -122.688],
                                  [51.714, -44.164],
                                  [-123.226, -35.554],
                                  [-17.391, -65.176],
                                  [-123.21, 7.118],
                                  [124.047, 79.096],
                                  [-41.592, 139.063],
                                  [-10.193, 92],
                                ],
                                o: [
                                  [-96.344, 43.958],
                                  [24.241, 122.688],
                                  [-51.714, 44.164],
                                  [123.226, 35.554],
                                  [18.975, 67.126],
                                  [110.782, -4.437],
                                  [-124.047, -79.096],
                                  [39.277, -141.134],
                                  [10.193, -92],
                                ],
                                v: [
                                  [-113.327, -362.066],
                                  [-155.655, -165.664],
                                  [-253.482, 32.087],
                                  [-246.276, 156.357],
                                  [-58.024, 279.319],
                                  [91.456, 400.35],
                                  [299.138, 280.031],
                                  [56.436, -67.961],
                                  [310.026, -220.225],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.796, -42.408],
                                  [-24.43, -122.902],
                                  [51.847, -44.033],
                                  [-123.463, -35.529],
                                  [-17.401, -65.286],
                                  [-123.03, 7.036],
                                  [124.367, 79.174],
                                  [-41.727, 139.335],
                                  [-10.158, 92],
                                ],
                                o: [
                                  [-96.403, 44.087],
                                  [24.43, 122.902],
                                  [-51.847, 44.033],
                                  [123.463, 35.529],
                                  [18.959, 67.204],
                                  [110.801, -4.398],
                                  [-124.367, -79.174],
                                  [39.449, -141.373],
                                  [10.158, -92],
                                ],
                                v: [
                                  [-112.886, -362.213],
                                  [-155.73, -165.812],
                                  [-253.578, 31.667],
                                  [-246.7, 156.447],
                                  [-58.548, 279.29],
                                  [92.417, 401.3],
                                  [299.055, 280.258],
                                  [56.305, -68.29],
                                  [310.205, -221.308],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.719, -42.563],
                                  [-24.617, -123.113],
                                  [51.979, -43.904],
                                  [-123.697, -35.504],
                                  [-17.41, -65.394],
                                  [-122.853, 6.954],
                                  [124.684, 79.252],
                                  [-41.861, 139.603],
                                  [-10.123, 92],
                                ],
                                o: [
                                  [-96.461, 44.214],
                                  [24.617, 123.113],
                                  [-51.979, 43.903],
                                  [123.697, 35.504],
                                  [18.944, 67.281],
                                  [110.82, -4.359],
                                  [-124.684, -79.252],
                                  [39.619, -141.608],
                                  [10.123, -92],
                                ],
                                v: [
                                  [-112.392, -362.299],
                                  [-155.809, -166.019],
                                  [-253.618, 31.248],
                                  [-247.126, 156.601],
                                  [-58.96, 279.319],
                                  [93.383, 402.302],
                                  [298.974, 280.438],
                                  [56.181, -68.664],
                                  [310.368, -222.398],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.642, -42.715],
                                  [-24.8, -123.321],
                                  [52.108, -43.776],
                                  [-123.928, -35.48],
                                  [-17.42, -65.501],
                                  [-122.677, 6.875],
                                  [124.995, 79.329],
                                  [-41.992, 139.868],
                                  [-10.089, 92],
                                ],
                                o: [
                                  [-96.518, 44.34],
                                  [24.8, 123.321],
                                  [-52.108, 43.776],
                                  [123.928, 35.48],
                                  [18.928, 67.358],
                                  [110.839, -4.321],
                                  [-124.995, -79.329],
                                  [39.787, -141.841],
                                  [10.089, -92],
                                ],
                                v: [
                                  [-111.892, -362.434],
                                  [-155.884, -166.23],
                                  [-253.658, 30.762],
                                  [-247.659, 156.757],
                                  [-59.385, 279.335],
                                  [94.352, 403.253],
                                  [298.897, 280.618],
                                  [56.119, -68.986],
                                  [310.477, -223.423],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.567, -42.866],
                                  [-24.982, -123.526],
                                  [52.236, -43.65],
                                  [-124.156, -35.456],
                                  [-17.429, -65.606],
                                  [-122.504, 6.796],
                                  [125.303, 79.404],
                                  [-42.122, 140.129],
                                  [-10.055, 92],
                                ],
                                o: [
                                  [-96.575, 44.464],
                                  [24.982, 123.526],
                                  [-52.236, 43.65],
                                  [124.156, 35.456],
                                  [18.913, 67.433],
                                  [110.858, -4.284],
                                  [-125.303, -79.404],
                                  [39.952, -142.07],
                                  [10.055, -92],
                                ],
                                v: [
                                  [-111.442, -362.515],
                                  [-156.025, -166.493],
                                  [-253.697, 30.373],
                                  [-248.147, 156.914],
                                  [-59.753, 279.353],
                                  [95.319, 404.201],
                                  [298.774, 280.806],
                                  [56.064, -69.367],
                                  [310.523, -224.502],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.493, -43.014],
                                  [-25.161, -123.728],
                                  [52.362, -43.526],
                                  [-124.381, -35.432],
                                  [-17.438, -65.71],
                                  [-122.334, 6.718],
                                  [125.607, 79.479],
                                  [-42.25, 140.387],
                                  [-10.022, 92],
                                ],
                                o: [
                                  [-96.63, 44.587],
                                  [25.161, 123.728],
                                  [-52.362, 43.526],
                                  [124.381, 35.432],
                                  [18.899, 67.507],
                                  [110.877, -4.246],
                                  [-125.607, -79.479],
                                  [40.115, -142.296],
                                  [10.022, -92],
                                ],
                                v: [
                                  [-110.931, -362.638],
                                  [-156.218, -166.768],
                                  [-253.675, 29.931],
                                  [-248.694, 157.018],
                                  [-60.134, 279.422],
                                  [96.29, 405.097],
                                  [298.708, 280.994],
                                  [56.008, -69.793],
                                  [310.617, -225.524],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.42, -43.16],
                                  [-25.337, -123.928],
                                  [52.486, -43.403],
                                  [-124.602, -35.409],
                                  [-17.448, -65.812],
                                  [-122.166, 6.641],
                                  [125.906, 79.552],
                                  [-42.376, 140.641],
                                  [-9.989, 92],
                                ],
                                o: [
                                  [-96.685, 44.707],
                                  [25.337, 123.928],
                                  [-52.486, 43.403],
                                  [124.602, 35.409],
                                  [18.884, 67.58],
                                  [110.895, -4.21],
                                  [-125.906, -79.552],
                                  [40.276, -142.519],
                                  [9.989, -92],
                                ],
                                v: [
                                  [-110.477, -362.81],
                                  [-156.408, -167.039],
                                  [-253.659, 29.476],
                                  [-249.3, 157.186],
                                  [-60.464, 279.487],
                                  [97.265, 406.047],
                                  [298.597, 281.188],
                                  [55.966, -70.223],
                                  [310.592, -226.538],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.348, -43.305],
                                  [-25.511, -124.125],
                                  [52.609, -43.282],
                                  [-124.821, -35.386],
                                  [-17.457, -65.913],
                                  [-122, 6.565],
                                  [126.201, 79.625],
                                  [-42.5, 140.892],
                                  [-9.956, 92],
                                ],
                                o: [
                                  [-96.739, 44.826],
                                  [25.511, 124.125],
                                  [-52.609, 43.282],
                                  [124.821, 35.386],
                                  [18.87, 67.652],
                                  [110.913, -4.174],
                                  [-126.201, -79.625],
                                  [40.435, -142.739],
                                  [9.957, -92],
                                ],
                                v: [
                                  [-109.961, -362.921],
                                  [-156.601, -167.371],
                                  [-253.629, 29.079],
                                  [-249.909, 157.348],
                                  [-60.68, 279.546],
                                  [98.238, 406.945],
                                  [298.432, 281.326],
                                  [55.987, -70.65],
                                  [310.623, -227.495],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.277, -43.447],
                                  [-25.683, -124.32],
                                  [52.73, -43.163],
                                  [-125.037, -35.363],
                                  [-17.465, -66.013],
                                  [-121.836, 6.49],
                                  [126.493, 79.697],
                                  [-42.623, 141.139],
                                  [-9.924, 92],
                                ],
                                o: [
                                  [-96.793, 44.944],
                                  [25.683, 124.32],
                                  [-52.73, 43.163],
                                  [125.037, 35.363],
                                  [18.855, 67.723],
                                  [110.931, -4.138],
                                  [-126.493, -79.697],
                                  [40.592, -142.956],
                                  [9.924, -92],
                                ],
                                v: [
                                  [-109.496, -363.09],
                                  [-156.852, -167.706],
                                  [-253.606, 28.67],
                                  [-250.515, 157.518],
                                  [-60.908, 279.608],
                                  [99.159, 407.79],
                                  [298.325, 281.471],
                                  [56.008, -71.129],
                                  [310.59, -228.45],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.206, -43.587],
                                  [-25.852, -124.511],
                                  [52.849, -43.046],
                                  [-125.249, -35.341],
                                  [-17.474, -66.111],
                                  [-121.675, 6.417],
                                  [126.78, 79.767],
                                  [-42.744, 141.383],
                                  [-9.893, 92],
                                ],
                                o: [
                                  [-96.845, 45.059],
                                  [25.852, 124.511],
                                  [-52.849, 43.046],
                                  [125.249, 35.341],
                                  [18.841, 67.794],
                                  [110.948, -4.103],
                                  [-126.78, -79.767],
                                  [40.746, -143.171],
                                  [9.893, -92],
                                ],
                                v: [
                                  [-109.031, -363.302],
                                  [-157.1, -168.04],
                                  [-253.527, 28.313],
                                  [-251.187, 157.634],
                                  [-61.134, 279.715],
                                  [100.14, 408.633],
                                  [298.17, 281.615],
                                  [56.042, -71.612],
                                  [310.55, -229.348],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.137, -43.726],
                                  [-26.019, -124.7],
                                  [52.967, -42.93],
                                  [-125.459, -35.319],
                                  [-17.483, -66.208],
                                  [-121.515, 6.344],
                                  [127.064, 79.837],
                                  [-42.863, 141.623],
                                  [-9.862, 92],
                                ],
                                o: [
                                  [-96.897, 45.174],
                                  [26.019, 124.7],
                                  [-52.967, 42.93],
                                  [125.459, 35.319],
                                  [18.827, 67.863],
                                  [110.966, -4.069],
                                  [-127.064, -79.837],
                                  [40.898, -143.382],
                                  [9.862, -92],
                                ],
                                v: [
                                  [-108.562, -363.459],
                                  [-157.401, -168.384],
                                  [-253.441, 27.888],
                                  [-251.863, 157.806],
                                  [-61.258, 279.768],
                                  [101.125, 409.424],
                                  [298.016, 281.758],
                                  [56.133, -72.148],
                                  [310.453, -230.3],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.069, -43.862],
                                  [-26.184, -124.887],
                                  [53.083, -42.815],
                                  [-125.666, -35.297],
                                  [-17.491, -66.304],
                                  [-121.358, 6.272],
                                  [127.343, 79.906],
                                  [-42.981, 141.861],
                                  [-9.831, 92],
                                ],
                                o: [
                                  [-96.948, 45.286],
                                  [26.184, 124.887],
                                  [-53.083, 42.815],
                                  [125.666, 35.297],
                                  [18.814, 67.931],
                                  [110.983, -4.034],
                                  [-127.343, -79.906],
                                  [41.048, -143.59],
                                  [9.831, -92],
                                ],
                                v: [
                                  [-108.093, -363.661],
                                  [-157.705, -168.782],
                                  [-253.356, 27.522],
                                  [-252.548, 157.924],
                                  [-61.324, 279.866],
                                  [102.051, 410.212],
                                  [297.871, 281.85],
                                  [56.174, -72.681],
                                  [310.348, -231.132],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.137, -43.726],
                                  [-26.019, -124.7],
                                  [52.967, -42.93],
                                  [-125.459, -35.319],
                                  [-17.483, -66.208],
                                  [-121.515, 6.344],
                                  [127.063, 79.837],
                                  [-42.863, 141.623],
                                  [-9.862, 92],
                                ],
                                o: [
                                  [-96.897, 45.174],
                                  [26.019, 124.7],
                                  [-52.967, 42.93],
                                  [125.459, 35.319],
                                  [18.827, 67.863],
                                  [110.966, -4.069],
                                  [-127.064, -79.837],
                                  [40.898, -143.382],
                                  [9.862, -92],
                                ],
                                v: [
                                  [-107.077, -363.47],
                                  [-158.013, -169.442],
                                  [-253.299, 26.765],
                                  [-253.179, 158.028],
                                  [-61.864, 279.831],
                                  [103.023, 410.663],
                                  [297.878, 281.751],
                                  [57.15, -72.607],
                                  [309.47, -231.49],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.206, -43.587],
                                  [-25.852, -124.511],
                                  [52.849, -43.046],
                                  [-125.249, -35.341],
                                  [-17.474, -66.111],
                                  [-121.675, 6.417],
                                  [126.78, 79.767],
                                  [-42.744, 141.383],
                                  [-9.893, 92],
                                ],
                                o: [
                                  [-96.845, 45.059],
                                  [25.852, 124.511],
                                  [-52.849, 43.046],
                                  [125.249, 35.341],
                                  [18.841, 67.794],
                                  [110.948, -4.103],
                                  [-126.78, -79.767],
                                  [40.746, -143.171],
                                  [9.893, -92],
                                ],
                                v: [
                                  [-106.064, -363.328],
                                  [-158.33, -170.152],
                                  [-253.3, 26.007],
                                  [-253.806, 158.068],
                                  [-62.351, 279.853],
                                  [103.999, 411.101],
                                  [297.887, 281.707],
                                  [58.131, -72.583],
                                  [308.533, -231.779],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.277, -43.447],
                                  [-25.683, -124.32],
                                  [52.73, -43.163],
                                  [-125.037, -35.363],
                                  [-17.465, -66.013],
                                  [-121.836, 6.49],
                                  [126.493, 79.697],
                                  [-42.623, 141.139],
                                  [-9.924, 92],
                                ],
                                o: [
                                  [-96.793, 44.944],
                                  [25.683, 124.32],
                                  [-52.73, 43.163],
                                  [125.037, 35.363],
                                  [18.855, 67.723],
                                  [110.931, -4.138],
                                  [-126.493, -79.697],
                                  [40.592, -142.956],
                                  [9.924, -92],
                                ],
                                v: [
                                  [-105.055, -363.191],
                                  [-158.65, -170.805],
                                  [-253.184, 25.239],
                                  [-254.427, 158.119],
                                  [-62.723, 279.87],
                                  [104.964, 411.476],
                                  [297.854, 281.605],
                                  [59.173, -72.552],
                                  [307.6, -232.014],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.348, -43.305],
                                  [-25.511, -124.125],
                                  [52.609, -43.282],
                                  [-124.821, -35.386],
                                  [-17.457, -65.913],
                                  [-122, 6.565],
                                  [126.201, 79.625],
                                  [-42.5, 140.892],
                                  [-9.956, 92],
                                ],
                                o: [
                                  [-96.739, 44.826],
                                  [25.511, 124.125],
                                  [-52.609, 43.282],
                                  [124.821, 35.386],
                                  [18.87, 67.652],
                                  [110.913, -4.174],
                                  [-126.201, -79.625],
                                  [40.435, -142.739],
                                  [9.956, -92],
                                ],
                                v: [
                                  [-104.044, -363.048],
                                  [-159.091, -171.515],
                                  [-253.132, 24.532],
                                  [-255.106, 158.162],
                                  [-63.106, 279.897],
                                  [105.87, 411.78],
                                  [297.815, 281.459],
                                  [60.221, -72.577],
                                  [306.665, -232.235],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.42, -43.16],
                                  [-25.337, -123.928],
                                  [52.486, -43.403],
                                  [-124.602, -35.409],
                                  [-17.448, -65.812],
                                  [-122.166, 6.641],
                                  [125.906, 79.552],
                                  [-42.376, 140.641],
                                  [-9.989, 92],
                                ],
                                o: [
                                  [-96.685, 44.707],
                                  [25.337, 123.928],
                                  [-52.486, 43.403],
                                  [124.602, 35.409],
                                  [18.884, 67.58],
                                  [110.895, -4.21],
                                  [-125.906, -79.552],
                                  [40.276, -142.519],
                                  [9.989, -92],
                                ],
                                v: [
                                  [-103.085, -362.966],
                                  [-159.479, -172.224],
                                  [-253.074, 23.821],
                                  [-255.772, 158.209],
                                  [-63.436, 279.919],
                                  [106.82, 412.084],
                                  [297.785, 281.362],
                                  [61.267, -72.594],
                                  [305.671, -232.444],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.493, -43.014],
                                  [-25.161, -123.728],
                                  [52.362, -43.526],
                                  [-124.381, -35.432],
                                  [-17.438, -65.71],
                                  [-122.334, 6.718],
                                  [125.607, 79.479],
                                  [-42.25, 140.387],
                                  [-10.022, 92],
                                ],
                                o: [
                                  [-96.63, 44.587],
                                  [25.161, 123.728],
                                  [-52.362, 43.526],
                                  [124.381, 35.432],
                                  [18.899, 67.507],
                                  [110.877, -4.246],
                                  [-125.607, -79.479],
                                  [40.115, -142.296],
                                  [10.022, -92],
                                ],
                                v: [
                                  [-102.131, -362.876],
                                  [-159.918, -172.877],
                                  [-252.963, 23.157],
                                  [-256.496, 158.2],
                                  [-63.709, 279.949],
                                  [107.712, 412.318],
                                  [297.763, 281.27],
                                  [62.269, -72.716],
                                  [304.738, -232.599],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.567, -42.866],
                                  [-24.982, -123.526],
                                  [52.236, -43.65],
                                  [-124.156, -35.456],
                                  [-17.429, -65.606],
                                  [-122.504, 6.796],
                                  [125.303, 79.404],
                                  [-42.122, 140.129],
                                  [-10.055, 92],
                                ],
                                o: [
                                  [-96.575, 44.464],
                                  [24.982, 123.526],
                                  [-52.236, 43.65],
                                  [124.156, 35.456],
                                  [18.913, 67.433],
                                  [110.858, -4.284],
                                  [-125.303, -79.404],
                                  [39.952, -142.07],
                                  [10.055, -92],
                                ],
                                v: [
                                  [-101.222, -362.848],
                                  [-160.374, -173.53],
                                  [-252.853, 22.49],
                                  [-257.151, 158.188],
                                  [-63.937, 280.031],
                                  [108.544, 412.496],
                                  [297.743, 281.129],
                                  [63.269, -72.782],
                                  [303.691, -232.74],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.642, -42.715],
                                  [-24.8, -123.321],
                                  [52.108, -43.776],
                                  [-123.928, -35.48],
                                  [-17.42, -65.501],
                                  [-122.677, 6.874],
                                  [124.995, 79.329],
                                  [-41.992, 139.868],
                                  [-10.089, 92],
                                ],
                                o: [
                                  [-96.518, 44.34],
                                  [24.8, 123.321],
                                  [-52.108, 43.776],
                                  [123.928, 35.48],
                                  [18.928, 67.358],
                                  [110.839, -4.321],
                                  [-124.995, -79.329],
                                  [39.787, -141.841],
                                  [10.089, -92],
                                ],
                                v: [
                                  [-100.324, -362.818],
                                  [-160.881, -174.183],
                                  [-252.689, 21.876],
                                  [-257.862, 158.183],
                                  [-64.108, 280.106],
                                  [109.427, 412.659],
                                  [297.733, 280.988],
                                  [64.275, -72.951],
                                  [302.698, -232.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.719, -42.563],
                                  [-24.617, -123.113],
                                  [51.979, -43.904],
                                  [-123.697, -35.504],
                                  [-17.41, -65.394],
                                  [-122.853, 6.954],
                                  [124.684, 79.252],
                                  [-41.861, 139.603],
                                  [-10.123, 92],
                                ],
                                o: [
                                  [-96.461, 44.214],
                                  [24.617, 123.113],
                                  [-51.979, 43.904],
                                  [123.697, 35.504],
                                  [18.944, 67.281],
                                  [110.82, -4.359],
                                  [-124.683, -79.252],
                                  [39.619, -141.608],
                                  [10.123, -92],
                                ],
                                v: [
                                  [-99.472, -362.788],
                                  [-161.453, -174.828],
                                  [-252.575, 21.257],
                                  [-258.568, 158.176],
                                  [-64.29, 280.192],
                                  [110.189, 412.76],
                                  [297.724, 280.908],
                                  [65.224, -73.12],
                                  [301.655, -232.879],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.796, -42.408],
                                  [-24.43, -122.902],
                                  [51.847, -44.033],
                                  [-123.463, -35.529],
                                  [-17.401, -65.286],
                                  [-123.03, 7.036],
                                  [124.367, 79.174],
                                  [-41.727, 139.335],
                                  [-10.158, 92],
                                ],
                                o: [
                                  [-96.403, 44.087],
                                  [24.43, 122.902],
                                  [-51.847, 44.033],
                                  [123.463, 35.529],
                                  [18.959, 67.204],
                                  [110.801, -4.398],
                                  [-124.367, -79.174],
                                  [39.449, -141.373],
                                  [10.158, -92],
                                ],
                                v: [
                                  [-98.623, -362.812],
                                  [-161.973, -175.426],
                                  [-252.415, 20.739],
                                  [-259.267, 158.12],
                                  [-64.368, 280.326],
                                  [111.002, 412.908],
                                  [297.725, 280.781],
                                  [66.131, -73.281],
                                  [300.611, -232.885],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.874, -42.252],
                                  [-24.241, -122.688],
                                  [51.714, -44.164],
                                  [-123.226, -35.554],
                                  [-17.391, -65.176],
                                  [-123.21, 7.118],
                                  [124.047, 79.096],
                                  [-41.592, 139.063],
                                  [-10.193, 92],
                                ],
                                o: [
                                  [-96.344, 43.958],
                                  [24.241, 122.688],
                                  [-51.714, 44.164],
                                  [123.226, 35.554],
                                  [18.975, 67.126],
                                  [110.781, -4.437],
                                  [-124.047, -79.095],
                                  [39.277, -141.134],
                                  [10.193, -92],
                                ],
                                v: [
                                  [-97.779, -362.834],
                                  [-162.543, -176.024],
                                  [-252.249, 20.168],
                                  [-260.023, 158.064],
                                  [-64.388, 280.462],
                                  [111.748, 412.993],
                                  [297.681, 280.646],
                                  [67.09, -73.552],
                                  [299.564, -232.884],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.953, -42.093],
                                  [-24.05, -122.471],
                                  [51.579, -44.297],
                                  [-122.985, -35.579],
                                  [-17.381, -65.065],
                                  [-123.393, 7.201],
                                  [123.722, 79.016],
                                  [-41.455, 138.787],
                                  [-10.229, 92],
                                ],
                                o: [
                                  [-96.285, 43.827],
                                  [24.05, 122.471],
                                  [-51.579, 44.297],
                                  [122.985, 35.579],
                                  [18.991, 67.047],
                                  [110.762, -4.477],
                                  [-123.722, -79.016],
                                  [39.103, -140.892],
                                  [10.229, -92],
                                ],
                                v: [
                                  [-96.98, -362.91],
                                  [-163.178, -176.621],
                                  [-252.092, 19.704],
                                  [-260.71, 157.951],
                                  [-64.368, 280.6],
                                  [112.498, 413.016],
                                  [297.695, 280.526],
                                  [67.946, -73.753],
                                  [298.524, -232.814],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.034, -41.932],
                                  [-23.856, -122.251],
                                  [51.443, -44.432],
                                  [-122.741, -35.605],
                                  [-17.371, -64.953],
                                  [-123.578, 7.286],
                                  [123.393, 78.935],
                                  [-41.317, 138.508],
                                  [-10.265, 92],
                                ],
                                o: [
                                  [-96.225, 43.694],
                                  [23.856, 122.251],
                                  [-51.443, 44.432],
                                  [122.741, 35.604],
                                  [19.007, 66.966],
                                  [110.742, -4.517],
                                  [-123.393, -78.935],
                                  [38.926, -140.647],
                                  [10.265, -92],
                                ],
                                v: [
                                  [-96.239, -362.985],
                                  [-163.762, -177.156],
                                  [-251.875, 19.228],
                                  [-261.452, 157.791],
                                  [-64.346, 280.731],
                                  [113.235, 413.086],
                                  [297.72, 280.352],
                                  [68.855, -74.07],
                                  [297.428, -232.675],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.115, -41.769],
                                  [-23.659, -122.029],
                                  [51.304, -44.568],
                                  [-122.494, -35.63],
                                  [-17.361, -64.839],
                                  [-123.765, 7.371],
                                  [123.059, 78.853],
                                  [-41.176, 138.224],
                                  [-10.302, 92],
                                ],
                                o: [
                                  [-96.164, 43.56],
                                  [23.659, 122.029],
                                  [-51.304, 44.568],
                                  [122.494, 35.63],
                                  [19.023, 66.885],
                                  [110.721, -4.558],
                                  [-123.059, -78.853],
                                  [38.746, -140.398],
                                  [10.302, -92],
                                ],
                                v: [
                                  [-95.495, -363.112],
                                  [-164.347, -177.754],
                                  [-251.715, 18.747],
                                  [-262.181, 157.631],
                                  [-64.228, 280.856],
                                  [113.905, 413.093],
                                  [297.693, 280.232],
                                  [69.723, -74.379],
                                  [296.339, -232.585],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.198, -41.604],
                                  [-23.46, -121.803],
                                  [51.164, -44.707],
                                  [-122.244, -35.657],
                                  [-17.35, -64.723],
                                  [-123.955, 7.458],
                                  [122.721, 78.77],
                                  [-41.034, 137.937],
                                  [-10.339, 92],
                                ],
                                o: [
                                  [-96.102, 43.423],
                                  [23.46, 121.803],
                                  [-51.164, 44.706],
                                  [122.244, 35.657],
                                  [19.04, 66.802],
                                  [110.701, -4.599],
                                  [-122.721, -78.77],
                                  [38.565, -140.146],
                                  [10.339, -92],
                                ],
                                v: [
                                  [-94.811, -363.238],
                                  [-164.936, -178.406],
                                  [-251.51, 18.378],
                                  [-262.91, 157.479],
                                  [-64.056, 281.037],
                                  [114.579, 413.1],
                                  [297.74, 280.122],
                                  [70.534, -74.679],
                                  [295.25, -232.433],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.282, -41.437],
                                  [-23.258, -121.575],
                                  [51.021, -44.847],
                                  [-121.99, -35.684],
                                  [-17.34, -64.606],
                                  [-124.148, 7.546],
                                  [122.378, 78.685],
                                  [-40.889, 137.646],
                                  [-10.377, 92],
                                ],
                                o: [
                                  [-96.039, 43.285],
                                  [23.258, 121.575],
                                  [-51.021, 44.847],
                                  [121.99, 35.684],
                                  [19.056, 66.718],
                                  [110.68, -4.641],
                                  [-122.378, -78.685],
                                  [38.38, -139.891],
                                  [10.377, -92],
                                ],
                                v: [
                                  [-94.177, -363.417],
                                  [-165.528, -179.003],
                                  [-251.3, 17.996],
                                  [-263.578, 157.273],
                                  [-63.898, 281.218],
                                  [115.241, 413.091],
                                  [297.791, 279.957],
                                  [71.352, -75.033],
                                  [294.214, -232.321],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.366, -41.267],
                                  [-23.053, -121.343],
                                  [50.877, -44.989],
                                  [-121.733, -35.711],
                                  [-17.329, -64.487],
                                  [-124.343, 7.635],
                                  [122.031, 78.6],
                                  [-40.743, 137.352],
                                  [-10.415, 92],
                                ],
                                o: [
                                  [-95.975, 43.145],
                                  [23.053, 121.343],
                                  [-50.877, 44.989],
                                  [121.733, 35.711],
                                  [19.073, 66.634],
                                  [110.658, -4.683],
                                  [-122.031, -78.6],
                                  [38.194, -139.632],
                                  [10.415, -92],
                                ],
                                v: [
                                  [-93.539, -363.593],
                                  [-166.069, -179.654],
                                  [-251.091, 17.663],
                                  [-264.294, 157.013],
                                  [-63.632, 281.392],
                                  [115.835, 413.028],
                                  [297.845, 279.856],
                                  [72.114, -75.433],
                                  [293.131, -232.147],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.452, -41.095],
                                  [-22.846, -121.108],
                                  [50.731, -45.133],
                                  [-121.472, -35.738],
                                  [-17.318, -64.367],
                                  [-124.541, 7.725],
                                  [121.679, 78.514],
                                  [-40.595, 137.053],
                                  [-10.454, 92],
                                ],
                                o: [
                                  [-95.911, 43.004],
                                  [22.846, 121.108],
                                  [-50.731, 45.133],
                                  [121.472, 35.738],
                                  [19.09, 66.548],
                                  [110.637, -4.726],
                                  [-121.679, -78.513],
                                  [38.005, -139.37],
                                  [10.454, -92],
                                ],
                                v: [
                                  [-92.952, -363.767],
                                  [-166.614, -180.305],
                                  [-250.831, 17.334],
                                  [-265.011, 156.763],
                                  [-63.382, 281.558],
                                  [116.432, 413.011],
                                  [297.912, 279.756],
                                  [72.89, -75.824],
                                  [292.048, -232.02],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.54, -40.921],
                                  [-22.636, -120.87],
                                  [50.583, -45.279],
                                  [-121.208, -35.766],
                                  [-17.308, -64.245],
                                  [-124.741, 7.816],
                                  [121.323, 78.426],
                                  [-40.444, 136.75],
                                  [-10.493, 92],
                                ],
                                o: [
                                  [-95.845, 42.86],
                                  [22.636, 120.87],
                                  [-50.583, 45.279],
                                  [121.208, 35.766],
                                  [19.108, 66.46],
                                  [110.615, -4.77],
                                  [-121.323, -78.426],
                                  [37.813, -139.104],
                                  [10.493, -92],
                                ],
                                v: [
                                  [-92.415, -364.002],
                                  [-167.162, -180.956],
                                  [-250.627, 17.044],
                                  [-265.659, 156.522],
                                  [-63.023, 281.779],
                                  [116.962, 412.879],
                                  [297.992, 279.602],
                                  [73.664, -76.214],
                                  [290.973, -231.823],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.628, -40.744],
                                  [-22.423, -120.629],
                                  [50.433, -45.427],
                                  [-120.941, -35.794],
                                  [-17.297, -64.121],
                                  [-124.944, 7.909],
                                  [120.961, 78.337],
                                  [-40.292, 136.443],
                                  [-10.533, 92],
                                ],
                                o: [
                                  [-95.779, 42.714],
                                  [22.423, 120.629],
                                  [-50.433, 45.427],
                                  [120.941, 35.794],
                                  [19.126, 66.372],
                                  [110.593, -4.814],
                                  [-120.961, -78.337],
                                  [37.619, -138.835],
                                  [10.533, -92],
                                ],
                                v: [
                                  [-91.936, -364.28],
                                  [-167.704, -181.606],
                                  [-250.371, 16.81],
                                  [-266.362, 156.228],
                                  [-62.673, 282.036],
                                  [117.488, 412.792],
                                  [298.067, 279.513],
                                  [74.329, -76.657],
                                  [289.944, -231.673],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.717, -40.566],
                                  [-22.207, -120.385],
                                  [50.281, -45.576],
                                  [-120.67, -35.822],
                                  [-17.285, -63.996],
                                  [-125.15, 8.003],
                                  [120.595, 78.247],
                                  [-40.138, 136.133],
                                  [-10.573, 92],
                                ],
                                o: [
                                  [-95.712, 42.567],
                                  [22.207, 120.385],
                                  [-50.281, 45.576],
                                  [120.67, 35.822],
                                  [19.143, 66.283],
                                  [110.571, -4.859],
                                  [-120.595, -78.247],
                                  [37.422, -138.562],
                                  [10.573, -92],
                                ],
                                v: [
                                  [-91.452, -364.555],
                                  [-168.259, -182.256],
                                  [-250.127, 16.615],
                                  [-267.005, 155.945],
                                  [-62.341, 282.239],
                                  [118.008, 412.652],
                                  [298.209, 279.362],
                                  [75.063, -77.092],
                                  [288.869, -231.461],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.808, -40.384],
                                  [-21.989, -120.137],
                                  [50.127, -45.728],
                                  [-120.395, -35.851],
                                  [-17.274, -63.869],
                                  [-125.358, 8.098],
                                  [120.224, 78.156],
                                  [-39.981, 135.818],
                                  [-10.614, 92],
                                ],
                                o: [
                                  [-95.644, 42.417],
                                  [21.989, 120.137],
                                  [-50.127, 45.728],
                                  [120.395, 35.851],
                                  [19.162, 66.192],
                                  [110.548, -4.904],
                                  [-120.224, -78.156],
                                  [37.223, -138.286],
                                  [10.614, -92],
                                ],
                                v: [
                                  [-91.017, -364.889],
                                  [-168.755, -182.958],
                                  [-249.877, 16.423],
                                  [-267.64, 155.664],
                                  [-61.965, 282.548],
                                  [118.515, 412.505],
                                  [298.293, 279.276],
                                  [75.742, -77.57],
                                  [287.803, -231.241],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.9, -40.201],
                                  [-21.767, -119.887],
                                  [49.971, -45.882],
                                  [-120.117, -35.88],
                                  [-17.263, -63.74],
                                  [-125.569, 8.195],
                                  [119.848, 78.063],
                                  [-39.823, 135.499],
                                  [-10.655, 92],
                                ],
                                o: [
                                  [-95.575, 42.266],
                                  [21.767, 119.887],
                                  [-49.971, 45.882],
                                  [120.117, 35.881],
                                  [19.18, 66.1],
                                  [110.525, -4.95],
                                  [-119.848, -78.063],
                                  [37.021, -138.006],
                                  [10.655, -92],
                                ],
                                v: [
                                  [-90.631, -365.212],
                                  [-169.246, -183.616],
                                  [-249.683, 16.224],
                                  [-268.268, 155.331],
                                  [-61.545, 282.839],
                                  [118.963, 412.35],
                                  [298.444, 279.137],
                                  [76.374, -78.048],
                                  [286.729, -231.067],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.993, -40.015],
                                  [-21.543, -119.633],
                                  [49.812, -46.038],
                                  [-119.835, -35.91],
                                  [-17.251, -63.61],
                                  [-125.783, 8.292],
                                  [119.468, 77.97],
                                  [-39.663, 135.175],
                                  [-10.697, 92],
                                ],
                                o: [
                                  [-95.505, 42.112],
                                  [21.543, 119.633],
                                  [-49.812, 46.038],
                                  [119.835, 35.91],
                                  [19.199, 66.007],
                                  [110.502, -4.996],
                                  [-119.468, -77.97],
                                  [36.816, -137.722],
                                  [10.697, -92],
                                ],
                                v: [
                                  [-90.24, -365.595],
                                  [-169.749, -184.317],
                                  [-249.428, 16.009],
                                  [-268.899, 155.01],
                                  [-61.135, 283.127],
                                  [119.352, 412.143],
                                  [298.653, 279.053],
                                  [77.004, -78.517],
                                  [285.665, -230.822],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.087, -39.826],
                                  [-21.315, -119.375],
                                  [49.652, -46.196],
                                  [-119.549, -35.94],
                                  [-17.239, -63.478],
                                  [-126, 8.391],
                                  [119.082, 77.875],
                                  [-39.5, 134.848],
                                  [-10.739, 92],
                                ],
                                o: [
                                  [-95.435, 41.957],
                                  [21.315, 119.375],
                                  [-49.652, 46.196],
                                  [119.549, 35.94],
                                  [19.217, 65.913],
                                  [110.478, -5.043],
                                  [-119.082, -77.875],
                                  [36.609, -137.435],
                                  [10.739, -92],
                                ],
                                v: [
                                  [-89.854, -365.965],
                                  [-170.194, -184.974],
                                  [-249.23, 15.849],
                                  [-269.512, 154.648],
                                  [-60.682, 283.458],
                                  [119.789, 411.928],
                                  [298.812, 278.917],
                                  [77.588, -79.038],
                                  [284.654, -230.568],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.182, -39.635],
                                  [-21.085, -119.114],
                                  [49.49, -46.355],
                                  [-119.26, -35.971],
                                  [-17.227, -63.345],
                                  [-126.219, 8.492],
                                  [118.691, 77.779],
                                  [-39.335, 134.516],
                                  [-10.782, 92],
                                ],
                                o: [
                                  [-95.363, 41.799],
                                  [21.085, 119.114],
                                  [-49.49, 46.356],
                                  [119.26, 35.971],
                                  [19.236, 65.818],
                                  [110.454, -5.091],
                                  [-118.691, -77.779],
                                  [36.399, -137.144],
                                  [10.782, -92],
                                ],
                                v: [
                                  [-89.472, -366.375],
                                  [-170.643, -185.683],
                                  [-248.98, 15.673],
                                  [-270.075, 154.289],
                                  [-60.249, 283.777],
                                  [120.159, 411.715],
                                  [298.977, 278.783],
                                  [78.18, -79.54],
                                  [283.59, -230.306],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.279, -39.442],
                                  [-20.852, -118.85],
                                  [49.326, -46.517],
                                  [-118.967, -36.001],
                                  [-17.215, -63.209],
                                  [-126.442, 8.593],
                                  [118.295, 77.682],
                                  [-39.169, 134.18],
                                  [-10.826, 92],
                                ],
                                o: [
                                  [-95.291, 41.64],
                                  [20.852, 118.85],
                                  [-49.326, 46.517],
                                  [118.967, 36.001],
                                  [19.256, 65.721],
                                  [110.43, -5.14],
                                  [-118.295, -77.682],
                                  [36.186, -136.849],
                                  [10.826, -92],
                                ],
                                v: [
                                  [-89.095, -366.771],
                                  [-171.096, -186.339],
                                  [-248.786, 15.499],
                                  [-270.631, 153.944],
                                  [-59.818, 284.093],
                                  [120.47, 411.443],
                                  [299.199, 278.704],
                                  [78.726, -80.042],
                                  [282.528, -230.088],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.377, -39.246],
                                  [-20.615, -118.583],
                                  [49.159, -46.682],
                                  [-118.67, -36.033],
                                  [-17.203, -63.072],
                                  [-126.667, 8.696],
                                  [117.894, 77.583],
                                  [-39, 133.84],
                                  [-10.87, 92],
                                ],
                                o: [
                                  [-95.217, 41.478],
                                  [20.615, 118.583],
                                  [-49.159, 46.682],
                                  [118.67, 36.033],
                                  [19.275, 65.623],
                                  [110.406, -5.189],
                                  [-117.894, -77.583],
                                  [35.971, -136.55],
                                  [10.87, -92],
                                ],
                                v: [
                                  [-88.724, -367.205],
                                  [-171.544, -187.048],
                                  [-248.584, 15.308],
                                  [-271.127, 153.602],
                                  [-59.355, 284.45],
                                  [120.829, 411.162],
                                  [299.425, 278.574],
                                  [79.228, -80.586],
                                  [281.476, -229.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.476, -39.047],
                                  [-20.376, -118.312],
                                  [48.99, -46.848],
                                  [-118.369, -36.064],
                                  [-17.19, -62.933],
                                  [-126.895, 8.8],
                                  [117.488, 77.483],
                                  [-38.828, 133.495],
                                  [-10.914, 92],
                                ],
                                o: [
                                  [-95.143, 41.314],
                                  [20.376, 118.312],
                                  [-48.99, 46.848],
                                  [118.369, 36.064],
                                  [19.295, 65.524],
                                  [110.381, -5.238],
                                  [-117.488, -77.483],
                                  [35.752, -136.247],
                                  [10.914, -92],
                                ],
                                v: [
                                  [-88.401, -367.687],
                                  [-171.985, -187.713],
                                  [-248.436, 15.162],
                                  [-271.627, 153.222],
                                  [-58.904, 284.793],
                                  [121.139, 410.885],
                                  [299.719, 278.446],
                                  [79.78, -81.109],
                                  [280.426, -229.52],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.577, -38.846],
                                  [-20.133, -118.037],
                                  [48.819, -47.016],
                                  [-118.064, -36.096],
                                  [-17.178, -62.792],
                                  [-127.127, 8.906],
                                  [117.076, 77.382],
                                  [-38.655, 133.146],
                                  [-10.96, 92],
                                ],
                                o: [
                                  [-95.067, 41.148],
                                  [20.133, 118.037],
                                  [-48.819, 47.016],
                                  [118.064, 36.096],
                                  [19.315, 65.423],
                                  [110.356, -5.288],
                                  [-117.076, -77.382],
                                  [35.531, -135.941],
                                  [10.96, -92],
                                ],
                                v: [
                                  [-88.094, -368.142],
                                  [-172.387, -188.42],
                                  [-248.227, 15.017],
                                  [-272.109, 152.846],
                                  [-58.413, 285.133],
                                  [121.382, 410.59],
                                  [299.954, 278.33],
                                  [80.246, -81.621],
                                  [279.419, -229.171],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.679, -38.643],
                                  [-19.888, -117.759],
                                  [48.646, -47.187],
                                  [-117.755, -36.129],
                                  [-17.165, -62.65],
                                  [-127.361, 9.013],
                                  [116.659, 77.28],
                                  [-38.479, 132.792],
                                  [-11.005, 92],
                                ],
                                o: [
                                  [-94.991, 40.98],
                                  [19.888, 117.759],
                                  [-48.646, 47.187],
                                  [117.755, 36.129],
                                  [19.336, 65.321],
                                  [110.33, -5.339],
                                  [-116.659, -77.28],
                                  [35.307, -135.63],
                                  [11.005, -92],
                                ],
                                v: [
                                  [-87.783, -368.592],
                                  [-172.774, -189.084],
                                  [-248.083, 14.854],
                                  [-272.542, 152.485],
                                  [-57.934, 285.511],
                                  [121.682, 410.246],
                                  [300.247, 278.258],
                                  [80.711, -82.112],
                                  [278.37, -228.865],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.782, -38.436],
                                  [-19.639, -117.477],
                                  [48.471, -47.359],
                                  [-117.443, -36.162],
                                  [-17.152, -62.505],
                                  [-127.598, 9.121],
                                  [116.237, 77.176],
                                  [-38.301, 132.433],
                                  [-11.052, 92],
                                ],
                                o: [
                                  [-94.914, 40.81],
                                  [19.639, 117.477],
                                  [-48.471, 47.359],
                                  [117.443, 36.162],
                                  [19.356, 65.218],
                                  [110.305, -5.391],
                                  [-116.237, -77.176],
                                  [35.08, -135.316],
                                  [11.052, -92],
                                ],
                                v: [
                                  [-87.48, -369.087],
                                  [-173.122, -189.748],
                                  [-247.93, 14.694],
                                  [-272.969, 152.078],
                                  [-57.467, 285.822],
                                  [121.925, 409.894],
                                  [300.543, 278.135],
                                  [81.132, -82.654],
                                  [277.324, -228.549],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.886, -38.228],
                                  [-19.387, -117.192],
                                  [48.293, -47.534],
                                  [-117.126, -36.195],
                                  [-17.139, -62.359],
                                  [-127.838, 9.23],
                                  [115.81, 77.071],
                                  [-38.121, 132.07],
                                  [-11.099, 92],
                                ],
                                o: [
                                  [-94.835, 40.638],
                                  [19.387, 117.192],
                                  [-48.293, 47.534],
                                  [117.126, 36.195],
                                  [19.377, 65.114],
                                  [110.278, -5.443],
                                  [-115.81, -77.071],
                                  [34.85, -134.997],
                                  [11.099, -92],
                                ],
                                v: [
                                  [-87.235, -369.606],
                                  [-173.517, -190.413],
                                  [-247.767, 14.566],
                                  [-273.348, 151.727],
                                  [-57.014, 286.181],
                                  [122.173, 409.525],
                                  [300.896, 278.015],
                                  [81.573, -83.173],
                                  [276.331, -228.224],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.992, -38.016],
                                  [-19.132, -116.903],
                                  [48.114, -47.712],
                                  [-116.806, -36.229],
                                  [-17.126, -62.211],
                                  [-128.082, 9.342],
                                  [115.377, 76.964],
                                  [-37.939, 131.703],
                                  [-11.146, 92],
                                ],
                                o: [
                                  [-94.756, 40.463],
                                  [19.132, 116.903],
                                  [-48.114, 47.712],
                                  [116.805, 36.229],
                                  [19.398, 65.008],
                                  [110.252, -5.496],
                                  [-115.376, -76.964],
                                  [34.618, -134.674],
                                  [11.146, -92],
                                ],
                                v: [
                                  [-86.997, -370.118],
                                  [-173.852, -191.087],
                                  [-247.668, 14.441],
                                  [-273.72, 151.341],
                                  [-56.533, 286.524],
                                  [122.365, 409.17],
                                  [301.191, 277.857],
                                  [82.012, -83.68],
                                  [275.287, -227.838],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.099, -37.802],
                                  [-18.873, -116.61],
                                  [47.931, -47.891],
                                  [-116.481, -36.263],
                                  [-17.113, -62.061],
                                  [-128.328, 9.454],
                                  [114.938, 76.856],
                                  [-37.754, 131.331],
                                  [-11.195, 92],
                                ],
                                o: [
                                  [-94.676, 40.286],
                                  [18.873, 116.61],
                                  [-47.931, 47.891],
                                  [116.481, 36.263],
                                  [19.42, 64.901],
                                  [110.225, -5.55],
                                  [-114.938, -76.856],
                                  [34.382, -134.348],
                                  [11.195, -92],
                                ],
                                v: [
                                  [-86.767, -370.61],
                                  [-174.141, -191.751],
                                  [-247.559, 14.306],
                                  [-274.035, 150.961],
                                  [-56.054, 286.862],
                                  [122.552, 408.745],
                                  [301.553, 277.742],
                                  [82.358, -84.174],
                                  [274.297, -227.495],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.208, -37.585],
                                  [-18.612, -116.314],
                                  [47.747, -48.073],
                                  [-116.152, -36.297],
                                  [-17.099, -61.909],
                                  [-128.578, 9.568],
                                  [114.494, 76.747],
                                  [-37.567, 130.953],
                                  [-11.243, 92],
                                ],
                                o: [
                                  [-94.594, 40.107],
                                  [18.612, 116.314],
                                  [-47.747, 48.073],
                                  [116.152, 36.297],
                                  [19.442, 64.792],
                                  [110.198, -5.604],
                                  [-114.494, -76.747],
                                  [34.143, -134.017],
                                  [11.243, -92],
                                ],
                                v: [
                                  [-86.586, -371.083],
                                  [-174.496, -192.375],
                                  [-247.451, 14.163],
                                  [-274.355, 150.547],
                                  [-55.652, 287.183],
                                  [122.746, 408.324],
                                  [301.97, 277.629],
                                  [82.765, -84.656],
                                  [273.296, -227.09],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.318, -37.365],
                                  [-18.346, -116.014],
                                  [47.56, -48.257],
                                  [-115.819, -36.332],
                                  [-17.085, -61.755],
                                  [-128.83, 9.683],
                                  [114.044, 76.637],
                                  [-37.377, 130.572],
                                  [-11.293, 92],
                                ],
                                o: [
                                  [-94.512, 39.926],
                                  [18.346, 116.014],
                                  [-47.56, 48.257],
                                  [115.819, 36.332],
                                  [19.464, 64.682],
                                  [110.171, -5.659],
                                  [-114.044, -76.637],
                                  [33.901, -133.682],
                                  [11.293, -92],
                                ],
                                v: [
                                  [-86.413, -371.587],
                                  [-174.794, -193.05],
                                  [-247.395, 14.062],
                                  [-274.618, 150.18],
                                  [-55.202, 287.538],
                                  [122.884, 407.896],
                                  [302.329, 277.518],
                                  [83.079, -85.177],
                                  [272.258, -226.676],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.429, -37.142],
                                  [-18.078, -115.71],
                                  [47.371, -48.443],
                                  [-115.481, -36.368],
                                  [-17.071, -61.6],
                                  [-129.086, 9.8],
                                  [113.588, 76.525],
                                  [-37.185, 130.185],
                                  [-11.343, 92],
                                ],
                                o: [
                                  [-94.428, 39.742],
                                  [18.078, 115.71],
                                  [-47.371, 48.443],
                                  [115.481, 36.368],
                                  [19.486, 64.571],
                                  [110.143, -5.714],
                                  [-113.588, -76.525],
                                  [33.657, -133.342],
                                  [11.343, -92],
                                ],
                                v: [
                                  [-86.236, -372.081],
                                  [-175.033, -193.685],
                                  [-247.339, 13.9],
                                  [-274.875, 149.842],
                                  [-54.777, 287.836],
                                  [123.069, 407.411],
                                  [302.743, 277.359],
                                  [83.443, -85.621],
                                  [271.272, -226.252],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.542, -36.917],
                                  [-17.806, -115.402],
                                  [47.179, -48.632],
                                  [-115.14, -36.404],
                                  [-17.057, -61.442],
                                  [-129.345, 9.919],
                                  [113.127, 76.411],
                                  [-36.991, 129.793],
                                  [-11.394, 92],
                                ],
                                o: [
                                  [-94.344, 39.557],
                                  [17.806, 115.402],
                                  [-47.179, 48.632],
                                  [115.14, 36.404],
                                  [19.508, 64.458],
                                  [110.115, -5.771],
                                  [-113.127, -76.411],
                                  [33.409, -132.998],
                                  [11.394, -92],
                                ],
                                v: [
                                  [-86.12, -372.617],
                                  [-175.34, -194.31],
                                  [-247.272, 13.792],
                                  [-275.088, 149.449],
                                  [-54.316, 288.179],
                                  [123.21, 406.93],
                                  [303.161, 277.241],
                                  [83.728, -86.103],
                                  [270.288, -225.83],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.656, -36.689],
                                  [-17.531, -115.091],
                                  [46.985, -48.823],
                                  [-114.794, -36.44],
                                  [-17.043, -61.282],
                                  [-129.608, 10.038],
                                  [112.66, 76.297],
                                  [-36.794, 129.397],
                                  [-11.445, 92],
                                ],
                                o: [
                                  [-94.258, 39.368],
                                  [17.531, 115.091],
                                  [-46.985, 48.823],
                                  [114.794, 36.44],
                                  [19.531, 64.344],
                                  [110.086, -5.828],
                                  [-112.66, -76.297],
                                  [33.158, -132.65],
                                  [11.445, -92],
                                ],
                                v: [
                                  [-86.012, -373.068],
                                  [-175.589, -194.896],
                                  [-247.256, 13.662],
                                  [-275.245, 149.075],
                                  [-53.922, 288.452],
                                  [123.347, 406.443],
                                  [303.634, 277.088],
                                  [84.024, -86.52],
                                  [269.345, -225.398],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.771, -36.458],
                                  [-17.252, -114.775],
                                  [46.789, -49.017],
                                  [-114.444, -36.477],
                                  [-17.029, -61.12],
                                  [-129.874, 10.16],
                                  [112.187, 76.18],
                                  [-36.595, 128.995],
                                  [-11.497, 92],
                                ],
                                o: [
                                  [-94.172, 39.178],
                                  [17.252, 114.775],
                                  [-46.789, 49.017],
                                  [114.444, 36.477],
                                  [19.554, 64.229],
                                  [110.057, -5.886],
                                  [-112.187, -76.18],
                                  [32.903, -132.298],
                                  [11.497, -92],
                                ],
                                v: [
                                  [-85.952, -373.558],
                                  [-175.843, -195.496],
                                  [-247.239, 13.585],
                                  [-275.398, 148.709],
                                  [-53.542, 288.757],
                                  [123.429, 405.962],
                                  [304.097, 276.925],
                                  [84.319, -86.973],
                                  [268.364, -224.945],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.888, -36.224],
                                  [-16.97, -114.456],
                                  [46.59, -49.213],
                                  [-114.089, -36.514],
                                  [-17.014, -60.957],
                                  [-130.143, 10.283],
                                  [111.708, 76.063],
                                  [-36.393, 128.589],
                                  [-11.55, 92],
                                ],
                                o: [
                                  [-94.084, 38.985],
                                  [16.97, 114.455],
                                  [-46.59, 49.213],
                                  [114.089, 36.514],
                                  [19.578, 64.112],
                                  [110.028, -5.944],
                                  [-111.708, -76.063],
                                  [32.646, -131.941],
                                  [11.55, -92],
                                ],
                                v: [
                                  [-85.902, -374.025],
                                  [-176.039, -196.084],
                                  [-247.222, 13.448],
                                  [-275.495, 148.35],
                                  [-53.228, 289.004],
                                  [123.521, 405.474],
                                  [304.565, 276.777],
                                  [84.587, -87.413],
                                  [267.423, -224.506],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.007, -35.987],
                                  [-16.684, -114.132],
                                  [46.389, -49.411],
                                  [-113.73, -36.552],
                                  [-16.999, -60.791],
                                  [-130.415, 10.407],
                                  [111.223, 75.943],
                                  [-36.189, 128.177],
                                  [-11.603, 92],
                                ],
                                o: [
                                  [-93.995, 38.789],
                                  [16.684, 114.132],
                                  [-46.389, 49.411],
                                  [113.73, 36.552],
                                  [19.601, 63.993],
                                  [109.998, -6.003],
                                  [-111.223, -75.943],
                                  [32.385, -131.58],
                                  [11.603, -92],
                                ],
                                v: [
                                  [-85.911, -374.48],
                                  [-176.24, -196.686],
                                  [-247.255, 13.313],
                                  [-275.55, 148.063],
                                  [-52.88, 289.293],
                                  [123.608, 404.929],
                                  [305.035, 276.619],
                                  [84.805, -87.825],
                                  [266.434, -223.995],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.127, -35.747],
                                  [-16.395, -113.804],
                                  [46.185, -49.612],
                                  [-113.366, -36.59],
                                  [-16.984, -60.623],
                                  [-130.691, 10.533],
                                  [110.732, 75.823],
                                  [-35.982, 127.76],
                                  [-11.657, 92],
                                ],
                                o: [
                                  [-93.905, 38.591],
                                  [16.395, 113.804],
                                  [-46.185, 49.612],
                                  [113.366, 36.59],
                                  [19.625, 63.874],
                                  [109.968, -6.063],
                                  [-110.732, -75.823],
                                  [32.122, -131.214],
                                  [11.657, -92],
                                ],
                                v: [
                                  [-85.905, -374.91],
                                  [-176.447, -197.24],
                                  [-247.324, 13.206],
                                  [-275.602, 147.721],
                                  [-52.547, 289.512],
                                  [123.692, 404.404],
                                  [305.559, 276.464],
                                  [85.047, -88.222],
                                  [265.496, -223.474],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.248, -35.504],
                                  [-16.102, -113.473],
                                  [45.978, -49.815],
                                  [-112.998, -36.629],
                                  [-16.969, -60.453],
                                  [-130.97, 10.66],
                                  [110.235, 75.701],
                                  [-35.772, 127.338],
                                  [-11.712, 92],
                                ],
                                o: [
                                  [-93.814, 38.391],
                                  [16.102, 113.473],
                                  [-45.978, 49.815],
                                  [112.998, 36.629],
                                  [19.65, 63.752],
                                  [109.938, -6.124],
                                  [-110.235, -75.701],
                                  [31.854, -130.844],
                                  [11.712, -92],
                                ],
                                v: [
                                  [-85.909, -375.378],
                                  [-176.609, -197.808],
                                  [-247.343, 13.102],
                                  [-275.612, 147.351],
                                  [-52.28, 289.76],
                                  [123.786, 403.874],
                                  [306.073, 276.3],
                                  [85.289, -88.554],
                                  [264.61, -223.007],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.371, -35.258],
                                  [-15.805, -113.137],
                                  [45.769, -50.021],
                                  [-112.626, -36.668],
                                  [-16.954, -60.281],
                                  [-131.253, 10.789],
                                  [109.732, 75.577],
                                  [-35.56, 126.911],
                                  [-11.767, 92],
                                ],
                                o: [
                                  [-93.722, 38.188],
                                  [15.805, 113.137],
                                  [-45.769, 50.021],
                                  [112.626, 36.668],
                                  [19.674, 63.629],
                                  [109.907, -6.185],
                                  [-109.732, -75.577],
                                  [31.584, -130.469],
                                  [11.767, -92],
                                ],
                                v: [
                                  [-85.973, -375.77],
                                  [-176.764, -198.366],
                                  [-247.471, 12.951],
                                  [-275.571, 147.039],
                                  [-52.029, 289.962],
                                  [123.826, 403.338],
                                  [306.639, 276.151],
                                  [85.494, -88.907],
                                  [263.662, -222.479],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.495, -35.009],
                                  [-15.505, -112.797],
                                  [45.558, -50.23],
                                  [-112.248, -36.708],
                                  [-16.938, -60.107],
                                  [-131.539, 10.92],
                                  [109.222, 75.452],
                                  [-35.345, 126.479],
                                  [-11.823, 92],
                                ],
                                o: [
                                  [-93.628, 37.983],
                                  [15.505, 112.797],
                                  [-45.558, 50.23],
                                  [112.248, 36.708],
                                  [19.699, 63.505],
                                  [109.876, -6.248],
                                  [-109.222, -75.452],
                                  [31.31, -130.089],
                                  [11.823, -92],
                                ],
                                v: [
                                  [-86.083, -376.148],
                                  [-176.911, -198.889],
                                  [-247.586, 12.826],
                                  [-275.477, 146.75],
                                  [-51.795, 290.13],
                                  [123.877, 402.81],
                                  [307.208, 275.942],
                                  [85.676, -89.257],
                                  [262.765, -221.956],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.621, -34.757],
                                  [-15.201, -112.453],
                                  [45.344, -50.441],
                                  [-111.866, -36.748],
                                  [-16.922, -59.93],
                                  [-131.829, 11.052],
                                  [108.706, 75.325],
                                  [-35.128, 126.041],
                                  [-11.88, 92],
                                ],
                                o: [
                                  [-93.534, 37.775],
                                  [15.201, 112.453],
                                  [-45.344, 50.441],
                                  [111.867, 36.748],
                                  [19.724, 63.379],
                                  [109.845, -6.311],
                                  [-108.706, -75.325],
                                  [31.033, -129.705],
                                  [11.88, -92],
                                ],
                                v: [
                                  [-86.142, -376.549],
                                  [-177.063, -199.379],
                                  [-247.684, 12.704],
                                  [-275.38, 146.457],
                                  [-51.626, 290.301],
                                  [123.926, 402.241],
                                  [307.765, 275.737],
                                  [85.858, -89.577],
                                  [261.868, -221.36],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.749, -34.502],
                                  [-14.893, -112.105],
                                  [45.127, -50.654],
                                  [-111.48, -36.789],
                                  [-16.906, -59.752],
                                  [-132.122, 11.186],
                                  [108.184, 75.196],
                                  [-34.908, 125.598],
                                  [-11.937, 92],
                                ],
                                o: [
                                  [-93.438, 37.564],
                                  [14.893, 112.105],
                                  [-45.127, 50.654],
                                  [111.48, 36.789],
                                  [19.75, 63.251],
                                  [109.813, -6.375],
                                  [-108.184, -75.196],
                                  [30.752, -129.316],
                                  [11.937, -92],
                                ],
                                v: [
                                  [-86.309, -376.886],
                                  [-177.171, -199.908],
                                  [-247.843, 12.524],
                                  [-275.246, 146.174],
                                  [-51.473, 290.451],
                                  [123.971, 401.716],
                                  [308.373, 275.569],
                                  [86.054, -89.819],
                                  [261.022, -220.818],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.878, -34.244],
                                  [-14.582, -111.752],
                                  [44.907, -50.871],
                                  [-111.088, -36.83],
                                  [-16.89, -59.571],
                                  [-132.419, 11.322],
                                  [107.656, 75.066],
                                  [-34.685, 125.149],
                                  [-11.995, 92],
                                ],
                                o: [
                                  [-93.341, 37.351],
                                  [14.582, 111.752],
                                  [-44.907, 50.871],
                                  [111.088, 36.83],
                                  [19.776, 63.122],
                                  [109.781, -6.439],
                                  [-107.656, -75.067],
                                  [30.468, -128.922],
                                  [11.995, -92],
                                ],
                                v: [
                                  [-86.459, -377.195],
                                  [-177.223, -200.405],
                                  [-247.999, 12.347],
                                  [-275.062, 145.914],
                                  [-51.337, 290.59],
                                  [124.028, 401.152],
                                  [308.983, 275.37],
                                  [86.214, -90.106],
                                  [260.163, -220.279],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.009, -33.983],
                                  [-14.266, -111.395],
                                  [44.685, -51.09],
                                  [-110.692, -36.872],
                                  [-16.874, -59.388],
                                  [-132.72, 11.459],
                                  [107.12, 74.935],
                                  [-34.46, 124.695],
                                  [-12.054, 92],
                                ],
                                o: [
                                  [-93.243, 37.136],
                                  [14.266, 111.395],
                                  [-44.685, 51.09],
                                  [110.692, 36.872],
                                  [19.802, 62.991],
                                  [109.748, -6.504],
                                  [-107.12, -74.935],
                                  [30.181, -128.523],
                                  [12.054, -92],
                                ],
                                v: [
                                  [-86.635, -377.49],
                                  [-177.329, -200.871],
                                  [-248.187, 12.209],
                                  [-274.828, 145.65],
                                  [-51.281, 290.72],
                                  [124.035, 400.598],
                                  [309.581, 275.111],
                                  [86.355, -90.314],
                                  [259.303, -219.683],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.141, -33.718],
                                  [-13.947, -111.034],
                                  [44.46, -51.311],
                                  [-110.291, -36.914],
                                  [-16.857, -59.203],
                                  [-133.024, 11.598],
                                  [106.579, 74.802],
                                  [-34.232, 124.235],
                                  [-12.113, 92],
                                ],
                                o: [
                                  [-93.144, 36.917],
                                  [13.947, 111.034],
                                  [-44.46, 51.311],
                                  [110.291, 36.914],
                                  [19.828, 62.859],
                                  [109.715, -6.571],
                                  [-106.579, -74.802],
                                  [29.89, -128.12],
                                  [12.114, -92],
                                ],
                                v: [
                                  [-86.841, -377.754],
                                  [-177.377, -201.327],
                                  [-248.42, 12.012],
                                  [-274.607, 145.444],
                                  [-51.227, 290.826],
                                  [124.053, 400.054],
                                  [310.228, 274.903],
                                  [86.545, -90.504],
                                  [258.493, -219.078],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.275, -33.45],
                                  [-13.624, -110.668],
                                  [44.233, -51.536],
                                  [-109.885, -36.956],
                                  [-16.841, -59.015],
                                  [-133.332, 11.739],
                                  [106.031, 74.667],
                                  [-34.001, 123.769],
                                  [-12.174, 92],
                                ],
                                o: [
                                  [-93.044, 36.696],
                                  [13.624, 110.668],
                                  [-44.233, 51.536],
                                  [109.885, 36.956],
                                  [19.855, 62.725],
                                  [109.681, -6.638],
                                  [-106.031, -74.667],
                                  [29.595, -127.711],
                                  [12.174, -92],
                                ],
                                v: [
                                  [-87.044, -378.003],
                                  [-177.384, -201.802],
                                  [-248.664, 11.82],
                                  [-274.323, 145.215],
                                  [-51.238, 290.873],
                                  [124.118, 399.507],
                                  [310.861, 274.684],
                                  [86.654, -90.662],
                                  [257.668, -218.464],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.41, -33.179],
                                  [-13.297, -110.298],
                                  [44.002, -51.763],
                                  [-109.474, -37],
                                  [-16.824, -58.825],
                                  [-133.644, 11.881],
                                  [105.476, 74.531],
                                  [-33.767, 123.298],
                                  [-12.235, 92],
                                ],
                                o: [
                                  [-92.942, 36.473],
                                  [13.297, 110.298],
                                  [-44.002, 51.763],
                                  [109.474, 37],
                                  [19.882, 62.589],
                                  [109.647, -6.705],
                                  [-105.476, -74.531],
                                  [29.297, -127.298],
                                  [12.235, -92],
                                ],
                                v: [
                                  [-87.306, -378.236],
                                  [-177.429, -202.233],
                                  [-248.937, 11.571],
                                  [-274.006, 144.981],
                                  [-51.314, 290.91],
                                  [124.133, 398.938],
                                  [311.542, 274.435],
                                  [86.841, -90.802],
                                  [256.843, -217.855],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.548, -32.905],
                                  [-12.966, -109.923],
                                  [43.769, -51.992],
                                  [-109.058, -37.043],
                                  [-16.807, -58.633],
                                  [-133.96, 12.025],
                                  [104.914, 74.393],
                                  [-33.53, 122.822],
                                  [-12.297, 92],
                                ],
                                o: [
                                  [-92.839, 36.246],
                                  [12.966, 109.923],
                                  [-43.769, 51.992],
                                  [109.058, 37.043],
                                  [19.91, 62.452],
                                  [109.613, -6.774],
                                  [-104.914, -74.393],
                                  [28.995, -126.879],
                                  [12.297, -92],
                                ],
                                v: [
                                  [-87.55, -378.39],
                                  [-177.433, -202.684],
                                  [-249.205, 11.327],
                                  [-273.656, 144.806],
                                  [-51.406, 290.936],
                                  [124.162, 398.414],
                                  [312.209, 274.159],
                                  [86.981, -90.923],
                                  [256.018, -217.237],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.687, -32.627],
                                  [-12.631, -109.544],
                                  [43.533, -52.225],
                                  [-108.638, -37.088],
                                  [-16.789, -58.439],
                                  [-134.279, 12.171],
                                  [104.346, 74.253],
                                  [-33.291, 122.339],
                                  [-12.359, 92],
                                ],
                                o: [
                                  [-92.735, 36.017],
                                  [12.631, 109.544],
                                  [-43.533, 52.225],
                                  [108.638, 37.088],
                                  [19.937, 62.313],
                                  [109.578, -6.843],
                                  [-104.346, -74.253],
                                  [28.69, -126.456],
                                  [12.359, -92],
                                ],
                                v: [
                                  [-87.853, -378.527],
                                  [-177.427, -203.078],
                                  [-249.517, 11.074],
                                  [-273.246, 144.593],
                                  [-51.577, 290.937],
                                  [124.189, 397.841],
                                  [312.875, 273.901],
                                  [87.106, -91.011],
                                  [255.177, -216.626],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.827, -32.346],
                                  [-12.292, -109.16],
                                  [43.294, -52.46],
                                  [-108.212, -37.132],
                                  [-16.772, -58.242],
                                  [-134.602, 12.318],
                                  [103.77, 74.111],
                                  [-33.048, 121.851],
                                  [-12.422, 92],
                                ],
                                o: [
                                  [-92.63, 35.785],
                                  [12.292, 109.16],
                                  [-43.294, 52.46],
                                  [108.212, 37.132],
                                  [19.965, 62.173],
                                  [109.543, -6.914],
                                  [-103.77, -74.112],
                                  [28.38, -126.027],
                                  [12.422, -92],
                                ],
                                v: [
                                  [-88.151, -378.601],
                                  [-177.379, -203.494],
                                  [-249.855, 10.812],
                                  [-272.805, 144.439],
                                  [-51.798, 290.895],
                                  [124.215, 397.343],
                                  [313.54, 273.584],
                                  [87.232, -91.031],
                                  [254.321, -216.006],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.969, -32.061],
                                  [-11.949, -108.771],
                                  [43.052, -52.699],
                                  [-107.78, -37.178],
                                  [-16.754, -58.043],
                                  [-134.93, 12.468],
                                  [103.188, 73.968],
                                  [-32.803, 121.356],
                                  [-12.486, 92],
                                ],
                                o: [
                                  [-92.523, 35.551],
                                  [11.949, 108.771],
                                  [-43.052, 52.699],
                                  [107.78, 37.178],
                                  [19.994, 62.031],
                                  [109.508, -6.985],
                                  [-103.188, -73.968],
                                  [28.067, -125.593],
                                  [12.486, -92],
                                ],
                                v: [
                                  [-88.477, -378.689],
                                  [-177.322, -203.883],
                                  [-250.202, 10.51],
                                  [-272.366, 144.279],
                                  [-52.081, 290.827],
                                  [124.209, 396.812],
                                  [314.142, 273.223],
                                  [87.39, -91.018],
                                  [253.526, -215.377],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.113, -31.773],
                                  [-11.602, -108.378],
                                  [42.807, -52.94],
                                  [-107.344, -37.224],
                                  [-16.736, -57.841],
                                  [-135.261, 12.619],
                                  [102.599, 73.823],
                                  [-32.555, 120.856],
                                  [-12.551, 92],
                                ],
                                o: [
                                  [-92.415, 35.313],
                                  [11.602, 108.378],
                                  [-42.807, 52.94],
                                  [107.344, 37.224],
                                  [20.023, 61.887],
                                  [109.472, -7.057],
                                  [-102.599, -73.823],
                                  [27.751, -125.154],
                                  [12.551, -92],
                                ],
                                v: [
                                  [-88.814, -378.665],
                                  [-177.255, -204.264],
                                  [-250.59, 10.168],
                                  [-271.883, 144.13],
                                  [-52.381, 290.748],
                                  [124.265, 396.281],
                                  [314.744, 272.85],
                                  [87.567, -90.984],
                                  [252.668, -214.708],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.259, -31.482],
                                  [-11.25, -107.98],
                                  [42.56, -53.184],
                                  [-106.902, -37.27],
                                  [-16.718, -57.637],
                                  [-135.596, 12.772],
                                  [102.002, 73.677],
                                  [-32.303, 120.35],
                                  [-12.617, 92],
                                ],
                                o: [
                                  [-92.306, 35.073],
                                  [11.25, 107.98],
                                  [-42.56, 53.184],
                                  [106.902, 37.27],
                                  [20.052, 61.741],
                                  [109.435, -7.13],
                                  [-102.002, -73.677],
                                  [27.43, -124.71],
                                  [12.617, -92],
                                ],
                                v: [
                                  [-89.147, -378.672],
                                  [-177.146, -204.622],
                                  [-251.003, 9.802],
                                  [-271.372, 143.992],
                                  [-52.744, 290.658],
                                  [124.32, 395.811],
                                  [315.283, 272.45],
                                  [87.714, -90.913],
                                  [251.793, -214.078],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.406, -31.187],
                                  [-10.895, -107.577],
                                  [42.309, -53.431],
                                  [-106.456, -37.317],
                                  [-16.699, -57.431],
                                  [-135.935, 12.927],
                                  [101.399, 73.529],
                                  [-32.049, 119.838],
                                  [-12.683, 92],
                                ],
                                o: [
                                  [-92.195, 34.829],
                                  [10.895, 107.578],
                                  [-42.309, 53.431],
                                  [106.456, 37.317],
                                  [20.081, 61.594],
                                  [109.398, -7.203],
                                  [-101.399, -73.529],
                                  [27.106, -124.26],
                                  [12.683, -92],
                                ],
                                v: [
                                  [-89.505, -378.567],
                                  [-177.074, -204.987],
                                  [-251.424, 9.415],
                                  [-270.803, 143.91],
                                  [-53.122, 290.556],
                                  [124.329, 395.311],
                                  [315.774, 271.976],
                                  [87.834, -90.776],
                                  [250.917, -213.409],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.556, -30.889],
                                  [-10.535, -107.17],
                                  [42.056, -53.681],
                                  [-106.003, -37.365],
                                  [-16.681, -57.222],
                                  [-136.278, 13.083],
                                  [100.788, 73.378],
                                  [-31.792, 119.319],
                                  [-12.75, 92],
                                ],
                                o: [
                                  [-92.083, 34.583],
                                  [10.535, 107.17],
                                  [-42.055, 53.681],
                                  [106.003, 37.365],
                                  [20.111, 61.444],
                                  [109.361, -7.278],
                                  [-100.788, -73.378],
                                  [26.778, -123.805],
                                  [12.75, -92],
                                ],
                                v: [
                                  [-89.875, -378.446],
                                  [-176.944, -205.376],
                                  [-251.885, 8.975],
                                  [-270.208, 143.777],
                                  [-53.517, 290.443],
                                  [124.401, 394.827],
                                  [316.249, 271.523],
                                  [88.019, -90.647],
                                  [250.068, -212.749],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.707, -30.587],
                                  [-10.17, -106.758],
                                  [41.799, -53.933],
                                  [-105.546, -37.413],
                                  [-16.662, -57.011],
                                  [-136.625, 13.242],
                                  [100.17, 73.227],
                                  [-31.531, 118.795],
                                  [-12.818, 92],
                                ],
                                o: [
                                  [-91.97, 34.334],
                                  [10.17, 106.758],
                                  [-41.799, 53.934],
                                  [105.546, 37.413],
                                  [20.141, 61.293],
                                  [109.323, -7.353],
                                  [-100.17, -73.227],
                                  [26.446, -123.345],
                                  [12.818, -92],
                                ],
                                v: [
                                  [-90.268, -378.291],
                                  [-176.773, -205.665],
                                  [-252.32, 8.482],
                                  [-269.619, 143.683],
                                  [-53.975, 290.318],
                                  [124.427, 394.361],
                                  [316.707, 271.011],
                                  [88.194, -90.405],
                                  [249.173, -212.065],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.859, -30.281],
                                  [-9.802, -106.341],
                                  [41.539, -54.189],
                                  [-105.083, -37.461],
                                  [-16.643, -56.797],
                                  [-136.976, 13.402],
                                  [99.545, 73.073],
                                  [-31.268, 118.264],
                                  [-12.887, 92],
                                ],
                                o: [
                                  [-91.856, 34.082],
                                  [9.802, 106.341],
                                  [-41.539, 54.189],
                                  [105.083, 37.461],
                                  [20.172, 61.141],
                                  [109.285, -7.43],
                                  [-99.545, -73.073],
                                  [26.11, -122.879],
                                  [12.887, -92],
                                ],
                                v: [
                                  [-90.611, -378.071],
                                  [-176.637, -206.026],
                                  [-252.794, 8.001],
                                  [-268.989, 143.616],
                                  [-54.449, 290.18],
                                  [124.499, 393.913],
                                  [317.089, 270.442],
                                  [88.39, -90.17],
                                  [248.32, -211.39],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.014, -29.972],
                                  [-9.429, -105.918],
                                  [41.276, -54.448],
                                  [-104.614, -37.511],
                                  [-16.623, -56.581],
                                  [-137.332, 13.565],
                                  [98.912, 72.917],
                                  [-31.001, 117.727],
                                  [-12.956, 92],
                                ],
                                o: [
                                  [-91.74, 33.827],
                                  [9.429, 105.918],
                                  [-41.276, 54.448],
                                  [104.614, 37.511],
                                  [20.203, 60.986],
                                  [109.247, -7.507],
                                  [-98.912, -72.917],
                                  [25.77, -122.408],
                                  [12.956, -92],
                                ],
                                v: [
                                  [-90.947, -377.834],
                                  [-176.46, -206.351],
                                  [-253.275, 7.468],
                                  [-268.336, 143.57],
                                  [-54.984, 290.031],
                                  [124.544, 393.485],
                                  [317.482, 269.877],
                                  [88.605, -89.85],
                                  [247.404, -210.68],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.17, -29.659],
                                  [-9.052, -105.491],
                                  [41.011, -54.71],
                                  [-104.14, -37.561],
                                  [-16.604, -56.362],
                                  [-137.692, 13.729],
                                  [98.272, 72.76],
                                  [-30.731, 117.183],
                                  [-13.027, 92],
                                ],
                                o: [
                                  [-91.622, 33.569],
                                  [9.052, 105.491],
                                  [-41.011, 54.71],
                                  [104.14, 37.56],
                                  [20.234, 60.83],
                                  [109.207, -7.585],
                                  [-98.272, -72.76],
                                  [25.425, -121.931],
                                  [13.027, -92],
                                ],
                                v: [
                                  [-91.233, -377.551],
                                  [-176.271, -206.658],
                                  [-253.776, 6.903],
                                  [-267.628, 143.489],
                                  [-55.518, 289.869],
                                  [124.652, 393.058],
                                  [317.801, 269.318],
                                  [88.842, -89.507],
                                  [246.512, -210.008],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.328, -29.343],
                                  [-8.67, -105.059],
                                  [40.742, -54.975],
                                  [-103.66, -37.611],
                                  [-16.584, -56.14],
                                  [-138.056, 13.895],
                                  [97.624, 72.601],
                                  [-30.458, 116.633],
                                  [-13.098, 92],
                                ],
                                o: [
                                  [-91.504, 33.308],
                                  [8.67, 105.059],
                                  [-40.742, 54.975],
                                  [103.66, 37.611],
                                  [20.266, 60.672],
                                  [109.168, -7.664],
                                  [-97.624, -72.601],
                                  [25.077, -121.448],
                                  [13.098, -92],
                                ],
                                v: [
                                  [-91.541, -377.215],
                                  [-176.024, -206.958],
                                  [-254.239, 6.288],
                                  [-266.945, 143.444],
                                  [-56.086, 289.65],
                                  [124.744, 392.67],
                                  [318.085, 268.718],
                                  [89.117, -89.123],
                                  [245.618, -209.301],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.489, -29.023],
                                  [-8.284, -104.622],
                                  [40.469, -55.243],
                                  [-103.175, -37.662],
                                  [-16.564, -55.916],
                                  [-138.424, 14.063],
                                  [96.969, 72.44],
                                  [-30.182, 116.077],
                                  [-13.17, 92],
                                ],
                                o: [
                                  [-91.384, 33.044],
                                  [8.284, 104.622],
                                  [-40.469, 55.243],
                                  [103.175, 37.662],
                                  [20.298, 60.511],
                                  [109.128, -7.744],
                                  [-96.969, -72.44],
                                  [24.725, -120.96],
                                  [13.17, -92],
                                ],
                                v: [
                                  [-91.754, -376.833],
                                  [-175.827, -207.243],
                                  [-254.721, 5.644],
                                  [-266.225, 143.408],
                                  [-56.653, 289.463],
                                  [124.872, 392.285],
                                  [318.323, 268.061],
                                  [89.369, -88.697],
                                  [244.703, -208.589],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.651, -28.699],
                                  [-7.893, -104.179],
                                  [40.194, -55.515],
                                  [-102.684, -37.714],
                                  [-16.544, -55.689],
                                  [-138.796, 14.233],
                                  [96.305, 72.277],
                                  [-29.903, 115.514],
                                  [-13.243, 92],
                                ],
                                o: [
                                  [-91.262, 32.777],
                                  [7.893, 104.179],
                                  [-40.194, 55.515],
                                  [102.684, 37.714],
                                  [20.33, 60.349],
                                  [109.087, -7.825],
                                  [-96.305, -72.277],
                                  [24.369, -120.466],
                                  [13.243, -92],
                                ],
                                v: [
                                  [-91.962, -376.478],
                                  [-175.554, -207.538],
                                  [-255.236, 5.015],
                                  [-265.45, 143.408],
                                  [-57.299, 289.264],
                                  [124.984, 391.923],
                                  [318.561, 267.393],
                                  [89.688, -88.22],
                                  [243.784, -207.845],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.814, -28.371],
                                  [-7.498, -103.732],
                                  [39.915, -55.789],
                                  [-102.187, -37.766],
                                  [-16.523, -55.46],
                                  [-139.173, 14.405],
                                  [95.634, 72.112],
                                  [-29.62, 114.945],
                                  [-13.316, 92],
                                ],
                                o: [
                                  [-91.139, 32.506],
                                  [7.498, 103.732],
                                  [-39.915, 55.789],
                                  [102.187, 37.766],
                                  [20.363, 60.185],
                                  [109.046, -7.907],
                                  [-95.634, -72.112],
                                  [24.008, -119.966],
                                  [13.317, -92],
                                ],
                                v: [
                                  [-92.165, -376.016],
                                  [-175.304, -207.846],
                                  [-255.759, 4.339],
                                  [-264.659, 143.335],
                                  [-57.986, 289.025],
                                  [125.134, 391.564],
                                  [318.736, 266.703],
                                  [90.048, -87.682],
                                  [242.843, -207.095],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.98, -28.039],
                                  [-7.098, -103.279],
                                  [39.634, -56.067],
                                  [-101.685, -37.819],
                                  [-16.502, -55.228],
                                  [-139.555, 14.579],
                                  [94.956, 71.945],
                                  [-29.334, 114.369],
                                  [-13.391, 92],
                                ],
                                o: [
                                  [-91.015, 32.233],
                                  [7.098, 103.279],
                                  [-39.634, 56.067],
                                  [101.685, 37.819],
                                  [20.396, 60.02],
                                  [109.005, -7.99],
                                  [-94.956, -71.945],
                                  [23.643, -119.46],
                                  [13.391, -92],
                                ],
                                v: [
                                  [-92.299, -375.536],
                                  [-175.02, -208.122],
                                  [-256.209, 3.619],
                                  [-263.877, 143.376],
                                  [-58.665, 288.756],
                                  [125.268, 391.192],
                                  [318.911, 266.002],
                                  [90.431, -87.137],
                                  [241.942, -206.359],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.148, -27.704],
                                  [-6.693, -102.821],
                                  [39.348, -56.348],
                                  [-101.176, -37.872],
                                  [-16.482, -54.993],
                                  [-139.94, 14.755],
                                  [94.269, 71.776],
                                  [-29.045, 113.786],
                                  [-13.467, 92],
                                ],
                                o: [
                                  [-90.889, 31.956],
                                  [6.693, 102.821],
                                  [-39.348, 56.348],
                                  [101.176, 37.872],
                                  [20.43, 59.852],
                                  [108.963, -8.074],
                                  [-94.269, -71.776],
                                  [23.274, -118.949],
                                  [13.467, -92],
                                ],
                                v: [
                                  [-92.429, -375.038],
                                  [-174.698, -208.368],
                                  [-256.736, 2.916],
                                  [-263.08, 143.343],
                                  [-59.385, 288.536],
                                  [125.467, 390.842],
                                  [319.022, 265.287],
                                  [90.838, -86.505],
                                  [240.974, -205.618],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.318, -27.365],
                                  [-6.284, -102.357],
                                  [39.06, -56.632],
                                  [-100.662, -37.926],
                                  [-16.46, -54.755],
                                  [-140.331, 14.934],
                                  [93.574, 71.605],
                                  [-28.752, 113.196],
                                  [-13.543, 92],
                                ],
                                o: [
                                  [-90.762, 31.676],
                                  [6.284, 102.357],
                                  [-39.06, 56.632],
                                  [100.662, 37.926],
                                  [20.464, 59.682],
                                  [108.921, -8.159],
                                  [-93.574, -71.605],
                                  [22.901, -118.431],
                                  [13.543, -92],
                                ],
                                v: [
                                  [-92.534, -374.521],
                                  [-174.403, -208.689],
                                  [-257.227, 2.188],
                                  [-262.276, 143.36],
                                  [-60.164, 288.259],
                                  [125.667, 390.497],
                                  [319.09, 264.535],
                                  [91.287, -85.846],
                                  [240.044, -204.873],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.489, -27.021],
                                  [-5.869, -101.888],
                                  [38.768, -56.92],
                                  [-100.142, -37.981],
                                  [-16.439, -54.515],
                                  [-140.726, 15.114],
                                  [92.872, 71.433],
                                  [-28.456, 112.599],
                                  [-13.62, 92],
                                ],
                                o: [
                                  [-90.633, 31.393],
                                  [5.869, 101.888],
                                  [-38.768, 56.92],
                                  [100.142, 37.981],
                                  [20.498, 59.511],
                                  [108.878, -8.245],
                                  [-92.872, -71.433],
                                  [22.523, -117.907],
                                  [13.62, -92],
                                ],
                                v: [
                                  [-92.591, -373.9],
                                  [-174.112, -208.962],
                                  [-257.687, 1.462],
                                  [-261.501, 143.427],
                                  [-60.916, 287.97],
                                  [125.87, 390.157],
                                  [319.095, 263.746],
                                  [91.741, -85.142],
                                  [239.09, -204.118],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.663, -26.674],
                                  [-5.45, -101.414],
                                  [38.473, -57.211],
                                  [-99.615, -38.036],
                                  [-16.417, -54.272],
                                  [-141.125, 15.296],
                                  [92.161, 71.258],
                                  [-28.156, 111.996],
                                  [-13.698, 92],
                                ],
                                o: [
                                  [-90.503, 31.106],
                                  [5.45, 101.414],
                                  [-38.473, 57.211],
                                  [99.615, 38.036],
                                  [20.533, 59.337],
                                  [108.834, -8.331],
                                  [-92.161, -71.258],
                                  [22.141, -117.378],
                                  [13.698, -92],
                                ],
                                v: [
                                  [-92.599, -373.303],
                                  [-173.801, -209.206],
                                  [-258.155, 0.693],
                                  [-260.676, 143.481],
                                  [-61.727, 287.668],
                                  [126.094, 389.76],
                                  [319.1, 263.006],
                                  [92.24, -84.388],
                                  [238.13, -203.299],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.839, -26.323],
                                  [-5.027, -100.934],
                                  [38.174, -57.505],
                                  [-99.083, -38.092],
                                  [-16.395, -54.026],
                                  [-141.529, 15.481],
                                  [91.442, 71.081],
                                  [-27.853, 111.386],
                                  [-13.777, 92],
                                ],
                                o: [
                                  [-90.371, 30.816],
                                  [5.027, 100.934],
                                  [-38.174, 57.505],
                                  [99.083, 38.092],
                                  [20.568, 59.161],
                                  [108.79, -8.419],
                                  [-91.442, -71.081],
                                  [21.755, -116.842],
                                  [13.777, -92],
                                ],
                                v: [
                                  [-92.583, -372.665],
                                  [-173.517, -209.487],
                                  [-258.634, -0.097],
                                  [-259.84, 143.52],
                                  [-62.535, 287.33],
                                  [126.363, 389.43],
                                  [319.085, 262.211],
                                  [92.763, -83.588],
                                  [237.187, -202.538],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.016, -25.967],
                                  [-4.598, -100.449],
                                  [37.872, -57.802],
                                  [-98.544, -38.149],
                                  [-16.373, -53.777],
                                  [-141.938, 15.667],
                                  [90.714, 70.902],
                                  [-27.547, 110.768],
                                  [-13.857, 92],
                                ],
                                o: [
                                  [-90.238, 30.523],
                                  [4.598, 100.449],
                                  [-37.872, 57.802],
                                  [98.544, 38.149],
                                  [20.603, 58.984],
                                  [108.746, -8.508],
                                  [-90.714, -70.902],
                                  [21.364, -116.3],
                                  [13.857, -92],
                                ],
                                v: [
                                  [-92.54, -371.946],
                                  [-173.194, -209.76],
                                  [-259.059, -0.864],
                                  [-259.06, 143.607],
                                  [-63.421, 287.001],
                                  [126.592, 389.085],
                                  [318.985, 261.38],
                                  [93.375, -82.741],
                                  [236.214, -201.753],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.196, -25.608],
                                  [-4.164, -99.958],
                                  [37.567, -58.103],
                                  [-97.999, -38.206],
                                  [-16.35, -53.525],
                                  [-142.351, 15.856],
                                  [89.978, 70.721],
                                  [-27.237, 110.144],
                                  [-13.938, 92],
                                ],
                                o: [
                                  [-90.103, 30.226],
                                  [4.164, 99.958],
                                  [-37.567, 58.103],
                                  [97.999, 38.206],
                                  [20.639, 58.804],
                                  [108.701, -8.598],
                                  [-89.978, -70.721],
                                  [20.969, -115.752],
                                  [13.938, -92],
                                ],
                                v: [
                                  [-92.45, -371.23],
                                  [-172.938, -210.027],
                                  [-259.533, -1.67],
                                  [-258.229, 143.677],
                                  [-64.26, 286.7],
                                  [126.866, 388.765],
                                  [318.886, 260.577],
                                  [93.95, -81.844],
                                  [235.258, -200.987],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.378, -25.244],
                                  [-3.726, -99.462],
                                  [37.258, -58.408],
                                  [-97.448, -38.264],
                                  [-16.328, -53.271],
                                  [-142.769, 16.047],
                                  [89.234, 70.539],
                                  [-26.923, 109.512],
                                  [-14.02, 92],
                                ],
                                o: [
                                  [-89.967, 29.926],
                                  [3.726, 99.462],
                                  [-37.258, 58.408],
                                  [97.448, 38.264],
                                  [20.676, 58.622],
                                  [108.656, -8.689],
                                  [-89.234, -70.539],
                                  [20.569, -115.197],
                                  [14.02, -92],
                                ],
                                v: [
                                  [-92.355, -370.497],
                                  [-172.686, -210.288],
                                  [-259.953, -2.451],
                                  [-257.434, 143.752],
                                  [-65.178, 286.324],
                                  [127.184, 388.388],
                                  [318.765, 259.76],
                                  [94.614, -80.92],
                                  [234.294, -200.157],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.562, -24.876],
                                  [-3.282, -98.959],
                                  [36.945, -58.716],
                                  [-96.891, -38.323],
                                  [-16.305, -53.014],
                                  [-143.192, 16.24],
                                  [88.482, 70.353],
                                  [-26.606, 108.873],
                                  [-14.103, 92],
                                ],
                                o: [
                                  [-89.829, 29.623],
                                  [3.282, 98.959],
                                  [-36.945, 58.716],
                                  [96.891, 38.323],
                                  [20.712, 58.438],
                                  [108.61, -8.781],
                                  [-88.482, -70.354],
                                  [20.164, -114.637],
                                  [14.103, -92],
                                ],
                                v: [
                                  [-92.191, -369.703],
                                  [-172.417, -210.544],
                                  [-260.34, -3.228],
                                  [-256.611, 143.873],
                                  [-66.092, 285.997],
                                  [127.484, 388.059],
                                  [318.604, 258.93],
                                  [95.304, -79.947],
                                  [233.343, -199.367],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.748, -24.505],
                                  [-2.834, -98.451],
                                  [36.629, -59.027],
                                  [-96.327, -38.382],
                                  [-16.282, -52.753],
                                  [-143.62, 16.435],
                                  [87.72, 70.166],
                                  [-26.285, 108.227],
                                  [-14.186, 92],
                                ],
                                o: [
                                  [-89.689, 29.316],
                                  [2.834, 98.451],
                                  [-36.629, 59.027],
                                  [96.327, 38.382],
                                  [20.75, 58.252],
                                  [108.563, -8.874],
                                  [-87.72, -70.166],
                                  [19.755, -114.069],
                                  [14.186, -92],
                                ],
                                v: [
                                  [-92.001, -368.893],
                                  [-172.174, -210.772],
                                  [-260.776, -4.041],
                                  [-255.824, 143.955],
                                  [-67.04, 285.616],
                                  [127.806, 387.693],
                                  [318.38, 258.086],
                                  [96.021, -78.926],
                                  [232.364, -198.578],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.936, -24.128],
                                  [-2.38, -97.938],
                                  [36.309, -59.342],
                                  [-95.757, -38.442],
                                  [-16.258, -52.49],
                                  [-144.052, 16.633],
                                  [86.95, 69.977],
                                  [-25.961, 107.573],
                                  [-14.271, 92],
                                ],
                                o: [
                                  [-89.548, 29.006],
                                  [2.38, 97.938],
                                  [-36.309, 59.343],
                                  [95.757, 38.442],
                                  [20.787, 58.064],
                                  [108.516, -8.968],
                                  [-86.95, -69.977],
                                  [19.341, -113.496],
                                  [14.271, -92],
                                ],
                                v: [
                                  [-91.763, -368.023],
                                  [-171.934, -211.037],
                                  [-261.158, -4.867],
                                  [-255.032, 144.102],
                                  [-67.964, 285.223],
                                  [128.111, 387.352],
                                  [318.116, 257.27],
                                  [96.827, -77.856],
                                  [231.397, -197.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.126, -23.748],
                                  [-1.921, -97.418],
                                  [35.986, -59.661],
                                  [-95.18, -38.503],
                                  [-16.234, -52.223],
                                  [-144.49, 16.832],
                                  [86.171, 69.786],
                                  [-25.633, 106.912],
                                  [-14.357, 92],
                                ],
                                o: [
                                  [-89.405, 28.692],
                                  [1.921, 97.418],
                                  [-35.986, 59.661],
                                  [95.18, 38.503],
                                  [20.825, 57.874],
                                  [108.468, -9.063],
                                  [-86.171, -69.786],
                                  [18.923, -112.915],
                                  [14.357, -92],
                                ],
                                v: [
                                  [-91.498, -367.118],
                                  [-171.739, -211.296],
                                  [-261.527, -5.687],
                                  [-254.214, 144.229],
                                  [-68.943, 284.816],
                                  [128.437, 386.998],
                                  [317.853, 256.422],
                                  [97.639, -76.716],
                                  [230.421, -196.919],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.318, -23.363],
                                  [-1.457, -96.893],
                                  [35.659, -59.983],
                                  [-94.597, -38.564],
                                  [-16.21, -51.954],
                                  [-144.932, 17.034],
                                  [85.384, 69.592],
                                  [-25.301, 106.243],
                                  [-14.443, 92],
                                ],
                                o: [
                                  [-89.261, 28.375],
                                  [1.457, 96.893],
                                  [-35.659, 59.983],
                                  [94.597, 38.564],
                                  [20.864, 57.682],
                                  [108.42, -9.159],
                                  [-85.384, -69.592],
                                  [18.499, -112.329],
                                  [14.443, -92],
                                ],
                                v: [
                                  [-91.187, -366.236],
                                  [-171.508, -211.57],
                                  [-261.843, -6.478],
                                  [-253.474, 144.376],
                                  [-69.938, 284.419],
                                  [128.805, 386.668],
                                  [317.59, 255.581],
                                  [98.478, -75.57],
                                  [229.455, -196.113],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.513, -22.974],
                                  [-0.987, -96.361],
                                  [35.328, -60.309],
                                  [-94.007, -38.626],
                                  [-16.186, -51.682],
                                  [-145.38, 17.239],
                                  [84.587, 69.396],
                                  [-24.965, 105.567],
                                  [-14.531, 92],
                                ],
                                o: [
                                  [-89.115, 28.053],
                                  [0.987, 96.361],
                                  [-35.328, 60.309],
                                  [94.007, 38.626],
                                  [20.903, 57.487],
                                  [108.372, -9.257],
                                  [-84.587, -69.396],
                                  [18.071, -111.735],
                                  [14.531, -92],
                                ],
                                v: [
                                  [-90.847, -365.257],
                                  [-171.322, -211.796],
                                  [-262.209, -7.26],
                                  [-252.689, 144.525],
                                  [-70.925, 284.009],
                                  [129.158, 386.303],
                                  [317.227, 254.748],
                                  [99.368, -74.334],
                                  [228.501, -195.292],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.71, -22.58],
                                  [-0.513, -95.824],
                                  [34.993, -60.639],
                                  [-93.411, -38.689],
                                  [-16.161, -51.406],
                                  [-145.833, 17.445],
                                  [83.782, 69.198],
                                  [-24.626, 104.883],
                                  [-14.619, 92],
                                ],
                                o: [
                                  [-88.968, 27.729],
                                  [0.513, 95.824],
                                  [-34.993, 60.639],
                                  [93.411, 38.689],
                                  [20.942, 57.29],
                                  [108.323, -9.355],
                                  [-83.782, -69.198],
                                  [17.638, -111.135],
                                  [14.619, -92],
                                ],
                                v: [
                                  [-90.462, -364.284],
                                  [-171.178, -212.079],
                                  [-262.523, -8.052],
                                  [-251.943, 144.674],
                                  [-71.967, 283.61],
                                  [129.529, 385.984],
                                  [316.864, 253.923],
                                  [100.325, -73.092],
                                  [227.492, -194.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.909, -22.182],
                                  [-0.033, -95.28],
                                  [34.655, -60.972],
                                  [-92.808, -38.752],
                                  [-16.136, -51.128],
                                  [-146.29, 17.654],
                                  [82.967, 68.998],
                                  [-24.282, 104.192],
                                  [-14.709, 92],
                                ],
                                o: [
                                  [-88.818, 27.4],
                                  [0.033, 95.28],
                                  [-34.655, 60.972],
                                  [92.808, 38.752],
                                  [20.982, 57.091],
                                  [108.273, -9.454],
                                  [-82.967, -68.998],
                                  [17.201, -110.528],
                                  [14.709, -92],
                                ],
                                v: [
                                  [-90.07, -363.296],
                                  [-170.999, -212.314],
                                  [-262.823, -8.873],
                                  [-251.192, 144.84],
                                  [-72.962, 283.159],
                                  [129.902, 385.63],
                                  [316.504, 253.068],
                                  [101.311, -71.799],
                                  [226.495, -193.618],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.11, -21.78],
                                  [0.453, -94.731],
                                  [34.313, -61.309],
                                  [-92.198, -38.816],
                                  [-16.111, -50.846],
                                  [-146.753, 17.866],
                                  [82.143, 68.796],
                                  [-23.935, 103.493],
                                  [-14.799, 92],
                                ],
                                o: [
                                  [-88.667, 27.068],
                                  [-0.453, 94.731],
                                  [-34.313, 61.309],
                                  [92.198, 38.816],
                                  [21.022, 56.89],
                                  [108.223, -9.555],
                                  [-82.143, -68.795],
                                  [16.758, -109.914],
                                  [14.8, -92],
                                ],
                                v: [
                                  [-89.65, -362.252],
                                  [-170.863, -212.564],
                                  [-263.073, -9.645],
                                  [-250.481, 144.982],
                                  [-74.009, 282.734],
                                  [130.277, 385.298],
                                  [316.043, 252.261],
                                  [102.364, -70.461],
                                  [225.504, -192.728],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.314, -21.373],
                                  [0.944, -94.175],
                                  [33.967, -61.65],
                                  [-91.581, -38.881],
                                  [-16.086, -50.561],
                                  [-147.221, 18.079],
                                  [81.31, 68.591],
                                  [-23.584, 102.785],
                                  [-14.891, 92],
                                ],
                                o: [
                                  [-88.515, 26.733],
                                  [-0.944, 94.175],
                                  [-33.967, 61.65],
                                  [91.581, 38.881],
                                  [21.063, 56.687],
                                  [108.172, -9.657],
                                  [-81.31, -68.591],
                                  [16.31, -109.294],
                                  [14.891, -92],
                                ],
                                v: [
                                  [-89.184, -361.193],
                                  [-170.769, -212.806],
                                  [-263.348, -10.446],
                                  [-249.804, 145.162],
                                  [-75.072, 282.283],
                                  [130.653, 384.933],
                                  [315.623, 251.463],
                                  [103.422, -69.094],
                                  [224.501, -191.905],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.519, -20.961],
                                  [1.44, -93.613],
                                  [33.617, -61.995],
                                  [-90.957, -38.947],
                                  [-16.06, -50.273],
                                  [-147.694, 18.295],
                                  [80.468, 68.384],
                                  [-23.229, 102.07],
                                  [-14.984, 92],
                                ],
                                o: [
                                  [-88.361, 26.393],
                                  [-1.44, 93.613],
                                  [-33.617, 61.995],
                                  [90.957, 38.947],
                                  [21.104, 56.481],
                                  [108.12, -9.76],
                                  [-80.468, -68.384],
                                  [15.858, -108.666],
                                  [14.984, -92],
                                ],
                                v: [
                                  [-88.65, -360.142],
                                  [-170.677, -213.064],
                                  [-263.597, -11.212],
                                  [-249.129, 145.34],
                                  [-76.124, 281.82],
                                  [131.044, 384.549],
                                  [315.129, 250.635],
                                  [104.57, -67.661],
                                  [223.525, -191.048],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.727, -20.545],
                                  [1.942, -93.045],
                                  [33.263, -62.343],
                                  [-90.326, -39.013],
                                  [-16.034, -49.982],
                                  [-148.173, 18.514],
                                  [79.616, 68.174],
                                  [-22.87, 101.347],
                                  [-15.077, 92],
                                ],
                                o: [
                                  [-88.204, 26.05],
                                  [-1.942, 93.045],
                                  [-33.263, 62.343],
                                  [90.326, 39.013],
                                  [21.145, 56.273],
                                  [108.068, -9.864],
                                  [-79.616, -68.174],
                                  [15.4, -108.032],
                                  [15.077, -92],
                                ],
                                v: [
                                  [-88.111, -358.999],
                                  [-170.588, -213.337],
                                  [-263.795, -11.967],
                                  [-248.449, 145.517],
                                  [-77.19, 281.369],
                                  [131.423, 384.208],
                                  [314.637, 249.831],
                                  [105.746, -66.161],
                                  [222.473, -190.197],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.938, -20.125],
                                  [2.45, -92.47],
                                  [32.906, -62.696],
                                  [-89.689, -39.08],
                                  [-16.008, -49.687],
                                  [-148.657, 18.735],
                                  [78.755, 67.963],
                                  [-22.507, 100.616],
                                  [-15.172, 92],
                                ],
                                o: [
                                  [-88.047, 25.703],
                                  [-2.45, 92.47],
                                  [-32.906, 62.696],
                                  [89.689, 39.08],
                                  [21.188, 56.062],
                                  [108.016, -9.969],
                                  [-78.755, -67.963],
                                  [14.937, -107.39],
                                  [15.172, -92],
                                ],
                                v: [
                                  [-87.54, -357.88],
                                  [-170.54, -213.639],
                                  [-263.982, -12.71],
                                  [-247.826, 145.704],
                                  [-78.27, 280.932],
                                  [131.815, 383.834],
                                  [314.148, 249.022],
                                  [106.989, -64.671],
                                  [221.447, -189.338],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.15, -19.699],
                                  [2.963, -91.889],
                                  [32.544, -63.052],
                                  [-89.044, -39.148],
                                  [-15.981, -49.389],
                                  [-149.146, 18.958],
                                  [77.884, 67.749],
                                  [-22.14, 99.877],
                                  [-15.268, 92],
                                ],
                                o: [
                                  [-87.887, 25.352],
                                  [-2.963, 91.889],
                                  [-32.544, 63.052],
                                  [89.044, 39.148],
                                  [21.23, 55.85],
                                  [107.962, -10.075],
                                  [-77.884, -67.749],
                                  [14.469, -106.741],
                                  [15.268, -92],
                                ],
                                v: [
                                  [-86.926, -356.734],
                                  [-170.494, -213.893],
                                  [-264.157, -13.453],
                                  [-247.183, 145.901],
                                  [-79.34, 280.46],
                                  [132.208, 383.466],
                                  [313.599, 248.223],
                                  [108.286, -63.102],
                                  [220.419, -188.45],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.366, -19.269],
                                  [3.482, -91.302],
                                  [32.179, -63.412],
                                  [-88.392, -39.217],
                                  [-15.954, -49.088],
                                  [-149.641, 19.184],
                                  [77.004, 67.532],
                                  [-21.769, 99.13],
                                  [-15.365, 92],
                                ],
                                o: [
                                  [-87.726, 24.997],
                                  [-3.482, 91.302],
                                  [-32.179, 63.412],
                                  [88.392, 39.217],
                                  [21.273, 55.634],
                                  [107.909, -10.183],
                                  [-77.003, -67.532],
                                  [13.996, -106.085],
                                  [15.365, -92],
                                ],
                                v: [
                                  [-86.243, -355.537],
                                  [-170.488, -214.161],
                                  [-264.283, -14.182],
                                  [-246.596, 146.093],
                                  [-80.485, 280.027],
                                  [132.613, 383.115],
                                  [313.042, 247.472],
                                  [109.623, -61.506],
                                  [219.402, -187.606],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.583, -18.834],
                                  [4.007, -90.708],
                                  [31.809, -63.777],
                                  [-87.733, -39.286],
                                  [-15.927, -48.784],
                                  [-150.141, 19.412],
                                  [76.113, 67.313],
                                  [-21.394, 98.374],
                                  [-15.462, 92],
                                ],
                                o: [
                                  [-87.563, 24.638],
                                  [-4.007, 90.708],
                                  [-31.809, 63.777],
                                  [87.733, 39.286],
                                  [21.317, 55.417],
                                  [107.854, -10.292],
                                  [-76.113, -67.313],
                                  [13.517, -105.422],
                                  [15.462, -92],
                                ],
                                v: [
                                  [-85.554, -354.326],
                                  [-170.519, -214.419],
                                  [-264.424, -14.923],
                                  [-246.065, 146.28],
                                  [-81.556, 279.583],
                                  [133.016, 382.732],
                                  [312.464, 246.73],
                                  [111.025, -59.882],
                                  [218.318, -186.733],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.803, -18.394],
                                  [4.537, -90.107],
                                  [31.435, -64.145],
                                  [-87.066, -39.356],
                                  [-15.9, -48.476],
                                  [-150.647, 19.643],
                                  [75.213, 67.092],
                                  [-21.015, 97.61],
                                  [-15.561, 92],
                                ],
                                o: [
                                  [-87.398, 24.275],
                                  [-4.537, 90.107],
                                  [-31.435, 64.145],
                                  [87.066, 39.356],
                                  [21.361, 55.197],
                                  [107.799, -10.401],
                                  [-75.213, -67.092],
                                  [13.034, -104.751],
                                  [15.561, -92],
                                ],
                                v: [
                                  [-84.833, -353.128],
                                  [-170.552, -214.728],
                                  [-264.491, -15.625],
                                  [-245.552, 146.499],
                                  [-82.702, 279.119],
                                  [133.382, 382.364],
                                  [311.852, 245.962],
                                  [112.455, -58.193],
                                  [217.245, -185.833],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.025, -17.95],
                                  [5.073, -89.5],
                                  [31.057, -64.517],
                                  [-86.392, -39.427],
                                  [-15.872, -48.165],
                                  [-151.158, 19.876],
                                  [74.303, 66.868],
                                  [-20.632, 96.837],
                                  [-15.661, 92],
                                ],
                                o: [
                                  [-87.231, 23.908],
                                  [-5.073, 89.5],
                                  [-31.057, 64.517],
                                  [86.392, 39.427],
                                  [21.405, 54.975],
                                  [107.744, -10.513],
                                  [-74.303, -66.868],
                                  [12.545, -104.073],
                                  [15.661, -92],
                                ],
                                v: [
                                  [-84.07, -351.908],
                                  [-170.594, -215.024],
                                  [-264.573, -16.285],
                                  [-245.058, 146.721],
                                  [-83.799, 278.646],
                                  [133.82, 381.964],
                                  [311.246, 245.267],
                                  [113.949, -56.504],
                                  [216.166, -184.968],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.25, -17.5],
                                  [5.615, -88.886],
                                  [30.675, -64.894],
                                  [-85.711, -39.498],
                                  [-15.844, -47.85],
                                  [-151.675, 20.112],
                                  [73.383, 66.642],
                                  [-20.244, 96.056],
                                  [-15.762, 92],
                                ],
                                o: [
                                  [-87.063, 23.538],
                                  [-5.615, 88.886],
                                  [-30.675, 64.894],
                                  [85.711, 39.498],
                                  [21.45, 54.75],
                                  [107.688, -10.625],
                                  [-73.383, -66.642],
                                  [12.05, -103.388],
                                  [15.763, -92],
                                ],
                                v: [
                                  [-83.273, -350.64],
                                  [-170.663, -215.333],
                                  [-264.61, -16.957],
                                  [-244.556, 146.937],
                                  [-84.946, 278.226],
                                  [134.193, 381.578],
                                  [310.608, 244.555],
                                  [115.507, -54.752],
                                  [215.069, -184.086],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.477, -17.046],
                                  [6.164, -88.266],
                                  [30.289, -65.274],
                                  [-85.022, -39.571],
                                  [-15.815, -47.532],
                                  [-152.197, 20.351],
                                  [72.453, 66.414],
                                  [-19.852, 95.267],
                                  [-15.865, 92],
                                ],
                                o: [
                                  [-86.892, 23.163],
                                  [-6.164, 88.266],
                                  [-30.289, 65.274],
                                  [85.022, 39.571],
                                  [21.495, 54.523],
                                  [107.631, -10.739],
                                  [-72.453, -66.414],
                                  [11.55, -102.695],
                                  [15.865, -92],
                                ],
                                v: [
                                  [-82.434, -349.396],
                                  [-170.768, -215.629],
                                  [-264.636, -17.614],
                                  [-244.143, 147.118],
                                  [-86.07, 277.762],
                                  [134.538, 381.159],
                                  [309.975, 243.882],
                                  [117.128, -52.972],
                                  [213.964, -183.206],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.707, -16.586],
                                  [6.718, -87.638],
                                  [29.898, -65.659],
                                  [-84.326, -39.644],
                                  [-15.787, -47.21],
                                  [-152.726, 20.592],
                                  [71.513, 66.182],
                                  [-19.456, 94.469],
                                  [-15.968, 92],
                                ],
                                o: [
                                  [-86.72, 22.784],
                                  [-6.718, 87.638],
                                  [-29.898, 65.659],
                                  [84.326, 39.644],
                                  [21.541, 54.293],
                                  [107.573, -10.853],
                                  [-71.513, -66.182],
                                  [11.045, -101.994],
                                  [15.968, -92],
                                ],
                                v: [
                                  [-81.562, -348.127],
                                  [-170.906, -215.939],
                                  [-264.59, -18.255],
                                  [-243.713, 147.326],
                                  [-87.242, 277.344],
                                  [134.942, 380.752],
                                  [309.313, 243.22],
                                  [118.782, -51.159],
                                  [212.804, -182.338],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.939, -16.122],
                                  [7.278, -87.004],
                                  [29.504, -66.048],
                                  [-83.622, -39.718],
                                  [-15.758, -46.885],
                                  [-153.26, 20.836],
                                  [70.562, 65.949],
                                  [-19.055, 93.662],
                                  [-16.073, 92],
                                ],
                                o: [
                                  [-86.546, 22.401],
                                  [-7.278, 87.004],
                                  [-29.504, 66.048],
                                  [83.622, 39.718],
                                  [21.588, 54.061],
                                  [107.515, -10.969],
                                  [-70.562, -65.949],
                                  [10.534, -101.286],
                                  [16.073, -92],
                                ],
                                v: [
                                  [-80.654, -346.854],
                                  [-171.05, -216.268],
                                  [-264.561, -18.881],
                                  [-243.37, 147.56],
                                  [-88.365, 276.918],
                                  [135.287, 380.348],
                                  [308.657, 242.603],
                                  [120.491, -49.284],
                                  [211.695, -181.447],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.174, -15.652],
                                  [7.844, -86.363],
                                  [29.105, -66.441],
                                  [-82.911, -39.793],
                                  [-15.728, -46.557],
                                  [-153.8, 21.082],
                                  [69.601, 65.713],
                                  [-18.65, 92.846],
                                  [-16.178, 92],
                                ],
                                o: [
                                  [-86.37, 22.013],
                                  [-7.844, 86.363],
                                  [-29.105, 66.441],
                                  [82.911, 39.793],
                                  [21.635, 53.826],
                                  [107.457, -11.087],
                                  [-69.601, -65.713],
                                  [10.018, -100.57],
                                  [16.178, -92],
                                ],
                                v: [
                                  [-79.705, -345.565],
                                  [-171.22, -216.643],
                                  [-264.483, -19.462],
                                  [-243.045, 147.784],
                                  [-89.535, 276.514],
                                  [135.657, 379.948],
                                  [308.007, 241.964],
                                  [122.295, -47.413],
                                  [210.532, -180.569],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.411, -15.178],
                                  [8.417, -85.715],
                                  [28.701, -66.839],
                                  [-82.191, -39.869],
                                  [-15.699, -46.224],
                                  [-154.346, 21.332],
                                  [68.63, 65.474],
                                  [-18.241, 92.022],
                                  [-16.285, 92],
                                ],
                                o: [
                                  [-86.192, 21.622],
                                  [-8.417, 85.715],
                                  [-28.701, 66.839],
                                  [82.191, 39.868],
                                  [21.682, 53.589],
                                  [107.397, -11.206],
                                  [-68.63, -65.474],
                                  [9.496, -99.846],
                                  [16.285, -92],
                                ],
                                v: [
                                  [-78.686, -344.261],
                                  [-171.421, -217.001],
                                  [-264.402, -20.055],
                                  [-242.772, 148.003],
                                  [-90.685, 276.102],
                                  [136, 379.521],
                                  [307.324, 241.398],
                                  [124.125, -45.487],
                                  [209.354, -179.669],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.651, -14.698],
                                  [8.995, -85.06],
                                  [28.293, -67.24],
                                  [-81.464, -39.945],
                                  [-15.669, -45.889],
                                  [-154.897, 21.583],
                                  [67.648, 65.232],
                                  [-17.827, 91.188],
                                  [-16.393, 92],
                                ],
                                o: [
                                  [-86.012, 21.226],
                                  [-8.995, 85.06],
                                  [-28.293, 67.24],
                                  [81.464, 39.945],
                                  [21.73, 53.349],
                                  [107.337, -11.326],
                                  [-67.648, -65.232],
                                  [8.968, -99.115],
                                  [16.393, -92],
                                ],
                                v: [
                                  [-77.665, -343.012],
                                  [-171.657, -217.34],
                                  [-264.245, -20.632],
                                  [-242.52, 148.21],
                                  [-91.88, 275.679],
                                  [136.333, 379.068],
                                  [306.655, 240.845],
                                  [126.019, -43.561],
                                  [208.154, -178.755],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.893, -14.213],
                                  [9.58, -84.397],
                                  [27.881, -67.646],
                                  [-80.729, -40.022],
                                  [-15.638, -45.549],
                                  [-155.455, 21.838],
                                  [66.655, 64.988],
                                  [-17.409, 90.345],
                                  [-16.502, 92],
                                ],
                                o: [
                                  [-85.83, 20.826],
                                  [-9.58, 84.397],
                                  [-27.881, 67.646],
                                  [80.729, 40.022],
                                  [21.779, 53.107],
                                  [107.277, -11.447],
                                  [-66.655, -64.988],
                                  [8.434, -98.375],
                                  [16.502, -92],
                                ],
                                v: [
                                  [-76.602, -341.69],
                                  [-171.888, -217.723],
                                  [-264.08, -21.221],
                                  [-242.316, 148.406],
                                  [-93.056, 275.314],
                                  [136.635, 378.616],
                                  [305.959, 240.333],
                                  [127.999, -41.577],
                                  [206.937, -177.884],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.138, -13.723],
                                  [10.172, -83.728],
                                  [27.465, -68.057],
                                  [-79.986, -40.1],
                                  [-15.608, -45.206],
                                  [-156.019, 22.095],
                                  [65.652, 64.742],
                                  [-16.986, 89.494],
                                  [-16.612, 92],
                                ],
                                o: [
                                  [-85.646, 20.421],
                                  [-10.172, 83.728],
                                  [-27.465, 68.057],
                                  [79.986, 40.1],
                                  [21.828, 52.862],
                                  [107.215, -11.569],
                                  [-65.652, -64.742],
                                  [7.895, -97.628],
                                  [16.612, -92],
                                ],
                                v: [
                                  [-75.468, -340.423],
                                  [-172.175, -218.117],
                                  [-263.874, -21.764],
                                  [-242.161, 148.625],
                                  [-94.245, 274.94],
                                  [136.961, 378.199],
                                  [305.301, 239.867],
                                  [129.974, -39.569],
                                  [205.696, -177.027],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.386, -13.228],
                                  [10.769, -83.051],
                                  [27.043, -68.472],
                                  [-79.235, -40.179],
                                  [-15.577, -44.859],
                                  [-156.588, 22.356],
                                  [64.638, 64.493],
                                  [-16.559, 88.633],
                                  [-16.724, 92],
                                ],
                                o: [
                                  [-85.46, 20.013],
                                  [-10.769, 83.051],
                                  [-27.043, 68.472],
                                  [79.235, 40.179],
                                  [21.877, 52.614],
                                  [107.153, -11.693],
                                  [-64.638, -64.493],
                                  [7.35, -96.872],
                                  [16.724, -92],
                                ],
                                v: [
                                  [-74.297, -339.118],
                                  [-172.427, -218.52],
                                  [-263.692, -22.319],
                                  [-242.054, 148.859],
                                  [-95.45, 274.624],
                                  [137.252, 377.721],
                                  [304.62, 239.414],
                                  [131.972, -37.536],
                                  [204.431, -176.153],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.637, -12.727],
                                  [11.373, -82.367],
                                  [26.618, -68.891],
                                  [-78.476, -40.259],
                                  [-15.545, -44.509],
                                  [-157.164, 22.618],
                                  [63.612, 64.241],
                                  [-16.127, 87.763],
                                  [-16.837, 92],
                                ],
                                o: [
                                  [-85.273, 19.6],
                                  [-11.373, 82.367],
                                  [-26.618, 68.891],
                                  [78.476, 40.259],
                                  [21.927, 52.363],
                                  [107.091, -11.818],
                                  [-63.612, -64.241],
                                  [6.799, -96.108],
                                  [16.836, -92],
                                ],
                                v: [
                                  [-73.116, -337.836],
                                  [-172.737, -218.933],
                                  [-263.44, -22.826],
                                  [-241.961, 149.049],
                                  [-96.666, 274.273],
                                  [137.471, 377.213],
                                  [303.981, 239.004],
                                  [134.026, -35.48],
                                  [203.174, -175.263],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.89, -12.221],
                                  [11.984, -81.676],
                                  [26.187, -69.315],
                                  [-77.709, -40.34],
                                  [-15.514, -44.154],
                                  [-157.746, 22.884],
                                  [62.576, 63.986],
                                  [-15.69, 86.883],
                                  [-16.95, 92],
                                ],
                                o: [
                                  [-85.083, 19.182],
                                  [-11.984, 81.676],
                                  [-26.187, 69.315],
                                  [77.709, 40.34],
                                  [21.978, 52.11],
                                  [107.028, -11.945],
                                  [-62.576, -63.986],
                                  [6.243, -95.336],
                                  [16.95, -92],
                                ],
                                v: [
                                  [-71.896, -336.583],
                                  [-173.069, -219.352],
                                  [-263.149, -23.378],
                                  [-241.976, 149.255],
                                  [-97.865, 273.95],
                                  [137.652, 376.674],
                                  [303.319, 238.67],
                                  [136.166, -33.338],
                                  [201.829, -174.39],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.146, -11.709],
                                  [12.601, -80.977],
                                  [25.753, -69.744],
                                  [-76.934, -40.421],
                                  [-15.482, -43.796],
                                  [-158.335, 23.153],
                                  [61.529, 63.729],
                                  [-15.249, 85.994],
                                  [-17.065, 92],
                                ],
                                o: [
                                  [-84.891, 18.76],
                                  [-12.601, 80.977],
                                  [-25.753, 69.744],
                                  [76.934, 40.421],
                                  [22.029, 51.854],
                                  [106.964, -12.073],
                                  [-61.529, -63.729],
                                  [5.68, -94.556],
                                  [17.065, -92],
                                ],
                                v: [
                                  [-70.603, -335.294],
                                  [-173.424, -219.807],
                                  [-262.853, -23.851],
                                  [-241.941, 149.414],
                                  [-99.109, 273.655],
                                  [137.796, 376.074],
                                  [302.668, 238.35],
                                  [138.236, -31.238],
                                  [200.521, -173.534],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.404, -11.192],
                                  [13.225, -80.271],
                                  [25.313, -70.177],
                                  [-76.15, -40.504],
                                  [-15.449, -43.434],
                                  [-158.929, 23.424],
                                  [60.471, 63.468],
                                  [-14.803, 85.096],
                                  [-17.182, 92],
                                ],
                                o: [
                                  [-84.697, 18.333],
                                  [-13.225, 80.271],
                                  [-25.313, 70.177],
                                  [76.15, 40.504],
                                  [22.081, 51.596],
                                  [106.899, -12.202],
                                  [-60.471, -63.468],
                                  [5.111, -93.768],
                                  [17.182, -92],
                                ],
                                v: [
                                  [-69.3, -334.036],
                                  [-173.799, -220.297],
                                  [-262.489, -24.37],
                                  [-241.979, 149.618],
                                  [-100.336, 273.391],
                                  [137.839, 375.476],
                                  [302.029, 238.044],
                                  [140.422, -29.086],
                                  [199.155, -172.633],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.665, -10.669],
                                  [13.855, -79.558],
                                  [24.869, -70.615],
                                  [-75.358, -40.587],
                                  [-15.417, -43.068],
                                  [-159.53, 23.699],
                                  [59.401, 63.205],
                                  [-14.352, 84.188],
                                  [-17.299, 92],
                                ],
                                o: [
                                  [-84.501, 17.902],
                                  [-13.855, 79.558],
                                  [-24.869, 70.614],
                                  [75.358, 40.587],
                                  [22.133, 51.335],
                                  [106.834, -12.333],
                                  [-59.401, -63.205],
                                  [4.536, -92.971],
                                  [17.299, -92],
                                ],
                                v: [
                                  [-67.956, -332.839],
                                  [-174.162, -220.759],
                                  [-262.183, -24.873],
                                  [-242.026, 149.772],
                                  [-101.576, 273.128],
                                  [137.846, 374.818],
                                  [301.434, 237.847],
                                  [142.63, -26.885],
                                  [197.76, -171.78],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [120.929, -10.141],
                                  [14.492, -78.836],
                                  [24.42, -71.057],
                                  [-74.558, -40.671],
                                  [-15.384, -42.699],
                                  [-160.138, 23.976],
                                  [58.32, 62.94],
                                  [-13.897, 83.27],
                                  [-17.418, 92],
                                ],
                                o: [
                                  [-84.303, 17.466],
                                  [-14.492, 78.836],
                                  [-24.42, 71.057],
                                  [74.558, 40.671],
                                  [22.186, 51.071],
                                  [106.768, -12.465],
                                  [-58.32, -62.94],
                                  [3.955, -92.165],
                                  [17.418, -92],
                                ],
                                v: [
                                  [-66.538, -331.612],
                                  [-174.574, -221.25],
                                  [-261.78, -25.331],
                                  [-242.083, 149.941],
                                  [-102.833, 272.928],
                                  [137.814, 374.098],
                                  [300.851, 237.666],
                                  [144.829, -24.698],
                                  [196.335, -170.882],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.196, -9.607],
                                  [15.136, -78.107],
                                  [23.966, -71.504],
                                  [-73.749, -40.756],
                                  [-15.35, -42.325],
                                  [-160.752, 24.256],
                                  [57.227, 62.671],
                                  [-13.436, 82.343],
                                  [-17.538, 92],
                                ],
                                o: [
                                  [-84.103, 17.026],
                                  [-15.136, 78.107],
                                  [-23.966, 71.504],
                                  [73.749, 40.756],
                                  [22.239, 50.804],
                                  [106.701, -12.598],
                                  [-57.227, -62.671],
                                  [3.368, -91.351],
                                  [17.538, -92],
                                ],
                                v: [
                                  [-65.109, -330.388],
                                  [-174.969, -221.741],
                                  [-261.344, -25.805],
                                  [-242.179, 150.058],
                                  [-104.134, 272.7],
                                  [137.683, 373.413],
                                  [300.282, 237.5],
                                  [147.083, -22.493],
                                  [194.882, -170.007],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.466, -9.068],
                                  [15.787, -77.371],
                                  [23.508, -71.956],
                                  [-72.931, -40.842],
                                  [-15.317, -41.948],
                                  [-161.372, 24.539],
                                  [56.123, 62.4],
                                  [-12.971, 81.405],
                                  [-17.66, 92],
                                ],
                                o: [
                                  [-83.9, 16.581],
                                  [-15.787, 77.371],
                                  [-23.508, 71.956],
                                  [72.931, 40.842],
                                  [22.293, 50.534],
                                  [106.633, -12.733],
                                  [-56.123, -62.4],
                                  [2.775, -90.529],
                                  [17.66, -92],
                                ],
                                v: [
                                  [-63.635, -329.229],
                                  [-175.378, -222.228],
                                  [-260.905, -26.298],
                                  [-242.28, 150.156],
                                  [-105.421, 272.57],
                                  [137.518, 372.634],
                                  [299.765, 237.447],
                                  [149.355, -20.245],
                                  [193.428, -169.146],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [121.739, -8.523],
                                  [16.444, -76.626],
                                  [23.044, -72.412],
                                  [-72.105, -40.929],
                                  [-15.283, -41.566],
                                  [-161.999, 24.826],
                                  [55.007, 62.125],
                                  [-12.501, 80.458],
                                  [-17.782, 92],
                                ],
                                o: [
                                  [-83.696, 16.131],
                                  [-16.444, 76.626],
                                  [-23.044, 72.412],
                                  [72.105, 40.929],
                                  [22.348, 50.261],
                                  [106.565, -12.869],
                                  [-55.008, -62.125],
                                  [2.175, -89.697],
                                  [17.782, -92],
                                ],
                                v: [
                                  [-62.116, -328.078],
                                  [-175.771, -222.679],
                                  [-260.428, -26.776],
                                  [-242.417, 150.235],
                                  [-106.724, 272.414],
                                  [137.286, 371.862],
                                  [299.257, 237.439],
                                  [151.677, -17.982],
                                  [191.948, -168.242],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.014, -7.972],
                                  [17.109, -75.874],
                                  [22.576, -72.874],
                                  [-71.27, -41.017],
                                  [-15.248, -41.18],
                                  [-162.632, 25.115],
                                  [53.88, 61.848],
                                  [-12.026, 79.501],
                                  [-17.906, 92],
                                ],
                                o: [
                                  [-83.489, 15.677],
                                  [-17.109, 75.874],
                                  [-22.576, 72.874],
                                  [71.27, 41.017],
                                  [22.403, 49.986],
                                  [106.496, -13.007],
                                  [-53.88, -61.848],
                                  [1.569, -88.857],
                                  [17.906, -92],
                                ],
                                v: [
                                  [-60.584, -326.965],
                                  [-176.177, -223.128],
                                  [-259.926, -27.28],
                                  [-242.561, 150.259],
                                  [-108.043, 272.296],
                                  [137.02, 370.997],
                                  [298.771, 237.516],
                                  [154.02, -15.737],
                                  [190.466, -167.357],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.292, -7.415],
                                  [17.781, -75.114],
                                  [22.103, -73.34],
                                  [-70.426, -41.106],
                                  [-15.213, -40.791],
                                  [-163.273, 25.407],
                                  [52.74, 61.568],
                                  [-11.546, 78.534],
                                  [-18.032, 92],
                                ],
                                o: [
                                  [-83.281, 15.217],
                                  [-17.781, 75.114],
                                  [-22.103, 73.34],
                                  [70.426, 41.106],
                                  [22.458, 49.708],
                                  [106.427, -13.146],
                                  [-52.74, -61.568],
                                  [0.957, -88.008],
                                  [18.032, -92],
                                ],
                                v: [
                                  [-59.003, -325.865],
                                  [-176.592, -223.629],
                                  [-259.422, -27.772],
                                  [-242.712, 150.325],
                                  [-109.384, 272.218],
                                  [136.715, 370.135],
                                  [298.336, 237.611],
                                  [156.384, -13.485],
                                  [188.954, -166.491],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [122.574, -6.853],
                                  [18.459, -74.346],
                                  [21.625, -73.811],
                                  [-69.573, -41.195],
                                  [-15.178, -40.397],
                                  [-163.92, 25.702],
                                  [51.589, 61.285],
                                  [-11.06, 77.556],
                                  [-18.158, 92],
                                ],
                                o: [
                                  [-83.07, 14.753],
                                  [-18.459, 74.346],
                                  [-21.625, 73.811],
                                  [69.573, 41.195],
                                  [22.515, 49.426],
                                  [106.357, -13.287],
                                  [-51.589, -61.285],
                                  [0.338, -87.15],
                                  [18.158, -92],
                                ],
                                v: [
                                  [-57.347, -324.833],
                                  [-176.989, -224.091],
                                  [-258.854, -28.285],
                                  [-242.862, 150.343],
                                  [-110.743, 272.216],
                                  [136.315, 369.275],
                                  [297.919, 237.752],
                                  [158.734, -11.192],
                                  [187.473, -165.61],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [19.625, 22.281],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.156, 21.562],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, 20.812],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.812, 19.312],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.469, 18.594],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.094, 17.906],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.812, 17.219],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.469, 16.625],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.219, 16],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.969, 15.406],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.781, 14.812],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.562, 14.312],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.406, 13.812],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.156, 12.875],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.062, 12.438],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, 12],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, 11.625],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 11.25],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.969, 10.906],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, 10.531],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.031, 10.219],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.125, 9.938],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.188, 9.594],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.281, 9.344],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.406, 9.062],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.562, 8.844],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.719, 8.594],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.594, 8.375],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.438, 8.156],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.312, 7.938],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.188, 7.75],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.094, 7.531],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.031, 7.344],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.969, 7.125],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 6.969],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 6.781],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.906, 6.625],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 6.438],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 6.25],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.031, 6.062],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.156, 5.688],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.25, 5.531],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.312, 5.375],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.406, 5.219],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.531, 5.125],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.656, 5.062],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.781, 4.969],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.938, 4.906],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.125, 4.844],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.25, 4.812],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.438, 4.812],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.656, 4.812],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.844, 4.812],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.125, 4.875],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 4.906],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.656, 4.938],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, 5],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.188, 5.062],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.5, 5.125],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.781, 5.219],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.094, 5.375],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.406, 5.438],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.719, 5.594],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.031, 5.688],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.375, 5.844],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.719, 5.969],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.344, 6.281],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.688, 6.438],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.062, 6.625],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.344, 6.812],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.656, 7],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 7.188],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 7.406],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.656, 7.594],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24, 7.812],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, 8.031],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 8.281],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.875, 8.5],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25, 8.75],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 9],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 9.25],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.188, 9.5],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.438, 9.781],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.656, 10.031],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 10.281],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.062, 10.625],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.438, 11.312],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.75, 12.062],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.906, 12.469],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, 12.844],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.156, 13.25],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.312, 13.688],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.406, 14.125],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.5, 14.594],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.625, 15.031],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.719, 15.5],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.781, 15.969],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, 16.844],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.094, 17.781],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.188, 18.219],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.219, 18.719],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.25, 19.156],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, 19.562],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.375, 20],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.406, 20.406],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.406, 20.844],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 21.25],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.438, 21.625],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, 22],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 22.375],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.531, 22.656],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, 23],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, 23.25],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.094, 23.594],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.375, 23.906],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 24.156],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.781, 24.344],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 24.562],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.125, 24.719],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.281, 24.812],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.406, 24.938],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.531, 24.969],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.688, 25.062],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.781, 25.094],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.906, 25.031],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.156, 25.031],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.75, 24.969],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 24.938],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.125, 24.812],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.781, 24.719],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.469, 24.625],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, 24.438],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.844, 24.281],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.594, 24.031],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.094, 23.594],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.875, 23.25],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.656, 23],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.469, 22.656],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.281, 22.344],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.156, 22],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.031, 21.594],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.906, 21.219],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.844, 20.844],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.781, 20.406],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, 19.938],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.719, 19.531],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, 19.062],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.812, 18.625],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.844, 18.156],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.938, 17.688],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.062, 17.156],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.188, 16.688],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.375, 16.188],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.531, 15.75],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.75, 15.219],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.969, 14.719],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.219, 14.25],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.531, 13.781],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.844, 13.344],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.188, 12.844],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.562, 12.406],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.969, 11.969],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.375, 11.562],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.812, 11.156],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.281, 10.75],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.781, 10.344],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.25, 10.031],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.812, 9.656],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.375, 9.344],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.875, 9.062],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.438, 8.781],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.969, 8.562],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 8.375],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 8.188],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.531, 8.031],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.031, 7.938],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 7.844],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.094, 7.75],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 7.75],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.562, 7.719],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.031, 7.781],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.531, 7.812],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, 7.875],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.406, 8],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.812, 8.156],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.219, 8.219],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.625, 8.375],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.969, 8.562],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.656, 8.75],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.406, 8.938],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.156, 9.188],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.812, 9.406],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 9.688],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.188, 9.938],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.875, 10.219],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.844, 11.156],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.5, 11.531],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.75, 12.219],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 13.344],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.375, 13.781],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24, 14.219],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.688, 14.625],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 15],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23, 15.469],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.688, 15.906],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.375, 16.312],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.75, 17.188],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.469, 17.625],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.188, 18.031],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.938, 18.469],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.719, 18.875],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.469, 19.312],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.094, 20.062],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.906, 20.375],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.75, 20.719],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.625, 21],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.562, 21.25],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.469, 21.531],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.438, 21.688],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.406, 21.906],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.406, 22.031],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.438, 22.125],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.531, 22.219],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [19.625, 22.281],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.156, 21.562],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, 20.812],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.812, 19.312],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.469, 18.594],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.094, 17.906],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.812, 17.219],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.469, 16.625],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.219, 16],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.969, 15.406],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.781, 14.812],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.562, 14.312],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.406, 13.812],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.156, 12.875],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.062, 12.438],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, 12],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, 11.625],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 11.25],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.969, 10.906],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, 10.531],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.031, 10.219],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.125, 9.938],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.188, 9.594],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.281, 9.344],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.406, 9.062],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.562, 8.844],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.719, 8.594],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.594, 8.375],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.438, 8.156],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.312, 7.938],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.188, 7.75],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.094, 7.531],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.031, 7.344],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.969, 7.125],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 6.969],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 6.781],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.906, 6.625],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 6.438],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 6.25],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.031, 6.062],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.156, 5.688],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.25, 5.531],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.312, 5.375],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.406, 5.219],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.531, 5.125],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.656, 5.062],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.781, 4.969],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.938, 4.906],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.125, 4.844],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.25, 4.812],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.438, 4.812],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.656, 4.812],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.844, 4.812],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.125, 4.875],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 4.906],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.656, 4.938],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, 5],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.188, 5.062],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.5, 5.125],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.781, 5.219],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.094, 5.375],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.406, 5.438],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.719, 5.594],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.031, 5.688],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.375, 5.844],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.719, 5.969],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.344, 6.281],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.688, 6.438],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.062, 6.625],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.344, 6.812],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.656, 7],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 7.188],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 7.406],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.656, 7.594],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24, 7.812],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, 8.031],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 8.281],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.875, 8.5],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25, 8.75],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 9],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 9.25],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.188, 9.5],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.438, 9.781],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.656, 10.031],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 10.281],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.062, 10.625],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.438, 11.312],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.75, 12.062],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.906, 12.469],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, 12.844],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.156, 13.25],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.312, 13.688],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.406, 14.125],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.5, 14.594],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.625, 15.031],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.719, 15.5],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.781, 15.969],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, 16.844],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.094, 17.781],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.188, 18.219],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.219, 18.719],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.25, 19.156],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, 19.562],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.375, 20],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.406, 20.406],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.406, 20.844],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 21.25],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.438, 21.625],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, 22],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 22.375],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.531, 22.656],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, 23],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, 23.25],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.094, 23.594],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.375, 23.906],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, 24.156],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.781, 24.344],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 24.562],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.125, 24.719],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.281, 24.812],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.406, 24.938],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.531, 24.969],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.688, 25.062],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.781, 25.094],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.906, 25.031],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.156, 25.031],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.75, 24.969],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 24.938],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.125, 24.812],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.781, 24.719],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.469, 24.625],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, 24.438],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.844, 24.281],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.594, 24.031],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.094, 23.594],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.875, 23.25],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.656, 23],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.469, 22.656],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.281, 22.344],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.156, 22],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.031, 21.594],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.906, 21.219],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.844, 20.844],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.781, 20.406],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, 19.938],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.719, 19.531],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, 19.062],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.812, 18.625],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.844, 18.156],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.938, 17.688],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.062, 17.156],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.188, 16.688],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.375, 16.188],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.531, 15.75],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.75, 15.219],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.969, 14.719],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.219, 14.25],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.531, 13.781],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.844, 13.344],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.188, 12.844],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.562, 12.406],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.969, 11.969],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.375, 11.562],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.812, 11.156],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.281, 10.75],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.781, 10.344],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.25, 10.031],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.812, 9.656],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.375, 9.344],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.875, 9.062],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.438, 8.781],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.969, 8.562],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 8.375],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 8.188],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.531, 8.031],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.031, 7.938],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 7.844],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.094, 7.75],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, 7.75],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.562, 7.719],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.031, 7.781],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.531, 7.812],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, 7.875],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.406, 8],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.812, 8.156],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.219, 8.219],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.625, 8.375],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.969, 8.562],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.656, 8.75],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.406, 8.938],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.156, 9.188],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.812, 9.406],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.469, 9.688],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.188, 9.938],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.875, 10.219],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.844, 11.156],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.5, 11.531],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.75, 12.219],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 13.344],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.375, 13.781],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24, 14.219],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.688, 14.625],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.344, 15],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23, 15.469],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.688, 15.906],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.375, 16.312],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.75, 17.188],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.469, 17.625],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.188, 18.031],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.938, 18.469],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.719, 18.875],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.469, 19.312],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.094, 20.062],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.906, 20.375],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.75, 20.719],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.625, 21],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.562, 21.25],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.469, 21.531],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.438, 21.688],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.406, 21.906],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.406, 22.031],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.438, 22.125],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.531, 22.219],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 7',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 7,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [120.001, -11.998],
                                  [12.252, -81.373],
                                  [25.999, -69.501],
                                  [-77.373, -40.375],
                                  [-15.5, -43.999],
                                  [-158.002, 23.001],
                                  [62.122, 63.874],
                                  [-15.499, 86.497],
                                  [-17, 92],
                                ],
                                o: [
                                  [-84.999, 18.999],
                                  [-12.252, 81.373],
                                  [-25.999, 69.501],
                                  [77.373, 40.375],
                                  [22, 51.999],
                                  [107, -12],
                                  [-62.122, -63.874],
                                  [5.998, -94.998],
                                  [17, -92],
                                ],
                                v: [
                                  [-66.06, -335.59],
                                  [-176.094, -217.158],
                                  [-260.593, -23.564],
                                  [-241.969, 150.063],
                                  [-96.442, 278.405],
                                  [137.625, 373.249],
                                  [293.095, 235.813],
                                  [138.788, -30.307],
                                  [198.715, -172.404],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.711, -12.578],
                                  [11.553, -82.164],
                                  [26.491, -69.016],
                                  [-78.251, -40.283],
                                  [-15.536, -44.405],
                                  [-157.335, 22.697],
                                  [63.308, 64.166],
                                  [-15.999, 87.504],
                                  [-16.87, 92],
                                ],
                                o: [
                                  [-85.217, 19.477],
                                  [-11.553, 82.164],
                                  [-26.491, 69.016],
                                  [78.251, 40.283],
                                  [21.942, 52.289],
                                  [107.072, -11.855],
                                  [-63.308, -64.166],
                                  [6.636, -95.882],
                                  [16.87, -92],
                                ],
                                v: [
                                  [-66.487, -336.74],
                                  [-176.383, -216.163],
                                  [-260.444, -22.878],
                                  [-242.078, 150.326],
                                  [-94.666, 279.437],
                                  [137.331, 373.056],
                                  [291.944, 235.623],
                                  [137.052, -31.97],
                                  [200.146, -172.926],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.425, -13.151],
                                  [10.862, -82.946],
                                  [26.978, -68.536],
                                  [-79.119, -40.192],
                                  [-15.572, -44.805],
                                  [-156.677, 22.396],
                                  [64.48, 64.454],
                                  [-16.492, 88.499],
                                  [-16.741, 92],
                                ],
                                o: [
                                  [-85.431, 19.949],
                                  [-10.862, 82.946],
                                  [-26.978, 68.536],
                                  [79.119, 40.192],
                                  [21.885, 52.575],
                                  [107.144, -11.712],
                                  [-64.48, -64.454],
                                  [7.266, -96.755],
                                  [16.741, -92],
                                ],
                                v: [
                                  [-66.875, -337.873],
                                  [-176.631, -215.249],
                                  [-260.276, -22.202],
                                  [-242.15, 150.616],
                                  [-92.843, 280.415],
                                  [136.968, 372.853],
                                  [290.813, 235.456],
                                  [135.318, -33.586],
                                  [201.578, -173.449],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.142, -13.716],
                                  [10.18, -83.718],
                                  [27.459, -68.063],
                                  [-79.976, -40.102],
                                  [-15.607, -45.201],
                                  [-156.027, 22.099],
                                  [65.637, 64.738],
                                  [-16.98, 89.481],
                                  [-16.614, 92],
                                ],
                                o: [
                                  [-85.643, 20.416],
                                  [-10.18, 83.718],
                                  [-27.459, 68.063],
                                  [79.976, 40.101],
                                  [21.828, 52.858],
                                  [107.215, -11.571],
                                  [-65.637, -64.738],
                                  [7.888, -97.617],
                                  [16.614, -92],
                                ],
                                v: [
                                  [-67.286, -338.987],
                                  [-176.933, -214.323],
                                  [-260.092, -21.499],
                                  [-242.249, 150.932],
                                  [-90.977, 281.34],
                                  [136.602, 372.611],
                                  [289.734, 235.281],
                                  [133.62, -35.186],
                                  [203.012, -173.88],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.863, -14.274],
                                  [9.507, -84.481],
                                  [27.933, -67.595],
                                  [-80.822, -40.013],
                                  [-15.642, -45.592],
                                  [-155.385, 21.806],
                                  [66.78, 65.019],
                                  [-17.462, 90.451],
                                  [-16.488, 92],
                                ],
                                o: [
                                  [-85.853, 20.876],
                                  [-9.507, 84.481],
                                  [-27.933, 67.595],
                                  [80.822, 40.013],
                                  [21.773, 53.137],
                                  [107.284, -11.431],
                                  [-66.78, -65.019],
                                  [8.502, -98.468],
                                  [16.488, -92],
                                ],
                                v: [
                                  [-67.691, -340.086],
                                  [-177.258, -213.386],
                                  [-259.855, -20.833],
                                  [-242.343, 151.269],
                                  [-89.128, 282.242],
                                  [136.164, 372.36],
                                  [288.677, 235.161],
                                  [131.987, -36.736],
                                  [204.444, -174.252],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.587, -14.826],
                                  [8.842, -85.234],
                                  [28.402, -67.134],
                                  [-81.657, -39.925],
                                  [-15.677, -45.978],
                                  [-154.751, 21.517],
                                  [67.908, 65.297],
                                  [-17.937, 91.409],
                                  [-16.364, 92],
                                ],
                                o: [
                                  [-86.06, 21.331],
                                  [-8.842, 85.234],
                                  [-28.402, 67.134],
                                  [81.657, 39.925],
                                  [21.717, 53.413],
                                  [107.353, -11.294],
                                  [-67.908, -65.297],
                                  [9.108, -99.309],
                                  [16.364, -92],
                                ],
                                v: [
                                  [-68.083, -341.104],
                                  [-177.571, -212.531],
                                  [-259.57, -20.138],
                                  [-242.437, 151.63],
                                  [-87.268, 283.088],
                                  [135.687, 372.101],
                                  [287.639, 235.067],
                                  [130.357, -38.273],
                                  [205.938, -174.601],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.315, -15.37],
                                  [8.185, -85.977],
                                  [28.864, -66.678],
                                  [-82.482, -39.838],
                                  [-15.711, -46.359],
                                  [-154.125, 21.231],
                                  [69.023, 65.57],
                                  [-18.406, 92.355],
                                  [-16.242, 92],
                                ],
                                o: [
                                  [-86.264, 21.78],
                                  [-8.185, 85.977],
                                  [-28.864, 66.678],
                                  [82.482, 39.838],
                                  [21.663, 53.685],
                                  [107.421, -11.158],
                                  [-69.023, -65.57],
                                  [9.707, -100.139],
                                  [16.242, -92],
                                ],
                                v: [
                                  [-68.533, -342.019],
                                  [-177.939, -211.666],
                                  [-259.267, -19.448],
                                  [-242.529, 151.979],
                                  [-85.363, 283.917],
                                  [135.141, 371.77],
                                  [286.59, 235],
                                  [128.762, -39.758],
                                  [207.403, -174.889],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.046, -15.907],
                                  [7.537, -86.711],
                                  [29.321, -66.228],
                                  [-83.297, -39.752],
                                  [-15.744, -46.735],
                                  [-153.507, 20.949],
                                  [70.123, 65.841],
                                  [-18.87, 93.289],
                                  [-16.121, 92],
                                ],
                                o: [
                                  [-86.465, 22.224],
                                  [-7.537, 86.711],
                                  [-29.321, 66.228],
                                  [83.297, 39.752],
                                  [21.609, 53.954],
                                  [107.488, -11.023],
                                  [-70.123, -65.841],
                                  [10.298, -100.959],
                                  [16.121, -92],
                                ],
                                v: [
                                  [-68.97, -342.89],
                                  [-178.293, -210.82],
                                  [-258.912, -18.785],
                                  [-242.651, 152.316],
                                  [-83.483, 284.692],
                                  [134.587, 371.466],
                                  [285.629, 234.956],
                                  [127.171, -41.259],
                                  [208.924, -175.13],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.781, -16.438],
                                  [6.897, -87.436],
                                  [29.772, -65.783],
                                  [-84.102, -39.668],
                                  [-15.777, -47.107],
                                  [-152.896, 20.67],
                                  [71.209, 66.108],
                                  [-19.328, 94.211],
                                  [-16.001, 92],
                                ],
                                o: [
                                  [-86.664, 22.661],
                                  [-6.897, 87.436],
                                  [-29.772, 65.783],
                                  [84.102, 39.668],
                                  [21.556, 54.219],
                                  [107.555, -10.89],
                                  [-71.209, -66.108],
                                  [10.882, -101.768],
                                  [16.001, -92],
                                ],
                                v: [
                                  [-69.461, -343.662],
                                  [-178.639, -210.029],
                                  [-258.54, -18.061],
                                  [-242.744, 152.673],
                                  [-81.594, 285.417],
                                  [133.964, 371.12],
                                  [284.624, 234.906],
                                  [125.677, -42.679],
                                  [210.381, -175.312],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.519, -16.962],
                                  [6.264, -88.151],
                                  [30.218, -65.344],
                                  [-84.896, -39.584],
                                  [-15.81, -47.474],
                                  [-152.294, 20.395],
                                  [72.282, 66.372],
                                  [-19.78, 95.122],
                                  [-15.883, 92],
                                ],
                                o: [
                                  [-86.861, 23.094],
                                  [-6.264, 88.151],
                                  [-30.218, 65.344],
                                  [84.896, 39.584],
                                  [21.504, 54.481],
                                  [107.62, -10.759],
                                  [-72.282, -66.372],
                                  [11.458, -102.567],
                                  [15.884, -92],
                                ],
                                v: [
                                  [-69.969, -344.428],
                                  [-179.033, -209.194],
                                  [-258.15, -17.332],
                                  [-242.844, 153.077],
                                  [-79.725, 286.123],
                                  [133.298, 370.8],
                                  [283.734, 234.884],
                                  [124.154, -44.087],
                                  [211.899, -175.505],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.26, -17.48],
                                  [5.64, -88.858],
                                  [30.658, -64.911],
                                  [-85.68, -39.502],
                                  [-15.842, -47.836],
                                  [-151.699, 20.123],
                                  [73.341, 66.632],
                                  [-20.226, 96.021],
                                  [-15.767, 92],
                                ],
                                o: [
                                  [-87.055, 23.521],
                                  [-5.64, 88.858],
                                  [-30.658, 64.911],
                                  [85.68, 39.502],
                                  [21.452, 54.74],
                                  [107.685, -10.63],
                                  [-73.341, -66.632],
                                  [12.028, -103.356],
                                  [15.767, -92],
                                ],
                                v: [
                                  [-70.468, -345.064],
                                  [-179.42, -208.415],
                                  [-257.707, -16.595],
                                  [-242.972, 153.429],
                                  [-77.876, 286.747],
                                  [132.626, 370.411],
                                  [282.772, 234.893],
                                  [122.664, -45.443],
                                  [213.415, -175.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.005, -17.99],
                                  [5.024, -89.556],
                                  [31.092, -64.483],
                                  [-86.454, -39.42],
                                  [-15.874, -48.193],
                                  [-151.111, 19.855],
                                  [74.387, 66.889],
                                  [-20.667, 96.908],
                                  [-15.652, 92],
                                ],
                                o: [
                                  [-87.246, 23.942],
                                  [-5.024, 89.556],
                                  [-31.092, 64.483],
                                  [86.454, 39.42],
                                  [21.401, 54.995],
                                  [107.749, -10.502],
                                  [-74.387, -66.889],
                                  [12.589, -104.135],
                                  [15.652, -92],
                                ],
                                v: [
                                  [-70.984, -345.699],
                                  [-179.816, -207.655],
                                  [-257.255, -15.856],
                                  [-243.072, 153.859],
                                  [-75.994, 287.414],
                                  [131.91, 370.015],
                                  [281.9, 234.925],
                                  [121.215, -46.779],
                                  [214.919, -175.682],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.753, -18.495],
                                  [4.416, -90.244],
                                  [31.521, -64.061],
                                  [-87.219, -39.34],
                                  [-15.906, -48.546],
                                  [-150.531, 19.59],
                                  [75.419, 67.143],
                                  [-21.102, 97.785],
                                  [-15.539, 92],
                                ],
                                o: [
                                  [-87.436, 24.358],
                                  [-4.416, 90.244],
                                  [-31.521, 64.061],
                                  [87.219, 39.34],
                                  [21.351, 55.247],
                                  [107.812, -10.376],
                                  [-75.419, -67.143],
                                  [13.144, -104.904],
                                  [15.539, -92],
                                ],
                                v: [
                                  [-71.525, -346.208],
                                  [-180.204, -206.915],
                                  [-256.778, -15.08],
                                  [-243.21, 154.234],
                                  [-74.122, 287.974],
                                  [131.124, 369.675],
                                  [281.045, 234.961],
                                  [119.79, -48.069],
                                  [216.457, -175.732],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.504, -18.993],
                                  [3.815, -90.924],
                                  [31.944, -63.644],
                                  [-87.973, -39.26],
                                  [-15.937, -48.895],
                                  [-149.958, 19.329],
                                  [76.438, 67.393],
                                  [-21.531, 98.65],
                                  [-15.427, 92],
                                ],
                                o: [
                                  [-87.622, 24.769],
                                  [-3.815, 90.924],
                                  [-31.944, 63.644],
                                  [87.973, 39.261],
                                  [21.301, 55.496],
                                  [107.874, -10.252],
                                  [-76.438, -67.393],
                                  [13.692, -105.664],
                                  [15.427, -92],
                                ],
                                v: [
                                  [-72.083, -346.692],
                                  [-180.573, -206.169],
                                  [-256.247, -14.328],
                                  [-243.324, 154.623],
                                  [-72.254, 288.553],
                                  [130.329, 369.265],
                                  [280.219, 235.057],
                                  [118.382, -49.376],
                                  [217.98, -175.736],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.258, -19.484],
                                  [3.222, -91.596],
                                  [32.362, -63.232],
                                  [-88.718, -39.182],
                                  [-15.968, -49.239],
                                  [-149.393, 19.071],
                                  [77.444, 67.641],
                                  [-21.955, 99.504],
                                  [-15.316, 92],
                                ],
                                o: [
                                  [-87.807, 25.174],
                                  [-3.222, 91.596],
                                  [-32.362, 63.232],
                                  [88.718, 39.182],
                                  [21.252, 55.742],
                                  [107.936, -10.129],
                                  [-77.444, -67.641],
                                  [14.233, -106.413],
                                  [15.316, -92],
                                ],
                                v: [
                                  [-72.656, -347.18],
                                  [-180.996, -205.467],
                                  [-255.71, -13.537],
                                  [-243.439, 155.026],
                                  [-70.434, 289.077],
                                  [129.49, 368.885],
                                  [279.447, 235.15],
                                  [116.996, -50.602],
                                  [219.473, -175.734],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.015, -19.97],
                                  [2.637, -92.258],
                                  [32.774, -62.826],
                                  [-89.454, -39.105],
                                  [-15.998, -49.579],
                                  [-148.835, 18.816],
                                  [78.438, 67.885],
                                  [-22.374, 100.347],
                                  [-15.207, 92],
                                ],
                                o: [
                                  [-87.989, 25.575],
                                  [-2.637, 92.258],
                                  [-32.774, 62.826],
                                  [89.454, 39.105],
                                  [21.203, 55.985],
                                  [107.996, -10.008],
                                  [-78.438, -67.885],
                                  [14.766, -107.153],
                                  [15.207, -92],
                                ],
                                v: [
                                  [-73.256, -347.549],
                                  [-181.361, -204.76],
                                  [-255.182, -12.73],
                                  [-243.594, 155.43],
                                  [-68.608, 289.585],
                                  [128.643, 368.462],
                                  [278.668, 235.215],
                                  [115.616, -51.82],
                                  [220.988, -175.678],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.776, -20.449],
                                  [2.059, -92.913],
                                  [33.181, -62.424],
                                  [-90.18, -39.029],
                                  [-16.028, -49.914],
                                  [-148.284, 18.565],
                                  [79.418, 68.126],
                                  [-22.787, 101.179],
                                  [-15.099, 92],
                                ],
                                o: [
                                  [-88.168, 25.97],
                                  [-2.059, 92.913],
                                  [-33.181, 62.424],
                                  [90.18, 39.029],
                                  [21.155, 56.224],
                                  [108.056, -9.888],
                                  [-79.418, -68.126],
                                  [15.293, -107.884],
                                  [15.099, -92],
                                ],
                                v: [
                                  [-73.87, -347.925],
                                  [-181.78, -204.071],
                                  [-254.575, -11.894],
                                  [-243.728, 155.809],
                                  [-66.766, 290.026],
                                  [127.725, 368.046],
                                  [277.919, 235.34],
                                  [114.269, -52.982],
                                  [222.523, -175.58],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.539, -20.921],
                                  [1.488, -93.558],
                                  [33.583, -62.028],
                                  [-90.897, -38.953],
                                  [-16.058, -50.245],
                                  [-147.74, 18.316],
                                  [80.386, 68.364],
                                  [-23.195, 102.001],
                                  [-14.993, 92],
                                ],
                                o: [
                                  [-88.346, 26.36],
                                  [-1.488, 93.558],
                                  [-33.583, 62.028],
                                  [90.897, 38.953],
                                  [21.108, 56.461],
                                  [108.115, -9.77],
                                  [-80.386, -68.364],
                                  [15.814, -108.605],
                                  [14.993, -92],
                                ],
                                v: [
                                  [-74.474, -348.223],
                                  [-182.114, -203.401],
                                  [-253.991, -11.065],
                                  [-243.889, 156.161],
                                  [-64.957, 290.477],
                                  [126.823, 367.648],
                                  [277.227, 235.463],
                                  [112.991, -54.138],
                                  [224.028, -175.481],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.306, -21.388],
                                  [0.925, -94.196],
                                  [33.98, -61.637],
                                  [-91.604, -38.879],
                                  [-16.087, -50.572],
                                  [-147.203, 18.071],
                                  [81.342, 68.598],
                                  [-23.597, 102.812],
                                  [-14.888, 92],
                                ],
                                o: [
                                  [-88.521, 26.745],
                                  [-0.925, 94.196],
                                  [-33.98, 61.637],
                                  [91.604, 38.879],
                                  [21.061, 56.694],
                                  [108.174, -9.653],
                                  [-81.342, -68.598],
                                  [16.327, -109.317],
                                  [14.888, -92],
                                ],
                                v: [
                                  [-75.105, -348.532],
                                  [-182.525, -202.75],
                                  [-253.377, -10.167],
                                  [-244.029, 156.547],
                                  [-63.192, 290.926],
                                  [125.85, 367.284],
                                  [276.591, 235.598],
                                  [111.683, -55.237],
                                  [225.552, -175.367],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.075, -21.849],
                                  [0.37, -94.825],
                                  [34.372, -61.251],
                                  [-92.302, -38.805],
                                  [-16.116, -50.894],
                                  [-146.674, 17.829],
                                  [82.285, 68.83],
                                  [-23.995, 103.613],
                                  [-14.784, 92],
                                ],
                                o: [
                                  [-88.693, 27.125],
                                  [-0.37, 94.825],
                                  [-34.372, 61.251],
                                  [92.302, 38.805],
                                  [21.015, 56.925],
                                  [108.231, -9.538],
                                  [-82.285, -68.83],
                                  [16.834, -110.02],
                                  [14.784, -92],
                                ],
                                v: [
                                  [-75.751, -348.791],
                                  [-182.85, -202.117],
                                  [-252.763, -9.288],
                                  [-244.188, 156.942],
                                  [-61.425, 291.299],
                                  [124.868, 366.899],
                                  [275.925, 235.772],
                                  [110.406, -56.331],
                                  [227.032, -175.216],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.848, -22.304],
                                  [-0.179, -95.446],
                                  [34.758, -60.87],
                                  [-92.992, -38.733],
                                  [-16.144, -51.213],
                                  [-146.15, 17.59],
                                  [83.216, 69.059],
                                  [-24.387, 104.403],
                                  [-14.682, 92],
                                ],
                                o: [
                                  [-88.864, 27.501],
                                  [0.179, 95.446],
                                  [-34.758, 60.871],
                                  [92.992, 38.733],
                                  [20.97, 57.152],
                                  [108.288, -9.424],
                                  [-83.216, -69.059],
                                  [17.334, -110.713],
                                  [14.682, -92],
                                ],
                                v: [
                                  [-76.425, -349.025],
                                  [-183.227, -201.502],
                                  [-252.134, -8.363],
                                  [-244.375, 157.268],
                                  [-59.638, 291.658],
                                  [123.878, 366.524],
                                  [275.316, 235.969],
                                  [109.137, -57.384],
                                  [228.568, -175.052],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.624, -22.753],
                                  [-0.721, -96.059],
                                  [35.14, -60.495],
                                  [-93.672, -38.661],
                                  [-16.172, -51.527],
                                  [-145.634, 17.355],
                                  [84.135, 69.285],
                                  [-24.774, 105.183],
                                  [-14.581, 92],
                                ],
                                o: [
                                  [-89.032, 27.871],
                                  [0.721, 96.059],
                                  [-35.14, 60.495],
                                  [93.672, 38.661],
                                  [20.925, 57.376],
                                  [108.344, -9.312],
                                  [-84.135, -69.285],
                                  [17.828, -111.398],
                                  [14.581, -92],
                                ],
                                v: [
                                  [-77.09, -349.228],
                                  [-183.54, -200.865],
                                  [-251.491, -7.432],
                                  [-244.503, 157.663],
                                  [-57.911, 291.981],
                                  [122.841, 366.104],
                                  [274.765, 236.143],
                                  [107.899, -58.394],
                                  [230.059, -174.829],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.402, -23.196],
                                  [-1.255, -96.664],
                                  [35.517, -60.123],
                                  [-94.344, -38.591],
                                  [-16.2, -51.837],
                                  [-145.125, 17.122],
                                  [85.042, 69.508],
                                  [-25.156, 105.953],
                                  [-14.481, 92],
                                ],
                                o: [
                                  [-89.198, 28.237],
                                  [1.255, 96.664],
                                  [-35.517, 60.123],
                                  [94.344, 38.591],
                                  [20.88, 57.598],
                                  [108.399, -9.201],
                                  [-85.042, -69.508],
                                  [18.315, -112.074],
                                  [14.481, -92],
                                ],
                                v: [
                                  [-77.767, -349.449],
                                  [-183.842, -200.284],
                                  [-250.835, -6.477],
                                  [-244.676, 157.986],
                                  [-56.203, 292.292],
                                  [121.772, 365.782],
                                  [274.249, 236.357],
                                  [106.653, -59.387],
                                  [231.542, -174.658],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.183, -23.633],
                                  [-1.783, -97.262],
                                  [35.888, -59.757],
                                  [-95.007, -38.521],
                                  [-16.227, -52.143],
                                  [-144.622, 16.893],
                                  [85.937, 69.728],
                                  [-25.534, 106.713],
                                  [-14.383, 92],
                                ],
                                o: [
                                  [-89.362, 28.597],
                                  [1.783, 97.262],
                                  [-35.888, 59.757],
                                  [95.007, 38.521],
                                  [20.837, 57.817],
                                  [108.454, -9.092],
                                  [-85.937, -69.728],
                                  [18.797, -112.741],
                                  [14.383, -92],
                                ],
                                v: [
                                  [-78.475, -349.586],
                                  [-184.179, -199.68],
                                  [-250.187, -5.514],
                                  [-244.853, 158.315],
                                  [-54.516, 292.608],
                                  [120.677, 365.414],
                                  [273.77, 236.55],
                                  [105.454, -60.338],
                                  [233.019, -174.431],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.968, -24.065],
                                  [-2.303, -97.851],
                                  [36.255, -59.396],
                                  [-95.661, -38.452],
                                  [-16.254, -52.445],
                                  [-144.125, 16.666],
                                  [86.82, 69.945],
                                  [-25.906, 107.463],
                                  [-14.285, 92],
                                ],
                                o: [
                                  [-89.524, 28.954],
                                  [2.303, 97.851],
                                  [-36.255, 59.396],
                                  [95.661, 38.452],
                                  [20.793, 58.032],
                                  [108.508, -8.984],
                                  [-86.82, -69.945],
                                  [19.271, -113.399],
                                  [14.285, -92],
                                ],
                                v: [
                                  [-79.174, -349.783],
                                  [-184.425, -199.133],
                                  [-249.544, -4.503],
                                  [-245.075, 158.63],
                                  [-52.847, 292.872],
                                  [119.613, 365.041],
                                  [273.287, 236.766],
                                  [104.246, -61.29],
                                  [234.448, -174.195],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.755, -24.491],
                                  [-2.817, -98.433],
                                  [36.617, -59.039],
                                  [-96.307, -38.384],
                                  [-16.281, -52.744],
                                  [-143.635, 16.442],
                                  [87.692, 70.16],
                                  [-26.273, 108.203],
                                  [-14.19, 92],
                                ],
                                o: [
                                  [-89.684, 29.305],
                                  [2.817, 98.433],
                                  [-36.617, 59.039],
                                  [96.307, 38.384],
                                  [20.751, 58.245],
                                  [108.561, -8.877],
                                  [-87.692, -70.159],
                                  [19.74, -114.049],
                                  [14.19, -92],
                                ],
                                v: [
                                  [-79.926, -349.918],
                                  [-184.703, -198.562],
                                  [-248.847, -3.485],
                                  [-245.24, 158.95],
                                  [-51.198, 293.145],
                                  [118.501, 364.725],
                                  [272.905, 237.003],
                                  [103.067, -62.184],
                                  [235.932, -173.949],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.544, -24.911],
                                  [-3.324, -99.007],
                                  [36.975, -58.687],
                                  [-96.944, -38.317],
                                  [-16.307, -53.038],
                                  [-143.152, 16.222],
                                  [88.553, 70.371],
                                  [-26.636, 108.933],
                                  [-14.095, 92],
                                ],
                                o: [
                                  [-89.842, 29.652],
                                  [3.324, 99.007],
                                  [-36.975, 58.687],
                                  [96.944, 38.317],
                                  [20.709, 58.456],
                                  [108.614, -8.772],
                                  [-88.553, -70.371],
                                  [20.202, -114.69],
                                  [14.095, -92],
                                ],
                                v: [
                                  [-80.669, -350.056],
                                  [-184.971, -197.965],
                                  [-248.221, -2.459],
                                  [-245.431, 159.254],
                                  [-49.568, 293.408],
                                  [117.358, 364.383],
                                  [272.52, 237.242],
                                  [101.938, -63.058],
                                  [237.367, -173.714],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.337, -25.326],
                                  [-3.825, -99.574],
                                  [37.327, -58.339],
                                  [-97.573, -38.251],
                                  [-16.333, -53.328],
                                  [-142.675, 16.004],
                                  [89.402, 70.58],
                                  [-26.994, 109.654],
                                  [-14.002, 92],
                                ],
                                o: [
                                  [-89.997, 29.994],
                                  [3.825, 99.574],
                                  [-37.327, 58.339],
                                  [97.573, 38.251],
                                  [20.667, 58.663],
                                  [108.666, -8.668],
                                  [-89.402, -70.58],
                                  [20.659, -115.322],
                                  [14.002, -92],
                                ],
                                v: [
                                  [-81.424, -350.177],
                                  [-185.207, -197.424],
                                  [-247.542, -1.404],
                                  [-245.605, 159.518],
                                  [-47.977, 293.64],
                                  [116.187, 364.119],
                                  [272.216, 237.504],
                                  [100.797, -63.853],
                                  [238.794, -173.472],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.132, -25.736],
                                  [-4.319, -100.133],
                                  [37.675, -57.996],
                                  [-98.193, -38.186],
                                  [-16.358, -53.615],
                                  [-142.204, 15.789],
                                  [90.24, 70.786],
                                  [-27.347, 110.366],
                                  [-13.909, 92],
                                ],
                                o: [
                                  [-90.151, 30.332],
                                  [4.319, 100.133],
                                  [-37.675, 57.996],
                                  [98.193, 38.186],
                                  [20.626, 58.868],
                                  [108.717, -8.566],
                                  [-90.24, -70.786],
                                  [21.109, -115.947],
                                  [13.909, -92],
                                ],
                                v: [
                                  [-82.212, -350.324],
                                  [-185.39, -196.898],
                                  [-246.893, -0.341],
                                  [-245.785, 159.785],
                                  [-46.384, 293.843],
                                  [115.048, 363.83],
                                  [271.933, 237.79],
                                  [99.623, -64.672],
                                  [240.171, -173.2],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.93, -26.14],
                                  [-4.806, -100.685],
                                  [38.019, -57.658],
                                  [-98.805, -38.121],
                                  [-16.384, -53.898],
                                  [-141.739, 15.577],
                                  [91.067, 70.989],
                                  [-27.695, 111.068],
                                  [-13.819, 92],
                                ],
                                o: [
                                  [-90.302, 30.665],
                                  [4.806, 100.684],
                                  [-38.019, 57.658],
                                  [98.805, 38.122],
                                  [20.586, 59.07],
                                  [108.767, -8.465],
                                  [-91.067, -70.989],
                                  [21.554, -116.563],
                                  [13.819, -92],
                                ],
                                v: [
                                  [-83.014, -350.477],
                                  [-185.561, -196.365],
                                  [-246.275, 0.773],
                                  [-245.969, 160.054],
                                  [-44.829, 294.038],
                                  [113.859, 363.557],
                                  [271.669, 238.038],
                                  [98.52, -65.454],
                                  [241.582, -172.902],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.731, -26.538],
                                  [-5.287, -101.229],
                                  [38.358, -57.324],
                                  [-99.41, -38.058],
                                  [-16.409, -54.177],
                                  [-141.281, 15.367],
                                  [91.883, 71.19],
                                  [-28.039, 111.76],
                                  [-13.729, 92],
                                ],
                                o: [
                                  [-90.452, 30.994],
                                  [5.287, 101.229],
                                  [-38.358, 57.324],
                                  [99.41, 38.058],
                                  [20.546, 59.269],
                                  [108.817, -8.365],
                                  [-91.883, -71.19],
                                  [21.992, -117.171],
                                  [13.729, -92],
                                ],
                                v: [
                                  [-83.808, -350.617],
                                  [-185.72, -195.845],
                                  [-245.648, 1.895],
                                  [-246.2, 160.301],
                                  [-43.292, 294.247],
                                  [112.704, 363.298],
                                  [271.469, 238.353],
                                  [97.404, -66.18],
                                  [242.962, -172.66],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.534, -26.932],
                                  [-5.761, -101.766],
                                  [38.692, -56.995],
                                  [-100.006, -37.995],
                                  [-16.433, -54.452],
                                  [-140.828, 15.161],
                                  [92.689, 71.388],
                                  [-28.379, 112.444],
                                  [-13.64, 92],
                                ],
                                o: [
                                  [-90.599, 31.319],
                                  [5.761, 101.766],
                                  [-38.692, 56.995],
                                  [100.006, 37.995],
                                  [20.507, 59.466],
                                  [108.866, -8.267],
                                  [-92.688, -71.388],
                                  [22.425, -117.771],
                                  [13.64, -92],
                                ],
                                v: [
                                  [-84.657, -350.768],
                                  [-185.844, -195.295],
                                  [-245.054, 3.026],
                                  [-246.374, 160.527],
                                  [-41.771, 294.45],
                                  [111.517, 363.077],
                                  [271.353, 238.61],
                                  [96.296, -66.89],
                                  [244.313, -172.392],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.34, -27.32],
                                  [-6.23, -102.296],
                                  [39.022, -56.669],
                                  [-100.594, -37.933],
                                  [-16.457, -54.724],
                                  [-140.382, 14.957],
                                  [93.483, 71.583],
                                  [-28.714, 113.118],
                                  [-13.553, 92],
                                ],
                                o: [
                                  [-90.745, 31.639],
                                  [6.23, 102.296],
                                  [-39.022, 56.669],
                                  [100.594, 37.933],
                                  [20.468, 59.66],
                                  [108.915, -8.17],
                                  [-93.483, -71.583],
                                  [22.852, -118.363],
                                  [13.553, -92],
                                ],
                                v: [
                                  [-85.479, -350.949],
                                  [-185.933, -194.801],
                                  [-244.451, 4.165],
                                  [-246.572, 160.732],
                                  [-40.33, 294.627],
                                  [110.303, 362.87],
                                  [271.259, 238.913],
                                  [95.216, -67.545],
                                  [245.632, -172.143],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.148, -27.703],
                                  [-6.692, -102.819],
                                  [39.348, -56.349],
                                  [-101.175, -37.872],
                                  [-16.481, -54.992],
                                  [-139.942, 14.756],
                                  [94.267, 71.776],
                                  [-29.044, 113.784],
                                  [-13.467, 92],
                                ],
                                o: [
                                  [-90.889, 31.955],
                                  [6.692, 102.819],
                                  [-39.348, 56.349],
                                  [101.175, 37.872],
                                  [20.43, 59.851],
                                  [108.963, -8.074],
                                  [-94.267, -71.776],
                                  [23.273, -118.947],
                                  [13.467, -92],
                                ],
                                v: [
                                  [-86.357, -351.12],
                                  [-186.029, -194.276],
                                  [-243.882, 5.336],
                                  [-246.732, 160.912],
                                  [-38.862, 294.779],
                                  [109.099, 362.658],
                                  [271.19, 239.202],
                                  [94.145, -68.185],
                                  [246.984, -171.889],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.96, -28.081],
                                  [-7.148, -103.336],
                                  [39.669, -56.032],
                                  [-101.748, -37.812],
                                  [-16.505, -55.257],
                                  [-139.507, 14.557],
                                  [95.041, 71.966],
                                  [-29.37, 114.441],
                                  [-13.382, 92],
                                ],
                                o: [
                                  [-91.03, 32.267],
                                  [7.148, 103.335],
                                  [-39.669, 56.032],
                                  [101.748, 37.812],
                                  [20.392, 60.04],
                                  [109.01, -7.98],
                                  [-95.041, -71.966],
                                  [23.689, -119.523],
                                  [13.382, -92],
                                ],
                                v: [
                                  [-87.228, -351.33],
                                  [-186.088, -193.738],
                                  [-243.35, 6.517],
                                  [-246.92, 161.11],
                                  [-37.454, 294.97],
                                  [107.886, 362.503],
                                  [271.185, 239.521],
                                  [93.102, -68.816],
                                  [248.284, -171.674],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.773, -28.454],
                                  [-7.597, -103.845],
                                  [39.986, -55.72],
                                  [-102.313, -37.753],
                                  [-16.528, -55.518],
                                  [-139.078, 14.362],
                                  [95.804, 72.153],
                                  [-29.691, 115.088],
                                  [-13.298, 92],
                                ],
                                o: [
                                  [-91.17, 32.574],
                                  [7.597, 103.845],
                                  [-39.986, 55.72],
                                  [102.313, 37.753],
                                  [20.355, 60.227],
                                  [109.057, -7.887],
                                  [-95.804, -72.153],
                                  [24.099, -120.092],
                                  [13.298, -92],
                                ],
                                v: [
                                  [-88.137, -351.549],
                                  [-186.09, -193.254],
                                  [-242.854, 7.724],
                                  [-247.131, 161.244],
                                  [-36.037, 295.156],
                                  [106.665, 362.343],
                                  [271.27, 239.821],
                                  [92.042, -69.37],
                                  [249.593, -171.456],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.589, -28.822],
                                  [-8.041, -104.347],
                                  [40.298, -55.412],
                                  [-102.87, -37.694],
                                  [-16.551, -55.775],
                                  [-138.655, 14.169],
                                  [96.557, 72.338],
                                  [-30.009, 115.728],
                                  [-13.215, 92],
                                ],
                                o: [
                                  [-91.308, 32.878],
                                  [8.041, 104.347],
                                  [-40.298, 55.412],
                                  [102.87, 37.694],
                                  [20.318, 60.411],
                                  [109.103, -7.795],
                                  [-96.557, -72.338],
                                  [24.504, -120.653],
                                  [13.215, -92],
                                ],
                                v: [
                                  [-89.04, -351.786],
                                  [-186.072, -192.755],
                                  [-242.352, 8.922],
                                  [-247.284, 161.35],
                                  [-34.679, 295.301],
                                  [105.435, 362.24],
                                  [271.358, 240.134],
                                  [90.991, -69.935],
                                  [250.917, -171.279],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.408, -29.185],
                                  [-8.479, -104.843],
                                  [40.607, -55.108],
                                  [-103.42, -37.636],
                                  [-16.574, -56.029],
                                  [-138.238, 13.978],
                                  [97.3, 72.521],
                                  [-30.322, 116.358],
                                  [-13.133, 92],
                                ],
                                o: [
                                  [-91.444, 33.177],
                                  [8.479, 104.843],
                                  [-40.607, 55.108],
                                  [103.42, 37.636],
                                  [20.282, 60.592],
                                  [109.148, -7.704],
                                  [-97.3, -72.521],
                                  [24.903, -121.207],
                                  [13.133, -92],
                                ],
                                v: [
                                  [-89.982, -352.06],
                                  [-186.041, -192.221],
                                  [-241.888, 10.128],
                                  [-247.504, 161.47],
                                  [-33.354, 295.488],
                                  [104.215, 362.105],
                                  [271.494, 240.497],
                                  [89.967, -70.469],
                                  [252.251, -171.126],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.229, -29.543],
                                  [-8.911, -105.332],
                                  [40.911, -54.808],
                                  [-103.963, -37.579],
                                  [-16.596, -56.28],
                                  [-137.826, 13.79],
                                  [98.033, 72.701],
                                  [-30.631, 116.98],
                                  [-13.053, 92],
                                ],
                                o: [
                                  [-91.578, 33.473],
                                  [8.911, 105.332],
                                  [-40.911, 54.808],
                                  [103.963, 37.579],
                                  [20.246, 60.771],
                                  [109.193, -7.614],
                                  [-98.033, -72.701],
                                  [25.297, -121.753],
                                  [13.053, -92],
                                ],
                                v: [
                                  [-90.963, -352.355],
                                  [-185.97, -191.696],
                                  [-241.481, 11.342],
                                  [-247.682, 161.57],
                                  [-32.044, 295.672],
                                  [102.987, 362.071],
                                  [271.679, 240.913],
                                  [88.952, -70.91],
                                  [253.558, -171.015],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.052, -29.896],
                                  [-9.337, -105.814],
                                  [41.212, -54.512],
                                  [-104.498, -37.523],
                                  [-16.618, -56.527],
                                  [-137.42, 13.605],
                                  [98.756, 72.879],
                                  [-30.935, 117.594],
                                  [-12.973, 92],
                                ],
                                o: [
                                  [-91.711, 33.764],
                                  [9.337, 105.814],
                                  [-41.212, 54.512],
                                  [104.498, 37.523],
                                  [20.21, 60.948],
                                  [109.237, -7.526],
                                  [-98.756, -72.879],
                                  [25.686, -122.291],
                                  [12.973, -92],
                                ],
                                v: [
                                  [-91.956, -352.643],
                                  [-185.841, -191.18],
                                  [-241.097, 12.609],
                                  [-247.847, 161.639],
                                  [-30.793, 295.836],
                                  [101.795, 362.004],
                                  [271.868, 241.319],
                                  [87.918, -71.365],
                                  [254.874, -170.903],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.878, -30.244],
                                  [-9.757, -106.29],
                                  [41.508, -54.22],
                                  [-105.027, -37.467],
                                  [-16.64, -56.771],
                                  [-137.019, 13.422],
                                  [99.469, 73.054],
                                  [-31.236, 118.2],
                                  [-12.895, 92],
                                ],
                                o: [
                                  [-91.842, 34.052],
                                  [9.757, 106.29],
                                  [-41.508, 54.22],
                                  [105.027, 37.467],
                                  [20.176, 61.122],
                                  [109.281, -7.439],
                                  [-99.469, -73.054],
                                  [26.069, -122.823],
                                  [12.895, -92],
                                ],
                                v: [
                                  [-92.973, -353],
                                  [-185.733, -190.661],
                                  [-240.753, 13.838],
                                  [-248.032, 161.675],
                                  [-29.62, 295.936],
                                  [100.567, 361.976],
                                  [272.089, 241.806],
                                  [86.91, -71.836],
                                  [256.226, -170.836],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.706, -30.588],
                                  [-10.172, -106.759],
                                  [41.8, -53.932],
                                  [-105.548, -37.413],
                                  [-16.662, -57.012],
                                  [-136.624, 13.241],
                                  [100.172, 73.227],
                                  [-31.532, 118.797],
                                  [-12.818, 92],
                                ],
                                o: [
                                  [-91.971, 34.335],
                                  [10.172, 106.759],
                                  [-41.8, 53.932],
                                  [105.548, 37.413],
                                  [20.141, 61.294],
                                  [109.323, -7.353],
                                  [-100.172, -73.227],
                                  [26.447, -123.347],
                                  [12.818, -92],
                                ],
                                v: [
                                  [-94.002, -353.415],
                                  [-185.566, -190.132],
                                  [-240.452, 15.075],
                                  [-248.176, 161.69],
                                  [-28.506, 296.033],
                                  [99.377, 361.958],
                                  [272.333, 242.303],
                                  [85.91, -72.216],
                                  [257.54, -170.796],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.536, -30.927],
                                  [-10.581, -107.222],
                                  [42.088, -53.649],
                                  [-106.061, -37.358],
                                  [-16.683, -57.249],
                                  [-136.234, 13.063],
                                  [100.867, 73.398],
                                  [-31.825, 119.386],
                                  [-12.741, 92],
                                ],
                                o: [
                                  [-92.098, 34.615],
                                  [10.581, 107.222],
                                  [-42.088, 53.648],
                                  [106.061, 37.358],
                                  [20.107, 61.464],
                                  [109.366, -7.268],
                                  [-100.867, -73.398],
                                  [26.82, -123.864],
                                  [12.741, -92],
                                ],
                                v: [
                                  [-95.056, -353.855],
                                  [-185.374, -189.626],
                                  [-240.148, 16.303],
                                  [-248.351, 161.717],
                                  [-27.471, 296.066],
                                  [98.195, 361.997],
                                  [272.672, 242.854],
                                  [84.936, -72.569],
                                  [258.875, -170.82],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.369, -31.262],
                                  [-10.984, -107.679],
                                  [42.372, -53.368],
                                  [-106.568, -37.305],
                                  [-16.704, -57.483],
                                  [-135.849, 12.888],
                                  [101.551, 73.566],
                                  [-32.113, 119.967],
                                  [-12.666, 92],
                                ],
                                o: [
                                  [-92.223, 34.891],
                                  [10.984, 107.679],
                                  [-42.372, 53.368],
                                  [106.568, 37.305],
                                  [20.074, 61.631],
                                  [109.408, -7.185],
                                  [-101.551, -73.566],
                                  [27.188, -124.374],
                                  [12.666, -92],
                                ],
                                v: [
                                  [-96.123, -354.277],
                                  [-185.121, -189.097],
                                  [-239.856, 17.554],
                                  [-248.5, 161.709],
                                  [-26.48, 296.051],
                                  [97.005, 362.046],
                                  [272.955, 243.43],
                                  [83.987, -72.939],
                                  [260.201, -170.827],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.204, -31.592],
                                  [-11.382, -108.13],
                                  [42.653, -53.092],
                                  [-107.068, -37.253],
                                  [-16.724, -57.714],
                                  [-135.47, 12.714],
                                  [102.226, 73.732],
                                  [-32.398, 120.54],
                                  [-12.592, 92],
                                ],
                                o: [
                                  [-92.347, 35.163],
                                  [11.382, 108.13],
                                  [-42.653, 53.092],
                                  [107.068, 37.253],
                                  [20.041, 61.796],
                                  [109.449, -7.102],
                                  [-102.226, -73.732],
                                  [27.551, -124.877],
                                  [12.592, -92],
                                ],
                                v: [
                                  [-97.232, -354.758],
                                  [-184.871, -188.574],
                                  [-239.592, 18.766],
                                  [-248.667, 161.68],
                                  [-25.614, 296.016],
                                  [95.822, 362.15],
                                  [273.291, 244.015],
                                  [83, -73.221],
                                  [261.488, -170.85],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.042, -31.917],
                                  [-11.775, -108.574],
                                  [42.929, -52.82],
                                  [-107.562, -37.201],
                                  [-16.745, -57.942],
                                  [-135.096, 12.544],
                                  [102.892, 73.896],
                                  [-32.678, 121.106],
                                  [-12.519, 92],
                                ],
                                o: [
                                  [-92.469, 35.431],
                                  [11.775, 108.574],
                                  [-42.929, 52.82],
                                  [107.562, 37.201],
                                  [20.008, 61.958],
                                  [109.49, -7.021],
                                  [-102.892, -73.896],
                                  [27.909, -125.373],
                                  [12.519, -92],
                                ],
                                v: [
                                  [-98.321, -355.221],
                                  [-184.576, -188.072],
                                  [-239.388, 19.984],
                                  [-248.821, 161.614],
                                  [-24.763, 295.933],
                                  [94.678, 362.264],
                                  [273.678, 244.612],
                                  [82.038, -73.523],
                                  [262.812, -170.952],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.881, -32.238],
                                  [-12.162, -109.012],
                                  [43.202, -52.551],
                                  [-108.048, -37.15],
                                  [-16.765, -58.167],
                                  [-134.727, 12.375],
                                  [103.549, 74.057],
                                  [-32.955, 121.663],
                                  [-12.446, 92],
                                ],
                                o: [
                                  [-92.589, 35.696],
                                  [12.162, 109.012],
                                  [-43.202, 52.551],
                                  [108.048, 37.15],
                                  [19.976, 62.119],
                                  [109.53, -6.941],
                                  [-103.549, -74.057],
                                  [28.262, -125.863],
                                  [12.447, -92],
                                ],
                                v: [
                                  [-99.423, -355.682],
                                  [-184.268, -187.528],
                                  [-239.134, 21.16],
                                  [-248.977, 161.558],
                                  [-23.976, 295.895],
                                  [93.526, 362.386],
                                  [274.026, 245.298],
                                  [81.1, -73.785],
                                  [264.128, -171.008],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.723, -32.554],
                                  [-12.544, -109.445],
                                  [43.471, -52.286],
                                  [-108.528, -37.099],
                                  [-16.785, -58.388],
                                  [-134.362, 12.209],
                                  [104.197, 74.216],
                                  [-33.228, 122.213],
                                  [-12.375, 92],
                                ],
                                o: [
                                  [-92.708, 35.957],
                                  [12.544, 109.445],
                                  [-43.471, 52.286],
                                  [108.528, 37.099],
                                  [19.945, 62.277],
                                  [109.569, -6.861],
                                  [-104.197, -74.216],
                                  [28.61, -126.345],
                                  [12.375, -92],
                                ],
                                v: [
                                  [-100.521, -356.125],
                                  [-183.93, -187.003],
                                  [-238.972, 22.295],
                                  [-249.151, 161.481],
                                  [-23.267, 295.744],
                                  [92.382, 362.563],
                                  [274.458, 245.932],
                                  [80.138, -74.022],
                                  [265.482, -171.175],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.567, -32.867],
                                  [-12.92, -109.871],
                                  [43.737, -52.024],
                                  [-109.001, -37.049],
                                  [-16.804, -58.607],
                                  [-134.003, 12.045],
                                  [104.836, 74.373],
                                  [-33.497, 122.756],
                                  [-12.305, 92],
                                ],
                                o: [
                                  [-92.825, 36.215],
                                  [12.92, 109.871],
                                  [-43.737, 52.024],
                                  [109.001, 37.049],
                                  [19.913, 62.433],
                                  [109.608, -6.783],
                                  [-104.836, -74.373],
                                  [28.953, -126.821],
                                  [12.305, -92],
                                ],
                                v: [
                                  [-101.6, -356.52],
                                  [-183.53, -186.45],
                                  [-238.776, 23.449],
                                  [-249.279, 161.365],
                                  [-22.653, 295.639],
                                  [91.245, 362.748],
                                  [274.85, 246.626],
                                  [79.247, -74.22],
                                  [266.781, -171.328],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.413, -33.174],
                                  [-13.292, -110.291],
                                  [43.998, -51.766],
                                  [-109.467, -37],
                                  [-16.823, -58.822],
                                  [-133.649, 11.883],
                                  [105.466, 74.528],
                                  [-33.763, 123.291],
                                  [-12.236, 92],
                                ],
                                o: [
                                  [-92.94, 36.469],
                                  [13.292, 110.291],
                                  [-43.998, 51.766],
                                  [109.467, 37],
                                  [19.883, 62.587],
                                  [109.647, -6.706],
                                  [-105.466, -74.528],
                                  [29.292, -127.291],
                                  [12.236, -92],
                                ],
                                v: [
                                  [-102.724, -356.96],
                                  [-183.131, -185.947],
                                  [-238.625, 24.592],
                                  [-249.408, 161.242],
                                  [-22.072, 295.484],
                                  [90.147, 362.987],
                                  [275.297, 247.317],
                                  [78.345, -74.395],
                                  [268.072, -171.483],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.261, -33.478],
                                  [-13.658, -110.706],
                                  [44.256, -51.512],
                                  [-109.928, -36.952],
                                  [-16.842, -59.035],
                                  [-133.3, 11.724],
                                  [106.088, 74.681],
                                  [-34.025, 123.818],
                                  [-12.167, 92],
                                ],
                                o: [
                                  [-93.054, 36.719],
                                  [13.658, 110.706],
                                  [-44.256, 51.512],
                                  [109.928, 36.952],
                                  [19.852, 62.739],
                                  [109.685, -6.63],
                                  [-106.088, -74.681],
                                  [29.626, -127.754],
                                  [12.167, -92],
                                ],
                                v: [
                                  [-103.811, -357.351],
                                  [-182.686, -185.413],
                                  [-238.506, 25.644],
                                  [-249.539, 161.113],
                                  [-21.554, 295.263],
                                  [89.105, 363.22],
                                  [275.75, 248.068],
                                  [77.418, -74.596],
                                  [269.354, -171.702],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.111, -33.777],
                                  [-14.019, -111.115],
                                  [44.511, -51.261],
                                  [-110.381, -36.904],
                                  [-16.861, -59.244],
                                  [-132.956, 11.567],
                                  [106.701, 74.832],
                                  [-34.283, 124.338],
                                  [-12.1, 92],
                                ],
                                o: [
                                  [-93.167, 36.966],
                                  [14.019, 111.115],
                                  [-44.511, 51.261],
                                  [110.381, 36.904],
                                  [19.822, 62.889],
                                  [109.722, -6.556],
                                  [-106.701, -74.832],
                                  [29.955, -128.211],
                                  [12.1, -92],
                                ],
                                v: [
                                  [-104.881, -357.787],
                                  [-182.241, -184.848],
                                  [-238.414, 26.713],
                                  [-249.623, 160.992],
                                  [-21.116, 295.089],
                                  [88.006, 363.459],
                                  [276.245, 248.816],
                                  [76.559, -74.76],
                                  [270.675, -171.956],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.964, -34.073],
                                  [-14.375, -111.518],
                                  [44.762, -51.014],
                                  [-110.829, -36.857],
                                  [-16.88, -59.451],
                                  [-132.616, 11.412],
                                  [107.305, 74.98],
                                  [-34.538, 124.851],
                                  [-12.034, 92],
                                ],
                                o: [
                                  [-93.277, 37.21],
                                  [14.375, 111.518],
                                  [-44.762, 51.014],
                                  [110.829, 36.857],
                                  [19.793, 63.036],
                                  [109.759, -6.482],
                                  [-107.305, -74.98],
                                  [30.28, -128.661],
                                  [12.034, -92],
                                ],
                                v: [
                                  [-105.947, -358.16],
                                  [-181.748, -184.298],
                                  [-238.305, 27.77],
                                  [-249.742, 160.816],
                                  [-20.679, 294.864],
                                  [87.011, 363.753],
                                  [276.698, 249.578],
                                  [75.673, -74.854],
                                  [271.941, -172.228],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.818, -34.364],
                                  [-14.726, -111.916],
                                  [45.009, -50.77],
                                  [-111.27, -36.811],
                                  [-16.898, -59.655],
                                  [-132.282, 11.259],
                                  [107.901, 75.127],
                                  [-34.789, 125.357],
                                  [-11.968, 92],
                                ],
                                o: [
                                  [-93.386, 37.45],
                                  [14.726, 111.916],
                                  [-45.009, 50.77],
                                  [111.27, 36.811],
                                  [19.764, 63.182],
                                  [109.795, -6.409],
                                  [-107.901, -75.127],
                                  [30.6, -129.105],
                                  [11.968, -92],
                                ],
                                v: [
                                  [-106.996, -358.53],
                                  [-181.256, -183.75],
                                  [-238.244, 28.795],
                                  [-249.801, 160.618],
                                  [-20.371, 294.623],
                                  [85.96, 364.039],
                                  [277.208, 250.337],
                                  [74.807, -74.963],
                                  [273.261, -172.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.675, -34.651],
                                  [-15.073, -112.308],
                                  [45.253, -50.53],
                                  [-111.705, -36.765],
                                  [-16.916, -59.856],
                                  [-131.952, 11.108],
                                  [108.488, 75.271],
                                  [-35.036, 125.856],
                                  [-11.904, 92],
                                ],
                                o: [
                                  [-93.494, 37.687],
                                  [15.073, 112.308],
                                  [-45.253, 50.53],
                                  [111.705, 36.765],
                                  [19.735, 63.325],
                                  [109.831, -6.337],
                                  [-108.488, -75.271],
                                  [30.916, -129.543],
                                  [11.904, -92],
                                ],
                                v: [
                                  [-108.056, -358.899],
                                  [-180.749, -183.215],
                                  [-238.196, 29.774],
                                  [-249.861, 160.429],
                                  [-20.078, 294.38],
                                  [84.951, 364.367],
                                  [277.711, 251.096],
                                  [73.975, -75.1],
                                  [274.51, -172.824],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.533, -34.934],
                                  [-15.414, -112.694],
                                  [45.494, -50.293],
                                  [-112.134, -36.72],
                                  [-16.933, -60.054],
                                  [-131.626, 10.96],
                                  [109.068, 75.414],
                                  [-35.28, 126.348],
                                  [-11.84, 92],
                                ],
                                o: [
                                  [-93.6, 37.92],
                                  [15.414, 112.694],
                                  [-45.494, 50.293],
                                  [112.134, 36.72],
                                  [19.707, 63.467],
                                  [109.867, -6.267],
                                  [-109.068, -75.414],
                                  [31.227, -129.974],
                                  [11.84, -92],
                                ],
                                v: [
                                  [-109.05, -359.253],
                                  [-180.145, -182.668],
                                  [-238.195, 30.753],
                                  [-249.896, 160.218],
                                  [-19.865, 294.135],
                                  [83.997, 364.701],
                                  [278.223, 251.867],
                                  [73.162, -75.169],
                                  [275.8, -173.199],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.393, -35.213],
                                  [-15.751, -113.075],
                                  [45.731, -50.059],
                                  [-112.557, -36.675],
                                  [-16.951, -60.249],
                                  [-131.305, 10.813],
                                  [109.639, 75.554],
                                  [-35.521, 126.833],
                                  [-11.777, 92],
                                ],
                                o: [
                                  [-93.705, 38.151],
                                  [15.751, 113.075],
                                  [-45.731, 50.059],
                                  [112.557, 36.675],
                                  [19.679, 63.607],
                                  [109.902, -6.197],
                                  [-109.639, -75.554],
                                  [31.534, -130.4],
                                  [11.777, -92],
                                ],
                                v: [
                                  [-110.104, -359.619],
                                  [-179.603, -182.085],
                                  [-238.193, 31.636],
                                  [-249.871, 160.002],
                                  [-19.704, 293.838],
                                  [83.037, 365.042],
                                  [278.742, 252.699],
                                  [72.319, -75.205],
                                  [277.095, -173.576],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.256, -35.488],
                                  [-16.083, -113.451],
                                  [45.965, -49.829],
                                  [-112.974, -36.632],
                                  [-16.968, -60.442],
                                  [-130.989, 10.669],
                                  [110.202, 75.692],
                                  [-35.759, 127.311],
                                  [-11.715, 92],
                                ],
                                o: [
                                  [-93.808, 38.378],
                                  [16.083, 113.451],
                                  [-45.965, 49.829],
                                  [112.974, 36.632],
                                  [19.651, 63.744],
                                  [109.936, -6.128],
                                  [-110.202, -75.693],
                                  [31.837, -130.819],
                                  [11.715, -92],
                                ],
                                v: [
                                  [-111.091, -359.97],
                                  [-178.998, -181.515],
                                  [-238.203, 32.568],
                                  [-249.848, 159.743],
                                  [-19.623, 293.589],
                                  [82.133, 365.424],
                                  [279.305, 253.482],
                                  [71.544, -75.26],
                                  [278.321, -173.969],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.12, -35.759],
                                  [-16.41, -113.821],
                                  [46.196, -49.602],
                                  [-113.385, -36.588],
                                  [-16.985, -60.632],
                                  [-130.677, 10.526],
                                  [110.758, 75.829],
                                  [-35.993, 127.782],
                                  [-11.654, 92],
                                ],
                                o: [
                                  [-93.91, 38.602],
                                  [16.41, 113.821],
                                  [-46.196, 49.601],
                                  [113.385, 36.588],
                                  [19.624, 63.88],
                                  [109.97, -6.06],
                                  [-110.758, -75.829],
                                  [32.135, -131.233],
                                  [11.654, -92],
                                ],
                                v: [
                                  [-112.075, -360.332],
                                  [-178.393, -180.933],
                                  [-238.249, 33.401],
                                  [-249.816, 159.515],
                                  [-19.558, 293.288],
                                  [81.222, 365.799],
                                  [279.827, 254.277],
                                  [70.75, -75.297],
                                  [279.588, -174.414],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.987, -36.027],
                                  [-16.732, -114.187],
                                  [46.423, -49.377],
                                  [-113.791, -36.546],
                                  [-17.002, -60.819],
                                  [-130.369, 10.386],
                                  [111.305, 75.964],
                                  [-36.223, 128.247],
                                  [-11.594, 92],
                                ],
                                o: [
                                  [-94.01, 38.822],
                                  [16.732, 114.187],
                                  [-46.423, 49.377],
                                  [113.791, 36.546],
                                  [19.597, 64.013],
                                  [110.003, -5.993],
                                  [-111.305, -75.964],
                                  [32.43, -131.641],
                                  [11.594, -92],
                                ],
                                v: [
                                  [-112.994, -360.68],
                                  [-177.737, -180.35],
                                  [-238.307, 34.234],
                                  [-249.786, 159.23],
                                  [-19.571, 293.022],
                                  [80.355, 366.218],
                                  [280.394, 255.071],
                                  [69.923, -75.317],
                                  [280.81, -174.86],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.855, -36.291],
                                  [-17.05, -114.547],
                                  [46.647, -49.157],
                                  [-114.19, -36.504],
                                  [-17.018, -61.003],
                                  [-130.066, 10.247],
                                  [111.845, 76.096],
                                  [-36.451, 128.705],
                                  [-11.535, 92],
                                ],
                                o: [
                                  [-94.109, 39.04],
                                  [17.05, 114.547],
                                  [-46.647, 49.157],
                                  [114.19, 36.504],
                                  [19.571, 64.145],
                                  [110.036, -5.927],
                                  [-111.845, -76.096],
                                  [32.72, -132.043],
                                  [11.535, -92],
                                ],
                                v: [
                                  [-113.971, -360.977],
                                  [-177.093, -179.73],
                                  [-238.414, 35.029],
                                  [-249.746, 158.99],
                                  [-19.589, 292.705],
                                  [79.546, 366.643],
                                  [280.932, 255.878],
                                  [69.164, -75.308],
                                  [282.062, -175.359],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.725, -36.551],
                                  [-17.364, -114.902],
                                  [46.868, -48.939],
                                  [-114.584, -36.462],
                                  [-17.034, -61.185],
                                  [-129.767, 10.111],
                                  [112.377, 76.227],
                                  [-36.675, 129.156],
                                  [-11.476, 92],
                                ],
                                o: [
                                  [-94.206, 39.254],
                                  [17.364, 114.902],
                                  [-46.868, 48.939],
                                  [114.584, 36.462],
                                  [19.545, 64.275],
                                  [110.069, -5.862],
                                  [-112.377, -76.227],
                                  [33.006, -132.44],
                                  [11.476, -92],
                                ],
                                v: [
                                  [-114.883, -361.333],
                                  [-176.435, -179.159],
                                  [-238.521, 35.759],
                                  [-249.647, 158.681],
                                  [-19.735, 292.398],
                                  [78.73, 367.061],
                                  [281.504, 256.684],
                                  [68.37, -75.27],
                                  [283.307, -175.872],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.597, -36.807],
                                  [-17.673, -115.252],
                                  [47.086, -48.724],
                                  [-114.973, -36.421],
                                  [-17.05, -61.365],
                                  [-129.472, 9.977],
                                  [112.901, 76.356],
                                  [-36.896, 129.602],
                                  [-11.418, 92],
                                ],
                                o: [
                                  [-94.303, 39.466],
                                  [17.673, 115.252],
                                  [-47.086, 48.724],
                                  [114.973, 36.421],
                                  [19.519, 64.403],
                                  [110.101, -5.798],
                                  [-112.901, -76.356],
                                  [33.287, -132.83],
                                  [11.419, -92],
                                ],
                                v: [
                                  [-115.74, -361.626],
                                  [-175.776, -178.538],
                                  [-238.678, 36.501],
                                  [-249.602, 158.43],
                                  [-19.847, 292.076],
                                  [77.972, 367.523],
                                  [282.033, 257.503],
                                  [67.656, -75.268],
                                  [284.494, -176.438],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.47, -37.059],
                                  [-17.978, -115.597],
                                  [47.3, -48.513],
                                  [-115.356, -36.381],
                                  [-17.066, -61.542],
                                  [-129.182, 9.844],
                                  [113.418, 76.483],
                                  [-37.114, 130.041],
                                  [-11.362, 92],
                                ],
                                o: [
                                  [-94.397, 39.674],
                                  [17.978, 115.597],
                                  [-47.3, 48.513],
                                  [115.356, 36.381],
                                  [19.494, 64.53],
                                  [110.132, -5.735],
                                  [-113.418, -76.483],
                                  [33.565, -133.215],
                                  [11.362, -92],
                                ],
                                v: [
                                  [-116.645, -361.916],
                                  [-175.078, -177.916],
                                  [-238.796, 37.19],
                                  [-249.447, 158.111],
                                  [-20.076, 291.815],
                                  [77.259, 367.991],
                                  [282.571, 258.282],
                                  [66.895, -75.25],
                                  [285.724, -177.018],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.346, -37.308],
                                  [-18.278, -115.937],
                                  [47.512, -48.304],
                                  [-115.733, -36.341],
                                  [-17.082, -61.716],
                                  [-128.895, 9.713],
                                  [113.928, 76.608],
                                  [-37.328, 130.473],
                                  [-11.306, 92],
                                ],
                                o: [
                                  [-94.491, 39.879],
                                  [18.278, 115.937],
                                  [-47.512, 48.304],
                                  [115.733, 36.341],
                                  [19.469, 64.654],
                                  [110.164, -5.673],
                                  [-113.928, -76.608],
                                  [33.839, -133.595],
                                  [11.306, -92],
                                ],
                                v: [
                                  [-117.445, -362.217],
                                  [-174.377, -177.294],
                                  [-239.015, 37.827],
                                  [-249.346, 157.837],
                                  [-20.282, 291.49],
                                  [76.541, 368.441],
                                  [283.156, 259.061],
                                  [66.122, -75.207],
                                  [286.947, -177.639],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.223, -37.554],
                                  [-18.574, -116.272],
                                  [47.721, -48.099],
                                  [-116.105, -36.302],
                                  [-17.097, -61.888],
                                  [-128.613, 9.584],
                                  [114.43, 76.732],
                                  [-37.54, 130.9],
                                  [-11.25, 92],
                                ],
                                o: [
                                  [-94.583, 40.082],
                                  [18.574, 116.272],
                                  [-47.721, 48.099],
                                  [116.105, 36.302],
                                  [19.445, 64.777],
                                  [110.194, -5.612],
                                  [-114.43, -76.732],
                                  [34.109, -133.969],
                                  [11.25, -92],
                                ],
                                v: [
                                  [-118.28, -362.503],
                                  [-173.675, -176.671],
                                  [-239.183, 38.46],
                                  [-249.247, 157.559],
                                  [-20.594, 291.175],
                                  [75.882, 368.948],
                                  [283.698, 259.852],
                                  [65.403, -75.138],
                                  [288.112, -178.286],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.102, -37.796],
                                  [-18.866, -116.602],
                                  [47.926, -47.896],
                                  [-116.471, -36.264],
                                  [-17.112, -62.057],
                                  [-128.335, 9.457],
                                  [114.925, 76.853],
                                  [-37.749, 131.32],
                                  [-11.196, 92],
                                ],
                                o: [
                                  [-94.673, 40.281],
                                  [18.866, 116.602],
                                  [-47.926, 47.896],
                                  [116.471, 36.264],
                                  [19.42, 64.898],
                                  [110.224, -5.551],
                                  [-114.925, -76.853],
                                  [34.375, -134.338],
                                  [11.196, -92],
                                ],
                                v: [
                                  [-119.06, -362.788],
                                  [-172.972, -176.059],
                                  [-239.402, 39.04],
                                  [-249.09, 157.265],
                                  [-20.881, 290.846],
                                  [75.269, 369.45],
                                  [284.238, 260.653],
                                  [64.699, -75.056],
                                  [289.321, -178.974],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.983, -38.034],
                                  [-19.154, -116.928],
                                  [48.129, -47.697],
                                  [-116.833, -36.226],
                                  [-17.127, -62.224],
                                  [-128.061, 9.332],
                                  [115.413, 76.973],
                                  [-37.954, 131.734],
                                  [-11.142, 92],
                                ],
                                o: [
                                  [-94.763, 40.478],
                                  [19.154, 116.928],
                                  [-48.129, 47.697],
                                  [116.833, 36.226],
                                  [19.397, 65.017],
                                  [110.254, -5.492],
                                  [-115.413, -76.973],
                                  [34.637, -134.702],
                                  [11.142, -92],
                                ],
                                v: [
                                  [-119.837, -363.081],
                                  [-172.267, -175.446],
                                  [-239.672, 39.628],
                                  [-248.935, 156.977],
                                  [-21.287, 290.577],
                                  [74.652, 369.997],
                                  [284.837, 261.403],
                                  [63.969, -75.011],
                                  [290.512, -179.675],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.865, -38.269],
                                  [-19.437, -117.249],
                                  [48.329, -47.5],
                                  [-117.189, -36.188],
                                  [-17.142, -62.388],
                                  [-127.791, 9.209],
                                  [115.894, 77.092],
                                  [-38.157, 132.142],
                                  [-11.089, 92],
                                ],
                                o: [
                                  [-94.851, 40.672],
                                  [19.437, 117.249],
                                  [-48.329, 47.5],
                                  [117.189, 36.188],
                                  [19.373, 65.134],
                                  [110.284, -5.433],
                                  [-115.894, -77.092],
                                  [34.896, -135.06],
                                  [11.089, -92],
                                ],
                                v: [
                                  [-120.559, -363.372],
                                  [-171.571, -174.822],
                                  [-239.902, 40.15],
                                  [-248.784, 156.675],
                                  [-21.668, 290.255],
                                  [74.145, 370.54],
                                  [285.393, 262.163],
                                  [63.253, -74.944],
                                  [291.656, -180.389],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.75, -38.501],
                                  [-19.716, -117.565],
                                  [48.525, -47.306],
                                  [-117.54, -36.151],
                                  [-17.156, -62.55],
                                  [-127.524, 9.087],
                                  [116.368, 77.208],
                                  [-38.357, 132.545],
                                  [-11.037, 92],
                                ],
                                o: [
                                  [-94.938, 40.863],
                                  [19.716, 117.565],
                                  [-48.525, 47.306],
                                  [117.54, 36.151],
                                  [19.35, 65.25],
                                  [110.313, -5.375],
                                  [-116.368, -77.208],
                                  [35.151, -135.413],
                                  [11.037, -92],
                                ],
                                v: [
                                  [-121.224, -363.609],
                                  [-170.873, -174.208],
                                  [-240.173, 40.628],
                                  [-248.625, 156.41],
                                  [-22.104, 289.931],
                                  [73.635, 371.067],
                                  [285.946, 262.882],
                                  [62.561, -74.854],
                                  [292.834, -181.207],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.636, -38.729],
                                  [-19.992, -117.876],
                                  [48.719, -47.115],
                                  [-117.886, -36.115],
                                  [-17.171, -62.71],
                                  [-127.262, 8.967],
                                  [116.835, 77.323],
                                  [-38.554, 132.941],
                                  [-10.986, 92],
                                ],
                                o: [
                                  [-95.023, 41.051],
                                  [19.992, 117.877],
                                  [-48.719, 47.115],
                                  [117.886, 36.115],
                                  [19.327, 65.364],
                                  [110.341, -5.318],
                                  [-116.835, -77.323],
                                  [35.402, -135.761],
                                  [10.986, -92],
                                ],
                                v: [
                                  [-121.927, -363.895],
                                  [-170.122, -173.594],
                                  [-240.507, 41.091],
                                  [-248.48, 156.1],
                                  [-22.617, 289.657],
                                  [73.174, 371.599],
                                  [286.445, 263.611],
                                  [61.882, -74.752],
                                  [294.015, -181.976],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.523, -38.954],
                                  [-20.263, -118.184],
                                  [48.911, -46.926],
                                  [-118.227, -36.079],
                                  [-17.185, -62.868],
                                  [-127.003, 8.849],
                                  [117.296, 77.436],
                                  [-38.748, 133.332],
                                  [-10.935, 92],
                                ],
                                o: [
                                  [-95.108, 41.237],
                                  [20.263, 118.184],
                                  [-48.911, 46.926],
                                  [118.227, 36.079],
                                  [19.305, 65.477],
                                  [110.369, -5.262],
                                  [-117.296, -77.436],
                                  [35.649, -136.104],
                                  [10.935, -92],
                                ],
                                v: [
                                  [-122.521, -364.127],
                                  [-169.43, -172.926],
                                  [-240.789, 41.509],
                                  [-248.274, 155.88],
                                  [-23.093, 289.339],
                                  [72.762, 372.18],
                                  [287.005, 264.349],
                                  [61.216, -74.639],
                                  [295.128, -182.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.412, -39.175],
                                  [-20.53, -118.486],
                                  [49.099, -46.741],
                                  [-118.563, -36.044],
                                  [-17.198, -63.023],
                                  [-126.748, 8.733],
                                  [117.75, 77.548],
                                  [-38.939, 133.717],
                                  [-10.886, 92],
                                ],
                                o: [
                                  [-95.191, 41.42],
                                  [20.53, 118.486],
                                  [-49.099, 46.741],
                                  [118.563, 36.044],
                                  [19.282, 65.588],
                                  [110.397, -5.206],
                                  [-117.749, -77.548],
                                  [35.893, -136.442],
                                  [10.886, -92],
                                ],
                                v: [
                                  [-123.111, -364.356],
                                  [-168.727, -172.363],
                                  [-241.123, 41.923],
                                  [-248.072, 155.605],
                                  [-23.636, 289.071],
                                  [72.357, 372.756],
                                  [287.573, 265.034],
                                  [60.51, -74.557],
                                  [296.286, -183.696],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.303, -39.394],
                                  [-20.794, -118.785],
                                  [49.285, -46.558],
                                  [-118.894, -36.009],
                                  [-17.212, -63.176],
                                  [-126.497, 8.618],
                                  [118.197, 77.657],
                                  [-39.127, 134.097],
                                  [-10.836, 92],
                                ],
                                o: [
                                  [-95.273, 41.6],
                                  [20.794, 118.785],
                                  [-49.285, 46.558],
                                  [118.894, 36.009],
                                  [19.261, 65.697],
                                  [110.424, -5.152],
                                  [-118.197, -77.657],
                                  [36.133, -136.775],
                                  [10.836, -92],
                                ],
                                v: [
                                  [-123.697, -364.592],
                                  [-168.031, -171.757],
                                  [-241.458, 42.28],
                                  [-247.925, 155.37],
                                  [-24.255, 288.749],
                                  [72.055, 373.37],
                                  [288.086, 265.675],
                                  [59.879, -74.464],
                                  [297.376, -184.647],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.196, -39.609],
                                  [-21.053, -119.078],
                                  [49.468, -46.378],
                                  [-119.22, -35.975],
                                  [-17.226, -63.326],
                                  [-126.25, 8.505],
                                  [118.637, 77.766],
                                  [-39.313, 134.47],
                                  [-10.788, 92],
                                ],
                                o: [
                                  [-95.353, 41.777],
                                  [21.053, 119.078],
                                  [-49.468, 46.378],
                                  [119.22, 35.975],
                                  [19.239, 65.804],
                                  [110.451, -5.098],
                                  [-118.637, -77.766],
                                  [36.37, -137.104],
                                  [10.788, -92],
                                ],
                                v: [
                                  [-124.216, -364.825],
                                  [-167.343, -171.14],
                                  [-241.846, 42.632],
                                  [-247.738, 155.133],
                                  [-24.826, 288.476],
                                  [71.761, 373.938],
                                  [288.598, 266.378],
                                  [59.207, -74.352],
                                  [298.522, -185.556],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.09, -39.821],
                                  [-21.309, -119.368],
                                  [49.648, -46.2],
                                  [-119.541, -35.941],
                                  [-17.239, -63.475],
                                  [-126.006, 8.394],
                                  [119.071, 77.872],
                                  [-39.496, 134.839],
                                  [-10.74, 92],
                                ],
                                o: [
                                  [-95.433, 41.952],
                                  [21.309, 119.368],
                                  [-49.648, 46.2],
                                  [119.541, 35.941],
                                  [19.218, 65.911],
                                  [110.478, -5.045],
                                  [-119.071, -77.872],
                                  [36.603, -137.427],
                                  [10.74, -92],
                                ],
                                v: [
                                  [-124.731, -365.002],
                                  [-166.706, -170.523],
                                  [-242.191, 42.936],
                                  [-247.49, 154.894],
                                  [-25.473, 288.159],
                                  [71.57, 374.545],
                                  [289.118, 266.984],
                                  [58.547, -74.283],
                                  [299.6, -186.519],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.985, -40.03],
                                  [-21.561, -119.653],
                                  [49.825, -46.025],
                                  [-119.858, -35.908],
                                  [-17.252, -63.621],
                                  [-125.766, 8.284],
                                  [119.499, 77.978],
                                  [-39.676, 135.202],
                                  [-10.693, 92],
                                ],
                                o: [
                                  [-95.511, 42.125],
                                  [21.561, 119.653],
                                  [-49.825, 46.025],
                                  [119.858, 35.908],
                                  [19.197, 66.015],
                                  [110.504, -4.992],
                                  [-119.499, -77.977],
                                  [36.833, -137.746],
                                  [10.693, -92],
                                ],
                                v: [
                                  [-125.188, -365.238],
                                  [-166.013, -169.968],
                                  [-242.581, 43.28],
                                  [-247.298, 154.705],
                                  [-26.134, 287.891],
                                  [71.368, 375.148],
                                  [289.646, 267.607],
                                  [57.907, -74.195],
                                  [300.671, -187.546],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.882, -40.236],
                                  [-21.809, -119.934],
                                  [50, -45.853],
                                  [-120.17, -35.875],
                                  [-17.265, -63.765],
                                  [-125.529, 8.176],
                                  [119.92, 78.081],
                                  [-39.853, 135.56],
                                  [-10.647, 92],
                                ],
                                o: [
                                  [-95.588, 42.294],
                                  [21.809, 119.934],
                                  [-50, 45.853],
                                  [120.17, 35.875],
                                  [19.176, 66.118],
                                  [110.529, -4.941],
                                  [-119.92, -78.081],
                                  [37.059, -138.059],
                                  [10.647, -92],
                                ],
                                v: [
                                  [-125.587, -365.418],
                                  [-165.381, -169.36],
                                  [-242.98, 43.521],
                                  [-247.118, 154.506],
                                  [-26.808, 287.631],
                                  [71.237, 375.802],
                                  [290.119, 268.228],
                                  [57.278, -74.098],
                                  [301.683, -188.574],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.781, -40.439],
                                  [-22.054, -120.211],
                                  [50.173, -45.683],
                                  [-120.477, -35.843],
                                  [-17.277, -63.907],
                                  [-125.296, 8.07],
                                  [120.335, 78.183],
                                  [-40.028, 135.912],
                                  [-10.601, 92],
                                ],
                                o: [
                                  [-95.664, 42.462],
                                  [22.054, 120.211],
                                  [-50.173, 45.683],
                                  [120.477, 35.843],
                                  [19.156, 66.219],
                                  [110.555, -4.89],
                                  [-120.335, -78.183],
                                  [37.282, -138.369],
                                  [10.601, -92],
                                ],
                                v: [
                                  [-125.982, -365.594],
                                  [-164.737, -168.804],
                                  [-243.37, 43.802],
                                  [-246.877, 154.358],
                                  [-27.557, 287.368],
                                  [71.148, 376.39],
                                  [290.591, 268.857],
                                  [56.66, -73.993],
                                  [302.689, -189.612],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.681, -40.638],
                                  [-22.295, -120.484],
                                  [50.343, -45.515],
                                  [-120.78, -35.811],
                                  [-17.29, -64.047],
                                  [-125.066, 7.965],
                                  [120.744, 78.284],
                                  [-40.201, 136.259],
                                  [-10.556, 92],
                                ],
                                o: [
                                  [-95.739, 42.627],
                                  [22.295, 120.484],
                                  [-50.343, 45.515],
                                  [120.78, 35.811],
                                  [19.136, 66.319],
                                  [110.58, -4.84],
                                  [-120.744, -78.284],
                                  [37.502, -138.673],
                                  [10.556, -92],
                                ],
                                v: [
                                  [-126.309, -365.775],
                                  [-164.163, -168.249],
                                  [-243.77, 44.033],
                                  [-246.647, 154.2],
                                  [-28.257, 287.049],
                                  [71.175, 377.028],
                                  [291.071, 269.439],
                                  [56.062, -73.879],
                                  [303.688, -190.651],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.582, -40.835],
                                  [-22.532, -120.753],
                                  [50.51, -45.351],
                                  [-121.078, -35.779],
                                  [-17.302, -64.185],
                                  [-124.84, 7.862],
                                  [121.147, 78.383],
                                  [-40.37, 136.601],
                                  [-10.512, 92],
                                ],
                                o: [
                                  [-95.813, 42.789],
                                  [22.532, 120.753],
                                  [-50.51, 45.351],
                                  [121.078, 35.779],
                                  [19.116, 66.418],
                                  [110.604, -4.791],
                                  [-121.147, -78.383],
                                  [37.719, -138.974],
                                  [10.512, -92],
                                ],
                                v: [
                                  [-126.641, -365.899],
                                  [-163.586, -167.693],
                                  [-244.224, 44.313],
                                  [-246.472, 154.105],
                                  [-29.032, 286.789],
                                  [71.202, 377.665],
                                  [291.505, 270.028],
                                  [55.473, -73.802],
                                  [304.619, -191.752],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.485, -41.029],
                                  [-22.766, -121.018],
                                  [50.675, -45.188],
                                  [-121.372, -35.748],
                                  [-17.314, -64.32],
                                  [-124.617, 7.76],
                                  [121.544, 78.48],
                                  [-40.538, 136.938],
                                  [-10.468, 92],
                                ],
                                o: [
                                  [-95.886, 42.949],
                                  [22.766, 121.018],
                                  [-50.675, 45.188],
                                  [121.372, 35.748],
                                  [19.097, 66.515],
                                  [110.629, -4.743],
                                  [-121.544, -78.48],
                                  [37.932, -139.269],
                                  [10.469, -92],
                                ],
                                v: [
                                  [-126.905, -366.073],
                                  [-163.008, -167.191],
                                  [-244.625, 44.534],
                                  [-246.246, 153.999],
                                  [-29.757, 286.526],
                                  [71.281, 378.29],
                                  [291.937, 270.624],
                                  [54.895, -73.717],
                                  [305.544, -192.863],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.39, -41.22],
                                  [-22.997, -121.279],
                                  [50.837, -45.028],
                                  [-121.662, -35.718],
                                  [-17.326, -64.454],
                                  [-124.397, 7.659],
                                  [121.935, 78.576],
                                  [-40.702, 137.27],
                                  [-10.425, 92],
                                ],
                                o: [
                                  [-95.958, 43.107],
                                  [22.997, 121.279],
                                  [-50.837, 45.028],
                                  [121.662, 35.718],
                                  [19.078, 66.61],
                                  [110.652, -4.695],
                                  [-121.935, -78.576],
                                  [38.142, -139.561],
                                  [10.425, -92],
                                ],
                                v: [
                                  [-127.101, -366.197],
                                  [-162.427, -166.635],
                                  [-245.081, 44.697],
                                  [-246.029, 153.885],
                                  [-30.556, 286.27],
                                  [71.415, 378.966],
                                  [292.377, 271.173],
                                  [54.335, -73.67],
                                  [306.462, -193.974],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.296, -41.408],
                                  [-23.224, -121.536],
                                  [50.997, -44.871],
                                  [-121.947, -35.688],
                                  [-17.338, -64.586],
                                  [-124.181, 7.561],
                                  [122.32, 78.671],
                                  [-40.865, 137.597],
                                  [-10.383, 92],
                                ],
                                o: [
                                  [-96.028, 43.262],
                                  [23.224, 121.536],
                                  [-50.997, 44.871],
                                  [121.947, 35.688],
                                  [19.059, 66.704],
                                  [110.676, -4.648],
                                  [-122.32, -78.671],
                                  [38.349, -139.847],
                                  [10.383, -92],
                                ],
                                v: [
                                  [-127.301, -366.325],
                                  [-161.907, -166.141],
                                  [-245.482, 44.799],
                                  [-245.814, 153.879],
                                  [-31.368, 285.964],
                                  [71.611, 379.587],
                                  [292.762, 271.736],
                                  [53.784, -73.624],
                                  [307.312, -195.093],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.203, -41.594],
                                  [-23.447, -121.789],
                                  [51.154, -44.715],
                                  [-122.228, -35.659],
                                  [-17.35, -64.715],
                                  [-123.967, 7.463],
                                  [122.699, 78.764],
                                  [-41.024, 137.919],
                                  [-10.341, 92],
                                ],
                                o: [
                                  [-96.098, 43.415],
                                  [23.447, 121.789],
                                  [-51.154, 44.715],
                                  [122.228, 35.659],
                                  [19.041, 66.797],
                                  [110.699, -4.602],
                                  [-122.699, -78.764],
                                  [38.553, -140.13],
                                  [10.341, -92],
                                ],
                                v: [
                                  [-127.433, -366.448],
                                  [-161.447, -165.694],
                                  [-245.885, 44.897],
                                  [-245.546, 153.82],
                                  [-32.254, 285.709],
                                  [71.862, 380.252],
                                  [293.154, 272.304],
                                  [53.197, -73.563],
                                  [308.165, -196.275],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.112, -41.776],
                                  [-23.667, -122.038],
                                  [51.31, -44.563],
                                  [-122.504, -35.629],
                                  [-17.361, -64.843],
                                  [-123.758, 7.368],
                                  [123.073, 78.856],
                                  [-41.182, 138.236],
                                  [-10.3, 92],
                                ],
                                o: [
                                  [-96.166, 43.565],
                                  [23.667, 122.038],
                                  [-51.31, 44.563],
                                  [122.504, 35.629],
                                  [19.022, 66.888],
                                  [110.722, -4.556],
                                  [-123.073, -78.856],
                                  [38.754, -140.408],
                                  [10.3, -92],
                                ],
                                v: [
                                  [-127.568, -366.513],
                                  [-160.977, -165.2],
                                  [-246.287, 44.934],
                                  [-245.333, 153.869],
                                  [-33.088, 285.451],
                                  [72.16, 380.907],
                                  [293.546, 272.816],
                                  [52.673, -73.495],
                                  [308.948, -197.41],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.022, -41.956],
                                  [-23.884, -122.283],
                                  [51.462, -44.412],
                                  [-122.777, -35.601],
                                  [-17.372, -64.969],
                                  [-123.551, 7.273],
                                  [123.441, 78.947],
                                  [-41.337, 138.548],
                                  [-10.26, 92],
                                ],
                                o: [
                                  [-96.233, 43.714],
                                  [23.884, 122.283],
                                  [-51.462, 44.412],
                                  [122.777, 35.601],
                                  [19.004, 66.978],
                                  [110.744, -4.511],
                                  [-123.441, -78.947],
                                  [38.951, -140.683],
                                  [10.26, -92],
                                ],
                                v: [
                                  [-127.635, -366.58],
                                  [-160.575, -164.762],
                                  [-246.69, 44.974],
                                  [-245.137, 153.911],
                                  [-33.934, 285.197],
                                  [72.529, 381.561],
                                  [293.889, 273.341],
                                  [52.165, -73.475],
                                  [309.726, -198.598],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.934, -42.133],
                                  [-24.098, -122.525],
                                  [51.613, -44.264],
                                  [-123.045, -35.573],
                                  [-17.383, -65.093],
                                  [-123.347, 7.18],
                                  [123.803, 79.036],
                                  [-41.49, 138.856],
                                  [-10.22, 92],
                                ],
                                o: [
                                  [-96.3, 43.86],
                                  [24.098, 122.525],
                                  [-51.613, 44.264],
                                  [123.045, 35.573],
                                  [18.987, 67.067],
                                  [110.767, -4.467],
                                  [-123.803, -79.036],
                                  [39.146, -140.953],
                                  [10.22, -92],
                                ],
                                v: [
                                  [-127.635, -366.698],
                                  [-160.164, -164.316],
                                  [-247.093, 44.945],
                                  [-244.934, 154.009],
                                  [-34.799, 284.948],
                                  [73, 382.207],
                                  [294.232, 273.878],
                                  [51.666, -73.449],
                                  [310.489, -199.786],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.846, -42.307],
                                  [-24.308, -122.763],
                                  [51.761, -44.118],
                                  [-123.309, -35.545],
                                  [-17.394, -65.215],
                                  [-123.147, 7.089],
                                  [124.16, 79.123],
                                  [-41.64, 139.159],
                                  [-10.181, 92],
                                ],
                                o: [
                                  [-96.365, 44.004],
                                  [24.308, 122.763],
                                  [-51.761, 44.118],
                                  [123.309, 35.545],
                                  [18.969, 67.154],
                                  [110.788, -4.423],
                                  [-124.16, -79.123],
                                  [39.338, -141.219],
                                  [10.181, -92],
                                ],
                                v: [
                                  [-127.636, -366.763],
                                  [-159.814, -163.933],
                                  [-247.497, 44.856],
                                  [-244.684, 154.108],
                                  [-35.675, 284.702],
                                  [73.473, 382.851],
                                  [294.527, 274.413],
                                  [51.231, -73.417],
                                  [311.192, -200.988],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.76, -42.479],
                                  [-24.515, -122.998],
                                  [51.907, -43.974],
                                  [-123.57, -35.517],
                                  [-17.405, -65.335],
                                  [-122.949, 6.998],
                                  [124.512, 79.21],
                                  [-41.788, 139.457],
                                  [-10.142, 92],
                                ],
                                o: [
                                  [-96.43, 44.145],
                                  [24.515, 122.998],
                                  [-51.907, 43.974],
                                  [123.57, 35.517],
                                  [18.952, 67.24],
                                  [110.81, -4.38],
                                  [-124.512, -79.21],
                                  [39.527, -141.481],
                                  [10.142, -92],
                                ],
                                v: [
                                  [-127.578, -366.776],
                                  [-159.525, -163.558],
                                  [-247.846, 44.769],
                                  [-244.49, 154.256],
                                  [-36.554, 284.453],
                                  [73.956, 383.597],
                                  [294.821, 274.905],
                                  [50.756, -73.426],
                                  [311.889, -202.244],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.676, -42.648],
                                  [-24.719, -123.229],
                                  [52.051, -43.832],
                                  [-123.826, -35.49],
                                  [-17.415, -65.454],
                                  [-122.755, 6.91],
                                  [124.858, 79.295],
                                  [-41.934, 139.751],
                                  [-10.104, 92],
                                ],
                                o: [
                                  [-96.493, 44.285],
                                  [24.719, 123.229],
                                  [-52.051, 43.832],
                                  [123.826, 35.49],
                                  [18.935, 67.324],
                                  [110.831, -4.338],
                                  [-124.858, -79.295],
                                  [39.713, -141.738],
                                  [10.104, -92],
                                ],
                                v: [
                                  [-127.451, -366.838],
                                  [-159.234, -163.176],
                                  [-248.195, 44.62],
                                  [-244.311, 154.406],
                                  [-37.45, 284.208],
                                  [74.542, 384.391],
                                  [295.121, 275.408],
                                  [50.344, -73.437],
                                  [312.525, -203.452],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.593, -42.815],
                                  [-24.92, -123.456],
                                  [52.192, -43.693],
                                  [-124.079, -35.464],
                                  [-17.426, -65.57],
                                  [-122.563, 6.822],
                                  [125.199, 79.379],
                                  [-42.078, 140.041],
                                  [-10.067, 92],
                                ],
                                o: [
                                  [-96.555, 44.422],
                                  [24.92, 123.456],
                                  [-52.192, 43.693],
                                  [124.079, 35.464],
                                  [18.919, 67.407],
                                  [110.852, -4.296],
                                  [-125.199, -79.379],
                                  [39.896, -141.992],
                                  [10.067, -92],
                                ],
                                v: [
                                  [-127.374, -366.84],
                                  [-158.997, -162.795],
                                  [-248.544, 44.457],
                                  [-244.125, 154.557],
                                  [-38.357, 283.958],
                                  [75.13, 385.185],
                                  [295.373, 275.852],
                                  [49.94, -73.435],
                                  [313.148, -204.665],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.511, -42.979],
                                  [-25.118, -123.68],
                                  [52.332, -43.555],
                                  [-124.327, -35.438],
                                  [-17.436, -65.685],
                                  [-122.375, 6.736],
                                  [125.534, 79.461],
                                  [-42.219, 140.325],
                                  [-10.03, 92],
                                ],
                                o: [
                                  [-96.617, 44.557],
                                  [25.118, 123.68],
                                  [-52.332, 43.555],
                                  [124.327, 35.438],
                                  [18.902, 67.489],
                                  [110.872, -4.255],
                                  [-125.534, -79.461],
                                  [40.077, -142.242],
                                  [10.03, -92],
                                ],
                                v: [
                                  [-127.181, -366.906],
                                  [-158.766, -162.477],
                                  [-248.893, 44.241],
                                  [-243.994, 154.71],
                                  [-39.218, 283.774],
                                  [75.776, 386.027],
                                  [295.624, 276.369],
                                  [49.544, -73.483],
                                  [313.764, -205.931],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.43, -43.14],
                                  [-25.313, -123.901],
                                  [52.469, -43.42],
                                  [-124.572, -35.412],
                                  [-17.446, -65.798],
                                  [-122.189, 6.651],
                                  [125.865, 79.542],
                                  [-42.358, 140.606],
                                  [-9.993, 92],
                                ],
                                o: [
                                  [-96.678, 44.691],
                                  [25.313, 123.901],
                                  [-52.469, 43.42],
                                  [124.572, 35.412],
                                  [18.886, 67.57],
                                  [110.892, -4.215],
                                  [-125.865, -79.542],
                                  [40.254, -142.489],
                                  [9.993, -92],
                                ],
                                v: [
                                  [-126.982, -366.911],
                                  [-158.588, -162.215],
                                  [-249.243, 44.017],
                                  [-243.92, 154.857],
                                  [-40.089, 283.53],
                                  [76.417, 386.913],
                                  [295.825, 276.828],
                                  [49.219, -73.477],
                                  [314.32, -207.148],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.35, -43.299],
                                  [-25.505, -124.118],
                                  [52.604, -43.287],
                                  [-124.813, -35.387],
                                  [-17.456, -65.909],
                                  [-122.006, 6.568],
                                  [126.19, 79.622],
                                  [-42.495, 140.882],
                                  [-9.958, 92],
                                ],
                                o: [
                                  [-96.737, 44.822],
                                  [25.505, 124.118],
                                  [-52.604, 43.287],
                                  [124.813, 35.387],
                                  [18.87, 67.65],
                                  [110.912, -4.175],
                                  [-126.19, -79.622],
                                  [40.429, -142.731],
                                  [9.958, -92],
                                ],
                                v: [
                                  [-126.778, -366.91],
                                  [-158.416, -161.948],
                                  [-249.537, 43.788],
                                  [-243.839, 155.006],
                                  [-41.025, 283.345],
                                  [77.123, 387.807],
                                  [296.026, 277.24],
                                  [48.846, -73.515],
                                  [314.814, -208.424],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.272, -43.456],
                                  [-25.693, -124.332],
                                  [52.737, -43.156],
                                  [-125.05, -35.362],
                                  [-17.466, -66.019],
                                  [-121.826, 6.486],
                                  [126.511, 79.701],
                                  [-42.631, 141.154],
                                  [-9.922, 92],
                                ],
                                o: [
                                  [-96.796, 44.951],
                                  [25.693, 124.332],
                                  [-52.737, 43.156],
                                  [125.05, 35.362],
                                  [18.854, 67.728],
                                  [110.932, -4.136],
                                  [-126.511, -79.701],
                                  [40.601, -142.97],
                                  [9.922, -92],
                                ],
                                v: [
                                  [-126.505, -366.972],
                                  [-158.194, -161.737],
                                  [-249.83, 43.497],
                                  [-243.822, 155.157],
                                  [-41.859, 283.155],
                                  [77.881, 388.688],
                                  [296.232, 277.662],
                                  [48.535, -73.548],
                                  [315.351, -209.643],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.195, -43.61],
                                  [-25.879, -124.542],
                                  [52.868, -43.027],
                                  [-125.284, -35.337],
                                  [-17.476, -66.127],
                                  [-121.649, 6.405],
                                  [126.826, 79.779],
                                  [-42.763, 141.422],
                                  [-9.888, 92],
                                ],
                                o: [
                                  [-96.854, 45.078],
                                  [25.879, 124.542],
                                  [-52.868, 43.027],
                                  [125.284, 35.337],
                                  [18.839, 67.805],
                                  [110.951, -4.098],
                                  [-126.826, -79.779],
                                  [40.771, -143.205],
                                  [9.888, -92],
                                ],
                                v: [
                                  [-126.234, -366.973],
                                  [-158.025, -161.528],
                                  [-250.124, 43.144],
                                  [-243.855, 155.254],
                                  [-42.757, 282.968],
                                  [78.641, 389.684],
                                  [296.389, 278.086],
                                  [48.233, -73.631],
                                  [315.777, -210.916],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.119, -43.762],
                                  [-26.062, -124.749],
                                  [52.997, -42.9],
                                  [-125.514, -35.313],
                                  [-17.485, -66.233],
                                  [-121.474, 6.325],
                                  [127.137, 79.855],
                                  [-42.894, 141.686],
                                  [-9.854, 92],
                                ],
                                o: [
                                  [-96.911, 45.203],
                                  [26.062, 124.749],
                                  [-52.997, 42.9],
                                  [125.514, 35.313],
                                  [18.824, 67.881],
                                  [110.97, -4.06],
                                  [-127.137, -79.855],
                                  [40.938, -143.436],
                                  [9.854, -92],
                                ],
                                v: [
                                  [-125.895, -366.968],
                                  [-157.812, -161.377],
                                  [-250.417, 42.84],
                                  [-243.89, 155.401],
                                  [-43.608, 282.79],
                                  [79.404, 390.618],
                                  [296.545, 278.513],
                                  [47.944, -73.71],
                                  [316.246, -212.124],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.045, -43.911],
                                  [-26.243, -124.953],
                                  [53.124, -42.775],
                                  [-125.74, -35.289],
                                  [-17.494, -66.338],
                                  [-121.303, 6.247],
                                  [127.443, 79.93],
                                  [-43.023, 141.945],
                                  [-9.82, 92],
                                ],
                                o: [
                                  [-96.967, 45.326],
                                  [26.243, 124.953],
                                  [-53.124, 42.775],
                                  [125.74, 35.289],
                                  [18.809, 67.955],
                                  [110.989, -4.022],
                                  [-127.443, -79.93],
                                  [41.102, -143.664],
                                  [9.82, -92],
                                ],
                                v: [
                                  [-125.549, -366.97],
                                  [-157.659, -161.221],
                                  [-250.704, 42.474],
                                  [-243.983, 155.501],
                                  [-44.468, 282.607],
                                  [80.226, 391.605],
                                  [296.656, 278.885],
                                  [47.719, -73.785],
                                  [316.66, -213.33],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.971, -44.058],
                                  [-26.42, -125.154],
                                  [53.249, -42.651],
                                  [-125.963, -35.266],
                                  [-17.504, -66.441],
                                  [-121.133, 6.17],
                                  [127.744, 80.004],
                                  [-43.15, 142.201],
                                  [-9.787, 92],
                                ],
                                o: [
                                  [-97.022, 45.448],
                                  [26.42, 125.154],
                                  [-53.249, 42.651],
                                  [125.963, 35.266],
                                  [18.794, 68.029],
                                  [111.007, -3.985],
                                  [-127.744, -80.004],
                                  [41.264, -143.888],
                                  [9.787, -92],
                                ],
                                v: [
                                  [-125.199, -366.965],
                                  [-157.447, -161.123],
                                  [-250.941, 42.046],
                                  [-244.076, 155.652],
                                  [-45.279, 282.425],
                                  [81.044, 392.594],
                                  [296.773, 279.266],
                                  [47.444, -73.855],
                                  [317.012, -214.59],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.899, -44.203],
                                  [-26.594, -125.352],
                                  [53.372, -42.53],
                                  [-126.182, -35.243],
                                  [-17.513, -66.542],
                                  [-120.967, 6.094],
                                  [128.04, 80.077],
                                  [-43.275, 142.452],
                                  [-9.754, 92],
                                ],
                                o: [
                                  [-97.076, 45.567],
                                  [26.594, 125.352],
                                  [-53.372, 42.53],
                                  [126.182, 35.243],
                                  [18.78, 68.101],
                                  [111.025, -3.949],
                                  [-128.04, -80.077],
                                  [41.423, -144.109],
                                  [9.754, -92],
                                ],
                                v: [
                                  [-124.787, -366.961],
                                  [-157.302, -161.084],
                                  [-251.172, 41.673],
                                  [-244.222, 155.748],
                                  [-46.105, 282.295],
                                  [81.922, 393.63],
                                  [296.894, 279.642],
                                  [47.233, -73.983],
                                  [317.357, -215.786],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.827, -44.345],
                                  [-26.766, -125.546],
                                  [53.493, -42.411],
                                  [-126.398, -35.22],
                                  [-17.522, -66.642],
                                  [-120.803, 6.019],
                                  [128.332, 80.149],
                                  [-43.398, 142.7],
                                  [-9.722, 92],
                                ],
                                o: [
                                  [-97.129, 45.685],
                                  [26.766, 125.546],
                                  [-53.493, 42.411],
                                  [126.398, 35.22],
                                  [18.765, 68.173],
                                  [111.043, -3.914],
                                  [-128.331, -80.149],
                                  [41.58, -144.326],
                                  [9.722, -92],
                                ],
                                v: [
                                  [-124.363, -366.95],
                                  [-157.153, -161.041],
                                  [-251.408, 41.232],
                                  [-244.376, 155.902],
                                  [-46.932, 282.167],
                                  [82.803, 394.675],
                                  [296.97, 279.969],
                                  [47.029, -74.1],
                                  [317.703, -216.981],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.757, -44.485],
                                  [-26.936, -125.738],
                                  [53.613, -42.293],
                                  [-126.611, -35.198],
                                  [-17.53, -66.74],
                                  [-120.642, 5.945],
                                  [128.619, 80.219],
                                  [-43.519, 142.943],
                                  [-9.691, 92],
                                ],
                                o: [
                                  [-97.182, 45.8],
                                  [26.936, 125.738],
                                  [-53.613, 42.293],
                                  [126.611, 35.198],
                                  [18.751, 68.243],
                                  [111.061, -3.879],
                                  [-128.619, -80.219],
                                  [41.734, -144.54],
                                  [9.691, -92],
                                ],
                                v: [
                                  [-123.94, -366.945],
                                  [-157.008, -161.006],
                                  [-251.639, 40.785],
                                  [-244.582, 156.002],
                                  [-47.71, 282.04],
                                  [83.738, 395.71],
                                  [297.051, 280.292],
                                  [46.889, -74.214],
                                  [317.979, -218.173],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.688, -44.623],
                                  [-27.102, -125.926],
                                  [53.73, -42.178],
                                  [-126.82, -35.176],
                                  [-17.539, -66.836],
                                  [-120.483, 5.873],
                                  [128.901, 80.289],
                                  [-43.638, 143.183],
                                  [-9.66, 92],
                                ],
                                o: [
                                  [-97.234, 45.914],
                                  [27.102, 125.926],
                                  [-53.73, 42.178],
                                  [126.82, 35.176],
                                  [18.738, 68.312],
                                  [111.078, -3.844],
                                  [-128.901, -80.289],
                                  [41.886, -144.751],
                                  [9.66, -92],
                                ],
                                v: [
                                  [-123.511, -366.99],
                                  [-156.923, -161.019],
                                  [-251.812, 40.338],
                                  [-244.847, 156.153],
                                  [-48.439, 281.909],
                                  [84.626, 396.749],
                                  [297.135, 280.621],
                                  [46.705, -74.385],
                                  [318.255, -219.301],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.62, -44.759],
                                  [-27.266, -126.112],
                                  [53.845, -42.064],
                                  [-127.026, -35.154],
                                  [-17.547, -66.931],
                                  [-120.327, 5.801],
                                  [129.179, 80.357],
                                  [-43.755, 143.419],
                                  [-9.629, 92],
                                ],
                                o: [
                                  [-97.285, 46.026],
                                  [27.266, 126.112],
                                  [-53.845, 42.064],
                                  [127.026, 35.154],
                                  [18.724, 68.38],
                                  [111.095, -3.81],
                                  [-129.179, -80.357],
                                  [42.035, -144.958],
                                  [9.629, -92],
                                ],
                                v: [
                                  [-123.015, -366.978],
                                  [-156.841, -161.097],
                                  [-251.98, 39.879],
                                  [-245.114, 156.256],
                                  [-49.181, 281.773],
                                  [85.567, 397.785],
                                  [297.167, 280.896],
                                  [46.578, -74.491],
                                  [318.468, -220.484],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.553, -44.893],
                                  [-27.427, -126.294],
                                  [53.959, -41.952],
                                  [-127.228, -35.133],
                                  [-17.556, -67.025],
                                  [-120.173, 5.731],
                                  [129.453, 80.424],
                                  [-43.87, 143.652],
                                  [-9.599, 92],
                                ],
                                o: [
                                  [-97.335, 46.137],
                                  [27.427, 126.294],
                                  [-53.959, 41.952],
                                  [127.228, 35.133],
                                  [18.711, 68.446],
                                  [111.112, -3.777],
                                  [-129.453, -80.424],
                                  [42.182, -145.162],
                                  [9.599, -92],
                                ],
                                v: [
                                  [-122.513, -366.959],
                                  [-156.756, -161.173],
                                  [-252.147, 39.357],
                                  [-245.44, 156.416],
                                  [-49.923, 281.694],
                                  [86.512, 398.824],
                                  [297.209, 281.171],
                                  [46.465, -74.711],
                                  [318.674, -221.609],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.488, -45.025],
                                  [-27.586, -126.474],
                                  [54.071, -41.842],
                                  [-127.428, -35.112],
                                  [-17.564, -67.117],
                                  [-120.022, 5.662],
                                  [129.722, 80.49],
                                  [-43.984, 143.88],
                                  [-9.57, 92],
                                ],
                                o: [
                                  [-97.384, 46.245],
                                  [27.586, 126.474],
                                  [-54.071, 41.842],
                                  [127.428, 35.112],
                                  [18.698, 68.512],
                                  [111.128, -3.744],
                                  [-129.722, -80.49],
                                  [42.327, -145.362],
                                  [9.57, -92],
                                ],
                                v: [
                                  [-122.012, -366.992],
                                  [-156.678, -161.314],
                                  [-252.258, 38.886],
                                  [-245.768, 156.578],
                                  [-50.621, 281.56],
                                  [87.455, 399.86],
                                  [297.259, 281.452],
                                  [46.357, -74.871],
                                  [318.817, -222.732],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.423, -45.154],
                                  [-27.742, -126.651],
                                  [54.181, -41.733],
                                  [-127.624, -35.091],
                                  [-17.572, -67.208],
                                  [-119.873, 5.594],
                                  [129.987, 80.556],
                                  [-44.095, 144.105],
                                  [-9.54, 92],
                                ],
                                o: [
                                  [-97.433, 46.352],
                                  [27.742, 126.651],
                                  [-54.181, 41.733],
                                  [127.624, 35.091],
                                  [18.685, 68.577],
                                  [111.144, -3.711],
                                  [-129.987, -80.556],
                                  [42.469, -145.56],
                                  [9.54, -92],
                                ],
                                v: [
                                  [-121.448, -367.018],
                                  [-156.655, -161.452],
                                  [-252.425, 38.41],
                                  [-246.156, 156.679],
                                  [-51.262, 281.479],
                                  [88.459, 400.895],
                                  [297.25, 281.729],
                                  [46.309, -75.084],
                                  [319.016, -223.853],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.359, -45.281],
                                  [-27.896, -126.825],
                                  [54.289, -41.627],
                                  [-127.817, -35.071],
                                  [-17.58, -67.297],
                                  [-119.726, 5.527],
                                  [130.248, 80.62],
                                  [-44.205, 144.326],
                                  [-9.512, 92],
                                ],
                                o: [
                                  [-97.481, 46.457],
                                  [27.896, 126.825],
                                  [-54.289, 41.627],
                                  [127.817, 35.071],
                                  [18.672, 68.641],
                                  [111.16, -3.68],
                                  [-130.248, -80.62],
                                  [42.61, -145.754],
                                  [9.512, -92],
                                ],
                                v: [
                                  [-120.936, -366.981],
                                  [-156.635, -161.646],
                                  [-252.53, 37.871],
                                  [-246.546, 156.844],
                                  [-51.858, 281.393],
                                  [89.466, 401.932],
                                  [297.25, 281.954],
                                  [46.272, -75.293],
                                  [319.089, -224.967],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.297, -45.407],
                                  [-28.047, -126.996],
                                  [54.396, -41.522],
                                  [-128.007, -35.051],
                                  [-17.588, -67.385],
                                  [-119.582, 5.461],
                                  [130.505, 80.683],
                                  [-44.313, 144.544],
                                  [-9.483, 92],
                                ],
                                o: [
                                  [-97.528, 46.561],
                                  [28.047, 126.996],
                                  [-54.396, 41.522],
                                  [128.007, 35.051],
                                  [18.659, 68.703],
                                  [111.176, -3.648],
                                  [-130.505, -80.683],
                                  [42.748, -145.945],
                                  [9.483, -92],
                                ],
                                v: [
                                  [-120.362, -367],
                                  [-156.616, -161.849],
                                  [-252.578, 37.378],
                                  [-246.996, 157.01],
                                  [-52.454, 281.36],
                                  [90.419, 402.967],
                                  [297.253, 282.186],
                                  [46.242, -75.499],
                                  [319.161, -226.079],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.235, -45.53],
                                  [-28.196, -127.165],
                                  [54.501, -41.418],
                                  [-128.194, -35.031],
                                  [-17.596, -67.471],
                                  [-119.44, 5.397],
                                  [130.757, 80.745],
                                  [-44.42, 144.759],
                                  [-9.456, 92],
                                ],
                                o: [
                                  [-97.574, 46.662],
                                  [28.196, 127.165],
                                  [-54.501, 41.418],
                                  [128.194, 35.031],
                                  [18.647, 68.765],
                                  [111.191, -3.617],
                                  [-130.757, -80.745],
                                  [42.883, -146.134],
                                  [9.456, -92],
                                ],
                                v: [
                                  [-119.84, -367.066],
                                  [-156.652, -162.049],
                                  [-252.678, 36.881],
                                  [-247.448, 157.115],
                                  [-53.06, 281.271],
                                  [91.427, 403.948],
                                  [297.206, 282.412],
                                  [46.218, -75.763],
                                  [319.231, -227.127],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.174, -45.652],
                                  [-28.342, -127.33],
                                  [54.604, -41.317],
                                  [-128.378, -35.012],
                                  [-17.603, -67.556],
                                  [-119.301, 5.333],
                                  [131.006, 80.806],
                                  [-44.525, 144.97],
                                  [-9.428, 92],
                                ],
                                o: [
                                  [-97.619, 46.763],
                                  [28.342, 127.33],
                                  [-54.604, 41.317],
                                  [128.378, 35.012],
                                  [18.635, 68.826],
                                  [111.206, -3.587],
                                  [-131.006, -80.806],
                                  [43.017, -146.319],
                                  [9.428, -92],
                                ],
                                v: [
                                  [-119.26, -367.07],
                                  [-156.69, -162.311],
                                  [-252.72, 36.383],
                                  [-247.903, 157.284],
                                  [-53.552, 281.24],
                                  [92.439, 404.985],
                                  [297.162, 282.586],
                                  [46.201, -76.076],
                                  [319.289, -228.179],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.115, -45.771],
                                  [-28.486, -127.494],
                                  [54.705, -41.217],
                                  [-128.559, -34.993],
                                  [-17.611, -67.64],
                                  [-119.163, 5.27],
                                  [131.25, 80.866],
                                  [-44.628, 145.177],
                                  [-9.402, 92],
                                ],
                                o: [
                                  [-97.664, 46.861],
                                  [28.486, 127.494],
                                  [-54.705, 41.217],
                                  [128.559, 34.993],
                                  [18.623, 68.885],
                                  [111.221, -3.557],
                                  [-131.25, -80.866],
                                  [43.148, -146.501],
                                  [9.401, -92],
                                ],
                                v: [
                                  [-118.675, -367.125],
                                  [-156.725, -162.575],
                                  [-252.762, 35.818],
                                  [-248.47, 157.454],
                                  [-54.054, 281.199],
                                  [93.455, 405.967],
                                  [297.121, 282.76],
                                  [46.248, -76.334],
                                  [319.288, -229.166],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.056, -45.888],
                                  [-28.628, -127.654],
                                  [54.805, -41.118],
                                  [-128.737, -34.974],
                                  [-17.618, -67.722],
                                  [-119.028, 5.208],
                                  [131.491, 80.925],
                                  [-44.729, 145.381],
                                  [-9.375, 92],
                                ],
                                o: [
                                  [-97.708, 46.958],
                                  [28.628, 127.654],
                                  [-54.805, 41.118],
                                  [128.737, 34.974],
                                  [18.611, 68.944],
                                  [111.236, -3.528],
                                  [-131.49, -80.925],
                                  [43.277, -146.68],
                                  [9.375, -92],
                                ],
                                v: [
                                  [-118.142, -367.122],
                                  [-156.824, -162.897],
                                  [-252.805, 35.358],
                                  [-248.988, 157.625],
                                  [-54.499, 281.16],
                                  [94.469, 406.947],
                                  [297.028, 282.939],
                                  [46.301, -76.65],
                                  [319.223, -230.21],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.998, -46.004],
                                  [-28.767, -127.812],
                                  [54.903, -41.022],
                                  [-128.912, -34.956],
                                  [-17.625, -67.803],
                                  [-118.895, 5.148],
                                  [131.727, 80.983],
                                  [-44.829, 145.582],
                                  [-9.349, 92],
                                ],
                                o: [
                                  [-97.752, 47.053],
                                  [28.767, 127.812],
                                  [-54.903, 41.022],
                                  [128.912, 34.956],
                                  [18.6, 69.002],
                                  [111.25, -3.499],
                                  [-131.727, -80.983],
                                  [43.404, -146.856],
                                  [9.349, -92],
                                ],
                                v: [
                                  [-117.547, -367.167],
                                  [-156.978, -163.226],
                                  [-252.785, 34.841],
                                  [-249.565, 157.74],
                                  [-54.952, 281.174],
                                  [95.486, 407.873],
                                  [296.996, 283.117],
                                  [46.356, -77.016],
                                  [319.209, -231.194],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.941, -46.118],
                                  [-28.905, -127.967],
                                  [55, -40.926],
                                  [-129.085, -34.938],
                                  [-17.632, -67.883],
                                  [-118.765, 5.088],
                                  [131.96, 81.041],
                                  [-44.927, 145.78],
                                  [-9.323, 92],
                                ],
                                o: [
                                  [-97.794, 47.147],
                                  [28.905, 127.967],
                                  [-55, 40.926],
                                  [129.085, 34.937],
                                  [18.588, 69.059],
                                  [111.265, -3.471],
                                  [-131.96, -81.041],
                                  [43.53, -147.03],
                                  [9.323, -92],
                                ],
                                v: [
                                  [-117.009, -367.265],
                                  [-157.129, -163.555],
                                  [-252.769, 34.315],
                                  [-250.203, 157.918],
                                  [-55.353, 281.185],
                                  [96.507, 408.854],
                                  [296.913, 283.3],
                                  [46.421, -77.384],
                                  [319.072, -232.171],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.885, -46.23],
                                  [-29.04, -128.12],
                                  [55.095, -40.833],
                                  [-129.254, -34.92],
                                  [-17.639, -67.961],
                                  [-118.636, 5.029],
                                  [132.189, 81.097],
                                  [-45.023, 145.974],
                                  [-9.298, 92],
                                ],
                                o: [
                                  [-97.836, 47.239],
                                  [29.04, 128.12],
                                  [-55.095, 40.833],
                                  [129.254, 34.92],
                                  [18.577, 69.115],
                                  [111.279, -3.443],
                                  [-132.189, -81.097],
                                  [43.653, -147.2],
                                  [9.298, -92],
                                ],
                                v: [
                                  [-116.408, -367.3],
                                  [-157.282, -163.945],
                                  [-252.745, 33.846],
                                  [-250.844, 158.093],
                                  [-55.637, 281.192],
                                  [97.527, 409.781],
                                  [296.773, 283.424],
                                  [46.551, -77.748],
                                  [318.991, -233.089],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.83, -46.34],
                                  [-29.172, -128.27],
                                  [55.189, -40.741],
                                  [-129.421, -34.902],
                                  [-17.646, -68.038],
                                  [-118.509, 4.972],
                                  [132.414, 81.152],
                                  [-45.118, 146.165],
                                  [-9.274, 92],
                                ],
                                o: [
                                  [-97.877, 47.33],
                                  [29.172, 128.27],
                                  [-55.189, 40.741],
                                  [129.421, 34.902],
                                  [18.566, 69.17],
                                  [111.292, -3.415],
                                  [-132.414, -81.152],
                                  [43.774, -147.368],
                                  [9.274, -92],
                                ],
                                v: [
                                  [-115.86, -367.393],
                                  [-157.494, -164.339],
                                  [-252.725, 33.369],
                                  [-251.482, 158.273],
                                  [-55.929, 281.199],
                                  [98.492, 410.652],
                                  [296.693, 283.552],
                                  [46.683, -78.167],
                                  [318.846, -234.005],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.776, -46.448],
                                  [-29.303, -128.418],
                                  [55.281, -40.65],
                                  [-129.585, -34.885],
                                  [-17.653, -68.114],
                                  [-118.385, 4.915],
                                  [132.636, 81.207],
                                  [-45.211, 146.353],
                                  [-9.249, 92],
                                ],
                                o: [
                                  [-97.918, 47.42],
                                  [29.303, 128.418],
                                  [-55.281, 40.65],
                                  [129.585, 34.885],
                                  [18.555, 69.224],
                                  [111.306, -3.388],
                                  [-132.636, -81.207],
                                  [43.893, -147.533],
                                  [9.249, -92],
                                ],
                                v: [
                                  [-115.311, -367.535],
                                  [-157.704, -164.732],
                                  [-252.646, 32.946],
                                  [-252.186, 158.396],
                                  [-56.221, 281.257],
                                  [99.518, 411.521],
                                  [296.561, 283.68],
                                  [46.826, -78.587],
                                  [318.694, -234.862],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.723, -46.554],
                                  [-29.431, -128.563],
                                  [55.371, -40.561],
                                  [-129.746, -34.868],
                                  [-17.66, -68.188],
                                  [-118.262, 4.859],
                                  [132.853, 81.26],
                                  [-45.303, 146.538],
                                  [-9.225, 92],
                                ],
                                o: [
                                  [-97.958, 47.507],
                                  [29.431, 128.563],
                                  [-55.371, 40.561],
                                  [129.746, 34.868],
                                  [18.545, 69.277],
                                  [111.319, -3.361],
                                  [-132.853, -81.26],
                                  [44.01, -147.695],
                                  [9.225, -92],
                                ],
                                v: [
                                  [-114.758, -367.618],
                                  [-157.969, -165.133],
                                  [-252.564, 32.456],
                                  [-252.892, 158.578],
                                  [-56.405, 281.258],
                                  [100.548, 412.334],
                                  [296.43, 283.806],
                                  [47.028, -79.063],
                                  [318.481, -235.774],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.67, -46.659],
                                  [-29.557, -128.706],
                                  [55.46, -40.473],
                                  [-129.905, -34.851],
                                  [-17.666, -68.261],
                                  [-118.142, 4.804],
                                  [133.068, 81.313],
                                  [-45.393, 146.72],
                                  [-9.202, 92],
                                ],
                                o: [
                                  [-97.997, 47.594],
                                  [29.557, 128.706],
                                  [-55.46, 40.473],
                                  [129.905, 34.851],
                                  [18.534, 69.329],
                                  [111.332, -3.335],
                                  [-133.068, -81.313],
                                  [44.125, -147.855],
                                  [9.202, -92],
                                ],
                                v: [
                                  [-114.204, -367.751],
                                  [-158.236, -165.592],
                                  [-252.482, 32.025],
                                  [-253.605, 158.702],
                                  [-56.53, 281.309],
                                  [101.518, 413.146],
                                  [296.304, 283.878],
                                  [47.179, -79.535],
                                  [318.262, -236.564],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.723, -46.554],
                                  [-29.431, -128.563],
                                  [55.371, -40.561],
                                  [-129.746, -34.868],
                                  [-17.66, -68.188],
                                  [-118.262, 4.859],
                                  [132.853, 81.26],
                                  [-45.303, 146.538],
                                  [-9.225, 92],
                                ],
                                o: [
                                  [-97.958, 47.507],
                                  [29.431, 128.563],
                                  [-55.371, 40.561],
                                  [129.746, 34.868],
                                  [18.545, 69.277],
                                  [111.319, -3.361],
                                  [-132.853, -81.26],
                                  [44.01, -147.695],
                                  [9.225, -92],
                                ],
                                v: [
                                  [-113.247, -367.583],
                                  [-158.521, -166.236],
                                  [-252.406, 31.311],
                                  [-254.293, 158.831],
                                  [-57.017, 281.256],
                                  [102.524, 413.684],
                                  [296.296, 283.802],
                                  [48.061, -79.557],
                                  [317.45, -237.006],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.776, -46.448],
                                  [-29.303, -128.418],
                                  [55.281, -40.65],
                                  [-129.585, -34.885],
                                  [-17.653, -68.114],
                                  [-118.385, 4.915],
                                  [132.636, 81.207],
                                  [-45.211, 146.353],
                                  [-9.249, 92],
                                ],
                                o: [
                                  [-97.918, 47.42],
                                  [29.303, 128.418],
                                  [-55.281, 40.65],
                                  [129.585, 34.885],
                                  [18.555, 69.224],
                                  [111.306, -3.388],
                                  [-132.636, -81.207],
                                  [43.893, -147.533],
                                  [9.249, -92],
                                ],
                                v: [
                                  [-112.291, -367.467],
                                  [-158.813, -166.934],
                                  [-252.39, 30.595],
                                  [-254.977, 158.895],
                                  [-57.45, 281.263],
                                  [103.532, 414.212],
                                  [296.289, 283.783],
                                  [48.948, -79.632],
                                  [316.577, -237.38],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.83, -46.34],
                                  [-29.172, -128.27],
                                  [55.189, -40.741],
                                  [-129.421, -34.902],
                                  [-17.646, -68.038],
                                  [-118.509, 4.972],
                                  [132.414, 81.152],
                                  [-45.118, 146.165],
                                  [-9.274, 92],
                                ],
                                o: [
                                  [-97.877, 47.33],
                                  [29.172, 128.27],
                                  [-55.189, 40.74],
                                  [129.421, 34.902],
                                  [18.566, 69.17],
                                  [111.292, -3.415],
                                  [-132.414, -81.152],
                                  [43.774, -147.368],
                                  [9.274, -92],
                                ],
                                v: [
                                  [-111.336, -367.354],
                                  [-159.107, -167.574],
                                  [-252.255, 29.872],
                                  [-255.657, 158.967],
                                  [-57.765, 281.267],
                                  [104.532, 414.676],
                                  [296.233, 283.704],
                                  [49.898, -79.7],
                                  [315.705, -237.695],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.885, -46.23],
                                  [-29.04, -128.12],
                                  [55.095, -40.833],
                                  [-129.254, -34.92],
                                  [-17.639, -67.961],
                                  [-118.636, 5.029],
                                  [132.189, 81.097],
                                  [-45.023, 145.974],
                                  [-9.298, 92],
                                ],
                                o: [
                                  [-97.836, 47.239],
                                  [29.04, 128.12],
                                  [-55.095, 40.833],
                                  [129.254, 34.92],
                                  [18.577, 69.115],
                                  [111.279, -3.443],
                                  [-132.189, -81.097],
                                  [43.653, -147.2],
                                  [9.298, -92],
                                ],
                                v: [
                                  [-110.379, -367.237],
                                  [-159.523, -168.272],
                                  [-252.183, 29.209],
                                  [-256.395, 159.032],
                                  [-58.087, 281.277],
                                  [105.472, 415.071],
                                  [296.174, 283.576],
                                  [50.854, -79.825],
                                  [314.83, -238.001],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.941, -46.118],
                                  [-28.905, -127.967],
                                  [55, -40.926],
                                  [-129.085, -34.937],
                                  [-17.632, -67.883],
                                  [-118.765, 5.088],
                                  [131.96, 81.041],
                                  [-44.927, 145.78],
                                  [-9.323, 92],
                                ],
                                o: [
                                  [-97.794, 47.147],
                                  [28.905, 127.967],
                                  [-55, 40.926],
                                  [129.085, 34.938],
                                  [18.588, 69.059],
                                  [111.265, -3.471],
                                  [-131.96, -81.041],
                                  [43.53, -147.03],
                                  [9.323, -92],
                                ],
                                v: [
                                  [-109.476, -367.18],
                                  [-159.883, -168.97],
                                  [-252.108, 28.544],
                                  [-257.124, 159.101],
                                  [-58.355, 281.284],
                                  [106.462, 415.464],
                                  [296.121, 283.499],
                                  [51.809, -79.944],
                                  [313.895, -238.297],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.998, -46.004],
                                  [-28.767, -127.812],
                                  [54.903, -41.022],
                                  [-128.912, -34.956],
                                  [-17.625, -67.803],
                                  [-118.895, 5.148],
                                  [131.727, 80.983],
                                  [-44.829, 145.582],
                                  [-9.349, 92],
                                ],
                                o: [
                                  [-97.752, 47.053],
                                  [28.767, 127.812],
                                  [-54.903, 41.022],
                                  [128.912, 34.956],
                                  [18.6, 69.002],
                                  [111.251, -3.499],
                                  [-131.727, -80.983],
                                  [43.404, -146.856],
                                  [9.349, -92],
                                ],
                                v: [
                                  [-108.576, -367.118],
                                  [-160.299, -169.611],
                                  [-251.977, 27.93],
                                  [-257.911, 159.111],
                                  [-58.565, 281.297],
                                  [107.392, 415.789],
                                  [296.074, 283.426],
                                  [52.717, -80.173],
                                  [313.019, -238.535],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.056, -45.889],
                                  [-28.628, -127.654],
                                  [54.805, -41.118],
                                  [-128.737, -34.974],
                                  [-17.618, -67.722],
                                  [-119.028, 5.209],
                                  [131.491, 80.925],
                                  [-44.729, 145.381],
                                  [-9.375, 92],
                                ],
                                o: [
                                  [-97.708, 46.958],
                                  [28.628, 127.654],
                                  [-54.805, 41.118],
                                  [128.737, 34.974],
                                  [18.611, 68.944],
                                  [111.236, -3.528],
                                  [-131.491, -80.925],
                                  [43.277, -146.68],
                                  [9.375, -92],
                                ],
                                v: [
                                  [-107.726, -367.118],
                                  [-160.726, -170.252],
                                  [-251.846, 27.313],
                                  [-258.631, 159.119],
                                  [-58.725, 281.365],
                                  [108.26, 416.055],
                                  [296.029, 283.3],
                                  [53.624, -80.341],
                                  [312.025, -238.762],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.115, -45.771],
                                  [-28.486, -127.494],
                                  [54.705, -41.217],
                                  [-128.559, -34.993],
                                  [-17.611, -67.64],
                                  [-119.163, 5.27],
                                  [131.25, 80.866],
                                  [-44.628, 145.177],
                                  [-9.402, 92],
                                ],
                                o: [
                                  [-97.664, 46.861],
                                  [28.486, 127.494],
                                  [-54.705, 41.217],
                                  [128.559, 34.993],
                                  [18.623, 68.885],
                                  [111.221, -3.557],
                                  [-131.25, -80.866],
                                  [43.148, -146.501],
                                  [9.402, -92],
                                ],
                                v: [
                                  [-106.882, -367.115],
                                  [-161.208, -170.893],
                                  [-251.66, 26.75],
                                  [-259.409, 159.131],
                                  [-58.827, 281.429],
                                  [109.184, 416.309],
                                  [295.99, 283.172],
                                  [54.538, -80.619],
                                  [311.087, -238.922],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.174, -45.652],
                                  [-28.342, -127.33],
                                  [54.604, -41.317],
                                  [-128.378, -35.012],
                                  [-17.603, -67.556],
                                  [-119.301, 5.333],
                                  [131.006, 80.806],
                                  [-44.524, 144.97],
                                  [-9.428, 92],
                                ],
                                o: [
                                  [-97.619, 46.763],
                                  [28.342, 127.33],
                                  [-54.604, 41.317],
                                  [128.378, 35.012],
                                  [18.635, 68.826],
                                  [111.206, -3.587],
                                  [-131.006, -80.806],
                                  [43.017, -146.319],
                                  [9.428, -92],
                                ],
                                v: [
                                  [-106.088, -367.112],
                                  [-161.755, -171.53],
                                  [-251.528, 26.185],
                                  [-260.182, 159.141],
                                  [-58.938, 281.499],
                                  [109.984, 416.5],
                                  [295.953, 283.107],
                                  [55.393, -80.895],
                                  [310.093, -239.076],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.235, -45.53],
                                  [-28.196, -127.165],
                                  [54.501, -41.418],
                                  [-128.194, -35.031],
                                  [-17.596, -67.471],
                                  [-119.44, 5.397],
                                  [130.757, 80.745],
                                  [-44.42, 144.759],
                                  [-9.456, 92],
                                ],
                                o: [
                                  [-97.574, 46.662],
                                  [28.196, 127.165],
                                  [-54.501, 41.418],
                                  [128.194, 35.031],
                                  [18.647, 68.765],
                                  [111.191, -3.617],
                                  [-130.757, -80.745],
                                  [42.883, -146.134],
                                  [9.456, -92],
                                ],
                                v: [
                                  [-105.296, -367.165],
                                  [-162.246, -172.114],
                                  [-251.344, 25.726],
                                  [-260.95, 159.098],
                                  [-58.939, 281.623],
                                  [110.839, 416.742],
                                  [295.923, 282.988],
                                  [56.202, -81.164],
                                  [309.098, -239.167],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.297, -45.407],
                                  [-28.047, -126.996],
                                  [54.396, -41.522],
                                  [-128.007, -35.051],
                                  [-17.588, -67.385],
                                  [-119.582, 5.461],
                                  [130.505, 80.683],
                                  [-44.313, 144.544],
                                  [-9.483, 92],
                                ],
                                o: [
                                  [-97.528, 46.561],
                                  [28.047, 126.996],
                                  [-54.396, 41.522],
                                  [128.007, 35.051],
                                  [18.659, 68.703],
                                  [111.176, -3.648],
                                  [-130.505, -80.683],
                                  [42.748, -145.945],
                                  [9.483, -92],
                                ],
                                v: [
                                  [-104.506, -367.217],
                                  [-162.792, -172.699],
                                  [-251.157, 25.211],
                                  [-261.775, 159.054],
                                  [-58.882, 281.749],
                                  [111.627, 416.92],
                                  [295.843, 282.864],
                                  [57.069, -81.546],
                                  [308.1, -239.253],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.359, -45.281],
                                  [-27.896, -126.825],
                                  [54.289, -41.627],
                                  [-127.817, -35.071],
                                  [-17.58, -67.297],
                                  [-119.726, 5.527],
                                  [130.248, 80.62],
                                  [-44.205, 144.326],
                                  [-9.512, 92],
                                ],
                                o: [
                                  [-97.481, 46.457],
                                  [27.896, 126.825],
                                  [-54.289, 41.627],
                                  [127.817, 35.071],
                                  [18.672, 68.641],
                                  [111.16, -3.68],
                                  [-130.248, -80.62],
                                  [42.61, -145.754],
                                  [9.512, -92],
                                ],
                                v: [
                                  [-103.765, -367.324],
                                  [-163.403, -173.283],
                                  [-250.976, 24.808],
                                  [-262.533, 158.952],
                                  [-58.778, 281.875],
                                  [112.418, 417.034],
                                  [295.822, 282.75],
                                  [57.826, -81.858],
                                  [307.105, -239.27],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.423, -45.154],
                                  [-27.742, -126.651],
                                  [54.181, -41.733],
                                  [-127.624, -35.091],
                                  [-17.572, -67.208],
                                  [-119.873, 5.594],
                                  [129.987, 80.556],
                                  [-44.095, 144.105],
                                  [-9.54, 92],
                                ],
                                o: [
                                  [-97.433, 46.352],
                                  [27.742, 126.651],
                                  [-54.181, 41.734],
                                  [127.624, 35.091],
                                  [18.685, 68.577],
                                  [111.144, -3.712],
                                  [-129.987, -80.556],
                                  [42.469, -145.56],
                                  [9.54, -92],
                                ],
                                v: [
                                  [-103.083, -367.43],
                                  [-163.958, -173.806],
                                  [-250.735, 24.395],
                                  [-263.348, 158.798],
                                  [-58.675, 281.997],
                                  [113.2, 417.198],
                                  [295.809, 282.579],
                                  [58.641, -82.287],
                                  [306.051, -239.219],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.488, -45.025],
                                  [-27.586, -126.474],
                                  [54.071, -41.842],
                                  [-127.428, -35.112],
                                  [-17.564, -67.117],
                                  [-120.022, 5.662],
                                  [129.722, 80.49],
                                  [-43.984, 143.88],
                                  [-9.57, 92],
                                ],
                                o: [
                                  [-97.384, 46.245],
                                  [27.586, 126.474],
                                  [-54.071, 41.842],
                                  [127.428, 35.112],
                                  [18.698, 68.512],
                                  [111.128, -3.744],
                                  [-129.722, -80.49],
                                  [42.327, -145.362],
                                  [9.569, -92],
                                ],
                                v: [
                                  [-102.397, -367.591],
                                  [-164.515, -174.392],
                                  [-250.553, 23.978],
                                  [-264.152, 158.644],
                                  [-58.468, 282.115],
                                  [113.916, 417.299],
                                  [295.742, 282.465],
                                  [59.411, -82.71],
                                  [305.001, -239.22],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.554, -44.893],
                                  [-27.427, -126.294],
                                  [53.959, -41.952],
                                  [-127.228, -35.133],
                                  [-17.556, -67.025],
                                  [-120.173, 5.731],
                                  [129.453, 80.424],
                                  [-43.87, 143.652],
                                  [-9.599, 92],
                                ],
                                o: [
                                  [-97.335, 46.137],
                                  [27.427, 126.294],
                                  [-53.959, 41.952],
                                  [127.228, 35.133],
                                  [18.711, 68.447],
                                  [111.112, -3.777],
                                  [-129.453, -80.424],
                                  [42.182, -145.162],
                                  [9.599, -92],
                                ],
                                v: [
                                  [-101.771, -367.75],
                                  [-165.075, -175.035],
                                  [-250.321, 23.676],
                                  [-264.955, 158.494],
                                  [-58.205, 282.29],
                                  [114.633, 417.398],
                                  [295.746, 282.357],
                                  [60.123, -83.124],
                                  [303.95, -239.157],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.62, -44.759],
                                  [-27.266, -126.112],
                                  [53.845, -42.064],
                                  [-127.026, -35.154],
                                  [-17.547, -66.931],
                                  [-120.327, 5.801],
                                  [129.179, 80.357],
                                  [-43.755, 143.419],
                                  [-9.629, 92],
                                ],
                                o: [
                                  [-97.285, 46.026],
                                  [27.266, 126.112],
                                  [-53.845, 42.064],
                                  [127.026, 35.154],
                                  [18.724, 68.38],
                                  [111.095, -3.81],
                                  [-129.179, -80.357],
                                  [42.035, -144.958],
                                  [9.629, -92],
                                ],
                                v: [
                                  [-101.198, -367.964],
                                  [-165.637, -175.621],
                                  [-250.085, 23.364],
                                  [-265.697, 158.288],
                                  [-57.954, 282.467],
                                  [115.342, 417.486],
                                  [295.753, 282.192],
                                  [60.842, -83.594],
                                  [302.953, -239.14],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.688, -44.623],
                                  [-27.102, -125.926],
                                  [53.73, -42.178],
                                  [-126.82, -35.176],
                                  [-17.539, -66.836],
                                  [-120.483, 5.873],
                                  [128.901, 80.289],
                                  [-43.638, 143.183],
                                  [-9.66, 92],
                                ],
                                o: [
                                  [-97.234, 45.914],
                                  [27.102, 125.926],
                                  [-53.73, 42.178],
                                  [126.82, 35.176],
                                  [18.738, 68.312],
                                  [111.078, -3.844],
                                  [-128.901, -80.289],
                                  [41.886, -144.751],
                                  [9.66, -92],
                                ],
                                v: [
                                  [-100.622, -368.176],
                                  [-166.145, -176.264],
                                  [-249.851, 23.104],
                                  [-266.489, 158.025],
                                  [-57.59, 282.638],
                                  [115.984, 417.515],
                                  [295.763, 282.09],
                                  [61.504, -84.112],
                                  [301.905, -239.059],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.757, -44.485],
                                  [-26.936, -125.738],
                                  [53.613, -42.293],
                                  [-126.611, -35.198],
                                  [-17.53, -66.74],
                                  [-120.642, 5.945],
                                  [128.619, 80.219],
                                  [-43.519, 142.943],
                                  [-9.691, 92],
                                ],
                                o: [
                                  [-97.182, 45.8],
                                  [26.935, 125.738],
                                  [-53.613, 42.294],
                                  [126.611, 35.198],
                                  [18.751, 68.243],
                                  [111.061, -3.879],
                                  [-128.619, -80.219],
                                  [41.734, -144.54],
                                  [9.691, -92],
                                ],
                                v: [
                                  [-100.098, -368.386],
                                  [-166.656, -176.907],
                                  [-249.562, 22.845],
                                  [-267.281, 157.769],
                                  [-57.239, 282.803],
                                  [116.628, 417.594],
                                  [295.783, 281.988],
                                  [62.179, -84.622],
                                  [300.855, -239.028],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.827, -44.345],
                                  [-26.766, -125.546],
                                  [53.493, -42.411],
                                  [-126.398, -35.22],
                                  [-17.522, -66.642],
                                  [-120.803, 6.019],
                                  [128.331, 80.149],
                                  [-43.398, 142.7],
                                  [-9.722, 92],
                                ],
                                o: [
                                  [-97.129, 45.685],
                                  [26.766, 125.546],
                                  [-53.493, 42.411],
                                  [126.398, 35.22],
                                  [18.765, 68.173],
                                  [111.043, -3.914],
                                  [-128.332, -80.149],
                                  [41.58, -144.326],
                                  [9.722, -92],
                                ],
                                v: [
                                  [-99.627, -368.657],
                                  [-167.17, -177.551],
                                  [-249.332, 22.632],
                                  [-268.005, 157.519],
                                  [-56.777, 283.026],
                                  [117.206, 417.552],
                                  [295.812, 281.831],
                                  [62.854, -85.13],
                                  [299.809, -238.929],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.899, -44.203],
                                  [-26.594, -125.352],
                                  [53.372, -42.53],
                                  [-126.182, -35.243],
                                  [-17.513, -66.542],
                                  [-120.967, 6.094],
                                  [128.04, 80.077],
                                  [-43.275, 142.452],
                                  [-9.754, 92],
                                ],
                                o: [
                                  [-97.076, 45.567],
                                  [26.594, 125.352],
                                  [-53.372, 42.53],
                                  [126.182, 35.243],
                                  [18.78, 68.101],
                                  [111.025, -3.949],
                                  [-128.04, -80.077],
                                  [41.423, -144.109],
                                  [9.754, -92],
                                ],
                                v: [
                                  [-99.214, -368.975],
                                  [-167.68, -178.194],
                                  [-249.047, 22.476],
                                  [-268.786, 157.214],
                                  [-56.322, 283.292],
                                  [117.78, 417.561],
                                  [295.838, 281.737],
                                  [63.417, -85.692],
                                  [298.814, -238.879],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.971, -44.058],
                                  [-26.42, -125.154],
                                  [53.249, -42.651],
                                  [-125.963, -35.266],
                                  [-17.504, -66.441],
                                  [-121.133, 6.17],
                                  [127.744, 80.004],
                                  [-43.15, 142.201],
                                  [-9.787, 92],
                                ],
                                o: [
                                  [-97.022, 45.448],
                                  [26.42, 125.154],
                                  [-53.249, 42.651],
                                  [125.963, 35.266],
                                  [18.794, 68.029],
                                  [111.007, -3.985],
                                  [-127.744, -80.004],
                                  [41.264, -143.888],
                                  [9.787, -92],
                                ],
                                v: [
                                  [-98.797, -369.291],
                                  [-168.199, -178.837],
                                  [-248.771, 22.364],
                                  [-269.504, 156.916],
                                  [-55.88, 283.502],
                                  [118.349, 417.512],
                                  [295.931, 281.581],
                                  [64.049, -86.246],
                                  [297.766, -238.765],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.045, -43.911],
                                  [-26.243, -124.953],
                                  [53.124, -42.775],
                                  [-125.74, -35.289],
                                  [-17.494, -66.338],
                                  [-121.302, 6.247],
                                  [127.443, 79.93],
                                  [-43.023, 141.945],
                                  [-9.82, 92],
                                ],
                                o: [
                                  [-96.967, 45.326],
                                  [26.242, 124.953],
                                  [-53.124, 42.775],
                                  [125.74, 35.289],
                                  [18.809, 67.955],
                                  [110.989, -4.022],
                                  [-127.443, -79.93],
                                  [41.102, -143.664],
                                  [9.82, -92],
                                ],
                                v: [
                                  [-98.431, -369.667],
                                  [-168.659, -179.536],
                                  [-248.491, 22.253],
                                  [-270.216, 156.62],
                                  [-55.391, 283.824],
                                  [118.909, 417.456],
                                  [295.965, 281.488],
                                  [64.625, -86.847],
                                  [296.725, -238.644],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.119, -43.762],
                                  [-26.062, -124.749],
                                  [52.997, -42.9],
                                  [-125.514, -35.313],
                                  [-17.485, -66.233],
                                  [-121.474, 6.325],
                                  [127.137, 79.855],
                                  [-42.894, 141.686],
                                  [-9.854, 92],
                                ],
                                o: [
                                  [-96.911, 45.203],
                                  [26.062, 124.749],
                                  [-52.997, 42.9],
                                  [125.514, 35.313],
                                  [18.824, 67.881],
                                  [110.97, -4.06],
                                  [-127.137, -79.855],
                                  [40.938, -143.436],
                                  [9.854, -92],
                                ],
                                v: [
                                  [-98.117, -370.033],
                                  [-169.115, -180.186],
                                  [-248.27, 22.135],
                                  [-270.922, 156.268],
                                  [-54.854, 284.132],
                                  [119.408, 417.393],
                                  [296.066, 281.34],
                                  [65.152, -87.446],
                                  [295.676, -238.572],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.195, -43.61],
                                  [-25.879, -124.542],
                                  [52.868, -43.027],
                                  [-125.284, -35.337],
                                  [-17.476, -66.127],
                                  [-121.649, 6.405],
                                  [126.826, 79.779],
                                  [-42.763, 141.422],
                                  [-9.888, 92],
                                ],
                                o: [
                                  [-96.854, 45.078],
                                  [25.879, 124.542],
                                  [-52.868, 43.027],
                                  [125.284, 35.337],
                                  [18.839, 67.805],
                                  [110.951, -4.098],
                                  [-126.826, -79.779],
                                  [40.771, -143.205],
                                  [9.888, -92],
                                ],
                                v: [
                                  [-97.798, -370.46],
                                  [-169.581, -180.885],
                                  [-247.988, 22.005],
                                  [-271.629, 155.926],
                                  [-54.326, 284.439],
                                  [119.846, 417.274],
                                  [296.226, 281.249],
                                  [65.679, -88.036],
                                  [294.633, -238.43],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.272, -43.456],
                                  [-25.693, -124.332],
                                  [52.737, -43.156],
                                  [-125.05, -35.362],
                                  [-17.466, -66.019],
                                  [-121.826, 6.486],
                                  [126.511, 79.701],
                                  [-42.631, 141.154],
                                  [-9.922, 92],
                                ],
                                o: [
                                  [-96.796, 44.951],
                                  [25.693, 124.332],
                                  [-52.737, 43.156],
                                  [125.05, 35.362],
                                  [18.854, 67.728],
                                  [110.932, -4.136],
                                  [-126.511, -79.701],
                                  [40.601, -142.97],
                                  [9.922, -92],
                                ],
                                v: [
                                  [-97.481, -370.876],
                                  [-169.987, -181.535],
                                  [-247.764, 21.932],
                                  [-272.322, 155.536],
                                  [-53.752, 284.793],
                                  [120.337, 417.148],
                                  [296.334, 281.103],
                                  [66.158, -88.679],
                                  [293.645, -238.281],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.35, -43.299],
                                  [-25.505, -124.118],
                                  [52.604, -43.287],
                                  [-124.813, -35.387],
                                  [-17.456, -65.909],
                                  [-122.006, 6.568],
                                  [126.19, 79.622],
                                  [-42.496, 140.882],
                                  [-9.958, 92],
                                ],
                                o: [
                                  [-96.737, 44.822],
                                  [25.505, 124.118],
                                  [-52.604, 43.287],
                                  [124.813, 35.387],
                                  [18.87, 67.65],
                                  [110.912, -4.175],
                                  [-126.19, -79.622],
                                  [40.429, -142.731],
                                  [9.958, -92],
                                ],
                                v: [
                                  [-97.166, -371.338],
                                  [-170.397, -182.24],
                                  [-247.486, 21.844],
                                  [-272.961, 155.148],
                                  [-53.193, 285.139],
                                  [120.76, 417.022],
                                  [296.447, 280.959],
                                  [66.644, -89.307],
                                  [292.601, -238.123],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.43, -43.14],
                                  [-25.313, -123.901],
                                  [52.469, -43.42],
                                  [-124.572, -35.412],
                                  [-17.446, -65.798],
                                  [-122.189, 6.651],
                                  [125.865, 79.542],
                                  [-42.358, 140.606],
                                  [-9.993, 92],
                                ],
                                o: [
                                  [-96.678, 44.691],
                                  [25.313, 123.901],
                                  [-52.469, 43.42],
                                  [124.572, 35.412],
                                  [18.886, 67.57],
                                  [110.893, -4.215],
                                  [-125.865, -79.542],
                                  [40.254, -142.489],
                                  [9.994, -92],
                                ],
                                v: [
                                  [-96.853, -371.789],
                                  [-170.81, -182.89],
                                  [-247.266, 21.757],
                                  [-273.593, 154.77],
                                  [-52.638, 285.483],
                                  [121.123, 416.834],
                                  [296.619, 280.871],
                                  [67.082, -89.932],
                                  [291.557, -238.014],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.511, -42.979],
                                  [-25.118, -123.68],
                                  [52.332, -43.555],
                                  [-124.327, -35.438],
                                  [-17.436, -65.685],
                                  [-122.375, 6.736],
                                  [125.534, 79.461],
                                  [-42.219, 140.325],
                                  [-10.03, 92],
                                ],
                                o: [
                                  [-96.617, 44.557],
                                  [25.118, 123.68],
                                  [-52.332, 43.555],
                                  [124.327, 35.438],
                                  [18.902, 67.489],
                                  [110.872, -4.255],
                                  [-125.534, -79.461],
                                  [40.077, -142.242],
                                  [10.03, -92],
                                ],
                                v: [
                                  [-96.542, -372.285],
                                  [-171.22, -183.595],
                                  [-247.041, 21.657],
                                  [-274.163, 154.395],
                                  [-52.044, 285.873],
                                  [121.537, 416.639],
                                  [296.795, 280.729],
                                  [67.473, -90.603],
                                  [290.519, -237.84],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.593, -42.815],
                                  [-24.92, -123.456],
                                  [52.192, -43.693],
                                  [-124.078, -35.464],
                                  [-17.426, -65.57],
                                  [-122.563, 6.822],
                                  [125.199, 79.379],
                                  [-42.078, 140.04],
                                  [-10.067, 92],
                                ],
                                o: [
                                  [-96.556, 44.422],
                                  [24.92, 123.456],
                                  [-52.192, 43.693],
                                  [124.078, 35.464],
                                  [18.919, 67.407],
                                  [110.852, -4.296],
                                  [-125.199, -79.379],
                                  [39.896, -141.992],
                                  [10.067, -92],
                                ],
                                v: [
                                  [-96.283, -372.831],
                                  [-171.625, -184.252],
                                  [-246.873, 21.604],
                                  [-274.735, 153.975],
                                  [-51.461, 286.253],
                                  [121.899, 416.444],
                                  [297.037, 280.588],
                                  [67.919, -91.257],
                                  [289.482, -237.658],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.676, -42.648],
                                  [-24.719, -123.229],
                                  [52.051, -43.832],
                                  [-123.826, -35.49],
                                  [-17.416, -65.454],
                                  [-122.755, 6.91],
                                  [124.858, 79.295],
                                  [-41.934, 139.751],
                                  [-10.104, 92],
                                ],
                                o: [
                                  [-96.493, 44.285],
                                  [24.719, 123.229],
                                  [-52.051, 43.832],
                                  [123.826, 35.49],
                                  [18.935, 67.324],
                                  [110.831, -4.338],
                                  [-124.858, -79.295],
                                  [39.713, -141.738],
                                  [10.104, -92],
                                ],
                                v: [
                                  [-96.033, -373.358],
                                  [-171.986, -184.956],
                                  [-246.644, 21.551],
                                  [-275.293, 153.559],
                                  [-50.833, 286.63],
                                  [122.193, 416.236],
                                  [297.222, 280.456],
                                  [68.271, -91.901],
                                  [288.492, -237.413],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.76, -42.479],
                                  [-24.515, -122.998],
                                  [51.907, -43.974],
                                  [-123.57, -35.517],
                                  [-17.405, -65.335],
                                  [-122.949, 6.998],
                                  [124.512, 79.21],
                                  [-41.788, 139.457],
                                  [-10.142, 92],
                                ],
                                o: [
                                  [-96.43, 44.145],
                                  [24.515, 122.998],
                                  [-51.907, 43.974],
                                  [123.57, 35.517],
                                  [18.952, 67.24],
                                  [110.81, -4.38],
                                  [-124.512, -79.21],
                                  [39.527, -141.48],
                                  [10.142, -92],
                                ],
                                v: [
                                  [-95.78, -373.879],
                                  [-172.336, -185.613],
                                  [-246.479, 21.484],
                                  [-275.797, 153.154],
                                  [-50.216, 287.052],
                                  [122.545, 415.973],
                                  [297.465, 280.374],
                                  [68.624, -92.527],
                                  [287.455, -237.214],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.846, -42.307],
                                  [-24.308, -122.763],
                                  [51.761, -44.118],
                                  [-123.309, -35.545],
                                  [-17.394, -65.215],
                                  [-123.147, 7.089],
                                  [124.16, 79.123],
                                  [-41.64, 139.159],
                                  [-10.181, 92],
                                ],
                                o: [
                                  [-96.365, 44.004],
                                  [24.308, 122.763],
                                  [-51.761, 44.118],
                                  [123.309, 35.545],
                                  [18.969, 67.154],
                                  [110.788, -4.423],
                                  [-124.16, -79.123],
                                  [39.338, -141.219],
                                  [10.181, -92],
                                ],
                                v: [
                                  [-95.53, -374.449],
                                  [-172.642, -186.271],
                                  [-246.309, 21.417],
                                  [-276.295, 152.698],
                                  [-49.611, 287.407],
                                  [122.838, 415.703],
                                  [297.713, 280.237],
                                  [68.93, -93.205],
                                  [286.417, -237.007],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.934, -42.133],
                                  [-24.098, -122.525],
                                  [51.613, -44.264],
                                  [-123.045, -35.573],
                                  [-17.383, -65.093],
                                  [-123.347, 7.18],
                                  [123.803, 79.036],
                                  [-41.49, 138.856],
                                  [-10.22, 92],
                                ],
                                o: [
                                  [-96.3, 43.86],
                                  [24.098, 122.525],
                                  [-51.613, 44.264],
                                  [123.045, 35.573],
                                  [18.987, 67.066],
                                  [110.767, -4.467],
                                  [-123.803, -79.036],
                                  [39.146, -140.953],
                                  [10.22, -92],
                                ],
                                v: [
                                  [-95.338, -375.053],
                                  [-172.998, -186.928],
                                  [-246.132, 21.388],
                                  [-276.74, 152.301],
                                  [-49.017, 287.813],
                                  [123.134, 415.419],
                                  [298.019, 280.102],
                                  [69.253, -93.864],
                                  [285.435, -236.792],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.022, -41.956],
                                  [-23.884, -122.283],
                                  [51.462, -44.412],
                                  [-122.777, -35.601],
                                  [-17.372, -64.969],
                                  [-123.551, 7.273],
                                  [123.441, 78.947],
                                  [-41.337, 138.548],
                                  [-10.26, 92],
                                ],
                                o: [
                                  [-96.233, 43.714],
                                  [23.884, 122.283],
                                  [-51.462, 44.412],
                                  [122.777, 35.601],
                                  [19.004, 66.978],
                                  [110.744, -4.511],
                                  [-123.441, -78.947],
                                  [38.951, -140.683],
                                  [10.26, -92],
                                ],
                                v: [
                                  [-95.151, -375.649],
                                  [-173.295, -187.594],
                                  [-246.018, 21.36],
                                  [-277.18, 151.861],
                                  [-48.388, 288.208],
                                  [123.372, 415.144],
                                  [298.267, 279.922],
                                  [69.577, -94.511],
                                  [284.398, -236.512],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.112, -41.776],
                                  [-23.667, -122.038],
                                  [51.31, -44.563],
                                  [-122.504, -35.629],
                                  [-17.361, -64.843],
                                  [-123.758, 7.368],
                                  [123.073, 78.856],
                                  [-41.182, 138.236],
                                  [-10.3, 92],
                                ],
                                o: [
                                  [-96.166, 43.565],
                                  [23.667, 122.038],
                                  [-51.31, 44.563],
                                  [122.504, 35.629],
                                  [19.022, 66.888],
                                  [110.722, -4.556],
                                  [-123.073, -78.856],
                                  [38.754, -140.408],
                                  [10.3, -92],
                                ],
                                v: [
                                  [-94.968, -376.23],
                                  [-173.542, -188.252],
                                  [-245.898, 21.323],
                                  [-277.559, 151.427],
                                  [-47.764, 288.598],
                                  [123.605, 414.799],
                                  [298.581, 279.791],
                                  [69.801, -95.147],
                                  [283.416, -236.278],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.203, -41.594],
                                  [-23.447, -121.789],
                                  [51.154, -44.715],
                                  [-122.228, -35.659],
                                  [-17.35, -64.715],
                                  [-123.967, 7.463],
                                  [122.699, 78.764],
                                  [-41.024, 137.919],
                                  [-10.341, 92],
                                ],
                                o: [
                                  [-96.098, 43.415],
                                  [23.447, 121.789],
                                  [-51.154, 44.715],
                                  [122.228, 35.659],
                                  [19.041, 66.797],
                                  [110.699, -4.602],
                                  [-122.699, -78.764],
                                  [38.553, -140.13],
                                  [10.341, -92],
                                ],
                                v: [
                                  [-94.835, -376.796],
                                  [-173.854, -188.864],
                                  [-245.779, 21.278],
                                  [-277.941, 150.951],
                                  [-47.215, 288.975],
                                  [123.842, 414.456],
                                  [298.954, 279.661],
                                  [70.09, -95.771],
                                  [282.426, -235.981],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.296, -41.408],
                                  [-23.224, -121.536],
                                  [50.997, -44.871],
                                  [-121.947, -35.688],
                                  [-17.338, -64.586],
                                  [-124.181, 7.561],
                                  [122.32, 78.671],
                                  [-40.865, 137.597],
                                  [-10.383, 92],
                                ],
                                o: [
                                  [-96.028, 43.262],
                                  [23.224, 121.536],
                                  [-50.997, 44.871],
                                  [121.947, 35.688],
                                  [19.059, 66.704],
                                  [110.676, -4.648],
                                  [-122.32, -78.671],
                                  [38.349, -139.847],
                                  [10.383, -92],
                                ],
                                v: [
                                  [-94.708, -377.399],
                                  [-174.108, -189.531],
                                  [-245.715, 21.279],
                                  [-278.263, 150.527],
                                  [-46.616, 289.393],
                                  [124.021, 414.107],
                                  [299.268, 279.533],
                                  [70.28, -96.438],
                                  [281.39, -235.675],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.39, -41.22],
                                  [-22.997, -121.279],
                                  [50.837, -45.028],
                                  [-121.662, -35.718],
                                  [-17.326, -64.454],
                                  [-124.397, 7.659],
                                  [121.935, 78.576],
                                  [-40.702, 137.27],
                                  [-10.426, 92],
                                ],
                                o: [
                                  [-95.958, 43.107],
                                  [22.997, 121.279],
                                  [-50.837, 45.028],
                                  [121.662, 35.718],
                                  [19.078, 66.61],
                                  [110.653, -4.695],
                                  [-121.935, -78.576],
                                  [38.142, -139.561],
                                  [10.426, -92],
                                ],
                                v: [
                                  [-94.577, -377.994],
                                  [-174.304, -190.152],
                                  [-245.651, 21.217],
                                  [-278.58, 150.125],
                                  [-46.039, 289.751],
                                  [124.25, 413.696],
                                  [299.64, 279.353],
                                  [70.526, -97.029],
                                  [280.409, -235.359],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.485, -41.029],
                                  [-22.766, -121.018],
                                  [50.675, -45.188],
                                  [-121.372, -35.749],
                                  [-17.314, -64.32],
                                  [-124.617, 7.76],
                                  [121.544, 78.48],
                                  [-40.538, 136.938],
                                  [-10.468, 92],
                                ],
                                o: [
                                  [-95.886, 42.949],
                                  [22.766, 121.018],
                                  [-50.675, 45.188],
                                  [121.372, 35.748],
                                  [19.097, 66.515],
                                  [110.629, -4.743],
                                  [-121.544, -78.48],
                                  [37.932, -139.269],
                                  [10.468, -92],
                                ],
                                v: [
                                  [-94.505, -378.634],
                                  [-174.566, -190.766],
                                  [-245.579, 21.209],
                                  [-278.847, 149.667],
                                  [-45.421, 290.158],
                                  [124.43, 413.288],
                                  [300.016, 279.22],
                                  [70.682, -97.661],
                                  [279.428, -235.043],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.582, -40.835],
                                  [-22.532, -120.753],
                                  [50.51, -45.351],
                                  [-121.078, -35.779],
                                  [-17.302, -64.185],
                                  [-124.84, 7.862],
                                  [121.147, 78.383],
                                  [-40.37, 136.601],
                                  [-10.512, 92],
                                ],
                                o: [
                                  [-95.813, 42.789],
                                  [22.532, 120.753],
                                  [-50.51, 45.35],
                                  [121.078, 35.779],
                                  [19.116, 66.418],
                                  [110.604, -4.791],
                                  [-121.147, -78.383],
                                  [37.719, -138.974],
                                  [10.512, -92],
                                ],
                                v: [
                                  [-94.438, -379.193],
                                  [-174.769, -191.336],
                                  [-245.562, 21.184],
                                  [-279.055, 149.224],
                                  [-44.871, 290.496],
                                  [124.607, 412.873],
                                  [300.449, 279.044],
                                  [70.85, -98.227],
                                  [278.493, -234.718],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.681, -40.638],
                                  [-22.295, -120.484],
                                  [50.343, -45.515],
                                  [-120.78, -35.811],
                                  [-17.29, -64.047],
                                  [-125.066, 7.965],
                                  [120.744, 78.284],
                                  [-40.201, 136.259],
                                  [-10.556, 92],
                                ],
                                o: [
                                  [-95.739, 42.627],
                                  [22.295, 120.484],
                                  [-50.343, 45.515],
                                  [120.78, 35.811],
                                  [19.136, 66.319],
                                  [110.58, -4.84],
                                  [-120.744, -78.284],
                                  [37.502, -138.673],
                                  [10.556, -92],
                                ],
                                v: [
                                  [-94.421, -379.796],
                                  [-174.977, -191.916],
                                  [-245.544, 21.211],
                                  [-279.258, 148.787],
                                  [-44.335, 290.873],
                                  [124.726, 412.461],
                                  [300.877, 278.861],
                                  [71.019, -98.833],
                                  [277.512, -234.374],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.781, -40.439],
                                  [-22.054, -120.211],
                                  [50.173, -45.683],
                                  [-120.477, -35.843],
                                  [-17.277, -63.907],
                                  [-125.296, 8.07],
                                  [120.335, 78.183],
                                  [-40.028, 135.912],
                                  [-10.601, 92],
                                ],
                                o: [
                                  [-95.664, 42.462],
                                  [22.054, 120.211],
                                  [-50.173, 45.683],
                                  [120.477, 35.843],
                                  [19.156, 66.219],
                                  [110.555, -4.89],
                                  [-120.335, -78.183],
                                  [37.282, -138.369],
                                  [10.601, -92],
                                ],
                                v: [
                                  [-94.41, -380.38],
                                  [-175.127, -192.488],
                                  [-245.527, 21.176],
                                  [-279.404, 148.357],
                                  [-43.867, 291.19],
                                  [124.851, 412.043],
                                  [301.308, 278.69],
                                  [71.156, -99.425],
                                  [276.577, -234.039],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.882, -40.236],
                                  [-21.809, -119.934],
                                  [50, -45.853],
                                  [-120.17, -35.875],
                                  [-17.265, -63.765],
                                  [-125.529, 8.176],
                                  [119.92, 78.081],
                                  [-39.853, 135.56],
                                  [-10.647, 92],
                                ],
                                o: [
                                  [-95.588, 42.294],
                                  [21.809, 119.934],
                                  [-50, 45.853],
                                  [120.17, 35.875],
                                  [19.176, 66.118],
                                  [110.529, -4.941],
                                  [-119.92, -78.081],
                                  [37.059, -138.059],
                                  [10.647, -92],
                                ],
                                v: [
                                  [-94.459, -380.953],
                                  [-175.281, -193.071],
                                  [-245.562, 21.14],
                                  [-279.5, 147.996],
                                  [-43.359, 291.553],
                                  [124.973, 411.565],
                                  [301.742, 278.511],
                                  [71.241, -99.994],
                                  [275.589, -233.631],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.985, -40.03],
                                  [-21.561, -119.653],
                                  [49.825, -46.025],
                                  [-119.858, -35.908],
                                  [-17.252, -63.621],
                                  [-125.766, 8.284],
                                  [119.499, 77.977],
                                  [-39.676, 135.202],
                                  [-10.693, 92],
                                ],
                                o: [
                                  [-95.511, 42.125],
                                  [21.561, 119.653],
                                  [-49.825, 46.025],
                                  [119.858, 35.908],
                                  [19.197, 66.015],
                                  [110.504, -4.992],
                                  [-119.499, -77.978],
                                  [36.833, -137.746],
                                  [10.693, -92],
                                ],
                                v: [
                                  [-94.494, -381.505],
                                  [-175.44, -193.603],
                                  [-245.641, 21.14],
                                  [-279.593, 147.58],
                                  [-42.867, 291.848],
                                  [125.091, 411.1],
                                  [302.233, 278.335],
                                  [71.348, -100.548],
                                  [274.654, -233.215],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.09, -39.821],
                                  [-21.309, -119.368],
                                  [49.648, -46.2],
                                  [-119.541, -35.941],
                                  [-17.239, -63.475],
                                  [-126.006, 8.394],
                                  [119.071, 77.872],
                                  [-39.496, 134.839],
                                  [-10.74, 92],
                                ],
                                o: [
                                  [-95.433, 41.952],
                                  [21.309, 119.368],
                                  [-49.648, 46.2],
                                  [119.541, 35.941],
                                  [19.218, 65.91],
                                  [110.478, -5.045],
                                  [-119.071, -77.872],
                                  [36.603, -137.427],
                                  [10.74, -92],
                                ],
                                v: [
                                  [-94.536, -382.099],
                                  [-175.55, -194.146],
                                  [-245.666, 21.139],
                                  [-279.639, 147.128],
                                  [-42.442, 292.178],
                                  [125.216, 410.629],
                                  [302.717, 278.151],
                                  [71.458, -101.035],
                                  [273.773, -232.851],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.196, -39.609],
                                  [-21.053, -119.078],
                                  [49.468, -46.378],
                                  [-119.22, -35.975],
                                  [-17.226, -63.326],
                                  [-126.25, 8.505],
                                  [118.637, 77.766],
                                  [-39.313, 134.47],
                                  [-10.788, 92],
                                ],
                                o: [
                                  [-95.353, 41.777],
                                  [21.053, 119.078],
                                  [-49.468, 46.377],
                                  [119.22, 35.975],
                                  [19.239, 65.804],
                                  [110.451, -5.098],
                                  [-118.637, -77.766],
                                  [36.37, -137.104],
                                  [10.788, -92],
                                ],
                                v: [
                                  [-94.637, -382.619],
                                  [-175.656, -194.681],
                                  [-245.806, 21.085],
                                  [-279.629, 146.737],
                                  [-42.033, 292.457],
                                  [125.285, 410.153],
                                  [303.257, 277.979],
                                  [71.527, -101.551],
                                  [272.829, -232.425],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.303, -39.394],
                                  [-20.794, -118.785],
                                  [49.285, -46.558],
                                  [-118.894, -36.009],
                                  [-17.212, -63.176],
                                  [-126.497, 8.618],
                                  [118.196, 77.657],
                                  [-39.127, 134.097],
                                  [-10.836, 92],
                                ],
                                o: [
                                  [-95.273, 41.6],
                                  [20.794, 118.785],
                                  [-49.285, 46.558],
                                  [118.894, 36.009],
                                  [19.261, 65.697],
                                  [110.424, -5.152],
                                  [-118.196, -77.657],
                                  [36.133, -136.775],
                                  [10.836, -92],
                                ],
                                v: [
                                  [-94.787, -383.126],
                                  [-175.757, -195.176],
                                  [-245.935, 21.064],
                                  [-279.563, 146.363],
                                  [-41.639, 292.709],
                                  [125.361, 409.681],
                                  [303.799, 277.747],
                                  [71.567, -102.061],
                                  [271.938, -231.999],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.412, -39.175],
                                  [-20.53, -118.486],
                                  [49.099, -46.741],
                                  [-118.563, -36.044],
                                  [-17.198, -63.023],
                                  [-126.748, 8.733],
                                  [117.75, 77.548],
                                  [-38.939, 133.717],
                                  [-10.886, 92],
                                ],
                                o: [
                                  [-95.191, 41.42],
                                  [20.53, 118.486],
                                  [-49.099, 46.741],
                                  [118.563, 36.044],
                                  [19.282, 65.588],
                                  [110.397, -5.206],
                                  [-117.749, -77.548],
                                  [35.893, -136.442],
                                  [10.886, -92],
                                ],
                                v: [
                                  [-94.881, -383.663],
                                  [-175.862, -195.631],
                                  [-246.052, 21.043],
                                  [-279.495, 145.988],
                                  [-41.313, 292.963],
                                  [125.434, 409.161],
                                  [304.334, 277.518],
                                  [71.61, -102.545],
                                  [271.046, -231.501],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.523, -38.954],
                                  [-20.263, -118.184],
                                  [48.911, -46.926],
                                  [-118.227, -36.079],
                                  [-17.185, -62.868],
                                  [-127.003, 8.849],
                                  [117.296, 77.436],
                                  [-38.748, 133.332],
                                  [-10.935, 92],
                                ],
                                o: [
                                  [-95.108, 41.237],
                                  [20.263, 118.184],
                                  [-48.911, 46.926],
                                  [118.227, 36.079],
                                  [19.305, 65.477],
                                  [110.369, -5.262],
                                  [-117.296, -77.436],
                                  [35.649, -136.104],
                                  [10.935, -92],
                                ],
                                v: [
                                  [-95.087, -384.134],
                                  [-175.919, -196.132],
                                  [-246.229, 20.961],
                                  [-279.381, 145.621],
                                  [-41.002, 293.198],
                                  [125.505, 408.689],
                                  [304.923, 277.332],
                                  [71.666, -102.951],
                                  [270.207, -231.056],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.636, -38.729],
                                  [-19.992, -117.877],
                                  [48.719, -47.115],
                                  [-117.886, -36.115],
                                  [-17.171, -62.71],
                                  [-127.262, 8.967],
                                  [116.835, 77.323],
                                  [-38.554, 132.941],
                                  [-10.986, 92],
                                ],
                                o: [
                                  [-95.023, 41.051],
                                  [19.992, 117.877],
                                  [-48.719, 47.115],
                                  [117.886, 36.115],
                                  [19.327, 65.364],
                                  [110.341, -5.318],
                                  [-116.835, -77.323],
                                  [35.402, -135.761],
                                  [10.986, -92],
                                ],
                                v: [
                                  [-95.278, -384.581],
                                  [-175.919, -196.593],
                                  [-246.405, 20.879],
                                  [-279.214, 145.272],
                                  [-40.707, 293.425],
                                  [125.584, 408.17],
                                  [305.514, 277.107],
                                  [71.684, -103.403],
                                  [269.357, -230.612],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.75, -38.501],
                                  [-19.716, -117.565],
                                  [48.525, -47.306],
                                  [-117.54, -36.151],
                                  [-17.156, -62.55],
                                  [-127.524, 9.087],
                                  [116.368, 77.208],
                                  [-38.357, 132.545],
                                  [-11.037, 92],
                                ],
                                o: [
                                  [-94.938, 40.863],
                                  [19.716, 117.565],
                                  [-48.525, 47.306],
                                  [117.54, 36.151],
                                  [19.35, 65.25],
                                  [110.313, -5.375],
                                  [-116.368, -77.208],
                                  [35.151, -135.413],
                                  [11.037, -92],
                                ],
                                v: [
                                  [-95.487, -385.015],
                                  [-175.977, -197.017],
                                  [-246.62, 20.839],
                                  [-278.993, 144.923],
                                  [-40.49, 293.643],
                                  [125.609, 407.657],
                                  [306.097, 276.822],
                                  [71.675, -103.776],
                                  [268.505, -230.106],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.866, -38.269],
                                  [-19.437, -117.249],
                                  [48.329, -47.5],
                                  [-117.189, -36.188],
                                  [-17.142, -62.388],
                                  [-127.791, 9.209],
                                  [115.894, 77.092],
                                  [-38.157, 132.142],
                                  [-11.089, 92],
                                ],
                                o: [
                                  [-94.851, 40.672],
                                  [19.437, 117.249],
                                  [-48.329, 47.5],
                                  [117.189, 36.188],
                                  [19.373, 65.134],
                                  [110.284, -5.433],
                                  [-115.894, -77.092],
                                  [34.896, -135.06],
                                  [11.089, -92],
                                ],
                                v: [
                                  [-95.733, -385.424],
                                  [-175.977, -197.434],
                                  [-246.884, 20.738],
                                  [-278.781, 144.633],
                                  [-40.28, 293.841],
                                  [125.642, 407.151],
                                  [306.732, 276.592],
                                  [71.722, -104.132],
                                  [267.705, -229.592],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.983, -38.034],
                                  [-19.154, -116.928],
                                  [48.129, -47.697],
                                  [-116.833, -36.226],
                                  [-17.127, -62.224],
                                  [-128.061, 9.332],
                                  [115.413, 76.973],
                                  [-37.954, 131.734],
                                  [-11.142, 92],
                                ],
                                o: [
                                  [-94.763, 40.478],
                                  [19.154, 116.928],
                                  [-48.129, 47.697],
                                  [116.833, 36.226],
                                  [19.397, 65.017],
                                  [110.254, -5.491],
                                  [-115.413, -76.973],
                                  [34.637, -134.702],
                                  [11.142, -92],
                                ],
                                v: [
                                  [-95.976, -385.817],
                                  [-175.93, -197.866],
                                  [-247.156, 20.637],
                                  [-278.506, 144.312],
                                  [-40.136, 293.978],
                                  [125.726, 406.64],
                                  [307.358, 276.353],
                                  [71.681, -104.46],
                                  [266.893, -229.068],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.102, -37.796],
                                  [-18.866, -116.602],
                                  [47.926, -47.896],
                                  [-116.471, -36.264],
                                  [-17.112, -62.057],
                                  [-128.335, 9.457],
                                  [114.925, 76.853],
                                  [-37.749, 131.32],
                                  [-11.196, 92],
                                ],
                                o: [
                                  [-94.673, 40.281],
                                  [18.866, 116.602],
                                  [-47.926, 47.896],
                                  [116.471, 36.264],
                                  [19.42, 64.898],
                                  [110.224, -5.551],
                                  [-114.925, -76.853],
                                  [34.375, -134.338],
                                  [11.196, -92],
                                ],
                                v: [
                                  [-96.276, -386.196],
                                  [-175.929, -198.251],
                                  [-247.465, 20.476],
                                  [-278.19, 143.989],
                                  [-40.061, 294.105],
                                  [125.756, 406.097],
                                  [308.036, 276.076],
                                  [71.718, -104.771],
                                  [266.08, -228.545],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.223, -37.554],
                                  [-18.574, -116.272],
                                  [47.721, -48.099],
                                  [-116.105, -36.302],
                                  [-17.097, -61.888],
                                  [-128.613, 9.584],
                                  [114.43, 76.732],
                                  [-37.54, 130.9],
                                  [-11.25, 92],
                                ],
                                o: [
                                  [-94.583, 40.082],
                                  [18.574, 116.272],
                                  [-47.721, 48.099],
                                  [116.105, 36.302],
                                  [19.445, 64.777],
                                  [110.194, -5.612],
                                  [-114.43, -76.732],
                                  [34.109, -133.969],
                                  [11.25, -92],
                                ],
                                v: [
                                  [-96.562, -386.497],
                                  [-175.882, -198.653],
                                  [-247.771, 20.317],
                                  [-277.833, 143.727],
                                  [-40.001, 294.224],
                                  [125.796, 405.602],
                                  [308.704, 275.779],
                                  [71.708, -105.063],
                                  [265.265, -228.014],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.346, -37.308],
                                  [-18.278, -115.937],
                                  [47.512, -48.304],
                                  [-115.733, -36.341],
                                  [-17.082, -61.716],
                                  [-128.895, 9.713],
                                  [113.928, 76.608],
                                  [-37.328, 130.473],
                                  [-11.306, 92],
                                ],
                                o: [
                                  [-94.491, 39.879],
                                  [18.278, 115.937],
                                  [-47.512, 48.304],
                                  [115.733, 36.341],
                                  [19.469, 64.654],
                                  [110.164, -5.673],
                                  [-113.928, -76.608],
                                  [33.839, -133.595],
                                  [11.306, -92],
                                ],
                                v: [
                                  [-96.905, -386.782],
                                  [-175.828, -198.997],
                                  [-248.123, 20.148],
                                  [-277.415, 143.423],
                                  [-40.02, 294.32],
                                  [125.835, 405.053],
                                  [309.371, 275.496],
                                  [71.675, -105.326],
                                  [264.438, -227.485],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.47, -37.059],
                                  [-17.978, -115.597],
                                  [47.3, -48.513],
                                  [-115.356, -36.381],
                                  [-17.066, -61.542],
                                  [-129.182, 9.844],
                                  [113.418, 76.483],
                                  [-37.114, 130.041],
                                  [-11.362, 92],
                                ],
                                o: [
                                  [-94.397, 39.674],
                                  [17.978, 115.597],
                                  [-47.3, 48.513],
                                  [115.356, 36.381],
                                  [19.494, 64.53],
                                  [110.132, -5.735],
                                  [-113.418, -76.483],
                                  [33.565, -133.215],
                                  [11.362, -92],
                                ],
                                v: [
                                  [-97.243, -387],
                                  [-175.729, -199.358],
                                  [-248.51, 19.971],
                                  [-276.959, 143.179],
                                  [-40.095, 294.368],
                                  [125.872, 404.575],
                                  [310.038, 275.153],
                                  [71.648, -105.518],
                                  [263.597, -226.946],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.597, -36.807],
                                  [-17.673, -115.252],
                                  [47.086, -48.724],
                                  [-114.973, -36.421],
                                  [-17.05, -61.365],
                                  [-129.472, 9.977],
                                  [112.901, 76.356],
                                  [-36.896, 129.602],
                                  [-11.419, 92],
                                ],
                                o: [
                                  [-94.303, 39.466],
                                  [17.673, 115.252],
                                  [-47.086, 48.724],
                                  [114.973, 36.421],
                                  [19.519, 64.403],
                                  [110.101, -5.798],
                                  [-112.901, -76.356],
                                  [33.287, -132.83],
                                  [11.418, -92],
                                ],
                                v: [
                                  [-97.616, -387.242],
                                  [-175.624, -199.687],
                                  [-248.904, 19.745],
                                  [-276.503, 142.934],
                                  [-40.236, 294.394],
                                  [125.87, 404.057],
                                  [310.643, 274.762],
                                  [71.649, -105.68],
                                  [262.817, -226.399],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.725, -36.551],
                                  [-17.364, -114.902],
                                  [46.868, -48.939],
                                  [-114.584, -36.462],
                                  [-17.034, -61.185],
                                  [-129.767, 10.111],
                                  [112.377, 76.227],
                                  [-36.675, 129.156],
                                  [-11.476, 92],
                                ],
                                o: [
                                  [-94.206, 39.254],
                                  [17.364, 114.902],
                                  [-46.868, 48.939],
                                  [114.584, 36.462],
                                  [19.545, 64.275],
                                  [110.069, -5.862],
                                  [-112.377, -76.227],
                                  [33.006, -132.44],
                                  [11.476, -92],
                                ],
                                v: [
                                  [-97.995, -387.365],
                                  [-175.512, -200.01],
                                  [-249.344, 19.472],
                                  [-275.999, 142.697],
                                  [-40.394, 294.409],
                                  [125.929, 403.536],
                                  [311.247, 274.362],
                                  [71.668, -105.822],
                                  [261.972, -225.804],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.855, -36.291],
                                  [-17.051, -114.547],
                                  [46.647, -49.157],
                                  [-114.19, -36.504],
                                  [-17.018, -61.003],
                                  [-130.066, 10.247],
                                  [111.845, 76.096],
                                  [-36.451, 128.705],
                                  [-11.535, 92],
                                ],
                                o: [
                                  [-94.109, 39.04],
                                  [17.05, 114.547],
                                  [-46.647, 49.157],
                                  [114.19, 36.504],
                                  [19.571, 64.145],
                                  [110.036, -5.927],
                                  [-111.845, -76.096],
                                  [32.72, -132.043],
                                  [11.535, -92],
                                ],
                                v: [
                                  [-98.37, -387.522],
                                  [-175.354, -200.301],
                                  [-249.816, 19.179],
                                  [-275.459, 142.471],
                                  [-40.617, 294.414],
                                  [125.988, 403.077],
                                  [311.789, 273.927],
                                  [71.655, -105.933],
                                  [261.113, -225.25],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.987, -36.027],
                                  [-16.732, -114.187],
                                  [46.423, -49.378],
                                  [-113.791, -36.546],
                                  [-17.002, -60.819],
                                  [-130.369, 10.386],
                                  [111.305, 75.964],
                                  [-36.223, 128.247],
                                  [-11.594, 92],
                                ],
                                o: [
                                  [-94.01, 38.822],
                                  [16.732, 114.187],
                                  [-46.423, 49.377],
                                  [113.791, 36.546],
                                  [19.597, 64.013],
                                  [110.003, -5.993],
                                  [-111.305, -75.964],
                                  [32.43, -131.641],
                                  [11.594, -92],
                                ],
                                v: [
                                  [-98.777, -387.562],
                                  [-175.24, -200.601],
                                  [-250.294, 18.852],
                                  [-274.86, 142.304],
                                  [-40.857, 294.409],
                                  [125.997, 402.578],
                                  [312.281, 273.421],
                                  [71.61, -105.972],
                                  [260.252, -224.65],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.12, -35.759],
                                  [-16.41, -113.822],
                                  [46.196, -49.601],
                                  [-113.385, -36.588],
                                  [-16.985, -60.632],
                                  [-130.677, 10.526],
                                  [110.758, 75.829],
                                  [-35.992, 127.782],
                                  [-11.654, 92],
                                ],
                                o: [
                                  [-93.91, 38.602],
                                  [16.41, 113.821],
                                  [-46.196, 49.601],
                                  [113.385, 36.588],
                                  [19.624, 63.88],
                                  [109.97, -6.06],
                                  [-110.758, -75.829],
                                  [32.135, -131.233],
                                  [11.654, -92],
                                ],
                                v: [
                                  [-99.19, -387.586],
                                  [-175.068, -200.92],
                                  [-250.815, 18.469],
                                  [-274.227, 142.084],
                                  [-41.113, 294.392],
                                  [126.068, 402.091],
                                  [312.76, 272.93],
                                  [71.634, -106.028],
                                  [259.425, -224.054],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.256, -35.488],
                                  [-16.083, -113.451],
                                  [45.965, -49.829],
                                  [-112.974, -36.632],
                                  [-16.968, -60.442],
                                  [-130.989, 10.669],
                                  [110.202, 75.693],
                                  [-35.759, 127.311],
                                  [-11.715, 92],
                                ],
                                o: [
                                  [-93.808, 38.378],
                                  [16.083, 113.451],
                                  [-45.965, 49.829],
                                  [112.974, 36.632],
                                  [19.651, 63.744],
                                  [109.936, -6.128],
                                  [-110.202, -75.693],
                                  [31.837, -130.819],
                                  [11.715, -92],
                                ],
                                v: [
                                  [-99.634, -387.58],
                                  [-174.851, -201.136],
                                  [-251.317, 18.028],
                                  [-273.597, 141.91],
                                  [-41.435, 294.365],
                                  [126.089, 401.617],
                                  [313.226, 272.382],
                                  [71.641, -105.963],
                                  [258.546, -223.437],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.393, -35.213],
                                  [-15.751, -113.075],
                                  [45.731, -50.059],
                                  [-112.557, -36.675],
                                  [-16.951, -60.249],
                                  [-131.305, 10.813],
                                  [109.639, 75.554],
                                  [-35.521, 126.833],
                                  [-11.777, 92],
                                ],
                                o: [
                                  [-93.705, 38.151],
                                  [15.751, 113.075],
                                  [-45.731, 50.059],
                                  [112.557, 36.675],
                                  [19.679, 63.607],
                                  [109.902, -6.197],
                                  [-109.639, -75.554],
                                  [31.534, -130.4],
                                  [11.777, -92],
                                ],
                                v: [
                                  [-100.023, -387.507],
                                  [-174.675, -201.422],
                                  [-251.861, 17.594],
                                  [-272.922, 141.759],
                                  [-41.773, 294.326],
                                  [126.16, 401.157],
                                  [313.606, 271.775],
                                  [71.669, -105.913],
                                  [257.714, -222.825],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.533, -34.934],
                                  [-15.414, -112.694],
                                  [45.494, -50.293],
                                  [-112.134, -36.72],
                                  [-16.933, -60.054],
                                  [-131.626, 10.96],
                                  [109.068, 75.414],
                                  [-35.28, 126.348],
                                  [-11.84, 92],
                                ],
                                o: [
                                  [-93.6, 37.92],
                                  [15.414, 112.694],
                                  [-45.494, 50.293],
                                  [112.134, 36.72],
                                  [19.707, 63.467],
                                  [109.867, -6.266],
                                  [-109.068, -75.414],
                                  [31.227, -129.974],
                                  [11.84, -92],
                                ],
                                v: [
                                  [-100.405, -387.416],
                                  [-174.456, -201.669],
                                  [-252.411, 17.104],
                                  [-272.216, 141.64],
                                  [-42.177, 294.276],
                                  [126.195, 400.71],
                                  [314.009, 271.172],
                                  [71.716, -105.778],
                                  [256.815, -222.169],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.675, -34.651],
                                  [-15.073, -112.308],
                                  [45.253, -50.53],
                                  [-111.705, -36.765],
                                  [-16.916, -59.856],
                                  [-131.952, 11.108],
                                  [108.488, 75.271],
                                  [-35.036, 125.856],
                                  [-11.904, 92],
                                ],
                                o: [
                                  [-93.494, 37.687],
                                  [15.073, 112.308],
                                  [-45.253, 50.53],
                                  [111.705, 36.765],
                                  [19.735, 63.325],
                                  [109.831, -6.337],
                                  [-108.488, -75.271],
                                  [30.916, -129.543],
                                  [11.904, -92],
                                ],
                                v: [
                                  [-100.732, -387.272],
                                  [-174.227, -201.889],
                                  [-252.988, 16.574],
                                  [-271.453, 141.48],
                                  [-42.584, 294.213],
                                  [126.294, 400.265],
                                  [314.327, 270.573],
                                  [71.785, -105.62],
                                  [255.949, -221.554],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.818, -34.364],
                                  [-14.726, -111.916],
                                  [45.009, -50.77],
                                  [-111.27, -36.811],
                                  [-16.898, -59.655],
                                  [-132.282, 11.259],
                                  [107.901, 75.127],
                                  [-34.789, 125.357],
                                  [-11.968, 92],
                                ],
                                o: [
                                  [-93.386, 37.45],
                                  [14.726, 111.916],
                                  [-45.009, 50.77],
                                  [111.27, 36.811],
                                  [19.764, 63.182],
                                  [109.795, -6.409],
                                  [-107.901, -75.127],
                                  [30.6, -129.105],
                                  [11.968, -92],
                                ],
                                v: [
                                  [-101.089, -387.084],
                                  [-173.941, -202.106],
                                  [-253.521, 15.989],
                                  [-270.71, 141.365],
                                  [-43.021, 294.09],
                                  [126.379, 399.848],
                                  [314.617, 269.93],
                                  [71.889, -105.425],
                                  [255.079, -220.896],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.964, -34.073],
                                  [-14.375, -111.518],
                                  [44.762, -51.014],
                                  [-110.829, -36.857],
                                  [-16.88, -59.451],
                                  [-132.616, 11.412],
                                  [107.305, 74.98],
                                  [-34.538, 124.851],
                                  [-12.034, 92],
                                ],
                                o: [
                                  [-93.277, 37.21],
                                  [14.375, 111.518],
                                  [-44.762, 51.014],
                                  [110.829, 36.857],
                                  [19.793, 63.036],
                                  [109.759, -6.482],
                                  [-107.305, -74.98],
                                  [30.28, -128.661],
                                  [12.034, -92],
                                ],
                                v: [
                                  [-101.341, -386.842],
                                  [-173.709, -202.299],
                                  [-254.081, 15.365],
                                  [-269.925, 141.258],
                                  [-43.46, 294.004],
                                  [126.494, 399.434],
                                  [314.86, 269.229],
                                  [71.967, -105.192],
                                  [254.191, -220.232],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.111, -33.777],
                                  [-14.019, -111.115],
                                  [44.511, -51.261],
                                  [-110.381, -36.904],
                                  [-16.861, -59.244],
                                  [-132.956, 11.567],
                                  [106.701, 74.832],
                                  [-34.283, 124.338],
                                  [-12.1, 92],
                                ],
                                o: [
                                  [-93.167, 36.966],
                                  [14.019, 111.115],
                                  [-44.511, 51.261],
                                  [110.381, 36.904],
                                  [19.822, 62.889],
                                  [109.722, -6.556],
                                  [-106.701, -74.832],
                                  [29.955, -128.211],
                                  [12.1, -92],
                                ],
                                v: [
                                  [-101.587, -386.632],
                                  [-173.404, -202.504],
                                  [-254.681, 14.751],
                                  [-269.084, 141.194],
                                  [-43.979, 293.906],
                                  [126.595, 399.037],
                                  [315.102, 268.519],
                                  [72.116, -104.9],
                                  [253.299, -219.525],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.261, -33.478],
                                  [-13.658, -110.706],
                                  [44.256, -51.512],
                                  [-109.928, -36.952],
                                  [-16.842, -59.035],
                                  [-133.3, 11.724],
                                  [106.088, 74.681],
                                  [-34.025, 123.818],
                                  [-12.167, 92],
                                ],
                                o: [
                                  [-93.054, 36.719],
                                  [13.658, 110.706],
                                  [-44.256, 51.512],
                                  [109.928, 36.952],
                                  [19.852, 62.739],
                                  [109.685, -6.63],
                                  [-106.088, -74.681],
                                  [29.626, -127.754],
                                  [12.167, -92],
                                ],
                                v: [
                                  [-101.828, -386.306],
                                  [-173.117, -202.719],
                                  [-255.284, 14.087],
                                  [-268.217, 141.061],
                                  [-44.549, 293.761],
                                  [126.726, 398.642],
                                  [315.281, 267.781],
                                  [72.302, -104.555],
                                  [252.387, -218.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.413, -33.174],
                                  [-13.292, -110.291],
                                  [43.998, -51.766],
                                  [-109.467, -37],
                                  [-16.823, -58.822],
                                  [-133.649, 11.883],
                                  [105.466, 74.528],
                                  [-33.763, 123.29],
                                  [-12.236, 92],
                                ],
                                o: [
                                  [-92.94, 36.469],
                                  [13.292, 110.291],
                                  [-43.998, 51.766],
                                  [109.467, 37],
                                  [19.883, 62.587],
                                  [109.647, -6.706],
                                  [-105.466, -74.528],
                                  [29.292, -127.291],
                                  [12.236, -92],
                                ],
                                v: [
                                  [-102, -385.963],
                                  [-172.806, -202.899],
                                  [-255.816, 13.371],
                                  [-267.359, 141.045],
                                  [-45.101, 293.59],
                                  [126.845, 398.236],
                                  [315.461, 267.033],
                                  [72.511, -104.199],
                                  [251.518, -218.109],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.567, -32.867],
                                  [-12.92, -109.871],
                                  [43.737, -52.024],
                                  [-109.001, -37.049],
                                  [-16.804, -58.607],
                                  [-134.003, 12.045],
                                  [104.836, 74.373],
                                  [-33.497, 122.756],
                                  [-12.305, 92],
                                ],
                                o: [
                                  [-92.825, 36.215],
                                  [12.92, 109.871],
                                  [-43.737, 52.024],
                                  [109.001, 37.049],
                                  [19.913, 62.433],
                                  [109.608, -6.783],
                                  [-104.836, -74.373],
                                  [28.953, -126.821],
                                  [12.305, -92],
                                ],
                                v: [
                                  [-102.165, -385.601],
                                  [-172.451, -203.044],
                                  [-256.434, 12.668],
                                  [-266.476, 140.959],
                                  [-45.704, 293.469],
                                  [127.028, 397.848],
                                  [315.578, 266.275],
                                  [72.744, -103.755],
                                  [250.581, -217.399],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.723, -32.554],
                                  [-12.544, -109.445],
                                  [43.471, -52.286],
                                  [-108.528, -37.099],
                                  [-16.785, -58.388],
                                  [-134.362, 12.209],
                                  [104.197, 74.216],
                                  [-33.228, 122.213],
                                  [-12.375, 92],
                                ],
                                o: [
                                  [-92.708, 35.957],
                                  [12.544, 109.445],
                                  [-43.471, 52.286],
                                  [108.528, 37.099],
                                  [19.945, 62.277],
                                  [109.569, -6.861],
                                  [-104.197, -74.216],
                                  [28.61, -126.345],
                                  [12.375, -92],
                                ],
                                v: [
                                  [-102.31, -385.221],
                                  [-172.132, -203.264],
                                  [-257.008, 11.931],
                                  [-265.588, 140.928],
                                  [-46.37, 293.287],
                                  [127.212, 397.463],
                                  [315.648, 265.475],
                                  [73.017, -103.285],
                                  [249.686, -216.683],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.881, -32.238],
                                  [-12.162, -109.012],
                                  [43.202, -52.551],
                                  [-108.048, -37.15],
                                  [-16.765, -58.167],
                                  [-134.727, 12.375],
                                  [103.549, 74.057],
                                  [-32.955, 121.663],
                                  [-12.446, 92],
                                ],
                                o: [
                                  [-92.589, 35.696],
                                  [12.162, 109.012],
                                  [-43.202, 52.551],
                                  [108.048, 37.15],
                                  [19.976, 62.119],
                                  [109.53, -6.941],
                                  [-103.549, -74.057],
                                  [28.262, -125.863],
                                  [12.447, -92],
                                ],
                                v: [
                                  [-102.4, -384.728],
                                  [-171.817, -203.436],
                                  [-257.557, 11.194],
                                  [-264.725, 140.95],
                                  [-47.006, 293.093],
                                  [127.398, 397.081],
                                  [315.656, 264.634],
                                  [73.3, -102.773],
                                  [248.769, -215.945],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.042, -31.917],
                                  [-11.775, -108.574],
                                  [42.929, -52.82],
                                  [-107.562, -37.201],
                                  [-16.745, -57.942],
                                  [-135.096, 12.544],
                                  [102.892, 73.896],
                                  [-32.678, 121.106],
                                  [-12.519, 92],
                                ],
                                o: [
                                  [-92.469, 35.431],
                                  [11.775, 108.574],
                                  [-42.929, 52.82],
                                  [107.562, 37.201],
                                  [20.008, 61.958],
                                  [109.49, -7.021],
                                  [-102.892, -73.896],
                                  [27.909, -125.373],
                                  [12.519, -92],
                                ],
                                v: [
                                  [-102.438, -384.264],
                                  [-171.474, -203.574],
                                  [-258.11, 10.409],
                                  [-263.81, 140.962],
                                  [-47.707, 292.886],
                                  [127.601, 396.641],
                                  [315.664, 263.846],
                                  [73.623, -102.202],
                                  [247.846, -215.14],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.204, -31.592],
                                  [-11.382, -108.13],
                                  [42.653, -53.092],
                                  [-107.068, -37.253],
                                  [-16.724, -57.714],
                                  [-135.47, 12.714],
                                  [102.226, 73.732],
                                  [-32.398, 120.54],
                                  [-12.592, 92],
                                ],
                                o: [
                                  [-92.347, 35.163],
                                  [11.382, 108.13],
                                  [-42.653, 53.092],
                                  [107.068, 37.253],
                                  [20.041, 61.796],
                                  [109.449, -7.102],
                                  [-102.226, -73.732],
                                  [27.551, -124.877],
                                  [12.592, -92],
                                ],
                                v: [
                                  [-102.453, -383.75],
                                  [-171.167, -203.757],
                                  [-258.684, 9.593],
                                  [-262.874, 140.964],
                                  [-48.408, 292.636],
                                  [127.853, 396.267],
                                  [315.656, 263.001],
                                  [73.972, -101.589],
                                  [246.946, -214.393],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.369, -31.262],
                                  [-10.984, -107.679],
                                  [42.372, -53.368],
                                  [-106.568, -37.305],
                                  [-16.704, -57.483],
                                  [-135.849, 12.888],
                                  [101.551, 73.566],
                                  [-32.113, 119.967],
                                  [-12.666, 92],
                                ],
                                o: [
                                  [-92.223, 34.891],
                                  [10.984, 107.679],
                                  [-42.372, 53.368],
                                  [106.568, 37.305],
                                  [20.074, 61.631],
                                  [109.408, -7.185],
                                  [-101.551, -73.566],
                                  [27.188, -124.374],
                                  [12.666, -92],
                                ],
                                v: [
                                  [-102.447, -383.155],
                                  [-170.816, -203.923],
                                  [-259.199, 8.794],
                                  [-261.997, 141.017],
                                  [-49.188, 292.403],
                                  [128.061, 395.88],
                                  [315.556, 262.116],
                                  [74.41, -100.932],
                                  [246.009, -213.612],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.536, -30.927],
                                  [-10.581, -107.222],
                                  [42.088, -53.648],
                                  [-106.061, -37.359],
                                  [-16.683, -57.249],
                                  [-136.234, 13.063],
                                  [100.867, 73.398],
                                  [-31.825, 119.386],
                                  [-12.741, 92],
                                ],
                                o: [
                                  [-92.098, 34.615],
                                  [10.581, 107.222],
                                  [-42.088, 53.649],
                                  [106.061, 37.358],
                                  [20.107, 61.464],
                                  [109.366, -7.268],
                                  [-100.867, -73.398],
                                  [26.82, -123.864],
                                  [12.741, -92],
                                ],
                                v: [
                                  [-102.387, -382.559],
                                  [-170.532, -204.088],
                                  [-259.766, 7.95],
                                  [-261.055, 141.059],
                                  [-49.923, 292.19],
                                  [128.317, 395.514],
                                  [315.457, 261.267],
                                  [74.813, -100.216],
                                  [245.094, -212.843],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.706, -30.588],
                                  [-10.172, -106.759],
                                  [41.8, -53.932],
                                  [-105.548, -37.413],
                                  [-16.662, -57.012],
                                  [-136.624, 13.241],
                                  [100.172, 73.227],
                                  [-31.532, 118.797],
                                  [-12.818, 92],
                                ],
                                o: [
                                  [-91.971, 34.335],
                                  [10.172, 106.759],
                                  [-41.8, 53.932],
                                  [105.548, 37.413],
                                  [20.141, 61.294],
                                  [109.324, -7.353],
                                  [-100.172, -73.227],
                                  [26.447, -123.347],
                                  [12.818, -92],
                                ],
                                v: [
                                  [-102.32, -381.944],
                                  [-170.252, -204.251],
                                  [-260.274, 7.126],
                                  [-260.156, 141.106],
                                  [-50.737, 291.901],
                                  [128.621, 395.089],
                                  [315.342, 260.395],
                                  [75.305, -99.473],
                                  [244.171, -212.009],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.878, -30.244],
                                  [-9.757, -106.29],
                                  [41.508, -54.22],
                                  [-105.027, -37.467],
                                  [-16.64, -56.771],
                                  [-137.019, 13.422],
                                  [99.469, 73.054],
                                  [-31.236, 118.2],
                                  [-12.895, 92],
                                ],
                                o: [
                                  [-91.842, 34.052],
                                  [9.757, 106.29],
                                  [-41.508, 54.22],
                                  [105.027, 37.467],
                                  [20.176, 61.122],
                                  [109.281, -7.439],
                                  [-99.469, -73.054],
                                  [26.069, -122.823],
                                  [12.895, -92],
                                ],
                                v: [
                                  [-102.185, -381.265],
                                  [-169.96, -204.4],
                                  [-260.755, 6.304],
                                  [-259.224, 141.202],
                                  [-51.552, 291.663],
                                  [128.897, 394.714],
                                  [315.182, 259.512],
                                  [75.824, -98.684],
                                  [243.269, -211.206],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.052, -29.896],
                                  [-9.337, -105.814],
                                  [41.212, -54.512],
                                  [-104.498, -37.523],
                                  [-16.618, -56.527],
                                  [-137.42, 13.605],
                                  [98.756, 72.879],
                                  [-30.935, 117.594],
                                  [-12.973, 92],
                                ],
                                o: [
                                  [-91.711, 33.764],
                                  [9.337, 105.814],
                                  [-41.212, 54.512],
                                  [104.498, 37.523],
                                  [20.21, 60.948],
                                  [109.237, -7.526],
                                  [-98.756, -72.879],
                                  [25.685, -122.291],
                                  [12.973, -92],
                                ],
                                v: [
                                  [-102.025, -380.568],
                                  [-169.689, -204.53],
                                  [-261.285, 5.44],
                                  [-258.322, 141.267],
                                  [-52.412, 291.366],
                                  [129.204, 394.298],
                                  [314.96, 258.609],
                                  [76.372, -97.838],
                                  [242.329, -210.401],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.229, -29.543],
                                  [-8.911, -105.332],
                                  [40.911, -54.808],
                                  [-103.963, -37.579],
                                  [-16.596, -56.28],
                                  [-137.826, 13.79],
                                  [98.033, 72.701],
                                  [-30.631, 116.98],
                                  [-13.053, 92],
                                ],
                                o: [
                                  [-91.579, 33.473],
                                  [8.911, 105.332],
                                  [-40.911, 54.808],
                                  [103.963, 37.579],
                                  [20.246, 60.771],
                                  [109.193, -7.614],
                                  [-98.033, -72.701],
                                  [25.297, -121.753],
                                  [13.053, -92],
                                ],
                                v: [
                                  [-101.814, -379.808],
                                  [-169.422, -204.692],
                                  [-261.759, 4.553],
                                  [-257.417, 141.399],
                                  [-53.243, 291.056],
                                  [129.485, 393.913],
                                  [314.694, 257.74],
                                  [77.01, -96.945],
                                  [241.409, -209.55],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.408, -29.185],
                                  [-8.479, -104.843],
                                  [40.607, -55.108],
                                  [-103.42, -37.636],
                                  [-16.574, -56.029],
                                  [-138.238, 13.978],
                                  [97.3, 72.521],
                                  [-30.322, 116.358],
                                  [-13.133, 92],
                                ],
                                o: [
                                  [-91.444, 33.177],
                                  [8.479, 104.843],
                                  [-40.607, 55.108],
                                  [103.42, 37.636],
                                  [20.282, 60.592],
                                  [109.148, -7.704],
                                  [-97.3, -72.521],
                                  [24.903, -121.207],
                                  [13.133, -92],
                                ],
                                v: [
                                  [-101.577, -379.002],
                                  [-169.204, -204.852],
                                  [-262.22, 3.67],
                                  [-256.482, 141.515],
                                  [-54.136, 290.733],
                                  [129.796, 393.505],
                                  [314.429, 256.834],
                                  [77.659, -95.978],
                                  [240.479, -208.698],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.589, -28.822],
                                  [-8.041, -104.347],
                                  [40.298, -55.412],
                                  [-102.87, -37.694],
                                  [-16.551, -55.775],
                                  [-138.655, 14.169],
                                  [96.557, 72.338],
                                  [-30.009, 115.728],
                                  [-13.215, 92],
                                ],
                                o: [
                                  [-91.308, 32.878],
                                  [8.041, 104.347],
                                  [-40.298, 55.412],
                                  [102.87, 37.694],
                                  [20.318, 60.411],
                                  [109.103, -7.795],
                                  [-96.557, -72.338],
                                  [24.504, -120.653],
                                  [13.215, -92],
                                ],
                                v: [
                                  [-101.289, -378.223],
                                  [-168.945, -205.028],
                                  [-262.624, 2.811],
                                  [-255.624, 141.66],
                                  [-55.046, 290.415],
                                  [130.153, 393.129],
                                  [314.166, 255.935],
                                  [78.337, -95],
                                  [239.566, -207.862],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.773, -28.454],
                                  [-7.597, -103.845],
                                  [39.986, -55.72],
                                  [-102.313, -37.753],
                                  [-16.528, -55.518],
                                  [-139.078, 14.362],
                                  [95.804, 72.153],
                                  [-29.691, 115.088],
                                  [-13.298, 92],
                                ],
                                o: [
                                  [-91.17, 32.574],
                                  [7.597, 103.845],
                                  [-39.986, 55.72],
                                  [102.313, 37.753],
                                  [20.355, 60.227],
                                  [109.057, -7.887],
                                  [-95.804, -72.153],
                                  [24.099, -120.092],
                                  [13.298, -92],
                                ],
                                v: [
                                  [-100.976, -377.338],
                                  [-168.736, -205.156],
                                  [-263.078, 1.957],
                                  [-254.718, 141.806],
                                  [-55.954, 290.084],
                                  [130.485, 392.712],
                                  [313.797, 255.043],
                                  [79.062, -93.929],
                                  [238.66, -207.001],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.96, -28.081],
                                  [-7.148, -103.335],
                                  [39.669, -56.032],
                                  [-101.748, -37.812],
                                  [-16.505, -55.257],
                                  [-139.507, 14.557],
                                  [95.041, 71.966],
                                  [-29.37, 114.441],
                                  [-13.382, 92],
                                ],
                                o: [
                                  [-91.03, 32.267],
                                  [7.148, 103.336],
                                  [-39.669, 56.032],
                                  [101.748, 37.812],
                                  [20.392, 60.04],
                                  [109.01, -7.98],
                                  [-95.041, -71.966],
                                  [23.689, -119.523],
                                  [13.382, -92],
                                ],
                                v: [
                                  [-100.612, -376.454],
                                  [-168.574, -205.345],
                                  [-263.477, 1.084],
                                  [-253.847, 141.954],
                                  [-56.922, 289.759],
                                  [130.844, 392.344],
                                  [313.431, 254.16],
                                  [79.861, -92.847],
                                  [237.707, -206.097],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.148, -27.703],
                                  [-6.692, -102.819],
                                  [39.348, -56.349],
                                  [-101.175, -37.872],
                                  [-16.481, -54.992],
                                  [-139.942, 14.756],
                                  [94.267, 71.776],
                                  [-29.044, 113.784],
                                  [-13.467, 92],
                                ],
                                o: [
                                  [-90.889, 31.955],
                                  [6.692, 102.819],
                                  [-39.348, 56.349],
                                  [101.175, 37.872],
                                  [20.43, 59.851],
                                  [108.963, -8.074],
                                  [-94.267, -71.776],
                                  [23.273, -118.947],
                                  [13.467, -92],
                                ],
                                v: [
                                  [-100.24, -375.553],
                                  [-168.373, -205.486],
                                  [-263.863, 0.176],
                                  [-252.973, 142.127],
                                  [-57.845, 289.378],
                                  [131.205, 391.936],
                                  [313.066, 253.241],
                                  [80.691, -91.716],
                                  [236.76, -205.237],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.34, -27.32],
                                  [-6.23, -102.296],
                                  [39.022, -56.67],
                                  [-100.594, -37.933],
                                  [-16.457, -54.724],
                                  [-140.382, 14.957],
                                  [93.483, 71.583],
                                  [-28.714, 113.118],
                                  [-13.553, 92],
                                ],
                                o: [
                                  [-90.745, 31.639],
                                  [6.23, 102.296],
                                  [-39.022, 56.67],
                                  [100.594, 37.933],
                                  [20.468, 59.66],
                                  [108.915, -8.17],
                                  [-93.483, -71.583],
                                  [22.852, -118.363],
                                  [13.553, -92],
                                ],
                                v: [
                                  [-99.843, -374.592],
                                  [-168.218, -205.643],
                                  [-264.194, -0.682],
                                  [-252.135, 142.282],
                                  [-58.826, 289.027],
                                  [131.567, 391.557],
                                  [312.598, 252.374],
                                  [81.593, -90.53],
                                  [235.827, -204.293],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.534, -26.932],
                                  [-5.761, -101.766],
                                  [38.692, -56.995],
                                  [-100.006, -37.995],
                                  [-16.433, -54.452],
                                  [-140.828, 15.161],
                                  [92.688, 71.388],
                                  [-28.379, 112.444],
                                  [-13.64, 92],
                                ],
                                o: [
                                  [-90.599, 31.319],
                                  [5.761, 101.766],
                                  [-38.692, 56.995],
                                  [100.006, 37.995],
                                  [20.507, 59.466],
                                  [108.867, -8.267],
                                  [-92.689, -71.388],
                                  [22.425, -117.771],
                                  [13.64, -92],
                                ],
                                v: [
                                  [-99.394, -373.613],
                                  [-168.111, -205.797],
                                  [-264.556, -1.574],
                                  [-251.337, 142.478],
                                  [-59.824, 288.641],
                                  [131.93, 391.139],
                                  [312.175, 251.515],
                                  [82.507, -89.314],
                                  [234.88, -203.413],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.731, -26.538],
                                  [-5.287, -101.229],
                                  [38.358, -57.324],
                                  [-99.41, -38.058],
                                  [-16.409, -54.177],
                                  [-141.281, 15.367],
                                  [91.883, 71.19],
                                  [-28.039, 111.76],
                                  [-13.729, 92],
                                ],
                                o: [
                                  [-90.452, 30.994],
                                  [5.287, 101.229],
                                  [-38.358, 57.324],
                                  [99.41, 38.058],
                                  [20.546, 59.269],
                                  [108.817, -8.365],
                                  [-91.883, -71.19],
                                  [21.992, -117.171],
                                  [13.729, -92],
                                ],
                                v: [
                                  [-98.876, -372.638],
                                  [-168.006, -205.966],
                                  [-264.882, -2.437],
                                  [-250.532, 142.674],
                                  [-60.818, 288.242],
                                  [132.317, 390.705],
                                  [311.669, 250.622],
                                  [83.513, -88.025],
                                  [233.964, -202.494],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.93, -26.14],
                                  [-4.806, -100.685],
                                  [38.019, -57.658],
                                  [-98.805, -38.121],
                                  [-16.384, -53.898],
                                  [-141.739, 15.577],
                                  [91.067, 70.989],
                                  [-27.695, 111.068],
                                  [-13.819, 92],
                                ],
                                o: [
                                  [-90.302, 30.665],
                                  [4.806, 100.684],
                                  [-38.019, 57.658],
                                  [98.805, 38.122],
                                  [20.586, 59.07],
                                  [108.767, -8.465],
                                  [-91.067, -70.989],
                                  [21.554, -116.563],
                                  [13.819, -92],
                                ],
                                v: [
                                  [-98.351, -371.56],
                                  [-167.905, -206.152],
                                  [-265.154, -3.291],
                                  [-249.726, 142.868],
                                  [-61.827, 287.851],
                                  [132.681, 390.318],
                                  [311.166, 249.761],
                                  [84.549, -86.663],
                                  [232.971, -201.578],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.132, -25.736],
                                  [-4.319, -100.133],
                                  [37.675, -57.996],
                                  [-98.193, -38.186],
                                  [-16.358, -53.615],
                                  [-142.204, 15.789],
                                  [90.24, 70.786],
                                  [-27.347, 110.366],
                                  [-13.909, 92],
                                ],
                                o: [
                                  [-90.151, 30.332],
                                  [4.319, 100.133],
                                  [-37.675, 57.996],
                                  [98.193, 38.186],
                                  [20.626, 58.868],
                                  [108.717, -8.566],
                                  [-90.24, -70.786],
                                  [21.109, -115.947],
                                  [13.909, -92],
                                ],
                                v: [
                                  [-97.798, -370.51],
                                  [-167.849, -206.375],
                                  [-265.415, -4.135],
                                  [-248.979, 143.083],
                                  [-62.853, 287.469],
                                  [133.069, 389.893],
                                  [310.667, 248.886],
                                  [85.658, -85.313],
                                  [232.007, -200.644],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.337, -25.326],
                                  [-3.825, -99.574],
                                  [37.327, -58.339],
                                  [-97.573, -38.251],
                                  [-16.333, -53.328],
                                  [-142.675, 16.004],
                                  [89.402, 70.58],
                                  [-26.994, 109.654],
                                  [-14.002, 92],
                                ],
                                o: [
                                  [-89.997, 29.994],
                                  [3.825, 99.574],
                                  [-37.327, 58.339],
                                  [97.573, 38.251],
                                  [20.667, 58.663],
                                  [108.666, -8.668],
                                  [-89.402, -70.58],
                                  [20.659, -115.322],
                                  [14.002, -92],
                                ],
                                v: [
                                  [-97.195, -369.421],
                                  [-167.795, -206.551],
                                  [-265.665, -4.99],
                                  [-248.207, 143.316],
                                  [-63.874, 287.054],
                                  [133.457, 389.472],
                                  [310.109, 248.021],
                                  [86.819, -83.87],
                                  [231.049, -199.673],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.544, -24.911],
                                  [-3.324, -99.007],
                                  [36.975, -58.687],
                                  [-96.944, -38.317],
                                  [-16.307, -53.038],
                                  [-143.152, 16.222],
                                  [88.553, 70.371],
                                  [-26.636, 108.933],
                                  [-14.095, 92],
                                ],
                                o: [
                                  [-89.842, 29.652],
                                  [3.324, 99.007],
                                  [-36.975, 58.687],
                                  [96.944, 38.317],
                                  [20.709, 58.456],
                                  [108.614, -8.772],
                                  [-88.553, -70.371],
                                  [20.202, -114.69],
                                  [14.095, -92],
                                ],
                                v: [
                                  [-96.523, -368.276],
                                  [-167.785, -206.742],
                                  [-265.861, -5.833],
                                  [-247.495, 143.545],
                                  [-64.973, 286.67],
                                  [133.865, 389.075],
                                  [309.533, 247.207],
                                  [88.032, -82.396],
                                  [230.096, -198.75],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.755, -24.491],
                                  [-2.817, -98.433],
                                  [36.617, -59.039],
                                  [-96.307, -38.384],
                                  [-16.281, -52.744],
                                  [-143.635, 16.442],
                                  [87.692, 70.16],
                                  [-26.273, 108.203],
                                  [-14.19, 92],
                                ],
                                o: [
                                  [-89.684, 29.305],
                                  [2.817, 98.433],
                                  [-36.617, 59.039],
                                  [96.307, 38.384],
                                  [20.751, 58.245],
                                  [108.561, -8.877],
                                  [-87.692, -70.159],
                                  [19.74, -114.049],
                                  [14.19, -92],
                                ],
                                v: [
                                  [-95.843, -367.116],
                                  [-167.819, -206.928],
                                  [-266.068, -6.685],
                                  [-246.842, 143.77],
                                  [-66.004, 286.274],
                                  [134.271, 388.641],
                                  [308.941, 246.403],
                                  [89.316, -80.892],
                                  [229.084, -197.791],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.968, -24.065],
                                  [-2.303, -97.851],
                                  [36.255, -59.396],
                                  [-95.661, -38.452],
                                  [-16.254, -52.445],
                                  [-144.125, 16.666],
                                  [86.82, 69.945],
                                  [-25.906, 107.463],
                                  [-14.285, 92],
                                ],
                                o: [
                                  [-89.524, 28.954],
                                  [2.303, 97.851],
                                  [-36.255, 59.396],
                                  [95.661, 38.452],
                                  [20.794, 58.032],
                                  [108.508, -8.984],
                                  [-86.82, -69.945],
                                  [19.271, -113.399],
                                  [14.285, -92],
                                ],
                                v: [
                                  [-95.134, -365.963],
                                  [-167.854, -207.17],
                                  [-266.201, -7.504],
                                  [-246.208, 144.031],
                                  [-67.112, 285.849],
                                  [134.636, 388.23],
                                  [308.313, 245.567],
                                  [90.631, -79.317],
                                  [228.077, -196.797],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.183, -23.633],
                                  [-1.783, -97.262],
                                  [35.888, -59.757],
                                  [-95.007, -38.521],
                                  [-16.227, -52.143],
                                  [-144.622, 16.893],
                                  [85.937, 69.728],
                                  [-25.534, 106.713],
                                  [-14.383, 92],
                                ],
                                o: [
                                  [-89.362, 28.597],
                                  [1.783, 97.262],
                                  [-35.888, 59.757],
                                  [95.007, 38.521],
                                  [20.837, 57.817],
                                  [108.454, -9.092],
                                  [-85.937, -69.728],
                                  [18.797, -112.741],
                                  [14.383, -92],
                                ],
                                v: [
                                  [-94.376, -364.776],
                                  [-167.908, -207.404],
                                  [-266.345, -8.288],
                                  [-245.591, 144.306],
                                  [-68.174, 285.413],
                                  [135.081, 387.781],
                                  [307.689, 244.805],
                                  [92.018, -77.733],
                                  [227.071, -195.833],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.402, -23.196],
                                  [-1.255, -96.664],
                                  [35.517, -60.123],
                                  [-94.344, -38.591],
                                  [-16.2, -51.837],
                                  [-145.125, 17.122],
                                  [85.042, 69.508],
                                  [-25.157, 105.953],
                                  [-14.481, 92],
                                ],
                                o: [
                                  [-89.198, 28.237],
                                  [1.255, 96.664],
                                  [-35.517, 60.123],
                                  [94.344, 38.591],
                                  [20.88, 57.598],
                                  [108.4, -9.201],
                                  [-85.042, -69.508],
                                  [18.316, -112.074],
                                  [14.481, -92],
                                ],
                                v: [
                                  [-93.588, -363.536],
                                  [-167.984, -207.653],
                                  [-266.439, -9.081],
                                  [-244.97, 144.574],
                                  [-69.291, 285.03],
                                  [135.462, 387.353],
                                  [307.03, 244.03],
                                  [93.476, -76.08],
                                  [226.047, -194.855],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.624, -22.753],
                                  [-0.721, -96.059],
                                  [35.14, -60.495],
                                  [-93.672, -38.661],
                                  [-16.172, -51.527],
                                  [-145.634, 17.355],
                                  [84.135, 69.285],
                                  [-24.774, 105.183],
                                  [-14.581, 92],
                                ],
                                o: [
                                  [-89.032, 27.871],
                                  [0.721, 96.059],
                                  [-35.14, 60.494],
                                  [93.672, 38.661],
                                  [20.925, 57.376],
                                  [108.344, -9.312],
                                  [-84.135, -69.285],
                                  [17.828, -111.398],
                                  [14.581, -92],
                                ],
                                v: [
                                  [-92.752, -362.322],
                                  [-168.101, -207.894],
                                  [-266.522, -9.86],
                                  [-244.446, 144.813],
                                  [-70.384, 284.597],
                                  [135.817, 386.889],
                                  [306.377, 243.289],
                                  [95.004, -74.396],
                                  [225.021, -193.867],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.848, -22.304],
                                  [-0.179, -95.446],
                                  [34.758, -60.871],
                                  [-92.992, -38.733],
                                  [-16.144, -51.213],
                                  [-146.151, 17.59],
                                  [83.216, 69.059],
                                  [-24.387, 104.403],
                                  [-14.682, 92],
                                ],
                                o: [
                                  [-88.864, 27.501],
                                  [0.179, 95.446],
                                  [-34.758, 60.87],
                                  [92.992, 38.733],
                                  [20.97, 57.152],
                                  [108.288, -9.424],
                                  [-83.216, -69.059],
                                  [17.334, -110.713],
                                  [14.682, -92],
                                ],
                                v: [
                                  [-91.885, -361.063],
                                  [-168.258, -208.148],
                                  [-266.533, -10.626],
                                  [-243.901, 145.084],
                                  [-71.532, 284.2],
                                  [136.233, 386.443],
                                  [305.69, 242.559],
                                  [96.578, -72.667],
                                  [223.935, -192.889],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.075, -21.849],
                                  [0.37, -94.825],
                                  [34.372, -61.251],
                                  [-92.302, -38.805],
                                  [-16.116, -50.894],
                                  [-146.674, 17.829],
                                  [82.285, 68.83],
                                  [-23.995, 103.613],
                                  [-14.784, 92],
                                ],
                                o: [
                                  [-88.693, 27.125],
                                  [-0.37, 94.825],
                                  [-34.372, 61.251],
                                  [92.302, 38.805],
                                  [21.015, 56.925],
                                  [108.231, -9.538],
                                  [-82.285, -68.83],
                                  [16.834, -110.02],
                                  [14.784, -92],
                                ],
                                v: [
                                  [-90.986, -359.807],
                                  [-168.428, -208.433],
                                  [-266.557, -11.377],
                                  [-243.451, 145.385],
                                  [-72.633, 283.795],
                                  [136.598, 386],
                                  [305.01, 241.878],
                                  [98.205, -70.869],
                                  [222.908, -191.881],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.306, -21.388],
                                  [0.925, -94.196],
                                  [33.98, -61.637],
                                  [-91.604, -38.879],
                                  [-16.087, -50.572],
                                  [-147.203, 18.071],
                                  [81.342, 68.598],
                                  [-23.597, 102.812],
                                  [-14.888, 92],
                                ],
                                o: [
                                  [-88.521, 26.745],
                                  [-0.925, 94.196],
                                  [-33.98, 61.637],
                                  [91.604, 38.879],
                                  [21.061, 56.694],
                                  [108.174, -9.653],
                                  [-81.342, -68.598],
                                  [16.327, -109.317],
                                  [14.888, -92],
                                ],
                                v: [
                                  [-90.039, -358.524],
                                  [-168.621, -208.769],
                                  [-266.517, -12.091],
                                  [-243.018, 145.678],
                                  [-73.789, 283.406],
                                  [136.984, 385.558],
                                  [304.337, 241.171],
                                  [99.939, -69.066],
                                  [221.82, -190.884],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.539, -20.921],
                                  [1.488, -93.558],
                                  [33.583, -62.028],
                                  [-90.897, -38.953],
                                  [-16.058, -50.245],
                                  [-147.74, 18.316],
                                  [80.386, 68.364],
                                  [-23.195, 102.001],
                                  [-14.993, 92],
                                ],
                                o: [
                                  [-88.346, 26.36],
                                  [-1.488, 93.558],
                                  [-33.583, 62.028],
                                  [90.897, 38.953],
                                  [21.108, 56.461],
                                  [108.115, -9.77],
                                  [-80.386, -68.364],
                                  [15.814, -108.605],
                                  [14.993, -92],
                                ],
                                v: [
                                  [-89.02, -357.216],
                                  [-168.85, -209.094],
                                  [-266.484, -12.813],
                                  [-242.642, 145.975],
                                  [-74.921, 283.008],
                                  [137.355, 385.094],
                                  [303.618, 240.537],
                                  [101.703, -67.21],
                                  [220.724, -189.86],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.776, -20.449],
                                  [2.059, -92.913],
                                  [33.181, -62.424],
                                  [-90.18, -39.029],
                                  [-16.028, -49.914],
                                  [-148.284, 18.564],
                                  [79.418, 68.126],
                                  [-22.787, 101.179],
                                  [-15.099, 92],
                                ],
                                o: [
                                  [-88.168, 25.97],
                                  [-2.059, 92.913],
                                  [-33.181, 62.424],
                                  [90.18, 39.028],
                                  [21.155, 56.224],
                                  [108.056, -9.888],
                                  [-79.418, -68.126],
                                  [15.293, -107.884],
                                  [15.099, -92],
                                ],
                                v: [
                                  [-88.007, -355.96],
                                  [-169.128, -209.406],
                                  [-266.365, -13.52],
                                  [-242.295, 146.26],
                                  [-76.107, 282.589],
                                  [137.707, 384.607],
                                  [302.921, 239.915],
                                  [103.547, -65.336],
                                  [219.606, -188.823],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.015, -19.97],
                                  [2.637, -92.258],
                                  [32.774, -62.826],
                                  [-89.454, -39.105],
                                  [-15.998, -49.579],
                                  [-148.835, 18.816],
                                  [78.438, 67.885],
                                  [-22.374, 100.347],
                                  [-15.207, 92],
                                ],
                                o: [
                                  [-87.989, 25.575],
                                  [-2.637, 92.258],
                                  [-32.774, 62.826],
                                  [89.454, 39.105],
                                  [21.203, 55.985],
                                  [107.996, -10.008],
                                  [-78.438, -67.885],
                                  [14.766, -107.154],
                                  [15.207, -92],
                                ],
                                v: [
                                  [-86.945, -354.619],
                                  [-169.401, -209.767],
                                  [-266.239, -14.237],
                                  [-242.002, 146.535],
                                  [-77.271, 282.226],
                                  [138.043, 384.121],
                                  [302.195, 239.33],
                                  [105.482, -63.397],
                                  [218.476, -187.822],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.258, -19.484],
                                  [3.222, -91.596],
                                  [32.362, -63.232],
                                  [-88.718, -39.182],
                                  [-15.968, -49.239],
                                  [-149.393, 19.071],
                                  [77.444, 67.641],
                                  [-21.955, 99.504],
                                  [-15.316, 92],
                                ],
                                o: [
                                  [-87.807, 25.174],
                                  [-3.222, 91.596],
                                  [-32.362, 63.232],
                                  [88.718, 39.182],
                                  [21.252, 55.742],
                                  [107.935, -10.129],
                                  [-77.444, -67.641],
                                  [14.233, -106.413],
                                  [15.316, -92],
                                ],
                                v: [
                                  [-85.812, -353.332],
                                  [-169.731, -210.151],
                                  [-266.066, -14.913],
                                  [-241.761, 146.847],
                                  [-78.451, 281.844],
                                  [138.396, 383.673],
                                  [301.503, 238.796],
                                  [107.419, -61.43],
                                  [217.322, -186.834],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.504, -18.993],
                                  [3.815, -90.924],
                                  [31.944, -63.644],
                                  [-87.973, -39.261],
                                  [-15.937, -48.895],
                                  [-149.958, 19.329],
                                  [76.438, 67.393],
                                  [-21.531, 98.65],
                                  [-15.427, 92],
                                ],
                                o: [
                                  [-87.622, 24.769],
                                  [-3.815, 90.924],
                                  [-31.944, 63.644],
                                  [87.973, 39.26],
                                  [21.301, 55.496],
                                  [107.874, -10.252],
                                  [-76.438, -67.393],
                                  [13.692, -105.664],
                                  [15.427, -92],
                                ],
                                v: [
                                  [-84.642, -351.999],
                                  [-170.031, -210.544],
                                  [-265.912, -15.599],
                                  [-241.572, 147.17],
                                  [-79.659, 281.52],
                                  [138.729, 383.162],
                                  [300.783, 238.274],
                                  [109.384, -59.425],
                                  [216.141, -185.823],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.753, -18.495],
                                  [4.416, -90.244],
                                  [31.521, -64.061],
                                  [-87.219, -39.34],
                                  [-15.906, -48.546],
                                  [-150.531, 19.59],
                                  [75.419, 67.143],
                                  [-21.102, 97.785],
                                  [-15.539, 92],
                                ],
                                o: [
                                  [-87.436, 24.358],
                                  [-4.416, 90.244],
                                  [-31.521, 64.061],
                                  [87.219, 39.34],
                                  [21.351, 55.247],
                                  [107.812, -10.376],
                                  [-75.419, -67.143],
                                  [13.144, -104.904],
                                  [15.539, -92],
                                ],
                                v: [
                                  [-83.462, -350.675],
                                  [-170.398, -210.956],
                                  [-265.688, -16.233],
                                  [-241.407, 147.454],
                                  [-80.872, 281.154],
                                  [138.981, 382.625],
                                  [300.11, 237.791],
                                  [111.414, -57.394],
                                  [214.971, -184.789],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.005, -17.99],
                                  [5.024, -89.556],
                                  [31.092, -64.483],
                                  [-86.454, -39.42],
                                  [-15.874, -48.193],
                                  [-151.111, 19.855],
                                  [74.387, 66.889],
                                  [-20.667, 96.908],
                                  [-15.652, 92],
                                ],
                                o: [
                                  [-87.246, 23.942],
                                  [-5.024, 89.556],
                                  [-31.092, 64.483],
                                  [86.454, 39.42],
                                  [21.401, 54.995],
                                  [107.749, -10.502],
                                  [-74.387, -66.889],
                                  [12.589, -104.135],
                                  [15.652, -92],
                                ],
                                v: [
                                  [-82.245, -349.38],
                                  [-170.793, -211.387],
                                  [-265.421, -16.914],
                                  [-241.354, 147.759],
                                  [-82.075, 280.81],
                                  [139.19, 382.052],
                                  [299.402, 237.385],
                                  [113.533, -55.263],
                                  [213.711, -183.78],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.26, -17.48],
                                  [5.64, -88.858],
                                  [30.658, -64.911],
                                  [-85.68, -39.502],
                                  [-15.842, -47.836],
                                  [-151.699, 20.123],
                                  [73.341, 66.632],
                                  [-20.226, 96.021],
                                  [-15.767, 92],
                                ],
                                o: [
                                  [-87.055, 23.521],
                                  [-5.64, 88.858],
                                  [-30.658, 64.911],
                                  [85.68, 39.502],
                                  [21.452, 54.74],
                                  [107.685, -10.63],
                                  [-73.341, -66.632],
                                  [12.028, -103.356],
                                  [15.767, -92],
                                ],
                                v: [
                                  [-80.954, -348.044],
                                  [-171.215, -211.859],
                                  [-265.148, -17.517],
                                  [-241.26, 148.021],
                                  [-83.321, 280.49],
                                  [139.365, 381.417],
                                  [298.714, 236.993],
                                  [115.591, -53.162],
                                  [212.495, -182.776],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.519, -16.962],
                                  [6.264, -88.151],
                                  [30.218, -65.344],
                                  [-84.896, -39.584],
                                  [-15.81, -47.474],
                                  [-152.294, 20.395],
                                  [72.282, 66.372],
                                  [-19.78, 95.122],
                                  [-15.883, 92],
                                ],
                                o: [
                                  [-86.861, 23.094],
                                  [-6.264, 88.151],
                                  [-30.218, 65.344],
                                  [84.896, 39.584],
                                  [21.504, 54.481],
                                  [107.62, -10.759],
                                  [-72.282, -66.372],
                                  [11.458, -102.567],
                                  [15.884, -92],
                                ],
                                v: [
                                  [-79.651, -346.721],
                                  [-171.662, -212.372],
                                  [-264.797, -18.158],
                                  [-241.239, 148.329],
                                  [-84.549, 280.194],
                                  [139.435, 380.782],
                                  [298.03, 236.615],
                                  [117.774, -51.009],
                                  [211.214, -181.726],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [117.781, -16.438],
                                  [6.897, -87.436],
                                  [29.772, -65.783],
                                  [-84.102, -39.668],
                                  [-15.777, -47.107],
                                  [-152.896, 20.67],
                                  [71.209, 66.108],
                                  [-19.328, 94.211],
                                  [-16.001, 92],
                                ],
                                o: [
                                  [-86.664, 22.661],
                                  [-6.897, 87.436],
                                  [-29.772, 65.783],
                                  [84.102, 39.668],
                                  [21.556, 54.219],
                                  [107.555, -10.89],
                                  [-71.209, -66.108],
                                  [10.882, -101.768],
                                  [16.001, -92],
                                ],
                                v: [
                                  [-78.308, -345.458],
                                  [-172.106, -212.863],
                                  [-264.504, -18.784],
                                  [-241.237, 148.592],
                                  [-85.802, 279.888],
                                  [139.464, 380.078],
                                  [297.386, 236.342],
                                  [119.983, -48.788],
                                  [209.91, -180.726],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.046, -15.907],
                                  [7.537, -86.711],
                                  [29.321, -66.228],
                                  [-83.297, -39.752],
                                  [-15.744, -46.735],
                                  [-153.507, 20.949],
                                  [70.123, 65.841],
                                  [-18.87, 93.289],
                                  [-16.121, 92],
                                ],
                                o: [
                                  [-86.465, 22.224],
                                  [-7.537, 86.711],
                                  [-29.321, 66.228],
                                  [83.297, 39.752],
                                  [21.609, 53.954],
                                  [107.488, -11.023],
                                  [-70.123, -65.841],
                                  [10.298, -100.959],
                                  [16.121, -92],
                                ],
                                v: [
                                  [-76.89, -344.158],
                                  [-172.6, -213.398],
                                  [-264.109, -19.362],
                                  [-241.245, 148.864],
                                  [-87.066, 279.645],
                                  [139.458, 379.319],
                                  [296.756, 236.085],
                                  [122.192, -46.574],
                                  [208.575, -179.681],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.315, -15.37],
                                  [8.185, -85.977],
                                  [28.864, -66.678],
                                  [-82.482, -39.838],
                                  [-15.711, -46.359],
                                  [-154.125, 21.231],
                                  [69.023, 65.57],
                                  [-18.407, 92.355],
                                  [-16.242, 92],
                                ],
                                o: [
                                  [-86.264, 21.78],
                                  [-8.185, 85.977],
                                  [-28.864, 66.678],
                                  [82.482, 39.838],
                                  [21.663, 53.685],
                                  [107.421, -11.157],
                                  [-69.023, -65.57],
                                  [9.707, -100.139],
                                  [16.242, -92],
                                ],
                                v: [
                                  [-75.459, -342.85],
                                  [-173.087, -213.935],
                                  [-263.676, -19.952],
                                  [-241.296, 149.09],
                                  [-88.382, 279.368],
                                  [139.349, 378.59],
                                  [296.14, 235.844],
                                  [124.456, -44.336],
                                  [207.208, -178.646],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.587, -14.826],
                                  [8.842, -85.234],
                                  [28.402, -67.134],
                                  [-81.657, -39.925],
                                  [-15.677, -45.978],
                                  [-154.751, 21.517],
                                  [67.908, 65.297],
                                  [-17.937, 91.409],
                                  [-16.364, 92],
                                ],
                                o: [
                                  [-86.06, 21.331],
                                  [-8.842, 85.234],
                                  [-28.402, 67.134],
                                  [81.657, 39.925],
                                  [21.717, 53.413],
                                  [107.353, -11.294],
                                  [-67.908, -65.297],
                                  [9.108, -99.309],
                                  [16.364, -92],
                                ],
                                v: [
                                  [-73.986, -341.604],
                                  [-173.592, -214.478],
                                  [-263.239, -20.558],
                                  [-241.362, 149.297],
                                  [-89.683, 279.182],
                                  [139.201, 377.771],
                                  [295.563, 235.71],
                                  [126.751, -42.039],
                                  [205.842, -177.634],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [118.863, -14.274],
                                  [9.507, -84.481],
                                  [27.933, -67.595],
                                  [-80.822, -40.013],
                                  [-15.642, -45.592],
                                  [-155.385, 21.806],
                                  [66.78, 65.019],
                                  [-17.462, 90.451],
                                  [-16.488, 92],
                                ],
                                o: [
                                  [-85.853, 20.876],
                                  [-9.507, 84.481],
                                  [-27.933, 67.595],
                                  [80.822, 40.013],
                                  [21.773, 53.137],
                                  [107.284, -11.431],
                                  [-66.78, -65.019],
                                  [8.502, -98.468],
                                  [16.488, -92],
                                ],
                                v: [
                                  [-72.469, -340.354],
                                  [-174.082, -214.991],
                                  [-262.769, -21.151],
                                  [-241.469, 149.484],
                                  [-91.002, 278.965],
                                  [138.984, 376.949],
                                  [295.007, 235.628],
                                  [129.107, -39.721],
                                  [204.439, -176.577],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.142, -13.716],
                                  [10.18, -83.718],
                                  [27.459, -68.063],
                                  [-79.976, -40.102],
                                  [-15.607, -45.201],
                                  [-156.027, 22.099],
                                  [65.637, 64.738],
                                  [-16.98, 89.481],
                                  [-16.614, 92],
                                ],
                                o: [
                                  [-85.643, 20.416],
                                  [-10.18, 83.718],
                                  [-27.459, 68.063],
                                  [79.976, 40.102],
                                  [21.828, 52.858],
                                  [107.214, -11.571],
                                  [-65.637, -64.738],
                                  [7.887, -97.617],
                                  [16.614, -92],
                                ],
                                v: [
                                  [-70.936, -339.136],
                                  [-174.588, -215.504],
                                  [-262.26, -21.756],
                                  [-241.583, 149.622],
                                  [-92.341, 278.781],
                                  [138.729, 376.037],
                                  [294.463, 235.622],
                                  [131.488, -37.412],
                                  [203.036, -175.538],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.425, -13.151],
                                  [10.862, -82.946],
                                  [26.978, -68.536],
                                  [-79.119, -40.192],
                                  [-15.572, -44.805],
                                  [-156.677, 22.396],
                                  [64.48, 64.454],
                                  [-16.492, 88.499],
                                  [-16.741, 92],
                                ],
                                o: [
                                  [-85.431, 19.949],
                                  [-10.862, 82.946],
                                  [-26.978, 68.536],
                                  [79.119, 40.192],
                                  [21.885, 52.575],
                                  [107.144, -11.712],
                                  [-64.48, -64.454],
                                  [7.266, -96.755],
                                  [16.741, -92],
                                ],
                                v: [
                                  [-69.359, -337.918],
                                  [-175.109, -216.079],
                                  [-261.748, -22.35],
                                  [-241.704, 149.801],
                                  [-93.697, 278.63],
                                  [138.44, 375.126],
                                  [293.967, 235.635],
                                  [133.895, -35.083],
                                  [201.599, -174.516],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [119.711, -12.578],
                                  [11.553, -82.164],
                                  [26.491, -69.016],
                                  [-78.251, -40.283],
                                  [-15.536, -44.405],
                                  [-157.335, 22.697],
                                  [63.308, 64.166],
                                  [-15.999, 87.504],
                                  [-16.87, 92],
                                ],
                                o: [
                                  [-85.217, 19.477],
                                  [-11.553, 82.164],
                                  [-26.491, 69.016],
                                  [78.251, 40.283],
                                  [21.942, 52.289],
                                  [107.072, -11.855],
                                  [-63.308, -64.166],
                                  [6.636, -95.882],
                                  [16.87, -92],
                                ],
                                v: [
                                  [-67.702, -336.767],
                                  [-175.611, -216.621],
                                  [-261.171, -22.963],
                                  [-241.834, 149.927],
                                  [-95.074, 278.545],
                                  [138.05, 374.218],
                                  [293.49, 235.699],
                                  [136.297, -32.703],
                                  [200.19, -173.482],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [16.281, 18.844],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.75, 18.156],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.281, 17.5],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.844, 16.812],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.406, 16.156],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.031, 15.5],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.656, 14.875],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.344, 14.281],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.031, 13.719],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.812, 13.188],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.531, 12.688],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.312, 12.156],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.156, 11.75],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 11.281],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.875, 10.844],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.75, 10.438],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.625, 9.688],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, 9.375],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, 9.031],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, 8.75],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.625, 8.438],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.719, 8.188],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.781, 7.938],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.875, 7.625],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 7.406],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.125, 7.156],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.312, 6.969],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.531, 6.75],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.719, 6.531],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.625, 6.344],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.5, 6.156],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.344, 5.969],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.219, 5.781],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.125, 5.594],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.062, 5.375],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.031, 5.219],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 5.031],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 4.844],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 4.688],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 4.5],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.062, 4.25],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.156, 4.062],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.219, 3.906],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.312, 3.688],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.438, 3.5],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.5, 3.344],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.656, 3.219],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.812, 3.125],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.938, 3.031],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.094, 2.938],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.312, 2.812],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.469, 2.781],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.688, 2.781],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.906, 2.75],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.188, 2.719],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.438, 2.719],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.719, 2.719],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, 2.75],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.5, 2.75],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, 2.812],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.844, 2.875],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.438, 2.938],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.156, 3.031],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.781, 3.125],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.438, 3.219],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.125, 3.312],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.781, 3.469],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.406, 3.594],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.094, 3.719],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.75, 3.875],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.125, 4.188],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 4.406],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.375, 4.562],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.062, 4.781],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 4.938],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.281, 5.188],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.906, 5.375],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.531, 5.625],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.062, 5.906],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.656, 6.125],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.219, 6.375],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 6.625],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.156, 6.906],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.312, 7.219],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.531, 7.5],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.719, 7.75],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, 8.062],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, 8.438],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.156, 8.781],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.281, 9.188],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.438, 9.562],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.625, 10.438],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.781, 10.844],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.812, 11.344],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.906, 11.844],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33, 12.312],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.031, 12.844],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.094, 13.344],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 13.875],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 14.375],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 14.875],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 15.406],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 15.938],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 16.938],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 17.469],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.312, 17.969],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 18.438],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 18.969],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 19.406],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 19.906],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 20.344],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 20.812],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 21.219],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.062, 21.625],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.031, 21.969],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.812, 22.344],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.312, 22.688],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.562, 23.062],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 23.375],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30, 23.656],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.219, 23.906],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.531, 24.344],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 24.469],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.812, 24.594],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.938, 24.688],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.094, 24.781],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.188, 24.875],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.281, 24.875],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.344, 24.875],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.438, 24.875],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.5, 24.812],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.625, 24.781],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.688, 24.656],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.812, 24.594],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, 24.281],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.125, 24.094],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.281, 23.875],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.562, 23.656],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.312, 23.406],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 23.125],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.75, 22.844],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.312, 22.156],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.156, 21.812],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.969, 21.438],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.812, 21.062],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.688, 20.625],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.625, 20.188],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.531, 19.75],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 19.281],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 18.812],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 18.375],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.531, 17.844],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.562, 17.344],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.688, 16.844],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.812, 16.312],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.969, 15.844],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.125, 15.312],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.344, 14.812],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.531, 14.25],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.812, 13.75],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.125, 13.281],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.406, 12.75],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.156, 11.812],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.562, 11.344],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14, 10.844],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.438, 10.406],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 9.969],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.438, 9.562],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.969, 9.125],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.562, 8.781],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.062, 8.406],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.188, 7.781],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, 7.5],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.25, 7.25],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.812, 7.031],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.344, 6.844],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.906, 6.625],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.438, 6.5],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.969, 6.406],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 6.312],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 6.219],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, 6.156],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.031, 6.156],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.031, 6.094],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.438, 6.156],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.938, 6.188],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.406, 6.25],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.781, 6.375],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.188, 6.469],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.531, 6.562],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.219, 6.719],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.812, 6.875],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.469, 7.062],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 7.25],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.781, 7.438],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 7.938],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 8.188],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.219, 8.5],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.812, 8.75],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.406, 9.031],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 9.375],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.625, 9.688],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.219, 10.031],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.844, 10.406],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.438, 10.75],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.062, 11.156],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.656, 11.5],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.281, 11.906],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.938, 12.281],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.562, 12.688],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.219, 13.094],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.906, 13.531],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.562, 13.969],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.281, 14.344],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.969, 14.75],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.719, 15.188],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.438, 15.594],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.219, 15.969],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.969, 16.344],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.594, 17.031],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.438, 17.312],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.312, 17.562],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.219, 17.844],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, 18.062],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.125, 18.281],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.094, 18.469],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.094, 18.594],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, 18.719],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [16.281, 18.844],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.75, 18.156],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.281, 17.5],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.844, 16.812],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.406, 16.156],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.031, 15.5],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.656, 14.875],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.344, 14.281],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.031, 13.719],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.812, 13.188],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.531, 12.688],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.312, 12.156],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.156, 11.75],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 11.281],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.875, 10.844],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.75, 10.438],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.625, 9.688],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, 9.375],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, 9.031],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, 8.75],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.625, 8.438],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.719, 8.188],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.781, 7.938],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.875, 7.625],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 7.406],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.125, 7.156],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.312, 6.969],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.531, 6.75],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.719, 6.531],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.625, 6.344],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.5, 6.156],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.344, 5.969],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.219, 5.781],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.125, 5.594],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.062, 5.375],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.031, 5.219],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 5.031],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 4.844],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 4.688],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 4.5],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.062, 4.25],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.156, 4.062],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.219, 3.906],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.312, 3.688],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.438, 3.5],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.5, 3.344],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.656, 3.219],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.812, 3.125],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.938, 3.031],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.094, 2.938],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.312, 2.812],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.469, 2.781],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.688, 2.781],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.906, 2.75],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.188, 2.719],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.438, 2.719],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.719, 2.719],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, 2.75],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.5, 2.75],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, 2.812],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.844, 2.875],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.438, 2.938],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.156, 3.031],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.781, 3.125],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.438, 3.219],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.125, 3.312],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.781, 3.469],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.406, 3.594],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.094, 3.719],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.75, 3.875],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.125, 4.188],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 4.406],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.375, 4.562],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.062, 4.781],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 4.938],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.281, 5.188],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.906, 5.375],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.531, 5.625],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.062, 5.906],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.656, 6.125],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.219, 6.375],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 6.625],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.156, 6.906],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.312, 7.219],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.531, 7.5],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.719, 7.75],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, 8.062],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, 8.438],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.156, 8.781],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.281, 9.188],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.438, 9.562],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.625, 10.438],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.781, 10.844],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.812, 11.344],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.906, 11.844],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33, 12.312],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.031, 12.844],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.094, 13.344],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 13.875],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 14.375],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 14.875],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 15.406],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 15.938],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 16.938],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 17.469],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.312, 17.969],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 18.438],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 18.969],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.281, 19.406],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 19.906],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 20.344],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.156, 20.812],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 21.219],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.062, 21.625],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.031, 21.969],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.812, 22.344],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.312, 22.688],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.562, 23.062],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.781, 23.375],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30, 23.656],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.219, 23.906],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.531, 24.344],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 24.469],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.812, 24.594],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.938, 24.688],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.094, 24.781],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.188, 24.875],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.281, 24.875],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.344, 24.875],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.438, 24.875],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.5, 24.812],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.625, 24.781],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.688, 24.656],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.812, 24.594],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, 24.281],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.125, 24.094],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.281, 23.875],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.562, 23.656],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.312, 23.406],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 23.125],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.75, 22.844],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.312, 22.156],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.156, 21.812],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.969, 21.438],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.812, 21.062],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.688, 20.625],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.625, 20.188],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.531, 19.75],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 19.281],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 18.812],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 18.375],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.531, 17.844],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.562, 17.344],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.688, 16.844],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.812, 16.312],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.969, 15.844],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.125, 15.312],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.344, 14.812],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.531, 14.25],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.812, 13.75],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.125, 13.281],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.406, 12.75],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.156, 11.812],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.562, 11.344],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14, 10.844],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.438, 10.406],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 9.969],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.438, 9.562],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.969, 9.125],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.562, 8.781],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.062, 8.406],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.188, 7.781],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, 7.5],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.25, 7.25],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.812, 7.031],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.344, 6.844],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.906, 6.625],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.438, 6.5],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.969, 6.406],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.469, 6.312],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 6.219],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, 6.156],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.031, 6.156],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.031, 6.094],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.438, 6.156],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.938, 6.188],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.406, 6.25],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.781, 6.375],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.188, 6.469],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.531, 6.562],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.219, 6.719],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.812, 6.875],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.469, 7.062],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 7.25],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.781, 7.438],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, 7.938],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 8.188],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.219, 8.5],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.812, 8.75],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.406, 9.031],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 9.375],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.625, 9.688],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.219, 10.031],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.844, 10.406],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.438, 10.75],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.062, 11.156],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.656, 11.5],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.281, 11.906],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.938, 12.281],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.562, 12.688],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.219, 13.094],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.906, 13.531],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.562, 13.969],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.281, 14.344],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.969, 14.75],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.719, 15.188],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.438, 15.594],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.219, 15.969],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.969, 16.344],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.594, 17.031],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.438, 17.312],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.312, 17.562],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.219, 17.844],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, 18.062],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.125, 18.281],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.094, 18.469],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.094, 18.594],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, 18.719],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 8',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 8,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [117.144, -17.713],
                                  [5.359, -89.176],
                                  [30.856, -64.716],
                                  [-86.033, -39.465],
                                  [-15.857, -47.999],
                                  [-151.43, 20.001],
                                  [73.818, 66.749],
                                  [-20.427, 96.426],
                                  [-15.715, 92],
                                ],
                                o: [
                                  [-87.142, 23.713],
                                  [-5.359, 89.176],
                                  [-30.856, 64.716],
                                  [86.033, 39.465],
                                  [21.429, 54.856],
                                  [107.714, -10.572],
                                  [-73.818, -66.749],
                                  [12.284, -103.712],
                                  [15.715, -92],
                                ],
                                v: [
                                  [-76.417, -347.398],
                                  [-174.831, -209.77],
                                  [-262.892, -18.332],
                                  [-240.929, 149.759],
                                  [-80.799, 284.65],
                                  [139.366, 378.141],
                                  [288.608, 233.679],
                                  [116.408, -51.726],
                                  [211.496, -180.123],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.86, -18.28],
                                  [4.675, -89.951],
                                  [31.338, -64.241],
                                  [-86.893, -39.374],
                                  [-15.893, -48.396],
                                  [-150.778, 19.703],
                                  [74.979, 67.035],
                                  [-20.917, 97.411],
                                  [-15.587, 92],
                                ],
                                o: [
                                  [-87.355, 24.181],
                                  [-4.675, 89.951],
                                  [-31.338, 64.241],
                                  [86.893, 39.374],
                                  [21.372, 55.14],
                                  [107.785, -10.43],
                                  [-74.979, -67.035],
                                  [12.908, -104.577],
                                  [15.587, -92],
                                ],
                                v: [
                                  [-76.84, -348.358],
                                  [-175.224, -208.836],
                                  [-262.667, -17.671],
                                  [-241, 150.152],
                                  [-79.039, 285.593],
                                  [139.055, 377.92],
                                  [287.387, 233.445],
                                  [114.752, -53.245],
                                  [212.988, -180.521],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.581, -18.839],
                                  [4, -90.715],
                                  [31.813, -63.772],
                                  [-87.74, -39.285],
                                  [-15.927, -48.787],
                                  [-150.135, 19.409],
                                  [76.124, 67.316],
                                  [-21.399, 98.383],
                                  [-15.461, 92],
                                ],
                                o: [
                                  [-87.565, 24.642],
                                  [-4, 90.715],
                                  [-31.813, 63.772],
                                  [87.74, 39.285],
                                  [21.316, 55.419],
                                  [107.855, -10.29],
                                  [-76.124, -67.316],
                                  [13.523, -105.429],
                                  [15.461, -92],
                                ],
                                v: [
                                  [-77.225, -349.294],
                                  [-175.577, -207.983],
                                  [-262.414, -17.02],
                                  [-241.039, 150.572],
                                  [-77.235, 286.486],
                                  [138.666, 377.688],
                                  [286.183, 233.229],
                                  [113.098, -54.713],
                                  [214.479, -180.924],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.305, -19.39],
                                  [3.336, -91.467],
                                  [32.281, -63.311],
                                  [-88.575, -39.197],
                                  [-15.962, -49.173],
                                  [-149.502, 19.12],
                                  [77.251, 67.593],
                                  [-21.874, 99.34],
                                  [-15.337, 92],
                                ],
                                o: [
                                  [-87.771, 25.097],
                                  [-3.336, 91.467],
                                  [-32.281, 63.311],
                                  [88.575, 39.197],
                                  [21.261, 55.695],
                                  [107.924, -10.152],
                                  [-77.251, -67.593],
                                  [14.129, -106.27],
                                  [15.337, -92],
                                ],
                                v: [
                                  [-77.637, -350.206],
                                  [-175.989, -207.124],
                                  [-262.153, -16.331],
                                  [-241.11, 151.016],
                                  [-75.4, 287.32],
                                  [138.282, 377.42],
                                  [285.031, 233.007],
                                  [111.492, -56.158],
                                  [215.969, -181.234],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.034, -19.933],
                                  [2.681, -92.208],
                                  [32.743, -62.856],
                                  [-89.398, -39.111],
                                  [-15.996, -49.553],
                                  [-148.877, 18.835],
                                  [78.362, 67.866],
                                  [-22.342, 100.283],
                                  [-15.215, 92],
                                ],
                                o: [
                                  [-87.975, 25.545],
                                  [-2.681, 92.208],
                                  [-32.743, 62.856],
                                  [89.398, 39.111],
                                  [21.207, 55.966],
                                  [107.992, -10.017],
                                  [-78.363, -67.866],
                                  [14.726, -107.098],
                                  [15.215, -92],
                                ],
                                v: [
                                  [-78.04, -351.107],
                                  [-176.422, -206.26],
                                  [-261.827, -15.674],
                                  [-241.176, 151.473],
                                  [-73.585, 288.13],
                                  [137.818, 377.143],
                                  [283.907, 232.84],
                                  [109.949, -57.541],
                                  [217.446, -181.489],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.766, -20.468],
                                  [2.036, -92.939],
                                  [33.198, -62.408],
                                  [-90.209, -39.025],
                                  [-16.029, -49.927],
                                  [-148.262, 18.554],
                                  [79.458, 68.135],
                                  [-22.803, 101.213],
                                  [-15.095, 92],
                                ],
                                o: [
                                  [-88.175, 25.986],
                                  [-2.036, 92.939],
                                  [-33.198, 62.408],
                                  [90.209, 39.025],
                                  [21.153, 56.234],
                                  [108.058, -9.883],
                                  [-79.458, -68.135],
                                  [15.315, -107.913],
                                  [15.095, -92],
                                ],
                                v: [
                                  [-78.42, -351.922],
                                  [-176.839, -205.478],
                                  [-261.456, -14.985],
                                  [-241.252, 151.952],
                                  [-71.765, 288.88],
                                  [137.31, 376.857],
                                  [282.799, 232.704],
                                  [108.419, -58.911],
                                  [218.981, -181.728],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.502, -20.995],
                                  [1.4, -93.659],
                                  [33.646, -61.967],
                                  [-91.008, -38.941],
                                  [-16.062, -50.296],
                                  [-147.656, 18.278],
                                  [80.536, 68.401],
                                  [-23.258, 102.129],
                                  [-14.976, 92],
                                ],
                                o: [
                                  [-88.373, 26.421],
                                  [-1.4, 93.659],
                                  [-33.646, 61.967],
                                  [91.008, 38.941],
                                  [21.101, 56.497],
                                  [108.124, -9.751],
                                  [-80.537, -68.401],
                                  [15.894, -108.717],
                                  [14.976, -92],
                                ],
                                v: [
                                  [-78.865, -352.641],
                                  [-177.315, -204.691],
                                  [-261.069, -14.302],
                                  [-241.325, 152.414],
                                  [-69.903, 289.621],
                                  [136.731, 376.499],
                                  [281.682, 232.601],
                                  [106.925, -60.219],
                                  [220.49, -181.903],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.243, -21.514],
                                  [0.773, -94.368],
                                  [34.087, -61.532],
                                  [-91.795, -38.859],
                                  [-16.095, -50.66],
                                  [-147.059, 18.005],
                                  [81.6, 68.662],
                                  [-23.706, 103.031],
                                  [-14.859, 92],
                                ],
                                o: [
                                  [-88.568, 26.849],
                                  [-0.773, 94.368],
                                  [-34.087, 61.532],
                                  [91.795, 38.859],
                                  [21.049, 56.757],
                                  [108.189, -9.621],
                                  [-81.6, -68.662],
                                  [16.466, -109.509],
                                  [14.859, -92],
                                ],
                                v: [
                                  [-79.286, -353.316],
                                  [-177.772, -203.925],
                                  [-260.625, -13.634],
                                  [-241.434, 152.859],
                                  [-68.077, 290.303],
                                  [136.146, 376.171],
                                  [280.657, 232.516],
                                  [105.444, -61.54],
                                  [222.043, -182.042],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.987, -22.026],
                                  [0.156, -95.067],
                                  [34.522, -61.103],
                                  [-92.571, -38.777],
                                  [-16.127, -51.018],
                                  [-146.47, 17.736],
                                  [82.647, 68.919],
                                  [-24.148, 103.92],
                                  [-14.744, 92],
                                ],
                                o: [
                                  [-88.76, 27.272],
                                  [-0.156, 95.067],
                                  [-34.522, 61.103],
                                  [92.571, 38.777],
                                  [20.997, 57.013],
                                  [108.253, -9.493],
                                  [-82.647, -68.919],
                                  [17.029, -110.29],
                                  [14.744, -92],
                                ],
                                v: [
                                  [-79.759, -353.899],
                                  [-178.225, -203.218],
                                  [-260.164, -12.906],
                                  [-241.518, 153.323],
                                  [-66.249, 290.94],
                                  [135.492, 375.795],
                                  [279.586, 232.426],
                                  [104.063, -62.774],
                                  [223.53, -182.118],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.735, -22.53],
                                  [-0.452, -95.756],
                                  [34.951, -60.681],
                                  [-93.335, -38.697],
                                  [-16.158, -51.371],
                                  [-145.89, 17.471],
                                  [83.679, 69.173],
                                  [-24.582, 104.797],
                                  [-14.631, 92],
                                ],
                                o: [
                                  [-88.949, 27.688],
                                  [0.452, 95.756],
                                  [-34.951, 60.681],
                                  [93.335, 38.697],
                                  [20.947, 57.265],
                                  [108.316, -9.367],
                                  [-83.679, -69.173],
                                  [17.583, -111.059],
                                  [14.631, -92],
                                ],
                                v: [
                                  [-80.244, -354.481],
                                  [-178.72, -202.469],
                                  [-259.685, -12.164],
                                  [-241.618, 153.829],
                                  [-64.443, 291.557],
                                  [134.791, 375.451],
                                  [278.631, 232.37],
                                  [102.655, -63.998],
                                  [225.075, -182.209],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.486, -23.027],
                                  [-1.052, -96.434],
                                  [35.373, -60.265],
                                  [-94.088, -38.618],
                                  [-16.189, -51.719],
                                  [-145.319, 17.211],
                                  [84.696, 69.423],
                                  [-25.011, 105.66],
                                  [-14.519, 92],
                                ],
                                o: [
                                  [-89.135, 28.097],
                                  [1.052, 96.434],
                                  [-35.373, 60.265],
                                  [94.088, 38.618],
                                  [20.897, 57.514],
                                  [108.378, -9.243],
                                  [-84.696, -69.423],
                                  [18.13, -111.817],
                                  [14.519, -92],
                                ],
                                v: [
                                  [-80.718, -354.938],
                                  [-179.211, -201.779],
                                  [-259.15, -11.407],
                                  [-241.741, 154.274],
                                  [-62.659, 292.091],
                                  [134.082, 375.036],
                                  [277.606, 232.349],
                                  [101.283, -65.158],
                                  [226.616, -182.232],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.242, -23.517],
                                  [-1.642, -97.102],
                                  [35.789, -59.855],
                                  [-94.83, -38.54],
                                  [-16.22, -52.062],
                                  [-144.756, 16.954],
                                  [85.698, 69.669],
                                  [-25.433, 106.51],
                                  [-14.409, 92],
                                ],
                                o: [
                                  [-89.319, 28.501],
                                  [1.642, 97.102],
                                  [-35.789, 59.855],
                                  [94.83, 38.54],
                                  [20.848, 57.758],
                                  [108.44, -9.121],
                                  [-85.698, -69.669],
                                  [18.668, -112.563],
                                  [14.409, -92],
                                ],
                                v: [
                                  [-81.203, -355.396],
                                  [-179.704, -201.11],
                                  [-258.615, -10.649],
                                  [-241.842, 154.796],
                                  [-60.852, 292.669],
                                  [133.326, 374.613],
                                  [276.676, 232.347],
                                  [99.965, -66.293],
                                  [228.135, -182.193],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.001, -23.999],
                                  [-2.224, -97.761],
                                  [36.199, -59.451],
                                  [-95.561, -38.463],
                                  [-16.25, -52.399],
                                  [-144.201, 16.701],
                                  [86.685, 69.912],
                                  [-25.849, 107.348],
                                  [-14.3, 92],
                                ],
                                o: [
                                  [-89.5, 28.899],
                                  [2.224, 97.761],
                                  [-36.199, 59.451],
                                  [95.561, 38.463],
                                  [20.8, 57.999],
                                  [108.5, -9],
                                  [-86.685, -69.912],
                                  [19.199, -113.298],
                                  [14.3, -92],
                                ],
                                v: [
                                  [-81.717, -355.732],
                                  [-180.191, -200.461],
                                  [-258.047, -9.851],
                                  [-241.985, 155.253],
                                  [-59.05, 293.143],
                                  [132.5, 374.246],
                                  [275.76, 232.36],
                                  [98.664, -67.381],
                                  [229.688, -182.152],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.763, -24.474],
                                  [-2.797, -98.41],
                                  [36.603, -59.053],
                                  [-96.281, -38.387],
                                  [-16.28, -52.732],
                                  [-143.655, 16.451],
                                  [87.658, 70.151],
                                  [-26.259, 108.174],
                                  [-14.193, 92],
                                ],
                                o: [
                                  [-89.678, 29.291],
                                  [2.797, 98.41],
                                  [-36.603, 59.053],
                                  [96.281, 38.387],
                                  [20.753, 58.237],
                                  [108.559, -8.882],
                                  [-87.658, -70.151],
                                  [19.721, -114.023],
                                  [14.193, -92],
                                ],
                                v: [
                                  [-82.241, -356.053],
                                  [-180.654, -199.809],
                                  [-257.42, -9.074],
                                  [-242.107, 155.723],
                                  [-57.266, 293.64],
                                  [131.666, 373.809],
                                  [274.879, 232.432],
                                  [97.395, -68.483],
                                  [231.216, -182.07],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.529, -24.942],
                                  [-3.361, -99.049],
                                  [37.001, -58.661],
                                  [-96.99, -38.312],
                                  [-16.309, -53.059],
                                  [-143.117, 16.205],
                                  [88.616, 70.386],
                                  [-26.662, 108.987],
                                  [-14.088, 92],
                                ],
                                o: [
                                  [-89.853, 29.677],
                                  [3.361, 99.049],
                                  [-37.001, 58.661],
                                  [96.99, 38.312],
                                  [20.706, 58.471],
                                  [108.618, -8.765],
                                  [-88.616, -70.386],
                                  [20.236, -114.736],
                                  [14.088, -92],
                                ],
                                v: [
                                  [-82.774, -356.382],
                                  [-181.175, -199.199],
                                  [-256.797, -8.254],
                                  [-242.232, 156.207],
                                  [-55.527, 294.079],
                                  [130.782, 373.405],
                                  [274.053, 232.502],
                                  [96.141, -69.499],
                                  [232.717, -181.989],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.299, -25.403],
                                  [-3.917, -99.678],
                                  [37.393, -58.275],
                                  [-97.689, -38.239],
                                  [-16.338, -53.382],
                                  [-142.587, 15.963],
                                  [89.559, 70.618],
                                  [-27.06, 109.788],
                                  [-13.984, 92],
                                ],
                                o: [
                                  [-90.026, 30.057],
                                  [3.917, 99.678],
                                  [-37.393, 58.275],
                                  [97.689, 38.239],
                                  [20.66, 58.701],
                                  [108.675, -8.649],
                                  [-89.559, -70.618],
                                  [20.743, -115.44],
                                  [13.984, -92],
                                ],
                                v: [
                                  [-83.338, -356.595],
                                  [-181.628, -198.588],
                                  [-256.179, -7.411],
                                  [-242.401, 156.683],
                                  [-53.789, 294.501],
                                  [129.891, 372.953],
                                  [273.222, 232.549],
                                  [94.901, -70.51],
                                  [234.232, -181.85],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.071, -25.857],
                                  [-4.465, -100.299],
                                  [37.779, -57.895],
                                  [-98.377, -38.167],
                                  [-16.366, -53.7],
                                  [-142.064, 15.725],
                                  [90.489, 70.847],
                                  [-27.452, 110.577],
                                  [-13.882, 92],
                                ],
                                o: [
                                  [-90.196, 30.432],
                                  [4.465, 100.299],
                                  [-37.779, 57.895],
                                  [98.377, 38.167],
                                  [20.614, 58.929],
                                  [108.732, -8.536],
                                  [-90.489, -70.847],
                                  [21.243, -116.132],
                                  [13.882, -92],
                                ],
                                v: [
                                  [-83.91, -356.82],
                                  [-182.139, -197.995],
                                  [-255.484, -6.544],
                                  [-242.553, 157.128],
                                  [-52.031, 294.864],
                                  [128.929, 372.516],
                                  [272.426, 232.657],
                                  [93.695, -71.456],
                                  [235.761, -181.673],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.848, -26.304],
                                  [-5.005, -100.909],
                                  [38.159, -57.52],
                                  [-99.055, -38.095],
                                  [-16.394, -54.013],
                                  [-141.55, 15.49],
                                  [91.404, 71.072],
                                  [-27.838, 111.354],
                                  [-13.781, 92],
                                ],
                                o: [
                                  [-90.364, 30.801],
                                  [5.005, 100.909],
                                  [-38.159, 57.52],
                                  [99.055, 38.095],
                                  [20.57, 59.152],
                                  [108.788, -8.424],
                                  [-91.404, -71.072],
                                  [21.735, -116.814],
                                  [13.781, -92],
                                ],
                                v: [
                                  [-84.47, -356.975],
                                  [-182.56, -197.421],
                                  [-254.815, -5.673],
                                  [-242.728, 157.541],
                                  [-50.317, 295.233],
                                  [127.979, 372.094],
                                  [271.688, 232.763],
                                  [92.565, -72.398],
                                  [237.263, -181.503],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.627, -26.745],
                                  [-5.536, -101.511],
                                  [38.533, -57.151],
                                  [-99.723, -38.025],
                                  [-16.422, -54.322],
                                  [-141.043, 15.259],
                                  [92.306, 71.294],
                                  [-28.218, 112.12],
                                  [-13.682, 92],
                                ],
                                o: [
                                  [-90.529, 31.165],
                                  [5.536, 101.511],
                                  [-38.533, 57.151],
                                  [99.723, 38.025],
                                  [20.526, 59.372],
                                  [108.843, -8.314],
                                  [-92.306, -71.294],
                                  [22.22, -117.486],
                                  [13.682, -92],
                                ],
                                v: [
                                  [-85.061, -357.145],
                                  [-183.057, -196.866],
                                  [-254.11, -4.735],
                                  [-242.886, 157.982],
                                  [-48.646, 295.609],
                                  [126.959, 371.707],
                                  [271.008, 232.891],
                                  [91.406, -73.274],
                                  [238.777, -181.318],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.41, -27.179],
                                  [-6.06, -102.104],
                                  [38.902, -56.788],
                                  [-100.381, -37.956],
                                  [-16.449, -54.625],
                                  [-140.544, 15.031],
                                  [93.195, 71.512],
                                  [-28.592, 112.874],
                                  [-13.585, 92],
                                ],
                                o: [
                                  [-90.692, 31.523],
                                  [6.06, 102.104],
                                  [-38.902, 56.788],
                                  [100.381, 37.956],
                                  [20.482, 59.589],
                                  [108.897, -8.205],
                                  [-93.195, -71.512],
                                  [22.697, -118.148],
                                  [13.585, -92],
                                ],
                                v: [
                                  [-85.661, -357.269],
                                  [-183.462, -196.329],
                                  [-253.412, -3.812],
                                  [-243.072, 158.433],
                                  [-46.976, 295.909],
                                  [125.93, 371.292],
                                  [270.303, 233.061],
                                  [90.281, -74.149],
                                  [240.241, -181.1],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.197, -27.606],
                                  [-6.575, -102.688],
                                  [39.266, -56.43],
                                  [-101.029, -37.888],
                                  [-16.475, -54.925],
                                  [-140.053, 14.807],
                                  [94.07, 71.727],
                                  [-28.961, 113.616],
                                  [-13.489, 92],
                                ],
                                o: [
                                  [-90.852, 31.875],
                                  [6.575, 102.688],
                                  [-39.266, 56.43],
                                  [101.029, 37.888],
                                  [20.439, 59.803],
                                  [108.951, -8.098],
                                  [-94.069, -71.727],
                                  [23.167, -118.8],
                                  [13.489, -92],
                                ],
                                v: [
                                  [-86.293, -357.368],
                                  [-183.923, -195.81],
                                  [-252.702, -2.841],
                                  [-243.281, 158.805],
                                  [-45.284, 296.195],
                                  [124.893, 370.894],
                                  [269.657, 233.252],
                                  [89.169, -74.982],
                                  [241.759, -180.868],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.986, -28.027],
                                  [-7.083, -103.263],
                                  [39.623, -56.077],
                                  [-101.667, -37.821],
                                  [-16.502, -55.219],
                                  [-139.568, 14.586],
                                  [94.931, 71.939],
                                  [-29.324, 114.348],
                                  [-13.394, 92],
                                ],
                                o: [
                                  [-91.01, 32.223],
                                  [7.083, 103.263],
                                  [-39.623, 56.077],
                                  [101.667, 37.821],
                                  [20.397, 60.014],
                                  [109.003, -7.993],
                                  [-94.931, -71.939],
                                  [23.63, -119.442],
                                  [13.394, -92],
                                ],
                                v: [
                                  [-86.914, -357.449],
                                  [-184.31, -195.265],
                                  [-251.978, -1.865],
                                  [-243.432, 159.246],
                                  [-43.657, 296.448],
                                  [123.805, 370.448],
                                  [269.07, 233.423],
                                  [88.091, -75.772],
                                  [243.225, -180.586],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.779, -28.442],
                                  [-7.583, -103.829],
                                  [39.976, -55.73],
                                  [-102.295, -37.755],
                                  [-16.528, -55.509],
                                  [-139.092, 14.368],
                                  [95.78, 72.147],
                                  [-29.681, 115.068],
                                  [-13.301, 92],
                                ],
                                o: [
                                  [-91.166, 32.565],
                                  [7.583, 103.829],
                                  [-39.976, 55.73],
                                  [102.295, 37.755],
                                  [20.356, 60.221],
                                  [109.055, -7.889],
                                  [-95.78, -72.147],
                                  [24.086, -120.074],
                                  [13.301, -92],
                                ],
                                v: [
                                  [-87.543, -357.552],
                                  [-184.69, -194.78],
                                  [-251.24, -0.858],
                                  [-243.631, 159.605],
                                  [-42.051, 296.689],
                                  [122.69, 370.102],
                                  [268.524, 233.64],
                                  [87, -76.539],
                                  [244.682, -180.355],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.575, -28.85],
                                  [-8.076, -104.386],
                                  [40.323, -55.388],
                                  [-102.914, -37.689],
                                  [-16.553, -55.795],
                                  [-138.622, 14.154],
                                  [96.616, 72.353],
                                  [-30.034, 115.778],
                                  [-13.209, 92],
                                ],
                                o: [
                                  [-91.319, 32.902],
                                  [8.076, 104.386],
                                  [-40.323, 55.388],
                                  [102.914, 37.689],
                                  [20.315, 60.425],
                                  [109.106, -7.787],
                                  [-96.616, -72.353],
                                  [24.535, -120.697],
                                  [13.209, -92],
                                ],
                                v: [
                                  [-88.205, -357.57],
                                  [-185.1, -194.268],
                                  [-250.509, 0.155],
                                  [-243.834, 159.97],
                                  [-40.466, 296.944],
                                  [121.541, 369.706],
                                  [268.019, 233.839],
                                  [85.967, -77.266],
                                  [246.131, -180.076],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.374, -29.253],
                                  [-8.561, -104.936],
                                  [40.665, -55.051],
                                  [-103.523, -37.625],
                                  [-16.578, -56.077],
                                  [-138.16, 13.942],
                                  [97.439, 72.555],
                                  [-30.38, 116.476],
                                  [-13.118, 92],
                                ],
                                o: [
                                  [-91.47, 33.233],
                                  [8.561, 104.936],
                                  [-40.665, 55.051],
                                  [103.523, 37.625],
                                  [20.275, 60.626],
                                  [109.157, -7.687],
                                  [-97.439, -72.555],
                                  [24.978, -121.31],
                                  [13.118, -92],
                                ],
                                v: [
                                  [-88.857, -357.658],
                                  [-185.414, -193.816],
                                  [-249.791, 1.219],
                                  [-244.086, 160.312],
                                  [-38.902, 297.144],
                                  [120.428, 369.304],
                                  [267.512, 234.059],
                                  [84.922, -77.996],
                                  [247.527, -179.788],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.176, -29.649],
                                  [-9.039, -105.476],
                                  [41.001, -54.719],
                                  [-104.124, -37.562],
                                  [-16.603, -56.354],
                                  [-137.704, 13.734],
                                  [98.249, 72.754],
                                  [-30.722, 117.164],
                                  [-13.029, 92],
                                ],
                                o: [
                                  [-91.618, 33.56],
                                  [9.039, 105.476],
                                  [-41.001, 54.719],
                                  [104.124, 37.562],
                                  [20.235, 60.824],
                                  [109.206, -7.588],
                                  [-98.249, -72.754],
                                  [25.413, -121.914],
                                  [13.029, -92],
                                ],
                                v: [
                                  [-89.561, -357.691],
                                  [-185.755, -193.336],
                                  [-249.017, 2.289],
                                  [-244.281, 160.659],
                                  [-37.357, 297.36],
                                  [119.262, 368.96],
                                  [267.11, 234.307],
                                  [83.907, -78.66],
                                  [248.975, -179.49],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.981, -30.039],
                                  [-9.509, -106.009],
                                  [41.333, -54.393],
                                  [-104.715, -37.5],
                                  [-16.627, -56.627],
                                  [-137.256, 13.53],
                                  [99.048, 72.951],
                                  [-31.058, 117.842],
                                  [-12.941, 92],
                                ],
                                o: [
                                  [-91.764, 33.882],
                                  [9.509, 106.009],
                                  [-41.333, 54.393],
                                  [104.715, 37.5],
                                  [20.196, 61.019],
                                  [109.255, -7.49],
                                  [-99.048, -72.951],
                                  [25.842, -122.509],
                                  [12.941, -92],
                                ],
                                v: [
                                  [-90.255, -357.723],
                                  [-186.088, -192.825],
                                  [-248.321, 3.366],
                                  [-244.498, 160.98],
                                  [-35.833, 297.566],
                                  [118.071, 368.593],
                                  [266.708, 234.558],
                                  [82.951, -79.313],
                                  [250.37, -179.21],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.789, -30.422],
                                  [-9.972, -106.533],
                                  [41.659, -54.071],
                                  [-105.297, -37.439],
                                  [-16.651, -56.896],
                                  [-136.814, 13.328],
                                  [99.833, 73.144],
                                  [-31.389, 118.509],
                                  [-12.855, 92],
                                ],
                                o: [
                                  [-91.908, 34.199],
                                  [9.972, 106.533],
                                  [-41.659, 54.071],
                                  [105.297, 37.439],
                                  [20.158, 61.211],
                                  [109.303, -7.394],
                                  [-99.834, -73.144],
                                  [26.265, -123.094],
                                  [12.855, -92],
                                ],
                                v: [
                                  [-90.968, -357.75],
                                  [-186.385, -192.375],
                                  [-247.57, 4.477],
                                  [-244.702, 161.258],
                                  [-34.345, 297.745],
                                  [116.844, 368.3],
                                  [266.396, 234.84],
                                  [81.981, -79.882],
                                  [251.754, -178.922],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.6, -30.801],
                                  [-10.428, -107.05],
                                  [41.981, -53.754],
                                  [-105.87, -37.379],
                                  [-16.675, -57.16],
                                  [-136.379, 13.13],
                                  [100.608, 73.334],
                                  [-31.716, 119.166],
                                  [-12.77, 92],
                                ],
                                o: [
                                  [-92.05, 34.51],
                                  [10.428, 107.05],
                                  [-41.98, 53.754],
                                  [105.87, 37.379],
                                  [20.12, 61.4],
                                  [109.35, -7.3],
                                  [-100.607, -73.334],
                                  [26.681, -123.671],
                                  [12.77, -92],
                                ],
                                v: [
                                  [-91.705, -357.806],
                                  [-186.629, -191.94],
                                  [-246.853, 5.594],
                                  [-244.91, 161.538],
                                  [-32.859, 297.899],
                                  [115.654, 367.985],
                                  [266.1, 235.143],
                                  [80.978, -80.476],
                                  [253.083, -178.61],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.414, -31.173],
                                  [-10.877, -107.558],
                                  [42.297, -53.443],
                                  [-106.434, -37.319],
                                  [-16.698, -57.421],
                                  [-135.951, 12.934],
                                  [101.37, 73.521],
                                  [-32.037, 119.813],
                                  [-12.686, 92],
                                ],
                                o: [
                                  [-92.19, 34.818],
                                  [10.877, 107.558],
                                  [-42.297, 53.443],
                                  [106.434, 37.319],
                                  [20.083, 61.586],
                                  [109.397, -7.207],
                                  [-101.37, -73.521],
                                  [27.09, -124.239],
                                  [12.686, -92],
                                ],
                                v: [
                                  [-92.463, -357.878],
                                  [-186.851, -191.489],
                                  [-246.173, 6.765],
                                  [-245.123, 161.819],
                                  [-31.409, 298.045],
                                  [114.41, 367.682],
                                  [265.834, 235.415],
                                  [80.051, -81.034],
                                  [254.448, -178.274],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.23, -31.539],
                                  [-11.319, -108.059],
                                  [42.609, -53.136],
                                  [-106.989, -37.261],
                                  [-16.721, -57.678],
                                  [-135.53, 12.742],
                                  [102.12, 73.706],
                                  [-32.353, 120.45],
                                  [-12.604, 92],
                                ],
                                o: [
                                  [-92.327, 35.12],
                                  [11.319, 108.059],
                                  [-42.609, 53.136],
                                  [106.989, 37.261],
                                  [20.046, 61.77],
                                  [109.442, -7.115],
                                  [-102.12, -73.706],
                                  [27.493, -124.798],
                                  [12.604, -92],
                                ],
                                v: [
                                  [-93.212, -357.935],
                                  [-187.064, -191.051],
                                  [-245.484, 7.943],
                                  [-245.387, 162.071],
                                  [-29.976, 298.213],
                                  [113.204, 367.389],
                                  [265.631, 235.755],
                                  [79.107, -81.539],
                                  [255.773, -177.993],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.05, -31.9],
                                  [-11.755, -108.551],
                                  [42.915, -52.833],
                                  [-107.536, -37.203],
                                  [-16.744, -57.93],
                                  [-135.115, 12.552],
                                  [102.859, 73.887],
                                  [-32.664, 121.077],
                                  [-12.522, 92],
                                ],
                                o: [
                                  [-92.463, 35.418],
                                  [11.755, 108.551],
                                  [-42.915, 52.833],
                                  [107.536, 37.203],
                                  [20.01, 61.95],
                                  [109.488, -7.025],
                                  [-102.859, -73.887],
                                  [27.89, -125.348],
                                  [12.522, -92],
                                ],
                                v: [
                                  [-94.015, -358.011],
                                  [-187.238, -190.579],
                                  [-244.833, 9.128],
                                  [-245.594, 162.293],
                                  [-28.562, 298.374],
                                  [111.96, 367.137],
                                  [265.523, 236.036],
                                  [78.176, -82.025],
                                  [257.072, -177.691],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.872, -32.256],
                                  [-12.183, -109.037],
                                  [43.217, -52.536],
                                  [-108.075, -37.147],
                                  [-16.766, -58.179],
                                  [-134.706, 12.366],
                                  [103.586, 74.066],
                                  [-32.97, 121.694],
                                  [-12.443, 92],
                                ],
                                o: [
                                  [-92.596, 35.711],
                                  [12.183, 109.037],
                                  [-43.217, 52.536],
                                  [108.075, 37.147],
                                  [19.974, 62.128],
                                  [109.532, -6.936],
                                  [-103.586, -74.066],
                                  [28.281, -125.89],
                                  [12.443, -92],
                                ],
                                v: [
                                  [-94.795, -358.122],
                                  [-187.371, -190.166],
                                  [-244.173, 10.319],
                                  [-245.82, 162.498],
                                  [-27.228, 298.514],
                                  [110.692, 366.896],
                                  [265.433, 236.368],
                                  [77.274, -82.46],
                                  [258.33, -177.414],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.697, -32.605],
                                  [-12.605, -109.514],
                                  [43.515, -52.243],
                                  [-108.605, -37.091],
                                  [-16.788, -58.424],
                                  [-134.304, 12.182],
                                  [104.302, 74.242],
                                  [-33.272, 122.302],
                                  [-12.364, 92],
                                ],
                                o: [
                                  [-92.727, 36],
                                  [12.605, 109.514],
                                  [-43.515, 52.243],
                                  [108.605, 37.091],
                                  [19.939, 62.303],
                                  [109.576, -6.849],
                                  [-104.302, -74.242],
                                  [28.666, -126.423],
                                  [12.364, -92],
                                ],
                                v: [
                                  [-95.63, -358.221],
                                  [-187.511, -189.718],
                                  [-243.553, 11.548],
                                  [-246.004, 162.67],
                                  [-25.863, 298.632],
                                  [109.432, 366.649],
                                  [265.376, 236.69],
                                  [76.385, -82.877],
                                  [259.624, -177.132],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.525, -32.95],
                                  [-13.021, -109.985],
                                  [43.807, -51.955],
                                  [-109.127, -37.036],
                                  [-16.809, -58.665],
                                  [-133.908, 12.001],
                                  [105.007, 74.415],
                                  [-33.569, 122.9],
                                  [-12.286, 92],
                                ],
                                o: [
                                  [-92.856, 36.284],
                                  [13.021, 109.985],
                                  [-43.807, 51.955],
                                  [109.127, 37.036],
                                  [19.905, 62.475],
                                  [109.619, -6.763],
                                  [-105.007, -74.415],
                                  [29.045, -126.949],
                                  [12.286, -92],
                                ],
                                v: [
                                  [-96.456, -358.374],
                                  [-187.609, -189.249],
                                  [-242.973, 12.785],
                                  [-246.224, 162.856],
                                  [-24.563, 298.793],
                                  [108.164, 366.459],
                                  [265.384, 237.049],
                                  [75.524, -83.293],
                                  [260.86, -176.893],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.356, -33.289],
                                  [-13.43, -110.448],
                                  [44.096, -51.671],
                                  [-109.641, -36.982],
                                  [-16.831, -58.902],
                                  [-133.518, 11.823],
                                  [105.701, 74.586],
                                  [-33.862, 123.489],
                                  [-12.21, 92],
                                ],
                                o: [
                                  [-92.983, 36.563],
                                  [13.43, 110.448],
                                  [-44.096, 51.671],
                                  [109.641, 36.982],
                                  [19.871, 62.644],
                                  [109.661, -6.678],
                                  [-105.701, -74.586],
                                  [29.418, -127.465],
                                  [12.21, -92],
                                ],
                                v: [
                                  [-97.324, -358.534],
                                  [-187.65, -188.839],
                                  [-242.435, 14.044],
                                  [-246.462, 162.978],
                                  [-23.247, 298.948],
                                  [106.888, 366.264],
                                  [265.491, 237.381],
                                  [74.643, -83.629],
                                  [262.1, -176.65],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.189, -33.623],
                                  [-13.832, -110.903],
                                  [44.379, -51.391],
                                  [-110.147, -36.929],
                                  [-16.851, -59.136],
                                  [-133.134, 11.648],
                                  [106.384, 74.754],
                                  [-34.149, 124.069],
                                  [-12.135, 92],
                                ],
                                o: [
                                  [-93.108, 36.839],
                                  [13.832, 110.903],
                                  [-44.379, 51.391],
                                  [110.147, 36.929],
                                  [19.838, 62.811],
                                  [109.703, -6.594],
                                  [-106.384, -74.754],
                                  [29.785, -127.974],
                                  [12.135, -92],
                                ],
                                v: [
                                  [-98.184, -358.718],
                                  [-187.662, -188.405],
                                  [-241.891, 15.294],
                                  [-246.643, 163.064],
                                  [-21.994, 299.071],
                                  [105.604, 366.126],
                                  [265.601, 237.737],
                                  [73.775, -83.982],
                                  [263.362, -176.451],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.025, -33.951],
                                  [-14.228, -111.352],
                                  [44.658, -51.116],
                                  [-110.645, -36.877],
                                  [-16.872, -59.366],
                                  [-132.756, 11.476],
                                  [107.056, 74.919],
                                  [-34.433, 124.64],
                                  [-12.061, 92],
                                ],
                                o: [
                                  [-93.232, 37.11],
                                  [14.228, 111.352],
                                  [-44.658, 51.116],
                                  [110.645, 36.877],
                                  [19.805, 62.975],
                                  [109.744, -6.512],
                                  [-107.056, -74.919],
                                  [30.146, -128.475],
                                  [12.061, -92],
                                ],
                                v: [
                                  [-99.086, -358.942],
                                  [-187.665, -187.932],
                                  [-241.389, 16.551],
                                  [-246.889, 163.161],
                                  [-20.771, 299.238],
                                  [104.327, 365.948],
                                  [265.764, 238.148],
                                  [72.934, -84.303],
                                  [264.629, -176.283],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.863, -34.274],
                                  [-14.619, -111.793],
                                  [44.933, -50.845],
                                  [-111.135, -36.825],
                                  [-16.892, -59.592],
                                  [-132.384, 11.306],
                                  [107.718, 75.082],
                                  [-34.712, 125.202],
                                  [-11.988, 92],
                                ],
                                o: [
                                  [-93.353, 37.376],
                                  [14.619, 111.794],
                                  [-44.933, 50.845],
                                  [111.135, 36.825],
                                  [19.773, 63.137],
                                  [109.784, -6.431],
                                  [-107.718, -75.082],
                                  [30.502, -128.968],
                                  [11.988, -92],
                                ],
                                v: [
                                  [-100.031, -359.193],
                                  [-187.622, -187.467],
                                  [-240.945, 17.814],
                                  [-247.091, 163.241],
                                  [-19.564, 299.402],
                                  [103.042, 365.875],
                                  [265.979, 238.614],
                                  [72.106, -84.532],
                                  [265.872, -176.161],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.704, -34.593],
                                  [-15.002, -112.228],
                                  [45.204, -50.579],
                                  [-111.617, -36.774],
                                  [-16.912, -59.815],
                                  [-132.018, 11.139],
                                  [108.369, 75.242],
                                  [-34.986, 125.755],
                                  [-11.917, 92],
                                ],
                                o: [
                                  [-93.472, 37.639],
                                  [15.002, 112.228],
                                  [-45.204, 50.579],
                                  [111.617, 36.774],
                                  [19.741, 63.296],
                                  [109.824, -6.352],
                                  [-108.369, -75.242],
                                  [30.852, -129.454],
                                  [11.917, -92],
                                ],
                                v: [
                                  [-100.983, -359.439],
                                  [-187.521, -187.012],
                                  [-240.532, 19.133],
                                  [-247.282, 163.282],
                                  [-18.42, 299.55],
                                  [101.799, 365.762],
                                  [266.199, 239.073],
                                  [71.255, -84.78],
                                  [267.119, -176.037],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.547, -34.906],
                                  [-15.38, -112.656],
                                  [45.47, -50.316],
                                  [-112.092, -36.724],
                                  [-16.932, -60.034],
                                  [-131.658, 10.974],
                                  [109.011, 75.4],
                                  [-35.256, 126.299],
                                  [-11.846, 92],
                                ],
                                o: [
                                  [-93.59, 37.897],
                                  [15.38, 112.656],
                                  [-45.47, 50.316],
                                  [112.092, 36.724],
                                  [19.709, 63.453],
                                  [109.863, -6.274],
                                  [-109.011, -75.4],
                                  [31.197, -129.932],
                                  [11.846, -92],
                                ],
                                v: [
                                  [-101.965, -359.763],
                                  [-187.437, -186.542],
                                  [-240.163, 20.408],
                                  [-247.49, 163.285],
                                  [-17.354, 299.632],
                                  [100.513, 365.691],
                                  [266.459, 239.625],
                                  [70.43, -85.049],
                                  [268.404, -175.96],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.393, -35.214],
                                  [-15.752, -113.077],
                                  [45.732, -50.058],
                                  [-112.559, -36.675],
                                  [-16.951, -60.25],
                                  [-131.304, 10.813],
                                  [109.642, 75.555],
                                  [-35.522, 126.835],
                                  [-11.777, 92],
                                ],
                                o: [
                                  [-93.705, 38.152],
                                  [15.752, 113.077],
                                  [-45.732, 50.058],
                                  [112.559, 36.675],
                                  [19.679, 63.607],
                                  [109.902, -6.196],
                                  [-109.642, -75.555],
                                  [31.536, -130.402],
                                  [11.777, -92],
                                ],
                                v: [
                                  [-102.954, -360.144],
                                  [-187.294, -186.066],
                                  [-239.841, 21.689],
                                  [-247.652, 163.269],
                                  [-16.352, 299.713],
                                  [99.268, 365.627],
                                  [266.738, 240.185],
                                  [69.617, -85.228],
                                  [269.644, -175.918],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.241, -35.518],
                                  [-16.118, -113.491],
                                  [45.99, -49.804],
                                  [-113.019, -36.627],
                                  [-16.97, -60.462],
                                  [-130.955, 10.653],
                                  [110.263, 75.707],
                                  [-35.784, 127.362],
                                  [-11.708, 92],
                                ],
                                o: [
                                  [-93.819, 38.402],
                                  [16.118, 113.491],
                                  [-45.99, 49.804],
                                  [113.019, 36.627],
                                  [19.648, 63.759],
                                  [109.94, -6.121],
                                  [-110.263, -75.707],
                                  [31.869, -130.864],
                                  [11.709, -92],
                                ],
                                v: [
                                  [-103.975, -360.558],
                                  [-187.117, -185.61],
                                  [-239.516, 22.963],
                                  [-247.852, 163.263],
                                  [-15.428, 299.728],
                                  [98.03, 365.619],
                                  [267.121, 240.803],
                                  [68.83, -85.379],
                                  [270.91, -175.937],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.092, -35.816],
                                  [-16.478, -113.899],
                                  [46.244, -49.554],
                                  [-113.472, -36.579],
                                  [-16.989, -60.671],
                                  [-130.611, 10.496],
                                  [110.874, 75.858],
                                  [-36.042, 127.881],
                                  [-11.641, 92],
                                ],
                                o: [
                                  [-93.931, 38.648],
                                  [16.478, 113.899],
                                  [-46.244, 49.554],
                                  [113.472, 36.579],
                                  [19.618, 63.908],
                                  [109.977, -6.046],
                                  [-110.874, -75.858],
                                  [32.198, -131.32],
                                  [11.641, -92],
                                ],
                                v: [
                                  [-105.004, -360.956],
                                  [-186.879, -185.123],
                                  [-239.201, 24.255],
                                  [-248.019, 163.214],
                                  [-14.556, 299.691],
                                  [96.784, 365.618],
                                  [267.447, 241.455],
                                  [68.067, -85.554],
                                  [272.167, -175.944],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.945, -36.11],
                                  [-16.833, -114.3],
                                  [46.494, -49.308],
                                  [-113.917, -36.532],
                                  [-17.007, -60.877],
                                  [-130.273, 10.342],
                                  [111.475, 76.005],
                                  [-36.295, 128.391],
                                  [-11.575, 92],
                                ],
                                o: [
                                  [-94.041, 38.891],
                                  [16.833, 114.3],
                                  [-46.494, 49.308],
                                  [113.917, 36.532],
                                  [19.589, 64.055],
                                  [110.014, -5.972],
                                  [-111.476, -76.005],
                                  [32.521, -131.768],
                                  [11.575, -92],
                                ],
                                v: [
                                  [-106.078, -361.412],
                                  [-186.643, -184.642],
                                  [-238.921, 25.502],
                                  [-248.201, 163.148],
                                  [-13.812, 299.638],
                                  [95.543, 365.673],
                                  [267.829, 242.116],
                                  [67.266, -85.64],
                                  [273.377, -175.962],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.8, -36.399],
                                  [-17.182, -114.695],
                                  [46.739, -49.066],
                                  [-114.355, -36.486],
                                  [-17.025, -61.08],
                                  [-129.941, 10.19],
                                  [112.067, 76.151],
                                  [-36.544, 128.894],
                                  [-11.51, 92],
                                ],
                                o: [
                                  [-94.15, 39.129],
                                  [17.182, 114.695],
                                  [-46.739, 49.066],
                                  [114.355, 36.486],
                                  [19.56, 64.2],
                                  [110.05, -5.9],
                                  [-112.068, -76.151],
                                  [32.839, -132.209],
                                  [11.51, -92],
                                ],
                                v: [
                                  [-107.135, -361.853],
                                  [-186.359, -184.178],
                                  [-238.701, 26.753],
                                  [-248.373, 163.04],
                                  [-13.084, 299.533],
                                  [94.345, 365.734],
                                  [268.268, 242.787],
                                  [66.49, -85.752],
                                  [274.628, -176.068],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.658, -36.684],
                                  [-17.525, -115.084],
                                  [46.981, -48.827],
                                  [-114.787, -36.441],
                                  [-17.043, -61.279],
                                  [-129.613, 10.041],
                                  [112.65, 76.294],
                                  [-36.79, 129.388],
                                  [-11.446, 92],
                                ],
                                o: [
                                  [-94.256, 39.364],
                                  [17.525, 115.084],
                                  [-46.981, 48.827],
                                  [114.787, 36.441],
                                  [19.532, 64.342],
                                  [110.086, -5.829],
                                  [-112.65, -76.294],
                                  [33.152, -132.643],
                                  [11.446, -92],
                                ],
                                v: [
                                  [-108.2, -362.292],
                                  [-186.064, -183.669],
                                  [-238.429, 27.958],
                                  [-248.546, 162.937],
                                  [-12.423, 299.476],
                                  [93.141, 365.8],
                                  [268.663, 243.557],
                                  [65.737, -85.828],
                                  [275.869, -176.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.518, -36.964],
                                  [-17.863, -115.466],
                                  [47.219, -48.593],
                                  [-115.211, -36.396],
                                  [-17.06, -61.475],
                                  [-129.291, 9.894],
                                  [113.223, 76.435],
                                  [-37.031, 129.875],
                                  [-11.383, 92],
                                ],
                                o: [
                                  [-94.361, 39.595],
                                  [17.863, 115.466],
                                  [-47.219, 48.593],
                                  [115.211, 36.396],
                                  [19.504, 64.482],
                                  [110.121, -5.759],
                                  [-113.223, -76.435],
                                  [33.46, -133.07],
                                  [11.383, -92],
                                ],
                                v: [
                                  [-109.261, -362.716],
                                  [-185.731, -183.176],
                                  [-238.256, 29.116],
                                  [-248.734, 162.817],
                                  [-11.839, 299.303],
                                  [91.941, 365.922],
                                  [269.153, 244.275],
                                  [64.956, -85.88],
                                  [277.151, -176.294],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.38, -37.24],
                                  [-18.195, -115.843],
                                  [47.454, -48.362],
                                  [-115.629, -36.352],
                                  [-17.077, -61.668],
                                  [-128.975, 9.749],
                                  [113.787, 76.574],
                                  [-37.269, 130.354],
                                  [-11.321, 92],
                                ],
                                o: [
                                  [-94.465, 39.823],
                                  [18.195, 115.843],
                                  [-47.454, 48.362],
                                  [115.629, 36.352],
                                  [19.476, 64.62],
                                  [110.155, -5.69],
                                  [-113.787, -76.574],
                                  [33.764, -133.49],
                                  [11.321, -92],
                                ],
                                v: [
                                  [-110.305, -363.086],
                                  [-185.337, -182.647],
                                  [-238.042, 30.289],
                                  [-248.872, 162.653],
                                  [-11.362, 299.178],
                                  [90.746, 366.048],
                                  [269.599, 245.055],
                                  [64.249, -85.897],
                                  [278.373, -176.453],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.245, -37.511],
                                  [-18.522, -116.213],
                                  [47.684, -48.135],
                                  [-116.04, -36.309],
                                  [-17.094, -61.857],
                                  [-128.663, 9.607],
                                  [114.342, 76.71],
                                  [-37.503, 130.825],
                                  [-11.26, 92],
                                ],
                                o: [
                                  [-94.566, 40.046],
                                  [18.522, 116.213],
                                  [-47.684, 48.135],
                                  [116.04, 36.309],
                                  [19.449, 64.755],
                                  [110.189, -5.622],
                                  [-114.342, -76.71],
                                  [34.062, -133.904],
                                  [11.26, -92],
                                ],
                                v: [
                                  [-111.396, -363.505],
                                  [-184.944, -182.173],
                                  [-237.877, 31.452],
                                  [-249.012, 162.482],
                                  [-10.912, 299],
                                  [89.595, 366.229],
                                  [270.103, 245.833],
                                  [63.524, -85.893],
                                  [279.586, -176.612],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.111, -37.777],
                                  [-18.844, -116.577],
                                  [47.911, -47.911],
                                  [-116.444, -36.267],
                                  [-17.111, -62.044],
                                  [-128.356, 9.467],
                                  [114.888, 76.844],
                                  [-37.733, 131.288],
                                  [-11.2, 92],
                                ],
                                o: [
                                  [-94.666, 40.266],
                                  [18.844, 116.577],
                                  [-47.911, 47.911],
                                  [116.444, 36.267],
                                  [19.422, 64.889],
                                  [110.222, -5.556],
                                  [-114.888, -76.844],
                                  [34.355, -134.31],
                                  [11.2, -92],
                                ],
                                v: [
                                  [-112.444, -363.87],
                                  [-184.5, -181.661],
                                  [-237.75, 32.516],
                                  [-249.153, 162.305],
                                  [-10.53, 298.757],
                                  [88.5, 366.404],
                                  [270.614, 246.674],
                                  [62.769, -85.918],
                                  [280.791, -176.834],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.98, -38.04],
                                  [-19.161, -116.936],
                                  [48.134, -47.692],
                                  [-116.842, -36.225],
                                  [-17.127, -62.228],
                                  [-128.054, 9.329],
                                  [115.425, 76.976],
                                  [-37.959, 131.744],
                                  [-11.141, 92],
                                ],
                                o: [
                                  [-94.765, 40.483],
                                  [19.161, 116.936],
                                  [-48.134, 47.692],
                                  [116.842, 36.225],
                                  [19.396, 65.02],
                                  [110.255, -5.49],
                                  [-115.425, -76.976],
                                  [34.644, -134.711],
                                  [11.141, -92],
                                ],
                                v: [
                                  [-113.476, -364.284],
                                  [-184.056, -181.112],
                                  [-237.644, 33.592],
                                  [-249.243, 162.134],
                                  [-10.226, 298.562],
                                  [87.347, 366.582],
                                  [271.173, 247.514],
                                  [62.087, -85.912],
                                  [282.037, -177.098],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.851, -38.298],
                                  [-19.472, -117.288],
                                  [48.353, -47.475],
                                  [-117.233, -36.184],
                                  [-17.144, -62.409],
                                  [-127.757, 9.194],
                                  [115.954, 77.106],
                                  [-38.182, 132.193],
                                  [-11.083, 92],
                                ],
                                o: [
                                  [-94.862, 40.696],
                                  [19.472, 117.288],
                                  [-48.353, 47.475],
                                  [117.233, 36.184],
                                  [19.37, 65.149],
                                  [110.287, -5.425],
                                  [-115.954, -77.106],
                                  [34.928, -135.104],
                                  [11.083, -92],
                                ],
                                v: [
                                  [-114.503, -364.633],
                                  [-183.561, -180.575],
                                  [-237.526, 34.659],
                                  [-249.376, 161.905],
                                  [-9.928, 298.314],
                                  [86.302, 366.816],
                                  [271.687, 248.366],
                                  [61.373, -85.834],
                                  [283.224, -177.374],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.724, -38.552],
                                  [-19.778, -117.635],
                                  [48.569, -47.263],
                                  [-117.618, -36.143],
                                  [-17.16, -62.586],
                                  [-127.465, 9.06],
                                  [116.474, 77.234],
                                  [-38.401, 132.634],
                                  [-11.026, 92],
                                ],
                                o: [
                                  [-94.957, 40.905],
                                  [19.778, 117.635],
                                  [-48.569, 47.263],
                                  [117.618, 36.143],
                                  [19.345, 65.276],
                                  [110.319, -5.362],
                                  [-116.474, -77.234],
                                  [35.207, -135.492],
                                  [11.026, -92],
                                ],
                                v: [
                                  [-115.517, -364.98],
                                  [-183.065, -180.039],
                                  [-237.459, 35.686],
                                  [-249.447, 161.659],
                                  [-9.759, 298.052],
                                  [85.198, 367.043],
                                  [272.26, 249.218],
                                  [60.679, -85.777],
                                  [284.464, -177.651],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.599, -38.802],
                                  [-20.08, -117.976],
                                  [48.782, -47.053],
                                  [-117.997, -36.104],
                                  [-17.175, -62.761],
                                  [-127.178, 8.929],
                                  [116.985, 77.36],
                                  [-38.617, 133.068],
                                  [-10.97, 92],
                                ],
                                o: [
                                  [-95.051, 41.112],
                                  [20.08, 117.976],
                                  [-48.782, 47.053],
                                  [117.997, 36.103],
                                  [19.32, 65.401],
                                  [110.35, -5.3],
                                  [-116.985, -77.36],
                                  [35.482, -135.873],
                                  [10.97, -92],
                                ],
                                v: [
                                  [-116.537, -365.324],
                                  [-182.559, -179.515],
                                  [-237.401, 36.662],
                                  [-249.52, 161.418],
                                  [-9.607, 297.788],
                                  [84.14, 367.315],
                                  [272.831, 250.071],
                                  [60.014, -85.753],
                                  [285.633, -177.98],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.476, -39.048],
                                  [-20.376, -118.312],
                                  [48.991, -46.848],
                                  [-118.369, -36.064],
                                  [-17.19, -62.933],
                                  [-126.895, 8.8],
                                  [117.488, 77.483],
                                  [-38.829, 133.495],
                                  [-10.914, 92],
                                ],
                                o: [
                                  [-95.143, 41.314],
                                  [20.376, 118.312],
                                  [-48.99, 46.848],
                                  [118.369, 36.064],
                                  [19.295, 65.524],
                                  [110.381, -5.238],
                                  [-117.488, -77.483],
                                  [35.752, -136.248],
                                  [10.914, -92],
                                ],
                                v: [
                                  [-117.49, -365.655],
                                  [-181.948, -178.982],
                                  [-237.395, 37.638],
                                  [-249.574, 161.16],
                                  [-9.533, 297.522],
                                  [83.139, 367.59],
                                  [273.408, 250.934],
                                  [59.368, -85.659],
                                  [286.846, -178.364],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.355, -39.289],
                                  [-20.668, -118.642],
                                  [49.196, -46.645],
                                  [-118.736, -36.026],
                                  [-17.206, -63.103],
                                  [-126.617, 8.673],
                                  [117.983, 77.605],
                                  [-39.037, 133.915],
                                  [-10.86, 92],
                                ],
                                o: [
                                  [-95.234, 41.514],
                                  [20.668, 118.642],
                                  [-49.196, 46.645],
                                  [118.736, 36.026],
                                  [19.271, 65.645],
                                  [110.411, -5.178],
                                  [-117.983, -77.605],
                                  [36.018, -136.616],
                                  [10.86, -92],
                                ],
                                v: [
                                  [-118.502, -365.995],
                                  [-181.399, -178.407],
                                  [-237.387, 38.508],
                                  [-249.568, 160.897],
                                  [-9.516, 297.201],
                                  [82.133, 367.87],
                                  [273.993, 251.862],
                                  [58.688, -85.537],
                                  [288.061, -178.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.236, -39.527],
                                  [-20.955, -118.967],
                                  [49.398, -46.446],
                                  [-119.096, -35.988],
                                  [-17.22, -63.269],
                                  [-126.344, 8.548],
                                  [118.47, 77.725],
                                  [-39.242, 134.329],
                                  [-10.806, 92],
                                ],
                                o: [
                                  [-95.323, 41.71],
                                  [20.955, 118.967],
                                  [-49.398, 46.446],
                                  [119.096, 35.988],
                                  [19.247, 65.764],
                                  [110.441, -5.118],
                                  [-118.47, -77.725],
                                  [36.28, -136.979],
                                  [10.806, -92],
                                ],
                                v: [
                                  [-119.447, -366.322],
                                  [-180.787, -177.842],
                                  [-237.389, 39.431],
                                  [-249.564, 160.586],
                                  [-9.579, 296.931],
                                  [81.183, 368.195],
                                  [274.629, 252.737],
                                  [58.079, -85.44],
                                  [289.204, -179.144],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.119, -39.761],
                                  [-21.237, -119.286],
                                  [49.597, -46.25],
                                  [-119.451, -35.951],
                                  [-17.235, -63.433],
                                  [-126.075, 8.425],
                                  [118.949, 77.842],
                                  [-39.444, 134.735],
                                  [-10.754, 92],
                                ],
                                o: [
                                  [-95.41, 41.903],
                                  [21.237, 119.286],
                                  [-49.597, 46.25],
                                  [119.451, 35.951],
                                  [19.224, 65.881],
                                  [110.47, -5.06],
                                  [-118.949, -77.842],
                                  [36.537, -137.336],
                                  [10.754, -92],
                                ],
                                v: [
                                  [-120.388, -366.657],
                                  [-180.175, -177.269],
                                  [-237.433, 40.248],
                                  [-249.553, 160.312],
                                  [-9.657, 296.605],
                                  [80.228, 368.513],
                                  [275.219, 253.623],
                                  [57.444, -85.326],
                                  [290.393, -179.594],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.004, -39.991],
                                  [-21.514, -119.601],
                                  [49.793, -46.057],
                                  [-119.799, -35.914],
                                  [-17.249, -63.594],
                                  [-125.81, 8.305],
                                  [119.42, 77.958],
                                  [-39.642, 135.135],
                                  [-10.702, 92],
                                ],
                                o: [
                                  [-95.497, 42.093],
                                  [21.514, 119.601],
                                  [-49.793, 46.057],
                                  [119.799, 35.914],
                                  [19.201, 65.996],
                                  [110.499, -5.002],
                                  [-119.42, -77.958],
                                  [36.79, -137.687],
                                  [10.702, -92],
                                ],
                                v: [
                                  [-121.263, -366.98],
                                  [-179.509, -176.696],
                                  [-237.486, 41.063],
                                  [-249.544, 159.98],
                                  [-9.812, 296.321],
                                  [79.321, 368.879],
                                  [275.86, 254.511],
                                  [56.773, -85.197],
                                  [291.529, -180.045],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.891, -40.218],
                                  [-21.787, -119.91],
                                  [49.985, -45.868],
                                  [-120.142, -35.878],
                                  [-17.264, -63.752],
                                  [-125.55, 8.186],
                                  [119.883, 78.072],
                                  [-39.838, 135.528],
                                  [-10.651, 92],
                                ],
                                o: [
                                  [-95.582, 42.279],
                                  [21.787, 119.91],
                                  [-49.985, 45.868],
                                  [120.142, 35.878],
                                  [19.178, 66.109],
                                  [110.527, -4.946],
                                  [-119.883, -78.072],
                                  [37.039, -138.032],
                                  [10.651, -92],
                                ],
                                v: [
                                  [-122.196, -367.247],
                                  [-178.85, -176.079],
                                  [-237.592, 41.833],
                                  [-249.529, 159.695],
                                  [-9.974, 295.982],
                                  [78.472, 369.247],
                                  [276.465, 255.408],
                                  [56.171, -85.042],
                                  [292.702, -180.55],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.78, -40.44],
                                  [-22.056, -120.213],
                                  [50.174, -45.681],
                                  [-120.479, -35.842],
                                  [-17.277, -63.908],
                                  [-125.294, 8.069],
                                  [120.338, 78.184],
                                  [-40.03, 135.915],
                                  [-10.601, 92],
                                ],
                                o: [
                                  [-95.665, 42.463],
                                  [22.056, 120.213],
                                  [-50.174, 45.681],
                                  [120.479, 35.842],
                                  [19.156, 66.22],
                                  [110.555, -4.89],
                                  [-120.338, -78.184],
                                  [37.284, -138.371],
                                  [10.601, -92],
                                ],
                                v: [
                                  [-123.063, -367.575],
                                  [-178.182, -175.516],
                                  [-237.697, 42.539],
                                  [-249.453, 159.344],
                                  [-10.266, 295.649],
                                  [77.618, 369.61],
                                  [277.113, 256.307],
                                  [55.532, -84.863],
                                  [293.867, -181.066],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.671, -40.659],
                                  [-22.32, -120.512],
                                  [50.36, -45.498],
                                  [-120.811, -35.807],
                                  [-17.291, -64.061],
                                  [-125.042, 7.954],
                                  [120.786, 78.294],
                                  [-40.218, 136.295],
                                  [-10.552, 92],
                                ],
                                o: [
                                  [-95.747, 42.644],
                                  [22.32, 120.512],
                                  [-50.36, 45.498],
                                  [120.811, 35.807],
                                  [19.134, 66.329],
                                  [110.582, -4.835],
                                  [-120.786, -78.294],
                                  [37.525, -138.705],
                                  [10.552, -92],
                                ],
                                v: [
                                  [-123.872, -367.837],
                                  [-177.513, -174.9],
                                  [-237.856, 43.252],
                                  [-249.434, 159.05],
                                  [-10.52, 295.305],
                                  [76.822, 370.021],
                                  [277.714, 257.217],
                                  [54.971, -84.724],
                                  [294.97, -181.636],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.563, -40.874],
                                  [-22.579, -120.806],
                                  [50.543, -45.318],
                                  [-121.137, -35.773],
                                  [-17.305, -64.212],
                                  [-124.795, 7.841],
                                  [121.227, 78.402],
                                  [-40.404, 136.669],
                                  [-10.503, 92],
                                ],
                                o: [
                                  [-95.828, 42.821],
                                  [22.579, 120.806],
                                  [-50.543, 45.318],
                                  [121.137, 35.773],
                                  [19.113, 66.437],
                                  [110.609, -4.781],
                                  [-121.227, -78.402],
                                  [37.762, -139.033],
                                  [10.503, -92],
                                ],
                                v: [
                                  [-124.731, -368.098],
                                  [-176.797, -174.283],
                                  [-237.97, 43.908],
                                  [-249.301, 158.689],
                                  [-10.897, 295.022],
                                  [76.076, 370.435],
                                  [278.324, 258.082],
                                  [54.363, -84.57],
                                  [296.119, -182.216],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.457, -41.086],
                                  [-22.835, -121.095],
                                  [50.723, -45.141],
                                  [-121.458, -35.74],
                                  [-17.318, -64.36],
                                  [-124.551, 7.73],
                                  [121.66, 78.509],
                                  [-40.586, 137.037],
                                  [-10.456, 92],
                                ],
                                o: [
                                  [-95.907, 42.996],
                                  [22.835, 121.095],
                                  [-50.723, 45.141],
                                  [121.458, 35.739],
                                  [19.091, 66.543],
                                  [110.636, -4.729],
                                  [-121.66, -78.509],
                                  [37.994, -139.356],
                                  [10.456, -92],
                                ],
                                v: [
                                  [-125.478, -368.364],
                                  [-176.08, -173.667],
                                  [-238.191, 44.508],
                                  [-249.225, 158.377],
                                  [-11.242, 294.675],
                                  [75.326, 370.835],
                                  [278.986, 258.949],
                                  [53.733, -84.394],
                                  [297.262, -182.842],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.353, -41.294],
                                  [-23.086, -121.379],
                                  [50.9, -44.966],
                                  [-121.773, -35.706],
                                  [-17.331, -64.506],
                                  [-124.312, 7.621],
                                  [122.086, 78.613],
                                  [-40.766, 137.398],
                                  [-10.409, 92],
                                ],
                                o: [
                                  [-95.985, 43.167],
                                  [23.086, 121.379],
                                  [-50.9, 44.966],
                                  [121.774, 35.706],
                                  [19.071, 66.647],
                                  [110.662, -4.677],
                                  [-122.086, -78.613],
                                  [38.223, -139.673],
                                  [10.409, -92],
                                ],
                                v: [
                                  [-126.266, -368.619],
                                  [-175.361, -173.051],
                                  [-238.359, 45.105],
                                  [-249.151, 158.061],
                                  [-11.702, 294.333],
                                  [74.634, 371.292],
                                  [279.602, 259.825],
                                  [53.163, -84.196],
                                  [298.342, -183.487],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.251, -41.498],
                                  [-23.333, -121.659],
                                  [51.074, -44.795],
                                  [-122.084, -35.674],
                                  [-17.344, -64.649],
                                  [-124.077, 7.513],
                                  [122.505, 78.716],
                                  [-40.942, 137.754],
                                  [-10.363, 92],
                                ],
                                o: [
                                  [-96.062, 43.336],
                                  [23.333, 121.659],
                                  [-51.074, 44.795],
                                  [122.084, 35.674],
                                  [19.05, 66.749],
                                  [110.687, -4.625],
                                  [-122.505, -78.716],
                                  [38.448, -139.985],
                                  [10.363, -92],
                                ],
                                v: [
                                  [-126.996, -368.871],
                                  [-174.641, -172.443],
                                  [-238.58, 45.645],
                                  [-249.018, 157.733],
                                  [-12.131, 293.982],
                                  [73.993, 371.744],
                                  [280.216, 260.711],
                                  [52.607, -83.986],
                                  [299.469, -184.178],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.15, -41.7],
                                  [-23.575, -121.934],
                                  [51.245, -44.627],
                                  [-122.389, -35.642],
                                  [-17.356, -64.79],
                                  [-123.845, 7.408],
                                  [122.917, 78.818],
                                  [-41.116, 138.103],
                                  [-10.318, 92],
                                ],
                                o: [
                                  [-96.137, 43.502],
                                  [23.575, 121.934],
                                  [-51.245, 44.627],
                                  [122.389, 35.642],
                                  [19.03, 66.85],
                                  [110.712, -4.575],
                                  [-122.917, -78.818],
                                  [38.67, -140.292],
                                  [10.318, -92],
                                ],
                                v: [
                                  [-127.721, -369.129],
                                  [-173.919, -171.835],
                                  [-238.856, 46.189],
                                  [-248.887, 157.41],
                                  [-12.682, 293.69],
                                  [73.349, 372.246],
                                  [280.893, 261.543],
                                  [52.018, -83.818],
                                  [300.581, -184.878],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.051, -41.897],
                                  [-23.814, -122.204],
                                  [51.413, -44.461],
                                  [-122.688, -35.61],
                                  [-17.369, -64.928],
                                  [-123.618, 7.304],
                                  [123.321, 78.917],
                                  [-41.287, 138.447],
                                  [-10.273, 92],
                                ],
                                o: [
                                  [-96.212, 43.665],
                                  [23.814, 122.204],
                                  [-51.413, 44.461],
                                  [122.688, 35.61],
                                  [19.01, 66.949],
                                  [110.737, -4.526],
                                  [-123.321, -78.917],
                                  [38.887, -140.594],
                                  [10.273, -92],
                                ],
                                v: [
                                  [-128.388, -369.385],
                                  [-173.204, -171.219],
                                  [-239.086, 46.667],
                                  [-248.759, 157.075],
                                  [-13.2, 293.342],
                                  [72.819, 372.742],
                                  [281.523, 262.384],
                                  [51.443, -83.63],
                                  [301.64, -185.587],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.954, -42.092],
                                  [-24.048, -122.469],
                                  [51.578, -44.298],
                                  [-122.983, -35.579],
                                  [-17.381, -65.064],
                                  [-123.394, 7.202],
                                  [123.719, 79.015],
                                  [-41.454, 138.785],
                                  [-10.229, 92],
                                ],
                                o: [
                                  [-96.284, 43.826],
                                  [24.048, 122.469],
                                  [-51.578, 44.298],
                                  [122.983, 35.579],
                                  [18.991, 67.046],
                                  [110.762, -4.477],
                                  [-123.719, -79.015],
                                  [39.101, -140.89],
                                  [10.229, -92],
                                ],
                                v: [
                                  [-128.996, -369.583],
                                  [-172.487, -170.611],
                                  [-239.362, 47.095],
                                  [-248.625, 156.783],
                                  [-13.779, 292.992],
                                  [72.286, 373.227],
                                  [282.153, 263.18],
                                  [50.889, -83.423],
                                  [302.738, -186.407],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.858, -42.283],
                                  [-24.279, -122.73],
                                  [51.741, -44.138],
                                  [-123.273, -35.549],
                                  [-17.393, -65.198],
                                  [-123.174, 7.101],
                                  [124.111, 79.111],
                                  [-41.619, 139.117],
                                  [-10.186, 92],
                                ],
                                o: [
                                  [-96.356, 43.984],
                                  [24.279, 122.73],
                                  [-51.741, 44.138],
                                  [123.273, 35.549],
                                  [18.972, 67.142],
                                  [110.785, -4.429],
                                  [-124.111, -79.111],
                                  [39.311, -141.182],
                                  [10.186, -92],
                                ],
                                v: [
                                  [-129.646, -369.832],
                                  [-171.713, -170.003],
                                  [-239.701, 47.511],
                                  [-248.502, 156.441],
                                  [-14.434, 292.695],
                                  [71.805, 373.715],
                                  [282.727, 263.983],
                                  [50.347, -83.205],
                                  [303.837, -187.172],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.764, -42.471],
                                  [-24.506, -122.987],
                                  [51.9, -43.98],
                                  [-123.558, -35.519],
                                  [-17.404, -65.33],
                                  [-122.958, 7.003],
                                  [124.496, 79.206],
                                  [-41.781, 139.444],
                                  [-10.144, 92],
                                ],
                                o: [
                                  [-96.427, 44.139],
                                  [24.506, 122.987],
                                  [-51.9, 43.98],
                                  [123.558, 35.519],
                                  [18.953, 67.236],
                                  [110.809, -4.382],
                                  [-124.496, -79.206],
                                  [39.518, -141.468],
                                  [10.144, -92],
                                ],
                                v: [
                                  [-130.182, -370.024],
                                  [-170.999, -169.34],
                                  [-239.986, 47.876],
                                  [-248.318, 156.199],
                                  [-15.047, 292.348],
                                  [71.377, 374.254],
                                  [283.364, 264.794],
                                  [49.817, -82.976],
                                  [304.867, -188],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.672, -42.656],
                                  [-24.729, -123.239],
                                  [52.057, -43.826],
                                  [-123.838, -35.489],
                                  [-17.416, -65.459],
                                  [-122.746, 6.906],
                                  [124.874, 79.299],
                                  [-41.941, 139.765],
                                  [-10.102, 92],
                                ],
                                o: [
                                  [-96.496, 44.291],
                                  [24.729, 123.239],
                                  [-52.057, 43.826],
                                  [123.838, 35.489],
                                  [18.934, 67.328],
                                  [110.832, -4.336],
                                  [-124.874, -79.299],
                                  [39.721, -141.75],
                                  [10.102, -92],
                                ],
                                v: [
                                  [-130.714, -370.213],
                                  [-170.276, -168.787],
                                  [-240.326, 48.237],
                                  [-248.137, 155.899],
                                  [-15.729, 292.054],
                                  [70.954, 374.789],
                                  [284.008, 265.55],
                                  [49.244, -82.785],
                                  [305.945, -188.884],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.581, -42.837],
                                  [-24.948, -123.487],
                                  [52.212, -43.674],
                                  [-124.113, -35.46],
                                  [-17.427, -65.586],
                                  [-122.537, 6.81],
                                  [125.245, 79.39],
                                  [-42.097, 140.08],
                                  [-10.062, 92],
                                ],
                                o: [
                                  [-96.564, 44.441],
                                  [24.948, 123.487],
                                  [-52.212, 43.674],
                                  [124.113, 35.46],
                                  [18.916, 67.419],
                                  [110.855, -4.291],
                                  [-125.245, -79.39],
                                  [39.921, -142.027],
                                  [10.062, -92],
                                ],
                                v: [
                                  [-131.241, -370.406],
                                  [-169.559, -168.186],
                                  [-240.667, 48.538],
                                  [-248.014, 155.645],
                                  [-16.485, 291.703],
                                  [70.64, 375.369],
                                  [284.597, 266.259],
                                  [48.746, -82.584],
                                  [306.954, -189.831],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.492, -43.016],
                                  [-25.163, -123.731],
                                  [52.364, -43.524],
                                  [-124.384, -35.432],
                                  [-17.439, -65.711],
                                  [-122.332, 6.717],
                                  [125.611, 79.48],
                                  [-42.251, 140.39],
                                  [-10.021, 92],
                                ],
                                o: [
                                  [-96.631, 44.588],
                                  [25.163, 123.731],
                                  [-52.364, 43.524],
                                  [124.384, 35.432],
                                  [18.898, 67.508],
                                  [110.877, -4.246],
                                  [-125.611, -79.48],
                                  [40.118, -142.299],
                                  [10.021, -92],
                                ],
                                v: [
                                  [-131.701, -370.596],
                                  [-168.847, -167.579],
                                  [-241.064, 48.834],
                                  [-247.844, 155.389],
                                  [-17.192, 291.404],
                                  [70.332, 375.897],
                                  [285.186, 267.03],
                                  [48.203, -82.367],
                                  [308.02, -190.731],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.404, -43.191],
                                  [-25.375, -123.971],
                                  [52.513, -43.377],
                                  [-124.65, -35.404],
                                  [-17.449, -65.834],
                                  [-122.13, 6.625],
                                  [125.97, 79.568],
                                  [-42.403, 140.695],
                                  [-9.982, 92],
                                ],
                                o: [
                                  [-96.697, 44.733],
                                  [25.375, 123.971],
                                  [-52.513, 43.377],
                                  [124.65, 35.404],
                                  [18.881, 67.596],
                                  [110.899, -4.202],
                                  [-125.97, -79.568],
                                  [40.311, -142.567],
                                  [9.982, -92],
                                ],
                                v: [
                                  [-132.156, -370.727],
                                  [-168.188, -166.971],
                                  [-241.412, 49.077],
                                  [-247.614, 155.131],
                                  [-17.974, 291.055],
                                  [70.132, 376.47],
                                  [285.782, 267.698],
                                  [47.671, -82.196],
                                  [309.016, -191.686],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.318, -43.364],
                                  [-25.583, -124.206],
                                  [52.659, -43.233],
                                  [-124.911, -35.376],
                                  [-17.46, -65.955],
                                  [-121.931, 6.534],
                                  [126.323, 79.655],
                                  [-42.551, 140.995],
                                  [-9.943, 92],
                                ],
                                o: [
                                  [-96.761, 44.875],
                                  [25.583, 124.206],
                                  [-52.659, 43.233],
                                  [124.911, 35.376],
                                  [18.864, 67.682],
                                  [110.92, -4.159],
                                  [-126.323, -79.655],
                                  [40.5, -142.83],
                                  [9.943, -92],
                                ],
                                v: [
                                  [-132.551, -370.917],
                                  [-167.472, -166.426],
                                  [-241.81, 49.364],
                                  [-247.441, 154.927],
                                  [-18.768, 290.759],
                                  [69.925, 377.039],
                                  [286.385, 268.378],
                                  [47.157, -82.009],
                                  [310.007, -192.704],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.233, -43.533],
                                  [-25.787, -124.438],
                                  [52.803, -43.091],
                                  [-125.168, -35.349],
                                  [-17.471, -66.073],
                                  [-121.736, 6.445],
                                  [126.67, 79.74],
                                  [-42.698, 141.289],
                                  [-9.905, 92],
                                ],
                                o: [
                                  [-96.825, 45.015],
                                  [25.787, 124.438],
                                  [-52.803, 43.091],
                                  [125.168, 35.349],
                                  [18.847, 67.767],
                                  [110.942, -4.117],
                                  [-126.67, -79.74],
                                  [40.687, -143.089],
                                  [9.905, -92],
                                ],
                                v: [
                                  [-132.886, -371.047],
                                  [-166.816, -165.825],
                                  [-242.215, 49.542],
                                  [-247.277, 154.714],
                                  [-19.573, 290.467],
                                  [69.786, 377.662],
                                  [286.932, 269.058],
                                  [46.653, -81.815],
                                  [310.935, -193.722],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.15, -43.7],
                                  [-25.988, -124.665],
                                  [52.945, -42.951],
                                  [-125.42, -35.323],
                                  [-17.481, -66.19],
                                  [-121.545, 6.357],
                                  [127.011, 79.824],
                                  [-42.841, 141.579],
                                  [-9.867, 92],
                                ],
                                o: [
                                  [-96.888, 45.153],
                                  [25.988, 124.665],
                                  [-52.945, 42.951],
                                  [125.42, 35.323],
                                  [18.83, 67.85],
                                  [110.962, -4.075],
                                  [-127.011, -79.824],
                                  [40.87, -143.343],
                                  [9.867, -92],
                                ],
                                v: [
                                  [-133.216, -371.174],
                                  [-166.151, -165.28],
                                  [-242.613, 49.764],
                                  [-247.053, 154.557],
                                  [-20.453, 290.173],
                                  [69.696, 378.219],
                                  [287.48, 269.743],
                                  [46.159, -81.612],
                                  [311.857, -194.747],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.068, -43.864],
                                  [-26.186, -124.889],
                                  [53.084, -42.814],
                                  [-125.668, -35.297],
                                  [-17.491, -66.305],
                                  [-121.357, 6.271],
                                  [127.346, 79.906],
                                  [-42.982, 141.863],
                                  [-9.831, 92],
                                ],
                                o: [
                                  [-96.949, 45.288],
                                  [26.186, 124.889],
                                  [-53.084, 42.814],
                                  [125.669, 35.297],
                                  [18.814, 67.932],
                                  [110.983, -4.034],
                                  [-127.346, -79.906],
                                  [41.05, -143.592],
                                  [9.831, -92],
                                ],
                                v: [
                                  [-133.478, -371.304],
                                  [-165.554, -164.736],
                                  [-243.019, 49.932],
                                  [-246.836, 154.392],
                                  [-21.282, 289.82],
                                  [69.724, 378.83],
                                  [288.035, 270.379],
                                  [45.682, -81.401],
                                  [312.773, -195.771],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.988, -44.025],
                                  [-26.38, -125.109],
                                  [53.221, -42.679],
                                  [-125.912, -35.271],
                                  [-17.502, -66.417],
                                  [-121.172, 6.187],
                                  [127.675, 79.987],
                                  [-43.121, 142.143],
                                  [-9.794, 92],
                                ],
                                o: [
                                  [-97.009, 45.42],
                                  [26.38, 125.109],
                                  [-53.221, 42.679],
                                  [125.912, 35.271],
                                  [18.798, 68.012],
                                  [111.003, -3.994],
                                  [-127.675, -79.987],
                                  [41.227, -143.838],
                                  [9.794, -92],
                                ],
                                v: [
                                  [-133.742, -371.374],
                                  [-164.954, -164.192],
                                  [-243.482, 50.151],
                                  [-246.677, 154.289],
                                  [-22.185, 289.527],
                                  [69.751, 379.439],
                                  [288.54, 271.02],
                                  [45.215, -81.232],
                                  [313.62, -196.858],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.909, -44.183],
                                  [-26.57, -125.325],
                                  [53.355, -42.547],
                                  [-126.152, -35.246],
                                  [-17.511, -66.528],
                                  [-120.99, 6.104],
                                  [127.999, 80.067],
                                  [-43.258, 142.418],
                                  [-9.759, 92],
                                ],
                                o: [
                                  [-97.069, 45.551],
                                  [26.57, 125.325],
                                  [-53.355, 42.547],
                                  [126.152, 35.246],
                                  [18.782, 68.091],
                                  [111.023, -3.954],
                                  [-127.999, -80.067],
                                  [41.401, -144.079],
                                  [9.759, -92],
                                ],
                                v: [
                                  [-133.939, -371.495],
                                  [-164.353, -163.704],
                                  [-243.889, 50.31],
                                  [-246.463, 154.179],
                                  [-23.036, 289.232],
                                  [69.835, 380.039],
                                  [289.045, 271.667],
                                  [44.756, -81.057],
                                  [314.46, -197.951],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.831, -44.338],
                                  [-26.758, -125.537],
                                  [53.487, -42.417],
                                  [-126.387, -35.221],
                                  [-17.521, -66.637],
                                  [-120.811, 6.023],
                                  [128.317, 80.145],
                                  [-43.392, 142.687],
                                  [-9.724, 92],
                                ],
                                o: [
                                  [-97.127, 45.679],
                                  [26.758, 125.537],
                                  [-53.487, 42.417],
                                  [126.387, 35.221],
                                  [18.766, 68.169],
                                  [111.042, -3.915],
                                  [-128.317, -80.145],
                                  [41.572, -144.316],
                                  [9.724, -92],
                                ],
                                v: [
                                  [-134.068, -371.563],
                                  [-163.75, -163.161],
                                  [-244.352, 50.407],
                                  [-246.256, 154.063],
                                  [-23.96, 288.939],
                                  [69.975, 380.694],
                                  [289.557, 272.262],
                                  [44.313, -80.924],
                                  [315.295, -199.043],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.755, -44.491],
                                  [-26.942, -125.745],
                                  [53.617, -42.289],
                                  [-126.619, -35.197],
                                  [-17.531, -66.743],
                                  [-120.636, 5.942],
                                  [128.629, 80.222],
                                  [-43.523, 142.953],
                                  [-9.69, 92],
                                ],
                                o: [
                                  [-97.184, 45.805],
                                  [26.942, 125.745],
                                  [-53.617, 42.289],
                                  [126.619, 35.197],
                                  [18.751, 68.245],
                                  [111.061, -3.877],
                                  [-128.629, -80.222],
                                  [41.74, -144.548],
                                  [9.69, -92],
                                ],
                                v: [
                                  [-134.198, -371.632],
                                  [-163.207, -162.68],
                                  [-244.76, 50.442],
                                  [-246.05, 154.06],
                                  [-24.895, 288.594],
                                  [70.178, 381.29],
                                  [290.012, 272.868],
                                  [43.88, -80.791],
                                  [316.061, -200.141],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.68, -44.641],
                                  [-27.123, -125.95],
                                  [53.745, -42.163],
                                  [-126.846, -35.173],
                                  [-17.54, -66.848],
                                  [-120.463, 5.864],
                                  [128.936, 80.297],
                                  [-43.653, 143.213],
                                  [-9.656, 92],
                                ],
                                o: [
                                  [-97.24, 45.929],
                                  [27.123, 125.95],
                                  [-53.745, 42.163],
                                  [126.846, 35.173],
                                  [18.736, 68.32],
                                  [111.08, -3.84],
                                  [-128.936, -80.297],
                                  [41.905, -144.777],
                                  [9.656, -92],
                                ],
                                v: [
                                  [-134.26, -371.697],
                                  [-162.725, -162.251],
                                  [-245.168, 50.472],
                                  [-245.789, 154.001],
                                  [-25.902, 288.301],
                                  [70.438, 381.935],
                                  [290.474, 273.478],
                                  [43.403, -80.645],
                                  [316.828, -201.3],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.606, -44.788],
                                  [-27.3, -126.151],
                                  [53.87, -42.04],
                                  [-127.069, -35.149],
                                  [-17.549, -66.952],
                                  [-120.294, 5.786],
                                  [129.238, 80.371],
                                  [-43.78, 143.469],
                                  [-9.623, 92],
                                ],
                                o: [
                                  [-97.295, 46.05],
                                  [27.301, 126.151],
                                  [-53.87, 42.04],
                                  [127.069, 35.149],
                                  [18.721, 68.394],
                                  [111.099, -3.803],
                                  [-129.238, -80.371],
                                  [42.067, -145.002],
                                  [9.623, -92],
                                ],
                                v: [
                                  [-134.324, -371.701],
                                  [-162.236, -161.772],
                                  [-245.577, 50.441],
                                  [-245.585, 154.055],
                                  [-26.857, 288.006],
                                  [70.75, 382.573],
                                  [290.936, 274.03],
                                  [42.992, -80.493],
                                  [317.525, -202.408],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.534, -44.933],
                                  [-27.475, -126.349],
                                  [53.993, -41.919],
                                  [-127.289, -35.126],
                                  [-17.558, -67.053],
                                  [-120.127, 5.71],
                                  [129.534, 80.444],
                                  [-43.904, 143.721],
                                  [-9.59, 92],
                                ],
                                o: [
                                  [-97.35, 46.169],
                                  [27.475, 126.349],
                                  [-53.993, 41.919],
                                  [127.289, 35.126],
                                  [18.707, 68.466],
                                  [111.117, -3.767],
                                  [-129.534, -80.444],
                                  [42.226, -145.222],
                                  [9.59, -92],
                                ],
                                v: [
                                  [-134.319, -371.706],
                                  [-161.813, -161.35],
                                  [-245.985, 50.409],
                                  [-245.393, 154.104],
                                  [-27.822, 287.713],
                                  [71.131, 383.21],
                                  [291.347, 274.592],
                                  [42.595, -80.392],
                                  [318.217, -203.57],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.463, -45.075],
                                  [-27.647, -126.543],
                                  [54.114, -41.8],
                                  [-127.504, -35.104],
                                  [-17.567, -67.152],
                                  [-119.964, 5.636],
                                  [129.825, 80.516],
                                  [-44.027, 143.968],
                                  [-9.558, 92],
                                ],
                                o: [
                                  [-97.403, 46.287],
                                  [27.647, 126.543],
                                  [-54.114, 41.8],
                                  [127.504, 35.104],
                                  [18.693, 68.537],
                                  [111.134, -3.731],
                                  [-129.825, -80.516],
                                  [42.382, -145.439],
                                  [9.558, -92],
                                ],
                                v: [
                                  [-134.247, -371.763],
                                  [-161.383, -160.923],
                                  [-246.395, 50.31],
                                  [-245.196, 154.211],
                                  [-28.803, 287.423],
                                  [71.621, 383.841],
                                  [291.759, 275.163],
                                  [42.206, -80.285],
                                  [318.897, -204.732],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.393, -45.215],
                                  [-27.815, -126.734],
                                  [54.232, -41.683],
                                  [-127.716, -35.081],
                                  [-17.576, -67.25],
                                  [-119.803, 5.562],
                                  [130.111, 80.586],
                                  [-44.148, 144.21],
                                  [-9.527, 92],
                                ],
                                o: [
                                  [-97.455, 46.402],
                                  [27.815, 126.734],
                                  [-54.232, 41.683],
                                  [127.716, 35.081],
                                  [18.679, 68.607],
                                  [111.152, -3.696],
                                  [-130.111, -80.586],
                                  [42.536, -145.652],
                                  [9.527, -92],
                                ],
                                v: [
                                  [-134.176, -371.765],
                                  [-161.014, -160.56],
                                  [-246.804, 50.148],
                                  [-244.948, 154.319],
                                  [-29.792, 287.135],
                                  [72.113, 384.47],
                                  [292.119, 275.733],
                                  [41.882, -80.172],
                                  [319.514, -205.903],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.324, -45.352],
                                  [-27.981, -126.921],
                                  [54.349, -41.568],
                                  [-127.924, -35.06],
                                  [-17.585, -67.346],
                                  [-119.645, 5.49],
                                  [130.392, 80.655],
                                  [-44.266, 144.449],
                                  [-9.496, 92],
                                ],
                                o: [
                                  [-97.507, 46.515],
                                  [27.981, 126.921],
                                  [-54.349, 41.568],
                                  [127.924, 35.06],
                                  [18.665, 68.676],
                                  [111.169, -3.662],
                                  [-130.392, -80.655],
                                  [42.687, -145.862],
                                  [9.496, -92],
                                ],
                                v: [
                                  [-134.042, -371.709],
                                  [-160.706, -160.202],
                                  [-247.157, 49.987],
                                  [-244.757, 154.481],
                                  [-30.786, 286.843],
                                  [72.611, 385.208],
                                  [292.48, 276.254],
                                  [41.513, -80.105],
                                  [320.126, -207.13],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.257, -45.487],
                                  [-28.143, -127.105],
                                  [54.464, -41.455],
                                  [-128.128, -35.038],
                                  [-17.593, -67.441],
                                  [-119.49, 5.42],
                                  [130.668, 80.723],
                                  [-44.382, 144.683],
                                  [-9.466, 92],
                                ],
                                o: [
                                  [-97.557, 46.626],
                                  [28.143, 127.105],
                                  [-54.464, 41.455],
                                  [128.128, 35.038],
                                  [18.651, 68.743],
                                  [111.186, -3.628],
                                  [-130.668, -80.723],
                                  [42.835, -146.067],
                                  [9.465, -92],
                                ],
                                v: [
                                  [-133.84, -371.706],
                                  [-160.396, -159.841],
                                  [-247.51, 49.762],
                                  [-244.577, 154.643],
                                  [-31.793, 286.554],
                                  [73.22, 385.998],
                                  [292.846, 276.783],
                                  [41.21, -80.039],
                                  [320.674, -208.304],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.191, -45.619],
                                  [-28.303, -127.286],
                                  [54.576, -41.344],
                                  [-128.329, -35.017],
                                  [-17.601, -67.533],
                                  [-119.338, 5.35],
                                  [130.939, 80.79],
                                  [-44.496, 144.913],
                                  [-9.436, 92],
                                ],
                                o: [
                                  [-97.607, 46.736],
                                  [28.303, 127.286],
                                  [-54.576, 41.344],
                                  [128.329, 35.017],
                                  [18.638, 68.81],
                                  [111.202, -3.595],
                                  [-130.939, -80.79],
                                  [42.981, -146.269],
                                  [9.436, -92],
                                ],
                                v: [
                                  [-133.69, -371.64],
                                  [-160.143, -159.48],
                                  [-247.863, 49.527],
                                  [-244.392, 154.807],
                                  [-32.81, 286.261],
                                  [73.831, 386.788],
                                  [293.16, 277.252],
                                  [40.914, -79.962],
                                  [321.212, -209.48],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.125, -45.749],
                                  [-28.46, -127.463],
                                  [54.687, -41.235],
                                  [-128.526, -34.996],
                                  [-17.609, -67.624],
                                  [-119.189, 5.282],
                                  [131.205, 80.855],
                                  [-44.609, 145.139],
                                  [-9.406, 92],
                                ],
                                o: [
                                  [-97.656, 46.843],
                                  [28.46, 127.464],
                                  [-54.687, 41.235],
                                  [128.526, 34.996],
                                  [18.625, 68.875],
                                  [111.219, -3.563],
                                  [-131.205, -80.855],
                                  [43.124, -146.467],
                                  [9.406, -92],
                                ],
                                v: [
                                  [-133.421, -371.637],
                                  [-159.893, -159.183],
                                  [-248.216, 49.234],
                                  [-244.265, 154.972],
                                  [-33.777, 286.031],
                                  [74.501, 387.631],
                                  [293.474, 277.791],
                                  [40.625, -79.937],
                                  [321.744, -210.712],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.062, -45.877],
                                  [-28.614, -127.638],
                                  [54.795, -41.128],
                                  [-128.719, -34.976],
                                  [-17.617, -67.714],
                                  [-119.042, 5.215],
                                  [131.466, 80.919],
                                  [-44.719, 145.361],
                                  [-9.378, 92],
                                ],
                                o: [
                                  [-97.704, 46.948],
                                  [28.614, 127.638],
                                  [-54.795, 41.128],
                                  [128.719, 34.976],
                                  [18.612, 68.938],
                                  [111.235, -3.531],
                                  [-131.466, -80.919],
                                  [43.264, -146.662],
                                  [9.378, -92],
                                ],
                                v: [
                                  [-133.146, -371.571],
                                  [-159.7, -158.944],
                                  [-248.569, 48.935],
                                  [-244.195, 155.133],
                                  [-34.753, 285.741],
                                  [75.169, 388.523],
                                  [293.736, 278.271],
                                  [40.406, -79.855],
                                  [322.213, -211.889],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.999, -46.002],
                                  [-28.765, -127.809],
                                  [54.902, -41.023],
                                  [-128.909, -34.956],
                                  [-17.625, -67.801],
                                  [-118.898, 5.149],
                                  [131.723, 80.982],
                                  [-44.827, 145.579],
                                  [-9.349, 92],
                                ],
                                o: [
                                  [-97.751, 47.052],
                                  [28.765, 127.809],
                                  [-54.902, 41.023],
                                  [128.909, 34.956],
                                  [18.6, 69.001],
                                  [111.25, -3.499],
                                  [-131.723, -80.982],
                                  [43.402, -146.853],
                                  [9.35, -92],
                                ],
                                v: [
                                  [-132.866, -371.5],
                                  [-159.51, -158.702],
                                  [-248.865, 48.63],
                                  [-244.122, 155.296],
                                  [-35.794, 285.51],
                                  [75.901, 389.42],
                                  [293.998, 278.7],
                                  [40.137, -79.821],
                                  [322.619, -213.127],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.937, -46.125],
                                  [-28.914, -127.977],
                                  [55.006, -40.92],
                                  [-129.096, -34.936],
                                  [-17.633, -67.888],
                                  [-118.756, 5.084],
                                  [131.975, 81.044],
                                  [-44.933, 145.793],
                                  [-9.322, 92],
                                ],
                                o: [
                                  [-97.797, 47.153],
                                  [28.914, 127.977],
                                  [-55.006, 40.92],
                                  [129.096, 34.936],
                                  [18.587, 69.063],
                                  [111.266, -3.469],
                                  [-131.975, -81.044],
                                  [43.538, -147.041],
                                  [9.322, -92],
                                ],
                                v: [
                                  [-132.518, -371.492],
                                  [-159.266, -158.519],
                                  [-249.161, 48.263],
                                  [-244.112, 155.459],
                                  [-36.728, 285.274],
                                  [76.688, 390.309],
                                  [294.266, 279.135],
                                  [39.932, -79.782],
                                  [323.072, -214.305],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.877, -46.246],
                                  [-29.059, -128.142],
                                  [55.109, -40.819],
                                  [-129.279, -34.917],
                                  [-17.64, -67.972],
                                  [-118.617, 5.021],
                                  [132.223, 81.105],
                                  [-45.037, 146.003],
                                  [-9.295, 92],
                                ],
                                o: [
                                  [-97.842, 47.253],
                                  [29.059, 128.142],
                                  [-55.109, 40.819],
                                  [129.279, 34.917],
                                  [18.575, 69.123],
                                  [111.281, -3.438],
                                  [-132.223, -81.105],
                                  [43.671, -147.225],
                                  [9.295, -92],
                                ],
                                v: [
                                  [-132.17, -371.42],
                                  [-159.078, -158.338],
                                  [-249.456, 47.832],
                                  [-244.156, 155.567],
                                  [-37.727, 285.04],
                                  [77.477, 391.315],
                                  [294.479, 279.571],
                                  [39.734, -79.796],
                                  [323.408, -215.538],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.818, -46.365],
                                  [-29.203, -128.305],
                                  [55.21, -40.719],
                                  [-129.459, -34.898],
                                  [-17.648, -68.056],
                                  [-118.48, 4.958],
                                  [132.466, 81.165],
                                  [-45.14, 146.209],
                                  [-9.268, 92],
                                ],
                                o: [
                                  [-97.887, 47.351],
                                  [29.203, 128.305],
                                  [-55.21, 40.719],
                                  [129.459, 34.898],
                                  [18.564, 69.182],
                                  [111.296, -3.409],
                                  [-132.466, -81.165],
                                  [43.801, -147.407],
                                  [9.268, -92],
                                ],
                                v: [
                                  [-131.754, -371.343],
                                  [-158.839, -158.216],
                                  [-249.752, 47.453],
                                  [-244.201, 155.729],
                                  [-38.676, 284.812],
                                  [78.269, 392.26],
                                  [294.694, 280.01],
                                  [39.548, -79.806],
                                  [323.792, -216.706],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.759, -46.482],
                                  [-29.343, -128.464],
                                  [55.309, -40.622],
                                  [-129.636, -34.88],
                                  [-17.655, -68.137],
                                  [-118.346, 4.897],
                                  [132.704, 81.224],
                                  [-45.24, 146.412],
                                  [-9.242, 92],
                                ],
                                o: [
                                  [-97.931, 47.447],
                                  [29.343, 128.464],
                                  [-55.309, 40.622],
                                  [129.636, 34.88],
                                  [18.552, 69.241],
                                  [111.31, -3.38],
                                  [-132.704, -81.224],
                                  [43.93, -147.584],
                                  [9.242, -92],
                                ],
                                v: [
                                  [-131.334, -371.27],
                                  [-158.661, -158.092],
                                  [-250.043, 47.011],
                                  [-244.306, 155.838],
                                  [-39.632, 284.579],
                                  [79.121, 393.26],
                                  [294.859, 280.392],
                                  [39.426, -79.812],
                                  [324.117, -217.873],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.702, -46.596],
                                  [-29.481, -128.62],
                                  [55.407, -40.526],
                                  [-129.81, -34.861],
                                  [-17.662, -68.217],
                                  [-118.215, 4.837],
                                  [132.939, 81.281],
                                  [-45.339, 146.611],
                                  [-9.216, 92],
                                ],
                                o: [
                                  [-97.974, 47.542],
                                  [29.481, 128.62],
                                  [-55.407, 40.526],
                                  [129.81, 34.861],
                                  [18.54, 69.298],
                                  [111.325, -3.351],
                                  [-132.939, -81.281],
                                  [44.056, -147.759],
                                  [9.216, -92],
                                ],
                                v: [
                                  [-130.908, -371.191],
                                  [-158.423, -158.028],
                                  [-250.281, 46.505],
                                  [-244.412, 156.003],
                                  [-40.536, 284.346],
                                  [79.971, 394.262],
                                  [295.028, 280.779],
                                  [39.253, -79.813],
                                  [324.378, -219.095],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.646, -46.708],
                                  [-29.617, -128.774],
                                  [55.502, -40.432],
                                  [-129.98, -34.843],
                                  [-17.669, -68.296],
                                  [-118.085, 4.778],
                                  [133.169, 81.338],
                                  [-45.436, 146.806],
                                  [-9.191, 92],
                                ],
                                o: [
                                  [-98.016, 47.634],
                                  [29.617, 128.774],
                                  [-55.502, 40.432],
                                  [129.98, 34.843],
                                  [18.529, 69.354],
                                  [111.339, -3.323],
                                  [-133.169, -81.338],
                                  [44.179, -147.93],
                                  [9.191, -92],
                                ],
                                v: [
                                  [-130.419, -371.111],
                                  [-158.249, -158.024],
                                  [-250.514, 46.056],
                                  [-244.573, 156.11],
                                  [-41.452, 284.169],
                                  [80.883, 395.316],
                                  [295.201, 281.163],
                                  [39.144, -79.873],
                                  [324.634, -220.254],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.591, -46.819],
                                  [-29.75, -128.924],
                                  [55.596, -40.339],
                                  [-130.147, -34.826],
                                  [-17.676, -68.373],
                                  [-117.958, 4.72],
                                  [133.395, 81.393],
                                  [-45.531, 146.998],
                                  [-9.166, 92],
                                ],
                                o: [
                                  [-98.057, 47.725],
                                  [29.75, 128.924],
                                  [-55.596, 40.339],
                                  [130.147, 34.826],
                                  [18.518, 69.409],
                                  [111.352, -3.295],
                                  [-133.395, -81.393],
                                  [44.301, -148.099],
                                  [9.166, -92],
                                ],
                                v: [
                                  [-129.921, -371.026],
                                  [-158.073, -158.018],
                                  [-250.751, 45.54],
                                  [-244.739, 156.277],
                                  [-42.369, 283.991],
                                  [81.796, 396.375],
                                  [295.324, 281.494],
                                  [39.042, -79.925],
                                  [324.888, -221.41],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.536, -46.927],
                                  [-29.881, -129.072],
                                  [55.688, -40.249],
                                  [-130.311, -34.808],
                                  [-17.683, -68.449],
                                  [-117.834, 4.663],
                                  [133.616, 81.448],
                                  [-45.625, 147.186],
                                  [-9.141, 92],
                                ],
                                o: [
                                  [-98.098, 47.815],
                                  [29.881, 129.072],
                                  [-55.688, 40.249],
                                  [130.311, 34.808],
                                  [18.507, 69.464],
                                  [111.366, -3.268],
                                  [-133.616, -81.448],
                                  [44.42, -148.264],
                                  [9.141, -92],
                                ],
                                v: [
                                  [-129.422, -370.943],
                                  [-157.899, -158.019],
                                  [-250.984, 45.018],
                                  [-244.961, 156.387],
                                  [-43.235, 283.814],
                                  [82.767, 397.429],
                                  [295.45, 281.822],
                                  [39.005, -79.973],
                                  [325.073, -222.564],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.483, -47.033],
                                  [-30.009, -129.218],
                                  [55.778, -40.159],
                                  [-130.473, -34.792],
                                  [-17.69, -68.523],
                                  [-117.711, 4.607],
                                  [133.834, 81.501],
                                  [-45.716, 147.371],
                                  [-9.117, 92],
                                ],
                                o: [
                                  [-98.138, 47.903],
                                  [30.009, 129.218],
                                  [-55.778, 40.159],
                                  [130.473, 34.792],
                                  [18.497, 69.517],
                                  [111.379, -3.242],
                                  [-133.834, -81.501],
                                  [44.537, -148.426],
                                  [9.117, -92],
                                ],
                                v: [
                                  [-128.919, -370.914],
                                  [-157.785, -158.072],
                                  [-251.159, 44.495],
                                  [-245.244, 156.552],
                                  [-44.048, 283.633],
                                  [83.686, 398.485],
                                  [295.58, 282.154],
                                  [38.919, -80.079],
                                  [325.257, -223.654],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.431, -47.138],
                                  [-30.135, -129.36],
                                  [55.867, -40.072],
                                  [-130.631, -34.775],
                                  [-17.696, -68.596],
                                  [-117.591, 4.553],
                                  [134.048, 81.554],
                                  [-45.806, 147.552],
                                  [-9.094, 92],
                                ],
                                o: [
                                  [-98.177, 47.989],
                                  [30.135, 129.36],
                                  [-55.867, 40.072],
                                  [130.631, 34.775],
                                  [18.486, 69.569],
                                  [111.392, -3.216],
                                  [-134.048, -81.554],
                                  [44.652, -148.585],
                                  [9.094, -92],
                                ],
                                v: [
                                  [-128.348, -370.824],
                                  [-157.673, -158.191],
                                  [-251.329, 43.963],
                                  [-245.528, 156.664],
                                  [-44.871, 283.449],
                                  [84.662, 399.539],
                                  [295.655, 282.429],
                                  [38.895, -80.119],
                                  [325.377, -224.801],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.38, -47.24],
                                  [-30.259, -129.5],
                                  [55.954, -39.986],
                                  [-130.786, -34.759],
                                  [-17.703, -68.668],
                                  [-117.474, 4.499],
                                  [134.258, 81.605],
                                  [-45.895, 147.73],
                                  [-9.071, 92],
                                ],
                                o: [
                                  [-98.215, 48.073],
                                  [30.259, 129.5],
                                  [-55.954, 39.986],
                                  [130.786, 34.759],
                                  [18.476, 69.62],
                                  [111.405, -3.19],
                                  [-134.258, -81.605],
                                  [44.764, -148.741],
                                  [9.071, -92],
                                ],
                                v: [
                                  [-127.773, -370.73],
                                  [-157.559, -158.308],
                                  [-251.5, 43.368],
                                  [-245.872, 156.836],
                                  [-45.696, 283.323],
                                  [85.641, 400.595],
                                  [295.736, 282.704],
                                  [38.88, -80.277],
                                  [325.491, -225.887],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.33, -47.341],
                                  [-30.38, -129.637],
                                  [56.04, -39.902],
                                  [-130.939, -34.743],
                                  [-17.709, -68.739],
                                  [-117.358, 4.446],
                                  [134.463, 81.656],
                                  [-45.982, 147.905],
                                  [-9.048, 92],
                                ],
                                o: [
                                  [-98.253, 48.156],
                                  [30.38, 129.637],
                                  [-56.04, 39.902],
                                  [130.939, 34.743],
                                  [18.466, 69.67],
                                  [111.418, -3.165],
                                  [-134.463, -81.656],
                                  [44.875, -148.895],
                                  [9.048, -92],
                                ],
                                v: [
                                  [-127.197, -370.689],
                                  [-157.449, -158.491],
                                  [-251.612, 42.826],
                                  [-246.219, 157.009],
                                  [-46.471, 283.138],
                                  [86.619, 401.65],
                                  [295.824, 282.983],
                                  [38.871, -80.372],
                                  [325.54, -226.972],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.28, -47.44],
                                  [-30.499, -129.772],
                                  [56.124, -39.819],
                                  [-131.088, -34.727],
                                  [-17.715, -68.808],
                                  [-117.245, 4.394],
                                  [134.665, 81.706],
                                  [-46.067, 148.076],
                                  [-9.026, 92],
                                ],
                                o: [
                                  [-98.29, 48.238],
                                  [30.499, 129.772],
                                  [-56.124, 39.819],
                                  [131.088, 34.727],
                                  [18.456, 69.72],
                                  [111.43, -3.14],
                                  [-134.665, -81.706],
                                  [44.984, -149.045],
                                  [9.026, -92],
                                ],
                                v: [
                                  [-126.557, -370.642],
                                  [-157.396, -158.672],
                                  [-251.783, 42.279],
                                  [-246.625, 157.12],
                                  [-47.189, 283.009],
                                  [87.659, 402.703],
                                  [295.852, 283.259],
                                  [38.923, -80.523],
                                  [325.646, -228.054],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.232, -47.536],
                                  [-30.616, -129.904],
                                  [56.206, -39.738],
                                  [-131.235, -34.711],
                                  [-17.721, -68.875],
                                  [-117.133, 4.343],
                                  [134.864, 81.754],
                                  [-46.15, 148.245],
                                  [-9.004, 92],
                                ],
                                o: [
                                  [-98.326, 48.318],
                                  [30.616, 129.904],
                                  [-56.206, 39.738],
                                  [131.235, 34.711],
                                  [18.446, 69.768],
                                  [111.442, -3.116],
                                  [-134.864, -81.754],
                                  [45.09, -149.193],
                                  [9.004, -92],
                                ],
                                v: [
                                  [-125.971, -370.532],
                                  [-157.345, -158.912],
                                  [-251.891, 41.669],
                                  [-247.034, 157.295],
                                  [-47.857, 282.876],
                                  [88.701, 403.759],
                                  [295.887, 283.48],
                                  [38.985, -80.67],
                                  [325.625, -229.131],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.184, -47.631],
                                  [-30.73, -130.034],
                                  [56.287, -39.659],
                                  [-131.379, -34.696],
                                  [-17.727, -68.942],
                                  [-117.024, 4.293],
                                  [135.058, 81.802],
                                  [-46.232, 148.41],
                                  [-8.983, 92],
                                ],
                                o: [
                                  [-98.362, 48.396],
                                  [30.73, 130.034],
                                  [-56.287, 39.659],
                                  [131.379, 34.696],
                                  [18.437, 69.816],
                                  [111.454, -3.092],
                                  [-135.058, -81.802],
                                  [45.195, -149.338],
                                  [8.983, -92],
                                ],
                                v: [
                                  [-125.322, -370.48],
                                  [-157.294, -159.158],
                                  [-251.94, 41.11],
                                  [-247.503, 157.471],
                                  [-48.526, 282.799],
                                  [89.687, 404.813],
                                  [295.923, 283.705],
                                  [39.053, -80.813],
                                  [325.602, -230.207],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.138, -47.725],
                                  [-30.843, -130.162],
                                  [56.366, -39.581],
                                  [-131.52, -34.681],
                                  [-17.733, -69.007],
                                  [-116.917, 4.245],
                                  [135.249, 81.849],
                                  [-46.313, 148.572],
                                  [-8.962, 92],
                                ],
                                o: [
                                  [-98.397, 48.473],
                                  [30.843, 130.162],
                                  [-56.366, 39.58],
                                  [131.52, 34.681],
                                  [18.428, 69.862],
                                  [111.466, -3.069],
                                  [-135.249, -81.849],
                                  [45.297, -149.48],
                                  [8.962, -92],
                                ],
                                v: [
                                  [-124.728, -370.478],
                                  [-157.3, -159.404],
                                  [-252.045, 40.547],
                                  [-247.975, 157.585],
                                  [-49.203, 282.663],
                                  [90.731, 405.81],
                                  [295.907, 283.926],
                                  [39.127, -81.016],
                                  [325.576, -231.218],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.092, -47.816],
                                  [-30.953, -130.287],
                                  [56.444, -39.504],
                                  [-131.659, -34.667],
                                  [-17.739, -69.071],
                                  [-116.811, 4.196],
                                  [135.437, 81.895],
                                  [-46.392, 148.731],
                                  [-8.941, 92],
                                ],
                                o: [
                                  [-98.431, 48.548],
                                  [30.953, 130.287],
                                  [-56.444, 39.504],
                                  [131.659, 34.667],
                                  [18.418, 69.908],
                                  [111.477, -3.046],
                                  [-135.437, -81.895],
                                  [45.398, -149.62],
                                  [8.941, -92],
                                ],
                                v: [
                                  [-124.073, -370.413],
                                  [-157.307, -159.711],
                                  [-252.091, 39.982],
                                  [-248.448, 157.762],
                                  [-49.762, 282.586],
                                  [91.778, 406.865],
                                  [295.892, 284.091],
                                  [39.206, -81.272],
                                  [325.541, -232.231],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.047, -47.906],
                                  [-31.062, -130.409],
                                  [56.52, -39.429],
                                  [-131.795, -34.652],
                                  [-17.744, -69.134],
                                  [-116.708, 4.149],
                                  [135.62, 81.94],
                                  [-46.469, 148.887],
                                  [-8.921, 92],
                                ],
                                o: [
                                  [-98.465, 48.623],
                                  [31.062, 130.409],
                                  [-56.52, 39.429],
                                  [131.795, 34.652],
                                  [18.409, 69.953],
                                  [111.488, -3.023],
                                  [-135.62, -81.94],
                                  [45.497, -149.757],
                                  [8.921, -92],
                                ],
                                v: [
                                  [-123.414, -370.403],
                                  [-157.312, -160.022],
                                  [-252.137, 39.351],
                                  [-249.038, 157.941],
                                  [-50.329, 282.502],
                                  [92.828, 407.863],
                                  [295.88, 284.256],
                                  [39.35, -81.468],
                                  [325.445, -233.18],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.003, -47.994],
                                  [-31.168, -130.53],
                                  [56.595, -39.355],
                                  [-131.929, -34.638],
                                  [-17.75, -69.196],
                                  [-116.607, 4.103],
                                  [135.801, 81.985],
                                  [-46.545, 149.04],
                                  [-8.901, 92],
                                ],
                                o: [
                                  [-98.498, 48.695],
                                  [31.168, 130.53],
                                  [-56.595, 39.355],
                                  [131.929, 34.638],
                                  [18.401, 69.997],
                                  [111.499, -3.001],
                                  [-135.801, -81.985],
                                  [45.594, -149.891],
                                  [8.901, -92],
                                ],
                                v: [
                                  [-122.809, -370.332],
                                  [-157.38, -160.391],
                                  [-252.183, 38.831],
                                  [-249.573, 158.121],
                                  [-50.838, 282.418],
                                  [93.877, 408.86],
                                  [295.813, 284.424],
                                  [39.5, -81.724],
                                  [325.283, -234.186],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.96, -48.081],
                                  [-31.272, -130.648],
                                  [56.669, -39.282],
                                  [-132.06, -34.625],
                                  [-17.755, -69.256],
                                  [-116.507, 4.058],
                                  [135.978, 82.028],
                                  [-46.62, 149.19],
                                  [-8.882, 92],
                                ],
                                o: [
                                  [-98.53, 48.767],
                                  [31.272, 130.648],
                                  [-56.669, 39.282],
                                  [132.06, 34.625],
                                  [18.392, 70.04],
                                  [111.51, -2.98],
                                  [-135.978, -82.028],
                                  [45.689, -150.023],
                                  [8.882, -92],
                                ],
                                v: [
                                  [-122.141, -370.313],
                                  [-157.505, -160.767],
                                  [-252.167, 38.251],
                                  [-250.17, 158.241],
                                  [-51.354, 282.391],
                                  [94.928, 409.8],
                                  [295.807, 284.592],
                                  [39.652, -82.032],
                                  [325.176, -235.131],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.917, -48.166],
                                  [-31.375, -130.763],
                                  [56.741, -39.211],
                                  [-132.188, -34.611],
                                  [-17.76, -69.316],
                                  [-116.41, 4.013],
                                  [136.151, 82.071],
                                  [-46.693, 149.338],
                                  [-8.863, 92],
                                ],
                                o: [
                                  [-98.562, 48.837],
                                  [31.375, 130.764],
                                  [-56.741, 39.211],
                                  [132.188, 34.611],
                                  [18.383, 70.083],
                                  [111.521, -2.959],
                                  [-136.151, -82.071],
                                  [45.782, -150.152],
                                  [8.863, -92],
                                ],
                                v: [
                                  [-121.531, -370.349],
                                  [-157.628, -161.143],
                                  [-252.153, 37.665],
                                  [-250.829, 158.425],
                                  [-51.814, 282.361],
                                  [95.983, 410.798],
                                  [295.746, 284.762],
                                  [39.813, -82.341],
                                  [324.943, -236.072],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.876, -48.249],
                                  [-31.475, -130.877],
                                  [56.811, -39.142],
                                  [-132.314, -34.598],
                                  [-17.766, -69.374],
                                  [-116.314, 3.969],
                                  [136.322, 82.113],
                                  [-46.764, 149.482],
                                  [-8.844, 92],
                                ],
                                o: [
                                  [-98.593, 48.905],
                                  [31.475, 130.877],
                                  [-56.811, 39.142],
                                  [132.314, 34.598],
                                  [18.375, 70.124],
                                  [111.531, -2.938],
                                  [-136.322, -82.113],
                                  [45.874, -150.279],
                                  [8.844, -92],
                                ],
                                v: [
                                  [-120.858, -370.322],
                                  [-157.752, -161.581],
                                  [-252.134, 37.137],
                                  [-251.489, 158.607],
                                  [-52.156, 282.327],
                                  [97.036, 411.738],
                                  [295.628, 284.872],
                                  [40.038, -82.647],
                                  [324.768, -236.951],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.835, -48.33],
                                  [-31.573, -130.989],
                                  [56.881, -39.073],
                                  [-132.438, -34.585],
                                  [-17.771, -69.431],
                                  [-116.22, 3.927],
                                  [136.489, 82.154],
                                  [-46.835, 149.624],
                                  [-8.826, 92],
                                ],
                                o: [
                                  [-98.624, 48.973],
                                  [31.573, 130.989],
                                  [-56.881, 39.073],
                                  [132.438, 34.585],
                                  [18.367, 70.165],
                                  [111.541, -2.917],
                                  [-136.489, -82.154],
                                  [45.963, -150.404],
                                  [8.826, -92],
                                ],
                                v: [
                                  [-120.239, -370.354],
                                  [-157.936, -162.022],
                                  [-252.118, 36.602],
                                  [-252.148, 158.792],
                                  [-52.503, 282.294],
                                  [98.033, 412.62],
                                  [295.57, 284.985],
                                  [40.266, -83.01],
                                  [324.527, -237.828],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.795, -48.41],
                                  [-31.67, -131.098],
                                  [56.949, -39.006],
                                  [-132.56, -34.572],
                                  [-17.776, -69.487],
                                  [-116.128, 3.885],
                                  [136.653, 82.194],
                                  [-46.904, 149.763],
                                  [-8.808, 92],
                                ],
                                o: [
                                  [-98.654, 49.039],
                                  [31.67, 131.098],
                                  [-56.949, 39.006],
                                  [132.56, 34.572],
                                  [18.359, 70.205],
                                  [111.551, -2.897],
                                  [-136.653, -82.194],
                                  [46.051, -150.526],
                                  [8.808, -92],
                                ],
                                v: [
                                  [-119.62, -370.439],
                                  [-158.118, -162.463],
                                  [-252.042, 36.124],
                                  [-252.871, 158.919],
                                  [-52.852, 282.315],
                                  [99.092, 413.502],
                                  [295.457, 285.096],
                                  [40.503, -83.373],
                                  [324.281, -238.644],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.756, -48.489],
                                  [-31.765, -131.205],
                                  [57.016, -38.941],
                                  [-132.679, -34.56],
                                  [-17.781, -69.542],
                                  [-116.038, 3.843],
                                  [136.813, 82.233],
                                  [-46.972, 149.9],
                                  [-8.79, 92],
                                ],
                                o: [
                                  [-98.683, 49.103],
                                  [31.765, 131.205],
                                  [-57.016, 38.94],
                                  [132.679, 34.56],
                                  [18.351, 70.245],
                                  [111.561, -2.878],
                                  [-136.813, -82.234],
                                  [46.138, -150.646],
                                  [8.79, -92],
                                ],
                                v: [
                                  [-118.996, -370.463],
                                  [-158.358, -162.91],
                                  [-251.964, 35.58],
                                  [-253.597, 159.105],
                                  [-53.086, 282.276],
                                  [100.153, 414.325],
                                  [295.344, 285.207],
                                  [40.801, -83.792],
                                  [323.972, -239.518],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.717, -48.566],
                                  [-31.858, -131.31],
                                  [57.081, -38.876],
                                  [-132.795, -34.547],
                                  [-17.785, -69.596],
                                  [-115.949, 3.803],
                                  [136.971, 82.272],
                                  [-47.038, 150.033],
                                  [-8.773, 92],
                                ],
                                o: [
                                  [-98.712, 49.167],
                                  [31.858, 131.31],
                                  [-57.081, 38.876],
                                  [132.795, 34.547],
                                  [18.343, 70.283],
                                  [111.571, -2.859],
                                  [-136.971, -82.272],
                                  [46.223, -150.763],
                                  [8.773, -92],
                                ],
                                v: [
                                  [-118.371, -370.54],
                                  [-158.598, -163.417],
                                  [-251.886, 35.096],
                                  [-254.327, 159.232],
                                  [-53.261, 282.292],
                                  [101.155, 415.147],
                                  [295.236, 285.26],
                                  [41.045, -84.209],
                                  [323.658, -240.268],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.756, -48.489],
                                  [-31.765, -131.205],
                                  [57.016, -38.941],
                                  [-132.679, -34.56],
                                  [-17.781, -69.542],
                                  [-116.038, 3.843],
                                  [136.813, 82.234],
                                  [-46.972, 149.899],
                                  [-8.79, 92],
                                ],
                                o: [
                                  [-98.683, 49.103],
                                  [31.765, 131.205],
                                  [-57.016, 38.94],
                                  [132.679, 34.56],
                                  [18.351, 70.245],
                                  [111.561, -2.878],
                                  [-136.813, -82.234],
                                  [46.138, -150.646],
                                  [8.79, -92],
                                ],
                                v: [
                                  [-117.466, -370.395],
                                  [-158.869, -164.043],
                                  [-251.796, 34.42],
                                  [-255.055, 159.379],
                                  [-53.703, 282.231],
                                  [102.182, 415.75],
                                  [295.214, 285.204],
                                  [41.845, -84.311],
                                  [322.907, -240.778],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.795, -48.41],
                                  [-31.67, -131.098],
                                  [56.949, -39.006],
                                  [-132.56, -34.572],
                                  [-17.776, -69.487],
                                  [-116.128, 3.885],
                                  [136.653, 82.194],
                                  [-46.904, 149.763],
                                  [-8.808, 92],
                                ],
                                o: [
                                  [-98.654, 49.039],
                                  [31.67, 131.098],
                                  [-56.949, 39.006],
                                  [132.56, 34.572],
                                  [18.359, 70.205],
                                  [111.551, -2.897],
                                  [-136.653, -82.194],
                                  [46.051, -150.526],
                                  [8.808, -92],
                                ],
                                v: [
                                  [-116.562, -370.306],
                                  [-159.144, -164.726],
                                  [-251.766, 33.743],
                                  [-255.781, 159.462],
                                  [-54.087, 282.23],
                                  [103.211, 416.346],
                                  [295.193, 285.207],
                                  [42.648, -84.467],
                                  [322.095, -241.222],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.835, -48.33],
                                  [-31.573, -130.989],
                                  [56.881, -39.073],
                                  [-132.438, -34.585],
                                  [-17.771, -69.431],
                                  [-116.22, 3.927],
                                  [136.489, 82.154],
                                  [-46.835, 149.624],
                                  [-8.826, 92],
                                ],
                                o: [
                                  [-98.624, 48.973],
                                  [31.573, 130.989],
                                  [-56.881, 39.073],
                                  [132.438, 34.585],
                                  [18.367, 70.165],
                                  [111.541, -2.917],
                                  [-136.489, -82.154],
                                  [45.963, -150.404],
                                  [8.826, -92],
                                ],
                                v: [
                                  [-115.658, -370.219],
                                  [-159.421, -165.35],
                                  [-251.616, 33.06],
                                  [-256.503, 159.55],
                                  [-54.353, 282.228],
                                  [104.235, 416.878],
                                  [295.118, 285.149],
                                  [43.516, -84.619],
                                  [321.282, -241.605],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.876, -48.249],
                                  [-31.475, -130.877],
                                  [56.811, -39.142],
                                  [-132.314, -34.598],
                                  [-17.766, -69.374],
                                  [-116.314, 3.969],
                                  [136.322, 82.113],
                                  [-46.764, 149.482],
                                  [-8.844, 92],
                                ],
                                o: [
                                  [-98.593, 48.905],
                                  [31.475, 130.877],
                                  [-56.811, 39.142],
                                  [132.314, 34.598],
                                  [18.375, 70.124],
                                  [111.531, -2.938],
                                  [-136.322, -82.113],
                                  [45.874, -150.279],
                                  [8.844, -92],
                                ],
                                v: [
                                  [-114.751, -370.128],
                                  [-159.821, -166.034],
                                  [-251.529, 32.438],
                                  [-257.285, 159.633],
                                  [-54.623, 282.23],
                                  [105.197, 417.342],
                                  [295.042, 285.036],
                                  [44.388, -84.829],
                                  [320.466, -241.981],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.917, -48.165],
                                  [-31.375, -130.764],
                                  [56.741, -39.211],
                                  [-132.188, -34.611],
                                  [-17.76, -69.316],
                                  [-116.41, 4.013],
                                  [136.151, 82.071],
                                  [-46.693, 149.338],
                                  [-8.863, 92],
                                ],
                                o: [
                                  [-98.562, 48.837],
                                  [31.375, 130.764],
                                  [-56.741, 39.211],
                                  [132.188, 34.611],
                                  [18.383, 70.083],
                                  [111.521, -2.959],
                                  [-136.151, -82.071],
                                  [45.782, -150.152],
                                  [8.863, -92],
                                ],
                                v: [
                                  [-113.901, -370.098],
                                  [-160.163, -166.718],
                                  [-251.44, 31.814],
                                  [-258.06, 159.719],
                                  [-54.838, 282.23],
                                  [106.214, 417.805],
                                  [294.969, 284.979],
                                  [45.261, -85.033],
                                  [319.588, -242.349],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.96, -48.081],
                                  [-31.272, -130.648],
                                  [56.669, -39.282],
                                  [-132.06, -34.625],
                                  [-17.755, -69.256],
                                  [-116.507, 4.058],
                                  [135.978, 82.028],
                                  [-46.62, 149.19],
                                  [-8.882, 92],
                                ],
                                o: [
                                  [-98.53, 48.767],
                                  [31.272, 130.648],
                                  [-56.669, 39.282],
                                  [132.06, 34.625],
                                  [18.392, 70.04],
                                  [111.51, -2.98],
                                  [-135.978, -82.028],
                                  [45.689, -150.023],
                                  [8.882, -92],
                                ],
                                v: [
                                  [-113.052, -370.064],
                                  [-160.563, -167.343],
                                  [-251.292, 31.244],
                                  [-258.894, 159.743],
                                  [-54.992, 282.233],
                                  [107.169, 418.2],
                                  [294.901, 284.924],
                                  [46.083, -85.351],
                                  [318.77, -242.657],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.003, -47.994],
                                  [-31.168, -130.53],
                                  [56.595, -39.355],
                                  [-131.929, -34.638],
                                  [-17.75, -69.196],
                                  [-116.607, 4.103],
                                  [135.801, 81.985],
                                  [-46.545, 149.04],
                                  [-8.901, 92],
                                ],
                                o: [
                                  [-98.498, 48.695],
                                  [31.168, 130.53],
                                  [-56.595, 39.355],
                                  [131.929, 34.638],
                                  [18.401, 69.997],
                                  [111.499, -3.001],
                                  [-135.801, -81.985],
                                  [45.594, -149.891],
                                  [8.901, -92],
                                ],
                                v: [
                                  [-112.256, -370.092],
                                  [-160.971, -167.969],
                                  [-251.146, 30.672],
                                  [-259.663, 159.767],
                                  [-55.094, 282.294],
                                  [108.063, 418.534],
                                  [294.835, 284.811],
                                  [46.906, -85.607],
                                  [317.831, -242.957],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.047, -47.906],
                                  [-31.062, -130.409],
                                  [56.52, -39.429],
                                  [-131.795, -34.652],
                                  [-17.744, -69.134],
                                  [-116.708, 4.149],
                                  [135.62, 81.94],
                                  [-46.469, 148.887],
                                  [-8.921, 92],
                                ],
                                o: [
                                  [-98.465, 48.623],
                                  [31.062, 130.409],
                                  [-56.52, 39.429],
                                  [131.795, 34.652],
                                  [18.409, 69.953],
                                  [111.488, -3.023],
                                  [-135.62, -81.94],
                                  [45.497, -149.757],
                                  [8.921, -92],
                                ],
                                v: [
                                  [-111.464, -370.118],
                                  [-161.437, -168.595],
                                  [-250.941, 30.156],
                                  [-260.49, 159.793],
                                  [-55.137, 282.353],
                                  [109.014, 418.86],
                                  [294.772, 284.699],
                                  [47.734, -85.977],
                                  [316.948, -243.19],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.092, -47.816],
                                  [-30.953, -130.287],
                                  [56.444, -39.504],
                                  [-131.659, -34.667],
                                  [-17.738, -69.071],
                                  [-116.811, 4.196],
                                  [135.436, 81.895],
                                  [-46.392, 148.731],
                                  [-8.941, 92],
                                ],
                                o: [
                                  [-98.431, 48.548],
                                  [30.953, 130.287],
                                  [-56.444, 39.504],
                                  [131.659, 34.667],
                                  [18.418, 69.908],
                                  [111.477, -3.046],
                                  [-135.436, -81.895],
                                  [45.398, -149.62],
                                  [8.941, -92],
                                ],
                                v: [
                                  [-110.725, -370.143],
                                  [-161.966, -169.218],
                                  [-250.794, 29.638],
                                  [-261.313, 159.818],
                                  [-55.187, 282.416],
                                  [109.84, 419.121],
                                  [294.712, 284.647],
                                  [48.503, -86.344],
                                  [316.008, -243.419],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.138, -47.725],
                                  [-30.843, -130.162],
                                  [56.366, -39.581],
                                  [-131.52, -34.681],
                                  [-17.733, -69.007],
                                  [-116.917, 4.245],
                                  [135.249, 81.849],
                                  [-46.313, 148.572],
                                  [-8.962, 92],
                                ],
                                o: [
                                  [-98.397, 48.473],
                                  [30.843, 130.162],
                                  [-56.366, 39.58],
                                  [131.52, 34.681],
                                  [18.428, 69.862],
                                  [111.466, -3.069],
                                  [-135.249, -81.849],
                                  [45.297, -149.48],
                                  [8.962, -92],
                                ],
                                v: [
                                  [-109.987, -370.226],
                                  [-162.438, -169.786],
                                  [-250.591, 29.232],
                                  [-262.133, 159.786],
                                  [-55.122, 282.536],
                                  [110.724, 419.437],
                                  [294.656, 284.54],
                                  [49.222, -86.705],
                                  [315.064, -243.584],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.184, -47.632],
                                  [-30.73, -130.034],
                                  [56.287, -39.659],
                                  [-131.379, -34.696],
                                  [-17.727, -68.942],
                                  [-117.024, 4.293],
                                  [135.058, 81.802],
                                  [-46.232, 148.41],
                                  [-8.983, 92],
                                ],
                                o: [
                                  [-98.362, 48.396],
                                  [30.73, 130.034],
                                  [-56.287, 39.659],
                                  [131.379, 34.696],
                                  [18.437, 69.816],
                                  [111.454, -3.092],
                                  [-135.058, -81.802],
                                  [45.195, -149.338],
                                  [8.983, -92],
                                ],
                                v: [
                                  [-109.249, -370.307],
                                  [-162.968, -170.354],
                                  [-250.387, 28.767],
                                  [-263.011, 159.753],
                                  [-54.999, 282.656],
                                  [111.542, 419.688],
                                  [294.546, 284.428],
                                  [50.002, -87.182],
                                  [314.119, -243.744],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.232, -47.536],
                                  [-30.616, -129.904],
                                  [56.206, -39.738],
                                  [-131.235, -34.711],
                                  [-17.721, -68.876],
                                  [-117.133, 4.343],
                                  [134.864, 81.754],
                                  [-46.15, 148.245],
                                  [-9.004, 92],
                                ],
                                o: [
                                  [-98.326, 48.318],
                                  [30.616, 129.904],
                                  [-56.206, 39.738],
                                  [131.235, 34.711],
                                  [18.446, 69.768],
                                  [111.442, -3.116],
                                  [-134.864, -81.754],
                                  [45.09, -149.193],
                                  [9.004, -92],
                                ],
                                v: [
                                  [-108.563, -370.446],
                                  [-163.561, -170.923],
                                  [-250.187, 28.417],
                                  [-263.822, 159.661],
                                  [-54.825, 282.777],
                                  [112.362, 419.875],
                                  [294.497, 284.323],
                                  [50.668, -87.59],
                                  [313.174, -243.836],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.28, -47.44],
                                  [-30.499, -129.772],
                                  [56.124, -39.819],
                                  [-131.088, -34.727],
                                  [-17.715, -68.808],
                                  [-117.244, 4.394],
                                  [134.665, 81.706],
                                  [-46.067, 148.076],
                                  [-9.026, 92],
                                ],
                                o: [
                                  [-98.29, 48.238],
                                  [30.499, 129.772],
                                  [-56.124, 39.819],
                                  [131.088, 34.727],
                                  [18.456, 69.72],
                                  [111.43, -3.14],
                                  [-134.665, -81.706],
                                  [44.983, -149.045],
                                  [9.026, -92],
                                ],
                                v: [
                                  [-107.938, -370.583],
                                  [-164.097, -171.43],
                                  [-249.926, 28.06],
                                  [-264.692, 159.512],
                                  [-54.652, 282.895],
                                  [113.175, 420.116],
                                  [294.454, 284.159],
                                  [51.396, -88.117],
                                  [312.169, -243.862],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.33, -47.341],
                                  [-30.38, -129.637],
                                  [56.04, -39.902],
                                  [-130.939, -34.743],
                                  [-17.709, -68.739],
                                  [-117.358, 4.446],
                                  [134.463, 81.656],
                                  [-45.982, 147.905],
                                  [-9.048, 92],
                                ],
                                o: [
                                  [-98.253, 48.156],
                                  [30.38, 129.637],
                                  [-56.04, 39.902],
                                  [130.939, 34.743],
                                  [18.466, 69.67],
                                  [111.418, -3.165],
                                  [-134.463, -81.656],
                                  [44.875, -148.895],
                                  [9.048, -92],
                                ],
                                v: [
                                  [-107.309, -370.778],
                                  [-164.635, -172],
                                  [-249.726, 27.7],
                                  [-265.554, 159.364],
                                  [-54.369, 283.011],
                                  [113.923, 420.292],
                                  [294.354, 284.054],
                                  [52.073, -88.638],
                                  [311.165, -243.941],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.38, -47.24],
                                  [-30.259, -129.5],
                                  [55.954, -39.986],
                                  [-130.786, -34.759],
                                  [-17.703, -68.668],
                                  [-117.474, 4.499],
                                  [134.258, 81.605],
                                  [-45.895, 147.73],
                                  [-9.071, 92],
                                ],
                                o: [
                                  [-98.215, 48.073],
                                  [30.259, 129.5],
                                  [-55.954, 39.986],
                                  [130.786, 34.759],
                                  [18.476, 69.62],
                                  [111.405, -3.19],
                                  [-134.258, -81.605],
                                  [44.764, -148.741],
                                  [9.071, -92],
                                ],
                                v: [
                                  [-106.739, -370.97],
                                  [-165.174, -172.629],
                                  [-249.472, 27.457],
                                  [-266.415, 159.219],
                                  [-54.03, 283.185],
                                  [114.672, 420.466],
                                  [294.323, 283.952],
                                  [52.692, -89.152],
                                  [310.159, -243.956],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.431, -47.138],
                                  [-30.135, -129.36],
                                  [55.867, -40.072],
                                  [-130.631, -34.775],
                                  [-17.696, -68.596],
                                  [-117.591, 4.553],
                                  [134.048, 81.554],
                                  [-45.806, 147.552],
                                  [-9.094, 92],
                                ],
                                o: [
                                  [-98.177, 47.989],
                                  [30.135, 129.36],
                                  [-55.867, 40.072],
                                  [130.631, 34.775],
                                  [18.486, 69.569],
                                  [111.392, -3.216],
                                  [-134.048, -81.554],
                                  [44.652, -148.585],
                                  [9.094, -92],
                                ],
                                v: [
                                  [-106.225, -371.22],
                                  [-165.715, -173.2],
                                  [-249.215, 27.207],
                                  [-267.213, 159.015],
                                  [-53.699, 283.36],
                                  [115.414, 420.632],
                                  [294.294, 283.792],
                                  [53.318, -89.722],
                                  [309.21, -244.021],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.483, -47.033],
                                  [-30.009, -129.218],
                                  [55.778, -40.159],
                                  [-130.473, -34.792],
                                  [-17.69, -68.523],
                                  [-117.711, 4.607],
                                  [133.834, 81.501],
                                  [-45.716, 147.371],
                                  [-9.117, 92],
                                ],
                                o: [
                                  [-98.138, 47.903],
                                  [30.009, 129.218],
                                  [-55.778, 40.159],
                                  [130.473, 34.792],
                                  [18.497, 69.517],
                                  [111.379, -3.242],
                                  [-133.834, -81.501],
                                  [44.537, -148.426],
                                  [9.117, -92],
                                ],
                                v: [
                                  [-105.707, -371.468],
                                  [-166.2, -173.829],
                                  [-248.96, 27.011],
                                  [-268.065, 158.752],
                                  [-53.252, 283.532],
                                  [116.091, 420.736],
                                  [294.268, 283.694],
                                  [53.886, -90.344],
                                  [308.205, -244.022],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.536, -46.927],
                                  [-29.881, -129.072],
                                  [55.688, -40.249],
                                  [-130.311, -34.808],
                                  [-17.683, -68.449],
                                  [-117.834, 4.663],
                                  [133.616, 81.448],
                                  [-45.625, 147.186],
                                  [-9.141, 92],
                                ],
                                o: [
                                  [-98.098, 47.815],
                                  [29.881, 129.072],
                                  [-55.688, 40.249],
                                  [130.311, 34.809],
                                  [18.507, 69.464],
                                  [111.366, -3.268],
                                  [-133.616, -81.448],
                                  [44.42, -148.264],
                                  [9.141, -92],
                                ],
                                v: [
                                  [-105.245, -371.713],
                                  [-166.687, -174.46],
                                  [-248.648, 26.815],
                                  [-268.916, 158.494],
                                  [-52.816, 283.7],
                                  [116.769, 420.894],
                                  [294.249, 283.596],
                                  [54.465, -90.959],
                                  [307.197, -244.076],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.591, -46.819],
                                  [-29.75, -128.924],
                                  [55.596, -40.339],
                                  [-130.147, -34.826],
                                  [-17.676, -68.373],
                                  [-117.958, 4.72],
                                  [133.395, 81.393],
                                  [-45.531, 146.998],
                                  [-9.166, 92],
                                ],
                                o: [
                                  [-98.057, 47.725],
                                  [29.75, 128.924],
                                  [-55.596, 40.339],
                                  [130.147, 34.826],
                                  [18.518, 69.409],
                                  [111.352, -3.295],
                                  [-133.395, -81.393],
                                  [44.301, -148.099],
                                  [9.166, -92],
                                ],
                                v: [
                                  [-104.837, -372.019],
                                  [-167.175, -175.09],
                                  [-248.397, 26.669],
                                  [-269.7, 158.24],
                                  [-52.264, 283.926],
                                  [117.382, 420.928],
                                  [294.236, 283.441],
                                  [55.045, -91.571],
                                  [306.193, -244.061],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.646, -46.708],
                                  [-29.617, -128.774],
                                  [55.502, -40.432],
                                  [-129.98, -34.843],
                                  [-17.669, -68.296],
                                  [-118.085, 4.778],
                                  [133.169, 81.338],
                                  [-45.436, 146.806],
                                  [-9.191, 92],
                                ],
                                o: [
                                  [-98.016, 47.634],
                                  [29.617, 128.774],
                                  [-55.502, 40.432],
                                  [129.98, 34.843],
                                  [18.529, 69.354],
                                  [111.339, -3.323],
                                  [-133.169, -81.338],
                                  [44.179, -147.93],
                                  [9.191, -92],
                                ],
                                v: [
                                  [-104.488, -372.378],
                                  [-167.662, -175.721],
                                  [-248.088, 26.581],
                                  [-270.542, 157.929],
                                  [-51.719, 284.203],
                                  [117.991, 421.016],
                                  [294.223, 283.348],
                                  [55.51, -92.238],
                                  [305.24, -244.1],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.702, -46.596],
                                  [-29.481, -128.62],
                                  [55.407, -40.526],
                                  [-129.81, -34.861],
                                  [-17.662, -68.217],
                                  [-118.215, 4.837],
                                  [132.939, 81.281],
                                  [-45.339, 146.611],
                                  [-9.216, 92],
                                ],
                                o: [
                                  [-97.974, 47.542],
                                  [29.481, 128.62],
                                  [-55.407, 40.526],
                                  [129.81, 34.861],
                                  [18.54, 69.298],
                                  [111.325, -3.351],
                                  [-132.939, -81.281],
                                  [44.056, -147.759],
                                  [9.216, -92],
                                ],
                                v: [
                                  [-104.135, -372.733],
                                  [-168.155, -176.352],
                                  [-247.786, 26.542],
                                  [-271.32, 157.622],
                                  [-51.185, 284.42],
                                  [118.597, 421.043],
                                  [294.275, 283.193],
                                  [56.044, -92.898],
                                  [304.233, -244.073],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.759, -46.482],
                                  [-29.343, -128.464],
                                  [55.309, -40.622],
                                  [-129.636, -34.88],
                                  [-17.655, -68.137],
                                  [-118.346, 4.897],
                                  [132.704, 81.224],
                                  [-45.24, 146.412],
                                  [-9.242, 92],
                                ],
                                o: [
                                  [-97.931, 47.447],
                                  [29.343, 128.464],
                                  [-55.309, 40.622],
                                  [129.636, 34.88],
                                  [18.552, 69.241],
                                  [111.31, -3.38],
                                  [-132.704, -81.224],
                                  [43.93, -147.584],
                                  [9.242, -92],
                                ],
                                v: [
                                  [-103.836, -373.149],
                                  [-168.588, -177.042],
                                  [-247.481, 26.502],
                                  [-272.094, 157.316],
                                  [-50.599, 284.754],
                                  [119.196, 421.065],
                                  [294.268, 283.101],
                                  [56.521, -93.609],
                                  [303.228, -244.041],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.818, -46.365],
                                  [-29.203, -128.305],
                                  [55.21, -40.719],
                                  [-129.459, -34.898],
                                  [-17.648, -68.056],
                                  [-118.48, 4.958],
                                  [132.466, 81.165],
                                  [-45.14, 146.209],
                                  [-9.268, 92],
                                ],
                                o: [
                                  [-97.887, 47.351],
                                  [29.203, 128.305],
                                  [-55.21, 40.719],
                                  [129.459, 34.898],
                                  [18.564, 69.182],
                                  [111.296, -3.409],
                                  [-132.466, -81.165],
                                  [43.801, -147.407],
                                  [9.268, -92],
                                ],
                                v: [
                                  [-103.591, -373.558],
                                  [-169.019, -177.678],
                                  [-247.236, 26.457],
                                  [-272.863, 156.954],
                                  [-49.963, 285.078],
                                  [119.733, 421.081],
                                  [294.327, 282.951],
                                  [56.947, -94.316],
                                  [302.217, -244.06],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.877, -46.246],
                                  [-29.059, -128.142],
                                  [55.109, -40.819],
                                  [-129.279, -34.917],
                                  [-17.64, -67.972],
                                  [-118.617, 5.021],
                                  [132.223, 81.105],
                                  [-45.037, 146.003],
                                  [-9.295, 92],
                                ],
                                o: [
                                  [-97.842, 47.253],
                                  [29.059, 128.142],
                                  [-55.109, 40.819],
                                  [129.279, 34.917],
                                  [18.575, 69.123],
                                  [111.281, -3.438],
                                  [-132.223, -81.105],
                                  [43.671, -147.225],
                                  [9.295, -92],
                                ],
                                v: [
                                  [-103.341, -374.027],
                                  [-169.457, -178.368],
                                  [-246.931, 26.403],
                                  [-273.631, 156.597],
                                  [-49.334, 285.401],
                                  [120.209, 421.037],
                                  [294.446, 282.86],
                                  [57.374, -95.016],
                                  [301.21, -244.01],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.937, -46.125],
                                  [-28.914, -127.977],
                                  [55.007, -40.92],
                                  [-129.096, -34.936],
                                  [-17.633, -67.888],
                                  [-118.756, 5.084],
                                  [131.975, 81.044],
                                  [-44.933, 145.793],
                                  [-9.322, 92],
                                ],
                                o: [
                                  [-97.797, 47.153],
                                  [28.914, 127.977],
                                  [-55.007, 40.92],
                                  [129.096, 34.936],
                                  [18.587, 69.063],
                                  [111.266, -3.469],
                                  [-131.975, -81.044],
                                  [43.538, -147.041],
                                  [9.322, -92],
                                ],
                                v: [
                                  [-103.091, -374.488],
                                  [-169.835, -179.005],
                                  [-246.685, 26.405],
                                  [-274.389, 156.188],
                                  [-48.655, 285.776],
                                  [120.739, 420.988],
                                  [294.511, 282.711],
                                  [57.751, -95.77],
                                  [300.259, -243.954],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.999, -46.002],
                                  [-28.765, -127.809],
                                  [54.902, -41.023],
                                  [-128.909, -34.956],
                                  [-17.625, -67.801],
                                  [-118.898, 5.149],
                                  [131.723, 80.982],
                                  [-44.827, 145.579],
                                  [-9.35, 92],
                                ],
                                o: [
                                  [-97.751, 47.052],
                                  [28.765, 127.809],
                                  [-54.902, 41.023],
                                  [128.909, 34.956],
                                  [18.6, 69.001],
                                  [111.25, -3.5],
                                  [-131.723, -80.982],
                                  [43.402, -146.853],
                                  [9.35, -92],
                                ],
                                v: [
                                  [-102.842, -375],
                                  [-170.215, -179.7],
                                  [-246.384, 26.397],
                                  [-275.089, 155.782],
                                  [-47.988, 286.145],
                                  [121.203, 420.937],
                                  [294.58, 282.564],
                                  [58.134, -96.512],
                                  [299.249, -243.89],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.062, -45.877],
                                  [-28.614, -127.638],
                                  [54.795, -41.128],
                                  [-128.719, -34.976],
                                  [-17.617, -67.714],
                                  [-119.042, 5.215],
                                  [131.466, 80.919],
                                  [-44.719, 145.361],
                                  [-9.378, 92],
                                ],
                                o: [
                                  [-97.704, 46.948],
                                  [28.614, 127.638],
                                  [-54.795, 41.128],
                                  [128.719, 34.976],
                                  [18.612, 68.938],
                                  [111.235, -3.531],
                                  [-131.466, -80.919],
                                  [43.264, -146.662],
                                  [9.378, -92],
                                ],
                                v: [
                                  [-102.593, -375.503],
                                  [-170.599, -180.337],
                                  [-246.141, 26.388],
                                  [-275.784, 155.382],
                                  [-47.325, 286.512],
                                  [121.606, 420.823],
                                  [294.709, 282.475],
                                  [58.466, -97.25],
                                  [298.238, -243.878],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.125, -45.749],
                                  [-28.46, -127.464],
                                  [54.687, -41.235],
                                  [-128.526, -34.996],
                                  [-17.609, -67.624],
                                  [-119.189, 5.282],
                                  [131.205, 80.855],
                                  [-44.609, 145.139],
                                  [-9.406, 92],
                                ],
                                o: [
                                  [-97.656, 46.843],
                                  [28.46, 127.463],
                                  [-54.687, 41.235],
                                  [128.526, 34.996],
                                  [18.625, 68.875],
                                  [111.219, -3.563],
                                  [-131.205, -80.855],
                                  [43.124, -146.467],
                                  [9.406, -92],
                                ],
                                v: [
                                  [-102.345, -376.055],
                                  [-170.98, -181.032],
                                  [-245.896, 26.368],
                                  [-276.416, 154.984],
                                  [-46.618, 286.929],
                                  [122.063, 420.704],
                                  [294.842, 282.328],
                                  [58.748, -98.037],
                                  [297.231, -243.801],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.191, -45.619],
                                  [-28.303, -127.286],
                                  [54.576, -41.344],
                                  [-128.329, -35.017],
                                  [-17.601, -67.533],
                                  [-119.338, 5.35],
                                  [130.939, 80.79],
                                  [-44.496, 144.913],
                                  [-9.436, 92],
                                ],
                                o: [
                                  [-97.607, 46.736],
                                  [28.303, 127.286],
                                  [-54.576, 41.344],
                                  [128.329, 35.017],
                                  [18.638, 68.81],
                                  [111.202, -3.595],
                                  [-130.939, -80.79],
                                  [42.981, -146.269],
                                  [9.436, -92],
                                ],
                                v: [
                                  [-102.15, -376.661],
                                  [-171.358, -181.675],
                                  [-245.71, 26.4],
                                  [-277.048, 154.536],
                                  [-45.92, 287.34],
                                  [122.464, 420.583],
                                  [295.042, 282.183],
                                  [59.09, -98.811],
                                  [296.223, -243.716],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.257, -45.487],
                                  [-28.143, -127.105],
                                  [54.464, -41.455],
                                  [-128.128, -35.038],
                                  [-17.593, -67.441],
                                  [-119.49, 5.419],
                                  [130.668, 80.723],
                                  [-44.382, 144.683],
                                  [-9.466, 92],
                                ],
                                o: [
                                  [-97.557, 46.626],
                                  [28.143, 127.105],
                                  [-54.464, 41.455],
                                  [128.128, 35.038],
                                  [18.651, 68.743],
                                  [111.186, -3.628],
                                  [-130.668, -80.723],
                                  [42.835, -146.067],
                                  [9.466, -92],
                                ],
                                v: [
                                  [-101.961, -377.252],
                                  [-171.687, -182.371],
                                  [-245.462, 26.43],
                                  [-277.67, 154.091],
                                  [-45.174, 287.748],
                                  [122.798, 420.452],
                                  [295.181, 282.044],
                                  [59.33, -99.576],
                                  [295.267, -243.567],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.324, -45.352],
                                  [-27.981, -126.921],
                                  [54.349, -41.568],
                                  [-127.924, -35.06],
                                  [-17.585, -67.346],
                                  [-119.645, 5.49],
                                  [130.392, 80.655],
                                  [-44.266, 144.449],
                                  [-9.496, 92],
                                ],
                                o: [
                                  [-97.507, 46.515],
                                  [27.981, 126.921],
                                  [-54.349, 41.568],
                                  [127.924, 35.06],
                                  [18.665, 68.676],
                                  [111.169, -3.662],
                                  [-130.392, -80.655],
                                  [42.687, -145.862],
                                  [9.496, -92],
                                ],
                                v: [
                                  [-101.768, -377.838],
                                  [-172.008, -183.015],
                                  [-245.279, 26.449],
                                  [-278.234, 153.654],
                                  [-44.437, 288.205],
                                  [123.192, 420.262],
                                  [295.382, 281.958],
                                  [59.572, -100.326],
                                  [294.257, -243.467],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.393, -45.215],
                                  [-27.815, -126.734],
                                  [54.232, -41.683],
                                  [-127.716, -35.081],
                                  [-17.576, -67.25],
                                  [-119.803, 5.562],
                                  [130.111, 80.586],
                                  [-44.148, 144.21],
                                  [-9.527, 92],
                                ],
                                o: [
                                  [-97.455, 46.402],
                                  [27.815, 126.734],
                                  [-54.232, 41.683],
                                  [127.716, 35.081],
                                  [18.679, 68.607],
                                  [111.152, -3.696],
                                  [-130.111, -80.586],
                                  [42.536, -145.652],
                                  [9.527, -92],
                                ],
                                v: [
                                  [-101.576, -378.476],
                                  [-172.28, -183.659],
                                  [-245.091, 26.466],
                                  [-278.794, 153.163],
                                  [-43.71, 288.597],
                                  [123.524, 420.067],
                                  [295.587, 281.816],
                                  [59.765, -101.13],
                                  [293.247, -243.36],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.463, -45.075],
                                  [-27.647, -126.543],
                                  [54.114, -41.8],
                                  [-127.504, -35.104],
                                  [-17.567, -67.152],
                                  [-119.964, 5.636],
                                  [129.825, 80.516],
                                  [-44.027, 143.968],
                                  [-9.558, 92],
                                ],
                                o: [
                                  [-97.403, 46.287],
                                  [27.647, 126.543],
                                  [-54.114, 41.8],
                                  [127.504, 35.104],
                                  [18.693, 68.537],
                                  [111.134, -3.731],
                                  [-129.825, -80.516],
                                  [42.382, -145.439],
                                  [9.558, -92],
                                ],
                                v: [
                                  [-101.443, -379.155],
                                  [-172.607, -184.303],
                                  [-244.9, 26.528],
                                  [-279.296, 152.733],
                                  [-42.993, 289.043],
                                  [123.858, 419.86],
                                  [295.852, 281.674],
                                  [59.971, -101.918],
                                  [292.293, -243.246],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.534, -44.933],
                                  [-27.475, -126.349],
                                  [53.993, -41.919],
                                  [-127.289, -35.126],
                                  [-17.558, -67.053],
                                  [-120.127, 5.71],
                                  [129.534, 80.444],
                                  [-43.904, 143.721],
                                  [-9.59, 92],
                                ],
                                o: [
                                  [-97.35, 46.169],
                                  [27.475, 126.349],
                                  [-53.993, 41.919],
                                  [127.289, 35.126],
                                  [18.707, 68.466],
                                  [111.117, -3.767],
                                  [-129.534, -80.444],
                                  [42.226, -145.222],
                                  [9.59, -92],
                                ],
                                v: [
                                  [-101.312, -379.828],
                                  [-172.874, -184.954],
                                  [-244.772, 26.588],
                                  [-279.794, 152.254],
                                  [-42.234, 289.48],
                                  [124.132, 419.658],
                                  [296.058, 281.483],
                                  [60.181, -102.696],
                                  [291.282, -243.066],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.606, -44.788],
                                  [-27.301, -126.151],
                                  [53.87, -42.04],
                                  [-127.069, -35.149],
                                  [-17.549, -66.952],
                                  [-120.294, 5.786],
                                  [129.238, 80.371],
                                  [-43.78, 143.469],
                                  [-9.623, 92],
                                ],
                                o: [
                                  [-97.295, 46.05],
                                  [27.301, 126.151],
                                  [-53.87, 42.04],
                                  [127.069, 35.149],
                                  [18.721, 68.394],
                                  [111.099, -3.803],
                                  [-129.238, -80.371],
                                  [42.067, -145.002],
                                  [9.623, -92],
                                ],
                                v: [
                                  [-101.182, -380.489],
                                  [-173.087, -185.6],
                                  [-244.639, 26.641],
                                  [-280.23, 151.78],
                                  [-41.481, 289.913],
                                  [124.402, 419.388],
                                  [296.33, 281.344],
                                  [60.285, -103.463],
                                  [290.327, -242.935],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.68, -44.641],
                                  [-27.123, -125.95],
                                  [53.745, -42.163],
                                  [-126.846, -35.173],
                                  [-17.54, -66.848],
                                  [-120.463, 5.864],
                                  [128.936, 80.297],
                                  [-43.653, 143.213],
                                  [-9.656, 92],
                                ],
                                o: [
                                  [-97.24, 45.929],
                                  [27.123, 125.95],
                                  [-53.745, 42.163],
                                  [126.846, 35.173],
                                  [18.736, 68.32],
                                  [111.08, -3.84],
                                  [-128.936, -80.297],
                                  [41.905, -144.777],
                                  [9.656, -92],
                                ],
                                v: [
                                  [-101.106, -381.138],
                                  [-173.366, -186.195],
                                  [-244.508, 26.687],
                                  [-280.667, 151.258],
                                  [-40.802, 290.336],
                                  [124.675, 419.118],
                                  [296.662, 281.206],
                                  [60.456, -104.22],
                                  [289.365, -242.74],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.755, -44.491],
                                  [-26.942, -125.745],
                                  [53.617, -42.289],
                                  [-126.619, -35.197],
                                  [-17.531, -66.743],
                                  [-120.636, 5.942],
                                  [128.629, 80.222],
                                  [-43.523, 142.953],
                                  [-9.69, 92],
                                ],
                                o: [
                                  [-97.184, 45.805],
                                  [26.942, 125.745],
                                  [-53.617, 42.289],
                                  [126.619, 35.197],
                                  [18.751, 68.245],
                                  [111.061, -3.877],
                                  [-128.629, -80.222],
                                  [41.74, -144.548],
                                  [9.69, -92],
                                ],
                                v: [
                                  [-101.032, -381.83],
                                  [-173.586, -186.848],
                                  [-244.434, 26.782],
                                  [-281.042, 150.792],
                                  [-40.071, 290.806],
                                  [124.888, 418.841],
                                  [296.935, 281.07],
                                  [60.522, -105.023],
                                  [288.352, -242.536],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.831, -44.338],
                                  [-26.758, -125.537],
                                  [53.487, -42.417],
                                  [-126.387, -35.221],
                                  [-17.521, -66.637],
                                  [-120.811, 6.023],
                                  [128.317, 80.145],
                                  [-43.392, 142.687],
                                  [-9.724, 92],
                                ],
                                o: [
                                  [-97.127, 45.679],
                                  [26.758, 125.537],
                                  [-53.487, 42.417],
                                  [126.387, 35.221],
                                  [18.766, 68.169],
                                  [111.042, -3.915],
                                  [-128.317, -80.145],
                                  [41.572, -144.316],
                                  [9.724, -92],
                                ],
                                v: [
                                  [-100.954, -382.515],
                                  [-173.746, -187.451],
                                  [-244.36, 26.813],
                                  [-281.413, 150.342],
                                  [-39.357, 291.215],
                                  [125.154, 418.502],
                                  [297.268, 280.878],
                                  [60.648, -105.752],
                                  [287.395, -242.324],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.909, -44.183],
                                  [-26.57, -125.325],
                                  [53.355, -42.547],
                                  [-126.152, -35.246],
                                  [-17.511, -66.528],
                                  [-120.99, 6.104],
                                  [127.999, 80.067],
                                  [-43.258, 142.417],
                                  [-9.759, 92],
                                ],
                                o: [
                                  [-97.068, 45.551],
                                  [26.57, 125.325],
                                  [-53.355, 42.547],
                                  [126.152, 35.246],
                                  [18.782, 68.091],
                                  [111.023, -3.954],
                                  [-127.999, -80.067],
                                  [41.401, -144.079],
                                  [9.759, -92],
                                ],
                                v: [
                                  [-100.935, -383.249],
                                  [-173.972, -188.049],
                                  [-244.281, 26.898],
                                  [-281.73, 149.835],
                                  [-38.598, 291.675],
                                  [125.367, 418.164],
                                  [297.604, 280.738],
                                  [60.676, -106.526],
                                  [286.437, -242.109],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.988, -44.025],
                                  [-26.38, -125.109],
                                  [53.221, -42.679],
                                  [-125.912, -35.271],
                                  [-17.502, -66.417],
                                  [-121.172, 6.187],
                                  [127.675, 79.987],
                                  [-43.121, 142.143],
                                  [-9.794, 92],
                                ],
                                o: [
                                  [-97.009, 45.42],
                                  [26.38, 125.109],
                                  [-53.221, 42.679],
                                  [125.912, 35.271],
                                  [18.798, 68.012],
                                  [111.003, -3.994],
                                  [-127.675, -79.987],
                                  [41.227, -143.838],
                                  [9.794, -92],
                                ],
                                v: [
                                  [-100.919, -383.905],
                                  [-174.139, -188.598],
                                  [-244.259, 26.97],
                                  [-281.985, 149.338],
                                  [-37.909, 292.068],
                                  [125.576, 417.819],
                                  [298, 280.549],
                                  [60.715, -107.232],
                                  [285.529, -241.887],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.068, -43.864],
                                  [-26.186, -124.889],
                                  [53.084, -42.814],
                                  [-125.669, -35.297],
                                  [-17.491, -66.305],
                                  [-121.357, 6.272],
                                  [127.346, 79.906],
                                  [-42.983, 141.863],
                                  [-9.831, 92],
                                ],
                                o: [
                                  [-96.949, 45.288],
                                  [26.186, 124.889],
                                  [-53.084, 42.814],
                                  [125.669, 35.297],
                                  [18.814, 67.932],
                                  [110.983, -4.034],
                                  [-127.346, -79.906],
                                  [41.05, -143.592],
                                  [9.831, -92],
                                ],
                                v: [
                                  [-100.955, -384.609],
                                  [-174.309, -189.154],
                                  [-244.236, 27.095],
                                  [-282.237, 148.847],
                                  [-37.231, 292.506],
                                  [125.727, 417.476],
                                  [298.392, 280.356],
                                  [60.758, -107.982],
                                  [284.571, -241.649],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.15, -43.7],
                                  [-25.988, -124.665],
                                  [52.945, -42.951],
                                  [-125.42, -35.323],
                                  [-17.481, -66.19],
                                  [-121.545, 6.357],
                                  [127.011, 79.824],
                                  [-42.841, 141.579],
                                  [-9.867, 92],
                                ],
                                o: [
                                  [-96.888, 45.153],
                                  [25.988, 124.665],
                                  [-52.945, 42.951],
                                  [125.42, 35.323],
                                  [18.83, 67.85],
                                  [110.962, -4.075],
                                  [-127.011, -79.824],
                                  [40.87, -143.343],
                                  [9.867, -92],
                                ],
                                v: [
                                  [-100.994, -385.298],
                                  [-174.421, -189.706],
                                  [-244.214, 27.156],
                                  [-282.428, 148.362],
                                  [-36.622, 292.882],
                                  [125.88, 417.126],
                                  [298.788, 280.17],
                                  [60.762, -108.72],
                                  [283.661, -241.415],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.233, -43.533],
                                  [-25.787, -124.438],
                                  [52.803, -43.091],
                                  [-125.168, -35.349],
                                  [-17.471, -66.073],
                                  [-121.737, 6.445],
                                  [126.67, 79.74],
                                  [-42.698, 141.289],
                                  [-9.905, 92],
                                ],
                                o: [
                                  [-96.825, 45.015],
                                  [25.787, 124.438],
                                  [-52.803, 43.091],
                                  [125.168, 35.349],
                                  [18.847, 67.767],
                                  [110.942, -4.117],
                                  [-126.67, -79.74],
                                  [40.687, -143.089],
                                  [9.905, -92],
                                ],
                                v: [
                                  [-101.093, -385.977],
                                  [-174.537, -190.266],
                                  [-244.248, 27.216],
                                  [-282.566, 147.945],
                                  [-35.97, 293.308],
                                  [126.031, 416.715],
                                  [299.187, 279.98],
                                  [60.714, -109.439],
                                  [282.695, -241.111],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.318, -43.364],
                                  [-25.583, -124.206],
                                  [52.659, -43.233],
                                  [-124.911, -35.376],
                                  [-17.46, -65.955],
                                  [-121.931, 6.534],
                                  [126.323, 79.655],
                                  [-42.551, 140.995],
                                  [-9.943, 92],
                                ],
                                o: [
                                  [-96.761, 44.875],
                                  [25.583, 124.206],
                                  [-52.659, 43.233],
                                  [124.911, 35.376],
                                  [18.864, 67.682],
                                  [110.92, -4.159],
                                  [-126.323, -79.655],
                                  [40.5, -142.83],
                                  [9.943, -92],
                                ],
                                v: [
                                  [-101.181, -386.639],
                                  [-174.656, -190.771],
                                  [-244.331, 27.316],
                                  [-282.701, 147.471],
                                  [-35.331, 293.666],
                                  [126.18, 416.312],
                                  [299.644, 279.791],
                                  [60.684, -110.144],
                                  [281.784, -240.797],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.404, -43.191],
                                  [-25.375, -123.971],
                                  [52.513, -43.377],
                                  [-124.65, -35.404],
                                  [-17.449, -65.834],
                                  [-122.13, 6.624],
                                  [125.97, 79.568],
                                  [-42.403, 140.695],
                                  [-9.982, 92],
                                ],
                                o: [
                                  [-96.697, 44.733],
                                  [25.375, 123.971],
                                  [-52.513, 43.377],
                                  [124.65, 35.404],
                                  [18.881, 67.596],
                                  [110.899, -4.202],
                                  [-125.97, -79.568],
                                  [40.311, -142.567],
                                  [9.982, -92],
                                ],
                                v: [
                                  [-101.272, -387.347],
                                  [-174.723, -191.286],
                                  [-244.358, 27.414],
                                  [-282.783, 146.954],
                                  [-34.761, 294.066],
                                  [126.332, 415.904],
                                  [300.097, 279.597],
                                  [60.659, -110.781],
                                  [280.928, -240.537],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.492, -43.016],
                                  [-25.163, -123.731],
                                  [52.364, -43.524],
                                  [-124.384, -35.432],
                                  [-17.439, -65.711],
                                  [-122.332, 6.717],
                                  [125.611, 79.48],
                                  [-42.251, 140.39],
                                  [-10.021, 92],
                                ],
                                o: [
                                  [-96.631, 44.588],
                                  [25.163, 123.731],
                                  [-52.364, 43.524],
                                  [124.384, 35.432],
                                  [18.898, 67.508],
                                  [110.877, -4.246],
                                  [-125.611, -79.48],
                                  [40.118, -142.299],
                                  [10.021, -92],
                                ],
                                v: [
                                  [-101.422, -387.982],
                                  [-174.788, -191.796],
                                  [-244.502, 27.454],
                                  [-282.807, 146.5],
                                  [-34.205, 294.411],
                                  [126.426, 415.49],
                                  [300.609, 279.411],
                                  [60.59, -111.453],
                                  [280.008, -240.213],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.581, -42.837],
                                  [-24.948, -123.487],
                                  [52.212, -43.674],
                                  [-124.113, -35.46],
                                  [-17.427, -65.586],
                                  [-122.537, 6.81],
                                  [125.245, 79.39],
                                  [-42.097, 140.08],
                                  [-10.062, 92],
                                ],
                                o: [
                                  [-96.564, 44.441],
                                  [24.948, 123.487],
                                  [-52.212, 43.674],
                                  [124.113, 35.46],
                                  [18.916, 67.419],
                                  [110.855, -4.291],
                                  [-125.245, -79.39],
                                  [39.921, -142.027],
                                  [10.062, -92],
                                ],
                                v: [
                                  [-101.624, -388.606],
                                  [-174.85, -192.26],
                                  [-244.638, 27.535],
                                  [-282.773, 146.059],
                                  [-33.662, 294.735],
                                  [126.525, 415.078],
                                  [301.122, 279.165],
                                  [60.485, -112.118],
                                  [279.143, -239.887],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.672, -42.656],
                                  [-24.729, -123.239],
                                  [52.057, -43.826],
                                  [-123.838, -35.489],
                                  [-17.416, -65.459],
                                  [-122.746, 6.906],
                                  [124.874, 79.299],
                                  [-41.941, 139.765],
                                  [-10.102, 92],
                                ],
                                o: [
                                  [-96.496, 44.291],
                                  [24.729, 123.239],
                                  [-52.057, 43.826],
                                  [123.838, 35.489],
                                  [18.934, 67.328],
                                  [110.832, -4.336],
                                  [-124.874, -79.299],
                                  [39.721, -141.75],
                                  [10.102, -92],
                                ],
                                v: [
                                  [-101.766, -389.267],
                                  [-174.915, -192.678],
                                  [-244.765, 27.613],
                                  [-282.736, 145.618],
                                  [-33.188, 295.059],
                                  [126.622, 414.613],
                                  [301.631, 278.921],
                                  [60.385, -112.761],
                                  [278.277, -239.49],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.764, -42.471],
                                  [-24.506, -122.987],
                                  [51.9, -43.98],
                                  [-123.558, -35.519],
                                  [-17.404, -65.33],
                                  [-122.958, 7.003],
                                  [124.496, 79.206],
                                  [-41.781, 139.444],
                                  [-10.144, 92],
                                ],
                                o: [
                                  [-96.427, 44.139],
                                  [24.506, 122.987],
                                  [-51.9, 43.98],
                                  [123.558, 35.519],
                                  [18.953, 67.236],
                                  [110.809, -4.382],
                                  [-124.495, -79.206],
                                  [39.518, -141.468],
                                  [10.144, -92],
                                ],
                                v: [
                                  [-102.023, -389.861],
                                  [-174.93, -193.147],
                                  [-244.954, 27.627],
                                  [-282.649, 145.184],
                                  [-32.728, 295.369],
                                  [126.717, 414.199],
                                  [302.197, 278.726],
                                  [60.298, -113.327],
                                  [277.464, -239.146],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.858, -42.283],
                                  [-24.279, -122.73],
                                  [51.741, -44.138],
                                  [-123.273, -35.549],
                                  [-17.393, -65.198],
                                  [-123.174, 7.101],
                                  [124.111, 79.111],
                                  [-41.619, 139.117],
                                  [-10.186, 92],
                                ],
                                o: [
                                  [-96.356, 43.984],
                                  [24.279, 122.73],
                                  [-51.741, 44.138],
                                  [123.273, 35.549],
                                  [18.972, 67.142],
                                  [110.785, -4.429],
                                  [-124.111, -79.111],
                                  [39.311, -141.182],
                                  [10.186, -92],
                                ],
                                v: [
                                  [-102.268, -390.435],
                                  [-174.886, -193.572],
                                  [-245.142, 27.64],
                                  [-282.504, 144.764],
                                  [-32.282, 295.671],
                                  [126.817, 413.732],
                                  [302.765, 278.485],
                                  [60.169, -113.941],
                                  [276.643, -238.801],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.954, -42.092],
                                  [-24.048, -122.469],
                                  [51.578, -44.298],
                                  [-122.983, -35.579],
                                  [-17.381, -65.064],
                                  [-123.394, 7.202],
                                  [123.719, 79.015],
                                  [-41.454, 138.785],
                                  [-10.229, 92],
                                ],
                                o: [
                                  [-96.284, 43.826],
                                  [24.048, 122.469],
                                  [-51.578, 44.298],
                                  [122.983, 35.579],
                                  [18.991, 67.046],
                                  [110.761, -4.477],
                                  [-123.719, -79.015],
                                  [39.101, -140.89],
                                  [10.229, -92],
                                ],
                                v: [
                                  [-102.524, -390.997],
                                  [-174.902, -193.953],
                                  [-245.374, 27.699],
                                  [-282.304, 144.345],
                                  [-31.913, 295.966],
                                  [126.86, 413.269],
                                  [303.328, 278.183],
                                  [60.006, -114.477],
                                  [275.82, -238.392],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.051, -41.897],
                                  [-23.814, -122.204],
                                  [51.413, -44.461],
                                  [-122.688, -35.61],
                                  [-17.369, -64.928],
                                  [-123.618, 7.304],
                                  [123.321, 78.917],
                                  [-41.287, 138.447],
                                  [-10.273, 92],
                                ],
                                o: [
                                  [-96.212, 43.665],
                                  [23.814, 122.204],
                                  [-51.413, 44.461],
                                  [122.688, 35.61],
                                  [19.01, 66.949],
                                  [110.737, -4.526],
                                  [-123.321, -78.917],
                                  [38.887, -140.594],
                                  [10.273, -92],
                                ],
                                v: [
                                  [-102.823, -391.538],
                                  [-174.86, -194.33],
                                  [-245.66, 27.694],
                                  [-282.109, 143.987],
                                  [-31.551, 296.245],
                                  [126.91, 412.809],
                                  [303.946, 277.938],
                                  [59.904, -114.998],
                                  [275.05, -237.974],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.15, -41.7],
                                  [-23.575, -121.934],
                                  [51.245, -44.627],
                                  [-122.389, -35.642],
                                  [-17.356, -64.79],
                                  [-123.845, 7.408],
                                  [122.917, 78.818],
                                  [-41.116, 138.103],
                                  [-10.318, 92],
                                ],
                                o: [
                                  [-96.137, 43.502],
                                  [23.575, 121.934],
                                  [-51.245, 44.626],
                                  [122.389, 35.642],
                                  [19.03, 66.85],
                                  [110.712, -4.575],
                                  [-122.917, -78.818],
                                  [38.67, -140.292],
                                  [10.318, -92],
                                ],
                                v: [
                                  [-103.118, -392.066],
                                  [-174.767, -194.719],
                                  [-245.95, 27.688],
                                  [-281.851, 143.59],
                                  [-31.259, 296.461],
                                  [127.012, 412.345],
                                  [304.558, 277.688],
                                  [59.708, -115.494],
                                  [274.271, -237.547],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.251, -41.498],
                                  [-23.333, -121.659],
                                  [51.074, -44.795],
                                  [-122.084, -35.674],
                                  [-17.344, -64.649],
                                  [-124.077, 7.513],
                                  [122.505, 78.716],
                                  [-40.942, 137.754],
                                  [-10.363, 92],
                                ],
                                o: [
                                  [-96.062, 43.336],
                                  [23.333, 121.659],
                                  [-51.074, 44.795],
                                  [122.084, 35.674],
                                  [19.05, 66.749],
                                  [110.687, -4.625],
                                  [-122.505, -78.716],
                                  [38.448, -139.985],
                                  [10.363, -92],
                                ],
                                v: [
                                  [-103.471, -392.581],
                                  [-174.725, -195.058],
                                  [-246.285, 27.619],
                                  [-281.546, 143.194],
                                  [-31.035, 296.669],
                                  [127.058, 411.839],
                                  [305.225, 277.392],
                                  [59.588, -115.975],
                                  [273.489, -237.12],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.353, -41.294],
                                  [-23.086, -121.379],
                                  [50.9, -44.966],
                                  [-121.773, -35.706],
                                  [-17.331, -64.506],
                                  [-124.312, 7.621],
                                  [122.086, 78.613],
                                  [-40.766, 137.398],
                                  [-10.409, 92],
                                ],
                                o: [
                                  [-95.985, 43.167],
                                  [23.086, 121.379],
                                  [-50.9, 44.966],
                                  [121.773, 35.706],
                                  [19.071, 66.647],
                                  [110.662, -4.677],
                                  [-122.086, -78.614],
                                  [38.223, -139.673],
                                  [10.409, -92],
                                ],
                                v: [
                                  [-103.811, -393.019],
                                  [-174.634, -195.409],
                                  [-246.616, 27.549],
                                  [-281.194, 142.859],
                                  [-30.826, 296.868],
                                  [127.111, 411.385],
                                  [305.884, 277.082],
                                  [59.421, -116.438],
                                  [272.705, -236.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.457, -41.086],
                                  [-22.835, -121.095],
                                  [50.723, -45.141],
                                  [-121.458, -35.74],
                                  [-17.318, -64.36],
                                  [-124.551, 7.73],
                                  [121.66, 78.509],
                                  [-40.586, 137.037],
                                  [-10.456, 92],
                                ],
                                o: [
                                  [-95.907, 42.996],
                                  [22.835, 121.095],
                                  [-50.723, 45.141],
                                  [121.458, 35.739],
                                  [19.091, 66.543],
                                  [110.636, -4.729],
                                  [-121.66, -78.509],
                                  [37.994, -139.356],
                                  [10.456, -92],
                                ],
                                v: [
                                  [-104.209, -393.443],
                                  [-174.539, -195.703],
                                  [-246.999, 27.47],
                                  [-280.78, 142.479],
                                  [-30.695, 297.05],
                                  [127.163, 410.873],
                                  [306.544, 276.782],
                                  [59.224, -116.876],
                                  [271.91, -236.247],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.563, -40.874],
                                  [-22.579, -120.806],
                                  [50.543, -45.318],
                                  [-121.137, -35.773],
                                  [-17.305, -64.212],
                                  [-124.795, 7.841],
                                  [121.227, 78.402],
                                  [-40.404, 136.669],
                                  [-10.503, 92],
                                ],
                                o: [
                                  [-95.828, 42.821],
                                  [22.579, 120.806],
                                  [-50.543, 45.318],
                                  [121.137, 35.773],
                                  [19.113, 66.437],
                                  [110.609, -4.781],
                                  [-121.227, -78.402],
                                  [37.762, -139.033],
                                  [10.503, -92],
                                ],
                                v: [
                                  [-104.602, -393.798],
                                  [-174.394, -196.011],
                                  [-247.423, 27.383],
                                  [-280.32, 142.16],
                                  [-30.623, 297.178],
                                  [127.214, 410.429],
                                  [307.204, 276.422],
                                  [59.035, -117.243],
                                  [271.105, -235.801],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.671, -40.659],
                                  [-22.32, -120.512],
                                  [50.36, -45.498],
                                  [-120.811, -35.807],
                                  [-17.291, -64.061],
                                  [-125.042, 7.954],
                                  [120.786, 78.294],
                                  [-40.218, 136.295],
                                  [-10.552, 92],
                                ],
                                o: [
                                  [-95.747, 42.644],
                                  [22.32, 120.512],
                                  [-50.36, 45.498],
                                  [120.811, 35.807],
                                  [19.134, 66.329],
                                  [110.582, -4.835],
                                  [-120.786, -78.294],
                                  [37.525, -138.705],
                                  [10.552, -92],
                                ],
                                v: [
                                  [-105.035, -394.184],
                                  [-174.245, -196.28],
                                  [-247.851, 27.242],
                                  [-279.862, 141.841],
                                  [-30.621, 297.289],
                                  [127.218, 409.937],
                                  [307.802, 276.011],
                                  [58.871, -117.583],
                                  [270.359, -235.346],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.78, -40.44],
                                  [-22.056, -120.213],
                                  [50.174, -45.681],
                                  [-120.479, -35.842],
                                  [-17.277, -63.908],
                                  [-125.294, 8.069],
                                  [120.338, 78.184],
                                  [-40.03, 135.915],
                                  [-10.601, 92],
                                ],
                                o: [
                                  [-95.665, 42.463],
                                  [22.056, 120.213],
                                  [-50.174, 45.681],
                                  [120.479, 35.842],
                                  [19.156, 66.22],
                                  [110.555, -4.89],
                                  [-120.338, -78.184],
                                  [37.284, -138.371],
                                  [10.601, -92],
                                ],
                                v: [
                                  [-105.471, -394.448],
                                  [-174.093, -196.546],
                                  [-248.329, 27.048],
                                  [-279.351, 141.53],
                                  [-30.633, 297.39],
                                  [127.285, 409.443],
                                  [308.4, 275.592],
                                  [58.723, -117.904],
                                  [269.548, -234.838],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.891, -40.218],
                                  [-21.787, -119.91],
                                  [49.985, -45.868],
                                  [-120.142, -35.878],
                                  [-17.264, -63.752],
                                  [-125.55, 8.186],
                                  [119.883, 78.072],
                                  [-39.838, 135.528],
                                  [-10.651, 92],
                                ],
                                o: [
                                  [-95.582, 42.279],
                                  [21.787, 119.91],
                                  [-49.985, 45.868],
                                  [120.142, 35.878],
                                  [19.178, 66.109],
                                  [110.527, -4.946],
                                  [-119.883, -78.072],
                                  [37.039, -138.032],
                                  [10.651, -92],
                                ],
                                v: [
                                  [-105.902, -394.75],
                                  [-173.891, -196.773],
                                  [-248.846, 26.837],
                                  [-278.797, 141.228],
                                  [-30.714, 297.482],
                                  [127.35, 409.01],
                                  [308.936, 275.132],
                                  [58.539, -118.198],
                                  [268.725, -234.374],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.004, -39.991],
                                  [-21.514, -119.601],
                                  [49.793, -46.057],
                                  [-119.799, -35.914],
                                  [-17.249, -63.594],
                                  [-125.81, 8.305],
                                  [119.42, 77.958],
                                  [-39.642, 135.135],
                                  [-10.702, 92],
                                ],
                                o: [
                                  [-95.497, 42.093],
                                  [21.514, 119.601],
                                  [-49.793, 46.057],
                                  [119.799, 35.914],
                                  [19.201, 65.996],
                                  [110.499, -5.002],
                                  [-119.42, -77.958],
                                  [36.79, -137.687],
                                  [10.702, -92],
                                ],
                                v: [
                                  [-106.371, -394.931],
                                  [-173.738, -197.008],
                                  [-249.368, 26.583],
                                  [-278.183, 140.987],
                                  [-30.81, 297.565],
                                  [127.363, 408.531],
                                  [309.419, 274.604],
                                  [58.32, -118.419],
                                  [267.899, -233.858],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.119, -39.761],
                                  [-21.237, -119.286],
                                  [49.597, -46.25],
                                  [-119.451, -35.951],
                                  [-17.235, -63.433],
                                  [-126.075, 8.425],
                                  [118.949, 77.842],
                                  [-39.444, 134.735],
                                  [-10.754, 92],
                                ],
                                o: [
                                  [-95.41, 41.903],
                                  [21.237, 119.286],
                                  [-49.597, 46.25],
                                  [119.451, 35.951],
                                  [19.224, 65.881],
                                  [110.47, -5.06],
                                  [-118.949, -77.842],
                                  [36.537, -137.336],
                                  [10.754, -92],
                                ],
                                v: [
                                  [-106.843, -395.096],
                                  [-173.526, -197.259],
                                  [-249.937, 26.269],
                                  [-277.528, 140.692],
                                  [-30.921, 297.638],
                                  [127.437, 408.059],
                                  [309.893, 274.087],
                                  [58.172, -118.665],
                                  [267.113, -233.343],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.236, -39.527],
                                  [-20.955, -118.967],
                                  [49.398, -46.446],
                                  [-119.096, -35.988],
                                  [-17.22, -63.269],
                                  [-126.344, 8.548],
                                  [118.47, 77.725],
                                  [-39.242, 134.329],
                                  [-10.806, 92],
                                ],
                                o: [
                                  [-95.323, 41.71],
                                  [20.955, 118.967],
                                  [-49.398, 46.446],
                                  [119.096, 35.988],
                                  [19.247, 65.764],
                                  [110.441, -5.118],
                                  [-118.47, -77.725],
                                  [36.28, -136.979],
                                  [10.806, -92],
                                ],
                                v: [
                                  [-107.353, -395.235],
                                  [-173.267, -197.403],
                                  [-250.491, 25.895],
                                  [-276.876, 140.449],
                                  [-31.1, 297.7],
                                  [127.458, 407.597],
                                  [310.358, 273.512],
                                  [58, -118.784],
                                  [266.271, -232.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.355, -39.289],
                                  [-20.668, -118.642],
                                  [49.196, -46.645],
                                  [-118.736, -36.026],
                                  [-17.206, -63.103],
                                  [-126.617, 8.673],
                                  [117.983, 77.605],
                                  [-39.037, 133.915],
                                  [-10.86, 92],
                                ],
                                o: [
                                  [-95.234, 41.514],
                                  [20.668, 118.642],
                                  [-49.196, 46.645],
                                  [118.736, 36.026],
                                  [19.271, 65.645],
                                  [110.411, -5.178],
                                  [-117.983, -77.605],
                                  [36.018, -136.616],
                                  [10.86, -92],
                                ],
                                v: [
                                  [-107.803, -395.306],
                                  [-173.054, -197.617],
                                  [-251.091, 25.524],
                                  [-276.174, 140.224],
                                  [-31.295, 297.753],
                                  [127.533, 407.144],
                                  [310.727, 272.877],
                                  [57.847, -118.926],
                                  [265.478, -232.277],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.476, -39.048],
                                  [-20.376, -118.312],
                                  [48.991, -46.848],
                                  [-118.369, -36.064],
                                  [-17.19, -62.933],
                                  [-126.895, 8.8],
                                  [117.488, 77.483],
                                  [-38.829, 133.495],
                                  [-10.914, 92],
                                ],
                                o: [
                                  [-95.143, 41.314],
                                  [20.376, 118.312],
                                  [-48.99, 46.848],
                                  [118.369, 36.064],
                                  [19.295, 65.524],
                                  [110.381, -5.238],
                                  [-117.488, -77.483],
                                  [35.752, -136.248],
                                  [10.914, -92],
                                ],
                                v: [
                                  [-108.247, -395.361],
                                  [-172.794, -197.786],
                                  [-251.694, 25.094],
                                  [-275.433, 140.039],
                                  [-31.558, 297.795],
                                  [127.564, 406.701],
                                  [311.129, 272.245],
                                  [57.714, -118.983],
                                  [264.619, -231.695],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.599, -38.802],
                                  [-20.08, -117.976],
                                  [48.782, -47.053],
                                  [-117.997, -36.103],
                                  [-17.175, -62.761],
                                  [-127.178, 8.929],
                                  [116.985, 77.36],
                                  [-38.617, 133.068],
                                  [-10.97, 92],
                                ],
                                o: [
                                  [-95.051, 41.112],
                                  [20.08, 117.976],
                                  [-48.782, 47.053],
                                  [117.997, 36.103],
                                  [19.32, 65.401],
                                  [110.35, -5.3],
                                  [-116.985, -77.36],
                                  [35.482, -135.873],
                                  [10.97, -92],
                                ],
                                v: [
                                  [-108.632, -395.357],
                                  [-172.528, -197.923],
                                  [-252.333, 24.616],
                                  [-274.635, 139.81],
                                  [-31.827, 297.826],
                                  [127.659, 406.258],
                                  [311.437, 271.617],
                                  [57.6, -119.02],
                                  [263.797, -231.156],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.724, -38.552],
                                  [-19.778, -117.635],
                                  [48.569, -47.263],
                                  [-117.618, -36.143],
                                  [-17.16, -62.586],
                                  [-127.465, 9.06],
                                  [116.474, 77.234],
                                  [-38.401, 132.634],
                                  [-11.026, 92],
                                ],
                                o: [
                                  [-94.957, 40.905],
                                  [19.778, 117.635],
                                  [-48.569, 47.263],
                                  [117.618, 36.143],
                                  [19.345, 65.276],
                                  [110.319, -5.362],
                                  [-116.474, -77.234],
                                  [35.207, -135.492],
                                  [11.026, -92],
                                ],
                                v: [
                                  [-109.053, -395.316],
                                  [-172.204, -198.059],
                                  [-252.923, 24.081],
                                  [-273.852, 139.631],
                                  [-32.121, 297.794],
                                  [127.744, 405.836],
                                  [311.725, 270.941],
                                  [57.518, -119.023],
                                  [262.971, -230.569],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.851, -38.298],
                                  [-19.472, -117.288],
                                  [48.353, -47.475],
                                  [-117.233, -36.184],
                                  [-17.144, -62.409],
                                  [-127.757, 9.194],
                                  [115.954, 77.106],
                                  [-38.182, 132.193],
                                  [-11.083, 92],
                                ],
                                o: [
                                  [-94.862, 40.696],
                                  [19.472, 117.288],
                                  [-48.353, 47.475],
                                  [117.233, 36.184],
                                  [19.37, 65.149],
                                  [110.287, -5.426],
                                  [-115.954, -77.106],
                                  [34.928, -135.104],
                                  [11.083, -92],
                                ],
                                v: [
                                  [-109.363, -395.217],
                                  [-171.936, -198.163],
                                  [-253.547, 23.499],
                                  [-273.021, 139.459],
                                  [-32.422, 297.804],
                                  [127.85, 405.416],
                                  [311.961, 270.206],
                                  [57.406, -118.994],
                                  [262.13, -229.973],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.98, -38.04],
                                  [-19.161, -116.936],
                                  [48.134, -47.692],
                                  [-116.842, -36.225],
                                  [-17.127, -62.228],
                                  [-128.054, 9.329],
                                  [115.425, 76.976],
                                  [-37.959, 131.744],
                                  [-11.141, 92],
                                ],
                                o: [
                                  [-94.765, 40.483],
                                  [19.161, 116.936],
                                  [-48.134, 47.692],
                                  [116.842, 36.225],
                                  [19.396, 65.02],
                                  [110.255, -5.49],
                                  [-115.425, -76.976],
                                  [34.644, -134.711],
                                  [11.141, -92],
                                ],
                                v: [
                                  [-109.666, -395.153],
                                  [-171.599, -198.278],
                                  [-254.214, 22.923],
                                  [-272.135, 139.336],
                                  [-32.8, 297.802],
                                  [127.947, 405.007],
                                  [312.198, 269.465],
                                  [57.367, -118.899],
                                  [261.284, -229.329],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.111, -37.777],
                                  [-18.844, -116.577],
                                  [47.911, -47.911],
                                  [-116.444, -36.267],
                                  [-17.111, -62.044],
                                  [-128.356, 9.467],
                                  [114.888, 76.844],
                                  [-37.733, 131.288],
                                  [-11.2, 92],
                                ],
                                o: [
                                  [-94.667, 40.266],
                                  [18.844, 116.577],
                                  [-47.911, 47.911],
                                  [116.444, 36.267],
                                  [19.422, 64.889],
                                  [110.222, -5.556],
                                  [-114.888, -76.844],
                                  [34.355, -134.311],
                                  [11.2, -92],
                                ],
                                v: [
                                  [-109.963, -394.969],
                                  [-171.277, -198.403],
                                  [-254.885, 22.292],
                                  [-271.214, 139.146],
                                  [-33.236, 297.748],
                                  [128.067, 404.601],
                                  [312.372, 268.687],
                                  [57.361, -118.76],
                                  [260.421, -228.678],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.245, -37.511],
                                  [-18.522, -116.213],
                                  [47.684, -48.135],
                                  [-116.04, -36.309],
                                  [-17.094, -61.857],
                                  [-128.663, 9.607],
                                  [114.342, 76.71],
                                  [-37.503, 130.825],
                                  [-11.26, 92],
                                ],
                                o: [
                                  [-94.566, 40.046],
                                  [18.522, 116.213],
                                  [-47.684, 48.135],
                                  [116.04, 36.309],
                                  [19.449, 64.755],
                                  [110.189, -5.622],
                                  [-114.342, -76.71],
                                  [34.062, -133.904],
                                  [11.26, -92],
                                ],
                                v: [
                                  [-110.191, -394.767],
                                  [-170.936, -198.488],
                                  [-255.484, 21.607],
                                  [-270.299, 139.077],
                                  [-33.647, 297.672],
                                  [128.176, 404.185],
                                  [312.548, 267.903],
                                  [57.378, -118.607],
                                  [259.605, -228.032],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.38, -37.24],
                                  [-18.195, -115.843],
                                  [47.454, -48.362],
                                  [-115.629, -36.352],
                                  [-17.077, -61.668],
                                  [-128.975, 9.749],
                                  [113.787, 76.574],
                                  [-37.269, 130.354],
                                  [-11.321, 92],
                                ],
                                o: [
                                  [-94.465, 39.823],
                                  [18.195, 115.843],
                                  [-47.454, 48.362],
                                  [115.629, 36.352],
                                  [19.476, 64.62],
                                  [110.155, -5.69],
                                  [-113.787, -76.574],
                                  [33.763, -133.49],
                                  [11.321, -92],
                                ],
                                v: [
                                  [-110.413, -394.549],
                                  [-170.548, -198.534],
                                  [-256.177, 20.929],
                                  [-269.353, 138.94],
                                  [-34.115, 297.647],
                                  [128.35, 403.782],
                                  [312.66, 267.112],
                                  [57.418, -118.367],
                                  [258.719, -227.379],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.518, -36.964],
                                  [-17.863, -115.466],
                                  [47.219, -48.593],
                                  [-115.211, -36.396],
                                  [-17.06, -61.475],
                                  [-129.291, 9.894],
                                  [113.223, 76.435],
                                  [-37.031, 129.875],
                                  [-11.383, 92],
                                ],
                                o: [
                                  [-94.362, 39.595],
                                  [17.863, 115.466],
                                  [-47.219, 48.593],
                                  [115.211, 36.396],
                                  [19.504, 64.482],
                                  [110.121, -5.759],
                                  [-113.223, -76.435],
                                  [33.46, -133.07],
                                  [11.383, -92],
                                ],
                                v: [
                                  [-110.616, -394.312],
                                  [-170.203, -198.655],
                                  [-256.822, 20.21],
                                  [-268.403, 138.861],
                                  [-34.65, 297.559],
                                  [128.524, 403.381],
                                  [312.723, 266.275],
                                  [57.494, -118.102],
                                  [257.878, -226.719],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.658, -36.684],
                                  [-17.525, -115.084],
                                  [46.981, -48.827],
                                  [-114.787, -36.441],
                                  [-17.043, -61.279],
                                  [-129.613, 10.041],
                                  [112.65, 76.294],
                                  [-36.79, 129.388],
                                  [-11.446, 92],
                                ],
                                o: [
                                  [-94.256, 39.364],
                                  [17.525, 115.084],
                                  [-46.981, 48.827],
                                  [114.787, 36.441],
                                  [19.532, 64.342],
                                  [110.086, -5.829],
                                  [-112.65, -76.294],
                                  [33.152, -132.643],
                                  [11.446, -92],
                                ],
                                v: [
                                  [-110.761, -393.957],
                                  [-169.862, -198.725],
                                  [-257.445, 19.489],
                                  [-267.473, 138.838],
                                  [-35.151, 297.459],
                                  [128.7, 402.983],
                                  [312.724, 265.392],
                                  [57.582, -117.799],
                                  [257.019, -226.026],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.8, -36.399],
                                  [-17.182, -114.695],
                                  [46.739, -49.066],
                                  [-114.355, -36.486],
                                  [-17.025, -61.08],
                                  [-129.941, 10.19],
                                  [112.068, 76.151],
                                  [-36.544, 128.894],
                                  [-11.51, 92],
                                ],
                                o: [
                                  [-94.15, 39.129],
                                  [17.182, 114.695],
                                  [-46.739, 49.066],
                                  [114.355, 36.486],
                                  [19.56, 64.2],
                                  [110.05, -5.9],
                                  [-112.068, -76.151],
                                  [32.839, -132.209],
                                  [11.51, -92],
                                ],
                                v: [
                                  [-110.849, -393.635],
                                  [-169.485, -198.758],
                                  [-258.072, 18.715],
                                  [-266.489, 138.808],
                                  [-35.719, 297.348],
                                  [128.89, 402.524],
                                  [312.726, 264.564],
                                  [57.707, -117.432],
                                  [256.153, -225.265],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.945, -36.11],
                                  [-16.833, -114.3],
                                  [46.494, -49.308],
                                  [-113.917, -36.532],
                                  [-17.007, -60.877],
                                  [-130.273, 10.342],
                                  [111.475, 76.005],
                                  [-36.295, 128.391],
                                  [-11.575, 92],
                                ],
                                o: [
                                  [-94.041, 38.891],
                                  [16.833, 114.3],
                                  [-46.494, 49.308],
                                  [113.917, 36.532],
                                  [19.589, 64.055],
                                  [110.014, -5.972],
                                  [-111.476, -76.005],
                                  [32.521, -131.768],
                                  [11.575, -92],
                                ],
                                v: [
                                  [-110.919, -393.257],
                                  [-169.151, -198.843],
                                  [-258.726, 17.903],
                                  [-265.476, 138.772],
                                  [-36.292, 297.186],
                                  [129.131, 402.13],
                                  [312.716, 263.679],
                                  [57.857, -117.026],
                                  [255.317, -224.56],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.092, -35.816],
                                  [-16.478, -113.899],
                                  [46.244, -49.554],
                                  [-113.472, -36.579],
                                  [-16.989, -60.671],
                                  [-130.611, 10.496],
                                  [110.874, 75.858],
                                  [-36.042, 127.881],
                                  [-11.641, 92],
                                ],
                                o: [
                                  [-93.931, 38.648],
                                  [16.478, 113.899],
                                  [-46.244, 49.554],
                                  [113.472, 36.579],
                                  [19.618, 63.908],
                                  [109.977, -6.046],
                                  [-110.874, -75.858],
                                  [32.198, -131.32],
                                  [11.641, -92],
                                ],
                                v: [
                                  [-110.969, -392.799],
                                  [-168.77, -198.902],
                                  [-259.32, 17.102],
                                  [-264.523, 138.79],
                                  [-36.944, 297.05],
                                  [129.324, 401.727],
                                  [312.607, 262.749],
                                  [58.096, -116.581],
                                  [254.435, -223.814],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.241, -35.518],
                                  [-16.118, -113.491],
                                  [45.99, -49.804],
                                  [-113.019, -36.627],
                                  [-16.97, -60.462],
                                  [-130.955, 10.653],
                                  [110.263, 75.707],
                                  [-35.784, 127.362],
                                  [-11.708, 92],
                                ],
                                o: [
                                  [-93.819, 38.402],
                                  [16.118, 113.491],
                                  [-45.99, 49.804],
                                  [113.019, 36.627],
                                  [19.648, 63.759],
                                  [109.94, -6.121],
                                  [-110.263, -75.707],
                                  [31.869, -130.864],
                                  [11.708, -92],
                                ],
                                v: [
                                  [-110.962, -392.335],
                                  [-168.456, -198.963],
                                  [-259.966, 16.253],
                                  [-263.494, 138.8],
                                  [-37.55, 296.927],
                                  [129.568, 401.338],
                                  [312.498, 261.862],
                                  [58.298, -116.072],
                                  [253.583, -223.075],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.393, -35.214],
                                  [-15.752, -113.077],
                                  [45.732, -50.058],
                                  [-112.559, -36.675],
                                  [-16.951, -60.25],
                                  [-131.304, 10.812],
                                  [109.642, 75.555],
                                  [-35.522, 126.835],
                                  [-11.777, 92],
                                ],
                                o: [
                                  [-93.705, 38.152],
                                  [15.752, 113.077],
                                  [-45.732, 50.058],
                                  [112.559, 36.675],
                                  [19.679, 63.607],
                                  [109.902, -6.196],
                                  [-109.642, -75.555],
                                  [31.536, -130.402],
                                  [11.777, -92],
                                ],
                                v: [
                                  [-110.947, -391.854],
                                  [-168.145, -199.025],
                                  [-260.553, 15.417],
                                  [-262.512, 138.814],
                                  [-38.236, 296.73],
                                  [129.864, 400.89],
                                  [312.378, 260.944],
                                  [58.589, -115.534],
                                  [252.723, -222.27],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.547, -34.906],
                                  [-15.38, -112.656],
                                  [45.47, -50.316],
                                  [-112.092, -36.724],
                                  [-16.932, -60.034],
                                  [-131.658, 10.974],
                                  [109.011, 75.4],
                                  [-35.256, 126.299],
                                  [-11.846, 92],
                                ],
                                o: [
                                  [-93.59, 37.897],
                                  [15.38, 112.656],
                                  [-45.47, 50.316],
                                  [112.092, 36.724],
                                  [19.709, 63.453],
                                  [109.863, -6.273],
                                  [-109.011, -75.4],
                                  [31.196, -129.932],
                                  [11.846, -92],
                                ],
                                v: [
                                  [-110.863, -391.306],
                                  [-167.826, -199.065],
                                  [-261.115, 14.582],
                                  [-261.492, 138.882],
                                  [-38.926, 296.583],
                                  [130.124, 400.493],
                                  [312.21, 260.019],
                                  [58.908, -114.956],
                                  [251.889, -221.487],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.704, -34.593],
                                  [-15.002, -112.228],
                                  [45.204, -50.579],
                                  [-111.617, -36.774],
                                  [-16.912, -59.815],
                                  [-132.018, 11.139],
                                  [108.369, 75.242],
                                  [-34.986, 125.755],
                                  [-11.917, 92],
                                ],
                                o: [
                                  [-93.472, 37.639],
                                  [15.002, 112.228],
                                  [-45.204, 50.579],
                                  [111.617, 36.774],
                                  [19.741, 63.296],
                                  [109.824, -6.352],
                                  [-108.369, -75.242],
                                  [30.852, -129.454],
                                  [11.917, -92],
                                ],
                                v: [
                                  [-110.758, -390.74],
                                  [-167.524, -199.092],
                                  [-261.729, 13.7],
                                  [-260.498, 138.926],
                                  [-39.668, 296.374],
                                  [130.423, 400.051],
                                  [311.98, 259.064],
                                  [59.254, -114.313],
                                  [251.011, -220.701],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.863, -34.274],
                                  [-14.619, -111.794],
                                  [44.933, -50.845],
                                  [-111.135, -36.825],
                                  [-16.892, -59.592],
                                  [-132.384, 11.306],
                                  [107.718, 75.082],
                                  [-34.712, 125.202],
                                  [-11.988, 92],
                                ],
                                o: [
                                  [-93.353, 37.376],
                                  [14.619, 111.794],
                                  [-44.933, 50.845],
                                  [111.135, 36.825],
                                  [19.773, 63.137],
                                  [109.784, -6.431],
                                  [-107.718, -75.082],
                                  [30.502, -128.968],
                                  [11.988, -92],
                                ],
                                v: [
                                  [-110.597, -390.107],
                                  [-167.226, -199.147],
                                  [-262.284, 12.785],
                                  [-259.502, 139.036],
                                  [-40.379, 296.153],
                                  [130.687, 399.647],
                                  [311.703, 258.15],
                                  [59.691, -113.628],
                                  [250.159, -219.864],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.024, -33.951],
                                  [-14.228, -111.352],
                                  [44.658, -51.116],
                                  [-110.645, -36.877],
                                  [-16.872, -59.366],
                                  [-132.756, 11.476],
                                  [107.056, 74.919],
                                  [-34.433, 124.64],
                                  [-12.061, 92],
                                ],
                                o: [
                                  [-93.232, 37.11],
                                  [14.229, 111.352],
                                  [-44.658, 51.116],
                                  [110.645, 36.877],
                                  [19.805, 62.976],
                                  [109.744, -6.512],
                                  [-107.056, -74.919],
                                  [30.146, -128.475],
                                  [12.061, -92],
                                ],
                                v: [
                                  [-110.414, -389.421],
                                  [-166.981, -199.203],
                                  [-262.827, 11.874],
                                  [-258.47, 139.135],
                                  [-41.155, 295.92],
                                  [130.988, 399.21],
                                  [311.428, 257.195],
                                  [60.142, -112.866],
                                  [249.297, -219.024],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.189, -33.623],
                                  [-13.832, -110.903],
                                  [44.379, -51.391],
                                  [-110.147, -36.929],
                                  [-16.851, -59.136],
                                  [-133.134, 11.648],
                                  [106.384, 74.754],
                                  [-34.149, 124.069],
                                  [-12.135, 92],
                                ],
                                o: [
                                  [-93.108, 36.839],
                                  [13.832, 110.903],
                                  [-44.379, 51.391],
                                  [110.147, 36.929],
                                  [19.838, 62.811],
                                  [109.703, -6.594],
                                  [-106.384, -74.754],
                                  [29.785, -127.974],
                                  [12.135, -92],
                                ],
                                v: [
                                  [-110.175, -388.766],
                                  [-166.691, -199.274],
                                  [-263.31, 10.98],
                                  [-257.514, 139.271],
                                  [-41.948, 295.689],
                                  [131.338, 398.811],
                                  [311.154, 256.246],
                                  [60.622, -112.088],
                                  [248.459, -218.195],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.356, -33.289],
                                  [-13.43, -110.448],
                                  [44.096, -51.671],
                                  [-109.641, -36.982],
                                  [-16.831, -58.902],
                                  [-133.518, 11.823],
                                  [105.701, 74.586],
                                  [-33.862, 123.489],
                                  [-12.21, 92],
                                ],
                                o: [
                                  [-92.983, 36.563],
                                  [13.43, 110.448],
                                  [-44.096, 51.671],
                                  [109.641, 36.982],
                                  [19.871, 62.644],
                                  [109.661, -6.678],
                                  [-105.701, -74.586],
                                  [29.418, -127.465],
                                  [12.21, -92],
                                ],
                                v: [
                                  [-109.914, -387.998],
                                  [-166.454, -199.298],
                                  [-263.845, 10.09],
                                  [-256.509, 139.408],
                                  [-42.744, 295.444],
                                  [131.656, 398.367],
                                  [310.772, 255.304],
                                  [61.146, -111.218],
                                  [247.624, -217.332],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.525, -32.95],
                                  [-13.021, -109.985],
                                  [43.807, -51.955],
                                  [-109.127, -37.036],
                                  [-16.809, -58.665],
                                  [-133.908, 12.001],
                                  [105.007, 74.415],
                                  [-33.569, 122.9],
                                  [-12.286, 92],
                                ],
                                o: [
                                  [-92.856, 36.284],
                                  [13.021, 109.985],
                                  [-43.807, 51.954],
                                  [109.127, 37.036],
                                  [19.905, 62.475],
                                  [109.619, -6.763],
                                  [-105.007, -74.415],
                                  [29.045, -126.949],
                                  [12.286, -92],
                                ],
                                v: [
                                  [-109.596, -387.227],
                                  [-166.269, -199.384],
                                  [-264.321, 9.171],
                                  [-255.532, 139.546],
                                  [-43.605, 295.203],
                                  [132.008, 397.973],
                                  [310.391, 254.369],
                                  [61.748, -110.333],
                                  [246.748, -216.42],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.697, -32.605],
                                  [-12.605, -109.514],
                                  [43.515, -52.243],
                                  [-108.605, -37.091],
                                  [-16.788, -58.424],
                                  [-134.304, 12.182],
                                  [104.302, 74.242],
                                  [-33.272, 122.302],
                                  [-12.364, 92],
                                ],
                                o: [
                                  [-92.727, 36],
                                  [12.605, 109.514],
                                  [-43.515, 52.243],
                                  [108.605, 37.091],
                                  [19.939, 62.303],
                                  [109.576, -6.849],
                                  [-104.302, -74.242],
                                  [28.666, -126.423],
                                  [12.364, -92],
                                ],
                                v: [
                                  [-109.272, -386.438],
                                  [-166.04, -199.423],
                                  [-264.786, 8.211],
                                  [-254.555, 139.718],
                                  [-44.42, 294.901],
                                  [132.362, 397.535],
                                  [310.014, 253.394],
                                  [62.379, -109.403],
                                  [245.876, -215.556],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.872, -32.256],
                                  [-12.183, -109.037],
                                  [43.217, -52.536],
                                  [-108.075, -37.147],
                                  [-16.766, -58.179],
                                  [-134.706, 12.366],
                                  [103.586, 74.066],
                                  [-32.97, 121.694],
                                  [-12.442, 92],
                                ],
                                o: [
                                  [-92.596, 35.711],
                                  [12.183, 109.037],
                                  [-43.217, 52.536],
                                  [108.075, 37.147],
                                  [19.974, 62.128],
                                  [109.532, -6.936],
                                  [-103.586, -74.066],
                                  [28.281, -125.89],
                                  [12.443, -92],
                                ],
                                v: [
                                  [-108.923, -385.585],
                                  [-165.862, -199.478],
                                  [-265.192, 7.304],
                                  [-253.608, 139.875],
                                  [-45.299, 294.634],
                                  [132.716, 397.133],
                                  [309.529, 252.474],
                                  [63.088, -108.411],
                                  [245.023, -214.596],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.05, -31.9],
                                  [-11.755, -108.551],
                                  [42.915, -52.833],
                                  [-107.536, -37.203],
                                  [-16.744, -57.93],
                                  [-135.115, 12.552],
                                  [102.859, 73.887],
                                  [-32.664, 121.077],
                                  [-12.522, 92],
                                ],
                                o: [
                                  [-92.463, 35.418],
                                  [11.755, 108.551],
                                  [-42.915, 52.833],
                                  [107.536, 37.203],
                                  [20.01, 61.95],
                                  [109.488, -7.025],
                                  [-102.859, -73.887],
                                  [27.89, -125.348],
                                  [12.522, -92],
                                ],
                                v: [
                                  [-108.52, -384.715],
                                  [-165.735, -199.532],
                                  [-265.635, 6.357],
                                  [-252.707, 140.079],
                                  [-46.195, 294.324],
                                  [133.071, 396.686],
                                  [309.093, 251.562],
                                  [63.812, -107.388],
                                  [244.158, -213.7],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.23, -31.539],
                                  [-11.319, -108.059],
                                  [42.609, -53.136],
                                  [-106.989, -37.261],
                                  [-16.721, -57.678],
                                  [-135.53, 12.742],
                                  [102.12, 73.706],
                                  [-32.353, 120.45],
                                  [-12.604, 92],
                                ],
                                o: [
                                  [-92.327, 35.12],
                                  [11.319, 108.059],
                                  [-42.609, 53.136],
                                  [106.989, 37.261],
                                  [20.046, 61.77],
                                  [109.442, -7.115],
                                  [-102.12, -73.706],
                                  [27.493, -124.798],
                                  [12.604, -92],
                                ],
                                v: [
                                  [-108.046, -383.843],
                                  [-165.611, -199.602],
                                  [-266.034, 5.432],
                                  [-251.79, 140.283],
                                  [-47.092, 294.001],
                                  [133.457, 396.226],
                                  [308.567, 250.61],
                                  [64.63, -106.287],
                                  [243.325, -212.758],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.414, -31.173],
                                  [-10.877, -107.558],
                                  [42.297, -53.443],
                                  [-106.434, -37.319],
                                  [-16.698, -57.421],
                                  [-135.951, 12.934],
                                  [101.37, 73.521],
                                  [-32.037, 119.813],
                                  [-12.686, 92],
                                ],
                                o: [
                                  [-92.19, 34.818],
                                  [10.877, 107.558],
                                  [-42.297, 53.443],
                                  [106.434, 37.319],
                                  [20.083, 61.586],
                                  [109.397, -7.207],
                                  [-101.37, -73.521],
                                  [27.09, -124.239],
                                  [12.686, -92],
                                ],
                                v: [
                                  [-107.564, -382.862],
                                  [-165.49, -199.687],
                                  [-266.377, 4.514],
                                  [-250.874, 140.485],
                                  [-48.006, 293.682],
                                  [133.814, 395.815],
                                  [308.044, 249.698],
                                  [65.478, -105.109],
                                  [242.417, -211.817],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.6, -30.801],
                                  [-10.428, -107.05],
                                  [41.981, -53.754],
                                  [-105.87, -37.379],
                                  [-16.675, -57.16],
                                  [-136.379, 13.13],
                                  [100.608, 73.334],
                                  [-31.716, 119.166],
                                  [-12.77, 92],
                                ],
                                o: [
                                  [-92.05, 34.51],
                                  [10.428, 107.05],
                                  [-41.98, 53.755],
                                  [105.87, 37.379],
                                  [20.12, 61.4],
                                  [109.35, -7.3],
                                  [-100.607, -73.334],
                                  [26.681, -123.671],
                                  [12.77, -92],
                                ],
                                v: [
                                  [-107.057, -381.91],
                                  [-165.419, -199.818],
                                  [-266.709, 3.605],
                                  [-250.019, 140.717],
                                  [-48.936, 293.368],
                                  [134.201, 395.361],
                                  [307.525, 248.763],
                                  [66.403, -103.947],
                                  [241.54, -210.849],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.789, -30.422],
                                  [-9.972, -106.533],
                                  [41.659, -54.071],
                                  [-105.297, -37.439],
                                  [-16.651, -56.896],
                                  [-136.814, 13.328],
                                  [99.833, 73.144],
                                  [-31.389, 118.509],
                                  [-12.855, 92],
                                ],
                                o: [
                                  [-91.908, 34.199],
                                  [9.972, 106.533],
                                  [-41.659, 54.071],
                                  [105.297, 37.439],
                                  [20.158, 61.211],
                                  [109.303, -7.394],
                                  [-99.834, -73.144],
                                  [26.265, -123.094],
                                  [12.855, -92],
                                ],
                                v: [
                                  [-106.496, -380.912],
                                  [-165.35, -199.902],
                                  [-267.03, 2.674],
                                  [-249.135, 140.975],
                                  [-49.867, 293.026],
                                  [134.587, 394.91],
                                  [306.947, 247.838],
                                  [67.377, -102.678],
                                  [240.676, -209.838],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.981, -30.039],
                                  [-9.509, -106.009],
                                  [41.333, -54.393],
                                  [-104.715, -37.5],
                                  [-16.627, -56.627],
                                  [-137.256, 13.53],
                                  [99.048, 72.951],
                                  [-31.058, 117.842],
                                  [-12.941, 92],
                                ],
                                o: [
                                  [-91.764, 33.882],
                                  [9.509, 106.009],
                                  [-41.333, 54.393],
                                  [104.715, 37.5],
                                  [20.196, 61.019],
                                  [109.255, -7.49],
                                  [-99.048, -72.951],
                                  [25.842, -122.509],
                                  [12.941, -92],
                                ],
                                v: [
                                  [-105.864, -379.853],
                                  [-165.33, -200.001],
                                  [-267.295, 1.754],
                                  [-248.312, 141.229],
                                  [-50.877, 292.706],
                                  [135.002, 394.491],
                                  [306.345, 246.967],
                                  [68.412, -101.379],
                                  [239.814, -208.876],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.176, -29.649],
                                  [-9.039, -105.476],
                                  [41.001, -54.719],
                                  [-104.124, -37.562],
                                  [-16.603, -56.354],
                                  [-137.704, 13.734],
                                  [98.249, 72.754],
                                  [-30.722, 117.164],
                                  [-13.029, 92],
                                ],
                                o: [
                                  [-91.618, 33.56],
                                  [9.039, 105.476],
                                  [-41.001, 54.719],
                                  [104.124, 37.562],
                                  [20.235, 60.824],
                                  [109.206, -7.588],
                                  [-98.249, -72.754],
                                  [25.413, -121.914],
                                  [13.029, -92],
                                ],
                                v: [
                                  [-105.223, -378.777],
                                  [-165.357, -200.098],
                                  [-267.567, 0.826],
                                  [-247.552, 141.481],
                                  [-51.824, 292.375],
                                  [135.416, 394.028],
                                  [305.73, 246.105],
                                  [69.524, -100.048],
                                  [238.9, -207.872],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.374, -29.253],
                                  [-8.561, -104.936],
                                  [40.665, -55.051],
                                  [-103.523, -37.625],
                                  [-16.578, -56.077],
                                  [-138.16, 13.942],
                                  [97.439, 72.555],
                                  [-30.38, 116.476],
                                  [-13.118, 92],
                                ],
                                o: [
                                  [-91.47, 33.233],
                                  [8.561, 104.936],
                                  [-40.665, 55.051],
                                  [103.523, 37.625],
                                  [20.275, 60.626],
                                  [109.157, -7.687],
                                  [-97.439, -72.555],
                                  [24.978, -121.31],
                                  [13.118, -92],
                                ],
                                v: [
                                  [-104.556, -377.704],
                                  [-165.385, -200.256],
                                  [-267.765, -0.075],
                                  [-246.807, 141.774],
                                  [-52.85, 292.005],
                                  [135.783, 393.596],
                                  [305.074, 245.207],
                                  [70.667, -98.641],
                                  [237.986, -206.828],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.575, -28.85],
                                  [-8.076, -104.386],
                                  [40.323, -55.388],
                                  [-102.914, -37.689],
                                  [-16.553, -55.795],
                                  [-138.622, 14.154],
                                  [96.616, 72.353],
                                  [-30.034, 115.778],
                                  [-13.209, 92],
                                ],
                                o: [
                                  [-91.319, 32.902],
                                  [8.076, 104.386],
                                  [-40.323, 55.388],
                                  [102.914, 37.689],
                                  [20.315, 60.425],
                                  [109.106, -7.787],
                                  [-96.616, -72.353],
                                  [24.535, -120.697],
                                  [13.209, -92],
                                ],
                                v: [
                                  [-103.836, -376.588],
                                  [-165.442, -200.41],
                                  [-267.971, -0.947],
                                  [-246.08, 142.089],
                                  [-53.831, 291.625],
                                  [136.239, 393.12],
                                  [304.424, 244.381],
                                  [71.887, -97.22],
                                  [237.082, -205.807],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.779, -28.442],
                                  [-7.583, -103.829],
                                  [39.976, -55.73],
                                  [-102.295, -37.755],
                                  [-16.528, -55.509],
                                  [-139.092, 14.368],
                                  [95.78, 72.147],
                                  [-29.681, 115.068],
                                  [-13.301, 92],
                                ],
                                o: [
                                  [-91.166, 32.565],
                                  [7.583, 103.829],
                                  [-39.976, 55.73],
                                  [102.295, 37.755],
                                  [20.356, 60.221],
                                  [109.055, -7.889],
                                  [-95.78, -72.147],
                                  [24.086, -120.074],
                                  [13.301, -92],
                                ],
                                v: [
                                  [-103.088, -375.413],
                                  [-165.517, -200.579],
                                  [-268.123, -1.826],
                                  [-245.351, 142.398],
                                  [-54.872, 291.297],
                                  [136.63, 392.673],
                                  [303.735, 243.547],
                                  [73.184, -95.724],
                                  [236.158, -204.773],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.986, -28.027],
                                  [-7.083, -103.263],
                                  [39.623, -56.077],
                                  [-101.667, -37.821],
                                  [-16.502, -55.219],
                                  [-139.568, 14.586],
                                  [94.931, 71.939],
                                  [-29.324, 114.348],
                                  [-13.394, 92],
                                ],
                                o: [
                                  [-91.01, 32.223],
                                  [7.083, 103.263],
                                  [-39.623, 56.077],
                                  [101.667, 37.821],
                                  [20.397, 60.014],
                                  [109.003, -7.993],
                                  [-94.931, -71.939],
                                  [23.63, -119.442],
                                  [13.394, -92],
                                ],
                                v: [
                                  [-102.287, -374.268],
                                  [-165.637, -200.744],
                                  [-268.265, -2.694],
                                  [-244.726, 142.683],
                                  [-55.887, 290.914],
                                  [137, 392.184],
                                  [303.051, 242.741],
                                  [74.556, -94.197],
                                  [235.241, -203.72],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.197, -27.606],
                                  [-6.575, -102.688],
                                  [39.266, -56.43],
                                  [-101.029, -37.888],
                                  [-16.475, -54.925],
                                  [-140.053, 14.807],
                                  [94.07, 71.727],
                                  [-28.961, 113.616],
                                  [-13.489, 92],
                                ],
                                o: [
                                  [-90.852, 31.875],
                                  [6.575, 102.688],
                                  [-39.266, 56.43],
                                  [101.029, 37.888],
                                  [20.439, 59.803],
                                  [108.951, -8.098],
                                  [-94.07, -71.727],
                                  [23.167, -118.8],
                                  [13.489, -92],
                                ],
                                v: [
                                  [-101.458, -373.06],
                                  [-165.801, -200.924],
                                  [-268.335, -3.55],
                                  [-244.075, 143.004],
                                  [-56.962, 290.559],
                                  [137.43, 391.721],
                                  [302.331, 241.945],
                                  [75.985, -92.613],
                                  [234.259, -202.674],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.41, -27.179],
                                  [-6.06, -102.104],
                                  [38.902, -56.788],
                                  [-100.381, -37.956],
                                  [-16.449, -54.625],
                                  [-140.544, 15.031],
                                  [93.195, 71.512],
                                  [-28.592, 112.874],
                                  [-13.585, 92],
                                ],
                                o: [
                                  [-90.692, 31.523],
                                  [6.06, 102.104],
                                  [-38.902, 56.788],
                                  [100.381, 37.956],
                                  [20.482, 59.589],
                                  [108.897, -8.205],
                                  [-93.195, -71.512],
                                  [22.697, -118.148],
                                  [13.585, -92],
                                ],
                                v: [
                                  [-100.6, -371.862],
                                  [-165.988, -201.142],
                                  [-268.414, -4.394],
                                  [-243.526, 143.362],
                                  [-57.993, 290.195],
                                  [137.818, 391.259],
                                  [301.617, 241.204],
                                  [77.464, -90.956],
                                  [233.343, -201.592],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.627, -26.745],
                                  [-5.536, -101.511],
                                  [38.533, -57.151],
                                  [-99.723, -38.025],
                                  [-16.422, -54.322],
                                  [-141.043, 15.259],
                                  [92.306, 71.294],
                                  [-28.218, 112.12],
                                  [-13.682, 92],
                                ],
                                o: [
                                  [-90.529, 31.165],
                                  [5.536, 101.511],
                                  [-38.533, 57.151],
                                  [99.723, 38.025],
                                  [20.525, 59.373],
                                  [108.843, -8.314],
                                  [-92.306, -71.294],
                                  [22.22, -117.486],
                                  [13.682, -92],
                                ],
                                v: [
                                  [-99.689, -370.627],
                                  [-166.194, -201.416],
                                  [-268.417, -5.207],
                                  [-242.993, 143.711],
                                  [-59.083, 289.842],
                                  [138.222, 390.797],
                                  [300.909, 240.43],
                                  [79.06, -89.288],
                                  [232.362, -200.518],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.848, -26.304],
                                  [-5.005, -100.91],
                                  [38.159, -57.52],
                                  [-99.055, -38.095],
                                  [-16.394, -54.013],
                                  [-141.55, 15.49],
                                  [91.404, 71.072],
                                  [-27.838, 111.354],
                                  [-13.781, 92],
                                ],
                                o: [
                                  [-90.364, 30.801],
                                  [5.005, 100.909],
                                  [-38.159, 57.52],
                                  [99.055, 38.095],
                                  [20.57, 59.152],
                                  [108.788, -8.424],
                                  [-91.404, -71.072],
                                  [21.735, -116.814],
                                  [13.781, -92],
                                ],
                                v: [
                                  [-98.706, -369.357],
                                  [-166.44, -201.684],
                                  [-268.435, -6.026],
                                  [-242.52, 144.074],
                                  [-60.147, 289.48],
                                  [138.625, 390.317],
                                  [300.144, 239.729],
                                  [80.688, -87.569],
                                  [231.381, -199.41],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.071, -25.857],
                                  [-4.465, -100.299],
                                  [37.779, -57.895],
                                  [-98.377, -38.167],
                                  [-16.366, -53.7],
                                  [-142.064, 15.725],
                                  [90.489, 70.847],
                                  [-27.452, 110.577],
                                  [-13.882, 92],
                                ],
                                o: [
                                  [-90.196, 30.432],
                                  [4.465, 100.299],
                                  [-37.779, 57.895],
                                  [98.377, 38.167],
                                  [20.614, 58.929],
                                  [108.732, -8.536],
                                  [-90.489, -70.847],
                                  [21.243, -116.132],
                                  [13.882, -92],
                                ],
                                v: [
                                  [-97.734, -368.138],
                                  [-166.749, -201.944],
                                  [-268.36, -6.831],
                                  [-242.084, 144.427],
                                  [-61.272, 289.088],
                                  [139, 389.817],
                                  [299.41, 239.04],
                                  [82.412, -85.817],
                                  [230.376, -198.292],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.299, -25.403],
                                  [-3.917, -99.678],
                                  [37.393, -58.275],
                                  [-97.689, -38.239],
                                  [-16.338, -53.382],
                                  [-142.587, 15.963],
                                  [89.559, 70.618],
                                  [-27.06, 109.788],
                                  [-13.984, 92],
                                ],
                                o: [
                                  [-90.026, 30.057],
                                  [3.917, 99.678],
                                  [-37.393, 58.275],
                                  [97.689, 38.239],
                                  [20.66, 58.701],
                                  [108.675, -8.649],
                                  [-89.559, -70.618],
                                  [20.743, -115.44],
                                  [13.984, -92],
                                ],
                                v: [
                                  [-96.708, -366.823],
                                  [-167.053, -202.258],
                                  [-268.276, -7.645],
                                  [-241.705, 144.769],
                                  [-62.372, 288.75],
                                  [139.371, 389.317],
                                  [298.642, 238.384],
                                  [84.228, -83.993],
                                  [229.369, -197.204],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.529, -24.942],
                                  [-3.361, -99.049],
                                  [37.001, -58.661],
                                  [-96.99, -38.312],
                                  [-16.309, -53.059],
                                  [-143.117, 16.206],
                                  [88.616, 70.386],
                                  [-26.662, 108.987],
                                  [-14.088, 92],
                                ],
                                o: [
                                  [-89.853, 29.677],
                                  [3.361, 99.049],
                                  [-37.001, 58.661],
                                  [96.99, 38.312],
                                  [20.706, 58.471],
                                  [108.618, -8.764],
                                  [-88.616, -70.386],
                                  [20.236, -114.736],
                                  [14.088, -92],
                                ],
                                v: [
                                  [-95.61, -365.561],
                                  [-167.416, -202.604],
                                  [-268.143, -8.424],
                                  [-241.381, 145.162],
                                  [-63.49, 288.385],
                                  [139.757, 388.857],
                                  [297.904, 237.781],
                                  [86.053, -82.14],
                                  [228.335, -196.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.763, -24.474],
                                  [-2.797, -98.41],
                                  [36.603, -59.053],
                                  [-96.281, -38.387],
                                  [-16.28, -52.732],
                                  [-143.655, 16.451],
                                  [87.658, 70.151],
                                  [-26.259, 108.173],
                                  [-14.193, 92],
                                ],
                                o: [
                                  [-89.678, 29.291],
                                  [2.797, 98.41],
                                  [-36.603, 59.053],
                                  [96.281, 38.387],
                                  [20.753, 58.237],
                                  [108.559, -8.881],
                                  [-87.658, -70.151],
                                  [19.721, -114.023],
                                  [14.193, -92],
                                ],
                                v: [
                                  [-94.479, -364.247],
                                  [-167.753, -202.96],
                                  [-268.023, -9.21],
                                  [-241.114, 145.564],
                                  [-64.646, 288.076],
                                  [140.134, 388.335],
                                  [297.135, 237.191],
                                  [87.909, -80.237],
                                  [227.275, -195.017],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.001, -23.999],
                                  [-2.224, -97.761],
                                  [36.199, -59.451],
                                  [-95.561, -38.463],
                                  [-16.25, -52.399],
                                  [-144.201, 16.701],
                                  [86.685, 69.912],
                                  [-25.849, 107.348],
                                  [-14.3, 92],
                                ],
                                o: [
                                  [-89.5, 28.899],
                                  [2.224, 97.761],
                                  [-36.199, 59.451],
                                  [95.561, 38.463],
                                  [20.8, 58],
                                  [108.5, -9],
                                  [-86.685, -69.912],
                                  [19.199, -113.298],
                                  [14.3, -92],
                                ],
                                v: [
                                  [-93.336, -362.926],
                                  [-168.166, -203.345],
                                  [-267.834, -9.942],
                                  [-240.878, 145.932],
                                  [-65.8, 287.721],
                                  [140.422, 387.79],
                                  [296.416, 236.635],
                                  [89.836, -78.305],
                                  [226.229, -193.879],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.242, -23.517],
                                  [-1.642, -97.102],
                                  [35.789, -59.855],
                                  [-94.83, -38.54],
                                  [-16.22, -52.062],
                                  [-144.756, 16.954],
                                  [85.698, 69.669],
                                  [-25.433, 106.51],
                                  [-14.409, 92],
                                ],
                                o: [
                                  [-89.319, 28.501],
                                  [1.642, 97.102],
                                  [-35.789, 59.855],
                                  [94.83, 38.54],
                                  [20.848, 57.758],
                                  [108.44, -9.121],
                                  [-85.698, -69.669],
                                  [18.668, -112.563],
                                  [14.409, -92],
                                ],
                                v: [
                                  [-92.158, -361.637],
                                  [-168.613, -203.758],
                                  [-267.597, -10.723],
                                  [-240.758, 146.325],
                                  [-66.951, 287.382],
                                  [140.662, 387.203],
                                  [295.65, 236.154],
                                  [91.855, -76.263],
                                  [225.092, -192.773],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.486, -23.027],
                                  [-1.052, -96.434],
                                  [35.373, -60.265],
                                  [-94.088, -38.618],
                                  [-16.189, -51.719],
                                  [-145.319, 17.211],
                                  [84.696, 69.423],
                                  [-25.011, 105.66],
                                  [-14.519, 92],
                                ],
                                o: [
                                  [-89.135, 28.097],
                                  [1.052, 96.434],
                                  [-35.373, 60.265],
                                  [94.088, 38.618],
                                  [20.897, 57.514],
                                  [108.378, -9.243],
                                  [-84.696, -69.423],
                                  [18.13, -111.816],
                                  [14.519, -92],
                                ],
                                v: [
                                  [-90.905, -360.301],
                                  [-169.091, -204.218],
                                  [-267.354, -11.427],
                                  [-240.605, 146.682],
                                  [-68.143, 287.06],
                                  [140.873, 386.553],
                                  [294.913, 235.688],
                                  [93.821, -74.239],
                                  [224.007, -191.661],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.735, -22.53],
                                  [-0.452, -95.756],
                                  [34.951, -60.681],
                                  [-93.335, -38.697],
                                  [-16.158, -51.371],
                                  [-145.89, 17.472],
                                  [83.679, 69.173],
                                  [-24.582, 104.797],
                                  [-14.631, 92],
                                ],
                                o: [
                                  [-88.949, 27.688],
                                  [0.452, 95.756],
                                  [-34.951, 60.681],
                                  [93.335, 38.697],
                                  [20.947, 57.265],
                                  [108.316, -9.367],
                                  [-83.679, -69.173],
                                  [17.583, -111.059],
                                  [14.631, -92],
                                ],
                                v: [
                                  [-89.639, -358.963],
                                  [-169.6, -204.725],
                                  [-267.025, -12.163],
                                  [-240.526, 147.085],
                                  [-69.314, 286.758],
                                  [140.975, 385.902],
                                  [294.172, 235.236],
                                  [95.918, -72.164],
                                  [222.851, -190.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.987, -22.026],
                                  [0.156, -95.067],
                                  [34.522, -61.103],
                                  [-92.571, -38.777],
                                  [-16.127, -51.018],
                                  [-146.47, 17.736],
                                  [82.647, 68.919],
                                  [-24.148, 103.92],
                                  [-14.744, 92],
                                ],
                                o: [
                                  [-88.76, 27.272],
                                  [-0.156, 95.067],
                                  [-34.522, 61.103],
                                  [92.571, 38.777],
                                  [20.997, 57.013],
                                  [108.253, -9.493],
                                  [-82.647, -68.919],
                                  [17.029, -110.29],
                                  [14.744, -92],
                                ],
                                v: [
                                  [-88.336, -357.682],
                                  [-170.116, -205.214],
                                  [-266.753, -12.886],
                                  [-240.473, 147.448],
                                  [-70.522, 286.437],
                                  [141.031, 385.172],
                                  [293.466, 234.884],
                                  [98.046, -70.006],
                                  [221.68, -189.392],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.243, -21.514],
                                  [0.773, -94.368],
                                  [34.087, -61.532],
                                  [-91.795, -38.859],
                                  [-16.095, -50.66],
                                  [-147.058, 18.005],
                                  [81.6, 68.662],
                                  [-23.706, 103.031],
                                  [-14.859, 92],
                                ],
                                o: [
                                  [-88.568, 26.849],
                                  [-0.773, 94.368],
                                  [-34.087, 61.532],
                                  [91.795, 38.859],
                                  [21.049, 56.757],
                                  [108.189, -9.621],
                                  [-81.6, -68.662],
                                  [16.466, -109.509],
                                  [14.859, -92],
                                ],
                                v: [
                                  [-86.956, -356.357],
                                  [-170.682, -205.763],
                                  [-266.374, -13.557],
                                  [-240.43, 147.817],
                                  [-71.734, 286.177],
                                  [141.057, 384.396],
                                  [292.775, 234.547],
                                  [100.18, -67.845],
                                  [220.477, -188.236],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.503, -20.995],
                                  [1.4, -93.659],
                                  [33.646, -61.967],
                                  [-91.008, -38.941],
                                  [-16.062, -50.297],
                                  [-147.656, 18.278],
                                  [80.536, 68.401],
                                  [-23.258, 102.129],
                                  [-14.976, 92],
                                ],
                                o: [
                                  [-88.373, 26.421],
                                  [-1.4, 93.659],
                                  [-33.646, 61.967],
                                  [91.008, 38.941],
                                  [21.101, 56.497],
                                  [108.124, -9.751],
                                  [-80.537, -68.401],
                                  [15.894, -108.717],
                                  [14.976, -92],
                                ],
                                v: [
                                  [-85.562, -355.014],
                                  [-171.25, -206.315],
                                  [-265.953, -14.24],
                                  [-240.435, 148.145],
                                  [-73.005, 285.876],
                                  [140.975, 383.644],
                                  [292.097, 234.226],
                                  [102.368, -65.659],
                                  [219.241, -187.079],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [115.766, -20.468],
                                  [2.036, -92.939],
                                  [33.198, -62.408],
                                  [-90.209, -39.025],
                                  [-16.029, -49.927],
                                  [-148.262, 18.554],
                                  [79.458, 68.135],
                                  [-22.803, 101.213],
                                  [-15.095, 92],
                                ],
                                o: [
                                  [-88.175, 25.986],
                                  [-2.036, 92.939],
                                  [-33.198, 62.408],
                                  [90.209, 39.025],
                                  [21.153, 56.234],
                                  [108.058, -9.883],
                                  [-79.458, -68.135],
                                  [15.315, -107.913],
                                  [15.095, -92],
                                ],
                                v: [
                                  [-84.128, -353.73],
                                  [-171.842, -206.883],
                                  [-265.527, -14.934],
                                  [-240.463, 148.455],
                                  [-74.259, 285.662],
                                  [140.849, 382.805],
                                  [291.446, 234.009],
                                  [104.601, -63.396],
                                  [218.009, -185.952],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.034, -19.933],
                                  [2.681, -92.208],
                                  [32.743, -62.856],
                                  [-89.398, -39.111],
                                  [-15.996, -49.553],
                                  [-148.877, 18.835],
                                  [78.363, 67.866],
                                  [-22.342, 100.283],
                                  [-15.215, 92],
                                ],
                                o: [
                                  [-87.975, 25.545],
                                  [-2.681, 92.208],
                                  [-32.743, 62.856],
                                  [89.398, 39.111],
                                  [21.207, 55.966],
                                  [107.992, -10.017],
                                  [-78.363, -67.866],
                                  [14.726, -107.098],
                                  [15.215, -92],
                                ],
                                v: [
                                  [-82.654, -352.432],
                                  [-172.42, -207.427],
                                  [-265.073, -15.616],
                                  [-240.536, 148.746],
                                  [-75.534, 285.411],
                                  [140.655, 381.953],
                                  [290.825, 233.846],
                                  [106.901, -61.109],
                                  [216.729, -184.778],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.305, -19.39],
                                  [3.336, -91.467],
                                  [32.281, -63.311],
                                  [-88.575, -39.197],
                                  [-15.962, -49.173],
                                  [-149.502, 19.12],
                                  [77.251, 67.593],
                                  [-21.874, 99.34],
                                  [-15.337, 92],
                                ],
                                o: [
                                  [-87.771, 25.097],
                                  [-3.336, 91.467],
                                  [-32.281, 63.311],
                                  [88.575, 39.197],
                                  [21.261, 55.695],
                                  [107.924, -10.153],
                                  [-77.251, -67.593],
                                  [14.129, -106.27],
                                  [15.337, -92],
                                ],
                                v: [
                                  [-81.163, -351.157],
                                  [-173.019, -207.972],
                                  [-264.565, -16.299],
                                  [-240.616, 148.993],
                                  [-76.83, 285.186],
                                  [140.418, 381.015],
                                  [290.208, 233.751],
                                  [109.231, -58.823],
                                  [215.453, -183.619],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.58, -18.839],
                                  [4, -90.715],
                                  [31.813, -63.772],
                                  [-87.74, -39.285],
                                  [-15.927, -48.787],
                                  [-150.135, 19.41],
                                  [76.124, 67.316],
                                  [-21.399, 98.383],
                                  [-15.461, 92],
                                ],
                                o: [
                                  [-87.565, 24.642],
                                  [-4, 90.715],
                                  [-31.813, 63.772],
                                  [87.74, 39.285],
                                  [21.316, 55.42],
                                  [107.855, -10.29],
                                  [-76.124, -67.316],
                                  [13.523, -105.429],
                                  [15.461, -92],
                                ],
                                v: [
                                  [-79.629, -349.873],
                                  [-173.638, -208.591],
                                  [-264.054, -16.973],
                                  [-240.704, 149.281],
                                  [-78.138, 284.99],
                                  [140.151, 380.077],
                                  [289.634, 233.675],
                                  [111.59, -56.503],
                                  [214.14, -182.476],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [116.86, -18.28],
                                  [4.675, -89.951],
                                  [31.338, -64.241],
                                  [-86.893, -39.374],
                                  [-15.892, -48.396],
                                  [-150.778, 19.703],
                                  [74.979, 67.035],
                                  [-20.916, 97.411],
                                  [-15.587, 92],
                                ],
                                o: [
                                  [-87.355, 24.181],
                                  [-4.675, 89.951],
                                  [-31.338, 64.241],
                                  [86.893, 39.374],
                                  [21.372, 55.14],
                                  [107.785, -10.43],
                                  [-74.979, -67.035],
                                  [12.908, -104.577],
                                  [15.587, -92],
                                ],
                                v: [
                                  [-78.014, -348.652],
                                  [-174.239, -209.182],
                                  [-263.479, -17.662],
                                  [-240.809, 149.512],
                                  [-79.47, 284.849],
                                  [139.779, 379.14],
                                  [289.08, 233.654],
                                  [113.953, -54.125],
                                  [212.855, -181.322],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [12.875, 15.375],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.344, 14.781],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.875, 14.188],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.438, 13.625],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.062, 13],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.312, 11.938],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.031, 11.438],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.688, 10.969],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.469, 10.469],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.25, 10.062],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.031, 9.625],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.844, 9.25],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.719, 8.875],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.625, 8.5],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.531, 8.156],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.469, 7.844],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.438, 7.531],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.438, 7.031],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.5, 6.75],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.531, 6.5],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.625, 6.281],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.75, 6.062],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.844, 5.812],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.062, 5.625],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.188, 5.406],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.406, 5.281],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.625, 5.094],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.812, 4.906],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.062, 4.719],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.938, 4.562],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.688, 4.188],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.562, 4.031],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.531, 3.844],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.5, 3.719],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.438, 3.5],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.469, 3.344],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.906, 3.156],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.438, 2.969],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11, 2.75],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.531, 2.531],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.094, 2.344],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.594, 2.125],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.656, 1.75],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.188, 1.625],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.75, 1.5],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.281, 1.375],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.406, 1.125],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.938, 1.094],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.5, 1.031],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.062, 1],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.25, 0.938],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.812, 0.938],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.406, 0.906],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.969, 0.938],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.594, 1],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.219, 1.031],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 1.094],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.406, 1.156],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24, 1.219],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 1.344],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.25, 1.438],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.438, 1.688],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, 1.844],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.656, 1.938],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.906, 2.312],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.469, 2.5],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.062, 2.625],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.688, 2.875],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.281, 3.062],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, 3.312],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.969, 3.75],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.469, 4.031],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34, 4.281],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.531, 4.562],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35, 4.844],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.5, 5.125],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.969, 5.438],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.094, 5.75],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.25, 6.031],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.344, 6.375],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.469, 6.75],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.594, 7.156],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.656, 7.594],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.781, 8],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.812, 8.469],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.875, 8.969],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.938, 9.406],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37, 9.938],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 10.469],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 11],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, 11.531],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, 12.094],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, 12.688],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 13.25],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 13.781],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 14.375],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37, 14.938],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.969, 15.469],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.906, 16.031],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.844, 17.156],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 17.656],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 18.219],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 19.281],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.5, 19.75],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.406, 20.219],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.312, 20.719],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.188, 21.125],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.188, 21.938],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.688, 22.281],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.938, 22.688],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 23.031],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.406, 23.312],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.562, 23.594],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 23.844],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.938, 24.062],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.062, 24.25],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.219, 24.375],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.344, 24.5],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.469, 24.594],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.562, 24.688],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.688, 24.719],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.75, 24.781],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.812, 24.781],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.875, 24.719],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21, 24.688],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.062, 24.656],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.125, 24.594],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.25, 24.469],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.344, 24.312],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.469, 24.156],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.562, 23.969],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.656, 23.75],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.781, 23.531],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.094, 22.969],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.219, 22.656],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.406, 22.312],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.594, 21.938],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.781, 21.594],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.562, 21.219],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.375, 20.781],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.281, 20.375],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.125, 19.938],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.031, 19.438],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8, 19],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.938, 18.5],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.906, 18],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.938, 17.469],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8, 16.969],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.062, 16.438],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.188, 15.906],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.312, 15.344],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.469, 14.844],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.656, 14.25],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.906, 13.75],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.188, 13.219],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.438, 12.688],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.781, 12.156],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.125, 11.656],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 11.125],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.906, 10.625],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.344, 10.156],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.844, 9.625],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.344, 9.188],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.906, 8.719],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.438, 8.312],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.969, 7.875],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.5, 7.5],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.062, 7.125],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.625, 6.781],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.188, 6.469],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.75, 6.188],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.281, 5.906],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.844, 5.656],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.406, 5.438],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.938, 5.25],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.469, 5.125],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.031, 4.938],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.594, 4.844],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.125, 4.719],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.188, 4.531],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.625, 4.531],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.125, 4.438],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.594, 4.438],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.062, 4.469],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.5, 4.5],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.938, 4.5],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.344, 4.594],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.125, 4.656],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.688, 4.75],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, 4.875],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.906, 5.031],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.438, 5.188],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 5.375],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.594, 5.562],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.719, 6],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.25, 6.219],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.844, 6.469],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.406, 6.719],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.969, 7],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.094, 7.625],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.656, 7.969],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.219, 8.281],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 8.969],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17, 9.344],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.594, 9.688],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.25, 10.094],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.844, 10.469],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.531, 10.844],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.188, 11.25],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.875, 11.656],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.562, 12.031],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.312, 12.438],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14, 12.781],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.781, 13.125],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.594, 13.469],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.344, 13.75],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.188, 14],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.094, 14.312],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.969, 14.531],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.875, 14.75],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.812, 14.906],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.781, 15.094],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.781, 15.25],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [12.875, 15.375],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.344, 14.781],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.875, 14.188],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.438, 13.625],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.062, 13],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.312, 11.938],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.031, 11.438],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.688, 10.969],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.469, 10.469],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.25, 10.062],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.031, 9.625],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.844, 9.25],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.719, 8.875],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.625, 8.5],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.531, 8.156],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.469, 7.844],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.438, 7.531],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.438, 7.031],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.5, 6.75],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.531, 6.5],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.625, 6.281],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.75, 6.062],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.844, 5.812],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.062, 5.625],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.188, 5.406],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.406, 5.281],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.625, 5.094],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.812, 4.906],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.062, 4.719],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.938, 4.562],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.688, 4.188],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.562, 4.031],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.531, 3.844],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.5, 3.719],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.438, 3.5],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.469, 3.344],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.906, 3.156],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.438, 2.969],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11, 2.75],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.531, 2.531],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.094, 2.344],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.594, 2.125],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.656, 1.75],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.188, 1.625],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.75, 1.5],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.281, 1.375],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.406, 1.125],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.938, 1.094],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.5, 1.031],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.062, 1],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.25, 0.938],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.812, 0.938],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.406, 0.906],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.969, 0.938],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.594, 1],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.219, 1.031],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, 1.094],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.406, 1.156],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24, 1.219],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.594, 1.344],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.25, 1.438],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.438, 1.688],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, 1.844],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.656, 1.938],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.906, 2.312],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.469, 2.5],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.062, 2.625],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.688, 2.875],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.281, 3.062],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, 3.312],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.969, 3.75],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.469, 4.031],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34, 4.281],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.531, 4.562],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35, 4.844],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.5, 5.125],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.969, 5.438],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.094, 5.75],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.25, 6.031],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.344, 6.375],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.469, 6.75],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.594, 7.156],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.656, 7.594],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.781, 8],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.812, 8.469],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.875, 8.969],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.938, 9.406],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37, 9.938],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 10.469],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 11],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, 11.531],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, 12.094],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, 12.688],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 13.25],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 13.781],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 14.375],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37, 14.938],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.969, 15.469],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.906, 16.031],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.844, 17.156],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 17.656],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 18.219],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 19.281],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.5, 19.75],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.406, 20.219],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.312, 20.719],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.188, 21.125],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.188, 21.938],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.688, 22.281],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.938, 22.688],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 23.031],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.406, 23.312],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.562, 23.594],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, 23.844],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.938, 24.062],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.062, 24.25],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.219, 24.375],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.344, 24.5],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.469, 24.594],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.562, 24.688],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.688, 24.719],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.75, 24.781],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.812, 24.781],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.875, 24.719],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21, 24.688],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.062, 24.656],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.125, 24.594],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.25, 24.469],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.344, 24.312],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.469, 24.156],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.562, 23.969],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.656, 23.75],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.781, 23.531],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.094, 22.969],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.219, 22.656],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.406, 22.312],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.594, 21.938],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.781, 21.594],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.562, 21.219],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.375, 20.781],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.281, 20.375],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.125, 19.938],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.031, 19.438],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8, 19],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.938, 18.5],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.906, 18],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.938, 17.469],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8, 16.969],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.062, 16.438],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.188, 15.906],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.312, 15.344],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.469, 14.844],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.656, 14.25],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.906, 13.75],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.188, 13.219],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.438, 12.688],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.781, 12.156],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.125, 11.656],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 11.125],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.906, 10.625],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.344, 10.156],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.844, 9.625],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.344, 9.188],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.906, 8.719],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.438, 8.312],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.969, 7.875],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.5, 7.5],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.062, 7.125],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.625, 6.781],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.188, 6.469],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.75, 6.188],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.281, 5.906],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.844, 5.656],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.406, 5.438],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.938, 5.25],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.469, 5.125],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.031, 4.938],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.594, 4.844],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.125, 4.719],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.188, 4.531],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.625, 4.531],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.125, 4.438],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.594, 4.438],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.062, 4.469],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.5, 4.5],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.938, 4.5],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.344, 4.594],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.125, 4.656],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.688, 4.75],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, 4.875],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.906, 5.031],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.438, 5.188],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.031, 5.375],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.594, 5.562],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.719, 6],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.25, 6.219],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.844, 6.469],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.406, 6.719],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.969, 7],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.094, 7.625],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.656, 7.969],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.219, 8.281],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 8.969],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17, 9.344],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.594, 9.688],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.25, 10.094],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.844, 10.469],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.531, 10.844],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.188, 11.25],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.875, 11.656],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.562, 12.031],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.312, 12.438],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14, 12.781],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.781, 13.125],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.594, 13.469],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.344, 13.75],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.188, 14],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.094, 14.312],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.969, 14.531],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.875, 14.75],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.812, 14.906],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.781, 15.094],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.781, 15.25],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 9',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 9,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [114.287, -23.427],
                                  [-1.534, -96.98],
                                  [35.713, -59.93],
                                  [-94.694, -38.554],
                                  [-16.214, -51.999],
                                  [-144.859, 17.001],
                                  [85.515, 69.624],
                                  [-25.356, 106.354],
                                  [-14.429, 92],
                                ],
                                o: [
                                  [-89.285, 28.427],
                                  [1.534, 96.98],
                                  [-35.713, 59.93],
                                  [94.694, 38.554],
                                  [20.857, 57.714],
                                  [108.428, -9.143],
                                  [-85.515, -69.624],
                                  [18.57, -112.426],
                                  [14.429, -92],
                                ],
                                v: [
                                  [-86.774, -359.207],
                                  [-173.567, -202.382],
                                  [-265.191, -13.1],
                                  [-239.889, 149.455],
                                  [-65.156, 290.896],
                                  [141.107, 383.034],
                                  [284.122, 231.545],
                                  [94.029, -73.146],
                                  [224.278, -187.842],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.021, -23.958],
                                  [-2.175, -97.705],
                                  [36.165, -59.485],
                                  [-95.499, -38.469],
                                  [-16.247, -52.371],
                                  [-144.248, 16.722],
                                  [86.602, 69.891],
                                  [-25.814, 107.277],
                                  [-14.309, 92],
                                ],
                                o: [
                                  [-89.484, 28.866],
                                  [2.175, 97.705],
                                  [-36.165, 59.485],
                                  [95.499, 38.469],
                                  [20.804, 57.979],
                                  [108.495, -9.01],
                                  [-86.602, -69.891],
                                  [19.154, -113.236],
                                  [14.309, -92],
                                ],
                                v: [
                                  [-87.149, -359.927],
                                  [-174.071, -201.538],
                                  [-264.881, -12.485],
                                  [-239.927, 149.979],
                                  [-63.477, 291.724],
                                  [140.772, 382.764],
                                  [282.849, 231.275],
                                  [92.545, -74.432],
                                  [225.778, -188.085],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.76, -24.481],
                                  [-2.805, -98.419],
                                  [36.609, -59.047],
                                  [-96.291, -38.386],
                                  [-16.28, -52.736],
                                  [-143.647, 16.448],
                                  [87.671, 70.154],
                                  [-26.265, 108.185],
                                  [-14.192, 92],
                                ],
                                o: [
                                  [-89.68, 29.297],
                                  [2.805, 98.419],
                                  [-36.609, 59.047],
                                  [96.291, 38.386],
                                  [20.752, 58.24],
                                  [108.56, -8.88],
                                  [-87.671, -70.154],
                                  [19.729, -114.033],
                                  [14.192, -92],
                                ],
                                v: [
                                  [-87.49, -360.621],
                                  [-174.533, -200.777],
                                  [-264.534, -11.881],
                                  [-239.937, 150.527],
                                  [-61.756, 292.507],
                                  [140.35, 382.484],
                                  [281.59, 231.021],
                                  [91.06, -75.667],
                                  [227.275, -188.338],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.503, -24.995],
                                  [-3.425, -99.121],
                                  [37.045, -58.617],
                                  [-97.07, -38.304],
                                  [-16.312, -53.096],
                                  [-143.056, 16.178],
                                  [88.723, 70.413],
                                  [-26.708, 109.078],
                                  [-14.076, 92],
                                ],
                                o: [
                                  [-89.873, 29.72],
                                  [3.425, 99.121],
                                  [-37.045, 58.617],
                                  [97.07, 38.304],
                                  [20.701, 58.497],
                                  [108.624, -8.751],
                                  [-88.723, -70.413],
                                  [20.294, -114.817],
                                  [14.076, -92],
                                ],
                                v: [
                                  [-87.861, -361.288],
                                  [-175.056, -200.013],
                                  [-264.189, -11.225],
                                  [-239.985, 151.099],
                                  [-60.013, 293.226],
                                  [139.941, 382.171],
                                  [280.385, 230.761],
                                  [89.634, -76.873],
                                  [228.768, -188.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.25, -25.5],
                                  [-4.034, -99.811],
                                  [37.475, -58.194],
                                  [-97.836, -38.224],
                                  [-16.344, -53.45],
                                  [-142.475, 15.913],
                                  [89.757, 70.667],
                                  [-27.144, 109.956],
                                  [-13.963, 92],
                                ],
                                o: [
                                  [-90.062, 30.137],
                                  [4.034, 99.811],
                                  [-37.475, 58.194],
                                  [97.836, 38.223],
                                  [20.65, 58.75],
                                  [108.688, -8.625],
                                  [-89.757, -70.667],
                                  [20.85, -115.587],
                                  [13.963, -92],
                                ],
                                v: [
                                  [-88.221, -361.949],
                                  [-175.6, -199.249],
                                  [-263.766, -10.598],
                                  [-240.028, 151.673],
                                  [-58.293, 293.923],
                                  [139.444, 381.849],
                                  [279.214, 230.557],
                                  [88.268, -78.008],
                                  [230.237, -188.609],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.002, -25.997],
                                  [-4.633, -100.489],
                                  [37.897, -57.778],
                                  [-98.589, -38.144],
                                  [-16.375, -53.798],
                                  [-141.904, 15.652],
                                  [90.774, 70.917],
                                  [-27.572, 110.819],
                                  [-13.851, 92],
                                ],
                                o: [
                                  [-90.249, 30.547],
                                  [4.633, 100.489],
                                  [-37.897, 57.778],
                                  [98.589, 38.144],
                                  [20.6, 58.998],
                                  [108.75, -8.501],
                                  [-90.774, -70.917],
                                  [21.396, -116.345],
                                  [13.851, -92],
                                ],
                                v: [
                                  [-88.548, -362.522],
                                  [-176.122, -198.567],
                                  [-263.305, -9.936],
                                  [-240.09, 152.267],
                                  [-56.573, 294.556],
                                  [138.899, 381.517],
                                  [278.056, 230.389],
                                  [86.921, -79.134],
                                  [231.762, -188.713],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.757, -26.485],
                                  [-5.223, -101.156],
                                  [38.312, -57.369],
                                  [-99.329, -38.066],
                                  [-16.405, -54.14],
                                  [-141.342, 15.395],
                                  [91.774, 71.163],
                                  [-27.993, 111.668],
                                  [-13.741, 92],
                                ],
                                o: [
                                  [-90.432, 30.95],
                                  [5.223, 101.156],
                                  [-38.312, 57.369],
                                  [99.329, 38.066],
                                  [20.551, 59.243],
                                  [108.811, -8.379],
                                  [-91.774, -71.163],
                                  [21.934, -117.09],
                                  [13.741, -92],
                                ],
                                v: [
                                  [-88.949, -363.008],
                                  [-176.706, -197.884],
                                  [-262.827, -9.28],
                                  [-240.149, 152.839],
                                  [-54.813, 295.189],
                                  [138.284, 381.114],
                                  [276.891, 230.259],
                                  [85.612, -80.19],
                                  [233.263, -188.749],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.517, -26.966],
                                  [-5.802, -101.812],
                                  [38.721, -56.966],
                                  [-100.057, -37.99],
                                  [-16.435, -54.476],
                                  [-140.79, 15.143],
                                  [92.758, 71.405],
                                  [-28.408, 112.503],
                                  [-13.633, 92],
                                ],
                                o: [
                                  [-90.612, 31.347],
                                  [5.802, 101.812],
                                  [-38.721, 56.966],
                                  [100.057, 37.99],
                                  [20.503, 59.483],
                                  [108.871, -8.259],
                                  [-92.758, -71.405],
                                  [22.462, -117.822],
                                  [13.633, -92],
                                ],
                                v: [
                                  [-89.315, -363.451],
                                  [-177.265, -197.222],
                                  [-262.29, -8.625],
                                  [-240.25, 153.387],
                                  [-53.099, 295.758],
                                  [137.662, 380.745],
                                  [275.824, 230.144],
                                  [84.321, -81.258],
                                  [234.798, -188.762],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.281, -27.438],
                                  [-6.372, -102.457],
                                  [39.122, -56.571],
                                  [-100.773, -37.915],
                                  [-16.465, -54.807],
                                  [-140.247, 14.895],
                                  [93.724, 71.642],
                                  [-28.815, 113.323],
                                  [-13.526, 92],
                                ],
                                o: [
                                  [-90.789, 31.736],
                                  [6.372, 102.457],
                                  [-39.122, 56.571],
                                  [100.773, 37.915],
                                  [20.456, 59.719],
                                  [108.93, -8.141],
                                  [-93.724, -71.642],
                                  [22.982, -118.543],
                                  [13.526, -92],
                                ],
                                v: [
                                  [-89.733, -363.814],
                                  [-177.823, -196.623],
                                  [-261.737, -7.912],
                                  [-240.33, 153.953],
                                  [-51.388, 296.288],
                                  [136.971, 380.323],
                                  [274.707, 230.025],
                                  [83.131, -82.235],
                                  [236.265, -188.709],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.049, -27.902],
                                  [-6.932, -103.091],
                                  [39.517, -56.182],
                                  [-101.477, -37.841],
                                  [-16.494, -55.132],
                                  [-139.712, 14.651],
                                  [94.675, 71.876],
                                  [-29.216, 114.13],
                                  [-13.422, 92],
                                ],
                                o: [
                                  [-90.963, 32.119],
                                  [6.932, 103.091],
                                  [-39.517, 56.182],
                                  [101.477, 37.841],
                                  [20.41, 59.951],
                                  [108.988, -8.024],
                                  [-94.675, -71.876],
                                  [23.492, -119.251],
                                  [13.422, -92],
                                ],
                                v: [
                                  [-90.157, -364.18],
                                  [-178.418, -195.981],
                                  [-261.167, -7.178],
                                  [-240.434, 154.556],
                                  [-49.7, 296.798],
                                  [136.23, 379.937],
                                  [273.708, 229.944],
                                  [81.915, -83.206],
                                  [237.787, -188.676],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.821, -28.359],
                                  [-7.483, -103.715],
                                  [39.905, -55.8],
                                  [-102.169, -37.768],
                                  [-16.522, -55.451],
                                  [-139.187, 14.412],
                                  [95.609, 72.105],
                                  [-29.609, 114.923],
                                  [-13.319, 92],
                                ],
                                o: [
                                  [-91.135, 32.496],
                                  [7.483, 103.715],
                                  [-39.905, 55.8],
                                  [102.169, 37.768],
                                  [20.364, 60.179],
                                  [109.045, -7.91],
                                  [-95.609, -72.105],
                                  [23.995, -119.947],
                                  [13.319, -92],
                                ],
                                v: [
                                  [-90.569, -364.426],
                                  [-179.012, -195.402],
                                  [-260.537, -6.421],
                                  [-240.558, 155.086],
                                  [-48.035, 297.227],
                                  [135.482, 379.48],
                                  [272.641, 229.904],
                                  [80.736, -84.106],
                                  [239.304, -188.584],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.596, -28.808],
                                  [-8.024, -104.328],
                                  [40.286, -55.424],
                                  [-102.849, -37.696],
                                  [-16.55, -55.765],
                                  [-138.671, 14.176],
                                  [96.528, 72.331],
                                  [-29.997, 115.703],
                                  [-13.218, 92],
                                ],
                                o: [
                                  [-91.303, 32.866],
                                  [8.024, 104.328],
                                  [-40.286, 55.424],
                                  [102.849, 37.696],
                                  [20.319, 60.404],
                                  [109.101, -7.798],
                                  [-96.528, -72.331],
                                  [24.488, -120.632],
                                  [13.218, -92],
                                ],
                                v: [
                                  [-90.987, -364.68],
                                  [-179.596, -194.844],
                                  [-259.917, -5.664],
                                  [-240.664, 155.693],
                                  [-46.356, 297.699],
                                  [134.682, 379.016],
                                  [271.674, 229.88],
                                  [79.62, -84.977],
                                  [240.787, -188.426],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.376, -29.249],
                                  [-8.556, -104.93],
                                  [40.661, -55.054],
                                  [-103.518, -37.626],
                                  [-16.578, -56.074],
                                  [-138.164, 13.944],
                                  [97.431, 72.553],
                                  [-30.377, 116.47],
                                  [-13.119, 92],
                                ],
                                o: [
                                  [-91.468, 33.23],
                                  [8.556, 104.93],
                                  [-40.661, 55.054],
                                  [103.518, 37.626],
                                  [20.275, 60.624],
                                  [109.156, -7.688],
                                  [-97.431, -72.553],
                                  [24.974, -121.304],
                                  [13.119, -92],
                                ],
                                v: [
                                  [-91.437, -364.817],
                                  [-180.178, -194.304],
                                  [-259.257, -4.864],
                                  [-240.816, 156.225],
                                  [-44.674, 298.073],
                                  [133.812, 378.606],
                                  [270.72, 229.878],
                                  [78.513, -85.8],
                                  [242.308, -188.275],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.159, -29.683],
                                  [-9.08, -105.523],
                                  [41.03, -54.691],
                                  [-104.175, -37.557],
                                  [-16.605, -56.378],
                                  [-137.665, 13.717],
                                  [98.319, 72.772],
                                  [-30.751, 117.223],
                                  [-13.021, 92],
                                ],
                                o: [
                                  [-91.631, 33.588],
                                  [9.08, 105.523],
                                  [-41.03, 54.691],
                                  [104.175, 37.557],
                                  [20.232, 60.841],
                                  [109.21, -7.579],
                                  [-98.319, -72.772],
                                  [25.451, -121.966],
                                  [13.021, -92],
                                ],
                                v: [
                                  [-91.893, -364.949],
                                  [-180.731, -193.767],
                                  [-258.535, -4.081],
                                  [-240.952, 156.769],
                                  [-43.024, 298.474],
                                  [132.935, 378.126],
                                  [269.805, 229.938],
                                  [77.451, -86.64],
                                  [243.794, -188.088],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.945, -30.109],
                                  [-9.594, -106.105],
                                  [41.393, -54.334],
                                  [-104.821, -37.489],
                                  [-16.632, -56.676],
                                  [-137.175, 13.493],
                                  [99.192, 72.986],
                                  [-31.119, 117.964],
                                  [-12.925, 92],
                                ],
                                o: [
                                  [-91.791, 33.94],
                                  [9.594, 106.105],
                                  [-41.393, 54.334],
                                  [104.821, 37.489],
                                  [20.189, 61.055],
                                  [109.264, -7.473],
                                  [-99.192, -72.986],
                                  [25.92, -122.616],
                                  [12.925, -92],
                                ],
                                v: [
                                  [-92.354, -365.093],
                                  [-181.344, -193.265],
                                  [-257.826, -3.252],
                                  [-241.09, 157.325],
                                  [-41.415, 298.814],
                                  [132.005, 377.684],
                                  [268.947, 229.996],
                                  [76.398, -87.39],
                                  [245.255, -187.911],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.736, -30.528],
                                  [-10.1, -106.677],
                                  [41.749, -53.983],
                                  [-105.457, -37.422],
                                  [-16.658, -56.97],
                                  [-136.693, 13.273],
                                  [100.05, 73.197],
                                  [-31.48, 118.693],
                                  [-12.831, 92],
                                ],
                                o: [
                                  [-91.948, 34.286],
                                  [10.1, 106.677],
                                  [-41.749, 53.983],
                                  [105.457, 37.422],
                                  [20.147, 61.264],
                                  [109.316, -7.368],
                                  [-100.05, -73.197],
                                  [26.381, -123.255],
                                  [12.831, -92],
                                ],
                                v: [
                                  [-92.847, -365.128],
                                  [-181.881, -192.766],
                                  [-257.12, -2.394],
                                  [-241.276, 157.864],
                                  [-39.811, 299.137],
                                  [131.068, 377.19],
                                  [268.085, 230.035],
                                  [75.361, -88.14],
                                  [246.725, -187.672],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.53, -30.94],
                                  [-10.597, -107.24],
                                  [42.099, -53.638],
                                  [-106.081, -37.356],
                                  [-16.684, -57.258],
                                  [-136.219, 13.056],
                                  [100.893, 73.404],
                                  [-31.836, 119.408],
                                  [-12.738, 92],
                                ],
                                o: [
                                  [-92.103, 34.626],
                                  [10.597, 107.24],
                                  [-42.099, 53.638],
                                  [106.081, 37.356],
                                  [20.106, 61.47],
                                  [109.368, -7.265],
                                  [-100.893, -73.404],
                                  [26.834, -123.884],
                                  [12.738, -92],
                                ],
                                v: [
                                  [-93.345, -365.179],
                                  [-182.476, -192.284],
                                  [-256.338, -1.517],
                                  [-241.449, 158.367],
                                  [-38.184, 299.411],
                                  [130.061, 376.718],
                                  [267.264, 230.135],
                                  [74.36, -88.817],
                                  [248.203, -187.4],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.327, -31.345],
                                  [-11.085, -107.793],
                                  [42.443, -53.298],
                                  [-106.695, -37.292],
                                  [-16.709, -57.542],
                                  [-135.753, 12.844],
                                  [101.722, 73.608],
                                  [-32.185, 120.112],
                                  [-12.647, 92],
                                ],
                                o: [
                                  [-92.254, 34.96],
                                  [11.085, 107.793],
                                  [-42.443, 53.298],
                                  [106.695, 37.292],
                                  [20.065, 61.673],
                                  [109.418, -7.164],
                                  [-101.722, -73.608],
                                  [27.28, -124.501],
                                  [12.647, -92],
                                ],
                                v: [
                                  [-93.831, -365.17],
                                  [-182.977, -191.821],
                                  [-255.587, -0.625],
                                  [-241.641, 158.832],
                                  [-36.609, 299.687],
                                  [129.062, 376.256],
                                  [266.502, 230.235],
                                  [73.438, -89.497],
                                  [249.655, -187.142],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.128, -31.743],
                                  [-11.565, -108.337],
                                  [42.782, -52.965],
                                  [-107.298, -37.228],
                                  [-16.734, -57.82],
                                  [-135.295, 12.635],
                                  [102.537, 73.808],
                                  [-32.528, 120.804],
                                  [-12.558, 92],
                                ],
                                o: [
                                  [-92.404, 35.288],
                                  [11.565, 108.337],
                                  [-42.782, 52.965],
                                  [107.298, 37.228],
                                  [20.026, 61.872],
                                  [109.468, -7.064],
                                  [-102.537, -73.808],
                                  [27.717, -125.108],
                                  [12.558, -92],
                                ],
                                v: [
                                  [-94.351, -365.181],
                                  [-183.553, -191.376],
                                  [-254.793, 0.333],
                                  [-241.82, 159.322],
                                  [-35.073, 299.979],
                                  [127.994, 375.835],
                                  [265.799, 230.364],
                                  [72.488, -90.104],
                                  [251.116, -186.871],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.933, -32.134],
                                  [-12.037, -108.871],
                                  [43.114, -52.638],
                                  [-107.891, -37.166],
                                  [-16.758, -58.094],
                                  [-134.846, 12.43],
                                  [103.337, 74.005],
                                  [-32.866, 121.483],
                                  [-12.47, 92],
                                ],
                                o: [
                                  [-92.55, 35.611],
                                  [12.037, 108.871],
                                  [-43.114, 52.638],
                                  [107.891, 37.166],
                                  [19.987, 62.067],
                                  [109.517, -6.966],
                                  [-103.337, -74.005],
                                  [28.148, -125.705],
                                  [12.47, -92],
                                ],
                                v: [
                                  [-94.875, -365.151],
                                  [-184.031, -190.948],
                                  [-254.016, 1.279],
                                  [-242.034, 159.819],
                                  [-33.542, 300.194],
                                  [126.917, 375.376],
                                  [265.077, 230.541],
                                  [71.571, -90.715],
                                  [252.52, -186.57],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.741, -32.519],
                                  [-12.501, -109.396],
                                  [43.441, -52.316],
                                  [-108.474, -37.105],
                                  [-16.782, -58.363],
                                  [-134.403, 12.228],
                                  [104.124, 74.198],
                                  [-33.197, 122.151],
                                  [-12.383, 92],
                                ],
                                o: [
                                  [-92.695, 35.928],
                                  [12.501, 109.396],
                                  [-43.441, 52.316],
                                  [108.474, 37.105],
                                  [19.948, 62.259],
                                  [109.565, -6.87],
                                  [-104.124, -74.198],
                                  [28.571, -126.291],
                                  [12.383, -92],
                                ],
                                v: [
                                  [-95.434, -365.097],
                                  [-184.568, -190.537],
                                  [-253.227, 2.275],
                                  [-242.268, 160.229],
                                  [-31.986, 300.397],
                                  [125.834, 374.943],
                                  [264.415, 230.734],
                                  [70.672, -91.284],
                                  [253.978, -186.256],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.552, -32.897],
                                  [-12.956, -109.912],
                                  [43.762, -51.999],
                                  [-109.046, -37.045],
                                  [-16.806, -58.628],
                                  [-133.969, 12.029],
                                  [104.898, 74.389],
                                  [-33.523, 122.808],
                                  [-12.298, 92],
                                ],
                                o: [
                                  [-92.836, 36.24],
                                  [12.956, 109.912],
                                  [-43.762, 51.999],
                                  [109.046, 37.045],
                                  [19.91, 62.448],
                                  [109.612, -6.776],
                                  [-104.898, -74.389],
                                  [28.986, -126.867],
                                  [12.298, -92],
                                ],
                                v: [
                                  [-95.982, -365.038],
                                  [-185.022, -190.095],
                                  [-252.426, 3.274],
                                  [-242.443, 160.706],
                                  [-30.499, 300.572],
                                  [124.695, 374.457],
                                  [263.813, 230.913],
                                  [69.805, -91.813],
                                  [255.379, -185.9],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.366, -33.268],
                                  [-13.404, -110.419],
                                  [44.078, -51.688],
                                  [-109.609, -36.985],
                                  [-16.829, -58.887],
                                  [-133.542, 11.834],
                                  [105.658, 74.575],
                                  [-33.844, 123.453],
                                  [-12.215, 92],
                                ],
                                o: [
                                  [-92.975, 36.546],
                                  [13.404, 110.419],
                                  [-44.078, 51.688],
                                  [109.609, 36.985],
                                  [19.873, 62.634],
                                  [109.658, -6.683],
                                  [-105.658, -74.575],
                                  [29.395, -127.434],
                                  [12.215, -92],
                                ],
                                v: [
                                  [-96.534, -365.005],
                                  [-185.471, -189.717],
                                  [-251.614, 4.311],
                                  [-242.669, 161.094],
                                  [-29.033, 300.735],
                                  [123.534, 374.075],
                                  [263.257, 231.14],
                                  [68.922, -92.317],
                                  [256.769, -185.596],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.184, -33.633],
                                  [-13.844, -110.917],
                                  [44.388, -51.383],
                                  [-110.162, -36.927],
                                  [-16.852, -59.143],
                                  [-133.122, 11.643],
                                  [106.404, 74.759],
                                  [-34.158, 124.087],
                                  [-12.133, 92],
                                ],
                                o: [
                                  [-93.112, 36.847],
                                  [13.844, 110.917],
                                  [-44.388, 51.383],
                                  [110.162, 36.927],
                                  [19.837, 62.816],
                                  [109.704, -6.592],
                                  [-106.405, -74.759],
                                  [29.796, -127.99],
                                  [12.133, -92],
                                ],
                                v: [
                                  [-97.123, -364.889],
                                  [-185.945, -189.306],
                                  [-250.804, 5.352],
                                  [-242.9, 161.487],
                                  [-27.587, 300.92],
                                  [122.333, 373.64],
                                  [262.747, 231.355],
                                  [68.105, -92.782],
                                  [258.15, -185.251],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.004, -33.991],
                                  [-14.277, -111.407],
                                  [44.693, -51.082],
                                  [-110.706, -36.87],
                                  [-16.874, -59.394],
                                  [-132.71, 11.455],
                                  [107.138, 74.939],
                                  [-34.467, 124.71],
                                  [-12.052, 92],
                                ],
                                o: [
                                  [-93.247, 37.143],
                                  [14.277, 111.407],
                                  [-44.693, 51.082],
                                  [110.706, 36.87],
                                  [19.801, 62.996],
                                  [109.749, -6.502],
                                  [-107.138, -74.939],
                                  [30.19, -128.537],
                                  [12.052, -92],
                                ],
                                v: [
                                  [-97.701, -364.851],
                                  [-186.317, -188.959],
                                  [-250.017, 6.447],
                                  [-243.183, 161.849],
                                  [-26.163, 301.046],
                                  [121.172, 373.199],
                                  [262.236, 231.586],
                                  [67.27, -93.256],
                                  [259.473, -184.897],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.828, -34.344],
                                  [-14.702, -111.888],
                                  [44.992, -50.787],
                                  [-111.239, -36.814],
                                  [-16.896, -59.641],
                                  [-132.305, 11.27],
                                  [107.859, 75.117],
                                  [-34.771, 125.322],
                                  [-11.973, 92],
                                ],
                                o: [
                                  [-93.379, 37.433],
                                  [14.702, 111.888],
                                  [-44.992, 50.787],
                                  [111.239, 36.814],
                                  [19.766, 63.172],
                                  [109.793, -6.414],
                                  [-107.86, -75.117],
                                  [30.578, -129.074],
                                  [11.973, -92],
                                ],
                                v: [
                                  [-98.331, -364.766],
                                  [-186.712, -188.578],
                                  [-249.171, 7.546],
                                  [-243.408, 162.214],
                                  [-24.758, 301.196],
                                  [119.955, 372.815],
                                  [261.836, 231.853],
                                  [66.466, -93.659],
                                  [260.849, -184.533],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.655, -34.69],
                                  [-15.119, -112.361],
                                  [45.286, -50.497],
                                  [-111.764, -36.759],
                                  [-16.918, -59.883],
                                  [-131.907, 11.088],
                                  [108.568, 75.291],
                                  [-35.07, 125.923],
                                  [-11.895, 92],
                                ],
                                o: [
                                  [-93.509, 37.719],
                                  [15.119, 112.361],
                                  [-45.286, 50.497],
                                  [111.764, 36.759],
                                  [19.731, 63.345],
                                  [109.836, -6.328],
                                  [-108.568, -75.291],
                                  [30.959, -129.602],
                                  [11.895, -92],
                                ],
                                v: [
                                  [-98.95, -364.678],
                                  [-187.102, -188.163],
                                  [-248.412, 8.649],
                                  [-243.651, 162.546],
                                  [-23.373, 301.337],
                                  [118.718, 372.412],
                                  [261.435, 232.123],
                                  [65.727, -94.059],
                                  [262.165, -184.196],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.485, -35.03],
                                  [-15.53, -112.825],
                                  [45.575, -50.212],
                                  [-112.28, -36.705],
                                  [-16.939, -60.121],
                                  [-131.516, 10.909],
                                  [109.264, 75.462],
                                  [-35.363, 126.514],
                                  [-11.818, 92],
                                ],
                                o: [
                                  [-93.636, 38],
                                  [15.53, 112.825],
                                  [-45.575, 50.212],
                                  [112.28, 36.705],
                                  [19.697, 63.515],
                                  [109.879, -6.243],
                                  [-109.264, -75.462],
                                  [31.333, -130.121],
                                  [11.818, -92],
                                ],
                                v: [
                                  [-99.596, -364.596],
                                  [-187.451, -187.811],
                                  [-247.595, 9.793],
                                  [-243.885, 162.831],
                                  [-22.02, 301.456],
                                  [117.437, 372.079],
                                  [261.133, 232.432],
                                  [64.969, -94.374],
                                  [263.47, -183.85],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.318, -35.364],
                                  [-15.933, -113.281],
                                  [45.859, -49.933],
                                  [-112.786, -36.651],
                                  [-16.96, -60.355],
                                  [-131.131, 10.734],
                                  [109.948, 75.63],
                                  [-35.651, 127.095],
                                  [-11.743, 92],
                                ],
                                o: [
                                  [-93.762, 38.275],
                                  [15.933, 113.282],
                                  [-45.859, 49.933],
                                  [112.786, 36.651],
                                  [19.664, 63.682],
                                  [109.92, -6.159],
                                  [-109.948, -75.63],
                                  [31.7, -130.63],
                                  [11.743, -92],
                                ],
                                v: [
                                  [-100.258, -364.548],
                                  [-187.744, -187.473],
                                  [-246.817, 10.942],
                                  [-244.122, 163.117],
                                  [-20.673, 301.553],
                                  [116.199, 371.728],
                                  [260.845, 232.757],
                                  [64.179, -94.716],
                                  [264.716, -183.483],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.154, -35.692],
                                  [-16.329, -113.73],
                                  [46.138, -49.658],
                                  [-113.284, -36.599],
                                  [-16.981, -60.585],
                                  [-130.754, 10.562],
                                  [110.62, 75.795],
                                  [-35.935, 127.665],
                                  [-11.669, 92],
                                ],
                                o: [
                                  [-93.885, 38.546],
                                  [16.329, 113.73],
                                  [-46.138, 49.658],
                                  [113.284, 36.599],
                                  [19.631, 63.846],
                                  [109.962, -6.077],
                                  [-110.62, -75.795],
                                  [32.062, -131.131],
                                  [11.669, -92],
                                ],
                                v: [
                                  [-100.947, -364.523],
                                  [-188.009, -187.111],
                                  [-246.081, 12.146],
                                  [-244.364, 163.405],
                                  [-19.358, 301.643],
                                  [114.904, 371.385],
                                  [260.594, 233.06],
                                  [63.467, -95.023],
                                  [266.001, -183.097],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.993, -36.015],
                                  [-16.718, -114.17],
                                  [46.413, -49.388],
                                  [-113.772, -36.548],
                                  [-17.001, -60.81],
                                  [-130.383, 10.392],
                                  [111.28, 75.958],
                                  [-36.213, 128.226],
                                  [-11.597, 92],
                                ],
                                o: [
                                  [-94.006, 38.812],
                                  [16.718, 114.17],
                                  [-46.413, 49.388],
                                  [113.772, 36.548],
                                  [19.599, 64.007],
                                  [110.002, -5.996],
                                  [-111.28, -75.957],
                                  [32.416, -131.623],
                                  [11.597, -92],
                                ],
                                v: [
                                  [-101.628, -364.484],
                                  [-188.266, -186.761],
                                  [-245.337, 13.355],
                                  [-244.66, 163.655],
                                  [-18.06, 301.762],
                                  [113.651, 371.05],
                                  [260.408, 233.43],
                                  [62.733, -95.283],
                                  [267.238, -182.766],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.834, -36.332],
                                  [-17.1, -114.603],
                                  [46.682, -49.122],
                                  [-114.253, -36.497],
                                  [-17.021, -61.032],
                                  [-130.019, 10.226],
                                  [111.929, 76.117],
                                  [-36.486, 128.776],
                                  [-11.525, 92],
                                ],
                                o: [
                                  [-94.124, 39.074],
                                  [17.1, 114.603],
                                  [-46.682, 49.122],
                                  [114.253, 36.497],
                                  [19.567, 64.166],
                                  [110.041, -5.917],
                                  [-111.929, -76.117],
                                  [32.765, -132.106],
                                  [11.525, -92],
                                ],
                                v: [
                                  [-102.362, -364.471],
                                  [-188.481, -186.373],
                                  [-244.635, 14.57],
                                  [-244.898, 163.868],
                                  [-16.781, 301.875],
                                  [112.355, 370.759],
                                  [260.323, 233.741],
                                  [62.015, -95.523],
                                  [268.452, -182.416],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.679, -36.643],
                                  [-17.476, -115.028],
                                  [46.947, -48.862],
                                  [-114.724, -36.448],
                                  [-17.04, -61.25],
                                  [-129.661, 10.062],
                                  [112.566, 76.273],
                                  [-36.755, 129.317],
                                  [-11.455, 92],
                                ],
                                o: [
                                  [-94.241, 39.33],
                                  [17.476, 115.028],
                                  [-46.947, 48.862],
                                  [114.724, 36.448],
                                  [19.536, 64.321],
                                  [110.08, -5.839],
                                  [-112.566, -76.273],
                                  [33.107, -132.58],
                                  [11.455, -92],
                                ],
                                v: [
                                  [-103.076, -364.497],
                                  [-188.65, -186.046],
                                  [-243.926, 15.789],
                                  [-245.152, 164.068],
                                  [-15.581, 301.969],
                                  [111.038, 370.474],
                                  [260.254, 234.106],
                                  [61.325, -95.718],
                                  [269.617, -182.099],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.526, -36.949],
                                  [-17.844, -115.446],
                                  [47.206, -48.605],
                                  [-115.188, -36.399],
                                  [-17.059, -61.464],
                                  [-129.309, 9.902],
                                  [113.192, 76.427],
                                  [-37.018, 129.848],
                                  [-11.387, 92],
                                ],
                                o: [
                                  [-94.356, 39.583],
                                  [17.844, 115.446],
                                  [-47.206, 48.606],
                                  [115.188, 36.399],
                                  [19.505, 64.474],
                                  [110.119, -5.763],
                                  [-113.192, -76.427],
                                  [33.444, -133.047],
                                  [11.387, -92],
                                ],
                                v: [
                                  [-103.844, -364.512],
                                  [-188.824, -185.681],
                                  [-243.261, 17.052],
                                  [-245.359, 164.228],
                                  [-14.347, 302.046],
                                  [109.727, 370.185],
                                  [260.225, 234.464],
                                  [60.651, -95.893],
                                  [270.821, -181.777],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.375, -37.249],
                                  [-18.207, -115.856],
                                  [47.462, -48.354],
                                  [-115.643, -36.351],
                                  [-17.078, -61.674],
                                  [-128.964, 9.744],
                                  [113.807, 76.578],
                                  [-37.277, 130.37],
                                  [-11.319, 92],
                                ],
                                o: [
                                  [-94.468, 39.83],
                                  [18.207, 115.856],
                                  [-47.462, 48.354],
                                  [115.643, 36.351],
                                  [19.475, 64.625],
                                  [110.156, -5.688],
                                  [-113.807, -76.578],
                                  [33.774, -133.505],
                                  [11.319, -92],
                                ],
                                v: [
                                  [-104.605, -364.595],
                                  [-188.953, -185.286],
                                  [-242.641, 18.321],
                                  [-245.609, 164.398],
                                  [-13.181, 302.168],
                                  [108.409, 369.952],
                                  [260.262, 234.866],
                                  [60.003, -96.076],
                                  [271.963, -181.501],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.228, -37.544],
                                  [-18.562, -116.258],
                                  [47.712, -48.107],
                                  [-116.09, -36.304],
                                  [-17.096, -61.881],
                                  [-128.624, 9.589],
                                  [114.41, 76.727],
                                  [-37.532, 130.883],
                                  [-11.253, 92],
                                ],
                                o: [
                                  [-94.579, 40.074],
                                  [18.562, 116.258],
                                  [-47.712, 48.107],
                                  [116.09, 36.304],
                                  [19.446, 64.772],
                                  [110.193, -5.614],
                                  [-114.41, -76.727],
                                  [34.098, -133.955],
                                  [11.253, -92],
                                ],
                                v: [
                                  [-105.409, -364.68],
                                  [-189.023, -184.952],
                                  [-242.066, 19.606],
                                  [-245.874, 164.504],
                                  [-11.991, 302.286],
                                  [107.084, 369.715],
                                  [260.404, 235.233],
                                  [59.332, -96.179],
                                  [273.106, -181.221],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.083, -37.834],
                                  [-18.912, -116.654],
                                  [47.959, -47.864],
                                  [-116.529, -36.258],
                                  [-17.115, -62.084],
                                  [-128.291, 9.437],
                                  [115.003, 76.873],
                                  [-37.782, 131.386],
                                  [-11.187, 92],
                                ],
                                o: [
                                  [-94.688, 40.313],
                                  [18.912, 116.654],
                                  [-47.959, 47.864],
                                  [116.529, 36.258],
                                  [19.417, 64.917],
                                  [110.229, -5.542],
                                  [-115.003, -76.873],
                                  [34.417, -134.396],
                                  [11.187, -92],
                                ],
                                v: [
                                  [-106.205, -364.797],
                                  [-189.057, -184.588],
                                  [-241.486, 20.884],
                                  [-246.08, 164.567],
                                  [-10.868, 302.377],
                                  [105.752, 369.534],
                                  [260.551, 235.635],
                                  [58.674, -96.302],
                                  [274.278, -180.988],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.941, -38.118],
                                  [-19.255, -117.043],
                                  [48.201, -47.626],
                                  [-116.96, -36.212],
                                  [-17.132, -62.283],
                                  [-127.964, 9.288],
                                  [115.586, 77.016],
                                  [-38.027, 131.88],
                                  [-11.123, 92],
                                ],
                                o: [
                                  [-94.794, 40.548],
                                  [19.255, 117.043],
                                  [-48.201, 47.626],
                                  [116.96, 36.212],
                                  [19.388, 65.059],
                                  [110.265, -5.47],
                                  [-115.586, -77.016],
                                  [34.73, -134.83],
                                  [11.123, -92],
                                ],
                                v: [
                                  [-107.046, -364.958],
                                  [-189.084, -184.181],
                                  [-240.953, 22.167],
                                  [-246.352, 164.639],
                                  [-9.771, 302.516],
                                  [104.425, 369.308],
                                  [260.753, 236.093],
                                  [58.043, -96.398],
                                  [275.451, -180.792],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.801, -38.398],
                                  [-19.592, -117.424],
                                  [48.438, -47.392],
                                  [-117.384, -36.168],
                                  [-17.15, -62.478],
                                  [-127.643, 9.141],
                                  [116.158, 77.156],
                                  [-38.268, 132.366],
                                  [-11.06, 92],
                                ],
                                o: [
                                  [-94.899, 40.778],
                                  [19.592, 117.424],
                                  [-48.438, 47.392],
                                  [117.384, 36.168],
                                  [19.36, 65.199],
                                  [110.3, -5.401],
                                  [-116.158, -77.156],
                                  [35.037, -135.257],
                                  [11.061, -92],
                                ],
                                v: [
                                  [-107.932, -365.153],
                                  [-189.062, -183.782],
                                  [-240.478, 23.455],
                                  [-246.575, 164.697],
                                  [-8.689, 302.653],
                                  [103.091, 369.19],
                                  [261.012, 236.61],
                                  [57.426, -96.403],
                                  [276.602, -180.645],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.664, -38.672],
                                  [-19.923, -117.799],
                                  [48.671, -47.162],
                                  [-117.8, -36.124],
                                  [-17.167, -62.671],
                                  [-127.327, 8.997],
                                  [116.72, 77.294],
                                  [-38.505, 132.843],
                                  [-10.999, 92],
                                ],
                                o: [
                                  [-95.002, 41.005],
                                  [19.923, 117.799],
                                  [-48.671, 47.162],
                                  [117.8, 36.124],
                                  [19.333, 65.336],
                                  [110.334, -5.332],
                                  [-116.72, -77.294],
                                  [35.339, -135.675],
                                  [10.999, -92],
                                ],
                                v: [
                                  [-108.823, -365.341],
                                  [-188.981, -183.391],
                                  [-240.041, 24.799],
                                  [-246.791, 164.71],
                                  [-7.673, 302.775],
                                  [101.802, 369.025],
                                  [261.275, 237.122],
                                  [56.783, -96.432],
                                  [277.754, -180.496],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.529, -38.942],
                                  [-20.248, -118.167],
                                  [48.9, -46.936],
                                  [-118.208, -36.081],
                                  [-17.184, -62.859],
                                  [-127.017, 8.856],
                                  [117.271, 77.43],
                                  [-38.737, 133.311],
                                  [-10.938, 92],
                                ],
                                o: [
                                  [-95.103, 41.227],
                                  [20.248, 118.167],
                                  [-48.9, 46.936],
                                  [118.208, 36.081],
                                  [19.306, 65.471],
                                  [110.368, -5.265],
                                  [-117.271, -77.43],
                                  [35.636, -136.086],
                                  [10.938, -92],
                                ],
                                v: [
                                  [-109.749, -365.618],
                                  [-188.913, -182.976],
                                  [-239.653, 26.096],
                                  [-247.02, 164.678],
                                  [-6.735, 302.833],
                                  [100.466, 368.907],
                                  [261.585, 237.736],
                                  [56.163, -96.488],
                                  [278.948, -180.396],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.397, -39.206],
                                  [-20.568, -118.529],
                                  [49.125, -46.715],
                                  [-118.61, -36.039],
                                  [-17.2, -63.044],
                                  [-126.713, 8.717],
                                  [117.813, 77.563],
                                  [-38.965, 133.771],
                                  [-10.879, 92],
                                ],
                                o: [
                                  [-95.202, 41.445],
                                  [20.568, 118.529],
                                  [-49.125, 46.715],
                                  [118.61, 36.039],
                                  [19.279, 65.603],
                                  [110.401, -5.198],
                                  [-117.813, -77.563],
                                  [35.927, -136.49],
                                  [10.879, -92],
                                ],
                                v: [
                                  [-110.68, -365.952],
                                  [-188.785, -182.557],
                                  [-239.314, 27.397],
                                  [-247.199, 164.632],
                                  [-5.863, 302.888],
                                  [99.175, 368.793],
                                  [261.91, 238.357],
                                  [55.558, -96.455],
                                  [280.089, -180.337],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.267, -39.466],
                                  [-20.881, -118.883],
                                  [49.346, -46.497],
                                  [-119.003, -35.998],
                                  [-17.217, -63.226],
                                  [-126.414, 8.58],
                                  [118.345, 77.694],
                                  [-39.19, 134.223],
                                  [-10.82, 92],
                                ],
                                o: [
                                  [-95.3, 41.66],
                                  [20.881, 118.884],
                                  [-49.346, 46.497],
                                  [119.003, 35.998],
                                  [19.253, 65.733],
                                  [110.433, -5.133],
                                  [-118.345, -77.694],
                                  [36.213, -136.886],
                                  [10.82, -92],
                                ],
                                v: [
                                  [-111.647, -366.324],
                                  [-188.616, -182.155],
                                  [-238.973, 28.691],
                                  [-247.424, 164.592],
                                  [-5.069, 302.877],
                                  [97.888, 368.735],
                                  [262.346, 239.039],
                                  [54.976, -96.398],
                                  [281.263, -180.339],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.139, -39.721],
                                  [-21.189, -119.232],
                                  [49.563, -46.283],
                                  [-119.39, -35.957],
                                  [-17.233, -63.405],
                                  [-126.12, 8.446],
                                  [118.867, 77.822],
                                  [-39.41, 134.666],
                                  [-10.763, 92],
                                ],
                                o: [
                                  [-95.396, 41.87],
                                  [21.189, 119.232],
                                  [-49.563, 46.283],
                                  [119.39, 35.957],
                                  [19.228, 65.861],
                                  [110.465, -5.07],
                                  [-118.867, -77.822],
                                  [36.494, -137.275],
                                  [10.763, -92],
                                ],
                                v: [
                                  [-112.619, -366.682],
                                  [-188.386, -181.715],
                                  [-238.64, 29.999],
                                  [-247.608, 164.505],
                                  [-4.333, 302.812],
                                  [96.595, 368.681],
                                  [262.724, 239.761],
                                  [54.418, -96.369],
                                  [282.427, -180.331],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.014, -39.972],
                                  [-21.491, -119.574],
                                  [49.776, -46.074],
                                  [-119.77, -35.917],
                                  [-17.248, -63.58],
                                  [-125.832, 8.315],
                                  [119.38, 77.948],
                                  [-39.626, 135.101],
                                  [-10.706, 92],
                                ],
                                o: [
                                  [-95.489, 42.077],
                                  [21.491, 119.574],
                                  [-49.776, 46.074],
                                  [119.77, 35.917],
                                  [19.203, 65.986],
                                  [110.496, -5.007],
                                  [-119.38, -77.948],
                                  [36.769, -137.657],
                                  [10.706, -92],
                                ],
                                v: [
                                  [-113.639, -367.099],
                                  [-188.158, -181.281],
                                  [-238.348, 31.258],
                                  [-247.803, 164.403],
                                  [-3.727, 302.734],
                                  [95.305, 368.684],
                                  [263.162, 240.493],
                                  [53.82, -96.254],
                                  [283.539, -180.331],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.891, -40.218],
                                  [-21.788, -119.91],
                                  [49.985, -45.868],
                                  [-120.143, -35.878],
                                  [-17.264, -63.753],
                                  [-125.549, 8.186],
                                  [119.883, 78.072],
                                  [-39.838, 135.529],
                                  [-10.651, 92],
                                ],
                                o: [
                                  [-95.582, 42.28],
                                  [21.788, 119.91],
                                  [-49.985, 45.868],
                                  [120.143, 35.878],
                                  [19.178, 66.109],
                                  [110.527, -4.945],
                                  [-119.884, -78.072],
                                  [37.04, -138.032],
                                  [10.651, -92],
                                ],
                                v: [
                                  [-114.644, -367.504],
                                  [-187.877, -180.861],
                                  [-238.117, 32.52],
                                  [-247.991, 164.254],
                                  [-3.135, 302.6],
                                  [94.062, 368.691],
                                  [263.659, 241.233],
                                  [53.244, -96.169],
                                  [284.693, -180.427],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.77, -40.459],
                                  [-22.079, -120.24],
                                  [50.19, -45.665],
                                  [-120.509, -35.839],
                                  [-17.279, -63.922],
                                  [-125.272, 8.059],
                                  [120.378, 78.194],
                                  [-40.046, 135.948],
                                  [-10.597, 92],
                                ],
                                o: [
                                  [-95.672, 42.479],
                                  [22.079, 120.24],
                                  [-50.19, 45.665],
                                  [120.509, 35.839],
                                  [19.154, 66.23],
                                  [110.557, -4.885],
                                  [-120.378, -78.194],
                                  [37.305, -138.401],
                                  [10.597, -92],
                                ],
                                v: [
                                  [-115.654, -367.905],
                                  [-187.589, -180.392],
                                  [-237.83, 33.73],
                                  [-248.181, 164.108],
                                  [-2.613, 302.517],
                                  [92.813, 368.7],
                                  [264.108, 242.08],
                                  [52.691, -96.054],
                                  [285.838, -180.469],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.652, -40.697],
                                  [-22.365, -120.564],
                                  [50.392, -45.467],
                                  [-120.868, -35.801],
                                  [-17.294, -64.088],
                                  [-124.999, 7.934],
                                  [120.863, 78.313],
                                  [-40.251, 136.36],
                                  [-10.543, 92],
                                ],
                                o: [
                                  [-95.761, 42.675],
                                  [22.365, 120.564],
                                  [-50.392, 45.467],
                                  [120.868, 35.802],
                                  [19.13, 66.348],
                                  [110.587, -4.826],
                                  [-120.863, -78.313],
                                  [37.566, -138.762],
                                  [10.543, -92],
                                ],
                                v: [
                                  [-116.659, -368.294],
                                  [-187.256, -179.936],
                                  [-237.649, 34.89],
                                  [-248.381, 163.949],
                                  [-2.167, 302.315],
                                  [91.568, 368.765],
                                  [264.662, 242.873],
                                  [52.105, -95.917],
                                  [287.027, -180.627],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.535, -40.929],
                                  [-22.646, -120.881],
                                  [50.59, -45.272],
                                  [-121.221, -35.764],
                                  [-17.308, -64.25],
                                  [-124.731, 7.812],
                                  [121.339, 78.43],
                                  [-40.452, 136.765],
                                  [-10.491, 92],
                                ],
                                o: [
                                  [-95.848, 42.867],
                                  [22.646, 120.882],
                                  [-50.59, 45.272],
                                  [121.221, 35.764],
                                  [19.107, 66.465],
                                  [110.616, -4.768],
                                  [-121.34, -78.43],
                                  [37.822, -139.117],
                                  [10.491, -92],
                                ],
                                v: [
                                  [-117.65, -368.627],
                                  [-186.862, -179.439],
                                  [-237.422, 36.06],
                                  [-248.529, 163.739],
                                  [-1.835, 302.165],
                                  [90.325, 368.833],
                                  [265.169, 243.729],
                                  [51.594, -95.75],
                                  [288.154, -180.776],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.421, -41.158],
                                  [-22.922, -121.194],
                                  [50.784, -45.08],
                                  [-121.567, -35.728],
                                  [-17.322, -64.41],
                                  [-124.469, 7.692],
                                  [121.807, 78.545],
                                  [-40.649, 137.162],
                                  [-10.44, 92],
                                ],
                                o: [
                                  [-95.934, 43.055],
                                  [22.922, 121.194],
                                  [-50.784, 45.08],
                                  [121.567, 35.728],
                                  [19.084, 66.579],
                                  [110.645, -4.711],
                                  [-121.807, -78.545],
                                  [38.073, -139.466],
                                  [10.44, -92],
                                ],
                                v: [
                                  [-118.69, -369.01],
                                  [-186.468, -178.999],
                                  [-237.247, 37.222],
                                  [-248.679, 163.525],
                                  [-1.527, 301.958],
                                  [89.131, 368.956],
                                  [265.735, 244.585],
                                  [51.059, -95.564],
                                  [289.271, -180.926],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.309, -41.382],
                                  [-23.192, -121.5],
                                  [50.975, -44.893],
                                  [-121.907, -35.692],
                                  [-17.336, -64.567],
                                  [-124.211, 7.574],
                                  [122.266, 78.658],
                                  [-40.842, 137.551],
                                  [-10.389, 92],
                                ],
                                o: [
                                  [-96.018, 43.24],
                                  [23.192, 121.5],
                                  [-50.975, 44.893],
                                  [121.907, 35.692],
                                  [19.062, 66.691],
                                  [110.673, -4.655],
                                  [-122.266, -78.658],
                                  [38.32, -139.808],
                                  [10.389, -92],
                                ],
                                v: [
                                  [-119.681, -369.336],
                                  [-186.021, -178.516],
                                  [-237.116, 38.278],
                                  [-248.829, 163.305],
                                  [-1.288, 301.685],
                                  [87.993, 369.074],
                                  [266.309, 245.505],
                                  [50.488, -95.412],
                                  [290.38, -181.137],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.199, -41.602],
                                  [-23.458, -121.8],
                                  [51.162, -44.708],
                                  [-122.241, -35.657],
                                  [-17.35, -64.721],
                                  [-123.958, 7.459],
                                  [122.717, 78.769],
                                  [-41.032, 137.934],
                                  [-10.34, 92],
                                ],
                                o: [
                                  [-96.101, 43.422],
                                  [23.458, 121.8],
                                  [-51.162, 44.708],
                                  [122.241, 35.657],
                                  [19.04, 66.801],
                                  [110.7, -4.599],
                                  [-122.717, -78.769],
                                  [38.562, -140.143],
                                  [10.339, -92],
                                ],
                                v: [
                                  [-120.659, -369.714],
                                  [-185.573, -177.989],
                                  [-237.001, 39.342],
                                  [-248.926, 163.089],
                                  [-1.126, 301.465],
                                  [86.796, 369.193],
                                  [266.934, 246.426],
                                  [49.991, -95.233],
                                  [291.533, -181.395],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.091, -41.818],
                                  [-23.718, -122.096],
                                  [51.346, -44.527],
                                  [-122.568, -35.623],
                                  [-17.364, -64.873],
                                  [-123.709, 7.345],
                                  [123.159, 78.877],
                                  [-41.218, 138.309],
                                  [-10.291, 92],
                                ],
                                o: [
                                  [-96.182, 43.6],
                                  [23.718, 122.096],
                                  [-51.345, 44.527],
                                  [122.568, 35.623],
                                  [19.018, 66.909],
                                  [110.727, -4.545],
                                  [-123.159, -78.877],
                                  [38.8, -140.473],
                                  [10.291, -92],
                                ],
                                v: [
                                  [-121.632, -370.026],
                                  [-185.07, -177.473],
                                  [-236.877, 40.399],
                                  [-249.07, 162.813],
                                  [-0.97, 301.188],
                                  [85.71, 369.368],
                                  [267.512, 247.357],
                                  [49.459, -94.981],
                                  [292.624, -181.661],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.985, -42.03],
                                  [-23.974, -122.385],
                                  [51.526, -44.35],
                                  [-122.89, -35.589],
                                  [-17.377, -65.021],
                                  [-123.465, 7.234],
                                  [123.593, 78.984],
                                  [-41.401, 138.678],
                                  [-10.243, 92],
                                ],
                                o: [
                                  [-96.261, 43.775],
                                  [23.974, 122.385],
                                  [-51.526, 44.35],
                                  [122.89, 35.589],
                                  [18.997, 67.015],
                                  [110.754, -4.492],
                                  [-123.593, -78.984],
                                  [39.033, -140.796],
                                  [10.243, -92],
                                ],
                                v: [
                                  [-122.593, -370.336],
                                  [-184.568, -176.958],
                                  [-236.807, 41.409],
                                  [-249.154, 162.523],
                                  [-0.947, 300.9],
                                  [84.565, 369.537],
                                  [268.152, 248.289],
                                  [48.945, -94.758],
                                  [293.769, -181.928],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.881, -42.238],
                                  [-24.225, -122.669],
                                  [51.703, -44.175],
                                  [-123.205, -35.556],
                                  [-17.39, -65.167],
                                  [-123.226, 7.125],
                                  [124.019, 79.089],
                                  [-41.581, 139.039],
                                  [-10.196, 92],
                                ],
                                o: [
                                  [-96.339, 43.947],
                                  [24.225, 122.669],
                                  [-51.703, 44.175],
                                  [123.205, 35.556],
                                  [18.976, 67.119],
                                  [110.78, -4.44],
                                  [-124.019, -79.089],
                                  [39.262, -141.114],
                                  [10.196, -92],
                                ],
                                v: [
                                  [-123.557, -370.642],
                                  [-184.057, -176.451],
                                  [-236.743, 42.364],
                                  [-249.238, 162.236],
                                  [-0.938, 300.61],
                                  [83.469, 369.755],
                                  [268.79, 249.222],
                                  [48.456, -94.573],
                                  [294.842, -182.249],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.779, -42.443],
                                  [-24.472, -122.948],
                                  [51.876, -44.004],
                                  [-123.515, -35.523],
                                  [-17.403, -65.31],
                                  [-122.991, 7.018],
                                  [124.438, 79.192],
                                  [-41.757, 139.394],
                                  [-10.15, 92],
                                ],
                                o: [
                                  [-96.416, 44.115],
                                  [24.472, 122.948],
                                  [-51.876, 44.004],
                                  [123.515, 35.523],
                                  [18.956, 67.221],
                                  [110.805, -4.389],
                                  [-124.438, -79.191],
                                  [39.487, -141.425],
                                  [10.15, -92],
                                ],
                                v: [
                                  [-124.455, -370.939],
                                  [-183.436, -175.939],
                                  [-236.734, 43.319],
                                  [-249.309, 161.937],
                                  [-1.005, 300.318],
                                  [82.431, 369.975],
                                  [269.435, 250.165],
                                  [47.984, -94.316],
                                  [295.962, -182.626],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.678, -42.643],
                                  [-24.713, -123.222],
                                  [52.047, -43.836],
                                  [-123.819, -35.491],
                                  [-17.415, -65.45],
                                  [-122.76, 6.912],
                                  [124.848, 79.292],
                                  [-41.93, 139.743],
                                  [-10.105, 92],
                                ],
                                o: [
                                  [-96.491, 44.281],
                                  [24.713, 123.222],
                                  [-52.047, 43.836],
                                  [123.819, 35.491],
                                  [18.936, 67.322],
                                  [110.83, -4.339],
                                  [-124.848, -79.292],
                                  [39.707, -141.731],
                                  [10.105, -92],
                                ],
                                v: [
                                  [-125.411, -371.24],
                                  [-182.877, -175.381],
                                  [-236.724, 44.163],
                                  [-249.319, 161.633],
                                  [-1.135, 299.968],
                                  [81.389, 370.196],
                                  [270.087, 251.173],
                                  [47.474, -94.036],
                                  [297.082, -183.004],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.58, -42.84],
                                  [-24.951, -123.491],
                                  [52.214, -43.672],
                                  [-124.117, -35.46],
                                  [-17.427, -65.588],
                                  [-122.534, 6.809],
                                  [125.25, 79.391],
                                  [-42.099, 140.084],
                                  [-10.061, 92],
                                ],
                                o: [
                                  [-96.565, 44.443],
                                  [24.951, 123.491],
                                  [-52.214, 43.672],
                                  [124.117, 35.46],
                                  [18.916, 67.42],
                                  [110.855, -4.29],
                                  [-125.25, -79.391],
                                  [39.924, -142.031],
                                  [10.061, -92],
                                ],
                                v: [
                                  [-126.3, -371.532],
                                  [-182.255, -174.83],
                                  [-236.721, 45.06],
                                  [-249.332, 161.277],
                                  [-1.341, 299.671],
                                  [80.404, 370.467],
                                  [270.793, 252.126],
                                  [47.036, -93.789],
                                  [298.13, -183.389],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.484, -43.033],
                                  [-25.183, -123.754],
                                  [52.378, -43.51],
                                  [-124.409, -35.429],
                                  [-17.44, -65.723],
                                  [-122.312, 6.708],
                                  [125.646, 79.488],
                                  [-42.266, 140.42],
                                  [-10.018, 92],
                                ],
                                o: [
                                  [-96.637, 44.602],
                                  [25.183, 123.754],
                                  [-52.378, 43.51],
                                  [124.409, 35.429],
                                  [18.897, 67.516],
                                  [110.879, -4.242],
                                  [-125.646, -79.488],
                                  [40.136, -142.325],
                                  [10.018, -92],
                                ],
                                v: [
                                  [-127.185, -371.828],
                                  [-181.632, -174.273],
                                  [-236.766, 45.845],
                                  [-249.338, 160.964],
                                  [-1.561, 299.317],
                                  [79.415, 370.732],
                                  [271.451, 253.089],
                                  [46.565, -93.526],
                                  [299.226, -183.829],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.389, -43.222],
                                  [-25.412, -124.013],
                                  [52.539, -43.351],
                                  [-124.696, -35.399],
                                  [-17.451, -65.856],
                                  [-122.094, 6.608],
                                  [126.033, 79.584],
                                  [-42.429, 140.749],
                                  [-9.975, 92],
                                ],
                                o: [
                                  [-96.708, 44.758],
                                  [25.412, 124.013],
                                  [-52.539, 43.351],
                                  [124.696, 35.399],
                                  [18.878, 67.611],
                                  [110.903, -4.194],
                                  [-126.033, -79.584],
                                  [40.345, -142.614],
                                  [9.975, -92],
                                ],
                                v: [
                                  [-128.003, -372.114],
                                  [-180.952, -173.717],
                                  [-236.817, 46.629],
                                  [-249.347, 160.59],
                                  [-1.858, 299.009],
                                  [78.478, 371.047],
                                  [272.164, 254.054],
                                  [46.055, -93.249],
                                  [300.266, -184.27],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.296, -43.408],
                                  [-25.636, -124.267],
                                  [52.697, -43.196],
                                  [-124.978, -35.369],
                                  [-17.463, -65.986],
                                  [-121.881, 6.511],
                                  [126.414, 79.677],
                                  [-42.59, 141.072],
                                  [-9.933, 92],
                                ],
                                o: [
                                  [-96.778, 44.912],
                                  [25.636, 124.267],
                                  [-52.697, 43.196],
                                  [124.978, 35.369],
                                  [18.859, 67.704],
                                  [110.926, -4.148],
                                  [-126.414, -79.677],
                                  [40.549, -142.897],
                                  [9.933, -92],
                                ],
                                v: [
                                  [-128.879, -372.342],
                                  [-180.279, -173.113],
                                  [-236.924, 47.362],
                                  [-249.352, 160.269],
                                  [-2.162, 298.644],
                                  [77.599, 371.364],
                                  [272.836, 255.027],
                                  [45.614, -92.952],
                                  [301.347, -184.768],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.205, -43.591],
                                  [-25.856, -124.516],
                                  [52.852, -43.043],
                                  [-125.254, -35.34],
                                  [-17.474, -66.113],
                                  [-121.671, 6.415],
                                  [126.787, 79.769],
                                  [-42.747, 141.389],
                                  [-9.892, 92],
                                ],
                                o: [
                                  [-96.846, 45.062],
                                  [25.856, 124.516],
                                  [-52.852, 43.043],
                                  [125.254, 35.34],
                                  [18.841, 67.795],
                                  [110.949, -4.102],
                                  [-126.787, -79.769],
                                  [40.75, -143.176],
                                  [9.892, -92],
                                ],
                                v: [
                                  [-129.689, -372.63],
                                  [-179.597, -172.565],
                                  [-237.03, 48.03],
                                  [-249.295, 159.88],
                                  [-2.597, 298.283],
                                  [76.717, 371.675],
                                  [273.556, 256.002],
                                  [45.133, -92.634],
                                  [302.42, -185.272],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.115, -43.769],
                                  [-26.072, -124.76],
                                  [53.004, -42.893],
                                  [-125.525, -35.312],
                                  [-17.486, -66.239],
                                  [-121.465, 6.321],
                                  [127.153, 79.859],
                                  [-42.901, 141.699],
                                  [-9.852, 92],
                                ],
                                o: [
                                  [-96.913, 45.21],
                                  [26.072, 124.76],
                                  [-53.004, 42.893],
                                  [125.525, 35.312],
                                  [18.823, 67.885],
                                  [110.971, -4.058],
                                  [-127.153, -79.859],
                                  [40.946, -143.448],
                                  [9.852, -92],
                                ],
                                v: [
                                  [-130.438, -372.853],
                                  [-178.915, -171.962],
                                  [-237.193, 48.702],
                                  [-249.298, 159.55],
                                  [-2.99, 297.913],
                                  [75.894, 372.038],
                                  [274.227, 256.986],
                                  [44.728, -92.359],
                                  [303.429, -185.833],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.028, -43.945],
                                  [-26.284, -125],
                                  [53.153, -42.746],
                                  [-125.791, -35.284],
                                  [-17.497, -66.361],
                                  [-121.263, 6.229],
                                  [127.512, 79.947],
                                  [-43.052, 142.004],
                                  [-9.812, 92],
                                ],
                                o: [
                                  [-96.979, 45.355],
                                  [26.284, 125],
                                  [-53.153, 42.746],
                                  [125.791, 35.284],
                                  [18.806, 67.972],
                                  [110.993, -4.014],
                                  [-127.512, -79.947],
                                  [41.139, -143.716],
                                  [9.812, -92],
                                ],
                                v: [
                                  [-131.239, -373.073],
                                  [-178.181, -171.359],
                                  [-237.306, 49.315],
                                  [-249.183, 159.154],
                                  [-3.508, 297.604],
                                  [75.123, 372.402],
                                  [274.905, 257.922],
                                  [44.275, -92.072],
                                  [304.487, -186.4],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.941, -44.117],
                                  [-26.491, -125.235],
                                  [53.299, -42.602],
                                  [-126.052, -35.256],
                                  [-17.507, -66.482],
                                  [-121.065, 6.139],
                                  [127.865, 80.034],
                                  [-43.201, 142.303],
                                  [-9.774, 92],
                                ],
                                o: [
                                  [-97.044, 45.497],
                                  [26.491, 125.235],
                                  [-53.299, 42.602],
                                  [126.052, 35.256],
                                  [18.788, 68.059],
                                  [111.015, -3.971],
                                  [-127.865, -80.034],
                                  [41.329, -143.978],
                                  [9.774, -92],
                                ],
                                v: [
                                  [-131.924, -373.297],
                                  [-177.447, -170.756],
                                  [-237.531, 49.87],
                                  [-249.128, 158.81],
                                  [-3.989, 297.23],
                                  [74.35, 372.755],
                                  [275.64, 258.859],
                                  [43.791, -91.766],
                                  [305.538, -187.017],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.857, -44.286],
                                  [-26.695, -125.465],
                                  [53.443, -42.461],
                                  [-126.308, -35.229],
                                  [-17.518, -66.6],
                                  [-120.871, 6.05],
                                  [128.21, 80.119],
                                  [-43.347, 142.597],
                                  [-9.736, 92],
                                ],
                                o: [
                                  [-97.107, 45.636],
                                  [26.695, 125.465],
                                  [-53.443, 42.461],
                                  [126.308, 35.229],
                                  [18.771, 68.143],
                                  [111.036, -3.929],
                                  [-128.21, -80.119],
                                  [41.514, -144.236],
                                  [9.736, -92],
                                ],
                                v: [
                                  [-132.654, -373.511],
                                  [-176.71, -170.154],
                                  [-237.7, 50.421],
                                  [-249.074, 158.463],
                                  [-4.589, 296.86],
                                  [73.636, 373.167],
                                  [276.324, 259.804],
                                  [43.371, -91.442],
                                  [306.526, -187.648],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.774, -44.452],
                                  [-26.895, -125.692],
                                  [53.584, -42.322],
                                  [-126.559, -35.203],
                                  [-17.528, -66.716],
                                  [-120.681, 5.963],
                                  [128.549, 80.202],
                                  [-43.489, 142.885],
                                  [-9.698, 92],
                                ],
                                o: [
                                  [-97.169, 45.773],
                                  [26.895, 125.692],
                                  [-53.584, 42.322],
                                  [126.559, 35.203],
                                  [18.755, 68.226],
                                  [111.056, -3.887],
                                  [-128.549, -80.202],
                                  [41.697, -144.489],
                                  [9.698, -92],
                                ],
                                v: [
                                  [-133.324, -373.723],
                                  [-175.972, -169.559],
                                  [-237.925, 50.912],
                                  [-248.96, 158.107],
                                  [-5.152, 296.482],
                                  [72.976, 373.574],
                                  [277.01, 260.757],
                                  [42.963, -91.108],
                                  [307.563, -188.328],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.693, -44.614],
                                  [-27.091, -125.914],
                                  [53.722, -42.186],
                                  [-126.806, -35.177],
                                  [-17.538, -66.83],
                                  [-120.494, 5.878],
                                  [128.882, 80.284],
                                  [-43.63, 143.167],
                                  [-9.662, 92],
                                ],
                                o: [
                                  [-97.23, 45.907],
                                  [27.091, 125.914],
                                  [-53.722, 42.186],
                                  [126.806, 35.177],
                                  [18.739, 68.307],
                                  [111.077, -3.846],
                                  [-128.882, -80.284],
                                  [41.875, -144.736],
                                  [9.662, -92],
                                ],
                                v: [
                                  [-133.989, -373.938],
                                  [-175.233, -168.964],
                                  [-238.207, 51.405],
                                  [-248.848, 157.754],
                                  [-5.84, 296.165],
                                  [72.313, 374.033],
                                  [277.758, 261.655],
                                  [42.517, -90.819],
                                  [308.587, -189.015],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.613, -44.773],
                                  [-27.283, -126.131],
                                  [53.857, -42.052],
                                  [-127.047, -35.152],
                                  [-17.548, -66.941],
                                  [-120.311, 5.794],
                                  [129.208, 80.364],
                                  [-43.767, 143.444],
                                  [-9.626, 92],
                                ],
                                o: [
                                  [-97.29, 46.038],
                                  [27.283, 126.131],
                                  [-53.857, 42.052],
                                  [127.047, 35.152],
                                  [18.723, 68.387],
                                  [111.097, -3.807],
                                  [-129.208, -80.364],
                                  [42.051, -144.979],
                                  [9.626, -92],
                                ],
                                v: [
                                  [-134.594, -374.15],
                                  [-174.498, -168.363],
                                  [-238.438, 51.833],
                                  [-248.738, 157.391],
                                  [-6.489, 295.789],
                                  [71.767, 374.488],
                                  [278.456, 262.56],
                                  [42.083, -90.514],
                                  [309.553, -189.708],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.535, -44.93],
                                  [-27.471, -126.345],
                                  [53.99, -41.921],
                                  [-127.284, -35.127],
                                  [-17.558, -67.051],
                                  [-120.131, 5.712],
                                  [129.528, 80.443],
                                  [-43.902, 143.715],
                                  [-9.591, 92],
                                ],
                                o: [
                                  [-97.349, 46.167],
                                  [27.471, 126.345],
                                  [-53.99, 41.921],
                                  [127.284, 35.127],
                                  [18.707, 68.465],
                                  [111.116, -3.768],
                                  [-129.528, -80.443],
                                  [42.223, -145.218],
                                  [9.591, -92],
                                ],
                                v: [
                                  [-135.137, -374.303],
                                  [-173.761, -167.769],
                                  [-238.721, 52.205],
                                  [-248.625, 157.078],
                                  [-7.201, 295.411],
                                  [71.219, 374.934],
                                  [279.155, 263.414],
                                  [41.666, -90.193],
                                  [310.564, -190.515],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.458, -45.083],
                                  [-27.656, -126.554],
                                  [54.121, -41.793],
                                  [-127.516, -35.102],
                                  [-17.568, -67.158],
                                  [-119.954, 5.631],
                                  [129.842, 80.52],
                                  [-44.034, 143.982],
                                  [-9.556, 92],
                                ],
                                o: [
                                  [-97.406, 46.293],
                                  [27.656, 126.554],
                                  [-54.121, 41.793],
                                  [127.516, 35.102],
                                  [18.692, 68.542],
                                  [111.135, -3.729],
                                  [-129.842, -80.52],
                                  [42.391, -145.452],
                                  [9.556, -92],
                                ],
                                v: [
                                  [-135.727, -374.509],
                                  [-172.966, -167.175],
                                  [-239.067, 52.569],
                                  [-248.519, 156.71],
                                  [-7.988, 295.088],
                                  [70.727, 375.382],
                                  [279.798, 264.276],
                                  [41.26, -89.863],
                                  [311.574, -191.264],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.383, -45.233],
                                  [-27.838, -126.759],
                                  [54.248, -41.667],
                                  [-127.744, -35.078],
                                  [-17.577, -67.263],
                                  [-119.782, 5.552],
                                  [130.149, 80.596],
                                  [-44.164, 144.243],
                                  [-9.522, 92],
                                ],
                                o: [
                                  [-97.463, 46.418],
                                  [27.838, 126.759],
                                  [-54.248, 41.667],
                                  [127.744, 35.078],
                                  [18.677, 68.617],
                                  [111.154, -3.692],
                                  [-130.15, -80.596],
                                  [42.557, -145.681],
                                  [9.523, -92],
                                ],
                                v: [
                                  [-136.199, -374.655],
                                  [-172.231, -166.524],
                                  [-239.356, 52.876],
                                  [-248.353, 156.449],
                                  [-8.729, 294.711],
                                  [70.289, 375.884],
                                  [280.505, 265.144],
                                  [40.866, -89.523],
                                  [312.515, -192.076],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.31, -45.381],
                                  [-28.016, -126.961],
                                  [54.374, -41.544],
                                  [-127.968, -35.055],
                                  [-17.586, -67.367],
                                  [-119.612, 5.475],
                                  [130.451, 80.67],
                                  [-44.291, 144.499],
                                  [-9.489, 92],
                                ],
                                o: [
                                  [-97.518, 46.539],
                                  [28.016, 126.961],
                                  [-54.374, 41.544],
                                  [127.968, 35.055],
                                  [18.662, 68.69],
                                  [111.173, -3.655],
                                  [-130.451, -80.67],
                                  [42.719, -145.906],
                                  [9.489, -92],
                                ],
                                v: [
                                  [-136.666, -374.799],
                                  [-171.49, -165.988],
                                  [-239.703, 53.18],
                                  [-248.188, 156.129],
                                  [-9.538, 294.39],
                                  [69.856, 376.382],
                                  [281.217, 265.955],
                                  [40.425, -89.227],
                                  [313.507, -192.946],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.237, -45.525],
                                  [-28.19, -127.158],
                                  [54.497, -41.423],
                                  [-128.187, -35.032],
                                  [-17.595, -67.468],
                                  [-119.446, 5.399],
                                  [130.747, 80.742],
                                  [-44.416, 144.75],
                                  [-9.457, 92],
                                ],
                                o: [
                                  [-97.572, 46.658],
                                  [28.19, 127.158],
                                  [-54.497, 41.423],
                                  [128.187, 35.032],
                                  [18.647, 68.763],
                                  [111.191, -3.619],
                                  [-130.747, -80.742],
                                  [42.878, -146.126],
                                  [9.457, -92],
                                ],
                                v: [
                                  [-137.129, -374.944],
                                  [-170.752, -165.4],
                                  [-240.05, 53.422],
                                  [-248.083, 155.859],
                                  [-10.421, 294.008],
                                  [69.536, 376.929],
                                  [281.873, 266.715],
                                  [40.056, -88.921],
                                  [314.43, -193.878],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.166, -45.667],
                                  [-28.361, -127.352],
                                  [54.617, -41.304],
                                  [-128.402, -35.009],
                                  [-17.604, -67.567],
                                  [-119.283, 5.325],
                                  [131.037, 80.814],
                                  [-44.538, 144.997],
                                  [-9.425, 92],
                                ],
                                o: [
                                  [-97.625, 46.775],
                                  [28.361, 127.352],
                                  [-54.617, 41.304],
                                  [128.402, 35.009],
                                  [18.633, 68.834],
                                  [111.208, -3.583],
                                  [-131.037, -80.814],
                                  [43.034, -146.342],
                                  [9.425, -92],
                                ],
                                v: [
                                  [-137.525, -375.086],
                                  [-170.017, -164.807],
                                  [-240.455, 53.66],
                                  [-247.927, 155.588],
                                  [-11.252, 293.683],
                                  [69.219, 377.421],
                                  [282.53, 267.538],
                                  [39.641, -88.603],
                                  [315.409, -194.757],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.097, -45.806],
                                  [-28.529, -127.541],
                                  [54.735, -41.187],
                                  [-128.612, -34.987],
                                  [-17.613, -67.664],
                                  [-119.123, 5.252],
                                  [131.322, 80.884],
                                  [-44.658, 145.238],
                                  [-9.394, 92],
                                ],
                                o: [
                                  [-97.677, 46.89],
                                  [28.529, 127.541],
                                  [-54.735, 41.187],
                                  [128.612, 34.987],
                                  [18.619, 68.903],
                                  [111.226, -3.548],
                                  [-131.322, -80.884],
                                  [43.187, -146.554],
                                  [9.394, -92],
                                ],
                                v: [
                                  [-137.916, -375.167],
                                  [-169.338, -164.215],
                                  [-240.807, 53.84],
                                  [-247.71, 155.314],
                                  [-12.157, 293.302],
                                  [69.017, 377.963],
                                  [283.194, 268.251],
                                  [39.235, -88.334],
                                  [316.321, -195.693],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.029, -45.942],
                                  [-28.693, -127.727],
                                  [54.851, -41.073],
                                  [-128.819, -34.965],
                                  [-17.621, -67.76],
                                  [-118.966, 5.18],
                                  [131.601, 80.952],
                                  [-44.775, 145.475],
                                  [-9.363, 92],
                                ],
                                o: [
                                  [-97.728, 47.002],
                                  [28.693, 127.727],
                                  [-54.851, 41.073],
                                  [128.819, 34.965],
                                  [18.606, 68.971],
                                  [111.243, -3.514],
                                  [-131.601, -80.952],
                                  [43.337, -146.762],
                                  [9.363, -92],
                                ],
                                v: [
                                  [-138.246, -375.308],
                                  [-168.6, -163.686],
                                  [-241.213, 54.07],
                                  [-247.552, 155.098],
                                  [-13.071, 292.977],
                                  [68.809, 378.502],
                                  [283.863, 268.974],
                                  [38.844, -88.052],
                                  [317.226, -196.692],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.962, -46.076],
                                  [-28.854, -127.91],
                                  [54.965, -40.961],
                                  [-129.021, -34.944],
                                  [-17.63, -67.853],
                                  [-118.813, 5.11],
                                  [131.874, 81.019],
                                  [-44.89, 145.707],
                                  [-9.333, 92],
                                ],
                                o: [
                                  [-97.778, 47.113],
                                  [28.854, 127.91],
                                  [-54.965, 40.961],
                                  [129.021, 34.944],
                                  [18.592, 69.038],
                                  [111.259, -3.481],
                                  [-131.874, -81.019],
                                  [43.484, -146.966],
                                  [9.333, -92],
                                ],
                                v: [
                                  [-138.513, -375.387],
                                  [-167.922, -163.1],
                                  [-241.625, 54.184],
                                  [-247.401, 154.875],
                                  [-13.996, 292.654],
                                  [68.668, 379.096],
                                  [284.475, 269.697],
                                  [38.462, -87.763],
                                  [318.068, -197.69],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.897, -46.207],
                                  [-29.012, -128.089],
                                  [55.076, -40.852],
                                  [-129.22, -34.923],
                                  [-17.638, -67.945],
                                  [-118.662, 5.041],
                                  [132.142, 81.085],
                                  [-45.003, 145.934],
                                  [-9.303, 92],
                                ],
                                o: [
                                  [-97.828, 47.221],
                                  [29.012, 128.089],
                                  [-55.076, 40.852],
                                  [129.22, 34.923],
                                  [18.579, 69.103],
                                  [111.276, -3.448],
                                  [-132.142, -81.085],
                                  [43.628, -147.165],
                                  [9.303, -92],
                                ],
                                v: [
                                  [-138.776, -375.463],
                                  [-167.238, -162.572],
                                  [-242.032, 54.347],
                                  [-247.188, 154.709],
                                  [-14.993, 292.329],
                                  [68.58, 379.625],
                                  [285.089, 270.425],
                                  [38.089, -87.468],
                                  [318.903, -198.693],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.832, -46.335],
                                  [-29.167, -128.264],
                                  [55.185, -40.744],
                                  [-129.414, -34.903],
                                  [-17.646, -68.035],
                                  [-118.514, 4.974],
                                  [132.405, 81.15],
                                  [-45.114, 146.157],
                                  [-9.275, 92],
                                ],
                                o: [
                                  [-97.876, 47.327],
                                  [29.167, 128.264],
                                  [-55.185, 40.744],
                                  [129.414, 34.903],
                                  [18.566, 69.168],
                                  [111.292, -3.416],
                                  [-132.405, -81.15],
                                  [43.769, -147.361],
                                  [9.275, -92],
                                ],
                                v: [
                                  [-138.972, -375.54],
                                  [-166.62, -162.044],
                                  [-242.444, 54.452],
                                  [-246.981, 154.539],
                                  [-15.938, 291.944],
                                  [68.612, 380.211],
                                  [285.708, 271.099],
                                  [37.729, -87.165],
                                  [319.733, -199.695],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.769, -46.461],
                                  [-29.319, -128.436],
                                  [55.292, -40.639],
                                  [-129.605, -34.883],
                                  [-17.654, -68.123],
                                  [-118.37, 4.908],
                                  [132.662, 81.213],
                                  [-45.223, 146.376],
                                  [-9.246, 92],
                                ],
                                o: [
                                  [-97.923, 47.43],
                                  [29.319, 128.436],
                                  [-55.292, 40.639],
                                  [129.605, 34.883],
                                  [18.554, 69.231],
                                  [111.308, -3.385],
                                  [-132.662, -81.213],
                                  [43.907, -147.553],
                                  [9.246, -92],
                                ],
                                v: [
                                  [-139.167, -375.555],
                                  [-165.999, -161.517],
                                  [-242.915, 54.611],
                                  [-246.833, 154.43],
                                  [-16.954, 291.619],
                                  [68.643, 380.794],
                                  [286.274, 271.778],
                                  [37.378, -86.909],
                                  [320.495, -200.758],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.708, -46.584],
                                  [-29.467, -128.604],
                                  [55.397, -40.536],
                                  [-129.792, -34.863],
                                  [-17.662, -68.209],
                                  [-118.228, 4.843],
                                  [132.915, 81.275],
                                  [-45.329, 146.59],
                                  [-9.219, 92],
                                ],
                                o: [
                                  [-97.969, 47.532],
                                  [29.467, 128.604],
                                  [-55.397, 40.536],
                                  [129.792, 34.863],
                                  [18.542, 69.292],
                                  [111.323, -3.354],
                                  [-132.915, -81.275],
                                  [44.043, -147.741],
                                  [9.219, -92],
                                ],
                                v: [
                                  [-139.296, -375.625],
                                  [-165.377, -161.049],
                                  [-243.328, 54.708],
                                  [-246.628, 154.316],
                                  [-17.917, 291.292],
                                  [68.734, 381.371],
                                  [286.842, 272.46],
                                  [37.035, -86.646],
                                  [321.251, -201.826],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.647, -46.705],
                                  [-29.613, -128.769],
                                  [55.5, -40.434],
                                  [-129.975, -34.844],
                                  [-17.669, -68.294],
                                  [-118.089, 4.78],
                                  [133.162, 81.336],
                                  [-45.433, 146.8],
                                  [-9.191, 92],
                                ],
                                o: [
                                  [-98.014, 47.632],
                                  [29.613, 128.769],
                                  [-55.5, 40.434],
                                  [129.975, 34.844],
                                  [18.529, 69.353],
                                  [111.338, -3.324],
                                  [-133.162, -81.336],
                                  [44.176, -147.926],
                                  [9.191, -92],
                                ],
                                v: [
                                  [-139.357, -375.637],
                                  [-164.754, -160.524],
                                  [-243.799, 54.742],
                                  [-246.428, 154.198],
                                  [-18.952, 290.966],
                                  [68.882, 382.005],
                                  [287.415, 273.088],
                                  [36.705, -86.432],
                                  [322.001, -202.892],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.588, -46.824],
                                  [-29.756, -128.931],
                                  [55.6, -40.335],
                                  [-130.155, -34.825],
                                  [-17.676, -68.377],
                                  [-117.953, 4.718],
                                  [133.405, 81.396],
                                  [-45.536, 147.006],
                                  [-9.165, 92],
                                ],
                                o: [
                                  [-98.059, 47.73],
                                  [29.756, 128.931],
                                  [-55.6, 40.335],
                                  [130.155, 34.825],
                                  [18.518, 69.412],
                                  [111.353, -3.294],
                                  [-133.405, -81.396],
                                  [44.306, -148.106],
                                  [9.165, -92],
                                ],
                                v: [
                                  [-139.418, -375.65],
                                  [-164.191, -160.061],
                                  [-244.213, 54.713],
                                  [-246.229, 154.197],
                                  [-19.995, 290.584],
                                  [69.094, 382.578],
                                  [287.931, 273.724],
                                  [36.383, -86.215],
                                  [322.684, -203.962],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.53, -46.94],
                                  [-29.896, -129.09],
                                  [55.699, -40.238],
                                  [-130.331, -34.806],
                                  [-17.684, -68.458],
                                  [-117.819, 4.656],
                                  [133.643, 81.454],
                                  [-45.636, 147.208],
                                  [-9.138, 92],
                                ],
                                o: [
                                  [-98.102, 47.825],
                                  [29.896, 129.09],
                                  [-55.699, 40.238],
                                  [130.331, 34.806],
                                  [18.506, 69.47],
                                  [111.367, -3.265],
                                  [-133.643, -81.454],
                                  [44.434, -148.283],
                                  [9.138, -92],
                                ],
                                v: [
                                  [-139.412, -375.658],
                                  [-163.69, -159.654],
                                  [-244.627, 54.679],
                                  [-245.972, 154.137],
                                  [-21.11, 290.258],
                                  [69.364, 383.205],
                                  [288.451, 274.364],
                                  [36.013, -85.989],
                                  [323.364, -205.092],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.473, -47.054],
                                  [-30.034, -129.245],
                                  [55.796, -40.142],
                                  [-130.503, -34.788],
                                  [-17.691, -68.538],
                                  [-117.688, 4.597],
                                  [133.876, 81.511],
                                  [-45.734, 147.406],
                                  [-9.113, 92],
                                ],
                                o: [
                                  [-98.145, 47.919],
                                  [30.034, 129.245],
                                  [-55.796, 40.142],
                                  [130.503, 34.788],
                                  [18.495, 69.527],
                                  [111.382, -3.237],
                                  [-133.876, -81.511],
                                  [44.559, -148.457],
                                  [9.113, -92],
                                ],
                                v: [
                                  [-139.406, -375.604],
                                  [-163.183, -159.193],
                                  [-245.042, 54.583],
                                  [-245.774, 154.195],
                                  [-22.17, 289.928],
                                  [69.69, 383.827],
                                  [288.973, 274.944],
                                  [35.71, -85.758],
                                  [323.977, -206.167],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.417, -47.165],
                                  [-30.168, -129.398],
                                  [55.891, -40.049],
                                  [-130.672, -34.771],
                                  [-17.698, -68.616],
                                  [-117.56, 4.538],
                                  [134.104, 81.568],
                                  [-45.83, 147.6],
                                  [-9.088, 92],
                                ],
                                o: [
                                  [-98.187, 48.011],
                                  [30.168, 129.398],
                                  [-55.891, 40.049],
                                  [130.672, 34.771],
                                  [18.483, 69.583],
                                  [111.396, -3.209],
                                  [-134.104, -81.568],
                                  [44.682, -148.627],
                                  [9.088, -92],
                                ],
                                v: [
                                  [-139.332, -375.55],
                                  [-162.741, -158.791],
                                  [-245.457, 54.485],
                                  [-245.585, 154.249],
                                  [-23.239, 289.601],
                                  [70.083, 384.447],
                                  [289.441, 275.53],
                                  [35.418, -85.579],
                                  [324.585, -207.3],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.363, -47.275],
                                  [-30.3, -129.547],
                                  [55.983, -39.958],
                                  [-130.838, -34.753],
                                  [-17.705, -68.692],
                                  [-117.434, 4.481],
                                  [134.328, 81.622],
                                  [-45.924, 147.79],
                                  [-9.063, 92],
                                ],
                                o: [
                                  [-98.228, 48.102],
                                  [30.3, 129.547],
                                  [-55.983, 39.958],
                                  [130.838, 34.753],
                                  [18.473, 69.637],
                                  [111.409, -3.181],
                                  [-134.328, -81.622],
                                  [44.802, -148.794],
                                  [9.063, -92],
                                ],
                                v: [
                                  [-139.191, -375.551],
                                  [-162.293, -158.387],
                                  [-245.872, 54.321],
                                  [-245.392, 154.363],
                                  [-24.319, 289.274],
                                  [70.59, 385.063],
                                  [289.91, 276.124],
                                  [35.133, -85.396],
                                  [325.183, -208.43],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.309, -47.382],
                                  [-30.429, -129.693],
                                  [56.074, -39.868],
                                  [-131, -34.736],
                                  [-17.711, -68.767],
                                  [-117.311, 4.425],
                                  [134.547, 81.676],
                                  [-46.017, 147.976],
                                  [-9.039, 92],
                                ],
                                o: [
                                  [-98.268, 48.19],
                                  [30.429, 129.693],
                                  [-56.074, 39.868],
                                  [131, 34.736],
                                  [18.462, 69.691],
                                  [111.423, -3.155],
                                  [-134.547, -81.676],
                                  [44.92, -148.957],
                                  [9.039, -92],
                                ],
                                v: [
                                  [-139.05, -375.493],
                                  [-161.907, -158.046],
                                  [-246.288, 54.093],
                                  [-245.144, 154.477],
                                  [-25.407, 288.948],
                                  [71.099, 385.677],
                                  [290.325, 276.716],
                                  [34.913, -85.207],
                                  [325.718, -209.567],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.257, -47.487],
                                  [-30.556, -129.836],
                                  [56.164, -39.78],
                                  [-131.159, -34.719],
                                  [-17.718, -68.841],
                                  [-117.19, 4.37],
                                  [134.762, 81.729],
                                  [-46.107, 148.158],
                                  [-9.016, 92],
                                ],
                                o: [
                                  [-98.307, 48.276],
                                  [30.556, 129.836],
                                  [-56.164, 39.78],
                                  [131.159, 34.719],
                                  [18.451, 69.743],
                                  [111.436, -3.128],
                                  [-134.762, -81.729],
                                  [45.035, -149.117],
                                  [9.016, -92],
                                ],
                                v: [
                                  [-138.845, -375.375],
                                  [-161.583, -157.709],
                                  [-246.645, 53.864],
                                  [-244.955, 154.647],
                                  [-26.5, 288.62],
                                  [71.612, 386.406],
                                  [290.74, 277.256],
                                  [34.646, -85.069],
                                  [326.247, -210.761],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.205, -47.589],
                                  [-30.68, -129.977],
                                  [56.251, -39.694],
                                  [-131.315, -34.703],
                                  [-17.724, -68.913],
                                  [-117.072, 4.316],
                                  [134.972, 81.781],
                                  [-46.196, 148.337],
                                  [-8.992, 92],
                                ],
                                o: [
                                  [-98.346, 48.361],
                                  [30.68, 129.977],
                                  [-56.251, 39.694],
                                  [131.315, 34.703],
                                  [18.441, 69.795],
                                  [111.449, -3.103],
                                  [-134.972, -81.781],
                                  [45.148, -149.274],
                                  [8.992, -92],
                                ],
                                v: [
                                  [-138.573, -375.311],
                                  [-161.257, -157.37],
                                  [-247.002, 53.572],
                                  [-244.774, 154.819],
                                  [-27.603, 288.292],
                                  [72.241, 387.189],
                                  [291.16, 277.801],
                                  [34.443, -84.929],
                                  [326.712, -211.898],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.155, -47.69],
                                  [-30.801, -130.114],
                                  [56.337, -39.61],
                                  [-131.468, -34.687],
                                  [-17.731, -68.983],
                                  [-116.956, 4.263],
                                  [135.178, 81.832],
                                  [-46.283, 148.512],
                                  [-8.97, 92],
                                ],
                                o: [
                                  [-98.384, 48.444],
                                  [30.801, 130.114],
                                  [-56.337, 39.61],
                                  [131.468, 34.687],
                                  [18.431, 69.845],
                                  [111.461, -3.078],
                                  [-135.178, -81.832],
                                  [45.259, -149.427],
                                  [8.97, -92],
                                ],
                                v: [
                                  [-138.355, -375.185],
                                  [-160.989, -157.032],
                                  [-247.36, 53.271],
                                  [-244.589, 154.991],
                                  [-28.713, 287.961],
                                  [72.872, 387.973],
                                  [291.526, 278.286],
                                  [34.248, -84.782],
                                  [327.168, -213.037],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.106, -47.789],
                                  [-30.92, -130.249],
                                  [56.42, -39.527],
                                  [-131.617, -34.671],
                                  [-17.737, -69.052],
                                  [-116.843, 4.211],
                                  [135.38, 81.881],
                                  [-46.368, 148.683],
                                  [-8.948, 92],
                                ],
                                o: [
                                  [-98.421, 48.526],
                                  [30.92, 130.249],
                                  [-56.42, 39.527],
                                  [131.617, 34.671],
                                  [18.421, 69.894],
                                  [111.474, -3.053],
                                  [-135.38, -81.881],
                                  [45.368, -149.578],
                                  [8.948, -92],
                                ],
                                v: [
                                  [-138.015, -375.12],
                                  [-160.723, -156.757],
                                  [-247.717, 52.91],
                                  [-244.464, 155.164],
                                  [-29.771, 287.693],
                                  [73.563, 388.812],
                                  [291.892, 278.838],
                                  [34.058, -84.689],
                                  [327.62, -214.232],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.057, -47.885],
                                  [-31.037, -130.381],
                                  [56.503, -39.446],
                                  [-131.764, -34.656],
                                  [-17.743, -69.12],
                                  [-116.732, 4.16],
                                  [135.578, 81.93],
                                  [-46.451, 148.851],
                                  [-8.926, 92],
                                ],
                                o: [
                                  [-98.457, 48.605],
                                  [31.037, 130.381],
                                  [-56.503, 39.446],
                                  [131.764, 34.656],
                                  [18.411, 69.943],
                                  [111.486, -3.029],
                                  [-135.578, -81.93],
                                  [45.474, -149.725],
                                  [8.926, -92],
                                ],
                                v: [
                                  [-137.67, -374.992],
                                  [-160.516, -156.543],
                                  [-248.075, 52.544],
                                  [-244.398, 155.335],
                                  [-30.836, 287.364],
                                  [74.252, 389.704],
                                  [292.203, 279.33],
                                  [33.938, -84.536],
                                  [328.007, -215.37],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.01, -47.98],
                                  [-31.151, -130.51],
                                  [56.583, -39.367],
                                  [-131.907, -34.641],
                                  [-17.749, -69.186],
                                  [-116.623, 4.11],
                                  [135.772, 81.978],
                                  [-46.533, 149.015],
                                  [-8.905, 92],
                                ],
                                o: [
                                  [-98.493, 48.684],
                                  [31.151, 130.51],
                                  [-56.583, 39.367],
                                  [131.907, 34.641],
                                  [18.402, 69.99],
                                  [111.498, -3.005],
                                  [-135.772, -81.977],
                                  [45.578, -149.87],
                                  [8.904, -92],
                                ],
                                v: [
                                  [-137.321, -374.86],
                                  [-160.311, -156.327],
                                  [-248.374, 52.174],
                                  [-244.329, 155.507],
                                  [-31.967, 287.093],
                                  [75.006, 390.601],
                                  [292.515, 279.767],
                                  [33.764, -84.435],
                                  [328.33, -216.568],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.964, -48.073],
                                  [-31.263, -130.637],
                                  [56.662, -39.289],
                                  [-132.048, -34.626],
                                  [-17.755, -69.251],
                                  [-116.516, 4.062],
                                  [135.961, 82.024],
                                  [-46.613, 149.176],
                                  [-8.884, 92],
                                ],
                                o: [
                                  [-98.527, 48.76],
                                  [31.263, 130.637],
                                  [-56.662, 39.289],
                                  [132.048, 34.626],
                                  [18.393, 70.036],
                                  [111.509, -2.982],
                                  [-135.961, -82.024],
                                  [45.68, -150.011],
                                  [8.884, -92],
                                ],
                                v: [
                                  [-136.905, -374.789],
                                  [-160.048, -156.172],
                                  [-248.672, 51.739],
                                  [-244.324, 155.68],
                                  [-32.985, 286.82],
                                  [75.817, 391.491],
                                  [292.831, 280.209],
                                  [33.656, -84.329],
                                  [328.704, -217.705],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.918, -48.164],
                                  [-31.372, -130.761],
                                  [56.739, -39.213],
                                  [-132.185, -34.611],
                                  [-17.76, -69.314],
                                  [-116.412, 4.014],
                                  [136.147, 82.07],
                                  [-46.691, 149.334],
                                  [-8.863, 92],
                                ],
                                o: [
                                  [-98.561, 48.835],
                                  [31.372, 130.761],
                                  [-56.739, 39.213],
                                  [132.185, 34.611],
                                  [18.384, 70.082],
                                  [111.52, -2.959],
                                  [-136.147, -82.07],
                                  [45.78, -150.15],
                                  [8.863, -92],
                                ],
                                v: [
                                  [-136.487, -374.654],
                                  [-159.843, -156.018],
                                  [-248.971, 51.241],
                                  [-244.375, 155.794],
                                  [-34.069, 286.547],
                                  [76.631, 392.501],
                                  [293.091, 280.652],
                                  [33.554, -84.279],
                                  [328.958, -218.899],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.874, -48.253],
                                  [-31.48, -130.882],
                                  [56.815, -39.138],
                                  [-132.32, -34.597],
                                  [-17.766, -69.377],
                                  [-116.31, 3.967],
                                  [136.329, 82.115],
                                  [-46.768, 149.489],
                                  [-8.843, 92],
                                ],
                                o: [
                                  [-98.595, 48.908],
                                  [31.48, 130.882],
                                  [-56.815, 39.139],
                                  [132.32, 34.597],
                                  [18.375, 70.126],
                                  [111.532, -2.937],
                                  [-136.329, -82.115],
                                  [45.878, -150.285],
                                  [8.843, -92],
                                ],
                                v: [
                                  [-136.003, -374.515],
                                  [-159.584, -155.925],
                                  [-249.27, 50.797],
                                  [-244.427, 155.966],
                                  [-35.1, 286.277],
                                  [77.446, 393.45],
                                  [293.352, 281.095],
                                  [33.461, -84.226],
                                  [329.263, -220.029],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.83, -48.34],
                                  [-31.585, -131.001],
                                  [56.889, -39.065],
                                  [-132.452, -34.583],
                                  [-17.771, -69.438],
                                  [-116.209, 3.922],
                                  [136.508, 82.158],
                                  [-46.843, 149.64],
                                  [-8.824, 92],
                                ],
                                o: [
                                  [-98.627, 48.98],
                                  [31.585, 131.001],
                                  [-56.889, 39.066],
                                  [132.452, 34.583],
                                  [18.366, 70.17],
                                  [111.542, -2.915],
                                  [-136.508, -82.158],
                                  [45.974, -150.418],
                                  [8.824, -92],
                                ],
                                v: [
                                  [-135.514, -374.378],
                                  [-159.386, -155.83],
                                  [-249.566, 50.29],
                                  [-244.54, 156.082],
                                  [-36.136, 286.004],
                                  [78.323, 394.456],
                                  [293.559, 281.48],
                                  [33.432, -84.168],
                                  [329.507, -221.156],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.788, -48.425],
                                  [-31.688, -131.118],
                                  [56.961, -38.994],
                                  [-132.582, -34.57],
                                  [-17.777, -69.497],
                                  [-116.111, 3.877],
                                  [136.682, 82.201],
                                  [-46.917, 149.788],
                                  [-8.804, 92],
                                ],
                                o: [
                                  [-98.659, 49.051],
                                  [31.688, 131.118],
                                  [-56.961, 38.994],
                                  [132.582, 34.57],
                                  [18.358, 70.212],
                                  [111.553, -2.894],
                                  [-136.682, -82.201],
                                  [46.067, -150.548],
                                  [8.804, -92],
                                ],
                                v: [
                                  [-135.021, -374.236],
                                  [-159.126, -155.797],
                                  [-249.805, 49.719],
                                  [-244.653, 156.256],
                                  [-37.118, 285.731],
                                  [79.199, 395.464],
                                  [293.77, 281.869],
                                  [33.35, -84.107],
                                  [329.687, -222.342],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.746, -48.509],
                                  [-31.788, -131.232],
                                  [57.032, -38.924],
                                  [-132.708, -34.556],
                                  [-17.782, -69.556],
                                  [-116.015, 3.833],
                                  [136.853, 82.243],
                                  [-46.989, 149.934],
                                  [-8.786, 92],
                                ],
                                o: [
                                  [-98.691, 49.12],
                                  [31.788, 131.232],
                                  [-57.032, 38.924],
                                  [132.708, 34.556],
                                  [18.349, 70.254],
                                  [111.564, -2.873],
                                  [-136.853, -82.243],
                                  [46.159, -150.675],
                                  [8.786, -92],
                                ],
                                v: [
                                  [-134.465, -374.092],
                                  [-158.93, -155.826],
                                  [-250.042, 49.205],
                                  [-244.824, 156.37],
                                  [-38.109, 285.515],
                                  [80.136, 396.526],
                                  [293.984, 282.256],
                                  [33.334, -84.105],
                                  [329.861, -223.463],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.705, -48.59],
                                  [-31.887, -131.344],
                                  [57.102, -38.856],
                                  [-132.832, -34.543],
                                  [-17.787, -69.613],
                                  [-115.921, 3.79],
                                  [137.021, 82.284],
                                  [-47.059, 150.076],
                                  [-8.767, 92],
                                ],
                                o: [
                                  [-98.721, 49.187],
                                  [31.887, 131.344],
                                  [-57.102, 38.856],
                                  [132.832, 34.543],
                                  [18.341, 70.295],
                                  [111.574, -2.852],
                                  [-137.021, -82.284],
                                  [46.249, -150.8],
                                  [8.767, -92],
                                ],
                                v: [
                                  [-133.901, -373.944],
                                  [-158.732, -155.854],
                                  [-250.281, 48.625],
                                  [-244.999, 156.546],
                                  [-39.102, 285.298],
                                  [81.075, 397.593],
                                  [294.145, 282.586],
                                  [33.322, -84.096],
                                  [330.033, -224.582],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.665, -48.67],
                                  [-31.984, -131.453],
                                  [57.17, -38.789],
                                  [-132.954, -34.531],
                                  [-17.792, -69.669],
                                  [-115.829, 3.748],
                                  [137.185, 82.325],
                                  [-47.128, 150.215],
                                  [-8.749, 92],
                                ],
                                o: [
                                  [-98.751, 49.253],
                                  [31.984, 131.453],
                                  [-57.17, 38.789],
                                  [132.954, 34.531],
                                  [18.333, 70.335],
                                  [111.584, -2.832],
                                  [-137.185, -82.325],
                                  [46.337, -150.922],
                                  [8.749, -92],
                                ],
                                v: [
                                  [-133.336, -373.798],
                                  [-158.535, -155.886],
                                  [-250.517, 48.04],
                                  [-245.232, 156.662],
                                  [-40.04, 285.081],
                                  [82.074, 398.656],
                                  [294.307, 282.914],
                                  [33.376, -84.084],
                                  [330.138, -225.699],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.626, -48.749],
                                  [-32.078, -131.56],
                                  [57.236, -38.723],
                                  [-133.072, -34.518],
                                  [-17.797, -69.724],
                                  [-115.739, 3.707],
                                  [137.345, 82.364],
                                  [-47.196, 150.351],
                                  [-8.732, 92],
                                ],
                                o: [
                                  [-98.781, 49.318],
                                  [32.078, 131.56],
                                  [-57.236, 38.723],
                                  [133.072, 34.518],
                                  [18.325, 70.374],
                                  [111.594, -2.813],
                                  [-137.345, -82.364],
                                  [46.424, -151.042],
                                  [8.732, -92],
                                ],
                                v: [
                                  [-132.768, -373.707],
                                  [-158.399, -155.975],
                                  [-250.694, 47.454],
                                  [-245.527, 156.836],
                                  [-40.923, 284.861],
                                  [83.017, 399.721],
                                  [294.473, 283.245],
                                  [33.378, -84.131],
                                  [330.241, -226.752],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.587, -48.826],
                                  [-32.171, -131.665],
                                  [57.302, -38.659],
                                  [-133.189, -34.506],
                                  [-17.802, -69.778],
                                  [-115.651, 3.667],
                                  [137.502, 82.403],
                                  [-47.262, 150.484],
                                  [-8.714, 92],
                                ],
                                o: [
                                  [-98.81, 49.381],
                                  [32.171, 131.665],
                                  [-57.302, 38.659],
                                  [133.189, 34.506],
                                  [18.317, 70.413],
                                  [111.603, -2.794],
                                  [-137.502, -82.403],
                                  [46.508, -151.159],
                                  [8.714, -92],
                                ],
                                v: [
                                  [-132.132, -373.554],
                                  [-158.264, -156.129],
                                  [-250.868, 46.861],
                                  [-245.822, 156.954],
                                  [-41.814, 284.638],
                                  [84.02, 400.784],
                                  [294.581, 283.516],
                                  [33.443, -84.113],
                                  [330.279, -227.864],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.55, -48.901],
                                  [-32.261, -131.767],
                                  [57.366, -38.596],
                                  [-133.303, -34.494],
                                  [-17.806, -69.831],
                                  [-115.564, 3.627],
                                  [137.656, 82.441],
                                  [-47.327, 150.615],
                                  [-8.697, 92],
                                ],
                                o: [
                                  [-98.838, 49.443],
                                  [32.261, 131.767],
                                  [-57.366, 38.596],
                                  [133.303, 34.494],
                                  [18.31, 70.45],
                                  [111.613, -2.775],
                                  [-137.656, -82.441],
                                  [46.591, -151.274],
                                  [8.697, -92],
                                ],
                                v: [
                                  [-131.493, -373.397],
                                  [-158.127, -156.282],
                                  [-251.042, 46.204],
                                  [-246.179, 157.133],
                                  [-42.706, 284.475],
                                  [85.025, 401.848],
                                  [294.694, 283.788],
                                  [33.515, -84.214],
                                  [330.312, -228.913],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.513, -48.974],
                                  [-32.35, -131.868],
                                  [57.428, -38.534],
                                  [-133.414, -34.482],
                                  [-17.811, -69.882],
                                  [-115.48, 3.589],
                                  [137.807, 82.478],
                                  [-47.39, 150.743],
                                  [-8.681, 92],
                                ],
                                o: [
                                  [-98.865, 49.504],
                                  [32.35, 131.868],
                                  [-57.428, 38.534],
                                  [133.414, 34.482],
                                  [18.303, 70.487],
                                  [111.622, -2.756],
                                  [-137.807, -82.478],
                                  [46.672, -151.386],
                                  [8.681, -92],
                                ],
                                v: [
                                  [-130.852, -373.296],
                                  [-157.993, -156.5],
                                  [-251.156, 45.604],
                                  [-246.536, 157.312],
                                  [-43.545, 284.251],
                                  [86.03, 402.912],
                                  [294.812, 284.063],
                                  [33.592, -84.251],
                                  [330.281, -229.961],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.477, -49.046],
                                  [-32.437, -131.966],
                                  [57.489, -38.474],
                                  [-133.523, -34.471],
                                  [-17.815, -69.932],
                                  [-115.397, 3.551],
                                  [137.954, 82.514],
                                  [-47.452, 150.868],
                                  [-8.665, 92],
                                ],
                                o: [
                                  [-98.892, 49.563],
                                  [32.437, 131.966],
                                  [-57.489, 38.474],
                                  [133.523, 34.471],
                                  [18.295, 70.523],
                                  [111.631, -2.738],
                                  [-137.954, -82.514],
                                  [46.751, -151.496],
                                  [8.665, -92],
                                ],
                                v: [
                                  [-130.148, -373.191],
                                  [-157.917, -156.718],
                                  [-251.331, 44.999],
                                  [-246.956, 157.43],
                                  [-44.326, 284.085],
                                  [87.096, 403.975],
                                  [294.869, 284.335],
                                  [33.732, -84.346],
                                  [330.307, -231.008],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.442, -49.117],
                                  [-32.522, -132.062],
                                  [57.549, -38.415],
                                  [-133.63, -34.46],
                                  [-17.82, -69.982],
                                  [-115.316, 3.514],
                                  [138.098, 82.549],
                                  [-47.513, 150.99],
                                  [-8.649, 92],
                                ],
                                o: [
                                  [-98.919, 49.621],
                                  [32.522, 132.062],
                                  [-57.549, 38.415],
                                  [133.63, 34.459],
                                  [18.288, 70.558],
                                  [111.64, -2.721],
                                  [-138.098, -82.549],
                                  [46.828, -151.603],
                                  [8.649, -92],
                                ],
                                v: [
                                  [-129.5, -373.021],
                                  [-157.842, -156.996],
                                  [-251.443, 44.331],
                                  [-247.376, 157.611],
                                  [-45.053, 283.916],
                                  [88.165, 405.039],
                                  [294.931, 284.549],
                                  [33.879, -84.438],
                                  [330.206, -232.05],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.407, -49.186],
                                  [-32.605, -132.157],
                                  [57.608, -38.357],
                                  [-133.735, -34.449],
                                  [-17.824, -70.03],
                                  [-115.237, 3.477],
                                  [138.239, 82.584],
                                  [-47.573, 151.11],
                                  [-8.633, 92],
                                ],
                                o: [
                                  [-98.945, 49.678],
                                  [32.605, 132.157],
                                  [-57.608, 38.357],
                                  [133.735, 34.448],
                                  [18.281, 70.593],
                                  [111.648, -2.704],
                                  [-138.239, -82.584],
                                  [46.904, -151.708],
                                  [8.633, -92],
                                ],
                                v: [
                                  [-128.787, -372.911],
                                  [-157.768, -157.279],
                                  [-251.494, 43.717],
                                  [-247.858, 157.792],
                                  [-45.782, 283.804],
                                  [89.176, 406.103],
                                  [294.995, 284.766],
                                  [34.031, -84.526],
                                  [330.102, -233.091],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.373, -49.253],
                                  [-32.687, -132.249],
                                  [57.665, -38.3],
                                  [-133.837, -34.438],
                                  [-17.828, -70.077],
                                  [-115.159, 3.442],
                                  [138.378, 82.618],
                                  [-47.631, 151.227],
                                  [-8.618, 92],
                                ],
                                o: [
                                  [-98.97, 49.734],
                                  [32.687, 132.249],
                                  [-57.665, 38.3],
                                  [133.837, 34.438],
                                  [18.275, 70.627],
                                  [111.657, -2.687],
                                  [-138.378, -82.618],
                                  [46.978, -151.811],
                                  [8.618, -92],
                                ],
                                v: [
                                  [-128.132, -372.855],
                                  [-157.751, -157.561],
                                  [-251.604, 43.099],
                                  [-248.341, 157.912],
                                  [-46.516, 283.633],
                                  [90.246, 407.107],
                                  [295.002, 284.98],
                                  [34.188, -84.675],
                                  [329.995, -234.067],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.34, -49.319],
                                  [-32.766, -132.339],
                                  [57.721, -38.245],
                                  [-133.937, -34.427],
                                  [-17.832, -70.123],
                                  [-115.083, 3.407],
                                  [138.513, 82.651],
                                  [-47.688, 151.342],
                                  [-8.603, 92],
                                ],
                                o: [
                                  [-98.995, 49.788],
                                  [32.766, 132.339],
                                  [-57.721, 38.245],
                                  [133.937, 34.427],
                                  [18.268, 70.66],
                                  [111.665, -2.67],
                                  [-138.513, -82.651],
                                  [47.051, -151.912],
                                  [8.603, -92],
                                ],
                                v: [
                                  [-127.415, -372.734],
                                  [-157.736, -157.907],
                                  [-251.654, 42.481],
                                  [-248.826, 158.095],
                                  [-47.131, 283.52],
                                  [91.319, 408.171],
                                  [295.01, 285.136],
                                  [34.35, -84.879],
                                  [329.882, -235.044],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.308, -49.384],
                                  [-32.844, -132.427],
                                  [57.776, -38.191],
                                  [-134.035, -34.417],
                                  [-17.836, -70.169],
                                  [-115.009, 3.373],
                                  [138.645, 82.684],
                                  [-47.744, 151.454],
                                  [-8.589, 92],
                                ],
                                o: [
                                  [-99.019, 49.842],
                                  [32.844, 132.427],
                                  [-57.776, 38.191],
                                  [134.035, 34.417],
                                  [18.262, 70.692],
                                  [111.673, -2.654],
                                  [-138.645, -82.684],
                                  [47.122, -152.01],
                                  [8.589, -92],
                                ],
                                v: [
                                  [-126.693, -372.671],
                                  [-157.718, -158.254],
                                  [-251.704, 41.796],
                                  [-249.43, 158.278],
                                  [-47.751, 283.404],
                                  [92.393, 409.176],
                                  [295.021, 285.292],
                                  [34.576, -85.022],
                                  [329.706, -235.958],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.276, -49.447],
                                  [-32.921, -132.514],
                                  [57.83, -38.138],
                                  [-134.131, -34.407],
                                  [-17.84, -70.213],
                                  [-114.936, 3.34],
                                  [138.775, 82.716],
                                  [-47.798, 151.564],
                                  [-8.574, 92],
                                ],
                                o: [
                                  [-99.043, 49.894],
                                  [32.921, 132.514],
                                  [-57.83, 38.138],
                                  [134.131, 34.407],
                                  [18.255, 70.724],
                                  [111.681, -2.638],
                                  [-138.775, -82.716],
                                  [47.192, -152.107],
                                  [8.574, -92],
                                ],
                                v: [
                                  [-126.029, -372.546],
                                  [-157.763, -158.663],
                                  [-251.754, 41.227],
                                  [-249.977, 158.462],
                                  [-48.313, 283.287],
                                  [93.468, 410.18],
                                  [294.974, 285.449],
                                  [34.807, -85.224],
                                  [329.465, -236.929],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.245, -49.509],
                                  [-32.995, -132.598],
                                  [57.883, -38.086],
                                  [-134.225, -34.397],
                                  [-17.844, -70.256],
                                  [-114.865, 3.308],
                                  [138.901, 82.747],
                                  [-47.852, 151.672],
                                  [-8.56, 92],
                                ],
                                o: [
                                  [-99.066, 49.945],
                                  [32.995, 132.598],
                                  [-57.883, 38.086],
                                  [134.225, 34.397],
                                  [18.249, 70.755],
                                  [111.689, -2.623],
                                  [-138.901, -82.747],
                                  [47.26, -152.201],
                                  [8.56, -92],
                                ],
                                v: [
                                  [-125.301, -372.477],
                                  [-157.868, -159.075],
                                  [-251.741, 40.597],
                                  [-250.587, 158.586],
                                  [-48.879, 283.228],
                                  [94.544, 411.126],
                                  [294.989, 285.606],
                                  [35.041, -85.483],
                                  [329.28, -237.839],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.215, -49.57],
                                  [-33.068, -132.681],
                                  [57.934, -38.035],
                                  [-134.316, -34.387],
                                  [-17.848, -70.299],
                                  [-114.795, 3.276],
                                  [139.025, 82.777],
                                  [-47.904, 151.777],
                                  [-8.547, 92],
                                ],
                                o: [
                                  [-99.089, 49.995],
                                  [33.068, 132.681],
                                  [-57.934, 38.035],
                                  [134.316, 34.387],
                                  [18.243, 70.785],
                                  [111.696, -2.608],
                                  [-139.025, -82.777],
                                  [47.327, -152.294],
                                  [8.547, -92],
                                ],
                                v: [
                                  [-124.632, -372.464],
                                  [-157.97, -159.488],
                                  [-251.731, 39.961],
                                  [-251.258, 158.773],
                                  [-49.388, 283.167],
                                  [95.623, 412.13],
                                  [294.947, 285.765],
                                  [35.281, -85.741],
                                  [328.969, -238.746],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.186, -49.629],
                                  [-33.14, -132.762],
                                  [57.984, -37.986],
                                  [-134.406, -34.378],
                                  [-17.852, -70.34],
                                  [-114.727, 3.245],
                                  [139.146, 82.807],
                                  [-47.955, 151.88],
                                  [-8.534, 92],
                                ],
                                o: [
                                  [-99.111, 50.044],
                                  [33.14, 132.762],
                                  [-57.984, 37.986],
                                  [134.406, 34.378],
                                  [18.237, 70.814],
                                  [111.704, -2.593],
                                  [-139.146, -82.807],
                                  [47.392, -152.384],
                                  [8.534, -92],
                                ],
                                v: [
                                  [-123.899, -372.388],
                                  [-158.073, -159.964],
                                  [-251.717, 39.386],
                                  [-251.93, 158.959],
                                  [-49.776, 283.104],
                                  [96.701, 413.076],
                                  [294.845, 285.862],
                                  [35.586, -85.996],
                                  [328.716, -239.59],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.157, -49.687],
                                  [-33.21, -132.841],
                                  [58.034, -37.937],
                                  [-134.494, -34.369],
                                  [-17.855, -70.381],
                                  [-114.66, 3.214],
                                  [139.265, 82.836],
                                  [-48.005, 151.981],
                                  [-8.521, 92],
                                ],
                                o: [
                                  [-99.133, 50.092],
                                  [33.21, 132.841],
                                  [-58.034, 37.937],
                                  [134.494, 34.369],
                                  [18.231, 70.843],
                                  [111.711, -2.578],
                                  [-139.265, -82.836],
                                  [47.455, -152.472],
                                  [8.52, -92],
                                ],
                                v: [
                                  [-123.223, -372.372],
                                  [-158.237, -160.443],
                                  [-251.705, 38.805],
                                  [-252.602, 159.146],
                                  [-50.169, 283.04],
                                  [97.721, 413.962],
                                  [294.805, 285.961],
                                  [35.894, -86.31],
                                  [328.398, -240.433],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.128, -49.743],
                                  [-33.278, -132.918],
                                  [58.082, -37.89],
                                  [-134.58, -34.36],
                                  [-17.859, -70.42],
                                  [-114.595, 3.185],
                                  [139.381, 82.865],
                                  [-48.054, 152.079],
                                  [-8.508, 92],
                                ],
                                o: [
                                  [-99.154, 50.138],
                                  [33.278, 132.918],
                                  [-58.082, 37.89],
                                  [134.58, 34.36],
                                  [18.226, 70.872],
                                  [111.718, -2.564],
                                  [-139.381, -82.865],
                                  [47.518, -152.559],
                                  [8.508, -92],
                                ],
                                v: [
                                  [-122.546, -372.411],
                                  [-158.399, -160.922],
                                  [-251.632, 38.283],
                                  [-253.337, 159.274],
                                  [-50.562, 283.034],
                                  [98.803, 414.847],
                                  [294.707, 286.059],
                                  [36.208, -86.623],
                                  [328.076, -241.213],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.101, -49.799],
                                  [-33.345, -132.994],
                                  [58.129, -37.843],
                                  [-134.664, -34.351],
                                  [-17.862, -70.459],
                                  [-114.531, 3.156],
                                  [139.495, 82.893],
                                  [-48.102, 152.176],
                                  [-8.495, 92],
                                ],
                                o: [
                                  [-99.175, 50.184],
                                  [33.345, 132.994],
                                  [-58.129, 37.843],
                                  [134.664, 34.351],
                                  [18.22, 70.899],
                                  [111.725, -2.55],
                                  [-139.495, -82.893],
                                  [47.579, -152.643],
                                  [8.495, -92],
                                ],
                                v: [
                                  [-121.866, -372.389],
                                  [-158.621, -161.405],
                                  [-251.558, 37.696],
                                  [-254.074, 159.462],
                                  [-50.839, 282.966],
                                  [99.886, 415.673],
                                  [294.61, 286.156],
                                  [36.585, -86.994],
                                  [327.69, -242.053],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.073, -49.853],
                                  [-33.41, -133.068],
                                  [58.175, -37.798],
                                  [-134.746, -34.342],
                                  [-17.866, -70.497],
                                  [-114.469, 3.127],
                                  [139.606, 82.92],
                                  [-48.148, 152.27],
                                  [-8.483, 92],
                                ],
                                o: [
                                  [-99.195, 50.229],
                                  [33.41, 133.068],
                                  [-58.175, 37.798],
                                  [134.746, 34.342],
                                  [18.215, 70.927],
                                  [111.732, -2.537],
                                  [-139.606, -82.92],
                                  [47.638, -152.726],
                                  [8.483, -92],
                                ],
                                v: [
                                  [-121.184, -372.423],
                                  [-158.842, -161.949],
                                  [-251.483, 37.168],
                                  [-254.813, 159.59],
                                  [-51.055, 282.956],
                                  [100.909, 416.497],
                                  [294.515, 286.194],
                                  [36.905, -87.363],
                                  [327.3, -242.768],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.101, -49.799],
                                  [-33.345, -132.994],
                                  [58.129, -37.843],
                                  [-134.664, -34.351],
                                  [-17.862, -70.459],
                                  [-114.531, 3.156],
                                  [139.495, 82.893],
                                  [-48.102, 152.176],
                                  [-8.495, 92],
                                ],
                                o: [
                                  [-99.175, 50.184],
                                  [33.345, 132.994],
                                  [-58.129, 37.843],
                                  [134.664, 34.351],
                                  [18.22, 70.899],
                                  [111.725, -2.55],
                                  [-139.495, -82.893],
                                  [47.579, -152.643],
                                  [8.495, -92],
                                ],
                                v: [
                                  [-120.324, -372.3],
                                  [-159.104, -162.558],
                                  [-251.382, 36.525],
                                  [-255.571, 159.751],
                                  [-51.459, 282.891],
                                  [101.951, 417.149],
                                  [294.481, 286.154],
                                  [37.635, -87.529],
                                  [326.603, -243.333],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.128, -49.743],
                                  [-33.278, -132.918],
                                  [58.082, -37.89],
                                  [-134.58, -34.36],
                                  [-17.859, -70.42],
                                  [-114.595, 3.185],
                                  [139.381, 82.865],
                                  [-48.054, 152.079],
                                  [-8.508, 92],
                                ],
                                o: [
                                  [-99.154, 50.138],
                                  [33.278, 132.918],
                                  [-58.082, 37.89],
                                  [134.58, 34.36],
                                  [18.226, 70.872],
                                  [111.718, -2.564],
                                  [-139.381, -82.865],
                                  [47.518, -152.559],
                                  [8.508, -92],
                                ],
                                v: [
                                  [-119.463, -372.235],
                                  [-159.369, -163.226],
                                  [-251.343, 35.881],
                                  [-256.326, 159.848],
                                  [-51.804, 282.888],
                                  [102.993, 417.796],
                                  [294.448, 286.175],
                                  [38.369, -87.752],
                                  [325.843, -243.832],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.157, -49.687],
                                  [-33.21, -132.841],
                                  [58.034, -37.937],
                                  [-134.494, -34.369],
                                  [-17.855, -70.381],
                                  [-114.66, 3.214],
                                  [139.265, 82.836],
                                  [-48.005, 151.981],
                                  [-8.52, 92],
                                ],
                                o: [
                                  [-99.133, 50.092],
                                  [33.21, 132.841],
                                  [-58.034, 37.937],
                                  [134.494, 34.369],
                                  [18.231, 70.843],
                                  [111.711, -2.578],
                                  [-139.265, -82.836],
                                  [47.455, -152.472],
                                  [8.521, -92],
                                ],
                                v: [
                                  [-118.603, -372.171],
                                  [-159.635, -163.834],
                                  [-251.18, 35.233],
                                  [-257.079, 159.947],
                                  [-52.028, 282.883],
                                  [104.033, 418.378],
                                  [294.358, 286.134],
                                  [39.167, -87.971],
                                  [325.082, -244.269],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.186, -49.629],
                                  [-33.14, -132.762],
                                  [57.984, -37.986],
                                  [-134.406, -34.378],
                                  [-17.852, -70.34],
                                  [-114.727, 3.245],
                                  [139.146, 82.807],
                                  [-47.955, 151.88],
                                  [-8.534, 92],
                                ],
                                o: [
                                  [-99.111, 50.044],
                                  [33.14, 132.762],
                                  [-57.984, 37.986],
                                  [134.406, 34.378],
                                  [18.237, 70.814],
                                  [111.704, -2.593],
                                  [-139.146, -82.807],
                                  [47.392, -152.384],
                                  [8.533, -92],
                                ],
                                v: [
                                  [-117.74, -372.104],
                                  [-160.025, -164.504],
                                  [-251.081, 34.645],
                                  [-257.893, 160.044],
                                  [-52.256, 282.881],
                                  [105.01, 418.895],
                                  [294.268, 286.035],
                                  [39.968, -88.249],
                                  [324.319, -244.701],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.215, -49.57],
                                  [-33.068, -132.681],
                                  [57.934, -38.035],
                                  [-134.316, -34.387],
                                  [-17.848, -70.299],
                                  [-114.795, 3.276],
                                  [139.025, 82.777],
                                  [-47.904, 151.777],
                                  [-8.547, 92],
                                ],
                                o: [
                                  [-99.089, 49.995],
                                  [33.068, 132.681],
                                  [-57.934, 38.035],
                                  [134.316, 34.387],
                                  [18.243, 70.785],
                                  [111.696, -2.608],
                                  [-139.025, -82.777],
                                  [47.327, -152.294],
                                  [8.547, -92],
                                ],
                                v: [
                                  [-116.935, -372.099],
                                  [-160.355, -165.173],
                                  [-250.981, 34.056],
                                  [-258.702, 160.142],
                                  [-52.425, 282.878],
                                  [106.044, 419.41],
                                  [294.18, 285.994],
                                  [40.772, -88.522],
                                  [323.492, -245.128],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.245, -49.509],
                                  [-32.995, -132.598],
                                  [57.883, -38.086],
                                  [-134.225, -34.397],
                                  [-17.844, -70.256],
                                  [-114.865, 3.308],
                                  [138.901, 82.747],
                                  [-47.852, 151.672],
                                  [-8.56, 92],
                                ],
                                o: [
                                  [-99.066, 49.945],
                                  [32.995, 132.598],
                                  [-57.883, 38.086],
                                  [134.225, 34.397],
                                  [18.249, 70.755],
                                  [111.689, -2.623],
                                  [-138.901, -82.747],
                                  [47.26, -152.201],
                                  [8.56, -92],
                                ],
                                v: [
                                  [-116.131, -372.091],
                                  [-160.745, -165.783],
                                  [-250.821, 33.524],
                                  [-259.571, 160.178],
                                  [-52.535, 282.877],
                                  [107.017, 419.858],
                                  [294.094, 285.954],
                                  [41.52, -88.913],
                                  [322.726, -245.493],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.276, -49.447],
                                  [-32.921, -132.514],
                                  [57.83, -38.138],
                                  [-134.131, -34.407],
                                  [-17.84, -70.213],
                                  [-114.936, 3.34],
                                  [138.775, 82.716],
                                  [-47.798, 151.564],
                                  [-8.574, 92],
                                ],
                                o: [
                                  [-99.043, 49.894],
                                  [32.921, 132.514],
                                  [-57.83, 38.138],
                                  [134.131, 34.407],
                                  [18.255, 70.724],
                                  [111.681, -2.638],
                                  [-138.775, -82.716],
                                  [47.192, -152.107],
                                  [8.574, -92],
                                ],
                                v: [
                                  [-115.382, -372.144],
                                  [-161.14, -166.393],
                                  [-250.662, 32.99],
                                  [-260.374, 160.214],
                                  [-52.589, 282.936],
                                  [107.927, 420.244],
                                  [294.01, 285.855],
                                  [42.27, -89.241],
                                  [321.837, -245.852],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.308, -49.384],
                                  [-32.844, -132.427],
                                  [57.776, -38.191],
                                  [-134.035, -34.417],
                                  [-17.836, -70.169],
                                  [-115.009, 3.373],
                                  [138.645, 82.684],
                                  [-47.744, 151.455],
                                  [-8.589, 92],
                                ],
                                o: [
                                  [-99.019, 49.842],
                                  [32.844, 132.427],
                                  [-57.776, 38.191],
                                  [134.035, 34.417],
                                  [18.262, 70.692],
                                  [111.673, -2.654],
                                  [-138.645, -82.684],
                                  [47.122, -152.01],
                                  [8.589, -92],
                                ],
                                v: [
                                  [-114.636, -372.196],
                                  [-161.595, -167.004],
                                  [-250.443, 32.514],
                                  [-261.238, 160.251],
                                  [-52.583, 282.993],
                                  [108.896, 420.625],
                                  [293.929, 285.755],
                                  [43.024, -89.685],
                                  [321.005, -246.145],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.34, -49.319],
                                  [-32.766, -132.339],
                                  [57.721, -38.245],
                                  [-133.937, -34.427],
                                  [-17.832, -70.123],
                                  [-115.083, 3.407],
                                  [138.513, 82.651],
                                  [-47.688, 151.342],
                                  [-8.603, 92],
                                ],
                                o: [
                                  [-98.995, 49.788],
                                  [32.766, 132.339],
                                  [-57.721, 38.245],
                                  [133.937, 34.427],
                                  [18.268, 70.66],
                                  [111.665, -2.67],
                                  [-138.513, -82.651],
                                  [47.051, -151.912],
                                  [8.603, -92],
                                ],
                                v: [
                                  [-113.945, -372.247],
                                  [-162.113, -167.613],
                                  [-250.284, 32.036],
                                  [-262.099, 160.287],
                                  [-52.583, 283.052],
                                  [109.741, 420.941],
                                  [293.85, 285.717],
                                  [43.72, -90.127],
                                  [320.114, -246.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.373, -49.253],
                                  [-32.687, -132.249],
                                  [57.665, -38.3],
                                  [-133.837, -34.438],
                                  [-17.828, -70.077],
                                  [-115.159, 3.442],
                                  [138.378, 82.618],
                                  [-47.631, 151.227],
                                  [-8.618, 92],
                                ],
                                o: [
                                  [-98.97, 49.734],
                                  [32.687, 132.249],
                                  [-57.665, 38.3],
                                  [133.837, 34.438],
                                  [18.275, 70.627],
                                  [111.657, -2.687],
                                  [-138.378, -82.618],
                                  [46.979, -151.811],
                                  [8.618, -92],
                                ],
                                v: [
                                  [-113.254, -372.357],
                                  [-162.572, -168.164],
                                  [-250.067, 31.674],
                                  [-262.957, 160.264],
                                  [-52.464, 283.171],
                                  [110.644, 421.314],
                                  [293.773, 285.62],
                                  [44.361, -90.564],
                                  [319.22, -246.66],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.407, -49.186],
                                  [-32.605, -132.157],
                                  [57.608, -38.357],
                                  [-133.735, -34.449],
                                  [-17.824, -70.03],
                                  [-115.236, 3.478],
                                  [138.239, 82.584],
                                  [-47.573, 151.11],
                                  [-8.633, 92],
                                ],
                                o: [
                                  [-98.945, 49.678],
                                  [32.605, 132.157],
                                  [-57.608, 38.357],
                                  [133.735, 34.448],
                                  [18.281, 70.593],
                                  [111.648, -2.704],
                                  [-138.239, -82.584],
                                  [46.904, -151.708],
                                  [8.633, -92],
                                ],
                                v: [
                                  [-112.562, -372.466],
                                  [-163.09, -168.716],
                                  [-249.849, 31.252],
                                  [-263.874, 160.241],
                                  [-52.287, 283.29],
                                  [111.483, 421.622],
                                  [293.64, 285.521],
                                  [45.065, -91.12],
                                  [318.323, -246.881],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.442, -49.117],
                                  [-32.522, -132.062],
                                  [57.549, -38.415],
                                  [-133.63, -34.46],
                                  [-17.82, -69.982],
                                  [-115.316, 3.514],
                                  [138.098, 82.549],
                                  [-47.513, 150.99],
                                  [-8.649, 92],
                                ],
                                o: [
                                  [-98.919, 49.621],
                                  [32.522, 132.062],
                                  [-57.549, 38.415],
                                  [133.63, 34.46],
                                  [18.288, 70.558],
                                  [111.64, -2.721],
                                  [-138.098, -82.549],
                                  [46.828, -151.603],
                                  [8.649, -92],
                                ],
                                v: [
                                  [-111.927, -372.633],
                                  [-163.672, -169.268],
                                  [-249.634, 30.947],
                                  [-264.726, 160.157],
                                  [-52.055, 283.409],
                                  [112.322, 421.867],
                                  [293.569, 285.426],
                                  [45.652, -91.608],
                                  [317.427, -247.036],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.477, -49.046],
                                  [-32.437, -131.966],
                                  [57.489, -38.474],
                                  [-133.523, -34.471],
                                  [-17.815, -69.932],
                                  [-115.397, 3.551],
                                  [137.954, 82.514],
                                  [-47.452, 150.868],
                                  [-8.665, 92],
                                ],
                                o: [
                                  [-98.892, 49.563],
                                  [32.437, 131.966],
                                  [-57.489, 38.474],
                                  [133.523, 34.471],
                                  [18.295, 70.523],
                                  [111.631, -2.738],
                                  [-137.954, -82.514],
                                  [46.751, -151.496],
                                  [8.665, -92],
                                ],
                                v: [
                                  [-111.351, -372.8],
                                  [-164.195, -169.759],
                                  [-249.358, 30.637],
                                  [-265.637, 160.014],
                                  [-51.824, 283.527],
                                  [113.157, 422.167],
                                  [293.502, 285.27],
                                  [46.302, -92.216],
                                  [316.469, -247.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.513, -48.974],
                                  [-32.35, -131.868],
                                  [57.428, -38.534],
                                  [-133.414, -34.482],
                                  [-17.811, -69.882],
                                  [-115.48, 3.589],
                                  [137.807, 82.478],
                                  [-47.39, 150.743],
                                  [-8.681, 92],
                                ],
                                o: [
                                  [-98.865, 49.504],
                                  [32.35, 131.868],
                                  [-57.428, 38.534],
                                  [133.414, 34.482],
                                  [18.303, 70.487],
                                  [111.622, -2.756],
                                  [-137.807, -82.478],
                                  [46.672, -151.386],
                                  [8.681, -92],
                                ],
                                v: [
                                  [-110.772, -373.025],
                                  [-164.719, -170.313],
                                  [-249.143, 30.325],
                                  [-266.543, 159.872],
                                  [-51.479, 283.642],
                                  [113.928, 422.403],
                                  [293.376, 285.174],
                                  [46.898, -92.818],
                                  [315.511, -247.27],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.55, -48.901],
                                  [-32.261, -131.767],
                                  [57.366, -38.596],
                                  [-133.303, -34.494],
                                  [-17.806, -69.83],
                                  [-115.564, 3.627],
                                  [137.656, 82.441],
                                  [-47.327, 150.615],
                                  [-8.697, 92],
                                ],
                                o: [
                                  [-98.838, 49.443],
                                  [32.261, 131.768],
                                  [-57.366, 38.596],
                                  [133.303, 34.494],
                                  [18.31, 70.45],
                                  [111.613, -2.775],
                                  [-137.656, -82.441],
                                  [46.591, -151.274],
                                  [8.697, -92],
                                ],
                                v: [
                                  [-110.253, -373.248],
                                  [-165.244, -170.927],
                                  [-248.871, 30.132],
                                  [-267.448, 159.731],
                                  [-51.076, 283.818],
                                  [114.699, 422.637],
                                  [293.316, 285.081],
                                  [47.436, -93.416],
                                  [314.551, -247.351],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.587, -48.826],
                                  [-32.171, -131.665],
                                  [57.302, -38.659],
                                  [-133.189, -34.506],
                                  [-17.802, -69.778],
                                  [-115.651, 3.667],
                                  [137.502, 82.403],
                                  [-47.262, 150.484],
                                  [-8.714, 92],
                                ],
                                o: [
                                  [-98.81, 49.381],
                                  [32.171, 131.665],
                                  [-57.302, 38.659],
                                  [133.189, 34.506],
                                  [18.317, 70.413],
                                  [111.603, -2.794],
                                  [-137.502, -82.403],
                                  [46.508, -151.159],
                                  [8.714, -92],
                                ],
                                v: [
                                  [-109.791, -373.53],
                                  [-165.771, -171.482],
                                  [-248.598, 29.934],
                                  [-268.289, 159.53],
                                  [-50.679, 283.995],
                                  [115.466, 422.864],
                                  [293.259, 284.927],
                                  [47.979, -94.071],
                                  [313.65, -247.485],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.626, -48.749],
                                  [-32.078, -131.56],
                                  [57.236, -38.723],
                                  [-133.072, -34.518],
                                  [-17.797, -69.724],
                                  [-115.739, 3.707],
                                  [137.345, 82.364],
                                  [-47.196, 150.351],
                                  [-8.732, 92],
                                ],
                                o: [
                                  [-98.781, 49.318],
                                  [32.078, 131.56],
                                  [-57.236, 38.723],
                                  [133.072, 34.518],
                                  [18.325, 70.374],
                                  [111.594, -2.813],
                                  [-137.345, -82.364],
                                  [46.424, -151.042],
                                  [8.732, -92],
                                ],
                                v: [
                                  [-109.327, -373.81],
                                  [-166.239, -172.097],
                                  [-248.326, 29.792],
                                  [-269.186, 159.27],
                                  [-50.165, 284.168],
                                  [116.168, 423.029],
                                  [293.204, 284.836],
                                  [48.464, -94.78],
                                  [312.689, -247.555],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.665, -48.67],
                                  [-31.984, -131.453],
                                  [57.17, -38.789],
                                  [-132.954, -34.531],
                                  [-17.792, -69.669],
                                  [-115.829, 3.748],
                                  [137.185, 82.325],
                                  [-47.128, 150.215],
                                  [-8.749, 92],
                                ],
                                o: [
                                  [-98.751, 49.253],
                                  [31.984, 131.453],
                                  [-57.17, 38.788],
                                  [132.954, 34.531],
                                  [18.333, 70.335],
                                  [111.584, -2.832],
                                  [-137.185, -82.325],
                                  [46.337, -150.922],
                                  [8.749, -92],
                                ],
                                v: [
                                  [-108.919, -374.088],
                                  [-166.708, -172.712],
                                  [-247.996, 29.649],
                                  [-270.083, 159.012],
                                  [-49.657, 284.34],
                                  [116.87, 423.25],
                                  [293.154, 284.745],
                                  [48.957, -95.484],
                                  [311.726, -247.68],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.705, -48.59],
                                  [-31.887, -131.344],
                                  [57.102, -38.856],
                                  [-132.832, -34.543],
                                  [-17.787, -69.613],
                                  [-115.921, 3.79],
                                  [137.021, 82.284],
                                  [-47.059, 150.076],
                                  [-8.767, 92],
                                ],
                                o: [
                                  [-98.721, 49.187],
                                  [31.887, 131.344],
                                  [-57.102, 38.856],
                                  [132.832, 34.543],
                                  [18.341, 70.295],
                                  [111.574, -2.852],
                                  [-137.021, -82.284],
                                  [46.249, -150.8],
                                  [8.767, -92],
                                ],
                                v: [
                                  [-108.568, -374.427],
                                  [-167.18, -173.329],
                                  [-247.727, 29.56],
                                  [-270.914, 158.756],
                                  [-49.033, 284.571],
                                  [117.508, 423.346],
                                  [293.109, 284.594],
                                  [49.452, -96.184],
                                  [310.764, -247.737],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.746, -48.509],
                                  [-31.788, -131.232],
                                  [57.032, -38.924],
                                  [-132.708, -34.556],
                                  [-17.782, -69.556],
                                  [-116.015, 3.833],
                                  [136.853, 82.243],
                                  [-46.989, 149.934],
                                  [-8.786, 92],
                                ],
                                o: [
                                  [-98.691, 49.12],
                                  [31.788, 131.232],
                                  [-57.032, 38.924],
                                  [132.708, 34.556],
                                  [18.349, 70.254],
                                  [111.564, -2.873],
                                  [-136.853, -82.243],
                                  [46.159, -150.675],
                                  [8.786, -92],
                                ],
                                v: [
                                  [-108.276, -374.822],
                                  [-167.65, -173.945],
                                  [-247.399, 29.53],
                                  [-271.803, 158.442],
                                  [-48.413, 284.857],
                                  [118.143, 423.498],
                                  [293.063, 284.505],
                                  [49.83, -96.941],
                                  [309.857, -247.85],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.788, -48.425],
                                  [-31.688, -131.118],
                                  [56.961, -38.994],
                                  [-132.582, -34.57],
                                  [-17.777, -69.497],
                                  [-116.111, 3.877],
                                  [136.682, 82.201],
                                  [-46.917, 149.788],
                                  [-8.804, 92],
                                ],
                                o: [
                                  [-98.659, 49.051],
                                  [31.688, 131.118],
                                  [-56.961, 38.994],
                                  [132.582, 34.57],
                                  [18.357, 70.212],
                                  [111.553, -2.894],
                                  [-136.682, -82.201],
                                  [46.067, -150.548],
                                  [8.804, -92],
                                ],
                                v: [
                                  [-107.981, -375.214],
                                  [-168.124, -174.562],
                                  [-247.076, 29.553],
                                  [-272.629, 158.131],
                                  [-47.801, 285.082],
                                  [118.776, 423.588],
                                  [293.082, 284.354],
                                  [50.275, -97.692],
                                  [308.892, -247.898],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.83, -48.34],
                                  [-31.585, -131.001],
                                  [56.889, -39.066],
                                  [-132.452, -34.583],
                                  [-17.771, -69.438],
                                  [-116.209, 3.922],
                                  [136.508, 82.158],
                                  [-46.843, 149.64],
                                  [-8.824, 92],
                                ],
                                o: [
                                  [-98.627, 48.98],
                                  [31.585, 131.001],
                                  [-56.889, 39.065],
                                  [132.452, 34.583],
                                  [18.366, 70.17],
                                  [111.542, -2.915],
                                  [-136.508, -82.158],
                                  [45.974, -150.418],
                                  [8.824, -92],
                                ],
                                v: [
                                  [-107.742, -375.666],
                                  [-168.538, -175.239],
                                  [-246.751, 29.574],
                                  [-273.451, 157.82],
                                  [-47.136, 285.426],
                                  [119.404, 423.674],
                                  [293.041, 284.266],
                                  [50.664, -98.497],
                                  [307.928, -247.941],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.874, -48.253],
                                  [-31.48, -130.882],
                                  [56.815, -39.138],
                                  [-132.32, -34.597],
                                  [-17.766, -69.377],
                                  [-116.31, 3.967],
                                  [136.329, 82.115],
                                  [-46.768, 149.489],
                                  [-8.843, 92],
                                ],
                                o: [
                                  [-98.595, 48.908],
                                  [31.48, 130.882],
                                  [-56.815, 39.139],
                                  [132.32, 34.597],
                                  [18.375, 70.126],
                                  [111.532, -2.937],
                                  [-136.329, -82.115],
                                  [45.878, -150.285],
                                  [8.843, -92],
                                ],
                                v: [
                                  [-107.559, -376.114],
                                  [-168.95, -175.86],
                                  [-246.487, 29.591],
                                  [-274.269, 157.451],
                                  [-46.416, 285.764],
                                  [119.969, 423.755],
                                  [293.066, 284.119],
                                  [50.998, -99.298],
                                  [306.96, -248.039],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.918, -48.164],
                                  [-31.372, -130.761],
                                  [56.739, -39.213],
                                  [-132.185, -34.611],
                                  [-17.76, -69.314],
                                  [-116.412, 4.014],
                                  [136.147, 82.07],
                                  [-46.691, 149.334],
                                  [-8.863, 92],
                                ],
                                o: [
                                  [-98.561, 48.835],
                                  [31.372, 130.761],
                                  [-56.739, 39.213],
                                  [132.185, 34.611],
                                  [18.384, 70.082],
                                  [111.52, -2.959],
                                  [-136.147, -82.07],
                                  [45.78, -150.15],
                                  [8.863, -92],
                                ],
                                v: [
                                  [-107.372, -376.621],
                                  [-169.367, -176.538],
                                  [-246.163, 29.601],
                                  [-275.087, 157.085],
                                  [-45.703, 286.1],
                                  [120.472, 423.774],
                                  [293.152, 284.031],
                                  [51.335, -100.093],
                                  [305.992, -248.068],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.964, -48.073],
                                  [-31.263, -130.637],
                                  [56.662, -39.289],
                                  [-132.048, -34.626],
                                  [-17.755, -69.251],
                                  [-116.516, 4.062],
                                  [135.961, 82.024],
                                  [-46.613, 149.176],
                                  [-8.884, 92],
                                ],
                                o: [
                                  [-98.527, 48.76],
                                  [31.263, 130.637],
                                  [-56.662, 39.289],
                                  [132.048, 34.626],
                                  [18.393, 70.036],
                                  [111.509, -2.982],
                                  [-135.961, -82.024],
                                  [45.68, -150.011],
                                  [8.884, -92],
                                ],
                                v: [
                                  [-107.184, -377.124],
                                  [-169.724, -177.159],
                                  [-245.899, 29.669],
                                  [-275.897, 156.665],
                                  [-44.936, 286.492],
                                  [121.033, 423.789],
                                  [293.182, 283.884],
                                  [51.618, -100.943],
                                  [305.083, -248.092],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.01, -47.98],
                                  [-31.151, -130.51],
                                  [56.583, -39.367],
                                  [-131.907, -34.641],
                                  [-17.749, -69.186],
                                  [-116.623, 4.11],
                                  [135.772, 81.977],
                                  [-46.533, 149.015],
                                  [-8.904, 92],
                                ],
                                o: [
                                  [-98.493, 48.684],
                                  [31.151, 130.51],
                                  [-56.583, 39.367],
                                  [131.907, 34.641],
                                  [18.402, 69.99],
                                  [111.498, -3.005],
                                  [-135.772, -81.977],
                                  [45.578, -149.87],
                                  [8.904, -92],
                                ],
                                v: [
                                  [-106.996, -377.679],
                                  [-170.083, -177.841],
                                  [-245.577, 29.729],
                                  [-276.647, 156.245],
                                  [-44.179, 286.88],
                                  [121.528, 423.802],
                                  [293.214, 283.738],
                                  [51.907, -101.784],
                                  [304.113, -248.111],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.057, -47.885],
                                  [-31.037, -130.381],
                                  [56.503, -39.446],
                                  [-131.764, -34.656],
                                  [-17.743, -69.12],
                                  [-116.732, 4.16],
                                  [135.578, 81.93],
                                  [-46.451, 148.851],
                                  [-8.926, 92],
                                ],
                                o: [
                                  [-98.457, 48.605],
                                  [31.037, 130.381],
                                  [-56.503, 39.446],
                                  [131.764, 34.656],
                                  [18.411, 69.943],
                                  [111.486, -3.029],
                                  [-135.578, -81.93],
                                  [45.474, -149.725],
                                  [8.926, -92],
                                ],
                                v: [
                                  [-106.806, -378.229],
                                  [-170.443, -178.463],
                                  [-245.316, 29.787],
                                  [-277.393, 155.831],
                                  [-43.426, 287.266],
                                  [121.961, 423.752],
                                  [293.308, 283.652],
                                  [52.142, -102.622],
                                  [303.142, -248.183],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.106, -47.789],
                                  [-30.92, -130.249],
                                  [56.42, -39.527],
                                  [-131.617, -34.671],
                                  [-17.737, -69.052],
                                  [-116.843, 4.211],
                                  [135.38, 81.881],
                                  [-46.368, 148.683],
                                  [-8.948, 92],
                                ],
                                o: [
                                  [-98.421, 48.526],
                                  [30.92, 130.249],
                                  [-56.42, 39.527],
                                  [131.617, 34.671],
                                  [18.421, 69.894],
                                  [111.474, -3.053],
                                  [-135.38, -81.881],
                                  [45.368, -149.578],
                                  [8.948, -92],
                                ],
                                v: [
                                  [-106.617, -378.831],
                                  [-170.803, -179.145],
                                  [-245.053, 29.838],
                                  [-278.075, 155.418],
                                  [-42.623, 287.707],
                                  [122.451, 423.696],
                                  [293.405, 283.506],
                                  [52.325, -103.511],
                                  [302.173, -248.189],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.155, -47.69],
                                  [-30.801, -130.114],
                                  [56.337, -39.61],
                                  [-131.468, -34.687],
                                  [-17.731, -68.983],
                                  [-116.956, 4.263],
                                  [135.178, 81.832],
                                  [-46.283, 148.512],
                                  [-8.97, 92],
                                ],
                                o: [
                                  [-98.384, 48.444],
                                  [30.801, 130.114],
                                  [-56.337, 39.61],
                                  [131.468, 34.687],
                                  [18.431, 69.845],
                                  [111.461, -3.077],
                                  [-135.178, -81.832],
                                  [45.259, -149.427],
                                  [8.97, -92],
                                ],
                                v: [
                                  [-106.483, -379.489],
                                  [-171.161, -179.772],
                                  [-244.85, 29.942],
                                  [-278.757, 154.951],
                                  [-41.828, 288.142],
                                  [122.881, 423.64],
                                  [293.567, 283.361],
                                  [52.569, -104.39],
                                  [301.202, -248.19],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.205, -47.589],
                                  [-30.68, -129.977],
                                  [56.251, -39.694],
                                  [-131.315, -34.703],
                                  [-17.724, -68.913],
                                  [-117.072, 4.316],
                                  [134.972, 81.781],
                                  [-46.196, 148.337],
                                  [-8.992, 92],
                                ],
                                o: [
                                  [-98.346, 48.361],
                                  [30.68, 129.977],
                                  [-56.251, 39.694],
                                  [131.315, 34.703],
                                  [18.441, 69.795],
                                  [111.449, -3.103],
                                  [-134.972, -81.781],
                                  [45.148, -149.274],
                                  [8.992, -92],
                                ],
                                v: [
                                  [-106.352, -380.137],
                                  [-171.465, -180.455],
                                  [-244.587, 30.044],
                                  [-279.431, 154.485],
                                  [-40.981, 288.576],
                                  [123.247, 423.575],
                                  [293.67, 283.22],
                                  [52.706, -105.262],
                                  [300.286, -248.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.257, -47.487],
                                  [-30.556, -129.836],
                                  [56.164, -39.78],
                                  [-131.159, -34.719],
                                  [-17.718, -68.841],
                                  [-117.19, 4.37],
                                  [134.762, 81.729],
                                  [-46.107, 148.158],
                                  [-9.016, 92],
                                ],
                                o: [
                                  [-98.307, 48.276],
                                  [30.556, 129.836],
                                  [-56.164, 39.78],
                                  [131.159, 34.719],
                                  [18.451, 69.743],
                                  [111.436, -3.128],
                                  [-134.762, -81.729],
                                  [45.035, -149.117],
                                  [9.016, -92],
                                ],
                                v: [
                                  [-106.217, -380.78],
                                  [-171.765, -181.083],
                                  [-244.386, 30.138],
                                  [-280.046, 154.026],
                                  [-40.142, 289.063],
                                  [123.672, 423.449],
                                  [293.835, 283.136],
                                  [52.846, -106.122],
                                  [299.312, -248.114],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.309, -47.382],
                                  [-30.429, -129.693],
                                  [56.074, -39.868],
                                  [-131, -34.736],
                                  [-17.711, -68.767],
                                  [-117.311, 4.425],
                                  [134.547, 81.676],
                                  [-46.017, 147.976],
                                  [-9.039, 92],
                                ],
                                o: [
                                  [-98.268, 48.19],
                                  [30.429, 129.693],
                                  [-56.074, 39.868],
                                  [131, 34.736],
                                  [18.462, 69.691],
                                  [111.423, -3.155],
                                  [-134.547, -81.676],
                                  [44.92, -148.957],
                                  [9.039, -92],
                                ],
                                v: [
                                  [-106.083, -381.478],
                                  [-172.011, -181.712],
                                  [-244.184, 30.229],
                                  [-280.656, 153.51],
                                  [-39.311, 289.484],
                                  [124.035, 423.319],
                                  [294.002, 282.992],
                                  [52.934, -107.036],
                                  [298.338, -248.096],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.363, -47.275],
                                  [-30.3, -129.547],
                                  [55.983, -39.958],
                                  [-130.838, -34.753],
                                  [-17.705, -68.692],
                                  [-117.434, 4.481],
                                  [134.328, 81.623],
                                  [-45.924, 147.79],
                                  [-9.063, 92],
                                ],
                                o: [
                                  [-98.228, 48.102],
                                  [30.3, 129.547],
                                  [-55.983, 39.957],
                                  [130.838, 34.753],
                                  [18.473, 69.637],
                                  [111.409, -3.181],
                                  [-134.328, -81.623],
                                  [44.802, -148.794],
                                  [9.063, -92],
                                ],
                                v: [
                                  [-106.007, -382.223],
                                  [-172.315, -182.341],
                                  [-243.979, 30.37],
                                  [-281.207, 153.056],
                                  [-38.489, 289.962],
                                  [124.4, 423.18],
                                  [294.231, 282.85],
                                  [53.032, -107.939],
                                  [297.421, -248.071],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.417, -47.165],
                                  [-30.168, -129.398],
                                  [55.891, -40.049],
                                  [-130.672, -34.771],
                                  [-17.698, -68.616],
                                  [-117.56, 4.538],
                                  [134.104, 81.568],
                                  [-45.83, 147.6],
                                  [-9.088, 92],
                                ],
                                o: [
                                  [-98.187, 48.011],
                                  [30.168, 129.398],
                                  [-55.891, 40.049],
                                  [130.672, 34.771],
                                  [18.483, 69.583],
                                  [111.396, -3.209],
                                  [-134.104, -81.568],
                                  [44.682, -148.627],
                                  [9.088, -92],
                                ],
                                v: [
                                  [-105.932, -382.963],
                                  [-172.559, -182.975],
                                  [-243.837, 30.509],
                                  [-281.755, 152.549],
                                  [-37.619, 290.434],
                                  [124.702, 423.044],
                                  [294.401, 282.653],
                                  [53.135, -108.833],
                                  [296.445, -247.981],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.473, -47.054],
                                  [-30.034, -129.245],
                                  [55.796, -40.142],
                                  [-130.503, -34.788],
                                  [-17.691, -68.538],
                                  [-117.688, 4.597],
                                  [133.876, 81.511],
                                  [-45.734, 147.406],
                                  [-9.113, 92],
                                ],
                                o: [
                                  [-98.145, 47.919],
                                  [30.034, 129.245],
                                  [-55.796, 40.142],
                                  [130.503, 34.788],
                                  [18.495, 69.527],
                                  [111.382, -3.237],
                                  [-133.876, -81.511],
                                  [44.559, -148.457],
                                  [9.113, -92],
                                ],
                                v: [
                                  [-105.857, -383.693],
                                  [-172.746, -183.605],
                                  [-243.692, 30.642],
                                  [-282.239, 152.045],
                                  [-36.755, 290.902],
                                  [125.003, 422.84],
                                  [294.636, 282.512],
                                  [53.127, -109.719],
                                  [295.526, -247.943],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.53, -46.94],
                                  [-29.896, -129.09],
                                  [55.699, -40.238],
                                  [-130.331, -34.806],
                                  [-17.684, -68.458],
                                  [-117.819, 4.657],
                                  [133.643, 81.454],
                                  [-45.636, 147.208],
                                  [-9.138, 92],
                                ],
                                o: [
                                  [-98.102, 47.825],
                                  [29.896, 129.09],
                                  [-55.699, 40.238],
                                  [130.331, 34.806],
                                  [18.506, 69.47],
                                  [111.367, -3.265],
                                  [-133.643, -81.454],
                                  [44.434, -148.283],
                                  [9.139, -92],
                                ],
                                v: [
                                  [-105.837, -384.414],
                                  [-172.998, -184.182],
                                  [-243.548, 30.768],
                                  [-282.724, 151.49],
                                  [-35.962, 291.364],
                                  [125.304, 422.635],
                                  [294.932, 282.372],
                                  [53.186, -110.595],
                                  [294.602, -247.839],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.588, -46.824],
                                  [-29.756, -128.931],
                                  [55.6, -40.335],
                                  [-130.155, -34.825],
                                  [-17.676, -68.377],
                                  [-117.953, 4.718],
                                  [133.405, 81.396],
                                  [-45.536, 147.006],
                                  [-9.165, 92],
                                ],
                                o: [
                                  [-98.059, 47.73],
                                  [29.756, 128.931],
                                  [-55.6, 40.335],
                                  [130.155, 34.825],
                                  [18.518, 69.412],
                                  [111.353, -3.294],
                                  [-133.405, -81.396],
                                  [44.306, -148.106],
                                  [9.165, -92],
                                ],
                                v: [
                                  [-105.818, -385.184],
                                  [-173.19, -184.817],
                                  [-243.464, 30.947],
                                  [-283.146, 150.993],
                                  [-35.116, 291.876],
                                  [125.544, 422.425],
                                  [295.169, 282.232],
                                  [53.136, -111.521],
                                  [293.622, -247.729],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.647, -46.705],
                                  [-29.613, -128.769],
                                  [55.5, -40.434],
                                  [-129.975, -34.844],
                                  [-17.669, -68.294],
                                  [-118.089, 4.78],
                                  [133.162, 81.336],
                                  [-45.433, 146.8],
                                  [-9.191, 92],
                                ],
                                o: [
                                  [-98.014, 47.632],
                                  [29.613, 128.769],
                                  [-55.5, 40.434],
                                  [129.975, 34.844],
                                  [18.529, 69.353],
                                  [111.338, -3.324],
                                  [-133.162, -81.336],
                                  [44.176, -147.926],
                                  [9.191, -92],
                                ],
                                v: [
                                  [-105.796, -385.948],
                                  [-173.322, -185.4],
                                  [-243.38, 31.061],
                                  [-283.564, 150.507],
                                  [-34.284, 292.326],
                                  [125.84, 422.151],
                                  [295.466, 282.036],
                                  [53.148, -112.375],
                                  [292.699, -247.611],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.708, -46.584],
                                  [-29.467, -128.604],
                                  [55.397, -40.536],
                                  [-129.792, -34.863],
                                  [-17.662, -68.209],
                                  [-118.228, 4.843],
                                  [132.915, 81.275],
                                  [-45.329, 146.59],
                                  [-9.219, 92],
                                ],
                                o: [
                                  [-97.969, 47.532],
                                  [29.467, 128.604],
                                  [-55.397, 40.535],
                                  [129.792, 34.863],
                                  [18.542, 69.292],
                                  [111.323, -3.354],
                                  [-132.915, -81.275],
                                  [44.043, -147.741],
                                  [9.219, -92],
                                ],
                                v: [
                                  [-105.832, -386.763],
                                  [-173.52, -185.979],
                                  [-243.292, 31.231],
                                  [-283.925, 149.962],
                                  [-33.403, 292.831],
                                  [126.08, 421.877],
                                  [295.767, 281.894],
                                  [53.057, -113.277],
                                  [291.776, -247.491],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.769, -46.461],
                                  [-29.319, -128.436],
                                  [55.292, -40.639],
                                  [-129.605, -34.883],
                                  [-17.654, -68.123],
                                  [-118.37, 4.908],
                                  [132.662, 81.213],
                                  [-45.223, 146.376],
                                  [-9.246, 92],
                                ],
                                o: [
                                  [-97.923, 47.43],
                                  [29.319, 128.436],
                                  [-55.292, 40.639],
                                  [129.605, 34.883],
                                  [18.554, 69.231],
                                  [111.308, -3.385],
                                  [-132.662, -81.213],
                                  [43.907, -147.553],
                                  [9.246, -92],
                                ],
                                v: [
                                  [-105.87, -387.505],
                                  [-173.657, -186.506],
                                  [-243.263, 31.39],
                                  [-284.224, 149.426],
                                  [-32.59, 293.269],
                                  [126.317, 421.598],
                                  [296.129, 281.699],
                                  [52.974, -114.111],
                                  [290.905, -247.363],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.832, -46.335],
                                  [-29.167, -128.264],
                                  [55.185, -40.744],
                                  [-129.414, -34.903],
                                  [-17.646, -68.035],
                                  [-118.514, 4.974],
                                  [132.405, 81.15],
                                  [-45.114, 146.157],
                                  [-9.275, 92],
                                ],
                                o: [
                                  [-97.876, 47.327],
                                  [29.167, 128.264],
                                  [-55.185, 40.744],
                                  [129.414, 34.903],
                                  [18.566, 69.168],
                                  [111.292, -3.416],
                                  [-132.405, -81.15],
                                  [43.769, -147.361],
                                  [9.275, -92],
                                ],
                                v: [
                                  [-105.962, -388.297],
                                  [-173.798, -187.038],
                                  [-243.234, 31.604],
                                  [-284.519, 148.894],
                                  [-31.788, 293.757],
                                  [126.493, 421.318],
                                  [296.489, 281.501],
                                  [52.896, -114.993],
                                  [289.979, -247.223],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.897, -46.207],
                                  [-29.012, -128.089],
                                  [55.076, -40.852],
                                  [-129.22, -34.923],
                                  [-17.638, -67.945],
                                  [-118.662, 5.041],
                                  [132.142, 81.085],
                                  [-45.003, 145.934],
                                  [-9.303, 92],
                                ],
                                o: [
                                  [-97.828, 47.221],
                                  [29.012, 128.089],
                                  [-55.076, 40.852],
                                  [129.22, 34.923],
                                  [18.579, 69.103],
                                  [111.276, -3.448],
                                  [-132.142, -81.085],
                                  [43.628, -147.165],
                                  [9.303, -92],
                                ],
                                v: [
                                  [-106.055, -389.078],
                                  [-173.879, -187.567],
                                  [-243.206, 31.753],
                                  [-284.753, 148.366],
                                  [-31.055, 294.182],
                                  [126.672, 421.033],
                                  [296.851, 281.308],
                                  [52.774, -115.865],
                                  [289.106, -247.085],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.962, -46.076],
                                  [-28.854, -127.91],
                                  [54.964, -40.961],
                                  [-129.021, -34.944],
                                  [-17.63, -67.853],
                                  [-118.813, 5.11],
                                  [131.874, 81.019],
                                  [-44.89, 145.707],
                                  [-9.333, 92],
                                ],
                                o: [
                                  [-97.778, 47.113],
                                  [28.854, 127.91],
                                  [-54.964, 40.961],
                                  [129.021, 34.944],
                                  [18.592, 69.038],
                                  [111.26, -3.481],
                                  [-131.874, -81.019],
                                  [43.483, -146.966],
                                  [9.333, -92],
                                ],
                                v: [
                                  [-106.207, -389.85],
                                  [-173.963, -188.103],
                                  [-243.235, 31.899],
                                  [-284.93, 147.905],
                                  [-30.274, 294.661],
                                  [126.848, 420.686],
                                  [297.216, 281.112],
                                  [52.598, -116.72],
                                  [288.173, -246.877],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.029, -45.942],
                                  [-28.693, -127.727],
                                  [54.851, -41.073],
                                  [-128.819, -34.965],
                                  [-17.621, -67.76],
                                  [-118.966, 5.18],
                                  [131.601, 80.952],
                                  [-44.775, 145.475],
                                  [-9.363, 92],
                                ],
                                o: [
                                  [-97.728, 47.002],
                                  [28.693, 127.727],
                                  [-54.851, 41.073],
                                  [128.819, 34.965],
                                  [18.606, 68.971],
                                  [111.243, -3.514],
                                  [-131.601, -80.952],
                                  [43.337, -146.762],
                                  [9.363, -92],
                                ],
                                v: [
                                  [-106.351, -390.61],
                                  [-174.049, -188.582],
                                  [-243.318, 32.092],
                                  [-285.104, 147.386],
                                  [-29.504, 295.072],
                                  [127.022, 420.343],
                                  [297.642, 280.917],
                                  [52.438, -117.565],
                                  [287.297, -246.661],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.097, -45.806],
                                  [-28.529, -127.541],
                                  [54.735, -41.187],
                                  [-128.612, -34.987],
                                  [-17.613, -67.664],
                                  [-119.123, 5.252],
                                  [131.322, 80.884],
                                  [-44.658, 145.238],
                                  [-9.394, 92],
                                ],
                                o: [
                                  [-97.677, 46.89],
                                  [28.529, 127.541],
                                  [-54.735, 41.187],
                                  [128.612, 34.987],
                                  [18.619, 68.903],
                                  [111.226, -3.548],
                                  [-131.322, -80.884],
                                  [43.187, -146.554],
                                  [9.394, -92],
                                ],
                                v: [
                                  [-106.497, -391.418],
                                  [-174.082, -189.068],
                                  [-243.342, 32.281],
                                  [-285.222, 146.819],
                                  [-28.803, 295.53],
                                  [127.198, 419.995],
                                  [298.065, 280.718],
                                  [52.282, -118.341],
                                  [286.477, -246.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.166, -45.667],
                                  [-28.361, -127.352],
                                  [54.617, -41.304],
                                  [-128.402, -35.009],
                                  [-17.604, -67.567],
                                  [-119.283, 5.325],
                                  [131.037, 80.814],
                                  [-44.538, 144.997],
                                  [-9.425, 92],
                                ],
                                o: [
                                  [-97.625, 46.775],
                                  [28.361, 127.352],
                                  [-54.617, 41.304],
                                  [128.402, 35.009],
                                  [18.633, 68.834],
                                  [111.208, -3.583],
                                  [-131.037, -80.814],
                                  [43.034, -146.342],
                                  [9.425, -92],
                                ],
                                v: [
                                  [-106.701, -392.156],
                                  [-174.113, -189.551],
                                  [-243.487, 32.41],
                                  [-285.28, 146.315],
                                  [-28.113, 295.931],
                                  [127.315, 419.643],
                                  [298.548, 280.526],
                                  [52.08, -119.157],
                                  [285.594, -246.273],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.237, -45.525],
                                  [-28.19, -127.158],
                                  [54.497, -41.423],
                                  [-128.187, -35.032],
                                  [-17.595, -67.468],
                                  [-119.446, 5.399],
                                  [130.747, 80.742],
                                  [-44.416, 144.75],
                                  [-9.457, 92],
                                ],
                                o: [
                                  [-97.572, 46.658],
                                  [28.19, 127.158],
                                  [-54.497, 41.423],
                                  [128.187, 35.032],
                                  [18.647, 68.763],
                                  [111.191, -3.619],
                                  [-130.747, -80.742],
                                  [42.878, -146.126],
                                  [9.457, -92],
                                ],
                                v: [
                                  [-106.959, -392.884],
                                  [-174.142, -189.983],
                                  [-243.626, 32.585],
                                  [-285.278, 145.822],
                                  [-27.435, 296.316],
                                  [127.435, 419.29],
                                  [299.033, 280.272],
                                  [51.835, -119.967],
                                  [284.766, -246.044],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.31, -45.381],
                                  [-28.016, -126.961],
                                  [54.374, -41.544],
                                  [-127.968, -35.055],
                                  [-17.586, -67.367],
                                  [-119.612, 5.475],
                                  [130.451, 80.67],
                                  [-44.291, 144.499],
                                  [-9.489, 92],
                                ],
                                o: [
                                  [-97.518, 46.539],
                                  [28.016, 126.961],
                                  [-54.374, 41.544],
                                  [127.968, 35.055],
                                  [18.662, 68.69],
                                  [111.173, -3.655],
                                  [-130.451, -80.67],
                                  [42.719, -145.906],
                                  [9.489, -92],
                                ],
                                v: [
                                  [-107.156, -393.655],
                                  [-174.175, -190.366],
                                  [-243.759, 32.756],
                                  [-285.273, 145.328],
                                  [-26.827, 296.7],
                                  [127.553, 418.882],
                                  [299.515, 280.019],
                                  [51.597, -120.759],
                                  [283.937, -245.744],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.383, -45.233],
                                  [-27.838, -126.759],
                                  [54.248, -41.667],
                                  [-127.744, -35.078],
                                  [-17.577, -67.263],
                                  [-119.782, 5.552],
                                  [130.15, 80.596],
                                  [-44.164, 144.243],
                                  [-9.523, 92],
                                ],
                                o: [
                                  [-97.462, 46.418],
                                  [27.838, 126.759],
                                  [-54.248, 41.667],
                                  [127.744, 35.078],
                                  [18.677, 68.617],
                                  [111.154, -3.692],
                                  [-130.15, -80.596],
                                  [42.557, -145.681],
                                  [9.523, -92],
                                ],
                                v: [
                                  [-107.47, -394.358],
                                  [-174.153, -190.804],
                                  [-243.953, 32.863],
                                  [-285.214, 144.841],
                                  [-26.23, 297.074],
                                  [127.669, 418.526],
                                  [300.057, 279.82],
                                  [51.37, -121.476],
                                  [283.164, -245.499],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.458, -45.083],
                                  [-27.656, -126.554],
                                  [54.121, -41.793],
                                  [-127.516, -35.102],
                                  [-17.568, -67.158],
                                  [-119.954, 5.631],
                                  [129.842, 80.52],
                                  [-44.034, 143.982],
                                  [-9.556, 92],
                                ],
                                o: [
                                  [-97.406, 46.293],
                                  [27.656, 126.554],
                                  [-54.121, 41.793],
                                  [127.516, 35.102],
                                  [18.692, 68.542],
                                  [111.135, -3.729],
                                  [-129.842, -80.52],
                                  [42.391, -145.452],
                                  [9.556, -92],
                                ],
                                v: [
                                  [-107.773, -395.046],
                                  [-174.073, -191.192],
                                  [-244.146, 32.967],
                                  [-285.096, 144.364],
                                  [-25.645, 297.441],
                                  [127.788, 418.114],
                                  [300.6, 279.57],
                                  [51.097, -122.242],
                                  [282.383, -245.251],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.535, -44.93],
                                  [-27.472, -126.345],
                                  [53.99, -41.921],
                                  [-127.284, -35.127],
                                  [-17.558, -67.051],
                                  [-120.131, 5.712],
                                  [129.528, 80.443],
                                  [-43.902, 143.715],
                                  [-9.591, 92],
                                ],
                                o: [
                                  [-97.349, 46.167],
                                  [27.471, 126.345],
                                  [-53.99, 41.921],
                                  [127.284, 35.127],
                                  [18.707, 68.465],
                                  [111.116, -3.768],
                                  [-129.528, -80.443],
                                  [42.223, -145.218],
                                  [9.591, -92],
                                ],
                                v: [
                                  [-108.084, -395.724],
                                  [-174.053, -191.532],
                                  [-244.39, 33.12],
                                  [-284.92, 143.888],
                                  [-25.136, 297.801],
                                  [127.849, 417.703],
                                  [301.139, 279.259],
                                  [50.785, -122.933],
                                  [281.6, -244.939],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.613, -44.773],
                                  [-27.283, -126.131],
                                  [53.857, -42.052],
                                  [-127.047, -35.152],
                                  [-17.548, -66.941],
                                  [-120.311, 5.794],
                                  [129.208, 80.364],
                                  [-43.767, 143.444],
                                  [-9.626, 92],
                                ],
                                o: [
                                  [-97.29, 46.038],
                                  [27.283, 126.131],
                                  [-53.857, 42.052],
                                  [127.047, 35.152],
                                  [18.723, 68.387],
                                  [111.097, -3.807],
                                  [-129.208, -80.364],
                                  [42.051, -144.979],
                                  [9.626, -92],
                                ],
                                v: [
                                  [-108.442, -396.385],
                                  [-173.974, -191.87],
                                  [-244.689, 33.208],
                                  [-284.747, 143.475],
                                  [-24.633, 298.15],
                                  [127.914, 417.294],
                                  [301.737, 279.006],
                                  [50.537, -123.61],
                                  [280.872, -244.618],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.693, -44.614],
                                  [-27.091, -125.914],
                                  [53.722, -42.186],
                                  [-126.806, -35.177],
                                  [-17.538, -66.83],
                                  [-120.494, 5.878],
                                  [128.882, 80.284],
                                  [-43.63, 143.167],
                                  [-9.662, 92],
                                ],
                                o: [
                                  [-97.23, 45.907],
                                  [27.091, 125.914],
                                  [-53.722, 42.186],
                                  [126.806, 35.177],
                                  [18.739, 68.307],
                                  [111.077, -3.847],
                                  [-128.882, -80.284],
                                  [41.875, -144.736],
                                  [9.662, -92],
                                ],
                                v: [
                                  [-108.796, -397.034],
                                  [-173.843, -192.217],
                                  [-244.992, 33.294],
                                  [-284.51, 143.017],
                                  [-24.2, 298.435],
                                  [128.034, 416.881],
                                  [302.331, 278.749],
                                  [50.188, -124.267],
                                  [280.136, -244.289],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.774, -44.452],
                                  [-26.895, -125.692],
                                  [53.584, -42.322],
                                  [-126.559, -35.203],
                                  [-17.528, -66.716],
                                  [-120.681, 5.963],
                                  [128.549, 80.202],
                                  [-43.489, 142.885],
                                  [-9.698, 92],
                                ],
                                o: [
                                  [-97.169, 45.773],
                                  [26.895, 125.692],
                                  [-53.584, 42.322],
                                  [126.559, 35.203],
                                  [18.755, 68.226],
                                  [111.056, -3.887],
                                  [-128.549, -80.202],
                                  [41.697, -144.489],
                                  [9.698, -92],
                                ],
                                v: [
                                  [-109.209, -397.672],
                                  [-173.766, -192.51],
                                  [-245.344, 33.315],
                                  [-284.222, 142.559],
                                  [-23.837, 298.713],
                                  [128.096, 416.419],
                                  [302.982, 278.442],
                                  [49.914, -124.91],
                                  [279.397, -243.958],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.857, -44.286],
                                  [-26.695, -125.465],
                                  [53.443, -42.461],
                                  [-126.308, -35.229],
                                  [-17.518, -66.6],
                                  [-120.871, 6.05],
                                  [128.21, 80.119],
                                  [-43.347, 142.597],
                                  [-9.736, 92],
                                ],
                                o: [
                                  [-97.107, 45.636],
                                  [26.695, 125.465],
                                  [-53.443, 42.461],
                                  [126.308, 35.229],
                                  [18.771, 68.143],
                                  [111.036, -3.929],
                                  [-128.21, -80.119],
                                  [41.514, -144.236],
                                  [9.736, -92],
                                ],
                                v: [
                                  [-109.61, -398.236],
                                  [-173.636, -192.815],
                                  [-245.693, 33.334],
                                  [-283.882, 142.165],
                                  [-23.486, 298.983],
                                  [128.163, 416.011],
                                  [303.628, 278.125],
                                  [49.592, -125.538],
                                  [278.656, -243.619],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.941, -44.117],
                                  [-26.491, -125.235],
                                  [53.299, -42.602],
                                  [-126.052, -35.256],
                                  [-17.507, -66.482],
                                  [-121.065, 6.139],
                                  [127.865, 80.034],
                                  [-43.201, 142.303],
                                  [-9.774, 92],
                                ],
                                o: [
                                  [-97.044, 45.497],
                                  [26.491, 125.235],
                                  [-53.299, 42.602],
                                  [126.052, 35.256],
                                  [18.788, 68.059],
                                  [111.015, -3.971],
                                  [-127.865, -80.034],
                                  [41.329, -143.978],
                                  [9.774, -92],
                                ],
                                v: [
                                  [-110.07, -398.788],
                                  [-173.504, -193.06],
                                  [-246.096, 33.346],
                                  [-283.479, 141.721],
                                  [-23.211, 299.241],
                                  [128.229, 415.543],
                                  [304.275, 277.815],
                                  [49.233, -126.145],
                                  [277.907, -243.278],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.028, -43.945],
                                  [-26.284, -125],
                                  [53.153, -42.746],
                                  [-125.791, -35.284],
                                  [-17.497, -66.361],
                                  [-121.263, 6.229],
                                  [127.512, 79.947],
                                  [-43.052, 142.004],
                                  [-9.812, 92],
                                ],
                                o: [
                                  [-96.979, 45.355],
                                  [26.284, 125],
                                  [-53.153, 42.746],
                                  [125.791, 35.284],
                                  [18.806, 67.972],
                                  [110.993, -4.014],
                                  [-127.512, -79.947],
                                  [41.139, -143.716],
                                  [9.812, -92],
                                ],
                                v: [
                                  [-110.525, -399.271],
                                  [-173.319, -193.317],
                                  [-246.547, 33.349],
                                  [-283.026, 141.34],
                                  [-22.999, 299.441],
                                  [128.293, 415.14],
                                  [304.922, 277.444],
                                  [48.882, -126.681],
                                  [277.148, -242.928],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.115, -43.769],
                                  [-26.072, -124.76],
                                  [53.004, -42.893],
                                  [-125.525, -35.312],
                                  [-17.486, -66.239],
                                  [-121.465, 6.321],
                                  [127.153, 79.859],
                                  [-42.901, 141.699],
                                  [-9.852, 92],
                                ],
                                o: [
                                  [-96.914, 45.21],
                                  [26.072, 124.76],
                                  [-53.004, 42.893],
                                  [125.525, 35.312],
                                  [18.823, 67.885],
                                  [110.971, -4.058],
                                  [-127.153, -79.859],
                                  [40.946, -143.448],
                                  [9.852, -92],
                                ],
                                v: [
                                  [-111.025, -399.791],
                                  [-173.133, -193.529],
                                  [-247.001, 33.295],
                                  [-282.574, 140.959],
                                  [-22.857, 299.627],
                                  [128.307, 414.685],
                                  [305.508, 277.019],
                                  [48.552, -127.194],
                                  [276.449, -242.571],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.205, -43.591],
                                  [-25.856, -124.516],
                                  [52.852, -43.043],
                                  [-125.254, -35.34],
                                  [-17.474, -66.113],
                                  [-121.671, 6.415],
                                  [126.787, 79.769],
                                  [-42.747, 141.388],
                                  [-9.892, 92],
                                ],
                                o: [
                                  [-96.846, 45.062],
                                  [25.856, 124.516],
                                  [-52.852, 43.043],
                                  [125.254, 35.34],
                                  [18.841, 67.795],
                                  [110.949, -4.102],
                                  [-126.787, -79.769],
                                  [40.75, -143.176],
                                  [9.892, -92],
                                ],
                                v: [
                                  [-111.526, -400.186],
                                  [-172.943, -193.74],
                                  [-247.507, 33.184],
                                  [-282.066, 140.585],
                                  [-22.728, 299.805],
                                  [128.382, 414.228],
                                  [306.094, 276.589],
                                  [48.238, -127.691],
                                  [275.684, -242.155],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.296, -43.408],
                                  [-25.636, -124.267],
                                  [52.697, -43.196],
                                  [-124.978, -35.369],
                                  [-17.463, -65.986],
                                  [-121.881, 6.511],
                                  [126.414, 79.677],
                                  [-42.59, 141.072],
                                  [-9.933, 92],
                                ],
                                o: [
                                  [-96.778, 44.912],
                                  [25.636, 124.267],
                                  [-52.697, 43.196],
                                  [124.978, 35.369],
                                  [18.859, 67.704],
                                  [110.926, -4.148],
                                  [-126.414, -79.677],
                                  [40.549, -142.897],
                                  [9.933, -92],
                                ],
                                v: [
                                  [-112.021, -400.623],
                                  [-172.702, -193.907],
                                  [-248.059, 33.058],
                                  [-281.509, 140.219],
                                  [-22.668, 299.975],
                                  [128.457, 413.831],
                                  [306.617, 276.112],
                                  [47.883, -128.164],
                                  [274.909, -241.787],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.389, -43.222],
                                  [-25.412, -124.013],
                                  [52.539, -43.351],
                                  [-124.696, -35.399],
                                  [-17.451, -65.856],
                                  [-122.094, 6.608],
                                  [126.033, 79.584],
                                  [-42.429, 140.749],
                                  [-9.975, 92],
                                ],
                                o: [
                                  [-96.708, 44.758],
                                  [25.412, 124.013],
                                  [-52.539, 43.351],
                                  [124.696, 35.399],
                                  [18.878, 67.611],
                                  [110.903, -4.194],
                                  [-126.033, -79.584],
                                  [40.345, -142.614],
                                  [9.975, -92],
                                ],
                                v: [
                                  [-112.56, -400.936],
                                  [-172.513, -194.08],
                                  [-248.613, 32.884],
                                  [-280.892, 139.914],
                                  [-22.621, 300.137],
                                  [128.476, 413.382],
                                  [307.086, 275.568],
                                  [47.488, -128.565],
                                  [274.131, -241.363],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.484, -43.033],
                                  [-25.183, -123.754],
                                  [52.378, -43.51],
                                  [-124.409, -35.429],
                                  [-17.44, -65.723],
                                  [-122.312, 6.708],
                                  [125.646, 79.488],
                                  [-42.266, 140.42],
                                  [-10.018, 92],
                                ],
                                o: [
                                  [-96.637, 44.602],
                                  [25.183, 123.754],
                                  [-52.378, 43.51],
                                  [124.409, 35.429],
                                  [18.897, 67.516],
                                  [110.879, -4.242],
                                  [-125.646, -79.488],
                                  [40.136, -142.325],
                                  [10.018, -92],
                                ],
                                v: [
                                  [-113.1, -401.235],
                                  [-172.266, -194.266],
                                  [-249.219, 32.646],
                                  [-280.227, 139.555],
                                  [-22.588, 300.291],
                                  [128.557, 412.939],
                                  [307.549, 275.033],
                                  [47.166, -128.996],
                                  [273.398, -240.937],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.58, -42.84],
                                  [-24.951, -123.491],
                                  [52.214, -43.672],
                                  [-124.117, -35.46],
                                  [-17.428, -65.588],
                                  [-122.534, 6.809],
                                  [125.25, 79.391],
                                  [-42.099, 140.084],
                                  [-10.061, 92],
                                ],
                                o: [
                                  [-96.565, 44.443],
                                  [24.951, 123.491],
                                  [-52.214, 43.672],
                                  [124.117, 35.46],
                                  [18.916, 67.42],
                                  [110.855, -4.29],
                                  [-125.25, -79.391],
                                  [39.924, -142.031],
                                  [10.061, -92],
                                ],
                                v: [
                                  [-113.683, -401.514],
                                  [-171.967, -194.341],
                                  [-249.812, 32.347],
                                  [-279.565, 139.251],
                                  [-22.624, 300.436],
                                  [128.582, 412.501],
                                  [308.005, 274.438],
                                  [46.812, -129.299],
                                  [272.606, -240.496],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.678, -42.643],
                                  [-24.713, -123.222],
                                  [52.047, -43.836],
                                  [-123.819, -35.491],
                                  [-17.415, -65.45],
                                  [-122.76, 6.912],
                                  [124.848, 79.292],
                                  [-41.93, 139.743],
                                  [-10.105, 92],
                                ],
                                o: [
                                  [-96.491, 44.281],
                                  [24.713, 123.222],
                                  [-52.047, 43.836],
                                  [123.819, 35.491],
                                  [18.936, 67.322],
                                  [110.83, -4.339],
                                  [-124.848, -79.292],
                                  [39.707, -141.731],
                                  [10.105, -92],
                                ],
                                v: [
                                  [-114.204, -401.723],
                                  [-171.721, -194.485],
                                  [-250.457, 32.048],
                                  [-278.849, 138.961],
                                  [-22.675, 300.572],
                                  [128.662, 412.07],
                                  [308.358, 273.783],
                                  [46.475, -129.631],
                                  [271.866, -240.054],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.779, -42.443],
                                  [-24.472, -122.948],
                                  [51.876, -44.004],
                                  [-123.515, -35.523],
                                  [-17.403, -65.31],
                                  [-122.991, 7.018],
                                  [124.437, 79.191],
                                  [-41.757, 139.394],
                                  [-10.15, 92],
                                ],
                                o: [
                                  [-96.416, 44.115],
                                  [24.472, 122.948],
                                  [-51.876, 44.004],
                                  [123.515, 35.523],
                                  [18.956, 67.221],
                                  [110.805, -4.389],
                                  [-124.437, -79.191],
                                  [39.487, -141.425],
                                  [10.15, -92],
                                ],
                                v: [
                                  [-114.719, -401.918],
                                  [-171.423, -194.582],
                                  [-251.103, 31.688],
                                  [-278.088, 138.718],
                                  [-22.795, 300.699],
                                  [128.694, 411.645],
                                  [308.753, 273.131],
                                  [46.157, -129.882],
                                  [271.059, -239.556],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.881, -42.238],
                                  [-24.225, -122.669],
                                  [51.703, -44.175],
                                  [-123.205, -35.556],
                                  [-17.39, -65.167],
                                  [-123.226, 7.125],
                                  [124.019, 79.089],
                                  [-41.581, 139.039],
                                  [-10.196, 92],
                                ],
                                o: [
                                  [-96.339, 43.947],
                                  [24.225, 122.669],
                                  [-51.703, 44.175],
                                  [123.205, 35.556],
                                  [18.976, 67.119],
                                  [110.78, -4.44],
                                  [-124.019, -79.089],
                                  [39.262, -141.114],
                                  [10.196, -92],
                                ],
                                v: [
                                  [-115.173, -402.05],
                                  [-171.121, -194.639],
                                  [-251.79, 31.275],
                                  [-277.269, 138.427],
                                  [-22.921, 300.817],
                                  [128.789, 411.219],
                                  [309.045, 272.482],
                                  [45.857, -130.114],
                                  [270.295, -239.106],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.985, -42.03],
                                  [-23.974, -122.385],
                                  [51.526, -44.35],
                                  [-122.89, -35.589],
                                  [-17.377, -65.021],
                                  [-123.465, 7.234],
                                  [123.593, 78.984],
                                  [-41.401, 138.678],
                                  [-10.243, 92],
                                ],
                                o: [
                                  [-96.261, 43.775],
                                  [23.974, 122.385],
                                  [-51.526, 44.35],
                                  [122.89, 35.589],
                                  [18.997, 67.015],
                                  [110.754, -4.492],
                                  [-123.593, -78.984],
                                  [39.033, -140.796],
                                  [10.243, -92],
                                ],
                                v: [
                                  [-115.667, -402.153],
                                  [-170.761, -194.697],
                                  [-252.425, 30.801],
                                  [-276.46, 138.191],
                                  [-23.07, 300.871],
                                  [128.877, 410.809],
                                  [309.323, 271.781],
                                  [45.583, -130.317],
                                  [269.526, -238.601],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.091, -41.818],
                                  [-23.718, -122.096],
                                  [51.346, -44.527],
                                  [-122.568, -35.623],
                                  [-17.364, -64.873],
                                  [-123.709, 7.345],
                                  [123.159, 78.877],
                                  [-41.218, 138.309],
                                  [-10.291, 92],
                                ],
                                o: [
                                  [-96.182, 43.6],
                                  [23.718, 122.096],
                                  [-51.345, 44.527],
                                  [122.568, 35.623],
                                  [19.018, 66.909],
                                  [110.727, -4.545],
                                  [-123.159, -78.877],
                                  [38.8, -140.473],
                                  [10.291, -92],
                                ],
                                v: [
                                  [-116.046, -402.194],
                                  [-170.459, -194.717],
                                  [-253.101, 30.275],
                                  [-275.601, 137.96],
                                  [-23.225, 300.969],
                                  [128.981, 410.399],
                                  [309.547, 271.02],
                                  [45.275, -130.492],
                                  [268.744, -238.089],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.199, -41.602],
                                  [-23.458, -121.8],
                                  [51.162, -44.708],
                                  [-122.241, -35.657],
                                  [-17.35, -64.721],
                                  [-123.957, 7.459],
                                  [122.717, 78.769],
                                  [-41.032, 137.934],
                                  [-10.339, 92],
                                ],
                                o: [
                                  [-96.101, 43.422],
                                  [23.458, 121.8],
                                  [-51.162, 44.708],
                                  [122.241, 35.657],
                                  [19.04, 66.801],
                                  [110.7, -4.599],
                                  [-122.717, -78.769],
                                  [38.562, -140.143],
                                  [10.34, -92],
                                ],
                                v: [
                                  [-116.418, -402.275],
                                  [-170.09, -194.746],
                                  [-253.825, 29.753],
                                  [-274.684, 137.783],
                                  [-23.457, 301.058],
                                  [129.077, 409.997],
                                  [309.771, 270.255],
                                  [45.041, -130.6],
                                  [267.958, -237.522],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.309, -41.382],
                                  [-23.192, -121.5],
                                  [50.975, -44.893],
                                  [-121.907, -35.692],
                                  [-17.336, -64.567],
                                  [-124.211, 7.574],
                                  [122.266, 78.658],
                                  [-40.842, 137.551],
                                  [-10.389, 92],
                                ],
                                o: [
                                  [-96.018, 43.24],
                                  [23.192, 121.5],
                                  [-50.975, 44.893],
                                  [121.907, 35.692],
                                  [19.062, 66.691],
                                  [110.673, -4.655],
                                  [-122.266, -78.658],
                                  [38.32, -139.808],
                                  [10.389, -92],
                                ],
                                v: [
                                  [-116.784, -402.231],
                                  [-169.733, -194.784],
                                  [-254.55, 29.172],
                                  [-273.726, 137.541],
                                  [-23.751, 301.091],
                                  [129.19, 409.597],
                                  [309.934, 269.448],
                                  [44.835, -130.669],
                                  [267.158, -236.949],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.421, -41.158],
                                  [-22.921, -121.194],
                                  [50.784, -45.08],
                                  [-121.567, -35.728],
                                  [-17.322, -64.41],
                                  [-124.469, 7.692],
                                  [121.807, 78.545],
                                  [-40.649, 137.162],
                                  [-10.44, 92],
                                ],
                                o: [
                                  [-95.934, 43.055],
                                  [22.922, 121.193],
                                  [-50.784, 45.08],
                                  [121.567, 35.728],
                                  [19.084, 66.579],
                                  [110.645, -4.711],
                                  [-121.807, -78.545],
                                  [38.074, -139.466],
                                  [10.44, -92],
                                ],
                                v: [
                                  [-117.081, -402.173],
                                  [-169.363, -194.779],
                                  [-255.205, 28.533],
                                  [-272.773, 137.421],
                                  [-24.014, 301.106],
                                  [129.296, 409.188],
                                  [310.097, 268.636],
                                  [44.65, -130.725],
                                  [266.406, -236.377],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.535, -40.929],
                                  [-22.646, -120.882],
                                  [50.59, -45.272],
                                  [-121.221, -35.764],
                                  [-17.308, -64.25],
                                  [-124.731, 7.812],
                                  [121.339, 78.43],
                                  [-40.451, 136.765],
                                  [-10.491, 92],
                                ],
                                o: [
                                  [-95.848, 42.867],
                                  [22.646, 120.881],
                                  [-50.59, 45.272],
                                  [121.221, 35.764],
                                  [19.107, 66.465],
                                  [110.616, -4.768],
                                  [-121.34, -78.43],
                                  [37.822, -139.117],
                                  [10.491, -92],
                                ],
                                v: [
                                  [-117.371, -402.098],
                                  [-168.943, -194.729],
                                  [-255.961, 27.899],
                                  [-271.78, 137.237],
                                  [-24.337, 301.172],
                                  [129.465, 408.789],
                                  [310.199, 267.818],
                                  [44.487, -130.696],
                                  [265.586, -235.799],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.652, -40.697],
                                  [-22.365, -120.564],
                                  [50.392, -45.467],
                                  [-120.868, -35.802],
                                  [-17.294, -64.088],
                                  [-124.999, 7.934],
                                  [120.863, 78.313],
                                  [-40.251, 136.36],
                                  [-10.543, 92],
                                ],
                                o: [
                                  [-95.761, 42.675],
                                  [22.365, 120.564],
                                  [-50.392, 45.467],
                                  [120.868, 35.802],
                                  [19.13, 66.348],
                                  [110.587, -4.826],
                                  [-120.863, -78.313],
                                  [37.566, -138.762],
                                  [10.543, -92],
                                ],
                                v: [
                                  [-117.646, -402.008],
                                  [-168.57, -194.753],
                                  [-256.664, 27.217],
                                  [-270.785, 137.111],
                                  [-24.73, 301.175],
                                  [129.635, 408.391],
                                  [310.247, 266.951],
                                  [44.354, -130.643],
                                  [264.813, -235.213],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.77, -40.459],
                                  [-22.079, -120.24],
                                  [50.19, -45.665],
                                  [-120.509, -35.839],
                                  [-17.279, -63.922],
                                  [-125.272, 8.059],
                                  [120.378, 78.194],
                                  [-40.046, 135.948],
                                  [-10.597, 92],
                                ],
                                o: [
                                  [-95.672, 42.479],
                                  [22.079, 120.24],
                                  [-50.19, 45.665],
                                  [120.509, 35.839],
                                  [19.154, 66.23],
                                  [110.557, -4.885],
                                  [-120.378, -78.194],
                                  [37.305, -138.401],
                                  [10.597, -92],
                                ],
                                v: [
                                  [-117.861, -401.794],
                                  [-168.201, -194.726],
                                  [-257.351, 26.532],
                                  [-269.806, 137.044],
                                  [-25.084, 301.167],
                                  [129.806, 407.994],
                                  [310.235, 266.034],
                                  [44.235, -130.559],
                                  [264.024, -234.585],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.891, -40.218],
                                  [-21.788, -119.91],
                                  [49.985, -45.867],
                                  [-120.143, -35.878],
                                  [-17.264, -63.753],
                                  [-125.549, 8.186],
                                  [119.884, 78.072],
                                  [-39.838, 135.529],
                                  [-10.651, 92],
                                ],
                                o: [
                                  [-95.582, 42.28],
                                  [21.788, 119.91],
                                  [-49.985, 45.868],
                                  [120.143, 35.878],
                                  [19.178, 66.109],
                                  [110.527, -4.945],
                                  [-119.883, -78.072],
                                  [37.04, -138.032],
                                  [10.651, -92],
                                ],
                                v: [
                                  [-118.015, -401.618],
                                  [-167.791, -194.657],
                                  [-258.039, 25.791],
                                  [-268.771, 136.973],
                                  [-25.508, 301.148],
                                  [129.988, 407.536],
                                  [310.223, 265.176],
                                  [44.148, -130.406],
                                  [263.229, -233.889],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.014, -39.972],
                                  [-21.491, -119.574],
                                  [49.776, -46.074],
                                  [-119.77, -35.917],
                                  [-17.248, -63.58],
                                  [-125.832, 8.315],
                                  [119.38, 77.948],
                                  [-39.626, 135.101],
                                  [-10.706, 92],
                                ],
                                o: [
                                  [-95.489, 42.077],
                                  [21.491, 119.574],
                                  [-49.776, 46.074],
                                  [119.77, 35.917],
                                  [19.203, 65.986],
                                  [110.497, -5.007],
                                  [-119.38, -77.948],
                                  [36.769, -137.657],
                                  [10.706, -92],
                                ],
                                v: [
                                  [-118.154, -401.382],
                                  [-167.428, -194.643],
                                  [-258.762, 25.005],
                                  [-267.699, 136.898],
                                  [-25.938, 301.075],
                                  [130.223, 407.142],
                                  [310.203, 264.258],
                                  [44.085, -130.22],
                                  [262.47, -233.249],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.139, -39.721],
                                  [-21.189, -119.232],
                                  [49.563, -46.283],
                                  [-119.39, -35.957],
                                  [-17.233, -63.405],
                                  [-126.12, 8.446],
                                  [118.867, 77.822],
                                  [-39.41, 134.666],
                                  [-10.763, 92],
                                ],
                                o: [
                                  [-95.396, 41.87],
                                  [21.189, 119.232],
                                  [-49.563, 46.283],
                                  [119.39, 35.957],
                                  [19.228, 65.861],
                                  [110.465, -5.07],
                                  [-118.867, -77.822],
                                  [36.493, -137.275],
                                  [10.763, -92],
                                ],
                                v: [
                                  [-118.276, -401.066],
                                  [-167.016, -194.598],
                                  [-259.424, 24.226],
                                  [-266.689, 136.88],
                                  [-26.446, 301.034],
                                  [130.407, 406.739],
                                  [310.077, 263.293],
                                  [44.107, -129.999],
                                  [261.66, -232.56],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.267, -39.466],
                                  [-20.881, -118.884],
                                  [49.346, -46.497],
                                  [-119.003, -35.998],
                                  [-17.217, -63.226],
                                  [-126.414, 8.58],
                                  [118.345, 77.694],
                                  [-39.19, 134.223],
                                  [-10.82, 92],
                                ],
                                o: [
                                  [-95.3, 41.66],
                                  [20.881, 118.883],
                                  [-49.346, 46.497],
                                  [119.003, 35.998],
                                  [19.253, 65.733],
                                  [110.433, -5.133],
                                  [-118.345, -77.694],
                                  [36.213, -136.886],
                                  [10.82, -92],
                                ],
                                v: [
                                  [-118.337, -400.744],
                                  [-166.67, -194.555],
                                  [-260.139, 23.394],
                                  [-265.591, 136.857],
                                  [-26.908, 301.002],
                                  [130.645, 406.348],
                                  [309.953, 262.375],
                                  [44.092, -129.709],
                                  [260.885, -231.876],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.397, -39.206],
                                  [-20.568, -118.529],
                                  [49.125, -46.715],
                                  [-118.61, -36.039],
                                  [-17.2, -63.044],
                                  [-126.713, 8.717],
                                  [117.813, 77.563],
                                  [-38.965, 133.771],
                                  [-10.879, 92],
                                ],
                                o: [
                                  [-95.202, 41.445],
                                  [20.568, 118.529],
                                  [-49.125, 46.715],
                                  [118.61, 36.039],
                                  [19.279, 65.603],
                                  [110.401, -5.198],
                                  [-117.813, -77.563],
                                  [35.927, -136.49],
                                  [10.879, -92],
                                ],
                                v: [
                                  [-118.392, -400.406],
                                  [-166.327, -194.515],
                                  [-260.793, 22.571],
                                  [-264.546, 136.837],
                                  [-27.448, 300.897],
                                  [130.937, 405.895],
                                  [309.821, 261.418],
                                  [44.165, -129.394],
                                  [260.102, -231.124],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.529, -38.942],
                                  [-20.248, -118.167],
                                  [48.9, -46.936],
                                  [-118.208, -36.081],
                                  [-17.184, -62.859],
                                  [-127.017, 8.856],
                                  [117.271, 77.43],
                                  [-38.737, 133.311],
                                  [-10.938, 92],
                                ],
                                o: [
                                  [-95.103, 41.227],
                                  [20.248, 118.167],
                                  [-48.9, 46.936],
                                  [118.208, 36.081],
                                  [19.306, 65.471],
                                  [110.368, -5.265],
                                  [-117.271, -77.43],
                                  [35.636, -136.086],
                                  [10.938, -92],
                                ],
                                v: [
                                  [-118.377, -399.999],
                                  [-165.978, -194.446],
                                  [-261.428, 21.749],
                                  [-263.457, 136.874],
                                  [-27.995, 300.842],
                                  [131.187, 405.497],
                                  [309.637, 260.457],
                                  [44.263, -129.043],
                                  [259.352, -230.388],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.664, -38.672],
                                  [-19.923, -117.799],
                                  [48.671, -47.162],
                                  [-117.8, -36.124],
                                  [-17.167, -62.67],
                                  [-127.327, 8.997],
                                  [116.719, 77.294],
                                  [-38.505, 132.843],
                                  [-10.999, 92],
                                ],
                                o: [
                                  [-95.002, 41.005],
                                  [19.923, 117.799],
                                  [-48.671, 47.162],
                                  [117.8, 36.124],
                                  [19.333, 65.336],
                                  [110.334, -5.332],
                                  [-116.72, -77.294],
                                  [35.339, -135.675],
                                  [10.999, -92],
                                ],
                                v: [
                                  [-118.344, -399.574],
                                  [-165.643, -194.369],
                                  [-262.115, 20.874],
                                  [-262.388, 136.893],
                                  [-28.6, 300.725],
                                  [131.481, 405.048],
                                  [309.392, 259.459],
                                  [44.386, -128.623],
                                  [258.552, -229.647],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.801, -38.398],
                                  [-19.592, -117.424],
                                  [48.438, -47.392],
                                  [-117.384, -36.168],
                                  [-17.15, -62.478],
                                  [-127.643, 9.141],
                                  [116.158, 77.156],
                                  [-38.268, 132.366],
                                  [-11.06, 92],
                                ],
                                o: [
                                  [-94.899, 40.778],
                                  [19.592, 117.424],
                                  [-48.438, 47.392],
                                  [117.384, 36.168],
                                  [19.36, 65.199],
                                  [110.3, -5.401],
                                  [-116.158, -77.156],
                                  [35.037, -135.257],
                                  [11.06, -92],
                                ],
                                v: [
                                  [-118.251, -399.081],
                                  [-165.312, -194.315],
                                  [-262.741, 19.959],
                                  [-261.319, 136.978],
                                  [-29.169, 300.596],
                                  [131.734, 404.643],
                                  [309.097, 258.508],
                                  [44.599, -128.166],
                                  [257.784, -228.851],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.941, -38.118],
                                  [-19.255, -117.043],
                                  [48.201, -47.626],
                                  [-116.96, -36.212],
                                  [-17.132, -62.283],
                                  [-127.964, 9.288],
                                  [115.586, 77.016],
                                  [-38.027, 131.88],
                                  [-11.123, 92],
                                ],
                                o: [
                                  [-94.794, 40.548],
                                  [19.255, 117.043],
                                  [-48.201, 47.626],
                                  [116.96, 36.212],
                                  [19.388, 65.059],
                                  [110.265, -5.47],
                                  [-115.586, -77.016],
                                  [34.73, -134.83],
                                  [11.123, -92],
                                ],
                                v: [
                                  [-118.14, -398.53],
                                  [-165.037, -194.265],
                                  [-263.357, 19.046],
                                  [-260.209, 137.055],
                                  [-29.806, 300.455],
                                  [132.03, 404.198],
                                  [308.804, 257.511],
                                  [44.828, -127.63],
                                  [257.006, -228.051],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.083, -37.834],
                                  [-18.912, -116.654],
                                  [47.959, -47.864],
                                  [-116.529, -36.258],
                                  [-17.115, -62.084],
                                  [-128.291, 9.437],
                                  [115.003, 76.873],
                                  [-37.782, 131.386],
                                  [-11.187, 92],
                                ],
                                o: [
                                  [-94.688, 40.313],
                                  [18.912, 116.654],
                                  [-47.959, 47.864],
                                  [116.529, 36.258],
                                  [19.417, 64.917],
                                  [110.229, -5.542],
                                  [-115.003, -76.873],
                                  [34.417, -134.396],
                                  [11.187, -92],
                                ],
                                v: [
                                  [-117.969, -398.014],
                                  [-164.714, -194.227],
                                  [-263.912, 18.145],
                                  [-259.172, 137.175],
                                  [-30.46, 300.314],
                                  [132.378, 403.795],
                                  [308.512, 256.519],
                                  [45.084, -127.077],
                                  [256.259, -227.259],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.228, -37.544],
                                  [-18.562, -116.258],
                                  [47.712, -48.107],
                                  [-116.09, -36.304],
                                  [-17.096, -61.881],
                                  [-128.624, 9.589],
                                  [114.41, 76.727],
                                  [-37.532, 130.883],
                                  [-11.253, 92],
                                ],
                                o: [
                                  [-94.579, 40.074],
                                  [18.562, 116.258],
                                  [-47.712, 48.107],
                                  [116.09, 36.304],
                                  [19.446, 64.772],
                                  [110.193, -5.614],
                                  [-114.41, -76.727],
                                  [34.098, -133.955],
                                  [11.253, -92],
                                ],
                                v: [
                                  [-117.779, -397.379],
                                  [-164.446, -194.142],
                                  [-264.52, 17.247],
                                  [-258.084, 137.297],
                                  [-31.119, 300.162],
                                  [132.686, 403.343],
                                  [308.109, 255.533],
                                  [45.379, -126.433],
                                  [255.512, -226.424],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.375, -37.249],
                                  [-18.207, -115.856],
                                  [47.462, -48.354],
                                  [-115.643, -36.351],
                                  [-17.078, -61.674],
                                  [-128.964, 9.744],
                                  [113.807, 76.578],
                                  [-37.277, 130.37],
                                  [-11.319, 92],
                                ],
                                o: [
                                  [-94.468, 39.83],
                                  [18.207, 115.856],
                                  [-47.462, 48.354],
                                  [115.643, 36.351],
                                  [19.475, 64.625],
                                  [110.156, -5.688],
                                  [-113.807, -76.578],
                                  [33.774, -133.505],
                                  [11.319, -92],
                                ],
                                v: [
                                  [-117.53, -396.738],
                                  [-164.234, -194.121],
                                  [-265.067, 16.312],
                                  [-257.02, 137.42],
                                  [-31.846, 300.009],
                                  [133.036, 402.944],
                                  [307.708, 254.554],
                                  [45.753, -125.773],
                                  [254.732, -225.536],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.526, -36.949],
                                  [-17.844, -115.446],
                                  [47.206, -48.605],
                                  [-115.188, -36.399],
                                  [-17.059, -61.464],
                                  [-129.309, 9.902],
                                  [113.192, 76.427],
                                  [-37.018, 129.848],
                                  [-11.387, 92],
                                ],
                                o: [
                                  [-94.356, 39.583],
                                  [17.844, 115.446],
                                  [-47.206, 48.606],
                                  [115.188, 36.399],
                                  [19.505, 64.474],
                                  [110.119, -5.763],
                                  [-113.192, -76.427],
                                  [33.444, -133.047],
                                  [11.387, -92],
                                ],
                                v: [
                                  [-117.273, -396.081],
                                  [-163.974, -194.052],
                                  [-265.604, 15.33],
                                  [-255.956, 137.584],
                                  [-32.527, 299.794],
                                  [133.386, 402.496],
                                  [307.309, 253.53],
                                  [46.157, -125.073],
                                  [253.951, -224.697],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.679, -36.643],
                                  [-17.476, -115.028],
                                  [46.947, -48.862],
                                  [-114.724, -36.448],
                                  [-17.04, -61.25],
                                  [-129.661, 10.062],
                                  [112.566, 76.273],
                                  [-36.755, 129.317],
                                  [-11.455, 92],
                                ],
                                o: [
                                  [-94.241, 39.33],
                                  [17.476, 115.028],
                                  [-46.947, 48.862],
                                  [114.724, 36.448],
                                  [19.536, 64.321],
                                  [110.08, -5.839],
                                  [-112.566, -76.273],
                                  [33.107, -132.58],
                                  [11.455, -92],
                                ],
                                v: [
                                  [-116.995, -395.357],
                                  [-163.768, -193.997],
                                  [-266.08, 14.403],
                                  [-254.917, 137.737],
                                  [-33.275, 299.618],
                                  [133.737, 402.089],
                                  [306.8, 252.563],
                                  [46.639, -124.304],
                                  [253.198, -223.755],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.834, -36.332],
                                  [-17.1, -114.603],
                                  [46.682, -49.122],
                                  [-114.253, -36.497],
                                  [-17.021, -61.032],
                                  [-130.019, 10.226],
                                  [111.929, 76.117],
                                  [-36.486, 128.776],
                                  [-11.525, 92],
                                ],
                                o: [
                                  [-94.124, 39.074],
                                  [17.1, 114.603],
                                  [-46.682, 49.122],
                                  [114.253, 36.497],
                                  [19.567, 64.166],
                                  [110.041, -5.917],
                                  [-111.929, -76.117],
                                  [32.765, -132.106],
                                  [11.525, -92],
                                ],
                                v: [
                                  [-116.659, -394.616],
                                  [-163.616, -193.944],
                                  [-266.597, 13.429],
                                  [-253.929, 137.94],
                                  [-34.04, 299.392],
                                  [134.089, 401.633],
                                  [306.344, 251.603],
                                  [47.14, -123.507],
                                  [252.432, -222.874],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.993, -36.015],
                                  [-16.718, -114.17],
                                  [46.413, -49.388],
                                  [-113.772, -36.548],
                                  [-17.001, -60.81],
                                  [-130.383, 10.392],
                                  [111.28, 75.957],
                                  [-36.213, 128.226],
                                  [-11.597, 92],
                                ],
                                o: [
                                  [-94.006, 38.812],
                                  [16.718, 114.17],
                                  [-46.413, 49.388],
                                  [113.772, 36.548],
                                  [19.599, 64.007],
                                  [110.002, -5.996],
                                  [-111.28, -75.957],
                                  [32.416, -131.623],
                                  [11.597, -92],
                                ],
                                v: [
                                  [-116.252, -393.871],
                                  [-163.468, -193.906],
                                  [-267.066, 12.473],
                                  [-252.916, 138.143],
                                  [-34.809, 299.155],
                                  [134.478, 401.166],
                                  [305.791, 250.6],
                                  [47.731, -122.629],
                                  [251.703, -221.943],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.154, -35.692],
                                  [-16.329, -113.73],
                                  [46.138, -49.658],
                                  [-113.284, -36.599],
                                  [-16.981, -60.585],
                                  [-130.754, 10.562],
                                  [110.62, 75.795],
                                  [-35.935, 127.665],
                                  [-11.669, 92],
                                ],
                                o: [
                                  [-93.885, 38.546],
                                  [16.329, 113.73],
                                  [-46.138, 49.658],
                                  [113.284, 36.599],
                                  [19.631, 63.846],
                                  [109.962, -6.077],
                                  [-110.62, -75.795],
                                  [32.062, -131.131],
                                  [11.669, -92],
                                ],
                                v: [
                                  [-115.837, -393.009],
                                  [-163.323, -193.883],
                                  [-267.475, 11.523],
                                  [-251.905, 138.346],
                                  [-35.595, 298.919],
                                  [134.831, 400.751],
                                  [305.241, 249.641],
                                  [48.353, -121.672],
                                  [250.898, -221.012],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.318, -35.364],
                                  [-15.933, -113.281],
                                  [45.859, -49.933],
                                  [-112.786, -36.651],
                                  [-16.96, -60.355],
                                  [-131.131, 10.734],
                                  [109.948, 75.63],
                                  [-35.651, 127.095],
                                  [-11.743, 92],
                                ],
                                o: [
                                  [-93.762, 38.275],
                                  [15.933, 113.281],
                                  [-45.859, 49.933],
                                  [112.786, 36.651],
                                  [19.664, 63.682],
                                  [109.921, -6.159],
                                  [-109.948, -75.63],
                                  [31.7, -130.63],
                                  [11.743, -92],
                                ],
                                v: [
                                  [-115.4, -392.181],
                                  [-163.23, -193.911],
                                  [-267.875, 10.578],
                                  [-250.957, 138.585],
                                  [-36.397, 298.684],
                                  [135.22, 400.288],
                                  [304.695, 248.653],
                                  [49.055, -120.735],
                                  [250.128, -220.044],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.485, -35.03],
                                  [-15.53, -112.825],
                                  [45.575, -50.212],
                                  [-112.28, -36.705],
                                  [-16.939, -60.121],
                                  [-131.516, 10.909],
                                  [109.264, 75.462],
                                  [-35.363, 126.514],
                                  [-11.818, 92],
                                ],
                                o: [
                                  [-93.636, 38],
                                  [15.53, 112.825],
                                  [-45.575, 50.212],
                                  [112.28, 36.705],
                                  [19.697, 63.515],
                                  [109.879, -6.242],
                                  [-109.264, -75.462],
                                  [31.333, -130.121],
                                  [11.818, -92],
                                ],
                                v: [
                                  [-114.904, -391.3],
                                  [-163.14, -193.891],
                                  [-268.265, 9.603],
                                  [-249.973, 138.858],
                                  [-37.204, 298.426],
                                  [135.61, 399.827],
                                  [304.089, 247.672],
                                  [49.801, -119.682],
                                  [249.38, -219.028],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.655, -34.69],
                                  [-15.12, -112.361],
                                  [45.286, -50.497],
                                  [-111.764, -36.759],
                                  [-16.918, -59.883],
                                  [-131.907, 11.088],
                                  [108.568, 75.291],
                                  [-35.07, 125.923],
                                  [-11.895, 92],
                                ],
                                o: [
                                  [-93.509, 37.719],
                                  [15.119, 112.361],
                                  [-45.286, 50.497],
                                  [111.764, 36.759],
                                  [19.731, 63.345],
                                  [109.836, -6.328],
                                  [-108.568, -75.291],
                                  [30.959, -129.602],
                                  [11.895, -92],
                                ],
                                v: [
                                  [-114.338, -390.354],
                                  [-163.102, -193.885],
                                  [-268.596, 8.636],
                                  [-249.054, 139.129],
                                  [-38.09, 298.182],
                                  [136.034, 399.404],
                                  [303.452, 246.749],
                                  [50.614, -118.599],
                                  [248.629, -218.062],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.828, -34.344],
                                  [-14.702, -111.888],
                                  [44.992, -50.787],
                                  [-111.239, -36.814],
                                  [-16.896, -59.641],
                                  [-132.305, 11.27],
                                  [107.859, 75.117],
                                  [-34.771, 125.322],
                                  [-11.973, 92],
                                ],
                                o: [
                                  [-93.379, 37.433],
                                  [14.702, 111.888],
                                  [-44.992, 50.787],
                                  [111.239, 36.814],
                                  [19.766, 63.172],
                                  [109.793, -6.414],
                                  [-107.859, -75.117],
                                  [30.578, -129.074],
                                  [11.973, -92],
                                ],
                                v: [
                                  [-113.762, -389.392],
                                  [-163.116, -193.881],
                                  [-268.931, 7.663],
                                  [-248.197, 139.398],
                                  [-38.916, 297.928],
                                  [136.457, 398.932],
                                  [302.806, 245.833],
                                  [51.507, -117.485],
                                  [247.835, -217.05],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.004, -33.991],
                                  [-14.277, -111.407],
                                  [44.693, -51.082],
                                  [-110.706, -36.87],
                                  [-16.874, -59.394],
                                  [-132.71, 11.455],
                                  [107.138, 74.939],
                                  [-34.467, 124.71],
                                  [-12.052, 92],
                                ],
                                o: [
                                  [-93.247, 37.143],
                                  [14.277, 111.407],
                                  [-44.693, 51.082],
                                  [110.706, 36.87],
                                  [19.801, 62.996],
                                  [109.749, -6.502],
                                  [-107.138, -74.939],
                                  [30.19, -128.537],
                                  [12.052, -92],
                                ],
                                v: [
                                  [-113.164, -388.429],
                                  [-163.131, -193.94],
                                  [-269.194, 6.711],
                                  [-247.355, 139.712],
                                  [-39.822, 297.629],
                                  [136.831, 398.497],
                                  [302.116, 244.877],
                                  [52.43, -116.293],
                                  [247.038, -215.991],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.184, -33.633],
                                  [-13.844, -110.917],
                                  [44.388, -51.383],
                                  [-110.162, -36.927],
                                  [-16.852, -59.143],
                                  [-133.122, 11.643],
                                  [106.405, 74.759],
                                  [-34.158, 124.087],
                                  [-12.133, 92],
                                ],
                                o: [
                                  [-93.112, 36.847],
                                  [13.844, 110.917],
                                  [-44.388, 51.383],
                                  [110.162, 36.927],
                                  [19.837, 62.816],
                                  [109.704, -6.592],
                                  [-106.405, -74.759],
                                  [29.796, -127.99],
                                  [12.133, -92],
                                ],
                                v: [
                                  [-112.507, -387.415],
                                  [-163.181, -193.999],
                                  [-269.462, 5.782],
                                  [-246.528, 140.056],
                                  [-40.683, 297.319],
                                  [137.3, 398.015],
                                  [301.431, 243.992],
                                  [53.434, -115.083],
                                  [246.258, -214.949],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.366, -33.268],
                                  [-13.404, -110.419],
                                  [44.078, -51.688],
                                  [-109.609, -36.985],
                                  [-16.829, -58.888],
                                  [-133.542, 11.834],
                                  [105.658, 74.575],
                                  [-33.844, 123.453],
                                  [-12.215, 92],
                                ],
                                o: [
                                  [-92.975, 36.546],
                                  [13.404, 110.419],
                                  [-44.078, 51.688],
                                  [109.609, 36.985],
                                  [19.873, 62.634],
                                  [109.659, -6.683],
                                  [-105.658, -74.575],
                                  [29.395, -127.434],
                                  [12.215, -92],
                                ],
                                v: [
                                  [-111.827, -386.339],
                                  [-163.247, -194.072],
                                  [-269.673, 4.847],
                                  [-245.701, 140.396],
                                  [-41.609, 297.061],
                                  [137.704, 397.567],
                                  [300.704, 243.102],
                                  [54.518, -113.794],
                                  [245.459, -213.897],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.552, -32.897],
                                  [-12.956, -109.912],
                                  [43.762, -51.999],
                                  [-109.046, -37.045],
                                  [-16.806, -58.628],
                                  [-133.969, 12.029],
                                  [104.898, 74.389],
                                  [-33.523, 122.808],
                                  [-12.298, 92],
                                ],
                                o: [
                                  [-92.836, 36.24],
                                  [12.956, 109.912],
                                  [-43.762, 51.999],
                                  [109.046, 37.045],
                                  [19.91, 62.448],
                                  [109.612, -6.776],
                                  [-104.898, -74.389],
                                  [28.986, -126.867],
                                  [12.298, -92],
                                ],
                                v: [
                                  [-111.09, -385.296],
                                  [-163.362, -194.144],
                                  [-269.874, 3.921],
                                  [-244.985, 140.716],
                                  [-42.505, 296.746],
                                  [138.092, 397.072],
                                  [299.982, 242.235],
                                  [55.681, -112.475],
                                  [244.675, -212.815],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.741, -32.519],
                                  [-12.501, -109.396],
                                  [43.441, -52.316],
                                  [-108.474, -37.105],
                                  [-16.782, -58.363],
                                  [-134.403, 12.228],
                                  [104.124, 74.199],
                                  [-33.197, 122.151],
                                  [-12.383, 92],
                                ],
                                o: [
                                  [-92.695, 35.928],
                                  [12.501, 109.396],
                                  [-43.441, 52.315],
                                  [108.474, 37.105],
                                  [19.948, 62.259],
                                  [109.565, -6.87],
                                  [-104.124, -74.198],
                                  [28.571, -126.291],
                                  [12.383, -92],
                                ],
                                v: [
                                  [-110.327, -384.174],
                                  [-163.525, -194.231],
                                  [-270.004, 3.005],
                                  [-244.236, 141.078],
                                  [-43.465, 296.45],
                                  [138.539, 396.61],
                                  [299.218, 241.378],
                                  [56.907, -111.092],
                                  [243.822, -211.738],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.933, -32.134],
                                  [-12.037, -108.871],
                                  [43.114, -52.638],
                                  [-107.891, -37.166],
                                  [-16.758, -58.094],
                                  [-134.846, 12.43],
                                  [103.337, 74.005],
                                  [-32.866, 121.483],
                                  [-12.47, 92],
                                ],
                                o: [
                                  [-92.55, 35.611],
                                  [12.037, 108.871],
                                  [-43.114, 52.638],
                                  [107.891, 37.166],
                                  [19.987, 62.067],
                                  [109.517, -6.966],
                                  [-103.337, -74.005],
                                  [28.148, -125.705],
                                  [12.47, -92],
                                ],
                                v: [
                                  [-109.539, -383.069],
                                  [-163.72, -194.363],
                                  [-270.141, 2.098],
                                  [-243.596, 141.48],
                                  [-44.381, 296.146],
                                  [138.953, 396.148],
                                  [298.461, 240.577],
                                  [58.181, -109.631],
                                  [243.044, -210.62],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.128, -31.743],
                                  [-11.565, -108.337],
                                  [42.782, -52.965],
                                  [-107.298, -37.228],
                                  [-16.734, -57.82],
                                  [-135.295, 12.635],
                                  [102.537, 73.808],
                                  [-32.528, 120.804],
                                  [-12.558, 92],
                                ],
                                o: [
                                  [-92.404, 35.288],
                                  [11.565, 108.337],
                                  [-42.782, 52.965],
                                  [107.298, 37.228],
                                  [20.026, 61.872],
                                  [109.468, -7.064],
                                  [-102.537, -73.808],
                                  [27.717, -125.108],
                                  [12.558, -92],
                                ],
                                v: [
                                  [-108.693, -381.92],
                                  [-163.929, -194.556],
                                  [-270.19, 1.217],
                                  [-242.97, 141.876],
                                  [-45.362, 295.848],
                                  [139.378, 395.686],
                                  [297.711, 239.739],
                                  [59.579, -108.155],
                                  [242.198, -209.507],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.327, -31.345],
                                  [-11.085, -107.793],
                                  [42.443, -53.298],
                                  [-106.695, -37.292],
                                  [-16.709, -57.542],
                                  [-135.753, 12.844],
                                  [101.722, 73.608],
                                  [-32.185, 120.112],
                                  [-12.647, 92],
                                ],
                                o: [
                                  [-92.254, 34.96],
                                  [11.085, 107.793],
                                  [-42.443, 53.298],
                                  [106.695, 37.292],
                                  [20.065, 61.673],
                                  [109.418, -7.164],
                                  [-101.722, -73.608],
                                  [27.28, -124.501],
                                  [12.647, -92],
                                ],
                                v: [
                                  [-107.775, -380.726],
                                  [-164.184, -194.745],
                                  [-270.263, 0.33],
                                  [-242.405, 142.294],
                                  [-46.313, 295.541],
                                  [139.814, 395.208],
                                  [296.891, 238.973],
                                  [61.01, -106.632],
                                  [241.36, -208.354],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.53, -30.94],
                                  [-10.597, -107.24],
                                  [42.099, -53.638],
                                  [-106.081, -37.356],
                                  [-16.684, -57.258],
                                  [-136.219, 13.056],
                                  [100.893, 73.404],
                                  [-31.836, 119.408],
                                  [-12.738, 92],
                                ],
                                o: [
                                  [-92.103, 34.626],
                                  [10.597, 107.24],
                                  [-42.099, 53.638],
                                  [106.081, 37.356],
                                  [20.106, 61.47],
                                  [109.368, -7.265],
                                  [-100.893, -73.404],
                                  [26.834, -123.884],
                                  [12.738, -92],
                                ],
                                v: [
                                  [-106.875, -379.582],
                                  [-164.513, -194.931],
                                  [-270.234, -0.545],
                                  [-241.885, 142.703],
                                  [-47.329, 295.195],
                                  [140.215, 394.713],
                                  [296.11, 238.218],
                                  [62.548, -105.065],
                                  [240.499, -207.191],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.736, -30.528],
                                  [-10.1, -106.677],
                                  [41.749, -53.983],
                                  [-105.457, -37.422],
                                  [-16.658, -56.97],
                                  [-136.693, 13.273],
                                  [100.05, 73.197],
                                  [-31.481, 118.693],
                                  [-12.831, 92],
                                ],
                                o: [
                                  [-91.948, 34.286],
                                  [10.1, 106.677],
                                  [-41.749, 53.983],
                                  [105.457, 37.422],
                                  [20.147, 61.264],
                                  [109.316, -7.368],
                                  [-100.05, -73.197],
                                  [26.381, -123.255],
                                  [12.831, -92],
                                ],
                                v: [
                                  [-105.918, -378.335],
                                  [-164.839, -195.175],
                                  [-270.198, -1.426],
                                  [-241.424, 143.103],
                                  [-48.317, 294.905],
                                  [140.624, 394.217],
                                  [295.291, 237.491],
                                  [64.179, -103.421],
                                  [239.643, -206.052],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.945, -30.109],
                                  [-9.594, -106.105],
                                  [41.393, -54.334],
                                  [-104.821, -37.489],
                                  [-16.632, -56.676],
                                  [-137.175, 13.493],
                                  [99.192, 72.986],
                                  [-31.119, 117.964],
                                  [-12.925, 92],
                                ],
                                o: [
                                  [-91.791, 33.94],
                                  [9.594, 106.105],
                                  [-41.393, 54.334],
                                  [104.821, 37.489],
                                  [20.189, 61.055],
                                  [109.264, -7.473],
                                  [-99.192, -72.986],
                                  [25.92, -122.616],
                                  [12.925, -92],
                                ],
                                v: [
                                  [-104.886, -377.138],
                                  [-165.224, -195.459],
                                  [-270.109, -2.28],
                                  [-241.022, 143.568],
                                  [-49.325, 294.577],
                                  [141.044, 393.766],
                                  [294.497, 236.82],
                                  [65.825, -101.746],
                                  [238.762, -204.921],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.159, -29.683],
                                  [-9.08, -105.523],
                                  [41.03, -54.691],
                                  [-104.175, -37.557],
                                  [-16.605, -56.378],
                                  [-137.665, 13.717],
                                  [98.319, 72.772],
                                  [-30.751, 117.224],
                                  [-13.021, 92],
                                ],
                                o: [
                                  [-91.631, 33.588],
                                  [9.08, 105.523],
                                  [-41.03, 54.691],
                                  [104.175, 37.557],
                                  [20.232, 60.841],
                                  [109.21, -7.579],
                                  [-98.319, -72.772],
                                  [25.451, -121.966],
                                  [13.021, -92],
                                ],
                                v: [
                                  [-103.826, -375.885],
                                  [-165.588, -195.753],
                                  [-270.029, -3.139],
                                  [-240.678, 144.038],
                                  [-50.379, 294.306],
                                  [141.469, 393.25],
                                  [293.668, 236.161],
                                  [67.501, -100.014],
                                  [237.855, -203.754],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.376, -29.249],
                                  [-8.556, -104.93],
                                  [40.661, -55.054],
                                  [-103.518, -37.626],
                                  [-16.578, -56.074],
                                  [-138.164, 13.944],
                                  [97.431, 72.553],
                                  [-30.377, 116.47],
                                  [-13.119, 92],
                                ],
                                o: [
                                  [-91.468, 33.23],
                                  [8.556, 104.93],
                                  [-40.661, 55.054],
                                  [103.518, 37.626],
                                  [20.275, 60.624],
                                  [109.156, -7.688],
                                  [-97.431, -72.553],
                                  [24.974, -121.304],
                                  [13.119, -92],
                                ],
                                v: [
                                  [-102.753, -374.611],
                                  [-166.037, -196.086],
                                  [-269.881, -3.941],
                                  [-240.374, 144.48],
                                  [-51.424, 293.984],
                                  [141.796, 392.715],
                                  [292.893, 235.531],
                                  [69.254, -98.251],
                                  [236.967, -202.55],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.596, -28.808],
                                  [-8.024, -104.328],
                                  [40.286, -55.424],
                                  [-102.849, -37.696],
                                  [-16.55, -55.765],
                                  [-138.671, 14.176],
                                  [96.528, 72.331],
                                  [-29.997, 115.703],
                                  [-13.218, 92],
                                ],
                                o: [
                                  [-91.303, 32.866],
                                  [8.024, 104.328],
                                  [-40.286, 55.424],
                                  [102.849, 37.696],
                                  [20.319, 60.404],
                                  [109.101, -7.798],
                                  [-96.528, -72.331],
                                  [24.488, -120.632],
                                  [13.218, -92],
                                ],
                                v: [
                                  [-101.649, -373.372],
                                  [-166.525, -196.454],
                                  [-269.68, -4.795],
                                  [-240.188, 144.953],
                                  [-52.471, 293.673],
                                  [142.071, 392.134],
                                  [292.058, 234.976],
                                  [71.101, -96.369],
                                  [235.988, -201.383],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.821, -28.359],
                                  [-7.483, -103.715],
                                  [39.905, -55.8],
                                  [-102.169, -37.768],
                                  [-16.522, -55.451],
                                  [-139.188, 14.412],
                                  [95.609, 72.105],
                                  [-29.609, 114.923],
                                  [-13.319, 92],
                                ],
                                o: [
                                  [-91.135, 32.496],
                                  [7.483, 103.715],
                                  [-39.905, 55.8],
                                  [102.169, 37.768],
                                  [20.364, 60.179],
                                  [109.045, -7.91],
                                  [-95.609, -72.105],
                                  [23.995, -119.947],
                                  [13.319, -92],
                                ],
                                v: [
                                  [-100.468, -372.08],
                                  [-167.05, -196.875],
                                  [-269.474, -5.575],
                                  [-239.976, 145.395],
                                  [-53.556, 293.375],
                                  [142.323, 391.488],
                                  [291.26, 234.434],
                                  [72.899, -94.494],
                                  [235.069, -200.2],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.049, -27.902],
                                  [-6.932, -103.091],
                                  [39.517, -56.182],
                                  [-101.477, -37.841],
                                  [-16.494, -55.131],
                                  [-139.712, 14.651],
                                  [94.675, 71.876],
                                  [-29.216, 114.13],
                                  [-13.422, 92],
                                ],
                                o: [
                                  [-90.963, 32.119],
                                  [6.932, 103.091],
                                  [-39.517, 56.182],
                                  [101.477, 37.841],
                                  [20.41, 59.951],
                                  [108.988, -8.024],
                                  [-94.675, -71.876],
                                  [23.492, -119.251],
                                  [13.422, -92],
                                ],
                                v: [
                                  [-99.275, -370.772],
                                  [-167.611, -197.347],
                                  [-269.173, -6.38],
                                  [-239.838, 145.884],
                                  [-54.618, 293.091],
                                  [142.46, 390.841],
                                  [290.449, 233.905],
                                  [74.834, -92.572],
                                  [234.077, -198.965],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.281, -27.438],
                                  [-6.372, -102.457],
                                  [39.122, -56.571],
                                  [-100.773, -37.915],
                                  [-16.465, -54.806],
                                  [-140.247, 14.895],
                                  [93.724, 71.642],
                                  [-28.815, 113.323],
                                  [-13.526, 92],
                                ],
                                o: [
                                  [-90.789, 31.736],
                                  [6.372, 102.457],
                                  [-39.122, 56.571],
                                  [100.773, 37.915],
                                  [20.456, 59.719],
                                  [108.93, -8.141],
                                  [-93.724, -71.642],
                                  [22.982, -118.543],
                                  [13.526, -92],
                                ],
                                v: [
                                  [-98.047, -369.52],
                                  [-168.188, -197.807],
                                  [-268.93, -7.175],
                                  [-239.733, 146.34],
                                  [-55.725, 292.779],
                                  [142.549, 390.106],
                                  [289.669, 233.472],
                                  [76.801, -90.553],
                                  [233.078, -197.784],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.517, -26.966],
                                  [-5.802, -101.812],
                                  [38.721, -56.966],
                                  [-100.057, -37.99],
                                  [-16.435, -54.476],
                                  [-140.79, 15.143],
                                  [92.758, 71.405],
                                  [-28.408, 112.503],
                                  [-13.633, 92],
                                ],
                                o: [
                                  [-90.612, 31.347],
                                  [5.802, 101.812],
                                  [-38.721, 56.966],
                                  [100.057, 37.99],
                                  [20.503, 59.483],
                                  [108.871, -8.259],
                                  [-92.758, -71.404],
                                  [22.462, -117.822],
                                  [13.633, -92],
                                ],
                                v: [
                                  [-96.743, -368.218],
                                  [-168.816, -198.34],
                                  [-268.576, -7.915],
                                  [-239.638, 146.799],
                                  [-56.829, 292.526],
                                  [142.611, 389.332],
                                  [288.903, 233.052],
                                  [78.78, -88.525],
                                  [232.048, -196.553],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [112.757, -26.485],
                                  [-5.223, -101.156],
                                  [38.312, -57.369],
                                  [-99.329, -38.066],
                                  [-16.405, -54.14],
                                  [-141.342, 15.395],
                                  [91.774, 71.163],
                                  [-27.993, 111.668],
                                  [-13.741, 92],
                                ],
                                o: [
                                  [-90.432, 30.95],
                                  [5.223, 101.156],
                                  [-38.312, 57.369],
                                  [99.329, 38.066],
                                  [20.551, 59.243],
                                  [108.811, -8.379],
                                  [-91.774, -71.163],
                                  [21.934, -117.09],
                                  [13.741, -92],
                                ],
                                v: [
                                  [-95.423, -366.887],
                                  [-169.457, -198.877],
                                  [-268.174, -8.664],
                                  [-239.594, 147.223],
                                  [-57.997, 292.229],
                                  [142.562, 388.576],
                                  [288.151, 232.648],
                                  [80.811, -86.47],
                                  [230.985, -195.31],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.002, -25.997],
                                  [-4.633, -100.489],
                                  [37.897, -57.778],
                                  [-98.589, -38.144],
                                  [-16.375, -53.798],
                                  [-141.904, 15.652],
                                  [90.774, 70.917],
                                  [-27.572, 110.819],
                                  [-13.851, 92],
                                ],
                                o: [
                                  [-90.249, 30.547],
                                  [4.633, 100.489],
                                  [-37.897, 57.778],
                                  [98.589, 38.144],
                                  [20.6, 58.998],
                                  [108.75, -8.501],
                                  [-90.774, -70.917],
                                  [21.396, -116.345],
                                  [13.851, -92],
                                ],
                                v: [
                                  [-94.067, -365.613],
                                  [-170.128, -199.441],
                                  [-267.769, -9.422],
                                  [-239.582, 147.63],
                                  [-59.145, 292.012],
                                  [142.465, 387.738],
                                  [287.412, 232.342],
                                  [82.896, -84.323],
                                  [229.93, -194.103],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.25, -25.5],
                                  [-4.034, -99.811],
                                  [37.475, -58.194],
                                  [-97.836, -38.223],
                                  [-16.344, -53.45],
                                  [-142.475, 15.913],
                                  [89.757, 70.667],
                                  [-27.144, 109.956],
                                  [-13.963, 92],
                                ],
                                o: [
                                  [-90.062, 30.137],
                                  [4.034, 99.811],
                                  [-37.475, 58.194],
                                  [97.836, 38.223],
                                  [20.65, 58.75],
                                  [108.688, -8.625],
                                  [-89.757, -70.667],
                                  [20.85, -115.587],
                                  [13.963, -92],
                                ],
                                v: [
                                  [-92.675, -364.314],
                                  [-170.784, -199.986],
                                  [-267.339, -10.171],
                                  [-239.618, 148.02],
                                  [-60.316, 291.752],
                                  [142.299, 386.876],
                                  [286.71, 232.093],
                                  [85.055, -82.15],
                                  [228.82, -192.845],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.503, -24.995],
                                  [-3.425, -99.121],
                                  [37.045, -58.617],
                                  [-97.07, -38.304],
                                  [-16.312, -53.096],
                                  [-143.056, 16.178],
                                  [88.723, 70.413],
                                  [-26.708, 109.078],
                                  [-14.076, 92],
                                ],
                                o: [
                                  [-89.873, 29.72],
                                  [3.425, 99.121],
                                  [-37.045, 58.617],
                                  [97.07, 38.304],
                                  [20.701, 58.497],
                                  [108.624, -8.751],
                                  [-88.723, -70.413],
                                  [20.294, -114.817],
                                  [14.076, -92],
                                ],
                                v: [
                                  [-91.264, -363.032],
                                  [-171.469, -200.533],
                                  [-266.842, -10.909],
                                  [-239.662, 148.371],
                                  [-61.51, 291.513],
                                  [142.087, 385.932],
                                  [286.004, 231.903],
                                  [87.246, -79.971],
                                  [227.717, -191.601],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [113.76, -24.481],
                                  [-2.805, -98.419],
                                  [36.609, -59.047],
                                  [-96.291, -38.386],
                                  [-16.28, -52.737],
                                  [-143.647, 16.448],
                                  [87.671, 70.154],
                                  [-26.265, 108.185],
                                  [-14.192, 92],
                                ],
                                o: [
                                  [-89.68, 29.297],
                                  [2.805, 98.419],
                                  [-36.609, 59.047],
                                  [96.291, 38.386],
                                  [20.752, 58.24],
                                  [108.56, -8.88],
                                  [-87.671, -70.154],
                                  [19.729, -114.033],
                                  [14.192, -92],
                                ],
                                v: [
                                  [-89.815, -361.729],
                                  [-172.179, -201.164],
                                  [-266.342, -11.639],
                                  [-239.712, 148.765],
                                  [-62.707, 291.297],
                                  [141.848, 384.987],
                                  [285.337, 231.731],
                                  [89.468, -77.748],
                                  [226.579, -190.37],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [114.021, -23.958],
                                  [-2.175, -97.705],
                                  [36.164, -59.485],
                                  [-95.499, -38.469],
                                  [-16.247, -52.371],
                                  [-144.248, 16.722],
                                  [86.602, 69.891],
                                  [-25.814, 107.277],
                                  [-14.309, 92],
                                ],
                                o: [
                                  [-89.484, 28.866],
                                  [2.175, 97.705],
                                  [-36.164, 59.485],
                                  [95.499, 38.469],
                                  [20.804, 57.979],
                                  [108.495, -9.01],
                                  [-86.602, -69.891],
                                  [19.154, -113.236],
                                  [14.309, -92],
                                ],
                                v: [
                                  [-88.284, -360.488],
                                  [-172.873, -201.773],
                                  [-265.777, -12.383],
                                  [-239.789, 149.1],
                                  [-63.93, 291.126],
                                  [141.5, 384.042],
                                  [284.689, 231.618],
                                  [91.701, -75.459],
                                  [225.467, -189.13],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [9.469, 11.938],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9, 11.406],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.5, 10.938],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, 10.438],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.719, 9.969],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.375, 9.5],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.031, 9.062],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.469, 8.25],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.25, 7.875],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.031, 7.531],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.875, 7.156],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.75, 6.906],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.625, 6.594],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.562, 6.312],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.469, 6.031],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.469, 5.75],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.469, 5.531],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.5, 5.312],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.531, 5.125],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.594, 4.906],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.688, 4.688],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.812, 4.531],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.969, 4.375],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.125, 4.156],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.312, 4.031],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.875, 3.875],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.938, 3.719],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.938, 3.594],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.969, 3.438],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.969, 3.281],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.781, 3.125],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.25, 3],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.719, 2.844],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.156, 2.656],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.625, 2.5],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.094, 2.375],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.531, 2.188],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.031, 2.031],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.469, 1.844],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.969, 1.656],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.906, 1.219],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 1],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.875, 0.781],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.812, 0.406],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.312, 0.219],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.812, 0.094],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.281, -0.031],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.781, -0.125],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.312, -0.25],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.781, -0.312],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.312, -0.375],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, -0.438],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.312, -0.469],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.875, -0.531],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.406, -0.562],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, -0.531],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.438, -0.531],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, -0.469],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.562, -0.469],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, -0.406],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.656, -0.344],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.219, -0.281],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.719, -0.156],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.312, -0.094],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.844, 0.062],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.531, 0.438],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.062, 0.625],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.656, 0.781],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 1],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.312, 1.375],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.844, 1.594],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.344, 1.875],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.844, 2.094],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.375, 2.312],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.844, 2.625],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.312, 2.875],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 3.188],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.219, 3.469],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.688, 3.75],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.125, 4.094],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.5, 4.438],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.656, 4.75],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.688, 5.094],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 5.5],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.844, 5.938],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.906, 6.406],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.969, 6.844],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.969, 7.344],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.969, 7.875],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40, 8.344],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.969, 8.938],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40, 9.469],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.938, 10.594],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.906, 11.219],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.906, 11.812],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 12.438],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.781, 13.031],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.688, 13.625],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.625, 14.25],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.531, 14.812],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.5, 15.406],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.375, 16.031],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.312, 16.594],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.188, 17.125],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.125, 17.719],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39, 18.25],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.844, 18.812],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.75, 19.312],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.625, 19.844],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.375, 20.344],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.375, 21.219],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.812, 21.656],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.25, 22.031],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, 22.406],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.75, 22.781],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34, 23.094],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 23.375],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.406, 23.656],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.594, 23.906],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.719, 24.062],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.875, 24.219],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29, 24.344],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.156, 24.469],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.219, 24.594],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.344, 24.625],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.438, 24.688],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.469, 24.688],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.656, 24.688],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.75, 24.594],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.844, 24.594],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.906, 24.469],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.031, 24.344],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.125, 24.188],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.25, 24],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.344, 23.812],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.469, 23.562],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.594, 23.344],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, 23.062],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.875, 22.75],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.062, 22.438],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.219, 22.062],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.438, 21.719],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.625, 21.344],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.844, 20.906],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.125, 20.469],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.344, 20.062],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.625, 19.562],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.094, 19.094],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.031, 18.625],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.938, 18.094],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.906, 17.562],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.938, 17.062],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6, 16.5],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.062, 16],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.125, 15.406],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.25, 14.844],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.406, 14.281],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.906, 13.219],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.125, 12.625],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.438, 12.062],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.75, 11.531],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, 10.969],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.5, 10.469],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.906, 9.906],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.375, 9.375],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.375, 8.375],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.969, 7.938],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.469, 7.438],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 7.031],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.562, 6.594],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.094, 6.219],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.656, 5.844],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.219, 5.5],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.75, 5.156],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.344, 4.844],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.906, 4.594],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.969, 4.094],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.531, 3.875],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.094, 3.688],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, 3.5],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.219, 3.344],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.719, 3.188],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.219, 3.094],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.75, 2.969],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.25, 2.875],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.688, 2.844],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.188, 2.812],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.625, 2.75],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.094, 2.75],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 2.75],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.188, 2.781],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.719, 2.844],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.281, 2.906],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.812, 2.969],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.312, 3.094],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.375, 3.344],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.375, 3.656],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.875, 3.875],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.406, 4.062],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.906, 4.25],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 4.5],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.938, 4.781],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16, 5.281],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.5, 5.625],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.062, 5.875],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.594, 6.219],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.156, 6.531],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, 6.875],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.312, 7.219],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.938, 7.562],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.188, 8.312],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.844, 8.688],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.5, 9.031],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.875, 9.719],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.625, 10.031],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.375, 10.312],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.156, 10.562],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.969, 10.844],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.844, 11.062],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.688, 11.281],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.594, 11.438],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.5, 11.625],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.469, 11.781],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [9.469, 11.938],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9, 11.406],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.5, 10.938],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, 10.438],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.719, 9.969],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.375, 9.5],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.031, 9.062],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.469, 8.25],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.25, 7.875],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.031, 7.531],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.875, 7.156],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.75, 6.906],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.625, 6.594],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.562, 6.312],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.469, 6.031],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.469, 5.75],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.469, 5.531],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.5, 5.312],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.531, 5.125],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.594, 4.906],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.688, 4.688],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.812, 4.531],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.969, 4.375],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.125, 4.156],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.312, 4.031],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.875, 3.875],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.938, 3.719],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.938, 3.594],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.969, 3.438],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.969, 3.281],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.781, 3.125],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.25, 3],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.719, 2.844],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.156, 2.656],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.625, 2.5],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.094, 2.375],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.531, 2.188],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.031, 2.031],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.469, 1.844],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.969, 1.656],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.906, 1.219],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 1],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.875, 0.781],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.812, 0.406],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.312, 0.219],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.812, 0.094],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.281, -0.031],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.781, -0.125],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.312, -0.25],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.781, -0.312],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.312, -0.375],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, -0.438],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.312, -0.469],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.875, -0.531],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.406, -0.562],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.969, -0.531],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.438, -0.531],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26, -0.469],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.562, -0.469],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.062, -0.406],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.656, -0.344],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.219, -0.281],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.719, -0.156],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.312, -0.094],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.844, 0.062],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.531, 0.438],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.062, 0.625],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.656, 0.781],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.188, 1],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.312, 1.375],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.844, 1.594],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.344, 1.875],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.844, 2.094],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.375, 2.312],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.844, 2.625],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.312, 2.875],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.781, 3.188],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.219, 3.469],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.688, 3.75],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.125, 4.094],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.5, 4.438],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.656, 4.75],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.688, 5.094],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 5.5],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.844, 5.938],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.906, 6.406],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.969, 6.844],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.969, 7.344],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.969, 7.875],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40, 8.344],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.969, 8.938],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40, 9.469],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.938, 10.594],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.906, 11.219],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.906, 11.812],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 12.438],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.781, 13.031],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.688, 13.625],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.625, 14.25],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.531, 14.812],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.5, 15.406],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.375, 16.031],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.312, 16.594],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.188, 17.125],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.125, 17.719],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39, 18.25],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.844, 18.812],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.75, 19.312],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.625, 19.844],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.375, 20.344],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.375, 21.219],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.812, 21.656],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.25, 22.031],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, 22.406],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.75, 22.781],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34, 23.094],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.219, 23.375],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.406, 23.656],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.594, 23.906],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.719, 24.062],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.875, 24.219],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29, 24.344],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.156, 24.469],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.219, 24.594],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.344, 24.625],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.438, 24.688],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.469, 24.688],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.656, 24.688],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.75, 24.594],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.844, 24.594],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.906, 24.469],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.031, 24.344],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.125, 24.188],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.25, 24],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.344, 23.812],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.469, 23.562],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.594, 23.344],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, 23.062],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.875, 22.75],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.062, 22.438],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.219, 22.062],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.438, 21.719],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.625, 21.344],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.844, 20.906],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.125, 20.469],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.344, 20.062],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.625, 19.562],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.094, 19.094],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.031, 18.625],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.938, 18.094],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.906, 17.562],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.938, 17.062],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6, 16.5],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.062, 16],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.125, 15.406],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.25, 14.844],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.406, 14.281],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.906, 13.219],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.125, 12.625],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.438, 12.062],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.75, 11.531],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, 10.969],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.5, 10.469],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.906, 9.906],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.375, 9.375],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.375, 8.375],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.969, 7.938],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.469, 7.438],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12, 7.031],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.562, 6.594],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.094, 6.219],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.656, 5.844],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.219, 5.5],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.75, 5.156],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.344, 4.844],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.906, 4.594],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.969, 4.094],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.531, 3.875],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.094, 3.688],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, 3.5],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.219, 3.344],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.719, 3.188],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.219, 3.094],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.75, 2.969],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.25, 2.875],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.688, 2.844],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.188, 2.812],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.625, 2.75],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.094, 2.75],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.5, 2.75],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.188, 2.781],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.719, 2.844],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.281, 2.906],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.812, 2.969],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.312, 3.094],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.375, 3.344],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.375, 3.656],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.875, 3.875],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.406, 4.062],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.906, 4.25],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 4.5],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.938, 4.781],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16, 5.281],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.5, 5.625],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.062, 5.875],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.594, 6.219],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.156, 6.531],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, 6.875],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.312, 7.219],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.938, 7.562],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.188, 8.312],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.844, 8.688],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.5, 9.031],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.875, 9.719],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.625, 10.031],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.375, 10.312],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.156, 10.562],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.969, 10.844],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.844, 11.062],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.688, 11.281],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.594, 11.438],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.5, 11.625],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.469, 11.781],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 10',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 10,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [111.429, -29.141],
                                  [-8.427, -104.784],
                                  [40.57, -55.144],
                                  [-103.355, -37.643],
                                  [-16.571, -55.999],
                                  [-138.288, 14.001],
                                  [97.211, 72.499],
                                  [-30.284, 116.283],
                                  [-13.143, 92],
                                ],
                                o: [
                                  [-91.428, 33.142],
                                  [8.427, 104.784],
                                  [-40.57, 55.144],
                                  [103.355, 37.643],
                                  [20.286, 60.571],
                                  [109.143, -7.715],
                                  [-97.211, -72.499],
                                  [24.855, -121.14],
                                  [13.143, -92],
                                ],
                                v: [
                                  [-97.131, -371.015],
                                  [-172.304, -194.993],
                                  [-267.49, -7.868],
                                  [-238.849, 149.152],
                                  [-49.513, 297.141],
                                  [142.848, 387.927],
                                  [279.635, 229.411],
                                  [71.649, -94.565],
                                  [237.059, -195.56],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.193, -29.613],
                                  [-8.996, -105.428],
                                  [40.971, -54.749],
                                  [-104.07, -37.568],
                                  [-16.601, -56.329],
                                  [-137.745, 13.753],
                                  [98.177, 72.737],
                                  [-30.691, 117.103],
                                  [-13.037, 92],
                                ],
                                o: [
                                  [-91.605, 33.531],
                                  [8.996, 105.428],
                                  [-40.971, 54.749],
                                  [104.07, 37.568],
                                  [20.239, 60.807],
                                  [109.202, -7.597],
                                  [-98.177, -72.737],
                                  [25.374, -121.86],
                                  [13.037, -92],
                                ],
                                v: [
                                  [-97.416, -371.449],
                                  [-172.922, -194.271],
                                  [-267.085, -7.321],
                                  [-238.858, 149.807],
                                  [-47.979, 297.829],
                                  [142.481, 387.589],
                                  [278.33, 229.115],
                                  [70.429, -95.531],
                                  [238.514, -195.617],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.962, -30.076],
                                  [-9.554, -106.06],
                                  [41.365, -54.361],
                                  [-104.771, -37.494],
                                  [-16.63, -56.653],
                                  [-137.213, 13.51],
                                  [99.124, 72.969],
                                  [-31.091, 117.907],
                                  [-12.933, 92],
                                ],
                                o: [
                                  [-91.779, 33.913],
                                  [9.554, 106.06],
                                  [-41.365, 54.361],
                                  [104.771, 37.494],
                                  [20.192, 61.038],
                                  [109.26, -7.481],
                                  [-99.124, -72.969],
                                  [25.884, -122.566],
                                  [12.933, -92],
                                ],
                                v: [
                                  [-97.671, -371.856],
                                  [-173.497, -193.63],
                                  [-266.636, -6.784],
                                  [-238.845, 150.484],
                                  [-46.404, 298.478],
                                  [142.02, 387.24],
                                  [277.035, 228.831],
                                  [69.203, -96.448],
                                  [239.965, -195.69],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.735, -30.53],
                                  [-10.102, -106.68],
                                  [41.751, -53.981],
                                  [-105.46, -37.422],
                                  [-16.658, -56.971],
                                  [-136.69, 13.272],
                                  [100.054, 73.198],
                                  [-31.482, 118.696],
                                  [-12.831, 92],
                                ],
                                o: [
                                  [-91.949, 34.288],
                                  [10.102, 106.68],
                                  [-41.751, 53.981],
                                  [105.46, 37.422],
                                  [20.147, 61.265],
                                  [109.316, -7.367],
                                  [-100.054, -73.198],
                                  [26.383, -123.259],
                                  [12.831, -92],
                                ],
                                v: [
                                  [-97.96, -372.235],
                                  [-174.135, -192.99],
                                  [-266.2, -6.183],
                                  [-238.873, 151.181],
                                  [-44.816, 299.06],
                                  [141.581, 386.864],
                                  [275.796, 228.542],
                                  [68.044, -97.334],
                                  [241.409, -195.673],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.512, -30.976],
                                  [-10.64, -107.289],
                                  [42.13, -53.607],
                                  [-106.136, -37.351],
                                  [-16.686, -57.283],
                                  [-136.177, 13.037],
                                  [100.967, 73.422],
                                  [-31.867, 119.471],
                                  [-12.73, 92],
                                ],
                                o: [
                                  [-92.116, 34.655],
                                  [10.64, 107.289],
                                  [-42.13, 53.607],
                                  [106.136, 37.351],
                                  [20.102, 61.488],
                                  [109.372, -7.256],
                                  [-100.967, -73.422],
                                  [26.874, -123.939],
                                  [12.73, -92],
                                ],
                                v: [
                                  [-98.237, -372.615],
                                  [-174.792, -192.352],
                                  [-265.674, -5.605],
                                  [-238.899, 151.869],
                                  [-43.25, 299.622],
                                  [141.045, 386.478],
                                  [274.597, 228.311],
                                  [66.94, -98.142],
                                  [242.819, -195.612],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.293, -31.414],
                                  [-11.168, -107.887],
                                  [42.502, -53.241],
                                  [-106.799, -37.281],
                                  [-16.713, -57.59],
                                  [-135.674, 12.808],
                                  [101.862, 73.642],
                                  [-32.244, 120.231],
                                  [-12.632, 92],
                                ],
                                o: [
                                  [-92.28, 35.016],
                                  [11.168, 107.887],
                                  [-42.502, 53.241],
                                  [106.799, 37.281],
                                  [20.059, 61.707],
                                  [109.427, -7.147],
                                  [-101.862, -73.643],
                                  [27.355, -124.606],
                                  [12.632, -92],
                                ],
                                v: [
                                  [-98.473, -372.907],
                                  [-175.42, -191.795],
                                  [-265.116, -4.989],
                                  [-238.951, 152.576],
                                  [-41.689, 300.117],
                                  [140.456, 386.084],
                                  [273.409, 228.121],
                                  [65.858, -98.949],
                                  [244.285, -195.556],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.079, -31.843],
                                  [-11.686, -108.473],
                                  [42.866, -52.882],
                                  [-107.449, -37.213],
                                  [-16.74, -57.89],
                                  [-135.181, 12.582],
                                  [102.741, 73.858],
                                  [-32.615, 120.977],
                                  [-12.535, 92],
                                ],
                                o: [
                                  [-92.441, 35.37],
                                  [11.686, 108.473],
                                  [-42.866, 52.882],
                                  [107.449, 37.213],
                                  [20.016, 61.921],
                                  [109.48, -7.039],
                                  [-102.741, -73.858],
                                  [27.827, -125.26],
                                  [12.535, -92],
                                ],
                                v: [
                                  [-98.79, -373.125],
                                  [-176.111, -191.242],
                                  [-264.543, -4.378],
                                  [-239.002, 153.253],
                                  [-40.088, 300.622],
                                  [139.799, 385.618],
                                  [272.215, 227.973],
                                  [64.813, -99.679],
                                  [245.728, -195.429],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.868, -32.264],
                                  [-12.194, -109.048],
                                  [43.225, -52.529],
                                  [-108.088, -37.145],
                                  [-16.767, -58.185],
                                  [-134.696, 12.361],
                                  [103.603, 74.07],
                                  [-32.978, 121.709],
                                  [-12.441, 92],
                                ],
                                o: [
                                  [-92.599, 35.718],
                                  [12.194, 109.048],
                                  [-43.225, 52.529],
                                  [108.088, 37.145],
                                  [19.974, 62.132],
                                  [109.533, -6.934],
                                  [-103.603, -74.07],
                                  [28.291, -125.903],
                                  [12.441, -92],
                                ],
                                v: [
                                  [-99.064, -373.304],
                                  [-176.772, -190.706],
                                  [-263.909, -3.757],
                                  [-239.099, 153.901],
                                  [-38.54, 301.061],
                                  [139.136, 385.19],
                                  [271.125, 227.838],
                                  [63.789, -100.424],
                                  [247.196, -195.294],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.661, -32.677],
                                  [-12.692, -109.613],
                                  [43.576, -52.183],
                                  [-108.714, -37.08],
                                  [-16.792, -58.474],
                                  [-134.221, 12.144],
                                  [104.449, 74.278],
                                  [-33.334, 122.427],
                                  [-12.348, 92],
                                ],
                                o: [
                                  [-92.754, 36.059],
                                  [12.692, 109.613],
                                  [-43.576, 52.183],
                                  [108.714, 37.08],
                                  [19.932, 62.339],
                                  [109.585, -6.831],
                                  [-104.449, -74.278],
                                  [28.745, -126.533],
                                  [12.348, -92],
                                ],
                                v: [
                                  [-99.389, -373.413],
                                  [-177.434, -190.237],
                                  [-263.259, -3.078],
                                  [-239.179, 154.563],
                                  [-36.999, 301.466],
                                  [138.404, 384.707],
                                  [269.983, 227.699],
                                  [62.864, -101.077],
                                  [248.594, -195.091],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.459, -33.083],
                                  [-13.181, -110.166],
                                  [43.92, -51.843],
                                  [-109.329, -37.015],
                                  [-16.818, -58.758],
                                  [-133.755, 11.932],
                                  [105.279, 74.482],
                                  [-33.684, 123.132],
                                  [-12.256, 92],
                                ],
                                o: [
                                  [-92.906, 36.393],
                                  [13.181, 110.166],
                                  [-43.92, 51.843],
                                  [109.329, 37.015],
                                  [19.892, 62.541],
                                  [109.635, -6.729],
                                  [-105.279, -74.482],
                                  [29.191, -127.151],
                                  [12.256, -92],
                                ],
                                v: [
                                  [-99.717, -373.533],
                                  [-178.127, -189.723],
                                  [-262.596, -2.37],
                                  [-239.293, 155.256],
                                  [-35.481, 301.854],
                                  [137.618, 384.264],
                                  [268.961, 227.605],
                                  [61.913, -101.731],
                                  [250.047, -194.913],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.26, -33.481],
                                  [-13.661, -110.71],
                                  [44.259, -51.51],
                                  [-109.932, -36.952],
                                  [-16.843, -59.036],
                                  [-133.297, 11.723],
                                  [106.093, 74.682],
                                  [-34.027, 123.823],
                                  [-12.167, 92],
                                ],
                                o: [
                                  [-93.055, 36.722],
                                  [13.661, 110.71],
                                  [-44.259, 51.51],
                                  [109.932, 36.952],
                                  [19.852, 62.74],
                                  [109.685, -6.63],
                                  [-106.093, -74.682],
                                  [29.629, -127.758],
                                  [12.167, -92],
                                ],
                                v: [
                                  [-100.033, -373.541],
                                  [-178.819, -189.276],
                                  [-261.87, -1.631],
                                  [-239.421, 155.866],
                                  [-33.985, 302.161],
                                  [136.826, 383.75],
                                  [267.871, 227.555],
                                  [60.997, -102.309],
                                  [251.492, -194.686],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.065, -33.871],
                                  [-14.132, -111.242],
                                  [44.59, -51.183],
                                  [-110.523, -36.889],
                                  [-16.867, -59.31],
                                  [-132.849, 11.518],
                                  [106.892, 74.879],
                                  [-34.364, 124.501],
                                  [-12.079, 92],
                                ],
                                o: [
                                  [-93.202, 37.043],
                                  [14.132, 111.242],
                                  [-44.59, 51.183],
                                  [110.523, 36.889],
                                  [19.813, 62.935],
                                  [109.734, -6.532],
                                  [-106.892, -74.879],
                                  [30.058, -128.353],
                                  [12.079, -92],
                                ],
                                v: [
                                  [-100.35, -373.564],
                                  [-179.493, -188.847],
                                  [-261.164, -0.894],
                                  [-239.537, 156.552],
                                  [-32.483, 302.513],
                                  [135.979, 383.229],
                                  [266.888, 227.518],
                                  [60.15, -102.857],
                                  [252.896, -194.392],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.873, -34.254],
                                  [-14.594, -111.765],
                                  [44.916, -50.862],
                                  [-111.103, -36.828],
                                  [-16.891, -59.578],
                                  [-132.408, 11.317],
                                  [107.676, 75.071],
                                  [-34.694, 125.166],
                                  [-11.993, 92],
                                ],
                                o: [
                                  [-93.345, 37.359],
                                  [14.594, 111.765],
                                  [-44.916, 50.863],
                                  [111.103, 36.828],
                                  [19.775, 63.127],
                                  [109.782, -6.437],
                                  [-107.676, -75.071],
                                  [30.479, -128.937],
                                  [11.993, -92],
                                ],
                                v: [
                                  [-100.704, -373.478],
                                  [-180.167, -188.435],
                                  [-260.41, -0.109],
                                  [-239.701, 157.152],
                                  [-30.969, 302.773],
                                  [135.063, 382.762],
                                  [265.914, 227.513],
                                  [59.303, -103.361],
                                  [254.34, -194.113],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.685, -34.629],
                                  [-15.047, -112.278],
                                  [45.235, -50.548],
                                  [-111.672, -36.768],
                                  [-16.914, -59.841],
                                  [-131.976, 11.12],
                                  [108.444, 75.26],
                                  [-35.018, 125.818],
                                  [-11.908, 92],
                                ],
                                o: [
                                  [-93.486, 37.669],
                                  [15.047, 112.278],
                                  [-45.235, 50.548],
                                  [111.672, 36.768],
                                  [19.737, 63.315],
                                  [109.829, -6.343],
                                  [-108.444, -75.26],
                                  [30.892, -129.51],
                                  [11.908, -92],
                                ],
                                v: [
                                  [-101.06, -373.397],
                                  [-180.805, -188.028],
                                  [-259.594, 0.661],
                                  [-239.854, 157.762],
                                  [-29.498, 303.065],
                                  [134.141, 382.226],
                                  [264.985, 227.569],
                                  [58.511, -103.884],
                                  [255.74, -193.804],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.501, -34.998],
                                  [-15.491, -112.781],
                                  [45.548, -50.239],
                                  [-112.231, -36.71],
                                  [-16.937, -60.098],
                                  [-131.553, 10.926],
                                  [109.198, 75.446],
                                  [-35.335, 126.458],
                                  [-11.826, 92],
                                ],
                                o: [
                                  [-93.624, 37.973],
                                  [15.491, 112.781],
                                  [-45.548, 50.24],
                                  [112.231, 36.71],
                                  [19.7, 63.499],
                                  [109.875, -6.251],
                                  [-109.198, -75.446],
                                  [31.297, -130.071],
                                  [11.826, -92],
                                ],
                                v: [
                                  [-101.417, -373.335],
                                  [-181.505, -187.651],
                                  [-258.8, 1.48],
                                  [-240.009, 158.383],
                                  [-28.063, 303.294],
                                  [133.163, 381.732],
                                  [264.116, 227.624],
                                  [57.718, -104.317],
                                  [257.118, -193.514],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.321, -35.359],
                                  [-15.927, -113.275],
                                  [45.855, -49.937],
                                  [-112.778, -36.652],
                                  [-16.96, -60.351],
                                  [-131.137, 10.737],
                                  [109.938, 75.627],
                                  [-35.647, 127.086],
                                  [-11.744, 92],
                                ],
                                o: [
                                  [-93.76, 38.271],
                                  [15.927, 113.275],
                                  [-45.855, 49.937],
                                  [112.778, 36.652],
                                  [19.664, 63.679],
                                  [109.92, -6.16],
                                  [-109.938, -75.627],
                                  [31.695, -130.622],
                                  [11.744, -92],
                                ],
                                v: [
                                  [-101.811, -373.17],
                                  [-182.118, -187.278],
                                  [-258.007, 2.335],
                                  [-240.216, 158.977],
                                  [-26.636, 303.508],
                                  [132.178, 381.183],
                                  [263.243, 227.665],
                                  [56.944, -104.757],
                                  [258.5, -193.159],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.143, -35.713],
                                  [-16.354, -113.759],
                                  [46.156, -49.64],
                                  [-113.316, -36.596],
                                  [-16.982, -60.599],
                                  [-130.73, 10.55],
                                  [110.663, 75.806],
                                  [-35.953, 127.702],
                                  [-11.664, 92],
                                ],
                                o: [
                                  [-93.892, 38.564],
                                  [16.354, 113.759],
                                  [-46.156, 49.64],
                                  [113.316, 36.596],
                                  [19.629, 63.857],
                                  [109.964, -6.072],
                                  [-110.663, -75.806],
                                  [32.085, -131.163],
                                  [11.664, -92],
                                ],
                                v: [
                                  [-102.205, -373.029],
                                  [-182.793, -186.922],
                                  [-257.139, 3.204],
                                  [-240.412, 159.53],
                                  [-25.181, 303.681],
                                  [131.124, 380.663],
                                  [262.416, 227.767],
                                  [56.203, -105.121],
                                  [259.886, -192.777],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.97, -36.061],
                                  [-16.773, -114.233],
                                  [46.452, -49.349],
                                  [-113.842, -36.54],
                                  [-17.004, -60.843],
                                  [-130.33, 10.368],
                                  [111.375, 75.981],
                                  [-36.253, 128.306],
                                  [-11.586, 92],
                                ],
                                o: [
                                  [-94.023, 38.85],
                                  [16.774, 114.233],
                                  [-46.452, 49.349],
                                  [113.842, 36.54],
                                  [19.594, 64.03],
                                  [110.008, -5.985],
                                  [-111.375, -75.981],
                                  [32.467, -131.693],
                                  [11.586, -92],
                                ],
                                v: [
                                  [-102.588, -372.837],
                                  [-183.368, -186.582],
                                  [-256.309, 4.099],
                                  [-240.624, 160.041],
                                  [-23.784, 303.854],
                                  [130.076, 380.15],
                                  [261.65, 227.869],
                                  [55.544, -105.494],
                                  [261.25, -192.418],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.799, -36.402],
                                  [-17.185, -114.699],
                                  [46.742, -49.063],
                                  [-114.359, -36.486],
                                  [-17.025, -61.081],
                                  [-129.938, 10.189],
                                  [112.073, 76.152],
                                  [-36.547, 128.898],
                                  [-11.51, 92],
                                ],
                                o: [
                                  [-94.151, 39.132],
                                  [17.185, 114.699],
                                  [-46.742, 49.063],
                                  [114.359, 36.486],
                                  [19.56, 64.201],
                                  [110.05, -5.9],
                                  [-112.073, -76.152],
                                  [32.842, -132.213],
                                  [11.51, -92],
                                ],
                                v: [
                                  [-103.009, -372.671],
                                  [-184.015, -186.259],
                                  [-255.43, 5.057],
                                  [-240.827, 160.57],
                                  [-22.421, 304.051],
                                  [128.958, 379.682],
                                  [260.944, 228.009],
                                  [54.854, -105.791],
                                  [262.617, -192.046],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.632, -36.736],
                                  [-17.588, -115.155],
                                  [47.026, -48.783],
                                  [-114.866, -36.433],
                                  [-17.046, -61.315],
                                  [-129.553, 10.013],
                                  [112.757, 76.32],
                                  [-36.835, 129.479],
                                  [-11.434, 92],
                                ],
                                o: [
                                  [-94.276, 39.407],
                                  [17.588, 115.155],
                                  [-47.026, 48.783],
                                  [114.866, 36.433],
                                  [19.526, 64.368],
                                  [110.092, -5.816],
                                  [-112.757, -76.32],
                                  [33.21, -132.723],
                                  [11.434, -92],
                                ],
                                v: [
                                  [-103.431, -372.471],
                                  [-184.56, -185.951],
                                  [-254.577, 6.006],
                                  [-241.071, 161.106],
                                  [-21.067, 304.174],
                                  [127.834, 379.169],
                                  [260.224, 228.201],
                                  [54.195, -106.098],
                                  [263.924, -191.65],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.468, -37.064],
                                  [-17.983, -115.603],
                                  [47.304, -48.509],
                                  [-115.363, -36.38],
                                  [-17.066, -61.545],
                                  [-129.176, 9.841],
                                  [113.428, 76.485],
                                  [-37.118, 130.049],
                                  [-11.361, 92],
                                ],
                                o: [
                                  [-94.399, 39.678],
                                  [17.983, 115.603],
                                  [-47.304, 48.509],
                                  [115.363, 36.38],
                                  [19.494, 64.532],
                                  [110.133, -5.734],
                                  [-113.428, -76.485],
                                  [33.57, -133.223],
                                  [11.361, -92],
                                ],
                                v: [
                                  [-103.893, -372.249],
                                  [-185.165, -185.658],
                                  [-253.714, 7.008],
                                  [-241.331, 161.546],
                                  [-19.682, 304.285],
                                  [126.704, 378.689],
                                  [259.565, 228.405],
                                  [53.556, -106.369],
                                  [265.284, -191.242],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.307, -37.385],
                                  [-18.371, -116.042],
                                  [47.578, -48.24],
                                  [-115.85, -36.329],
                                  [-17.087, -61.77],
                                  [-128.807, 9.673],
                                  [114.086, 76.647],
                                  [-37.395, 130.607],
                                  [-11.288, 92],
                                ],
                                o: [
                                  [-94.52, 39.943],
                                  [18.371, 116.042],
                                  [-47.578, 48.24],
                                  [115.85, 36.329],
                                  [19.461, 64.693],
                                  [110.173, -5.654],
                                  [-114.086, -76.647],
                                  [33.924, -133.713],
                                  [11.288, -92],
                                ],
                                v: [
                                  [-104.343, -372.034],
                                  [-185.677, -185.329],
                                  [-252.84, 8.012],
                                  [-241.531, 162.053],
                                  [-18.368, 304.373],
                                  [125.516, 378.153],
                                  [258.967, 228.598],
                                  [52.947, -106.602],
                                  [266.583, -190.8],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.15, -37.701],
                                  [-18.752, -116.473],
                                  [47.846, -47.976],
                                  [-116.328, -36.279],
                                  [-17.106, -61.99],
                                  [-128.444, 9.507],
                                  [114.731, 76.806],
                                  [-37.667, 131.155],
                                  [-11.217, 92],
                                ],
                                o: [
                                  [-94.638, 40.203],
                                  [18.752, 116.473],
                                  [-47.846, 47.976],
                                  [116.328, 36.279],
                                  [19.43, 64.85],
                                  [110.213, -5.575],
                                  [-114.731, -76.806],
                                  [34.271, -134.194],
                                  [11.217, -92],
                                ],
                                v: [
                                  [-104.794, -371.852],
                                  [-186.188, -185.066],
                                  [-251.957, 9.059],
                                  [-241.786, 162.462],
                                  [-17.074, 304.451],
                                  [124.31, 377.725],
                                  [258.419, 228.844],
                                  [52.316, -106.811],
                                  [267.872, -190.409],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.995, -38.01],
                                  [-19.124, -116.895],
                                  [48.108, -47.717],
                                  [-116.796, -36.23],
                                  [-17.126, -62.207],
                                  [-128.089, 9.345],
                                  [115.364, 76.961],
                                  [-37.934, 131.692],
                                  [-11.148, 92],
                                ],
                                o: [
                                  [-94.754, 40.458],
                                  [19.124, 116.895],
                                  [-48.108, 47.717],
                                  [116.796, 36.23],
                                  [19.399, 65.005],
                                  [110.251, -5.497],
                                  [-115.364, -76.961],
                                  [34.611, -134.665],
                                  [11.148, -92],
                                ],
                                v: [
                                  [-105.286, -371.589],
                                  [-186.717, -184.765],
                                  [-251.074, 10.109],
                                  [-242.045, 162.875],
                                  [-15.8, 304.558],
                                  [123.058, 377.241],
                                  [257.922, 229.081],
                                  [51.755, -106.984],
                                  [269.151, -189.987],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.844, -38.313],
                                  [-19.49, -117.309],
                                  [48.366, -47.463],
                                  [-117.256, -36.181],
                                  [-17.145, -62.419],
                                  [-127.74, 9.186],
                                  [115.984, 77.114],
                                  [-38.195, 132.219],
                                  [-11.08, 92],
                                ],
                                o: [
                                  [-94.867, 40.708],
                                  [19.49, 117.309],
                                  [-48.366, 47.463],
                                  [117.256, 36.181],
                                  [19.369, 65.156],
                                  [110.289, -5.422],
                                  [-115.984, -77.114],
                                  [34.944, -135.127],
                                  [11.08, -92],
                                ],
                                v: [
                                  [-105.767, -371.411],
                                  [-187.141, -184.529],
                                  [-250.223, 11.214],
                                  [-242.359, 163.25],
                                  [-14.545, 304.605],
                                  [121.851, 376.751],
                                  [257.425, 229.33],
                                  [51.172, -107.174],
                                  [270.369, -189.556],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.695, -38.61],
                                  [-19.848, -117.714],
                                  [48.619, -47.214],
                                  [-117.706, -36.134],
                                  [-17.163, -62.627],
                                  [-127.398, 9.03],
                                  [116.593, 77.263],
                                  [-38.451, 132.735],
                                  [-11.013, 92],
                                ],
                                o: [
                                  [-94.979, 40.953],
                                  [19.848, 117.714],
                                  [-48.619, 47.214],
                                  [117.706, 36.134],
                                  [19.339, 65.305],
                                  [110.326, -5.347],
                                  [-116.593, -77.263],
                                  [35.271, -135.58],
                                  [11.013, -92],
                                ],
                                v: [
                                  [-106.301, -371.196],
                                  [-187.582, -184.255],
                                  [-249.311, 12.322],
                                  [-242.615, 163.627],
                                  [-13.308, 304.683],
                                  [120.585, 376.318],
                                  [257.043, 229.623],
                                  [50.616, -107.288],
                                  [271.638, -189.116],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.549, -38.902],
                                  [-20.2, -118.112],
                                  [48.866, -46.97],
                                  [-118.148, -36.088],
                                  [-17.181, -62.831],
                                  [-127.063, 8.877],
                                  [117.189, 77.41],
                                  [-38.703, 133.241],
                                  [-10.947, 92],
                                ],
                                o: [
                                  [-95.088, 41.194],
                                  [20.2, 118.112],
                                  [-48.866, 46.97],
                                  [118.148, 36.088],
                                  [19.31, 65.451],
                                  [110.363, -5.275],
                                  [-117.189, -77.41],
                                  [35.592, -136.025],
                                  [10.947, -92],
                                ],
                                v: [
                                  [-106.824, -370.976],
                                  [-188.02, -183.942],
                                  [-248.494, 13.433],
                                  [-242.884, 163.964],
                                  [-12.09, 304.753],
                                  [119.303, 375.87],
                                  [256.661, 229.919],
                                  [50.13, -107.411],
                                  [272.846, -188.71],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.406, -39.187],
                                  [-20.544, -118.502],
                                  [49.109, -46.731],
                                  [-118.581, -36.042],
                                  [-17.199, -63.031],
                                  [-126.735, 8.727],
                                  [117.774, 77.554],
                                  [-38.949, 133.738],
                                  [-10.883, 92],
                                ],
                                o: [
                                  [-95.195, 41.429],
                                  [20.544, 118.502],
                                  [-49.109, 46.731],
                                  [118.581, 36.042],
                                  [19.281, 65.594],
                                  [110.398, -5.203],
                                  [-117.774, -77.554],
                                  [35.906, -136.46],
                                  [10.883, -92],
                                ],
                                v: [
                                  [-107.381, -370.773],
                                  [-188.412, -183.693],
                                  [-247.617, 14.59],
                                  [-243.147, 164.251],
                                  [-10.899, 304.805],
                                  [117.973, 375.49],
                                  [256.385, 230.259],
                                  [49.62, -107.45],
                                  [274.042, -188.296],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.266, -39.467],
                                  [-20.882, -118.885],
                                  [49.347, -46.496],
                                  [-119.005, -35.997],
                                  [-17.217, -63.227],
                                  [-126.413, 8.58],
                                  [118.347, 77.694],
                                  [-39.19, 134.224],
                                  [-10.82, 92],
                                ],
                                o: [
                                  [-95.3, 41.66],
                                  [20.882, 118.885],
                                  [-49.347, 46.496],
                                  [119.005, 35.997],
                                  [19.253, 65.734],
                                  [110.433, -5.133],
                                  [-118.347, -77.694],
                                  [36.214, -136.887],
                                  [10.82, -92],
                                ],
                                v: [
                                  [-107.948, -370.61],
                                  [-188.748, -183.456],
                                  [-246.785, 15.751],
                                  [-243.414, 164.537],
                                  [-9.717, 304.839],
                                  [116.689, 375.094],
                                  [256.12, 230.613],
                                  [49.074, -107.519],
                                  [275.176, -187.865],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.129, -39.742],
                                  [-21.213, -119.26],
                                  [49.58, -46.266],
                                  [-119.421, -35.954],
                                  [-17.234, -63.419],
                                  [-126.097, 8.436],
                                  [118.909, 77.833],
                                  [-39.427, 134.701],
                                  [-10.758, 92],
                                ],
                                o: [
                                  [-95.403, 41.887],
                                  [21.213, 119.26],
                                  [-49.58, 46.266],
                                  [119.421, 35.954],
                                  [19.226, 65.871],
                                  [110.468, -5.065],
                                  [-118.909, -77.833],
                                  [36.516, -137.306],
                                  [10.758, -92],
                                ],
                                v: [
                                  [-108.549, -370.476],
                                  [-189.046, -183.188],
                                  [-245.999, 16.967],
                                  [-243.684, 164.825],
                                  [-8.561, 304.866],
                                  [115.347, 374.704],
                                  [255.9, 230.95],
                                  [48.607, -107.557],
                                  [276.352, -187.418],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.995, -40.011],
                                  [-21.538, -119.627],
                                  [49.809, -46.041],
                                  [-119.829, -35.911],
                                  [-17.251, -63.608],
                                  [-125.788, 8.294],
                                  [119.459, 77.968],
                                  [-39.659, 135.169],
                                  [-10.698, 92],
                                ],
                                o: [
                                  [-95.504, 42.109],
                                  [21.538, 119.627],
                                  [-49.809, 46.041],
                                  [119.829, 35.911],
                                  [19.199, 66.005],
                                  [110.501, -4.997],
                                  [-119.459, -77.968],
                                  [36.812, -137.716],
                                  [10.698, -92],
                                ],
                                v: [
                                  [-109.142, -370.331],
                                  [-189.34, -182.93],
                                  [-245.206, 18.188],
                                  [-244.011, 165.069],
                                  [-7.421, 304.93],
                                  [114.051, 374.318],
                                  [255.744, 231.354],
                                  [48.114, -107.555],
                                  [277.474, -187.027],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.863, -40.274],
                                  [-21.856, -119.987],
                                  [50.033, -45.82],
                                  [-120.228, -35.869],
                                  [-17.267, -63.792],
                                  [-125.484, 8.156],
                                  [119.999, 78.101],
                                  [-39.887, 135.627],
                                  [-10.638, 92],
                                ],
                                o: [
                                  [-95.603, 42.326],
                                  [21.856, 119.987],
                                  [-50.033, 45.82],
                                  [120.229, 35.869],
                                  [19.173, 66.137],
                                  [110.534, -4.931],
                                  [-119.999, -78.101],
                                  [37.102, -138.119],
                                  [10.638, -92],
                                ],
                                v: [
                                  [-109.789, -370.219],
                                  [-189.586, -182.63],
                                  [-244.46, 19.412],
                                  [-244.279, 165.269],
                                  [-6.298, 304.989],
                                  [112.706, 373.981],
                                  [255.697, 231.698],
                                  [47.637, -107.533],
                                  [278.577, -186.621],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.734, -40.533],
                                  [-22.168, -120.34],
                                  [50.253, -45.604],
                                  [-120.62, -35.828],
                                  [-17.283, -63.973],
                                  [-125.187, 8.02],
                                  [120.528, 78.231],
                                  [-40.11, 136.076],
                                  [-10.58, 92],
                                ],
                                o: [
                                  [-95.7, 42.54],
                                  [22.168, 120.34],
                                  [-50.253, 45.604],
                                  [120.62, 35.828],
                                  [19.147, 66.266],
                                  [110.567, -4.867],
                                  [-120.528, -78.231],
                                  [37.386, -138.513],
                                  [10.58, -92],
                                ],
                                v: [
                                  [-110.419, -370.15],
                                  [-189.783, -182.393],
                                  [-243.708, 20.64],
                                  [-244.559, 165.459],
                                  [-5.254, 305.032],
                                  [111.345, 373.647],
                                  [255.661, 232.1],
                                  [47.184, -107.473],
                                  [279.624, -186.254],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.607, -40.786],
                                  [-22.474, -120.686],
                                  [50.468, -45.391],
                                  [-121.004, -35.787],
                                  [-17.299, -64.15],
                                  [-124.896, 7.887],
                                  [121.047, 78.358],
                                  [-40.328, 136.516],
                                  [-10.523, 92],
                                ],
                                o: [
                                  [-95.795, 42.749],
                                  [22.474, 120.686],
                                  [-50.468, 45.391],
                                  [121.004, 35.787],
                                  [19.121, 66.393],
                                  [110.598, -4.803],
                                  [-121.047, -78.358],
                                  [37.665, -138.899],
                                  [10.523, -92],
                                ],
                                v: [
                                  [-111.103, -370.07],
                                  [-189.985, -182.113],
                                  [-243.003, 21.915],
                                  [-244.79, 165.604],
                                  [-4.171, 305.062],
                                  [109.988, 373.309],
                                  [255.674, 232.497],
                                  [46.748, -107.394],
                                  [280.715, -185.882],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.483, -41.035],
                                  [-22.773, -121.025],
                                  [50.679, -45.183],
                                  [-121.381, -35.748],
                                  [-17.315, -64.324],
                                  [-124.61, 7.757],
                                  [121.555, 78.483],
                                  [-40.542, 136.948],
                                  [-10.467, 92],
                                ],
                                o: [
                                  [-95.888, 42.954],
                                  [22.773, 121.025],
                                  [-50.679, 45.184],
                                  [121.381, 35.748],
                                  [19.097, 66.517],
                                  [110.629, -4.741],
                                  [-121.555, -78.483],
                                  [37.938, -139.278],
                                  [10.467, -92],
                                ],
                                v: [
                                  [-111.78, -370.072],
                                  [-190.136, -181.796],
                                  [-242.348, 23.195],
                                  [-245.068, 165.755],
                                  [-3.157, 305.14],
                                  [108.625, 373.028],
                                  [255.752, 232.943],
                                  [46.337, -107.333],
                                  [281.741, -185.559],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.361, -41.278],
                                  [-23.067, -121.358],
                                  [50.886, -44.98],
                                  [-121.75, -35.709],
                                  [-17.33, -64.495],
                                  [-124.33, 7.629],
                                  [122.053, 78.606],
                                  [-40.752, 137.371],
                                  [-10.412, 92],
                                ],
                                o: [
                                  [-95.979, 43.154],
                                  [23.067, 121.358],
                                  [-50.886, 44.98],
                                  [121.75, 35.709],
                                  [19.072, 66.639],
                                  [110.66, -4.68],
                                  [-122.053, -78.605],
                                  [38.206, -139.649],
                                  [10.412, -92],
                                ],
                                v: [
                                  [-112.503, -370.074],
                                  [-190.228, -181.542],
                                  [-241.742, 24.486],
                                  [-245.358, 165.843],
                                  [-2.113, 305.215],
                                  [107.256, 372.743],
                                  [255.941, 233.349],
                                  [45.895, -107.191],
                                  [282.765, -185.232],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.242, -41.517],
                                  [-23.354, -121.683],
                                  [51.089, -44.78],
                                  [-122.111, -35.671],
                                  [-17.345, -64.661],
                                  [-124.056, 7.504],
                                  [122.542, 78.726],
                                  [-40.958, 137.785],
                                  [-10.359, 92],
                                ],
                                o: [
                                  [-96.069, 43.351],
                                  [23.354, 121.684],
                                  [-51.089, 44.78],
                                  [122.111, 35.671],
                                  [19.048, 66.758],
                                  [110.69, -4.621],
                                  [-122.542, -78.725],
                                  [38.468, -140.013],
                                  [10.359, -92],
                                ],
                                v: [
                                  [-113.22, -370.114],
                                  [-190.277, -181.251],
                                  [-241.132, 25.772],
                                  [-245.588, 165.882],
                                  [-1.137, 305.269],
                                  [105.881, 372.515],
                                  [256.134, 233.796],
                                  [45.468, -107.078],
                                  [283.825, -184.956],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.125, -41.75],
                                  [-23.636, -122.003],
                                  [51.288, -44.584],
                                  [-122.465, -35.634],
                                  [-17.359, -64.825],
                                  [-123.787, 7.381],
                                  [123.02, 78.843],
                                  [-41.16, 138.191],
                                  [-10.306, 92],
                                ],
                                o: [
                                  [-96.156, 43.544],
                                  [23.636, 122.003],
                                  [-51.288, 44.584],
                                  [122.465, 35.634],
                                  [19.025, 66.875],
                                  [110.719, -4.562],
                                  [-123.02, -78.843],
                                  [38.725, -140.369],
                                  [10.306, -92],
                                ],
                                v: [
                                  [-113.984, -370.202],
                                  [-190.321, -180.912],
                                  [-240.573, 27.062],
                                  [-245.884, 165.927],
                                  [-0.183, 305.374],
                                  [104.51, 372.236],
                                  [256.386, 234.303],
                                  [45.064, -106.939],
                                  [284.883, -184.722],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.01, -41.979],
                                  [-23.912, -122.315],
                                  [51.482, -44.392],
                                  [-122.812, -35.597],
                                  [-17.374, -64.985],
                                  [-123.524, 7.261],
                                  [123.489, 78.958],
                                  [-41.357, 138.589],
                                  [-10.255, 92],
                                ],
                                o: [
                                  [-96.242, 43.733],
                                  [23.912, 122.315],
                                  [-51.482, 44.392],
                                  [122.812, 35.597],
                                  [19.002, 66.99],
                                  [110.747, -4.505],
                                  [-123.489, -78.958],
                                  [38.977, -140.718],
                                  [10.255, -92],
                                ],
                                v: [
                                  [-114.796, -370.329],
                                  [-190.313, -180.581],
                                  [-240.073, 28.354],
                                  [-246.128, 165.961],
                                  [0.758, 305.476],
                                  [103.133, 372.069],
                                  [256.697, 234.869],
                                  [44.676, -106.714],
                                  [285.922, -184.54],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.898, -42.204],
                                  [-24.183, -122.622],
                                  [51.673, -44.204],
                                  [-123.152, -35.561],
                                  [-17.388, -65.143],
                                  [-123.266, 7.143],
                                  [123.948, 79.071],
                                  [-41.551, 138.979],
                                  [-10.204, 92],
                                ],
                                o: [
                                  [-96.326, 43.918],
                                  [24.183, 122.622],
                                  [-51.673, 44.204],
                                  [123.152, 35.561],
                                  [18.98, 67.102],
                                  [110.775, -4.449],
                                  [-123.948, -79.071],
                                  [39.224, -141.061],
                                  [10.204, -92],
                                ],
                                v: [
                                  [-115.61, -370.451],
                                  [-190.245, -180.257],
                                  [-239.616, 29.705],
                                  [-246.366, 165.946],
                                  [1.63, 305.567],
                                  [101.805, 371.85],
                                  [257.012, 235.434],
                                  [44.254, -106.519],
                                  [286.96, -184.355],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.788, -42.423],
                                  [-24.448, -122.922],
                                  [51.86, -44.02],
                                  [-123.486, -35.526],
                                  [-17.401, -65.296],
                                  [-123.013, 7.028],
                                  [124.398, 79.182],
                                  [-41.74, 139.361],
                                  [-10.155, 92],
                                ],
                                o: [
                                  [-96.409, 44.099],
                                  [24.448, 122.922],
                                  [-51.86, 44.02],
                                  [123.486, 35.526],
                                  [18.958, 67.212],
                                  [110.803, -4.394],
                                  [-124.398, -79.182],
                                  [39.466, -141.396],
                                  [10.155, -92],
                                ],
                                v: [
                                  [-116.465, -370.669],
                                  [-190.186, -179.899],
                                  [-239.212, 31.003],
                                  [-246.615, 165.881],
                                  [2.427, 305.594],
                                  [100.425, 371.681],
                                  [257.379, 236.107],
                                  [43.855, -106.356],
                                  [288.044, -184.223],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.681, -42.639],
                                  [-24.708, -123.216],
                                  [52.043, -43.84],
                                  [-123.812, -35.492],
                                  [-17.415, -65.447],
                                  [-122.765, 6.915],
                                  [124.839, 79.29],
                                  [-41.926, 139.735],
                                  [-10.106, 92],
                                ],
                                o: [
                                  [-96.49, 44.277],
                                  [24.708, 123.216],
                                  [-52.043, 43.84],
                                  [123.812, 35.492],
                                  [18.936, 67.319],
                                  [110.83, -4.34],
                                  [-124.839, -79.29],
                                  [39.703, -141.724],
                                  [10.106, -92],
                                ],
                                v: [
                                  [-117.322, -370.945],
                                  [-190.067, -179.54],
                                  [-238.861, 32.304],
                                  [-246.81, 165.804],
                                  [3.155, 305.617],
                                  [99.094, 371.514],
                                  [257.759, 236.786],
                                  [43.469, -106.109],
                                  [289.07, -184.137],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.575, -42.85],
                                  [-24.963, -123.504],
                                  [52.222, -43.663],
                                  [-124.132, -35.458],
                                  [-17.428, -65.595],
                                  [-122.523, 6.804],
                                  [125.271, 79.396],
                                  [-42.108, 140.102],
                                  [-10.059, 92],
                                ],
                                o: [
                                  [-96.569, 44.451],
                                  [24.963, 123.504],
                                  [-52.222, 43.663],
                                  [124.132, 35.458],
                                  [18.915, 67.425],
                                  [110.856, -4.288],
                                  [-125.271, -79.396],
                                  [39.935, -142.046],
                                  [10.059, -92],
                                ],
                                v: [
                                  [-118.222, -371.264],
                                  [-189.901, -179.194],
                                  [-238.508, 33.6],
                                  [-247.056, 165.731],
                                  [3.808, 305.576],
                                  [97.766, 371.405],
                                  [258.253, 237.526],
                                  [43.104, -105.84],
                                  [290.135, -184.112],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.472, -43.057],
                                  [-25.212, -123.787],
                                  [52.398, -43.49],
                                  [-124.445, -35.425],
                                  [-17.441, -65.74],
                                  [-122.285, 6.695],
                                  [125.694, 79.5],
                                  [-42.286, 140.461],
                                  [-10.012, 92],
                                ],
                                o: [
                                  [-96.646, 44.622],
                                  [25.212, 123.787],
                                  [-52.398, 43.49],
                                  [124.445, 35.425],
                                  [18.894, 67.528],
                                  [110.882, -4.236],
                                  [-125.694, -79.5],
                                  [40.162, -142.361],
                                  [10.012, -92],
                                ],
                                v: [
                                  [-119.123, -371.573],
                                  [-189.673, -178.804],
                                  [-238.161, 34.906],
                                  [-247.256, 165.607],
                                  [4.399, 305.477],
                                  [96.433, 371.298],
                                  [258.691, 238.315],
                                  [42.76, -105.606],
                                  [291.19, -184.078],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.37, -43.259],
                                  [-25.456, -124.063],
                                  [52.57, -43.32],
                                  [-124.752, -35.393],
                                  [-17.454, -65.881],
                                  [-122.052, 6.589],
                                  [126.109, 79.602],
                                  [-42.461, 140.813],
                                  [-9.967, 92],
                                ],
                                o: [
                                  [-96.722, 44.789],
                                  [25.456, 124.063],
                                  [-52.57, 43.32],
                                  [124.752, 35.393],
                                  [18.874, 67.63],
                                  [110.907, -4.185],
                                  [-126.108, -79.602],
                                  [40.385, -142.67],
                                  [9.967, -92],
                                ],
                                v: [
                                  [-120.075, -371.94],
                                  [-189.447, -178.42],
                                  [-237.86, 36.158],
                                  [-247.464, 165.472],
                                  [4.859, 305.369],
                                  [95.102, 371.247],
                                  [259.189, 239.111],
                                  [42.373, -105.289],
                                  [292.188, -184.049],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.271, -43.458],
                                  [-25.696, -124.334],
                                  [52.739, -43.154],
                                  [-125.053, -35.361],
                                  [-17.466, -66.02],
                                  [-121.824, 6.485],
                                  [126.515, 79.702],
                                  [-42.632, 141.157],
                                  [-9.922, 92],
                                ],
                                o: [
                                  [-96.797, 44.952],
                                  [25.696, 124.334],
                                  [-52.739, 43.154],
                                  [125.053, 35.361],
                                  [18.854, 67.729],
                                  [110.932, -4.136],
                                  [-126.515, -79.702],
                                  [40.603, -142.973],
                                  [9.922, -92],
                                ],
                                v: [
                                  [-121.015, -372.297],
                                  [-189.166, -178.046],
                                  [-237.621, 37.412],
                                  [-247.667, 165.284],
                                  [5.305, 305.202],
                                  [93.822, 371.199],
                                  [259.749, 239.915],
                                  [42.007, -105.008],
                                  [293.233, -184.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.174, -43.652],
                                  [-25.93, -124.6],
                                  [52.904, -42.992],
                                  [-125.347, -35.33],
                                  [-17.478, -66.156],
                                  [-121.6, 6.383],
                                  [126.912, 79.8],
                                  [-42.8, 141.495],
                                  [-9.878, 92],
                                ],
                                o: [
                                  [-96.869, 45.113],
                                  [25.93, 124.6],
                                  [-52.904, 42.992],
                                  [125.347, 35.331],
                                  [18.835, 67.826],
                                  [110.956, -4.087],
                                  [-126.912, -79.8],
                                  [40.817, -143.269],
                                  [9.878, -92],
                                ],
                                v: [
                                  [-121.956, -372.651],
                                  [-188.878, -177.621],
                                  [-237.324, 38.611],
                                  [-247.871, 165.098],
                                  [5.683, 305.088],
                                  [92.536, 371.151],
                                  [260.257, 240.83],
                                  [41.659, -104.701],
                                  [294.268, -184.143],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.079, -43.842],
                                  [-26.16, -124.859],
                                  [53.066, -42.832],
                                  [-125.636, -35.3],
                                  [-17.49, -66.289],
                                  [-121.382, 6.283],
                                  [127.302, 79.896],
                                  [-42.964, 141.826],
                                  [-9.836, 92],
                                ],
                                o: [
                                  [-96.941, 45.27],
                                  [26.16, 124.859],
                                  [-53.066, 42.832],
                                  [125.636, 35.3],
                                  [18.816, 67.921],
                                  [110.98, -4.039],
                                  [-127.302, -79.896],
                                  [41.026, -143.559],
                                  [9.835, -92],
                                ],
                                v: [
                                  [-122.893, -372.995],
                                  [-188.542, -177.206],
                                  [-237.138, 39.756],
                                  [-248.084, 164.902],
                                  [5.985, 304.854],
                                  [91.253, 371.161],
                                  [260.877, 241.691],
                                  [41.275, -104.376],
                                  [295.351, -184.279],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.986, -44.029],
                                  [-26.385, -125.114],
                                  [53.224, -42.676],
                                  [-125.918, -35.27],
                                  [-17.502, -66.42],
                                  [-121.167, 6.185],
                                  [127.684, 79.989],
                                  [-43.125, 142.15],
                                  [-9.794, 92],
                                ],
                                o: [
                                  [-97.011, 45.424],
                                  [26.385, 125.114],
                                  [-53.224, 42.676],
                                  [125.918, 35.27],
                                  [18.797, 68.014],
                                  [111.004, -3.993],
                                  [-127.684, -79.989],
                                  [41.232, -143.844],
                                  [9.794, -92],
                                ],
                                v: [
                                  [-123.819, -373.28],
                                  [-188.143, -176.744],
                                  [-236.901, 40.907],
                                  [-248.241, 164.652],
                                  [6.168, 304.673],
                                  [89.972, 371.172],
                                  [261.447, 242.615],
                                  [40.964, -104.026],
                                  [296.37, -184.409],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.894, -44.211],
                                  [-26.605, -125.363],
                                  [53.38, -42.523],
                                  [-126.195, -35.241],
                                  [-17.513, -66.548],
                                  [-120.957, 6.089],
                                  [128.057, 80.081],
                                  [-43.282, 142.467],
                                  [-9.753, 92],
                                ],
                                o: [
                                  [-97.079, 45.574],
                                  [26.605, 125.363],
                                  [-53.38, 42.523],
                                  [126.195, 35.241],
                                  [18.779, 68.106],
                                  [111.026, -3.947],
                                  [-128.057, -80.081],
                                  [41.432, -144.122],
                                  [9.753, -92],
                                ],
                                v: [
                                  [-124.797, -373.619],
                                  [-187.745, -176.341],
                                  [-236.72, 42.053],
                                  [-248.399, 164.398],
                                  [6.331, 304.434],
                                  [88.742, 371.239],
                                  [262.078, 243.54],
                                  [40.622, -103.66],
                                  [297.38, -184.537],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.805, -44.39],
                                  [-26.82, -125.608],
                                  [53.531, -42.373],
                                  [-126.466, -35.213],
                                  [-17.524, -66.673],
                                  [-120.751, 5.995],
                                  [128.423, 80.171],
                                  [-43.436, 142.778],
                                  [-9.712, 92],
                                ],
                                o: [
                                  [-97.146, 45.722],
                                  [26.82, 125.608],
                                  [-53.531, 42.373],
                                  [126.466, 35.213],
                                  [18.761, 68.195],
                                  [111.049, -3.903],
                                  [-128.423, -80.171],
                                  [41.629, -144.395],
                                  [9.712, -92],
                                ],
                                v: [
                                  [-125.721, -373.898],
                                  [-187.29, -175.891],
                                  [-236.588, 43.086],
                                  [-248.559, 164.139],
                                  [6.425, 304.129],
                                  [87.57, 371.301],
                                  [262.715, 244.53],
                                  [40.24, -103.333],
                                  [298.381, -184.728],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.717, -44.565],
                                  [-27.032, -125.847],
                                  [53.68, -42.227],
                                  [-126.731, -35.185],
                                  [-17.535, -66.796],
                                  [-120.55, 5.903],
                                  [128.782, 80.259],
                                  [-43.587, 143.082],
                                  [-9.673, 92],
                                ],
                                o: [
                                  [-97.212, 45.866],
                                  [27.032, 125.847],
                                  [-53.68, 42.227],
                                  [126.731, 35.185],
                                  [18.743, 68.283],
                                  [111.071, -3.859],
                                  [-128.782, -80.259],
                                  [41.822, -144.662],
                                  [9.673, -92],
                                ],
                                v: [
                                  [-126.633, -374.23],
                                  [-186.834, -175.392],
                                  [-236.467, 44.125],
                                  [-248.662, 163.883],
                                  [6.444, 303.879],
                                  [86.338, 371.364],
                                  [263.409, 245.521],
                                  [39.931, -102.985],
                                  [299.431, -184.969],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.632, -44.737],
                                  [-27.238, -126.081],
                                  [53.826, -42.083],
                                  [-126.991, -35.158],
                                  [-17.546, -66.916],
                                  [-120.353, 5.813],
                                  [129.133, 80.346],
                                  [-43.735, 143.38],
                                  [-9.634, 92],
                                ],
                                o: [
                                  [-97.276, 46.008],
                                  [27.238, 126.081],
                                  [-53.826, 42.083],
                                  [126.991, 35.158],
                                  [18.726, 68.368],
                                  [111.092, -3.816],
                                  [-129.133, -80.346],
                                  [42.01, -144.923],
                                  [9.634, -92],
                                ],
                                v: [
                                  [-127.542, -374.497],
                                  [-186.322, -174.901],
                                  [-236.339, 45.157],
                                  [-248.817, 163.565],
                                  [6.455, 303.57],
                                  [85.22, 371.484],
                                  [264.052, 246.52],
                                  [39.582, -102.564],
                                  [300.417, -185.216],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.548, -44.904],
                                  [-27.441, -126.31],
                                  [53.969, -41.943],
                                  [-127.246, -35.131],
                                  [-17.557, -67.033],
                                  [-120.16, 5.725],
                                  [129.476, 80.43],
                                  [-43.88, 143.671],
                                  [-9.596, 92],
                                ],
                                o: [
                                  [-97.339, 46.146],
                                  [27.441, 126.31],
                                  [-53.969, 41.943],
                                  [127.246, 35.131],
                                  [18.71, 68.452],
                                  [111.113, -3.774],
                                  [-129.476, -80.43],
                                  [42.195, -145.179],
                                  [9.596, -92],
                                ],
                                v: [
                                  [-128.44, -374.761],
                                  [-185.809, -174.411],
                                  [-236.268, 46.138],
                                  [-248.911, 163.237],
                                  [6.335, 303.253],
                                  [84.042, 371.599],
                                  [264.757, 247.521],
                                  [39.249, -102.179],
                                  [301.457, -185.462],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.466, -45.069],
                                  [-27.639, -126.535],
                                  [54.109, -41.805],
                                  [-127.495, -35.105],
                                  [-17.567, -67.148],
                                  [-119.971, 5.639],
                                  [129.813, 80.513],
                                  [-44.022, 143.957],
                                  [-9.56, 92],
                                ],
                                o: [
                                  [-97.401, 46.282],
                                  [27.639, 126.535],
                                  [-54.109, 41.805],
                                  [127.495, 35.105],
                                  [18.693, 68.534],
                                  [111.134, -3.733],
                                  [-129.813, -80.513],
                                  [42.376, -145.43],
                                  [9.559, -92],
                                ],
                                v: [
                                  [-129.34, -375.023],
                                  [-185.291, -173.928],
                                  [-236.202, 47.061],
                                  [-249.006, 162.911],
                                  [6.203, 302.934],
                                  [82.916, 371.765],
                                  [265.462, 248.524],
                                  [38.936, -101.837],
                                  [302.427, -185.765],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.385, -45.23],
                                  [-27.833, -126.754],
                                  [54.245, -41.67],
                                  [-127.739, -35.079],
                                  [-17.577, -67.261],
                                  [-119.786, 5.554],
                                  [130.142, 80.594],
                                  [-44.161, 144.237],
                                  [-9.523, 92],
                                ],
                                o: [
                                  [-97.461, 46.415],
                                  [27.833, 126.754],
                                  [-54.245, 41.67],
                                  [127.739, 35.079],
                                  [18.677, 68.615],
                                  [111.154, -3.693],
                                  [-130.142, -80.594],
                                  [42.553, -145.675],
                                  [9.523, -92],
                                ],
                                v: [
                                  [-130.173, -375.276],
                                  [-184.658, -173.442],
                                  [-236.192, 47.983],
                                  [-249.091, 162.575],
                                  [5.995, 302.613],
                                  [81.85, 371.932],
                                  [266.173, 249.534],
                                  [38.639, -101.423],
                                  [303.446, -186.125],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.306, -45.387],
                                  [-28.024, -126.97],
                                  [54.379, -41.538],
                                  [-127.978, -35.054],
                                  [-17.587, -67.371],
                                  [-119.604, 5.472],
                                  [130.465, 80.673],
                                  [-44.297, 144.511],
                                  [-9.488, 92],
                                ],
                                o: [
                                  [-97.52, 46.545],
                                  [28.024, 126.97],
                                  [-54.379, 41.538],
                                  [127.978, 35.054],
                                  [18.661, 68.694],
                                  [111.173, -3.653],
                                  [-130.465, -80.673],
                                  [42.726, -145.916],
                                  [9.488, -92],
                                ],
                                v: [
                                  [-131.064, -375.532],
                                  [-184.086, -172.904],
                                  [-236.182, 48.789],
                                  [-249.116, 162.236],
                                  [5.724, 302.232],
                                  [80.78, 372.1],
                                  [266.89, 250.609],
                                  [38.298, -100.991],
                                  [304.463, -186.485],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.229, -45.542],
                                  [-28.21, -127.18],
                                  [54.51, -41.409],
                                  [-128.212, -35.029],
                                  [-17.596, -67.479],
                                  [-119.427, 5.391],
                                  [130.781, 80.751],
                                  [-44.43, 144.779],
                                  [-9.453, 92],
                                ],
                                o: [
                                  [-97.578, 46.672],
                                  [28.21, 127.18],
                                  [-54.51, 41.409],
                                  [128.212, 35.029],
                                  [18.646, 68.771],
                                  [111.193, -3.615],
                                  [-130.781, -80.751],
                                  [42.896, -146.151],
                                  [9.453, -92],
                                ],
                                v: [
                                  [-131.889, -375.781],
                                  [-183.452, -172.373],
                                  [-236.177, 49.651],
                                  [-249.142, 161.84],
                                  [5.377, 301.907],
                                  [79.769, 372.32],
                                  [267.665, 251.628],
                                  [38.029, -100.598],
                                  [305.41, -186.85],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.154, -45.693],
                                  [-28.392, -127.387],
                                  [54.639, -41.282],
                                  [-128.44, -35.005],
                                  [-17.606, -67.585],
                                  [-119.253, 5.311],
                                  [131.09, 80.827],
                                  [-44.56, 145.041],
                                  [-9.419, 92],
                                ],
                                o: [
                                  [-97.635, 46.796],
                                  [28.392, 127.387],
                                  [-54.639, 41.282],
                                  [128.44, 35.005],
                                  [18.631, 68.846],
                                  [111.212, -3.577],
                                  [-131.09, -80.827],
                                  [43.062, -146.381],
                                  [9.419, -92],
                                ],
                                v: [
                                  [-132.71, -376.031],
                                  [-182.816, -171.838],
                                  [-236.224, 50.396],
                                  [-249.164, 161.493],
                                  [5.019, 301.522],
                                  [78.754, 372.535],
                                  [268.388, 252.655],
                                  [37.722, -100.192],
                                  [306.408, -187.273],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.08, -45.841],
                                  [-28.57, -127.588],
                                  [54.764, -41.159],
                                  [-128.665, -34.982],
                                  [-17.615, -67.688],
                                  [-119.083, 5.234],
                                  [131.392, 80.901],
                                  [-44.688, 145.298],
                                  [-9.386, 92],
                                ],
                                o: [
                                  [-97.69, 46.918],
                                  [28.57, 127.588],
                                  [-54.764, 41.159],
                                  [128.665, 34.982],
                                  [18.616, 68.92],
                                  [111.23, -3.54],
                                  [-131.392, -80.901],
                                  [43.225, -146.607],
                                  [9.386, -92],
                                ],
                                v: [
                                  [-133.464, -376.275],
                                  [-182.122, -171.303],
                                  [-236.275, 51.139],
                                  [-249.188, 161.085],
                                  [4.587, 301.188],
                                  [77.795, 372.804],
                                  [269.17, 253.683],
                                  [37.37, -99.774],
                                  [307.345, -187.695],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.007, -45.985],
                                  [-28.745, -127.786],
                                  [54.887, -41.037],
                                  [-128.884, -34.959],
                                  [-17.624, -67.79],
                                  [-118.917, 5.158],
                                  [131.689, 80.974],
                                  [-44.812, 145.549],
                                  [-9.353, 92],
                                ],
                                o: [
                                  [-97.744, 47.038],
                                  [28.745, 127.786],
                                  [-54.887, 41.037],
                                  [128.884, 34.959],
                                  [18.601, 68.993],
                                  [111.248, -3.504],
                                  [-131.689, -80.974],
                                  [43.384, -146.828],
                                  [9.353, -92],
                                ],
                                v: [
                                  [-134.277, -376.458],
                                  [-181.432, -170.717],
                                  [-236.384, 51.828],
                                  [-249.209, 160.732],
                                  [4.148, 300.794],
                                  [76.895, 373.073],
                                  [269.904, 254.719],
                                  [37.088, -99.34],
                                  [308.329, -188.174],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.937, -46.127],
                                  [-28.916, -127.98],
                                  [55.008, -40.919],
                                  [-129.099, -34.936],
                                  [-17.633, -67.889],
                                  [-118.754, 5.083],
                                  [131.978, 81.045],
                                  [-44.934, 145.795],
                                  [-9.321, 92],
                                ],
                                o: [
                                  [-97.798, 47.155],
                                  [28.916, 127.98],
                                  [-55.008, 40.919],
                                  [129.099, 34.936],
                                  [18.587, 69.063],
                                  [111.266, -3.468],
                                  [-131.978, -81.045],
                                  [43.54, -147.044],
                                  [9.321, -92],
                                ],
                                v: [
                                  [-135.023, -376.701],
                                  [-180.737, -170.189],
                                  [-236.493, 52.451],
                                  [-249.169, 160.312],
                                  [3.578, 300.403],
                                  [75.992, 373.338],
                                  [270.693, 255.756],
                                  [36.761, -98.89],
                                  [309.305, -188.658],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.867, -46.266],
                                  [-29.083, -128.169],
                                  [55.126, -40.803],
                                  [-129.309, -34.914],
                                  [-17.642, -67.986],
                                  [-118.595, 5.011],
                                  [132.262, 81.115],
                                  [-45.054, 146.036],
                                  [-9.29, 92],
                                ],
                                o: [
                                  [-97.85, 47.269],
                                  [29.083, 128.169],
                                  [-55.126, 40.803],
                                  [129.309, 34.914],
                                  [18.573, 69.133],
                                  [111.283, -3.434],
                                  [-132.262, -81.115],
                                  [43.692, -147.255],
                                  [9.29, -92],
                                ],
                                v: [
                                  [-135.707, -376.878],
                                  [-180.04, -169.604],
                                  [-236.661, 53.076],
                                  [-249.188, 159.952],
                                  [3.054, 300.005],
                                  [75.149, 373.657],
                                  [271.429, 256.801],
                                  [36.508, -98.486],
                                  [310.217, -189.2],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.799, -46.401],
                                  [-29.246, -128.354],
                                  [55.241, -40.689],
                                  [-129.514, -34.892],
                                  [-17.65, -68.081],
                                  [-118.439, 4.939],
                                  [132.54, 81.183],
                                  [-45.171, 146.272],
                                  [-9.26, 92],
                                ],
                                o: [
                                  [-97.9, 47.381],
                                  [29.246, 128.354],
                                  [-55.241, 40.689],
                                  [129.514, 34.892],
                                  [18.56, 69.201],
                                  [111.3, -3.4],
                                  [-132.54, -81.183],
                                  [43.841, -147.462],
                                  [9.26, -92],
                                ],
                                v: [
                                  [-136.446, -377.053],
                                  [-179.289, -169.019],
                                  [-236.774, 53.641],
                                  [-249.089, 159.526],
                                  [2.403, 299.668],
                                  [74.362, 373.976],
                                  [272.171, 257.793],
                                  [36.205, -98.072],
                                  [311.181, -189.747],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.733, -46.534],
                                  [-29.407, -128.535],
                                  [55.354, -40.578],
                                  [-129.716, -34.871],
                                  [-17.658, -68.174],
                                  [-118.286, 4.87],
                                  [132.812, 81.25],
                                  [-45.286, 146.503],
                                  [-9.23, 92],
                                ],
                                o: [
                                  [-97.95, 47.491],
                                  [29.407, 128.536],
                                  [-55.354, 40.578],
                                  [129.716, 34.871],
                                  [18.547, 69.267],
                                  [111.317, -3.366],
                                  [-132.812, -81.25],
                                  [43.987, -147.664],
                                  [9.23, -92],
                                ],
                                v: [
                                  [-137.064, -377.23],
                                  [-178.536, -168.436],
                                  [-237.004, 54.145],
                                  [-249.05, 159.156],
                                  [1.795, 299.267],
                                  [73.572, 374.287],
                                  [272.971, 258.787],
                                  [35.864, -97.644],
                                  [312.138, -190.346],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.668, -46.664],
                                  [-29.563, -128.713],
                                  [55.464, -40.469],
                                  [-129.913, -34.85],
                                  [-17.667, -68.265],
                                  [-118.136, 4.801],
                                  [133.078, 81.315],
                                  [-45.398, 146.729],
                                  [-9.201, 92],
                                ],
                                o: [
                                  [-97.999, 47.598],
                                  [29.563, 128.713],
                                  [-55.464, 40.469],
                                  [129.913, 34.85],
                                  [18.534, 69.332],
                                  [111.333, -3.334],
                                  [-133.078, -81.315],
                                  [44.13, -147.863],
                                  [9.201, -92],
                                ],
                                v: [
                                  [-137.732, -377.4],
                                  [-177.782, -167.853],
                                  [-237.176, 54.645],
                                  [-249.013, 158.783],
                                  [1.064, 298.869],
                                  [72.843, 374.657],
                                  [273.72, 259.788],
                                  [35.588, -97.202],
                                  [313.03, -190.955],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.604, -46.791],
                                  [-29.717, -128.887],
                                  [55.572, -40.362],
                                  [-130.105, -34.83],
                                  [-17.674, -68.354],
                                  [-117.99, 4.735],
                                  [133.338, 81.379],
                                  [-45.507, 146.949],
                                  [-9.172, 92],
                                ],
                                o: [
                                  [-98.047, 47.703],
                                  [29.717, 128.887],
                                  [-55.572, 40.362],
                                  [130.105, 34.83],
                                  [18.521, 69.396],
                                  [111.349, -3.302],
                                  [-133.338, -81.379],
                                  [44.27, -148.056],
                                  [9.172, -92],
                                ],
                                v: [
                                  [-138.337, -377.567],
                                  [-177.027, -167.274],
                                  [-237.406, 55.084],
                                  [-248.914, 158.403],
                                  [0.377, 298.464],
                                  [72.17, 375.023],
                                  [274.469, 260.794],
                                  [35.323, -96.75],
                                  [313.974, -191.617],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.542, -46.916],
                                  [-29.867, -129.056],
                                  [55.678, -40.258],
                                  [-130.294, -34.81],
                                  [-17.682, -68.441],
                                  [-117.847, 4.669],
                                  [133.593, 81.442],
                                  [-45.615, 147.166],
                                  [-9.144, 92],
                                ],
                                o: [
                                  [-98.093, 47.805],
                                  [29.867, 129.056],
                                  [-55.678, 40.258],
                                  [130.294, 34.81],
                                  [18.508, 69.458],
                                  [111.364, -3.271],
                                  [-133.593, -81.442],
                                  [44.407, -148.246],
                                  [9.144, -92],
                                ],
                                v: [
                                  [-138.939, -377.736],
                                  [-176.271, -166.697],
                                  [-237.695, 55.525],
                                  [-248.818, 158.025],
                                  [-0.437, 298.12],
                                  [71.495, 375.444],
                                  [275.282, 261.743],
                                  [35.014, -96.348],
                                  [314.909, -192.282],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.481, -47.037],
                                  [-30.014, -129.223],
                                  [55.782, -40.156],
                                  [-130.478, -34.791],
                                  [-17.69, -68.526],
                                  [-117.707, 4.605],
                                  [133.842, 81.503],
                                  [-45.72, 147.377],
                                  [-9.117, 92],
                                ],
                                o: [
                                  [-98.139, 47.906],
                                  [30.014, 129.223],
                                  [-55.782, 40.156],
                                  [130.478, 34.791],
                                  [18.496, 69.519],
                                  [111.38, -3.241],
                                  [-133.842, -81.503],
                                  [44.541, -148.432],
                                  [9.117, -92],
                                ],
                                v: [
                                  [-139.479, -377.902],
                                  [-175.517, -166.115],
                                  [-237.929, 55.899],
                                  [-248.722, 157.641],
                                  [-1.206, 297.715],
                                  [70.94, 375.862],
                                  [276.042, 262.698],
                                  [34.715, -95.933],
                                  [315.782, -192.952],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.422, -47.156],
                                  [-30.157, -129.385],
                                  [55.883, -40.057],
                                  [-130.659, -34.772],
                                  [-17.697, -68.609],
                                  [-117.57, 4.543],
                                  [134.086, 81.563],
                                  [-45.822, 147.584],
                                  [-9.09, 92],
                                ],
                                o: [
                                  [-98.184, 48.004],
                                  [30.157, 129.385],
                                  [-55.883, 40.057],
                                  [130.659, 34.772],
                                  [18.484, 69.578],
                                  [111.395, -3.211],
                                  [-134.086, -81.563],
                                  [44.672, -148.613],
                                  [9.09, -92],
                                ],
                                v: [
                                  [-139.956, -378.006],
                                  [-174.762, -165.539],
                                  [-238.219, 56.215],
                                  [-248.625, 157.309],
                                  [-2.04, 297.309],
                                  [70.383, 376.274],
                                  [276.804, 263.599],
                                  [34.429, -95.506],
                                  [316.705, -193.738],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.364, -47.273],
                                  [-30.298, -129.545],
                                  [55.982, -39.959],
                                  [-130.835, -34.753],
                                  [-17.705, -68.691],
                                  [-117.436, 4.482],
                                  [134.324, 81.622],
                                  [-45.923, 147.787],
                                  [-9.064, 92],
                                ],
                                o: [
                                  [-98.227, 48.1],
                                  [30.298, 129.545],
                                  [-55.982, 39.959],
                                  [130.835, 34.753],
                                  [18.473, 69.636],
                                  [111.409, -3.182],
                                  [-134.324, -81.622],
                                  [44.8, -148.791],
                                  [9.064, -92],
                                ],
                                v: [
                                  [-140.483, -378.167],
                                  [-173.946, -164.963],
                                  [-238.571, 56.524],
                                  [-248.533, 156.921],
                                  [-2.946, 296.96],
                                  [69.883, 376.686],
                                  [277.508, 264.505],
                                  [34.153, -95.071],
                                  [317.625, -194.465],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.307, -47.387],
                                  [-30.435, -129.7],
                                  [56.079, -39.864],
                                  [-131.008, -34.735],
                                  [-17.712, -68.771],
                                  [-117.305, 4.422],
                                  [134.557, 81.679],
                                  [-46.021, 147.985],
                                  [-9.038, 92],
                                ],
                                o: [
                                  [-98.27, 48.194],
                                  [30.435, 129.7],
                                  [-56.079, 39.863],
                                  [131.008, 34.735],
                                  [18.461, 69.693],
                                  [111.423, -3.153],
                                  [-134.558, -81.679],
                                  [44.926, -148.965],
                                  [9.038, -92],
                                ],
                                v: [
                                  [-140.89, -378.266],
                                  [-173.192, -164.328],
                                  [-238.865, 56.775],
                                  [-248.38, 156.644],
                                  [-3.802, 296.554],
                                  [69.441, 377.154],
                                  [278.275, 265.416],
                                  [33.887, -94.628],
                                  [318.477, -195.255],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.251, -47.498],
                                  [-30.57, -129.853],
                                  [56.174, -39.77],
                                  [-131.177, -34.717],
                                  [-17.719, -68.849],
                                  [-117.177, 4.363],
                                  [134.786, 81.735],
                                  [-46.117, 148.179],
                                  [-9.013, 92],
                                ],
                                o: [
                                  [-98.312, 48.286],
                                  [30.57, 129.853],
                                  [-56.174, 39.77],
                                  [131.177, 34.717],
                                  [18.45, 69.749],
                                  [111.437, -3.125],
                                  [-134.786, -81.735],
                                  [45.048, -149.135],
                                  [9.013, -92],
                                ],
                                v: [
                                  [-141.292, -378.362],
                                  [-172.433, -163.812],
                                  [-239.218, 57.021],
                                  [-248.228, 156.308],
                                  [-4.727, 296.204],
                                  [69.002, 377.619],
                                  [279.048, 266.269],
                                  [33.57, -94.233],
                                  [319.383, -196.102],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.196, -47.607],
                                  [-30.702, -130.002],
                                  [56.266, -39.679],
                                  [-131.343, -34.7],
                                  [-17.725, -68.925],
                                  [-117.051, 4.306],
                                  [135.009, 81.79],
                                  [-46.211, 148.368],
                                  [-8.988, 92],
                                ],
                                o: [
                                  [-98.353, 48.376],
                                  [30.702, 130.001],
                                  [-56.266, 39.679],
                                  [131.343, 34.7],
                                  [18.439, 69.804],
                                  [111.451, -3.098],
                                  [-135.009, -81.79],
                                  [45.168, -149.301],
                                  [8.988, -92],
                                ],
                                v: [
                                  [-141.69, -378.459],
                                  [-171.676, -163.241],
                                  [-239.571, 57.206],
                                  [-248.137, 156.025],
                                  [-5.723, 295.795],
                                  [68.68, 378.138],
                                  [279.763, 267.068],
                                  [33.325, -93.831],
                                  [320.221, -197.012],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.143, -47.714],
                                  [-30.83, -130.147],
                                  [56.357, -39.589],
                                  [-131.504, -34.683],
                                  [-17.732, -69],
                                  [-116.929, 4.25],
                                  [135.227, 81.844],
                                  [-46.303, 148.553],
                                  [-8.964, 92],
                                ],
                                o: [
                                  [-98.393, 48.464],
                                  [30.83, 130.147],
                                  [-56.357, 39.589],
                                  [131.504, 34.683],
                                  [18.429, 69.857],
                                  [111.464, -3.071],
                                  [-135.228, -81.844],
                                  [45.286, -149.464],
                                  [8.964, -92],
                                ],
                                v: [
                                  [-142.022, -378.553],
                                  [-170.921, -162.667],
                                  [-239.984, 57.386],
                                  [-247.991, 155.741],
                                  [-6.665, 295.442],
                                  [68.361, 378.598],
                                  [280.48, 267.93],
                                  [33.029, -93.418],
                                  [321.116, -197.866],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.091, -47.819],
                                  [-30.956, -130.29],
                                  [56.446, -39.502],
                                  [-131.663, -34.666],
                                  [-17.739, -69.073],
                                  [-116.809, 4.195],
                                  [135.441, 81.896],
                                  [-46.393, 148.735],
                                  [-8.941, 92],
                                ],
                                o: [
                                  [-98.432, 48.55],
                                  [30.956, 130.29],
                                  [-56.446, 39.502],
                                  [131.663, 34.666],
                                  [18.418, 69.909],
                                  [111.477, -3.045],
                                  [-135.441, -81.896],
                                  [45.4, -149.623],
                                  [8.941, -92],
                                ],
                                v: [
                                  [-142.35, -378.586],
                                  [-170.223, -162.094],
                                  [-240.342, 57.507],
                                  [-247.784, 155.456],
                                  [-7.679, 295.032],
                                  [68.159, 379.112],
                                  [281.201, 268.677],
                                  [32.742, -93.058],
                                  [321.943, -198.778],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.04, -47.921],
                                  [-31.079, -130.429],
                                  [56.533, -39.416],
                                  [-131.817, -34.65],
                                  [-17.745, -69.144],
                                  [-116.691, 4.142],
                                  [135.65, 81.948],
                                  [-46.482, 148.912],
                                  [-8.918, 92],
                                ],
                                o: [
                                  [-98.47, 48.635],
                                  [31.079, 130.429],
                                  [-56.533, 39.417],
                                  [131.817, 34.65],
                                  [18.408, 69.96],
                                  [111.49, -3.02],
                                  [-135.65, -81.948],
                                  [45.513, -149.779],
                                  [8.918, -92],
                                ],
                                v: [
                                  [-142.615, -378.677],
                                  [-169.465, -161.584],
                                  [-240.756, 57.68],
                                  [-247.637, 155.229],
                                  [-8.7, 294.679],
                                  [67.953, 379.624],
                                  [281.928, 269.432],
                                  [32.465, -92.689],
                                  [322.765, -199.752],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.99, -48.02],
                                  [-31.2, -130.565],
                                  [56.617, -39.333],
                                  [-131.968, -34.634],
                                  [-17.751, -69.214],
                                  [-116.577, 4.089],
                                  [135.854, 81.998],
                                  [-46.568, 149.086],
                                  [-8.895, 92],
                                ],
                                o: [
                                  [-98.508, 48.717],
                                  [31.2, 130.565],
                                  [-56.617, 39.333],
                                  [131.968, 34.634],
                                  [18.398, 70.01],
                                  [111.503, -2.995],
                                  [-135.854, -81.998],
                                  [45.623, -149.931],
                                  [8.895, -92],
                                ],
                                v: [
                                  [-142.817, -378.707],
                                  [-168.768, -161.016],
                                  [-241.173, 57.734],
                                  [-247.495, 154.998],
                                  [-9.73, 294.327],
                                  [67.813, 380.193],
                                  [282.596, 270.186],
                                  [32.197, -92.313],
                                  [323.523, -200.726],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.941, -48.118],
                                  [-31.317, -130.699],
                                  [56.7, -39.251],
                                  [-132.116, -34.619],
                                  [-17.757, -69.283],
                                  [-116.464, 4.038],
                                  [136.054, 82.047],
                                  [-46.652, 149.255],
                                  [-8.873, 92],
                                ],
                                o: [
                                  [-98.544, 48.797],
                                  [31.317, 130.699],
                                  [-56.7, 39.251],
                                  [132.116, 34.619],
                                  [18.388, 70.059],
                                  [111.515, -2.971],
                                  [-136.054, -82.047],
                                  [45.73, -150.08],
                                  [8.873, -92],
                                ],
                                v: [
                                  [-143.015, -378.733],
                                  [-168.067, -160.507],
                                  [-241.588, 57.841],
                                  [-247.291, 154.826],
                                  [-10.83, 293.973],
                                  [67.729, 380.698],
                                  [283.266, 270.945],
                                  [31.936, -91.932],
                                  [324.276, -201.701],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.893, -48.214],
                                  [-31.433, -130.829],
                                  [56.781, -39.171],
                                  [-132.261, -34.604],
                                  [-17.763, -69.349],
                                  [-116.354, 3.988],
                                  [136.25, 82.095],
                                  [-46.734, 149.421],
                                  [-8.852, 92],
                                ],
                                o: [
                                  [-98.58, 48.876],
                                  [31.433, 130.829],
                                  [-56.781, 39.171],
                                  [132.261, 34.603],
                                  [18.379, 70.107],
                                  [111.527, -2.947],
                                  [-136.25, -82.095],
                                  [45.835, -150.226],
                                  [8.852, -92],
                                ],
                                v: [
                                  [-143.146, -378.76],
                                  [-167.43, -159.999],
                                  [-242.007, 57.887],
                                  [-247.091, 154.65],
                                  [-11.876, 293.558],
                                  [67.766, 381.26],
                                  [283.94, 271.647],
                                  [31.685, -91.545],
                                  [325.023, -202.676],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.847, -48.307],
                                  [-31.545, -130.957],
                                  [56.861, -39.093],
                                  [-132.403, -34.589],
                                  [-17.769, -69.415],
                                  [-116.247, 3.939],
                                  [136.441, 82.142],
                                  [-46.815, 149.583],
                                  [-8.831, 92],
                                ],
                                o: [
                                  [-98.615, 48.953],
                                  [31.545, 130.957],
                                  [-56.861, 39.093],
                                  [132.403, 34.589],
                                  [18.369, 70.153],
                                  [111.538, -2.923],
                                  [-136.441, -82.142],
                                  [45.938, -150.368],
                                  [8.831, -92],
                                ],
                                v: [
                                  [-143.277, -378.724],
                                  [-166.791, -159.491],
                                  [-242.485, 57.99],
                                  [-246.951, 154.536],
                                  [-12.991, 293.204],
                                  [67.804, 381.821],
                                  [284.558, 272.352],
                                  [31.441, -91.209],
                                  [325.703, -203.713],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.801, -48.398],
                                  [-31.655, -131.081],
                                  [56.938, -39.017],
                                  [-132.541, -34.574],
                                  [-17.775, -69.479],
                                  [-116.142, 3.891],
                                  [136.627, 82.188],
                                  [-46.893, 149.742],
                                  [-8.81, 92],
                                ],
                                o: [
                                  [-98.649, 49.028],
                                  [31.655, 131.081],
                                  [-56.938, 39.017],
                                  [132.541, 34.574],
                                  [18.36, 70.199],
                                  [111.55, -2.9],
                                  [-136.627, -82.188],
                                  [46.038, -150.507],
                                  [8.81, -92],
                                ],
                                v: [
                                  [-143.341, -378.744],
                                  [-166.151, -159.044],
                                  [-242.904, 58.029],
                                  [-246.752, 154.42],
                                  [-14.052, 292.848],
                                  [67.902, 382.377],
                                  [285.179, 273.06],
                                  [31.205, -90.867],
                                  [326.379, -204.752],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.756, -48.487],
                                  [-31.763, -131.203],
                                  [57.014, -38.942],
                                  [-132.676, -34.56],
                                  [-17.78, -69.541],
                                  [-116.04, 3.844],
                                  [136.81, 82.233],
                                  [-46.97, 149.897],
                                  [-8.79, 92],
                                ],
                                o: [
                                  [-98.683, 49.102],
                                  [31.763, 131.203],
                                  [-57.014, 38.942],
                                  [132.676, 34.56],
                                  [18.351, 70.244],
                                  [111.561, -2.878],
                                  [-136.81, -82.233],
                                  [46.136, -150.643],
                                  [8.79, -92],
                                ],
                                v: [
                                  [-143.339, -378.704],
                                  [-165.51, -158.538],
                                  [-243.383, 58.005],
                                  [-246.557, 154.3],
                                  [-15.182, 292.492],
                                  [68.059, 382.992],
                                  [285.803, 273.711],
                                  [30.977, -90.578],
                                  [327.049, -205.789],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.713, -48.575],
                                  [-31.868, -131.322],
                                  [57.088, -38.869],
                                  [-132.808, -34.546],
                                  [-17.786, -69.602],
                                  [-115.939, 3.798],
                                  [136.989, 82.277],
                                  [-47.046, 150.048],
                                  [-8.771, 92],
                                ],
                                o: [
                                  [-98.715, 49.174],
                                  [31.868, 131.322],
                                  [-57.088, 38.869],
                                  [132.808, 34.546],
                                  [18.343, 70.287],
                                  [111.572, -2.856],
                                  [-136.989, -82.277],
                                  [46.232, -150.776],
                                  [8.771, -92],
                                ],
                                v: [
                                  [-143.336, -378.664],
                                  [-164.93, -158.096],
                                  [-243.803, 57.917],
                                  [-246.363, 154.299],
                                  [-16.318, 292.078],
                                  [68.28, 383.545],
                                  [286.369, 274.367],
                                  [30.757, -90.286],
                                  [327.653, -206.828],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.67, -48.66],
                                  [-31.971, -131.439],
                                  [57.161, -38.797],
                                  [-132.938, -34.532],
                                  [-17.791, -69.662],
                                  [-115.841, 3.754],
                                  [137.163, 82.319],
                                  [-47.119, 150.196],
                                  [-8.752, 92],
                                ],
                                o: [
                                  [-98.747, 49.244],
                                  [31.971, 131.439],
                                  [-57.161, 38.797],
                                  [132.938, 34.532],
                                  [18.334, 70.33],
                                  [111.582, -2.835],
                                  [-137.163, -82.32],
                                  [46.326, -150.906],
                                  [8.752, -92],
                                ],
                                v: [
                                  [-143.266, -378.621],
                                  [-164.411, -157.711],
                                  [-244.223, 57.826],
                                  [-246.109, 154.239],
                                  [-17.525, 291.721],
                                  [68.56, 384.155],
                                  [286.938, 275.026],
                                  [30.486, -89.987],
                                  [328.254, -207.929],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.628, -48.743],
                                  [-32.071, -131.552],
                                  [57.232, -38.728],
                                  [-133.064, -34.519],
                                  [-17.796, -69.72],
                                  [-115.745, 3.71],
                                  [137.334, 82.361],
                                  [-47.191, 150.341],
                                  [-8.733, 92],
                                ],
                                o: [
                                  [-98.779, 49.313],
                                  [32.071, 131.552],
                                  [-57.232, 38.728],
                                  [133.064, 34.519],
                                  [18.326, 70.372],
                                  [111.593, -2.814],
                                  [-137.334, -82.361],
                                  [46.418, -151.033],
                                  [8.733, -92],
                                ],
                                v: [
                                  [-143.195, -378.515],
                                  [-163.889, -157.27],
                                  [-244.643, 57.671],
                                  [-245.915, 154.299],
                                  [-18.675, 291.362],
                                  [68.899, 384.761],
                                  [287.509, 275.625],
                                  [30.28, -89.683],
                                  [328.788, -208.971],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.588, -48.825],
                                  [-32.17, -131.664],
                                  [57.301, -38.659],
                                  [-133.187, -34.506],
                                  [-17.802, -69.777],
                                  [-115.652, 3.667],
                                  [137.5, 82.402],
                                  [-47.261, 150.483],
                                  [-8.714, 92],
                                ],
                                o: [
                                  [-98.809, 49.38],
                                  [32.17, 131.664],
                                  [-57.301, 38.659],
                                  [133.187, 34.506],
                                  [18.318, 70.412],
                                  [111.603, -2.794],
                                  [-137.5, -82.402],
                                  [46.507, -151.158],
                                  [8.714, -92],
                                ],
                                v: [
                                  [-143.058, -378.407],
                                  [-163.43, -156.889],
                                  [-245.064, 57.515],
                                  [-245.727, 154.357],
                                  [-19.832, 291.003],
                                  [69.303, 385.366],
                                  [288.024, 276.228],
                                  [30.083, -89.435],
                                  [329.318, -210.071],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.548, -48.904],
                                  [-32.266, -131.772],
                                  [57.369, -38.593],
                                  [-133.308, -34.493],
                                  [-17.807, -69.833],
                                  [-115.56, 3.625],
                                  [137.664, 82.443],
                                  [-47.33, 150.621],
                                  [-8.697, 92],
                                ],
                                o: [
                                  [-98.839, 49.446],
                                  [32.266, 131.772],
                                  [-57.369, 38.593],
                                  [133.308, 34.493],
                                  [18.31, 70.452],
                                  [111.613, -2.774],
                                  [-137.663, -82.443],
                                  [46.595, -151.279],
                                  [8.697, -92],
                                ],
                                v: [
                                  [-142.854, -378.357],
                                  [-162.968, -156.507],
                                  [-245.485, 57.293],
                                  [-245.537, 154.475],
                                  [-20.997, 290.645],
                                  [69.826, 385.968],
                                  [288.54, 276.836],
                                  [29.892, -89.182],
                                  [329.841, -211.17],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.509, -48.982],
                                  [-32.36, -131.879],
                                  [57.435, -38.527],
                                  [-133.426, -34.481],
                                  [-17.811, -69.888],
                                  [-115.471, 3.584],
                                  [137.823, 82.482],
                                  [-47.397, 150.756],
                                  [-8.679, 92],
                                ],
                                o: [
                                  [-98.868, 49.51],
                                  [32.36, 131.879],
                                  [-57.435, 38.527],
                                  [133.426, 34.481],
                                  [18.302, 70.491],
                                  [111.623, -2.754],
                                  [-137.823, -82.482],
                                  [46.68, -151.398],
                                  [8.679, -92],
                                ],
                                v: [
                                  [-142.65, -378.246],
                                  [-162.568, -156.189],
                                  [-245.907, 57.007],
                                  [-245.289, 154.593],
                                  [-22.169, 290.287],
                                  [70.35, 386.569],
                                  [288.999, 277.443],
                                  [29.767, -88.926],
                                  [330.299, -212.273],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.471, -49.058],
                                  [-32.451, -131.983],
                                  [57.499, -38.464],
                                  [-133.541, -34.469],
                                  [-17.816, -69.941],
                                  [-115.383, 3.544],
                                  [137.979, 82.52],
                                  [-47.463, 150.889],
                                  [-8.662, 92],
                                ],
                                o: [
                                  [-98.897, 49.573],
                                  [32.451, 131.983],
                                  [-57.499, 38.464],
                                  [133.541, 34.469],
                                  [18.294, 70.529],
                                  [111.632, -2.735],
                                  [-137.979, -82.52],
                                  [46.764, -151.514],
                                  [8.662, -92],
                                ],
                                v: [
                                  [-142.381, -378.073],
                                  [-162.229, -155.873],
                                  [-246.268, 56.719],
                                  [-245.102, 154.77],
                                  [-23.344, 289.927],
                                  [70.877, 387.287],
                                  [289.46, 277.994],
                                  [29.589, -88.723],
                                  [330.753, -213.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.434, -49.133],
                                  [-32.541, -132.084],
                                  [57.563, -38.401],
                                  [-133.654, -34.457],
                                  [-17.821, -69.993],
                                  [-115.298, 3.505],
                                  [138.131, 82.557],
                                  [-47.527, 151.018],
                                  [-8.645, 92],
                                ],
                                o: [
                                  [-98.925, 49.634],
                                  [32.541, 132.084],
                                  [-57.563, 38.402],
                                  [133.654, 34.457],
                                  [18.287, 70.566],
                                  [111.642, -2.717],
                                  [-138.131, -82.557],
                                  [46.846, -151.627],
                                  [8.645, -92],
                                ],
                                v: [
                                  [-142.046, -377.958],
                                  [-161.889, -155.557],
                                  [-246.63, 56.367],
                                  [-244.919, 154.948],
                                  [-24.527, 289.567],
                                  [71.523, 388.063],
                                  [289.923, 278.549],
                                  [29.477, -88.518],
                                  [331.142, -214.536],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.397, -49.205],
                                  [-32.629, -132.183],
                                  [57.624, -38.341],
                                  [-133.764, -34.445],
                                  [-17.825, -70.044],
                                  [-115.214, 3.467],
                                  [138.279, 82.594],
                                  [-47.589, 151.144],
                                  [-8.629, 92],
                                ],
                                o: [
                                  [-98.952, 49.694],
                                  [32.629, 132.183],
                                  [-57.624, 38.341],
                                  [133.764, 34.445],
                                  [18.279, 70.603],
                                  [111.651, -2.699],
                                  [-138.279, -82.594],
                                  [46.926, -151.738],
                                  [8.629, -92],
                                ],
                                v: [
                                  [-141.768, -377.779],
                                  [-161.608, -155.241],
                                  [-246.992, 56.01],
                                  [-244.734, 155.126],
                                  [-25.716, 289.205],
                                  [72.17, 388.839],
                                  [290.33, 279.042],
                                  [29.371, -88.308],
                                  [331.525, -215.638],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.362, -49.276],
                                  [-32.714, -132.28],
                                  [57.685, -38.281],
                                  [-133.872, -34.434],
                                  [-17.83, -70.093],
                                  [-115.132, 3.43],
                                  [138.425, 82.63],
                                  [-47.651, 151.267],
                                  [-8.613, 92],
                                ],
                                o: [
                                  [-98.979, 49.753],
                                  [32.714, 132.28],
                                  [-57.685, 38.281],
                                  [133.872, 34.434],
                                  [18.272, 70.638],
                                  [111.66, -2.681],
                                  [-138.425, -82.63],
                                  [47.004, -151.846],
                                  [8.613, -92],
                                ],
                                v: [
                                  [-141.365, -377.661],
                                  [-161.329, -154.989],
                                  [-247.354, 55.591],
                                  [-244.609, 155.305],
                                  [-26.85, 288.905],
                                  [72.878, 389.673],
                                  [290.738, 279.602],
                                  [29.27, -88.154],
                                  [331.904, -216.799],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.327, -49.346],
                                  [-32.798, -132.375],
                                  [57.744, -38.223],
                                  [-133.977, -34.423],
                                  [-17.834, -70.142],
                                  [-115.053, 3.394],
                                  [138.567, 82.664],
                                  [-47.71, 151.388],
                                  [-8.597, 92],
                                ],
                                o: [
                                  [-99.005, 49.81],
                                  [32.798, 132.375],
                                  [-57.744, 38.223],
                                  [133.977, 34.423],
                                  [18.265, 70.673],
                                  [111.668, -2.664],
                                  [-138.567, -82.664],
                                  [47.08, -151.952],
                                  [8.597, -92],
                                ],
                                v: [
                                  [-140.959, -377.479],
                                  [-161.109, -154.798],
                                  [-247.716, 55.168],
                                  [-244.545, 155.482],
                                  [-27.989, 288.543],
                                  [73.586, 390.563],
                                  [291.089, 280.1],
                                  [29.236, -87.939],
                                  [332.218, -217.9],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.293, -49.413],
                                  [-32.88, -132.467],
                                  [57.801, -38.166],
                                  [-134.079, -34.412],
                                  [-17.838, -70.189],
                                  [-114.975, 3.358],
                                  [138.705, 82.699],
                                  [-47.769, 151.506],
                                  [-8.582, 92],
                                ],
                                o: [
                                  [-99.03, 49.866],
                                  [32.88, 132.468],
                                  [-57.801, 38.166],
                                  [134.079, 34.412],
                                  [18.259, 70.707],
                                  [111.677, -2.647],
                                  [-138.705, -82.698],
                                  [47.155, -152.055],
                                  [8.582, -92],
                                ],
                                v: [
                                  [-140.549, -377.294],
                                  [-160.891, -154.606],
                                  [-248.018, 54.741],
                                  [-244.479, 155.661],
                                  [-29.193, 288.241],
                                  [74.358, 391.456],
                                  [291.44, 280.541],
                                  [29.147, -87.778],
                                  [332.468, -219.062],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.26, -49.479],
                                  [-32.96, -132.558],
                                  [57.858, -38.111],
                                  [-134.18, -34.402],
                                  [-17.842, -70.236],
                                  [-114.899, 3.323],
                                  [138.841, 82.732],
                                  [-47.826, 151.621],
                                  [-8.567, 92],
                                ],
                                o: [
                                  [-99.055, 49.921],
                                  [32.96, 132.558],
                                  [-57.858, 38.111],
                                  [134.18, 34.402],
                                  [18.252, 70.74],
                                  [111.685, -2.63],
                                  [-138.841, -82.732],
                                  [47.227, -152.156],
                                  [8.567, -92],
                                ],
                                v: [
                                  [-140.073, -377.17],
                                  [-160.613, -154.476],
                                  [-248.32, 54.25],
                                  [-244.476, 155.839],
                                  [-30.282, 287.937],
                                  [75.189, 392.345],
                                  [291.794, 280.985],
                                  [29.123, -87.614],
                                  [332.773, -220.161],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.228, -49.544],
                                  [-33.038, -132.646],
                                  [57.913, -38.057],
                                  [-134.278, -34.391],
                                  [-17.846, -70.281],
                                  [-114.824, 3.289],
                                  [138.973, 82.764],
                                  [-47.882, 151.733],
                                  [-8.553, 92],
                                ],
                                o: [
                                  [-99.079, 49.974],
                                  [33.038, 132.646],
                                  [-57.913, 38.057],
                                  [134.278, 34.391],
                                  [18.246, 70.772],
                                  [111.693, -2.614],
                                  [-138.973, -82.764],
                                  [47.299, -152.255],
                                  [8.553, -92],
                                ],
                                v: [
                                  [-139.595, -376.983],
                                  [-160.395, -154.347],
                                  [-248.622, 53.696],
                                  [-244.532, 155.958],
                                  [-31.435, 287.632],
                                  [76.022, 393.355],
                                  [292.091, 281.429],
                                  [29.104, -87.507],
                                  [332.954, -221.32],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.196, -49.607],
                                  [-33.114, -132.732],
                                  [57.966, -38.004],
                                  [-134.374, -34.381],
                                  [-17.85, -70.325],
                                  [-114.752, 3.256],
                                  [139.102, 82.796],
                                  [-47.936, 151.843],
                                  [-8.538, 92],
                                ],
                                o: [
                                  [-99.103, 50.026],
                                  [33.114, 132.732],
                                  [-57.966, 38.004],
                                  [134.374, 34.381],
                                  [18.239, 70.804],
                                  [111.701, -2.598],
                                  [-139.102, -82.796],
                                  [47.368, -152.351],
                                  [8.538, -92],
                                ],
                                v: [
                                  [-139.052, -376.792],
                                  [-160.119, -154.28],
                                  [-248.924, 53.198],
                                  [-244.589, 156.137],
                                  [-32.533, 287.329],
                                  [76.856, 394.304],
                                  [292.389, 281.874],
                                  [29.091, -87.398],
                                  [333.19, -222.413],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.165, -49.669],
                                  [-33.188, -132.817],
                                  [58.019, -37.952],
                                  [-134.467, -34.372],
                                  [-17.854, -70.368],
                                  [-114.681, 3.224],
                                  [139.229, 82.827],
                                  [-47.99, 151.95],
                                  [-8.524, 92],
                                ],
                                o: [
                                  [-99.126, 50.077],
                                  [33.188, 132.817],
                                  [-58.019, 37.952],
                                  [134.467, 34.372],
                                  [18.233, 70.835],
                                  [111.709, -2.583],
                                  [-139.229, -82.827],
                                  [47.436, -152.445],
                                  [8.524, -92],
                                ],
                                v: [
                                  [-138.505, -376.601],
                                  [-159.904, -154.212],
                                  [-249.224, 52.636],
                                  [-244.707, 156.257],
                                  [-33.635, 287.024],
                                  [77.752, 395.312],
                                  [292.63, 282.259],
                                  [29.144, -87.285],
                                  [333.363, -223.506],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.135, -49.729],
                                  [-33.261, -132.899],
                                  [58.07, -37.902],
                                  [-134.558, -34.362],
                                  [-17.858, -70.411],
                                  [-114.611, 3.192],
                                  [139.352, 82.858],
                                  [-48.042, 152.055],
                                  [-8.511, 92],
                                ],
                                o: [
                                  [-99.148, 50.127],
                                  [33.261, 132.899],
                                  [-58.07, 37.902],
                                  [134.558, 34.362],
                                  [18.227, 70.865],
                                  [111.716, -2.568],
                                  [-139.352, -82.858],
                                  [47.502, -152.537],
                                  [8.511, -92],
                                ],
                                v: [
                                  [-137.955, -376.407],
                                  [-159.627, -154.207],
                                  [-249.466, 52.01],
                                  [-244.825, 156.436],
                                  [-34.681, 286.718],
                                  [78.647, 396.321],
                                  [292.874, 282.646],
                                  [29.141, -87.169],
                                  [333.472, -224.657],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.106, -49.788],
                                  [-33.332, -132.979],
                                  [58.12, -37.852],
                                  [-134.648, -34.352],
                                  [-17.862, -70.452],
                                  [-114.544, 3.161],
                                  [139.473, 82.887],
                                  [-48.092, 152.157],
                                  [-8.498, 92],
                                ],
                                o: [
                                  [-99.171, 50.175],
                                  [33.332, 132.979],
                                  [-58.12, 37.852],
                                  [134.648, 34.353],
                                  [18.221, 70.894],
                                  [111.724, -2.553],
                                  [-139.473, -82.887],
                                  [47.567, -152.627],
                                  [8.498, -92],
                                ],
                                v: [
                                  [-137.341, -376.212],
                                  [-159.414, -154.263],
                                  [-249.706, 51.443],
                                  [-245.003, 156.555],
                                  [-35.733, 286.471],
                                  [79.605, 397.387],
                                  [293.12, 283.032],
                                  [29.203, -87.113],
                                  [333.577, -225.744],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.077, -49.846],
                                  [-33.401, -133.058],
                                  [58.169, -37.804],
                                  [-134.735, -34.343],
                                  [-17.865, -70.492],
                                  [-114.478, 3.131],
                                  [139.59, 82.916],
                                  [-48.142, 152.257],
                                  [-8.485, 92],
                                ],
                                o: [
                                  [-99.192, 50.223],
                                  [33.401, 133.058],
                                  [-58.169, 37.804],
                                  [134.735, 34.343],
                                  [18.215, 70.923],
                                  [111.731, -2.539],
                                  [-139.59, -82.916],
                                  [47.63, -152.715],
                                  [8.485, -92],
                                ],
                                v: [
                                  [-136.722, -376.013],
                                  [-159.199, -154.32],
                                  [-249.947, 50.811],
                                  [-245.184, 156.736],
                                  [-36.786, 286.224],
                                  [80.564, 398.456],
                                  [293.309, 283.36],
                                  [29.269, -87.052],
                                  [333.68, -226.83],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.049, -49.902],
                                  [-33.469, -133.135],
                                  [58.216, -37.757],
                                  [-134.82, -34.334],
                                  [-17.869, -70.531],
                                  [-114.413, 3.102],
                                  [139.705, 82.944],
                                  [-48.19, 152.354],
                                  [-8.472, 92],
                                ],
                                o: [
                                  [-99.213, 50.269],
                                  [33.469, 133.135],
                                  [-58.216, 37.757],
                                  [134.82, 34.334],
                                  [18.21, 70.951],
                                  [111.738, -2.525],
                                  [-139.705, -82.944],
                                  [47.692, -152.8],
                                  [8.472, -92],
                                ],
                                v: [
                                  [-136.101, -375.814],
                                  [-158.984, -154.38],
                                  [-250.187, 50.175],
                                  [-245.424, 156.856],
                                  [-37.783, 285.975],
                                  [81.584, 399.523],
                                  [293.5, 283.685],
                                  [29.4, -86.989],
                                  [333.716, -227.914],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.022, -49.957],
                                  [-33.535, -133.209],
                                  [58.263, -37.711],
                                  [-134.903, -34.326],
                                  [-17.872, -70.57],
                                  [-114.35, 3.073],
                                  [139.817, 82.972],
                                  [-48.237, 152.45],
                                  [-8.46, 92],
                                ],
                                o: [
                                  [-99.234, 50.314],
                                  [33.535, 133.209],
                                  [-58.263, 37.711],
                                  [134.903, 34.326],
                                  [18.204, 70.978],
                                  [111.745, -2.511],
                                  [-139.817, -82.972],
                                  [47.752, -152.884],
                                  [8.46, -92],
                                ],
                                v: [
                                  [-135.478, -375.673],
                                  [-158.831, -154.498],
                                  [-250.366, 49.538],
                                  [-245.726, 157.037],
                                  [-38.723, 285.725],
                                  [82.547, 400.591],
                                  [293.694, 284.013],
                                  [29.476, -86.985],
                                  [333.75, -228.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.995, -50.01],
                                  [-33.6, -133.282],
                                  [58.309, -37.667],
                                  [-134.984, -34.317],
                                  [-17.876, -70.607],
                                  [-114.288, 3.045],
                                  [139.927, 82.999],
                                  [-48.284, 152.542],
                                  [-8.448, 92],
                                ],
                                o: [
                                  [-99.254, 50.358],
                                  [33.6, 133.283],
                                  [-58.309, 37.667],
                                  [134.984, 34.317],
                                  [18.199, 71.005],
                                  [111.751, -2.497],
                                  [-139.927, -82.999],
                                  [47.811, -152.965],
                                  [8.448, -92],
                                ],
                                v: [
                                  [-134.788, -375.469],
                                  [-158.679, -154.681],
                                  [-250.544, 48.895],
                                  [-246.028, 157.158],
                                  [-39.668, 285.473],
                                  [83.57, 401.657],
                                  [293.828, 284.28],
                                  [29.616, -86.916],
                                  [333.72, -230.014],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.969, -50.062],
                                  [-33.663, -133.354],
                                  [58.353, -37.623],
                                  [-135.063, -34.309],
                                  [-17.879, -70.644],
                                  [-114.228, 3.017],
                                  [140.034, 83.025],
                                  [-48.329, 152.633],
                                  [-8.436, 92],
                                ],
                                o: [
                                  [-99.273, 50.401],
                                  [33.663, 133.354],
                                  [-58.353, 37.623],
                                  [135.063, 34.309],
                                  [18.194, 71.031],
                                  [111.758, -2.484],
                                  [-140.034, -83.025],
                                  [47.869, -153.045],
                                  [8.436, -92],
                                ],
                                v: [
                                  [-134.096, -375.263],
                                  [-158.524, -154.865],
                                  [-250.722, 48.189],
                                  [-246.393, 157.341],
                                  [-40.614, 285.281],
                                  [84.594, 402.725],
                                  [293.966, 284.547],
                                  [29.761, -86.968],
                                  [333.686, -231.031],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.943, -50.113],
                                  [-33.724, -133.424],
                                  [58.396, -37.58],
                                  [-135.141, -34.301],
                                  [-17.882, -70.679],
                                  [-114.17, 2.991],
                                  [140.138, 83.051],
                                  [-48.373, 152.722],
                                  [-8.424, 92],
                                ],
                                o: [
                                  [-99.292, 50.444],
                                  [33.724, 133.424],
                                  [-58.396, 37.58],
                                  [135.141, 34.301],
                                  [18.189, 71.057],
                                  [111.764, -2.472],
                                  [-140.138, -83.051],
                                  [47.925, -153.123],
                                  [8.425, -92],
                                ],
                                v: [
                                  [-133.402, -375.114],
                                  [-158.372, -155.112],
                                  [-250.839, 47.541],
                                  [-246.758, 157.524],
                                  [-41.505, 285.027],
                                  [85.618, 403.792],
                                  [294.106, 284.816],
                                  [29.91, -86.957],
                                  [333.587, -232.047],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.918, -50.163],
                                  [-33.784, -133.492],
                                  [58.439, -37.538],
                                  [-135.216, -34.293],
                                  [-17.885, -70.714],
                                  [-114.112, 2.964],
                                  [140.24, 83.076],
                                  [-48.416, 152.808],
                                  [-8.413, 92],
                                ],
                                o: [
                                  [-99.311, 50.485],
                                  [33.784, 133.492],
                                  [-58.439, 37.538],
                                  [135.216, 34.293],
                                  [18.184, 71.082],
                                  [111.77, -2.459],
                                  [-140.24, -83.076],
                                  [47.979, -153.199],
                                  [8.413, -92],
                                ],
                                v: [
                                  [-132.644, -374.962],
                                  [-158.279, -155.359],
                                  [-251.017, 46.89],
                                  [-247.185, 157.646],
                                  [-42.336, 284.833],
                                  [86.705, 404.859],
                                  [294.186, 285.083],
                                  [30.123, -87.004],
                                  [333.547, -233.061],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.894, -50.212],
                                  [-33.843, -133.558],
                                  [58.48, -37.498],
                                  [-135.29, -34.285],
                                  [-17.888, -70.748],
                                  [-114.057, 2.939],
                                  [140.34, 83.1],
                                  [-48.458, 152.893],
                                  [-8.402, 92],
                                ],
                                o: [
                                  [-99.329, 50.525],
                                  [33.843, 133.558],
                                  [-58.48, 37.498],
                                  [135.29, 34.285],
                                  [18.179, 71.106],
                                  [111.776, -2.447],
                                  [-140.34, -83.1],
                                  [48.033, -153.273],
                                  [8.402, -92],
                                ],
                                v: [
                                  [-131.945, -374.746],
                                  [-158.187, -155.668],
                                  [-251.132, 46.175],
                                  [-247.613, 157.83],
                                  [-43.11, 284.636],
                                  [87.794, 405.926],
                                  [294.269, 285.29],
                                  [30.341, -87.049],
                                  [333.379, -234.072],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.87, -50.259],
                                  [-33.9, -133.623],
                                  [58.52, -37.458],
                                  [-135.362, -34.277],
                                  [-17.891, -70.781],
                                  [-114.002, 2.914],
                                  [140.437, 83.124],
                                  [-48.499, 152.975],
                                  [-8.392, 92],
                                ],
                                o: [
                                  [-99.347, 50.564],
                                  [33.9, 133.623],
                                  [-58.52, 37.458],
                                  [135.362, 34.277],
                                  [18.174, 71.13],
                                  [111.782, -2.435],
                                  [-140.437, -83.124],
                                  [48.085, -153.345],
                                  [8.392, -92],
                                ],
                                v: [
                                  [-131.181, -374.59],
                                  [-158.095, -155.98],
                                  [-251.187, 45.518],
                                  [-248.103, 158.015],
                                  [-43.886, 284.499],
                                  [88.823, 406.993],
                                  [294.353, 285.499],
                                  [30.562, -87.091],
                                  [333.21, -235.083],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.847, -50.306],
                                  [-33.956, -133.686],
                                  [58.56, -37.419],
                                  [-135.432, -34.27],
                                  [-17.894, -70.814],
                                  [-113.949, 2.89],
                                  [140.532, 83.147],
                                  [-48.538, 153.056],
                                  [-8.381, 92],
                                ],
                                o: [
                                  [-99.365, 50.602],
                                  [33.956, 133.686],
                                  [-58.56, 37.419],
                                  [135.432, 34.27],
                                  [18.169, 71.153],
                                  [111.788, -2.424],
                                  [-140.532, -83.147],
                                  [48.136, -153.416],
                                  [8.381, -92],
                                ],
                                v: [
                                  [-130.476, -374.491],
                                  [-158.062, -156.293],
                                  [-251.301, 44.857],
                                  [-248.594, 158.137],
                                  [-44.666, 284.3],
                                  [89.912, 408],
                                  [294.378, 285.706],
                                  [30.788, -87.194],
                                  [333.038, -236.029],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.825, -50.351],
                                  [-34.011, -133.748],
                                  [58.598, -37.381],
                                  [-135.5, -34.263],
                                  [-17.897, -70.846],
                                  [-113.897, 2.866],
                                  [140.624, 83.17],
                                  [-48.577, 153.134],
                                  [-8.371, 92],
                                ],
                                o: [
                                  [-99.382, 50.639],
                                  [34.011, 133.748],
                                  [-58.598, 37.381],
                                  [135.5, 34.263],
                                  [18.165, 71.175],
                                  [111.794, -2.412],
                                  [-140.624, -83.17],
                                  [48.186, -153.485],
                                  [8.371, -92],
                                ],
                                v: [
                                  [-129.708, -374.328],
                                  [-158.03, -156.668],
                                  [-251.354, 44.196],
                                  [-249.085, 158.323],
                                  [-45.325, 284.162],
                                  [91.004, 409.067],
                                  [294.405, 285.853],
                                  [31.017, -87.354],
                                  [332.861, -236.975],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.803, -50.395],
                                  [-34.064, -133.808],
                                  [58.636, -37.344],
                                  [-135.567, -34.256],
                                  [-17.9, -70.876],
                                  [-113.846, 2.843],
                                  [140.714, 83.192],
                                  [-48.616, 153.211],
                                  [-8.361, 92],
                                ],
                                o: [
                                  [-99.398, 50.676],
                                  [34.064, 133.808],
                                  [-58.636, 37.344],
                                  [135.567, 34.256],
                                  [18.161, 71.197],
                                  [111.799, -2.401],
                                  [-140.714, -83.192],
                                  [48.234, -153.552],
                                  [8.361, -92],
                                ],
                                v: [
                                  [-128.937, -374.223],
                                  [-157.996, -157.045],
                                  [-251.408, 43.469],
                                  [-249.699, 158.509],
                                  [-45.988, 284.021],
                                  [92.097, 410.074],
                                  [294.433, 286],
                                  [31.31, -87.453],
                                  [332.621, -237.858],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.781, -50.438],
                                  [-34.116, -133.867],
                                  [58.672, -37.308],
                                  [-135.632, -34.249],
                                  [-17.902, -70.907],
                                  [-113.796, 2.82],
                                  [140.802, 83.214],
                                  [-48.653, 153.286],
                                  [-8.352, 92],
                                ],
                                o: [
                                  [-99.414, 50.711],
                                  [34.116, 133.867],
                                  [-58.672, 37.308],
                                  [135.632, 34.249],
                                  [18.156, 71.219],
                                  [111.805, -2.391],
                                  [-140.803, -83.214],
                                  [48.282, -153.618],
                                  [8.352, -92],
                                ],
                                v: [
                                  [-128.225, -374.056],
                                  [-158.025, -157.484],
                                  [-251.461, 42.861],
                                  [-250.253, 158.695],
                                  [-46.59, 283.879],
                                  [93.189, 411.081],
                                  [294.402, 286.148],
                                  [31.608, -87.611],
                                  [332.317, -238.8],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.76, -50.48],
                                  [-34.166, -133.924],
                                  [58.708, -37.273],
                                  [-135.696, -34.242],
                                  [-17.905, -70.936],
                                  [-113.748, 2.798],
                                  [140.888, 83.235],
                                  [-48.689, 153.359],
                                  [-8.342, 92],
                                ],
                                o: [
                                  [-99.43, 50.746],
                                  [34.166, 133.924],
                                  [-58.708, 37.273],
                                  [135.696, 34.242],
                                  [18.152, 71.24],
                                  [111.81, -2.38],
                                  [-140.889, -83.235],
                                  [48.328, -153.682],
                                  [8.342, -92],
                                ],
                                v: [
                                  [-127.449, -373.947],
                                  [-158.114, -157.925],
                                  [-251.452, 42.191],
                                  [-250.87, 158.821],
                                  [-47.197, 283.797],
                                  [94.284, 412.027],
                                  [294.433, 286.296],
                                  [31.907, -87.828],
                                  [332.07, -239.68],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.74, -50.521],
                                  [-34.216, -133.98],
                                  [58.743, -37.239],
                                  [-135.758, -34.236],
                                  [-17.908, -70.965],
                                  [-113.701, 2.777],
                                  [140.972, 83.256],
                                  [-48.724, 153.43],
                                  [-8.333, 92],
                                ],
                                o: [
                                  [-99.445, 50.78],
                                  [34.216, 133.98],
                                  [-58.743, 37.239],
                                  [135.758, 34.236],
                                  [18.148, 71.26],
                                  [111.815, -2.37],
                                  [-140.972, -83.256],
                                  [48.373, -153.744],
                                  [8.333, -92],
                                ],
                                v: [
                                  [-126.733, -373.897],
                                  [-158.202, -158.368],
                                  [-251.445, 41.518],
                                  [-251.548, 159.009],
                                  [-47.744, 283.713],
                                  [95.379, 413.033],
                                  [294.405, 286.444],
                                  [32.211, -88.044],
                                  [331.697, -240.558],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.72, -50.561],
                                  [-34.264, -134.035],
                                  [58.777, -37.205],
                                  [-135.819, -34.229],
                                  [-17.91, -70.993],
                                  [-113.655, 2.755],
                                  [141.054, 83.276],
                                  [-48.759, 153.499],
                                  [-8.324, 92],
                                ],
                                o: [
                                  [-99.46, 50.813],
                                  [34.264, 134.035],
                                  [-58.777, 37.205],
                                  [135.819, 34.229],
                                  [18.144, 71.28],
                                  [111.82, -2.36],
                                  [-141.054, -83.276],
                                  [48.417, -153.805],
                                  [8.324, -92],
                                ],
                                v: [
                                  [-125.953, -373.783],
                                  [-158.29, -158.873],
                                  [-251.435, 40.905],
                                  [-252.228, 159.196],
                                  [-48.169, 283.628],
                                  [96.475, 413.98],
                                  [294.316, 286.53],
                                  [32.58, -88.258],
                                  [331.383, -241.373],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.7, -50.6],
                                  [-34.311, -134.088],
                                  [58.81, -37.173],
                                  [-135.878, -34.223],
                                  [-17.912, -71.02],
                                  [-113.61, 2.735],
                                  [141.134, 83.296],
                                  [-48.792, 153.567],
                                  [-8.315, 92],
                                ],
                                o: [
                                  [-99.475, 50.845],
                                  [34.311, 134.088],
                                  [-58.81, 37.173],
                                  [135.878, 34.223],
                                  [18.14, 71.3],
                                  [111.825, -2.35],
                                  [-141.134, -83.296],
                                  [48.46, -153.865],
                                  [8.315, -92],
                                ],
                                v: [
                                  [-125.232, -373.73],
                                  [-158.439, -159.38],
                                  [-251.427, 40.288],
                                  [-252.907, 159.385],
                                  [-48.597, 283.542],
                                  [97.51, 414.865],
                                  [294.29, 286.618],
                                  [32.951, -88.531],
                                  [331.004, -242.186],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.681, -50.638],
                                  [-34.357, -134.14],
                                  [58.842, -37.141],
                                  [-135.936, -34.217],
                                  [-17.915, -71.047],
                                  [-113.566, 2.715],
                                  [141.212, 83.315],
                                  [-48.825, 153.633],
                                  [-8.306, 92],
                                ],
                                o: [
                                  [-99.489, 50.876],
                                  [34.357, 134.14],
                                  [-58.842, 37.141],
                                  [135.936, 34.217],
                                  [18.136, 71.319],
                                  [111.83, -2.34],
                                  [-141.212, -83.315],
                                  [48.502, -153.923],
                                  [8.306, -92],
                                ],
                                v: [
                                  [-124.51, -373.735],
                                  [-158.588, -159.887],
                                  [-251.357, 39.732],
                                  [-253.649, 159.512],
                                  [-49.026, 283.516],
                                  [98.608, 415.75],
                                  [294.204, 286.704],
                                  [33.326, -88.804],
                                  [330.622, -242.937],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.662, -50.675],
                                  [-34.402, -134.191],
                                  [58.874, -37.11],
                                  [-135.992, -34.211],
                                  [-17.917, -71.073],
                                  [-113.524, 2.696],
                                  [141.288, 83.333],
                                  [-48.857, 153.698],
                                  [-8.298, 92],
                                ],
                                o: [
                                  [-99.503, 50.907],
                                  [34.402, 134.191],
                                  [-58.874, 37.11],
                                  [135.992, 34.211],
                                  [18.133, 71.338],
                                  [111.834, -2.331],
                                  [-141.288, -83.333],
                                  [48.543, -153.979],
                                  [8.298, -92],
                                ],
                                v: [
                                  [-123.785, -373.677],
                                  [-158.796, -160.398],
                                  [-251.286, 39.11],
                                  [-254.393, 159.701],
                                  [-49.336, 283.427],
                                  [99.708, 416.574],
                                  [294.118, 286.79],
                                  [33.765, -89.136],
                                  [330.177, -243.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.644, -50.711],
                                  [-34.445, -134.24],
                                  [58.905, -37.079],
                                  [-136.047, -34.205],
                                  [-17.919, -71.098],
                                  [-113.482, 2.677],
                                  [141.362, 83.352],
                                  [-48.888, 153.761],
                                  [-8.29, 92],
                                ],
                                o: [
                                  [-99.517, 50.937],
                                  [34.445, 134.24],
                                  [-58.905, 37.079],
                                  [136.047, 34.205],
                                  [18.129, 71.356],
                                  [111.839, -2.322],
                                  [-141.362, -83.352],
                                  [48.582, -154.035],
                                  [8.29, -92],
                                ],
                                v: [
                                  [-123.059, -373.678],
                                  [-159.005, -160.97],
                                  [-251.215, 38.55],
                                  [-255.138, 159.829],
                                  [-49.584, 283.398],
                                  [100.746, 417.398],
                                  [294.034, 286.816],
                                  [34.145, -89.467],
                                  [329.728, -244.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.662, -50.675],
                                  [-34.402, -134.191],
                                  [58.874, -37.11],
                                  [-135.992, -34.211],
                                  [-17.917, -71.073],
                                  [-113.524, 2.696],
                                  [141.288, 83.333],
                                  [-48.857, 153.698],
                                  [-8.298, 92],
                                ],
                                o: [
                                  [-99.503, 50.907],
                                  [34.402, 134.191],
                                  [-58.874, 37.11],
                                  [135.992, 34.211],
                                  [18.133, 71.338],
                                  [111.834, -2.331],
                                  [-141.288, -83.333],
                                  [48.543, -153.979],
                                  [8.298, -92],
                                ],
                                v: [
                                  [-122.235, -373.574],
                                  [-159.262, -161.564],
                                  [-251.106, 37.933],
                                  [-255.916, 159.999],
                                  [-49.958, 283.332],
                                  [101.796, 418.085],
                                  [293.991, 286.789],
                                  [34.82, -89.682],
                                  [329.075, -245.041],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.681, -50.638],
                                  [-34.357, -134.14],
                                  [58.842, -37.141],
                                  [-135.936, -34.217],
                                  [-17.915, -71.047],
                                  [-113.566, 2.715],
                                  [141.212, 83.315],
                                  [-48.825, 153.633],
                                  [-8.306, 92],
                                ],
                                o: [
                                  [-99.489, 50.876],
                                  [34.357, 134.14],
                                  [-58.842, 37.141],
                                  [135.936, 34.217],
                                  [18.136, 71.319],
                                  [111.83, -2.341],
                                  [-141.212, -83.315],
                                  [48.502, -153.923],
                                  [8.306, -92],
                                ],
                                v: [
                                  [-121.41, -373.529],
                                  [-159.52, -162.22],
                                  [-251.058, 37.315],
                                  [-256.692, 160.106],
                                  [-50.271, 283.329],
                                  [102.847, 418.769],
                                  [293.948, 286.824],
                                  [35.498, -89.956],
                                  [328.358, -245.583],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.7, -50.6],
                                  [-34.311, -134.088],
                                  [58.81, -37.173],
                                  [-135.878, -34.223],
                                  [-17.913, -71.02],
                                  [-113.61, 2.735],
                                  [141.134, 83.296],
                                  [-48.792, 153.567],
                                  [-8.315, 92],
                                ],
                                o: [
                                  [-99.475, 50.845],
                                  [34.311, 134.088],
                                  [-58.81, 37.173],
                                  [135.878, 34.223],
                                  [18.14, 71.3],
                                  [111.825, -2.35],
                                  [-141.134, -83.296],
                                  [48.46, -153.865],
                                  [8.315, -92],
                                ],
                                v: [
                                  [-120.585, -373.485],
                                  [-159.779, -162.814],
                                  [-250.887, 36.695],
                                  [-257.467, 160.215],
                                  [-50.462, 283.324],
                                  [103.896, 419.389],
                                  [293.847, 286.796],
                                  [36.239, -90.228],
                                  [327.64, -246.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.72, -50.561],
                                  [-34.264, -134.035],
                                  [58.777, -37.205],
                                  [-135.819, -34.229],
                                  [-17.91, -70.993],
                                  [-113.655, 2.756],
                                  [141.054, 83.276],
                                  [-48.759, 153.499],
                                  [-8.324, 92],
                                ],
                                o: [
                                  [-99.46, 50.813],
                                  [34.264, 134.035],
                                  [-58.777, 37.205],
                                  [135.819, 34.229],
                                  [18.144, 71.28],
                                  [111.82, -2.36],
                                  [-141.054, -83.276],
                                  [48.417, -153.805],
                                  [8.324, -92],
                                ],
                                v: [
                                  [-119.758, -373.439],
                                  [-160.163, -163.47],
                                  [-250.779, 36.136],
                                  [-258.303, 160.322],
                                  [-50.657, 283.321],
                                  [104.883, 419.943],
                                  [293.745, 286.709],
                                  [36.984, -90.559],
                                  [326.92, -246.538],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.74, -50.521],
                                  [-34.216, -133.98],
                                  [58.743, -37.239],
                                  [-135.758, -34.236],
                                  [-17.908, -70.965],
                                  [-113.701, 2.776],
                                  [140.972, 83.256],
                                  [-48.724, 153.43],
                                  [-8.333, 92],
                                ],
                                o: [
                                  [-99.445, 50.78],
                                  [34.216, 133.98],
                                  [-58.743, 37.239],
                                  [135.758, 34.236],
                                  [18.148, 71.26],
                                  [111.815, -2.37],
                                  [-140.972, -83.256],
                                  [48.373, -153.744],
                                  [8.333, -92],
                                ],
                                v: [
                                  [-118.991, -373.455],
                                  [-160.485, -164.127],
                                  [-250.671, 35.576],
                                  [-259.136, 160.429],
                                  [-50.791, 283.317],
                                  [105.929, 420.497],
                                  [293.645, 286.681],
                                  [37.73, -90.887],
                                  [326.137, -247.01],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.76, -50.48],
                                  [-34.166, -133.924],
                                  [58.708, -37.273],
                                  [-135.696, -34.242],
                                  [-17.905, -70.936],
                                  [-113.748, 2.798],
                                  [140.888, 83.235],
                                  [-48.689, 153.359],
                                  [-8.342, 92],
                                ],
                                o: [
                                  [-99.43, 50.746],
                                  [34.166, 133.924],
                                  [-58.708, 37.273],
                                  [135.696, 34.242],
                                  [18.152, 71.24],
                                  [111.81, -2.38],
                                  [-140.888, -83.235],
                                  [48.328, -153.682],
                                  [8.342, -92],
                                ],
                                v: [
                                  [-118.223, -373.468],
                                  [-160.869, -164.723],
                                  [-250.501, 35.074],
                                  [-260.03, 160.474],
                                  [-50.865, 283.315],
                                  [106.913, 420.985],
                                  [293.546, 286.654],
                                  [38.419, -91.334],
                                  [325.415, -247.42],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.781, -50.438],
                                  [-34.116, -133.867],
                                  [58.672, -37.308],
                                  [-135.632, -34.249],
                                  [-17.902, -70.907],
                                  [-113.796, 2.82],
                                  [140.803, 83.214],
                                  [-48.653, 153.286],
                                  [-8.352, 92],
                                ],
                                o: [
                                  [-99.414, 50.711],
                                  [34.116, 133.867],
                                  [-58.672, 37.308],
                                  [135.632, 34.249],
                                  [18.156, 71.219],
                                  [111.805, -2.391],
                                  [-140.802, -83.214],
                                  [48.282, -153.618],
                                  [8.352, -92],
                                ],
                                v: [
                                  [-117.514, -373.543],
                                  [-161.256, -165.319],
                                  [-250.332, 34.57],
                                  [-260.86, 160.519],
                                  [-50.881, 283.373],
                                  [107.834, 421.411],
                                  [293.448, 286.566],
                                  [39.109, -91.718],
                                  [324.568, -247.825],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.803, -50.395],
                                  [-34.064, -133.808],
                                  [58.636, -37.344],
                                  [-135.567, -34.256],
                                  [-17.9, -70.876],
                                  [-113.846, 2.843],
                                  [140.714, 83.192],
                                  [-48.616, 153.211],
                                  [-8.361, 92],
                                ],
                                o: [
                                  [-99.398, 50.676],
                                  [34.064, 133.808],
                                  [-58.636, 37.344],
                                  [135.567, 34.256],
                                  [18.16, 71.197],
                                  [111.799, -2.401],
                                  [-140.714, -83.192],
                                  [48.234, -153.552],
                                  [8.361, -92],
                                ],
                                v: [
                                  [-116.805, -373.618],
                                  [-161.703, -165.916],
                                  [-250.103, 34.127],
                                  [-261.75, 160.565],
                                  [-50.836, 283.43],
                                  [108.815, 421.833],
                                  [293.353, 286.478],
                                  [39.803, -92.222],
                                  [323.781, -248.166],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.825, -50.351],
                                  [-34.011, -133.748],
                                  [58.598, -37.381],
                                  [-135.5, -34.263],
                                  [-17.897, -70.846],
                                  [-113.897, 2.866],
                                  [140.624, 83.17],
                                  [-48.578, 153.134],
                                  [-8.371, 92],
                                ],
                                o: [
                                  [-99.382, 50.639],
                                  [34.011, 133.748],
                                  [-58.598, 37.381],
                                  [135.5, 34.263],
                                  [18.165, 71.175],
                                  [111.794, -2.412],
                                  [-140.624, -83.17],
                                  [48.186, -153.485],
                                  [8.371, -92],
                                ],
                                v: [
                                  [-116.154, -373.691],
                                  [-162.214, -166.511],
                                  [-249.934, 33.681],
                                  [-262.638, 160.61],
                                  [-50.795, 283.489],
                                  [109.672, 422.19],
                                  [293.258, 286.451],
                                  [40.437, -92.723],
                                  [322.932, -248.504],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.847, -50.306],
                                  [-33.956, -133.686],
                                  [58.56, -37.419],
                                  [-135.432, -34.27],
                                  [-17.894, -70.814],
                                  [-113.949, 2.89],
                                  [140.532, 83.147],
                                  [-48.539, 153.056],
                                  [-8.381, 92],
                                ],
                                o: [
                                  [-99.365, 50.602],
                                  [33.956, 133.686],
                                  [-58.56, 37.419],
                                  [135.432, 34.27],
                                  [18.169, 71.153],
                                  [111.788, -2.424],
                                  [-140.532, -83.147],
                                  [48.136, -153.416],
                                  [8.381, -92],
                                ],
                                v: [
                                  [-115.503, -373.824],
                                  [-162.664, -167.048],
                                  [-249.706, 33.355],
                                  [-263.524, 160.594],
                                  [-50.634, 283.608],
                                  [110.589, 422.606],
                                  [293.166, 286.364],
                                  [41.014, -93.221],
                                  [322.081, -248.777],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.87, -50.259],
                                  [-33.9, -133.623],
                                  [58.52, -37.458],
                                  [-135.362, -34.277],
                                  [-17.891, -70.781],
                                  [-114.002, 2.914],
                                  [140.437, 83.124],
                                  [-48.499, 152.975],
                                  [-8.392, 92],
                                ],
                                o: [
                                  [-99.347, 50.564],
                                  [33.9, 133.623],
                                  [-58.52, 37.458],
                                  [135.362, 34.277],
                                  [18.174, 71.13],
                                  [111.782, -2.435],
                                  [-140.437, -83.124],
                                  [48.085, -153.345],
                                  [8.392, -92],
                                ],
                                v: [
                                  [-114.851, -373.957],
                                  [-163.175, -167.585],
                                  [-249.477, 32.967],
                                  [-264.471, 160.578],
                                  [-50.413, 283.728],
                                  [111.442, 422.959],
                                  [293.015, 286.276],
                                  [41.655, -93.84],
                                  [321.228, -249.048],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.894, -50.212],
                                  [-33.843, -133.558],
                                  [58.48, -37.498],
                                  [-135.29, -34.285],
                                  [-17.888, -70.748],
                                  [-114.057, 2.939],
                                  [140.34, 83.1],
                                  [-48.458, 152.893],
                                  [-8.402, 92],
                                ],
                                o: [
                                  [-99.329, 50.525],
                                  [33.843, 133.558],
                                  [-58.48, 37.498],
                                  [135.29, 34.285],
                                  [18.179, 71.106],
                                  [111.776, -2.447],
                                  [-140.34, -83.1],
                                  [48.033, -153.273],
                                  [8.402, -92],
                                ],
                                v: [
                                  [-114.257, -374.149],
                                  [-163.749, -168.122],
                                  [-249.251, 32.7],
                                  [-265.352, 160.501],
                                  [-50.135, 283.847],
                                  [112.295, 423.247],
                                  [292.926, 286.19],
                                  [42.176, -94.391],
                                  [320.375, -249.254],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.918, -50.163],
                                  [-33.784, -133.492],
                                  [58.439, -37.538],
                                  [-135.216, -34.293],
                                  [-17.885, -70.714],
                                  [-114.112, 2.964],
                                  [140.24, 83.076],
                                  [-48.416, 152.808],
                                  [-8.413, 92],
                                ],
                                o: [
                                  [-99.311, 50.485],
                                  [33.784, 133.492],
                                  [-58.439, 37.538],
                                  [135.216, 34.293],
                                  [18.184, 71.082],
                                  [111.77, -2.459],
                                  [-140.24, -83.076],
                                  [47.979, -153.199],
                                  [8.413, -92],
                                ],
                                v: [
                                  [-113.723, -374.341],
                                  [-164.263, -168.598],
                                  [-248.963, 32.429],
                                  [-266.294, 160.363],
                                  [-49.858, 283.966],
                                  [113.145, 423.593],
                                  [292.839, 286.042],
                                  [42.761, -95.065],
                                  [319.459, -249.394],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.943, -50.113],
                                  [-33.724, -133.424],
                                  [58.396, -37.58],
                                  [-135.141, -34.301],
                                  [-17.882, -70.679],
                                  [-114.17, 2.991],
                                  [140.138, 83.051],
                                  [-48.373, 152.722],
                                  [-8.424, 92],
                                ],
                                o: [
                                  [-99.292, 50.444],
                                  [33.724, 133.424],
                                  [-58.396, 37.58],
                                  [135.141, 34.301],
                                  [18.189, 71.057],
                                  [111.764, -2.472],
                                  [-140.138, -83.051],
                                  [47.925, -153.123],
                                  [8.425, -92],
                                ],
                                v: [
                                  [-113.187, -374.592],
                                  [-164.778, -169.136],
                                  [-248.736, 32.156],
                                  [-267.233, 160.226],
                                  [-49.464, 284.083],
                                  [113.932, 423.875],
                                  [292.693, 285.956],
                                  [43.289, -95.734],
                                  [318.542, -249.592],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.969, -50.062],
                                  [-33.663, -133.354],
                                  [58.353, -37.623],
                                  [-135.063, -34.309],
                                  [-17.879, -70.644],
                                  [-114.228, 3.017],
                                  [140.034, 83.025],
                                  [-48.329, 152.633],
                                  [-8.436, 92],
                                ],
                                o: [
                                  [-99.273, 50.401],
                                  [33.663, 133.354],
                                  [-58.353, 37.623],
                                  [135.063, 34.309],
                                  [18.194, 71.031],
                                  [111.758, -2.484],
                                  [-140.034, -83.025],
                                  [47.869, -153.045],
                                  [8.436, -92],
                                ],
                                v: [
                                  [-112.712, -374.842],
                                  [-165.293, -169.736],
                                  [-248.451, 32.003],
                                  [-268.17, 160.09],
                                  [-49.009, 284.261],
                                  [114.718, 424.155],
                                  [292.612, 285.87],
                                  [43.759, -96.399],
                                  [317.624, -249.726],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.995, -50.01],
                                  [-33.6, -133.282],
                                  [58.309, -37.667],
                                  [-134.984, -34.317],
                                  [-17.876, -70.607],
                                  [-114.288, 3.045],
                                  [139.927, 82.999],
                                  [-48.284, 152.542],
                                  [-8.448, 92],
                                ],
                                o: [
                                  [-99.254, 50.358],
                                  [33.6, 133.282],
                                  [-58.309, 37.667],
                                  [134.984, 34.317],
                                  [18.199, 71.005],
                                  [111.751, -2.497],
                                  [-139.927, -82.999],
                                  [47.811, -152.965],
                                  [8.448, -92],
                                ],
                                v: [
                                  [-112.295, -375.151],
                                  [-165.81, -170.276],
                                  [-248.165, 31.848],
                                  [-269.044, 159.892],
                                  [-48.56, 284.44],
                                  [115.502, 424.431],
                                  [292.533, 285.724],
                                  [44.232, -97.123],
                                  [316.766, -249.916],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.022, -49.957],
                                  [-33.535, -133.209],
                                  [58.263, -37.711],
                                  [-134.903, -34.326],
                                  [-17.872, -70.57],
                                  [-114.35, 3.073],
                                  [139.817, 82.972],
                                  [-48.238, 152.45],
                                  [-8.46, 92],
                                ],
                                o: [
                                  [-99.234, 50.314],
                                  [33.535, 133.209],
                                  [-58.263, 37.711],
                                  [134.903, 34.326],
                                  [18.204, 70.978],
                                  [111.745, -2.511],
                                  [-139.817, -82.972],
                                  [47.752, -152.884],
                                  [8.46, -92],
                                ],
                                v: [
                                  [-111.876, -375.459],
                                  [-166.266, -170.877],
                                  [-247.88, 31.75],
                                  [-269.976, 159.634],
                                  [-47.991, 284.617],
                                  [116.221, 424.643],
                                  [292.455, 285.64],
                                  [44.647, -97.903],
                                  [315.846, -250.042],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.049, -49.902],
                                  [-33.469, -133.135],
                                  [58.216, -37.757],
                                  [-134.82, -34.334],
                                  [-17.869, -70.531],
                                  [-114.413, 3.102],
                                  [139.705, 82.944],
                                  [-48.19, 152.354],
                                  [-8.472, 92],
                                ],
                                o: [
                                  [-99.213, 50.269],
                                  [33.469, 133.135],
                                  [-58.216, 37.757],
                                  [134.82, 34.334],
                                  [18.21, 70.951],
                                  [111.738, -2.525],
                                  [-139.705, -82.944],
                                  [47.692, -152.8],
                                  [8.472, -92],
                                ],
                                v: [
                                  [-111.515, -375.766],
                                  [-166.724, -171.478],
                                  [-247.535, 31.651],
                                  [-270.908, 159.377],
                                  [-47.426, 284.792],
                                  [116.941, 424.914],
                                  [292.38, 285.556],
                                  [45.067, -98.679],
                                  [314.924, -250.225],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.077, -49.846],
                                  [-33.401, -133.058],
                                  [58.169, -37.804],
                                  [-134.735, -34.343],
                                  [-17.865, -70.492],
                                  [-114.478, 3.131],
                                  [139.59, 82.916],
                                  [-48.142, 152.257],
                                  [-8.485, 92],
                                ],
                                o: [
                                  [-99.192, 50.223],
                                  [33.401, 133.058],
                                  [-58.169, 37.804],
                                  [134.735, 34.343],
                                  [18.215, 70.923],
                                  [111.731, -2.539],
                                  [-139.59, -82.916],
                                  [47.63, -152.715],
                                  [8.485, -92],
                                ],
                                v: [
                                  [-111.212, -376.134],
                                  [-167.182, -172.08],
                                  [-247.252, 31.609],
                                  [-271.774, 159.122],
                                  [-46.743, 285.028],
                                  [117.597, 425.06],
                                  [292.309, 285.41],
                                  [45.489, -99.453],
                                  [314.003, -250.342],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.106, -49.788],
                                  [-33.332, -132.979],
                                  [58.12, -37.852],
                                  [-134.648, -34.353],
                                  [-17.862, -70.452],
                                  [-114.544, 3.161],
                                  [139.473, 82.887],
                                  [-48.092, 152.157],
                                  [-8.498, 92],
                                ],
                                o: [
                                  [-99.171, 50.175],
                                  [33.332, 132.979],
                                  [-58.12, 37.852],
                                  [134.648, 34.352],
                                  [18.221, 70.894],
                                  [111.724, -2.553],
                                  [-139.473, -82.887],
                                  [47.567, -152.627],
                                  [8.498, -92],
                                ],
                                v: [
                                  [-110.97, -376.559],
                                  [-167.641, -172.682],
                                  [-246.909, 31.626],
                                  [-272.7, 158.807],
                                  [-46.063, 285.322],
                                  [118.251, 425.263],
                                  [292.238, 285.328],
                                  [45.792, -100.284],
                                  [313.139, -250.516],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.135, -49.729],
                                  [-33.261, -132.899],
                                  [58.07, -37.902],
                                  [-134.558, -34.362],
                                  [-17.858, -70.411],
                                  [-114.611, 3.192],
                                  [139.352, 82.858],
                                  [-48.042, 152.055],
                                  [-8.511, 92],
                                ],
                                o: [
                                  [-99.148, 50.127],
                                  [33.261, 132.899],
                                  [-58.07, 37.902],
                                  [134.558, 34.362],
                                  [18.227, 70.865],
                                  [111.716, -2.568],
                                  [-139.352, -82.858],
                                  [47.502, -152.537],
                                  [8.511, -92],
                                ],
                                v: [
                                  [-110.724, -376.983],
                                  [-168.102, -173.285],
                                  [-246.569, 31.7],
                                  [-273.562, 158.493],
                                  [-45.388, 285.554],
                                  [118.903, 425.403],
                                  [292.231, 285.183],
                                  [46.162, -101.111],
                                  [312.215, -250.626],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.165, -49.669],
                                  [-33.188, -132.817],
                                  [58.019, -37.952],
                                  [-134.467, -34.372],
                                  [-17.854, -70.368],
                                  [-114.681, 3.224],
                                  [139.229, 82.827],
                                  [-47.99, 151.95],
                                  [-8.525, 92],
                                ],
                                o: [
                                  [-99.126, 50.077],
                                  [33.188, 132.817],
                                  [-58.019, 37.952],
                                  [134.467, 34.372],
                                  [18.233, 70.834],
                                  [111.709, -2.583],
                                  [-139.229, -82.827],
                                  [47.436, -152.445],
                                  [8.524, -92],
                                ],
                                v: [
                                  [-110.537, -377.467],
                                  [-168.501, -173.949],
                                  [-246.229, 31.772],
                                  [-274.422, 158.181],
                                  [-44.658, 285.907],
                                  [119.552, 425.54],
                                  [292.164, 285.1],
                                  [46.473, -101.993],
                                  [311.291, -250.732],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.196, -49.607],
                                  [-33.114, -132.732],
                                  [57.966, -38.004],
                                  [-134.374, -34.381],
                                  [-17.85, -70.325],
                                  [-114.752, 3.256],
                                  [139.102, 82.796],
                                  [-47.936, 151.843],
                                  [-8.538, 92],
                                ],
                                o: [
                                  [-99.103, 50.026],
                                  [33.114, 132.732],
                                  [-57.966, 38.004],
                                  [134.374, 34.381],
                                  [18.239, 70.804],
                                  [111.701, -2.598],
                                  [-139.102, -82.796],
                                  [47.368, -152.351],
                                  [8.538, -92],
                                ],
                                v: [
                                  [-110.407, -377.948],
                                  [-168.9, -174.555],
                                  [-245.95, 31.84],
                                  [-275.279, 157.808],
                                  [-43.871, 286.256],
                                  [120.138, 425.673],
                                  [292.161, 284.957],
                                  [46.728, -102.873],
                                  [310.363, -250.894],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.228, -49.544],
                                  [-33.038, -132.646],
                                  [57.913, -38.057],
                                  [-134.278, -34.391],
                                  [-17.847, -70.281],
                                  [-114.824, 3.289],
                                  [138.973, 82.764],
                                  [-47.882, 151.733],
                                  [-8.553, 92],
                                ],
                                o: [
                                  [-99.079, 49.974],
                                  [33.038, 132.646],
                                  [-57.913, 38.057],
                                  [134.278, 34.391],
                                  [18.246, 70.772],
                                  [111.693, -2.614],
                                  [-138.973, -82.764],
                                  [47.299, -152.255],
                                  [8.553, -92],
                                ],
                                v: [
                                  [-110.274, -378.489],
                                  [-169.303, -175.22],
                                  [-245.609, 31.904],
                                  [-276.135, 157.437],
                                  [-43.088, 286.604],
                                  [120.662, 425.745],
                                  [292.22, 284.875],
                                  [46.986, -103.748],
                                  [309.436, -250.99],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.26, -49.479],
                                  [-32.96, -132.558],
                                  [57.858, -38.111],
                                  [-134.18, -34.402],
                                  [-17.842, -70.236],
                                  [-114.899, 3.323],
                                  [138.841, 82.732],
                                  [-47.826, 151.621],
                                  [-8.567, 92],
                                ],
                                o: [
                                  [-99.055, 49.921],
                                  [32.96, 132.558],
                                  [-57.858, 38.111],
                                  [134.18, 34.402],
                                  [18.252, 70.74],
                                  [111.685, -2.63],
                                  [-138.841, -82.732],
                                  [47.227, -152.156],
                                  [8.567, -92],
                                ],
                                v: [
                                  [-110.14, -379.027],
                                  [-169.644, -175.826],
                                  [-245.331, 32.026],
                                  [-276.986, 157.009],
                                  [-42.25, 287.01],
                                  [121.245, 425.812],
                                  [292.221, 284.732],
                                  [47.188, -104.68],
                                  [308.568, -251.081],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.293, -49.413],
                                  [-32.88, -132.468],
                                  [57.801, -38.166],
                                  [-134.079, -34.412],
                                  [-17.838, -70.189],
                                  [-114.975, 3.358],
                                  [138.705, 82.699],
                                  [-47.769, 151.506],
                                  [-8.582, 92],
                                ],
                                o: [
                                  [-99.03, 49.866],
                                  [32.88, 132.467],
                                  [-57.801, 38.166],
                                  [134.079, 34.412],
                                  [18.259, 70.707],
                                  [111.677, -2.647],
                                  [-138.705, -82.698],
                                  [47.155, -152.055],
                                  [8.582, -92],
                                ],
                                v: [
                                  [-110.005, -379.621],
                                  [-169.986, -176.494],
                                  [-244.992, 32.143],
                                  [-277.775, 156.581],
                                  [-41.419, 287.413],
                                  [121.763, 425.878],
                                  [292.224, 284.589],
                                  [47.394, -105.605],
                                  [307.638, -251.168],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.327, -49.346],
                                  [-32.798, -132.375],
                                  [57.744, -38.223],
                                  [-133.977, -34.423],
                                  [-17.834, -70.142],
                                  [-115.053, 3.394],
                                  [138.567, 82.664],
                                  [-47.71, 151.388],
                                  [-8.597, 92],
                                ],
                                o: [
                                  [-99.005, 49.81],
                                  [32.798, 132.375],
                                  [-57.744, 38.223],
                                  [133.977, 34.423],
                                  [18.265, 70.673],
                                  [111.668, -2.664],
                                  [-138.567, -82.664],
                                  [47.08, -151.952],
                                  [8.597, -92],
                                ],
                                v: [
                                  [-109.869, -380.21],
                                  [-170.33, -177.101],
                                  [-244.716, 32.258],
                                  [-278.562, 156.157],
                                  [-40.591, 287.815],
                                  [122.219, 425.88],
                                  [292.289, 284.507],
                                  [47.545, -106.526],
                                  [306.707, -251.312],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.362, -49.276],
                                  [-32.714, -132.28],
                                  [57.685, -38.281],
                                  [-133.872, -34.434],
                                  [-17.83, -70.093],
                                  [-115.132, 3.43],
                                  [138.425, 82.63],
                                  [-47.651, 151.267],
                                  [-8.613, 92],
                                ],
                                o: [
                                  [-98.979, 49.753],
                                  [32.714, 132.28],
                                  [-57.685, 38.281],
                                  [133.872, 34.434],
                                  [18.272, 70.638],
                                  [111.659, -2.681],
                                  [-138.425, -82.63],
                                  [47.004, -151.846],
                                  [8.613, -92],
                                ],
                                v: [
                                  [-109.732, -380.856],
                                  [-170.674, -177.77],
                                  [-244.438, 32.367],
                                  [-279.285, 155.734],
                                  [-39.71, 288.274],
                                  [122.733, 425.879],
                                  [292.357, 284.365],
                                  [47.64, -107.503],
                                  [305.776, -251.389],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.397, -49.205],
                                  [-32.629, -132.183],
                                  [57.624, -38.341],
                                  [-133.764, -34.445],
                                  [-17.825, -70.044],
                                  [-115.214, 3.467],
                                  [138.279, 82.594],
                                  [-47.589, 151.144],
                                  [-8.629, 92],
                                ],
                                o: [
                                  [-98.952, 49.694],
                                  [32.629, 132.183],
                                  [-57.624, 38.341],
                                  [133.764, 34.445],
                                  [18.28, 70.603],
                                  [111.651, -2.699],
                                  [-138.279, -82.594],
                                  [46.926, -151.738],
                                  [8.629, -92],
                                ],
                                v: [
                                  [-109.653, -381.558],
                                  [-171.016, -178.38],
                                  [-244.222, 32.533],
                                  [-280.007, 155.254],
                                  [-38.834, 288.729],
                                  [123.187, 425.875],
                                  [292.489, 284.223],
                                  [47.799, -108.471],
                                  [304.844, -251.463],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.434, -49.133],
                                  [-32.541, -132.084],
                                  [57.563, -38.402],
                                  [-133.654, -34.457],
                                  [-17.821, -69.993],
                                  [-115.298, 3.505],
                                  [138.131, 82.557],
                                  [-47.527, 151.018],
                                  [-8.645, 92],
                                ],
                                o: [
                                  [-98.925, 49.634],
                                  [32.541, 132.084],
                                  [-57.563, 38.402],
                                  [133.654, 34.457],
                                  [18.287, 70.566],
                                  [111.642, -2.717],
                                  [-138.131, -82.557],
                                  [46.846, -151.627],
                                  [8.645, -92],
                                ],
                                v: [
                                  [-109.574, -382.254],
                                  [-171.302, -179.05],
                                  [-243.944, 32.697],
                                  [-280.724, 154.775],
                                  [-37.905, 289.183],
                                  [123.576, 425.866],
                                  [292.561, 284.083],
                                  [47.845, -109.434],
                                  [303.969, -251.471],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.471, -49.058],
                                  [-32.451, -131.983],
                                  [57.499, -38.464],
                                  [-133.541, -34.469],
                                  [-17.816, -69.941],
                                  [-115.383, 3.544],
                                  [137.979, 82.52],
                                  [-47.463, 150.889],
                                  [-8.662, 92],
                                ],
                                o: [
                                  [-98.897, 49.573],
                                  [32.451, 131.983],
                                  [-57.499, 38.464],
                                  [133.541, 34.469],
                                  [18.294, 70.529],
                                  [111.632, -2.735],
                                  [-137.979, -82.52],
                                  [46.764, -151.514],
                                  [8.662, -92],
                                ],
                                v: [
                                  [-109.493, -382.946],
                                  [-171.586, -179.661],
                                  [-243.73, 32.854],
                                  [-281.379, 154.3],
                                  [-36.981, 289.694],
                                  [124.026, 425.795],
                                  [292.695, 284.002],
                                  [47.894, -110.388],
                                  [303.034, -251.534],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.509, -48.982],
                                  [-32.36, -131.879],
                                  [57.435, -38.527],
                                  [-133.426, -34.481],
                                  [-17.811, -69.887],
                                  [-115.471, 3.584],
                                  [137.823, 82.482],
                                  [-47.397, 150.756],
                                  [-8.679, 92],
                                ],
                                o: [
                                  [-98.868, 49.51],
                                  [32.36, 131.879],
                                  [-57.435, 38.527],
                                  [133.426, 34.481],
                                  [18.302, 70.491],
                                  [111.623, -2.754],
                                  [-137.823, -82.482],
                                  [46.68, -151.398],
                                  [8.679, -92],
                                ],
                                v: [
                                  [-109.411, -383.695],
                                  [-171.812, -180.274],
                                  [-243.514, 33.009],
                                  [-282.032, 153.767],
                                  [-36.063, 290.14],
                                  [124.413, 425.721],
                                  [292.832, 283.861],
                                  [47.888, -111.399],
                                  [302.098, -251.593],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.548, -48.904],
                                  [-32.266, -131.772],
                                  [57.369, -38.593],
                                  [-133.308, -34.493],
                                  [-17.807, -69.833],
                                  [-115.56, 3.625],
                                  [137.664, 82.443],
                                  [-47.33, 150.621],
                                  [-8.697, 92],
                                ],
                                o: [
                                  [-98.839, 49.446],
                                  [32.266, 131.772],
                                  [-57.369, 38.593],
                                  [133.308, 34.493],
                                  [18.31, 70.452],
                                  [111.613, -2.774],
                                  [-137.664, -82.443],
                                  [46.595, -151.279],
                                  [8.697, -92],
                                ],
                                v: [
                                  [-109.389, -384.495],
                                  [-172.099, -180.887],
                                  [-243.296, 33.217],
                                  [-282.623, 153.295],
                                  [-35.151, 290.644],
                                  [124.801, 425.64],
                                  [293.031, 283.721],
                                  [47.891, -112.401],
                                  [301.221, -251.646],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.588, -48.825],
                                  [-32.17, -131.664],
                                  [57.301, -38.659],
                                  [-133.187, -34.506],
                                  [-17.802, -69.777],
                                  [-115.652, 3.667],
                                  [137.5, 82.402],
                                  [-47.261, 150.483],
                                  [-8.714, 92],
                                ],
                                o: [
                                  [-98.809, 49.38],
                                  [32.17, 131.664],
                                  [-57.301, 38.659],
                                  [133.187, 34.506],
                                  [18.318, 70.412],
                                  [111.603, -2.794],
                                  [-137.5, -82.402],
                                  [46.507, -151.158],
                                  [8.714, -92],
                                ],
                                v: [
                                  [-109.366, -385.292],
                                  [-172.324, -181.503],
                                  [-243.142, 33.423],
                                  [-283.212, 152.768],
                                  [-34.189, 291.143],
                                  [125.126, 425.56],
                                  [293.169, 283.523],
                                  [47.897, -113.395],
                                  [300.282, -251.634],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.628, -48.743],
                                  [-32.071, -131.552],
                                  [57.232, -38.728],
                                  [-133.064, -34.519],
                                  [-17.796, -69.72],
                                  [-115.745, 3.71],
                                  [137.334, 82.361],
                                  [-47.191, 150.341],
                                  [-8.733, 92],
                                ],
                                o: [
                                  [-98.779, 49.313],
                                  [32.071, 131.552],
                                  [-57.232, 38.728],
                                  [133.064, 34.519],
                                  [18.326, 70.372],
                                  [111.593, -2.814],
                                  [-137.334, -82.361],
                                  [46.417, -151.033],
                                  [8.733, -92],
                                ],
                                v: [
                                  [-109.343, -386.082],
                                  [-172.491, -182.118],
                                  [-242.986, 33.625],
                                  [-283.737, 152.243],
                                  [-33.231, 291.64],
                                  [125.45, 425.413],
                                  [293.372, 283.383],
                                  [47.79, -114.383],
                                  [299.402, -251.677],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.67, -48.66],
                                  [-31.971, -131.439],
                                  [57.161, -38.797],
                                  [-132.938, -34.532],
                                  [-17.791, -69.662],
                                  [-115.841, 3.754],
                                  [137.163, 82.32],
                                  [-47.119, 150.196],
                                  [-8.751, 92],
                                ],
                                o: [
                                  [-98.747, 49.244],
                                  [31.971, 131.439],
                                  [-57.161, 38.797],
                                  [132.938, 34.532],
                                  [18.334, 70.33],
                                  [111.582, -2.835],
                                  [-137.163, -82.319],
                                  [46.326, -150.906],
                                  [8.752, -92],
                                ],
                                v: [
                                  [-109.377, -386.865],
                                  [-172.722, -182.675],
                                  [-242.831, 33.821],
                                  [-284.262, 151.663],
                                  [-32.343, 292.132],
                                  [125.774, 425.266],
                                  [293.638, 283.244],
                                  [47.748, -115.364],
                                  [298.518, -251.654],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.713, -48.575],
                                  [-31.868, -131.322],
                                  [57.088, -38.869],
                                  [-132.808, -34.546],
                                  [-17.786, -69.602],
                                  [-115.939, 3.798],
                                  [136.989, 82.277],
                                  [-47.046, 150.048],
                                  [-8.771, 92],
                                ],
                                o: [
                                  [-98.715, 49.174],
                                  [31.868, 131.322],
                                  [-57.088, 38.869],
                                  [132.808, 34.546],
                                  [18.343, 70.287],
                                  [111.572, -2.856],
                                  [-136.989, -82.277],
                                  [46.232, -150.776],
                                  [8.771, -92],
                                ],
                                v: [
                                  [-109.41, -387.701],
                                  [-172.893, -183.294],
                                  [-242.736, 34.073],
                                  [-284.724, 151.143],
                                  [-31.399, 292.679],
                                  [126.037, 425.114],
                                  [293.843, 283.105],
                                  [47.594, -116.397],
                                  [297.576, -251.626],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.756, -48.487],
                                  [-31.763, -131.203],
                                  [57.014, -38.942],
                                  [-132.676, -34.56],
                                  [-17.78, -69.541],
                                  [-116.04, 3.844],
                                  [136.81, 82.233],
                                  [-46.97, 149.897],
                                  [-8.79, 92],
                                ],
                                o: [
                                  [-98.683, 49.102],
                                  [31.763, 131.203],
                                  [-57.014, 38.942],
                                  [132.676, 34.56],
                                  [18.351, 70.244],
                                  [111.561, -2.878],
                                  [-136.81, -82.233],
                                  [46.136, -150.643],
                                  [8.79, -92],
                                ],
                                v: [
                                  [-109.44, -388.532],
                                  [-173.003, -183.856],
                                  [-242.642, 34.259],
                                  [-285.183, 150.631],
                                  [-30.465, 293.163],
                                  [126.357, 424.898],
                                  [294.11, 282.907],
                                  [47.503, -117.36],
                                  [296.692, -251.592],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.801, -48.398],
                                  [-31.655, -131.081],
                                  [56.938, -39.017],
                                  [-132.541, -34.574],
                                  [-17.775, -69.479],
                                  [-116.142, 3.891],
                                  [136.627, 82.188],
                                  [-46.893, 149.742],
                                  [-8.81, 92],
                                ],
                                o: [
                                  [-98.649, 49.028],
                                  [31.655, 131.081],
                                  [-56.938, 39.017],
                                  [132.541, 34.574],
                                  [18.36, 70.199],
                                  [111.55, -2.9],
                                  [-136.627, -82.188],
                                  [46.038, -150.507],
                                  [8.81, -92],
                                ],
                                v: [
                                  [-109.531, -389.417],
                                  [-173.178, -184.416],
                                  [-242.545, 34.503],
                                  [-285.583, 150.059],
                                  [-29.479, 293.704],
                                  [126.618, 424.681],
                                  [294.38, 282.767],
                                  [47.303, -118.375],
                                  [295.807, -251.554],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.847, -48.307],
                                  [-31.545, -130.957],
                                  [56.861, -39.093],
                                  [-132.403, -34.589],
                                  [-17.769, -69.415],
                                  [-116.247, 3.939],
                                  [136.441, 82.142],
                                  [-46.815, 149.583],
                                  [-8.831, 92],
                                ],
                                o: [
                                  [-98.615, 48.953],
                                  [31.545, 130.957],
                                  [-56.861, 39.093],
                                  [132.403, 34.589],
                                  [18.369, 70.153],
                                  [111.538, -2.923],
                                  [-136.441, -82.142],
                                  [45.938, -150.368],
                                  [8.831, -92],
                                ],
                                v: [
                                  [-109.62, -390.231],
                                  [-173.292, -184.921],
                                  [-242.509, 34.738],
                                  [-285.919, 149.492],
                                  [-28.561, 294.179],
                                  [126.878, 424.46],
                                  [294.711, 282.57],
                                  [47.109, -119.323],
                                  [294.977, -251.512],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.893, -48.214],
                                  [-31.433, -130.829],
                                  [56.781, -39.171],
                                  [-132.261, -34.603],
                                  [-17.763, -69.349],
                                  [-116.354, 3.988],
                                  [136.25, 82.095],
                                  [-46.734, 149.421],
                                  [-8.852, 92],
                                ],
                                o: [
                                  [-98.58, 48.876],
                                  [31.433, 130.829],
                                  [-56.781, 39.171],
                                  [132.261, 34.603],
                                  [18.379, 70.107],
                                  [111.527, -2.947],
                                  [-136.25, -82.095],
                                  [45.835, -150.226],
                                  [8.852, -92],
                                ],
                                v: [
                                  [-109.767, -391.1],
                                  [-173.409, -185.43],
                                  [-242.473, 35.03],
                                  [-286.253, 148.929],
                                  [-27.652, 294.708],
                                  [127.076, 424.238],
                                  [295.042, 282.371],
                                  [46.921, -120.321],
                                  [294.089, -251.46],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.941, -48.118],
                                  [-31.317, -130.699],
                                  [56.7, -39.251],
                                  [-132.116, -34.619],
                                  [-17.757, -69.283],
                                  [-116.464, 4.038],
                                  [136.054, 82.047],
                                  [-46.652, 149.255],
                                  [-8.873, 92],
                                ],
                                o: [
                                  [-98.544, 48.797],
                                  [31.317, 130.699],
                                  [-56.7, 39.251],
                                  [132.116, 34.619],
                                  [18.388, 70.059],
                                  [111.515, -2.971],
                                  [-136.054, -82.047],
                                  [45.73, -150.08],
                                  [8.873, -92],
                                ],
                                v: [
                                  [-109.913, -391.959],
                                  [-173.465, -185.937],
                                  [-242.437, 35.257],
                                  [-286.525, 148.369],
                                  [-26.81, 295.174],
                                  [127.275, 424.012],
                                  [295.374, 282.175],
                                  [46.683, -121.311],
                                  [293.256, -251.408],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.99, -48.02],
                                  [-31.2, -130.565],
                                  [56.617, -39.333],
                                  [-131.968, -34.634],
                                  [-17.751, -69.214],
                                  [-116.576, 4.089],
                                  [135.854, 81.998],
                                  [-46.568, 149.086],
                                  [-8.895, 92],
                                ],
                                o: [
                                  [-98.508, 48.717],
                                  [31.2, 130.565],
                                  [-56.617, 39.333],
                                  [131.968, 34.634],
                                  [18.398, 70.01],
                                  [111.503, -2.995],
                                  [-135.854, -81.998],
                                  [45.623, -149.931],
                                  [8.895, -92],
                                ],
                                v: [
                                  [-110.119, -392.812],
                                  [-173.524, -186.448],
                                  [-242.461, 35.481],
                                  [-286.738, 147.875],
                                  [-25.917, 295.695],
                                  [127.472, 423.723],
                                  [295.709, 281.978],
                                  [46.391, -122.29],
                                  [292.363, -251.287],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.04, -47.921],
                                  [-31.079, -130.429],
                                  [56.533, -39.416],
                                  [-131.817, -34.65],
                                  [-17.745, -69.144],
                                  [-116.691, 4.142],
                                  [135.65, 81.948],
                                  [-46.482, 148.912],
                                  [-8.918, 92],
                                ],
                                o: [
                                  [-98.47, 48.635],
                                  [31.079, 130.429],
                                  [-56.533, 39.417],
                                  [131.817, 34.65],
                                  [18.408, 69.96],
                                  [111.49, -3.02],
                                  [-135.65, -81.948],
                                  [45.513, -149.779],
                                  [8.918, -92],
                                ],
                                v: [
                                  [-110.319, -393.656],
                                  [-173.584, -186.902],
                                  [-242.541, 35.756],
                                  [-286.948, 147.321],
                                  [-25.033, 296.15],
                                  [127.668, 423.435],
                                  [296.105, 281.781],
                                  [46.11, -123.259],
                                  [291.527, -251.16],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.091, -47.819],
                                  [-30.956, -130.29],
                                  [56.446, -39.502],
                                  [-131.663, -34.666],
                                  [-17.739, -69.073],
                                  [-116.809, 4.195],
                                  [135.441, 81.896],
                                  [-46.393, 148.735],
                                  [-8.941, 92],
                                ],
                                o: [
                                  [-98.432, 48.55],
                                  [30.956, 130.29],
                                  [-56.446, 39.502],
                                  [131.663, 34.666],
                                  [18.418, 69.909],
                                  [111.477, -3.045],
                                  [-135.441, -81.896],
                                  [45.4, -149.623],
                                  [8.941, -92],
                                ],
                                v: [
                                  [-110.519, -394.552],
                                  [-173.588, -187.36],
                                  [-242.561, 36.028],
                                  [-287.099, 146.716],
                                  [-24.217, 296.658],
                                  [127.865, 423.144],
                                  [296.5, 281.582],
                                  [45.834, -124.16],
                                  [290.749, -251.089],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.143, -47.714],
                                  [-30.83, -130.147],
                                  [56.357, -39.589],
                                  [-131.504, -34.683],
                                  [-17.732, -69],
                                  [-116.929, 4.25],
                                  [135.227, 81.844],
                                  [-46.303, 148.553],
                                  [-8.964, 92],
                                ],
                                o: [
                                  [-98.393, 48.464],
                                  [30.83, 130.147],
                                  [-56.357, 39.589],
                                  [131.504, 34.683],
                                  [18.429, 69.857],
                                  [111.464, -3.071],
                                  [-135.227, -81.844],
                                  [45.286, -149.464],
                                  [8.964, -92],
                                ],
                                v: [
                                  [-110.778, -395.378],
                                  [-173.592, -187.817],
                                  [-242.703, 36.238],
                                  [-287.19, 146.173],
                                  [-23.41, 297.105],
                                  [128.001, 422.849],
                                  [296.957, 281.386],
                                  [45.508, -125.107],
                                  [289.907, -250.953],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.196, -47.608],
                                  [-30.702, -130.001],
                                  [56.266, -39.679],
                                  [-131.343, -34.7],
                                  [-17.725, -68.925],
                                  [-117.051, 4.306],
                                  [135.009, 81.79],
                                  [-46.211, 148.368],
                                  [-8.988, 92],
                                ],
                                o: [
                                  [-98.353, 48.376],
                                  [30.702, 130.002],
                                  [-56.266, 39.679],
                                  [131.343, 34.7],
                                  [18.439, 69.804],
                                  [111.451, -3.098],
                                  [-135.009, -81.79],
                                  [45.168, -149.301],
                                  [8.988, -92],
                                ],
                                v: [
                                  [-111.093, -396.197],
                                  [-173.594, -188.22],
                                  [-242.842, 36.497],
                                  [-287.219, 145.638],
                                  [-22.612, 297.541],
                                  [128.139, 422.554],
                                  [297.415, 281.129],
                                  [45.135, -126.048],
                                  [289.123, -250.813],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.251, -47.498],
                                  [-30.57, -129.853],
                                  [56.174, -39.77],
                                  [-131.177, -34.717],
                                  [-17.719, -68.849],
                                  [-117.177, 4.363],
                                  [134.786, 81.735],
                                  [-46.117, 148.178],
                                  [-9.013, 92],
                                ],
                                o: [
                                  [-98.312, 48.286],
                                  [30.57, 129.853],
                                  [-56.174, 39.77],
                                  [131.177, 34.717],
                                  [18.45, 69.749],
                                  [111.437, -3.125],
                                  [-134.786, -81.735],
                                  [45.048, -149.135],
                                  [9.013, -92],
                                ],
                                v: [
                                  [-111.345, -397.064],
                                  [-173.599, -188.569],
                                  [-242.976, 36.753],
                                  [-287.245, 145.103],
                                  [-21.883, 297.976],
                                  [128.276, 422.199],
                                  [297.871, 280.873],
                                  [44.768, -126.975],
                                  [288.336, -250.605],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.307, -47.387],
                                  [-30.435, -129.7],
                                  [56.079, -39.863],
                                  [-131.008, -34.735],
                                  [-17.712, -68.771],
                                  [-117.305, 4.422],
                                  [134.558, 81.679],
                                  [-46.021, 147.985],
                                  [-9.038, 92],
                                ],
                                o: [
                                  [-98.27, 48.194],
                                  [30.435, 129.7],
                                  [-56.079, 39.863],
                                  [131.008, 34.735],
                                  [18.461, 69.693],
                                  [111.423, -3.153],
                                  [-134.558, -81.679],
                                  [44.926, -148.965],
                                  [9.038, -92],
                                ],
                                v: [
                                  [-111.716, -397.864],
                                  [-173.547, -188.976],
                                  [-243.172, 36.944],
                                  [-287.215, 144.573],
                                  [-21.164, 298.403],
                                  [128.411, 421.899],
                                  [298.388, 280.673],
                                  [44.41, -127.829],
                                  [287.607, -250.452],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.364, -47.273],
                                  [-30.298, -129.545],
                                  [55.982, -39.959],
                                  [-130.835, -34.753],
                                  [-17.705, -68.691],
                                  [-117.436, 4.482],
                                  [134.324, 81.622],
                                  [-45.923, 147.787],
                                  [-9.064, 92],
                                ],
                                o: [
                                  [-98.227, 48.1],
                                  [30.298, 129.545],
                                  [-55.982, 39.959],
                                  [130.835, 34.753],
                                  [18.473, 69.636],
                                  [111.409, -3.182],
                                  [-134.324, -81.622],
                                  [44.8, -148.791],
                                  [9.064, -92],
                                ],
                                v: [
                                  [-112.08, -398.653],
                                  [-173.436, -189.331],
                                  [-243.368, 37.132],
                                  [-287.124, 144.051],
                                  [-20.455, 298.825],
                                  [128.548, 421.54],
                                  [298.906, 280.418],
                                  [44.003, -128.735],
                                  [286.872, -250.297],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.422, -47.156],
                                  [-30.157, -129.385],
                                  [55.883, -40.057],
                                  [-130.659, -34.772],
                                  [-17.697, -68.609],
                                  [-117.57, 4.543],
                                  [134.086, 81.563],
                                  [-45.822, 147.584],
                                  [-9.09, 92],
                                ],
                                o: [
                                  [-98.184, 48.004],
                                  [30.157, 129.385],
                                  [-55.883, 40.057],
                                  [130.659, 34.772],
                                  [18.484, 69.578],
                                  [111.395, -3.211],
                                  [-134.086, -81.563],
                                  [44.672, -148.613],
                                  [9.09, -92],
                                ],
                                v: [
                                  [-112.447, -399.432],
                                  [-173.387, -189.632],
                                  [-243.618, 37.373],
                                  [-286.972, 143.53],
                                  [-19.818, 299.242],
                                  [128.625, 421.182],
                                  [299.422, 280.102],
                                  [43.551, -129.568],
                                  [286.135, -250.076],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.481, -47.037],
                                  [-30.014, -129.223],
                                  [55.782, -40.156],
                                  [-130.478, -34.791],
                                  [-17.69, -68.526],
                                  [-117.707, 4.605],
                                  [133.842, 81.503],
                                  [-45.72, 147.377],
                                  [-9.117, 92],
                                ],
                                o: [
                                  [-98.139, 47.906],
                                  [30.014, 129.223],
                                  [-55.782, 40.156],
                                  [130.478, 34.791],
                                  [18.496, 69.519],
                                  [111.38, -3.241],
                                  [-133.842, -81.503],
                                  [44.541, -148.432],
                                  [9.117, -92],
                                ],
                                v: [
                                  [-112.866, -400.199],
                                  [-173.278, -189.933],
                                  [-243.925, 37.548],
                                  [-286.823, 143.071],
                                  [-19.188, 299.65],
                                  [128.704, 420.824],
                                  [299.999, 279.846],
                                  [43.164, -130.389],
                                  [285.454, -249.848],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.542, -46.916],
                                  [-29.867, -129.056],
                                  [55.678, -40.258],
                                  [-130.294, -34.81],
                                  [-17.682, -68.441],
                                  [-117.847, 4.669],
                                  [133.593, 81.442],
                                  [-45.615, 147.166],
                                  [-9.144, 92],
                                ],
                                o: [
                                  [-98.093, 47.805],
                                  [29.867, 129.057],
                                  [-55.678, 40.258],
                                  [130.294, 34.81],
                                  [18.508, 69.458],
                                  [111.364, -3.271],
                                  [-133.593, -81.442],
                                  [44.407, -148.246],
                                  [9.144, -92],
                                ],
                                v: [
                                  [-113.281, -400.958],
                                  [-173.113, -190.241],
                                  [-244.235, 37.72],
                                  [-286.61, 142.564],
                                  [-18.626, 299.994],
                                  [128.841, 420.462],
                                  [300.572, 279.586],
                                  [42.67, -131.195],
                                  [284.768, -249.613],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.604, -46.791],
                                  [-29.717, -128.887],
                                  [55.572, -40.362],
                                  [-130.105, -34.83],
                                  [-17.674, -68.354],
                                  [-117.99, 4.735],
                                  [133.338, 81.379],
                                  [-45.507, 146.95],
                                  [-9.172, 92],
                                ],
                                o: [
                                  [-98.047, 47.703],
                                  [29.717, 128.887],
                                  [-55.572, 40.362],
                                  [130.105, 34.83],
                                  [18.521, 69.396],
                                  [111.349, -3.302],
                                  [-133.338, -81.379],
                                  [44.27, -148.056],
                                  [9.172, -92],
                                ],
                                v: [
                                  [-113.754, -401.706],
                                  [-173.005, -190.493],
                                  [-244.598, 37.828],
                                  [-286.342, 142.057],
                                  [-18.134, 300.332],
                                  [128.919, 420.047],
                                  [301.206, 279.273],
                                  [42.251, -131.989],
                                  [284.078, -249.376],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.668, -46.664],
                                  [-29.563, -128.713],
                                  [55.464, -40.469],
                                  [-129.913, -34.85],
                                  [-17.667, -68.265],
                                  [-118.136, 4.801],
                                  [133.078, 81.315],
                                  [-45.398, 146.729],
                                  [-9.201, 92],
                                ],
                                o: [
                                  [-97.999, 47.598],
                                  [29.563, 128.713],
                                  [-55.464, 40.469],
                                  [129.913, 34.85],
                                  [18.534, 69.332],
                                  [111.333, -3.334],
                                  [-133.078, -81.315],
                                  [44.13, -147.862],
                                  [9.201, -92],
                                ],
                                v: [
                                  [-114.22, -402.383],
                                  [-172.843, -190.752],
                                  [-244.959, 37.932],
                                  [-286.018, 141.613],
                                  [-17.653, 300.665],
                                  [128.999, 419.687],
                                  [301.835, 278.953],
                                  [41.78, -132.771],
                                  [283.386, -249.131],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.733, -46.534],
                                  [-29.407, -128.535],
                                  [55.354, -40.578],
                                  [-129.716, -34.871],
                                  [-17.658, -68.174],
                                  [-118.286, 4.87],
                                  [132.812, 81.25],
                                  [-45.286, 146.503],
                                  [-9.23, 92],
                                ],
                                o: [
                                  [-97.95, 47.491],
                                  [29.407, 128.535],
                                  [-55.354, 40.578],
                                  [129.716, 34.871],
                                  [18.547, 69.267],
                                  [111.317, -3.367],
                                  [-132.812, -81.25],
                                  [43.987, -147.664],
                                  [9.23, -92],
                                ],
                                v: [
                                  [-114.743, -403.049],
                                  [-172.679, -190.953],
                                  [-245.377, 38.03],
                                  [-285.632, 141.116],
                                  [-17.244, 300.987],
                                  [129.078, 419.266],
                                  [302.466, 278.638],
                                  [41.267, -133.536],
                                  [282.688, -248.884],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.799, -46.401],
                                  [-29.246, -128.354],
                                  [55.241, -40.689],
                                  [-129.514, -34.892],
                                  [-17.65, -68.081],
                                  [-118.439, 4.939],
                                  [132.54, 81.183],
                                  [-45.171, 146.272],
                                  [-9.26, 92],
                                ],
                                o: [
                                  [-97.9, 47.381],
                                  [29.246, 128.354],
                                  [-55.241, 40.689],
                                  [129.514, 34.892],
                                  [18.56, 69.201],
                                  [111.3, -3.4],
                                  [-132.54, -81.183],
                                  [43.841, -147.462],
                                  [9.26, -92],
                                ],
                                v: [
                                  [-115.263, -403.648],
                                  [-172.46, -191.162],
                                  [-245.847, 38.122],
                                  [-285.191, 140.683],
                                  [-16.901, 301.25],
                                  [129.157, 418.909],
                                  [303.097, 278.262],
                                  [40.761, -134.23],
                                  [281.982, -248.63],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.867, -46.266],
                                  [-29.083, -128.169],
                                  [55.126, -40.803],
                                  [-129.309, -34.914],
                                  [-17.642, -67.986],
                                  [-118.595, 5.011],
                                  [132.262, 81.115],
                                  [-45.054, 146.036],
                                  [-9.29, 92],
                                ],
                                o: [
                                  [-97.85, 47.269],
                                  [29.083, 128.169],
                                  [-55.126, 40.803],
                                  [129.309, 34.914],
                                  [18.573, 69.133],
                                  [111.283, -3.434],
                                  [-132.262, -81.115],
                                  [43.692, -147.255],
                                  [9.29, -92],
                                ],
                                v: [
                                  [-115.832, -404.29],
                                  [-172.239, -191.321],
                                  [-246.318, 38.153],
                                  [-284.75, 140.251],
                                  [-16.626, 301.503],
                                  [129.18, 418.496],
                                  [303.667, 277.828],
                                  [40.272, -134.906],
                                  [281.335, -248.368],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.937, -46.127],
                                  [-28.916, -127.98],
                                  [55.008, -40.919],
                                  [-129.099, -34.936],
                                  [-17.633, -67.889],
                                  [-118.754, 5.083],
                                  [131.978, 81.045],
                                  [-44.934, 145.795],
                                  [-9.321, 92],
                                ],
                                o: [
                                  [-97.798, 47.155],
                                  [28.916, 127.98],
                                  [-55.008, 40.919],
                                  [129.099, 34.936],
                                  [18.587, 69.063],
                                  [111.266, -3.468],
                                  [-131.979, -81.045],
                                  [43.54, -147.044],
                                  [9.321, -92],
                                ],
                                v: [
                                  [-116.4, -404.805],
                                  [-172.018, -191.481],
                                  [-246.845, 38.124],
                                  [-284.252, 139.824],
                                  [-16.363, 301.749],
                                  [129.266, 418.08],
                                  [304.237, 277.392],
                                  [39.796, -135.569],
                                  [280.624, -248.046],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.007, -45.985],
                                  [-28.745, -127.786],
                                  [54.887, -41.037],
                                  [-128.884, -34.959],
                                  [-17.624, -67.79],
                                  [-118.917, 5.158],
                                  [131.689, 80.974],
                                  [-44.812, 145.549],
                                  [-9.353, 92],
                                ],
                                o: [
                                  [-97.744, 47.038],
                                  [28.745, 127.786],
                                  [-54.887, 41.037],
                                  [128.884, 34.959],
                                  [18.601, 68.993],
                                  [111.248, -3.504],
                                  [-131.689, -80.974],
                                  [43.384, -146.827],
                                  [9.353, -92],
                                ],
                                v: [
                                  [-116.964, -405.367],
                                  [-171.741, -191.591],
                                  [-247.422, 38.084],
                                  [-283.7, 139.403],
                                  [-16.168, 301.988],
                                  [129.351, 417.725],
                                  [304.745, 276.903],
                                  [39.276, -136.213],
                                  [279.904, -247.775],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.08, -45.841],
                                  [-28.57, -127.588],
                                  [54.764, -41.159],
                                  [-128.665, -34.982],
                                  [-17.615, -67.688],
                                  [-119.083, 5.234],
                                  [131.392, 80.901],
                                  [-44.687, 145.298],
                                  [-9.386, 92],
                                ],
                                o: [
                                  [-97.69, 46.918],
                                  [28.57, 127.589],
                                  [-54.764, 41.159],
                                  [128.665, 34.982],
                                  [18.616, 68.92],
                                  [111.23, -3.54],
                                  [-131.392, -80.901],
                                  [43.225, -146.607],
                                  [9.386, -92],
                                ],
                                v: [
                                  [-117.576, -405.802],
                                  [-171.521, -191.707],
                                  [-248.001, 37.989],
                                  [-283.087, 139.045],
                                  [-15.985, 302.221],
                                  [129.378, 417.314],
                                  [305.196, 276.349],
                                  [38.711, -136.785],
                                  [279.181, -247.444],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.154, -45.693],
                                  [-28.392, -127.387],
                                  [54.639, -41.282],
                                  [-128.44, -35.005],
                                  [-17.606, -67.585],
                                  [-119.253, 5.311],
                                  [131.09, 80.827],
                                  [-44.56, 145.041],
                                  [-9.419, 92],
                                ],
                                o: [
                                  [-97.635, 46.796],
                                  [28.392, 127.387],
                                  [-54.639, 41.282],
                                  [128.44, 35.005],
                                  [18.631, 68.846],
                                  [111.212, -3.577],
                                  [-131.09, -80.827],
                                  [43.062, -146.381],
                                  [9.419, -92],
                                ],
                                v: [
                                  [-118.187, -406.226],
                                  [-171.242, -191.833],
                                  [-248.635, 37.831],
                                  [-282.421, 138.63],
                                  [-15.814, 302.448],
                                  [129.467, 416.906],
                                  [305.644, 275.802],
                                  [38.218, -137.395],
                                  [278.508, -247.11],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.229, -45.542],
                                  [-28.21, -127.18],
                                  [54.51, -41.409],
                                  [-128.212, -35.029],
                                  [-17.596, -67.479],
                                  [-119.427, 5.391],
                                  [130.781, 80.751],
                                  [-44.43, 144.779],
                                  [-9.453, 92],
                                ],
                                o: [
                                  [-97.578, 46.672],
                                  [28.21, 127.18],
                                  [-54.51, 41.409],
                                  [128.212, 35.029],
                                  [18.646, 68.771],
                                  [111.193, -3.615],
                                  [-130.781, -80.751],
                                  [42.896, -146.151],
                                  [9.453, -92],
                                ],
                                v: [
                                  [-118.846, -406.634],
                                  [-170.908, -191.844],
                                  [-249.259, 37.609],
                                  [-281.758, 138.274],
                                  [-15.712, 302.667],
                                  [129.498, 416.501],
                                  [306.086, 275.194],
                                  [37.687, -137.875],
                                  [277.773, -246.765],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.306, -45.387],
                                  [-28.024, -126.97],
                                  [54.379, -41.538],
                                  [-127.978, -35.054],
                                  [-17.587, -67.371],
                                  [-119.604, 5.472],
                                  [130.465, 80.673],
                                  [-44.297, 144.511],
                                  [-9.488, 92],
                                ],
                                o: [
                                  [-97.52, 46.545],
                                  [28.024, 126.97],
                                  [-54.379, 41.538],
                                  [127.978, 35.054],
                                  [18.661, 68.694],
                                  [111.173, -3.653],
                                  [-130.465, -80.673],
                                  [42.726, -145.916],
                                  [9.488, -92],
                                ],
                                v: [
                                  [-119.442, -406.973],
                                  [-170.63, -191.923],
                                  [-249.938, 37.386],
                                  [-281.038, 137.928],
                                  [-15.621, 302.879],
                                  [129.586, 416.1],
                                  [306.42, 274.525],
                                  [37.171, -138.391],
                                  [277.092, -246.417],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.385, -45.23],
                                  [-27.833, -126.754],
                                  [54.245, -41.67],
                                  [-127.739, -35.079],
                                  [-17.577, -67.261],
                                  [-119.786, 5.554],
                                  [130.142, 80.594],
                                  [-44.161, 144.237],
                                  [-9.523, 92],
                                ],
                                o: [
                                  [-97.461, 46.415],
                                  [27.833, 126.754],
                                  [-54.245, 41.67],
                                  [127.739, 35.079],
                                  [18.677, 68.615],
                                  [111.154, -3.693],
                                  [-130.142, -80.594],
                                  [42.553, -145.675],
                                  [9.523, -92],
                                ],
                                v: [
                                  [-120.032, -407.3],
                                  [-170.297, -191.952],
                                  [-250.617, 37.101],
                                  [-280.268, 137.634],
                                  [-15.601, 303.083],
                                  [129.622, 415.703],
                                  [306.802, 273.858],
                                  [36.67, -138.829],
                                  [276.345, -246.01],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.466, -45.069],
                                  [-27.639, -126.535],
                                  [54.109, -41.805],
                                  [-127.495, -35.105],
                                  [-17.567, -67.148],
                                  [-119.971, 5.639],
                                  [129.813, 80.513],
                                  [-44.022, 143.957],
                                  [-9.56, 92],
                                ],
                                o: [
                                  [-97.401, 46.282],
                                  [27.639, 126.535],
                                  [-54.109, 41.805],
                                  [127.495, 35.105],
                                  [18.693, 68.534],
                                  [111.134, -3.733],
                                  [-129.813, -80.513],
                                  [42.376, -145.43],
                                  [9.56, -92],
                                ],
                                v: [
                                  [-120.559, -407.563],
                                  [-169.962, -191.935],
                                  [-251.344, 36.758],
                                  [-279.438, 137.289],
                                  [-15.586, 303.28],
                                  [129.72, 415.306],
                                  [307.075, 273.194],
                                  [36.185, -139.25],
                                  [275.646, -245.654],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.548, -44.904],
                                  [-27.441, -126.31],
                                  [53.969, -41.943],
                                  [-127.246, -35.131],
                                  [-17.557, -67.033],
                                  [-120.16, 5.725],
                                  [129.476, 80.43],
                                  [-43.88, 143.672],
                                  [-9.597, 92],
                                ],
                                o: [
                                  [-97.339, 46.146],
                                  [27.441, 126.31],
                                  [-53.969, 41.943],
                                  [127.246, 35.131],
                                  [18.71, 68.452],
                                  [111.113, -3.774],
                                  [-129.476, -80.43],
                                  [42.195, -145.179],
                                  [9.596, -92],
                                ],
                                v: [
                                  [-121.132, -407.803],
                                  [-169.569, -191.92],
                                  [-252.014, 36.354],
                                  [-278.616, 137.001],
                                  [-15.59, 303.412],
                                  [129.813, 414.918],
                                  [307.338, 272.475],
                                  [35.722, -139.649],
                                  [274.942, -245.239],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.632, -44.737],
                                  [-27.238, -126.081],
                                  [53.826, -42.083],
                                  [-126.991, -35.158],
                                  [-17.546, -66.916],
                                  [-120.353, 5.813],
                                  [129.133, 80.346],
                                  [-43.735, 143.38],
                                  [-9.634, 92],
                                ],
                                o: [
                                  [-97.276, 46.008],
                                  [27.238, 126.081],
                                  [-53.826, 42.083],
                                  [126.991, 35.158],
                                  [18.726, 68.368],
                                  [111.092, -3.816],
                                  [-129.133, -80.346],
                                  [42.01, -144.923],
                                  [9.634, -92],
                                ],
                                v: [
                                  [-121.586, -407.979],
                                  [-169.235, -191.86],
                                  [-252.732, 35.893],
                                  [-277.74, 136.718],
                                  [-15.601, 303.594],
                                  [129.918, 414.53],
                                  [307.545, 271.695],
                                  [35.218, -140.025],
                                  [274.228, -244.817],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.717, -44.565],
                                  [-27.032, -125.847],
                                  [53.68, -42.227],
                                  [-126.731, -35.185],
                                  [-17.535, -66.796],
                                  [-120.55, 5.903],
                                  [128.782, 80.259],
                                  [-43.587, 143.082],
                                  [-9.673, 92],
                                ],
                                o: [
                                  [-97.212, 45.866],
                                  [27.032, 125.847],
                                  [-53.68, 42.227],
                                  [126.731, 35.185],
                                  [18.743, 68.283],
                                  [111.071, -3.859],
                                  [-128.782, -80.259],
                                  [41.822, -144.662],
                                  [9.673, -92],
                                ],
                                v: [
                                  [-122.034, -408.198],
                                  [-168.836, -191.809],
                                  [-253.501, 35.433],
                                  [-276.804, 136.491],
                                  [-15.687, 303.767],
                                  [130.017, 414.148],
                                  [307.753, 270.913],
                                  [34.788, -140.331],
                                  [273.509, -244.337],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.805, -44.39],
                                  [-26.82, -125.608],
                                  [53.531, -42.373],
                                  [-126.466, -35.213],
                                  [-17.524, -66.673],
                                  [-120.751, 5.995],
                                  [128.423, 80.171],
                                  [-43.436, 142.778],
                                  [-9.712, 92],
                                ],
                                o: [
                                  [-97.146, 45.722],
                                  [26.82, 125.608],
                                  [-53.531, 42.373],
                                  [126.466, 35.213],
                                  [18.761, 68.195],
                                  [111.049, -3.902],
                                  [-128.423, -80.171],
                                  [41.629, -144.395],
                                  [9.712, -92],
                                ],
                                v: [
                                  [-122.475, -408.292],
                                  [-168.446, -191.765],
                                  [-254.27, 34.913],
                                  [-275.822, 136.201],
                                  [-15.836, 303.88],
                                  [130.128, 413.766],
                                  [307.899, 270.082],
                                  [34.382, -140.607],
                                  [272.779, -243.851],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.894, -44.211],
                                  [-26.605, -125.363],
                                  [53.38, -42.523],
                                  [-126.195, -35.241],
                                  [-17.513, -66.548],
                                  [-120.957, 6.089],
                                  [128.057, 80.081],
                                  [-43.282, 142.467],
                                  [-9.752, 92],
                                ],
                                o: [
                                  [-97.079, 45.574],
                                  [26.605, 125.363],
                                  [-53.38, 42.523],
                                  [126.195, 35.241],
                                  [18.779, 68.106],
                                  [111.026, -3.947],
                                  [-128.057, -80.081],
                                  [41.432, -144.122],
                                  [9.753, -92],
                                ],
                                v: [
                                  [-122.849, -408.373],
                                  [-168.046, -191.673],
                                  [-254.971, 34.332],
                                  [-274.843, 136.035],
                                  [-15.948, 303.98],
                                  [130.234, 413.377],
                                  [308.046, 269.249],
                                  [33.994, -140.87],
                                  [272.1, -243.365],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.986, -44.029],
                                  [-26.385, -125.114],
                                  [53.224, -42.676],
                                  [-125.918, -35.27],
                                  [-17.502, -66.42],
                                  [-121.167, 6.185],
                                  [127.684, 79.989],
                                  [-43.125, 142.15],
                                  [-9.794, 92],
                                ],
                                o: [
                                  [-97.011, 45.424],
                                  [26.385, 125.114],
                                  [-53.224, 42.676],
                                  [125.918, 35.27],
                                  [18.797, 68.014],
                                  [111.004, -3.993],
                                  [-127.684, -79.989],
                                  [41.231, -143.844],
                                  [9.794, -92],
                                ],
                                v: [
                                  [-123.216, -408.44],
                                  [-167.594, -191.533],
                                  [-255.78, 33.754],
                                  [-273.819, 135.806],
                                  [-16.124, 304.134],
                                  [130.402, 412.995],
                                  [308.131, 268.412],
                                  [33.625, -141.052],
                                  [271.353, -242.872],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.079, -43.842],
                                  [-26.16, -124.859],
                                  [53.066, -42.832],
                                  [-125.636, -35.3],
                                  [-17.49, -66.289],
                                  [-121.381, 6.283],
                                  [127.302, 79.896],
                                  [-42.964, 141.826],
                                  [-9.836, 92],
                                ],
                                o: [
                                  [-96.941, 45.27],
                                  [26.16, 124.859],
                                  [-53.066, 42.832],
                                  [125.636, 35.3],
                                  [18.816, 67.921],
                                  [110.98, -4.039],
                                  [-127.302, -79.896],
                                  [41.026, -143.559],
                                  [9.836, -92],
                                ],
                                v: [
                                  [-123.572, -408.493],
                                  [-167.194, -191.465],
                                  [-256.532, 33.122],
                                  [-272.793, 135.636],
                                  [-16.369, 304.222],
                                  [130.572, 412.613],
                                  [308.161, 267.521],
                                  [33.281, -141.213],
                                  [270.657, -242.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.174, -43.652],
                                  [-25.93, -124.6],
                                  [52.904, -42.992],
                                  [-125.347, -35.331],
                                  [-17.478, -66.156],
                                  [-121.6, 6.383],
                                  [126.912, 79.8],
                                  [-42.8, 141.495],
                                  [-9.878, 92],
                                ],
                                o: [
                                  [-96.869, 45.113],
                                  [25.93, 124.6],
                                  [-52.904, 42.992],
                                  [125.347, 35.33],
                                  [18.835, 67.826],
                                  [110.956, -4.087],
                                  [-126.912, -79.8],
                                  [40.817, -143.269],
                                  [9.878, -92],
                                ],
                                v: [
                                  [-123.864, -408.421],
                                  [-166.797, -191.344],
                                  [-257.271, 32.488],
                                  [-271.779, 135.528],
                                  [-16.572, 304.302],
                                  [130.742, 412.232],
                                  [308.13, 266.578],
                                  [32.949, -141.348],
                                  [269.948, -241.823],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.271, -43.458],
                                  [-25.696, -124.334],
                                  [52.739, -43.154],
                                  [-125.053, -35.361],
                                  [-17.466, -66.02],
                                  [-121.824, 6.485],
                                  [126.515, 79.702],
                                  [-42.632, 141.157],
                                  [-9.922, 92],
                                ],
                                o: [
                                  [-96.797, 44.952],
                                  [25.696, 124.334],
                                  [-52.739, 43.154],
                                  [125.053, 35.361],
                                  [18.854, 67.729],
                                  [110.932, -4.136],
                                  [-126.515, -79.702],
                                  [40.603, -142.973],
                                  [9.922, -92],
                                ],
                                v: [
                                  [-124.095, -408.391],
                                  [-166.353, -191.176],
                                  [-258.011, 31.795],
                                  [-270.707, 135.417],
                                  [-16.844, 304.373],
                                  [130.919, 411.788],
                                  [308.099, 265.694],
                                  [32.645, -141.413],
                                  [269.232, -241.206],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.37, -43.259],
                                  [-25.456, -124.063],
                                  [52.57, -43.32],
                                  [-124.752, -35.393],
                                  [-17.454, -65.881],
                                  [-122.052, 6.589],
                                  [126.109, 79.602],
                                  [-42.461, 140.813],
                                  [-9.967, 92],
                                ],
                                o: [
                                  [-96.722, 44.789],
                                  [25.456, 124.063],
                                  [-52.57, 43.32],
                                  [124.752, 35.393],
                                  [18.874, 67.63],
                                  [110.907, -4.185],
                                  [-126.109, -79.602],
                                  [40.385, -142.67],
                                  [9.967, -92],
                                ],
                                v: [
                                  [-124.312, -408.298],
                                  [-165.962, -191.068],
                                  [-258.793, 31.05],
                                  [-269.592, 135.303],
                                  [-17.124, 304.385],
                                  [131.153, 411.407],
                                  [308.063, 264.751],
                                  [32.361, -141.45],
                                  [268.559, -240.646],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.472, -43.057],
                                  [-25.212, -123.787],
                                  [52.398, -43.49],
                                  [-124.445, -35.425],
                                  [-17.441, -65.74],
                                  [-122.285, 6.695],
                                  [125.694, 79.5],
                                  [-42.286, 140.461],
                                  [-10.012, 92],
                                ],
                                o: [
                                  [-96.646, 44.622],
                                  [25.212, 123.787],
                                  [-52.398, 43.49],
                                  [124.445, 35.425],
                                  [18.894, 67.528],
                                  [110.882, -4.236],
                                  [-125.694, -79.5],
                                  [40.162, -142.361],
                                  [10.012, -92],
                                ],
                                v: [
                                  [-124.516, -408.128],
                                  [-165.518, -190.921],
                                  [-259.512, 30.31],
                                  [-268.539, 135.249],
                                  [-17.48, 304.437],
                                  [131.332, 411.02],
                                  [307.917, 263.756],
                                  [32.16, -141.459],
                                  [267.83, -240.031],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.575, -42.85],
                                  [-24.963, -123.504],
                                  [52.222, -43.663],
                                  [-124.132, -35.458],
                                  [-17.428, -65.595],
                                  [-122.523, 6.804],
                                  [125.271, 79.396],
                                  [-42.108, 140.102],
                                  [-10.059, 92],
                                ],
                                o: [
                                  [-96.569, 44.451],
                                  [24.963, 123.504],
                                  [-52.222, 43.663],
                                  [124.132, 35.458],
                                  [18.915, 67.425],
                                  [110.856, -4.288],
                                  [-125.271, -79.396],
                                  [39.935, -142.046],
                                  [10.059, -92],
                                ],
                                v: [
                                  [-124.658, -407.951],
                                  [-165.139, -190.778],
                                  [-260.287, 29.514],
                                  [-267.389, 135.191],
                                  [-17.787, 304.494],
                                  [131.568, 410.641],
                                  [307.773, 262.813],
                                  [31.919, -141.396],
                                  [267.142, -239.418],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.681, -42.639],
                                  [-24.708, -123.216],
                                  [52.043, -43.84],
                                  [-123.812, -35.492],
                                  [-17.415, -65.447],
                                  [-122.765, 6.915],
                                  [124.839, 79.29],
                                  [-41.926, 139.735],
                                  [-10.106, 92],
                                ],
                                o: [
                                  [-96.49, 44.277],
                                  [24.708, 123.216],
                                  [-52.043, 43.84],
                                  [123.812, 35.492],
                                  [18.936, 67.319],
                                  [110.83, -4.34],
                                  [-124.839, -79.29],
                                  [39.703, -141.724],
                                  [10.106, -92],
                                ],
                                v: [
                                  [-124.793, -407.759],
                                  [-164.764, -190.638],
                                  [-260.999, 28.723],
                                  [-266.294, 135.137],
                                  [-18.172, 304.479],
                                  [131.859, 410.199],
                                  [307.622, 261.826],
                                  [31.762, -141.312],
                                  [266.447, -238.737],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.788, -42.423],
                                  [-24.448, -122.922],
                                  [51.86, -44.02],
                                  [-123.486, -35.526],
                                  [-17.401, -65.296],
                                  [-123.013, 7.028],
                                  [124.398, 79.182],
                                  [-41.74, 139.361],
                                  [-10.155, 92],
                                ],
                                o: [
                                  [-96.409, 44.099],
                                  [24.448, 122.922],
                                  [-51.86, 44.02],
                                  [123.486, 35.526],
                                  [18.958, 67.212],
                                  [110.803, -4.394],
                                  [-124.398, -79.182],
                                  [39.466, -141.396],
                                  [10.155, -92],
                                ],
                                v: [
                                  [-124.859, -407.498],
                                  [-164.384, -190.461],
                                  [-261.697, 27.932],
                                  [-265.151, 135.141],
                                  [-18.564, 304.517],
                                  [132.104, 409.814],
                                  [307.418, 260.835],
                                  [31.627, -141.197],
                                  [265.791, -238.067],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.898, -42.204],
                                  [-24.183, -122.622],
                                  [51.673, -44.205],
                                  [-123.152, -35.561],
                                  [-17.388, -65.143],
                                  [-123.266, 7.143],
                                  [123.948, 79.071],
                                  [-41.551, 138.979],
                                  [-10.204, 92],
                                ],
                                o: [
                                  [-96.326, 43.918],
                                  [24.183, 122.622],
                                  [-51.673, 44.204],
                                  [123.152, 35.561],
                                  [18.98, 67.102],
                                  [110.775, -4.449],
                                  [-123.948, -79.071],
                                  [39.224, -141.061],
                                  [10.204, -92],
                                ],
                                v: [
                                  [-124.91, -407.222],
                                  [-164.016, -190.28],
                                  [-262.449, 27.085],
                                  [-264.024, 135.133],
                                  [-19.018, 304.491],
                                  [132.397, 409.374],
                                  [307.152, 259.801],
                                  [31.516, -141.011],
                                  [265.079, -237.392],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.01, -41.979],
                                  [-23.912, -122.315],
                                  [51.482, -44.392],
                                  [-122.812, -35.597],
                                  [-17.374, -64.985],
                                  [-123.524, 7.261],
                                  [123.489, 78.958],
                                  [-41.357, 138.589],
                                  [-10.255, 92],
                                ],
                                o: [
                                  [-96.242, 43.733],
                                  [23.912, 122.315],
                                  [-51.482, 44.392],
                                  [122.812, 35.597],
                                  [19.002, 66.99],
                                  [110.747, -4.505],
                                  [-123.489, -78.958],
                                  [38.977, -140.718],
                                  [10.255, -92],
                                ],
                                v: [
                                  [-124.899, -406.877],
                                  [-163.65, -190.118],
                                  [-263.138, 26.19],
                                  [-262.897, 135.19],
                                  [-19.431, 304.454],
                                  [132.643, 408.983],
                                  [306.833, 258.819],
                                  [31.49, -140.794],
                                  [264.407, -236.658],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.125, -41.75],
                                  [-23.636, -122.003],
                                  [51.288, -44.584],
                                  [-122.465, -35.634],
                                  [-17.359, -64.825],
                                  [-123.787, 7.381],
                                  [123.02, 78.843],
                                  [-41.16, 138.191],
                                  [-10.306, 92],
                                ],
                                o: [
                                  [-96.156, 43.544],
                                  [23.636, 122.003],
                                  [-51.288, 44.584],
                                  [122.465, 35.634],
                                  [19.025, 66.875],
                                  [110.719, -4.562],
                                  [-123.02, -78.843],
                                  [38.725, -140.369],
                                  [10.306, -92],
                                ],
                                v: [
                                  [-124.873, -406.47],
                                  [-163.343, -189.96],
                                  [-263.82, 25.297],
                                  [-261.724, 135.242],
                                  [-19.915, 304.408],
                                  [132.938, 408.545],
                                  [306.517, 257.786],
                                  [31.48, -140.498],
                                  [263.725, -235.92],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.242, -41.517],
                                  [-23.354, -121.683],
                                  [51.089, -44.78],
                                  [-122.111, -35.671],
                                  [-17.345, -64.662],
                                  [-124.056, 7.504],
                                  [122.542, 78.726],
                                  [-40.958, 137.785],
                                  [-10.359, 92],
                                ],
                                o: [
                                  [-96.069, 43.351],
                                  [23.354, 121.684],
                                  [-51.089, 44.78],
                                  [122.111, 35.671],
                                  [19.048, 66.758],
                                  [110.69, -4.621],
                                  [-122.542, -78.726],
                                  [38.468, -140.013],
                                  [10.359, -92],
                                ],
                                v: [
                                  [-124.785, -406.102],
                                  [-162.985, -189.814],
                                  [-264.439, 24.412],
                                  [-260.622, 135.342],
                                  [-20.413, 304.36],
                                  [133.287, 408.154],
                                  [306.202, 256.758],
                                  [31.495, -140.185],
                                  [263.081, -235.186],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.361, -41.278],
                                  [-23.067, -121.358],
                                  [50.886, -44.98],
                                  [-121.75, -35.709],
                                  [-17.33, -64.495],
                                  [-124.33, 7.629],
                                  [122.053, 78.606],
                                  [-40.752, 137.371],
                                  [-10.412, 92],
                                ],
                                o: [
                                  [-95.979, 43.154],
                                  [23.067, 121.358],
                                  [-50.886, 44.98],
                                  [121.75, 35.709],
                                  [19.072, 66.639],
                                  [110.66, -4.68],
                                  [-122.053, -78.606],
                                  [38.206, -139.649],
                                  [10.412, -92],
                                ],
                                v: [
                                  [-124.681, -405.611],
                                  [-162.684, -189.617],
                                  [-265.112, 23.528],
                                  [-259.467, 135.444],
                                  [-20.918, 304.301],
                                  [133.591, 407.71],
                                  [305.772, 255.734],
                                  [31.542, -139.786],
                                  [262.435, -234.403],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.483, -41.035],
                                  [-22.773, -121.025],
                                  [50.679, -45.184],
                                  [-121.381, -35.748],
                                  [-17.315, -64.324],
                                  [-124.61, 7.757],
                                  [121.555, 78.483],
                                  [-40.542, 136.948],
                                  [-10.467, 92],
                                ],
                                o: [
                                  [-95.888, 42.954],
                                  [22.773, 121.025],
                                  [-50.679, 45.183],
                                  [121.381, 35.748],
                                  [19.097, 66.517],
                                  [110.629, -4.741],
                                  [-121.555, -78.483],
                                  [37.938, -139.278],
                                  [10.467, -92],
                                ],
                                v: [
                                  [-124.516, -405.114],
                                  [-162.441, -189.486],
                                  [-265.723, 22.6],
                                  [-258.33, 135.548],
                                  [-21.492, 304.241],
                                  [133.941, 407.321],
                                  [305.345, 254.716],
                                  [31.669, -139.369],
                                  [261.762, -233.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.607, -40.786],
                                  [-22.474, -120.686],
                                  [50.468, -45.391],
                                  [-121.004, -35.787],
                                  [-17.299, -64.15],
                                  [-124.896, 7.887],
                                  [121.047, 78.358],
                                  [-40.328, 136.516],
                                  [-10.523, 92],
                                ],
                                o: [
                                  [-95.795, 42.749],
                                  [22.474, 120.686],
                                  [-50.468, 45.391],
                                  [121.004, 35.787],
                                  [19.121, 66.393],
                                  [110.598, -4.803],
                                  [-121.047, -78.358],
                                  [37.665, -138.899],
                                  [10.523, -92],
                                ],
                                v: [
                                  [-124.342, -404.602],
                                  [-162.148, -189.306],
                                  [-266.326, 21.62],
                                  [-257.194, 135.698],
                                  [-22.019, 304.117],
                                  [134.292, 406.879],
                                  [304.92, 253.65],
                                  [31.822, -138.918],
                                  [261.087, -232.774],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.734, -40.533],
                                  [-22.168, -120.34],
                                  [50.253, -45.604],
                                  [-120.62, -35.828],
                                  [-17.283, -63.973],
                                  [-125.187, 8.02],
                                  [120.528, 78.231],
                                  [-40.11, 136.076],
                                  [-10.58, 92],
                                ],
                                o: [
                                  [-95.7, 42.54],
                                  [22.168, 120.34],
                                  [-50.253, 45.604],
                                  [120.62, 35.828],
                                  [19.147, 66.266],
                                  [110.567, -4.867],
                                  [-120.528, -78.231],
                                  [37.386, -138.513],
                                  [10.58, -92],
                                ],
                                v: [
                                  [-124.152, -404.022],
                                  [-161.911, -189.137],
                                  [-266.867, 20.697],
                                  [-256.078, 135.84],
                                  [-22.614, 304.037],
                                  [134.643, 406.483],
                                  [304.381, 252.642],
                                  [32.055, -138.396],
                                  [260.446, -231.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.863, -40.274],
                                  [-21.856, -119.987],
                                  [50.033, -45.82],
                                  [-120.228, -35.869],
                                  [-17.267, -63.792],
                                  [-125.484, 8.156],
                                  [119.999, 78.101],
                                  [-39.887, 135.627],
                                  [-10.638, 92],
                                ],
                                o: [
                                  [-95.603, 42.326],
                                  [21.856, 119.987],
                                  [-50.033, 45.82],
                                  [120.229, 35.869],
                                  [19.173, 66.137],
                                  [110.534, -4.931],
                                  [-119.999, -78.101],
                                  [37.102, -138.119],
                                  [10.638, -92],
                                ],
                                v: [
                                  [-123.901, -403.426],
                                  [-161.731, -188.973],
                                  [-267.453, 19.722],
                                  [-255.016, 136.036],
                                  [-23.225, 303.901],
                                  [134.994, 406.035],
                                  [303.898, 251.64],
                                  [32.305, -137.848],
                                  [259.794, -231.037],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.995, -40.011],
                                  [-21.538, -119.627],
                                  [49.809, -46.041],
                                  [-119.829, -35.911],
                                  [-17.251, -63.607],
                                  [-125.788, 8.294],
                                  [119.459, 77.968],
                                  [-39.659, 135.169],
                                  [-10.698, 92],
                                ],
                                o: [
                                  [-95.504, 42.109],
                                  [21.538, 119.627],
                                  [-49.809, 46.041],
                                  [119.829, 35.911],
                                  [19.199, 66.005],
                                  [110.501, -4.997],
                                  [-119.459, -77.968],
                                  [36.812, -137.716],
                                  [10.698, -92],
                                ],
                                v: [
                                  [-123.579, -402.824],
                                  [-161.554, -188.821],
                                  [-267.987, 18.761],
                                  [-253.921, 136.232],
                                  [-23.842, 303.756],
                                  [135.39, 405.577],
                                  [303.312, 250.591],
                                  [32.644, -137.22],
                                  [259.183, -230.144],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.129, -39.742],
                                  [-21.213, -119.26],
                                  [49.58, -46.266],
                                  [-119.421, -35.954],
                                  [-17.234, -63.419],
                                  [-126.097, 8.436],
                                  [118.909, 77.833],
                                  [-39.427, 134.701],
                                  [-10.758, 92],
                                ],
                                o: [
                                  [-95.403, 41.887],
                                  [21.213, 119.26],
                                  [-49.58, 46.266],
                                  [119.421, 35.954],
                                  [19.226, 65.871],
                                  [110.468, -5.065],
                                  [-118.909, -77.833],
                                  [36.516, -137.306],
                                  [10.758, -92],
                                ],
                                v: [
                                  [-123.249, -402.101],
                                  [-161.38, -188.682],
                                  [-268.459, 17.802],
                                  [-252.829, 136.429],
                                  [-24.475, 303.61],
                                  [135.742, 405.174],
                                  [302.729, 249.591],
                                  [33.01, -136.513],
                                  [258.496, -229.249],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.266, -39.467],
                                  [-20.882, -118.885],
                                  [49.347, -46.496],
                                  [-119.005, -35.997],
                                  [-17.217, -63.227],
                                  [-126.413, 8.58],
                                  [118.347, 77.694],
                                  [-39.19, 134.224],
                                  [-10.82, 92],
                                ],
                                o: [
                                  [-95.3, 41.66],
                                  [20.882, 118.885],
                                  [-49.347, 46.496],
                                  [119.005, 35.997],
                                  [19.253, 65.734],
                                  [110.433, -5.133],
                                  [-118.347, -77.694],
                                  [36.214, -136.887],
                                  [10.82, -92],
                                ],
                                v: [
                                  [-122.901, -401.416],
                                  [-161.262, -188.599],
                                  [-268.923, 16.848],
                                  [-251.8, 136.668],
                                  [-25.123, 303.464],
                                  [136.137, 404.718],
                                  [302.149, 248.554],
                                  [33.457, -135.83],
                                  [257.85, -228.311],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.406, -39.187],
                                  [-20.544, -118.502],
                                  [49.109, -46.731],
                                  [-118.581, -36.042],
                                  [-17.199, -63.031],
                                  [-126.735, 8.727],
                                  [117.774, 77.554],
                                  [-38.949, 133.738],
                                  [-10.883, 92],
                                ],
                                o: [
                                  [-95.195, 41.429],
                                  [20.544, 118.502],
                                  [-49.109, 46.731],
                                  [118.581, 36.042],
                                  [19.281, 65.594],
                                  [110.398, -5.203],
                                  [-117.774, -77.554],
                                  [35.906, -136.46],
                                  [10.883, -92],
                                ],
                                v: [
                                  [-122.492, -400.674],
                                  [-161.146, -188.467],
                                  [-269.379, 15.856],
                                  [-250.73, 136.948],
                                  [-25.778, 303.297],
                                  [136.532, 404.264],
                                  [301.51, 247.523],
                                  [33.942, -135.025],
                                  [257.233, -227.319],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.549, -38.902],
                                  [-20.2, -118.112],
                                  [48.866, -46.97],
                                  [-118.148, -36.088],
                                  [-17.181, -62.831],
                                  [-127.063, 8.877],
                                  [117.189, 77.41],
                                  [-38.703, 133.241],
                                  [-10.947, 92],
                                ],
                                o: [
                                  [-95.088, 41.194],
                                  [20.2, 118.112],
                                  [-48.866, 46.97],
                                  [118.148, 36.088],
                                  [19.31, 65.451],
                                  [110.363, -5.275],
                                  [-117.189, -77.41],
                                  [35.592, -136.025],
                                  [10.947, -92],
                                ],
                                v: [
                                  [-122.011, -399.864],
                                  [-161.085, -188.347],
                                  [-269.774, 14.868],
                                  [-249.725, 137.227],
                                  [-26.511, 303.141],
                                  [136.969, 403.852],
                                  [300.833, 246.552],
                                  [34.497, -134.192],
                                  [256.612, -226.38],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.695, -38.61],
                                  [-19.849, -117.714],
                                  [48.619, -47.214],
                                  [-117.706, -36.134],
                                  [-17.163, -62.627],
                                  [-127.398, 9.03],
                                  [116.593, 77.263],
                                  [-38.451, 132.735],
                                  [-11.013, 92],
                                ],
                                o: [
                                  [-94.979, 40.953],
                                  [19.848, 117.715],
                                  [-48.619, 47.214],
                                  [117.706, 36.134],
                                  [19.339, 65.305],
                                  [110.326, -5.347],
                                  [-116.593, -77.263],
                                  [35.271, -135.581],
                                  [11.013, -92],
                                ],
                                v: [
                                  [-121.522, -399.039],
                                  [-161.079, -188.231],
                                  [-270.171, 13.876],
                                  [-248.784, 137.504],
                                  [-27.187, 302.975],
                                  [137.404, 403.389],
                                  [300.15, 245.587],
                                  [35.133, -133.332],
                                  [255.955, -225.39],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.844, -38.313],
                                  [-19.49, -117.309],
                                  [48.366, -47.463],
                                  [-117.256, -36.181],
                                  [-17.145, -62.419],
                                  [-127.74, 9.186],
                                  [115.984, 77.114],
                                  [-38.195, 132.219],
                                  [-11.08, 92],
                                ],
                                o: [
                                  [-94.867, 40.708],
                                  [19.49, 117.309],
                                  [-48.366, 47.463],
                                  [117.256, 36.181],
                                  [19.369, 65.156],
                                  [110.289, -5.422],
                                  [-115.984, -77.114],
                                  [34.944, -135.127],
                                  [11.08, -92],
                                ],
                                v: [
                                  [-121.014, -398.21],
                                  [-161.075, -188.18],
                                  [-270.497, 12.9],
                                  [-247.855, 137.831],
                                  [-27.941, 302.757],
                                  [137.786, 402.967],
                                  [299.419, 244.577],
                                  [35.799, -132.391],
                                  [255.293, -224.347],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.995, -38.01],
                                  [-19.124, -116.895],
                                  [48.108, -47.717],
                                  [-116.796, -36.23],
                                  [-17.126, -62.207],
                                  [-128.089, 9.345],
                                  [115.364, 76.961],
                                  [-37.934, 131.692],
                                  [-11.148, 92],
                                ],
                                o: [
                                  [-94.754, 40.458],
                                  [19.124, 116.895],
                                  [-48.108, 47.717],
                                  [116.796, 36.23],
                                  [19.399, 65.005],
                                  [110.251, -5.497],
                                  [-115.364, -76.961],
                                  [34.611, -134.665],
                                  [11.148, -92],
                                ],
                                v: [
                                  [-120.444, -397.326],
                                  [-161.113, -188.131],
                                  [-270.827, 11.94],
                                  [-246.938, 138.196],
                                  [-28.649, 302.531],
                                  [138.271, 402.495],
                                  [298.692, 243.637],
                                  [36.545, -131.432],
                                  [254.656, -223.317],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.15, -37.701],
                                  [-18.752, -116.473],
                                  [47.846, -47.976],
                                  [-116.328, -36.279],
                                  [-17.106, -61.99],
                                  [-128.444, 9.507],
                                  [114.731, 76.806],
                                  [-37.667, 131.155],
                                  [-11.217, 92],
                                ],
                                o: [
                                  [-94.638, 40.203],
                                  [18.752, 116.473],
                                  [-47.846, 47.976],
                                  [116.328, 36.279],
                                  [19.43, 64.85],
                                  [110.213, -5.575],
                                  [-114.731, -76.806],
                                  [34.271, -134.194],
                                  [11.217, -92],
                                ],
                                v: [
                                  [-119.855, -396.376],
                                  [-161.163, -188.094],
                                  [-271.096, 10.977],
                                  [-246.024, 138.557],
                                  [-29.426, 302.357],
                                  [138.691, 402.062],
                                  [297.919, 242.693],
                                  [37.372, -130.394],
                                  [254.003, -222.278],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.307, -37.385],
                                  [-18.371, -116.042],
                                  [47.578, -48.24],
                                  [-115.85, -36.329],
                                  [-17.087, -61.77],
                                  [-128.807, 9.673],
                                  [114.086, 76.647],
                                  [-37.395, 130.607],
                                  [-11.288, 92],
                                ],
                                o: [
                                  [-94.52, 39.943],
                                  [18.371, 116.042],
                                  [-47.578, 48.24],
                                  [115.85, 36.329],
                                  [19.461, 64.693],
                                  [110.173, -5.654],
                                  [-114.086, -76.647],
                                  [33.924, -133.713],
                                  [11.288, -92],
                                ],
                                v: [
                                  [-119.205, -395.463],
                                  [-161.265, -188.06],
                                  [-271.358, 10.02],
                                  [-245.224, 138.903],
                                  [-30.167, 302.122],
                                  [139.098, 401.578],
                                  [297.151, 241.769],
                                  [38.279, -129.326],
                                  [253.372, -221.201],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.468, -37.064],
                                  [-17.983, -115.603],
                                  [47.304, -48.509],
                                  [-115.363, -36.38],
                                  [-17.066, -61.545],
                                  [-129.176, 9.841],
                                  [113.428, 76.485],
                                  [-37.118, 130.049],
                                  [-11.361, 92],
                                ],
                                o: [
                                  [-94.399, 39.678],
                                  [17.983, 115.603],
                                  [-47.304, 48.509],
                                  [115.363, 36.38],
                                  [19.494, 64.532],
                                  [110.133, -5.734],
                                  [-113.428, -76.485],
                                  [33.57, -133.223],
                                  [11.361, -92],
                                ],
                                v: [
                                  [-118.534, -394.457],
                                  [-161.42, -188.038],
                                  [-271.549, 9.07],
                                  [-244.385, 139.295],
                                  [-30.977, 301.901],
                                  [139.565, 401.134],
                                  [296.338, 240.852],
                                  [39.255, -128.19],
                                  [252.671, -220.126],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.632, -36.736],
                                  [-17.588, -115.155],
                                  [47.026, -48.784],
                                  [-114.866, -36.433],
                                  [-17.046, -61.315],
                                  [-129.553, 10.014],
                                  [112.757, 76.32],
                                  [-36.835, 129.479],
                                  [-11.434, 92],
                                ],
                                o: [
                                  [-94.276, 39.407],
                                  [17.588, 115.155],
                                  [-47.026, 48.783],
                                  [114.866, 36.433],
                                  [19.526, 64.368],
                                  [110.092, -5.816],
                                  [-112.757, -76.32],
                                  [33.21, -132.723],
                                  [11.434, -92],
                                ],
                                v: [
                                  [-117.84, -393.477],
                                  [-161.613, -188.068],
                                  [-271.744, 8.127],
                                  [-243.66, 139.733],
                                  [-31.74, 301.671],
                                  [140.006, 400.689],
                                  [295.531, 239.994],
                                  [40.273, -126.974],
                                  [252.054, -219.004],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.799, -36.402],
                                  [-17.185, -114.699],
                                  [46.742, -49.063],
                                  [-114.359, -36.486],
                                  [-17.025, -61.081],
                                  [-129.938, 10.189],
                                  [112.072, 76.152],
                                  [-36.547, 128.898],
                                  [-11.51, 92],
                                ],
                                o: [
                                  [-94.151, 39.132],
                                  [17.185, 114.699],
                                  [-46.742, 49.063],
                                  [114.359, 36.486],
                                  [19.56, 64.201],
                                  [110.05, -5.9],
                                  [-112.072, -76.152],
                                  [32.842, -132.213],
                                  [11.51, -92],
                                ],
                                v: [
                                  [-117.086, -392.445],
                                  [-161.818, -188.161],
                                  [-271.843, 7.204],
                                  [-242.948, 140.165],
                                  [-32.572, 301.445],
                                  [140.455, 400.243],
                                  [294.73, 239.094],
                                  [41.421, -125.742],
                                  [251.366, -217.885],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.97, -36.061],
                                  [-16.773, -114.233],
                                  [46.452, -49.349],
                                  [-113.842, -36.54],
                                  [-17.004, -60.843],
                                  [-130.33, 10.368],
                                  [111.375, 75.981],
                                  [-36.253, 128.306],
                                  [-11.586, 92],
                                ],
                                o: [
                                  [-94.023, 38.85],
                                  [16.773, 114.233],
                                  [-46.452, 49.349],
                                  [113.842, 36.54],
                                  [19.594, 64.03],
                                  [110.008, -5.985],
                                  [-111.375, -75.981],
                                  [32.467, -131.693],
                                  [11.586, -92],
                                ],
                                v: [
                                  [-116.26, -391.362],
                                  [-162.073, -188.254],
                                  [-271.972, 6.277],
                                  [-242.299, 140.628],
                                  [-33.37, 301.211],
                                  [140.927, 399.784],
                                  [293.848, 238.266],
                                  [42.6, -124.468],
                                  [250.696, -216.721],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.143, -35.713],
                                  [-16.354, -113.759],
                                  [46.156, -49.64],
                                  [-113.316, -36.596],
                                  [-16.982, -60.599],
                                  [-130.73, 10.551],
                                  [110.663, 75.806],
                                  [-35.953, 127.702],
                                  [-11.664, 92],
                                ],
                                o: [
                                  [-93.893, 38.564],
                                  [16.354, 113.759],
                                  [-46.156, 49.64],
                                  [113.316, 36.596],
                                  [19.629, 63.857],
                                  [109.964, -6.072],
                                  [-110.663, -75.806],
                                  [32.085, -131.163],
                                  [11.664, -92],
                                ],
                                v: [
                                  [-115.459, -390.33],
                                  [-162.414, -188.346],
                                  [-271.994, 5.358],
                                  [-241.699, 141.085],
                                  [-34.236, 300.931],
                                  [141.356, 399.311],
                                  [293.011, 237.446],
                                  [43.895, -123.139],
                                  [250.004, -215.548],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.321, -35.359],
                                  [-15.927, -113.275],
                                  [45.855, -49.937],
                                  [-112.778, -36.652],
                                  [-16.96, -60.351],
                                  [-131.137, 10.737],
                                  [109.938, 75.628],
                                  [-35.647, 127.086],
                                  [-11.744, 92],
                                ],
                                o: [
                                  [-93.76, 38.271],
                                  [15.927, 113.275],
                                  [-45.855, 49.937],
                                  [112.778, 36.652],
                                  [19.664, 63.68],
                                  [109.92, -6.16],
                                  [-109.938, -75.627],
                                  [31.695, -130.622],
                                  [11.744, -92],
                                ],
                                v: [
                                  [-114.598, -389.185],
                                  [-162.752, -188.498],
                                  [-272.01, 4.435],
                                  [-241.16, 141.533],
                                  [-35.07, 300.705],
                                  [141.806, 398.837],
                                  [292.132, 236.649],
                                  [45.283, -121.732],
                                  [249.327, -214.393],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.501, -34.998],
                                  [-15.491, -112.781],
                                  [45.548, -50.24],
                                  [-112.231, -36.71],
                                  [-16.937, -60.098],
                                  [-131.553, 10.926],
                                  [109.198, 75.446],
                                  [-35.335, 126.458],
                                  [-11.826, 92],
                                ],
                                o: [
                                  [-93.624, 37.973],
                                  [15.491, 112.781],
                                  [-45.548, 50.239],
                                  [112.231, 36.71],
                                  [19.7, 63.499],
                                  [109.875, -6.251],
                                  [-109.198, -75.446],
                                  [31.297, -130.071],
                                  [11.826, -92],
                                ],
                                v: [
                                  [-113.663, -388.092],
                                  [-163.15, -188.699],
                                  [-271.969, 3.533],
                                  [-240.682, 142.059],
                                  [-35.923, 300.436],
                                  [142.263, 398.41],
                                  [291.273, 235.911],
                                  [46.687, -120.296],
                                  [248.627, -213.244],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.685, -34.629],
                                  [-15.047, -112.278],
                                  [45.235, -50.548],
                                  [-111.672, -36.768],
                                  [-16.914, -59.841],
                                  [-131.976, 11.12],
                                  [108.444, 75.26],
                                  [-35.018, 125.818],
                                  [-11.908, 92],
                                ],
                                o: [
                                  [-93.486, 37.669],
                                  [15.047, 112.278],
                                  [-45.235, 50.548],
                                  [111.672, 36.768],
                                  [19.737, 63.315],
                                  [109.829, -6.343],
                                  [-108.444, -75.26],
                                  [30.892, -129.51],
                                  [11.908, -92],
                                ],
                                v: [
                                  [-112.703, -386.937],
                                  [-163.532, -188.909],
                                  [-271.935, 2.627],
                                  [-240.265, 142.589],
                                  [-36.83, 300.223],
                                  [142.736, 397.919],
                                  [290.375, 235.183],
                                  [48.12, -118.795],
                                  [247.903, -212.051],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.873, -34.254],
                                  [-14.594, -111.765],
                                  [44.916, -50.862],
                                  [-111.103, -36.828],
                                  [-16.891, -59.578],
                                  [-132.408, 11.317],
                                  [107.676, 75.071],
                                  [-34.694, 125.166],
                                  [-11.993, 92],
                                ],
                                o: [
                                  [-93.345, 37.359],
                                  [14.594, 111.765],
                                  [-44.916, 50.863],
                                  [111.103, 36.828],
                                  [19.775, 63.127],
                                  [109.782, -6.437],
                                  [-107.676, -75.071],
                                  [30.479, -128.937],
                                  [11.993, -92],
                                ],
                                v: [
                                  [-111.73, -385.751],
                                  [-164.008, -189.165],
                                  [-271.832, 1.779],
                                  [-239.893, 143.095],
                                  [-37.719, 299.954],
                                  [143.105, 397.411],
                                  [289.534, 234.479],
                                  [49.633, -117.266],
                                  [247.203, -210.816],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.065, -33.871],
                                  [-14.132, -111.242],
                                  [44.59, -51.183],
                                  [-110.523, -36.889],
                                  [-16.867, -59.31],
                                  [-132.848, 11.518],
                                  [106.892, 74.879],
                                  [-34.364, 124.501],
                                  [-12.079, 92],
                                ],
                                o: [
                                  [-93.202, 37.043],
                                  [14.132, 111.242],
                                  [-44.59, 51.183],
                                  [110.523, 36.889],
                                  [19.813, 62.935],
                                  [109.734, -6.532],
                                  [-106.892, -74.879],
                                  [30.058, -128.353],
                                  [12.079, -92],
                                ],
                                v: [
                                  [-110.731, -384.603],
                                  [-164.527, -189.464],
                                  [-271.674, 0.877],
                                  [-239.642, 143.64],
                                  [-38.614, 299.693],
                                  [143.42, 396.853],
                                  [288.62, 233.849],
                                  [51.239, -115.609],
                                  [246.415, -209.623],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.26, -33.481],
                                  [-13.661, -110.71],
                                  [44.259, -51.51],
                                  [-109.932, -36.952],
                                  [-16.843, -59.036],
                                  [-133.297, 11.723],
                                  [106.093, 74.682],
                                  [-34.027, 123.823],
                                  [-12.167, 92],
                                ],
                                o: [
                                  [-93.055, 36.722],
                                  [13.661, 110.71],
                                  [-44.259, 51.51],
                                  [109.932, 36.952],
                                  [19.852, 62.74],
                                  [109.685, -6.63],
                                  [-106.093, -74.682],
                                  [29.629, -127.758],
                                  [12.167, -92],
                                ],
                                v: [
                                  [-109.656, -383.396],
                                  [-165.089, -189.82],
                                  [-271.511, 0.047],
                                  [-239.372, 144.158],
                                  [-39.543, 299.441],
                                  [143.715, 396.23],
                                  [287.751, 233.23],
                                  [52.799, -113.953],
                                  [245.697, -208.403],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.459, -33.083],
                                  [-13.181, -110.166],
                                  [43.92, -51.843],
                                  [-109.329, -37.015],
                                  [-16.818, -58.758],
                                  [-133.755, 11.931],
                                  [105.279, 74.482],
                                  [-33.684, 123.131],
                                  [-12.256, 92],
                                ],
                                o: [
                                  [-92.906, 36.393],
                                  [13.181, 110.166],
                                  [-43.92, 51.843],
                                  [109.329, 37.015],
                                  [19.892, 62.541],
                                  [109.635, -6.729],
                                  [-105.279, -74.482],
                                  [29.191, -127.151],
                                  [12.256, -92],
                                ],
                                v: [
                                  [-108.567, -382.162],
                                  [-165.693, -190.231],
                                  [-271.246, -0.803],
                                  [-239.174, 144.727],
                                  [-40.444, 299.198],
                                  [143.893, 395.605],
                                  [286.859, 232.622],
                                  [54.5, -112.255],
                                  [244.904, -207.128],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.661, -32.677],
                                  [-12.692, -109.613],
                                  [43.576, -52.183],
                                  [-108.714, -37.08],
                                  [-16.792, -58.474],
                                  [-134.221, 12.144],
                                  [104.449, 74.278],
                                  [-33.334, 122.427],
                                  [-12.348, 92],
                                ],
                                o: [
                                  [-92.754, 36.059],
                                  [12.692, 109.613],
                                  [-43.576, 52.183],
                                  [108.714, 37.08],
                                  [19.932, 62.339],
                                  [109.585, -6.831],
                                  [-104.449, -74.278],
                                  [28.745, -126.533],
                                  [12.348, -92],
                                ],
                                v: [
                                  [-107.45, -380.982],
                                  [-166.321, -190.635],
                                  [-271.038, -1.644],
                                  [-239.017, 145.268],
                                  [-41.398, 298.919],
                                  [144.018, 394.883],
                                  [285.994, 232.104],
                                  [56.231, -110.447],
                                  [244.114, -205.91],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [109.868, -32.264],
                                  [-12.194, -109.048],
                                  [43.225, -52.529],
                                  [-108.088, -37.145],
                                  [-16.766, -58.185],
                                  [-134.696, 12.361],
                                  [103.603, 74.07],
                                  [-32.978, 121.709],
                                  [-12.441, 92],
                                ],
                                o: [
                                  [-92.599, 35.718],
                                  [12.194, 109.048],
                                  [-43.225, 52.529],
                                  [108.088, 37.145],
                                  [19.974, 62.132],
                                  [109.533, -6.934],
                                  [-103.603, -74.07],
                                  [28.291, -125.903],
                                  [12.441, -92],
                                ],
                                v: [
                                  [-106.255, -379.747],
                                  [-167.003, -191.125],
                                  [-270.716, -2.43],
                                  [-238.868, 145.81],
                                  [-42.34, 298.698],
                                  [144.122, 394.13],
                                  [285.141, 231.599],
                                  [57.979, -108.627],
                                  [243.295, -204.637],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.079, -31.843],
                                  [-11.686, -108.473],
                                  [42.866, -52.882],
                                  [-107.449, -37.213],
                                  [-16.74, -57.89],
                                  [-135.181, 12.582],
                                  [102.741, 73.858],
                                  [-32.615, 120.977],
                                  [-12.535, 92],
                                ],
                                o: [
                                  [-92.441, 35.37],
                                  [11.686, 108.473],
                                  [-42.866, 52.882],
                                  [107.449, 37.213],
                                  [20.016, 61.922],
                                  [109.48, -7.039],
                                  [-102.741, -73.858],
                                  [27.827, -125.26],
                                  [12.535, -92],
                                ],
                                v: [
                                  [-105.046, -378.473],
                                  [-167.708, -191.619],
                                  [-270.343, -3.222],
                                  [-238.774, 146.323],
                                  [-43.351, 298.428],
                                  [144.111, 393.39],
                                  [284.3, 231.108],
                                  [59.773, -106.78],
                                  [242.445, -203.343],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.293, -31.414],
                                  [-11.168, -107.887],
                                  [42.502, -53.241],
                                  [-106.799, -37.281],
                                  [-16.713, -57.59],
                                  [-135.674, 12.808],
                                  [101.862, 73.643],
                                  [-32.244, 120.231],
                                  [-12.632, 92],
                                ],
                                o: [
                                  [-92.28, 35.016],
                                  [11.168, 107.887],
                                  [-42.502, 53.241],
                                  [106.799, 37.281],
                                  [20.059, 61.707],
                                  [109.427, -7.147],
                                  [-101.862, -73.643],
                                  [27.355, -124.606],
                                  [12.632, -92],
                                ],
                                v: [
                                  [-103.805, -377.255],
                                  [-168.447, -192.149],
                                  [-269.965, -4.022],
                                  [-238.718, 146.822],
                                  [-44.337, 298.233],
                                  [144.047, 392.571],
                                  [283.459, 230.708],
                                  [61.63, -104.827],
                                  [241.611, -202.089],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.512, -30.976],
                                  [-10.64, -107.289],
                                  [42.13, -53.607],
                                  [-106.136, -37.351],
                                  [-16.686, -57.283],
                                  [-136.177, 13.038],
                                  [100.967, 73.422],
                                  [-31.867, 119.471],
                                  [-12.73, 92],
                                ],
                                o: [
                                  [-92.116, 34.655],
                                  [10.64, 107.289],
                                  [-42.13, 53.607],
                                  [106.136, 37.351],
                                  [20.102, 61.488],
                                  [109.372, -7.256],
                                  [-100.967, -73.422],
                                  [26.874, -123.939],
                                  [12.73, -92],
                                ],
                                v: [
                                  [-102.532, -376.002],
                                  [-169.176, -192.665],
                                  [-269.568, -4.814],
                                  [-238.716, 147.305],
                                  [-45.346, 297.99],
                                  [143.916, 391.72],
                                  [282.663, 230.369],
                                  [63.565, -102.849],
                                  [240.714, -200.782],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.735, -30.53],
                                  [-10.102, -106.68],
                                  [41.751, -53.981],
                                  [-105.46, -37.422],
                                  [-16.658, -56.971],
                                  [-136.69, 13.272],
                                  [100.054, 73.198],
                                  [-31.482, 118.696],
                                  [-12.831, 92],
                                ],
                                o: [
                                  [-91.949, 34.288],
                                  [10.102, 106.68],
                                  [-41.751, 53.981],
                                  [105.46, 37.422],
                                  [20.147, 61.265],
                                  [109.316, -7.367],
                                  [-100.054, -73.198],
                                  [26.383, -123.259],
                                  [12.831, -92],
                                ],
                                v: [
                                  [-101.242, -374.761],
                                  [-169.938, -193.185],
                                  [-269.09, -5.585],
                                  [-238.719, 147.757],
                                  [-46.377, 297.763],
                                  [143.734, 390.789],
                                  [281.852, 230.078],
                                  [65.531, -100.86],
                                  [239.831, -199.485],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [110.962, -30.076],
                                  [-9.554, -106.06],
                                  [41.365, -54.361],
                                  [-104.771, -37.494],
                                  [-16.63, -56.653],
                                  [-137.213, 13.51],
                                  [99.124, 72.969],
                                  [-31.091, 117.907],
                                  [-12.933, 92],
                                ],
                                o: [
                                  [-91.778, 33.913],
                                  [9.554, 106.06],
                                  [-41.365, 54.361],
                                  [104.771, 37.494],
                                  [20.192, 61.038],
                                  [109.259, -7.481],
                                  [-99.124, -72.969],
                                  [25.884, -122.566],
                                  [12.933, -92],
                                ],
                                v: [
                                  [-99.917, -373.488],
                                  [-170.732, -193.798],
                                  [-268.611, -6.35],
                                  [-238.729, 148.254],
                                  [-47.403, 297.552],
                                  [143.531, 389.857],
                                  [281.075, 229.804],
                                  [67.529, -98.817],
                                  [238.914, -198.199],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [111.193, -29.613],
                                  [-8.996, -105.428],
                                  [40.971, -54.749],
                                  [-104.07, -37.568],
                                  [-16.601, -56.329],
                                  [-137.745, 13.753],
                                  [98.177, 72.737],
                                  [-30.691, 117.103],
                                  [-13.037, 92],
                                ],
                                o: [
                                  [-91.605, 33.531],
                                  [8.996, 105.428],
                                  [-40.971, 54.749],
                                  [104.07, 37.568],
                                  [20.239, 60.807],
                                  [109.202, -7.597],
                                  [-98.177, -72.737],
                                  [25.374, -121.86],
                                  [13.037, -92],
                                ],
                                v: [
                                  [-98.511, -372.275],
                                  [-171.512, -194.396],
                                  [-268.065, -7.125],
                                  [-238.773, 148.689],
                                  [-48.454, 297.377],
                                  [143.214, 388.924],
                                  [280.315, 229.59],
                                  [69.542, -96.704],
                                  [238.026, -196.905],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [6.062, 8.469],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.625, 8.062],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.812, 7.312],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.469, 6.938],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.156, 6.562],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.812, 6.25],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.375, 5.625],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.156, 5.344],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.844, 4.844],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.75, 4.625],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.688, 4.438],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.625, 4],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.656, 3.844],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.656, 3.656],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.594, 3.5],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.688, 3.344],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.812, 3.219],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.875, 3.031],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.969, 2.938],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.031, 2.844],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.062, 2.656],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.156, 2.562],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.188, 2.438],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.219, 2.375],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.188, 2.25],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.188, 2.094],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, 2],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.062, 1.875],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.531, 1.75],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, 1.625],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.344, 1.469],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, 1.344],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.125, 1.188],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.5, 1.031],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.906, 0.875],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.281, 0.719],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.719, 0.5],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.531, 0.062],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.969, -0.125],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, -0.562],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.188, -0.75],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.656, -0.906],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.062, -1.031],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.5, -1.188],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.906, -1.281],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.375, -1.438],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, -1.562],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.719, -1.625],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.188, -1.656],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.656, -1.719],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.156, -1.719],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.625, -1.75],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.562, -1.688],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.062, -1.688],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.5, -1.594],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.062, -1.531],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.531, -1.469],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, -1.344],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.531, -1.281],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.062, -1.156],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.531, -1],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.531, -0.75],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.062, -0.562],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.562, -0.375],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.062, -0.156],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 0.031],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, 0.281],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.562, 0.469],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38, 0.75],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.469, 0.969],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.969, 1.25],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.375, 1.531],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 1.812],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.25, 2.156],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.625, 2.438],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.062, 2.75],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.438, 3.125],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.781, 3.469],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.125, 3.781],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.188, 4.156],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.25, 4.625],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.25, 5.062],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.25, 6],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.25, 6.531],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.219, 7.062],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.219, 7.594],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.156, 8.188],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.125, 8.75],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.062, 9.344],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.031, 9.938],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.938, 10.594],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.875, 11.219],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.75, 11.844],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.688, 12.438],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.594, 13.094],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.469, 13.75],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.375, 14.344],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.281, 14.969],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.125, 15.594],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41, 16.219],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.875, 16.75],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.75, 17.375],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.594, 17.906],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.438, 18.5],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.312, 19.031],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.062, 19.594],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.562, 20.094],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.031, 20.562],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.5, 21],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.906, 21.438],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.312, 21.844],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 22.25],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.844, 22.625],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.094, 22.938],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.312, 23.25],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.5, 23.531],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.719, 23.781],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, 23.938],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.156, 24.25],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.281, 24.406],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.375, 24.5],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.5, 24.562],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.562, 24.625],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.656, 24.656],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 24.656],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.844, 24.656],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.938, 24.594],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22, 24.594],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.125, 24.469],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.219, 24.344],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.312, 24.188],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.438, 24.062],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.562, 23.875],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.656, 23.625],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.781, 23.406],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 23.125],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.062, 22.844],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.219, 22.5],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.438, 22.156],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, 21.812],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.844, 21.438],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.031, 21.031],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.312, 20.562],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.812, 19.688],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.125, 19.188],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.406, 18.719],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.75, 18.156],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.125, 17.625],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.531, 17.094],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.406, 16.562],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.438, 16.031],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.469, 15.469],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.594, 14.906],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.688, 14.281],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.875, 13.75],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.125, 13.188],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.312, 12.562],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.594, 12],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.875, 11.469],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.219, 10.875],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.969, 9.75],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.438, 9.188],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.906, 8.656],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.406, 8.094],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.969, 7.656],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.469, 7.094],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10, 6.625],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, 5.312],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.156, 4.938],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.688, 4.562],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.281, 4.188],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.344, 3.562],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.906, 3.25],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.469, 2.969],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.031, 2.719],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.625, 2.5],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.688, 2.062],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.188, 1.906],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.719, 1.719],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.25, 1.562],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.688, 1.438],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.188, 1.344],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.656, 1.219],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.156, 1.156],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.562, 1.062],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.844, 1.062],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.344, 1.031],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.875, 1],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.312, 1.031],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.812, 1.094],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.312, 1.125],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.75, 1.25],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.219, 1.312],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.656, 1.375],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.156, 1.531],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.594, 1.625],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.062, 1.781],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.531, 2],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.969, 2.156],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.469, 2.375],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.938, 2.594],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.406, 2.844],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.875, 3.062],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.375, 3.344],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.906, 3.594],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.469, 3.906],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.938, 4.219],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 4.5],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.062, 4.812],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.656, 5.188],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.219, 5.5],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.875, 5.844],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.469, 6.125],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.125, 6.438],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.812, 6.719],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.469, 6.938],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.219, 7.188],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7, 7.438],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.75, 7.656],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.562, 7.875],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.406, 8.031],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.219, 8.188],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.125, 8.344],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [6.062, 8.469],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.625, 8.062],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.812, 7.312],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.469, 6.938],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.156, 6.562],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.812, 6.25],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.375, 5.625],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.156, 5.344],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.844, 4.844],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.75, 4.625],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.688, 4.438],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.625, 4],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.656, 3.844],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.656, 3.656],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.594, 3.5],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.688, 3.344],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.812, 3.219],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.875, 3.031],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.969, 2.938],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.031, 2.844],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.062, 2.656],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.156, 2.562],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.188, 2.438],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.219, 2.375],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.188, 2.25],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.188, 2.094],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, 2],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.062, 1.875],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.531, 1.75],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, 1.625],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.344, 1.469],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, 1.344],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.125, 1.188],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.5, 1.031],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.906, 0.875],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.281, 0.719],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.719, 0.5],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.531, 0.062],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.969, -0.125],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.781, -0.562],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.188, -0.75],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.656, -0.906],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.062, -1.031],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.5, -1.188],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.906, -1.281],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.375, -1.438],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.25, -1.562],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.719, -1.625],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.188, -1.656],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.656, -1.719],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.156, -1.719],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.625, -1.75],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.562, -1.688],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.062, -1.688],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.5, -1.594],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.062, -1.531],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.531, -1.469],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, -1.344],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.531, -1.281],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.062, -1.156],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.531, -1],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.531, -0.75],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.062, -0.562],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.562, -0.375],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.062, -0.156],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 0.031],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, 0.281],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.562, 0.469],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38, 0.75],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.469, 0.969],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.969, 1.25],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.375, 1.531],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 1.812],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.25, 2.156],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.625, 2.438],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.062, 2.75],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.438, 3.125],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.781, 3.469],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.125, 3.781],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.188, 4.156],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.25, 4.625],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.25, 5.062],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.25, 6],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.25, 6.531],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.219, 7.062],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.219, 7.594],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.156, 8.188],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.125, 8.75],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.062, 9.344],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.031, 9.938],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.938, 10.594],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.875, 11.219],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.75, 11.844],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.688, 12.438],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.594, 13.094],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.469, 13.75],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.375, 14.344],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.281, 14.969],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.125, 15.594],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41, 16.219],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.875, 16.75],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.75, 17.375],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.594, 17.906],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.438, 18.5],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.312, 19.031],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.062, 19.594],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.562, 20.094],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.031, 20.562],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.5, 21],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.906, 21.438],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.312, 21.844],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 22.25],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.844, 22.625],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.094, 22.938],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.312, 23.25],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.5, 23.531],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.719, 23.781],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, 23.938],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.156, 24.25],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.281, 24.406],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.375, 24.5],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.5, 24.562],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.562, 24.625],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.656, 24.656],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 24.656],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.844, 24.656],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.938, 24.594],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22, 24.594],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.125, 24.469],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.219, 24.344],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.312, 24.188],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.438, 24.062],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.562, 23.875],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.656, 23.625],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.781, 23.406],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 23.125],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.062, 22.844],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.219, 22.5],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.438, 22.156],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, 21.812],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.844, 21.438],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.031, 21.031],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.312, 20.562],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.812, 19.688],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.125, 19.188],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.406, 18.719],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.75, 18.156],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.125, 17.625],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.531, 17.094],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.406, 16.562],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.438, 16.031],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.469, 15.469],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.594, 14.906],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.688, 14.281],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.875, 13.75],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.125, 13.188],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.312, 12.562],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.594, 12],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.875, 11.469],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.219, 10.875],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.969, 9.75],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.438, 9.188],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.906, 8.656],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.406, 8.094],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.969, 7.656],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.469, 7.094],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10, 6.625],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, 5.312],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.156, 4.938],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.688, 4.562],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.281, 4.188],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.344, 3.562],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.906, 3.25],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.469, 2.969],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.031, 2.719],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.625, 2.5],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.688, 2.062],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.188, 1.906],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.719, 1.719],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.25, 1.562],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.688, 1.438],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.188, 1.344],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.656, 1.219],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.156, 1.156],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.562, 1.062],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.844, 1.062],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.344, 1.031],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.875, 1],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.312, 1.031],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.812, 1.094],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.312, 1.125],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.75, 1.25],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.219, 1.312],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.656, 1.375],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.156, 1.531],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.594, 1.625],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.062, 1.781],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.531, 2],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.969, 2.156],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.469, 2.375],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.938, 2.594],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.406, 2.844],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.875, 3.062],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.375, 3.344],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.906, 3.594],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.469, 3.906],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.938, 4.219],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 4.5],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.062, 4.812],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.656, 5.188],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.219, 5.5],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.875, 5.844],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.469, 6.125],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.125, 6.438],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.812, 6.719],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.469, 6.938],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.219, 7.188],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7, 7.438],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.75, 7.656],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.562, 7.875],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.406, 8.031],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.219, 8.188],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.125, 8.344],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 11',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 11,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [108.572, -34.856],
                                  [-15.32, -112.587],
                                  [45.427, -50.358],
                                  [-112.015, -36.732],
                                  [-16.928, -59.999],
                                  [-131.716, 11.001],
                                  [108.907, 75.374],
                                  [-35.213, 126.211],
                                  [-11.858, 92],
                                ],
                                o: [
                                  [-93.571, 37.856],
                                  [15.319, 112.587],
                                  [-45.427, 50.359],
                                  [112.015, 36.732],
                                  [19.714, 63.428],
                                  [109.857, -6.286],
                                  [-108.907, -75.374],
                                  [31.141, -129.855],
                                  [11.857, -92],
                                ],
                                v: [
                                  [-107.488, -382.823],
                                  [-171.041, -187.605],
                                  [-269.79, -2.635],
                                  [-237.808, 148.848],
                                  [-33.87, 303.387],
                                  [144.589, 392.82],
                                  [275.149, 227.277],
                                  [49.27, -115.985],
                                  [249.84, -203.279],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.378, -35.244],
                                  [-15.788, -113.118],
                                  [45.758, -50.033],
                                  [-112.605, -36.67],
                                  [-16.953, -60.271],
                                  [-131.269, 10.797],
                                  [109.703, 75.57],
                                  [-35.548, 126.887],
                                  [-11.77, 92],
                                ],
                                o: [
                                  [-93.717, 38.177],
                                  [15.788, 113.118],
                                  [-45.758, 50.033],
                                  [112.605, 36.67],
                                  [19.676, 63.622],
                                  [109.906, -6.189],
                                  [-109.703, -75.57],
                                  [31.569, -130.448],
                                  [11.77, -92],
                                ],
                                v: [
                                  [-107.641, -382.923],
                                  [-171.778, -187.034],
                                  [-269.28, -2.178],
                                  [-237.793, 149.636],
                                  [-32.546, 303.909],
                                  [144.184, 392.393],
                                  [273.829, 226.964],
                                  [48.405, -116.543],
                                  [251.198, -203.118],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.187, -35.625],
                                  [-16.248, -113.638],
                                  [46.081, -49.714],
                                  [-113.182, -36.61],
                                  [-16.977, -60.538],
                                  [-130.831, 10.597],
                                  [110.483, 75.761],
                                  [-35.877, 127.549],
                                  [-11.684, 92],
                                ],
                                o: [
                                  [-93.859, 38.491],
                                  [16.248, 113.638],
                                  [-46.081, 49.714],
                                  [113.182, 36.61],
                                  [19.637, 63.813],
                                  [109.953, -6.094],
                                  [-110.483, -75.761],
                                  [31.988, -131.028],
                                  [11.684, -92],
                                ],
                                v: [
                                  [-107.768, -382.997],
                                  [-172.469, -186.541],
                                  [-268.722, -1.729],
                                  [-237.761, 150.44],
                                  [-31.179, 304.4],
                                  [143.676, 391.956],
                                  [272.518, 226.659],
                                  [47.527, -117.058],
                                  [252.55, -202.982],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.001, -35.998],
                                  [-16.698, -114.147],
                                  [46.398, -49.402],
                                  [-113.747, -36.55],
                                  [-17, -60.799],
                                  [-130.402, 10.401],
                                  [111.246, 75.949],
                                  [-36.198, 128.196],
                                  [-11.6, 92],
                                ],
                                o: [
                                  [-93.999, 38.798],
                                  [16.698, 114.147],
                                  [-46.398, 49.402],
                                  [113.747, 36.55],
                                  [19.6, 63.999],
                                  [110, -6],
                                  [-111.246, -75.949],
                                  [32.398, -131.597],
                                  [11.6, -92],
                                ],
                                v: [
                                  [-107.934, -383.046],
                                  [-173.225, -186.053],
                                  [-268.187, -1.202],
                                  [-237.775, 151.262],
                                  [-29.806, 304.823],
                                  [143.199, 391.498],
                                  [271.264, 226.351],
                                  [46.72, -117.542],
                                  [253.895, -202.76],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.818, -36.363],
                                  [-17.138, -114.646],
                                  [46.709, -49.096],
                                  [-114.3, -36.492],
                                  [-17.023, -61.054],
                                  [-129.982, 10.209],
                                  [111.993, 76.133],
                                  [-36.513, 128.831],
                                  [-11.518, 92],
                                ],
                                o: [
                                  [-94.136, 39.1],
                                  [17.138, 114.646],
                                  [-46.709, 49.096],
                                  [114.3, 36.492],
                                  [19.564, 64.182],
                                  [110.045, -5.909],
                                  [-111.993, -76.133],
                                  [32.799, -132.154],
                                  [11.518, -92],
                                ],
                                v: [
                                  [-108.089, -383.107],
                                  [-173.996, -185.568],
                                  [-267.552, -0.694],
                                  [-237.788, 152.063],
                                  [-28.453, 305.228],
                                  [142.619, 391.032],
                                  [270.056, 226.101],
                                  [45.961, -117.947],
                                  [255.197, -202.502],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.64, -36.721],
                                  [-17.569, -115.134],
                                  [47.013, -48.796],
                                  [-114.842, -36.435],
                                  [-17.045, -61.304],
                                  [-129.571, 10.022],
                                  [112.725, 76.313],
                                  [-36.822, 129.452],
                                  [-11.438, 92],
                                ],
                                o: [
                                  [-94.27, 39.395],
                                  [17.569, 115.134],
                                  [-47.013, 48.796],
                                  [114.842, 36.435],
                                  [19.528, 64.36],
                                  [110.09, -5.82],
                                  [-112.725, -76.313],
                                  [33.193, -132.699],
                                  [11.438, -92],
                                ],
                                v: [
                                  [-108.195, -383.082],
                                  [-174.732, -185.162],
                                  [-266.891, -0.142],
                                  [-237.836, 152.879],
                                  [-27.107, 305.565],
                                  [141.982, 390.557],
                                  [268.857, 225.898],
                                  [45.223, -118.361],
                                  [256.553, -202.26],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.465, -37.071],
                                  [-17.992, -115.612],
                                  [47.31, -48.503],
                                  [-115.373, -36.379],
                                  [-17.067, -61.55],
                                  [-129.169, 9.838],
                                  [113.442, 76.489],
                                  [-37.124, 130.06],
                                  [-11.359, 92],
                                ],
                                o: [
                                  [-94.402, 39.683],
                                  [17.992, 115.612],
                                  [-47.31, 48.503],
                                  [115.373, 36.379],
                                  [19.493, 64.535],
                                  [110.134, -5.732],
                                  [-113.442, -76.489],
                                  [33.578, -133.233],
                                  [11.359, -92],
                                ],
                                v: [
                                  [-108.392, -382.997],
                                  [-175.531, -184.76],
                                  [-266.218, 0.405],
                                  [-237.883, 153.658],
                                  [-25.72, 305.923],
                                  [141.278, 390.013],
                                  [267.653, 225.743],
                                  [44.518, -118.695],
                                  [257.891, -201.948],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.293, -37.414],
                                  [-18.405, -116.08],
                                  [47.602, -48.216],
                                  [-115.892, -36.325],
                                  [-17.088, -61.789],
                                  [-128.774, 9.658],
                                  [114.143, 76.661],
                                  [-37.419, 130.656],
                                  [-11.282, 92],
                                ],
                                o: [
                                  [-94.53, 39.966],
                                  [18.405, 116.08],
                                  [-47.602, 48.216],
                                  [115.892, 36.325],
                                  [19.459, 64.707],
                                  [110.177, -5.647],
                                  [-114.143, -76.661],
                                  [33.955, -133.756],
                                  [11.282, -92],
                                ],
                                v: [
                                  [-108.538, -382.878],
                                  [-176.294, -184.374],
                                  [-265.482, 0.974],
                                  [-237.98, 154.399],
                                  [-24.391, 306.215],
                                  [140.568, 389.511],
                                  [266.559, 225.597],
                                  [43.835, -119.051],
                                  [259.245, -201.643],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.125, -37.749],
                                  [-18.81, -116.539],
                                  [47.887, -47.935],
                                  [-116.401, -36.271],
                                  [-17.109, -62.024],
                                  [-128.388, 9.482],
                                  [114.83, 76.83],
                                  [-37.709, 131.239],
                                  [-11.206, 92],
                                ],
                                o: [
                                  [-94.656, 40.243],
                                  [18.81, 116.539],
                                  [-47.887, 47.935],
                                  [116.401, 36.271],
                                  [19.425, 64.875],
                                  [110.219, -5.563],
                                  [-114.83, -76.83],
                                  [34.324, -134.267],
                                  [11.206, -92],
                                ],
                                v: [
                                  [-108.736, -382.705],
                                  [-177.058, -184.056],
                                  [-264.733, 1.601],
                                  [-238.066, 155.154],
                                  [-23.072, 306.479],
                                  [139.791, 388.951],
                                  [265.411, 225.449],
                                  [43.247, -119.315],
                                  [260.529, -201.268],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.961, -38.078],
                                  [-19.206, -116.987],
                                  [48.166, -47.66],
                                  [-116.899, -36.219],
                                  [-17.13, -62.254],
                                  [-128.011, 9.309],
                                  [115.503, 76.995],
                                  [-37.992, 131.81],
                                  [-11.133, 92],
                                ],
                                o: [
                                  [-94.779, 40.514],
                                  [19.206, 116.987],
                                  [-48.166, 47.66],
                                  [116.899, 36.219],
                                  [19.392, 65.039],
                                  [110.26, -5.481],
                                  [-115.503, -76.995],
                                  [34.686, -134.769],
                                  [11.133, -92],
                                ],
                                v: [
                                  [-108.934, -382.551],
                                  [-177.846, -183.69],
                                  [-263.973, 2.266],
                                  [-238.192, 155.931],
                                  [-21.773, 306.727],
                                  [138.957, 388.435],
                                  [264.383, 225.349],
                                  [42.628, -119.592],
                                  [261.867, -200.926],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.8, -38.399],
                                  [-19.594, -117.427],
                                  [48.44, -47.39],
                                  [-117.387, -36.168],
                                  [-17.15, -62.48],
                                  [-127.641, 9.14],
                                  [116.161, 77.157],
                                  [-38.27, 132.369],
                                  [-11.06, 92],
                                ],
                                o: [
                                  [-94.9, 40.78],
                                  [19.594, 117.427],
                                  [-48.44, 47.391],
                                  [117.387, 36.168],
                                  [19.36, 65.2],
                                  [110.3, -5.4],
                                  [-116.161, -77.157],
                                  [35.039, -135.259],
                                  [11.06, -92],
                                ],
                                v: [
                                  [-109.121, -382.295],
                                  [-178.635, -183.393],
                                  [-263.149, 2.969],
                                  [-238.33, 156.616],
                                  [-20.493, 306.899],
                                  [138.117, 387.85],
                                  [263.291, 225.3],
                                  [42.04, -119.79],
                                  [263.197, -200.546],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.643, -38.714],
                                  [-19.974, -117.857],
                                  [48.707, -47.127],
                                  [-117.864, -36.117],
                                  [-17.17, -62.7],
                                  [-127.278, 8.975],
                                  [116.806, 77.316],
                                  [-38.541, 132.916],
                                  [-10.989, 92],
                                ],
                                o: [
                                  [-95.018, 41.039],
                                  [19.974, 117.857],
                                  [-48.707, 47.127],
                                  [117.864, 36.117],
                                  [19.329, 65.357],
                                  [110.339, -5.321],
                                  [-116.806, -77.316],
                                  [35.386, -135.739],
                                  [10.989, -92],
                                ],
                                v: [
                                  [-109.307, -382.063],
                                  [-179.395, -183.11],
                                  [-262.355, 3.67],
                                  [-238.458, 157.373],
                                  [-19.213, 307.118],
                                  [137.221, 387.258],
                                  [262.309, 225.259],
                                  [41.525, -119.96],
                                  [264.479, -200.098],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.489, -39.023],
                                  [-20.346, -118.278],
                                  [48.969, -46.869],
                                  [-118.331, -36.068],
                                  [-17.189, -62.916],
                                  [-126.924, 8.813],
                                  [117.437, 77.471],
                                  [-38.807, 133.452],
                                  [-10.92, 92],
                                ],
                                o: [
                                  [-95.133, 41.294],
                                  [20.346, 118.278],
                                  [-48.969, 46.869],
                                  [118.331, 36.068],
                                  [19.298, 65.511],
                                  [110.378, -5.244],
                                  [-117.437, -77.471],
                                  [35.725, -136.209],
                                  [10.92, -92],
                                ],
                                v: [
                                  [-109.534, -381.731],
                                  [-180.155, -182.843],
                                  [-261.509, 4.421],
                                  [-238.639, 158.035],
                                  [-17.911, 307.251],
                                  [136.256, 386.723],
                                  [261.335, 225.259],
                                  [40.998, -120.093],
                                  [265.804, -199.676],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.338, -39.324],
                                  [-20.71, -118.69],
                                  [49.226, -46.616],
                                  [-118.788, -36.02],
                                  [-17.208, -63.127],
                                  [-126.577, 8.655],
                                  [118.055, 77.623],
                                  [-39.067, 133.976],
                                  [-10.852, 92],
                                ],
                                o: [
                                  [-95.247, 41.543],
                                  [20.71, 118.69],
                                  [-49.226, 46.616],
                                  [118.788, 36.02],
                                  [19.268, 65.662],
                                  [110.416, -5.169],
                                  [-118.055, -77.623],
                                  [36.057, -136.67],
                                  [10.852, -92],
                                ],
                                v: [
                                  [-109.76, -381.415],
                                  [-180.874, -182.581],
                                  [-260.599, 5.162],
                                  [-238.812, 158.705],
                                  [-16.66, 307.423],
                                  [135.285, 386.118],
                                  [260.411, 225.32],
                                  [40.534, -120.251],
                                  [267.078, -199.229],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.19, -39.62],
                                  [-21.066, -119.093],
                                  [49.477, -46.368],
                                  [-119.236, -35.973],
                                  [-17.226, -63.334],
                                  [-126.237, 8.5],
                                  [118.659, 77.771],
                                  [-39.322, 134.489],
                                  [-10.786, 92],
                                ],
                                o: [
                                  [-95.357, 41.786],
                                  [21.066, 119.093],
                                  [-49.477, 46.368],
                                  [119.236, 35.973],
                                  [19.238, 65.81],
                                  [110.452, -5.095],
                                  [-118.659, -77.771],
                                  [36.382, -137.12],
                                  [10.786, -92],
                                ],
                                v: [
                                  [-109.986, -381.128],
                                  [-181.656, -182.343],
                                  [-259.721, 5.954],
                                  [-238.987, 159.383],
                                  [-15.439, 307.53],
                                  [134.257, 385.56],
                                  [259.548, 225.381],
                                  [40.056, -120.321],
                                  [268.333, -198.811],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.046, -39.909],
                                  [-21.415, -119.488],
                                  [49.722, -46.126],
                                  [-119.674, -35.927],
                                  [-17.244, -63.536],
                                  [-125.905, 8.348],
                                  [119.251, 77.917],
                                  [-39.571, 134.992],
                                  [-10.72, 92],
                                ],
                                o: [
                                  [-95.466, 42.025],
                                  [21.415, 119.488],
                                  [-49.722, 46.126],
                                  [119.674, 35.927],
                                  [19.209, 65.954],
                                  [110.489, -5.023],
                                  [-119.251, -77.917],
                                  [36.7, -137.561],
                                  [10.721, -92],
                                ],
                                v: [
                                  [-110.253, -380.745],
                                  [-182.342, -182.11],
                                  [-258.843, 6.789],
                                  [-239.217, 160.026],
                                  [-14.227, 307.624],
                                  [133.223, 384.943],
                                  [258.682, 225.432],
                                  [39.598, -120.408],
                                  [269.59, -198.328],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.904, -40.192],
                                  [-21.756, -119.874],
                                  [49.963, -45.889],
                                  [-120.103, -35.882],
                                  [-17.262, -63.734],
                                  [-125.579, 8.199],
                                  [119.83, 78.059],
                                  [-39.815, 135.483],
                                  [-10.657, 92],
                                ],
                                o: [
                                  [-95.572, 42.258],
                                  [21.756, 119.874],
                                  [-49.963, 45.889],
                                  [120.103, 35.882],
                                  [19.181, 66.096],
                                  [110.524, -4.952],
                                  [-119.83, -78.059],
                                  [37.011, -137.992],
                                  [10.657, -92],
                                ],
                                v: [
                                  [-110.518, -380.393],
                                  [-183.091, -181.891],
                                  [-257.892, 7.633],
                                  [-239.44, 160.622],
                                  [-12.981, 307.687],
                                  [132.121, 384.365],
                                  [257.868, 225.546],
                                  [39.168, -120.417],
                                  [270.847, -197.822],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.766, -40.469],
                                  [-22.09, -120.252],
                                  [50.198, -45.658],
                                  [-120.523, -35.838],
                                  [-17.279, -63.928],
                                  [-125.261, 8.054],
                                  [120.397, 78.198],
                                  [-40.054, 135.964],
                                  [-10.595, 92],
                                ],
                                o: [
                                  [-95.676, 42.487],
                                  [22.09, 120.252],
                                  [-50.198, 45.658],
                                  [120.523, 35.838],
                                  [19.153, 66.234],
                                  [110.559, -4.883],
                                  [-120.397, -78.198],
                                  [37.315, -138.415],
                                  [10.595, -92],
                                ],
                                v: [
                                  [-110.773, -380.003],
                                  [-183.733, -181.686],
                                  [-256.984, 8.513],
                                  [-239.674, 161.17],
                                  [-11.797, 307.748],
                                  [131.023, 383.79],
                                  [257.115, 225.659],
                                  [38.819, -120.446],
                                  [272.087, -197.349],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.63, -40.74],
                                  [-22.417, -120.623],
                                  [50.429, -45.431],
                                  [-120.933, -35.795],
                                  [-17.296, -64.118],
                                  [-124.949, 7.912],
                                  [120.951, 78.335],
                                  [-40.288, 136.435],
                                  [-10.534, 92],
                                ],
                                o: [
                                  [-95.777, 42.71],
                                  [22.417, 120.623],
                                  [-50.429, 45.431],
                                  [120.933, 35.795],
                                  [19.126, 66.37],
                                  [110.592, -4.815],
                                  [-120.951, -78.335],
                                  [37.614, -138.828],
                                  [10.534, -92],
                                ],
                                v: [
                                  [-111.072, -379.645],
                                  [-184.445, -181.494],
                                  [-256.023, 9.456],
                                  [-239.902, 161.733],
                                  [-10.641, 307.843],
                                  [129.856, 383.264],
                                  [256.423, 225.817],
                                  [38.435, -120.397],
                                  [273.326, -196.865],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.498, -41.005],
                                  [-22.737, -120.985],
                                  [50.654, -45.208],
                                  [-121.335, -35.752],
                                  [-17.313, -64.303],
                                  [-124.645, 7.772],
                                  [121.494, 78.468],
                                  [-40.517, 136.896],
                                  [-10.474, 92],
                                ],
                                o: [
                                  [-95.877, 42.929],
                                  [22.737, 120.985],
                                  [-50.654, 45.208],
                                  [121.335, 35.752],
                                  [19.1, 66.502],
                                  [110.626, -4.749],
                                  [-121.494, -78.468],
                                  [37.905, -139.232],
                                  [10.474, -92],
                                ],
                                v: [
                                  [-111.368, -379.261],
                                  [-185.05, -181.315],
                                  [-255.097, 10.392],
                                  [-240.177, 162.3],
                                  [-9.495, 307.866],
                                  [128.685, 382.688],
                                  [255.721, 226.03],
                                  [38.078, -120.368],
                                  [274.503, -196.362],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.368, -41.264],
                                  [-23.05, -121.339],
                                  [50.875, -44.991],
                                  [-121.729, -35.711],
                                  [-17.329, -64.485],
                                  [-124.346, 7.636],
                                  [122.025, 78.599],
                                  [-40.74, 137.347],
                                  [-10.416, 92],
                                ],
                                o: [
                                  [-95.974, 43.143],
                                  [23.05, 121.339],
                                  [-50.875, 44.991],
                                  [121.729, 35.711],
                                  [19.074, 66.632],
                                  [110.658, -4.684],
                                  [-122.025, -78.599],
                                  [38.191, -139.628],
                                  [10.416, -92],
                                ],
                                v: [
                                  [-111.709, -378.858],
                                  [-185.716, -181.149],
                                  [-254.163, 11.382],
                                  [-240.464, 162.763],
                                  [-8.312, 307.878],
                                  [127.508, 382.151],
                                  [255.082, 226.252],
                                  [37.739, -120.308],
                                  [275.732, -195.849],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.241, -41.518],
                                  [-23.356, -121.686],
                                  [51.09, -44.779],
                                  [-122.114, -35.671],
                                  [-17.345, -64.663],
                                  [-124.054, 7.503],
                                  [122.545, 78.726],
                                  [-40.959, 137.788],
                                  [-10.358, 92],
                                ],
                                o: [
                                  [-96.069, 43.353],
                                  [23.356, 121.686],
                                  [-51.09, 44.779],
                                  [122.114, 35.671],
                                  [19.048, 66.759],
                                  [110.69, -4.62],
                                  [-122.545, -78.726],
                                  [38.47, -140.015],
                                  [10.358, -92],
                                ],
                                v: [
                                  [-112.04, -378.476],
                                  [-186.281, -180.941],
                                  [-253.221, 12.374],
                                  [-240.692, 163.293],
                                  [-7.2, 307.873],
                                  [126.271, 381.556],
                                  [254.505, 226.467],
                                  [37.427, -120.217],
                                  [276.899, -195.31],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.117, -41.767],
                                  [-23.656, -122.025],
                                  [51.302, -44.57],
                                  [-122.49, -35.631],
                                  [-17.36, -64.837],
                                  [-123.768, 7.372],
                                  [123.054, 78.851],
                                  [-41.174, 138.22],
                                  [-10.302, 92],
                                ],
                                o: [
                                  [-96.162, 43.557],
                                  [23.656, 122.025],
                                  [-51.302, 44.57],
                                  [122.49, 35.631],
                                  [19.023, 66.883],
                                  [110.721, -4.558],
                                  [-123.054, -78.851],
                                  [38.743, -140.394],
                                  [10.302, -92],
                                ],
                                v: [
                                  [-112.37, -378.132],
                                  [-186.845, -180.799],
                                  [-252.271, 13.414],
                                  [-240.976, 163.717],
                                  [-6.106, 307.859],
                                  [125.022, 381.073],
                                  [253.981, 226.738],
                                  [37.085, -120.104],
                                  [278.056, -194.825],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.995, -42.01],
                                  [-23.949, -122.357],
                                  [51.508, -44.367],
                                  [-122.858, -35.592],
                                  [-17.376, -65.007],
                                  [-123.489, 7.245],
                                  [123.551, 78.974],
                                  [-41.383, 138.642],
                                  [-10.248, 92],
                                ],
                                o: [
                                  [-96.254, 43.758],
                                  [23.949, 122.357],
                                  [-51.508, 44.367],
                                  [122.858, 35.592],
                                  [18.999, 67.005],
                                  [110.751, -4.498],
                                  [-123.551, -78.974],
                                  [39.011, -140.765],
                                  [10.248, -92],
                                ],
                                v: [
                                  [-112.745, -377.71],
                                  [-187.424, -180.615],
                                  [-251.321, 14.456],
                                  [-241.264, 164.144],
                                  [-5.029, 307.883],
                                  [123.72, 380.531],
                                  [253.513, 227.003],
                                  [36.815, -119.961],
                                  [279.204, -194.315],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.876, -42.248],
                                  [-24.236, -122.682],
                                  [51.71, -44.168],
                                  [-123.219, -35.554],
                                  [-17.39, -65.173],
                                  [-123.215, 7.12],
                                  [124.038, 79.093],
                                  [-41.589, 139.055],
                                  [-10.194, 92],
                                ],
                                o: [
                                  [-96.343, 43.954],
                                  [24.236, 122.682],
                                  [-51.71, 44.168],
                                  [123.219, 35.554],
                                  [18.975, 67.124],
                                  [110.781, -4.438],
                                  [-124.038, -79.093],
                                  [39.272, -141.128],
                                  [10.194, -92],
                                ],
                                v: [
                                  [-113.11, -377.384],
                                  [-187.891, -180.496],
                                  [-250.41, 15.554],
                                  [-241.609, 164.526],
                                  [-3.967, 307.844],
                                  [122.469, 379.985],
                                  [253.045, 227.277],
                                  [36.515, -119.844],
                                  [280.288, -193.798],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.76, -42.48],
                                  [-24.517, -123],
                                  [51.908, -43.973],
                                  [-123.572, -35.517],
                                  [-17.405, -65.336],
                                  [-122.948, 6.998],
                                  [124.514, 79.21],
                                  [-41.789, 139.459],
                                  [-10.142, 92],
                                ],
                                o: [
                                  [-96.43, 44.146],
                                  [24.517, 123],
                                  [-51.908, 43.973],
                                  [123.572, 35.517],
                                  [18.952, 67.24],
                                  [110.81, -4.38],
                                  [-124.514, -79.21],
                                  [39.528, -141.482],
                                  [10.142, -92],
                                ],
                                v: [
                                  [-113.53, -377.028],
                                  [-188.371, -180.334],
                                  [-249.438, 16.655],
                                  [-241.895, 164.909],
                                  [-2.922, 307.846],
                                  [121.157, 379.496],
                                  [252.695, 227.601],
                                  [36.24, -119.651],
                                  [281.425, -193.274],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.646, -42.708],
                                  [-24.791, -123.31],
                                  [52.102, -43.782],
                                  [-123.917, -35.481],
                                  [-17.419, -65.495],
                                  [-122.686, 6.878],
                                  [124.98, 79.325],
                                  [-41.985, 139.855],
                                  [-10.091, 92],
                                ],
                                o: [
                                  [-96.515, 44.334],
                                  [24.791, 123.31],
                                  [-52.102, 43.782],
                                  [123.917, 35.481],
                                  [18.929, 67.354],
                                  [110.838, -4.323],
                                  [-124.98, -79.325],
                                  [39.779, -141.829],
                                  [10.091, -92],
                                ],
                                v: [
                                  [-113.94, -376.668],
                                  [-188.85, -180.126],
                                  [-248.569, 17.757],
                                  [-242.191, 165.246],
                                  [-1.893, 307.84],
                                  [119.833, 378.995],
                                  [252.346, 227.926],
                                  [36.035, -119.478],
                                  [282.498, -192.79],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.535, -42.93],
                                  [-25.06, -123.614],
                                  [52.291, -43.596],
                                  [-124.254, -35.445],
                                  [-17.433, -65.651],
                                  [-122.43, 6.762],
                                  [125.436, 79.437],
                                  [-42.178, 140.242],
                                  [-10.041, 92],
                                ],
                                o: [
                                  [-96.599, 44.518],
                                  [25.06, 123.614],
                                  [-52.291, 43.596],
                                  [124.254, 35.445],
                                  [18.907, 67.465],
                                  [110.866, -4.267],
                                  [-125.436, -79.437],
                                  [40.023, -142.169],
                                  [10.041, -92],
                                ],
                                v: [
                                  [-114.391, -376.336],
                                  [-189.278, -179.985],
                                  [-247.638, 18.91],
                                  [-242.483, 165.529],
                                  [-0.886, 307.82],
                                  [118.456, 378.56],
                                  [252.109, 228.303],
                                  [35.799, -119.223],
                                  [283.561, -192.3],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.426, -43.148],
                                  [-25.323, -123.912],
                                  [52.476, -43.413],
                                  [-124.584, -35.411],
                                  [-17.447, -65.804],
                                  [-122.18, 6.647],
                                  [125.882, 79.547],
                                  [-42.365, 140.62],
                                  [-9.992, 92],
                                ],
                                o: [
                                  [-96.681, 44.697],
                                  [25.323, 123.912],
                                  [-52.476, 43.413],
                                  [124.584, 35.411],
                                  [18.885, 67.574],
                                  [110.893, -4.213],
                                  [-125.882, -79.546],
                                  [40.263, -142.501],
                                  [9.992, -92],
                                ],
                                v: [
                                  [-114.847, -376.048],
                                  [-189.648, -179.853],
                                  [-246.757, 20.064],
                                  [-242.778, 165.811],
                                  [0.113, 307.787],
                                  [117.129, 378.114],
                                  [251.881, 228.689],
                                  [35.523, -119.005],
                                  [284.56, -191.797],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.319, -43.361],
                                  [-25.579, -124.203],
                                  [52.657, -43.235],
                                  [-124.907, -35.377],
                                  [-17.46, -65.953],
                                  [-121.935, 6.535],
                                  [126.318, 79.654],
                                  [-42.549, 140.99],
                                  [-9.944, 92],
                                ],
                                o: [
                                  [-96.76, 44.873],
                                  [25.579, 124.203],
                                  [-52.657, 43.235],
                                  [124.907, 35.377],
                                  [18.864, 67.681],
                                  [110.92, -4.16],
                                  [-126.318, -79.654],
                                  [40.497, -142.826],
                                  [9.944, -92],
                                ],
                                v: [
                                  [-115.345, -375.798],
                                  [-189.974, -179.681],
                                  [-245.925, 21.277],
                                  [-243.076, 166.095],
                                  [1.091, 307.748],
                                  [115.743, 377.67],
                                  [251.703, 229.064],
                                  [35.325, -118.76],
                                  [285.605, -191.281],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.215, -43.57],
                                  [-25.831, -124.487],
                                  [52.834, -43.06],
                                  [-125.223, -35.344],
                                  [-17.473, -66.099],
                                  [-121.695, 6.426],
                                  [126.744, 79.758],
                                  [-42.729, 141.352],
                                  [-9.897, 92],
                                ],
                                o: [
                                  [-96.839, 45.045],
                                  [25.831, 124.487],
                                  [-52.834, 43.06],
                                  [125.223, 35.344],
                                  [18.843, 67.785],
                                  [110.946, -4.108],
                                  [-126.744, -79.758],
                                  [40.727, -143.144],
                                  [9.897, -92],
                                ],
                                v: [
                                  [-115.834, -375.539],
                                  [-190.297, -179.519],
                                  [-245.089, 22.492],
                                  [-243.432, 166.329],
                                  [2.054, 307.752],
                                  [114.407, 377.229],
                                  [251.59, 229.505],
                                  [35.094, -118.484],
                                  [286.591, -190.822],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.113, -43.773],
                                  [-26.077, -124.765],
                                  [53.007, -42.89],
                                  [-125.531, -35.311],
                                  [-17.486, -66.241],
                                  [-121.461, 6.319],
                                  [127.161, 79.861],
                                  [-42.905, 141.706],
                                  [-9.851, 92],
                                ],
                                o: [
                                  [-96.915, 45.213],
                                  [26.077, 124.765],
                                  [-53.007, 42.89],
                                  [125.531, 35.311],
                                  [18.823, 67.887],
                                  [110.972, -4.057],
                                  [-127.161, -79.861],
                                  [40.951, -143.454],
                                  [9.851, -92],
                                ],
                                v: [
                                  [-116.379, -375.32],
                                  [-190.568, -179.309],
                                  [-244.304, 23.709],
                                  [-243.729, 166.513],
                                  [3.004, 307.752],
                                  [113.017, 376.84],
                                  [251.591, 229.886],
                                  [34.877, -118.191],
                                  [287.562, -190.352],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.014, -43.973],
                                  [-26.317, -125.038],
                                  [53.177, -42.723],
                                  [-125.833, -35.279],
                                  [-17.498, -66.381],
                                  [-121.231, 6.214],
                                  [127.569, 79.961],
                                  [-43.076, 142.052],
                                  [-9.806, 92],
                                ],
                                o: [
                                  [-96.99, 45.377],
                                  [26.317, 125.038],
                                  [-53.177, 42.723],
                                  [125.833, 35.279],
                                  [18.803, 67.986],
                                  [110.997, -4.007],
                                  [-127.569, -79.961],
                                  [41.17, -143.758],
                                  [9.806, -92],
                                ],
                                v: [
                                  [-116.911, -375.148],
                                  [-190.786, -179.163],
                                  [-243.514, 24.928],
                                  [-244.035, 166.69],
                                  [3.877, 307.741],
                                  [111.617, 376.453],
                                  [251.601, 230.327],
                                  [34.681, -117.867],
                                  [288.473, -189.927],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.916, -44.167],
                                  [-26.552, -125.304],
                                  [53.342, -42.56],
                                  [-126.129, -35.248],
                                  [-17.51, -66.517],
                                  [-121.007, 6.112],
                                  [127.968, 80.059],
                                  [-43.245, 142.391],
                                  [-9.762, 92],
                                ],
                                o: [
                                  [-97.063, 45.538],
                                  [26.552, 125.304],
                                  [-53.342, 42.56],
                                  [126.129, 35.248],
                                  [18.783, 68.084],
                                  [111.021, -3.958],
                                  [-127.968, -80.059],
                                  [41.384, -144.055],
                                  [9.762, -92],
                                ],
                                v: [
                                  [-117.498, -374.968],
                                  [-191.007, -178.969],
                                  [-242.776, 26.2],
                                  [-244.288, 166.816],
                                  [4.794, 307.719],
                                  [110.218, 376.061],
                                  [251.664, 230.764],
                                  [34.5, -117.527],
                                  [289.432, -189.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.821, -44.358],
                                  [-26.782, -125.564],
                                  [53.504, -42.4],
                                  [-126.418, -35.218],
                                  [-17.522, -66.651],
                                  [-120.788, 6.012],
                                  [128.358, 80.155],
                                  [-43.409, 142.722],
                                  [-9.719, 92],
                                ],
                                o: [
                                  [-97.134, 45.695],
                                  [26.782, 125.564],
                                  [-53.504, 42.4],
                                  [126.418, 35.218],
                                  [18.764, 68.179],
                                  [111.045, -3.911],
                                  [-128.358, -80.155],
                                  [41.594, -144.346],
                                  [9.719, -92],
                                ],
                                v: [
                                  [-118.079, -374.881],
                                  [-191.174, -178.732],
                                  [-242.091, 27.474],
                                  [-244.593, 166.947],
                                  [5.642, 307.75],
                                  [108.814, 375.728],
                                  [251.792, 231.256],
                                  [34.339, -117.215],
                                  [290.325, -189.121],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.728, -44.544],
                                  [-27.007, -125.818],
                                  [53.663, -42.244],
                                  [-126.7, -35.188],
                                  [-17.534, -66.781],
                                  [-120.574, 5.914],
                                  [128.739, 80.249],
                                  [-43.57, 143.046],
                                  [-9.678, 92],
                                ],
                                o: [
                                  [-97.204, 45.849],
                                  [27.007, 125.818],
                                  [-53.663, 42.244],
                                  [126.7, 35.188],
                                  [18.746, 68.272],
                                  [111.068, -3.864],
                                  [-128.739, -80.249],
                                  [41.799, -144.63],
                                  [9.677, -92],
                                ],
                                v: [
                                  [-118.709, -374.793],
                                  [-191.282, -178.559],
                                  [-241.459, 28.755],
                                  [-244.906, 167.014],
                                  [6.528, 307.777],
                                  [107.406, 375.391],
                                  [252.037, 231.7],
                                  [34.141, -116.824],
                                  [291.214, -188.741],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.637, -44.727],
                                  [-27.226, -126.067],
                                  [53.818, -42.091],
                                  [-126.976, -35.159],
                                  [-17.545, -66.909],
                                  [-120.364, 5.818],
                                  [129.112, 80.341],
                                  [-43.727, 143.362],
                                  [-9.636, 92],
                                ],
                                o: [
                                  [-97.272, 45.999],
                                  [27.226, 126.067],
                                  [-53.818, 42.091],
                                  [126.976, 35.159],
                                  [18.727, 68.363],
                                  [111.091, -3.818],
                                  [-129.112, -80.341],
                                  [41.999, -144.908],
                                  [9.637, -92],
                                ],
                                v: [
                                  [-119.334, -374.749],
                                  [-191.341, -178.342],
                                  [-240.824, 30.033],
                                  [-245.159, 167.028],
                                  [7.345, 307.789],
                                  [105.994, 375.113],
                                  [252.285, 232.194],
                                  [33.957, -116.47],
                                  [292.146, -188.414],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.548, -44.905],
                                  [-27.441, -126.311],
                                  [53.969, -41.942],
                                  [-127.246, -35.131],
                                  [-17.557, -67.033],
                                  [-120.16, 5.725],
                                  [129.477, 80.43],
                                  [-43.88, 143.672],
                                  [-9.596, 92],
                                ],
                                o: [
                                  [-97.339, 46.146],
                                  [27.441, 126.311],
                                  [-53.969, 41.942],
                                  [127.246, 35.131],
                                  [18.71, 68.452],
                                  [111.113, -3.774],
                                  [-129.477, -80.43],
                                  [42.195, -145.18],
                                  [9.596, -92],
                                ],
                                v: [
                                  [-120.009, -374.756],
                                  [-191.396, -178.073],
                                  [-240.243, 31.313],
                                  [-245.477, 167.046],
                                  [8.145, 307.856],
                                  [104.584, 374.779],
                                  [252.593, 232.748],
                                  [33.792, -116.095],
                                  [293.074, -188.135],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.461, -45.079],
                                  [-27.651, -126.548],
                                  [54.117, -41.796],
                                  [-127.51, -35.103],
                                  [-17.567, -67.155],
                                  [-119.959, 5.634],
                                  [129.833, 80.518],
                                  [-44.031, 143.975],
                                  [-9.557, 92],
                                ],
                                o: [
                                  [-97.405, 46.29],
                                  [27.651, 126.548],
                                  [-54.117, 41.796],
                                  [127.51, 35.103],
                                  [18.692, 68.539],
                                  [111.135, -3.73],
                                  [-129.833, -80.518],
                                  [42.387, -145.445],
                                  [9.557, -92],
                                ],
                                v: [
                                  [-120.736, -374.809],
                                  [-191.396, -177.811],
                                  [-239.722, 32.595],
                                  [-245.74, 167.056],
                                  [8.933, 307.919],
                                  [103.169, 374.561],
                                  [252.963, 233.363],
                                  [33.64, -115.637],
                                  [293.989, -187.911],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.375, -45.249],
                                  [-27.857, -126.781],
                                  [54.262, -41.654],
                                  [-127.768, -35.076],
                                  [-17.578, -67.274],
                                  [-119.763, 5.544],
                                  [130.182, 80.604],
                                  [-44.177, 144.27],
                                  [-9.519, 92],
                                ],
                                o: [
                                  [-97.468, 46.431],
                                  [27.857, 126.781],
                                  [-54.262, 41.654],
                                  [127.768, 35.076],
                                  [18.675, 68.625],
                                  [111.156, -3.688],
                                  [-130.182, -80.603],
                                  [42.574, -145.705],
                                  [9.519, -92],
                                ],
                                v: [
                                  [-121.463, -374.857],
                                  [-191.334, -177.554],
                                  [-239.25, 33.935],
                                  [-246, 167.012],
                                  [9.653, 307.975],
                                  [101.807, 374.287],
                                  [253.336, 233.978],
                                  [33.45, -115.218],
                                  [294.9, -187.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.292, -45.416],
                                  [-28.058, -127.008],
                                  [54.403, -41.514],
                                  [-128.021, -35.049],
                                  [-17.588, -67.391],
                                  [-119.572, 5.457],
                                  [130.523, 80.687],
                                  [-44.321, 144.56],
                                  [-9.481, 92],
                                ],
                                o: [
                                  [-97.531, 46.568],
                                  [28.058, 127.008],
                                  [-54.403, 41.514],
                                  [128.021, 35.049],
                                  [18.658, 68.708],
                                  [111.177, -3.646],
                                  [-130.523, -80.687],
                                  [42.757, -145.959],
                                  [9.481, -92],
                                ],
                                v: [
                                  [-122.237, -375.01],
                                  [-191.28, -177.255],
                                  [-238.833, 35.22],
                                  [-246.266, 166.914],
                                  [10.3, 307.966],
                                  [100.39, 374.066],
                                  [253.765, 234.706],
                                  [33.277, -114.837],
                                  [295.861, -187.512],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.211, -45.578],
                                  [-28.254, -127.23],
                                  [54.542, -41.378],
                                  [-128.267, -35.023],
                                  [-17.599, -67.505],
                                  [-119.385, 5.371],
                                  [130.856, 80.769],
                                  [-44.461, 144.842],
                                  [-9.445, 92],
                                ],
                                o: [
                                  [-97.592, 46.702],
                                  [28.254, 127.23],
                                  [-54.542, 41.378],
                                  [128.267, 35.023],
                                  [18.642, 68.789],
                                  [111.197, -3.605],
                                  [-130.856, -80.769],
                                  [42.936, -146.207],
                                  [9.445, -92],
                                ],
                                v: [
                                  [-123.011, -375.221],
                                  [-191.164, -176.957],
                                  [-238.473, 36.507],
                                  [-246.477, 166.808],
                                  [10.878, 307.955],
                                  [99.026, 373.845],
                                  [254.204, 235.44],
                                  [33.116, -114.377],
                                  [296.761, -187.391],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.131, -45.737],
                                  [-28.446, -127.448],
                                  [54.677, -41.245],
                                  [-128.508, -34.998],
                                  [-17.609, -67.616],
                                  [-119.202, 5.288],
                                  [131.181, 80.849],
                                  [-44.598, 145.119],
                                  [-9.409, 92],
                                ],
                                o: [
                                  [-97.652, 46.833],
                                  [28.446, 127.448],
                                  [-54.677, 41.245],
                                  [128.508, 34.998],
                                  [18.626, 68.869],
                                  [111.217, -3.566],
                                  [-131.181, -80.849],
                                  [43.111, -146.45],
                                  [9.409, -92],
                                ],
                                v: [
                                  [-123.832, -375.481],
                                  [-190.997, -176.667],
                                  [-238.111, 37.789],
                                  [-246.743, 166.704],
                                  [11.383, 307.88],
                                  [97.663, 373.683],
                                  [254.761, 236.236],
                                  [32.973, -113.898],
                                  [297.705, -187.331],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.054, -45.893],
                                  [-28.633, -127.66],
                                  [54.809, -41.115],
                                  [-128.744, -34.973],
                                  [-17.618, -67.725],
                                  [-119.023, 5.206],
                                  [131.499, 80.927],
                                  [-44.733, 145.389],
                                  [-9.374, 92],
                                ],
                                o: [
                                  [-97.71, 46.962],
                                  [28.633, 127.66],
                                  [-54.809, 41.115],
                                  [128.744, 34.973],
                                  [18.611, 68.946],
                                  [111.237, -3.527],
                                  [-131.499, -80.927],
                                  [43.282, -146.687],
                                  [9.374, -92],
                                ],
                                v: [
                                  [-124.654, -375.732],
                                  [-190.768, -176.329],
                                  [-237.753, 39.078],
                                  [-246.957, 166.544],
                                  [11.824, 307.744],
                                  [96.296, 373.522],
                                  [255.261, 237.086],
                                  [32.846, -113.461],
                                  [298.642, -187.264],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.978, -46.045],
                                  [-28.817, -127.867],
                                  [54.938, -40.987],
                                  [-128.974, -34.949],
                                  [-17.628, -67.831],
                                  [-118.849, 5.126],
                                  [131.81, 81.004],
                                  [-44.864, 145.653],
                                  [-9.34, 92],
                                ],
                                o: [
                                  [-97.767, 47.087],
                                  [28.817, 127.867],
                                  [-54.938, 40.988],
                                  [128.974, 34.949],
                                  [18.596, 69.022],
                                  [111.256, -3.489],
                                  [-131.81, -81.004],
                                  [43.449, -146.918],
                                  [9.34, -92],
                                ],
                                v: [
                                  [-125.528, -376.043],
                                  [-190.539, -175.995],
                                  [-237.446, 40.31],
                                  [-247.177, 166.377],
                                  [12.134, 307.602],
                                  [94.93, 373.419],
                                  [255.822, 237.941],
                                  [32.674, -112.945],
                                  [299.518, -187.201],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.903, -46.193],
                                  [-28.996, -128.07],
                                  [55.064, -40.863],
                                  [-129.199, -34.925],
                                  [-17.637, -67.935],
                                  [-118.678, 5.049],
                                  [132.114, 81.078],
                                  [-44.992, 145.911],
                                  [-9.307, 92],
                                ],
                                o: [
                                  [-97.822, 47.209],
                                  [28.996, 128.07],
                                  [-55.064, 40.863],
                                  [129.199, 34.925],
                                  [18.581, 69.097],
                                  [111.274, -3.452],
                                  [-132.114, -81.078],
                                  [43.613, -147.145],
                                  [9.307, -92],
                                ],
                                v: [
                                  [-126.394, -376.345],
                                  [-190.253, -175.67],
                                  [-237.202, 41.543],
                                  [-247.393, 166.154],
                                  [12.433, 307.399],
                                  [93.618, 373.317],
                                  [256.446, 238.801],
                                  [32.517, -112.471],
                                  [300.444, -187.247],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.831, -46.338],
                                  [-29.171, -128.268],
                                  [55.188, -40.742],
                                  [-129.419, -34.902],
                                  [-17.646, -68.037],
                                  [-118.511, 4.972],
                                  [132.411, 81.151],
                                  [-45.117, 146.163],
                                  [-9.274, 92],
                                ],
                                o: [
                                  [-97.877, 47.329],
                                  [29.171, 128.268],
                                  [-55.188, 40.742],
                                  [129.419, 34.902],
                                  [18.566, 69.169],
                                  [111.292, -3.415],
                                  [-132.411, -81.151],
                                  [43.772, -147.366],
                                  [9.274, -92],
                                ],
                                v: [
                                  [-127.26, -376.645],
                                  [-189.963, -175.289],
                                  [-236.898, 42.719],
                                  [-247.611, 165.932],
                                  [12.664, 307.252],
                                  [92.303, 373.215],
                                  [257.017, 239.779],
                                  [32.376, -111.978],
                                  [301.362, -187.235],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.76, -46.48],
                                  [-29.342, -128.462],
                                  [55.308, -40.623],
                                  [-129.634, -34.88],
                                  [-17.655, -68.136],
                                  [-118.348, 4.898],
                                  [132.702, 81.223],
                                  [-45.239, 146.409],
                                  [-9.242, 92],
                                ],
                                o: [
                                  [-97.93, 47.446],
                                  [29.342, 128.462],
                                  [-55.308, 40.623],
                                  [129.634, 34.88],
                                  [18.552, 69.24],
                                  [111.31, -3.38],
                                  [-132.702, -81.223],
                                  [43.928, -147.582],
                                  [9.242, -92],
                                ],
                                v: [
                                  [-128.122, -376.938],
                                  [-189.62, -174.916],
                                  [-236.709, 43.837],
                                  [-247.834, 165.702],
                                  [12.821, 306.983],
                                  [90.989, 373.171],
                                  [257.703, 240.699],
                                  [32.192, -111.469],
                                  [302.332, -187.342],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.691, -46.619],
                                  [-29.509, -128.651],
                                  [55.426, -40.507],
                                  [-129.844, -34.858],
                                  [-17.664, -68.233],
                                  [-118.188, 4.825],
                                  [132.985, 81.293],
                                  [-45.359, 146.65],
                                  [-9.211, 92],
                                ],
                                o: [
                                  [-97.982, 47.561],
                                  [29.509, 128.651],
                                  [-55.426, 40.507],
                                  [129.844, 34.858],
                                  [18.538, 69.309],
                                  [111.327, -3.345],
                                  [-132.985, -81.293],
                                  [44.081, -147.794],
                                  [9.211, -92],
                                ],
                                v: [
                                  [-128.976, -377.17],
                                  [-189.214, -174.492],
                                  [-236.466, 44.958],
                                  [-248, 165.415],
                                  [12.856, 306.77],
                                  [89.676, 373.126],
                                  [258.337, 241.684],
                                  [32.081, -110.943],
                                  [303.236, -187.444],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.623, -46.754],
                                  [-29.672, -128.836],
                                  [55.541, -40.393],
                                  [-130.049, -34.836],
                                  [-17.672, -68.328],
                                  [-118.033, 4.754],
                                  [133.262, 81.361],
                                  [-45.475, 146.885],
                                  [-9.18, 92],
                                ],
                                o: [
                                  [-98.033, 47.672],
                                  [29.672, 128.836],
                                  [-55.541, 40.393],
                                  [130.049, 34.836],
                                  [18.525, 69.377],
                                  [111.344, -3.311],
                                  [-133.263, -81.361],
                                  [44.23, -148],
                                  [9.18, -92],
                                ],
                                v: [
                                  [-129.883, -377.457],
                                  [-188.807, -174.128],
                                  [-236.281, 46.076],
                                  [-248.167, 165.125],
                                  [12.875, 306.496],
                                  [88.418, 373.14],
                                  [259.033, 242.67],
                                  [31.93, -110.403],
                                  [304.132, -187.545],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.557, -46.887],
                                  [-29.832, -129.017],
                                  [55.654, -40.283],
                                  [-130.25, -34.815],
                                  [-17.68, -68.421],
                                  [-117.881, 4.685],
                                  [133.533, 81.427],
                                  [-45.59, 147.115],
                                  [-9.151, 92],
                                ],
                                o: [
                                  [-98.082, 47.781],
                                  [29.832, 129.017],
                                  [-55.654, 40.283],
                                  [130.25, 34.815],
                                  [18.511, 69.443],
                                  [111.361, -3.278],
                                  [-133.533, -81.427],
                                  [44.375, -148.202],
                                  [9.151, -92],
                                ],
                                v: [
                                  [-130.733, -377.683],
                                  [-188.343, -173.712],
                                  [-236.149, 47.076],
                                  [-248.334, 164.832],
                                  [12.826, 306.158],
                                  [87.219, 373.15],
                                  [259.733, 243.72],
                                  [31.734, -109.908],
                                  [305.022, -187.708],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.492, -47.016],
                                  [-29.988, -129.193],
                                  [55.763, -40.174],
                                  [-130.446, -34.794],
                                  [-17.688, -68.511],
                                  [-117.732, 4.617],
                                  [133.798, 81.492],
                                  [-45.701, 147.34],
                                  [-9.121, 92],
                                ],
                                o: [
                                  [-98.131, 47.888],
                                  [29.988, 129.193],
                                  [-55.763, 40.174],
                                  [130.446, 34.794],
                                  [18.498, 69.508],
                                  [111.377, -3.246],
                                  [-133.798, -81.492],
                                  [44.517, -148.399],
                                  [9.121, -92],
                                ],
                                v: [
                                  [-131.575, -377.965],
                                  [-187.878, -173.244],
                                  [-236.025, 48.08],
                                  [-248.444, 164.539],
                                  [12.704, 305.876],
                                  [85.958, 373.16],
                                  [260.493, 244.773],
                                  [31.611, -109.397],
                                  [305.964, -187.925],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.429, -47.142],
                                  [-30.14, -129.366],
                                  [55.871, -40.069],
                                  [-130.637, -34.774],
                                  [-17.696, -68.599],
                                  [-117.587, 4.55],
                                  [134.056, 81.556],
                                  [-45.81, 147.559],
                                  [-9.093, 92],
                                ],
                                o: [
                                  [-98.178, 47.992],
                                  [30.14, 129.366],
                                  [-55.871, 40.069],
                                  [130.637, 34.774],
                                  [18.486, 69.571],
                                  [111.393, -3.214],
                                  [-134.056, -81.556],
                                  [44.656, -148.592],
                                  [9.093, -92],
                                ],
                                v: [
                                  [-132.413, -378.182],
                                  [-187.354, -172.781],
                                  [-235.896, 49.079],
                                  [-248.609, 164.185],
                                  [12.576, 305.534],
                                  [84.816, 373.228],
                                  [261.199, 245.83],
                                  [31.441, -108.814],
                                  [306.84, -188.146],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.367, -47.265],
                                  [-30.289, -129.534],
                                  [55.976, -39.965],
                                  [-130.824, -34.755],
                                  [-17.704, -68.686],
                                  [-117.445, 4.486],
                                  [134.309, 81.618],
                                  [-45.916, 147.774],
                                  [-9.065, 92],
                                ],
                                o: [
                                  [-98.225, 48.094],
                                  [30.289, 129.534],
                                  [-55.976, 39.965],
                                  [130.824, 34.755],
                                  [18.473, 69.633],
                                  [111.408, -3.184],
                                  [-134.309, -81.618],
                                  [44.792, -148.78],
                                  [9.065, -92],
                                ],
                                v: [
                                  [-133.243, -378.397],
                                  [-186.83, -172.32],
                                  [-235.825, 50.023],
                                  [-248.712, 163.824],
                                  [12.317, 305.186],
                                  [83.612, 373.292],
                                  [261.968, 246.89],
                                  [31.284, -108.275],
                                  [307.772, -188.365],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.307, -47.386],
                                  [-30.434, -129.699],
                                  [56.078, -39.864],
                                  [-131.007, -34.735],
                                  [-17.712, -68.77],
                                  [-117.306, 4.422],
                                  [134.556, 81.678],
                                  [-46.02, 147.983],
                                  [-9.038, 92],
                                ],
                                o: [
                                  [-98.27, 48.193],
                                  [30.434, 129.699],
                                  [-56.078, 39.864],
                                  [131.007, 34.735],
                                  [18.461, 69.693],
                                  [111.423, -3.154],
                                  [-134.556, -81.679],
                                  [44.925, -148.964],
                                  [9.038, -92],
                                ],
                                v: [
                                  [-134.074, -378.609],
                                  [-186.301, -171.863],
                                  [-235.758, 50.906],
                                  [-248.816, 163.463],
                                  [12.048, 304.836],
                                  [82.464, 373.411],
                                  [262.738, 247.952],
                                  [31.143, -107.783],
                                  [308.636, -188.643],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.248, -47.504],
                                  [-30.576, -129.86],
                                  [56.178, -39.766],
                                  [-131.185, -34.717],
                                  [-17.719, -68.853],
                                  [-117.171, 4.361],
                                  [134.797, 81.738],
                                  [-46.122, 148.188],
                                  [-9.012, 92],
                                ],
                                o: [
                                  [-98.314, 48.29],
                                  [30.576, 129.86],
                                  [-56.178, 39.766],
                                  [131.185, 34.717],
                                  [18.45, 69.752],
                                  [111.438, -3.124],
                                  [-134.796, -81.738],
                                  [45.054, -149.143],
                                  [9.012, -92],
                                ],
                                v: [
                                  [-134.838, -378.815],
                                  [-185.654, -171.404],
                                  [-235.75, 51.788],
                                  [-248.914, 163.096],
                                  [11.706, 304.485],
                                  [81.375, 373.529],
                                  [263.512, 249.019],
                                  [31.014, -107.222],
                                  [309.552, -188.98],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.191, -47.619],
                                  [-30.715, -130.017],
                                  [56.276, -39.669],
                                  [-131.359, -34.698],
                                  [-17.726, -68.933],
                                  [-117.039, 4.3],
                                  [135.032, 81.796],
                                  [-46.221, 148.387],
                                  [-8.986, 92],
                                ],
                                o: [
                                  [-98.357, 48.385],
                                  [30.715, 130.017],
                                  [-56.276, 39.669],
                                  [131.359, 34.698],
                                  [18.438, 69.809],
                                  [111.452, -3.095],
                                  [-135.032, -81.796],
                                  [45.18, -149.318],
                                  [8.986, -92],
                                ],
                                v: [
                                  [-135.661, -379.022],
                                  [-185.069, -170.891],
                                  [-235.741, 52.551],
                                  [-248.95, 162.726],
                                  [11.3, 304.073],
                                  [80.285, 373.648],
                                  [264.291, 250.15],
                                  [30.838, -106.646],
                                  [310.465, -189.316],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.135, -47.731],
                                  [-30.85, -130.17],
                                  [56.371, -39.575],
                                  [-131.53, -34.68],
                                  [-17.733, -69.012],
                                  [-116.909, 4.241],
                                  [135.262, 81.852],
                                  [-46.318, 148.582],
                                  [-8.961, 92],
                                ],
                                o: [
                                  [-98.399, 48.478],
                                  [30.85, 130.17],
                                  [-56.371, 39.575],
                                  [131.53, 34.68],
                                  [18.427, 69.865],
                                  [111.466, -3.067],
                                  [-135.262, -81.852],
                                  [45.304, -149.49],
                                  [8.961, -92],
                                ],
                                v: [
                                  [-136.418, -379.224],
                                  [-184.421, -170.382],
                                  [-235.735, 53.371],
                                  [-248.988, 162.297],
                                  [10.821, 303.718],
                                  [79.254, 373.822],
                                  [265.13, 251.225],
                                  [30.731, -106.116],
                                  [311.309, -189.655],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.08, -47.841],
                                  [-30.983, -130.32],
                                  [56.464, -39.484],
                                  [-131.696, -34.663],
                                  [-17.74, -69.088],
                                  [-116.783, 4.184],
                                  [135.486, 81.907],
                                  [-46.412, 148.773],
                                  [-8.936, 92],
                                ],
                                o: [
                                  [-98.44, 48.568],
                                  [30.983, 130.32],
                                  [-56.464, 39.484],
                                  [131.696, 34.663],
                                  [18.416, 69.92],
                                  [111.48, -3.04],
                                  [-135.486, -81.907],
                                  [45.425, -149.657],
                                  [8.936, -92],
                                ],
                                v: [
                                  [-137.172, -379.426],
                                  [-183.773, -169.871],
                                  [-235.786, 54.071],
                                  [-249.023, 161.921],
                                  [10.334, 303.302],
                                  [78.221, 373.992],
                                  [265.915, 252.304],
                                  [30.58, -105.575],
                                  [312.207, -190.053],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.026, -47.948],
                                  [-31.112, -130.466],
                                  [56.556, -39.394],
                                  [-131.858, -34.646],
                                  [-17.747, -69.163],
                                  [-116.66, 4.127],
                                  [135.705, 81.961],
                                  [-46.505, 148.959],
                                  [-8.912, 92],
                                ],
                                o: [
                                  [-98.48, 48.657],
                                  [31.112, 130.466],
                                  [-56.556, 39.394],
                                  [131.858, 34.646],
                                  [18.405, 69.974],
                                  [111.493, -3.013],
                                  [-135.705, -81.961],
                                  [45.542, -149.82],
                                  [8.912, -92],
                                ],
                                v: [
                                  [-137.86, -379.623],
                                  [-183.064, -169.361],
                                  [-235.839, 54.769],
                                  [-249.059, 161.484],
                                  [9.774, 302.941],
                                  [77.245, 374.218],
                                  [266.76, 253.385],
                                  [30.38, -105.026],
                                  [313.043, -190.45],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.974, -48.052],
                                  [-31.238, -130.609],
                                  [56.644, -39.306],
                                  [-132.017, -34.629],
                                  [-17.753, -69.237],
                                  [-116.54, 4.073],
                                  [135.919, 82.014],
                                  [-46.595, 149.141],
                                  [-8.888, 92],
                                ],
                                o: [
                                  [-98.52, 48.743],
                                  [31.238, 130.609],
                                  [-56.644, 39.306],
                                  [132.017, 34.629],
                                  [18.395, 70.026],
                                  [111.507, -2.987],
                                  [-135.919, -82.014],
                                  [45.657, -149.98],
                                  [8.888, -92],
                                ],
                                v: [
                                  [-138.606, -379.758],
                                  [-182.358, -168.795],
                                  [-235.952, 55.409],
                                  [-249.094, 161.103],
                                  [9.209, 302.519],
                                  [76.329, 374.444],
                                  [267.553, 254.472],
                                  [30.249, -104.464],
                                  [313.929, -190.907],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.923, -48.154],
                                  [-31.361, -130.748],
                                  [56.731, -39.221],
                                  [-132.171, -34.613],
                                  [-17.76, -69.308],
                                  [-116.422, 4.019],
                                  [136.129, 82.065],
                                  [-46.683, 149.318],
                                  [-8.865, 92],
                                ],
                                o: [
                                  [-98.558, 48.827],
                                  [31.361, 130.748],
                                  [-56.731, 39.221],
                                  [132.172, 34.613],
                                  [18.385, 70.077],
                                  [111.519, -2.961],
                                  [-136.128, -82.065],
                                  [45.77, -150.135],
                                  [8.865, -92],
                                ],
                                v: [
                                  [-139.287, -379.954],
                                  [-181.647, -168.29],
                                  [-236.064, 55.985],
                                  [-249.067, 160.658],
                                  [8.513, 302.098],
                                  [75.412, 374.667],
                                  [268.404, 255.56],
                                  [30.069, -103.89],
                                  [314.81, -191.366],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.873, -48.254],
                                  [-31.482, -130.884],
                                  [56.816, -39.137],
                                  [-132.323, -34.597],
                                  [-17.766, -69.378],
                                  [-116.308, 3.967],
                                  [136.333, 82.115],
                                  [-46.769, 149.492],
                                  [-8.843, 92],
                                ],
                                o: [
                                  [-98.595, 48.91],
                                  [31.482, 130.885],
                                  [-56.816, 39.137],
                                  [132.323, 34.597],
                                  [18.375, 70.127],
                                  [111.532, -2.936],
                                  [-136.333, -82.115],
                                  [45.88, -150.288],
                                  [8.843, -92],
                                ],
                                v: [
                                  [-139.905, -380.085],
                                  [-180.936, -167.726],
                                  [-236.236, 56.561],
                                  [-249.101, 160.272],
                                  [7.868, 301.672],
                                  [74.555, 374.946],
                                  [269.2, 256.653],
                                  [29.96, -103.368],
                                  [315.625, -191.883],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.824, -48.351],
                                  [-31.599, -131.017],
                                  [56.899, -39.056],
                                  [-132.47, -34.581],
                                  [-17.772, -69.446],
                                  [-116.196, 3.915],
                                  [136.532, 82.164],
                                  [-46.853, 149.661],
                                  [-8.821, 92],
                                ],
                                o: [
                                  [-98.632, 48.99],
                                  [31.599, 131.017],
                                  [-56.899, 39.056],
                                  [132.47, 34.582],
                                  [18.365, 70.176],
                                  [111.544, -2.912],
                                  [-136.532, -82.164],
                                  [45.987, -150.436],
                                  [8.821, -92],
                                ],
                                v: [
                                  [-140.579, -380.213],
                                  [-180.168, -167.162],
                                  [-236.352, 57.075],
                                  [-249.015, 159.822],
                                  [7.096, 301.308],
                                  [73.757, 375.225],
                                  [269.999, 257.691],
                                  [29.798, -102.837],
                                  [316.495, -192.404],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.777, -48.447],
                                  [-31.714, -131.147],
                                  [56.98, -38.976],
                                  [-132.614, -34.566],
                                  [-17.778, -69.513],
                                  [-116.086, 3.866],
                                  [136.727, 82.212],
                                  [-46.935, 149.826],
                                  [-8.8, 92],
                                ],
                                o: [
                                  [-98.667, 49.068],
                                  [31.714, 131.147],
                                  [-56.98, 38.976],
                                  [132.614, 34.566],
                                  [18.355, 70.223],
                                  [111.556, -2.888],
                                  [-136.726, -82.212],
                                  [46.091, -150.581],
                                  [8.799, -92],
                                ],
                                v: [
                                  [-141.131, -380.342],
                                  [-179.398, -166.599],
                                  [-236.587, 57.527],
                                  [-248.989, 159.429],
                                  [6.372, 300.88],
                                  [72.956, 375.499],
                                  [270.86, 258.73],
                                  [29.592, -102.295],
                                  [317.36, -192.981],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.73, -48.539],
                                  [-31.826, -131.274],
                                  [57.058, -38.898],
                                  [-132.755, -34.552],
                                  [-17.784, -69.578],
                                  [-115.98, 3.817],
                                  [136.917, 82.259],
                                  [-47.015, 149.987],
                                  [-8.779, 92],
                                ],
                                o: [
                                  [-98.702, 49.145],
                                  [31.826, 131.274],
                                  [-57.058, 38.898],
                                  [132.755, 34.552],
                                  [18.346, 70.27],
                                  [111.567, -2.865],
                                  [-136.916, -82.259],
                                  [46.193, -150.722],
                                  [8.779, -92],
                                ],
                                v: [
                                  [-141.736, -380.466],
                                  [-178.628, -166.037],
                                  [-236.762, 57.977],
                                  [-248.964, 159.035],
                                  [5.523, 300.452],
                                  [72.217, 375.832],
                                  [271.665, 259.774],
                                  [29.451, -101.744],
                                  [318.16, -193.563],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.685, -48.63],
                                  [-31.935, -131.398],
                                  [57.135, -38.822],
                                  [-132.892, -34.537],
                                  [-17.789, -69.641],
                                  [-115.876, 3.769],
                                  [137.102, 82.304],
                                  [-47.093, 150.145],
                                  [-8.758, 92],
                                ],
                                o: [
                                  [-98.736, 49.22],
                                  [31.935, 131.398],
                                  [-57.135, 38.822],
                                  [132.892, 34.537],
                                  [18.337, 70.315],
                                  [111.579, -2.843],
                                  [-137.102, -82.304],
                                  [46.293, -150.861],
                                  [8.758, -92],
                                ],
                                v: [
                                  [-142.278, -380.588],
                                  [-177.856, -165.479],
                                  [-236.998, 58.364],
                                  [-248.878, 158.636],
                                  [4.723, 300.021],
                                  [71.536, 376.162],
                                  [272.472, 260.822],
                                  [29.318, -101.185],
                                  [319.014, -194.201],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.641, -48.718],
                                  [-32.042, -131.518],
                                  [57.211, -38.748],
                                  [-133.026, -34.523],
                                  [-17.795, -69.703],
                                  [-115.774, 3.723],
                                  [137.283, 82.349],
                                  [-47.17, 150.298],
                                  [-8.738, 92],
                                ],
                                o: [
                                  [-98.769, 49.293],
                                  [32.042, 131.518],
                                  [-57.211, 38.748],
                                  [133.026, 34.523],
                                  [18.328, 70.359],
                                  [111.59, -2.82],
                                  [-137.283, -82.349],
                                  [46.39, -150.995],
                                  [8.738, -92],
                                ],
                                v: [
                                  [-142.817, -380.71],
                                  [-177.083, -164.92],
                                  [-237.293, 58.751],
                                  [-248.794, 158.238],
                                  [3.796, 299.651],
                                  [70.854, 376.55],
                                  [273.343, 261.812],
                                  [29.136, -100.679],
                                  [319.861, -194.842],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.598, -48.805],
                                  [-32.146, -131.636],
                                  [57.284, -38.676],
                                  [-133.157, -34.509],
                                  [-17.8, -69.763],
                                  [-115.675, 3.678],
                                  [137.46, 82.392],
                                  [-47.244, 150.448],
                                  [-8.719, 92],
                                ],
                                o: [
                                  [-98.802, 49.364],
                                  [32.146, 131.636],
                                  [-57.284, 38.676],
                                  [133.157, 34.509],
                                  [18.32, 70.402],
                                  [111.601, -2.799],
                                  [-137.46, -82.392],
                                  [46.485, -151.127],
                                  [8.719, -92],
                                ],
                                v: [
                                  [-143.293, -380.83],
                                  [-176.312, -164.36],
                                  [-237.531, 59.073],
                                  [-248.71, 157.836],
                                  [2.918, 299.219],
                                  [70.293, 376.935],
                                  [274.158, 262.805],
                                  [28.962, -100.164],
                                  [320.645, -195.484],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.556, -48.889],
                                  [-32.247, -131.751],
                                  [57.356, -38.606],
                                  [-133.285, -34.496],
                                  [-17.806, -69.822],
                                  [-115.578, 3.633],
                                  [137.632, 82.435],
                                  [-47.317, 150.594],
                                  [-8.7, 92],
                                ],
                                o: [
                                  [-98.833, 49.433],
                                  [32.247, 131.751],
                                  [-57.356, 38.606],
                                  [133.285, 34.496],
                                  [18.311, 70.444],
                                  [111.611, -2.778],
                                  [-137.632, -82.435],
                                  [46.578, -151.256],
                                  [8.7, -92],
                                ],
                                v: [
                                  [-143.705, -380.888],
                                  [-175.54, -163.803],
                                  [-237.827, 59.335],
                                  [-248.625, 157.489],
                                  [1.976, 298.786],
                                  [69.732, 377.316],
                                  [274.974, 263.742],
                                  [28.799, -99.64],
                                  [321.483, -196.246],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.514, -48.971],
                                  [-32.346, -131.864],
                                  [57.425, -38.537],
                                  [-133.409, -34.483],
                                  [-17.811, -69.88],
                                  [-115.483, 3.59],
                                  [137.8, 82.476],
                                  [-47.388, 150.737],
                                  [-8.681, 92],
                                ],
                                o: [
                                  [-98.864, 49.501],
                                  [32.346, 131.864],
                                  [-57.425, 38.537],
                                  [133.409, 34.483],
                                  [18.303, 70.486],
                                  [111.621, -2.757],
                                  [-137.8, -82.476],
                                  [46.668, -151.381],
                                  [8.681, -92],
                                ],
                                v: [
                                  [-144.172, -381.003],
                                  [-174.707, -163.247],
                                  [-238.186, 59.592],
                                  [-248.544, 157.084],
                                  [0.964, 298.411],
                                  [69.229, 377.697],
                                  [275.731, 264.683],
                                  [28.642, -99.109],
                                  [322.318, -196.948],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.474, -49.051],
                                  [-32.443, -131.973],
                                  [57.494, -38.469],
                                  [-133.531, -34.47],
                                  [-17.816, -69.936],
                                  [-115.391, 3.548],
                                  [137.965, 82.516],
                                  [-47.457, 150.877],
                                  [-8.663, 92],
                                ],
                                o: [
                                  [-98.894, 49.567],
                                  [32.443, 131.973],
                                  [-57.494, 38.469],
                                  [133.531, 34.47],
                                  [18.295, 70.526],
                                  [111.631, -2.737],
                                  [-137.964, -82.516],
                                  [46.757, -151.503],
                                  [8.663, -92],
                                ],
                                v: [
                                  [-144.515, -381.056],
                                  [-173.935, -162.631],
                                  [-238.485, 59.788],
                                  [-248.401, 156.795],
                                  [0.005, 297.978],
                                  [68.786, 378.136],
                                  [276.552, 265.627],
                                  [28.493, -98.573],
                                  [323.086, -197.711],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.435, -49.13],
                                  [-32.538, -132.08],
                                  [57.56, -38.404],
                                  [-133.65, -34.458],
                                  [-17.821, -69.991],
                                  [-115.301, 3.507],
                                  [138.125, 82.556],
                                  [-47.524, 151.013],
                                  [-8.646, 92],
                                ],
                                o: [
                                  [-98.924, 49.632],
                                  [32.538, 132.08],
                                  [-57.56, 38.404],
                                  [133.65, 34.457],
                                  [18.287, 70.565],
                                  [111.641, -2.718],
                                  [-138.125, -82.556],
                                  [46.843, -151.623],
                                  [8.646, -92],
                                ],
                                v: [
                                  [-144.855, -381.107],
                                  [-173.159, -162.136],
                                  [-238.845, 59.981],
                                  [-248.259, 156.445],
                                  [-1.021, 297.603],
                                  [68.345, 378.573],
                                  [277.377, 266.511],
                                  [28.29, -98.089],
                                  [323.91, -198.534],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.397, -49.206],
                                  [-32.63, -132.185],
                                  [57.625, -38.34],
                                  [-133.765, -34.445],
                                  [-17.825, -70.044],
                                  [-115.213, 3.467],
                                  [138.281, 82.594],
                                  [-47.59, 151.146],
                                  [-8.629, 92],
                                ],
                                o: [
                                  [-98.952, 49.695],
                                  [32.63, 132.185],
                                  [-57.625, 38.34],
                                  [133.765, 34.445],
                                  [18.279, 70.603],
                                  [111.651, -2.698],
                                  [-138.281, -82.594],
                                  [46.927, -151.739],
                                  [8.629, -92],
                                ],
                                v: [
                                  [-145.192, -381.158],
                                  [-172.385, -161.584],
                                  [-239.204, 60.11],
                                  [-248.178, 156.152],
                                  [-2.116, 297.166],
                                  [68.023, 379.066],
                                  [278.144, 267.339],
                                  [28.157, -97.6],
                                  [324.667, -199.419],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.36, -49.281],
                                  [-32.72, -132.286],
                                  [57.689, -38.278],
                                  [-133.878, -34.433],
                                  [-17.83, -70.096],
                                  [-115.127, 3.428],
                                  [138.434, 82.632],
                                  [-47.655, 151.275],
                                  [-8.612, 92],
                                ],
                                o: [
                                  [-98.98, 49.757],
                                  [32.72, 132.286],
                                  [-57.689, 38.277],
                                  [133.878, 34.433],
                                  [18.272, 70.64],
                                  [111.66, -2.68],
                                  [-138.434, -82.632],
                                  [47.009, -151.853],
                                  [8.612, -92],
                                ],
                                v: [
                                  [-145.463, -381.207],
                                  [-171.612, -161.03],
                                  [-239.625, 60.237],
                                  [-248.04, 155.859],
                                  [-3.156, 296.788],
                                  [67.703, 379.499],
                                  [278.911, 268.23],
                                  [27.97, -97.103],
                                  [325.482, -200.245],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.323, -49.353],
                                  [-32.807, -132.386],
                                  [57.75, -38.217],
                                  [-133.989, -34.422],
                                  [-17.835, -70.147],
                                  [-115.044, 3.39],
                                  [138.583, 82.668],
                                  [-47.717, 151.401],
                                  [-8.596, 92],
                                ],
                                o: [
                                  [-99.008, 49.816],
                                  [32.807, 132.386],
                                  [-57.75, 38.217],
                                  [133.989, 34.422],
                                  [18.265, 70.677],
                                  [111.669, -2.662],
                                  [-138.583, -82.668],
                                  [47.089, -151.964],
                                  [8.596, -92],
                                ],
                                v: [
                                  [-145.732, -381.192],
                                  [-170.898, -160.477],
                                  [-239.987, 60.303],
                                  [-247.84, 155.564],
                                  [-4.264, 296.351],
                                  [67.504, 379.989],
                                  [279.682, 269.002],
                                  [27.79, -96.662],
                                  [326.231, -201.131],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.288, -49.424],
                                  [-32.893, -132.483],
                                  [57.811, -38.157],
                                  [-134.096, -34.411],
                                  [-17.839, -70.197],
                                  [-114.962, 3.352],
                                  [138.728, 82.704],
                                  [-47.778, 151.525],
                                  [-8.579, 92],
                                ],
                                o: [
                                  [-99.034, 49.875],
                                  [32.893, 132.483],
                                  [-57.811, 38.157],
                                  [134.096, 34.411],
                                  [18.258, 70.712],
                                  [111.678, -2.644],
                                  [-138.728, -82.704],
                                  [47.167, -152.072],
                                  [8.58, -92],
                                ],
                                v: [
                                  [-145.936, -381.238],
                                  [-170.123, -159.987],
                                  [-240.408, 60.424],
                                  [-247.702, 155.329],
                                  [-5.378, 295.972],
                                  [67.302, 380.477],
                                  [280.457, 269.779],
                                  [27.617, -96.213],
                                  [326.976, -202.078],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.253, -49.494],
                                  [-32.977, -132.577],
                                  [57.87, -38.099],
                                  [-134.201, -34.399],
                                  [-17.843, -70.245],
                                  [-114.882, 3.316],
                                  [138.87, 82.739],
                                  [-47.838, 151.645],
                                  [-8.564, 92],
                                ],
                                o: [
                                  [-99.06, 49.932],
                                  [32.977, 132.577],
                                  [-57.87, 38.099],
                                  [134.201, 34.399],
                                  [18.251, 70.747],
                                  [111.687, -2.627],
                                  [-138.87, -82.739],
                                  [47.243, -152.178],
                                  [8.564, -92],
                                ],
                                v: [
                                  [-146.077, -381.221],
                                  [-169.409, -159.437],
                                  [-240.832, 60.424],
                                  [-247.566, 155.091],
                                  [-6.499, 295.594],
                                  [67.165, 381.024],
                                  [281.172, 270.557],
                                  [27.451, -95.76],
                                  [327.656, -203.025],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.219, -49.561],
                                  [-33.058, -132.669],
                                  [57.927, -38.043],
                                  [-134.303, -34.389],
                                  [-17.848, -70.293],
                                  [-114.805, 3.28],
                                  [139.008, 82.773],
                                  [-47.896, 151.762],
                                  [-8.549, 92],
                                ],
                                o: [
                                  [-99.085, 49.988],
                                  [33.058, 132.669],
                                  [-57.927, 38.043],
                                  [134.303, 34.389],
                                  [18.244, 70.781],
                                  [111.695, -2.61],
                                  [-139.008, -82.773],
                                  [47.317, -152.281],
                                  [8.549, -92],
                                ],
                                v: [
                                  [-146.215, -381.202],
                                  [-168.692, -158.948],
                                  [-241.253, 60.479],
                                  [-247.369, 154.914],
                                  [-7.687, 295.215],
                                  [67.086, 381.507],
                                  [281.889, 271.337],
                                  [27.29, -95.303],
                                  [328.332, -203.973],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.187, -49.627],
                                  [-33.137, -132.759],
                                  [57.983, -37.987],
                                  [-134.403, -34.378],
                                  [-17.852, -70.339],
                                  [-114.729, 3.246],
                                  [139.143, 82.806],
                                  [-47.953, 151.877],
                                  [-8.534, 92],
                                ],
                                o: [
                                  [-99.11, 50.042],
                                  [33.137, 132.759],
                                  [-57.983, 37.987],
                                  [134.403, 34.378],
                                  [18.237, 70.813],
                                  [111.703, -2.593],
                                  [-139.143, -82.806],
                                  [47.39, -152.381],
                                  [8.534, -92],
                                ],
                                v: [
                                  [-146.288, -381.182],
                                  [-168.039, -158.459],
                                  [-241.677, 60.472],
                                  [-247.173, 154.734],
                                  [-8.819, 294.773],
                                  [67.13, 382.049],
                                  [282.609, 272.058],
                                  [27.137, -94.841],
                                  [329.004, -204.92],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.154, -49.691],
                                  [-33.215, -132.847],
                                  [58.038, -37.934],
                                  [-134.501, -34.368],
                                  [-17.856, -70.384],
                                  [-114.655, 3.212],
                                  [139.274, 82.838],
                                  [-48.009, 151.988],
                                  [-8.519, 92],
                                ],
                                o: [
                                  [-99.134, 50.095],
                                  [33.215, 132.847],
                                  [-58.037, 37.934],
                                  [134.501, 34.368],
                                  [18.231, 70.846],
                                  [111.711, -2.577],
                                  [-139.274, -82.838],
                                  [47.46, -152.479],
                                  [8.519, -92],
                                ],
                                v: [
                                  [-146.359, -381.1],
                                  [-167.385, -157.972],
                                  [-242.163, 60.524],
                                  [-247.04, 154.617],
                                  [-10.019, 294.393],
                                  [67.175, 382.591],
                                  [283.271, 272.782],
                                  [26.989, -94.434],
                                  [329.61, -205.929],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.123, -49.754],
                                  [-33.291, -132.933],
                                  [58.091, -37.881],
                                  [-134.596, -34.358],
                                  [-17.86, -70.428],
                                  [-114.583, 3.179],
                                  [139.402, 82.87],
                                  [-48.063, 152.097],
                                  [-8.505, 92],
                                ],
                                o: [
                                  [-99.158, 50.147],
                                  [33.291, 132.933],
                                  [-58.091, 37.881],
                                  [134.596, 34.358],
                                  [18.225, 70.877],
                                  [111.719, -2.562],
                                  [-139.402, -82.87],
                                  [47.529, -152.575],
                                  [8.505, -92],
                                ],
                                v: [
                                  [-146.365, -381.075],
                                  [-166.729, -157.546],
                                  [-242.587, 60.512],
                                  [-246.846, 154.497],
                                  [-11.162, 294.011],
                                  [67.28, 383.129],
                                  [283.935, 273.508],
                                  [26.846, -94.022],
                                  [330.212, -206.939],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.093, -49.815],
                                  [-33.364, -133.016],
                                  [58.143, -37.83],
                                  [-134.688, -34.348],
                                  [-17.863, -70.47],
                                  [-114.513, 3.147],
                                  [139.527, 82.901],
                                  [-48.115, 152.203],
                                  [-8.492, 92],
                                ],
                                o: [
                                  [-99.181, 50.197],
                                  [33.364, 133.016],
                                  [-58.143, 37.83],
                                  [134.688, 34.348],
                                  [18.219, 70.907],
                                  [111.727, -2.546],
                                  [-139.528, -82.901],
                                  [47.596, -152.668],
                                  [8.492, -92],
                                ],
                                v: [
                                  [-146.305, -380.989],
                                  [-166.073, -157.059],
                                  [-243.073, 60.436],
                                  [-246.654, 154.375],
                                  [-12.373, 293.629],
                                  [67.446, 383.727],
                                  [284.602, 274.174],
                                  [26.71, -93.666],
                                  [330.81, -207.948],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.063, -49.875],
                                  [-33.436, -133.097],
                                  [58.193, -37.78],
                                  [-134.779, -34.339],
                                  [-17.867, -70.512],
                                  [-114.444, 3.116],
                                  [139.649, 82.931],
                                  [-48.167, 152.307],
                                  [-8.478, 92],
                                ],
                                o: [
                                  [-99.203, 50.246],
                                  [33.436, 133.097],
                                  [-58.193, 37.78],
                                  [134.779, 34.339],
                                  [18.213, 70.937],
                                  [111.734, -2.531],
                                  [-139.649, -82.931],
                                  [47.662, -152.759],
                                  [8.478, -92],
                                ],
                                v: [
                                  [-146.244, -380.903],
                                  [-165.478, -156.636],
                                  [-243.498, 60.297],
                                  [-246.462, 154.375],
                                  [-13.588, 293.187],
                                  [67.676, 384.263],
                                  [285.209, 274.845],
                                  [26.58, -93.308],
                                  [331.342, -208.957],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.034, -49.933],
                                  [-33.506, -133.177],
                                  [58.243, -37.731],
                                  [-134.867, -34.329],
                                  [-17.871, -70.553],
                                  [-114.377, 3.085],
                                  [139.768, 82.96],
                                  [-48.217, 152.408],
                                  [-8.465, 92],
                                ],
                                o: [
                                  [-99.225, 50.294],
                                  [33.506, 133.177],
                                  [-58.243, 37.731],
                                  [134.867, 34.329],
                                  [18.207, 70.966],
                                  [111.742, -2.517],
                                  [-139.768, -82.96],
                                  [47.726, -152.847],
                                  [8.465, -92],
                                ],
                                v: [
                                  [-146.118, -380.814],
                                  [-164.945, -156.273],
                                  [-243.924, 60.155],
                                  [-246.21, 154.315],
                                  [-14.872, 292.804],
                                  [67.966, 384.858],
                                  [285.819, 275.516],
                                  [26.395, -92.945],
                                  [331.873, -210.028],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.005, -49.989],
                                  [-33.575, -133.254],
                                  [58.291, -37.684],
                                  [-134.953, -34.32],
                                  [-17.874, -70.593],
                                  [-114.312, 3.056],
                                  [139.884, 82.988],
                                  [-48.266, 152.506],
                                  [-8.452, 92],
                                ],
                                o: [
                                  [-99.246, 50.341],
                                  [33.575, 133.254],
                                  [-58.291, 37.684],
                                  [134.953, 34.32],
                                  [18.201, 70.995],
                                  [111.749, -2.503],
                                  [-139.884, -82.988],
                                  [47.788, -152.934],
                                  [8.452, -92],
                                ],
                                v: [
                                  [-145.99, -380.661],
                                  [-164.41, -155.851],
                                  [-244.349, 59.949],
                                  [-246.019, 154.376],
                                  [-16.097, 292.419],
                                  [68.316, 385.45],
                                  [286.43, 276.127],
                                  [26.275, -92.578],
                                  [332.337, -211.039],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.978, -50.045],
                                  [-33.641, -133.33],
                                  [58.338, -37.638],
                                  [-135.036, -34.312],
                                  [-17.878, -70.631],
                                  [-114.249, 3.027],
                                  [139.997, 83.016],
                                  [-48.313, 152.602],
                                  [-8.44, 92],
                                ],
                                o: [
                                  [-99.267, 50.387],
                                  [33.641, 133.33],
                                  [-58.338, 37.638],
                                  [135.036, 34.312],
                                  [18.196, 71.022],
                                  [111.756, -2.489],
                                  [-139.997, -83.016],
                                  [47.849, -153.018],
                                  [8.44, -92],
                                ],
                                v: [
                                  [-145.797, -380.508],
                                  [-163.937, -155.491],
                                  [-244.775, 59.742],
                                  [-245.832, 154.436],
                                  [-17.327, 292.035],
                                  [68.731, 386.042],
                                  [286.982, 276.741],
                                  [26.161, -92.269],
                                  [332.797, -212.109],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.951, -50.098],
                                  [-33.706, -133.403],
                                  [58.384, -37.593],
                                  [-135.118, -34.303],
                                  [-17.881, -70.669],
                                  [-114.187, 2.998],
                                  [140.108, 83.043],
                                  [-48.36, 152.696],
                                  [-8.428, 92],
                                ],
                                o: [
                                  [-99.287, 50.431],
                                  [33.706, 133.403],
                                  [-58.384, 37.593],
                                  [135.118, 34.303],
                                  [18.19, 71.049],
                                  [111.762, -2.475],
                                  [-140.108, -83.043],
                                  [47.908, -153.1],
                                  [8.428, -92],
                                ],
                                v: [
                                  [-145.538, -380.413],
                                  [-163.463, -155.13],
                                  [-245.202, 59.47],
                                  [-245.643, 154.557],
                                  [-18.563, 291.65],
                                  [69.266, 386.631],
                                  [287.536, 277.357],
                                  [26.052, -91.957],
                                  [333.253, -213.178],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.925, -50.151],
                                  [-33.77, -133.475],
                                  [58.428, -37.549],
                                  [-135.198, -34.295],
                                  [-17.884, -70.706],
                                  [-114.126, 2.971],
                                  [140.215, 83.07],
                                  [-48.405, 152.787],
                                  [-8.416, 92],
                                ],
                                o: [
                                  [-99.307, 50.475],
                                  [33.77, 133.475],
                                  [-58.428, 37.549],
                                  [135.198, 34.295],
                                  [18.185, 71.075],
                                  [111.769, -2.462],
                                  [-140.215, -83.07],
                                  [47.966, -153.18],
                                  [8.416, -92],
                                ],
                                v: [
                                  [-145.279, -380.257],
                                  [-163.05, -154.833],
                                  [-245.628, 59.134],
                                  [-245.395, 154.678],
                                  [-19.804, 291.265],
                                  [69.803, 387.22],
                                  [288.031, 277.973],
                                  [26.008, -91.641],
                                  [333.645, -214.249],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.899, -50.202],
                                  [-33.831, -133.545],
                                  [58.472, -37.506],
                                  [-135.275, -34.287],
                                  [-17.888, -70.741],
                                  [-114.068, 2.944],
                                  [140.32, 83.095],
                                  [-48.449, 152.876],
                                  [-8.405, 92],
                                ],
                                o: [
                                  [-99.326, 50.517],
                                  [33.831, 133.545],
                                  [-58.472, 37.506],
                                  [135.275, 34.286],
                                  [18.18, 71.101],
                                  [111.775, -2.449],
                                  [-140.32, -83.095],
                                  [48.022, -153.258],
                                  [8.404, -92],
                                ],
                                v: [
                                  [-144.955, -380.038],
                                  [-162.699, -154.537],
                                  [-245.994, 58.797],
                                  [-245.208, 154.86],
                                  [-21.047, 290.879],
                                  [70.341, 387.929],
                                  [288.527, 278.531],
                                  [25.909, -91.382],
                                  [334.033, -215.38],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.874, -50.252],
                                  [-33.891, -133.613],
                                  [58.514, -37.464],
                                  [-135.351, -34.279],
                                  [-17.891, -70.776],
                                  [-114.01, 2.918],
                                  [140.422, 83.12],
                                  [-48.492, 152.963],
                                  [-8.393, 92],
                                ],
                                o: [
                                  [-99.344, 50.558],
                                  [33.891, 133.613],
                                  [-58.514, 37.464],
                                  [135.351, 34.279],
                                  [18.175, 71.126],
                                  [111.781, -2.437],
                                  [-140.422, -83.121],
                                  [48.077, -153.334],
                                  [8.393, -92],
                                ],
                                v: [
                                  [-144.566, -379.877],
                                  [-162.347, -154.241],
                                  [-246.359, 58.396],
                                  [-245.024, 155.041],
                                  [-22.296, 290.493],
                                  [71.001, 388.697],
                                  [289.026, 279.091],
                                  [25.875, -91.122],
                                  [334.356, -216.45],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.85, -50.301],
                                  [-33.95, -133.679],
                                  [58.556, -37.423],
                                  [-135.424, -34.271],
                                  [-17.894, -70.81],
                                  [-113.954, 2.892],
                                  [140.522, 83.145],
                                  [-48.534, 153.047],
                                  [-8.382, 92],
                                ],
                                o: [
                                  [-99.363, 50.598],
                                  [33.95, 133.679],
                                  [-58.556, 37.423],
                                  [135.424, 34.271],
                                  [18.17, 71.15],
                                  [111.788, -2.425],
                                  [-140.522, -83.145],
                                  [48.131, -153.408],
                                  [8.382, -92],
                                ],
                                v: [
                                  [-144.235, -379.654],
                                  [-162.056, -153.946],
                                  [-246.725, 57.99],
                                  [-244.838, 155.224],
                                  [-23.549, 290.105],
                                  [71.662, 389.466],
                                  [289.466, 279.589],
                                  [25.845, -90.858],
                                  [334.676, -217.519],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.826, -50.348],
                                  [-34.007, -133.744],
                                  [58.596, -37.384],
                                  [-135.496, -34.263],
                                  [-17.897, -70.844],
                                  [-113.9, 2.867],
                                  [140.619, 83.169],
                                  [-48.575, 153.13],
                                  [-8.372, 92],
                                ],
                                o: [
                                  [-99.38, 50.637],
                                  [34.007, 133.744],
                                  [-58.596, 37.383],
                                  [135.496, 34.263],
                                  [18.165, 71.174],
                                  [111.793, -2.413],
                                  [-140.619, -83.169],
                                  [48.183, -153.481],
                                  [8.372, -92],
                                ],
                                v: [
                                  [-143.779, -379.491],
                                  [-161.765, -153.714],
                                  [-247.091, 57.523],
                                  [-244.714, 155.406],
                                  [-24.745, 289.779],
                                  [72.385, 390.294],
                                  [289.907, 280.152],
                                  [25.819, -90.651],
                                  [334.992, -218.649],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.803, -50.394],
                                  [-34.063, -133.807],
                                  [58.635, -37.345],
                                  [-135.566, -34.256],
                                  [-17.9, -70.876],
                                  [-113.847, 2.843],
                                  [140.713, 83.192],
                                  [-48.615, 153.21],
                                  [-8.361, 92],
                                ],
                                o: [
                                  [-99.398, 50.675],
                                  [34.063, 133.807],
                                  [-58.635, 37.345],
                                  [135.566, 34.256],
                                  [18.161, 71.197],
                                  [111.799, -2.401],
                                  [-140.713, -83.192],
                                  [48.234, -153.551],
                                  [8.361, -92],
                                ],
                                v: [
                                  [-143.321, -379.265],
                                  [-161.535, -153.544],
                                  [-247.458, 57.052],
                                  [-244.651, 155.588],
                                  [-25.944, 289.39],
                                  [73.108, 391.18],
                                  [290.288, 280.653],
                                  [25.859, -90.383],
                                  [335.243, -219.718],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.78, -50.439],
                                  [-34.117, -133.869],
                                  [58.673, -37.307],
                                  [-135.634, -34.249],
                                  [-17.902, -70.907],
                                  [-113.795, 2.819],
                                  [140.805, 83.215],
                                  [-48.654, 153.288],
                                  [-8.351, 92],
                                ],
                                o: [
                                  [-99.415, 50.712],
                                  [34.117, 133.869],
                                  [-58.673, 37.307],
                                  [135.634, 34.249],
                                  [18.156, 71.22],
                                  [111.805, -2.39],
                                  [-140.805, -83.215],
                                  [48.283, -153.62],
                                  [8.351, -92],
                                ],
                                v: [
                                  [-142.86, -379.037],
                                  [-161.306, -153.374],
                                  [-247.763, 56.579],
                                  [-244.587, 155.77],
                                  [-27.208, 289.063],
                                  [73.894, 392.068],
                                  [290.671, 281.095],
                                  [25.841, -90.172],
                                  [335.431, -220.847],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.758, -50.483],
                                  [-34.17, -133.928],
                                  [58.711, -37.27],
                                  [-135.701, -34.242],
                                  [-17.905, -70.938],
                                  [-113.744, 2.796],
                                  [140.895, 83.237],
                                  [-48.692, 153.364],
                                  [-8.341, 92],
                                ],
                                o: [
                                  [-99.431, 50.749],
                                  [34.17, 133.928],
                                  [-58.711, 37.27],
                                  [135.701, 34.242],
                                  [18.152, 71.242],
                                  [111.81, -2.379],
                                  [-140.895, -83.237],
                                  [48.331, -153.687],
                                  [8.341, -92],
                                ],
                                v: [
                                  [-142.333, -378.869],
                                  [-161.016, -153.266],
                                  [-248.068, 56.042],
                                  [-244.585, 155.953],
                                  [-28.353, 288.733],
                                  [74.74, 392.954],
                                  [291.055, 281.538],
                                  [25.888, -89.958],
                                  [335.675, -221.914],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.737, -50.526],
                                  [-34.222, -133.987],
                                  [58.747, -37.235],
                                  [-135.766, -34.235],
                                  [-17.908, -70.968],
                                  [-113.695, 2.774],
                                  [140.983, 83.258],
                                  [-48.729, 153.439],
                                  [-8.332, 92],
                                ],
                                o: [
                                  [-99.447, 50.784],
                                  [34.222, 133.987],
                                  [-58.747, 37.235],
                                  [135.766, 34.235],
                                  [18.147, 71.263],
                                  [111.816, -2.369],
                                  [-140.983, -83.258],
                                  [48.378, -153.752],
                                  [8.332, -92],
                                ],
                                v: [
                                  [-141.806, -378.639],
                                  [-160.786, -153.159],
                                  [-248.373, 55.441],
                                  [-244.644, 156.075],
                                  [-29.562, 288.404],
                                  [75.588, 393.963],
                                  [291.38, 281.982],
                                  [25.939, -89.803],
                                  [335.795, -223.041],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.716, -50.567],
                                  [-34.272, -134.044],
                                  [58.782, -37.2],
                                  [-135.829, -34.228],
                                  [-17.91, -70.997],
                                  [-113.647, 2.752],
                                  [141.068, 83.279],
                                  [-48.764, 153.511],
                                  [-8.322, 92],
                                ],
                                o: [
                                  [-99.463, 50.818],
                                  [34.272, 134.044],
                                  [-58.782, 37.2],
                                  [135.829, 34.228],
                                  [18.143, 71.284],
                                  [111.821, -2.358],
                                  [-141.068, -83.279],
                                  [48.424, -153.815],
                                  [8.322, -92],
                                ],
                                v: [
                                  [-141.213, -378.405],
                                  [-160.497, -153.114],
                                  [-248.678, 54.899],
                                  [-244.704, 156.257],
                                  [-30.714, 288.075],
                                  [76.437, 394.909],
                                  [291.706, 282.426],
                                  [25.995, -89.646],
                                  [335.973, -224.104],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.696, -50.608],
                                  [-34.321, -134.099],
                                  [58.817, -37.166],
                                  [-135.89, -34.222],
                                  [-17.913, -71.026],
                                  [-113.601, 2.731],
                                  [141.151, 83.3],
                                  [-48.799, 153.581],
                                  [-8.313, 92],
                                ],
                                o: [
                                  [-99.478, 50.852],
                                  [34.321, 134.099],
                                  [-58.817, 37.166],
                                  [135.89, 34.222],
                                  [18.139, 71.304],
                                  [111.826, -2.348],
                                  [-141.151, -83.3],
                                  [48.469, -153.877],
                                  [8.313, -92],
                                ],
                                v: [
                                  [-140.618, -378.172],
                                  [-160.27, -153.069],
                                  [-248.983, 54.293],
                                  [-244.825, 156.38],
                                  [-31.869, 287.745],
                                  [77.348, 395.916],
                                  [291.973, 282.809],
                                  [26.115, -89.487],
                                  [336.087, -225.165],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.676, -50.648],
                                  [-34.369, -134.153],
                                  [58.85, -37.133],
                                  [-135.95, -34.216],
                                  [-17.915, -71.053],
                                  [-113.555, 2.71],
                                  [141.232, 83.32],
                                  [-48.834, 153.65],
                                  [-8.304, 92],
                                ],
                                o: [
                                  [-99.493, 50.884],
                                  [34.369, 134.153],
                                  [-58.85, 37.133],
                                  [135.95, 34.216],
                                  [18.135, 71.324],
                                  [111.831, -2.338],
                                  [-141.232, -83.32],
                                  [48.512, -153.938],
                                  [8.304, -92],
                                ],
                                v: [
                                  [-140.021, -377.936],
                                  [-159.98, -153.087],
                                  [-249.227, 53.624],
                                  [-244.946, 156.563],
                                  [-32.965, 287.414],
                                  [78.259, 396.924],
                                  [292.242, 283.194],
                                  [26.177, -89.325],
                                  [336.137, -226.287],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.657, -50.686],
                                  [-34.415, -134.206],
                                  [58.883, -37.1],
                                  [-136.009, -34.209],
                                  [-17.918, -71.08],
                                  [-113.511, 2.69],
                                  [141.31, 83.339],
                                  [-48.867, 153.717],
                                  [-8.296, 92],
                                ],
                                o: [
                                  [-99.507, 50.916],
                                  [34.415, 134.206],
                                  [-58.883, 37.1],
                                  [136.009, 34.209],
                                  [18.131, 71.343],
                                  [111.836, -2.328],
                                  [-141.311, -83.339],
                                  [48.555, -153.996],
                                  [8.296, -92],
                                ],
                                v: [
                                  [-139.359, -377.699],
                                  [-159.753, -153.167],
                                  [-249.47, 53.014],
                                  [-245.129, 156.685],
                                  [-34.065, 287.143],
                                  [79.233, 397.991],
                                  [292.513, 283.577],
                                  [26.304, -89.224],
                                  [336.184, -227.345],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.638, -50.724],
                                  [-34.46, -134.257],
                                  [58.915, -37.069],
                                  [-136.065, -34.203],
                                  [-17.92, -71.107],
                                  [-113.468, 2.67],
                                  [141.387, 83.358],
                                  [-48.899, 153.782],
                                  [-8.287, 92],
                                ],
                                o: [
                                  [-99.521, 50.947],
                                  [34.46, 134.257],
                                  [-58.915, 37.069],
                                  [136.065, 34.203],
                                  [18.128, 71.362],
                                  [111.84, -2.319],
                                  [-141.387, -83.358],
                                  [48.596, -154.053],
                                  [8.287, -92],
                                ],
                                v: [
                                  [-138.694, -377.459],
                                  [-159.525, -153.247],
                                  [-249.714, 52.34],
                                  [-245.313, 156.869],
                                  [-35.167, 286.871],
                                  [80.207, 399.06],
                                  [292.725, 283.901],
                                  [26.434, -89.119],
                                  [336.23, -228.402],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.62, -50.76],
                                  [-34.504, -134.307],
                                  [58.946, -37.038],
                                  [-136.121, -34.198],
                                  [-17.922, -71.132],
                                  [-113.426, 2.651],
                                  [141.462, 83.376],
                                  [-48.931, 153.846],
                                  [-8.279, 92],
                                ],
                                o: [
                                  [-99.535, 50.977],
                                  [34.504, 134.307],
                                  [-58.946, 37.038],
                                  [136.121, 34.198],
                                  [18.124, 71.38],
                                  [111.845, -2.31],
                                  [-141.462, -83.376],
                                  [48.636, -154.109],
                                  [8.279, -92],
                                ],
                                v: [
                                  [-138.028, -377.22],
                                  [-159.298, -153.329],
                                  [-249.957, 51.663],
                                  [-245.557, 156.992],
                                  [-36.21, 286.599],
                                  [81.243, 400.127],
                                  [292.938, 284.223],
                                  [26.629, -89.013],
                                  [336.21, -229.458],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.602, -50.796],
                                  [-34.547, -134.355],
                                  [58.976, -37.009],
                                  [-136.175, -34.192],
                                  [-17.925, -71.157],
                                  [-113.385, 2.632],
                                  [141.535, 83.394],
                                  [-48.961, 153.907],
                                  [-8.271, 92],
                                ],
                                o: [
                                  [-99.548, 51.006],
                                  [34.547, 134.355],
                                  [-58.976, 37.009],
                                  [136.175, 34.192],
                                  [18.12, 71.398],
                                  [111.849, -2.301],
                                  [-141.535, -83.394],
                                  [48.675, -154.163],
                                  [8.271, -92],
                                ],
                                v: [
                                  [-137.36, -377.039],
                                  [-159.132, -153.473],
                                  [-250.139, 50.985],
                                  [-245.864, 157.176],
                                  [-37.194, 286.326],
                                  [82.22, 401.195],
                                  [293.152, 284.547],
                                  [26.766, -88.967],
                                  [336.188, -230.45],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.585, -50.83],
                                  [-34.589, -134.403],
                                  [59.006, -36.98],
                                  [-136.227, -34.186],
                                  [-17.927, -71.181],
                                  [-113.345, 2.614],
                                  [141.606, 83.411],
                                  [-48.991, 153.968],
                                  [-8.263, 92],
                                ],
                                o: [
                                  [-99.561, 51.035],
                                  [34.589, 134.403],
                                  [-59.006, 36.98],
                                  [136.227, 34.186],
                                  [18.117, 71.415],
                                  [111.854, -2.292],
                                  [-141.606, -83.411],
                                  [48.713, -154.216],
                                  [8.263, -92],
                                ],
                                v: [
                                  [-136.627, -376.796],
                                  [-158.966, -153.679],
                                  [-250.32, 50.304],
                                  [-246.171, 157.299],
                                  [-38.182, 286.051],
                                  [83.258, 402.262],
                                  [293.306, 284.809],
                                  [26.966, -88.857],
                                  [336.102, -231.502],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.568, -50.864],
                                  [-34.63, -134.449],
                                  [59.034, -36.951],
                                  [-136.278, -34.181],
                                  [-17.929, -71.205],
                                  [-113.306, 2.596],
                                  [141.675, 83.428],
                                  [-49.02, 154.026],
                                  [-8.256, 92],
                                ],
                                o: [
                                  [-99.574, 51.063],
                                  [34.63, 134.449],
                                  [-59.034, 36.951],
                                  [136.278, 34.181],
                                  [18.114, 71.432],
                                  [111.858, -2.284],
                                  [-141.675, -83.428],
                                  [48.75, -154.268],
                                  [8.256, -92],
                                ],
                                v: [
                                  [-135.892, -376.55],
                                  [-158.799, -153.886],
                                  [-250.501, 49.559],
                                  [-246.541, 157.484],
                                  [-39.171, 285.837],
                                  [84.297, 403.33],
                                  [293.463, 285.07],
                                  [27.171, -88.869],
                                  [336.014, -232.492],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.552, -50.897],
                                  [-34.669, -134.493],
                                  [59.062, -36.924],
                                  [-136.328, -34.176],
                                  [-17.931, -71.228],
                                  [-113.269, 2.579],
                                  [141.742, 83.445],
                                  [-49.048, 154.083],
                                  [-8.248, 92],
                                ],
                                o: [
                                  [-99.586, 51.09],
                                  [34.669, 134.493],
                                  [-59.062, 36.924],
                                  [136.328, 34.176],
                                  [18.11, 71.448],
                                  [111.862, -2.276],
                                  [-141.742, -83.445],
                                  [48.787, -154.318],
                                  [8.248, -92],
                                ],
                                v: [
                                  [-135.156, -376.364],
                                  [-158.633, -154.157],
                                  [-250.62, 48.874],
                                  [-246.91, 157.67],
                                  [-40.101, 285.561],
                                  [85.336, 404.398],
                                  [293.621, 285.333],
                                  [27.378, -88.817],
                                  [335.861, -233.481],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.536, -50.929],
                                  [-34.708, -134.537],
                                  [59.089, -36.897],
                                  [-136.376, -34.171],
                                  [-17.933, -71.25],
                                  [-113.232, 2.562],
                                  [141.807, 83.461],
                                  [-49.076, 154.139],
                                  [-8.241, 92],
                                ],
                                o: [
                                  [-99.598, 51.116],
                                  [34.708, 134.537],
                                  [-59.089, 36.897],
                                  [136.376, 34.171],
                                  [18.107, 71.464],
                                  [111.866, -2.268],
                                  [-141.807, -83.461],
                                  [48.822, -154.366],
                                  [8.241, -92],
                                ],
                                v: [
                                  [-134.356, -376.176],
                                  [-158.528, -154.428],
                                  [-250.801, 48.186],
                                  [-247.342, 157.793],
                                  [-40.971, 285.345],
                                  [86.437, 405.465],
                                  [293.718, 285.595],
                                  [27.649, -88.826],
                                  [335.768, -234.468],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.52, -50.96],
                                  [-34.745, -134.58],
                                  [59.116, -36.871],
                                  [-136.424, -34.166],
                                  [-17.935, -71.272],
                                  [-113.196, 2.546],
                                  [141.871, 83.477],
                                  [-49.103, 154.193],
                                  [-8.234, 92],
                                ],
                                o: [
                                  [-99.61, 51.142],
                                  [34.745, 134.58],
                                  [-59.116, 36.871],
                                  [136.424, 34.166],
                                  [18.104, 71.48],
                                  [111.87, -2.26],
                                  [-141.871, -83.477],
                                  [48.856, -154.414],
                                  [8.234, -92],
                                ],
                                v: [
                                  [-133.615, -375.924],
                                  [-158.423, -154.761],
                                  [-250.92, 47.436],
                                  [-247.775, 157.979],
                                  [-41.783, 285.128],
                                  [87.54, 406.532],
                                  [293.816, 285.796],
                                  [27.923, -88.833],
                                  [335.548, -235.454],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.505, -50.99],
                                  [-34.782, -134.621],
                                  [59.142, -36.846],
                                  [-136.47, -34.161],
                                  [-17.937, -71.293],
                                  [-113.161, 2.53],
                                  [141.933, 83.492],
                                  [-49.129, 154.245],
                                  [-8.227, 92],
                                ],
                                o: [
                                  [-99.621, 51.167],
                                  [34.782, 134.621],
                                  [-59.142, 36.846],
                                  [136.47, 34.161],
                                  [18.101, 71.495],
                                  [111.874, -2.252],
                                  [-141.933, -83.492],
                                  [48.889, -154.46],
                                  [8.227, -92],
                                ],
                                v: [
                                  [-132.811, -375.733],
                                  [-158.317, -155.096],
                                  [-250.977, 46.744],
                                  [-248.27, 158.166],
                                  [-42.595, 284.972],
                                  [88.582, 407.6],
                                  [293.916, 285.998],
                                  [28.2, -88.838],
                                  [335.326, -236.439],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.49, -51.02],
                                  [-34.818, -134.661],
                                  [59.167, -36.821],
                                  [-136.514, -34.156],
                                  [-17.939, -71.314],
                                  [-113.127, 2.515],
                                  [141.993, 83.507],
                                  [-49.155, 154.297],
                                  [-8.221, 92],
                                ],
                                o: [
                                  [-99.632, 51.191],
                                  [34.818, 134.661],
                                  [-59.167, 36.821],
                                  [136.514, 34.156],
                                  [18.098, 71.51],
                                  [111.877, -2.245],
                                  [-141.993, -83.507],
                                  [48.922, -154.505],
                                  [8.221, -92],
                                ],
                                v: [
                                  [-132.067, -375.601],
                                  [-158.273, -155.432],
                                  [-251.095, 46.05],
                                  [-248.765, 158.29],
                                  [-43.411, 284.753],
                                  [89.686, 408.606],
                                  [293.956, 286.199],
                                  [28.48, -88.903],
                                  [335.103, -237.36],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.476, -51.048],
                                  [-34.852, -134.7],
                                  [59.191, -36.797],
                                  [-136.558, -34.152],
                                  [-17.941, -71.334],
                                  [-113.094, 2.5],
                                  [142.052, 83.521],
                                  [-49.179, 154.347],
                                  [-8.214, 92],
                                ],
                                o: [
                                  [-99.643, 51.215],
                                  [34.852, 134.701],
                                  [-59.191, 36.797],
                                  [136.558, 34.152],
                                  [18.095, 71.524],
                                  [111.881, -2.238],
                                  [-142.052, -83.521],
                                  [48.953, -154.549],
                                  [8.214, -92],
                                ],
                                v: [
                                  [-131.259, -375.405],
                                  [-158.229, -155.83],
                                  [-251.152, 45.355],
                                  [-249.261, 158.477],
                                  [-44.104, 284.596],
                                  [90.791, 409.673],
                                  [293.996, 286.338],
                                  [28.762, -89.029],
                                  [334.877, -238.281],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.462, -51.076],
                                  [-34.886, -134.739],
                                  [59.215, -36.774],
                                  [-136.6, -34.147],
                                  [-17.942, -71.353],
                                  [-113.062, 2.485],
                                  [142.109, 83.535],
                                  [-49.203, 154.395],
                                  [-8.208, 92],
                                ],
                                o: [
                                  [-99.654, 51.238],
                                  [34.886, 134.739],
                                  [-59.215, 36.774],
                                  [136.6, 34.147],
                                  [18.092, 71.538],
                                  [111.885, -2.231],
                                  [-142.109, -83.535],
                                  [48.984, -154.591],
                                  [8.208, -92],
                                ],
                                v: [
                                  [-130.45, -375.27],
                                  [-158.184, -156.23],
                                  [-251.208, 44.597],
                                  [-249.88, 158.664],
                                  [-44.799, 284.436],
                                  [91.896, 410.68],
                                  [294.037, 286.478],
                                  [29.109, -89.091],
                                  [334.587, -239.139],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.448, -51.104],
                                  [-34.919, -134.776],
                                  [59.238, -36.751],
                                  [-136.641, -34.143],
                                  [-17.944, -71.372],
                                  [-113.031, 2.471],
                                  [142.165, 83.549],
                                  [-49.227, 154.442],
                                  [-8.202, 92],
                                ],
                                o: [
                                  [-99.664, 51.26],
                                  [34.919, 134.776],
                                  [-59.238, 36.751],
                                  [136.641, 34.143],
                                  [18.09, 71.552],
                                  [111.888, -2.224],
                                  [-142.165, -83.549],
                                  [49.014, -154.633],
                                  [8.202, -92],
                                ],
                                v: [
                                  [-129.701, -375.071],
                                  [-158.201, -156.692],
                                  [-251.265, 43.959],
                                  [-250.438, 158.852],
                                  [-45.433, 284.277],
                                  [93.002, 411.685],
                                  [294.018, 286.618],
                                  [29.458, -89.215],
                                  [334.232, -240.057],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.435, -51.13],
                                  [-34.951, -134.812],
                                  [59.26, -36.729],
                                  [-136.681, -34.139],
                                  [-17.946, -71.391],
                                  [-113.001, 2.457],
                                  [142.219, 83.562],
                                  [-49.25, 154.488],
                                  [-8.196, 92],
                                ],
                                o: [
                                  [-99.674, 51.282],
                                  [34.951, 134.812],
                                  [-59.261, 36.729],
                                  [136.681, 34.139],
                                  [18.087, 71.565],
                                  [111.891, -2.218],
                                  [-142.219, -83.562],
                                  [49.043, -154.673],
                                  [8.196, -92],
                                ],
                                v: [
                                  [-128.888, -374.932],
                                  [-158.279, -157.155],
                                  [-251.259, 43.259],
                                  [-251.059, 158.977],
                                  [-46.07, 284.178],
                                  [94.109, 412.63],
                                  [294.061, 286.757],
                                  [29.808, -89.398],
                                  [333.938, -240.913],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.422, -51.156],
                                  [-34.982, -134.847],
                                  [59.282, -36.707],
                                  [-136.72, -34.135],
                                  [-17.947, -71.409],
                                  [-112.971, 2.443],
                                  [142.272, 83.575],
                                  [-49.272, 154.533],
                                  [-8.19, 92],
                                ],
                                o: [
                                  [-99.683, 51.303],
                                  [34.982, 134.847],
                                  [-59.282, 36.707],
                                  [136.72, 34.135],
                                  [18.084, 71.578],
                                  [111.895, -2.211],
                                  [-142.272, -83.575],
                                  [49.071, -154.713],
                                  [8.19, -92],
                                ],
                                v: [
                                  [-128.135, -374.853],
                                  [-158.357, -157.62],
                                  [-251.254, 42.556],
                                  [-251.742, 159.166],
                                  [-46.646, 284.078],
                                  [95.216, 413.636],
                                  [294.043, 286.897],
                                  [30.162, -89.581],
                                  [333.517, -241.767],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.41, -51.181],
                                  [-35.012, -134.881],
                                  [59.304, -36.686],
                                  [-136.758, -34.131],
                                  [-17.949, -71.427],
                                  [-112.942, 2.43],
                                  [142.323, 83.588],
                                  [-49.293, 154.577],
                                  [-8.184, 92],
                                ],
                                o: [
                                  [-99.693, 51.324],
                                  [35.012, 134.881],
                                  [-59.304, 36.686],
                                  [136.758, 34.131],
                                  [18.082, 71.59],
                                  [111.898, -2.205],
                                  [-142.323, -83.588],
                                  [49.099, -154.751],
                                  [8.184, -92],
                                ],
                                v: [
                                  [-127.32, -374.711],
                                  [-158.434, -158.147],
                                  [-251.248, 41.915],
                                  [-252.426, 159.354],
                                  [-47.1, 283.977],
                                  [96.324, 414.581],
                                  [293.965, 286.975],
                                  [30.58, -89.762],
                                  [333.156, -242.558],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.397, -51.205],
                                  [-35.041, -134.915],
                                  [59.324, -36.666],
                                  [-136.795, -34.127],
                                  [-17.95, -71.444],
                                  [-112.914, 2.417],
                                  [142.373, 83.6],
                                  [-49.314, 154.619],
                                  [-8.179, 92],
                                ],
                                o: [
                                  [-99.702, 51.344],
                                  [35.041, 134.915],
                                  [-59.324, 36.666],
                                  [136.795, 34.127],
                                  [18.079, 71.603],
                                  [111.901, -2.199],
                                  [-142.373, -83.6],
                                  [49.126, -154.788],
                                  [8.179, -92],
                                ],
                                v: [
                                  [-126.564, -374.63],
                                  [-158.574, -158.675],
                                  [-251.242, 41.272],
                                  [-253.109, 159.543],
                                  [-47.555, 283.875],
                                  [97.371, 415.464],
                                  [293.948, 287.053],
                                  [31, -90.004],
                                  [332.732, -243.349],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.386, -51.229],
                                  [-35.07, -134.947],
                                  [59.345, -36.646],
                                  [-136.831, -34.123],
                                  [-17.952, -71.46],
                                  [-112.887, 2.405],
                                  [142.422, 83.612],
                                  [-49.335, 154.66],
                                  [-8.174, 92],
                                ],
                                o: [
                                  [-99.711, 51.364],
                                  [35.07, 134.947],
                                  [-59.345, 36.646],
                                  [136.831, 34.123],
                                  [18.077, 71.614],
                                  [111.904, -2.193],
                                  [-142.422, -83.612],
                                  [49.152, -154.824],
                                  [8.174, -92],
                                ],
                                v: [
                                  [-125.807, -374.609],
                                  [-158.712, -159.204],
                                  [-251.175, 40.689],
                                  [-253.856, 159.67],
                                  [-48.012, 283.834],
                                  [98.48, 416.347],
                                  [293.871, 287.131],
                                  [31.422, -90.245],
                                  [332.305, -244.076],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.374, -51.252],
                                  [-35.098, -134.978],
                                  [59.364, -36.627],
                                  [-136.866, -34.119],
                                  [-17.953, -71.476],
                                  [-112.86, 2.393],
                                  [142.469, 83.624],
                                  [-49.355, 154.7],
                                  [-8.168, 92],
                                ],
                                o: [
                                  [-99.719, 51.383],
                                  [35.098, 134.978],
                                  [-59.364, 36.626],
                                  [136.866, 34.119],
                                  [18.075, 71.626],
                                  [111.907, -2.187],
                                  [-142.469, -83.624],
                                  [49.177, -154.859],
                                  [8.168, -92],
                                ],
                                v: [
                                  [-125.049, -374.525],
                                  [-158.912, -159.735],
                                  [-251.107, 40.042],
                                  [-254.603, 159.859],
                                  [-48.346, 283.731],
                                  [99.59, 417.168],
                                  [293.795, 287.208],
                                  [31.908, -90.546],
                                  [331.814, -244.865],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.363, -51.274],
                                  [-35.125, -135.009],
                                  [59.383, -36.608],
                                  [-136.9, -34.116],
                                  [-17.955, -71.492],
                                  [-112.835, 2.381],
                                  [142.515, 83.635],
                                  [-49.374, 154.739],
                                  [-8.163, 92],
                                ],
                                o: [
                                  [-99.728, 51.401],
                                  [35.125, 135.009],
                                  [-59.383, 36.608],
                                  [136.9, 34.116],
                                  [18.073, 71.637],
                                  [111.909, -2.181],
                                  [-142.515, -83.635],
                                  [49.202, -154.893],
                                  [8.163, -92],
                                ],
                                v: [
                                  [-124.289, -374.501],
                                  [-159.112, -160.328],
                                  [-251.039, 39.457],
                                  [-255.351, 159.986],
                                  [-48.619, 283.688],
                                  [100.638, 417.989],
                                  [293.719, 287.224],
                                  [32.334, -90.847],
                                  [331.322, -245.528],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.374, -51.252],
                                  [-35.098, -134.978],
                                  [59.364, -36.626],
                                  [-136.866, -34.119],
                                  [-17.953, -71.476],
                                  [-112.86, 2.393],
                                  [142.469, 83.624],
                                  [-49.355, 154.7],
                                  [-8.168, 92],
                                ],
                                o: [
                                  [-99.719, 51.383],
                                  [35.098, 134.978],
                                  [-59.364, 36.626],
                                  [136.866, 34.119],
                                  [18.075, 71.626],
                                  [111.906, -2.187],
                                  [-142.469, -83.624],
                                  [49.177, -154.859],
                                  [8.168, -92],
                                ],
                                v: [
                                  [-123.493, -374.412],
                                  [-159.366, -160.911],
                                  [-250.924, 38.86],
                                  [-256.143, 160.163],
                                  [-48.969, 283.623],
                                  [101.695, 418.701],
                                  [293.668, 287.208],
                                  [32.966, -91.099],
                                  [330.702, -246.166],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.386, -51.229],
                                  [-35.07, -134.947],
                                  [59.345, -36.646],
                                  [-136.831, -34.123],
                                  [-17.952, -71.46],
                                  [-112.887, 2.405],
                                  [142.422, 83.612],
                                  [-49.335, 154.66],
                                  [-8.174, 92],
                                ],
                                o: [
                                  [-99.711, 51.364],
                                  [35.07, 134.947],
                                  [-59.345, 36.646],
                                  [136.831, 34.123],
                                  [18.077, 71.614],
                                  [111.904, -2.193],
                                  [-142.422, -83.612],
                                  [49.152, -154.824],
                                  [8.173, -92],
                                ],
                                v: [
                                  [-122.697, -374.384],
                                  [-159.62, -161.555],
                                  [-250.87, 38.263],
                                  [-256.934, 160.277],
                                  [-49.259, 283.62],
                                  [102.751, 419.411],
                                  [293.618, 287.253],
                                  [33.6, -91.412],
                                  [330.019, -246.74],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.397, -51.205],
                                  [-35.041, -134.915],
                                  [59.324, -36.666],
                                  [-136.795, -34.127],
                                  [-17.95, -71.444],
                                  [-112.914, 2.417],
                                  [142.373, 83.6],
                                  [-49.314, 154.619],
                                  [-8.179, 92],
                                ],
                                o: [
                                  [-99.702, 51.344],
                                  [35.041, 134.915],
                                  [-59.324, 36.666],
                                  [136.795, 34.127],
                                  [18.079, 71.603],
                                  [111.901, -2.199],
                                  [-142.373, -83.6],
                                  [49.126, -154.788],
                                  [8.179, -92],
                                ],
                                v: [
                                  [-121.899, -374.356],
                                  [-159.875, -162.138],
                                  [-250.693, 37.665],
                                  [-257.725, 160.392],
                                  [-49.425, 283.616],
                                  [103.806, 420.058],
                                  [293.508, 287.236],
                                  [34.298, -91.723],
                                  [329.336, -247.251],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.41, -51.181],
                                  [-35.012, -134.881],
                                  [59.304, -36.686],
                                  [-136.758, -34.131],
                                  [-17.949, -71.427],
                                  [-112.942, 2.43],
                                  [142.323, 83.588],
                                  [-49.293, 154.577],
                                  [-8.184, 92],
                                ],
                                o: [
                                  [-99.693, 51.324],
                                  [35.012, 134.881],
                                  [-59.304, 36.686],
                                  [136.758, 34.131],
                                  [18.082, 71.59],
                                  [111.898, -2.205],
                                  [-142.323, -83.588],
                                  [49.099, -154.751],
                                  [8.184, -92],
                                ],
                                v: [
                                  [-121.101, -374.327],
                                  [-160.254, -162.783],
                                  [-250.578, 37.127],
                                  [-258.577, 160.506],
                                  [-49.593, 283.613],
                                  [104.799, 420.641],
                                  [293.397, 287.157],
                                  [34.998, -92.095],
                                  [328.651, -247.76],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.422, -51.156],
                                  [-34.982, -134.847],
                                  [59.282, -36.707],
                                  [-136.72, -34.135],
                                  [-17.947, -71.409],
                                  [-112.971, 2.443],
                                  [142.272, 83.575],
                                  [-49.272, 154.533],
                                  [-8.19, 92],
                                ],
                                o: [
                                  [-99.683, 51.303],
                                  [34.982, 134.847],
                                  [-59.282, 36.707],
                                  [136.72, 34.135],
                                  [18.084, 71.578],
                                  [111.895, -2.211],
                                  [-142.272, -83.575],
                                  [49.071, -154.713],
                                  [8.19, -92],
                                ],
                                v: [
                                  [-120.363, -374.359],
                                  [-160.572, -163.429],
                                  [-250.463, 36.589],
                                  [-259.427, 160.62],
                                  [-49.7, 283.61],
                                  [105.852, 421.223],
                                  [293.287, 287.14],
                                  [35.7, -92.464],
                                  [327.903, -248.267],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.435, -51.13],
                                  [-34.951, -134.812],
                                  [59.26, -36.729],
                                  [-136.681, -34.139],
                                  [-17.946, -71.391],
                                  [-113.001, 2.457],
                                  [142.219, 83.562],
                                  [-49.25, 154.488],
                                  [-8.196, 92],
                                ],
                                o: [
                                  [-99.674, 51.282],
                                  [34.951, 134.812],
                                  [-59.26, 36.729],
                                  [136.681, 34.139],
                                  [18.087, 71.565],
                                  [111.891, -2.217],
                                  [-142.219, -83.562],
                                  [49.043, -154.673],
                                  [8.196, -92],
                                ],
                                v: [
                                  [-119.625, -374.391],
                                  [-160.952, -164.013],
                                  [-250.287, 36.111],
                                  [-260.338, 160.672],
                                  [-49.747, 283.608],
                                  [106.843, 421.74],
                                  [293.179, 287.123],
                                  [36.342, -92.955],
                                  [327.215, -248.71],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.448, -51.104],
                                  [-34.919, -134.776],
                                  [59.238, -36.751],
                                  [-136.641, -34.143],
                                  [-17.944, -71.372],
                                  [-113.031, 2.471],
                                  [142.165, 83.549],
                                  [-49.227, 154.442],
                                  [-8.202, 92],
                                ],
                                o: [
                                  [-99.664, 51.26],
                                  [34.919, 134.776],
                                  [-59.238, 36.751],
                                  [136.641, 34.143],
                                  [18.09, 71.552],
                                  [111.888, -2.224],
                                  [-142.165, -83.549],
                                  [49.014, -154.633],
                                  [8.202, -92],
                                ],
                                v: [
                                  [-118.946, -374.484],
                                  [-161.333, -164.597],
                                  [-250.111, 35.632],
                                  [-261.186, 160.724],
                                  [-49.733, 283.667],
                                  [107.772, 422.195],
                                  [293.071, 287.044],
                                  [36.985, -93.383],
                                  [326.403, -249.152],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.462, -51.076],
                                  [-34.886, -134.739],
                                  [59.215, -36.774],
                                  [-136.6, -34.147],
                                  [-17.942, -71.353],
                                  [-113.062, 2.485],
                                  [142.109, 83.535],
                                  [-49.203, 154.395],
                                  [-8.208, 92],
                                ],
                                o: [
                                  [-99.654, 51.238],
                                  [34.886, 134.739],
                                  [-59.215, 36.774],
                                  [136.6, 34.147],
                                  [18.092, 71.538],
                                  [111.884, -2.231],
                                  [-142.109, -83.535],
                                  [48.984, -154.591],
                                  [8.208, -92],
                                ],
                                v: [
                                  [-118.268, -374.576],
                                  [-161.776, -165.182],
                                  [-249.874, 35.214],
                                  [-262.095, 160.776],
                                  [-49.659, 283.725],
                                  [108.761, 422.647],
                                  [292.964, 286.965],
                                  [37.631, -93.932],
                                  [325.652, -249.528],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.476, -51.048],
                                  [-34.852, -134.7],
                                  [59.191, -36.797],
                                  [-136.558, -34.152],
                                  [-17.941, -71.334],
                                  [-113.094, 2.5],
                                  [142.052, 83.521],
                                  [-49.179, 154.347],
                                  [-8.214, 92],
                                ],
                                o: [
                                  [-99.643, 51.215],
                                  [34.852, 134.7],
                                  [-59.191, 36.797],
                                  [136.558, 34.152],
                                  [18.095, 71.524],
                                  [111.881, -2.238],
                                  [-142.052, -83.521],
                                  [48.953, -154.549],
                                  [8.214, -92],
                                ],
                                v: [
                                  [-117.649, -374.668],
                                  [-162.282, -165.766],
                                  [-249.698, 34.794],
                                  [-263.003, 160.828],
                                  [-49.586, 283.785],
                                  [109.626, 423.035],
                                  [292.858, 286.948],
                                  [38.216, -94.48],
                                  [324.838, -249.903],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.49, -51.02],
                                  [-34.818, -134.661],
                                  [59.167, -36.821],
                                  [-136.514, -34.156],
                                  [-17.939, -71.314],
                                  [-113.127, 2.515],
                                  [141.993, 83.507],
                                  [-49.155, 154.297],
                                  [-8.221, 92],
                                ],
                                o: [
                                  [-99.632, 51.191],
                                  [34.818, 134.661],
                                  [-59.167, 36.821],
                                  [136.514, 34.156],
                                  [18.098, 71.51],
                                  [111.877, -2.245],
                                  [-141.993, -83.507],
                                  [48.922, -154.505],
                                  [8.221, -92],
                                ],
                                v: [
                                  [-117.03, -374.82],
                                  [-162.727, -166.29],
                                  [-249.461, 34.496],
                                  [-263.909, 160.818],
                                  [-49.392, 283.905],
                                  [110.551, 423.484],
                                  [292.753, 286.869],
                                  [38.743, -95.025],
                                  [324.022, -250.215],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.505, -50.99],
                                  [-34.782, -134.621],
                                  [59.142, -36.846],
                                  [-136.47, -34.161],
                                  [-17.937, -71.293],
                                  [-113.161, 2.53],
                                  [141.933, 83.492],
                                  [-49.129, 154.246],
                                  [-8.227, 92],
                                ],
                                o: [
                                  [-99.621, 51.167],
                                  [34.782, 134.621],
                                  [-59.142, 36.846],
                                  [136.47, 34.161],
                                  [18.101, 71.495],
                                  [111.874, -2.252],
                                  [-141.933, -83.492],
                                  [48.889, -154.46],
                                  [8.227, -92],
                                ],
                                v: [
                                  [-116.41, -374.972],
                                  [-163.233, -166.814],
                                  [-249.224, 34.136],
                                  [-264.877, 160.808],
                                  [-49.137, 284.026],
                                  [111.414, 423.868],
                                  [292.589, 286.79],
                                  [39.333, -95.692],
                                  [323.206, -250.524],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.52, -50.96],
                                  [-34.745, -134.58],
                                  [59.116, -36.871],
                                  [-136.424, -34.166],
                                  [-17.935, -71.272],
                                  [-113.196, 2.546],
                                  [141.871, 83.477],
                                  [-49.103, 154.193],
                                  [-8.234, 92],
                                ],
                                o: [
                                  [-99.61, 51.142],
                                  [34.745, 134.58],
                                  [-59.116, 36.871],
                                  [136.424, 34.166],
                                  [18.104, 71.48],
                                  [111.87, -2.26],
                                  [-141.871, -83.477],
                                  [48.856, -154.414],
                                  [8.234, -92],
                                ],
                                v: [
                                  [-115.849, -375.185],
                                  [-163.802, -167.339],
                                  [-248.989, 33.898],
                                  [-265.78, 160.736],
                                  [-48.823, 284.146],
                                  [112.276, 424.189],
                                  [292.486, 286.712],
                                  [39.802, -96.293],
                                  [322.388, -250.769],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.536, -50.929],
                                  [-34.708, -134.537],
                                  [59.089, -36.897],
                                  [-136.376, -34.171],
                                  [-17.933, -71.25],
                                  [-113.232, 2.562],
                                  [141.807, 83.461],
                                  [-49.076, 154.139],
                                  [-8.241, 92],
                                ],
                                o: [
                                  [-99.598, 51.116],
                                  [34.708, 134.537],
                                  [-59.089, 36.897],
                                  [136.376, 34.171],
                                  [18.107, 71.464],
                                  [111.866, -2.268],
                                  [-141.807, -83.461],
                                  [48.822, -154.366],
                                  [8.241, -92],
                                ],
                                v: [
                                  [-115.35, -375.397],
                                  [-164.31, -167.801],
                                  [-248.692, 33.657],
                                  [-266.745, 160.603],
                                  [-48.51, 284.267],
                                  [113.137, 424.57],
                                  [292.385, 286.572],
                                  [40.334, -97.018],
                                  [321.508, -250.949],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.552, -50.897],
                                  [-34.669, -134.493],
                                  [59.062, -36.924],
                                  [-136.328, -34.176],
                                  [-17.931, -71.228],
                                  [-113.269, 2.579],
                                  [141.742, 83.445],
                                  [-49.048, 154.083],
                                  [-8.248, 92],
                                ],
                                o: [
                                  [-99.586, 51.09],
                                  [34.669, 134.493],
                                  [-59.062, 36.924],
                                  [136.328, 34.176],
                                  [18.11, 71.448],
                                  [111.862, -2.276],
                                  [-141.742, -83.445],
                                  [48.786, -154.318],
                                  [8.248, -92],
                                ],
                                v: [
                                  [-114.848, -375.67],
                                  [-164.818, -168.327],
                                  [-248.456, 33.415],
                                  [-267.707, 160.469],
                                  [-48.077, 284.386],
                                  [113.934, 424.887],
                                  [292.224, 286.493],
                                  [40.807, -97.739],
                                  [320.627, -251.189],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.568, -50.864],
                                  [-34.63, -134.449],
                                  [59.034, -36.951],
                                  [-136.278, -34.181],
                                  [-17.929, -71.205],
                                  [-113.306, 2.596],
                                  [141.675, 83.428],
                                  [-49.02, 154.026],
                                  [-8.256, 92],
                                ],
                                o: [
                                  [-99.574, 51.063],
                                  [34.63, 134.449],
                                  [-59.034, 36.951],
                                  [136.278, 34.181],
                                  [18.114, 71.432],
                                  [111.858, -2.284],
                                  [-141.675, -83.428],
                                  [48.75, -154.268],
                                  [8.256, -92],
                                ],
                                v: [
                                  [-114.408, -375.942],
                                  [-165.327, -168.914],
                                  [-248.161, 33.295],
                                  [-268.669, 160.337],
                                  [-47.583, 284.567],
                                  [114.731, 425.203],
                                  [292.126, 286.415],
                                  [41.221, -98.458],
                                  [319.745, -251.365],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.585, -50.83],
                                  [-34.589, -134.403],
                                  [59.006, -36.98],
                                  [-136.227, -34.186],
                                  [-17.927, -71.181],
                                  [-113.345, 2.614],
                                  [141.606, 83.411],
                                  [-48.991, 153.968],
                                  [-8.263, 92],
                                ],
                                o: [
                                  [-99.561, 51.035],
                                  [34.589, 134.403],
                                  [-59.006, 36.98],
                                  [136.227, 34.186],
                                  [18.117, 71.415],
                                  [111.854, -2.292],
                                  [-141.606, -83.411],
                                  [48.713, -154.216],
                                  [8.263, -92],
                                ],
                                v: [
                                  [-114.028, -376.274],
                                  [-165.837, -169.441],
                                  [-247.865, 33.173],
                                  [-269.567, 160.143],
                                  [-47.093, 284.748],
                                  [115.527, 425.515],
                                  [292.03, 286.276],
                                  [41.638, -99.236],
                                  [318.923, -251.599],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.602, -50.796],
                                  [-34.547, -134.355],
                                  [58.976, -37.009],
                                  [-136.175, -34.192],
                                  [-17.925, -71.157],
                                  [-113.385, 2.632],
                                  [141.535, 83.394],
                                  [-48.961, 153.907],
                                  [-8.271, 92],
                                ],
                                o: [
                                  [-99.548, 51.006],
                                  [34.547, 134.355],
                                  [-58.976, 37.009],
                                  [136.175, 34.192],
                                  [18.12, 71.398],
                                  [111.849, -2.301],
                                  [-141.535, -83.394],
                                  [48.675, -154.163],
                                  [8.271, -92],
                                ],
                                v: [
                                  [-113.646, -376.605],
                                  [-166.285, -170.029],
                                  [-247.57, 33.11],
                                  [-270.525, 159.887],
                                  [-46.48, 284.928],
                                  [116.259, 425.765],
                                  [291.935, 286.198],
                                  [41.994, -100.073],
                                  [318.039, -251.77],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.62, -50.76],
                                  [-34.504, -134.307],
                                  [58.946, -37.038],
                                  [-136.121, -34.198],
                                  [-17.922, -71.132],
                                  [-113.426, 2.651],
                                  [141.462, 83.376],
                                  [-48.931, 153.846],
                                  [-8.279, 92],
                                ],
                                o: [
                                  [-99.535, 50.977],
                                  [34.504, 134.307],
                                  [-58.946, 37.038],
                                  [136.121, 34.198],
                                  [18.124, 71.38],
                                  [111.845, -2.31],
                                  [-141.462, -83.376],
                                  [48.636, -154.109],
                                  [8.279, -92],
                                ],
                                v: [
                                  [-113.324, -376.936],
                                  [-166.734, -170.618],
                                  [-247.214, 33.047],
                                  [-271.482, 159.632],
                                  [-45.871, 285.107],
                                  [116.991, 426.074],
                                  [291.841, 286.121],
                                  [42.355, -100.907],
                                  [317.154, -251.999],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.638, -50.724],
                                  [-34.46, -134.257],
                                  [58.915, -37.069],
                                  [-136.065, -34.203],
                                  [-17.92, -71.107],
                                  [-113.468, 2.67],
                                  [141.387, 83.358],
                                  [-48.899, 153.782],
                                  [-8.287, 92],
                                ],
                                o: [
                                  [-99.521, 50.947],
                                  [34.46, 134.257],
                                  [-58.915, 37.069],
                                  [136.065, 34.203],
                                  [18.128, 71.362],
                                  [111.84, -2.319],
                                  [-141.387, -83.358],
                                  [48.596, -154.053],
                                  [8.287, -92],
                                ],
                                v: [
                                  [-113.061, -377.327],
                                  [-167.185, -171.207],
                                  [-246.92, 33.042],
                                  [-272.375, 159.378],
                                  [-45.141, 285.348],
                                  [117.659, 426.258],
                                  [291.75, 285.982],
                                  [42.717, -101.739],
                                  [316.269, -252.164],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.657, -50.686],
                                  [-34.415, -134.206],
                                  [58.883, -37.1],
                                  [-136.009, -34.209],
                                  [-17.918, -71.08],
                                  [-113.511, 2.69],
                                  [141.31, 83.339],
                                  [-48.867, 153.717],
                                  [-8.296, 92],
                                ],
                                o: [
                                  [-99.507, 50.916],
                                  [34.415, 134.206],
                                  [-58.883, 37.1],
                                  [136.009, 34.209],
                                  [18.131, 71.343],
                                  [111.836, -2.328],
                                  [-141.311, -83.339],
                                  [48.555, -153.996],
                                  [8.296, -92],
                                ],
                                v: [
                                  [-112.86, -377.778],
                                  [-167.634, -171.797],
                                  [-246.565, 33.097],
                                  [-273.329, 159.063],
                                  [-44.413, 285.648],
                                  [118.327, 426.501],
                                  [291.659, 285.905],
                                  [42.959, -102.63],
                                  [315.442, -252.387],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.676, -50.648],
                                  [-34.369, -134.153],
                                  [58.85, -37.133],
                                  [-135.95, -34.216],
                                  [-17.915, -71.053],
                                  [-113.555, 2.71],
                                  [141.232, 83.32],
                                  [-48.834, 153.65],
                                  [-8.304, 92],
                                ],
                                o: [
                                  [-99.493, 50.884],
                                  [34.369, 134.153],
                                  [-58.85, 37.133],
                                  [135.95, 34.216],
                                  [18.135, 71.324],
                                  [111.831, -2.338],
                                  [-141.232, -83.32],
                                  [48.512, -153.938],
                                  [8.304, -92],
                                ],
                                v: [
                                  [-112.656, -378.228],
                                  [-168.086, -172.386],
                                  [-246.213, 33.211],
                                  [-274.22, 158.749],
                                  [-43.69, 285.886],
                                  [118.993, 426.681],
                                  [291.632, 285.766],
                                  [43.266, -103.518],
                                  [314.554, -252.546],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.696, -50.608],
                                  [-34.321, -134.099],
                                  [58.817, -37.166],
                                  [-135.89, -34.222],
                                  [-17.913, -71.026],
                                  [-113.601, 2.731],
                                  [141.151, 83.3],
                                  [-48.799, 153.581],
                                  [-8.313, 92],
                                ],
                                o: [
                                  [-99.478, 50.852],
                                  [34.321, 134.099],
                                  [-58.817, 37.166],
                                  [135.89, 34.222],
                                  [18.139, 71.304],
                                  [111.826, -2.348],
                                  [-141.151, -83.3],
                                  [48.469, -153.877],
                                  [8.313, -92],
                                ],
                                v: [
                                  [-112.511, -378.739],
                                  [-168.476, -173.038],
                                  [-245.859, 33.324],
                                  [-275.108, 158.435],
                                  [-42.907, 286.247],
                                  [119.657, 426.858],
                                  [291.544, 285.689],
                                  [43.513, -104.464],
                                  [313.666, -252.703],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.716, -50.567],
                                  [-34.272, -134.044],
                                  [58.782, -37.2],
                                  [-135.829, -34.228],
                                  [-17.91, -70.997],
                                  [-113.647, 2.752],
                                  [141.068, 83.279],
                                  [-48.764, 153.511],
                                  [-8.322, 92],
                                ],
                                o: [
                                  [-99.463, 50.818],
                                  [34.272, 134.044],
                                  [-58.782, 37.2],
                                  [135.829, 34.228],
                                  [18.143, 71.284],
                                  [111.821, -2.358],
                                  [-141.068, -83.279],
                                  [48.424, -153.815],
                                  [8.322, -92],
                                ],
                                v: [
                                  [-112.426, -379.248],
                                  [-168.865, -173.63],
                                  [-245.569, 33.434],
                                  [-275.995, 158.06],
                                  [-42.067, 286.605],
                                  [120.258, 427.033],
                                  [291.519, 285.551],
                                  [43.702, -105.407],
                                  [312.776, -252.918],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.737, -50.526],
                                  [-34.222, -133.987],
                                  [58.747, -37.235],
                                  [-135.766, -34.235],
                                  [-17.908, -70.968],
                                  [-113.695, 2.774],
                                  [140.983, 83.258],
                                  [-48.729, 153.439],
                                  [-8.332, 92],
                                ],
                                o: [
                                  [-99.447, 50.784],
                                  [34.222, 133.987],
                                  [-58.747, 37.235],
                                  [135.766, 34.235],
                                  [18.147, 71.263],
                                  [111.816, -2.369],
                                  [-140.983, -83.258],
                                  [48.378, -153.752],
                                  [8.332, -92],
                                ],
                                v: [
                                  [-112.338, -379.818],
                                  [-169.257, -174.282],
                                  [-245.216, 33.541],
                                  [-276.881, 157.687],
                                  [-41.229, 286.962],
                                  [120.797, 427.146],
                                  [291.558, 285.474],
                                  [43.894, -106.347],
                                  [311.885, -253.068],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.758, -50.483],
                                  [-34.17, -133.928],
                                  [58.711, -37.27],
                                  [-135.701, -34.242],
                                  [-17.905, -70.938],
                                  [-113.744, 2.796],
                                  [140.895, 83.237],
                                  [-48.692, 153.364],
                                  [-8.341, 92],
                                ],
                                o: [
                                  [-99.431, 50.749],
                                  [34.17, 133.928],
                                  [-58.711, 37.27],
                                  [135.701, 34.242],
                                  [18.152, 71.242],
                                  [111.81, -2.379],
                                  [-140.895, -83.237],
                                  [48.331, -153.687],
                                  [8.341, -92],
                                ],
                                v: [
                                  [-112.25, -380.385],
                                  [-169.587, -174.875],
                                  [-244.925, 33.708],
                                  [-277.763, 157.254],
                                  [-40.334, 287.379],
                                  [121.396, 427.256],
                                  [291.536, 285.336],
                                  [44.027, -107.346],
                                  [311.055, -253.214],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.78, -50.439],
                                  [-34.117, -133.869],
                                  [58.673, -37.307],
                                  [-135.634, -34.249],
                                  [-17.902, -70.907],
                                  [-113.795, 2.819],
                                  [140.805, 83.215],
                                  [-48.654, 153.288],
                                  [-8.351, 92],
                                ],
                                o: [
                                  [-99.415, 50.712],
                                  [34.117, 133.869],
                                  [-58.673, 37.307],
                                  [135.634, 34.249],
                                  [18.156, 71.22],
                                  [111.805, -2.39],
                                  [-140.805, -83.215],
                                  [48.283, -153.62],
                                  [8.351, -92],
                                ],
                                v: [
                                  [-112.16, -381.011],
                                  [-169.917, -175.529],
                                  [-244.574, 33.871],
                                  [-278.583, 156.822],
                                  [-39.443, 287.795],
                                  [121.931, 427.364],
                                  [291.515, 285.198],
                                  [44.164, -108.34],
                                  [310.161, -253.357],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.803, -50.394],
                                  [-34.063, -133.807],
                                  [58.635, -37.345],
                                  [-135.566, -34.256],
                                  [-17.9, -70.876],
                                  [-113.847, 2.843],
                                  [140.713, 83.192],
                                  [-48.615, 153.21],
                                  [-8.361, 92],
                                ],
                                o: [
                                  [-99.398, 50.675],
                                  [34.063, 133.807],
                                  [-58.635, 37.345],
                                  [135.566, 34.256],
                                  [18.161, 71.197],
                                  [111.799, -2.401],
                                  [-140.713, -83.192],
                                  [48.234, -153.551],
                                  [8.361, -92],
                                ],
                                v: [
                                  [-112.069, -381.633],
                                  [-170.249, -176.123],
                                  [-244.285, 34.033],
                                  [-279.402, 156.391],
                                  [-38.555, 288.209],
                                  [122.404, 427.409],
                                  [291.558, 285.122],
                                  [44.243, -109.331],
                                  [309.267, -253.559],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.826, -50.348],
                                  [-34.007, -133.744],
                                  [58.596, -37.384],
                                  [-135.496, -34.263],
                                  [-17.897, -70.844],
                                  [-113.9, 2.867],
                                  [140.619, 83.169],
                                  [-48.575, 153.13],
                                  [-8.372, 92],
                                ],
                                o: [
                                  [-99.381, 50.637],
                                  [34.007, 133.744],
                                  [-58.596, 37.384],
                                  [135.496, 34.263],
                                  [18.165, 71.174],
                                  [111.794, -2.413],
                                  [-140.619, -83.169],
                                  [48.183, -153.481],
                                  [8.372, -92],
                                ],
                                v: [
                                  [-111.977, -382.314],
                                  [-170.581, -176.778],
                                  [-243.995, 34.19],
                                  [-280.156, 155.961],
                                  [-37.611, 288.683],
                                  [122.936, 427.451],
                                  [291.602, 284.984],
                                  [44.265, -110.379],
                                  [308.373, -253.696],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.85, -50.301],
                                  [-33.95, -133.679],
                                  [58.556, -37.423],
                                  [-135.424, -34.271],
                                  [-17.894, -70.81],
                                  [-113.954, 2.892],
                                  [140.522, 83.145],
                                  [-48.534, 153.047],
                                  [-8.382, 92],
                                ],
                                o: [
                                  [-99.363, 50.598],
                                  [33.95, 133.679],
                                  [-58.556, 37.423],
                                  [135.424, 34.271],
                                  [18.17, 71.15],
                                  [111.788, -2.425],
                                  [-140.522, -83.145],
                                  [48.131, -153.408],
                                  [8.382, -92],
                                ],
                                v: [
                                  [-111.945, -383.054],
                                  [-170.912, -177.374],
                                  [-243.767, 34.406],
                                  [-280.911, 155.473],
                                  [-36.67, 289.154],
                                  [123.408, 427.492],
                                  [291.71, 284.846],
                                  [44.35, -111.422],
                                  [307.477, -253.829],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.874, -50.252],
                                  [-33.891, -133.613],
                                  [58.514, -37.464],
                                  [-135.351, -34.279],
                                  [-17.891, -70.776],
                                  [-114.01, 2.918],
                                  [140.422, 83.121],
                                  [-48.492, 152.963],
                                  [-8.393, 92],
                                ],
                                o: [
                                  [-99.344, 50.558],
                                  [33.892, 133.613],
                                  [-58.514, 37.464],
                                  [135.351, 34.279],
                                  [18.175, 71.126],
                                  [111.781, -2.437],
                                  [-140.422, -83.121],
                                  [48.077, -153.334],
                                  [8.393, -92],
                                ],
                                v: [
                                  [-111.912, -383.789],
                                  [-171.184, -178.03],
                                  [-243.478, 34.621],
                                  [-281.661, 154.985],
                                  [-35.673, 289.624],
                                  [123.815, 427.529],
                                  [291.756, 284.71],
                                  [44.319, -112.461],
                                  [306.641, -253.898],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.899, -50.202],
                                  [-33.831, -133.545],
                                  [58.472, -37.506],
                                  [-135.275, -34.286],
                                  [-17.888, -70.741],
                                  [-114.068, 2.944],
                                  [140.32, 83.095],
                                  [-48.449, 152.876],
                                  [-8.404, 92],
                                ],
                                o: [
                                  [-99.326, 50.517],
                                  [33.831, 133.545],
                                  [-58.472, 37.506],
                                  [135.275, 34.287],
                                  [18.18, 71.101],
                                  [111.775, -2.449],
                                  [-140.32, -83.095],
                                  [48.022, -153.258],
                                  [8.404, -92],
                                ],
                                v: [
                                  [-111.878, -384.522],
                                  [-171.455, -178.627],
                                  [-243.251, 34.831],
                                  [-282.35, 154.499],
                                  [-34.679, 290.153],
                                  [124.283, 427.503],
                                  [291.866, 284.633],
                                  [44.29, -113.494],
                                  [305.743, -254.024],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.925, -50.151],
                                  [-33.77, -133.475],
                                  [58.428, -37.549],
                                  [-135.198, -34.295],
                                  [-17.884, -70.706],
                                  [-114.126, 2.971],
                                  [140.215, 83.07],
                                  [-48.405, 152.787],
                                  [-8.416, 92],
                                ],
                                o: [
                                  [-99.307, 50.475],
                                  [33.77, 133.475],
                                  [-58.428, 37.549],
                                  [135.198, 34.295],
                                  [18.185, 71.075],
                                  [111.769, -2.462],
                                  [-140.215, -83.07],
                                  [47.966, -153.18],
                                  [8.416, -92],
                                ],
                                v: [
                                  [-111.842, -385.314],
                                  [-171.667, -179.224],
                                  [-243.024, 35.039],
                                  [-283.036, 153.954],
                                  [-33.691, 290.618],
                                  [124.689, 427.475],
                                  [291.977, 284.496],
                                  [44.204, -114.585],
                                  [304.844, -254.147],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.951, -50.098],
                                  [-33.706, -133.403],
                                  [58.384, -37.593],
                                  [-135.118, -34.303],
                                  [-17.881, -70.669],
                                  [-114.187, 2.998],
                                  [140.108, 83.043],
                                  [-48.36, 152.696],
                                  [-8.428, 92],
                                ],
                                o: [
                                  [-99.287, 50.431],
                                  [33.706, 133.403],
                                  [-58.384, 37.593],
                                  [135.118, 34.303],
                                  [18.19, 71.049],
                                  [111.762, -2.475],
                                  [-140.108, -83.043],
                                  [47.908, -153.1],
                                  [8.428, -92],
                                ],
                                v: [
                                  [-111.867, -386.161],
                                  [-171.94, -179.822],
                                  [-242.796, 35.303],
                                  [-283.661, 153.471],
                                  [-32.706, 291.143],
                                  [125.095, 427.442],
                                  [292.151, 284.359],
                                  [44.124, -115.669],
                                  [304.004, -254.266],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.978, -50.045],
                                  [-33.641, -133.33],
                                  [58.338, -37.638],
                                  [-135.036, -34.312],
                                  [-17.878, -70.631],
                                  [-114.249, 3.027],
                                  [139.997, 83.016],
                                  [-48.313, 152.602],
                                  [-8.44, 92],
                                ],
                                o: [
                                  [-99.267, 50.387],
                                  [33.641, 133.33],
                                  [-58.338, 37.638],
                                  [135.036, 34.312],
                                  [18.196, 71.022],
                                  [111.756, -2.489],
                                  [-139.997, -83.016],
                                  [47.849, -153.018],
                                  [8.44, -92],
                                ],
                                v: [
                                  [-111.891, -387.005],
                                  [-172.152, -180.421],
                                  [-242.631, 35.566],
                                  [-284.283, 152.929],
                                  [-31.667, 291.664],
                                  [125.438, 427.41],
                                  [292.264, 284.162],
                                  [44.047, -116.749],
                                  [303.103, -254.32],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.005, -49.989],
                                  [-33.575, -133.254],
                                  [58.291, -37.684],
                                  [-134.953, -34.32],
                                  [-17.874, -70.593],
                                  [-114.312, 3.056],
                                  [139.884, 82.988],
                                  [-48.266, 152.506],
                                  [-8.452, 92],
                                ],
                                o: [
                                  [-99.246, 50.341],
                                  [33.575, 133.254],
                                  [-58.291, 37.684],
                                  [134.953, 34.32],
                                  [18.201, 70.995],
                                  [111.749, -2.503],
                                  [-139.884, -82.988],
                                  [47.788, -152.934],
                                  [8.452, -92],
                                ],
                                v: [
                                  [-111.914, -387.844],
                                  [-172.303, -181.02],
                                  [-242.465, 35.825],
                                  [-284.842, 152.389],
                                  [-30.632, 292.184],
                                  [125.78, 427.312],
                                  [292.441, 284.026],
                                  [43.853, -117.824],
                                  [302.261, -254.431],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.034, -49.933],
                                  [-33.506, -133.177],
                                  [58.243, -37.731],
                                  [-134.867, -34.329],
                                  [-17.871, -70.553],
                                  [-114.377, 3.085],
                                  [139.768, 82.96],
                                  [-48.217, 152.408],
                                  [-8.465, 92],
                                ],
                                o: [
                                  [-99.225, 50.294],
                                  [33.506, 133.177],
                                  [-58.243, 37.731],
                                  [134.867, 34.33],
                                  [18.207, 70.966],
                                  [111.742, -2.517],
                                  [-139.768, -82.96],
                                  [47.726, -152.847],
                                  [8.465, -92],
                                ],
                                v: [
                                  [-111.996, -388.679],
                                  [-172.519, -181.561],
                                  [-242.3, 36.08],
                                  [-285.401, 151.792],
                                  [-29.664, 292.701],
                                  [126.122, 427.213],
                                  [292.68, 283.889],
                                  [43.724, -118.893],
                                  [301.417, -254.477],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.063, -49.875],
                                  [-33.436, -133.097],
                                  [58.193, -37.78],
                                  [-134.779, -34.339],
                                  [-17.867, -70.512],
                                  [-114.444, 3.116],
                                  [139.649, 82.931],
                                  [-48.167, 152.307],
                                  [-8.478, 92],
                                ],
                                o: [
                                  [-99.203, 50.246],
                                  [33.436, 133.097],
                                  [-58.193, 37.78],
                                  [134.779, 34.339],
                                  [18.213, 70.937],
                                  [111.734, -2.531],
                                  [-139.649, -82.931],
                                  [47.662, -152.759],
                                  [8.478, -92],
                                ],
                                v: [
                                  [-112.077, -389.57],
                                  [-172.673, -182.162],
                                  [-242.196, 36.393],
                                  [-285.896, 151.255],
                                  [-28.638, 293.275],
                                  [126.402, 427.11],
                                  [292.859, 283.753],
                                  [43.478, -120.018],
                                  [300.512, -254.519],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.093, -49.815],
                                  [-33.364, -133.016],
                                  [58.143, -37.83],
                                  [-134.688, -34.348],
                                  [-17.863, -70.47],
                                  [-114.513, 3.147],
                                  [139.528, 82.901],
                                  [-48.115, 152.203],
                                  [-8.492, 92],
                                ],
                                o: [
                                  [-99.181, 50.197],
                                  [33.364, 133.016],
                                  [-58.143, 37.83],
                                  [134.688, 34.348],
                                  [18.219, 70.907],
                                  [111.727, -2.546],
                                  [-139.527, -82.901],
                                  [47.596, -152.668],
                                  [8.492, -92],
                                ],
                                v: [
                                  [-112.156, -390.457],
                                  [-172.766, -182.706],
                                  [-242.092, 36.641],
                                  [-286.39, 150.723],
                                  [-27.62, 293.787],
                                  [126.742, 426.944],
                                  [293.1, 283.556],
                                  [43.297, -121.074],
                                  [299.667, -254.557],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.123, -49.754],
                                  [-33.291, -132.933],
                                  [58.091, -37.881],
                                  [-134.596, -34.358],
                                  [-17.86, -70.428],
                                  [-114.583, 3.179],
                                  [139.402, 82.87],
                                  [-48.063, 152.097],
                                  [-8.505, 92],
                                ],
                                o: [
                                  [-99.158, 50.147],
                                  [33.291, 132.933],
                                  [-58.091, 37.881],
                                  [134.596, 34.358],
                                  [18.225, 70.877],
                                  [111.719, -2.562],
                                  [-139.402, -82.87],
                                  [47.529, -152.575],
                                  [8.505, -92],
                                ],
                                v: [
                                  [-112.295, -391.401],
                                  [-172.923, -183.248],
                                  [-241.987, 36.948],
                                  [-286.822, 150.131],
                                  [-26.547, 294.357],
                                  [127.021, 426.777],
                                  [293.343, 283.419],
                                  [43.001, -122.186],
                                  [298.82, -254.592],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.154, -49.691],
                                  [-33.215, -132.847],
                                  [58.038, -37.934],
                                  [-134.501, -34.368],
                                  [-17.856, -70.384],
                                  [-114.655, 3.212],
                                  [139.274, 82.838],
                                  [-48.009, 151.988],
                                  [-8.52, 92],
                                ],
                                o: [
                                  [-99.134, 50.095],
                                  [33.215, 132.847],
                                  [-58.037, 37.934],
                                  [134.501, 34.368],
                                  [18.231, 70.846],
                                  [111.711, -2.577],
                                  [-139.274, -82.838],
                                  [47.46, -152.479],
                                  [8.519, -92],
                                ],
                                v: [
                                  [-112.433, -392.277],
                                  [-173.019, -183.732],
                                  [-241.943, 37.249],
                                  [-287.191, 149.542],
                                  [-25.54, 294.862],
                                  [127.298, 426.607],
                                  [293.648, 283.223],
                                  [42.711, -123.231],
                                  [298.031, -254.623],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.187, -49.627],
                                  [-33.138, -132.759],
                                  [57.983, -37.987],
                                  [-134.403, -34.378],
                                  [-17.852, -70.339],
                                  [-114.729, 3.246],
                                  [139.143, 82.806],
                                  [-47.953, 151.877],
                                  [-8.534, 92],
                                ],
                                o: [
                                  [-99.11, 50.042],
                                  [33.137, 132.759],
                                  [-57.983, 37.987],
                                  [134.403, 34.378],
                                  [18.237, 70.813],
                                  [111.703, -2.593],
                                  [-139.143, -82.806],
                                  [47.39, -152.381],
                                  [8.534, -92],
                                ],
                                v: [
                                  [-112.63, -393.209],
                                  [-173.116, -184.219],
                                  [-241.9, 37.608],
                                  [-287.559, 148.956],
                                  [-24.539, 295.424],
                                  [127.514, 426.436],
                                  [293.953, 283.026],
                                  [42.424, -124.331],
                                  [297.182, -254.648],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.219, -49.561],
                                  [-33.058, -132.669],
                                  [57.927, -38.043],
                                  [-134.303, -34.389],
                                  [-17.848, -70.293],
                                  [-114.805, 3.28],
                                  [139.008, 82.773],
                                  [-47.896, 151.762],
                                  [-8.549, 92],
                                ],
                                o: [
                                  [-99.085, 49.988],
                                  [33.058, 132.669],
                                  [-57.927, 38.043],
                                  [134.303, 34.389],
                                  [18.244, 70.781],
                                  [111.695, -2.61],
                                  [-139.008, -82.773],
                                  [47.317, -152.281],
                                  [8.549, -92],
                                ],
                                v: [
                                  [-112.826, -394.135],
                                  [-173.153, -184.706],
                                  [-241.856, 37.903],
                                  [-287.863, 148.371],
                                  [-23.605, 295.922],
                                  [127.73, 426.261],
                                  [294.259, 282.83],
                                  [42.085, -125.424],
                                  [296.39, -254.671],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.253, -49.494],
                                  [-32.977, -132.577],
                                  [57.869, -38.099],
                                  [-134.201, -34.399],
                                  [-17.843, -70.245],
                                  [-114.882, 3.316],
                                  [138.87, 82.739],
                                  [-47.838, 151.645],
                                  [-8.564, 92],
                                ],
                                o: [
                                  [-99.06, 49.932],
                                  [32.977, 132.577],
                                  [-57.869, 38.099],
                                  [134.201, 34.399],
                                  [18.251, 70.747],
                                  [111.687, -2.627],
                                  [-138.87, -82.739],
                                  [47.243, -152.178],
                                  [8.564, -92],
                                ],
                                v: [
                                  [-113.083, -395.057],
                                  [-173.191, -185.195],
                                  [-241.874, 38.195],
                                  [-288.107, 147.852],
                                  [-22.616, 296.479],
                                  [127.945, 426.023],
                                  [294.568, 282.634],
                                  [41.689, -126.509],
                                  [295.537, -254.628],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.288, -49.424],
                                  [-32.893, -132.483],
                                  [57.811, -38.157],
                                  [-134.096, -34.41],
                                  [-17.839, -70.197],
                                  [-114.962, 3.352],
                                  [138.728, 82.704],
                                  [-47.778, 151.525],
                                  [-8.58, 92],
                                ],
                                o: [
                                  [-99.034, 49.875],
                                  [32.893, 132.483],
                                  [-57.811, 38.157],
                                  [134.096, 34.411],
                                  [18.258, 70.712],
                                  [111.678, -2.644],
                                  [-138.728, -82.704],
                                  [47.167, -152.072],
                                  [8.58, -92],
                                ],
                                v: [
                                  [-113.335, -395.971],
                                  [-173.231, -185.625],
                                  [-241.95, 38.541],
                                  [-288.349, 147.272],
                                  [-21.634, 296.97],
                                  [128.159, 425.786],
                                  [294.938, 282.438],
                                  [41.301, -127.587],
                                  [294.742, -254.58],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.323, -49.353],
                                  [-32.807, -132.386],
                                  [57.75, -38.217],
                                  [-133.989, -34.422],
                                  [-17.835, -70.147],
                                  [-115.044, 3.39],
                                  [138.583, 82.668],
                                  [-47.717, 151.401],
                                  [-8.595, 92],
                                ],
                                o: [
                                  [-99.008, 49.816],
                                  [32.807, 132.386],
                                  [-57.75, 38.217],
                                  [133.989, 34.422],
                                  [18.265, 70.677],
                                  [111.669, -2.662],
                                  [-138.583, -82.668],
                                  [47.089, -151.964],
                                  [8.596, -92],
                                ],
                                v: [
                                  [-113.586, -396.942],
                                  [-173.212, -186.058],
                                  [-241.965, 38.885],
                                  [-288.531, 146.636],
                                  [-20.719, 297.517],
                                  [128.373, 425.546],
                                  [295.307, 282.24],
                                  [40.917, -128.597],
                                  [294.007, -254.589],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.36, -49.281],
                                  [-32.72, -132.286],
                                  [57.689, -38.278],
                                  [-133.878, -34.433],
                                  [-17.83, -70.096],
                                  [-115.127, 3.428],
                                  [138.434, 82.632],
                                  [-47.655, 151.275],
                                  [-8.612, 92],
                                ],
                                o: [
                                  [-98.98, 49.757],
                                  [32.72, 132.286],
                                  [-57.689, 38.277],
                                  [133.878, 34.433],
                                  [18.272, 70.64],
                                  [111.66, -2.68],
                                  [-138.434, -82.632],
                                  [47.009, -151.853],
                                  [8.612, -92],
                                ],
                                v: [
                                  [-113.897, -397.844],
                                  [-173.193, -186.49],
                                  [-242.104, 39.166],
                                  [-288.651, 146.064],
                                  [-19.811, 298.003],
                                  [128.526, 425.303],
                                  [295.739, 282.044],
                                  [40.48, -129.659],
                                  [293.208, -254.534],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.397, -49.206],
                                  [-32.63, -132.185],
                                  [57.625, -38.34],
                                  [-133.765, -34.445],
                                  [-17.825, -70.044],
                                  [-115.213, 3.467],
                                  [138.281, 82.594],
                                  [-47.59, 151.146],
                                  [-8.629, 92],
                                ],
                                o: [
                                  [-98.952, 49.695],
                                  [32.63, 132.185],
                                  [-57.625, 38.34],
                                  [133.765, 34.445],
                                  [18.279, 70.603],
                                  [111.651, -2.698],
                                  [-138.281, -82.594],
                                  [46.927, -151.739],
                                  [8.629, -92],
                                ],
                                v: [
                                  [-114.266, -398.741],
                                  [-173.173, -186.866],
                                  [-242.24, 39.5],
                                  [-288.708, 145.497],
                                  [-18.909, 298.481],
                                  [128.68, 425.059],
                                  [296.172, 281.787],
                                  [39.991, -130.716],
                                  [292.467, -254.475],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.435, -49.13],
                                  [-32.538, -132.08],
                                  [57.56, -38.404],
                                  [-133.65, -34.457],
                                  [-17.821, -69.991],
                                  [-115.301, 3.507],
                                  [138.125, 82.556],
                                  [-47.524, 151.013],
                                  [-8.646, 92],
                                ],
                                o: [
                                  [-98.924, 49.632],
                                  [32.538, 132.08],
                                  [-57.56, 38.404],
                                  [133.65, 34.457],
                                  [18.287, 70.565],
                                  [111.641, -2.718],
                                  [-138.125, -82.556],
                                  [46.843, -151.623],
                                  [8.646, -92],
                                ],
                                v: [
                                  [-114.572, -399.691],
                                  [-173.155, -187.185],
                                  [-242.373, 39.832],
                                  [-288.764, 144.93],
                                  [-18.075, 298.959],
                                  [128.833, 424.754],
                                  [296.604, 281.53],
                                  [39.507, -131.763],
                                  [291.725, -254.349],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.474, -49.051],
                                  [-32.443, -131.973],
                                  [57.494, -38.469],
                                  [-133.531, -34.47],
                                  [-17.816, -69.936],
                                  [-115.391, 3.548],
                                  [137.964, 82.516],
                                  [-47.457, 150.877],
                                  [-8.663, 92],
                                ],
                                o: [
                                  [-98.894, 49.567],
                                  [32.443, 131.973],
                                  [-57.494, 38.469],
                                  [133.531, 34.47],
                                  [18.295, 70.526],
                                  [111.631, -2.737],
                                  [-137.964, -82.516],
                                  [46.757, -151.503],
                                  [8.663, -92],
                                ],
                                v: [
                                  [-114.998, -400.574],
                                  [-173.079, -187.564],
                                  [-242.569, 40.099],
                                  [-288.761, 144.366],
                                  [-17.249, 299.43],
                                  [128.984, 424.506],
                                  [297.098, 281.332],
                                  [39.03, -132.739],
                                  [291.041, -254.281],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.514, -48.971],
                                  [-32.346, -131.864],
                                  [57.425, -38.537],
                                  [-133.409, -34.483],
                                  [-17.811, -69.88],
                                  [-115.483, 3.59],
                                  [137.8, 82.476],
                                  [-47.388, 150.737],
                                  [-8.682, 92],
                                ],
                                o: [
                                  [-98.864, 49.501],
                                  [32.346, 131.864],
                                  [-57.425, 38.537],
                                  [133.409, 34.483],
                                  [18.303, 70.486],
                                  [111.621, -2.757],
                                  [-137.8, -82.476],
                                  [46.668, -151.381],
                                  [8.681, -92],
                                ],
                                v: [
                                  [-115.419, -401.449],
                                  [-172.943, -187.887],
                                  [-242.764, 40.363],
                                  [-288.696, 143.808],
                                  [-16.429, 299.898],
                                  [129.137, 424.198],
                                  [297.593, 281.076],
                                  [38.501, -133.77],
                                  [290.353, -254.209],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.556, -48.889],
                                  [-32.247, -131.751],
                                  [57.356, -38.606],
                                  [-133.285, -34.496],
                                  [-17.806, -69.822],
                                  [-115.578, 3.633],
                                  [137.632, 82.435],
                                  [-47.317, 150.594],
                                  [-8.7, 92],
                                ],
                                o: [
                                  [-98.833, 49.433],
                                  [32.247, 131.751],
                                  [-57.356, 38.606],
                                  [133.285, 34.496],
                                  [18.311, 70.444],
                                  [111.611, -2.778],
                                  [-137.632, -82.435],
                                  [46.578, -151.256],
                                  [8.7, -92],
                                ],
                                v: [
                                  [-115.842, -402.318],
                                  [-172.868, -188.154],
                                  [-243.017, 40.682],
                                  [-288.569, 143.251],
                                  [-15.68, 300.363],
                                  [129.228, 423.889],
                                  [298.086, 280.759],
                                  [37.921, -134.73],
                                  [289.664, -254.073],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.598, -48.805],
                                  [-32.146, -131.636],
                                  [57.284, -38.676],
                                  [-133.157, -34.509],
                                  [-17.8, -69.763],
                                  [-115.675, 3.678],
                                  [137.459, 82.392],
                                  [-47.244, 150.448],
                                  [-8.719, 92],
                                ],
                                o: [
                                  [-98.802, 49.364],
                                  [32.146, 131.636],
                                  [-57.284, 38.676],
                                  [133.157, 34.509],
                                  [18.32, 70.402],
                                  [111.601, -2.799],
                                  [-137.46, -82.392],
                                  [46.485, -151.127],
                                  [8.719, -92],
                                ],
                                v: [
                                  [-116.319, -403.178],
                                  [-172.734, -188.421],
                                  [-243.328, 40.936],
                                  [-288.444, 142.757],
                                  [-14.936, 300.821],
                                  [129.322, 423.58],
                                  [298.641, 280.502],
                                  [37.407, -135.681],
                                  [289.032, -253.931],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.641, -48.718],
                                  [-32.042, -131.518],
                                  [57.211, -38.748],
                                  [-133.026, -34.523],
                                  [-17.795, -69.703],
                                  [-115.774, 3.723],
                                  [137.283, 82.349],
                                  [-47.17, 150.298],
                                  [-8.738, 92],
                                ],
                                o: [
                                  [-98.769, 49.293],
                                  [32.042, 131.518],
                                  [-57.211, 38.748],
                                  [133.026, 34.523],
                                  [18.328, 70.359],
                                  [111.59, -2.82],
                                  [-137.283, -82.349],
                                  [46.39, -150.995],
                                  [8.738, -92],
                                ],
                                v: [
                                  [-116.793, -404.031],
                                  [-172.541, -188.693],
                                  [-243.642, 41.188],
                                  [-288.255, 142.209],
                                  [-14.26, 301.215],
                                  [129.474, 423.268],
                                  [299.195, 280.243],
                                  [36.782, -136.622],
                                  [288.396, -253.784],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.685, -48.63],
                                  [-31.935, -131.398],
                                  [57.135, -38.822],
                                  [-132.892, -34.537],
                                  [-17.789, -69.641],
                                  [-115.876, 3.769],
                                  [137.102, 82.304],
                                  [-47.093, 150.144],
                                  [-8.758, 92],
                                ],
                                o: [
                                  [-98.736, 49.22],
                                  [31.935, 131.398],
                                  [-57.135, 38.822],
                                  [132.892, 34.537],
                                  [18.337, 70.315],
                                  [111.579, -2.843],
                                  [-137.102, -82.304],
                                  [46.293, -150.861],
                                  [8.758, -92],
                                ],
                                v: [
                                  [-117.327, -404.877],
                                  [-172.408, -188.907],
                                  [-244.012, 41.374],
                                  [-288.008, 141.662],
                                  [-13.652, 301.605],
                                  [129.565, 422.899],
                                  [299.809, 279.927],
                                  [36.227, -137.553],
                                  [287.757, -253.634],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.73, -48.539],
                                  [-31.826, -131.274],
                                  [57.058, -38.898],
                                  [-132.755, -34.552],
                                  [-17.784, -69.578],
                                  [-115.98, 3.817],
                                  [136.916, 82.259],
                                  [-47.015, 149.987],
                                  [-8.779, 92],
                                ],
                                o: [
                                  [-98.702, 49.145],
                                  [31.826, 131.274],
                                  [-57.058, 38.898],
                                  [132.755, 34.552],
                                  [18.346, 70.27],
                                  [111.567, -2.865],
                                  [-136.916, -82.259],
                                  [46.193, -150.723],
                                  [8.779, -92],
                                ],
                                v: [
                                  [-117.855, -405.653],
                                  [-172.217, -189.126],
                                  [-244.38, 41.559],
                                  [-287.703, 141.178],
                                  [-13.052, 301.991],
                                  [129.658, 422.587],
                                  [300.422, 279.607],
                                  [35.619, -138.475],
                                  [287.117, -253.478],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.777, -48.447],
                                  [-31.714, -131.147],
                                  [56.98, -38.976],
                                  [-132.614, -34.566],
                                  [-17.778, -69.513],
                                  [-116.086, 3.866],
                                  [136.727, 82.212],
                                  [-46.935, 149.826],
                                  [-8.8, 92],
                                ],
                                o: [
                                  [-98.667, 49.068],
                                  [31.714, 131.147],
                                  [-56.98, 38.976],
                                  [132.614, 34.566],
                                  [18.355, 70.223],
                                  [111.556, -2.888],
                                  [-136.726, -82.212],
                                  [46.091, -150.581],
                                  [8.8, -92],
                                ],
                                v: [
                                  [-118.442, -406.422],
                                  [-172.026, -189.286],
                                  [-244.808, 41.737],
                                  [-287.335, 140.638],
                                  [-12.523, 302.37],
                                  [129.751, 422.213],
                                  [301.035, 279.29],
                                  [34.963, -139.384],
                                  [286.471, -253.32],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.824, -48.351],
                                  [-31.599, -131.017],
                                  [56.899, -39.056],
                                  [-132.47, -34.581],
                                  [-17.772, -69.446],
                                  [-116.196, 3.916],
                                  [136.532, 82.164],
                                  [-46.853, 149.661],
                                  [-8.821, 92],
                                ],
                                o: [
                                  [-98.632, 48.99],
                                  [31.599, 131.018],
                                  [-56.899, 39.056],
                                  [132.47, 34.581],
                                  [18.365, 70.176],
                                  [111.544, -2.912],
                                  [-136.532, -82.164],
                                  [45.987, -150.436],
                                  [8.821, -92],
                                ],
                                v: [
                                  [-119.025, -407.123],
                                  [-171.777, -189.451],
                                  [-245.29, 41.911],
                                  [-286.91, 140.162],
                                  [-12.059, 302.687],
                                  [129.842, 421.902],
                                  [301.648, 278.911],
                                  [34.313, -140.224],
                                  [285.82, -253.156],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.873, -48.254],
                                  [-31.482, -130.885],
                                  [56.816, -39.137],
                                  [-132.323, -34.597],
                                  [-17.766, -69.378],
                                  [-116.308, 3.967],
                                  [136.333, 82.115],
                                  [-46.769, 149.492],
                                  [-8.843, 92],
                                ],
                                o: [
                                  [-98.595, 48.91],
                                  [31.482, 130.885],
                                  [-56.816, 39.137],
                                  [132.323, 34.597],
                                  [18.375, 70.127],
                                  [111.532, -2.936],
                                  [-136.333, -82.115],
                                  [45.88, -150.288],
                                  [8.843, -92],
                                ],
                                v: [
                                  [-119.661, -407.874],
                                  [-171.528, -189.563],
                                  [-245.774, 42.023],
                                  [-286.484, 139.687],
                                  [-11.663, 302.997],
                                  [129.876, 421.532],
                                  [302.2, 278.473],
                                  [33.676, -141.051],
                                  [285.229, -252.987],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.923, -48.154],
                                  [-31.361, -130.748],
                                  [56.731, -39.221],
                                  [-132.172, -34.613],
                                  [-17.76, -69.308],
                                  [-116.422, 4.019],
                                  [136.129, 82.065],
                                  [-46.683, 149.318],
                                  [-8.865, 92],
                                ],
                                o: [
                                  [-98.558, 48.827],
                                  [31.361, 130.748],
                                  [-56.731, 39.221],
                                  [132.172, 34.613],
                                  [18.385, 70.077],
                                  [111.519, -2.961],
                                  [-136.128, -82.065],
                                  [45.77, -150.135],
                                  [8.865, -92],
                                ],
                                v: [
                                  [-120.297, -408.497],
                                  [-171.278, -189.675],
                                  [-246.316, 42.073],
                                  [-285.999, 139.216],
                                  [-11.275, 303.303],
                                  [129.973, 421.159],
                                  [302.753, 278.033],
                                  [33.049, -141.867],
                                  [284.573, -252.755],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.974, -48.052],
                                  [-31.238, -130.609],
                                  [56.644, -39.306],
                                  [-132.017, -34.629],
                                  [-17.753, -69.237],
                                  [-116.54, 4.073],
                                  [135.92, 82.014],
                                  [-46.595, 149.141],
                                  [-8.888, 92],
                                ],
                                o: [
                                  [-98.52, 48.743],
                                  [31.238, 130.609],
                                  [-56.644, 39.306],
                                  [132.017, 34.629],
                                  [18.395, 70.026],
                                  [111.507, -2.987],
                                  [-135.919, -82.014],
                                  [45.657, -149.98],
                                  [8.888, -92],
                                ],
                                v: [
                                  [-120.928, -409.171],
                                  [-170.971, -189.734],
                                  [-246.912, 42.114],
                                  [-285.457, 138.749],
                                  [-10.956, 303.603],
                                  [130.068, 420.848],
                                  [303.243, 277.538],
                                  [32.372, -142.669],
                                  [283.911, -252.578],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.026, -47.948],
                                  [-31.112, -130.466],
                                  [56.556, -39.394],
                                  [-131.858, -34.646],
                                  [-17.747, -69.163],
                                  [-116.66, 4.127],
                                  [135.705, 81.961],
                                  [-46.505, 148.959],
                                  [-8.912, 92],
                                ],
                                o: [
                                  [-98.48, 48.657],
                                  [31.112, 130.466],
                                  [-56.556, 39.394],
                                  [131.858, 34.646],
                                  [18.405, 69.974],
                                  [111.494, -3.013],
                                  [-135.705, -81.961],
                                  [45.542, -149.82],
                                  [8.912, -92],
                                ],
                                v: [
                                  [-121.612, -409.718],
                                  [-170.723, -189.797],
                                  [-247.509, 42.098],
                                  [-284.853, 138.346],
                                  [-10.645, 303.899],
                                  [130.104, 420.478],
                                  [303.675, 276.978],
                                  [31.647, -143.401],
                                  [283.246, -252.338],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.08, -47.841],
                                  [-30.983, -130.32],
                                  [56.465, -39.484],
                                  [-131.696, -34.663],
                                  [-17.74, -69.088],
                                  [-116.783, 4.184],
                                  [135.486, 81.907],
                                  [-46.413, 148.773],
                                  [-8.936, 92],
                                ],
                                o: [
                                  [-98.44, 48.568],
                                  [30.983, 130.32],
                                  [-56.464, 39.483],
                                  [131.696, 34.663],
                                  [18.416, 69.92],
                                  [111.48, -3.04],
                                  [-135.486, -81.907],
                                  [45.425, -149.657],
                                  [8.936, -92],
                                ],
                                v: [
                                  [-122.295, -410.257],
                                  [-170.415, -189.868],
                                  [-248.163, 42.017],
                                  [-284.193, 137.884],
                                  [-10.344, 304.189],
                                  [130.202, 420.109],
                                  [304.105, 276.423],
                                  [30.993, -144.178],
                                  [282.634, -252.096],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.135, -47.731],
                                  [-30.85, -130.17],
                                  [56.371, -39.575],
                                  [-131.53, -34.68],
                                  [-17.733, -69.012],
                                  [-116.909, 4.241],
                                  [135.262, 81.852],
                                  [-46.318, 148.582],
                                  [-8.961, 92],
                                ],
                                o: [
                                  [-98.399, 48.478],
                                  [30.85, 130.17],
                                  [-56.371, 39.575],
                                  [131.53, 34.68],
                                  [18.427, 69.865],
                                  [111.466, -3.067],
                                  [-135.262, -81.852],
                                  [45.304, -149.49],
                                  [8.961, -92],
                                ],
                                v: [
                                  [-123.03, -410.784],
                                  [-170.049, -189.821],
                                  [-248.811, 41.873],
                                  [-283.535, 137.482],
                                  [-10.11, 304.475],
                                  [130.24, 419.742],
                                  [304.532, 275.806],
                                  [30.293, -144.824],
                                  [281.96, -251.844],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.191, -47.619],
                                  [-30.715, -130.017],
                                  [56.276, -39.669],
                                  [-131.359, -34.698],
                                  [-17.726, -68.933],
                                  [-117.039, 4.3],
                                  [135.032, 81.796],
                                  [-46.221, 148.387],
                                  [-8.986, 92],
                                ],
                                o: [
                                  [-98.357, 48.385],
                                  [30.715, 130.017],
                                  [-56.276, 39.669],
                                  [131.359, 34.698],
                                  [18.438, 69.809],
                                  [111.452, -3.095],
                                  [-135.032, -81.796],
                                  [45.18, -149.318],
                                  [8.986, -92],
                                ],
                                v: [
                                  [-123.7, -411.242],
                                  [-169.742, -189.84],
                                  [-249.516, 41.727],
                                  [-282.818, 137.087],
                                  [-9.887, 304.754],
                                  [130.337, 419.377],
                                  [304.844, 275.128],
                                  [29.606, -145.514],
                                  [281.342, -251.591],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.248, -47.504],
                                  [-30.576, -129.86],
                                  [56.178, -39.766],
                                  [-131.185, -34.717],
                                  [-17.719, -68.853],
                                  [-117.171, 4.361],
                                  [134.796, 81.738],
                                  [-46.122, 148.188],
                                  [-9.012, 92],
                                ],
                                o: [
                                  [-98.314, 48.29],
                                  [30.576, 129.86],
                                  [-56.178, 39.766],
                                  [131.185, 34.717],
                                  [18.45, 69.752],
                                  [111.438, -3.124],
                                  [-134.796, -81.738],
                                  [45.054, -149.143],
                                  [9.012, -92],
                                ],
                                v: [
                                  [-124.366, -411.691],
                                  [-169.379, -189.806],
                                  [-250.221, 41.517],
                                  [-282.046, 136.749],
                                  [-9.731, 305.029],
                                  [130.379, 419.015],
                                  [305.21, 274.452],
                                  [28.93, -146.128],
                                  [280.658, -251.276],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.307, -47.386],
                                  [-30.434, -129.699],
                                  [56.078, -39.864],
                                  [-131.007, -34.735],
                                  [-17.712, -68.77],
                                  [-117.306, 4.422],
                                  [134.556, 81.679],
                                  [-46.02, 147.983],
                                  [-9.038, 92],
                                ],
                                o: [
                                  [-98.27, 48.193],
                                  [30.434, 129.699],
                                  [-56.078, 39.864],
                                  [131.007, 34.735],
                                  [18.461, 69.693],
                                  [111.423, -3.154],
                                  [-134.556, -81.679],
                                  [44.925, -148.964],
                                  [9.038, -92],
                                ],
                                v: [
                                  [-124.969, -412.076],
                                  [-169.014, -189.721],
                                  [-250.978, 41.248],
                                  [-281.214, 136.356],
                                  [-9.582, 305.297],
                                  [130.482, 418.651],
                                  [305.462, 273.777],
                                  [28.267, -146.73],
                                  [280.026, -251.014],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.367, -47.265],
                                  [-30.289, -129.534],
                                  [55.976, -39.965],
                                  [-130.824, -34.755],
                                  [-17.704, -68.686],
                                  [-117.445, 4.486],
                                  [134.309, 81.618],
                                  [-45.916, 147.774],
                                  [-9.065, 92],
                                ],
                                o: [
                                  [-98.225, 48.094],
                                  [30.289, 129.534],
                                  [-55.976, 39.965],
                                  [130.824, 34.755],
                                  [18.473, 69.633],
                                  [111.408, -3.184],
                                  [-134.309, -81.618],
                                  [44.792, -148.78],
                                  [9.065, -92],
                                ],
                                v: [
                                  [-125.622, -412.444],
                                  [-168.589, -189.638],
                                  [-251.677, 40.915],
                                  [-280.387, 136.023],
                                  [-9.446, 305.5],
                                  [130.583, 418.294],
                                  [305.707, 273.045],
                                  [27.621, -147.315],
                                  [279.391, -250.691],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.429, -47.142],
                                  [-30.14, -129.366],
                                  [55.871, -40.069],
                                  [-130.637, -34.774],
                                  [-17.696, -68.599],
                                  [-117.587, 4.55],
                                  [134.056, 81.556],
                                  [-45.81, 147.559],
                                  [-9.093, 92],
                                ],
                                o: [
                                  [-98.178, 47.992],
                                  [30.14, 129.366],
                                  [-55.871, 40.069],
                                  [130.637, 34.774],
                                  [18.486, 69.571],
                                  [111.393, -3.214],
                                  [-134.056, -81.556],
                                  [44.656, -148.592],
                                  [9.093, -92],
                                ],
                                v: [
                                  [-126.152, -412.746],
                                  [-168.226, -189.505],
                                  [-252.427, 40.523],
                                  [-279.503, 135.693],
                                  [-9.316, 305.757],
                                  [130.69, 417.935],
                                  [305.895, 272.252],
                                  [26.928, -147.882],
                                  [278.747, -250.363],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.492, -47.016],
                                  [-29.988, -129.193],
                                  [55.763, -40.174],
                                  [-130.446, -34.794],
                                  [-17.688, -68.511],
                                  [-117.732, 4.617],
                                  [133.798, 81.492],
                                  [-45.701, 147.34],
                                  [-9.121, 92],
                                ],
                                o: [
                                  [-98.131, 47.888],
                                  [29.988, 129.193],
                                  [-55.763, 40.174],
                                  [130.446, 34.794],
                                  [18.498, 69.508],
                                  [111.377, -3.246],
                                  [-133.798, -81.492],
                                  [44.517, -148.399],
                                  [9.121, -92],
                                ],
                                v: [
                                  [-126.678, -413.098],
                                  [-167.798, -189.379],
                                  [-253.233, 40.132],
                                  [-278.558, 135.423],
                                  [-9.259, 306.007],
                                  [130.794, 417.581],
                                  [306.083, 271.457],
                                  [26.309, -148.38],
                                  [278.1, -249.974],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.557, -46.887],
                                  [-29.832, -129.017],
                                  [55.654, -40.283],
                                  [-130.25, -34.815],
                                  [-17.68, -68.421],
                                  [-117.881, 4.685],
                                  [133.533, 81.427],
                                  [-45.59, 147.115],
                                  [-9.151, 92],
                                ],
                                o: [
                                  [-98.082, 47.781],
                                  [29.832, 129.017],
                                  [-55.654, 40.283],
                                  [130.25, 34.815],
                                  [18.511, 69.443],
                                  [111.361, -3.278],
                                  [-133.533, -81.427],
                                  [44.375, -148.202],
                                  [9.151, -92],
                                ],
                                v: [
                                  [-127.199, -413.322],
                                  [-167.377, -189.258],
                                  [-254.038, 39.678],
                                  [-277.562, 135.09],
                                  [-9.267, 306.196],
                                  [130.906, 417.225],
                                  [306.21, 270.609],
                                  [25.707, -148.855],
                                  [277.444, -249.579],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.623, -46.754],
                                  [-29.672, -128.836],
                                  [55.541, -40.393],
                                  [-130.049, -34.836],
                                  [-17.672, -68.328],
                                  [-118.033, 4.754],
                                  [133.263, 81.361],
                                  [-45.475, 146.885],
                                  [-9.18, 92],
                                ],
                                o: [
                                  [-98.033, 47.672],
                                  [29.672, 128.836],
                                  [-55.541, 40.393],
                                  [130.049, 34.836],
                                  [18.525, 69.377],
                                  [111.344, -3.311],
                                  [-133.263, -81.361],
                                  [44.23, -148],
                                  [9.18, -92],
                                ],
                                v: [
                                  [-127.653, -413.536],
                                  [-166.95, -189.086],
                                  [-254.777, 39.162],
                                  [-276.568, 134.881],
                                  [-9.231, 306.374],
                                  [131.014, 416.866],
                                  [306.337, 269.759],
                                  [25.12, -149.32],
                                  [276.842, -249.184],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.691, -46.619],
                                  [-29.509, -128.651],
                                  [55.426, -40.507],
                                  [-129.844, -34.858],
                                  [-17.664, -68.233],
                                  [-118.188, 4.825],
                                  [132.985, 81.293],
                                  [-45.359, 146.65],
                                  [-9.211, 92],
                                ],
                                o: [
                                  [-97.982, 47.561],
                                  [29.509, 128.651],
                                  [-55.426, 40.507],
                                  [129.844, 34.858],
                                  [18.538, 69.309],
                                  [111.327, -3.345],
                                  [-132.985, -81.293],
                                  [44.081, -147.794],
                                  [9.211, -92],
                                ],
                                v: [
                                  [-128.101, -413.74],
                                  [-166.467, -188.862],
                                  [-255.628, 38.647],
                                  [-275.523, 134.61],
                                  [-9.26, 306.608],
                                  [131.186, 416.51],
                                  [306.403, 268.908],
                                  [24.547, -149.707],
                                  [276.173, -248.782],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.76, -46.48],
                                  [-29.342, -128.462],
                                  [55.308, -40.623],
                                  [-129.634, -34.88],
                                  [-17.655, -68.136],
                                  [-118.348, 4.898],
                                  [132.702, 81.223],
                                  [-45.239, 146.409],
                                  [-9.242, 92],
                                ],
                                o: [
                                  [-97.93, 47.446],
                                  [29.342, 128.462],
                                  [-55.308, 40.623],
                                  [129.634, 34.88],
                                  [18.552, 69.24],
                                  [111.31, -3.38],
                                  [-132.702, -81.223],
                                  [43.928, -147.582],
                                  [9.242, -92],
                                ],
                                v: [
                                  [-128.54, -413.932],
                                  [-166.04, -188.707],
                                  [-256.42, 38.075],
                                  [-274.477, 134.4],
                                  [-9.358, 306.778],
                                  [131.357, 416.154],
                                  [306.411, 268],
                                  [23.994, -150.078],
                                  [275.558, -248.376],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.831, -46.338],
                                  [-29.171, -128.268],
                                  [55.188, -40.742],
                                  [-129.419, -34.902],
                                  [-17.646, -68.037],
                                  [-118.511, 4.972],
                                  [132.411, 81.151],
                                  [-45.117, 146.163],
                                  [-9.274, 92],
                                ],
                                o: [
                                  [-97.877, 47.329],
                                  [29.171, 128.268],
                                  [-55.188, 40.742],
                                  [129.419, 34.902],
                                  [18.566, 69.169],
                                  [111.292, -3.415],
                                  [-132.411, -81.152],
                                  [43.772, -147.366],
                                  [9.274, -92],
                                ],
                                v: [
                                  [-128.916, -413.998],
                                  [-165.615, -188.498],
                                  [-257.204, 37.5],
                                  [-273.439, 134.252],
                                  [-9.409, 306.94],
                                  [131.529, 415.798],
                                  [306.358, 267.036],
                                  [23.452, -150.428],
                                  [274.933, -247.914],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.903, -46.193],
                                  [-28.996, -128.07],
                                  [55.064, -40.863],
                                  [-129.199, -34.925],
                                  [-17.637, -67.935],
                                  [-118.678, 5.049],
                                  [132.114, 81.078],
                                  [-44.992, 145.911],
                                  [-9.307, 92],
                                ],
                                o: [
                                  [-97.822, 47.209],
                                  [28.996, 128.07],
                                  [-55.064, 40.863],
                                  [129.199, 34.925],
                                  [18.581, 69.097],
                                  [111.274, -3.452],
                                  [-132.114, -81.078],
                                  [43.613, -147.145],
                                  [9.307, -92],
                                ],
                                v: [
                                  [-129.228, -414.11],
                                  [-165.139, -188.238],
                                  [-257.987, 36.864],
                                  [-272.342, 134.102],
                                  [-9.528, 307.095],
                                  [131.706, 415.379],
                                  [306.306, 266.133],
                                  [22.931, -150.708],
                                  [274.302, -247.385],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.978, -46.045],
                                  [-28.817, -127.867],
                                  [54.938, -40.987],
                                  [-128.974, -34.949],
                                  [-17.628, -67.831],
                                  [-118.849, 5.126],
                                  [131.81, 81.004],
                                  [-44.864, 145.653],
                                  [-9.34, 92],
                                ],
                                o: [
                                  [-97.767, 47.087],
                                  [28.816, 127.867],
                                  [-54.938, 40.988],
                                  [128.974, 34.949],
                                  [18.596, 69.022],
                                  [111.256, -3.489],
                                  [-131.81, -81.004],
                                  [43.449, -146.918],
                                  [9.34, -92],
                                ],
                                v: [
                                  [-129.531, -414.158],
                                  [-164.719, -188.039],
                                  [-258.819, 36.173],
                                  [-271.196, 133.952],
                                  [-9.655, 307.19],
                                  [131.941, 415.022],
                                  [306.251, 265.169],
                                  [22.426, -150.967],
                                  [273.719, -246.913],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.054, -45.893],
                                  [-28.633, -127.66],
                                  [54.809, -41.115],
                                  [-128.744, -34.973],
                                  [-17.618, -67.725],
                                  [-119.023, 5.206],
                                  [131.499, 80.927],
                                  [-44.733, 145.389],
                                  [-9.374, 92],
                                ],
                                o: [
                                  [-97.71, 46.962],
                                  [28.633, 127.66],
                                  [-54.809, 41.115],
                                  [128.744, 34.973],
                                  [18.611, 68.946],
                                  [111.237, -3.527],
                                  [-131.499, -80.927],
                                  [43.282, -146.687],
                                  [9.374, -92],
                                ],
                                v: [
                                  [-129.823, -414.133],
                                  [-164.243, -187.795],
                                  [-259.588, 35.484],
                                  [-270.112, 133.862],
                                  [-9.855, 307.331],
                                  [132.119, 414.661],
                                  [306.08, 264.151],
                                  [22.001, -151.204],
                                  [273.077, -246.383],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.131, -45.737],
                                  [-28.446, -127.448],
                                  [54.677, -41.245],
                                  [-128.508, -34.998],
                                  [-17.609, -67.616],
                                  [-119.202, 5.288],
                                  [131.181, 80.849],
                                  [-44.598, 145.119],
                                  [-9.409, 92],
                                ],
                                o: [
                                  [-97.652, 46.833],
                                  [28.446, 127.448],
                                  [-54.677, 41.245],
                                  [128.508, 34.998],
                                  [18.626, 68.869],
                                  [111.217, -3.566],
                                  [-131.181, -80.849],
                                  [43.111, -146.45],
                                  [9.409, -92],
                                ],
                                v: [
                                  [-130.052, -414.1],
                                  [-163.833, -187.554],
                                  [-260.414, 34.736],
                                  [-268.923, 133.77],
                                  [-10.005, 307.474],
                                  [132.355, 414.304],
                                  [305.911, 263.188],
                                  [21.531, -151.37],
                                  [272.482, -245.854],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.211, -45.578],
                                  [-28.254, -127.23],
                                  [54.542, -41.378],
                                  [-128.267, -35.023],
                                  [-17.599, -67.505],
                                  [-119.385, 5.371],
                                  [130.856, 80.769],
                                  [-44.461, 144.842],
                                  [-9.445, 92],
                                ],
                                o: [
                                  [-97.592, 46.702],
                                  [28.254, 127.23],
                                  [-54.542, 41.378],
                                  [128.267, 35.023],
                                  [18.642, 68.789],
                                  [111.197, -3.605],
                                  [-130.856, -80.769],
                                  [42.936, -146.207],
                                  [9.445, -92],
                                ],
                                v: [
                                  [-130.275, -414.056],
                                  [-163.425, -187.317],
                                  [-261.176, 33.991],
                                  [-267.791, 133.681],
                                  [-10.229, 307.547],
                                  [132.649, 413.885],
                                  [305.739, 262.175],
                                  [21.141, -151.517],
                                  [271.88, -245.257],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.292, -45.416],
                                  [-28.058, -127.008],
                                  [54.403, -41.514],
                                  [-128.021, -35.049],
                                  [-17.588, -67.391],
                                  [-119.572, 5.457],
                                  [130.523, 80.687],
                                  [-44.321, 144.56],
                                  [-9.482, 92],
                                ],
                                o: [
                                  [-97.531, 46.568],
                                  [28.058, 127.008],
                                  [-54.403, 41.514],
                                  [128.021, 35.049],
                                  [18.658, 68.708],
                                  [111.177, -3.646],
                                  [-130.523, -80.687],
                                  [42.757, -145.959],
                                  [9.482, -92],
                                ],
                                v: [
                                  [-130.429, -413.943],
                                  [-163.014, -187.036],
                                  [-261.928, 33.245],
                                  [-266.608, 133.652],
                                  [-10.459, 307.675],
                                  [132.891, 413.524],
                                  [305.51, 261.16],
                                  [20.769, -151.641],
                                  [271.324, -244.666],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.375, -45.249],
                                  [-27.857, -126.781],
                                  [54.262, -41.654],
                                  [-127.768, -35.076],
                                  [-17.578, -67.274],
                                  [-119.763, 5.544],
                                  [130.182, 80.603],
                                  [-44.177, 144.27],
                                  [-9.519, 92],
                                ],
                                o: [
                                  [-97.468, 46.431],
                                  [27.857, 126.781],
                                  [-54.262, 41.654],
                                  [127.768, 35.076],
                                  [18.675, 68.625],
                                  [111.156, -3.688],
                                  [-130.182, -80.604],
                                  [42.574, -145.705],
                                  [9.519, -92],
                                ],
                                v: [
                                  [-130.573, -413.818],
                                  [-162.612, -186.754],
                                  [-262.737, 32.44],
                                  [-265.435, 133.615],
                                  [-10.754, 307.738],
                                  [133.187, 413.105],
                                  [305.22, 260.096],
                                  [20.416, -151.694],
                                  [270.709, -244.071],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.461, -45.079],
                                  [-27.651, -126.548],
                                  [54.117, -41.796],
                                  [-127.51, -35.103],
                                  [-17.567, -67.155],
                                  [-119.959, 5.634],
                                  [129.833, 80.518],
                                  [-44.031, 143.975],
                                  [-9.557, 92],
                                ],
                                o: [
                                  [-97.405, 46.29],
                                  [27.651, 126.548],
                                  [-54.117, 41.796],
                                  [127.51, 35.103],
                                  [18.692, 68.539],
                                  [111.135, -3.73],
                                  [-129.833, -80.518],
                                  [42.387, -145.445],
                                  [9.557, -92],
                                ],
                                v: [
                                  [-130.653, -413.623],
                                  [-162.212, -186.486],
                                  [-263.482, 31.583],
                                  [-264.263, 133.643],
                                  [-11.004, 307.794],
                                  [133.43, 412.739],
                                  [304.874, 259.087],
                                  [20.145, -151.723],
                                  [270.139, -243.414],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.548, -44.905],
                                  [-27.441, -126.311],
                                  [53.969, -41.942],
                                  [-127.246, -35.131],
                                  [-17.557, -67.033],
                                  [-120.16, 5.725],
                                  [129.477, 80.43],
                                  [-43.88, 143.672],
                                  [-9.596, 92],
                                ],
                                o: [
                                  [-97.339, 46.146],
                                  [27.441, 126.311],
                                  [-53.969, 41.942],
                                  [127.246, 35.131],
                                  [18.71, 68.452],
                                  [111.113, -3.774],
                                  [-129.477, -80.43],
                                  [42.195, -145.18],
                                  [9.596, -92],
                                ],
                                v: [
                                  [-130.721, -413.365],
                                  [-161.872, -186.221],
                                  [-264.221, 30.726],
                                  [-263.04, 133.667],
                                  [-11.324, 307.841],
                                  [133.726, 412.32],
                                  [304.53, 258.025],
                                  [19.888, -151.674],
                                  [269.561, -242.754],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.637, -44.727],
                                  [-27.226, -126.067],
                                  [53.818, -42.091],
                                  [-126.976, -35.159],
                                  [-17.545, -66.909],
                                  [-120.364, 5.818],
                                  [129.112, 80.341],
                                  [-43.727, 143.363],
                                  [-9.636, 92],
                                ],
                                o: [
                                  [-97.272, 45.999],
                                  [27.226, 126.067],
                                  [-53.818, 42.091],
                                  [126.976, 35.159],
                                  [18.727, 68.363],
                                  [111.091, -3.818],
                                  [-129.112, -80.341],
                                  [41.999, -144.908],
                                  [9.636, -92],
                                ],
                                v: [
                                  [-130.726, -413.152],
                                  [-161.478, -185.967],
                                  [-264.898, 29.874],
                                  [-261.886, 133.745],
                                  [-11.655, 307.886],
                                  [134.079, 411.954],
                                  [304.188, 256.966],
                                  [19.65, -151.611],
                                  [269.027, -242.095],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.728, -44.544],
                                  [-27.007, -125.818],
                                  [53.663, -42.244],
                                  [-126.7, -35.188],
                                  [-17.534, -66.781],
                                  [-120.574, 5.914],
                                  [128.739, 80.249],
                                  [-43.57, 143.046],
                                  [-9.677, 92],
                                ],
                                o: [
                                  [-97.204, 45.849],
                                  [27.007, 125.818],
                                  [-53.663, 42.244],
                                  [126.7, 35.188],
                                  [18.746, 68.272],
                                  [111.068, -3.864],
                                  [-128.739, -80.249],
                                  [41.799, -144.63],
                                  [9.678, -92],
                                ],
                                v: [
                                  [-130.719, -412.813],
                                  [-161.143, -185.659],
                                  [-265.63, 29.022],
                                  [-260.677, 133.824],
                                  [-11.994, 307.922],
                                  [134.382, 411.53],
                                  [303.728, 255.911],
                                  [19.439, -151.465],
                                  [268.49, -241.382],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.821, -44.358],
                                  [-26.782, -125.564],
                                  [53.504, -42.4],
                                  [-126.418, -35.218],
                                  [-17.522, -66.651],
                                  [-120.788, 6.012],
                                  [128.358, 80.155],
                                  [-43.409, 142.722],
                                  [-9.719, 92],
                                ],
                                o: [
                                  [-97.134, 45.695],
                                  [26.782, 125.564],
                                  [-53.504, 42.4],
                                  [126.418, 35.218],
                                  [18.764, 68.179],
                                  [111.045, -3.911],
                                  [-128.358, -80.155],
                                  [41.594, -144.346],
                                  [9.719, -92],
                                ],
                                v: [
                                  [-130.648, -412.467],
                                  [-160.868, -185.418],
                                  [-266.3, 28.12],
                                  [-259.48, 133.904],
                                  [-12.402, 307.957],
                                  [134.736, 411.164],
                                  [303.27, 254.859],
                                  [19.305, -151.305],
                                  [267.934, -240.609],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.916, -44.167],
                                  [-26.552, -125.304],
                                  [53.342, -42.56],
                                  [-126.129, -35.248],
                                  [-17.51, -66.517],
                                  [-121.007, 6.112],
                                  [127.968, 80.059],
                                  [-43.244, 142.391],
                                  [-9.762, 92],
                                ],
                                o: [
                                  [-97.063, 45.538],
                                  [26.552, 125.304],
                                  [-53.342, 42.56],
                                  [126.129, 35.248],
                                  [18.783, 68.084],
                                  [111.021, -3.958],
                                  [-127.968, -80.059],
                                  [41.384, -144.055],
                                  [9.762, -92],
                                ],
                                v: [
                                  [-130.571, -412.109],
                                  [-160.539, -185.124],
                                  [-266.963, 27.162],
                                  [-258.285, 134.036],
                                  [-12.76, 307.926],
                                  [135.09, 410.741],
                                  [302.814, 253.755],
                                  [19.193, -151.117],
                                  [267.374, -239.89],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.014, -43.973],
                                  [-26.317, -125.038],
                                  [53.177, -42.723],
                                  [-125.833, -35.279],
                                  [-17.498, -66.381],
                                  [-121.232, 6.214],
                                  [127.569, 79.961],
                                  [-43.076, 142.052],
                                  [-9.806, 92],
                                ],
                                o: [
                                  [-96.99, 45.377],
                                  [26.317, 125.038],
                                  [-53.177, 42.723],
                                  [125.833, 35.279],
                                  [18.803, 67.986],
                                  [110.997, -4.007],
                                  [-127.569, -79.961],
                                  [41.17, -143.758],
                                  [9.806, -92],
                                ],
                                v: [
                                  [-130.481, -411.683],
                                  [-160.269, -184.84],
                                  [-267.563, 26.262],
                                  [-257.104, 134.163],
                                  [-13.188, 307.944],
                                  [135.444, 410.369],
                                  [302.242, 252.712],
                                  [19.159, -150.857],
                                  [266.855, -239.055],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.113, -43.773],
                                  [-26.077, -124.765],
                                  [53.007, -42.89],
                                  [-125.531, -35.311],
                                  [-17.486, -66.241],
                                  [-121.461, 6.319],
                                  [127.161, 79.861],
                                  [-42.904, 141.706],
                                  [-9.851, 92],
                                ],
                                o: [
                                  [-96.915, 45.213],
                                  [26.077, 124.765],
                                  [-53.007, 42.89],
                                  [125.531, 35.311],
                                  [18.823, 67.887],
                                  [110.972, -4.057],
                                  [-127.161, -79.861],
                                  [40.951, -143.454],
                                  [9.851, -92],
                                ],
                                v: [
                                  [-130.327, -411.244],
                                  [-160.058, -184.561],
                                  [-268.213, 25.307],
                                  [-255.981, 134.347],
                                  [-13.628, 307.903],
                                  [135.798, 409.941],
                                  [301.728, 251.673],
                                  [19.14, -150.576],
                                  [266.327, -238.281],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.215, -43.57],
                                  [-25.831, -124.487],
                                  [52.834, -43.06],
                                  [-125.223, -35.344],
                                  [-17.473, -66.099],
                                  [-121.695, 6.426],
                                  [126.744, 79.758],
                                  [-42.729, 141.352],
                                  [-9.897, 92],
                                ],
                                o: [
                                  [-96.839, 45.045],
                                  [25.831, 124.487],
                                  [-52.834, 43.06],
                                  [125.223, 35.344],
                                  [18.843, 67.785],
                                  [110.946, -4.108],
                                  [-126.744, -79.758],
                                  [40.727, -143.144],
                                  [9.897, -92],
                                ],
                                v: [
                                  [-130.105, -410.798],
                                  [-159.85, -184.292],
                                  [-268.807, 24.36],
                                  [-254.817, 134.531],
                                  [-14.075, 307.854],
                                  [136.202, 409.506],
                                  [301.104, 250.582],
                                  [19.206, -150.216],
                                  [265.844, -237.448],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.319, -43.361],
                                  [-25.579, -124.203],
                                  [52.657, -43.235],
                                  [-124.907, -35.377],
                                  [-17.46, -65.953],
                                  [-121.935, 6.535],
                                  [126.318, 79.654],
                                  [-42.549, 140.99],
                                  [-9.944, 92],
                                ],
                                o: [
                                  [-96.76, 44.873],
                                  [25.579, 124.203],
                                  [-52.657, 43.235],
                                  [124.907, 35.377],
                                  [18.864, 67.681],
                                  [110.92, -4.16],
                                  [-126.318, -79.654],
                                  [40.497, -142.826],
                                  [9.944, -92],
                                ],
                                v: [
                                  [-129.874, -410.228],
                                  [-159.644, -184.033],
                                  [-269.339, 23.416],
                                  [-253.654, 134.715],
                                  [-14.535, 307.804],
                                  [136.556, 409.127],
                                  [300.484, 249.546],
                                  [19.295, -149.778],
                                  [265.289, -236.613],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.426, -43.148],
                                  [-25.323, -123.912],
                                  [52.476, -43.413],
                                  [-124.584, -35.411],
                                  [-17.447, -65.804],
                                  [-122.18, 6.647],
                                  [125.882, 79.546],
                                  [-42.365, 140.62],
                                  [-9.992, 92],
                                ],
                                o: [
                                  [-96.681, 44.697],
                                  [25.323, 123.912],
                                  [-52.476, 43.413],
                                  [124.584, 35.411],
                                  [18.885, 67.574],
                                  [110.893, -4.213],
                                  [-125.881, -79.547],
                                  [40.263, -142.501],
                                  [9.992, -92],
                                ],
                                v: [
                                  [-129.63, -409.702],
                                  [-159.496, -183.834],
                                  [-269.864, 22.473],
                                  [-252.556, 134.948],
                                  [-15.009, 307.752],
                                  [136.96, 408.693],
                                  [299.866, 248.464],
                                  [19.463, -149.373],
                                  [264.778, -235.728],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.535, -42.93],
                                  [-25.06, -123.614],
                                  [52.291, -43.596],
                                  [-124.254, -35.445],
                                  [-17.433, -65.651],
                                  [-122.43, 6.761],
                                  [125.436, 79.437],
                                  [-42.177, 140.242],
                                  [-10.041, 92],
                                ],
                                o: [
                                  [-96.599, 44.518],
                                  [25.06, 123.614],
                                  [-52.291, 43.596],
                                  [124.254, 35.445],
                                  [18.907, 67.465],
                                  [110.866, -4.267],
                                  [-125.436, -79.437],
                                  [40.023, -142.169],
                                  [10.041, -92],
                                ],
                                v: [
                                  [-129.323, -409.113],
                                  [-159.351, -183.583],
                                  [-270.382, 21.485],
                                  [-251.412, 135.229],
                                  [-15.49, 307.684],
                                  [137.363, 408.259],
                                  [299.189, 247.388],
                                  [19.661, -148.84],
                                  [264.305, -234.785],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.646, -42.708],
                                  [-24.791, -123.31],
                                  [52.102, -43.782],
                                  [-123.916, -35.481],
                                  [-17.419, -65.495],
                                  [-122.686, 6.878],
                                  [124.98, 79.325],
                                  [-41.985, 139.855],
                                  [-10.091, 92],
                                ],
                                o: [
                                  [-96.515, 44.334],
                                  [24.791, 123.31],
                                  [-52.102, 43.782],
                                  [123.917, 35.481],
                                  [18.929, 67.354],
                                  [110.838, -4.323],
                                  [-124.98, -79.325],
                                  [39.779, -141.829],
                                  [10.091, -92],
                                ],
                                v: [
                                  [-128.946, -408.457],
                                  [-159.263, -183.343],
                                  [-270.839, 20.5],
                                  [-250.332, 135.509],
                                  [-16.046, 307.622],
                                  [137.813, 407.873],
                                  [298.466, 246.373],
                                  [19.932, -148.285],
                                  [263.826, -233.898],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.76, -42.48],
                                  [-24.517, -123],
                                  [51.908, -43.973],
                                  [-123.572, -35.517],
                                  [-17.405, -65.336],
                                  [-122.948, 6.998],
                                  [124.514, 79.21],
                                  [-41.789, 139.459],
                                  [-10.142, 92],
                                ],
                                o: [
                                  [-96.43, 44.146],
                                  [24.517, 123],
                                  [-51.908, 43.973],
                                  [123.572, 35.517],
                                  [18.952, 67.24],
                                  [110.81, -4.38],
                                  [-124.514, -79.21],
                                  [39.528, -141.482],
                                  [10.142, -92],
                                ],
                                v: [
                                  [-128.561, -407.789],
                                  [-159.232, -183.106],
                                  [-271.296, 19.512],
                                  [-249.316, 135.787],
                                  [-16.547, 307.552],
                                  [138.263, 407.431],
                                  [297.74, 245.363],
                                  [20.282, -147.705],
                                  [263.32, -232.954],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.876, -42.248],
                                  [-24.236, -122.682],
                                  [51.71, -44.168],
                                  [-123.219, -35.554],
                                  [-17.39, -65.173],
                                  [-123.215, 7.12],
                                  [124.038, 79.093],
                                  [-41.589, 139.055],
                                  [-10.194, 92],
                                ],
                                o: [
                                  [-96.343, 43.954],
                                  [24.236, 122.682],
                                  [-51.71, 44.168],
                                  [123.219, 35.554],
                                  [18.975, 67.124],
                                  [110.781, -4.438],
                                  [-124.038, -79.093],
                                  [39.272, -141.128],
                                  [10.194, -92],
                                ],
                                v: [
                                  [-128.161, -407.115],
                                  [-159.203, -182.936],
                                  [-271.684, 18.534],
                                  [-248.31, 136.119],
                                  [-17.124, 307.427],
                                  [138.656, 407.037],
                                  [296.963, 244.303],
                                  [20.657, -147.047],
                                  [262.809, -231.955],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.995, -42.01],
                                  [-23.949, -122.357],
                                  [51.508, -44.367],
                                  [-122.858, -35.592],
                                  [-17.376, -65.007],
                                  [-123.489, 7.245],
                                  [123.551, 78.974],
                                  [-41.383, 138.642],
                                  [-10.248, 92],
                                ],
                                o: [
                                  [-96.254, 43.758],
                                  [23.949, 122.357],
                                  [-51.508, 44.367],
                                  [122.858, 35.592],
                                  [18.999, 67.005],
                                  [110.751, -4.498],
                                  [-123.551, -78.974],
                                  [39.011, -140.765],
                                  [10.248, -92],
                                ],
                                v: [
                                  [-127.697, -406.381],
                                  [-159.222, -182.768],
                                  [-272.073, 17.568],
                                  [-247.313, 136.496],
                                  [-17.653, 307.293],
                                  [139.158, 406.588],
                                  [296.189, 243.312],
                                  [21.111, -146.372],
                                  [262.331, -230.964],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.117, -41.767],
                                  [-23.656, -122.025],
                                  [51.302, -44.57],
                                  [-122.49, -35.631],
                                  [-17.36, -64.837],
                                  [-123.768, 7.372],
                                  [123.054, 78.851],
                                  [-41.174, 138.22],
                                  [-10.303, 92],
                                ],
                                o: [
                                  [-96.162, 43.557],
                                  [23.656, 122.025],
                                  [-51.302, 44.57],
                                  [122.49, 35.631],
                                  [19.023, 66.883],
                                  [110.721, -4.558],
                                  [-123.054, -78.851],
                                  [38.743, -140.394],
                                  [10.302, -92],
                                ],
                                v: [
                                  [-127.218, -405.581],
                                  [-159.25, -182.612],
                                  [-272.402, 16.599],
                                  [-246.319, 136.87],
                                  [-18.251, 307.213],
                                  [139.596, 406.185],
                                  [295.365, 242.318],
                                  [21.645, -145.618],
                                  [261.839, -229.965],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.241, -41.518],
                                  [-23.356, -121.686],
                                  [51.09, -44.778],
                                  [-122.114, -35.671],
                                  [-17.345, -64.663],
                                  [-124.054, 7.503],
                                  [122.545, 78.726],
                                  [-40.959, 137.788],
                                  [-10.358, 92],
                                ],
                                o: [
                                  [-96.069, 43.353],
                                  [23.356, 121.686],
                                  [-51.09, 44.778],
                                  [122.114, 35.671],
                                  [19.048, 66.759],
                                  [110.69, -4.62],
                                  [-122.545, -78.726],
                                  [38.47, -140.015],
                                  [10.358, -92],
                                ],
                                v: [
                                  [-126.676, -404.823],
                                  [-159.334, -182.458],
                                  [-272.724, 15.634],
                                  [-245.443, 137.234],
                                  [-18.809, 307.071],
                                  [140.025, 405.727],
                                  [294.546, 241.339],
                                  [22.258, -144.84],
                                  [261.379, -228.92],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.368, -41.264],
                                  [-23.05, -121.339],
                                  [50.875, -44.991],
                                  [-121.729, -35.711],
                                  [-17.329, -64.485],
                                  [-124.346, 7.636],
                                  [122.025, 78.599],
                                  [-40.74, 137.347],
                                  [-10.416, 92],
                                ],
                                o: [
                                  [-95.974, 43.143],
                                  [23.05, 121.339],
                                  [-50.875, 44.991],
                                  [121.729, 35.711],
                                  [19.074, 66.632],
                                  [110.658, -4.684],
                                  [-122.025, -78.599],
                                  [38.191, -139.628],
                                  [10.416, -92],
                                ],
                                v: [
                                  [-126.117, -403.96],
                                  [-159.474, -182.315],
                                  [-272.977, 14.675],
                                  [-244.523, 137.648],
                                  [-19.436, 306.938],
                                  [140.514, 405.315],
                                  [293.677, 240.366],
                                  [22.943, -143.99],
                                  [260.849, -227.877],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.498, -41.005],
                                  [-22.737, -120.985],
                                  [50.654, -45.208],
                                  [-121.335, -35.752],
                                  [-17.313, -64.303],
                                  [-124.645, 7.772],
                                  [121.494, 78.468],
                                  [-40.517, 136.896],
                                  [-10.474, 92],
                                ],
                                o: [
                                  [-95.877, 42.929],
                                  [22.737, 120.985],
                                  [-50.654, 45.208],
                                  [121.335, 35.752],
                                  [19.1, 66.502],
                                  [110.626, -4.749],
                                  [-121.494, -78.468],
                                  [37.905, -139.232],
                                  [10.474, -92],
                                ],
                                v: [
                                  [-125.541, -403.132],
                                  [-159.659, -182.229],
                                  [-273.231, 13.72],
                                  [-243.72, 138.112],
                                  [-20.015, 306.797],
                                  [140.983, 404.901],
                                  [292.813, 239.454],
                                  [23.662, -143.062],
                                  [260.411, -226.781],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.63, -40.74],
                                  [-22.417, -120.623],
                                  [50.429, -45.431],
                                  [-120.933, -35.795],
                                  [-17.296, -64.118],
                                  [-124.949, 7.912],
                                  [120.951, 78.335],
                                  [-40.288, 136.435],
                                  [-10.534, 92],
                                ],
                                o: [
                                  [-95.777, 42.71],
                                  [22.417, 120.623],
                                  [-50.429, 45.431],
                                  [120.933, 35.795],
                                  [19.126, 66.37],
                                  [110.592, -4.815],
                                  [-120.951, -78.335],
                                  [37.614, -138.828],
                                  [10.534, -92],
                                ],
                                v: [
                                  [-124.901, -402.246],
                                  [-159.852, -182.207],
                                  [-273.381, 12.779],
                                  [-242.928, 138.572],
                                  [-20.663, 306.657],
                                  [141.459, 404.486],
                                  [291.955, 238.495],
                                  [24.514, -142.117],
                                  [259.902, -225.686],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.766, -40.469],
                                  [-22.09, -120.253],
                                  [50.198, -45.658],
                                  [-120.523, -35.838],
                                  [-17.279, -63.928],
                                  [-125.261, 8.054],
                                  [120.397, 78.198],
                                  [-40.054, 135.964],
                                  [-10.595, 92],
                                ],
                                o: [
                                  [-95.676, 42.487],
                                  [22.09, 120.252],
                                  [-50.198, 45.658],
                                  [120.523, 35.838],
                                  [19.153, 66.234],
                                  [110.559, -4.883],
                                  [-120.397, -78.198],
                                  [37.315, -138.415],
                                  [10.594, -92],
                                ],
                                v: [
                                  [-124.19, -401.304],
                                  [-160.1, -182.186],
                                  [-273.57, 11.835],
                                  [-242.199, 139.072],
                                  [-21.273, 306.51],
                                  [141.967, 404.061],
                                  [291.004, 237.605],
                                  [25.392, -141.138],
                                  [259.422, -224.541],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.904, -40.192],
                                  [-21.756, -119.874],
                                  [49.963, -45.889],
                                  [-120.103, -35.882],
                                  [-17.262, -63.734],
                                  [-125.58, 8.199],
                                  [119.83, 78.059],
                                  [-39.815, 135.483],
                                  [-10.657, 92],
                                ],
                                o: [
                                  [-95.572, 42.258],
                                  [21.756, 119.874],
                                  [-49.963, 45.889],
                                  [120.103, 35.882],
                                  [19.181, 66.096],
                                  [110.524, -4.952],
                                  [-119.83, -78.059],
                                  [37.011, -137.992],
                                  [10.657, -92],
                                ],
                                v: [
                                  [-123.514, -400.413],
                                  [-160.444, -182.167],
                                  [-273.646, 10.896],
                                  [-241.524, 139.566],
                                  [-21.952, 306.312],
                                  [142.427, 403.625],
                                  [290.104, 236.722],
                                  [26.395, -140.098],
                                  [258.922, -223.389],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.046, -39.909],
                                  [-21.415, -119.488],
                                  [49.722, -46.126],
                                  [-119.674, -35.927],
                                  [-17.244, -63.536],
                                  [-125.905, 8.348],
                                  [119.251, 77.917],
                                  [-39.571, 134.992],
                                  [-10.721, 92],
                                ],
                                o: [
                                  [-95.466, 42.025],
                                  [21.415, 119.488],
                                  [-49.722, 46.126],
                                  [119.674, 35.927],
                                  [19.209, 65.954],
                                  [110.489, -5.023],
                                  [-119.251, -77.917],
                                  [36.7, -137.561],
                                  [10.721, -92],
                                ],
                                v: [
                                  [-122.774, -399.405],
                                  [-160.786, -182.21],
                                  [-273.716, 9.955],
                                  [-240.911, 140.055],
                                  [-22.594, 306.169],
                                  [142.918, 403.188],
                                  [289.157, 235.857],
                                  [27.485, -138.979],
                                  [258.448, -222.249],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.19, -39.62],
                                  [-21.066, -119.093],
                                  [49.477, -46.368],
                                  [-119.236, -35.973],
                                  [-17.226, -63.334],
                                  [-126.237, 8.5],
                                  [118.659, 77.771],
                                  [-39.322, 134.489],
                                  [-10.786, 92],
                                ],
                                o: [
                                  [-95.357, 41.786],
                                  [21.066, 119.093],
                                  [-49.477, 46.368],
                                  [119.236, 35.973],
                                  [19.238, 65.81],
                                  [110.452, -5.095],
                                  [-118.659, -77.771],
                                  [36.382, -137.12],
                                  [10.786, -92],
                                ],
                                v: [
                                  [-121.961, -398.448],
                                  [-161.189, -182.307],
                                  [-273.727, 9.029],
                                  [-240.361, 140.633],
                                  [-23.252, 305.975],
                                  [143.415, 402.801],
                                  [288.226, 235.052],
                                  [28.592, -137.835],
                                  [257.954, -221.112],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.338, -39.324],
                                  [-20.71, -118.69],
                                  [49.226, -46.616],
                                  [-118.788, -36.02],
                                  [-17.208, -63.127],
                                  [-126.577, 8.655],
                                  [118.055, 77.623],
                                  [-39.067, 133.976],
                                  [-10.852, 92],
                                ],
                                o: [
                                  [-95.247, 41.543],
                                  [20.71, 118.69],
                                  [-49.226, 46.616],
                                  [118.788, 36.02],
                                  [19.268, 65.662],
                                  [110.416, -5.169],
                                  [-118.055, -77.623],
                                  [36.057, -136.67],
                                  [10.852, -92],
                                ],
                                v: [
                                  [-121.128, -397.428],
                                  [-161.581, -182.413],
                                  [-273.743, 8.1],
                                  [-239.872, 141.214],
                                  [-23.969, 305.839],
                                  [143.939, 402.35],
                                  [287.25, 234.255],
                                  [29.725, -136.622],
                                  [257.44, -219.926],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.489, -39.023],
                                  [-20.346, -118.278],
                                  [48.969, -46.869],
                                  [-118.331, -36.068],
                                  [-17.189, -62.916],
                                  [-126.924, 8.813],
                                  [117.437, 77.471],
                                  [-38.807, 133.452],
                                  [-10.92, 92],
                                ],
                                o: [
                                  [-95.133, 41.294],
                                  [20.346, 118.278],
                                  [-48.969, 46.869],
                                  [118.331, 36.068],
                                  [19.298, 65.511],
                                  [110.378, -5.244],
                                  [-117.437, -77.471],
                                  [35.725, -136.209],
                                  [10.92, -92],
                                ],
                                v: [
                                  [-120.284, -396.365],
                                  [-162.074, -182.57],
                                  [-273.691, 7.23],
                                  [-239.435, 141.777],
                                  [-24.661, 305.643],
                                  [144.354, 401.886],
                                  [286.334, 233.477],
                                  [30.938, -135.384],
                                  [256.957, -218.692],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.643, -38.714],
                                  [-19.974, -117.857],
                                  [48.707, -47.127],
                                  [-117.864, -36.117],
                                  [-17.17, -62.7],
                                  [-127.279, 8.975],
                                  [116.806, 77.316],
                                  [-38.541, 132.916],
                                  [-10.989, 92],
                                ],
                                o: [
                                  [-95.018, 41.039],
                                  [19.974, 117.857],
                                  [-48.707, 47.127],
                                  [117.864, 36.117],
                                  [19.329, 65.357],
                                  [110.339, -5.321],
                                  [-116.806, -77.316],
                                  [35.386, -135.739],
                                  [10.989, -92],
                                ],
                                v: [
                                  [-119.419, -395.345],
                                  [-162.617, -182.778],
                                  [-273.581, 6.303],
                                  [-239.12, 142.383],
                                  [-25.359, 305.453],
                                  [144.711, 401.367],
                                  [285.332, 232.771],
                                  [32.24, -134.014],
                                  [256.39, -217.505],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.8, -38.399],
                                  [-19.594, -117.427],
                                  [48.44, -47.39],
                                  [-117.387, -36.168],
                                  [-17.15, -62.48],
                                  [-127.641, 9.14],
                                  [116.161, 77.157],
                                  [-38.27, 132.369],
                                  [-11.06, 92],
                                ],
                                o: [
                                  [-94.9, 40.78],
                                  [19.594, 117.427],
                                  [-48.44, 47.391],
                                  [117.387, 36.168],
                                  [19.36, 65.2],
                                  [110.3, -5.4],
                                  [-116.161, -77.157],
                                  [35.039, -135.259],
                                  [11.06, -92],
                                ],
                                v: [
                                  [-118.479, -394.264],
                                  [-163.206, -183.045],
                                  [-273.467, 5.446],
                                  [-238.792, 142.971],
                                  [-26.085, 305.267],
                                  [145.052, 400.784],
                                  [284.381, 232.073],
                                  [33.497, -132.641],
                                  [255.904, -216.28],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [106.961, -38.078],
                                  [-19.206, -116.987],
                                  [48.166, -47.66],
                                  [-116.899, -36.219],
                                  [-17.13, -62.254],
                                  [-128.011, 9.309],
                                  [115.503, 76.995],
                                  [-37.992, 131.81],
                                  [-11.133, 92],
                                ],
                                o: [
                                  [-94.779, 40.514],
                                  [19.206, 116.987],
                                  [-48.166, 47.66],
                                  [116.899, 36.219],
                                  [19.392, 65.039],
                                  [110.26, -5.481],
                                  [-115.503, -76.995],
                                  [34.686, -134.769],
                                  [11.133, -92],
                                ],
                                v: [
                                  [-117.527, -393.143],
                                  [-163.843, -183.371],
                                  [-273.244, 4.574],
                                  [-238.534, 143.611],
                                  [-26.778, 305.087],
                                  [145.275, 400.198],
                                  [283.398, 231.385],
                                  [34.895, -131.231],
                                  [255.342, -214.999],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.125, -37.749],
                                  [-18.81, -116.539],
                                  [47.887, -47.935],
                                  [-116.401, -36.271],
                                  [-17.109, -62.024],
                                  [-128.388, 9.482],
                                  [114.83, 76.83],
                                  [-37.709, 131.239],
                                  [-11.206, 92],
                                ],
                                o: [
                                  [-94.656, 40.243],
                                  [18.81, 116.539],
                                  [-47.887, 47.935],
                                  [116.401, 36.271],
                                  [19.425, 64.875],
                                  [110.219, -5.563],
                                  [-114.83, -76.83],
                                  [34.324, -134.267],
                                  [11.206, -92],
                                ],
                                v: [
                                  [-116.551, -392.076],
                                  [-164.514, -183.693],
                                  [-273.079, 3.709],
                                  [-238.323, 144.229],
                                  [-27.53, 304.862],
                                  [145.441, 399.507],
                                  [282.435, 230.781],
                                  [36.321, -129.704],
                                  [254.797, -213.775],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.293, -37.414],
                                  [-18.405, -116.08],
                                  [47.602, -48.216],
                                  [-115.892, -36.325],
                                  [-17.088, -61.789],
                                  [-128.774, 9.658],
                                  [114.143, 76.661],
                                  [-37.419, 130.656],
                                  [-11.282, 92],
                                ],
                                o: [
                                  [-94.53, 39.966],
                                  [18.405, 116.08],
                                  [-47.602, 48.216],
                                  [115.892, 36.325],
                                  [19.459, 64.707],
                                  [110.177, -5.647],
                                  [-114.143, -76.661],
                                  [33.955, -133.756],
                                  [11.282, -92],
                                ],
                                v: [
                                  [-115.5, -390.951],
                                  [-165.241, -184.113],
                                  [-272.796, 2.901],
                                  [-238.12, 144.849],
                                  [-28.26, 304.697],
                                  [145.591, 398.792],
                                  [281.484, 230.187],
                                  [37.763, -128.163],
                                  [254.225, -212.494],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.465, -37.071],
                                  [-17.992, -115.612],
                                  [47.31, -48.503],
                                  [-115.373, -36.379],
                                  [-17.067, -61.55],
                                  [-129.169, 9.838],
                                  [113.442, 76.489],
                                  [-37.124, 130.06],
                                  [-11.359, 92],
                                ],
                                o: [
                                  [-94.402, 39.683],
                                  [17.992, 115.612],
                                  [-47.31, 48.503],
                                  [115.373, 36.379],
                                  [19.493, 64.535],
                                  [110.134, -5.732],
                                  [-113.442, -76.489],
                                  [33.578, -133.233],
                                  [11.359, -92],
                                ],
                                v: [
                                  [-114.436, -389.778],
                                  [-166, -184.537],
                                  [-272.458, 2.087],
                                  [-237.974, 145.445],
                                  [-29.06, 304.476],
                                  [145.622, 398.087],
                                  [280.543, 229.605],
                                  [39.246, -126.596],
                                  [253.628, -211.18],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.64, -36.721],
                                  [-17.569, -115.134],
                                  [47.013, -48.796],
                                  [-114.842, -36.435],
                                  [-17.045, -61.304],
                                  [-129.571, 10.022],
                                  [112.725, 76.313],
                                  [-36.822, 129.452],
                                  [-11.438, 92],
                                ],
                                o: [
                                  [-94.27, 39.395],
                                  [17.569, 115.134],
                                  [-47.013, 48.796],
                                  [114.842, 36.435],
                                  [19.528, 64.36],
                                  [110.09, -5.82],
                                  [-112.725, -76.313],
                                  [33.193, -132.699],
                                  [11.438, -92],
                                ],
                                v: [
                                  [-113.345, -388.661],
                                  [-166.801, -185.005],
                                  [-272.117, 1.268],
                                  [-237.873, 146.03],
                                  [-29.83, 304.328],
                                  [145.598, 397.305],
                                  [279.587, 229.108],
                                  [40.796, -124.915],
                                  [253.054, -209.912],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [107.818, -36.363],
                                  [-17.138, -114.646],
                                  [46.709, -49.096],
                                  [-114.3, -36.492],
                                  [-17.023, -61.054],
                                  [-129.982, 10.209],
                                  [111.993, 76.133],
                                  [-36.513, 128.831],
                                  [-11.518, 92],
                                ],
                                o: [
                                  [-94.136, 39.1],
                                  [17.138, 114.646],
                                  [-46.709, 49.096],
                                  [114.3, 36.492],
                                  [19.564, 64.182],
                                  [110.045, -5.909],
                                  [-111.993, -76.133],
                                  [32.799, -132.154],
                                  [11.518, -92],
                                ],
                                v: [
                                  [-112.229, -387.5],
                                  [-167.593, -185.465],
                                  [-271.76, 0.455],
                                  [-237.828, 146.603],
                                  [-30.62, 304.126],
                                  [145.507, 396.484],
                                  [278.682, 228.673],
                                  [42.425, -123.21],
                                  [252.414, -208.589],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.001, -35.998],
                                  [-16.698, -114.147],
                                  [46.398, -49.402],
                                  [-113.747, -36.55],
                                  [-17, -60.799],
                                  [-130.402, 10.401],
                                  [111.246, 75.949],
                                  [-36.198, 128.196],
                                  [-11.6, 92],
                                ],
                                o: [
                                  [-93.999, 38.798],
                                  [16.698, 114.147],
                                  [-46.398, 49.402],
                                  [113.747, 36.55],
                                  [19.6, 63.999],
                                  [110, -6.001],
                                  [-111.246, -75.949],
                                  [32.398, -131.597],
                                  [11.6, -92],
                                ],
                                v: [
                                  [-111.096, -386.346],
                                  [-168.426, -185.928],
                                  [-271.312, -0.327],
                                  [-237.788, 147.15],
                                  [-31.431, 303.935],
                                  [145.362, 395.586],
                                  [277.751, 228.276],
                                  [44.083, -121.492],
                                  [251.796, -207.272],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.187, -35.625],
                                  [-16.248, -113.638],
                                  [46.081, -49.714],
                                  [-113.182, -36.61],
                                  [-16.977, -60.538],
                                  [-130.831, 10.597],
                                  [110.483, 75.761],
                                  [-35.877, 127.549],
                                  [-11.684, 92],
                                ],
                                o: [
                                  [-93.859, 38.491],
                                  [16.248, 113.638],
                                  [-46.081, 49.714],
                                  [113.182, 36.61],
                                  [19.637, 63.813],
                                  [109.953, -6.094],
                                  [-110.483, -75.761],
                                  [31.988, -131.028],
                                  [11.684, -92],
                                ],
                                v: [
                                  [-109.936, -385.15],
                                  [-169.297, -186.494],
                                  [-270.86, -1.104],
                                  [-237.753, 147.747],
                                  [-32.225, 303.756],
                                  [145.2, 394.686],
                                  [276.848, 227.892],
                                  [45.77, -119.713],
                                  [251.148, -205.963],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [108.378, -35.244],
                                  [-15.788, -113.118],
                                  [45.758, -50.033],
                                  [-112.605, -36.67],
                                  [-16.953, -60.271],
                                  [-131.269, 10.797],
                                  [109.703, 75.57],
                                  [-35.548, 126.887],
                                  [-11.77, 92],
                                ],
                                o: [
                                  [-93.717, 38.177],
                                  [15.788, 113.118],
                                  [-45.758, 50.033],
                                  [112.605, 36.67],
                                  [19.676, 63.622],
                                  [109.906, -6.189],
                                  [-109.703, -75.57],
                                  [31.569, -130.448],
                                  [11.77, -92],
                                ],
                                v: [
                                  [-108.696, -384.012],
                                  [-170.157, -187.048],
                                  [-270.344, -1.89],
                                  [-237.761, 148.28],
                                  [-33.043, 303.602],
                                  [144.921, 393.785],
                                  [275.959, 227.571],
                                  [47.474, -117.861],
                                  [250.534, -204.648],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [2.656, 5],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.281, 4.719],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.875, 4.469],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.531, 4.219],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.25, 3.969],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1, 3.75],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.719, 3.5],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.344, 3.125],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.188, 2.938],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.094, 2.781],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.062, 2.594],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.156, 2.5],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.219, 2.344],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.281, 2.219],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.375, 2.094],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.5, 2],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.531, 1.906],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.656, 1.812],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.688, 1.719],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.781, 1.625],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.812, 1.531],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.906, 1.469],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.938, 1.438],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 1.312],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16, 1.25],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.969, 1.156],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.969, 1.125],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.844, 0.938],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.75, 0.844],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.625, 0.75],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.219, 0.656],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.562, 0.562],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.875, 0.438],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.156, 0.281],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.469, 0.188],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.781, 0],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.125, -0.125],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.438, -0.281],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.812, -0.469],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.125, -0.688],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.469, -0.906],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, -1.125],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.156, -1.312],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.531, -1.531],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.875, -1.719],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.625, -2.031],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, -2.156],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.344, -2.281],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.75, -2.375],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.094, -2.469],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.531, -2.531],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.906, -2.594],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.312, -2.656],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, -2.688],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.156, -2.719],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.594, -2.719],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, -2.719],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.406, -2.656],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.875, -2.625],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.25, -2.562],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.75, -2.469],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.188, -2.406],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.625, -2.312],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.062, -2.188],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, -2.062],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.969, -1.938],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.438, -1.781],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.875, -1.656],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.344, -1.469],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, -1.281],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.25, -1.062],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.719, -0.844],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.219, -0.594],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.656, -0.375],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, -0.125],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.906, 0.438],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.281, 0.719],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.656, 1.031],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.094, 1.375],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.438, 1.688],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.844, 2.031],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.156, 2.375],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.5, 2.781],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.812, 3.094],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44, 3.5],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.031, 3.938],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44, 4.406],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.969, 4.906],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.969, 5.406],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.906, 5.969],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.844, 6.5],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.812, 7.031],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.719, 7.656],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.656, 8.281],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.531, 8.875],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.438, 9.5],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.344, 10.156],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.281, 10.812],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.125, 11.438],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.031, 12.062],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.906, 12.719],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.75, 13.375],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.625, 14],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.469, 14.625],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.312, 15.312],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.156, 15.938],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42, 16.5],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.875, 17.156],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.688, 17.719],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.5, 18.312],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.344, 18.844],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.875, 19.375],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, 19.938],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 20.406],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.219, 20.875],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.594, 21.344],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.969, 21.75],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.281, 22.125],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.531, 22.531],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.031, 23.156],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.219, 23.438],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.438, 23.688],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.594, 23.844],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.75, 24.031],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, 24.188],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.031, 24.344],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.156, 24.438],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, 24.5],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.375, 24.594],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.469, 24.594],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.531, 24.625],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.688, 24.625],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.781, 24.562],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.812, 24.562],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.062, 24.375],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.156, 24.219],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.281, 24.062],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.375, 23.906],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.5, 23.656],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.656, 23.438],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.812, 23.188],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 22.906],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.094, 22.562],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.281, 22.219],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.469, 21.844],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.688, 21.5],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.875, 21.094],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.156, 20.625],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.406, 20.219],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.719, 19.719],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.031, 19.25],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.312, 18.781],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.656, 18.25],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6, 17.688],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.406, 17.188],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.812, 16.625],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.25, 16.062],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.719, 15.469],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.281, 14.906],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.406, 14.312],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.562, 13.719],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.75, 13.156],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.938, 12.531],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.188, 11.969],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.438, 11.375],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.75, 10.781],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.094, 10.188],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.469, 9.625],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.906, 9],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.375, 8.469],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.875, 7.875],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.344, 7.375],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.844, 6.844],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.906, 5.844],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.406, 5.406],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.969, 4.938],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 4.469],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.562, 3.656],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.125, 3.281],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.188, 2.594],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, 2.219],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.312, 1.969],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.875, 1.656],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.438, 1.375],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.969, 1.094],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.469, 0.906],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17, 0.625],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.531, 0.406],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.969, 0.281],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.5, 0.094],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.969, -0.094],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.906, -0.344],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.312, -0.438],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.125, -0.531],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.656, -0.594],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.062, -0.656],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.469, -0.625],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, -0.688],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.344, -0.656],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.781, -0.656],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, -0.656],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.594, -0.562],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.406, -0.438],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.812, -0.281],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.219, -0.188],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.625, -0.031],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.062, 0.094],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.5, 0.312],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.906, 0.469],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.344, 0.688],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.781, 0.875],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.281, 1.125],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.719, 1.375],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.25, 1.594],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.75, 1.906],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.75, 2.469],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.312, 2.75],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.875, 3],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.469, 3.281],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.062, 3.531],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.688, 3.719],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.344, 3.938],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.062, 4.156],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.719, 4.312],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.469, 4.5],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.219, 4.594],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3, 4.781],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.781, 4.906],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [2.656, 5],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.281, 4.719],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.875, 4.469],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.531, 4.219],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.25, 3.969],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1, 3.75],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.719, 3.5],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.344, 3.125],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.188, 2.938],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.094, 2.781],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.062, 2.594],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.156, 2.5],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.219, 2.344],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.281, 2.219],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.375, 2.094],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.5, 2],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.531, 1.906],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.656, 1.812],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.688, 1.719],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.781, 1.625],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.812, 1.531],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.906, 1.469],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.938, 1.438],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 1.312],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16, 1.25],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.969, 1.156],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.969, 1.125],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.844, 0.938],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.75, 0.844],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.625, 0.75],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.219, 0.656],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.562, 0.562],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.875, 0.438],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.156, 0.281],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.469, 0.188],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.781, 0],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.125, -0.125],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.438, -0.281],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.812, -0.469],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.125, -0.688],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.469, -0.906],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, -1.125],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.156, -1.312],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.531, -1.531],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.875, -1.719],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.625, -2.031],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.969, -2.156],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.344, -2.281],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.75, -2.375],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.094, -2.469],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.531, -2.531],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.906, -2.594],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.312, -2.656],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.75, -2.688],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.156, -2.719],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.594, -2.719],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32, -2.719],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.406, -2.656],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.875, -2.625],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.25, -2.562],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.75, -2.469],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.188, -2.406],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.625, -2.312],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.062, -2.188],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, -2.062],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.969, -1.938],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.438, -1.781],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.875, -1.656],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.344, -1.469],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, -1.281],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.25, -1.062],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.719, -0.844],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.219, -0.594],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.656, -0.375],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, -0.125],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.906, 0.438],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.281, 0.719],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.656, 1.031],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.094, 1.375],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.438, 1.688],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.844, 2.031],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.156, 2.375],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.5, 2.781],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.812, 3.094],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44, 3.5],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.031, 3.938],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44, 4.406],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.969, 4.906],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.969, 5.406],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.906, 5.969],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.844, 6.5],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.812, 7.031],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.719, 7.656],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.656, 8.281],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.531, 8.875],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.438, 9.5],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.344, 10.156],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.281, 10.812],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.125, 11.438],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.031, 12.062],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.906, 12.719],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.75, 13.375],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.625, 14],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.469, 14.625],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.312, 15.312],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.156, 15.938],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42, 16.5],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.875, 17.156],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.688, 17.719],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.5, 18.312],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.344, 18.844],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.875, 19.375],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, 19.938],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.812, 20.406],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.219, 20.875],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.594, 21.344],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.969, 21.75],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.281, 22.125],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.531, 22.531],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.031, 23.156],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.219, 23.438],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.438, 23.688],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.594, 23.844],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.75, 24.031],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, 24.188],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.031, 24.344],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.156, 24.438],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, 24.5],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.375, 24.594],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.469, 24.594],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.531, 24.625],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.688, 24.625],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.781, 24.562],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.812, 24.562],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.062, 24.375],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.156, 24.219],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.281, 24.062],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.375, 23.906],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.5, 23.656],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.656, 23.438],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.812, 23.188],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.938, 22.906],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.094, 22.562],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.281, 22.219],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.469, 21.844],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.688, 21.5],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.875, 21.094],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.156, 20.625],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.406, 20.219],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.719, 19.719],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.031, 19.25],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.312, 18.781],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.656, 18.25],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6, 17.688],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.406, 17.188],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.812, 16.625],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.25, 16.062],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.719, 15.469],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.281, 14.906],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.406, 14.312],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.562, 13.719],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.75, 13.156],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.938, 12.531],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.188, 11.969],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.438, 11.375],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.75, 10.781],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.094, 10.188],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.469, 9.625],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.906, 9],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.375, 8.469],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.875, 7.875],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.344, 7.375],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.844, 6.844],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.906, 5.844],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.406, 5.406],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.969, 4.938],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 4.469],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.562, 3.656],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.125, 3.281],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.188, 2.594],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, 2.219],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.312, 1.969],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.875, 1.656],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.438, 1.375],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.969, 1.094],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.469, 0.906],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17, 0.625],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.531, 0.406],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.969, 0.281],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.5, 0.094],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.969, -0.094],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.906, -0.344],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.312, -0.438],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.125, -0.531],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.656, -0.594],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.062, -0.656],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.469, -0.625],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, -0.688],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.344, -0.656],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.781, -0.656],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.156, -0.656],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.594, -0.562],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.406, -0.438],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.812, -0.281],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.219, -0.188],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.625, -0.031],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.062, 0.094],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.5, 0.312],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.906, 0.469],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.344, 0.688],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.781, 0.875],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.281, 1.125],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.719, 1.375],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.25, 1.594],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.75, 1.906],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.75, 2.469],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.312, 2.75],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.875, 3],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.469, 3.281],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.062, 3.531],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.688, 3.719],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.344, 3.938],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.062, 4.156],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.719, 4.312],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.469, 4.5],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.219, 4.594],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3, 4.781],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.781, 4.906],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 12',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 12,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [105.715, -40.57],
                                  [-22.212, -120.391],
                                  [50.284, -45.573],
                                  [-120.676, -35.822],
                                  [-17.286, -63.999],
                                  [-125.145, 8.001],
                                  [120.604, 78.249],
                                  [-40.141, 136.14],
                                  [-10.572, 92],
                                ],
                                o: [
                                  [-95.714, 42.57],
                                  [22.212, 120.391],
                                  [-50.284, 45.573],
                                  [120.676, 35.822],
                                  [19.143, 66.285],
                                  [110.571, -4.858],
                                  [-120.604, -78.249],
                                  [37.427, -138.569],
                                  [10.572, -92],
                                ],
                                v: [
                                  [-117.845, -394.631],
                                  [-169.777, -180.216],
                                  [-272.089, 2.597],
                                  [-236.768, 148.545],
                                  [-18.228, 309.632],
                                  [146.33, 397.713],
                                  [270.662, 225.143],
                                  [26.89, -137.405],
                                  [262.621, -210.998],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.574, -40.852],
                                  [-22.553, -120.776],
                                  [50.524, -45.336],
                                  [-121.104, -35.777],
                                  [-17.303, -64.197],
                                  [-124.82, 7.853],
                                  [121.182, 78.391],
                                  [-40.385, 136.631],
                                  [-10.508, 92],
                                ],
                                o: [
                                  [-95.82, 42.803],
                                  [22.553, 120.776],
                                  [-50.524, 45.336],
                                  [121.104, 35.777],
                                  [19.115, 66.426],
                                  [110.607, -4.787],
                                  [-121.182, -78.391],
                                  [37.738, -139],
                                  [10.508, -92],
                                ],
                                v: [
                                  [-117.822, -394.349],
                                  [-170.639, -179.827],
                                  [-271.467, 2.944],
                                  [-236.733, 149.465],
                                  [-17.177, 309.964],
                                  [145.88, 397.177],
                                  [269.347, 224.821],
                                  [26.473, -137.468],
                                  [263.829, -210.588],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.436, -41.128],
                                  [-22.886, -121.153],
                                  [50.759, -45.105],
                                  [-121.523, -35.733],
                                  [-17.321, -64.39],
                                  [-124.502, 7.708],
                                  [121.747, 78.53],
                                  [-40.623, 137.11],
                                  [-10.446, 92],
                                ],
                                o: [
                                  [-95.923, 43.031],
                                  [22.886, 121.153],
                                  [-50.759, 45.105],
                                  [121.523, 35.733],
                                  [19.087, 66.564],
                                  [110.641, -4.718],
                                  [-121.747, -78.53],
                                  [38.041, -139.421],
                                  [10.446, -92],
                                ],
                                v: [
                                  [-117.781, -394.047],
                                  [-171.45, -179.512],
                                  [-270.79, 3.284],
                                  [-236.686, 150.397],
                                  [-16.079, 310.273],
                                  [145.318, 396.634],
                                  [268.038, 224.505],
                                  [26.03, -137.497],
                                  [265.031, -210.214],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.301, -41.398],
                                  [-23.211, -121.522],
                                  [50.988, -44.879],
                                  [-121.932, -35.69],
                                  [-17.337, -64.579],
                                  [-124.192, 7.566],
                                  [122.299, 78.666],
                                  [-40.856, 137.579],
                                  [-10.385, 92],
                                ],
                                o: [
                                  [-96.024, 43.253],
                                  [23.211, 121.522],
                                  [-50.988, 44.879],
                                  [121.932, 35.69],
                                  [19.06, 66.699],
                                  [110.675, -4.65],
                                  [-122.299, -78.666],
                                  [38.338, -139.832],
                                  [10.385, -92],
                                ],
                                v: [
                                  [-117.785, -393.724],
                                  [-172.327, -179.201],
                                  [-270.149, 3.717],
                                  [-236.691, 151.343],
                                  [-14.98, 310.514],
                                  [144.798, 396.076],
                                  [266.788, 224.187],
                                  [25.66, -137.502],
                                  [266.227, -209.759],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.169, -41.662],
                                  [-23.529, -121.882],
                                  [51.212, -44.658],
                                  [-122.331, -35.648],
                                  [-17.354, -64.763],
                                  [-123.889, 7.428],
                                  [122.839, 78.799],
                                  [-41.083, 138.037],
                                  [-10.326, 92],
                                ],
                                o: [
                                  [-96.123, 43.471],
                                  [23.529, 121.882],
                                  [-51.212, 44.658],
                                  [122.331, 35.648],
                                  [19.034, 66.831],
                                  [110.708, -4.585],
                                  [-122.839, -78.799],
                                  [38.628, -140.234],
                                  [10.326, -92],
                                ],
                                v: [
                                  [-117.78, -393.427],
                                  [-173.214, -178.895],
                                  [-269.398, 4.137],
                                  [-236.695, 152.253],
                                  [-13.898, 310.742],
                                  [144.167, 395.511],
                                  [265.59, 223.928],
                                  [25.325, -137.428],
                                  [267.371, -209.278],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.04, -41.919],
                                  [-23.84, -122.234],
                                  [51.431, -44.443],
                                  [-122.721, -35.607],
                                  [-17.37, -64.944],
                                  [-123.593, 7.292],
                                  [123.366, 78.928],
                                  [-41.305, 138.485],
                                  [-10.268, 92],
                                ],
                                o: [
                                  [-96.22, 43.683],
                                  [23.84, 122.234],
                                  [-51.431, 44.443],
                                  [122.721, 35.607],
                                  [19.008, 66.96],
                                  [110.74, -4.52],
                                  [-123.366, -78.928],
                                  [38.911, -140.627],
                                  [10.268, -92],
                                ],
                                v: [
                                  [-117.719, -393.048],
                                  [-174.057, -178.663],
                                  [-268.629, 4.606],
                                  [-236.744, 153.175],
                                  [-12.823, 310.902],
                                  [143.477, 394.94],
                                  [264.397, 223.721],
                                  [25.009, -137.377],
                                  [268.571, -208.828],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.914, -42.171],
                                  [-24.144, -122.577],
                                  [51.645, -44.232],
                                  [-123.103, -35.566],
                                  [-17.386, -65.12],
                                  [-123.303, 7.16],
                                  [123.881, 79.055],
                                  [-41.523, 138.922],
                                  [-10.211, 92],
                                ],
                                o: [
                                  [-96.314, 43.891],
                                  [24.144, 122.577],
                                  [-51.645, 44.232],
                                  [123.103, 35.567],
                                  [18.983, 67.086],
                                  [110.771, -4.457],
                                  [-123.881, -79.055],
                                  [39.188, -141.011],
                                  [10.211, -92],
                                ],
                                v: [
                                  [-117.759, -392.628],
                                  [-174.965, -178.436],
                                  [-267.852, 5.071],
                                  [-236.792, 154.052],
                                  [-11.702, 311.095],
                                  [142.72, 394.3],
                                  [263.202, 223.568],
                                  [24.719, -137.247],
                                  [269.757, -208.307],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.792, -42.417],
                                  [-24.44, -122.913],
                                  [51.854, -44.026],
                                  [-123.476, -35.527],
                                  [-17.401, -65.292],
                                  [-123.021, 7.031],
                                  [124.385, 79.179],
                                  [-41.735, 139.349],
                                  [-10.156, 92],
                                ],
                                o: [
                                  [-96.406, 44.094],
                                  [24.44, 122.913],
                                  [-51.854, 44.026],
                                  [123.476, 35.527],
                                  [18.958, 67.208],
                                  [110.802, -4.396],
                                  [-124.385, -79.179],
                                  [39.459, -141.386],
                                  [10.156, -92],
                                ],
                                v: [
                                  [-117.744, -392.182],
                                  [-175.828, -178.222],
                                  [-267.01, 5.571],
                                  [-236.894, 154.884],
                                  [-10.643, 311.222],
                                  [141.96, 393.709],
                                  [262.122, 223.42],
                                  [24.447, -137.149],
                                  [270.952, -207.811],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.671, -42.657],
                                  [-24.73, -123.241],
                                  [52.059, -43.825],
                                  [-123.84, -35.489],
                                  [-17.416, -65.46],
                                  [-122.744, 6.905],
                                  [124.877, 79.299],
                                  [-41.942, 139.767],
                                  [-10.102, 92],
                                ],
                                o: [
                                  [-96.496, 44.292],
                                  [24.73, 123.241],
                                  [-52.059, 43.825],
                                  [123.84, 35.489],
                                  [18.934, 67.329],
                                  [110.832, -4.336],
                                  [-124.876, -79.299],
                                  [39.723, -141.752],
                                  [10.102, -92],
                                ],
                                v: [
                                  [-117.782, -391.696],
                                  [-176.693, -178.074],
                                  [-266.16, 6.13],
                                  [-236.989, 155.725],
                                  [-9.594, 311.329],
                                  [141.133, 393.058],
                                  [260.986, 223.271],
                                  [24.263, -136.965],
                                  [272.078, -207.246],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.554, -42.892],
                                  [-25.013, -123.562],
                                  [52.258, -43.628],
                                  [-124.196, -35.452],
                                  [-17.431, -65.624],
                                  [-122.474, 6.782],
                                  [125.357, 79.418],
                                  [-42.144, 140.175],
                                  [-10.049, 92],
                                ],
                                o: [
                                  [-96.584, 44.486],
                                  [25.013, 123.562],
                                  [-52.258, 43.628],
                                  [124.196, 35.452],
                                  [18.911, 67.446],
                                  [110.862, -4.277],
                                  [-125.357, -79.417],
                                  [39.981, -142.11],
                                  [10.049, -92],
                                ],
                                v: [
                                  [-117.818, -391.243],
                                  [-177.575, -177.876],
                                  [-265.301, 6.734],
                                  [-237.132, 156.582],
                                  [-8.56, 311.425],
                                  [140.247, 392.456],
                                  [259.972, 223.175],
                                  [24.041, -136.807],
                                  [273.259, -206.722],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.439, -43.121],
                                  [-25.29, -123.875],
                                  [52.453, -43.436],
                                  [-124.543, -35.415],
                                  [-17.445, -65.785],
                                  [-122.211, 6.661],
                                  [125.826, 79.533],
                                  [-42.342, 140.573],
                                  [-9.998, 92],
                                ],
                                o: [
                                  [-96.67, 44.675],
                                  [25.29, 123.875],
                                  [-52.453, 43.436],
                                  [124.543, 35.415],
                                  [18.888, 67.561],
                                  [110.89, -4.22],
                                  [-125.826, -79.533],
                                  [40.233, -142.46],
                                  [9.998, -92],
                                ],
                                v: [
                                  [-117.845, -390.699],
                                  [-178.458, -177.745],
                                  [-264.378, 7.384],
                                  [-237.282, 157.335],
                                  [-7.542, 311.447],
                                  [139.357, 391.786],
                                  [258.893, 223.134],
                                  [23.843, -136.571],
                                  [274.434, -206.172],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.327, -43.345],
                                  [-25.56, -124.181],
                                  [52.644, -43.248],
                                  [-124.883, -35.379],
                                  [-17.459, -65.942],
                                  [-121.953, 6.544],
                                  [126.285, 79.646],
                                  [-42.535, 140.963],
                                  [-9.947, 92],
                                ],
                                o: [
                                  [-96.755, 44.86],
                                  [25.56, 124.181],
                                  [-52.644, 43.248],
                                  [124.883, 35.379],
                                  [18.865, 67.673],
                                  [110.918, -4.164],
                                  [-126.285, -79.646],
                                  [40.48, -142.802],
                                  [9.947, -92],
                                ],
                                v: [
                                  [-117.871, -390.189],
                                  [-179.301, -177.625],
                                  [-263.495, 8.033],
                                  [-237.427, 158.158],
                                  [-6.525, 311.521],
                                  [138.407, 391.112],
                                  [257.931, 223.099],
                                  [23.717, -136.313],
                                  [275.553, -205.554],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.218, -43.564],
                                  [-25.824, -124.48],
                                  [52.83, -43.065],
                                  [-125.214, -35.344],
                                  [-17.473, -66.095],
                                  [-121.701, 6.429],
                                  [126.733, 79.756],
                                  [-42.724, 141.343],
                                  [-9.898, 92],
                                ],
                                o: [
                                  [-96.837, 45.04],
                                  [25.824, 124.48],
                                  [-52.83, 43.065],
                                  [125.214, 35.344],
                                  [18.844, 67.782],
                                  [110.945, -4.109],
                                  [-126.733, -79.756],
                                  [40.721, -143.135],
                                  [9.898, -92],
                                ],
                                v: [
                                  [-117.943, -389.59],
                                  [-180.145, -177.517],
                                  [-262.556, 8.736],
                                  [-237.628, 158.876],
                                  [-5.475, 311.516],
                                  [137.391, 390.494],
                                  [256.975, 223.112],
                                  [23.567, -136.027],
                                  [276.721, -204.973],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.111, -43.778],
                                  [-26.082, -124.772],
                                  [53.011, -42.886],
                                  [-125.538, -35.31],
                                  [-17.486, -66.245],
                                  [-121.455, 6.317],
                                  [127.17, 79.863],
                                  [-42.908, 141.714],
                                  [-9.85, 92],
                                ],
                                o: [
                                  [-96.917, 45.217],
                                  [26.082, 124.772],
                                  [-53.011, 42.886],
                                  [125.538, 35.31],
                                  [18.822, 67.889],
                                  [110.972, -4.056],
                                  [-127.17, -79.863],
                                  [40.956, -143.461],
                                  [9.85, -92],
                                ],
                                v: [
                                  [-118.013, -389.021],
                                  [-180.94, -177.414],
                                  [-261.552, 9.431],
                                  [-237.824, 159.599],
                                  [-4.482, 311.556],
                                  [136.371, 389.81],
                                  [256.073, 223.188],
                                  [23.482, -135.776],
                                  [277.832, -204.375],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.007, -43.987],
                                  [-26.334, -125.057],
                                  [53.189, -42.711],
                                  [-125.855, -35.277],
                                  [-17.499, -66.391],
                                  [-121.215, 6.207],
                                  [127.598, 79.968],
                                  [-43.088, 142.077],
                                  [-9.803, 92],
                                ],
                                o: [
                                  [-96.995, 45.389],
                                  [26.334, 125.057],
                                  [-53.189, 42.711],
                                  [125.855, 35.277],
                                  [18.801, 67.993],
                                  [110.998, -4.003],
                                  [-127.598, -79.968],
                                  [41.185, -143.78],
                                  [9.803, -92],
                                ],
                                v: [
                                  [-118.081, -388.49],
                                  [-181.8, -177.328],
                                  [-260.591, 10.181],
                                  [-238.022, 160.328],
                                  [-3.512, 311.531],
                                  [135.291, 389.176],
                                  [255.232, 223.263],
                                  [23.37, -135.441],
                                  [278.93, -203.816],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.905, -44.191],
                                  [-26.58, -125.335],
                                  [53.362, -42.54],
                                  [-126.164, -35.245],
                                  [-17.512, -66.533],
                                  [-120.981, 6.1],
                                  [128.015, 80.071],
                                  [-43.264, 142.431],
                                  [-9.757, 92],
                                ],
                                o: [
                                  [-97.071, 45.557],
                                  [26.58, 125.335],
                                  [-53.362, 42.54],
                                  [126.164, 35.245],
                                  [18.781, 68.095],
                                  [111.024, -3.952],
                                  [-128.015, -80.071],
                                  [41.41, -144.091],
                                  [9.757, -92],
                                ],
                                v: [
                                  [-118.197, -387.873],
                                  [-182.553, -177.246],
                                  [-259.629, 10.98],
                                  [-238.277, 161.013],
                                  [-2.549, 311.497],
                                  [134.206, 388.482],
                                  [254.39, 223.332],
                                  [23.274, -135.137],
                                  [280.027, -203.191],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.805, -44.39],
                                  [-26.82, -125.607],
                                  [53.531, -42.374],
                                  [-126.466, -35.213],
                                  [-17.524, -66.673],
                                  [-120.752, 5.995],
                                  [128.423, 80.171],
                                  [-43.436, 142.777],
                                  [-9.712, 92],
                                ],
                                o: [
                                  [-97.146, 45.721],
                                  [26.82, 125.607],
                                  [-53.531, 42.374],
                                  [126.466, 35.213],
                                  [18.761, 68.195],
                                  [111.049, -3.903],
                                  [-128.423, -80.171],
                                  [41.629, -144.394],
                                  [9.712, -92],
                                ],
                                v: [
                                  [-118.311, -387.297],
                                  [-183.37, -177.175],
                                  [-258.597, 11.785],
                                  [-238.528, 161.645],
                                  [-1.545, 311.442],
                                  [133.056, 387.835],
                                  [253.604, 223.463],
                                  [23.199, -134.756],
                                  [281.122, -202.551],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.708, -44.584],
                                  [-27.055, -125.873],
                                  [53.697, -42.211],
                                  [-126.76, -35.182],
                                  [-17.536, -66.809],
                                  [-120.528, 5.893],
                                  [128.821, 80.269],
                                  [-43.604, 143.115],
                                  [-9.669, 92],
                                ],
                                o: [
                                  [-97.219, 45.882],
                                  [27.055, 125.873],
                                  [-53.697, 42.211],
                                  [126.76, 35.182],
                                  [18.742, 68.292],
                                  [111.073, -3.854],
                                  [-128.821, -80.269],
                                  [41.842, -144.691],
                                  [9.669, -92],
                                ],
                                v: [
                                  [-118.416, -386.693],
                                  [-184.073, -177.114],
                                  [-257.614, 12.635],
                                  [-238.787, 162.225],
                                  [-0.605, 311.385],
                                  [131.907, 387.189],
                                  [252.88, 223.595],
                                  [23.202, -134.406],
                                  [282.205, -201.953],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.613, -44.774],
                                  [-27.284, -126.132],
                                  [53.858, -42.052],
                                  [-127.048, -35.152],
                                  [-17.548, -66.942],
                                  [-120.31, 5.794],
                                  [129.209, 80.364],
                                  [-43.768, 143.445],
                                  [-9.626, 92],
                                ],
                                o: [
                                  [-97.29, 46.039],
                                  [27.284, 126.132],
                                  [-53.858, 42.052],
                                  [127.048, 35.152],
                                  [18.723, 68.387],
                                  [111.097, -3.806],
                                  [-129.209, -80.364],
                                  [42.051, -144.98],
                                  [9.626, -92],
                                ],
                                v: [
                                  [-118.57, -386.132],
                                  [-184.846, -177.062],
                                  [-256.575, 13.547],
                                  [-239.041, 162.814],
                                  [0.314, 311.37],
                                  [130.691, 386.595],
                                  [252.218, 223.777],
                                  [23.164, -133.981],
                                  [283.286, -201.347],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.52, -44.959],
                                  [-27.507, -126.385],
                                  [54.016, -41.896],
                                  [-127.329, -35.122],
                                  [-17.56, -67.072],
                                  [-120.097, 5.696],
                                  [129.589, 80.458],
                                  [-43.928, 143.767],
                                  [-9.584, 92],
                                ],
                                o: [
                                  [-97.36, 46.192],
                                  [27.507, 126.385],
                                  [-54.016, 41.896],
                                  [127.329, 35.122],
                                  [18.704, 68.48],
                                  [111.12, -3.76],
                                  [-129.589, -80.458],
                                  [42.255, -145.263],
                                  [9.584, -92],
                                ],
                                v: [
                                  [-118.721, -385.551],
                                  [-185.504, -177.021],
                                  [-255.579, 14.454],
                                  [-239.349, 163.406],
                                  [1.226, 311.286],
                                  [129.472, 385.947],
                                  [251.551, 224.018],
                                  [23.146, -133.589],
                                  [284.303, -200.728],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.43, -45.141],
                                  [-27.726, -126.633],
                                  [54.169, -41.745],
                                  [-127.604, -35.093],
                                  [-17.571, -67.198],
                                  [-119.888, 5.601],
                                  [129.959, 80.549],
                                  [-44.084, 144.082],
                                  [-9.543, 92],
                                ],
                                o: [
                                  [-97.428, 46.341],
                                  [27.726, 126.632],
                                  [-54.169, 41.745],
                                  [127.604, 35.093],
                                  [18.686, 68.57],
                                  [111.143, -3.715],
                                  [-129.959, -80.549],
                                  [42.455, -145.539],
                                  [9.543, -92],
                                ],
                                v: [
                                  [-118.922, -384.957],
                                  [-186.225, -176.988],
                                  [-254.578, 15.419],
                                  [-239.665, 163.887],
                                  [2.181, 311.194],
                                  [128.25, 385.346],
                                  [250.945, 224.265],
                                  [23.143, -133.173],
                                  [285.374, -200.101],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.341, -45.317],
                                  [-27.939, -126.874],
                                  [54.32, -41.597],
                                  [-127.871, -35.065],
                                  [-17.582, -67.322],
                                  [-119.685, 5.508],
                                  [130.321, 80.638],
                                  [-44.236, 144.389],
                                  [-9.504, 92],
                                ],
                                o: [
                                  [-97.494, 46.487],
                                  [27.939, 126.874],
                                  [-54.32, 41.597],
                                  [127.871, 35.065],
                                  [18.668, 68.659],
                                  [111.165, -3.671],
                                  [-130.321, -80.638],
                                  [42.649, -145.809],
                                  [9.504, -92],
                                ],
                                v: [
                                  [-119.116, -384.397],
                                  [-186.836, -176.908],
                                  [-253.571, 16.384],
                                  [-239.92, 164.433],
                                  [3.066, 311.091],
                                  [126.966, 384.685],
                                  [250.403, 224.508],
                                  [23.159, -132.734],
                                  [286.382, -199.456],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.255, -45.49],
                                  [-28.147, -127.11],
                                  [54.466, -41.452],
                                  [-128.133, -35.038],
                                  [-17.593, -67.443],
                                  [-119.487, 5.418],
                                  [130.675, 80.725],
                                  [-44.385, 144.689],
                                  [-9.465, 92],
                                ],
                                o: [
                                  [-97.559, 46.629],
                                  [28.147, 127.11],
                                  [-54.466, 41.452],
                                  [128.133, 35.038],
                                  [18.651, 68.745],
                                  [111.186, -3.628],
                                  [-130.675, -80.725],
                                  [42.839, -146.072],
                                  [9.465, -92],
                                ],
                                v: [
                                  [-119.307, -383.882],
                                  [-187.447, -176.893],
                                  [-252.559, 17.402],
                                  [-240.234, 164.866],
                                  [3.938, 310.98],
                                  [125.673, 384.139],
                                  [249.918, 224.81],
                                  [23.138, -132.277],
                                  [287.382, -198.868],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.171, -45.658],
                                  [-28.35, -127.34],
                                  [54.61, -41.311],
                                  [-128.388, -35.011],
                                  [-17.604, -67.561],
                                  [-119.293, 5.329],
                                  [131.019, 80.809],
                                  [-44.53, 144.981],
                                  [-9.427, 92],
                                ],
                                o: [
                                  [-97.622, 46.768],
                                  [28.35, 127.34],
                                  [-54.61, 41.311],
                                  [128.388, 35.011],
                                  [18.634, 68.829],
                                  [111.207, -3.585],
                                  [-131.019, -80.809],
                                  [43.024, -146.329],
                                  [9.427, -92],
                                ],
                                v: [
                                  [-119.549, -383.294],
                                  [-188.068, -176.829],
                                  [-251.546, 18.421],
                                  [-240.551, 165.301],
                                  [4.797, 310.916],
                                  [124.325, 383.533],
                                  [249.491, 225.107],
                                  [23.187, -131.799],
                                  [288.374, -198.263],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.089, -45.823],
                                  [-28.549, -127.564],
                                  [54.749, -41.173],
                                  [-128.638, -34.984],
                                  [-17.614, -67.676],
                                  [-119.104, 5.243],
                                  [131.356, 80.892],
                                  [-44.672, 145.267],
                                  [-9.39, 92],
                                ],
                                o: [
                                  [-97.684, 46.904],
                                  [28.549, 127.564],
                                  [-54.749, 41.173],
                                  [128.638, 34.984],
                                  [18.618, 68.911],
                                  [111.228, -3.544],
                                  [-131.356, -80.892],
                                  [43.205, -146.58],
                                  [9.39, -92],
                                ],
                                v: [
                                  [-119.783, -382.811],
                                  [-188.572, -176.832],
                                  [-250.581, 19.498],
                                  [-240.928, 165.685],
                                  [5.644, 310.788],
                                  [123.03, 382.923],
                                  [249.064, 225.411],
                                  [23.197, -131.358],
                                  [289.302, -197.653],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.008, -45.983],
                                  [-28.743, -127.784],
                                  [54.886, -41.039],
                                  [-128.881, -34.959],
                                  [-17.624, -67.788],
                                  [-118.919, 5.159],
                                  [131.685, 80.973],
                                  [-44.811, 145.546],
                                  [-9.354, 92],
                                ],
                                o: [
                                  [-97.744, 47.036],
                                  [28.743, 127.784],
                                  [-54.886, 41.039],
                                  [128.881, 34.959],
                                  [18.602, 68.992],
                                  [111.248, -3.504],
                                  [-131.685, -80.973],
                                  [43.382, -146.825],
                                  [9.354, -92],
                                ],
                                v: [
                                  [-120.074, -382.308],
                                  [-189.086, -176.784],
                                  [-249.553, 20.577],
                                  [-241.244, 166.07],
                                  [6.479, 310.708],
                                  [121.674, 382.372],
                                  [248.759, 225.77],
                                  [23.226, -130.842],
                                  [290.285, -197.037],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.93, -46.14],
                                  [-28.932, -127.998],
                                  [55.019, -40.908],
                                  [-129.119, -34.934],
                                  [-17.634, -67.898],
                                  [-118.739, 5.076],
                                  [132.006, 81.052],
                                  [-44.946, 145.819],
                                  [-9.318, 92],
                                ],
                                o: [
                                  [-97.803, 47.166],
                                  [28.932, 127.998],
                                  [-55.019, 40.908],
                                  [129.119, 34.934],
                                  [18.586, 69.07],
                                  [111.268, -3.465],
                                  [-132.006, -81.052],
                                  [43.554, -147.064],
                                  [9.318, -92],
                                ],
                                v: [
                                  [-120.357, -381.8],
                                  [-189.598, -176.686],
                                  [-248.636, 21.656],
                                  [-241.567, 166.402],
                                  [7.302, 310.623],
                                  [120.31, 381.814],
                                  [248.455, 226.13],
                                  [23.325, -130.36],
                                  [291.202, -196.469],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.853, -46.293],
                                  [-29.116, -128.207],
                                  [55.149, -40.78],
                                  [-129.351, -34.91],
                                  [-17.643, -68.005],
                                  [-118.563, 4.996],
                                  [132.319, 81.129],
                                  [-45.078, 146.084],
                                  [-9.284, 92],
                                ],
                                o: [
                                  [-97.86, 47.292],
                                  [29.116, 128.207],
                                  [-55.149, 40.78],
                                  [129.351, 34.91],
                                  [18.571, 69.147],
                                  [111.287, -3.427],
                                  [-132.319, -81.129],
                                  [43.722, -147.297],
                                  [9.284, -92],
                                ],
                                v: [
                                  [-120.688, -381.332],
                                  [-190.055, -176.653],
                                  [-247.656, 22.79],
                                  [-241.887, 166.677],
                                  [8.109, 310.528],
                                  [118.889, 381.319],
                                  [248.268, 226.545],
                                  [23.383, -129.8],
                                  [292.113, -195.896],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.779, -46.442],
                                  [-29.296, -128.41],
                                  [55.276, -40.654],
                                  [-129.577, -34.886],
                                  [-17.653, -68.11],
                                  [-118.391, 4.918],
                                  [132.624, 81.204],
                                  [-45.207, 146.344],
                                  [-9.25, 92],
                                ],
                                o: [
                                  [-97.916, 47.415],
                                  [29.296, 128.41],
                                  [-55.276, 40.654],
                                  [129.577, 34.886],
                                  [18.556, 69.221],
                                  [111.305, -3.389],
                                  [-132.624, -81.204],
                                  [43.887, -147.525],
                                  [9.25, -92],
                                ],
                                v: [
                                  [-121.021, -380.915],
                                  [-190.454, -176.628],
                                  [-246.731, 23.925],
                                  [-242.21, 166.952],
                                  [8.91, 310.425],
                                  [117.523, 380.816],
                                  [248.087, 226.967],
                                  [23.396, -129.284],
                                  [292.958, -195.315],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.706, -46.588],
                                  [-29.472, -128.609],
                                  [55.4, -40.532],
                                  [-129.798, -34.862],
                                  [-17.662, -68.212],
                                  [-118.224, 4.841],
                                  [132.923, 81.277],
                                  [-45.332, 146.597],
                                  [-9.218, 92],
                                ],
                                o: [
                                  [-97.971, 47.535],
                                  [29.472, 128.609],
                                  [-55.4, 40.532],
                                  [129.798, 34.862],
                                  [18.541, 69.294],
                                  [111.324, -3.353],
                                  [-132.923, -81.277],
                                  [44.047, -147.747],
                                  [9.218, -92],
                                ],
                                v: [
                                  [-121.403, -380.543],
                                  [-190.801, -176.555],
                                  [-245.86, 25.119],
                                  [-242.534, 167.227],
                                  [9.695, 310.317],
                                  [116.096, 380.315],
                                  [247.962, 227.383],
                                  [23.484, -128.749],
                                  [293.854, -194.725],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.635, -46.73],
                                  [-29.644, -128.804],
                                  [55.521, -40.413],
                                  [-130.013, -34.84],
                                  [-17.671, -68.311],
                                  [-118.06, 4.767],
                                  [133.214, 81.349],
                                  [-45.455, 146.844],
                                  [-9.186, 92],
                                ],
                                o: [
                                  [-98.024, 47.653],
                                  [29.644, 128.804],
                                  [-55.521, 40.413],
                                  [130.013, 34.84],
                                  [18.527, 69.365],
                                  [111.341, -3.317],
                                  [-133.214, -81.349],
                                  [44.203, -147.964],
                                  [9.186, -92],
                                ],
                                v: [
                                  [-121.778, -380.164],
                                  [-191.146, -176.489],
                                  [-244.985, 26.315],
                                  [-242.919, 167.448],
                                  [10.47, 310.259],
                                  [114.723, 379.815],
                                  [247.901, 227.863],
                                  [23.529, -128.191],
                                  [294.688, -194.193],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.565, -46.869],
                                  [-29.811, -128.993],
                                  [55.639, -40.297],
                                  [-130.224, -34.818],
                                  [-17.679, -68.408],
                                  [-117.9, 4.694],
                                  [133.498, 81.419],
                                  [-45.575, 147.085],
                                  [-9.154, 92],
                                ],
                                o: [
                                  [-98.076, 47.767],
                                  [29.811, 128.993],
                                  [-55.639, 40.297],
                                  [130.224, 34.818],
                                  [18.513, 69.435],
                                  [111.359, -3.283],
                                  [-133.498, -81.419],
                                  [44.356, -148.175],
                                  [9.154, -92],
                                ],
                                v: [
                                  [-122.211, -379.833],
                                  [-191.436, -176.37],
                                  [-244.166, 27.511],
                                  [-243.243, 167.614],
                                  [11.234, 310.197],
                                  [113.293, 379.37],
                                  [247.958, 228.283],
                                  [23.587, -127.621],
                                  [295.512, -193.653],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.498, -47.005],
                                  [-29.974, -129.178],
                                  [55.754, -40.184],
                                  [-130.429, -34.796],
                                  [-17.688, -68.503],
                                  [-117.745, 4.623],
                                  [133.775, 81.487],
                                  [-45.691, 147.32],
                                  [-9.124, 92],
                                ],
                                o: [
                                  [-98.127, 47.879],
                                  [29.974, 129.178],
                                  [-55.754, 40.184],
                                  [130.429, 34.796],
                                  [18.5, 69.502],
                                  [111.376, -3.249],
                                  [-133.775, -81.487],
                                  [44.505, -148.382],
                                  [9.124, -92],
                                ],
                                v: [
                                  [-122.634, -379.554],
                                  [-191.669, -176.316],
                                  [-243.343, 28.709],
                                  [-243.574, 167.775],
                                  [11.926, 310.129],
                                  [111.856, 378.926],
                                  [248.022, 228.763],
                                  [23.659, -127.029],
                                  [296.273, -193.165],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.432, -47.137],
                                  [-30.134, -129.359],
                                  [55.866, -40.073],
                                  [-130.629, -34.775],
                                  [-17.696, -68.596],
                                  [-117.593, 4.553],
                                  [134.045, 81.553],
                                  [-45.805, 147.55],
                                  [-9.094, 92],
                                ],
                                o: [
                                  [-98.176, 47.988],
                                  [30.134, 129.359],
                                  [-55.866, 40.073],
                                  [130.629, 34.775],
                                  [18.486, 69.568],
                                  [111.392, -3.216],
                                  [-134.045, -81.553],
                                  [44.65, -148.583],
                                  [9.094, -92],
                                ],
                                v: [
                                  [-123.114, -379.268],
                                  [-191.905, -176.209],
                                  [-242.577, 29.962],
                                  [-243.847, 167.881],
                                  [12.667, 310.053],
                                  [110.419, 378.478],
                                  [248.143, 229.242],
                                  [23.743, -126.425],
                                  [297.087, -192.674],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.367, -47.266],
                                  [-30.289, -129.535],
                                  [55.976, -39.965],
                                  [-130.824, -34.755],
                                  [-17.704, -68.686],
                                  [-117.445, 4.486],
                                  [134.309, 81.618],
                                  [-45.917, 147.774],
                                  [-9.065, 92],
                                ],
                                o: [
                                  [-98.225, 48.094],
                                  [30.289, 129.535],
                                  [-55.976, 39.965],
                                  [130.824, 34.755],
                                  [18.473, 69.633],
                                  [111.408, -3.184],
                                  [-134.309, -81.618],
                                  [44.792, -148.78],
                                  [9.065, -92],
                                ],
                                v: [
                                  [-123.589, -379.087],
                                  [-192.083, -176.052],
                                  [-241.866, 31.217],
                                  [-244.177, 167.99],
                                  [13.34, 310.033],
                                  [108.98, 378.091],
                                  [248.328, 229.779],
                                  [23.842, -125.86],
                                  [297.834, -192.238],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.304, -47.391],
                                  [-30.441, -129.706],
                                  [56.083, -39.86],
                                  [-131.015, -34.734],
                                  [-17.712, -68.774],
                                  [-117.3, 4.419],
                                  [134.567, 81.681],
                                  [-46.025, 147.993],
                                  [-9.037, 92],
                                ],
                                o: [
                                  [-98.272, 48.198],
                                  [30.441, 129.706],
                                  [-56.083, 39.86],
                                  [131.015, 34.734],
                                  [18.461, 69.696],
                                  [111.424, -3.152],
                                  [-134.567, -81.681],
                                  [44.931, -148.972],
                                  [9.037, -92],
                                ],
                                v: [
                                  [-124.119, -378.905],
                                  [-192.201, -175.959],
                                  [-241.212, 32.476],
                                  [-244.512, 168.035],
                                  [14.059, 310.01],
                                  [107.538, 377.7],
                                  [248.634, 230.263],
                                  [23.896, -125.221],
                                  [298.579, -191.799],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.243, -47.514],
                                  [-30.589, -129.874],
                                  [56.187, -39.757],
                                  [-131.201, -34.715],
                                  [-17.72, -68.86],
                                  [-117.159, 4.355],
                                  [134.818, 81.743],
                                  [-46.131, 148.206],
                                  [-9.009, 92],
                                ],
                                o: [
                                  [-98.318, 48.299],
                                  [30.589, 129.874],
                                  [-56.187, 39.757],
                                  [131.201, 34.715],
                                  [18.449, 69.757],
                                  [111.439, -3.121],
                                  [-134.818, -81.743],
                                  [45.066, -149.159],
                                  [9.009, -92],
                                ],
                                v: [
                                  [-124.643, -378.773],
                                  [-192.264, -175.815],
                                  [-240.556, 33.733],
                                  [-244.787, 168.023],
                                  [14.71, 309.978],
                                  [106.092, 377.369],
                                  [248.942, 230.802],
                                  [23.961, -124.625],
                                  [299.372, -191.417],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.183, -47.634],
                                  [-30.733, -130.037],
                                  [56.289, -39.657],
                                  [-131.383, -34.696],
                                  [-17.727, -68.944],
                                  [-117.021, 4.292],
                                  [135.063, 81.803],
                                  [-46.234, 148.414],
                                  [-8.982, 92],
                                ],
                                o: [
                                  [-98.363, 48.398],
                                  [30.733, 130.037],
                                  [-56.289, 39.657],
                                  [131.383, 34.696],
                                  [18.437, 69.817],
                                  [111.454, -3.092],
                                  [-135.063, -81.803],
                                  [45.197, -149.342],
                                  [8.982, -92],
                                ],
                                v: [
                                  [-125.222, -378.696],
                                  [-192.325, -175.617],
                                  [-239.957, 34.991],
                                  [-245.125, 168.014],
                                  [15.349, 310.003],
                                  [104.648, 376.98],
                                  [249.312, 231.402],
                                  [24.04, -124.015],
                                  [300.162, -191.088],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.125, -47.751],
                                  [-30.874, -130.197],
                                  [56.388, -39.559],
                                  [-131.559, -34.677],
                                  [-17.734, -69.025],
                                  [-116.887, 4.231],
                                  [135.302, 81.862],
                                  [-46.335, 148.617],
                                  [-8.956, 92],
                                ],
                                o: [
                                  [-98.406, 48.494],
                                  [30.874, 130.197],
                                  [-56.388, 39.559],
                                  [131.559, 34.677],
                                  [18.425, 69.875],
                                  [111.469, -3.062],
                                  [-135.302, -81.862],
                                  [45.326, -149.52],
                                  [8.956, -92],
                                ],
                                v: [
                                  [-125.856, -378.671],
                                  [-192.329, -175.423],
                                  [-239.419, 36.249],
                                  [-245.406, 167.999],
                                  [15.98, 310.025],
                                  [103.2, 376.709],
                                  [249.744, 232.065],
                                  [24.129, -123.329],
                                  [300.942, -190.816],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.068, -47.865],
                                  [-31.012, -130.352],
                                  [56.485, -39.463],
                                  [-131.732, -34.659],
                                  [-17.742, -69.105],
                                  [-116.756, 4.171],
                                  [135.535, 81.919],
                                  [-46.433, 148.815],
                                  [-8.93, 92],
                                ],
                                o: [
                                  [-98.449, 48.588],
                                  [31.012, 130.352],
                                  [-56.485, 39.464],
                                  [131.732, 34.659],
                                  [18.414, 69.932],
                                  [111.483, -3.034],
                                  [-135.535, -81.919],
                                  [45.451, -149.693],
                                  [8.93, -92],
                                ],
                                v: [
                                  [-126.49, -378.641],
                                  [-192.27, -175.233],
                                  [-238.935, 37.568],
                                  [-245.685, 167.928],
                                  [16.543, 310.043],
                                  [101.809, 376.379],
                                  [250.179, 232.727],
                                  [24.171, -122.688],
                                  [301.719, -190.543],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.012, -47.976],
                                  [-31.146, -130.504],
                                  [56.579, -39.37],
                                  [-131.9, -34.641],
                                  [-17.748, -69.183],
                                  [-116.628, 4.113],
                                  [135.763, 81.975],
                                  [-46.529, 149.008],
                                  [-8.906, 92],
                                ],
                                o: [
                                  [-98.491, 48.68],
                                  [31.146, 130.504],
                                  [-56.579, 39.37],
                                  [131.901, 34.641],
                                  [18.402, 69.988],
                                  [111.497, -3.006],
                                  [-135.763, -81.975],
                                  [45.573, -149.863],
                                  [8.905, -92],
                                ],
                                v: [
                                  [-127.175, -378.724],
                                  [-192.216, -174.993],
                                  [-238.509, 38.828],
                                  [-245.968, 167.798],
                                  [17.036, 309.996],
                                  [100.36, 376.106],
                                  [250.673, 233.508],
                                  [24.227, -122.093],
                                  [302.549, -190.326],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.958, -48.084],
                                  [-31.276, -130.652],
                                  [56.671, -39.28],
                                  [-132.065, -34.624],
                                  [-17.755, -69.259],
                                  [-116.503, 4.056],
                                  [135.984, 82.03],
                                  [-46.622, 149.196],
                                  [-8.881, 92],
                                ],
                                o: [
                                  [-98.532, 48.769],
                                  [31.276, 130.652],
                                  [-56.671, 39.28],
                                  [132.065, 34.624],
                                  [18.392, 70.042],
                                  [111.51, -2.979],
                                  [-135.984, -82.03],
                                  [45.692, -150.028],
                                  [8.881, -92],
                                ],
                                v: [
                                  [-127.859, -378.866],
                                  [-192.1, -174.754],
                                  [-238.142, 40.089],
                                  [-246.194, 167.663],
                                  [17.461, 309.948],
                                  [98.967, 375.832],
                                  [251.173, 234.293],
                                  [24.292, -121.424],
                                  [303.316, -190.165],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.905, -48.19],
                                  [-31.404, -130.796],
                                  [56.761, -39.191],
                                  [-132.225, -34.607],
                                  [-17.762, -69.333],
                                  [-116.382, 4],
                                  [136.201, 82.083],
                                  [-46.714, 149.379],
                                  [-8.857, 92],
                                ],
                                o: [
                                  [-98.571, 48.856],
                                  [31.404, 130.796],
                                  [-56.761, 39.191],
                                  [132.225, 34.607],
                                  [18.381, 70.095],
                                  [111.524, -2.953],
                                  [-136.201, -82.083],
                                  [45.809, -150.189],
                                  [8.857, -92],
                                ],
                                v: [
                                  [-128.597, -379.061],
                                  [-191.928, -174.522],
                                  [-237.774, 41.347],
                                  [-246.476, 167.529],
                                  [17.816, 309.836],
                                  [97.574, 375.618],
                                  [251.796, 235.14],
                                  [24.369, -120.742],
                                  [304.135, -190.064],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.854, -48.293],
                                  [-31.528, -130.937],
                                  [56.849, -39.105],
                                  [-132.381, -34.591],
                                  [-17.768, -69.405],
                                  [-116.263, 3.946],
                                  [136.412, 82.135],
                                  [-46.802, 149.559],
                                  [-8.834, 92],
                                ],
                                o: [
                                  [-98.61, 48.941],
                                  [31.528, 130.937],
                                  [-56.849, 39.105],
                                  [132.381, 34.591],
                                  [18.371, 70.146],
                                  [111.537, -2.927],
                                  [-136.412, -82.135],
                                  [45.922, -150.346],
                                  [8.834, -92],
                                ],
                                v: [
                                  [-129.333, -379.251],
                                  [-191.694, -174.235],
                                  [-237.408, 42.609],
                                  [-246.704, 167.337],
                                  [18.107, 309.662],
                                  [96.18, 375.405],
                                  [252.359, 236.045],
                                  [24.458, -120.107],
                                  [304.947, -189.96],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.803, -48.393],
                                  [-31.649, -131.074],
                                  [56.934, -39.021],
                                  [-132.533, -34.575],
                                  [-17.775, -69.475],
                                  [-116.148, 3.894],
                                  [136.617, 82.185],
                                  [-46.889, 149.733],
                                  [-8.812, 92],
                                ],
                                o: [
                                  [-98.647, 49.024],
                                  [31.649, 131.074],
                                  [-56.934, 39.021],
                                  [132.533, 34.575],
                                  [18.361, 70.197],
                                  [111.549, -2.902],
                                  [-136.617, -82.185],
                                  [46.032, -150.5],
                                  [8.812, -92],
                                ],
                                v: [
                                  [-130.126, -379.501],
                                  [-191.46, -173.952],
                                  [-237.098, 43.811],
                                  [-246.934, 167.14],
                                  [18.267, 309.484],
                                  [94.785, 375.25],
                                  [252.984, 236.954],
                                  [24.497, -119.399],
                                  [305.697, -189.857],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.754, -48.491],
                                  [-31.767, -131.208],
                                  [57.017, -38.939],
                                  [-132.682, -34.559],
                                  [-17.781, -69.544],
                                  [-116.035, 3.842],
                                  [136.818, 82.235],
                                  [-46.974, 149.903],
                                  [-8.789, 92],
                                ],
                                o: [
                                  [-98.684, 49.105],
                                  [31.767, 131.208],
                                  [-57.017, 38.939],
                                  [132.682, 34.559],
                                  [18.351, 70.246],
                                  [111.561, -2.877],
                                  [-136.818, -82.235],
                                  [46.14, -150.649],
                                  [8.789, -92],
                                ],
                                v: [
                                  [-130.913, -379.745],
                                  [-191.167, -173.673],
                                  [-236.85, 45.014],
                                  [-247.163, 166.884],
                                  [18.42, 309.244],
                                  [93.448, 375.096],
                                  [253.673, 237.866],
                                  [24.546, -118.74],
                                  [306.501, -189.87],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.707, -48.587],
                                  [-31.883, -131.339],
                                  [57.099, -38.859],
                                  [-132.827, -34.544],
                                  [-17.787, -69.611],
                                  [-115.925, 3.792],
                                  [137.013, 82.283],
                                  [-47.056, 150.069],
                                  [-8.768, 92],
                                ],
                                o: [
                                  [-98.72, 49.184],
                                  [31.883, 131.339],
                                  [-57.099, 38.859],
                                  [132.827, 34.544],
                                  [18.341, 70.293],
                                  [111.573, -2.853],
                                  [-137.013, -82.283],
                                  [46.245, -150.795],
                                  [8.768, -92],
                                ],
                                v: [
                                  [-131.699, -379.988],
                                  [-190.871, -173.338],
                                  [-236.541, 46.156],
                                  [-247.393, 166.629],
                                  [18.506, 309.063],
                                  [92.108, 374.941],
                                  [254.305, 238.898],
                                  [24.607, -118.067],
                                  [307.299, -189.822],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.66, -48.68],
                                  [-31.995, -131.466],
                                  [57.178, -38.781],
                                  [-132.968, -34.529],
                                  [-17.793, -69.676],
                                  [-115.818, 3.743],
                                  [137.204, 82.33],
                                  [-47.136, 150.231],
                                  [-8.747, 92],
                                ],
                                o: [
                                  [-98.755, 49.261],
                                  [31.995, 131.466],
                                  [-57.178, 38.781],
                                  [132.968, 34.529],
                                  [18.332, 70.34],
                                  [111.585, -2.83],
                                  [-137.204, -82.33],
                                  [46.348, -150.937],
                                  [8.747, -92],
                                ],
                                v: [
                                  [-132.482, -380.225],
                                  [-190.518, -173.007],
                                  [-236.352, 47.239],
                                  [-247.626, 166.369],
                                  [18.521, 308.758],
                                  [90.769, 374.846],
                                  [255.057, 239.873],
                                  [24.619, -117.384],
                                  [308.152, -189.895],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.615, -48.771],
                                  [-32.105, -131.59],
                                  [57.255, -38.705],
                                  [-133.105, -34.515],
                                  [-17.798, -69.739],
                                  [-115.714, 3.695],
                                  [137.39, 82.375],
                                  [-47.215, 150.389],
                                  [-8.727, 92],
                                ],
                                o: [
                                  [-98.789, 49.336],
                                  [32.105, 131.59],
                                  [-57.255, 38.705],
                                  [133.105, 34.515],
                                  [18.323, 70.385],
                                  [111.596, -2.807],
                                  [-137.39, -82.375],
                                  [46.448, -151.075],
                                  [8.727, -92],
                                ],
                                v: [
                                  [-133.259, -380.401],
                                  [-190.103, -172.621],
                                  [-236.105, 48.324],
                                  [-247.8, 166.049],
                                  [18.412, 308.511],
                                  [89.431, 374.75],
                                  [255.753, 240.911],
                                  [24.701, -116.689],
                                  [308.94, -189.966],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.57, -48.859],
                                  [-32.211, -131.711],
                                  [57.33, -38.63],
                                  [-133.24, -34.501],
                                  [-17.804, -69.801],
                                  [-115.612, 3.649],
                                  [137.571, 82.42],
                                  [-47.291, 150.543],
                                  [-8.707, 92],
                                ],
                                o: [
                                  [-98.822, 49.409],
                                  [32.211, 131.711],
                                  [-57.33, 38.63],
                                  [133.24, 34.501],
                                  [18.314, 70.43],
                                  [111.607, -2.785],
                                  [-137.571, -82.42],
                                  [46.545, -151.21],
                                  [8.707, -92],
                                ],
                                v: [
                                  [-134.093, -380.634],
                                  [-189.687, -172.296],
                                  [-235.917, 49.406],
                                  [-247.975, 165.727],
                                  [18.292, 308.203],
                                  [88.15, 374.714],
                                  [256.512, 241.95],
                                  [24.735, -115.984],
                                  [309.722, -190.035],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.527, -48.945],
                                  [-32.315, -131.829],
                                  [57.404, -38.558],
                                  [-133.37, -34.487],
                                  [-17.809, -69.862],
                                  [-115.513, 3.604],
                                  [137.748, 82.463],
                                  [-47.365, 150.693],
                                  [-8.687, 92],
                                ],
                                o: [
                                  [-98.855, 49.48],
                                  [32.315, 131.829],
                                  [-57.404, 38.558],
                                  [133.37, 34.487],
                                  [18.306, 70.473],
                                  [111.618, -2.764],
                                  [-137.748, -82.463],
                                  [46.64, -151.342],
                                  [8.687, -92],
                                ],
                                v: [
                                  [-134.867, -380.806],
                                  [-189.211, -171.916],
                                  [-235.787, 50.367],
                                  [-248.149, 165.403],
                                  [18.105, 307.831],
                                  [86.93, 374.675],
                                  [257.274, 243.053],
                                  [24.719, -115.331],
                                  [310.499, -190.166],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.485, -49.03],
                                  [-32.417, -131.944],
                                  [57.475, -38.488],
                                  [-133.498, -34.473],
                                  [-17.814, -69.921],
                                  [-115.416, 3.56],
                                  [137.92, 82.506],
                                  [-47.438, 150.839],
                                  [-8.668, 92],
                                ],
                                o: [
                                  [-98.886, 49.549],
                                  [32.417, 131.944],
                                  [-57.475, 38.488],
                                  [133.498, 34.473],
                                  [18.297, 70.515],
                                  [111.629, -2.743],
                                  [-137.92, -82.505],
                                  [46.733, -151.47],
                                  [8.668, -92],
                                ],
                                v: [
                                  [-135.635, -381.035],
                                  [-188.735, -171.479],
                                  [-235.661, 51.331],
                                  [-248.265, 165.079],
                                  [17.849, 307.517],
                                  [85.647, 374.636],
                                  [258.097, 244.157],
                                  [24.773, -114.666],
                                  [311.332, -190.354],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.444, -49.112],
                                  [-32.516, -132.055],
                                  [57.545, -38.419],
                                  [-133.622, -34.46],
                                  [-17.819, -69.978],
                                  [-115.322, 3.516],
                                  [138.088, 82.547],
                                  [-47.509, 150.981],
                                  [-8.65, 92],
                                ],
                                o: [
                                  [-98.917, 49.617],
                                  [32.516, 132.055],
                                  [-57.545, 38.419],
                                  [133.622, 34.46],
                                  [18.289, 70.556],
                                  [111.639, -2.722],
                                  [-138.088, -82.547],
                                  [46.823, -151.595],
                                  [8.65, -92],
                                ],
                                v: [
                                  [-136.401, -381.2],
                                  [-188.199, -171.045],
                                  [-235.533, 52.29],
                                  [-248.438, 164.693],
                                  [17.588, 307.142],
                                  [84.485, 374.656],
                                  [258.863, 245.266],
                                  [24.776, -113.932],
                                  [312.099, -190.544],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.404, -49.191],
                                  [-32.612, -132.165],
                                  [57.613, -38.352],
                                  [-133.743, -34.448],
                                  [-17.824, -70.034],
                                  [-115.23, 3.474],
                                  [138.251, 82.587],
                                  [-47.578, 151.12],
                                  [-8.632, 92],
                                ],
                                o: [
                                  [-98.947, 49.683],
                                  [32.612, 132.165],
                                  [-57.613, 38.352],
                                  [133.743, 34.448],
                                  [18.281, 70.596],
                                  [111.649, -2.702],
                                  [-138.251, -82.587],
                                  [46.911, -151.717],
                                  [8.632, -92],
                                ],
                                v: [
                                  [-137.161, -381.363],
                                  [-187.662, -170.613],
                                  [-235.464, 53.192],
                                  [-248.549, 164.302],
                                  [17.197, 306.763],
                                  [83.261, 374.673],
                                  [259.693, 246.375],
                                  [24.787, -113.248],
                                  [312.925, -190.734],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.365, -49.269],
                                  [-32.706, -132.271],
                                  [57.679, -38.287],
                                  [-133.861, -34.435],
                                  [-17.829, -70.089],
                                  [-115.14, 3.434],
                                  [138.411, 82.626],
                                  [-47.645, 151.256],
                                  [-8.614, 92],
                                ],
                                o: [
                                  [-98.976, 49.747],
                                  [32.706, 132.271],
                                  [-57.679, 38.287],
                                  [133.861, 34.435],
                                  [18.273, 70.635],
                                  [111.659, -2.683],
                                  [-138.411, -82.626],
                                  [46.996, -151.836],
                                  [8.614, -92],
                                ],
                                v: [
                                  [-137.921, -381.524],
                                  [-187.122, -170.184],
                                  [-235.398, 54.031],
                                  [-248.661, 163.912],
                                  [16.799, 306.383],
                                  [82.096, 374.748],
                                  [260.523, 247.487],
                                  [24.809, -112.617],
                                  [313.683, -190.983],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.327, -49.345],
                                  [-32.798, -132.375],
                                  [57.743, -38.223],
                                  [-133.976, -34.423],
                                  [-17.834, -70.142],
                                  [-115.053, 3.394],
                                  [138.566, 82.664],
                                  [-47.71, 151.387],
                                  [-8.597, 92],
                                ],
                                o: [
                                  [-99.004, 49.81],
                                  [32.798, 132.375],
                                  [-57.743, 38.223],
                                  [133.976, 34.423],
                                  [18.265, 70.673],
                                  [111.668, -2.664],
                                  [-138.566, -82.664],
                                  [47.08, -151.951],
                                  [8.597, -92],
                                ],
                                v: [
                                  [-138.616, -381.681],
                                  [-186.461, -169.754],
                                  [-235.391, 54.87],
                                  [-248.77, 163.517],
                                  [16.332, 306.001],
                                  [80.991, 374.823],
                                  [261.357, 248.601],
                                  [24.839, -111.918],
                                  [314.497, -191.292],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.29, -49.419],
                                  [-32.887, -132.476],
                                  [57.806, -38.161],
                                  [-134.088, -34.411],
                                  [-17.839, -70.193],
                                  [-114.968, 3.355],
                                  [138.717, 82.702],
                                  [-47.774, 151.516],
                                  [-8.581, 92],
                                ],
                                o: [
                                  [-99.032, 49.871],
                                  [32.887, 132.476],
                                  [-57.806, 38.161],
                                  [134.088, 34.411],
                                  [18.258, 70.71],
                                  [111.677, -2.645],
                                  [-138.717, -82.702],
                                  [47.161, -152.064],
                                  [8.581, -92],
                                ],
                                v: [
                                  [-139.371, -381.838],
                                  [-185.863, -169.266],
                                  [-235.385, 55.586],
                                  [-248.817, 163.121],
                                  [15.8, 305.558],
                                  [79.885, 374.897],
                                  [262.194, 249.78],
                                  [24.817, -111.209],
                                  [315.308, -191.601],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.254, -49.491],
                                  [-32.974, -132.574],
                                  [57.868, -38.101],
                                  [-134.198, -34.4],
                                  [-17.843, -70.244],
                                  [-114.885, 3.317],
                                  [138.865, 82.738],
                                  [-47.836, 151.641],
                                  [-8.564, 92],
                                ],
                                o: [
                                  [-99.059, 49.93],
                                  [32.974, 132.574],
                                  [-57.868, 38.101],
                                  [134.198, 34.4],
                                  [18.251, 70.746],
                                  [111.686, -2.627],
                                  [-138.865, -82.738],
                                  [47.24, -152.174],
                                  [8.564, -92],
                                ],
                                v: [
                                  [-140.06, -381.992],
                                  [-185.201, -168.781],
                                  [-235.381, 56.362],
                                  [-248.864, 162.664],
                                  [15.199, 305.174],
                                  [78.84, 375.029],
                                  [263.092, 250.9],
                                  [24.863, -110.552],
                                  [316.053, -191.911],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.219, -49.561],
                                  [-33.058, -132.67],
                                  [57.927, -38.042],
                                  [-134.304, -34.389],
                                  [-17.848, -70.293],
                                  [-114.804, 3.28],
                                  [139.009, 82.773],
                                  [-47.897, 151.763],
                                  [-8.549, 92],
                                ],
                                o: [
                                  [-99.086, 49.988],
                                  [33.059, 132.67],
                                  [-57.927, 38.042],
                                  [134.304, 34.389],
                                  [18.244, 70.781],
                                  [111.695, -2.61],
                                  [-139.009, -82.773],
                                  [47.318, -152.281],
                                  [8.549, -92],
                                ],
                                v: [
                                  [-140.747, -382.146],
                                  [-184.539, -168.295],
                                  [-235.435, 57.015],
                                  [-248.91, 162.264],
                                  [14.591, 304.729],
                                  [77.793, 375.159],
                                  [263.933, 252.023],
                                  [24.858, -109.888],
                                  [316.853, -192.281],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.185, -49.63],
                                  [-33.141, -132.763],
                                  [57.985, -37.985],
                                  [-134.408, -34.378],
                                  [-17.852, -70.341],
                                  [-114.726, 3.244],
                                  [139.149, 82.808],
                                  [-47.956, 151.882],
                                  [-8.533, 92],
                                ],
                                o: [
                                  [-99.111, 50.045],
                                  [33.141, 132.763],
                                  [-57.985, 37.985],
                                  [134.408, 34.378],
                                  [18.237, 70.815],
                                  [111.704, -2.593],
                                  [-139.149, -82.807],
                                  [47.393, -152.386],
                                  [8.533, -92],
                                ],
                                v: [
                                  [-141.369, -382.296],
                                  [-183.816, -167.81],
                                  [-235.491, 57.667],
                                  [-248.957, 161.802],
                                  [13.915, 304.34],
                                  [76.806, 375.347],
                                  [264.836, 253.148],
                                  [24.8, -109.218],
                                  [317.591, -192.65],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.152, -49.696],
                                  [-33.221, -132.854],
                                  [58.042, -37.929],
                                  [-134.509, -34.367],
                                  [-17.856, -70.388],
                                  [-114.649, 3.209],
                                  [139.285, 82.841],
                                  [-48.013, 151.998],
                                  [-8.518, 92],
                                ],
                                o: [
                                  [-99.136, 50.1],
                                  [33.221, 132.854],
                                  [-58.042, 37.929],
                                  [134.509, 34.367],
                                  [18.23, 70.848],
                                  [111.712, -2.576],
                                  [-139.285, -82.841],
                                  [47.466, -152.487],
                                  [8.518, -92],
                                ],
                                v: [
                                  [-142.05, -382.384],
                                  [-183.094, -167.267],
                                  [-235.607, 58.258],
                                  [-249.003, 161.399],
                                  [13.235, 303.891],
                                  [75.88, 375.535],
                                  [265.683, 254.275],
                                  [24.809, -108.54],
                                  [318.384, -193.08],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.119, -49.761],
                                  [-33.3, -132.943],
                                  [58.097, -37.875],
                                  [-134.607, -34.357],
                                  [-17.86, -70.433],
                                  [-114.574, 3.175],
                                  [139.418, 82.874],
                                  [-48.069, 152.11],
                                  [-8.504, 92],
                                ],
                                o: [
                                  [-99.161, 50.153],
                                  [33.3, 132.943],
                                  [-58.097, 37.875],
                                  [134.607, 34.357],
                                  [18.224, 70.881],
                                  [111.72, -2.56],
                                  [-139.418, -82.874],
                                  [47.538, -152.586],
                                  [8.504, -92],
                                ],
                                v: [
                                  [-142.667, -382.533],
                                  [-182.369, -166.784],
                                  [-235.724, 58.786],
                                  [-248.987, 160.931],
                                  [12.425, 303.441],
                                  [74.953, 375.72],
                                  [266.59, 255.404],
                                  [24.764, -107.854],
                                  [319.173, -193.511],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.088, -49.825],
                                  [-33.376, -133.029],
                                  [58.151, -37.822],
                                  [-134.703, -34.347],
                                  [-17.864, -70.477],
                                  [-114.502, 3.142],
                                  [139.547, 82.906],
                                  [-48.124, 152.22],
                                  [-8.489, 92],
                                ],
                                o: [
                                  [-99.184, 50.205],
                                  [33.376, 133.029],
                                  [-58.151, 37.822],
                                  [134.703, 34.347],
                                  [18.217, 70.912],
                                  [111.728, -2.544],
                                  [-139.547, -82.906],
                                  [47.607, -152.683],
                                  [8.489, -92],
                                ],
                                v: [
                                  [-143.22, -382.617],
                                  [-181.644, -166.242],
                                  [-235.901, 59.313],
                                  [-249.033, 160.525],
                                  [11.671, 302.989],
                                  [74.087, 375.964],
                                  [267.439, 256.536],
                                  [24.788, -107.223],
                                  [319.897, -194.002],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.057, -49.886],
                                  [-33.45, -133.113],
                                  [58.203, -37.77],
                                  [-134.796, -34.337],
                                  [-17.868, -70.52],
                                  [-114.431, 3.11],
                                  [139.673, 82.937],
                                  [-48.177, 152.327],
                                  [-8.476, 92],
                                ],
                                o: [
                                  [-99.207, 50.256],
                                  [33.45, 133.113],
                                  [-58.203, 37.77],
                                  [134.796, 34.337],
                                  [18.211, 70.943],
                                  [111.736, -2.528],
                                  [-139.673, -82.937],
                                  [47.675, -152.776],
                                  [8.476, -92],
                                ],
                                v: [
                                  [-143.832, -382.7],
                                  [-180.86, -165.701],
                                  [-236.02, 59.778],
                                  [-248.956, 160.055],
                                  [10.789, 302.598],
                                  [73.281, 376.208],
                                  [268.291, 257.611],
                                  [24.757, -106.586],
                                  [320.677, -194.495],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.027, -49.946],
                                  [-33.523, -133.195],
                                  [58.254, -37.72],
                                  [-134.887, -34.327],
                                  [-17.872, -70.562],
                                  [-114.362, 3.078],
                                  [139.796, 82.967],
                                  [-48.229, 152.432],
                                  [-8.462, 92],
                                ],
                                o: [
                                  [-99.23, 50.306],
                                  [33.523, 133.195],
                                  [-58.254, 37.72],
                                  [134.887, 34.327],
                                  [18.205, 70.973],
                                  [111.743, -2.513],
                                  [-139.796, -82.967],
                                  [47.741, -152.868],
                                  [8.462, -92],
                                ],
                                v: [
                                  [-144.32, -382.783],
                                  [-180.074, -165.159],
                                  [-236.26, 60.18],
                                  [-248.941, 159.644],
                                  [9.96, 302.144],
                                  [72.474, 376.449],
                                  [269.205, 258.686],
                                  [24.673, -105.943],
                                  [321.455, -195.046],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.998, -50.005],
                                  [-33.593, -133.275],
                                  [58.304, -37.671],
                                  [-134.976, -34.318],
                                  [-17.875, -70.603],
                                  [-114.295, 3.048],
                                  [139.916, 82.996],
                                  [-48.279, 152.533],
                                  [-8.449, 92],
                                ],
                                o: [
                                  [-99.252, 50.354],
                                  [33.593, 133.275],
                                  [-58.304, 37.671],
                                  [134.976, 34.318],
                                  [18.2, 71.002],
                                  [111.751, -2.499],
                                  [-139.916, -82.996],
                                  [47.805, -152.957],
                                  [8.449, -92],
                                ],
                                v: [
                                  [-144.865, -382.862],
                                  [-179.288, -164.619],
                                  [-236.44, 60.58],
                                  [-248.926, 159.232],
                                  [9.006, 301.69],
                                  [71.728, 376.75],
                                  [270.061, 259.764],
                                  [24.655, -105.292],
                                  [322.167, -195.6],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.969, -50.062],
                                  [-33.662, -133.353],
                                  [58.352, -37.623],
                                  [-135.062, -34.309],
                                  [-17.879, -70.643],
                                  [-114.229, 3.018],
                                  [140.032, 83.025],
                                  [-48.328, 152.632],
                                  [-8.436, 92],
                                ],
                                o: [
                                  [-99.273, 50.401],
                                  [33.662, 133.353],
                                  [-58.352, 37.624],
                                  [135.062, 34.309],
                                  [18.194, 71.031],
                                  [111.758, -2.485],
                                  [-140.032, -83.025],
                                  [47.868, -153.044],
                                  [8.436, -92],
                                ],
                                v: [
                                  [-145.346, -382.94],
                                  [-178.501, -164.081],
                                  [-236.68, 60.918],
                                  [-248.85, 158.817],
                                  [8.106, 301.233],
                                  [71.043, 377.049],
                                  [270.918, 260.845],
                                  [24.643, -104.637],
                                  [322.937, -196.213],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.942, -50.117],
                                  [-33.729, -133.428],
                                  [58.399, -37.577],
                                  [-135.146, -34.3],
                                  [-17.882, -70.682],
                                  [-114.166, 2.989],
                                  [140.146, 83.053],
                                  [-48.376, 152.728],
                                  [-8.424, 92],
                                ],
                                o: [
                                  [-99.294, 50.446],
                                  [33.729, 133.428],
                                  [-58.399, 37.577],
                                  [135.146, 34.3],
                                  [18.188, 71.058],
                                  [111.765, -2.471],
                                  [-140.146, -83.053],
                                  [47.929, -153.128],
                                  [8.424, -92],
                                ],
                                v: [
                                  [-145.825, -383.018],
                                  [-177.714, -163.543],
                                  [-236.982, 61.255],
                                  [-248.775, 158.403],
                                  [7.079, 300.838],
                                  [70.357, 377.408],
                                  [271.838, 261.865],
                                  [24.577, -104.039],
                                  [323.702, -196.827],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.915, -50.171],
                                  [-33.794, -133.502],
                                  [58.445, -37.532],
                                  [-135.228, -34.292],
                                  [-17.886, -70.72],
                                  [-114.103, 2.96],
                                  [140.256, 83.08],
                                  [-48.422, 152.822],
                                  [-8.412, 92],
                                ],
                                o: [
                                  [-99.314, 50.491],
                                  [33.794, 133.502],
                                  [-58.445, 37.532],
                                  [135.228, 34.292],
                                  [18.183, 71.085],
                                  [111.771, -2.457],
                                  [-140.256, -83.08],
                                  [47.988, -153.211],
                                  [8.412, -92],
                                ],
                                v: [
                                  [-146.241, -383.094],
                                  [-176.927, -163.004],
                                  [-237.224, 61.527],
                                  [-248.7, 157.986],
                                  [6.107, 300.381],
                                  [69.794, 377.765],
                                  [272.701, 262.889],
                                  [24.516, -103.434],
                                  [324.405, -197.442],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.888, -50.223],
                                  [-33.857, -133.574],
                                  [58.49, -37.488],
                                  [-135.307, -34.283],
                                  [-17.889, -70.756],
                                  [-114.043, 2.933],
                                  [140.364, 83.106],
                                  [-48.468, 152.913],
                                  [-8.4, 92],
                                ],
                                o: [
                                  [-99.334, 50.534],
                                  [33.857, 133.574],
                                  [-58.49, 37.488],
                                  [135.307, 34.283],
                                  [18.178, 71.112],
                                  [111.778, -2.444],
                                  [-140.363, -83.106],
                                  [48.046, -153.291],
                                  [8.4, -92],
                                ],
                                v: [
                                  [-146.593, -383.108],
                                  [-176.139, -162.467],
                                  [-237.526, 61.738],
                                  [-248.625, 157.628],
                                  [5.069, 299.923],
                                  [69.23, 378.119],
                                  [273.564, 263.853],
                                  [24.461, -102.824],
                                  [325.163, -198.178],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.863, -50.275],
                                  [-33.919, -133.644],
                                  [58.533, -37.445],
                                  [-135.385, -34.275],
                                  [-17.892, -70.792],
                                  [-113.984, 2.906],
                                  [140.468, 83.132],
                                  [-48.512, 153.002],
                                  [-8.388, 92],
                                ],
                                o: [
                                  [-99.353, 50.576],
                                  [33.919, 133.644],
                                  [-58.533, 37.445],
                                  [135.385, 34.275],
                                  [18.173, 71.137],
                                  [111.784, -2.431],
                                  [-140.468, -83.132],
                                  [48.102, -153.369],
                                  [8.388, -92],
                                ],
                                v: [
                                  [-147.002, -383.18],
                                  [-175.29, -161.93],
                                  [-237.891, 61.946],
                                  [-248.552, 157.209],
                                  [3.965, 299.525],
                                  [68.727, 378.473],
                                  [274.368, 264.819],
                                  [24.412, -102.209],
                                  [325.92, -198.853],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.838, -50.324],
                                  [-33.979, -133.712],
                                  [58.576, -37.403],
                                  [-135.46, -34.267],
                                  [-17.895, -70.827],
                                  [-113.927, 2.88],
                                  [140.57, 83.157],
                                  [-48.555, 153.089],
                                  [-8.377, 92],
                                ],
                                o: [
                                  [-99.372, 50.618],
                                  [33.979, 133.712],
                                  [-58.576, 37.403],
                                  [135.46, 34.267],
                                  [18.168, 71.162],
                                  [111.791, -2.419],
                                  [-140.57, -83.157],
                                  [48.157, -153.445],
                                  [8.377, -92],
                                ],
                                v: [
                                  [-147.288, -383.191],
                                  [-174.502, -161.333],
                                  [-238.195, 62.092],
                                  [-248.417, 156.911],
                                  [2.917, 299.066],
                                  [68.285, 378.887],
                                  [275.235, 265.788],
                                  [24.368, -101.591],
                                  [326.61, -199.59],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.814, -50.373],
                                  [-34.037, -133.778],
                                  [58.617, -37.363],
                                  [-135.534, -34.259],
                                  [-17.898, -70.861],
                                  [-113.871, 2.854],
                                  [140.669, 83.181],
                                  [-48.597, 153.173],
                                  [-8.366, 92],
                                ],
                                o: [
                                  [-99.39, 50.658],
                                  [34.037, 133.778],
                                  [-58.617, 37.363],
                                  [135.534, 34.259],
                                  [18.163, 71.186],
                                  [111.797, -2.407],
                                  [-140.669, -83.181],
                                  [48.21, -153.519],
                                  [8.366, -92],
                                ],
                                v: [
                                  [-147.571, -383.199],
                                  [-173.713, -160.859],
                                  [-238.56, 62.236],
                                  [-248.282, 156.55],
                                  [1.803, 298.668],
                                  [67.843, 379.299],
                                  [276.104, 266.696],
                                  [24.267, -101.028],
                                  [327.359, -200.387],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.79, -50.42],
                                  [-34.094, -133.842],
                                  [58.657, -37.323],
                                  [-135.605, -34.252],
                                  [-17.901, -70.894],
                                  [-113.817, 2.83],
                                  [140.766, 83.205],
                                  [-48.637, 153.255],
                                  [-8.356, 92],
                                ],
                                o: [
                                  [-99.407, 50.696],
                                  [34.094, 133.842],
                                  [-58.657, 37.323],
                                  [135.605, 34.252],
                                  [18.158, 71.21],
                                  [111.802, -2.395],
                                  [-140.766, -83.205],
                                  [48.262, -153.59],
                                  [8.355, -92],
                                ],
                                v: [
                                  [-147.851, -383.207],
                                  [-172.924, -160.326],
                                  [-238.925, 62.316],
                                  [-248.209, 156.249],
                                  [0.623, 298.207],
                                  [67.524, 379.77],
                                  [276.914, 267.545],
                                  [24.233, -100.462],
                                  [328.043, -201.246],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.767, -50.466],
                                  [-34.149, -133.905],
                                  [58.696, -37.285],
                                  [-135.675, -34.245],
                                  [-17.904, -70.926],
                                  [-113.764, 2.805],
                                  [140.86, 83.228],
                                  [-48.677, 153.334],
                                  [-8.345, 92],
                                ],
                                o: [
                                  [-99.425, 50.734],
                                  [34.149, 133.905],
                                  [-58.696, 37.285],
                                  [135.675, 34.245],
                                  [18.153, 71.233],
                                  [111.808, -2.384],
                                  [-140.86, -83.228],
                                  [48.312, -153.66],
                                  [8.345, -92],
                                ],
                                v: [
                                  [-148.067, -383.214],
                                  [-172.135, -159.791],
                                  [-239.352, 62.395],
                                  [-248.077, 155.947],
                                  [-0.5, 297.807],
                                  [67.206, 380.18],
                                  [277.724, 268.456],
                                  [24.142, -99.891],
                                  [328.786, -202.045],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.745, -50.511],
                                  [-34.203, -133.966],
                                  [58.734, -37.247],
                                  [-135.743, -34.237],
                                  [-17.907, -70.957],
                                  [-113.713, 2.782],
                                  [140.951, 83.251],
                                  [-48.715, 153.412],
                                  [-8.335, 92],
                                ],
                                o: [
                                  [-99.441, 50.771],
                                  [34.203, 133.966],
                                  [-58.734, 37.247],
                                  [135.743, 34.237],
                                  [18.149, 71.255],
                                  [111.814, -2.372],
                                  [-140.951, -83.251],
                                  [48.362, -153.729],
                                  [8.335, -92],
                                ],
                                v: [
                                  [-148.281, -383.158],
                                  [-171.407, -159.257],
                                  [-239.719, 62.411],
                                  [-247.883, 155.645],
                                  [-1.689, 297.345],
                                  [67.01, 380.649],
                                  [278.537, 269.247],
                                  [24.056, -99.378],
                                  [329.464, -202.904],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.723, -50.554],
                                  [-34.256, -134.025],
                                  [58.771, -37.211],
                                  [-135.808, -34.23],
                                  [-17.91, -70.988],
                                  [-113.663, 2.759],
                                  [141.04, 83.273],
                                  [-48.753, 153.488],
                                  [-8.325, 92],
                                ],
                                o: [
                                  [-99.458, 50.807],
                                  [34.256, 134.025],
                                  [-58.771, 37.211],
                                  [135.808, 34.23],
                                  [18.145, 71.277],
                                  [111.819, -2.361],
                                  [-141.04, -83.273],
                                  [48.409, -153.795],
                                  [8.325, -92],
                                ],
                                v: [
                                  [-148.431, -383.162],
                                  [-170.617, -158.786],
                                  [-240.147, 62.486],
                                  [-247.75, 155.404],
                                  [-2.882, 296.944],
                                  [66.813, 381.117],
                                  [279.352, 270.041],
                                  [23.975, -98.861],
                                  [330.139, -203.826],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.702, -50.596],
                                  [-34.307, -134.083],
                                  [58.807, -37.176],
                                  [-135.873, -34.224],
                                  [-17.912, -71.017],
                                  [-113.614, 2.737],
                                  [141.127, 83.294],
                                  [-48.789, 153.561],
                                  [-8.316, 92],
                                ],
                                o: [
                                  [-99.474, 50.842],
                                  [34.307, 134.083],
                                  [-58.807, 37.175],
                                  [135.873, 34.224],
                                  [18.14, 71.298],
                                  [111.825, -2.351],
                                  [-141.127, -83.294],
                                  [48.456, -153.859],
                                  [8.316, -92],
                                ],
                                v: [
                                  [-148.518, -383.104],
                                  [-169.889, -158.255],
                                  [-240.576, 62.437],
                                  [-247.62, 155.161],
                                  [-4.079, 296.543],
                                  [66.68, 381.646],
                                  [280.107, 270.834],
                                  [23.897, -98.341],
                                  [330.75, -204.747],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.681, -50.638],
                                  [-34.357, -134.139],
                                  [58.842, -37.141],
                                  [-135.935, -34.217],
                                  [-17.915, -71.046],
                                  [-113.567, 2.715],
                                  [141.211, 83.315],
                                  [-48.825, 153.633],
                                  [-8.307, 92],
                                ],
                                o: [
                                  [-99.489, 50.876],
                                  [34.357, 134.139],
                                  [-58.842, 37.141],
                                  [135.935, 34.217],
                                  [18.136, 71.319],
                                  [111.83, -2.341],
                                  [-141.211, -83.314],
                                  [48.501, -153.922],
                                  [8.307, -92],
                                ],
                                v: [
                                  [-148.603, -383.044],
                                  [-169.159, -157.785],
                                  [-241.004, 62.447],
                                  [-247.427, 154.979],
                                  [-5.343, 296.141],
                                  [66.607, 382.111],
                                  [280.862, 271.63],
                                  [23.824, -97.817],
                                  [331.358, -205.668],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.661, -50.678],
                                  [-34.405, -134.194],
                                  [58.876, -37.108],
                                  [-135.996, -34.211],
                                  [-17.917, -71.074],
                                  [-113.521, 2.694],
                                  [141.293, 83.335],
                                  [-48.859, 153.702],
                                  [-8.298, 92],
                                ],
                                o: [
                                  [-99.504, 50.909],
                                  [34.405, 134.194],
                                  [-58.876, 37.107],
                                  [135.996, 34.211],
                                  [18.132, 71.339],
                                  [111.835, -2.331],
                                  [-141.293, -83.335],
                                  [48.545, -153.983],
                                  [8.298, -92],
                                ],
                                v: [
                                  [-148.623, -382.983],
                                  [-168.492, -157.315],
                                  [-241.433, 62.394],
                                  [-247.235, 154.796],
                                  [-6.547, 295.676],
                                  [66.658, 382.636],
                                  [281.62, 272.365],
                                  [23.755, -97.291],
                                  [331.963, -206.588],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.642, -50.717],
                                  [-34.452, -134.247],
                                  [58.909, -37.075],
                                  [-136.055, -34.205],
                                  [-17.92, -71.102],
                                  [-113.476, 2.674],
                                  [141.373, 83.354],
                                  [-48.893, 153.77],
                                  [-8.289, 92],
                                ],
                                o: [
                                  [-99.519, 50.941],
                                  [34.452, 134.247],
                                  [-58.909, 37.075],
                                  [136.055, 34.205],
                                  [18.128, 71.358],
                                  [111.84, -2.321],
                                  [-141.373, -83.354],
                                  [48.588, -154.043],
                                  [8.289, -92],
                                ],
                                v: [
                                  [-148.642, -382.86],
                                  [-167.825, -156.846],
                                  [-241.924, 62.401],
                                  [-247.105, 154.676],
                                  [-7.818, 295.273],
                                  [66.709, 383.161],
                                  [282.318, 273.101],
                                  [23.69, -96.823],
                                  [332.503, -207.571],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.623, -50.755],
                                  [-34.498, -134.299],
                                  [58.941, -37.043],
                                  [-136.112, -34.198],
                                  [-17.922, -71.128],
                                  [-113.432, 2.654],
                                  [141.451, 83.373],
                                  [-48.926, 153.836],
                                  [-8.28, 92],
                                ],
                                o: [
                                  [-99.533, 50.973],
                                  [34.498, 134.299],
                                  [-58.941, 37.043],
                                  [136.112, 34.198],
                                  [18.125, 71.377],
                                  [111.844, -2.311],
                                  [-141.451, -83.373],
                                  [48.63, -154.101],
                                  [8.28, -92],
                                ],
                                v: [
                                  [-148.597, -382.796],
                                  [-167.156, -156.439],
                                  [-242.353, 62.344],
                                  [-246.914, 154.554],
                                  [-9.029, 294.869],
                                  [66.821, 383.684],
                                  [283.017, 273.838],
                                  [23.629, -96.351],
                                  [333.041, -208.553],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.604, -50.791],
                                  [-34.542, -134.35],
                                  [58.973, -37.012],
                                  [-136.168, -34.193],
                                  [-17.924, -71.154],
                                  [-113.39, 2.634],
                                  [141.526, 83.392],
                                  [-48.958, 153.9],
                                  [-8.272, 92],
                                ],
                                o: [
                                  [-99.547, 51.003],
                                  [34.542, 134.35],
                                  [-58.973, 37.012],
                                  [136.168, 34.193],
                                  [18.121, 71.396],
                                  [111.849, -2.302],
                                  [-141.526, -83.392],
                                  [48.671, -154.157],
                                  [8.272, -92],
                                ],
                                v: [
                                  [-148.487, -382.67],
                                  [-166.487, -155.971],
                                  [-242.845, 62.224],
                                  [-246.725, 154.431],
                                  [-10.307, 294.465],
                                  [66.996, 384.268],
                                  [283.718, 274.515],
                                  [23.572, -95.938],
                                  [333.576, -209.535],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.586, -50.827],
                                  [-34.585, -134.399],
                                  [59.003, -36.982],
                                  [-136.223, -34.187],
                                  [-17.927, -71.179],
                                  [-113.349, 2.616],
                                  [141.6, 83.41],
                                  [-48.989, 153.962],
                                  [-8.264, 92],
                                ],
                                o: [
                                  [-99.56, 51.033],
                                  [34.585, 134.399],
                                  [-59.003, 36.982],
                                  [136.223, 34.187],
                                  [18.117, 71.414],
                                  [111.853, -2.293],
                                  [-141.6, -83.41],
                                  [48.71, -154.212],
                                  [8.264, -92],
                                ],
                                v: [
                                  [-148.376, -382.543],
                                  [-165.88, -155.566],
                                  [-243.275, 62.041],
                                  [-246.535, 154.431],
                                  [-11.588, 294],
                                  [67.233, 384.79],
                                  [284.359, 275.194],
                                  [23.518, -95.524],
                                  [334.047, -210.517],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.569, -50.862],
                                  [-34.628, -134.446],
                                  [59.033, -36.953],
                                  [-136.276, -34.181],
                                  [-17.929, -71.204],
                                  [-113.308, 2.597],
                                  [141.671, 83.428],
                                  [-49.019, 154.023],
                                  [-8.256, 92],
                                ],
                                o: [
                                  [-99.573, 51.061],
                                  [34.628, 134.446],
                                  [-59.033, 36.953],
                                  [136.276, 34.181],
                                  [18.114, 71.431],
                                  [111.858, -2.284],
                                  [-141.671, -83.428],
                                  [48.748, -154.265],
                                  [8.256, -92],
                                ],
                                v: [
                                  [-148.201, -382.415],
                                  [-165.335, -155.223],
                                  [-243.705, 61.856],
                                  [-246.284, 154.37],
                                  [-12.934, 293.595],
                                  [67.532, 385.372],
                                  [285.001, 275.874],
                                  [23.407, -95.106],
                                  [334.515, -211.561],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.552, -50.896],
                                  [-34.669, -134.493],
                                  [59.062, -36.924],
                                  [-136.327, -34.176],
                                  [-17.931, -71.227],
                                  [-113.269, 2.579],
                                  [141.741, 83.445],
                                  [-49.048, 154.082],
                                  [-8.248, 92],
                                ],
                                o: [
                                  [-99.586, 51.089],
                                  [34.669, 134.493],
                                  [-59.062, 36.924],
                                  [136.327, 34.176],
                                  [18.11, 71.448],
                                  [111.862, -2.276],
                                  [-141.741, -83.445],
                                  [48.786, -154.317],
                                  [8.248, -92],
                                ],
                                v: [
                                  [-148.024, -382.224],
                                  [-164.789, -154.819],
                                  [-244.135, 61.607],
                                  [-246.095, 154.432],
                                  [-14.221, 293.188],
                                  [67.892, 385.952],
                                  [285.644, 276.492],
                                  [23.36, -94.685],
                                  [334.919, -212.543],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.535, -50.929],
                                  [-34.708, -134.538],
                                  [59.09, -36.897],
                                  [-136.377, -34.171],
                                  [-17.933, -71.25],
                                  [-113.231, 2.562],
                                  [141.808, 83.461],
                                  [-49.076, 154.14],
                                  [-8.241, 92],
                                ],
                                o: [
                                  [-99.598, 51.117],
                                  [34.708, 134.538],
                                  [-59.09, 36.897],
                                  [136.377, 34.171],
                                  [18.107, 71.465],
                                  [111.866, -2.268],
                                  [-141.808, -83.461],
                                  [48.822, -154.367],
                                  [8.241, -92],
                                ],
                                v: [
                                  [-147.783, -382.031],
                                  [-164.305, -154.477],
                                  [-244.566, 61.357],
                                  [-245.908, 154.493],
                                  [-15.511, 292.782],
                                  [68.315, 386.532],
                                  [286.227, 277.112],
                                  [23.317, -94.325],
                                  [335.321, -213.586],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.519, -50.961],
                                  [-34.747, -134.582],
                                  [59.117, -36.87],
                                  [-136.426, -34.166],
                                  [-17.935, -71.273],
                                  [-113.194, 2.545],
                                  [141.874, 83.477],
                                  [-49.104, 154.195],
                                  [-8.234, 92],
                                ],
                                o: [
                                  [-99.61, 51.143],
                                  [34.747, 134.582],
                                  [-59.117, 36.87],
                                  [136.426, 34.166],
                                  [18.104, 71.481],
                                  [111.87, -2.26],
                                  [-141.874, -83.477],
                                  [48.857, -154.416],
                                  [8.234, -92],
                                ],
                                v: [
                                  [-147.478, -381.899],
                                  [-163.82, -154.135],
                                  [-244.997, 61.044],
                                  [-245.72, 154.616],
                                  [-16.805, 292.376],
                                  [68.862, 387.111],
                                  [286.811, 277.734],
                                  [23.277, -93.962],
                                  [335.719, -214.629],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.504, -50.993],
                                  [-34.785, -134.624],
                                  [59.144, -36.844],
                                  [-136.473, -34.161],
                                  [-17.937, -71.295],
                                  [-113.159, 2.529],
                                  [141.938, 83.493],
                                  [-49.131, 154.25],
                                  [-8.227, 92],
                                ],
                                o: [
                                  [-99.622, 51.169],
                                  [34.785, 134.624],
                                  [-59.144, 36.844],
                                  [136.473, 34.161],
                                  [18.101, 71.496],
                                  [111.874, -2.252],
                                  [-141.938, -83.493],
                                  [48.892, -154.464],
                                  [8.227, -92],
                                ],
                                v: [
                                  [-147.172, -381.704],
                                  [-163.397, -153.856],
                                  [-245.428, 60.666],
                                  [-245.471, 154.739],
                                  [-18.101, 291.97],
                                  [69.409, 387.689],
                                  [287.334, 278.355],
                                  [23.302, -93.597],
                                  [336.054, -215.672],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.489, -51.023],
                                  [-34.821, -134.666],
                                  [59.169, -36.818],
                                  [-136.519, -34.156],
                                  [-17.939, -71.316],
                                  [-113.124, 2.513],
                                  [142, 83.508],
                                  [-49.157, 154.302],
                                  [-8.22, 92],
                                ],
                                o: [
                                  [-99.634, 51.194],
                                  [34.821, 134.666],
                                  [-59.169, 36.818],
                                  [136.519, 34.156],
                                  [18.098, 71.511],
                                  [111.878, -2.244],
                                  [-142, -83.508],
                                  [48.925, -154.51],
                                  [8.22, -92],
                                ],
                                v: [
                                  [-146.802, -381.447],
                                  [-163.036, -153.579],
                                  [-245.797, 60.288],
                                  [-245.284, 154.924],
                                  [-19.399, 291.562],
                                  [69.957, 388.389],
                                  [287.859, 278.916],
                                  [23.268, -93.291],
                                  [336.386, -216.776],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.474, -51.052],
                                  [-34.857, -134.706],
                                  [59.195, -36.794],
                                  [-136.564, -34.151],
                                  [-17.941, -71.337],
                                  [-113.09, 2.497],
                                  [142.06, 83.523],
                                  [-49.183, 154.354],
                                  [-8.213, 92],
                                ],
                                o: [
                                  [-99.645, 51.218],
                                  [34.857, 134.706],
                                  [-59.195, 36.794],
                                  [136.564, 34.151],
                                  [18.095, 71.526],
                                  [111.882, -2.237],
                                  [-142.06, -83.523],
                                  [48.958, -154.555],
                                  [8.213, -92],
                                ],
                                v: [
                                  [-146.367, -381.25],
                                  [-162.675, -153.301],
                                  [-246.166, 59.846],
                                  [-245.099, 155.108],
                                  [-20.701, 291.154],
                                  [70.629, 389.151],
                                  [288.385, 279.478],
                                  [23.299, -92.984],
                                  [336.654, -217.818],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.459, -51.081],
                                  [-34.892, -134.745],
                                  [59.219, -36.77],
                                  [-136.607, -34.146],
                                  [-17.943, -71.357],
                                  [-113.057, 2.482],
                                  [142.119, 83.538],
                                  [-49.207, 154.404],
                                  [-8.207, 92],
                                ],
                                o: [
                                  [-99.655, 51.242],
                                  [34.892, 134.745],
                                  [-59.219, 36.77],
                                  [136.607, 34.146],
                                  [18.092, 71.541],
                                  [111.885, -2.23],
                                  [-142.119, -83.538],
                                  [48.989, -154.599],
                                  [8.207, -92],
                                ],
                                v: [
                                  [-145.993, -380.99],
                                  [-162.375, -153.024],
                                  [-246.536, 59.401],
                                  [-244.912, 155.293],
                                  [-22.005, 290.746],
                                  [71.301, 389.912],
                                  [288.85, 279.979],
                                  [23.333, -92.674],
                                  [336.92, -218.86],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.445, -51.109],
                                  [-34.925, -134.783],
                                  [59.243, -36.746],
                                  [-136.65, -34.142],
                                  [-17.944, -71.376],
                                  [-113.025, 2.468],
                                  [142.176, 83.552],
                                  [-49.232, 154.452],
                                  [-8.2, 92],
                                ],
                                o: [
                                  [-99.666, 51.265],
                                  [34.925, 134.783],
                                  [-59.243, 36.746],
                                  [136.65, 34.142],
                                  [18.089, 71.555],
                                  [111.889, -2.223],
                                  [-142.176, -83.552],
                                  [49.02, -154.641],
                                  [8.201, -92],
                                ],
                                v: [
                                  [-145.493, -380.791],
                                  [-162.075, -152.809],
                                  [-246.905, 58.894],
                                  [-244.788, 155.478],
                                  [-23.25, 290.399],
                                  [72.035, 390.734],
                                  [289.316, 280.543],
                                  [23.368, -92.424],
                                  [337.184, -219.962],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.432, -51.136],
                                  [-34.958, -134.82],
                                  [59.266, -36.723],
                                  [-136.691, -34.138],
                                  [-17.946, -71.395],
                                  [-112.993, 2.454],
                                  [142.232, 83.565],
                                  [-49.255, 154.499],
                                  [-8.194, 92],
                                ],
                                o: [
                                  [-99.676, 51.287],
                                  [34.958, 134.82],
                                  [-59.266, 36.723],
                                  [136.691, 34.138],
                                  [18.086, 71.568],
                                  [111.892, -2.216],
                                  [-142.232, -83.565],
                                  [49.05, -154.683],
                                  [8.194, -92],
                                ],
                                v: [
                                  [-144.992, -380.529],
                                  [-161.837, -152.658],
                                  [-247.275, 58.385],
                                  [-244.725, 155.663],
                                  [-24.497, 289.99],
                                  [72.769, 391.617],
                                  [289.722, 281.044],
                                  [23.469, -92.112],
                                  [337.383, -221.003],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.419, -51.163],
                                  [-34.99, -134.856],
                                  [59.288, -36.701],
                                  [-136.731, -34.133],
                                  [-17.948, -71.414],
                                  [-112.963, 2.44],
                                  [142.286, 83.579],
                                  [-49.278, 154.545],
                                  [-8.188, 92],
                                ],
                                o: [
                                  [-99.686, 51.309],
                                  [34.99, 134.856],
                                  [-59.288, 36.701],
                                  [136.731, 34.133],
                                  [18.084, 71.581],
                                  [111.895, -2.209],
                                  [-142.286, -83.579],
                                  [49.079, -154.723],
                                  [8.188, -92],
                                ],
                                v: [
                                  [-144.489, -380.265],
                                  [-161.599, -152.506],
                                  [-247.583, 57.875],
                                  [-244.662, 155.848],
                                  [-25.808, 289.642],
                                  [73.566, 392.5],
                                  [290.128, 281.485],
                                  [23.511, -91.859],
                                  [337.519, -222.105],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.406, -51.188],
                                  [-35.021, -134.891],
                                  [59.31, -36.68],
                                  [-136.77, -34.129],
                                  [-17.949, -71.432],
                                  [-112.934, 2.426],
                                  [142.338, 83.591],
                                  [-49.3, 154.589],
                                  [-8.183, 92],
                                ],
                                o: [
                                  [-99.696, 51.33],
                                  [35.021, 134.891],
                                  [-59.31, 36.68],
                                  [136.77, 34.129],
                                  [18.081, 71.594],
                                  [111.898, -2.203],
                                  [-142.338, -83.591],
                                  [49.107, -154.762],
                                  [8.183, -92],
                                ],
                                v: [
                                  [-143.921, -380.063],
                                  [-161.299, -152.416],
                                  [-247.891, 57.301],
                                  [-244.662, 156.033],
                                  [-26.998, 289.293],
                                  [74.425, 393.382],
                                  [290.536, 281.927],
                                  [23.616, -91.604],
                                  [337.714, -223.145],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.393, -51.213],
                                  [-35.051, -134.925],
                                  [59.331, -36.659],
                                  [-136.807, -34.125],
                                  [-17.951, -71.449],
                                  [-112.905, 2.413],
                                  [142.389, 83.604],
                                  [-49.321, 154.633],
                                  [-8.177, 92],
                                ],
                                o: [
                                  [-99.705, 51.351],
                                  [35.051, 134.925],
                                  [-59.331, 36.659],
                                  [136.807, 34.125],
                                  [18.079, 71.607],
                                  [111.902, -2.197],
                                  [-142.389, -83.604],
                                  [49.134, -154.8],
                                  [8.177, -92],
                                ],
                                v: [
                                  [-143.353, -379.798],
                                  [-161.061, -152.327],
                                  [-248.199, 56.663],
                                  [-244.723, 156.157],
                                  [-28.251, 288.944],
                                  [75.285, 394.388],
                                  [290.882, 282.369],
                                  [23.724, -91.41],
                                  [337.785, -224.245],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.381, -51.237],
                                  [-35.08, -134.958],
                                  [59.352, -36.639],
                                  [-136.844, -34.122],
                                  [-17.952, -71.466],
                                  [-112.877, 2.4],
                                  [142.439, 83.616],
                                  [-49.342, 154.675],
                                  [-8.172, 92],
                                ],
                                o: [
                                  [-99.714, 51.371],
                                  [35.08, 134.958],
                                  [-59.352, 36.639],
                                  [136.844, 34.122],
                                  [18.076, 71.619],
                                  [111.905, -2.191],
                                  [-142.439, -83.616],
                                  [49.161, -154.837],
                                  [8.172, -92],
                                ],
                                v: [
                                  [-142.721, -379.531],
                                  [-160.762, -152.301],
                                  [-248.507, 56.086],
                                  [-244.784, 156.342],
                                  [-29.445, 288.595],
                                  [76.145, 395.332],
                                  [291.23, 282.811],
                                  [23.835, -91.214],
                                  [337.914, -225.282],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.37, -51.261],
                                  [-35.108, -134.99],
                                  [59.372, -36.619],
                                  [-136.88, -34.118],
                                  [-17.954, -71.482],
                                  [-112.85, 2.388],
                                  [142.487, 83.628],
                                  [-49.362, 154.716],
                                  [-8.166, 92],
                                ],
                                o: [
                                  [-99.723, 51.39],
                                  [35.108, 134.99],
                                  [-59.372, 36.619],
                                  [136.88, 34.118],
                                  [18.074, 71.63],
                                  [111.908, -2.185],
                                  [-142.487, -83.628],
                                  [49.187, -154.873],
                                  [8.166, -92],
                                ],
                                v: [
                                  [-142.087, -379.263],
                                  [-160.524, -152.275],
                                  [-248.815, 55.445],
                                  [-244.907, 156.465],
                                  [-30.641, 288.245],
                                  [77.068, 396.337],
                                  [291.517, 283.192],
                                  [24.01, -91.017],
                                  [337.981, -226.319],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.358, -51.284],
                                  [-35.136, -135.022],
                                  [59.391, -36.6],
                                  [-136.914, -34.114],
                                  [-17.955, -71.498],
                                  [-112.824, 2.376],
                                  [142.534, 83.64],
                                  [-49.382, 154.755],
                                  [-8.161, 92],
                                ],
                                o: [
                                  [-99.731, 51.409],
                                  [35.136, 135.022],
                                  [-59.391, 36.6],
                                  [136.914, 34.114],
                                  [18.072, 71.642],
                                  [111.91, -2.179],
                                  [-142.534, -83.64],
                                  [49.212, -154.907],
                                  [8.161, -92],
                                ],
                                v: [
                                  [-141.451, -378.995],
                                  [-160.225, -152.311],
                                  [-249.061, 54.741],
                                  [-245.03, 156.651],
                                  [-31.776, 287.895],
                                  [77.99, 397.342],
                                  [291.805, 283.573],
                                  [24.125, -90.818],
                                  [337.983, -227.416],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.347, -51.306],
                                  [-35.163, -135.052],
                                  [59.41, -36.581],
                                  [-136.948, -34.111],
                                  [-17.957, -71.514],
                                  [-112.798, 2.365],
                                  [142.579, 83.651],
                                  [-49.401, 154.794],
                                  [-8.156, 92],
                                ],
                                o: [
                                  [-99.74, 51.427],
                                  [35.163, 135.052],
                                  [-59.41, 36.581],
                                  [136.948, 34.111],
                                  [18.069, 71.653],
                                  [111.913, -2.174],
                                  [-142.579, -83.651],
                                  [49.236, -154.941],
                                  [8.156, -92],
                                ],
                                v: [
                                  [-140.752, -378.725],
                                  [-159.987, -152.41],
                                  [-249.307, 54.098],
                                  [-245.215, 156.775],
                                  [-32.914, 287.606],
                                  [78.975, 398.408],
                                  [292.094, 283.954],
                                  [24.304, -90.681],
                                  [337.984, -228.45],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.336, -51.327],
                                  [-35.189, -135.081],
                                  [59.428, -36.563],
                                  [-136.98, -34.107],
                                  [-17.958, -71.529],
                                  [-112.774, 2.353],
                                  [142.623, 83.662],
                                  [-49.42, 154.831],
                                  [-8.151, 92],
                                ],
                                o: [
                                  [-99.748, 51.445],
                                  [35.189, 135.081],
                                  [-59.428, 36.563],
                                  [136.98, 34.107],
                                  [18.067, 71.664],
                                  [111.916, -2.168],
                                  [-142.623, -83.661],
                                  [49.26, -154.974],
                                  [8.151, -92],
                                ],
                                v: [
                                  [-140.051, -378.454],
                                  [-159.75, -152.509],
                                  [-249.554, 53.391],
                                  [-245.401, 156.961],
                                  [-34.053, 287.316],
                                  [79.961, 399.475],
                                  [292.323, 284.273],
                                  [24.484, -90.541],
                                  [337.984, -229.483],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.326, -51.348],
                                  [-35.214, -135.11],
                                  [59.446, -36.546],
                                  [-137.012, -34.104],
                                  [-17.959, -71.544],
                                  [-112.749, 2.342],
                                  [142.666, 83.672],
                                  [-49.438, 154.868],
                                  [-8.147, 92],
                                ],
                                o: [
                                  [-99.756, 51.462],
                                  [35.214, 135.11],
                                  [-59.446, 36.546],
                                  [137.012, 34.104],
                                  [18.065, 71.674],
                                  [111.919, -2.163],
                                  [-142.666, -83.672],
                                  [49.283, -155.006],
                                  [8.147, -92],
                                ],
                                v: [
                                  [-139.349, -378.183],
                                  [-159.512, -152.61],
                                  [-249.8, 52.683],
                                  [-245.649, 157.085],
                                  [-35.132, 287.026],
                                  [81.009, 400.541],
                                  [292.552, 284.592],
                                  [24.729, -90.4],
                                  [337.919, -230.515],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.316, -51.369],
                                  [-35.238, -135.138],
                                  [59.463, -36.529],
                                  [-137.043, -34.101],
                                  [-17.961, -71.558],
                                  [-112.726, 2.331],
                                  [142.708, 83.682],
                                  [-49.455, 154.903],
                                  [-8.142, 92],
                                ],
                                o: [
                                  [-99.763, 51.479],
                                  [35.238, 135.138],
                                  [-59.463, 36.529],
                                  [137.043, 34.101],
                                  [18.063, 71.684],
                                  [111.921, -2.158],
                                  [-142.708, -83.682],
                                  [49.305, -155.037],
                                  [8.142, -92],
                                ],
                                v: [
                                  [-138.646, -377.972],
                                  [-159.337, -152.772],
                                  [-249.984, 51.973],
                                  [-245.958, 157.27],
                                  [-36.15, 286.736],
                                  [81.996, 401.608],
                                  [292.782, 284.911],
                                  [24.915, -90.321],
                                  [337.853, -231.485],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.306, -51.388],
                                  [-35.262, -135.165],
                                  [59.48, -36.512],
                                  [-137.073, -34.097],
                                  [-17.962, -71.572],
                                  [-112.703, 2.321],
                                  [142.748, 83.692],
                                  [-49.472, 154.937],
                                  [-8.138, 92],
                                ],
                                o: [
                                  [-99.771, 51.495],
                                  [35.262, 135.165],
                                  [-59.48, 36.512],
                                  [137.073, 34.097],
                                  [18.061, 71.694],
                                  [111.924, -2.153],
                                  [-142.748, -83.692],
                                  [49.327, -155.067],
                                  [8.138, -92],
                                ],
                                v: [
                                  [-137.879, -377.698],
                                  [-159.161, -152.997],
                                  [-250.167, 51.262],
                                  [-246.268, 157.395],
                                  [-37.171, 286.444],
                                  [83.045, 402.674],
                                  [292.951, 285.168],
                                  [25.163, -90.178],
                                  [337.723, -232.515],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.296, -51.408],
                                  [-35.285, -135.191],
                                  [59.496, -36.496],
                                  [-137.102, -34.094],
                                  [-17.963, -71.585],
                                  [-112.681, 2.311],
                                  [142.787, 83.702],
                                  [-49.489, 154.971],
                                  [-8.133, 92],
                                ],
                                o: [
                                  [-99.778, 51.511],
                                  [35.285, 135.191],
                                  [-59.496, 36.496],
                                  [137.102, 34.094],
                                  [18.059, 71.704],
                                  [111.926, -2.148],
                                  [-142.787, -83.702],
                                  [49.348, -155.097],
                                  [8.133, -92],
                                ],
                                v: [
                                  [-137.11, -377.423],
                                  [-158.985, -153.223],
                                  [-250.351, 50.488],
                                  [-246.641, 157.582],
                                  [-38.192, 286.214],
                                  [84.095, 403.74],
                                  [293.122, 285.426],
                                  [25.414, -90.158],
                                  [337.592, -233.483],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.287, -51.426],
                                  [-35.308, -135.216],
                                  [59.512, -36.481],
                                  [-137.13, -34.091],
                                  [-17.964, -71.598],
                                  [-112.66, 2.301],
                                  [142.826, 83.711],
                                  [-49.505, 155.003],
                                  [-8.129, 92],
                                ],
                                o: [
                                  [-99.785, 51.527],
                                  [35.308, 135.216],
                                  [-59.512, 36.481],
                                  [137.13, 34.091],
                                  [18.057, 71.713],
                                  [111.928, -2.143],
                                  [-142.825, -83.711],
                                  [49.369, -155.125],
                                  [8.129, -92],
                                ],
                                v: [
                                  [-136.341, -377.209],
                                  [-158.809, -153.512],
                                  [-250.473, 49.774],
                                  [-247.013, 157.768],
                                  [-39.153, 285.922],
                                  [85.145, 404.807],
                                  [293.293, 285.683],
                                  [25.667, -90.075],
                                  [337.397, -234.45],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.278, -51.444],
                                  [-35.33, -135.241],
                                  [59.528, -36.465],
                                  [-137.158, -34.089],
                                  [-17.965, -71.611],
                                  [-112.639, 2.292],
                                  [142.862, 83.72],
                                  [-49.521, 155.034],
                                  [-8.125, 92],
                                ],
                                o: [
                                  [-99.792, 51.542],
                                  [35.33, 135.241],
                                  [-59.528, 36.465],
                                  [137.158, 34.089],
                                  [18.056, 71.722],
                                  [111.93, -2.139],
                                  [-142.863, -83.72],
                                  [49.389, -155.153],
                                  [8.125, -92],
                                ],
                                v: [
                                  [-135.508, -376.994],
                                  [-158.695, -153.801],
                                  [-250.656, 49.059],
                                  [-247.448, 157.893],
                                  [-40.053, 285.69],
                                  [86.257, 405.873],
                                  [293.402, 285.94],
                                  [25.983, -90.053],
                                  [337.263, -235.416],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.269, -51.462],
                                  [-35.351, -135.265],
                                  [59.543, -36.451],
                                  [-137.184, -34.086],
                                  [-17.966, -71.623],
                                  [-112.619, 2.283],
                                  [142.898, 83.729],
                                  [-49.536, 155.065],
                                  [-8.121, 92],
                                ],
                                o: [
                                  [-99.798, 51.556],
                                  [35.351, 135.265],
                                  [-59.543, 36.451],
                                  [137.184, 34.086],
                                  [18.054, 71.731],
                                  [111.933, -2.135],
                                  [-142.898, -83.729],
                                  [49.408, -155.179],
                                  [8.121, -92],
                                ],
                                v: [
                                  [-134.736, -376.715],
                                  [-158.581, -154.153],
                                  [-250.778, 48.281],
                                  [-247.884, 158.08],
                                  [-40.892, 285.458],
                                  [87.37, 406.939],
                                  [293.513, 286.136],
                                  [26.301, -90.029],
                                  [337.003, -236.381],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.261, -51.479],
                                  [-35.371, -135.288],
                                  [59.557, -36.436],
                                  [-137.21, -34.083],
                                  [-17.967, -71.635],
                                  [-112.599, 2.274],
                                  [142.933, 83.738],
                                  [-49.551, 155.095],
                                  [-8.117, 92],
                                ],
                                o: [
                                  [-99.805, 51.57],
                                  [35.371, 135.288],
                                  [-59.557, 36.436],
                                  [137.21, 34.083],
                                  [18.052, 71.739],
                                  [111.935, -2.13],
                                  [-142.933, -83.738],
                                  [49.427, -155.205],
                                  [8.117, -92],
                                ],
                                v: [
                                  [-133.901, -376.497],
                                  [-158.466, -154.505],
                                  [-250.837, 47.563],
                                  [-248.381, 158.267],
                                  [-41.733, 285.288],
                                  [88.421, 408.005],
                                  [293.624, 286.332],
                                  [26.621, -90.005],
                                  [336.741, -237.346],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.252, -51.495],
                                  [-35.391, -135.311],
                                  [59.571, -36.423],
                                  [-137.235, -34.08],
                                  [-17.968, -71.647],
                                  [-112.58, 2.265],
                                  [142.967, 83.746],
                                  [-49.565, 155.123],
                                  [-8.114, 92],
                                ],
                                o: [
                                  [-99.811, 51.584],
                                  [35.391, 135.311],
                                  [-59.571, 36.423],
                                  [137.235, 34.08],
                                  [18.05, 71.748],
                                  [111.937, -2.126],
                                  [-142.967, -83.746],
                                  [49.445, -155.231],
                                  [8.114, -92],
                                ],
                                v: [
                                  [-133.126, -376.341],
                                  [-158.413, -154.858],
                                  [-250.958, 46.845],
                                  [-248.879, 158.392],
                                  [-42.574, 285.055],
                                  [89.535, 409.009],
                                  [293.674, 286.527],
                                  [26.943, -90.042],
                                  [336.479, -238.247],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.244, -51.512],
                                  [-35.411, -135.333],
                                  [59.585, -36.409],
                                  [-137.26, -34.078],
                                  [-17.969, -71.658],
                                  [-112.562, 2.256],
                                  [143, 83.754],
                                  [-49.579, 155.151],
                                  [-8.11, 92],
                                ],
                                o: [
                                  [-99.817, 51.597],
                                  [35.411, 135.333],
                                  [-59.585, 36.409],
                                  [137.26, 34.078],
                                  [18.049, 71.756],
                                  [111.939, -2.122],
                                  [-143, -83.754],
                                  [49.463, -155.255],
                                  [8.11, -92],
                                ],
                                v: [
                                  [-132.289, -376.121],
                                  [-158.361, -155.274],
                                  [-251.017, 46.125],
                                  [-249.377, 158.579],
                                  [-43.293, 284.884],
                                  [90.649, 410.076],
                                  [293.724, 286.66],
                                  [27.266, -90.14],
                                  [336.214, -239.148],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.236, -51.527],
                                  [-35.43, -135.354],
                                  [59.598, -36.396],
                                  [-137.283, -34.075],
                                  [-17.97, -71.669],
                                  [-112.544, 2.248],
                                  [143.032, 83.762],
                                  [-49.592, 155.178],
                                  [-8.106, 92],
                                ],
                                o: [
                                  [-99.823, 51.61],
                                  [35.43, 135.354],
                                  [-59.598, 36.396],
                                  [137.283, 34.075],
                                  [18.047, 71.764],
                                  [111.941, -2.118],
                                  [-143.032, -83.762],
                                  [49.48, -155.279],
                                  [8.106, -92],
                                ],
                                v: [
                                  [-131.45, -375.962],
                                  [-158.307, -155.691],
                                  [-251.076, 45.343],
                                  [-249.999, 158.767],
                                  [-44.012, 284.711],
                                  [91.764, 411.08],
                                  [293.775, 286.794],
                                  [27.653, -90.175],
                                  [335.886, -239.986],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.229, -51.542],
                                  [-35.448, -135.375],
                                  [59.611, -36.383],
                                  [-137.306, -34.073],
                                  [-17.971, -71.68],
                                  [-112.526, 2.24],
                                  [143.063, 83.77],
                                  [-49.605, 155.205],
                                  [-8.103, 92],
                                ],
                                o: [
                                  [-99.828, 51.622],
                                  [35.448, 135.375],
                                  [-59.611, 36.383],
                                  [137.306, 34.073],
                                  [18.046, 71.771],
                                  [111.943, -2.115],
                                  [-143.063, -83.77],
                                  [49.496, -155.302],
                                  [8.103, -92],
                                ],
                                v: [
                                  [-130.673, -375.74],
                                  [-158.317, -156.17],
                                  [-251.135, 44.683],
                                  [-250.56, 158.955],
                                  [-44.671, 284.539],
                                  [92.879, 412.084],
                                  [293.764, 286.927],
                                  [28.041, -90.272],
                                  [335.495, -240.886],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.222, -51.557],
                                  [-35.466, -135.395],
                                  [59.623, -36.371],
                                  [-137.329, -34.071],
                                  [-17.972, -71.69],
                                  [-112.51, 2.233],
                                  [143.093, 83.777],
                                  [-49.618, 155.23],
                                  [-8.1, 92],
                                ],
                                o: [
                                  [-99.834, 51.634],
                                  [35.466, 135.395],
                                  [-59.623, 36.371],
                                  [137.329, 34.071],
                                  [18.044, 71.778],
                                  [111.945, -2.111],
                                  [-143.093, -83.777],
                                  [49.513, -155.324],
                                  [8.1, -92],
                                ],
                                v: [
                                  [-129.832, -375.579],
                                  [-158.388, -156.65],
                                  [-251.132, 43.96],
                                  [-251.183, 159.081],
                                  [-45.33, 284.428],
                                  [93.994, 413.026],
                                  [293.816, 287.06],
                                  [28.43, -90.43],
                                  [335.164, -241.722],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.214, -51.571],
                                  [-35.483, -135.414],
                                  [59.636, -36.359],
                                  [-137.35, -34.068],
                                  [-17.973, -71.7],
                                  [-112.493, 2.225],
                                  [143.122, 83.784],
                                  [-49.63, 155.255],
                                  [-8.096, 92],
                                ],
                                o: [
                                  [-99.839, 51.646],
                                  [35.483, 135.414],
                                  [-59.636, 36.359],
                                  [137.35, 34.068],
                                  [18.043, 71.786],
                                  [111.946, -2.107],
                                  [-143.122, -83.784],
                                  [49.528, -155.346],
                                  [8.096, -92],
                                ],
                                v: [
                                  [-129.053, -375.479],
                                  [-158.458, -157.13],
                                  [-251.129, 43.236],
                                  [-251.869, 159.269],
                                  [-45.928, 284.316],
                                  [95.11, 414.03],
                                  [293.807, 287.194],
                                  [28.821, -90.587],
                                  [334.708, -242.558],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.207, -51.585],
                                  [-35.499, -135.433],
                                  [59.647, -36.348],
                                  [-137.371, -34.066],
                                  [-17.974, -71.71],
                                  [-112.477, 2.218],
                                  [143.151, 83.791],
                                  [-49.642, 155.279],
                                  [-8.093, 92],
                                ],
                                o: [
                                  [-99.844, 51.658],
                                  [35.499, 135.433],
                                  [-59.647, 36.348],
                                  [137.371, 34.066],
                                  [18.042, 71.793],
                                  [111.948, -2.104],
                                  [-143.151, -83.791],
                                  [49.544, -155.367],
                                  [8.093, -92],
                                ],
                                v: [
                                  [-128.211, -375.316],
                                  [-158.528, -157.673],
                                  [-251.125, 42.574],
                                  [-252.555, 159.457],
                                  [-46.403, 284.204],
                                  [96.226, 414.973],
                                  [293.735, 287.265],
                                  [29.276, -90.743],
                                  [334.313, -243.331],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.201, -51.598],
                                  [-35.516, -135.452],
                                  [59.659, -36.336],
                                  [-137.391, -34.064],
                                  [-17.975, -71.719],
                                  [-112.462, 2.211],
                                  [143.178, 83.798],
                                  [-49.654, 155.302],
                                  [-8.09, 92],
                                ],
                                o: [
                                  [-99.849, 51.669],
                                  [35.516, 135.452],
                                  [-59.659, 36.336],
                                  [137.391, 34.064],
                                  [18.04, 71.799],
                                  [111.95, -2.1],
                                  [-143.178, -83.798],
                                  [49.558, -155.388],
                                  [8.09, -92],
                                ],
                                v: [
                                  [-127.429, -375.215],
                                  [-158.661, -158.217],
                                  [-251.122, 41.91],
                                  [-253.241, 159.645],
                                  [-46.879, 284.092],
                                  [97.28, 415.853],
                                  [293.726, 287.336],
                                  [29.732, -90.961],
                                  [333.854, -244.104],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.194, -51.612],
                                  [-35.531, -135.469],
                                  [59.67, -36.325],
                                  [-137.411, -34.062],
                                  [-17.976, -71.728],
                                  [-112.447, 2.204],
                                  [143.205, 83.805],
                                  [-49.665, 155.325],
                                  [-8.087, 92],
                                ],
                                o: [
                                  [-99.854, 51.679],
                                  [35.531, 135.469],
                                  [-59.67, 36.325],
                                  [137.411, 34.062],
                                  [18.039, 71.806],
                                  [111.951, -2.097],
                                  [-143.205, -83.805],
                                  [49.573, -155.408],
                                  [8.087, -92],
                                ],
                                v: [
                                  [-126.647, -375.175],
                                  [-158.793, -158.762],
                                  [-251.057, 41.308],
                                  [-253.989, 159.772],
                                  [-47.355, 284.041],
                                  [98.397, 416.733],
                                  [293.656, 287.407],
                                  [30.189, -91.178],
                                  [333.394, -244.814],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.188, -51.624],
                                  [-35.547, -135.487],
                                  [59.681, -36.315],
                                  [-137.43, -34.06],
                                  [-17.977, -71.737],
                                  [-112.432, 2.197],
                                  [143.231, 83.811],
                                  [-49.676, 155.347],
                                  [-8.085, 92],
                                ],
                                o: [
                                  [-99.859, 51.69],
                                  [35.547, 135.487],
                                  [-59.681, 36.315],
                                  [137.43, 34.06],
                                  [18.038, 71.812],
                                  [111.953, -2.094],
                                  [-143.231, -83.811],
                                  [49.587, -155.427],
                                  [8.085, -92],
                                ],
                                v: [
                                  [-125.864, -375.072],
                                  [-158.987, -159.307],
                                  [-250.992, 40.643],
                                  [-254.738, 159.96],
                                  [-47.707, 283.927],
                                  [99.514, 417.551],
                                  [293.586, 287.478],
                                  [30.71, -91.456],
                                  [332.871, -245.585],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.182, -51.636],
                                  [-35.561, -135.503],
                                  [59.691, -36.305],
                                  [-137.449, -34.058],
                                  [-17.977, -71.745],
                                  [-112.418, 2.191],
                                  [143.256, 83.817],
                                  [-49.686, 155.368],
                                  [-8.082, 92],
                                ],
                                o: [
                                  [-99.864, 51.7],
                                  [35.561, 135.503],
                                  [-59.691, 36.305],
                                  [137.449, 34.058],
                                  [18.036, 71.818],
                                  [111.955, -2.091],
                                  [-143.256, -83.817],
                                  [49.6, -155.445],
                                  [8.082, -92],
                                ],
                                v: [
                                  [-125.08, -375.031],
                                  [-159.181, -159.915],
                                  [-250.926, 40.039],
                                  [-255.488, 160.086],
                                  [-47.998, 283.875],
                                  [100.569, 418.368],
                                  [293.516, 287.486],
                                  [31.17, -91.734],
                                  [332.346, -246.231],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.188, -51.624],
                                  [-35.547, -135.487],
                                  [59.681, -36.315],
                                  [-137.43, -34.06],
                                  [-17.977, -71.737],
                                  [-112.432, 2.197],
                                  [143.231, 83.811],
                                  [-49.676, 155.347],
                                  [-8.085, 92],
                                ],
                                o: [
                                  [-99.859, 51.69],
                                  [35.547, 135.487],
                                  [-59.68, 36.315],
                                  [137.43, 34.06],
                                  [18.038, 71.812],
                                  [111.953, -2.094],
                                  [-143.231, -83.811],
                                  [49.587, -155.427],
                                  [8.085, -92],
                                ],
                                v: [
                                  [-124.305, -374.954],
                                  [-159.432, -160.489],
                                  [-250.806, 39.458],
                                  [-256.289, 160.268],
                                  [-48.331, 283.811],
                                  [101.629, 419.099],
                                  [293.46, 287.478],
                                  [31.77, -92.014],
                                  [331.752, -246.892],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.194, -51.612],
                                  [-35.531, -135.469],
                                  [59.67, -36.325],
                                  [-137.411, -34.062],
                                  [-17.976, -71.728],
                                  [-112.447, 2.204],
                                  [143.205, 83.805],
                                  [-49.665, 155.325],
                                  [-8.087, 92],
                                ],
                                o: [
                                  [-99.854, 51.679],
                                  [35.531, 135.469],
                                  [-59.67, 36.325],
                                  [137.411, 34.062],
                                  [18.039, 71.806],
                                  [111.951, -2.097],
                                  [-143.205, -83.805],
                                  [49.573, -155.408],
                                  [8.087, -92],
                                ],
                                v: [
                                  [-123.529, -374.938],
                                  [-159.684, -161.125],
                                  [-250.749, 38.877],
                                  [-257.091, 160.388],
                                  [-48.603, 283.809],
                                  [102.688, 419.828],
                                  [293.404, 287.531],
                                  [32.372, -92.355],
                                  [331.095, -247.489],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.201, -51.598],
                                  [-35.516, -135.452],
                                  [59.659, -36.336],
                                  [-137.391, -34.064],
                                  [-17.975, -71.719],
                                  [-112.462, 2.211],
                                  [143.178, 83.798],
                                  [-49.654, 155.302],
                                  [-8.09, 92],
                                ],
                                o: [
                                  [-99.849, 51.669],
                                  [35.516, 135.452],
                                  [-59.659, 36.336],
                                  [137.391, 34.064],
                                  [18.04, 71.799],
                                  [111.95, -2.1],
                                  [-143.178, -83.798],
                                  [49.558, -155.388],
                                  [8.09, -92],
                                ],
                                v: [
                                  [-122.753, -374.922],
                                  [-159.937, -161.699],
                                  [-250.566, 38.294],
                                  [-257.892, 160.507],
                                  [-48.751, 283.806],
                                  [103.747, 420.493],
                                  [293.287, 287.521],
                                  [33.037, -92.695],
                                  [330.438, -248.024],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.207, -51.585],
                                  [-35.499, -135.433],
                                  [59.647, -36.348],
                                  [-137.371, -34.066],
                                  [-17.974, -71.71],
                                  [-112.477, 2.218],
                                  [143.151, 83.791],
                                  [-49.642, 155.279],
                                  [-8.093, 92],
                                ],
                                o: [
                                  [-99.844, 51.658],
                                  [35.499, 135.433],
                                  [-59.647, 36.348],
                                  [137.371, 34.066],
                                  [18.041, 71.793],
                                  [111.948, -2.104],
                                  [-143.151, -83.791],
                                  [49.544, -155.367],
                                  [8.093, -92],
                                ],
                                v: [
                                  [-121.976, -374.906],
                                  [-160.314, -162.335],
                                  [-250.447, 37.774],
                                  [-258.755, 160.626],
                                  [-48.899, 283.804],
                                  [104.744, 421.096],
                                  [293.17, 287.45],
                                  [33.704, -93.097],
                                  [329.779, -248.557],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.214, -51.571],
                                  [-35.483, -135.414],
                                  [59.636, -36.359],
                                  [-137.35, -34.068],
                                  [-17.973, -71.7],
                                  [-112.493, 2.225],
                                  [143.122, 83.784],
                                  [-49.63, 155.255],
                                  [-8.096, 92],
                                ],
                                o: [
                                  [-99.839, 51.646],
                                  [35.483, 135.414],
                                  [-59.636, 36.359],
                                  [137.35, 34.068],
                                  [18.043, 71.786],
                                  [111.946, -2.107],
                                  [-143.122, -83.784],
                                  [49.528, -155.346],
                                  [8.096, -92],
                                ],
                                v: [
                                  [-121.261, -374.951],
                                  [-160.629, -162.972],
                                  [-250.328, 37.253],
                                  [-259.617, 160.746],
                                  [-48.987, 283.802],
                                  [105.802, 421.697],
                                  [293.054, 287.44],
                                  [34.371, -93.497],
                                  [329.058, -249.089],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.222, -51.557],
                                  [-35.466, -135.395],
                                  [59.623, -36.371],
                                  [-137.329, -34.071],
                                  [-17.972, -71.69],
                                  [-112.509, 2.233],
                                  [143.093, 83.777],
                                  [-49.618, 155.23],
                                  [-8.1, 92],
                                ],
                                o: [
                                  [-99.834, 51.634],
                                  [35.466, 135.395],
                                  [-59.623, 36.371],
                                  [137.329, 34.071],
                                  [18.044, 71.779],
                                  [111.945, -2.111],
                                  [-143.093, -83.777],
                                  [49.513, -155.324],
                                  [8.1, -92],
                                ],
                                v: [
                                  [-120.545, -374.996],
                                  [-161.006, -163.546],
                                  [-250.146, 36.793],
                                  [-260.54, 160.802],
                                  [-49.012, 283.8],
                                  [106.797, 422.236],
                                  [292.938, 287.431],
                                  [34.978, -94.02],
                                  [328.398, -249.558],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.229, -51.542],
                                  [-35.448, -135.375],
                                  [59.611, -36.383],
                                  [-137.306, -34.073],
                                  [-17.971, -71.68],
                                  [-112.526, 2.24],
                                  [143.063, 83.77],
                                  [-49.605, 155.205],
                                  [-8.103, 92],
                                ],
                                o: [
                                  [-99.828, 51.622],
                                  [35.448, 135.375],
                                  [-59.611, 36.383],
                                  [137.306, 34.073],
                                  [18.046, 71.771],
                                  [111.943, -2.114],
                                  [-143.063, -83.77],
                                  [49.496, -155.302],
                                  [8.103, -92],
                                ],
                                v: [
                                  [-119.89, -375.103],
                                  [-161.384, -164.121],
                                  [-249.965, 36.332],
                                  [-261.401, 160.859],
                                  [-48.977, 283.86],
                                  [107.73, 422.711],
                                  [292.822, 287.359],
                                  [35.585, -94.48],
                                  [327.613, -250.026],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.236, -51.527],
                                  [-35.43, -135.354],
                                  [59.598, -36.396],
                                  [-137.283, -34.075],
                                  [-17.97, -71.669],
                                  [-112.544, 2.248],
                                  [143.032, 83.762],
                                  [-49.592, 155.178],
                                  [-8.106, 92],
                                ],
                                o: [
                                  [-99.823, 51.61],
                                  [35.43, 135.354],
                                  [-59.598, 36.396],
                                  [137.283, 34.075],
                                  [18.047, 71.764],
                                  [111.941, -2.118],
                                  [-143.032, -83.762],
                                  [49.48, -155.279],
                                  [8.106, -92],
                                ],
                                v: [
                                  [-119.235, -375.21],
                                  [-161.824, -164.697],
                                  [-249.722, 35.933],
                                  [-262.323, 160.916],
                                  [-48.88, 283.92],
                                  [108.725, 423.185],
                                  [292.707, 287.287],
                                  [36.194, -95.063],
                                  [326.889, -250.43],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.244, -51.512],
                                  [-35.411, -135.333],
                                  [59.585, -36.409],
                                  [-137.26, -34.078],
                                  [-17.969, -71.658],
                                  [-112.562, 2.256],
                                  [143, 83.754],
                                  [-49.579, 155.151],
                                  [-8.11, 92],
                                ],
                                o: [
                                  [-99.817, 51.597],
                                  [35.411, 135.333],
                                  [-59.585, 36.409],
                                  [137.26, 34.078],
                                  [18.049, 71.756],
                                  [111.939, -2.122],
                                  [-143, -83.754],
                                  [49.463, -155.255],
                                  [8.11, -92],
                                ],
                                v: [
                                  [-118.641, -375.316],
                                  [-162.327, -165.272],
                                  [-249.541, 35.533],
                                  [-263.245, 160.972],
                                  [-48.784, 283.981],
                                  [109.595, 423.596],
                                  [292.593, 287.277],
                                  [36.742, -95.645],
                                  [326.103, -250.833],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.252, -51.495],
                                  [-35.391, -135.311],
                                  [59.571, -36.423],
                                  [-137.235, -34.08],
                                  [-17.968, -71.647],
                                  [-112.58, 2.265],
                                  [142.967, 83.746],
                                  [-49.565, 155.123],
                                  [-8.114, 92],
                                ],
                                o: [
                                  [-99.811, 51.584],
                                  [35.391, 135.311],
                                  [-59.571, 36.423],
                                  [137.235, 34.08],
                                  [18.05, 71.748],
                                  [111.937, -2.126],
                                  [-142.967, -83.746],
                                  [49.445, -155.231],
                                  [8.114, -92],
                                ],
                                v: [
                                  [-118.047, -375.484],
                                  [-162.768, -165.785],
                                  [-249.298, 35.256],
                                  [-264.166, 160.967],
                                  [-48.565, 284.103],
                                  [110.526, 424.068],
                                  [292.479, 287.206],
                                  [37.23, -96.226],
                                  [325.316, -251.172],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.261, -51.479],
                                  [-35.371, -135.288],
                                  [59.557, -36.436],
                                  [-137.21, -34.083],
                                  [-17.967, -71.635],
                                  [-112.599, 2.274],
                                  [142.933, 83.738],
                                  [-49.551, 155.095],
                                  [-8.117, 92],
                                ],
                                o: [
                                  [-99.805, 51.57],
                                  [35.371, 135.288],
                                  [-59.557, 36.436],
                                  [137.21, 34.083],
                                  [18.052, 71.739],
                                  [111.935, -2.13],
                                  [-142.933, -83.738],
                                  [49.427, -155.205],
                                  [8.117, -92],
                                ],
                                v: [
                                  [-117.452, -375.651],
                                  [-163.271, -166.299],
                                  [-249.055, 34.917],
                                  [-265.148, 160.961],
                                  [-48.284, 284.225],
                                  [111.395, 424.477],
                                  [292.304, 287.134],
                                  [37.78, -96.93],
                                  [324.528, -251.511],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.269, -51.462],
                                  [-35.351, -135.265],
                                  [59.543, -36.451],
                                  [-137.184, -34.086],
                                  [-17.966, -71.623],
                                  [-112.619, 2.283],
                                  [142.898, 83.729],
                                  [-49.536, 155.065],
                                  [-8.121, 92],
                                ],
                                o: [
                                  [-99.798, 51.556],
                                  [35.351, 135.265],
                                  [-59.543, 36.451],
                                  [137.184, 34.086],
                                  [18.054, 71.731],
                                  [111.933, -2.135],
                                  [-142.898, -83.729],
                                  [49.408, -155.179],
                                  [8.121, -92],
                                ],
                                v: [
                                  [-116.917, -375.88],
                                  [-163.837, -166.813],
                                  [-248.813, 34.701],
                                  [-266.067, 160.893],
                                  [-47.943, 284.347],
                                  [112.263, 424.822],
                                  [292.191, 287.062],
                                  [38.208, -97.569],
                                  [323.739, -251.785],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.278, -51.444],
                                  [-35.33, -135.241],
                                  [59.528, -36.465],
                                  [-137.158, -34.089],
                                  [-17.965, -71.611],
                                  [-112.639, 2.292],
                                  [142.862, 83.72],
                                  [-49.521, 155.034],
                                  [-8.125, 92],
                                ],
                                o: [
                                  [-99.792, 51.542],
                                  [35.33, 135.241],
                                  [-59.528, 36.465],
                                  [137.158, 34.089],
                                  [18.056, 71.722],
                                  [111.931, -2.139],
                                  [-142.863, -83.72],
                                  [49.389, -155.153],
                                  [8.125, -92],
                                ],
                                v: [
                                  [-116.445, -376.108],
                                  [-164.341, -167.265],
                                  [-248.509, 34.484],
                                  [-267.048, 160.764],
                                  [-47.603, 284.469],
                                  [113.131, 425.228],
                                  [292.08, 286.928],
                                  [38.699, -98.332],
                                  [322.888, -251.996],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.287, -51.426],
                                  [-35.308, -135.216],
                                  [59.512, -36.481],
                                  [-137.13, -34.091],
                                  [-17.964, -71.598],
                                  [-112.66, 2.301],
                                  [142.826, 83.711],
                                  [-49.505, 155.003],
                                  [-8.129, 92],
                                ],
                                o: [
                                  [-99.785, 51.527],
                                  [35.308, 135.216],
                                  [-59.512, 36.481],
                                  [137.13, 34.091],
                                  [18.057, 71.713],
                                  [111.928, -2.143],
                                  [-142.825, -83.711],
                                  [49.369, -155.125],
                                  [8.129, -92],
                                ],
                                v: [
                                  [-115.971, -376.398],
                                  [-164.845, -167.78],
                                  [-248.267, 34.265],
                                  [-268.028, 160.634],
                                  [-47.14, 284.591],
                                  [113.936, 425.571],
                                  [291.906, 286.856],
                                  [39.13, -99.094],
                                  [322.036, -252.268],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.296, -51.408],
                                  [-35.285, -135.191],
                                  [59.496, -36.496],
                                  [-137.102, -34.094],
                                  [-17.963, -71.585],
                                  [-112.681, 2.311],
                                  [142.787, 83.702],
                                  [-49.489, 154.971],
                                  [-8.133, 92],
                                ],
                                o: [
                                  [-99.778, 51.511],
                                  [35.285, 135.191],
                                  [-59.496, 36.496],
                                  [137.102, 34.094],
                                  [18.059, 71.704],
                                  [111.926, -2.148],
                                  [-142.787, -83.702],
                                  [49.348, -155.097],
                                  [8.133, -92],
                                ],
                                v: [
                                  [-115.559, -376.687],
                                  [-165.35, -168.357],
                                  [-247.964, 34.171],
                                  [-269.007, 160.505],
                                  [-46.616, 284.774],
                                  [114.74, 425.913],
                                  [291.797, 286.785],
                                  [39.5, -99.854],
                                  [321.183, -252.476],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.306, -51.388],
                                  [-35.262, -135.165],
                                  [59.48, -36.512],
                                  [-137.073, -34.097],
                                  [-17.962, -71.572],
                                  [-112.703, 2.321],
                                  [142.748, 83.692],
                                  [-49.472, 154.937],
                                  [-8.138, 92],
                                ],
                                o: [
                                  [-99.771, 51.495],
                                  [35.262, 135.165],
                                  [-59.48, 36.512],
                                  [137.073, 34.097],
                                  [18.061, 71.694],
                                  [111.924, -2.153],
                                  [-142.748, -83.692],
                                  [49.327, -155.067],
                                  [8.138, -92],
                                ],
                                v: [
                                  [-115.207, -377.038],
                                  [-165.855, -168.873],
                                  [-247.661, 34.074],
                                  [-269.923, 160.313],
                                  [-46.094, 284.958],
                                  [115.544, 426.253],
                                  [291.688, 286.651],
                                  [39.872, -100.674],
                                  [320.391, -252.745],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.316, -51.369],
                                  [-35.238, -135.138],
                                  [59.463, -36.529],
                                  [-137.043, -34.101],
                                  [-17.961, -71.558],
                                  [-112.726, 2.332],
                                  [142.708, 83.682],
                                  [-49.455, 154.903],
                                  [-8.142, 92],
                                ],
                                o: [
                                  [-99.763, 51.479],
                                  [35.238, 135.138],
                                  [-59.463, 36.529],
                                  [137.043, 34.101],
                                  [18.063, 71.684],
                                  [111.921, -2.158],
                                  [-142.708, -83.682],
                                  [49.305, -155.037],
                                  [8.142, -92],
                                ],
                                v: [
                                  [-114.855, -377.388],
                                  [-166.298, -169.45],
                                  [-247.358, 34.039],
                                  [-270.9, 160.06],
                                  [-45.449, 285.141],
                                  [116.284, 426.531],
                                  [291.579, 286.58],
                                  [40.183, -101.555],
                                  [319.537, -252.95],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.326, -51.348],
                                  [-35.214, -135.11],
                                  [59.446, -36.546],
                                  [-137.012, -34.104],
                                  [-17.959, -71.544],
                                  [-112.75, 2.342],
                                  [142.666, 83.672],
                                  [-49.438, 154.868],
                                  [-8.147, 92],
                                ],
                                o: [
                                  [-99.756, 51.462],
                                  [35.214, 135.11],
                                  [-59.446, 36.546],
                                  [137.012, 34.104],
                                  [18.065, 71.674],
                                  [111.919, -2.163],
                                  [-142.666, -83.672],
                                  [49.283, -155.006],
                                  [8.147, -92],
                                ],
                                v: [
                                  [-114.564, -377.737],
                                  [-166.742, -170.028],
                                  [-246.994, 34.003],
                                  [-271.876, 159.807],
                                  [-44.806, 285.323],
                                  [117.025, 426.869],
                                  [291.472, 286.508],
                                  [40.497, -102.434],
                                  [318.682, -253.215],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.336, -51.327],
                                  [-35.189, -135.081],
                                  [59.428, -36.563],
                                  [-136.98, -34.107],
                                  [-17.958, -71.529],
                                  [-112.774, 2.353],
                                  [142.623, 83.662],
                                  [-49.42, 154.831],
                                  [-8.151, 92],
                                ],
                                o: [
                                  [-99.748, 51.445],
                                  [35.189, 135.081],
                                  [-59.428, 36.563],
                                  [136.98, 34.107],
                                  [18.067, 71.664],
                                  [111.916, -2.168],
                                  [-142.623, -83.662],
                                  [49.26, -154.974],
                                  [8.151, -92],
                                ],
                                v: [
                                  [-114.333, -378.148],
                                  [-167.186, -170.606],
                                  [-246.692, 34.027],
                                  [-272.789, 159.554],
                                  [-44.04, 285.568],
                                  [117.702, 427.082],
                                  [291.366, 286.375],
                                  [40.811, -103.311],
                                  [317.827, -253.417],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.347, -51.306],
                                  [-35.163, -135.052],
                                  [59.41, -36.581],
                                  [-136.948, -34.111],
                                  [-17.957, -71.514],
                                  [-112.798, 2.365],
                                  [142.579, 83.651],
                                  [-49.401, 154.794],
                                  [-8.156, 92],
                                ],
                                o: [
                                  [-99.74, 51.427],
                                  [35.163, 135.052],
                                  [-59.41, 36.581],
                                  [136.948, 34.111],
                                  [18.069, 71.653],
                                  [111.913, -2.174],
                                  [-142.579, -83.651],
                                  [49.236, -154.941],
                                  [8.156, -92],
                                ],
                                v: [
                                  [-114.164, -378.62],
                                  [-167.63, -171.185],
                                  [-246.328, 34.113],
                                  [-273.763, 159.24],
                                  [-43.275, 285.873],
                                  [118.379, 427.355],
                                  [291.26, 286.304],
                                  [41.003, -104.249],
                                  [317.032, -253.679],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.358, -51.284],
                                  [-35.136, -135.022],
                                  [59.391, -36.6],
                                  [-136.914, -34.114],
                                  [-17.955, -71.498],
                                  [-112.824, 2.376],
                                  [142.534, 83.64],
                                  [-49.382, 154.755],
                                  [-8.161, 92],
                                ],
                                o: [
                                  [-99.731, 51.409],
                                  [35.136, 135.022],
                                  [-59.391, 36.6],
                                  [136.914, 34.114],
                                  [18.072, 71.642],
                                  [111.91, -2.179],
                                  [-142.534, -83.64],
                                  [49.212, -154.907],
                                  [8.161, -92],
                                ],
                                v: [
                                  [-113.993, -379.091],
                                  [-168.075, -171.764],
                                  [-245.966, 34.258],
                                  [-274.675, 158.926],
                                  [-42.513, 286.116],
                                  [119.055, 427.566],
                                  [291.217, 286.17],
                                  [41.26, -105.185],
                                  [316.175, -253.877],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.37, -51.261],
                                  [-35.108, -134.99],
                                  [59.372, -36.619],
                                  [-136.88, -34.118],
                                  [-17.954, -71.482],
                                  [-112.85, 2.388],
                                  [142.487, 83.628],
                                  [-49.362, 154.716],
                                  [-8.166, 92],
                                ],
                                o: [
                                  [-99.723, 51.39],
                                  [35.108, 134.99],
                                  [-59.372, 36.619],
                                  [136.88, 34.118],
                                  [18.074, 71.63],
                                  [111.908, -2.185],
                                  [-142.487, -83.628],
                                  [49.187, -154.873],
                                  [8.166, -92],
                                ],
                                v: [
                                  [-113.883, -379.624],
                                  [-168.458, -172.405],
                                  [-245.603, 34.403],
                                  [-275.585, 158.612],
                                  [-41.691, 286.483],
                                  [119.73, 427.775],
                                  [291.113, 286.099],
                                  [41.455, -106.18],
                                  [315.317, -254.073],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.381, -51.237],
                                  [-35.08, -134.958],
                                  [59.352, -36.639],
                                  [-136.844, -34.122],
                                  [-17.952, -71.466],
                                  [-112.877, 2.4],
                                  [142.439, 83.616],
                                  [-49.342, 154.675],
                                  [-8.172, 92],
                                ],
                                o: [
                                  [-99.714, 51.371],
                                  [35.08, 134.958],
                                  [-59.352, 36.639],
                                  [136.844, 34.122],
                                  [18.076, 71.619],
                                  [111.905, -2.191],
                                  [-142.439, -83.616],
                                  [49.161, -154.837],
                                  [8.172, -92],
                                ],
                                v: [
                                  [-113.834, -380.155],
                                  [-168.841, -172.985],
                                  [-245.303, 34.546],
                                  [-276.494, 158.237],
                                  [-40.808, 286.848],
                                  [120.342, 427.982],
                                  [291.072, 285.966],
                                  [41.591, -107.174],
                                  [314.458, -254.33],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.393, -51.213],
                                  [-35.051, -134.925],
                                  [59.331, -36.659],
                                  [-136.807, -34.125],
                                  [-17.951, -71.449],
                                  [-112.905, 2.413],
                                  [142.389, 83.604],
                                  [-49.321, 154.633],
                                  [-8.177, 92],
                                ],
                                o: [
                                  [-99.705, 51.351],
                                  [35.051, 134.925],
                                  [-59.331, 36.659],
                                  [136.807, 34.125],
                                  [18.079, 71.607],
                                  [111.902, -2.197],
                                  [-142.389, -83.604],
                                  [49.134, -154.8],
                                  [8.177, -92],
                                ],
                                v: [
                                  [-113.783, -380.748],
                                  [-169.224, -173.626],
                                  [-244.94, 34.687],
                                  [-277.402, 157.862],
                                  [-39.928, 287.213],
                                  [120.892, 428.127],
                                  [291.094, 285.894],
                                  [41.729, -108.166],
                                  [313.599, -254.522],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.406, -51.188],
                                  [-35.021, -134.891],
                                  [59.31, -36.68],
                                  [-136.77, -34.129],
                                  [-17.949, -71.432],
                                  [-112.934, 2.426],
                                  [142.338, 83.592],
                                  [-49.3, 154.589],
                                  [-8.183, 92],
                                ],
                                o: [
                                  [-99.696, 51.33],
                                  [35.021, 134.891],
                                  [-59.31, 36.68],
                                  [136.77, 34.129],
                                  [18.081, 71.594],
                                  [111.898, -2.203],
                                  [-142.338, -83.592],
                                  [49.107, -154.762],
                                  [8.183, -92],
                                ],
                                v: [
                                  [-113.731, -381.339],
                                  [-169.546, -174.207],
                                  [-244.64, 34.889],
                                  [-278.309, 157.426],
                                  [-38.988, 287.639],
                                  [121.503, 428.27],
                                  [291.054, 285.761],
                                  [41.807, -109.219],
                                  [312.801, -254.712],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.419, -51.163],
                                  [-34.99, -134.856],
                                  [59.288, -36.701],
                                  [-136.731, -34.133],
                                  [-17.948, -71.414],
                                  [-112.963, 2.44],
                                  [142.286, 83.579],
                                  [-49.278, 154.545],
                                  [-8.188, 92],
                                ],
                                o: [
                                  [-99.686, 51.309],
                                  [34.99, 134.856],
                                  [-59.288, 36.701],
                                  [136.731, 34.133],
                                  [18.084, 71.581],
                                  [111.895, -2.209],
                                  [-142.286, -83.579],
                                  [49.079, -154.723],
                                  [8.188, -92],
                                ],
                                v: [
                                  [-113.679, -381.99],
                                  [-169.869, -174.85],
                                  [-244.279, 35.089],
                                  [-279.153, 156.991],
                                  [-38.05, 288.064],
                                  [122.05, 428.412],
                                  [291.016, 285.628],
                                  [41.887, -110.268],
                                  [311.94, -254.901],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.432, -51.136],
                                  [-34.958, -134.82],
                                  [59.266, -36.723],
                                  [-136.691, -34.138],
                                  [-17.946, -71.395],
                                  [-112.993, 2.453],
                                  [142.232, 83.565],
                                  [-49.255, 154.499],
                                  [-8.194, 92],
                                ],
                                o: [
                                  [-99.676, 51.287],
                                  [34.958, 134.82],
                                  [-59.266, 36.723],
                                  [136.691, 34.138],
                                  [18.086, 71.568],
                                  [111.892, -2.216],
                                  [-142.232, -83.565],
                                  [49.05, -154.683],
                                  [8.194, -92],
                                ],
                                v: [
                                  [-113.625, -382.64],
                                  [-170.192, -175.431],
                                  [-243.98, 35.288],
                                  [-279.996, 156.557],
                                  [-37.115, 288.488],
                                  [122.535, 428.491],
                                  [291.04, 285.556],
                                  [41.907, -111.315],
                                  [311.079, -255.149],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.445, -51.109],
                                  [-34.925, -134.783],
                                  [59.243, -36.746],
                                  [-136.65, -34.142],
                                  [-17.944, -71.376],
                                  [-113.025, 2.468],
                                  [142.176, 83.552],
                                  [-49.232, 154.452],
                                  [-8.201, 92],
                                ],
                                o: [
                                  [-99.666, 51.265],
                                  [34.925, 134.783],
                                  [-59.243, 36.746],
                                  [136.65, 34.142],
                                  [18.089, 71.555],
                                  [111.889, -2.223],
                                  [-142.176, -83.552],
                                  [49.02, -154.641],
                                  [8.2, -92],
                                ],
                                v: [
                                  [-113.571, -383.35],
                                  [-170.515, -176.074],
                                  [-243.681, 35.485],
                                  [-280.775, 156.123],
                                  [-36.12, 288.973],
                                  [123.081, 428.568],
                                  [291.065, 285.423],
                                  [41.868, -112.422],
                                  [310.217, -255.333],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.459, -51.081],
                                  [-34.892, -134.745],
                                  [59.219, -36.77],
                                  [-136.607, -34.146],
                                  [-17.943, -71.357],
                                  [-113.057, 2.482],
                                  [142.119, 83.538],
                                  [-49.207, 154.403],
                                  [-8.207, 92],
                                ],
                                o: [
                                  [-99.655, 51.242],
                                  [34.892, 134.745],
                                  [-59.219, 36.77],
                                  [136.607, 34.146],
                                  [18.092, 71.541],
                                  [111.885, -2.23],
                                  [-142.119, -83.538],
                                  [48.989, -154.599],
                                  [8.207, -92],
                                ],
                                v: [
                                  [-113.578, -384.12],
                                  [-170.838, -176.657],
                                  [-243.444, 35.741],
                                  [-281.554, 155.629],
                                  [-35.128, 289.456],
                                  [123.565, 428.644],
                                  [291.154, 285.29],
                                  [41.893, -113.525],
                                  [309.354, -255.515],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.474, -51.052],
                                  [-34.857, -134.706],
                                  [59.195, -36.794],
                                  [-136.564, -34.151],
                                  [-17.941, -71.337],
                                  [-113.09, 2.497],
                                  [142.06, 83.523],
                                  [-49.183, 154.354],
                                  [-8.213, 92],
                                ],
                                o: [
                                  [-99.645, 51.218],
                                  [34.857, 134.706],
                                  [-59.195, 36.794],
                                  [136.564, 34.151],
                                  [18.095, 71.526],
                                  [111.882, -2.237],
                                  [-142.06, -83.523],
                                  [48.958, -154.555],
                                  [8.213, -92],
                                ],
                                v: [
                                  [-113.584, -384.888],
                                  [-171.1, -177.301],
                                  [-243.145, 35.996],
                                  [-282.331, 155.135],
                                  [-34.077, 289.939],
                                  [123.985, 428.718],
                                  [291.181, 285.157],
                                  [41.797, -114.625],
                                  [308.551, -255.633],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.489, -51.023],
                                  [-34.821, -134.666],
                                  [59.169, -36.818],
                                  [-136.519, -34.156],
                                  [-17.939, -71.316],
                                  [-113.124, 2.513],
                                  [142, 83.508],
                                  [-49.157, 154.302],
                                  [-8.22, 92],
                                ],
                                o: [
                                  [-99.634, 51.194],
                                  [34.821, 134.666],
                                  [-59.169, 36.818],
                                  [136.519, 34.156],
                                  [18.098, 71.511],
                                  [111.878, -2.244],
                                  [-142, -83.508],
                                  [48.925, -154.51],
                                  [8.22, -92],
                                ],
                                v: [
                                  [-113.588, -385.653],
                                  [-171.361, -177.884],
                                  [-242.908, 36.249],
                                  [-283.046, 154.642],
                                  [-33.028, 290.483],
                                  [124.468, 428.729],
                                  [291.271, 285.086],
                                  [41.704, -115.722],
                                  [307.686, -255.811],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.504, -50.993],
                                  [-34.785, -134.624],
                                  [59.144, -36.844],
                                  [-136.473, -34.161],
                                  [-17.937, -71.295],
                                  [-113.159, 2.529],
                                  [141.938, 83.493],
                                  [-49.131, 154.25],
                                  [-8.227, 92],
                                ],
                                o: [
                                  [-99.622, 51.169],
                                  [34.785, 134.624],
                                  [-59.144, 36.844],
                                  [136.473, 34.161],
                                  [18.101, 71.496],
                                  [111.874, -2.252],
                                  [-141.938, -83.493],
                                  [48.892, -154.464],
                                  [8.227, -92],
                                ],
                                v: [
                                  [-113.592, -386.48],
                                  [-171.563, -178.468],
                                  [-242.672, 36.5],
                                  [-283.759, 154.089],
                                  [-31.982, 290.963],
                                  [124.887, 428.738],
                                  [291.362, 284.953],
                                  [41.551, -116.879],
                                  [306.821, -255.986],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.519, -50.961],
                                  [-34.747, -134.582],
                                  [59.117, -36.87],
                                  [-136.426, -34.166],
                                  [-17.935, -71.273],
                                  [-113.194, 2.545],
                                  [141.874, 83.477],
                                  [-49.104, 154.195],
                                  [-8.234, 92],
                                ],
                                o: [
                                  [-99.611, 51.143],
                                  [34.747, 134.582],
                                  [-59.117, 36.87],
                                  [136.426, 34.166],
                                  [18.104, 71.481],
                                  [111.87, -2.26],
                                  [-141.874, -83.477],
                                  [48.857, -154.416],
                                  [8.234, -92],
                                ],
                                v: [
                                  [-113.657, -387.364],
                                  [-171.825, -179.052],
                                  [-242.435, 36.811],
                                  [-284.41, 153.597],
                                  [-30.939, 291.503],
                                  [125.307, 428.745],
                                  [291.515, 284.82],
                                  [41.402, -118.032],
                                  [306.016, -256.159],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.535, -50.929],
                                  [-34.708, -134.538],
                                  [59.09, -36.897],
                                  [-136.377, -34.171],
                                  [-17.933, -71.25],
                                  [-113.231, 2.562],
                                  [141.808, 83.461],
                                  [-49.076, 154.139],
                                  [-8.241, 92],
                                ],
                                o: [
                                  [-99.598, 51.117],
                                  [34.708, 134.538],
                                  [-59.09, 36.897],
                                  [136.377, 34.171],
                                  [18.107, 71.465],
                                  [111.866, -2.268],
                                  [-141.808, -83.461],
                                  [48.822, -154.367],
                                  [8.241, -92],
                                ],
                                v: [
                                  [-113.721, -388.247],
                                  [-172.026, -179.637],
                                  [-242.261, 37.119],
                                  [-285.06, 153.046],
                                  [-29.839, 292.042],
                                  [125.664, 428.751],
                                  [291.607, 284.626],
                                  [41.255, -119.181],
                                  [305.149, -256.268],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.552, -50.896],
                                  [-34.669, -134.493],
                                  [59.062, -36.924],
                                  [-136.327, -34.176],
                                  [-17.931, -71.227],
                                  [-113.269, 2.58],
                                  [141.741, 83.445],
                                  [-49.048, 154.082],
                                  [-8.248, 92],
                                ],
                                o: [
                                  [-99.586, 51.089],
                                  [34.669, 134.493],
                                  [-59.062, 36.924],
                                  [136.327, 34.176],
                                  [18.11, 71.448],
                                  [111.862, -2.276],
                                  [-141.741, -83.445],
                                  [48.786, -154.317],
                                  [8.248, -92],
                                ],
                                v: [
                                  [-113.785, -389.127],
                                  [-172.166, -180.222],
                                  [-242.086, 37.426],
                                  [-285.646, 152.496],
                                  [-28.74, 292.58],
                                  [126.02, 428.693],
                                  [291.763, 284.493],
                                  [40.987, -120.327],
                                  [304.342, -256.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.569, -50.862],
                                  [-34.628, -134.446],
                                  [59.033, -36.953],
                                  [-136.276, -34.181],
                                  [-17.929, -71.204],
                                  [-113.308, 2.597],
                                  [141.671, 83.428],
                                  [-49.019, 154.023],
                                  [-8.256, 92],
                                ],
                                o: [
                                  [-99.573, 51.061],
                                  [34.628, 134.446],
                                  [-59.033, 36.953],
                                  [136.276, 34.181],
                                  [18.114, 71.431],
                                  [111.858, -2.284],
                                  [-141.671, -83.428],
                                  [48.748, -154.265],
                                  [8.256, -92],
                                ],
                                v: [
                                  [-113.909, -390.004],
                                  [-172.37, -180.746],
                                  [-241.912, 37.73],
                                  [-286.232, 151.885],
                                  [-27.707, 293.117],
                                  [126.376, 428.635],
                                  [291.981, 284.361],
                                  [40.785, -121.47],
                                  [303.534, -256.539],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.586, -50.827],
                                  [-34.585, -134.399],
                                  [59.003, -36.982],
                                  [-136.223, -34.187],
                                  [-17.927, -71.179],
                                  [-113.349, 2.616],
                                  [141.6, 83.41],
                                  [-48.989, 153.962],
                                  [-8.264, 92],
                                ],
                                o: [
                                  [-99.56, 51.033],
                                  [34.585, 134.399],
                                  [-59.003, 36.982],
                                  [136.223, 34.187],
                                  [18.117, 71.414],
                                  [111.853, -2.293],
                                  [-141.6, -83.41],
                                  [48.71, -154.212],
                                  [8.264, -92],
                                ],
                                v: [
                                  [-114.032, -390.939],
                                  [-172.511, -181.333],
                                  [-241.8, 38.094],
                                  [-286.755, 151.337],
                                  [-26.615, 293.712],
                                  [126.67, 428.574],
                                  [292.138, 284.228],
                                  [40.462, -122.671],
                                  [302.664, -256.64],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.604, -50.791],
                                  [-34.542, -134.35],
                                  [58.973, -37.012],
                                  [-136.168, -34.193],
                                  [-17.924, -71.154],
                                  [-113.39, 2.635],
                                  [141.526, 83.392],
                                  [-48.958, 153.9],
                                  [-8.272, 92],
                                ],
                                o: [
                                  [-99.547, 51.003],
                                  [34.542, 134.35],
                                  [-58.973, 37.012],
                                  [136.168, 34.193],
                                  [18.121, 71.396],
                                  [111.849, -2.302],
                                  [-141.526, -83.392],
                                  [48.671, -154.157],
                                  [8.272, -92],
                                ],
                                v: [
                                  [-114.153, -391.873],
                                  [-172.591, -181.86],
                                  [-241.688, 38.394],
                                  [-287.277, 150.791],
                                  [-25.527, 294.245],
                                  [127.025, 428.45],
                                  [292.357, 284.034],
                                  [40.204, -123.806],
                                  [301.855, -256.738],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.623, -50.755],
                                  [-34.498, -134.299],
                                  [58.941, -37.043],
                                  [-136.112, -34.199],
                                  [-17.922, -71.128],
                                  [-113.432, 2.654],
                                  [141.451, 83.373],
                                  [-48.926, 153.836],
                                  [-8.28, 92],
                                ],
                                o: [
                                  [-99.533, 50.973],
                                  [34.498, 134.299],
                                  [-58.941, 37.043],
                                  [136.112, 34.198],
                                  [18.125, 71.377],
                                  [111.844, -2.311],
                                  [-141.451, -83.373],
                                  [48.63, -154.101],
                                  [8.28, -92],
                                ],
                                v: [
                                  [-114.335, -392.865],
                                  [-172.735, -182.386],
                                  [-241.575, 38.753],
                                  [-287.737, 150.184],
                                  [-24.382, 294.838],
                                  [127.318, 428.324],
                                  [292.578, 283.901],
                                  [39.826, -124.999],
                                  [301.044, -256.834],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.642, -50.717],
                                  [-34.452, -134.247],
                                  [58.909, -37.075],
                                  [-136.055, -34.205],
                                  [-17.92, -71.102],
                                  [-113.476, 2.674],
                                  [141.373, 83.354],
                                  [-48.893, 153.77],
                                  [-8.289, 92],
                                ],
                                o: [
                                  [-99.519, 50.941],
                                  [34.452, 134.247],
                                  [-58.909, 37.075],
                                  [136.055, 34.205],
                                  [18.128, 71.358],
                                  [111.84, -2.321],
                                  [-141.373, -83.354],
                                  [48.588, -154.043],
                                  [8.289, -92],
                                ],
                                v: [
                                  [-114.517, -393.792],
                                  [-172.816, -182.852],
                                  [-241.524, 39.109],
                                  [-288.133, 149.579],
                                  [-23.301, 295.367],
                                  [127.61, 428.197],
                                  [292.861, 283.707],
                                  [39.453, -126.126],
                                  [300.294, -256.928],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.661, -50.678],
                                  [-34.405, -134.194],
                                  [58.876, -37.108],
                                  [-135.996, -34.211],
                                  [-17.917, -71.074],
                                  [-113.521, 2.694],
                                  [141.293, 83.335],
                                  [-48.859, 153.702],
                                  [-8.298, 92],
                                ],
                                o: [
                                  [-99.504, 50.909],
                                  [34.405, 134.194],
                                  [-58.876, 37.107],
                                  [135.996, 34.211],
                                  [18.132, 71.339],
                                  [111.835, -2.331],
                                  [-141.293, -83.335],
                                  [48.545, -153.983],
                                  [8.298, -92],
                                ],
                                v: [
                                  [-114.759, -394.777],
                                  [-172.899, -183.32],
                                  [-241.474, 39.525],
                                  [-288.529, 148.975],
                                  [-22.225, 295.956],
                                  [127.84, 428.069],
                                  [293.144, 283.512],
                                  [39.082, -127.311],
                                  [299.481, -257.017],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.681, -50.638],
                                  [-34.357, -134.139],
                                  [58.842, -37.141],
                                  [-135.935, -34.217],
                                  [-17.915, -71.046],
                                  [-113.567, 2.715],
                                  [141.211, 83.315],
                                  [-48.825, 153.633],
                                  [-8.307, 92],
                                ],
                                o: [
                                  [-99.489, 50.876],
                                  [34.357, 134.139],
                                  [-58.842, 37.141],
                                  [135.935, 34.217],
                                  [18.136, 71.319],
                                  [111.83, -2.341],
                                  [-141.211, -83.315],
                                  [48.501, -153.922],
                                  [8.307, -92],
                                ],
                                v: [
                                  [-115, -395.758],
                                  [-172.92, -183.787],
                                  [-241.423, 39.877],
                                  [-288.862, 148.373],
                                  [-21.214, 296.481],
                                  [128.07, 427.939],
                                  [293.428, 283.319],
                                  [38.654, -128.492],
                                  [298.728, -257.106],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.702, -50.596],
                                  [-34.307, -134.083],
                                  [58.807, -37.176],
                                  [-135.873, -34.224],
                                  [-17.912, -71.017],
                                  [-113.614, 2.737],
                                  [141.127, 83.294],
                                  [-48.789, 153.561],
                                  [-8.316, 92],
                                ],
                                o: [
                                  [-99.474, 50.842],
                                  [34.307, 134.083],
                                  [-58.807, 37.176],
                                  [135.873, 34.224],
                                  [18.14, 71.298],
                                  [111.825, -2.351],
                                  [-141.127, -83.294],
                                  [48.456, -153.859],
                                  [8.316, -92],
                                ],
                                v: [
                                  [-115.301, -396.737],
                                  [-172.942, -184.257],
                                  [-241.434, 40.227],
                                  [-289.133, 147.834],
                                  [-20.145, 297.066],
                                  [128.299, 427.745],
                                  [293.713, 283.125],
                                  [38.168, -129.667],
                                  [297.913, -257.129],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.723, -50.554],
                                  [-34.256, -134.025],
                                  [58.771, -37.211],
                                  [-135.808, -34.23],
                                  [-17.91, -70.988],
                                  [-113.663, 2.759],
                                  [141.04, 83.273],
                                  [-48.753, 153.488],
                                  [-8.325, 92],
                                ],
                                o: [
                                  [-99.458, 50.807],
                                  [34.256, 134.025],
                                  [-58.771, 37.211],
                                  [135.808, 34.23],
                                  [18.145, 71.277],
                                  [111.819, -2.362],
                                  [-141.04, -83.273],
                                  [48.409, -153.795],
                                  [8.325, -92],
                                ],
                                v: [
                                  [-115.6, -397.711],
                                  [-172.965, -184.665],
                                  [-241.506, 40.634],
                                  [-289.402, 147.235],
                                  [-19.081, 297.587],
                                  [128.528, 427.552],
                                  [294.06, 282.931],
                                  [37.687, -130.838],
                                  [297.158, -257.149],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.745, -50.511],
                                  [-34.203, -133.966],
                                  [58.734, -37.247],
                                  [-135.743, -34.237],
                                  [-17.907, -70.957],
                                  [-113.713, 2.782],
                                  [140.951, 83.251],
                                  [-48.715, 153.412],
                                  [-8.335, 92],
                                ],
                                o: [
                                  [-99.441, 50.771],
                                  [34.203, 133.966],
                                  [-58.734, 37.247],
                                  [135.743, 34.237],
                                  [18.149, 71.255],
                                  [111.814, -2.372],
                                  [-140.951, -83.251],
                                  [48.362, -153.729],
                                  [8.335, -92],
                                ],
                                v: [
                                  [-115.898, -398.743],
                                  [-172.928, -185.076],
                                  [-241.516, 41.04],
                                  [-289.611, 146.577],
                                  [-18.082, 298.166],
                                  [128.757, 427.357],
                                  [294.408, 282.736],
                                  [37.21, -131.943],
                                  [296.463, -257.228],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.767, -50.466],
                                  [-34.149, -133.905],
                                  [58.696, -37.285],
                                  [-135.675, -34.245],
                                  [-17.904, -70.926],
                                  [-113.764, 2.805],
                                  [140.86, 83.228],
                                  [-48.677, 153.334],
                                  [-8.345, 92],
                                ],
                                o: [
                                  [-99.425, 50.734],
                                  [34.149, 133.905],
                                  [-58.696, 37.285],
                                  [135.675, 34.245],
                                  [18.153, 71.233],
                                  [111.808, -2.384],
                                  [-140.86, -83.228],
                                  [48.312, -153.66],
                                  [8.345, -92],
                                ],
                                v: [
                                  [-116.257, -399.71],
                                  [-172.891, -185.487],
                                  [-241.65, 41.382],
                                  [-289.756, 145.982],
                                  [-17.087, 298.683],
                                  [128.923, 427.159],
                                  [294.818, 282.543],
                                  [36.675, -133.104],
                                  [295.705, -257.243],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.79, -50.42],
                                  [-34.094, -133.842],
                                  [58.657, -37.323],
                                  [-135.605, -34.252],
                                  [-17.901, -70.894],
                                  [-113.817, 2.83],
                                  [140.766, 83.205],
                                  [-48.637, 153.255],
                                  [-8.355, 92],
                                ],
                                o: [
                                  [-99.408, 50.696],
                                  [34.094, 133.842],
                                  [-58.657, 37.323],
                                  [135.605, 34.252],
                                  [18.158, 71.21],
                                  [111.802, -2.395],
                                  [-140.766, -83.205],
                                  [48.262, -153.59],
                                  [8.356, -92],
                                ],
                                v: [
                                  [-116.676, -400.673],
                                  [-172.854, -185.838],
                                  [-241.782, 41.781],
                                  [-289.84, 145.389],
                                  [-16.098, 299.195],
                                  [129.091, 426.961],
                                  [295.228, 282.287],
                                  [36.085, -134.261],
                                  [295.007, -257.256],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.814, -50.373],
                                  [-34.037, -133.778],
                                  [58.617, -37.363],
                                  [-135.534, -34.259],
                                  [-17.898, -70.861],
                                  [-113.871, 2.854],
                                  [140.669, 83.181],
                                  [-48.597, 153.173],
                                  [-8.366, 92],
                                ],
                                o: [
                                  [-99.39, 50.658],
                                  [34.037, 133.778],
                                  [-58.617, 37.363],
                                  [135.534, 34.259],
                                  [18.163, 71.186],
                                  [111.797, -2.407],
                                  [-140.669, -83.181],
                                  [48.21, -153.518],
                                  [8.366, -92],
                                ],
                                v: [
                                  [-117.031, -401.692],
                                  [-172.817, -186.131],
                                  [-241.914, 42.179],
                                  [-289.922, 144.798],
                                  [-15.174, 299.707],
                                  [129.257, 426.701],
                                  [295.639, 282.031],
                                  [35.498, -135.411],
                                  [294.307, -257.203],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.838, -50.324],
                                  [-33.979, -133.712],
                                  [58.576, -37.403],
                                  [-135.46, -34.267],
                                  [-17.895, -70.827],
                                  [-113.927, 2.88],
                                  [140.57, 83.157],
                                  [-48.555, 153.088],
                                  [-8.377, 92],
                                ],
                                o: [
                                  [-99.372, 50.618],
                                  [33.979, 133.712],
                                  [-58.576, 37.403],
                                  [135.46, 34.267],
                                  [18.168, 71.162],
                                  [111.791, -2.419],
                                  [-140.57, -83.157],
                                  [48.157, -153.445],
                                  [8.377, -92],
                                ],
                                v: [
                                  [-117.508, -402.647],
                                  [-172.721, -186.484],
                                  [-242.107, 42.512],
                                  [-289.943, 144.208],
                                  [-14.254, 300.216],
                                  [129.423, 426.5],
                                  [296.111, 281.836],
                                  [34.916, -136.494],
                                  [293.668, -257.208],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.863, -50.275],
                                  [-33.919, -133.644],
                                  [58.533, -37.445],
                                  [-135.385, -34.275],
                                  [-17.892, -70.792],
                                  [-113.984, 2.906],
                                  [140.468, 83.132],
                                  [-48.512, 153.002],
                                  [-8.388, 92],
                                ],
                                o: [
                                  [-99.353, 50.576],
                                  [33.919, 133.644],
                                  [-58.533, 37.445],
                                  [135.385, 34.275],
                                  [18.173, 71.137],
                                  [111.784, -2.431],
                                  [-140.468, -83.132],
                                  [48.102, -153.369],
                                  [8.388, -92],
                                ],
                                v: [
                                  [-117.982, -403.596],
                                  [-172.564, -186.779],
                                  [-242.301, 42.843],
                                  [-289.903, 143.622],
                                  [-13.34, 300.722],
                                  [129.589, 426.237],
                                  [296.585, 281.581],
                                  [34.278, -137.634],
                                  [293.025, -257.212],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.888, -50.223],
                                  [-33.857, -133.574],
                                  [58.49, -37.488],
                                  [-135.307, -34.283],
                                  [-17.889, -70.756],
                                  [-114.043, 2.933],
                                  [140.364, 83.106],
                                  [-48.468, 152.913],
                                  [-8.4, 92],
                                ],
                                o: [
                                  [-99.334, 50.534],
                                  [33.857, 133.574],
                                  [-58.49, 37.488],
                                  [135.307, 34.283],
                                  [18.178, 71.112],
                                  [111.778, -2.444],
                                  [-140.363, -83.106],
                                  [48.046, -153.291],
                                  [8.4, -92],
                                ],
                                v: [
                                  [-118.456, -404.541],
                                  [-172.469, -187.016],
                                  [-242.554, 43.231],
                                  [-289.8, 143.036],
                                  [-12.493, 301.226],
                                  [129.693, 425.974],
                                  [297.057, 281.264],
                                  [33.585, -138.706],
                                  [292.382, -257.151],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.915, -50.171],
                                  [-33.794, -133.502],
                                  [58.445, -37.532],
                                  [-135.228, -34.292],
                                  [-17.886, -70.72],
                                  [-114.103, 2.96],
                                  [140.256, 83.08],
                                  [-48.422, 152.822],
                                  [-8.411, 92],
                                ],
                                o: [
                                  [-99.314, 50.491],
                                  [33.794, 133.502],
                                  [-58.445, 37.532],
                                  [135.228, 34.292],
                                  [18.183, 71.085],
                                  [111.771, -2.457],
                                  [-140.256, -83.08],
                                  [47.988, -153.211],
                                  [8.412, -92],
                                ],
                                v: [
                                  [-118.989, -405.48],
                                  [-172.313, -187.252],
                                  [-242.867, 43.556],
                                  [-289.697, 142.513],
                                  [-11.65, 301.726],
                                  [129.799, 425.71],
                                  [297.592, 281.009],
                                  [32.958, -139.773],
                                  [291.797, -257.087],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.942, -50.117],
                                  [-33.729, -133.428],
                                  [58.399, -37.577],
                                  [-135.146, -34.3],
                                  [-17.882, -70.682],
                                  [-114.165, 2.989],
                                  [140.146, 83.053],
                                  [-48.376, 152.728],
                                  [-8.424, 92],
                                ],
                                o: [
                                  [-99.294, 50.446],
                                  [33.729, 133.428],
                                  [-58.399, 37.577],
                                  [135.146, 34.3],
                                  [18.188, 71.058],
                                  [111.765, -2.471],
                                  [-140.146, -83.053],
                                  [47.929, -153.128],
                                  [8.424, -92],
                                ],
                                v: [
                                  [-119.518, -406.415],
                                  [-172.097, -187.492],
                                  [-243.182, 43.878],
                                  [-289.532, 141.933],
                                  [-10.873, 302.162],
                                  [129.964, 425.444],
                                  [298.126, 280.752],
                                  [32.213, -140.832],
                                  [291.21, -257.019],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.969, -50.062],
                                  [-33.662, -133.353],
                                  [58.352, -37.623],
                                  [-135.062, -34.309],
                                  [-17.879, -70.643],
                                  [-114.229, 3.018],
                                  [140.032, 83.025],
                                  [-48.328, 152.632],
                                  [-8.436, 92],
                                ],
                                o: [
                                  [-99.273, 50.401],
                                  [33.662, 133.353],
                                  [-58.352, 37.623],
                                  [135.062, 34.309],
                                  [18.194, 71.031],
                                  [111.758, -2.485],
                                  [-140.032, -83.025],
                                  [47.868, -153.044],
                                  [8.436, -92],
                                ],
                                v: [
                                  [-120.109, -407.345],
                                  [-171.943, -187.672],
                                  [-243.555, 44.136],
                                  [-289.306, 141.354],
                                  [-10.163, 302.596],
                                  [130.069, 425.119],
                                  [298.722, 280.436],
                                  [31.538, -141.885],
                                  [290.622, -256.949],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.998, -50.005],
                                  [-33.593, -133.275],
                                  [58.304, -37.671],
                                  [-134.976, -34.318],
                                  [-17.875, -70.603],
                                  [-114.295, 3.048],
                                  [139.916, 82.996],
                                  [-48.279, 152.533],
                                  [-8.449, 92],
                                ],
                                o: [
                                  [-99.252, 50.354],
                                  [33.593, 133.275],
                                  [-58.304, 37.671],
                                  [134.976, 34.318],
                                  [18.2, 71.002],
                                  [111.751, -2.499],
                                  [-139.916, -82.996],
                                  [47.805, -152.957],
                                  [8.449, -92],
                                ],
                                v: [
                                  [-120.695, -408.208],
                                  [-171.728, -187.855],
                                  [-243.928, 44.392],
                                  [-289.02, 140.838],
                                  [-9.457, 303.027],
                                  [130.174, 424.852],
                                  [299.317, 280.117],
                                  [30.805, -142.931],
                                  [290.031, -256.875],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.027, -49.946],
                                  [-33.523, -133.195],
                                  [58.254, -37.72],
                                  [-134.887, -34.327],
                                  [-17.872, -70.562],
                                  [-114.362, 3.078],
                                  [139.796, 82.967],
                                  [-48.229, 152.432],
                                  [-8.462, 92],
                                ],
                                o: [
                                  [-99.23, 50.306],
                                  [33.523, 133.195],
                                  [-58.254, 37.72],
                                  [134.887, 34.327],
                                  [18.205, 70.973],
                                  [111.743, -2.513],
                                  [-139.796, -82.967],
                                  [47.741, -152.868],
                                  [8.462, -92],
                                ],
                                v: [
                                  [-121.341, -409.066],
                                  [-171.514, -187.978],
                                  [-244.361, 44.644],
                                  [-288.671, 140.263],
                                  [-8.82, 303.453],
                                  [130.278, 424.523],
                                  [299.912, 279.8],
                                  [30.019, -143.97],
                                  [289.437, -256.799],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.057, -49.886],
                                  [-33.45, -133.113],
                                  [58.203, -37.77],
                                  [-134.796, -34.337],
                                  [-17.868, -70.52],
                                  [-114.431, 3.11],
                                  [139.673, 82.937],
                                  [-48.177, 152.327],
                                  [-8.476, 92],
                                ],
                                o: [
                                  [-99.207, 50.256],
                                  [33.45, 133.113],
                                  [-58.203, 37.77],
                                  [134.796, 34.337],
                                  [18.211, 70.943],
                                  [111.736, -2.528],
                                  [-139.673, -82.936],
                                  [47.675, -152.776],
                                  [8.476, -92],
                                ],
                                v: [
                                  [-121.985, -409.858],
                                  [-171.24, -188.105],
                                  [-244.853, 44.893],
                                  [-288.262, 139.752],
                                  [-8.248, 303.818],
                                  [130.382, 424.257],
                                  [300.508, 279.421],
                                  [29.239, -144.941],
                                  [288.84, -256.719],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.088, -49.825],
                                  [-33.376, -133.029],
                                  [58.151, -37.822],
                                  [-134.703, -34.347],
                                  [-17.864, -70.477],
                                  [-114.502, 3.142],
                                  [139.547, 82.906],
                                  [-48.124, 152.22],
                                  [-8.489, 92],
                                ],
                                o: [
                                  [-99.184, 50.205],
                                  [33.376, 133.029],
                                  [-58.151, 37.822],
                                  [134.703, 34.347],
                                  [18.217, 70.912],
                                  [111.728, -2.544],
                                  [-139.547, -82.906],
                                  [47.607, -152.683],
                                  [8.49, -92],
                                ],
                                v: [
                                  [-122.686, -410.704],
                                  [-170.966, -188.174],
                                  [-245.345, 45.079],
                                  [-287.853, 139.242],
                                  [-7.742, 304.178],
                                  [130.426, 423.929],
                                  [301.042, 278.982],
                                  [28.466, -145.903],
                                  [288.304, -256.635],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.119, -49.761],
                                  [-33.3, -132.943],
                                  [58.097, -37.875],
                                  [-134.607, -34.357],
                                  [-17.86, -70.433],
                                  [-114.574, 3.175],
                                  [139.418, 82.874],
                                  [-48.069, 152.11],
                                  [-8.504, 92],
                                ],
                                o: [
                                  [-99.161, 50.153],
                                  [33.3, 132.943],
                                  [-58.097, 37.875],
                                  [134.607, 34.357],
                                  [18.224, 70.881],
                                  [111.72, -2.56],
                                  [-139.418, -82.874],
                                  [47.538, -152.586],
                                  [8.504, -92],
                                ],
                                v: [
                                  [-123.385, -411.423],
                                  [-170.692, -188.244],
                                  [-245.897, 45.202],
                                  [-287.383, 138.734],
                                  [-7.242, 304.534],
                                  [130.532, 423.6],
                                  [301.576, 278.542],
                                  [27.7, -146.859],
                                  [287.703, -256.488],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.152, -49.697],
                                  [-33.221, -132.854],
                                  [58.042, -37.929],
                                  [-134.509, -34.367],
                                  [-17.856, -70.388],
                                  [-114.649, 3.209],
                                  [139.285, 82.841],
                                  [-48.013, 151.998],
                                  [-8.518, 92],
                                ],
                                o: [
                                  [-99.136, 50.1],
                                  [33.221, 132.854],
                                  [-58.042, 37.929],
                                  [134.509, 34.367],
                                  [18.23, 70.848],
                                  [111.712, -2.576],
                                  [-139.285, -82.841],
                                  [47.466, -152.487],
                                  [8.518, -92],
                                ],
                                v: [
                                  [-124.082, -412.198],
                                  [-170.358, -188.257],
                                  [-246.506, 45.321],
                                  [-286.855, 138.229],
                                  [-6.809, 304.888],
                                  [130.638, 423.332],
                                  [302.049, 278.043],
                                  [26.881, -147.805],
                                  [287.098, -256.398],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.185, -49.63],
                                  [-33.141, -132.763],
                                  [57.985, -37.985],
                                  [-134.408, -34.378],
                                  [-17.852, -70.341],
                                  [-114.726, 3.244],
                                  [139.149, 82.808],
                                  [-47.956, 151.882],
                                  [-8.533, 92],
                                ],
                                o: [
                                  [-99.111, 50.045],
                                  [33.141, 132.763],
                                  [-57.985, 37.985],
                                  [134.408, 34.378],
                                  [18.237, 70.815],
                                  [111.704, -2.593],
                                  [-139.149, -82.807],
                                  [47.393, -152.386],
                                  [8.533, -92],
                                ],
                                v: [
                                  [-124.835, -412.845],
                                  [-170.086, -188.273],
                                  [-247.116, 45.378],
                                  [-286.263, 137.787],
                                  [-6.382, 305.238],
                                  [130.683, 423.004],
                                  [302.461, 277.48],
                                  [26.008, -148.683],
                                  [286.491, -256.245],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.219, -49.561],
                                  [-33.059, -132.67],
                                  [57.927, -38.042],
                                  [-134.304, -34.389],
                                  [-17.848, -70.293],
                                  [-114.804, 3.28],
                                  [139.009, 82.773],
                                  [-47.897, 151.763],
                                  [-8.549, 92],
                                ],
                                o: [
                                  [-99.086, 49.988],
                                  [33.059, 132.67],
                                  [-57.927, 38.042],
                                  [134.304, 34.389],
                                  [18.244, 70.781],
                                  [111.695, -2.61],
                                  [-139.009, -82.773],
                                  [47.318, -152.281],
                                  [8.549, -92],
                                ],
                                v: [
                                  [-125.586, -413.486],
                                  [-169.752, -188.293],
                                  [-247.785, 45.372],
                                  [-285.613, 137.285],
                                  [-5.961, 305.585],
                                  [130.791, 422.676],
                                  [302.872, 276.92],
                                  [25.203, -149.612],
                                  [285.94, -256.09],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.254, -49.491],
                                  [-32.974, -132.574],
                                  [57.868, -38.101],
                                  [-134.198, -34.4],
                                  [-17.843, -70.244],
                                  [-114.885, 3.317],
                                  [138.865, 82.738],
                                  [-47.836, 151.641],
                                  [-8.565, 92],
                                ],
                                o: [
                                  [-99.059, 49.93],
                                  [32.974, 132.574],
                                  [-57.868, 38.101],
                                  [134.198, 34.4],
                                  [18.251, 70.746],
                                  [111.686, -2.627],
                                  [-138.865, -82.738],
                                  [47.24, -152.174],
                                  [8.564, -92],
                                ],
                                v: [
                                  [-126.393, -414.12],
                                  [-169.359, -188.194],
                                  [-248.451, 45.301],
                                  [-284.964, 136.845],
                                  [-5.606, 305.928],
                                  [130.837, 422.348],
                                  [303.282, 276.298],
                                  [24.348, -150.412],
                                  [285.327, -255.929],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.29, -49.419],
                                  [-32.887, -132.476],
                                  [57.806, -38.161],
                                  [-134.088, -34.411],
                                  [-17.839, -70.193],
                                  [-114.968, 3.355],
                                  [138.717, 82.702],
                                  [-47.774, 151.516],
                                  [-8.581, 92],
                                ],
                                o: [
                                  [-99.032, 49.871],
                                  [32.887, 132.476],
                                  [-57.806, 38.161],
                                  [134.088, 34.411],
                                  [18.258, 70.71],
                                  [111.677, -2.645],
                                  [-138.717, -82.702],
                                  [47.161, -152.064],
                                  [8.581, -92],
                                ],
                                v: [
                                  [-127.137, -414.687],
                                  [-169.026, -188.159],
                                  [-249.175, 45.229],
                                  [-284.254, 136.409],
                                  [-5.258, 306.268],
                                  [130.943, 422.022],
                                  [303.573, 275.615],
                                  [23.501, -151.262],
                                  [284.772, -255.766],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.327, -49.345],
                                  [-32.798, -132.375],
                                  [57.743, -38.223],
                                  [-133.976, -34.423],
                                  [-17.834, -70.142],
                                  [-115.053, 3.394],
                                  [138.566, 82.664],
                                  [-47.71, 151.387],
                                  [-8.597, 92],
                                ],
                                o: [
                                  [-99.004, 49.81],
                                  [32.798, 132.375],
                                  [-57.743, 38.223],
                                  [133.976, 34.423],
                                  [18.265, 70.673],
                                  [111.668, -2.664],
                                  [-138.566, -82.664],
                                  [47.08, -151.951],
                                  [8.597, -92],
                                ],
                                v: [
                                  [-127.877, -415.248],
                                  [-168.635, -188.068],
                                  [-249.9, 45.094],
                                  [-283.486, 136.033],
                                  [-4.978, 306.604],
                                  [130.991, 421.697],
                                  [303.921, 274.932],
                                  [22.662, -152.04],
                                  [284.152, -255.54],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.365, -49.269],
                                  [-32.706, -132.271],
                                  [57.679, -38.287],
                                  [-133.861, -34.435],
                                  [-17.829, -70.089],
                                  [-115.14, 3.434],
                                  [138.411, 82.626],
                                  [-47.645, 151.255],
                                  [-8.614, 92],
                                ],
                                o: [
                                  [-98.976, 49.747],
                                  [32.706, 132.271],
                                  [-57.679, 38.287],
                                  [133.861, 34.435],
                                  [18.273, 70.635],
                                  [111.659, -2.683],
                                  [-138.411, -82.626],
                                  [46.996, -151.836],
                                  [8.614, -92],
                                ],
                                v: [
                                  [-128.553, -415.744],
                                  [-168.243, -187.922],
                                  [-250.681, 44.897],
                                  [-282.657, 135.599],
                                  [-4.701, 306.936],
                                  [131.102, 421.37],
                                  [304.151, 274.251],
                                  [21.831, -152.81],
                                  [283.587, -255.371],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.404, -49.191],
                                  [-32.612, -132.165],
                                  [57.613, -38.352],
                                  [-133.743, -34.448],
                                  [-17.824, -70.034],
                                  [-115.23, 3.474],
                                  [138.251, 82.587],
                                  [-47.578, 151.12],
                                  [-8.632, 92],
                                ],
                                o: [
                                  [-98.947, 49.683],
                                  [32.612, 132.165],
                                  [-57.613, 38.352],
                                  [133.743, 34.448],
                                  [18.281, 70.596],
                                  [111.649, -2.702],
                                  [-138.251, -82.587],
                                  [46.911, -151.717],
                                  [8.632, -92],
                                ],
                                v: [
                                  [-129.284, -416.229],
                                  [-167.79, -187.777],
                                  [-251.402, 44.636],
                                  [-281.831, 135.225],
                                  [-4.434, 307.204],
                                  [131.21, 421.047],
                                  [304.377, 273.509],
                                  [21.012, -153.569],
                                  [283.02, -255.139],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.444, -49.112],
                                  [-32.516, -132.055],
                                  [57.545, -38.419],
                                  [-133.622, -34.46],
                                  [-17.819, -69.978],
                                  [-115.322, 3.516],
                                  [138.088, 82.547],
                                  [-47.509, 150.981],
                                  [-8.65, 92],
                                ],
                                o: [
                                  [-98.917, 49.617],
                                  [32.516, 132.055],
                                  [-57.545, 38.419],
                                  [133.622, 34.46],
                                  [18.289, 70.556],
                                  [111.639, -2.722],
                                  [-138.088, -82.547],
                                  [46.823, -151.595],
                                  [8.65, -92],
                                ],
                                v: [
                                  [-129.891, -416.65],
                                  [-167.399, -187.578],
                                  [-252.178, 44.315],
                                  [-280.946, 134.855],
                                  [-4.171, 307.528],
                                  [131.323, 420.724],
                                  [304.544, 272.707],
                                  [20.141, -154.315],
                                  [282.448, -254.903],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.485, -49.03],
                                  [-32.417, -131.944],
                                  [57.475, -38.488],
                                  [-133.498, -34.473],
                                  [-17.814, -69.921],
                                  [-115.416, 3.56],
                                  [137.92, 82.505],
                                  [-47.438, 150.839],
                                  [-8.668, 92],
                                ],
                                o: [
                                  [-98.886, 49.549],
                                  [32.417, 131.944],
                                  [-57.475, 38.488],
                                  [133.498, 34.473],
                                  [18.297, 70.515],
                                  [111.629, -2.743],
                                  [-137.92, -82.506],
                                  [46.733, -151.47],
                                  [8.668, -92],
                                ],
                                v: [
                                  [-130.495, -417.124],
                                  [-166.945, -187.382],
                                  [-253.012, 43.993],
                                  [-279.999, 134.545],
                                  [-3.978, 307.847],
                                  [131.433, 420.402],
                                  [304.711, 271.903],
                                  [19.341, -154.994],
                                  [281.873, -254.606],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.527, -48.945],
                                  [-32.315, -131.829],
                                  [57.404, -38.558],
                                  [-133.37, -34.487],
                                  [-17.809, -69.862],
                                  [-115.513, 3.604],
                                  [137.748, 82.463],
                                  [-47.365, 150.693],
                                  [-8.687, 92],
                                ],
                                o: [
                                  [-98.855, 49.48],
                                  [32.315, 131.829],
                                  [-57.404, 38.558],
                                  [133.37, 34.487],
                                  [18.305, 70.473],
                                  [111.618, -2.764],
                                  [-137.748, -82.463],
                                  [46.64, -151.342],
                                  [8.687, -92],
                                ],
                                v: [
                                  [-131.095, -417.471],
                                  [-166.495, -187.191],
                                  [-253.846, 43.608],
                                  [-278.996, 134.173],
                                  [-3.85, 308.105],
                                  [131.548, 420.079],
                                  [304.817, 271.043],
                                  [18.552, -155.658],
                                  [281.292, -254.304],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.57, -48.859],
                                  [-32.211, -131.711],
                                  [57.33, -38.63],
                                  [-133.24, -34.501],
                                  [-17.804, -69.801],
                                  [-115.612, 3.649],
                                  [137.571, 82.42],
                                  [-47.291, 150.543],
                                  [-8.707, 92],
                                ],
                                o: [
                                  [-98.822, 49.409],
                                  [32.211, 131.711],
                                  [-57.33, 38.63],
                                  [133.24, 34.501],
                                  [18.314, 70.43],
                                  [111.607, -2.785],
                                  [-137.571, -82.42],
                                  [46.545, -151.21],
                                  [8.707, -92],
                                ],
                                v: [
                                  [-131.629, -417.81],
                                  [-166.042, -186.945],
                                  [-254.615, 43.16],
                                  [-277.995, 133.926],
                                  [-3.671, 308.356],
                                  [131.661, 419.754],
                                  [304.923, 270.182],
                                  [17.773, -156.314],
                                  [280.768, -254],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.615, -48.771],
                                  [-32.105, -131.59],
                                  [57.255, -38.705],
                                  [-133.105, -34.515],
                                  [-17.798, -69.74],
                                  [-115.714, 3.695],
                                  [137.39, 82.375],
                                  [-47.215, 150.389],
                                  [-8.727, 92],
                                ],
                                o: [
                                  [-98.789, 49.336],
                                  [32.105, 131.59],
                                  [-57.255, 38.705],
                                  [133.105, 34.515],
                                  [18.323, 70.385],
                                  [111.596, -2.807],
                                  [-137.39, -82.375],
                                  [46.448, -151.075],
                                  [8.727, -92],
                                ],
                                v: [
                                  [-132.16, -418.142],
                                  [-165.53, -186.643],
                                  [-255.502, 42.712],
                                  [-276.938, 133.616],
                                  [-3.558, 308.664],
                                  [131.836, 419.43],
                                  [304.967, 269.319],
                                  [17.005, -156.897],
                                  [280.178, -253.693],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.66, -48.68],
                                  [-31.995, -131.466],
                                  [57.178, -38.781],
                                  [-132.968, -34.529],
                                  [-17.792, -69.676],
                                  [-115.818, 3.743],
                                  [137.204, 82.329],
                                  [-47.136, 150.231],
                                  [-8.747, 92],
                                ],
                                o: [
                                  [-98.755, 49.261],
                                  [31.995, 131.466],
                                  [-57.178, 38.781],
                                  [132.968, 34.529],
                                  [18.332, 70.34],
                                  [111.585, -2.83],
                                  [-137.204, -82.33],
                                  [46.348, -150.937],
                                  [8.747, -92],
                                ],
                                v: [
                                  [-132.684, -418.467],
                                  [-165.078, -186.408],
                                  [-256.328, 42.204],
                                  [-275.881, 133.369],
                                  [-3.512, 308.909],
                                  [132.012, 419.106],
                                  [304.952, 268.398],
                                  [16.251, -157.469],
                                  [279.644, -253.382],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.707, -48.587],
                                  [-31.883, -131.339],
                                  [57.099, -38.859],
                                  [-132.827, -34.544],
                                  [-17.787, -69.611],
                                  [-115.925, 3.792],
                                  [137.013, 82.283],
                                  [-47.056, 150.069],
                                  [-8.768, 92],
                                ],
                                o: [
                                  [-98.72, 49.184],
                                  [31.883, 131.339],
                                  [-57.099, 38.859],
                                  [132.827, 34.544],
                                  [18.341, 70.293],
                                  [111.573, -2.853],
                                  [-137.013, -82.283],
                                  [46.245, -150.795],
                                  [8.768, -92],
                                ],
                                v: [
                                  [-133.144, -418.665],
                                  [-164.626, -186.116],
                                  [-257.148, 41.695],
                                  [-274.828, 133.184],
                                  [-3.414, 309.148],
                                  [132.188, 418.782],
                                  [304.876, 267.419],
                                  [15.504, -158.027],
                                  [279.104, -253.011],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.754, -48.491],
                                  [-31.767, -131.208],
                                  [57.017, -38.939],
                                  [-132.682, -34.559],
                                  [-17.781, -69.544],
                                  [-116.035, 3.842],
                                  [136.818, 82.235],
                                  [-46.974, 149.903],
                                  [-8.789, 92],
                                ],
                                o: [
                                  [-98.684, 49.105],
                                  [31.767, 131.208],
                                  [-57.017, 38.939],
                                  [132.682, 34.559],
                                  [18.351, 70.246],
                                  [111.561, -2.877],
                                  [-136.818, -82.235],
                                  [46.14, -150.649],
                                  [8.79, -92],
                                ],
                                v: [
                                  [-133.54, -418.914],
                                  [-164.12, -185.769],
                                  [-257.968, 41.123],
                                  [-273.715, 132.998],
                                  [-3.383, 309.383],
                                  [132.366, 418.395],
                                  [304.8, 266.501],
                                  [14.772, -158.516],
                                  [278.56, -252.575],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.803, -48.393],
                                  [-31.649, -131.074],
                                  [56.934, -39.021],
                                  [-132.533, -34.575],
                                  [-17.775, -69.475],
                                  [-116.148, 3.894],
                                  [136.617, 82.185],
                                  [-46.889, 149.733],
                                  [-8.812, 92],
                                ],
                                o: [
                                  [-98.647, 49.024],
                                  [31.649, 131.074],
                                  [-56.934, 39.021],
                                  [132.533, 34.575],
                                  [18.361, 70.197],
                                  [111.549, -2.902],
                                  [-136.617, -82.185],
                                  [46.032, -150.5],
                                  [8.812, -92],
                                ],
                                v: [
                                  [-133.93, -419.099],
                                  [-163.671, -185.485],
                                  [-258.841, 40.492],
                                  [-272.548, 132.812],
                                  [-3.358, 309.555],
                                  [132.605, 418.07],
                                  [304.722, 265.521],
                                  [14.051, -158.99],
                                  [278.069, -252.197],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.854, -48.293],
                                  [-31.528, -130.937],
                                  [56.849, -39.105],
                                  [-132.381, -34.591],
                                  [-17.768, -69.405],
                                  [-116.263, 3.946],
                                  [136.412, 82.135],
                                  [-46.802, 149.559],
                                  [-8.834, 92],
                                ],
                                o: [
                                  [-98.61, 48.941],
                                  [31.528, 130.937],
                                  [-56.849, 39.105],
                                  [132.381, 34.591],
                                  [18.371, 70.146],
                                  [111.537, -2.927],
                                  [-136.411, -82.135],
                                  [45.922, -150.346],
                                  [8.834, -92],
                                ],
                                v: [
                                  [-134.313, -419.213],
                                  [-163.165, -185.149],
                                  [-259.652, 39.862],
                                  [-271.444, 132.688],
                                  [-3.404, 309.78],
                                  [132.784, 417.741],
                                  [304.526, 264.484],
                                  [13.405, -159.449],
                                  [277.516, -251.759],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.905, -48.19],
                                  [-31.404, -130.796],
                                  [56.761, -39.191],
                                  [-132.225, -34.607],
                                  [-17.762, -69.333],
                                  [-116.382, 4],
                                  [136.201, 82.083],
                                  [-46.714, 149.379],
                                  [-8.857, 92],
                                ],
                                o: [
                                  [-98.571, 48.856],
                                  [31.404, 130.796],
                                  [-56.761, 39.191],
                                  [132.225, 34.607],
                                  [18.381, 70.095],
                                  [111.524, -2.953],
                                  [-136.201, -82.083],
                                  [45.809, -150.189],
                                  [8.857, -92],
                                ],
                                v: [
                                  [-134.632, -419.323],
                                  [-162.724, -184.816],
                                  [-260.521, 39.171],
                                  [-270.226, 132.563],
                                  [-3.395, 310.005],
                                  [133.024, 417.416],
                                  [304.331, 263.506],
                                  [12.708, -159.839],
                                  [277.016, -251.32],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.958, -48.084],
                                  [-31.276, -130.652],
                                  [56.671, -39.28],
                                  [-132.065, -34.624],
                                  [-17.755, -69.259],
                                  [-116.503, 4.056],
                                  [135.984, 82.03],
                                  [-46.622, 149.196],
                                  [-8.881, 92],
                                ],
                                o: [
                                  [-98.531, 48.769],
                                  [31.276, 130.652],
                                  [-56.671, 39.28],
                                  [132.065, 34.624],
                                  [18.392, 70.042],
                                  [111.51, -2.979],
                                  [-135.984, -82.03],
                                  [45.692, -150.028],
                                  [8.881, -92],
                                ],
                                v: [
                                  [-134.947, -419.424],
                                  [-162.284, -184.486],
                                  [-261.327, 38.482],
                                  [-269.068, 132.44],
                                  [-3.458, 310.163],
                                  [133.323, 417.027],
                                  [304.134, 262.472],
                                  [12.087, -160.216],
                                  [276.511, -250.814],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.012, -47.976],
                                  [-31.146, -130.504],
                                  [56.579, -39.37],
                                  [-131.901, -34.641],
                                  [-17.748, -69.183],
                                  [-116.628, 4.113],
                                  [135.763, 81.975],
                                  [-46.529, 149.008],
                                  [-8.906, 92],
                                ],
                                o: [
                                  [-98.491, 48.68],
                                  [31.146, 130.504],
                                  [-56.579, 39.37],
                                  [131.901, 34.641],
                                  [18.402, 69.988],
                                  [111.497, -3.006],
                                  [-135.763, -81.975],
                                  [45.573, -149.863],
                                  [8.906, -92],
                                ],
                                v: [
                                  [-135.195, -419.457],
                                  [-161.842, -184.106],
                                  [-262.126, 37.791],
                                  [-267.854, 132.378],
                                  [-3.525, 310.377],
                                  [133.565, 416.698],
                                  [303.878, 261.438],
                                  [11.479, -160.577],
                                  [276.058, -250.312],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.068, -47.865],
                                  [-31.012, -130.352],
                                  [56.485, -39.464],
                                  [-131.732, -34.659],
                                  [-17.742, -69.105],
                                  [-116.756, 4.171],
                                  [135.535, 81.919],
                                  [-46.433, 148.815],
                                  [-8.93, 92],
                                ],
                                o: [
                                  [-98.449, 48.588],
                                  [31.012, 130.352],
                                  [-56.485, 39.463],
                                  [131.732, 34.659],
                                  [18.414, 69.932],
                                  [111.483, -3.034],
                                  [-135.535, -81.919],
                                  [45.451, -149.693],
                                  [8.93, -92],
                                ],
                                v: [
                                  [-135.436, -419.482],
                                  [-161.406, -183.726],
                                  [-262.984, 37.04],
                                  [-266.647, 132.311],
                                  [-3.658, 310.527],
                                  [133.865, 416.309],
                                  [303.561, 260.349],
                                  [10.884, -160.868],
                                  [275.543, -249.806],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.125, -47.751],
                                  [-30.874, -130.197],
                                  [56.388, -39.559],
                                  [-131.559, -34.677],
                                  [-17.734, -69.025],
                                  [-116.887, 4.231],
                                  [135.302, 81.862],
                                  [-46.335, 148.617],
                                  [-8.956, 92],
                                ],
                                o: [
                                  [-98.406, 48.494],
                                  [30.874, 130.197],
                                  [-56.388, 39.559],
                                  [131.559, 34.677],
                                  [18.425, 69.875],
                                  [111.469, -3.062],
                                  [-135.302, -81.862],
                                  [45.326, -149.52],
                                  [8.956, -92],
                                ],
                                v: [
                                  [-135.612, -419.438],
                                  [-160.972, -183.355],
                                  [-263.779, 36.232],
                                  [-265.44, 132.309],
                                  [-3.741, 310.672],
                                  [134.109, 415.976],
                                  [303.186, 259.319],
                                  [10.366, -161.143],
                                  [275.079, -249.238],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.183, -47.634],
                                  [-30.733, -130.037],
                                  [56.289, -39.657],
                                  [-131.383, -34.696],
                                  [-17.727, -68.944],
                                  [-117.021, 4.292],
                                  [135.063, 81.803],
                                  [-46.234, 148.414],
                                  [-8.982, 92],
                                ],
                                o: [
                                  [-98.363, 48.398],
                                  [30.733, 130.037],
                                  [-56.289, 39.657],
                                  [131.383, 34.696],
                                  [18.437, 69.817],
                                  [111.454, -3.092],
                                  [-135.063, -81.803],
                                  [45.197, -149.342],
                                  [8.982, -92],
                                ],
                                v: [
                                  [-135.781, -419.331],
                                  [-160.599, -182.987],
                                  [-264.569, 35.423],
                                  [-264.178, 132.305],
                                  [-3.891, 310.811],
                                  [134.408, 415.587],
                                  [302.812, 258.232],
                                  [9.858, -161.343],
                                  [274.61, -248.666],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.243, -47.514],
                                  [-30.589, -129.874],
                                  [56.187, -39.757],
                                  [-131.201, -34.715],
                                  [-17.72, -68.86],
                                  [-117.159, 4.355],
                                  [134.818, 81.743],
                                  [-46.131, 148.206],
                                  [-9.009, 92],
                                ],
                                o: [
                                  [-98.318, 48.299],
                                  [30.589, 129.874],
                                  [-56.187, 39.757],
                                  [131.201, 34.715],
                                  [18.449, 69.757],
                                  [111.439, -3.121],
                                  [-134.818, -81.743],
                                  [45.066, -149.159],
                                  [9.009, -92],
                                ],
                                v: [
                                  [-135.885, -419.274],
                                  [-160.169, -182.626],
                                  [-265.296, 34.617],
                                  [-262.984, 132.357],
                                  [-4.051, 310.948],
                                  [134.768, 415.253],
                                  [302.439, 257.147],
                                  [9.365, -161.533],
                                  [274.191, -248.095],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.304, -47.391],
                                  [-30.441, -129.706],
                                  [56.083, -39.86],
                                  [-131.015, -34.734],
                                  [-17.712, -68.774],
                                  [-117.3, 4.419],
                                  [134.567, 81.681],
                                  [-46.025, 147.993],
                                  [-9.037, 92],
                                ],
                                o: [
                                  [-98.272, 48.198],
                                  [30.441, 129.706],
                                  [-56.083, 39.86],
                                  [131.015, 34.734],
                                  [18.461, 69.696],
                                  [111.424, -3.152],
                                  [-134.567, -81.681],
                                  [44.931, -148.972],
                                  [9.037, -92],
                                ],
                                v: [
                                  [-135.981, -419.09],
                                  [-159.8, -182.209],
                                  [-266.082, 33.811],
                                  [-261.731, 132.411],
                                  [-4.216, 311.078],
                                  [135.071, 414.86],
                                  [301.947, 256.064],
                                  [8.889, -161.646],
                                  [273.769, -247.465],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.367, -47.266],
                                  [-30.289, -129.535],
                                  [55.976, -39.965],
                                  [-130.824, -34.755],
                                  [-17.704, -68.686],
                                  [-117.444, 4.486],
                                  [134.309, 81.618],
                                  [-45.917, 147.774],
                                  [-9.065, 92],
                                ],
                                o: [
                                  [-98.225, 48.094],
                                  [30.289, 129.535],
                                  [-55.976, 39.965],
                                  [130.824, 34.755],
                                  [18.473, 69.633],
                                  [111.408, -3.184],
                                  [-134.309, -81.618],
                                  [44.792, -148.78],
                                  [9.065, -92],
                                ],
                                v: [
                                  [-136.014, -418.9],
                                  [-159.491, -181.858],
                                  [-266.804, 32.949],
                                  [-260.486, 132.466],
                                  [-4.449, 311.207],
                                  [135.431, 414.526],
                                  [301.455, 254.984],
                                  [8.488, -161.747],
                                  [273.333, -246.774],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.432, -47.137],
                                  [-30.134, -129.359],
                                  [55.866, -40.073],
                                  [-130.629, -34.775],
                                  [-17.696, -68.596],
                                  [-117.593, 4.553],
                                  [134.045, 81.553],
                                  [-45.805, 147.55],
                                  [-9.094, 92],
                                ],
                                o: [
                                  [-98.176, 47.988],
                                  [30.134, 129.359],
                                  [-55.866, 40.073],
                                  [130.629, 34.775],
                                  [18.486, 69.568],
                                  [111.392, -3.216],
                                  [-134.045, -81.553],
                                  [44.65, -148.583],
                                  [9.094, -92],
                                ],
                                v: [
                                  [-136.041, -418.702],
                                  [-159.126, -181.452],
                                  [-267.522, 32.029],
                                  [-259.243, 132.577],
                                  [-4.63, 311.271],
                                  [135.79, 414.133],
                                  [300.966, 253.848],
                                  [8.103, -161.829],
                                  [272.895, -246.139],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.498, -47.005],
                                  [-29.974, -129.178],
                                  [55.754, -40.184],
                                  [-130.429, -34.796],
                                  [-17.688, -68.503],
                                  [-117.745, 4.623],
                                  [133.775, 81.487],
                                  [-45.691, 147.32],
                                  [-9.124, 92],
                                ],
                                o: [
                                  [-98.127, 47.879],
                                  [29.974, 129.178],
                                  [-55.754, 40.184],
                                  [130.429, 34.796],
                                  [18.5, 69.502],
                                  [111.376, -3.249],
                                  [-133.775, -81.487],
                                  [44.505, -148.382],
                                  [9.124, -92],
                                ],
                                v: [
                                  [-136.059, -418.436],
                                  [-158.822, -181.053],
                                  [-268.177, 31.168],
                                  [-258.009, 132.685],
                                  [-4.878, 311.388],
                                  [136.15, 413.794],
                                  [300.357, 252.774],
                                  [7.791, -161.841],
                                  [272.505, -245.385],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.565, -46.869],
                                  [-29.811, -128.993],
                                  [55.639, -40.297],
                                  [-130.224, -34.818],
                                  [-17.679, -68.408],
                                  [-117.901, 4.694],
                                  [133.498, 81.419],
                                  [-45.575, 147.085],
                                  [-9.154, 92],
                                ],
                                o: [
                                  [-98.076, 47.767],
                                  [29.811, 128.993],
                                  [-55.639, 40.297],
                                  [130.224, 34.818],
                                  [18.513, 69.435],
                                  [111.359, -3.283],
                                  [-133.498, -81.419],
                                  [44.356, -148.175],
                                  [9.154, -92],
                                ],
                                v: [
                                  [-136.014, -418.161],
                                  [-158.578, -180.657],
                                  [-268.886, 30.248],
                                  [-256.835, 132.852],
                                  [-5.136, 311.444],
                                  [136.509, 413.397],
                                  [299.808, 251.702],
                                  [7.492, -161.837],
                                  [272.107, -244.69],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.635, -46.73],
                                  [-29.644, -128.804],
                                  [55.521, -40.413],
                                  [-130.013, -34.84],
                                  [-17.671, -68.311],
                                  [-118.06, 4.767],
                                  [133.214, 81.349],
                                  [-45.455, 146.844],
                                  [-9.186, 92],
                                ],
                                o: [
                                  [-98.024, 47.653],
                                  [29.644, 128.804],
                                  [-55.521, 40.413],
                                  [130.013, 34.84],
                                  [18.527, 69.365],
                                  [111.341, -3.317],
                                  [-133.214, -81.349],
                                  [44.203, -147.964],
                                  [9.186, -92],
                                ],
                                v: [
                                  [-135.9, -417.88],
                                  [-158.336, -180.269],
                                  [-269.536, 29.334],
                                  [-255.612, 133.02],
                                  [-5.4, 311.494],
                                  [136.923, 412.995],
                                  [299.144, 250.575],
                                  [7.272, -161.758],
                                  [271.761, -243.935],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.706, -46.588],
                                  [-29.472, -128.609],
                                  [55.4, -40.532],
                                  [-129.798, -34.862],
                                  [-17.662, -68.212],
                                  [-118.224, 4.841],
                                  [132.923, 81.277],
                                  [-45.332, 146.597],
                                  [-9.218, 92],
                                ],
                                o: [
                                  [-97.971, 47.535],
                                  [29.472, 128.609],
                                  [-55.4, 40.532],
                                  [129.798, 34.862],
                                  [18.541, 69.294],
                                  [111.323, -3.353],
                                  [-132.923, -81.277],
                                  [44.047, -147.747],
                                  [9.218, -92],
                                ],
                                v: [
                                  [-135.781, -417.474],
                                  [-158.096, -179.888],
                                  [-270.123, 28.42],
                                  [-254.39, 133.187],
                                  [-5.674, 311.542],
                                  [137.282, 412.652],
                                  [298.482, 249.505],
                                  [7.068, -161.604],
                                  [271.344, -243.178],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.779, -46.442],
                                  [-29.296, -128.41],
                                  [55.276, -40.654],
                                  [-129.577, -34.886],
                                  [-17.653, -68.11],
                                  [-118.391, 4.918],
                                  [132.624, 81.204],
                                  [-45.207, 146.344],
                                  [-9.25, 92],
                                ],
                                o: [
                                  [-97.916, 47.415],
                                  [29.296, 128.41],
                                  [-55.276, 40.654],
                                  [129.577, 34.886],
                                  [18.556, 69.221],
                                  [111.305, -3.389],
                                  [-132.624, -81.204],
                                  [43.887, -147.525],
                                  [9.25, -92],
                                ],
                                v: [
                                  [-135.653, -417.116],
                                  [-157.916, -179.569],
                                  [-270.705, 27.507],
                                  [-253.233, 133.409],
                                  [-5.958, 311.589],
                                  [137.696, 412.25],
                                  [297.822, 248.385],
                                  [6.94, -161.492],
                                  [270.978, -242.365],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.853, -46.293],
                                  [-29.116, -128.207],
                                  [55.149, -40.78],
                                  [-129.351, -34.91],
                                  [-17.643, -68.005],
                                  [-118.563, 4.996],
                                  [132.319, 81.129],
                                  [-45.078, 146.084],
                                  [-9.284, 92],
                                ],
                                o: [
                                  [-97.86, 47.292],
                                  [29.116, 128.207],
                                  [-55.149, 40.779],
                                  [129.351, 34.91],
                                  [18.571, 69.147],
                                  [111.287, -3.427],
                                  [-132.319, -81.129],
                                  [43.722, -147.297],
                                  [9.284, -92],
                                ],
                                v: [
                                  [-135.46, -416.695],
                                  [-157.738, -179.196],
                                  [-271.283, 26.542],
                                  [-252.024, 133.684],
                                  [-6.247, 311.625],
                                  [138.109, 411.847],
                                  [297.103, 247.268],
                                  [6.833, -161.251],
                                  [270.657, -241.492],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.93, -46.14],
                                  [-28.932, -127.998],
                                  [55.019, -40.908],
                                  [-129.119, -34.934],
                                  [-17.634, -67.898],
                                  [-118.739, 5.076],
                                  [132.006, 81.052],
                                  [-44.946, 145.819],
                                  [-9.318, 92],
                                ],
                                o: [
                                  [-97.803, 47.166],
                                  [28.932, 127.998],
                                  [-55.019, 40.908],
                                  [129.119, 34.934],
                                  [18.586, 69.07],
                                  [111.268, -3.465],
                                  [-132.006, -81.052],
                                  [43.554, -147.064],
                                  [9.318, -92],
                                ],
                                v: [
                                  [-135.199, -416.207],
                                  [-157.619, -178.83],
                                  [-271.799, 25.579],
                                  [-250.879, 133.959],
                                  [-6.61, 311.663],
                                  [138.575, 411.498],
                                  [296.332, 246.212],
                                  [6.798, -160.992],
                                  [270.331, -240.677],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.008, -45.983],
                                  [-28.743, -127.784],
                                  [54.886, -41.039],
                                  [-128.881, -34.959],
                                  [-17.624, -67.788],
                                  [-118.919, 5.159],
                                  [131.685, 80.973],
                                  [-44.811, 145.546],
                                  [-9.354, 92],
                                ],
                                o: [
                                  [-97.744, 47.036],
                                  [28.742, 127.784],
                                  [-54.886, 41.039],
                                  [128.881, 34.959],
                                  [18.602, 68.992],
                                  [111.248, -3.504],
                                  [-131.685, -80.973],
                                  [43.382, -146.825],
                                  [9.354, -92],
                                ],
                                v: [
                                  [-134.932, -415.709],
                                  [-157.559, -178.467],
                                  [-272.314, 24.613],
                                  [-249.798, 134.232],
                                  [-6.916, 311.696],
                                  [139.04, 411.09],
                                  [295.559, 245.16],
                                  [6.839, -160.717],
                                  [269.987, -239.802],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.089, -45.823],
                                  [-28.549, -127.564],
                                  [54.749, -41.173],
                                  [-128.638, -34.984],
                                  [-17.614, -67.676],
                                  [-119.104, 5.243],
                                  [131.356, 80.892],
                                  [-44.672, 145.267],
                                  [-9.39, 92],
                                ],
                                o: [
                                  [-97.684, 46.904],
                                  [28.549, 127.564],
                                  [-54.749, 41.173],
                                  [128.638, 34.984],
                                  [18.618, 68.911],
                                  [111.228, -3.544],
                                  [-131.356, -80.892],
                                  [43.205, -146.58],
                                  [9.39, -92],
                                ],
                                v: [
                                  [-134.655, -415.207],
                                  [-157.501, -178.17],
                                  [-272.762, 23.654],
                                  [-248.723, 134.563],
                                  [-7.295, 311.669],
                                  [139.447, 410.736],
                                  [294.731, 244.055],
                                  [6.897, -160.365],
                                  [269.638, -238.868],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.171, -45.658],
                                  [-28.35, -127.34],
                                  [54.61, -41.311],
                                  [-128.388, -35.011],
                                  [-17.604, -67.561],
                                  [-119.293, 5.329],
                                  [131.019, 80.809],
                                  [-44.53, 144.981],
                                  [-9.427, 92],
                                ],
                                o: [
                                  [-97.622, 46.768],
                                  [28.35, 127.34],
                                  [-54.61, 41.311],
                                  [128.388, 35.011],
                                  [18.634, 68.829],
                                  [111.207, -3.585],
                                  [-131.02, -80.809],
                                  [43.024, -146.329],
                                  [9.427, -92],
                                ],
                                v: [
                                  [-134.313, -414.642],
                                  [-157.497, -177.877],
                                  [-273.211, 22.702],
                                  [-247.655, 134.945],
                                  [-7.622, 311.637],
                                  [139.968, 410.322],
                                  [293.906, 243.015],
                                  [7.032, -160.001],
                                  [269.332, -237.939],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.255, -45.49],
                                  [-28.147, -127.11],
                                  [54.466, -41.452],
                                  [-128.133, -35.038],
                                  [-17.593, -67.443],
                                  [-119.487, 5.418],
                                  [130.675, 80.725],
                                  [-44.385, 144.689],
                                  [-9.465, 92],
                                ],
                                o: [
                                  [-97.559, 46.629],
                                  [28.147, 127.11],
                                  [-54.466, 41.452],
                                  [128.133, 35.038],
                                  [18.651, 68.745],
                                  [111.186, -3.628],
                                  [-130.675, -80.725],
                                  [42.839, -146.072],
                                  [9.465, -92],
                                ],
                                v: [
                                  [-133.961, -414.011],
                                  [-157.499, -177.591],
                                  [-273.597, 21.748],
                                  [-246.589, 135.326],
                                  [-8.018, 311.661],
                                  [140.425, 409.961],
                                  [293.027, 241.975],
                                  [7.243, -159.56],
                                  [269.015, -237.004],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.341, -45.317],
                                  [-27.939, -126.874],
                                  [54.32, -41.597],
                                  [-127.871, -35.065],
                                  [-17.582, -67.322],
                                  [-119.685, 5.508],
                                  [130.321, 80.638],
                                  [-44.236, 144.389],
                                  [-9.504, 92],
                                ],
                                o: [
                                  [-97.494, 46.487],
                                  [27.939, 126.874],
                                  [-54.32, 41.597],
                                  [127.872, 35.065],
                                  [18.668, 68.659],
                                  [111.165, -3.671],
                                  [-130.321, -80.638],
                                  [42.649, -145.809],
                                  [9.504, -92],
                                ],
                                v: [
                                  [-133.544, -413.427],
                                  [-157.56, -177.308],
                                  [-273.979, 20.796],
                                  [-245.645, 135.699],
                                  [-8.367, 311.621],
                                  [140.877, 409.541],
                                  [292.151, 240.944],
                                  [7.531, -159.101],
                                  [268.74, -236.017],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.43, -45.141],
                                  [-27.726, -126.633],
                                  [54.169, -41.745],
                                  [-127.604, -35.093],
                                  [-17.571, -67.198],
                                  [-119.888, 5.601],
                                  [129.959, 80.549],
                                  [-44.084, 144.082],
                                  [-9.543, 92],
                                ],
                                o: [
                                  [-97.428, 46.341],
                                  [27.726, 126.633],
                                  [-54.169, 41.745],
                                  [127.604, 35.093],
                                  [18.686, 68.57],
                                  [111.143, -3.715],
                                  [-129.959, -80.549],
                                  [42.455, -145.539],
                                  [9.543, -92],
                                ],
                                v: [
                                  [-133.115, -412.73],
                                  [-157.678, -177.034],
                                  [-274.294, 19.847],
                                  [-244.65, 136.128],
                                  [-8.785, 311.586],
                                  [141.389, 409.173],
                                  [291.221, 239.918],
                                  [7.889, -158.572],
                                  [268.395, -235.03],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.52, -44.959],
                                  [-27.507, -126.385],
                                  [54.016, -41.896],
                                  [-127.329, -35.122],
                                  [-17.56, -67.072],
                                  [-120.097, 5.696],
                                  [129.589, 80.458],
                                  [-43.928, 143.767],
                                  [-9.584, 92],
                                ],
                                o: [
                                  [-97.36, 46.192],
                                  [27.507, 126.385],
                                  [-54.016, 41.896],
                                  [127.329, 35.122],
                                  [18.704, 68.48],
                                  [111.12, -3.76],
                                  [-129.589, -80.458],
                                  [42.255, -145.263],
                                  [9.584, -92],
                                ],
                                v: [
                                  [-132.674, -412.076],
                                  [-157.848, -176.819],
                                  [-274.609, 18.901],
                                  [-243.776, 136.611],
                                  [-9.152, 311.546],
                                  [141.888, 408.803],
                                  [290.295, 238.954],
                                  [8.273, -157.966],
                                  [268.153, -233.985],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.613, -44.774],
                                  [-27.284, -126.132],
                                  [53.858, -42.052],
                                  [-127.048, -35.152],
                                  [-17.548, -66.942],
                                  [-120.31, 5.794],
                                  [129.209, 80.364],
                                  [-43.768, 143.445],
                                  [-9.626, 92],
                                ],
                                o: [
                                  [-97.29, 46.039],
                                  [27.284, 126.132],
                                  [-53.858, 42.052],
                                  [127.048, 35.152],
                                  [18.723, 68.387],
                                  [111.097, -3.806],
                                  [-129.209, -80.364],
                                  [42.051, -144.98],
                                  [9.626, -92],
                                ],
                                v: [
                                  [-132.169, -411.361],
                                  [-158.024, -176.669],
                                  [-274.812, 17.963],
                                  [-242.909, 137.091],
                                  [-9.588, 311.505],
                                  [142.392, 408.432],
                                  [289.374, 237.937],
                                  [8.789, -157.347],
                                  [267.842, -232.942],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.708, -44.584],
                                  [-27.055, -125.873],
                                  [53.697, -42.211],
                                  [-126.76, -35.182],
                                  [-17.536, -66.809],
                                  [-120.528, 5.893],
                                  [128.821, 80.269],
                                  [-43.604, 143.115],
                                  [-9.669, 92],
                                ],
                                o: [
                                  [-97.219, 45.882],
                                  [27.055, 125.873],
                                  [-53.697, 42.211],
                                  [126.76, 35.182],
                                  [18.742, 68.292],
                                  [111.073, -3.854],
                                  [-128.821, -80.269],
                                  [41.843, -144.691],
                                  [9.669, -92],
                                ],
                                v: [
                                  [-131.595, -410.586],
                                  [-158.257, -176.521],
                                  [-275.062, 17.024],
                                  [-242.106, 137.619],
                                  [-9.978, 311.459],
                                  [142.938, 408.054],
                                  [288.348, 236.987],
                                  [9.326, -156.703],
                                  [267.569, -231.843],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.805, -44.39],
                                  [-26.82, -125.607],
                                  [53.531, -42.374],
                                  [-126.466, -35.213],
                                  [-17.524, -66.673],
                                  [-120.752, 5.995],
                                  [128.423, 80.171],
                                  [-43.436, 142.777],
                                  [-9.712, 92],
                                ],
                                o: [
                                  [-97.146, 45.721],
                                  [26.82, 125.607],
                                  [-53.531, 42.374],
                                  [126.466, 35.213],
                                  [18.761, 68.195],
                                  [111.049, -3.903],
                                  [-128.423, -80.171],
                                  [41.629, -144.394],
                                  [9.712, -92],
                                ],
                                v: [
                                  [-131.063, -409.865],
                                  [-158.597, -176.375],
                                  [-275.194, 16.088],
                                  [-241.36, 138.143],
                                  [-10.437, 311.356],
                                  [143.431, 407.669],
                                  [287.378, 236.043],
                                  [9.99, -155.994],
                                  [267.282, -230.738],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [103.905, -44.191],
                                  [-26.58, -125.335],
                                  [53.362, -42.54],
                                  [-126.164, -35.245],
                                  [-17.512, -66.533],
                                  [-120.981, 6.1],
                                  [128.015, 80.071],
                                  [-43.264, 142.431],
                                  [-9.757, 92],
                                ],
                                o: [
                                  [-97.071, 45.557],
                                  [26.58, 125.335],
                                  [-53.362, 42.54],
                                  [126.164, 35.245],
                                  [18.781, 68.095],
                                  [111.024, -3.952],
                                  [-128.015, -80.071],
                                  [41.41, -144.091],
                                  [9.757, -92],
                                ],
                                v: [
                                  [-130.467, -409.022],
                                  [-158.936, -176.293],
                                  [-275.321, 15.15],
                                  [-240.677, 138.663],
                                  [-10.853, 311.31],
                                  [143.965, 407.282],
                                  [286.357, 235.111],
                                  [10.736, -155.21],
                                  [267.032, -229.641],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.007, -43.987],
                                  [-26.334, -125.057],
                                  [53.189, -42.711],
                                  [-125.855, -35.277],
                                  [-17.499, -66.391],
                                  [-121.215, 6.207],
                                  [127.598, 79.968],
                                  [-43.088, 142.077],
                                  [-9.803, 92],
                                ],
                                o: [
                                  [-96.995, 45.389],
                                  [26.334, 125.057],
                                  [-53.189, 42.711],
                                  [125.855, 35.277],
                                  [18.801, 67.993],
                                  [110.998, -4.003],
                                  [-127.598, -79.968],
                                  [41.185, -143.78],
                                  [9.803, -92],
                                ],
                                v: [
                                  [-129.801, -408.233],
                                  [-159.337, -176.268],
                                  [-275.389, 14.222],
                                  [-240.057, 139.285],
                                  [-11.28, 311.209],
                                  [144.503, 406.95],
                                  [285.346, 234.24],
                                  [11.496, -154.406],
                                  [266.767, -228.546],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.111, -43.778],
                                  [-26.082, -124.772],
                                  [53.011, -42.886],
                                  [-125.538, -35.31],
                                  [-17.486, -66.245],
                                  [-121.455, 6.317],
                                  [127.17, 79.863],
                                  [-42.908, 141.714],
                                  [-9.85, 92],
                                ],
                                o: [
                                  [-96.917, 45.217],
                                  [26.082, 124.772],
                                  [-53.011, 42.886],
                                  [125.538, 35.31],
                                  [18.822, 67.889],
                                  [110.972, -4.056],
                                  [-127.17, -79.863],
                                  [40.956, -143.461],
                                  [9.85, -92],
                                ],
                                v: [
                                  [-129.12, -407.379],
                                  [-159.73, -176.251],
                                  [-275.458, 13.291],
                                  [-239.5, 139.909],
                                  [-11.771, 311.166],
                                  [145.081, 406.553],
                                  [284.285, 233.375],
                                  [12.277, -153.532],
                                  [266.486, -227.396],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.218, -43.564],
                                  [-25.824, -124.48],
                                  [52.83, -43.065],
                                  [-125.214, -35.344],
                                  [-17.473, -66.095],
                                  [-121.701, 6.429],
                                  [126.733, 79.756],
                                  [-42.724, 141.343],
                                  [-9.898, 92],
                                ],
                                o: [
                                  [-96.837, 45.04],
                                  [25.824, 124.48],
                                  [-52.829, 43.065],
                                  [125.214, 35.344],
                                  [18.844, 67.782],
                                  [110.945, -4.109],
                                  [-126.733, -79.756],
                                  [40.721, -143.135],
                                  [9.898, -92],
                                ],
                                v: [
                                  [-128.431, -406.474],
                                  [-160.233, -176.29],
                                  [-275.461, 12.421],
                                  [-238.998, 140.521],
                                  [-12.225, 311.061],
                                  [145.542, 406.147],
                                  [283.286, 232.523],
                                  [13.134, -152.638],
                                  [266.246, -226.193],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.327, -43.345],
                                  [-25.56, -124.181],
                                  [52.644, -43.248],
                                  [-124.883, -35.379],
                                  [-17.459, -65.942],
                                  [-121.953, 6.544],
                                  [126.285, 79.646],
                                  [-42.535, 140.962],
                                  [-9.947, 92],
                                ],
                                o: [
                                  [-96.754, 44.86],
                                  [25.56, 124.181],
                                  [-52.644, 43.248],
                                  [124.883, 35.379],
                                  [18.865, 67.673],
                                  [110.918, -4.164],
                                  [-126.285, -79.646],
                                  [40.48, -142.802],
                                  [9.947, -92],
                                ],
                                v: [
                                  [-127.726, -405.617],
                                  [-160.79, -176.385],
                                  [-275.405, 11.492],
                                  [-238.621, 141.182],
                                  [-12.685, 310.959],
                                  [145.944, 405.684],
                                  [282.188, 231.74],
                                  [14.074, -151.612],
                                  [265.927, -225.041],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.439, -43.121],
                                  [-25.29, -123.875],
                                  [52.453, -43.436],
                                  [-124.543, -35.415],
                                  [-17.445, -65.785],
                                  [-122.21, 6.661],
                                  [125.826, 79.533],
                                  [-42.342, 140.573],
                                  [-9.998, 92],
                                ],
                                o: [
                                  [-96.67, 44.675],
                                  [25.29, 123.875],
                                  [-52.453, 43.436],
                                  [124.543, 35.415],
                                  [18.888, 67.561],
                                  [110.89, -4.22],
                                  [-125.826, -79.533],
                                  [40.233, -142.46],
                                  [9.998, -92],
                                ],
                                v: [
                                  [-126.949, -404.696],
                                  [-161.399, -176.542],
                                  [-275.344, 10.629],
                                  [-238.235, 141.831],
                                  [-13.167, 310.859],
                                  [146.336, 405.155],
                                  [281.146, 230.963],
                                  [14.967, -150.58],
                                  [265.702, -223.843],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.554, -42.892],
                                  [-25.013, -123.562],
                                  [52.258, -43.628],
                                  [-124.196, -35.452],
                                  [-17.431, -65.624],
                                  [-122.474, 6.782],
                                  [125.357, 79.418],
                                  [-42.144, 140.175],
                                  [-10.049, 92],
                                ],
                                o: [
                                  [-96.584, 44.486],
                                  [25.013, 123.562],
                                  [-52.258, 43.628],
                                  [124.196, 35.452],
                                  [18.911, 67.446],
                                  [110.862, -4.277],
                                  [-125.357, -79.418],
                                  [39.981, -142.11],
                                  [10.049, -92],
                                ],
                                v: [
                                  [-126.162, -403.727],
                                  [-162.06, -176.759],
                                  [-275.169, 9.757],
                                  [-237.917, 142.535],
                                  [-13.607, 310.762],
                                  [146.606, 404.625],
                                  [280.062, 230.193],
                                  [15.999, -149.522],
                                  [265.403, -222.585],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.671, -42.657],
                                  [-24.73, -123.241],
                                  [52.059, -43.825],
                                  [-123.84, -35.489],
                                  [-17.416, -65.46],
                                  [-122.744, 6.905],
                                  [124.876, 79.299],
                                  [-41.942, 139.767],
                                  [-10.102, 92],
                                ],
                                o: [
                                  [-96.496, 44.292],
                                  [24.73, 123.241],
                                  [-52.059, 43.825],
                                  [123.84, 35.489],
                                  [18.934, 67.329],
                                  [110.832, -4.336],
                                  [-124.877, -79.299],
                                  [39.723, -141.752],
                                  [10.102, -92],
                                ],
                                v: [
                                  [-125.359, -402.813],
                                  [-162.766, -176.975],
                                  [-275.053, 8.889],
                                  [-237.652, 143.225],
                                  [-14.109, 310.614],
                                  [146.817, 403.982],
                                  [278.992, 229.5],
                                  [17.053, -148.339],
                                  [265.134, -221.386],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.792, -42.417],
                                  [-24.44, -122.913],
                                  [51.854, -44.026],
                                  [-123.476, -35.527],
                                  [-17.401, -65.292],
                                  [-123.02, 7.031],
                                  [124.385, 79.179],
                                  [-41.735, 139.349],
                                  [-10.156, 92],
                                ],
                                o: [
                                  [-96.406, 44.094],
                                  [24.44, 122.913],
                                  [-51.854, 44.026],
                                  [123.476, 35.527],
                                  [18.958, 67.208],
                                  [110.802, -4.396],
                                  [-124.385, -79.179],
                                  [39.459, -141.386],
                                  [10.156, -92],
                                ],
                                v: [
                                  [-124.483, -401.837],
                                  [-163.529, -177.299],
                                  [-274.817, 8.08],
                                  [-237.393, 143.914],
                                  [-14.579, 310.525],
                                  [147.017, 403.323],
                                  [277.93, 228.815],
                                  [18.121, -147.144],
                                  [264.846, -220.128],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [104.915, -42.171],
                                  [-24.144, -122.577],
                                  [51.645, -44.232],
                                  [-123.103, -35.567],
                                  [-17.386, -65.12],
                                  [-123.303, 7.16],
                                  [123.881, 79.055],
                                  [-41.523, 138.922],
                                  [-10.212, 92],
                                ],
                                o: [
                                  [-96.314, 43.891],
                                  [24.144, 122.577],
                                  [-51.645, 44.232],
                                  [123.103, 35.566],
                                  [18.983, 67.085],
                                  [110.771, -4.457],
                                  [-123.881, -79.055],
                                  [39.188, -141.011],
                                  [10.212, -92],
                                ],
                                v: [
                                  [-123.596, -400.807],
                                  [-164.335, -177.628],
                                  [-274.522, 7.267],
                                  [-237.193, 144.588],
                                  [-15.118, 310.378],
                                  [147.096, 402.669],
                                  [276.877, 228.138],
                                  [19.219, -145.93],
                                  [264.538, -218.827],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.04, -41.919],
                                  [-23.84, -122.234],
                                  [51.431, -44.443],
                                  [-122.721, -35.607],
                                  [-17.37, -64.944],
                                  [-123.593, 7.292],
                                  [123.366, 78.928],
                                  [-41.305, 138.485],
                                  [-10.268, 92],
                                ],
                                o: [
                                  [-96.22, 43.683],
                                  [23.84, 122.234],
                                  [-51.431, 44.443],
                                  [122.721, 35.607],
                                  [19.008, 66.96],
                                  [110.74, -4.52],
                                  [-123.366, -78.928],
                                  [38.911, -140.627],
                                  [10.268, -92],
                                ],
                                v: [
                                  [-122.691, -399.834],
                                  [-165.189, -178.007],
                                  [-274.225, 6.451],
                                  [-237.044, 145.254],
                                  [-15.619, 310.298],
                                  [147.117, 401.944],
                                  [275.793, 227.54],
                                  [20.387, -144.593],
                                  [264.264, -217.576],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.169, -41.662],
                                  [-23.529, -121.882],
                                  [51.212, -44.658],
                                  [-122.331, -35.648],
                                  [-17.354, -64.763],
                                  [-123.889, 7.428],
                                  [122.839, 78.799],
                                  [-41.083, 138.037],
                                  [-10.326, 92],
                                ],
                                o: [
                                  [-96.123, 43.471],
                                  [23.529, 121.882],
                                  [-51.212, 44.658],
                                  [122.331, 35.648],
                                  [19.034, 66.831],
                                  [110.708, -4.585],
                                  [-122.839, -78.799],
                                  [38.628, -140.234],
                                  [10.326, -92],
                                ],
                                v: [
                                  [-121.766, -398.809],
                                  [-166.037, -178.382],
                                  [-273.917, 5.638],
                                  [-236.954, 145.911],
                                  [-16.136, 310.162],
                                  [147.072, 401.17],
                                  [274.766, 227.005],
                                  [21.632, -143.237],
                                  [263.922, -216.268],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.301, -41.398],
                                  [-23.211, -121.522],
                                  [50.988, -44.879],
                                  [-121.932, -35.69],
                                  [-17.337, -64.579],
                                  [-124.192, 7.566],
                                  [122.299, 78.666],
                                  [-40.856, 137.579],
                                  [-10.385, 92],
                                ],
                                o: [
                                  [-96.024, 43.253],
                                  [23.211, 121.522],
                                  [-50.988, 44.879],
                                  [121.932, 35.69],
                                  [19.06, 66.699],
                                  [110.675, -4.65],
                                  [-122.299, -78.666],
                                  [38.338, -139.832],
                                  [10.385, -92],
                                ],
                                v: [
                                  [-120.829, -397.787],
                                  [-166.932, -178.76],
                                  [-273.505, 4.866],
                                  [-236.868, 146.551],
                                  [-16.669, 310.031],
                                  [146.969, 400.323],
                                  [273.701, 226.495],
                                  [22.9, -141.869],
                                  [263.613, -214.963],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.436, -41.128],
                                  [-22.886, -121.153],
                                  [50.759, -45.105],
                                  [-121.523, -35.733],
                                  [-17.321, -64.39],
                                  [-124.502, 7.708],
                                  [121.747, 78.53],
                                  [-40.623, 137.11],
                                  [-10.446, 92],
                                ],
                                o: [
                                  [-95.923, 43.031],
                                  [22.886, 121.153],
                                  [-50.759, 45.105],
                                  [121.523, 35.733],
                                  [19.087, 66.564],
                                  [110.641, -4.718],
                                  [-121.747, -78.53],
                                  [38.041, -139.421],
                                  [10.446, -92],
                                ],
                                v: [
                                  [-119.872, -396.715],
                                  [-167.874, -179.249],
                                  [-273.092, 4.098],
                                  [-236.786, 147.244],
                                  [-17.173, 309.908],
                                  [146.855, 399.476],
                                  [272.656, 225.996],
                                  [24.191, -140.436],
                                  [263.281, -213.663],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [105.574, -40.852],
                                  [-22.553, -120.776],
                                  [50.524, -45.336],
                                  [-121.104, -35.777],
                                  [-17.303, -64.197],
                                  [-124.82, 7.853],
                                  [121.182, 78.391],
                                  [-40.385, 136.631],
                                  [-10.508, 92],
                                ],
                                o: [
                                  [-95.82, 42.803],
                                  [22.553, 120.776],
                                  [-50.524, 45.336],
                                  [121.104, 35.777],
                                  [19.115, 66.426],
                                  [110.607, -4.787],
                                  [-121.182, -78.391],
                                  [37.738, -139],
                                  [10.508, -92],
                                ],
                                v: [
                                  [-118.838, -395.702],
                                  [-168.807, -179.732],
                                  [-272.614, 3.324],
                                  [-236.753, 147.872],
                                  [-17.696, 309.802],
                                  [146.621, 398.626],
                                  [271.622, 225.56],
                                  [25.498, -138.93],
                                  [262.99, -212.359],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [-0.688, 1.531],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.375, 1.281],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.656, 1.156],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1, 1.031],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.938, 0.844],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.969, 0.75],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.938, 0.688],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.969, 0.594],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.031, 0.562],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.031, 0.469],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.094, 0.469],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.125, 0.406],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.188, 0.344],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.188, 0.312],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.25, 0.25],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.281, 0.25],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.375, 0.25],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.375, 0.188],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.406, 0.219],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.406, 0.156],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 0.125],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.406, 0.125],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.375, 0.062],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.375, 0.031],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.281, 0],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.219, 0],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.094, -0.062],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24, -0.125],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.844, -0.188],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.656, -0.219],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.344, -0.312],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, -0.375],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.812, -0.5],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.031, -0.594],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.281, -0.688],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.781, -1],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28, -1.125],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, -1.312],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.562, -1.531],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.812, -1.719],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.125, -1.938],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.375, -2.125],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.656, -2.312],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.969, -2.531],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.25, -2.688],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.562, -2.812],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.875, -2.969],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.188, -3.062],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.531, -3.219],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, -3.281],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.188, -3.344],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.5, -3.375],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.875, -3.438],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.25, -3.469],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.594, -3.5],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.969, -3.469],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.312, -3.469],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.688, -3.406],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.094, -3.406],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.406, -3.344],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.875, -3.25],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.25, -3.188],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.625, -3.062],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, -2.938],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.469, -2.781],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, -2.688],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.281, -2.5],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.688, -2.344],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.094, -2.156],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.531, -1.938],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.938, -1.719],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, -1.5],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.781, -1.281],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.188, -1.031],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.562, -0.75],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.969, -0.469],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.344, -0.188],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.688, 0.156],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.062, 0.438],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.094, 1.469],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.406, 1.844],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.688, 2.25],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45, 2.625],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.281, 3],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.281, 3.469],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.219, 3.938],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.188, 4.469],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.156, 4.969],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.062, 5.562],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.969, 6.125],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.906, 6.656],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.719, 7.906],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.469, 9.156],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.219, 10.531],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.062, 11.188],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.938, 11.812],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.781, 12.5],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.594, 13.156],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.438, 13.812],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.281, 14.438],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.125, 15.125],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.969, 15.75],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.781, 16.344],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.594, 16.969],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.406, 17.562],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.188, 18.156],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42, 18.719],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.406, 19.25],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.875, 19.844],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.312, 20.344],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.688, 20.781],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.062, 21.25],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.438, 21.688],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.719, 22.094],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 22.438],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.281, 22.781],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.5, 23.125],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.719, 23.375],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.906, 23.625],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 23.781],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.281, 24],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.438, 24.156],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.562, 24.281],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.688, 24.438],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.844, 24.469],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.906, 24.562],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.031, 24.594],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 24.625],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.344, 24.562],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.406, 24.562],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.5, 24.469],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.656, 24.375],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.75, 24.25],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.875, 24.062],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.969, 23.938],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.125, 23.688],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.25, 23.469],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.406, 23.219],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.531, 22.938],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.906, 22.25],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.125, 21.906],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.312, 21.562],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.531, 21.125],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.031, 20.25],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.656, 19.312],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.969, 18.812],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.312, 18.281],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.656, 17.719],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.094, 17.188],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.5, 16.656],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.938, 16.094],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.406, 15.5],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.906, 14.938],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.406, 14.312],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.969, 13.75],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.719, 13.125],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.844, 12.5],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.094, 11.938],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.344, 11.312],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.625, 10.719],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.938, 10.094],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.281, 9.5],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.719, 8.875],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.156, 8.344],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.625, 7.719],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.125, 7.188],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.594, 6.625],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.594, 5.562],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, 5.062],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.625, 4.594],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.188, 4.125],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.656, 3.656],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.219, 3.219],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.719, 2.812],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.281, 2.406],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.812, 2.062],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.344, 1.625],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.906, 1.281],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.469, 0.969],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.531, 0.344],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.031, 0.062],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.562, -0.281],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.094, -0.531],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.531, -0.719],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.031, -0.938],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.531, -1.219],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18, -1.375],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.469, -1.594],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.875, -1.719],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.125, -1.875],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.562, -2],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.312, -2.188],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.719, -2.281],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.094, -2.312],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.469, -2.406],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.781, -2.438],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.188, -2.438],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.562, -2.438],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.906, -2.406],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.25, -2.344],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.625, -2.312],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11, -2.219],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.344, -2.156],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.688, -2.031],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.062, -1.938],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.469, -1.781],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.844, -1.625],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.281, -1.469],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.656, -1.25],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.094, -1.094],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.531, -0.844],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5, -0.656],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.438, -0.406],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.938, -0.188],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.406, 0.031],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.875, 0.219],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.438, 0.438],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.969, 0.594],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.562, 0.75],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.188, 0.938],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.781, 1.062],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.406, 1.188],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.094, 1.25],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.219, 1.406],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.5, 1.469],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [-0.688, 1.531],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.375, 1.281],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1.656, 1.156],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-1, 1.031],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.938, 0.844],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.969, 0.75],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.938, 0.688],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.969, 0.594],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.031, 0.562],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.031, 0.469],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.094, 0.469],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.125, 0.406],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.188, 0.344],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.188, 0.312],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.25, 0.25],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.281, 0.25],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.375, 0.25],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.375, 0.188],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.406, 0.219],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.406, 0.156],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.406, 0.125],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.406, 0.125],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.375, 0.062],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.375, 0.031],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.281, 0],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.219, 0],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.094, -0.062],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24, -0.125],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.844, -0.188],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.656, -0.219],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.344, -0.312],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.594, -0.375],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.812, -0.5],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.031, -0.594],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.281, -0.688],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.781, -1],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28, -1.125],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.312, -1.312],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.562, -1.531],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.812, -1.719],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.125, -1.938],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.375, -2.125],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.656, -2.312],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.969, -2.531],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.25, -2.688],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.562, -2.812],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.875, -2.969],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.188, -3.062],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.531, -3.219],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.844, -3.281],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.188, -3.344],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.5, -3.375],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.875, -3.438],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.25, -3.469],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.594, -3.5],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.969, -3.469],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.312, -3.469],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.688, -3.406],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.094, -3.406],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.406, -3.344],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.875, -3.25],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.25, -3.188],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.625, -3.062],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, -2.938],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.469, -2.781],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.844, -2.688],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.281, -2.5],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.688, -2.344],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.094, -2.156],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.531, -1.938],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.938, -1.719],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, -1.5],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.781, -1.281],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.188, -1.031],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.562, -0.75],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.969, -0.469],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.344, -0.188],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.688, 0.156],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.062, 0.438],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.094, 1.469],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.406, 1.844],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.688, 2.25],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45, 2.625],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.281, 3],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.281, 3.469],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.219, 3.938],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.188, 4.469],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.156, 4.969],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.062, 5.562],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.969, 6.125],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.906, 6.656],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.719, 7.906],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.469, 9.156],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.219, 10.531],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.062, 11.188],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.938, 11.812],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.781, 12.5],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.594, 13.156],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.438, 13.812],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.281, 14.438],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.125, 15.125],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.969, 15.75],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.781, 16.344],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.594, 16.969],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.406, 17.562],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.188, 18.156],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42, 18.719],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.406, 19.25],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.875, 19.844],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.312, 20.344],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.688, 20.781],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.062, 21.25],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.438, 21.688],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.719, 22.094],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.031, 22.438],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.281, 22.781],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.5, 23.125],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.719, 23.375],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.906, 23.625],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.125, 23.781],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.281, 24],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.438, 24.156],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.562, 24.281],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.688, 24.438],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.844, 24.469],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.906, 24.562],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.031, 24.594],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.094, 24.625],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.344, 24.562],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.406, 24.562],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.5, 24.469],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.656, 24.375],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.75, 24.25],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.875, 24.062],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.969, 23.938],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.125, 23.688],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.25, 23.469],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.406, 23.219],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.531, 22.938],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.906, 22.25],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.125, 21.906],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.312, 21.562],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.531, 21.125],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.031, 20.25],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.656, 19.312],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.969, 18.812],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.312, 18.281],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.656, 17.719],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.094, 17.188],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.5, 16.656],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.938, 16.094],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.406, 15.5],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.906, 14.938],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.406, 14.312],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.969, 13.75],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.719, 13.125],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.844, 12.5],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.094, 11.938],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.344, 11.312],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.625, 10.719],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.938, 10.094],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.281, 9.5],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.719, 8.875],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.156, 8.344],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.625, 7.719],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.125, 7.188],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.594, 6.625],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.594, 5.562],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, 5.062],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.625, 4.594],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.188, 4.125],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.656, 3.656],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.219, 3.219],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.719, 2.812],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.281, 2.406],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.812, 2.062],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.344, 1.625],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.906, 1.281],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.469, 0.969],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.531, 0.344],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.031, 0.062],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.562, -0.281],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.094, -0.531],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.531, -0.719],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.031, -0.938],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.531, -1.219],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18, -1.375],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.469, -1.594],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.875, -1.719],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.125, -1.875],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.562, -2],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.312, -2.188],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.719, -2.281],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.094, -2.312],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.469, -2.406],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.781, -2.438],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.188, -2.438],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.562, -2.438],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.906, -2.406],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.25, -2.344],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.625, -2.312],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11, -2.219],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.344, -2.156],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.688, -2.031],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.062, -1.938],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.469, -1.781],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.844, -1.625],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.281, -1.469],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.656, -1.25],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.094, -1.094],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.531, -0.844],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5, -0.656],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.438, -0.406],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.938, -0.188],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.406, 0.031],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.875, 0.219],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.438, 0.438],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.969, 0.594],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.562, 0.75],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.188, 0.938],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.781, 1.062],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.406, 1.188],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.094, 1.25],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.219, 1.406],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.5, 1.469],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 13',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 13,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [102.858, -46.284],
                                  [-29.105, -128.194],
                                  [55.141, -40.787],
                                  [-129.337, -34.911],
                                  [-17.643, -67.999],
                                  [-118.573, 5.001],
                                  [132.3, 81.124],
                                  [-45.07, 146.069],
                                  [-9.286, 92],
                                ],
                                o: [
                                  [-97.857, 47.284],
                                  [29.105, 128.194],
                                  [-55.142, 40.787],
                                  [129.337, 34.911],
                                  [18.572, 69.142],
                                  [111.286, -3.429],
                                  [-132.3, -81.124],
                                  [43.712, -147.283],
                                  [9.286, -92],
                                ],
                                v: [
                                  [-128.202, -406.439],
                                  [-168.514, -172.828],
                                  [-274.388, 7.829],
                                  [-235.728, 148.241],
                                  [-2.585, 315.878],
                                  [148.071, 402.606],
                                  [266.175, 223.01],
                                  [4.511, -158.824],
                                  [275.403, -218.717],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.782, -46.437],
                                  [-29.29, -128.403],
                                  [55.271, -40.659],
                                  [-129.569, -34.887],
                                  [-17.652, -68.106],
                                  [-118.398, 4.921],
                                  [132.613, 81.201],
                                  [-45.202, 146.334],
                                  [-9.252, 92],
                                ],
                                o: [
                                  [-97.914, 47.41],
                                  [29.29, 128.403],
                                  [-55.271, 40.659],
                                  [129.569, 34.887],
                                  [18.556, 69.218],
                                  [111.305, -3.391],
                                  [-132.613, -81.201],
                                  [43.881, -147.516],
                                  [9.252, -92],
                                ],
                                v: [
                                  [-127.962, -405.728],
                                  [-169.505, -172.649],
                                  [-273.644, 8.044],
                                  [-235.677, 149.294],
                                  [-1.871, 315.994],
                                  [147.568, 401.942],
                                  [264.884, 222.688],
                                  [4.632, -158.305],
                                  [276.408, -218.027],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.707, -46.586],
                                  [-29.469, -128.607],
                                  [55.398, -40.534],
                                  [-129.794, -34.863],
                                  [-17.662, -68.21],
                                  [-118.226, 4.842],
                                  [132.918, 81.276],
                                  [-45.33, 146.593],
                                  [-9.218, 92],
                                ],
                                o: [
                                  [-97.97, 47.533],
                                  [29.469, 128.607],
                                  [-55.398, 40.534],
                                  [129.794, 34.863],
                                  [18.541, 69.293],
                                  [111.323, -3.354],
                                  [-132.918, -81.276],
                                  [44.045, -147.744],
                                  [9.218, -92],
                                ],
                                v: [
                                  [-127.712, -405.005],
                                  [-170.44, -172.541],
                                  [-272.841, 8.256],
                                  [-235.62, 150.355],
                                  [-1.105, 316.097],
                                  [146.947, 401.273],
                                  [263.595, 222.369],
                                  [4.711, -157.767],
                                  [277.409, -217.386],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.634, -46.731],
                                  [-29.645, -128.805],
                                  [55.522, -40.412],
                                  [-130.015, -34.84],
                                  [-17.671, -68.312],
                                  [-118.059, 4.766],
                                  [133.216, 81.349],
                                  [-45.456, 146.846],
                                  [-9.185, 92],
                                ],
                                o: [
                                  [-98.024, 47.653],
                                  [29.645, 128.805],
                                  [-55.522, 40.412],
                                  [130.015, 34.84],
                                  [18.527, 69.366],
                                  [111.341, -3.317],
                                  [-133.216, -81.349],
                                  [44.205, -147.965],
                                  [9.185, -92],
                                ],
                                v: [
                                  [-127.514, -404.27],
                                  [-171.439, -172.435],
                                  [-272.086, 8.576],
                                  [-235.62, 151.422],
                                  [-0.339, 316.135],
                                  [146.377, 400.597],
                                  [262.367, 222.049],
                                  [4.86, -157.214],
                                  [278.406, -216.671],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.563, -46.873],
                                  [-29.816, -128.999],
                                  [55.642, -40.294],
                                  [-130.23, -34.817],
                                  [-17.68, -68.411],
                                  [-117.896, 4.692],
                                  [133.506, 81.421],
                                  [-45.578, 147.092],
                                  [-9.154, 92],
                                ],
                                o: [
                                  [-98.077, 47.77],
                                  [29.816, 128.999],
                                  [-55.642, 40.294],
                                  [130.23, 34.817],
                                  [18.513, 69.437],
                                  [111.359, -3.282],
                                  [-133.506, -81.421],
                                  [44.36, -148.182],
                                  [9.154, -92],
                                ],
                                v: [
                                  [-127.311, -403.577],
                                  [-172.445, -172.332],
                                  [-271.214, 8.888],
                                  [-235.62, 152.441],
                                  [0.417, 316.165],
                                  [145.689, 399.916],
                                  [261.197, 221.79],
                                  [5.029, -156.588],
                                  [279.345, -215.943],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.494, -47.011],
                                  [-29.982, -129.187],
                                  [55.76, -40.178],
                                  [-130.439, -34.795],
                                  [-17.688, -68.508],
                                  [-117.737, 4.619],
                                  [133.789, 81.49],
                                  [-45.697, 147.332],
                                  [-9.122, 92],
                                ],
                                o: [
                                  [-98.129, 47.884],
                                  [29.982, 129.187],
                                  [-55.76, 40.178],
                                  [130.439, 34.795],
                                  [18.499, 69.506],
                                  [111.376, -3.247],
                                  [-133.789, -81.49],
                                  [44.512, -148.392],
                                  [9.122, -92],
                                ],
                                v: [
                                  [-127.048, -402.811],
                                  [-173.397, -172.298],
                                  [-270.332, 9.256],
                                  [-235.673, 153.466],
                                  [1.168, 316.129],
                                  [144.941, 399.232],
                                  [260.029, 221.589],
                                  [5.21, -156.003],
                                  [280.343, -215.26],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.427, -47.146],
                                  [-30.145, -129.371],
                                  [55.874, -40.065],
                                  [-130.643, -34.774],
                                  [-17.697, -68.602],
                                  [-117.582, 4.548],
                                  [134.065, 81.558],
                                  [-45.814, 147.566],
                                  [-9.092, 92],
                                ],
                                o: [
                                  [-98.18, 47.996],
                                  [30.145, 129.371],
                                  [-55.874, 40.065],
                                  [130.643, 34.774],
                                  [18.485, 69.573],
                                  [111.393, -3.214],
                                  [-134.065, -81.558],
                                  [44.661, -148.598],
                                  [9.092, -92],
                                ],
                                v: [
                                  [-126.897, -402.022],
                                  [-174.413, -172.268],
                                  [-269.445, 9.622],
                                  [-235.727, 154.437],
                                  [1.972, 316.14],
                                  [144.127, 398.482],
                                  [258.861, 221.445],
                                  [5.405, -155.344],
                                  [281.332, -214.51],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.361, -47.278],
                                  [-30.304, -129.551],
                                  [55.986, -39.955],
                                  [-130.843, -34.753],
                                  [-17.705, -68.694],
                                  [-117.431, 4.479],
                                  [134.334, 81.624],
                                  [-45.927, 147.795],
                                  [-9.062, 92],
                                ],
                                o: [
                                  [-98.229, 48.104],
                                  [30.304, 129.551],
                                  [-55.986, 39.955],
                                  [130.843, 34.753],
                                  [18.472, 69.639],
                                  [111.41, -3.181],
                                  [-134.334, -81.624],
                                  [44.805, -148.798],
                                  [9.063, -92],
                                ],
                                v: [
                                  [-126.686, -401.219],
                                  [-175.377, -172.245],
                                  [-268.495, 10.036],
                                  [-235.838, 155.355],
                                  [2.712, 316.086],
                                  [143.312, 397.788],
                                  [257.812, 221.305],
                                  [5.612, -154.73],
                                  [282.325, -213.803],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.297, -47.406],
                                  [-30.458, -129.726],
                                  [56.095, -39.848],
                                  [-131.037, -34.732],
                                  [-17.713, -68.784],
                                  [-117.283, 4.412],
                                  [134.596, 81.688],
                                  [-46.037, 148.017],
                                  [-9.034, 92],
                                ],
                                o: [
                                  [-98.277, 48.21],
                                  [30.458, 129.726],
                                  [-56.095, 39.848],
                                  [131.037, 34.732],
                                  [18.459, 69.703],
                                  [111.426, -3.149],
                                  [-134.596, -81.688],
                                  [44.946, -148.994],
                                  [9.034, -92],
                                ],
                                v: [
                                  [-126.533, -400.396],
                                  [-176.341, -172.287],
                                  [-267.54, 10.511],
                                  [-235.946, 156.278],
                                  [3.446, 316.022],
                                  [142.431, 397.031],
                                  [256.705, 221.164],
                                  [5.896, -154.041],
                                  [283.251, -213.029],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.235, -47.531],
                                  [-30.609, -129.896],
                                  [56.201, -39.743],
                                  [-131.226, -34.712],
                                  [-17.721, -68.871],
                                  [-117.14, 4.346],
                                  [134.852, 81.751],
                                  [-46.145, 148.234],
                                  [-9.006, 92],
                                ],
                                o: [
                                  [-98.324, 48.313],
                                  [30.609, 129.896],
                                  [-56.201, 39.743],
                                  [131.226, 34.712],
                                  [18.447, 69.765],
                                  [111.441, -3.117],
                                  [-134.852, -81.751],
                                  [45.084, -149.184],
                                  [9.006, -92],
                                ],
                                v: [
                                  [-126.377, -399.618],
                                  [-177.314, -172.273],
                                  [-266.58, 11.039],
                                  [-236.11, 157.208],
                                  [4.171, 315.951],
                                  [141.49, 396.33],
                                  [255.721, 221.081],
                                  [6.131, -153.393],
                                  [284.236, -212.307],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.174, -47.652],
                                  [-30.756, -130.063],
                                  [56.304, -39.641],
                                  [-131.411, -34.693],
                                  [-17.728, -68.957],
                                  [-117, 4.283],
                                  [135.101, 81.813],
                                  [-46.25, 148.446],
                                  [-8.978, 92],
                                ],
                                o: [
                                  [-98.37, 48.413],
                                  [30.756, 130.063],
                                  [-56.304, 39.641],
                                  [131.411, 34.693],
                                  [18.435, 69.826],
                                  [111.457, -3.087],
                                  [-135.101, -81.813],
                                  [45.218, -149.37],
                                  [8.978, -92],
                                ],
                                v: [
                                  [-126.217, -398.762],
                                  [-178.288, -172.325],
                                  [-265.556, 11.622],
                                  [-236.277, 158.025],
                                  [4.887, 315.812],
                                  [140.546, 395.563],
                                  [254.674, 221.056],
                                  [6.381, -152.674],
                                  [285.216, -211.57],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.114, -47.771],
                                  [-30.899, -130.225],
                                  [56.405, -39.542],
                                  [-131.59, -34.674],
                                  [-17.736, -69.04],
                                  [-116.863, 4.22],
                                  [135.344, 81.872],
                                  [-46.353, 148.652],
                                  [-8.952, 92],
                                ],
                                o: [
                                  [-98.414, 48.511],
                                  [30.899, 130.225],
                                  [-56.405, 39.542],
                                  [131.59, 34.674],
                                  [18.423, 69.885],
                                  [111.471, -3.057],
                                  [-135.344, -81.872],
                                  [45.348, -149.551],
                                  [8.952, -92],
                                ],
                                v: [
                                  [-126.055, -397.954],
                                  [-179.211, -172.384],
                                  [-264.584, 12.203],
                                  [-236.442, 158.908],
                                  [5.601, 315.729],
                                  [139.541, 394.794],
                                  [253.748, 221.035],
                                  [6.699, -151.941],
                                  [286.137, -210.768],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.057, -47.887],
                                  [-31.038, -130.383],
                                  [56.504, -39.445],
                                  [-131.766, -34.656],
                                  [-17.743, -69.121],
                                  [-116.73, 4.159],
                                  [135.581, 81.931],
                                  [-46.452, 148.853],
                                  [-8.925, 92],
                                ],
                                o: [
                                  [-98.458, 48.607],
                                  [31.038, 130.383],
                                  [-56.504, 39.445],
                                  [131.766, 34.656],
                                  [18.411, 69.943],
                                  [111.486, -3.028],
                                  [-135.581, -81.93],
                                  [45.475, -149.727],
                                  [8.925, -92],
                                ],
                                v: [
                                  [-125.946, -397.07],
                                  [-180.135, -172.448],
                                  [-263.552, 12.842],
                                  [-236.666, 159.676],
                                  [6.362, 315.575],
                                  [138.472, 394.084],
                                  [252.824, 221.069],
                                  [6.976, -151.193],
                                  [287.112, -210.015],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102, -47.999],
                                  [-31.174, -130.537],
                                  [56.6, -39.35],
                                  [-131.937, -34.638],
                                  [-17.75, -69.2],
                                  [-116.601, 4.1],
                                  [135.811, 81.987],
                                  [-46.55, 149.049],
                                  [-8.9, 92],
                                ],
                                o: [
                                  [-98.5, 48.7],
                                  [31.174, 130.537],
                                  [-56.6, 39.35],
                                  [131.937, 34.638],
                                  [18.4, 70],
                                  [111.5, -3],
                                  [-135.811, -81.987],
                                  [45.599, -149.899],
                                  [8.9, -92],
                                ],
                                v: [
                                  [-125.836, -396.23],
                                  [-181.003, -172.516],
                                  [-262.456, 13.478],
                                  [-236.888, 160.447],
                                  [7.061, 315.474],
                                  [137.4, 393.309],
                                  [251.96, 221.166],
                                  [7.318, -150.492],
                                  [288.027, -209.253],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.945, -48.109],
                                  [-31.307, -130.687],
                                  [56.693, -39.258],
                                  [-132.103, -34.62],
                                  [-17.757, -69.277],
                                  [-116.474, 4.043],
                                  [136.036, 82.043],
                                  [-46.644, 149.24],
                                  [-8.875, 92],
                                ],
                                o: [
                                  [-98.541, 48.79],
                                  [31.307, 130.687],
                                  [-56.693, 39.258],
                                  [132.103, 34.62],
                                  [18.389, 70.055],
                                  [111.514, -2.973],
                                  [-136.036, -82.042],
                                  [45.72, -150.067],
                                  [8.875, -92],
                                ],
                                v: [
                                  [-125.725, -395.441],
                                  [-181.935, -172.593],
                                  [-261.412, 14.171],
                                  [-237.11, 161.221],
                                  [7.749, 315.31],
                                  [136.267, 392.591],
                                  [251.158, 221.263],
                                  [7.617, -149.716],
                                  [288.934, -208.541],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.892, -48.216],
                                  [-31.436, -130.833],
                                  [56.784, -39.169],
                                  [-132.266, -34.603],
                                  [-17.764, -69.352],
                                  [-116.351, 3.986],
                                  [136.256, 82.096],
                                  [-46.737, 149.426],
                                  [-8.851, 92],
                                ],
                                o: [
                                  [-98.581, 48.879],
                                  [31.436, 130.833],
                                  [-56.784, 39.169],
                                  [132.266, 34.603],
                                  [18.378, 70.108],
                                  [111.527, -2.946],
                                  [-136.256, -82.096],
                                  [45.838, -150.23],
                                  [8.851, -92],
                                ],
                                v: [
                                  [-125.667, -394.576],
                                  [-182.751, -172.673],
                                  [-260.368, 14.921],
                                  [-237.393, 161.941],
                                  [8.431, 315.14],
                                  [135.131, 391.81],
                                  [250.355, 221.356],
                                  [7.925, -148.985],
                                  [289.84, -207.765],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.84, -48.321],
                                  [-31.562, -130.976],
                                  [56.873, -39.081],
                                  [-132.424, -34.586],
                                  [-17.77, -69.425],
                                  [-116.231, 3.932],
                                  [136.469, 82.149],
                                  [-46.827, 149.607],
                                  [-8.828, 92],
                                ],
                                o: [
                                  [-98.62, 48.965],
                                  [31.562, 130.976],
                                  [-56.873, 39.081],
                                  [132.424, 34.586],
                                  [18.368, 70.16],
                                  [111.54, -2.92],
                                  [-136.469, -82.149],
                                  [45.953, -150.389],
                                  [8.828, -92],
                                ],
                                v: [
                                  [-125.608, -393.762],
                                  [-183.631, -172.758],
                                  [-259.257, 15.674],
                                  [-237.674, 162.603],
                                  [9.165, 314.959],
                                  [133.931, 391.084],
                                  [249.612, 221.513],
                                  [8.245, -148.183],
                                  [290.745, -206.98],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.789, -48.423],
                                  [-31.685, -131.115],
                                  [56.959, -38.996],
                                  [-132.578, -34.57],
                                  [-17.776, -69.496],
                                  [-116.114, 3.878],
                                  [136.677, 82.2],
                                  [-46.914, 149.784],
                                  [-8.805, 92],
                                ],
                                o: [
                                  [-98.658, 49.049],
                                  [31.685, 131.115],
                                  [-56.959, 38.996],
                                  [132.578, 34.57],
                                  [18.358, 70.211],
                                  [111.553, -2.894],
                                  [-136.677, -82.2],
                                  [46.065, -150.544],
                                  [8.805, -92],
                                ],
                                v: [
                                  [-125.544, -392.934],
                                  [-184.391, -172.849],
                                  [-258.202, 16.479],
                                  [-237.959, 163.208],
                                  [9.834, 314.777],
                                  [132.731, 390.358],
                                  [248.931, 221.669],
                                  [8.637, -147.427],
                                  [291.642, -206.248],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.739, -48.522],
                                  [-31.804, -131.25],
                                  [57.043, -38.913],
                                  [-132.728, -34.554],
                                  [-17.783, -69.565],
                                  [-116, 3.826],
                                  [136.88, 82.25],
                                  [-47, 149.956],
                                  [-8.783, 92],
                                ],
                                o: [
                                  [-98.696, 49.13],
                                  [31.804, 131.25],
                                  [-57.043, 38.913],
                                  [132.728, 34.554],
                                  [18.348, 70.261],
                                  [111.565, -2.87],
                                  [-136.88, -82.25],
                                  [46.174, -150.696],
                                  [8.783, -92],
                                ],
                                v: [
                                  [-125.535, -392.158],
                                  [-185.217, -172.946],
                                  [-257.087, 17.348],
                                  [-238.242, 163.818],
                                  [10.492, 314.647],
                                  [131.467, 389.69],
                                  [248.313, 221.883],
                                  [8.979, -146.6],
                                  [292.538, -205.511],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.691, -48.618],
                                  [-31.921, -131.382],
                                  [57.126, -38.832],
                                  [-132.875, -34.539],
                                  [-17.789, -69.633],
                                  [-115.889, 3.775],
                                  [137.078, 82.299],
                                  [-47.083, 150.124],
                                  [-8.761, 92],
                                ],
                                o: [
                                  [-98.732, 49.21],
                                  [31.921, 131.382],
                                  [-57.125, 38.832],
                                  [132.874, 34.539],
                                  [18.338, 70.309],
                                  [111.577, -2.845],
                                  [-137.078, -82.299],
                                  [46.28, -150.843],
                                  [8.761, -92],
                                ],
                                v: [
                                  [-125.525, -391.371],
                                  [-185.924, -173.047],
                                  [-256.025, 18.213],
                                  [-238.583, 164.429],
                                  [11.145, 314.45],
                                  [130.201, 388.963],
                                  [247.692, 222.157],
                                  [9.331, -145.82],
                                  [293.37, -204.767],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.644, -48.712],
                                  [-32.034, -131.51],
                                  [57.205, -38.753],
                                  [-133.017, -34.524],
                                  [-17.794, -69.699],
                                  [-115.781, 3.726],
                                  [137.271, 82.346],
                                  [-47.164, 150.288],
                                  [-8.74, 92],
                                ],
                                o: [
                                  [-98.767, 49.288],
                                  [32.034, 131.51],
                                  [-57.205, 38.753],
                                  [133.017, 34.524],
                                  [18.329, 70.356],
                                  [111.589, -2.822],
                                  [-137.271, -82.346],
                                  [46.384, -150.986],
                                  [8.74, -92],
                                ],
                                v: [
                                  [-125.569, -390.577],
                                  [-186.693, -173.154],
                                  [-254.961, 19.139],
                                  [-238.928, 164.922],
                                  [11.85, 314.25],
                                  [128.933, 388.29],
                                  [247.134, 222.435],
                                  [9.693, -145.027],
                                  [294.259, -204.019],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.598, -48.804],
                                  [-32.145, -131.635],
                                  [57.283, -38.677],
                                  [-133.156, -34.509],
                                  [-17.8, -69.763],
                                  [-115.675, 3.678],
                                  [137.458, 82.392],
                                  [-47.243, 150.447],
                                  [-8.719, 92],
                                ],
                                o: [
                                  [-98.802, 49.363],
                                  [32.145, 131.635],
                                  [-57.283, 38.677],
                                  [133.156, 34.509],
                                  [18.32, 70.402],
                                  [111.601, -2.799],
                                  [-137.458, -82.392],
                                  [46.484, -151.126],
                                  [8.719, -92],
                                ],
                                v: [
                                  [-125.61, -389.831],
                                  [-187.346, -173.206],
                                  [-253.893, 20.064],
                                  [-239.212, 165.479],
                                  [12.488, 314.043],
                                  [127.603, 387.556],
                                  [246.638, 222.71],
                                  [10.065, -144.221],
                                  [295.085, -203.262],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.553, -48.893],
                                  [-32.253, -131.757],
                                  [57.359, -38.602],
                                  [-133.291, -34.495],
                                  [-17.806, -69.825],
                                  [-115.573, 3.631],
                                  [137.641, 82.437],
                                  [-47.32, 150.602],
                                  [-8.699, 92],
                                ],
                                o: [
                                  [-98.835, 49.437],
                                  [32.253, 131.757],
                                  [-57.359, 38.602],
                                  [133.291, 34.495],
                                  [18.311, 70.447],
                                  [111.612, -2.777],
                                  [-137.641, -82.437],
                                  [46.583, -151.262],
                                  [8.699, -92],
                                ],
                                v: [
                                  [-125.649, -389.139],
                                  [-187.998, -173.322],
                                  [-252.822, 21.047],
                                  [-239.556, 165.916],
                                  [13.119, 313.833],
                                  [126.269, 386.942],
                                  [246.203, 223.047],
                                  [10.388, -143.405],
                                  [295.906, -202.564],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.51, -48.98],
                                  [-32.357, -131.876],
                                  [57.433, -38.529],
                                  [-133.423, -34.481],
                                  [-17.811, -69.886],
                                  [-115.473, 3.585],
                                  [137.819, 82.481],
                                  [-47.395, 150.753],
                                  [-8.679, 92],
                                ],
                                o: [
                                  [-98.868, 49.509],
                                  [32.357, 131.876],
                                  [-57.433, 38.529],
                                  [133.423, 34.481],
                                  [18.302, 70.49],
                                  [111.622, -2.755],
                                  [-137.819, -82.481],
                                  [46.678, -151.395],
                                  [8.679, -92],
                                ],
                                v: [
                                  [-125.744, -388.378],
                                  [-188.654, -173.383],
                                  [-251.751, 22.031],
                                  [-239.902, 166.355],
                                  [13.743, 313.677],
                                  [124.875, 386.266],
                                  [245.829, 223.381],
                                  [10.779, -142.577],
                                  [296.723, -201.858],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.467, -49.065],
                                  [-32.46, -131.992],
                                  [57.505, -38.458],
                                  [-133.552, -34.468],
                                  [-17.817, -69.945],
                                  [-115.375, 3.541],
                                  [137.992, 82.523],
                                  [-47.469, 150.9],
                                  [-8.66, 92],
                                ],
                                o: [
                                  [-98.899, 49.579],
                                  [32.46, 131.992],
                                  [-57.505, 38.458],
                                  [133.552, 34.468],
                                  [18.294, 70.533],
                                  [111.633, -2.734],
                                  [-137.992, -82.523],
                                  [46.772, -151.524],
                                  [8.66, -92],
                                ],
                                v: [
                                  [-125.835, -387.732],
                                  [-189.191, -173.508],
                                  [-250.735, 23.075],
                                  [-240.309, 166.736],
                                  [14.36, 313.458],
                                  [123.539, 385.588],
                                  [245.455, 223.719],
                                  [11.12, -141.798],
                                  [297.476, -201.148],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.426, -49.148],
                                  [-32.559, -132.105],
                                  [57.575, -38.389],
                                  [-133.677, -34.455],
                                  [-17.822, -70.003],
                                  [-115.28, 3.497],
                                  [138.161, 82.565],
                                  [-47.54, 151.044],
                                  [-8.642, 92],
                                ],
                                o: [
                                  [-98.93, 49.647],
                                  [32.559, 132.105],
                                  [-57.575, 38.389],
                                  [133.677, 34.455],
                                  [18.285, 70.574],
                                  [111.643, -2.713],
                                  [-138.161, -82.565],
                                  [46.862, -151.65],
                                  [8.642, -92],
                                ],
                                v: [
                                  [-125.984, -387.076],
                                  [-189.731, -173.578],
                                  [-249.656, 24.119],
                                  [-240.655, 167.118],
                                  [14.97, 313.294],
                                  [122.141, 384.97],
                                  [245.205, 224.116],
                                  [11.471, -140.95],
                                  [298.286, -200.436],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.386, -49.228],
                                  [-32.656, -132.214],
                                  [57.644, -38.322],
                                  [-133.799, -34.442],
                                  [-17.827, -70.06],
                                  [-115.188, 3.455],
                                  [138.326, 82.605],
                                  [-47.609, 151.184],
                                  [-8.624, 92],
                                ],
                                o: [
                                  [-98.96, 49.713],
                                  [32.656, 132.214],
                                  [-57.644, 38.322],
                                  [133.799, 34.442],
                                  [18.277, 70.614],
                                  [111.654, -2.693],
                                  [-138.326, -82.605],
                                  [46.951, -151.773],
                                  [8.624, -92],
                                ],
                                v: [
                                  [-126.13, -386.417],
                                  [-190.271, -173.591],
                                  [-248.696, 25.164],
                                  [-241.005, 167.442],
                                  [15.574, 313.127],
                                  [120.74, 384.349],
                                  [244.955, 224.513],
                                  [11.89, -140.149],
                                  [299.033, -199.779],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.347, -49.306],
                                  [-32.751, -132.321],
                                  [57.71, -38.256],
                                  [-133.917, -34.429],
                                  [-17.832, -70.114],
                                  [-115.098, 3.414],
                                  [138.486, 82.645],
                                  [-47.677, 151.32],
                                  [-8.606, 92],
                                ],
                                o: [
                                  [-98.99, 49.778],
                                  [32.751, 132.321],
                                  [-57.71, 38.256],
                                  [133.917, 34.429],
                                  [18.269, 70.653],
                                  [111.663, -2.673],
                                  [-138.486, -82.645],
                                  [47.037, -151.892],
                                  [8.606, -92],
                                ],
                                v: [
                                  [-126.33, -385.81],
                                  [-190.752, -173.669],
                                  [-247.673, 26.267],
                                  [-241.353, 167.705],
                                  [16.169, 312.955],
                                  [119.278, 383.79],
                                  [244.827, 224.97],
                                  [12.258, -139.277],
                                  [299.775, -199.119],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.309, -49.382],
                                  [-32.843, -132.425],
                                  [57.775, -38.192],
                                  [-134.033, -34.417],
                                  [-17.836, -70.168],
                                  [-115.01, 3.374],
                                  [138.642, 82.683],
                                  [-47.742, 151.452],
                                  [-8.589, 92],
                                ],
                                o: [
                                  [-99.018, 49.841],
                                  [32.843, 132.425],
                                  [-57.775, 38.192],
                                  [134.033, 34.417],
                                  [18.262, 70.691],
                                  [111.673, -2.654],
                                  [-138.642, -82.683],
                                  [47.121, -152.008],
                                  [8.589, -92],
                                ],
                                v: [
                                  [-126.532, -385.258],
                                  [-191.172, -173.75],
                                  [-246.708, 27.37],
                                  [-241.702, 167.969],
                                  [16.76, 312.779],
                                  [117.875, 383.228],
                                  [244.702, 225.431],
                                  [12.573, -138.458],
                                  [300.452, -198.455],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.272, -49.457],
                                  [-32.932, -132.527],
                                  [57.838, -38.13],
                                  [-134.145, -34.405],
                                  [-17.841, -70.22],
                                  [-114.925, 3.335],
                                  [138.794, 82.72],
                                  [-47.806, 151.581],
                                  [-8.572, 92],
                                ],
                                o: [
                                  [-99.046, 49.902],
                                  [32.932, 132.527],
                                  [-57.838, 38.13],
                                  [134.145, 34.405],
                                  [18.254, 70.728],
                                  [111.682, -2.636],
                                  [-138.794, -82.72],
                                  [47.202, -152.121],
                                  [8.572, -92],
                                ],
                                v: [
                                  [-126.788, -384.761],
                                  [-191.536, -173.776],
                                  [-245.802, 28.534],
                                  [-242.052, 168.233],
                                  [17.344, 312.6],
                                  [116.409, 382.666],
                                  [244.636, 225.888],
                                  [12.958, -137.628],
                                  [301.186, -197.786],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.235, -49.529],
                                  [-33.019, -132.626],
                                  [57.9, -38.069],
                                  [-134.255, -34.394],
                                  [-17.846, -70.27],
                                  [-114.842, 3.297],
                                  [138.942, 82.757],
                                  [-47.869, 151.707],
                                  [-8.556, 92],
                                ],
                                o: [
                                  [-99.073, 49.961],
                                  [33.019, 132.626],
                                  [-57.9, 38.069],
                                  [134.255, 34.394],
                                  [18.247, 70.765],
                                  [111.691, -2.618],
                                  [-138.942, -82.757],
                                  [47.282, -152.232],
                                  [8.556, -92],
                                ],
                                v: [
                                  [-127.041, -384.259],
                                  [-191.898, -173.806],
                                  [-244.894, 29.699],
                                  [-242.464, 168.438],
                                  [17.921, 312.478],
                                  [115.003, 382.104],
                                  [244.634, 226.409],
                                  [13.29, -136.786],
                                  [301.858, -197.177],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.2, -49.599],
                                  [-33.104, -132.722],
                                  [57.96, -38.01],
                                  [-134.362, -34.383],
                                  [-17.85, -70.32],
                                  [-114.761, 3.26],
                                  [139.086, 82.792],
                                  [-47.929, 151.829],
                                  [-8.54, 92],
                                ],
                                o: [
                                  [-99.1, 50.02],
                                  [33.104, 132.722],
                                  [-57.96, 38.01],
                                  [134.362, 34.383],
                                  [18.24, 70.8],
                                  [111.7, -2.6],
                                  [-139.086, -82.792],
                                  [47.359, -152.339],
                                  [8.54, -92],
                                ],
                                v: [
                                  [-127.353, -383.813],
                                  [-192.202, -173.779],
                                  [-244.044, 30.864],
                                  [-242.814, 168.584],
                                  [18.493, 312.354],
                                  [113.536, 381.601],
                                  [244.754, 226.868],
                                  [13.63, -135.938],
                                  [302.523, -196.565],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.166, -49.668],
                                  [-33.187, -132.815],
                                  [58.018, -37.953],
                                  [-134.465, -34.372],
                                  [-17.854, -70.368],
                                  [-114.682, 3.224],
                                  [139.227, 82.827],
                                  [-47.989, 151.948],
                                  [-8.525, 92],
                                ],
                                o: [
                                  [-99.125, 50.076],
                                  [33.187, 132.815],
                                  [-58.018, 37.953],
                                  [134.465, 34.372],
                                  [18.233, 70.834],
                                  [111.708, -2.583],
                                  [-139.227, -82.827],
                                  [47.435, -152.444],
                                  [8.525, -92],
                                ],
                                v: [
                                  [-127.661, -383.424],
                                  [-192.445, -173.815],
                                  [-243.194, 32.03],
                                  [-243.168, 168.728],
                                  [18.997, 312.226],
                                  [112.066, 381.098],
                                  [244.878, 227.39],
                                  [13.977, -135.078],
                                  [303.125, -196.009],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.133, -49.735],
                                  [-33.267, -132.906],
                                  [58.074, -37.897],
                                  [-134.567, -34.361],
                                  [-17.858, -70.414],
                                  [-114.605, 3.189],
                                  [139.363, 82.86],
                                  [-48.046, 152.064],
                                  [-8.51, 92],
                                ],
                                o: [
                                  [-99.15, 50.131],
                                  [33.267, 132.906],
                                  [-58.074, 37.897],
                                  [134.567, 34.361],
                                  [18.227, 70.867],
                                  [111.717, -2.566],
                                  [-139.363, -82.86],
                                  [47.508, -152.545],
                                  [8.51, -92],
                                ],
                                v: [
                                  [-128.028, -383.031],
                                  [-192.69, -173.794],
                                  [-242.402, 33.254],
                                  [-243.461, 168.813],
                                  [19.556, 312.095],
                                  [110.596, 380.593],
                                  [245.062, 227.911],
                                  [14.332, -134.211],
                                  [303.785, -195.452],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.1, -49.8],
                                  [-33.346, -132.995],
                                  [58.13, -37.843],
                                  [-134.665, -34.351],
                                  [-17.862, -70.46],
                                  [-114.53, 3.155],
                                  [139.496, 82.893],
                                  [-48.102, 152.177],
                                  [-8.495, 92],
                                ],
                                o: [
                                  [-99.175, 50.185],
                                  [33.346, 132.995],
                                  [-58.13, 37.843],
                                  [134.665, 34.351],
                                  [18.22, 70.9],
                                  [111.725, -2.55],
                                  [-139.496, -82.893],
                                  [47.58, -152.644],
                                  [8.495, -92],
                                ],
                                v: [
                                  [-128.392, -382.754],
                                  [-192.875, -173.716],
                                  [-241.67, 34.48],
                                  [-243.815, 168.898],
                                  [20.049, 312.022],
                                  [109.124, 380.15],
                                  [245.309, 228.492],
                                  [14.694, -133.395],
                                  [304.379, -194.954],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.069, -49.863],
                                  [-33.422, -133.081],
                                  [58.183, -37.79],
                                  [-134.761, -34.341],
                                  [-17.866, -70.504],
                                  [-114.458, 3.122],
                                  [139.626, 82.925],
                                  [-48.157, 152.287],
                                  [-8.481, 92],
                                ],
                                o: [
                                  [-99.199, 50.237],
                                  [33.422, 133.081],
                                  [-58.183, 37.79],
                                  [134.761, 34.341],
                                  [18.214, 70.931],
                                  [111.733, -2.534],
                                  [-139.626, -82.925],
                                  [47.649, -152.741],
                                  [8.481, -92],
                                ],
                                v: [
                                  [-128.814, -382.475],
                                  [-192.998, -173.702],
                                  [-240.998, 35.707],
                                  [-244.171, 168.921],
                                  [20.597, 311.949],
                                  [107.651, 379.704],
                                  [245.68, 229.016],
                                  [15.003, -132.51],
                                  [304.972, -194.454],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.038, -49.924],
                                  [-33.496, -133.166],
                                  [58.236, -37.738],
                                  [-134.854, -34.331],
                                  [-17.87, -70.547],
                                  [-114.387, 3.09],
                                  [139.752, 82.956],
                                  [-48.21, 152.394],
                                  [-8.467, 92],
                                ],
                                o: [
                                  [-99.222, 50.288],
                                  [33.496, 133.166],
                                  [-58.236, 37.738],
                                  [134.854, 34.331],
                                  [18.208, 70.962],
                                  [111.741, -2.519],
                                  [-139.752, -82.956],
                                  [47.717, -152.835],
                                  [8.467, -92],
                                ],
                                v: [
                                  [-129.234, -382.253],
                                  [-193.062, -173.631],
                                  [-240.324, 36.932],
                                  [-244.465, 168.884],
                                  [21.078, 311.87],
                                  [106.177, 379.32],
                                  [246.052, 229.599],
                                  [15.318, -131.677],
                                  [305.62, -194.014],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.008, -49.984],
                                  [-33.569, -133.247],
                                  [58.287, -37.688],
                                  [-134.945, -34.321],
                                  [-17.874, -70.589],
                                  [-114.318, 3.058],
                                  [139.874, 82.986],
                                  [-48.261, 152.498],
                                  [-8.454, 92],
                                ],
                                o: [
                                  [-99.244, 50.337],
                                  [33.569, 133.247],
                                  [-58.287, 37.688],
                                  [134.945, 34.321],
                                  [18.202, 70.992],
                                  [111.748, -2.504],
                                  [-139.874, -82.986],
                                  [47.783, -152.926],
                                  [8.454, -92],
                                ],
                                v: [
                                  [-129.712, -382.09],
                                  [-193.126, -173.502],
                                  [-239.711, 38.159],
                                  [-244.822, 168.848],
                                  [21.554, 311.852],
                                  [104.703, 378.875],
                                  [246.486, 230.244],
                                  [15.64, -130.837],
                                  [306.266, -193.631],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.979, -50.043],
                                  [-33.639, -133.327],
                                  [58.336, -37.639],
                                  [-135.033, -34.312],
                                  [-17.878, -70.63],
                                  [-114.251, 3.028],
                                  [139.994, 83.015],
                                  [-48.312, 152.599],
                                  [-8.44, 92],
                                ],
                                o: [
                                  [-99.266, 50.385],
                                  [33.639, 133.327],
                                  [-58.336, 37.639],
                                  [135.033, 34.312],
                                  [18.196, 71.021],
                                  [111.755, -2.489],
                                  [-139.994, -83.015],
                                  [47.847, -153.015],
                                  [8.44, -92],
                                ],
                                v: [
                                  [-130.249, -381.983],
                                  [-193.129, -173.374],
                                  [-239.159, 39.385],
                                  [-245.12, 168.809],
                                  [22.025, 311.832],
                                  [103.227, 378.551],
                                  [246.983, 230.951],
                                  [15.969, -129.927],
                                  [306.906, -193.309],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.95, -50.099],
                                  [-33.707, -133.404],
                                  [58.384, -37.592],
                                  [-135.119, -34.303],
                                  [-17.881, -70.67],
                                  [-114.186, 2.998],
                                  [140.11, 83.044],
                                  [-48.361, 152.698],
                                  [-8.428, 92],
                                ],
                                o: [
                                  [-99.287, 50.432],
                                  [33.707, 133.404],
                                  [-58.384, 37.592],
                                  [135.119, 34.303],
                                  [18.19, 71.05],
                                  [111.762, -2.475],
                                  [-140.11, -83.044],
                                  [47.909, -153.102],
                                  [8.428, -92],
                                ],
                                v: [
                                  [-130.785, -381.875],
                                  [-193.07, -173.249],
                                  [-238.666, 40.672],
                                  [-245.416, 168.71],
                                  [22.431, 311.81],
                                  [101.811, 378.167],
                                  [247.482, 231.659],
                                  [16.243, -129.071],
                                  [307.545, -192.985],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.923, -50.155],
                                  [-33.774, -133.48],
                                  [58.431, -37.546],
                                  [-135.203, -34.294],
                                  [-17.885, -70.708],
                                  [-114.122, 2.969],
                                  [140.223, 83.072],
                                  [-48.408, 152.794],
                                  [-8.415, 92],
                                ],
                                o: [
                                  [-99.308, 50.478],
                                  [33.774, 133.48],
                                  [-58.431, 37.546],
                                  [135.203, 34.294],
                                  [18.185, 71.077],
                                  [111.769, -2.461],
                                  [-140.223, -83.072],
                                  [47.97, -153.186],
                                  [8.415, -92],
                                ],
                                v: [
                                  [-131.378, -381.885],
                                  [-193.013, -173.068],
                                  [-238.234, 41.899],
                                  [-245.715, 168.55],
                                  [22.769, 311.724],
                                  [100.334, 377.842],
                                  [248.041, 232.489],
                                  [16.524, -128.269],
                                  [308.241, -192.722],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.896, -50.208],
                                  [-33.839, -133.553],
                                  [58.477, -37.501],
                                  [-135.285, -34.286],
                                  [-17.888, -70.746],
                                  [-114.06, 2.941],
                                  [140.333, 83.099],
                                  [-48.455, 152.887],
                                  [-8.403, 92],
                                ],
                                o: [
                                  [-99.328, 50.522],
                                  [33.839, 133.553],
                                  [-58.477, 37.501],
                                  [135.285, 34.286],
                                  [18.179, 71.104],
                                  [111.776, -2.448],
                                  [-140.333, -83.099],
                                  [48.029, -153.268],
                                  [8.403, -92],
                                ],
                                v: [
                                  [-131.97, -381.956],
                                  [-192.893, -172.887],
                                  [-237.862, 43.126],
                                  [-245.953, 168.388],
                                  [23.043, 311.638],
                                  [98.917, 377.517],
                                  [248.604, 233.32],
                                  [16.81, -127.398],
                                  [308.875, -192.517],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.87, -50.261],
                                  [-33.902, -133.625],
                                  [58.522, -37.457],
                                  [-135.364, -34.277],
                                  [-17.891, -70.782],
                                  [-114, 2.913],
                                  [140.44, 83.125],
                                  [-48.5, 152.978],
                                  [-8.391, 92],
                                ],
                                o: [
                                  [-99.348, 50.565],
                                  [33.902, 133.625],
                                  [-58.522, 37.457],
                                  [135.364, 34.277],
                                  [18.174, 71.13],
                                  [111.783, -2.435],
                                  [-140.44, -83.125],
                                  [48.087, -153.348],
                                  [8.391, -92],
                                ],
                                v: [
                                  [-132.62, -382.085],
                                  [-192.715, -172.709],
                                  [-237.489, 44.352],
                                  [-246.251, 168.227],
                                  [23.249, 311.488],
                                  [97.5, 377.253],
                                  [249.291, 234.215],
                                  [17.103, -126.521],
                                  [309.565, -192.373],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.844, -50.312],
                                  [-33.963, -133.694],
                                  [58.565, -37.414],
                                  [-135.441, -34.269],
                                  [-17.895, -70.818],
                                  [-113.942, 2.886],
                                  [140.544, 83.151],
                                  [-48.544, 153.066],
                                  [-8.38, 92],
                                ],
                                o: [
                                  [-99.367, 50.607],
                                  [33.963, 133.694],
                                  [-58.565, 37.414],
                                  [135.441, 34.269],
                                  [18.169, 71.156],
                                  [111.789, -2.422],
                                  [-140.544, -83.151],
                                  [48.143, -153.425],
                                  [8.38, -92],
                                ],
                                v: [
                                  [-133.27, -382.211],
                                  [-192.473, -172.473],
                                  [-237.118, 45.579],
                                  [-246.491, 168.005],
                                  [23.392, 311.276],
                                  [96.082, 376.988],
                                  [249.917, 235.17],
                                  [17.402, -125.699],
                                  [310.251, -192.228],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.819, -50.361],
                                  [-34.023, -133.762],
                                  [58.607, -37.373],
                                  [-135.516, -34.261],
                                  [-17.898, -70.853],
                                  [-113.885, 2.86],
                                  [140.645, 83.175],
                                  [-48.586, 153.152],
                                  [-8.369, 92],
                                ],
                                o: [
                                  [-99.385, 50.648],
                                  [34.023, 133.762],
                                  [-58.607, 37.373],
                                  [135.516, 34.261],
                                  [18.164, 71.18],
                                  [111.795, -2.41],
                                  [-140.645, -83.175],
                                  [48.197, -153.501],
                                  [8.369, -92],
                                ],
                                v: [
                                  [-133.979, -382.399],
                                  [-192.232, -172.239],
                                  [-236.806, 46.744],
                                  [-246.732, 167.78],
                                  [23.407, 311.061],
                                  [94.664, 376.785],
                                  [250.606, 236.126],
                                  [17.645, -124.808],
                                  [310.875, -192.083],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.795, -50.409],
                                  [-34.081, -133.828],
                                  [58.648, -37.332],
                                  [-135.589, -34.254],
                                  [-17.901, -70.886],
                                  [-113.829, 2.835],
                                  [140.744, 83.2],
                                  [-48.628, 153.236],
                                  [-8.358, 92],
                                ],
                                o: [
                                  [-99.403, 50.688],
                                  [34.081, 133.828],
                                  [-58.648, 37.332],
                                  [135.589, 34.254],
                                  [18.159, 71.205],
                                  [111.801, -2.398],
                                  [-140.744, -83.2],
                                  [48.25, -153.574],
                                  [8.358, -92],
                                ],
                                v: [
                                  [-134.684, -382.584],
                                  [-191.93, -172.007],
                                  [-236.556, 47.91],
                                  [-246.972, 167.494],
                                  [23.418, 310.785],
                                  [93.306, 376.581],
                                  [251.358, 237.085],
                                  [17.893, -123.973],
                                  [311.557, -192.059],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.772, -50.456],
                                  [-34.138, -133.892],
                                  [58.688, -37.293],
                                  [-135.66, -34.246],
                                  [-17.904, -70.919],
                                  [-113.776, 2.811],
                                  [140.84, 83.223],
                                  [-48.668, 153.317],
                                  [-8.347, 92],
                                ],
                                o: [
                                  [-99.421, 50.726],
                                  [34.138, 133.891],
                                  [-58.688, 37.293],
                                  [135.66, 34.246],
                                  [18.154, 71.228],
                                  [111.807, -2.386],
                                  [-140.84, -83.223],
                                  [48.302, -153.645],
                                  [8.347, -92],
                                ],
                                v: [
                                  [-135.389, -382.767],
                                  [-191.626, -171.715],
                                  [-236.245, 49.014],
                                  [-247.212, 167.209],
                                  [23.363, 310.569],
                                  [91.946, 376.377],
                                  [252.05, 238.167],
                                  [18.148, -123.13],
                                  [312.235, -191.973],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.749, -50.502],
                                  [-34.193, -133.954],
                                  [58.726, -37.255],
                                  [-135.729, -34.239],
                                  [-17.906, -70.951],
                                  [-113.723, 2.787],
                                  [140.933, 83.246],
                                  [-48.708, 153.397],
                                  [-8.337, 92],
                                ],
                                o: [
                                  [-99.438, 50.764],
                                  [34.193, 133.954],
                                  [-58.726, 37.255],
                                  [135.729, 34.239],
                                  [18.15, 71.251],
                                  [111.813, -2.375],
                                  [-140.933, -83.246],
                                  [48.352, -153.715],
                                  [8.337, -92],
                                ],
                                v: [
                                  [-136.093, -382.948],
                                  [-191.263, -171.426],
                                  [-236.056, 50.057],
                                  [-247.454, 166.921],
                                  [23.242, 310.228],
                                  [90.587, 376.234],
                                  [252.865, 239.188],
                                  [18.346, -122.283],
                                  [312.973, -192.01],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.727, -50.546],
                                  [-34.246, -134.014],
                                  [58.764, -37.218],
                                  [-135.796, -34.232],
                                  [-17.909, -70.982],
                                  [-113.672, 2.763],
                                  [141.024, 83.268],
                                  [-48.746, 153.474],
                                  [-8.327, 92],
                                ],
                                o: [
                                  [-99.455, 50.8],
                                  [34.246, 134.014],
                                  [-58.764, 37.218],
                                  [135.796, 34.232],
                                  [18.145, 71.273],
                                  [111.818, -2.364],
                                  [-141.024, -83.268],
                                  [48.401, -153.782],
                                  [8.327, -92],
                                ],
                                v: [
                                  [-136.793, -383.067],
                                  [-190.837, -171.077],
                                  [-235.807, 51.101],
                                  [-247.635, 166.572],
                                  [22.995, 309.948],
                                  [89.228, 376.09],
                                  [253.621, 240.273],
                                  [18.612, -121.43],
                                  [313.646, -192.046],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.705, -50.589],
                                  [-34.298, -134.073],
                                  [58.801, -37.182],
                                  [-135.861, -34.225],
                                  [-17.912, -71.012],
                                  [-113.623, 2.741],
                                  [141.112, 83.29],
                                  [-48.783, 153.548],
                                  [-8.317, 92],
                                ],
                                o: [
                                  [-99.471, 50.836],
                                  [34.298, 134.073],
                                  [-58.801, 37.182],
                                  [135.861, 34.225],
                                  [18.141, 71.295],
                                  [111.824, -2.353],
                                  [-141.112, -83.29],
                                  [48.448, -153.848],
                                  [8.317, -92],
                                ],
                                v: [
                                  [-137.553, -383.245],
                                  [-190.41, -170.79],
                                  [-235.619, 52.143],
                                  [-247.816, 166.222],
                                  [22.744, 309.606],
                                  [87.93, 376.008],
                                  [254.44, 241.358],
                                  [18.823, -120.571],
                                  [314.316, -192.081],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.685, -50.631],
                                  [-34.349, -134.13],
                                  [58.836, -37.147],
                                  [-135.925, -34.218],
                                  [-17.914, -71.042],
                                  [-113.574, 2.719],
                                  [141.198, 83.311],
                                  [-48.819, 153.621],
                                  [-8.308, 92],
                                ],
                                o: [
                                  [-99.487, 50.871],
                                  [34.349, 134.13],
                                  [-58.836, 37.147],
                                  [135.925, 34.218],
                                  [18.137, 71.315],
                                  [111.829, -2.342],
                                  [-141.198, -83.311],
                                  [48.494, -153.912],
                                  [8.308, -92],
                                ],
                                v: [
                                  [-138.251, -383.361],
                                  [-189.922, -170.445],
                                  [-235.491, 53.062],
                                  [-247.998, 165.87],
                                  [22.427, 309.2],
                                  [86.693, 375.924],
                                  [255.26, 242.506],
                                  [18.977, -119.769],
                                  [314.983, -192.178],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.664, -50.672],
                                  [-34.398, -134.186],
                                  [58.871, -37.112],
                                  [-135.987, -34.212],
                                  [-17.917, -71.07],
                                  [-113.528, 2.697],
                                  [141.281, 83.332],
                                  [-48.854, 153.692],
                                  [-8.299, 92],
                                ],
                                o: [
                                  [-99.502, 50.904],
                                  [34.398, 134.186],
                                  [-58.871, 37.112],
                                  [135.987, 34.212],
                                  [18.133, 71.336],
                                  [111.834, -2.332],
                                  [-141.281, -83.332],
                                  [48.539, -153.974],
                                  [8.299, -92],
                                ],
                                v: [
                                  [-138.947, -383.538],
                                  [-189.434, -170.039],
                                  [-235.365, 53.981],
                                  [-248.118, 165.519],
                                  [22.044, 308.855],
                                  [85.393, 375.839],
                                  [256.143, 243.656],
                                  [19.198, -118.962],
                                  [315.709, -192.335],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.644, -50.711],
                                  [-34.446, -134.24],
                                  [58.905, -37.079],
                                  [-136.047, -34.205],
                                  [-17.919, -71.098],
                                  [-113.482, 2.677],
                                  [141.362, 83.352],
                                  [-48.889, 153.761],
                                  [-8.29, 92],
                                ],
                                o: [
                                  [-99.517, 50.937],
                                  [34.446, 134.24],
                                  [-58.905, 37.079],
                                  [136.047, 34.205],
                                  [18.129, 71.356],
                                  [111.839, -2.322],
                                  [-141.362, -83.352],
                                  [48.582, -154.035],
                                  [8.29, -92],
                                ],
                                v: [
                                  [-139.64, -383.651],
                                  [-188.885, -169.636],
                                  [-235.238, 54.899],
                                  [-248.299, 165.105],
                                  [21.659, 308.448],
                                  [84.216, 375.816],
                                  [256.966, 244.807],
                                  [19.361, -118.089],
                                  [316.371, -192.493],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.625, -50.75],
                                  [-34.492, -134.293],
                                  [58.937, -37.047],
                                  [-136.105, -34.199],
                                  [-17.922, -71.125],
                                  [-113.438, 2.656],
                                  [141.441, 83.371],
                                  [-48.922, 153.828],
                                  [-8.281, 92],
                                ],
                                o: [
                                  [-99.531, 50.969],
                                  [34.492, 134.293],
                                  [-58.937, 37.047],
                                  [136.105, 34.199],
                                  [18.125, 71.375],
                                  [111.844, -2.313],
                                  [-141.441, -83.371],
                                  [48.625, -154.094],
                                  [8.281, -92],
                                ],
                                v: [
                                  [-140.332, -383.763],
                                  [-188.335, -169.233],
                                  [-235.172, 55.756],
                                  [-248.418, 164.689],
                                  [21.145, 308.039],
                                  [82.978, 375.791],
                                  [257.852, 245.959],
                                  [19.53, -117.272],
                                  [317.094, -192.65],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.606, -50.787],
                                  [-34.537, -134.344],
                                  [58.969, -37.016],
                                  [-136.162, -34.193],
                                  [-17.924, -71.151],
                                  [-113.395, 2.637],
                                  [141.518, 83.39],
                                  [-48.954, 153.893],
                                  [-8.273, 92],
                                ],
                                o: [
                                  [-99.545, 51],
                                  [34.537, 134.344],
                                  [-58.969, 37.016],
                                  [136.162, 34.193],
                                  [18.121, 71.394],
                                  [111.848, -2.303],
                                  [-141.518, -83.39],
                                  [48.666, -154.151],
                                  [8.273, -92],
                                ],
                                v: [
                                  [-141.023, -383.873],
                                  [-187.784, -168.831],
                                  [-235.107, 56.55],
                                  [-248.537, 164.274],
                                  [20.628, 307.629],
                                  [81.799, 375.826],
                                  [258.738, 247.112],
                                  [19.703, -116.513],
                                  [317.751, -192.869],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.588, -50.824],
                                  [-34.581, -134.394],
                                  [59, -36.985],
                                  [-136.217, -34.187],
                                  [-17.926, -71.177],
                                  [-113.353, 2.617],
                                  [141.592, 83.408],
                                  [-48.986, 153.956],
                                  [-8.265, 92],
                                ],
                                o: [
                                  [-99.559, 51.03],
                                  [34.581, 134.394],
                                  [-59, 36.985],
                                  [136.217, 34.187],
                                  [18.118, 71.412],
                                  [111.853, -2.294],
                                  [-141.592, -83.408],
                                  [48.706, -154.206],
                                  [8.265, -92],
                                ],
                                v: [
                                  [-141.649, -383.982],
                                  [-187.109, -168.429],
                                  [-235.104, 57.344],
                                  [-248.654, 163.856],
                                  [20.046, 307.219],
                                  [80.683, 375.861],
                                  [259.627, 248.266],
                                  [19.881, -115.688],
                                  [318.467, -193.148],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.57, -50.859],
                                  [-34.624, -134.442],
                                  [59.03, -36.955],
                                  [-136.271, -34.182],
                                  [-17.929, -71.201],
                                  [-113.312, 2.599],
                                  [141.665, 83.426],
                                  [-49.016, 154.018],
                                  [-8.257, 92],
                                ],
                                o: [
                                  [-99.572, 51.059],
                                  [34.624, 134.442],
                                  [-59.03, 36.955],
                                  [136.271, 34.182],
                                  [18.114, 71.43],
                                  [111.857, -2.285],
                                  [-141.665, -83.426],
                                  [48.745, -154.26],
                                  [8.257, -92],
                                ],
                                v: [
                                  [-142.337, -384.091],
                                  [-186.497, -167.967],
                                  [-235.1, 58.014],
                                  [-248.71, 163.437],
                                  [19.399, 306.746],
                                  [79.566, 375.896],
                                  [260.516, 249.484],
                                  [20.002, -114.859],
                                  [319.182, -193.428],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.553, -50.894],
                                  [-34.666, -134.489],
                                  [59.06, -36.927],
                                  [-136.323, -34.176],
                                  [-17.931, -71.226],
                                  [-113.272, 2.581],
                                  [141.736, 83.443],
                                  [-49.046, 154.078],
                                  [-8.249, 92],
                                ],
                                o: [
                                  [-99.585, 51.087],
                                  [34.666, 134.489],
                                  [-59.06, 36.927],
                                  [136.323, 34.176],
                                  [18.111, 71.447],
                                  [111.862, -2.277],
                                  [-141.735, -83.443],
                                  [48.783, -154.313],
                                  [8.249, -92],
                                ],
                                v: [
                                  [-142.961, -384.198],
                                  [-185.822, -167.506],
                                  [-235.098, 58.745],
                                  [-248.765, 162.957],
                                  [18.686, 306.335],
                                  [78.51, 375.991],
                                  [261.468, 250.641],
                                  [20.188, -114.087],
                                  [319.832, -193.708],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.536, -50.927],
                                  [-34.706, -134.535],
                                  [59.088, -36.898],
                                  [-136.374, -34.171],
                                  [-17.933, -71.249],
                                  [-113.234, 2.563],
                                  [141.804, 83.46],
                                  [-49.075, 154.136],
                                  [-8.241, 92],
                                ],
                                o: [
                                  [-99.598, 51.115],
                                  [34.706, 134.535],
                                  [-59.088, 36.898],
                                  [136.374, 34.171],
                                  [18.107, 71.464],
                                  [111.866, -2.268],
                                  [-141.804, -83.46],
                                  [48.82, -154.364],
                                  [8.241, -92],
                                ],
                                v: [
                                  [-143.584, -384.304],
                                  [-185.147, -167.045],
                                  [-235.156, 59.352],
                                  [-248.82, 162.536],
                                  [17.971, 305.861],
                                  [77.454, 376.085],
                                  [262.36, 251.8],
                                  [20.317, -113.311],
                                  [320.541, -194.049],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.52, -50.96],
                                  [-34.745, -134.579],
                                  [59.116, -36.871],
                                  [-136.423, -34.166],
                                  [-17.935, -71.272],
                                  [-113.196, 2.546],
                                  [141.871, 83.477],
                                  [-49.103, 154.192],
                                  [-8.234, 92],
                                ],
                                o: [
                                  [-99.61, 51.142],
                                  [34.745, 134.579],
                                  [-59.116, 36.871],
                                  [136.423, 34.166],
                                  [18.104, 71.48],
                                  [111.87, -2.26],
                                  [-141.871, -83.477],
                                  [48.856, -154.414],
                                  [8.234, -92],
                                ],
                                v: [
                                  [-144.142, -384.409],
                                  [-184.41, -166.584],
                                  [-235.215, 59.958],
                                  [-248.876, 162.053],
                                  [17.189, 305.447],
                                  [76.459, 376.239],
                                  [263.315, 252.96],
                                  [20.388, -112.532],
                                  [321.187, -194.389],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.504, -50.991],
                                  [-34.783, -134.623],
                                  [59.143, -36.845],
                                  [-136.471, -34.161],
                                  [-17.937, -71.294],
                                  [-113.16, 2.53],
                                  [141.935, 83.493],
                                  [-49.13, 154.247],
                                  [-8.227, 92],
                                ],
                                o: [
                                  [-99.622, 51.168],
                                  [34.783, 134.622],
                                  [-59.143, 36.845],
                                  [136.471, 34.161],
                                  [18.101, 71.496],
                                  [111.874, -2.252],
                                  [-141.935, -83.492],
                                  [48.89, -154.462],
                                  [8.227, -92],
                                ],
                                v: [
                                  [-144.762, -384.452],
                                  [-183.673, -166.063],
                                  [-235.336, 60.502],
                                  [-248.931, 161.631],
                                  [16.405, 304.971],
                                  [75.526, 376.393],
                                  [264.21, 254.121],
                                  [20.525, -111.75],
                                  [321.892, -194.792],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.489, -51.022],
                                  [-34.82, -134.664],
                                  [59.169, -36.819],
                                  [-136.518, -34.156],
                                  [-17.939, -71.315],
                                  [-113.125, 2.513],
                                  [141.998, 83.508],
                                  [-49.156, 154.301],
                                  [-8.22, 92],
                                ],
                                o: [
                                  [-99.633, 51.193],
                                  [34.82, 134.664],
                                  [-59.169, 36.819],
                                  [136.518, 34.156],
                                  [18.098, 71.511],
                                  [111.878, -2.244],
                                  [-141.998, -83.508],
                                  [48.924, -154.509],
                                  [8.22, -92],
                                ],
                                v: [
                                  [-145.318, -384.556],
                                  [-182.936, -165.604],
                                  [-235.457, 60.983],
                                  [-248.924, 161.146],
                                  [15.494, 304.495],
                                  [74.592, 376.546],
                                  [265.167, 255.282],
                                  [20.603, -110.963],
                                  [322.595, -195.194],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.474, -51.052],
                                  [-34.856, -134.705],
                                  [59.194, -36.794],
                                  [-136.563, -34.151],
                                  [-17.941, -71.336],
                                  [-113.09, 2.498],
                                  [142.059, 83.523],
                                  [-49.182, 154.353],
                                  [-8.213, 92],
                                ],
                                o: [
                                  [-99.644, 51.218],
                                  [34.856, 134.705],
                                  [-59.194, 36.794],
                                  [136.563, 34.151],
                                  [18.095, 71.526],
                                  [111.881, -2.237],
                                  [-142.059, -83.523],
                                  [48.957, -154.554],
                                  [8.213, -92],
                                ],
                                v: [
                                  [-145.811, -384.596],
                                  [-182.198, -165.083],
                                  [-235.64, 61.464],
                                  [-248.979, 160.722],
                                  [14.642, 304.018],
                                  [73.72, 376.76],
                                  [266.063, 256.445],
                                  [20.747, -110.235],
                                  [323.234, -195.658],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.46, -51.081],
                                  [-34.891, -134.745],
                                  [59.219, -36.77],
                                  [-136.607, -34.146],
                                  [-17.943, -71.357],
                                  [-113.057, 2.483],
                                  [142.119, 83.538],
                                  [-49.207, 154.403],
                                  [-8.207, 92],
                                ],
                                o: [
                                  [-99.655, 51.242],
                                  [34.891, 134.745],
                                  [-59.219, 36.77],
                                  [136.607, 34.146],
                                  [18.092, 71.54],
                                  [111.885, -2.23],
                                  [-142.119, -83.538],
                                  [48.989, -154.598],
                                  [8.207, -92],
                                ],
                                v: [
                                  [-146.364, -384.636],
                                  [-181.398, -164.563],
                                  [-235.761, 61.882],
                                  [-248.91, 160.236],
                                  [13.663, 303.602],
                                  [72.91, 376.974],
                                  [266.961, 257.548],
                                  [20.832, -109.505],
                                  [323.933, -196.123],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.445, -51.109],
                                  [-34.925, -134.783],
                                  [59.243, -36.746],
                                  [-136.65, -34.142],
                                  [-17.944, -71.376],
                                  [-113.025, 2.468],
                                  [142.177, 83.552],
                                  [-49.232, 154.452],
                                  [-8.2, 92],
                                ],
                                o: [
                                  [-99.666, 51.265],
                                  [34.925, 134.783],
                                  [-59.243, 36.746],
                                  [136.65, 34.142],
                                  [18.089, 71.555],
                                  [111.889, -2.223],
                                  [-142.177, -83.552],
                                  [49.02, -154.641],
                                  [8.2, -92],
                                ],
                                v: [
                                  [-146.793, -384.675],
                                  [-180.598, -164.043],
                                  [-236.007, 62.237],
                                  [-248.904, 159.81],
                                  [12.743, 303.124],
                                  [72.099, 377.186],
                                  [267.921, 258.651],
                                  [20.859, -108.771],
                                  [324.63, -196.648],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.432, -51.137],
                                  [-34.958, -134.821],
                                  [59.266, -36.723],
                                  [-136.691, -34.138],
                                  [-17.946, -71.396],
                                  [-112.993, 2.453],
                                  [142.233, 83.566],
                                  [-49.255, 154.5],
                                  [-8.194, 92],
                                ],
                                o: [
                                  [-99.676, 51.288],
                                  [34.958, 134.821],
                                  [-59.266, 36.723],
                                  [136.691, 34.138],
                                  [18.086, 71.568],
                                  [111.892, -2.216],
                                  [-142.233, -83.566],
                                  [49.05, -154.683],
                                  [8.194, -92],
                                ],
                                v: [
                                  [-147.281, -384.713],
                                  [-179.798, -163.523],
                                  [-236.19, 62.591],
                                  [-248.897, 159.384],
                                  [11.697, 302.646],
                                  [71.351, 377.459],
                                  [268.821, 259.756],
                                  [20.951, -108.034],
                                  [325.263, -197.174],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.418, -51.163],
                                  [-34.991, -134.857],
                                  [59.289, -36.701],
                                  [-136.732, -34.133],
                                  [-17.948, -71.414],
                                  [-112.962, 2.439],
                                  [142.287, 83.579],
                                  [-49.278, 154.546],
                                  [-8.188, 92],
                                ],
                                o: [
                                  [-99.686, 51.31],
                                  [34.991, 134.857],
                                  [-59.289, 36.701],
                                  [136.732, 34.133],
                                  [18.084, 71.582],
                                  [111.895, -2.209],
                                  [-142.287, -83.579],
                                  [49.079, -154.724],
                                  [8.188, -92],
                                ],
                                v: [
                                  [-147.707, -384.75],
                                  [-178.998, -163.005],
                                  [-236.436, 62.882],
                                  [-248.829, 158.957],
                                  [10.71, 302.166],
                                  [70.663, 377.732],
                                  [269.721, 260.862],
                                  [21.045, -107.294],
                                  [325.956, -197.761],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.406, -51.189],
                                  [-35.022, -134.892],
                                  [59.311, -36.679],
                                  [-136.771, -34.129],
                                  [-17.949, -71.432],
                                  [-112.933, 2.426],
                                  [142.34, 83.592],
                                  [-49.3, 154.591],
                                  [-8.182, 92],
                                ],
                                o: [
                                  [-99.696, 51.331],
                                  [35.022, 134.892],
                                  [-59.311, 36.679],
                                  [136.771, 34.129],
                                  [18.081, 71.594],
                                  [111.899, -2.203],
                                  [-142.34, -83.592],
                                  [49.108, -154.763],
                                  [8.182, -92],
                                ],
                                v: [
                                  [-148.131, -384.787],
                                  [-178.197, -162.486],
                                  [-236.743, 63.173],
                                  [-248.761, 158.529],
                                  [9.596, 301.749],
                                  [69.976, 378.065],
                                  [270.685, 261.906],
                                  [21.082, -106.614],
                                  [326.647, -198.349],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.393, -51.214],
                                  [-35.052, -134.927],
                                  [59.332, -36.658],
                                  [-136.809, -34.125],
                                  [-17.951, -71.45],
                                  [-112.904, 2.413],
                                  [142.391, 83.605],
                                  [-49.322, 154.634],
                                  [-8.177, 92],
                                ],
                                o: [
                                  [-99.705, 51.352],
                                  [35.052, 134.927],
                                  [-59.332, 36.658],
                                  [136.809, 34.125],
                                  [18.079, 71.607],
                                  [111.902, -2.196],
                                  [-142.391, -83.605],
                                  [49.135, -154.801],
                                  [8.177, -92],
                                ],
                                v: [
                                  [-148.492, -384.823],
                                  [-177.396, -161.968],
                                  [-236.989, 63.401],
                                  [-248.693, 158.101],
                                  [8.541, 301.269],
                                  [69.412, 378.398],
                                  [271.588, 262.952],
                                  [21.12, -105.931],
                                  [327.275, -198.936],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.381, -51.238],
                                  [-35.081, -134.96],
                                  [59.353, -36.638],
                                  [-136.846, -34.121],
                                  [-17.952, -71.467],
                                  [-112.876, 2.4],
                                  [142.441, 83.617],
                                  [-49.343, 154.677],
                                  [-8.171, 92],
                                ],
                                o: [
                                  [-99.714, 51.372],
                                  [35.081, 134.96],
                                  [-59.353, 36.638],
                                  [136.846, 34.121],
                                  [18.076, 71.619],
                                  [111.905, -2.19],
                                  [-142.441, -83.617],
                                  [49.162, -154.839],
                                  [8.171, -92],
                                ],
                                v: [
                                  [-148.79, -384.796],
                                  [-176.595, -161.45],
                                  [-237.297, 63.566],
                                  [-248.625, 157.733],
                                  [7.422, 300.788],
                                  [68.849, 378.729],
                                  [272.492, 263.937],
                                  [21.162, -105.246],
                                  [327.962, -199.648],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.369, -51.262],
                                  [-35.11, -134.992],
                                  [59.373, -36.618],
                                  [-136.882, -34.118],
                                  [-17.954, -71.484],
                                  [-112.849, 2.387],
                                  [142.49, 83.629],
                                  [-49.364, 154.718],
                                  [-8.166, 92],
                                ],
                                o: [
                                  [-99.723, 51.391],
                                  [35.11, 134.992],
                                  [-59.373, 36.618],
                                  [136.882, 34.118],
                                  [18.074, 71.631],
                                  [111.908, -2.184],
                                  [-142.49, -83.629],
                                  [49.188, -154.875],
                                  [8.166, -92],
                                ],
                                v: [
                                  [-149.148, -384.83],
                                  [-175.732, -160.933],
                                  [-237.667, 63.73],
                                  [-248.558, 157.304],
                                  [6.239, 300.369],
                                  [68.347, 379.061],
                                  [273.334, 264.923],
                                  [21.207, -104.558],
                                  [328.649, -200.297],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.357, -51.285],
                                  [-35.138, -135.024],
                                  [59.392, -36.599],
                                  [-136.917, -34.114],
                                  [-17.955, -71.5],
                                  [-112.822, 2.375],
                                  [142.537, 83.64],
                                  [-49.383, 154.758],
                                  [-8.161, 92],
                                ],
                                o: [
                                  [-99.732, 51.41],
                                  [35.138, 135.024],
                                  [-59.392, 36.599],
                                  [136.917, 34.114],
                                  [18.071, 71.643],
                                  [111.911, -2.179],
                                  [-142.537, -83.64],
                                  [49.214, -154.91],
                                  [8.161, -92],
                                ],
                                v: [
                                  [-149.38, -384.802],
                                  [-174.931, -160.354],
                                  [-237.976, 63.831],
                                  [-248.429, 156.998],
                                  [5.115, 299.888],
                                  [67.906, 379.453],
                                  [274.24, 265.909],
                                  [21.254, -103.868],
                                  [329.271, -201.008],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.346, -51.308],
                                  [-35.165, -135.054],
                                  [59.411, -36.58],
                                  [-136.95, -34.11],
                                  [-17.957, -71.515],
                                  [-112.796, 2.364],
                                  [142.583, 83.652],
                                  [-49.403, 154.797],
                                  [-8.156, 92],
                                ],
                                o: [
                                  [-99.74, 51.429],
                                  [35.165, 135.054],
                                  [-59.411, 36.58],
                                  [136.95, 34.11],
                                  [18.069, 71.654],
                                  [111.913, -2.173],
                                  [-142.583, -83.652],
                                  [49.238, -154.944],
                                  [8.156, -92],
                                ],
                                v: [
                                  [-149.612, -384.772],
                                  [-174.129, -159.899],
                                  [-238.346, 63.931],
                                  [-248.3, 156.629],
                                  [3.927, 299.469],
                                  [67.467, 379.845],
                                  [275.147, 266.835],
                                  [21.241, -103.238],
                                  [329.953, -201.78],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.335, -51.329],
                                  [-35.191, -135.084],
                                  [59.43, -36.562],
                                  [-136.983, -34.107],
                                  [-17.958, -71.53],
                                  [-112.771, 2.352],
                                  [142.627, 83.662],
                                  [-49.421, 154.835],
                                  [-8.151, 92],
                                ],
                                o: [
                                  [-99.748, 51.447],
                                  [35.191, 135.084],
                                  [-59.43, 36.562],
                                  [136.983, 34.107],
                                  [18.067, 71.665],
                                  [111.916, -2.168],
                                  [-142.627, -83.662],
                                  [49.262, -154.977],
                                  [8.151, -92],
                                ],
                                v: [
                                  [-149.843, -384.743],
                                  [-173.327, -159.383],
                                  [-238.717, 63.969],
                                  [-248.233, 156.322],
                                  [2.674, 298.987],
                                  [67.151, 380.298],
                                  [275.992, 267.699],
                                  [21.294, -102.606],
                                  [330.572, -202.615],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.325, -51.35],
                                  [-35.216, -135.113],
                                  [59.448, -36.544],
                                  [-137.015, -34.104],
                                  [-17.959, -71.545],
                                  [-112.747, 2.341],
                                  [142.67, 83.673],
                                  [-49.44, 154.871],
                                  [-8.146, 92],
                                ],
                                o: [
                                  [-99.756, 51.464],
                                  [35.216, 135.113],
                                  [-59.448, 36.544],
                                  [137.015, 34.104],
                                  [18.065, 71.675],
                                  [111.919, -2.162],
                                  [-142.67, -83.673],
                                  [49.285, -155.009],
                                  [8.146, -92],
                                ],
                                v: [
                                  [-150.01, -384.712],
                                  [-172.526, -158.867],
                                  [-239.149, 64.005],
                                  [-248.105, 156.014],
                                  [1.482, 298.567],
                                  [66.835, 380.689],
                                  [276.838, 268.626],
                                  [21.286, -101.972],
                                  [331.251, -203.388],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.315, -51.371],
                                  [-35.241, -135.141],
                                  [59.465, -36.527],
                                  [-137.046, -34.1],
                                  [-17.961, -71.559],
                                  [-112.724, 2.33],
                                  [142.712, 83.683],
                                  [-49.457, 154.907],
                                  [-8.142, 92],
                                ],
                                o: [
                                  [-99.764, 51.481],
                                  [35.241, 135.141],
                                  [-59.465, 36.527],
                                  [137.046, 34.1],
                                  [18.063, 71.685],
                                  [111.921, -2.157],
                                  [-142.712, -83.683],
                                  [49.308, -155.04],
                                  [8.142, -92],
                                ],
                                v: [
                                  [-150.176, -384.619],
                                  [-171.786, -158.351],
                                  [-239.521, 63.978],
                                  [-247.914, 155.706],
                                  [0.225, 298.084],
                                  [66.643, 381.141],
                                  [277.685, 269.429],
                                  [21.28, -101.398],
                                  [331.867, -204.223],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.305, -51.391],
                                  [-35.265, -135.168],
                                  [59.482, -36.51],
                                  [-137.076, -34.097],
                                  [-17.962, -71.573],
                                  [-112.701, 2.32],
                                  [142.753, 83.693],
                                  [-49.474, 154.941],
                                  [-8.137, 92],
                                ],
                                o: [
                                  [-99.771, 51.497],
                                  [35.265, 135.168],
                                  [-59.482, 36.51],
                                  [137.076, 34.097],
                                  [18.061, 71.695],
                                  [111.924, -2.152],
                                  [-142.753, -83.693],
                                  [49.33, -155.071],
                                  [8.137, -92],
                                ],
                                v: [
                                  [-150.279, -384.587],
                                  [-170.983, -157.898],
                                  [-239.953, 64.013],
                                  [-247.786, 155.46],
                                  [-1.034, 297.663],
                                  [66.451, 381.592],
                                  [278.534, 270.234],
                                  [21.277, -100.822],
                                  [332.481, -205.12],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.295, -51.41],
                                  [-35.288, -135.194],
                                  [59.498, -36.494],
                                  [-137.106, -34.094],
                                  [-17.963, -71.587],
                                  [-112.679, 2.31],
                                  [142.792, 83.703],
                                  [-49.491, 154.975],
                                  [-8.133, 92],
                                ],
                                o: [
                                  [-99.779, 51.513],
                                  [35.288, 135.194],
                                  [-59.498, 36.494],
                                  [137.106, 34.094],
                                  [18.059, 71.705],
                                  [111.926, -2.148],
                                  [-142.792, -83.703],
                                  [49.351, -155.1],
                                  [8.133, -92],
                                ],
                                v: [
                                  [-150.319, -384.493],
                                  [-170.243, -157.383],
                                  [-240.387, 63.922],
                                  [-247.659, 155.213],
                                  [-2.295, 297.242],
                                  [66.322, 382.105],
                                  [279.32, 271.039],
                                  [21.276, -100.244],
                                  [333.032, -206.016],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.286, -51.429],
                                  [-35.311, -135.22],
                                  [59.514, -36.479],
                                  [-137.134, -34.091],
                                  [-17.964, -71.6],
                                  [-112.657, 2.3],
                                  [142.83, 83.713],
                                  [-49.507, 155.007],
                                  [-8.129, 92],
                                ],
                                o: [
                                  [-99.786, 51.529],
                                  [35.311, 135.22],
                                  [-59.514, 36.479],
                                  [137.134, 34.091],
                                  [18.057, 71.714],
                                  [111.929, -2.143],
                                  [-142.83, -83.712],
                                  [49.371, -155.129],
                                  [8.129, -92],
                                ],
                                v: [
                                  [-150.358, -384.397],
                                  [-169.502, -156.93],
                                  [-240.82, 63.893],
                                  [-247.469, 155.028],
                                  [-3.62, 296.821],
                                  [66.254, 382.554],
                                  [280.108, 271.845],
                                  [21.277, -99.665],
                                  [333.581, -206.913],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.277, -51.447],
                                  [-35.333, -135.244],
                                  [59.53, -36.463],
                                  [-137.161, -34.088],
                                  [-17.965, -71.613],
                                  [-112.636, 2.291],
                                  [142.867, 83.722],
                                  [-49.523, 155.039],
                                  [-8.125, 92],
                                ],
                                o: [
                                  [-99.793, 51.544],
                                  [35.333, 135.244],
                                  [-59.53, 36.463],
                                  [137.161, 34.088],
                                  [18.055, 71.723],
                                  [111.931, -2.138],
                                  [-142.867, -83.722],
                                  [49.391, -155.156],
                                  [8.124, -92],
                                ],
                                v: [
                                  [-150.333, -384.302],
                                  [-168.824, -156.478],
                                  [-241.254, 63.801],
                                  [-247.28, 154.842],
                                  [-4.884, 296.337],
                                  [66.311, 383.066],
                                  [280.896, 272.589],
                                  [21.28, -99.085],
                                  [334.129, -207.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.268, -51.464],
                                  [-35.354, -135.268],
                                  [59.545, -36.449],
                                  [-137.188, -34.085],
                                  [-17.967, -71.625],
                                  [-112.616, 2.281],
                                  [142.904, 83.73],
                                  [-49.538, 155.069],
                                  [-8.121, 92],
                                ],
                                o: [
                                  [-99.799, 51.558],
                                  [35.354, 135.268],
                                  [-59.545, 36.449],
                                  [137.188, 34.085],
                                  [18.054, 71.732],
                                  [111.933, -2.134],
                                  [-142.904, -83.73],
                                  [49.411, -155.183],
                                  [8.12, -92],
                                ],
                                v: [
                                  [-150.307, -384.143],
                                  [-168.145, -156.025],
                                  [-241.75, 63.769],
                                  [-247.153, 154.719],
                                  [-6.213, 295.915],
                                  [66.369, 383.577],
                                  [281.623, 273.333],
                                  [21.285, -98.565],
                                  [334.613, -208.768],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.259, -51.481],
                                  [-35.374, -135.292],
                                  [59.559, -36.434],
                                  [-137.214, -34.083],
                                  [-17.968, -71.637],
                                  [-112.596, 2.272],
                                  [142.938, 83.739],
                                  [-49.553, 155.099],
                                  [-8.117, 92],
                                ],
                                o: [
                                  [-99.806, 51.572],
                                  [35.374, 135.292],
                                  [-59.559, 36.434],
                                  [137.214, 34.083],
                                  [18.052, 71.741],
                                  [111.935, -2.13],
                                  [-142.939, -83.739],
                                  [49.43, -155.209],
                                  [8.117, -92],
                                ],
                                v: [
                                  [-150.218, -384.046],
                                  [-167.466, -155.636],
                                  [-242.184, 63.675],
                                  [-246.964, 154.595],
                                  [-7.48, 295.493],
                                  [66.488, 384.087],
                                  [282.351, 274.079],
                                  [21.292, -98.043],
                                  [335.096, -209.726],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.251, -51.498],
                                  [-35.394, -135.314],
                                  [59.573, -36.42],
                                  [-137.239, -34.08],
                                  [-17.969, -71.649],
                                  [-112.577, 2.264],
                                  [142.972, 83.747],
                                  [-49.567, 155.128],
                                  [-8.113, 92],
                                ],
                                o: [
                                  [-99.812, 51.586],
                                  [35.394, 135.314],
                                  [-59.573, 36.42],
                                  [137.239, 34.08],
                                  [18.05, 71.749],
                                  [111.937, -2.125],
                                  [-142.973, -83.747],
                                  [49.448, -155.234],
                                  [8.113, -92],
                                ],
                                v: [
                                  [-150.066, -383.886],
                                  [-166.787, -155.184],
                                  [-242.68, 63.518],
                                  [-246.776, 154.471],
                                  [-8.812, 295.07],
                                  [66.669, 384.659],
                                  [283.079, 274.762],
                                  [21.301, -97.582],
                                  [335.578, -210.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.243, -51.514],
                                  [-35.414, -135.336],
                                  [59.587, -36.407],
                                  [-137.264, -34.077],
                                  [-17.97, -71.66],
                                  [-112.559, 2.255],
                                  [143.005, 83.756],
                                  [-49.581, 155.156],
                                  [-8.109, 92],
                                ],
                                o: [
                                  [-99.818, 51.599],
                                  [35.414, 135.336],
                                  [-59.587, 36.407],
                                  [137.264, 34.077],
                                  [18.049, 71.757],
                                  [111.939, -2.121],
                                  [-143.005, -83.756],
                                  [49.466, -155.259],
                                  [8.109, -92],
                                ],
                                v: [
                                  [-149.913, -383.726],
                                  [-166.17, -154.795],
                                  [-243.114, 63.298],
                                  [-246.588, 154.472],
                                  [-10.145, 294.585],
                                  [66.913, 385.169],
                                  [283.746, 275.447],
                                  [21.311, -97.12],
                                  [335.996, -211.642],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.235, -51.53],
                                  [-35.433, -135.358],
                                  [59.6, -36.394],
                                  [-137.287, -34.075],
                                  [-17.971, -71.671],
                                  [-112.541, 2.247],
                                  [143.037, 83.763],
                                  [-49.594, 155.183],
                                  [-8.106, 92],
                                ],
                                o: [
                                  [-99.824, 51.612],
                                  [35.433, 135.358],
                                  [-59.6, 36.394],
                                  [137.287, 34.075],
                                  [18.047, 71.765],
                                  [111.941, -2.118],
                                  [-143.037, -83.763],
                                  [49.483, -155.283],
                                  [8.106, -92],
                                ],
                                v: [
                                  [-149.696, -383.565],
                                  [-165.615, -154.469],
                                  [-243.548, 63.077],
                                  [-246.338, 154.41],
                                  [-11.543, 294.162],
                                  [67.22, 385.74],
                                  [284.414, 276.131],
                                  [21.261, -96.657],
                                  [336.413, -212.662],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.228, -51.545],
                                  [-35.451, -135.379],
                                  [59.613, -36.381],
                                  [-137.31, -34.073],
                                  [-17.972, -71.681],
                                  [-112.523, 2.239],
                                  [143.068, 83.771],
                                  [-49.607, 155.209],
                                  [-8.102, 92],
                                ],
                                o: [
                                  [-99.829, 51.625],
                                  [35.451, 135.379],
                                  [-59.613, 36.381],
                                  [137.31, 34.073],
                                  [18.046, 71.772],
                                  [111.943, -2.114],
                                  [-143.068, -83.771],
                                  [49.499, -155.306],
                                  [8.102, -92],
                                ],
                                v: [
                                  [-149.479, -383.341],
                                  [-165.06, -154.081],
                                  [-243.982, 62.793],
                                  [-246.149, 154.472],
                                  [-12.879, 293.739],
                                  [67.588, 386.311],
                                  [285.082, 276.754],
                                  [21.275, -96.193],
                                  [336.767, -213.62],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.22, -51.56],
                                  [-35.469, -135.399],
                                  [59.626, -36.369],
                                  [-137.333, -34.07],
                                  [-17.973, -71.692],
                                  [-112.506, 2.231],
                                  [143.099, 83.778],
                                  [-49.62, 155.235],
                                  [-8.099, 92],
                                ],
                                o: [
                                  [-99.835, 51.637],
                                  [35.469, 135.399],
                                  [-59.626, 36.369],
                                  [137.333, 34.07],
                                  [18.044, 71.78],
                                  [111.945, -2.11],
                                  [-143.099, -83.778],
                                  [49.516, -155.328],
                                  [8.099, -92],
                                ],
                                v: [
                                  [-149.199, -383.117],
                                  [-164.567, -153.755],
                                  [-244.417, 62.508],
                                  [-245.962, 154.534],
                                  [-14.217, 293.315],
                                  [68.019, 386.881],
                                  [285.689, 277.377],
                                  [21.291, -95.789],
                                  [337.119, -214.639],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.213, -51.574],
                                  [-35.486, -135.418],
                                  [59.638, -36.357],
                                  [-137.354, -34.068],
                                  [-17.973, -71.702],
                                  [-112.49, 2.224],
                                  [143.128, 83.786],
                                  [-49.632, 155.26],
                                  [-8.096, 92],
                                ],
                                o: [
                                  [-99.84, 51.648],
                                  [35.486, 135.418],
                                  [-59.638, 36.357],
                                  [137.354, 34.068],
                                  [18.043, 71.787],
                                  [111.947, -2.107],
                                  [-143.128, -83.786],
                                  [49.531, -155.35],
                                  [8.096, -92],
                                ],
                                v: [
                                  [-148.855, -382.954],
                                  [-164.074, -153.429],
                                  [-244.851, 62.16],
                                  [-245.775, 154.658],
                                  [-15.556, 292.891],
                                  [68.575, 387.451],
                                  [286.296, 278.001],
                                  [21.308, -95.385],
                                  [337.47, -215.659],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.206, -51.588],
                                  [-35.503, -135.437],
                                  [59.65, -36.345],
                                  [-137.375, -34.066],
                                  [-17.974, -71.711],
                                  [-112.474, 2.216],
                                  [143.156, 83.793],
                                  [-49.644, 155.284],
                                  [-8.093, 92],
                                ],
                                o: [
                                  [-99.845, 51.66],
                                  [35.503, 135.437],
                                  [-59.65, 36.345],
                                  [137.375, 34.066],
                                  [18.041, 71.794],
                                  [111.948, -2.103],
                                  [-143.156, -83.792],
                                  [49.546, -155.371],
                                  [8.093, -92],
                                ],
                                v: [
                                  [-148.51, -382.728],
                                  [-163.642, -153.166],
                                  [-245.286, 61.749],
                                  [-245.525, 154.783],
                                  [-16.897, 292.467],
                                  [69.13, 388.02],
                                  [286.841, 278.625],
                                  [21.388, -94.979],
                                  [337.757, -216.678],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.199, -51.601],
                                  [-35.519, -135.455],
                                  [59.661, -36.334],
                                  [-137.395, -34.064],
                                  [-17.975, -71.721],
                                  [-112.459, 2.209],
                                  [143.184, 83.799],
                                  [-49.656, 155.307],
                                  [-8.09, 92],
                                ],
                                o: [
                                  [-99.85, 51.671],
                                  [35.519, 135.455],
                                  [-59.661, 36.334],
                                  [137.395, 34.064],
                                  [18.04, 71.801],
                                  [111.95, -2.1],
                                  [-143.183, -83.799],
                                  [49.561, -155.392],
                                  [8.09, -92],
                                ],
                                v: [
                                  [-148.102, -382.44],
                                  [-163.274, -152.903],
                                  [-245.658, 61.338],
                                  [-245.338, 154.969],
                                  [-18.238, 292.043],
                                  [69.687, 388.714],
                                  [287.388, 279.188],
                                  [21.408, -94.635],
                                  [338.044, -217.759],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.193, -51.614],
                                  [-35.535, -135.473],
                                  [59.672, -36.323],
                                  [-137.415, -34.062],
                                  [-17.976, -71.73],
                                  [-112.444, 2.203],
                                  [143.21, 83.806],
                                  [-49.667, 155.329],
                                  [-8.087, 92],
                                ],
                                o: [
                                  [-99.855, 51.682],
                                  [35.535, 135.473],
                                  [-59.672, 36.323],
                                  [137.415, 34.061],
                                  [18.039, 71.807],
                                  [111.952, -2.096],
                                  [-143.21, -83.806],
                                  [49.576, -155.411],
                                  [8.087, -92],
                                ],
                                v: [
                                  [-147.631, -382.213],
                                  [-162.905, -152.641],
                                  [-246.031, 60.863],
                                  [-245.151, 155.155],
                                  [-19.582, 291.619],
                                  [70.367, 389.469],
                                  [287.934, 279.751],
                                  [21.492, -94.29],
                                  [338.267, -218.778],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.187, -51.627],
                                  [-35.55, -135.49],
                                  [59.683, -36.313],
                                  [-137.434, -34.06],
                                  [-17.977, -71.739],
                                  [-112.429, 2.196],
                                  [143.236, 83.812],
                                  [-49.678, 155.351],
                                  [-8.084, 92],
                                ],
                                o: [
                                  [-99.86, 51.692],
                                  [35.55, 135.49],
                                  [-59.683, 36.313],
                                  [137.434, 34.06],
                                  [18.037, 71.813],
                                  [111.953, -2.093],
                                  [-143.236, -83.812],
                                  [49.589, -155.431],
                                  [8.084, -92],
                                ],
                                v: [
                                  [-147.222, -381.924],
                                  [-162.597, -152.379],
                                  [-246.403, 60.388],
                                  [-244.964, 155.342],
                                  [-20.926, 291.194],
                                  [71.048, 390.224],
                                  [288.42, 280.251],
                                  [21.577, -93.944],
                                  [338.489, -219.797],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.181, -51.639],
                                  [-35.564, -135.507],
                                  [59.693, -36.302],
                                  [-137.453, -34.058],
                                  [-17.977, -71.747],
                                  [-112.415, 2.19],
                                  [143.261, 83.818],
                                  [-49.689, 155.373],
                                  [-8.081, 92],
                                ],
                                o: [
                                  [-99.865, 51.702],
                                  [35.564, 135.507],
                                  [-59.693, 36.302],
                                  [137.453, 34.058],
                                  [18.036, 71.819],
                                  [111.955, -2.09],
                                  [-143.261, -83.818],
                                  [49.603, -155.449],
                                  [8.081, -92],
                                ],
                                v: [
                                  [-146.687, -381.696],
                                  [-162.291, -152.179],
                                  [-246.776, 59.849],
                                  [-244.84, 155.528],
                                  [-22.209, 290.831],
                                  [71.791, 391.041],
                                  [288.905, 280.815],
                                  [21.662, -93.659],
                                  [338.71, -220.877],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.175, -51.651],
                                  [-35.579, -135.523],
                                  [59.703, -36.292],
                                  [-137.471, -34.056],
                                  [-17.978, -71.756],
                                  [-112.402, 2.183],
                                  [143.285, 83.824],
                                  [-49.699, 155.393],
                                  [-8.078, 92],
                                ],
                                o: [
                                  [-99.869, 51.712],
                                  [35.579, 135.523],
                                  [-59.703, 36.292],
                                  [137.471, 34.056],
                                  [18.035, 71.825],
                                  [111.956, -2.087],
                                  [-143.285, -83.824],
                                  [49.616, -155.467],
                                  [8.079, -92],
                                ],
                                v: [
                                  [-146.151, -381.405],
                                  [-162.046, -152.042],
                                  [-247.148, 59.31],
                                  [-244.777, 155.715],
                                  [-23.493, 290.405],
                                  [72.534, 391.92],
                                  [289.329, 281.316],
                                  [21.812, -93.311],
                                  [338.868, -221.895],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.169, -51.662],
                                  [-35.593, -135.539],
                                  [59.713, -36.283],
                                  [-137.488, -34.054],
                                  [-17.979, -71.764],
                                  [-112.388, 2.177],
                                  [143.309, 83.83],
                                  [-49.709, 155.413],
                                  [-8.076, 92],
                                ],
                                o: [
                                  [-99.873, 51.721],
                                  [35.593, 135.539],
                                  [-59.713, 36.283],
                                  [137.488, 34.054],
                                  [18.034, 71.831],
                                  [111.958, -2.084],
                                  [-143.309, -83.83],
                                  [49.629, -155.485],
                                  [8.076, -92],
                                ],
                                v: [
                                  [-145.614, -381.114],
                                  [-161.801, -151.906],
                                  [-247.459, 58.77],
                                  [-244.715, 155.901],
                                  [-24.841, 290.042],
                                  [73.34, 392.798],
                                  [289.753, 281.755],
                                  [21.901, -93.025],
                                  [338.962, -222.975],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.163, -51.673],
                                  [-35.606, -135.554],
                                  [59.722, -36.273],
                                  [-137.505, -34.052],
                                  [-17.98, -71.771],
                                  [-112.376, 2.171],
                                  [143.332, 83.836],
                                  [-49.718, 155.433],
                                  [-8.073, 92],
                                ],
                                o: [
                                  [-99.878, 51.731],
                                  [35.606, 135.554],
                                  [-59.722, 36.273],
                                  [137.505, 34.052],
                                  [18.033, 71.837],
                                  [111.959, -2.082],
                                  [-143.332, -83.836],
                                  [49.641, -155.502],
                                  [8.073, -92],
                                ],
                                v: [
                                  [-145.015, -380.885],
                                  [-161.494, -151.831],
                                  [-247.769, 58.167],
                                  [-244.715, 156.088],
                                  [-26.065, 289.678],
                                  [74.208, 393.677],
                                  [290.178, 282.195],
                                  [22.052, -92.737],
                                  [339.118, -223.992],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.158, -51.684],
                                  [-35.619, -135.569],
                                  [59.732, -36.264],
                                  [-137.521, -34.05],
                                  [-17.98, -71.779],
                                  [-112.363, 2.166],
                                  [143.354, 83.841],
                                  [-49.728, 155.451],
                                  [-8.071, 92],
                                ],
                                o: [
                                  [-99.882, 51.74],
                                  [35.619, 135.569],
                                  [-59.732, 36.264],
                                  [137.521, 34.05],
                                  [18.032, 71.842],
                                  [111.96, -2.079],
                                  [-143.354, -83.841],
                                  [49.653, -155.518],
                                  [8.071, -92],
                                ],
                                v: [
                                  [-144.414, -380.592],
                                  [-161.249, -151.757],
                                  [-248.08, 57.501],
                                  [-244.776, 156.212],
                                  [-27.352, 289.314],
                                  [75.077, 394.68],
                                  [290.541, 282.635],
                                  [22.205, -92.512],
                                  [339.149, -225.071],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.153, -51.695],
                                  [-35.632, -135.583],
                                  [59.741, -36.256],
                                  [-137.537, -34.049],
                                  [-17.981, -71.786],
                                  [-112.351, 2.16],
                                  [143.375, 83.846],
                                  [-49.737, 155.47],
                                  [-8.069, 92],
                                ],
                                o: [
                                  [-99.886, 51.748],
                                  [35.632, 135.583],
                                  [-59.741, 36.256],
                                  [137.537, 34.049],
                                  [18.031, 71.847],
                                  [111.962, -2.076],
                                  [-143.375, -83.846],
                                  [49.664, -155.534],
                                  [8.069, -92],
                                ],
                                v: [
                                  [-143.75, -380.299],
                                  [-160.942, -151.746],
                                  [-248.39, 56.897],
                                  [-244.839, 156.399],
                                  [-28.578, 288.95],
                                  [75.946, 395.62],
                                  [290.905, 283.074],
                                  [22.36, -92.285],
                                  [339.24, -226.088],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.148, -51.705],
                                  [-35.644, -135.597],
                                  [59.749, -36.247],
                                  [-137.553, -34.047],
                                  [-17.982, -71.793],
                                  [-112.339, 2.155],
                                  [143.396, 83.851],
                                  [-49.745, 155.487],
                                  [-8.066, 92],
                                ],
                                o: [
                                  [-99.889, 51.757],
                                  [35.644, 135.597],
                                  [-59.749, 36.247],
                                  [137.553, 34.047],
                                  [18.03, 71.852],
                                  [111.963, -2.074],
                                  [-143.396, -83.852],
                                  [49.675, -155.55],
                                  [8.066, -92],
                                ],
                                v: [
                                  [-143.086, -380.006],
                                  [-160.697, -151.734],
                                  [-248.701, 56.229],
                                  [-244.963, 156.524],
                                  [-29.805, 288.586],
                                  [76.877, 396.623],
                                  [291.206, 283.452],
                                  [22.577, -92.058],
                                  [339.269, -227.103],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.143, -51.715],
                                  [-35.656, -135.61],
                                  [59.758, -36.239],
                                  [-137.568, -34.045],
                                  [-17.982, -71.8],
                                  [-112.328, 2.15],
                                  [143.416, 83.856],
                                  [-49.754, 155.504],
                                  [-8.064, 92],
                                ],
                                o: [
                                  [-99.893, 51.765],
                                  [35.656, 135.61],
                                  [-59.758, 36.239],
                                  [137.568, 34.045],
                                  [18.029, 71.857],
                                  [111.964, -2.071],
                                  [-143.416, -83.857],
                                  [49.686, -155.565],
                                  [8.064, -92],
                                ],
                                v: [
                                  [-142.421, -379.713],
                                  [-160.39, -151.785],
                                  [-248.949, 55.499],
                                  [-245.087, 156.711],
                                  [-30.971, 288.222],
                                  [77.808, 397.625],
                                  [291.509, 283.83],
                                  [22.733, -91.83],
                                  [339.234, -228.181],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.138, -51.724],
                                  [-35.667, -135.623],
                                  [59.766, -36.231],
                                  [-137.582, -34.044],
                                  [-17.983, -71.807],
                                  [-112.317, 2.145],
                                  [143.436, 83.861],
                                  [-49.762, 155.521],
                                  [-8.062, 92],
                                ],
                                o: [
                                  [-99.897, 51.772],
                                  [35.667, 135.623],
                                  [-59.766, 36.231],
                                  [137.582, 34.044],
                                  [18.028, 71.862],
                                  [111.966, -2.069],
                                  [-143.436, -83.861],
                                  [49.697, -155.579],
                                  [8.062, -92],
                                ],
                                v: [
                                  [-141.693, -379.418],
                                  [-160.146, -151.899],
                                  [-249.197, 54.83],
                                  [-245.274, 156.835],
                                  [-32.137, 287.919],
                                  [78.802, 398.69],
                                  [291.811, 284.208],
                                  [22.953, -91.664],
                                  [339.199, -229.196],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.133, -51.733],
                                  [-35.678, -135.636],
                                  [59.773, -36.223],
                                  [-137.596, -34.042],
                                  [-17.983, -71.813],
                                  [-112.307, 2.14],
                                  [143.454, 83.866],
                                  [-49.77, 155.537],
                                  [-8.06, 92],
                                ],
                                o: [
                                  [-99.9, 51.78],
                                  [35.678, 135.636],
                                  [-59.773, 36.223],
                                  [137.596, 34.042],
                                  [18.027, 71.867],
                                  [111.967, -2.067],
                                  [-143.454, -83.866],
                                  [49.707, -155.594],
                                  [8.06, -92],
                                ],
                                v: [
                                  [-140.964, -379.123],
                                  [-159.901, -152.013],
                                  [-249.446, 54.098],
                                  [-245.461, 157.022],
                                  [-33.304, 287.616],
                                  [79.796, 399.754],
                                  [292.052, 284.523],
                                  [23.173, -91.497],
                                  [339.163, -230.21],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.129, -51.742],
                                  [-35.689, -135.648],
                                  [59.781, -36.216],
                                  [-137.61, -34.041],
                                  [-17.984, -71.82],
                                  [-112.296, 2.135],
                                  [143.473, 83.87],
                                  [-49.778, 155.552],
                                  [-8.058, 92],
                                ],
                                o: [
                                  [-99.903, 51.787],
                                  [35.689, 135.648],
                                  [-59.781, 36.216],
                                  [137.61, 34.041],
                                  [18.026, 71.871],
                                  [111.968, -2.064],
                                  [-143.473, -83.87],
                                  [49.717, -155.607],
                                  [8.058, -92],
                                ],
                                v: [
                                  [-140.234, -378.828],
                                  [-159.656, -152.128],
                                  [-249.694, 53.366],
                                  [-245.71, 157.147],
                                  [-34.41, 287.313],
                                  [80.852, 400.819],
                                  [292.294, 284.839],
                                  [23.457, -91.33],
                                  [339.064, -231.224],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.124, -51.751],
                                  [-35.7, -135.66],
                                  [59.788, -36.208],
                                  [-137.623, -34.04],
                                  [-17.984, -71.826],
                                  [-112.286, 2.131],
                                  [143.49, 83.875],
                                  [-49.785, 155.567],
                                  [-8.056, 92],
                                ],
                                o: [
                                  [-99.907, 51.795],
                                  [35.7, 135.66],
                                  [-59.788, 36.208],
                                  [137.623, 34.04],
                                  [18.025, 71.876],
                                  [111.969, -2.062],
                                  [-143.49, -83.875],
                                  [49.726, -155.62],
                                  [8.056, -92],
                                ],
                                v: [
                                  [-139.504, -378.595],
                                  [-159.473, -152.304],
                                  [-249.88, 52.633],
                                  [-246.021, 157.334],
                                  [-35.454, 287.009],
                                  [81.847, 401.883],
                                  [292.536, 285.154],
                                  [23.679, -91.224],
                                  [338.964, -232.175],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.12, -51.759],
                                  [-35.71, -135.672],
                                  [59.796, -36.201],
                                  [-137.635, -34.038],
                                  [-17.985, -71.832],
                                  [-112.277, 2.126],
                                  [143.508, 83.879],
                                  [-49.793, 155.582],
                                  [-8.054, 92],
                                ],
                                o: [
                                  [-99.91, 51.802],
                                  [35.71, 135.672],
                                  [-59.796, 36.201],
                                  [137.635, 34.038],
                                  [18.024, 71.88],
                                  [111.97, -2.06],
                                  [-143.508, -83.879],
                                  [49.735, -155.633],
                                  [8.054, -92],
                                ],
                                v: [
                                  [-138.711, -378.299],
                                  [-159.291, -152.544],
                                  [-250.066, 51.899],
                                  [-246.333, 157.459],
                                  [-36.498, 286.706],
                                  [82.904, 402.948],
                                  [292.715, 285.407],
                                  [23.965, -91.056],
                                  [338.801, -233.188],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.116, -51.768],
                                  [-35.72, -135.683],
                                  [59.802, -36.195],
                                  [-137.648, -34.037],
                                  [-17.986, -71.837],
                                  [-112.267, 2.122],
                                  [143.524, 83.883],
                                  [-49.8, 155.596],
                                  [-8.052, 92],
                                ],
                                o: [
                                  [-99.913, 51.808],
                                  [35.72, 135.683],
                                  [-59.802, 36.195],
                                  [137.648, 34.037],
                                  [18.023, 71.884],
                                  [111.971, -2.058],
                                  [-143.524, -83.883],
                                  [49.744, -155.646],
                                  [8.052, -92],
                                ],
                                v: [
                                  [-137.917, -378.002],
                                  [-159.108, -152.784],
                                  [-250.252, 51.103],
                                  [-246.707, 157.646],
                                  [-37.543, 286.464],
                                  [83.961, 404.012],
                                  [292.896, 285.661],
                                  [24.251, -91.011],
                                  [338.638, -234.139],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.112, -51.775],
                                  [-35.729, -135.693],
                                  [59.809, -36.188],
                                  [-137.66, -34.036],
                                  [-17.986, -71.843],
                                  [-112.258, 2.118],
                                  [143.54, 83.887],
                                  [-49.806, 155.61],
                                  [-8.05, 92],
                                ],
                                o: [
                                  [-99.916, 51.815],
                                  [35.729, 135.693],
                                  [-59.809, 36.188],
                                  [137.66, 34.036],
                                  [18.022, 71.888],
                                  [111.972, -2.056],
                                  [-143.54, -83.887],
                                  [49.753, -155.658],
                                  [8.051, -92],
                                ],
                                v: [
                                  [-137.122, -377.767],
                                  [-158.925, -153.086],
                                  [-250.375, 50.368],
                                  [-247.081, 157.833],
                                  [-38.527, 286.16],
                                  [85.019, 405.077],
                                  [293.077, 285.914],
                                  [24.538, -90.904],
                                  [338.411, -235.089],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.108, -51.783],
                                  [-35.738, -135.704],
                                  [59.816, -36.182],
                                  [-137.671, -34.035],
                                  [-17.986, -71.848],
                                  [-112.249, 2.114],
                                  [143.556, 83.891],
                                  [-49.813, 155.623],
                                  [-8.049, 92],
                                ],
                                o: [
                                  [-99.919, 51.821],
                                  [35.738, 135.704],
                                  [-59.816, 36.182],
                                  [137.671, 34.035],
                                  [18.022, 71.892],
                                  [111.973, -2.054],
                                  [-143.556, -83.891],
                                  [49.761, -155.669],
                                  [8.049, -92],
                                ],
                                v: [
                                  [-136.265, -377.531],
                                  [-158.805, -153.389],
                                  [-250.561, 49.633],
                                  [-247.518, 157.958],
                                  [-39.449, 285.917],
                                  [86.138, 406.141],
                                  [293.195, 286.167],
                                  [24.888, -90.859],
                                  [338.246, -236.039],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.105, -51.79],
                                  [-35.747, -135.714],
                                  [59.822, -36.175],
                                  [-137.682, -34.033],
                                  [-17.987, -71.853],
                                  [-112.241, 2.11],
                                  [143.571, 83.895],
                                  [-49.819, 155.636],
                                  [-8.047, 92],
                                ],
                                o: [
                                  [-99.921, 51.827],
                                  [35.747, 135.714],
                                  [-59.822, 36.175],
                                  [137.682, 34.033],
                                  [18.021, 71.895],
                                  [111.974, -2.052],
                                  [-143.571, -83.895],
                                  [49.77, -155.681],
                                  [8.047, -92],
                                ],
                                v: [
                                  [-135.47, -377.233],
                                  [-158.684, -153.754],
                                  [-250.684, 48.835],
                                  [-247.955, 158.146],
                                  [-40.309, 285.675],
                                  [87.259, 407.205],
                                  [293.314, 286.358],
                                  [25.24, -90.813],
                                  [337.955, -236.988],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.101, -51.798],
                                  [-35.756, -135.724],
                                  [59.828, -36.169],
                                  [-137.693, -34.032],
                                  [-17.987, -71.858],
                                  [-112.233, 2.106],
                                  [143.586, 83.898],
                                  [-49.826, 155.648],
                                  [-8.046, 92],
                                ],
                                o: [
                                  [-99.924, 51.833],
                                  [35.756, 135.724],
                                  [-59.828, 36.169],
                                  [137.693, 34.032],
                                  [18.02, 71.899],
                                  [111.975, -2.051],
                                  [-143.586, -83.898],
                                  [49.777, -155.691],
                                  [8.046, -92],
                                ],
                                v: [
                                  [-134.611, -376.996],
                                  [-158.563, -154.12],
                                  [-250.746, 48.098],
                                  [-248.454, 158.333],
                                  [-41.17, 285.494],
                                  [88.317, 408.27],
                                  [293.433, 286.549],
                                  [25.591, -90.767],
                                  [337.664, -237.937],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.098, -51.805],
                                  [-35.764, -135.733],
                                  [59.834, -36.164],
                                  [-137.704, -34.031],
                                  [-17.988, -71.863],
                                  [-112.225, 2.103],
                                  [143.6, 83.902],
                                  [-49.831, 155.66],
                                  [-8.044, 92],
                                ],
                                o: [
                                  [-99.927, 51.839],
                                  [35.764, 135.733],
                                  [-59.834, 36.164],
                                  [137.704, 34.031],
                                  [18.02, 71.902],
                                  [111.976, -2.049],
                                  [-143.6, -83.902],
                                  [49.785, -155.702],
                                  [8.044, -92],
                                ],
                                v: [
                                  [-133.815, -376.821],
                                  [-158.505, -154.486],
                                  [-250.869, 47.361],
                                  [-248.953, 158.458],
                                  [-42.031, 285.251],
                                  [89.437, 409.272],
                                  [293.491, 286.74],
                                  [25.944, -90.782],
                                  [337.372, -238.823],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.094, -51.811],
                                  [-35.772, -135.742],
                                  [59.84, -36.158],
                                  [-137.714, -34.03],
                                  [-17.988, -71.868],
                                  [-112.217, 2.099],
                                  [143.614, 83.905],
                                  [-49.837, 155.672],
                                  [-8.042, 92],
                                ],
                                o: [
                                  [-99.929, 51.844],
                                  [35.772, 135.742],
                                  [-59.84, 36.158],
                                  [137.714, 34.03],
                                  [18.019, 71.906],
                                  [111.976, -2.047],
                                  [-143.614, -83.905],
                                  [49.792, -155.712],
                                  [8.042, -92],
                                ],
                                v: [
                                  [-132.955, -376.583],
                                  [-158.446, -154.914],
                                  [-250.93, 46.624],
                                  [-249.453, 158.646],
                                  [-42.768, 285.07],
                                  [90.558, 410.336],
                                  [293.548, 286.869],
                                  [26.297, -90.86],
                                  [337.08, -239.709],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.091, -51.818],
                                  [-35.78, -135.751],
                                  [59.845, -36.153],
                                  [-137.724, -34.029],
                                  [-17.989, -71.872],
                                  [-112.21, 2.096],
                                  [143.627, 83.908],
                                  [-49.843, 155.683],
                                  [-8.041, 92],
                                ],
                                o: [
                                  [-99.932, 51.85],
                                  [35.78, 135.751],
                                  [-59.845, 36.153],
                                  [137.724, 34.029],
                                  [18.018, 71.909],
                                  [111.977, -2.046],
                                  [-143.627, -83.908],
                                  [49.8, -155.722],
                                  [8.041, -92],
                                ],
                                v: [
                                  [-132.095, -376.408],
                                  [-158.387, -155.343],
                                  [-250.991, 45.823],
                                  [-250.077, 158.833],
                                  [-43.505, 284.889],
                                  [91.678, 411.338],
                                  [293.606, 286.997],
                                  [26.714, -90.874],
                                  [336.724, -240.532],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.088, -51.824],
                                  [-35.788, -135.76],
                                  [59.85, -36.147],
                                  [-137.733, -34.028],
                                  [-17.989, -71.877],
                                  [-112.202, 2.092],
                                  [143.64, 83.911],
                                  [-49.848, 155.694],
                                  [-8.04, 92],
                                ],
                                o: [
                                  [-99.934, 51.855],
                                  [35.788, 135.76],
                                  [-59.85, 36.147],
                                  [137.733, 34.028],
                                  [18.018, 71.912],
                                  [111.978, -2.044],
                                  [-143.64, -83.911],
                                  [49.806, -155.732],
                                  [8.04, -92],
                                ],
                                v: [
                                  [-131.297, -376.169],
                                  [-158.391, -155.834],
                                  [-251.052, 45.147],
                                  [-250.639, 159.021],
                                  [-44.181, 284.707],
                                  [92.8, 412.34],
                                  [293.602, 287.126],
                                  [27.131, -90.951],
                                  [336.306, -241.418],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.085, -51.83],
                                  [-35.795, -135.768],
                                  [59.856, -36.142],
                                  [-137.743, -34.027],
                                  [-17.989, -71.881],
                                  [-112.195, 2.089],
                                  [143.652, 83.914],
                                  [-49.853, 155.705],
                                  [-8.038, 92],
                                ],
                                o: [
                                  [-99.936, 51.86],
                                  [35.795, 135.768],
                                  [-59.856, 36.142],
                                  [137.742, 34.027],
                                  [18.017, 71.915],
                                  [111.979, -2.042],
                                  [-143.652, -83.914],
                                  [49.813, -155.741],
                                  [8.038, -92],
                                ],
                                v: [
                                  [-130.437, -375.993],
                                  [-158.457, -156.326],
                                  [-251.051, 44.409],
                                  [-251.263, 159.146],
                                  [-44.857, 284.588],
                                  [93.921, 413.28],
                                  [293.66, 287.254],
                                  [27.548, -91.09],
                                  [335.949, -242.24],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.082, -51.836],
                                  [-35.802, -135.776],
                                  [59.861, -36.137],
                                  [-137.751, -34.026],
                                  [-17.99, -71.885],
                                  [-112.189, 2.086],
                                  [143.664, 83.917],
                                  [-49.859, 155.715],
                                  [-8.037, 92],
                                ],
                                o: [
                                  [-99.938, 51.865],
                                  [35.802, 135.776],
                                  [-59.861, 36.137],
                                  [137.751, 34.026],
                                  [18.016, 71.918],
                                  [111.98, -2.041],
                                  [-143.664, -83.917],
                                  [49.82, -155.75],
                                  [8.037, -92],
                                ],
                                v: [
                                  [-129.638, -375.878],
                                  [-158.523, -156.818],
                                  [-251.049, 43.669],
                                  [-251.95, 159.334],
                                  [-45.471, 284.468],
                                  [95.042, 414.282],
                                  [293.656, 287.383],
                                  [27.967, -91.228],
                                  [335.467, -243.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.079, -51.842],
                                  [-35.809, -135.784],
                                  [59.865, -36.133],
                                  [-137.76, -34.025],
                                  [-17.99, -71.889],
                                  [-112.182, 2.083],
                                  [143.676, 83.92],
                                  [-49.863, 155.725],
                                  [-8.036, 92],
                                ],
                                o: [
                                  [-99.941, 51.869],
                                  [35.809, 135.784],
                                  [-59.865, 36.133],
                                  [137.76, 34.025],
                                  [18.016, 71.921],
                                  [111.98, -2.04],
                                  [-143.676, -83.92],
                                  [49.826, -155.759],
                                  [8.036, -92],
                                ],
                                v: [
                                  [-128.776, -375.701],
                                  [-158.588, -157.373],
                                  [-251.048, 42.992],
                                  [-252.637, 159.522],
                                  [-45.96, 284.348],
                                  [96.163, 415.221],
                                  [293.59, 287.449],
                                  [28.448, -91.366],
                                  [335.047, -243.822],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.076, -51.847],
                                  [-35.816, -135.791],
                                  [59.87, -36.128],
                                  [-137.768, -34.024],
                                  [-17.99, -71.893],
                                  [-112.176, 2.08],
                                  [143.687, 83.923],
                                  [-49.868, 155.734],
                                  [-8.034, 92],
                                ],
                                o: [
                                  [-99.943, 51.874],
                                  [35.816, 135.791],
                                  [-59.87, 36.128],
                                  [137.768, 34.024],
                                  [18.015, 71.924],
                                  [111.981, -2.038],
                                  [-143.687, -83.923],
                                  [49.832, -155.767],
                                  [8.034, -92],
                                ],
                                v: [
                                  [-127.976, -375.585],
                                  [-158.716, -157.928],
                                  [-251.047, 42.314],
                                  [-253.324, 159.71],
                                  [-46.451, 284.228],
                                  [97.223, 416.099],
                                  [293.586, 287.515],
                                  [28.93, -91.566],
                                  [334.564, -244.582],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.074, -51.853],
                                  [-35.822, -135.799],
                                  [59.875, -36.124],
                                  [-137.776, -34.023],
                                  [-17.991, -71.897],
                                  [-112.17, 2.077],
                                  [143.698, 83.926],
                                  [-49.873, 155.744],
                                  [-8.033, 92],
                                ],
                                o: [
                                  [-99.945, 51.878],
                                  [35.822, 135.799],
                                  [-59.875, 36.124],
                                  [137.776, 34.024],
                                  [18.015, 71.926],
                                  [111.982, -2.037],
                                  [-143.698, -83.926],
                                  [49.838, -155.775],
                                  [8.033, -92],
                                ],
                                v: [
                                  [-127.176, -375.532],
                                  [-158.844, -158.483],
                                  [-250.983, 41.699],
                                  [-254.073, 159.836],
                                  [-46.941, 284.171],
                                  [98.345, 416.976],
                                  [293.52, 287.581],
                                  [29.413, -91.765],
                                  [334.08, -245.278],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.071, -51.858],
                                  [-35.828, -135.806],
                                  [59.879, -36.119],
                                  [-137.784, -34.023],
                                  [-17.991, -71.9],
                                  [-112.164, 2.075],
                                  [143.709, 83.928],
                                  [-49.877, 155.753],
                                  [-8.032, 92],
                                ],
                                o: [
                                  [-99.947, 51.883],
                                  [35.828, 135.806],
                                  [-59.879, 36.119],
                                  [137.784, 34.023],
                                  [18.014, 71.929],
                                  [111.982, -2.036],
                                  [-143.709, -83.928],
                                  [49.843, -155.783],
                                  [8.032, -92],
                                ],
                                v: [
                                  [-126.376, -375.416],
                                  [-159.034, -159.039],
                                  [-250.919, 41.02],
                                  [-254.823, 160.024],
                                  [-47.307, 284.05],
                                  [99.467, 417.791],
                                  [293.455, 287.647],
                                  [29.958, -92.027],
                                  [333.534, -246.037],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.069, -51.863],
                                  [-35.834, -135.812],
                                  [59.883, -36.115],
                                  [-137.792, -34.022],
                                  [-17.991, -71.904],
                                  [-112.158, 2.072],
                                  [143.719, 83.931],
                                  [-49.882, 155.761],
                                  [-8.031, 92],
                                ],
                                o: [
                                  [-99.948, 51.887],
                                  [35.834, 135.812],
                                  [-59.883, 36.115],
                                  [137.792, 34.022],
                                  [18.014, 71.931],
                                  [111.983, -2.034],
                                  [-143.719, -83.931],
                                  [49.849, -155.791],
                                  [8.031, -92],
                                ],
                                v: [
                                  [-125.575, -375.362],
                                  [-159.224, -159.657],
                                  [-250.855, 40.404],
                                  [-255.573, 160.149],
                                  [-47.61, 283.992],
                                  [100.526, 418.606],
                                  [293.389, 287.65],
                                  [30.442, -92.288],
                                  [332.986, -246.671],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.071, -51.858],
                                  [-35.828, -135.806],
                                  [59.879, -36.119],
                                  [-137.784, -34.023],
                                  [-17.991, -71.9],
                                  [-112.164, 2.075],
                                  [143.709, 83.928],
                                  [-49.877, 155.753],
                                  [-8.032, 92],
                                ],
                                o: [
                                  [-99.947, 51.883],
                                  [35.828, 135.806],
                                  [-59.879, 36.119],
                                  [137.784, 34.023],
                                  [18.014, 71.929],
                                  [111.982, -2.036],
                                  [-143.709, -83.928],
                                  [49.843, -155.783],
                                  [8.032, -92],
                                ],
                                v: [
                                  [-124.815, -375.293],
                                  [-159.474, -160.224],
                                  [-250.732, 39.834],
                                  [-256.381, 160.335],
                                  [-47.931, 283.928],
                                  [101.588, 419.348],
                                  [293.33, 287.647],
                                  [31.02, -92.588],
                                  [332.411, -247.347],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.074, -51.853],
                                  [-35.822, -135.799],
                                  [59.875, -36.124],
                                  [-137.776, -34.023],
                                  [-17.991, -71.897],
                                  [-112.17, 2.077],
                                  [143.698, 83.926],
                                  [-49.873, 155.744],
                                  [-8.033, 92],
                                ],
                                o: [
                                  [-99.945, 51.878],
                                  [35.822, 135.799],
                                  [-59.875, 36.124],
                                  [137.776, 34.024],
                                  [18.015, 71.926],
                                  [111.982, -2.037],
                                  [-143.698, -83.926],
                                  [49.838, -155.775],
                                  [8.033, -92],
                                ],
                                v: [
                                  [-124.054, -375.287],
                                  [-159.725, -160.853],
                                  [-250.672, 39.263],
                                  [-257.19, 160.457],
                                  [-48.19, 283.927],
                                  [102.649, 420.09],
                                  [293.27, 287.705],
                                  [31.598, -92.949],
                                  [331.773, -247.961],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.076, -51.847],
                                  [-35.816, -135.791],
                                  [59.87, -36.128],
                                  [-137.768, -34.024],
                                  [-17.99, -71.893],
                                  [-112.176, 2.08],
                                  [143.687, 83.923],
                                  [-49.868, 155.734],
                                  [-8.034, 92],
                                ],
                                o: [
                                  [-99.943, 51.874],
                                  [35.816, 135.791],
                                  [-59.87, 36.128],
                                  [137.768, 34.024],
                                  [18.015, 71.924],
                                  [111.981, -2.038],
                                  [-143.687, -83.923],
                                  [49.832, -155.767],
                                  [8.034, -92],
                                ],
                                v: [
                                  [-123.293, -375.28],
                                  [-159.976, -161.421],
                                  [-250.487, 38.693],
                                  [-257.998, 160.58],
                                  [-48.324, 283.926],
                                  [103.71, 420.768],
                                  [293.148, 287.702],
                                  [32.24, -93.31],
                                  [331.134, -248.512],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.079, -51.842],
                                  [-35.809, -135.784],
                                  [59.865, -36.133],
                                  [-137.76, -34.025],
                                  [-17.99, -71.889],
                                  [-112.182, 2.083],
                                  [143.676, 83.92],
                                  [-49.863, 155.725],
                                  [-8.036, 92],
                                ],
                                o: [
                                  [-99.941, 51.869],
                                  [35.809, 135.784],
                                  [-59.865, 36.133],
                                  [137.76, 34.025],
                                  [18.016, 71.921],
                                  [111.98, -2.04],
                                  [-143.676, -83.92],
                                  [49.826, -155.759],
                                  [8.036, -92],
                                ],
                                v: [
                                  [-122.532, -375.273],
                                  [-160.352, -162.051],
                                  [-250.364, 38.184],
                                  [-258.868, 160.703],
                                  [-48.459, 283.925],
                                  [104.709, 421.384],
                                  [293.026, 287.635],
                                  [32.882, -93.733],
                                  [330.496, -249.063],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.082, -51.836],
                                  [-35.802, -135.776],
                                  [59.861, -36.137],
                                  [-137.751, -34.026],
                                  [-17.99, -71.885],
                                  [-112.189, 2.086],
                                  [143.664, 83.917],
                                  [-49.859, 155.715],
                                  [-8.037, 92],
                                ],
                                o: [
                                  [-99.938, 51.865],
                                  [35.802, 135.776],
                                  [-59.861, 36.137],
                                  [137.751, 34.026],
                                  [18.016, 71.918],
                                  [111.979, -2.041],
                                  [-143.664, -83.917],
                                  [49.82, -155.75],
                                  [8.037, -92],
                                ],
                                v: [
                                  [-121.833, -375.329],
                                  [-160.665, -162.68],
                                  [-250.241, 37.676],
                                  [-259.738, 160.826],
                                  [-48.532, 283.924],
                                  [105.77, 422],
                                  [292.905, 287.631],
                                  [33.524, -94.155],
                                  [329.794, -249.613],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.085, -51.83],
                                  [-35.795, -135.768],
                                  [59.856, -36.142],
                                  [-137.742, -34.027],
                                  [-17.989, -71.881],
                                  [-112.195, 2.089],
                                  [143.652, 83.915],
                                  [-49.853, 155.705],
                                  [-8.038, 92],
                                ],
                                o: [
                                  [-99.936, 51.86],
                                  [35.795, 135.768],
                                  [-59.856, 36.142],
                                  [137.742, 34.027],
                                  [18.017, 71.915],
                                  [111.979, -2.042],
                                  [-143.652, -83.915],
                                  [49.813, -155.741],
                                  [8.038, -92],
                                ],
                                v: [
                                  [-121.134, -375.384],
                                  [-161.041, -163.248],
                                  [-250.056, 37.229],
                                  [-260.67, 160.886],
                                  [-48.542, 283.923],
                                  [106.768, 422.553],
                                  [292.784, 287.628],
                                  [34.105, -94.701],
                                  [329.154, -250.1],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.088, -51.824],
                                  [-35.788, -135.76],
                                  [59.85, -36.147],
                                  [-137.733, -34.028],
                                  [-17.989, -71.877],
                                  [-112.202, 2.092],
                                  [143.64, 83.911],
                                  [-49.848, 155.694],
                                  [-8.04, 92],
                                ],
                                o: [
                                  [-99.934, 51.855],
                                  [35.788, 135.76],
                                  [-59.85, 36.147],
                                  [137.733, 34.028],
                                  [18.018, 71.912],
                                  [111.978, -2.044],
                                  [-143.64, -83.911],
                                  [49.806, -155.732],
                                  [8.04, -92],
                                ],
                                v: [
                                  [-120.496, -375.501],
                                  [-161.417, -163.816],
                                  [-249.871, 36.782],
                                  [-261.539, 160.946],
                                  [-48.491, 283.985],
                                  [107.704, 423.043],
                                  [292.662, 287.561],
                                  [34.686, -95.185],
                                  [328.39, -250.587],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.091, -51.818],
                                  [-35.78, -135.751],
                                  [59.845, -36.153],
                                  [-137.724, -34.029],
                                  [-17.989, -71.872],
                                  [-112.21, 2.096],
                                  [143.627, 83.908],
                                  [-49.843, 155.683],
                                  [-8.041, 92],
                                ],
                                o: [
                                  [-99.932, 51.85],
                                  [35.78, 135.751],
                                  [-59.845, 36.153],
                                  [137.724, 34.029],
                                  [18.018, 71.909],
                                  [111.977, -2.046],
                                  [-143.627, -83.908],
                                  [49.8, -155.722],
                                  [8.041, -92],
                                ],
                                v: [
                                  [-119.859, -375.619],
                                  [-161.855, -164.384],
                                  [-249.624, 36.397],
                                  [-262.47, 161.006],
                                  [-48.377, 284.046],
                                  [108.702, 423.532],
                                  [292.541, 287.495],
                                  [35.268, -95.793],
                                  [327.687, -251.011],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.094, -51.811],
                                  [-35.772, -135.742],
                                  [59.84, -36.158],
                                  [-137.714, -34.03],
                                  [-17.988, -71.868],
                                  [-112.217, 2.099],
                                  [143.614, 83.905],
                                  [-49.837, 155.672],
                                  [-8.042, 92],
                                ],
                                o: [
                                  [-99.929, 51.844],
                                  [35.772, 135.742],
                                  [-59.84, 36.158],
                                  [137.714, 34.03],
                                  [18.019, 71.906],
                                  [111.976, -2.047],
                                  [-143.614, -83.905],
                                  [49.792, -155.712],
                                  [8.042, -92],
                                ],
                                v: [
                                  [-119.283, -375.736],
                                  [-162.357, -164.952],
                                  [-249.439, 36.011],
                                  [-263.401, 161.066],
                                  [-48.265, 284.108],
                                  [109.575, 423.959],
                                  [292.421, 287.491],
                                  [35.788, -96.4],
                                  [326.922, -251.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.098, -51.805],
                                  [-35.764, -135.733],
                                  [59.834, -36.164],
                                  [-137.704, -34.031],
                                  [-17.988, -71.863],
                                  [-112.225, 2.103],
                                  [143.6, 83.902],
                                  [-49.831, 155.66],
                                  [-8.044, 92],
                                ],
                                o: [
                                  [-99.927, 51.839],
                                  [35.764, 135.733],
                                  [-59.834, 36.164],
                                  [137.704, 34.031],
                                  [18.02, 71.902],
                                  [111.976, -2.049],
                                  [-143.6, -83.902],
                                  [49.785, -155.702],
                                  [8.044, -92],
                                ],
                                v: [
                                  [-118.707, -375.915],
                                  [-162.795, -165.457],
                                  [-249.192, 35.75],
                                  [-264.332, 161.064],
                                  [-48.027, 284.231],
                                  [110.51, 424.447],
                                  [292.3, 287.424],
                                  [36.247, -97.007],
                                  [326.156, -251.794],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.101, -51.798],
                                  [-35.756, -135.724],
                                  [59.828, -36.169],
                                  [-137.693, -34.032],
                                  [-17.987, -71.858],
                                  [-112.233, 2.106],
                                  [143.586, 83.898],
                                  [-49.825, 155.648],
                                  [-8.046, 92],
                                ],
                                o: [
                                  [-99.924, 51.833],
                                  [35.756, 135.724],
                                  [-59.828, 36.169],
                                  [137.693, 34.032],
                                  [18.02, 71.899],
                                  [111.975, -2.051],
                                  [-143.586, -83.898],
                                  [49.777, -155.691],
                                  [8.046, -92],
                                ],
                                v: [
                                  [-118.131, -376.094],
                                  [-163.297, -165.963],
                                  [-248.945, 35.427],
                                  [-265.325, 161.061],
                                  [-47.728, 284.355],
                                  [111.383, 424.873],
                                  [292.118, 287.358],
                                  [36.768, -97.737],
                                  [325.39, -252.154],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.105, -51.79],
                                  [-35.747, -135.714],
                                  [59.822, -36.176],
                                  [-137.682, -34.033],
                                  [-17.987, -71.853],
                                  [-112.241, 2.11],
                                  [143.571, 83.895],
                                  [-49.819, 155.636],
                                  [-8.047, 92],
                                ],
                                o: [
                                  [-99.921, 51.827],
                                  [35.747, 135.714],
                                  [-59.822, 36.175],
                                  [137.682, 34.033],
                                  [18.021, 71.895],
                                  [111.974, -2.052],
                                  [-143.571, -83.895],
                                  [49.77, -155.68],
                                  [8.047, -92],
                                ],
                                v: [
                                  [-117.617, -376.335],
                                  [-163.86, -166.469],
                                  [-248.698, 35.227],
                                  [-266.255, 160.997],
                                  [-47.367, 284.479],
                                  [112.255, 425.236],
                                  [291.998, 287.291],
                                  [37.165, -98.405],
                                  [324.624, -252.451],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.108, -51.783],
                                  [-35.738, -135.704],
                                  [59.816, -36.182],
                                  [-137.671, -34.035],
                                  [-17.986, -71.848],
                                  [-112.249, 2.114],
                                  [143.556, 83.891],
                                  [-49.813, 155.623],
                                  [-8.049, 92],
                                ],
                                o: [
                                  [-99.919, 51.821],
                                  [35.738, 135.704],
                                  [-59.816, 36.182],
                                  [137.671, 34.035],
                                  [18.022, 71.892],
                                  [111.973, -2.054],
                                  [-143.556, -83.891],
                                  [49.761, -155.669],
                                  [8.049, -92],
                                ],
                                v: [
                                  [-117.164, -376.576],
                                  [-164.362, -166.913],
                                  [-248.389, 35.027],
                                  [-267.247, 160.87],
                                  [-47.007, 284.602],
                                  [113.127, 425.661],
                                  [291.879, 287.163],
                                  [37.625, -99.197],
                                  [323.794, -252.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.112, -51.775],
                                  [-35.729, -135.693],
                                  [59.809, -36.188],
                                  [-137.66, -34.036],
                                  [-17.986, -71.843],
                                  [-112.258, 2.118],
                                  [143.54, 83.887],
                                  [-49.806, 155.61],
                                  [-8.05, 92],
                                ],
                                o: [
                                  [-99.916, 51.815],
                                  [35.729, 135.693],
                                  [-59.809, 36.188],
                                  [137.66, 34.036],
                                  [18.022, 71.888],
                                  [111.972, -2.056],
                                  [-143.54, -83.887],
                                  [49.753, -155.658],
                                  [8.051, -92],
                                ],
                                v: [
                                  [-116.711, -376.879],
                                  [-164.863, -167.419],
                                  [-248.143, 34.827],
                                  [-268.239, 160.743],
                                  [-46.522, 284.726],
                                  [113.937, 426.022],
                                  [291.697, 287.096],
                                  [38.024, -99.988],
                                  [322.965, -252.98],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.116, -51.768],
                                  [-35.72, -135.683],
                                  [59.802, -36.195],
                                  [-137.648, -34.037],
                                  [-17.985, -71.837],
                                  [-112.267, 2.122],
                                  [143.524, 83.883],
                                  [-49.8, 155.596],
                                  [-8.052, 92],
                                ],
                                o: [
                                  [-99.913, 51.808],
                                  [35.72, 135.683],
                                  [-59.802, 36.195],
                                  [137.648, 34.037],
                                  [18.023, 71.884],
                                  [111.971, -2.058],
                                  [-143.524, -83.883],
                                  [49.744, -155.646],
                                  [8.052, -92],
                                ],
                                v: [
                                  [-116.321, -377.181],
                                  [-165.365, -167.988],
                                  [-247.834, 34.751],
                                  [-269.23, 160.616],
                                  [-45.976, 284.911],
                                  [114.746, 426.384],
                                  [291.578, 287.03],
                                  [38.361, -100.778],
                                  [322.135, -253.212],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.12, -51.759],
                                  [-35.71, -135.672],
                                  [59.796, -36.201],
                                  [-137.635, -34.038],
                                  [-17.985, -71.832],
                                  [-112.277, 2.126],
                                  [143.508, 83.879],
                                  [-49.793, 155.582],
                                  [-8.054, 92],
                                ],
                                o: [
                                  [-99.91, 51.802],
                                  [35.71, 135.672],
                                  [-59.796, 36.202],
                                  [137.635, 34.038],
                                  [18.024, 71.88],
                                  [111.97, -2.06],
                                  [-143.508, -83.879],
                                  [49.735, -155.633],
                                  [8.054, -92],
                                ],
                                v: [
                                  [-115.992, -377.546],
                                  [-165.867, -168.495],
                                  [-247.525, 34.674],
                                  [-270.159, 160.427],
                                  [-45.43, 285.097],
                                  [115.555, 426.744],
                                  [291.46, 286.901],
                                  [38.698, -101.63],
                                  [321.367, -253.506],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.124, -51.751],
                                  [-35.7, -135.66],
                                  [59.788, -36.208],
                                  [-137.623, -34.04],
                                  [-17.984, -71.826],
                                  [-112.286, 2.131],
                                  [143.49, 83.875],
                                  [-49.785, 155.568],
                                  [-8.056, 92],
                                ],
                                o: [
                                  [-99.907, 51.795],
                                  [35.7, 135.66],
                                  [-59.788, 36.208],
                                  [137.623, 34.04],
                                  [18.025, 71.876],
                                  [111.969, -2.062],
                                  [-143.49, -83.875],
                                  [49.726, -155.62],
                                  [8.056, -92],
                                ],
                                v: [
                                  [-115.662, -377.91],
                                  [-166.307, -169.064],
                                  [-247.217, 34.659],
                                  [-271.15, 160.175],
                                  [-44.761, 285.283],
                                  [116.301, 427.042],
                                  [291.342, 286.834],
                                  [38.974, -102.544],
                                  [320.537, -253.737],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.129, -51.742],
                                  [-35.689, -135.648],
                                  [59.781, -36.216],
                                  [-137.61, -34.041],
                                  [-17.984, -71.82],
                                  [-112.296, 2.135],
                                  [143.473, 83.87],
                                  [-49.778, 155.552],
                                  [-8.058, 92],
                                ],
                                o: [
                                  [-99.903, 51.787],
                                  [35.689, 135.648],
                                  [-59.781, 36.216],
                                  [137.61, 34.041],
                                  [18.026, 71.871],
                                  [111.968, -2.064],
                                  [-143.473, -83.87],
                                  [49.717, -155.607],
                                  [8.058, -92],
                                ],
                                v: [
                                  [-115.395, -378.274],
                                  [-166.747, -169.633],
                                  [-246.846, 34.644],
                                  [-272.14, 159.923],
                                  [-44.092, 285.468],
                                  [117.048, 427.402],
                                  [291.224, 286.768],
                                  [39.251, -103.456],
                                  [319.706, -254.03],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.133, -51.733],
                                  [-35.679, -135.636],
                                  [59.773, -36.223],
                                  [-137.596, -34.042],
                                  [-17.983, -71.813],
                                  [-112.307, 2.14],
                                  [143.454, 83.866],
                                  [-49.77, 155.537],
                                  [-8.06, 92],
                                ],
                                o: [
                                  [-99.9, 51.78],
                                  [35.678, 135.636],
                                  [-59.773, 36.223],
                                  [137.596, 34.042],
                                  [18.027, 71.867],
                                  [111.967, -2.067],
                                  [-143.454, -83.866],
                                  [49.707, -155.594],
                                  [8.06, -92],
                                ],
                                v: [
                                  [-115.189, -378.7],
                                  [-167.187, -170.203],
                                  [-246.538, 34.69],
                                  [-273.067, 159.672],
                                  [-43.299, 285.715],
                                  [117.731, 427.636],
                                  [291.107, 286.639],
                                  [39.529, -104.368],
                                  [318.875, -254.259],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.138, -51.724],
                                  [-35.667, -135.623],
                                  [59.766, -36.231],
                                  [-137.582, -34.044],
                                  [-17.983, -71.807],
                                  [-112.317, 2.145],
                                  [143.436, 83.861],
                                  [-49.762, 155.521],
                                  [-8.062, 92],
                                ],
                                o: [
                                  [-99.897, 51.772],
                                  [35.667, 135.623],
                                  [-59.766, 36.231],
                                  [137.582, 34.044],
                                  [18.028, 71.862],
                                  [111.966, -2.069],
                                  [-143.436, -83.861],
                                  [49.697, -155.579],
                                  [8.062, -92],
                                ],
                                v: [
                                  [-115.045, -379.188],
                                  [-167.627, -170.772],
                                  [-246.168, 34.798],
                                  [-274.057, 159.359],
                                  [-42.507, 286.025],
                                  [118.414, 427.932],
                                  [290.99, 286.573],
                                  [39.683, -105.342],
                                  [318.105, -254.55],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.143, -51.715],
                                  [-35.656, -135.61],
                                  [59.758, -36.239],
                                  [-137.568, -34.045],
                                  [-17.982, -71.8],
                                  [-112.328, 2.15],
                                  [143.416, 83.856],
                                  [-49.754, 155.504],
                                  [-8.064, 92],
                                ],
                                o: [
                                  [-99.893, 51.765],
                                  [35.656, 135.61],
                                  [-59.758, 36.239],
                                  [137.568, 34.045],
                                  [18.029, 71.857],
                                  [111.964, -2.071],
                                  [-143.416, -83.856],
                                  [49.686, -155.565],
                                  [8.064, -92],
                                ],
                                v: [
                                  [-114.9, -379.676],
                                  [-168.067, -171.342],
                                  [-245.798, 34.968],
                                  [-274.983, 159.046],
                                  [-41.715, 286.272],
                                  [119.097, 428.166],
                                  [290.936, 286.444],
                                  [39.9, -106.315],
                                  [317.273, -254.778],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.148, -51.705],
                                  [-35.644, -135.597],
                                  [59.749, -36.247],
                                  [-137.553, -34.047],
                                  [-17.982, -71.793],
                                  [-112.339, 2.155],
                                  [143.396, 83.852],
                                  [-49.745, 155.487],
                                  [-8.066, 92],
                                ],
                                o: [
                                  [-99.889, 51.757],
                                  [35.644, 135.597],
                                  [-59.749, 36.247],
                                  [137.553, 34.047],
                                  [18.03, 71.852],
                                  [111.963, -2.074],
                                  [-143.396, -83.852],
                                  [49.675, -155.55],
                                  [8.066, -92],
                                ],
                                v: [
                                  [-114.817, -380.225],
                                  [-168.446, -171.974],
                                  [-245.428, 35.137],
                                  [-275.909, 158.732],
                                  [-40.863, 286.643],
                                  [119.78, 428.398],
                                  [290.82, 286.377],
                                  [40.055, -107.349],
                                  [316.441, -255.005],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.153, -51.695],
                                  [-35.632, -135.583],
                                  [59.741, -36.256],
                                  [-137.537, -34.049],
                                  [-17.981, -71.786],
                                  [-112.351, 2.16],
                                  [143.375, 83.846],
                                  [-49.737, 155.47],
                                  [-8.069, 92],
                                ],
                                o: [
                                  [-99.886, 51.748],
                                  [35.632, 135.583],
                                  [-59.741, 36.256],
                                  [137.537, 34.049],
                                  [18.031, 71.847],
                                  [111.962, -2.076],
                                  [-143.375, -83.846],
                                  [49.664, -155.534],
                                  [8.069, -92],
                                ],
                                v: [
                                  [-114.796, -380.774],
                                  [-168.824, -172.544],
                                  [-245.121, 35.306],
                                  [-276.835, 158.357],
                                  [-39.949, 287.014],
                                  [120.399, 428.63],
                                  [290.766, 286.249],
                                  [40.15, -108.382],
                                  [315.608, -255.294],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.158, -51.684],
                                  [-35.619, -135.569],
                                  [59.732, -36.264],
                                  [-137.521, -34.05],
                                  [-17.98, -71.779],
                                  [-112.363, 2.166],
                                  [143.354, 83.841],
                                  [-49.728, 155.451],
                                  [-8.071, 92],
                                ],
                                o: [
                                  [-99.882, 51.74],
                                  [35.619, 135.569],
                                  [-59.732, 36.264],
                                  [137.521, 34.05],
                                  [18.032, 71.842],
                                  [111.961, -2.079],
                                  [-143.354, -83.841],
                                  [49.653, -155.518],
                                  [8.071, -92],
                                ],
                                v: [
                                  [-114.774, -381.385],
                                  [-169.202, -173.176],
                                  [-244.752, 35.473],
                                  [-277.76, 157.982],
                                  [-39.035, 287.385],
                                  [120.957, 428.799],
                                  [290.776, 286.182],
                                  [40.245, -109.414],
                                  [314.775, -255.519],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.163, -51.673],
                                  [-35.606, -135.554],
                                  [59.722, -36.273],
                                  [-137.505, -34.052],
                                  [-17.98, -71.771],
                                  [-112.376, 2.171],
                                  [143.332, 83.836],
                                  [-49.718, 155.433],
                                  [-8.073, 92],
                                ],
                                o: [
                                  [-99.878, 51.731],
                                  [35.606, 135.554],
                                  [-59.722, 36.273],
                                  [137.505, 34.052],
                                  [18.033, 71.837],
                                  [111.959, -2.082],
                                  [-143.332, -83.836],
                                  [49.641, -155.502],
                                  [8.073, -92],
                                ],
                                v: [
                                  [-114.751, -381.996],
                                  [-169.519, -173.747],
                                  [-244.444, 35.703],
                                  [-278.685, 157.545],
                                  [-38.061, 287.817],
                                  [121.576, 428.968],
                                  [290.723, 286.053],
                                  [40.278, -110.508],
                                  [314.004, -255.744],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.169, -51.662],
                                  [-35.593, -135.539],
                                  [59.713, -36.283],
                                  [-137.488, -34.054],
                                  [-17.979, -71.764],
                                  [-112.388, 2.177],
                                  [143.309, 83.83],
                                  [-49.709, 155.413],
                                  [-8.076, 92],
                                ],
                                o: [
                                  [-99.873, 51.721],
                                  [35.593, 135.539],
                                  [-59.713, 36.283],
                                  [137.488, 34.054],
                                  [18.034, 71.831],
                                  [111.958, -2.084],
                                  [-143.309, -83.83],
                                  [49.629, -155.485],
                                  [8.076, -92],
                                ],
                                v: [
                                  [-114.728, -382.668],
                                  [-169.835, -174.38],
                                  [-244.075, 35.931],
                                  [-279.547, 157.108],
                                  [-37.088, 288.249],
                                  [122.132, 429.136],
                                  [290.671, 285.924],
                                  [40.313, -111.6],
                                  [313.169, -255.967],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.175, -51.651],
                                  [-35.579, -135.523],
                                  [59.703, -36.292],
                                  [-137.471, -34.056],
                                  [-17.978, -71.756],
                                  [-112.402, 2.183],
                                  [143.285, 83.824],
                                  [-49.699, 155.393],
                                  [-8.079, 92],
                                ],
                                o: [
                                  [-99.869, 51.712],
                                  [35.579, 135.523],
                                  [-59.703, 36.292],
                                  [137.471, 34.056],
                                  [18.035, 71.825],
                                  [111.956, -2.087],
                                  [-143.285, -83.824],
                                  [49.616, -155.467],
                                  [8.079, -92],
                                ],
                                v: [
                                  [-114.705, -383.339],
                                  [-170.152, -174.951],
                                  [-243.768, 36.159],
                                  [-280.408, 156.672],
                                  [-36.115, 288.681],
                                  [122.626, 429.241],
                                  [290.681, 285.858],
                                  [40.287, -112.691],
                                  [312.335, -256.252],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.181, -51.639],
                                  [-35.564, -135.507],
                                  [59.693, -36.302],
                                  [-137.453, -34.058],
                                  [-17.977, -71.747],
                                  [-112.415, 2.19],
                                  [143.261, 83.818],
                                  [-49.689, 155.373],
                                  [-8.081, 92],
                                ],
                                o: [
                                  [-99.865, 51.702],
                                  [35.564, 135.507],
                                  [-59.693, 36.302],
                                  [137.453, 34.058],
                                  [18.036, 71.82],
                                  [111.955, -2.09],
                                  [-143.261, -83.818],
                                  [49.603, -155.449],
                                  [8.081, -92],
                                ],
                                v: [
                                  [-114.681, -384.071],
                                  [-170.469, -175.584],
                                  [-243.462, 36.386],
                                  [-281.206, 156.236],
                                  [-35.082, 289.175],
                                  [123.181, 429.345],
                                  [290.692, 285.729],
                                  [40.199, -113.844],
                                  [311.5, -256.473],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.187, -51.627],
                                  [-35.55, -135.49],
                                  [59.683, -36.313],
                                  [-137.434, -34.06],
                                  [-17.977, -71.739],
                                  [-112.429, 2.196],
                                  [143.236, 83.812],
                                  [-49.678, 155.351],
                                  [-8.084, 92],
                                ],
                                o: [
                                  [-99.86, 51.692],
                                  [35.55, 135.49],
                                  [-59.683, 36.313],
                                  [137.434, 34.06],
                                  [18.037, 71.813],
                                  [111.953, -2.093],
                                  [-143.236, -83.812],
                                  [49.589, -155.431],
                                  [8.084, -92],
                                ],
                                v: [
                                  [-114.719, -384.865],
                                  [-170.786, -176.155],
                                  [-243.217, 36.674],
                                  [-282.005, 155.738],
                                  [-34.05, 289.668],
                                  [123.675, 429.449],
                                  [290.766, 285.6],
                                  [40.175, -114.994],
                                  [310.665, -256.694],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.193, -51.614],
                                  [-35.535, -135.473],
                                  [59.672, -36.323],
                                  [-137.415, -34.061],
                                  [-17.976, -71.73],
                                  [-112.444, 2.203],
                                  [143.21, 83.806],
                                  [-49.667, 155.329],
                                  [-8.087, 92],
                                ],
                                o: [
                                  [-99.855, 51.682],
                                  [35.535, 135.473],
                                  [-59.672, 36.323],
                                  [137.415, 34.062],
                                  [18.039, 71.807],
                                  [111.952, -2.096],
                                  [-143.21, -83.806],
                                  [49.576, -155.412],
                                  [8.087, -92],
                                ],
                                v: [
                                  [-114.757, -385.658],
                                  [-171.041, -176.789],
                                  [-242.911, 36.962],
                                  [-282.802, 155.24],
                                  [-32.957, 290.161],
                                  [124.105, 429.552],
                                  [290.777, 285.472],
                                  [40.028, -116.144],
                                  [309.891, -256.851],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.199, -51.601],
                                  [-35.519, -135.455],
                                  [59.661, -36.334],
                                  [-137.395, -34.064],
                                  [-17.975, -71.721],
                                  [-112.459, 2.209],
                                  [143.183, 83.799],
                                  [-49.656, 155.307],
                                  [-8.09, 92],
                                ],
                                o: [
                                  [-99.85, 51.671],
                                  [35.519, 135.455],
                                  [-59.661, 36.334],
                                  [137.395, 34.064],
                                  [18.04, 71.801],
                                  [111.95, -2.1],
                                  [-143.183, -83.799],
                                  [49.561, -155.392],
                                  [8.09, -92],
                                ],
                                v: [
                                  [-114.794, -386.45],
                                  [-171.296, -177.361],
                                  [-242.667, 37.248],
                                  [-283.537, 154.743],
                                  [-31.865, 290.715],
                                  [124.598, 429.592],
                                  [290.852, 285.405],
                                  [39.882, -117.292],
                                  [309.055, -257.069],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.206, -51.588],
                                  [-35.503, -135.437],
                                  [59.65, -36.345],
                                  [-137.375, -34.066],
                                  [-17.974, -71.711],
                                  [-112.474, 2.216],
                                  [143.156, 83.793],
                                  [-49.644, 155.284],
                                  [-8.093, 92],
                                ],
                                o: [
                                  [-99.845, 51.66],
                                  [35.503, 135.437],
                                  [-59.65, 36.345],
                                  [137.375, 34.066],
                                  [18.041, 71.794],
                                  [111.948, -2.103],
                                  [-143.156, -83.793],
                                  [49.546, -155.371],
                                  [8.093, -92],
                                ],
                                v: [
                                  [-114.83, -387.304],
                                  [-171.489, -177.933],
                                  [-242.423, 37.534],
                                  [-284.271, 154.184],
                                  [-30.774, 291.206],
                                  [125.028, 429.631],
                                  [290.927, 285.276],
                                  [39.675, -118.501],
                                  [308.219, -257.287],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.213, -51.574],
                                  [-35.486, -135.418],
                                  [59.638, -36.357],
                                  [-137.354, -34.068],
                                  [-17.973, -71.702],
                                  [-112.49, 2.224],
                                  [143.128, 83.786],
                                  [-49.632, 155.26],
                                  [-8.096, 92],
                                ],
                                o: [
                                  [-99.84, 51.648],
                                  [35.486, 135.418],
                                  [-59.638, 36.357],
                                  [137.354, 34.068],
                                  [18.043, 71.787],
                                  [111.947, -2.107],
                                  [-143.128, -83.786],
                                  [49.531, -155.35],
                                  [8.096, -92],
                                ],
                                v: [
                                  [-114.928, -388.218],
                                  [-171.744, -178.505],
                                  [-242.178, 37.881],
                                  [-284.942, 153.687],
                                  [-29.685, 291.759],
                                  [125.458, 429.669],
                                  [291.064, 285.147],
                                  [39.469, -119.708],
                                  [307.444, -257.503],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.22, -51.56],
                                  [-35.469, -135.399],
                                  [59.626, -36.369],
                                  [-137.332, -34.07],
                                  [-17.972, -71.692],
                                  [-112.507, 2.231],
                                  [143.099, 83.778],
                                  [-49.62, 155.235],
                                  [-8.099, 92],
                                ],
                                o: [
                                  [-99.835, 51.637],
                                  [35.469, 135.399],
                                  [-59.626, 36.369],
                                  [137.332, 34.07],
                                  [18.044, 71.78],
                                  [111.945, -2.11],
                                  [-143.099, -83.778],
                                  [49.516, -155.328],
                                  [8.099, -92],
                                ],
                                v: [
                                  [-115.026, -389.132],
                                  [-171.937, -179.078],
                                  [-241.997, 38.227],
                                  [-285.613, 153.129],
                                  [-28.535, 292.312],
                                  [125.825, 429.707],
                                  [291.139, 284.957],
                                  [39.265, -120.914],
                                  [306.607, -257.655],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.228, -51.545],
                                  [-35.451, -135.378],
                                  [59.613, -36.381],
                                  [-137.31, -34.073],
                                  [-17.972, -71.681],
                                  [-112.523, 2.239],
                                  [143.068, 83.771],
                                  [-49.608, 155.209],
                                  [-8.102, 92],
                                ],
                                o: [
                                  [-99.829, 51.625],
                                  [35.451, 135.379],
                                  [-59.613, 36.381],
                                  [137.31, 34.073],
                                  [18.046, 71.772],
                                  [111.943, -2.114],
                                  [-143.068, -83.771],
                                  [49.499, -155.306],
                                  [8.102, -92],
                                ],
                                v: [
                                  [-115.123, -390.044],
                                  [-172.069, -179.651],
                                  [-241.815, 38.571],
                                  [-286.221, 152.572],
                                  [-27.387, 292.864],
                                  [126.192, 429.682],
                                  [291.278, 284.828],
                                  [38.938, -122.118],
                                  [305.831, -257.869],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.235, -51.53],
                                  [-35.433, -135.358],
                                  [59.6, -36.394],
                                  [-137.287, -34.075],
                                  [-17.971, -71.671],
                                  [-112.541, 2.247],
                                  [143.037, 83.763],
                                  [-49.594, 155.183],
                                  [-8.106, 92],
                                ],
                                o: [
                                  [-99.824, 51.612],
                                  [35.433, 135.358],
                                  [-59.6, 36.394],
                                  [137.287, 34.075],
                                  [18.047, 71.765],
                                  [111.941, -2.118],
                                  [-143.037, -83.763],
                                  [49.483, -155.283],
                                  [8.106, -92],
                                ],
                                v: [
                                  [-115.282, -390.955],
                                  [-172.263, -180.162],
                                  [-241.634, 38.915],
                                  [-286.829, 151.953],
                                  [-26.302, 293.415],
                                  [126.559, 429.656],
                                  [291.479, 284.699],
                                  [38.674, -123.321],
                                  [305.054, -258.019],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.243, -51.514],
                                  [-35.414, -135.336],
                                  [59.587, -36.407],
                                  [-137.264, -34.077],
                                  [-17.97, -71.66],
                                  [-112.559, 2.255],
                                  [143.005, 83.756],
                                  [-49.581, 155.156],
                                  [-8.109, 92],
                                ],
                                o: [
                                  [-99.818, 51.599],
                                  [35.414, 135.336],
                                  [-59.587, 36.407],
                                  [137.264, 34.077],
                                  [18.049, 71.757],
                                  [111.939, -2.121],
                                  [-143.005, -83.756],
                                  [49.466, -155.259],
                                  [8.109, -92],
                                ],
                                v: [
                                  [-115.441, -391.927],
                                  [-172.395, -180.735],
                                  [-241.514, 39.32],
                                  [-287.374, 151.396],
                                  [-25.157, 294.027],
                                  [126.863, 429.629],
                                  [291.618, 284.57],
                                  [38.288, -124.584],
                                  [304.215, -258.169],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.251, -51.498],
                                  [-35.394, -135.314],
                                  [59.573, -36.42],
                                  [-137.239, -34.08],
                                  [-17.969, -71.649],
                                  [-112.577, 2.264],
                                  [142.973, 83.747],
                                  [-49.567, 155.128],
                                  [-8.113, 92],
                                ],
                                o: [
                                  [-99.812, 51.586],
                                  [35.394, 135.314],
                                  [-59.573, 36.42],
                                  [137.239, 34.08],
                                  [18.05, 71.749],
                                  [111.937, -2.125],
                                  [-142.972, -83.747],
                                  [49.448, -155.234],
                                  [8.113, -92],
                                ],
                                v: [
                                  [-115.598, -392.897],
                                  [-172.465, -181.247],
                                  [-241.395, 39.662],
                                  [-287.919, 150.84],
                                  [-24.013, 294.577],
                                  [127.229, 429.539],
                                  [291.819, 284.38],
                                  [37.965, -125.783],
                                  [303.438, -258.316],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.259, -51.481],
                                  [-35.374, -135.292],
                                  [59.559, -36.434],
                                  [-137.214, -34.083],
                                  [-17.968, -71.637],
                                  [-112.596, 2.272],
                                  [142.938, 83.739],
                                  [-49.553, 155.099],
                                  [-8.117, 92],
                                ],
                                o: [
                                  [-99.806, 51.572],
                                  [35.374, 135.292],
                                  [-59.559, 36.434],
                                  [137.214, 34.083],
                                  [18.052, 71.741],
                                  [111.935, -2.13],
                                  [-142.939, -83.739],
                                  [49.43, -155.209],
                                  [8.117, -92],
                                ],
                                v: [
                                  [-115.818, -393.929],
                                  [-172.598, -181.759],
                                  [-241.276, 40.064],
                                  [-288.401, 150.222],
                                  [-22.809, 295.188],
                                  [127.534, 429.448],
                                  [292.022, 284.25],
                                  [37.52, -127.042],
                                  [302.66, -258.463],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.268, -51.464],
                                  [-35.354, -135.268],
                                  [59.545, -36.449],
                                  [-137.188, -34.085],
                                  [-17.967, -71.625],
                                  [-112.616, 2.281],
                                  [142.904, 83.73],
                                  [-49.538, 155.069],
                                  [-8.121, 92],
                                ],
                                o: [
                                  [-99.799, 51.558],
                                  [35.354, 135.268],
                                  [-59.545, 36.449],
                                  [137.188, 34.085],
                                  [18.054, 71.732],
                                  [111.933, -2.134],
                                  [-142.904, -83.73],
                                  [49.411, -155.183],
                                  [8.121, -92],
                                ],
                                v: [
                                  [-116.036, -394.896],
                                  [-172.668, -182.21],
                                  [-241.219, 40.466],
                                  [-288.82, 149.606],
                                  [-21.669, 295.736],
                                  [127.837, 429.357],
                                  [292.286, 284.06],
                                  [37.077, -128.238],
                                  [301.943, -258.608],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.277, -51.447],
                                  [-35.333, -135.244],
                                  [59.53, -36.463],
                                  [-137.161, -34.088],
                                  [-17.965, -71.613],
                                  [-112.636, 2.291],
                                  [142.867, 83.722],
                                  [-49.523, 155.039],
                                  [-8.125, 92],
                                ],
                                o: [
                                  [-99.793, 51.544],
                                  [35.333, 135.244],
                                  [-59.53, 36.463],
                                  [137.161, 34.088],
                                  [18.055, 71.723],
                                  [111.931, -2.138],
                                  [-142.867, -83.722],
                                  [49.391, -155.156],
                                  [8.125, -92],
                                ],
                                v: [
                                  [-116.317, -395.924],
                                  [-172.74, -182.661],
                                  [-241.162, 40.928],
                                  [-289.239, 148.99],
                                  [-20.531, 296.345],
                                  [128.078, 429.265],
                                  [292.551, 283.869],
                                  [36.635, -129.493],
                                  [301.164, -258.752],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.286, -51.429],
                                  [-35.311, -135.22],
                                  [59.514, -36.479],
                                  [-137.134, -34.091],
                                  [-17.964, -71.6],
                                  [-112.657, 2.3],
                                  [142.83, 83.713],
                                  [-49.507, 155.007],
                                  [-8.129, 92],
                                ],
                                o: [
                                  [-99.786, 51.529],
                                  [35.311, 135.22],
                                  [-59.514, 36.479],
                                  [137.134, 34.091],
                                  [18.057, 71.714],
                                  [111.929, -2.143],
                                  [-142.83, -83.713],
                                  [49.371, -155.129],
                                  [8.129, -92],
                                ],
                                v: [
                                  [-116.596, -396.951],
                                  [-172.749, -183.113],
                                  [-241.105, 41.327],
                                  [-289.595, 148.374],
                                  [-19.457, 296.891],
                                  [128.32, 429.172],
                                  [292.817, 283.678],
                                  [36.134, -130.746],
                                  [300.446, -258.895],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.295, -51.41],
                                  [-35.288, -135.194],
                                  [59.498, -36.494],
                                  [-137.106, -34.094],
                                  [-17.963, -71.587],
                                  [-112.679, 2.31],
                                  [142.792, 83.703],
                                  [-49.491, 154.975],
                                  [-8.133, 92],
                                ],
                                o: [
                                  [-99.779, 51.513],
                                  [35.288, 135.194],
                                  [-59.498, 36.494],
                                  [137.106, 34.094],
                                  [18.059, 71.705],
                                  [111.926, -2.148],
                                  [-142.792, -83.703],
                                  [49.351, -155.1],
                                  [8.133, -92],
                                ],
                                v: [
                                  [-116.938, -397.976],
                                  [-172.758, -183.565],
                                  [-241.11, 41.725],
                                  [-289.889, 147.822],
                                  [-18.322, 297.499],
                                  [128.561, 429.016],
                                  [293.082, 283.487],
                                  [35.571, -131.997],
                                  [299.666, -258.974],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.305, -51.391],
                                  [-35.265, -135.168],
                                  [59.482, -36.51],
                                  [-137.076, -34.097],
                                  [-17.962, -71.573],
                                  [-112.701, 2.32],
                                  [142.753, 83.693],
                                  [-49.474, 154.941],
                                  [-8.137, 92],
                                ],
                                o: [
                                  [-99.771, 51.497],
                                  [35.265, 135.168],
                                  [-59.482, 36.51],
                                  [137.076, 34.097],
                                  [18.061, 71.695],
                                  [111.924, -2.152],
                                  [-142.753, -83.693],
                                  [49.33, -155.071],
                                  [8.137, -92],
                                ],
                                v: [
                                  [-117.278, -398.999],
                                  [-172.768, -183.955],
                                  [-241.177, 42.184],
                                  [-290.182, 147.208],
                                  [-17.19, 298.043],
                                  [128.801, 428.86],
                                  [293.411, 283.296],
                                  [35.012, -133.246],
                                  [298.947, -259.052],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.315, -51.371],
                                  [-35.241, -135.141],
                                  [59.465, -36.527],
                                  [-137.046, -34.1],
                                  [-17.961, -71.56],
                                  [-112.724, 2.33],
                                  [142.712, 83.683],
                                  [-49.457, 154.907],
                                  [-8.142, 92],
                                ],
                                o: [
                                  [-99.764, 51.481],
                                  [35.241, 135.141],
                                  [-59.465, 36.527],
                                  [137.046, 34.1],
                                  [18.063, 71.685],
                                  [111.921, -2.157],
                                  [-142.712, -83.683],
                                  [49.308, -155.04],
                                  [8.142, -92],
                                ],
                                v: [
                                  [-117.617, -400.083],
                                  [-172.717, -184.346],
                                  [-241.182, 42.641],
                                  [-290.413, 146.532],
                                  [-16.122, 298.647],
                                  [129.042, 428.703],
                                  [293.739, 283.105],
                                  [34.454, -134.43],
                                  [298.289, -259.19],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.325, -51.35],
                                  [-35.216, -135.113],
                                  [59.448, -36.544],
                                  [-137.015, -34.104],
                                  [-17.959, -71.545],
                                  [-112.747, 2.341],
                                  [142.67, 83.673],
                                  [-49.44, 154.871],
                                  [-8.146, 92],
                                ],
                                o: [
                                  [-99.756, 51.464],
                                  [35.216, 135.113],
                                  [-59.448, 36.544],
                                  [137.015, 34.104],
                                  [18.065, 71.675],
                                  [111.919, -2.162],
                                  [-142.67, -83.673],
                                  [49.285, -155.009],
                                  [8.146, -92],
                                ],
                                v: [
                                  [-118.019, -401.102],
                                  [-172.665, -184.738],
                                  [-241.311, 43.035],
                                  [-290.581, 145.92],
                                  [-15.055, 299.19],
                                  [129.22, 428.545],
                                  [294.13, 282.914],
                                  [33.836, -135.674],
                                  [297.568, -259.265],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.335, -51.329],
                                  [-35.191, -135.084],
                                  [59.43, -36.562],
                                  [-136.983, -34.107],
                                  [-17.958, -71.53],
                                  [-112.771, 2.352],
                                  [142.627, 83.663],
                                  [-49.421, 154.834],
                                  [-8.151, 92],
                                ],
                                o: [
                                  [-99.748, 51.447],
                                  [35.191, 135.084],
                                  [-59.43, 36.562],
                                  [136.983, 34.107],
                                  [18.067, 71.665],
                                  [111.916, -2.168],
                                  [-142.627, -83.662],
                                  [49.262, -154.977],
                                  [8.151, -92],
                                ],
                                v: [
                                  [-118.481, -402.12],
                                  [-172.614, -185.068],
                                  [-241.44, 43.49],
                                  [-290.687, 145.309],
                                  [-13.991, 299.73],
                                  [129.398, 428.386],
                                  [294.521, 282.661],
                                  [33.159, -136.916],
                                  [296.909, -259.338],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.346, -51.308],
                                  [-35.165, -135.054],
                                  [59.411, -36.58],
                                  [-136.95, -34.11],
                                  [-17.957, -71.515],
                                  [-112.796, 2.364],
                                  [142.583, 83.652],
                                  [-49.403, 154.797],
                                  [-8.156, 92],
                                ],
                                o: [
                                  [-99.74, 51.429],
                                  [35.165, 135.054],
                                  [-59.411, 36.58],
                                  [136.95, 34.11],
                                  [18.069, 71.654],
                                  [111.913, -2.173],
                                  [-142.583, -83.652],
                                  [49.238, -154.944],
                                  [8.156, -92],
                                ],
                                v: [
                                  [-118.88, -403.197],
                                  [-172.563, -185.338],
                                  [-241.568, 43.943],
                                  [-290.793, 144.699],
                                  [-12.992, 300.27],
                                  [129.576, 428.165],
                                  [294.913, 282.408],
                                  [32.483, -138.155],
                                  [296.249, -259.348],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.357, -51.285],
                                  [-35.138, -135.024],
                                  [59.392, -36.599],
                                  [-136.917, -34.114],
                                  [-17.955, -71.5],
                                  [-112.822, 2.375],
                                  [142.537, 83.64],
                                  [-49.383, 154.758],
                                  [-8.161, 92],
                                ],
                                o: [
                                  [-99.732, 51.41],
                                  [35.138, 135.024],
                                  [-59.392, 36.599],
                                  [136.917, 34.114],
                                  [18.071, 71.643],
                                  [111.911, -2.179],
                                  [-142.537, -83.64],
                                  [49.214, -154.91],
                                  [8.161, -92],
                                ],
                                v: [
                                  [-119.403, -404.211],
                                  [-172.451, -185.669],
                                  [-241.759, 44.333],
                                  [-290.836, 144.089],
                                  [-11.994, 300.809],
                                  [129.754, 428.005],
                                  [295.366, 282.217],
                                  [31.81, -139.329],
                                  [295.65, -259.418],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.369, -51.262],
                                  [-35.11, -134.992],
                                  [59.373, -36.618],
                                  [-136.882, -34.118],
                                  [-17.954, -71.484],
                                  [-112.849, 2.387],
                                  [142.49, 83.629],
                                  [-49.364, 154.718],
                                  [-8.166, 92],
                                ],
                                o: [
                                  [-99.723, 51.391],
                                  [35.11, 134.992],
                                  [-59.373, 36.618],
                                  [136.882, 34.118],
                                  [18.074, 71.631],
                                  [111.908, -2.184],
                                  [-142.49, -83.629],
                                  [49.188, -154.875],
                                  [8.166, -92],
                                ],
                                v: [
                                  [-119.924, -405.222],
                                  [-172.277, -185.94],
                                  [-241.95, 44.721],
                                  [-290.817, 143.48],
                                  [-10.999, 301.346],
                                  [129.932, 427.783],
                                  [295.821, 281.964],
                                  [31.078, -140.562],
                                  [295.05, -259.488],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.381, -51.238],
                                  [-35.081, -134.96],
                                  [59.353, -36.638],
                                  [-136.846, -34.121],
                                  [-17.952, -71.467],
                                  [-112.876, 2.4],
                                  [142.441, 83.617],
                                  [-49.343, 154.677],
                                  [-8.171, 92],
                                ],
                                o: [
                                  [-99.714, 51.372],
                                  [35.081, 134.96],
                                  [-59.353, 36.638],
                                  [136.846, 34.121],
                                  [18.076, 71.619],
                                  [111.905, -2.19],
                                  [-142.441, -83.617],
                                  [49.162, -154.839],
                                  [8.171, -92],
                                ],
                                v: [
                                  [-120.445, -406.232],
                                  [-172.165, -186.15],
                                  [-242.202, 45.17],
                                  [-290.735, 142.873],
                                  [-10.069, 301.882],
                                  [130.047, 427.56],
                                  [296.275, 281.649],
                                  [30.287, -141.731],
                                  [294.449, -259.493],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.393, -51.214],
                                  [-35.052, -134.927],
                                  [59.332, -36.658],
                                  [-136.809, -34.125],
                                  [-17.951, -71.45],
                                  [-112.904, 2.413],
                                  [142.391, 83.605],
                                  [-49.322, 154.634],
                                  [-8.177, 92],
                                ],
                                o: [
                                  [-99.705, 51.352],
                                  [35.052, 134.927],
                                  [-59.332, 36.658],
                                  [136.809, 34.125],
                                  [18.079, 71.607],
                                  [111.902, -2.197],
                                  [-142.391, -83.605],
                                  [49.135, -154.801],
                                  [8.177, -92],
                                ],
                                v: [
                                  [-121.027, -407.238],
                                  [-171.992, -186.36],
                                  [-242.515, 45.556],
                                  [-290.654, 142.327],
                                  [-9.141, 302.417],
                                  [130.163, 427.337],
                                  [296.791, 281.396],
                                  [29.56, -142.897],
                                  [293.909, -259.497],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.406, -51.189],
                                  [-35.022, -134.892],
                                  [59.311, -36.679],
                                  [-136.771, -34.129],
                                  [-17.949, -71.432],
                                  [-112.933, 2.426],
                                  [142.34, 83.592],
                                  [-49.301, 154.591],
                                  [-8.182, 92],
                                ],
                                o: [
                                  [-99.696, 51.331],
                                  [35.022, 134.892],
                                  [-59.311, 36.679],
                                  [136.771, 34.129],
                                  [18.081, 71.594],
                                  [111.899, -2.203],
                                  [-142.34, -83.592],
                                  [49.108, -154.763],
                                  [8.182, -92],
                                ],
                                v: [
                                  [-121.607, -408.242],
                                  [-171.757, -186.571],
                                  [-242.829, 45.94],
                                  [-290.51, 141.722],
                                  [-8.277, 302.888],
                                  [130.341, 427.113],
                                  [297.307, 281.142],
                                  [28.712, -144.059],
                                  [293.368, -259.499],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.418, -51.163],
                                  [-34.991, -134.857],
                                  [59.289, -36.701],
                                  [-136.732, -34.133],
                                  [-17.948, -71.414],
                                  [-112.962, 2.439],
                                  [142.287, 83.579],
                                  [-49.278, 154.546],
                                  [-8.188, 92],
                                ],
                                o: [
                                  [-99.686, 51.31],
                                  [34.991, 134.857],
                                  [-59.289, 36.701],
                                  [136.732, 34.133],
                                  [18.084, 71.582],
                                  [111.895, -2.209],
                                  [-142.287, -83.579],
                                  [49.079, -154.724],
                                  [8.188, -92],
                                ],
                                v: [
                                  [-122.249, -409.245],
                                  [-171.585, -186.722],
                                  [-243.204, 46.261],
                                  [-290.304, 141.117],
                                  [-7.477, 303.358],
                                  [130.456, 426.827],
                                  [297.885, 280.828],
                                  [27.929, -145.218],
                                  [292.826, -259.5],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.432, -51.137],
                                  [-34.958, -134.821],
                                  [59.266, -36.723],
                                  [-136.691, -34.138],
                                  [-17.946, -71.396],
                                  [-112.993, 2.453],
                                  [142.233, 83.566],
                                  [-49.255, 154.5],
                                  [-8.194, 92],
                                ],
                                o: [
                                  [-99.676, 51.288],
                                  [34.958, 134.821],
                                  [-59.266, 36.723],
                                  [136.691, 34.138],
                                  [18.086, 71.568],
                                  [111.892, -2.216],
                                  [-142.233, -83.566],
                                  [49.05, -154.683],
                                  [8.194, -92],
                                ],
                                v: [
                                  [-122.889, -410.182],
                                  [-171.351, -186.874],
                                  [-243.579, 46.58],
                                  [-290.037, 140.575],
                                  [-6.681, 303.827],
                                  [130.571, 426.602],
                                  [298.464, 280.512],
                                  [27.087, -146.374],
                                  [292.282, -259.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.445, -51.109],
                                  [-34.925, -134.783],
                                  [59.243, -36.746],
                                  [-136.65, -34.142],
                                  [-17.944, -71.376],
                                  [-113.024, 2.468],
                                  [142.177, 83.552],
                                  [-49.232, 154.452],
                                  [-8.2, 92],
                                ],
                                o: [
                                  [-99.666, 51.265],
                                  [34.925, 134.783],
                                  [-59.243, 36.746],
                                  [136.65, 34.142],
                                  [18.089, 71.555],
                                  [111.889, -2.223],
                                  [-142.176, -83.552],
                                  [49.02, -154.641],
                                  [8.2, -92],
                                ],
                                v: [
                                  [-123.59, -411.117],
                                  [-171.117, -186.964],
                                  [-244.015, 46.898],
                                  [-289.707, 139.973],
                                  [-5.949, 304.294],
                                  [130.687, 426.315],
                                  [299.042, 280.197],
                                  [26.186, -147.526],
                                  [291.738, -259.496],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.46, -51.081],
                                  [-34.891, -134.745],
                                  [59.219, -36.77],
                                  [-136.607, -34.146],
                                  [-17.943, -71.357],
                                  [-113.057, 2.482],
                                  [142.119, 83.538],
                                  [-49.207, 154.403],
                                  [-8.207, 92],
                                ],
                                o: [
                                  [-99.655, 51.242],
                                  [34.891, 134.745],
                                  [-59.219, 36.77],
                                  [136.607, 34.146],
                                  [18.092, 71.541],
                                  [111.885, -2.23],
                                  [-142.119, -83.538],
                                  [48.989, -154.598],
                                  [8.207, -92],
                                ],
                                v: [
                                  [-124.29, -411.987],
                                  [-170.822, -187.056],
                                  [-244.512, 47.214],
                                  [-289.315, 139.433],
                                  [-5.282, 304.698],
                                  [130.802, 426.09],
                                  [299.62, 279.819],
                                  [25.289, -148.613],
                                  [291.191, -259.492],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.474, -51.052],
                                  [-34.856, -134.705],
                                  [59.194, -36.794],
                                  [-136.563, -34.151],
                                  [-17.941, -71.336],
                                  [-113.09, 2.498],
                                  [142.059, 83.523],
                                  [-49.182, 154.353],
                                  [-8.213, 92],
                                ],
                                o: [
                                  [-99.644, 51.218],
                                  [34.856, 134.705],
                                  [-59.194, 36.794],
                                  [136.563, 34.151],
                                  [18.095, 71.526],
                                  [111.881, -2.237],
                                  [-142.059, -83.523],
                                  [48.957, -154.554],
                                  [8.213, -92],
                                ],
                                v: [
                                  [-125.049, -412.916],
                                  [-170.527, -187.088],
                                  [-245.009, 47.467],
                                  [-288.923, 138.894],
                                  [-4.679, 305.1],
                                  [130.856, 425.803],
                                  [300.137, 279.38],
                                  [24.395, -149.696],
                                  [290.706, -259.485],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.489, -51.022],
                                  [-34.82, -134.664],
                                  [59.169, -36.819],
                                  [-136.518, -34.156],
                                  [-17.939, -71.315],
                                  [-113.125, 2.513],
                                  [141.998, 83.508],
                                  [-49.157, 154.301],
                                  [-8.22, 92],
                                ],
                                o: [
                                  [-99.633, 51.193],
                                  [34.82, 134.664],
                                  [-59.169, 36.819],
                                  [136.518, 34.156],
                                  [18.098, 71.511],
                                  [111.878, -2.245],
                                  [-141.998, -83.508],
                                  [48.924, -154.509],
                                  [8.22, -92],
                                ],
                                v: [
                                  [-125.808, -413.719],
                                  [-170.232, -187.121],
                                  [-245.568, 47.658],
                                  [-288.47, 138.356],
                                  [-4.079, 305.5],
                                  [130.972, 425.515],
                                  [300.653, 278.941],
                                  [23.505, -150.775],
                                  [290.158, -259.416],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.504, -50.991],
                                  [-34.783, -134.622],
                                  [59.143, -36.845],
                                  [-136.471, -34.161],
                                  [-17.937, -71.294],
                                  [-113.16, 2.53],
                                  [141.935, 83.492],
                                  [-49.13, 154.247],
                                  [-8.227, 92],
                                ],
                                o: [
                                  [-99.622, 51.168],
                                  [34.783, 134.623],
                                  [-59.143, 36.845],
                                  [136.471, 34.161],
                                  [18.101, 71.496],
                                  [111.874, -2.252],
                                  [-141.935, -83.492],
                                  [48.89, -154.462],
                                  [8.227, -92],
                                ],
                                v: [
                                  [-126.565, -414.581],
                                  [-169.876, -187.094],
                                  [-246.187, 47.846],
                                  [-287.955, 137.819],
                                  [-3.544, 305.899],
                                  [131.088, 425.288],
                                  [301.108, 278.44],
                                  [22.556, -151.849],
                                  [289.607, -259.406],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.52, -50.96],
                                  [-34.745, -134.579],
                                  [59.116, -36.871],
                                  [-136.423, -34.166],
                                  [-17.935, -71.272],
                                  [-113.196, 2.546],
                                  [141.871, 83.477],
                                  [-49.103, 154.192],
                                  [-8.234, 92],
                                ],
                                o: [
                                  [-99.61, 51.142],
                                  [34.745, 134.579],
                                  [-59.116, 36.871],
                                  [136.423, 34.166],
                                  [18.104, 71.48],
                                  [111.87, -2.26],
                                  [-141.871, -83.477],
                                  [48.856, -154.414],
                                  [8.234, -92],
                                ],
                                v: [
                                  [-127.382, -415.316],
                                  [-169.582, -187.068],
                                  [-246.806, 47.971],
                                  [-287.378, 137.345],
                                  [-3.012, 306.297],
                                  [131.142, 425],
                                  [301.501, 277.877],
                                  [21.55, -152.859],
                                  [289.056, -259.334],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.536, -50.927],
                                  [-34.706, -134.535],
                                  [59.088, -36.898],
                                  [-136.374, -34.171],
                                  [-17.933, -71.249],
                                  [-113.234, 2.563],
                                  [141.804, 83.46],
                                  [-49.075, 154.136],
                                  [-8.241, 92],
                                ],
                                o: [
                                  [-99.598, 51.115],
                                  [34.706, 134.535],
                                  [-59.088, 36.898],
                                  [136.374, 34.171],
                                  [18.107, 71.464],
                                  [111.866, -2.268],
                                  [-141.804, -83.46],
                                  [48.82, -154.364],
                                  [8.241, -92],
                                ],
                                v: [
                                  [-128.198, -416.049],
                                  [-169.226, -187.044],
                                  [-247.486, 48.034],
                                  [-286.74, 136.81],
                                  [-2.482, 306.693],
                                  [131.258, 424.712],
                                  [301.894, 277.315],
                                  [20.609, -153.925],
                                  [288.564, -259.26],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.553, -50.894],
                                  [-34.666, -134.489],
                                  [59.06, -36.927],
                                  [-136.323, -34.176],
                                  [-17.931, -71.226],
                                  [-113.272, 2.581],
                                  [141.736, 83.443],
                                  [-49.046, 154.078],
                                  [-8.249, 92],
                                ],
                                o: [
                                  [-99.585, 51.087],
                                  [34.666, 134.489],
                                  [-59.06, 36.927],
                                  [136.323, 34.176],
                                  [18.111, 71.447],
                                  [111.862, -2.277],
                                  [-141.736, -83.443],
                                  [48.783, -154.313],
                                  [8.249, -92],
                                ],
                                v: [
                                  [-129.073, -416.778],
                                  [-168.809, -186.897],
                                  [-248.164, 48.033],
                                  [-286.102, 136.338],
                                  [-2.018, 307.086],
                                  [131.312, 424.425],
                                  [302.286, 276.691],
                                  [19.611, -154.863],
                                  [288.009, -259.183],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.57, -50.859],
                                  [-34.624, -134.442],
                                  [59.03, -36.955],
                                  [-136.271, -34.182],
                                  [-17.929, -71.201],
                                  [-113.312, 2.599],
                                  [141.665, 83.426],
                                  [-49.016, 154.018],
                                  [-8.257, 92],
                                ],
                                o: [
                                  [-99.572, 51.059],
                                  [34.624, 134.442],
                                  [-59.03, 36.955],
                                  [136.271, 34.182],
                                  [18.114, 71.43],
                                  [111.857, -2.285],
                                  [-141.665, -83.426],
                                  [48.745, -154.26],
                                  [8.257, -92],
                                ],
                                v: [
                                  [-129.885, -417.442],
                                  [-168.454, -186.815],
                                  [-248.903, 48.031],
                                  [-285.403, 135.867],
                                  [-1.557, 307.479],
                                  [131.428, 424.137],
                                  [302.556, 276.004],
                                  [18.618, -155.858],
                                  [287.515, -259.105],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.588, -50.824],
                                  [-34.581, -134.394],
                                  [59, -36.985],
                                  [-136.217, -34.187],
                                  [-17.926, -71.177],
                                  [-113.353, 2.617],
                                  [141.593, 83.408],
                                  [-48.986, 153.956],
                                  [-8.265, 92],
                                ],
                                o: [
                                  [-99.559, 51.03],
                                  [34.581, 134.394],
                                  [-59, 36.985],
                                  [136.217, 34.187],
                                  [18.118, 71.412],
                                  [111.853, -2.294],
                                  [-141.592, -83.408],
                                  [48.706, -154.206],
                                  [8.265, -92],
                                ],
                                v: [
                                  [-130.695, -418.104],
                                  [-168.038, -186.673],
                                  [-249.642, 47.966],
                                  [-284.643, 135.458],
                                  [-1.161, 307.869],
                                  [131.484, 423.85],
                                  [302.886, 275.318],
                                  [17.629, -156.787],
                                  [286.956, -258.964],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.606, -50.787],
                                  [-34.537, -134.344],
                                  [58.969, -37.016],
                                  [-136.162, -34.193],
                                  [-17.924, -71.151],
                                  [-113.395, 2.637],
                                  [141.518, 83.39],
                                  [-48.954, 153.893],
                                  [-8.273, 92],
                                ],
                                o: [
                                  [-99.545, 51],
                                  [34.537, 134.344],
                                  [-58.969, 37.016],
                                  [136.162, 34.193],
                                  [18.121, 71.394],
                                  [111.848, -2.303],
                                  [-141.518, -83.39],
                                  [48.666, -154.151],
                                  [8.273, -92],
                                ],
                                v: [
                                  [-131.442, -418.701],
                                  [-167.621, -186.471],
                                  [-250.441, 47.838],
                                  [-283.821, 134.988],
                                  [-0.767, 308.257],
                                  [131.601, 423.562],
                                  [303.094, 274.632],
                                  [16.644, -157.711],
                                  [286.457, -258.882],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.625, -50.75],
                                  [-34.492, -134.293],
                                  [58.937, -37.047],
                                  [-136.105, -34.199],
                                  [-17.922, -71.125],
                                  [-113.438, 2.656],
                                  [141.441, 83.371],
                                  [-48.922, 153.828],
                                  [-8.281, 92],
                                ],
                                o: [
                                  [-99.531, 50.969],
                                  [34.492, 134.293],
                                  [-58.937, 37.047],
                                  [136.105, 34.199],
                                  [18.125, 71.375],
                                  [111.844, -2.313],
                                  [-141.441, -83.371],
                                  [48.625, -154.094],
                                  [8.281, -92],
                                ],
                                v: [
                                  [-132.248, -419.293],
                                  [-167.144, -186.271],
                                  [-251.179, 47.647],
                                  [-283, 134.58],
                                  [-0.378, 308.582],
                                  [131.718, 423.275],
                                  [303.301, 273.886],
                                  [15.664, -158.629],
                                  [285.957, -258.738],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.644, -50.711],
                                  [-34.446, -134.24],
                                  [58.905, -37.079],
                                  [-136.047, -34.205],
                                  [-17.919, -71.098],
                                  [-113.482, 2.677],
                                  [141.362, 83.352],
                                  [-48.889, 153.761],
                                  [-8.29, 92],
                                ],
                                o: [
                                  [-99.517, 50.937],
                                  [34.446, 134.24],
                                  [-58.905, 37.079],
                                  [136.047, 34.205],
                                  [18.129, 71.356],
                                  [111.839, -2.322],
                                  [-141.362, -83.352],
                                  [48.582, -154.035],
                                  [8.29, -92],
                                ],
                                v: [
                                  [-132.929, -419.821],
                                  [-166.728, -186.011],
                                  [-251.976, 47.394],
                                  [-282.118, 134.174],
                                  [0.008, 308.966],
                                  [131.836, 422.988],
                                  [303.446, 273.077],
                                  [14.628, -159.541],
                                  [285.454, -258.592],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.664, -50.672],
                                  [-34.398, -134.186],
                                  [58.871, -37.112],
                                  [-135.987, -34.212],
                                  [-17.917, -71.07],
                                  [-113.528, 2.697],
                                  [141.281, 83.332],
                                  [-48.854, 153.692],
                                  [-8.299, 92],
                                ],
                                o: [
                                  [-99.502, 50.904],
                                  [34.398, 134.186],
                                  [-58.871, 37.112],
                                  [135.987, 34.212],
                                  [18.133, 71.336],
                                  [111.834, -2.332],
                                  [-141.281, -83.332],
                                  [48.539, -153.974],
                                  [8.299, -92],
                                ],
                                v: [
                                  [-133.607, -420.407],
                                  [-166.25, -185.754],
                                  [-252.833, 47.141],
                                  [-281.174, 133.829],
                                  [0.329, 309.348],
                                  [131.954, 422.702],
                                  [303.592, 272.268],
                                  [13.659, -160.387],
                                  [284.949, -258.382],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.685, -50.631],
                                  [-34.349, -134.13],
                                  [58.836, -37.147],
                                  [-135.925, -34.218],
                                  [-17.914, -71.042],
                                  [-113.574, 2.719],
                                  [141.198, 83.311],
                                  [-48.819, 153.621],
                                  [-8.308, 92],
                                ],
                                o: [
                                  [-99.487, 50.871],
                                  [34.349, 134.13],
                                  [-58.836, 37.147],
                                  [135.925, 34.218],
                                  [18.137, 71.315],
                                  [111.829, -2.342],
                                  [-141.198, -83.311],
                                  [48.494, -153.912],
                                  [8.308, -92],
                                ],
                                v: [
                                  [-134.284, -420.867],
                                  [-165.774, -185.499],
                                  [-253.69, 46.825],
                                  [-280.171, 133.422],
                                  [0.585, 309.668],
                                  [132.073, 422.415],
                                  [303.676, 271.399],
                                  [12.695, -161.227],
                                  [284.442, -258.171],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.705, -50.589],
                                  [-34.298, -134.073],
                                  [58.801, -37.182],
                                  [-135.861, -34.225],
                                  [-17.912, -71.012],
                                  [-113.623, 2.741],
                                  [141.112, 83.29],
                                  [-48.783, 153.548],
                                  [-8.318, 92],
                                ],
                                o: [
                                  [-99.471, 50.836],
                                  [34.298, 134.073],
                                  [-58.801, 37.182],
                                  [135.861, 34.225],
                                  [18.141, 71.294],
                                  [111.824, -2.353],
                                  [-141.112, -83.29],
                                  [48.448, -153.848],
                                  [8.317, -92],
                                ],
                                v: [
                                  [-134.897, -421.323],
                                  [-165.296, -185.185],
                                  [-254.483, 46.445],
                                  [-279.168, 133.14],
                                  [0.898, 309.984],
                                  [132.192, 422.127],
                                  [303.76, 270.529],
                                  [11.737, -162.062],
                                  [283.994, -257.959],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.727, -50.546],
                                  [-34.246, -134.014],
                                  [58.764, -37.218],
                                  [-135.796, -34.232],
                                  [-17.909, -70.982],
                                  [-113.672, 2.763],
                                  [141.024, 83.268],
                                  [-48.746, 153.473],
                                  [-8.327, 92],
                                ],
                                o: [
                                  [-99.455, 50.8],
                                  [34.246, 134.014],
                                  [-58.764, 37.218],
                                  [135.796, 34.232],
                                  [18.145, 71.273],
                                  [111.818, -2.364],
                                  [-141.024, -83.268],
                                  [48.4, -153.782],
                                  [8.327, -92],
                                ],
                                v: [
                                  [-135.508, -421.775],
                                  [-164.758, -184.812],
                                  [-255.398, 46.066],
                                  [-278.106, 132.796],
                                  [1.147, 310.361],
                                  [132.373, 421.839],
                                  [303.783, 269.659],
                                  [10.784, -162.829],
                                  [283.482, -257.744],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.749, -50.502],
                                  [-34.193, -133.954],
                                  [58.726, -37.255],
                                  [-135.729, -34.239],
                                  [-17.906, -70.951],
                                  [-113.723, 2.787],
                                  [140.933, 83.246],
                                  [-48.708, 153.397],
                                  [-8.337, 92],
                                ],
                                o: [
                                  [-99.438, 50.764],
                                  [34.193, 133.954],
                                  [-58.726, 37.255],
                                  [135.729, 34.239],
                                  [18.15, 71.251],
                                  [111.813, -2.375],
                                  [-140.933, -83.246],
                                  [48.352, -153.715],
                                  [8.337, -92],
                                ],
                                v: [
                                  [-136.115, -422.223],
                                  [-164.281, -184.504],
                                  [-256.251, 45.624],
                                  [-277.044, 132.515],
                                  [1.33, 310.673],
                                  [132.554, 421.551],
                                  [303.744, 268.728],
                                  [9.837, -163.59],
                                  [283.029, -257.527],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.772, -50.456],
                                  [-34.138, -133.892],
                                  [58.688, -37.293],
                                  [-135.66, -34.246],
                                  [-17.904, -70.919],
                                  [-113.776, 2.811],
                                  [140.84, 83.223],
                                  [-48.668, 153.317],
                                  [-8.347, 92],
                                ],
                                o: [
                                  [-99.421, 50.726],
                                  [34.138, 133.892],
                                  [-58.688, 37.293],
                                  [135.66, 34.246],
                                  [18.154, 71.228],
                                  [111.807, -2.386],
                                  [-140.84, -83.223],
                                  [48.302, -153.645],
                                  [8.347, -92],
                                ],
                                v: [
                                  [-136.659, -422.545],
                                  [-163.804, -184.136],
                                  [-257.101, 45.182],
                                  [-275.983, 132.296],
                                  [1.571, 310.984],
                                  [132.735, 421.263],
                                  [303.643, 267.737],
                                  [8.896, -164.344],
                                  [282.573, -257.25],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.795, -50.409],
                                  [-34.081, -133.828],
                                  [58.648, -37.332],
                                  [-135.589, -34.254],
                                  [-17.901, -70.886],
                                  [-113.829, 2.835],
                                  [140.744, 83.2],
                                  [-48.628, 153.236],
                                  [-8.358, 92],
                                ],
                                o: [
                                  [-99.403, 50.688],
                                  [34.081, 133.828],
                                  [-58.648, 37.332],
                                  [135.589, 34.254],
                                  [18.159, 71.205],
                                  [111.801, -2.398],
                                  [-140.744, -83.2],
                                  [48.25, -153.574],
                                  [8.358, -92],
                                ],
                                v: [
                                  [-137.14, -422.924],
                                  [-163.268, -183.709],
                                  [-257.951, 44.677],
                                  [-274.862, 132.077],
                                  [1.746, 311.292],
                                  [132.918, 420.912],
                                  [303.543, 266.808],
                                  [7.961, -165.032],
                                  [282.115, -256.907],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.819, -50.361],
                                  [-34.023, -133.762],
                                  [58.607, -37.373],
                                  [-135.516, -34.261],
                                  [-17.898, -70.853],
                                  [-113.885, 2.86],
                                  [140.645, 83.175],
                                  [-48.586, 153.152],
                                  [-8.369, 92],
                                ],
                                o: [
                                  [-99.385, 50.648],
                                  [34.023, 133.762],
                                  [-58.607, 37.373],
                                  [135.516, 34.261],
                                  [18.164, 71.181],
                                  [111.795, -2.41],
                                  [-140.645, -83.175],
                                  [48.197, -153.501],
                                  [8.369, -92],
                                ],
                                v: [
                                  [-137.617, -423.239],
                                  [-162.794, -183.345],
                                  [-258.86, 44.111],
                                  [-273.681, 131.858],
                                  [1.918, 311.537],
                                  [133.161, 420.623],
                                  [303.442, 265.817],
                                  [7.033, -165.713],
                                  [281.714, -256.625],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.844, -50.312],
                                  [-33.963, -133.694],
                                  [58.565, -37.414],
                                  [-135.441, -34.269],
                                  [-17.894, -70.818],
                                  [-113.942, 2.886],
                                  [140.544, 83.15],
                                  [-48.544, 153.066],
                                  [-8.38, 92],
                                ],
                                o: [
                                  [-99.367, 50.607],
                                  [33.963, 133.694],
                                  [-58.565, 37.414],
                                  [135.441, 34.269],
                                  [18.169, 71.156],
                                  [111.789, -2.422],
                                  [-140.544, -83.15],
                                  [48.143, -153.425],
                                  [8.38, -92],
                                ],
                                v: [
                                  [-138.091, -423.488],
                                  [-162.258, -182.924],
                                  [-259.705, 43.545],
                                  [-272.563, 131.701],
                                  [2.023, 311.84],
                                  [133.344, 420.333],
                                  [303.219, 264.765],
                                  [6.173, -166.386],
                                  [281.251, -256.281],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.87, -50.261],
                                  [-33.902, -133.625],
                                  [58.522, -37.457],
                                  [-135.364, -34.277],
                                  [-17.891, -70.782],
                                  [-114, 2.913],
                                  [140.44, 83.125],
                                  [-48.5, 152.978],
                                  [-8.391, 92],
                                ],
                                o: [
                                  [-99.348, 50.565],
                                  [33.902, 133.625],
                                  [-58.522, 37.457],
                                  [135.364, 34.277],
                                  [18.174, 71.13],
                                  [111.783, -2.435],
                                  [-140.44, -83.125],
                                  [48.087, -153.348],
                                  [8.391, -92],
                                ],
                                v: [
                                  [-138.501, -423.733],
                                  [-161.787, -182.504],
                                  [-260.611, 42.917],
                                  [-271.326, 131.544],
                                  [2.187, 312.142],
                                  [133.588, 420.043],
                                  [302.996, 263.774],
                                  [5.258, -166.992],
                                  [280.846, -255.936],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.896, -50.208],
                                  [-33.839, -133.553],
                                  [58.477, -37.5],
                                  [-135.285, -34.286],
                                  [-17.888, -70.746],
                                  [-114.06, 2.941],
                                  [140.333, 83.099],
                                  [-48.455, 152.887],
                                  [-8.403, 92],
                                ],
                                o: [
                                  [-99.328, 50.522],
                                  [33.839, 133.553],
                                  [-58.477, 37.501],
                                  [135.285, 34.286],
                                  [18.179, 71.104],
                                  [111.776, -2.448],
                                  [-140.333, -83.099],
                                  [48.029, -153.268],
                                  [8.403, -92],
                                ],
                                v: [
                                  [-138.909, -423.974],
                                  [-161.316, -182.086],
                                  [-261.455, 42.289],
                                  [-270.15, 131.387],
                                  [2.283, 312.38],
                                  [133.893, 419.691],
                                  [302.773, 262.725],
                                  [4.411, -167.592],
                                  [280.438, -255.526],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.923, -50.155],
                                  [-33.774, -133.48],
                                  [58.431, -37.546],
                                  [-135.203, -34.294],
                                  [-17.885, -70.708],
                                  [-114.122, 2.969],
                                  [140.223, 83.072],
                                  [-48.408, 152.794],
                                  [-8.415, 92],
                                ],
                                o: [
                                  [-99.308, 50.478],
                                  [33.774, 133.48],
                                  [-58.431, 37.545],
                                  [135.203, 34.294],
                                  [18.185, 71.077],
                                  [111.769, -2.461],
                                  [-140.223, -83.072],
                                  [47.97, -153.186],
                                  [8.415, -92],
                                ],
                                v: [
                                  [-139.252, -424.15],
                                  [-160.845, -181.612],
                                  [-262.295, 41.661],
                                  [-268.914, 131.293],
                                  [2.377, 312.677],
                                  [134.139, 419.4],
                                  [302.489, 261.675],
                                  [3.572, -168.183],
                                  [280.087, -255.118],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.95, -50.099],
                                  [-33.707, -133.404],
                                  [58.384, -37.592],
                                  [-135.119, -34.303],
                                  [-17.881, -70.67],
                                  [-114.186, 2.998],
                                  [140.11, 83.044],
                                  [-48.361, 152.698],
                                  [-8.428, 92],
                                ],
                                o: [
                                  [-99.287, 50.432],
                                  [33.707, 133.404],
                                  [-58.384, 37.592],
                                  [135.119, 34.303],
                                  [18.19, 71.05],
                                  [111.762, -2.475],
                                  [-140.11, -83.044],
                                  [47.909, -153.102],
                                  [8.428, -92],
                                ],
                                v: [
                                  [-139.591, -424.322],
                                  [-160.376, -181.138],
                                  [-263.195, 40.97],
                                  [-267.682, 131.197],
                                  [2.406, 312.911],
                                  [134.445, 419.047],
                                  [302.143, 260.566],
                                  [2.739, -168.708],
                                  [279.674, -254.707],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.979, -50.043],
                                  [-33.639, -133.327],
                                  [58.336, -37.639],
                                  [-135.033, -34.312],
                                  [-17.878, -70.63],
                                  [-114.251, 3.028],
                                  [139.994, 83.015],
                                  [-48.312, 152.599],
                                  [-8.44, 92],
                                ],
                                o: [
                                  [-99.266, 50.385],
                                  [33.639, 133.327],
                                  [-58.336, 37.639],
                                  [135.033, 34.312],
                                  [18.196, 71.021],
                                  [111.755, -2.489],
                                  [-139.994, -83.015],
                                  [47.847, -153.015],
                                  [8.44, -92],
                                ],
                                v: [
                                  [-139.867, -424.427],
                                  [-159.908, -180.669],
                                  [-264.033, 40.22],
                                  [-266.45, 131.165],
                                  [2.491, 313.141],
                                  [134.691, 418.753],
                                  [301.737, 259.518],
                                  [1.977, -169.224],
                                  [279.318, -254.234],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.008, -49.984],
                                  [-33.569, -133.247],
                                  [58.287, -37.688],
                                  [-134.945, -34.321],
                                  [-17.874, -70.589],
                                  [-114.318, 3.058],
                                  [139.874, 82.986],
                                  [-48.262, 152.498],
                                  [-8.453, 92],
                                ],
                                o: [
                                  [-99.244, 50.337],
                                  [33.569, 133.247],
                                  [-58.287, 37.688],
                                  [134.945, 34.321],
                                  [18.202, 70.992],
                                  [111.748, -2.504],
                                  [-139.874, -82.986],
                                  [47.783, -152.926],
                                  [8.453, -92],
                                ],
                                v: [
                                  [-140.138, -424.469],
                                  [-159.503, -180.201],
                                  [-264.868, 39.468],
                                  [-265.159, 131.131],
                                  [2.51, 313.369],
                                  [134.996, 418.4],
                                  [301.332, 258.41],
                                  [1.22, -169.671],
                                  [278.959, -253.758],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.038, -49.924],
                                  [-33.496, -133.166],
                                  [58.236, -37.738],
                                  [-134.854, -34.331],
                                  [-17.87, -70.547],
                                  [-114.387, 3.09],
                                  [139.752, 82.956],
                                  [-48.21, 152.394],
                                  [-8.467, 92],
                                ],
                                o: [
                                  [-99.222, 50.288],
                                  [33.496, 133.166],
                                  [-58.236, 37.738],
                                  [134.854, 34.331],
                                  [18.208, 70.962],
                                  [111.741, -2.519],
                                  [-139.752, -82.956],
                                  [47.717, -152.835],
                                  [8.467, -92],
                                ],
                                v: [
                                  [-140.346, -424.567],
                                  [-159.037, -179.737],
                                  [-265.641, 38.718],
                                  [-263.933, 131.158],
                                  [2.525, 313.595],
                                  [135.363, 418.106],
                                  [300.927, 257.303],
                                  [0.471, -170.112],
                                  [278.655, -253.283],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.069, -49.863],
                                  [-33.422, -133.081],
                                  [58.183, -37.79],
                                  [-134.761, -34.341],
                                  [-17.866, -70.504],
                                  [-114.458, 3.122],
                                  [139.626, 82.925],
                                  [-48.157, 152.287],
                                  [-8.481, 92],
                                ],
                                o: [
                                  [-99.199, 50.237],
                                  [33.422, 133.081],
                                  [-58.183, 37.79],
                                  [134.761, 34.341],
                                  [18.214, 70.931],
                                  [111.733, -2.534],
                                  [-139.626, -82.925],
                                  [47.649, -152.741],
                                  [8.481, -92],
                                ],
                                v: [
                                  [-140.55, -424.538],
                                  [-158.633, -179.215],
                                  [-266.474, 37.968],
                                  [-262.646, 131.185],
                                  [2.536, 313.818],
                                  [135.67, 417.751],
                                  [300.4, 256.197],
                                  [-0.268, -170.483],
                                  [278.35, -252.746],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.1, -49.8],
                                  [-33.346, -132.995],
                                  [58.13, -37.843],
                                  [-134.665, -34.351],
                                  [-17.862, -70.46],
                                  [-114.53, 3.155],
                                  [139.496, 82.893],
                                  [-48.102, 152.177],
                                  [-8.495, 92],
                                ],
                                o: [
                                  [-99.175, 50.185],
                                  [33.346, 132.995],
                                  [-58.13, 37.843],
                                  [134.665, 34.351],
                                  [18.22, 70.9],
                                  [111.725, -2.55],
                                  [-139.496, -82.893],
                                  [47.58, -152.644],
                                  [8.495, -92],
                                ],
                                v: [
                                  [-140.69, -424.507],
                                  [-158.292, -178.756],
                                  [-267.243, 37.158],
                                  [-261.363, 131.213],
                                  [2.482, 314.04],
                                  [136.036, 417.456],
                                  [299.874, 255.093],
                                  [-0.939, -170.848],
                                  [278.039, -252.147],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.133, -49.735],
                                  [-33.267, -132.906],
                                  [58.074, -37.897],
                                  [-134.567, -34.361],
                                  [-17.858, -70.414],
                                  [-114.605, 3.189],
                                  [139.363, 82.86],
                                  [-48.046, 152.064],
                                  [-8.51, 92],
                                ],
                                o: [
                                  [-99.151, 50.131],
                                  [33.267, 132.906],
                                  [-58.074, 37.897],
                                  [134.567, 34.361],
                                  [18.227, 70.867],
                                  [111.717, -2.566],
                                  [-139.363, -82.86],
                                  [47.508, -152.545],
                                  [8.51, -92],
                                ],
                                v: [
                                  [-140.827, -424.47],
                                  [-157.89, -178.239],
                                  [-268.011, 36.287],
                                  [-260.082, 131.301],
                                  [2.484, 314.198],
                                  [136.403, 417.1],
                                  [299.348, 253.929],
                                  [-1.601, -171.202],
                                  [277.725, -251.607],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.166, -49.668],
                                  [-33.187, -132.815],
                                  [58.018, -37.953],
                                  [-134.465, -34.372],
                                  [-17.854, -70.368],
                                  [-114.682, 3.224],
                                  [139.227, 82.827],
                                  [-47.989, 151.948],
                                  [-8.525, 92],
                                ],
                                o: [
                                  [-99.125, 50.076],
                                  [33.187, 132.815],
                                  [-58.018, 37.953],
                                  [134.465, 34.372],
                                  [18.233, 70.834],
                                  [111.708, -2.583],
                                  [-139.227, -82.827],
                                  [47.435, -152.444],
                                  [8.525, -92],
                                ],
                                v: [
                                  [-140.959, -424.368],
                                  [-157.551, -177.726],
                                  [-268.716, 35.477],
                                  [-258.804, 131.387],
                                  [2.421, 314.413],
                                  [136.77, 416.803],
                                  [298.7, 252.828],
                                  [-2.194, -171.489],
                                  [277.467, -250.944],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.2, -49.599],
                                  [-33.104, -132.722],
                                  [57.96, -38.011],
                                  [-134.362, -34.383],
                                  [-17.85, -70.32],
                                  [-114.761, 3.26],
                                  [139.086, 82.792],
                                  [-47.93, 151.829],
                                  [-8.54, 92],
                                ],
                                o: [
                                  [-99.1, 50.02],
                                  [33.104, 132.722],
                                  [-57.96, 38.01],
                                  [134.362, 34.383],
                                  [18.24, 70.8],
                                  [111.7, -2.6],
                                  [-139.086, -82.792],
                                  [47.359, -152.339],
                                  [8.54, -92],
                                ],
                                v: [
                                  [-141.028, -424.261],
                                  [-157.273, -177.214],
                                  [-269.479, 34.606],
                                  [-257.588, 131.534],
                                  [2.353, 314.567],
                                  [137.136, 416.445],
                                  [298.114, 251.727],
                                  [-2.78, -171.768],
                                  [277.205, -250.343],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.235, -49.529],
                                  [-33.019, -132.626],
                                  [57.9, -38.069],
                                  [-134.255, -34.394],
                                  [-17.846, -70.27],
                                  [-114.842, 3.297],
                                  [138.942, 82.757],
                                  [-47.869, 151.707],
                                  [-8.556, 92],
                                ],
                                o: [
                                  [-99.073, 49.961],
                                  [33.019, 132.626],
                                  [-57.9, 38.069],
                                  [134.255, 34.394],
                                  [18.247, 70.765],
                                  [111.691, -2.618],
                                  [-138.942, -82.757],
                                  [47.282, -152.232],
                                  [8.556, -92],
                                ],
                                v: [
                                  [-141.032, -424.151],
                                  [-156.996, -176.707],
                                  [-270.181, 33.737],
                                  [-256.316, 131.681],
                                  [2.281, 314.717],
                                  [137.561, 416.085],
                                  [297.408, 250.568],
                                  [-3.295, -171.977],
                                  [277, -249.679],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.272, -49.457],
                                  [-32.932, -132.527],
                                  [57.838, -38.13],
                                  [-134.145, -34.405],
                                  [-17.841, -70.22],
                                  [-114.925, 3.335],
                                  [138.794, 82.72],
                                  [-47.806, 151.581],
                                  [-8.572, 92],
                                ],
                                o: [
                                  [-99.046, 49.902],
                                  [32.932, 132.527],
                                  [-57.838, 38.13],
                                  [134.145, 34.405],
                                  [18.254, 70.728],
                                  [111.682, -2.636],
                                  [-138.794, -82.72],
                                  [47.202, -152.122],
                                  [8.572, -92],
                                ],
                                v: [
                                  [-141.032, -423.915],
                                  [-156.72, -176.204],
                                  [-270.82, 32.868],
                                  [-255.045, 131.829],
                                  [2.203, 314.866],
                                  [137.928, 415.785],
                                  [296.703, 249.469],
                                  [-3.801, -172.117],
                                  [276.727, -249.014],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.309, -49.382],
                                  [-32.843, -132.425],
                                  [57.775, -38.192],
                                  [-134.033, -34.417],
                                  [-17.836, -70.168],
                                  [-115.01, 3.374],
                                  [138.642, 82.683],
                                  [-47.742, 151.452],
                                  [-8.589, 92],
                                ],
                                o: [
                                  [-99.018, 49.841],
                                  [32.843, 132.425],
                                  [-57.775, 38.192],
                                  [134.033, 34.417],
                                  [18.262, 70.691],
                                  [111.673, -2.654],
                                  [-138.642, -82.683],
                                  [47.121, -152.008],
                                  [8.589, -92],
                                ],
                                v: [
                                  [-141.027, -423.734],
                                  [-156.506, -175.763],
                                  [-271.456, 32],
                                  [-253.837, 132.035],
                                  [2.12, 315.014],
                                  [138.353, 415.424],
                                  [295.999, 248.313],
                                  [-4.237, -172.308],
                                  [276.511, -248.289],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.347, -49.306],
                                  [-32.751, -132.321],
                                  [57.71, -38.256],
                                  [-133.917, -34.429],
                                  [-17.832, -70.114],
                                  [-115.098, 3.414],
                                  [138.486, 82.645],
                                  [-47.677, 151.32],
                                  [-8.606, 92],
                                ],
                                o: [
                                  [-98.99, 49.778],
                                  [32.751, 132.321],
                                  [-57.71, 38.256],
                                  [133.917, 34.429],
                                  [18.269, 70.653],
                                  [111.663, -2.673],
                                  [-138.486, -82.645],
                                  [47.037, -151.892],
                                  [8.606, -92],
                                ],
                                v: [
                                  [-140.959, -423.489],
                                  [-156.292, -175.265],
                                  [-272.091, 31.074],
                                  [-252.572, 132.3],
                                  [2.034, 315.156],
                                  [138.777, 415.063],
                                  [295.234, 247.159],
                                  [-4.661, -172.371],
                                  [276.349, -247.502],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.386, -49.228],
                                  [-32.656, -132.214],
                                  [57.644, -38.322],
                                  [-133.799, -34.442],
                                  [-17.827, -70.06],
                                  [-115.188, 3.455],
                                  [138.326, 82.605],
                                  [-47.609, 151.184],
                                  [-8.624, 92],
                                ],
                                o: [
                                  [-98.96, 49.713],
                                  [32.656, 132.214],
                                  [-57.644, 38.322],
                                  [133.799, 34.442],
                                  [18.277, 70.614],
                                  [111.654, -2.693],
                                  [-138.326, -82.605],
                                  [46.951, -151.773],
                                  [8.624, -92],
                                ],
                                v: [
                                  [-140.825, -423.179],
                                  [-156.14, -174.77],
                                  [-272.662, 30.148],
                                  [-251.371, 132.564],
                                  [1.879, 315.299],
                                  [139.26, 414.759],
                                  [294.411, 246.067],
                                  [-5.018, -172.424],
                                  [276.184, -246.775],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.426, -49.148],
                                  [-32.559, -132.105],
                                  [57.575, -38.389],
                                  [-133.677, -34.455],
                                  [-17.822, -70.003],
                                  [-115.28, 3.498],
                                  [138.161, 82.565],
                                  [-47.54, 151.044],
                                  [-8.642, 92],
                                ],
                                o: [
                                  [-98.93, 49.647],
                                  [32.559, 132.105],
                                  [-57.575, 38.389],
                                  [133.677, 34.455],
                                  [18.285, 70.574],
                                  [111.643, -2.713],
                                  [-138.161, -82.565],
                                  [46.862, -151.65],
                                  [8.642, -92],
                                ],
                                v: [
                                  [-140.687, -422.863],
                                  [-156.049, -174.277],
                                  [-273.234, 29.221],
                                  [-250.233, 132.828],
                                  [1.783, 315.439],
                                  [139.742, 414.396],
                                  [293.589, 244.977],
                                  [-5.304, -172.468],
                                  [276.009, -245.987],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.467, -49.065],
                                  [-32.46, -131.992],
                                  [57.505, -38.458],
                                  [-133.552, -34.468],
                                  [-17.817, -69.946],
                                  [-115.375, 3.541],
                                  [137.992, 82.523],
                                  [-47.469, 150.9],
                                  [-8.66, 92],
                                ],
                                o: [
                                  [-98.899, 49.579],
                                  [32.46, 131.992],
                                  [-57.505, 38.458],
                                  [133.552, 34.468],
                                  [18.293, 70.532],
                                  [111.633, -2.734],
                                  [-137.992, -82.523],
                                  [46.772, -151.524],
                                  [8.66, -92],
                                ],
                                v: [
                                  [-140.544, -422.545],
                                  [-155.959, -173.849],
                                  [-273.74, 28.297],
                                  [-249.098, 133.152],
                                  [1.619, 315.517],
                                  [140.164, 414.089],
                                  [292.707, 243.829],
                                  [-5.58, -172.442],
                                  [275.831, -245.137],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.51, -48.98],
                                  [-32.357, -131.876],
                                  [57.433, -38.529],
                                  [-133.423, -34.481],
                                  [-17.811, -69.886],
                                  [-115.473, 3.585],
                                  [137.819, 82.481],
                                  [-47.396, 150.753],
                                  [-8.679, 92],
                                ],
                                o: [
                                  [-98.868, 49.509],
                                  [32.357, 131.876],
                                  [-57.433, 38.529],
                                  [133.423, 34.481],
                                  [18.302, 70.49],
                                  [111.622, -2.755],
                                  [-137.819, -82.481],
                                  [46.678, -151.395],
                                  [8.679, -92],
                                ],
                                v: [
                                  [-140.337, -422.163],
                                  [-155.927, -173.423],
                                  [-274.246, 27.376],
                                  [-247.967, 133.533],
                                  [1.511, 315.592],
                                  [140.705, 413.722],
                                  [291.827, 242.745],
                                  [-5.786, -172.408],
                                  [275.706, -244.29],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.553, -48.893],
                                  [-32.253, -131.757],
                                  [57.359, -38.602],
                                  [-133.291, -34.495],
                                  [-17.806, -69.825],
                                  [-115.573, 3.631],
                                  [137.641, 82.437],
                                  [-47.32, 150.602],
                                  [-8.699, 92],
                                ],
                                o: [
                                  [-98.835, 49.437],
                                  [32.253, 131.757],
                                  [-57.359, 38.602],
                                  [133.291, 34.495],
                                  [18.311, 70.447],
                                  [111.612, -2.777],
                                  [-137.641, -82.437],
                                  [46.583, -151.262],
                                  [8.699, -92],
                                ],
                                v: [
                                  [-140.124, -421.716],
                                  [-155.899, -173.002],
                                  [-274.69, 26.454],
                                  [-246.837, 133.914],
                                  [1.336, 315.726],
                                  [141.183, 413.412],
                                  [290.889, 241.661],
                                  [-5.921, -172.304],
                                  [275.575, -243.439],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.598, -48.804],
                                  [-32.145, -131.635],
                                  [57.283, -38.677],
                                  [-133.156, -34.509],
                                  [-17.8, -69.763],
                                  [-115.675, 3.678],
                                  [137.458, 82.392],
                                  [-47.243, 150.447],
                                  [-8.719, 92],
                                ],
                                o: [
                                  [-98.801, 49.363],
                                  [32.145, 131.635],
                                  [-57.283, 38.677],
                                  [133.156, 34.509],
                                  [18.32, 70.402],
                                  [111.601, -2.799],
                                  [-137.458, -82.392],
                                  [46.484, -151.126],
                                  [8.719, -92],
                                ],
                                v: [
                                  [-139.847, -421.324],
                                  [-155.931, -172.582],
                                  [-275.131, 25.533],
                                  [-245.83, 134.291],
                                  [1.216, 315.797],
                                  [141.658, 413.042],
                                  [289.952, 240.582],
                                  [-5.985, -172.19],
                                  [275.495, -242.53],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.644, -48.712],
                                  [-32.034, -131.51],
                                  [57.205, -38.753],
                                  [-133.017, -34.524],
                                  [-17.794, -69.699],
                                  [-115.781, 3.726],
                                  [137.271, 82.346],
                                  [-47.164, 150.288],
                                  [-8.74, 92],
                                ],
                                o: [
                                  [-98.767, 49.288],
                                  [32.034, 131.51],
                                  [-57.205, 38.753],
                                  [133.017, 34.524],
                                  [18.329, 70.356],
                                  [111.589, -2.822],
                                  [-137.271, -82.346],
                                  [46.384, -150.986],
                                  [8.74, -92],
                                ],
                                v: [
                                  [-139.564, -420.812],
                                  [-156.023, -172.167],
                                  [-275.508, 24.613],
                                  [-244.767, 134.727],
                                  [1.029, 315.87],
                                  [142.195, 412.728],
                                  [288.958, 239.505],
                                  [-5.982, -172.008],
                                  [275.349, -241.621],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.691, -48.618],
                                  [-31.921, -131.382],
                                  [57.126, -38.832],
                                  [-132.875, -34.539],
                                  [-17.789, -69.633],
                                  [-115.889, 3.775],
                                  [137.078, 82.299],
                                  [-47.083, 150.124],
                                  [-8.761, 92],
                                ],
                                o: [
                                  [-98.732, 49.21],
                                  [31.921, 131.382],
                                  [-57.125, 38.832],
                                  [132.874, 34.539],
                                  [18.338, 70.309],
                                  [111.577, -2.845],
                                  [-137.078, -82.299],
                                  [46.28, -150.843],
                                  [8.761, -92],
                                ],
                                v: [
                                  [-139.275, -420.351],
                                  [-156.173, -171.814],
                                  [-275.884, 23.694],
                                  [-243.827, 135.222],
                                  [0.898, 315.939],
                                  [142.726, 412.413],
                                  [287.966, 238.491],
                                  [-5.965, -171.755],
                                  [275.317, -240.651],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.739, -48.522],
                                  [-31.804, -131.25],
                                  [57.043, -38.913],
                                  [-132.728, -34.554],
                                  [-17.783, -69.565],
                                  [-116, 3.826],
                                  [136.88, 82.25],
                                  [-47, 149.956],
                                  [-8.783, 92],
                                ],
                                o: [
                                  [-98.696, 49.13],
                                  [31.804, 131.25],
                                  [-57.043, 38.913],
                                  [132.728, 34.554],
                                  [18.348, 70.261],
                                  [111.565, -2.87],
                                  [-136.88, -82.25],
                                  [46.174, -150.696],
                                  [8.783, -92],
                                ],
                                v: [
                                  [-138.921, -419.829],
                                  [-156.326, -171.525],
                                  [-276.141, 22.78],
                                  [-242.891, 135.715],
                                  [0.701, 316.008],
                                  [143.258, 412.098],
                                  [286.976, 237.418],
                                  [-5.818, -171.494],
                                  [275.217, -239.682],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.789, -48.423],
                                  [-31.685, -131.115],
                                  [56.959, -38.996],
                                  [-132.578, -34.57],
                                  [-17.776, -69.496],
                                  [-116.114, 3.878],
                                  [136.677, 82.2],
                                  [-46.914, 149.784],
                                  [-8.805, 92],
                                ],
                                o: [
                                  [-98.658, 49.049],
                                  [31.685, 131.115],
                                  [-56.959, 38.996],
                                  [132.578, 34.57],
                                  [18.358, 70.211],
                                  [111.553, -2.894],
                                  [-136.677, -82.2],
                                  [46.065, -150.544],
                                  [8.805, -92],
                                ],
                                v: [
                                  [-138.501, -419.244],
                                  [-156.539, -171.237],
                                  [-276.453, 21.864],
                                  [-242.019, 136.263],
                                  [0.557, 316.074],
                                  [143.843, 411.779],
                                  [285.871, 236.412],
                                  [-5.659, -171.22],
                                  [275.168, -238.653],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.84, -48.321],
                                  [-31.562, -130.976],
                                  [56.873, -39.081],
                                  [-132.424, -34.586],
                                  [-17.77, -69.425],
                                  [-116.231, 3.932],
                                  [136.469, 82.149],
                                  [-46.827, 149.607],
                                  [-8.828, 92],
                                ],
                                o: [
                                  [-98.62, 48.965],
                                  [31.562, 130.976],
                                  [-56.873, 39.081],
                                  [132.424, 34.586],
                                  [18.368, 70.16],
                                  [111.54, -2.92],
                                  [-136.469, -82.149],
                                  [45.953, -150.389],
                                  [8.828, -92],
                                ],
                                v: [
                                  [-138.133, -418.716],
                                  [-156.868, -170.951],
                                  [-276.643, 20.95],
                                  [-241.206, 136.81],
                                  [0.346, 316.079],
                                  [144.37, 411.456],
                                  [284.826, 235.407],
                                  [-5.373, -170.881],
                                  [275.111, -237.621],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.892, -48.216],
                                  [-31.436, -130.833],
                                  [56.784, -39.169],
                                  [-132.266, -34.603],
                                  [-17.764, -69.352],
                                  [-116.351, 3.986],
                                  [136.256, 82.096],
                                  [-46.737, 149.426],
                                  [-8.851, 92],
                                ],
                                o: [
                                  [-98.581, 48.879],
                                  [31.436, 130.833],
                                  [-56.784, 39.169],
                                  [132.266, 34.603],
                                  [18.378, 70.108],
                                  [111.527, -2.946],
                                  [-136.256, -82.096],
                                  [45.838, -150.23],
                                  [8.851, -92],
                                ],
                                v: [
                                  [-137.701, -418.064],
                                  [-157.197, -170.729],
                                  [-276.831, 20.035],
                                  [-240.457, 137.355],
                                  [0.187, 316.144],
                                  [144.95, 411.132],
                                  [283.724, 234.409],
                                  [-5.012, -170.471],
                                  [275.103, -236.592],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [101.945, -48.109],
                                  [-31.307, -130.687],
                                  [56.693, -39.258],
                                  [-132.103, -34.62],
                                  [-17.757, -69.277],
                                  [-116.474, 4.043],
                                  [136.036, 82.043],
                                  [-46.644, 149.24],
                                  [-8.875, 92],
                                ],
                                o: [
                                  [-98.541, 48.79],
                                  [31.307, 130.687],
                                  [-56.693, 39.258],
                                  [132.103, 34.62],
                                  [18.389, 70.055],
                                  [111.514, -2.973],
                                  [-136.036, -82.043],
                                  [45.72, -150.067],
                                  [8.875, -92],
                                ],
                                v: [
                                  [-137.203, -417.47],
                                  [-157.588, -170.568],
                                  [-276.957, 19.124],
                                  [-239.771, 138.013],
                                  [0.022, 316.15],
                                  [145.53, 410.866],
                                  [282.628, 233.473],
                                  [-4.644, -170.049],
                                  [275.086, -235.564],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102, -47.999],
                                  [-31.174, -130.537],
                                  [56.6, -39.35],
                                  [-131.937, -34.638],
                                  [-17.75, -69.2],
                                  [-116.601, 4.1],
                                  [135.811, 81.987],
                                  [-46.55, 149.049],
                                  [-8.9, 92],
                                ],
                                o: [
                                  [-98.5, 48.7],
                                  [31.174, 130.537],
                                  [-56.6, 39.35],
                                  [131.937, 34.638],
                                  [18.4, 70],
                                  [111.5, -3],
                                  [-135.811, -81.987],
                                  [45.599, -149.899],
                                  [8.9, -92],
                                ],
                                v: [
                                  [-136.696, -416.811],
                                  [-157.975, -170.41],
                                  [-277.084, 18.212],
                                  [-239.147, 138.672],
                                  [-0.208, 316.215],
                                  [146.162, 410.537],
                                  [281.475, 232.541],
                                  [-4.263, -169.56],
                                  [275.061, -234.477],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.057, -47.887],
                                  [-31.038, -130.383],
                                  [56.504, -39.445],
                                  [-131.766, -34.656],
                                  [-17.743, -69.121],
                                  [-116.73, 4.159],
                                  [135.581, 81.93],
                                  [-46.452, 148.853],
                                  [-8.926, 92],
                                ],
                                o: [
                                  [-98.458, 48.607],
                                  [31.038, 130.383],
                                  [-56.504, 39.445],
                                  [131.766, 34.656],
                                  [18.411, 69.943],
                                  [111.486, -3.028],
                                  [-135.581, -81.93],
                                  [45.475, -149.727],
                                  [8.926, -92],
                                ],
                                v: [
                                  [-136.184, -416.095],
                                  [-158.48, -170.313],
                                  [-277.146, 17.361],
                                  [-238.583, 139.325],
                                  [-0.388, 316.218],
                                  [146.673, 410.203],
                                  [280.385, 231.614],
                                  [-3.812, -169.06],
                                  [275.087, -233.332],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.115, -47.771],
                                  [-30.899, -130.225],
                                  [56.405, -39.542],
                                  [-131.59, -34.674],
                                  [-17.736, -69.04],
                                  [-116.863, 4.22],
                                  [135.344, 81.872],
                                  [-46.353, 148.652],
                                  [-8.952, 92],
                                ],
                                o: [
                                  [-98.414, 48.511],
                                  [30.899, 130.225],
                                  [-56.405, 39.542],
                                  [131.59, 34.674],
                                  [18.423, 69.885],
                                  [111.471, -3.057],
                                  [-135.344, -81.872],
                                  [45.348, -149.551],
                                  [8.952, -92],
                                ],
                                v: [
                                  [-135.664, -415.433],
                                  [-159.043, -170.275],
                                  [-277.147, 16.45],
                                  [-238.143, 140.034],
                                  [-0.574, 316.221],
                                  [147.123, 409.808],
                                  [279.184, 230.754],
                                  [-3.286, -168.43],
                                  [275.041, -232.243],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.174, -47.652],
                                  [-30.756, -130.063],
                                  [56.304, -39.641],
                                  [-131.411, -34.693],
                                  [-17.728, -68.957],
                                  [-117, 4.283],
                                  [135.101, 81.813],
                                  [-46.25, 148.446],
                                  [-8.978, 92],
                                ],
                                o: [
                                  [-98.37, 48.413],
                                  [30.756, 130.063],
                                  [-56.304, 39.641],
                                  [131.411, 34.693],
                                  [18.435, 69.826],
                                  [111.457, -3.087],
                                  [-135.101, -81.813],
                                  [45.218, -149.37],
                                  [8.978, -92],
                                ],
                                v: [
                                  [-135.076, -414.707],
                                  [-159.664, -170.301],
                                  [-277.146, 15.603],
                                  [-237.7, 140.737],
                                  [-0.77, 316.226],
                                  [147.568, 409.35],
                                  [278.041, 229.897],
                                  [-2.814, -167.795],
                                  [275.104, -231.099],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.235, -47.531],
                                  [-30.609, -129.896],
                                  [56.201, -39.743],
                                  [-131.226, -34.712],
                                  [-17.721, -68.871],
                                  [-117.14, 4.346],
                                  [134.852, 81.751],
                                  [-46.145, 148.234],
                                  [-9.006, 92],
                                ],
                                o: [
                                  [-98.324, 48.313],
                                  [30.609, 129.896],
                                  [-56.201, 39.743],
                                  [131.226, 34.712],
                                  [18.447, 69.765],
                                  [111.441, -3.117],
                                  [-134.852, -81.751],
                                  [45.084, -149.184],
                                  [9.006, -92],
                                ],
                                v: [
                                  [-134.483, -413.924],
                                  [-160.343, -170.388],
                                  [-277.025, 14.751],
                                  [-237.323, 141.499],
                                  [-0.916, 316.231],
                                  [147.889, 408.89],
                                  [276.847, 229.044],
                                  [-2.207, -167.145],
                                  [275.097, -229.895],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.297, -47.406],
                                  [-30.458, -129.726],
                                  [56.095, -39.848],
                                  [-131.037, -34.732],
                                  [-17.713, -68.784],
                                  [-117.283, 4.412],
                                  [134.596, 81.689],
                                  [-46.037, 148.017],
                                  [-9.034, 92],
                                ],
                                o: [
                                  [-98.277, 48.21],
                                  [30.458, 129.726],
                                  [-56.095, 39.848],
                                  [131.037, 34.732],
                                  [18.459, 69.703],
                                  [111.426, -3.149],
                                  [-134.596, -81.688],
                                  [44.946, -148.994],
                                  [9.034, -92],
                                ],
                                v: [
                                  [-133.88, -413.2],
                                  [-161.074, -170.476],
                                  [-276.964, 13.901],
                                  [-237.003, 142.253],
                                  [-1.125, 316.178],
                                  [148.149, 408.311],
                                  [275.661, 228.261],
                                  [-1.589, -166.368],
                                  [275.136, -228.75],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.361, -47.278],
                                  [-30.304, -129.551],
                                  [55.986, -39.955],
                                  [-130.843, -34.753],
                                  [-17.705, -68.694],
                                  [-117.431, 4.479],
                                  [134.334, 81.624],
                                  [-45.927, 147.795],
                                  [-9.063, 92],
                                ],
                                o: [
                                  [-98.229, 48.104],
                                  [30.304, 129.551],
                                  [-55.986, 39.955],
                                  [130.843, 34.753],
                                  [18.472, 69.639],
                                  [111.41, -3.181],
                                  [-134.334, -81.624],
                                  [44.805, -148.798],
                                  [9.062, -92],
                                ],
                                v: [
                                  [-133.209, -412.412],
                                  [-161.866, -170.681],
                                  [-276.78, 13.112],
                                  [-236.686, 143.007],
                                  [-1.288, 316.187],
                                  [148.403, 407.724],
                                  [274.479, 227.482],
                                  [-0.961, -165.584],
                                  [275.163, -227.545],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.427, -47.146],
                                  [-30.145, -129.371],
                                  [55.874, -40.065],
                                  [-130.643, -34.774],
                                  [-17.697, -68.602],
                                  [-117.582, 4.548],
                                  [134.065, 81.558],
                                  [-45.814, 147.566],
                                  [-9.092, 92],
                                ],
                                o: [
                                  [-98.18, 47.996],
                                  [30.145, 129.371],
                                  [-55.874, 40.065],
                                  [130.643, 34.774],
                                  [18.485, 69.573],
                                  [111.393, -3.213],
                                  [-134.065, -81.558],
                                  [44.661, -148.598],
                                  [9.092, -92],
                                ],
                                v: [
                                  [-132.532, -411.566],
                                  [-162.71, -170.888],
                                  [-276.536, 12.32],
                                  [-236.431, 143.753],
                                  [-1.518, 316.134],
                                  [148.534, 407.139],
                                  [273.301, 226.708],
                                  [-0.316, -164.788],
                                  [275.18, -226.286],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.494, -47.011],
                                  [-29.982, -129.187],
                                  [55.76, -40.178],
                                  [-130.439, -34.795],
                                  [-17.688, -68.508],
                                  [-117.737, 4.619],
                                  [133.789, 81.49],
                                  [-45.697, 147.332],
                                  [-9.122, 92],
                                ],
                                o: [
                                  [-98.129, 47.884],
                                  [29.982, 129.187],
                                  [-55.76, 40.178],
                                  [130.439, 34.795],
                                  [18.499, 69.506],
                                  [111.376, -3.247],
                                  [-133.789, -81.49],
                                  [44.512, -148.392],
                                  [9.122, -92],
                                ],
                                v: [
                                  [-131.845, -410.778],
                                  [-163.61, -171.153],
                                  [-276.29, 11.527],
                                  [-236.233, 144.494],
                                  [-1.699, 316.146],
                                  [148.605, 406.487],
                                  [272.078, 226.004],
                                  [0.397, -163.867],
                                  [275.243, -225.083],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.563, -46.873],
                                  [-29.816, -128.999],
                                  [55.642, -40.294],
                                  [-130.23, -34.817],
                                  [-17.68, -68.411],
                                  [-117.896, 4.692],
                                  [133.506, 81.421],
                                  [-45.578, 147.092],
                                  [-9.154, 92],
                                ],
                                o: [
                                  [-98.077, 47.77],
                                  [29.816, 128.999],
                                  [-55.642, 40.294],
                                  [130.23, 34.817],
                                  [18.513, 69.437],
                                  [111.359, -3.282],
                                  [-133.506, -81.421],
                                  [44.36, -148.182],
                                  [9.154, -92],
                                ],
                                v: [
                                  [-131.147, -409.932],
                                  [-164.506, -171.416],
                                  [-276.038, 10.735],
                                  [-236.095, 145.231],
                                  [-1.89, 316.098],
                                  [148.611, 405.778],
                                  [270.914, 225.364],
                                  [1.181, -162.935],
                                  [275.24, -223.82],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.634, -46.731],
                                  [-29.645, -128.805],
                                  [55.522, -40.412],
                                  [-130.015, -34.84],
                                  [-17.671, -68.312],
                                  [-118.059, 4.766],
                                  [133.216, 81.349],
                                  [-45.456, 146.846],
                                  [-9.185, 92],
                                ],
                                o: [
                                  [-98.024, 47.653],
                                  [29.645, 128.805],
                                  [-55.522, 40.413],
                                  [130.015, 34.84],
                                  [18.527, 69.366],
                                  [111.341, -3.317],
                                  [-133.216, -81.349],
                                  [44.204, -147.965],
                                  [9.185, -92],
                                ],
                                v: [
                                  [-130.442, -409.087],
                                  [-165.457, -171.681],
                                  [-275.672, 9.996],
                                  [-235.96, 145.96],
                                  [-2.09, 316.052],
                                  [148.557, 405.003],
                                  [269.701, 224.737],
                                  [1.979, -161.994],
                                  [275.283, -222.559],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.707, -46.586],
                                  [-29.469, -128.607],
                                  [55.398, -40.534],
                                  [-129.794, -34.863],
                                  [-17.662, -68.21],
                                  [-118.226, 4.842],
                                  [132.918, 81.276],
                                  [-45.33, 146.593],
                                  [-9.218, 92],
                                ],
                                o: [
                                  [-97.97, 47.533],
                                  [29.469, 128.607],
                                  [-55.398, 40.534],
                                  [129.794, 34.863],
                                  [18.541, 69.293],
                                  [111.323, -3.354],
                                  [-132.918, -81.276],
                                  [44.045, -147.744],
                                  [9.218, -92],
                                ],
                                v: [
                                  [-129.725, -408.185],
                                  [-166.463, -172.065],
                                  [-275.305, 9.257],
                                  [-235.827, 146.745],
                                  [-2.246, 316.01],
                                  [148.497, 404.226],
                                  [268.499, 224.115],
                                  [2.791, -160.988],
                                  [275.314, -221.3],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [102.782, -46.437],
                                  [-29.29, -128.403],
                                  [55.271, -40.659],
                                  [-129.569, -34.887],
                                  [-17.652, -68.106],
                                  [-118.398, 4.921],
                                  [132.613, 81.201],
                                  [-45.202, 146.334],
                                  [-9.252, 92],
                                ],
                                o: [
                                  [-97.914, 47.41],
                                  [29.29, 128.403],
                                  [-55.271, 40.659],
                                  [129.569, 34.887],
                                  [18.556, 69.218],
                                  [111.305, -3.391],
                                  [-132.613, -81.201],
                                  [43.881, -147.516],
                                  [9.252, -92],
                                ],
                                v: [
                                  [-128.939, -407.342],
                                  [-167.464, -172.446],
                                  [-274.874, 8.516],
                                  [-235.75, 147.467],
                                  [-2.412, 315.975],
                                  [148.314, 403.448],
                                  [267.303, 223.557],
                                  [3.613, -159.912],
                                  [275.394, -220.038],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [0.5, -1.906],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.406, -1.906],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.312, -1.875],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.156, -1.812],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.094, -1.812],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5, -1.781],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.938, -1.75],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.906, -1.719],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.844, -1.688],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.844, -1.656],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.812, -1.594],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.781, -1.562],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.781, -1.5],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.781, -1.469],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, -1.438],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.719, -1.375],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.75, -1.344],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.719, -1.281],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.75, -1.25],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, -1.219],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.656, -1.125],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.594, -1.125],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.531, -1.094],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.5, -1.062],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.375, -1.094],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, -1.031],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.188, -1.031],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, -1],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.875, -1],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, -1.062],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.219, -1.094],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.969, -1.156],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.188, -1.25],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.281, -1.312],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.406, -1.406],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.594, -1.469],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.719, -1.594],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, -1.719],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.062, -1.844],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.281, -2.031],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.656, -2.406],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.875, -2.594],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.062, -2.781],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.281, -3],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.531, -3.188],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.781, -3.344],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33, -3.5],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.25, -3.625],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.5, -3.719],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.781, -3.875],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.031, -3.906],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.594, -4.031],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.906, -4.062],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.25, -4.094],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, -4.094],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.875, -4.125],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.156, -4.094],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.469, -4.031],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.844, -4],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.125, -3.938],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.5, -3.812],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.875, -3.75],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.188, -3.625],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.938, -3.375],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.281, -3.219],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.656, -3.031],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, -2.875],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.406, -2.688],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.812, -2.438],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.156, -2.219],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.562, -2],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.969, -1.75],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.344, -1.5],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.688, -1.188],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.031, -0.906],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.438, -0.625],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.75, -0.281],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.094, 0],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.406, 0.406],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.719, 0.719],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.062, 1.094],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.312, 1.5],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.875, 2.25],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.125, 2.625],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.188, 3.125],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.125, 3.656],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.062, 4.156],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.969, 4.688],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.875, 5.281],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.75, 5.844],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.688, 6.406],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.531, 7.062],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.438, 7.656],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.281, 8.312],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.156, 8.969],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45, 9.625],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.875, 10.312],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.688, 11],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.531, 11.625],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.375, 12.312],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.188, 13],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.031, 13.656],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.844, 14.312],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.625, 14.969],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.469, 15.625],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.25, 16.219],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.062, 16.875],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.875, 17.438],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.625, 18.062],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.344, 18.625],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.75, 19.219],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.219, 19.781],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.625, 20.281],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40, 20.719],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.375, 21.188],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.719, 21.625],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.031, 22.031],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.281, 22.375],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 22.75],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.812, 23.062],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.031, 23.344],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.219, 23.594],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.594, 23.969],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.781, 24.094],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, 24.25],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.031, 24.375],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.188, 24.469],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.281, 24.562],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.344, 24.562],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.438, 24.594],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.594, 24.594],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 24.562],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.781, 24.562],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.031, 24.375],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.125, 24.25],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.25, 24.062],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.406, 23.938],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.5, 23.719],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.812, 23.219],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.938, 22.969],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.156, 22.656],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.344, 22.281],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.531, 21.938],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.75, 21.562],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.25, 20.75],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 20.281],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.781, 19.812],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.125, 19.344],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.406, 18.844],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.75, 18.344],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.156, 17.75],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.562, 17.25],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.969, 16.656],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.406, 16.125],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.906, 15.5],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.375, 14.938],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.938, 14.312],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.5, 13.719],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.125, 13.125],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.719, 12.5],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.406, 11.906],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.5, 11.281],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.75, 10.656],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.094, 10.031],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.406, 9.438],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.781, 8.781],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.188, 8.219],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.688, 7.594],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.156, 7.031],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.594, 6.438],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.062, 5.875],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.594, 5.375],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.062, 4.844],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.562, 4.312],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, 3.844],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.594, 3.344],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.125, 2.875],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.656, 2.438],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.156, 1.969],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.656, 1.594],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.188, 1.125],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.75, 0.781],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.281, 0.406],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.844, 0.031],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.344, -0.344],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.812, -0.656],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.344, -1],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.875, -1.312],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.312, -1.594],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.844, -1.844],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.312, -2.156],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.781, -2.375],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.219, -2.625],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.656, -2.844],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.062, -3.062],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.656, -3.219],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.938, -3.406],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.312, -3.531],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.688, -3.688],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, -3.781],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.625, -4.031],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.938, -4.062],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.281, -4.156],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, -4.219],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.875, -4.219],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.156, -4.25],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.469, -4.25],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.781, -4.219],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, -4.125],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.406, -4.125],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.719, -4.031],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.031, -3.969],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.438, -3.844],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.719, -3.75],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.094, -3.625],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.844, -3.312],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.219, -3.125],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.031, -2.812],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.469, -2.656],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.062, -2.531],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.625, -2.438],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.781, -2.344],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.688, -2.219],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.531, -2.125],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.406, -2.094],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.188, -2.031],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0, -1.969],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.25, -1.938],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [0.5, -1.906],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.406, -1.906],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.312, -1.875],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.156, -1.812],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.094, -1.812],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5, -1.781],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.938, -1.75],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.906, -1.719],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.844, -1.688],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.844, -1.656],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.812, -1.594],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.781, -1.562],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.781, -1.5],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.781, -1.469],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.75, -1.438],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.719, -1.375],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.75, -1.344],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.719, -1.281],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.75, -1.25],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.688, -1.219],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.656, -1.125],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.594, -1.125],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.531, -1.094],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.5, -1.062],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.375, -1.094],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.312, -1.031],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.188, -1.031],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.031, -1],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.875, -1],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, -1.062],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.219, -1.094],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.969, -1.156],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.188, -1.25],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.281, -1.312],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.406, -1.406],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.594, -1.469],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.719, -1.594],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, -1.719],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.062, -1.844],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.281, -2.031],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.656, -2.406],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.875, -2.594],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.062, -2.781],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.281, -3],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.531, -3.188],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.781, -3.344],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33, -3.5],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.25, -3.625],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.5, -3.719],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.781, -3.875],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.031, -3.906],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.594, -4.031],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.906, -4.062],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.25, -4.094],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.531, -4.094],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.875, -4.125],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.156, -4.094],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.469, -4.031],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.844, -4],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.125, -3.938],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.5, -3.812],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.875, -3.75],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.188, -3.625],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.938, -3.375],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.281, -3.219],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.656, -3.031],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.031, -2.875],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.406, -2.688],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.812, -2.438],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.156, -2.219],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.562, -2],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.969, -1.75],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.344, -1.5],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.688, -1.188],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.031, -0.906],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.438, -0.625],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.75, -0.281],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.094, 0],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.406, 0.406],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.719, 0.719],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.062, 1.094],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.312, 1.5],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.875, 2.25],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.125, 2.625],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.188, 3.125],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.125, 3.656],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.062, 4.156],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.969, 4.688],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.875, 5.281],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.75, 5.844],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.688, 6.406],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.531, 7.062],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.438, 7.656],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.281, 8.312],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.156, 8.969],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45, 9.625],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.875, 10.312],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.688, 11],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.531, 11.625],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.375, 12.312],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.188, 13],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.031, 13.656],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.844, 14.312],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.625, 14.969],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.469, 15.625],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.25, 16.219],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.062, 16.875],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.875, 17.438],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.625, 18.062],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.344, 18.625],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.75, 19.219],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.219, 19.781],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.625, 20.281],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40, 20.719],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.375, 21.188],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.719, 21.625],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.031, 22.031],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.281, 22.375],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.562, 22.75],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.812, 23.062],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.031, 23.344],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.219, 23.594],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.594, 23.969],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.781, 24.094],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.906, 24.25],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.031, 24.375],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.188, 24.469],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.281, 24.562],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.344, 24.562],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.438, 24.594],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.594, 24.594],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.719, 24.562],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.781, 24.562],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.031, 24.375],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.125, 24.25],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.25, 24.062],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.406, 23.938],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.5, 23.719],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.812, 23.219],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.938, 22.969],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.156, 22.656],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.344, 22.281],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.531, 21.938],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.75, 21.562],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.25, 20.75],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.5, 20.281],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.781, 19.812],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.125, 19.344],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.406, 18.844],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.75, 18.344],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.156, 17.75],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.562, 17.25],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.969, 16.656],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.406, 16.125],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.906, 15.5],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.375, 14.938],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.938, 14.312],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.5, 13.719],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.125, 13.125],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.719, 12.5],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.406, 11.906],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.5, 11.281],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.75, 10.656],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.094, 10.031],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.406, 9.438],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.781, 8.781],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.188, 8.219],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.688, 7.594],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.156, 7.031],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.594, 6.438],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.062, 5.875],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.594, 5.375],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.062, 4.844],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.562, 4.312],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, 3.844],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.594, 3.344],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.125, 2.875],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.656, 2.438],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.156, 1.969],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.656, 1.594],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.188, 1.125],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.75, 0.781],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.281, 0.406],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.844, 0.031],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.344, -0.344],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.812, -0.656],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.344, -1],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.875, -1.312],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.312, -1.594],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.844, -1.844],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.312, -2.156],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.781, -2.375],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.219, -2.625],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.656, -2.844],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.062, -3.062],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.656, -3.219],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.938, -3.406],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.312, -3.531],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.688, -3.688],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15, -3.781],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.625, -4.031],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.938, -4.062],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.281, -4.156],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.594, -4.219],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.875, -4.219],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.156, -4.25],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.469, -4.25],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.781, -4.219],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, -4.125],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.406, -4.125],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.719, -4.031],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.031, -3.969],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.438, -3.844],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.719, -3.75],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.094, -3.625],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.844, -3.312],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.219, -3.125],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [1.031, -2.812],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.469, -2.656],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.062, -2.531],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.625, -2.438],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.781, -2.344],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.688, -2.219],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.531, -2.125],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.406, -2.094],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [-0.188, -2.031],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0, -1.969],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [0.25, -1.938],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 14',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 14,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        k: [
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-138.56, -418.247],
                                  [-167.25, -165.44],
                                  [-276.687, 13.061],
                                  [-234.688, 147.938],
                                  [13.058, 322.123],
                                  [149.812, 407.499],
                                  [261.689, 220.876],
                                  [-17.869, -180.244],
                                  [288.184, -226.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-138.06, -417.059],
                                  [-168.375, -165.502],
                                  [-275.812, 13.124],
                                  [-234.625, 149.125],
                                  [13.371, 321.998],
                                  [149.25, 406.686],
                                  [260.439, 220.563],
                                  [-17.119, -179.057],
                                  [288.934, -225.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-137.56, -415.872],
                                  [-169.438, -165.627],
                                  [-274.874, 13.186],
                                  [-234.563, 150.313],
                                  [13.746, 321.873],
                                  [148.562, 405.874],
                                  [259.189, 220.251],
                                  [-16.431, -177.869],
                                  [289.684, -224.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-137.122, -414.684],
                                  [-170.563, -165.752],
                                  [-273.999, 13.374],
                                  [-234.563, 151.5],
                                  [14.121, 321.686],
                                  [147.937, 405.061],
                                  [258.001, 219.938],
                                  [-15.681, -176.682],
                                  [290.434, -223.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-136.685, -413.559],
                                  [-171.688, -165.877],
                                  [-272.999, 13.561],
                                  [-234.563, 152.625],
                                  [14.496, 321.498],
                                  [147.187, 404.249],
                                  [256.876, 219.688],
                                  [-14.931, -175.432],
                                  [291.121, -222.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-136.185, -412.372],
                                  [-172.75, -166.064],
                                  [-271.999, 13.811],
                                  [-234.625, 153.75],
                                  [14.871, 321.248],
                                  [146.375, 403.436],
                                  [255.751, 219.501],
                                  [-14.181, -174.244],
                                  [291.871, -221.56],
                                ],
                                c: true,
                              },
                            ],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-135.81, -411.184],
                                  [-173.875, -166.252],
                                  [-270.999, 14.061],
                                  [-234.688, 154.812],
                                  [15.308, 321.061],
                                  [145.5, 402.561],
                                  [254.626, 219.376],
                                  [-13.431, -172.994],
                                  [292.621, -220.561],
                                ],
                                c: true,
                              },
                            ],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-135.372, -409.997],
                                  [-174.938, -166.439],
                                  [-269.937, 14.374],
                                  [-234.813, 155.812],
                                  [15.683, 320.811],
                                  [144.625, 401.749],
                                  [253.626, 219.251],
                                  [-12.681, -171.807],
                                  [293.371, -219.623],
                                ],
                                c: true,
                              },
                            ],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-134.997, -408.81],
                                  [-176, -166.689],
                                  [-268.875, 14.749],
                                  [-234.938, 156.812],
                                  [16.058, 320.561],
                                  [143.687, 400.874],
                                  [252.564, 219.126],
                                  [-11.869, -170.557],
                                  [294.059, -218.623],
                                ],
                                c: true,
                              },
                            ],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-134.622, -407.685],
                                  [-177.063, -166.877],
                                  [-267.812, 15.186],
                                  [-235.125, 157.812],
                                  [16.433, 320.311],
                                  [142.687, 400.061],
                                  [251.626, 219.063],
                                  [-11.119, -169.369],
                                  [294.809, -217.686],
                                ],
                                c: true,
                              },
                            ],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-134.247, -406.497],
                                  [-178.125, -167.127],
                                  [-266.687, 15.686],
                                  [-235.313, 158.687],
                                  [16.808, 319.998],
                                  [141.687, 399.186],
                                  [250.626, 219.063],
                                  [-10.369, -168.119],
                                  [295.559, -216.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-133.872, -405.372],
                                  [-179.125, -167.377],
                                  [-265.625, 16.186],
                                  [-235.5, 159.625],
                                  [17.183, 319.748],
                                  [140.625, 398.311],
                                  [249.752, 219.063],
                                  [-9.556, -166.869],
                                  [296.246, -215.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-133.56, -404.185],
                                  [-180.125, -167.627],
                                  [-264.5, 16.748],
                                  [-235.75, 160.437],
                                  [17.621, 319.436],
                                  [139.5, 397.499],
                                  [248.877, 219.126],
                                  [-8.806, -165.619],
                                  [296.996, -214.811],
                                ],
                                c: true,
                              },
                            ],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-133.247, -403.06],
                                  [-181.062, -167.877],
                                  [-263.312, 17.311],
                                  [-236, 161.25],
                                  [17.996, 319.186],
                                  [138.375, 396.624],
                                  [248.064, 219.251],
                                  [-7.994, -164.432],
                                  [297.684, -213.873],
                                ],
                                c: true,
                              },
                            ],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-132.935, -401.997],
                                  [-182.062, -168.127],
                                  [-262.187, 17.936],
                                  [-236.25, 162.062],
                                  [18.371, 318.874],
                                  [137.187, 395.811],
                                  [247.314, 219.376],
                                  [-7.244, -163.182],
                                  [298.371, -212.998],
                                ],
                                c: true,
                              },
                            ],
                            t: 14,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-132.685, -400.872],
                                  [-182.937, -168.377],
                                  [-261.062, 18.623],
                                  [-236.563, 162.812],
                                  [18.746, 318.561],
                                  [136, 394.936],
                                  [246.564, 219.501],
                                  [-6.494, -161.994],
                                  [299.059, -212.061],
                                ],
                                c: true,
                              },
                            ],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-132.435, -399.81],
                                  [-183.875, -168.627],
                                  [-259.875, 19.311],
                                  [-236.875, 163.5],
                                  [19.183, 318.249],
                                  [134.75, 394.124],
                                  [245.877, 219.688],
                                  [-5.744, -160.745],
                                  [299.746, -211.123],
                                ],
                                c: true,
                              },
                            ],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-132.185, -398.747],
                                  [-184.687, -168.877],
                                  [-258.75, 20.061],
                                  [-237.188, 164.125],
                                  [19.558, 317.936],
                                  [133.5, 393.311],
                                  [245.252, 219.876],
                                  [-4.931, -159.557],
                                  [300.434, -210.248],
                                ],
                                c: true,
                              },
                            ],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.997, -397.747],
                                  [-185.562, -169.127],
                                  [-257.562, 20.873],
                                  [-237.5, 164.75],
                                  [19.933, 317.686],
                                  [132.187, 392.561],
                                  [244.689, 220.126],
                                  [-4.181, -158.307],
                                  [301.121, -209.373],
                                ],
                                c: true,
                              },
                            ],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.81, -396.747],
                                  [-186.312, -169.377],
                                  [-256.437, 21.686],
                                  [-237.875, 165.375],
                                  [20.308, 317.374],
                                  [130.875, 391.749],
                                  [244.127, 220.438],
                                  [-3.431, -157.12],
                                  [301.746, -208.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.685, -395.747],
                                  [-187.125, -169.627],
                                  [-255.312, 22.561],
                                  [-238.25, 165.875],
                                  [20.746, 317.061],
                                  [129.562, 390.999],
                                  [243.627, 220.751],
                                  [-2.681, -155.932],
                                  [302.434, -207.623],
                                ],
                                c: true,
                              },
                            ],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.56, -394.81],
                                  [-187.812, -169.814],
                                  [-254.187, 23.436],
                                  [-238.563, 166.437],
                                  [21.121, 316.749],
                                  [128.187, 390.186],
                                  [243.189, 221.063],
                                  [-1.931, -154.745],
                                  [303.059, -206.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.435, -393.935],
                                  [-188.5, -170.064],
                                  [-253.062, 24.373],
                                  [-238.938, 166.875],
                                  [21.496, 316.436],
                                  [126.812, 389.499],
                                  [242.814, 221.438],
                                  [-1.244, -153.557],
                                  [303.684, -205.936],
                                ],
                                c: true,
                              },
                            ],
                            t: 22,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.372, -392.998],
                                  [-189.187, -170.252],
                                  [-251.937, 25.311],
                                  [-239.313, 167.312],
                                  [21.871, 316.186],
                                  [125.375, 388.749],
                                  [242.502, 221.813],
                                  [-0.494, -152.37],
                                  [304.308, -205.123],
                                ],
                                c: true,
                              },
                            ],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.31, -392.185],
                                  [-189.75, -170.502],
                                  [-250.875, 26.311],
                                  [-239.75, 167.687],
                                  [22.246, 315.874],
                                  [124, 387.999],
                                  [242.189, 222.188],
                                  [0.193, -151.245],
                                  [304.871, -204.311],
                                ],
                                c: true,
                              },
                            ],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.309, -391.373],
                                  [-190.312, -170.689],
                                  [-249.75, 27.311],
                                  [-240.125, 168.062],
                                  [22.621, 315.624],
                                  [122.562, 387.311],
                                  [242.002, 222.626],
                                  [0.881, -150.057],
                                  [305.496, -203.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.31, -390.56],
                                  [-190.875, -170.814],
                                  [-248.75, 28.311],
                                  [-240.5, 168.374],
                                  [22.996, 315.374],
                                  [121.125, 386.624],
                                  [241.814, 223.063],
                                  [1.631, -148.932],
                                  [306.058, -202.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.372, -389.81],
                                  [-191.375, -171.002],
                                  [-247.687, 29.373],
                                  [-240.875, 168.624],
                                  [23.371, 315.124],
                                  [119.625, 385.999],
                                  [241.752, 223.563],
                                  [2.318, -147.745],
                                  [306.621, -201.998],
                                ],
                                c: true,
                              },
                            ],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.434, -389.123],
                                  [-191.812, -171.189],
                                  [-246.688, 30.436],
                                  [-241.25, 168.874],
                                  [23.746, 314.874],
                                  [118.187, 385.374],
                                  [241.689, 224.063],
                                  [2.943, -146.62],
                                  [307.121, -201.248],
                                ],
                                c: true,
                              },
                            ],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.559, -388.498],
                                  [-192.187, -171.314],
                                  [-245.75, 31.561],
                                  [-241.625, 169.124],
                                  [24.121, 314.624],
                                  [116.687, 384.749],
                                  [241.689, 224.563],
                                  [3.631, -145.495],
                                  [307.683, -200.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.684, -387.873],
                                  [-192.562, -171.439],
                                  [-244.813, 32.686],
                                  [-242.063, 169.312],
                                  [24.496, 314.436],
                                  [115.25, 384.124],
                                  [241.752, 225.126],
                                  [4.256, -144.37],
                                  [308.183, -199.811],
                                ],
                                c: true,
                              },
                            ],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.872, -387.31],
                                  [-192.875, -171.502],
                                  [-243.938, 33.811],
                                  [-242.438, 169.437],
                                  [24.871, 314.249],
                                  [113.75, 383.561],
                                  [241.939, 225.626],
                                  [4.881, -143.245],
                                  [308.683, -199.123],
                                ],
                                c: true,
                              },
                            ],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-132.059, -386.81],
                                  [-193.125, -171.626],
                                  [-243.063, 34.936],
                                  [-242.813, 169.562],
                                  [25.183, 314.061],
                                  [112.25, 382.999],
                                  [242.127, 226.188],
                                  [5.506, -142.12],
                                  [309.121, -198.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-132.309, -386.31],
                                  [-193.375, -171.689],
                                  [-242.25, 36.123],
                                  [-243.125, 169.624],
                                  [25.558, 313.874],
                                  [110.75, 382.436],
                                  [242.377, 226.751],
                                  [6.131, -140.995],
                                  [309.621, -197.873],
                                ],
                                c: true,
                              },
                            ],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-132.559, -385.935],
                                  [-193.562, -171.689],
                                  [-241.5, 37.31],
                                  [-243.5, 169.687],
                                  [25.871, 313.749],
                                  [109.25, 381.936],
                                  [242.689, 227.376],
                                  [6.756, -139.933],
                                  [310.058, -197.311],
                                ],
                                c: true,
                              },
                            ],
                            t: 34,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-132.872, -385.56],
                                  [-193.687, -171.751],
                                  [-240.813, 38.498],
                                  [-243.875, 169.687],
                                  [26.246, 313.624],
                                  [107.75, 381.436],
                                  [243.127, 227.938],
                                  [7.318, -138.808],
                                  [310.496, -196.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-133.184, -385.248],
                                  [-193.749, -171.751],
                                  [-240.125, 39.685],
                                  [-244.188, 169.624],
                                  [26.558, 313.499],
                                  [106.25, 380.999],
                                  [243.564, 228.563],
                                  [7.881, -137.745],
                                  [310.996, -196.248],
                                ],
                                c: true,
                              },
                            ],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-133.559, -384.998],
                                  [-193.812, -171.689],
                                  [-239.5, 40.873],
                                  [-244.563, 169.562],
                                  [26.871, 313.436],
                                  [104.75, 380.499],
                                  [244.064, 229.251],
                                  [8.443, -136.683],
                                  [311.496, -195.811],
                                ],
                                c: true,
                              },
                            ],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-133.997, -384.81],
                                  [-193.812, -171.626],
                                  [-238.938, 42.06],
                                  [-244.875, 169.499],
                                  [27.183, 313.374],
                                  [103.25, 380.124],
                                  [244.627, 230.001],
                                  [9.006, -135.558],
                                  [311.996, -195.436],
                                ],
                                c: true,
                              },
                            ],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-134.434, -384.623],
                                  [-193.749, -171.564],
                                  [-238.438, 43.31],
                                  [-245.188, 169.374],
                                  [27.433, 313.311],
                                  [101.812, 379.686],
                                  [245.189, 230.751],
                                  [9.506, -134.495],
                                  [312.496, -195.061],
                                ],
                                c: true,
                              },
                            ],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-134.934, -384.56],
                                  [-193.687, -171.439],
                                  [-238, 44.498],
                                  [-245.5, 169.187],
                                  [27.621, 313.186],
                                  [100.312, 379.311],
                                  [245.814, 231.626],
                                  [10.006, -133.495],
                                  [313.058, -194.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-135.434, -384.56],
                                  [-193.562, -171.314],
                                  [-237.625, 45.685],
                                  [-245.75, 168.999],
                                  [27.746, 313.061],
                                  [98.875, 378.936],
                                  [246.439, 232.501],
                                  [10.506, -132.433],
                                  [313.558, -194.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 41,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-135.997, -384.623],
                                  [-193.374, -171.189],
                                  [-237.25, 46.873],
                                  [-246.063, 168.812],
                                  [27.808, 312.874],
                                  [97.438, 378.624],
                                  [247.189, 233.438],
                                  [11.006, -131.371],
                                  [314.121, -194.311],
                                ],
                                c: true,
                              },
                            ],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-136.559, -384.685],
                                  [-193.124, -171.001],
                                  [-236.875, 48.06],
                                  [-246.313, 168.562],
                                  [27.808, 312.624],
                                  [96, 378.311],
                                  [247.877, 234.438],
                                  [11.506, -130.371],
                                  [314.683, -194.123],
                                ],
                                c: true,
                              },
                            ],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-137.184, -384.81],
                                  [-192.874, -170.814],
                                  [-236.563, 49.185],
                                  [-246.563, 168.312],
                                  [27.683, 312.374],
                                  [94.563, 378.061],
                                  [248.627, 235.438],
                                  [11.943, -129.308],
                                  [315.183, -193.936],
                                ],
                                c: true,
                              },
                            ],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-137.809, -384.935],
                                  [-192.562, -170.626],
                                  [-236.313, 50.31],
                                  [-246.813, 168],
                                  [27.558, 312.061],
                                  [93.188, 377.811],
                                  [249.439, 236.438],
                                  [12.381, -128.308],
                                  [315.746, -193.873],
                                ],
                                c: true,
                              },
                            ],
                            t: 45,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-138.434, -385.06],
                                  [-192.249, -170.376],
                                  [-236, 51.373],
                                  [-247.063, 167.687],
                                  [27.371, 311.811],
                                  [91.813, 377.561],
                                  [250.189, 237.563],
                                  [12.818, -127.308],
                                  [316.308, -193.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-139.059, -385.185],
                                  [-191.874, -170.126],
                                  [-235.813, 52.373],
                                  [-247.313, 167.375],
                                  [27.121, 311.436],
                                  [90.438, 377.374],
                                  [251.064, 238.626],
                                  [13.193, -126.308],
                                  [316.933, -193.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-139.684, -385.248],
                                  [-191.437, -169.814],
                                  [-235.563, 53.372],
                                  [-247.5, 167],
                                  [26.746, 311.124],
                                  [89.063, 377.186],
                                  [251.877, 239.751],
                                  [13.63, -125.308],
                                  [317.496, -193.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 48,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-140.372, -385.373],
                                  [-190.999, -169.564],
                                  [-235.375, 54.372],
                                  [-247.688, 166.625],
                                  [26.371, 310.749],
                                  [87.75, 377.061],
                                  [252.752, 240.876],
                                  [14.005, -124.308],
                                  [318.058, -193.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-140.997, -385.435],
                                  [-190.499, -169.251],
                                  [-235.25, 55.247],
                                  [-247.875, 166.25],
                                  [25.933, 310.311],
                                  [86.5, 376.936],
                                  [253.627, 242.063],
                                  [14.318, -123.371],
                                  [318.621, -193.811],
                                ],
                                c: true,
                              },
                            ],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-141.622, -385.56],
                                  [-189.999, -168.876],
                                  [-235.125, 56.122],
                                  [-248, 165.875],
                                  [25.433, 309.936],
                                  [85.188, 376.811],
                                  [254.564, 243.25],
                                  [14.693, -122.433],
                                  [319.246, -193.936],
                                ],
                                c: true,
                              },
                            ],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-142.247, -385.623],
                                  [-189.437, -168.501],
                                  [-235, 56.997],
                                  [-248.188, 165.437],
                                  [24.933, 309.499],
                                  [84, 376.749],
                                  [255.439, 244.438],
                                  [15.005, -121.433],
                                  [319.808, -194.061],
                                ],
                                c: true,
                              },
                            ],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-142.872, -385.685],
                                  [-188.874, -168.126],
                                  [-234.938, 57.81],
                                  [-248.313, 165],
                                  [24.308, 309.061],
                                  [82.75, 376.686],
                                  [256.377, 245.625],
                                  [15.318, -120.496],
                                  [320.433, -194.186],
                                ],
                                c: true,
                              },
                            ],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-143.497, -385.748],
                                  [-188.312, -167.751],
                                  [-234.875, 58.56],
                                  [-248.438, 164.562],
                                  [23.683, 308.624],
                                  [81.563, 376.686],
                                  [257.314, 246.813],
                                  [15.63, -119.621],
                                  [320.996, -194.373],
                                ],
                                c: true,
                              },
                            ],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-144.059, -385.81],
                                  [-187.624, -167.376],
                                  [-234.875, 59.31],
                                  [-248.563, 164.125],
                                  [22.996, 308.186],
                                  [80.438, 376.686],
                                  [258.252, 248],
                                  [15.943, -118.683],
                                  [321.621, -194.623],
                                ],
                                c: true,
                              },
                            ],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-144.684, -385.872],
                                  [-186.999, -166.939],
                                  [-234.875, 59.935],
                                  [-248.625, 163.687],
                                  [22.246, 307.686],
                                  [79.313, 376.686],
                                  [259.189, 249.25],
                                  [16.193, -117.746],
                                  [322.246, -194.873],
                                ],
                                c: true,
                              },
                            ],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.247, -385.935],
                                  [-186.312, -166.501],
                                  [-234.875, 60.622],
                                  [-248.688, 163.187],
                                  [21.434, 307.249],
                                  [78.25, 376.749],
                                  [260.189, 250.438],
                                  [16.505, -116.871],
                                  [322.808, -195.123],
                                ],
                                c: true,
                              },
                            ],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.809, -385.997],
                                  [-185.624, -166.064],
                                  [-234.938, 61.185],
                                  [-248.75, 162.75],
                                  [20.621, 306.749],
                                  [77.188, 376.811],
                                  [261.127, 251.625],
                                  [16.755, -115.996],
                                  [323.433, -195.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-146.309, -386.06],
                                  [-184.874, -165.626],
                                  [-235, 61.747],
                                  [-248.813, 162.25],
                                  [19.746, 306.311],
                                  [76.188, 376.936],
                                  [262.127, 252.813],
                                  [16.943, -115.121],
                                  [323.996, -195.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-146.872, -386.06],
                                  [-184.124, -165.126],
                                  [-235.125, 62.247],
                                  [-248.875, 161.812],
                                  [18.871, 305.811],
                                  [75.25, 377.061],
                                  [263.064, 254],
                                  [17.193, -114.246],
                                  [324.621, -196.123],
                                ],
                                c: true,
                              },
                            ],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-147.372, -386.122],
                                  [-183.374, -164.689],
                                  [-235.25, 62.685],
                                  [-248.875, 161.312],
                                  [17.871, 305.311],
                                  [74.313, 377.186],
                                  [264.064, 255.188],
                                  [17.38, -113.371],
                                  [325.246, -196.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-147.809, -386.122],
                                  [-182.624, -164.189],
                                  [-235.438, 63.122],
                                  [-248.938, 160.875],
                                  [16.934, 304.811],
                                  [73.438, 377.374],
                                  [265.002, 256.375],
                                  [17.63, -112.559],
                                  [325.808, -196.935],
                                ],
                                c: true,
                              },
                            ],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-148.309, -386.122],
                                  [-181.812, -163.689],
                                  [-235.563, 63.497],
                                  [-248.875, 160.375],
                                  [15.871, 304.374],
                                  [72.625, 377.561],
                                  [265.939, 257.5],
                                  [17.818, -111.746],
                                  [326.433, -197.373],
                                ],
                                c: true,
                              },
                            ],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-148.684, -386.122],
                                  [-180.999, -163.189],
                                  [-235.813, 63.81],
                                  [-248.875, 159.937],
                                  [14.871, 303.874],
                                  [71.813, 377.749],
                                  [266.939, 258.625],
                                  [17.943, -110.934],
                                  [327.058, -197.873],
                                ],
                                c: true,
                              },
                            ],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-149.122, -386.122],
                                  [-180.187, -162.689],
                                  [-236, 64.122],
                                  [-248.875, 159.5],
                                  [13.746, 303.374],
                                  [71.063, 377.999],
                                  [267.877, 259.75],
                                  [18.13, -110.121],
                                  [327.621, -198.373],
                                ],
                                c: true,
                              },
                            ],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-149.497, -386.122],
                                  [-179.374, -162.189],
                                  [-236.25, 64.372],
                                  [-248.813, 159.062],
                                  [12.684, 302.874],
                                  [70.376, 378.249],
                                  [268.814, 260.875],
                                  [18.318, -109.309],
                                  [328.246, -198.935],
                                ],
                                c: true,
                              },
                            ],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-149.872, -386.122],
                                  [-178.562, -161.689],
                                  [-236.563, 64.622],
                                  [-248.75, 158.625],
                                  [11.496, 302.436],
                                  [69.688, 378.561],
                                  [269.814, 261.937],
                                  [18.443, -108.559],
                                  [328.871, -199.498],
                                ],
                                c: true,
                              },
                            ],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-150.184, -386.122],
                                  [-177.749, -161.189],
                                  [-236.813, 64.81],
                                  [-248.688, 158.187],
                                  [10.371, 301.936],
                                  [69.126, 378.874],
                                  [270.752, 263],
                                  [18.568, -107.809],
                                  [329.433, -200.06],
                                ],
                                c: true,
                              },
                            ],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-150.434, -386.06],
                                  [-176.937, -160.689],
                                  [-237.125, 64.935],
                                  [-248.625, 157.812],
                                  [9.184, 301.436],
                                  [68.563, 379.186],
                                  [271.689, 264],
                                  [18.693, -107.059],
                                  [330.058, -200.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-150.747, -386.06],
                                  [-176.062, -160.189],
                                  [-237.5, 65.06],
                                  [-248.562, 157.375],
                                  [7.934, 300.999],
                                  [68.063, 379.499],
                                  [272.564, 265],
                                  [18.818, -106.309],
                                  [330.683, -201.373],
                                ],
                                c: true,
                              },
                            ],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-150.934, -385.997],
                                  [-175.249, -159.627],
                                  [-237.813, 65.122],
                                  [-248.437, 157.062],
                                  [6.746, 300.499],
                                  [67.626, 379.874],
                                  [273.502, 266],
                                  [18.943, -105.559],
                                  [331.246, -202.06],
                                ],
                                c: true,
                              },
                            ],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-151.122, -385.935],
                                  [-174.437, -159.189],
                                  [-238.188, 65.185],
                                  [-248.312, 156.687],
                                  [5.496, 300.061],
                                  [67.188, 380.249],
                                  [274.439, 266.937],
                                  [19.005, -104.871],
                                  [331.871, -202.81],
                                ],
                                c: true,
                              },
                            ],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-151.309, -385.872],
                                  [-173.624, -158.689],
                                  [-238.563, 65.185],
                                  [-248.25, 156.375],
                                  [4.184, 299.561],
                                  [66.876, 380.687],
                                  [275.314, 267.812],
                                  [19.13, -104.184],
                                  [332.433, -203.623],
                                ],
                                c: true,
                              },
                            ],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-151.434, -385.81],
                                  [-172.812, -158.189],
                                  [-239, 65.185],
                                  [-248.125, 156.062],
                                  [2.934, 299.124],
                                  [66.563, 381.062],
                                  [276.189, 268.75],
                                  [19.193, -103.496],
                                  [333.058, -204.373],
                                ],
                                c: true,
                              },
                            ],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-151.559, -385.685],
                                  [-172.062, -157.689],
                                  [-239.375, 65.122],
                                  [-247.937, 155.75],
                                  [1.621, 298.624],
                                  [66.376, 381.499],
                                  [277.064, 269.562],
                                  [19.255, -102.871],
                                  [333.621, -205.185],
                                ],
                                c: true,
                              },
                            ],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-151.621, -385.622],
                                  [-171.249, -157.252],
                                  [-239.813, 65.122],
                                  [-247.812, 155.5],
                                  [0.309, 298.186],
                                  [66.188, 381.937],
                                  [277.939, 270.375],
                                  [19.318, -102.246],
                                  [334.183, -206.06],
                                ],
                                c: true,
                              },
                            ],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-151.621, -385.497],
                                  [-170.499, -156.752],
                                  [-240.25, 64.997],
                                  [-247.687, 155.25],
                                  [-1.004, 297.749],
                                  [66.063, 382.437],
                                  [278.752, 271.187],
                                  [19.38, -101.621],
                                  [334.683, -206.935],
                                ],
                                c: true,
                              },
                            ],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-151.621, -385.372],
                                  [-169.749, -156.314],
                                  [-240.688, 64.935],
                                  [-247.5, 155.062],
                                  [-2.378, 297.311],
                                  [66.001, 382.874],
                                  [279.564, 272],
                                  [19.443, -100.996],
                                  [335.183, -207.81],
                                ],
                                c: true,
                              },
                            ],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-151.559, -385.247],
                                  [-169.062, -155.877],
                                  [-241.125, 64.81],
                                  [-247.312, 154.875],
                                  [-3.691, 296.811],
                                  [66.063, 383.374],
                                  [280.377, 272.75],
                                  [19.505, -100.371],
                                  [335.683, -208.685],
                                ],
                                c: true,
                              },
                            ],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-151.496, -385.06],
                                  [-168.374, -155.439],
                                  [-241.625, 64.747],
                                  [-247.187, 154.75],
                                  [-5.066, 296.374],
                                  [66.126, 383.874],
                                  [281.126, 273.5],
                                  [19.568, -99.809],
                                  [336.12, -209.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-151.371, -384.935],
                                  [-167.687, -155.064],
                                  [-242.063, 64.622],
                                  [-247, 154.625],
                                  [-6.378, 295.936],
                                  [66.251, 384.374],
                                  [281.876, 274.249],
                                  [19.63, -99.246],
                                  [336.558, -210.56],
                                ],
                                c: true,
                              },
                            ],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-151.184, -384.747],
                                  [-166.999, -154.627],
                                  [-242.563, 64.435],
                                  [-246.812, 154.5],
                                  [-7.753, 295.499],
                                  [66.438, 384.937],
                                  [282.626, 274.937],
                                  [19.693, -98.746],
                                  [336.995, -211.497],
                                ],
                                c: true,
                              },
                            ],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-150.996, -384.56],
                                  [-166.374, -154.252],
                                  [-243, 64.185],
                                  [-246.625, 154.5],
                                  [-9.128, 294.999],
                                  [66.688, 385.437],
                                  [283.314, 275.624],
                                  [19.755, -98.246],
                                  [337.37, -212.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-150.746, -384.372],
                                  [-165.812, -153.939],
                                  [-243.438, 63.935],
                                  [-246.375, 154.437],
                                  [-10.566, 294.561],
                                  [67.001, 385.999],
                                  [284.001, 276.312],
                                  [19.755, -97.746],
                                  [337.745, -213.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-150.496, -384.122],
                                  [-165.249, -153.564],
                                  [-243.875, 63.622],
                                  [-246.187, 154.5],
                                  [-11.941, 294.124],
                                  [67.376, 386.562],
                                  [284.689, 276.937],
                                  [19.818, -97.246],
                                  [338.058, -214.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 85,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-150.184, -383.872],
                                  [-164.749, -153.252],
                                  [-244.313, 63.31],
                                  [-246, 154.562],
                                  [-13.316, 293.686],
                                  [67.813, 387.124],
                                  [285.314, 277.562],
                                  [19.88, -96.809],
                                  [338.37, -215.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-149.809, -383.685],
                                  [-164.249, -152.939],
                                  [-244.75, 62.935],
                                  [-245.812, 154.687],
                                  [-14.691, 293.249],
                                  [68.376, 387.687],
                                  [285.939, 278.187],
                                  [19.943, -96.371],
                                  [338.683, -216.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-149.434, -383.435],
                                  [-163.812, -152.689],
                                  [-245.188, 62.497],
                                  [-245.562, 154.812],
                                  [-16.066, 292.811],
                                  [68.938, 388.249],
                                  [286.501, 278.812],
                                  [20.068, -95.934],
                                  [338.933, -217.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-148.996, -383.122],
                                  [-163.437, -152.439],
                                  [-245.563, 62.06],
                                  [-245.375, 155],
                                  [-17.441, 292.374],
                                  [69.501, 388.937],
                                  [287.064, 279.374],
                                  [20.13, -95.559],
                                  [339.183, -218.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-148.496, -382.872],
                                  [-163.062, -152.189],
                                  [-245.938, 61.56],
                                  [-245.187, 155.187],
                                  [-18.816, 291.936],
                                  [70.188, 389.687],
                                  [287.626, 279.937],
                                  [20.255, -95.184],
                                  [339.37, -219.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-148.059, -382.56],
                                  [-162.749, -151.939],
                                  [-246.313, 61.06],
                                  [-245, 155.375],
                                  [-20.191, 291.499],
                                  [70.876, 390.437],
                                  [288.126, 280.437],
                                  [20.38, -94.809],
                                  [339.558, -220.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 91,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-147.496, -382.31],
                                  [-162.437, -151.752],
                                  [-246.688, 60.497],
                                  [-244.875, 155.562],
                                  [-21.503, 291.124],
                                  [71.626, 391.249],
                                  [288.626, 280.999],
                                  [20.505, -94.496],
                                  [339.745, -221.497],
                                ],
                                c: true,
                              },
                            ],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-146.934, -381.997],
                                  [-162.187, -151.627],
                                  [-247.063, 59.935],
                                  [-244.812, 155.75],
                                  [-22.816, 290.686],
                                  [72.376, 392.124],
                                  [289.064, 281.499],
                                  [20.693, -94.121],
                                  [339.87, -222.497],
                                ],
                                c: true,
                              },
                            ],
                            t: 93,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-146.371, -381.685],
                                  [-161.937, -151.502],
                                  [-247.375, 59.372],
                                  [-244.75, 155.937],
                                  [-24.191, 290.311],
                                  [73.188, 392.999],
                                  [289.501, 281.937],
                                  [20.818, -93.809],
                                  [339.933, -223.56],
                                ],
                                c: true,
                              },
                            ],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.746, -381.435],
                                  [-161.624, -151.439],
                                  [-247.688, 58.747],
                                  [-244.75, 156.125],
                                  [-25.441, 289.936],
                                  [74.063, 393.874],
                                  [289.939, 282.374],
                                  [21.005, -93.496],
                                  [340.058, -224.56],
                                ],
                                c: true,
                              },
                            ],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.121, -381.122],
                                  [-161.374, -151.377],
                                  [-248, 58.06],
                                  [-244.812, 156.25],
                                  [-26.753, 289.561],
                                  [74.938, 394.874],
                                  [290.314, 282.812],
                                  [21.193, -93.246],
                                  [340.058, -225.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-144.434, -380.81],
                                  [-161.062, -151.377],
                                  [-248.313, 57.435],
                                  [-244.875, 156.437],
                                  [-28.003, 289.186],
                                  [75.813, 395.812],
                                  [290.689, 283.249],
                                  [21.38, -92.996],
                                  [340.12, -226.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-143.746, -380.497],
                                  [-160.812, -151.377],
                                  [-248.625, 56.747],
                                  [-245, 156.562],
                                  [-29.253, 288.811],
                                  [76.751, 396.811],
                                  [291.001, 283.624],
                                  [21.63, -92.746],
                                  [340.12, -227.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 98,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-143.059, -380.185],
                                  [-160.499, -151.439],
                                  [-248.875, 55.997],
                                  [-245.125, 156.75],
                                  [-30.44, 288.436],
                                  [77.688, 397.811],
                                  [291.314, 283.999],
                                  [21.818, -92.496],
                                  [340.058, -228.685],
                                ],
                                c: true,
                              },
                            ],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-142.309, -379.872],
                                  [-160.249, -151.564],
                                  [-249.125, 55.31],
                                  [-245.312, 156.875],
                                  [-31.628, 288.124],
                                  [78.688, 398.874],
                                  [291.626, 284.374],
                                  [22.068, -92.309],
                                  [339.995, -229.685],
                                ],
                                c: true,
                              },
                            ],
                            t: 100,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-141.559, -379.56],
                                  [-159.999, -151.689],
                                  [-249.375, 54.56],
                                  [-245.5, 157.062],
                                  [-32.815, 287.811],
                                  [79.688, 399.936],
                                  [291.876, 284.687],
                                  [22.318, -92.121],
                                  [339.933, -230.685],
                                ],
                                c: true,
                              },
                            ],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-140.809, -379.247],
                                  [-159.749, -151.814],
                                  [-249.625, 53.81],
                                  [-245.75, 157.187],
                                  [-33.94, 287.499],
                                  [80.751, 400.999],
                                  [292.126, 284.999],
                                  [22.63, -91.934],
                                  [339.808, -231.685],
                                ],
                                c: true,
                              },
                            ],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-140.059, -378.997],
                                  [-159.562, -152.002],
                                  [-249.813, 53.06],
                                  [-246.062, 157.375],
                                  [-35.003, 287.186],
                                  [81.751, 402.061],
                                  [292.376, 285.311],
                                  [22.88, -91.809],
                                  [339.683, -232.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-139.246, -378.685],
                                  [-159.374, -152.252],
                                  [-250, 52.31],
                                  [-246.375, 157.5],
                                  [-36.065, 286.874],
                                  [82.813, 403.124],
                                  [292.563, 285.561],
                                  [23.193, -91.621],
                                  [339.495, -233.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 104,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-138.434, -378.372],
                                  [-159.187, -152.502],
                                  [-250.188, 51.497],
                                  [-246.75, 157.687],
                                  [-37.128, 286.624],
                                  [83.876, 404.186],
                                  [292.751, 285.811],
                                  [23.505, -91.559],
                                  [339.308, -234.56],
                                ],
                                c: true,
                              },
                            ],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-137.621, -378.122],
                                  [-158.999, -152.814],
                                  [-250.313, 50.747],
                                  [-247.125, 157.875],
                                  [-38.128, 286.311],
                                  [84.938, 405.249],
                                  [292.938, 286.061],
                                  [23.818, -91.434],
                                  [339.058, -235.497],
                                ],
                                c: true,
                              },
                            ],
                            t: 106,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-136.746, -377.872],
                                  [-158.874, -153.127],
                                  [-250.5, 49.997],
                                  [-247.562, 158],
                                  [-39.065, 286.061],
                                  [86.063, 406.311],
                                  [293.063, 286.311],
                                  [24.193, -91.371],
                                  [338.87, -236.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-135.934, -377.56],
                                  [-158.749, -153.502],
                                  [-250.625, 49.185],
                                  [-248, 158.187],
                                  [-39.94, 285.811],
                                  [87.188, 407.374],
                                  [293.188, 286.499],
                                  [24.568, -91.309],
                                  [338.558, -237.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-135.059, -377.31],
                                  [-158.625, -153.877],
                                  [-250.688, 48.435],
                                  [-248.5, 158.375],
                                  [-40.815, 285.624],
                                  [88.25, 408.436],
                                  [293.313, 286.686],
                                  [24.943, -91.246],
                                  [338.245, -238.31],
                                ],
                                c: true,
                              },
                            ],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-134.246, -377.122],
                                  [-158.562, -154.252],
                                  [-250.813, 47.685],
                                  [-249, 158.5],
                                  [-41.69, 285.374],
                                  [89.376, 409.436],
                                  [293.376, 286.874],
                                  [25.318, -91.246],
                                  [337.933, -239.185],
                                ],
                                c: true,
                              },
                            ],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-133.371, -376.873],
                                  [-158.5, -154.689],
                                  [-250.875, 46.935],
                                  [-249.5, 158.687],
                                  [-42.44, 285.186],
                                  [90.5, 410.499],
                                  [293.438, 286.999],
                                  [25.693, -91.309],
                                  [337.62, -240.06],
                                ],
                                c: true,
                              },
                            ],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-132.496, -376.685],
                                  [-158.437, -155.127],
                                  [-250.938, 46.122],
                                  [-250.125, 158.875],
                                  [-43.19, 284.999],
                                  [91.625, 411.499],
                                  [293.501, 287.124],
                                  [26.13, -91.309],
                                  [337.245, -240.872],
                                ],
                                c: true,
                              },
                            ],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.684, -376.435],
                                  [-158.437, -155.627],
                                  [-251, 45.435],
                                  [-250.687, 159.062],
                                  [-43.878, 284.812],
                                  [92.75, 412.499],
                                  [293.501, 287.249],
                                  [26.568, -91.371],
                                  [336.808, -241.747],
                                ],
                                c: true,
                              },
                            ],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-130.809, -376.248],
                                  [-158.5, -156.127],
                                  [-251, 44.685],
                                  [-251.312, 159.187],
                                  [-44.565, 284.687],
                                  [93.875, 413.436],
                                  [293.563, 287.374],
                                  [27.005, -91.496],
                                  [336.433, -242.559],
                                ],
                                c: true,
                              },
                            ],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-129.996, -376.123],
                                  [-158.562, -156.627],
                                  [-251, 43.935],
                                  [-252, 159.375],
                                  [-45.19, 284.562],
                                  [95, 414.436],
                                  [293.563, 287.499],
                                  [27.443, -91.621],
                                  [335.933, -243.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-129.121, -375.935],
                                  [-158.625, -157.189],
                                  [-251, 43.247],
                                  [-252.687, 159.562],
                                  [-45.69, 284.437],
                                  [96.125, 415.373],
                                  [293.501, 287.561],
                                  [27.943, -91.746],
                                  [335.495, -244.122],
                                ],
                                c: true,
                              },
                            ],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-128.309, -375.81],
                                  [-158.75, -157.752],
                                  [-251, 42.56],
                                  [-253.374, 159.75],
                                  [-46.19, 284.312],
                                  [97.188, 416.248],
                                  [293.501, 287.624],
                                  [28.443, -91.934],
                                  [334.995, -244.872],
                                ],
                                c: true,
                              },
                            ],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-127.496, -375.748],
                                  [-158.875, -158.314],
                                  [-250.938, 41.935],
                                  [-254.124, 159.875],
                                  [-46.69, 284.249],
                                  [98.313, 417.123],
                                  [293.438, 287.686],
                                  [28.943, -92.121],
                                  [334.495, -245.559],
                                ],
                                c: true,
                              },
                            ],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-126.684, -375.623],
                                  [-159.062, -158.877],
                                  [-250.876, 41.247],
                                  [-254.874, 160.062],
                                  [-47.065, 284.124],
                                  [99.438, 417.936],
                                  [293.376, 287.749],
                                  [29.505, -92.371],
                                  [333.933, -246.309],
                                ],
                                c: true,
                              },
                            ],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-125.872, -375.56],
                                  [-159.25, -159.502],
                                  [-250.813, 40.622],
                                  [-255.624, 160.187],
                                  [-47.378, 284.062],
                                  [100.5, 418.748],
                                  [293.313, 287.749],
                                  [30.005, -92.621],
                                  [333.37, -246.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-125.122, -375.498],
                                  [-159.5, -160.064],
                                  [-250.688, 40.06],
                                  [-256.437, 160.375],
                                  [-47.69, 283.999],
                                  [101.563, 419.498],
                                  [293.251, 287.749],
                                  [30.568, -92.934],
                                  [332.808, -247.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-124.372, -375.498],
                                  [-159.75, -160.689],
                                  [-250.626, 39.497],
                                  [-257.249, 160.5],
                                  [-47.94, 283.999],
                                  [102.625, 420.248],
                                  [293.188, 287.811],
                                  [31.13, -93.309],
                                  [332.183, -248.247],
                                ],
                                c: true,
                              },
                            ],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-123.622, -375.498],
                                  [-160, -161.252],
                                  [-250.438, 38.935],
                                  [-258.062, 160.625],
                                  [-48.065, 283.999],
                                  [103.688, 420.936],
                                  [293.063, 287.811],
                                  [31.755, -93.684],
                                  [331.558, -248.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-122.872, -375.498],
                                  [-160.375, -161.877],
                                  [-250.313, 38.435],
                                  [-258.937, 160.75],
                                  [-48.19, 283.999],
                                  [104.688, 421.561],
                                  [292.938, 287.749],
                                  [32.38, -94.121],
                                  [330.933, -249.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-122.184, -375.56],
                                  [-160.687, -162.502],
                                  [-250.188, 37.935],
                                  [-259.812, 160.874],
                                  [-48.253, 283.999],
                                  [105.75, 422.186],
                                  [292.813, 287.749],
                                  [33.005, -94.559],
                                  [330.246, -249.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-121.497, -375.623],
                                  [-161.062, -163.064],
                                  [-250.001, 37.497],
                                  [-260.749, 160.937],
                                  [-48.253, 283.999],
                                  [106.75, 422.748],
                                  [292.688, 287.749],
                                  [33.568, -95.121],
                                  [329.621, -250.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-120.872, -375.748],
                                  [-161.437, -163.627],
                                  [-249.813, 37.06],
                                  [-261.624, 161],
                                  [-48.19, 284.062],
                                  [107.688, 423.248],
                                  [292.563, 287.686],
                                  [34.13, -95.621],
                                  [328.871, -250.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 127,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-120.247, -375.873],
                                  [-161.875, -164.189],
                                  [-249.563, 36.685],
                                  [-262.562, 161.062],
                                  [-48.065, 284.124],
                                  [108.688, 423.748],
                                  [292.438, 287.624],
                                  [34.693, -96.246],
                                  [328.183, -251.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-119.684, -375.998],
                                  [-162.375, -164.752],
                                  [-249.376, 36.31],
                                  [-263.499, 161.124],
                                  [-47.94, 284.187],
                                  [109.563, 424.186],
                                  [292.313, 287.624],
                                  [35.193, -96.871],
                                  [327.433, -251.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 129,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-119.122, -376.185],
                                  [-162.812, -165.252],
                                  [-249.126, 36.06],
                                  [-264.437, 161.124],
                                  [-47.69, 284.312],
                                  [110.5, 424.686],
                                  [292.188, 287.561],
                                  [35.63, -97.496],
                                  [326.683, -252.184],
                                ],
                                c: true,
                              },
                            ],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-118.559, -376.373],
                                  [-163.312, -165.752],
                                  [-248.876, 35.747],
                                  [-265.437, 161.124],
                                  [-47.378, 284.437],
                                  [111.375, 425.123],
                                  [292.001, 287.499],
                                  [36.13, -98.246],
                                  [325.933, -252.559],
                                ],
                                c: true,
                              },
                            ],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-118.059, -376.623],
                                  [-163.875, -166.252],
                                  [-248.626, 35.56],
                                  [-266.374, 161.062],
                                  [-47.003, 284.562],
                                  [112.25, 425.498],
                                  [291.876, 287.436],
                                  [36.505, -98.933],
                                  [325.183, -252.872],
                                ],
                                c: true,
                              },
                            ],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-117.622, -376.873],
                                  [-164.375, -166.689],
                                  [-248.313, 35.372],
                                  [-267.374, 160.937],
                                  [-46.628, 284.687],
                                  [113.125, 425.935],
                                  [291.751, 287.311],
                                  [36.943, -99.746],
                                  [324.371, -253.122],
                                ],
                                c: true,
                              },
                            ],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-117.184, -377.185],
                                  [-164.875, -167.189],
                                  [-248.063, 35.185],
                                  [-268.374, 160.812],
                                  [-46.128, 284.812],
                                  [113.938, 426.31],
                                  [291.563, 287.249],
                                  [37.318, -100.558],
                                  [323.558, -253.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 134,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-116.809, -377.498],
                                  [-165.375, -167.752],
                                  [-247.751, 35.122],
                                  [-269.374, 160.687],
                                  [-45.565, 284.999],
                                  [114.75, 426.685],
                                  [291.438, 287.186],
                                  [37.63, -101.371],
                                  [322.746, -253.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-116.497, -377.873],
                                  [-165.875, -168.252],
                                  [-247.438, 35.06],
                                  [-270.311, 160.5],
                                  [-45.003, 285.187],
                                  [115.562, 427.06],
                                  [291.313, 287.061],
                                  [37.943, -102.246],
                                  [321.996, -253.997],
                                ],
                                c: true,
                              },
                            ],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-116.184, -378.248],
                                  [-166.312, -168.814],
                                  [-247.126, 35.06],
                                  [-271.311, 160.25],
                                  [-44.315, 285.374],
                                  [116.312, 427.373],
                                  [291.189, 286.999],
                                  [38.193, -103.183],
                                  [321.183, -254.247],
                                ],
                                c: true,
                              },
                            ],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.934, -378.623],
                                  [-166.75, -169.377],
                                  [-246.751, 35.06],
                                  [-272.311, 160],
                                  [-43.628, 285.562],
                                  [117.062, 427.748],
                                  [291.064, 286.936],
                                  [38.443, -104.121],
                                  [320.371, -254.559],
                                ],
                                c: true,
                              },
                            ],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.747, -379.06],
                                  [-167.187, -169.939],
                                  [-246.438, 35.122],
                                  [-273.249, 159.75],
                                  [-42.815, 285.812],
                                  [117.75, 427.998],
                                  [290.938, 286.811],
                                  [38.693, -105.058],
                                  [319.558, -254.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 139,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.622, -379.56],
                                  [-167.625, -170.502],
                                  [-246.063, 35.247],
                                  [-274.249, 159.437],
                                  [-42.003, 286.124],
                                  [118.437, 428.31],
                                  [290.814, 286.749],
                                  [38.818, -106.058],
                                  [318.808, -255.122],
                                ],
                                c: true,
                              },
                            ],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.497, -380.06],
                                  [-168.063, -171.064],
                                  [-245.688, 35.435],
                                  [-275.186, 159.125],
                                  [-41.19, 286.374],
                                  [119.125, 428.56],
                                  [290.751, 286.624],
                                  [39.005, -107.058],
                                  [317.996, -255.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.434, -380.623],
                                  [-168.438, -171.689],
                                  [-245.313, 35.622],
                                  [-276.124, 158.812],
                                  [-40.315, 286.749],
                                  [119.812, 428.81],
                                  [290.626, 286.561],
                                  [39.13, -108.121],
                                  [317.183, -255.622],
                                ],
                                c: true,
                              },
                            ],
                            t: 142,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.434, -381.185],
                                  [-168.813, -172.252],
                                  [-245.001, 35.81],
                                  [-277.061, 158.437],
                                  [-39.378, 287.124],
                                  [120.437, 429.06],
                                  [290.564, 286.436],
                                  [39.193, -109.183],
                                  [316.371, -255.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.434, -381.81],
                                  [-169.188, -172.877],
                                  [-244.626, 35.997],
                                  [-277.999, 158.062],
                                  [-38.441, 287.499],
                                  [121, 429.248],
                                  [290.564, 286.374],
                                  [39.255, -110.246],
                                  [315.558, -256.184],
                                ],
                                c: true,
                              },
                            ],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.434, -382.435],
                                  [-169.5, -173.439],
                                  [-244.313, 36.247],
                                  [-278.936, 157.625],
                                  [-37.441, 287.937],
                                  [121.625, 429.435],
                                  [290.501, 286.249],
                                  [39.255, -111.371],
                                  [314.809, -256.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.434, -383.123],
                                  [-169.813, -174.064],
                                  [-243.938, 36.497],
                                  [-279.811, 157.187],
                                  [-36.441, 288.374],
                                  [122.187, 429.623],
                                  [290.439, 286.124],
                                  [39.255, -112.496],
                                  [313.996, -256.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.434, -383.81],
                                  [-170.125, -174.626],
                                  [-243.626, 36.747],
                                  [-280.686, 156.75],
                                  [-35.441, 288.812],
                                  [122.687, 429.748],
                                  [290.439, 286.062],
                                  [39.193, -113.621],
                                  [313.184, -256.997],
                                ],
                                c: true,
                              },
                            ],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.434, -384.56],
                                  [-170.438, -175.251],
                                  [-243.313, 36.997],
                                  [-281.499, 156.312],
                                  [-34.378, 289.312],
                                  [123.25, 429.873],
                                  [290.439, 285.937],
                                  [39.068, -114.808],
                                  [312.371, -257.247],
                                ],
                                c: true,
                              },
                            ],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.497, -385.373],
                                  [-170.75, -175.814],
                                  [-243.063, 37.31],
                                  [-282.311, 155.812],
                                  [-33.316, 289.812],
                                  [123.75, 429.998],
                                  [290.501, 285.812],
                                  [39.005, -115.996],
                                  [311.559, -257.497],
                                ],
                                c: true,
                              },
                            ],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.559, -386.185],
                                  [-171, -176.439],
                                  [-242.751, 37.622],
                                  [-283.124, 155.312],
                                  [-32.191, 290.312],
                                  [124.187, 430.123],
                                  [290.501, 285.687],
                                  [38.818, -117.183],
                                  [310.809, -257.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.622, -386.998],
                                  [-171.25, -177.001],
                                  [-242.501, 37.935],
                                  [-283.874, 154.812],
                                  [-31.066, 290.874],
                                  [124.687, 430.185],
                                  [290.564, 285.624],
                                  [38.63, -118.371],
                                  [309.996, -257.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.684, -387.873],
                                  [-171.438, -177.564],
                                  [-242.251, 38.247],
                                  [-284.624, 154.25],
                                  [-29.941, 291.374],
                                  [125.125, 430.248],
                                  [290.626, 285.499],
                                  [38.38, -119.621],
                                  [309.184, -258.184],
                                ],
                                c: true,
                              },
                            ],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.809, -388.81],
                                  [-171.688, -178.126],
                                  [-242.001, 38.622],
                                  [-285.311, 153.75],
                                  [-28.816, 291.937],
                                  [125.562, 430.31],
                                  [290.751, 285.374],
                                  [38.13, -120.871],
                                  [308.434, -258.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-115.934, -389.748],
                                  [-171.875, -178.689],
                                  [-241.813, 38.997],
                                  [-285.999, 153.187],
                                  [-27.628, 292.499],
                                  [125.937, 430.373],
                                  [290.814, 285.187],
                                  [37.88, -122.121],
                                  [307.621, -258.621],
                                ],
                                c: true,
                              },
                            ],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-116.059, -390.685],
                                  [-172, -179.251],
                                  [-241.626, 39.372],
                                  [-286.624, 152.625],
                                  [-26.441, 293.062],
                                  [126.312, 430.373],
                                  [290.939, 285.062],
                                  [37.505, -123.371],
                                  [306.871, -258.871],
                                ],
                                c: true,
                              },
                            ],
                            t: 155,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-116.247, -391.623],
                                  [-172.188, -179.751],
                                  [-241.438, 39.747],
                                  [-287.249, 152],
                                  [-25.316, 293.624],
                                  [126.687, 430.373],
                                  [291.126, 284.937],
                                  [37.193, -124.621],
                                  [306.121, -259.059],
                                ],
                                c: true,
                              },
                            ],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-116.434, -392.623],
                                  [-172.313, -180.314],
                                  [-241.313, 40.185],
                                  [-287.811, 151.437],
                                  [-24.128, 294.249],
                                  [127, 430.373],
                                  [291.251, 284.812],
                                  [36.755, -125.933],
                                  [305.309, -259.246],
                                ],
                                c: true,
                              },
                            ],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-116.622, -393.623],
                                  [-172.375, -180.814],
                                  [-241.188, 40.56],
                                  [-288.374, 150.875],
                                  [-22.941, 294.812],
                                  [127.375, 430.31],
                                  [291.439, 284.624],
                                  [36.38, -127.183],
                                  [304.559, -259.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-116.872, -394.685],
                                  [-172.5, -181.314],
                                  [-241.063, 40.997],
                                  [-288.874, 150.25],
                                  [-21.691, 295.437],
                                  [127.687, 430.248],
                                  [291.626, 284.499],
                                  [35.88, -128.496],
                                  [303.809, -259.621],
                                ],
                                c: true,
                              },
                            ],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-117.122, -395.685],
                                  [-172.563, -181.751],
                                  [-241.001, 41.435],
                                  [-289.311, 149.625],
                                  [-20.503, 295.999],
                                  [128, 430.185],
                                  [291.876, 284.312],
                                  [35.38, -129.745],
                                  [303.121, -259.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-117.434, -396.748],
                                  [-172.625, -182.189],
                                  [-240.938, 41.935],
                                  [-289.749, 149],
                                  [-19.316, 296.624],
                                  [128.25, 430.122],
                                  [292.126, 284.124],
                                  [34.88, -131.058],
                                  [302.371, -259.996],
                                ],
                                c: true,
                              },
                            ],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-117.747, -397.81],
                                  [-172.625, -182.626],
                                  [-240.876, 42.372],
                                  [-290.124, 148.375],
                                  [-18.191, 297.187],
                                  [128.5, 430.06],
                                  [292.376, 283.937],
                                  [34.318, -132.37],
                                  [301.684, -260.184],
                                ],
                                c: true,
                              },
                            ],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-118.122, -398.873],
                                  [-172.625, -183.064],
                                  [-240.876, 42.81],
                                  [-290.436, 147.812],
                                  [-17.004, 297.812],
                                  [128.75, 429.935],
                                  [292.626, 283.749],
                                  [33.693, -133.683],
                                  [300.934, -260.309],
                                ],
                                c: true,
                              },
                            ],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-118.497, -399.935],
                                  [-172.625, -183.439],
                                  [-240.938, 43.31],
                                  [-290.749, 147.188],
                                  [-15.816, 298.374],
                                  [129, 429.81],
                                  [292.939, 283.562],
                                  [33.068, -134.995],
                                  [300.247, -260.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-118.872, -401.06],
                                  [-172.563, -183.814],
                                  [-240.938, 43.81],
                                  [-290.999, 146.5],
                                  [-14.691, 298.999],
                                  [129.249, 429.685],
                                  [293.251, 283.374],
                                  [32.443, -136.245],
                                  [299.622, -260.621],
                                ],
                                c: true,
                              },
                            ],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-119.309, -402.122],
                                  [-172.5, -184.189],
                                  [-241.063, 44.247],
                                  [-291.186, 145.875],
                                  [-13.566, 299.562],
                                  [129.437, 429.56],
                                  [293.626, 283.187],
                                  [31.755, -137.558],
                                  [298.934, -260.746],
                                ],
                                c: true,
                              },
                            ],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-119.809, -403.185],
                                  [-172.438, -184.501],
                                  [-241.188, 44.747],
                                  [-291.311, 145.25],
                                  [-12.441, 300.124],
                                  [129.624, 429.435],
                                  [294.001, 282.937],
                                  [31.005, -138.87],
                                  [298.309, -260.871],
                                ],
                                c: true,
                              },
                            ],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-120.247, -404.31],
                                  [-172.375, -184.751],
                                  [-241.313, 45.247],
                                  [-291.436, 144.625],
                                  [-11.379, 300.687],
                                  [129.812, 429.247],
                                  [294.376, 282.687],
                                  [30.255, -140.183],
                                  [297.684, -260.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-120.809, -405.372],
                                  [-172.25, -185.064],
                                  [-241.501, 45.684],
                                  [-291.499, 144],
                                  [-10.316, 301.249],
                                  [129.999, 429.122],
                                  [294.814, 282.499],
                                  [29.505, -141.433],
                                  [297.122, -261.059],
                                ],
                                c: true,
                              },
                            ],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-121.372, -406.435],
                                  [-172.063, -185.314],
                                  [-241.688, 46.122],
                                  [-291.499, 143.375],
                                  [-9.254, 301.811],
                                  [130.187, 428.935],
                                  [295.251, 282.249],
                                  [28.693, -142.745],
                                  [296.559, -261.184],
                                ],
                                c: true,
                              },
                            ],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-121.934, -407.497],
                                  [-171.938, -185.501],
                                  [-241.938, 46.622],
                                  [-291.436, 142.75],
                                  [-8.254, 302.374],
                                  [130.312, 428.748],
                                  [295.689, 281.937],
                                  [27.818, -143.995],
                                  [295.997, -261.246],
                                ],
                                c: true,
                              },
                            ],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-122.559, -408.56],
                                  [-171.75, -185.689],
                                  [-242.251, 47.059],
                                  [-291.374, 142.188],
                                  [-7.254, 302.936],
                                  [130.437, 428.56],
                                  [296.189, 281.687],
                                  [27.005, -145.245],
                                  [295.497, -261.309],
                                ],
                                c: true,
                              },
                            ],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-123.184, -409.622],
                                  [-171.5, -185.876],
                                  [-242.563, 47.497],
                                  [-291.249, 141.563],
                                  [-6.316, 303.436],
                                  [130.624, 428.372],
                                  [296.689, 281.437],
                                  [26.068, -146.495],
                                  [294.997, -261.371],
                                ],
                                c: true,
                              },
                            ],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-123.872, -410.685],
                                  [-171.313, -186.001],
                                  [-242.938, 47.872],
                                  [-291.061, 140.938],
                                  [-5.441, 303.936],
                                  [130.749, 428.122],
                                  [297.251, 281.124],
                                  [25.193, -147.745],
                                  [294.497, -261.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-124.559, -411.685],
                                  [-171.063, -186.126],
                                  [-243.313, 48.247],
                                  [-290.811, 140.375],
                                  [-4.566, 304.436],
                                  [130.874, 427.935],
                                  [297.814, 280.812],
                                  [24.255, -148.995],
                                  [293.997, -261.496],
                                ],
                                c: true,
                              },
                            ],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-125.309, -412.685],
                                  [-170.813, -186.189],
                                  [-243.75, 48.622],
                                  [-290.499, 139.75],
                                  [-3.754, 304.936],
                                  [130.999, 427.685],
                                  [298.376, 280.499],
                                  [23.255, -150.245],
                                  [293.497, -261.559],
                                ],
                                c: true,
                              },
                            ],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-126.059, -413.622],
                                  [-170.501, -186.251],
                                  [-244.25, 48.997],
                                  [-290.124, 139.188],
                                  [-3.004, 305.374],
                                  [131.124, 427.498],
                                  [298.939, 280.124],
                                  [22.255, -151.433],
                                  [292.997, -261.621],
                                ],
                                c: true,
                              },
                            ],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-126.872, -414.622],
                                  [-170.188, -186.251],
                                  [-244.75, 49.309],
                                  [-289.749, 138.625],
                                  [-2.316, 305.811],
                                  [131.187, 427.248],
                                  [299.439, 279.687],
                                  [21.255, -152.62],
                                  [292.559, -261.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-127.684, -415.497],
                                  [-169.876, -186.251],
                                  [-245.313, 49.559],
                                  [-289.311, 138.063],
                                  [-1.629, 306.249],
                                  [131.312, 426.998],
                                  [299.939, 279.249],
                                  [20.255, -153.808],
                                  [292.059, -261.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 179,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-128.497, -416.435],
                                  [-169.501, -186.189],
                                  [-245.938, 49.809],
                                  [-288.811, 137.501],
                                  [-1.004, 306.686],
                                  [131.437, 426.81],
                                  [300.376, 278.75],
                                  [19.193, -154.995],
                                  [291.559, -261.746],
                                ],
                                c: true,
                              },
                            ],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-129.372, -417.247],
                                  [-169.188, -186.126],
                                  [-246.563, 49.997],
                                  [-288.249, 137.001],
                                  [-0.379, 307.124],
                                  [131.499, 426.56],
                                  [300.751, 278.187],
                                  [18.068, -156.12],
                                  [291.059, -261.746],
                                ],
                                c: true,
                              },
                            ],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-130.247, -418.06],
                                  [-168.813, -186.064],
                                  [-247.25, 50.122],
                                  [-287.624, 136.438],
                                  [0.246, 307.561],
                                  [131.624, 426.31],
                                  [301.126, 277.625],
                                  [17.005, -157.307],
                                  [290.622, -261.746],
                                ],
                                c: true,
                              },
                            ],
                            t: 182,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-131.184, -418.872],
                                  [-168.376, -185.876],
                                  [-247.938, 50.184],
                                  [-286.999, 135.938],
                                  [0.808, 307.999],
                                  [131.687, 426.06],
                                  [301.501, 277],
                                  [15.88, -158.37],
                                  [290.122, -261.746],
                                ],
                                c: true,
                              },
                            ],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-132.059, -419.622],
                                  [-168.001, -185.751],
                                  [-248.688, 50.247],
                                  [-286.311, 135.438],
                                  [1.371, 308.436],
                                  [131.812, 425.81],
                                  [301.751, 276.312],
                                  [14.755, -159.495],
                                  [289.684, -261.746],
                                ],
                                c: true,
                              },
                            ],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-132.934, -420.372],
                                  [-167.563, -185.564],
                                  [-249.438, 50.247],
                                  [-285.561, 135.001],
                                  [1.871, 308.874],
                                  [131.874, 425.56],
                                  [302.064, 275.625],
                                  [13.63, -160.557],
                                  [289.184, -261.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-133.747, -421.059],
                                  [-167.126, -185.314],
                                  [-250.25, 50.184],
                                  [-284.749, 134.501],
                                  [2.371, 309.311],
                                  [132, 425.31],
                                  [302.251, 274.937],
                                  [12.505, -161.62],
                                  [288.747, -261.684],
                                ],
                                c: true,
                              },
                            ],
                            t: 186,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-134.622, -421.747],
                                  [-166.626, -185.064],
                                  [-251, 50.059],
                                  [-283.936, 134.063],
                                  [2.871, 309.686],
                                  [132.124, 425.06],
                                  [302.439, 274.187],
                                  [11.38, -162.682],
                                  [288.31, -261.621],
                                ],
                                c: true,
                              },
                            ],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-135.372, -422.372],
                                  [-166.188, -184.752],
                                  [-251.813, 49.872],
                                  [-283.061, 133.626],
                                  [3.371, 310.124],
                                  [132.249, 424.81],
                                  [302.564, 273.375],
                                  [10.193, -163.745],
                                  [287.872, -261.559],
                                ],
                                c: true,
                              },
                            ],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-136.122, -423.059],
                                  [-165.688, -184.439],
                                  [-252.688, 49.684],
                                  [-282.124, 133.251],
                                  [3.808, 310.561],
                                  [132.375, 424.56],
                                  [302.689, 272.562],
                                  [9.068, -164.745],
                                  [287.435, -261.434],
                                ],
                                c: true,
                              },
                            ],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-136.872, -423.622],
                                  [-165.188, -184.127],
                                  [-253.563, 49.434],
                                  [-281.124, 132.813],
                                  [4.183, 310.936],
                                  [132.5, 424.31],
                                  [302.751, 271.687],
                                  [7.943, -165.745],
                                  [286.997, -261.309],
                                ],
                                c: true,
                              },
                            ],
                            t: 190,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-137.559, -424.184],
                                  [-164.688, -183.752],
                                  [-254.375, 49.122],
                                  [-280.124, 132.501],
                                  [4.621, 311.311],
                                  [132.625, 424.06],
                                  [302.814, 270.812],
                                  [6.818, -166.745],
                                  [286.622, -261.184],
                                ],
                                c: true,
                              },
                            ],
                            t: 191,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-138.247, -424.747],
                                  [-164.126, -183.314],
                                  [-255.313, 48.809],
                                  [-279.061, 132.126],
                                  [4.996, 311.749],
                                  [132.812, 423.81],
                                  [302.814, 269.937],
                                  [5.693, -167.682],
                                  [286.185, -261.059],
                                ],
                                c: true,
                              },
                            ],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-138.934, -425.309],
                                  [-163.626, -182.939],
                                  [-256.188, 48.435],
                                  [-277.999, 131.813],
                                  [5.308, 312.123],
                                  [133, 423.56],
                                  [302.751, 269],
                                  [4.568, -168.62],
                                  [285.81, -260.934],
                                ],
                                c: true,
                              },
                            ],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-139.56, -425.747],
                                  [-163.126, -182.502],
                                  [-257.063, 48.06],
                                  [-276.937, 131.563],
                                  [5.683, 312.498],
                                  [133.187, 423.31],
                                  [302.626, 268],
                                  [3.443, -169.557],
                                  [285.435, -260.746],
                                ],
                                c: true,
                              },
                            ],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-140.122, -426.247],
                                  [-162.563, -182.002],
                                  [-257.938, 47.622],
                                  [-275.812, 131.313],
                                  [5.996, 312.873],
                                  [133.375, 422.998],
                                  [302.501, 267.062],
                                  [2.318, -170.432],
                                  [285.06, -260.496],
                                ],
                                c: true,
                              },
                            ],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-140.685, -426.684],
                                  [-162.063, -181.564],
                                  [-258.875, 47.122],
                                  [-274.624, 131.063],
                                  [6.308, 313.186],
                                  [133.625, 422.748],
                                  [302.376, 266.062],
                                  [1.193, -171.307],
                                  [284.747, -260.309],
                                ],
                                c: true,
                              },
                            ],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-141.247, -427.059],
                                  [-161.501, -181.064],
                                  [-259.75, 46.622],
                                  [-273.499, 130.876],
                                  [6.558, 313.561],
                                  [133.812, 422.498],
                                  [302.126, 265],
                                  [0.131, -172.182],
                                  [284.372, -260.059],
                                ],
                                c: true,
                              },
                            ],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-141.747, -427.434],
                                  [-161.001, -180.564],
                                  [-260.687, 46.06],
                                  [-272.249, 130.688],
                                  [6.871, 313.936],
                                  [134.062, 422.248],
                                  [301.876, 264],
                                  [-0.994, -172.994],
                                  [284.06, -259.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-142.247, -427.809],
                                  [-160.501, -180.064],
                                  [-261.562, 45.497],
                                  [-271.062, 130.501],
                                  [7.121, 314.248],
                                  [134.375, 421.935],
                                  [301.626, 262.937],
                                  [-2.057, -173.807],
                                  [283.747, -259.496],
                                ],
                                c: true,
                              },
                            ],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-142.685, -428.122],
                                  [-160.001, -179.502],
                                  [-262.437, 44.935],
                                  [-269.812, 130.376],
                                  [7.371, 314.623],
                                  [134.625, 421.686],
                                  [301.314, 261.875],
                                  [-3.119, -174.619],
                                  [283.497, -259.184],
                                ],
                                c: true,
                              },
                            ],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-143.122, -428.434],
                                  [-159.501, -178.939],
                                  [-263.375, 44.31],
                                  [-268.562, 130.251],
                                  [7.558, 314.936],
                                  [134.937, 421.373],
                                  [300.938, 260.75],
                                  [-4.182, -175.369],
                                  [283.185, -258.871],
                                ],
                                c: true,
                              },
                            ],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-143.497, -428.684],
                                  [-159.001, -178.377],
                                  [-264.25, 43.622],
                                  [-267.312, 130.188],
                                  [7.808, 315.248],
                                  [135.187, 421.123],
                                  [300.501, 259.687],
                                  [-5.182, -176.119],
                                  [282.935, -258.497],
                                ],
                                c: true,
                              },
                            ],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-143.872, -428.871],
                                  [-158.563, -177.814],
                                  [-265.125, 42.935],
                                  [-265.999, 130.126],
                                  [7.996, 315.561],
                                  [135.5, 420.811],
                                  [300.064, 258.562],
                                  [-6.182, -176.807],
                                  [282.685, -258.122],
                                ],
                                c: true,
                              },
                            ],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-144.185, -429.121],
                                  [-158.063, -177.252],
                                  [-265.937, 42.247],
                                  [-264.749, 130.126],
                                  [8.183, 315.873],
                                  [135.875, 420.561],
                                  [299.626, 257.437],
                                  [-7.182, -177.494],
                                  [282.497, -257.747],
                                ],
                                c: true,
                              },
                            ],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-144.497, -429.246],
                                  [-157.626, -176.627],
                                  [-266.812, 41.56],
                                  [-263.437, 130.126],
                                  [8.371, 316.186],
                                  [136.187, 420.248],
                                  [299.064, 256.312],
                                  [-8.182, -178.119],
                                  [282.31, -257.309],
                                ],
                                c: true,
                              },
                            ],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-144.747, -429.371],
                                  [-157.251, -176.064],
                                  [-267.625, 40.81],
                                  [-262.124, 130.126],
                                  [8.496, 316.498],
                                  [136.562, 419.998],
                                  [298.501, 255.187],
                                  [-9.119, -178.744],
                                  [282.122, -256.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-144.997, -429.496],
                                  [-156.813, -175.439],
                                  [-268.437, 39.997],
                                  [-260.812, 130.188],
                                  [8.683, 316.748],
                                  [136.937, 419.686],
                                  [297.938, 254],
                                  [-10.057, -179.369],
                                  [281.935, -256.372],
                                ],
                                c: true,
                              },
                            ],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.247, -429.559],
                                  [-156.438, -174.814],
                                  [-269.187, 39.247],
                                  [-259.5, 130.251],
                                  [8.808, 317.061],
                                  [137.312, 419.436],
                                  [297.251, 252.875],
                                  [-10.931, -179.932],
                                  [281.81, -255.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.435, -429.621],
                                  [-156.126, -174.19],
                                  [-270, 38.435],
                                  [-258.25, 130.376],
                                  [8.933, 317.311],
                                  [137.687, 419.123],
                                  [296.626, 251.75],
                                  [-11.806, -180.494],
                                  [281.685, -255.309],
                                ],
                                c: true,
                              },
                            ],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.56, -429.684],
                                  [-155.813, -173.565],
                                  [-270.75, 37.622],
                                  [-256.937, 130.501],
                                  [9.058, 317.561],
                                  [138.125, 418.811],
                                  [295.876, 250.563],
                                  [-12.619, -180.994],
                                  [281.622, -254.747],
                                ],
                                c: true,
                              },
                            ],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.685, -429.621],
                                  [-155.501, -172.94],
                                  [-271.437, 36.81],
                                  [-255.625, 130.626],
                                  [9.183, 317.811],
                                  [138.5, 418.561],
                                  [295.126, 249.438],
                                  [-13.431, -181.432],
                                  [281.497, -254.184],
                                ],
                                c: true,
                              },
                            ],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.81, -429.621],
                                  [-155.251, -172.377],
                                  [-272.125, 35.998],
                                  [-254.375, 130.813],
                                  [9.308, 318.061],
                                  [138.937, 418.248],
                                  [294.376, 248.25],
                                  [-14.181, -181.932],
                                  [281.435, -253.559],
                                ],
                                c: true,
                              },
                            ],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.872, -429.559],
                                  [-155.001, -171.752],
                                  [-272.812, 35.123],
                                  [-253.062, 131.063],
                                  [9.433, 318.311],
                                  [139.375, 417.936],
                                  [293.564, 247.063],
                                  [-14.931, -182.307],
                                  [281.434, -252.872],
                                ],
                                c: true,
                              },
                            ],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.872, -429.434],
                                  [-154.813, -171.127],
                                  [-273.437, 34.248],
                                  [-251.812, 131.313],
                                  [9.496, 318.561],
                                  [139.875, 417.686],
                                  [292.688, 245.938],
                                  [-15.619, -182.682],
                                  [281.434, -252.247],
                                ],
                                c: true,
                              },
                            ],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.872, -429.309],
                                  [-154.688, -170.502],
                                  [-274.062, 33.373],
                                  [-250.625, 131.563],
                                  [9.621, 318.811],
                                  [140.375, 417.373],
                                  [291.814, 244.813],
                                  [-16.244, -183.057],
                                  [281.434, -251.559],
                                ],
                                c: true,
                              },
                            ],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.872, -429.184],
                                  [-154.563, -169.94],
                                  [-274.625, 32.498],
                                  [-249.437, 131.876],
                                  [9.683, 318.998],
                                  [140.812, 417.123],
                                  [290.876, 243.625],
                                  [-16.869, -183.369],
                                  [281.434, -250.809],
                                ],
                                c: true,
                              },
                            ],
                            t: 216,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.81, -428.996],
                                  [-154.501, -169.377],
                                  [-275.187, 31.623],
                                  [-248.25, 132.251],
                                  [9.808, 319.186],
                                  [141.375, 416.811],
                                  [289.938, 242.5],
                                  [-17.431, -183.682],
                                  [281.497, -250.059],
                                ],
                                c: true,
                              },
                            ],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.747, -428.746],
                                  [-154.438, -168.815],
                                  [-275.687, 30.748],
                                  [-247.062, 132.626],
                                  [9.871, 319.436],
                                  [141.875, 416.561],
                                  [288.938, 241.375],
                                  [-17.931, -183.931],
                                  [281.559, -249.309],
                                ],
                                c: true,
                              },
                            ],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.622, -428.559],
                                  [-154.438, -168.252],
                                  [-276.187, 29.873],
                                  [-246, 133.001],
                                  [9.996, 319.623],
                                  [142.375, 416.248],
                                  [287.939, 240.25],
                                  [-18.369, -184.181],
                                  [281.684, -248.497],
                                ],
                                c: true,
                              },
                            ],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.497, -428.246],
                                  [-154.501, -167.69],
                                  [-276.624, 28.998],
                                  [-244.875, 133.438],
                                  [10.058, 319.811],
                                  [142.937, 415.998],
                                  [286.876, 239.125],
                                  [-18.744, -184.369],
                                  [281.747, -247.685],
                                ],
                                c: true,
                              },
                            ],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.372, -427.996],
                                  [-154.626, -167.19],
                                  [-277.062, 28.123],
                                  [-243.875, 133.938],
                                  [10.183, 319.998],
                                  [143.5, 415.748],
                                  [285.813, 238.063],
                                  [-19.119, -184.494],
                                  [281.934, -246.81],
                                ],
                                c: true,
                              },
                            ],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-145.185, -427.684],
                                  [-154.751, -166.752],
                                  [-277.374, 27.248],
                                  [-242.875, 134.438],
                                  [10.246, 320.186],
                                  [144.062, 415.498],
                                  [284.751, 236.938],
                                  [-19.369, -184.619],
                                  [282.059, -245.935],
                                ],
                                c: true,
                              },
                            ],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-144.935, -427.309],
                                  [-154.938, -166.315],
                                  [-277.749, 26.373],
                                  [-241.938, 135.001],
                                  [10.371, 320.373],
                                  [144.687, 415.248],
                                  [283.564, 235.875],
                                  [-19.619, -184.744],
                                  [282.247, -244.997],
                                ],
                                c: true,
                              },
                            ],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-144.747, -426.996],
                                  [-155.251, -165.877],
                                  [-277.999, 25.498],
                                  [-241.063, 135.563],
                                  [10.433, 320.498],
                                  [145.25, 414.998],
                                  [282.439, 234.813],
                                  [-19.744, -184.806],
                                  [282.434, -244.06],
                                ],
                                c: true,
                              },
                            ],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-144.497, -426.559],
                                  [-155.563, -165.502],
                                  [-278.249, 24.623],
                                  [-240.25, 136.126],
                                  [10.558, 320.686],
                                  [145.875, 414.748],
                                  [281.251, 233.75],
                                  [-19.806, -184.806],
                                  [282.684, -243.122],
                                ],
                                c: true,
                              },
                            ],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-144.185, -426.184],
                                  [-155.938, -165.19],
                                  [-278.437, 23.748],
                                  [-239.5, 136.813],
                                  [10.683, 320.811],
                                  [146.5, 414.561],
                                  [280.064, 232.75],
                                  [-19.869, -184.806],
                                  [282.934, -242.185],
                                ],
                                c: true,
                              },
                            ],
                            t: 226,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-143.872, -425.746],
                                  [-156.313, -164.877],
                                  [-278.624, 22.873],
                                  [-238.813, 137.5],
                                  [10.746, 320.998],
                                  [147.187, 414.311],
                                  [278.814, 231.75],
                                  [-19.931, -184.744],
                                  [283.184, -241.185],
                                ],
                                c: true,
                              },
                            ],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-143.56, -425.246],
                                  [-156.813, -164.627],
                                  [-278.749, 22.061],
                                  [-238.188, 138.188],
                                  [10.871, 321.123],
                                  [147.75, 414.061],
                                  [277.626, 230.75],
                                  [-19.931, -184.681],
                                  [283.497, -240.122],
                                ],
                                c: true,
                              },
                            ],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-143.247, -424.809],
                                  [-157.376, -164.44],
                                  [-278.812, 21.186],
                                  [-237.688, 138.938],
                                  [10.996, 321.248],
                                  [148.25, 413.749],
                                  [276.314, 229.813],
                                  [-19.869, -184.494],
                                  [283.747, -239.122],
                                ],
                                c: true,
                              },
                            ],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-142.872, -424.309],
                                  [-158.001, -164.315],
                                  [-278.874, 20.373],
                                  [-237.188, 139.688],
                                  [11.121, 321.373],
                                  [148.75, 413.374],
                                  [275.064, 228.875],
                                  [-19.869, -184.306],
                                  [284.122, -238.06],
                                ],
                                c: true,
                              },
                            ],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-142.497, -423.746],
                                  [-158.688, -164.252],
                                  [-278.812, 19.561],
                                  [-236.75, 140.5],
                                  [11.308, 321.498],
                                  [149.125, 412.999],
                                  [273.751, 227.938],
                                  [-19.744, -184.119],
                                  [284.434, -236.935],
                                ],
                                c: true,
                              },
                            ],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-142.122, -423.246],
                                  [-159.438, -164.19],
                                  [-278.812, 18.748],
                                  [-236.375, 141.313],
                                  [11.433, 321.561],
                                  [149.437, 412.499],
                                  [272.439, 227.063],
                                  [-19.619, -183.806],
                                  [284.809, -235.873],
                                ],
                                c: true,
                              },
                            ],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-141.685, -422.684],
                                  [-160.251, -164.252],
                                  [-278.687, 17.998],
                                  [-236, 142.125],
                                  [11.621, 321.686],
                                  [149.75, 411.999],
                                  [271.126, 226.188],
                                  [-19.494, -183.494],
                                  [285.184, -234.748],
                                ],
                                c: true,
                              },
                            ],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-141.247, -422.059],
                                  [-161.126, -164.315],
                                  [-278.499, 17.248],
                                  [-235.688, 142.938],
                                  [11.746, 321.748],
                                  [149.937, 411.499],
                                  [269.814, 225.313],
                                  [-19.369, -183.181],
                                  [285.559, -233.56],
                                ],
                                c: true,
                              },
                            ],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-140.81, -421.497],
                                  [-162.063, -164.44],
                                  [-278.312, 16.498],
                                  [-235.438, 143.75],
                                  [11.933, 321.873],
                                  [150.062, 410.936],
                                  [268.439, 224.501],
                                  [-19.181, -182.744],
                                  [285.997, -232.435],
                                ],
                                c: true,
                              },
                            ],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-140.372, -420.872],
                                  [-163, -164.565],
                                  [-278.124, 15.748],
                                  [-235.25, 144.563],
                                  [12.121, 321.936],
                                  [150.125, 410.311],
                                  [267.126, 223.75],
                                  [-18.931, -182.306],
                                  [286.372, -231.248],
                                ],
                                c: true,
                              },
                            ],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-139.935, -420.247],
                                  [-164, -164.69],
                                  [-277.812, 15.061],
                                  [-235.063, 145.375],
                                  [12.308, 321.998],
                                  [150.125, 409.624],
                                  [265.751, 223.001],
                                  [-18.681, -181.869],
                                  [286.809, -230.06],
                                ],
                                c: true,
                              },
                            ],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-139.497, -419.559],
                                  [-165.063, -164.94],
                                  [-277.499, 14.373],
                                  [-234.875, 146.25],
                                  [12.558, 322.061],
                                  [150.125, 408.936],
                                  [264.376, 222.251],
                                  [-18.431, -181.369],
                                  [287.246, -228.873],
                                ],
                                c: true,
                              },
                            ],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [
                              {
                                i: [
                                  [100.001, -51.998],
                                  [-35.998, -135.998],
                                  [59.999, -36.001],
                                  [-137.998, -34],
                                  [-18, -71.999],
                                  [-112.002, 2.001],
                                  [143.997, 83.999],
                                  [-49.999, 155.997],
                                  [-8, 92],
                                ],
                                o: [
                                  [-99.999, 51.999],
                                  [35.998, 135.998],
                                  [-59.999, 36.001],
                                  [137.998, 34],
                                  [18, 71.999],
                                  [112, -2],
                                  [-143.997, -83.999],
                                  [49.998, -155.998],
                                  [8, -92],
                                ],
                                v: [
                                  [-138.997, -418.934],
                                  [-166.125, -165.19],
                                  [-277.124, 13.686],
                                  [-234.75, 147.063],
                                  [12.808, 322.123],
                                  [150, 408.249],
                                  [263.001, 221.563],
                                  [-18.181, -180.806],
                                  [287.746, -227.685],
                                ],
                                c: true,
                              },
                            ],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: {
                        k: [
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.216, 0.216, 0.216, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [100],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [100],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      w: {
                        k: [
                          {
                            s: [1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: {
                        k: [
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0.949, 0.949, 0.949, 1],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: {
                        k: [
                          {
                            s: [5.75, -5.375],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.562, -5.188],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.406, -5],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.219, -4.812],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.062, -4.656],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.938, -4.469],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.812, -4.312],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.719, -4.125],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.594, -3.969],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.5, -3.812],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.438, -3.656],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.312, -3.531],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.25, -3.344],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.188, -3.219],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19, -2.969],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.938, -2.844],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.844, -2.719],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.781, -2.594],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.656, -2.5],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, -2.375],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.438, -2.312],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, -2.125],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27, -2.094],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.875, -2.031],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.656, -1.969],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.469, -1.906],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.219, -1.875],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.969, -1.875],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.688, -1.875],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.375, -1.875],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.031, -1.906],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.25, -1.938],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.312, -2.062],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.406, -2.125],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.469, -2.25],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.562, -2.344],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.656, -2.469],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.781, -2.625],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.031, -3],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.188, -3.188],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.312, -3.375],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.625, -3.75],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.812, -3.906],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.188, -4.156],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.375, -4.25],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.625, -4.375],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.812, -4.438],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.062, -4.5],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.281, -4.531],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.531, -4.562],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.812, -4.594],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, -4.594],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.344, -4.594],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.594, -4.562],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.875, -4.5],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.188, -4.469],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.438, -4.375],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.781, -4.281],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.094, -4.188],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.375, -4.062],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.719, -3.938],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.062, -3.781],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, -3.625],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.719, -3.438],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.062, -3.281],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.406, -3.062],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.781, -2.844],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.094, -2.594],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.469, -2.375],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.844, -2.094],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.188, -1.844],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.5, -1.531],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.844, -1.25],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.188, -0.938],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.469, -0.594],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.781, -0.281],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.094, 0.094],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.375, 0.438],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.688, 0.812],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.188, 1.625],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.438, 2],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.688, 2.406],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.812, 2.906],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.719, 3.406],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.531, 4.469],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.281, 5.656],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.188, 6.219],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.031, 6.875],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.906, 7.5],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.594, 8.812],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.281, 10.188],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.094, 10.875],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.938, 11.531],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.562, 12.906],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.188, 14.219],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.969, 14.906],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.781, 15.562],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.562, 16.156],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.375, 16.812],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.156, 17.406],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.906, 18.031],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.562, 18.594],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.969, 19.156],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.406, 19.719],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.812, 20.219],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.188, 20.688],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.531, 21.156],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.875, 21.594],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.188, 22],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.469, 22.375],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 22.719],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36, 23.031],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.219, 23.312],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.438, 23.562],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.812, 23.938],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.125, 24.25],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.25, 24.375],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.406, 24.438],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, 24.531],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 24.594],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 24.594],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.938, 24.562],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.031, 24.562],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.281, 24.375],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.406, 24.25],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.656, 23.938],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.781, 23.719],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, 23.5],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.094, 23.25],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.25, 22.969],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.438, 22.656],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.625, 22.312],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.844, 21.969],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.062, 21.594],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.281, 21.188],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.562, 20.75],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.812, 20.312],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.438, 19.375],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.75, 18.875],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, 18.344],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.469, 17.781],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.906, 17.25],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.312, 16.688],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.781, 16.125],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.25, 15.531],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.75, 14.938],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.312, 14.312],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.875, 13.719],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.5, 13.125],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.125, 12.469],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.812, 11.875],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.531, 11.25],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.281, 10.625],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.406, 10],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.719, 9.375],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.094, 8.719],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.5, 8.125],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.938, 7.5],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.406, 6.938],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.844, 6.312],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.781, 5.188],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.25, 4.656],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.25, 3.594],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.719, 3.094],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.25, 2.594],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.25, 1.656],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.75, 1.219],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.281, 0.75],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.875, -0.469],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.375, -0.875],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.844, -1.219],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.344, -1.625],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.875, -1.969],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.312, -2.281],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.812, -2.594],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.281, -2.938],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.75, -3.219],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.188, -3.531],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.594, -3.781],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.031, -4.031],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.844, -4.281],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.125, -4.5],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.438, -4.688],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.75, -4.906],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.031, -5.062],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.312, -5.25],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.562, -5.438],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.844, -5.531],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.125, -5.688],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.375, -5.812],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.625, -5.875],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.875, -5.969],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.375, -6.094],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.625, -6.094],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.875, -6.156],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.125, -6.125],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.375, -6.125],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.688, -6.094],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.906, -6.031],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.219, -6],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.219, -5.906],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.281, -5.719],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.375, -5.594],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.469, -5.531],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.625, -5.469],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.812, -5.375],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3, -5.375],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.25, -5.344],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.531, -5.281],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.844, -5.281],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.125, -5.281],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.5, -5.312],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.875, -5.312],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.312, -5.344],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      a: {
                        k: [
                          {
                            s: [5.75, -5.375],
                            t: 0,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.562, -5.188],
                            t: 1,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.406, -5],
                            t: 2,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.219, -4.812],
                            t: 3,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.062, -4.656],
                            t: 4,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.938, -4.469],
                            t: 5,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.812, -4.312],
                            t: 6,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.719, -4.125],
                            t: 7,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.594, -3.969],
                            t: 8,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.5, -3.812],
                            t: 9,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.438, -3.656],
                            t: 10,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.312, -3.531],
                            t: 11,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.25, -3.344],
                            t: 12,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.188, -3.219],
                            t: 13,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19, -2.969],
                            t: 15,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.938, -2.844],
                            t: 16,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [20.844, -2.719],
                            t: 17,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.781, -2.594],
                            t: 18,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.656, -2.5],
                            t: 19,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [23.562, -2.375],
                            t: 20,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.438, -2.312],
                            t: 21,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.188, -2.125],
                            t: 23,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27, -2.094],
                            t: 24,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [27.875, -2.031],
                            t: 25,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.656, -1.969],
                            t: 26,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.469, -1.906],
                            t: 27,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.219, -1.875],
                            t: 28,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.969, -1.875],
                            t: 29,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.688, -1.875],
                            t: 30,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.375, -1.875],
                            t: 31,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.031, -1.906],
                            t: 32,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.25, -1.938],
                            t: 33,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.312, -2.062],
                            t: 35,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.406, -2.125],
                            t: 36,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.469, -2.25],
                            t: 37,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.562, -2.344],
                            t: 38,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.656, -2.469],
                            t: 39,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [33.781, -2.625],
                            t: 40,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.031, -3],
                            t: 42,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.188, -3.188],
                            t: 43,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.312, -3.375],
                            t: 44,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.625, -3.75],
                            t: 46,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.812, -3.906],
                            t: 47,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.188, -4.156],
                            t: 49,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.375, -4.25],
                            t: 50,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.625, -4.375],
                            t: 51,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.812, -4.438],
                            t: 52,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.062, -4.5],
                            t: 53,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.281, -4.531],
                            t: 54,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.531, -4.562],
                            t: 55,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.812, -4.594],
                            t: 56,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.062, -4.594],
                            t: 57,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.344, -4.594],
                            t: 58,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.594, -4.562],
                            t: 59,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.875, -4.5],
                            t: 60,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.188, -4.469],
                            t: 61,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.438, -4.375],
                            t: 62,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.781, -4.281],
                            t: 63,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.094, -4.188],
                            t: 64,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.375, -4.062],
                            t: 65,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.719, -3.938],
                            t: 66,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.062, -3.781],
                            t: 67,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.375, -3.625],
                            t: 68,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.719, -3.438],
                            t: 69,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.062, -3.281],
                            t: 70,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.406, -3.062],
                            t: 71,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.781, -2.844],
                            t: 72,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.094, -2.594],
                            t: 73,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.469, -2.375],
                            t: 74,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.844, -2.094],
                            t: 75,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.188, -1.844],
                            t: 76,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.5, -1.531],
                            t: 77,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.844, -1.25],
                            t: 78,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.188, -0.938],
                            t: 79,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.469, -0.594],
                            t: 80,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.781, -0.281],
                            t: 81,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.094, 0.094],
                            t: 82,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.375, 0.438],
                            t: 83,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.688, 0.812],
                            t: 84,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.188, 1.625],
                            t: 86,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.438, 2],
                            t: 87,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.688, 2.406],
                            t: 88,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.812, 2.906],
                            t: 89,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.719, 3.406],
                            t: 90,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.531, 4.469],
                            t: 92,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.281, 5.656],
                            t: 94,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.188, 6.219],
                            t: 95,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [46.031, 6.875],
                            t: 96,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.906, 7.5],
                            t: 97,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.594, 8.812],
                            t: 99,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.281, 10.188],
                            t: 101,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [45.094, 10.875],
                            t: 102,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.938, 11.531],
                            t: 103,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.562, 12.906],
                            t: 105,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [44.188, 14.219],
                            t: 107,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.969, 14.906],
                            t: 108,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.781, 15.562],
                            t: 109,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.562, 16.156],
                            t: 110,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.375, 16.812],
                            t: 111,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [43.156, 17.406],
                            t: 112,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.906, 18.031],
                            t: 113,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [42.562, 18.594],
                            t: 114,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.969, 19.156],
                            t: 115,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [41.406, 19.719],
                            t: 116,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.812, 20.219],
                            t: 117,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [40.188, 20.688],
                            t: 118,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [39.531, 21.156],
                            t: 119,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.875, 21.594],
                            t: 120,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [38.188, 22],
                            t: 121,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [37.469, 22.375],
                            t: 122,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36.75, 22.719],
                            t: 123,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [36, 23.031],
                            t: 124,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [35.219, 23.312],
                            t: 125,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [34.438, 23.562],
                            t: 126,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [32.812, 23.938],
                            t: 128,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [31.125, 24.25],
                            t: 130,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [30.25, 24.375],
                            t: 131,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [29.406, 24.438],
                            t: 132,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [28.5, 24.531],
                            t: 133,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [26.688, 24.594],
                            t: 135,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [25.844, 24.594],
                            t: 136,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.938, 24.562],
                            t: 137,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [24.031, 24.562],
                            t: 138,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [22.281, 24.375],
                            t: 140,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [21.406, 24.25],
                            t: 141,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [19.656, 23.938],
                            t: 143,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [18.781, 23.719],
                            t: 144,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.938, 23.5],
                            t: 145,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.094, 23.25],
                            t: 146,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.25, 22.969],
                            t: 147,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.438, 22.656],
                            t: 148,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.625, 22.312],
                            t: 149,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.844, 21.969],
                            t: 150,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.062, 21.594],
                            t: 151,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.281, 21.188],
                            t: 152,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.562, 20.75],
                            t: 153,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.812, 20.312],
                            t: 154,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.438, 19.375],
                            t: 156,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.75, 18.875],
                            t: 157,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.094, 18.344],
                            t: 158,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.469, 17.781],
                            t: 159,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.906, 17.25],
                            t: 160,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.312, 16.688],
                            t: 161,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.781, 16.125],
                            t: 162,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.25, 15.531],
                            t: 163,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.75, 14.938],
                            t: 164,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.312, 14.312],
                            t: 165,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.875, 13.719],
                            t: 166,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.5, 13.125],
                            t: 167,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.125, 12.469],
                            t: 168,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.812, 11.875],
                            t: 169,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.531, 11.25],
                            t: 170,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.281, 10.625],
                            t: 171,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.406, 10],
                            t: 172,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.719, 9.375],
                            t: 173,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.094, 8.719],
                            t: 174,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.5, 8.125],
                            t: 175,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.938, 7.5],
                            t: 176,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.406, 6.938],
                            t: 177,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.844, 6.312],
                            t: 178,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.781, 5.188],
                            t: 180,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.25, 4.656],
                            t: 181,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.25, 3.594],
                            t: 183,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.719, 3.094],
                            t: 184,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.25, 2.594],
                            t: 185,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.25, 1.656],
                            t: 187,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.75, 1.219],
                            t: 188,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.281, 0.75],
                            t: 189,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.875, -0.469],
                            t: 192,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.375, -0.875],
                            t: 193,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.844, -1.219],
                            t: 194,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.344, -1.625],
                            t: 195,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.875, -1.969],
                            t: 196,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.312, -2.281],
                            t: 197,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.812, -2.594],
                            t: 198,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.281, -2.938],
                            t: 199,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.75, -3.219],
                            t: 200,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.188, -3.531],
                            t: 201,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.594, -3.781],
                            t: 202,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [17.031, -4.031],
                            t: 203,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.844, -4.281],
                            t: 204,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [16.125, -4.5],
                            t: 205,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [15.438, -4.688],
                            t: 206,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.75, -4.906],
                            t: 207,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [14.031, -5.062],
                            t: 208,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [13.312, -5.25],
                            t: 209,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [12.562, -5.438],
                            t: 210,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.844, -5.531],
                            t: 211,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [11.125, -5.688],
                            t: 212,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [10.375, -5.812],
                            t: 213,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [9.625, -5.875],
                            t: 214,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [8.875, -5.969],
                            t: 215,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [7.375, -6.094],
                            t: 217,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [6.625, -6.094],
                            t: 218,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.875, -6.156],
                            t: 219,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.125, -6.125],
                            t: 220,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.375, -6.125],
                            t: 221,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.688, -6.094],
                            t: 222,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.906, -6.031],
                            t: 223,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.219, -6],
                            t: 224,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.219, -5.906],
                            t: 225,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.281, -5.719],
                            t: 227,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.375, -5.594],
                            t: 228,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.469, -5.531],
                            t: 229,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.625, -5.469],
                            t: 230,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [2.812, -5.375],
                            t: 231,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3, -5.375],
                            t: 232,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.25, -5.344],
                            t: 233,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.531, -5.281],
                            t: 234,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [3.844, -5.281],
                            t: 235,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.125, -5.281],
                            t: 236,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.5, -5.312],
                            t: 237,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [4.875, -5.312],
                            t: 238,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                          {
                            s: [5.312, -5.344],
                            t: 239,
                            i: { x: 1, y: 1 },
                            o: { x: 0, y: 0 },
                          },
                        ],
                      },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      o: {
                        k: [
                          {
                            s: [0],
                            t: 0,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                          {
                            s: [0],
                            t: 239,
                            i: { x: [1], y: [1] },
                            o: { x: [0], y: [0] },
                          },
                        ],
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Shape 15',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 15,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group-Blend',
              np: 15,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 250,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  fonts: {
    list: [
      {
        fName: 'Berkeley Mono',
        fFamily: 'Berkeley Mono',
        fStyle: 'Regular',
        ascent: 72.7996826171875,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Blob Container',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [314, 320, 0], ix: 2, l: 2 },
        a: { a: 0, k: [640, 640, 0], ix: 1, l: 2 },
        s: { a: 0, k: [55, 55, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1280,
      h: 1280,
      ip: 0,
      op: 1800,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
  chars: [
    {
      ch: '1',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [32.1, 0],
                      [40.5, 0],
                      [40.5, -68],
                      [30.9, -68],
                      [10.6, -53.4],
                      [10.6, -44],
                      [25.3, -53.3],
                      [32.1, -59],
                      [32.7, -59],
                      [32.1, -48.8],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '6',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.334, 0],
                      [-3.034, 1.166],
                      [-1.167, 3.034],
                      [0, 6.267],
                      [1.033, 2.934],
                      [2.766, 1.1],
                      [5.666, 0],
                      [2.666, -0.966],
                      [0.733, -1.933],
                      [0, 0],
                      [-1.434, 2.3],
                      [-3.8, 3.9],
                      [-7.4, 6.534],
                      [0, 0],
                      [3.5, -4.866],
                      [0.933, -4.2],
                      [0, -6.8],
                      [-1.167, -3.066],
                      [-3.067, -1.166],
                    ],
                    o: [
                      [6.266, 0],
                      [3.033, -1.166],
                      [1.166, -3.033],
                      [0, -6.066],
                      [-1.034, -2.933],
                      [-2.767, -1.1],
                      [-4.067, 0],
                      [-2.667, 0.967],
                      [0, 0],
                      [0.733, -2.533],
                      [1.433, -2.3],
                      [3.8, -3.9],
                      [0, 0],
                      [-7.667, 6.867],
                      [-3.5, 4.867],
                      [-0.934, 4.2],
                      [0, 6.4],
                      [1.166, 3.067],
                      [3.066, 1.166],
                    ],
                    v: [
                      [30.1, 1],
                      [44.05, -0.75],
                      [50.35, -7.05],
                      [52.1, -21],
                      [50.55, -34.5],
                      [44.85, -40.55],
                      [32.2, -42.2],
                      [22.1, -40.75],
                      [17, -36.4],
                      [16.5, -36.8],
                      [19.75, -44.05],
                      [27.6, -53.35],
                      [44.4, -69],
                      [32.7, -69],
                      [15.95, -51.4],
                      [9.3, -37.8],
                      [7.9, -21.3],
                      [9.65, -7.1],
                      [16, -0.75],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '6',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 3.934],
                      [-0.7, 2.034],
                      [-1.9, 0.767],
                      [-4.067, 0],
                      [-1.9, -0.733],
                      [-0.734, -1.933],
                      [0, -4],
                      [0.733, -2.033],
                      [1.9, -0.766],
                      [3.933, 0],
                      [1.9, 0.734],
                      [0.766, 1.934],
                    ],
                    o: [
                      [0, -4.333],
                      [0.7, -2.033],
                      [1.9, -0.766],
                      [3.933, 0],
                      [1.9, 0.734],
                      [0.733, 1.934],
                      [0, 4.267],
                      [-0.734, 2.034],
                      [-1.9, 0.767],
                      [-3.934, 0],
                      [-1.9, -0.733],
                      [-0.767, -1.933],
                    ],
                    v: [
                      [16.2, -20],
                      [17.25, -29.55],
                      [21.15, -33.75],
                      [30.1, -34.9],
                      [38.85, -33.8],
                      [42.8, -29.8],
                      [43.9, -20.9],
                      [42.8, -11.45],
                      [38.85, -7.25],
                      [30.1, -6.1],
                      [21.35, -7.2],
                      [17.35, -11.2],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '6',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '6',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '3',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.534, 0],
                      [-3.5, 1.4],
                      [-1.567, 3.2],
                      [0, 5.534],
                      [2.5, 2.967],
                      [6.066, 0],
                      [2.133, -0.133],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.334, 0],
                      [-1.967, -1.9],
                      [0, -4.4],
                      [1.033, -2.133],
                      [2.366, -0.933],
                      [4.133, 0],
                      [2.533, 0.667],
                      [3.466, 1.667],
                      [0, 0],
                      [-2.534, -0.5],
                    ],
                    o: [
                      [6.133, 0],
                      [3.5, -1.4],
                      [1.566, -3.2],
                      [0, -7.066],
                      [-2.5, -2.966],
                      [-0.8, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.866, -0.533],
                      [4.666, 0],
                      [1.966, 1.9],
                      [0, 3.734],
                      [-1.034, 2.134],
                      [-2.367, 0.934],
                      [-2.8, 0],
                      [-2.534, -0.666],
                      [0, 0],
                      [3, 1.267],
                      [2.533, 0.5],
                    ],
                    v: [
                      [24.9, 0.9],
                      [39.35, -1.2],
                      [46.95, -8.1],
                      [49.3, -21.2],
                      [45.55, -36.25],
                      [32.7, -40.7],
                      [28.3, -40.5],
                      [28.1, -40.9],
                      [46.7, -58.5],
                      [46.7, -68],
                      [8.9, -68],
                      [8.9, -60.6],
                      [37.7, -60.6],
                      [37.7, -60.3],
                      [18.7, -42.4],
                      [18.7, -33],
                      [28, -33.8],
                      [37.95, -30.95],
                      [40.9, -21.5],
                      [39.35, -12.7],
                      [34.25, -8.1],
                      [24.5, -6.7],
                      [16.5, -7.7],
                      [7.5, -11.2],
                      [7.5, -2.5],
                      [15.8, 0.15],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '3',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '3',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '7',
      size: 18,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.4, -0.1],
                      [28, -0.1],
                      [40.6, -33.3],
                      [54, -33.3],
                      [54, -40.6],
                      [43.4, -40.6],
                      [50, -58],
                      [50, -68],
                      [9.4, -68],
                      [9.4, -59.8],
                      [42.4, -59.8],
                      [35, -40.6],
                      [15.7, -40.6],
                      [15.7, -33.3],
                      [32.2, -33.3],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '7',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '7',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    { ch: '\r', size: 18, style: 'Regular', w: 0, fFamily: 'Berkeley Mono' },
    {
      ch: '8',
      size: 18,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.4, 0],
                      [-3.134, 1],
                      [-1.167, 2.634],
                      [0, 5.467],
                      [0.666, 1.934],
                      [1.766, 1.534],
                      [3.666, 2.067],
                      [0, 0],
                      [-1.534, 1.334],
                      [-0.567, 1.667],
                      [0, 2.8],
                      [1.066, 2.234],
                      [2.866, 0.867],
                      [5.933, 0],
                      [2.833, -0.866],
                      [1.066, -2.233],
                      [0, -4.666],
                      [-0.567, -1.666],
                      [-1.534, -1.333],
                      [-3.134, -1.666],
                      [0, 0],
                      [1.766, -1.566],
                      [0.666, -1.9],
                      [0, -3.266],
                      [-1.167, -2.633],
                      [-3.067, -1],
                    ],
                    o: [
                      [6.4, 0],
                      [3.133, -1],
                      [1.166, -2.633],
                      [0, -3.2],
                      [-0.667, -1.933],
                      [-1.767, -1.533],
                      [0, 0],
                      [3.133, -1.666],
                      [1.533, -1.333],
                      [0.566, -1.666],
                      [0, -4.666],
                      [-1.067, -2.233],
                      [-2.867, -0.866],
                      [-5.934, 0],
                      [-2.834, 0.867],
                      [-1.067, 2.234],
                      [0, 2.8],
                      [0.566, 1.667],
                      [1.533, 1.334],
                      [0, 0],
                      [-3.6, 2],
                      [-1.767, 1.567],
                      [-0.667, 1.9],
                      [0, 5.467],
                      [1.166, 2.634],
                      [3.066, 1],
                    ],
                    v: [
                      [29.9, 1],
                      [44.2, -0.5],
                      [50.65, -5.95],
                      [52.4, -18.1],
                      [51.4, -25.8],
                      [47.75, -31],
                      [39.6, -36.4],
                      [39.6, -37],
                      [46.6, -41.5],
                      [49.75, -46],
                      [50.6, -52.7],
                      [49, -63.05],
                      [43.1, -67.7],
                      [29.9, -69],
                      [16.75, -67.7],
                      [10.9, -63.05],
                      [9.3, -52.7],
                      [10.15, -46],
                      [13.3, -41.5],
                      [20.3, -37],
                      [20.3, -36.4],
                      [12.25, -31.05],
                      [8.6, -25.85],
                      [7.6, -18.1],
                      [9.35, -5.95],
                      [15.7, -0.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 3.267],
                      [-0.734, 1.734],
                      [-1.934, 1.234],
                      [-4, 1.467],
                      [-1.967, -1.266],
                      [-0.734, -1.766],
                      [0, -3.066],
                      [0.733, -1.533],
                      [1.966, -0.566],
                      [4.066, 0],
                      [1.933, 0.6],
                      [0.733, 1.534],
                    ],
                    o: [
                      [0, -3.066],
                      [0.733, -1.733],
                      [1.933, -1.233],
                      [4.066, 1.467],
                      [1.966, 1.267],
                      [0.733, 1.767],
                      [0, 3.2],
                      [-0.734, 1.534],
                      [-1.967, 0.567],
                      [-4, 0],
                      [-1.934, -0.6],
                      [-0.734, -1.533],
                    ],
                    v: [
                      [15.9, -17.7],
                      [17, -24.9],
                      [21, -29.35],
                      [29.9, -33.4],
                      [38.95, -29.3],
                      [43, -24.75],
                      [44.1, -17.5],
                      [43, -10.4],
                      [38.95, -7.25],
                      [29.9, -6.4],
                      [21, -7.3],
                      [17, -10.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 2,
                ty: 'sh',
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 2.6],
                      [-0.634, 1.234],
                      [-1.7, 0.467],
                      [-3.534, 0],
                      [-1.734, -0.466],
                      [-0.634, -1.266],
                      [0, -2.6],
                      [0.633, -1.433],
                      [1.733, -0.933],
                      [3.533, -1.066],
                      [1.7, 0.967],
                      [0.633, 1.434],
                    ],
                    o: [
                      [0, -2.6],
                      [0.633, -1.233],
                      [1.7, -0.466],
                      [3.533, 0],
                      [1.733, 0.467],
                      [0.633, 1.267],
                      [0, 2.534],
                      [-0.634, 1.434],
                      [-1.734, 0.934],
                      [-3.534, -1],
                      [-1.7, -0.966],
                      [-0.634, -1.433],
                    ],
                    v: [
                      [17.6, -52.7],
                      [18.55, -58.45],
                      [22.05, -61],
                      [29.9, -61.7],
                      [37.8, -61],
                      [41.35, -58.4],
                      [42.3, -52.6],
                      [41.35, -46.65],
                      [37.8, -43.1],
                      [29.9, -40.1],
                      [22.05, -43.05],
                      [18.55, -46.65],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '8',
            np: 6,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '5',
      size: 18,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.467, 0],
                      [-3.6, 1.467],
                      [-1.6, 3.267],
                      [0, 5.667],
                      [1.266, 2.934],
                      [2.8, 1.5],
                      [4.866, 0.534],
                      [1.4, 0],
                      [2.2, -0.666],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.534, 0],
                      [-1.6, -0.2],
                      [-1.634, -1.966],
                      [0, -4.533],
                      [2.4, -2.266],
                      [5.733, 0],
                      [2.6, 0.7],
                      [3.666, 1.734],
                      [0, 0],
                      [-2.534, -0.5],
                    ],
                    o: [
                      [6.2, 0],
                      [3.6, -1.466],
                      [1.6, -3.266],
                      [0, -4.933],
                      [-1.267, -2.933],
                      [-2.8, -1.5],
                      [-1.8, -0.2],
                      [-2.6, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [6.733, -0.866],
                      [1.866, 0],
                      [4.2, 0.534],
                      [1.633, 1.967],
                      [0, 5.534],
                      [-2.4, 2.267],
                      [-3.067, 0],
                      [-2.6, -0.7],
                      [0, 0],
                      [2.933, 1.267],
                      [2.533, 0.5],
                    ],
                    v: [
                      [25.6, 0.7],
                      [40.3, -1.5],
                      [48.1, -8.6],
                      [50.5, -22],
                      [48.6, -33.8],
                      [42.5, -40.45],
                      [31, -43.5],
                      [26.2, -43.8],
                      [19, -42.8],
                      [20.3, -60.4],
                      [47.6, -60.6],
                      [47.6, -68],
                      [13, -68],
                      [10.3, -34.5],
                      [25.7, -35.8],
                      [30.9, -35.5],
                      [39.65, -31.75],
                      [42.1, -22],
                      [38.5, -10.3],
                      [26.3, -6.9],
                      [17.8, -7.95],
                      [8.4, -11.6],
                      [8.4, -2.7],
                      [16.6, -0.05],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '5',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '5',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '4',
      size: 18,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8, -12.8],
                      [35.4, -12.8],
                      [35.4, 0],
                      [43.4, 0],
                      [43.4, -12.8],
                      [53, -12.8],
                      [53, -20.4],
                      [43.4, -20.4],
                      [43.4, -37],
                      [35.4, -37],
                      [35.4, -20.4],
                      [16.6, -20.4],
                      [16.6, -22.6],
                      [39.7, -69],
                      [30.6, -69],
                      [8, -22.6],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '4',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '4',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
  ],
}

import store from '../store'
import { qs, bounds } from '../utils'
import { Emitter } from '../core'
export default class TunnelGL {
  constructor() {
    const container = qs('.background-container')
    const canvas = qs('.tunnel-gl')
    const content = qs('.tunnel .bg-inner')

    this.dom = {
      container,
      canvas,
      content,
    }

    this.init()
  }

  setup() {
    const { canvas, container, content } = this.dom
    const { XL } = store.breakpoints

    const ctx = canvas.getContext('2d')
    const devicePixelRatio = Math.min(window.devicePixelRatio, 2)
    const rect = bounds(container)
    const lines = []

    canvas.width = rect.width * devicePixelRatio
    canvas.height = rect.height * devicePixelRatio
    content.style.width = `${rect.width - rect.width * 0.5}px`
    content.style.height = `${rect.height - rect.height * 0.5}px`
    ctx.scale(devicePixelRatio, devicePixelRatio)

    var rectWidth = rect.width - 1
    var rectHeight = rect.height - 1
    var centerX = rectWidth / 2
    var centerY = rectHeight / 2
    var numSquares = 10

    for (let i = 0; i <= 5; i++) {
      lines[i] = {}
      lines[i].t = 0
      lines[i].w = rectWidth - rectWidth * lines[i].t
      lines[i].h = rectHeight - rectHeight * lines[i].t
      lines[i].x = -(lines[i].w + i * (rectWidth * 0.15)) / 2
      lines[i].y = -(lines[i].h + i * (rectHeight * 0.15)) / 2
      lines[i].fw = lines[i].w + i * (rectWidth * 0.15)
      lines[i].fh = lines[i].h + i * (rectHeight * 0.15)
    }

    this.scene = {
      rectWidth,
      rectHeight,
      canvas,
      ctx,
      centerX,
      centerY,
      velocity: 0.01,
      time: 0,
      numSquares,

      lines,
    }
  }

  drawRectangle(x, y, width, height) {
    const { ctx } = this.scene
    ctx.beginPath()
    ctx.rect(x, y, width, height)
    ctx.stroke()
  }

  drawLines = () => {
    const { XL } = store.breakpoints
    const { centerX, centerY, ctx, rectWidth, rectHeight, canvas } = this.scene

    var numLines = 46

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    // Draw the square
    ctx.beginPath()
    ctx.rect(0, 0, rectWidth, rectHeight)
    ctx.strokeStyle = '#878787'
    ctx.lineWidth = 1
    ctx.stroke()

    // Angle between each line
    var angleIncrement = (2 * Math.PI) / numLines
    ctx.strokeStyle = '#878787'

    var diagonalLength = Math.sqrt(rectWidth ** 2 + rectHeight ** 2) / 2

    for (var i = 0; i < numLines; i++) {
      // Calculate the endpoint of each line
      var endX = centerX + diagonalLength * Math.cos(i * angleIncrement)
      var endY = centerY + diagonalLength * Math.sin(i * angleIncrement)

      //ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Draw the line
      ctx.beginPath()
      ctx.moveTo(centerX, centerY)
      ctx.lineTo(endX, endY)
      ctx.stroke()
    }

    const { lines } = this.scene
    const offset = XL ? 400 : 40

    for (let i = 0; i <= 5; i++) {
      //if (lines[i].fw < offset + i * (rectWidth * 0.15)) {
      if (lines[i].t > 0.7) {
        lines[i].t = 0.55
        lines[i].w = rectWidth - rectWidth * lines[i].t
        lines[i].h = rectHeight - rectHeight * lines[i].t
        lines[i].fw = lines[i].w + i * (rectWidth * 0.15)
      } else {
        lines[i].t += 0.002
        lines[i].w = rectWidth - rectWidth * lines[i].t
        lines[i].h = rectHeight - rectHeight * lines[i].t
        lines[i].x = -(lines[i].w + i * (rectWidth * 0.15)) / 2
        lines[i].y = -(lines[i].h + i * (rectHeight * 0.15)) / 2
        lines[i].fw = lines[i].w + i * (rectWidth * 0.15)
        lines[i].fh = lines[i].h + i * (rectHeight * 0.15)
      }

      ctx.save()
      ctx.translate(centerX, centerY)

      ctx.strokeStyle = '#878787'
      ctx.strokeRect(
        -(lines[i].w + i * (rectWidth * 0.15)) / 2,
        -(lines[i].h + i * (rectHeight * 0.15)) / 2,
        lines[i].w + i * (rectWidth * 0.15),
        lines[i].h + i * (rectHeight * 0.15),
      )

      ctx.restore()
    }
  }

  resize = () => {
    const { container, canvas, content } = this.dom
    const { ctx } = this.scene
    const rect = bounds(container)
    const { XL } = store.breakpoints

    canvas.width = rect.width * devicePixelRatio
    canvas.height = rect.height * devicePixelRatio
    ctx.scale(devicePixelRatio, devicePixelRatio)

    content.style.width = `${rect.width - rect.width * 0.5}px`
    content.style.height = `${rect.height - rect.height * 0.5}px`

    var rectWidth = rect.width - 1
    var rectHeight = rect.height - 1
    var centerX = rectWidth / 2
    var centerY = rectHeight / 2

    Object.assign(this.scene, {
      rectWidth,
      rectHeight,
      centerX,
      centerY,

      smallRectSize: {
        width: rectWidth / 5,
        height: rectHeight / 5,
      },
    })
  }

  on() {
    Emitter.on('tick', this.drawLines)
    Emitter.on('resize', this.resize)
  }

  off() {
    Emitter.off('tick', this.drawLines)
    Emitter.off('resize', this.resize)
  }

  destroy() {
    this.off()
  }

  init() {
    const { container } = this.dom

    if (!container) return

    this.setup()
    this.on()
    this.resize()
  }
}

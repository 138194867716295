import EventEmitter from 'tiny-emitter'
import SplitText from '../vendor/SplitText'
import { qsa } from '../utils'

export default class Splits extends EventEmitter {
  constructor(obj = {}) {
    super()
    this.els = obj.els
    this.splits = []
  }

  splitContent() {
    if (!this.els) return

    const totalSplits = this.els.length
    let splitted = 0

    this.els.forEach((el) => {
      const dataset = el.dataset.split
      const data = dataset.split(',')
      const split = {}
      const classes = {}

      split.el = el
      splitted++

      data.forEach((type, i) => {
        const filter = type.trim()
        if (filter == 'lines') classes.linesClass = `line-${i}`
        if (filter == 'words') classes.wordsClass = `word-${i}`
        if (filter == 'chars') classes.charsClass = `char-${i} chr-++`
      })

      const splitText = new SplitText(el, {
        type: dataset.toString(),
        ...classes,
      })

      this.splits.push(splitText)

      if (splitted == totalSplits) {
        this.emit('ready', this.splits)
      }
    })
  }

  reverseContent() {
    this.splits.forEach((split) => {
      split.revert()
    })
  }

  init() {
    this.splitContent()
  }
}

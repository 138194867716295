import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Contacts extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    Object.assign(this.dom, {
      page: qs('.page'),
    })
  }

  setAnimation(splitText) {
    const { el } = this.dom
    const contactTitle = qsa('.-t6', el)

    this.tl = gsap.timeline({ paused: true })
    this.tl.from(contactTitle, {
      duration: 0.8,
      y: 40,
      autoAlpha: 0,
      stagger: 0.08,
    })
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=50%',
      end: 'bottom bottom',
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })
  }
}

export const box = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 120,
  w: 480,
  h: 480,
  nm: 'L - Box',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: '25',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'dot 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 0,
                  s: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 7.5,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 30,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 37.5,
                  s: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 60,
                  s: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 67.5,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 90,
                  s: [0],
                },
                { t: 97.5, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [630.247, 85.753, 0], ix: 2, l: 2 },
            a: { a: 0, k: [6.497, 6.497, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.45],
                        [3.45, 0],
                        [0, 3.45],
                        [-3.45, 0],
                      ],
                      o: [
                        [0, 3.45],
                        [-3.45, 0],
                        [0, -3.45],
                        [3.45, 0],
                      ],
                      v: [
                        [6.247, 0],
                        [-0.001, 6.247],
                        [-6.247, 0],
                        [-0.001, -6.247],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.717647058824, 0.717647058824, 0.717647058824, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [6.497, 6.497], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'dot 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 5,
                  s: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 12.5,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 35,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 42.5,
                  s: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 65,
                  s: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 72.5,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 95,
                  s: [0],
                },
                { t: 102.5, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [654.227, 85.753, 0], ix: 2, l: 2 },
            a: { a: 0, k: [6.496, 6.497, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.45],
                        [3.45, 0],
                        [0, 3.45],
                        [-3.45, 0],
                      ],
                      o: [
                        [0, 3.45],
                        [-3.45, 0],
                        [0, -3.45],
                        [3.45, 0],
                      ],
                      v: [
                        [6.247, 0],
                        [-0.001, 6.247],
                        [-6.247, 0],
                        [-0.001, -6.247],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.717647058824, 0.717647058824, 0.717647058824, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [6.496, 6.497], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'dot 1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 10,
                  s: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 17.5,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 40,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 47.5,
                  s: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 70,
                  s: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 77.5,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 100,
                  s: [0],
                },
                { t: 107.5, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [678.208, 85.753, 0], ix: 2, l: 2 },
            a: { a: 0, k: [6.496, 6.497, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 3.45],
                        [-3.45, 0],
                        [0, -3.45],
                        [3.45, 0],
                      ],
                      o: [
                        [0, -3.45],
                        [3.45, 0],
                        [0, 3.45],
                        [-3.45, 0],
                      ],
                      v: [
                        [-6.246, 0],
                        [0, -6.247],
                        [6.246, 0],
                        [0, 6.247],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.717647058824, 0.717647058824, 0.717647058824, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [6.496, 6.497], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'line 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [557, 455, 0], ix: 2, l: 2 },
            a: { a: 0, k: [157, 155, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.9, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [144, 144],
                              [144, 144],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.617, y: 0 },
                        t: 30,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [216, 216],
                              [72, 72],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.9, y: 0 },
                        t: 70,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [216, 216],
                              [72, 72],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        t: 100,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [144, 144],
                              [144, 144],
                            ],
                            c: false,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 35, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'line 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [544, 156, 0], ix: 2, l: 2 },
            a: { a: 0, k: [144, -144, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.9, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [144, -144],
                              [144, -144],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.617, y: 0 },
                        t: 30,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [72, -216],
                              [216, -72],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.9, y: 0 },
                        t: 70,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [72, -216],
                              [216, -72],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        t: 100,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [144, -144],
                              [144, -144],
                            ],
                            c: false,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 35, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'line 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [246, 434, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-154, 134, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.9, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-144, 144],
                              [-144, 144],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.617, y: 0 },
                        t: 30,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-216, 72],
                              [-72, 216],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.9, y: 0 },
                        t: 70,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-216, 72],
                              [-72, 216],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        t: 100,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-144, 144],
                              [-144, 144],
                            ],
                            c: false,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 35, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'line 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [267, 166, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-133, -134, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.9, y: 0 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-144, -144],
                              [-144, -144],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.617, y: 0 },
                        t: 30,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-72, -72],
                              [-216, -216],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.9, y: 0 },
                        t: 70,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-72, -72],
                              [-216, -216],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        t: 100,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-144, -144],
                              [-144, -144],
                            ],
                            c: false,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 35, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 5,
          nm: 'random number',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [710.25, 85.557, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0.202, -6.302, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 18,
                    f: 'Berkeley Mono',
                    t: 'FAFAF',
                    ca: 1,
                    j: 0,
                    tr: 0,
                    lh: 66.9562225341797,
                    ls: 0,
                    fc: [0.718, 0.718, 0.718],
                  },
                  t: 0,
                },
              ],
              x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 23900);\n$bm_rt = n = Math.round(n);',
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 120,
          st: -30,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'coloured circle',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 30,
                  s: [100],
                },
                {
                  i: { x: [0.833], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 40,
                  s: [0],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 50,
                  s: [100],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 60,
                  s: [0],
                },
                { t: 70, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [400, 300, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [50, 50, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [72, 72], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.717647058824, 0.717647058824, 0.717647058824, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 35, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.964705882353, 0.788235294118, 0.294117647059, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'square front',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.9, y: 0 },
                  t: 0,
                  s: [400, 300, 0],
                  to: [12, 12, 0],
                  ti: [-12, -12, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.617, y: 0.617 },
                  t: 30,
                  s: [472, 372, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.9, y: 0 },
                  t: 70,
                  s: [472, 372, 0],
                  to: [-12, -12, 0],
                  ti: [12, 12, 0],
                },
                { t: 100, s: [400, 300, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [144, -144],
                        [-144, -144],
                        [-144, 144],
                        [144, 144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 35, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'square behind',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.9, y: 0 },
                  t: 0,
                  s: [400, 300, 0],
                  to: [-12, -12, 0],
                  ti: [12, 12, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.617, y: 0.617 },
                  t: 30,
                  s: [328, 228, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.9, y: 0 },
                  t: 70,
                  s: [328, 228, 0],
                  to: [12, 12, 0],
                  ti: [-12, -12, 0],
                },
                { t: 100, s: [400, 300, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [144, -144],
                        [-144, -144],
                        [-144, 144],
                        [144, 144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 35, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'square center',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [400, 300, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [144, -144],
                        [-144, -144],
                        [-144, 144],
                        [144, 144],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 35, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 120,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  fonts: {
    list: [
      {
        fName: 'Berkeley Mono',
        fFamily: 'Berkeley Mono',
        fStyle: 'Regular',
        ascent: 72.7996826171875,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: '25',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [240, 240, 0], ix: 2, l: 2 },
        a: { a: 0, k: [400, 300, 0], ix: 1, l: 2 },
        s: { a: 0, k: [55, 55, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 800,
      h: 600,
      ip: 0,
      op: 120,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
  chars: [
    {
      ch: '8',
      size: 18,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.4, 0],
                      [-3.134, 1],
                      [-1.167, 2.634],
                      [0, 5.467],
                      [0.666, 1.934],
                      [1.766, 1.534],
                      [3.666, 2.067],
                      [0, 0],
                      [-1.534, 1.334],
                      [-0.567, 1.667],
                      [0, 2.8],
                      [1.066, 2.234],
                      [2.866, 0.867],
                      [5.933, 0],
                      [2.833, -0.866],
                      [1.066, -2.233],
                      [0, -4.666],
                      [-0.567, -1.666],
                      [-1.534, -1.333],
                      [-3.134, -1.666],
                      [0, 0],
                      [1.766, -1.566],
                      [0.666, -1.9],
                      [0, -3.266],
                      [-1.167, -2.633],
                      [-3.067, -1],
                    ],
                    o: [
                      [6.4, 0],
                      [3.133, -1],
                      [1.166, -2.633],
                      [0, -3.2],
                      [-0.667, -1.933],
                      [-1.767, -1.533],
                      [0, 0],
                      [3.133, -1.666],
                      [1.533, -1.333],
                      [0.566, -1.666],
                      [0, -4.666],
                      [-1.067, -2.233],
                      [-2.867, -0.866],
                      [-5.934, 0],
                      [-2.834, 0.867],
                      [-1.067, 2.234],
                      [0, 2.8],
                      [0.566, 1.667],
                      [1.533, 1.334],
                      [0, 0],
                      [-3.6, 2],
                      [-1.767, 1.567],
                      [-0.667, 1.9],
                      [0, 5.467],
                      [1.166, 2.634],
                      [3.066, 1],
                    ],
                    v: [
                      [29.9, 1],
                      [44.2, -0.5],
                      [50.65, -5.95],
                      [52.4, -18.1],
                      [51.4, -25.8],
                      [47.75, -31],
                      [39.6, -36.4],
                      [39.6, -37],
                      [46.6, -41.5],
                      [49.75, -46],
                      [50.6, -52.7],
                      [49, -63.05],
                      [43.1, -67.7],
                      [29.9, -69],
                      [16.75, -67.7],
                      [10.9, -63.05],
                      [9.3, -52.7],
                      [10.15, -46],
                      [13.3, -41.5],
                      [20.3, -37],
                      [20.3, -36.4],
                      [12.25, -31.05],
                      [8.6, -25.85],
                      [7.6, -18.1],
                      [9.35, -5.95],
                      [15.7, -0.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 3.267],
                      [-0.734, 1.734],
                      [-1.934, 1.234],
                      [-4, 1.467],
                      [-1.967, -1.266],
                      [-0.734, -1.766],
                      [0, -3.066],
                      [0.733, -1.533],
                      [1.966, -0.566],
                      [4.066, 0],
                      [1.933, 0.6],
                      [0.733, 1.534],
                    ],
                    o: [
                      [0, -3.066],
                      [0.733, -1.733],
                      [1.933, -1.233],
                      [4.066, 1.467],
                      [1.966, 1.267],
                      [0.733, 1.767],
                      [0, 3.2],
                      [-0.734, 1.534],
                      [-1.967, 0.567],
                      [-4, 0],
                      [-1.934, -0.6],
                      [-0.734, -1.533],
                    ],
                    v: [
                      [15.9, -17.7],
                      [17, -24.9],
                      [21, -29.35],
                      [29.9, -33.4],
                      [38.95, -29.3],
                      [43, -24.75],
                      [44.1, -17.5],
                      [43, -10.4],
                      [38.95, -7.25],
                      [29.9, -6.4],
                      [21, -7.3],
                      [17, -10.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 2,
                ty: 'sh',
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 2.6],
                      [-0.634, 1.234],
                      [-1.7, 0.467],
                      [-3.534, 0],
                      [-1.734, -0.466],
                      [-0.634, -1.266],
                      [0, -2.6],
                      [0.633, -1.433],
                      [1.733, -0.933],
                      [3.533, -1.066],
                      [1.7, 0.967],
                      [0.633, 1.434],
                    ],
                    o: [
                      [0, -2.6],
                      [0.633, -1.233],
                      [1.7, -0.466],
                      [3.533, 0],
                      [1.733, 0.467],
                      [0.633, 1.267],
                      [0, 2.534],
                      [-0.634, 1.434],
                      [-1.734, 0.934],
                      [-3.534, -1],
                      [-1.7, -0.966],
                      [-0.634, -1.433],
                    ],
                    v: [
                      [17.6, -52.7],
                      [18.55, -58.45],
                      [22.05, -61],
                      [29.9, -61.7],
                      [37.8, -61],
                      [41.35, -58.4],
                      [42.3, -52.6],
                      [41.35, -46.65],
                      [37.8, -43.1],
                      [29.9, -40.1],
                      [22.05, -43.05],
                      [18.55, -46.65],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '8',
            np: 6,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '9',
      size: 18,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [5.8, -5.8],
                      [0, 0],
                      [-3.334, 4.734],
                      [-1.267, 4.267],
                      [0, 5.934],
                      [1.166, 3.134],
                      [3.066, 1.2],
                      [6.333, 0],
                      [3.033, -1.166],
                      [1.166, -3.1],
                      [0, -6.4],
                      [-1.067, -3],
                      [-2.867, -1.133],
                      [-5.934, 0],
                      [-1.6, 0.6],
                      [-1.534, 1.934],
                      [0, 0],
                      [3.1, -3.8],
                    ],
                    o: [
                      [0, 0],
                      [6.866, -7.533],
                      [3.333, -4.733],
                      [1.266, -4.266],
                      [0, -6.533],
                      [-1.167, -3.133],
                      [-3.067, -1.2],
                      [-6.267, 0],
                      [-3.034, 1.167],
                      [-1.167, 3.1],
                      [0, 6.2],
                      [1.066, 3],
                      [2.866, 1.134],
                      [3.533, 0],
                      [1.6, -0.6],
                      [0, 0],
                      [-4.4, 6.734],
                      [-3.1, 3.8],
                    ],
                    v: [
                      [16.3, 1],
                      [27.4, 1],
                      [42.7, -17.4],
                      [49.6, -30.9],
                      [51.5, -46.2],
                      [49.75, -60.7],
                      [43.4, -67.2],
                      [29.3, -69],
                      [15.35, -67.25],
                      [9.05, -60.85],
                      [7.3, -46.6],
                      [8.9, -32.8],
                      [14.8, -26.6],
                      [28, -24.9],
                      [35.7, -25.8],
                      [40.4, -29.6],
                      [40.9, -29.2],
                      [29.65, -13.4],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '9',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 4.134],
                      [-0.734, 2.134],
                      [-1.9, 0.8],
                      [-3.934, 0],
                      [-1.934, -0.766],
                      [-0.767, -1.966],
                      [0, -4.066],
                      [0.7, -2.133],
                      [1.966, -0.8],
                      [4.133, 0],
                      [1.9, 0.767],
                      [0.7, 2],
                    ],
                    o: [
                      [0, -4.4],
                      [0.733, -2.133],
                      [1.9, -0.8],
                      [3.933, 0],
                      [1.933, 0.767],
                      [0.766, 1.967],
                      [0, 4.534],
                      [-0.7, 2.134],
                      [-1.967, 0.8],
                      [-3.867, 0],
                      [-1.9, -0.766],
                      [-0.7, -2],
                    ],
                    v: [
                      [15.5, -46.5],
                      [16.6, -56.3],
                      [20.55, -60.7],
                      [29.3, -61.9],
                      [38.1, -60.75],
                      [42.15, -56.65],
                      [43.3, -47.6],
                      [42.25, -37.6],
                      [38.25, -33.2],
                      [29.1, -32],
                      [20.45, -33.15],
                      [16.55, -37.3],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '9',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '9',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '5',
      size: 18,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.467, 0],
                      [-3.6, 1.467],
                      [-1.6, 3.267],
                      [0, 5.667],
                      [1.266, 2.934],
                      [2.8, 1.5],
                      [4.866, 0.534],
                      [1.4, 0],
                      [2.2, -0.666],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.534, 0],
                      [-1.6, -0.2],
                      [-1.634, -1.966],
                      [0, -4.533],
                      [2.4, -2.266],
                      [5.733, 0],
                      [2.6, 0.7],
                      [3.666, 1.734],
                      [0, 0],
                      [-2.534, -0.5],
                    ],
                    o: [
                      [6.2, 0],
                      [3.6, -1.466],
                      [1.6, -3.266],
                      [0, -4.933],
                      [-1.267, -2.933],
                      [-2.8, -1.5],
                      [-1.8, -0.2],
                      [-2.6, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [6.733, -0.866],
                      [1.866, 0],
                      [4.2, 0.534],
                      [1.633, 1.967],
                      [0, 5.534],
                      [-2.4, 2.267],
                      [-3.067, 0],
                      [-2.6, -0.7],
                      [0, 0],
                      [2.933, 1.267],
                      [2.533, 0.5],
                    ],
                    v: [
                      [25.6, 0.7],
                      [40.3, -1.5],
                      [48.1, -8.6],
                      [50.5, -22],
                      [48.6, -33.8],
                      [42.5, -40.45],
                      [31, -43.5],
                      [26.2, -43.8],
                      [19, -42.8],
                      [20.3, -60.4],
                      [47.6, -60.6],
                      [47.6, -68],
                      [13, -68],
                      [10.3, -34.5],
                      [25.7, -35.8],
                      [30.9, -35.5],
                      [39.65, -31.75],
                      [42.1, -22],
                      [38.5, -10.3],
                      [26.3, -6.9],
                      [17.8, -7.95],
                      [8.4, -11.6],
                      [8.4, -2.7],
                      [16.6, -0.05],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '5',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '5',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
  ],
}

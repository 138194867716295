import store from '../store'
import { bounds, qs } from '../utils'
import { Emitter } from '../core'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import gsap from 'gsap'

export default class Globe {
  constructor() {
    const { sizes } = store

    const container = qs('.globe-content')
    const textureLoader = new THREE.TextureLoader()
    const offset = store.sniff.isDevice ? 0.66 : 1
    const canvas = qs('.globe-gl')
    const scene = new THREE.Scene()
    const group = new THREE.Group()
    const glob = new THREE.Group()
    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.vw / sizes.vh,
      0.1,
      1000,
    )

    const rect = bounds(container)

    const clock = new THREE.Clock()
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true,
    })

    renderer.outputColorSpace = THREE.LinearSRGBColorSpace
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(rect.width, sizes.vh)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    camera.position.set(0, 0, 6)
    scene.add(camera)

    const controls = new OrbitControls(camera, canvas)
    controls.target.set(0, 0, 0)
    controls.enableDamping = true
    controls.enabled = false

    this.scene = {
      canvas,
      scene,
      camera,
      renderer,
      controls,
      glob,
      group,
      clock,
      offset,
      textureLoader,
      container,
    }

    this.init()
  }

  setup() {}

  addSphere() {
    const { group, offset, textureLoader, glob } = this.scene

    const matcapTexture = textureLoader.load('/images/Globe-MatCap.jpg')
    const material = new THREE.MeshMatcapMaterial({
      matcap: matcapTexture,
      color: '#fff',
      transparent: true,
    })
    const geometry = new THREE.SphereGeometry(2.9 * offset, 64, 64)
    const sphere = new THREE.Mesh(geometry, material)
    glob.add(sphere)
  }

  addGlb() {
    const { scene, text, group, offset, textureLoader } = this.scene

    const matcapTexture = textureLoader.load('/images/Text-MatCap.jpg')
    const material = new THREE.MeshMatcapMaterial()
    material.matcap = matcapTexture

    const loader = new GLTFLoader()

    loader.load(['/models/globe-text.glb'], (gltf) => {
      const model = gltf.scene

      model.scale.set(3 * offset, 3 * offset, 3 * offset)

      group.add(model)

      scene.traverse((object) => {
        if (object.material && object.name == 'Extrude_1') {
          object.material.dispose()
          object.material = material

          Object.assign(this.scene, {
            model: object,
          })
        }
      })
    })
  }

  spherePointToUV = (dotCenter, sphereCenter) => {
    const newVector = new THREE.Vector3()
    newVector.subVectors(sphereCenter, dotCenter).normalize()
    const uvX = 1 - (0.5 + Math.atan2(newVector.z, newVector.x) / (2 * Math.PI))
    const uvY = 0.5 + Math.asin(newVector.y) / Math.PI

    return new THREE.Vector2(uvX, uvY)
  }

  sampleImage = (imgData, uv) => {
    const point =
      4 * Math.floor(uv.x * imgData.width) +
      Math.floor(uv.y * imgData.height) * (4 * imgData.width)

    return imgData.data.slice(point, point + 4)
  }

  addMap() {
    const { scene, glob, offset, group } = this.scene
    const map = '/images/map.png'
    const loader = new THREE.ImageLoader()
    const dotGeometries = []
    const vector = new THREE.Vector3()
    const sphereRadius = 2.9 * offset
    const latitude = 180
    const dotSize = 0.015
    const dotColor = 0x777777
    const density = 14

    loader.load(map, (image) => {
      const tempCanvas = document.createElement('canvas')

      tempCanvas.width = image.width
      tempCanvas.height = image.height

      const ctx = tempCanvas.getContext('2d')
      ctx.drawImage(image, 0, 0)
      const imageData = ctx.getImageData(0, 0, image.width, image.height)

      for (let lat = 0; lat < latitude; lat += 1) {
        const radius =
          Math.cos((-90 + (180 / latitude) * lat) * (Math.PI / 180)) *
          sphereRadius

        const latitudeCircumference = radius * Math.PI * 2 * 2

        const latitudeDotCount = Math.ceil(latitudeCircumference * density)

        for (let dot = 0; dot < latitudeDotCount; dot += 1) {
          const dotGeometry = new THREE.CircleGeometry(dotSize, 5)

          const phi = (Math.PI / latitude) * lat
          const theta = ((2 * Math.PI) / latitudeDotCount) * dot

          vector.setFromSphericalCoords(sphereRadius, phi, theta)
          dotGeometry.lookAt(vector)
          dotGeometry.translate(vector.x, vector.y, vector.z)
          dotGeometry.computeBoundingSphere()

          const uv = this.spherePointToUV(
            dotGeometry.boundingSphere.center,
            new THREE.Vector3(),
          )

          const sampledPixel = this.sampleImage(imageData, uv)

          if (sampledPixel[3]) {
            dotGeometries.push(dotGeometry)
          }
        }
      }

      const mergedDotGeometries =
        BufferGeometryUtils.mergeGeometries(dotGeometries)

      const dotMaterial = new THREE.MeshBasicMaterial({
        color: dotColor,
        side: THREE.DoubleSide,
      })

      const dotMesh = new THREE.Mesh(mergedDotGeometries, dotMaterial)

      glob.add(dotMesh)
      group.add(glob)
      scene.add(group)
      glob.rotation.y = (-5 * Math.PI) / 180
      glob.rotation.x = (15 * Math.PI) / 180
    })
  }

  tick = () => {
    const { scene, camera, renderer, controls, clock, text, glob, model } =
      this.scene

    const elapsedTime = clock.getElapsedTime()

    if (model) model.rotation.z += 0.001
    //text.rotation.y += 0.01
    glob.rotation.y -= 0.0015

    controls.update()
    renderer.render(scene, camera)
  }

  resize = () => {
    const { sizes } = store
    const { camera, renderer, container } = this.scene
    const rect = bounds(container)

    camera.aspect = sizes.vw / sizes.vh
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(rect.width, sizes.vh)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }

  on() {
    Emitter.on('resize', this.resize)
    Emitter.on('tick', this.tick)
  }

  off() {
    Emitter.off('resize', this.resize)
    Emitter.off('tick', this.tick)
  }

  destroy() {
    this.off()
  }

  init() {
    const { group } = this.scene
    this.on()
    this.addMap()
    this.addSphere()
    this.addGlb()
    this.resize()

    gsap.from(group.scale, { duration: 0.8, x: 0, y: 0, z: 0, delay: 0.5 })
  }
}

import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { FAQ } from '../components'
import gsap from 'gsap'

export default class Faqs extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    // * Setup faqs

    this.faqs = new FAQ()
  }

  setAnimation(splitText) {
    const title = splitText[0].chars

    this.tl = gsap.timeline({ paused: true })

    this.tl.from(title, {
      duration: 0.6,
      y: '101%',
      stagger: 0.035,
      ease: 'power2.inOut',
    })
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=50%',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })
  }

  destroy() {
    this.faqs && this.faqs.destroy()
  }
}

import { qs, qsa } from '../utils'
import gsap from 'gsap'
import { Emitter } from '../core'

export default class Tabs {
  constructor() {
    this.dom = {
      el: qs('.press'),
      titles: qsa('.press-title .title-item'),
      subtitles: qsa('.article-subtitle .subtitle-item'),
      videosDesktop: qsa('.article-asset.asset-1 .video-item'),
      videosMobile: qsa('.article-asset.asset-2 .video-item'),
      content: qsa('.article-excerpt .excerpt-item '),
      tabs: qsa('.article-sources .source-item'),
      dates: qsa('.article-date .date-item'),
      authors: qsa('.article-author .author-item'),
      times: qsa('.source-time'),
    }

    this.state = {
      current: 0,
      prev: 0,
      animate: false,
      autoplay: true,
    }

    this.init()
  }

  updateTab = (e) => {
    const { tabs, times } = this.dom
    const target = e.target
    const index = target.dataset.tab
    const isCurrent = target.classList.contains('active')

    if (this.state.animate || isCurrent) return

    this.state.autoplay = false
    this.timeline && this.timeline.clear()
    this.state.prev = this.state.current
    this.state.current = index
    tabs.forEach((tab) => tab.classList.remove('active'))
    target.classList.add('active')
    gsap.set(times, { autoAlpha: 0 })
    this.animate()

    Emitter.emit('updateTab', index)
  }

  nextTab() {
    const { tabs } = this.dom

    if (this.state.animate) return
    const target = tabs[this.state.current]

    tabs.forEach((tab) => tab.classList.remove('active'))
    target.classList.add('active')
    this.animate()
    this.autoUpdate()
    Emitter.emit('updateTab', this.state.current)
  }

  animate() {
    const { titles, subtitles, content, dates, authors } = this.dom
    const prev = this.state.prev
    const current = this.state.current

    this.state.animate = true
    const tl = gsap.timeline({
      onComplete: () => {
        this.state.animate = false
      },
    })

    const charsPrev = qsa('.char-1', titles[prev])
    const charsCurrent = qsa('.char-1', titles[current])
    const subtitlePrev = qsa('.line-0', subtitles[prev])
    const subtitleCurrent = qsa('.line-0', subtitles[current])

    // Titles
    tl.fromTo(
      charsPrev,
      { autoAlpha: 1, yPercent: 0 },
      { duration: 0.8, autoAlpha: 0, yPercent: -100, stagger: 0.035 },
      0,
    )

    tl.fromTo(
      charsCurrent,
      { autoAlpha: 0, yPercent: 100 },
      { autoAlpha: 1, yPercent: 0, stagger: 0.05 },
      0,
    )

    //  SubTitles
    tl.fromTo(
      subtitlePrev,
      { autoAlpha: 1, yPercent: 0 },
      { autoAlpha: 0, yPercent: -100, stagger: 0.1 },
      0,
    )

    tl.fromTo(
      subtitleCurrent,
      { autoAlpha: 0, yPercent: 100 },
      { autoAlpha: 1, yPercent: 0, stagger: 0.1 },
      0,
    )

    //  Content
    tl.fromTo(
      content[prev],
      { autoAlpha: 1, yPercent: 0 },
      { autoAlpha: 0, yPercent: -100 },
      0,
    )

    tl.fromTo(
      content[current],
      { autoAlpha: 0, yPercent: 100 },
      { autoAlpha: 1, yPercent: 0 },
      0,
    )

    //  Dates
    tl.fromTo(
      dates[prev],
      { autoAlpha: 1, yPercent: 0 },
      { autoAlpha: 0, yPercent: -100 },
      0,
    )

    tl.fromTo(
      dates[current],
      { autoAlpha: 0, yPercent: 100 },
      { autoAlpha: 1, yPercent: 0 },
      0,
    )

    //  Authors
    tl.fromTo(
      authors[prev],
      { autoAlpha: 1, yPercent: 0 },
      { autoAlpha: 0, yPercent: -100 },
      0,
    )

    tl.fromTo(
      authors[current],
      { autoAlpha: 0, yPercent: 100 },
      { autoAlpha: 1, yPercent: 0 },
      0,
    )
  }

  autoUpdate() {
    const { times } = this.dom
    const length = times.length - 1
    const current = this.state.current
    const timeline = qs('.source-time-inner', times[current])

    if (!this.state.autoplay) return

    this.timeline = gsap.timeline()

    this.timeline.fromTo(
      timeline,
      { width: '0%' },
      {
        duration: 10,
        width: '100%',
        onComplete: () => {
          if (!this.state.autoplay) return
          this.state.prev = this.state.current
          this.state.current = current + 1 <= length ? current + 1 : 0
          this.nextTab()
        },
      },
    )
  }

  on() {
    const { tabs } = this.dom

    tabs.forEach((tab) => {
      tab.addEventListener('click', (e) => this.updateTab(e))
    })
  }

  off() {
    const { tabs } = this.dom

    tabs.forEach((tab) => {
      tab.removeEventListener('click', (e) => this.updateTab(e))
    })
  }

  destroy() {
    this.off()
  }

  init() {
    const { el } = this.dom

    if (!el) return
    this.on()
  }
}

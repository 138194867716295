import store from './store'
import { Core } from './core'

export default class App {
  constructor() {
    console.log(
      `%c${store.title} \nMade with ❤️ by ${store.author.name} \n↳ ${store.author.link}`,
      'color: #342f2f',
    )
    // Initialize core components
    this.appCore = new Core()
  }
}

import * as THREE from 'three'
import WebGL from './WebGL'

const vertex = `
  precision mediump float;
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`
const fragment = `
precision mediump float;
varying vec2 vUv;
uniform vec4 uBlack;
uniform vec4 uGrey;
uniform vec2 uResolution;
uniform float uProgress;
uniform float uTime;

float random(vec2 co){
  return fract(sin(dot(co.xy ,vec2(12.9898,96.233))) * 43758.5453);
}

void main() {
  
  vec2 uv = vUv;
  float resolution = 10.0;
  vec2 pixelated = vec2(
    floor(gl_FragCoord.x / resolution),
    floor(gl_FragCoord.y / resolution)
  );
  float t = step(random(pixelated), sin(uProgress));
  gl_FragColor = mix(uBlack, uGrey, t);
}
`

export default class Background extends WebGL {
  constructor(obj) {
    super(obj)
  }

  setMesh() {
    const { scene } = this.bgContext
    const black = new THREE.Color('#000')
    const grey = new THREE.Color('#B7B7B7')

    Object.assign(this.uniforms, {
      uBlack: { value: [black.r, black.g, black.b, 1] },
      uGrey: { value: [grey.r, grey.g, grey.b, 1] },
      uProgress: { value: -0.01 },
    })

    const plane = new THREE.PlaneGeometry(1, 1)
    const material = new THREE.ShaderMaterial({
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: this.uniforms,
      transparent: true,
    })
    const mesh = new THREE.Mesh(plane, material)
    scene.add(mesh)

    Object.assign(this.bgContext, { mesh })
  }
}

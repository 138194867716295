import Module from './module'
import { qs, qsa, bounds } from '../utils'
import gsap from 'gsap'

export default class Marquee extends Module {
  constructor(obj) {
    super(obj)

    this.x = 0
    this.x1 = 0
    this.x2 = 0
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      lines: qsa('.marquee-line', el),
      odd: [],
      even: [],
    })

    this.dom.lines.forEach((line, i) => {
      if (i % 2 == 0) {
        this.dom.even.push(line)
      } else {
        this.dom.odd.push(line)
      }
    })

    const rectodd = bounds(qs('.marquee-item', this.dom.odd[0]))
    const recteven = bounds(qs('.marquee-item', this.dom.even[0]))
    const oddWidth = rectodd.width * 1
    const evenWidth = recteven.width * 1

    this.settings = {
      rectodd,
      recteven,
      oddWidth,
      evenWidth,
      ease: 0.1,
    }
  }

  run() {
    if (!this.state.isPreVisible) return

    const { odd, even } = this.dom
    const { oddWidth, evenWidth } = this.settings

    let x1 = 1
    let x2 = -1

    if (!odd || !even) return

    this.x1 = this.x1 < evenWidth ? this.x1 + x1 : 0
    this.x2 = this.x2 > -oddWidth ? this.x2 + x2 : 0

    gsap.set(even, { x: `${this.x1}px` })
    gsap.set(odd, { x: `${this.x2}px` })
  }

  resize() {
    this.setup()
  }
}

export const team = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 211,
  w: 480,
  h: 480,
  nm: 'Team',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'Team-Container',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Layer 1/bender Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [244, 240, 0], ix: 2, l: 2 },
            a: { a: 0, k: [203.5, 196, 0], ix: 1, l: 2 },
            s: { a: 0, k: [120, 120, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [20.968, 20.967],
                        [29.652, 0],
                        [20.967, -20.968],
                        [0, 0],
                        [29.652, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-29.652, 0],
                        [-20.967, -20.968],
                        [-29.652, 0],
                        [0, 0],
                        [-20.967, 20.967],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [200.671, -43.98],
                        [158.113, -43.98],
                        [79.056, -76.726],
                        [-0.001, -109.473],
                        [-79.056, -76.726],
                        [-79.057, -76.726],
                        [-158.113, -43.98],
                        [-200.671, -43.98],
                        [-200.671, 109.474],
                        [144.314, 109.474],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [203.171, 279.898], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [14.496, 6.981],
                        [8.115, 7.732],
                        [28.194, -0.724],
                        [18.363, -13.513],
                        [3.917, -3.932],
                        [28.09, -0.77],
                        [0.076, -0.394],
                        [0, -0.447],
                        [-0.413, -0.904],
                        [-0.594, -0.594],
                        [0, -1.853],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-16.392, 0],
                        [-9.954, -4.794],
                        [-19.777, -18.835],
                        [-22.726, 0.579],
                        [-4.416, 3.25],
                        [-20.204, 20.285],
                        [-0.142, 0.373],
                        [-0.083, 0.432],
                        [0, 1.013],
                        [0.343, 0.749],
                        [1.31, 1.31],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [190.176, -55.964],
                        [150.279, -54.765],
                        [103.493, -64.325],
                        [76.089, -82.99],
                        [1.191, -111.354],
                        [-61.935, -89.507],
                        [-74.445, -78.71],
                        [-149.825, -45.895],
                        [-190.049, -44.743],
                        [-190.176, 0.688],
                        [-189.542, 103.349],
                        [-105.434, 105.382],
                        [79.024, 110.323],
                        [137.342, 112.078],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [198.236, 277.293], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [13.633, 6.296],
                        [7.626, 6.86],
                        [26.737, -1.449],
                        [17.677, -13.076],
                        [0.001, 0],
                        [3.768, -3.799],
                        [26.528, -1.538],
                        [0.152, -0.788],
                        [0, -0.892],
                        [-0.828, -1.809],
                        [-1.188, -1.188],
                        [0, -3.706],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-15.299, 0],
                        [-9.227, -4.261],
                        [-18.587, -16.704],
                        [-21.548, 1.158],
                        [0, 0],
                        [-4.251, 3.145],
                        [-19.44, 19.603],
                        [-0.283, 0.747],
                        [-0.166, 0.864],
                        [0, 2.027],
                        [0.686, 1.498],
                        [2.621, 2.621],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [179.68, -67.949],
                        [142.443, -65.55],
                        [98.776, -72.916],
                        [73.12, -89.253],
                        [2.382, -113.234],
                        [-57.806, -91.148],
                        [-57.807, -91.148],
                        [-69.834, -80.693],
                        [-141.538, -47.812],
                        [-179.429, -45.505],
                        [-179.68, -1.695],
                        [-178.412, 97.224],
                        [-98.406, 101.289],
                        [75.938, 111.172],
                        [130.368, 114.682],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [193.303, 274.689], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [12.77, 5.6],
                        [7.137, 5.989],
                        [25.279, -2.172],
                        [16.993, -12.637],
                        [3.502, -3.778],
                        [24.966, -2.308],
                        [0.227, -1.183],
                        [0, -1.338],
                        [-1.241, -2.712],
                        [-1.781, -1.781],
                        [0, -5.559],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-14.207, 0],
                        [-8.51, -3.732],
                        [-17.397, -14.571],
                        [-20.37, 1.738],
                        [-4.087, 3.04],
                        [-18.677, 18.921],
                        [-0.424, 1.121],
                        [-0.25, 1.295],
                        [0, 3.04],
                        [1.029, 2.248],
                        [3.932, 3.931],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [169.185, -79.933],
                        [134.608, -76.335],
                        [94.06, -81.505],
                        [70.152, -95.518],
                        [3.573, -115.115],
                        [-53.677, -92.789],
                        [-65.222, -82.677],
                        [-133.25, -49.727],
                        [-168.807, -46.266],
                        [-169.185, -4.079],
                        [-167.283, 91.099],
                        [-91.377, 97.197],
                        [72.853, 112.02],
                        [123.395, 117.287],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [188.369, 272.085], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [11.905, 4.894],
                        [6.648, 5.117],
                        [23.822, -2.896],
                        [16.31, -12.2],
                        [3.363, -3.637],
                        [23.404, -3.078],
                        [0.303, -1.577],
                        [0, -1.784],
                        [-1.655, -3.616],
                        [-2.375, -2.375],
                        [0, -7.413],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-13.113, 0],
                        [-7.803, -3.207],
                        [-16.207, -12.44],
                        [-19.193, 2.316],
                        [-3.923, 2.935],
                        [-17.913, 18.238],
                        [-0.566, 1.494],
                        [-0.332, 1.727],
                        [0, 4.054],
                        [1.372, 2.997],
                        [5.242, 5.242],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [158.689, -91.917],
                        [126.771, -87.12],
                        [89.343, -90.095],
                        [67.184, -101.781],
                        [4.764, -116.995],
                        [-49.548, -94.429],
                        [-60.611, -84.66],
                        [-124.963, -51.642],
                        [-158.187, -47.028],
                        [-158.689, -6.462],
                        [-156.153, 84.974],
                        [-84.349, 93.105],
                        [69.767, 112.869],
                        [116.421, 119.891],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [183.435, 269.48], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [11.037, 4.173],
                        [6.16, 4.246],
                        [22.365, -3.621],
                        [15.625, -11.763],
                        [0, 0],
                        [3.223, -3.494],
                        [21.841, -3.846],
                        [0.379, -1.971],
                        [0, -2.231],
                        [-2.069, -4.521],
                        [-2.969, -2.969],
                        [0, -9.267],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-12.021, 0],
                        [-7.112, -2.69],
                        [-15.018, -10.308],
                        [-18.015, 2.895],
                        [-0.001, 0],
                        [-3.758, 2.829],
                        [-16.639, 18.042],
                        [-0.707, 1.868],
                        [-0.415, 2.159],
                        [0, 5.067],
                        [1.715, 3.746],
                        [6.552, 6.553],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [148.195, -103.901],
                        [118.937, -97.905],
                        [84.627, -98.684],
                        [64.216, -108.045],
                        [5.955, -118.875],
                        [-45.419, -96.069],
                        [-45.419, -96.069],
                        [-55.999, -86.644],
                        [-116.674, -53.558],
                        [-147.565, -47.79],
                        [-148.195, -8.844],
                        [-145.024, 78.85],
                        [-77.318, 89.013],
                        [66.682, 113.719],
                        [109.45, 122.496],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [178.501, 266.875], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [10.161, 3.438],
                        [5.671, 3.374],
                        [20.906, -4.345],
                        [14.942, -11.326],
                        [3.083, -3.353],
                        [20.279, -4.616],
                        [0.455, -2.364],
                        [0, -2.677],
                        [-2.483, -5.425],
                        [-3.563, -3.563],
                        [0, -11.12],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-10.928, 0],
                        [-6.443, -2.179],
                        [-13.828, -8.176],
                        [-16.838, 3.474],
                        [-3.594, 2.724],
                        [-16.387, 16.875],
                        [-0.848, 2.241],
                        [-0.498, 2.591],
                        [0, 6.081],
                        [2.057, 4.495],
                        [7.863, 7.863],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [137.699, -115.885],
                        [111.101, -108.69],
                        [79.91, -107.274],
                        [61.247, -114.308],
                        [7.146, -120.755],
                        [-41.29, -97.709],
                        [-51.388, -88.627],
                        [-108.387, -55.473],
                        [-136.945, -48.552],
                        [-137.699, -11.227],
                        [-133.895, 72.725],
                        [-70.29, 84.921],
                        [63.597, 114.568],
                        [102.476, 125.1],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [173.568, 264.271], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [8.639, 4.309],
                        [5.182, 2.502],
                        [19.449, -5.069],
                        [14.26, -10.89],
                        [2.944, -3.211],
                        [18.717, -5.385],
                        [0.53, -2.758],
                        [0, -3.123],
                        [-2.897, -6.328],
                        [-4.157, -4.157],
                        [0, -12.973],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-9.835, 0],
                        [-6.011, -0.608],
                        [-12.638, -6.045],
                        [-15.66, 4.054],
                        [-3.43, 2.619],
                        [-15.623, 16.192],
                        [-0.99, 2.615],
                        [-0.582, 3.023],
                        [0, 7.094],
                        [2.401, 5.245],
                        [9.173, 9.173],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [127.204, -127.787],
                        [103.266, -119.393],
                        [75.194, -115.781],
                        [58.279, -120.489],
                        [8.337, -122.554],
                        [-37.161, -99.267],
                        [-46.777, -90.528],
                        [-100.099, -57.307],
                        [-126.323, -49.232],
                        [-127.204, -13.528],
                        [-122.766, 66.682],
                        [-63.261, 80.912],
                        [60.513, 115.499],
                        [95.503, 127.787],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [168.634, 261.584], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [7.679, 3.831],
                        [4.693, 1.631],
                        [17.991, -5.793],
                        [13.578, -10.453],
                        [2.804, -3.07],
                        [17.155, -6.154],
                        [0.606, -3.152],
                        [0, -3.569],
                        [-3.311, -7.233],
                        [-4.75, -4.751],
                        [0, -14.826],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-8.743, 0],
                        [-5.343, 0.067],
                        [-11.448, -3.912],
                        [-14.483, 4.633],
                        [-3.266, 2.515],
                        [-14.485, 15.86],
                        [-1.131, 2.989],
                        [-0.665, 3.455],
                        [0, 8.108],
                        [2.743, 5.993],
                        [10.484, 10.483],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [116.708, -135.081],
                        [95.43, -125.487],
                        [70.477, -119.681],
                        [55.31, -122.063],
                        [9.527, -119.744],
                        [-33.032, -96.218],
                        [-42.166, -87.821],
                        [-91.812, -54.532],
                        [-115.703, -45.304],
                        [-116.708, -11.221],
                        [-111.636, 65.248],
                        [-56.232, 81.51],
                        [57.427, 121.038],
                        [88.531, 135.081],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [163.7, 254.29], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [6.719, 3.351],
                        [4.205, 0.759],
                        [16.534, -6.518],
                        [12.896, -10.018],
                        [0, 0],
                        [2.663, -2.928],
                        [15.593, -6.924],
                        [0.682, -3.547],
                        [0, -4.016],
                        [-3.724, -8.137],
                        [-5.345, -5.345],
                        [0, -16.679],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-7.649, 0],
                        [-4.676, 0.741],
                        [-10.257, -1.781],
                        [-13.305, 5.212],
                        [0, 0],
                        [-3.102, 2.41],
                        [-13.768, 15.134],
                        [-1.272, 3.362],
                        [-0.748, 3.886],
                        [0, 9.121],
                        [3.087, 6.743],
                        [11.794, 11.794],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [106.214, -142.376],
                        [87.594, -131.582],
                        [65.761, -123.581],
                        [52.341, -123.636],
                        [10.719, -116.934],
                        [-28.903, -93.169],
                        [-28.904, -93.169],
                        [-37.554, -85.115],
                        [-83.523, -51.758],
                        [-105.081, -41.376],
                        [-106.214, -8.913],
                        [-100.508, 63.812],
                        [-49.203, 82.107],
                        [54.343, 126.576],
                        [81.557, 142.375],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [158.766, 246.996], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [5.759, 2.872],
                        [3.716, -0.112],
                        [15.077, -7.242],
                        [12.216, -9.584],
                        [2.524, -2.786],
                        [14.031, -7.693],
                        [0.758, -3.941],
                        [0, -4.461],
                        [-4.138, -9.041],
                        [-5.938, -5.939],
                        [0, -18.532],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-6.557, 0],
                        [-4.008, 1.415],
                        [-9.067, 0.351],
                        [-12.127, 5.79],
                        [-2.939, 2.305],
                        [-13.05, 14.407],
                        [-1.413, 3.736],
                        [-0.83, 4.318],
                        [0, 10.134],
                        [3.429, 7.492],
                        [13.104, 13.104],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [95.718, -149.67],
                        [79.759, -137.678],
                        [61.045, -127.48],
                        [49.373, -125.211],
                        [11.909, -114.125],
                        [-24.774, -90.119],
                        [-32.943, -82.409],
                        [-75.236, -48.984],
                        [-94.461, -37.448],
                        [-95.718, -6.607],
                        [-89.378, 62.378],
                        [-42.174, 82.706],
                        [51.257, 132.115],
                        [74.584, 149.669],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [153.833, 239.701], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 11',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [4.799, 2.394],
                        [3.227, -0.984],
                        [13.618, -7.965],
                        [11.537, -9.15],
                        [2.385, -2.645],
                        [12.469, -8.462],
                        [0.833, -4.335],
                        [0, -4.907],
                        [-4.552, -9.945],
                        [-6.532, -6.532],
                        [0, -20.386],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.464, 0],
                        [-3.34, 2.09],
                        [-7.877, 2.483],
                        [-10.95, 6.37],
                        [-2.775, 2.201],
                        [-12.333, 13.68],
                        [-1.555, 4.109],
                        [-0.914, 4.751],
                        [0, 11.148],
                        [3.772, 8.241],
                        [14.415, 14.415],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [85.223, -156.964],
                        [71.923, -143.773],
                        [56.329, -131.38],
                        [46.405, -126.784],
                        [13.1, -111.316],
                        [-20.645, -87.069],
                        [-28.332, -79.702],
                        [-66.949, -46.209],
                        [-83.84, -33.52],
                        [-85.223, -4.3],
                        [-78.249, 60.943],
                        [-35.145, 83.303],
                        [48.171, 137.655],
                        [67.611, 156.964],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [148.899, 232.407], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [3.84, 1.915],
                        [2.739, -1.855],
                        [12.161, -8.69],
                        [10.858, -8.716],
                        [0, 0],
                        [2.245, -2.503],
                        [0, -0.001],
                        [10.907, -9.232],
                        [0.909, -4.729],
                        [0, -5.354],
                        [-4.966, -10.849],
                        [-7.126, -7.126],
                        [0, -22.239],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-4.371, 0],
                        [-2.671, 2.764],
                        [-6.687, 4.615],
                        [-9.773, 6.949],
                        [0, 0],
                        [-2.612, 2.096],
                        [0, 0],
                        [-11.616, 12.954],
                        [-1.696, 4.482],
                        [-0.997, 5.183],
                        [0, 12.161],
                        [4.115, 8.991],
                        [15.726, 15.725],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [74.728, -164.258],
                        [64.089, -149.868],
                        [51.611, -135.279],
                        [43.436, -128.358],
                        [14.292, -108.506],
                        [-16.517, -84.02],
                        [-16.517, -84.019],
                        [-23.72, -76.997],
                        [-23.72, -76.995],
                        [-58.66, -43.434],
                        [-73.218, -29.592],
                        [-74.728, -1.993],
                        [-67.119, 59.508],
                        [-28.116, 83.901],
                        [45.087, 143.193],
                        [60.639, 164.258],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [143.965, 225.113], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [2.88, 1.436],
                        [2.25, -2.727],
                        [10.703, -9.414],
                        [10.18, -8.284],
                        [2.106, -2.362],
                        [9.345, -10.001],
                        [0.985, -5.123],
                        [0, -5.8],
                        [-5.379, -11.753],
                        [-7.72, -7.72],
                        [0, -24.092],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.279, 0],
                        [-2.003, 3.439],
                        [-5.497, 6.747],
                        [-8.595, 7.528],
                        [-2.449, 1.993],
                        [-10.899, 12.229],
                        [-1.837, 4.856],
                        [-1.08, 5.615],
                        [0, 13.175],
                        [4.458, 9.74],
                        [17.036, 17.036],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [64.232, -171.553],
                        [56.253, -155.962],
                        [46.895, -139.18],
                        [40.468, -129.932],
                        [15.482, -105.696],
                        [-12.387, -80.971],
                        [-19.109, -74.29],
                        [-50.373, -40.661],
                        [-62.598, -25.665],
                        [-64.232, 0.314],
                        [-55.991, 58.073],
                        [-21.087, 84.498],
                        [42.002, 148.732],
                        [53.665, 171.552],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [139.032, 217.818], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 14',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [1.92, 0.957],
                        [1.185, -3.826],
                        [0, -0.001],
                        [9.246, -10.139],
                        [9.505, -7.853],
                        [1.966, -2.22],
                        [6.297, -11.701],
                        [1.061, -5.517],
                        [0, -6.246],
                        [-5.794, -12.658],
                        [-8.314, -8.313],
                        [0, -25.946],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-2.185, 0],
                        [-1.336, 4.113],
                        [0, 0],
                        [-2.904, 9.431],
                        [-7.417, 8.107],
                        [-2.287, 1.889],
                        [-10.183, 11.503],
                        [-2.651, 4.924],
                        [-1.163, 6.047],
                        [0, 14.188],
                        [4.801, 10.489],
                        [18.346, 18.347],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [53.737, -178.847],
                        [48.417, -162.058],
                        [42.179, -143.079],
                        [37.5, -131.506],
                        [37.5, -131.505],
                        [16.673, -102.887],
                        [-8.258, -77.921],
                        [-14.497, -71.583],
                        [-42.084, -37.886],
                        [-51.977, -21.737],
                        [-53.737, 2.621],
                        [-44.861, 56.638],
                        [-14.058, 85.096],
                        [38.917, 154.271],
                        [46.692, 178.847],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [134.098, 210.524], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 15',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 15,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.96, 0.479],
                        [0.975, -4.544],
                        [7.789, -10.863],
                        [8.316, -7.997],
                        [1.826, -2.079],
                        [5.442, -11.926],
                        [1.137, -5.911],
                        [0, -6.692],
                        [-6.207, -13.561],
                        [-8.907, -8.907],
                        [0, -27.799],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.093, 0],
                        [-0.668, 4.789],
                        [-2.394, 11.19],
                        [-6.239, 8.686],
                        [-2.124, 1.786],
                        [-9.467, 10.778],
                        [-2.488, 5.451],
                        [-1.246, 6.479],
                        [0, 15.202],
                        [5.144, 11.238],
                        [19.657, 19.657],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [43.241, -186.141],
                        [40.582, -168.153],
                        [37.462, -146.979],
                        [34.531, -133.079],
                        [17.863, -100.077],
                        [-4.129, -74.871],
                        [-9.886, -68.876],
                        [-33.797, -35.112],
                        [-41.355, -17.809],
                        [-43.241, 4.928],
                        [-33.733, 55.203],
                        [-7.03, 85.694],
                        [35.832, 159.81],
                        [39.72, 186.141],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [129.164, 203.23], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 16',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 16,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [20.967, 20.967],
                        [0, 29.652],
                        [-20.968, 20.967],
                        [0, 29.652],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -29.652],
                        [-20.968, -20.968],
                        [0, -29.652],
                        [20.967, -20.967],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [32.746, 193.436],
                        [32.746, 165.349],
                        [0.001, 86.293],
                        [-32.746, 7.236],
                        [0.001, -71.822],
                        [32.746, -150.878],
                        [32.746, -193.436],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [124.23, 195.935], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 17',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 17,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.05], y: [1] },
                    o: { x: [0.5], y: [0] },
                    t: 0,
                    s: [100],
                  },
                  { t: 90, s: [0] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.05], y: [1] },
                    o: { x: [0.5], y: [0] },
                    t: 0,
                    s: [100],
                  },
                  { t: 90, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 18,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  fonts: {
    list: [
      {
        fName: 'Berkeley Mono',
        fFamily: 'Berkeley Mono',
        fStyle: 'Regular',
        ascent: 72.7996826171875,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: '26',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [364, 89, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 12,
                f: 'Berkeley Mono',
                t: '26',
                ca: 1,
                j: 0,
                tr: 0,
                lh: 16,
                ls: 0,
                fc: [0.718, 0.718, 0.718],
              },
              t: 0,
            },
          ],
          x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 240);\n$bm_rt = n = Math.round(n);',
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 5,
      nm: '2341',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [364, 49, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 12,
                f: 'Berkeley Mono',
                t: '2341',
                ca: 1,
                j: 0,
                tr: 0,
                lh: 16,
                ls: 0,
                fc: [0.718, 0.718, 0.718],
              },
              t: 0,
            },
          ],
          x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 23900);\n$bm_rt = n = Math.round(n);',
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 5,
      nm: '914',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [364, 29, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 12,
                f: 'Berkeley Mono',
                t: '914',
                ca: 1,
                j: 0,
                tr: 0,
                lh: 16,
                ls: 0,
                fc: [0.718, 0.718, 0.718],
              },
              t: 0,
            },
          ],
          x: 'var $bm_rt;\nvar hold, s, n, n;\nhold = 0.2;\ns = Math.round($bm_div(time, hold));\nseedRandom(s, true);\nn = random(20, 23900);\n$bm_rt = n = Math.round(n);',
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Top +',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.05], y: [1] },
              o: { x: [0.5], y: [0] },
              t: 90,
              s: [0],
            },
            { t: 150, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [362.773, 121.35, 0], ix: 2, l: 2 },
        a: { a: 0, k: [326.273, 97.35, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [314.528, 97.3],
                    [338.018, 97.3],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [326.273, 109.045],
                    [326.273, 85.555],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Bottom Left + ',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.05], y: [1] },
              o: { x: [0.5], y: [0] },
              t: 105,
              s: [0],
            },
            { t: 165, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [298.674, 337.924, 0], ix: 2, l: 2 },
        a: { a: 0, k: [262.174, 313.924, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [262.124, 302.179],
                    [262.124, 325.669],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [250.378, 313.924],
                    [273.868, 313.924],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Bottom Right +',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.05], y: [1] },
              o: { x: [0.5], y: [0] },
              t: 120,
              s: [0],
            },
            { t: 180, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [362.823, 337.924, 0], ix: 2, l: 2 },
        a: { a: 0, k: [326.323, 313.924, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [326.273, 302.179],
                    [326.273, 325.669],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [314.528, 313.924],
                    [338.018, 313.924],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.709803921569, 0.709803921569, 0.709803921569, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: 'Team-Container',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [240, 240, 0], ix: 2, l: 2 },
        a: { a: 0, k: [240, 240, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 480,
      h: 480,
      ip: 0,
      op: 900,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
  chars: [
    {
      ch: '7',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.4, -0.1],
                      [28, -0.1],
                      [40.6, -33.3],
                      [54, -33.3],
                      [54, -40.6],
                      [43.4, -40.6],
                      [50, -58],
                      [50, -68],
                      [9.4, -68],
                      [9.4, -59.8],
                      [42.4, -59.8],
                      [35, -40.6],
                      [15.7, -40.6],
                      [15.7, -33.3],
                      [32.2, -33.3],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '7',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '7',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '2',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [8.733, -7.466],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.667, 3.567],
                      [-4.867, 5.134],
                      [-0.934, 2.134],
                      [0, 3.067],
                      [1.266, 2.734],
                      [3.033, 1.134],
                      [5.6, 0],
                      [3, -1.033],
                      [1.433, -2.433],
                      [0.4, -4.533],
                      [0, 0],
                      [-0.867, 1.367],
                      [-1.8, 0.567],
                      [-3.2, 0],
                      [-1.8, -0.666],
                      [-0.767, -1.633],
                      [0, -2.933],
                      [0.533, -1.366],
                      [1.466, -1.733],
                      [4.133, -3.833],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.333, -4.6],
                      [3.666, -3.566],
                      [2.6, -2.8],
                      [0.933, -2.133],
                      [0, -5],
                      [-1.267, -2.733],
                      [-3.034, -1.133],
                      [-5.4, 0],
                      [-3, 1.034],
                      [-1.434, 2.434],
                      [0, 0],
                      [0.266, -2.533],
                      [0.866, -1.366],
                      [1.8, -0.566],
                      [3.333, 0],
                      [1.8, 0.667],
                      [0.766, 1.634],
                      [0, 1.934],
                      [-0.534, 1.367],
                      [-2.734, 3.467],
                      [-4.134, 3.834],
                    ],
                    v: [
                      [8.7, -12.6],
                      [8.7, 0],
                      [51.5, 0],
                      [51.5, -8],
                      [17.6, -8],
                      [17.6, -9.4],
                      [31.1, -21.65],
                      [43.9, -34.7],
                      [49.2, -42.1],
                      [50.6, -49.9],
                      [48.7, -61.5],
                      [42.25, -67.3],
                      [29.3, -69],
                      [16.7, -67.45],
                      [10.05, -62.25],
                      [7.3, -51.8],
                      [16.2, -51.8],
                      [17.9, -57.65],
                      [21.9, -60.55],
                      [29.4, -61.4],
                      [37.1, -60.4],
                      [40.95, -56.95],
                      [42.1, -50.1],
                      [41.3, -45.15],
                      [38.3, -40.5],
                      [28, -29.55],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '2',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '4',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8, -12.8],
                      [35.4, -12.8],
                      [35.4, 0],
                      [43.4, 0],
                      [43.4, -12.8],
                      [53, -12.8],
                      [53, -20.4],
                      [43.4, -20.4],
                      [43.4, -37],
                      [35.4, -37],
                      [35.4, -20.4],
                      [16.6, -20.4],
                      [16.6, -22.6],
                      [39.7, -69],
                      [30.6, -69],
                      [8, -22.6],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '4',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '4',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '1',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [32.1, 0],
                      [40.5, 0],
                      [40.5, -68],
                      [30.9, -68],
                      [10.6, -53.4],
                      [10.6, -44],
                      [25.3, -53.3],
                      [32.1, -59],
                      [32.7, -59],
                      [32.1, -48.8],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '3',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.534, 0],
                      [-3.5, 1.4],
                      [-1.567, 3.2],
                      [0, 5.534],
                      [2.5, 2.967],
                      [6.066, 0],
                      [2.133, -0.133],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.334, 0],
                      [-1.967, -1.9],
                      [0, -4.4],
                      [1.033, -2.133],
                      [2.366, -0.933],
                      [4.133, 0],
                      [2.533, 0.667],
                      [3.466, 1.667],
                      [0, 0],
                      [-2.534, -0.5],
                    ],
                    o: [
                      [6.133, 0],
                      [3.5, -1.4],
                      [1.566, -3.2],
                      [0, -7.066],
                      [-2.5, -2.966],
                      [-0.8, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.866, -0.533],
                      [4.666, 0],
                      [1.966, 1.9],
                      [0, 3.734],
                      [-1.034, 2.134],
                      [-2.367, 0.934],
                      [-2.8, 0],
                      [-2.534, -0.666],
                      [0, 0],
                      [3, 1.267],
                      [2.533, 0.5],
                    ],
                    v: [
                      [24.9, 0.9],
                      [39.35, -1.2],
                      [46.95, -8.1],
                      [49.3, -21.2],
                      [45.55, -36.25],
                      [32.7, -40.7],
                      [28.3, -40.5],
                      [28.1, -40.9],
                      [46.7, -58.5],
                      [46.7, -68],
                      [8.9, -68],
                      [8.9, -60.6],
                      [37.7, -60.6],
                      [37.7, -60.3],
                      [18.7, -42.4],
                      [18.7, -33],
                      [28, -33.8],
                      [37.95, -30.95],
                      [40.9, -21.5],
                      [39.35, -12.7],
                      [34.25, -8.1],
                      [24.5, -6.7],
                      [16.5, -7.7],
                      [7.5, -11.2],
                      [7.5, -2.5],
                      [15.8, 0.15],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '3',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '3',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '6',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.334, 0],
                      [-3.034, 1.166],
                      [-1.167, 3.034],
                      [0, 6.267],
                      [1.033, 2.934],
                      [2.766, 1.1],
                      [5.666, 0],
                      [2.666, -0.966],
                      [0.733, -1.933],
                      [0, 0],
                      [-1.434, 2.3],
                      [-3.8, 3.9],
                      [-7.4, 6.534],
                      [0, 0],
                      [3.5, -4.866],
                      [0.933, -4.2],
                      [0, -6.8],
                      [-1.167, -3.066],
                      [-3.067, -1.166],
                    ],
                    o: [
                      [6.266, 0],
                      [3.033, -1.166],
                      [1.166, -3.033],
                      [0, -6.066],
                      [-1.034, -2.933],
                      [-2.767, -1.1],
                      [-4.067, 0],
                      [-2.667, 0.967],
                      [0, 0],
                      [0.733, -2.533],
                      [1.433, -2.3],
                      [3.8, -3.9],
                      [0, 0],
                      [-7.667, 6.867],
                      [-3.5, 4.867],
                      [-0.934, 4.2],
                      [0, 6.4],
                      [1.166, 3.067],
                      [3.066, 1.166],
                    ],
                    v: [
                      [30.1, 1],
                      [44.05, -0.75],
                      [50.35, -7.05],
                      [52.1, -21],
                      [50.55, -34.5],
                      [44.85, -40.55],
                      [32.2, -42.2],
                      [22.1, -40.75],
                      [17, -36.4],
                      [16.5, -36.8],
                      [19.75, -44.05],
                      [27.6, -53.35],
                      [44.4, -69],
                      [32.7, -69],
                      [15.95, -51.4],
                      [9.3, -37.8],
                      [7.9, -21.3],
                      [9.65, -7.1],
                      [16, -0.75],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '6',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 3.934],
                      [-0.7, 2.034],
                      [-1.9, 0.767],
                      [-4.067, 0],
                      [-1.9, -0.733],
                      [-0.734, -1.933],
                      [0, -4],
                      [0.733, -2.033],
                      [1.9, -0.766],
                      [3.933, 0],
                      [1.9, 0.734],
                      [0.766, 1.934],
                    ],
                    o: [
                      [0, -4.333],
                      [0.7, -2.033],
                      [1.9, -0.766],
                      [3.933, 0],
                      [1.9, 0.734],
                      [0.733, 1.934],
                      [0, 4.267],
                      [-0.734, 2.034],
                      [-1.9, 0.767],
                      [-3.934, 0],
                      [-1.9, -0.733],
                      [-0.767, -1.933],
                    ],
                    v: [
                      [16.2, -20],
                      [17.25, -29.55],
                      [21.15, -33.75],
                      [30.1, -34.9],
                      [38.85, -33.8],
                      [42.8, -29.8],
                      [43.9, -20.9],
                      [42.8, -11.45],
                      [38.85, -7.25],
                      [30.1, -6.1],
                      [21.35, -7.2],
                      [17.35, -11.2],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '6',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '6',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '8',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.4, 0],
                      [-3.134, 1],
                      [-1.167, 2.634],
                      [0, 5.467],
                      [0.666, 1.934],
                      [1.766, 1.534],
                      [3.666, 2.067],
                      [0, 0],
                      [-1.534, 1.334],
                      [-0.567, 1.667],
                      [0, 2.8],
                      [1.066, 2.234],
                      [2.866, 0.867],
                      [5.933, 0],
                      [2.833, -0.866],
                      [1.066, -2.233],
                      [0, -4.666],
                      [-0.567, -1.666],
                      [-1.534, -1.333],
                      [-3.134, -1.666],
                      [0, 0],
                      [1.766, -1.566],
                      [0.666, -1.9],
                      [0, -3.266],
                      [-1.167, -2.633],
                      [-3.067, -1],
                    ],
                    o: [
                      [6.4, 0],
                      [3.133, -1],
                      [1.166, -2.633],
                      [0, -3.2],
                      [-0.667, -1.933],
                      [-1.767, -1.533],
                      [0, 0],
                      [3.133, -1.666],
                      [1.533, -1.333],
                      [0.566, -1.666],
                      [0, -4.666],
                      [-1.067, -2.233],
                      [-2.867, -0.866],
                      [-5.934, 0],
                      [-2.834, 0.867],
                      [-1.067, 2.234],
                      [0, 2.8],
                      [0.566, 1.667],
                      [1.533, 1.334],
                      [0, 0],
                      [-3.6, 2],
                      [-1.767, 1.567],
                      [-0.667, 1.9],
                      [0, 5.467],
                      [1.166, 2.634],
                      [3.066, 1],
                    ],
                    v: [
                      [29.9, 1],
                      [44.2, -0.5],
                      [50.65, -5.95],
                      [52.4, -18.1],
                      [51.4, -25.8],
                      [47.75, -31],
                      [39.6, -36.4],
                      [39.6, -37],
                      [46.6, -41.5],
                      [49.75, -46],
                      [50.6, -52.7],
                      [49, -63.05],
                      [43.1, -67.7],
                      [29.9, -69],
                      [16.75, -67.7],
                      [10.9, -63.05],
                      [9.3, -52.7],
                      [10.15, -46],
                      [13.3, -41.5],
                      [20.3, -37],
                      [20.3, -36.4],
                      [12.25, -31.05],
                      [8.6, -25.85],
                      [7.6, -18.1],
                      [9.35, -5.95],
                      [15.7, -0.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 3.267],
                      [-0.734, 1.734],
                      [-1.934, 1.234],
                      [-4, 1.467],
                      [-1.967, -1.266],
                      [-0.734, -1.766],
                      [0, -3.066],
                      [0.733, -1.533],
                      [1.966, -0.566],
                      [4.066, 0],
                      [1.933, 0.6],
                      [0.733, 1.534],
                    ],
                    o: [
                      [0, -3.066],
                      [0.733, -1.733],
                      [1.933, -1.233],
                      [4.066, 1.467],
                      [1.966, 1.267],
                      [0.733, 1.767],
                      [0, 3.2],
                      [-0.734, 1.534],
                      [-1.967, 0.567],
                      [-4, 0],
                      [-1.934, -0.6],
                      [-0.734, -1.533],
                    ],
                    v: [
                      [15.9, -17.7],
                      [17, -24.9],
                      [21, -29.35],
                      [29.9, -33.4],
                      [38.95, -29.3],
                      [43, -24.75],
                      [44.1, -17.5],
                      [43, -10.4],
                      [38.95, -7.25],
                      [29.9, -6.4],
                      [21, -7.3],
                      [17, -10.5],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 2,
                ty: 'sh',
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 2.6],
                      [-0.634, 1.234],
                      [-1.7, 0.467],
                      [-3.534, 0],
                      [-1.734, -0.466],
                      [-0.634, -1.266],
                      [0, -2.6],
                      [0.633, -1.433],
                      [1.733, -0.933],
                      [3.533, -1.066],
                      [1.7, 0.967],
                      [0.633, 1.434],
                    ],
                    o: [
                      [0, -2.6],
                      [0.633, -1.233],
                      [1.7, -0.466],
                      [3.533, 0],
                      [1.733, 0.467],
                      [0.633, 1.267],
                      [0, 2.534],
                      [-0.634, 1.434],
                      [-1.734, 0.934],
                      [-3.534, -1],
                      [-1.7, -0.966],
                      [-0.634, -1.433],
                    ],
                    v: [
                      [17.6, -52.7],
                      [18.55, -58.45],
                      [22.05, -61],
                      [29.9, -61.7],
                      [37.8, -61],
                      [41.35, -58.4],
                      [42.3, -52.6],
                      [41.35, -46.65],
                      [37.8, -43.1],
                      [29.9, -40.1],
                      [22.05, -43.05],
                      [18.55, -46.65],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '8',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '8',
            np: 6,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
    {
      ch: '5',
      size: 12,
      style: 'Regular',
      w: 60,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.467, 0],
                      [-3.6, 1.467],
                      [-1.6, 3.267],
                      [0, 5.667],
                      [1.266, 2.934],
                      [2.8, 1.5],
                      [4.866, 0.534],
                      [1.4, 0],
                      [2.2, -0.666],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.534, 0],
                      [-1.6, -0.2],
                      [-1.634, -1.966],
                      [0, -4.533],
                      [2.4, -2.266],
                      [5.733, 0],
                      [2.6, 0.7],
                      [3.666, 1.734],
                      [0, 0],
                      [-2.534, -0.5],
                    ],
                    o: [
                      [6.2, 0],
                      [3.6, -1.466],
                      [1.6, -3.266],
                      [0, -4.933],
                      [-1.267, -2.933],
                      [-2.8, -1.5],
                      [-1.8, -0.2],
                      [-2.6, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [6.733, -0.866],
                      [1.866, 0],
                      [4.2, 0.534],
                      [1.633, 1.967],
                      [0, 5.534],
                      [-2.4, 2.267],
                      [-3.067, 0],
                      [-2.6, -0.7],
                      [0, 0],
                      [2.933, 1.267],
                      [2.533, 0.5],
                    ],
                    v: [
                      [25.6, 0.7],
                      [40.3, -1.5],
                      [48.1, -8.6],
                      [50.5, -22],
                      [48.6, -33.8],
                      [42.5, -40.45],
                      [31, -43.5],
                      [26.2, -43.8],
                      [19, -42.8],
                      [20.3, -60.4],
                      [47.6, -60.6],
                      [47.6, -68],
                      [13, -68],
                      [10.3, -34.5],
                      [25.7, -35.8],
                      [30.9, -35.5],
                      [39.65, -31.75],
                      [42.1, -22],
                      [38.5, -10.3],
                      [26.3, -6.9],
                      [17.8, -7.95],
                      [8.4, -11.6],
                      [8.4, -2.7],
                      [16.6, -0.05],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: '5',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: '5',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'Berkeley Mono',
    },
  ],
}

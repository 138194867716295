import store from '../store'
import { Emitter } from '../core'
import { bounds } from '../utils'
import gsap from 'gsap'

export default class Player {
  constructor(obj = {}) {
    const canvas = obj.canvas
    const container = obj.container
    const rect = bounds(container)
    const reference = container

    this.progress = obj.progress || 0

    this.dom = {
      container: container,
      images: [],
      reference: reference,
    }

    this.canvas = {
      el: canvas,
      ctx: canvas.getContext('2d'),
      width: rect.width,
      height: rect.height,
      rect: rect,
    }

    this.state = {
      resize: false,
      loaded: false,
    }

    this.isVisible = false

    this.init()
  }

  loadImages() {
    const worker = new Worker('./sequence/Worker.js')
    const images = []
    const total = 181
    const origin = store.sniff.isDesktop
      ? `/sequence/desktop`
      : `/sequence/mobile`

    for (let item = 1; item < total; item++) {
      const index =
        item < 10 ? `00${item}` : item < 100 ? `0${item}` : `${item}`
      const url = `${origin}/intro_0${index}.jpg`

      images.push(url)
    }

    images.forEach((url, index) => {
      const message = url + '|||' + index
      worker.postMessage(message)
    })

    worker.addEventListener('message', (event) => {
      const { blob, url, currCount } = event.data
      const img = new Image()
      img.src = URL.createObjectURL(blob)
      img.onerror = function (error) {
        console.error('Error loading image:', error)
      }

      this.dom.images[currCount] = img
      if (currCount == total - 2) this.state.loaded = true
    })
  }

  setup() {
    const { el, width, height, ctx, rect } = this.canvas
    const { reference } = this.dom
    const dpr = window.devicePixelRatio || 1
    const area = bounds(reference)
    const offset = area.top - rect.top
    const img = new Image()

    el.style.width = `${rect.width}px`
    el.style.height = `${rect.height}px`

    el.width = Math.floor(rect.width * dpr)
    el.height = Math.floor(rect.height * dpr)
    ctx.scale(dpr, dpr)

    img.onload = () => {
      ctx.clearRect(0, 0, width, height)
      ctx.drawImage(img, 0, offset, area.width, area.height)
    }

    Object.assign(this.canvas, {
      area,
      offset,
    })

    img.src = '/sequence/desktop/intro_0001.jpg'
  }

  run = ({ current }) => {
    if (this.state.resize || !this.state.loaded || !this.isVisible) return

    this.scroll = current
    const { ctx, width, height, area, offset } = this.canvas
    const { images } = this.dom
    const val = gsap.utils.clamp(0, 179, this.progress * 179)
    const index = Math.floor(val)
    const img = images[index]

    ctx.clearRect(0, 0, width, height)
    if (img) ctx.drawImage(img, 0, offset, area.width, area.height)
  }

  on() {
    Emitter.on('tick', this.run)
    Emitter.on('resize', this.resize)
  }

  off() {
    Emitter.off('tick', this.run)
    Emitter.off('resize', this.resize)
  }

  resize = () => {
    const { container, reference } = this.dom
    const { el } = this.canvas
    this.state.resize = true

    const dpr = window.devicePixelRatio || 1
    const rect = bounds(container)
    const area = bounds(reference)
    const offset = area.top - rect.top

    el.style.width = `${rect.width}px`
    el.style.height = `${rect.height}px`
    el.width = Math.floor(rect.width * dpr)
    el.height = Math.floor(rect.height * dpr)
    this.canvas.width = rect.width
    this.canvas.height = rect.height
    this.canvas.bounds = rect
    this.canvas.ctx.scale(dpr, dpr)
    this.state.resize = false

    Object.assign(this.canvas, {
      area,
      offset,
    })
  }

  destroy() {
    this.off()
  }

  init() {
    this.loadImages()
    this.setup()
    this.on()
  }
}

import { Core } from '../core'
import { qs, qsa } from '../utils'
import gsap from 'gsap'
import { ScrollTrigger } from '../vendor/ScrollTrigger'

export default class FAQ {
  constructor() {
    this.core = new Core()
    this.smooth = this.core.smooth

    this.dom = {
      el: qs('.faq'),
      items: qsa('.dropdown-item'),
      content: qsa('.dropdown-content'),
      titles: qsa('.dropdown-title'),
    }

    this.init()
  }

  setup() {
    const { content } = this.dom

    content.forEach((item) => {
      gsap.set(item, { height: 0 })
    })

    this.updateScroll()
  }

  openDropdown = (el) => {
    const title = el.target
    const content = title.nextSibling

    title.classList.toggle('active')

    if (title.classList.contains('active')) {
      gsap.to(content, {
        duration: 0.5,
        height: 'auto',
        ease: 'power2.easeOut',
        onComplete: () => {
          this.updateScroll()
        },
      })
    } else {
      gsap.to(content, {
        duration: 0.5,
        height: '0',
        ease: 'power2.easeOut',
        onComplete: () => {
          this.updateScroll()
        },
      })
    }
  }

  updateScroll() {
    this.smooth && this.smooth.update()
    ScrollTrigger && ScrollTrigger.refresh()
  }

  on() {
    const { titles } = this.dom
    titles &&
      titles.forEach((title) => {
        title.addEventListener('click', this.openDropdown)
      })
  }

  off() {
    const { titles } = this.dom
    titles &&
      titles.forEach((title) => {
        title.removeEventListener('click', this.openDropdown)
      })
  }

  destroy() {
    this.off()
  }

  init() {
    const { el } = this.dom

    if (!el) return
    this.setup()
    this.on()
  }
}

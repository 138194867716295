import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Feed extends Module {
  constructor(obj) {
    super(obj)

    // ! Need to add slider on mobile
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      titles: qsa('.-t2', el),
      assets: qsa('.article-asset', el),
      articleTitles: qsa('.-p2', el),
      articleDates: qsa('.-p', el),
    })
  }

  setAnimation(splitText) {
    const { assets, articleTitles, articleDates } = this.dom

    const title = splitText[0].chars ? splitText[0].chars : null

    this.tl = gsap.timeline({ paused: true })

    if (title && title.length > 0) {
      this.tl.from(
        title,
        {
          duration: 0.6,
          yPercent: 101,
          stagger: 0.035,
          ease: 'power2.inOut',
        },
        0,
      )
    }

    this.tl.from(
      assets,
      {
        duration: 1,
        y: 300,
        stagger: 0.05,
        ease: 'power2.inOut',
      },
      0,
    )

    articleTitles.forEach((title) => {
      const lines = qsa('.line-0', title)
      this.tl.from(
        lines,
        {
          duration: 0.6,
          y: 30,
          autoAlpha: 0,
          stagger: 0.05,
          ease: 'power2.inOut',
        },
        '-=0.6',
      )
    })

    this.tl.from(
      articleDates,
      {
        duration: 0.6,
        y: 30,
        autoAlpha: 0,
        stagger: 0.05,
        ease: 'power2.inOut',
      },
      '-=0.5',
    )
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=70%',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })
  }
}

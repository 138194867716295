export const crystal = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 90,
  w: 800,
  h: 540,
  nm: 'L - Crystal',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 1/stats Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [687, 272, 0], ix: 2, l: 2 },
        a: { a: 0, k: [62, 262.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [34.436, 103.983],
                    [80.825, 103.983],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.215686289469, 0.215686289469, 0.215686289469, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-15.968, -259.558],
                    [15.968, -259.558],
                    [15.968, 259.558],
                    [-15.968, 259.558],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.215686289469, 0.215686289469, 0.215686289469, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [18.468, 262.058], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.209, 0],
                    [0, 2.209],
                    [2.209, 0],
                    [0, -2.209],
                  ],
                  o: [
                    [2.209, 0],
                    [0, -2.209],
                    [-2.209, 0],
                    [0, 2.209],
                  ],
                  v: [
                    [0, 4],
                    [4, 0],
                    [0, -4],
                    [-4, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [102.592, 486.847], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.209, 0],
                    [0, 2.209],
                    [2.209, 0],
                    [0, -2.209],
                  ],
                  o: [
                    [2.209, 0],
                    [0, -2.209],
                    [-2.209, 0],
                    [0, 2.209],
                  ],
                  v: [
                    [0, 4],
                    [4, 0],
                    [0, -4],
                    [-4, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.215686289469, 0.215686289469, 0.215686289469, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [102.592, 300.515], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.209, 0],
                    [0, 2.209],
                    [2.209, 0],
                    [0, -2.209],
                  ],
                  o: [
                    [2.209, 0],
                    [0, -2.209],
                    [-2.209, 0],
                    [0, 2.209],
                  ],
                  v: [
                    [0, 4],
                    [4, 0],
                    [0, -4],
                    [-4, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.717647058824, 0.717647058824, 0.717647058824, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [102.592, 332.515], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.204, -0.079],
                    [-0.424, 0],
                    [-0.203, 0.08],
                    [-0.079, 0.212],
                    [0, 0.46],
                    [0.08, 0.212],
                    [0.203, 0.079],
                    [0.424, 0],
                    [0.212, -0.08],
                    [0.08, -0.212],
                    [0, -0.469],
                    [-0.08, -0.212],
                  ],
                  o: [
                    [0.079, 0.212],
                    [0.203, 0.08],
                    [0.424, 0],
                    [0.203, -0.079],
                    [0.08, -0.221],
                    [0, -0.433],
                    [-0.079, -0.212],
                    [-0.203, -0.08],
                    [-0.424, 0],
                    [-0.203, 0.079],
                    [-0.071, 0.221],
                    [0, 0.424],
                    [0, 0],
                  ],
                  v: [
                    [-1.365, 2.475],
                    [-0.932, 2.908],
                    [0.014, 3.023],
                    [0.958, 2.899],
                    [1.383, 2.449],
                    [1.497, 1.423],
                    [1.383, 0.46],
                    [0.958, 0.027],
                    [0.014, -0.088],
                    [-0.959, 0.036],
                    [-1.383, 0.486],
                    [-1.498, 1.521],
                    [-1.374, 2.475],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.335, 0.123],
                    [0.124, 0.336],
                    [0, 0.69],
                    [-0.097, 0.45],
                    [-0.38, 0.531],
                    [-0.831, 0.743],
                    [0, 0],
                    [0.415, -0.424],
                    [0.15, -0.247],
                    [0.079, -0.274],
                    [0, 0],
                    [-0.282, 0.098],
                    [-0.442, 0],
                    [-0.3, -0.115],
                    [-0.114, -0.318],
                    [0, -0.662],
                    [0.124, -0.327],
                    [0.326, -0.124],
                    [0.68, 0],
                  ],
                  o: [
                    [-0.336, -0.124],
                    [-0.123, -0.336],
                    [0, -0.734],
                    [0.106, -0.451],
                    [0.379, -0.53],
                    [0, 0],
                    [-0.804, 0.707],
                    [-0.415, 0.425],
                    [-0.15, 0.248],
                    [0, 0],
                    [0.079, -0.212],
                    [0.292, -0.106],
                    [0.609, 0],
                    [0.3, 0.115],
                    [0.115, 0.318],
                    [0, 0.663],
                    [-0.124, 0.327],
                    [-0.327, 0.123],
                    [-0.68, 0],
                  ],
                  v: [
                    [-1.515, 3.607],
                    [-2.204, 2.917],
                    [-2.39, 1.379],
                    [-2.24, -0.406],
                    [-1.515, -1.883],
                    [0.297, -3.792],
                    [1.56, -3.792],
                    [-0.261, -2.095],
                    [-1.109, -1.087],
                    [-1.462, -0.3],
                    [-1.409, -0.256],
                    [-0.862, -0.725],
                    [0.234, -0.884],
                    [1.604, -0.707],
                    [2.221, -0.053],
                    [2.389, 1.414],
                    [2.204, 2.926],
                    [1.524, 3.607],
                    [0.014, 3.792],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [108.34, 228.164], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.291, 0.371],
                    [-0.062, 0.151],
                    [0, 0.212],
                    [0.079, 0.176],
                    [0.195, 0.071],
                    [0.353, 0],
                    [0.195, -0.053],
                    [0.098, -0.15],
                    [0.026, -0.274],
                    [0, 0],
                    [-0.159, 0.265],
                    [-0.318, 0.115],
                    [-0.583, 0],
                    [-0.327, -0.124],
                    [-0.141, -0.291],
                    [0, -0.54],
                    [0.097, -0.238],
                    [0.282, -0.31],
                    [0.397, -0.389],
                    [0.583, -0.495],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.451, 0.416],
                  ],
                  o: [
                    [0.451, -0.416],
                    [0.159, -0.186],
                    [0.053, -0.15],
                    [0, -0.318],
                    [-0.08, -0.177],
                    [-0.194, -0.07],
                    [-0.353, 0],
                    [-0.194, 0.053],
                    [-0.097, 0.15],
                    [0, 0],
                    [0.044, -0.495],
                    [0.15, -0.265],
                    [0.327, -0.115],
                    [0.583, 0],
                    [0.327, 0.124],
                    [0.133, 0.292],
                    [0, 0.335],
                    [-0.097, 0.239],
                    [-0.53, 0.556],
                    [-0.398, 0.389],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.946, -0.813],
                    [0, 0],
                  ],
                  v: [
                    [-0.155, 0.531],
                    [0.958, -0.654],
                    [1.286, -1.158],
                    [1.374, -1.697],
                    [1.251, -2.439],
                    [0.835, -2.811],
                    [0.005, -2.917],
                    [-0.809, -2.828],
                    [-1.242, -2.51],
                    [-1.427, -1.874],
                    [-2.39, -1.874],
                    [-2.09, -3.005],
                    [-1.374, -3.571],
                    [-0.014, -3.739],
                    [1.391, -3.553],
                    [2.09, -2.926],
                    [2.292, -1.67],
                    [2.143, -0.822],
                    [1.568, -0.017],
                    [0.182, 1.397],
                    [-1.286, 2.723],
                    [-1.286, 2.873],
                    [2.389, 2.873],
                    [2.389, 3.739],
                    [-2.249, 3.739],
                    [-2.249, 2.378],
                    [-0.155, 0.539],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [101.777, 228.12], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.292, 0.371],
                    [-0.062, 0.15],
                    [0, 0.212],
                    [0.08, 0.177],
                    [0.194, 0.071],
                    [0.353, 0],
                    [0.194, -0.053],
                    [0.097, -0.151],
                    [0.027, -0.274],
                    [0, 0],
                    [-0.159, 0.265],
                    [-0.318, 0.115],
                    [-0.583, 0],
                    [-0.327, -0.124],
                    [-0.142, -0.292],
                    [0, -0.539],
                    [0.097, -0.239],
                    [0.283, -0.309],
                    [0.398, -0.389],
                    [0.583, -0.495],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.451, 0.416],
                  ],
                  o: [
                    [0.45, -0.415],
                    [0.159, -0.186],
                    [0.053, -0.15],
                    [0, -0.318],
                    [-0.079, -0.176],
                    [-0.194, -0.071],
                    [-0.354, 0],
                    [-0.194, 0.053],
                    [-0.097, 0.15],
                    [0, 0],
                    [0.044, -0.495],
                    [0.15, -0.266],
                    [0.327, -0.115],
                    [0.583, 0],
                    [0.326, 0.123],
                    [0.132, 0.292],
                    [0, 0.336],
                    [-0.098, 0.239],
                    [-0.53, 0.557],
                    [-0.397, 0.388],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.945, -0.814],
                    [0, 0],
                  ],
                  v: [
                    [-0.155, 0.53],
                    [0.958, -0.654],
                    [1.286, -1.158],
                    [1.374, -1.697],
                    [1.249, -2.44],
                    [0.835, -2.811],
                    [0.005, -2.917],
                    [-0.809, -2.829],
                    [-1.242, -2.51],
                    [-1.428, -1.874],
                    [-2.39, -1.874],
                    [-2.09, -3.005],
                    [-1.375, -3.571],
                    [-0.014, -3.739],
                    [1.391, -3.553],
                    [2.09, -2.926],
                    [2.292, -1.671],
                    [2.143, -0.822],
                    [1.568, -0.018],
                    [0.18, 1.397],
                    [-1.286, 2.722],
                    [-1.286, 2.873],
                    [2.389, 2.873],
                    [2.389, 3.739],
                    [-2.249, 3.739],
                    [-2.249, 2.378],
                    [-0.155, 0.539],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [121.275, 209.487], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.439, 1.291],
                    [0.008, -3.739],
                    [0.999, -3.739],
                    [-1.502, 1.291],
                    [-1.502, 1.529],
                    [0.53, 1.529],
                    [0.53, -0.274],
                    [1.396, -0.274],
                    [1.396, 1.529],
                    [2.439, 1.529],
                    [2.439, 2.351],
                    [1.396, 2.351],
                    [1.396, 3.739],
                    [0.53, 3.739],
                    [0.53, 2.351],
                    [-2.439, 2.351],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [114.892, 209.478], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.327, 0.141],
                    [0, 0],
                    [-0.274, -0.07],
                    [-0.301, 0],
                    [-0.265, 0.097],
                    [-0.106, 0.23],
                    [0, 0.407],
                    [0.212, 0.203],
                    [0.504, 0],
                    [0.415, -0.062],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.088, 0],
                    [-0.274, -0.319],
                    [0, -0.769],
                    [0.168, -0.354],
                    [0.38, -0.159],
                    [0.662, 0],
                    [0.274, 0.054],
                  ],
                  o: [
                    [-0.274, -0.053],
                    [0, 0],
                    [0.371, 0.177],
                    [0.274, 0.071],
                    [0.45, 0],
                    [0.256, -0.097],
                    [0.115, -0.23],
                    [0, -0.477],
                    [-0.212, -0.203],
                    [-0.256, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.23, -0.018],
                    [0.654, 0],
                    [0.274, 0.318],
                    [0, 0.601],
                    [-0.168, 0.345],
                    [-0.379, 0.15],
                    [-0.38, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.361, 3.651],
                    [-2.262, 3.359],
                    [-2.262, 2.413],
                    [-1.29, 2.793],
                    [-0.424, 2.899],
                    [0.637, 2.749],
                    [1.184, 2.254],
                    [1.352, 1.299],
                    [1.034, 0.274],
                    [-0.044, -0.035],
                    [-1.051, 0.053],
                    [-1.051, -0.963],
                    [1.008, -2.899],
                    [1.008, -2.935],
                    [-2.111, -2.935],
                    [-2.111, -3.739],
                    [1.979, -3.739],
                    [1.979, -2.714],
                    [-0.036, -0.804],
                    [-0.018, -0.76],
                    [0.46, -0.778],
                    [1.856, -0.3],
                    [2.262, 1.335],
                    [2.006, 2.758],
                    [1.184, 3.509],
                    [-0.379, 3.739],
                    [-1.37, 3.659],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [108.16, 209.584], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.291, 0.371],
                    [-0.062, 0.15],
                    [0, 0.212],
                    [0.079, 0.176],
                    [0.195, 0.071],
                    [0.353, 0],
                    [0.195, -0.053],
                    [0.098, -0.15],
                    [0.026, -0.274],
                    [0, 0],
                    [-0.159, 0.265],
                    [-0.318, 0.115],
                    [-0.583, 0],
                    [-0.327, -0.124],
                    [-0.141, -0.291],
                    [0, -0.54],
                    [0.097, -0.239],
                    [0.282, -0.309],
                    [0.397, -0.389],
                    [0.583, -0.495],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.451, 0.416],
                  ],
                  o: [
                    [0.451, -0.416],
                    [0.159, -0.186],
                    [0.053, -0.15],
                    [0, -0.318],
                    [-0.08, -0.177],
                    [-0.194, -0.07],
                    [-0.353, 0],
                    [-0.194, 0.053],
                    [-0.097, 0.15],
                    [0, 0],
                    [0.044, -0.495],
                    [0.15, -0.265],
                    [0.327, -0.115],
                    [0.583, 0],
                    [0.327, 0.124],
                    [0.133, 0.292],
                    [0, 0.335],
                    [-0.097, 0.239],
                    [-0.53, 0.557],
                    [-0.398, 0.389],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.946, -0.813],
                    [0, 0],
                  ],
                  v: [
                    [-0.155, 0.531],
                    [0.958, -0.654],
                    [1.286, -1.158],
                    [1.374, -1.697],
                    [1.251, -2.439],
                    [0.835, -2.811],
                    [0.005, -2.917],
                    [-0.809, -2.828],
                    [-1.242, -2.51],
                    [-1.427, -1.874],
                    [-2.39, -1.874],
                    [-2.09, -3.005],
                    [-1.374, -3.571],
                    [-0.014, -3.739],
                    [1.391, -3.553],
                    [2.09, -2.926],
                    [2.292, -1.67],
                    [2.143, -0.822],
                    [1.568, -0.018],
                    [0.182, 1.397],
                    [-1.286, 2.723],
                    [-1.286, 2.873],
                    [2.389, 2.873],
                    [2.389, 3.739],
                    [-2.249, 3.739],
                    [-2.249, 2.378],
                    [-0.155, 0.539],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [101.777, 209.487], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.439, 1.291],
                    [0.008, -3.739],
                    [0.999, -3.739],
                    [-1.502, 1.291],
                    [-1.502, 1.529],
                    [0.53, 1.529],
                    [0.53, -0.274],
                    [1.396, -0.274],
                    [1.396, 1.529],
                    [2.439, 1.529],
                    [2.439, 2.351],
                    [1.396, 2.351],
                    [1.396, 3.739],
                    [0.53, 3.739],
                    [0.53, 2.351],
                    [-2.439, 2.351],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [114.892, 190.845], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0.702, -1.609],
                    [0.764, -2.714],
                    [0.702, -2.714],
                    [-0.031, -2.095],
                    [-1.622, -1.087],
                    [-1.622, -2.104],
                    [0.579, -3.686],
                    [1.622, -3.686],
                    [1.622, 3.686],
                    [0.712, 3.686],
                    [0.712, -1.6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [107.864, 190.907], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.203, -0.089],
                    [-0.415, 0],
                    [-0.212, 0.088],
                    [-0.079, 0.23],
                    [0, 0.495],
                    [0.081, 0.212],
                    [0.212, 0.08],
                    [0.424, 0],
                    [0.203, -0.089],
                    [0.079, -0.23],
                    [0, -0.477],
                    [-0.08, -0.221],
                  ],
                  o: [
                    [0.071, 0.221],
                    [0.203, 0.079],
                    [0.451, 0],
                    [0.212, -0.089],
                    [0.08, -0.229],
                    [0, -0.442],
                    [-0.079, -0.212],
                    [-0.212, -0.08],
                    [-0.424, 0],
                    [-0.203, 0.088],
                    [-0.08, 0.23],
                    [0, 0.451],
                    [0, 0],
                  ],
                  v: [
                    [-1.392, -0.371],
                    [-0.967, 0.08],
                    [-0.032, 0.203],
                    [0.958, 0.071],
                    [1.391, -0.407],
                    [1.506, -1.494],
                    [1.382, -2.475],
                    [0.941, -2.917],
                    [-0.014, -3.041],
                    [-0.959, -2.908],
                    [-1.383, -2.431],
                    [-1.498, -1.37],
                    [-1.383, -0.371],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.477, 0.733],
                    [0, 0],
                    [0.176, -0.071],
                    [0.379, 0],
                    [0.31, 0.123],
                    [0.115, 0.327],
                    [0, 0.672],
                    [-0.124, 0.336],
                    [-0.326, 0.124],
                    [-0.68, 0],
                    [-0.336, -0.133],
                    [-0.124, -0.345],
                    [0, -0.707],
                    [0.133, -0.469],
                    [0.363, -0.513],
                    [0.751, -0.822],
                    [0, 0],
                    [-0.335, 0.416],
                  ],
                  o: [
                    [0.336, -0.416],
                    [0, 0],
                    [-0.168, 0.212],
                    [-0.177, 0.062],
                    [-0.645, 0],
                    [-0.309, -0.124],
                    [-0.115, -0.327],
                    [0, -0.672],
                    [0.124, -0.336],
                    [0.327, -0.124],
                    [0.68, 0],
                    [0.336, 0.132],
                    [0.124, 0.336],
                    [0, 0.645],
                    [-0.141, 0.459],
                    [-0.362, 0.513],
                    [0, 0],
                    [0.627, -0.628],
                    [0, 0],
                  ],
                  v: [
                    [0.03, 2.219],
                    [1.249, 0.504],
                    [1.197, 0.46],
                    [0.684, 0.875],
                    [-0.146, 0.972],
                    [-1.577, 0.787],
                    [-2.214, 0.115],
                    [-2.39, -1.379],
                    [-2.205, -2.926],
                    [-1.525, -3.615],
                    [-0.014, -3.801],
                    [1.514, -3.606],
                    [2.204, -2.899],
                    [2.389, -1.326],
                    [2.186, 0.336],
                    [1.434, 1.803],
                    [-0.226, 3.801],
                    [-1.427, 3.801],
                    [0.021, 2.236],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [101.768, 190.916], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [0.702, -1.609],
                    [0.764, -2.714],
                    [0.702, -2.714],
                    [-0.031, -2.095],
                    [-1.621, -1.087],
                    [-1.621, -2.104],
                    [0.579, -3.686],
                    [1.621, -3.686],
                    [1.621, 3.686],
                    [0.711, 3.686],
                    [0.711, -1.6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [120.859, 105.061], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.203, -0.088],
                    [-0.415, 0],
                    [-0.212, 0.088],
                    [-0.08, 0.23],
                    [0, 0.495],
                    [0.079, 0.212],
                    [0.212, 0.079],
                    [0.424, 0],
                    [0.203, -0.089],
                    [0.079, -0.23],
                    [0, -0.478],
                    [-0.08, -0.221],
                  ],
                  o: [
                    [0.071, 0.221],
                    [0.203, 0.08],
                    [0.451, 0],
                    [0.212, -0.089],
                    [0.079, -0.23],
                    [0, -0.442],
                    [-0.08, -0.212],
                    [-0.212, -0.08],
                    [-0.424, 0],
                    [-0.203, 0.088],
                    [-0.08, 0.23],
                    [0, 0.451],
                    [0, 0],
                  ],
                  v: [
                    [-1.392, -0.371],
                    [-0.967, 0.079],
                    [-0.032, 0.203],
                    [0.958, 0.071],
                    [1.391, -0.407],
                    [1.506, -1.494],
                    [1.383, -2.475],
                    [0.941, -2.917],
                    [-0.014, -3.041],
                    [-0.959, -2.908],
                    [-1.383, -2.431],
                    [-1.498, -1.37],
                    [-1.383, -0.371],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.477, 0.733],
                    [0, 0],
                    [0.177, -0.071],
                    [0.38, 0],
                    [0.309, 0.124],
                    [0.115, 0.327],
                    [0, 0.672],
                    [-0.124, 0.336],
                    [-0.327, 0.123],
                    [-0.68, 0],
                    [-0.335, -0.132],
                    [-0.123, -0.345],
                    [0, -0.707],
                    [0.132, -0.469],
                    [0.362, -0.513],
                    [0.751, -0.822],
                    [0, 0],
                    [-0.335, 0.416],
                  ],
                  o: [
                    [0.336, -0.415],
                    [0, 0],
                    [-0.168, 0.213],
                    [-0.177, 0.062],
                    [-0.644, 0],
                    [-0.309, -0.123],
                    [-0.115, -0.327],
                    [0, -0.672],
                    [0.124, -0.336],
                    [0.327, -0.124],
                    [0.68, 0],
                    [0.336, 0.133],
                    [0.124, 0.335],
                    [0, 0.645],
                    [-0.142, 0.459],
                    [-0.362, 0.513],
                    [0, 0],
                    [0.628, -0.628],
                    [0, 0],
                  ],
                  v: [
                    [0.03, 2.218],
                    [1.249, 0.504],
                    [1.197, 0.459],
                    [0.684, 0.875],
                    [-0.146, 0.972],
                    [-1.577, 0.786],
                    [-2.214, 0.115],
                    [-2.39, -1.379],
                    [-2.205, -2.926],
                    [-1.525, -3.615],
                    [-0.014, -3.801],
                    [1.514, -3.607],
                    [2.204, -2.899],
                    [2.389, -1.326],
                    [2.187, 0.336],
                    [1.436, 1.803],
                    [-0.226, 3.801],
                    [-1.428, 3.801],
                    [0.021, 2.236],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [114.764, 105.07], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.185, -0.106],
                    [-0.38, -0.106],
                    [-0.185, 0.106],
                    [-0.071, 0.15],
                    [0, 0.274],
                    [0.071, 0.133],
                    [0.195, 0.053],
                    [0.38, 0],
                    [0.186, -0.044],
                    [0.071, -0.132],
                    [0, -0.274],
                    [-0.07, -0.15],
                  ],
                  o: [
                    [0.071, 0.159],
                    [0.186, 0.106],
                    [0.38, -0.115],
                    [0.186, -0.097],
                    [0.071, -0.159],
                    [0, -0.274],
                    [-0.071, -0.141],
                    [-0.185, -0.053],
                    [-0.38, 0],
                    [-0.185, 0.053],
                    [-0.07, 0.133],
                    [0, 0.274],
                    [0, 0],
                  ],
                  v: [
                    [-1.246, -1.365],
                    [-0.866, -0.976],
                    [-0.018, -0.658],
                    [0.839, -0.985],
                    [1.228, -1.365],
                    [1.334, -2.01],
                    [1.228, -2.639],
                    [0.839, -2.921],
                    [-0.018, -2.992],
                    [-0.866, -2.921],
                    [-1.246, -2.648],
                    [-1.352, -2.028],
                    [-1.246, -1.374],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.212, -0.062],
                    [-0.433, 0],
                    [-0.212, 0.053],
                    [-0.08, 0.168],
                    [0, 0.344],
                    [0.079, 0.194],
                    [0.212, 0.141],
                    [0.442, 0.159],
                    [0.212, -0.133],
                    [0.08, -0.185],
                    [0, -0.327],
                    [-0.079, -0.168],
                  ],
                  o: [
                    [0.08, 0.168],
                    [0.212, 0.061],
                    [0.433, 0],
                    [0.212, -0.061],
                    [0.079, -0.168],
                    [0, -0.345],
                    [-0.08, -0.194],
                    [-0.212, -0.141],
                    [-0.433, 0.159],
                    [-0.212, 0.133],
                    [-0.079, 0.186],
                    [0, 0.354],
                    [0, 0],
                  ],
                  v: [
                    [-1.414, 2.55],
                    [-0.981, 2.895],
                    [-0.018, 2.993],
                    [0.963, 2.904],
                    [1.405, 2.559],
                    [1.519, 1.791],
                    [1.405, 1.003],
                    [0.963, 0.508],
                    [-0.018, 0.067],
                    [-0.981, 0.508],
                    [-1.414, 0.986],
                    [-1.528, 1.764],
                    [-1.414, 2.542],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.124, 0.292],
                    [0, 0.592],
                    [-0.071, 0.203],
                    [-0.194, 0.168],
                    [-0.398, 0.221],
                    [0, 0],
                    [0.168, 0.141],
                    [0.062, 0.177],
                    [0, 0.301],
                    [-0.115, 0.248],
                    [-0.309, 0.097],
                    [-0.645, 0],
                    [-0.309, -0.098],
                    [-0.114, -0.238],
                    [0, -0.504],
                    [0.053, -0.177],
                    [0.168, -0.141],
                    [0.345, -0.177],
                    [0, 0],
                    [-0.194, -0.168],
                    [-0.07, -0.212],
                    [0, -0.345],
                    [0.124, -0.283],
                    [0.336, -0.106],
                    [0.689, 0],
                    [0.335, 0.106],
                  ],
                  o: [
                    [-0.336, -0.106],
                    [-0.124, -0.283],
                    [0, -0.354],
                    [0.07, -0.203],
                    [0.194, -0.168],
                    [0, 0],
                    [-0.336, -0.177],
                    [-0.168, -0.141],
                    [-0.061, -0.177],
                    [0, -0.504],
                    [0.115, -0.238],
                    [0.309, -0.098],
                    [0.645, 0],
                    [0.31, 0.097],
                    [0.115, 0.239],
                    [0, 0.301],
                    [-0.062, 0.185],
                    [-0.168, 0.141],
                    [0, 0],
                    [0.398, 0.221],
                    [0.194, 0.168],
                    [0.071, 0.212],
                    [0, 0.592],
                    [-0.124, 0.283],
                    [-0.336, 0.106],
                    [-0.689, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1.555, 3.637],
                    [-2.244, 3.046],
                    [-2.429, 1.729],
                    [-2.323, 0.889],
                    [-1.926, 0.322],
                    [-1.051, -0.26],
                    [-1.051, -0.322],
                    [-1.811, -0.808],
                    [-2.156, -1.294],
                    [-2.244, -2.019],
                    [-2.067, -3.143],
                    [-1.431, -3.645],
                    [-0.009, -3.787],
                    [1.422, -3.645],
                    [2.058, -3.143],
                    [2.235, -2.019],
                    [2.147, -1.294],
                    [1.802, -0.808],
                    [1.042, -0.322],
                    [1.042, -0.26],
                    [1.926, 0.322],
                    [2.323, 0.889],
                    [2.429, 1.719],
                    [2.244, 3.037],
                    [1.546, 3.628],
                    [0, 3.787],
                    [-1.537, 3.628],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [108.345, 105.048], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 5,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-2.438, 1.291],
                    [0.009, -3.739],
                    [0.998, -3.739],
                    [-1.502, 1.291],
                    [-1.502, 1.529],
                    [0.53, 1.529],
                    [0.53, -0.274],
                    [1.397, -0.274],
                    [1.397, 1.529],
                    [2.438, 1.529],
                    [2.438, 2.351],
                    [1.397, 2.351],
                    [1.397, 3.739],
                    [0.53, 3.739],
                    [0.53, 2.351],
                    [-2.438, 2.351],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [101.895, 104.999], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 2100,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Group 17',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 273, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 260.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 13,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [154.4, 374.5],
                          [154.25, 374.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 44,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [314.4, 374.5],
                          [0.5, 374.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 74,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [157.4, 520.5],
                          [157.4, 0.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -16,
      op: 2084,
      st: -16,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Group 16',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 280.15, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 267.65, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 14,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.05, 106.85],
                          [-3.2, 106.85],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 45,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.95, 106.85],
                          [-156.95, 106.85],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 75,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [9.85, 243.75],
                          [-9.85, -243.75],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.45, 267.65], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -15,
      op: 2085,
      st: -15,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Group 15',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [382, 287.3, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.5, 274.8, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.1, 99.7],
                          [-3.25, 99.7],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 46,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.9, 99.7],
                          [-157, 99.7],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 76,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [19.6, 227.5],
                          [-19.6, -227.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.5, 274.8], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -14,
      op: 2086,
      st: -14,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Group 14',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 294.35, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 281.85, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 16,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.05, 92.65],
                          [-3.2, 92.65],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 47,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.95, 92.65],
                          [-156.95, 92.65],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 77,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [29.45, 211.25],
                          [-29.45, -211.25],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.45, 281.85], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -13,
      op: 2087,
      st: -13,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Group 13',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 301.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 289, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 17,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.05, 85.5],
                          [-3.2, 85.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 48,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.95, 85.5],
                          [-156.95, 85.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 78,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [39.25, 195],
                          [-39.25, -195],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.45, 289], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -12,
      op: 2088,
      st: -12,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Group 12',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 308.65, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 296.15, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 18,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.05, 78.35],
                          [-3.2, 78.35],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 49,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.95, 78.35],
                          [-156.95, 78.35],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 79,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [49.05, 178.75],
                          [-49.05, -178.75],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.45, 296.15], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -11,
      op: 2089,
      st: -11,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Group 11',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 315.8, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 303.3, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 19,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.05, 71.2],
                          [-3.2, 71.2],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 50,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.95, 71.2],
                          [-156.95, 71.2],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 80,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [58.85, 162.5],
                          [-58.85, -162.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.45, 303.3], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -10,
      op: 2090,
      st: -10,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Group 10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 322.85, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 310.35, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 20,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.05, 64.15],
                          [-3.2, 64.15],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.95, 64.15],
                          [-156.95, 64.15],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [68.65, 146.25],
                          [-68.65, -146.25],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.45, 310.35], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -9,
      op: 2091,
      st: -9,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Group 9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 330, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 317.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 21,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.05, 57],
                          [-3.2, 57],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 52,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.95, 57],
                          [-156.95, 57],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 82,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [78.45, 130],
                          [-78.45, -130],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.45, 317.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -8,
      op: 2092,
      st: -8,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Group 8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 337.15, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 324.65, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 22,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.05, 49.85],
                          [-3.2, 49.85],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 53,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.95, 49.85],
                          [-156.95, 49.85],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 83,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [88.25, 113.75],
                          [-88.25, -113.75],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.45, 324.65], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -7,
      op: 2093,
      st: -7,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Group 7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 344.2, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 331.7, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 23,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.05, 42.8],
                          [-3.2, 42.8],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 54,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.95, 42.8],
                          [-156.95, 42.8],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 84,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [98.05, 97.5],
                          [-98.05, -97.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.45, 331.7], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -6,
      op: 2094,
      st: -6,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Group 6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 351.35, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 338.85, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 24,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.05, 35.65],
                          [-3.2, 35.65],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 55,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.95, 35.65],
                          [-156.95, 35.65],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 85,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [107.85, 81.25],
                          [-107.85, -81.25],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.45, 338.85], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -5,
      op: 2095,
      st: -5,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Group 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.9, 358.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.4, 346, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 25,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3, 28.5],
                          [-3.15, 28.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 56,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [157, 28.5],
                          [-156.9, 28.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 86,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [117.7, 65],
                          [-117.7, -65],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.4, 346], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -4,
      op: 2096,
      st: -4,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Group 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.9, 365.65, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.4, 353.15, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 26,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3, 21.35],
                          [-3.15, 21.35],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 57,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [157, 21.35],
                          [-156.9, 21.35],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 87,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [127.5, 48.75],
                          [-127.5, -48.75],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.4, 353.15], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -3,
      op: 2097,
      st: -3,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Group 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.9, 372.8, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.4, 360.3, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 27,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3, 14.2],
                          [-3.15, 14.2],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 58,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [157, 14.2],
                          [-156.9, 14.2],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 88,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [137.3, 32.5],
                          [-137.3, -32.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.4, 360.3], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -2,
      op: 2098,
      st: -2,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Group 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 379.85, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 367.35, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 28,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-3.05, 7.15],
                          [-3.2, 7.15],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 59,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [156.95, 7.15],
                          [-156.95, 7.15],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 89,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [147.15, 16.25],
                          [-147.15, -16.25],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [157.45, 367.35], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -1,
      op: 2099,
      st: -1,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'Group 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [381.95, 387.05, 0], ix: 2, l: 2 },
        a: { a: 0, k: [157.45, 374.55, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 29,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [154.4, 374.5],
                          [154.25, 374.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 60,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [314.4, 374.5],
                          [0.5, 374.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 2100,
      st: 0,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
}

import store from '../store'
import { Emitter } from '../core'
import { qs } from '../utils'

export default class Noise {
  constructor() {
    const { sizes } = store

    this.canvas = qs('.-noise')
    this.canvas.width = sizes.vw
    this.canvas.height = sizes.vh
    this.ctx = this.canvas.getContext('2d')

    this.on()
  }

  noise(ctx) {
    const w = ctx.canvas.width
    const h = ctx.canvas.height
    const iData = ctx.createImageData(w, h)
    const buffer32 = new Uint32Array(iData.data.buffer)
    const len = buffer32.length
    let i = 0

    for (let i = 0; i < len; i++) {
      if (Math.random() < 0.75) buffer32[i] = 0xf3f2feff
    }

    ctx.putImageData(iData, 0, 0)
  }

  tick = () => {
    this.noise(this.ctx)
  }

  resize = () => {
    const { sizes } = store
    this.canvas.width = sizes.vw
    this.canvas.height = sizes.vh
  }

  on() {
    Emitter.on('tick', this.tick)
    Emitter.on('resize', this.resize)
  }
}

export const drop = {
  v: '5.12.1',
  fr: 30,
  ip: 30,
  op: 91,
  w: 800,
  h: 800,
  nm: 'L - Drop',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: {
          k: [
            { s: [100], t: 30, i: { x: [1], y: [1] }, o: { x: [0], y: [0] } },
            { s: [100], t: 90, i: { x: [1], y: [1] }, o: { x: [0], y: [0] } },
          ],
        },
        r: {
          k: [
            { s: [0], t: 30, i: { x: [1], y: [1] }, o: { x: [0], y: [0] } },
            { s: [0], t: 90, i: { x: [1], y: [1] }, o: { x: [0], y: [0] } },
          ],
        },
        p: {
          k: [
            { s: [400, -60, 0], t: 31, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [400, 680, 0], t: 46, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [400, 668.6, 0], t: 47, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            {
              s: [400, 658.09, 0],
              t: 48,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 648.468, 0],
              t: 49,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 639.735, 0],
              t: 50,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 631.891, 0],
              t: 51,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 624.936, 0],
              t: 52,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 618.869, 0],
              t: 53,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 613.692, 0],
              t: 54,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 609.403, 0],
              t: 55,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 606.004, 0],
              t: 56,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 603.493, 0],
              t: 57,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 601.871, 0],
              t: 58,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 601.294, 0],
              t: 60,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 602.339, 0],
              t: 61,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 604.273, 0],
              t: 62,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 607.095, 0],
              t: 63,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 610.807, 0],
              t: 64,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 615.407, 0],
              t: 65,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 620.896, 0],
              t: 66,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 627.275, 0],
              t: 67,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 634.542, 0],
              t: 68,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 642.698, 0],
              t: 69,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 651.742, 0],
              t: 70,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 661.676, 0],
              t: 71,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 672.499, 0],
              t: 72,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 679.057, 0],
              t: 73,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 676.971, 0],
              t: 74,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 675.774, 0],
              t: 75,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 676.046, 0],
              t: 77,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 677.515, 0],
              t: 78,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
            {
              s: [400, 679.873, 0],
              t: 79,
              i: { x: 1, y: 1 },
              o: { x: 0, y: 0 },
            },
          ],
          l: 2,
        },
        a: {
          k: [
            { s: [0, 0, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, 0, 0], t: 90, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
          ],
          l: 2,
        },
        s: {
          k: [
            {
              s: [100, 100, 100],
              t: 30,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100, 100, 100],
              t: 90,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
          ],
          l: 2,
        },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: 'Jump - Scale',
          np: 9,
          mn: 'Pseudo/aHCxb79bbc95d',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: 'Enable',
              mn: 'Pseudo/aHCxb79bbc95d-0001',
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 7,
              nm: 'Jump',
              mn: 'Pseudo/aHCxb79bbc95d-0002',
              ix: 2,
              v: { a: 0, k: 0, ix: 2 },
            },
            {
              ty: 6,
              nm: 'Properties',
              mn: 'Pseudo/aHCxb79bbc95d-0003',
              ix: 3,
              v: 0,
            },
            {
              ty: 0,
              nm: 'Stretch',
              mn: 'Pseudo/aHCxb79bbc95d-0004',
              ix: 4,
              v: { a: 0, k: 60, ix: 4 },
            },
            {
              ty: 0,
              nm: 'Gravity',
              mn: 'Pseudo/aHCxb79bbc95d-0005',
              ix: 5,
              v: { a: 0, k: 8, ix: 5 },
            },
            {
              ty: 0,
              nm: 'Max Jumps',
              mn: 'Pseudo/aHCxb79bbc95d-0006',
              ix: 6,
              v: { a: 0, k: 8, ix: 6 },
            },
            { ty: 6, nm: '', mn: 'Pseudo/aHCxb79bbc95d-0007', ix: 7, v: 0 },
          ],
        },
        {
          ty: 5,
          nm: 'Jump - Position',
          np: 9,
          mn: 'Pseudo/aHCxb79bbc95d',
          ix: 2,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: 'Enable',
              mn: 'Pseudo/aHCxb79bbc95d-0001',
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 7,
              nm: 'Jump',
              mn: 'Pseudo/aHCxb79bbc95d-0002',
              ix: 2,
              v: { a: 0, k: 0, ix: 2 },
            },
            {
              ty: 6,
              nm: 'Properties',
              mn: 'Pseudo/aHCxb79bbc95d-0003',
              ix: 3,
              v: 0,
            },
            {
              ty: 0,
              nm: 'Stretch',
              mn: 'Pseudo/aHCxb79bbc95d-0004',
              ix: 4,
              v: { a: 0, k: 24, ix: 4 },
            },
            {
              ty: 0,
              nm: 'Gravity',
              mn: 'Pseudo/aHCxb79bbc95d-0005',
              ix: 5,
              v: { a: 0, k: 8, ix: 5 },
            },
            {
              ty: 0,
              nm: 'Max Jumps',
              mn: 'Pseudo/aHCxb79bbc95d-0006',
              ix: 6,
              v: { a: 0, k: 2, ix: 6 },
            },
            { ty: 6, nm: '', mn: 'Pseudo/aHCxb79bbc95d-0007', ix: 7, v: 0 },
          ],
        },
        {
          ty: 5,
          nm: 'Jump - Opacity',
          np: 9,
          mn: 'Pseudo/aHCxb79bbc95d',
          ix: 3,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: 'Enable',
              mn: 'Pseudo/aHCxb79bbc95d-0001',
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 7,
              nm: 'Jump',
              mn: 'Pseudo/aHCxb79bbc95d-0002',
              ix: 2,
              v: { a: 0, k: 0, ix: 2 },
            },
            {
              ty: 6,
              nm: 'Properties',
              mn: 'Pseudo/aHCxb79bbc95d-0003',
              ix: 3,
              v: 0,
            },
            {
              ty: 0,
              nm: 'Stretch',
              mn: 'Pseudo/aHCxb79bbc95d-0004',
              ix: 4,
              v: { a: 0, k: 60, ix: 4 },
            },
            {
              ty: 0,
              nm: 'Gravity',
              mn: 'Pseudo/aHCxb79bbc95d-0005',
              ix: 5,
              v: { a: 0, k: 8, ix: 5 },
            },
            {
              ty: 0,
              nm: 'Max Jumps',
              mn: 'Pseudo/aHCxb79bbc95d-0006',
              ix: 6,
              v: { a: 0, k: 8, ix: 6 },
            },
            { ty: 6, nm: '', mn: 'Pseudo/aHCxb79bbc95d-0007', ix: 7, v: 0 },
          ],
        },
        {
          ty: 5,
          nm: 'Jump - Rotation',
          np: 9,
          mn: 'Pseudo/aHCxb79bbc95d',
          ix: 4,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: 'Enable',
              mn: 'Pseudo/aHCxb79bbc95d-0001',
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 7,
              nm: 'Jump',
              mn: 'Pseudo/aHCxb79bbc95d-0002',
              ix: 2,
              v: { a: 0, k: 0, ix: 2 },
            },
            {
              ty: 6,
              nm: 'Properties',
              mn: 'Pseudo/aHCxb79bbc95d-0003',
              ix: 3,
              v: 0,
            },
            {
              ty: 0,
              nm: 'Stretch',
              mn: 'Pseudo/aHCxb79bbc95d-0004',
              ix: 4,
              v: { a: 0, k: 60, ix: 4 },
            },
            {
              ty: 0,
              nm: 'Gravity',
              mn: 'Pseudo/aHCxb79bbc95d-0005',
              ix: 5,
              v: { a: 0, k: 8, ix: 5 },
            },
            {
              ty: 0,
              nm: 'Max Jumps',
              mn: 'Pseudo/aHCxb79bbc95d-0006',
              ix: 6,
              v: { a: 0, k: 8, ix: 6 },
            },
            { ty: 6, nm: '', mn: 'Pseudo/aHCxb79bbc95d-0007', ix: 7, v: 0 },
          ],
        },
        {
          ty: 5,
          nm: 'Jump - Anchor Point',
          np: 9,
          mn: 'Pseudo/aHCxb79bbc95d',
          ix: 5,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: 'Enable',
              mn: 'Pseudo/aHCxb79bbc95d-0001',
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 7,
              nm: 'Jump',
              mn: 'Pseudo/aHCxb79bbc95d-0002',
              ix: 2,
              v: { a: 0, k: 0, ix: 2 },
            },
            {
              ty: 6,
              nm: 'Properties',
              mn: 'Pseudo/aHCxb79bbc95d-0003',
              ix: 3,
              v: 0,
            },
            {
              ty: 0,
              nm: 'Stretch',
              mn: 'Pseudo/aHCxb79bbc95d-0004',
              ix: 4,
              v: { a: 0, k: 60, ix: 4 },
            },
            {
              ty: 0,
              nm: 'Gravity',
              mn: 'Pseudo/aHCxb79bbc95d-0005',
              ix: 5,
              v: { a: 0, k: 8, ix: 5 },
            },
            {
              ty: 0,
              nm: 'Max Jumps',
              mn: 'Pseudo/aHCxb79bbc95d-0006',
              ix: 6,
              v: { a: 0, k: 8, ix: 6 },
            },
            { ty: 6, nm: '', mn: 'Pseudo/aHCxb79bbc95d-0007', ix: 7, v: 0 },
          ],
        },
        {
          ty: 5,
          nm: 'Jump - Fill 1 - Opacity',
          np: 9,
          mn: 'Pseudo/aHCxb79bbc95d',
          ix: 6,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: 'Enable',
              mn: 'Pseudo/aHCxb79bbc95d-0001',
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 7,
              nm: 'Jump',
              mn: 'Pseudo/aHCxb79bbc95d-0002',
              ix: 2,
              v: { a: 0, k: 0, ix: 2 },
            },
            {
              ty: 6,
              nm: 'Properties',
              mn: 'Pseudo/aHCxb79bbc95d-0003',
              ix: 3,
              v: 0,
            },
            {
              ty: 0,
              nm: 'Stretch',
              mn: 'Pseudo/aHCxb79bbc95d-0004',
              ix: 4,
              v: { a: 0, k: 60, ix: 4 },
            },
            {
              ty: 0,
              nm: 'Gravity',
              mn: 'Pseudo/aHCxb79bbc95d-0005',
              ix: 5,
              v: { a: 0, k: 8, ix: 5 },
            },
            {
              ty: 0,
              nm: 'Max Jumps',
              mn: 'Pseudo/aHCxb79bbc95d-0006',
              ix: 6,
              v: { a: 0, k: 8, ix: 6 },
            },
            { ty: 6, nm: '', mn: 'Pseudo/aHCxb79bbc95d-0007', ix: 7, v: 0 },
          ],
        },
      ],
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: { a: 0, k: [80, 80], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'fl',
          c: {
            a: 0,
            k: [0.717647075653, 0.717647075653, 0.717647075653, 1],
            ix: 4,
          },
          o: {
            k: [
              { s: [100], t: 30, i: { x: [1], y: [1] }, o: { x: [0], y: [0] } },
              { s: [100], t: 90, i: { x: [1], y: [1] }, o: { x: [0], y: [0] } },
            ],
          },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        },
      ],
      ip: 1,
      op: 901,
      st: 1,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Mask',
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [400, 774, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.05, 0.05], y: [1, 1] },
                    o: { x: [0.5, 0.5], y: [0, 0] },
                    t: 30,
                    s: [960, 0],
                  },
                  { t: 60, s: [960, 208] },
                ],
                ix: 2,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 30,
                    s: [0, 104],
                    to: [0, 0],
                    ti: [0, 0],
                  },
                  { t: 60, s: [0, 0] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.529411944221, 0.529411944221, 0.529411944221, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-0.66, -111.203], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Space Time',
      tt: 1,
      tp: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [400, 662, 0], ix: 2, l: 2 },
        a: { a: 0, k: [363, 102, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [22.022, -36.893],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-17.196, 28.807],
                          [0, 0],
                        ],
                        v: [
                          [54.945, -99.139],
                          [-3.5, 11.259],
                          [-54.945, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [22.642, -36.525],
                          [16.115, -29.221],
                        ],
                        o: [
                          [-19.001, 37.763],
                          [-17.667, 28.521],
                          [0, 0],
                        ],
                        v: [
                          [54.945, -99.139],
                          [0.001, 14.826],
                          [-54.945, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [22.022, -36.893],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-17.196, 28.807],
                          [0, 0],
                        ],
                        v: [
                          [54.945, -99.139],
                          [-3.5, 11.259],
                          [-54.945, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [22.642, -36.525],
                          [16.115, -29.221],
                        ],
                        o: [
                          [-19.001, 37.763],
                          [-17.667, 28.521],
                          [0, 0],
                        ],
                        v: [
                          [54.945, -99.139],
                          [0.001, 14.826],
                          [-54.945, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [122.977, 101.651], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [18.218, -41.04],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-11.798, 26.578],
                          [0, 0],
                        ],
                        v: [
                          [42.731, -99.139],
                          [-9.5, 25.224],
                          [-42.731, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [19.857, -40.272],
                          [11.632, -25.381],
                        ],
                        o: [
                          [-15.36, 41.439],
                          [-12.329, 24.984],
                          [0, 0],
                        ],
                        v: [
                          [42.731, -99.139],
                          [0, 26.753],
                          [-42.731, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [18.218, -41.04],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-11.798, 26.578],
                          [0, 0],
                        ],
                        v: [
                          [42.731, -99.139],
                          [-9.5, 25.224],
                          [-42.731, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [19.857, -40.272],
                          [11.632, -25.381],
                        ],
                        o: [
                          [-15.36, 41.439],
                          [-12.329, 24.984],
                          [0, 0],
                        ],
                        v: [
                          [42.731, -99.139],
                          [0, 26.753],
                          [-42.731, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [176.298, 101.674], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [13.868, -46.248],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-6.454, 21.524],
                          [0, 0],
                        ],
                        v: [
                          [30.519, -99.145],
                          [-11.001, 36.88],
                          [-30.519, 99.145],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [16.231, -44.508],
                          [6.455, -21.577],
                        ],
                        o: [
                          [-11.503, 45.406],
                          [-7.702, 21.11],
                          [0, 0],
                        ],
                        v: [
                          [30.519, -99.145],
                          [-0.001, 38.918],
                          [-30.519, 99.145],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [13.868, -46.248],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-6.454, 21.524],
                          [0, 0],
                        ],
                        v: [
                          [30.519, -99.145],
                          [-11.001, 36.88],
                          [-30.519, 99.145],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [16.231, -44.508],
                          [6.455, -21.577],
                        ],
                        o: [
                          [-11.503, 45.406],
                          [-7.702, 21.11],
                          [0, 0],
                        ],
                        v: [
                          [30.519, -99.145],
                          [-0.001, 38.918],
                          [-30.519, 99.145],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [229.632, 101.692], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [9.562, -49.098],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-3.472, 17.828],
                          [0, 0],
                        ],
                        v: [
                          [18.32, -99.157],
                          [-8.5, 46.853],
                          [-18.32, 99.157],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [10.255, -48.58],
                          [2.988, -17.913],
                        ],
                        o: [
                          [-7.179, 48.919],
                          [-3.743, 17.773],
                          [0, 0],
                        ],
                        v: [
                          [18.32, -99.157],
                          [0, 48.382],
                          [-18.32, 99.157],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [9.562, -49.098],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-3.472, 17.828],
                          [0, 0],
                        ],
                        v: [
                          [18.32, -99.157],
                          [-8.5, 46.853],
                          [-18.32, 99.157],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [10.255, -48.58],
                          [2.988, -17.913],
                        ],
                        o: [
                          [-7.179, 48.919],
                          [-3.743, 17.773],
                          [0, 0],
                        ],
                        v: [
                          [18.32, -99.157],
                          [0, 48.382],
                          [-18.32, 99.157],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [282.938, 101.703], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [6.107, -99.163],
                          [-6.107, 99.163],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [2.814, -65.77],
                        ],
                        o: [
                          [-3.22, 66.062],
                          [0, 0],
                        ],
                        v: [
                          [6.107, -99.163],
                          [-6.107, 99.163],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [6.107, -99.163],
                          [-6.107, 99.163],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [2.814, -65.77],
                        ],
                        o: [
                          [-3.22, 66.062],
                          [0, 0],
                        ],
                        v: [
                          [6.107, -99.163],
                          [-6.107, 99.163],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [336.258, 101.698], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-6.107, -99.163],
                          [6.107, 99.163],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-2.814, -65.77],
                        ],
                        o: [
                          [3.22, 66.062],
                          [0, 0],
                        ],
                        v: [
                          [-6.107, -99.163],
                          [6.107, 99.163],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-6.107, -99.163],
                          [6.107, 99.163],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-2.814, -65.77],
                        ],
                        o: [
                          [3.22, 66.062],
                          [0, 0],
                        ],
                        v: [
                          [-6.107, -99.163],
                          [6.107, 99.163],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [389.592, 101.698], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-8.412, -48.589],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [3.098, 17.897],
                          [0, 0],
                        ],
                        v: [
                          [-18.319, -99.157],
                          [8.499, 48.891],
                          [18.319, 99.157],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-10.255, -48.58],
                          [-2.988, -17.913],
                        ],
                        o: [
                          [7.18, 48.919],
                          [3.743, 17.773],
                          [0, 0],
                        ],
                        v: [
                          [-18.319, -99.157],
                          [-0.001, 48.382],
                          [18.319, 99.157],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-8.412, -48.589],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [3.098, 17.897],
                          [0, 0],
                        ],
                        v: [
                          [-18.319, -99.157],
                          [8.499, 48.891],
                          [18.319, 99.157],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-10.255, -48.58],
                          [-2.988, -17.913],
                        ],
                        o: [
                          [7.18, 48.919],
                          [3.743, 17.773],
                          [0, 0],
                        ],
                        v: [
                          [-18.319, -99.157],
                          [-0.001, 48.382],
                          [18.319, 99.157],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [442.913, 101.703], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-13.233, -44.719],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [6.376, 21.548],
                          [0, 0],
                        ],
                        v: [
                          [-30.518, -99.145],
                          [10.501, 37.389],
                          [30.518, 99.145],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-16.231, -44.508],
                          [-6.455, -21.577],
                        ],
                        o: [
                          [11.503, 45.406],
                          [7.702, 21.11],
                          [0, 0],
                        ],
                        v: [
                          [-30.518, -99.145],
                          [0.001, 38.918],
                          [30.518, 99.145],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-13.233, -44.719],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [6.376, 21.548],
                          [0, 0],
                        ],
                        v: [
                          [-30.518, -99.145],
                          [10.501, 37.389],
                          [30.518, 99.145],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-16.231, -44.508],
                          [-6.455, -21.577],
                        ],
                        o: [
                          [11.503, 45.406],
                          [7.702, 21.11],
                          [0, 0],
                        ],
                        v: [
                          [-30.518, -99.145],
                          [0.001, 38.918],
                          [30.518, 99.145],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [496.232, 101.692], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-18.052, -41.199],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [11.181, 25.518],
                          [0, 0],
                        ],
                        v: [
                          [-42.732, -99.139],
                          [8.5, 26.243],
                          [42.732, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-19.857, -40.272],
                          [-11.633, -25.381],
                        ],
                        o: [
                          [15.36, 41.439],
                          [12.329, 24.984],
                          [0, 0],
                        ],
                        v: [
                          [-42.732, -99.139],
                          [0, 26.753],
                          [42.732, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-18.052, -41.199],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [11.181, 25.518],
                          [0, 0],
                        ],
                        v: [
                          [-42.732, -99.139],
                          [8.5, 26.243],
                          [42.732, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-19.857, -40.272],
                          [-11.633, -25.381],
                        ],
                        o: [
                          [15.36, 41.439],
                          [12.329, 24.984],
                          [0, 0],
                        ],
                        v: [
                          [-42.732, -99.139],
                          [0, 26.753],
                          [42.732, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [549.552, 101.674], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-20.887, -39.441],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [15.701, 29.649],
                          [0, 0],
                        ],
                        v: [
                          [-54.945, -99.139],
                          [5, 13.807],
                          [54.944, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-22.642, -36.525],
                          [-16.115, -29.221],
                        ],
                        o: [
                          [19.002, 37.763],
                          [17.667, 28.521],
                          [0, 0],
                        ],
                        v: [
                          [-54.945, -99.139],
                          [0, 14.826],
                          [54.944, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-20.887, -39.441],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [15.701, 29.649],
                          [0, 0],
                        ],
                        v: [
                          [-54.945, -99.139],
                          [5, 13.807],
                          [54.944, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-22.642, -36.525],
                          [-16.115, -29.221],
                        ],
                        o: [
                          [19.002, 37.763],
                          [17.667, 28.521],
                          [0, 0],
                        ],
                        v: [
                          [-54.945, -99.139],
                          [0, 14.826],
                          [54.944, 99.139],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [602.886, 101.651], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 10',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [156.146, 2.252],
                          [0, 0],
                        ],
                        v: [
                          [-234.051, -1.126],
                          [234.051, -1.126],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-156.146, 2.252],
                        ],
                        o: [
                          [156.146, 2.252],
                          [0, 0],
                        ],
                        v: [
                          [-234.051, -1.126],
                          [234.051, -1.126],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [156.146, 2.252],
                          [0, 0],
                        ],
                        v: [
                          [-234.051, -1.126],
                          [234.051, -1.126],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-156.146, 2.252],
                        ],
                        o: [
                          [156.146, 2.252],
                          [0, 0],
                        ],
                        v: [
                          [-234.051, -1.126],
                          [234.051, -1.126],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [362.932, 15.704], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 11',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [162.817, 6.302],
                          [0, 0],
                        ],
                        v: [
                          [-242.558, -3.151],
                          [242.558, -3.151],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-162.832, 6.302],
                        ],
                        o: [
                          [162.817, 6.302],
                          [0, 0],
                        ],
                        v: [
                          [-242.558, -3.151],
                          [242.558, -3.151],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [162.817, 6.302],
                          [0, 0],
                        ],
                        v: [
                          [-242.558, -3.151],
                          [242.558, -3.151],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-162.832, 6.302],
                        ],
                        o: [
                          [162.817, 6.302],
                          [0, 0],
                        ],
                        v: [
                          [-242.558, -3.151],
                          [242.558, -3.151],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [362.925, 31.745], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 12',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-84.331, 0],
                          [-55.791, 0.568],
                        ],
                        o: [
                          [0, 0],
                          [84.332, 0],
                          [0, 0],
                        ],
                        v: [
                          [-251.704, -7.352],
                          [-0.001, -5.648],
                          [251.703, -7.352],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-84.331, 0],
                          [-83.882, 5.952],
                        ],
                        o: [
                          [83.882, 5.952],
                          [84.332, 0],
                          [0, 0],
                        ],
                        v: [
                          [-251.704, -7.352],
                          [0, 7.352],
                          [251.703, -7.352],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-84.331, 0],
                          [-55.791, 0.568],
                        ],
                        o: [
                          [0, 0],
                          [84.332, 0],
                          [0, 0],
                        ],
                        v: [
                          [-251.704, -7.352],
                          [-0.001, -5.648],
                          [251.703, -7.352],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-84.331, 0],
                          [-83.882, 5.952],
                        ],
                        o: [
                          [83.882, 5.952],
                          [84.332, 0],
                          [0, 0],
                        ],
                        v: [
                          [-251.704, -7.352],
                          [0, 7.352],
                          [251.703, -7.352],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [362.932, 51.478], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 13',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-87.943, 0],
                          [-57.708, 1.285],
                        ],
                        o: [
                          [57.875, 0.266],
                          [87.943, 0],
                          [0, 0],
                        ],
                        v: [
                          [-261.567, -12.428],
                          [0, -11.63],
                          [261.067, -15.486],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-87.943, 0],
                          [-86.812, 9.277],
                        ],
                        o: [
                          [86.812, 9.277],
                          [87.943, 0],
                          [0, 0],
                        ],
                        v: [
                          [-261.567, -12.428],
                          [0, 12.428],
                          [261.567, -12.428],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-87.943, 0],
                          [-57.708, 1.285],
                        ],
                        o: [
                          [57.875, 0.266],
                          [87.943, 0],
                          [0, 0],
                        ],
                        v: [
                          [-261.567, -12.428],
                          [0, -11.63],
                          [261.067, -15.486],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-87.943, 0],
                          [-86.812, 9.277],
                        ],
                        o: [
                          [86.812, 9.277],
                          [87.943, 0],
                          [0, 0],
                        ],
                        v: [
                          [-261.567, -12.428],
                          [0, 12.428],
                          [261.567, -12.428],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [362.932, 73.101], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 14',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-91.961, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [91.961, 0],
                          [0, 0],
                        ],
                        v: [
                          [-272.228, -18.03],
                          [0.5, -17.133],
                          [272.228, -18.03],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-91.961, 0],
                          [-89.916, 12.615],
                        ],
                        o: [
                          [89.93, 12.615],
                          [91.961, 0],
                          [0, 0],
                        ],
                        v: [
                          [-272.228, -18.03],
                          [0, 18.029],
                          [272.228, -18.03],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-91.961, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [91.961, 0],
                          [0, 0],
                        ],
                        v: [
                          [-272.228, -18.03],
                          [0.5, -17.133],
                          [272.228, -18.03],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-91.961, 0],
                          [-89.916, 12.615],
                        ],
                        o: [
                          [89.93, 12.615],
                          [91.961, 0],
                          [0, 0],
                        ],
                        v: [
                          [-272.228, -18.03],
                          [0, 18.029],
                          [272.228, -18.03],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [362.918, 95.682], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 15',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-96.53, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [96.546, 0],
                          [0, 0],
                        ],
                        v: [
                          [-283.802, -23.205],
                          [0, -20.621],
                          [283.802, -23.205],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-96.53, 0],
                          [-93.614, 15.03],
                        ],
                        o: [
                          [93.585, 15.03],
                          [96.546, 0],
                          [0, 0],
                        ],
                        v: [
                          [-283.802, -23.205],
                          [0, 23.205],
                          [283.802, -23.205],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-96.53, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [96.546, 0],
                          [0, 0],
                        ],
                        v: [
                          [-283.802, -23.205],
                          [0, -20.621],
                          [283.802, -23.205],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-96.53, 0],
                          [-93.614, 15.03],
                        ],
                        o: [
                          [93.585, 15.03],
                          [96.546, 0],
                          [0, 0],
                        ],
                        v: [
                          [-283.802, -23.205],
                          [0, 23.205],
                          [283.802, -23.205],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [362.932, 117.697], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 16',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-101.36, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [101.36, 0],
                          [0, 0],
                        ],
                        v: [
                          [-296.408, -25.428],
                          [-0.501, -24.513],
                          [296.408, -25.428],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-101.36, 0],
                          [-98.402, 14.972],
                        ],
                        o: [
                          [98.415, 14.984],
                          [101.36, 0],
                          [0, 0],
                        ],
                        v: [
                          [-296.408, -25.428],
                          [-0.001, 25.428],
                          [296.408, -25.428],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-101.36, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [101.36, 0],
                          [0, 0],
                        ],
                        v: [
                          [-296.408, -25.428],
                          [-0.501, -24.513],
                          [296.408, -25.428],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-101.36, 0],
                          [-98.402, 14.972],
                        ],
                        o: [
                          [98.415, 14.984],
                          [101.36, 0],
                          [0, 0],
                        ],
                        v: [
                          [-296.408, -25.428],
                          [-0.001, 25.428],
                          [296.408, -25.428],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [362.932, 136.735], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 17',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-105.088, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [105.088, 0],
                          [0, 0],
                        ],
                        v: [
                          [-310.172, -22.09],
                          [0.5, -20.716],
                          [310.172, -22.09],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-105.088, 0],
                          [-103.318, 11.669],
                        ],
                        o: [
                          [103.333, 11.669],
                          [105.088, 0],
                          [0, 0],
                        ],
                        v: [
                          [-310.172, -22.09],
                          [0, 22.09],
                          [310.172, -22.09],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-105.088, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [105.088, 0],
                          [0, 0],
                        ],
                        v: [
                          [-310.172, -22.09],
                          [0.5, -20.716],
                          [310.172, -22.09],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-105.088, 0],
                          [-103.318, 11.669],
                        ],
                        o: [
                          [103.333, 11.669],
                          [105.088, 0],
                          [0, 0],
                        ],
                        v: [
                          [-310.172, -22.09],
                          [0, 22.09],
                          [310.172, -22.09],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [362.917, 151.79], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 18',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-109.381, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [109.382, 0],
                          [0, 0],
                        ],
                        v: [
                          [-325.302, -14.663],
                          [0, -14.384],
                          [325.302, -14.663],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-109.381, 0],
                          [-108.816, 6.15],
                        ],
                        o: [
                          [108.816, 6.161],
                          [109.382, 0],
                          [0, 0],
                        ],
                        v: [
                          [-325.302, -14.663],
                          [0, 14.663],
                          [325.302, -14.663],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-109.381, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [109.382, 0],
                          [0, 0],
                        ],
                        v: [
                          [-325.302, -14.663],
                          [0, -14.384],
                          [325.302, -14.663],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-109.381, 0],
                          [-108.816, 6.15],
                        ],
                        o: [
                          [108.816, 6.161],
                          [109.382, 0],
                          [0, 0],
                        ],
                        v: [
                          [-325.302, -14.663],
                          [0, 14.663],
                          [325.302, -14.663],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [362.932, 164.527], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 19',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-114.197, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [114.197, 0],
                          [0, 0],
                        ],
                        v: [
                          [-341.953, -5.461],
                          [0.5, -6.77],
                          [341.953, -5.461],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-114.197, 0],
                          [-114.154, 0.653],
                        ],
                        o: [
                          [114.153, 0.653],
                          [114.197, 0],
                          [0, 0],
                        ],
                        v: [
                          [-341.953, -5.461],
                          [0, 5.461],
                          [341.953, -5.461],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-114.197, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [114.197, 0],
                          [0, 0],
                        ],
                        v: [
                          [-341.953, -5.461],
                          [0.5, -6.77],
                          [341.953, -5.461],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [-114.197, 0],
                          [-114.154, 0.653],
                        ],
                        o: [
                          [114.153, 0.653],
                          [114.197, 0],
                          [0, 0],
                        ],
                        v: [
                          [-341.953, -5.461],
                          [0, 5.461],
                          [341.953, -5.461],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [362.918, 178.886], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 20',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 41,
                    s: [
                      {
                        i: [
                          [-43.515, 66.844],
                          [-150.749, 0.058],
                          [-44.574, -66.388],
                          [240.288, -0.175],
                        ],
                        o: [
                          [150.749, 0.058],
                          [43.515, 66.855],
                          [-240.274, -0.175],
                          [44.574, -66.4],
                        ],
                        v: [
                          [-226.124, -99.163],
                          [226.124, -99.163],
                          [360.425, 99.163],
                          [-360.425, 99.163],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [
                      {
                        i: [
                          [-43.515, 66.844],
                          [-150.749, 0.058],
                          [-44.574, -66.388],
                          [240.288, -0.175],
                        ],
                        o: [
                          [150.749, 0.058],
                          [43.515, 66.855],
                          [-240.274, -0.175],
                          [44.574, -66.4],
                        ],
                        v: [
                          [-226.124, -99.163],
                          [226.124, -99.163],
                          [360.425, 99.163],
                          [-360.425, 99.163],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.05, y: 1 },
                    o: { x: 0.5, y: 0 },
                    t: 61,
                    s: [
                      {
                        i: [
                          [-43.515, 66.844],
                          [-150.749, 0.058],
                          [-44.574, -66.388],
                          [240.288, -0.175],
                        ],
                        o: [
                          [150.749, 0.058],
                          [43.515, 66.855],
                          [-240.274, -0.175],
                          [44.574, -66.4],
                        ],
                        v: [
                          [-226.124, -99.163],
                          [226.124, -99.163],
                          [360.425, 99.163],
                          [-360.425, 99.163],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 81,
                    s: [
                      {
                        i: [
                          [-43.515, 66.844],
                          [-150.749, 0.058],
                          [-44.574, -66.388],
                          [240.288, -0.175],
                        ],
                        o: [
                          [150.749, 0.058],
                          [43.515, 66.855],
                          [-240.274, -0.175],
                          [44.574, -66.4],
                        ],
                        v: [
                          [-226.124, -99.163],
                          [226.124, -99.163],
                          [360.425, 99.163],
                          [-360.425, 99.163],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.529411764706, 0.529411764706, 0.529411764706, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [363.579, 102.633], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 98.115], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 21',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 21,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 1,
      op: 2101,
      st: 1,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
}

import store from '../store'
import { bounds } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import gsap from 'gsap'

export default class Stick {
  constructor(obj = {}) {
    this.isSmooth = store.flags.smooth

    this.dom = {
      container: obj.container,
      sticky: obj.sticky,
      items: obj.items,
    }

    this.name = obj.container.dataset.stick

    this.settings = {
      fullWidth: 0,
      fullHeight: 0,
      width: 0,
      posX: 0,
      posY: 0,
      enter: 0,
      totalItems: 0,
    }

    this.state = {
      fixed: false,
    }

    this.progress = 0

    this.init()
  }

  setup() {
    const { items, container } = this.dom
    const { sizes } = store
    const rect = bounds(items[0])
    const fullHeight = bounds(container).height
    const totalItems = items.length

    this.settings.totalItems = totalItems
    this.settings.width = rect.width
    this.settings.fullWidth = totalItems * rect.width - rect.width
    this.settings.fullHeight = fullHeight - sizes.vh
  }

  stick() {
    const { container } = this.dom

    ScrollTrigger.create({
      trigger: container,
      start: 'top +=50%',
      end: 'top top',
      scrub: true,
      onEnter: () => {
        //this.beforeEnter()
      },
      onUpdate: (self) => {
        this.beforeEnter(self)
      },
    })

    ScrollTrigger.create({
      trigger: container,
      start: 'top top+=16',
      end: 'bottom bottom+=16',
      scrub: true,
      onEnter: () => {
        this.onEnter()
      },
      onEnterBack: () => {
        this.onEnterBack()
      },
      onLeaveBack: () => {
        this.onLeaveBack()
      },
      onLeave: () => {
        this.onLeave()
      },
      onUpdate: (self) => {
        this.onUpdate(self)
      },
      onRefresh: () => {
        this.onRefresh()
      },
    })
  }

  beforeEnter(self) {
    this.settings.enter = self.progress
  }

  onEnter() {
    const { sticky } = this.dom
    this.state.fixed = true
    if (!this.isSmooth) {
      sticky.classList.add('-fixed')
      gsap.set(sticky, { top: 0 })
    }
  }

  onEnterBack() {
    const { sticky } = this.dom
    this.state.fixed = true

    if (!this.isSmooth) {
      sticky.classList.add('-fixed')
      gsap.set(sticky, { top: 0, position: '' })
    }
  }

  onLeave() {
    const { sticky } = this.dom
    const { totalItems, fullHeight } = this.settings

    this.state.fixed = false
    if (!this.isSmooth) {
      sticky.classList.remove('-fixed')
      gsap.set(sticky, { top: `${fullHeight}px`, position: 'relative' })
    }
  }

  onLeaveBack() {
    const { sticky } = this.dom

    this.state.fixed = false
    if (!this.isSmooth) {
      sticky.classList.remove('-fixed')
      gsap.set(sticky, { top: 0 })
    }
  }

  onUpdate(self) {
    const { sticky } = this.dom
    const { fullWidth, fullHeight } = this.settings

    this.progress = self.progress

    if (this.isSmooth) {
      this.settings.posY = self.progress * fullHeight
      this.settings.posX = self.progress * fullWidth
      gsap.set(sticky, { y: this.settings.posY })
    } else {
      this.settings.posY = self.progress * fullHeight
      this.settings.posX = self.progress * fullWidth
      // gsap.set(sticky, { y: this.settings.posY })
    }
  }

  onRefresh() {}

  resize = () => {
    console.log('resize')
    this.setup()
  }

  init() {
    this.setup()
    this.stick()
  }
}

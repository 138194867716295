import Module from './module'
import { Emitter } from '../core'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { qs } from '../utils'
import gsap from 'gsap'

export default class HeroNews extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      page: qs('.single') || qs('.page'),
      asset: qs('.newshero-asset', el),
      button: qs('.newshero-link', el),
    })
  }

  setAnimation(splitText) {
    const { page, asset, button } = this.dom

    gsap.set(page, { autoAlpha: 1 })

    const title = splitText[0].chars ? splitText[0].chars : null
    const subtitle = splitText[1] ? splitText[1].words : splitText[0].words
    const excerpt = splitText[2] ? splitText[2].words : null

    const hero = gsap.timeline({
      paused: true,
      onComplete: () => {
        Emitter.emit('animateIn:ended')
      },
    })

    if (title && title.length > 0) {
      hero.from(title, {
        duration: 0.8,
        yPercent: 100,
        stagger: 0.1,
        ease: 'power3.out',
      })
    }

    hero.fromTo(asset, { autoAlpha: 0 }, { duration: 1, autoAlpha: 1 }, 0.25)

    hero.play()

    this.tl = gsap.timeline({ paused: true })
    subtitle &&
      this.tl.from(subtitle, { autoAlpha: 0, yPercent: 40, stagger: 0.1 })
    excerpt &&
      this.tl.from(
        excerpt,
        { autoAlpha: 0, yPercent: 20, stagger: 0.1 },
        '-=0.5',
      )
    button && this.tl.from(button, { autoAlpha: 0, yPercent: 20 }, '-=0.4')
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    ScrollTrigger.create({
      trigger: el,
      start: 'center center+=15%',
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })
  }
}

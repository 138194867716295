import Module from './module'
import { Emitter } from '../core'
import { qs } from '../utils'
import gsap from 'gsap'

export default class HeroTitle extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    Object.assign(this.dom, {
      page: qs('.page'),
    })
  }

  setAnimation(splitText) {
    const { page } = this.dom

    gsap.set(page, { autoAlpha: 1 })

    const title = splitText[0].chars

    const hero = gsap.timeline({
      paused: true,
      onComplete: () => {
        Emitter.emit('animateIn:ended')
      },
    })

    hero.from(title, {
      duration: 0.5,
      yPercent: 100,
      stagger: 0.05,
    })

    hero.play()
  }
}

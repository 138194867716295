import Module from './module'
import { Emitter } from '../core'
import { HeroGL } from '../components'
import { qs } from '../utils'
import gsap from 'gsap'

export default class Hero extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      page: qs('.page'),
      title: qs('.hero-title svg', el),
      description: qs('.hero-description .-p1', el),
    })

    this.heroGL = new HeroGL({
      container: qs('.hero-art', el),
      canvas: qs('.hero-gl', el),
    })

    this.setAnimation()
  }

  setAnimation() {
    const { page, title, description } = this.dom

    gsap.set(page, { autoAlpha: 1 })

    const hero = gsap.timeline({
      paused: true,
      onComplete: () => {
        Emitter.emit('animateIn:ended')
      },
    })

    hero.to('html', {
      duration: 1.8,
      '--sides': '0%',
      ease: 'power3.inOut',
    })

    hero.to(
      'html',
      { duration: 2, '--tops': '0%', ease: 'power3.inOut' },
      '-=1.8',
    )
    hero.from(title, { duration: 0.5, y: '100%' }, '-=0.5')
    hero.from(description, { duration: 0.5, y: '100%' }, '-=0.3')
    hero.play()
  }
}

/* Support
   Some helpfull support functions
   ========================================================================== */

export const supportWebp = () => {
  const el = document.createElement('canvas')
  if (!!(el.getContext && el.getContext('2d'))) {
    return el.toDataURL('image/webp').indexOf('data:image/webp') == 0
  } else {
    return false
  }
}

export const supportMouseTouch = () => {
  return {
    hasWheelEvent: 'onwheel' in document,
    hasMouseWheelEvent: 'onmousewheel' in document,
    hasTouch:
      'ontouchstart' in window ||
      window.TouchEvent ||
      (window.DocumentTouch && document instanceof DocumentTouch),
    hasTouchWin: navigator.msMaxTouchPoints && navigator.msMaxTouchPoints > 1,
    hasPointer: !!window.navigator.msPointerEnabled,
    hasKeyDown: 'onkeydown' in document,
    isFirefox: navigator.userAgent.indexOf('Firefox') > -1,
  }
}

import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { qsa } from '../utils'
import gsap from 'gsap'

export default class Team extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      assets: qsa('.team-asset', el),
      names: qsa('.-p2', el),
      text: qsa('.team-text', el),
      links: qsa('.team-link', el),
    })
  }

  setAnimation(splitText) {
    const { assets, names, text, links } = this.dom
    const title = splitText[0].chars

    this.tl = gsap.timeline({ paused: true })

    this.tl.from(
      title,
      {
        duration: 0.6,
        yPercent: 101,
        stagger: 0.035,
        ease: 'power2.inOut',
      },
      0,
    )

    this.tl.from(
      assets,
      { duration: 1, y: 300, stagger: 0.05, ease: 'power2.inOut' },
      '-=0.8',
    )

    names.forEach((title) => {
      const lines = qsa('.line-0', title)
      this.tl.from(
        lines,
        {
          duration: 0.6,
          y: 30,
          autoAlpha: 0,
          stagger: 0.05,
          ease: 'power2.inOut',
        },
        '-=0.8',
      )
    })

    text.forEach((title) => {
      const lines = qsa('.line-0', title)
      this.tl.from(
        lines,
        {
          duration: 0.6,
          y: 30,
          autoAlpha: 0,
          stagger: 0.03,
          ease: 'power2.inOut',
        },
        '-=0.8',
      )
    })

    this.tl.from(
      links,
      {
        duration: 0.6,
        y: 30,
        autoAlpha: 0,
        stagger: 0.05,
        ease: 'power2.inOut',
      },
      '-=0.5',
    )
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=70%',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })
  }
}

import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { qsa } from '../utils'
import gsap from 'gsap'

export default class Foundation extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    const { el } = this.dom
    Object.assign(this.dom, {
      lines: qsa('.foundation-statements .line', el),
      cross: qsa('.foundation-bg .line', el),
    })
  }

  setAnimation(splitText) {
    // * Setting up Animation when SplitText is available

    const { lines, cross } = this.dom
    const titleLeft = splitText[0].lines
    const titleRight = splitText[1].lines
    const description = splitText[2].lines

    // * Setup Scroll Animation

    this.tl = gsap.timeline({ paused: true })
    titleLeft.forEach((line, i) => {
      const charsLeft = qsa('.char-1', line)
      const charsRight = qsa('.char-1', titleRight[i])
      this.tl.from(
        [charsLeft, charsRight],
        {
          duration: 0.6,
          y: ['101%', '-101%'],
          stagger: 0.025,
          ease: 'power2.inOut',
        },
        `-=${i * 1.2}`,
      )
    })

    this.tl.from(lines, { duration: 0.8, scaleX: 0 }, 0)
    this.tl.from(cross, { duration: 0.8, scaleY: 0 }, 0)
    this.tl.from(
      description,
      {
        duration: 0.5,
        y: 20,
        autoAlpha: 0,
        stagger: 0.1,
      },
      '-=0.5',
    )
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=50%',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })
  }
}

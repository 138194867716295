import store from '../store'
import { Emitter } from '.'
import debounce from 'lodash.debounce'
import { Sniffer, getWindowSizes, getViewport, setVH } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'

export default class GlobalResize {
  constructor() {
    this.state = {
      resize: false,
    }
    this.init()
  }

  resize = () => {
    const { width, height } = getViewport()

    const update = width == store.sizes.vw

    Sniffer.update()
    this.reload()
    this.state.resize = true
    store.flags.resize = true
    store.sizes.vw = width
    store.sizes.vh = height
    store.breakpoints = getWindowSizes()
    store.sniff = Sniffer.sniff

    if (update && store.sniff.isDevice) {
    } else {
      setVH()
    }

    this.addClasses()

    Emitter.emit('resize')
    ScrollTrigger.refresh()
    this.state.resize = false
    store.flags.resize = false
  }

  reload() {
    const { sniff } = store

    if (
      (!sniff.isDevice && Sniffer.isDevice) ||
      (sniff.isDevice && !Sniffer.isDevice)
    )
      location.reload()
  }

  addClasses() {
    const { sniff, body, sizes, msgs } = store
    if (sniff.isDevice) {
      body.classList.add('is-device')
      if (sizes.vw > sizes.vh) {
        body.classList.remove('is-portrait')
        body.classList.add('is-landscape')
        store.orientation = 'landscape'
      } else {
        body.classList.remove('is-landscape')
        body.classList.add('is-portrait')
        store.orientation = 'portrait'
      }

      if (store.orientation == 'landscape' && sniff.isPhone) {
        msgs.rotate.classList.add('--active')
      } else {
        msgs.rotate.classList.remove('--active')
      }
    } else {
      body.classList.add('is-desktop')
    }
  }

  on() {
    window.addEventListener('resize', debounce(this.resize, 200))
  }

  init() {
    this.on()
    this.resize()
    setVH()
  }
}

import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { qs } from '../utils'
import gsap from 'gsap'

export default class Statement extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      line: qs('.line', el),
    })
  }

  setAnimation(splitText) {
    const { line } = this.dom
    const title = splitText[0].words
    const description = splitText[1].lines

    this.tl = gsap.timeline({ paused: true })

    this.tl.from(title, {
      duration: 0.5,
      y: 40,
      autoAlpha: 0,
      stagger: 0.025,
    })

    this.tl.from(line, {
      duration: 0.5,
      scaleX: 0,
      ease: 'power2.inOut',
    })
    this.tl.from(
      description,
      {
        duration: 0.5,
        y: 20,
        autoAlpha: 0,
        stagger: 0.1,
      },
      '-=0.3',
    )
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=50%',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })
  }
}

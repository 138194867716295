import store from '../store'
import * as Taxi from '@unseenco/taxi'
import { Page } from '../renders'
import { Fade } from '../transitions'
import { CustomEase } from '../vendor/CustomEase'
import { qs, Sniffer, Grid, getViewport, getWindowSizes } from '../utils'
import { LottieAnimate } from '../animations'
import { intro } from '../lottie'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import {
  Emitter,
  GlobalVRaf,
  VScroll,
  Mouse,
  GlobalResize,
  AssetLoader,
} from './'
import { Noise } from '../components'
import lazySizes from 'lazysizes'
import gsap from 'gsap'
let singleton = null

export default class Core {
  constructor(obj = {}) {
    // Need to find a way to make this work with Obj
    const vs = true

    if (!singleton) {
      singleton = this
    } else {
      return singleton
    }

    this.setupStore()
    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(CustomEase)

    this.ready = false

    // Store
    this.store = store
    // Grid cmd+g to activate
    this.grid = new Grid({
      background: '#a25b4c',
      desktop: {
        columns: 18,
      },
      mobile: {
        columns: 4,
        gutter: 16,
        size: 390,
      },
    })
    // Taxi
    this.taxi = new Taxi.Core({
      links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore])',
      renderers: {
        default: Page,
        page: Page,
      },
      transitions: {
        default: Fade,
      },
    })
    // Global Request Animation Frame
    this.raf = new GlobalVRaf()
    // Global Scroll
    this.scroll = new VScroll({ smooth: !store.sniff.isDevice })
    // Global Mouse
    this.mouse = new Mouse()
    // Global Resize
    this.resize = new GlobalResize()
    // Asset Loader (Needs a data file data.yml)
    this.loader = new AssetLoader()
    // Ease for the Project
    this.pageEasing = CustomEase.create(
      'custom',
      'M0,0 C0.1,0 0.148,0.034 0.182,0.136 0.216,0.238 0.239,0.685 0.448,0.886 0.55,0.984 0.704,1 1,1',
    )

    this.setupLazySizes()
    this.init()
  }

  setupStore() {
    // Initial Settings
    store.sizes.vw = getViewport().width
    store.sizes.vh = getViewport().height
    store.breakpoints = getWindowSizes()
    store.sniff = Sniffer.sniff
    store.msgs.rotate = qs('.-rotate')
  }

  setupLazySizes() {
    // Update clases
    lazySizes.cfg.lazyClass = 'lazy'
    lazySizes.cfg.loadedClass = 'loaded'
  }

  init() {
    // Initialize core methods
    this.raf.on()
    this.scroll.on()
    this.mouse.on()

    const lottie = new LottieAnimate({
      container: qs('.preloader .loader'),
      data: intro,
      loop: false,
      autoplay: true,
      speed: 3,
    })

    gsap.set('.preloader', { autoAlpha: 1 })

    lottie.load()

    const anim = lottie.getAnimation()

    this.noise = new Noise()

    this.loader.on().then((responses) => {
      this.ready = true

      anim.addEventListener('complete', () => {
        gsap.set('.preloader', { autoAlpha: 0 })
        Emitter.emit('loaded')
      })
    })
  }
}

import store from '../store'
import { Transition } from '@unseenco/taxi'
import { Core } from '../core'
import { gsap } from 'gsap'

export default class Fade extends Transition {
  onLeave({ from, trigger, done }) {
    this.core = new Core()
    const main = from.parentNode
    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        store.body.scrollTo(0, 0)
        this.core.raf.setScroll(0)
        done()
      },
    })

    tl.fromTo(main, { autoAlpha: 1 }, { duration: 0.5, autoAlpha: 0 })
    tl.play()
  }

  onEnter({ to, trigger, done }) {
    this.core = new Core()
    const main = to.parentNode
    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        done()

        //store.body.scrollTo(0, 0)
        //this.core.raf.setScroll(0)
      },
    })

    tl.fromTo(main, { autoAlpha: 0 }, { duration: 0.5, autoAlpha: 1 })
    tl.play()
  }
}

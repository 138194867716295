import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Form {
  constructor(opt = {}) {
    this.dom = {
      forms: qsa('form'),
    }
    this.init()
  }

  setup() {
    const { forms } = this.dom
    const items = []

    forms &&
      forms.forEach((form) => {
        const reset = qs('.form-reset', form.parentNode)
        const success = qsa('.form-success', form)
        const required = qsa('[required]', form)
        const button = qs('.form-button', form)
        const obj = {
          el: form,
          reset,
          required,
          success,
          button,
        }

        items.push(obj)
      })

    Object.assign(this.dom, { items })
  }

  submit = (e, form) => {
    e.preventDefault()

    const formData = new FormData(form)

    fetch('/', {
      method: 'POST',
      //headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        this.success(form)
      })
      .catch((error) => console.log(error))
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  validate(e, form) {
    const required = qsa('[required]', form)
    const validated = []

    required.forEach((input) => {
      if (input.type == 'email' && input.value) {
        const validate = this.validateEmail(input.value)
        if (!validate) {
          this.error(input)
        } else {
          input.classList.remove('error')
        }
      } else if (!input.value || input.value.length < 2) {
        validated.push(input)
        this.error(input)
      } else {
        input.classList.remove('error')
      }
    })

    if (validated.length == 0) this.submit(e, form)
  }

  error(input) {
    input.classList.add('error')
  }

  success(form) {
    const success = qs('.form-success', form.parentNode)
    gsap.to(success, { autoAlpha: 1 })
  }

  reset = (e, form) => {
    const success = qs('.form-success', form.parentNode)
    form.reset()

    gsap.to(success, { autoAlpha: 0 })
  }

  on() {
    const { forms, items } = this.dom

    items &&
      items.forEach((item) => {
        if (item.reset) {
          item.reset.addEventListener('click', (e) => this.reset(e, item.el))
        }

        if (item.button) {
          item.button.addEventListener('click', (e) =>
            this.validate(e, item.el),
          )
        }

        if (item.el) {
          item.el.addEventListener('submit', (e) => this.validate(e, item.el))
        }
      })
  }

  off() {
    const { forms, items } = this.dom

    items &&
      items.forEach((item) => {
        if (!item.reset) return
        item.reset.removeEventListener('click', (e) => this.reset(e, item.form))
      })

    forms &&
      forms.forEach((form) => {
        form.removeEventListener('submit', (e) => this.validate(e, form))
      })
  }

  destroy() {
    this.off()
  }

  init() {
    const { forms } = this.dom

    if (!forms || forms.length == 0) return

    this.setup()
    this.on()
  }
}

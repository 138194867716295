import Module from './module'
import { LottieAnimate } from '../animations'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { blob } from '../lottie'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class ProductInfo extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      titles: qsa('.-t4', el),
      footnote: qsa('.footnote', el),
    })

    this.lottie = {
      blob: {
        data: blob,
        el: qs('.productinfo-art.art-1'),
      },
    }
  }

  setAnimation() {
    const { blob } = this.lottie
    const { titles, footnote } = this.dom

    this.tl = gsap.timeline({ paused: true })
    titles.forEach((title) => {
      const lines = qsa('.line-0', title)
      lines.forEach((line, i) => {
        const chars = qsa('.char-1', line)
        this.tl.from(
          chars,
          { duration: 0.65, y: '101%', stagger: 0.035, ease: 'power2.inOut' },
          0,
        )
      })
    })

    footnote.forEach((foot) => {
      const lines = qsa('.line-0', foot)

      if (!lines.length) return
      this.tl.from(
        lines,
        {
          duration: 0.6,
          y: 30,
          autoAlpha: 0,
          stagger: 0.05,
          ease: 'power2.inOut',
        },
        '-=0.8',
      )
    })

    // * Setup Lottie Animation

    blob.anim = new LottieAnimate({
      container: blob.el,
      data: blob.data,
      loop: true,
      autoplay: false,
    })

    blob.anim.load()
  }

  setTrigger() {
    const { el } = this.dom
    const { blob } = this.lottie

    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=40%',
      end: 'bottom bottom',
      once: true,
      onEnter: () => {
        this.tl && this.tl.play()
      },
    })

    // * Play / Pause Lottie animation

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=100%',
      end: 'bottom bottom-=100%',
      onEnter: () => {
        blob.anim.play()
      },
      onLeave: () => {
        blob.anim.pause()
      },
      onEnterBack: () => {
        blob.anim.play()
      },
      onLeaveBack: () => {
        blob.anim.pause()
      },
    })
  }
}

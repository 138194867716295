import store from '../store'
import Module from './module'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import { Stick, Player } from '../components'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export default class Intro extends Module {
  constructor(obj) {
    super(obj)
  }

  setup() {
    const { el } = this.dom

    Object.assign(this.dom, {
      sticky: qs('[data-stick]', el),
      stick: qs('.sticky', el),
      content: qsa('.sticky-content', el),
      container: qs('.intro-block'),
      canvas: qs('.intro-player'),
    })

    // * Sticky makes the section sticky / fixed

    this.sticky = new Stick({
      container: el,
      sticky: this.dom.stick,
      items: this.dom.content,
      name: this.dom.stick.parentNode,
    })
  }

  setAnimation(splitText) {
    const { sniff } = store
    const titleTop = splitText[0].chars
    const titleDown = splitText[1].chars

    this.text = gsap.timeline({ paused: true })

    this.text.from(titleTop, {
      duration: 0.5,
      yPercent: 100,
      stagger: {
        amount: 0,
        each: 0.1,
      },
    })

    this.text.from(
      titleDown,
      {
        duration: 0.5,
        yPercent: -100,
        stagger: {
          amount: 0,
          each: 0.05,
        },
      },
      '-=1.2',
    )

    this.text.to(titleTop, {
      duration: 0.5,
      delay: 0.2,
      yPercent: 100,
      stagger: {
        amount: 0,
        each: 0.1,
      },
    })

    this.text.to(
      titleDown,
      {
        duration: 0.5,
        yPercent: 100,
        stagger: {
          amount: 0,
          each: 0.05,
        },
      },
      '-=1.2',
    )

    this.tl = gsap.timeline({ paused: true })

    if (sniff.isDesktop) {
      this.tl.from('.intro-asset', { duration: 0.5, y: 100 })
      this.tl.from('.intro-box', { duration: 0.5, xPercent: -100 }, '-=0.3')
      this.tl.from(
        '.intro-box .box-content',
        { duration: 0.5, xPercent: 100 },
        '-=0.5',
      )
    } else {
      this.tl.from('.intro-asset', { duration: 0.5, autoAlpha: 0 })
      this.tl.from('.intro-box', { duration: 0.5, autoAlpha: 0 })
    }

    // * Player is the image sequence animation

    this.player = new Player({
      container: this.dom.container,
      canvas: this.dom.canvas,
      progress: 0.019,
    })
  }

  run() {
    if (!this.state.isVisible) return

    // * Player Animation
    // * Update image sequence based on sticky progress

    if (this.sticky.state.fixed) {
      if (this.player) this.player.isVisible = true
    } else {
      if (this.player) this.player.isVisible = false
    }
    if (this.player) this.player.progress = this.sticky.progress
  }

  setTrigger() {
    const { el } = this.dom
    if (!el) return

    // * Animation Trigger based on Scroll

    ScrollTrigger.create({
      trigger: el,
      start: 'center center',
      end: 'bottom bottom',
      scrub: true,
      onUpdate: (self) => {
        this.tl.progress(self.progress)
      },
    })

    ScrollTrigger.create({
      trigger: el,
      start: 'top top+=45%',
      end: 'top top-=30%',
      scrub: true,
      onUpdate: (self) => {
        this.text.progress(self.progress)
      },
    })
  }

  resize() {
    this.sticky && this.sticky.resize()
    this.player && this.player.resize()
  }

  destroy() {
    this.player && this.player.destroy()
  }
}
